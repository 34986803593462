import React from "react";
import { connect } from "react-redux";
import { DepartmentCreateEditContainerStyled } from "./styled";
import { Forms, Loading, BGLoading } from "components";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants";

class DepartmentCreateEditContainer extends React.Component {
  state = {
    isLoading: true,
    disabled: false,
    loadOnlyImage: false,
    labelNotic:
      this.props.match.params.id !== "create"
        ? {
            label1: "บันทึกสำเร็จ",
            label2: "บันทึกไม่สำเร็จ",
          }
        : {
            label1: "สร้างสำเร็จ",
            label2: "สร้างไม่สำเร็จ",
          },
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.match.params.id !== "create") {
      this.fetchData();
    } else {
      // this.fetchGoal();
      this.setState({
        isLoading: false,
      });
    }

    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyimg = "";
    for (let i = 0; i < 10; i++) {
      keyimg += characters.charAt(
        Math.floor(Math.random() * characters?.length)
      );
    }
    this.setState({
      keyimg: keyimg,
    });
  }

  // componentDidMount(prevProps) {
  //   let { data } = this.state;
  //   if (data?.media_key !== undefined && data?.media_key !== null) {
  //     this.setState(
  //       {
  //         keyimg: data?.media_key,
  //       },
  //       () => {
  //         this.handleCopy();
  //       }
  //     );
  //   }
  // }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    this.setState({
      loadOnlyImage: false,
    });

    let res = await adminService.GET_CONTENT_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      if (res?.data?.media_key !== null) {
        this.setState({
          keyimg: res?.data?.media_key,
        });
      }
      const response = await this.handleCopy(res?.data?.media_key);

      this.setState({
        data: res.data,
        initialValues: res.data,
        initialValuesImg: response.data,
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  fetchGoal = async () => {
    let res = await adminService.GET_GOALS();
    if (res && res.success) {
      this.setState({
        goalData: res.data.step.step_daily_target,
        isLoading: false,
      });
    }

    //rai
    this.setState({
      isLoading: false,
    });
  };

  onSubmit = async (values) => {
    const { labelNotic, keyimg } = this.state;
    const { companySelectedRedux } = this.props;
    this.setState({
      disabled: true,
      isBgLoading: true,
    });

    let params = {
      ...values,
      category: values.category?.value,
      is_draft: values.is_draft?.value === "true" ? true : false,
      type: values.type?.value,
      files: values?.files ? values?.files : [],
      reward_select: values?.reward_select?.value,
      reward_select_2: values?.reward_select_2?.value,
      media_key: keyimg,
    };
    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_CREATE_CONTENTS(
            companySelectedRedux?.id,
            params
          )
        : await adminService.PATCH_CONTENT_UPDATE(
            this.props.match.params.id,
            params
          );
    if (res && res.success) {
      const filesTemp = [
        ...values.file_cover.filter((e) => e.isNewUpload || e.isDelete),
        ...params.files.filter((e) => e.isNewUpload || e.isDelete),
        ...values.file_feature.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(res.data.id, filesTemp);
      } else {
        toast.success(labelNotic.label1);
        this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
      }
    } else {
      this.setState({
        disabled: false,
      });
      toast.error(labelNotic.label2);
    }
  };

  handleUploadContentMedia = (contentID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set(
              "type",
              e.fieldName === "file_feature"
                ? "feature"
                : this.renderType(e.type)
            );
            bodyFormData.append("files[]", e);
            let res = await adminService.POST_UPLOAD_CONTENT_MEDIA(
              contentID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
          if (e.isDelete) {
            let res = await adminService.DELETE_MEDIA_CONTENT(contentID, e.id);
            if (res && res.success) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success("สร้างสำเร็จ");
      this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
      // this.fetchData();
    });
  };

  renderType = (type) => {
    switch (type) {
      case "image/jpeg":
      case "image/png":
        return "cover";
      case "audio/mpeg":
        return "audio";
      case "video/mp4":
        return "video";
      default:
        break;
    }
  };

  handleBack = () => {
    if (this.props.match.params.id === "create") {
      this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
    } else {
      this.props.history.push(
        `${ROUTE_PATH.BACK_OFFICE_PREVIEW_DETAIL}/${this.props.match.params.id}`
      );
    }
  };

  handleUpload = async (files) => {
    // _setBgIsLoading(true);
    // Promise.all(
    //   files.map((e) => {
    //     return new Promise(async (resolve) => {
    //       if (e.isNewUpload) {
    //         const bodyFormData = new FormData();
    //         bodyFormData.set("type", "article");
    //         bodyFormData.append("files[]", e);
    //         let res = await adminService.POST_WEB_CONTENTS_UPLOAD(bodyFormData);
    //         if (res && res.success) {
    //           if (res.data && res.data.length === 0) {
    //             _setIsShowModalError(true);
    //           }
    //           let resImg = await _fetchImage("article");
    // this.setState({
    //   loadOnlyImage: true,
    // });
    //           if (resImg) {
    //             _setInitialValuesImage({ file_img: resImg });
    //             _setBgIsLoading(false);
    //           }
    //           resolve();
    //         } else {
    //           _setIsShowModalError(true);
    // this.setState({
    //   loadOnlyImage: true,
    // });
    //           let resImg = await _fetchImage("article");
    //           if (resImg) {
    //             _setInitialValuesImage({ file_img: resImg });
    //             _setBgIsLoading(false);
    //           }
    //         }
    //       }
    //     });
    //   })
    // )
    //   .then(() => {
    //     toast.success("อัพรูปสำเร็จ");
    //   })
    //   .catch((err) => {
    //     _setIsShowModalError(true);
    //     // toast.success("อัพเดทไม่สำเร็จ");
    //   });
  };

  handleRemove = async (e) => {
    let res = await adminService.DELETE_LINK_MEDIA(e.id);
    this.handleCopy();
    return res;
  };

  handleCopy = async (e) => {
    const { keyimg } = this.state;

    let res = await adminService.GET_GENERATE_LINK_MEDIA(
      this.props.companySelectedRedux.id,
      keyimg
    );

    return res;
  };

  handleApiGenerateLink = async (e) => {
    const { keyimg } = this.state;
    const bodyFormData = new FormData();
    bodyFormData.set("type", "content");
    bodyFormData.append("key", keyimg);
    bodyFormData.append("files[]", e);

    let res = await adminService.POST_GENERATE_LINK_MEDIA(
      this.props.companySelectedRedux.id,
      bodyFormData
    );

    return res;
  };

  render() {
    const { companySelectedRedux } = this.props;
    const {
      initialValues,
      isLoading,
      goalData,
      disabled,
      isBgLoading,
      loadOnlyImage,
      initialValuesImg,
    } = this.state;

    return (
      <DepartmentCreateEditContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Forms.AddArticleForm
              initialValues={initialValues}
              companyID={companySelectedRedux?.id}
              goalData={goalData}
              onSubmit={this.onSubmit}
              disabled={disabled}
              handleBack={this.handleBack}
              handleRemove={this.handleRemove}
              loadOnlyImage={loadOnlyImage}
              handleUpload={this.handleUpload}
              handleCopy={this.handleCopy}
              apiGenerateLink={this.handleApiGenerateLink}
              initialValuesImage={initialValuesImg}
            />
          </>
        )}
      </DepartmentCreateEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentCreateEditContainer);

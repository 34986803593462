import React from "react";
import { RewardManagementCreateEditContainerStyled } from "./styled";
import { Tabs, Forms, Loading, BGLoading } from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { getObjectKey } from "utils/functions/getObjectKey";

class RewardManagementCreateEditContainer extends React.Component {
  state = {
    isLoading: true,
    // disabled: false,
    // labelNotic:
    //   this.props.match.params.id !== "create"
    //     ? {
    //         label1: "บันทึกสำเร็จ",
    //         label2: "บันทึกไม่สำเร็จ",
    //       }
    //     : {
    //         label1: "สร้างสำเร็จ",
    //         label2: "สร้างไม่สำเร็จ",
    //       },
  };

  componentDidMount() {
    this.scrollToTop();
    this.checkPermission()
    if (this.props.match.params.id !== "create") {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'spin_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_SPIN_ITEM_BY_ID(
      this.props.match.params.id
    );

    if (res && res.success) {
      this.setState({
        isLoading: false,
        initialValues: res.data,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleBackClick = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN}?tabIndex=reward`);
  };

  onSubmit = async (values) => {
    const { companySelectedRedux } = this.props;
    this.setState({
      isBgLoading: true,
    })

    let params = {
      ...values
    }

    if (values && values.files && values.files.length > 0 && values.files[0].isNewUpload) {
      params.image_base64 = await this.getBase64(
        values.files[0]
      );

      delete params.image_uri
    }

    let res =
      this.props.match.params.action !== "edit"
        ? await adminService.POST_CREATE_SPIN_ITEMS(
          companySelectedRedux?.id,
          params
        )
        : await adminService.PATCH_SPIN_ITEMS_BY_ID(
          this.props.match.params.id,
          params
        );

    if (res && res.success) {
      toast.success("บันทึก");
      this.handleBackClick()
    } else {
      toast.error("บันทึกไม่สำเร็จ");
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) { };
    });
  };

  render() {
    const { initialValues, isLoading, isBgLoading, perMissions } = this.state;

    return (
      <RewardManagementCreateEditContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title={this.props.match.params.action !== 'edit' ? "สร้างรางวัล" : "แก้ไขรางวัล"}
                subTitle={this.props.match.params.action !== 'edit' ? "Create Reward" : "Edit Reward"}
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                margin_right="19px"
                lineHeight="1.3"
                // typeDate
                // dataDate={updateDate}
                handleBackCustom={this.handleBackClick}
              />
            </div>
            <div className="layer_two">
              <Forms.AddLuckyRewardForm
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                isCannotEditDelete={perMissions ? false : true}
                aciton_type={this.props.match.params.action}
              />
            </div>
          </>
        )}
      </RewardManagementCreateEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  companySelectedRedux: state.companySelectedRedux,
  authenRedux: state.authenRedux,
  rewardCategory: state.rewardCategory,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardManagementCreateEditContainer);

import styled from 'styled-components';

export const MostPickedStyled = styled.div`
  .container_most_pick {
    .top_pick {
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 16px;
        display: flex;
      }
    }
    .mid_pick {
      margin-top: 18px;
      // padding-bottom: 24px;
      // border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_1}50;
      // height: 291px;
      height: 140px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #f0f0f0;
      }
      .label_empty {
        text-align: center;
        padding-top: 40px;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .bot_pick {
      margin-top: 26px;
    }
  }
`;

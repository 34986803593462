import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { ShowGenderStyled } from './styled';

const ShowGender = ({
  theme_gender_male,
  theme_gender_female,
  label,
  labelBottom,
  svg
}) => {
  const customClass = cx({
    theme_gender_male: theme_gender_male,
    theme_gender_female: theme_gender_female,
  });
  return (
    <ShowGenderStyled >
      <div className={customClass} >
        {
          svg &&
          <div className='svg_show'>
            {svg}
          </div>
        }
        <div className='show_label'>
          <div>
            {label}
          </div>
          <div className='labe_bottom'>
            {labelBottom}
          </div>
        </div>
      </div>
    </ShowGenderStyled>
  );
};

ShowGender.propTypes = {};

export default ShowGender;

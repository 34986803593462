import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { NewestRewardStyled } from './styled';
import { Blocks, Icons } from 'components';

const NewestReward = ({
  theme_newest_reward,
  label1,
  label2,
  label3,
  label4,
  label5,
}) => {
  const customClass = cx({
    theme_newest_reward: theme_newest_reward,
  });
  return (
    <NewestRewardStyled>
      <div className={customClass}>
        <Blocks.Box theme_standard_box>
          <div className="line1">
            <div className="lb_1">{label1}</div>
            <div className="group_icon">
              <div className="show_svg">
                <Icons.Dumbel2 width="16" height="16" />
              </div>
              <div>{label2}</div>
            </div>
          </div>
          <div className="light_font h_54">{label3}</div>
          <div className="light_font red">{label4}</div>
          <div className="date_show">{label5}</div>
        </Blocks.Box>
      </div>
    </NewestRewardStyled>
  );
};

NewestReward.propTypes = {};

export default NewestReward;

import React, { useState, useEffect, useRef } from 'react';
import { InputSuggestHealthyClubStyled } from './styled';
import { adminService } from 'apiServices';
import Autosuggest from 'react-autosuggest';
// import { Button } from 'components';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

const InputSuggestHealthyClub = ({ value, groupId, onChangeValue, errors }) => {
  let refFetchSettimeout = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [_value, setValue] = useState('');
  const [_valueSelected, _setValueSelected] = useState('');

  useEffect(() => {
    if (_valueSelected) {
      onChangeValue && onChangeValue(_valueSelected);
    }
  }, [_valueSelected, onChangeValue]);

  useEffect(() => {
    if (!value) {
      setValue('');
    }
  }, [value]);

  const onChange = (event, { newValue }) => {
    _setValueSelected(null);
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(refFetchSettimeout.current);
    refFetchSettimeout.current = setTimeout(async () => {
      let res = await adminService.GET_USERS_BY_GROUP_ID(
        groupId,
        `?is_admin=0&sort_by=joined_at&order_by=desc&keyword=${value}`
      );

      if (res && res.success) {
        setSuggestions(res.data.slice(0, 10));
      } else {
        setSuggestions([]);
      }
    }, 400);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'เพิ่มบุคคลที่ต้องการเป็นผู้ดูแลกลุ่ม',
    value: _value,
    id: 'inputtext',
    onChange: onChange,
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    _setValueSelected(suggestion);
    setValue(
      `${suggestion.employee.firstname} ${suggestion.employee.lastname}`
    );
  };

  return (
    <InputSuggestHealthyClubStyled>
      <div className="input_layout">
        <div className="box_suggest">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion) => (
              <div>{`${suggestion.employee.firstname} ${suggestion.employee.lastname}`}</div>
            )}
            // shouldRenderSuggestions={shouldRenderSuggestions}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={inputProps}
          />
          <div className="label_bottom">
            <div>ผู้ดูแลกลุ่ม</div>
          </div>
        </div>
        {errors && <div className="error">* กรุณาใส่ชื่อผู้ดูแลระบบด้วย</div>}
      </div>
    </InputSuggestHealthyClubStyled>
  );
};

export default InputSuggestHealthyClub;

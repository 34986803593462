export default function People() {
  return (
    <svg id="Group_14221" data-name="Group 14221" xmlns="http://www.w3.org/2000/svg" width="146.999" height="84.536" viewBox="0 0 146.999 84.536">
      <g id="Group_14122" data-name="Group 14122" transform="translate(0 8.118)">
        <g id="Group_14121" data-name="Group 14121" transform="translate(0 0)">
          <g id="Group_14105" data-name="Group 14105" transform="translate(0 46.781)">
            <g id="Group_14104" data-name="Group 14104">
              <g id="Group_14103" data-name="Group 14103" transform="translate(0 25.389)">
                <path id="Path_34260" data-name="Path 34260" d="M212.442,1572.109l-.036-.588-.625-3.66-2.166.229-.588,3.433-.11.586Z" transform="translate(-208.916 -1567.861)" fill="#df7e61" />
              </g>
              <path id="Path_34261" data-name="Path 34261" d="M226.766,1167.129s-5.383,18.663-5.983,23.019c0,0-1.925,2.141-2.166.229-.484-3.818,1.02-23.683,2.042-25.227S226.766,1167.129,226.766,1167.129Z" transform="translate(-217.918 -1164.759)" fill="#df7e61" />
            </g>
            <path id="Path_34262" data-name="Path 34262" d="M217.826,1180.626a217.013,217.013,0,0,0-1.516,23.224s2.314.6,3.445.108c0,0,6.331-17.27,5.481-25.327C225.236,1178.631,219.425,1175.512,217.826,1180.626Z" transform="translate(-215.844 -1176.875)" fill="#95bedb" />
          </g>
          <path id="Path_34263" data-name="Path 34263" d="M269.613,433.558c-.319,3.711-1.565,4.74-.214,8.525,1.247,3.5,4.318,5.954,4.9,9.763.417,2.705-.061,7.168-1.753,9.45-2.456,3.312-8.512,1.827-11.944,1.45-3.9-.43-12.554-.262-15.133-3.96-1.957-2.8.077-8.063.668-11.061.75-3.8,1.544-7.6,2.221-11.422.617-3.471,1.474-9.118,3.9-11.942,2.06-2.4,4.9-2.607,8.31-2.164A10.524,10.524,0,0,1,269.613,433.558Z" transform="translate(-242.392 -422.01)" fill="#301204" />
          <path id="Path_34264" data-name="Path 34264" d="M255.429,838.243a5.042,5.042,0,0,0-5.182,4.9c-.077,2.783,1.44,9.965,3.56,8.159,4.167-3.549,6.246-5.684,6.324-8.467S258.212,838.321,255.429,838.243Z" transform="translate(-247.641 -812.026)" fill="#df7e61" />
          <path id="Path_34265" data-name="Path 34265" d="M248.027,889.789l5.04-.687,3.054,4.239s-3.838,16.267-6.149,19.406c-2.463,3.344-3.055-.1-3.449-1.452C246.522,911.295,246.355,892.572,248.027,889.789Z" transform="translate(-244.149 -859.683)" fill="#df7e61" />
          <path id="Path_34266" data-name="Path 34266" d="M303.327,1473.378q.268-1.179.552-2.437l-13.76-8.447s-1.808,6.369-2.773,10.885Z" transform="translate(-282.406 -1396.961)" fill="#f5916e" />
          <path id="Path_34267" data-name="Path 34267" d="M285.794,1457.581c.149-.99.217-1.553.217-1.553,2.484-11.2-14.038-10.38-14.038-10.38-1.491,2.734-2.547,7.044-3.295,11.933Z" transform="translate(-264.914 -1381.163)" fill="#050910" />
          <path id="Path_34268" data-name="Path 34268" d="M321.107,1218.122c-.638-.116-3.273,1.244-3.273,1.244s-3.291,10.572-3.431,12.181,10.913,9.929,23.265,5.19c0,0-5.719-14.889-6.068-18.555S321.107,1218.122,321.107,1218.122Z" transform="translate(-307.758 -1166.494)" fill="#e18160" />
          <path id="Path_34269" data-name="Path 34269" d="M317.052,1243.844s-3.154,9.534-3.431,12.181c-.985,9.366,11.69,13.295,24.04,8.556,0,0,.8-12.878-6.843-21.921C330.818,1242.66,328.787,1244.512,317.052,1243.844Z" transform="translate(-306.976 -1190.973)" fill="#0a0f15" />
          <path id="Path_34270" data-name="Path 34270" d="M675.371,1623.942c.026-.492.04-.764.04-.764l-1.391.764Z" transform="translate(-644.725 -1547.524)" fill="#0a0f15" />
          <path id="Path_34271" data-name="Path 34271" d="M446.5,1484.25c.816-7.809-3.413-9.034-3.413-9.034-7.351-3.282-14.118.923-13.16,9.034Z" transform="translate(-415.918 -1407.832)" fill="#0a0f15" />
          <path id="Path_34272" data-name="Path 34272" d="M415.91,644.966h-8.6c.389-1.252,1.2-4.492-.053-6.7l8.685-5.7S414.4,642.136,415.91,644.966Z" transform="translate(-394.77 -619.304)" fill="#f5916e" />
          <path id="Path_34273" data-name="Path 34273" d="M407.931,641.207a5.606,5.606,0,0,0-.669-2.939l8.685-5.7a61.662,61.662,0,0,0-.693,7.458C412.117,641.021,410.827,641.407,407.931,641.207Z" transform="translate(-394.77 -619.304)" fill="#d9705a" />
          <path id="Path_34274" data-name="Path 34274" d="M296.823,821.574c3.869,1.892,1.718,6.439,1.718,6.439l-5.229,19.032s-4.887,2-13.461,1c-1.19-1.7-3.639-8.845-4.458-14.3-.676-4.49-2.773-8.914-.488-11.418.876-.96,7.548-1.918,7.582-2.075h8.031Z" transform="translate(-269.776 -795.171)" fill="#f5916e" />
          <g id="Group_14117" data-name="Group 14117" transform="translate(8.746 0.187)">
            <path id="Path_34275" data-name="Path 34275" d="M353.041,452.842l-.521-1.268-1.306-3.742a3.84,3.84,0,0,1-.122-2.016,6.113,6.113,0,0,0,.016-3.162c-.883-3.42.559-5.3.559-5.3,1.858-3.282,6.619-3.508,6.619-3.508,5.9.007,6.674,5.683,6.674,5.683,1.138,3.745-1.726,7.047-1.732,7.053a4.188,4.188,0,0,0-.53,1.568,4.743,4.743,0,0,1-.792,1.484,10.558,10.558,0,0,1-2.895,2.577,11.657,11.657,0,0,1-4.484,1.3C353.929,453.532,353.339,453.569,353.041,452.842Z" transform="translate(-350.631 -433.284)" fill="#f5916e" />
            <path id="Path_34276" data-name="Path 34276" d="M359.826,440.5a11.105,11.105,0,0,0-.165-2.522c-.1-.783-1.558.989-1.579-.035-.092-4.692-1.908-6.835-2.591-7.162a8.253,8.253,0,0,0-5.276-.725c-2.348.541-2.1,3.69-2.1,3.69-2.6-11.442,10.458-8.407,10.458-8.407a7.144,7.144,0,0,1,6.008,7.27c1.091,6.306-4.971,10.277-4.971,10.277Z" transform="translate(-347.774 -424.975)" fill="#301204" />
            <g id="Group_14114" data-name="Group 14114" transform="translate(10.869 11.074)">
              <g id="Group_14110" data-name="Group 14110">
                <g id="Group_14109" data-name="Group 14109">
                  <g id="Group_14108" data-name="Group 14108">
                    <g id="Group_14107" data-name="Group 14107">
                      <g id="Group_14106" data-name="Group 14106">
                        <path id="Path_34277" data-name="Path 34277" d="M521.073,604.357a1.262,1.262,0,0,0,.829.207,1.855,1.855,0,0,0,1.593-1.99,1.7,1.7,0,0,0-1.7-1.772,1.6,1.6,0,0,0-1.1.573,2.312,2.312,0,0,0-.253,1.9A1.74,1.74,0,0,0,521.073,604.357Z" transform="translate(-520.349 -600.798)" fill="#f68c68" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_14113" data-name="Group 14113" transform="translate(0.97 0.59)">
                <g id="Group_14111" data-name="Group 14111" transform="translate(0.136)">
                  <path id="Path_34278" data-name="Path 34278" d="M537.924,610.559c.026-.548,1.155-.083,1,.232a1.673,1.673,0,0,1-.418.452.8.8,0,0,0-.309.613c0,.03,0,.126.029.146.147.092.588-.463.684-.581a.909.909,0,0,0,.087-.878.544.544,0,0,0-.623-.377c-.248.027-.5.057-.469.364,0,.006.014.057.016.029Z" transform="translate(-537.906 -610.161)" fill="#e3755b" />
                </g>
                <g id="Group_14112" data-name="Group 14112" transform="translate(0 0.898)">
                  <path id="Path_34279" data-name="Path 34279" d="M536.289,624.468c-.121-.108-.225-.007-.339.082a.524.524,0,0,0-.18.331,1.279,1.279,0,0,0,.183.9.686.686,0,0,0,.607.284c.332-.026.415-.324.413-.606,0-.074,0-.253-.078-.3s-.133.081-.182.136a.469.469,0,0,1-.276.12.6.6,0,0,1-.5-.2.255.255,0,0,1-.055-.083c.009.184.021.224.035.122a.515.515,0,0,1,.056-.134c.07-.131.2-.224.275-.35.038-.057.105-.259.045-.315Z" transform="translate(-535.753 -624.421)" fill="#e3755b" />
                </g>
              </g>
            </g>
            <g id="Group_14116" data-name="Group 14116" transform="translate(0.343 8.42)">
              <path id="Path_34280" data-name="Path 34280" d="M445.7,589.281c-.016.328.153.6.376.612s.414-.247.429-.575-.154-.6-.376-.612S445.71,588.954,445.7,589.281Z" transform="translate(-439.871 -586.814)" fill="#2d1a10" />
              <path id="Path_34281" data-name="Path 34281" d="M373.288,582.879c0,.329.189.592.411.589s.4-.272.4-.6-.188-.591-.412-.587S373.283,582.551,373.288,582.879Z" transform="translate(-372.024 -580.793)" fill="#2d1a10" />
              <path id="Path_34282" data-name="Path 34282" d="M427.253,566.947c-.877-1.145-3.18-.7-3.18-.7s-.134,0-.118-.41c0,0-.009-.361.282-.341C424.237,565.5,427.06,565.019,427.253,566.947Z" transform="translate(-419.499 -565.019)" fill="#301204" />
              <path id="Path_34283" data-name="Path 34283" d="M353.227,559.016c.4-.771,2.552-.033,2.552-.033.29.027.223.383.223.383-.052.406-.184.384-.184.384s-2.137-.832-2.5-.34C353.25,559.14,353.269,559.162,353.227,559.016Z" transform="translate(-353.227 -558.665)" fill="#301204" />
              <g id="Group_14115" data-name="Group 14115" transform="translate(1.814 6.422)">
                <path id="Path_34284" data-name="Path 34284" d="M382.2,660.623l3.185.423c.166.024.234.145.144.27,0,0-.906,1.289-1.939,1.148s-1.558-1.623-1.558-1.623C381.99,660.711,382.066,660.625,382.2,660.623Z" transform="translate(-382.022 -660.623)" fill="#fff" />
              </g>
              <path id="Path_34285" data-name="Path 34285" d="M395.235,614.473c-1.7-.036-1.811-.475-1.811-.475l.8-1.826S394.107,613.963,395.235,614.473Z" transform="translate(-390.892 -608.802)" fill="#e3755b" />
            </g>
            <path id="Path_34286" data-name="Path 34286" d="M531.3,651.607a.558.558,0,1,0,.53-.587A.559.559,0,0,0,531.3,651.607Z" transform="translate(-519.739 -636.782)" fill="#fff" />
          </g>
          <g id="Group_14118" data-name="Group 14118" transform="translate(21.919 26.23)">
            <path id="Path_34287" data-name="Path 34287" d="M556.939,842.691c-.173,2.779,1.28,5.508,4.06,5.679s10.007-1.1,8.274-3.278c-3.4-4.286-5.466-6.437-8.245-6.609S557.11,839.911,556.939,842.691Z" transform="translate(-556.925 -838.47)" fill="#f5916e" />
            <path id="Path_34288" data-name="Path 34288" d="M590.8,888.352l1.768-4.769,5.177-.7s12.538,11.051,14.219,14.569c1.789,3.749-1.53,2.647-2.906,2.358C609.055,899.814,592.462,891.139,590.8,888.352Z" transform="translate(-588.664 -880.09)" fill="#f5916e" />
            <path id="Path_34289" data-name="Path 34289" d="M724.041,1099.19s-6.247,8.788-12.065,13.794c0,0-2.507-.249-1.713-1.618,1.351-2.322,6.911-13.2,8.277-15.528C719.308,1094.529,724.041,1099.19,724.041,1099.19Z" transform="translate(-700.459 -1079.411)" fill="#f5916e" />
            <path id="Path_34290" data-name="Path 34290" d="M598.525,1332.626l-2.938.75-1.257-1.638a7.607,7.607,0,0,1-.574,3.057,34.969,34.969,0,0,0-2.628,3.109c-.643.593.073.922.073.922a16.376,16.376,0,0,0,3.48-2.131,40.071,40.071,0,0,1-3.307,2.856c-.441.582.293.757.293.757,1.734-.043,3.738-2.616,3.738-2.616a23.222,23.222,0,0,1-2.791,2.718c-.091.519.2.488.2.488,1.38.176,3.3-2.305,3.3-2.305l-.743.911c-1.13,1.156-.09,1.277-.09,1.277l.686-.512a9.422,9.422,0,0,0,2.845-3.635l1.424-2.389Z" transform="translate(-588.722 -1300.67)" fill="#f5916e" />
          </g>
          <path id="Path_34291" data-name="Path 34291" d="M719.057,1083.127s.508.734-1.286,3.181c0,0-6.727,9.706-11.072,12.073,0,0-1.913-.765-2.158-2.192,0,0,5.766-13.272,9.356-15.533h0s2.857-2.1,5.16,2.471" transform="translate(-673.325 -1038.231)" fill="#3797be" />
          <path id="Path_34292" data-name="Path 34292" d="M238.419,851.095s-2.605.65-2.605,6.466c0,0-2.075,20.045,1.538,21.021,0,0,4.5,1.43,7.779-19.418C245.131,859.165,244.386,851,238.419,851.095Z" transform="translate(-233.564 -824.069)" fill="#95bedb" />
          <path id="Path_34293" data-name="Path 34293" d="M276.53,827.668l-3.709.666s-2.7.591-2.793,2.906c0,0-.152,4.384,2.234,12.312C272.263,843.553,276.209,831.387,276.53,827.668Z" transform="translate(-266.177 -802.118)" fill="#95bedb" />
          <path id="Path_34294" data-name="Path 34294" d="M303.8,820.576l-1.672.257a42.98,42.98,0,0,0-4.009,12.914c.819,5.452,2.463,13.707,4.458,14.3,6.759,2,13.814-1.143,13.814-1.143l3.232-12.907c-8.681-3.38-4.294-13.3-4.294-13.3l-1.823-.437-7.674,7.757-2.032-7.435" transform="translate(-292.503 -795.171)" fill="#95bedb" />
          <path id="Path_34295" data-name="Path 34295" d="M546.21,827.186l4.294.71a8.457,8.457,0,0,1,3.267,2.2s17.186,12.833,15.289,17.456a1.847,1.847,0,0,1-2.332,1.079l-16.642-7.85S540.8,836.5,546.21,827.186Z" transform="translate(-523.381 -801.667)" fill="#3797be" />
          <path id="Path_34296" data-name="Path 34296" d="M274.072,893.324s-3.392,4.7-3.983,7.119c-.388,1.591,2.385,5.174,4.624,5.806C275.936,906.594,275.965,893.64,274.072,893.324Z" transform="translate(-266.202 -863.639)" fill="#3797be" />
          <path id="Path_34297" data-name="Path 34297" d="M274.072,893.324s-3.392,4.7-3.983,7.119c-.388,1.591,2.385,5.174,4.624,5.806C275.936,906.594,275.965,893.64,274.072,893.324Z" transform="translate(-266.202 -863.639)" fill="#0d141b" />
          <g id="Group_14119" data-name="Group 14119" transform="translate(5.515 25.083)">
            <path id="Path_34298" data-name="Path 34298" d="M300.335,820.833a45.715,45.715,0,0,0-3.855,12.954c.819,5.451,2.567,13.667,4.561,14.257,6.759,2,13.814-1.143,13.814-1.143l3.232-12.907c-8.681-3.38-4.294-13.3-4.294-13.3l-1.823-.437-8.118,11.6-1.588-11.283Z" transform="translate(-296.48 -820.254)" fill="#0d141b" />
            <path id="Path_34299" data-name="Path 34299" d="M421.654,820.254l-8.119,11.6h0l5.839-1.58s.848-.719.309-1.5l-.8-1.567,1.069-.318s1.4-.166,1.637-1.31l.877-5.132Z" transform="translate(-406.162 -820.254)" fill="#182028" />
            <path id="Path_34300" data-name="Path 34300" d="M355.778,825.629l1.559,11.266h0l-3.172-2.946s-1.3-1.061-.083-1.591l1.511-.469-1.773-1.593a1.468,1.468,0,0,1,0-1.4l1.065-3.164Z" transform="translate(-349.965 -825.29)" fill="#182028" />
            <path id="Path_34301" data-name="Path 34301" d="M315.155,1224.313l5.172,8.571s-4.6,4.536-13.642,5.341c0,0-6.335.158-10.205-3.389l3.231-10.523Z" transform="translate(-296.48 -1198.864)" fill="#0d141b" />
          </g>
          <g id="Group_14120" data-name="Group 14120" transform="translate(14.487 23.172)">
            <path id="Path_34302" data-name="Path 34302" d="M438.924,854.559l.1,4.353,1.6-1.567Z" transform="translate(-438.924 -850.487)" fill="#3081a2" />
            <path id="Path_34303" data-name="Path 34303" d="M438.924,793.892s4.863-1.991,6.069-3.974l2.273,2.347-5.9,5.722-2.443-4" transform="translate(-438.924 -789.918)" fill="#5392bf" />
          </g>
          <path id="Path_34304" data-name="Path 34304" d="M370.2,806.479a6.521,6.521,0,0,1,2.783-3.364l-.3,6.284S371.4,806.185,370.2,806.479Z" transform="translate(-360.039 -779.112)" fill="#5392bf" />
          <path id="Path_34305" data-name="Path 34305" d="M366.875,859.142l.209-2.921c.707-.173,1.336.814,1.851,1.624Z" transform="translate(-356.926 -828.855)" fill="#3081a2" />
          <path id="Path_34306" data-name="Path 34306" d="M398.324,441.492c4,1.935,6.293,3.783,5.523,8.568-.694,4.315-.884,6.668,1.662,10.357,2.391,3.463,2.694,5.5.986,9.425-1.372,3.156-2.724,6.187,1.2,7.841,7.4,3.123,13.829-2.948,10.723-10.633-1.393-3.445-4.465-5.685-6.041-8.985-1.6-3.342-.374-6.322-.246-9.789.214-5.8-8.094-13.783-12.776-7.936A10.1,10.1,0,0,0,398.349,442Z" transform="translate(-386.394 -437.273)" fill="#301204" />
        </g>
      </g>
      <g id="Group_14149" data-name="Group 14149" transform="translate(105.463)">
        <g id="Group_14148" data-name="Group 14148" transform="translate(0 0)">
          <g id="Group_14147" data-name="Group 14147">
            <g id="Group_14123" data-name="Group 14123" transform="translate(26.326 34.091)">
              <path id="Path_34307" data-name="Path 34307" d="M2318.34,907.785l1.612.351s4.407,18.966,2.749,23.469-4.324.045-5.157-.362-8.2-21.279-8.2-21.279Z" transform="translate(-2308.841 -903.163)" fill="#df7e61" />
              <path id="Path_34308" data-name="Path 34308" d="M2316.407,860.466l-2.9-22.028c-1.725-5.795-8.093-3.654-8.093-3.654-4.778,1.771-4.018,5.53-4.018,5.53l6.542,22.632s1.239,3.437,5.777,2.98C2313.718,865.925,2317.021,865.136,2316.407,860.466Z" transform="translate(-2301.354 -834.397)" fill="#001431" />
            </g>
            <path id="Path_34309" data-name="Path 34309" d="M1942.987,793.473l4.423-27.038s1.525-4.063-1.523-6.095l-11.619-5.462h-8.194l-13.969,8.256,9.156,30.338Z" transform="translate(-1910.295 -725.796)" fill="#f5916e" />
            <path id="Path_34310" data-name="Path 34310" d="M2034.514,1384.5l.03-16.859h-21.727s-2.249,5.138-3,16.859Z" transform="translate(-2001.852 -1299.966)" fill="#001c3e" />
            <g id="Group_14124" data-name="Group 14124" transform="translate(8.106 78.574)">
              <path id="Path_34311" data-name="Path 34311" d="M2027.054,1546.615a7.688,7.688,0,0,0-14.983,0Z" transform="translate(-2012.07 -1540.653)" fill="#001c3e" />
              <path id="Path_34312" data-name="Path 34312" d="M2097.081,1628.051a7.1,7.1,0,0,0-5.194,0Z" transform="translate(-2086.86 -1622.089)" fill="#001c3e" />
            </g>
            <g id="Group_14125" data-name="Group 14125" transform="translate(19.117 79.23)">
              <path id="Path_34313" data-name="Path 34313" d="M2200.023,1556.378c-.884-3.334-3.073-5.632-6.634-5.268a7.731,7.731,0,0,0-6.5,5.268Z" transform="translate(-2186.887 -1551.072)" fill="#001c3e" />
            </g>
            <g id="Group_14126" data-name="Group 14126" transform="translate(0 35.889)">
              <path id="Path_34314" data-name="Path 34314" d="M1888.252,938.729l-1.593.425s-2.484,19.065-.619,23.485,3.616.037,4.428-.409,6.872-21.739,6.872-21.739Z" transform="translate(-1885.221 -933.955)" fill="#f5916e" />
              <path id="Path_34315" data-name="Path 34315" d="M1890.674,891.039l5.219-22.452a6.53,6.53,0,0,0-6.858-5.645c-5.074.461-5.323,4.287-5.323,4.287l-.336,22.836s-.335,3.9,2.606,3.7C1885.983,893.761,1890.194,893.1,1890.674,891.039Z" transform="translate(-1883.365 -862.936)" fill="#001431" />
            </g>
            <path id="Path_34316" data-name="Path 34316" d="M2133.232,660.061v8.759h9.261a12.165,12.165,0,0,1-1.138-8.759Z" transform="translate(-2117.494 -636.95)" fill="#f5916e" />
            <path id="Path_34317" data-name="Path 34317" d="M2137.93,667.5c.834.537,2.608,1.681,3.622,1.011a.956.956,0,0,0,.439-.679,12.83,12.83,0,0,1-.636-7.77h-8.123v.795C2134.245,663.418,2135.546,665.961,2137.93,667.5Z" transform="translate(-2117.494 -636.95)" fill="#d9705a" />
            <g id="Group_14127" data-name="Group 14127" transform="translate(10.043 5.388)">
              <path id="Path_34318" data-name="Path 34318" d="M2048.285,396.512s-1.576-.684-3.361-4.586c0,0-3.874-4.613-1.128-9.851,0,0,1.623-4.429,9.487-3.19C2053.283,378.885,2053.99,390.757,2048.285,396.512Z" transform="translate(-2042.812 -378.667)" fill="#f68c68" />
              <path id="Path_34319" data-name="Path 34319" d="M2084.626,398.88l.524-1.373,1.448-5.11a5.308,5.308,0,0,0,.075-2.163,5.309,5.309,0,0,1-.021-2.227,6.45,6.45,0,0,0-.97-5.761c-2.075-3.466-7.183-3.581-7.183-3.581-6.325.165-7,6.266-7,6.266-1.12,4.042,2.036,7.5,2.042,7.51.253.277.089,1.612.157,1.994.3,1.63,1.825,2.663,3.186,3.383a15.173,15.173,0,0,0,4.99,1.829C2082.515,399.721,2084.325,399.668,2084.626,398.88Z" transform="translate(-2069.468 -378.666)" fill="#f5916e" />
            </g>
            <path id="Path_34320" data-name="Path 34320" d="M2237.668,541.089l-1.76.536s2.3.313,2.437-.351c0,0-.254-1.334-.54-2.6C2237.8,538.672,2237.929,541.01,2237.668,541.089Z" transform="translate(-2213.704 -523.206)" fill="#e3755b" />
            <g id="Group_14128" data-name="Group 14128" transform="translate(18.764 13.027)">
              <path id="Path_34321" data-name="Path 34321" d="M2206.013,524.941c.011.362-.182.661-.428.667s-.453-.28-.463-.643.182-.661.427-.668S2206,524.579,2206.013,524.941Z" transform="translate(-2203.621 -522.764)" fill="#2d1a10" />
              <path id="Path_34322" data-name="Path 34322" d="M2285,521.986c.025.363-.156.667-.4.683s-.463-.262-.487-.625.156-.668.4-.684S2284.981,521.625,2285,521.986Z" transform="translate(-2277.637 -520.011)" fill="#2d1a10" />
              <path id="Path_34323" data-name="Path 34323" d="M2184.236,503.653c.156.046.212-.166.212-.166a.75.75,0,0,0,.018-.384.377.377,0,0,0-.176-.208,5.42,5.42,0,0,0-1.908.108c-.383.084-1.013.319-1.078.662-.019.1-.042.28.073.32A15.451,15.451,0,0,1,2184.236,503.653Z" transform="translate(-2181.288 -502.668)" fill="#4d1e05" />
              <path id="Path_34324" data-name="Path 34324" d="M2280.28,500.8a13.382,13.382,0,0,0-2.222.068c-.151.065-.232-.138-.233-.138a.753.753,0,0,1-.066-.379.368.368,0,0,1,.149-.228,4.013,4.013,0,0,1,1.654-.136,3.207,3.207,0,0,1,.814.171A1.586,1.586,0,0,1,2280.28,500.8Z" transform="translate(-2271.68 -499.959)" fill="#4d1e05" />
            </g>
            <g id="Group_14129" data-name="Group 14129" transform="translate(21.584 19.853)">
              <path id="Path_34325" data-name="Path 34325" d="M2229.619,608.334a.178.178,0,0,1,.154.266s-.677,1.634-1.764,1.686-1.919-1.509-1.919-1.509c-.081-.151,0-.282.175-.292C2226.265,608.484,2228.142,608.375,2229.619,608.334Z" transform="translate(-2226.058 -608.334)" fill="#fff" />
            </g>
            <path id="Path_34326" data-name="Path 34326" d="M1919.757,792.739c.019.9-1.349,10.324-.774,10.445,0,0,15.635,3.6,23.971,0l.034-9.71,4.423-27.038s1.525-4.063-1.523-6.095l-11.619-5.462.784,2.355-1.679,4.777-7.3-7.132-13.969,8.256q1.17,3.878,2.336,7.762c1.441,4.768,2.842,9.551,4.325,14.305C1918.767,785.2,1919.994,790.36,1919.757,792.739Z" transform="translate(-1910.295 -725.796)" fill="#bbd9f0" />
            <path id="Path_34327" data-name="Path 34327" d="M2208.455,781.865l-.1,4.354-1.6-1.568Z" transform="translate(-2186.386 -751.083)" fill="#2ca0bb" />
            <g id="Group_14130" data-name="Group 14130" transform="translate(22.868 37.182)">
              <path id="Path_34328" data-name="Path 34328" d="M2247.686,884.088a.621.621,0,1,1-.622-.62A.621.621,0,0,1,2247.686,884.088Z" transform="translate(-2246.443 -883.467)" fill="#c5e6e0" />
            </g>
            <g id="Group_14131" data-name="Group 14131" transform="translate(22.868 48.46)">
              <path id="Path_34329" data-name="Path 34329" d="M2247.686,1063.156a.621.621,0,1,1-.622-.621A.621.621,0,0,1,2247.686,1063.156Z" transform="translate(-2246.443 -1062.535)" fill="#c5e6e0" />
            </g>
            <g id="Group_14132" data-name="Group 14132" transform="translate(22.868 59.739)">
              <path id="Path_34330" data-name="Path 34330" d="M2247.686,1242.224a.621.621,0,1,1-.622-.62A.621.621,0,0,1,2247.686,1242.224Z" transform="translate(-2246.443 -1241.604)" fill="#c5e6e0" />
            </g>
            <g id="Group_14133" data-name="Group 14133" transform="translate(22.868 71.017)">
              <path id="Path_34331" data-name="Path 34331" d="M2247.686,1421.3a.621.621,0,1,1-.622-.621A.621.621,0,0,1,2247.686,1421.3Z" transform="translate(-2246.443 -1420.678)" fill="#c5e6e0" />
            </g>
            <line id="Line_2527" data-name="Line 2527" y2="42.804" transform="translate(22.069 35.632)" fill="none" stroke="#5fc5dc" strokeMiterlimit="10" strokeWidth="0.34" />
            <g id="Group_14135" data-name="Group 14135" transform="translate(8.06 75.381)">
              <path id="Path_34332" data-name="Path 34332" d="M2035.838,1492.857s-7.984,3.74-24.323-.082c-.268-.064-.155-.761-.155-.761l.155-1.574c.072-.5.353-.48.353-.48s15.634,3.6,23.97,0c0,0,.337-.059.352.445v1.961A.518.518,0,0,1,2035.838,1492.857Z" transform="translate(-2011.336 -1489.958)" fill="#371608" />
              <g id="Group_14134" data-name="Group 14134" transform="translate(12.331 1.344)">
                <path id="Path_34333" data-name="Path 34333" d="M2211.172,1514.033a.446.446,0,0,1-.441.452h-3.18a.446.446,0,0,1-.44-.452v-2.29a.446.446,0,0,1,.44-.452h3.18a.446.446,0,0,1,.441.452Z" transform="translate(-2207.111 -1511.291)" fill="none" stroke="#aeb0b3" strokeMiterlimit="10" strokeWidth="0.73" />
              </g>
            </g>
            <path id="Path_34334" data-name="Path 34334" d="M2117.022,721.211s-5.125-1.991-6.331-3.973l-1.515,2.886,5.4,5.182,2.443-4" transform="translate(-2094.954 -690.526)" fill="#bbd9f0" />
            <path id="Path_34335" data-name="Path 34335" d="M2017.9,306.469c2.066,6.229,5.129,8.47,5.129,8.47s-.843-4.741,1.663-4.092l.432-.024s-.862-2.779.924-4.15c-.847-1.089-.159-3.264.012-3.574.866-1.565,3.366-.9,4.828-1.312,1.131-.321,3.943-.8,4.592.569,4.91-.142,2.43-6.093,2.43-6.093a2.348,2.348,0,0,1-1.592,2.159c1.557-1.877-.531-5.3-.531-5.3-2.123,1.7-7.7,2.051-7.7,2.051-4.009-.126-5,2.442-5,2.442S2015.629,299.083,2017.9,306.469Z" transform="translate(-2009.022 -293.123)" fill="#4d1e05" />
            <g id="Group_14144" data-name="Group 14144" transform="translate(12.203 15.927)">
              <g id="Group_14140" data-name="Group 14140">
                <g id="Group_14139" data-name="Group 14139">
                  <g id="Group_14138" data-name="Group 14138">
                    <g id="Group_14137" data-name="Group 14137">
                      <g id="Group_14136" data-name="Group 14136">
                        <path id="Path_34336" data-name="Path 34336" d="M2079.98,549.837a1.4,1.4,0,0,1-.89.318,2.054,2.054,0,0,1-1.966-2.018,1.883,1.883,0,0,1,1.684-2.131,1.769,1.769,0,0,1,1.275.512,2.559,2.559,0,0,1,.482,2.065A1.926,1.926,0,0,1,2079.98,549.837Z" transform="translate(-2077.119 -546.006)" fill="#f5916e" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_14143" data-name="Group 14143" transform="translate(0.916 0.666)">
                <g id="Group_14141" data-name="Group 14141">
                  <path id="Path_34337" data-name="Path 34337" d="M2092.891,556.959c-.087-.6-1.276.032-1.078.362a1.836,1.836,0,0,0,.51.452.888.888,0,0,1,.4.641c.006.034.017.139-.017.165-.152.116-.695-.448-.814-.566a1.012,1.012,0,0,1-.192-.956.6.6,0,0,1,.648-.482c.273,0,.553.009.553.349,0,.007-.01.065-.015.034Z" transform="translate(-2091.661 -556.576)" fill="#e3755b" />
                </g>
                <g id="Group_14142" data-name="Group 14142" transform="translate(0.216 0.961)">
                  <path id="Path_34338" data-name="Path 34338" d="M2095.741,571.905c.12-.133.246-.034.381.055a.563.563,0,0,1,.234.345,1.406,1.406,0,0,1-.105,1.011.755.755,0,0,1-.636.379c-.367.005-.491-.313-.521-.623-.008-.082-.023-.279.055-.334s.154.074.215.129a.513.513,0,0,0,.315.1.662.662,0,0,0,.535-.268.365.365,0,0,0,.052-.1c.009.2,0,.25-.027.139a.513.513,0,0,0-.076-.14c-.09-.138-.239-.228-.338-.356-.049-.06-.144-.275-.083-.342Z" transform="translate(-2095.087 -571.841)" fill="#e3755b" />
                </g>
              </g>
            </g>
            <path id="Path_34339" data-name="Path 34339" d="M1915.555,830.743h13.661l.749-32.255-6.213-21.825-11.648,6.884,6.041,27.024Z" transform="translate(-1910.295 -746.207)" fill="#001431" />
            <path id="Path_34340" data-name="Path 34340" d="M2060.428,776.662l6.213,21.825-.027,1.036-7.825-8.01s-.822-1.39.291-2.212l1.849-1.769-1.319-.945s-1.849-.9-1.624-2.578l.53-6.32Z" transform="translate(-2046.97 -746.207)" fill="#001f41" />
            <path id="Path_34341" data-name="Path 34341" d="M2309.783,833.016l-1.059-22.254,2.327-23.113a4.42,4.42,0,0,0-3.2-5.234l-7.076-3.326-.991,21.084,2.452,32.844Z" transform="translate(-2273.554 -748.48)" fill="#001431" />
            <path id="Path_34342" data-name="Path 34342" d="M2261.086,733.8a6.521,6.521,0,0,0-2.783-3.362l.3,6.284S2259.888,733.5,2261.086,733.8Z" transform="translate(-2234.688 -702.891)" fill="#bbd9f0" />
            <path id="Path_34343" data-name="Path 34343" d="M2275.152,786.445l-.209-2.922c-.706-.173-1.337.816-1.851,1.625Z" transform="translate(-2248.546 -752.618)" fill="#2ca0bb" />
            <g id="Group_14145" data-name="Group 14145" transform="translate(20.087 31.978)">
              <path id="Path_34344" data-name="Path 34344" d="M2203.773,801.6l-.19-.353-1.288,1.978,1.107.4Z" transform="translate(-2202.295 -801.221)" fill="#003776" />
              <path id="Path_34345" data-name="Path 34345" d="M2273.092,801.4l.362-.553a1.487,1.487,0,0,1-.069,2.4A6.411,6.411,0,0,0,2273.092,801.4Z" transform="translate(-2268.633 -800.842)" fill="#003776" />
              <path id="Path_34346" data-name="Path 34346" d="M2220,806.852a2.837,2.837,0,0,0,2.981.175,2.234,2.234,0,0,1,.293,1.845l-.979,2.019h-1.367l-1.3-2.019A4.325,4.325,0,0,1,2220,806.852Z" transform="translate(-2218.519 -806.474)" fill="#004896" />
              <path id="Path_34347" data-name="Path 34347" d="M2224.049,870.982l-1.106,28.889,2.307,4.487,1.882-4.464-1.716-28.912Z" transform="translate(-2221.643 -866.565)" fill="#003776" />
            </g>
            <path id="Path_34348" data-name="Path 34348" d="M2301.028,779.088l1.824.859,1.318,6.085s.66,1.576-.3,1.987l-1.719,1,2.424,1.533a1.4,1.4,0,0,1,.49,1.976l-4.86,9.49-.153-1.622Z" transform="translate(-2273.81 -748.48)" fill="#001f41" />
            <path id="Path_34349" data-name="Path 34349" d="M1976.919,1121.962l-2.841-2.243a33.348,33.348,0,0,0-6.411-.358s-.707.982,4.687,1.132a26.411,26.411,0,0,0-5,2.108s-.2,1.118,3.263-.506l1.575-.116-3.66,1.827s.143.7,3.476-.21l3.341.367Z" transform="translate(-1962.065 -1067.259)" fill="#f5916e" />
            <path id="Path_34350" data-name="Path 34350" d="M2113.354,1135.709l-18.305-2.189a6.754,6.754,0,0,0-1.4,5.271l23.382,7.416s6.177,1.1,4.547-6.343A11.118,11.118,0,0,0,2113.354,1135.709Z" transform="translate(-2080.311 -1080.589)" fill="#00112d" />
            <path id="Path_34351" data-name="Path 34351" d="M2312.24,1115.394a22.218,22.218,0,0,1-4.523,1.913l-1.914-3.3,2.615-1.7,1.794-.413s2.948-.761,3.725,3.286Z" transform="translate(-2279.196 -1060.274)" fill="#f5916e" />
            <g id="Group_14146" data-name="Group 14146" transform="translate(0.154 53.153)">
              <path id="Path_34352" data-name="Path 34352" d="M1885.975,1145.413c-1.106,6.688,3.758,5,3.758,5l24.8-8.927c-.7-3.843-2.273-4.444-2.273-4.444l-21.213,4.048S1887.06,1142.343,1885.975,1145.413Z" transform="translate(-1885.812 -1137.041)" fill="#001f41" />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_14165" data-name="Group 14165" transform="translate(86.751 8.304)">
        <path id="Path_34353" data-name="Path 34353" d="M1758.964,1457.581h17.115c-.748-4.889-1.8-9.2-3.3-11.933,0,0-16.521-.816-14.036,10.38C1758.747,1456.027,1758.815,1456.591,1758.964,1457.581Z" transform="translate(-1747.646 -1381.35)" fill="#df7e61" />
        <path id="Path_34354" data-name="Path 34354" d="M1825.484,478.856c.334,4.158,1.125,8.242,3.4,11.842a13.79,13.79,0,0,0,2.153,2.868c-3.4.722-12.344.7-13.708-4.383a34.811,34.811,0,0,1-.968-10.223c.107-3.1.177-6.555,1.49-9.417,1.2-2.6,5.416-2.569,6.983-.185.761,1.157.335,2.58.377,3.891C1825.275,475.109,1825.334,476.99,1825.484,478.856Z" transform="translate(-1801.844 -464.897)" fill="#491214" />
        <path id="Path_34355" data-name="Path 34355" d="M1903.825,838.247c2.784-.077,4.311,2.4,5.18,4.9.916,2.63-1.438,9.965-3.558,8.159-4.167-3.548-6.246-5.684-6.325-8.467S1901.042,838.324,1903.825,838.247Z" transform="translate(-1879.416 -812.216)" fill="#df7e61" />
        <path id="Path_34356" data-name="Path 34356" d="M1639.153,1218.122c.639-.116,3.273,1.244,3.273,1.244s3.29,10.572,3.431,12.181-10.914,9.929-23.265,5.19c0,0,5.717-14.889,6.068-18.555S1639.153,1218.122,1639.153,1218.122Z" transform="translate(-1620.305 -1166.681)" fill="#e18160" />
        <path id="Path_34357" data-name="Path 34357" d="M1630.52,1243.844s3.153,9.534,3.431,12.181c.985,9.366-11.69,13.295-24.041,8.556,0,0-.8-12.878,6.844-21.921C1616.754,1242.66,1618.785,1244.512,1630.52,1243.844Z" transform="translate(-1608.399 -1191.16)" fill="#0a0f15" />
        <path id="Path_34358" data-name="Path 34358" d="M1611.66,1623.942l-1.392-.764.04.764Z" transform="translate(-1608.757 -1547.711)" fill="#f5916e" />
        <path id="Path_34359" data-name="Path 34359" d="M1626.987,1484.25c.958-8.11-5.81-12.316-13.16-9.034,0,0-4.231,1.225-3.413,9.034Z" transform="translate(-1608.795 -1408.019)" fill="#f5916e" />
        <path id="Path_34360" data-name="Path 34360" d="M1761.268,644.966h8.6c-.391-1.252-1.2-4.492.053-6.7l-8.686-5.7S1762.777,642.136,1761.268,644.966Z" transform="translate(-1750.213 -619.491)" fill="#f5916e" />
        <path id="Path_34361" data-name="Path 34361" d="M1769.248,641.207a5.593,5.593,0,0,1,.669-2.939l-8.686-5.7a61.679,61.679,0,0,1,.693,7.458C1765.061,641.021,1766.352,641.407,1769.248,641.207Z" transform="translate(-1750.213 -619.491)" fill="#d9705a" />
        <path id="Path_34362" data-name="Path 34362" d="M1633.993,821.157c-4.023,1.924-1.863,6.371-1.863,6.371l5.23,19.032s4.886,2,13.46,1c1.191-1.7,3.638-8.845,4.458-14.3.675-4.49,2.773-8.914.488-11.417-.876-.96-7.547-1.918-7.582-2.075l-7.877-.033Z" transform="translate(-1628.7 -794.873)" fill="#f5916e" />
        <g id="Group_14161" data-name="Group 14161" transform="translate(6.509 0)">
          <path id="Path_34363" data-name="Path 34363" d="M1739.032,452.842l.521-1.268,1.3-3.742a3.835,3.835,0,0,0,.123-2.016,6.11,6.11,0,0,1-.017-3.162c.883-3.42-.559-5.3-.559-5.3-1.857-3.282-6.619-3.508-6.619-3.508-5.9.007-6.672,5.683-6.672,5.683-1.14,3.745,1.725,7.047,1.731,7.053a4.2,4.2,0,0,1,.53,1.568,4.752,4.752,0,0,0,.79,1.484,10.594,10.594,0,0,0,2.9,2.577,11.662,11.662,0,0,0,4.484,1.3C1738.143,453.532,1738.734,453.569,1739.032,452.842Z" transform="translate(-1724.5 -433.284)" fill="#f5916e" />
          <path id="Path_34364" data-name="Path 34364" d="M1694.515,440.5a11.019,11.019,0,0,1,.166-2.522c.1-.783,1.557.989,1.578-.035.091-4.692,1.907-6.835,2.591-7.162a8.25,8.25,0,0,1,5.275-.725c2.349.541,2.1,3.69,2.1,3.69,2.6-11.442-10.458-8.407-10.458-8.407a7.146,7.146,0,0,0-6.008,7.27c-1.091,6.306,4.971,10.277,4.971,10.277Z" transform="translate(-1689.625 -424.975)" fill="#491214" />
          <g id="Group_14158" data-name="Group 14158" transform="translate(2.923 11.074)">
            <g id="Group_14154" data-name="Group 14154">
              <g id="Group_14153" data-name="Group 14153">
                <g id="Group_14152" data-name="Group 14152">
                  <g id="Group_14151" data-name="Group 14151">
                    <g id="Group_14150" data-name="Group 14150">
                      <path id="Path_34365" data-name="Path 34365" d="M1738.465,604.357a1.263,1.263,0,0,1-.83.207,1.856,1.856,0,0,1-1.594-1.99,1.7,1.7,0,0,1,1.7-1.772,1.6,1.6,0,0,1,1.1.573,2.314,2.314,0,0,1,.252,1.9A1.745,1.745,0,0,1,1738.465,604.357Z" transform="translate(-1736.041 -600.798)" fill="#f68c68" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_14157" data-name="Group 14157" transform="translate(0.876 0.59)">
              <g id="Group_14155" data-name="Group 14155">
                <path id="Path_34366" data-name="Path 34366" d="M1751.092,610.559c-.026-.548-1.155-.083-1,.232a1.683,1.683,0,0,0,.42.452.8.8,0,0,1,.309.613c0,.03,0,.126-.03.146-.147.092-.588-.463-.684-.581a.91.91,0,0,1-.088-.878.545.545,0,0,1,.624-.377c.248.027.5.057.469.364,0,.006-.015.057-.016.029Z" transform="translate(-1749.943 -610.161)" fill="#e3755b" />
              </g>
              <g id="Group_14156" data-name="Group 14156" transform="translate(0.083 0.898)">
                <path id="Path_34367" data-name="Path 34367" d="M1751.938,624.468c.121-.108.226-.007.338.082a.518.518,0,0,1,.182.331,1.283,1.283,0,0,1-.183.9.688.688,0,0,1-.607.284c-.332-.026-.415-.324-.414-.606,0-.074,0-.253.078-.3s.133.081.183.136a.467.467,0,0,0,.276.12.6.6,0,0,0,.5-.2.282.282,0,0,0,.054-.083c-.01.184-.021.224-.035.122a.512.512,0,0,0-.056-.134c-.07-.131-.2-.224-.275-.35-.038-.057-.106-.259-.045-.315Z" transform="translate(-1751.254 -624.421)" fill="#e3755b" />
              </g>
            </g>
          </g>
          <g id="Group_14160" data-name="Group 14160" transform="translate(8.844 8.441)">
            <path id="Path_34368" data-name="Path 34368" d="M1848.695,589.281c.015.328-.153.6-.376.612s-.414-.247-.429-.575.154-.6.376-.612S1848.679,588.954,1848.695,589.281Z" transform="translate(-1846.765 -586.835)" fill="#2d1a10" />
            <path id="Path_34369" data-name="Path 34369" d="M1921.1,582.879c0,.329-.188.592-.411.589s-.4-.272-.395-.6.188-.591.411-.587S1921.1,582.551,1921.1,582.879Z" transform="translate(-1914.61 -580.814)" fill="#2d1a10" />
            <path id="Path_34370" data-name="Path 34370" d="M1830.049,566.947c.877-1.145,3.179-.7,3.179-.7s.135,0,.119-.41c0,0,.008-.361-.283-.341C1833.064,565.5,1830.242,565.019,1830.049,566.947Z" transform="translate(-1830.049 -565.04)" fill="#491214" />
            <path id="Path_34371" data-name="Path 34371" d="M1911.714,559.764c.005-1.267-2.437-.592-2.437-.592-.29,0-.262.357-.262.357.009.41.143.4.143.4s2.212-.6,2.523-.074C1911.776,559.6,1911.658,559.9,1911.714,559.764Z" transform="translate(-1904.042 -558.992)" fill="#491214" />
            <path id="Path_34372" data-name="Path 34372" d="M1884.2,614.473c1.7-.036,1.812-.475,1.812-.475l-.8-1.826S1885.334,613.963,1884.2,614.473Z" transform="translate(-1880.794 -608.823)" fill="#e3755b" />
            <g id="Group_14159" data-name="Group 14159" transform="translate(2.502 6.402)">
              <path id="Path_34373" data-name="Path 34373" d="M1869.923,660.853l3.247-.211a.189.189,0,0,1,.2.276s-.631,1.659-1.684,1.725-1.885-1.506-1.885-1.506A.18.18,0,0,1,1869.923,660.853Z" transform="translate(-1869.771 -660.641)" fill="#8b3231" />
              <path id="Path_34374" data-name="Path 34374" d="M1882.491,673.1a1.39,1.39,0,0,1-2.442.146A1.7,1.7,0,0,1,1882.491,673.1Z" transform="translate(-1879.401 -671.903)" fill="#c84847" />
              <path id="Path_34375" data-name="Path 34375" d="M1871,660.873a.267.267,0,0,1,.031-.015l3.247-.212a.3.3,0,0,1,.089.013c.1.141,0,.4-.253.575a7.572,7.572,0,0,1-2.818.179C1871,661.158,1870.877,660.948,1871,660.873Z" transform="translate(-1870.877 -660.646)" fill="#fff" />
            </g>
          </g>
          <path id="Path_34376" data-name="Path 34376" d="M1758.464,651.607a.559.559,0,1,1-.531-.587A.559.559,0,0,1,1758.464,651.607Z" transform="translate(-1753.082 -636.782)" fill="#fff" />
        </g>
        <path id="Path_34377" data-name="Path 34377" d="M1926.681,845.014s2.11,0,3.306,5.343c0,0,5.853,17.769,2.1,18.8,0,0-5.6-1.1-10.88-15.255C1921.206,853.9,1920.724,845.352,1926.681,845.014Z" transform="translate(-1900.103 -818.558)" fill="#7f86af" />
        <path id="Path_34378" data-name="Path 34378" d="M1933.092,827.668l3.709.666s2.7.591,2.793,2.906c0,0,.151,4.384-2.235,12.312C1937.36,843.553,1933.413,831.387,1933.092,827.668Z" transform="translate(-1911.249 -802.305)" fill="#7f86af" />
        <path id="Path_34379" data-name="Path 34379" d="M1682.655,820.576l1.672.257a43,43,0,0,1,4.01,12.914c-.82,5.452-2.464,13.707-4.458,14.3-6.759,2-13.815-1.143-13.815-1.143l-3.232-12.907c8.682-3.38,4.3-13.3,4.3-13.3l1.823-.437,7.672,7.757,2.032-7.435" transform="translate(-1661.759 -795.358)" fill="#7f86af" />
        <path id="Path_34380" data-name="Path 34380" d="M1950.188,893.324s3.392,4.7,3.983,7.119c.389,1.591-2.385,5.174-4.624,5.806C1948.324,906.594,1948.295,893.64,1950.188,893.324Z" transform="translate(-1925.862 -863.826)" fill="#3797be" />
        <path id="Path_34381" data-name="Path 34381" d="M1950.188,893.324s3.392,4.7,3.983,7.119c.389,1.591-2.385,5.174-4.624,5.806C1948.324,906.594,1948.295,893.64,1950.188,893.324Z" transform="translate(-1925.862 -863.826)" fill="#0a0f15" />
        <g id="Group_14162" data-name="Group 14162" transform="translate(5.073 24.896)">
          <path id="Path_34382" data-name="Path 34382" d="M1684.584,820.833a45.712,45.712,0,0,1,3.857,12.954c-.821,5.451-2.567,13.667-4.562,14.257-6.759,2-13.815-1.143-13.815-1.143l-3.232-12.907c8.682-3.38,4.3-13.3,4.3-13.3l1.823-.437,8.117,11.6,1.588-11.283Z" transform="translate(-1666.832 -820.254)" fill="#0a0f15" />
          <path id="Path_34383" data-name="Path 34383" d="M1751.915,820.254l8.118,11.6h0l-5.839-1.58s-.848-.719-.31-1.5l.8-1.567-1.07-.318s-1.4-.166-1.637-1.31l-.876-5.132Z" transform="translate(-1745.797 -820.254)" fill="#182028" />
          <path id="Path_34384" data-name="Path 34384" d="M1894.411,825.629l-1.559,11.266h0l3.172-2.946s1.3-1.061.083-1.591l-1.51-.469,1.772-1.593a1.465,1.465,0,0,0,0-1.4l-1.065-3.164Z" transform="translate(-1878.616 -825.29)" fill="#182028" />
        </g>
        <g id="Group_14163" data-name="Group 14163" transform="translate(9.368 22.985)">
          <path id="Path_34385" data-name="Path 34385" d="M1842.165,854.559l-.1,4.353-1.6-1.567Z" transform="translate(-1833.824 -850.487)" fill="#566e9c" />
          <path id="Path_34386" data-name="Path 34386" d="M1743.363,793.892s-4.862-1.991-6.069-3.974l-2.273,2.347,5.9,5.722,2.443-4" transform="translate(-1735.022 -789.918)" fill="#bfb9d1" />
        </g>
        <path id="Path_34387" data-name="Path 34387" d="M1894.79,806.479a6.517,6.517,0,0,0-2.782-3.364l.3,6.284S1893.592,806.185,1894.79,806.479Z" transform="translate(-1872.752 -779.298)" fill="#bfb9d1" />
        <path id="Path_34388" data-name="Path 34388" d="M1908.863,859.142l-.21-2.921c-.706-.173-1.336.814-1.85,1.624Z" transform="translate(-1886.615 -829.042)" fill="#566e9c" />
        <path id="Path_34389" data-name="Path 34389" d="M1634.6,1442.041c-.919-5.138-2.13-9.843-3.713-12.763,0,0-22.463-2.078-23.511,6.318l-.273,6.445Z" transform="translate(-1605.794 -1365.81)" fill="#0a0f15" />
        <path id="Path_34390" data-name="Path 34390" d="M1589.814,828.531a8.962,8.962,0,0,0-1.629,3.638s-3.559,13.01-.94,19.621a5.346,5.346,0,0,0,3.059,1.94l7-16.834s2.984-7.049-1.756-10.694l-3.97,1.014A4.27,4.27,0,0,0,1589.814,828.531Z" transform="translate(-1586.283 -800.932)" fill="#7f86af" />
        <path id="Path_34391" data-name="Path 34391" d="M1614.972,1051.534l-2.5-1.974a29.287,29.287,0,0,0-5.639-.313s-.622.863,4.123,1a23.218,23.218,0,0,0-4.4,1.854s-.171.984,2.872-.446l1.384-.1-3.219,1.607s.125.612,3.057-.184l2.939.322Z" transform="translate(-1605.281 -1009.872)" fill="#f5916e" />
        <path id="Path_34392" data-name="Path 34392" d="M1734.983,1063.625l-16.1-1.926a5.938,5.938,0,0,0-1.236,4.637l20.567,6.523s5.434.964,4-5.58A9.78,9.78,0,0,0,1734.983,1063.625Z" transform="translate(-1709.294 -1021.596)" fill="#7f86af" />
        <path id="Path_34393" data-name="Path 34393" d="M1909.929,1045.758a19.487,19.487,0,0,1-3.978,1.683l-1.683-2.9,2.3-1.491,1.577-.363s2.594-.67,3.277,2.89Z" transform="translate(-1884.24 -1003.729)" fill="#f5916e" />
        <g id="Group_14164" data-name="Group 14164" transform="translate(0.845 40.298)">
          <path id="Path_34394" data-name="Path 34394" d="M1603.18,1077.381s-4.411.4-3.3-4.4c.954-2.7,4.465-3.8,4.465-3.8l14.54-4.389s1.383.529,2,3.91C1620.879,1068.7,1608.163,1077.509,1603.18,1077.381Z" transform="translate(-1599.698 -1064.791)" fill="#8b92b5" />
        </g>
        <path id="Path_34395" data-name="Path 34395" d="M1646.675,465.959c-.536,4.138-.61,8.3.871,12.291a13.756,13.756,0,0,0,1.511,3.253c-3.474,0-12.22-1.881-12.5-7.134a34.813,34.813,0,0,1,1.176-10.2c.747-3.007,1.534-6.376,3.413-8.9,1.71-2.3,5.83-1.388,6.869,1.27.5,1.29-.208,2.592-.438,3.884C1647.249,462.25,1646.916,464.1,1646.675,465.959Z" transform="translate(-1633.357 -452.128)" fill="#491214" />
      </g>
      <g id="Group_14192" data-name="Group 14192" transform="translate(51.367 2.268)">
        <g id="Group_14191" data-name="Group 14191" transform="translate(0 0)">
          <g id="Group_14168" data-name="Group 14168" transform="translate(29.523 32.161)">
            <g id="Group_14166" data-name="Group 14166" transform="translate(5.661 27.198)">
              <path id="Path_34396" data-name="Path 34396" d="M1590.7,1294.489c.156-6.727-.587-21.156-1.409-22.488-.971-1.575-6.167,1.781-6.167,1.781s3.57,14.07,4.835,20.707Z" transform="translate(-1583.121 -1271.58)" fill="#df7e61" />
            </g>
            <g id="Group_14167" data-name="Group 14167">
              <path id="Path_34397" data-name="Path 34397" d="M1508.262,946.379l1.567.511s2.483,19.312.383,23.626-4.52-.021-5.308-.51-5.812-22.361-5.812-22.361Z" transform="translate(-1498.723 -939.664)" fill="#df7e61" />
              <path id="Path_34398" data-name="Path 34398" d="M1506.147,868.37l-1-24.193c-1.135-5.939-7.216-4.171-7.216-4.171-4.93,1.283-4.693,6.809-4.693,6.809l4.24,23.173s.888,3.544,5.45,3.544C1502.925,873.532,1506.291,873.079,1506.147,868.37Z" transform="translate(-1493.233 -839.76)" fill="#0e161e" />
            </g>
            <path id="Path_34399" data-name="Path 34399" d="M1570.7,1289.241H1576c-.023-6.2-.232-18.43-1.461-23.262,0,0-8.386.175-7.782,4.2A185.161,185.161,0,0,0,1570.7,1289.241Z" transform="translate(-1562.097 -1239.134)" fill="#0e161e" />
          </g>
          <path id="Path_34400" data-name="Path 34400" d="M1138.038,800.116l4.424-27.036s1.525-4.064-1.524-6.1l-11.618-5.46h-8.194l-13.969,8.255,9.156,30.338Z" transform="translate(-1101.949 -734.289)" fill="#f5916e" />
          <path id="Path_34401" data-name="Path 34401" d="M1229.981,1390.714l.029-16.441h-21.726s-2.2,5.015-2.974,16.441Z" transform="translate(-1193.921 -1308.446)" fill="#001c3e" />
          <g id="Group_14169" data-name="Group 14169" transform="translate(11.623 76.725)">
            <path id="Path_34402" data-name="Path 34402" d="M1223.767,1552.845a7.675,7.675,0,0,0-14.746,0Z" transform="translate(-1209.021 -1547.302)" fill="#0d141c" />
            <path id="Path_34403" data-name="Path 34403" d="M1316.756,1634.295a6.961,6.961,0,0,0-1.946,0Z" transform="translate(-1308.147 -1628.752)" fill="#0d141c" />
          </g>
          <g id="Group_14170" data-name="Group 14170" transform="translate(22.668 77.381)">
            <path id="Path_34404" data-name="Path 34404" d="M1397.252,1562.6c-.952-3.105-3.1-5.2-6.515-4.85a7.712,7.712,0,0,0-6.348,4.85Z" transform="translate(-1384.389 -1557.713)" fill="#0d141c" />
          </g>
          <g id="Group_14171" data-name="Group 14171" transform="translate(0 34.622)">
            <path id="Path_34405" data-name="Path 34405" d="M1041.695,1340.268c-.159-3.589-.17-7.391.973-18.85,0,0-5.032-2.332-6.117.192-.507,1.182.457,11.839,1.579,18.658Z" transform="translate(-1035.66 -1292.622)" fill="#f5916e" />
            <path id="Path_34406" data-name="Path 34406" d="M1044.59,897.58a6.14,6.14,0,0,0-7.235,3.756s-2.708,16.592-2.675,23.7c.022,4.8,5.876.934,6.261.091.847-1.861,7-21.847,7-21.847S1048.261,898.587,1044.59,897.58Z" transform="translate(-1034.037 -896.216)" fill="#f5916e" />
            <path id="Path_34407" data-name="Path 34407" d="M1035.133,908.011l6.994-22.675c-.157-5.636-5.673-6.477-5.673-6.477a5.651,5.651,0,0,0-6.323,3.966l-2.509,22.7s-.851,5.1,2.1,5.176C1029.72,910.7,1034.666,911.237,1035.133,908.011Z" transform="translate(-1027.318 -878.821)" fill="#131c24" />
            <path id="Path_34408" data-name="Path 34408" d="M1031.7,1307.83c.4-6.683,1.024-19.832,1.024-19.832-2.379-4.142-7.935-1.11-7.935-1.11-.991,4.165.665,15.016,1.706,20.942Z" transform="translate(-1024.484 -1260.184)" fill="#131c24" />
          </g>
          <path id="Path_34409" data-name="Path 34409" d="M1328.275,666.7v8.759h9.263a12.157,12.157,0,0,1-1.139-8.759Z" transform="translate(-1309.141 -645.436)" fill="#f5916e" />
          <path id="Path_34410" data-name="Path 34410" d="M1332.974,674.136c.835.537,2.609,1.68,3.622,1.011a.956.956,0,0,0,.439-.68,12.833,12.833,0,0,1-.635-7.77h-8.124v.794C1329.289,670.055,1330.589,672.6,1332.974,674.136Z" transform="translate(-1309.141 -645.436)" fill="#d9705a" />
          <g id="Group_14172" data-name="Group 14172" transform="translate(13.44 3.538)">
            <path id="Path_34411" data-name="Path 34411" d="M1243.347,403.161s-1.576-.685-3.361-4.586c0,0-3.875-4.612-1.129-9.85,0,0,1.623-4.43,9.487-3.19C1248.344,385.536,1249.051,397.406,1243.347,403.161Z" transform="translate(-1237.873 -385.315)" fill="#f68c68" />
            <path id="Path_34412" data-name="Path 34412" d="M1279.69,405.512l.525-1.372,1.447-5.112a5.291,5.291,0,0,0,.075-2.162,5.308,5.308,0,0,1-.021-2.227,6.452,6.452,0,0,0-.97-5.761c-2.075-3.466-7.183-3.581-7.183-3.581-6.324.164-7,6.265-7,6.265-1.12,4.043,2.036,7.5,2.042,7.511.252.275.088,1.611.156,1.993.3,1.631,1.826,2.664,3.186,3.383a15.173,15.173,0,0,0,4.991,1.829C1277.58,406.353,1279.39,406.3,1279.69,405.512Z" transform="translate(-1264.533 -385.297)" fill="#f5916e" />
          </g>
          <path id="Path_34413" data-name="Path 34413" d="M1432.72,547.725l-1.761.534s2.3.313,2.437-.35c0,0-.254-1.335-.54-2.6C1432.855,545.307,1432.98,547.645,1432.72,547.725Z" transform="translate(-1405.358 -531.691)" fill="#e3755b" />
          <g id="Group_14173" data-name="Group 14173" transform="translate(22.161 11.04)">
            <path id="Path_34414" data-name="Path 34414" d="M1401.066,531.574c.017.362-.171.663-.417.674s-.458-.272-.473-.635.171-.664.416-.675S1401.051,531.213,1401.066,531.574Z" transform="translate(-1398.674 -529.268)" fill="#2d1a10" />
            <path id="Path_34415" data-name="Path 34415" d="M1480.064,528.63c.018.362-.168.664-.414.676s-.459-.271-.476-.634.168-.665.414-.677S1480.048,528.268,1480.064,528.63Z" transform="translate(-1472.697 -526.51)" fill="#2d1a10" />
            <path id="Path_34416" data-name="Path 34416" d="M1379.288,510.3c.158.045.212-.167.212-.167a.742.742,0,0,0,.017-.384.365.365,0,0,0-.174-.206,5.414,5.414,0,0,0-1.91.107c-.383.084-1.012.32-1.077.662-.02.1-.042.28.073.32A15.4,15.4,0,0,1,1379.288,510.3Z" transform="translate(-1376.34 -509.175)" fill="#cc753c" />
            <path id="Path_34417" data-name="Path 34417" d="M1470.169,505.208c-.45-.026-1.638.163-2.617.273-.151.064-.231-.138-.232-.138a.754.754,0,0,1-.066-.38.368.368,0,0,1,.148-.227,6.523,6.523,0,0,1,2.169-.31.859.859,0,0,1,.648.323S1470.3,504.7,1470.169,505.208Z" transform="translate(-1461.524 -504.417)" fill="#cc753c" />
          </g>
          <path id="Path_34418" data-name="Path 34418" d="M1114.808,799.382c.019.9-1.349,10.324-.774,10.446,0,0,15.634,3.6,23.97,0l.033-9.712,4.424-27.036s1.525-4.064-1.524-6.1l-11.618-5.46.784,2.355-1.68,4.778-7.3-7.133-13.969,8.255q1.171,3.88,2.338,7.761c1.44,4.768,2.841,9.551,4.325,14.307C1113.819,791.847,1115.046,797,1114.808,799.382Z" transform="translate(-1101.949 -734.289)" fill="#bbd9f0" />
          <path id="Path_34419" data-name="Path 34419" d="M1403.524,788.51l-.1,4.353-1.6-1.567Z" transform="translate(-1378.058 -759.576)" fill="#5392bf" />
          <g id="Group_14174" data-name="Group 14174" transform="translate(26.265 35.332)">
            <path id="Path_34420" data-name="Path 34420" d="M1442.742,890.719a.621.621,0,1,1-.621-.621A.621.621,0,0,1,1442.742,890.719Z" transform="translate(-1441.5 -890.098)" fill="#c5e6e0" />
          </g>
          <g id="Group_14175" data-name="Group 14175" transform="translate(26.265 46.611)">
            <path id="Path_34421" data-name="Path 34421" d="M1442.742,1069.793a.621.621,0,1,1-.621-.621A.621.621,0,0,1,1442.742,1069.793Z" transform="translate(-1441.5 -1069.172)" fill="#c5e6e0" />
          </g>
          <g id="Group_14176" data-name="Group 14176" transform="translate(26.265 57.889)">
            <path id="Path_34422" data-name="Path 34422" d="M1442.742,1248.856a.621.621,0,1,1-.621-.621A.621.621,0,0,1,1442.742,1248.856Z" transform="translate(-1441.5 -1248.234)" fill="#c5e6e0" />
          </g>
          <g id="Group_14177" data-name="Group 14177" transform="translate(26.265 69.167)">
            <path id="Path_34423" data-name="Path 34423" d="M1442.742,1427.927a.621.621,0,1,1-.621-.622A.621.621,0,0,1,1442.742,1427.927Z" transform="translate(-1441.5 -1427.305)" fill="#c5e6e0" />
          </g>
          <line id="Line_2529" data-name="Line 2529" y2="42.804" transform="translate(25.466 33.782)" fill="none" stroke="#5392bf" strokeMiterlimit="10" strokeWidth="0.34" />
          <g id="Group_14179" data-name="Group 14179" transform="translate(11.457 73.532)">
            <path id="Path_34424" data-name="Path 34424" d="M1230.9,1499.505s-7.984,3.741-24.323-.081c-.268-.063-.155-.761-.155-.761l.155-1.573c.072-.5.353-.479.353-.479s15.634,3.6,23.97,0c0,0,.337-.06.351.445v1.961A.516.516,0,0,1,1230.9,1499.505Z" transform="translate(-1206.396 -1496.608)" fill="#371608" />
            <g id="Group_14178" data-name="Group 14178" transform="translate(12.331 1.343)">
              <path id="Path_34425" data-name="Path 34425" d="M1406.231,1520.677a.446.446,0,0,1-.441.451h-3.179a.446.446,0,0,1-.44-.451v-2.29a.447.447,0,0,1,.44-.451h3.179a.446.446,0,0,1,.441.451Z" transform="translate(-1402.17 -1517.936)" fill="none" stroke="#aeb0b3" strokeMiterlimit="10" strokeWidth="0.73" />
            </g>
          </g>
          <path id="Path_34426" data-name="Path 34426" d="M1312.086,727.851s-5.126-1.992-6.332-3.974l-1.514,2.887,5.4,5.182,2.443-4" transform="translate(-1286.62 -699.014)" fill="#bbd9f0" />
          <path id="Path_34427" data-name="Path 34427" d="M1223.873,335.94a7.339,7.339,0,0,1,3.2-.288c1.037.189,1.622,1.579,2.692.874a6.45,6.45,0,0,0,1.532-1.343c.417-.663-.486-1.906-1.1-2.13-.691-.251-.623-.478-.964-1.156-.29-.577-.36-1.109-1.073-1.3-.682-.187-1.337.267-2,.168-1.146-.169-1.362-1.405-2.566-1.617-1.329-.233-2.033,2-3.348,1.909-1.139-.076-1.876-1.071-3.111-.833-1.274.245-1.6,1.609-2.753,2.007-1.176.405-2.474.19-2.826,1.714-.286,1.24.258,2.085-.623,3.143-.977,1.176-.623,2.4-.1,3.639a29.6,29.6,0,0,0,3.323,6.38,12.271,12.271,0,0,0,1.342,1.538c.083.079.5.345.519.454,0,0-.841-4.74,1.664-4.091l.433-.025s-.862-2.779.923-4.151c-.847-1.089-.159-3.262.013-3.573C1219.911,335.687,1222.41,336.355,1223.873,335.94Z" transform="translate(-1198.61 -329.126)" fill="#cc753c" />
          <g id="Group_14188" data-name="Group 14188" transform="translate(15.602 14.079)">
            <g id="Group_14184" data-name="Group 14184">
              <g id="Group_14183" data-name="Group 14183">
                <g id="Group_14182" data-name="Group 14182">
                  <g id="Group_14181" data-name="Group 14181">
                    <g id="Group_14180" data-name="Group 14180">
                      <path id="Path_34428" data-name="Path 34428" d="M1275.057,556.485a1.4,1.4,0,0,1-.891.317,2.05,2.05,0,0,1-1.964-2.018,1.88,1.88,0,0,1,1.682-2.13,1.774,1.774,0,0,1,1.275.511,2.561,2.561,0,0,1,.482,2.065A1.929,1.929,0,0,1,1275.057,556.485Z" transform="translate(-1272.196 -552.654)" fill="#f5916e" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_14187" data-name="Group 14187" transform="translate(0.915 0.665)">
              <g id="Group_14185" data-name="Group 14185">
                <path id="Path_34429" data-name="Path 34429" d="M1287.949,563.592c-.087-.6-1.276.032-1.077.363a1.858,1.858,0,0,0,.51.451.893.893,0,0,1,.4.641c.006.035.017.139-.016.166-.152.116-.7-.448-.814-.567a1.006,1.006,0,0,1-.192-.955.6.6,0,0,1,.647-.483c.273,0,.554.008.552.35,0,.008-.009.065-.014.034Z" transform="translate(-1286.719 -563.208)" fill="#e3755b" />
              </g>
              <g id="Group_14186" data-name="Group 14186" transform="translate(0.216 0.962)">
                <path id="Path_34430" data-name="Path 34430" d="M1290.8,578.547c.12-.134.246-.034.381.054a.566.566,0,0,1,.235.344,1.415,1.415,0,0,1-.106,1.011.757.757,0,0,1-.636.378c-.367.006-.49-.313-.52-.623-.007-.081-.023-.279.057-.334s.154.074.215.13a.511.511,0,0,0,.315.1.658.658,0,0,0,.535-.269.343.343,0,0,0,.051-.1c.009.2,0,.25-.026.138a.546.546,0,0,0-.076-.139c-.091-.137-.239-.227-.339-.356-.049-.059-.143-.273-.083-.341Z" transform="translate(-1290.148 -578.482)" fill="#e3755b" />
              </g>
            </g>
          </g>
          <path id="Path_34431" data-name="Path 34431" d="M1110.66,836.971h13.617l.74-31.837-6.212-21.825-11.648,6.883,6.041,27.026Z" transform="translate(-1101.949 -754.703)" fill="#131c24" />
          <path id="Path_34432" data-name="Path 34432" d="M1255.492,783.309l6.212,21.824-.026,1.036-7.825-8.009s-.822-1.39.291-2.212l1.849-1.769-1.318-.945s-1.849-.9-1.625-2.578l.53-6.321Z" transform="translate(-1238.637 -754.703)" fill="#1e2e35" />
          <path id="Path_34433" data-name="Path 34433" d="M1504.813,839.245l-1.039-21.836L1506.1,794.3a4.42,4.42,0,0,0-3.2-5.235l-7.075-3.326-.991,21.084,2.422,32.425Z" transform="translate(-1465.208 -756.977)" fill="#131c24" />
          <path id="Path_34434" data-name="Path 34434" d="M1456.141,740.427a6.52,6.52,0,0,0-2.782-3.364l.3,6.285S1454.944,740.133,1456.141,740.427Z" transform="translate(-1426.347 -711.37)" fill="#bbd9f0" />
          <path id="Path_34435" data-name="Path 34435" d="M1470.216,793.095l-.21-2.921c-.706-.173-1.337.815-1.85,1.624Z" transform="translate(-1440.212 -761.117)" fill="#5392bf" />
          <g id="Group_14189" data-name="Group 14189" transform="translate(23.484 30.128)">
            <path id="Path_34436" data-name="Path 34436" d="M1398.816,808.249l-.189-.353-1.289,1.977,1.108.395Z" transform="translate(-1397.338 -807.87)" fill="#76b1db" />
            <path id="Path_34437" data-name="Path 34437" d="M1468.156,808.023l.362-.554a1.487,1.487,0,0,1-.07,2.4A6.4,6.4,0,0,0,1468.156,808.023Z" transform="translate(-1463.696 -807.469)" fill="#76b1db" />
            <path id="Path_34438" data-name="Path 34438" d="M1415.061,813.5a2.841,2.841,0,0,0,2.982.174,2.235,2.235,0,0,1,.292,1.845l-.979,2.019h-1.367l-1.3-2.019A4.341,4.341,0,0,1,1415.061,813.5Z" transform="translate(-1413.583 -813.116)" fill="#5392bf" />
            <path id="Path_34439" data-name="Path 34439" d="M1419.113,877.609l-1.1,28.89,2.306,4.487,1.883-4.465-1.717-28.912Z" transform="translate(-1416.706 -873.192)" fill="#76b1db" />
          </g>
          <path id="Path_34440" data-name="Path 34440" d="M1496.079,785.736l1.824.857,1.317,6.086s.661,1.574-.3,1.987l-1.719,1,2.423,1.535a1.4,1.4,0,0,1,.491,1.975l-4.861,9.491-.153-1.623Z" transform="translate(-1465.464 -756.977)" fill="#1e2e35" />
          <g id="Group_14190" data-name="Group 14190" transform="translate(24.652 18.013)">
            <path id="Path_34441" data-name="Path 34441" d="M1416.072,615.383l3.89-.253a.226.226,0,0,1,.24.331s-.756,1.988-2.018,2.067-2.257-1.8-2.257-1.8A.217.217,0,0,1,1416.072,615.383Z" transform="translate(-1415.89 -615.129)" fill="#8b3231" />
            <path id="Path_34442" data-name="Path 34442" d="M1431.117,630.066a1.666,1.666,0,0,1-2.926.174A2.035,2.035,0,0,1,1431.117,630.066Z" transform="translate(-1427.416 -628.626)" fill="#c84847" />
            <path id="Path_34443" data-name="Path 34443" d="M1418.184,615.407a.265.265,0,0,1,.037-.018l3.89-.253a.346.346,0,0,1,.106.016c-.1.23-.2.461-.3.688a9.082,9.082,0,0,1-3.376.215A6.441,6.441,0,0,0,1418.184,615.407Z" transform="translate(-1418.04 -615.135)" fill="#fff" />
          </g>
        </g>
      </g>
      <g id="Group_14220" data-name="Group 14220" transform="translate(28.216 2.672)">
        <g id="Group_14219" data-name="Group 14219" transform="translate(0 0)">
          <g id="Group_14195" data-name="Group 14195" transform="translate(0 31.757)">
            <g id="Group_14193" data-name="Group 14193" transform="translate(0.643 27.198)">
              <path id="Path_34444" data-name="Path 34444" d="M669.888,1294.489c1.264-6.638,4.834-20.708,4.834-20.708s-5.2-3.356-6.167-1.781c-.822,1.332-1.565,15.762-1.409,22.488Z" transform="translate(-667.125 -1271.58)" fill="#df7e61" />
            </g>
            <g id="Group_14194" data-name="Group 14194" transform="translate(0.984)">
              <path id="Path_34445" data-name="Path 34445" d="M684.623,946.391l-1.569.511s-2.483,19.313-.381,23.626,4.52-.021,5.308-.51,5.812-22.361,5.812-22.361Z" transform="translate(-681.243 -939.674)" fill="#df7e61" />
              <path id="Path_34446" data-name="Path 34446" d="M672.537,868.364l1-24.194c1.135-5.939,7.216-4.171,7.216-4.171,4.93,1.284,4.693,6.809,4.693,6.809l-4.239,23.175s-.889,3.543-5.451,3.544C675.759,873.527,672.394,873.072,672.537,868.364Z" transform="translate(-672.533 -839.753)" fill="#030c13" />
            </g>
            <path id="Path_34447" data-name="Path 34447" d="M662.208,1289.242a185.058,185.058,0,0,0,3.949-19.062c.6-4.023-7.782-4.2-7.782-4.2-1.229,4.832-1.437,17.06-1.461,23.261Z" transform="translate(-656.914 -1239.135)" fill="#030c13" />
          </g>
          <path id="Path_34448" data-name="Path 34448" d="M701.919,800.112,697.5,773.075s-1.524-4.064,1.525-6.1l11.619-5.461h8.195l13.969,8.256-9.156,30.338Z" transform="translate(-694.583 -734.689)" fill="#f5916e" />
          <path id="Path_34449" data-name="Path 34449" d="M798.094,1390.723c-.779-11.426-2.974-16.44-2.974-16.44H773.393l.029,16.44Z" transform="translate(-766.057 -1308.86)" fill="#001c3e" />
          <g id="Group_14196" data-name="Group 14196" transform="translate(17.057 76.32)">
            <path id="Path_34450" data-name="Path 34450" d="M942.484,1552.835a7.675,7.675,0,0,0-14.746,0Z" transform="translate(-927.738 -1547.292)" fill="#0d141c" />
            <path id="Path_34451" data-name="Path 34451" d="M1027.111,1634.292a6.954,6.954,0,0,0-1.947,0Z" transform="translate(-1019.028 -1628.749)" fill="#0d141c" />
          </g>
          <g id="Group_14197" data-name="Group 14197" transform="translate(7.896 76.976)">
            <path id="Path_34452" data-name="Path 34452" d="M795.139,1562.6a7.713,7.713,0,0,0-6.349-4.849c-3.409-.348-5.561,1.744-6.513,4.849Z" transform="translate(-782.277 -1557.714)" fill="#0d141c" />
          </g>
          <g id="Group_14198" data-name="Group 14198" transform="translate(28.617 34.217)">
            <path id="Path_34453" data-name="Path 34453" d="M1236.116,1340.268h3.565c1.122-6.819,2.086-17.476,1.579-18.658-1.085-2.524-6.117-.192-6.117-.192C1236.286,1332.878,1236.275,1336.679,1236.116,1340.268Z" transform="translate(-1227.341 -1292.622)" fill="#f5916e" />
            <path id="Path_34454" data-name="Path 34454" d="M1128.9,897.574a6.141,6.141,0,0,1,7.235,3.757s2.707,16.591,2.674,23.7c-.022,4.8-5.876.934-6.261.09-.848-1.861-7-21.845-7-21.845S1125.23,898.582,1128.9,897.574Z" transform="translate(-1124.645 -896.21)" fill="#f5916e" />
            <path id="Path_34455" data-name="Path 34455" d="M1118.264,908.01l-6.994-22.675c.158-5.636,5.673-6.477,5.673-6.477a5.651,5.651,0,0,1,6.323,3.966l2.509,22.7s.851,5.1-2.1,5.176C1123.678,910.7,1118.731,911.236,1118.264,908.01Z" transform="translate(-1111.27 -878.819)" fill="#091018" />
            <path id="Path_34456" data-name="Path 34456" d="M1221.807,1307.828c1.041-5.926,2.7-16.777,1.706-20.942,0,0-5.555-3.031-7.935,1.111,0,0,.626,13.149,1.024,19.832Z" transform="translate(-1209.008 -1260.182)" fill="#091018" />
          </g>
          <path id="Path_34457" data-name="Path 34457" d="M904.8,666.7v8.759h-9.263a12.16,12.16,0,0,0,1.139-8.759Z" transform="translate(-880.508 -645.844)" fill="#f5916e" />
          <path id="Path_34458" data-name="Path 34458" d="M907.589,674.139c-.835.537-2.608,1.68-3.622,1.011a.955.955,0,0,1-.438-.679,12.837,12.837,0,0,0,.636-7.77h8.124v.795C911.274,670.059,909.974,672.6,907.589,674.139Z" transform="translate(-887.996 -645.844)" fill="#d9705a" />
          <g id="Group_14199" data-name="Group 14199" transform="translate(12.553 3.134)">
            <path id="Path_34459" data-name="Path 34459" d="M971.354,403.161s1.576-.685,3.361-4.586c0,0,3.875-4.613,1.128-9.851,0,0-1.623-4.429-9.486-3.189C966.357,385.535,965.65,397.406,971.354,403.161Z" transform="translate(-959.394 -385.316)" fill="#f68c68" />
            <path id="Path_34460" data-name="Path 34460" d="M858.489,405.522l-.524-1.373-1.448-5.11a5.284,5.284,0,0,1-.075-2.163,5.332,5.332,0,0,0,.021-2.227,6.449,6.449,0,0,1,.97-5.761c2.075-3.466,7.184-3.581,7.184-3.581,6.324.165,7,6.265,7,6.265,1.119,4.042-2.036,7.5-2.043,7.511-.252.275-.088,1.611-.156,1.994-.3,1.63-1.826,2.663-3.185,3.383a15.181,15.181,0,0,1-4.992,1.829C860.6,406.362,858.79,406.31,858.489,405.522Z" transform="translate(-856.213 -385.307)" fill="#f5916e" />
          </g>
          <path id="Path_34461" data-name="Path 34461" d="M901.916,547.731l1.76.535s-2.3.313-2.436-.351c0,0,.253-1.334.54-2.6C901.78,545.313,901.655,547.651,901.916,547.731Z" transform="translate(-885.852 -532.101)" fill="#e3755b" />
          <g id="Group_14200" data-name="Group 14200" transform="translate(12.545 10.636)">
            <path id="Path_34462" data-name="Path 34462" d="M956.527,531.582c-.016.362.172.664.417.674s.458-.273.474-.635-.17-.664-.415-.675S956.543,531.22,956.527,531.582Z" transform="translate(-950.2 -529.275)" fill="#2d1a10" />
            <path id="Path_34463" data-name="Path 34463" d="M877.534,528.634c-.018.362.169.664.414.676s.458-.272.476-.634-.168-.665-.413-.677S877.551,528.272,877.534,528.634Z" transform="translate(-876.182 -526.514)" fill="#2d1a10" />
            <path id="Path_34464" data-name="Path 34464" d="M944.058,510.3c-.157.046-.212-.167-.212-.167a.744.744,0,0,1-.018-.384.37.37,0,0,1,.176-.207,5.4,5.4,0,0,1,1.908.107c.384.084,1.013.318,1.078.661.019.1.042.28-.074.32A15.461,15.461,0,0,0,944.058,510.3Z" transform="translate(-938.287 -509.176)" fill="#2d1a10" />
            <path id="Path_34465" data-name="Path 34465" d="M856.17,505.209c.45-.025,1.638.164,2.618.275.151.064.231-.139.232-.139a.752.752,0,0,0,.067-.379.373.373,0,0,0-.148-.228,6.563,6.563,0,0,0-2.17-.309.86.86,0,0,0-.649.323S856.039,504.7,856.17,505.209Z" transform="translate(-856.095 -504.418)" fill="#2d1a10" />
          </g>
          <path id="Path_34466" data-name="Path 34466" d="M725.15,799.378c-.019.9,1.349,10.324.774,10.444,0,0-15.634,3.6-23.971,0l-.034-9.71L697.5,773.075s-1.524-4.064,1.525-6.1l11.619-5.461-.784,2.355,1.68,4.778,7.3-7.133,13.969,8.256q-1.172,3.88-2.338,7.762c-1.44,4.768-2.842,9.551-4.324,14.306C726.14,791.842,724.913,797,725.15,799.378Z" transform="translate(-694.583 -734.689)" fill="#fff" />
          <path id="Path_34467" data-name="Path 34467" d="M942.061,788.51l.1,4.353,1.6-1.568Z" transform="translate(-924.101 -759.981)" fill="#c7c9cb" />
          <g id="Group_14205" data-name="Group 14205" transform="translate(15.918 34.928)">
            <g id="Group_14201" data-name="Group 14201">
              <path id="Path_34468" data-name="Path 34468" d="M909.65,890.729a.621.621,0,1,0,.621-.62A.621.621,0,0,0,909.65,890.729Z" transform="translate(-909.65 -890.109)" fill="#e0e1e3" />
            </g>
            <g id="Group_14202" data-name="Group 14202" transform="translate(0 11.278)">
              <path id="Path_34469" data-name="Path 34469" d="M909.65,1069.8a.621.621,0,1,0,.621-.622A.621.621,0,0,0,909.65,1069.8Z" transform="translate(-909.65 -1069.176)" fill="#e0e1e3" />
            </g>
            <g id="Group_14203" data-name="Group 14203" transform="translate(0 22.557)">
              <path id="Path_34470" data-name="Path 34470" d="M909.65,1248.866a.621.621,0,1,0,.621-.62A.621.621,0,0,0,909.65,1248.866Z" transform="translate(-909.65 -1248.246)" fill="#e0e1e3" />
            </g>
            <g id="Group_14204" data-name="Group 14204" transform="translate(0 33.835)">
              <path id="Path_34471" data-name="Path 34471" d="M909.65,1427.931a.621.621,0,1,0,.621-.622A.621.621,0,0,0,909.65,1427.931Z" transform="translate(-909.65 -1427.309)" fill="#e0e1e3" />
            </g>
          </g>
          <line id="Line_2531" data-name="Line 2531" y2="42.804" transform="translate(17.96 33.378)" fill="none" stroke="#e0e1e3" strokeMiterlimit="10" strokeWidth="0.34" />
          <g id="Group_14207" data-name="Group 14207" transform="translate(7.115 73.127)">
            <path id="Path_34472" data-name="Path 34472" d="M770.235,1499.494s7.984,3.741,24.324-.082c.269-.064.156-.761.156-.761l-.156-1.573c-.071-.5-.353-.48-.353-.48s-15.634,3.6-23.971,0c0,0-.336-.059-.35.446v1.962A.517.517,0,0,0,770.235,1499.494Z" transform="translate(-769.885 -1496.596)" fill="#371608" />
            <g id="Group_14206" data-name="Group 14206" transform="translate(8.461 1.344)">
              <path id="Path_34473" data-name="Path 34473" d="M904.227,1520.677a.447.447,0,0,0,.441.452h3.18a.447.447,0,0,0,.441-.452v-2.29a.447.447,0,0,0-.441-.451h-3.18a.447.447,0,0,0-.441.451Z" transform="translate(-904.227 -1517.936)" fill="none" stroke="#aeb0b3" strokeMiterlimit="10" strokeWidth="0.73" />
            </g>
          </g>
          <path id="Path_34474" data-name="Path 34474" d="M942.06,727.854s5.126-1.991,6.333-3.973l1.514,2.886-5.4,5.182-2.444-4" transform="translate(-924.101 -699.422)" fill="#e0e1e3" />
          <path id="Path_34475" data-name="Path 34475" d="M828.7,337.484a3.886,3.886,0,0,0,2.146,2.147c-.179.556-1.351.974-1.351.974,1.04,1.774,3.347,1.063,3.347,1.063a7.327,7.327,0,0,1,3.2.288c1.462.414,3.962-.253,4.828,1.312.172.311.859,2.485.013,3.574,1.786,1.372.923,4.151.923,4.151l.432.024c2.506-.648,1.664,4.09,1.664,4.092.019-.109.436-.374.519-.453a17.664,17.664,0,0,0,1.846-2.749,14.378,14.378,0,0,0,2.069-5.306c.324-1.3.184-4.833-1.713-6.607,0,0,.589-2.325-3.081-3.732,0,0-7.828-1.965-11.623.621A6.229,6.229,0,0,1,828.7,337.484Z" transform="translate(-817.879 -335.546)" fill="#2d1a10" />
          <path id="Path_34476" data-name="Path 34476" d="M1002.932,359.709" transform="translate(-981.139 -358.187)" fill="#cc753c" />
          <g id="Group_14216" data-name="Group 14216" transform="translate(24.348 13.674)">
            <g id="Group_14212" data-name="Group 14212">
              <g id="Group_14211" data-name="Group 14211">
                <g id="Group_14210" data-name="Group 14210">
                  <g id="Group_14209" data-name="Group 14209">
                    <g id="Group_14208" data-name="Group 14208">
                      <path id="Path_34477" data-name="Path 34477" d="M1044.114,556.484a1.4,1.4,0,0,0,.89.318,2.052,2.052,0,0,0,1.965-2.018,1.881,1.881,0,0,0-1.684-2.13,1.767,1.767,0,0,0-1.274.512,2.558,2.558,0,0,0-.481,2.065A1.925,1.925,0,0,0,1044.114,556.484Z" transform="translate(-1043.498 -552.653)" fill="#f5916e" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_14215" data-name="Group 14215" transform="translate(1.029 0.666)">
              <g id="Group_14213" data-name="Group 14213" transform="translate(0.288)">
                <path id="Path_34478" data-name="Path 34478" d="M1064.417,563.6c.087-.6,1.276.033,1.077.362a1.844,1.844,0,0,1-.509.452.885.885,0,0,0-.4.64c-.007.034-.017.139.017.166.152.117.7-.449.814-.566a1.008,1.008,0,0,0,.191-.956.6.6,0,0,0-.648-.482c-.273,0-.553.009-.553.349,0,.008.01.065.015.034Z" transform="translate(-1064.402 -563.221)" fill="#e3755b" />
              </g>
              <g id="Group_14214" data-name="Group 14214" transform="translate(0 0.961)">
                <path id="Path_34479" data-name="Path 34479" d="M1060.492,578.547c-.12-.133-.246-.033-.381.055a.572.572,0,0,0-.234.345,1.411,1.411,0,0,0,.105,1.011.759.759,0,0,0,.638.379c.367,0,.49-.313.52-.623.008-.082.024-.279-.056-.334s-.154.074-.215.13a.511.511,0,0,1-.316.1.662.662,0,0,1-.535-.268.359.359,0,0,1-.051-.1c-.009.2,0,.25.026.139a.513.513,0,0,1,.077-.14c.09-.137.239-.227.339-.356.049-.06.143-.274.083-.342Z" transform="translate(-1059.829 -578.482)" fill="#e3755b" />
              </g>
            </g>
          </g>
          <path id="Path_34480" data-name="Path 34480" d="M994.5,836.961l-2.537-19.753L998,790.183,986.352,783.3l-6.214,21.826.741,31.837Z" transform="translate(-959.781 -755.098)" fill="#091018" />
          <path id="Path_34481" data-name="Path 34481" d="M986.353,783.3l-6.214,21.825.027,1.036,7.825-8.01s.823-1.39-.291-2.213l-1.849-1.768,1.318-.945s1.849-.9,1.625-2.579l-.529-6.32Z" transform="translate(-959.781 -755.098)" fill="#16242a" />
          <path id="Path_34482" data-name="Path 34482" d="M697.785,817.41l-1.038,21.835h7.56l2.421-32.426-.99-21.083-7.076,3.326a4.42,4.42,0,0,0-3.2,5.235Z" transform="translate(-692.927 -757.382)" fill="#091018" />
          <path id="Path_34483" data-name="Path 34483" d="M873.348,740.437a6.515,6.515,0,0,1,2.782-3.363l-.3,6.284S874.545,740.143,873.348,740.437Z" transform="translate(-859.716 -711.784)" fill="#e0e1e3" />
          <path id="Path_34484" data-name="Path 34484" d="M870.016,793.095l.21-2.921c.706-.173,1.337.815,1.85,1.625Z" transform="translate(-856.594 -761.521)" fill="#c7c9cb" />
          <path id="Path_34485" data-name="Path 34485" d="M796.405,785.736l-1.824.858-1.317,6.085s-.661,1.575.295,1.987l1.719,1-2.423,1.533a1.4,1.4,0,0,0-.491,1.976l4.861,9.49.152-1.622Z" transform="translate(-783.594 -757.382)" fill="#16242a" />
          <g id="Group_14217" data-name="Group 14217" transform="translate(14.438 17.609)">
            <path id="Path_34486" data-name="Path 34486" d="M890.309,615.387l-3.891-.253a.226.226,0,0,0-.24.331s.756,1.988,2.018,2.066,2.257-1.8,2.257-1.8A.217.217,0,0,0,890.309,615.387Z" transform="translate(-886.155 -615.133)" fill="#8b3231" />
            <path id="Path_34487" data-name="Path 34487" d="M896.234,630.071a1.666,1.666,0,0,0,2.925.174A2.035,2.035,0,0,0,896.234,630.071Z" transform="translate(-895.599 -628.632)" fill="#c84847" />
            <path id="Path_34488" data-name="Path 34488" d="M892.684,615.41a.326.326,0,0,0-.037-.018l-3.891-.254a.352.352,0,0,0-.106.015c.1.231.2.461.3.689a9.065,9.065,0,0,0,3.376.214A6.492,6.492,0,0,1,892.684,615.41Z" transform="translate(-888.493 -615.138)" fill="#fff" />
          </g>
          <path id="Path_34489" data-name="Path 34489" d="M874.849,583.772l-.678-2.953-.432-.024a3.231,3.231,0,0,1-2.778,2.3s-1.012.154-1.443.21c-.057.008-.68-.608-.76-.661a4.89,4.89,0,0,0-2.332-.636,5.8,5.8,0,0,0-2.5.157c-.1.04-1.116.553-1.149.409a39.086,39.086,0,0,0,1.823,6.084s.328,1.041,2.761.786a13.831,13.831,0,0,0,7.417-3.392C875.315,585.651,874.849,583.772,874.849,583.772Zm-10.9-.2c.1-.492.116-.8.538-.88a8.578,8.578,0,0,1,2.572.1c.67.084,1.5.172,1.775.9a2.847,2.847,0,0,1-.274,2.173,2.718,2.718,0,0,1-3.367.862A3.843,3.843,0,0,1,863.949,583.57Z" transform="translate(-849.811 -565.348)" fill="#2d1a10" />
          <g id="Group_14218" data-name="Group 14218" transform="translate(14.453 29.724)">
            <path id="Path_34490" data-name="Path 34490" d="M950.068,808.244l.19-.353,1.288,1.978-1.108.395Z" transform="translate(-946.057 -807.864)" fill="#d03138" />
            <path id="Path_34491" data-name="Path 34491" d="M888.538,808.029l-.361-.553a1.487,1.487,0,0,0,.069,2.4A6.444,6.444,0,0,1,888.538,808.029Z" transform="translate(-887.509 -807.475)" fill="#d03138" />
            <path id="Path_34492" data-name="Path 34492" d="M900.324,813.5a2.838,2.838,0,0,1-2.982.174,2.235,2.235,0,0,0-.292,1.845l.979,2.019H899.4l1.3-2.019A4.343,4.343,0,0,0,900.324,813.5Z" transform="translate(-896.313 -813.117)" fill="#e4393d" />
            <path id="Path_34493" data-name="Path 34493" d="M889.466,877.615l1.1,28.889-2.306,4.488-1.882-4.465,1.716-28.912Z" transform="translate(-886.383 -873.198)" fill="#d03138" />
          </g>
        </g>
      </g>
    </svg>

  );
}

export default function UserLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="123.337" viewBox="0 0 120 123.337">
      <g id="verified-user" transform="translate(-7 -5.999)">
        <path id="Path_49761" data-name="Path 49761" d="M118.767,106.236c-2.133,1.067-4.267,2.167-6.367,3.333q-8.3,4.5-16.3,9.6-1.9,1.133-3.8,2.4a12.168,12.168,0,0,0-1.4,1.133H7v-3.367A56.666,56.666,0,0,1,52.4,63.8a30,30,0,1,1,22.535,0,56.667,56.667,0,0,1,43.833,42.433Z" fill="#505078" />
        <path id="Path_49762" data-name="Path 49762" d="M58.339,27.09A38.4,38.4,0,0,0,25.906,73.457h23c.433-.4,21.5-13.133,21.5-13.133,2.1-1.133,4.233-2.233,6.367-3.333a56.867,56.867,0,0,0-18.433-29.9Z" transform="translate(41.994 49.212)" fill="#323250" />
        <circle id="Ellipse_5182" data-name="Ellipse 5182" cx="20" cy="20" r="20" transform="translate(80 82.999)" fill="#006400" />
        <path id="Path_49763" data-name="Path 49763" d="M53.667,27A26.667,26.667,0,1,0,80.333,53.667,26.667,26.667,0,0,0,53.667,27Zm15.7,22.367L56.033,62.7a3.333,3.333,0,0,1-4.733,0l-10-10a3.347,3.347,0,1,1,4.733-4.733l7.633,7.667,10.967-11a3.347,3.347,0,0,1,4.733,4.733Z" transform="translate(46.667 49.002)" fill="#64d885" />
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const AddArticleInvitraceFormStyled = styled.div`
  .layer_one {
    display: flex;
    padding-bottom: 10px;
  }
  .layer_two {
    margin-top: 7px;
    .top_form {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 32px;
      .group_top_form {
        width: 40%;
        .line_label {
          display: flex;
          margin-bottom: 14px;
          .label_1 {
            margin-right: 18px;
          }
          .label_2 {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            color: ${({ theme }) => theme.COLORS.GRAY_1};
            padding-top: 4px;
          }
        }
      }
    }
    .mid_form {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      .line_mid {
        display: flex;
        .mid_left {
          width: 41%;
          margin-right: 24px;
          .group_mid_form {
            margin-bottom: 23px;
            &.no_margin {
              margin-bottom: 0px;
            }
            &.mb10 {
              margin-bottom: 10px;
            }
            .line_label {
              display: flex;
              margin-bottom: 14px;
              .label_1 {
                margin-right: 18px;
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                color: ${({ theme }) => theme.COLORS.GRAY_1};
                padding-top: 4px;
              }
            }
          }
        }
        .mid_right {
          width: 59%;
          margin-bottom: 16px;
          .group_mid_form_right {
            &.no_margin {
              margin-bottom: 0px;
            }
            &.mb10 {
              margin-bottom: 10px;
            }
            .line_label {
              display: flex;
              margin-bottom: 14px;
              .label_1 {
                margin-right: 18px;
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                color: ${({ theme }) => theme.COLORS.GRAY_1};
                padding-top: 4px;
              }
            }
          }
        }
      }
      .show_file_name {
        margin-bottom: 42px;
      }
    }
    .bottom_form {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      display: flex;
      margin-bottom: 23px;
      .bottom_left {
        width: 41%;
        margin-right: 24px;
        .group_bottom_form {
          margin-bottom: 23px;
          &.no_margin {
            margin-bottom: 0px;
          }
          &.mb10 {
            margin-bottom: 10px;
          }
          .line_label {
            display: flex;
            margin-bottom: 14px;
            .label_1 {
              margin-right: 18px;
            }
            .label_2 {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              padding-top: 4px;
            }
          }
        }
        .reward_opt {
          .show_reward_layer {
            .title_show {
              display: flex;
              margin-bottom: 17px;
              .show_icon {
                margin-right: 4px;
                display: flex;
              }
            }
            .box_input_bottom {
              margin-bottom: 24px;
              .left_box {
              }
              .right_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 16px;
                .body_input {
                  width: 86%;
                }
                .label_b {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S20};
                  ffont-family: noto_sans_thai_medium, noto_sans_regular;
                }
              }
              .between_label {
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                font-family: noto_sans_thai_bold, noto_sans_bold;
              }
              &.no_margin {
                margin-bottom: 0px;
              }
            }
            &.left {
              padding-right: 24px;
            }
            &.right {
              padding-left: 24px;
            }
          }
        }
      }
      .bottom_right {
        width: 59%;
        .group_bottom_form_right {
          margin-bottom: 23px;
          &.no_margin {
            margin-bottom: 0px;
          }
          &.mb10 {
            margin-bottom: 10px;
          }
          .line_label {
            display: flex;
            margin-bottom: 14px;
            .label_1 {
              margin-right: 18px;
            }
            .label_2 {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              padding-top: 4px;
            }
          }
        }
      }
    }
    .opt_show {
      display: flex;
      .left_opt {
        width: 41%;
        margin-right: 24px;
        .group_opt {
          .line_label {
            line-height: 1.2;
            font-family: noto_sans_thai_medium, noto_sans_regular;
            margin-bottom: 19px;
            .label_1 {
              font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            }
            .label_2 {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              color: ${({ theme }) => theme.COLORS.GRAY_1};
            }
          }
        }
        .show_img {
          margin-top: 32px;
        }
      }
    }
    .zone_upload {
      padding-bottom: 88px;
      .header_zone {
        line-height: 1.2;
        font-family: noto_sans_thai_medium, noto_sans_regular;
        display: flex;
        margin-bottom: 23px;
        align-items: baseline;
        .label_1 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        }
        .label_2 {
          margin-left: 14px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
      }
      .upload_layer {
      }
    }
  }
  .reward_setting {
    padding: 15px 32px 48px 32px;
    margin-top: 24px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 18px;
    .top_setting {
      display: flex;
      justify-content: space-between;
      .left_top_setting {
        display: flex;
        .show_icon_set {
          margin-right: 19px;
          display: flex;
        }
      }
      .right_top_setting {
        .btn_action_save {
        }
      }
    }
    .body_setting {
      margin-top: 18px;
      display: flex;
      .left_body {
        width: 490px;
        margin-right: 26px;
        .in_box {
          display: flex;
          .show_icon_in_box {
            margin-right: 14px;
            display: flex;
          }
        }
        .opt_layout {
          margin-top: 15px;
          padding-left: 38px;
          display: flex;
          align-items: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          &.pd_right {
            padding-right: 38px;
          }
          .label_1 {
            margin-right: 13px;
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            &.active {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
              font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            }
          }
          .label_2 {
            margin-left: 13px;
            color: ${({ theme }) => theme.COLORS.GRAY_1};
            &.active {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
              font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            }
          }
          .group_label {
            margin-left: 16px;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            .sub_label {
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            }
          }
        }
      }
      .right_body {
        width: 100%;
      }
    }
  }
`;

import { Route, Switch } from "react-router-dom";
import LoginContainer from "containers/LoginContainer";
import ComponentContainer from "containers/ComponentContainer";
import ForgotEmailPasswordContainer from "containers/ForgotEmailPasswordContainer";
import PasswordExpiredContainer from "containers/PasswordExpiredContainer";
import ForgotPasswordContainer from "containers/ForgotPasswordContainer";
import { Mainlayout } from "mainlayouts";
import { ROUTE_PATH } from "utils/constants";

const indexRoutes = [
  { path: "/", exact: true, name: "page", component: LoginContainer },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    exact: true,
    name: "page",
    component: ForgotEmailPasswordContainer,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    exact: true,
    name: "page",
    component: ForgotPasswordContainer,
  },
  {
    path: ROUTE_PATH.PASSWORD_EXPIRED,
    exact: true,
    name: "page",
    component: PasswordExpiredContainer,
  },

  {
    path: "/components",
    exact: true,
    name: "components",
    component: ComponentContainer,
  },
  {
    path: "/administrator",
    exact: false,
    name: "administrator",
    component: Mainlayout,
  },
];

const Routes = () => {
  return (
    <Switch>
      {indexRoutes.map((prop, i) => {
        return (
          <Route
            key={i}
            exact={prop.exact}
            path={prop.path}
            component={prop.component}
          />
        );
      })}
    </Switch>
  );
};

export default Routes;

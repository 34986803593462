import React, { useEffect, useState } from "react";
import { CreateUserAccountFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useParams } from "react-router-dom";
import * as yup from "yup";
import { adminService } from "apiServices";
import { Inputs, Icons, Buttons, Modal, Forms, BGLoading } from "components";
import theme from "styles/theme.json";
import { toast } from "react-toastify";

const CreateUserAccountForm = ({
  onSubmit,
  initialValues,
  isCreate,
  disabled,
  perMissions,
  handleDelete,
  deparmentList,
  companySelectedRedux,
  companyCurrent,
  isCannotEditDelete = false,
  handleBack,
}) => {
  const schema = yup.object().shape({
    code: yup.string().required("Code is required"),
    firstname: yup.string().required("Firstname is required"),
    lastname: yup.string().required("Lastname is required"),
    department_id: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable()
      .required("Department is required."),
    mobile_no: yup.string().when("id_card", (id_card, schema) => {
      if (initialValues && initialValues.user && initialValues.user.mobile_no) {
        return yup
          .string()
          .test("len", "Invalid Phone number", (val) => {
            const val_length_without_dashes = val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 10;
          })
          .required("Phone number is required");
      }
    }),
    email: yup.string().when("id_card", (id_card, schema) => {
      if (initialValues && initialValues.user && initialValues.user.email) {
        return yup
          .string()
          .email("Email is required")
          .matches(
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, //eslint-disable-line
            "Email is not valid"
          ); //eslint-disable-line
      }
    }),
  });

  const [_companyRealm, _setCompanyRealm] = useState();
  const [_isShowModal, _setIsModal] = useState();
  const [_isBgLoading, _setIsBGLoading] = useState(false);

  const {
    control,
    // setValue,
    // setError,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (deparmentList && deparmentList.length > 0) {
      _setCompanyRealm(deparmentList[0].company.realm);
    } else {
      _setCompanyRealm(companySelectedRedux.realm);
    }

    if (initialValues) {
      let userField = {};
      let mobile_no;
      let email;

      if (initialValues.user && initialValues.user.mobile_no) {
        mobile_no = initialValues.user.mobile_no;
        userField.mobile_no = initialValues.user.mobile_no;
      } else {
        mobile_no = "ไม่มีข้อมูล";
      }

      if (initialValues.user && initialValues.user.email) {
        email = initialValues.user.email;
        userField.email = initialValues.user.email;
      } else {
        email = "ไม่มีข้อมูล";
      }

      reset({
        ...initialValues,
        birth_date: new Date(initialValues.birth_date),
        department_id: {
          label: initialValues.department?.name,
          value: initialValues.department?.id,
        },
        mobile_no: mobile_no,
        email: email,
        user: userField,
      });
    }
  }, [initialValues, deparmentList]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDisabled = () => {
    return (
      disabled ||
      !watchAllFields ||
      !watchAllFields.code ||
      watchAllFields.code === "" ||
      !watchAllFields.firstname ||
      watchAllFields.firstname === "" ||
      !watchAllFields.lastname ||
      watchAllFields.lastname === ""
    );
  };

  const renderDisabledIcon = () => {
    if (
      disabled ||
      !watchAllFields ||
      !watchAllFields.code ||
      watchAllFields.code === "" ||
      !watchAllFields.firstname ||
      watchAllFields.firstname === "" ||
      !watchAllFields.lastname ||
      watchAllFields.lastname === ""
    ) {
      return "#ACACAC";
    } else {
      return "#002366";
    }
  };

  const handleSelctDepartment = (e) => {
    if (e && e.data) {
      _setCompanyRealm(e.data.company.realm);
    }
  };

  const _onSubmit = (values) => {
    let params = {
      ...values,
    };

    if (initialValues && initialValues.user && initialValues.user.mobile_no) {
      params.user.mobile_no = values.mobile_no.replace(/[^\w\s]/gi, "");
    }

    if (initialValues && initialValues.user && initialValues.user.email) {
      params.user.email = values.email;
    }

    onSubmit && onSubmit(params);
  };

  const handleClickCloseModal = () => {
    _setIsModal(null);
  };

  const submitSendPassword = async (values) => {
    _setIsBGLoading(true);

    let params = {
      provider: values.opt_select === "1" ? "mobile_no" : "email",
    };

    if (values.opt_select === "3") {
      delete params.provider;
    }

    let res = await adminService.UNLOCK_USER_BY_ID(
      initialValues.user.id,
      params
    );

    if (res && res.success) {
      toast.success("ส่งลิงค์สำเร็จ");
      handleClickCloseModal();
      handleBack && handleBack();
      _setIsBGLoading(false);
    } else {
      toast.error("ส่งลิงค์สำเร็จไม่สำเร็จ");
      _setIsBGLoading(false);
    }
  };

  const handleSendPassword = () => {
    _setIsModal(
      <Forms.SendPasswordForm
        handleConfirm={submitSendPassword}
        handleCancel={handleClickCloseModal}
        data={initialValues}
      />
    );

    // _setIsModal(
    //   <div className="box_send">
    //     <div className="info_success">
    //       <div className="icon_succ">
    //         <Icons.Info />
    //       </div>
    //       <div>ไม่สามารถตั้งค่ารหัสผ่าน</div>
    //       <div>เนื่องจากไม่มีอีเมล หรือ เบอร์โทรศัพท์ของผู้ใช้งาน</div>
    //     </div>
    //     <div className="bottom_success">
    //       <div className="btn_k ">
    //         <div className="btn_k ">
    //           <Buttons.BtnNormal
    //             theme_only_border_blue
    //             padding="11.2px 20px"
    //             label="รับทราบ"
    //             backgroundColor={theme.COLORS.BLUE_28}
    //             fontColor={theme.COLORS.WHITE_1}
    //             onClick={handleClickCloseModal}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  };

  const disabledMobileNoEmail = (fieldName = "mobile_no") => {
    let tempValue = true;

    if (initialValues && initialValues.user && initialValues.user[fieldName]) {
      tempValue = false;
    } else if (isCannotEditDelete) {
      tempValue = true;
    }
    return tempValue;
  };

  return (
    <CreateUserAccountFormStyled>
      <BGLoading visible={_isBgLoading} />
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="layer_one">
          <div className="head_wrap_layout">
            <div className="h_33">
              <Buttons.BtnLink
                theme_standard_btn_link
                svg={<Icons.ArrowLeft />}
                label="ย้อนกลับ"
                onClick={handleBack}
              />
            </div>
            <div className="h_33 center">
              <div className="text_c">
                {isCannotEditDelete
                  ? "รายละเอียด"
                  : isCreate
                  ? "แก้ไขบัญชีของผู้ใช้"
                  : "สร้างบัญชีของผู้ใช้"}
              </div>
              <div className="g_h_text">
                {isCannotEditDelete
                  ? "Detail User Account"
                  : isCreate
                  ? "Edit User Account"
                  : "Create User Account"}
              </div>
            </div>
            <div className="h_33 flex">
              {/* {initialValues &&
                !isCannotEditDelete &&
                perMissions &&
                perMissions.key === "employee_manage" && (
                  <div className="bw132">
                    <Buttons.BtnNormal
                      theme_only_border_red
                      padding="12px 8px"
                      borderRadius="8px"
                      label="ลบบัญชี"
                      svg_front={<Icons.Delete5 />}
                      onClick={() => handleDelete && handleDelete("delete")}
                    />
                  </div>
                )}
              {!isCannotEditDelete && (
                <div className="bw200">
                  <Buttons.BtnNormal
                    theme_standard_btn_log_in
                    backgroundColor={theme.COLORS.BLUE_28}
                    fontColor={theme.COLORS.WHITE_1}
                    type="submit"
                    label={isCreate ? "บันทึก" : "บันทึก"}
                    disabled={renderDisabled()}
                    svg_front={
                      isCreate ? (
                        <Icons.Edit color={renderDisabledIcon()} />
                      ) : (
                        <Icons.Disk color={renderDisabledIcon()} />
                      )
                    }
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="title_b">ข้อมูลส่วนตัว</div>
        <div className="layer_two">
          <div className="box_50">
            <div className="group_input">
              <div className="g_b_label">
                <div>ชื่อ</div>
                <div className="sub_gray">Name</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      iconSvg={<Icons.User />}
                      placeholder="ชื่อ"
                      {...field}
                      errors={errors.firstname?.message}
                      disabled={isCannotEditDelete}
                    />
                  )}
                  name="firstname"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input">
              <div className="g_b_label">
                <div>วัน/เดือน/ปีเกิด</div>
                <div className="sub_gray">Date of birth</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputDatePiker2
                      theme_standard
                      {...field}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      disabled={isCannotEditDelete}
                      errors={errors.birth_date?.message}
                      maxDate={new Date()}
                    />
                  )}
                  name="birth_date"
                  defaultValue={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="box_50">
            <div className="group_input">
              <div className="g_b_label">
                <div>นามสกุล</div>
                <div className="sub_gray">Surname</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      iconSvg={<Icons.User />}
                      placeholder="นามสกุล"
                      {...field}
                      errors={errors.lastname?.message}
                      disabled={isCannotEditDelete}
                    />
                  )}
                  name="lastname"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input">
              <div className="g_b_label">
                <div>บริษัท</div>
                <div className="sub_gray">Company</div>
              </div>
              <div className="input_sub">
                <div className="realm">{_companyRealm}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="title_b mt40">ข้อมูลการใช้งาน</div> */}
        <div className="layer_two">
          <div className="box_50">
            <div className="group_input">
              <div className="g_b_label">
                <div>Department</div>
                <div className="sub_gray">Department</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DropdownLoadmore
                      {...field}
                      endPoint={adminService.GET_DEPARTMENT_LIST}
                      epParameter={companyCurrent}
                      placeholder="Department"
                      disabled={isCannotEditDelete}
                      onChangeCustom={handleSelctDepartment}
                      errors={errors.department_id?.message}
                      // showOnTop
                    />
                  )}
                  name="department_id"
                  defaultValue={
                    !initialValues &&
                    deparmentList &&
                    deparmentList.length > 0 && {
                      label: deparmentList[0].name,
                      value: deparmentList[0].id,
                    }
                  }
                />
              </div>
            </div>

            {initialValues && initialValues.user && (
              <div className="group_input">
                <div className="g_b_label">
                  <div>เบอร์โทรศัพท์</div>
                  <div className="sub_gray">Phone Number</div>
                </div>
                <div className="input_sub">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputMaskText
                        theme_admin
                        placeholder="เบอร์โทรศัพท์"
                        maskFormat={
                          initialValues.user.mobile_no && "999-999-9999"
                        }
                        {...field}
                        disabled={disabledMobileNoEmail("mobile_no")}
                        errors={errors.mobile_no?.message}
                      />
                    )}
                    name="mobile_no"
                    defaultValue=""
                  />
                </div>
              </div>
            )}
            {/* ส่งลิงค์ตั้งค่ารหัสผ่าน */}
            {initialValues &&
              initialValues.user &&
              initialValues.user.is_lock === true && (
                <div className="group_input flex_row">
                  <div className="g_b_label">
                    <div>รหัสผ่าน</div>
                    <div className="sub_gray">Password</div>
                  </div>
                  <div className="green_text" onClick={handleSendPassword}>
                    ปลดล็อครหัสผ่าน
                  </div>
                </div>
              )}
          </div>
          <div className="box_50">
            <div className="group_input">
              <div className="g_b_label">
                <div>รหัสพนักงาน</div>
                <div className="sub_gray">Employee ID</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="ชื่อผู้ใช้งาน"
                      type={!isCreate && "number"}
                      {...field}
                      maxLength={20}
                      disabled={initialValues}
                      errors={errors.code?.message}
                    />
                  )}
                  name="code"
                  defaultValue=""
                />
              </div>
            </div>
            {initialValues && initialValues.user && (
              <div className="group_input">
                <div className="g_b_label">
                  <div>อีเมล</div>
                  <div className="sub_gray">Email</div>
                </div>
                <div className="input_sub">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputText
                        theme_admin
                        placeholder="อีเมล"
                        {...field}
                        disabled={disabledMobileNoEmail("email")}
                        errors={errors.email?.message}
                      />
                    )}
                    name="email"
                    defaultValue=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="layer_button">
          {initialValues &&
            !isCannotEditDelete &&
            perMissions &&
            perMissions.key === "employee_manage" && (
              <div className="w220">
                <Buttons.BtnNormal
                  theme_only_border_red
                  padding="12px 8px"
                  borderRadius="8px"
                  label="ลบบัญชี"
                  svg_front={<Icons.Delete5 />}
                  onClick={() => handleDelete && handleDelete("delete")}
                />
              </div>
            )}
          {!isCannotEditDelete && (
            <div className="w220">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                backgroundColor={theme.COLORS.BLUE_21}
                fontColor={theme.COLORS.PURPLE_1}
                type="submit"
                label={
                  isCreate ? "บันทึกการแก้ไขบัญชีผู้ใช้" : "บันทึกบัญชีผู้ใช้"
                }
                disabled={renderDisabled()}
                svg_front={
                  isCreate ? (
                    <Icons.Disk color={renderDisabledIcon()} />
                  ) : (
                    <Icons.Disk color={renderDisabledIcon()} />
                  )
                }
              />
            </div>
          )}
        </div>
      </form>
      <Modal
        theme_modal_standard
        isShow={_isShowModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        {_isShowModal}
      </Modal>
    </CreateUserAccountFormStyled>
  );
};

CreateUserAccountForm.propTypes = {};

export default CreateUserAccountForm;

export default function RewardEdit({
  color = "#002366",
  bgColor = "#fff",
  width = "18",
  height = "18",
}) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0353 10.8879V15.8087C18.0353 16.742 17.2809 17.4987 16.3499 17.4987H4.65022C3.7192 17.4987 2.96484 16.742 2.96484 15.8087V5.02203C2.96484 4.0887 3.7192 3.33203 4.65022 3.33203H10.5001"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.00732 14.3085L10.1311 13.531C10.2784 13.4943 10.4124 13.4185 10.5204 13.3118L17.5449 6.32016C18.1988 5.66932 18.1988 4.61432 17.5449 3.96349L17.4009 3.82016C16.747 3.16932 15.6871 3.16932 15.0332 3.82016L8.00867 10.8118C7.9015 10.9185 7.82531 11.0527 7.78847 11.1993L7.00732 14.3085"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.707 5.14062L16.2188 7.64062"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1202 13.5344C10.132 13.4461 10.147 13.3586 10.147 13.2669C10.147 12.1161 9.21015 11.1836 8.05391 11.1836C7.96182 11.1836 7.8739 11.1994 7.78516 11.2103"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

import React from "react";
import { connect } from "react-redux";
import { ForgotPasswordContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Forms, BGLoading, Icons, Buttons } from "components";
import { adminService } from "apiServices";
import {
  setReduxAuthen,
  setReduxCompanyList,
  setReduxCompanySelected,
} from "store/actions";
import { ROUTE_PATH } from "utils/constants";
import { toast } from "react-toastify";
import { renderErrorMessage } from "utils/functions/renderErrorMessage";
import { setReduxRewardCategory } from "store/actions";

class ForgotPasswordContainer extends React.Component {
  state = { isBgLoading: true, step: 1 };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isBgLoading: false,
      });
    }, 1000);
  }

  onSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    if (values) {
      let res = await adminService.POST_ADMIN_RESET_PASSWORD_BY_USERNAME(
        values.email
      );
      if (res && res.success) {
        this.props.history.push(
          `${ROUTE_PATH.FORGOT_PASSWORD}?ref_no=${res.data.ref_no} `
        );
        this.props.setReduxRewardCategory(values.email);
        this.setState({
          step: 2,
          errorMessage: null,
          email: res.data.email,
          dataCheckEmail: res.data,
          isBgLoading: false,
        });
      } else {
        let txtErr = renderErrorMessage(res.error_code, res.error);
        // toast.error(res.error);
        this.setState({
          errorMessage: txtErr,
          isBgLoading: false,
        });
      }
    }
  };

  onSubmitOTP = async (values) => {
    const { dataCheckEmail } = this.state;

    this.setState({
      isBgLoading: true,
    });

    let res = await adminService.GET_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      `?password=${values.otp}`
    );

    if (res && res.success) {
      this.setState({
        passwordOtp: values.otp,
        step: 3,
        email: values.email,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  onSubmitChangePassword = async (values) => {
    const { dataCheckEmail, passwordOtp } = this.state;

    this.setState({
      isBgLoading: true,
    });

    let params = {
      password: passwordOtp,
      new_password: values.password,
    };

    let res = await adminService.PUT_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      params
    );

    if (res && res.success) {
      toast.success("Change password success");
      this.setState({
        step: 4,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  renderError = (e) => {
    switch (e) {
      case "REFNO_INVALID":
        return "ref no. ไม่ถูกต้อง";

      case "OTP_EXPIRED":
        return "OTP หมดอายุ";

      case "PASSWORD_INCORRECT":
        return "รหัสไม่ถูกต้อง";

      default:
        return "เกิดข้อผิดพลาด";
    }
  };

  renderStep = () => {
    const { step, email, dataCheckEmail, errorMessage } = this.state;

    switch (step) {
      case 1:
        return (
          <Forms.ForgotPasswordForm
            onSubmit={this.onSubmit}
            errorMessage={errorMessage}
            handleClearTextError={this.handleClearTextError}
          />
        );

      case 2:
        return (
          <Forms.OTPForm
            onSubmit={this.onSubmitOTP}
            email={email}
            data={dataCheckEmail && dataCheckEmail}
          />
        );

      case 3:
        return (
          <Forms.ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
        );

      case 4:
        return (
          <div className="b_success">
            <div className="logo_icon">
              <Icons.RoundCheck width="56" height="56" />
            </div>
            <div className="text_b">สร้างรหัสผ่านใหม่สำเร็จ</div>
            <div className="fw">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label="เข้าสู่ระบบ"
                onClick={this.handleGoLogin}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };

  handleClearTextError = () => {
    this.setState({
      errorMessage: null,
    });
  };

  handleGoLogin = () => {
    this.props.history.push(ROUTE_PATH.LOGIN);
  };

  render() {
    const { isBgLoading } = this.state;

    return (
      <ForgotPasswordContainerStyled>
        <BGLoading visible={isBgLoading} />
        <div className="logo_show">
          <img
            className="img_logo"
            src={"/assets/images/logo/logo_login.svg"}
            alt="img"
          />
        </div>
        <div className="form_layout">{this.renderStep()}</div>
      </ForgotPasswordContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxCompanyList: (data) => dispatch(setReduxCompanyList(data)),
  setReduxCompanySelected: (data) => dispatch(setReduxCompanySelected(data)),
  setReduxRewardCategory: (data) => dispatch(setReduxRewardCategory(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);

export default function renderIcon({ width = "17", height = "17", color = '#646464' }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4291 14.8747H5.56412C4.8225 14.8747 4.20625 14.3023 4.151 13.5621L3.51562 4.95801H13.4564L12.8422 13.5586C12.7891 14.3002 12.1722 14.8747 11.4291 14.8747V14.8747Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.5026 7.79199V12.042" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.83594 4.95866H14.1693" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.0443 4.95833L11.3267 3.04442C11.1192 2.49121 10.5908 2.125 10 2.125H7.00519C6.41444 2.125 5.88602 2.49121 5.67848 3.04442L4.96094 4.95833" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.9283 7.79199L10.6237 12.042" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.06906 7.79199L6.37365 12.042" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { Table1Styled } from './styled';

const Table1 = ({ theme_standard_table, labelTop1, labelTop2, data }) => {
  const customClass = cx({
    theme_standard_table: theme_standard_table,
  });
  return (
    <Table1Styled>
      <div className={`chart_container ${customClass}`}>
        <div className='header'>
          <div className='h1'>
            {labelTop1}
          </div>
          <div className='h2'>
            {labelTop2}
          </div>
        </div>
        {
          data &&
          data.map((e, i) => (
            <div key={i + 1} className='row'>
              <div className='r1'>
                {e.label}
              </div>
              <div className='r2'>
                <div className='value_show green' style={{ width: `${e.percent1}%`, minWidth: e.percent1 > 0 && '50px' }}>
                  {e.percent1 > 0 && `${e.percent1}%`}
                </div>
                <div className='value_show blue' style={{ width: `${e.percent2}%`, minWidth: e.percent2 > 0 && '50px' }}>
                  {e.percent2 > 0 && `${e.percent2}%`}
                </div>
                <div className='value_show purple' style={{ width: `${e.percent3}%`, minWidth: e.percent3 > 0 && '50px' }}>
                  {e.percent3 > 0 && `${e.percent3}%`}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </Table1Styled>
  );
};

Table1.propTypes = {};

export default Table1;

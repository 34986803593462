export default function Kcal({ width = '61.678', height = '50.178' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 61.678 50.178">
      <g id="Group_14213" data-name="Group 14213" transform="translate(-213 -131.737)">
        <g id="diet" transform="translate(221.456 139.808)">
          <path id="Path_18999" data-name="Path 18999" d="M238.249,137.4a3.543,3.543,0,0,1,.051.6.116.116,0,0,0,.106.116,3.512,3.512,0,0,1,3.1,2.639.116.116,0,0,0,.08.083.115.115,0,0,0,.033,0,.116.116,0,0,0,.079-.031,3.681,3.681,0,0,1,2.183-.983.116.116,0,0,0,.105-.112,6.384,6.384,0,0,1,1.867-4.317c.024-.024.049-.047.074-.07a8.01,8.01,0,0,0-2.248-4.432.627.627,0,0,0-.887,0l-5.1,5.116A3.515,3.515,0,0,1,238.249,137.4Z" transform="translate(-210.047 -130.715)" fill="#ef5050" />
          <g id="Group_13713" data-name="Group 13713" transform="translate(30.33 2.459)">
            <path id="Path_19000" data-name="Path 19000" d="M261.967,201.307a.116.116,0,0,0,.08.083.114.114,0,0,0,.033,0,.116.116,0,0,0,.079-.031,3.718,3.718,0,0,1,.986-.662.869.869,0,0,0-.218-.365l-.847-.849a.872.872,0,0,0-1.233,0l-.007.008A3.5,3.5,0,0,1,261.967,201.307Z" transform="translate(-260.841 -193.724)" fill="#c0272d" />
            <path id="Path_19001" data-name="Path 19001" d="M270.4,181.623h-1.2a.872.872,0,0,1,0-1.744h1.2a.872.872,0,1,1,0,1.744Z" transform="translate(-267.458 -176.621)" fill="#c0272d" />
            <path id="Path_19002" data-name="Path 19002" d="M267.748,154.454a.872.872,0,0,1-.617-1.488l.847-.849a.872.872,0,0,1,1.234,1.232l-.847.849A.87.87,0,0,1,267.748,154.454Z" transform="translate(-266.174 -151.862)" fill="#c0272d" />
          </g>
          <path id="Path_19003" data-name="Path 19003" d="M299.854,154.467a.206.206,0,0,0-.116-.116,6.5,6.5,0,0,0-8.9,5.96c.121-.012.243-.018.367-.018a3.733,3.733,0,0,1,3.655,3.015,6.516,6.516,0,0,0,5-8.84Z" transform="translate(-257.017 -151.194)" fill="#89c947" />
          <path id="Path_19004" data-name="Path 19004" d="M309.158,182.935a.872.872,0,0,0-1.233,0l-3.017,3.024a3.736,3.736,0,0,1,1.476.99l2.775-2.782A.872.872,0,0,0,309.158,182.935Z" transform="translate(-269.454 -176.638)" fill="#d5e962" />
          <g id="Group_13714" data-name="Group 13714" transform="translate(11.652 7.699)">
            <path id="Path_19005" data-name="Path 19005" d="M355.929,202.49a3.187,3.187,0,0,1,.046.538.116.116,0,0,0,.106.116,3.137,3.137,0,0,1,1.337.435.116.116,0,0,0,.147-.023c.1-.114.184-.206.268-.289a5.824,5.824,0,0,1,2.476-1.479,3.841,3.841,0,1,0-7.439-1.916A3.153,3.153,0,0,1,355.929,202.49Z" transform="translate(-323.491 -196.929)" fill="#ffc502" />
            <path id="Path_19006" data-name="Path 19006" d="M106.322,257.506a5.993,5.993,0,0,1-.73-2.226A3.5,3.5,0,0,1,103,253.35a3.826,3.826,0,0,0-2.769,4.156h6.088Z" transform="translate(-100.206 -246.79)" fill="#ffc502" />
          </g>
          <path id="Path_19007" data-name="Path 19007" d="M393.36,239.961a3.137,3.137,0,0,1-.258,1.892h4.935a5.991,5.991,0,0,0,.689-5.684.191.191,0,0,0-.107-.107,5.958,5.958,0,0,0-6.429,1.329c-.123.124-.239.253-.349.385A3.161,3.161,0,0,1,393.36,239.961Z" transform="translate(-346.281 -223.438)" fill="#89c947" />
          <path id="Path_19008" data-name="Path 19008" d="M402.678,264.68h1.078l1.72-1.724a.872.872,0,0,0-1.235-1.232L402.965,263A3.132,3.132,0,0,1,402.678,264.68Z" transform="translate(-355.856 -246.264)" fill="#d5e962" />
          <path id="Path_19009" data-name="Path 19009" d="M137.848,168.644a3.629,3.629,0,0,0-3.22-2.822,3.635,3.635,0,0,0-6.668-2,3.636,3.636,0,0,0-5.606,4.132,3.63,3.63,0,0,0,.939,6.883,5.86,5.86,0,0,1,.358-3,.074.074,0,0,1,.017-.025.076.076,0,0,1,.025-.016,5.84,5.84,0,0,1,6.3,1.3c.08.08.157.163.232.247a8.012,8.012,0,0,1-.094-1.223.512.512,0,0,1,.511-.512h6.089c-.005-.081-.009-.162-.009-.245A3.842,3.842,0,0,1,137.848,168.644Z" transform="translate(-106.258 -158.534)" fill="#368c0e" />
          <g id="Group_13715" data-name="Group 13715" transform="translate(17.982 6.841)">
            <path id="Path_19010" data-name="Path 19010" d="M155.524,193.161h-.015a.872.872,0,0,1-.857-.887,2.774,2.774,0,0,1,2.77-2.722h.044a2.776,2.776,0,0,1,2,.9.872.872,0,0,1-1.288,1.176,1.011,1.011,0,0,0-.738-.332A1.026,1.026,0,0,0,156.4,192.3.872.872,0,0,1,155.524,193.161Z" transform="translate(-154.652 -189.552)" fill="#89c947" />
            <path id="Path_19011" data-name="Path 19011" d="M196.93,211.962a.873.873,0,0,1-.818-1.176,2.54,2.54,0,0,1,4.719-.113.872.872,0,1,1-1.605.682.785.785,0,0,0-.455-.434.8.8,0,0,0-1.024.47A.872.872,0,0,1,196.93,211.962Z" transform="translate(-191.244 -206.852)" fill="#89c947" />
          </g>
          <path id="Path_19012" data-name="Path 19012" d="M324.657,223.751h-.032c0,.008,0,.016,0,.025h1.168a.628.628,0,0,1,.627.629,8.008,8.008,0,0,1-1.518,4.7h8.8a3.273,3.273,0,0,0-2.7-4.617,3.283,3.283,0,0,0-6.023-1.8,3.294,3.294,0,0,0-.5-.306,3.728,3.728,0,0,1,.179,1.141C324.665,223.593,324.662,223.67,324.657,223.751Z" transform="translate(-286.756 -210.684)" fill="#368c0e" />
          <path id="Path_19013" data-name="Path 19013" d="M268.631,212.057c.005-.081.009-.162.009-.245a3.841,3.841,0,1,0-7.682,0c0,.082,0,.164.009.245Z" transform="translate(-230.616 -198.981)" fill="#f7931e" />
          <path id="Path_19014" data-name="Path 19014" d="M151.967,241.936a5.958,5.958,0,0,0-6.429-1.329.2.2,0,0,0-.064.042.191.191,0,0,0-.042.065,5.991,5.991,0,0,0,.353,5.156H153.7a8.025,8.025,0,0,1-1.536-3.726Q152.068,242.038,151.967,241.936Z" transform="translate(-128.148 -227.454)" fill="#89c947" />
          <path id="Path_19015" data-name="Path 19015" d="M172.2,268.7h2.464l-2.422-2.427a.872.872,0,0,0-1.235,1.232Z" transform="translate(-150.898 -250.28)" fill="#d5e962" />
          <path id="Path_19016" data-name="Path 19016" d="M218.767,247.629a8.014,8.014,0,0,0,1.613-4.827.628.628,0,0,0-.627-.629H204.947a.628.628,0,0,0-.627.629,8.014,8.014,0,0,0,1.613,4.827Z" transform="translate(-180.562 -229.214)" fill="#ef5050" />
          <g id="Group_13716" data-name="Group 13716" transform="translate(25.726 14.319)">
            <path id="Path_19017" data-name="Path 19017" d="M267.894,277.878v-.6a.872.872,0,0,0-1.744,0v.6Z" transform="translate(-260.929 -273.782)" fill="#c0272d" />
            <path id="Path_19018" data-name="Path 19018" d="M287.653,271.817h1.356a.872.872,0,0,0-.058-1.168l-.847-.849a.872.872,0,1,0-1.235,1.232Z" transform="translate(-279.015 -267.722)" fill="#c0272d" />
            <path id="Path_19019" data-name="Path 19019" d="M238.773,271.818l.783-.785a.872.872,0,0,0-1.235-1.232l-.847.849a.872.872,0,0,0-.058,1.168Z" transform="translate(-235.363 -267.722)" fill="#c0272d" />
            <path id="Path_19020" data-name="Path 19020" d="M223.319,255.609h-1.2a.872.872,0,0,1,0-1.744h1.2a.872.872,0,0,1,0,1.744Z" transform="translate(-221.25 -253.865)" fill="#c0272d" />
            <path id="Path_19021" data-name="Path 19021" d="M302.277,255.609h-1.2a.872.872,0,1,1,0-1.744h1.2a.872.872,0,1,1,0,1.744Z" transform="translate(-291.026 -253.865)" fill="#c0272d" />
          </g>
          <path id="Path_19022" data-name="Path 19022" d="M87.007,294.854a.116.116,0,0,0,.148-.112v-1.476a.756.756,0,1,1,1.512,0v1.476a.116.116,0,0,0,.116.116.112.112,0,0,0,.032,0,7.837,7.837,0,0,1,2.087-.263c2.117,0,3.539.686,3.539,1.327a.524.524,0,0,1-.075.258.116.116,0,0,0,.057.165,8.055,8.055,0,0,1,5.012,6.184.116.116,0,0,0,.115.1.118.118,0,0,0,.041-.008l18.357-6.974a3.11,3.11,0,0,1,4.165,2.331l.16.863a.116.116,0,0,0,.114.1.115.115,0,0,0,.059-.016l6.172-3.643a21.624,21.624,0,0,0,.8-5.862.6.6,0,0,0-.6-.6H86.822a.6.6,0,0,0-.6.6,21.679,21.679,0,0,0,.68,5.416Z" transform="translate(-76.196 -270.425)" fill="#e6e9ed" />
          <path id="Path_19023" data-name="Path 19023" d="M87.007,294.854a.116.116,0,0,0,.148-.112v-1.476a.756.756,0,1,1,1.512,0v1.476a.116.116,0,0,0,.116.116.11.11,0,0,0,.032,0,7.572,7.572,0,0,1,1.764-.257,21.714,21.714,0,0,1-.621-5.186.6.6,0,0,1,.6-.6H86.822a.6.6,0,0,0-.6.6,21.679,21.679,0,0,0,.68,5.416Z" transform="translate(-76.196 -270.426)" opacity="0.1" />
          <path id="Path_19026" data-name="Path 19026" d="M3.773,359.631A7.815,7.815,0,0,1,7.279,353.2a3.061,3.061,0,0,1-2.011-.937A8.035,8.035,0,0,0,0,359.631c0,4.428,3.9,8.018,8.721,8.018h3.773C7.678,367.65,3.773,364.06,3.773,359.631Z" transform="translate(0 -326.506)" opacity="0.1" />
          <path id="Path_19028" data-name="Path 19028" d="M51.152,56.465s-2.4,18.624-9.4,18.509-27.15,0-27.15,0L9.029,62.481l34.3-7.979Z" transform="translate(1.878 -33.818)" fill="#e6e9ed" />
          <path id="Path_19024" data-name="Path 19024" d="M94.124,322.523a4.049,4.049,0,0,1,.872-.346V320.52a.872.872,0,1,0-1.744,0v1.657A4.033,4.033,0,0,1,94.124,322.523Z" transform="translate(-82.409 -297.68)" fill="#89c947" />
          <path id="Path_19025" data-name="Path 19025" d="M14.64,367.649c-1.537-.494.047-1.182-.224-2.65l-.031-.167a3.093,3.093,0,0,1,1.949-3.453l7.036-2.673a8.134,8.134,0,0,0-5.209-6.439,3.5,3.5,0,0,1-2.429,1,.116.116,0,0,0-.059.206,2.562,2.562,0,0,1,1.063,1.427.441.441,0,0,1-.106.348,1.12,1.12,0,0,1-.843.256h0a6.387,6.387,0,0,1-2.84-.878,7.16,7.16,0,0,1-1.157-.787.116.116,0,0,0-.15,0,7.145,7.145,0,0,1-1.157.787,6.386,6.386,0,0,1-2.84.878,1.12,1.12,0,0,1-.843-.256.441.441,0,0,1-.106-.348,2.564,2.564,0,0,1,1.063-1.427.116.116,0,0,0-.059-.206,3.5,3.5,0,0,1-2.429-1A8.035,8.035,0,0,0,0,359.63c0,4.428,3.9,8.018,8.721,8.018Z" transform="translate(0 -326.505)" fill="#ef5050" />
          <path id="Path_19027" data-name="Path 19027" d="M56.887,338.982c0-.8-1.636-1.443-3.655-1.443a6.482,6.482,0,0,0-2.991.614,6.479,6.479,0,0,0-2.99-.614c-2.019,0-3.655.646-3.655,1.443,0,.654,1.1,1.206,2.614,1.384-.925.74-1.348,1.517-.971,1.943.5.564,2.213.3,3.827-.6a7.287,7.287,0,0,0,1.176-.8,7.314,7.314,0,0,0,1.176.8c1.614.893,3.327,1.161,3.826.6.377-.425-.046-1.2-.971-1.943C55.784,340.189,56.887,339.636,56.887,338.982Z" transform="translate(-38.526 -313.49)" fill="#368c0e" />
          <path id="Path_19029" data-name="Path 19029" d="M8.954,365l.031-.167a3.093,3.093,0,0,0-1.949-3.453L0,358.705a8.134,8.134,0,0,1,5.209-6.439,3.5,3.5,0,0,0,2.429,1,.116.116,0,0,1,.059.206A2.562,2.562,0,0,0,6.635,354.9a.441.441,0,0,0,.106.348,1.121,1.121,0,0,0,.843.256h0a6.387,6.387,0,0,0,2.84-.878,7.16,7.16,0,0,0,1.157-.787.116.116,0,0,1,.15,0,7.145,7.145,0,0,0,1.157.787,6.386,6.386,0,0,0,2.84.878,1.12,1.12,0,0,0,.843-.256.441.441,0,0,0,.106-.348,2.564,2.564,0,0,0-1.063-1.427.116.116,0,0,1,.059-.206,3.5,3.5,0,0,0,2.429-1,8.035,8.035,0,0,1,5.268,7.364c0,4.428-3.9,8.018-8.721,8.018C14.649,367.649,8.683,366.467,8.954,365Z" transform="translate(0 -326.505)" fill="#ef5050" />
        </g>
        <g id="burn" transform="translate(213 131.737)">
          <g id="Group_13689" data-name="Group 13689" transform="translate(0 0)">
            <path id="Path_18960" data-name="Path 18960" d="M23.959,33a22.824,22.824,0,0,0-2.069,6.815c-1.27,9.987,7.437,17.971,18.812,17.971s20.6-8.147,20.6-18.2a16.32,16.32,0,0,0-.985-4.5c-.9,5.259-5.84,8.236-5.84,8.236a17.131,17.131,0,0,0-2.246-18.059C43.916,14.755,46.05,7.611,46.05,7.611,34.931,16.343,35.605,25.968,35.605,25.968c-1.021-5.838-9.369-8.16-9.369-8.16C28.99,21.933,28,25.13,26.151,28.662,24.8,31.236,23.959,33,23.959,33Z" transform="translate(-21.767 -7.611)" fill="#fddb33" />
          </g>
          <g id="Group_13690" data-name="Group 13690" transform="translate(4.469 0)">
            <path id="Path_18961" data-name="Path 18961" d="M70.8,118.747h0c-1.258-6.061-9.375-8.634-9.375-8.634a12.454,12.454,0,0,1,1.439,3.464C65.539,114.4,70.8,118.747,70.8,118.747Z" transform="translate(-61.425 -100.158)" fill="#f9c032" />
            <path id="Path_18962" data-name="Path 18962" d="M202.095,27.617c3.674,5.266,7.513,9.9,6.827,20.42h0a24.617,24.617,0,0,0,1.585-8.185,21.069,21.069,0,0,0-3.838-12.235c-8.311-11.914-6.177-20.006-6.177-20.006a35.148,35.148,0,0,0-4.67,5.022C196.248,16.164,197.732,21.363,202.095,27.617Z" transform="translate(-180.677 -7.611)" fill="#f9c032" />
            <path id="Path_18963" data-name="Path 18963" d="M191.349,283.9a14.187,14.187,0,0,1-3.721,7.421,20.61,20.61,0,0,1-18.185,18.156,21,21,0,0,0,2.294.126,19.516,19.516,0,0,0,19.612-25.7Z" transform="translate(-157.271 -259.423)" fill="#f9c032" />
          </g>
          <path id="Path_18967" data-name="Path 18967" d="M148.416,326.011a11.342,11.342,0,0,1,1.352,4.195,6.182,6.182,0,0,1-12.364,0c0-3.414,6.182-12.284,6.182-12.284s1.423,2.042,2.9,4.544Z" transform="translate(-124.373 -289.613)" fill="#ff7373" />
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const ParticipantsDetailsStyled = styled.div`
  .title_participant_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .left_col {
      .title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      }
      .sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .right_col {
      display: flex;
      align-items: center;
      .date_wrap {
        margin-left: 14px;
      }
    }
  }
  .row {
    display: flex;
    // flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    overflow: auto;
    overflow-y: hidden;
    padding: 4px 3px;
    .col_3 {
      &.w_327 {
        flex: 1;
        min-width: 327px;
        flex-shrink: 0;
      }
      &.w_415 {
        flex: 1;
        min-width: 415px;
        flex-shrink: 0;
        padding-right: 2px;
      }
    }
  }
`;

import { useState, useEffect } from "react";
import { BodyMassAgeGenderStyled } from "./styled";
import { Blocks, Inputs, Typographys, Skeleton } from "components";
import Chart from "react-apexcharts";
import moment from "moment";
import theme from "styles/theme.json";

const BodyMassAgeGender = ({ data, fetchData }) => {
  const [_data, _setData] = useState();
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);
    if (res) {
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };
  const handleChangDate = (e) => {
    _setLoadSkeleton(true);
    fetchDataApi(moment(e).startOf("months").format("YYYY-MM-DD"));
  };
  return (
    <BodyMassAgeGenderStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="26px"
        paddingRight="24px"
        paddingBottom="20px"
        paddingLeft="24px"
      >
        <div className="body_mass_container">
          <div className="body_mass_left_col">
            <Typographys.TopBottom
              theme_left_align
              label="BMI แบ่งตามเพศและอายุ"
              labelBottom="Body Mass Index by age and gender"
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="body_mass_right_col">
            <div className="item_right">
              <Typographys.TopBottom
                theme_left_align
                // label="แสดงสถิติ"
                // labelBottom="Show statistics for"
                fontSizeLabelButtom="14px"
              />
            </div>
            <div className="item_right w_200 ml_14">
              <Inputs.InputDatePikerCustom
                theme_standard_date_picker_custom
                dateFormat="MMMM yyyy"
                onChangeCustom={(e) => handleChangDate(e)}
                showMonthYearPicker
                showFullMonthYearPicker
                initialValues={new Date()}
              />
            </div>
          </div>
        </div>
        {_data ? (
          <>
            <div className="bmi_trend_graph">
              <div className="label_top">Percent</div>
              <div className="show_graph">
                <div className="graph_wrap">
                  {_loadSkeleton ? (
                    <Skeleton.GraphSkeleton />
                  ) : (
                    <Chart
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                          fontFamily: theme.FONT.STYLE.REGULAR,
                          stacked: true,
                          stackType: "100%",
                          toolbar: {
                            show: false,
                          },
                        },
                        // responsive: [
                        //   {
                        //     breakpoint: 480,
                        //     options: {
                        //       legend: {
                        //         position: 'bottom',
                        //         offsetX: -10,
                        //         offsetY: 0,
                        //       },
                        //     },
                        //   },
                        // ],
                        xaxis: {
                          categories: [
                            ["ชาย", "Men"],
                            ["หญิง", "Women"],
                            ["", ""],
                            ["ชาย", "Men"],
                            ["หญิง", "Women"],
                            ["", ""],
                            ["ชาย", "Men"],
                            ["หญิง", "Women"],
                            ["", ""],
                            ["ชาย", "Men"],
                            ["หญิง", "Women"],
                            ["", ""],
                            ["ชาย", "Men"],
                            ["หญิง", "Women"],
                          ],
                          labels: {
                            rotate: 0,
                            // formatter: function (value, timestamp, opts) {
                            //   return parse(value)
                            // }
                            style: {
                              colors: [],
                              fontSize: "12px",
                              fontFamily:
                                "noto_sans_thai_semi_bold, sans-serif",
                              fontWeight: 100,
                              cssClass: "apexcharts-yaxis-label",
                            },
                          },
                          axisBorder: {
                            show: true,
                            color: theme.COLORS.PURPLE_1,
                            height: 3,
                          },
                        },
                        yaxis: {
                          axisBorder: {
                            show: true,
                            color: theme.COLORS.PURPLE_1,
                            width: 3,
                          },
                        },
                        colors: ["#654C96", "#8AC47F", "#6FB5CB", "#43519C"],
                        fill: {
                          opacity: 1,
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "80%",
                          },
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      series={[
                        {
                          name: "ต่ำกว่ามาตรฐาน  (เกณฑ์ BMI < 18.5)",
                          data: _data?.under,
                        },
                        {
                          name: "สมส่วน  (เกณฑ์ BMI = 18.5 ถึง <23)",
                          data: _data?.normal,
                        },
                        {
                          name: "เกินมาตรฐาน  (เกณฑ์ BMI =>23 ถึง <25)",
                          data: _data?.over,
                        },
                        {
                          name: "ภาวะอ้วน  (เกณฑ์ BMI=>25)",
                          data: _data?.obese,
                        },
                      ]}
                      type="bar"
                      height={350}
                      width="100%"
                    />
                  )}
                </div>
                {_loadSkeleton === false && (
                  <div className="cate_row">
                    {cateLabel &&
                      cateLabel.map((e, i) => (
                        <div key={i} className="cate_label_item">
                          {e}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div className="show_group_label">
                <Typographys.LabelGraph2
                  theme_standard_label_graph
                  data={dataLabel}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </BodyMassAgeGenderStyled>
  );
};

const cateLabel = [
  "18 - 19 Years",
  "20 - 34 Years",
  "35 - 49 Years",
  "50 - 64 Years",
  "65 Years and older",
];

const dataLabel = [
  {
    color: "#43519C",
    label: "ภาวะอ้วน  (เกณฑ์ BMI=>25)",
    labelBottom: "Obese",
  },
  {
    color: "#6FB5CB",
    label: "เกินมาตรฐาน  (เกณฑ์ BMI =>23 ถึง <25)",
    labelBottom: "Overweight",
  },
  {
    color: "#8AC47F",
    label: "สมส่วน  (เกณฑ์ BMI = 18.5 ถึง <23)",
    labelBottom: "Normal weight",
  },
  // {
  //   color: '#6FB5CB',
  //   label: 'เล็กน้อย 1-3 วัน',
  //   labelBottom: 'Soft Activity',
  // },
  {
    color: "#654C96",
    label: "ต่ำกว่ามาตรฐาน  (เกณฑ์ BMI < 18.5)",
    labelBottom: "Underweight",
  },
];

export default BodyMassAgeGender;

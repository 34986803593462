import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { RewardManagmentContainerStyled } from "./styled";
import {
  Tabs,
  Icons,
  Inputs,
  Typographys,
  Buttons,
  TableCustom,
  Loading,
} from "components";
import { useHistory } from "react-router-dom";
import { WeeklyPopular, MostPicked } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import { adminService } from "apiServices";
import { setReduxRewardCategory } from "store/actions";
import moment from "moment";
import { DIF_DATE_2 } from "utils/functions/date";
import theme from "styles/theme.json";
import { getObjectKey } from "utils/functions/getObjectKey";
import { useSelector, useDispatch } from "react-redux";

const RewardManagmentContainer = () => {
  const { companySelectedRedux, authenRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [loadSkeleton, setLoadSkeleton] = useState(true);
  // const [keyword, setKeyword] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("category_id=1");
  const [dataRewardCategory, setDataRewardCategory] = useState();
  const [dataPopularRewardItems, setDataPopularRewardItems] = useState();
  const [dataRewardList, setDataRewardList] = useState({ data: [] });
  const [, setHasMore] = useState(true);
  const [perMissions, setPerMissions] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState();
  const [inputStartDateFilter, setInputStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();
  const [inputEndDateFilter, setInputEndDateFilter] = useState();
  const [isSortFilter, setIsSortFilter] = useState();
  const [queryFilterAll, setQueryFilterAll] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    per_page: 10,
  });
  const [, setIsReset] = useState();
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const fetchData = async () => {
    let res = await Promise.all([
      fetchRewardCategoryData(),
      fecthRewardList("?category_id=1&per_page=5"),
      fetchRewardItemListCompany(),
      fetchPopularRewardItems(),
      checkPermission(),
    ]);

    if (res[0] || res[1]) {
      setDataRewardCategory(res[0]);
      setDataPopularRewardItems(res[3]);
      // dataRewardItemListCompany: res[2],
      setPerMissions(res[4]);
      setIsLoading(false);
      dispatch(setReduxRewardCategory(res[0]));
    } else {
      setPerMissions(res[4]);
      setIsLoading(false);
    }
  };

  const fetchRewardCategoryData = async () => {
    let res = await adminService.GET_REWARD_CATEGORIES();
    if (res && res.success) {
      return res.data;
    }
  };

  const fecthRewardList = async (stringQuery) => {
    let res = await adminService.GET_REWARD_LIST_BY_CATEGORIES(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setDataRewardList(
        res.data.map((e) => ({
          ...e,
          rowSty:
            e.redeemable_count - e.redeemed_count <= 0 ||
            moment(e.end_date).diff(moment().format("YYYY-MM-DD")) < 0
              ? { background: theme.COLORS.BLUE_1 }
              : false,
        }))
      );
      setPagination({ ...res.page });
      setIsReset(false);
      setLoadSkeleton(false);
    } else {
      setDataRewardList({ data: [] });
      setLoadSkeleton(false);
    }
  };

  const fetchRewardItemListCompany = async () => {
    let stringQuery = "?sort_by=created_at&per_page=3";
    let res = await adminService.GET_REWARD_ITEM_LIST_COMPANY(
      companySelectedRedux?.id,
      stringQuery
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchPopularRewardItems = async () => {
    let res = await adminService.GET_POPULAR_REWARD_ITEMS(
      companySelectedRedux?.id
    );

    if (res && res.success) {
      return res.data;
    }
  };

  const checkPermission = () => {
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "reward_manage"
    );
    return checkPermission;
  };

  const handleClickLog = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_LOG}`);
    // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_LOG}`);
  };

  const handleClickSearch = (e) => {
    let splitCateID = categoryFilter.split("=");
    let queryString = `?category_id=${splitCateID[splitCateID.length - 1]}`;
    if (e) {
      queryString += `&keyword=${e}`;
    }
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_SEARCH}${queryString}`);
  };

  const handleBackClick = () => {
    history.push(ROUTE_PATH.BACK_OFFICE);
  };
  const handleAddNewReward = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_NEW_DETAIL}/create`);
  };
  const handleRewardHistory = () => {
    history.push(ROUTE_PATH.BACK_OFFICE_REWARD_HISTORY);
  };
  const handleDetailHistory = (e) => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_DETAILS}/${e.id}`);
  };

  const handleClickExchangeReward = (e) => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE}/${e.id}`);
  };

  //FILTER PROCESS
  //=======================================
  //=======================================
  const handleFilterDate = (key, value) => {
    switch (key) {
      case "categoryFilter":
        setCategoryFilter(`category_id=${value.id}`);
        setIsReset(true);
        setHasMore(true);
        handleJoinQuery({ category_id: `category_id=${value.id}` });
        break;
      case "startDate":
        if (value) {
          // let end_date = `end_date_after=${moment(value)
          //   .add(30, "days")
          //   .format("YYYY-MM-DD")}`;
          let start_date_input = new Date(moment(value).format("YYYY-MM-DD"));
          let start_date = `begin_date_before=${moment(value)
            // .add(30, "days")
            .format("YYYY-MM-DD")}`;
          // let end_date_input = new Date(
          //   moment(value).add(1, "months").format("YYYY-MM-DD")
          // );

          setStartDateFilter(start_date);
          // setEndDateFilter(end_date);
          setInputStartDateFilter(start_date_input);
          setInputEndDateFilter(null);
          setEndDateFilter(null);
          setIsReset(true);
          setHasMore(true);
          handleJoinQuery({ start_date });
        } else {
          setStartDateFilter(false);
          setEndDateFilter(false);
          setIsReset(true);
          setHasMore(true);
          handleJoinQuery({ start_date: false });
        }
        break;
      case "endDate":
        if (value) {
          let end_date = `end_date_after=${moment(value).format("YYYY-MM-DD")}`;
          let end_date_input = new Date(
            moment(value).add(1, "months").format("YYYY-MM-DD")
          );
          setInputEndDateFilter(end_date_input);
          setEndDateFilter(end_date);
          setInputStartDateFilter(null);
          setStartDateFilter(null);

          setIsReset(true);
          setHasMore(true);
          handleJoinQuery({ end_date });
        } else {
          setEndDateFilter(false);
          setIsReset(true);
          setHasMore(true);
          handleJoinQuery({ end_date: false });
        }

        break;
      case "sort":
        setIsSortFilter(`sort_by=end_date&order_by=${value}`);
        setIsReset(true);
        setHasMore(true);
        handleJoinQuery({ sort: `sort_by=end_date&order_by=${value}` });

        break;
      default:
        break;
    }
  };

  const handleJoinQuery = (e) => {
    console.log(e);
    let queryString = [];

    if (e?.category_id) {
      queryString.push(e.category_id);
    } else if (categoryFilter) {
      queryString.push(categoryFilter);
    }

    if (e?.start_date === undefined && e?.end_date === undefined) {
      if (startDateFilter) {
        queryString.push(startDateFilter);
      }
      if (endDateFilter) {
        queryString.push(endDateFilter);
      }
    }

    if (e?.start_date) {
      queryString.push(e.start_date);
    }

    if (e?.end_date) {
      queryString.push(e?.end_date);
    }

    if (e?.sort) {
      queryString.push(e?.sort);
    } else if (isSortFilter) {
      queryString.push(isSortFilter);
    }

    setLoadSkeleton(true);
    if (queryString && queryString.length > 0) {
      fecthRewardList(`?${queryString.join("&")}&per_page=5`);
      setQueryFilterAll(`${queryString.join("&")}&per_page=5`);
      history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}`);
    } else {
      fecthRewardList();
      setQueryFilterAll(false);
      history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}`);
    }
  };

  const handlePageClick = (e) => {
    let query = `?per_page=5&offset=${e.selected * 5}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}${query}`);
    fecthRewardList(query);
  };

  const handleBack = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE}`);
  };

  return (
    <RewardManagmentContainerStyled>
      {isLoading ? (
        <div className="show_loading">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="layer_one">
            <Tabs.HeadTab
              theme_standard_head_tab
              title="จัดการรางวัล"
              subTitle="Rewards Management"
              fontSize="32px"
              fontSizeLabelButtom="18px"
              fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              fontColor="#013D59"
              colorBottom="#9E9E9E"
              lineHeight="1.3"
              svg={<Icons.Bonus />}
              onClick={handleBackClick}
              handleBackCustom={handleBack}
              typeLog
              handleClickButton={handleClickLog}
            />
          </div>
          <div className="serach_panel">
            <div className="search_layout">
              <Inputs.InputSearch
                theme_standard_search
                placeholder="Search by Reward name"
                onSubmit={(e) => handleClickSearch(e)}
              />
            </div>
            {perMissions && (
              <div className="add_reward" onClick={handleAddNewReward}>
                <Buttons.BtnRoundShadow
                  theme_btn_round_shadow_orange
                  label="เพิ่มรางวัลใหม่"
                  svg={<Icons.Magic />}
                  imgWidth="20px"
                  imgHeight="20px"
                />
              </div>
            )}
          </div>
          <div className="layer_two">
            <Typographys.TopBottom
              theme_left_align
              label="รางวัลยอดนิยมประจำสัปดาห์"
              labelBottom="Weekly Popular Rewards"
              fontSizeLabelButtom="14px"
            />
            <div className="group_two">
              <div className="group_l">
                <div className="pop_show">
                  <WeeklyPopular
                    data={dataPopularRewardItems}
                    handleOnClick={(e) => handleDetailHistory(e)}
                  />
                </div>
              </div>
              <div className="group_r">
                <MostPicked companyID={companySelectedRedux?.id} />
              </div>
            </div>
          </div>
          <div className="layer_three">
            <div className="group_line">
              <div className="left_line">
                <div className="title_list">
                  <div className="icon_list">
                    <Icons.List />
                  </div>
                  <Typographys.TopBottom
                    theme_left_align
                    label="รายชื่อรางวัลทั้งหมด"
                    labelBottom="All Rewards list"
                    fontSizeLabelButtom="14px"
                  />
                </div>
              </div>
              <div className="category_layout">
                <Buttons.BtnCategroyFillter
                  theme_standard_category_filter
                  data={dataRewardCategory}
                  // onClick={(e) => this.handleSelectCategory(e)}
                  onClick={(e) => handleFilterDate("categoryFilter", e)}
                />
              </div>
            </div>
          </div>
          <div className="layer_four">
            <div className="history_button" onClick={handleRewardHistory}>
              <Buttons.BtnRoundShadow
                theme_btn_round_shadow_white
                label="ดูประวัติรางวัล"
                imgWidth="31px"
                imgHeight="25px"
                svg={<Icons.Certicficate />}
              />
            </div>
            <TableCustom.TableNormal
              theme_standard_table_normal
              showTitle="รางวัลในระบบขณะนี้"
              columns={columns({
                inputStartDateFilter: inputStartDateFilter,
                endDate: inputEndDateFilter,
                handleFilterDate: handleFilterDate,
                handleDetailHistory: handleDetailHistory,
                handleClickExchangeReward: handleClickExchangeReward,
              })}
              data={dataRewardList}
              useNo
              useRenderNoPage
              labelEmpty="ไม่มีการแลกรางวัล"
              loading={loadSkeleton}
              showPagination
              pagination={pagination}
              handlePageClick={handlePageClick}
            />
          </div>
        </>
      )}
    </RewardManagmentContainerStyled>
  );
};

const columns = ({
  inputStartDateFilter,
  endDate,
  handleFilterDate,
  handleDetailHistory,
  handleClickExchangeReward,
}) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
    style: { textAlign: "center", width: "30px", flexShrink: 0 },
  },
  {
    title: "แบรนด์",
    field: "name",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>{value}</div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันเริ่ม
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={inputStartDateFilter}
            onChangeCustom={(e) => handleFilterDate("startDate", e)}
          />
        </div>
      </div>
    ),
    field: "begin_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันหมดเขต
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={endDate}
            minDate={inputStartDateFilter}
            onChangeCustom={(e) => handleFilterDate("endDate", e)}
          />
        </div>
      </div>
    ),
    field: "end_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <div>วันคงเหลือ</div>
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputSort
            theme_standard_sort
            onChangeCustom={(e) => handleFilterDate("sort", e)}
          />
        </div>
      </div>
    ),
    field: "remain_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${DIF_DATE_2(origin.begin_date, origin.end_date)} วัน`}
      </div>
    ),
  },
  {
    title: "สิทธิ์คงเหลือ",
    field: "remain",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${
          origin.total_redeemable_count - origin.redeemed_count > 0
            ? origin.total_redeemable_count - origin.redeemed_count
            : 0
        } สิทธิ์`}
      </div>
    ),
  },
  // {
  //   title: "",
  //   field: "status",
  //   headerStyle: { textAlign: "left" },
  //   style: { textAlign: "left" },
  //   render: ({ origin, value }) => (
  //     <div className="show_new" onClick={() => handleDetailHistory(origin)}>
  //       NEW
  //     </div>
  //   ),
  // },
  {
    title: "",
    field: "button",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <Buttons.BtnNormal
        theme_standard_btn_round_red_bold
        label="แลกของรางวัล"
        fontFamilyBottom="noto_sans_thai_bold, noto_sans_bold;"
        colorBottom="#F0211D"
        fontSize="16px"
        padding="5px"
        onClick={() => handleClickExchangeReward(origin)}
      />
    ),
  },
];

export default RewardManagmentContainer;

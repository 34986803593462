export default function renderIcon({ color = "#0B408D" }) {
  return (
    <svg
      id="_007-up-arrow-1"
      data-name="007-up-arrow-1"
      xmlns="http://www.w3.org/2000/svg"
      width="35.57"
      height="40"
      viewBox="0 0 35.57 40"
    >
      <g id="Group_2199" data-name="Group 2199" transform="translate(0)">
        <path
          id="Path_5452"
          data-name="Path 5452"
          d="M70.3,21.321,52.876.112a.343.343,0,0,0-.507,0L34.91,21.362a.305.305,0,0,0-.037.331.325.325,0,0,0,.291.177h6.451V39.692a.338.338,0,0,0,.349.308h21.36a.3.3,0,0,0,.312-.308V21.87h6.448a.316.316,0,0,0,.327-.313A.309.309,0,0,0,70.3,21.321Z"
          transform="translate(-34.84 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

import React from "react";
import { AccountManagementContainerStyled } from "./styled";
import {
  Buttons,
  Icons,
  TableCustom,
  Loading,
  Blocks,
  BGLoading,
  Inputs,
  Tabs,
  // Inputs,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { getObjectKey2 } from "utils/functions/getObjectKey";
import theme from "styles/theme.json";

class AccountManagementContainer extends React.Component {
  state = {
    isLoading: true,
    perPage: 10,
    labelEmpty: "ยังไม่มีรายการ",
    offset: 0,
  };

  componentDidMount() {
    const { perPage } = this.state;

    this.scrollToTop();
    this.fetchAdminRoles();
    this.fetchData(`?per_page=${perPage}&offset=0`);
    this.checkPermission();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0`);
      this.checkPermission();
    }
  }

  fetchAdminRoles = async () => {
    let res = await adminService.GET_ADMIN_ROLES();

    if (res && res.success) {
      let tempData = [
        {
          label: "All",
          value: "all",
        },
      ];
      res.data.map((e) =>
        tempData.push({
          label: e.name,
          value: e.id,
        })
      );

      this.setState({
        optRole: tempData,
      });
    }
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_ADMIN_LIST_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        isLoading: false,
        isBgLoading: false,
        data: res.data,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
        isBgLoading: false,
        data: [],
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props;

    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "admin_manage",
      "admin_manage_no_delete"
    );
    this.setState({
      perMissions: checkPermission,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickBack = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE}`);
  };

  handleClickCreate = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_DETAIL}/create`);
  };

  handleClickDetail = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_DETAIL}/${e.id}`);
  };

  handleClickLog = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_LOG}`);
  };

  handlePageClick = (e) => {
    const { pagination } = this.state;

    this.setState(
      {
        offset: e.selected * pagination.per_page,
      },
      () => {
        this.handleJoinQuery();
      }
    );
  };

  // // FILTER PROCESS
  // //==========================================
  // //==========================================
  // handleSelectedFilter = (key, e) => {
  //   switch (key) {
  //     case "categoryFilter":
  //       this.setState(
  //         {
  //           categoryFilter: `department_ids=${e
  //             .filter((e) => e !== "all")
  //             .join()}`,
  //         },
  //         () => this.handleJoinQuery()
  //       );
  //       break;
  //     case "keywordFilter":
  //       this.setState(
  //         {
  //           keywordFilter: !e ? false : `keyword=${e}`,
  //         },
  //         () => this.handleJoinQuery()
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // };

  handleJoinQuery = () => {
    const { keyword, roleFilter, statusFilter, offset, perPage } = this.state;

    let queryString = [];

    if (keyword && keyword !== "") {
      queryString.push(keyword);
    }
    if (roleFilter) {
      queryString.push(roleFilter);
    }
    if (statusFilter) {
      queryString.push(statusFilter);
    }

    this.setState({
      isBgLoading: true,
      labelEmpty: "ไม่พบข้อมูลจากการค้นหา",
    });

    if (queryString && queryString.length > 0) {
      this.fetchData(
        `?per_page=${perPage}&offset=${offset}&${queryString.join("&")}`
      );
      this.setState({
        queryFilterAll: queryString.join("&"),
      });
    } else {
      this.fetchData(`?per_page=${perPage}&offset=${offset}`);
      this.setState({
        queryFilterAll: null,
      });
    }
  };

  handleSearch = () => {
    const { temp_keyword, temp_role_filter, temp_status_filter } = this.state;

    this.setState(
      {
        keyword: temp_keyword,
        roleFilter: temp_role_filter,
        statusFilter: temp_status_filter,
        offset: 0,
      },
      () => this.handleJoinQuery()
    );
  };

  handleChangeKeyword = (e) => {
    this.setState({
      // keyword: (e && e !== '') ? `keyword=${e}` : null,
      temp_keyword: e && e !== "" ? `keyword=${e}` : null,
    });
  };

  handleSelectRole = (e) => {
    this.setState({
      // typeFilter: !e || e.value === 'all'
      //   ? false
      //   : `types=${e.value}`,
      temp_role_filter:
        !e || e.value === "all" ? false : `admin_role_id=${e.value}`,
    });
  };

  handleSelectStatus = (e) => {
    this.setState({
      // typeFilter: !e || e.value === 'all'
      //   ? false
      //   : `types=${e.value}`,
      temp_status_filter:
        !e || e.value === "all" ? undefined : `status=${e.value}`,
    });
  };

  render() {
    const {
      isLoading,
      data,
      pagination,
      perMissions,
      optRole,
      isBgLoading,
      labelEmpty,
    } = this.state;

    const { authenRedux } = this.props;

    return (
      <AccountManagementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <div className={`head_wrap_layout mb49`}>
                  <div className="h_33">
                    {/* <div className="back_click" onClick={this.handleClickBack}>
                      <Icons.RoundArrowLeft2 />
                    </div> */}
                    <Buttons.BtnLink
                      theme_standard_btn_link
                      svg={<Icons.ArrowLeft />}
                      label="ย้อนกลับ"
                      onClick={this.handleClickBack}
                    />
                  </div>
                  <div className="h_33 center">
                    <div className="text_c">จัดการบัญชีผู้ดูแลระบบ</div>
                    <div className="g_h_text">Administration</div>
                  </div>
                  <div className="h_33 flex">
                    {perMissions && (
                      <div className="bw140">
                        <Buttons.BtnRoundShadow
                          theme_btn_blue
                          label="สร้างบัญชี"
                          svg={
                            <Icons.AddRound
                              width="20"
                              height="20"
                              color="#002366"
                            />
                          }
                          onClick={this.handleClickCreate}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {authenRedux && authenRedux.role !== "company_admin" && (
                <div className="tab_show">
                  <Tabs.TabBarSelect
                    tab_normal
                    tabHeader={headerTab}
                    fontFamilyTop={theme.FONT.STYLE.REGULAR}
                    fontSizeTop={theme.FONT.SIZE.S20}
                  />
                </div>
              )}
              <div className="filter_content_zone">
                <div className="group_f_left">
                  <div className="w280">
                    <Inputs.InputSearch2
                      theme_standard_search_2
                      iconColor="#989898"
                      placeholder="ชื่อ - นามสกุล"
                      label="ค้นหา"
                      onChangeCustom={this.handleChangeKeyword}
                      onSubmit={this.handleSearch}
                    />
                  </div>
                  {authenRedux && authenRedux.role === "company_admin" && (
                    <div className="w200">
                      <Inputs.InputDropDownNormal
                        theme_40h
                        label="ตำแหน่ง"
                        iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                        iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                        placeholder="เลือกตำแหน่ง"
                        options={optRole && optRole}
                        onChange={this.handleSelectRole}
                      />
                    </div>
                  )}
                  <div className="w200">
                    <Inputs.InputDropDownNormal
                      theme_40h
                      label="สถานะ"
                      iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                      iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                      placeholder="เลือกสถานะ"
                      options={optStatus && optStatus}
                      onChange={this.handleSelectStatus}
                    />
                  </div>
                  <div className="w83">
                    <Buttons.BtnNormal
                      theme_standard_btn_log_in
                      label="ค้นหา"
                      padding="8px 8px"
                      backgroundColor={theme.COLORS.BLUE_21}
                      onClick={this.handleSearch}
                    />
                  </div>
                </div>
                <div className="log140">
                  <Buttons.BtnNormal
                    theme_blue_border_bg_white
                    padding="7.2px 8px"
                    label="Log"
                    onClick={this.handleClickLog}
                  />
                </div>
              </div>
              <div className="layer_two">
                {/* <div className="button_show">
                  <div className="search_layer">
                    <Inputs.InputSearch
                      theme_standard_search
                      onSubmit={(e) =>
                        this.handleSelectedFilter("keywordFilter", e)
                      }
                    />
                  </div>
                </div> */}
                <div className="table_show">
                  <TableCustom.TableRole
                    theme_standard_table_role
                    pagination={pagination}
                    labelEmpty={labelEmpty}
                    showPagination
                    data={data}
                    onClick={this.handleClickDetail}
                    handlePageClick={this.handlePageClick}
                    haveAdminRole={
                      authenRedux && authenRedux.role === "company_admin"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </Blocks.Container>
          </>
        )}
      </AccountManagementContainerStyled>
    );
  }
}

const optStatus = [
  {
    label: "ALL",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

const headerTab = [
  {
    label: "Group Admin",
    // component: <CurrentMissionContainer />,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountManagementContainer);

import styled from "styled-components";

export const RewardDetailsContainerStyled = styled.div`
  padding: 15px 32px 0px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .btn_del {
    width: 120px;
  }
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layer_one {
    display: flex;
  }
  .layer_two {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    .left_two {
      width: 69%;
      .two_block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
        .box {
          width: 48.5%;
          white-space: pre-wrap;
          .img_show {
            width: 100%;
            border-radius: 6px;
            border: 2px solid ${({ theme }) => theme.COLORS.PINK_3};
            object-fit: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 257px;
          }
          .group_box {
            display: flex;
            height: 100%;
            .box_left {
              width: 60%;
              display: flex;
              align-items: center;
              .group_label {
                .label {
                  font-family: noto_sans_thai_medium, noto_sans_regular;
                  &.f24 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S24};
                    margin-bottom: 22px;
                  }
                  &.f20 {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
                    margin-top: 15px;
                  }
                }
              }
            }
            .box_right {
              width: 40%;
            }
          }
          .group_show_label {
            display: flex;
            .left_show_label {
              width: 40%;
              margin-right: 10px;
            }
            .date_show {
              font-size: ${({ theme }) => theme.FONT.SIZE.S18};
              font-family: noto_sans_thai_medium, noto_sans_regular;
            }
            .icon_num {
              font-size: ${({ theme }) => theme.FONT.SIZE.S24};
              font-family: noto_sans_thai_bold, noto_sans_bold;
              color: ${({ theme }) => theme.COLORS.GOLD_1};
              display: flex;
              align-items: center;
              .show_svg {
                display: flex;
                margin-right: 14px;
              }
            }
            .big_num {
              font-size: ${({ theme }) => theme.FONT.SIZE.S24};
              font-family: noto_sans_thai_bold, noto_sans_bold;
              cursor: pointer;
              .num_hightlight {
                color: ${({ theme }) => theme.COLORS.PURPLE_1};
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .right_two {
      width: 29%;
      .box_right_2 {
        margin-bottom: 16px;
        cursor: pointer;
        &.mb40 {
          margin-bottom: 40px;
        }
      }
      .icon_bottom {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

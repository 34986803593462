import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BoxImageStyled } from './styled';

const BoxImage = ({ theme_standard, src }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxImageStyled>
      <div className={customClass}>
        <div className="show_img" style={{ backgroundImage: `url(${src})` }}>
          <div className="close">{/* <Icons.CloseX /> */}</div>
        </div>
      </div>
    </BoxImageStyled>
  );
};

BoxImage.propTypes = {};

export default BoxImage;

export default function renderIcon({
  color = "#ACACAC",
  width = "22",
  height = "20",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4864 2.5H5.14524C4.2159 2.5 3.46405 3.25333 3.47075 4.17917L3.55782 15.8458C3.56452 16.7617 4.31218 17.5 5.23231 17.5H16.8541C17.7793 17.5 18.5286 16.7542 18.5286 15.8333V6.52333C18.5286 6.08167 18.352 5.6575 18.038 5.345L15.6703 2.98833C15.3563 2.67583 14.931 2.5 14.4864 2.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.3435 2.5V5.7575C14.3435 6.2175 13.9684 6.59083 13.5062 6.59083H8.48275C8.02059 6.59083 7.64551 6.2175 7.64551 5.7575V2.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.81348 17.5V11.0717C6.81348 10.48 7.29573 10 7.89017 10H14.1101C14.7037 10 15.1859 10.48 15.1859 11.0717V17.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export const ROUTE_PATH = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset_password",
  PASSWORD_EXPIRED: "/password_expired",
  COMPONENTS: "/components",

  ADMINISTARTOR: "/administrator",
  MAIN: "/administrator/main",
  WELL_BEING: "/administrator/wellbeing",
  BMI: "/administrator/bmi",
  MSD: "/administrator/msd",
  STEP: "/administrator/step",
  COMPANY: "/administrator/company",
  HEALTH_CHECK: "/administrator/health_check",
  BACK_OFFICE: "/administrator/backoffice",

  BACK_OFFICE_REWARD: "/administrator/backoffice/rewardmanagement",
  BACK_OFFICE_REWARD_LOG: "/administrator/backoffice/rewardmanagement/log",
  BACK_OFFICE_REWARD_SEARCH:
    "/administrator/backoffice/rewardmanagement/search",
  BACK_OFFICE_REWARD_HISTORY:
    "/administrator/backoffice/rewardmanagement/history",
  BACK_OFFICE_REWARD_EXCHANGE:
    "/administrator/backoffice/rewardmanagement/exchange",
  BACK_OFFICE_REWARD_EXCHANGE_LIST:
    "/administrator/backoffice/rewardmanagement/exchange/:id",
  BACK_OFFICE_REWARD_EXCHANGE_HISTORY:
    "/administrator/backoffice/rewardmanagement/exchange_history",
  BACK_OFFICE_REWARD_EXCHANGE_HISTORY_LIST:
    "/administrator/backoffice/rewardmanagement/exchange_history/:id",
  BACK_OFFICE_REWARD_DETAILS:
    "/administrator/backoffice/rewardmanagement/preview",
  BACK_OFFICE_REWARD_PREVIEW_DETAIL_BY_ID:
    "/administrator/backoffice/rewardmanagement/preview/:id",
  BACK_OFFICE_REWARD_NEW_DETAIL:
    "/administrator/backoffice/rewardmanagement/detail",
  BACK_OFFICE_REWARD_CREATE_EDIT:
    "/administrator/backoffice/rewardmanagement/detail/:id",

  BACK_OFFICE_NEW: "/administrator/backoffice/newmanagement",
  BACK_OFFICE_NEW_LOG: "/administrator/backoffice/newmanagement/log",
  BACK_OFFICE_NEW_DETAIL: "/administrator/backoffice/newmanagement/detail",
  BACK_OFFICE_PREVIEW_DETAIL: "/administrator/backoffice/newmanagement/preview",
  BACK_OFFICE_PREVIEW_DETAIL_BY_ID:
    "/administrator/backoffice/newmanagement/preview/:id",
  BACK_OFFICE_CREATE_EDIT: "/administrator/backoffice/newmanagement/detail/:id",

  // Luck Spin
  BACK_OFFICE_LUCK_SPIN: "/administrator/backoffice/luck_spin",
  BACK_OFFICE_LUCK_SPIN_GAME: "/administrator/backoffice/luck_spin/spin_game",
  BACK_OFFICE_LUCK_SPIN_GAME_CREATE_EDIT:
    "/administrator/backoffice/luck_spin/spin_game/:id/:action",
  BACK_OFFICE_LUCK_SPIN_REWARD_MANAGEMENT:
    "/administrator/backoffice/luck_spin/reward_management",
  BACK_OFFICE_LUCK_SPIN_REWARD_MANAGEMENT_CREATE_EDIT:
    "/administrator/backoffice/luck_spin/reward_management/:id/:action",

  // user
  BACK_OFFICE_USER_ACCOUNT: "/administrator/backoffice/user_accountmanagement",
  BACK_OFFICE_USER_ACCOUNT_DETAIL:
    "/administrator/backoffice/user_accountmanagement/detail",
  BACK_OFFICE_USER_ACCOUNT_CREATE_EDIT:
    "/administrator/backoffice/user_accountmanagement/detail/:id",
  BACK_OFFICE_DELETE_USER_ACCOUNT: "/administrator/backoffice/deleteaccount",
  BACK_OFFICE_MANY_USER_ACCOUNT: "/administrator/backoffice/manyaccount",
  BACK_OFFICE_USER_MANAGEMENT_LOG:
    "/administrator/backoffice/user_management/log",

  // reward point
  BACK_OFFICE_REWARD_POINT: "/administrator/backoffice/reward_point",

  //  point
  BACK_OFFICE_POINT: "/administrator/backoffice/point",
  BACK_OFFICE_POINT_LOG: "/administrator/backoffice/point/log",

  // department
  BACK_OFFICE_DEPARTMENT: "/administrator/backoffice/department_management",
  BACK_OFFICE_DEPARTMENT_DETAIL:
    "/administrator/backoffice/department_management/detail",
  BACK_OFFICE_DEPARTMENT_CREATE_EDIT:
    "/administrator/backoffice/department_management/detail/:id",
  BACK_OFFICE_DELETE_DEPARTMENT: "/administrator/backoffice/deletedepartment",

  //  mission
  BACK_OFFICE_MISSION: "/administrator/backoffice/mission",
  BACK_OFFICE_MISSION_CREATE_EDIT: "/administrator/backoffice/mission/:id",

  // admin
  BACK_OFFICE_ACCOUNT: "/administrator/backoffice/accountmanagement",
  BACK_OFFICE_ACCOUNT_LOG: "/administrator/backoffice/accountmanagement/log",
  BACK_OFFICE_ACCOUNT_DETAIL:
    "/administrator/backoffice/accountmanagement/detail",
  BACK_OFFICE_ACCOUNT_CREATE_EDIT:
    "/administrator/backoffice/accountmanagement/detail/:id",

  // profile
  BACK_OFFICE_ACCOUNT_PROFILE:
    "/administrator/backoffice/accountmanagement/profile",
  BACK_OFFICE_ACCOUNT_PROFILE_BY_ID:
    "/administrator/backoffice/accountmanagement/profile/:id",

  // healthy club
  BACK_OFFICE_HEALTHY_CLUB: "/administrator/backoffice/healthyclub",
  BACK_OFFICE_HEALTHY_CLUB_DETAIL:
    "/administrator/backoffice/healthyclub/detail",
  BACK_OFFICE_HEALTHY_CLUB_PREVIEW_DETAIL:
    "/administrator/backoffice/healthyclub/preview",
  BACK_OFFICE_HEALTHY_CLUB_PREVIEW_DETAIL_BY_ID:
    "/administrator/backoffice/healthyclub/preview/:id",
  BACK_OFFICE_HEALTHY_CLUB_CREATE_EDIT:
    "/administrator/backoffice/healthyclub/detail/:id",
  BACK_OFFICE_HEALTHY_CLUB_RANK: "/administrator/backoffice/healthyclub/rank",
  BACK_OFFICE_HEALTHY_CLUB_GROUP_ROLE_DETAIL:
    "/administrator/backoffice/healthyclub/groupRole",
  BACK_OFFICE_HEALTHY_CLUB_GROUP_ROLE_CREATE_EDIT:
    "/administrator/backoffice/healthyclub/groupRole/:id/:group",
};

export const Months = [
  { value: "01", th: "มกราคม", en: "January" },
  { value: "02", th: "กุมภาพันธ์", en: "February" },
  { value: "03", th: "มีนาคม", en: "March" },
  { value: "04", th: "เมษายน", en: "April" },
  { value: "05", th: "พฤษภาคม", en: "May" },
  { value: "06", th: "มิถุนายน", en: "June" },
  { value: "07", th: "กรกฎาคม", en: "July" },
  { value: "08", th: "สิงหาคม", en: "August" },
  { value: "09", th: "กันยายน", en: "September" },
  { value: "10", th: "ตุลาคม", en: "October" },
  { value: "11", th: "พฤศจิกายน", en: "November" },
  { value: "12", th: "ธันวาคม", en: "December" },
];

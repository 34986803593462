import React from 'react';
import { YourAccountFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Label, Icons, Buttons } from 'components';

const YourAccountForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required('New password is required'),
  });

  const { control, handleSubmit, formState: { errors }, } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <YourAccountFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='top_form'>
          <div className='l_top_form'>
            <div className='group_input'>
              <Label.TopBottom
                theme_left_align
                label='ชื่อ'
                labelBottom='Name'
                fontSize='24x'
                fontSizeLabelButtom='18px'
                fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
                fontFamilyBottom='noto_sans_thai_regular, noto_sans_regular'
              />
              <div className='input_sub'>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_create_account
                      iconSvg={<Icons.User />}
                      placeholder="ชื่อ"
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
            <div className='group_input no_margin'>
              <Label.TopBottom
                theme_left_align
                label='Username'
                fontSize='24x'
                fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
              />
              <div className='input_sub'>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_create_account
                      iconSvg={<Icons.User />}
                      placeholder="Username"
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className='r_top_form'>
            <div className='group_input_right'>
              <Label.TopBottom
                theme_left_align
                label='นามสกุล'
                labelBottom='Surname'
                fontSize='24x'
                fontSizeLabelButtom='18px'
                fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
                fontFamilyBottom='noto_sans_thai_regular, noto_sans_regular'
              />
              <div className='input_sub'>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_create_account
                      iconSvg={<Icons.User />}
                      placeholder="นามสกุล"
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
            <div className='group_input_right'>
              <Label.TopBottom
                theme_left_align
                label='Password'
                fontSize='24x'
                fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
              />
              <div className='input_sub'>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_create_account
                      iconSvg={<Icons.User />}
                      placeholder="Password"
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
            <div className='group_input_right'>
              <Label.TopBottom
                theme_left_align
                label='Re-Password'
                fontSize='24x'
                fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
              />
              <div className='input_sub'>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_create_account
                      iconSvg={<Icons.User />}
                      placeholder="Re-Password"
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bottom_form'>
          <div className='btn'>
            <Buttons.BtnRoundShadow
              theme_btn_round_shadow_green
              label="บันทึกการแก้ไข"
              svg={<Icons.Download />}
              fontSize="20px"
            />
          </div>
        </div>
      </form>
    </YourAccountFormStyled>
  );
};

YourAccountForm.propTypes = {};

export default YourAccountForm;

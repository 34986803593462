import React, { useState, useEffect, forwardRef } from "react";
import { InputDatePikerCustomStyled } from "./styled";
import cx from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import moment from "moment";
import { Icons } from "components";

const InputDatePikerCustom = ({
  theme_standard_date_picker_custom,
  theme_quarter,
  theme_year,
  theme_log,
  theme_luky,
  onChangeCustom,
  minDate,
  dateFormat,
  showYearPicker,
  showMonthYearPicker,
  showFullMonthYearPicker,
  showQuarterYearPicker,
  showYearDropdown,
  iconColor = "#575D96",
  maxDate,
  initialValues,
  formatAD,
}) => {
  const customClass = cx({
    theme_standard_date_picker_custom: theme_standard_date_picker_custom,
    theme_quarter: theme_quarter,
    theme_log: theme_log,
    theme_year: theme_year,
    theme_luky: theme_luky,
  });

  const [_value, _setValue] = useState();
  const [_onClick] = useState(false);

  useEffect(() => {
    _setValue(initialValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePicker = (e) => {
    _setValue(e);
    onChangeCustom && onChangeCustom(e);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="custom_date" onClick={onClick} ref={ref}>
      {showQuarterYearPicker ? (
        <div>
          {`ไตรมาส ${moment(_value).format("Q")}/${moment(_value)
            .add(543, "Y")
            .format("YYYY")}`}
        </div>
      ) : showYearPicker ? (
        formatAD ? (
          <>
            {moment(_value).format("YYYY")}
            <span className="arrow_icon">
              {!theme_log ? (
                _onClick ? (
                  <Icons.RectArrowUp color={iconColor} />
                ) : (
                  <Icons.RectArrowDown color={iconColor} />
                )
              ) : _onClick ? (
                <Icons.ArrowDropdown
                  color={iconColor}
                  translate="21.81"
                  rotate="90"
                  width="10"
                  height="16"
                />
              ) : (
                <Icons.ArrowDropdown color={iconColor} width="10" height="16" />
              )}
            </span>
          </>
        ) : (
          <>
            {moment(_value).add(543, "years").format("YYYY")}
            <span className="arrow_icon">
              {!theme_log ? (
                _onClick ? (
                  <Icons.RectArrowUp color={iconColor} />
                ) : (
                  <Icons.RectArrowDown color={iconColor} />
                )
              ) : _onClick ? (
                <Icons.ArrowDropdown
                  color={iconColor}
                  translate="21.81"
                  rotate="90"
                  width="10"
                  height="16"
                />
              ) : (
                <Icons.ArrowDropdown color={iconColor} width="10" height="16" />
              )}
            </span>
          </>
        )
      ) : (
        <>
          {moment(_value).add(543, "years").format("MMMM YYYY")}
          <span className="arrow_icon">
            {!theme_log ? (
              _onClick ? (
                <Icons.RectArrowUp color={iconColor} />
              ) : (
                <Icons.RectArrowDown color={iconColor} />
              )
            ) : _onClick ? (
              <Icons.ArrowDropdown
                color={iconColor}
                translate="21.81"
                rotate="90"
                width="10"
                height="16"
              />
            ) : (
              <Icons.ArrowDropdown color={iconColor} width="10" height="16" />
            )}
          </span>
        </>
      )}
    </button>
  ));

  return (
    <InputDatePikerCustomStyled>
      <div className={customClass}>
        <DatePicker
          className="date_picker"
          selected={_value}
          onChange={(e) => {
            handleChangePicker(e);
          }}
          locale={th}
          dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
          showMonthYearPicker={showMonthYearPicker}
          showFullMonthYearPicker={showFullMonthYearPicker}
          showYearPicker={showYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown={showYearDropdown}
          customInput={<ExampleCustomInput />}
        />
      </div>
    </InputDatePikerCustomStyled>
  );
};

InputDatePikerCustom.propTypes = {};

export default InputDatePikerCustom;

import styled from 'styled-components';

export const HealthCheckDepartmentStyled = styled.div`
.box_dep_health_check {
  padding: 24px 72px;
  padding-top: 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
  display: flex;
  column-gap: 50px;
  flex-wrap: wrap;
  .body_graph_dep_health {
    flex: 1;
    position: relative;
    min-width: 400px;
    max-width: 800px;
    .top_dep_title_g {
      color: ${({ theme }) => theme.COLORS.BLACK_11};
      font-size:  ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70px;
      position: absolute;
      top: -12px;
      left: 72px;
    }
    .right_dep_title_g {
      color: ${({ theme }) => theme.COLORS.BLACK_11};
      font-size:  ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-weight: 300;
      position: absolute;
      right: -90px;
      bottom: 41px;
    }
  }
  .right_dep_men_layout {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    width: 224px;
    padding-top: 26px;
    flex-shrink: 0;
  }
}

.cate_label {
  background: pink;l
}
`;

import styled from "styled-components";

export const CreateAccountFormStyled = styled.div`
  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.mb61 {
        margin-bottom: 61px;
      }
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
        .bw132 {
          width: 132px;
        }
        .bw140 {
          width: 140px;
        }
      }
    }
  }
  .status_zone {
    margin-bottom: 22px;
    display: flex;
    justify-content: flex-end;
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    align-items: center;
    column-gap: 16px;
    .box_w_status {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .w190 {
        width: 190px;
      }
    }
    .w161 {
      width: 161px;
    }
  }
  .title_b {
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    margin-bottom: 24px;
  }
  .top_form {
    display: flex;
    column-gap: 24px;
    &.border {
      padding-bottom: 29px;
      margin-bottom: 46px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    }
    .l_top_form {
      width: 50%;
      row-gap: 16px;
      display: flex;
      flex-direction: column;
      .group_input {
        &.flex_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .purple_text {
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          text-decoration: underline;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          cursor: pointer;
        }
        .g_b_label {
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          margin-bottom: 11px;
          .sub_gray {
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          }
        }
        .input_sub {
          &.mt17 {
            margin: 17px 0px;
          }
          .realm {
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            margin-top: 19px;
            margin-bottom: 17px;
          }
        }
      }
      .error_show {
        margin-top: 8px;
        color: ${({ theme }) => theme.COLORS.RED_3};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }
  .bottom_form {
    margin-top: 114px;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .err_submit_msg {
      color: red;
      margin-bottom: 10px;
    }
    .btn_row_block {
      display: flex;
      .btn {
        flex-shrink: 0;
        margin-right: 0px;
        &.w18 {
          width: 18%;
        }
        &.margin_right {
          margin-right: 24px;
        }
      }
    }
  }

  .change_password_success_box {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 54px 54px;
    width: 432px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S32};
    .svg_check {
      display: flex;
      margin-bottom: 37px;
    }
    .btn_k {
      margin-top: 32px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_24};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      width: 100%;
      text-align: center;
      padding: 11.2px;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .bottom_form {
      .btn {
        // width: 20%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .top_form {
      justify-content: space-between;
      .l_top_form {
        width: 47%;
      }
      .r_top_form {
        width: 47%;
      }
    }
    .bottom_form {
      width: 100%;
      .btn {
        // width: 23%;
      }
    }
  }
`;

import styled from "styled-components";

export const ForgotPasswordFormStyled = styled.form`
  .forgot_form_container {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 72px 54px 42px 54px;
    width: 432px;
    height: 529px;
    .logo_icon {
      margin-bottom: 43px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box_input {
      .group_input {
      }
    }
    .error_show {
      margin-top: 8px;
      color: ${({ theme }) => theme.COLORS.RED_3};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    }
    .btn_layout {
      margin-top: 127px;
    }
    .text_com {
      margin-top: 85px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      text-align: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .form_container {
      padding: 40px 30px 20px 30px;
    }
  }
`;

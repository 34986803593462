export default function Food3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="_22-ramyeon" data-name="22-ramyeon" transform="translate(-0.996 -1)">
        <circle id="Ellipse_1890" data-name="Ellipse 1890" cx="24" cy="24" r="24" transform="translate(0.996 1)" fill="#e4e4e4" />
        <circle id="Ellipse_1891" data-name="Ellipse 1891" cx="22" cy="22" r="22" transform="translate(2.996 3)" fill="#8a673f" />
        <circle id="Ellipse_1892" data-name="Ellipse 1892" cx="20" cy="20" r="20" transform="translate(4.996 5)" fill="#ffffec" />
        <circle id="Ellipse_1893" data-name="Ellipse 1893" cx="20" cy="20" r="20" transform="translate(4.996 5)" fill="#fffacd" />
        <path id="Path_20726" data-name="Path 20726" d="M54.636,13.821a1.914,1.914,0,0,0-.6.641,21.626,21.626,0,0,1,2.441,3.892A1.878,1.878,0,0,0,58.24,16.7a23.952,23.952,0,0,0-1.931-3.091,1.928,1.928,0,0,0-1.674.214Z" transform="translate(-11.98 -2.824)" fill="#fff6bb" />
        <path id="Path_20727" data-name="Path 20727" d="M38.436,58.584a1.914,1.914,0,0,0-.455-.742A21.718,21.718,0,0,1,22,58.128a1.9,1.9,0,0,0-.133,2.416A24,24,0,0,0,38.2,60.252a1.925,1.925,0,0,0,.232-1.668Z" transform="translate(-4.631 -12.838)" fill="#c38c39" />
        <path id="Path_20728" data-name="Path 20728" d="M52.878,50.062a1.92,1.92,0,0,0-.753-.437A21.781,21.781,0,0,1,46.546,54.5,1.9,1.9,0,0,0,48.368,56.1a24.136,24.136,0,0,0,5.1-4.455,1.927,1.927,0,0,0-.586-1.579Z" transform="translate(-10.288 -10.982)" fill="#c38c39" />
        <path id="Path_20729" data-name="Path 20729" d="M60.25,20.824a1.9,1.9,0,0,0-2.409.22,21.712,21.712,0,0,1,.774,14.521,1.91,1.91,0,0,0,2.423-.032,24.039,24.039,0,0,0-.791-14.709Z" transform="translate(-12.839 -4.403)" fill="#ffda6c" />
        <path id="Path_20730" data-name="Path 20730" d="M5.459,18.1a1.914,1.914,0,0,0,.835.248,21.72,21.72,0,0,1,2.44-3.892A1.9,1.9,0,0,0,6.464,13.6a23.988,23.988,0,0,0-1.935,3.1,1.926,1.926,0,0,0,.93,1.4Z" transform="translate(-0.798 -2.823)" fill="#fff6bb" />
        <path id="Path_20731" data-name="Path 20731" d="M15.1,55.307a1.918,1.918,0,0,0,.333-.8A21.788,21.788,0,0,1,9.86,49.628a1.9,1.9,0,0,0-1.338,2.013,24.1,24.1,0,0,0,5.09,4.457A1.923,1.923,0,0,0,15.1,55.307Z" transform="translate(-1.697 -10.983)" fill="#c38c39" />
        <path id="Path_20732" data-name="Path 20732" d="M3.426,35.939a1.912,1.912,0,0,0,.79-.375A21.709,21.709,0,0,1,4.99,21.045a1.9,1.9,0,0,0-2.409-.221,24.032,24.032,0,0,0-.79,14.709,1.914,1.914,0,0,0,1.633.406Z" transform="translate(0 -4.403)" fill="#ffda6c" />
        <path id="Path_20733" data-name="Path 20733" d="M58.579,39.943a1.925,1.925,0,0,1-.4-.043,1.9,1.9,0,0,1-.789-.375,21.618,21.618,0,0,1-3.943,7.481,1.9,1.9,0,0,1,1.337,2.013A24.206,24.206,0,0,1,52.4,51.428a23.967,23.967,0,0,0,7.75-13.393q-.145.736-.334,1.456A1.935,1.935,0,0,1,58.579,39.943Z" transform="translate(-11.61 -8.365)" fill="#f0ac47" />
        <path id="Path_20734" data-name="Path 20734" d="M58.026,18.579a1.911,1.911,0,0,1-.834.248q.266.547.5,1.112a1.9,1.9,0,0,1,2.409-.22A23.912,23.912,0,0,0,58.05,15.6q.478.768.9,1.572a1.924,1.924,0,0,1-.924,1.407Z" transform="translate(-12.692 -3.298)" fill="#f0ac47" />
        <path id="Path_20735" data-name="Path 20735" d="M8.408,11a1.914,1.914,0,0,1,.6.641,21.662,21.662,0,0,1,34.124,0,1.9,1.9,0,0,1,2.271-.855A23.993,23.993,0,0,0,5.729,12.263q.476-.76,1.006-1.482A1.922,1.922,0,0,1,8.408,11Z" transform="translate(-1.069 0)" fill="#ffda6c" />
        <path id="Path_20736" data-name="Path 20736" d="M20.133,58.455a1.9,1.9,0,0,1,.428-.758,21.6,21.6,0,0,1-3.638-1.769A1.9,1.9,0,0,1,15.1,57.52a23.916,23.916,0,0,0,5.321,2.593A1.923,1.923,0,0,1,20.133,58.455Z" transform="translate(-3.186 -12.406)" fill="#db9327" />
        <path id="Path_20737" data-name="Path 20737" d="M45.818,56.73a1.919,1.919,0,0,1-.333-.8,21.649,21.649,0,0,1-2.911,1.482,1.9,1.9,0,0,1,.219,2.41c-.31.119-.626.232-.944.337a23.855,23.855,0,0,0,5.458-2.638A1.922,1.922,0,0,1,45.818,56.73Z" transform="translate(-9.227 -12.406)" fill="#db9327" />
        <path id="Path_20738" data-name="Path 20738" d="M4.714,19.484a1.91,1.91,0,0,1,.742.456q.232-.564.5-1.112A1.878,1.878,0,0,1,4.2,17.172q.423-.805.9-1.572A23.849,23.849,0,0,0,3.05,19.719a1.926,1.926,0,0,1,1.664-.235Z" transform="translate(-0.464 -3.298)" fill="#f0ac47" />
        <path id="Path_20739" data-name="Path 20739" d="M8.293,47.008a21.618,21.618,0,0,1-3.943-7.481,1.911,1.911,0,0,1-2.422-.03q-.19-.72-.335-1.457A23.909,23.909,0,0,0,6.956,49.024a1.9,1.9,0,0,1,1.338-2.013Z" transform="translate(-0.135 -8.366)" fill="#f0ac47" />
        <path id="Path_20740" data-name="Path 20740" d="M46.8,31.322c0,.263-.008.519-.023.774-1.719.015-1.726,1.548-3.46,1.548S41.578,32.1,39.837,32.1s-1.742,1.548-3.484,1.548S34.611,32.1,32.869,32.1s-1.742,1.548-3.484,1.548S27.644,32.1,25.9,32.1s-1.742,1.548-3.484,1.548S20.676,32.1,18.935,32.1s-1.742,1.548-3.484,1.548S13.709,32.1,11.967,32.1s-1.742,1.548-3.484,1.548S6.742,32.112,5.023,32.1C5.008,31.841,5,31.586,5,31.322a20.629,20.629,0,0,1,.132-2.307c1.6.085,1.657,1.533,3.352,1.533,1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.193,29,18.935,29s1.742,1.548,3.484,1.548S24.16,29,25.9,29s1.742,1.548,3.484,1.548S31.127,29,32.869,29s1.742,1.548,3.484,1.548S38.095,29,39.837,29s1.742,1.548,3.484,1.548c1.7,0,1.75-1.448,3.352-1.533A20.629,20.629,0,0,1,46.8,31.322Z" transform="translate(-0.904 -6.324)" fill="#f8c351" />
        <path id="Path_20741" data-name="Path 20741" d="M46.787,33a20.992,20.992,0,0,1-.348,3.143c-1.362.209-1.51,1.5-3.112,1.5-1.742,0-1.742-1.548-3.484-1.548S38.1,37.645,36.36,37.645,34.618,36.1,32.876,36.1s-1.742,1.548-3.484,1.548S27.651,36.1,25.909,36.1s-1.742,1.548-3.484,1.548S20.683,36.1,18.941,36.1s-1.742,1.548-3.484,1.548S13.716,36.1,11.974,36.1s-1.742,1.548-3.484,1.548c-1.6,0-1.75-1.293-3.112-1.5A20.992,20.992,0,0,1,5.03,33c1.719.015,1.726,1.548,3.46,1.548S10.232,33,11.974,33s1.742,1.548,3.484,1.548S17.2,33,18.941,33s1.742,1.548,3.484,1.548S24.167,33,25.909,33s1.742,1.548,3.484,1.548S31.134,33,32.876,33s1.742,1.548,3.484,1.548S38.1,33,39.843,33s1.742,1.548,3.484,1.548S45.069,33.015,46.787,33Z" transform="translate(-0.911 -7.227)" fill="#ffdf7c" />
        <path id="Path_20742" data-name="Path 20742" d="M46.161,25.108a20.153,20.153,0,0,1,.55,3c-1.6.085-1.657,1.533-3.352,1.533-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S34.649,28.1,32.908,28.1s-1.742,1.548-3.484,1.548S27.682,28.1,25.94,28.1s-1.742,1.548-3.484,1.548S20.715,28.1,18.973,28.1s-1.742,1.548-3.484,1.548S13.748,28.1,12.006,28.1s-1.742,1.548-3.484,1.548c-1.7,0-1.75-1.448-3.352-1.533a20.153,20.153,0,0,1,.55-3c1.092.341,1.332,1.44,2.8,1.44,1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.231,25,18.973,25s1.742,1.548,3.484,1.548S24.2,25,25.94,25s1.742,1.548,3.484,1.548S31.166,25,32.908,25s1.742,1.548,3.484,1.548S38.133,25,39.875,25s1.742,1.548,3.484,1.548C44.829,26.548,45.069,25.449,46.161,25.108Z" transform="translate(-0.943 -5.421)" fill="#ffdf7c" />
        <path id="Path_20743" data-name="Path 20743" d="M46.541,37.046a21.178,21.178,0,0,1-.991,3.507c-.6.457-1.006,1.092-2.121,1.092-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S34.719,40.1,32.978,40.1s-1.742,1.548-3.484,1.548S27.752,40.1,26.01,40.1s-1.742,1.548-3.484,1.548S20.785,40.1,19.043,40.1s-1.742,1.548-3.484,1.548S13.818,40.1,12.076,40.1s-1.742,1.548-3.484,1.548c-1.115,0-1.517-.635-2.121-1.092a21.178,21.178,0,0,1-.991-3.507c1.362.209,1.51,1.5,3.112,1.5,1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.3,37,19.043,37s1.742,1.548,3.484,1.548S24.268,37,26.01,37s1.742,1.548,3.484,1.548S31.236,37,32.978,37s1.742,1.548,3.484,1.548S38.2,37,39.945,37s1.742,1.548,3.484,1.548C45.031,38.548,45.178,37.255,46.541,37.046Z" transform="translate(-1.013 -8.131)" fill="#ffbf54" />
        <path id="Path_20744" data-name="Path 20744" d="M45.454,21.612a20.8,20.8,0,0,1,.867,2.593c-1.092.341-1.332,1.44-2.8,1.44-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S34.81,24.1,33.068,24.1s-1.742,1.548-3.484,1.548S27.842,24.1,26.1,24.1s-1.742,1.548-3.484,1.548S20.875,24.1,19.133,24.1s-1.742,1.548-3.484,1.548S13.908,24.1,12.166,24.1s-1.742,1.548-3.484,1.548c-1.471,0-1.711-1.1-2.8-1.44a20.8,20.8,0,0,1,.867-2.593,2.652,2.652,0,0,0,1.935.937c1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.392,21,19.133,21s1.742,1.548,3.484,1.548S24.359,21,26.1,21s1.742,1.548,3.484,1.548S31.326,21,33.068,21s1.742,1.548,3.484,1.548S38.293,21,40.035,21s1.742,1.548,3.484,1.548A2.652,2.652,0,0,0,45.454,21.612Z" transform="translate(-1.103 -4.517)" fill="#ffc347" />
        <path id="Path_20745" data-name="Path 20745" d="M43.718,42.548c1.115,0,1.517-.635,2.121-1.092a20.346,20.346,0,0,1-2.16,4.18c-1.7-.023-1.719-1.541-3.445-1.541-1.742,0-1.742,1.548-3.484,1.548S35.009,44.1,33.267,44.1s-1.742,1.548-3.484,1.548S28.041,44.1,26.3,44.1s-1.742,1.548-3.484,1.548S21.074,44.1,19.332,44.1s-1.742,1.548-3.484,1.548S14.107,44.1,12.365,44.1c-1.726,0-1.75,1.517-3.445,1.541a20.346,20.346,0,0,1-2.16-4.18c.6.457,1.006,1.092,2.121,1.092,1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.59,41,19.332,41s1.742,1.548,3.484,1.548S24.558,41,26.3,41s1.742,1.548,3.484,1.548S31.525,41,33.267,41s1.742,1.548,3.484,1.548S38.492,41,40.234,41,41.976,42.548,43.718,42.548Z" transform="translate(-1.302 -9.034)" fill="#ffdf7c" />
        <path id="Path_20746" data-name="Path 20746" d="M44.585,18.393a22.019,22.019,0,0,1,1.123,2.315,2.652,2.652,0,0,1-1.935.937c-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S35.063,20.1,33.321,20.1s-1.742,1.548-3.484,1.548S28.1,20.1,26.354,20.1s-1.742,1.548-3.484,1.548S21.128,20.1,19.386,20.1s-1.742,1.548-3.484,1.548S14.161,20.1,12.419,20.1s-1.742,1.548-3.484,1.548A2.652,2.652,0,0,1,7,20.708a22.019,22.019,0,0,1,1.123-2.315,2.234,2.234,0,0,0,.813.155c1.742,0,1.742-1.548,3.484-1.548s1.742,1.548,3.484,1.548S17.644,17,19.386,17s1.742,1.548,3.484,1.548S24.612,17,26.354,17s1.742,1.548,3.484,1.548S31.579,17,33.321,17s1.742,1.548,3.484,1.548S38.546,17,40.288,17s1.742,1.548,3.484,1.548A2.234,2.234,0,0,0,44.585,18.393Z" transform="translate(-1.356 -3.614)" fill="#ffdf7c" />
        <path id="Path_20747" data-name="Path 20747" d="M44.912,17.49a2.234,2.234,0,0,1-.813.155c-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S35.39,16.1,33.648,16.1s-1.742,1.548-3.484,1.548S28.423,16.1,26.681,16.1s-1.742,1.548-3.484,1.548S21.456,16.1,19.714,16.1s-1.742,1.548-3.484,1.548S14.488,16.1,12.747,16.1,11,17.645,9.263,17.645a2.234,2.234,0,0,1-.813-.155,20.934,20.934,0,0,1,2.965-4.049A2.073,2.073,0,0,1,12.747,13c1.742,0,1.742,1.548,3.484,1.548S17.972,13,19.714,13s1.742,1.548,3.484,1.548S24.939,13,26.681,13s1.742,1.548,3.484,1.548S31.907,13,33.648,13s1.742,1.548,3.484,1.548S38.874,13,40.616,13a2.073,2.073,0,0,1,1.332.441,20.935,20.935,0,0,1,2.965,4.049Z" transform="translate(-1.683 -2.71)" fill="#ffc347" />
        <path id="Path_20748" data-name="Path 20748" d="M44.309,46.541a20.173,20.173,0,0,1-1.765,2.284,2.265,2.265,0,0,0-1.68-.728c-1.742,0-1.742,1.548-3.484,1.548S35.639,48.1,33.9,48.1s-1.742,1.548-3.484,1.548S28.671,48.1,26.93,48.1s-1.742,1.548-3.484,1.548S21.7,48.1,19.962,48.1s-1.742,1.548-3.484,1.548S14.737,48.1,12.995,48.1a2.265,2.265,0,0,0-1.68.728A20.174,20.174,0,0,1,9.55,46.541c1.7-.023,1.719-1.541,3.445-1.541,1.742,0,1.742,1.548,3.484,1.548S18.22,45,19.962,45s1.742,1.548,3.484,1.548S25.188,45,26.93,45s1.742,1.548,3.484,1.548S32.155,45,33.9,45s1.742,1.548,3.484,1.548S39.122,45,40.864,45C42.59,45,42.614,46.517,44.309,46.541Z" transform="translate(-1.932 -9.938)" fill="#ffbf54" />
        <path id="Path_20749" data-name="Path 20749" d="M41.379,49a2.265,2.265,0,0,1,1.68.728A21.086,21.086,0,0,1,38.53,53.56a2.519,2.519,0,0,1-.635.085c-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S29.186,52.1,27.444,52.1s-1.742,1.548-3.484,1.548S22.219,52.1,20.477,52.1s-1.742,1.548-3.484,1.548a2.519,2.519,0,0,1-.635-.085,21.085,21.085,0,0,1-4.529-3.832A2.265,2.265,0,0,1,13.51,49c1.742,0,1.742,1.548,3.484,1.548S18.735,49,20.477,49s1.742,1.548,3.484,1.548S25.7,49,27.444,49s1.742,1.548,3.484,1.548S32.67,49,34.412,49s1.742,1.548,3.484,1.548S39.637,49,41.379,49Z" transform="translate(-2.447 -10.841)" fill="#fbc04c" />
        <path id="Path_20750" data-name="Path 20750" d="M42.812,12.538a2.073,2.073,0,0,0-1.332-.441c-1.742,0-1.742,1.548-3.484,1.548S36.255,12.1,34.513,12.1s-1.742,1.548-3.484,1.548S29.288,12.1,27.546,12.1s-1.742,1.548-3.484,1.548S22.321,12.1,20.579,12.1s-1.742,1.548-3.484,1.548S15.353,12.1,13.612,12.1a2.073,2.073,0,0,0-1.332.441,20.5,20.5,0,0,1,3.112-2.733,2.314,2.314,0,0,0,1.7.743C18.837,10.548,18.837,9,20.579,9s1.742,1.548,3.484,1.548S25.8,9,27.546,9s1.742,1.548,3.484,1.548S32.772,9,34.513,9,36.255,10.548,38,10.548a2.314,2.314,0,0,0,1.7-.743,20.5,20.5,0,0,1,3.112,2.733Z" transform="translate(-2.549 -1.807)" fill="#ffdf7c" />
        <path id="Path_20751" data-name="Path 20751" d="M40.608,8.9a2.314,2.314,0,0,1-1.7.743c-1.742,0-1.742-1.548-3.484-1.548S33.68,9.645,31.938,9.645,30.2,8.1,28.454,8.1,26.712,9.645,24.97,9.645,23.229,8.1,21.487,8.1,19.745,9.645,18,9.645A2.314,2.314,0,0,1,16.3,8.9a20.881,20.881,0,0,1,24.308,0Z" transform="translate(-3.456 -0.903)" fill="#ec782d" />
        <path id="Path_20752" data-name="Path 20752" d="M39.217,54.548a2.519,2.519,0,0,0,.635-.085,20.9,20.9,0,0,1-22.172,0,2.519,2.519,0,0,0,.635.085C20.057,54.548,20.057,53,21.8,53s1.742,1.548,3.484,1.548S27.024,53,28.766,53s1.742,1.548,3.484,1.548S33.991,53,35.733,53,37.475,54.548,39.217,54.548Z" transform="translate(-3.768 -11.745)" fill="#ec782d" />
        <g id="Group_14537" data-name="Group 14537" transform="translate(7.192 7.193)">
          <path id="Path_20753" data-name="Path 20753" d="M12.871,30.548c1.742,0,1.742,1.548,3.484,1.548s1.742-1.548,3.484-1.548S21.58,32.1,23.322,32.1s1.742-1.548,3.484-1.548S28.547,32.1,30.289,32.1s1.742-1.548,3.484-1.548S35.514,32.1,37.256,32.1,39,30.548,40.74,30.548,42.482,32.1,44.224,32.1a2.215,2.215,0,0,0,.362-.04c.011-.244.026-.487.026-.734,0-.273-.009-.542-.021-.814a2.354,2.354,0,0,1-.366.04C42.482,30.548,42.482,29,40.74,29S39,30.548,37.256,30.548,35.514,29,33.773,29s-1.742,1.548-3.484,1.548S28.547,29,26.805,29s-1.742,1.548-3.484,1.548S21.58,29,19.838,29s-1.742,1.548-3.484,1.548S14.613,29,12.871,29s-1.742,1.548-3.484,1.548a2.354,2.354,0,0,1-.366-.04c-.012.272-.021.541-.021.814,0,.247.015.49.026.734a2.214,2.214,0,0,0,.362.04C11.129,32.1,11.129,30.548,12.871,30.548Z" transform="translate(-9 -13.517)" fill="#ff813a" />
          <path id="Path_20754" data-name="Path 20754" d="M16.4,36.1c1.742,0,1.742-1.548,3.484-1.548S21.626,36.1,23.368,36.1s1.742-1.548,3.484-1.548S28.593,36.1,30.335,36.1s1.742-1.548,3.484-1.548S35.56,36.1,37.3,36.1s1.742-1.548,3.484-1.548,1.746,1.519,3.446,1.544q.17-.774.273-1.571a2.145,2.145,0,0,1-.232.027C42.53,34.548,42.53,33,40.788,33s-1.742,1.548-3.484,1.548S35.563,33,33.821,33s-1.742,1.548-3.484,1.548S28.6,33,26.853,33s-1.742,1.548-3.484,1.548S21.628,33,19.886,33s-1.742,1.548-3.484,1.548S14.661,33,12.919,33s-1.742,1.548-3.484,1.548a2.145,2.145,0,0,1-.232-.027q.1.8.272,1.571c1.695-.025,1.714-1.544,3.441-1.544S14.658,36.1,16.4,36.1Z" transform="translate(-9.046 -14.421)" fill="#ff813a" />
          <path id="Path_20755" data-name="Path 20755" d="M12.917,26.548c1.742,0,1.742,1.548,3.484,1.548s1.742-1.548,3.484-1.548S21.626,28.1,23.368,28.1s1.742-1.548,3.484-1.548S28.593,28.1,30.335,28.1s1.742-1.548,3.484-1.548S35.561,28.1,37.3,28.1s1.742-1.548,3.484-1.548S42.528,28.1,44.27,28.1a1.372,1.372,0,0,0,.232-.026q-.1-.774-.27-1.527C42.532,26.517,42.512,25,40.786,25c-1.742,0-1.742,1.548-3.484,1.548S35.561,25,33.819,25s-1.742,1.548-3.484,1.548S28.593,25,26.851,25s-1.742,1.548-3.484,1.548S21.626,25,19.884,25s-1.742,1.548-3.484,1.548S14.659,25,12.917,25c-1.726,0-1.746,1.517-3.443,1.544q-.167.752-.27,1.527a1.372,1.372,0,0,0,.229.026C11.175,28.1,11.175,26.548,12.917,26.548Z" transform="translate(-9.046 -12.614)" fill="#ff813a" />
          <path id="Path_20756" data-name="Path 20756" d="M16.6,40.1c1.742,0,1.742-1.548,3.484-1.548S21.821,40.1,23.562,40.1s1.742-1.548,3.484-1.548S28.788,40.1,30.53,40.1s1.742-1.548,3.484-1.548S35.755,40.1,37.5,40.1s1.742-1.548,3.484-1.548c1.345,0,1.666.912,2.542,1.33.186-.451.355-.911.5-1.38-1.307-.241-1.469-1.5-3.045-1.5-1.742,0-1.742,1.548-3.484,1.548S35.755,37,34.013,37s-1.742,1.548-3.484,1.548S28.788,37,27.046,37s-1.742,1.548-3.484,1.548S21.821,37,20.079,37s-1.742,1.548-3.484,1.548S14.853,37,13.111,37c-1.577,0-1.739,1.257-3.045,1.5.149.469.318.929.5,1.38.876-.418,1.2-1.33,2.542-1.33C14.853,38.548,14.853,40.1,16.6,40.1Z" transform="translate(-9.241 -15.324)" fill="#ff813a" />
          <path id="Path_20757" data-name="Path 20757" d="M16.608,24.1c1.742,0,1.742-1.548,3.484-1.548S21.833,24.1,23.575,24.1s1.742-1.548,3.484-1.548S28.8,24.1,30.542,24.1s1.742-1.548,3.484-1.548S35.768,24.1,37.51,24.1s1.742-1.548,3.484-1.548c1.558,0,1.738,1.225,3,1.486a17.628,17.628,0,0,0-.7-1.825c-.708-.455-1.081-1.208-2.3-1.208-1.742,0-1.742,1.548-3.484,1.548S35.768,21,34.026,21s-1.742,1.548-3.484,1.548S28.8,21,27.059,21s-1.742,1.548-3.484,1.548S21.833,21,20.091,21s-1.742,1.548-3.484,1.548S14.866,21,13.124,21c-1.22,0-1.593.753-2.3,1.208a17.629,17.629,0,0,0-.7,1.825c1.264-.261,1.445-1.486,3-1.486C14.866,22.548,14.866,24.1,16.608,24.1Z" transform="translate(-9.253 -11.71)" fill="#ff813a" />
          <path id="Path_20758" data-name="Path 20758" d="M16.9,44.1c1.742,0,1.742-1.548,3.484-1.548S22.128,44.1,23.869,44.1s1.742-1.548,3.484-1.548S29.095,44.1,30.837,44.1s1.742-1.548,3.484-1.548S36.062,44.1,37.8,44.1s1.742-1.548,3.484-1.548a2.134,2.134,0,0,1,1.417.495c.2-.344.4-.7.576-1.054A2.764,2.764,0,0,0,41.288,41c-1.742,0-1.742,1.548-3.484,1.548S36.062,41,34.32,41s-1.742,1.548-3.484,1.548S29.095,41,27.353,41s-1.742,1.548-3.484,1.548S22.128,41,20.386,41s-1.742,1.548-3.484,1.548S15.16,41,13.418,41a2.764,2.764,0,0,0-1.993.989c.18.359.373.71.576,1.054a2.134,2.134,0,0,1,1.417-.495C15.16,42.548,15.16,44.1,16.9,44.1Z" transform="translate(-9.548 -16.227)" fill="#ff813a" />
          <path id="Path_20759" data-name="Path 20759" d="M17.238,20.1c1.742,0,1.742-1.548,3.484-1.548S22.463,20.1,24.2,20.1s1.742-1.548,3.484-1.548S29.43,20.1,31.172,20.1s1.742-1.548,3.484-1.548S36.4,20.1,38.14,20.1s1.742-1.548,3.484-1.548a2.208,2.208,0,0,1,.843.155,17.717,17.717,0,0,0-1.246-1.657c-1.337.225-1.49,1.5-3.08,1.5C36.4,18.548,36.4,17,34.656,17s-1.742,1.548-3.484,1.548S29.43,17,27.689,17s-1.742,1.548-3.484,1.548S22.463,17,20.721,17s-1.742,1.548-3.484,1.548c-1.59,0-1.743-1.277-3.08-1.5A17.718,17.718,0,0,0,12.911,18.7a2.208,2.208,0,0,1,.843-.156C15.5,18.548,15.5,20.1,17.238,20.1Z" transform="translate(-9.883 -10.807)" fill="#ff813a" />
          <path id="Path_20760" data-name="Path 20760" d="M21.38,14.548c1.742,0,1.742,1.548,3.484,1.548s1.742-1.548,3.484-1.548S30.089,16.1,31.831,16.1s1.742-1.548,3.484-1.548S37.056,16.1,38.8,16.1a2.956,2.956,0,0,0,2.073-1.031c-.294-.291-.6-.575-.909-.845a2.072,2.072,0,0,1-1.164.328c-1.742,0-1.742-1.548-3.484-1.548s-1.742,1.548-3.484,1.548S30.089,13,28.347,13s-1.742,1.548-3.484,1.548S23.122,13,21.38,13s-1.742,1.548-3.484,1.548a2.072,2.072,0,0,1-1.161-.328c-.31.27-.615.554-.909.845A2.942,2.942,0,0,0,17.9,16.1C19.638,16.1,19.638,14.548,21.38,14.548Z" transform="translate(-10.542 -9.903)" fill="#ff813a" />
          <path id="Path_20761" data-name="Path 20761" d="M20.769,46.548c1.742,0,1.742,1.548,3.484,1.548s1.742-1.548,3.484-1.548S29.479,48.1,31.22,48.1s1.742-1.548,3.484-1.548S36.446,48.1,38.188,48.1c1.548,0,1.729-1.221,2.977-1.49a17.783,17.783,0,0,0,1.185-1.5,2.245,2.245,0,0,0-.678-.1c-1.742,0-1.742,1.548-3.484,1.548S36.446,45,34.7,45s-1.742,1.548-3.484,1.548S29.479,45,27.737,45s-1.742,1.548-3.484,1.548S22.511,45,20.769,45s-1.742,1.548-3.484,1.548S15.544,45,13.8,45a2.245,2.245,0,0,0-.678.1,17.782,17.782,0,0,0,1.185,1.5c1.248.269,1.428,1.49,2.977,1.49C19.028,48.1,19.028,46.548,20.769,46.548Z" transform="translate(-9.931 -17.131)" fill="#ff813a" />
          <path id="Path_20762" data-name="Path 20762" d="M25.572,52.1c1.742,0,1.742-1.548,3.484-1.548S30.8,52.1,32.539,52.1s1.742-1.548,3.484-1.548a2.446,2.446,0,0,1,1.781.793c.459-.259.908-.536,1.341-.834C37.771,50.3,37.629,49,36.023,49c-1.742,0-1.742,1.548-3.484,1.548S30.8,49,29.056,49s-1.742,1.548-3.484,1.548S23.83,49,22.088,49c-1.606,0-1.748,1.3-3.124,1.507.433.3.882.574,1.341.834a2.446,2.446,0,0,1,1.784-.793c1.742,0,1.742,1.548,3.484,1.548Z" transform="translate(-11.25 -18.034)" fill="#ff813a" />
          <path id="Path_20763" data-name="Path 20763" d="M33.339,10.548C31.6,10.548,31.6,9,29.856,9s-1.742,1.548-3.484,1.548a2.355,2.355,0,0,1-1.736-.77,17.5,17.5,0,0,0-2.13.814,2.276,2.276,0,0,1,.382-.044c1.742,0,1.742,1.548,3.484,1.548s1.742-1.548,3.484-1.548S31.6,12.1,33.339,12.1s1.742-1.548,3.484-1.548a2.276,2.276,0,0,1,.382.044,17.5,17.5,0,0,0-2.13-.814A2.355,2.355,0,0,1,33.339,10.548Z" transform="translate(-12.05 -9)" fill="#ff813a" />
        </g>
        <path id="Path_20764" data-name="Path 20764" d="M8.1,31.327c0-.273.009-.542.021-.814C6.784,30.314,6.6,29.1,5.132,29.02A20.629,20.629,0,0,0,5,31.327c0,.263.008.519.023.774,1.631.015,1.739,1.381,3.226,1.521a17.952,17.952,0,0,1-.153-2.3Z" transform="translate(-0.904 -6.329)" fill="#c33e11" />
        <path id="Path_20765" data-name="Path 20765" d="M57.92,29.02c-1.471.077-1.653,1.294-2.986,1.493.012.27.021.542.021.814a17.953,17.953,0,0,1-.155,2.3c1.487-.14,1.595-1.506,3.226-1.521.015-.255.023-.511.023-.774a20.631,20.631,0,0,0-.129-2.308Z" transform="translate(-12.152 -6.328)" fill="#c33e11" />
        <path id="Path_20766" data-name="Path 20766" d="M53.934,37.595a2.326,2.326,0,0,0,.438.05c1.6,0,1.75-1.293,3.112-1.5A20.992,20.992,0,0,0,57.833,33c-1.631.015-1.739,1.381-3.226,1.521a17.71,17.71,0,0,1-.673,3.073Z" transform="translate(-11.956 -7.227)" fill="#e3511f" />
        <path id="Path_20767" data-name="Path 20767" d="M8.929,37.595a17.71,17.71,0,0,1-.673-3.073C6.769,34.381,6.661,33.015,5.03,33a20.992,20.992,0,0,0,.348,3.143c1.362.209,1.51,1.5,3.112,1.5a2.326,2.326,0,0,0,.438-.05Z" transform="translate(-0.911 -7.227)" fill="#e3511f" />
        <path id="Path_20768" data-name="Path 20768" d="M54.447,26.575a17.775,17.775,0,0,1,.407,3.061c1.333-.2,1.513-1.414,2.986-1.493a20.153,20.153,0,0,0-.55-3c-1.092.341-1.332,1.44-2.8,1.44C54.473,26.58,54.462,26.575,54.447,26.575Z" transform="translate(-12.072 -5.452)" fill="#e3511f" />
        <path id="Path_20769" data-name="Path 20769" d="M8.563,26.575c-.015,0-.026,0-.041,0-1.471,0-1.711-1.1-2.8-1.44a20.154,20.154,0,0,0-.55,3c1.471.077,1.653,1.294,2.986,1.493a17.775,17.775,0,0,1,.407-3.061Z" transform="translate(-0.943 -5.452)" fill="#e3511f" />
        <path id="Path_20770" data-name="Path 20770" d="M10.082,41.1A17.682,17.682,0,0,1,9.03,38.512a2.327,2.327,0,0,1-.438.05c-1.6,0-1.75-1.293-3.112-1.5a21.178,21.178,0,0,0,.991,3.507c.6.457,1.006,1.092,2.121,1.092a2.151,2.151,0,0,0,1.49-.56Z" transform="translate(-1.013 -8.144)" fill="#c33e11" />
        <path id="Path_20771" data-name="Path 20771" d="M53.627,38.512A17.681,17.681,0,0,1,52.575,41.1a2.151,2.151,0,0,0,1.49.56c1.115,0,1.517-.635,2.121-1.092a21.179,21.179,0,0,0,.991-3.507c-1.362.209-1.51,1.5-3.112,1.5a2.326,2.326,0,0,1-.438-.05Z" transform="translate(-11.649 -8.144)" fill="#c33e11" />
        <path id="Path_20772" data-name="Path 20772" d="M8.723,25.819a17.63,17.63,0,0,1,1.142-3.432,2.072,2.072,0,0,1-1.183.34,2.652,2.652,0,0,1-1.935-.937,20.8,20.8,0,0,0-.867,2.593c1.092.341,1.332,1.44,2.8,1.44.015,0,.026,0,.041,0Z" transform="translate(-1.103 -4.696)" fill="#c33e11" />
        <path id="Path_20773" data-name="Path 20773" d="M52.972,22.387a17.629,17.629,0,0,1,1.142,3.432c.015,0,.026,0,.041,0,1.471,0,1.711-1.1,2.8-1.44a20.8,20.8,0,0,0-.867-2.593,2.652,2.652,0,0,1-1.935.937,2.072,2.072,0,0,1-1.183-.34Z" transform="translate(-11.739 -4.696)" fill="#c33e11" />
        <path id="Path_20774" data-name="Path 20774" d="M10.371,42.122a2.151,2.151,0,0,1-1.49.56c-1.115,0-1.517-.635-2.121-1.092a20.346,20.346,0,0,0,2.16,4.18c1.435-.019,1.681-1.1,2.767-1.436a17.779,17.779,0,0,1-1.315-2.213Z" transform="translate(-1.302 -9.168)" fill="#e3511f" />
        <path id="Path_20775" data-name="Path 20775" d="M52.191,42.122a17.779,17.779,0,0,1-1.316,2.213c1.084.334,1.332,1.417,2.767,1.436a20.346,20.346,0,0,0,2.16-4.18c-.6.457-1.006,1.092-2.121,1.092a2.151,2.151,0,0,1-1.489-.56Z" transform="translate(-11.265 -9.168)" fill="#e3511f" />
        <path id="Path_20776" data-name="Path 20776" d="M49.882,17a2.264,2.264,0,0,0-.4.046,17.764,17.764,0,0,1,2.7,4.258,2.072,2.072,0,0,0,1.183.341,2.652,2.652,0,0,0,1.935-.937,22.019,22.019,0,0,0-1.123-2.315,2.234,2.234,0,0,1-.813.155c-1.742,0-1.742-1.548-3.484-1.548Z" transform="translate(-10.95 -3.614)" fill="#e3511f" />
        <path id="Path_20777" data-name="Path 20777" d="M10.118,21.305a17.764,17.764,0,0,1,2.7-4.258,2.264,2.264,0,0,0-.4-.047c-1.742,0-1.742,1.548-3.484,1.548a2.234,2.234,0,0,1-.813-.155A22.02,22.02,0,0,0,7,20.708a2.652,2.652,0,0,0,1.935.937,2.072,2.072,0,0,0,1.183-.34Z" transform="translate(-1.356 -3.614)" fill="#e3511f" />
        <path id="Path_20778" data-name="Path 20778" d="M47,14.22a17.832,17.832,0,0,1,1.916,1.923,2.264,2.264,0,0,1,.406-.046c1.742,0,1.742,1.548,3.484,1.548a2.234,2.234,0,0,0,.813-.155,20.934,20.934,0,0,0-2.965-4.049A2.073,2.073,0,0,0,49.322,13C48.092,13,47.724,13.766,47,14.22Z" transform="translate(-10.39 -2.71)" fill="#c33e11" />
        <path id="Path_20779" data-name="Path 20779" d="M12.747,16.1a2.265,2.265,0,0,1,.4.046,17.833,17.833,0,0,1,1.919-1.923c-.724-.454-1.092-1.22-2.322-1.22a2.073,2.073,0,0,0-1.332.441A20.935,20.935,0,0,0,8.45,17.49a2.234,2.234,0,0,0,.813.155C11,17.645,11,16.1,12.747,16.1Z" transform="translate(-1.683 -2.71)" fill="#c33e11" />
        <path id="Path_20780" data-name="Path 20780" d="M16.479,49.675a2.28,2.28,0,0,0,.359-.041,17.9,17.9,0,0,1-4.521-4.5c-1.084.334-1.332,1.417-2.767,1.436a20.173,20.173,0,0,0,1.765,2.284,2.265,2.265,0,0,1,1.68-.728C14.737,48.127,14.737,49.675,16.479,49.675Z" transform="translate(-1.932 -9.968)" fill="#c33e11" />
        <path id="Path_20781" data-name="Path 20781" d="M45.036,49.634a2.28,2.28,0,0,0,.359.041c1.742,0,1.742-1.548,3.484-1.548a2.265,2.265,0,0,1,1.68.728,20.174,20.174,0,0,0,1.765-2.284c-1.435-.019-1.681-1.1-2.767-1.436a17.9,17.9,0,0,1-4.521,4.5Z" transform="translate(-9.947 -9.968)" fill="#c33e11" />
        <path id="Path_20782" data-name="Path 20782" d="M26.988,54.771a2.117,2.117,0,0,0,.9-.189,17.691,17.691,0,0,1-2.651-.6,2.377,2.377,0,0,0,1.753.784Z" transform="translate(-5.474 -11.967)" fill="#f03c00" />
        <path id="Path_20783" data-name="Path 20783" d="M36.238,54.771a2.377,2.377,0,0,0,1.753-.784,17.689,17.689,0,0,1-2.651.6,2.117,2.117,0,0,0,.9.189Z" transform="translate(-7.757 -11.967)" fill="#f03c00" />
        <path id="Path_20784" data-name="Path 20784" d="M20.245,52.124a17.782,17.782,0,0,1-2.891-1.616,2.28,2.28,0,0,1-.361.041C15.252,50.548,15.252,49,13.51,49a2.265,2.265,0,0,0-1.68.728,21.085,21.085,0,0,0,4.529,3.832,2.519,2.519,0,0,0,.635.085C18.647,53.645,18.749,52.265,20.245,52.124Z" transform="translate(-2.447 -10.841)" fill="#e3511f" />
        <path id="Path_20785" data-name="Path 20785" d="M44.551,50.548a2.28,2.28,0,0,1-.359-.041A17.782,17.782,0,0,1,41.3,52.124c1.5.141,1.6,1.521,3.251,1.521a2.519,2.519,0,0,0,.635-.085,21.085,21.085,0,0,0,4.529-3.832A2.265,2.265,0,0,0,48.035,49C46.293,49,46.293,50.548,44.551,50.548Z" transform="translate(-9.103 -10.841)" fill="#e3511f" />
        <path id="Path_20786" data-name="Path 20786" d="M40.49,9a2.371,2.371,0,0,0-1.748.779,17.783,17.783,0,0,1,6.393,3.538c.724-.454,1.092-1.22,2.322-1.22a2.073,2.073,0,0,1,1.332.441,20.5,20.5,0,0,0-3.112-2.733,2.314,2.314,0,0,1-1.7.743C42.232,10.548,42.232,9,40.49,9Z" transform="translate(-8.525 -1.807)" fill="#e3511f" />
        <path id="Path_20787" data-name="Path 20787" d="M15.934,13.317a17.783,17.783,0,0,1,6.4-3.538A2.371,2.371,0,0,0,20.579,9c-1.742,0-1.742,1.548-3.484,1.548a2.314,2.314,0,0,1-1.7-.743,20.5,20.5,0,0,0-3.112,2.733,2.073,2.073,0,0,1,1.332-.441C14.842,12.1,15.21,12.863,15.934,13.317Z" transform="translate(-2.549 -1.807)" fill="#e3511f" />
        <path id="Path_20788" data-name="Path 20788" d="M21.487,8.1a2.371,2.371,0,0,1,1.748.779,17.879,17.879,0,0,1,10.439,0A2.371,2.371,0,0,1,35.421,8.1c1.742,0,1.742,1.548,3.484,1.548a2.314,2.314,0,0,0,1.7-.743A20.881,20.881,0,0,0,16.3,8.9a2.314,2.314,0,0,0,1.7.743C19.745,9.645,19.745,8.1,21.487,8.1Z" transform="translate(-3.456 -0.903)" fill="#c33e11" />
        <path id="Path_20789" data-name="Path 20789" d="M35.965,53.035A17.612,17.612,0,0,1,34,53.772a2.377,2.377,0,0,1-1.752.784,2.117,2.117,0,0,1-.9-.189,17.805,17.805,0,0,1-5.173,0,2.117,2.117,0,0,1-.9.189,2.377,2.377,0,0,1-1.753-.784,17.612,17.612,0,0,1-1.964-.737c-1.495.141-1.6,1.521-3.25,1.521a2.519,2.519,0,0,1-.635-.085,20.9,20.9,0,0,0,22.172,0,2.519,2.519,0,0,1-.635.085C37.563,54.556,37.462,53.176,35.965,53.035Z" transform="translate(-3.768 -11.752)" fill="#c33e11" />
        <path id="Path_20790" data-name="Path 20790" d="M55.5,17.861l.046-2.926L55.774,1h.774l.774,48H55l.209-13.16.039-2.346.1-6.154.1-6.363Z" transform="translate(-12.197 0)" fill="#dcd9d0" />
        <path id="Path_20791" data-name="Path 20791" d="M61.548,1l.774,48H60l.774-48Z" transform="translate(-13.326 0)" fill="#dcd9d0" />
        <path id="Path_20792" data-name="Path 20792" d="M60.774,44.352V1L60,49h2.322l-.05-3.1a1.548,1.548,0,0,1-1.5-1.543Z" transform="translate(-13.326 0)" fill="#9c9c9c" />
        <path id="Path_20793" data-name="Path 20793" d="M55.774,44.352V1L55,49h2.322l-.05-3.1a1.548,1.548,0,0,1-1.5-1.543Z" transform="translate(-12.197 0)" fill="#9c9c9c" />
        <ellipse id="Ellipse_1894" data-name="Ellipse 1894" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(16.996 6)" fill="#f06046" />
        <path id="Path_20794" data-name="Path 20794" d="M33.8,12.823a6.292,6.292,0,0,1-.293,1.493,4.349,4.349,0,0,1-1.393,2.065,6.588,6.588,0,0,1-3.6.909H28.4a3.3,3.3,0,0,1-2.5-1,4.584,4.584,0,0,1-1.359-3.672,3.885,3.885,0,0,1,.393-1.664c.536-1.035,1.246-1.405,2.7-1.405,2.033,0,3.1,1.168,3.1,2.322a2.446,2.446,0,0,1-2.322,2.322.774.774,0,0,1,0-1.548.907.907,0,0,0,.774-.774c0-.321-.48-.774-1.548-.774-1.033,0-1.126.182-1.334.581a2.44,2.44,0,0,0-.214,1.006,3.107,3.107,0,0,0,.833,2.439,1.832,1.832,0,0,0,1.5.619,5.131,5.131,0,0,0,2.73-.578,2.869,2.869,0,0,0,.883-1.341,5.551,5.551,0,0,0,.245-1.836,8.321,8.321,0,0,0-.06-.935,3.32,3.32,0,0,0-2.249-2.819,5.419,5.419,0,1,0-1.562,10.606,4.1,4.1,0,0,0,2.391-.817c2.826-2.062,3.159-3.7,2.994-5.2Z" transform="translate(-4.968 -1.581)" fill="#f89180" />
        <path id="Path_20795" data-name="Path 20795" d="M30.292,8.008a.269.269,0,0,0-.177.067.272.272,0,0,0-.058.155,1.31,1.31,0,0,0,.4,1.027,5.109,5.109,0,0,0,.886.661A7.852,7.852,0,0,1,32.627,11c.057.06.136.125.21.089a.194.194,0,0,0,.068-.073C33.911,9.431,31.529,7.878,30.292,8.008Z" transform="translate(-6.563 -1.581)" fill="#f8e2cd" />
        <path id="Path_20796" data-name="Path 20796" d="M27.534,18.747a2.769,2.769,0,0,0-2.149-.048.688.688,0,0,0-.367.368.529.529,0,0,0,.185.517,2.043,2.043,0,0,0,.536.327l.811.387a5.446,5.446,0,0,0,1.26.477,1.926,1.926,0,0,0,1.33-.161c1.537-.912-.845-1.588-1.606-1.867Z" transform="translate(-5.423 -3.954)" fill="#fcc4b5" />
        <path id="Path_20797" data-name="Path 20797" d="M33.235,15.861l-1.057-.06a1.961,1.961,0,0,0-1.239.232l-.574.266c-.129.06-.278.216-.174.31s.239-.046.326-.15A1.712,1.712,0,0,1,32,16.1l1.613.122c.211.015,1.088-.011.846-.416-.12-.2-1.017.073-1.229.06Z" transform="translate(-6.586 -3.326)" fill="#eb5844" />
        <path id="Path_20798" data-name="Path 20798" d="M28.193,7a6.193,6.193,0,1,0,6.193,6.193A6.193,6.193,0,0,0,28.193,7Zm0,11.612a5.419,5.419,0,0,1-5.264-4.146.955.955,0,0,1,.325-.5.626.626,0,0,1,.855.077c.077.108.166.269.289.221a.214.214,0,0,0,.105-.184,1.3,1.3,0,0,0-.13-.769.44.44,0,0,0-.115-.155.475.475,0,0,0-.294-.073c-.4,0-.842.105-1.172-.122l-.007,0a5.414,5.414,0,0,1,10.73-.756c-.411.241-.738,1-.993,1.284a1.852,1.852,0,0,0-.464.675.226.226,0,0,0,0,.146c.046.122.221.112.345.077a4.886,4.886,0,0,0,.748-.287,3.219,3.219,0,0,0,.417-.238A5.419,5.419,0,0,1,28.193,18.612Z" transform="translate(-4.744 -1.355)" fill="#b43a23" />
        <path id="Path_20799" data-name="Path 20799" d="M28.036,10.966a3.681,3.681,0,0,1,.923-.009.718.718,0,0,1,.31.093.8.8,0,0,1,.242.692.19.19,0,0,1-.026.121.187.187,0,0,1-.082.052,4.078,4.078,0,0,1-1.478.288c-.365,0-.959-.172-.837-.668.091-.355.641-.527.949-.569Z" transform="translate(-5.889 -2.243)" fill="#f8e2cd" />
        <circle id="Ellipse_1895" data-name="Ellipse 1895" cx="6" cy="6" r="6" transform="translate(26.996 9)" fill="#f06046" />
        <path id="Path_20800" data-name="Path 20800" d="M45.8,16.823a6.292,6.292,0,0,1-.293,1.493,4.349,4.349,0,0,1-1.393,2.065,6.588,6.588,0,0,1-3.6.909H40.4a3.3,3.3,0,0,1-2.5-1,4.584,4.584,0,0,1-1.359-3.672,3.885,3.885,0,0,1,.393-1.664c.536-1.035,1.246-1.405,2.7-1.405,2.033,0,3.1,1.168,3.1,2.322a2.446,2.446,0,0,1-2.322,2.322.774.774,0,0,1,0-1.548.907.907,0,0,0,.774-.774c0-.321-.48-.774-1.548-.774-1.033,0-1.126.182-1.334.581a2.44,2.44,0,0,0-.214,1.006,3.107,3.107,0,0,0,.833,2.439,1.832,1.832,0,0,0,1.5.619,5.131,5.131,0,0,0,2.73-.578,2.869,2.869,0,0,0,.883-1.341,5.551,5.551,0,0,0,.245-1.836,8.32,8.32,0,0,0-.06-.935,3.32,3.32,0,0,0-2.249-2.819,5.419,5.419,0,1,0-1.562,10.606,4.1,4.1,0,0,0,2.391-.817c2.826-2.062,3.159-3.7,2.994-5.2Z" transform="translate(-7.678 -2.484)" fill="#f89180" />
        <path id="Path_20801" data-name="Path 20801" d="M42.292,12.008a.269.269,0,0,0-.177.067.272.272,0,0,0-.058.155,1.31,1.31,0,0,0,.4,1.027,5.109,5.109,0,0,0,.886.661A7.851,7.851,0,0,1,44.627,15c.057.06.136.125.21.089a.194.194,0,0,0,.068-.073C45.911,13.431,43.529,11.878,42.292,12.008Z" transform="translate(-9.273 -2.485)" fill="#f8e2cd" />
        <path id="Path_20802" data-name="Path 20802" d="M39.534,22.747a2.769,2.769,0,0,0-2.149-.048.688.688,0,0,0-.367.368.529.529,0,0,0,.185.517,2.043,2.043,0,0,0,.536.327l.811.387a5.446,5.446,0,0,0,1.26.477,1.926,1.926,0,0,0,1.33-.161c1.537-.912-.845-1.588-1.606-1.867Z" transform="translate(-8.133 -4.857)" fill="#fcc4b5" />
        <path id="Path_20803" data-name="Path 20803" d="M45.235,19.861l-1.057-.06a1.961,1.961,0,0,0-1.239.232l-.574.266c-.129.06-.278.216-.174.31s.239-.046.326-.15A1.712,1.712,0,0,1,44,20.1l1.613.122c.211.015,1.088-.011.846-.416-.12-.2-1.017.073-1.229.06Z" transform="translate(-9.296 -4.23)" fill="#eb5844" />
        <path id="Path_20804" data-name="Path 20804" d="M40.193,11a6.193,6.193,0,1,0,6.193,6.193A6.193,6.193,0,0,0,40.193,11Zm0,11.612a5.419,5.419,0,0,1-5.264-4.146.955.955,0,0,1,.325-.5.626.626,0,0,1,.852.077c.077.108.166.269.289.221a.214.214,0,0,0,.105-.184,1.3,1.3,0,0,0-.13-.769.44.44,0,0,0-.115-.155.475.475,0,0,0-.294-.073c-.4,0-.842.105-1.172-.122l-.007,0a5.414,5.414,0,0,1,10.73-.756c-.411.241-.738,1-.993,1.284a1.852,1.852,0,0,0-.464.675.226.226,0,0,0,0,.146c.046.122.221.112.345.077a4.887,4.887,0,0,0,.748-.287,3.219,3.219,0,0,0,.417-.238,5.419,5.419,0,0,1-5.366,4.759Z" transform="translate(-7.454 -2.259)" fill="#b43a23" />
        <path id="Path_20805" data-name="Path 20805" d="M40.036,14.966a3.681,3.681,0,0,1,.923-.009.718.718,0,0,1,.31.093.8.8,0,0,1,.242.692.19.19,0,0,1-.026.121.187.187,0,0,1-.082.052,4.078,4.078,0,0,1-1.478.288c-.365,0-.959-.172-.837-.668.091-.355.641-.527.949-.569Z" transform="translate(-8.599 -3.147)" fill="#f8e2cd" />
        <path id="Path_20806" data-name="Path 20806" d="M10.329,12.6a13.625,13.625,0,0,1,5.41,3.073A7.819,7.819,0,1,1,4.145,26.138a14.071,14.071,0,0,1-2.43-4.474C.693,18.638.623,15.3,2.465,13.458A5.76,5.76,0,0,1,6.668,12a12.535,12.535,0,0,1,3.661.6Zm4.606,8.686a4.645,4.645,0,1,0-4.645,4.645A4.645,4.645,0,0,0,14.935,21.287Z" transform="translate(-0.001 -2.481)" fill="#fffae8" />
        <circle id="Ellipse_1896" data-name="Ellipse 1896" cx="4.5" cy="4.5" r="4.5" transform="translate(5.996 14)" fill="#fcd83c" />
        <path id="Path_20807" data-name="Path 20807" d="M2.466,13.462A5.719,5.719,0,0,1,6.569,12a13.346,13.346,0,0,1,5.085,1.084,13.987,13.987,0,0,1,2.555,1.354,10.119,10.119,0,0,1,1.532,1.239,7.941,7.941,0,0,1,.712.82c-.222-.173-.451-.346-.7-.509A13.986,13.986,0,0,0,13.2,14.63a13.339,13.339,0,0,0-5.085-1.084,5.714,5.714,0,0,0-4.1,1.462c-1.656,1.656-1.764,4.512-1.03,7.267A14.737,14.737,0,0,0,5.491,27.43a7.912,7.912,0,0,1-.807-.7,13.882,13.882,0,0,1-3.243-6.005C.7,17.974.81,15.119,2.466,13.462Z" transform="translate(-0.002 -2.483)" fill="#fff2c4" />
        <path id="Path_20808" data-name="Path 20808" d="M14.941,21.3a4.61,4.61,0,0,0-.683-2.412,4.639,4.639,0,1,1-6.374,6.373A4.635,4.635,0,0,0,14.941,21.3Z" transform="translate(-1.556 -4.039)" fill="#f6a635" />
        <path id="Path_20809" data-name="Path 20809" d="M16.6,16.538a9.213,9.213,0,0,0-.836-.728,7.811,7.811,0,0,1,1.359,6.5,4.663,4.663,0,0,0-2.013-2.576,4.634,4.634,0,0,1-6.374,6.372,4.661,4.661,0,0,0,2.595,2.018,7.834,7.834,0,0,1-5.445-.678A7.886,7.886,0,0,1,4.8,26.744a9.315,9.315,0,0,0,.74.852A7.819,7.819,0,1,0,16.6,16.537Z" transform="translate(-0.859 -3.345)" fill="#ffefb5" />
        <path id="Path_20810" data-name="Path 20810" d="M12.621,19.193a1.14,1.14,0,0,1,.619.812,1.354,1.354,0,0,1-.334.957,3.059,3.059,0,0,1-1.515,1.061.954.954,0,0,1-.71,0c-.612-.31-.648-1.69-.338-2.237A1.757,1.757,0,0,1,12.621,19.193Z" transform="translate(-2.068 -4.064)" fill="#ffef96" />
        <path id="Path_20811" data-name="Path 20811" d="M32.548,37A1.548,1.548,0,1,0,34.1,38.548,1.548,1.548,0,0,0,32.548,37Zm0,2.477a.929.929,0,1,1,.929-.929A.929.929,0,0,1,32.548,39.477Z" transform="translate(-6.777 -8.131)" fill="#497323" />
        <path id="Path_20812" data-name="Path 20812" d="M40.935,37a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,40.935,37Zm0,3.1A1.161,1.161,0,1,1,42.1,38.935,1.161,1.161,0,0,1,40.935,40.1Z" transform="translate(-8.583 -8.131)" fill="#497323" />
        <path id="Path_20813" data-name="Path 20813" d="M38.935,34a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,38.935,34Zm0,3.1A1.161,1.161,0,1,1,40.1,35.935,1.161,1.161,0,0,1,38.935,37.1Z" transform="translate(-8.132 -7.453)" fill="#65a229" />
        <path id="Path_20814" data-name="Path 20814" d="M35.935,28a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,35.935,28Zm0,3.1A1.161,1.161,0,1,1,37.1,29.935,1.161,1.161,0,0,1,35.935,31.1Z" transform="translate(-7.454 -6.098)" fill="#eef4ae" />
        <path id="Path_20815" data-name="Path 20815" d="M29.548,29A1.548,1.548,0,1,0,31.1,30.548,1.548,1.548,0,0,0,29.548,29Zm0,2.477a.929.929,0,1,1,.929-.929A.929.929,0,0,1,29.548,31.477Z" transform="translate(-6.099 -6.324)" fill="#eef4ae" />
        <path id="Path_20816" data-name="Path 20816" d="M32.935,33a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,32.935,33Zm0,3.1A1.161,1.161,0,1,1,34.1,34.935,1.161,1.161,0,0,1,32.935,36.1Z" transform="translate(-6.777 -7.227)" fill="#eef4ae" />
        <path id="Path_20817" data-name="Path 20817" d="M27.548,34A1.548,1.548,0,1,0,29.1,35.548,1.548,1.548,0,0,0,27.548,34Zm0,2.477a.929.929,0,1,1,.929-.929A.929.929,0,0,1,27.548,36.477Z" transform="translate(-5.647 -7.453)" fill="#eef4ae" />
        <path id="Path_20818" data-name="Path 20818" d="M35.548,39A1.548,1.548,0,1,0,37.1,40.548,1.548,1.548,0,0,0,35.548,39Zm0,2.477a.929.929,0,1,1,.929-.929A.929.929,0,0,1,35.548,41.477Z" transform="translate(-7.454 -8.583)" fill="#eef4ae" />
        <path id="Path_20819" data-name="Path 20819" d="M40.548,31A1.548,1.548,0,1,0,42.1,32.548,1.548,1.548,0,0,0,40.548,31Zm0,2.477a.929.929,0,1,1,.929-.929A.929.929,0,0,1,40.548,33.477Z" transform="translate(-8.583 -6.776)" fill="#497323" />
        <path id="Path_20820" data-name="Path 20820" d="M27.935,38a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,27.935,38Zm0,3.1A1.161,1.161,0,1,1,29.1,39.935,1.161,1.161,0,0,1,27.935,41.1Z" transform="translate(-5.647 -8.357)" fill="#497323" />
        <path id="Path_20821" data-name="Path 20821" d="M27.935,31a1.935,1.935,0,1,0,1.935,1.935A1.935,1.935,0,0,0,27.935,31Zm0,3.1A1.161,1.161,0,1,1,29.1,32.935,1.161,1.161,0,0,1,27.935,34.1Z" transform="translate(-5.647 -6.776)" fill="#497323" />
        <path id="Path_20822" data-name="Path 20822" d="M47.645,30v2.322L43,35.419V33.1Z" transform="translate(-9.487 -6.55)" fill="#65a229" />
        <path id="Path_20823" data-name="Path 20823" d="M40.419,44.774l-3.1,5.419L35,49.419l3.035-5.311L38.1,44Z" transform="translate(-7.68 -9.712)" fill="#65a229" />
        <path id="Path_20824" data-name="Path 20824" d="M24.967,45.322l-.774,2.322L18,45.322,18.774,43Z" transform="translate(-3.84 -9.486)" fill="#65a229" />
        <path id="Path_20825" data-name="Path 20825" d="M0,0H2.507V1.872H0Z" transform="translate(28.863 25.998) rotate(-45)" fill="#65a229" />
        <path id="Path_20826" data-name="Path 20826" d="M0,0H1.872V2.507H0Z" transform="translate(24.219 21.678) rotate(-45)" fill="#65a229" />
        <path id="Path_20827" data-name="Path 20827" d="M27,28h2.322v3.1H27Z" transform="translate(-5.873 -6.098)" fill="#65a229" />
        <path id="Path_20828" data-name="Path 20828" d="M26.1,34.324l-.774.774-1,1L23,34.773,24.773,33Z" transform="translate(-4.97 -7.227)" fill="#65a229" />
        <path id="Path_20829" data-name="Path 20829" d="M0,0H2.507V1.872H0Z" transform="translate(18.8 29.867) rotate(-45)" fill="#65a229" />
        <path id="Path_20830" data-name="Path 20830" d="M35,36h2.322v3.1H35Z" transform="translate(-7.68 -7.905)" fill="#65a229" />
        <path id="Path_20831" data-name="Path 20831" d="M0,0H1.872V2.507H0Z" transform="translate(23.444 30.966) rotate(-45)" fill="#65a229" />
        <path id="Path_20832" data-name="Path 20832" d="M43,35.645l4.645-3.1V31L43,34.1Z" transform="translate(-9.487 -6.776)" fill="#5f873b" />
        <path id="Path_20833" data-name="Path 20833" d="M39.4,44.56l-2.219,3.883a1.548,1.548,0,0,1-1.833.7l-.1-.033L35,49.545l2.322.774,3.1-5.419Z" transform="translate(-7.68 -9.838)" fill="#5f873b" />
        <path id="Path_20834" data-name="Path 20834" d="M24.7,45.823a1.548,1.548,0,0,1-2.013.96l-4.456-1.672L18,45.8l6.193,2.322.774-2.322-.232-.086Z" transform="translate(-3.84 -9.963)" fill="#5f873b" />
        <path id="Path_20835" data-name="Path 20835" d="M0,0H1.095V2.507H0Z" transform="translate(24.218 21.677) rotate(-45)" fill="#5f873b" />
        <path id="Path_20836" data-name="Path 20836" d="M29,28h.774v3.1H29Z" transform="translate(-6.325 -6.098)" fill="#5f873b" />
        <path id="Path_20837" data-name="Path 20837" d="M35,36h.774v3.1H35Z" transform="translate(-7.68 -7.905)" fill="#5f873b" />
        <path id="Path_20838" data-name="Path 20838" d="M0,0H2.507V1.095H0Z" transform="translate(19.349 30.416) rotate(-45)" fill="#5f873b" />
        <path id="Path_20839" data-name="Path 20839" d="M0,0H2.507V1.095H0Z" transform="translate(29.412 26.547) rotate(-45)" fill="#5f873b" />
        <path id="Path_20840" data-name="Path 20840" d="M0,0H2.507V1.095H0Z" transform="translate(18.576 28.094) rotate(-45)" fill="#5f873b" />
        <path id="Path_20841" data-name="Path 20841" d="M0,0H1.095V2.507H0Z" transform="translate(23.444 30.966) rotate(-45)" fill="#5f873b" />
        <path id="Path_20842" data-name="Path 20842" d="M30.548,34a1.548,1.548,0,0,1,.519,3,1.434,1.434,0,0,1-.519.093,1.548,1.548,0,1,1,0-3.1Z" transform="translate(-6.325 -7.453)" fill="#65a229" />
        <ellipse id="Ellipse_1897" data-name="Ellipse 1897" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(24.996 23)" fill="#65a229" />
        <ellipse id="Ellipse_1898" data-name="Ellipse 1898" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(25.996 24)" fill="#f1fbb6" />
        <circle id="Ellipse_1899" data-name="Ellipse 1899" cx="1" cy="1" r="1" transform="translate(22.996 27)" fill="#f1fbb6" />
        <circle id="Ellipse_1900" data-name="Ellipse 1900" cx="0.5" cy="0.5" r="0.5" transform="translate(35.996 36)" fill="#f56300" />
        <ellipse id="Ellipse_1901" data-name="Ellipse 1901" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(20.996 20)" fill="#f03c00" />
        <ellipse id="Ellipse_1902" data-name="Ellipse 1902" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(15.996 25)" fill="#f03c00" />
        <circle id="Ellipse_1903" data-name="Ellipse 1903" cx="1" cy="1" r="1" transform="translate(22.996 37)" fill="#f03c00" />
        <ellipse id="Ellipse_1904" data-name="Ellipse 1904" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(37.996 34)" fill="#c33e11" />
        <ellipse id="Ellipse_1905" data-name="Ellipse 1905" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(9.996 32)" fill="#f56300" />
        <circle id="Ellipse_1906" data-name="Ellipse 1906" cx="0.5" cy="0.5" r="0.5" transform="translate(22.996 40)" fill="#c33e11" />
        <circle id="Ellipse_1907" data-name="Ellipse 1907" cx="0.5" cy="0.5" r="0.5" transform="translate(37.996 28)" fill="#f56300" />
        <ellipse id="Ellipse_1908" data-name="Ellipse 1908" cy="0.5" ry="0.5" transform="translate(12.996 31)" fill="#f56300" />
        <ellipse id="Ellipse_1909" data-name="Ellipse 1909" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(39.996 27)" fill="#f03c00" />
        <circle id="Ellipse_1910" data-name="Ellipse 1910" cx="0.5" cy="0.5" r="0.5" transform="translate(37.996 19)" fill="#f56300" />
        <ellipse id="Ellipse_1911" data-name="Ellipse 1911" cx="0.5" rx="0.5" transform="translate(36.996 27)" fill="#c33e11" />
        <ellipse id="Ellipse_1912" data-name="Ellipse 1912" cy="0.5" ry="0.5" transform="translate(36.996 33)" fill="#c33e11" />
        <ellipse id="Ellipse_1913" data-name="Ellipse 1913" cy="0.5" ry="0.5" transform="translate(36.996 35)" fill="#c33e11" />
        <ellipse id="Ellipse_1914" data-name="Ellipse 1914" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(19.996 40)" fill="#f03c00" />
        <circle id="Ellipse_1915" data-name="Ellipse 1915" cx="0.5" cy="0.5" r="0.5" transform="translate(18.996 39)" fill="#c33e11" />
        <circle id="Ellipse_1916" data-name="Ellipse 1916" cx="0.5" cy="0.5" r="0.5" transform="translate(16.996 37)" fill="#c33e11" />
        <ellipse id="Ellipse_1917" data-name="Ellipse 1917" cx="0.5" rx="0.5" transform="translate(16.996 40)" fill="#c33e11" />
        <ellipse id="Ellipse_1918" data-name="Ellipse 1918" cx="0.5" rx="0.5" transform="translate(38.996 23)" fill="#c33e11" />
        <circle id="Ellipse_1919" data-name="Ellipse 1919" cx="0.5" cy="0.5" r="0.5" transform="translate(14.996 28)" fill="#f56300" />
        <ellipse id="Ellipse_1920" data-name="Ellipse 1920" cy="0.5" ry="0.5" transform="translate(12.996 29)" fill="#f56300" />
        <circle id="Ellipse_1921" data-name="Ellipse 1921" cx="0.5" cy="0.5" r="0.5" transform="translate(32.996 37)" fill="#f56300" />
        <ellipse id="Ellipse_1922" data-name="Ellipse 1922" cy="0.5" ry="0.5" transform="translate(33.996 34)" fill="#f56300" />
        <ellipse id="Ellipse_1923" data-name="Ellipse 1923" cx="0.5" rx="0.5" transform="translate(39.996 20)" fill="#c33e11" />
        <circle id="Ellipse_1924" data-name="Ellipse 1924" cx="0.5" cy="0.5" r="0.5" transform="translate(10.996 28)" fill="#c33e11" />
        <circle id="Ellipse_1925" data-name="Ellipse 1925" cx="0.5" cy="0.5" r="0.5" transform="translate(22.996 19)" fill="#f56300" />
        <circle id="Ellipse_1926" data-name="Ellipse 1926" cx="0.5" cy="0.5" r="0.5" transform="translate(21.996 18)" fill="#c33e11" />
        <circle id="Ellipse_1927" data-name="Ellipse 1927" cx="0.5" cy="0.5" r="0.5" transform="translate(35.996 22)" fill="#c33e11" />
        <circle id="Ellipse_1928" data-name="Ellipse 1928" cx="0.5" cy="0.5" r="0.5" transform="translate(39.996 21)" fill="#f56300" />
        <circle id="Ellipse_1929" data-name="Ellipse 1929" cx="0.5" cy="0.5" r="0.5" transform="translate(26.996 34)" fill="#c33e11" />
        <circle id="Ellipse_1930" data-name="Ellipse 1930" cx="1.5" cy="1.5" r="1.5" transform="translate(36.996 30)" fill="#f03c00" />
        <circle id="Ellipse_1931" data-name="Ellipse 1931" cx="1.5" cy="1.5" r="1.5" transform="translate(6.996 27)" fill="#c33e11" />
        <circle id="Ellipse_1932" data-name="Ellipse 1932" cx="1" cy="1" r="1" transform="translate(10.996 34)" fill="#c33e11" />
        <ellipse id="Ellipse_1933" data-name="Ellipse 1933" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(38.996 23)" fill="#f03c00" />
      </g>
    </svg>
  );
}

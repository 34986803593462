import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputTextRichStyled } from "./styled";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const InputTextRich = ({
  theme_standard,
  theme_normal_text_rich,
  theme_text_rich_lucky,
  theme_normal,
  labelTitle,
  labelSub,
  maxLength,
  onChange,
  value,
  disabled,
  height,
  minHieght,
  inlineOption,
  errors,
  fontSize,
  listOption,
  optionsTextRich = [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    // "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  loadRichText,
  // svg,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_text_rich_lucky: theme_text_rich_lucky,
    theme_normal: theme_normal,
    theme_normal_text_rich: theme_normal_text_rich,
  });

  const [count, setCount] = useState(0);
  const [isInitial, setIsInitial] = useState(false);
  const [_value, _setValue] = useState(EditorState.createEmpty());

  useEffect(() => {
    // loadRichText ใช้กรณี fetch data ครั้งแรก
    if (loadRichText === true) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setIsInitial(true);
      _setValue(editorState);
      setCount(editorState.getCurrentContent().getPlainText().length);
    } else {
      if (!isInitial || value === null || value === false || value === "") {
        if (isInitial || value === false || value === "" || value === null) {
          _setValue("");
        } else {
          const blocksFromHtml = htmlToDraft(`<div>${value}</div>`); //fix this on production tag <div> not appear it will not effect to data when post to API
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          setIsInitial(true);
          _setValue(editorState);
          setCount(editorState.getCurrentContent().getPlainText().length);
        }
      }
    }
    // if (value && !isInitial) {
    //   const blocksFromHtml = htmlToDraft(value);
    //   const { contentBlocks, entityMap } = blocksFromHtml;
    //   const contentState = ContentState.createFromBlockArray(
    //     contentBlocks,
    //     entityMap
    //   );
    //   const editorState = EditorState.createWithContent(contentState);
    //   _setValue(editorState);
    //   setCount(editorState.getCurrentContent().getPlainText().length);
    //   setIsInitial(true);
    // }
  }, [value, isInitial, loadRichText]);

  const handleChange = (e) => {
    _setValue(e);
    setCount(e.getCurrentContent().getPlainText().length);
    onChange && onChange(draftToHtml(convertToRaw(e.getCurrentContent())));
  };

  return (
    <InputTextRichStyled
      height={height}
      minHieght={minHieght}
      disabled={disabled}
      fontSize={fontSize}
    >
      <div className={customClass}>
        <div className="title_layout">
          {labelTitle && (
            <div className="show_title">
              <div className="title_display">{labelTitle}</div>
              <div className="sub_display">{labelSub}</div>
            </div>
          )}
          {maxLength && (
            <div className="num_show">
              {`${count > 0 ? RenderCommaMoney(count, 0) : 0}/${
                maxLength ? RenderCommaMoney(maxLength, 0) : 100
              }`}
            </div>
          )}
        </div>
        <div className="editor_wrap">
          <Editor
            editorClassName="editorClassName"
            editorState={_value}
            onEditorStateChange={handleChange}
            editorStyle={{ background: disabled ? "#E0E0E0" : "#fff" }}
            readOnly={disabled}
            toolbar={{
              options: optionsTextRich,
              inline: inlineOption && inlineOption,
              list: listOption && listOption,
              image: {
                alignmentEnabled: false, // Disable alignment options in the image popup
                uploadEnabled: true,
                previewImage: true,
                inputAccept:
                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              },
            }}
            handleBeforeInput={(val) => {
              const textLength =
                _value && _value.getCurrentContent().getPlainText().length;
              if (val && textLength >= maxLength) {
                return "handled";
              }
              return "not-handled";
            }}
            // handlePastedText={(val) => {
            //   const textLength = _value
            //     .getCurrentContent()
            //     .getPlainText().length;
            //   return val.length + textLength >= maxLength;
            // }}
            handlePastedText={(val) => {
              const currentTextLength = _value
                .getCurrentContent()
                .getPlainText().length;
              const newTextLength = val.length + currentTextLength;
              if (newTextLength >= maxLength) {
                const remainingLength = maxLength - currentTextLength;
                const truncatedText = val.slice(0, remainingLength);
                // Get the current selection state
                const selectionState = _value.getSelection();
                // Collapse the selection to the end
                const collapsedSelection = selectionState.merge({
                  anchorKey: selectionState.getAnchorKey(),
                  anchorOffset: selectionState.getEndOffset(), // Collapse to the end
                  focusKey: selectionState.getFocusKey(),
                  focusOffset: selectionState.getEndOffset(),
                  isBackward: false,
                });
                if (!collapsedSelection.isCollapsed()) {
                  console.error("The selection is not collapsed.");
                  return; // or handle the error as needed
                }
                // Get the current content state
                const contentState = _value.getCurrentContent();
                // Create a new content state with the text inserted at the collapsed selection
                const newContentState = Modifier.insertText(
                  contentState,
                  collapsedSelection,
                  truncatedText
                );
                // Create a new editor state with the updated content
                const newEditorState = EditorState.push(
                  _value,
                  newContentState,
                  "insert-characters"
                );
                // Update the editor state
                handleChange(newEditorState);
                return "handled"; // Indicate that the paste was handled
              }
              return "not-handled"; // Otherwise, let the default paste behavior occur
            }}
          ></Editor>
        </div>
        {errors && <div className="error">{errors}</div>}
      </div>
    </InputTextRichStyled>
  );
};

InputTextRich.propTypes = {};

export default InputTextRich;

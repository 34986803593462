export default function CloseX({ width = '32', height = '32', color = '#ed2b27', bg = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_33213" data-name="Group 33213" transform="translate(-424 -1112)">
        <g id="Group_33212" data-name="Group 33212" transform="translate(424 1112)">
          <circle id="Ellipse_5181" data-name="Ellipse 5181" cx="16" cy="16" r="16" fill={bg} />
        </g>
        <g id="x-mark" transform="translate(427.2 1115.2)">
          <path id="Path_49751" data-name="Path 49751" d="M12.8,25.6A12.8,12.8,0,1,1,25.6,12.8,12.814,12.814,0,0,1,12.8,25.6Zm0-23.771A10.971,10.971,0,1,0,23.771,12.8,10.984,10.984,0,0,0,12.8,1.829Zm0,0" fill={color} />
          <path id="Path_49752" data-name="Path 49752" d="M156.343,157.259a.911.911,0,0,1-.646-.268l-9.143-9.143a.914.914,0,1,1,1.293-1.293l9.143,9.143a.914.914,0,0,1-.646,1.561Zm0,0" transform="translate(-138.972 -138.973)" fill={color} />
          <path id="Path_49753" data-name="Path 49753" d="M147.2,157.258a.914.914,0,0,1-.646-1.561l9.143-9.143a.914.914,0,1,1,1.293,1.293l-9.143,9.143A.911.911,0,0,1,147.2,157.258Zm0,0" transform="translate(-138.972 -138.972)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

import { AgeDistributionStyled } from "./styled";
import { Blocks, Typographys } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import theme from "styles/theme.json";
// import momentTZ from "moment-timezone";
// import moment from "moment-timezone";
// import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const AgeDistribution = ({ data }) => {
  const [_dataNoCheckup, _setDataNoCheckup] = useState();
  const [_dataWithCheckup, _setDataWithCheckup] = useState();

  useEffect(() => {
    if (data) {
      _setDataWithCheckup([
        data["19"].employee_count_with_checkup,
        data["20_29"].employee_count_with_checkup,
        data["30_39"].employee_count_with_checkup,
        data["40_49"].employee_count_with_checkup,
        data["50_59"].employee_count_with_checkup,
        data["60"].employee_count_with_checkup,
      ]);
      _setDataNoCheckup([
        data["19"].employee_count_no_checkup,
        data["20_29"].employee_count_no_checkup,
        data["30_39"].employee_count_no_checkup,
        data["40_49"].employee_count_no_checkup,
        data["50_59"].employee_count_no_checkup,
        data["60"].employee_count_no_checkup,
      ]);
    }
  }, [data]);

  return (
    <AgeDistributionStyled>
      <div className="box_age_dis">
        <div className="top_age_dis">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="แบ่งตามช่วงอายุ"
              labelBottom="Age distribution"
              fontSize={theme.FONT.SIZE.S18}
              fontSizeLabelButtom={theme.FONT.SIZE.S14}
              fontFamilyTop={theme.FONT.STYLE.BOLD}
              fontFamilyBottom={theme.FONT.STYLE.REGULAR}
            />
          </div>
          <div className="right_men_layout">
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_center
                label="พนักงานที่มีผลสุขภาพประจำปีแล้ว"
                bgColor={theme.COLORS.PURPLE_1}
                showRound
              />
            </div>
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_center
                label="พนักงานที่ยังไม่มีผลสุขภาพประจำปี"
                bgColor={theme.COLORS.BLUE_25}
                showRound
              />
            </div>
          </div>
        </div>
        <div className="body_graph">
          {_dataNoCheckup && _dataWithCheckup ? (
            <>
              <div className="top_title_g">
                <div>จำนวน</div>
                <div>พนักงาน</div>
              </div>
              <div className="right_title_g">อายุ (ปี)</div>
              <Chart
                type="bar"
                height={328}
                options={{
                  chart: {
                    stacked: true,
                    fontFamily: theme.FONT.STYLE.BOLD,
                    toolbar: {
                      show: false,
                    },
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 480,
                  //     options: {
                  //       legend: {
                  //         position: "bottom",
                  //         offsetX: -10,
                  //         offsetY: 0,
                  //       },
                  //     },
                  //   },
                  //   {
                  //     breakpoint: 780,
                  //     options: {
                  //       xaxis: {
                  //         labels: {
                  //           rotate: -80,
                  //         }
                  //       },
                  //     }
                  //   },
                  //   {
                  //     breakpoint: 1200,
                  //     options: {
                  //       plotOptions: {
                  //         bar: {
                  //           columnWidth: "40%",
                  //         },
                  //       },
                  //     }
                  //   }
                  // ],
                  tooltip: {
                    style: {
                      fontSize: "12px",
                      fontFamily: theme.FONT.STYLE.REGULAR,
                    },
                    x: {
                      formatter: function (
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return `${value} ปี`;
                      },
                    },
                    y: {
                      formatter: function (
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return `${RenderCommaMoney(
                          Number(value).toFixed(0),
                          0
                        )} คน`;
                      },
                    },
                  },
                  dataLabels: {
                    style: {
                      // colors: theme.COLORS.WHITE_1,
                      fontSize: "14px",
                      fontFamily: theme.FONT.STYLE.REGULAR,
                      fontWeight: 400,
                    },
                  },
                  xaxis: {
                    categories: [
                      "<20",
                      "20-29",
                      "30-39",
                      "40-49",
                      "50-59",
                      "60+",
                    ],
                    labels: {
                      rotate: -16,
                      // formatter: function (value, timestamp, opts) {
                      //   return parse(value)
                      // }
                      hideOverlappingLabels: false,
                      style: {
                        colors: theme.COLORS.BLACK_6,
                        fontSize: "16px",
                        fontFamily: theme.FONT.STYLE.BOLD,
                        cssClass: "apexcharts-yaxis-label",
                      },
                    },
                    axisBorder: {
                      show: true,
                      color: theme.COLORS.PURPLE_7,
                      height: 3,
                    },
                  },
                  yaxis: {
                    axisBorder: {
                      show: true,
                      color: theme.COLORS.PURPLE_7,
                      width: 3,
                    },
                    labels: {
                      formatter: function (value) {
                        return RenderCommaMoney(Number(value).toFixed(0), 0);
                      },
                      style: {
                        colors: theme.COLORS.BLACK_11,
                        fontSize: "12px",
                        fontFamily: theme.FONT.STYLE.REGULAR,
                      },
                    },
                  },
                  colors: ["#002366", "#79A6FF"],
                  fill: {
                    opacity: 1,
                  },

                  plotOptions: {
                    bar: {
                      columnWidth: "45%",
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "พนักงานที่มีผลสุขภาพประจำปีแล้ว",
                    data: _dataWithCheckup,
                  },
                  {
                    name: "พนักงานที่ยังไม่มีผลสุขภาพประจำปี",
                    data: _dataNoCheckup,
                  },
                ]}
              />
            </>
          ) : (
            <div className="lbl_empty">ไม่มีข้อมูล</div>
          )}
        </div>
      </div>
    </AgeDistributionStyled>
  );
};

export default AgeDistribution;

export default function Success() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <g id="mission" transform="translate(0)">
        <ellipse id="Ellipse_5174" data-name="Ellipse 5174" cx="3" cy="3.5" rx="3" ry="3.5" transform="translate(14 14)" fill="#4bc0ff" />
        <path id="Path_49745" data-name="Path 49745" d="M23.349,234.574v-6.089L33.38,218.453a2.116,2.116,0,0,0-2.993-2.993l-8.7,8.7a1.411,1.411,0,0,1-1,.413H13.314a1.411,1.411,0,0,1-1-.413l-8.7-8.7A2.116,2.116,0,0,0,.62,218.453l10.032,10.032v6.045Z" transform="translate(0 -200.574)" fill="#4bc0ff" />
        <path id="Path_49746" data-name="Path 49746" d="M178.847,4.282a1,1,0,0,0-.8-.678l-2.9-.421-1.3-2.628a1,1,0,0,0-1.787,0l-1.3,2.628-2.9.421a1,1,0,0,0-.552,1.7l2.1,2.045-.5,2.888a1,1,0,0,0,1.445,1.05l2.594-1.364,2.594,1.364a1,1,0,0,0,1.445-1.05l-.5-2.888,2.1-2.045a1,1,0,0,0,.252-1.021Z" transform="translate(-155.919)" fill="#fdb43a" />
        <path id="Path_49747" data-name="Path 49747" d="M261.927,4.282a1,1,0,0,0-.8-.678l-2.9-.421-1.3-2.628A1,1,0,0,0,256.033,0H256V9.941l.033-.017,2.594,1.364a1,1,0,0,0,1.445-1.05l-.5-2.888,2.1-2.045A1,1,0,0,0,261.927,4.282Z" transform="translate(-239)" fill="#f57a21" />
        <g id="Group_33147" data-name="Group 33147" transform="translate(17 14.267)">
          <path id="Path_49748" data-name="Path 49748" d="M259.32,218.32A3.32,3.32,0,0,0,256,215v6.641A3.32,3.32,0,0,0,259.32,218.32Z" transform="translate(-256 -214.989)" fill="#0592fd" />
          <path id="Path_49749" data-name="Path 49749" d="M272.38,215.46a2.116,2.116,0,0,0-2.993,0l-8.7,8.7a1.411,1.411,0,0,1-1,.413H256v9.974l6.349.022v-6.089l10.032-10.032A2.116,2.116,0,0,0,272.38,215.46Z" transform="translate(-256 -214.84)" fill="#0592fd" />
        </g>
      </g>
    </svg>
  );
}

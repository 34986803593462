import styled from 'styled-components';

export const DepartmentManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layer_one {
    display: flex;
  }
  .sub_label {
    margin-top: 47px;
    color: #3E3E3E;
    margin-bottom: 14px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .layer_two {
    margin-top: 41px;
    display: flex;
    justify-content: space-between;
    &.flex_end {
      justify-content: flex-end;
    }
    &.mt4 {
      margin-top: 4px;
      align-items: flex-end;
    }
    .search_layer {
      width: 50%;
    }
    .btn_layer {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      column-gap: 11px;
      &.w100 {
        column-gap: 11px;
        width: 100%;
      }
      .body_btn {
        width: 30%;
      }
      .min_132 {
        min-width: 132px;
      }
    }
  }
  .layer_table {
    margin-top: 24px;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
      
    }
  }
`;

import styled from 'styled-components';

export const MsdPercentOfRiskPuiAndPainStyled = styled.div`
  .top_title {
    .head {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size:  ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
    }
    .sub_head {
      margin-top: 8px;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size:  ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: noto_sans_thai_medium, noto_sans_regular;
    }
  }
  .label_show {
    display: flex;
    margin-top: 19px;
    .show_being {
      margin-right: 10px;
      &.no_margin {
        margin-right: 0px;
      }
    }
  }
  .graph_show {
    margin-top: 18px;
    padding: 0px 10px;
    margin-bottom: 23px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

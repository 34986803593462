import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnDateFillter2Styled } from './styled';

const BtnDateFillter2 = ({
  theme_standard_date_filter_2,
  initial,
  onChange,
}) => {
  const customClass = cx({
    theme_standard_date_filter_2: theme_standard_date_filter_2,
  });
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    if (initial) {
      setSelected(initial);
    }
  }, [initial]);

  const handleClickTab = (key) => {
    setSelected(key);
    onChange && onChange(key);
  };

  return (
    <BtnDateFillter2Styled>
      <div className={customClass}>
        <div className="top_opt">
          <div
            key={1}
            className={`opt1 ${selected === 'daily' ? 'active' : ''}`}
            onClick={() => handleClickTab('daily')}
          >
            วัน
          </div>
          <div
            key={2}
            className={`opt2 ${selected === 'weekly' ? 'active' : ''}`}
            onClick={() => handleClickTab('weekly')}
          >
            สัปดาห์
          </div>
          <div
            key={3}
            className={`opt4 ${selected === 'monthly' ? 'active' : ''}`}
            onClick={() => handleClickTab('monthly')}
          >
            เดือน
          </div>
          {/* <div
            key={4}
            className={`opt4 ${selected === 4 ? 'active' : ''}`}
            onClick={() => handleClickTab(4)}
          >
            ปี
          </div> */}
        </div>
        <div className="bottom_opt">
          <div
            key={1}
            className={`label1 ${selected === 'daily' ? 'active' : ''}`}
          >
            Day
          </div>
          <div
            key={2}
            className={`label2 ${selected === 'weekly' ? 'active' : ''}`}
          >
            Week
          </div>
          <div
            key={3}
            className={`label2 ${selected === 'monthly' ? 'active' : ''}`}
          >
            Month
          </div>
          {/* <div key={4} className={`label2 ${selected === 4 ? 'active' : ''}`}>
            Year
          </div> */}
        </div>
      </div>
    </BtnDateFillter2Styled>
  );
};

// BtnDateFillter2.propTypes = {};

export default BtnDateFillter2;

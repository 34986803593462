export default function Star() {
  return (
    <svg id="medal" xmlns="http://www.w3.org/2000/svg" width="25.366" height="30.01" viewBox="0 0 25.366 30.01">
      <path id="Path_11724" data-name="Path 11724" d="M46.146,221.248,39.592,233.6l5.951-.55,2.88,5.237,6.554-12.347Z" transform="translate(-39.592 -208.274)" fill="#707070" />
      <g id="Group_3324" data-name="Group 3324" transform="translate(5.414 12.974)">
        <path id="Path_11725" data-name="Path 11725" d="M133.81,266.863a.837.837,0,0,0-.393-1.071l-.377-.2a.806.806,0,0,1,.776-1.414l1.066.566a.673.673,0,0,0,.912-.283l.026-.051a.636.636,0,0,0-.245-.9l-3.188-1.692a.839.839,0,0,1-.4-1.072.809.809,0,0,1,1.111-.372l2.957,1.57a.68.68,0,0,0,.917-.281l.017-.043,0-.009a.708.708,0,0,0-.292-.964l-1.659-.88a.839.839,0,0,1-.4-1.077.812.812,0,0,1,1.111-.372l1.832.973a.684.684,0,0,0,.929-.315l0-.009,0-.009a.683.683,0,0,0-.26-.946l-1.977-1.049a.837.837,0,0,1-.393-1.07.809.809,0,0,1,1.111-.372l4.877,2.589-6.554,12.347-1.806-3.283A.768.768,0,0,0,133.81,266.863Z" transform="translate(-131.92 -253.424)" fill="#848484" />
        <path id="Path_11726" data-name="Path 11726" d="M218.632,221.248l6.554,12.347-5.951-.55-2.88,5.237L209.8,225.935Z" transform="translate(-205.233 -221.248)" fill="#707070" />
      </g>
      <path id="Path_11727" data-name="Path 11727" d="M217.9,266.878a.837.837,0,0,1,.393-1.071l.377-.2a.806.806,0,0,0-.776-1.414l-1.066.566a.673.673,0,0,1-.912-.283l-.026-.051a.636.636,0,0,1,.245-.9l3.189-1.693a.839.839,0,0,0,.4-1.072.809.809,0,0,0-1.111-.372l-2.957,1.57a.68.68,0,0,1-.917-.281l-.017-.043,0-.009a.708.708,0,0,1,.292-.964l1.659-.88a.839.839,0,0,0,.395-1.077.812.812,0,0,0-1.111-.372l-1.834.975a.684.684,0,0,1-.929-.315l0-.009,0-.009a.683.683,0,0,1,.26-.946l1.977-1.049a.837.837,0,0,0,.393-1.07.809.809,0,0,0-1.111-.372l-4.877,2.589,6.554,12.347,1.806-3.283A.781.781,0,0,1,217.9,266.878Z" transform="translate(-199.834 -240.464)" fill="#848484" />
      <circle id="Ellipse_1497" data-name="Ellipse 1497" cx="10.95" cy="10.95" r="10.95" transform="translate(1.733 0)" fill="#ffcc5b" />
      <path id="Path_11728" data-name="Path 11728" d="M82.092,22.888H75.152a.986.986,0,0,1-.982-.982l.008-.068v-.015A1.026,1.026,0,0,1,75.2,20.788h4.809a1.217,1.217,0,0,0,1.238-1.11,1.176,1.176,0,0,0-1.17-1.23H74.987a.992.992,0,0,1-.975-1.035v-.03a.992.992,0,0,1,.975-1.035h5.331a1.214,1.214,0,0,0,1.23-1.1,1.172,1.172,0,0,0-1.17-1.23h-3.9a10.949,10.949,0,0,0,0,20.254h4.957a1.214,1.214,0,0,0,1.23-1.1,1.171,1.171,0,0,0-1.17-1.229H75.853a1.167,1.167,0,0,1-1.165-1.23,1.212,1.212,0,0,1,1.231-1.1h2.186a1.168,1.168,0,0,0-.034-2.336H74.757a.974.974,0,0,1-.975-.982V26.2a.923.923,0,0,1,.922-.982h7.319a1.216,1.216,0,0,0,1.238-1.1A1.172,1.172,0,0,0,82.092,22.888Z" transform="translate(-67.923 -13.194)" fill="#fdbc4b" />
      <path id="Path_11729" data-name="Path 11729" d="M130.4,69.072a7.816,7.816,0,1,1,7.817-7.816A7.826,7.826,0,0,1,130.4,69.072Z" transform="translate(-117.717 -50.306)" fill="#ffdb70" />
      <path id="Path_11730" data-name="Path 11730" d="M236.782,61.618a7.82,7.82,0,0,0-7.368-7.794h-.728a.926.926,0,0,0-.938.841.894.894,0,0,0,.892.938h4.3a.889.889,0,0,1,.889.938.924.924,0,0,1-.939.843h-1.667a.891.891,0,0,0,.025,1.782h2.528a.743.743,0,0,1,.743.749v.062a.7.7,0,0,1-.7.749h-5.582a.928.928,0,0,0-.944.841.894.894,0,0,0,.892.938h5.292a.751.751,0,0,1,.749.749l-.006.052v.011a.782.782,0,0,1-.783.789h-3.669a.928.928,0,0,0-.944.846.9.9,0,0,0,.892.938H233.6a.756.756,0,0,1,.743.789V66.7a.756.756,0,0,1-.743.789h-4.065a.926.926,0,0,0-.938.841.894.894,0,0,0,.892.938h1.072A7.829,7.829,0,0,0,236.782,61.618Z" transform="translate(-216.282 -50.668)" fill="#fff" opacity="0.2" />
      <path id="Path_11731" data-name="Path 11731" d="M165.62,93.576h-3.8l-1.176-3.616a.5.5,0,0,0-.96,0l-1.175,3.616h-3.8a.5.5,0,0,0-.3.913l3.076,2.235-1.175,3.616a.5.5,0,0,0,.777.564l3.076-2.235,3.076,2.235a.5.5,0,0,0,.777-.564l-1.175-3.616,3.076-2.235A.5.5,0,0,0,165.62,93.576Z" transform="translate(-147.48 -84.357)" fill="#ec9922" />
    </svg>
  );
}

import { useState, useEffect } from "react";
import { BMITrendStyled } from "./styled";
import { Blocks, Icons, Buttons, Typographys, Skeleton } from "components";
import Chart from "react-apexcharts";
import moment from "moment";
import { RENDER_MONTH, RENDER_DAYS } from "utils/functions/date";
import theme from "styles/theme.json";

const BMITrend = ({ data, fetchData }) => {
  const [_data, _setData] = useState();
  const [_selectedDayData, _setSelectedDayData] = useState();
  const [_filter, _setFilter] = useState("weekly");
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_dataLoaded, _setDataLoaded] = useState();
  const [_isShorLeftArrow, _setIsShorLeftArrow] = useState(false);
  const [_isShorRightArrow, _setIsShorRightArrow] = useState(false);
  const [_page, _setPage] = useState({
    per_page: 8,
    offset: 0,
    current_page: 1,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      _setData(data);
      _setDataLoaded(data);
      let tempDisplay = []
      Object.keys(data[data.length - 1]).forEach((e, i) => {
        if (String(e) !== 'stat_date') {
          tempDisplay.push(renderColor(e, data[data.length - 1][e]))
        }
      }
      )

      _setSelectedDayData(tempDisplay);
    }
  }, [data]);

  const renderColor = (key, value) => {
    switch (key) {
      case "male_over":
        return {
          color: "#6FB5CB",
          label: "เกินมาตรฐาน - ชาย",
          labelBottom: "Overweight - Men",
          percent: `${value.percent}%`,
        };
      case "female_over":
        return {
          color: "#182777",
          label: "เกินมาตรฐาน - หญิง",
          labelBottom: "Overweight - Women",
          percent: `${value.percent}%`,
        };
      // case 'male_obese':
      //   return {
      //     color: '#ffe000',
      //     label: 'อ้วน - ชาย',
      //     labelBottom: 'Obese weight - Men',
      //     percent: `${value.percent}%`,
      //   };
      // case 'female_obese':
      //   return {
      //     color: '#b39e00',
      //     label: 'อ้วน - หญิง',
      //     labelBottom: 'Obese weight - Women',
      //     percent: `${value.percent}%`,
      //   };
      case "male_normal":
        return {
          color: "#8AC47F",
          label: "สมส่วน - ชาย",
          labelBottom: "Normal weight - Men",
          percent: `${value.percent}%`,
        };
      case "female_normal":
        return {
          color: "#FDD600",
          label: "สมส่วน - หญิง",
          labelBottom: "Normal weight - Women",
          percent: `${value.percent}%`,
        };
      case "male_under":
        return {
          color: "#654C96",
          label: "ต่ำกว่าเกณฑ์ - ชาย",
          labelBottom: "Underweight - Men",
          percent: `${value.percent}%`,
        };

      case "female_under":
        return {
          color: "#9B68FD",
          label: "ต่ำกว่าเกณฑ์ - หญิง",
          labelBottom: "Underweight - Women",
          percent: `${value.percent}%`,
        };
      default:
        break;
    }
  };

  const handleClickFilter = async (value) => {
    _setFilter(value);
    _setLoadSkeleton(true);
    let res = await fetchData(`?period=${value}&offset=0&per_page=8`);
    if (res && res.length > 0) {
      _setData(res);
      _setDataLoaded(res);
      // _setLoadSkeleton(false);
      let tempDisplay = []
      Object.keys(res[res.length - 1]).forEach((e, i) => {
        if (String(e) !== 'stat_date') {
          tempDisplay.push(renderColor(e, res[res.length - 1][e]))
        }
      }
      )

      _setSelectedDayData(tempDisplay);
      resetPagination();
    } else {
      _setLoadSkeleton(false);
    }
  };

  const handleClickItemGraph = (index) => {
    if (index > -1) {
      let tempDisplay = []
      Object.keys(_data[0]).forEach((e, i) => {
        if (String(e) !== 'stat_date') {
          tempDisplay.push(renderColor(e, _data[index][e]))
        }
      })

      _setSelectedDayData(tempDisplay);
    }
  };

  const renderLabelX = (key, value) => {
    switch (key) {
      case "daily":
        return RENDER_DAYS(moment(value).format("d")).enShort;
      case "weekly":
        let renderMonth =
          moment(value).startOf("isoWeek").format("MM") ===
            moment(value).endOf("isoWeek").format("MM")
            ? RENDER_MONTH(moment(value).startOf("isoWeek").format("MM")).enShort
            : `${RENDER_MONTH(moment(value).startOf("isoWeek").format("MM"))
              .enShort
            } ${RENDER_MONTH(moment(value).endOf("isoWeek").format("MM")).enShort
            }`;

        return [
          `${moment(value).startOf("isoWeek").format("DD")}-${moment(value)
            .endOf("isoWeek")
            .format("DD")}`,
          renderMonth,
        ];
      default:
        return RENDER_MONTH(moment(value).format("MM")).enShort;
    }
  };

  const _fetchDataApi = async (
    filterDay = "weekly",
    offset = 0,
    perPage = 8
  ) => {
    // _setLoadSkeleton(true);
    let res = await fetchData(
      `?period=${filterDay}&offset=${offset}&per_page=${perPage}`
    );
    if (res && res.length > 0) {
      _setLoadSkeleton(false);
      return res;
    } else {
      _setLoadSkeleton(false);
      return res;
    }
  };

  // Manage Enable/Disable Left/Right Arrow
  // PAGINATION GRAPH FLOW
  //============================================
  //============================================
  useEffect(() => {
    const checkCondition = async () => {
      if (_page.current_page === 1) {
        _setIsShorRightArrow(false);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      } else {
        _setIsShorRightArrow(true);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0 || res.length < _page.per_page) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      }
    };
    if (_page) {
      checkCondition();
    }
  }, [_page]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClickLeftArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    //check has dataloadmore
    if (_page.current_page * _page.per_page < _dataLoaded.length) {
      handleCalDataPage("next");
    } else {
      let res = await _fetchDataApi(
        _filter,
        _page.current_page * _page.per_page,
        _page.per_page
      );
      if (res) {
        _setDataLoaded([...res, ..._dataLoaded]);
        _setData(res);
        _setPage({ ..._page, current_page: _page.current_page + 1 });
      }
    }
  };
  const handleClickRightArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    handleCalDataPage("back");
  };
  const handleCalDataPage = (key) => {
    let dataTemps = [..._dataLoaded];
    switch (key) {
      case "back":
        _setData(
          dataTemps
            .reverse()
            .slice(
              _page.current_page - 1 === 1
                ? 0
                : (_page.current_page - 1) * Number(_page.per_page) -
                _page.per_page,
              (_page.current_page - 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page - 1 });
        break;
      case "next":
        _setData(
          dataTemps
            .reverse()
            .slice(
              (_page.current_page + 1) * Number(_page.per_page) -
              _page.per_page,
              (_page.current_page + 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page + 1 });
        break;
      default:
        break;
    }
  };
  const resetPagination = () => {
    _setPage({ per_page: 8, offset: 0, current_page: 1 });
  };
  const handleDisabledArrow = () => {
    _setIsShorLeftArrow(false);
    _setIsShorRightArrow(false);
  };

  return (
    <BMITrendStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="21px"
        paddingRight="25.4px"
        paddingBottom="32px"
        paddingLeft="25.4px"
        height={_data ? "470px" : ""}
      >
        <div className="bmi_trend_head_container">
          <div className="bmi_trend_head_left_col">
            <div className="head_icon">
              <Icons.Scales />
            </div>
            <Typographys.TopBottom
              theme_left_align
              label="ความเปลี่ยนแปลง"
              labelBottom="BMI Trend"
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="bmi_trend_head_right_col">
            {/* <Buttons.BtnDateFillter
              theme_standard_date_filter
              initial={_filter}
              onSelected={handleClickFilter}
            /> */}
            <Buttons.BtnDateFillter3
              theme_standard_date_filter
              onSelected={handleClickFilter}
            />
          </div>
        </div>
        <div className="box_graph">
          <div className="bmi_trend_graph">
            <div className="show_graph">
              {_data ? (
                <>
                  {_loadSkeleton ? (
                    <div className="show_skeloton">
                      <Skeleton.GraphSkeleton height="250px;" />
                    </div>
                  ) : (
                    <>
                      <div className="arrow_left">
                        {_isShorLeftArrow && (
                          <div onClick={handleClickLeftArrow}>
                            <Icons.MoveChartLeft />
                          </div>
                        )}
                      </div>
                      <div className="arrow_right">
                        {_isShorRightArrow && (
                          <div onClick={handleClickRightArrow}>
                            <Icons.MoveChartRight />
                          </div>
                        )}
                      </div>
                      <div className="y_title">Percent</div>
                      <Chart
                        type="line"
                        height="400px"
                        options={{
                          chart: {
                            id: "apexchart-example",
                            fontFamily: theme.FONT.STYLE.REGULAR,
                            width: "100%",
                            toolbar: {
                              show: false,
                            },
                            events: {
                               click: function (event, chartContext, config) {
                                handleClickItemGraph(config.dataPointIndex);
                              },
                              // markerClick: function (
                              //   event,
                              //   chartContext,
                              //   { seriesIndex, dataPointIndex, config }
                              // ) {
                              //   handleClickItemGraph(dataPointIndex);
                              // },
                            },
                          },
                          legend: {
                            show: false,
                          },
                          xaxis: {
                            categories: _data
                              ? _data.map((e) => {
                                return renderLabelX(_filter, e.stat_date);
                              })
                              : [],
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                          },
                          yaxis: {
                            max: 100,
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (
                                value,
                                { series, seriesIndex, dataPointIndex, w }
                              ) {
                                return `${value}%`;
                              },
                            },
                          },
                          colors: [
                            "#6FB5CB",
                            "#182777",
                            // '#ffe000',
                            // '#b39e00',
                            "#8AC47F",
                            "#FDD600",
                            "#654C96",
                            "#9B68FD",
                          ],
                          markers: {
                            size: 4,
                          },
                          stroke: {
                            curve: "smooth",
                            width: 3,
                          },
                        }}
                        series={[
                          {
                            name: "เกินมาตรฐาน - ชาย",
                            data: _data && [
                              ..._data.map((e) => e.male_over.percent),
                            ],
                          },
                          {
                            name: "เกินมาตรฐาน - หญิง",
                            data: _data && [
                              ..._data.map((e) => e.female_over.percent),
                            ],
                          },
                          // {
                          //   name: 'อ้วน - ชาย',
                          //   data: _data && [
                          //     ..._data.map((e) => e.male_obese.percent),
                          //   ],
                          // },
                          // {
                          //   name: 'อ้วน - หญิง',
                          //   data: _data && [
                          //     ..._data.map((e) => e.female_obese.percent),
                          //   ],
                          // },
                          {
                            name: "สมส่วน - ชาย",
                            data: _data && [
                              ..._data.map((e) => e.male_normal.percent),
                            ],
                          },
                          {
                            name: "สมส่วน - หญิง",
                            data: _data && [
                              ..._data.map((e) => e.female_normal.percent),
                            ],
                          },
                          {
                            name: "ต่ำกว่าเกณฑ์ - ชาย",
                            data: _data && [
                              ..._data.map((e) => e.male_under.percent),
                            ],
                          },
                          {
                            name: "ต่ำกว่าเกณฑ์ - หญิง",
                            data: _data && [
                              ..._data.map((e) => e.female_under.percent),
                            ],
                          },
                        ]}
                      />
                    </>
                  )}
                </>
              ) : (
                <div className="label_empty">ยังไม่มีข้อมูล</div>
              )}
            </div>
          </div>
          <div className="label_group_show">
            {_selectedDayData && (
              <div className="show_group_label">
                <Typographys.LabelGraph3
                  theme_standard_label_graph
                  data={_selectedDayData}
                />
              </div>
            )}
          </div>
        </div>
      </Blocks.Box>
    </BMITrendStyled>
  );
};

export default BMITrend;

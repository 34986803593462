import { MissionRewardStyled } from './styled';
import { Blocks, Label, Icons } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const MissionReward = ({ dataMission, heightBox }) => {
  return (
    <MissionRewardStyled
      heightBox={heightBox}
    >
      <Blocks.Box
        theme_standard_box
        paddingTop="22px"
        paddingRight="16px"
        paddingBottom="0px"
        paddingLeft="16px"
        height={heightBox}
      >
        <div className="container_box">
          <div className="top_current">
            <div className="show_icon">
              <Icons.Reward />
            </div>
            <Label.TopBottom
              theme_left_align
              label="ของรางวัล"
              labelBottom="Reward"
              fontSizeLabelButtom="14px"
              colorBottom="##B1B1C1"
            />
          </div>
          <div className="bottom_current">
            <div className="group_icon" style={{ marginBottom: '20px' }}>
              <div className="left_icon">
                <Icons.Reward2 />
              </div>
              <div className="right_icon">
                <div className="show_label">
                  {dataMission &&
                    `${RenderCommaMoney(dataMission.success_coin_amount.toFixed(0))} แต้ม`}
                </div>
              </div>
            </div>
            {/* <div className="group_icon" style={{ marginBottom: '20px' }}>
              <div className="left_icon">
                <Icons.Reward3 />
              </div>
              <div className="right_icon">
                <div className="show_label orange">
                  {dataMission &&
                    `${RenderCommaMoney(dataMission.success_exp_amount)} XP`}
                </div>
              </div>
            </div> */}
            <div className="group_icon">
              <div className="left_icon">
                {dataMission && dataMission.success_avatar_item && (
                  <img
                    style={{ width: '55px' }}
                    src={dataMission.success_avatar_item.icon_uri}
                    alt="img"
                  />
                )}
              </div>
              <div className="right_icon">
                <div className="show_label black">
                  {dataMission &&
                    dataMission.success_avatar_item &&
                    dataMission.success_avatar_item.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MissionRewardStyled>
  );
};

export default MissionReward;

import styled from "styled-components";

export const HeadTabStyled = styled.div`
  width: 100%;
  .theme_standard_head_tab {
    display: flex;
    .box1 {
      display: flex;
      align-items: flex-end;
      padding-top: 30px;
      width: 33.33%;
      .btn_link {
        width: 20%;
      }
    }
    .box2 {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      .show_svg {
        display: flex;
        margin-right: ${(props) =>
          props.margin_right ? props.margin_right : "3px"};
      }
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: ${(props) =>
          props.lineHeight ? props.lineHeight : "unset"};
        .top_title {
          text-align: center;
          font-size: ${(props) =>
            props.fontSize
              ? props.fontSize
              : ({ theme }) => theme.FONT.SIZE.S32};
          font-family: ${(props) =>
            props.fontFamilyTop ? props.fontFamilyTop : "noto_sans_thai_bold"};
          color: ${(props) =>
            props.fontColor
              ? props.fontColor
              : ({ theme }) => theme.COLORS.PURPLE_1};
        }
        .bottom_title {
          font-family: ${(props) =>
            props.fontFamilyBottom
              ? props.fontFamilyBottom
              : "noto_sans_thai_medium, noto_sans_regular"};
          font-size: ${(props) =>
            props.fontSizeLabelButtom
              ? props.fontSizeLabelButtom
              : ({ theme }) => theme.FONT.SIZE.S18};
          color: ${(props) =>
            props.colorBottom
              ? props.colorBottom
              : ({ theme }) => theme.COLORS.GRAY_1};
          text-align: center;
        }
      }
    }
    .date_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .date_wrap {
        margin-left: 14px;
      }
    }
    .btn_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .btn_body {
        width: 50%;
      }
      .btn_body_small {
        min-width: 88px;
        &.end {
          padding-left: 10px;
        }
      }
    }
    .blank {
      width: 33.33%;
    }
  }
  .theme_only_title {
    display: flex;
    .box1 {
      display: none;
    }
    .box2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .show_svg {
        display: flex;
        margin-right: ${(props) =>
          props.margin_right ? props.margin_right : "3px"};
      }
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: ${(props) =>
          props.lineHeight ? props.lineHeight : "unset"};
        .top_title {
          text-align: center;
          font-size: ${(props) =>
            props.fontSize
              ? props.fontSize
              : ({ theme }) => theme.FONT.SIZE.S32};
          font-family: ${(props) =>
            props.fontFamilyTop
              ? props.fontFamilyTop
              : "noto_sans_thai_bold, noto_sans_bold"};
          color: ${(props) =>
            props.fontColor
              ? props.fontColor
              : ({ theme }) => theme.COLORS.PURPLE_1};
        }
        .bottom_title {
          font-family: ${(props) =>
            props.fontFamilyBottom
              ? props.fontFamilyBottom
              : "noto_sans_thai_medium, noto_sans_regular"};
          font-size: ${(props) =>
            props.fontSizeLabelButtom
              ? props.fontSizeLabelButtom
              : ({ theme }) => theme.FONT.SIZE.S18};
          color: ${(props) =>
            props.colorBottom
              ? props.colorBottom
              : ({ theme }) => theme.COLORS.GRAY_1};
          text-align: center;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard_head_tab {
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_standard_head_tab {
      .box2 {
        .group_label {
          .top_title {
            font-size: ${({ theme }) => theme.FONT.SIZE.S30};
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const NewestRewardStyled = styled.div`
  .theme_newest_reward {
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    .line1 {
      display: flex;
      justify-content: space-between;
      .lb_1 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .group_icon {
        display: flex;
        align-items: center;
        font-family: noto_sans_thai_bold, noto_sans_bold;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GOLD_1};
        .show_svg {
          display: flex;
          margin-right: 6px;
        }
      }
    }
    .light_font {
      margin-top: 14px;
      font-family: noto_sans_thai_regular, noto_sans_regular;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      &.red {
        margin-top: 8px;
        color: ${({ theme }) => theme.COLORS.RED_3};
      }
      &.h_54 {
        height: 54px;
      }
    }
    .date_show {
      margin-top: 8px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
`;

import styled from "styled-components";

export const LoginFormStyled = styled.form`
  .form_container {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 80px 60px 25px 60px;
    width: 420px;
    .logo_icon {
      margin-bottom: 20px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      display: flex;
      align-items: center;
      justify-content: center;
      .show_svg {
        margin-left: 7px;
        display: flex;
      }
    }
    .box_input {
      .group_input {
        margin-bottom: 32px;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
    .forgot_password {
      text-align: right;
      text-decoration: underline;
      margin-top: 16px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      cursor: pointer;
    }
    .btn_layout {
      margin-top: 32px;
    }
    .box_logo {
      display: flex;
      padding-top: 25px;
      justify-content: center;
      margin-left: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .form_container {
      padding: 40px 30px 20px 30px;
    }
  }
`;

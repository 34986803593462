import { ROUTE_PATH } from "utils/constants";
import { Icons } from "components";
import MainDashboardContainer from "containers/DDCSystem/MainDashboardContainer";
import CompanyContainer from "containers/DDCSystem/CompanyContainer";
import BackOfficeContainer from "containers/DDCSystem/BackOfficeContainer";
import AccountManagementContainer from "containers/DDCSystem/BackOfficeContainer/Menus/AccountManagementContainer";
import CreateAccountContainer from "containers/DDCSystem/BackOfficeContainer/Menus/AccountManagementContainer/Menus/CreateAccountContainer";

const adminRoutes = [
  {
    key: "administrator",
    path: ROUTE_PATH.ADMINISTARTOR,
    name: "Main",
    exact: true,
    notShowSidebar: true,
    component: MainDashboardContainer,
  },
  {
    key: "main",
    path: ROUTE_PATH.MAIN,
    name: "Main",
    exact: true,
    icon: (active) => <Icons.Main color={active ? "#002366" : "#ffffff"} />,
    component: MainDashboardContainer,
  },
  {
    key: "company",
    path: ROUTE_PATH.COMPANY,
    name: "company",
    exact: true,
    icon: (active) => <Icons.Company active={active ? true : false} />,
    component: CompanyContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE,
    name: "Back office",
    exact: true,
    icon: (active) => <Icons.Setting2 color={active ? "#575D96" : "#ffffff"} />,
    component: BackOfficeContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_ACCOUNT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    icon: (active) => <Icons.MenuBackOffice isActive={active} />,
    component: AccountManagementContainer,
  },

  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_CREATE_EDIT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: CreateAccountContainer,
  },
];

export default adminRoutes;

export default function Dumbel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="Group_33093" data-name="Group 33093" transform="translate(-0.121 0.385)">
        <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
          <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(0.121 -0.385)" fill="#fedb41" stroke="#e3a52f" strokeWidth="2">
            <circle cx="11" cy="11" r="11" stroke="none" />
            <circle cx="11" cy="11" r="10" fill="none" />
          </g>
          <g id="gym" transform="translate(4.222 6.925)">
            <g id="Group_180" data-name="Group 180" transform="translate(12.49 1.104)">
              <g id="Group_179" data-name="Group 179" transform="translate(0 0)">
                <path id="Path_286" data-name="Path 286" d="M480.534,131.4V138.1a1.1,1.1,0,0,0,.818-1.062v-4.57A1.1,1.1,0,0,0,480.534,131.4Z" transform="translate(-480.534 -131.405)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_182" data-name="Group 182" transform="translate(9.216 0.217)">
              <g id="Group_181" data-name="Group 181" transform="translate(0 0)">
                <path id="Path_287" data-name="Path 287" d="M355.91,97.3h-.258a1.1,1.1,0,0,0-1.1,1.1v6.269a1.1,1.1,0,0,0,1.1,1.1h.258a1.1,1.1,0,0,0,1.1-1.1V98.4A1.1,1.1,0,0,0,355.91,97.3Z" transform="translate(-354.553 -97.301)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_184" data-name="Group 184" transform="translate(4.911 3.09)">
              <g id="Group_183" data-name="Group 183">
                <rect id="Rectangle_1366" data-name="Rectangle 1366" width="3.486" height="2.682" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_186" data-name="Group 186" transform="translate(0 0.886)">
              <g id="Group_185" data-name="Group 185" transform="translate(0 0)">
                <path id="Path_288" data-name="Path 288" d="M0,124.106v4.569a1.1,1.1,0,0,0,.818,1.062v-6.694A1.1,1.1,0,0,0,0,124.106Z" transform="translate(0 -123.044)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_188" data-name="Group 188" transform="translate(1.637 0)">
              <g id="Group_187" data-name="Group 187">
                <path id="Path_289" data-name="Path 289" d="M64.329,88.942h-.258a1.1,1.1,0,0,0-1.1,1.1V96.31a1.1,1.1,0,0,0,1.1,1.1h.258a1.1,1.1,0,0,0,1.1-1.1V90.041A1.1,1.1,0,0,0,64.329,88.942Z" transform="translate(-62.972 -88.942)" fill="#e3a52f" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

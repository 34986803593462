export default function EmotionVerySmile({ width = '80', height = '76.308' }) {
  return (
    <svg id="_004-happy" data-name="004-happy" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 80 76.308">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="0.254" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#ffd767" />
          <stop offset="1" stop-color="#ffba39" />
        </linearGradient>
      </defs>
      <ellipse id="Ellipse_2099" data-name="Ellipse 2099" cx="40" cy="38.154" rx="40" ry="38.154" fill="url(#linear-gradient)" />
      <g id="Group_20084" data-name="Group 20084" transform="translate(22.5 16.577)">
        <ellipse id="Ellipse_2100" data-name="Ellipse 2100" cx="5" cy="5" rx="5" ry="5" />
        <ellipse id="Ellipse_2101" data-name="Ellipse 2101" cx="5" cy="5" rx="5" ry="5" transform="translate(25)" />
      </g>
      <path id="Path_34215" data-name="Path 34215" d="M64,224v5a30,30,0,1,0,60,0v-5Z" transform="translate(-54 -192.692)" fill="#d32f2f" />
      <path id="Path_34216" data-name="Path 34216" d="M64,224v5a29.988,29.988,0,0,0,.45,5h59.1a29.989,29.989,0,0,0,.45-5v-5Z" transform="translate(-54 -190.846)" fill="#fafafa" />
    </svg>

  );
}

import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputCalendarStyled } from "./styled";
import { Blocks, Icons } from "components";
import DatePicker from "react-date-picker";
import theme from "styles/theme.json";

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

const InputCalendar = ({
  theme_standard_calendar,
  onChangeCustom,
  initialValue,
  minDate,
  maxDate,
}) => {
  const customClass = cx({
    theme_standard_calendar: theme_standard_calendar,
  });

  const [_value, _setValue] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    if (initialValue) {
      _setValue(initialValue);
    }
    if (initialValue === null) {
      _setValue(initialValue);
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePicker = (e) => {
    _setValue(e);
    setIsComponentVisible(false);
    onChangeCustom && onChangeCustom(e);
  };

  const handleClickFilter = () => {
    setIsComponentVisible(true);
  };

  return (
    <InputCalendarStyled>
      <div className={customClass} ref={ref}>
        <div className="icon_filter" onClick={handleClickFilter}>
          <Icons.Control
            color={_value ? theme.COLORS.BLACK_1 : theme.COLORS.BLACK_1}
          />
        </div>
        <div
          className="filter_block22"
          style={{ visibility: isComponentVisible ? "visible" : "hidden" }}
        >
          <Blocks.Box theme_standard_box>
            <DatePicker
              value={_value}
              onChange={handleChangePicker}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Blocks.Box>
        </div>
      </div>
    </InputCalendarStyled>
  );
};

InputCalendar.propTypes = {};

export default InputCalendar;

export default function WomenBad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35.431" height="40.665" viewBox="0 0 35.431 40.665">
      <g id="Group_15118" data-name="Group 15118" transform="translate(0 0)">
        <g id="Group_407" data-name="Group 407" transform="translate(0 0)">
          <path id="Path_240" data-name="Path 240" d="M542.272,152.137c-.279-1.789-.543-3.578-.838-5.365-.515-3.127-.954-6.214-4.317-8.609a19.136,19.136,0,0,0-10.859-3.169,21.711,21.711,0,0,0-2.868.189c-4.344.579-8.2,2.493-10.3,5.136a9.923,9.923,0,0,0-1.807,5.162q-.437,2.839-.884,5.678l-1.754,11.229c-1.109,7.1,7.117,13.286,17.652,13.271h0c10.506-.015,18.684-6.189,17.578-13.271Z" transform="translate(-508.541 -134.993)" fill="#010101" />
        </g>
        <g id="Group_449" data-name="Group 449" transform="translate(1.07 3.917)">
          <g id="Group_442" data-name="Group 442">
            <g id="Group_415" data-name="Group 415" transform="translate(26.67 13.776)">
              <g id="Group_414" data-name="Group 414">
                <g id="Group_408" data-name="Group 408">
                  <circle id="Ellipse_9" data-name="Ellipse 9" cx="3.423" cy="3.423" r="3.423" fill="#ffdac3" />
                </g>
                <g id="Group_413" data-name="Group 413" transform="translate(2.144 2.342)">
                  <g id="Group_410" data-name="Group 410">
                    <g id="Group_409" data-name="Group 409">
                      <path id="Path_241" data-name="Path 241" d="M543.513,159.73a1.229,1.229,0,0,1,1.823-.929c.35.186.661-.345.311-.531a1.844,1.844,0,0,0-2.727,1.3c-.066.388.527.554.593.164Z" transform="translate(-542.915 -158.037)" fill="#010101" />
                    </g>
                  </g>
                  <g id="Group_412" data-name="Group 412" transform="translate(0.015 1.13)">
                    <g id="Group_411" data-name="Group 411">
                      <path id="Path_242" data-name="Path 242" d="M543.384,160.145a1.062,1.062,0,0,1,1.261.033c.322.232.629-.3.31-.531a1.653,1.653,0,0,0-1.881-.033c-.329.216-.022.75.31.531Z" transform="translate(-542.933 -159.337)" fill="#010101" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_423" data-name="Group 423" transform="translate(0 13.776)">
              <g id="Group_422" data-name="Group 422">
                <g id="Group_416" data-name="Group 416">
                  <circle id="Ellipse_10" data-name="Ellipse 10" cx="3.423" cy="3.423" r="3.423" fill="#ffdac3" />
                </g>
                <g id="Group_421" data-name="Group 421" transform="translate(1.815 2.341)">
                  <g id="Group_418" data-name="Group 418">
                    <g id="Group_417" data-name="Group 417">
                      <path id="Path_243" data-name="Path 243" d="M514.74,159.565a1.843,1.843,0,0,0-2.726-1.3c-.35.186-.04.717.31.531a1.229,1.229,0,0,1,1.823.929c.066.389.659.224.593-.164Z" transform="translate(-511.86 -158.037)" fill="#010101" />
                    </g>
                  </g>
                  <g id="Group_420" data-name="Group 420" transform="translate(0.714 1.13)">
                    <g id="Group_419" data-name="Group 419">
                      <path id="Path_244" data-name="Path 244" d="M514.7,159.614a1.653,1.653,0,0,0-1.881.033c-.318.229-.011.763.31.531a1.062,1.062,0,0,1,1.261-.033c.332.218.641-.314.31-.531Z" transform="translate(-512.681 -159.337)" fill="#010101" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_424" data-name="Group 424" transform="translate(4.801)">
              <path id="Path_245" data-name="Path 245" d="M527.271,170.16h0a11.977,11.977,0,0,0,11.977-11.977v-6.707A11.977,11.977,0,0,0,527.271,139.5h0a11.977,11.977,0,0,0-11.977,11.978v6.707A11.976,11.976,0,0,0,527.271,170.16Z" transform="translate(-515.294 -139.498)" fill="#ffdac3" />
            </g>
            <g id="Group_441" data-name="Group 441" transform="translate(9.689 15.727)">
              <g id="Group_428" data-name="Group 428" transform="translate(5.893 1.824)">
                <g id="Group_427" data-name="Group 427">
                  <path id="Path_248" data-name="Path 248" d="M528.836,155.257q.133,1.308.264,2.618c.05.489.513,3.661-1.006,2.708-.3-.185-.568.283-.273.468a1.227,1.227,0,0,0,1.857-.76,5.341,5.341,0,0,0-.016-2.214l-.284-2.819c-.035-.343-.576-.348-.542,0Z" transform="translate(-527.695 -154.998)" fill="#010101" />
                </g>
              </g>
              <g id="Group_440" data-name="Group 440" transform="translate(0 0)">
                <g id="Group_437" data-name="Group 437">
                  <path id="Path_255" data-name="Path 255" d="M521.362,154.275a.445.445,0,0,1-.1-.879l2.089-.483a.445.445,0,1,1,.2.868l-2.088.483A.437.437,0,0,1,521.362,154.275Z" transform="translate(-520.917 -152.9)" fill="#010101" />
                </g>
                <g id="Group_438" data-name="Group 438" transform="translate(11.27)">
                  <path id="Path_256" data-name="Path 256" d="M536.413,154.275a.436.436,0,0,1-.1-.011l-2.089-.483a.445.445,0,0,1,.2-.868l2.088.483a.445.445,0,0,1-.1.879Z" transform="translate(-533.88 -152.9)" fill="#010101" />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_445" data-name="Group 445" transform="translate(1.931 19.509)">
            <g id="Group_444" data-name="Group 444">
              <path id="Path_259" data-name="Path 259" d="M514.1,162.993a1.055,1.055,0,1,1-1.055-1.055A1.056,1.056,0,0,1,514.1,162.993Z" transform="translate(-511.993 -161.938)" fill="#d8f01d" />
            </g>
          </g>
          <g id="Group_448" data-name="Group 448" transform="translate(29.45 19.509)">
            <g id="Group_447" data-name="Group 447">
              <path id="Path_261" data-name="Path 261" d="M545.759,162.993a1.056,1.056,0,1,1-1.056-1.055A1.056,1.056,0,0,1,545.759,162.993Z" transform="translate(-543.647 -161.938)" fill="#d8f01d" />
            </g>
          </g>
        </g>
        <g id="Group_451" data-name="Group 451" transform="translate(4.42 1.174)">
          <path id="Path_262" data-name="Path 262" d="M517.851,149.486h-4.225s3.417-13.143,14.177-13.143,12.131,13.143,12.131,13.143H530.49l-.64-2.129-.8,2.129h-1.618l-1.694-4.006-1.69,4.006h-6.194Z" transform="translate(-513.626 -136.343)" fill="#010101" />
        </g>
        <g id="Group_788" data-name="Group 788" transform="translate(11.434 23.667)">
          <g id="Group_482" data-name="Group 482" transform="translate(0 0)">
            <path id="Path_279" data-name="Path 279" d="M583.821,158.337l-.008-.268c-.022-.718-1.142-.722-1.12,0l.009.268c.021.719,1.141.722,1.119,0Z" transform="translate(-582.693 -157.529)" fill="#010101" />
          </g>
        </g>
        <g id="Group_789" data-name="Group 789" transform="translate(22.736 23.667)">
          <g id="Group_482-2" data-name="Group 482" transform="translate(0 0)">
            <path id="Path_279-2" data-name="Path 279" d="M583.821,158.337l-.008-.268c-.022-.718-1.142-.722-1.12,0l.009.268c.021.719,1.141.722,1.119,0Z" transform="translate(-582.693 -157.529)" fill="#010101" />
          </g>
        </g>
      </g>
    </svg>
  );
}

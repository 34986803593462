export const getObjectKey = (obj, value) => {
  let findData = obj.find((e) => e.key === value);

  return findData;
};

export const getObjectKey2 = (obj, value, value2) => {
  let findData = obj.find((e) => e.key === value || e.key === value2);

  return findData;
};

export const getObjectKey3 = (obj, value, value2, value3) => {
  let findData = obj.find(
    (e) => e.key === value || e.key === value2 || e.key === value3
  );

  return findData;
};

export default function Alcohol() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42.866" height="54.465" viewBox="0 0 42.866 54.465">
      <g id="Group_15112" data-name="Group 15112" transform="translate(-685.134 -2008)">
        <g id="Group_1" data-name="Group 1" transform="translate(646.92 1732.775)" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
          <path id="Path_1" data-name="Path 1" d="M70.266,303.088l.76-.43c.2-.113.381-.207.58-.306l0-.019c-.236-.087-.441-.175-.653-.263l-.847-.376-.064-.558,1.929.915,1.825-1.058.068.588-.8.442c-.2.119-.4.22-.617.327v.011c.237.087.45.182.687.281l.894.385.066.571-2.034-.953-1.729,1.019Z" fill="#e4e5e8" />
          <path id="Path_2" data-name="Path 2" d="M69.95,300.366l.761-.429c.2-.114.381-.208.58-.307l0-.019c-.236-.088-.441-.175-.653-.263l-.847-.375-.064-.56,1.928.916,1.826-1.058.068.588-.8.443c-.2.118-.4.218-.617.326v.011c.237.088.45.181.687.28l.894.386.066.572-2.034-.954-1.729,1.019Z" fill="#e4e5e8" />
          <path id="Path_3" data-name="Path 3" d="M69.636,297.645l.76-.43c.2-.113.381-.206.58-.308l0-.017c-.236-.087-.441-.175-.653-.263l-.847-.376-.064-.559,1.929.915,1.825-1.058.068.588-.8.444c-.2.118-.4.219-.617.325v.012c.237.087.451.182.688.28l.893.385.066.571-2.034-.953L69.7,298.222Z" fill="#e4e5e8" />
        </g>
        <g id="Group_7" data-name="Group 7" transform="translate(646.92 1732.775)">
          <path id="Path_6" data-name="Path 6" d="M39.418,323.186l-1.2-4.846,11.075-.2L63.72,321.06,62.574,325.8l-6.33,3.655-5.56.231Z" fill="#e7c38f" />
          <path id="Path_7" data-name="Path 7" d="M41.355,322.59l-.58-3.467,6.374-3.069,4.713-.2L61.91,321l-1.2,3.658-5.366,3.1-4.713.2Z" fill="#d16a00" />
          <path id="Path_8" data-name="Path 8" d="M39.782,319.908l-.683-4.083,7.5-3.614,5.55-.23,11.832,6.05-1.408,4.307-6.318,3.648-5.55.23Z" fill="#e7c38f" />
          <path id="Path_9" data-name="Path 9" d="M63.413,316.592l-11.62-6.709-5.768.239L39.5,313.89l-.414,3.33L50.7,323.93l5.768-.239L63,319.922Z" fill="#c47914" />
          <path id="Path_10" data-name="Path 10" d="M38.214,318.341l.563-4.53,7.07-4.082,6.21-.258,12.227,7.059-.563,4.53-7.07,4.082-6.21.258Z" fill="#f49d00" />
          <path id="Path_11" data-name="Path 11" d="M63.413,316.592l-11.62-6.709-5.768.239L39.5,313.89l-.414,3.33L50.7,323.93l5.768-.239L63,319.922Z" fill="#c47914" />
          <path id="Path_12" data-name="Path 12" d="M63.413,296.224,63,299.554l-6.527,3.769-5.768.239-11.62-6.709.414-3.33,6.527-3.769,5.768-.239Z" fill="#f49d00" />
          <path id="Path_13" data-name="Path 13" d="M40.322,295.12l.249-2,5.785-3.34,5.15-.214,10.669,6.159-.249,2-5.785,3.34-5.15.213Z" fill="#ffb851" />
          <path id="Path_14" data-name="Path 14" d="M39.083,296.853v20.368L50.7,323.93V303.562Z" fill="#c6650d" />
          <path id="Path_15" data-name="Path 15" d="M39.083,299.652v14.77L50.7,321.13v-14.77Z" fill="#e7b86a" />
          <g id="Group_3" data-name="Group 3">
            <path id="Path_16" data-name="Path 16" d="M49.3,311.047l-1.115,1.208,1.17,2.6-.66-.381-.476-1.127c-.123-.3-.239-.567-.348-.857l-.013-.008a4.969,4.969,0,0,1-.354.452l-.483.574-.681-.394,1.157-1.285-1.123-2.477.647.374.463,1.072c.108.265.217.524.326.813l.021.012c.108-.147.211-.284.333-.425l.47-.541Z" />
            <path id="Path_17" data-name="Path 17" d="M46.145,309.227l-1.115,1.208,1.17,2.605-.66-.381-.477-1.127c-.122-.3-.238-.567-.347-.856l-.013-.008a5.26,5.26,0,0,1-.354.452l-.483.572-.68-.392,1.156-1.285-1.123-2.477.647.373.463,1.072c.108.266.217.524.326.814l.02.012c.11-.149.212-.285.334-.425l.47-.541Z" />
            <path id="Path_18" data-name="Path 18" d="M42.993,307.407l-1.116,1.207,1.17,2.606-.66-.381-.476-1.127c-.123-.3-.239-.567-.348-.856l-.013-.008a5.219,5.219,0,0,1-.354.452l-.483.572-.681-.392,1.157-1.286-1.123-2.476.647.373.463,1.072c.108.266.217.524.326.814l.021.011c.109-.148.211-.284.333-.424l.47-.541Z" />
          </g>
          <path id="Path_19" data-name="Path 19" d="M63,299.554v20.368l-6.527,3.768V303.323Z" fill="#f4b842" />
          <path id="Path_20" data-name="Path 20" d="M56.472,303.323V323.69l-5.768.239V303.562Z" fill="#d68100" />
          <g id="Group_4" data-name="Group 4">
            <path id="Path_21" data-name="Path 21" d="M58.23,293l-1.566-.726c-.127-.087-.26-.172-.4-.254a10.421,10.421,0,0,0-9.43-.018c-.152.088-.293.179-.427.272L44.893,293v1.733a3.352,3.352,0,0,0,1.967,2.714,10.424,10.424,0,0,0,9.431.017,3.277,3.277,0,0,0,1.938-2.731h0Z" fill="#c6650d" />
            <path id="Path_22" data-name="Path 22" d="M56.292,295.734a10.427,10.427,0,0,1-9.431-.017c-2.612-1.509-2.625-3.946-.029-5.445a10.422,10.422,0,0,1,9.43.017C58.875,291.8,58.888,294.235,56.292,295.734Z" fill="#ffcc63" />
          </g>
          <g id="Group_5" data-name="Group 5">
            <path id="Path_23" data-name="Path 23" d="M55.974,284.591l-1.037-3.03,0,0a6.732,6.732,0,0,0-6.791,0v0l-1,3.03-.22.7a11.79,11.79,0,0,0,.22,7.671h0v-.1a2.221,2.221,0,0,0,1.3,1.8,6.9,6.9,0,0,0,6.246.011,2.169,2.169,0,0,0,1.283-1.808h0a12.533,12.533,0,0,0,.033-8.185Z" fill="#e7c38f" />
          </g>
          <path id="Path_24" data-name="Path 24" d="M46.806,288.309s.674,5.077,1.233,6.086c1.027,1.026,2.851,1.059,3.9,1.064,2.464.011,3.148-1.045,3.148-1.045a18.033,18.033,0,0,0,1.385-6.105A25.059,25.059,0,0,0,46.806,288.309Z" fill="#d68100" />
          <path id="Path_25" data-name="Path 25" d="M55.084,282.6c-.761-1.961-6.476-1.672-7.17.489-1.034,3.219-1.925,6.038.053,7.18a7.893,7.893,0,0,0,7.14.013C57.072,289.148,56.1,285.234,55.084,282.6Z" fill="#e7c38f" />
          <path id="Path_26" data-name="Path 26" d="M55.023,286.311a7.675,7.675,0,0,0-6.944-.013c-1.911,1.1-1.9,2.9.022,4.009a7.673,7.673,0,0,0,6.943.012C56.956,289.216,56.946,287.421,55.023,286.311Z" fill="#f49d00" />
          <g id="Group_6" data-name="Group 6">
            <path id="Path_27" data-name="Path 27" d="M55.893,277.725l-1.017-.471c-.083-.057-.169-.112-.261-.165a6.766,6.766,0,0,0-6.125-.011c-.1.056-.19.115-.277.176l-.982.471v4.03a2.177,2.177,0,0,0,1.278,1.762,6.766,6.766,0,0,0,6.125.011,2.128,2.128,0,0,0,1.258-1.773h0Z" fill="#3d3d41" />
            <path id="Path_28" data-name="Path 28" d="M54.634,279.5a6.772,6.772,0,0,1-6.125-.011c-1.7-.98-1.7-2.563-.019-3.536a6.766,6.766,0,0,1,6.125.011C56.311,276.942,56.32,278.526,54.634,279.5Z" fill="#9d9d9f" />
            <path id="Path_29" data-name="Path 29" d="M54.32,279.224a6.305,6.305,0,0,1-5.7-.01c-1.58-.913-1.588-2.387-.018-3.294a6.305,6.305,0,0,1,5.7.01C55.883,276.842,55.891,278.317,54.32,279.224Z" fill="#656568" />
          </g>
          <path id="Path_30" data-name="Path 30" d="M56.472,306.241v14.77l-5.768.12v-14.77Z" fill="#f4deba" />
          <path id="Path_31" data-name="Path 31" d="M63,302.832V317.6l-6.527,3.529v-14.77Z" fill="#f7e6cb" />
          <path id="Path_32" data-name="Path 32" d="M46.592,286.617l.532.546A2.039,2.039,0,0,1,49,286.208c1.708.224,2.15,1.8,2.521,2.005a4.073,4.073,0,0,0,2.419.108c.575-.072,1.514-.2,1.514-.2a12.019,12.019,0,0,1-.094,3.01l-.269.99s-1.21,1.053-4.383.6-4.167-2.023-4.167-2.023A7.905,7.905,0,0,1,46.592,286.617Z" fill="#f7e6cb" />
        </g>
        <g id="Group_8" data-name="Group 8" transform="translate(646.92 1732.775)">
          <path id="Path_33" data-name="Path 33" d="M49.3,311.047l-1.115,1.208,1.17,2.6-.66-.381-.476-1.127c-.123-.3-.239-.567-.348-.857l-.013-.008a4.969,4.969,0,0,1-.354.452l-.483.574-.681-.394,1.157-1.285-1.123-2.477.647.374.463,1.072c.108.265.217.524.326.813l.021.012c.108-.147.211-.284.333-.425l.47-.541Z" />
          <path id="Path_34" data-name="Path 34" d="M46.145,309.227l-1.115,1.208,1.17,2.605-.66-.381-.477-1.127c-.122-.3-.238-.567-.347-.856l-.013-.008a5.26,5.26,0,0,1-.354.452l-.483.572-.68-.392,1.156-1.285-1.123-2.477.647.373.463,1.072c.108.266.217.524.326.814l.02.012c.11-.149.212-.285.334-.425l.47-.541Z" />
          <path id="Path_35" data-name="Path 35" d="M42.993,307.407l-1.116,1.207,1.17,2.606-.66-.381-.476-1.127c-.123-.3-.239-.567-.348-.856l-.013-.008a5.219,5.219,0,0,1-.354.452l-.483.572-.681-.392,1.157-1.286-1.123-2.476.647.373.463,1.072c.108.266.217.524.326.814l.021.011c.109-.148.211-.284.333-.424l.47-.541Z" />
        </g>
        <g id="Group_13" data-name="Group 13" transform="translate(646.92 1729.775)">
          <g id="Group_12" data-name="Group 12">
            <g id="Group_10" data-name="Group 10">
              <path id="Path_36" data-name="Path 36" d="M78.521,324.74a10.814,10.814,0,0,0-9.785-.018c-2.694,1.555-2.68,4.084.03,5.649a10.814,10.814,0,0,0,9.785.018C81.245,328.834,81.231,326.3,78.521,324.74Z" fill="#005c0c" />
              <path id="Path_37" data-name="Path 37" d="M78.918,329.507a11.622,11.622,0,0,1-10.516-.019c-2.913-1.682-2.928-4.4-.033-6.071a11.619,11.619,0,0,1,10.516.019C81.8,325.117,81.813,327.836,78.918,329.507Z" fill="#256900" />
              <rect id="Rectangle_2" data-name="Rectangle 2" width="14.872" height="24.775" transform="translate(66.208 301.687)" fill="#256900" />
              <path id="Path_38" data-name="Path 38" d="M78.918,304.868a11.626,11.626,0,0,1-10.516-.019c-2.913-1.682-2.928-4.4-.033-6.071a11.622,11.622,0,0,1,10.516.018C81.8,300.478,81.813,303.2,78.918,304.868Z" fill="#2f8a0c" />
              <path id="Path_39" data-name="Path 39" d="M78.052,297.6a9.776,9.776,0,0,0-8.845-.016c-2.434,1.406-2.422,3.692.028,5.106a9.771,9.771,0,0,0,8.844.016C80.514,301.3,80.5,299.01,78.052,297.6Z" fill="#3d9c1e" />
              <path id="Path_40" data-name="Path 40" d="M76.768,296.428a6.929,6.929,0,0,0-6.268-.011c-1.726,1-1.717,2.616.019,3.619a6.929,6.929,0,0,0,6.268.011C78.513,299.051,78.5,297.431,76.768,296.428Z" fill="#256900" />
              <path id="Path_41" data-name="Path 41" d="M76.817,286.476H70.47s-.288,4.472-1.258,11.756h8.863C77.105,290.948,76.817,286.476,76.817,286.476Z" fill="#256900" />
              <path id="Path_42" data-name="Path 42" d="M76.193,289.26a2.4,2.4,0,0,0,.792-.7c-.068-.77-.114-1.34-.139-1.685a2.431,2.431,0,0,0-.454-.4H70.867a2.389,2.389,0,0,0-.424.376c-.025.339-.07.908-.139,1.686a2.418,2.418,0,0,0,.806.712A5.62,5.62,0,0,0,76.193,289.26Z" fill="#005c0c" />
              <path id="Path_43" data-name="Path 43" d="M75.881,285.185a4.959,4.959,0,0,0-4.489-.008c-1.235.713-1.229,1.873.014,2.591a4.959,4.959,0,0,0,4.489.008C77.13,287.063,77.124,285.9,75.881,285.185Z" fill="#3d9c1e" />
              <g id="Group_9" data-name="Group 9">
                <path id="Path_44" data-name="Path 44" d="M77.238,287.063a11.331,11.331,0,0,0-.421-2.9c-.469-1.517-6.347,0-6.347,0a8.947,8.947,0,0,0-.421,2.9h0v.007h0a1.805,1.805,0,0,0,1.06,1.462,5.62,5.62,0,0,0,5.083.01,1.768,1.768,0,0,0,1.044-1.472Z" fill="#2f8a0c" />
                <path id="Path_45" data-name="Path 45" d="M75.881,282.932a4.965,4.965,0,0,0-4.489-.008c-1.235.714-1.229,1.874.014,2.592a4.962,4.962,0,0,0,4.489.008C77.13,284.811,77.124,283.65,75.881,282.932Z" fill="#d7ba48" />
                <path id="Path_46" data-name="Path 46" d="M75.03,283.424a3.075,3.075,0,0,0-2.782-.005.848.848,0,0,0,.009,1.606,3.075,3.075,0,0,0,2.782.005A.848.848,0,0,0,75.03,283.424Z" fill="#cc8114" />
              </g>
            </g>
            <g id="Group_11" data-name="Group 11">
              <path id="Path_47" data-name="Path 47" d="M74.251,286.826a.785.785,0,0,1,.33-.281.852.852,0,0,1,.776.186.753.753,0,0,0,.671.121c.419-.172.22-.79.786-.679.386.075.761.044.781-.435a6.659,6.659,0,0,0-.177-1.146,11.7,11.7,0,0,1-.032-1.5l-.879-.407c-.071-.049-.146-.1-.225-.143a5.849,5.849,0,0,0-5.293-.009c-.085.049-.165.1-.24.152l-.848.407a4.355,4.355,0,0,0-.011.766c-.009.284-.023.568-.044.852-.017.228-.082.426-.118.64a.3.3,0,0,0,.279.373c.2.008.4-.158.574-.065a.532.532,0,0,1,.187.285,1.347,1.347,0,0,0,.48.584.44.44,0,0,0,.24.092.5.5,0,0,0,.178-.046c.434-.173.9-.371,1.271.02.109.114.179.279.319.36a.96.96,0,0,0,.714.08A.581.581,0,0,0,74.251,286.826Z" fill="#bd2020" />
              <path id="Path_48" data-name="Path 48" d="M76.3,284.626a5.849,5.849,0,0,1-5.293-.01c-1.466-.846-1.473-2.214-.016-3.055a5.849,5.849,0,0,1,5.293.009C77.748,282.417,77.755,283.785,76.3,284.626Z" fill="#ff8383" />
              <path id="Path_49" data-name="Path 49" d="M76.027,284.388a5.445,5.445,0,0,1-4.93-.009c-1.366-.788-1.373-2.063-.015-2.846a5.445,5.445,0,0,1,4.93.009C77.377,282.33,77.384,283.605,76.027,284.388Z" fill="#ff3838" />
              <path id="Path_50" data-name="Path 50" d="M74.829,283.7a2.809,2.809,0,0,1-2.541,0,.774.774,0,0,1-.008-1.467,2.806,2.806,0,0,1,2.541.005A.774.774,0,0,1,74.829,283.7Z" fill="#e7b86a" />
            </g>
            <path id="Path_51" data-name="Path 51" d="M70.247,293.108l-.575,4.908a6.067,6.067,0,0,0-2.976,5.307v23.51s1.455,3.193,6.947,3.193,6.919-3.564,6.919-3.564v-23.04a6.256,6.256,0,0,0-3.053-5.62c-.311-2.571-.469-4.688-.469-4.688Z" fill="#ffa600" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
            <path id="Path_52" data-name="Path 52" d="M67.1,307.283v16.258a5.467,5.467,0,0,0,1.3,1.006,11.81,11.81,0,0,0,9.324.577V308.866c-2.5.605-2.46-1.768-6.024-2.031C69.011,306.636,69.5,308.562,67.1,307.283Z" fill="#e7b86a" />
          </g>
          <path id="Path_53" data-name="Path 53" d="M76.038,291.732a5.312,5.312,0,0,0-4.8-.009,1.672,1.672,0,0,0-.987,1.385,1.7,1.7,0,0,0,1,1.389,5.312,5.312,0,0,0,4.8.009C77.375,293.742,77.369,292.5,76.038,291.732Z" fill="#4a6012" />
        </g>
        <path id="Path_54" data-name="Path 54" d="M63,315.789V299.554l.414-3.33-2.343-1.353c-.151,1.656-.413,4.2-.836,7.444-.026.014-.057.024-.083.039A3.138,3.138,0,0,0,58.7,303.9a2.828,2.828,0,0,0-1.144,2.022h-.008v21.534h0a2.333,2.333,0,0,0,.292,1.082l4.737-2.736,1.146-4.744.563-4.53Z" transform="translate(646.92 1732.775)" fill="#a3a6b0" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
      </g>
    </svg>

  );
}

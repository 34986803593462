import React, { useEffect, useState } from 'react';
import { DropdownLoadmoreStyled } from './styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import cx from 'classnames';

import { Icons } from 'components';

const DropdownLoadmore = ({
  theme_standard,
  value,
  onChange,
  onChangeCustom,
  placeholder,
  endPoint,
  errors,
  isInitial,
  epParameter,
  showOnTop,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_options, _setOptions] = useState({
    page: { offset: 0, per_page: 10 },
    data: [],
    hasMore: true,
  });
  const [_active, _setActive] = useState(false);
  const [_value, _setValue] = useState(placeholder ? placeholder : 'หมวดหมู่');

  useEffect(() => {
    _fetchData();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchData = async (
    stringQuery = `?per_page=${_options.page.per_page}&offset=${_options.page.offset}`,
    isLoadMore
  ) => {
    if (!isLoadMore) {
      if (!_options || _options.data.length === 0) {
        let res = await endPoint(epParameter, stringQuery);
        if (res && res.success) {
          _setOptions({
            data: res.data.map((e) => ({ label: e.name, value: e.id, data: e })),
            page: {
              offset: res.page.from - 1,
              per_page: Number(res.page.per_page),
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
          if (value && value !== _value) {
            let find = res.data.find((e) => e.id === value.value);
            _setValue(find ? find.name : value);
            if (!isInitial) {
              onChange(find ? { label: find.name, value: find.id } : value);
            }
          }
        }
      } else {
        if (value && value !== _value) {
          let find = _options.data.find((e) => e.value === value.value);
          _setValue(find ? find.label : value);
        }
      }
    } else {
      let res = await endPoint(epParameter, stringQuery);
      if (res && res.success) {
        if (!res.data || res.data.length === 0) {
          _setOptions({
            ..._options,
            hasMore: false,
          });
        } else {
          _setOptions({
            ..._options,
            data: [
              ..._options.data,
              ...res.data.map((e) => ({ label: e.name, value: e.id, data: e })),
            ],
            page: {
              offset: res.page.from - 1,
              per_page: res.page.per_page,
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
        }
      }
    }
  };

  const _fetchMoreData = () => {
    _fetchData(
      `?per_page=${_options.page.per_page}&offset=${_options.page.offset + _options.page.per_page
      }`,
      true
    );
  };

  const _handleClick = () => {
    if (!disabled) {
      _setActive(!_active);
    }
  };

  const handleSelect = (e) => {
    _setValue(e.label);
    _setActive(false);
    onChange(e);
    onChangeCustom && onChangeCustom(e)
  };

  return (
    <DropdownLoadmoreStyled
      disabled={disabled}
      showOnTop={showOnTop}
    >
      <div className={customClass}>
        <div className='group_box_body'>
          <div
            className="body_dropdown"
            onClick={_handleClick}
          >
            <div className="label_show">{value ? value.label : _value}</div>
            <div className="d_arrow">
              {_active ? (
                <span className="arrow_icon arrow-closed">
                  <Icons.RectArrowUp
                    color={disabled ? "#949494" : "#002366"}
                    width="24"
                    height="24" />
                </span>
              ) : (
                <span className="arrow_icon arrow-open">
                  <Icons.RectArrowDown
                    color={disabled ? "#949494" : "#002366"}
                    width="24"
                    height="24" />
                </span>
              )}
            </div>
          </div>
          {_active && (
            <div className="list_dropdown">
              <InfiniteScroll
                dataLength={_options.data.length}
                next={_fetchMoreData}
                hasMore={_options.hasMore}
                loader={<h3 className="loading">Loading...</h3>}
                height={220}
                endMessage={
                  <p className="loading">{/* <b>ดูครบทั้งหมดแล้ว</b> */}</p>
                }
              >
                {_options &&
                  _options.data.length > 0 &&
                  _options.data.map((e, i) => (
                    <div
                      key={i + 1}
                      className="dropdown_item"
                      onClick={() => handleSelect(e)}
                    >
                      {e.label}
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
        {errors && <div className="error">{`* ${errors}`}</div>}
      </div>
    </DropdownLoadmoreStyled>
  );
};

export default DropdownLoadmore;

export default function Depressed({ width = '48', height = '47.96' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 47.96">
      <g id="_003-crying" data-name="003-crying" transform="translate(0.239 0)">
        <ellipse id="Ellipse_243" data-name="Ellipse 243" cx="24" cy="23.98" rx="24" ry="23.98" transform="translate(-0.239 0)" fill="#ffe17d" />
        <path id="Path_2778" data-name="Path 2778" d="M27.474,96.462A23.961,23.961,0,0,1,8.86,57.376,23.976,23.976,0,1,0,42.605,91.09,23.9,23.9,0,0,1,27.474,96.462Z" transform="translate(-0.22 -51.981)" fill="#ffd164" />
        <g id="Group_1129" data-name="Group 1129" transform="translate(9.647 15.288)">
          <path id="Path_2779" data-name="Path 2779" d="M106.256,168.131a.968.968,0,0,1-.433-1.833l6.191-3.1a.967.967,0,1,1,.865,1.731l-6.191,3.1A.965.965,0,0,1,106.256,168.131Z" transform="translate(-105.288 -163.1)" fill="#aa7346" />
          <path id="Path_2780" data-name="Path 2780" d="M327.155,168.119a.96.96,0,0,1-.432-.1l-6.191-3.1a.967.967,0,1,1,.865-1.731l6.191,3.1a.968.968,0,0,1-.433,1.833Z" transform="translate(-299.875 -163.088)" fill="#aa7346" />
        </g>
        <path id="Path_2781" data-name="Path 2781" d="M218.521,307.381h0a3.869,3.869,0,0,1-3.87-3.87v-2.322a3.869,3.869,0,0,1,3.87-3.87h0a3.869,3.869,0,0,1,3.869,3.87v2.322A3.87,3.87,0,0,1,218.521,307.381Z" transform="translate(-194.755 -269.468)" fill="#9c6846" />
        <path id="Path_2782" data-name="Path 2782" d="M215.486,300.413h7.582a3.871,3.871,0,0,0-3.791-3.093h0A3.871,3.871,0,0,0,215.486,300.413Z" transform="translate(-195.511 -269.464)" fill="#fff" />
        <path id="Path_2783" data-name="Path 2783" d="M214.651,331.1v1.545h7.739V331.1a3.885,3.885,0,0,0-.078-.777h-7.583A3.879,3.879,0,0,0,214.651,331.1Z" transform="translate(-194.755 -299.375)" fill="#7d5046" />
        <path id="Path_2784" data-name="Path 2784" d="M103.941,247.059c-.491,2.92-1.862,2.98-2.224,6.318-.387,3.569,1.151,3.744.764,7.313-.359,3.307-1.7,3.408-2.207,6.243a23.925,23.925,0,0,0,6.867,3.676c.127-3.474,1.535-3.653,1.535-7.453,0-3.979-1.548-3.979-1.548-7.958s1.548-3.977,1.548-7.953Z" transform="translate(-91.096 -223.928)" fill="#a5cdff" />
        <path id="Path_2785" data-name="Path 2785" d="M125.839,232.647a4.954,4.954,0,0,1-3.849-1.909.967.967,0,1,1,1.507-1.213,2.9,2.9,0,0,0,4.684,0,.968.968,0,0,1,1.509,1.212A4.953,4.953,0,0,1,125.839,232.647Z" transform="translate(-110.583 -207.69)" fill="#aa7346" />
        <path id="Path_2786" data-name="Path 2786" d="M108.253,412.247a24.026,24.026,0,0,1-6.043-4.013c-.535,1.95-1.52,2.433-1.936,4.77h0a23.922,23.922,0,0,0,6.867,3.675A12.566,12.566,0,0,1,108.253,412.247Z" transform="translate(-91.097 -369.998)" fill="#8cb4eb" />
        <path id="Path_2787" data-name="Path 2787" d="M329.025,253.378c-.362-3.339-1.733-3.4-2.224-6.318l-4.736.185c0,3.977,1.548,3.977,1.548,7.953s-1.548,3.979-1.548,7.959c0,3.8,1.408,3.98,1.535,7.453a23.926,23.926,0,0,0,6.867-3.676c-.5-2.835-1.848-2.936-2.207-6.243C327.874,257.122,329.412,256.947,329.025,253.378Z" transform="translate(-292.102 -223.929)" fill="#a5cdff" />
        <path id="Path_2788" data-name="Path 2788" d="M334.424,452.058a23.926,23.926,0,0,0,6.867-3.675,7.74,7.74,0,0,0-.281-1.112,23.812,23.812,0,0,1-7,2.274A8.987,8.987,0,0,1,334.424,452.058Z" transform="translate(-302.926 -405.377)" fill="#8cb4eb" />
        <path id="Path_2789" data-name="Path 2789" d="M307.576,232.646a4.952,4.952,0,0,1-3.85-1.909.968.968,0,0,1,1.509-1.212,2.9,2.9,0,0,0,4.684,0,.967.967,0,1,1,1.507,1.212A4.952,4.952,0,0,1,307.576,232.646Z" transform="translate(-275.289 -207.689)" fill="#aa7346" />
      </g>
    </svg>

  );
}

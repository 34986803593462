import styled from "styled-components";

export const AddArticleFormStyled = styled.div`
  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.mb61 {
        margin-bottom: 61px;
      }
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S32};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
        .bw132 {
          width: 132px;
        }
        .bw140 {
          width: 140px;
        }
      }
    }
  }
  .layer_two {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    .right_layer_two {
      width: 58%;
      .right_text_rich {
        margin-top: 23px;
      }
      .more_photo {
        margin-top: 32px;
        .box_detail {
          width: 100%;
          .g_text {
            display: flex;
            align-items: center;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            margin-bottom: 5px;
            column-gap: 5px;
            .sub_g_text {
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              color: ${({ theme }) => theme.COLORS.GRAY_24};
            }
          }
          .g_max_group {
            display: flex;
            justify-content: space-between;
            column-gap: 5px;
            margin-bottom: 5px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
          }
          .sub_more_detail {
            display: flex;
            column-gap: 10px;
            margin-bottom: 24px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            .ic_more {
              display: flex;
            }
          }
        }
        .render_photo_box {
          display: flex;
        }
      }
    }
    .left_layer_two {
      width: 35%;
      .top_form {
        margin-bottom: 32px;
        width: 100%;
        .group_top_form {
          width: 100%;
          .line_label {
            display: flex;
            margin-bottom: 14px;
            .label_1 {
              margin-right: 18px;
              font-size: ${({ theme }) => theme.FONT.SIZE.S18};
              color: ${({ theme }) => theme.COLORS.GRAY_23};
              font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            }
            .label_2 {
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              color: ${({ theme }) => theme.COLORS.GRAY_24};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              padding-top: 4px;
            }
          }
        }
      }
      .mid_form {
        .line_mid {
          display: flex;
          flex-direction: column;
          .mid_left {
            width: 100%;
            // margin-right: 24px;
            .group_mid_form {
              margin-bottom: 23px;
              &.no_margin {
                margin-bottom: 0px;
              }
              &.mb10 {
                margin-bottom: 10px;
              }
              .line_label {
                display: flex;
                margin-bottom: 14px;
                .label_1 {
                  margin-right: 18px;
                  font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                  color: ${({ theme }) => theme.COLORS.GRAY_23};
                  font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                }
                .label_2 {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
                  color: ${({ theme }) => theme.COLORS.GRAY_24};
                  font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
                  padding-top: 4px;
                }
              }
            }
          }
          .mid_right {
            // width: 100%;
            margin-bottom: 16px;
            .group_mid_form_right {
              &.no_margin {
                margin-bottom: 0px;
              }
              &.mb10 {
                margin-bottom: 10px;
              }
              .line_label {
                display: flex;
                margin-bottom: 14px;
                .label_1 {
                  margin-right: 18px;
                  font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                  color: ${({ theme }) => theme.COLORS.GRAY_23};
                  font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                }
                .label_2 {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
                  color: ${({ theme }) => theme.COLORS.GRAY_24};
                  font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
                  padding-top: 4px;
                }
              }
            }
          }
        }
        .show_file_name {
          margin-bottom: 42px;
        }
      }
      .bottom_form {
        display: flex;
        margin-bottom: 23px;
        .bottom_left {
          width: 100%;
          // margin-right: 24px;
          .group_bottom_form {
            margin-bottom: 23px;
            &.no_margin {
              margin-bottom: 0px;
            }
            &.mb10 {
              margin-bottom: 10px;
            }
            .line_label {
              display: flex;
              margin-bottom: 14px;
              .label_1 {
                margin-right: 18px;
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                color: ${({ theme }) => theme.COLORS.GRAY_23};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S14};
                color: ${({ theme }) => theme.COLORS.GRAY_24};
                font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
                padding-top: 4px;
              }
            }
          }
          .reward_opt {
            .show_reward_layer {
              .title_show {
                display: flex;
                margin-bottom: 17px;
                .show_icon {
                  margin-right: 4px;
                  display: flex;
                }
              }
              .box_input_bottom {
                margin-bottom: 24px;
                .left_box {
                }
                .right_box {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 16px;
                  .body_input {
                    width: 100%;
                  }
                  .label_b {
                    margin-left: 5px;
                    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                  }
                }
                .between_label {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                  font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
                }
                &.no_margin {
                  margin-bottom: 0px;
                }
              }
              &.left {
                padding-right: 24px;
              }
              &.right {
                padding-left: 24px;
              }
            }
          }
        }
        .bottom_right {
          width: 100%;
          .group_bottom_form_right {
            margin-bottom: 23px;
            &.no_margin {
              margin-bottom: 0px;
            }
            &.mb10 {
              margin-bottom: 10px;
            }
            .line_label {
              display: flex;
              margin-bottom: 14px;
              .label_1 {
                margin-right: 18px;
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                color: ${({ theme }) => theme.COLORS.GRAY_23};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S14};
                color: ${({ theme }) => theme.COLORS.GRAY_24};
                font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
                padding-top: 4px;
              }
            }
          }
        }
      }
      .opt_show {
        display: flex;
        .left_opt {
          width: 100%;
          margin-right: 24px;
          .group_opt {
            .line_label {
              line-height: 1.2;
              font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              margin-bottom: 19px;
              .label_1 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                color: ${({ theme }) => theme.COLORS.GRAY_23};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S14};
                color: ${({ theme }) => theme.COLORS.GRAY_24};
                font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              }
            }
          }
          .show_img {
            margin-top: 32px;
          }
        }
      }
      .zone_upload {
        padding-bottom: 88px;
        .header_zone {
          line-height: 1.2;
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          display: flex;
          margin-bottom: 23px;
          align-items: baseline;
          .label_1 {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          }
          .label_2 {
            margin-left: 14px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          }
        }
        .upload_layer {
        }
      }
    }
  }
`;

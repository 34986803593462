import { useState, useEffect } from "react";
import { AverageDepartmentWellbeingStyled } from "./styled";
import { Blocks, Typographys, Inputs, Skeleton } from "components";
import moment from "moment";

const AverageDepartmentWellbeing = ({ data, fetchData }) => {
  const [_data, _setData] = useState();
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);
    if (res) {
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };
  const handleChangDate = (e) => {
    _setLoadSkeleton(true);
    fetchDataApi(
      `?period=monthly&stat_date=${moment(e)
        .startOf("months")
        .format("YYYY-MM-DD")}`
    );
  };
  return (
    <AverageDepartmentWellbeingStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="20px"
        paddingRight="31px"
        paddingBottom="32px"
        paddingLeft="23px"
      >
        <div className="top_avg_dep">
          <div className="title_wrap">
            <Typographys.TopBottom
              theme_left_align
              label="สัดส่วนผู้ที่มีสุขภาวะแบบต่างๆ ในแต่ละแผนก"
              labelBottom="Percentage of employees who have Wellbeing condition by department"
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="group_date">
            <div className="box_date">
              <Inputs.InputDatePikerCustom
                theme_standard_date_picker_custom
                dateFormat="MMMM yyyy"
                onChangeCustom={(e) => handleChangDate(e)}
                showMonthYearPicker
                showFullMonthYearPicker
                initialValues={new Date()}
              />
            </div>
            {/* <div className="box_date">
              <Inputs.InputDropDownFilter
                iconSvgClose={<Icons.RectArrowDown />}
                iconSvgOpen={<Icons.RectArrowUp />}
                placeholder="2020"
              />
            </div>
            <div className="box_date">
              <Inputs.InputDropDownFilter
                iconSvgClose={<Icons.RectArrowDown />}
                iconSvgOpen={<Icons.RectArrowUp />}
                placeholder="November"
              />
            </div> */}
          </div>
          <div className="group_well">
            <div className="show_wellbeing">
              <Blocks.BoxWellbeing
                theme_average_box_well_being
                label="สุขภาวะทางจิตอยู่ในระดับดี"
                labelBottom="Good wellbeing"
                bgColor="#8AC47F"
                showRound
              />
            </div>
            <div className="show_wellbeing">
              <Blocks.BoxWellbeing
                theme_average_box_well_being
                label="สุขภาวะทางจิตอยู่ในระดับต่ำกว่าปกติ"
                labelBottom="Poor wellbeing"
                bgColor="#6FB5CB"
                showRound
              />
            </div>
            <Blocks.BoxWellbeing
              theme_average_box_well_being
              label="มีแนวโน้มของภาวะซึมเศร้า"
              labelBottom="Likely depressed state"
              bgColor="#654C96"
              showRound
            />
          </div>
        </div>
        <div className="bottom_avg_dep">
          {!_data || _data.length === 0 ? (
            <div className="label_empty">ยังไม่มีข้อมูล</div>
          ) : _loadSkeleton ? (
            <Skeleton.GraphSkeleton height="100px" />
          ) : (
            _data.map((d, h) => (
              <div key={h} className="chart_wrap">
                {d && (
                  <div className="c_row_2_col">
                    {d.map((e, i) => (
                      <div key={i} className="bar_item">
                        <div className="label">{e.name}</div>
                        <div className="percent_bar">
                          {e.data.map((e2, j) => (
                            <div
                              key={j}
                              className="stack_item"
                              style={{
                                width: `${e2.percent}%`,
                                backgroundColor: COLORS_GRAPH[j],
                              }}
                            >
                              {e2.percent !== 0 && `${e2.percent}%`}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Blocks.Box>
    </AverageDepartmentWellbeingStyled>
  );
};
const COLORS_GRAPH = ["#8AC47F", "#6FB5CB", "#654C96"];

export default AverageDepartmentWellbeing;

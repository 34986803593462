export const RENDER_MEMBER_IMPORT_ERROR = (key) => {
  switch (key) {
    case 'INVALID_DEPARTMENT_ID':
      return 'รหัสแผนกไม่ถูกต้อง';
    case 'ALREADY_EXIST_EMPLOYEE_CODE':
      return 'รหัสพนักงานนี้มีอยู่ในระบบแล้ว';
    case 'DUPLICATE_EMPLOYEE_CODE':
      return 'รหัสพนักงานซ้ำ';
    case 'INVALID_BIRTH_DATE':
      return 'วันเดือนปีเกิดไม่ถูกต้อง';
    default:
      break;
  }
};

import React from "react";
import { DepartmentManagementContainerStyled } from "./styled";
import {
  Tabs,
  Loading,
  Inputs,
  Buttons,
  Icons,
  TableCustom,
  Modal,
  BoxCofirm,
  BoxNotic,
  Blocks,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { getObjectKey2 } from "utils/functions/getObjectKey";

class DepartmentManagementContainer extends React.Component {
  state = { isLoading: true, isShowModal: false, dataSelectDelete: [] };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData(`?per_page=10&offset=0`);
    this.checkPermission();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0`);
      this.checkPermission();
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props;

    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "employee_manage",
      "employee_manage_no_delete"
    );

    this.setState({
      perMissions: checkPermission,
    });
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_DEPARTMENT_LIST(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      let arrayTemp = [{ label: "All", name: "all", value: "all" }];
      this.setState({
        isLoading: false,
        data: res.data,
        pagination: { ...res.page },
        deparmentList: [
          ...arrayTemp,
          ...res.data.map((e) => ({
            label: e.name,
            name: e.id,
            value: e.id,
          })),
        ],
      });
    } else {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };

  handlePopup = () => {
    const { selectedData, data } = this.state;
    let checkValue = [];
    let dataSelect = [];

    // เช็คว่ามีแผนกที่ลบมีพนักงานอยู่หรือไม่ ถ้ามีลบไม่ได้
    selectedData.forEach((e) => {
      let isFind = data.find(
        (j) => Number(e) === j.id && j.employees_count > 0
      );
      let dataTemp = data.find((j) => Number(e) === j.id);
      if (isFind) {
        checkValue.push(isFind);
      } else {
        dataSelect.push(dataTemp);
      }
    });

    this.setState({
      isShowModal: true,
      modalType: checkValue.length > 0 ? 2 : 1,
      dataSelectDelete: dataSelect,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickCreate = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_DEPARTMENT_DETAIL}/create`
    );
  };

  handleClickSort = (e) => {};

  handleClickDetail = (e) => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_DEPARTMENT_DETAIL}/${e.id}`
    );
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };
  handleConfirmDelete = () => {
    this.setState({
      isShowModal: false,
      isLoading: true,
    });
    const { selectedData } = this.state;

    if (selectedData && selectedData.length > 0) {
      Promise.all(
        selectedData.map((e) => {
          return new Promise(async (resolve) => {
            let res = await adminService.DELETE_DEPARTMENT(e);
            if (res && res.success) {
              resolve();
            }
          });
        })
      ).then(() => {
        toast.success("ลบสำเร็จ");
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.fetchData();
          }
        );
      });
    }
  };

  // FILTER PROCESS
  //==========================================
  //==========================================
  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `department_ids=${e
              .filter((e) => e !== "all")
              .join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "keywordFilter":
        this.setState(
          {
            keywordFilter: !e ? false : `keyword=${e}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleJoinQuery = () => {
    const { categoryFilter, keywordFilter } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchData();
      this.setState({
        queryFilterAll: false,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT}${query}`);
    this.fetchData(query);
  };

  render() {
    const {
      isLoading,
      isShowModal,
      pagination,
      data,
      deparmentList,
      selectedData,
      modalType,
      dataSelectDelete,
      perMissions,
    } = this.state;

    return (
      <DepartmentManagementContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="แผนกในบริษัท"
                  subTitle="Department"
                  fontSize="34px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#092945"
                  colorBottom="#9E9E9E"
                  lineHeight="1.3"
                />
              </div>
              <div className="sub_label">ค้นหาแผนกที่คุณต้องการ</div>
              <div className="layer_two mt4">
                <div className="search_layer">
                  <Inputs.InputSearch
                    theme_standard_search
                    onSubmit={(e) =>
                      this.handleSelectedFilter("keywordFilter", e)
                    }
                  />
                </div>
                {perMissions && (
                  <div className="btn_layer">
                    <div className="body_btn">
                      <Buttons.BtnNormal
                        theme_only_border
                        label="ลบ"
                        borderRadius="4px"
                        backgroundColor="#fff"
                        fontColor="#FF0000"
                        disabled={!(selectedData && selectedData.length > 0)}
                        svg={
                          <Icons.Delete3
                            color={
                              selectedData && selectedData.length > 0
                                ? "#f0211d"
                                : "#fff"
                            }
                            color2={
                              selectedData && selectedData.length > 0
                                ? "#fff"
                                : "#9E9E9E"
                            }
                          />
                        }
                        onClick={this.handlePopup}
                      />
                    </div>
                    <div className="min_132">
                      <Buttons.BtnNormal
                        theme_standard_btn_normal_red
                        label="สร้างแผนก"
                        backgroundColor="#21A526"
                        fontSize="16px"
                        borderRadius="4px"
                        svg_front={<Icons.Plus color="#FFFFFF" />}
                        onClick={this.handleClickCreate}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="layer_table">
                <TableCustom.TableAction
                  theme_standard_table_user
                  columns={columns({
                    deparmentList: deparmentList,
                    handleClickSort: this.handleClickSort,
                    handleClickDetail: this.handleClickDetail,
                    handleSelectedFilter: this.handleSelectedFilter,
                    perMissions: perMissions,
                  })}
                  data={data}
                  pagination={pagination}
                  isCheckbox={perMissions ? true : false}
                  useNo
                  showPagination
                  onChangeSelected={this.handleChangeSelectedTable}
                  handlePageClick={this.handlePageClick}
                />
              </div>
              <Modal
                theme_modal_standard
                isShow={isShowModal}
                handleClickCloseModal={this.handleClickCloseModal}
              >
                {modalType === 1 ? (
                  <BoxCofirm
                    theme_box_confirm
                    handleCancel={this.handleClickCloseModal}
                    handleConfirm={this.handleConfirmDelete}
                    textShow="ยืนยันการลบแผนกหรือไม่"
                    textBottom={`${
                      dataSelectDelete && dataSelectDelete.length > 1
                        ? "แผนกทั้งหมดที่เลือก"
                        : `แผนก${dataSelectDelete[0].name}`
                    }`}
                    labelConfirm="ลบ"
                  />
                ) : (
                  <BoxNotic
                    theme_box_notic
                    handleCancel={this.handleClickCloseModal}
                    textShow="ไม่สามารถลบแผนกได้"
                    textBottom="เนื่องจากยังมีคนอยู่ในแผนก"
                  />
                )}
              </Modal>
            </Blocks.Container>
          </>
        )}
      </DepartmentManagementContainerStyled>
    );
  }
}

const columns = ({
  deparmentList,
  handleClickSort,
  handleClickDetail,
  handleSelectedFilter,
  perMissions,
}) => {
  let tempColumn = [];

  if (perMissions) {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
      style: { textAlign: "center", width: "20px", flexShrink: 0 },
    });
  } else {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
      style: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
    });
  }

  tempColumn.push(
    {
      title: "ลำดับ",
      field: "no",
      headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
      style: { textAlign: "center", width: "50px", flexShrink: 0 },
    },
    {
      title: "รหัส",
      field: "id",
      headerStyle: { textAlign: "center", maxWidth: "100px" },
      style: { textAlign: "center", maxWidth: "100px" },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>
          {String(value).padStart(4, "0")}
        </div>
      ),
    },
    {
      // title: (
      //   <div
      //     style={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       cursor: 'pointer',
      //       justifyContent: 'center',
      //     }}
      //     onClick={() => handleClickSort('d1')}
      //   >
      //     <div>แผนก</div>
      //     <div style={{ marginLeft: '16px' }}>
      //       <Inputs.InputGroupCheckBox
      //         theme_standard_group_check_box_user
      //         iconColor='#171717'
      //         labelButton="SUBMIT"
      //         data={deparmentList}
      //         onChangeCustom={(e) => handleSelectedFilter('categoryFilter', e)}
      //         maxHeight='350px'
      //       />
      //     </div>
      //   </div>
      // ),
      title: "แผนก",
      field: "name",
      classStyle: "showDep",
      headerStyle: { textAlign: "center", minWidth: "100px" },
      style: { textAlign: "left", minWidth: "100px" },
      render: ({ origin, value }) => (
        <div
          className="depDetail"
          style={{
            minWidth: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
          data-tip
          data-for={`tooltipDep${origin.id}`}
        >
          {value}
          <ReactTooltip id={`tooltipDep${origin.id}`}>
            <span>{value}</span>
          </ReactTooltip>
        </div>
      ),
    },
    {
      title: "บริษัท",
      field: "company.name",
      classStyle: "showHead",
      headerStyle: { textAlign: "center", minWidth: "100px" },
      style: { textAlign: "left", minWidth: "100px" },
      render: ({ origin, value }) => (
        <div
          className="showName"
          style={{
            minWidth: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
          data-tip
          data-for={`tooltipCompany${origin.id}`}
        >
          <ReactTooltip id={`tooltipCompany${origin.id}`}>
            <span>{value}</span>
          </ReactTooltip>
          {value}
        </div>
      ),
    },
    {
      title: "จำนวนคนในแผนก",
      field: "employees_count",
      headerStyle: { textAlign: "center", maxWidth: "180px" },
      style: { textAlign: "center", color: "#575D96", maxWidth: "180px" },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>
          {`${RenderCommaMoney(value, true)}`}
        </div>
      ),
    }
  );
  return tempColumn;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentManagementContainer);

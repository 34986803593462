import styled from 'styled-components';

export const DwbRiskStyled = styled.div`
  height: 100%;
  .top_men {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 6px;
        display: flex;
      }
    }
    .right_men_layout {
      display: flex;
      justify-content: flex-end;
      .show_search {
      }
      .date_show {
        display: flex;
        .left_arrow {
          margin-right: 12px;
          min-width: 48px;
        }
        .group_date {
          display: flex;
          flex-direction: column;
          align-items: center;
          .group_date_top {
            display: flex;
            .show_icon {
              margin-right: 9px;
            }
            .date_layout {
              color: ${({ theme }) => theme.COLORS.BLACK_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              font-family: noto_sans_thai_medium, noto_sans_regular;
            }
          }
          .group_date_bottom {
            color: ${({ theme }) => theme.COLORS.GRAY_12};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
          }
        }
        .right_arrow {
          min-width: 48px;
          margin-left: 12px;
        }
      }
    }
  }
  .title_graph {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    margin-top: 10px;
    padding-left: 10px;
  }
  .bottom_men {
    display: flex;
    column-gap: 30px;
    margin-top: 25px;
    row-gap: 16px;
    .b_left_layout {
      flex: 1;
      position: relative;
      .top_label {
        color: ${({ theme }) => theme.COLORS.GRAY_4};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        position: absolute;
        top: -6px;
        left: 15px;
      }
      .body_graph {
        // padding: 0px 15px;
        tspan {
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
    
    .seleton_body {
      width: 100%;
    }
  }
  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
    height: calc(100% - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
`;

export default function Food4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-704 -1200)">
        <g id="_11-roast" data-name="11-roast" transform="translate(703.002 1199.003)">
          <path id="Path_20633" data-name="Path 20633" d="M39.547,47l-1.006,8.064a2.581,2.581,0,0,1-2.561,2.258H9.568a2.581,2.581,0,0,1-2.561-2.258L6,47Z" transform="translate(-1.775 -16.325)" fill="#e8e4dc" />
          <path id="Path_20634" data-name="Path 20634" d="M48.161,39.161H45.581A5.654,5.654,0,0,1,43,34Z" transform="translate(-14.905 -11.711)" fill="#869f3c" />
          <path id="Path_20635" data-name="Path 20635" d="M60.161,34a5.641,5.641,0,0,1-2.581,5.161H55Z" transform="translate(-19.164 -11.711)" fill="#869f3c" />
          <path id="Path_20636" data-name="Path 20636" d="M57,4.871C57,1,60.871,1,60.871,1,60.871,4.871,57,4.871,57,4.871Z" transform="translate(-19.873 -0.001)" fill="#96b343" />
          <path id="Path_20637" data-name="Path 20637" d="M21,6s3.871,0,3.871,3.871C24.871,9.871,21,9.871,21,6Z" transform="translate(-7.098 -1.775)" fill="#96b343" />
          <path id="Path_20638" data-name="Path 20638" d="M20.975,35.419l2.555,3.742H11.279A3.759,3.759,0,0,1,13.788,34H15.13l4.445.555A2.017,2.017,0,0,1,20.975,35.419Z" transform="translate(-3.551 -11.711)" fill="#f3c0a5" />
          <path id="Path_20639" data-name="Path 20639" d="M17.489,17.488A17.473,17.473,0,0,1,21.773,17a26.6,26.6,0,0,1,6.316.652c-4.471.039-8.9,2.129-9.8,3.045s-1.735,1.155-4.516,1.852c-3.613.9-5.548,5.419-5.548,5.419a17.853,17.853,0,0,0-.645,5.161H5.567A8.893,8.893,0,0,1,5,29.9c0-5.806,4.477-8.587,6.787-9.5a7.677,7.677,0,0,0,1.864-1.135,10.335,10.335,0,0,1,3.839-1.781Z" transform="translate(-1.42 -5.677)" fill="#ffece0" />
          <path id="Path_20640" data-name="Path 20640" d="M33.448,19.862c-4.606.781-10.258,2.626-10.258,6.987a8.393,8.393,0,0,0,2.581,6.38v.258H22.816l-2.555-3.742a2.017,2.017,0,0,0-1.4-.864l-4.445-.555H13.075a3.759,3.759,0,0,0-2.51,5.161H9a17.853,17.853,0,0,1,.645-5.161s1.935-4.516,5.548-5.419c2.781-.7,3.613-.923,4.516-1.852s5.329-3.006,9.8-3.045a12.82,12.82,0,0,1,1.587.477,6.311,6.311,0,0,1,2.355,1.374Z" transform="translate(-2.837 -6.037)" fill="#fce2d1" />
          <path id="Path_20641" data-name="Path 20641" d="M34.96,14.812a26.6,26.6,0,0,0-6.316-.652,17.473,17.473,0,0,0-4.284.49c4.619-2.974,13.316-3.071,13.316-3.071V10.29a1.4,1.4,0,0,1,.316-.916A1.131,1.131,0,0,1,38.863,9a1.355,1.355,0,0,1,1.393,1.29v.645l1.006.4a1.7,1.7,0,0,1,.929,1.535v.11a1.824,1.824,0,0,1-3.116,1.29l-.755-.755L36.547,15.29a12.82,12.82,0,0,0-1.587-.477Z" transform="translate(-8.291 -2.839)" fill="#f3c0a5" />
          <path id="Path_20642" data-name="Path 20642" d="M38.738,32.128a2.581,2.581,0,1,0-5.161,0v.387A8.393,8.393,0,0,1,31,26.135c0-4.361,5.651-6.206,10.258-6.987a42.623,42.623,0,0,1,5.871-.568V17.29a1.4,1.4,0,0,1,.316-.916A1.131,1.131,0,0,1,48.312,16a1.355,1.355,0,0,1,1.393,1.29v.645l1.006.4a1.7,1.7,0,0,1,.929,1.535v.11a1.824,1.824,0,0,1-3.116,1.29l-.755-.755-4.342,4.7s1.774.916,1.774,5.529a4.826,4.826,0,0,1-.168,1.316c-1.181-4.451-6.3-4.451-6.3-4.451a5.654,5.654,0,0,0,2.581,5.161H38.738Z" transform="translate(-10.643 -5.324)" fill="#fce2d1" />
          <path id="Path_20643" data-name="Path 20643" d="M1,42H41v1.062a1.29,1.29,0,0,1-.811,1.2l-2.415.968H4.226l-2.415-.968A1.29,1.29,0,0,1,1,43.062Z" transform="translate(-0.001 -14.55)" fill="#da684e" />
          <path id="Path_20644" data-name="Path 20644" d="M9.719,5.048,5.048,9.719A3.3,3.3,0,1,1,9.719,5.048Z" transform="translate(-1.094 -1.094)" fill="#ffe59f" />
          <path id="Path_20645" data-name="Path 20645" d="M10.031,2.547l-1.4,1.406H8.625A3.3,3.3,0,1,0,3.954,8.625v.006l-1.406,1.4a5.292,5.292,0,1,1,7.484-7.484Z" transform="translate(0)" fill="#ffcf4f" />
          <path id="Path_20646" data-name="Path 20646" d="M43,34s5.116,0,6.3,4.451a3.433,3.433,0,0,1-.264.71h-.871Z" transform="translate(-14.905 -11.711)" fill="#96b343" />
          <path id="Path_20647" data-name="Path 20647" d="M59.451,34,54.29,39.161H53C53.8,34,59.451,34,59.451,34Z" transform="translate(-18.454 -11.711)" fill="#96b343" />
          <g id="Group_14503" data-name="Group 14503" transform="translate(4.748 4.745)">
            <circle id="Ellipse_1868" data-name="Ellipse 1868" cx="0.5" cy="0.5" r="0.5" transform="translate(2.25 2.253)" fill="#ffe59f" />
            <ellipse id="Ellipse_1869" data-name="Ellipse 1869" cx="0.5" rx="0.5" transform="translate(2.25 6.253)" fill="#ffe59f" />
            <ellipse id="Ellipse_1870" data-name="Ellipse 1870" cx="0.5" rx="0.5" transform="translate(0.25 6.253)" fill="#ffe59f" />
            <circle id="Ellipse_1871" data-name="Ellipse 1871" cx="0.5" cy="0.5" r="0.5" transform="translate(0.25 7.253)" fill="#ffe59f" />
            <ellipse id="Ellipse_1872" data-name="Ellipse 1872" cx="1.5" cy="0.5" rx="1.5" ry="0.5" transform="translate(3.25 0.253)" fill="#ffe59f" />
            <ellipse id="Ellipse_1873" data-name="Ellipse 1873" cx="1.5" cy="0.5" rx="1.5" ry="0.5" transform="translate(3.25 2.253)" fill="#ffe59f" />
          </g>
          <path id="Path_20648" data-name="Path 20648" d="M39.708,44.29H2.29A1.29,1.29,0,0,1,1,43v.417a1.29,1.29,0,0,0,.811,1.2l2.415.968H37.773l2.415-.968a1.29,1.29,0,0,0,.811-1.2V43A1.29,1.29,0,0,1,39.708,44.29Z" transform="translate(-0.001 -14.905)" fill="#85392b" />
          <rect id="Rectangle_18225" data-name="Rectangle 18225" width="32" height="1" rx="0.5" transform="translate(2.998 26.998)" fill="#ea735c" />
          <rect id="Rectangle_18226" data-name="Rectangle 18226" width="3" height="1" rx="0.5" transform="translate(34.998 26.998)" fill="#ea735c" />
          <path id="Path_20649" data-name="Path 20649" d="M9.969,53.766a4.193,4.193,0,0,1,4.144-4.831H39.306L39.547,47H6l1.006,8.064a2.581,2.581,0,0,0,2.561,2.258h4.546a4.193,4.193,0,0,1-4.144-3.556Z" transform="translate(-1.775 -16.325)" fill="#d6cec1" />
          <rect id="Rectangle_18227" data-name="Rectangle 18227" width="6" height="3" rx="1.5" transform="translate(24.998 37.998)" fill="#f7f3eb" />
          <path id="Path_20650" data-name="Path 20650" d="M52.071,53.016a1.524,1.524,0,0,0-1.241,1.3l-.3,1.958a.323.323,0,0,1-.319.274h-.759a1.452,1.452,0,1,0,0,2.9h1.432a2.581,2.581,0,0,0,2.562-2.262l.316-2.532a1.475,1.475,0,0,0-1.69-1.641Z" transform="translate(-16.68 -18.454)" fill="#f7f3eb" />
          <path id="Path_20651" data-name="Path 20651" d="M40.161,39.581a2.581,2.581,0,1,0-5.161,0v.645h5.161Z" transform="translate(-12.066 -12.776)" fill="#e84655" />
          <path id="Path_20652" data-name="Path 20652" d="M37.581,37A2.577,2.577,0,0,0,35,39.581v.645a2.581,2.581,0,1,1,5.161,0v-.645A2.581,2.581,0,0,0,37.581,37Z" transform="translate(-12.066 -12.776)" fill="#ff6976" />
          <ellipse id="Ellipse_1874" data-name="Ellipse 1874" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(25.258 26.273)" fill="#ff858f" />
          <ellipse id="Ellipse_1875" data-name="Ellipse 1875" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(23.822 26.477) rotate(-31.926)" fill="#ff858f" />
          <ellipse id="Ellipse_1876" data-name="Ellipse 1876" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(24.467 25.831) rotate(-31.926)" fill="#ff858f" />
          <ellipse id="Ellipse_1877" data-name="Ellipse 1877" cx="0.37" cy="0.266" rx="0.37" ry="0.266" transform="translate(26.403 26.81) rotate(-58.074)" fill="#ff858f" />
          <ellipse id="Ellipse_1878" data-name="Ellipse 1878" cx="0.37" cy="0.266" rx="0.37" ry="0.266" transform="translate(25.758 26.165) rotate(-58.074)" fill="#ff858f" />
          <path id="Path_20653" data-name="Path 20653" d="M23.589,40.226a2.959,2.959,0,0,0-2.443-1.29H20.63l-.326-.516a1.856,1.856,0,0,0-1.3-.864L14.888,37H13.641a3.316,3.316,0,0,0-2.47,2.652,4.293,4.293,0,0,0,.165.574Z" transform="translate(-3.61 -12.776)" fill="#f3aa83" />
          <path id="Path_20654" data-name="Path 20654" d="M51.082,19.918v.053a3.589,3.589,0,0,1-.029.463A1.552,1.552,0,0,1,48.972,21.7a1.121,1.121,0,0,1-.294-.165,1.251,1.251,0,0,0-.792-.275H46.566c-3.893,0-7.052,2.937-8.369,4.38a2.556,2.556,0,0,0-.663,1.726,2.351,2.351,0,0,1-1.69,2.269c-1.372.392-3.29.645-4.611-.375a8.081,8.081,0,0,0,2.43,4.644v-.387a2.576,2.576,0,0,1,2.457-2.577,2.684,2.684,0,0,1,2.7,2.723v.5H41.4A5.654,5.654,0,0,1,38.824,29s5.116,0,6.3,4.451a4.826,4.826,0,0,0,.168-1.316c0-4.613-1.774-5.529-1.774-5.529l4.342-4.7.755.755a1.826,1.826,0,0,0,3.116-1.29v-.11a1.732,1.732,0,0,0-.645-1.343Z" transform="translate(-10.73 -6.714)" fill="#f3c0a5" />
          <path id="Path_20655" data-name="Path 20655" d="M8.832,31.466A7.234,7.234,0,0,1,6.04,30.111,9.612,9.612,0,0,0,5,34.555a8.893,8.893,0,0,0,.568,3.226H7.58a17.853,17.853,0,0,1,.645-5.161A11.429,11.429,0,0,1,8.832,31.466Z" transform="translate(-1.42 -10.331)" fill="#fce2d1" />
          <path id="Path_20656" data-name="Path 20656" d="M33.448,20.259a5.64,5.64,0,0,0-1.761-1.129c-11.569,1.077-9.4,8.849-17.34,8.9a15.531,15.531,0,0,1-4.1-.456,11.429,11.429,0,0,0-.607,1.154A17.853,17.853,0,0,0,9,33.884h1.568a3.759,3.759,0,0,1,2.51-5.161h1.342l4.445.555a2.017,2.017,0,0,1,1.4.864l2.555,3.742h2.955v-.258a8.393,8.393,0,0,1-2.581-6.38C23.191,22.885,28.842,21.04,33.448,20.259Z" transform="translate(-2.837 -6.435)" fill="#fad5c3" />
          <ellipse id="Ellipse_1879" data-name="Ellipse 1879" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(22.032 21.112)" fill="#fff3eb" />
          <ellipse id="Ellipse_1880" data-name="Ellipse 1880" cx="0.37" cy="0.266" rx="0.37" ry="0.266" transform="translate(23.864 16.7)" fill="#fff3eb" />
          <ellipse id="Ellipse_1881" data-name="Ellipse 1881" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(24.423 19.464) rotate(-45)" fill="#ffece0" />
          <ellipse id="Ellipse_1882" data-name="Ellipse 1882" cx="0.266" cy="0.37" rx="0.266" ry="0.37" transform="translate(22.032 18.531)" fill="#ffece0" />
          <ellipse id="Ellipse_1883" data-name="Ellipse 1883" cx="0.532" cy="0.741" rx="0.532" ry="0.741" transform="translate(22.85 20.378) rotate(-45)" fill="#ffece0" />
          <ellipse id="Ellipse_1884" data-name="Ellipse 1884" cx="0.532" cy="0.741" rx="0.532" ry="0.741" transform="translate(22.851 18.442) rotate(-45)" fill="#ffece0" />
          <path id="Path_20657" data-name="Path 20657" d="M23.369,18.412a4.487,4.487,0,0,0-1.174.488,1.443,1.443,0,0,0-.686.906.589.589,0,0,0,.134.516.874.874,0,0,0,.476.22,2.516,2.516,0,0,0,1.652-.387c.484-.279.909-.631,1.424-.869.616-.285,1.337-.4,1.926-.717.1-.057.216-.151.174-.249a.243.243,0,0,0-.094-.092,2.733,2.733,0,0,0-1.666-.211,8.921,8.921,0,0,0-2.166.395Z" transform="translate(-7.274 -6.023)" fill="#fff7f2" />
          <path id="Path_20658" data-name="Path 20658" d="M11.377,24.413c-.258.246-.5.62-.311.919a.626.626,0,0,0,.567.247,1.871,1.871,0,0,0,.623-.181l3.575-1.512a.619.619,0,0,0,.279-.184.387.387,0,0,0-.125-.5,1.1,1.1,0,0,0-.556-.173,5.071,5.071,0,0,0-4.052,1.385Z" transform="translate(-3.55 -7.808)" fill="#fff7f2" />
          <path id="Path_20659" data-name="Path 20659" d="M18.954,25.488,17,26.238c-.406.155-.864.367-.975.79a.952.952,0,0,0,.008.464A1.912,1.912,0,0,0,17.507,28.8a4.441,4.441,0,0,0,2.045-.129A5.672,5.672,0,0,0,21.263,28a2.606,2.606,0,0,0,1.131-1.419,1.13,1.13,0,0,0-.768-1.456A3.933,3.933,0,0,0,18.954,25.488Z" transform="translate(-5.324 -8.517)" fill="#ffece0" />
          <path id="Path_20660" data-name="Path 20660" d="M27.064,22.6a1.035,1.035,0,0,0-.011.693.483.483,0,0,0,.608.261,1.513,1.513,0,0,0,.577-.47c.331-.377.653-.767.968-1.161.105-.129.393-.375.366-.564-.024-.171-.27-.3-.381-.33-.432-.125-.913.21-1.284.463A2.05,2.05,0,0,0,27.064,22.6Z" transform="translate(-9.227 -7.098)" fill="#ffece0" />
          <path id="Path_20661" data-name="Path 20661" d="M47.14,27.927l1.788-.258L53.24,23H51.95c-1.737,0-4.059,2.339-5.391,3.871a.645.645,0,0,0,.581,1.056Z" transform="translate(-16.114 -7.808)" fill="#f3aa83" />
          <path id="Path_20662" data-name="Path 20662" d="M38.321,14.664c-1.29,0-1.29,1.29-2.581.645a31.059,31.059,0,0,0-4.56-1.256,19.782,19.782,0,0,0-6.82,2.392,17.473,17.473,0,0,1,4.284-.49,26.6,26.6,0,0,1,6.316.652,12.82,12.82,0,0,1,1.587.477l1.774-1.774.755.755a1.826,1.826,0,0,0,3.116-1.29v-.11C40.256,16.6,39.611,14.664,38.321,14.664Z" transform="translate(-8.291 -4.633)" fill="#f3aa83" />
          <path id="Path_20663" data-name="Path 20663" d="M24.871,9.871,21,6C21,9.871,24.871,9.871,24.871,9.871Z" transform="translate(-7.098 -1.775)" fill="#869f3c" />
          <path id="Path_20664" data-name="Path 20664" d="M60.871,1,57,4.871S60.871,4.871,60.871,1Z" transform="translate(-19.873 -0.001)" fill="#869f3c" />
        </g>
      </g>
    </svg>
  );
}

import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnDateFillter3Styled } from './styled';

const BtnDateFillter3 = ({ theme_standard_date_filter, onSelected }) => {
  const customClass = cx({
    theme_standard_date_filter: theme_standard_date_filter,
  });
  const [selected, setSelected] = useState('weekly');

  const handleClickTab = (key) => {
    setSelected(key);
    onSelected && onSelected(key);
  };

  return (
    <BtnDateFillter3Styled>
      <div className={customClass}>
        <div className="top_opt">
          <div
            key={1}
            className={`opt1 ${selected === 'weekly' ? 'active' : ''}`}
            onClick={() => handleClickTab('weekly')}
          >
            สัปดาห์
          </div>
          <div
            key={2}
            className={`opt3 ${selected === 'monthly' ? 'active' : ''}`}
            onClick={() => handleClickTab('monthly')}
          >
            เดือน
          </div>
          {/* <div
            key={3}
            className={`opt3 ${selected === 3 ? 'active' : ''}`}
            onClick={() => handleClickTab(3)}
          >
            ปี
          </div> */}
        </div>
      </div>
    </BtnDateFillter3Styled>
  );
};

// BtnDateFillter3.propTypes = {};

export default BtnDateFillter3;

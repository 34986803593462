import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxLabelCenterStyled } from "./styled";

const BoxLabelCenter = ({
  theme_standard_box_label_center,
  theme_standard_box_4_col,
  label1,
  label2,
  label3,
  svg1,
  svg2,
  labelBack,
  iconShow,
  label3Fontsize,
  label3FontsColor,
  labelBackFontsize,
  labelBackFontColor,
  paddingTop,
}) => {
  const customClass = cx({
    theme_standard_box_label_center: theme_standard_box_label_center,
    theme_standard_box_4_col: theme_standard_box_4_col,
  });
  return (
    <BoxLabelCenterStyled
      label3Fontsize={label3Fontsize}
      label3FontsColor={label3FontsColor}
      labelBackFontsize={labelBackFontsize}
      labelBackFontColor={labelBackFontColor}
    >
      <div className={customClass}>
        <div className="left_layout">
          {iconShow && <div className="left_icon_show">{iconShow}</div>}
          <div className="left_label_show">
            <div className="box_label1">{label1}</div>
            <div className="bottom_label">{label2}</div>
          </div>
        </div>
        <div className="right_layout">
          {svg1 && <div className="svg_show_f">{svg1}</div>}
          <div className="label_3">{label3}</div>
          <div className="back_label">{labelBack}</div>
          {svg2 && <div className="svg_show_b">{svg2}</div>}
        </div>
      </div>
    </BoxLabelCenterStyled>
  );
};

BoxLabelCenter.propTypes = {};

export default BoxLabelCenter;

import styled from "styled-components";

export const TableActionAllClickStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_table_normal {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 10px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          margin-top: 20px;
        }
        .body_row {
          display: flex;
          width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          border-radius: 12px;
          padding: 18px 0;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_purple {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 11px 0;
        // border-bottom: 1.5px solid  ${({ theme }) => theme.COLORS.GRAY_4};
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        border-radius: 14px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          background: red;
          padding: 12px 0;
          text-align: center;
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.GRAY_11};
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          border-radius: 14px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.PURPLE_3};
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_gray {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 11px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_4};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1}50;
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          border-radius: 14px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.GRAY_4}10;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_normal_action {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: ${({ theme }) => theme.COLORS.BLUE_31};
        border-raius: 0px;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 12px 15px;
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            // border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          align-items: center;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          // padding: 10px 0;
          // align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            padding: 13.2px 15px;
            width: 100%;
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background:  ${({ theme }) => theme.COLORS.BLUE_32};
          }
          &.select {
            background:  ${({ theme }) => theme.COLORS.BLUE_32};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_standard_table_user {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: ${({ theme }) => theme.COLORS.BLUE_31};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 12px 15px;
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            // border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 12px 0;
          text-align: center;
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          align-items: center;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          // padding: 10px 0;
          // align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            padding: 13.2px 15px;
            width: 100%;
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background:  ${({ theme }) => theme.COLORS.BLUE_32};
          }
          &.select {
            background:  ${({ theme }) => theme.COLORS.BLUE_32};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_department {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid #b5b5b5;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 10px 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: #171717;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.PURPLE_5};
          // padding: 10px 0;
          // align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid #b5b5b5;
          border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 10px 15px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard_table_user {
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    // .theme_standard_table_user {
    //   .table_container {
    //     .table_header {
    //       .header_row {
    //         .header_col {
    //           font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    //         }
    //       }
    //     }
    //     .table_body {
    //       .body_row {
    //         .body_col {
    //           font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    //           padding-top: 15px;
    //           &:first-child {
    //             padding-top: 10px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .theme_department {
      .table_container {
        .table_header {
          .header_row {
            .header_col {
              font-size: ${({ theme }) => theme.FONT.SIZE.S15};
            }
          }
        }
        .table_body {
          .body_row {
            .body_col {
              font-size: ${({ theme }) => theme.FONT.SIZE.S10};
              padding-top: 15px;
              &:first-child {
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
  }
`;

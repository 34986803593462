export default function renderIcon({
  color = "#0B408D",
  width = "14",
  height = "16",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 16"
    >
      <path
        id="Icon_awesome-calendar-alt"
        data-name="Icon awesome-calendar-alt"
        d="M0,14.5A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V6H0ZM10,8.375A.376.376,0,0,1,10.375,8h1.25A.376.376,0,0,1,12,8.375v1.25a.376.376,0,0,1-.375.375h-1.25A.376.376,0,0,1,10,9.625Zm0,4A.376.376,0,0,1,10.375,12h1.25a.376.376,0,0,1,.375.375v1.25a.376.376,0,0,1-.375.375h-1.25A.376.376,0,0,1,10,13.625Zm-4-4A.376.376,0,0,1,6.375,8h1.25A.376.376,0,0,1,8,8.375v1.25A.376.376,0,0,1,7.625,10H6.375A.376.376,0,0,1,6,9.625Zm0,4A.376.376,0,0,1,6.375,12h1.25A.376.376,0,0,1,8,12.375v1.25A.376.376,0,0,1,7.625,14H6.375A.376.376,0,0,1,6,13.625Zm-4-4A.376.376,0,0,1,2.375,8h1.25A.376.376,0,0,1,4,8.375v1.25A.376.376,0,0,1,3.625,10H2.375A.376.376,0,0,1,2,9.625Zm0,4A.376.376,0,0,1,2.375,12h1.25A.376.376,0,0,1,4,12.375v1.25A.376.376,0,0,1,3.625,14H2.375A.376.376,0,0,1,2,13.625ZM12.5,2H11V.5a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,9,.5V2H5V.5A.5.5,0,0,0,4.5,0h-1A.5.5,0,0,0,3,.5V2H1.5A1.5,1.5,0,0,0,0,3.5V5H14V3.5A1.5,1.5,0,0,0,12.5,2Z"
        fill={color}
      />
    </svg>
  );
}

export default function EmotionSad({ width = '80', height = '80' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 80 80">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="-0.165" x2="0.5" y2="1.131" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#ffe279" />
          <stop offset="1" stop-color="#ffb632" />
        </linearGradient>
      </defs>
      <g id="Group_6" data-name="Group 6" transform="translate(0 8)">
        <circle id="Oval_Copy" data-name="Oval Copy" cx="40" cy="40" r="40" transform="translate(0 -8)" fill="url(#linear-gradient)" />
        <path id="Rectangle_Copy" data-name="Rectangle Copy" d="M0,5.49A2.811,2.811,0,0,1,2.3,2.645,73.506,73.506,0,0,1,20.193,0,68.609,68.609,0,0,1,37.7,2.639,2.811,2.811,0,0,1,40,5.483V6.489a2.742,2.742,0,0,1-3.036,2.844A65.6,65.6,0,0,0,20.192,6.8,70.645,70.645,0,0,0,3.029,9.342,2.742,2.742,0,0,1,0,6.5Z" transform="translate(20 50.431)" />
        <path id="Oval_Copy_2" data-name="Oval Copy 2" d="M6.627,11.551a6.632,6.632,0,0,0,6.219-8.924C9.668,1.755,3.722.316,2.191,0A6.627,6.627,0,0,0,6.627,11.551Z" transform="translate(13.563 36.083) rotate(-60)" />
        <path id="Oval_Copy_3" data-name="Oval Copy 3" d="M6.627,11.551A6.632,6.632,0,0,1,.409,2.627C3.586,1.755,9.533.316,11.063,0A6.627,6.627,0,0,1,6.627,11.551Z" transform="translate(59.81 24.604) rotate(60)" />
      </g>
    </svg>
  );
}

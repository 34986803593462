import styled from 'styled-components';

export const ArticleDetailStyled = styled.div`
  .article_layout {
    display: flex;
    .img_show {
      width: 35%;
      border-radius: 12px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      background-image: url(${({ src }) => src});
      height: 207px;
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .detail_show {
      width: 63%;
      margin-left: 18px;
      .title_group {
        display: flex;
        justify-content: space-between;
        .show_t {
          .title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S22};
            font-family: noto_sans_thai_bold, noto_sans_bold;
          }
          .show_value {
            margin-top: 19px;
            .box_icon {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              &.no_margin {
                margin-bottom: 0px;
              }
              .show_svg {
                display: flex;
                width: 33px;
                justify-content: center;
                margin-right: 7px;
              }
              .label_show {
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.gray {
                  font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                  font-family: noto_sans_thai_medium, noto_sans_regular;
                  color:  ${({ theme }) => theme.COLORS.GRAY_1};
                }
                .sub_label {
                  font-family: noto_sans_thai_semi_bold, noto_sans_regular;
                  font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                  color:  ${({ theme }) => theme.COLORS.GOLD_1};
                  &.bold {
                    font-family: noto_sans_thai_bold, noto_sans_bold; 
                    color:  ${({ theme }) => theme.COLORS.ORANGE_4};
                  }
                }
              }
            }
          }
        }
        .btn_t {
          padding-left: 15px;
          width: 21%;
        }
      }
    }
  }
`;

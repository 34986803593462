import React from "react";
import { RewardPointContainerStyled } from "./styled";
import { Tabs, Loading, Blocks, BGLoading } from "components";
// import { ROUTE_PATH } from 'utils/constants';
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { ExportRewardPoint } from "widgets";
import moment from "moment";
// import ReactTooltip from 'react-tooltip';

class RewardPointContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async (stringQuery) => {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  onSubmit = (values) => {
    // console.log('value',values)
  };

  handleDownload = async (e) => {
    this.setState({
      isBgLoading: true,
    });
    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      "user_coin",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setState({
        isBgLoading: false,
      });
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { isLoading, isBgLoading } = this.state;

    return (
      <RewardPointContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="คะแนนที่ได้รับรายเดือน"
                  subTitle="Reward points information"
                  fontSize="34px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#092945"
                  colorBottom="#092945"
                  lineHeight="1.3"
                />
              </div>
              <div className="download_zone">
                <div className="top_zone">
                  <div className="text_top">
                    คะแนนสะสมของพนักงานแต่ละคนโดยแสดงเป็นคะแนนสะสมรายเดือน
                  </div>
                  กรุณาเลือกเดือน และปีที่คุณต้องการดาวน์โหลดข้อมูล
                </div>
                <div className="form_show">
                  {/* <Forms.RewardPointForm  /> */}
                  <ExportRewardPoint
                    img="bmi"
                    onDownload={this.handleDownload}
                    minDate={moment()
                      .tz("Asia/Bangkok")
                      .subtract(10, "years")
                      .format("01-01-YYYY")}
                    rangeDate={11}
                  />
                </div>
              </div>
            </Blocks.Container>
          </>
        )}
      </RewardPointContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardPointContainer);

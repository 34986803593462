import { ROUTE_PATH } from "utils/constants";
import { Icons } from "components";
import MainDashboardContainer from "containers/InvitraceSystem/MainDashboardContainer";
import BackOfficeContainer from "containers/InvitraceSystem/BackOfficeContainer";
import NewManagementContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/NewManagementContainer";
import DepartmentDetailContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/DepartmentDetailContainer";
import NewLogContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/NewLogContainer";
import DepartmentCreateEditContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/DepartmentCreateEditContainer";
import RewardManagmentContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer";
import RewardSearchContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardSearchContainer";
import RewardHistoryContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardHistoryContainer";
import RewardExchangeContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardExchangeContainer";
import RewardExchangeHistoryContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardExchangeHistoryContainer";
import RewardDetailsContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardDetailsContainer";
import RewardAddDetailsContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardAddDetailsContainer";

import AccountManagementContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/AccountManagementContainer";
import CreateAccountContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/AccountManagementContainer/Menus/CreateAccountContainer";

import DeleteUserAccountManagementContainer from "containers/InvitraceSystem/BackOfficeContainer/Menus/DeleteUserAccountManagementContainer";

const adminRoutes = [
  {
    key: "administrator",
    path: ROUTE_PATH.ADMINISTARTOR,
    name: "Main",
    exact: true,
    notShowSidebar: true,
    component: MainDashboardContainer,
  },
  {
    key: "main",
    path: ROUTE_PATH.MAIN,
    name: "Main",
    exact: true,
    icon: (active) => <Icons.Main color={active ? "#575D96" : "#ffffff"} />,
    component: MainDashboardContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE,
    name: "Back office",
    exact: true,
    icon: (active) => <Icons.Setting2 color={active ? "#575D96" : "#ffffff"} />,
    component: BackOfficeContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardManagmentContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_SEARCH,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardSearchContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_HISTORY,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardHistoryContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE_LIST,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardExchangeContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE_HISTORY_LIST,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardExchangeHistoryContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_PREVIEW_DETAIL_BY_ID,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardDetailsContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_REWARD_CREATE_EDIT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: RewardAddDetailsContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_NEW,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: NewManagementContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_NEW_LOG,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: NewLogContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_PREVIEW_DETAIL_BY_ID,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: DepartmentDetailContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_CREATE_EDIT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: DepartmentCreateEditContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_ACCOUNT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    icon: (active) => <Icons.MenuBackOffice isActive={active} />,
    component: AccountManagementContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_CREATE_EDIT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: CreateAccountContainer,
  },
  {
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_DELETE_USER_ACCOUNT,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    icon: (active) => <Icons.MenuBackOffice isActive={active} />,
    component: DeleteUserAccountManagementContainer,
  },
];

export default adminRoutes;

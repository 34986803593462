export default function MenuBackOffice({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.332"
      height="20.332"
      viewBox="0 0 20.332 20.332"
    >
      <path
        id="Subtraction_20"
        data-name="Subtraction 20"
        d="M11.614,20.332h-2.9L7.551,17.87l-.984-.408L4,18.379,1.953,16.329l.917-2.565-.408-.984L0,11.614v-2.9L2.462,7.551l.408-.984L1.953,4,4,1.953l2.567.917.982-.406L8.717,0h2.9l1.167,2.464.982.406,2.567-.917L18.379,4l-.917,2.566.408.984,2.462,1.166v2.9L17.87,12.781l-.408.984.917,2.565-2.049,2.049-2.565-.917-.984.408-1.166,2.462Zm-1.5-13.358a3.138,3.138,0,1,0,3.138,3.138A3.142,3.142,0,0,0,10.112,6.974Z"
        fill="#fff"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.154"
      height="20.154"
      viewBox="0 0 20.154 20.154"
    >
      <path
        id="Subtraction_19"
        data-name="Subtraction 19"
        d="M10.941,19.154H8.212l-1.1-2.32-.927-.384-2.417.864L1.84,15.383,2.7,12.966,2.32,12.04,0,10.941V8.212l2.32-1.1L2.7,6.187,1.84,3.77,3.77,1.84,6.188,2.7l.925-.383L8.212,0h2.729l1.1,2.321.925.383,2.418-.864,1.931,1.93L16.45,6.187l.384.927,2.32,1.1v2.729l-2.32,1.1-.384.927.864,2.417-1.931,1.931-2.417-.864-.927.384-1.1,2.319ZM9.526,6.57a2.956,2.956,0,1,0,2.956,2.956A2.96,2.96,0,0,0,9.526,6.57Z"
        transform="translate(0.5 0.5)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
    </svg>
  );
}

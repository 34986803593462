import styled from "styled-components";

export const MsdPercentOfOrgansStyled = styled.div`
  height: 100%;
  .container {
    .top_main {
      display: flex;
      .top_main_left {
        width: 60%;
        display: flex;
        align-items: center;
        .show_icon {
          margin-right: 7px;
        }
      }
      .top_main_right {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .label_layout {
          display: flex;
          .show_icon {
          }
          .show_label {
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            margin-left: 8px;
          }
        }
        .date_layout {
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
    }
    .show_group_label {
      margin-top: 50px;
      display: flex;
      flex-flow: wrap;
      max-width: 650px;
      .line_label {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        margin-right: 15px;
        min-width: 140px;

        .round {
          width: 16px;
          height: 16px;
          border-radius: 4px;
        }
        .label {
          margin-left: 10px;
        }
      }
    }
    .bottom_main {
      display: flex;
      margin-top: 20px;
      .bottom_main_detail {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.right {
          width: 50%;
          border-left: 1px solid #d3d3d3;
        }
        .graph_show {
        }
        .graph_label_top {
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: noto_sans_thai_semi_bold, noto_sans_regular;
          margin-top: 26px;
          &.no_mt {
            margin-top: 0px;
          }
        }
        .graph_label_bottom {
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          margin-top: 3px;
        }
        .empty_show {
          height: 270px;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .show_group_label {
      .line_label {
        .label {
        }
      }
    }
  }
`;

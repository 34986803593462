import { store, persistor } from 'store';
import Routes from './routes';
import theme from 'styles/theme.json';
import { Provider } from 'react-redux';
import { GlobalStyle } from 'styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Notification } from 'components';
import { TokenManager } from 'contexts';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <Notification />
            <TokenManager>
              <Routes />
            </TokenManager>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;

export default function renderIcon({
  width = "20",
  height = "20",
  color = "#002366",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.88472 13.0159L13.5301 4.41086C13.8566 4.08586 14.3866 4.08586 14.7131 4.41086L16.1164 5.80753C16.4429 6.13253 16.4429 6.66003 16.1164 6.98503L7.47013 15.5892C7.31357 15.7459 7.10091 15.8334 6.87904 15.8334H4.6394V13.6042C4.6394 13.3834 4.72732 13.1717 4.88472 13.0159Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9653 5.96667L14.5524 8.54167"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import { TableActionAllClickStyled } from "./styled";
import cx from "classnames";
import { PaginationCustom, Inputs } from "components";

export const TableActionAllClick = ({
  theme_standard_table_normal,
  theme_standard_table_normal_gray,
  theme_standard_table_normal_purple,
  theme_standard_table_user,
  theme_normal_action,
  theme_department,
  columns,
  isUseResetSelect = true,
  data,
  labelEmpty,
  pagination,
  showTitle,
  useNo,
  bgColor,
  bgHeader,
  showPagination,
  marginTopPagination,
  handlePageClick,
  onChangeSelected,
  resetSelect = false,
  isCheckbox = true,
}) => {
  const customClass = cx({
    theme_standard_table_normal: theme_standard_table_normal,
    theme_standard_table_normal_gray: theme_standard_table_normal_gray,
    theme_standard_table_user: theme_standard_table_user,
    theme_standard_table_normal_purple: theme_standard_table_normal_purple,
    theme_department: theme_department,
    theme_normal_action: theme_normal_action,
  });

  const [_selected, _setSelected] = useState({});
  const [_isSelectedAll, _setIsSelectedAll] = useState(false);

  useEffect(() => {
    _setIsSelectedAll(false);
    if (isUseResetSelect) {
      _setIsSelectedAll(false);
      _setSelected({});
    } else {
      if (resetSelect) {
        _setIsSelectedAll(false);
        _setSelected({});
      } else {
        if (_selected && Object.keys(_selected).length > 0) {
          let tempObj = [];

          Object.keys(_selected).forEach((e, i) => {
            let findIndex = data.findIndex((a) => String(a.id) === String(e));
            if (findIndex > -1) {
              tempObj.push(String(e));
            }
          });

          if (tempObj.length !== 0 && data.length === tempObj.length) {
            _setIsSelectedAll(true);
          }
        }
      }
    }
  }, [data, isUseResetSelect, resetSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChangeSelected && onChangeSelected(_selected);
  }, [_selected, onChangeSelected]);

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const handleSelectedAll = (e) => {
    let selectedTemp = { ..._selected };
    if (_isSelectedAll) {
      _setIsSelectedAll(false);
      data.forEach((e, i) => {
        Object.keys(selectedTemp).forEach((j, k) => {
          if (String(e.id) === String(j)) {
            delete selectedTemp[j];
          }
        });
      });
    } else {
      data &&
        data.forEach((element) => {
          if (!element.disabled) {
            selectedTemp[element.id] = true;
          } else {
            if (element.disabled === false) {
              selectedTemp[element.id] = true;
            }
          }
        });
      _setIsSelectedAll(true);
    }
    _setSelected(selectedTemp);
  };

  const handleSelectedItem = (e) => {
    let temp = { ..._selected };
    if (temp[e?.target?.name]) {
      delete temp[e?.target?.name];
      _setSelected(temp);
      if (_isSelectedAll) {
        _setIsSelectedAll(false);
      }
    } else {
      temp[e?.target?.name] = true;
      _setSelected(temp);
    }
  };

  return (
    <TableActionAllClickStyled
      bgHeader={bgHeader}
      marginTopPagination={marginTopPagination}
    >
      <div className={customClass}>
        <div className="table_container">
          {showTitle && <div className="show_title">{showTitle}</div>}
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                columns.map((e, i) => (
                  <div
                    key={i}
                    className={`header_col ${e.classStyle ? e.classStyle : ""}`}
                    style={e.headerStyle}
                  >
                    {isCheckbox && i === 0 && (
                      <div className="checkbox_layout">
                        <Inputs.InputCheckBoxTable
                          input_check_box_square_light_blue
                          name={i}
                          value={"isSelectedAll"}
                          checked={_isSelectedAll}
                          onChange={handleSelectedAll}
                        />
                      </div>
                    )}
                    {e.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className="table_body">
            {!data || data.length === 0 ? (
              <div className="label_empty">{labelEmpty}</div>
            ) : (
              data.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`body_row ${_selected[e.id] ? "active" : ""}`}
                  >
                    {columns.map((e1, i2) => (
                      <div
                        key={i2}
                        className={`body_col ${
                          e1.classStyle ? e1.classStyle : ""
                        }`}
                        style={e1.style}
                      >
                        {isCheckbox && i2 === 0 && (
                          <div className="checkbox_layout">
                            <Inputs.InputCheckBoxTable
                              input_check_box_square_light_blue
                              value={e.id}
                              checked={_selected[e.id] ? true : false}
                              disabled={e.disabled}
                              onChange={handleSelectedItem}
                            />
                          </div>
                        )}
                        {useNo && i2 === 1 ? (
                          <div className="no">
                            {e1.render
                              ? e1.render({
                                  origin: e,
                                  value: i + 1,
                                })
                              : pagination
                              ? pagination.from + i
                              : i + 1}
                          </div>
                        ) : e1.render ? (
                          e1.render({
                            origin: e,
                            value: getDescendantProp(e, e1.field),
                          })
                        ) : (
                          getDescendantProp(e, e1.field)
                        )}
                      </div>
                    ))}
                  </div>
                );
              })
            )}
          </div>
          {showPagination && pagination && (
            <div className="pagi_layout">
              <PaginationCustom
                theme_standard_pagination
                totalPages={pagination?.last_page}
                currentPageData={pagination?.current_page - 1}
                _handlePageClick={handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    </TableActionAllClickStyled>
  );
};

export default TableActionAllClick;

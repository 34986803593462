import styled from 'styled-components';

export const BodyMassAgeGenderStyled = styled.div`
  .body_mass_container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 54px;
    row-gap: 16px;
    .body_mass_left_col {
      flex: 1;
    }
    .body_mass_right_col {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .item_right {
        &.w_200 {
          width: 200px;
          flex-shrink: 0;
        }
        &.w_152 {
          width: 152px;
          flex-shrink: 0;
        }
        &.ml_14 {
          margin-left: 14px;
        }
        &.ml_16 {
          margin-left: 14px;
        }
      }
    }
  }
  
  .bmi_trend_graph {
    display: flex;
    column-gap: 30px;
    row-gap: 16px;
    position: relative;
    margin-top: 25px;
    .label_top {
      position: absolute;
      top: -10px;
      left: 23px;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size:  ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
    }
    .show_graph {
      flex: 1;
      .graph_wrap {
        
      }
      .cate_row {
        min-width: 164px;
        display: flex;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        font-weight: 500;
        margin-right: -1.7rem;
        margin-left: 1rem;
        margin-top: -10px;
        .cate_label_item {
          width: 20%;
          text-align: center;
        }
      }
    }
    .show_group_label {
      max-width: 288px;
      width: 100%;
    }
    .arrow_box {
      padding: 1rem;
      width: 10rem;
    }
  }
  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .bmi_trend_graph {
      display: block;
      .show_graph {
        width: 100%;
      }
      .show_group_label {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .bmi_trend_graph {
      
  @media (max-width: 1020px) {
    .bmi_trend_graph {
      flex-direction: column;X
    }
  }
`;

import React, { useState } from 'react';
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CountDownStyled } from "./styled";
import Countdown from 'react-countdown';
import { Icons } from "components";

const CountDown = ({
  theme_standard_countdown,
  countdown,
  useReset = false,
}) => {
  const customClass = cx({
    theme_standard_countdown: theme_standard_countdown,
  });

  const [_key, _setKey] = useState(false);

  const handleResetCountDown = () => {
    if (useReset) {
      _setKey((i) => !i);
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="time_render_action">
          <div
            className="group_value"
            onClick={handleResetCountDown}
          >
            {/* <Icons.Refresh /> */}
            <div className="underline">
              Expire
            </div>
          </div>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="time_render_action">
          <div className="group_value">
            <Icons.ClockTime />
            <div>
              {
                `${hours > 0 ? `${hours} : ` : ``}${minutes} : ${seconds}`
              }
            </div>
          </div>
        </div>
      )
    }
  };

  return (
    <CountDownStyled >
      <div className={customClass}>
        <Countdown
          key={_key}
          date={countdown}
          // precision={2}
          renderer={renderer}
        />
      </div>
    </CountDownStyled>
  );
};

CountDown.propTypes = {};

export default CountDown;

import styled from 'styled-components';

export const InputSortStyled = styled.div`
  .theme_standard_sort {
    position: relative;
    .icon_filter {
      cursor: pointer;
    }
  }
`;

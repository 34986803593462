import styled from 'styled-components'

export const SuccesBoxStyled = styled.div`
  .theme_standard_succes_box {
    color:  ${({ theme }) => theme.COLORS.WHITE_1};
    position: relative;
    .show_svg {
      
    }
    .group_label {
      position: absolute;
      top: 55%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_success {
        // margin-top: 7px;
        font-family: noto_sans_thai_bold, noto_sans_bold;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
      .bottom_success {
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S9};
      }
    }
  }

  .theme_standard_un_succes_box {
    color:  $#141414;
    position: relative;
    .show_svg {
      
    }
    .group_label {
      position: absolute;
      top: 55%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_success {
        // margin-top: 7px;
        font-family: noto_sans_thai_bold, noto_sans_bold;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
      .bottom_success {
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S9};
        color: #092945;
      }
    }
  }
`

export default function renderIcon({ color = '#bb6989', width = "18.006", height = "33.75" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.006 33.75">
      <g id="Icon_ionic-ios-woman" data-name="Icon ionic-ios-woman" transform="translate(-8.983 -1.125)">
        <path id="Path_107142" data-name="Path 107142" d="M20.109,34.875a1.556,1.556,0,0,1-1.125-.464,1.8,1.8,0,0,1-.5-1.308v-8.4h-.977v8.4a1.768,1.768,0,0,1-.52,1.315,1.666,1.666,0,0,1-2.292,0,1.755,1.755,0,0,1-.52-1.315v-8.4H11.088l3.129-12h-.478l-1.716,6.356a1.6,1.6,0,0,1-1.5,1.252h0a1.5,1.5,0,0,1-1.2-.612,1.9,1.9,0,0,1-.253-1.68l2.039-7.32a3.844,3.844,0,0,1,3.424-2.763h6.919a4.011,4.011,0,0,1,3.41,2.735l.007.028,2.039,7.334a1.915,1.915,0,0,1-.274,1.688,1.506,1.506,0,0,1-1.188.6,1.6,1.6,0,0,1-1.5-1.273v-.014L22.24,12.712H21.7l3.2,12H21.727v8.4a1.8,1.8,0,0,1-.5,1.308A1.54,1.54,0,0,1,20.109,34.875Z" fill={color} />
        <path id="Path_107143" data-name="Path 107143" d="M17.993,7.481A3.138,3.138,0,0,1,14.906,4.3a3.088,3.088,0,1,1,6.173,0A3.138,3.138,0,0,1,17.993,7.481Z" fill={color} />
      </g>
    </svg>
  )
}

import styled from "styled-components";

export const LoginContainerStyled = styled.div`
  width: 100%;
  min-height: calc(100% - 30px);
  padding: 15px 0;
  background-image: url(/assets/images/backgrounds/bg_login.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .logo_show {
    display: flex;
    align-items: center;
    margin-right: 245px;
    .img_logo {
      width: 544px;
    }
  }
  .form_layout {
    // margin: 0 112px;
    max-width: 540px;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
    }
    .form_layout {
      margin-top: 40px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 432px;
      }
    }
    .form_layout {
      margin-top: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 338px;
      }
    }
    .form_layout {
      margin-top: 20px;
      max-width: 332px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SMALLMOBILE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 275px;
      }
    }
    .form_layout {
      margin-top: 20px;
      max-width: 284px;
    }
  }
`;

export default function renderIcon({
  width = "20",
  height = "20",
  color = "#002366",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10C12.7614 10 15 7.76142 15 5C15 2.23858 12.7614 0 10 0C7.23858 0 5 2.23858 5 5C5 7.76142 7.23858 10 10 10Z"
        fill={color}
      />
      <path
        d="M10 11C7.34884 11.0027 4.80721 11.8569 2.93255 13.3753C1.0579 14.8938 0.00327956 16.9525 0 19.0999C0 19.3386 0.117079 19.5676 0.325482 19.7364C0.533884 19.9052 0.816539 20 1.11126 20H18.8887C19.1835 20 19.4661 19.9052 19.6745 19.7364C19.8829 19.5676 20 19.3386 20 19.0999C19.9967 16.9525 18.9421 14.8938 17.0674 13.3753C15.1928 11.8569 12.6512 11.0027 10 11Z"
        fill={color}
      />
    </svg>
  );
}

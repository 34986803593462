import styled from 'styled-components';

export const TotalViewStyled = styled.div`
  .top_view {
    // margin-bottom: 40px;
    display: flex;
    align-items: center;
    .show_icon {
      margin-right: 16px;
      display: flex;
    }
  }
  .show_detail {
    display: flex;
    justify-content: center;
    .graph_show {
      margin-right: 57px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .num_show {
        color: ${({ theme }) => theme.COLORS.BLUE_8};
        font-size:  ${({ theme }) => theme.FONT.SIZE.S77};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        .label_show {
          font-size:  ${({ theme }) => theme.FONT.SIZE.S21};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
      }
    }
    .state_show {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .group_line {
        display: flex;
        font-size:  ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        align-items: center;
        &.mb {
          margin-bottom: 32px;
        }
        .show_svg {

        }
        .s_value_show {
          margin-left: 15px;
          margin-right: 7px;
        }
        .label_show {
          
        }
        .s_percent_show {
          color: ${({ theme }) => theme.COLORS.BLACK_1}50;
        }
      }
    }
  }
`;

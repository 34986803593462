import styled from 'styled-components';

export const AddMissionFormStyled = styled.div`
  .top_add_mission {
    display: flex;
    margin-bottom: 60px;
    .left_add {
      width: 35%;
      min-width: 414px;
      flex-shrink: 0;
    }
    .right_add {
      width: 65%;
      margin-left: 32px;
      .right_add_top {
        display: flex;
        justify-content: center;
        margin-bottom: 13px;
        .show_icon {
          display: flex;
          margin-right: 15px;
        }
      }
      .right_add_bottom {
        .box_input {
          margin-bottom: 13px;
        }
        .two_label {
          margin-bottom: 12px;
          .two_title {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
          }
          .two_sub {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
        }
        .box_input2 {
          margin-bottom: 13px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.flex_start {
            align-items: flex-start;
          }
          .left_box {
            width: 45%;
          }
          .right_box {
            width: 45%;
          }
          .between_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_bold, noto_sans_bold;
            &.pt_9 {
              padding-top: 9px;
            }
          }
          &.no_margin {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .mid_add_mission {
    margin-bottom: 46px;
    display: flex;
    .box_mid {
      width: 50%;
      .title_show {
        display: flex;
        margin-bottom: 17px;
        .show_icon {
          margin-right: 4px;
          display: flex;
        }
      }
      .box_input_bottom {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_box {
          width: 48%;
          display: flex;
          .icon_svg {
            display: flex;
            margin-right: 13px;
          }
          .box_label {
            // padding: 10px 12px;
            // border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
            // border-radius: 10px;
            // box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
        .right_box {
          width: 48%;
        }
        .between_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: noto_sans_thai_bold, noto_sans_bold;
        }
        &.no_margin {
          margin-bottom: 0px;
        }
      }
      &.left {
        padding-right: 24px;
      }
      &.right {
        padding-left: 24px;
      }
    }
  }
  .bottom_add_mission {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    .show_svg {
      display: flex;
      width: 50%;
      justify-content: center;
    }
    .btn_action {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .error {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.RED_1};
        margin-bottom: 5px;
      }
      .body_btn {
        width: 45%;
      }
      .body_btn_2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .box_2 {
          width: 47%;
        }
      }
    }
  }
`;

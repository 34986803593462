import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BarPercent2Styled } from './styled';

const BarPercent2 = ({
  theme_standard_bar_percent2,
  theme_normal_bar_percent2,
  theme_normal_bar_percent3,
  svgFront,
  svgBack,
  leftValue,
  leftValuePercent,
  rightValue,
  rightValuePercent,
}) => {
  const customClass = cx({
    theme_standard_bar_percent2: theme_standard_bar_percent2,
    theme_normal_bar_percent2: theme_normal_bar_percent2,
    theme_normal_bar_percent3: theme_normal_bar_percent3,
  });
  return (
    <BarPercent2Styled>
      <div className={customClass}>
        <div className="top_bar">
          {svgFront && <div className="svg">{svgFront}</div>}
          <div className="line_bar">
            <div className="left_bar" style={{ width: `${leftValuePercent}%` }} />
          </div>
          {svgBack && <div className="svg">{svgBack}</div>}
        </div>
        <div className="bot_bar">
          <div className="group_label">
            <div>{leftValue} คน</div>
            <div className="highlight">({(Number(leftValuePercent) === 100.00 || Number(leftValuePercent) === 0.00) ? Number(leftValuePercent).toFixed(0) : leftValuePercent} %)</div>
          </div>
          <div className="group_label lr">
            <div>{rightValue} คน</div>
            <div className="highlight">({(Number(rightValuePercent) === 100.00 || Number(rightValuePercent) === 0.00) ? Number(rightValuePercent).toFixed(0) : rightValuePercent} %)</div>
          </div>
        </div>
      </div>
    </BarPercent2Styled>
  );
};

BarPercent2.propTypes = {};

export default BarPercent2;

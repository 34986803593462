export default function renderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="94.057"
      viewBox="0 0 112 94.057"
    >
      <g
        id="download_1_"
        data-name="download (1)"
        transform="translate(0 -41.013)"
      >
        <g
          id="Group_57441"
          data-name="Group 57441"
          transform="translate(0 41.013)"
        >
          <g
            id="Group_57440"
            data-name="Group 57440"
            transform="translate(0 0)"
          >
            <g id="Group_57437" data-name="Group 57437">
              <path
                id="Path_59340"
                data-name="Path 59340"
                d="M100.758,47.2a6.19,6.19,0,0,0-6.189-6.19H6.19A6.19,6.19,0,0,0,0,47.2V73.082H100.758Z"
                transform="translate(0 -41.013)"
                fill="#e49542"
              />
            </g>
            <path
              id="Path_59341"
              data-name="Path 59341"
              d="M432.835,47.2V73.082h12.481V47.2a6.19,6.19,0,0,0-6.19-6.19H426.645a6.19,6.19,0,0,1,6.19,6.19Z"
              transform="translate(-333.317 -41.013)"
              fill="#e28424"
            />
            <g
              id="Group_57439"
              data-name="Group 57439"
              transform="translate(0 11.105)"
            >
              <g
                id="Group_57438"
                data-name="Group 57438"
                transform="translate(0 0)"
              >
                <path
                  id="Path_59342"
                  data-name="Path 59342"
                  d="M78.31,96.118a7.527,7.527,0,0,1,6.82-4.341h19.343A7.527,7.527,0,0,1,112,99.3v69.235a6.19,6.19,0,0,1-6.19,6.19H6.19A6.19,6.19,0,0,1,0,168.539V107.986a7.527,7.527,0,0,1,7.527-7.527H71.49A7.528,7.528,0,0,0,78.31,96.118Z"
                  transform="translate(0 -91.777)"
                  fill="#f7ecad"
                />
              </g>
            </g>
            <path
              id="Path_59343"
              data-name="Path 59343"
              d="M100.758,168.539V99.3a7.527,7.527,0,0,0-7.527-7.527h-8.1a7.527,7.527,0,0,0-6.82,4.341,7.527,7.527,0,0,1-6.82,4.341H7.527A7.527,7.527,0,0,0,0,107.986v60.553a6.19,6.19,0,0,0,6.19,6.19H94.568a6.19,6.19,0,0,0,6.19-6.19Z"
              transform="translate(0 -80.672)"
              fill="#ffd064"
            />
          </g>
          <path
            id="Path_59344"
            data-name="Path 59344"
            d="M433.013,91.777H420.532a7.527,7.527,0,0,1,7.527,7.527v69.235a6.19,6.19,0,0,1-6.19,6.19h12.482a6.19,6.19,0,0,0,6.19-6.19V99.3a7.528,7.528,0,0,0-7.527-7.527Z"
            transform="translate(-328.541 -80.672)"
            fill="#ffc250"
          />
          <path
            id="Path_59345"
            data-name="Path 59345"
            d="M176.618,207.052a27.227,27.227,0,0,0-19.79-26.189,26.55,26.55,0,0,0,0,52.378,27.227,27.227,0,0,0,19.79-26.189Z"
            transform="translate(-105.188 -150.271)"
            fill="#fff"
          />
          <path
            id="Path_59346"
            data-name="Path 59346"
            d="M234.294,179.226a26.6,26.6,0,0,0-6.078.7,26.554,26.554,0,0,1,0,51.688,26.548,26.548,0,1,0,6.078-52.392Z"
            transform="translate(-178.294 -148.992)"
            fill="#eaf2ff"
          />
          <path
            id="Path_59347"
            data-name="Path 59347"
            d="M205.454,250.945V240.533a1.66,1.66,0,0,0-1.66-1.66h-8.811a1.66,1.66,0,0,0-1.66,1.66l0,10.411a1.66,1.66,0,0,1-1.66,1.66h-6.461a1.66,1.66,0,0,0-1.095,2.907L198.3,267.964a1.66,1.66,0,0,0,2.19,0l14.187-12.453a1.66,1.66,0,0,0-1.095-2.907h-6.466a1.659,1.659,0,0,1-1.66-1.659Z"
            transform="translate(-143.393 -195.591)"
            fill="#77ce8e"
          />
        </g>
      </g>
    </svg>
  );
}

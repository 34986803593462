export default function renderIcon({
  color = "#009078",
  width = "19.2",
  height = "24",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.2 24"
    >
      <path
        id="Path_37099"
        data-name="Path 37099"
        d="M21.8,23.2H20.6V2.209A1.406,1.406,0,0,0,19.2.8h-1V.4a.4.4,0,0,0-.4-.4H7.4A.4.4,0,0,0,7,.4V.8H6A1.406,1.406,0,0,0,4.6,2.209V23.2H3.4a.4.4,0,1,0,0,.8h7.2a.4.4,0,0,0,.4-.4V16.8h3.2v6.8a.4.4,0,0,0,.4.4h7.2a.4.4,0,1,0,0-.8ZM9.4,19.6A.4.4,0,0,1,9,20H6.6a.4.4,0,0,1-.4-.4V16.4a.4.4,0,0,1,.4-.4H9a.4.4,0,0,1,.4.4Zm0-6.4a.4.4,0,0,1-.4.4H6.6a.4.4,0,0,1-.4-.4V10a.4.4,0,0,1,.4-.4H9a.4.4,0,0,1,.4.4Zm0-6.4a.4.4,0,0,1-.4.4H6.6a.4.4,0,0,1-.4-.4V3.6a.4.4,0,0,1,.4-.4H9a.4.4,0,0,1,.4.4Zm4.8,6.4a.4.4,0,0,1-.4.4H11.4a.4.4,0,0,1-.4-.4V10a.4.4,0,0,1,.4-.4h2.4a.4.4,0,0,1,.4.4Zm0-6.4a.4.4,0,0,1-.4.4H11.4a.4.4,0,0,1-.4-.4V3.6a.4.4,0,0,1,.4-.4h2.4a.4.4,0,0,1,.4.4ZM19,19.6a.4.4,0,0,1-.4.4H16.2a.4.4,0,0,1-.4-.4V16.4a.4.4,0,0,1,.4-.4h2.4a.4.4,0,0,1,.4.4Zm0-6.4a.4.4,0,0,1-.4.4H16.2a.4.4,0,0,1-.4-.4V10a.4.4,0,0,1,.4-.4h2.4a.4.4,0,0,1,.4.4Zm0-6.4a.4.4,0,0,1-.4.4H16.2a.4.4,0,0,1-.4-.4V3.6a.4.4,0,0,1,.4-.4h2.4a.4.4,0,0,1,.4.4Z"
        transform="translate(-3)"
        fill={color}
      />
    </svg>
  );
}

export default function Food5() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45.025" height="45.025" viewBox="0 0 45.025 45.025">
      <g id="_05-cook" data-name="05-cook" transform="translate(-0.998 -0.998)">
        <path id="Path_20581" data-name="Path 20581" d="M1,60H46.024v2.179H1Z" transform="translate(-0.001 -16.155)" fill="#da684e" />
        <path id="Path_20582" data-name="Path 20582" d="M9,27H42.4v2.9H9Z" transform="translate(-2.191 -7.12)" fill="#da684e" />
        <path id="Path_20583" data-name="Path 20583" d="M7.631,35v2.9H6.353a1.452,1.452,0,0,1-1.3-.806L4,35Z" transform="translate(-0.822 -9.31)" fill="#423e35" />
        <path id="Path_20584" data-name="Path 20584" d="M58.631,35l-1.053,2.1a1.452,1.452,0,0,1-1.3.806H55V35Z" transform="translate(-14.786 -9.31)" fill="#423e35" />
        <path id="Path_20585" data-name="Path 20585" d="M6.81,31v2.9H1.726A.726.726,0,0,1,1,33.179V31.726A.726.726,0,0,1,1.726,31Z" transform="translate(-0.001 -8.215)" fill="#a0968c" />
        <path id="Path_20586" data-name="Path 20586" d="M60.81,31.726v1.452a.726.726,0,0,1-.726.726H55V31h5.083A.726.726,0,0,1,60.81,31.726Z" transform="translate(-14.786 -8.215)" fill="#a0968c" />
        <path id="Path_20587" data-name="Path 20587" d="M42.4,46.976a2.9,2.9,0,0,1-2.556,2.883,2.9,2.9,0,0,0,.378-1.431c0-1.6-.726-4.357-1.452-4.357s-4.357,3.478-4.357,5.083a2.272,2.272,0,0,0,.1.7l-.1.029h-6.2a2.9,2.9,0,0,0,.392-1.452c0-1.6-2.179-4.357-2.9-4.357s-2.9,2.752-2.9,4.357a2.9,2.9,0,0,0,.392,1.452h-6.2l-.1-.029a2.272,2.272,0,0,0,.1-.7c0-1.6-3.631-5.083-4.357-5.083s-1.452,2.752-1.452,4.357a2.9,2.9,0,0,0,.377,1.43A2.9,2.9,0,0,1,9,46.976V31H42.4Z" transform="translate(-2.191 -8.215)" fill="#e8e4dc" />
        <path id="Path_20588" data-name="Path 20588" d="M43.845,57.5H5.37a.344.344,0,0,0-.37.312v1.5h.74V58.122h7.248v1.193h.726V58.122h10.53v1.193h.726V58.122H35.5v1.193h.726V58.122h7.248v1.193h.74v-1.5a.344.344,0,0,0-.37-.312Z" transform="translate(-1.096 -15.47)" fill="#635d50" />
        <path id="Path_20589" data-name="Path 20589" d="M2.452,60A1.452,1.452,0,0,0,3.9,61.452H43.119A1.452,1.452,0,0,0,44.571,60h1.452v2.179H1V60Z" transform="translate(-0.001 -16.155)" fill="#85392b" />
        <path id="Path_20590" data-name="Path 20590" d="M60.083,32.452h-2.9a.726.726,0,0,1-.726-.726V31H55v2.9h5.083a.726.726,0,0,0,.726-.726V31.726A.726.726,0,0,1,60.083,32.452Z" transform="translate(-14.786 -8.215)" fill="#80786a" />
        <path id="Path_20591" data-name="Path 20591" d="M55,35v2.9h.726v-.726a1.452,1.452,0,0,1,1.452-1.452h1.089L58.631,35Z" transform="translate(-14.786 -9.31)" fill="#292721" />
        <path id="Path_20592" data-name="Path 20592" d="M1.726,32.452h2.9a.726.726,0,0,0,.726-.726V31H6.81v2.9H1.726A.726.726,0,0,1,1,33.179V31.726a.726.726,0,0,0,.726.726Z" transform="translate(-0.001 -8.215)" fill="#80786a" />
        <path id="Path_20593" data-name="Path 20593" d="M7.631,35v2.9H6.9v-.726a1.452,1.452,0,0,0-1.452-1.452H4.363L4,35Z" transform="translate(-0.822 -9.31)" fill="#292721" />
        <path id="Path_20594" data-name="Path 20594" d="M9,27h2.179a1.452,1.452,0,0,0,1.452,1.452H38.774A1.452,1.452,0,0,0,40.226,27H42.4v2.9H9Z" transform="translate(-2.191 -7.12)" fill="#bd4c3d" />
        <path id="Path_20595" data-name="Path 20595" d="M37.3,54.974c-.492,0-.977-.025-1.458-.069,0,.023.006.047.006.069a2.9,2.9,0,0,1-.392,1.452h6.2l.1-.029a2.272,2.272,0,0,1-.1-.7,1.955,1.955,0,0,1,.179-.726Z" transform="translate(-9.436 -14.76)" fill="#d6cec1" />
        <path id="Path_20596" data-name="Path 20596" d="M52.584,54.452h-.726a2.9,2.9,0,0,1-.377,1.43A2.9,2.9,0,0,0,54.036,53,1.452,1.452,0,0,1,52.584,54.452Z" transform="translate(-13.823 -14.239)" fill="#d6cec1" />
        <path id="Path_20597" data-name="Path 20597" d="M14.083,31H9V46.976a2.9,2.9,0,0,0,2.556,2.883,2.9,2.9,0,0,1-.378-1.431c0-1.6.726-4.357,1.452-4.357s4.357,3.478,4.357,5.083a2.272,2.272,0,0,1-.1.7l.1.029h6.2a2.9,2.9,0,0,1-.392-1.452,3.756,3.756,0,0,1,.436-1.53,16.042,16.042,0,0,1-7.526-7.425,4.365,4.365,0,0,1,3.909-6.3H42.4V31Z" transform="translate(-2.191 -8.215)" fill="#d6cec1" />
        <path id="Path_20598" data-name="Path 20598" d="M49.432,54.788A2.91,2.91,0,0,1,46.9,56.262c-1.343,0-2.476-.407-2.8-1.481a2.272,2.272,0,0,1-.1-.7C44,52.478,47.631,49,48.357,49s1.452,2.752,1.452,4.357a2.9,2.9,0,0,1-.377,1.43Z" transform="translate(-11.774 -13.143)" fill="#d84f27" />
        <path id="Path_20599" data-name="Path 20599" d="M33.417,54.81A2.9,2.9,0,0,1,28,53.357C28,51.752,30.179,49,30.9,49s2.9,2.752,2.9,4.357A2.9,2.9,0,0,1,33.417,54.81Z" transform="translate(-7.393 -13.143)" fill="#d84f27" />
        <path id="Path_20600" data-name="Path 20600" d="M12.378,54.788A2.9,2.9,0,0,1,12,53.357c0-1.6.726-4.357,1.452-4.357s4.357,3.478,4.357,5.083a2.272,2.272,0,0,1-.1.7c-.327,1.075-1.46,1.481-2.8,1.481A2.91,2.91,0,0,1,12.378,54.788Z" transform="translate(-3.012 -13.143)" fill="#d84f27" />
        <rect id="Rectangle_18222" data-name="Rectangle 18222" width="22" height="1" rx="0.5" transform="translate(9.998 20)" fill="#ea735c" />
        <rect id="Rectangle_18223" data-name="Rectangle 18223" width="4" height="1" rx="0.5" transform="translate(32.998 20)" fill="#ea735c" />
        <path id="Path_20601" data-name="Path 20601" d="M52.452,40A1.452,1.452,0,0,0,51,41.452v5.921a8.757,8.757,0,0,1,.518,1.718,1.445,1.445,0,0,0,2.386-1.1V41.452A1.452,1.452,0,0,0,52.452,40Z" transform="translate(-13.691 -10.679)" fill="#f7f3eb" />
        <rect id="Rectangle_18224" data-name="Rectangle 18224" width="2" height="4" rx="1" transform="translate(37.998 24)" fill="#f7f3eb" />
        <path id="Path_20602" data-name="Path 20602" d="M33.067,55.648a2.106,2.106,0,0,1-3.769,0,2.437,2.437,0,0,1-.3-1.162C29,53.2,30.634,51,31.179,51s2.179,2.2,2.179,3.486a2.437,2.437,0,0,1-.29,1.162Z" transform="translate(-7.667 -13.691)" fill="#65adaf" />
        <path id="Path_20603" data-name="Path 20603" d="M32.886,56.486a1.851,1.851,0,0,1-3.141,0,1.688,1.688,0,0,1-.245-.871A3.783,3.783,0,0,1,31.315,53a3.783,3.783,0,0,1,1.815,2.614A1.688,1.688,0,0,1,32.886,56.486Z" transform="translate(-7.804 -14.239)" fill="#a3c7b9" />
        <path id="Path_20604" data-name="Path 20604" d="M49.067,55.648a2.106,2.106,0,0,1-3.769,0,2.437,2.437,0,0,1-.3-1.162C45,53.2,48.086,51,48.631,51s.726,2.2.726,3.486A2.437,2.437,0,0,1,49.067,55.648Z" transform="translate(-12.048 -13.691)" fill="#65adaf" />
        <path id="Path_20605" data-name="Path 20605" d="M48.879,56.486a1.845,1.845,0,0,1-3.134,0,1.688,1.688,0,0,1-.245-.871c0-.963,2.808-2.614,3.261-2.614s.363,1.651.363,2.614A1.688,1.688,0,0,1,48.879,56.486Z" transform="translate(-12.185 -14.239)" fill="#a3c7b9" />
        <path id="Path_20606" data-name="Path 20606" d="M13.294,55.648a2.106,2.106,0,0,0,3.769,0,2.437,2.437,0,0,0,.294-1.162C17.357,53.2,14.271,51,13.726,51S13,53.2,13,54.486A2.437,2.437,0,0,0,13.294,55.648Z" transform="translate(-3.286 -13.691)" fill="#65adaf" />
        <path id="Path_20607" data-name="Path 20607" d="M13.752,56.486a1.845,1.845,0,0,0,3.134,0,1.688,1.688,0,0,0,.245-.871c0-.963-2.808-2.614-3.261-2.614s-.363,1.651-.363,2.614A1.688,1.688,0,0,0,13.752,56.486Z" transform="translate(-3.423 -14.239)" fill="#a3c7b9" />
        <path id="Path_20608" data-name="Path 20608" d="M32.533,57.324a1.055,1.055,0,0,1-1.888,0,1.222,1.222,0,0,1-.145-.581A2.524,2.524,0,0,1,31.589,55a2.524,2.524,0,0,1,1.089,1.743A1.222,1.222,0,0,1,32.533,57.324Z" transform="translate(-8.078 -14.786)" fill="#d7dbbe" />
        <path id="Path_20609" data-name="Path 20609" d="M48.528,57.324a1.052,1.052,0,0,1-1.881,0,1.222,1.222,0,0,1-.145-.581C46.5,56.1,48.187,55,48.463,55s.218,1.1.218,1.743A1.222,1.222,0,0,1,48.528,57.324Z" transform="translate(-12.459 -14.786)" fill="#d7dbbe" />
        <path id="Path_20610" data-name="Path 20610" d="M14.65,57.324a1.052,1.052,0,0,0,1.881,0,1.222,1.222,0,0,0,.145-.581c0-.642-1.685-1.743-1.957-1.743s-.218,1.1-.218,1.743A1.222,1.222,0,0,0,14.65,57.324Z" transform="translate(-3.696 -14.786)" fill="#d7dbbe" />
        <path id="Path_20611" data-name="Path 20611" d="M29.81,21.9v.726H24V21.9a2.9,2.9,0,1,1,5.81,0Z" transform="translate(-6.298 -4.929)" fill="#e84655" />
        <path id="Path_20612" data-name="Path 20612" d="M26.9,19A2.9,2.9,0,0,0,24,21.9v.726a2.9,2.9,0,1,1,5.81,0V21.9A2.9,2.9,0,0,0,26.9,19Z" transform="translate(-6.298 -4.929)" fill="#ff6976" />
        <path id="Path_20613" data-name="Path 20613" d="M45.909,2.9a3.1,3.1,0,0,1,0,4.366l-.545.545L41,3.444l.545-.545a3.1,3.1,0,0,1,4.364,0Z" transform="translate(-10.953 -0.274)" fill="#ffd669" />
        <path id="Path_20614" data-name="Path 20614" d="M37,18.357C37,14,41.357,14,41.357,14,41.357,18.357,37,18.357,37,18.357Z" transform="translate(-9.858 -3.56)" fill="#869f3c" />
        <path id="Path_20615" data-name="Path 20615" d="M33.357,5.357S29,5.357,29,1C29,1,33.357,1,33.357,5.357Z" transform="translate(-7.667 -0.001)" fill="#869f3c" />
        <path id="Path_20616" data-name="Path 20616" d="M56.373,1.225a1.343,1.343,0,0,1,.421,1.794L49.039,16.258c.24.036,1.4.378,1.4,3.624H41c.6-3.631,4.219-4.234,5.417-4.335l8.039-13.87a1.354,1.354,0,0,1,1.917-.45Z" transform="translate(-10.953 -0.001)" fill="#da684e" />
        <path id="Path_20617" data-name="Path 20617" d="M3.477,3.477a1.452,1.452,0,1,1,2.054,0L7.477,5.423A2.332,2.332,0,0,0,6.04,6.04a2.332,2.332,0,0,0-.617,1.437L3.477,5.532a1.452,1.452,0,1,1,0-2.054Z" transform="translate(0 0)" fill="#ede9e1" />
        <path id="Path_20618" data-name="Path 20618" d="M9.137,7.083c2.157-.225,5.43,1.336,6.767,2.672A4.348,4.348,0,1,1,9.754,15.9c-1.335-1.335-2.9-4.61-2.672-6.767A2.332,2.332,0,0,1,7.7,7.7a2.332,2.332,0,0,1,1.437-.617Z" transform="translate(-1.66 -1.66)" fill="#fad5c3" />
        <g id="Group_14502" data-name="Group 14502" transform="translate(17.882 6.628)">
          <ellipse id="Ellipse_1851" data-name="Ellipse 1851" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(5.116 0.372)" fill="#70695b" />
          <ellipse id="Ellipse_1852" data-name="Ellipse 1852" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(5.116 2.372)" fill="#70695b" />
          <ellipse id="Ellipse_1853" data-name="Ellipse 1853" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(2.116 0.372)" fill="#70695b" />
          <ellipse id="Ellipse_1854" data-name="Ellipse 1854" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(2.116 2.372)" fill="#70695b" />
          <ellipse id="Ellipse_1855" data-name="Ellipse 1855" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(0.116 0.372)" fill="#70695b" />
          <ellipse id="Ellipse_1856" data-name="Ellipse 1856" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(0.116 2.372)" fill="#70695b" />
        </g>
        <path id="Path_20619" data-name="Path 20619" d="M45.909,2.9a3.1,3.1,0,0,0-4.364,0L41,3.444l.019.019A3.068,3.068,0,0,1,45.345,7.79l.02.02.545-.545a3.1,3.1,0,0,0,0-4.366Z" transform="translate(-10.953 -0.274)" fill="#ffe399" />
        <path id="Path_20620" data-name="Path 20620" d="M16.629,13.936a4.335,4.335,0,0,1-6.149,0,10.95,10.95,0,0,1-2.687-6.1c-.031.028-.067.049-.1.079a2.332,2.332,0,0,0-.617,1.437c-.225,2.157,1.336,5.43,2.672,6.767a4.337,4.337,0,0,0,7.407-2.823A4.373,4.373,0,0,1,16.629,13.936Z" transform="translate(-1.659 -1.871)" fill="#f3c0a5" />
        <path id="Path_20621" data-name="Path 20621" d="M56.373,1.226a1.337,1.337,0,0,0-.833-.218,1.446,1.446,0,0,1-.052,1.327l-7.572,13.25a.726.726,0,0,0,.306,1.01l.27.134a.9.9,0,0,1-.4,1.7H41.473A5.019,5.019,0,0,0,41,19.882h9.44c0-3.246-1.162-3.587-1.4-3.624L56.795,3.019A1.343,1.343,0,0,0,56.373,1.226Z" transform="translate(-10.953 -0.002)" fill="#bd4c3d" />
        <path id="Path_20622" data-name="Path 20622" d="M6.384,5.1l-.514.514a.726.726,0,0,1-1.027,0L3.477,4.252a1.452,1.452,0,1,0,0,2.054L5.423,8.252A2.332,2.332,0,0,1,6.04,6.814,2.332,2.332,0,0,1,7.477,6.2Z" transform="translate(0 -0.774)" fill="#ded9d0" />
        <path id="Path_20623" data-name="Path 20623" d="M11.015,8.046a1.3,1.3,0,0,0-.813.322.916.916,0,0,0-.128.951,2.158,2.158,0,0,0,.626.789c.762.667,1.67,1.162,2.418,1.839a11.607,11.607,0,0,0,1.752,1.572,1.79,1.79,0,0,0,1.155.281,1.452,1.452,0,0,0,1.041-.8,2.089,2.089,0,0,0,.152-1.316,3.428,3.428,0,0,0-1.063-1.836,6.536,6.536,0,0,0-5.14-1.805Z" transform="translate(-2.464 -1.922)" fill="#fce2d1" />
        <path id="Path_20624" data-name="Path 20624" d="M33.357,5.357,29,1C29,5.357,33.357,5.357,33.357,5.357Z" transform="translate(-7.667 -0.001)" fill="#658f41" />
        <path id="Path_20625" data-name="Path 20625" d="M41.357,14,37,18.357S41.357,18.357,41.357,14Z" transform="translate(-9.858 -3.56)" fill="#658f41" />
        <path id="Path_20626" data-name="Path 20626" d="M47.308,22.064c-.26-.63-1.215-.619-1.743-.5a5.311,5.311,0,0,0-1.9.957A1.312,1.312,0,0,0,43,23.5a1.268,1.268,0,0,0,.9.166l.988-.062a4.639,4.639,0,0,0,1.77-.355,1.38,1.38,0,0,0,.562-.468.8.8,0,0,0,.088-.72Z" transform="translate(-11.501 -5.614)" fill="#ea735c" />
        <ellipse id="Ellipse_1857" data-name="Ellipse 1857" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(13.783 9.115)" fill="#fff3eb" />
        <ellipse id="Ellipse_1858" data-name="Ellipse 1858" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(9.943 12.349) rotate(-45)" fill="#f3c0a5" />
        <ellipse id="Ellipse_1859" data-name="Ellipse 1859" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(7.247 11.294)" fill="#f3ac86" />
        <ellipse id="Ellipse_1860" data-name="Ellipse 1860" cx="0.599" cy="0.834" rx="0.599" ry="0.834" transform="translate(11.801 8.282) rotate(-45)" fill="#fff3eb" />
        <ellipse id="Ellipse_1861" data-name="Ellipse 1861" cx="0.599" cy="0.834" rx="0.599" ry="0.834" transform="translate(8.168 13.365) rotate(-45)" fill="#f3ac86" />
        <ellipse id="Ellipse_1862" data-name="Ellipse 1862" cx="0.599" cy="0.834" rx="0.599" ry="0.834" transform="translate(8.169 11.186) rotate(-45)" fill="#f3c0a5" />
        <ellipse id="Ellipse_1863" data-name="Ellipse 1863" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(20.318 16.377)" fill="#ff858f" />
        <ellipse id="Ellipse_1864" data-name="Ellipse 1864" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(18.698 16.603) rotate(-31.926)" fill="#ff858f" />
        <ellipse id="Ellipse_1865" data-name="Ellipse 1865" cx="0.3" cy="0.417" rx="0.3" ry="0.417" transform="translate(19.424 15.876) rotate(-31.926)" fill="#ff858f" />
        <ellipse id="Ellipse_1866" data-name="Ellipse 1866" cx="0.417" cy="0.3" rx="0.417" ry="0.3" transform="translate(21.603 16.984) rotate(-58.074)" fill="#ff858f" />
        <ellipse id="Ellipse_1867" data-name="Ellipse 1867" cx="0.417" cy="0.3" rx="0.417" ry="0.3" transform="translate(20.876 16.257) rotate(-58.074)" fill="#ff858f" />
        <path id="Path_20627" data-name="Path 20627" d="M24.726,9.863a.715.715,0,0,1-.615-.363A.709.709,0,0,0,24,9.863a.726.726,0,1,0,1.452,0,.709.709,0,0,0-.111-.363A.715.715,0,0,1,24.726,9.863Z" transform="translate(-6.298 -2.328)" fill="#38352d" />
        <path id="Path_20628" data-name="Path 20628" d="M27.726,9.863a.715.715,0,0,1-.615-.363A.71.71,0,0,0,27,9.863a.726.726,0,1,0,1.452,0,.71.71,0,0,0-.111-.363A.715.715,0,0,1,27.726,9.863Z" transform="translate(-7.12 -2.328)" fill="#38352d" />
        <path id="Path_20629" data-name="Path 20629" d="M30.726,9.863a.715.715,0,0,1-.615-.363A.709.709,0,0,0,30,9.863a.726.726,0,1,0,1.452,0,.71.71,0,0,0-.111-.363.715.715,0,0,1-.615.363Z" transform="translate(-7.941 -2.328)" fill="#38352d" />
        <path id="Path_20630" data-name="Path 20630" d="M24.726,13.863a.715.715,0,0,1-.615-.363.709.709,0,0,0-.111.363.726.726,0,0,0,1.452,0,.709.709,0,0,0-.111-.363.715.715,0,0,1-.615.363Z" transform="translate(-6.298 -3.423)" fill="#38352d" />
        <path id="Path_20631" data-name="Path 20631" d="M27.726,13.863a.715.715,0,0,1-.615-.363.71.71,0,0,0-.111.363.726.726,0,0,0,1.452,0,.71.71,0,0,0-.111-.363.715.715,0,0,1-.615.363Z" transform="translate(-7.12 -3.423)" fill="#38352d" />
        <path id="Path_20632" data-name="Path 20632" d="M30.726,13.863a.715.715,0,0,1-.615-.363.709.709,0,0,0-.111.363.726.726,0,0,0,1.452,0,.71.71,0,0,0-.111-.363.715.715,0,0,1-.615.363Z" transform="translate(-7.941 -3.423)" fill="#38352d" />
      </g>
    </svg>

  );
}

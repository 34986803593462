export default function Arrow4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Group_33151" data-name="Group 33151" transform="translate(-1180 -706)">
        <g id="Ellipse_3685" data-name="Ellipse 3685" transform="translate(1200 726) rotate(-180)" fill="#575D96" strokeWidth="1">
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.5" fill="none" />
        </g>
        <g id="_025-left-arrow-2" data-name="025-left-arrow-2" transform="translate(1192.438 720.255) rotate(-180)">
          <g id="Group_2243" data-name="Group 2243" transform="translate(0 0)">
            <path id="Path_5484" data-name="Path 5484" d="M1.827,4.63,5.29,1.167a.507.507,0,0,0,0-.716l-.3-.3a.507.507,0,0,0-.716,0L.148,4.27a.511.511,0,0,0,0,.719L4.266,9.107a.507.507,0,0,0,.716,0l.3-.3a.507.507,0,0,0,0-.716Z" fill="#ffffff" />
          </g>
        </g>
      </g>
    </svg>
  );
}

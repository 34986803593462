import styled from "styled-components";

export const MainDashBoardBMIType2Styled = styled.div`
  .container {
    .top_container {
      display: flex;
      margin-bottom: 35px;
      align-items: baseline;
      .left_top {
        width: 100%;
      }
      .right_top {
      }
    }
    .bottom_container {
      .chart_wrap_container {
        display: flex;
        position: relative;
        justify-content: space-between;
        .item_bar_wrap {
          min-width: 80px;
          // margin: 0 20px;
          z-index: 2;
        }
        .chart_bg {
          position: absolute;
          top: 38px;
          width: 100%;
          .layer {
            display: flex;
            align-items: center;
            padding-left: 56px;
            &.pink {
              background: ${({ theme }) => theme.COLORS.BLUE_15};
            }
            &.pink_dark {
              background: ${({ theme }) => theme.COLORS.BLUE_16};
            }
            &.orange {
              background: ${({ theme }) => theme.COLORS.BLUE_11};
            }
            &.blue {
              background: ${({ theme }) => theme.COLORS.PURPLE_4};
            }
            &.h_40 {
              height: 40px;
            }
            &.h_48 {
              height: 48px;
            }
            &.h_72 {
              height: 72px;
            }
            .txt_box {
              width: 92px;
              text-align: center;
              color: ${({ theme }) => theme.COLORS.BLUE_3};
              .bmi_title {
                font-size: ${({ theme }) => theme.FONT.SIZE.S12};
                font-family: noto_sans_regular;
              }
              .bmi_sub_title {
                font-size: ${({ theme }) => theme.FONT.SIZE.S10};
                font-family: noto_sans_regular;
              }
              &.pd_50 {
                padding-left: 50px;
              }
            }
          }
        }
      }
      .label_empty {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
`;

import React from 'react';
import { HealthyClubCreateEditContainerStyled } from './styled';
import { ROUTE_PATH } from "utils/constants";
import { connect } from 'react-redux';
// import { withRouter } from 'navigations/withRouter';
import { adminService } from 'apiServices';
import { toast } from 'react-toastify';
import { Modals, Blocks, Forms, Loading, BGLoading } from 'components';

class HealthyClubCreateEditContainer extends React.Component {
  state = {
    isShowModal: false,
    isLoading: true,
    isEdit: false,
    labelBox: '',
    labelNotic:
      this.props.match.params.id !== 'create'
        ? {
          label1: 'บันทึกสำเร็จ',
          label2: 'บันทึกไม่สำเร็จ',
        }
        : {
          label1: 'สร้างสำเร็จ',
          label2: 'สร้างไม่สำเร็จ',
        },
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.match.params.id !== 'create') {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_GROUPS_BY_ID(this.props.match.params.id);
    
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: res.data,
        isEdit: true,
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  onSubmit = (values) => {
    // if (this.props.match.params.id === 'create') {
    //   this.setState({
    //     isShowModal: true,
    //     dataValue: values,
    //     labelBox: values.type.value === 'closed' ? 'Closed' : 'Open',
    //   });
    // } else {
    //   this.onSave(values);
    // }
    this.onSave(values);
  };

  onSave = async (values) => {
    const { companySelectedRedux } = this.props;
    const { labelNotic } = this.state;
    this.setState({
      isBgLoading: true
    })
    let params = {
      ...values,
      type: values.type?.value,
      covers: values?.covers ? values?.covers : [],
    };
    let res =
      this.props.match.params.id === 'create'
        ? await adminService.POST_GROUPS(companySelectedRedux.id, params)
        : await adminService.PATCH_GROUPS(this.props.match.params.id, params);
    if (res && res.success) {
      const filesTemp = [
        ...params.covers.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(res.data.id, filesTemp);
      } else {
        toast.success(labelNotic.label1);
        this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB}`);
      }
    } else {
      toast.error(labelNotic.label2);
    }
  };

  handleUploadContentMedia = (group_id, files) => {
    const { labelNotic } = this.state;

    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('type', this.renderType(e.type));
            bodyFormData.append('files[]', e);
            let res = await adminService.POST_UPLOAD_GROUPS_MEDIA(
              group_id,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
          if (e.isDelete) {
            let res = await adminService.DELETE_MEDIA_GROUP(group_id, e.id);
            if (res && res.success) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success(labelNotic.label1);
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB}`);
      // toast.success('สร้างสำเร็จ');
    });
  };

  renderType = (type) => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return 'cover';
      case 'audio/mpeg':
        return 'audio';
      case 'video/mp4':
        return 'video';
      default:
        break;
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
      dataValue: null,
    });
  };

  render() {
    const {
      initialValues,
      isLoading,
      isShowModal,
      dataValue,
      labelBox,
      isEdit,
      isBgLoading,
    } = this.state;
    return (
      <HealthyClubCreateEditContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Forms.AddHealthyClubForm
              initialValues={initialValues}
              onSubmit={this.onSubmit}
              isEdit={isEdit}
            />
          </>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <Blocks.BoxDelete
            theme_box_delete
            showIcon={false}
            textShow="ยืนยันสถานะของกลุ่มหรือไม่"
            labelSub1={`คุณไม่สามารถแก้ไขสถานะได้หลังจากที่คุณเลือกสถานะ ${labelBox} group ไปแล้ว`}
            // labelSub2='สถานะ Open group ไปแล้ว'
            handleConfirm={() => this.onSave(dataValue)}
            handleCancel={this.handleClickCloseModal}
          />
        </Modals.Standard>
      </HealthyClubCreateEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthyClubCreateEditContainer);

import React, { useState, useEffect, forwardRef } from 'react';
import { InputDatePikerStyled } from './styled';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import th from 'date-fns/locale/th';
import moment from 'moment';
import { Icons } from 'components'

const InputDatePiker = ({
  theme_standard,
  theme_date_lucky,
  value,
  onChange,
  onChangeCustom,
  disabled,
  minDate,
  maxDate,
  dateFormat,
  iconColor = '#575D96',
  showYearDropdown,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_date_lucky: theme_date_lucky,
  });

  const [_value, _setValue] = useState();
  const [_onClick] = useState(false);

  useEffect(() => {
    if (value) {
      _setValue(new Date(value));
    }
  }, [value]);

  const handleChangePicker = (e) => {
    // _setValue(e);
    onChange && onChange(e);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type='button' className="custom_date" onClick={onClick} ref={ref}>
      <>
        {moment(_value).add(543, 'years').format('DD MMMM YYYY')}
        <span className="arrow_icon">
          {
            _onClick
              ? <Icons.RectArrowUp color={disabled ? '#989898' : iconColor} />
              : <Icons.RectArrowDown color={disabled ? '#989898' : iconColor} />
          }
        </span>
      </>
    </button>
  ));

  return (
    <InputDatePikerStyled
      disabled={disabled}
    >
      <div className={customClass}>
        <DatePicker
          selected={_value}
          // onChange={handleChangePicker}
          onChange={(e) => {
            onChange && handleChangePicker(e);
            onChangeCustom && onChangeCustom(e);
          }}
          dateFormat={dateFormat ? dateFormat : 'dd/MM/yyyy'}
          minDate={minDate}
          maxDate={maxDate}
          customInput={<ExampleCustomInput />}
          showYearDropdown={showYearDropdown}
          disabled={disabled}
          dropdownMode="select"
        />
      </div>
    </InputDatePikerStyled>
  );
};

InputDatePiker.propTypes = {};

export default InputDatePiker;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { LabelDescripScoreStyled } from "./styled";
import { Icons } from "components";

const LabelDescripScore = ({
  theme_standard,
  txtTitlePrefix,
  txtSubTitlePrefix,
  txtMid,
  txtTitleAffix,
  txtSubTitleAffix,
  txtPercent,
  status,
  isShowIconStatus,
  centerText,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderStatus = (value) => {
    switch (status) {
      case 'up':
        return <div className="red">{value}</div>;

      case 'down':
        return <div className="blue">{value}</div>;

      case 'stable':
        return <div className="gray">{value}</div>;
      default:
        return value;
    }
  };

  const renderIcon = () => {
    switch (status) {
      case 'up':
        return <Icons.ArrowDownCircle width="22" height="22" />;
      case 'down':
        return <Icons.ArrowUpCircle width="22" height="22" />;
      case 'stable':
        return <Icons.DashCircle width="22" height="22" />;
      default:
        return txtMid;
    }
  };

  return (
    <LabelDescripScoreStyled>
      <div className={`container ${customClass}`}>
        {isShowIconStatus && (
          <div className="icon_score_block">
            <div className="icon_wrap">{renderIcon()}</div>
            <div className="txt_percent">{renderStatus(txtPercent)}</div>
          </div>
        )}
        <div className="label_descrip_container">
          <div className="txt_group w44">
            <div className="txt_title">{txtTitlePrefix}</div>
            <div className="txt_sub_title">{txtSubTitlePrefix}</div>
          </div>
          <div
            className="txt_mid"
            style={centerText && { textAlign: "center" }}
          >
            {renderStatus(txtMid)}
          </div>
          <div className="txt_group">
            <div className="txt_title">{txtTitleAffix}</div>
            <div className="txt_sub_title">{txtSubTitleAffix}</div>
          </div>
        </div>
      </div>
    </LabelDescripScoreStyled>
  );
};

LabelDescripScore.propTypes = {};

export default LabelDescripScore;

import { useState, useEffect } from 'react';
import { BurnoutSyndromeByDepartmentStyled } from './styled';
import { Blocks, Typographys, Inputs, Skeleton } from 'components';
import moment from 'moment';

const BurnoutSyndromeByDepartment = ({ data, fetchData }) => {
  const [_data, _setData] = useState();
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);
    if (res) {
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
      _setData();
    }
  };
  const handleChangDate = (e) => {
    _setLoadSkeleton(true);
    fetchDataApi(
      `?stat_date=${moment(e)
        .startOf('months')
        .format('YYYY-MM-DD')}&period=monthly`
    );
  };

  return (
    <BurnoutSyndromeByDepartmentStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="23px"
        paddingRight="20px"
        paddingBottom="23px"
        paddingLeft="20px"
      >
        <div className="bmi_department_head_container">
          <div className="bmi_department_head_col">
            <div className="bmi_group_left">
              <Typographys.TopBottom
                theme_left_align
                warpText
                label="สัดส่วนผู้ที่มีการอาการ Burnout syndrome ในแต่ละแผนก"
                labelBottom="Percentage of employees who have burnout syndrome condition by department"
                fontSizeLabelButtom="14px"
              />
              <div className="chart_status_block">
                <div className="item_status ml_29">
                  <Blocks.BoxWellbeing
                    theme_average_box_well_being
                    label="ไม่มีอาการ"
                    labelBottom="No symptoms"
                    bgColor="#8AC47F"
                    showRound
                  />
                </div>
                <div className="item_status">
                  <Blocks.BoxWellbeing
                    theme_average_box_well_being
                    label="เริ่มรู้สึกหมดไฟ"
                    labelBottom="Starting to feel exhausted"
                    bgColor="#62B4E9"
                    showRound
                  />
                </div>
                <div className="item_status ml_11">
                  <Blocks.BoxWellbeing
                    theme_average_box_well_being
                    label="หมดไฟในการทำงาน"
                    labelBottom="Burnout syndrome"
                    bgColor="#654C96"
                    showRound
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bmi_department_head_col">
            <div className="chart_status_block">
              <div className="item_status ml_29">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="ไม่มีอาการ"
                  labelBottom="No symptoms"
                  bgColor="#8AC47F"
                  showRound
                />
              </div>
              <div className="item_status">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="เริ่มรู้สึกหมดไฟ"
                  labelBottom="Starting to feel exhausted"
                  bgColor="#62B4E9"
                  showRound
                />
              </div>
              <div className="item_status ml_11">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="หมดไฟในการทำงาน"
                  labelBottom="Burnout syndrome"
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
          </div> */}
          <div className="bmi_department_head_col">
            <div className="filter_block">
              <div className="item_filter w_200">
                <Inputs.InputDatePikerCustom
                  theme_standard_date_picker_custom
                  dateFormat="MM/yyyy"
                  onChangeCustom={(e) => handleChangDate(e)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  initialValues={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="chart_container">
          {!_data || _data.length === 0 ? (
            <div className="label_empty">ยังไม่มีข้อมูล</div>
          ) : _loadSkeleton ? (
            <Skeleton.GraphSkeleton height="100px" />
          ) : (
            _data.map((d, h) => (
              <div key={h} className="chart_wrap">
                {d && (
                  <div className="c_row_2_col">
                    {d.map((e, i) => (
                      <div key={i} className="bar_item">
                        <div className="label">{e.name}</div>
                        <div className="percent_bar">
                          {e.data.map((e2, j) => (
                            <div
                              key={j}
                              className="stack_item"
                              style={{
                                width: `${e2.percent}%`,
                                backgroundColor: COLORS_GRAPH[j],
                              }}
                            >
                              {e2.percent !== 0 && `${e2.percent}%`}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Blocks.Box>
    </BurnoutSyndromeByDepartmentStyled>
  );
};

const COLORS_GRAPH = ['#8AC47F', '#62B4E9', '#654C96'];

export default BurnoutSyndromeByDepartment;

export default function ManGraph2() {
  return (
    <svg id="ไอคอน" xmlns="http://www.w3.org/2000/svg" width="27.912" height="50.686" viewBox="0 0 27.912 50.686">
      <g id="Group_155" data-name="Group 155" transform="translate(10.788)">
        <path id="Path_700" data-name="Path 700" d="M77.745,172.732a19.077,19.077,0,0,1,2.1,1.4,21.028,21.028,0,0,0,3.254,2.783,2.163,2.163,0,0,1,1.388,2.681c-.306,1.43-2.32.434-2.743-.051s-1.764-1.9-2.3-2.413a11.365,11.365,0,0,1-1.845-2.013C77.266,174.51,77.745,172.732,77.745,172.732Z" transform="translate(-68.495 -157.632)" fill="#4f4faa" />
        <g id="Group_153" data-name="Group 153">
          <path id="Path_701" data-name="Path 701" d="M63.334,138.985,57.9,131.362l-5.035,13.669,2.618-1.511v26.449l5.236-3.023V140.5Z" transform="translate(-52.862 -131.362)" fill="#ea4e98" />
          <path id="Path_702" data-name="Path 702" d="M60.034,195.779l2.015,1.12V165.79l-2.015-1.12Z" transform="translate(-57.417 -152.512)" fill="#bc0021" />
          <path id="Path_703" data-name="Path 703" d="M68.855,142.053l-5.437-7.623L58.383,148.1,61,146.588V177.7l5.236-3.023v-31.11Z" transform="translate(-56.368 -133.311)" fill="#ed3d26" />
          <path id="Path_704" data-name="Path 704" d="M59.912,132.482l-2.015-1.12-5.035,13.669,2.015,1.12Z" transform="translate(-52.862 -131.362)" fill="#bc0021" />
        </g>
        <g id="Group_154" data-name="Group 154" transform="translate(2.011 15.533)">
          <path id="Path_705" data-name="Path 705" d="M74.313,254.8a7.05,7.05,0,0,1,2.136.336l2,.669-.153,1.5a1.287,1.287,0,0,1-1.693.1,8.534,8.534,0,0,1-2.145-1.517C74.146,255.441,74.028,254.977,74.313,254.8Z" transform="translate(-68.392 -225.254)" fill="#5e2e2e" />
          <path id="Path_706" data-name="Path 706" d="M87.837,246.817a7.05,7.05,0,0,1,2.136.336l2,.669-.153,1.5a1.287,1.287,0,0,1-1.692.1,8.54,8.54,0,0,1-2.145-1.517C87.669,247.459,87.551,247,87.837,246.817Z" transform="translate(-76.979 -220.185)" fill="#5e2e2e" />
          <path id="Path_707" data-name="Path 707" d="M84.85,209.213A4.518,4.518,0,0,1,85.91,212c.064.851-.39,4.48-.39,4.48a19.465,19.465,0,0,1,.872,3.086,36.794,36.794,0,0,1,.155,4.24c-.118.374-.507.728-1.284.671-.8-.059-1.363-1.084-1.363-1.084s-.276-2.326-.537-3.866c-.148-.877-.316-1.458-.4-2.187-.033-.838-.118-2.878-.118-2.878s-.96,3.813-1.263,4.979c.591,1.943.066,7.229.011,7.65-1.185.933-1.914.051-2.4-.714.029-1.175-.159-3.249-.2-4.892-.034-1.3-.013-1.839-.034-2.274-.041-.846.344-3.391.539-5.34.227-2.271.273-3.914.273-3.914Z" transform="translate(-71.439 -196.331)" fill="#7e7edd" />
          <path id="Path_708" data-name="Path 708" d="M80.28,187.9c.018,1.307.25,3.561.224,4.264a43.114,43.114,0,0,1-.6,4.851,4.819,4.819,0,0,0,3.567.843c1.019-.218,2.618-.549,3.218-1.57.007-.2-.961-2.424-.842-3.064a33.629,33.629,0,0,0,.454-4.7,3.936,3.936,0,0,0-.684-2.363,1.238,1.238,0,0,0-1.4-.458,8.622,8.622,0,0,0-2.167.968A1.612,1.612,0,0,0,80.28,187.9Z" transform="translate(-72.043 -181.364)" fill="#a0a0e5" />
          <path id="Path_709" data-name="Path 709" d="M89.539,185.524l-.218-1.215-1.7.4a2.009,2.009,0,0,1,0,1.176A6.138,6.138,0,0,0,89.539,185.524Z" transform="translate(-76.948 -180.517)" fill="#ffad97" />
          <path id="Path_710" data-name="Path 710" d="M87.6,175.551a1.763,1.763,0,0,0-2.873-.439c-1.293,1.208-.784,3.639.168,3.807a2.713,2.713,0,0,0,2.092-.808A2.638,2.638,0,0,0,87.6,175.551Z" transform="translate(-74.616 -174.318)" fill="#ffc5b3" />
          <path id="Path_711" data-name="Path 711" d="M83.627,174.609a1.486,1.486,0,0,0,.421.894,4.364,4.364,0,0,0,1.111.6,3.09,3.09,0,0,1-.073.546.389.389,0,0,0,.111.425s.381-.6.637-.119a.553.553,0,0,1-.2.754,1.973,1.973,0,0,0,.411.612,6.334,6.334,0,0,0,.533.421,7.146,7.146,0,0,1,.766-1.455,2.972,2.972,0,0,0,.626-1.532A1.5,1.5,0,0,0,87,174.319a1.63,1.63,0,0,0-1.836-.2,1.273,1.273,0,0,1-1.091-.057,1.6,1.6,0,0,0,.23.676A4.366,4.366,0,0,1,83.627,174.609Z" transform="translate(-74.409 -173.919)" fill="#5e2e2e" />
          <path id="Path_712" data-name="Path 712" d="M87.088,186.118a1.662,1.662,0,0,0-1.309.592s1.042.622,1.732.335a3.81,3.81,0,0,0,1.065-.632c.178-.228-.208-.714-.208-.714A1.794,1.794,0,0,1,87.088,186.118Z" transform="translate(-75.776 -181.399)" fill="#4f4faa" />
          <path id="Path_713" data-name="Path 713" d="M59.987,177.391a2.138,2.138,0,0,1-.482-.919c-.021-.383-.078-.561-.3-.408s-.026.456,0,.636-.224.308-.224.308v-.664s-.663.535-.6,1.046a.967.967,0,0,0,.8.792,6.974,6.974,0,0,1,.676.221Z" transform="translate(-58.372 -175.246)" fill="#ffc5b3" />
          <path id="Path_714" data-name="Path 714" d="M62.663,179.7a3.687,3.687,0,0,0-1.108-.412c-.419.287-.749.623-.421,1.125a3.571,3.571,0,0,0,.926.669Z" transform="translate(-60.041 -177.325)" fill="#fff" />
          <path id="Path_715" data-name="Path 715" d="M62.536,179.726c-.563-.024-.944.493-.891,1.391.032.532,2.065,2.343,2.837,3.1a30.823,30.823,0,0,0,4.426,2.758c.8.358,1.022-.4,1.2-.782a4.976,4.976,0,0,0,.053-2.856c-.376-.517-1.149-.51-1.676-.536a8.911,8.911,0,0,1-2.623-.615C65.05,181.278,63.124,179.751,62.536,179.726Z" transform="translate(-60.448 -177.606)" fill="#7e7edd" />
        </g>
      </g>
      <g id="Group_156" data-name="Group 156" transform="translate(0 18.572)">
        <path id="Path_716" data-name="Path 716" d="M30.479,234.389l2.015,1.12V216.672l-2.015-1.12Z" transform="translate(-27.861 -203.395)" fill="#ffb240" />
        <path id="Path_717" data-name="Path 717" d="M39.3,192.936l-5.437-7.623-5.035,13.669,2.618-1.511v18.837l5.236-3.023V194.447Z" transform="translate(-26.813 -184.193)" fill="#ffc943" />
        <path id="Path_718" data-name="Path 718" d="M30.356,183.364l-2.015-1.12-5.035,13.669,2.015,1.12Z" transform="translate(-23.307 -182.244)" fill="#ffdc5a" />
      </g>
    </svg>

  );
}

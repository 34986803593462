import React from 'react';
import { WellLifeContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { Loading, Typographys, Icons } from 'components';
import { adminService } from 'apiServices';
import {
  MainDashBoardInvitraceWellBeing,
  DdcTopDepartmentPuiAndRisk,
  TotalStep,
} from 'widgets';
import millify from 'millify';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import moment from 'moment';
import momentTZ from 'moment-timezone';

class WellLifeContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchAllUserStatistics(),
      this.fetchAllStepStatistics(),
      this.fetchStatisticsWellbeing(),
      this.fetchAllMsdStatistics(),
      this.fetchMsdBehaviourStatistics(
        `?stat_date=${momentTZ()
          .tz('Asia/Bangkok')
          .subtract(7, 'days')
          .startOf('week')
          .add(1, 'days')
          .format('YYYY-MM-DD')}&period=weekly`
      ),
    ]);
    this.setState({
      isLoading: false,
    });
    if (res) {
      this.setState({
        allUserStatisticsData: res[0],
        allStepStatistics: res[1],
        statisticsWellbeingData: res[2],
        allMsdStatisticsData: res[3],
        msdBehaviourStatistics: res[4],
      });
    }
  };

  fetchAllMsdStatistics = async () => {
    let res = await adminService.GET_MSD_ALL_STATISTICS(
      `?stat_date=${moment()
        .startOf('week')
        .isoWeekday(1)
        .format('YYYY-MM-DD')}`
    );

    if (res && res.success) {
      return res.data;
    }
  };

  fetchAllUserStatistics = async () => {
    let res = await adminService.GET_USER_STATISTICS();

    if (res && res.success) {
      return res.data;
    }
  };

  fetchAllStepStatistics = async () => {
    let res = await adminService.GET_ALL_STATISTICS_STEP();
    if (res && res.success) {
      return res.data;
    }
  };

  fetchStatisticsWellbeing = async () => {
    let res = await adminService.GET_STATISTICS_WELLBEING();

    if (res && res.success) {
      return res.data;
    }
  };

  fetchMsdBehaviourStatistics = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_ALL_MSD_COMPANY_SUMMARY(stringQuery);
    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.company.name,
        norisk_user_count: e.norisk_user_count,
        pui_user_count: e.pui_user_count,
        user_count: e.user_count,
      }));
      data.sort((a, b) => b.user_count - a.user_count);
      return {
        data: data,
        startDate:
          initialStartDate ||
          momentTZ()
            .tz('Asia/Bangkok')
            .subtract(7, 'days')
            .startOf('week')
            .add(1, 'days')
            .format('YYYY-MM-DD'),
      };
    }
  };

  render() {
    const {
      isLoading,
      allUserStatisticsData,
      statisticsWellbeingData,
      allStepStatistics,
      allMsdStatisticsData,
      msdBehaviourStatistics,
    } = this.state;

    return (
      <WellLifeContainerStyled>
        {isLoading ? (
          <div className="loading_layout">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="block_2_col_layout" >
              <div className="col_2 w_368">
                <div className="b2c_block_wrap mb_24">
                  <Typographys.IconTitleSubTxt
                    svg={<Icons.Build />}
                    theme_flex_col
                    title="จำนวนบริษัททั้งหมด"
                    subTitle="Total number of companies"
                    score={
                      allUserStatisticsData &&
                      millify(Number(allUserStatisticsData.total_company_count))
                    }
                    suffixLabelScore="บริษัท"
                  />
                </div>
                <div className="b2c_block_wrap">
                  <Typographys.IconTitleSubTxt
                    svg={<Icons.NumberOfUse />}
                    theme_flex_col
                    title="สัดส่วนผู้ใช้"
                    subTitle="Number of User"
                    score={
                      allUserStatisticsData &&
                      millify(Number(allUserStatisticsData.total_user_count))
                    }
                    suffixLabelScore="คน"
                  />
                </div>
              </div>
              <div className="col_2 flex_1 mw_780" >
                <div className="wrap_mr5_overflow">
                  <MainDashBoardInvitraceWellBeing
                    data={statisticsWellbeingData && statisticsWellbeingData}
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div className="block_2_col_layout">
              <div className="col_2 w_368">
                <div className="b2c_block_wrap h100">
                  <TotalStep
                    title="ก้าวสะสม"
                    suffixLabelScore="ก้าว"
                    data={
                      allStepStatistics && allStepStatistics
                    }
                  />
                </div>
              </div>
              <div className="col_2 flex_1 mw_780" >
                <div className="obj_four">
                  <div className="obj_four mb">
                    <div className="obj_show">
                      <Typographys.IconTitleSubTxt
                        svg={<Icons.ManMsd />}
                        title="จำนวนพนักงานที่ตอบคำถาม MSD สัปดาห์ที่แล้ว"
                        subTitle="Number of employees answering the MSD questions last week"
                        // prefixLabelScore="รวม 3 เดือนล่าสุด"
                        score={
                          allMsdStatisticsData &&
                          RenderCommaMoney(
                            Number(allMsdStatisticsData.last_week_msd_user_count),
                            true
                          )
                        }
                        suffixLabelScore="คน"
                        height="156px"
                      />
                    </div>
                    <div className="obj_show">
                      <Typographys.IconTitleSubTxt
                        svg={<Icons.ManMsd />}
                        title="จำนวนพนักงานที่ตอบคำถาม MSD ไตรมาสปัจจุบัน"
                        subTitle="Number of employees answering the MSD questions this quarter"
                        // prefixLabelScore="รวม 3 เดือนล่าสุด"
                        score={
                          allMsdStatisticsData &&
                          RenderCommaMoney(
                            Number(allMsdStatisticsData.current_quarter_msd_user_count),
                            true
                          )
                        }
                        suffixLabelScore="คน"
                        height="156px"
                      />
                    </div>
                  </div>
                  <div className="obj_four">
                    <div className="obj_show">
                      <Typographys.IconTitleSubTxt
                        svg={
                          <Icons.MSD
                            color="#0F8989"
                            width="25.61"
                            height="32.93"
                          />
                        }
                        title="จำนวนพนักงานมีอาการเจ็บแต่ไม่มีความเสี่ยงสัปดาห์ที่แล้ว"
                        subTitle="Number of employees who are pain without risk last week"
                        score={
                          allMsdStatisticsData &&
                            allMsdStatisticsData.last_week_norisk_count > 0
                            ? RenderCommaMoney(
                              Number(allMsdStatisticsData.last_week_norisk_count),
                              true
                            )
                            : '0'
                        }
                        suffixLabelScore="คน"
                        bottomColor="#6FB5CB"
                      // height='157px'
                      />
                    </div>
                    <div className="obj_show">
                      <Typographys.IconTitleSubTxt
                        svg={
                          <Icons.MSD
                            color="#654C96"
                            width="25.61"
                            height="32.93"
                          />
                        }
                        theme_standard_new
                        height={`calc(100% - 32px)`}
                        title="จำนวนพนักงานที่มีความเสี่ยง PUI สัปดาห์ที่แล้ว"
                        subTitle="Number of employees who are at PUI risk last week"
                        score={
                          allMsdStatisticsData &&
                            allMsdStatisticsData.last_week_pui_count > 0
                            ? RenderCommaMoney(
                              Number(allMsdStatisticsData.last_week_pui_count),
                              true
                            )
                            : '0'
                        }
                        suffixLabelScore="คน"
                        bottomColor="#654C96"
                      // height='157px'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_chart_layout">
              <DdcTopDepartmentPuiAndRisk
                data={msdBehaviourStatistics && msdBehaviourStatistics}
                label="7 อันดับบริษัทที่พนักงานมีความเสี่ยง PUI และมีเจ็บ แต่ไม่มีความเสี่ยงสูงสุด"
                labelBottom="Top 7 company which employees are at PUI risk and pain without risk"
                fetchData={this.fetchMsdBehaviourStatistics}
              />
            </div>
          </>
        )}
      </WellLifeContainerStyled>
    );
  }
}

export default WellLifeContainer;

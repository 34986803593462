import React from "react";
import { CurrentDWBStatContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards, Typographys, Icons } from "components";
import { adminService } from "apiServices";
import { connect } from "react-redux";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { DwbRisk } from "widgets";
import moment from "moment";

class CurrentDWBStatContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchDWBAllCompany(),
      this.fetchDWBASummaryCompanyList(
        `?stat_date=${moment().startOf("quarter").format("YYYY-MM-DD")}`
      ),
    ]);

    if (res) {
      this.setState({
        dwbAllCompanyData: res[0],
        dwbSummaryCompanyListData: res[1],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchDWBAllCompany = async () => {
    let res = await adminService.GET_DWB_STATISTICS_ALL_COMPANY();
    if (res && res.success) {
      return res.data;
    }
  };

  fetchDWBASummaryCompanyList = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_DWB_SUMMARY_COMPANY_LIST(stringQuery);
    if (res && res.success) {
      let tempData = res.data.map((e) => ({
        name: e.company.name,
        risk_user_count: e.risk_user_count,
      }));

      return {
        data: tempData,
        startDate:
          initialStartDate || moment().startOf("quarter").format("YYYY-MM-DD"),
      };
    }
  };

  render() {
    const { isLoading, dwbSummaryCompanyListData, dwbAllCompanyData } =
      this.state;
    return (
      <CurrentDWBStatContainerStyled>
        {isLoading ? (
          <div className="loading_layout">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="section_introduce_layout">
              <Cards.BoxIntroduce
                src="/assets/images/logo/logo_dbg.svg"
                titleLabel="Dangerous work behavior"
                label1="พฤติกรรมการทำงานที่มีความเสี่ยงจะทำให้พนักงานเกิดอาการบาดเจ็บและนำไปสู่โรค MSD จึงควรมีการการเฝ้าระวังและติดตามอาการบาดเจ็บของระบบกล้ามเนื้อและโครงกระดูกในการปฏิบัติงานของพนักงานเพื่อให้พนักงานมีสุขภาพร่างกายที่ดียิ่งขึ้น"
              />
            </div>
            <div className="block_2_col_layout">
              <div className="col_2 w_368">
                <div className="b2c_block_wrap mb_24">
                  <Typographys.IconTitleSubTxt
                    theme_big_text
                    bottomColor="#2A74A3"
                    svg={<Icons.Build />}
                    title="จำนวนพนักงานที่่ทำแบบทดสอบ"
                    subTitle="Total number of companies"
                    score={
                      dwbAllCompanyData &&
                      Number(dwbAllCompanyData.total_risk_user_count) > 0
                        ? RenderCommaMoney(
                            Number(dwbAllCompanyData.total_risk_user_count),
                            true
                          )
                        : "0"
                    }
                    suffixLabelScore="คน"
                  />
                </div>
                <div className="b2c_block_wrap">
                  <Typographys.IconTitleSubTxt
                    theme_big_text
                    bottomColor="#2A74A3"
                    svg={<Icons.NumberOfUse />}
                    title="จำนวนพนักงานที่ตอบแบบสอบถามล่าสุดสัปดาห์ที่แล้ว"
                    score={
                      dwbAllCompanyData &&
                      Number(dwbAllCompanyData.quarter_user_count) > 0
                        ? RenderCommaMoney(
                            Number(dwbAllCompanyData.quarter_user_count),
                            true
                          )
                        : "0"
                    }
                    suffixLabelScore="คน"
                  />
                </div>
              </div>
              <div className="col_2 flex_1 mw_780">
                <div className="wrap_mr5_overflow">
                  <DwbRisk
                    data={
                      dwbSummaryCompanyListData && dwbSummaryCompanyListData
                    }
                    labelTop="5 อันดับบริษัทที่พนักงานมีความเสี่ยง DWB สูงสุด"
                    labelSub="Top 5 companies of DWB risk’s employees"
                    fetchData={this.fetchDWBASummaryCompanyList}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </CurrentDWBStatContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentDWBStatContainer);

// export default CurrentDWBStatContainer;

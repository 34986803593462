import React from "react";
import { BackOfficeContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { renderConfigSystemFN } from "utils/functions/renderCofigSystem";
import { Typographys, Cards, Icons, Loading } from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { getObjectKey2, getObjectKey3 } from "utils/functions/getObjectKey";

class BackOfficeContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.checkPermission();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickMenu = (e) => {
    this.props.history.push(e);
  };

  checkPermission = () => {
    const { authenRedux } = this.props;
    
    let checkPermissionReward = getObjectKey2(
      authenRedux.admin_role.permissions,
      "reward_manage",
      "reward_view"
    );
    let checkPermissionContent = getObjectKey2(
      authenRedux.admin_role.permissions,
      "content_manage",
      "content_view"
    );
    let checkPermissionCompanyMission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "company_mission_manage",
      "company_mission_view"
    );
    // let checkPermissionEmployee = getObjectKey2(authenRedux.admin_role.permissions, 'employee_manage', 'employee_view')
    let checkPermissionEmployee = getObjectKey3(
      authenRedux.admin_role.permissions,
      "employee_manage",
      "employee_view",
      "employee_manage_no_delete"
    );
    let checkPermissionGroup = getObjectKey2(
      authenRedux.admin_role.permissions,
      "group_manage",
      "group_view"
    );
    let checkPermissionAdmin = getObjectKey3(
      authenRedux.admin_role.permissions,
      "admin_manage",
      "admin_view",
      "admin_manage_no_delete"
    );
    let checkPermissionPoint = getObjectKey2(
      authenRedux.admin_role.permissions,
      "user_coin_manage",
      "user_coin_view"
    );
    let checkPermissionSpin = getObjectKey2(
      authenRedux.admin_role.permissions,
      "spin_manage",
      "spin_view"
    );

    this.setState({
      permissionReward: checkPermissionReward,
      permissionContent: checkPermissionContent,
      permissionCompanyMission: checkPermissionCompanyMission,
      permissionEmployee: checkPermissionEmployee,
      permissionGroup: checkPermissionGroup,
      permissionAdmin: checkPermissionAdmin,
      permissionPoint: checkPermissionPoint,
      permissionSpin: checkPermissionSpin,
      isLoading: false,
    });
  };

  render() {
    const {
      isLoading,
      permissionReward,
      permissionContent,
      permissionCompanyMission,
      permissionEmployee,
      permissionGroup,
      permissionAdmin,
      permissionPoint,
      permissionSpin,
    } = this.state;
    const { authenRedux, companySelectedRedux } = this.props;

    return (
      <BackOfficeContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Typographys.LabelSubVertical
              theme_standard
              title="จัดการหลังบ้าน"
              subTitle="Back office"
            />
            <div className="card_container">
              {companySelectedRedux.isParent === 0 && (
                <>
                  {permissionReward &&
                    permissionReward.permission_group &&
                    permissionReward.permission_group.key === "reward" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_REWARD)
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการรางวัล"
                          subTitle="Rewards Management"
                          svg={<Icons.RewardManage />}
                        />
                      </div>
                    )}
                  {permissionContent &&
                    permissionContent.permission_group &&
                    permissionContent.permission_group.key === "content" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_NEW)
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการข่าวสาร"
                          subTitle="News Management"
                          svg={<Icons.NewManagement />}
                        />
                      </div>
                    )}
                  {permissionCompanyMission &&
                    permissionCompanyMission.permission_group &&
                    permissionCompanyMission.permission_group.key ===
                      "company_mission" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_MISSION)
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการภารกิจ"
                          subTitle="Company Mission"
                          svg={<Icons.CompanyMission />}
                        />
                      </div>
                    )}
                  {permissionEmployee &&
                    permissionEmployee.permission_group &&
                    permissionEmployee.permission_group.key === "employee" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(
                            ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT
                          )
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการบัญชีผู้ใช้"
                          subTitle="User Management"
                          svg={<Icons.UserLogo />}
                        />
                      </div>
                    )}
                  {permissionGroup &&
                    permissionGroup.permission_group &&
                    permissionGroup.permission_group.key === "group" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(
                            ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB
                          )
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการกลุ่ม"
                          subTitle="Group Management"
                          svg={<Icons.BackOfficeUserLogo />}
                        />
                      </div>
                    )}
                </>
              )}
              {authenRedux &&
                renderConfigSystemFN(authenRedux)
                  ?.enableAccountManagementOnBackoffice &&
                permissionAdmin &&
                permissionAdmin.permission_group &&
                permissionAdmin.permission_group.key === "admin" && (
                  <div
                    className="card_wrap"
                    onClick={() =>
                      this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_ACCOUNT)
                    }
                  >
                    <Cards.BoxIconTitleSub
                      title="จัดการบัญชีผู้ดูแลระบบ"
                      subTitle="Account Management"
                      svg={<Icons.AccountManagement />}
                    />
                  </div>
                )}
              {companySelectedRedux.isParent === 0 && (
                <>
                  {permissionPoint &&
                    permissionPoint.permission_group &&
                    permissionPoint.permission_group.key === "user_coin" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(
                            ROUTE_PATH.BACK_OFFICE_REWARD_POINT
                          )
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="คะแนนสะสมรายเดือน"
                          subTitle="Reward points information"
                          svg={<Icons.RewardPoint />}
                        />
                      </div>
                    )}
                  {permissionPoint &&
                    permissionPoint.permission_group &&
                    permissionPoint.permission_group.key === "user_coin" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_POINT)
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="คะแนน"
                          subTitle="Points"
                          svg={<Icons.Points />}
                        />
                      </div>
                    )}
                  {permissionSpin &&
                    permissionSpin.permission_group &&
                    permissionSpin.permission_group.key === "spin" && (
                      <div
                        className="card_wrap"
                        onClick={() =>
                          this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_LUCK_SPIN)
                        }
                      >
                        <Cards.BoxIconTitleSub
                          title="จัดการวงล้อ"
                          subTitle="Lucky Spin Management"
                          svg={<Icons.LuckSpin />}
                        />
                      </div>
                    )}
                </>
              )}
            </div>
          </>
        )}
      </BackOfficeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfficeContainer);

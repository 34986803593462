import styled from 'styled-components';

export const HealthyClubCreateEditContainerStyled = styled.div`
    .show_loading {
        height: calc(100vh - 85px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

import React from "react";
import { connect } from "react-redux";
import { DepartmentCreateEditContainerStyled } from "./styled";
import { Forms, Loading } from "components";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants";

class DepartmentCreateEditContainer extends React.Component {
  state = {
    isLoading: true,
    labelNotic:
      this.props.match.params.id !== "create"
        ? {
            label1: "บันทึกสำเร็จ",
            label2: "บันทึกไม่สำเร็จ",
          }
        : {
            label1: "สร้างสำเร็จ",
            label2: "สร้างไม่สำเร็จ",
          },
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.match.params.id !== "create") {
      this.fetchData();
    } else {
      this.fetchGoal();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_CONTENT_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: res.data,
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  fetchGoal = async () => {
    let res = await adminService.GET_GOALS();
    if (res && res.success) {
      this.setState({
        goalData: res.data.step.step_daily_target,
        isLoading: false,
      });
    }

    //rai
    this.setState({
      isLoading: false,
    });
  };

  onSubmit = async (values) => {
    const { labelNotic } = this.state;
    const { companySelectedRedux } = this.props;
    let params = {
      ...values,
      category: values.category?.value,
      is_draft: values.is_draft?.value === "true" ? true : false,
      type: values.type?.value,
      files: values?.files ? values?.files : [],
      reward_select: values?.reward_select?.value,
      reward_select_2: values?.reward_select_2?.value,
    };
    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_CREATE_CONTENTS(
            companySelectedRedux?.id,
            params
          )
        : await adminService.PATCH_CONTENT_UPDATE(
            this.props.match.params.id,
            params
          );
    if (res && res.success) {
      const filesTemp = [
        ...values.file_cover.filter((e) => e.isNewUpload || e.isDelete),
        ...params.files.filter((e) => e.isNewUpload || e.isDelete),
        ...values.file_feature.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(res.data.id, filesTemp);
      } else {
        toast.success(labelNotic.label1);
        this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
      }
    } else {
      toast.error(labelNotic.label2);
    }
  };

  handleUploadContentMedia = (contentID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set(
              "type",
              e.fieldName === "file_feature"
                ? "feature"
                : this.renderType(e.type)
            );
            bodyFormData.append("files[]", e);
            let res = await adminService.POST_UPLOAD_CONTENT_MEDIA(
              contentID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
          if (e.isDelete) {
            let res = await adminService.DELETE_MEDIA_CONTENT(contentID, e.id);
            if (res && res.success) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success("สร้างสำเร็จ");
      this.fetchData();
    });
  };

  renderType = (type) => {
    switch (type) {
      case "image/jpeg":
      case "image/png":
        return "cover";
      case "audio/mpeg":
        return "audio";
      case "video/mp4":
        return "video";
      default:
        break;
    }
  };

  render() {
    const { companySelectedRedux } = this.props;
    const { initialValues, isLoading, goalData } = this.state;
    return (
      <DepartmentCreateEditContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Forms.AddArticleForm
              initialValues={initialValues}
              companyID={companySelectedRedux?.id}
              goalData={goalData}
              onSubmit={this.onSubmit}
            />
          </>
        )}
      </DepartmentCreateEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentCreateEditContainer);

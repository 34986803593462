import styled from 'styled-components'

export const BoxNoticStyled = styled.div`
  .theme_box_notic {
    .notic_box {
      width: 400px;
      border-radius: 5px;
      // border: 1px solid #F0211D;
      padding: 22px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title_box {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color:  ${({ theme }) => theme.COLORS.BLACK_8};
        margin-bottom: 20px;
        align-items: baseline;
        .icon {
          display: flex;
          margin-left: 7px;
        }
        .group_label {
          .t_label {
            text-align: center;
          }
          .b_label {
            color:  ${({ theme }) => theme.COLORS.BLACK_8};
            text-align: center;
          }
        }
      }
      .action_btn {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .body_btn {
          width: 100%;
        }
      }
    }
  }
`

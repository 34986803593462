import React from "react";
import cx from "classnames";
import { PreviewMediaStyled } from "./styled";
import { PlayAudio, ShowImage, PlayVideo } from "widgets";

const PreviewMedia = ({
  theme_standard,
  type,
  src,

  name,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderTypePreview = () => {
    switch (type) {
      case "cover":
        return <ShowImage url={src} name={name} />;
      case "audio":
        return <PlayAudio url={src} name={name} />;
      case "video":
        return <PlayVideo url={src} name={name} />;
      default:
        break;
    }
  };

  return (
    <PreviewMediaStyled theme_standard={theme_standard}>
      <div className={customClass}>
        <div className="preview_container">{renderTypePreview()}</div>
      </div>
    </PreviewMediaStyled>
  );
};

PreviewMedia.propTypes = {};

export default PreviewMedia;

import React, { useEffect } from "react";
import { PointAddDeleteFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Icons, Buttons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const PointAddDeleteForm = ({
  onSubmit,
  actionType,
  handleCancel,
  selectedData,
  totalUser,
}) => {
  const schema = yup.object().shape({
    point: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Point is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // reset,
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchAllFields = watch();
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLabel = (e) => {
    let stringShow = "ให้กับ";
    if (totalUser > 1) {
      stringShow = "";
    }

    switch (e) {
      case "add_point":
        return "กรุณากรอกคะแนนที่คุณต้องการเพิ่ม" + stringShow;

      case "del_point":
        return "กรุณากรอกคะแนนที่คุณต้องการลบ" + stringShow;

      default:
        break;
    }
  };

  return (
    <PointAddDeleteFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tile_point">
          <div className="svg_point">
            {actionType === "add_point" || actionType === "add_point_all" ? (
              <Icons.RoundAdd color="#fff" bgColor="#21A526" />
            ) : (
              <Icons.RoundDel />
            )}
          </div>
          <div className="label">{renderLabel(actionType)}</div>
        </div>
        <div className="name_list">
          {totalUser && totalUser > 1 ? (
            <div className="show_name">
              <div>
                {actionType === "add_point" || actionType === "add_point_all"
                  ? "จำนวนคนที่เพิ่มคะแนน"
                  : "จำนวนคนที่ลบคะแนน"}
              </div>
              <div className="hText">{`${RenderCommaMoney(totalUser)} คน`}</div>
            </div>
          ) : (
            selectedData.length === 1 && (
              <div className="show_name detail">
                <div className="w100">{`${selectedData[0].code}`}</div>
                <div className="w300">
                  {`${selectedData[0].firstname} ${selectedData[0].lastname}`}
                </div>
                <div className="w200">
                  {`${selectedData[0].department.name}`}
                </div>
              </div>
            )
          )}
        </div>
        <div className="input_zone">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputText
                theme_add_point
                placeholder={
                  actionType === "add_point" || actionType === "add_point_all"
                    ? "กรอกคะแนนที่คุณต้องการเพิ่ม"
                    : "กรอกคะแนนที่คุณต้องการลบ"
                }
                type="number"
                min={1}
                max={9999}
                {...field}
                errors={errors.point?.message}
              />
            )}
            name="point"
            defaultValue=""
          />
        </div>
        <div className="btn_zone">
          <div className="box_width">
            <Buttons.BtnNormal
              theme_only_border
              label="ยกเลิก"
              fontSize="18px"
              fontFamily="noto_sans_regular, noto_sans_thai_thin"
              padding="10px"
              borderRadius="8px"
              backgroundColor="#fff"
              fontColor="#0B408D"
              borderColor="#0B408D"
              onClick={handleCancel}
            />
          </div>
          <div className="box_width">
            <Buttons.BtnNormal
              theme_only_border
              label="บันทึก"
              fontSize="18px"
              fontFamily="noto_sans_bold, noto_sans_thai_bold"
              padding="10px"
              borderRadius="8px"
              backgroundColor="#0B408D"
              borderColor="#575D96"
              fontColor="#fff"
              type="submit"
            />
          </div>
        </div>
      </form>
    </PointAddDeleteFormStyled>
  );
};

PointAddDeleteForm.propTypes = {};

export default PointAddDeleteForm;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    body {
      margin: 0;
      height: 100%;
      position: relative;
      font-family: noto_sans_thai_regular, noto_sans_regular;
    }
     button {
      border: none; 
      &:focus {
        outline: none;
      }
    }
    input {
      &:focus {
        outline: none;
      }
    }
  }
`;

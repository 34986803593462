import styled from 'styled-components';

export const PointManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  min-height: calc(100vh - 202px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  .layer_one {
    display: flex;
  }
  .layer_two {
    margin-top: 41px;
    display: flex;
    justify-content: space-between;
    &.align_end {
      align-items: flex-end;
    }
    .search_layer {
      width: 100%;
      &.half_width {
        width: 50%;
      }
      .sub_label {
        color: #3E3E3E;
        margin-bottom: 14px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
    
    .btn_layer {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      &.w100 {
        column-gap: 11px;
        width: 100%;
      }
      .body_btn {
        width: 30%;
      }
      .min_132 {
        min-width: 132px;
      }
    }
  }
  .layer_table {
    margin-top: 24px;
  }
  .action_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group_svg {
      display: flex;
      align-items: center;
      column-gap: 11px;
      .svg_btn {
        cursor: pointer;
        display: flex;
      }
    }
  }

  .confirm_box {
    min-width: 448px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 28px 24px;
    border-radius: 5px;
    .top_cf {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      .group_top {
        display: flex;
        justify-content: center;
        column-gap: 15px;
        color: ${({ theme }) => theme.COLORS.BLACK_8};
        .g_text {
          font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
          color: #233077;
        }
      }
      .text_center {
        text-align: center;
      }
    }
    .point_show {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 13px;
      column-gap: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      .svg_show {
        display: flex;
      }
      .detail_show {
        display: flex;
        column-gap: 36px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        .group_svg_point {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 15px;
          .svg_action {
            display: flex;
          }
          .add_point {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
          }
        }
      }
    }
    .btn_do {
      margin-top: 20px;
      justify-content: center;
      column-gap: 32px;
      display: flex;
      .btn_w184 {
        width: 184px;
      }
    }
  }

  .error_box {
    max-width: 500px;
    min-width: 345px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 28px 24px;
    border-radius: 5px;
    .msg {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_6};
      .text_group {
        display: flex;
        justify-content: center;
        text-align: center;
        column-gap: 5px;
        .htext_show {
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
    }
    .btn_accept {
      margin-top: 14px;
    }
  }
  

  // @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
  //   .layer_two {
      
  //   }
  //   .header_col  {
  //     &.showHead {
  //       width: 50px;
  //       max-width: 50px;
  //     }
  //     &.showStatus {
  //       width: 60px;
  //       max-width: 60px;
  //     }
  //   } 
  //   .body_col  {
  //     &.showHead {
  //       width: 50px;
  //       max-width: 50px;
  //     }
  //     &.showStatus {
  //       width: 60px;
  //       max-width: 60px;
        
  //     }
  //   } 
  //   .showName {
  //     width: 50px;
  //     max-width: 50px;
  //   }
  // }
  // @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
  //   .layer_two {
       
  //   }
  // }
`;

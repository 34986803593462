import React from 'react';
import { AccountManagementContainerStyled } from './styled';
import {
  Tabs,
  TabBarSelect,
  Buttons,
  Icons,
  TableCustom,
  Loading,
  Blocks,
  Inputs,
} from 'components';
import { ROUTE_PATH } from 'utils/constants';
import { connect } from 'react-redux';
import { adminService } from 'apiServices';

class AccountManagementContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData(`?per_page=10&offset=0`);
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0`);
    }
  }

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_ADMIN_LIST_INVITRACE(stringQuery);
    if (res && res.success) {
      this.setState({
        isLoading: false,
        data: res.data,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickCreate = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_DETAIL}/create`);
  };

  handleClickDetail = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_DETAIL}/${e.id}`);
  };

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.fetchData(query);
  };

  // FILTER PROCESS
  //==========================================
  //==========================================
  handleSelectedFilter = (key, e) => {
    switch (key) {
      case 'categoryFilter':
        this.setState(
          {
            categoryFilter: `department_ids=${e
              .filter((e) => e !== 'all')
              .join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case 'keywordFilter':
        this.setState(
          {
            keywordFilter: !e ? false : `keyword=${e}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleJoinQuery = () => {
    const { categoryFilter, keywordFilter } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join('&')}`);
      this.setState({
        queryFilterAll: `${queryString.join('&')}`,
      });
    } else {
      this.fetchData();
      this.setState({
        queryFilterAll: false,
      });
    }
  };

  render() {
    const { isLoading, data, pagination } = this.state;

    return (
      <AccountManagementContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_only_title
                  title="จัดการบัญชี"
                  subTitle="Administration"
                  fontSize="34px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#092945"
                  colorBottom="#092945"
                  lineHeight="1.3"
                />
              </div>
              <div className="layer_two">
                <div className="tab_show">
                  <TabBarSelect
                    tab_standard_tab_bar_select
                    tabHeader={headerTab}
                    fontFamilyTop="noto_sans_thai_bold, noto_sans_bold"
                    fontSizeTop="16px"
                    // onClick={this.handleClickCreate}
                  />
                </div>
                <div className="button_show">
                  <div className="search_layer">
                    <Inputs.InputSearch
                      theme_standard_search
                      onSubmit={(e) =>
                        this.handleSelectedFilter('keywordFilter', e)
                      }
                    />
                  </div>
                  <div className="btn">
                    <Buttons.BtnRoundShadow
                      theme_btn_round_shadow_pink
                      label="Create New"
                      svg={<Icons.Add />}
                      fontFamily="noto_sans_thai_medium, noto_sans_regular"
                      onClick={this.handleClickCreate}
                    />
                  </div>
                </div>
                <div className="table_show">
                  <TableCustom.TableRole
                    theme_standard_table_role
                    pagination={pagination}
                    showPagination
                    data={data}
                    onClick={this.handleClickDetail}
                    handlePageClick={this.handlePageClick}
                    haveAdminRole
                  />
                </div>
              </div>
            </Blocks.Container>
          </>
        )}
      </AccountManagementContainerStyled>
    );
  }
}

const headerTab = [
  {
    label: 'Admin',
    // component: <CurrentMissionContainer />,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountManagementContainer);

export default function Popular2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="163.081" viewBox="0 0 168 163.081">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-459 -1260.919)">
        <g id="data-analysis" transform="translate(459 1223.681)">
          <path id="Path_20964" data-name="Path 20964" d="M112.456,220.271a5.8,5.8,0,0,1-5.8,5.8H5.8a5.8,5.8,0,0,1-5.8-5.8V84.242l112.456-5.8Z" transform="translate(0 -25.749)" fill="#edebed" />
          <path id="Path_20965" data-name="Path 20965" d="M256.466,93.9V229.929a5.8,5.8,0,0,1-5.8,5.8H239.075a5.8,5.8,0,0,0,5.8-5.8V222.2l-31.109-50.818L244.872,93.9Z" transform="translate(-133.575 -35.407)" fill="#dbd9dc" />
          <path id="Path_20966" data-name="Path 20966" d="M112.456,58.493H111.3l-52.17,8.941L11.594,58.493H0V43.035a5.8,5.8,0,0,1,5.8-5.8H106.659a5.8,5.8,0,0,1,5.8,5.8Z" fill="#c3aff0" />
          <path id="Path_20967" data-name="Path 20967" d="M298.63,43.037V58.495H287.037V43.037a5.8,5.8,0,0,0-5.8-5.8h11.594A5.8,5.8,0,0,1,298.63,43.037Z" transform="translate(-175.74 -0.001)" fill="#a69ded" />
          <path id="Path_20968" data-name="Path 20968" d="M130.608,222.2a1.932,1.932,0,0,1-1.932,1.932H32.837A1.932,1.932,0,0,1,30.9,222.2V93.9h99.7Z" transform="translate(-19.312 -35.405)" fill="#fff" />
          <path id="Path_20969" data-name="Path 20969" d="M118.426,156.493H63.743a1.932,1.932,0,0,1-1.932-1.932V126.736a1.932,1.932,0,0,1,1.932-1.932H136.4a1.932,1.932,0,0,1,1.932,1.932v13.526Z" transform="translate(-38.624 -54.718)" fill="#bde4ff" />
          <g id="Group_14561" data-name="Group 14561" transform="translate(20.289 110.47)">
            <path id="Path_20970" data-name="Path 20970" d="M90.025,300.066H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -271.083)" fill="#dbd9dc" />
            <path id="Path_20971" data-name="Path 20971" d="M90.025,269.161H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -251.771)" fill="#dbd9dc" />
            <path id="Path_20972" data-name="Path 20972" d="M93.889,238.255H56.983a2.9,2.9,0,1,1,0-5.8H93.889a2.9,2.9,0,1,1,0,5.8Z" transform="translate(-54.085 -232.459)" fill="#dbd9dc" />
          </g>
          <path id="Path_20973" data-name="Path 20973" d="M56.983,398.58a2.9,2.9,0,0,1-2.9-2.9v-5.8a2.9,2.9,0,1,1,5.8,0v5.8A2.9,2.9,0,0,1,56.983,398.58Z" transform="translate(-33.796 -218.55)" fill="#bde4ff" />
          <path id="Path_20974" data-name="Path 20974" d="M87.889,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,87.889,385.706Z" transform="translate(-53.109 -205.675)" fill="#fc8d94" />
          <path id="Path_20975" data-name="Path 20975" d="M149.7,359.956a2.9,2.9,0,0,1-2.9-2.9V328.074a2.9,2.9,0,1,1,5.8,0v28.984A2.9,2.9,0,0,1,149.7,359.956Z" transform="translate(-91.733 -179.926)" fill="#fc8d94" />
          <path id="Path_20976" data-name="Path 20976" d="M180.605,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,180.605,385.706Z" transform="translate(-111.045 -205.675)" fill="#bde4ff" />
          <path id="Path_20977" data-name="Path 20977" d="M118.794,372.831a2.9,2.9,0,0,1-2.9-2.9V348.678a2.9,2.9,0,1,1,5.8,0v21.255A2.9,2.9,0,0,1,118.794,372.831Z" transform="translate(-72.421 -192.8)" fill="#bde4ff" />
          <path id="Path_20979" data-name="Path 20979" d="M200.563,233.647a49.081,49.081,0,0,1,28.906-83.441c13.533.156,28.912,7.629,39.972,18.689,0,0,22.414,30.049-3.349,62.154,0,0-15.748,16.134-36.615,16.633a48.872,48.872,0,0,1-28.915-14.034Z" transform="translate(-116.345 -70.591)" fill="#ffe07d" />
          <path id="Path_20980" data-name="Path 20980" d="M356.447,198.371a49.1,49.1,0,0,1-54.872,48.738,49.08,49.08,0,0,0,0-97.473,49.075,49.075,0,0,1,54.872,48.735Z" transform="translate(-188.447 -70.02)" fill="#ffd064" />
          <path id="Path_20981" data-name="Path 20981" d="M228.074,244.192a37.485,37.485,0,1,1,53.012,0A37.485,37.485,0,0,1,228.074,244.192Z" transform="translate(-135.657 -89.334)" fill="#fff" />
        </g>
        <g id="_23-samgyeopsal" data-name="23-samgyeopsal" transform="translate(545 1320)">
          <path id="Path_21056" data-name="Path 21056" d="M29,1h6s-3,3-3,10C32,4,29,1,29,1Z" fill="#5f873b" />
          <path id="Path_21057" data-name="Path 21057" d="M17,51A15.332,15.332,0,0,1,32,63C24.4,63,17.98,58.34,17,51Z" fill="#466926" />
          <path id="Path_21058" data-name="Path 21058" d="M32,63A15.332,15.332,0,0,0,17,51L9.17,47.08A7.513,7.513,0,0,1,5,40.34V37A27.017,27.017,0,0,1,32,63Z" fill="#527a2d" />
          <path id="Path_21059" data-name="Path 21059" d="M32,60.68V63A27.016,27.016,0,0,0,5,37V34.12c0-1.86,1.5-6.36,3.36-6.36a2.184,2.184,0,0,0,2.25-1.43l1.48.81A38.207,38.207,0,0,1,32,60.68Z" fill="#466926" />
          <path id="Path_21060" data-name="Path 21060" d="M32,43V60.68A38.207,38.207,0,0,0,12.091,27.14l-1.481-.81a2.2,2.2,0,0,0,.09-.64,3.02,3.02,0,0,0-.2-1.02l-.88-2.36a5.983,5.983,0,0,1,.02-4.29C22.05,18.42,32,30.52,32,43Z" fill="#527a2d" />
          <path id="Path_21061" data-name="Path 21061" d="M32,31V43c0-12.48-9.95-24.58-22.36-24.98a6.043,6.043,0,0,1,2.11-2.7l1.67-1.19a5.267,5.267,0,0,0,1.14-1.12A18,18,0,0,1,32,31Z" fill="#466926" />
          <path id="Path_21062" data-name="Path 21062" d="M32,19V31A18,18,0,0,0,14.561,13.01a6.234,6.234,0,0,0,1.029-2.08A6.866,6.866,0,0,1,17.34,8H18S32,10,32,19Z" fill="#527a2d" />
          <path id="Path_21063" data-name="Path 21063" d="M32,11v1c0-3-3.13-6-7-6H24a2.006,2.006,0,0,0,2-2,3,3,0,0,1,3-3S32,4,32,11Z" fill="#527a2d" />
          <path id="Path_21064" data-name="Path 21064" d="M32,12v7C32,10,18,8,18,8h-.66a6.862,6.862,0,0,1,4.84-2H25C28.87,6,32,9,32,12Z" fill="#5f873b" />
          <path id="Path_21065" data-name="Path 21065" d="M40,6H39c-3.87,0-7,3-7,6V11c0-7,3-10,3-10a3,3,0,0,1,3,3A2.015,2.015,0,0,0,40,6Z" fill="#7bbb40" />
          <path id="Path_21066" data-name="Path 21066" d="M48.45,11.08a5.937,5.937,0,0,0,1.01,1.93A18,18,0,0,0,32,31V19C32,10,46,8,46,8h.61A7.3,7.3,0,0,1,48.45,11.08Z" fill="#7bbb40" />
          <path id="Path_21067" data-name="Path 21067" d="M47,51a13.105,13.105,0,0,1-5.02,8.75A16.157,16.157,0,0,1,32,63,15.332,15.332,0,0,1,47,51Z" fill="#65a229" />
          <path id="Path_21068" data-name="Path 21068" d="M59,37v3.34a7.513,7.513,0,0,1-4.17,6.74L47,51A15.332,15.332,0,0,0,32,63,27.016,27.016,0,0,1,59,37Z" fill="#7bbb40" />
          <path id="Path_21069" data-name="Path 21069" d="M59,34.12V37A27.017,27.017,0,0,0,32,63V60.68A38.207,38.207,0,0,1,51.909,27.14l1.481-.81a2.184,2.184,0,0,0,2.25,1.43c.93,0,1.77,1.12,2.38,2.48A11.171,11.171,0,0,1,59,34.12Z" fill="#65a229" />
          <path id="Path_21070" data-name="Path 21070" d="M54.36,18.02a5.983,5.983,0,0,1,.02,4.29l-.88,2.36a2.614,2.614,0,0,0-.11,1.66l-1.48.81A38.207,38.207,0,0,0,32,60.68V43C32,30.52,41.95,18.42,54.36,18.02Z" fill="#7bbb40" />
          <path id="Path_21071" data-name="Path 21071" d="M52.25,15.32a6.043,6.043,0,0,1,2.11,2.7C41.95,18.42,32,30.52,32,43V31A18,18,0,0,1,49.458,13.01a5.744,5.744,0,0,0,1.252,1.21Z" fill="#65a229" />
          <path id="Path_21072" data-name="Path 21072" d="M46.61,8H46S32,10,32,19V12c0-3,3.13-6,7-6h2.82A6.769,6.769,0,0,1,46.61,8Z" fill="#65a229" />
          <path id="Path_21073" data-name="Path 21073" d="M38.057,14.435a9.01,9.01,0,0,1,1.24,2.111,17.887,17.887,0,0,1,6.187-2.957c-.756-1.167-1.445-2.386-2.3-3.484a10.115,10.115,0,0,0-1.071-1.174A21.161,21.161,0,0,0,35.9,11.947c.046.064.084.133.132.194.617.81,1.388,1.5,2.025,2.294Z" fill="#9ee352" />
          <path id="Path_21074" data-name="Path 21074" d="M43.122,28.553a4.781,4.781,0,0,0,4.81-.478,7.458,7.458,0,0,0,2.748-4.138,6.317,6.317,0,0,0-.118-4.311,5.231,5.231,0,0,0-.585-.963,22.508,22.508,0,0,0-9.594,5.4,5.972,5.972,0,0,0,2.739,4.49Z" fill="#9ee352" />
          <path id="Path_21075" data-name="Path 21075" d="M39.742,17.838c.476,1.9.319,3.887.573,5.824.018.135.045.27.068.405a22.508,22.508,0,0,1,9.594-5.4,37.259,37.259,0,0,0-2.82-2.861,15.749,15.749,0,0,1-1.673-2.213A17.887,17.887,0,0,0,39.3,16.546a9,9,0,0,1,.442,1.292Z" fill="#8ad147" />
          <path id="Path_21076" data-name="Path 21076" d="M38.457,7.049c-1.575-.249-3.3.449-3.447,2.281a4.024,4.024,0,0,0,.885,2.617,21.161,21.161,0,0,1,6.221-3.016,6.953,6.953,0,0,0-3.659-1.882Z" fill="#8ad147" />
          <path id="Path_21077" data-name="Path 21077" d="M40.19,46.539a4.6,4.6,0,0,0,2.679.438A17.529,17.529,0,0,0,47.34,46.1a9.326,9.326,0,0,0,2.1-.969,7.245,7.245,0,0,0,2.748-3.553,13.579,13.579,0,0,0,.75-3.886,26.98,26.98,0,0,0-13.65,7.845,2.392,2.392,0,0,0,.9,1Z" fill="#9ee352" />
          <path id="Path_21078" data-name="Path 21078" d="M52.943,37.69c.01-.151.025-.3.033-.453a5.128,5.128,0,0,0-.529-3.09,6.12,6.12,0,0,0-1.077-1.174c-1.289-1.184-3.1-2.756-5.111-1.525A3.324,3.324,0,0,0,45.014,33.6a13.806,13.806,0,0,1-1.886,4.708A25.373,25.373,0,0,1,40.8,40.8a6.2,6.2,0,0,0-1.735,2.824,2.966,2.966,0,0,0,.225,1.909A26.98,26.98,0,0,1,52.943,37.69Z" fill="#8ad147" />
          <path id="Path_21079" data-name="Path 21079" d="M10.433,40.948a1.761,1.761,0,0,0,.892-.011,1.468,1.468,0,0,0,.6-.439,4.247,4.247,0,0,0,.918-2.341A26.979,26.979,0,0,0,6.4,37.036c.02.087.032.175.057.261a5.269,5.269,0,0,0,3.976,3.651Z" fill="#5f873b" />
          <path id="Path_21080" data-name="Path 21080" d="M13.21,35.591c.322-1.221,1.088-2.37,1.035-3.624S12.8,28.9,11.219,29c-1.475.1-3.128,2.026-3.813,3.085A6.519,6.519,0,0,0,6.4,37.036a26.979,26.979,0,0,1,6.45,1.121,21.648,21.648,0,0,1,.36-2.566Z" fill="#527a2d" />
          <path id="Path_21081" data-name="Path 21081" d="M17.122,13.548a.985.985,0,0,0,.525.357,2.427,2.427,0,0,0,1.611-.106,17.626,17.626,0,0,0-2.232-.524A.693.693,0,0,0,17.122,13.548Z" fill="#5f873b" />
          <path id="Path_21082" data-name="Path 21082" d="M23.394,12.155a10.925,10.925,0,0,0,2.187-.5,2.624,2.624,0,0,0,.983-.72,24.426,24.426,0,0,0-5.486-2.247A12.361,12.361,0,0,0,18.9,10.466a12.5,12.5,0,0,0-1.52,1.69,1.592,1.592,0,0,0-.355,1.119,17.626,17.626,0,0,1,2.232.524,15.656,15.656,0,0,0,1.63-.786,9.664,9.664,0,0,1,2.507-.858Z" fill="#73a63b" />
          <path id="Path_21083" data-name="Path 21083" d="M26.989,10.015c.248-2.266-3.873-2.258-5.157-1.714a6.047,6.047,0,0,0-.754.391,24.426,24.426,0,0,1,5.486,2.247,1.735,1.735,0,0,0,.425-.924Z" fill="#7cb33f" />
          <path id="Path_21084" data-name="Path 21084" d="M39,13.96l-.02.03C35.98,12.01,32,15,32,15H25l1.16-.91a9.721,9.721,0,0,1,6.74-2.06c2.8.22,6.1.73,6.1,1.93Z" fill="#f9eec9" />
          <path id="Path_21085" data-name="Path 21085" d="M39,38.06V48.93a1.981,1.981,0,0,1-.89,1.66L33,54V44l5.28-4.4A1.985,1.985,0,0,0,39,38.06Z" fill="#d7924d" />
          <path id="Path_21086" data-name="Path 21086" d="M33,44V54l-5.79-2.48A2.014,2.014,0,0,1,26,49.68V42l.08-.02a2,2,0,0,0,1.58,1.26Z" fill="#a23b23" />
          <path id="Path_21087" data-name="Path 21087" d="M32,15l1,29-5.34-.76a2,2,0,0,1-1.58-1.26,1.759,1.759,0,0,1-.14-.65L25,15Z" fill="#eea74e" />
          <path id="Path_21088" data-name="Path 21088" d="M33,44,32,15s3.98-2.99,6.98-1.01c.01,0,.01.01.02.01V38.06a1.985,1.985,0,0,1-.72,1.54Z" fill="#ffe4a9" />
          <path id="Path_21089" data-name="Path 21089" d="M34.257,48.794a6.654,6.654,0,0,0,.593-1.161c.264-.927-.289-1.9-.182-2.861a.679.679,0,0,1,.137-.377c.233-.264.655-.121,1-.054a2.463,2.463,0,0,0,2.109-.76A6.174,6.174,0,0,0,39,42.015V38.06a1.985,1.985,0,0,1-.72,1.54L33,44V54l1.239-.827A6.042,6.042,0,0,1,34.257,48.794Z" fill="#c2612d" />
          <path id="Path_21090" data-name="Path 21090" d="M37.111,44.75a6.007,6.007,0,0,0-1.094.9c-.125.121-.261.28-.212.447s.233.224.4.25a2.644,2.644,0,0,0,2.395-.89.825.825,0,0,0,.215-.413c.122-.976-1.294-.535-1.7-.294Z" fill="#c87646" />
          <path id="Path_21091" data-name="Path 21091" d="M37.246,46.875a.742.742,0,0,1,.385.078.256.256,0,0,1,.1.341.364.364,0,0,1-.216.115,7.768,7.768,0,0,1-1.908.244c-.6,0-.67-.43-.116-.619a5.125,5.125,0,0,1,1.755-.159Z" fill="#c87646" />
          <path id="Path_21092" data-name="Path 21092" d="M37.472,48.358a.708.708,0,0,0-.639.081.689.689,0,0,0-.148.206,5.1,5.1,0,0,1-.386.767,2.623,2.623,0,0,1-1.251.7,1.576,1.576,0,0,0-1.068.905.394.394,0,0,0,.01.281.476.476,0,0,0,.412.19,6.518,6.518,0,0,0,3.259-.877c.48-.236.9-.514.838-1.086a1.335,1.335,0,0,0-1.027-1.167Z" fill="#c87646" />
          <path id="Path_21093" data-name="Path 21093" d="M27.66,43.24a2,2,0,0,1-1.58-1.26L26,42v4.257c.321-.264.633-.539.971-.78.246-.176.589-.345.827-.159s.181.63.44.8a.686.686,0,0,0,.387.074l1.626-.008a1.4,1.4,0,0,1,.607.088.475.475,0,0,1,.291.493,1.8,1.8,0,0,1-.2.369c-.143.293-.013.649-.09.967a7.614,7.614,0,0,1-.343.712,1.7,1.7,0,0,0,.631,1.642,3.211,3.211,0,0,1,1.084,1.474.712.712,0,0,1-.11.6.83.83,0,0,1-.744.2A8.407,8.407,0,0,1,28.6,51.407,3.246,3.246,0,0,0,26.528,51a2,2,0,0,0,.682.519L33,54V44Z" fill="#843a2b" />
          <path id="Path_21094" data-name="Path 21094" d="M28.219,48.8a2.48,2.48,0,0,0-1.5.613.439.439,0,0,0-.147.23c-.037.224.227.372.448.429.658.17,2.257.352,2.769-.268.728-.883-1.055-1.045-1.57-1Z" fill="#843a2b" />
          <path id="Path_21095" data-name="Path 21095" d="M28.8,46.98a1.043,1.043,0,0,0-.914.973.223.223,0,0,0,.153.252,1.367,1.367,0,0,0,1.915-1.558,1.288,1.288,0,0,0-1.2.353" fill="#843a2b" />
          <path id="Path_21096" data-name="Path 21096" d="M38.98,13.99C35.98,12.01,32,15,32,15l.08,2.325c.06.038.125.066.183.107a1.937,1.937,0,0,1,1.077-2.193,3.846,3.846,0,0,1,2.853-.3c.786.479,1.172,2.06,1.235,2.893a2.591,2.591,0,0,1-.1,1.037,4.332,4.332,0,0,1-.4.762A9.293,9.293,0,0,0,35.7,23.224c-.147,1.2,1.345,3.252,1.838,4.334a8.158,8.158,0,0,1,.832,2.514A4.148,4.148,0,0,1,37,33.554a.627.627,0,0,0-.145.159c-.149.276.193.544.442.736a2.493,2.493,0,0,1,.86,1.935,7.371,7.371,0,0,1-.381,2.148,1.157,1.157,0,0,1-.392.7c-.358.233-.847.01-1.258.129-.651.187-.878,1.141-1.547,1.248-.461.073-.861-.3-1.158-.665-.208-.252-.4-.516-.592-.783L33,44l5.28-4.4A1.985,1.985,0,0,0,39,38.06V14c-.01,0-.01-.01-.02-.01Z" fill="#f5d384" />
          <path id="Path_21097" data-name="Path 21097" d="M33.624,35.1a1,1,0,0,0,.386,1.044,1.65,1.65,0,0,0,1.124.279c.2-.015.434-.039.554.117.1.131.061.317.087.48.078.48.773.641,1.165.352a1.488,1.488,0,0,0,.477-1.314.38.38,0,0,0-.062-.22.64.64,0,0,0-.226-.134.917.917,0,0,1-.312-1.063c.168-.638.569-2.607-.873-1.78-.42.24-.663.933-1.1,1.252s-1.044.408-1.22.987Z" fill="#f3bb63" />
          <path id="Path_21098" data-name="Path 21098" d="M37.4,21.273c-.22-.147-.492.093-.635.317a6.463,6.463,0,0,0-.5.953c-.112.264-.176.632.067.782a.542.542,0,0,0,.342.051,1.733,1.733,0,0,0,1.489-1.524c.019-.231-.1-.542-.331-.5" fill="#efb242" />
          <path id="Path_21099" data-name="Path 21099" d="M37.226,23.806a.43.43,0,0,0-.206.082.605.605,0,0,0-.1.6.448.448,0,0,0,.218.166,1.5,1.5,0,0,0,.4.113.43.43,0,0,0,.249-.023.463.463,0,0,0,.19-.229c.294-.584-.217-.8-.746-.709Z" fill="#efb242" />
          <path id="Path_21100" data-name="Path 21100" d="M36.83,14.256c-.2.066-.394.265-.312.455a.478.478,0,0,0,.136.158,6.105,6.105,0,0,1,1.124,1.312c.091.144.219.313.388.286s.239-.228.255-.4c.079-.867-.516-2.178-1.591-1.811Z" fill="#efb242" />
          <path id="Path_21101" data-name="Path 21101" d="M35.708,37.94c-.283-.12-.593-.164-.882-.272a5.641,5.641,0,0,1-.883-.481.526.526,0,0,0-.333-.1.474.474,0,0,0-.306.283,1.768,1.768,0,0,0-.191.784.823.823,0,0,0,.048.312.719.719,0,0,0,.184.256,1.628,1.628,0,0,0,.761.362,6.489,6.489,0,0,0,1.57.095c1.017-.024.776-.924.032-1.239Z" fill="#efb242" />
          <g id="Group_14564" data-name="Group 14564">
            <path id="Path_21102" data-name="Path 21102" d="M35.541,30.7a3.509,3.509,0,0,0-.642.346.653.653,0,0,0-.271.636,2.567,2.567,0,0,0,1.162.214,1.936,1.936,0,0,0,1.373-.323,1.964,1.964,0,0,0,.4-1.6c-.063-1.184-1.783.6-2.022.727Z" fill="#fcfdfd" />
            <path id="Path_21103" data-name="Path 21103" d="M37.3,25.273a2.279,2.279,0,0,0-2.039-.472,3.985,3.985,0,0,1-.714.184c-.223.017-.447-.038-.67-.02a.536.536,0,0,0-.51.361.48.48,0,0,0,.31.5,1.441,1.441,0,0,0,.624.072,4.558,4.558,0,0,1,1.63.171c.349.121.666.319,1.011.45.966.363.864-.805.358-1.246Z" fill="#fcfdfd" />
            <path id="Path_21104" data-name="Path 21104" d="M32.58,22.109c.028.038.088.015.127-.011l.583-.386a.788.788,0,0,0,.327-.325.535.535,0,0,0,.034-.214.834.834,0,0,0-.08-.344.269.269,0,0,0-.165-.166.3.3,0,0,0-.235.076,1.915,1.915,0,0,0-.657.661,1.176,1.176,0,0,0,.066.709Z" fill="#fcfdfd" />
            <path id="Path_21105" data-name="Path 21105" d="M33.787,21.071c.025.087.071.185.16.2a.285.285,0,0,0,.2-.082,4.615,4.615,0,0,1,1.6-.612.71.71,0,0,0,.445-.246.729.729,0,0,0,.061-.463.506.506,0,0,0-.061-.24c-.115-.179-.381-.149-.587-.1l-.926.228a1.952,1.952,0,0,0-.6.219.89.89,0,0,0-.292,1.1Z" fill="#fcfdfd" />
            <path id="Path_21106" data-name="Path 21106" d="M36.092,16.443a1.137,1.137,0,0,0-.115.462.085.085,0,0,0,.016.065.079.079,0,0,0,.051.02.963.963,0,0,0,.418-.073.319.319,0,0,0,.146-.081.239.239,0,0,0,.046-.143.487.487,0,0,0-.238-.43.187.187,0,0,0-.127-.03c-.107.024-.121.177-.207.245" fill="#fcfdfd" />
            <path id="Path_21107" data-name="Path 21107" d="M35.237,15.976c-.012.14-.021.28-.029.42-.006.12.008.271.12.315a.291.291,0,0,0,.253-.065.838.838,0,0,0,.3-.281.33.33,0,0,0-.029-.384.5.5,0,0,0-.241-.105.344.344,0,0,0-.32.032.332.332,0,0,0-.054.077" fill="#fcfdfd" />
          </g>
          <path id="Path_21108" data-name="Path 21108" d="M30.934,41.347a8.229,8.229,0,0,0-2.695-.235,2.517,2.517,0,0,1-.681.088c-.69-.068-1.031-.922-.931-1.609a12.107,12.107,0,0,1,.942-2.35,7.464,7.464,0,0,0,.055-4.082,21.586,21.586,0,0,1-.7-4.07c-.008-.677.079-1.356.023-2.031a21.279,21.279,0,0,0-.554-2.55,8.866,8.866,0,0,1-.136-2.767,1.491,1.491,0,0,1,.112-.486c.161-.342.532-.533.754-.84.466-.641.162-1.558.41-2.312a2.818,2.818,0,0,1,2.4-1.554,14.556,14.556,0,0,0,2.111-.373L32,15H25l.94,26.33a1.759,1.759,0,0,0,.14.65,2,2,0,0,0,1.58,1.26L33,44l-.1-2.79a3.853,3.853,0,0,1-1.966.137Z" fill="#d6904b" />
          <path id="Path_21109" data-name="Path 21109" d="M31.224,25.692a6.611,6.611,0,0,1-1.047-.251c-.946-.167-1.824.538-2.778.644a.4.4,0,0,1-.275-.041.326.326,0,0,1-.114-.171.842.842,0,0,1,.238-.827,1.761,1.761,0,0,1,.793-.417c.373-.1.848-.243.89-.629H27.365c-.441,0-.971-.048-1.18-.437a.807.807,0,0,1,.367-1.015,1.662,1.662,0,0,1,1.162-.109,3.734,3.734,0,0,1,1.17.457,6.851,6.851,0,0,0,1.706,1.01,5.094,5.094,0,0,1,.933.211.823.823,0,0,1-.3,1.575Z" fill="#eeb875" />
          <path id="Path_21110" data-name="Path 21110" d="M31.793,35.265a.283.283,0,0,1-.025.147c-.064.115-.231.1-.358.064a5.669,5.669,0,0,0-1.789-.142,2.333,2.333,0,0,1-.528.011.619.619,0,0,1-.443-.257.9.9,0,0,1-.059-.6.194.194,0,0,1,.067-.149.2.2,0,0,1,.112-.018,6.246,6.246,0,0,1,1.145.164.374.374,0,0,0,.283-.005.314.314,0,0,0,.1-.125c.188-.341.28-.782.627-.959a.611.611,0,0,1,.889.575c-.02.429-.022.86-.021,1.294Z" fill="#eeb875" />
          <path id="Path_21111" data-name="Path 21111" d="M30.123,17.063a3.32,3.32,0,0,0-.886.383.4.4,0,0,0-.183.255c-.021.181.161.31.288.441a1.9,1.9,0,0,1,.361.672,1.637,1.637,0,0,0,.382.659.587.587,0,0,0,.7.1.883.883,0,0,0,.229-.792c-.027-.542-.042-1.086-.081-1.627-.046-.7-.357-.242-.81-.091Z" fill="#d6904b" />
          <path id="Path_21112" data-name="Path 21112" d="M34.7,33.033a2,2,0,0,0-.306.218c-.063.053-.127.127-.107.207a.187.187,0,0,0,.05.08.509.509,0,0,0,.5.148.431.431,0,0,0,.308-.41.393.393,0,0,0-.121-.269c-.089-.084-.287-.078-.286.046" fill="#fcfdfd" />
          <path id="Path_21113" data-name="Path 21113" d="M31.467,13.823a7.255,7.255,0,0,1-2.72.515,6.054,6.054,0,0,1-1.723-.846,10.4,10.4,0,0,0-.864.6L25,15h7a10.278,10.278,0,0,1,1.987-1.1A6.213,6.213,0,0,0,31.467,13.823Z" fill="#eeb875" />
          <path id="Path_21114" data-name="Path 21114" d="M25.11,17.95,25.64,33H24c-1.13,0-2.62-2.3-2.62-2.3-.86,2.18-1.73,1.45-1.73,1.45a3.2,3.2,0,0,1-.96-3.29c-.04,0-.07.01-.11.01,0,0-1.02,0,0-2.06,0,0-3.08,1.03-3.08,0,0-.69,1.83-3.21,4.27-4.82a3.116,3.116,0,0,1-1.43-.48c-.73-.72,2.18-2.17,2.18-2.17-2.18-.72-1.45-1.45-1.45-1.45,1.48-1.48,3.91-.88,5.93.06Z" fill="#7bbb40" />
          <path id="Path_21115" data-name="Path 21115" d="M22,51.846v.628A2.116,2.116,0,0,0,23.21,54.4L29,57l.139-.1A15.566,15.566,0,0,0,22,51.846Z" fill="#2e450d" />
          <path id="Path_21116" data-name="Path 21116" d="M27.21,51.52A2.014,2.014,0,0,1,26,49.68V47.025a27.163,27.163,0,0,0-4.087-4.077l.027.779a1.92,1.92,0,0,0,.14.681l-.08.021v7.417A15.566,15.566,0,0,1,29.139,56.9l1.6-1.116a26.922,26.922,0,0,0-1.308-3.317Z" fill="#354f1c" />
          <path id="Path_21117" data-name="Path 21117" d="M26,47.025V42l.08-.02a1.759,1.759,0,0,1-.14-.65L25.892,40a38.132,38.132,0,0,0-4.265-5.466l.194,5.7.092,2.714A27.163,27.163,0,0,1,26,47.025Z" fill="#2e450d" />
          <path id="Path_21118" data-name="Path 21118" d="M29.428,52.47a26.921,26.921,0,0,1,1.308,3.317l.842-.59.016-.011q-.141-.97-.33-1.93Z" fill="#2e450d" />
          <path id="Path_21119" data-name="Path 21119" d="M25.7,34.607l-.251-7.035L25.64,33H24c-.946,0-2.133-1.6-2.5-2.122l.125,3.653A38.132,38.132,0,0,1,25.892,40Z" fill="#354f1c" />
          <path id="Path_21120" data-name="Path 21120" d="M25,17.95h.1l-.04-1.123a17.953,17.953,0,0,0-2.73-1.764c-.058.044-.119.081-.175.126L21,16.143l.03.874A8.2,8.2,0,0,1,25,17.95Z" fill="#2e450d" />
          <path id="Path_21121" data-name="Path 21121" d="M25,15l.476-.373.684-.537a10.019,10.019,0,0,1,1.74-1.081,9.3,9.3,0,0,0-3.932,1.013,9.9,9.9,0,0,0-1.631,1.041,17.953,17.953,0,0,1,2.73,1.764Z" fill="#354f1c" />
          <path id="Path_21122" data-name="Path 21122" d="M33.858,53.428,33,54l-.364-.156L32,53.572l-.736-.316q.189.959.33,1.93L32,54.9l.516-.361,1.4-.979Z" fill="#354f1c" />
          <path id="Path_21123" data-name="Path 21123" d="M24,33h1.64l-.191-5.428-.06-1.685A25.016,25.016,0,0,0,21.2,22.08l.3,8.662v.136C21.867,31.4,23.054,33,24,33Z" fill="#549119" />
          <path id="Path_21124" data-name="Path 21124" d="M21.2,22.08a25.016,25.016,0,0,1,4.187,3.807l-.135-3.772L25.1,17.95H25a8.2,8.2,0,0,0-3.97-.933l.093,2.73Z" fill="#549119" />
          <path id="Path_21125" data-name="Path 21125" d="M47.53,18.47a5.034,5.034,0,0,1,0,7.11c-.51.5-.71.71-1.42,1.42,1.57-1.57,1.25-4.43-.71-6.4s-4.83-2.28-6.4-.71c.71-.71.92-.91,1.42-1.42a5.034,5.034,0,0,1,7.11,0Z" fill="#f9dc4b" />
          <path id="Path_21126" data-name="Path 21126" d="M29.72,22.97a.984.984,0,0,1,0,1.37l-1.38,1.38a.984.984,0,0,1-1.37,0l-.69-.69a.984.984,0,0,1,0-1.37l1.38-1.38a.984.984,0,0,1,1.37,0Z" fill="#eb272c" />
          <path id="Path_21127" data-name="Path 21127" d="M36.72,18.66a.984.984,0,0,1,0,1.37l-.69.69a.984.984,0,0,1-1.37,0l-1.38-1.38a.984.984,0,0,1,0-1.37l.69-.69a.984.984,0,0,1,1.37,0Z" fill="#eb272c" />
          <path id="Path_21128" data-name="Path 21128" d="M48,28v1a1.022,1.022,0,0,1-.44.83A.975.975,0,0,1,47,30H45a1,1,0,0,1-1-1V28a1,1,0,0,1,1-1h2A1,1,0,0,1,48,28Z" fill="#eb272c" />
          <path id="Path_21129" data-name="Path 21129" d="M29.03,22.28a.945.945,0,0,0-.188-.122.971.971,0,0,1-.122,1.182l-1.38,1.38a.971.971,0,0,1-1.182.122.945.945,0,0,0,.122.188l.69.69a.984.984,0,0,0,1.37,0l1.38-1.38a.984.984,0,0,0,0-1.37Z" fill="#ab0f1c" />
          <path id="Path_21130" data-name="Path 21130" d="M35.66,19.72l-1.38-1.38a.971.971,0,0,1-.122-1.182.945.945,0,0,0-.188.122l-.69.69a.984.984,0,0,0,0,1.37l1.38,1.38a.984.984,0,0,0,1.37,0l.69-.69a.945.945,0,0,0,.122-.188.971.971,0,0,1-1.182-.122Z" fill="#ab0f1c" />
          <path id="Path_21131" data-name="Path 21131" d="M47.56,28.83A.975.975,0,0,1,47,29H45a1,1,0,0,1-1-1v1a1,1,0,0,0,1,1h2a.975.975,0,0,0,.56-.17A1.022,1.022,0,0,0,48,29V28A1.022,1.022,0,0,1,47.56,28.83Z" fill="#ab0f1c" />
          <path id="Path_21132" data-name="Path 21132" d="M45.4,20.6c1.96,1.97,2.28,4.83.71,6.4L39,19.89c1.57-1.57,4.43-1.25,6.4.71Z" fill="#fdf2a3" />
          <path id="Path_21133" data-name="Path 21133" d="M46.53,19.47a5.032,5.032,0,0,0-7.1-.006l-.094.093-.316.319c1.574-1.551,4.419-1.229,6.382.724s2.275,4.808.724,6.381l.323-.319.089-.089A5.033,5.033,0,0,0,46.53,19.47Z" fill="#ffea73" />
          <ellipse id="Ellipse_2009" data-name="Ellipse 2009" cx="1.5" cy="4" rx="1.5" ry="4" transform="translate(46 32)" fill="#ffcd36" />
          <path id="Path_21134" data-name="Path 21134" d="M47.5,38c-.7,0-1.28-1.277-1.447-3-.031.321-.053.653-.053,1,0,2.21.67,4,1.5,4S49,38.21,49,36c0-.347-.022-.679-.053-1C48.78,36.723,48.2,38,47.5,38Z" fill="#fc8200" />
          <path id="Path_21135" data-name="Path 21135" d="M44.81,41.19c.58.58-.21,2.31-1.76,3.86s-3.28,2.34-3.86,1.76a.743.743,0,0,1-.19-.54,5.9,5.9,0,0,1,1.95-3.32c1.55-1.55,3.28-2.34,3.86-1.76Z" fill="#eb272c" />
          <ellipse id="Ellipse_2010" data-name="Ellipse 2010" cx="1.485" cy="3.974" rx="1.485" ry="3.974" transform="translate(18.133 45.237) rotate(-45)" fill="#ffcd36" />
          <ellipse id="Ellipse_2011" data-name="Ellipse 2011" cx="0.742" cy="1.987" rx="0.742" ry="1.987" transform="translate(19.564 45.617) rotate(-45)" fill="#fff3b0" />
          <path id="Path_21136" data-name="Path 21136" d="M23.05,45.95c-.171-.171-.345-.329-.519-.481,1.223,1.4,1.8,2.825,1.279,3.341s-1.941-.056-3.341-1.279c.152.174.31.348.481.519,1.55,1.55,3.28,2.34,3.86,1.76s-.21-2.31-1.76-3.86Z" fill="#fc8200" />
          <ellipse id="Ellipse_2012" data-name="Ellipse 2012" cx="1.987" cy="0.742" rx="1.987" ry="0.742" transform="translate(40.562 44.38) rotate(-45)" fill="#ab0f1c" />
          <path id="Path_21137" data-name="Path 21137" d="M40.95,42.95c.171-.171.345-.329.519-.481-1.223,1.4-1.8,2.825-1.279,3.341s1.941-.056,3.341-1.279c-.152.174-.31.348-.481.519-1.55,1.55-3.28,2.34-3.86,1.76S39.4,44.5,40.95,42.95Z" fill="#ab0f1c" />
          <path id="Path_21138" data-name="Path 21138" d="M23,36.05V37a5,5,0,0,1-10,0V35c0,2.21,2.24,4,5,4s5-1.79,5-4Z" fill="#ffc145" />
          <path id="Path_21139" data-name="Path 21139" d="M18,41a5,5,0,0,1-5-5v1a5,5,0,0,0,10,0V36a5,5,0,0,1-5,5Z" fill="#ffb31c" />
          <path id="Path_21140" data-name="Path 21140" d="M22.08,35H23c0,2.21-2.24,4-5,4s-5-1.79-5-4Z" fill="#ffea73" />
          <path id="Path_21141" data-name="Path 21141" d="M43.61,33.1A4.78,4.78,0,0,1,44,35a5,5,0,0,1-5,5V33c-.11,0-.22-.02-.33-.03A3.507,3.507,0,0,1,35.5,35h-3a3.5,3.5,0,0,1-.05-7h2.69A3.993,3.993,0,0,1,43,29a3.914,3.914,0,0,1-.71,2.26,4.95,4.95,0,0,1,1.32,1.84Z" fill="#eb272c" />
          <path id="Path_21142" data-name="Path 21142" d="M41.54,33.551a.952.952,0,0,0-.35-.64,1.068,1.068,0,0,0-.673-.082c-.49.047-.963.147-1.452.213A.315.315,0,0,0,39,33.06V40a4.99,4.99,0,0,0,4.1-2.152,17.933,17.933,0,0,1-1.175-1.961,14.962,14.962,0,0,1-.385-2.336Z" fill="#ab0f1c" />
          <path id="Path_21143" data-name="Path 21143" d="M39.9,27.8c.357.632.01,1.45.236,2.14a1.807,1.807,0,0,0,1.229,1.083,3.765,3.765,0,0,0,.981.144A3.916,3.916,0,0,0,43,29a3.99,3.99,0,0,0-7.843-1.049c.736-.226,2.288-.557,2.91-.717A1.628,1.628,0,0,1,39.9,27.8Z" fill="#ff5c60" />
          <path id="Path_21144" data-name="Path 21144" d="M33.113,32.5A3.272,3.272,0,0,1,32.7,31q-.159-1.5-.285-3a3.5,3.5,0,1,0,.08,7h3a3.451,3.451,0,0,0,1.844-.535,7.725,7.725,0,0,1-3.489-1.278,2.7,2.7,0,0,1-.742-.687Z" fill="#ab0f1c" />
          <path id="Path_21145" data-name="Path 21145" d="M38.113,28.083a11.345,11.345,0,0,0-1.2.018,1.632,1.632,0,0,0-.529.1.612.612,0,0,0-.363.378.6.6,0,0,0,.2.576,1.391,1.391,0,0,0,.572.279,4.3,4.3,0,0,0,.907.152,1.164,1.164,0,0,0,.83-.193.724.724,0,0,0-.417-1.31Z" fill="#ff5c60" />
          <path id="Path_21146" data-name="Path 21146" d="M35.319,28.5a.327.327,0,0,1,.128.024c.095.048.116.173.119.28a.446.446,0,0,1-.069.307.456.456,0,0,1-.3.129c-.358.054-1.294.27-1.354-.271-.04-.36.135-.415.445-.443A8.251,8.251,0,0,1,35.319,28.5Z" fill="#ff5c60" />
          <path id="Path_21147" data-name="Path 21147" d="M41.62,28.668a1.848,1.848,0,0,0-.118.5.861.861,0,0,0,.347.81c.216.1.468-.078.568-.4a2.323,2.323,0,0,0,.074-.522,1.839,1.839,0,0,0,0-.371A1.186,1.186,0,0,0,42.2,28.1c-.258-.325-.48.282-.583.572Z" fill="#f6ecd1" />
          <path id="Path_21148" data-name="Path 21148" d="M41.206,27.443a1.2,1.2,0,0,1,.252.3.974.974,0,0,0,.5-.067.166.166,0,0,0,.061-.04.157.157,0,0,0,.026-.083c.019-.175.057-.463-.189-.424a4.422,4.422,0,0,0-.65.314Z" fill="#f6ecd1" />
          <path id="Path_21149" data-name="Path 21149" d="M40.6,26.559c-.038.028-.083.061-.082.109a.138.138,0,0,0,.054.088l.237.224a.327.327,0,0,0,.24.122.313.313,0,0,0,.147-.07l.214-.151c.071-.05.15-.114.152-.2a.261.261,0,0,0-.074-.16,1.68,1.68,0,0,0-.366-.338C40.849,26,40.775,26.425,40.6,26.559Z" fill="#f6ecd1" />
          <path id="Path_21150" data-name="Path 21150" d="M42.543,33.187a.221.221,0,0,0-.056.085.254.254,0,0,0,.012.136l.409,1.43c.02.071.053.155.118.162.048.005.089-.038.122-.08a1.521,1.521,0,0,0,.317-1.148,1.036,1.036,0,0,0-.341-.621A.613.613,0,0,0,42.835,33c-.142-.015-.188.075-.292.187Z" fill="#f6ecd1" />
          <ellipse id="Ellipse_2013" data-name="Ellipse 2013" cx="0.5" cy="2" rx="0.5" ry="2" transform="translate(47 33)" fill="#fff3b0" />
          <path id="Path_21151" data-name="Path 21151" d="M25,18c-.277-.129-.563-.249-.853-.361a3.345,3.345,0,0,1-1.4.99c-.272.1-.61.22-.646.508-.056.441.648.6.765,1.032.1.365-.268.678-.6.864s-.718.443-.691.82a.581.581,0,0,1,.682.546,1.027,1.027,0,0,1-.471.842,5.542,5.542,0,0,1-.9.457,8.877,8.877,0,0,0-1.951,1.428c-.376.331-.779.876-.47,1.27s.972.138,1.424.358c.486.236.539.891.583,1.429s.291,1.206.832,1.217c.744.014.948-1.212,1.68-1.347a1.023,1.023,0,0,1,.966.531c.214.326.327.709.535,1.038a2.008,2.008,0,0,0,1.051.8L25.11,18Z" fill="#65a229" />
          <path id="Path_21152" data-name="Path 21152" d="M25.11,18H25c-.277-.129-.563-.249-.853-.361a3.345,3.345,0,0,1-1.4.99c-.272.1-.61.22-.646.508-.056.441.648.6.765,1.032.1.365-.268.678-.6.864s-.718.443-.691.82a.581.581,0,0,1,.682.546,1.027,1.027,0,0,1-.471.842,3.1,3.1,0,0,1-.567.308l.2,5.83c.651-.108.876-1.2,1.569-1.326a1.023,1.023,0,0,1,.966.531c.214.326.327.709.535,1.038a2.008,2.008,0,0,0,1.051.8Z" fill="#457815" />
          <ellipse id="Ellipse_2014" data-name="Ellipse 2014" cx="1.642" cy="2.286" rx="1.642" ry="2.286" transform="translate(36.783 10.255) rotate(-45)" fill="#f6fafd" />
          <ellipse id="Ellipse_2015" data-name="Ellipse 2015" cx="0.825" cy="1.148" rx="0.825" ry="1.148" transform="translate(41.163 13.496) rotate(-45)" fill="#f6fafd" />
          <ellipse id="Ellipse_2016" data-name="Ellipse 2016" cx="0.825" cy="1.148" rx="0.825" ry="1.148" transform="translate(49.156 35.501) rotate(-45)" fill="#f6fafd" />
          <ellipse id="Ellipse_2017" data-name="Ellipse 2017" cx="0.825" cy="1.148" rx="0.825" ry="1.148" transform="translate(33.155 30.495) rotate(-45)" fill="#f6fafd" />
          <ellipse id="Ellipse_2018" data-name="Ellipse 2018" cx="0.413" cy="0.574" rx="0.413" ry="0.574" transform="translate(35.598 32.112) rotate(-45)" fill="#f6fafd" />
          <ellipse id="Ellipse_2019" data-name="Ellipse 2019" cx="0.413" cy="0.574" rx="0.413" ry="0.574" transform="translate(49.597 40.118) rotate(-45)" fill="#f6fafd" />
        </g>
      </g>
    </svg>
  );
}

export default function RoundHeart({ width = '40', height = '40' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-896 -432)">
        <circle id="Ellipse_5159" data-name="Ellipse 5159" cx="20" cy="20" r="20" transform="translate(896 432)" fill="#ec5e49" />
        <g id="Group_33063" data-name="Group 33063" transform="translate(889.904 390.772)">
          <path id="Path_9" data-name="Path 9" d="M4.461,0A4.365,4.365,0,0,1,8.842,4.4L8.8,14.418a4.455,4.455,0,0,1-4.418,4.438A4.365,4.365,0,0,1,0,14.456L.043,4.438A4.455,4.455,0,0,1,4.461,0Z" transform="translate(15.095 56.171) rotate(-34)" fill="#fff" />
          <path id="Oval-2" d="M7.6,0a4.257,4.257,0,0,1,4.079,4.423A4.3,4.3,0,0,1,7.735,9a3.623,3.623,0,0,1-1.7-.386v.016L0,4.423,5.6.642q.146-.1.3-.183l.14-.084V.386A3.634,3.634,0,0,1,7.6,0Z" transform="translate(28.044 64.284) rotate(-90)" fill="#fff" fillRule="evenodd" />
        </g>
      </g>
    </svg>
  );
}

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputRadioCheckboxStyled } from './styled';

const InputRadioCheckbox = ({ theme_standard, value, onChange, options }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_selected, _setSelected] = useState({});

  useEffect(() => {}, []);

  const _handleClick = (e) => {
    let temp = {};
    temp[e.value] = true;
    _setSelected(temp);
    onChange && onChange(e.value);
  };

  return (
    <InputRadioCheckboxStyled>
      <div className={customClass}>
        {options &&
          options.map((e, i) => (
            <label key={i} className="input_wrap">
              <div className="container">
                <input
                  type="checkbox"
                  name={value}
                  value={value}
                  checked={_selected[e.value]}
                  onChange={() => _handleClick(e)}
                />
                <span className="checkmark"></span>
                {e.label && <span className="lable_show">{e.label}</span>}
              </div>
            </label>
          ))}
      </div>
    </InputRadioCheckboxStyled>
  );
};

InputRadioCheckbox.propTypes = {};

export default InputRadioCheckbox;

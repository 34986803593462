import styled from 'styled-components';

export const MainDashBoardWellBeingStyled = styled.div`
  height: 100%;
  .container {
    .top_main {
      display: flex;
      .top_main_left {
        width: 60%;
        display: flex;
        align-items: center;
        .show_icon {
          margin-right: 7px;
        }
      }
      .top_main_right {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .label_layout {
          display: flex;
          .show_icon {
          }
          .show_label {
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            margin-left: 8px;
          }
        }
        .date_layout {
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
    }
    .bottom_main {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      .bottom_main_left {
        width: 60%;
        column-gap: 30px;
        display: flex;
        padding-left: 45px;
        .bar_show {
          // width: 80px;
          width: 20%;
          display: flex;
          align-items: flex-end;
        }
        .bar_show_2 {
          // width: 80px;
          width: 20%;
          display: flex;
          align-items: flex-end;
          &.no_mr {
            margin-right: 0px;
          }
        }
      }
      .bottom_main_right {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .show_being {
          margin-bottom: 27px;
          &.no_margin {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
   .container {
     .bottom_main {
       .bottom_main_left {
         .bar_show {
           width: 25%;
         }
         .bar_show_2 {
          width: 25%;
        }
       }
     }
   }
  }
`;

import React, { useEffect, useState } from "react";
import { InputCheckBoxStyled } from "./styled";
import cx from "classnames";

export const InputCheckBox = ({
  theme_standard_input_check_box,
  theme_standard_input_check_box_round,
  theme_standard_input_check_box_square,
  theme_standard_input_check_box_square_account,
  input_check_box_square_light_blue,
  theme_blue_square,
  theme_dark_blue,
  theme_color_custom,
  label,
  value,
  checked,
  fontSize,
  fontFamily,
  fontColor,
  onChange,
  onChangeCustom,
  disabled,
  bgcolor,
}) => {
  const customClass = cx({
    theme_standard_input_check_box: theme_standard_input_check_box,
    input_check_box_square_light_blue: input_check_box_square_light_blue,
    theme_standard_input_check_box_round: theme_standard_input_check_box_round,
    theme_standard_input_check_box_square:
      theme_standard_input_check_box_square,
    theme_standard_input_check_box_square_account:
      theme_standard_input_check_box_square_account,
    theme_blue_square: theme_blue_square,
    theme_dark_blue: theme_dark_blue,
    theme_color_custom: theme_color_custom,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeEvent = (e) => {
    _setValue(e.target.checked);
    onChange && onChange(e);
    onChangeCustom && onChangeCustom(e.target.checked);
  };

  return (
    <InputCheckBoxStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      disabled={disabled}
      bgcolor={bgcolor}
    >
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={value}
            value={_value}
            checked={_value}
            onChange={onChangeEvent}
            disabled={disabled}
          />
          <span className="checkmark"></span>
          {label && (
            <span
              className={`lable_show ${
                _value ? "active_font" : disabled ? "dis_font" : ""
              }`}
            >
              {label}
            </span>
          )}
        </label>
      </div>
    </InputCheckBoxStyled>
  );
};

export default InputCheckBox;

import styled from 'styled-components';

export const RewardTotalViewerStyled = styled.div`
  .top_current {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size:  ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    .sub_top {
      font-size:  ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  .mid_current {
    font-size: ${({ theme }) => theme.FONT.SIZE.S72};
    font-family: noto_sans_thai_bold, noto_sans_bold;
    color:  ${({ theme }) => theme.COLORS.BLACK_1};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    .label_mid {
      font-size:  ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      margin-left: 4px;
      padding-top: 29px;
    }
  }
  .bottom_current {
    display: flex;
    justify-content: center;
  }
`;

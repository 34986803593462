export default function MostPick() {
  return (
    <svg id="Group_15170" data-name="Group 15170" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_24358" data-name="Group 24358" transform="translate(0 13.714)">
        <g id="Group_24355" data-name="Group 24355" transform="translate(0 3.429)">
          <path id="Path_43692" data-name="Path 43692" d="M4,37.429a3.428,3.428,0,0,0,3.429,3.429H8.571V34H7.429A3.428,3.428,0,0,0,4,37.429Z" transform="translate(-4 -34)" fill="#d1aa81" />
        </g>
        <g id="Group_24356" data-name="Group 24356" transform="translate(22.857 3.429)">
          <path id="Path_43693" data-name="Path 43693" d="M44,34v6.857h1.143a3.429,3.429,0,0,0,0-6.857Z" transform="translate(-44 -34)" fill="#d1aa81" />
        </g>
        <g id="Group_24357" data-name="Group 24357" transform="translate(3.429)">
          <path id="Path_43694" data-name="Path 43694" d="M30.571,28H10v8a10.286,10.286,0,1,0,20.571,0Z" transform="translate(-10 -28)" fill="#f2c8a0" />
        </g>
      </g>
      <g id="Group_24359" data-name="Group 24359" transform="translate(2.286 6.857)">
        <path id="Path_43695" data-name="Path 43695" d="M14.857,16H12.571A4.572,4.572,0,0,0,8,20.571v4a.572.572,0,0,0,.571.571h2.857A4.572,4.572,0,0,0,16,20.571V17.143A1.143,1.143,0,0,0,14.857,16Z" transform="translate(-8 -16)" fill="#00325a" />
      </g>
      <g id="Group_24360" data-name="Group 24360" transform="translate(6.857 4.571)">
        <path id="Path_43696" data-name="Path 43696" d="M27.429,12H20.571A4.572,4.572,0,0,0,16,16.571a6.857,6.857,0,0,0,6.857,6.857H33.714a.572.572,0,0,0,.571-.571v-4A6.857,6.857,0,0,0,27.429,12Z" transform="translate(-16 -12)" fill="#0a5078" />
      </g>
      <g id="Group_24361" data-name="Group 24361" transform="translate(17.143 4.571)">
        <path id="Path_43697" data-name="Path 43697" d="M42,18.857v2.286H36.989a.59.59,0,0,0-.406.166l-.64.64a1.145,1.145,0,0,1-1.743-.143,1.185,1.185,0,0,1,.171-1.514l.594-.6a.553.553,0,0,0,.166-.4V12h.011A6.855,6.855,0,0,1,42,18.857Z" transform="translate(-34 -12)" fill="#00325a" />
      </g>
      <g id="Group_24362" data-name="Group 24362" transform="translate(8 18.286)">
        <path id="Path_43698" data-name="Path 43698" d="M20.857,39.429a.571.571,0,0,1-.571-.571V37.714a.571.571,0,1,0-1.143,0v1.143a.571.571,0,1,1-1.143,0V37.714a1.714,1.714,0,1,1,3.429,0v1.143A.571.571,0,0,1,20.857,39.429Z" transform="translate(-18 -36)" fill="#00143c" />
      </g>
      <g id="Group_24363" data-name="Group 24363" transform="translate(16 18.286)">
        <path id="Path_43699" data-name="Path 43699" d="M34.857,39.429a.571.571,0,0,1-.571-.571V37.714a.571.571,0,1,0-1.143,0v1.143a.571.571,0,1,1-1.143,0V37.714a1.714,1.714,0,1,1,3.429,0v1.143A.571.571,0,0,1,34.857,39.429Z" transform="translate(-32 -36)" fill="#00143c" />
      </g>
      <g id="Group_24364" data-name="Group 24364" transform="translate(9.299 22.857)">
        <path id="Path_43700" data-name="Path 43700" d="M24.685,48.571a4.572,4.572,0,0,0,4.368-3.218A1.056,1.056,0,0,0,28.034,44h-6.7a1.06,1.06,0,0,0-1.015,1.369,4.577,4.577,0,0,0,4.363,3.2Z" transform="translate(-20.274 -44)" fill="#c80a50" />
      </g>
      <g id="Group_24365" data-name="Group 24365" transform="translate(9.301 22.857)">
        <path id="Path_43701" data-name="Path 43701" d="M25.175,48.543a4.633,4.633,0,0,1-.491.029,4.575,4.575,0,0,1-4.36-3.206A1.059,1.059,0,0,1,21.341,44h.983a1.061,1.061,0,0,0-1.017,1.366A4.583,4.583,0,0,0,25.175,48.543Z" transform="translate(-20.276 -44)" fill="#a00028" />
      </g>
      <g id="Group_24366" data-name="Group 24366" transform="translate(17.143 0)">
        <path id="Path_43702" data-name="Path 43702" d="M46.571,4H37.419a2.286,2.286,0,0,0-2.286,2.286v8.433a.57.57,0,0,1-.167.4l-.6.6a1.186,1.186,0,0,0-.168,1.513,1.144,1.144,0,0,0,1.741.147l.641-.641a.571.571,0,0,1,.4-.167h9.585a2.286,2.286,0,0,0,2.286-2.286v-8A2.286,2.286,0,0,0,46.571,4Z" transform="translate(-34 -4)" fill="#00a0c8" />
      </g>
      <g id="Group_24367" data-name="Group 24367" transform="translate(21.714 1.143)">
        <path id="Path_43703" data-name="Path 43703" d="M50,9.429H48.286a.572.572,0,0,1-.571-.571V7.143a1.143,1.143,0,1,0-2.286,0,2.286,2.286,0,0,1-2.286,2.286H42v5.714h6.857a2.286,2.286,0,0,0,2.286-2.286V10.571A1.143,1.143,0,0,0,50,9.429Z" transform="translate(-42 -6)" fill="#f0f0f0" />
      </g>
      <g id="Group_24368" data-name="Group 24368" transform="translate(19.428 3.429)">
        <path id="Path_43704" data-name="Path 43704" d="M38.571,10h2.286a.572.572,0,0,1,.571.571v6.857a.572.572,0,0,1-.571.571H38.571A.572.572,0,0,1,38,17.429V10.571A.572.572,0,0,1,38.571,10Z" transform="translate(-38 -10)" fill="#c80a50" />
      </g>
      <g id="Group_24369" data-name="Group 24369" transform="translate(19.428 8)">
        <path id="Path_43705" data-name="Path 43705" d="M40.857,21.429H38.571A.572.572,0,0,1,38,20.857V19.714a1.714,1.714,0,1,1,3.429,0v1.143A.572.572,0,0,1,40.857,21.429Z" transform="translate(-38 -18)" fill="#a00028" />
      </g>
      <g id="Group_24370" data-name="Group 24370" transform="translate(20.488 9.229)">
        <circle id="Ellipse_3681" data-name="Ellipse 3681" cx="0.641" cy="0.641" r="0.641" fill="#f0f0f0" />
      </g>
    </svg>
  );
}

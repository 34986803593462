export default function RoundAdd2({ width = "24", height = "25", color = '#60C2A8' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z" fill={color} />
      <path d="M5.51168 13.4374C5.3259 13.2518 5.19948 13.0151 5.14847 12.7574C5.09745 12.4997 5.12416 12.2327 5.22518 11.9903C5.32621 11.7478 5.497 11.5408 5.71587 11.3956C5.93474 11.2504 6.19183 11.1735 6.45449 11.1747L17.4731 11.1738C17.6474 11.1739 17.8199 11.2084 17.9808 11.2753C18.1417 11.3422 18.2879 11.4401 18.411 11.5634C18.5341 11.6868 18.6317 11.8332 18.6982 11.9942C18.7647 12.1553 18.7989 12.3279 18.7987 12.5022C18.7985 12.6764 18.764 12.849 18.6971 13.0099C18.6303 13.1708 18.5324 13.317 18.409 13.4401C18.2857 13.5632 18.1393 13.6608 17.9782 13.7273C17.8171 13.7938 17.6445 13.828 17.4703 13.8278H6.45261C6.27774 13.8282 6.10454 13.7939 5.94302 13.7269C5.7815 13.6599 5.63489 13.5615 5.51168 13.4374Z" fill="white" />
      <path d="M11.0228 18.9491C10.7756 18.701 10.6369 18.365 10.6372 18.0147L10.6325 6.99048C10.6325 6.63816 10.7725 6.30028 11.0216 6.05115C11.2707 5.80202 11.6086 5.66207 11.9609 5.66207C12.3133 5.66207 12.6511 5.80202 12.9003 6.05115C13.1494 6.30028 13.2894 6.63816 13.2894 6.99048L13.2865 18.011C13.2877 18.2736 13.2108 18.5307 13.0656 18.7496C12.9204 18.9685 12.7134 19.1393 12.471 19.2403C12.2285 19.3413 11.9615 19.368 11.7038 19.317C11.4462 19.266 11.2095 19.1396 11.0238 18.9538L11.0228 18.9491Z" fill="white" />
      <path d="M5.51168 13.4374C5.3259 13.2518 5.19948 13.0151 5.14847 12.7574C5.09745 12.4997 5.12416 12.2327 5.22518 11.9903C5.32621 11.7478 5.497 11.5408 5.71587 11.3956C5.93474 11.2504 6.19183 11.1735 6.45449 11.1747L17.4731 11.1738C17.6474 11.1739 17.8199 11.2084 17.9808 11.2753C18.1417 11.3422 18.2879 11.4401 18.411 11.5634C18.5341 11.6868 18.6317 11.8332 18.6982 11.9942C18.7647 12.1553 18.7989 12.3279 18.7987 12.5022C18.7985 12.6764 18.764 12.849 18.6971 13.0099C18.6303 13.1708 18.5324 13.317 18.409 13.4401C18.2857 13.5632 18.1393 13.6608 17.9782 13.7273C17.8171 13.7938 17.6445 13.828 17.4703 13.8278H6.45261C6.27774 13.8282 6.10454 13.7939 5.94302 13.7269C5.7815 13.6599 5.63489 13.5615 5.51168 13.4374Z" fill="white" />
      <path d="M11.0228 18.9491C10.7756 18.701 10.6369 18.365 10.6372 18.0147L10.6325 6.99048C10.6325 6.63816 10.7725 6.30028 11.0216 6.05115C11.2707 5.80202 11.6086 5.66207 11.9609 5.66207C12.3133 5.66207 12.6511 5.80202 12.9003 6.05115C13.1494 6.30028 13.2894 6.63816 13.2894 6.99048L13.2865 18.011C13.2877 18.2736 13.2108 18.5307 13.0656 18.7496C12.9204 18.9685 12.7134 19.1393 12.471 19.2403C12.2285 19.3413 11.9615 19.368 11.7038 19.317C11.4462 19.266 11.2095 19.1396 11.0238 18.9538L11.0228 18.9491Z" fill="white" />
    </svg>
  );
}

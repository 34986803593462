import styled from "styled-components";

export const HeadContentStyled = styled.div`
  .head_container {
    position: relative;
    .node_left {
      position: absolute;
      top: 5px;
      left: 0px;
    }
    .title_center {
      text-align: center;
      .head_t {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S32};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
      .sub_t {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .node_right {
      position: absolute;
      top: 12px;
      right: 0px;
      &.mw_272 {
        max-width: 272px;
        width: 100%;
      }
      &.top_18 {
        top: 18px;
      }
    }
    .w132 {
      position: absolute;
      top: 18px;
      width: 132px;
      right: 0px;
    }
  }
`;

export default function Page() {
  return (
    <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="_002---Pen-and-Paper" data-name="002---Pen-and-Paper" transform="translate(0)">
        <path id="Shape" d="M29.51,1.379A1.365,1.365,0,0,0,28.169,0H9.945A1.323,1.323,0,0,0,9,.4L.393,9.251A1.4,1.4,0,0,0,0,10.226V38.621A1.365,1.365,0,0,0,1.341,40H28.169a1.361,1.361,0,0,0,1.341-1.379Z" transform="translate(0)" fill="#f9eab0" />
        <g id="Group_14929" data-name="Group 14929" transform="translate(3.746 10.781)">
          <path id="Shape-2" data-name="Shape" d="M28.975,20.5H5.749a.749.749,0,0,1,0-1.5H28.975a.749.749,0,1,1,0,1.5Z" transform="translate(-5 -19)" fill="#cbb292" />
          <path id="Shape-3" data-name="Shape" d="M23.558,25.5H5.749a.749.749,0,0,1,0-1.5H23.558a.749.749,0,1,1,0,1.5Z" transform="translate(-5 -20.254)" fill="#cbb292" />
          <path id="Shape-4" data-name="Shape" d="M18.134,30.5H5.749a.749.749,0,0,1,0-1.5H18.134a.749.749,0,1,1,0,1.5Z" transform="translate(-5 -21.508)" fill="#cbb292" />
          <path id="Shape-5" data-name="Shape" d="M11.383,39.5H5.749a.749.749,0,1,1,0-1.5h5.634a.749.749,0,0,1,0,1.5Z" transform="translate(-5 -23.765)" fill="#cbb292" />
          <path id="Shape-6" data-name="Shape" d="M8.828,44.5H5.749a.749.749,0,1,1,0-1.5H8.828a.749.749,0,1,1,0,1.5Z" transform="translate(-5 -25.019)" fill="#cbb292" />
          <path id="Shape-7" data-name="Shape" d="M13.121,49.5H5.749a.749.749,0,1,1,0-1.5h7.372a.749.749,0,1,1,0,1.5Z" transform="translate(-5 -26.273)" fill="#cbb292" />
        </g>
        <path id="Shape-8" data-name="Shape" d="M37.5,52.244H33.749a.749.749,0,0,1,0-1.5h3v-3a.749.749,0,0,1,1.5,0V51.5A.749.749,0,0,1,37.5,52.244Z" transform="translate(-10.512 -15.241)" fill="#fff" />
        <path id="Shape-9" data-name="Shape" d="M36.743,5.5H30.749a.749.749,0,0,1,0-1.5h5.994a.749.749,0,1,1,0,1.5Z" transform="translate(-9.683 -1.003)" fill="#cbb292" />
        <path id="Shape-10" data-name="Shape" d="M37.5,9.5H33.749a.749.749,0,0,1,0-1.5H37.5a.749.749,0,1,1,0,1.5Z" transform="translate(-10.512 -2.006)" fill="#cbb292" />
        <path id="Shape-11" data-name="Shape" d="M10.534.18V8.286a2.248,2.248,0,0,1-2.248,2.248H.18a1.511,1.511,0,0,1,.307-.442l9.6-9.6A1.511,1.511,0,0,1,10.534.18Z" transform="translate(-0.045 -0.045)" fill="#f3d55b" />
        <path id="Shape-12" data-name="Shape" d="M48.429,21.432l-1.708-2.465A2.248,2.248,0,0,0,43.6,18.4L39.281,21.38,36.2,23.507,21.525,33.658a.394.394,0,0,0-.1.09l4.248,6.136a.62.62,0,0,0,.12-.067L40.465,29.673l3.079-2.128,4.315-2.989a2.248,2.248,0,0,0,.569-3.123Z" transform="translate(-8.827 -6.674)" fill="#bdc3c7" />
        <path id="Shape-13" data-name="Shape" d="M53.838,24.556l-4.315,2.989L45.26,21.38,49.575,18.4a2.248,2.248,0,0,1,3.124.569l1.708,2.465a2.248,2.248,0,0,1-.569,3.124Z" transform="translate(-14.806 -5.888)" fill="#ff5364" />
        <path id="Rectangle-path" d="M0,0H3.743V7.5H0Z" transform="translate(27.377 17.355) rotate(-34.612)" fill="#bdc3c7" />
        <path id="Shape-14" data-name="Shape" d="M40.465,31.517c-15.607,10.789-14.721,10.189-14.8,10.212L21.42,35.593c.061-.075-.894.6,14.782-10.242Z" transform="translate(-7.643 -8.781)" fill="#3b97d3" />
        <path id="Shape-15" data-name="Shape" d="M24.758,45.157c-.075.046.127-.016-4.675,1.154L17.79,42.984c2.78-4.078,2.634-3.878,2.72-3.963Z" transform="translate(-5.724 -12.578)" fill="#fdd7ad" />
        <path id="Shape-16" data-name="Shape" d="M19.346,47.637,15.78,48.5a.749.749,0,0,1-.794-1.154l2.068-3.034Z" transform="translate(-4.712 -14.567)" fill="#464f5d" />
      </g>
    </svg>

  );
}

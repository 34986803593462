import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxCofirmStyled } from "./styled";
import { Buttons } from "components";

const BoxCofirm = ({
  theme_box_confirm,
  handleConfirm,
  handleCancel,
  textShow,
  textBottom,
  labelCancel = "ยกเลิก",
  labelConfirm = "บันทึก",
}) => {
  const customClass = cx({
    theme_box_confirm: theme_box_confirm,
  });
  return (
    <BoxCofirmStyled>
      <div className={customClass}>
        <div className="confirm_box">
          <div className="title_box">
            <div className="group_label">
              <div className="t_label">{textShow ? textShow : "ยืนยันการ"}</div>
              {textBottom && <div className="b_label">{textBottom}</div>}
            </div>
            {/* <div className='icon'>
              <Icons.Delete
                width='21.84px'
                height='28px'
              />
            </div> */}
          </div>
          <div className="action_btn">
            <div className="body_btn">
              <Buttons.BtnNormal
                theme_only_border
                label={labelCancel}
                fontSize="20px"
                fontFamilyTop="noto_sans_regular, noto_sans_thai_thin"
                padding="10px"
                borderRadius="8px"
                fontColor="#7B7B7B"
                borderColor="#7B7B7B"
                backgroundColor="#fff"
                onClick={handleCancel}
              />
            </div>
            <div className="body_btn">
              <Buttons.BtnNormal
                theme_only_border
                label={labelConfirm}
                fontSize="20px"
                fontFamilyTop="noto_sans_regular, noto_sans_thai_thin"
                padding="10px"
                borderRadius="8px"
                backgroundColor="#0B408D"
                borderColor="#0B408D"
                fontColor="#fff"
                onClick={handleConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </BoxCofirmStyled>
  );
};

BoxCofirm.propTypes = {};

export default BoxCofirm;

// import React from "react";
import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { StepContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import moment from "moment";
import { adminService } from "apiServices";
import { RenderCommaMoney, nFormatter } from "utils/functions/FormatMoney";
import { StepIndicatorGraph, WeeklyAchiever, ExportRaw } from "widgets";
import {
  Blocks,
  Cards,
  Typographys,
  Icons,
  Loading,
  BGLoading,
} from "components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const StepContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isBgLoading, setIsBgLoading] = useState(false);
  const [dataStep, setDataStep] = useState();
  const [dataStepAverage, setDataStepAverage] = useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchStep(),
      fetchStepAverage(),
      // this.fetchGoal(),
    ]);

    if (res) {
      setDataStep(res[0]);
      setDataStepAverage({ ...res[1] });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchStep = async () => {
    let res = await adminService.GET_STATISTICS_STEP(companySelectedRedux.id);

    if (res && res.success) {
      return res.data;
    }
  };

  const fetchStepAverage = async (
    period = "weekly",
    per_page = "8",
    offset = "0"
  ) => {
    let res = await adminService.GET_STATISTICS_STEP_AVERAGE_PER_PAGE(
      companySelectedRedux.id,
      period,
      per_page,
      offset
    );

    if (res && res.success) {
      let tempData = [];

      if (res.data.length > 0) {
        res.data.forEach((e) => {
          tempData.push({
            ...e,
            step_count_avg:
              e.user_count > 0
                ? (e.step_count_total / e.user_count).toFixed(0)
                : 0,
          });
        });
      }

      return {
        data: tempData && tempData.length > 0 ? tempData.reverse() : [],
        page: res.page,
        sort: res.sort,
        success: res.success,
      };
    }
  };

  // const fetchFrequencyAge = async (stat_date) => {
  //   let res = await adminService.GET_STATISTICS_FREQUENCY_AGE_GENDER(
  //     companySelectedRedux.id,
  //     stat_date
  //   );
  //   if (res && res.success) {
  //     let extreme = [];
  //     let expert = [];
  //     let moderate = [];
  //     let soft = [];
  //     let stable = [];

  //     if (res.data.age_groups !== null) {
  //       res.data.age_groups.reverse().forEach((e, i) => {
  //         extreme.push(Number(e.male.extra_active.count));
  //         extreme.push(Number(e.female.extra_active.count));
  //         expert.push(Number(e.male.very_active.count));
  //         expert.push(Number(e.female.very_active.count));
  //         moderate.push(Number(e.male.moderately_active.count));
  //         moderate.push(Number(e.female.moderately_active.count));
  //         soft.push(Number(e.male.light_active.count));
  //         soft.push(Number(e.female.light_active.count));
  //         stable.push(Number(e.male.sedentary.count));
  //         stable.push(Number(e.female.sedentary.count));
  //       });
  //     }
  //     const insertEmpty = (data) => {
  //       return data.reduce((arr, b, i) => {
  //         return i % 2 === 1 && data.length - 1 !== i
  //           ? [...arr, b, ""]
  //           : // : i === 0
  //             //   ? ['20', ...arr, b]
  //             [...arr, b];
  //       }, []);
  //     };
  //     this.setState({
  //       dataActiveLevel: {
  //         extreme: insertEmpty(extreme),
  //         expert: insertEmpty(expert),
  //         moderate: insertEmpty(moderate),
  //         soft: insertEmpty(soft),
  //         stable: insertEmpty(stable),
  //       },
  //     });
  //   }
  // };

  const fetchGoal = async () => {
    let res = await adminService.GET_GOALS();
    if (res && res.success) {
      return res.data;
    }
  };

  const handleDownload = async (e) => {
    setIsBgLoading(true);
    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      companySelectedRedux.id,
      "user_step",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsBgLoading(false);
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      setIsBgLoading(false);
    }
  };

  return (
    <StepContainerStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label="Activeness Dashboard"
              svgFront={<Icons.Activiness />}
            />
          </div>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/step_man.svg"
              titleLabel="10,000 Steps to maintain Health"
              label1="การเดินครบ 10,000 ก้าวในแต่ละวัน จะเผาผลาญพลังงานได้ประมาณ 2,000 ถึง 3,000 กิโลแคลอรี่ต่อสัปดาห์ หากมีการเผาผลาญถึง 3,500 กิโลแคลอรี่ จะสามารถลดไขมันในร่างกายไปได้ 0.5 กิโลกรัม ดังนั้นขึ้นอยู่กับปริมาณการออกกำลังกาย ซึ่งคุณสามารถลดน้ำหนัก 0.5 กิโลกรัมใน 1 สัปดาห์ได้ง่ายๆ ด้วยการเดินให้ครบ 10,000 ก้าวในแต่ละวัน"
              // label2="14.3% of employees fail in this category, which is better than the national average."
              // label3="These employees were signposted and re-routed to the company’s Employee Assistance Programme."
            />
          </div>
          <div className="block_4_col_layout">
            <div className="col_4">
              <Cards.ScoreBottom
                title="ก้าวสะสมรวม"
                subTitle="Total steps"
                colorScore="#000000"
                score={
                  dataStep && dataStep.step_count_total
                    ? `${nFormatter(Number(dataStep.step_count_total), 2)}`
                    : "0"
                }
              />
            </div>
            <div className="col_4">
              <Cards.ScoreBottom
                title="ก้าวเพิ่มล่าสุด"
                subTitle="Currently added"
                status="up"
                colorScore="#000000"
                svg={
                  <div className="mr_22">{/* <Icons.ActivenessWalk /> */}</div>
                }
                score={
                  dataStep && dataStep.step_count_today
                    ? RenderCommaMoney(
                        Number(dataStep.step_count_today).toFixed(0)
                      )
                    : "0"
                }
              />
            </div>
            <div className="col_4">
              <Cards.ScoreBottom
                title="เฉลี่ยสัปดาห์ละ"
                subTitle="Average steps"
                colorScore="#000000"
                svg={
                  <div className="mr_22">{/* <Icons.ActivenessGroup /> */}</div>
                }
                score={
                  dataStep &&
                  dataStep.previous_user_count &&
                  dataStep.this_weekly_step_count_total
                    ? RenderCommaMoney(
                        Number(
                          dataStep.this_weekly_step_count_total /
                            dataStep.previous_user_count
                        ).toFixed(0)
                      )
                    : "0"
                }
              />
            </div>
            <div className="col_4">
              <div className="wrap_mr5_overflow">
                <Cards.MeanRate
                  title={
                    <div>
                      <div>ผู้ทำสำเร็จตามเป้าหมาย</div>
                      <div>(สัปดาห์ที่ผ่านมา)</div>
                    </div>
                  }
                  subTitle="Goal Achiever Rate (last week)"
                  svg={
                    <div className="icon_wrap">
                      {/* <Icons.ActivenessGoal /> */}
                    </div>
                  }
                  txt1={`${
                    dataStep && dataStep.previous_weekly_achievement_percent
                      ? dataStep.previous_weekly_achievement_percent
                      : 0
                  } %`}
                  txt2={
                    dataStep && dataStep?.previous_weekly_achievement_count
                    // RenderCommaMoney(
                    //   Number(dataStep.achievement_percent_this_week),
                    //   true
                    // )
                  }
                />
              </div>
            </div>
          </div>
          <div className="block_2_col_layout">
            <div className="col_2 flex_1 mw_780">
              <StepIndicatorGraph
                data={dataStepAverage}
                fetchData={fetchStepAverage}
                fetchDataGoal={fetchGoal}
              />
            </div>
            <div className="col_2 w_388">
              <div className="wrap_mr5_overflow">
                <WeeklyAchiever data={dataStep && dataStep} />
              </div>
            </div>
          </div>
          <div className="export">
            <ExportRaw
              img="step"
              onDownload={handleDownload}
              minDate={moment()
                .tz("Asia/Bangkok")
                .subtract(10, "years")
                .format("01-01-YYYY")}
              rangeDate={5}
              // maxDate={moment().tz('Asia/Bangkok').endOf('month').format('MM-DD-YYYY')}
            />
          </div>
        </Blocks.Container>
      )}
    </StepContainerStyled>
  );
};

export default StepContainer;

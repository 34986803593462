export default function Reward2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g
        id="Group_13723"
        data-name="Group 13723"
        transform="translate(0 -0.32)"
      >
        <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
          <g
            id="Ellipse_8"
            data-name="Ellipse 8"
            transform="translate(0 0.32)"
            fill="#fedb41"
            stroke="#e3a52f"
            strokeWidth="2"
          >
            <circle cx="16" cy="16" r="16" stroke="none" />
            <circle cx="16" cy="16" r="15" fill="none" />
          </g>
          <g id="gym" transform="translate(6.565 9.423)">
            <g
              id="Group_180"
              data-name="Group 180"
              transform="translate(18.914 1.671)"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(0 0)"
              >
                <path
                  id="Path_286"
                  data-name="Path 286"
                  d="M480.534,131.4v10.137a1.667,1.667,0,0,0,1.238-1.609v-6.92A1.667,1.667,0,0,0,480.534,131.4Z"
                  transform="translate(-480.534 -131.405)"
                  fill="#e3a52f"
                />
              </g>
            </g>
            <g
              id="Group_182"
              data-name="Group 182"
              transform="translate(13.955 0.329)"
            >
              <g
                id="Group_181"
                data-name="Group 181"
                transform="translate(0 0)"
              >
                <path
                  id="Path_287"
                  data-name="Path 287"
                  d="M356.608,97.3h-.39a1.666,1.666,0,0,0-1.665,1.664v9.493a1.666,1.666,0,0,0,1.665,1.664h.39a1.666,1.666,0,0,0,1.664-1.664V98.965A1.666,1.666,0,0,0,356.608,97.3Z"
                  transform="translate(-354.553 -97.301)"
                  fill="#e3a52f"
                />
              </g>
            </g>
            <g
              id="Group_184"
              data-name="Group 184"
              transform="translate(7.437 4.68)"
            >
              <g id="Group_183" data-name="Group 183">
                <rect
                  id="Rectangle_1366"
                  data-name="Rectangle 1366"
                  width="5.278"
                  height="4.061"
                  fill="#e3a52f"
                />
              </g>
            </g>
            <g
              id="Group_186"
              data-name="Group 186"
              transform="translate(0 1.342)"
            >
              <g
                id="Group_185"
                data-name="Group 185"
                transform="translate(0 0)"
              >
                <path
                  id="Path_288"
                  data-name="Path 288"
                  d="M0,124.653v6.919a1.667,1.667,0,0,0,1.238,1.609V123.044A1.667,1.667,0,0,0,0,124.653Z"
                  transform="translate(0 -123.044)"
                  fill="#e3a52f"
                />
              </g>
            </g>
            <g
              id="Group_188"
              data-name="Group 188"
              transform="translate(2.479 0)"
            >
              <g id="Group_187" data-name="Group 187">
                <path
                  id="Path_289"
                  data-name="Path 289"
                  d="M65.026,88.942h-.39a1.666,1.666,0,0,0-1.664,1.664V100.1a1.666,1.666,0,0,0,1.664,1.664h.39a1.666,1.666,0,0,0,1.664-1.664V90.606A1.666,1.666,0,0,0,65.026,88.942Z"
                  transform="translate(-62.972 -88.942)"
                  fill="#e3a52f"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

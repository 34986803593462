import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { InputTextStyled } from "./styled";

const InputText = ({
  theme_standard,
  theme_input_normal,
  theme_input_mini,
  theme_input_create_account,
  theme_admin,
  theme_add_point,
  theme_input_reward_exchange,
  theme_input_border_purple,
  theme_input_mini_border_purple,
  label,
  subLabel,
  maxLength,
  minlength,
  placeholder,
  labelBottom,
  onChange,
  disabled,
  onChangeCustom,
  errors,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_input_normal: theme_input_normal,
    theme_input_mini: theme_input_mini,
    theme_input_create_account: theme_input_create_account,
    theme_admin: theme_admin,
    theme_input_reward_exchange: theme_input_reward_exchange,
    theme_add_point: theme_add_point,
    theme_input_border_purple: theme_input_border_purple,
    theme_input_mini_border_purple,
  });
  return (
    <InputTextStyled
      useLabel={subLabel}
      labelBottom={labelBottom}
      disabled={disabled}
    >
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {subLabel && <div className="sub_label">{subLabel}</div>}
        <div className="group_input_label">
          <input
            placeholder={placeholder}
            {...props}
            maxLength={maxLength && maxLength}
            minlength={minlength}
            disabled={disabled}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              onChange(e);
              onChangeCustom && onChangeCustom(e);
            }}
            onKeyPress={(e) => {
              // ใช้ในกรณี type เป็น number และมี max length
              if ((e.target.value.length >= Number(maxLength)) && maxLength) {
                e.preventDefault()
              }
            }}
          />
          {labelBottom && <div className="bottom_label">{labelBottom}</div>}
        </div>
      </div>
      {errors && <div className="error">{`* ${errors}`}</div>}
    </InputTextStyled>
  );
};

InputText.propTypes = {};

export default InputText;

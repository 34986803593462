import styled from 'styled-components'

export const BtnLanguageStyled = styled.div`
  .theme_standard_language {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-family: noto_sans_thai_regular, noto_sans_regular;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14}; 
    background: ${({ theme }) => theme.COLORS.GRAY_5};
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 1px 8px -4px rgb(0 0 0 / 45%);
    // cursor: pointer;
  }
`

export default function renderIcon({
  color = "#002366",
  width = "26.71",
  height = "30.526",
}) {
  return (
    <svg
      id="_006-user"
      data-name="006-user"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26.71 30.526"
    >
      <g id="Group_1239" data-name="Group 1239" transform="translate(9.519)">
        <g id="Group_1238" data-name="Group 1238" transform="translate(0)">
          <ellipse
            id="Ellipse_306"
            data-name="Ellipse 306"
            cx="3.829"
            cy="3.805"
            rx="3.829"
            ry="3.805"
            fill={color}
          />
        </g>
      </g>
      <g
        id="Group_1241"
        data-name="Group 1241"
        transform="translate(7.632 9.539)"
      >
        <g id="Group_1240" data-name="Group 1240">
          <path
            id="Path_3276"
            data-name="Path 3276"
            d="M171.441,168.463l-.866-6.746a1.9,1.9,0,0,0-1.9-1.717h-5.9a1.9,1.9,0,0,0-1.9,1.692l-.868,6.773a.956.956,0,0,0,.946,1.074h1.068l.757,5.914a1.9,1.9,0,0,0,1.9,1.717h2.089a1.9,1.9,0,0,0,1.9-1.692l.759-5.939h1.068a.951.951,0,0,0,.715-.324A.94.94,0,0,0,171.441,168.463Z"
            transform="translate(-160.001 -160)"
            fill={color}
          />
        </g>
      </g>
      <g id="Group_1243" data-name="Group 1243" transform="translate(0 21.412)">
        <g id="Group_1242" data-name="Group 1242">
          <path
            id="Path_3277"
            data-name="Path 3277"
            d="M50.682,359.136l-.237,1.839c4.018.588,6.357,1.767,6.357,2.505,0,1.009-4.346,2.862-11.447,2.862s-11.447-1.853-11.447-2.862c0-.738,2.339-1.919,6.359-2.505l-.237-1.839c-4.495.622-8.03,2.066-8.03,4.344,0,3.133,6.718,4.77,13.355,4.77s13.355-1.637,13.355-4.77C58.71,361.2,55.175,359.758,50.682,359.136Z"
            transform="translate(-32 -359.136)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

import styled from "styled-components";

export const BoxLabelCenterStyled = styled.div`
  .theme_standard_box_label_center {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    display: flex;
    flex-wrap: wrap;
    .left_layout {
      flex: 1;
      display: flex;
      .left_icon_show {
        display: flex;
        margin-right: 8px;
      }
      .left_label_show {
        .bottom_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
      }
    }
    .right_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      .svg_show_f {
        padding-top: 11px;
        margin-right: 5px;
      }
      .svg_show_b {
        margin-left: 3px;
      }
      .label_3 {
        font-size: ${({ theme }) => theme.FONT.SIZE.S48};
        color: ${({ theme }) => theme.COLORS.GREEN_2};
      }
      .back_label {
        margin-left: 10px;
        padding-top: 25px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S23};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }

  .theme_standard_box_4_col {
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // row-gap: 20px;
    .left_layout {
      flex: 1;
      display: flex;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .left_icon_show {
        display: flex;
        margin-right: 8px;
      }
      .left_label_show {
        .bottom_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .box_label1 {
          width: 320px;
        }
      }
    }
    .right_layout {
      flex: 1;
      display: flex;
      justify-content: center;
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      padding: 10px 0px;
      .svg_show_f {
        padding-top: 11px;
        margin-right: 5px;
      }
      .svg_show_b {
        margin-left: 3px;
      }
      .label_3 {
        font-size: ${({ theme, label3Fontsize }) =>
          label3Fontsize ? label3Fontsize : theme.FONT.SIZE.S48};
        color: ${({ theme, label3FontsColor }) =>
          label3FontsColor ? label3FontsColor : theme.COLORS.BLACK_1};
      }
      .back_label {
        margin-left: 10px;
        padding-top: 25px;
        font-size: ${({ theme, labelBackFontsize }) =>
          labelBackFontsize ? labelBackFontsize : theme.FONT.SIZE.S23};
        color: ${({ theme, labelBackFontColor }) =>
          labelBackFontColor ? labelBackFontColor : theme.COLORS.BLACK_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }
`;

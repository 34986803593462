import moment from 'moment';
import 'moment-timezone';

export const RENDER_MONTH = (month) => {
  switch (month) {
    case '01':
      return { th: 'มกราคม', en: 'January', thShort: 'ม.ค.', enShort: 'Jan' };
    case '02':
      return { th: 'กุมภาพันธ์', en: 'February', thShort: 'ก.พ.', enShort: 'Feb' };
    case '03':
      return { th: 'มีนาคม', en: 'March', thShort: 'มี.ค.', enShort: 'Mar' };
    case '04':
      return { th: 'เมษายน', en: 'April', thShort: 'เม.ย.', enShort: 'Apr' };
    case '05':
      return { th: 'พฤษภาคม', en: 'May', thShort: 'พ.ค.', enShort: 'May' };
    case '06':
      return { th: 'มิถุนายน', en: 'June', thShort: 'มิ.ย.', enShort: 'Jun' };
    case '07':
      return { th: 'กรกฎาคม', en: 'July', thShort: 'ก.ค.', enShort: 'Jul' };
    case '08':
      return { th: 'สิงหาคม', en: 'August', thShort: 'ส.ค.', enShort: 'Aug' };
    case '09':
      return { th: 'กันยายน', en: 'September', thShort: 'ก.ย.', enShort: 'Sep' };
    case '10':
      return { th: 'ตุลาคม', en: 'October', thShort: 'ต.ค.', enShort: 'Oct' };
    case '11':
      return { th: 'พฤศจิกายน', en: 'November', thShort: 'พ.ย.', enShort: 'Nov' };
    case '12':
      return { th: 'ธันวาคม', en: 'December', thShort: 'ธ.ค.', enShort: 'Dec' };
    default:
      break;
  }
};

export const RENDER_DAYS = (day) => {
  switch (day) {
    case '0':
      return { th: 'วันอาทิตย์', en: 'sunday', enShort: 'Sun' };
    case '1':
      return { th: 'วันจันทร์', en: 'Monday', enShort: 'Mon' };
    case '2':
      return { th: 'วันอังคาร', en: 'Tuesday', enShort: 'Tue' };
    case '3':
      return { th: 'วันพุธ', en: 'Wednesday', enShort: 'Wed' };
    case '4':
      return { th: 'วันพฤหัสบดี', en: 'Thursday', enShort: 'Thu' };
    case '5':
      return { th: 'วันศุกร์', en: 'Friday', enShort: 'Fri' };
    case '6':
      return { th: 'วันเสาร์', en: 'Saturday', enShort: 'Sat' };
    default:
      break;
  }
};

export const RENDER_MONTH_SELECT = (days) => {
  let month = [];
  for (var i = 0; i < days; i++) {
    month.push({
      label: `${moment().add(i, 'days').format('D MMMM')} ${moment().add(i, 'days').year() + 543
        }`,
      value: `${moment().add(i, 'days').format('YYYY-MM-DD')}`,
    });
  }
  return month;
};

export const RENDER_MONTH_SELECT_2 = (days) => {
  let month = [];
  for (var i = 1; i < days; i++) {
    month.push({
      label: `${moment().add(i, 'days').format('D MMMM')} ${moment().add(i, 'days').year() + 543
        }`,
      value: `${moment().add(i, 'days').format('YYYY-MM-DD')}`,
    });
  }
  return month;
};

export const DIF_DATE = (start, end) => {
  let start_date = moment(start, 'YYYY-MM-DD');
  let end_date = moment(end, 'YYYY-MM-DD');

  return moment.duration(end_date.diff(start_date)).asDays();
};

export const DIF_DATE_2 = (start, end) => {
  // let start_date = moment(start, "YYYY-MM-DD");
  let end_date = moment(end, 'YYYY-MM-DD');
  // if ((moment(start_date).isBefore(end_date)) === true) {
  if (moment(end_date).isBefore(moment()) === true) {
    return 0;
  } else {
    return moment
      .duration(end_date.diff(moment().format('YYYY-MM-DD')))
      .asDays();
  }
};

export const CHECK_BEFORE_AND_TODAY = (isDate) => {
  let dataCheck = moment().tz('Asia/Bangkok').format('YYYY-MM-DD');
  let today = moment().tz('Asia/Bangkok').format('YYYY-MM-DD');

  if (
    moment(today).isBefore(dataCheck) === true ||
    moment(dataCheck).isSame(today) === true
  ) {
    return true;
  } else {
    return false;
  }
};

export const GET_MONTH = (month) => {
  const dataMonth = []
  for (let i = 1; i <= 12; i++) {
    dataMonth.push({
      value: moment(`${moment().tz('Asia/Bangkok').format(`YYYY`)}-${i}-${moment().tz('Asia/Bangkok').format(`DD`)}`).tz('Asia/Bangkok').format(`YYYY-MM-DD`),
      label: moment(`${moment().tz('Asia/Bangkok').format(`YYYY`)}-${i}-${moment().tz('Asia/Bangkok').format(`DD`)}`).tz('Asia/Bangkok').format(`MMMM`),
    })
  }
  
  return dataMonth;
};
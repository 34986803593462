export default function Smoke() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65.585" height="56.026" viewBox="0 0 65.585 56.026">
      <g id="Group_286" data-name="Group 286" transform="translate(-163.326 -176.28)">
        <g id="Group_285" data-name="Group 285" transform="translate(163.326 176.28)">
          <g id="Group_277" data-name="Group 277">
            <g id="Group_220" data-name="Group 220" transform="translate(0 1.35)">
              <path id="Path_992" data-name="Path 992" d="M171.937,195.788l15.921-9.192-5.479-8.825-15.921,9.192Z" transform="translate(-164.685 -177.771)" fill="#d8d4d5" />
              <path id="Path_993" data-name="Path 993" d="M170.2,200.126l15.921-9.192-5.7-4.175L164.5,195.951Z" transform="translate(-164.501 -178.618)" fill="#9e9395" />
              <path id="Path_994" data-name="Path 994" d="M189.33,186.6l-1.555,3.491-5.7-4.175,1.772-8.141Z" transform="translate(-166.158 -177.771)" fill="#aea6a8" />
              <path id="Path_995" data-name="Path 995" d="M170.79,200.2l15.921-9.192,1.555-3.491-15.921,9.192Z" transform="translate(-165.094 -178.689)" fill="#ff0007" />
              <path id="Path_996" data-name="Path 996" d="M172.507,196.706l15.921-9.192v14.1l-15.921,9.192Z" transform="translate(-165.255 -178.689)" fill="#d8d4d5" />
              <path id="Path_997" data-name="Path 997" d="M171.752,196.744l-1.555,3.491-5.7-4.175,1.772-8.141Z" transform="translate(-164.501 -178.728)" fill="#cc0007" />
            </g>
            <g id="Group_221" data-name="Group 221" transform="translate(22.624 8.016)">
              <path id="Path_998" data-name="Path 998" d="M197.44,188.882l-.548.306-7.413-3.752.548-.306Z" transform="translate(-189.479 -185.13)" fill="#ede5e6" />
              <path id="Path_999" data-name="Path 999" d="M196.892,189.22v14.518l-7.412-4.281V185.468Z" transform="translate(-189.479 -185.162)" fill="#aea6a8" />
            </g>
            <g id="Group_224" data-name="Group 224" transform="translate(20.177 8.548)">
              <path id="Path_1000" data-name="Path 1000" d="M189.742,195.606l-.348.246c-.029-.019-.058-.038-.09-.056a2.316,2.316,0,0,0-2.1,0,1.045,1.045,0,0,0-.094.06l-.336-.246V210.05h0a.743.743,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.728.728,0,0,0,.431-.607h0Z" transform="translate(-186.777 -186.644)" fill="#dce4e4" />
              <g id="Group_223" data-name="Group 223" transform="translate(0 0.881)">
                <path id="Path_1001" data-name="Path 1001" d="M189.741,186.753v8.017a.726.726,0,0,1-.431.608,2.312,2.312,0,0,1-2.094,0,.749.749,0,0,1-.438-.6v-8.017l.337.247c.029-.02.06-.041.094-.062a2.312,2.312,0,0,1,2.1.005c.031.017.06.036.089.056Z" transform="translate(-186.777 -186.69)" fill="#f1a443" />
                <g id="Group_222" data-name="Group 222" transform="translate(0 0.316)">
                  <path id="Path_1002" data-name="Path 1002" d="M187.8,193.307c-.053-.032-.112-.072-.118-.134a.441.441,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.056-.043-.129-.074-.153-.14a.345.345,0,0,0-.105.1.12.12,0,0,0,0,.138c.086.094.08.04.03.144-.029.061-.055.128.014.179C187.549,193.572,187.73,193.385,187.8,193.307Z" transform="translate(-186.832 -187.593)" fill="#fcd07f" />
                  <path id="Path_1003" data-name="Path 1003" d="M190.019,194.24v.253a.233.233,0,0,0-.108.1c-.074-.08-.249-.27-.149-.39.054-.065.12-.034.178,0S190.006,194.249,190.019,194.24Z" transform="translate(-187.055 -187.711)" fill="#fcd07f" />
                  <path id="Path_1004" data-name="Path 1004" d="M187.1,195.093c-.062.065-.2.21-.32.195v-.575c.028.062.1.092.149.131a.113.113,0,0,1,.05.066.421.421,0,0,1,0,.048C186.985,195.02,187.043,195.061,187.1,195.093Z" transform="translate(-186.777 -187.762)" fill="#fcd07f" />
                  <path id="Path_1005" data-name="Path 1005" d="M187.094,189.451a.212.212,0,0,1,.074.172.492.492,0,0,0-.014.049.123.123,0,0,0,.029.081c.043.06.105.115.108.188a.356.356,0,0,0,.137-.066.125.125,0,0,0,.04-.138c-.056-.122-.067-.065.016-.154.048-.051.1-.109.043-.182C187.433,189.269,187.193,189.4,187.094,189.451Z" transform="translate(-186.807 -187.256)" fill="#fcd07f" />
                  <path id="Path_1006" data-name="Path 1006" d="M188.823,190.87c-.037-.031-.079-.069-.077-.118a.3.3,0,0,0,0-.039.087.087,0,0,0-.032-.056c-.039-.039-.092-.072-.1-.127a.3.3,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.059.04.008.116-.03.044-.058.094-.009.141C188.6,191.048,188.757,190.923,188.823,190.87Z" transform="translate(-186.938 -187.368)" fill="#fcd07f" />
                  <path id="Path_1007" data-name="Path 1007" d="M190.023,188.73V189a.282.282,0,0,0-.036.081c-.073-.041-.252-.144-.2-.257.025-.062.082-.055.134-.044.089.019.048.034.1-.05Z" transform="translate(-187.06 -187.198)" fill="#fcd07f" />
                  <path id="Path_1008" data-name="Path 1008" d="M186.99,190.947a.54.54,0,0,1-.213.119v-.457l0,0c.013.054.065.087.105.127a.1.1,0,0,1,.033.054.281.281,0,0,1-.005.04C186.911,190.876,186.953,190.916,186.99,190.947Z" transform="translate(-186.777 -187.375)" fill="#fcd07f" />
                  <path id="Path_1009" data-name="Path 1009" d="M187.685,190.724a.164.164,0,0,1-.13.063.3.3,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.045.035-.086.085-.143.09a.281.281,0,0,0,.056.105.1.1,0,0,0,.109.025c.093-.047.049-.054.12.007.042.036.089.071.144.028C187.838,190.981,187.731,190.8,187.685,190.724Z" transform="translate(-186.827 -187.386)" fill="#fcd07f" />
                  <path id="Path_1010" data-name="Path 1010" d="M189.571,192.425a.177.177,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.071.323.323,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.055.09-.127C189.886,192.388,189.663,192.413,189.571,192.425Z" transform="translate(-187.038 -187.546)" fill="#fcd07f" />
                  <path id="Path_1011" data-name="Path 1011" d="M187.375,194.58a.155.155,0,0,1,.025-.137c.009-.009.021-.015.029-.025a.094.094,0,0,0,.015-.062.287.287,0,0,1,.012-.162.257.257,0,0,0-.113-.015.092.092,0,0,0-.082.069c-.016.1.014.069-.074.089-.052.012-.105.029-.1.1C187.089,194.553,187.29,194.573,187.375,194.58Z" transform="translate(-186.806 -187.712)" fill="#fcd07f" />
                  <path id="Path_1012" data-name="Path 1012" d="M188.5,187.949a.271.271,0,0,0,.009-.047.041.041,0,0,0-.023-.04.073.073,0,0,0-.024,0,.12.12,0,0,0-.126.149c0,.073-.04.21.082.228.015,0,.032,0,.048,0a.088.088,0,0,1,.073.057c.007.019.007.04.014.06s.024.037.044.034.034-.032.039-.054a.231.231,0,0,0-.025-.219C188.555,188.061,188.475,188.047,188.5,187.949Z" transform="translate(-186.923 -187.116)" fill="#fcd07f" />
                  <path id="Path_1013" data-name="Path 1013" d="M189.07,187.246a.31.31,0,0,0,.055.014.049.049,0,0,0,.048-.024.077.077,0,0,0,0-.029.14.14,0,0,0-.169-.154c-.085-.007-.244-.055-.27.086,0,.018,0,.037,0,.055a.1.1,0,0,1-.07.084c-.023.007-.047.006-.07.014a.052.052,0,0,0-.043.05c0,.028.034.041.062.048a.266.266,0,0,0,.257-.02C188.936,187.313,188.956,187.22,189.07,187.246Z" transform="translate(-186.944 -187.039)" fill="#fcd07f" />
                  <path id="Path_1014" data-name="Path 1014" d="M187.136,191.662a.252.252,0,0,0,.01-.051.043.043,0,0,0-.024-.042.063.063,0,0,0-.026,0,.171.171,0,0,0-.091.029.176.176,0,0,0-.04.13c0,.078-.039.223.089.24.017,0,.034,0,.051,0a.093.093,0,0,1,.079.06c.008.02.008.043.015.063a.048.048,0,0,0,.048.036c.024,0,.034-.034.04-.058a.244.244,0,0,0-.029-.232C187.2,191.781,187.117,191.767,187.136,191.662Z" transform="translate(-186.794 -187.466)" fill="#fcd07f" />
                  <path id="Path_1015" data-name="Path 1015" d="M189.489,194.948a.264.264,0,0,0,.023-.045.043.043,0,0,0-.012-.046.06.06,0,0,0-.025-.008.162.162,0,0,0-.1,0,.172.172,0,0,0-.072.114c-.024.073-.1.2.022.253.015.006.033.008.048.014a.093.093,0,0,1,.06.078c0,.022,0,.043,0,.064s.014.044.035.047.043-.023.054-.045a.241.241,0,0,0,.033-.228C189.519,195.079,189.442,195.043,189.489,194.948Z" transform="translate(-187.011 -187.774)" fill="#fcd07f" />
                  <path id="Path_1016" data-name="Path 1016" d="M187.658,188.7a.271.271,0,0,0,.016.048.042.042,0,0,0,.04.025.072.072,0,0,0,.024-.012.162.162,0,0,0,.065-.068.175.175,0,0,0-.026-.132c-.034-.068-.072-.21-.19-.166-.015.006-.029.017-.044.024a.093.093,0,0,1-.1-.014c-.016-.014-.026-.034-.043-.047a.047.047,0,0,0-.058-.009c-.02.015-.014.045-.007.07.024.079.051.159.134.186S187.625,188.6,187.658,188.7Z" transform="translate(-186.829 -187.162)" fill="#fcd07f" />
                  <path id="Path_1017" data-name="Path 1017" d="M186.985,188a.166.166,0,0,1-.065.067.063.063,0,0,1-.024.013.043.043,0,0,1-.039-.024.2.2,0,0,1-.015-.048.1.1,0,0,0-.064-.072V187.7c.112-.034.149.1.182.169A.176.176,0,0,1,186.985,188Z" transform="translate(-186.777 -187.101)" fill="#fcd07f" />
                  <path id="Path_1018" data-name="Path 1018" d="M187.836,192.235a.3.3,0,0,0-.05.019.044.044,0,0,0-.024.043.06.06,0,0,0,.013.024.168.168,0,0,0,.075.065.18.18,0,0,0,.138-.034c.071-.039.219-.085.166-.208-.007-.016-.019-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.017-.02-.049-.014-.073,0-.082.029-.167.062-.19.15S187.939,192.195,187.836,192.235Z" transform="translate(-186.87 -187.495)" fill="#fcd07f" />
                  <path id="Path_1019" data-name="Path 1019" d="M187.009,193.352a.165.165,0,0,1-.077.065.057.057,0,0,1-.024.009.04.04,0,0,1-.039-.031.194.194,0,0,1-.012-.052.11.11,0,0,0-.08-.09v-.223a.278.278,0,0,1,.038-.015c.129-.033.153.12.18.2A.175.175,0,0,1,187.009,193.352Z" transform="translate(-186.777 -187.602)" fill="#fcd07f" />
                  <path id="Path_1020" data-name="Path 1020" d="M189.492,191.423a.151.151,0,0,0,.025.016c.086.048.132-.072.141-.137a.24.24,0,0,0-.067-.209c-.062-.057-.247-.087-.253.024a.087.087,0,0,0,.013.061.235.235,0,0,0,.029.024c.03.026.029.071.038.11A.208.208,0,0,0,189.492,191.423Z" transform="translate(-187.018 -187.416)" fill="#fcd07f" />
                  <path id="Path_1021" data-name="Path 1021" d="M189.01,193.635a.231.231,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.023-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.044.224.224,0,0,0-.007.042c-.01.043-.054.067-.088.1A.228.228,0,0,0,189.01,193.635Z" transform="translate(-186.987 -187.63)" fill="#fcd07f" />
                  <path id="Path_1022" data-name="Path 1022" d="M189.374,187.885a.214.214,0,0,0-.027.021c-.082.075.031.168.1.2a.268.268,0,0,0,.247,0c.083-.045.182-.234.064-.279a.1.1,0,0,0-.069-.008.334.334,0,0,0-.035.023c-.039.023-.087.006-.13,0A.227.227,0,0,0,189.374,187.885Z" transform="translate(-187.017 -187.112)" fill="#fcd07f" />
                  <path id="Path_1023" data-name="Path 1023" d="M189.084,188.979c0,.011,0,.022,0,.032,0,.1.135.081.2.055a.251.251,0,0,0,.153-.169c.018-.085-.052-.268-.154-.215a.1.1,0,0,0-.048.043.258.258,0,0,0-.005.038c-.008.04-.049.063-.078.091A.211.211,0,0,0,189.084,188.979Z" transform="translate(-186.994 -187.193)" fill="#fcd07f" />
                  <path id="Path_1024" data-name="Path 1024" d="M188.71,192.172a.162.162,0,0,0-.015.024c-.046.082.07.127.132.136a.23.23,0,0,0,.2-.064c.054-.06.084-.239-.024-.244a.085.085,0,0,0-.058.012.342.342,0,0,0-.023.028c-.025.028-.069.028-.105.037A.194.194,0,0,0,188.71,192.172Z" transform="translate(-186.957 -187.509)" fill="#fcd07f" />
                  <path id="Path_1025" data-name="Path 1025" d="M189.405,190.071c0,.01.007.019.012.029.044.089.153.015.2-.034a.243.243,0,0,0,.065-.214c-.019-.082-.157-.216-.225-.126a.09.09,0,0,0-.024.058.237.237,0,0,0,.01.036c.01.039-.016.076-.031.113A.208.208,0,0,0,189.405,190.071Z" transform="translate(-187.024 -187.289)" fill="#fcd07f" />
                  <path id="Path_1026" data-name="Path 1026" d="M187.645,196.066c.065.054.153.12.156-.025,0-.081-.1-.17-.176-.181-.035-.005-.079,0-.1-.031s.017-.1-.015-.122a.157.157,0,0,0,.026.305.132.132,0,0,1,.057.014C187.609,196.034,187.626,196.049,187.645,196.066Z" transform="translate(-186.836 -187.856)" fill="#fcd07f" />
                  <path id="Path_1027" data-name="Path 1027" d="M190.017,190.408v.168c-.024-.015-.058-.005-.089,0a.227.227,0,0,1-.212-.139c-.027-.143.072-.1.149-.059l.056.029a.129.129,0,0,0,.059,0A.131.131,0,0,1,190.017,190.408Z" transform="translate(-187.054 -187.35)" fill="#fcd07f" />
                  <path id="Path_1028" data-name="Path 1028" d="M187.615,195.272c-.037.082-.078.188.07.151a.239.239,0,0,0,.132-.227c0-.037-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C187.637,195.228,187.626,195.249,187.615,195.272Z" transform="translate(-186.853 -187.786)" fill="#fcd07f" />
                  <path id="Path_1029" data-name="Path 1029" d="M188.842,195.894c.04-.062.086-.143-.038-.13a.194.194,0,0,0-.136.168c0,.031.008.067-.015.086s-.083-.005-.1.026a.135.135,0,0,0,.256-.055.113.113,0,0,1,.006-.05C188.819,195.928,188.83,195.912,188.842,195.894Z" transform="translate(-186.944 -187.861)" fill="#fcd07f" />
                  <path id="Path_1030" data-name="Path 1030" d="M187.393,187.466c0,.091,0,.207.122.115a.24.24,0,0,0,.038-.264c-.017-.034-.05-.067-.036-.1s.091-.047.092-.092a.166.166,0,0,0-.239.219.146.146,0,0,1,.025.057C187.4,187.416,187.394,187.44,187.393,187.466Z" transform="translate(-186.831 -187.044)" fill="#fcd07f" />
                  <path id="Path_1031" data-name="Path 1031" d="M188.327,194.33c-.048.071-.1.164.041.152.08-.006.157-.119.16-.192,0-.035-.007-.078.021-.1s.1.008.119-.028a.156.156,0,0,0-.3.057.124.124,0,0,1-.008.058C188.354,194.291,188.34,194.31,188.327,194.33Z" transform="translate(-186.918 -187.702)" fill="#fcd07f" />
                  <path id="Path_1032" data-name="Path 1032" d="M188.3,189.684c-.07,0-.159-.005-.091.092a.187.187,0,0,0,.2.035c.025-.013.053-.037.079-.026s.034.072.07.074a.129.129,0,0,0-.165-.19.108.108,0,0,1-.045.018Z" transform="translate(-186.909 -187.285)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1033" data-name="Path 1033" d="M189.31,187.18a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.638.638,0,0,1,189.31,187.18Z" transform="translate(-186.777 -185.718)" fill="#f1f4f4" />
            </g>
            <g id="Group_227" data-name="Group 227" transform="translate(22.871 9.679)">
              <path id="Path_1034" data-name="Path 1034" d="M192.716,196.854l-.348.246c-.028-.019-.057-.038-.089-.056a2.316,2.316,0,0,0-2.1,0q-.05.029-.1.06l-.336-.246V211.3h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.725.725,0,0,0,.43-.607h0Z" transform="translate(-189.752 -187.892)" fill="#dce4e4" />
              <g id="Group_226" data-name="Group 226" transform="translate(0 0.881)">
                <path id="Path_1035" data-name="Path 1035" d="M192.716,188v8.016a.73.73,0,0,1-.431.608,2.314,2.314,0,0,1-2.1,0,.748.748,0,0,1-.437-.6V188l.336.247c.03-.021.061-.042.1-.062a2.313,2.313,0,0,1,2.1.005.94.94,0,0,1,.09.057Z" transform="translate(-189.752 -187.939)" fill="#f1a443" />
                <g id="Group_225" data-name="Group 225" transform="translate(0 0.316)">
                  <path id="Path_1036" data-name="Path 1036" d="M190.778,194.556c-.053-.033-.112-.073-.118-.135a.293.293,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.057-.043-.129-.074-.153-.14a.36.36,0,0,0-.106.1.121.121,0,0,0,0,.138c.087.1.081.04.031.144-.029.061-.055.128.014.179C190.523,194.82,190.7,194.633,190.778,194.556Z" transform="translate(-189.807 -188.841)" fill="#fcd07f" />
                  <path id="Path_1037" data-name="Path 1037" d="M192.994,195.488v.253a.239.239,0,0,0-.109.1c-.073-.079-.248-.27-.149-.39.055-.065.12-.034.179,0S192.98,195.5,192.994,195.488Z" transform="translate(-190.03 -188.959)" fill="#fcd07f" />
                  <path id="Path_1038" data-name="Path 1038" d="M190.072,196.341c-.062.066-.2.21-.32.195v-.575c.027.062.094.092.149.132a.118.118,0,0,1,.05.065.424.424,0,0,1,0,.048C189.959,196.268,190.018,196.309,190.072,196.341Z" transform="translate(-189.752 -189.01)" fill="#fcd07f" />
                  <path id="Path_1039" data-name="Path 1039" d="M190.069,190.7a.212.212,0,0,1,.074.171.312.312,0,0,0-.014.05.117.117,0,0,0,.029.08c.043.06.1.115.108.189a.379.379,0,0,0,.137-.067.123.123,0,0,0,.039-.138c-.055-.122-.067-.065.016-.153.048-.052.1-.11.043-.183C190.408,190.517,190.168,190.644,190.069,190.7Z" transform="translate(-189.782 -188.504)" fill="#fcd07f" />
                  <path id="Path_1040" data-name="Path 1040" d="M191.8,192.118c-.038-.031-.08-.069-.077-.118a.285.285,0,0,0,0-.039.093.093,0,0,0-.032-.055c-.04-.04-.092-.072-.1-.127a.265.265,0,0,0-.093.066.1.1,0,0,0-.012.108c.057.083.058.04.007.116-.029.044-.057.094-.009.141C191.569,192.3,191.731,192.171,191.8,192.118Z" transform="translate(-189.913 -188.616)" fill="#fcd07f" />
                  <path id="Path_1041" data-name="Path 1041" d="M193,189.978v.273a.255.255,0,0,0-.036.082c-.074-.042-.253-.145-.2-.258.026-.062.082-.054.134-.044.09.019.048.034.105-.05Z" transform="translate(-190.034 -188.447)" fill="#fcd07f" />
                  <path id="Path_1042" data-name="Path 1042" d="M189.965,192.195a.547.547,0,0,1-.213.119v-.456l0,0c.012.054.065.087.1.127a.1.1,0,0,1,.033.055.285.285,0,0,1,0,.039A.164.164,0,0,0,189.965,192.195Z" transform="translate(-189.752 -188.623)" fill="#fcd07f" />
                  <path id="Path_1043" data-name="Path 1043" d="M190.66,191.972c-.036.034-.082.072-.13.063a.315.315,0,0,0-.04-.009.1.1,0,0,0-.061.025c-.045.035-.086.085-.143.091a.284.284,0,0,0,.056.1.1.1,0,0,0,.108.025c.093-.047.049-.054.12.007.042.036.089.072.143.028C190.813,192.229,190.705,192.047,190.66,191.972Z" transform="translate(-189.802 -188.635)" fill="#fcd07f" />
                  <path id="Path_1044" data-name="Path 1044" d="M192.545,193.673a.171.171,0,0,1,0,.154c-.007.012-.018.021-.025.034a.1.1,0,0,0,0,.071.314.314,0,0,1,.024.178.309.309,0,0,0,.126-.009.1.1,0,0,0,.072-.093c0-.111-.031-.071.06-.113.053-.024.107-.054.089-.127C192.86,193.637,192.638,193.661,192.545,193.673Z" transform="translate(-190.013 -188.794)" fill="#fcd07f" />
                  <path id="Path_1045" data-name="Path 1045" d="M190.35,195.829a.158.158,0,0,1,.025-.138c.009-.009.02-.015.029-.025a.093.093,0,0,0,.014-.062.3.3,0,0,1,.012-.162.266.266,0,0,0-.112-.015.094.094,0,0,0-.082.069c-.016.1.015.069-.073.089-.052.013-.106.029-.1.1C190.063,195.8,190.266,195.822,190.35,195.829Z" transform="translate(-189.781 -188.96)" fill="#fcd07f" />
                  <path id="Path_1046" data-name="Path 1046" d="M191.469,189.2a.274.274,0,0,0,.01-.047.041.041,0,0,0-.023-.04.056.056,0,0,0-.024,0,.151.151,0,0,0-.087.026.167.167,0,0,0-.04.122c0,.073-.039.21.082.228.016,0,.033,0,.048,0a.09.09,0,0,1,.074.057c.006.019.006.04.014.06a.045.045,0,0,0,.044.034c.024,0,.034-.032.038-.054a.228.228,0,0,0-.024-.219C191.529,189.31,191.45,189.3,191.469,189.2Z" transform="translate(-189.898 -188.365)" fill="#fcd07f" />
                  <path id="Path_1047" data-name="Path 1047" d="M192.045,188.5a.376.376,0,0,0,.055.013.046.046,0,0,0,.047-.024.063.063,0,0,0,0-.029.19.19,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.055a.1.1,0,0,1-.07.084c-.024.007-.048.006-.071.014s-.045.026-.043.05.035.041.063.048a.264.264,0,0,0,.256-.02C191.911,188.561,191.93,188.468,192.045,188.5Z" transform="translate(-189.919 -188.287)" fill="#fcd07f" />
                  <path id="Path_1048" data-name="Path 1048" d="M190.111,192.91a.313.313,0,0,0,.009-.051.042.042,0,0,0-.024-.042.064.064,0,0,0-.026,0,.166.166,0,0,0-.091.029.178.178,0,0,0-.041.131c0,.077-.039.222.09.24.016,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.007.02.008.043.015.063s.026.04.047.036.035-.034.041-.058a.244.244,0,0,0-.03-.232C190.177,193.029,190.092,193.015,190.111,192.91Z" transform="translate(-189.769 -188.714)" fill="#fcd07f" />
                  <path id="Path_1049" data-name="Path 1049" d="M192.463,196.2a.26.26,0,0,0,.022-.045.042.042,0,0,0-.012-.046.06.06,0,0,0-.024-.008.169.169,0,0,0-.1,0,.177.177,0,0,0-.073.114c-.023.073-.1.2.023.253a.413.413,0,0,0,.048.014.1.1,0,0,1,.06.078c0,.022,0,.043,0,.064a.048.048,0,0,0,.036.047c.024,0,.042-.023.053-.045a.238.238,0,0,0,.034-.228C192.494,196.327,192.417,196.292,192.463,196.2Z" transform="translate(-189.986 -189.023)" fill="#fcd07f" />
                  <path id="Path_1050" data-name="Path 1050" d="M190.633,189.951a.271.271,0,0,0,.015.048.042.042,0,0,0,.041.025.06.06,0,0,0,.023-.012.157.157,0,0,0,.065-.068.168.168,0,0,0-.026-.132c-.034-.068-.071-.21-.189-.166-.016.006-.029.017-.044.024a.09.09,0,0,1-.1-.014c-.016-.014-.027-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.015.045-.008.07.024.079.052.159.134.187S190.6,189.851,190.633,189.951Z" transform="translate(-189.804 -188.41)" fill="#fcd07f" />
                  <path id="Path_1051" data-name="Path 1051" d="M189.959,189.248a.15.15,0,0,1-.065.067.06.06,0,0,1-.024.013c-.015,0-.031-.011-.04-.024a.326.326,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.236c.111-.034.149.1.182.17A.173.173,0,0,1,189.959,189.248Z" transform="translate(-189.752 -188.349)" fill="#fcd07f" />
                  <path id="Path_1052" data-name="Path 1052" d="M190.81,193.483a.332.332,0,0,0-.05.019.044.044,0,0,0-.024.043.076.076,0,0,0,.014.024.176.176,0,0,0,.075.066.181.181,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.006-.016-.019-.03-.026-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.047-.047a.051.051,0,0,0,.007-.062c-.017-.02-.049-.014-.074,0-.082.029-.166.062-.189.15S190.913,193.443,190.81,193.483Z" transform="translate(-189.845 -188.743)" fill="#fcd07f" />
                  <path id="Path_1053" data-name="Path 1053" d="M189.984,194.6a.171.171,0,0,1-.077.065.078.078,0,0,1-.024.008.041.041,0,0,1-.04-.031.307.307,0,0,1-.012-.052.106.106,0,0,0-.079-.089v-.224a.207.207,0,0,1,.038-.015c.129-.033.152.12.18.2A.177.177,0,0,1,189.984,194.6Z" transform="translate(-189.752 -188.85)" fill="#fcd07f" />
                  <path id="Path_1054" data-name="Path 1054" d="M192.467,192.671a.176.176,0,0,0,.025.017c.085.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.057-.248-.087-.253.024a.088.088,0,0,0,.013.061.183.183,0,0,0,.029.024c.029.026.029.071.038.11A.2.2,0,0,0,192.467,192.671Z" transform="translate(-189.993 -188.665)" fill="#fcd07f" />
                  <path id="Path_1055" data-name="Path 1055" d="M191.984,194.884a.208.208,0,0,0,0,.034c0,.111.144.091.212.066a.271.271,0,0,0,.171-.179c.023-.091-.048-.292-.16-.236a.1.1,0,0,0-.053.044.335.335,0,0,0-.008.042c-.01.043-.054.067-.087.1A.231.231,0,0,0,191.984,194.884Z" transform="translate(-189.962 -188.878)" fill="#fcd07f" />
                  <path id="Path_1056" data-name="Path 1056" d="M192.348,189.133l-.027.021c-.081.075.032.168.1.2a.273.273,0,0,0,.248,0c.082-.045.181-.234.064-.278a.1.1,0,0,0-.07-.009.388.388,0,0,0-.035.024c-.038.022-.086.005-.13,0A.227.227,0,0,0,192.348,189.133Z" transform="translate(-189.991 -188.361)" fill="#fcd07f" />
                  <path id="Path_1057" data-name="Path 1057" d="M192.059,190.228a.175.175,0,0,0,0,.031c0,.1.135.081.2.055a.25.25,0,0,0,.152-.169c.019-.085-.052-.268-.153-.215a.091.091,0,0,0-.048.043.258.258,0,0,0-.006.038c-.008.04-.048.063-.078.091A.212.212,0,0,0,192.059,190.228Z" transform="translate(-189.969 -188.441)" fill="#fcd07f" />
                  <path id="Path_1058" data-name="Path 1058" d="M191.685,193.42a.162.162,0,0,0-.016.024c-.045.082.071.127.132.137a.232.232,0,0,0,.2-.065c.055-.059.084-.238-.023-.244a.084.084,0,0,0-.059.013.159.159,0,0,0-.022.027c-.025.029-.069.028-.106.037A.194.194,0,0,0,191.685,193.42Z" transform="translate(-189.932 -188.757)" fill="#fcd07f" />
                  <path id="Path_1059" data-name="Path 1059" d="M192.38,191.319a.2.2,0,0,0,.012.029c.044.089.152.015.2-.034a.245.245,0,0,0,.065-.213c-.019-.083-.157-.216-.225-.127a.087.087,0,0,0-.025.058.239.239,0,0,0,.011.036c.009.039-.016.076-.031.113A.208.208,0,0,0,192.38,191.319Z" transform="translate(-189.999 -188.538)" fill="#fcd07f" />
                  <path id="Path_1060" data-name="Path 1060" d="M190.619,197.314c.065.055.154.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.129.129,0,0,1,.056.014C190.583,197.282,190.6,197.3,190.619,197.314Z" transform="translate(-189.811 -189.104)" fill="#fcd07f" />
                  <path id="Path_1061" data-name="Path 1061" d="M192.992,191.656v.168c-.024-.015-.059-.005-.09,0a.227.227,0,0,1-.211-.139c-.027-.143.072-.1.148-.059.023.012.043.023.057.029a.125.125,0,0,0,.058,0A.184.184,0,0,1,192.992,191.656Z" transform="translate(-190.029 -188.598)" fill="#fcd07f" />
                  <path id="Path_1062" data-name="Path 1062" d="M190.59,196.521c-.037.081-.078.187.069.15a.237.237,0,0,0,.133-.227c0-.037-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.137.137,0,0,1,0,.061C190.612,196.476,190.6,196.5,190.59,196.521Z" transform="translate(-189.828 -189.034)" fill="#fcd07f" />
                  <path id="Path_1063" data-name="Path 1063" d="M191.816,197.142c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.008.067-.016.085s-.082,0-.1.026a.135.135,0,0,0,.257-.055.1.1,0,0,1,.005-.05C191.793,197.176,191.8,197.16,191.816,197.142Z" transform="translate(-189.919 -189.109)" fill="#fcd07f" />
                  <path id="Path_1064" data-name="Path 1064" d="M190.368,188.714c0,.091,0,.206.122.115a.242.242,0,0,0,.038-.264c-.018-.033-.051-.067-.036-.1s.091-.047.092-.092a.174.174,0,0,0-.207.014.172.172,0,0,0-.032.206.135.135,0,0,1,.024.056C190.371,188.664,190.369,188.688,190.368,188.714Z" transform="translate(-189.806 -188.293)" fill="#fcd07f" />
                  <path id="Path_1065" data-name="Path 1065" d="M191.3,195.578c-.048.071-.1.164.041.152a.226.226,0,0,0,.16-.192c0-.035-.008-.078.021-.1s.1.008.119-.028a.156.156,0,0,0-.3.058.122.122,0,0,1-.007.057C191.329,195.54,191.315,195.558,191.3,195.578Z" transform="translate(-189.893 -188.95)" fill="#fcd07f" />
                  <path id="Path_1066" data-name="Path 1066" d="M191.272,190.932c-.07,0-.16-.005-.092.093a.187.187,0,0,0,.2.034c.026-.013.053-.037.08-.026s.034.072.07.074a.129.129,0,0,0-.166-.19.1.1,0,0,1-.044.018Z" transform="translate(-189.884 -188.534)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1067" data-name="Path 1067" d="M192.285,188.429a2.319,2.319,0,0,1-2.1,0,.638.638,0,0,1-.006-1.209,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,192.285,188.429Z" transform="translate(-189.752 -186.966)" fill="#f1f4f4" />
            </g>
            <g id="Group_230" data-name="Group 230" transform="translate(25.646 7.961)">
              <path id="Path_1068" data-name="Path 1068" d="M195.78,194.957l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0,1.057,1.057,0,0,0-.094.061l-.336-.247V209.4h0a.744.744,0,0,0,.437.6,2.314,2.314,0,0,0,2.1,0,.728.728,0,0,0,.431-.607h0Z" transform="translate(-192.816 -185.995)" fill="#dce4e4" />
              <g id="Group_229" data-name="Group 229" transform="translate(0 0.881)">
                <path id="Path_1069" data-name="Path 1069" d="M195.779,186.105v8.016a.726.726,0,0,1-.43.608,2.317,2.317,0,0,1-2.1,0,.749.749,0,0,1-.437-.605v-8.016l.336.247c.029-.021.06-.042.094-.062a2.318,2.318,0,0,1,2.1.005c.031.017.06.036.09.057Z" transform="translate(-192.816 -186.042)" fill="#f1a443" />
                <g id="Group_228" data-name="Group 228" transform="translate(0 0.315)">
                  <path id="Path_1070" data-name="Path 1070" d="M193.842,192.659c-.053-.032-.112-.073-.118-.135a.442.442,0,0,0,0-.049.115.115,0,0,0-.049-.064c-.057-.042-.13-.074-.154-.14a.338.338,0,0,0-.105.1.118.118,0,0,0,0,.137c.086.1.08.04.03.145-.029.061-.055.127.014.178C193.587,192.923,193.768,192.737,193.842,192.659Z" transform="translate(-192.871 -186.944)" fill="#fcd07f" />
                  <path id="Path_1071" data-name="Path 1071" d="M196.057,193.591v.253a.233.233,0,0,0-.108.1c-.074-.079-.249-.27-.149-.389.055-.065.12-.034.178,0S196.044,193.6,196.057,193.591Z" transform="translate(-193.094 -187.062)" fill="#fcd07f" />
                  <path id="Path_1072" data-name="Path 1072" d="M193.135,194.445c-.062.065-.2.209-.319.194v-.575c.027.062.094.092.149.132a.113.113,0,0,1,.05.065.428.428,0,0,1,0,.048C193.023,194.371,193.081,194.413,193.135,194.445Z" transform="translate(-192.816 -187.113)" fill="#fcd07f" />
                  <path id="Path_1073" data-name="Path 1073" d="M193.132,188.8a.21.21,0,0,1,.074.171.446.446,0,0,0-.014.05.125.125,0,0,0,.029.08c.043.06.105.115.108.189a.369.369,0,0,0,.137-.066.124.124,0,0,0,.04-.138c-.055-.122-.067-.066.016-.154.048-.052.1-.11.043-.183C193.471,188.621,193.232,188.748,193.132,188.8Z" transform="translate(-192.846 -186.607)" fill="#fcd07f" />
                  <path id="Path_1074" data-name="Path 1074" d="M194.862,190.221c-.038-.031-.08-.069-.077-.118,0-.013,0-.025,0-.039a.093.093,0,0,0-.032-.055c-.04-.039-.093-.072-.1-.127a.29.29,0,0,0-.094.066.1.1,0,0,0-.011.108c.056.084.058.041.007.117-.029.043-.057.093-.009.14C194.633,190.4,194.8,190.274,194.862,190.221Z" transform="translate(-192.977 -186.719)" fill="#fcd07f" />
                  <path id="Path_1075" data-name="Path 1075" d="M196.061,188.081v.274a.261.261,0,0,0-.036.081c-.073-.042-.252-.144-.2-.258.025-.062.082-.054.134-.044.089.019.048.034.105-.05Z" transform="translate(-193.098 -186.55)" fill="#fcd07f" />
                  <path id="Path_1076" data-name="Path 1076" d="M193.029,190.3a.553.553,0,0,1-.213.119v-.456l0,0c.013.055.065.088.105.127a.1.1,0,0,1,.033.055.325.325,0,0,1-.005.04C192.949,190.228,192.991,190.267,193.029,190.3Z" transform="translate(-192.816 -186.726)" fill="#fcd07f" />
                  <path id="Path_1077" data-name="Path 1077" d="M193.723,190.075a.164.164,0,0,1-.13.063.27.27,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.045.035-.086.085-.143.091a.29.29,0,0,0,.056.1.1.1,0,0,0,.109.026c.093-.048.049-.054.12.007.041.035.088.071.143.027C193.877,190.332,193.769,190.15,193.723,190.075Z" transform="translate(-192.866 -186.738)" fill="#fcd07f" />
                  <path id="Path_1078" data-name="Path 1078" d="M195.609,191.776a.174.174,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.07.317.317,0,0,1,.025.178.3.3,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.111-.032-.071.06-.113.053-.024.106-.054.089-.127C195.924,191.74,195.7,191.764,195.609,191.776Z" transform="translate(-193.077 -186.897)" fill="#fcd07f" />
                  <path id="Path_1079" data-name="Path 1079" d="M193.413,193.932a.161.161,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.09.09,0,0,0,.015-.062.29.29,0,0,1,.012-.163.286.286,0,0,0-.113-.015.093.093,0,0,0-.082.07c-.016.1.014.068-.074.089-.052.012-.105.028-.1.1C193.127,193.9,193.329,193.925,193.413,193.932Z" transform="translate(-192.845 -187.063)" fill="#fcd07f" />
                  <path id="Path_1080" data-name="Path 1080" d="M194.533,187.3a.3.3,0,0,0,.009-.048.04.04,0,0,0-.022-.04.059.059,0,0,0-.025,0,.158.158,0,0,0-.086.026.167.167,0,0,0-.04.123c0,.072-.039.209.082.227.015,0,.032,0,.048,0a.088.088,0,0,1,.073.057c.007.019.007.041.014.06s.024.037.044.034.034-.031.039-.054a.231.231,0,0,0-.024-.219C194.593,187.413,194.514,187.4,194.533,187.3Z" transform="translate(-192.962 -186.468)" fill="#fcd07f" />
                  <path id="Path_1081" data-name="Path 1081" d="M195.108,186.6a.314.314,0,0,0,.056.013.047.047,0,0,0,.047-.024.072.072,0,0,0,0-.028.18.18,0,0,0-.028-.1.193.193,0,0,0-.141-.053c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.056a.106.106,0,0,1-.071.083c-.023.007-.047.006-.07.014s-.045.027-.043.051.035.04.062.047a.268.268,0,0,0,.257-.019C194.974,186.664,194.994,186.571,195.108,186.6Z" transform="translate(-192.983 -186.39)" fill="#fcd07f" />
                  <path id="Path_1082" data-name="Path 1082" d="M193.175,191.013a.311.311,0,0,0,.009-.051.044.044,0,0,0-.024-.042.064.064,0,0,0-.026,0,.159.159,0,0,0-.091.03.171.171,0,0,0-.041.13c0,.077-.039.222.089.24.017,0,.034,0,.052,0a.1.1,0,0,1,.079.06c.007.02.007.043.014.063a.051.051,0,0,0,.048.036c.024,0,.034-.034.04-.059a.241.241,0,0,0-.029-.232C193.24,191.132,193.155,191.118,193.175,191.013Z" transform="translate(-192.833 -186.817)" fill="#fcd07f" />
                  <path id="Path_1083" data-name="Path 1083" d="M195.527,194.3a.314.314,0,0,0,.023-.046.043.043,0,0,0-.012-.046.076.076,0,0,0-.025-.008.166.166,0,0,0-.094,0c-.043.019-.059.07-.073.114-.024.072-.1.2.022.252.015.007.033.008.048.014a.093.093,0,0,1,.06.078c0,.021,0,.043,0,.063s.014.045.035.047.043-.023.054-.044a.24.24,0,0,0,.033-.229C195.557,194.43,195.481,194.4,195.527,194.3Z" transform="translate(-193.05 -187.126)" fill="#fcd07f" />
                  <path id="Path_1084" data-name="Path 1084" d="M193.7,188.054a.272.272,0,0,0,.016.048.041.041,0,0,0,.04.025.058.058,0,0,0,.024-.012.162.162,0,0,0,.065-.068.175.175,0,0,0-.026-.132c-.033-.068-.071-.21-.19-.165-.015.005-.029.016-.044.024a.093.093,0,0,1-.1-.015c-.015-.014-.026-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.046-.007.07.024.079.051.159.134.187S193.663,187.954,193.7,188.054Z" transform="translate(-192.868 -186.513)" fill="#fcd07f" />
                  <path id="Path_1085" data-name="Path 1085" d="M193.023,187.352a.166.166,0,0,1-.065.067.077.077,0,0,1-.024.012c-.015,0-.031-.011-.039-.024a.2.2,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.181.17A.175.175,0,0,1,193.023,187.352Z" transform="translate(-192.816 -186.452)" fill="#fcd07f" />
                  <path id="Path_1086" data-name="Path 1086" d="M193.874,191.586a.332.332,0,0,0-.05.019.045.045,0,0,0-.024.044.057.057,0,0,0,.014.024.169.169,0,0,0,.075.065.182.182,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.007-.016-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.016-.02-.049-.013-.073,0-.082.029-.167.062-.19.15S193.977,191.547,193.874,191.586Z" transform="translate(-192.909 -186.846)" fill="#fcd07f" />
                  <path id="Path_1087" data-name="Path 1087" d="M193.047,192.7a.171.171,0,0,1-.077.065.052.052,0,0,1-.024.008.041.041,0,0,1-.04-.031.239.239,0,0,1-.012-.052.108.108,0,0,0-.079-.089v-.224a.35.35,0,0,1,.037-.015c.129-.033.153.12.18.2A.177.177,0,0,1,193.047,192.7Z" transform="translate(-192.816 -186.953)" fill="#fcd07f" />
                  <path id="Path_1088" data-name="Path 1088" d="M195.53,190.774l.025.017c.086.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.056-.247-.087-.252.024a.086.086,0,0,0,.012.061.233.233,0,0,0,.029.024c.03.026.03.072.039.11A.2.2,0,0,0,195.53,190.774Z" transform="translate(-193.057 -186.768)" fill="#fcd07f" />
                  <path id="Path_1089" data-name="Path 1089" d="M195.048,192.987a.209.209,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.023-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.044.223.223,0,0,0-.007.042c-.01.043-.054.067-.088.1A.23.23,0,0,0,195.048,192.987Z" transform="translate(-193.026 -186.981)" fill="#fcd07f" />
                  <path id="Path_1090" data-name="Path 1090" d="M195.412,187.236a.159.159,0,0,0-.027.022c-.082.074.031.168.1.2a.271.271,0,0,0,.247,0c.083-.046.182-.235.065-.279a.1.1,0,0,0-.07-.008.214.214,0,0,0-.035.023c-.039.022-.087.005-.13,0A.233.233,0,0,0,195.412,187.236Z" transform="translate(-193.055 -186.464)" fill="#fcd07f" />
                  <path id="Path_1091" data-name="Path 1091" d="M195.123,188.331a.122.122,0,0,0,0,.032c0,.1.134.08.2.054a.249.249,0,0,0,.153-.169c.018-.085-.052-.268-.154-.214a.092.092,0,0,0-.048.043c0,.012,0,.025-.005.038-.008.04-.048.063-.078.091A.207.207,0,0,0,195.123,188.331Z" transform="translate(-193.033 -186.544)" fill="#fcd07f" />
                  <path id="Path_1092" data-name="Path 1092" d="M194.749,191.523a.143.143,0,0,0-.016.024c-.046.082.071.128.132.137a.235.235,0,0,0,.2-.064c.054-.06.084-.239-.023-.244a.081.081,0,0,0-.059.012.236.236,0,0,0-.023.028c-.025.028-.069.028-.105.036A.2.2,0,0,0,194.749,191.523Z" transform="translate(-192.996 -186.86)" fill="#fcd07f" />
                  <path id="Path_1093" data-name="Path 1093" d="M195.444,189.422a.159.159,0,0,0,.011.029c.044.089.153.015.2-.034a.242.242,0,0,0,.064-.213c-.018-.083-.157-.216-.225-.127a.092.092,0,0,0-.024.058.19.19,0,0,0,.011.037c.009.038-.017.076-.032.113A.205.205,0,0,0,195.444,189.422Z" transform="translate(-193.063 -186.641)" fill="#fcd07f" />
                  <path id="Path_1094" data-name="Path 1094" d="M193.683,195.417c.065.055.153.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.018-.1-.015-.122a.158.158,0,0,0,.026.306.121.121,0,0,1,.057.014C193.647,195.386,193.664,195.4,193.683,195.417Z" transform="translate(-192.875 -187.207)" fill="#fcd07f" />
                  <path id="Path_1095" data-name="Path 1095" d="M196.055,189.76v.168c-.024-.015-.058,0-.089,0a.226.226,0,0,1-.211-.139c-.028-.142.072-.1.148-.059.022.013.043.023.056.03a.143.143,0,0,0,.059,0A.131.131,0,0,1,196.055,189.76Z" transform="translate(-193.093 -186.701)" fill="#fcd07f" />
                  <path id="Path_1096" data-name="Path 1096" d="M193.654,194.624c-.038.081-.079.187.069.15a.239.239,0,0,0,.133-.227c0-.037-.022-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.129.129,0,0,1,0,.062C193.675,194.579,193.665,194.6,193.654,194.624Z" transform="translate(-192.892 -187.137)" fill="#fcd07f" />
                  <path id="Path_1097" data-name="Path 1097" d="M194.88,195.245c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.007.067-.016.085s-.083,0-.1.026a.139.139,0,0,0,.15.075.14.14,0,0,0,.107-.13.1.1,0,0,1,.005-.05Z" transform="translate(-192.983 -187.212)" fill="#fcd07f" />
                  <path id="Path_1098" data-name="Path 1098" d="M193.432,186.817c0,.091,0,.206.122.115a.242.242,0,0,0,.039-.264c-.018-.033-.051-.067-.037-.1s.091-.046.092-.092a.167.167,0,0,0-.239.22.137.137,0,0,1,.025.056C193.434,186.767,193.433,186.791,193.432,186.817Z" transform="translate(-192.87 -186.396)" fill="#fcd07f" />
                  <path id="Path_1099" data-name="Path 1099" d="M194.365,193.682c-.047.07-.1.163.041.151.08-.006.157-.119.16-.192,0-.034-.007-.078.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.12.12,0,0,1-.008.057C194.392,193.643,194.378,193.661,194.365,193.682Z" transform="translate(-192.957 -187.053)" fill="#fcd07f" />
                  <path id="Path_1100" data-name="Path 1100" d="M194.336,189.036c-.071-.005-.16-.006-.092.092a.189.189,0,0,0,.2.035c.025-.014.053-.038.08-.026s.034.072.069.073a.129.129,0,0,0-.165-.19.1.1,0,0,1-.044.018Z" transform="translate(-192.948 -186.637)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1101" data-name="Path 1101" d="M195.348,186.532a2.314,2.314,0,0,1-2.1,0,.639.639,0,0,1-.007-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,195.348,186.532Z" transform="translate(-192.816 -185.069)" fill="#f1f4f4" />
            </g>
            <g id="Group_233" data-name="Group 233" transform="translate(17.846 9.842)">
              <path id="Path_1102" data-name="Path 1102" d="M187.169,197.034l-.349.246c-.028-.019-.057-.038-.089-.056a2.316,2.316,0,0,0-2.1,0q-.05.029-.1.06l-.336-.246v14.445h0a.743.743,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.725.725,0,0,0,.43-.607h0Z" transform="translate(-184.204 -188.072)" fill="#dce4e4" />
              <g id="Group_232" data-name="Group 232" transform="translate(0 0.881)">
                <path id="Path_1103" data-name="Path 1103" d="M187.168,188.182V196.2a.726.726,0,0,1-.431.608,2.312,2.312,0,0,1-2.094,0,.747.747,0,0,1-.438-.6v-8.016l.337.247c.029-.021.06-.042.094-.062a2.313,2.313,0,0,1,2.1.005.834.834,0,0,1,.09.057Z" transform="translate(-184.204 -188.119)" fill="#f1a443" />
                <g id="Group_231" data-name="Group 231" transform="translate(0 0.316)">
                  <path id="Path_1104" data-name="Path 1104" d="M185.23,194.736c-.053-.033-.111-.073-.117-.135a.441.441,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.056-.043-.129-.074-.153-.14a.346.346,0,0,0-.105.1.118.118,0,0,0,0,.138c.086.1.08.04.03.144-.029.061-.055.128.014.179C184.976,195,185.156,194.813,185.23,194.736Z" transform="translate(-184.259 -189.021)" fill="#fcd07f" />
                  <path id="Path_1105" data-name="Path 1105" d="M187.446,195.668v.253a.233.233,0,0,0-.108.1c-.074-.08-.249-.27-.149-.39.054-.065.12-.034.178,0S187.433,195.677,187.446,195.668Z" transform="translate(-184.482 -189.139)" fill="#fcd07f" />
                  <path id="Path_1106" data-name="Path 1106" d="M184.524,196.521c-.062.065-.2.21-.32.195v-.575c.027.062.094.092.149.132a.114.114,0,0,1,.05.065.425.425,0,0,1,0,.048C184.412,196.448,184.47,196.489,184.524,196.521Z" transform="translate(-184.204 -189.19)" fill="#fcd07f" />
                  <path id="Path_1107" data-name="Path 1107" d="M184.521,190.88a.212.212,0,0,1,.074.171.447.447,0,0,0-.014.05.122.122,0,0,0,.029.08c.043.06.1.115.108.188a.356.356,0,0,0,.137-.066.125.125,0,0,0,.04-.138c-.056-.122-.067-.065.015-.153.049-.052.1-.11.043-.183C184.86,190.7,184.62,190.824,184.521,190.88Z" transform="translate(-184.234 -188.684)" fill="#fcd07f" />
                  <path id="Path_1108" data-name="Path 1108" d="M186.25,192.3c-.038-.031-.08-.069-.077-.118a.274.274,0,0,0,0-.039.085.085,0,0,0-.032-.055c-.039-.04-.092-.072-.1-.128a.3.3,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.058.04.008.116-.03.044-.058.094-.009.141C186.022,192.476,186.183,192.351,186.25,192.3Z" transform="translate(-184.365 -188.796)" fill="#fcd07f" />
                  <path id="Path_1109" data-name="Path 1109" d="M187.45,190.158v.273a.282.282,0,0,0-.036.081c-.073-.041-.252-.144-.2-.257.025-.062.082-.054.133-.044.09.019.049.034.105-.05Z" transform="translate(-184.487 -188.627)" fill="#fcd07f" />
                  <path id="Path_1110" data-name="Path 1110" d="M184.417,192.375a.547.547,0,0,1-.213.119v-.456l0,0c.012.054.065.087.105.127a.1.1,0,0,1,.033.055.289.289,0,0,1-.005.039C184.338,192.3,184.38,192.344,184.417,192.375Z" transform="translate(-184.204 -188.803)" fill="#fcd07f" />
                  <path id="Path_1111" data-name="Path 1111" d="M185.112,192.152a.164.164,0,0,1-.13.063.335.335,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.045.035-.086.085-.143.091a.284.284,0,0,0,.056.1.1.1,0,0,0,.109.025c.093-.047.049-.054.12.007.042.036.089.071.144.028C185.265,192.409,185.158,192.227,185.112,192.152Z" transform="translate(-184.254 -188.814)" fill="#fcd07f" />
                  <path id="Path_1112" data-name="Path 1112" d="M187,193.853a.174.174,0,0,1,.005.154c-.008.012-.019.021-.026.034a.1.1,0,0,0,0,.071.323.323,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.054.089-.127C187.313,193.816,187.09,193.841,187,193.853Z" transform="translate(-184.465 -188.974)" fill="#fcd07f" />
                  <path id="Path_1113" data-name="Path 1113" d="M184.8,196.009a.157.157,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.094.094,0,0,0,.015-.062.3.3,0,0,1,.011-.162.251.251,0,0,0-.112-.015.092.092,0,0,0-.082.069c-.015.1.015.069-.073.089-.052.013-.105.029-.1.1C184.515,195.981,184.718,196,184.8,196.009Z" transform="translate(-184.233 -189.14)" fill="#fcd07f" />
                  <path id="Path_1114" data-name="Path 1114" d="M185.921,189.377a.272.272,0,0,0,.01-.047.041.041,0,0,0-.023-.04.055.055,0,0,0-.024,0,.151.151,0,0,0-.086.026.16.16,0,0,0-.04.122c0,.073-.04.21.082.228.015,0,.032,0,.047,0a.088.088,0,0,1,.074.057c.007.019.007.04.014.06a.046.046,0,0,0,.044.034c.024,0,.034-.032.038-.054a.228.228,0,0,0-.024-.219C185.982,189.49,185.9,189.475,185.921,189.377Z" transform="translate(-184.35 -188.545)" fill="#fcd07f" />
                  <path id="Path_1115" data-name="Path 1115" d="M186.5,188.675a.374.374,0,0,0,.055.013.046.046,0,0,0,.047-.024.053.053,0,0,0,0-.029.183.183,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.084-.007-.243-.054-.269.086,0,.018,0,.037,0,.056a.1.1,0,0,1-.07.083c-.023.007-.048.006-.071.014s-.045.026-.043.05.035.041.063.048a.266.266,0,0,0,.257-.02C186.363,188.741,186.383,188.648,186.5,188.675Z" transform="translate(-184.371 -188.467)" fill="#fcd07f" />
                  <path id="Path_1116" data-name="Path 1116" d="M184.563,193.09a.249.249,0,0,0,.009-.051.041.041,0,0,0-.024-.042.064.064,0,0,0-.026,0,.166.166,0,0,0-.091.029.173.173,0,0,0-.04.13c0,.078-.039.223.089.241.017,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.008.02.008.043.015.063s.026.04.047.036.035-.034.041-.058a.244.244,0,0,0-.029-.232C184.629,193.209,184.544,193.2,184.563,193.09Z" transform="translate(-184.221 -188.894)" fill="#fcd07f" />
                  <path id="Path_1117" data-name="Path 1117" d="M186.915,196.376a.261.261,0,0,0,.022-.045.04.04,0,0,0-.012-.046.052.052,0,0,0-.024-.008.169.169,0,0,0-.1,0,.172.172,0,0,0-.072.114c-.024.073-.1.2.022.253a.478.478,0,0,0,.048.014.1.1,0,0,1,.06.078c0,.022,0,.043,0,.064a.048.048,0,0,0,.036.047c.024,0,.043-.023.053-.045a.238.238,0,0,0,.034-.228C186.946,196.507,186.869,196.471,186.915,196.376Z" transform="translate(-184.438 -189.203)" fill="#fcd07f" />
                  <path id="Path_1118" data-name="Path 1118" d="M185.085,190.131a.373.373,0,0,0,.015.048.043.043,0,0,0,.041.025.054.054,0,0,0,.023-.012.157.157,0,0,0,.065-.068.168.168,0,0,0-.026-.132c-.034-.068-.071-.21-.189-.166-.015.006-.029.017-.044.024a.091.091,0,0,1-.1-.014c-.016-.014-.027-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.045-.007.07.024.079.051.159.133.186S185.052,190.031,185.085,190.131Z" transform="translate(-184.256 -188.59)" fill="#fcd07f" />
                  <path id="Path_1119" data-name="Path 1119" d="M184.412,189.428a.163.163,0,0,1-.066.067.054.054,0,0,1-.024.013c-.015,0-.031-.011-.04-.024a.326.326,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.236c.111-.034.149.1.182.169A.176.176,0,0,1,184.412,189.428Z" transform="translate(-184.204 -188.529)" fill="#fcd07f" />
                  <path id="Path_1120" data-name="Path 1120" d="M185.263,193.663a.308.308,0,0,0-.051.019.045.045,0,0,0-.024.043.06.06,0,0,0,.013.024.175.175,0,0,0,.075.066.181.181,0,0,0,.138-.034c.071-.039.219-.085.166-.208-.007-.016-.019-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.047-.047a.05.05,0,0,0,.007-.062c-.017-.02-.049-.014-.073,0-.082.029-.167.062-.19.15S185.366,193.623,185.263,193.663Z" transform="translate(-184.297 -188.923)" fill="#fcd07f" />
                  <path id="Path_1121" data-name="Path 1121" d="M184.436,194.78a.167.167,0,0,1-.077.066.071.071,0,0,1-.024.008.039.039,0,0,1-.039-.031.24.24,0,0,1-.013-.052.108.108,0,0,0-.079-.09v-.223a.239.239,0,0,1,.038-.015c.129-.033.153.12.18.2A.175.175,0,0,1,184.436,194.78Z" transform="translate(-184.204 -189.03)" fill="#fcd07f" />
                  <path id="Path_1122" data-name="Path 1122" d="M186.919,192.851l.025.017c.085.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.057-.247-.087-.253.024a.087.087,0,0,0,.013.061.235.235,0,0,0,.029.024c.029.026.029.071.038.11A.2.2,0,0,0,186.919,192.851Z" transform="translate(-184.445 -188.845)" fill="#fcd07f" />
                  <path id="Path_1123" data-name="Path 1123" d="M186.437,195.064a.208.208,0,0,0,0,.033c0,.111.143.091.212.066a.271.271,0,0,0,.17-.179c.023-.091-.048-.292-.16-.236a.1.1,0,0,0-.053.044.337.337,0,0,0-.008.042c-.01.043-.054.067-.087.1A.23.23,0,0,0,186.437,195.064Z" transform="translate(-184.414 -189.058)" fill="#fcd07f" />
                  <path id="Path_1124" data-name="Path 1124" d="M186.8,189.313l-.026.021c-.082.075.031.168.1.2a.271.271,0,0,0,.247,0c.083-.045.181-.234.064-.278a.1.1,0,0,0-.069-.009.342.342,0,0,0-.035.023c-.039.023-.087.006-.131,0A.23.23,0,0,0,186.8,189.313Z" transform="translate(-184.444 -188.541)" fill="#fcd07f" />
                  <path id="Path_1125" data-name="Path 1125" d="M186.511,190.407c0,.011,0,.022,0,.032,0,.1.135.081.2.055a.248.248,0,0,0,.152-.169c.019-.085-.051-.268-.153-.215a.094.094,0,0,0-.048.043.175.175,0,0,0-.005.038c-.008.04-.049.063-.078.091A.213.213,0,0,0,186.511,190.407Z" transform="translate(-184.421 -188.621)" fill="#fcd07f" />
                  <path id="Path_1126" data-name="Path 1126" d="M186.137,193.6a.162.162,0,0,0-.015.024c-.046.082.07.128.132.137a.231.231,0,0,0,.2-.065c.055-.059.084-.239-.023-.244a.08.08,0,0,0-.058.013.224.224,0,0,0-.023.027c-.025.029-.069.028-.106.037A.194.194,0,0,0,186.137,193.6Z" transform="translate(-184.384 -188.937)" fill="#fcd07f" />
                  <path id="Path_1127" data-name="Path 1127" d="M186.832,191.5c0,.01.007.019.012.029.044.089.153.015.2-.034a.246.246,0,0,0,.065-.214c-.019-.082-.157-.216-.225-.126a.087.087,0,0,0-.025.058c0,.013.008.024.011.036.01.039-.016.076-.031.113A.208.208,0,0,0,186.832,191.5Z" transform="translate(-184.451 -188.718)" fill="#fcd07f" />
                  <path id="Path_1128" data-name="Path 1128" d="M185.071,197.494c.066.055.154.12.157-.025,0-.081-.1-.17-.176-.181-.035-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.136.136,0,0,1,.057.014Z" transform="translate(-184.263 -189.284)" fill="#fcd07f" />
                  <path id="Path_1129" data-name="Path 1129" d="M187.444,191.836V192c-.024-.015-.058-.005-.09,0a.227.227,0,0,1-.211-.139c-.027-.143.072-.1.148-.059l.057.029a.125.125,0,0,0,.058,0A.135.135,0,0,1,187.444,191.836Z" transform="translate(-184.481 -188.778)" fill="#fcd07f" />
                  <path id="Path_1130" data-name="Path 1130" d="M185.042,196.7c-.037.081-.078.187.069.15a.238.238,0,0,0,.133-.227c0-.037-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C185.064,196.656,185.053,196.677,185.042,196.7Z" transform="translate(-184.28 -189.214)" fill="#fcd07f" />
                  <path id="Path_1131" data-name="Path 1131" d="M186.268,197.322c.041-.062.086-.143-.038-.13a.2.2,0,0,0-.135.168c0,.031.008.068-.016.086s-.082,0-.1.026a.135.135,0,0,0,.256-.055.13.13,0,0,1,.005-.05C186.246,197.356,186.257,197.34,186.268,197.322Z" transform="translate(-184.371 -189.289)" fill="#fcd07f" />
                  <path id="Path_1132" data-name="Path 1132" d="M184.82,188.894c0,.091,0,.207.122.115a.24.24,0,0,0,.038-.264c-.018-.033-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.239.22.137.137,0,0,1,.025.056C184.823,188.844,184.821,188.868,184.82,188.894Z" transform="translate(-184.258 -188.473)" fill="#fcd07f" />
                  <path id="Path_1133" data-name="Path 1133" d="M185.754,195.758c-.048.071-.1.164.041.152a.226.226,0,0,0,.16-.192c0-.035-.007-.078.021-.1s.1.008.119-.028a.162.162,0,0,0-.172-.09.16.16,0,0,0-.126.148.122.122,0,0,1-.008.057C185.781,195.72,185.767,195.738,185.754,195.758Z" transform="translate(-184.345 -189.13)" fill="#fcd07f" />
                  <path id="Path_1134" data-name="Path 1134" d="M185.724,191.112c-.07,0-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.013.053-.037.079-.026s.034.072.07.074a.129.129,0,0,0-.166-.19.1.1,0,0,1-.044.018Z" transform="translate(-184.336 -188.714)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1135" data-name="Path 1135" d="M186.737,188.608a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.319,2.319,0,0,1,2.1,0A.638.638,0,0,1,186.737,188.608Z" transform="translate(-184.204 -187.146)" fill="#f1f4f4" />
            </g>
            <g id="Group_236" data-name="Group 236" transform="translate(20.54 11.186)">
              <path id="Path_1136" data-name="Path 1136" d="M190.143,198.518l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0,1.053,1.053,0,0,0-.094.061l-.336-.247v14.445h0a.744.744,0,0,0,.437.6,2.317,2.317,0,0,0,2.1,0,.729.729,0,0,0,.431-.608h0Z" transform="translate(-187.179 -189.556)" fill="#dce4e4" />
              <g id="Group_235" data-name="Group 235" transform="translate(0 0.881)">
                <path id="Path_1137" data-name="Path 1137" d="M190.142,189.666v8.016a.726.726,0,0,1-.43.608,2.317,2.317,0,0,1-2.1,0,.749.749,0,0,1-.437-.605v-8.016l.336.247c.029-.021.06-.042.094-.062a2.318,2.318,0,0,1,2.1.005,1.061,1.061,0,0,1,.09.057Z" transform="translate(-187.179 -189.603)" fill="#f1a443" />
                <g id="Group_234" data-name="Group 234" transform="translate(0 0.315)">
                  <path id="Path_1138" data-name="Path 1138" d="M188.205,196.22c-.053-.032-.112-.072-.118-.135a.445.445,0,0,0,0-.049.115.115,0,0,0-.049-.064c-.057-.042-.13-.074-.154-.14a.338.338,0,0,0-.105.1.12.12,0,0,0,0,.137c.086.1.081.04.03.145-.029.061-.055.128.014.178C187.95,196.484,188.131,196.3,188.205,196.22Z" transform="translate(-187.234 -190.506)" fill="#fcd07f" />
                  <path id="Path_1139" data-name="Path 1139" d="M190.42,197.152v.253a.233.233,0,0,0-.108.1c-.073-.079-.249-.27-.149-.389.055-.065.12-.034.178,0S190.407,197.161,190.42,197.152Z" transform="translate(-187.457 -190.623)" fill="#fcd07f" />
                  <path id="Path_1140" data-name="Path 1140" d="M187.5,198.006c-.062.065-.2.209-.319.194v-.575c.027.062.094.092.149.132a.122.122,0,0,1,.05.065c0,.015,0,.033,0,.048C187.386,197.932,187.445,197.974,187.5,198.006Z" transform="translate(-187.179 -190.675)" fill="#fcd07f" />
                  <path id="Path_1141" data-name="Path 1141" d="M187.495,192.364a.208.208,0,0,1,.074.171.451.451,0,0,0-.014.05.125.125,0,0,0,.029.08c.043.06.105.115.108.189a.373.373,0,0,0,.138-.066.126.126,0,0,0,.039-.139c-.055-.121-.067-.065.016-.153.048-.052.1-.11.043-.183C187.834,192.182,187.595,192.309,187.495,192.364Z" transform="translate(-187.209 -190.169)" fill="#fcd07f" />
                  <path id="Path_1142" data-name="Path 1142" d="M189.225,193.782c-.038-.031-.08-.069-.077-.118,0-.013,0-.025,0-.038a.093.093,0,0,0-.032-.056c-.04-.039-.092-.072-.1-.127a.277.277,0,0,0-.093.066.094.094,0,0,0-.012.108c.056.084.058.041.007.117-.029.043-.057.093-.009.14C189,193.961,189.158,193.835,189.225,193.782Z" transform="translate(-187.34 -190.28)" fill="#fcd07f" />
                  <path id="Path_1143" data-name="Path 1143" d="M190.424,191.642v.274a.24.24,0,0,0-.035.081c-.074-.042-.253-.144-.2-.258.025-.062.082-.054.134-.044.089.019.048.034.105-.05Z" transform="translate(-187.461 -190.111)" fill="#fcd07f" />
                  <path id="Path_1144" data-name="Path 1144" d="M187.392,193.859a.553.553,0,0,1-.213.119v-.456l0,0c.013.055.066.088.105.127a.1.1,0,0,1,.033.055.322.322,0,0,1-.005.04A.166.166,0,0,0,187.392,193.859Z" transform="translate(-187.179 -190.288)" fill="#fcd07f" />
                  <path id="Path_1145" data-name="Path 1145" d="M188.087,193.636c-.037.034-.082.072-.131.063a.272.272,0,0,0-.039-.009.1.1,0,0,0-.061.025c-.045.035-.086.085-.144.091a.29.29,0,0,0,.056.1.1.1,0,0,0,.109.025c.093-.047.049-.053.12.008.041.035.088.071.143.027C188.24,193.893,188.132,193.711,188.087,193.636Z" transform="translate(-187.229 -190.299)" fill="#fcd07f" />
                  <path id="Path_1146" data-name="Path 1146" d="M189.972,195.337a.174.174,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.07.317.317,0,0,1,.025.178.309.309,0,0,0,.126-.009.1.1,0,0,0,.072-.094c-.005-.11-.031-.071.06-.113.053-.024.107-.054.089-.127C190.287,195.3,190.064,195.325,189.972,195.337Z" transform="translate(-187.44 -190.458)" fill="#fcd07f" />
                  <path id="Path_1147" data-name="Path 1147" d="M187.777,197.493a.158.158,0,0,1,.024-.138.263.263,0,0,0,.029-.025.086.086,0,0,0,.015-.062.29.29,0,0,1,.012-.163.286.286,0,0,0-.113-.015.094.094,0,0,0-.082.069c-.016.1.014.069-.074.09-.051.012-.105.028-.1.1C187.49,197.465,187.692,197.486,187.777,197.493Z" transform="translate(-187.208 -190.624)" fill="#fcd07f" />
                  <path id="Path_1148" data-name="Path 1148" d="M188.9,190.862a.3.3,0,0,0,.009-.048.039.039,0,0,0-.022-.04.056.056,0,0,0-.024,0,.152.152,0,0,0-.087.026.165.165,0,0,0-.04.123c0,.072-.039.209.082.227.015,0,.032,0,.048,0a.088.088,0,0,1,.073.057c.007.019.007.041.014.06s.024.037.044.034.034-.031.039-.054a.229.229,0,0,0-.024-.219C188.956,190.974,188.877,190.96,188.9,190.862Z" transform="translate(-187.325 -190.029)" fill="#fcd07f" />
                  <path id="Path_1149" data-name="Path 1149" d="M189.472,190.159a.289.289,0,0,0,.055.013.047.047,0,0,0,.047-.024.073.073,0,0,0,0-.028.18.18,0,0,0-.028-.1.187.187,0,0,0-.141-.052c-.085-.008-.244-.055-.27.085,0,.018,0,.037,0,.056a.1.1,0,0,1-.071.083c-.023.007-.047.006-.07.014s-.045.027-.043.051.035.04.062.047a.266.266,0,0,0,.257-.02C189.337,190.225,189.357,190.132,189.472,190.159Z" transform="translate(-187.346 -189.951)" fill="#fcd07f" />
                  <path id="Path_1150" data-name="Path 1150" d="M187.538,194.574a.313.313,0,0,0,.009-.051.044.044,0,0,0-.024-.042.06.06,0,0,0-.025,0,.16.16,0,0,0-.091.03.171.171,0,0,0-.041.13c0,.077-.039.222.09.24.016,0,.034,0,.051,0a.1.1,0,0,1,.079.06c.007.02.007.043.015.063a.049.049,0,0,0,.047.036c.024,0,.035-.034.04-.059a.241.241,0,0,0-.029-.232C187.6,194.693,187.519,194.679,187.538,194.574Z" transform="translate(-187.196 -190.378)" fill="#fcd07f" />
                  <path id="Path_1151" data-name="Path 1151" d="M189.89,197.861a.311.311,0,0,0,.023-.046.043.043,0,0,0-.012-.046.078.078,0,0,0-.024-.008.169.169,0,0,0-.1,0c-.043.019-.059.069-.073.114-.024.072-.1.2.022.252.015.007.033.008.048.014a.093.093,0,0,1,.06.078c0,.021,0,.043,0,.063s.014.045.035.047.043-.023.054-.044a.243.243,0,0,0,.034-.229C189.92,197.992,189.844,197.956,189.89,197.861Z" transform="translate(-187.413 -190.687)" fill="#fcd07f" />
                  <path id="Path_1152" data-name="Path 1152" d="M188.06,191.615a.214.214,0,0,0,.015.048.041.041,0,0,0,.04.025.058.058,0,0,0,.024-.012.162.162,0,0,0,.065-.068.172.172,0,0,0-.026-.132c-.034-.068-.071-.21-.19-.165-.015.005-.029.016-.043.023a.092.092,0,0,1-.1-.014c-.015-.014-.026-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.046-.007.07.024.079.051.16.134.187S188.026,191.515,188.06,191.615Z" transform="translate(-187.231 -190.074)" fill="#fcd07f" />
                  <path id="Path_1153" data-name="Path 1153" d="M187.386,190.913a.166.166,0,0,1-.065.067.083.083,0,0,1-.024.012c-.016,0-.032-.011-.04-.024a.247.247,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.174.174,0,0,1,187.386,190.913Z" transform="translate(-187.179 -190.013)" fill="#fcd07f" />
                  <path id="Path_1154" data-name="Path 1154" d="M188.237,195.147a.33.33,0,0,0-.05.019.044.044,0,0,0-.024.043.055.055,0,0,0,.014.024.17.17,0,0,0,.075.065.181.181,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.007-.016-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.016-.02-.049-.013-.073,0-.082.029-.166.062-.19.15S188.34,195.108,188.237,195.147Z" transform="translate(-187.272 -190.407)" fill="#fcd07f" />
                  <path id="Path_1155" data-name="Path 1155" d="M187.411,196.264a.173.173,0,0,1-.078.065.052.052,0,0,1-.024.008.041.041,0,0,1-.04-.031.237.237,0,0,1-.012-.052.108.108,0,0,0-.079-.089v-.224a.353.353,0,0,1,.037-.015c.13-.033.153.12.18.2A.181.181,0,0,1,187.411,196.264Z" transform="translate(-187.179 -190.514)" fill="#fcd07f" />
                  <path id="Path_1156" data-name="Path 1156" d="M189.893,194.335l.025.017c.086.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.056-.247-.087-.252.024a.09.09,0,0,0,.012.06.237.237,0,0,0,.029.024c.03.026.03.072.039.11A.2.2,0,0,0,189.893,194.335Z" transform="translate(-187.42 -190.329)" fill="#fcd07f" />
                  <path id="Path_1157" data-name="Path 1157" d="M189.411,196.548a.21.21,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.024-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.044.333.333,0,0,0-.007.042c-.01.043-.054.067-.087.1A.226.226,0,0,0,189.411,196.548Z" transform="translate(-187.389 -190.542)" fill="#fcd07f" />
                  <path id="Path_1158" data-name="Path 1158" d="M189.775,190.8a.159.159,0,0,0-.027.022c-.082.075.032.168.1.2a.273.273,0,0,0,.248,0c.082-.046.181-.235.064-.279a.1.1,0,0,0-.07-.008.212.212,0,0,0-.035.023c-.038.022-.086.005-.13,0A.233.233,0,0,0,189.775,190.8Z" transform="translate(-187.418 -190.025)" fill="#fcd07f" />
                  <path id="Path_1159" data-name="Path 1159" d="M189.486,191.892a.118.118,0,0,0,0,.031c0,.1.134.081.2.055a.252.252,0,0,0,.152-.169c.019-.085-.052-.268-.154-.214a.087.087,0,0,0-.047.043.256.256,0,0,0-.006.038c-.008.04-.048.063-.078.091A.207.207,0,0,0,189.486,191.892Z" transform="translate(-187.396 -190.105)" fill="#fcd07f" />
                  <path id="Path_1160" data-name="Path 1160" d="M189.112,195.084a.19.19,0,0,0-.016.024c-.046.082.071.128.132.137a.235.235,0,0,0,.2-.064c.054-.06.084-.239-.023-.244a.081.081,0,0,0-.059.012.234.234,0,0,0-.023.028c-.025.028-.068.028-.105.036A.2.2,0,0,0,189.112,195.084Z" transform="translate(-187.359 -190.421)" fill="#fcd07f" />
                  <path id="Path_1161" data-name="Path 1161" d="M189.807,192.983a.16.16,0,0,0,.011.029c.044.089.153.015.2-.033a.244.244,0,0,0,.064-.214c-.018-.083-.156-.216-.225-.126a.088.088,0,0,0-.024.057.191.191,0,0,0,.011.037c.009.038-.017.076-.032.113A.206.206,0,0,0,189.807,192.983Z" transform="translate(-187.426 -190.202)" fill="#fcd07f" />
                  <path id="Path_1162" data-name="Path 1162" d="M188.046,198.978c.065.055.153.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.018-.1-.015-.122a.158.158,0,0,0,.026.306.121.121,0,0,1,.057.014C188.01,198.947,188.027,198.961,188.046,198.978Z" transform="translate(-187.238 -190.768)" fill="#fcd07f" />
                  <path id="Path_1163" data-name="Path 1163" d="M190.418,193.321v.168c-.024-.015-.058,0-.089,0a.226.226,0,0,1-.211-.139c-.028-.143.072-.1.148-.059.022.013.043.023.056.03a.143.143,0,0,0,.059,0A.131.131,0,0,1,190.418,193.321Z" transform="translate(-187.456 -190.262)" fill="#fcd07f" />
                  <path id="Path_1164" data-name="Path 1164" d="M188.017,198.185c-.037.081-.079.187.069.15a.237.237,0,0,0,.133-.227c0-.036-.022-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.129.129,0,0,1,0,.062C188.038,198.14,188.028,198.162,188.017,198.185Z" transform="translate(-187.255 -190.699)" fill="#fcd07f" />
                  <path id="Path_1165" data-name="Path 1165" d="M189.243,198.806c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.007.067-.016.085s-.083,0-.1.026a.139.139,0,0,0,.15.075.14.14,0,0,0,.107-.13.1.1,0,0,1,.005-.05Z" transform="translate(-187.346 -190.774)" fill="#fcd07f" />
                  <path id="Path_1166" data-name="Path 1166" d="M187.794,190.378c0,.091,0,.206.123.115a.243.243,0,0,0,.038-.264c-.018-.033-.051-.067-.037-.1s.091-.046.093-.092a.173.173,0,0,0-.207.015.171.171,0,0,0-.033.2.126.126,0,0,1,.025.056A.647.647,0,0,1,187.794,190.378Z" transform="translate(-187.233 -189.957)" fill="#fcd07f" />
                  <path id="Path_1167" data-name="Path 1167" d="M188.728,197.243c-.047.07-.1.163.041.151.08-.006.157-.119.16-.192,0-.034-.007-.078.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.12.12,0,0,1-.008.057A.62.62,0,0,1,188.728,197.243Z" transform="translate(-187.32 -190.614)" fill="#fcd07f" />
                  <path id="Path_1168" data-name="Path 1168" d="M188.7,192.6c-.071-.005-.16-.006-.092.092a.189.189,0,0,0,.2.035c.026-.014.053-.038.08-.026s.034.072.069.073a.129.129,0,0,0-.165-.19.1.1,0,0,1-.044.018Z" transform="translate(-187.311 -190.198)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1169" data-name="Path 1169" d="M189.711,190.093a2.314,2.314,0,0,1-2.1,0,.639.639,0,0,1-.007-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,189.711,190.093Z" transform="translate(-187.179 -188.63)" fill="#f1f4f4" />
            </g>
            <g id="Group_239" data-name="Group 239" transform="translate(23.315)">
              <path id="Path_1170" data-name="Path 1170" d="M193.207,186.168l-.348.246c-.029-.019-.058-.038-.09-.056a2.316,2.316,0,0,0-2.1,0q-.05.029-.1.06l-.336-.246v14.445h0a.743.743,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.728.728,0,0,0,.431-.607h0Z" transform="translate(-190.242 -177.206)" fill="#f7f9f9" />
              <g id="Group_238" data-name="Group 238" transform="translate(0 0.881)">
                <path id="Path_1171" data-name="Path 1171" d="M193.206,177.315v8.017a.726.726,0,0,1-.431.608,2.312,2.312,0,0,1-2.094,0,.749.749,0,0,1-.438-.6v-8.017l.337.247c.029-.02.06-.041.094-.062a2.312,2.312,0,0,1,2.1.005c.031.017.06.036.089.056Z" transform="translate(-190.242 -177.252)" fill="#f1a443" />
                <g id="Group_237" data-name="Group 237" transform="translate(0 0.316)">
                  <path id="Path_1172" data-name="Path 1172" d="M191.268,183.869c-.053-.032-.111-.072-.117-.134a.442.442,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.056-.043-.129-.074-.153-.14a.345.345,0,0,0-.105.1.117.117,0,0,0,0,.137c.086.1.08.041.03.145-.029.061-.055.128.014.179C191.014,184.134,191.194,183.947,191.268,183.869Z" transform="translate(-190.297 -178.155)" fill="#fcd07f" />
                  <path id="Path_1173" data-name="Path 1173" d="M193.484,184.8v.253a.233.233,0,0,0-.108.1c-.074-.08-.249-.27-.149-.39.054-.065.12-.034.178,0S193.471,184.811,193.484,184.8Z" transform="translate(-190.521 -178.273)" fill="#fcd07f" />
                  <path id="Path_1174" data-name="Path 1174" d="M190.562,185.655c-.062.065-.2.21-.32.195v-.575c.028.062.1.092.149.132a.113.113,0,0,1,.05.064.44.44,0,0,1,0,.049C190.45,185.582,190.508,185.623,190.562,185.655Z" transform="translate(-190.242 -178.324)" fill="#fcd07f" />
                  <path id="Path_1175" data-name="Path 1175" d="M190.559,180.014a.209.209,0,0,1,.074.171.444.444,0,0,0-.014.05.122.122,0,0,0,.029.08c.043.06.105.115.108.188a.356.356,0,0,0,.137-.066.125.125,0,0,0,.04-.138c-.056-.122-.067-.065.015-.154.049-.051.1-.109.043-.182C190.9,179.831,190.658,179.958,190.559,180.014Z" transform="translate(-190.272 -177.818)" fill="#fcd07f" />
                  <path id="Path_1176" data-name="Path 1176" d="M192.288,181.432c-.038-.031-.08-.069-.077-.118a.28.28,0,0,0,0-.039.085.085,0,0,0-.032-.055c-.039-.04-.092-.072-.1-.128a.3.3,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.059.04.008.116-.03.044-.058.094-.009.141C192.06,181.61,192.222,181.485,192.288,181.432Z" transform="translate(-190.403 -177.93)" fill="#fcd07f" />
                  <path id="Path_1177" data-name="Path 1177" d="M193.488,179.292v.273a.281.281,0,0,0-.036.081c-.073-.041-.252-.144-.2-.257.025-.062.082-.054.134-.044.089.019.048.034.1-.05Z" transform="translate(-190.525 -177.761)" fill="#fcd07f" />
                  <path id="Path_1178" data-name="Path 1178" d="M190.455,181.509a.541.541,0,0,1-.213.119v-.456l0,0c.012.054.065.087.105.127a.1.1,0,0,1,.033.055.287.287,0,0,1-.005.039C190.376,181.438,190.418,181.478,190.455,181.509Z" transform="translate(-190.242 -177.937)" fill="#fcd07f" />
                  <path id="Path_1179" data-name="Path 1179" d="M191.15,181.286a.164.164,0,0,1-.13.063.344.344,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.045.035-.086.085-.143.09a.281.281,0,0,0,.056.105.1.1,0,0,0,.109.025c.093-.047.049-.054.12.007.042.036.089.071.144.028C191.3,181.543,191.2,181.361,191.15,181.286Z" transform="translate(-190.292 -177.949)" fill="#fcd07f" />
                  <path id="Path_1180" data-name="Path 1180" d="M193.035,182.987a.174.174,0,0,1,.005.154.294.294,0,0,0-.025.034.1.1,0,0,0,0,.071.323.323,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.054.089-.127C193.351,182.95,193.128,182.975,193.035,182.987Z" transform="translate(-190.503 -178.108)" fill="#fcd07f" />
                  <path id="Path_1181" data-name="Path 1181" d="M190.84,185.143a.157.157,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.094.094,0,0,0,.015-.062.287.287,0,0,1,.012-.162.271.271,0,0,0-.113-.015.091.091,0,0,0-.082.069c-.016.1.014.069-.074.089-.052.012-.105.029-.1.1C190.553,185.115,190.755,185.135,190.84,185.143Z" transform="translate(-190.271 -178.274)" fill="#fcd07f" />
                  <path id="Path_1182" data-name="Path 1182" d="M191.959,178.511a.208.208,0,0,0,.01-.047.041.041,0,0,0-.023-.04.073.073,0,0,0-.024,0,.156.156,0,0,0-.086.027.16.16,0,0,0-.04.122c0,.073-.04.21.082.228.015,0,.032,0,.047,0a.088.088,0,0,1,.074.057c.006.019.006.04.014.059a.046.046,0,0,0,.044.035c.024,0,.033-.032.038-.054a.228.228,0,0,0-.024-.219C192.02,178.624,191.94,178.609,191.959,178.511Z" transform="translate(-190.388 -177.679)" fill="#fcd07f" />
                  <path id="Path_1183" data-name="Path 1183" d="M192.535,177.809a.4.4,0,0,0,.055.013.049.049,0,0,0,.048-.024.062.062,0,0,0,0-.029.183.183,0,0,0-.027-.1.193.193,0,0,0-.142-.052c-.084-.007-.243-.054-.269.086,0,.018,0,.037,0,.055a.1.1,0,0,1-.07.084c-.023.007-.048.006-.071.014a.054.054,0,0,0-.043.05c0,.028.035.041.063.048a.266.266,0,0,0,.257-.02C192.4,177.875,192.421,177.782,192.535,177.809Z" transform="translate(-190.409 -177.601)" fill="#fcd07f" />
                  <path id="Path_1184" data-name="Path 1184" d="M190.6,182.224a.334.334,0,0,0,.01-.051.043.043,0,0,0-.024-.042.064.064,0,0,0-.026,0,.169.169,0,0,0-.091.029.176.176,0,0,0-.04.13c0,.078-.039.223.089.24.017,0,.034,0,.051,0a.093.093,0,0,1,.079.06c.008.02.008.043.015.062a.048.048,0,0,0,.048.036c.024,0,.034-.034.04-.058a.244.244,0,0,0-.029-.232C190.667,182.343,190.582,182.329,190.6,182.224Z" transform="translate(-190.259 -178.028)" fill="#fcd07f" />
                  <path id="Path_1185" data-name="Path 1185" d="M192.954,185.51a.26.26,0,0,0,.022-.045.04.04,0,0,0-.012-.046.056.056,0,0,0-.024-.008.17.17,0,0,0-.1,0,.172.172,0,0,0-.072.114c-.024.073-.1.2.022.253.015.006.033.008.048.014a.094.094,0,0,1,.06.078c0,.022,0,.043,0,.064a.046.046,0,0,0,.035.047c.024,0,.043-.023.053-.045a.238.238,0,0,0,.034-.228C192.984,185.641,192.907,185.605,192.954,185.51Z" transform="translate(-190.476 -178.337)" fill="#fcd07f" />
                  <path id="Path_1186" data-name="Path 1186" d="M191.123,179.265a.271.271,0,0,0,.016.048.042.042,0,0,0,.04.025.072.072,0,0,0,.024-.012.162.162,0,0,0,.065-.068.172.172,0,0,0-.027-.132c-.034-.068-.071-.21-.189-.166-.015.006-.029.017-.044.024a.093.093,0,0,1-.1-.014c-.016-.014-.027-.034-.043-.047a.048.048,0,0,0-.058-.009c-.02.015-.014.045-.007.07.024.079.051.159.134.186S191.09,179.165,191.123,179.265Z" transform="translate(-190.294 -177.724)" fill="#fcd07f" />
                  <path id="Path_1187" data-name="Path 1187" d="M190.45,178.562a.166.166,0,0,1-.065.067.063.063,0,0,1-.024.013.043.043,0,0,1-.039-.024.251.251,0,0,1-.016-.048.1.1,0,0,0-.063-.072v-.236c.112-.034.149.1.182.17A.173.173,0,0,1,190.45,178.562Z" transform="translate(-190.242 -177.663)" fill="#fcd07f" />
                  <path id="Path_1188" data-name="Path 1188" d="M191.3,182.8a.272.272,0,0,0-.05.019.044.044,0,0,0-.024.043.059.059,0,0,0,.013.024.176.176,0,0,0,.075.066.181.181,0,0,0,.138-.034c.071-.039.219-.085.166-.208-.007-.016-.019-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.047-.047a.05.05,0,0,0,.007-.062c-.017-.02-.049-.014-.073,0-.082.029-.167.062-.19.15S191.4,182.757,191.3,182.8Z" transform="translate(-190.335 -178.057)" fill="#fcd07f" />
                  <path id="Path_1189" data-name="Path 1189" d="M190.474,183.914a.16.16,0,0,1-.077.065.057.057,0,0,1-.024.009.04.04,0,0,1-.039-.031.2.2,0,0,1-.012-.052.11.11,0,0,0-.08-.09v-.223a.277.277,0,0,1,.038-.015c.129-.033.153.12.18.2A.175.175,0,0,1,190.474,183.914Z" transform="translate(-190.242 -178.164)" fill="#fcd07f" />
                  <path id="Path_1190" data-name="Path 1190" d="M192.957,181.985a.15.15,0,0,0,.025.016c.086.048.132-.072.141-.137a.24.24,0,0,0-.067-.209c-.062-.057-.247-.087-.253.024a.087.087,0,0,0,.013.061.234.234,0,0,0,.029.024c.03.026.029.071.038.11A.2.2,0,0,0,192.957,181.985Z" transform="translate(-190.483 -177.979)" fill="#fcd07f" />
                  <path id="Path_1191" data-name="Path 1191" d="M192.475,184.2a.208.208,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.023-.091-.048-.292-.159-.236a.1.1,0,0,0-.053.044.223.223,0,0,0-.007.042c-.01.043-.055.067-.088.1A.23.23,0,0,0,192.475,184.2Z" transform="translate(-190.452 -178.192)" fill="#fcd07f" />
                  <path id="Path_1192" data-name="Path 1192" d="M192.838,178.447l-.026.021c-.082.075.031.168.1.2a.271.271,0,0,0,.247,0c.083-.045.182-.234.064-.279a.1.1,0,0,0-.069-.008.334.334,0,0,0-.035.023c-.039.023-.087.006-.131,0A.23.23,0,0,0,192.838,178.447Z" transform="translate(-190.482 -177.675)" fill="#fcd07f" />
                  <path id="Path_1193" data-name="Path 1193" d="M192.549,179.541c0,.011,0,.022,0,.032,0,.1.135.081.2.055a.251.251,0,0,0,.153-.169c.018-.085-.052-.268-.154-.215a.1.1,0,0,0-.048.043.177.177,0,0,0-.005.038c-.008.04-.049.063-.078.091A.213.213,0,0,0,192.549,179.541Z" transform="translate(-190.459 -177.755)" fill="#fcd07f" />
                  <path id="Path_1194" data-name="Path 1194" d="M192.175,182.734l-.016.024c-.045.082.071.128.133.136a.228.228,0,0,0,.2-.064c.055-.06.085-.238-.023-.244a.085.085,0,0,0-.058.012.337.337,0,0,0-.023.028c-.025.029-.069.028-.106.037A.2.2,0,0,0,192.175,182.734Z" transform="translate(-190.422 -178.071)" fill="#fcd07f" />
                  <path id="Path_1195" data-name="Path 1195" d="M192.87,180.633c0,.01.007.019.012.029.044.089.153.015.2-.033a.243.243,0,0,0,.065-.214c-.019-.082-.157-.216-.225-.126a.086.086,0,0,0-.024.058.237.237,0,0,0,.01.036c.01.039-.016.076-.031.113A.208.208,0,0,0,192.87,180.633Z" transform="translate(-190.489 -177.852)" fill="#fcd07f" />
                  <path id="Path_1196" data-name="Path 1196" d="M191.109,186.628c.065.054.154.12.157-.025,0-.081-.1-.17-.176-.181-.035-.005-.079,0-.1-.031s.017-.1-.015-.122a.157.157,0,0,0,.026.305.136.136,0,0,1,.057.014C191.074,186.6,191.09,186.611,191.109,186.628Z" transform="translate(-190.301 -178.418)" fill="#fcd07f" />
                  <path id="Path_1197" data-name="Path 1197" d="M193.482,180.97v.168c-.024-.015-.058-.005-.089,0a.227.227,0,0,1-.212-.139c-.027-.143.072-.1.148-.059l.057.029a.125.125,0,0,0,.058,0A.136.136,0,0,1,193.482,180.97Z" transform="translate(-190.519 -177.912)" fill="#fcd07f" />
                  <path id="Path_1198" data-name="Path 1198" d="M191.08,185.834c-.037.082-.078.188.069.151a.238.238,0,0,0,.133-.227c0-.037-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C191.1,185.79,191.091,185.812,191.08,185.834Z" transform="translate(-190.318 -178.348)" fill="#fcd07f" />
                  <path id="Path_1199" data-name="Path 1199" d="M192.307,186.456c.04-.062.085-.143-.039-.13a.2.2,0,0,0-.135.168c0,.031.008.068-.015.086s-.083,0-.1.026a.135.135,0,0,0,.256-.055.115.115,0,0,1,.006-.05C192.284,186.49,192.295,186.474,192.307,186.456Z" transform="translate(-190.409 -178.423)" fill="#fcd07f" />
                  <path id="Path_1200" data-name="Path 1200" d="M190.859,178.028c0,.091,0,.207.122.115a.24.24,0,0,0,.038-.264c-.018-.033-.05-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.239.22.137.137,0,0,1,.025.056C190.861,177.978,190.859,178,190.859,178.028Z" transform="translate(-190.296 -177.607)" fill="#fcd07f" />
                  <path id="Path_1201" data-name="Path 1201" d="M191.792,184.892c-.048.071-.1.164.041.152.08-.006.157-.119.16-.192,0-.035-.007-.078.021-.1s.1.008.119-.028a.156.156,0,0,0-.3.058.122.122,0,0,1-.008.057C191.819,184.854,191.805,184.872,191.792,184.892Z" transform="translate(-190.383 -178.264)" fill="#fcd07f" />
                  <path id="Path_1202" data-name="Path 1202" d="M191.762,180.246c-.07,0-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.013.053-.037.079-.026s.034.072.07.074a.129.129,0,0,0-.166-.19.1.1,0,0,1-.044.018Z" transform="translate(-190.374 -177.848)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1203" data-name="Path 1203" d="M192.775,177.743a2.319,2.319,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.319,2.319,0,0,1,2.1,0A.639.639,0,0,1,192.775,177.743Z" transform="translate(-190.242 -176.28)" fill="#f1f4f4" />
            </g>
            <g id="Group_242" data-name="Group 242" transform="translate(15.516 11.349)">
              <path id="Path_1204" data-name="Path 1204" d="M184.595,198.7l-.348.246c-.028-.019-.058-.038-.09-.056a2.314,2.314,0,0,0-2.1,0c-.034.019-.065.04-.1.06l-.336-.246v14.445h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.725.725,0,0,0,.43-.607h0Z" transform="translate(-181.631 -189.736)" fill="#dce4e4" />
              <g id="Group_241" data-name="Group 241" transform="translate(0 0.881)">
                <path id="Path_1205" data-name="Path 1205" d="M184.595,189.846v8.016a.727.727,0,0,1-.431.608,2.317,2.317,0,0,1-2.1,0,.75.75,0,0,1-.437-.605v-8.016l.336.247c.03-.021.061-.042.1-.062a2.316,2.316,0,0,1,2.1.005.933.933,0,0,1,.09.057Z" transform="translate(-181.631 -189.783)" fill="#f1a443" />
                <g id="Group_240" data-name="Group 240" transform="translate(0 0.315)">
                  <path id="Path_1206" data-name="Path 1206" d="M182.657,196.4c-.053-.033-.112-.073-.118-.135a.444.444,0,0,0,0-.049.116.116,0,0,0-.05-.064c-.057-.042-.13-.074-.153-.14a.351.351,0,0,0-.106.1.12.12,0,0,0,0,.137c.086.1.081.04.031.144-.029.062-.055.128.014.179C182.4,196.664,182.583,196.478,182.657,196.4Z" transform="translate(-181.686 -190.685)" fill="#fcd07f" />
                  <path id="Path_1207" data-name="Path 1207" d="M184.873,197.332v.253a.235.235,0,0,0-.109.1c-.073-.079-.249-.27-.149-.389.055-.065.12-.034.178,0S184.859,197.341,184.873,197.332Z" transform="translate(-181.909 -190.803)" fill="#fcd07f" />
                  <path id="Path_1208" data-name="Path 1208" d="M181.95,198.186c-.062.065-.2.209-.319.194V197.8c.027.062.094.092.149.132a.118.118,0,0,1,.05.065.425.425,0,0,1,0,.048C181.838,198.112,181.9,198.154,181.95,198.186Z" transform="translate(-181.631 -190.854)" fill="#fcd07f" />
                  <path id="Path_1209" data-name="Path 1209" d="M181.947,192.544a.208.208,0,0,1,.074.171.44.44,0,0,0-.013.05.116.116,0,0,0,.029.08c.043.06.1.115.108.189a.376.376,0,0,0,.137-.066.125.125,0,0,0,.039-.139c-.055-.122-.067-.065.016-.153.048-.052.1-.11.043-.183C182.287,192.362,182.047,192.488,181.947,192.544Z" transform="translate(-181.661 -190.348)" fill="#fcd07f" />
                  <path id="Path_1210" data-name="Path 1210" d="M183.677,193.962c-.038-.031-.08-.069-.077-.118a.272.272,0,0,0,0-.039.093.093,0,0,0-.032-.055c-.04-.039-.092-.072-.1-.127a.265.265,0,0,0-.093.066.094.094,0,0,0-.012.108c.057.084.058.04.007.117-.029.043-.057.093-.009.14C183.448,194.141,183.61,194.015,183.677,193.962Z" transform="translate(-181.792 -190.46)" fill="#fcd07f" />
                  <path id="Path_1211" data-name="Path 1211" d="M184.877,191.822v.274a.242.242,0,0,0-.036.081c-.074-.042-.253-.144-.2-.258.026-.062.082-.054.134-.044.09.019.048.034.105-.05Z" transform="translate(-181.913 -190.291)" fill="#fcd07f" />
                  <path id="Path_1212" data-name="Path 1212" d="M181.844,194.039a.547.547,0,0,1-.213.119V193.7l0,0c.012.055.065.088.1.127a.1.1,0,0,1,.033.055.285.285,0,0,1,0,.039A.162.162,0,0,0,181.844,194.039Z" transform="translate(-181.631 -190.467)" fill="#fcd07f" />
                  <path id="Path_1213" data-name="Path 1213" d="M182.539,193.816c-.036.034-.082.072-.13.063a.31.31,0,0,0-.04-.009.1.1,0,0,0-.061.025c-.045.035-.086.085-.143.091a.277.277,0,0,0,.056.1.1.1,0,0,0,.108.026c.093-.048.049-.054.12.006.042.036.089.072.143.028C182.692,194.073,182.584,193.891,182.539,193.816Z" transform="translate(-181.681 -190.479)" fill="#fcd07f" />
                  <path id="Path_1214" data-name="Path 1214" d="M184.424,195.517a.171.171,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.07.314.314,0,0,1,.024.178.281.281,0,0,0,.126-.009.1.1,0,0,0,.072-.093c-.006-.111-.032-.071.059-.113.053-.024.107-.054.089-.127C184.74,195.481,184.516,195.505,184.424,195.517Z" transform="translate(-181.892 -190.638)" fill="#fcd07f" />
                  <path id="Path_1215" data-name="Path 1215" d="M182.229,197.673a.161.161,0,0,1,.024-.138.275.275,0,0,0,.03-.025.1.1,0,0,0,.015-.062.282.282,0,0,1,.011-.163.282.282,0,0,0-.112-.015.094.094,0,0,0-.082.069c-.016.1.014.069-.073.09-.052.012-.106.028-.1.1C181.942,197.645,182.145,197.666,182.229,197.673Z" transform="translate(-181.66 -190.804)" fill="#fcd07f" />
                  <path id="Path_1216" data-name="Path 1216" d="M183.348,191.042a.224.224,0,0,0,.009-.048.039.039,0,0,0-.022-.04.056.056,0,0,0-.024,0,.159.159,0,0,0-.087.026.169.169,0,0,0-.04.123c0,.072-.039.209.082.227.016,0,.033,0,.048,0a.09.09,0,0,1,.074.057c.006.019.006.041.014.06a.045.045,0,0,0,.044.034c.023,0,.033-.032.038-.054a.229.229,0,0,0-.024-.219C183.408,191.154,183.329,191.14,183.348,191.042Z" transform="translate(-181.777 -190.209)" fill="#fcd07f" />
                  <path id="Path_1217" data-name="Path 1217" d="M183.924,190.339a.288.288,0,0,0,.055.013.046.046,0,0,0,.047-.024.06.06,0,0,0,0-.028.186.186,0,0,0-.027-.1.2.2,0,0,0-.142-.053c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.056a.1.1,0,0,1-.071.083c-.023.007-.047.006-.07.014s-.045.026-.043.051.035.04.063.047a.267.267,0,0,0,.256-.019C183.79,190.4,183.809,190.312,183.924,190.339Z" transform="translate(-181.798 -190.131)" fill="#fcd07f" />
                  <path id="Path_1218" data-name="Path 1218" d="M181.99,194.754A.315.315,0,0,0,182,194.7a.044.044,0,0,0-.024-.042.065.065,0,0,0-.026,0,.16.16,0,0,0-.091.03.171.171,0,0,0-.041.13c0,.077-.039.222.09.24.016,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.007.02.008.043.015.063a.048.048,0,0,0,.047.035c.025,0,.035-.034.041-.058a.244.244,0,0,0-.03-.232C182.055,194.873,181.971,194.859,181.99,194.754Z" transform="translate(-181.648 -190.558)" fill="#fcd07f" />
                  <path id="Path_1219" data-name="Path 1219" d="M184.342,198.041a.307.307,0,0,0,.022-.046.043.043,0,0,0-.012-.046.078.078,0,0,0-.024-.008.169.169,0,0,0-.1,0c-.043.019-.059.07-.073.113-.023.073-.1.2.022.253.015.007.033.008.049.014a.093.093,0,0,1,.059.078c0,.021,0,.043,0,.063s.014.045.036.047.042-.023.053-.045a.238.238,0,0,0,.033-.228C184.373,198.171,184.3,198.136,184.342,198.041Z" transform="translate(-181.865 -190.867)" fill="#fcd07f" />
                  <path id="Path_1220" data-name="Path 1220" d="M182.512,191.795a.271.271,0,0,0,.015.048.042.042,0,0,0,.041.025.06.06,0,0,0,.023-.012.157.157,0,0,0,.065-.068.168.168,0,0,0-.026-.132c-.034-.068-.071-.21-.19-.165-.015.005-.028.016-.043.023a.09.09,0,0,1-.1-.014c-.016-.014-.027-.033-.043-.047a.05.05,0,0,0-.059-.009c-.019.015-.014.045-.007.07.024.079.052.159.134.187S182.479,191.7,182.512,191.795Z" transform="translate(-181.683 -190.254)" fill="#fcd07f" />
                  <path id="Path_1221" data-name="Path 1221" d="M181.838,191.093a.16.16,0,0,1-.065.067.072.072,0,0,1-.024.012c-.015,0-.031-.011-.04-.024a.328.328,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.174.174,0,0,1,181.838,191.093Z" transform="translate(-181.631 -190.193)" fill="#fcd07f" />
                  <path id="Path_1222" data-name="Path 1222" d="M182.689,195.327a.333.333,0,0,0-.05.019.044.044,0,0,0-.024.043.065.065,0,0,0,.014.024.174.174,0,0,0,.075.065.181.181,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.006-.016-.019-.03-.026-.045a.1.1,0,0,1,.01-.1c.014-.017.033-.03.048-.047a.05.05,0,0,0,.006-.062c-.016-.02-.048-.013-.073,0-.082.029-.166.062-.19.15S182.793,195.288,182.689,195.327Z" transform="translate(-181.724 -190.587)" fill="#fcd07f" />
                  <path id="Path_1223" data-name="Path 1223" d="M181.863,196.444a.171.171,0,0,1-.077.065.06.06,0,0,1-.024.008.041.041,0,0,1-.04-.031.307.307,0,0,1-.012-.052.106.106,0,0,0-.079-.089v-.224a.3.3,0,0,1,.038-.015c.129-.033.152.12.18.2A.181.181,0,0,1,181.863,196.444Z" transform="translate(-181.631 -190.694)" fill="#fcd07f" />
                  <path id="Path_1224" data-name="Path 1224" d="M184.345,194.515l.026.017c.085.047.131-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.057-.248-.087-.253.024a.087.087,0,0,0,.013.061.149.149,0,0,0,.029.024c.029.026.029.072.038.11A.208.208,0,0,0,184.345,194.515Z" transform="translate(-181.872 -190.509)" fill="#fcd07f" />
                  <path id="Path_1225" data-name="Path 1225" d="M183.863,196.728a.209.209,0,0,0,0,.034c0,.111.144.091.212.066a.271.271,0,0,0,.171-.179c.023-.091-.048-.292-.16-.236a.1.1,0,0,0-.053.044c0,.014,0,.028-.007.042-.01.043-.054.067-.087.1A.231.231,0,0,0,183.863,196.728Z" transform="translate(-181.841 -190.722)" fill="#fcd07f" />
                  <path id="Path_1226" data-name="Path 1226" d="M184.227,190.977,184.2,191c-.081.074.032.168.1.2a.273.273,0,0,0,.248,0c.082-.046.181-.235.064-.279a.1.1,0,0,0-.07-.009.4.4,0,0,0-.035.024c-.038.022-.086.005-.13,0A.233.233,0,0,0,184.227,190.977Z" transform="translate(-181.87 -190.205)" fill="#fcd07f" />
                  <path id="Path_1227" data-name="Path 1227" d="M183.938,192.072a.182.182,0,0,0,0,.032c0,.1.135.08.2.054a.248.248,0,0,0,.152-.169c.019-.085-.052-.268-.153-.214a.086.086,0,0,0-.048.043.254.254,0,0,0-.006.038c-.008.04-.048.063-.078.091A.212.212,0,0,0,183.938,192.072Z" transform="translate(-181.848 -190.285)" fill="#fcd07f" />
                  <path id="Path_1228" data-name="Path 1228" d="M183.564,195.264a.115.115,0,0,0-.016.024c-.045.082.071.128.132.137a.232.232,0,0,0,.2-.065c.055-.059.084-.238-.023-.243a.08.08,0,0,0-.058.012.175.175,0,0,0-.024.028c-.024.028-.068.028-.105.036A.2.2,0,0,0,183.564,195.264Z" transform="translate(-181.811 -190.601)" fill="#fcd07f" />
                  <path id="Path_1229" data-name="Path 1229" d="M184.259,193.163a.164.164,0,0,0,.012.029c.044.089.152.015.2-.033a.247.247,0,0,0,.065-.214c-.019-.083-.157-.216-.226-.127a.092.092,0,0,0-.024.058.24.24,0,0,0,.011.036c.009.039-.016.076-.031.114A.206.206,0,0,0,184.259,193.163Z" transform="translate(-181.878 -190.382)" fill="#fcd07f" />
                  <path id="Path_1230" data-name="Path 1230" d="M182.5,199.158c.065.055.154.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.018-.1-.015-.122a.157.157,0,0,0,.027.305.129.129,0,0,1,.056.014C182.462,199.127,182.479,199.141,182.5,199.158Z" transform="translate(-181.69 -190.948)" fill="#fcd07f" />
                  <path id="Path_1231" data-name="Path 1231" d="M184.871,193.5v.168c-.024-.015-.059,0-.09,0a.227.227,0,0,1-.211-.139c-.027-.143.072-.1.148-.059l.057.03a.155.155,0,0,0,.058,0A.18.18,0,0,1,184.871,193.5Z" transform="translate(-181.908 -190.442)" fill="#fcd07f" />
                  <path id="Path_1232" data-name="Path 1232" d="M182.469,198.365c-.037.081-.078.187.069.15a.237.237,0,0,0,.133-.227c0-.037-.022-.08,0-.106s.1-.009.118-.05a.164.164,0,0,0-.3.111.142.142,0,0,1,0,.062C182.491,198.32,182.48,198.342,182.469,198.365Z" transform="translate(-181.707 -190.878)" fill="#fcd07f" />
                  <path id="Path_1233" data-name="Path 1233" d="M183.695,198.986c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.008.067-.016.085s-.082,0-.1.026a.135.135,0,0,0,.257-.055.1.1,0,0,1,.005-.05Z" transform="translate(-181.798 -190.953)" fill="#fcd07f" />
                  <path id="Path_1234" data-name="Path 1234" d="M182.247,190.558c0,.091,0,.207.122.115a.241.241,0,0,0,.038-.264c-.018-.033-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.24.22.126.126,0,0,1,.025.056C182.25,190.508,182.248,190.532,182.247,190.558Z" transform="translate(-181.685 -190.137)" fill="#fcd07f" />
                  <path id="Path_1235" data-name="Path 1235" d="M183.181,197.422c-.048.071-.1.164.041.152a.226.226,0,0,0,.16-.192c0-.034-.008-.078.02-.1s.1.008.12-.027a.156.156,0,0,0-.3.057.132.132,0,0,1-.007.057C183.207,197.384,183.194,197.4,183.181,197.422Z" transform="translate(-181.772 -190.794)" fill="#fcd07f" />
                  <path id="Path_1236" data-name="Path 1236" d="M183.151,192.777c-.07-.005-.16-.006-.092.092a.189.189,0,0,0,.2.035c.026-.014.053-.038.08-.026s.034.072.07.073a.129.129,0,0,0-.166-.19.1.1,0,0,1-.044.018Z" transform="translate(-181.763 -190.378)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1237" data-name="Path 1237" d="M184.164,190.273a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.314,2.314,0,0,1,2.1,0A.639.639,0,0,1,184.164,190.273Z" transform="translate(-181.631 -188.81)" fill="#f1f4f4" />
            </g>
            <g id="Group_245" data-name="Group 245" transform="translate(18.209 12.693)">
              <path id="Path_1238" data-name="Path 1238" d="M187.57,200.182l-.348.247c-.029-.02-.058-.038-.09-.057a2.316,2.316,0,0,0-2.1,0c-.033.02-.065.04-.1.061l-.336-.247v14.446h0a.745.745,0,0,0,.437.6,2.319,2.319,0,0,0,2.1,0,.731.731,0,0,0,.431-.607h0Z" transform="translate(-184.605 -191.22)" fill="#dce4e4" />
              <g id="Group_244" data-name="Group 244" transform="translate(0 0.881)">
                <path id="Path_1239" data-name="Path 1239" d="M187.569,191.33v8.017a.727.727,0,0,1-.431.608,2.318,2.318,0,0,1-2.094,0,.75.75,0,0,1-.438-.6V191.33l.337.247c.029-.021.06-.042.094-.062a2.315,2.315,0,0,1,2.1,0,.923.923,0,0,1,.089.057Z" transform="translate(-184.605 -191.267)" fill="#f1a443" />
                <g id="Group_243" data-name="Group 243" transform="translate(0 0.316)">
                  <path id="Path_1240" data-name="Path 1240" d="M185.632,197.884c-.053-.032-.112-.072-.118-.134a.463.463,0,0,0,0-.05.116.116,0,0,0-.05-.064c-.056-.042-.129-.074-.153-.14a.338.338,0,0,0-.105.1.118.118,0,0,0,0,.137c.086.1.08.041.03.145-.029.061-.055.128.014.178C185.377,198.148,185.558,197.962,185.632,197.884Z" transform="translate(-184.66 -192.17)" fill="#fcd07f" />
                  <path id="Path_1241" data-name="Path 1241" d="M187.847,198.817v.252a.238.238,0,0,0-.108.1c-.074-.079-.249-.269-.149-.389.054-.065.12-.034.178,0S187.834,198.825,187.847,198.817Z" transform="translate(-184.883 -192.288)" fill="#fcd07f" />
                  <path id="Path_1242" data-name="Path 1242" d="M184.925,199.67c-.062.065-.2.209-.32.194v-.575c.028.062.1.092.149.132a.114.114,0,0,1,.05.065.425.425,0,0,1,0,.048C184.813,199.6,184.871,199.638,184.925,199.67Z" transform="translate(-184.605 -192.339)" fill="#fcd07f" />
                  <path id="Path_1243" data-name="Path 1243" d="M184.922,194.028a.21.21,0,0,1,.074.171.508.508,0,0,0-.014.05.12.12,0,0,0,.029.08c.043.061.105.116.108.189a.356.356,0,0,0,.137-.066.126.126,0,0,0,.04-.138c-.056-.122-.067-.066.015-.154.049-.052.1-.11.044-.182C185.261,193.846,185.021,193.973,184.922,194.028Z" transform="translate(-184.635 -191.833)" fill="#fcd07f" />
                  <path id="Path_1244" data-name="Path 1244" d="M186.651,195.447a.162.162,0,0,1-.077-.119.272.272,0,0,0,0-.038.087.087,0,0,0-.032-.056c-.039-.039-.092-.072-.1-.127a.286.286,0,0,0-.093.067.094.094,0,0,0-.012.107c.057.084.059.041.008.117-.03.044-.058.093-.009.141C186.423,195.625,186.585,195.5,186.651,195.447Z" transform="translate(-184.766 -191.945)" fill="#fcd07f" />
                  <path id="Path_1245" data-name="Path 1245" d="M187.851,193.306v.274a.282.282,0,0,0-.036.081c-.073-.041-.252-.144-.2-.257.025-.062.082-.055.134-.045.089.019.048.034.1-.05Z" transform="translate(-184.888 -191.775)" fill="#fcd07f" />
                  <path id="Path_1246" data-name="Path 1246" d="M184.818,195.524a.551.551,0,0,1-.213.118v-.456l0,0c.012.055.065.088.105.127a.1.1,0,0,1,.033.055.3.3,0,0,1-.005.04C184.739,195.453,184.781,195.492,184.818,195.524Z" transform="translate(-184.605 -191.952)" fill="#fcd07f" />
                  <path id="Path_1247" data-name="Path 1247" d="M185.513,195.3a.161.161,0,0,1-.13.063.3.3,0,0,0-.039-.008.1.1,0,0,0-.062.024c-.045.036-.086.086-.143.091a.277.277,0,0,0,.056.1.1.1,0,0,0,.109.026c.093-.048.049-.054.12.007.041.035.088.071.143.027C185.667,195.557,185.559,195.375,185.513,195.3Z" transform="translate(-184.655 -191.963)" fill="#fcd07f" />
                  <path id="Path_1248" data-name="Path 1248" d="M187.4,197a.177.177,0,0,1,0,.154.306.306,0,0,0-.025.034.1.1,0,0,0,0,.07.326.326,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.11-.032-.071.059-.112.053-.025.107-.055.09-.127C187.714,196.965,187.491,196.989,187.4,197Z" transform="translate(-184.866 -192.122)" fill="#fcd07f" />
                  <path id="Path_1249" data-name="Path 1249" d="M185.2,199.157a.158.158,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.09.09,0,0,0,.015-.062.291.291,0,0,1,.012-.163.286.286,0,0,0-.113-.015.093.093,0,0,0-.082.07c-.016.1.014.069-.074.089-.052.012-.105.028-.1.1C184.917,199.13,185.119,199.15,185.2,199.157Z" transform="translate(-184.634 -192.288)" fill="#fcd07f" />
                  <path id="Path_1250" data-name="Path 1250" d="M186.323,192.526a.3.3,0,0,0,.009-.048.042.042,0,0,0-.022-.04.06.06,0,0,0-.025,0,.158.158,0,0,0-.086.026.163.163,0,0,0-.04.123c0,.072-.04.209.082.227.015,0,.032,0,.048,0a.089.089,0,0,1,.073.057c.007.02.007.041.014.06s.024.038.044.034.034-.031.039-.054a.229.229,0,0,0-.025-.218C186.383,192.638,186.3,192.624,186.323,192.526Z" transform="translate(-184.751 -191.693)" fill="#fcd07f" />
                  <path id="Path_1251" data-name="Path 1251" d="M186.9,191.823a.3.3,0,0,0,.055.013.048.048,0,0,0,.048-.024.077.077,0,0,0,0-.029.18.18,0,0,0-.028-.1.192.192,0,0,0-.141-.052c-.085-.007-.244-.055-.27.085,0,.019,0,.038,0,.056a.1.1,0,0,1-.07.084c-.023.006-.047.005-.07.013a.055.055,0,0,0-.043.051c0,.028.035.041.063.047a.266.266,0,0,0,.257-.019C186.764,191.889,186.784,191.8,186.9,191.823Z" transform="translate(-184.772 -191.616)" fill="#fcd07f" />
                  <path id="Path_1252" data-name="Path 1252" d="M184.964,196.239a.335.335,0,0,0,.01-.051.043.043,0,0,0-.024-.042.051.051,0,0,0-.026,0,.172.172,0,0,0-.091.03.175.175,0,0,0-.04.13c0,.077-.039.223.089.24a.477.477,0,0,0,.051,0,.094.094,0,0,1,.079.06c.008.021.008.043.015.063s.026.04.048.036.034-.034.04-.059a.239.239,0,0,0-.029-.231C185.03,196.357,184.945,196.343,184.964,196.239Z" transform="translate(-184.622 -192.043)" fill="#fcd07f" />
                  <path id="Path_1253" data-name="Path 1253" d="M187.316,199.525a.434.434,0,0,0,.023-.046.042.042,0,0,0-.013-.046.052.052,0,0,0-.024-.007.159.159,0,0,0-.094,0,.17.17,0,0,0-.073.114c-.024.072-.1.2.022.253.015.006.033.007.048.014a.093.093,0,0,1,.06.078c0,.021,0,.043,0,.064a.049.049,0,0,0,.035.047c.025,0,.043-.024.053-.045a.24.24,0,0,0,.034-.229C187.347,199.655,187.27,199.62,187.316,199.525Z" transform="translate(-184.839 -192.351)" fill="#fcd07f" />
                  <path id="Path_1254" data-name="Path 1254" d="M185.486,193.279a.312.312,0,0,0,.016.049.041.041,0,0,0,.04.024.056.056,0,0,0,.024-.011.17.17,0,0,0,.065-.069.172.172,0,0,0-.026-.131c-.034-.069-.072-.211-.19-.166-.015.005-.029.016-.044.024a.1.1,0,0,1-.1-.014c-.016-.014-.026-.034-.043-.047a.046.046,0,0,0-.058-.009c-.02.014-.015.045-.007.069.024.079.051.16.134.187S185.453,193.18,185.486,193.279Z" transform="translate(-184.657 -191.739)" fill="#fcd07f" />
                  <path id="Path_1255" data-name="Path 1255" d="M184.813,192.577a.172.172,0,0,1-.065.067.061.061,0,0,1-.024.012c-.015,0-.031-.01-.039-.024a.2.2,0,0,1-.015-.048.1.1,0,0,0-.064-.072v-.237c.112-.034.149.1.182.17A.176.176,0,0,1,184.813,192.577Z" transform="translate(-184.605 -191.677)" fill="#fcd07f" />
                  <path id="Path_1256" data-name="Path 1256" d="M185.664,196.811a.3.3,0,0,0-.05.019.045.045,0,0,0-.024.044.06.06,0,0,0,.013.024.131.131,0,0,0,.213.031c.071-.039.219-.085.166-.208-.007-.015-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.018.034-.03.047-.048a.05.05,0,0,0,.007-.062c-.017-.02-.049-.013-.073,0-.082.028-.167.061-.19.149S185.767,196.772,185.664,196.811Z" transform="translate(-184.698 -192.072)" fill="#fcd07f" />
                  <path id="Path_1257" data-name="Path 1257" d="M184.837,197.928a.171.171,0,0,1-.077.065.057.057,0,0,1-.024.009.042.042,0,0,1-.039-.032.189.189,0,0,1-.012-.051.108.108,0,0,0-.08-.09v-.224c.013,0,.024-.012.038-.015.129-.033.153.12.18.2A.175.175,0,0,1,184.837,197.928Z" transform="translate(-184.605 -192.179)" fill="#fcd07f" />
                  <path id="Path_1258" data-name="Path 1258" d="M187.32,196a.157.157,0,0,0,.025.017c.086.047.132-.073.141-.138a.238.238,0,0,0-.067-.208c-.062-.057-.247-.088-.253.024a.088.088,0,0,0,.013.061c.008.009.02.014.029.024.03.025.029.071.038.109A.2.2,0,0,0,187.32,196Z" transform="translate(-184.846 -191.993)" fill="#fcd07f" />
                  <path id="Path_1259" data-name="Path 1259" d="M186.838,198.212a.22.22,0,0,0,0,.034c0,.11.143.091.212.065a.269.269,0,0,0,.17-.179c.023-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.045.2.2,0,0,0-.007.041c-.01.043-.054.067-.088.1A.23.23,0,0,0,186.838,198.212Z" transform="translate(-184.815 -192.207)" fill="#fcd07f" />
                  <path id="Path_1260" data-name="Path 1260" d="M187.2,192.461a.159.159,0,0,0-.027.022c-.082.075.031.168.1.2a.268.268,0,0,0,.247,0c.083-.046.182-.235.064-.279a.1.1,0,0,0-.069-.008.18.18,0,0,0-.035.023.22.22,0,0,1-.13,0A.233.233,0,0,0,187.2,192.461Z" transform="translate(-184.845 -191.689)" fill="#fcd07f" />
                  <path id="Path_1261" data-name="Path 1261" d="M186.912,193.556c0,.01,0,.021,0,.032,0,.1.135.081.2.055a.252.252,0,0,0,.153-.17c.018-.085-.052-.268-.154-.214a.092.092,0,0,0-.048.043.277.277,0,0,0-.005.038c-.008.041-.049.063-.078.091A.216.216,0,0,0,186.912,193.556Z" transform="translate(-184.822 -191.77)" fill="#fcd07f" />
                  <path id="Path_1262" data-name="Path 1262" d="M186.539,196.748a.191.191,0,0,0-.016.024c-.046.083.07.128.132.137a.231.231,0,0,0,.2-.064c.055-.06.085-.239-.023-.244a.085.085,0,0,0-.058.012.343.343,0,0,0-.023.028c-.025.028-.069.028-.105.037A.192.192,0,0,0,186.539,196.748Z" transform="translate(-184.785 -192.086)" fill="#fcd07f" />
                  <path id="Path_1263" data-name="Path 1263" d="M187.233,194.647a.277.277,0,0,0,.012.029c.044.089.153.015.2-.033a.242.242,0,0,0,.065-.214c-.019-.083-.157-.216-.225-.126a.09.09,0,0,0-.024.058.2.2,0,0,0,.011.036c.009.039-.017.076-.032.113A.206.206,0,0,0,187.233,194.647Z" transform="translate(-184.852 -191.866)" fill="#fcd07f" />
                  <path id="Path_1264" data-name="Path 1264" d="M185.473,200.642c.065.055.153.12.156-.025,0-.08-.1-.169-.176-.18-.035-.005-.079,0-.1-.031s.017-.1-.015-.122a.161.161,0,0,0-.109.163.162.162,0,0,0,.135.142.121.121,0,0,1,.057.014C185.437,200.611,185.453,200.626,185.473,200.642Z" transform="translate(-184.664 -192.433)" fill="#fcd07f" />
                  <path id="Path_1265" data-name="Path 1265" d="M187.845,194.985v.168c-.024-.016-.058-.005-.089,0a.231.231,0,0,1-.212-.139c-.027-.142.072-.1.148-.058l.057.029a.143.143,0,0,0,.059,0A.131.131,0,0,1,187.845,194.985Z" transform="translate(-184.882 -191.927)" fill="#fcd07f" />
                  <path id="Path_1266" data-name="Path 1266" d="M185.443,199.849c-.037.082-.078.187.07.15a.238.238,0,0,0,.132-.227c0-.036-.021-.08,0-.106s.1-.008.119-.05a.164.164,0,0,0-.3.112.125.125,0,0,1,0,.061C185.465,199.8,185.454,199.826,185.443,199.849Z" transform="translate(-184.681 -192.363)" fill="#fcd07f" />
                  <path id="Path_1267" data-name="Path 1267" d="M186.67,200.47c.04-.061.086-.143-.038-.13a.2.2,0,0,0-.136.169c0,.03.008.067-.015.085s-.083,0-.1.027a.135.135,0,0,0,.256-.055.113.113,0,0,1,.006-.05C186.647,200.5,186.658,200.488,186.67,200.47Z" transform="translate(-184.772 -192.438)" fill="#fcd07f" />
                  <path id="Path_1268" data-name="Path 1268" d="M185.222,192.042c0,.091,0,.207.122.115a.24.24,0,0,0,.038-.264c-.017-.033-.05-.066-.036-.1s.091-.046.092-.091a.167.167,0,0,0-.239.219.135.135,0,0,1,.025.057C185.224,191.993,185.222,192.016,185.222,192.042Z" transform="translate(-184.659 -191.621)" fill="#fcd07f" />
                  <path id="Path_1269" data-name="Path 1269" d="M186.155,198.907c-.048.07-.1.163.041.151.08-.006.157-.119.16-.192,0-.034-.007-.077.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.124.124,0,0,1-.008.058C186.182,198.868,186.168,198.886,186.155,198.907Z" transform="translate(-184.746 -192.279)" fill="#fcd07f" />
                  <path id="Path_1270" data-name="Path 1270" d="M186.125,194.261c-.07-.005-.159-.005-.091.092a.187.187,0,0,0,.2.035c.025-.014.053-.038.079-.026s.034.072.07.073a.129.129,0,0,0-.165-.19.1.1,0,0,1-.044.018A.391.391,0,0,1,186.125,194.261Z" transform="translate(-184.737 -191.862)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1271" data-name="Path 1271" d="M187.138,191.757a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,187.138,191.757Z" transform="translate(-184.605 -190.294)" fill="#f1f4f4" />
            </g>
            <g id="Group_248" data-name="Group 248" transform="translate(20.985 5.661)">
              <path id="Path_1272" data-name="Path 1272" d="M190.633,192.418l-.348.246c-.028-.019-.058-.038-.089-.056a2.316,2.316,0,0,0-2.1,0q-.05.029-.1.06l-.336-.246v14.445h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.725.725,0,0,0,.43-.607h0Z" transform="translate(-187.669 -183.456)" fill="#ecf1f1" />
              <g id="Group_247" data-name="Group 247" transform="translate(0 0.881)">
                <path id="Path_1273" data-name="Path 1273" d="M190.633,183.566v8.016a.728.728,0,0,1-.431.608,2.314,2.314,0,0,1-2.1,0,.749.749,0,0,1-.437-.6v-8.016l.337.247c.029-.021.06-.042.094-.062a2.313,2.313,0,0,1,2.1.005.938.938,0,0,1,.09.057Z" transform="translate(-187.669 -183.503)" fill="#f1a443" />
                <g id="Group_246" data-name="Group 246" transform="translate(0 0.316)">
                  <path id="Path_1274" data-name="Path 1274" d="M188.7,190.12c-.053-.033-.112-.073-.118-.135a.3.3,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.057-.043-.129-.074-.153-.14a.359.359,0,0,0-.106.1.121.121,0,0,0,0,.138c.086.1.081.04.031.144-.029.061-.055.128.014.179C188.44,190.384,188.621,190.2,188.7,190.12Z" transform="translate(-187.724 -184.405)" fill="#fcd07f" />
                  <path id="Path_1275" data-name="Path 1275" d="M190.911,191.052v.253a.239.239,0,0,0-.109.1c-.073-.08-.249-.27-.149-.39.054-.065.12-.034.178,0S190.9,191.061,190.911,191.052Z" transform="translate(-187.947 -184.523)" fill="#fcd07f" />
                  <path id="Path_1276" data-name="Path 1276" d="M187.989,191.905c-.062.065-.2.21-.32.195v-.575c.027.062.094.092.149.132a.117.117,0,0,1,.05.064.444.444,0,0,1,0,.049C187.876,191.832,187.935,191.873,187.989,191.905Z" transform="translate(-187.669 -184.574)" fill="#fcd07f" />
                  <path id="Path_1277" data-name="Path 1277" d="M187.986,186.264a.212.212,0,0,1,.074.171.311.311,0,0,0-.014.05.122.122,0,0,0,.029.08c.043.06.1.115.108.188a.363.363,0,0,0,.137-.066.123.123,0,0,0,.039-.138c-.055-.122-.067-.065.016-.153.048-.052.1-.11.043-.183C188.325,186.081,188.085,186.208,187.986,186.264Z" transform="translate(-187.699 -184.068)" fill="#fcd07f" />
                  <path id="Path_1278" data-name="Path 1278" d="M189.715,187.682c-.038-.031-.08-.069-.077-.118a.27.27,0,0,0,0-.039.089.089,0,0,0-.032-.055c-.04-.04-.092-.072-.1-.128a.279.279,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.058.04.007.116-.029.044-.057.094-.009.141C189.486,187.86,189.648,187.735,189.715,187.682Z" transform="translate(-187.83 -184.18)" fill="#fcd07f" />
                  <path id="Path_1279" data-name="Path 1279" d="M190.915,185.542v.273a.259.259,0,0,0-.036.081c-.073-.041-.253-.144-.2-.257.026-.062.082-.054.134-.044.09.019.048.034.105-.05Z" transform="translate(-187.951 -184.011)" fill="#fcd07f" />
                  <path id="Path_1280" data-name="Path 1280" d="M187.882,187.759a.547.547,0,0,1-.213.119v-.456l0,0c.012.054.065.087.1.127a.1.1,0,0,1,.033.055.287.287,0,0,1,0,.039A.164.164,0,0,0,187.882,187.759Z" transform="translate(-187.669 -184.187)" fill="#fcd07f" />
                  <path id="Path_1281" data-name="Path 1281" d="M188.577,187.536c-.036.034-.082.072-.13.063a.317.317,0,0,0-.04-.009.1.1,0,0,0-.061.025c-.045.035-.086.085-.143.091a.284.284,0,0,0,.056.1.1.1,0,0,0,.108.025c.093-.047.049-.054.12.007.042.036.089.071.143.028C188.73,187.793,188.623,187.611,188.577,187.536Z" transform="translate(-187.719 -184.199)" fill="#fcd07f" />
                  <path id="Path_1282" data-name="Path 1282" d="M190.462,189.237a.171.171,0,0,1,0,.154c-.007.012-.019.021-.025.034a.1.1,0,0,0,0,.071.314.314,0,0,1,.024.178.309.309,0,0,0,.126-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.054.089-.127C190.777,189.2,190.555,189.225,190.462,189.237Z" transform="translate(-187.93 -184.358)" fill="#fcd07f" />
                  <path id="Path_1283" data-name="Path 1283" d="M188.267,191.393a.157.157,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.094.094,0,0,0,.014-.062.3.3,0,0,1,.012-.162.266.266,0,0,0-.112-.015.093.093,0,0,0-.082.069c-.016.1.014.069-.073.089-.052.013-.105.029-.1.1C187.98,191.365,188.183,191.386,188.267,191.393Z" transform="translate(-187.698 -184.524)" fill="#fcd07f" />
                  <path id="Path_1284" data-name="Path 1284" d="M189.386,184.761a.273.273,0,0,0,.01-.047.041.041,0,0,0-.023-.04.056.056,0,0,0-.024,0,.151.151,0,0,0-.087.026.163.163,0,0,0-.039.122c0,.073-.04.21.081.228.016,0,.033,0,.048,0a.09.09,0,0,1,.074.057c.006.019.006.04.014.06a.045.045,0,0,0,.044.034c.024,0,.034-.032.038-.054a.228.228,0,0,0-.024-.219C189.446,184.874,189.367,184.859,189.386,184.761Z" transform="translate(-187.815 -183.929)" fill="#fcd07f" />
                  <path id="Path_1285" data-name="Path 1285" d="M189.962,184.059a.374.374,0,0,0,.055.013.046.046,0,0,0,.047-.024.062.062,0,0,0,0-.029.183.183,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.085-.007-.244-.054-.269.086,0,.018,0,.037,0,.056a.1.1,0,0,1-.071.083c-.023.007-.047.006-.07.014s-.045.026-.043.05.035.041.063.048a.266.266,0,0,0,.257-.02C189.828,184.125,189.847,184.032,189.962,184.059Z" transform="translate(-187.836 -183.851)" fill="#fcd07f" />
                  <path id="Path_1286" data-name="Path 1286" d="M188.028,188.474a.329.329,0,0,0,.009-.051.042.042,0,0,0-.024-.042.065.065,0,0,0-.026,0,.166.166,0,0,0-.091.029.177.177,0,0,0-.041.13c0,.078-.039.223.09.241.017,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.007.02.008.043.015.062s.026.04.047.036.035-.034.041-.058a.244.244,0,0,0-.03-.232C188.094,188.593,188.009,188.579,188.028,188.474Z" transform="translate(-187.686 -184.278)" fill="#fcd07f" />
                  <path id="Path_1287" data-name="Path 1287" d="M190.38,191.76a.26.26,0,0,0,.022-.045.043.043,0,0,0-.012-.046.06.06,0,0,0-.024-.008.169.169,0,0,0-.1,0,.173.173,0,0,0-.073.114c-.023.073-.1.2.022.253a.438.438,0,0,0,.049.014.1.1,0,0,1,.06.078c0,.022,0,.043,0,.064a.048.048,0,0,0,.036.047c.024,0,.042-.023.053-.045a.238.238,0,0,0,.034-.228C190.411,191.891,190.334,191.855,190.38,191.76Z" transform="translate(-187.903 -184.587)" fill="#fcd07f" />
                  <path id="Path_1288" data-name="Path 1288" d="M188.55,185.515a.27.27,0,0,0,.015.048.043.043,0,0,0,.041.025.06.06,0,0,0,.023-.012.157.157,0,0,0,.065-.068.171.171,0,0,0-.026-.132c-.034-.068-.071-.21-.189-.166-.016.006-.029.017-.044.024a.09.09,0,0,1-.1-.014c-.016-.014-.027-.034-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.045-.008.07.024.079.052.159.134.186S188.517,185.415,188.55,185.515Z" transform="translate(-187.721 -183.974)" fill="#fcd07f" />
                  <path id="Path_1289" data-name="Path 1289" d="M187.876,184.812a.155.155,0,0,1-.065.067.06.06,0,0,1-.024.013c-.015,0-.031-.011-.04-.024a.326.326,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.236c.111-.034.149.1.182.17A.173.173,0,0,1,187.876,184.812Z" transform="translate(-187.669 -183.913)" fill="#fcd07f" />
                  <path id="Path_1290" data-name="Path 1290" d="M188.727,189.047a.334.334,0,0,0-.05.019.046.046,0,0,0-.024.043.062.062,0,0,0,.014.024.176.176,0,0,0,.075.066.181.181,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.006-.016-.019-.03-.026-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.047-.047a.05.05,0,0,0,.006-.062c-.016-.02-.048-.014-.073,0-.082.029-.166.062-.189.15S188.831,189.007,188.727,189.047Z" transform="translate(-187.762 -184.307)" fill="#fcd07f" />
                  <path id="Path_1291" data-name="Path 1291" d="M187.9,190.164a.16.16,0,0,1-.077.065.062.062,0,0,1-.024.009.041.041,0,0,1-.04-.031.307.307,0,0,1-.012-.052.105.105,0,0,0-.079-.089v-.224a.208.208,0,0,1,.038-.015c.129-.033.152.12.18.2A.179.179,0,0,1,187.9,190.164Z" transform="translate(-187.669 -184.414)" fill="#fcd07f" />
                  <path id="Path_1292" data-name="Path 1292" d="M190.384,188.235a.178.178,0,0,0,.025.017c.085.047.131-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.057-.248-.087-.253.024a.087.087,0,0,0,.013.061.15.15,0,0,0,.029.024c.029.026.029.071.038.11A.2.2,0,0,0,190.384,188.235Z" transform="translate(-187.91 -184.229)" fill="#fcd07f" />
                  <path id="Path_1293" data-name="Path 1293" d="M189.9,190.448a.209.209,0,0,0,0,.034c0,.111.144.091.212.066a.271.271,0,0,0,.171-.179c.023-.091-.048-.292-.16-.236a.1.1,0,0,0-.053.044.337.337,0,0,0-.008.042c-.01.043-.054.067-.087.1A.231.231,0,0,0,189.9,190.448Z" transform="translate(-187.879 -184.442)" fill="#fcd07f" />
                  <path id="Path_1294" data-name="Path 1294" d="M190.265,184.7l-.027.021c-.081.075.032.168.1.2a.273.273,0,0,0,.248,0c.082-.045.181-.234.064-.278a.1.1,0,0,0-.07-.009c-.013.005-.023.016-.035.023-.038.023-.086.006-.13,0A.227.227,0,0,0,190.265,184.7Z" transform="translate(-187.908 -183.925)" fill="#fcd07f" />
                  <path id="Path_1295" data-name="Path 1295" d="M189.976,185.792a.175.175,0,0,0,0,.031c0,.1.135.081.2.055a.25.25,0,0,0,.152-.169c.019-.085-.052-.268-.153-.215a.091.091,0,0,0-.048.043.258.258,0,0,0-.006.038c-.007.04-.048.063-.078.091A.217.217,0,0,0,189.976,185.792Z" transform="translate(-187.886 -184.005)" fill="#fcd07f" />
                  <path id="Path_1296" data-name="Path 1296" d="M189.6,188.984a.165.165,0,0,0-.016.024c-.045.082.071.128.132.137a.232.232,0,0,0,.2-.065c.055-.059.084-.238-.023-.244a.085.085,0,0,0-.058.012.228.228,0,0,0-.024.028c-.024.029-.068.028-.105.037A.194.194,0,0,0,189.6,188.984Z" transform="translate(-187.849 -184.321)" fill="#fcd07f" />
                  <path id="Path_1297" data-name="Path 1297" d="M190.3,186.883a.192.192,0,0,0,.012.029c.044.089.152.015.2-.033a.246.246,0,0,0,.065-.214c-.019-.082-.157-.216-.225-.126a.087.087,0,0,0-.025.058c0,.013.008.024.011.036.01.039-.016.076-.031.113A.208.208,0,0,0,190.3,186.883Z" transform="translate(-187.916 -184.102)" fill="#fcd07f" />
                  <path id="Path_1298" data-name="Path 1298" d="M188.536,192.878c.065.055.154.12.157-.025,0-.081-.1-.17-.177-.181-.034-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.128.128,0,0,1,.056.014C188.5,192.846,188.517,192.861,188.536,192.878Z" transform="translate(-187.728 -184.668)" fill="#fcd07f" />
                  <path id="Path_1299" data-name="Path 1299" d="M190.909,187.22v.168c-.024-.015-.059-.005-.09,0a.227.227,0,0,1-.211-.139c-.027-.143.072-.1.148-.059l.057.029a.125.125,0,0,0,.058,0A.138.138,0,0,1,190.909,187.22Z" transform="translate(-187.946 -184.162)" fill="#fcd07f" />
                  <path id="Path_1300" data-name="Path 1300" d="M188.507,192.085c-.037.081-.078.188.069.15a.238.238,0,0,0,.133-.227c0-.037-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C188.529,192.04,188.518,192.062,188.507,192.085Z" transform="translate(-187.745 -184.598)" fill="#fcd07f" />
                  <path id="Path_1301" data-name="Path 1301" d="M189.733,192.706c.04-.062.086-.143-.038-.13a.194.194,0,0,0-.135.168c0,.031.008.068-.016.086s-.082,0-.1.026a.14.14,0,0,0,.15.074.138.138,0,0,0,.107-.13.1.1,0,0,1,.005-.05C189.71,192.74,189.721,192.724,189.733,192.706Z" transform="translate(-187.836 -184.673)" fill="#fcd07f" />
                  <path id="Path_1302" data-name="Path 1302" d="M188.285,184.278c0,.091,0,.207.122.115a.242.242,0,0,0,.038-.264c-.018-.033-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.239.22.124.124,0,0,1,.024.056C188.288,184.228,188.286,184.252,188.285,184.278Z" transform="translate(-187.723 -183.857)" fill="#fcd07f" />
                  <path id="Path_1303" data-name="Path 1303" d="M189.219,191.142c-.048.071-.1.164.041.152a.226.226,0,0,0,.16-.192c0-.035-.007-.078.02-.1s.1.008.12-.028a.156.156,0,0,0-.3.058.111.111,0,0,1-.007.057C189.245,191.1,189.232,191.122,189.219,191.142Z" transform="translate(-187.81 -184.514)" fill="#fcd07f" />
                  <path id="Path_1304" data-name="Path 1304" d="M189.189,186.5c-.07,0-.16-.005-.092.092a.187.187,0,0,0,.2.035c.026-.013.053-.037.08-.026s.034.072.07.074a.129.129,0,0,0-.166-.19.105.105,0,0,1-.044.018Z" transform="translate(-187.801 -184.098)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1305" data-name="Path 1305" d="M190.2,183.993a2.319,2.319,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.319,2.319,0,0,1,2.1,0A.639.639,0,0,1,190.2,183.993Z" transform="translate(-187.669 -182.53)" fill="#f1f4f4" />
            </g>
            <g id="Group_251" data-name="Group 251" transform="translate(13.185 12.856)">
              <path id="Path_1306" data-name="Path 1306" d="M182.022,200.362l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0c-.034.02-.064.04-.094.061l-.336-.247v14.446h0a.746.746,0,0,0,.437.6,2.319,2.319,0,0,0,2.1,0,.729.729,0,0,0,.43-.607h0Z" transform="translate(-179.058 -191.4)" fill="#dce4e4" />
              <g id="Group_250" data-name="Group 250" transform="translate(0 0.881)">
                <path id="Path_1307" data-name="Path 1307" d="M182.021,191.51v8.016a.728.728,0,0,1-.43.609,2.32,2.32,0,0,1-2.1,0,.749.749,0,0,1-.437-.605V191.51l.336.247c.029-.021.061-.042.094-.062a2.315,2.315,0,0,1,2.1,0,.926.926,0,0,1,.089.057Z" transform="translate(-179.058 -191.447)" fill="#f1a443" />
                <g id="Group_249" data-name="Group 249" transform="translate(0 0.315)">
                  <path id="Path_1308" data-name="Path 1308" d="M180.084,198.064c-.053-.032-.112-.072-.118-.134a.462.462,0,0,0,0-.05.118.118,0,0,0-.049-.064c-.057-.042-.13-.074-.154-.14a.337.337,0,0,0-.105.1.12.12,0,0,0,0,.137c.086.1.08.04.03.145-.029.061-.055.128.014.178C179.829,198.328,180.01,198.142,180.084,198.064Z" transform="translate(-179.113 -192.35)" fill="#fcd07f" />
                  <path id="Path_1309" data-name="Path 1309" d="M182.3,199v.252a.238.238,0,0,0-.108.1c-.074-.079-.249-.269-.149-.389.055-.065.12-.034.178,0S182.286,199.005,182.3,199Z" transform="translate(-179.336 -192.467)" fill="#fcd07f" />
                  <path id="Path_1310" data-name="Path 1310" d="M179.377,199.85c-.062.065-.2.209-.319.194v-.575c.027.062.094.092.149.132a.116.116,0,0,1,.049.065.425.425,0,0,1,0,.048C179.265,199.776,179.323,199.818,179.377,199.85Z" transform="translate(-179.058 -192.519)" fill="#fcd07f" />
                  <path id="Path_1311" data-name="Path 1311" d="M179.374,194.208a.21.21,0,0,1,.074.171.512.512,0,0,0-.014.05.125.125,0,0,0,.029.08c.043.061.105.116.108.189a.363.363,0,0,0,.137-.066.126.126,0,0,0,.04-.138c-.055-.122-.067-.066.016-.154.048-.052.1-.11.043-.183C179.713,194.026,179.474,194.153,179.374,194.208Z" transform="translate(-179.088 -192.013)" fill="#fcd07f" />
                  <path id="Path_1312" data-name="Path 1312" d="M181.1,195.627c-.038-.032-.08-.07-.077-.119,0-.013,0-.025,0-.038a.1.1,0,0,0-.032-.056c-.04-.039-.092-.072-.1-.127a.268.268,0,0,0-.093.067.092.092,0,0,0-.012.107c.056.084.058.041.007.117-.03.044-.057.093-.009.141C180.875,195.805,181.037,195.679,181.1,195.627Z" transform="translate(-179.219 -192.124)" fill="#fcd07f" />
                  <path id="Path_1313" data-name="Path 1313" d="M182.3,193.486v.274a.281.281,0,0,0-.035.081c-.074-.042-.253-.144-.2-.257.025-.062.082-.055.134-.045.09.019.048.034.105-.05Z" transform="translate(-179.34 -191.955)" fill="#fcd07f" />
                  <path id="Path_1314" data-name="Path 1314" d="M179.271,195.7a.554.554,0,0,1-.213.119v-.456l0,0c.013.055.065.088.105.127a.1.1,0,0,1,.033.055.325.325,0,0,1-.005.04A.166.166,0,0,0,179.271,195.7Z" transform="translate(-179.058 -192.132)" fill="#fcd07f" />
                  <path id="Path_1315" data-name="Path 1315" d="M179.966,195.48a.164.164,0,0,1-.131.063.269.269,0,0,0-.039-.008.1.1,0,0,0-.062.024c-.044.036-.086.086-.143.091a.29.29,0,0,0,.056.1.1.1,0,0,0,.109.026c.093-.048.049-.054.12.007.042.035.088.071.143.027C180.119,195.737,180.011,195.555,179.966,195.48Z" transform="translate(-179.108 -192.143)" fill="#fcd07f" />
                  <path id="Path_1316" data-name="Path 1316" d="M181.851,197.181a.174.174,0,0,1,0,.154.308.308,0,0,0-.025.034.1.1,0,0,0,0,.07.318.318,0,0,1,.025.178.3.3,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.111-.032-.071.06-.113.053-.024.107-.054.089-.126C182.166,197.145,181.943,197.169,181.851,197.181Z" transform="translate(-179.319 -192.302)" fill="#fcd07f" />
                  <path id="Path_1317" data-name="Path 1317" d="M179.656,199.337a.155.155,0,0,1,.024-.138.266.266,0,0,0,.029-.025.086.086,0,0,0,.015-.062.29.29,0,0,1,.012-.163.285.285,0,0,0-.113-.015.093.093,0,0,0-.082.07c-.016.1.015.069-.074.089-.052.012-.105.028-.1.1C179.369,199.31,179.571,199.33,179.656,199.337Z" transform="translate(-179.087 -192.468)" fill="#fcd07f" />
                  <path id="Path_1318" data-name="Path 1318" d="M180.775,192.706a.3.3,0,0,0,.009-.048.041.041,0,0,0-.022-.04.056.056,0,0,0-.024,0,.159.159,0,0,0-.087.026.166.166,0,0,0-.04.123c0,.072-.039.209.082.227.016,0,.032,0,.048,0a.089.089,0,0,1,.073.057c.007.019.007.041.014.06s.024.038.044.034.034-.031.039-.054a.227.227,0,0,0-.024-.218C180.835,192.818,180.756,192.8,180.775,192.706Z" transform="translate(-179.204 -191.873)" fill="#fcd07f" />
                  <path id="Path_1319" data-name="Path 1319" d="M181.35,192a.314.314,0,0,0,.056.013.047.047,0,0,0,.047-.024.071.071,0,0,0,0-.028.186.186,0,0,0-.027-.1.192.192,0,0,0-.142-.052c-.085-.008-.244-.055-.27.085,0,.018,0,.037,0,.056a.106.106,0,0,1-.071.084c-.023.006-.047.005-.07.013s-.045.027-.043.051.035.041.063.047a.267.267,0,0,0,.256-.019C181.216,192.069,181.236,191.976,181.35,192Z" transform="translate(-179.225 -191.795)" fill="#fcd07f" />
                  <path id="Path_1320" data-name="Path 1320" d="M179.417,196.419a.332.332,0,0,0,.009-.051.043.043,0,0,0-.024-.042.049.049,0,0,0-.025,0,.167.167,0,0,0-.091.03.172.172,0,0,0-.041.13c0,.077-.039.223.09.24.016,0,.034,0,.051,0a.1.1,0,0,1,.079.06c.007.021.007.043.014.063a.051.051,0,0,0,.048.036c.024,0,.035-.034.04-.059a.239.239,0,0,0-.029-.231C179.482,196.537,179.4,196.523,179.417,196.419Z" transform="translate(-179.075 -192.222)" fill="#fcd07f" />
                  <path id="Path_1321" data-name="Path 1321" d="M181.769,199.705a.242.242,0,0,0,.023-.046.043.043,0,0,0-.012-.046.059.059,0,0,0-.024-.007.161.161,0,0,0-.1,0,.173.173,0,0,0-.073.114c-.024.072-.1.2.022.253.015.006.033.007.049.014a.093.093,0,0,1,.059.078c0,.021,0,.043,0,.063s.014.045.035.047.043-.023.054-.044a.243.243,0,0,0,.034-.229C181.8,199.836,181.723,199.8,181.769,199.705Z" transform="translate(-179.292 -192.531)" fill="#fcd07f" />
                  <path id="Path_1322" data-name="Path 1322" d="M179.939,193.46a.247.247,0,0,0,.015.048.042.042,0,0,0,.04.024.057.057,0,0,0,.024-.012.162.162,0,0,0,.065-.068.175.175,0,0,0-.026-.132c-.034-.068-.071-.21-.19-.165a.41.41,0,0,0-.043.024.1.1,0,0,1-.1-.014c-.015-.014-.026-.034-.043-.048a.049.049,0,0,0-.058-.009c-.02.015-.015.046-.007.07.024.079.051.16.134.187S179.905,193.359,179.939,193.46Z" transform="translate(-179.11 -191.918)" fill="#fcd07f" />
                  <path id="Path_1323" data-name="Path 1323" d="M179.265,192.757a.166.166,0,0,1-.065.067.077.077,0,0,1-.024.012c-.015,0-.031-.01-.039-.024a.185.185,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.179.179,0,0,1,179.265,192.757Z" transform="translate(-179.058 -191.857)" fill="#fcd07f" />
                  <path id="Path_1324" data-name="Path 1324" d="M180.116,196.991a.335.335,0,0,0-.05.019.045.045,0,0,0-.024.044.062.062,0,0,0,.014.024.169.169,0,0,0,.075.065.182.182,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.007-.015-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.029.048-.047a.051.051,0,0,0,.006-.062c-.016-.02-.049-.013-.073,0-.082.029-.166.062-.19.15S180.219,196.952,180.116,196.991Z" transform="translate(-179.151 -192.251)" fill="#fcd07f" />
                  <path id="Path_1325" data-name="Path 1325" d="M179.289,198.108a.171.171,0,0,1-.077.065.052.052,0,0,1-.024.008.041.041,0,0,1-.04-.031.317.317,0,0,1-.012-.051.108.108,0,0,0-.079-.09v-.224a.353.353,0,0,1,.037-.015c.129-.033.153.12.18.2A.175.175,0,0,1,179.289,198.108Z" transform="translate(-179.058 -192.358)" fill="#fcd07f" />
                  <path id="Path_1326" data-name="Path 1326" d="M181.772,196.18a.244.244,0,0,0,.025.016c.086.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.056-.247-.087-.252.024a.087.087,0,0,0,.012.061.354.354,0,0,0,.029.023c.03.026.03.072.039.11A.2.2,0,0,0,181.772,196.18Z" transform="translate(-179.299 -192.173)" fill="#fcd07f" />
                  <path id="Path_1327" data-name="Path 1327" d="M181.29,198.392a.212.212,0,0,0,0,.034c0,.11.143.091.212.065a.269.269,0,0,0,.17-.179c.024-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.045.2.2,0,0,0-.007.041c-.01.043-.054.067-.087.1A.226.226,0,0,0,181.29,198.392Z" transform="translate(-179.268 -192.386)" fill="#fcd07f" />
                  <path id="Path_1328" data-name="Path 1328" d="M181.654,192.641a.158.158,0,0,0-.027.022c-.082.075.031.168.1.2a.27.27,0,0,0,.248,0c.082-.046.181-.235.064-.279a.1.1,0,0,0-.07-.008.213.213,0,0,0-.035.023c-.039.022-.086.005-.13,0A.233.233,0,0,0,181.654,192.641Z" transform="translate(-179.297 -191.869)" fill="#fcd07f" />
                  <path id="Path_1329" data-name="Path 1329" d="M181.365,193.736a.121.121,0,0,0,0,.032c0,.1.134.08.2.055a.252.252,0,0,0,.153-.17c.018-.085-.052-.268-.154-.214a.087.087,0,0,0-.047.043.28.28,0,0,0-.006.038c-.008.041-.048.063-.078.091A.209.209,0,0,0,181.365,193.736Z" transform="translate(-179.275 -191.949)" fill="#fcd07f" />
                  <path id="Path_1330" data-name="Path 1330" d="M180.991,196.928a.191.191,0,0,0-.016.024c-.046.082.071.128.132.137a.233.233,0,0,0,.2-.064c.054-.06.084-.239-.023-.244a.082.082,0,0,0-.059.012.236.236,0,0,0-.023.028c-.025.028-.068.028-.105.037A.2.2,0,0,0,180.991,196.928Z" transform="translate(-179.238 -192.265)" fill="#fcd07f" />
                  <path id="Path_1331" data-name="Path 1331" d="M181.686,194.827a.157.157,0,0,0,.011.029c.044.089.153.015.2-.033a.244.244,0,0,0,.064-.214c-.018-.083-.157-.216-.225-.126a.09.09,0,0,0-.024.057.192.192,0,0,0,.011.037c.009.039-.017.076-.032.113A.206.206,0,0,0,181.686,194.827Z" transform="translate(-179.305 -192.046)" fill="#fcd07f" />
                  <path id="Path_1332" data-name="Path 1332" d="M179.925,200.822c.065.055.153.12.157-.025,0-.081-.1-.169-.177-.18-.034-.005-.079,0-.1-.032s.017-.1-.015-.121a.157.157,0,0,0,.026.305.12.12,0,0,1,.057.014C179.889,200.791,179.906,200.806,179.925,200.822Z" transform="translate(-179.117 -192.612)" fill="#fcd07f" />
                  <path id="Path_1333" data-name="Path 1333" d="M182.3,195.165v.168c-.024-.015-.058,0-.089,0a.228.228,0,0,1-.211-.139c-.028-.142.072-.1.148-.059.022.013.043.023.056.03a.143.143,0,0,0,.059,0A.131.131,0,0,1,182.3,195.165Z" transform="translate(-179.335 -192.106)" fill="#fcd07f" />
                  <path id="Path_1334" data-name="Path 1334" d="M179.9,200.029c-.038.081-.079.188.069.15a.239.239,0,0,0,.133-.227c0-.036-.022-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.129.129,0,0,1,0,.062C179.917,199.984,179.907,200.006,179.9,200.029Z" transform="translate(-179.134 -192.543)" fill="#fcd07f" />
                  <path id="Path_1335" data-name="Path 1335" d="M181.122,200.65c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.007.067-.016.085s-.083,0-.1.026a.135.135,0,0,0,.257-.054.1.1,0,0,1,.005-.05C181.1,200.685,181.11,200.668,181.122,200.65Z" transform="translate(-179.225 -192.618)" fill="#fcd07f" />
                  <path id="Path_1336" data-name="Path 1336" d="M179.674,192.222c0,.091,0,.207.122.115a.242.242,0,0,0,.039-.264c-.018-.033-.051-.066-.037-.1s.091-.046.093-.091a.167.167,0,0,0-.24.219.135.135,0,0,1,.025.057A.626.626,0,0,1,179.674,192.222Z" transform="translate(-179.112 -191.801)" fill="#fcd07f" />
                  <path id="Path_1337" data-name="Path 1337" d="M180.607,199.087c-.047.07-.1.163.041.151.08-.006.157-.119.16-.192,0-.034-.007-.077.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.131.131,0,0,1-.007.057C180.634,199.048,180.621,199.066,180.607,199.087Z" transform="translate(-179.199 -192.458)" fill="#fcd07f" />
                  <path id="Path_1338" data-name="Path 1338" d="M180.578,194.441c-.071-.005-.16-.006-.092.092a.187.187,0,0,0,.2.035c.025-.014.053-.038.08-.026s.034.072.069.073a.129.129,0,0,0-.165-.19.1.1,0,0,1-.044.018Z" transform="translate(-179.19 -192.042)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1339" data-name="Path 1339" d="M181.591,191.937a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.007-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,181.591,191.937Z" transform="translate(-179.058 -190.474)" fill="#f1f4f4" />
            </g>
            <g id="Group_254" data-name="Group 254" transform="translate(15.879 14.2)">
              <path id="Path_1340" data-name="Path 1340" d="M185,201.846l-.349.247c-.028-.019-.057-.038-.089-.056a2.319,2.319,0,0,0-2.1,0c-.034.02-.065.04-.1.061l-.336-.247v14.446h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.726.726,0,0,0,.43-.607h0Z" transform="translate(-182.032 -192.884)" fill="#dce4e4" />
              <g id="Group_253" data-name="Group 253" transform="translate(0 0.882)">
                <path id="Path_1341" data-name="Path 1341" d="M185,192.994v8.017a.727.727,0,0,1-.431.608,2.312,2.312,0,0,1-2.094,0,.75.75,0,0,1-.438-.6v-8.017l.337.247c.029-.021.06-.041.094-.062a2.31,2.31,0,0,1,2.1.005.927.927,0,0,1,.09.056Z" transform="translate(-182.032 -192.931)" fill="#f1a443" />
                <g id="Group_252" data-name="Group 252" transform="translate(0 0.316)">
                  <path id="Path_1342" data-name="Path 1342" d="M183.058,199.548c-.053-.032-.111-.072-.118-.134a.291.291,0,0,0,0-.049.118.118,0,0,0-.05-.065c-.057-.042-.129-.074-.153-.14a.36.36,0,0,0-.105.1.118.118,0,0,0,0,.137c.087.1.081.041.031.145-.029.061-.055.128.014.178C182.8,199.813,182.984,199.626,183.058,199.548Z" transform="translate(-182.087 -193.834)" fill="#fcd07f" />
                  <path id="Path_1343" data-name="Path 1343" d="M185.274,200.481v.253a.236.236,0,0,0-.108.1c-.074-.079-.249-.269-.149-.389.054-.065.12-.034.178,0S185.261,200.489,185.274,200.481Z" transform="translate(-182.311 -193.952)" fill="#fcd07f" />
                  <path id="Path_1344" data-name="Path 1344" d="M182.352,201.334c-.062.065-.2.209-.32.194v-.575c.027.062.094.093.149.132a.117.117,0,0,1,.05.065.428.428,0,0,1,0,.048C182.239,201.261,182.3,201.3,182.352,201.334Z" transform="translate(-182.032 -194.003)" fill="#fcd07f" />
                  <path id="Path_1345" data-name="Path 1345" d="M182.349,195.692a.214.214,0,0,1,.074.171.51.51,0,0,0-.014.05.123.123,0,0,0,.029.081c.043.06.1.115.108.188a.356.356,0,0,0,.137-.066.124.124,0,0,0,.039-.138c-.055-.122-.066-.066.016-.154.048-.051.1-.11.043-.182C182.688,195.51,182.448,195.637,182.349,195.692Z" transform="translate(-182.062 -193.497)" fill="#fcd07f" />
                  <path id="Path_1346" data-name="Path 1346" d="M184.078,197.111c-.038-.031-.08-.07-.077-.118a.3.3,0,0,0,0-.039.091.091,0,0,0-.032-.056c-.039-.039-.092-.072-.1-.127a.289.289,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.058.04.008.116-.03.044-.058.093-.01.141C183.849,197.289,184.011,197.164,184.078,197.111Z" transform="translate(-182.193 -193.609)" fill="#fcd07f" />
                  <path id="Path_1347" data-name="Path 1347" d="M185.278,194.971v.273a.282.282,0,0,0-.036.081c-.073-.041-.253-.144-.2-.257.026-.062.083-.055.134-.044.09.018.049.034.105-.05Z" transform="translate(-182.314 -193.439)" fill="#fcd07f" />
                  <path id="Path_1348" data-name="Path 1348" d="M182.245,197.188a.571.571,0,0,1-.213.119v-.457l0,0c.012.055.065.088.105.128a.1.1,0,0,1,.033.054.281.281,0,0,1-.005.04A.164.164,0,0,0,182.245,197.188Z" transform="translate(-182.032 -193.616)" fill="#fcd07f" />
                  <path id="Path_1349" data-name="Path 1349" d="M182.94,196.964a.165.165,0,0,1-.13.064.341.341,0,0,0-.039-.009.09.09,0,0,0-.062.025c-.045.035-.086.085-.143.09a.277.277,0,0,0,.056.1.1.1,0,0,0,.109.026c.092-.047.048-.054.12.007.042.036.089.071.143.027C183.093,197.221,182.986,197.039,182.94,196.964Z" transform="translate(-182.082 -193.627)" fill="#fcd07f" />
                  <path id="Path_1350" data-name="Path 1350" d="M184.825,198.665a.173.173,0,0,1,0,.155c-.007.011-.018.021-.025.034a.1.1,0,0,0,0,.071.322.322,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.072.059-.113.053-.024.107-.055.089-.127C185.141,198.629,184.918,198.654,184.825,198.665Z" transform="translate(-182.293 -193.786)" fill="#fcd07f" />
                  <path id="Path_1351" data-name="Path 1351" d="M182.63,200.821a.158.158,0,0,1,.025-.138.253.253,0,0,0,.029-.025.1.1,0,0,0,.015-.062.3.3,0,0,1,.011-.162.265.265,0,0,0-.112-.016.094.094,0,0,0-.082.07c-.016.1.014.069-.073.089-.052.012-.105.028-.1.1C182.343,200.794,182.546,200.814,182.63,200.821Z" transform="translate(-182.061 -193.952)" fill="#fcd07f" />
                  <path id="Path_1352" data-name="Path 1352" d="M183.749,194.19a.3.3,0,0,0,.01-.048.04.04,0,0,0-.023-.039.044.044,0,0,0-.024,0,.156.156,0,0,0-.086.027.16.16,0,0,0-.04.122c0,.072-.04.209.082.227a.4.4,0,0,0,.047,0,.09.09,0,0,1,.074.058c.006.019.006.04.014.059a.045.045,0,0,0,.044.035c.024,0,.034-.032.038-.055a.227.227,0,0,0-.024-.218C183.809,194.3,183.73,194.288,183.749,194.19Z" transform="translate(-182.178 -193.357)" fill="#fcd07f" />
                  <path id="Path_1353" data-name="Path 1353" d="M184.325,193.487a.377.377,0,0,0,.055.014.048.048,0,0,0,.047-.024.053.053,0,0,0,0-.029.181.181,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.085-.007-.244-.055-.269.085,0,.019,0,.038,0,.056a.106.106,0,0,1-.07.084c-.024.007-.048.006-.071.014s-.045.026-.043.05.035.041.062.048a.269.269,0,0,0,.257-.02C184.191,193.553,184.21,193.461,184.325,193.487Z" transform="translate(-182.199 -193.28)" fill="#fcd07f" />
                  <path id="Path_1354" data-name="Path 1354" d="M182.391,197.9a.333.333,0,0,0,.009-.051.042.042,0,0,0-.024-.042.051.051,0,0,0-.026,0,.166.166,0,0,0-.091.029.176.176,0,0,0-.041.13c0,.077-.038.223.09.24.017,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.008.02.008.043.015.063s.026.04.047.036.035-.034.041-.058a.243.243,0,0,0-.029-.232C182.457,198.022,182.372,198.008,182.391,197.9Z" transform="translate(-182.049 -193.707)" fill="#fcd07f" />
                  <path id="Path_1355" data-name="Path 1355" d="M184.743,201.189a.3.3,0,0,0,.022-.045.041.041,0,0,0-.012-.046.049.049,0,0,0-.024-.008.161.161,0,0,0-.1,0,.177.177,0,0,0-.073.114c-.023.072-.1.2.023.253.015.006.033.008.048.014a.094.094,0,0,1,.06.078c0,.022,0,.043,0,.064a.049.049,0,0,0,.036.047c.024,0,.043-.023.053-.045a.238.238,0,0,0,.034-.228C184.774,201.32,184.7,201.284,184.743,201.189Z" transform="translate(-182.266 -194.015)" fill="#fcd07f" />
                  <path id="Path_1356" data-name="Path 1356" d="M182.913,194.944a.426.426,0,0,0,.015.048.043.043,0,0,0,.041.024.053.053,0,0,0,.023-.011.164.164,0,0,0,.065-.069.166.166,0,0,0-.026-.131c-.034-.068-.071-.211-.189-.166-.016.006-.029.016-.044.024a.092.092,0,0,1-.1-.014c-.016-.014-.027-.034-.043-.047a.048.048,0,0,0-.058-.009c-.02.014-.014.045-.007.069.024.08.051.16.133.187S182.88,194.844,182.913,194.944Z" transform="translate(-182.084 -193.403)" fill="#fcd07f" />
                  <path id="Path_1357" data-name="Path 1357" d="M182.239,194.241a.16.16,0,0,1-.065.067.049.049,0,0,1-.024.012c-.015,0-.031-.01-.04-.024a.328.328,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.176.176,0,0,1,182.239,194.241Z" transform="translate(-182.032 -193.341)" fill="#fcd07f" />
                  <path id="Path_1358" data-name="Path 1358" d="M183.091,198.476a.243.243,0,0,0-.051.019.045.045,0,0,0-.024.043.059.059,0,0,0,.013.024.168.168,0,0,0,.075.065.18.18,0,0,0,.138-.033c.071-.039.219-.085.165-.208-.006-.016-.019-.03-.026-.045a.1.1,0,0,1,.011-.1c.014-.018.034-.03.047-.047a.05.05,0,0,0,.007-.062c-.017-.021-.049-.014-.074,0-.082.029-.166.062-.189.15S183.194,198.436,183.091,198.476Z" transform="translate(-182.125 -193.736)" fill="#fcd07f" />
                  <path id="Path_1359" data-name="Path 1359" d="M182.264,199.593a.171.171,0,0,1-.077.065.057.057,0,0,1-.024.009.039.039,0,0,1-.039-.031.239.239,0,0,1-.013-.052.108.108,0,0,0-.079-.09v-.223a.176.176,0,0,1,.038-.015c.129-.033.152.12.18.2A.175.175,0,0,1,182.264,199.593Z" transform="translate(-182.032 -193.843)" fill="#fcd07f" />
                  <path id="Path_1360" data-name="Path 1360" d="M184.747,197.664a.121.121,0,0,0,.025.016c.085.047.132-.072.141-.137a.239.239,0,0,0-.067-.209c-.062-.057-.248-.088-.253.024a.088.088,0,0,0,.013.061.371.371,0,0,0,.029.024c.029.026.029.071.038.109A.207.207,0,0,0,184.747,197.664Z" transform="translate(-182.273 -193.657)" fill="#fcd07f" />
                  <path id="Path_1361" data-name="Path 1361" d="M184.265,199.876a.148.148,0,0,0,0,.034c0,.111.144.091.213.065a.272.272,0,0,0,.17-.178c.023-.092-.048-.293-.16-.237a.1.1,0,0,0-.053.045.282.282,0,0,0-.008.042c-.01.043-.054.067-.087.1A.23.23,0,0,0,184.265,199.876Z" transform="translate(-182.242 -193.871)" fill="#fcd07f" />
                  <path id="Path_1362" data-name="Path 1362" d="M184.628,194.126l-.026.021c-.082.075.031.168.1.2a.27.27,0,0,0,.248,0c.083-.046.181-.235.064-.279a.1.1,0,0,0-.069-.008.3.3,0,0,0-.036.023c-.038.023-.086.006-.13,0A.229.229,0,0,0,184.628,194.126Z" transform="translate(-182.272 -193.353)" fill="#fcd07f" />
                  <path id="Path_1363" data-name="Path 1363" d="M184.339,195.22a.187.187,0,0,0,0,.032c0,.1.135.081.2.055a.247.247,0,0,0,.152-.17c.019-.085-.051-.267-.153-.214a.089.089,0,0,0-.048.043.286.286,0,0,0-.006.039c-.007.04-.048.063-.077.091A.211.211,0,0,0,184.339,195.22Z" transform="translate(-182.249 -193.434)" fill="#fcd07f" />
                  <path id="Path_1364" data-name="Path 1364" d="M183.965,198.412l-.016.024c-.045.083.071.128.133.137a.231.231,0,0,0,.2-.064c.055-.06.084-.239-.023-.244a.085.085,0,0,0-.058.012.218.218,0,0,0-.023.028c-.025.028-.069.028-.106.037A.2.2,0,0,0,183.965,198.412Z" transform="translate(-182.212 -193.75)" fill="#fcd07f" />
                  <path id="Path_1365" data-name="Path 1365" d="M184.66,196.312a.188.188,0,0,0,.012.028c.044.09.152.015.2-.033a.247.247,0,0,0,.065-.214c-.019-.083-.157-.216-.225-.126a.087.087,0,0,0-.025.058c0,.013.008.024.011.036.01.039-.016.076-.031.113A.208.208,0,0,0,184.66,196.312Z" transform="translate(-182.279 -193.53)" fill="#fcd07f" />
                  <path id="Path_1366" data-name="Path 1366" d="M182.9,202.306c.065.055.154.12.157-.025,0-.08-.1-.169-.177-.18-.034-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.136.136,0,0,1,.057.014Z" transform="translate(-182.091 -194.097)" fill="#fcd07f" />
                  <path id="Path_1367" data-name="Path 1367" d="M185.272,196.649v.168c-.024-.015-.058-.005-.09,0a.226.226,0,0,1-.211-.139c-.027-.142.072-.1.148-.058l.057.029a.125.125,0,0,0,.058,0A.135.135,0,0,1,185.272,196.649Z" transform="translate(-182.309 -193.591)" fill="#fcd07f" />
                  <path id="Path_1368" data-name="Path 1368" d="M182.87,201.513c-.037.082-.078.187.069.15a.236.236,0,0,0,.133-.227c0-.036-.021-.079,0-.106s.1-.008.119-.05a.171.171,0,0,0-.195-.063.169.169,0,0,0-.105.176.126.126,0,0,1,0,.061C182.892,201.469,182.881,201.49,182.87,201.513Z" transform="translate(-182.108 -194.027)" fill="#fcd07f" />
                  <path id="Path_1369" data-name="Path 1369" d="M184.1,202.135c.04-.062.086-.144-.038-.13-.068.006-.133.105-.135.168,0,.03.008.067-.016.086s-.082-.005-.1.026a.135.135,0,0,0,.256-.055.113.113,0,0,1,.005-.05C184.073,202.169,184.085,202.152,184.1,202.135Z" transform="translate(-182.199 -194.102)" fill="#fcd07f" />
                  <path id="Path_1370" data-name="Path 1370" d="M182.648,193.706c0,.091,0,.207.122.116a.241.241,0,0,0,.038-.265c-.018-.033-.051-.066-.036-.1s.091-.047.092-.092a.166.166,0,0,0-.239.219.128.128,0,0,1,.024.057C182.651,193.657,182.649,193.68,182.648,193.706Z" transform="translate(-182.086 -193.285)" fill="#fcd07f" />
                  <path id="Path_1371" data-name="Path 1371" d="M183.582,200.571c-.048.07-.1.163.041.152a.227.227,0,0,0,.16-.192c0-.035-.007-.078.021-.1s.1.007.119-.028a.16.16,0,0,0-.172-.09.158.158,0,0,0-.126.147.124.124,0,0,1-.008.058C183.609,200.532,183.6,200.551,183.582,200.571Z" transform="translate(-182.173 -193.943)" fill="#fcd07f" />
                  <path id="Path_1372" data-name="Path 1372" d="M183.552,195.925c-.07-.005-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.014.053-.038.079-.026s.034.072.07.074a.13.13,0,0,0-.166-.191.106.106,0,0,1-.044.019Z" transform="translate(-182.164 -193.526)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1373" data-name="Path 1373" d="M184.565,193.421a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,184.565,193.421Z" transform="translate(-182.032 -191.958)" fill="#f1f4f4" />
            </g>
            <g id="Group_257" data-name="Group 257" transform="translate(18.654 15.494)">
              <path id="Path_1374" data-name="Path 1374" d="M188.06,203.274l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0,.989.989,0,0,0-.094.061l-.336-.247v14.445h0a.744.744,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.727.727,0,0,0,.43-.607h0Z" transform="translate(-185.096 -194.312)" fill="#dce4e4" />
              <g id="Group_256" data-name="Group 256" transform="translate(0 0.881)">
                <path id="Path_1375" data-name="Path 1375" d="M188.059,194.422v8.016a.726.726,0,0,1-.43.608,2.317,2.317,0,0,1-2.1,0,.749.749,0,0,1-.437-.605v-8.016l.336.247c.029-.021.061-.042.094-.062a2.318,2.318,0,0,1,2.1.005,1.062,1.062,0,0,1,.09.057Z" transform="translate(-185.096 -194.359)" fill="#f1a443" />
                <g id="Group_255" data-name="Group 255" transform="translate(0 0.315)">
                  <path id="Path_1376" data-name="Path 1376" d="M186.122,200.976c-.053-.032-.112-.073-.118-.135,0-.016,0-.033,0-.049a.121.121,0,0,0-.05-.064c-.057-.042-.13-.074-.153-.14a.331.331,0,0,0-.106.1.12.12,0,0,0,0,.137c.086.1.08.04.03.145-.029.06-.055.127.014.178C185.867,201.24,186.048,201.053,186.122,200.976Z" transform="translate(-185.151 -195.261)" fill="#fcd07f" />
                  <path id="Path_1377" data-name="Path 1377" d="M188.337,201.908v.253a.233.233,0,0,0-.108.1c-.073-.079-.249-.27-.149-.389.055-.065.12-.034.178,0S188.324,201.917,188.337,201.908Z" transform="translate(-185.374 -195.379)" fill="#fcd07f" />
                  <path id="Path_1378" data-name="Path 1378" d="M185.415,202.762c-.062.065-.2.209-.319.194v-.575c.027.062.094.092.149.132a.118.118,0,0,1,.05.065c0,.015,0,.033,0,.048C185.3,202.688,185.362,202.73,185.415,202.762Z" transform="translate(-185.096 -195.43)" fill="#fcd07f" />
                  <path id="Path_1379" data-name="Path 1379" d="M185.412,197.12a.209.209,0,0,1,.074.171.31.31,0,0,0-.013.05.119.119,0,0,0,.028.08c.043.06.105.115.108.189a.388.388,0,0,0,.138-.066.125.125,0,0,0,.039-.139c-.055-.121-.067-.065.016-.153.048-.052.1-.11.043-.183C185.751,196.937,185.512,197.064,185.412,197.12Z" transform="translate(-185.126 -194.924)" fill="#fcd07f" />
                  <path id="Path_1380" data-name="Path 1380" d="M187.142,198.538c-.038-.031-.08-.069-.077-.118,0-.013,0-.025,0-.039a.093.093,0,0,0-.032-.055c-.04-.039-.092-.072-.1-.127a.265.265,0,0,0-.093.066.094.094,0,0,0-.012.108c.056.084.058.041.007.116-.029.044-.057.094-.009.141C186.913,198.716,187.075,198.591,187.142,198.538Z" transform="translate(-185.257 -195.036)" fill="#fcd07f" />
                  <path id="Path_1381" data-name="Path 1381" d="M188.341,196.4v.274a.259.259,0,0,0-.035.081c-.074-.042-.253-.145-.2-.258.026-.062.082-.054.134-.044.09.019.048.034.105-.05Z" transform="translate(-185.378 -194.867)" fill="#fcd07f" />
                  <path id="Path_1382" data-name="Path 1382" d="M185.309,198.615a.547.547,0,0,1-.213.119v-.457l0,0c.013.055.065.088.105.127a.1.1,0,0,1,.033.055.287.287,0,0,1-.005.039C185.229,198.544,185.271,198.584,185.309,198.615Z" transform="translate(-185.096 -195.043)" fill="#fcd07f" />
                  <path id="Path_1383" data-name="Path 1383" d="M186,198.392c-.037.034-.082.072-.131.063a.27.27,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.044.035-.085.085-.143.091a.314.314,0,0,0,.056.1.1.1,0,0,0,.109.025c.093-.047.049-.054.12.008.042.035.088.071.143.027C186.157,198.649,186.049,198.467,186,198.392Z" transform="translate(-185.146 -195.055)" fill="#fcd07f" />
                  <path id="Path_1384" data-name="Path 1384" d="M187.889,200.093a.174.174,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.07.315.315,0,0,1,.025.178.294.294,0,0,0,.126-.009.1.1,0,0,0,.072-.093c-.005-.111-.031-.071.06-.113.053-.024.107-.054.089-.127C188.2,200.057,187.981,200.081,187.889,200.093Z" transform="translate(-185.357 -195.214)" fill="#fcd07f" />
                  <path id="Path_1385" data-name="Path 1385" d="M185.694,202.249a.158.158,0,0,1,.024-.138.266.266,0,0,0,.029-.025.088.088,0,0,0,.015-.062.29.29,0,0,1,.012-.163.27.27,0,0,0-.113-.015.093.093,0,0,0-.082.069c-.016.1.014.069-.074.09-.051.012-.105.028-.1.1C185.407,202.221,185.609,202.242,185.694,202.249Z" transform="translate(-185.125 -195.38)" fill="#fcd07f" />
                  <path id="Path_1386" data-name="Path 1386" d="M186.813,195.618a.3.3,0,0,0,.009-.048.039.039,0,0,0-.022-.04.056.056,0,0,0-.024,0,.151.151,0,0,0-.087.026.169.169,0,0,0-.04.123c0,.072-.039.209.082.227.016,0,.032,0,.048,0a.088.088,0,0,1,.073.057c.007.019.007.041.014.06s.024.037.044.034.034-.031.039-.054a.228.228,0,0,0-.024-.219C186.873,195.73,186.794,195.715,186.813,195.618Z" transform="translate(-185.242 -194.785)" fill="#fcd07f" />
                  <path id="Path_1387" data-name="Path 1387" d="M187.389,194.915a.377.377,0,0,0,.055.013.046.046,0,0,0,.047-.024.06.06,0,0,0,0-.028.184.184,0,0,0-.028-.1.192.192,0,0,0-.141-.052c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.056a.1.1,0,0,1-.071.083c-.023.007-.047.006-.07.014s-.045.026-.043.051.035.04.063.047a.265.265,0,0,0,.256-.02C187.255,194.981,187.274,194.888,187.389,194.915Z" transform="translate(-185.263 -194.707)" fill="#fcd07f" />
                  <path id="Path_1388" data-name="Path 1388" d="M185.455,199.33a.312.312,0,0,0,.009-.051.043.043,0,0,0-.024-.042.06.06,0,0,0-.025,0,.16.16,0,0,0-.091.03.171.171,0,0,0-.041.13c0,.077-.039.222.09.24.016,0,.034,0,.051,0a.1.1,0,0,1,.079.06c.007.02.008.043.015.063a.047.047,0,0,0,.047.036c.025,0,.035-.034.04-.058a.241.241,0,0,0-.029-.232C185.52,199.449,185.436,199.435,185.455,199.33Z" transform="translate(-185.113 -195.134)" fill="#fcd07f" />
                  <path id="Path_1389" data-name="Path 1389" d="M187.807,202.617a.242.242,0,0,0,.023-.046.043.043,0,0,0-.012-.046.06.06,0,0,0-.024-.008.169.169,0,0,0-.1,0c-.043.019-.059.069-.073.113-.024.073-.1.2.022.253a.429.429,0,0,0,.049.014.1.1,0,0,1,.059.079c0,.021,0,.043,0,.063s.014.045.035.047.043-.023.054-.045a.238.238,0,0,0,.034-.228C187.837,202.747,187.761,202.712,187.807,202.617Z" transform="translate(-185.33 -195.443)" fill="#fcd07f" />
                  <path id="Path_1390" data-name="Path 1390" d="M185.977,196.371a.213.213,0,0,0,.015.048.042.042,0,0,0,.041.025.061.061,0,0,0,.023-.012.162.162,0,0,0,.065-.068.175.175,0,0,0-.026-.132c-.034-.068-.071-.21-.19-.165-.015.005-.028.016-.043.023a.092.092,0,0,1-.1-.014c-.015-.014-.026-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.046-.007.07.024.079.051.159.134.187S185.943,196.271,185.977,196.371Z" transform="translate(-185.148 -194.83)" fill="#fcd07f" />
                  <path id="Path_1391" data-name="Path 1391" d="M185.3,195.669a.16.16,0,0,1-.065.067.077.077,0,0,1-.024.012c-.015,0-.031-.011-.039-.024a.2.2,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.174.174,0,0,1,185.3,195.669Z" transform="translate(-185.096 -194.769)" fill="#fcd07f" />
                  <path id="Path_1392" data-name="Path 1392" d="M186.154,199.9a.333.333,0,0,0-.05.019.044.044,0,0,0-.024.043.065.065,0,0,0,.014.024.169.169,0,0,0,.075.065.181.181,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.007-.016-.019-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.016-.02-.049-.013-.073,0-.082.029-.166.062-.19.15S186.257,199.864,186.154,199.9Z" transform="translate(-185.189 -195.163)" fill="#fcd07f" />
                  <path id="Path_1393" data-name="Path 1393" d="M185.328,201.02a.178.178,0,0,1-.078.065.052.052,0,0,1-.024.008.041.041,0,0,1-.04-.031.305.305,0,0,1-.012-.052.108.108,0,0,0-.079-.089V200.7a.349.349,0,0,1,.037-.015c.13-.033.153.12.18.2A.181.181,0,0,1,185.328,201.02Z" transform="translate(-185.096 -195.27)" fill="#fcd07f" />
                  <path id="Path_1394" data-name="Path 1394" d="M187.81,199.091l.025.017c.086.047.132-.073.141-.138a.237.237,0,0,0-.067-.209c-.062-.057-.247-.087-.252.024a.091.091,0,0,0,.012.061.19.19,0,0,0,.03.024c.029.026.029.072.038.11A.2.2,0,0,0,187.81,199.091Z" transform="translate(-185.337 -195.085)" fill="#fcd07f" />
                  <path id="Path_1395" data-name="Path 1395" d="M187.328,201.3a.21.21,0,0,0,0,.034c0,.11.143.091.212.066a.269.269,0,0,0,.17-.179c.024-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.044.339.339,0,0,0-.007.042c-.01.043-.054.067-.087.1A.226.226,0,0,0,187.328,201.3Z" transform="translate(-185.306 -195.298)" fill="#fcd07f" />
                  <path id="Path_1396" data-name="Path 1396" d="M187.692,195.553a.218.218,0,0,0-.027.021c-.081.075.032.168.1.2a.27.27,0,0,0,.248,0c.082-.045.181-.234.064-.278a.1.1,0,0,0-.07-.008.212.212,0,0,0-.035.023c-.038.022-.086.005-.13,0A.227.227,0,0,0,187.692,195.553Z" transform="translate(-185.335 -194.781)" fill="#fcd07f" />
                  <path id="Path_1397" data-name="Path 1397" d="M187.4,196.648a.118.118,0,0,0,0,.031c0,.1.134.081.2.055a.251.251,0,0,0,.153-.169c.018-.085-.052-.268-.154-.215a.092.092,0,0,0-.047.043.256.256,0,0,0-.006.038c-.008.04-.048.063-.078.091A.207.207,0,0,0,187.4,196.648Z" transform="translate(-185.313 -194.861)" fill="#fcd07f" />
                  <path id="Path_1398" data-name="Path 1398" d="M187.029,199.84a.114.114,0,0,0-.016.024c-.046.082.071.128.132.137a.233.233,0,0,0,.2-.065c.054-.059.084-.238-.023-.243a.078.078,0,0,0-.059.012.326.326,0,0,0-.023.027c-.025.029-.068.029-.105.037A.194.194,0,0,0,187.029,199.84Z" transform="translate(-185.276 -195.177)" fill="#fcd07f" />
                  <path id="Path_1399" data-name="Path 1399" d="M187.724,197.739a.114.114,0,0,0,.012.029c.043.089.152.015.2-.034a.242.242,0,0,0,.064-.213c-.018-.083-.156-.216-.225-.127a.092.092,0,0,0-.024.058.167.167,0,0,0,.011.036c.009.039-.017.076-.032.113A.208.208,0,0,0,187.724,197.739Z" transform="translate(-185.343 -194.958)" fill="#fcd07f" />
                  <path id="Path_1400" data-name="Path 1400" d="M185.963,203.734c.065.055.153.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.018-.1-.015-.122a.158.158,0,0,0,.026.306.12.12,0,0,1,.057.014A.564.564,0,0,1,185.963,203.734Z" transform="translate(-185.155 -195.524)" fill="#fcd07f" />
                  <path id="Path_1401" data-name="Path 1401" d="M188.335,198.076v.168c-.024-.015-.058-.005-.089,0a.226.226,0,0,1-.211-.139c-.028-.143.072-.1.148-.059.022.013.043.023.056.029a.129.129,0,0,0,.059,0A.176.176,0,0,1,188.335,198.076Z" transform="translate(-185.373 -195.018)" fill="#fcd07f" />
                  <path id="Path_1402" data-name="Path 1402" d="M185.934,202.941c-.037.081-.079.187.069.15a.237.237,0,0,0,.133-.227c0-.037-.022-.08,0-.106s.1-.009.119-.05a.167.167,0,0,0-.194-.063.17.17,0,0,0-.106.175.126.126,0,0,1,0,.061C185.955,202.9,185.945,202.918,185.934,202.941Z" transform="translate(-185.172 -195.454)" fill="#fcd07f" />
                  <path id="Path_1403" data-name="Path 1403" d="M187.16,203.562c.04-.062.086-.143-.038-.13a.194.194,0,0,0-.135.169c0,.03.008.067-.016.085s-.083,0-.1.026a.139.139,0,0,0,.15.075.14.14,0,0,0,.107-.13.1.1,0,0,1,.005-.05Z" transform="translate(-185.263 -195.529)" fill="#fcd07f" />
                  <path id="Path_1404" data-name="Path 1404" d="M185.712,195.134c0,.091,0,.207.122.115a.24.24,0,0,0,.039-.264c-.018-.033-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.24.22.126.126,0,0,1,.025.056A.641.641,0,0,1,185.712,195.134Z" transform="translate(-185.15 -194.713)" fill="#fcd07f" />
                  <path id="Path_1405" data-name="Path 1405" d="M186.645,202c-.047.071-.1.164.041.152.08-.006.157-.119.161-.192,0-.034-.008-.078.02-.1s.1.008.119-.027a.156.156,0,0,0-.3.057.134.134,0,0,1-.007.057C186.672,201.96,186.659,201.978,186.645,202Z" transform="translate(-185.237 -195.37)" fill="#fcd07f" />
                  <path id="Path_1406" data-name="Path 1406" d="M186.616,197.353c-.071-.005-.16-.006-.092.092a.189.189,0,0,0,.2.035c.026-.014.053-.038.08-.026s.034.072.069.073a.129.129,0,0,0-.165-.19.1.1,0,0,1-.044.018Z" transform="translate(-185.228 -194.954)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1407" data-name="Path 1407" d="M187.629,194.849a2.319,2.319,0,0,1-2.1,0c-.581-.336-.583-.878-.007-1.21a2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,187.629,194.849Z" transform="translate(-185.096 -193.386)" fill="#f1f4f4" />
            </g>
            <g id="Group_260" data-name="Group 260" transform="translate(10.854 14.364)">
              <path id="Path_1408" data-name="Path 1408" d="M179.449,202.026l-.348.246c-.029-.019-.058-.038-.09-.057a2.319,2.319,0,0,0-2.1,0c-.034.02-.065.04-.1.061l-.336-.246v14.445h0a.744.744,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.73.73,0,0,0,.431-.607h0Z" transform="translate(-176.484 -193.064)" fill="#dce4e4" />
              <g id="Group_259" data-name="Group 259" transform="translate(0 0.882)">
                <path id="Path_1409" data-name="Path 1409" d="M179.448,193.174v8.017a.727.727,0,0,1-.431.608,2.312,2.312,0,0,1-2.094,0,.749.749,0,0,1-.438-.6v-8.017l.337.247c.029-.021.06-.041.094-.062a2.312,2.312,0,0,1,2.1.005.912.912,0,0,1,.089.056Z" transform="translate(-176.484 -193.111)" fill="#f1a443" />
                <g id="Group_258" data-name="Group 258" transform="translate(0 0.316)">
                  <path id="Path_1410" data-name="Path 1410" d="M177.511,199.728c-.053-.032-.112-.072-.118-.134a.433.433,0,0,0,0-.049.117.117,0,0,0-.05-.065c-.056-.042-.129-.074-.153-.14a.348.348,0,0,0-.105.1.117.117,0,0,0,0,.137c.086.1.08.041.03.145-.029.061-.055.128.014.178C177.256,199.993,177.436,199.806,177.511,199.728Z" transform="translate(-176.539 -194.014)" fill="#fcd07f" />
                  <path id="Path_1411" data-name="Path 1411" d="M179.726,200.661v.252a.242.242,0,0,0-.108.1c-.074-.079-.249-.269-.149-.389.055-.065.12-.034.178,0S179.713,200.669,179.726,200.661Z" transform="translate(-176.762 -194.132)" fill="#fcd07f" />
                  <path id="Path_1412" data-name="Path 1412" d="M176.8,201.514c-.062.065-.2.209-.32.194v-.575c.028.062.1.093.149.132a.118.118,0,0,1,.05.065.428.428,0,0,1,0,.048C176.692,201.441,176.75,201.482,176.8,201.514Z" transform="translate(-176.484 -194.183)" fill="#fcd07f" />
                  <path id="Path_1413" data-name="Path 1413" d="M176.8,195.872a.212.212,0,0,1,.074.171.512.512,0,0,0-.014.05.124.124,0,0,0,.029.081c.043.06.105.115.108.188a.355.355,0,0,0,.137-.066.126.126,0,0,0,.04-.138c-.056-.122-.067-.066.015-.154.049-.051.1-.11.043-.182C177.14,195.69,176.9,195.817,176.8,195.872Z" transform="translate(-176.514 -193.677)" fill="#fcd07f" />
                  <path id="Path_1414" data-name="Path 1414" d="M178.53,197.291c-.037-.031-.079-.07-.077-.118a.3.3,0,0,0,0-.039.087.087,0,0,0-.032-.056c-.039-.039-.092-.072-.1-.127a.28.28,0,0,0-.094.067.1.1,0,0,0-.012.108c.057.083.059.04.008.116-.03.044-.058.093-.009.141C178.3,197.469,178.464,197.343,178.53,197.291Z" transform="translate(-176.645 -193.789)" fill="#fcd07f" />
                  <path id="Path_1415" data-name="Path 1415" d="M179.73,195.151v.273a.282.282,0,0,0-.036.081c-.073-.041-.252-.144-.2-.257.025-.062.082-.055.134-.044.089.018.048.034.1-.05Z" transform="translate(-176.767 -193.619)" fill="#fcd07f" />
                  <path id="Path_1416" data-name="Path 1416" d="M176.7,197.368a.546.546,0,0,1-.213.118v-.456l0,0c.012.055.065.088.105.128a.1.1,0,0,1,.033.054.3.3,0,0,1-.005.04C176.618,197.3,176.66,197.336,176.7,197.368Z" transform="translate(-176.484 -193.796)" fill="#fcd07f" />
                  <path id="Path_1417" data-name="Path 1417" d="M177.392,197.144a.161.161,0,0,1-.13.063c-.014,0-.025-.007-.039-.008a.091.091,0,0,0-.062.025c-.045.035-.086.085-.143.09a.277.277,0,0,0,.056.1.1.1,0,0,0,.109.026c.093-.047.049-.054.12.007.042.036.089.071.144.027C177.545,197.4,177.438,197.219,177.392,197.144Z" transform="translate(-176.534 -193.807)" fill="#fcd07f" />
                  <path id="Path_1418" data-name="Path 1418" d="M179.277,198.845a.176.176,0,0,1,.005.155.231.231,0,0,0-.025.034.1.1,0,0,0,0,.071.322.322,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.111-.032-.071.059-.112.053-.024.107-.055.089-.127C179.593,198.809,179.37,198.833,179.277,198.845Z" transform="translate(-176.745 -193.966)" fill="#fcd07f" />
                  <path id="Path_1419" data-name="Path 1419" d="M177.082,201a.158.158,0,0,1,.025-.138.253.253,0,0,0,.029-.025.09.09,0,0,0,.015-.062.287.287,0,0,1,.012-.162.271.271,0,0,0-.113-.016.092.092,0,0,0-.082.07c-.016.1.014.069-.074.089-.052.012-.105.028-.1.1C176.8,200.974,177,200.994,177.082,201Z" transform="translate(-176.513 -194.132)" fill="#fcd07f" />
                  <path id="Path_1420" data-name="Path 1420" d="M178.2,194.37a.306.306,0,0,0,.009-.047.042.042,0,0,0-.022-.04.048.048,0,0,0-.025,0,.12.12,0,0,0-.126.149c0,.072-.04.209.082.227a.42.42,0,0,0,.048,0,.089.089,0,0,1,.073.058c.007.019.007.04.014.059s.024.038.044.035.034-.032.039-.055a.229.229,0,0,0-.025-.218C178.262,194.482,178.182,194.468,178.2,194.37Z" transform="translate(-176.63 -193.537)" fill="#fcd07f" />
                  <path id="Path_1421" data-name="Path 1421" d="M178.777,193.667a.41.41,0,0,0,.055.014.049.049,0,0,0,.048-.024.062.062,0,0,0,0-.029.181.181,0,0,0-.027-.1.192.192,0,0,0-.141-.052c-.085-.007-.244-.055-.27.085,0,.019,0,.038,0,.056a.1.1,0,0,1-.07.084c-.023.007-.047.006-.071.013a.054.054,0,0,0-.042.051c0,.028.034.041.062.048a.269.269,0,0,0,.257-.02C178.643,193.733,178.663,193.641,178.777,193.667Z" transform="translate(-176.651 -193.46)" fill="#fcd07f" />
                  <path id="Path_1422" data-name="Path 1422" d="M176.843,198.083a.337.337,0,0,0,.01-.051.043.043,0,0,0-.024-.042.064.064,0,0,0-.026,0,.171.171,0,0,0-.091.029.175.175,0,0,0-.04.13c0,.077-.039.223.089.24.017,0,.034,0,.051,0a.093.093,0,0,1,.079.06c.008.02.008.043.015.063s.026.04.048.036.034-.034.04-.058a.243.243,0,0,0-.029-.232C176.909,198.2,176.824,198.187,176.843,198.083Z" transform="translate(-176.501 -193.887)" fill="#fcd07f" />
                  <path id="Path_1423" data-name="Path 1423" d="M179.2,201.369a.421.421,0,0,0,.023-.045.043.043,0,0,0-.013-.047.051.051,0,0,0-.024-.007.162.162,0,0,0-.1,0,.172.172,0,0,0-.072.114c-.024.072-.1.2.022.253.015.006.033.008.048.014a.093.093,0,0,1,.06.078c0,.022,0,.043,0,.064a.048.048,0,0,0,.035.047c.024,0,.043-.024.053-.045a.24.24,0,0,0,.034-.229C179.226,201.5,179.149,201.464,179.2,201.369Z" transform="translate(-176.718 -194.195)" fill="#fcd07f" />
                  <path id="Path_1424" data-name="Path 1424" d="M177.365,195.124a.334.334,0,0,0,.016.048.041.041,0,0,0,.04.024.056.056,0,0,0,.024-.011.17.17,0,0,0,.065-.069.174.174,0,0,0-.026-.131c-.034-.068-.072-.211-.19-.166-.015.005-.029.016-.044.024a.093.093,0,0,1-.1-.014c-.016-.014-.027-.034-.043-.047a.046.046,0,0,0-.058-.009c-.02.014-.014.045-.007.069.024.08.051.16.134.187S177.332,195.024,177.365,195.124Z" transform="translate(-176.536 -193.583)" fill="#fcd07f" />
                  <path id="Path_1425" data-name="Path 1425" d="M176.692,194.421a.172.172,0,0,1-.065.067.061.061,0,0,1-.024.012c-.015,0-.031-.01-.04-.024a.329.329,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.176.176,0,0,1,176.692,194.421Z" transform="translate(-176.484 -193.521)" fill="#fcd07f" />
                  <path id="Path_1426" data-name="Path 1426" d="M177.543,198.655a.218.218,0,0,0-.05.019.044.044,0,0,0-.024.043.06.06,0,0,0,.013.024.168.168,0,0,0,.075.065.18.18,0,0,0,.138-.034c.071-.039.219-.085.166-.209-.007-.015-.019-.029-.027-.044a.1.1,0,0,1,.011-.1c.014-.018.034-.03.048-.048a.05.05,0,0,0,.006-.061c-.017-.021-.049-.014-.073,0-.082.029-.167.062-.19.15S177.646,198.616,177.543,198.655Z" transform="translate(-176.577 -193.916)" fill="#fcd07f" />
                  <path id="Path_1427" data-name="Path 1427" d="M176.716,199.773a.171.171,0,0,1-.077.065.057.057,0,0,1-.024.009.039.039,0,0,1-.039-.031.239.239,0,0,1-.013-.052.106.106,0,0,0-.079-.09v-.223a.2.2,0,0,1,.038-.015c.129-.033.153.12.18.2A.175.175,0,0,1,176.716,199.773Z" transform="translate(-176.484 -194.023)" fill="#fcd07f" />
                  <path id="Path_1428" data-name="Path 1428" d="M179.2,197.844a.151.151,0,0,0,.025.016c.086.047.132-.072.141-.137a.239.239,0,0,0-.067-.209c-.062-.057-.247-.088-.253.024a.087.087,0,0,0,.013.061.37.37,0,0,0,.029.024c.029.026.029.071.038.109A.207.207,0,0,0,179.2,197.844Z" transform="translate(-176.725 -193.837)" fill="#fcd07f" />
                  <path id="Path_1429" data-name="Path 1429" d="M178.717,200.056a.222.222,0,0,0,0,.034c0,.111.143.091.212.065a.271.271,0,0,0,.17-.179c.023-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.045.2.2,0,0,0-.007.042c-.01.043-.054.067-.088.1A.23.23,0,0,0,178.717,200.056Z" transform="translate(-176.694 -194.051)" fill="#fcd07f" />
                  <path id="Path_1430" data-name="Path 1430" d="M179.081,194.306a.155.155,0,0,0-.027.021c-.082.075.031.168.1.2a.268.268,0,0,0,.247,0c.083-.046.182-.235.064-.279a.1.1,0,0,0-.069-.008.235.235,0,0,0-.035.023c-.039.023-.087.006-.131,0A.232.232,0,0,0,179.081,194.306Z" transform="translate(-176.724 -193.533)" fill="#fcd07f" />
                  <path id="Path_1431" data-name="Path 1431" d="M178.791,195.4c0,.011,0,.021,0,.032,0,.1.135.081.2.055a.25.25,0,0,0,.153-.17c.018-.085-.052-.267-.154-.214a.092.092,0,0,0-.048.043.284.284,0,0,0-.005.039c-.008.04-.049.063-.078.091A.211.211,0,0,0,178.791,195.4Z" transform="translate(-176.701 -193.614)" fill="#fcd07f" />
                  <path id="Path_1432" data-name="Path 1432" d="M178.417,198.592l-.015.024c-.046.083.07.128.132.137a.228.228,0,0,0,.2-.064c.055-.06.085-.239-.022-.244a.086.086,0,0,0-.059.012.342.342,0,0,0-.023.028c-.025.028-.069.028-.105.037A.2.2,0,0,0,178.417,198.592Z" transform="translate(-176.664 -193.93)" fill="#fcd07f" />
                  <path id="Path_1433" data-name="Path 1433" d="M179.112,196.492a.261.261,0,0,0,.012.028c.044.09.153.015.2-.033a.244.244,0,0,0,.065-.214c-.019-.083-.157-.216-.225-.126a.09.09,0,0,0-.024.058.269.269,0,0,0,.01.036c.01.039-.016.076-.031.113A.208.208,0,0,0,179.112,196.492Z" transform="translate(-176.731 -193.71)" fill="#fcd07f" />
                  <path id="Path_1434" data-name="Path 1434" d="M177.351,202.486c.066.055.154.12.157-.025,0-.08-.1-.169-.176-.18-.035-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.12.12,0,0,1,.057.014C177.316,202.455,177.332,202.47,177.351,202.486Z" transform="translate(-176.543 -194.277)" fill="#fcd07f" />
                  <path id="Path_1435" data-name="Path 1435" d="M179.724,196.829V197c-.024-.015-.058-.005-.089,0a.23.23,0,0,1-.212-.139c-.027-.142.072-.1.148-.058l.057.029a.125.125,0,0,0,.058,0A.136.136,0,0,1,179.724,196.829Z" transform="translate(-176.761 -193.771)" fill="#fcd07f" />
                  <path id="Path_1436" data-name="Path 1436" d="M177.322,201.693c-.037.082-.078.188.07.15a.237.237,0,0,0,.132-.227c0-.036-.021-.079,0-.106s.1-.008.119-.05a.171.171,0,0,0-.195-.063.169.169,0,0,0-.105.176.126.126,0,0,1,0,.061C177.344,201.649,177.333,201.67,177.322,201.693Z" transform="translate(-176.56 -194.207)" fill="#fcd07f" />
                  <path id="Path_1437" data-name="Path 1437" d="M178.549,202.315c.04-.062.085-.144-.039-.131a.2.2,0,0,0-.135.169c0,.03.008.067-.015.086s-.083-.005-.1.026a.135.135,0,0,0,.257-.055.1.1,0,0,1,.005-.05C178.526,202.349,178.537,202.332,178.549,202.315Z" transform="translate(-176.651 -194.282)" fill="#fcd07f" />
                  <path id="Path_1438" data-name="Path 1438" d="M177.1,193.886c0,.091,0,.207.122.116a.241.241,0,0,0,.038-.265c-.018-.033-.05-.066-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.239.219.14.14,0,0,1,.025.057C177.1,193.837,177.1,193.86,177.1,193.886Z" transform="translate(-176.538 -193.465)" fill="#fcd07f" />
                  <path id="Path_1439" data-name="Path 1439" d="M178.034,200.751c-.048.07-.1.163.041.152a.226.226,0,0,0,.16-.192c0-.035-.007-.078.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.124.124,0,0,1-.008.058C178.061,200.712,178.047,200.731,178.034,200.751Z" transform="translate(-176.625 -194.123)" fill="#fcd07f" />
                  <path id="Path_1440" data-name="Path 1440" d="M178,196.1c-.07-.005-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.014.053-.038.079-.026s.034.072.07.074a.133.133,0,0,0-.007-.161.132.132,0,0,0-.158-.03.11.11,0,0,1-.045.019Z" transform="translate(-176.616 -193.706)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1441" data-name="Path 1441" d="M179.017,193.6a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,179.017,193.6Z" transform="translate(-176.484 -192.138)" fill="#f1f4f4" />
            </g>
            <g id="Group_263" data-name="Group 263" transform="translate(13.548 15.708)">
              <path id="Path_1442" data-name="Path 1442" d="M182.423,203.511l-.348.246c-.028-.019-.058-.038-.09-.056a2.314,2.314,0,0,0-2.1,0,1.071,1.071,0,0,0-.1.06l-.336-.246v14.445h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.728.728,0,0,0,.43-.607h0Z" transform="translate(-179.459 -194.549)" fill="#dce4e4" />
              <g id="Group_262" data-name="Group 262" transform="translate(0 0.881)">
                <path id="Path_1443" data-name="Path 1443" d="M182.423,194.658v8.017a.73.73,0,0,1-.431.608,2.314,2.314,0,0,1-2.1,0,.748.748,0,0,1-.437-.6v-8.017l.336.247c.03-.02.061-.041.1-.062a2.31,2.31,0,0,1,2.1.005c.031.017.06.036.09.056Z" transform="translate(-179.459 -194.595)" fill="#f1a443" />
                <g id="Group_261" data-name="Group 261" transform="translate(0 0.316)">
                  <path id="Path_1444" data-name="Path 1444" d="M180.485,201.212c-.053-.032-.112-.072-.118-.134a.437.437,0,0,0,0-.049.121.121,0,0,0-.05-.064c-.057-.043-.13-.074-.153-.14a.349.349,0,0,0-.106.1.121.121,0,0,0,0,.138c.086.094.081.04.031.144-.03.061-.056.128.014.179C180.23,201.477,180.411,201.29,180.485,201.212Z" transform="translate(-179.514 -195.498)" fill="#fcd07f" />
                  <path id="Path_1445" data-name="Path 1445" d="M182.7,202.145v.253a.231.231,0,0,0-.109.1c-.073-.08-.249-.27-.149-.39.055-.065.12-.034.178,0S182.687,202.154,182.7,202.145Z" transform="translate(-179.737 -195.616)" fill="#fcd07f" />
                  <path id="Path_1446" data-name="Path 1446" d="M179.778,203c-.062.065-.2.21-.319.194v-.575c.027.062.094.093.149.132a.12.12,0,0,1,.05.066c0,.015,0,.033,0,.048C179.666,202.925,179.725,202.966,179.778,203Z" transform="translate(-179.459 -195.667)" fill="#fcd07f" />
                  <path id="Path_1447" data-name="Path 1447" d="M179.775,197.356a.209.209,0,0,1,.074.172.489.489,0,0,0-.013.049.122.122,0,0,0,.028.081c.043.06.105.115.108.189a.375.375,0,0,0,.138-.067.123.123,0,0,0,.039-.138c-.055-.122-.067-.065.016-.154.048-.051.1-.109.043-.182C180.115,197.174,179.875,197.3,179.775,197.356Z" transform="translate(-179.489 -195.161)" fill="#fcd07f" />
                  <path id="Path_1448" data-name="Path 1448" d="M181.505,198.775c-.038-.031-.08-.069-.077-.118,0-.013,0-.026,0-.039a.1.1,0,0,0-.032-.056c-.04-.039-.092-.072-.1-.126a.265.265,0,0,0-.093.066.094.094,0,0,0-.012.108c.057.083.058.04.007.116-.029.044-.057.093-.009.141C181.276,198.953,181.438,198.828,181.505,198.775Z" transform="translate(-179.62 -195.273)" fill="#fcd07f" />
                  <path id="Path_1449" data-name="Path 1449" d="M182.705,196.635v.273a.26.26,0,0,0-.036.081c-.074-.041-.253-.144-.2-.257.026-.062.082-.054.134-.044.09.019.048.034.105-.05Z" transform="translate(-179.741 -195.104)" fill="#fcd07f" />
                  <path id="Path_1450" data-name="Path 1450" d="M179.672,198.852a.547.547,0,0,1-.213.119v-.456l0,0c.012.054.065.087.1.127a.1.1,0,0,1,.033.055.287.287,0,0,1-.005.039A.167.167,0,0,0,179.672,198.852Z" transform="translate(-179.459 -195.28)" fill="#fcd07f" />
                  <path id="Path_1451" data-name="Path 1451" d="M180.367,198.628a.167.167,0,0,1-.131.064.271.271,0,0,0-.039-.009.092.092,0,0,0-.061.025c-.045.035-.086.085-.144.09a.309.309,0,0,0,.056.105.1.1,0,0,0,.109.025c.093-.047.049-.054.12.007.042.036.088.071.143.028C180.52,198.886,180.412,198.7,180.367,198.628Z" transform="translate(-179.509 -195.291)" fill="#fcd07f" />
                  <path id="Path_1452" data-name="Path 1452" d="M182.252,200.33a.171.171,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.071.322.322,0,0,1,.024.178.309.309,0,0,0,.126-.009.1.1,0,0,0,.072-.093c-.005-.111-.031-.071.06-.113.053-.024.107-.055.089-.127C182.567,200.293,182.344,200.318,182.252,200.33Z" transform="translate(-179.72 -195.451)" fill="#fcd07f" />
                  <path id="Path_1453" data-name="Path 1453" d="M180.057,202.485a.158.158,0,0,1,.024-.137.266.266,0,0,0,.029-.025.089.089,0,0,0,.015-.062.287.287,0,0,1,.012-.162.284.284,0,0,0-.112-.016.1.1,0,0,0-.082.07c-.016.1.014.069-.074.089-.051.012-.105.029-.1.1C179.77,202.458,179.972,202.478,180.057,202.485Z" transform="translate(-179.488 -195.617)" fill="#fcd07f" />
                  <path id="Path_1454" data-name="Path 1454" d="M181.176,195.854a.271.271,0,0,0,.009-.047.039.039,0,0,0-.022-.04.056.056,0,0,0-.024,0,.151.151,0,0,0-.087.026.167.167,0,0,0-.04.122c0,.073-.039.21.082.227.016,0,.033,0,.048,0a.09.09,0,0,1,.074.057c.006.019.006.04.013.06s.024.037.044.034.034-.032.039-.054a.228.228,0,0,0-.024-.219C181.236,195.967,181.157,195.952,181.176,195.854Z" transform="translate(-179.605 -195.022)" fill="#fcd07f" />
                  <path id="Path_1455" data-name="Path 1455" d="M181.752,195.151a.29.29,0,0,0,.055.014.048.048,0,0,0,.047-.024.062.062,0,0,0,0-.029.19.19,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.055a.1.1,0,0,1-.071.084c-.023.007-.047.006-.07.014s-.045.026-.043.05.035.041.063.048a.264.264,0,0,0,.256-.02C181.618,195.218,181.637,195.125,181.752,195.151Z" transform="translate(-179.626 -194.944)" fill="#fcd07f" />
                  <path id="Path_1456" data-name="Path 1456" d="M179.818,199.567a.332.332,0,0,0,.009-.051.043.043,0,0,0-.024-.042.06.06,0,0,0-.025,0,.166.166,0,0,0-.091.029.173.173,0,0,0-.041.13c0,.078-.039.223.09.24.016,0,.034,0,.051,0a.1.1,0,0,1,.079.06c.007.02.008.043.015.063s.026.04.047.036.035-.034.04-.058a.241.241,0,0,0-.029-.232C179.884,199.686,179.8,199.672,179.818,199.567Z" transform="translate(-179.476 -195.371)" fill="#fcd07f" />
                  <path id="Path_1457" data-name="Path 1457" d="M182.17,202.853a.26.26,0,0,0,.022-.045.043.043,0,0,0-.012-.046.06.06,0,0,0-.024-.008.169.169,0,0,0-.1,0,.173.173,0,0,0-.073.114c-.024.073-.1.2.022.253.015.006.033.008.049.014a.093.093,0,0,1,.059.078c0,.022,0,.043,0,.064a.048.048,0,0,0,.035.047c.025,0,.043-.023.054-.045a.238.238,0,0,0,.034-.228C182.2,202.984,182.124,202.948,182.17,202.853Z" transform="translate(-179.693 -195.68)" fill="#fcd07f" />
                  <path id="Path_1458" data-name="Path 1458" d="M180.34,196.608a.27.27,0,0,0,.015.048.04.04,0,0,0,.041.024.058.058,0,0,0,.023-.011.162.162,0,0,0,.065-.068.172.172,0,0,0-.026-.132c-.034-.068-.071-.21-.19-.166-.015.006-.028.017-.043.024a.092.092,0,0,1-.1-.014c-.016-.014-.027-.033-.043-.047a.047.047,0,0,0-.058-.009c-.019.015-.014.045-.007.069.024.08.052.16.134.187S180.307,196.508,180.34,196.608Z" transform="translate(-179.511 -195.067)" fill="#fcd07f" />
                  <path id="Path_1459" data-name="Path 1459" d="M179.666,195.905a.155.155,0,0,1-.065.067.067.067,0,0,1-.024.013.043.043,0,0,1-.04-.024.246.246,0,0,1-.015-.048.1.1,0,0,0-.063-.072V195.6c.111-.034.149.1.182.169A.176.176,0,0,1,179.666,195.905Z" transform="translate(-179.459 -195.006)" fill="#fcd07f" />
                  <path id="Path_1460" data-name="Path 1460" d="M180.517,200.14a.332.332,0,0,0-.05.019.044.044,0,0,0-.024.043.062.062,0,0,0,.014.024.171.171,0,0,0,.075.066.185.185,0,0,0,.138-.034c.07-.039.218-.085.165-.208-.007-.016-.019-.03-.026-.045a.1.1,0,0,1,.01-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.016-.02-.048-.014-.073,0-.082.029-.166.062-.19.15S180.62,200.1,180.517,200.14Z" transform="translate(-179.552 -195.4)" fill="#fcd07f" />
                  <path id="Path_1461" data-name="Path 1461" d="M179.691,201.257a.173.173,0,0,1-.078.065.066.066,0,0,1-.024.008.041.041,0,0,1-.04-.031.305.305,0,0,1-.012-.052.108.108,0,0,0-.079-.09v-.223a.228.228,0,0,1,.037-.015c.13-.033.153.12.18.2A.181.181,0,0,1,179.691,201.257Z" transform="translate(-179.459 -195.507)" fill="#fcd07f" />
                  <path id="Path_1462" data-name="Path 1462" d="M182.173,199.328a.2.2,0,0,0,.025.016c.086.048.132-.072.142-.137a.242.242,0,0,0-.067-.209c-.062-.057-.248-.087-.253.024a.092.092,0,0,0,.012.061.192.192,0,0,0,.03.024c.029.026.029.071.038.109A.2.2,0,0,0,182.173,199.328Z" transform="translate(-179.7 -195.322)" fill="#fcd07f" />
                  <path id="Path_1463" data-name="Path 1463" d="M181.691,201.54a.22.22,0,0,0,0,.034c0,.111.144.091.212.066a.267.267,0,0,0,.17-.179c.024-.091-.047-.292-.159-.237a.106.106,0,0,0-.053.045c0,.013,0,.028-.007.042-.01.043-.054.067-.087.1A.232.232,0,0,0,181.691,201.54Z" transform="translate(-179.669 -195.535)" fill="#fcd07f" />
                  <path id="Path_1464" data-name="Path 1464" d="M182.055,195.79l-.027.021c-.081.075.032.168.1.2a.273.273,0,0,0,.248,0c.082-.045.181-.234.064-.279a.1.1,0,0,0-.07-.008c-.013.005-.023.016-.035.023-.038.023-.086.006-.13,0A.227.227,0,0,0,182.055,195.79Z" transform="translate(-179.698 -195.018)" fill="#fcd07f" />
                  <path id="Path_1465" data-name="Path 1465" d="M181.766,196.884a.191.191,0,0,0,0,.032c0,.1.134.081.2.055a.25.25,0,0,0,.152-.169c.019-.085-.052-.268-.153-.215a.091.091,0,0,0-.048.043.258.258,0,0,0-.006.038c-.008.04-.048.063-.078.091A.212.212,0,0,0,181.766,196.884Z" transform="translate(-179.676 -195.098)" fill="#fcd07f" />
                  <path id="Path_1466" data-name="Path 1466" d="M181.392,200.077a.116.116,0,0,0-.016.024c-.045.082.071.127.132.136a.229.229,0,0,0,.2-.064c.054-.059.084-.238-.023-.244a.087.087,0,0,0-.059.012.337.337,0,0,0-.023.028c-.024.029-.068.028-.105.037A.194.194,0,0,0,181.392,200.077Z" transform="translate(-179.639 -195.414)" fill="#fcd07f" />
                  <path id="Path_1467" data-name="Path 1467" d="M182.087,197.976a.152.152,0,0,0,.012.028c.044.09.152.016.2-.033a.242.242,0,0,0,.064-.213c-.018-.083-.156-.216-.225-.127a.09.09,0,0,0-.024.058.274.274,0,0,0,.011.036c.009.039-.016.076-.032.113A.208.208,0,0,0,182.087,197.976Z" transform="translate(-179.706 -195.195)" fill="#fcd07f" />
                  <path id="Path_1468" data-name="Path 1468" d="M180.326,203.971c.065.055.153.12.157-.025,0-.081-.1-.169-.177-.181-.034-.005-.079,0-.1-.031s.018-.1-.015-.122a.162.162,0,0,0-.108.163.16.16,0,0,0,.134.142.132.132,0,0,1,.057.014C180.29,203.939,180.307,203.954,180.326,203.971Z" transform="translate(-179.518 -195.761)" fill="#fcd07f" />
                  <path id="Path_1469" data-name="Path 1469" d="M182.7,198.313v.168c-.024-.015-.059-.005-.09,0a.226.226,0,0,1-.211-.139c-.027-.142.072-.1.148-.058l.056.029a.129.129,0,0,0,.059,0A.138.138,0,0,1,182.7,198.313Z" transform="translate(-179.736 -195.255)" fill="#fcd07f" />
                  <path id="Path_1470" data-name="Path 1470" d="M180.3,203.177c-.037.082-.079.188.069.151a.238.238,0,0,0,.133-.227c0-.037-.022-.08,0-.106s.1-.009.119-.05a.167.167,0,0,0-.194-.063.169.169,0,0,0-.106.175.138.138,0,0,1,0,.061C180.318,203.133,180.308,203.154,180.3,203.177Z" transform="translate(-179.535 -195.691)" fill="#fcd07f" />
                  <path id="Path_1471" data-name="Path 1471" d="M181.523,203.8c.04-.062.086-.144-.038-.13a.194.194,0,0,0-.135.168c0,.031.008.068-.016.086s-.083,0-.1.026a.135.135,0,0,0,.257-.055.1.1,0,0,1,.005-.05C181.5,203.833,181.511,203.816,181.523,203.8Z" transform="translate(-179.626 -195.766)" fill="#fcd07f" />
                  <path id="Path_1472" data-name="Path 1472" d="M180.075,195.371c0,.091,0,.206.122.115a.242.242,0,0,0,.038-.264c-.018-.034-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.24.22.126.126,0,0,1,.025.056C180.078,195.321,180.076,195.345,180.075,195.371Z" transform="translate(-179.513 -194.949)" fill="#fcd07f" />
                  <path id="Path_1473" data-name="Path 1473" d="M181.009,202.235c-.048.071-.1.164.041.152.079-.006.156-.119.16-.192,0-.035-.008-.078.02-.1s.1.007.12-.028a.156.156,0,0,0-.3.058.134.134,0,0,1-.007.057C181.035,202.2,181.022,202.215,181.009,202.235Z" transform="translate(-179.6 -195.607)" fill="#fcd07f" />
                  <path id="Path_1474" data-name="Path 1474" d="M180.979,197.589c-.071,0-.16-.005-.092.093a.188.188,0,0,0,.2.034c.026-.013.053-.037.08-.026s.034.072.069.074a.129.129,0,0,0-.165-.19.105.105,0,0,1-.044.018Z" transform="translate(-179.591 -195.191)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1475" data-name="Path 1475" d="M181.992,195.085a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.317,2.317,0,0,1,2.1,0C182.566,194.211,182.569,194.752,181.992,195.085Z" transform="translate(-179.459 -193.623)" fill="#f1f4f4" />
            </g>
            <g id="Group_266" data-name="Group 266" transform="translate(16.323 17.001)">
              <path id="Path_1476" data-name="Path 1476" d="M185.487,204.938l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0c-.034.019-.065.04-.1.061l-.335-.247v14.446h0a.743.743,0,0,0,.437.6,2.319,2.319,0,0,0,2.1,0,.731.731,0,0,0,.431-.607h0Z" transform="translate(-182.522 -195.976)" fill="#dce4e4" />
              <g id="Group_265" data-name="Group 265" transform="translate(0 0.881)">
                <path id="Path_1477" data-name="Path 1477" d="M185.486,196.086V204.1a.727.727,0,0,1-.431.608,2.318,2.318,0,0,1-2.094,0,.75.75,0,0,1-.438-.6v-8.017l.337.247c.029-.021.06-.042.094-.062a2.315,2.315,0,0,1,2.1,0,.926.926,0,0,1,.089.057Z" transform="translate(-182.522 -196.023)" fill="#f1a443" />
                <g id="Group_264" data-name="Group 264" transform="translate(0 0.315)">
                  <path id="Path_1478" data-name="Path 1478" d="M183.549,202.64c-.053-.032-.112-.072-.118-.134a.458.458,0,0,0,0-.05.113.113,0,0,0-.05-.064c-.056-.042-.129-.074-.153-.14a.338.338,0,0,0-.105.1.118.118,0,0,0,0,.137c.086.1.08.04.03.145-.029.061-.055.128.014.178C183.294,202.9,183.475,202.718,183.549,202.64Z" transform="translate(-182.577 -196.926)" fill="#fcd07f" />
                  <path id="Path_1479" data-name="Path 1479" d="M185.764,203.573v.252a.238.238,0,0,0-.108.1c-.074-.079-.249-.27-.149-.389.054-.065.12-.034.178,0S185.751,203.581,185.764,203.573Z" transform="translate(-182.8 -197.043)" fill="#fcd07f" />
                  <path id="Path_1480" data-name="Path 1480" d="M182.842,204.426c-.062.065-.2.209-.32.194v-.575c.028.062.1.092.149.132a.113.113,0,0,1,.05.065.424.424,0,0,1,0,.048C182.73,204.352,182.788,204.394,182.842,204.426Z" transform="translate(-182.522 -197.095)" fill="#fcd07f" />
                  <path id="Path_1481" data-name="Path 1481" d="M182.839,198.784a.21.21,0,0,1,.074.171.509.509,0,0,0-.014.05.12.12,0,0,0,.029.08c.043.061.105.116.108.189a.356.356,0,0,0,.137-.066.126.126,0,0,0,.04-.138c-.056-.122-.067-.066.015-.154.049-.052.1-.11.044-.183C183.178,198.6,182.938,198.729,182.839,198.784Z" transform="translate(-182.552 -196.589)" fill="#fcd07f" />
                  <path id="Path_1482" data-name="Path 1482" d="M184.568,200.2c-.037-.03-.079-.069-.077-.118,0-.013,0-.025,0-.038a.093.093,0,0,0-.032-.056c-.04-.039-.093-.072-.1-.127a.28.28,0,0,0-.094.067.094.094,0,0,0-.012.107c.057.084.059.041.008.117-.03.043-.057.093-.009.141C184.34,200.381,184.5,200.255,184.568,200.2Z" transform="translate(-182.683 -196.7)" fill="#fcd07f" />
                  <path id="Path_1483" data-name="Path 1483" d="M185.768,198.062v.274a.261.261,0,0,0-.036.081c-.073-.042-.252-.144-.2-.257.025-.062.082-.055.134-.045.089.019.048.034.1-.05Z" transform="translate(-182.805 -196.531)" fill="#fcd07f" />
                  <path id="Path_1484" data-name="Path 1484" d="M182.736,200.279a.551.551,0,0,1-.214.119v-.457l0,0c.012.055.065.088.105.127a.1.1,0,0,1,.033.055.325.325,0,0,1-.005.04C182.656,200.209,182.7,200.248,182.736,200.279Z" transform="translate(-182.522 -196.708)" fill="#fcd07f" />
                  <path id="Path_1485" data-name="Path 1485" d="M183.43,200.056a.164.164,0,0,1-.13.063c-.013,0-.025-.008-.039-.009a.1.1,0,0,0-.062.025c-.045.035-.086.086-.143.091a.277.277,0,0,0,.056.1.1.1,0,0,0,.109.026c.093-.048.049-.054.12.007.041.035.088.071.143.027C183.584,200.313,183.476,200.131,183.43,200.056Z" transform="translate(-182.572 -196.719)" fill="#fcd07f" />
                  <path id="Path_1486" data-name="Path 1486" d="M185.316,201.757a.177.177,0,0,1,0,.154.3.3,0,0,0-.025.034.1.1,0,0,0,0,.07.318.318,0,0,1,.025.178.3.3,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.111-.032-.071.059-.113.053-.024.107-.054.09-.127C185.631,201.721,185.408,201.745,185.316,201.757Z" transform="translate(-182.783 -196.878)" fill="#fcd07f" />
                  <path id="Path_1487" data-name="Path 1487" d="M183.12,203.913a.158.158,0,0,1,.025-.138.383.383,0,0,0,.029-.025.09.09,0,0,0,.015-.062.29.29,0,0,1,.012-.163.286.286,0,0,0-.113-.015.093.093,0,0,0-.082.07c-.016.1.015.069-.074.089-.052.012-.105.028-.1.1C182.834,203.886,183.036,203.906,183.12,203.913Z" transform="translate(-182.551 -197.044)" fill="#fcd07f" />
                  <path id="Path_1488" data-name="Path 1488" d="M184.24,197.282a.3.3,0,0,0,.009-.048.041.041,0,0,0-.023-.04.055.055,0,0,0-.024,0,.158.158,0,0,0-.086.026.163.163,0,0,0-.04.123c0,.072-.039.209.082.227.015,0,.032,0,.048,0a.089.089,0,0,1,.073.057c.007.02.007.041.014.06a.046.046,0,0,0,.044.034c.024,0,.033-.031.039-.054a.229.229,0,0,0-.025-.219C184.3,197.394,184.22,197.38,184.24,197.282Z" transform="translate(-182.668 -196.449)" fill="#fcd07f" />
                  <path id="Path_1489" data-name="Path 1489" d="M184.815,196.579a.3.3,0,0,0,.055.013.048.048,0,0,0,.048-.024.063.063,0,0,0,0-.029.172.172,0,0,0-.027-.1.188.188,0,0,0-.141-.052c-.085-.008-.244-.055-.27.085,0,.018,0,.037,0,.056a.1.1,0,0,1-.07.083c-.023.007-.047.006-.071.014a.055.055,0,0,0-.043.051c0,.027.035.041.063.047a.269.269,0,0,0,.257-.019C184.681,196.645,184.7,196.552,184.815,196.579Z" transform="translate(-182.689 -196.371)" fill="#fcd07f" />
                  <path id="Path_1490" data-name="Path 1490" d="M182.881,200.994a.3.3,0,0,0,.01-.05.045.045,0,0,0-.024-.043.064.064,0,0,0-.026,0,.164.164,0,0,0-.091.03.174.174,0,0,0-.04.13c0,.077-.039.223.089.24.017,0,.034,0,.051,0a.1.1,0,0,1,.08.06c.007.021.007.043.014.063a.05.05,0,0,0,.048.036c.024,0,.034-.034.04-.059a.241.241,0,0,0-.029-.231C182.947,201.113,182.862,201.1,182.881,200.994Z" transform="translate(-182.539 -196.798)" fill="#fcd07f" />
                  <path id="Path_1491" data-name="Path 1491" d="M185.234,204.281a.312.312,0,0,0,.023-.046.042.042,0,0,0-.013-.046.065.065,0,0,0-.024-.008.126.126,0,0,0-.168.119c-.024.072-.1.2.022.253.015.006.033.007.048.014a.093.093,0,0,1,.06.078c0,.021,0,.043,0,.063s.014.045.035.047.043-.023.053-.044a.24.24,0,0,0,.034-.229C185.264,204.411,185.187,204.376,185.234,204.281Z" transform="translate(-182.756 -197.107)" fill="#fcd07f" />
                  <path id="Path_1492" data-name="Path 1492" d="M183.4,198.035a.311.311,0,0,0,.016.049.041.041,0,0,0,.04.024.049.049,0,0,0,.024-.012.162.162,0,0,0,.065-.068.172.172,0,0,0-.026-.131c-.034-.069-.072-.211-.19-.166-.015.005-.029.016-.044.024a.1.1,0,0,1-.1-.014c-.016-.014-.026-.034-.043-.048a.049.049,0,0,0-.058-.009c-.02.015-.014.046-.007.07.024.079.051.16.134.187S183.37,197.935,183.4,198.035Z" transform="translate(-182.574 -196.494)" fill="#fcd07f" />
                  <path id="Path_1493" data-name="Path 1493" d="M182.73,197.333a.166.166,0,0,1-.065.067.076.076,0,0,1-.024.012c-.015,0-.031-.01-.039-.024a.282.282,0,0,1-.016-.049.1.1,0,0,0-.063-.072v-.237c.112-.034.149.1.182.17A.175.175,0,0,1,182.73,197.333Z" transform="translate(-182.522 -196.433)" fill="#fcd07f" />
                  <path id="Path_1494" data-name="Path 1494" d="M183.581,201.567a.3.3,0,0,0-.05.019.045.045,0,0,0-.024.044.067.067,0,0,0,.013.024.131.131,0,0,0,.213.031c.071-.039.219-.085.166-.208-.007-.016-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.017-.02-.049-.013-.073,0-.082.029-.167.062-.19.15S183.684,201.528,183.581,201.567Z" transform="translate(-182.615 -196.827)" fill="#fcd07f" />
                  <path id="Path_1495" data-name="Path 1495" d="M182.754,202.684a.171.171,0,0,1-.077.065.056.056,0,0,1-.024.008.04.04,0,0,1-.04-.031.2.2,0,0,1-.012-.052.108.108,0,0,0-.08-.089v-.224c.013,0,.024-.012.038-.015.129-.033.153.12.18.2A.177.177,0,0,1,182.754,202.684Z" transform="translate(-182.522 -196.934)" fill="#fcd07f" />
                  <path id="Path_1496" data-name="Path 1496" d="M185.237,200.755l.025.017c.086.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.056-.247-.087-.253.024a.084.084,0,0,0,.013.06.236.236,0,0,0,.029.024c.03.026.029.072.038.11A.2.2,0,0,0,185.237,200.755Z" transform="translate(-182.763 -196.749)" fill="#fcd07f" />
                  <path id="Path_1497" data-name="Path 1497" d="M184.755,202.968a.208.208,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.023-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.045.2.2,0,0,0-.007.041c-.01.043-.054.067-.088.1A.23.23,0,0,0,184.755,202.968Z" transform="translate(-182.732 -196.962)" fill="#fcd07f" />
                  <path id="Path_1498" data-name="Path 1498" d="M185.119,197.217a.159.159,0,0,0-.027.022c-.082.075.031.168.1.2a.271.271,0,0,0,.247,0c.083-.046.182-.235.064-.279a.092.092,0,0,0-.069-.008.181.181,0,0,0-.035.023.22.22,0,0,1-.13,0A.233.233,0,0,0,185.119,197.217Z" transform="translate(-182.762 -196.445)" fill="#fcd07f" />
                  <path id="Path_1499" data-name="Path 1499" d="M184.829,198.312c0,.01,0,.021,0,.032,0,.1.135.08.2.054a.249.249,0,0,0,.153-.169c.018-.085-.052-.268-.154-.214a.092.092,0,0,0-.048.043.276.276,0,0,0-.005.038c-.008.041-.049.063-.078.091A.213.213,0,0,0,184.829,198.312Z" transform="translate(-182.739 -196.525)" fill="#fcd07f" />
                  <path id="Path_1500" data-name="Path 1500" d="M184.455,201.5l-.015.024c-.046.082.07.128.132.137a.235.235,0,0,0,.2-.064c.054-.06.084-.239-.024-.244a.085.085,0,0,0-.058.012.237.237,0,0,0-.023.028c-.025.028-.069.028-.105.036A.2.2,0,0,0,184.455,201.5Z" transform="translate(-182.702 -196.841)" fill="#fcd07f" />
                  <path id="Path_1501" data-name="Path 1501" d="M185.15,199.4a.271.271,0,0,0,.012.029c.044.089.153.015.2-.033a.245.245,0,0,0,.065-.214c-.019-.083-.157-.216-.225-.126a.088.088,0,0,0-.024.057.28.28,0,0,0,.01.037c.01.038-.016.076-.031.113A.206.206,0,0,0,185.15,199.4Z" transform="translate(-182.769 -196.622)" fill="#fcd07f" />
                  <path id="Path_1502" data-name="Path 1502" d="M183.389,205.4c.066.055.154.12.157-.025,0-.081-.1-.169-.176-.181-.035,0-.079,0-.1-.031s.017-.1-.015-.122a.158.158,0,0,0,.026.306.12.12,0,0,1,.057.014C183.354,205.367,183.37,205.381,183.389,205.4Z" transform="translate(-182.581 -197.188)" fill="#fcd07f" />
                  <path id="Path_1503" data-name="Path 1503" d="M185.762,199.741v.168c-.024-.015-.058,0-.089,0a.228.228,0,0,1-.212-.139c-.027-.142.072-.1.148-.058l.057.029a.139.139,0,0,0,.058,0A.136.136,0,0,1,185.762,199.741Z" transform="translate(-182.799 -196.682)" fill="#fcd07f" />
                  <path id="Path_1504" data-name="Path 1504" d="M183.361,204.605c-.038.081-.079.187.069.15a.238.238,0,0,0,.132-.227c0-.036-.021-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.129.129,0,0,1,0,.062C183.382,204.56,183.371,204.582,183.361,204.605Z" transform="translate(-182.598 -197.119)" fill="#fcd07f" />
                  <path id="Path_1505" data-name="Path 1505" d="M184.587,205.226c.04-.062.086-.143-.039-.13a.2.2,0,0,0-.135.169c0,.03.008.067-.015.085s-.083,0-.1.026a.135.135,0,0,0,.257-.054.1.1,0,0,1,.005-.05C184.564,205.261,184.575,205.244,184.587,205.226Z" transform="translate(-182.689 -197.194)" fill="#fcd07f" />
                  <path id="Path_1506" data-name="Path 1506" d="M183.139,196.8c0,.091,0,.206.122.115a.24.24,0,0,0,.038-.264c-.017-.033-.05-.066-.036-.1s.091-.046.092-.091a.167.167,0,0,0-.239.219.135.135,0,0,1,.025.057C183.141,196.748,183.139,196.772,183.139,196.8Z" transform="translate(-182.576 -196.377)" fill="#fcd07f" />
                  <path id="Path_1507" data-name="Path 1507" d="M184.072,203.663c-.048.07-.1.163.041.151.08-.006.157-.119.16-.192,0-.034-.007-.077.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.118.118,0,0,1-.008.057C184.1,203.624,184.085,203.642,184.072,203.663Z" transform="translate(-182.663 -197.034)" fill="#fcd07f" />
                  <path id="Path_1508" data-name="Path 1508" d="M184.042,199.017c-.07-.005-.16-.006-.091.092a.188.188,0,0,0,.2.035c.025-.014.053-.038.079-.026s.034.072.07.073a.129.129,0,0,0-.165-.19.109.109,0,0,1-.045.018Z" transform="translate(-182.654 -196.618)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1509" data-name="Path 1509" d="M185.055,196.513a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,185.055,196.513Z" transform="translate(-182.522 -195.05)" fill="#f1f4f4" />
            </g>
            <g id="Group_269" data-name="Group 269" transform="translate(8.523 15.871)">
              <path id="Path_1510" data-name="Path 1510" d="M176.876,203.691l-.349.246c-.028-.019-.058-.038-.089-.056a2.316,2.316,0,0,0-2.1,0q-.05.029-.1.06l-.336-.246v14.445h0a.746.746,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.726.726,0,0,0,.43-.607h0Z" transform="translate(-173.911 -194.728)" fill="#dce4e4" />
              <g id="Group_268" data-name="Group 268" transform="translate(0 0.882)">
                <path id="Path_1511" data-name="Path 1511" d="M176.875,194.838v8.017a.728.728,0,0,1-.431.608,2.314,2.314,0,0,1-2.1,0,.749.749,0,0,1-.437-.6v-8.017l.337.247c.029-.02.06-.041.094-.062a2.31,2.31,0,0,1,2.1.005c.031.017.061.036.09.056Z" transform="translate(-173.911 -194.775)" fill="#f1a443" />
                <g id="Group_267" data-name="Group 267" transform="translate(0 0.316)">
                  <path id="Path_1512" data-name="Path 1512" d="M174.937,201.392c-.053-.032-.112-.072-.118-.134a.3.3,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.057-.043-.129-.074-.153-.141a.374.374,0,0,0-.106.1.121.121,0,0,0,0,.138c.087.094.081.04.031.144-.029.061-.055.128.014.179C174.682,201.657,174.863,201.47,174.937,201.392Z" transform="translate(-173.966 -195.678)" fill="#fcd07f" />
                  <path id="Path_1513" data-name="Path 1513" d="M177.153,202.325v.253a.239.239,0,0,0-.109.1c-.073-.08-.248-.27-.149-.39.054-.065.12-.034.178,0S177.14,202.333,177.153,202.325Z" transform="translate(-174.189 -195.796)" fill="#fcd07f" />
                  <path id="Path_1514" data-name="Path 1514" d="M174.231,203.178c-.062.065-.2.209-.32.194V202.8c.027.063.094.093.149.132a.118.118,0,0,1,.05.065.441.441,0,0,1,0,.049C174.118,203.1,174.177,203.146,174.231,203.178Z" transform="translate(-173.911 -195.847)" fill="#fcd07f" />
                  <path id="Path_1515" data-name="Path 1515" d="M174.228,197.536a.215.215,0,0,1,.074.172.328.328,0,0,0-.014.049.123.123,0,0,0,.029.081c.043.06.1.115.108.188a.363.363,0,0,0,.137-.066.124.124,0,0,0,.039-.138c-.055-.122-.067-.065.016-.154.049-.051.1-.109.043-.182C174.567,197.354,174.327,197.481,174.228,197.536Z" transform="translate(-173.941 -195.341)" fill="#fcd07f" />
                  <path id="Path_1516" data-name="Path 1516" d="M175.957,198.955c-.038-.031-.08-.069-.077-.118a.3.3,0,0,0,0-.039.1.1,0,0,0-.032-.056c-.04-.039-.092-.072-.1-.127a.289.289,0,0,0-.093.067.1.1,0,0,0-.012.108c.057.083.058.04.007.116-.029.044-.057.093-.009.141C175.729,199.133,175.89,199.008,175.957,198.955Z" transform="translate(-174.072 -195.453)" fill="#fcd07f" />
                  <path id="Path_1517" data-name="Path 1517" d="M177.157,196.815v.273a.26.26,0,0,0-.036.081c-.073-.041-.253-.144-.2-.257.025-.062.082-.055.133-.044.09.019.048.034.105-.05Z" transform="translate(-174.194 -195.283)" fill="#fcd07f" />
                  <path id="Path_1518" data-name="Path 1518" d="M174.124,199.032a.559.559,0,0,1-.213.119v-.457l0,0c.012.055.065.088.1.128a.093.093,0,0,1,.033.055.287.287,0,0,1,0,.039A.164.164,0,0,0,174.124,199.032Z" transform="translate(-173.911 -195.46)" fill="#fcd07f" />
                  <path id="Path_1519" data-name="Path 1519" d="M174.819,198.808a.166.166,0,0,1-.13.064.317.317,0,0,0-.04-.009.091.091,0,0,0-.061.025c-.045.035-.086.085-.143.09a.27.27,0,0,0,.056.1.1.1,0,0,0,.108.026c.093-.047.049-.054.12.007.042.036.089.071.143.028C174.972,199.065,174.865,198.884,174.819,198.808Z" transform="translate(-173.961 -195.471)" fill="#fcd07f" />
                  <path id="Path_1520" data-name="Path 1520" d="M176.7,200.51a.171.171,0,0,1,0,.154c-.007.012-.018.021-.025.034a.1.1,0,0,0,0,.071.314.314,0,0,1,.024.178.309.309,0,0,0,.126-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.055.089-.127C177.02,200.473,176.8,200.5,176.7,200.51Z" transform="translate(-174.172 -195.631)" fill="#fcd07f" />
                  <path id="Path_1521" data-name="Path 1521" d="M174.509,202.665a.155.155,0,0,1,.025-.137c.009-.009.021-.016.029-.025a.1.1,0,0,0,.015-.062.3.3,0,0,1,.011-.162.265.265,0,0,0-.112-.016.094.094,0,0,0-.082.07c-.016.1.014.069-.073.089-.052.012-.106.029-.1.1C174.222,202.638,174.424,202.658,174.509,202.665Z" transform="translate(-173.94 -195.797)" fill="#fcd07f" />
                  <path id="Path_1522" data-name="Path 1522" d="M175.628,196.034a.272.272,0,0,0,.01-.047.041.041,0,0,0-.023-.04.072.072,0,0,0-.024,0,.159.159,0,0,0-.087.027.163.163,0,0,0-.039.122c0,.073-.04.21.081.227.016,0,.033,0,.048,0a.09.09,0,0,1,.074.057c.006.019.006.04.014.059a.045.045,0,0,0,.044.035c.024,0,.034-.032.038-.054a.228.228,0,0,0-.024-.219C175.688,196.146,175.609,196.132,175.628,196.034Z" transform="translate(-174.057 -195.201)" fill="#fcd07f" />
                  <path id="Path_1523" data-name="Path 1523" d="M176.2,195.331a.289.289,0,0,0,.055.014.048.048,0,0,0,.047-.024.062.062,0,0,0,0-.029.181.181,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.085-.007-.243-.055-.269.086,0,.018,0,.037,0,.055a.1.1,0,0,1-.07.084c-.023.007-.048.006-.071.014s-.045.026-.043.05.035.041.063.048a.269.269,0,0,0,.257-.02C176.07,195.4,176.089,195.305,176.2,195.331Z" transform="translate(-174.078 -195.124)" fill="#fcd07f" />
                  <path id="Path_1524" data-name="Path 1524" d="M174.27,199.747a.33.33,0,0,0,.009-.051.042.042,0,0,0-.024-.042.063.063,0,0,0-.026,0,.166.166,0,0,0-.091.029.176.176,0,0,0-.041.13c0,.078-.039.223.09.24.016,0,.033,0,.051,0a.094.094,0,0,1,.079.06c.008.02.008.043.015.063s.026.04.047.036.035-.034.041-.058a.244.244,0,0,0-.029-.232C174.336,199.866,174.251,199.852,174.27,199.747Z" transform="translate(-173.928 -195.551)" fill="#fcd07f" />
                  <path id="Path_1525" data-name="Path 1525" d="M176.622,203.033a.3.3,0,0,0,.022-.045.041.041,0,0,0-.012-.046.06.06,0,0,0-.024-.008.169.169,0,0,0-.1,0,.177.177,0,0,0-.073.114c-.023.073-.1.2.023.253.015.006.033.008.048.014a.094.094,0,0,1,.06.078c0,.022,0,.043,0,.064a.049.049,0,0,0,.036.047c.024,0,.042-.023.053-.045a.239.239,0,0,0,.034-.228C176.653,203.164,176.576,203.128,176.622,203.033Z" transform="translate(-174.145 -195.859)" fill="#fcd07f" />
                  <path id="Path_1526" data-name="Path 1526" d="M174.792,196.788a.3.3,0,0,0,.015.048.042.042,0,0,0,.041.024.058.058,0,0,0,.023-.011.162.162,0,0,0,.065-.068.168.168,0,0,0-.026-.132c-.034-.068-.071-.211-.189-.166-.015.006-.029.017-.044.024a.092.092,0,0,1-.1-.014c-.016-.014-.027-.033-.043-.047a.048.048,0,0,0-.058-.009c-.02.015-.015.045-.007.069.024.08.051.16.133.187S174.759,196.688,174.792,196.788Z" transform="translate(-173.963 -195.247)" fill="#fcd07f" />
                  <path id="Path_1527" data-name="Path 1527" d="M174.118,196.085a.155.155,0,0,1-.065.067.059.059,0,0,1-.024.013c-.015,0-.031-.011-.04-.024a.326.326,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.236c.111-.034.149.1.182.169A.176.176,0,0,1,174.118,196.085Z" transform="translate(-173.911 -195.186)" fill="#fcd07f" />
                  <path id="Path_1528" data-name="Path 1528" d="M174.969,200.32a.332.332,0,0,0-.05.019.043.043,0,0,0-.024.043.06.06,0,0,0,.013.024.175.175,0,0,0,.075.066.185.185,0,0,0,.138-.034c.071-.039.218-.085.166-.208-.007-.016-.02-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.018.034-.03.047-.047a.051.051,0,0,0,.007-.062c-.017-.02-.049-.014-.074,0-.082.029-.166.062-.189.15S175.073,200.28,174.969,200.32Z" transform="translate(-174.004 -195.58)" fill="#fcd07f" />
                  <path id="Path_1529" data-name="Path 1529" d="M174.143,201.437a.161.161,0,0,1-.077.065.057.057,0,0,1-.024.009.041.041,0,0,1-.04-.031.305.305,0,0,1-.012-.052.108.108,0,0,0-.079-.09v-.223a.239.239,0,0,1,.038-.015c.129-.033.153.12.18.2A.175.175,0,0,1,174.143,201.437Z" transform="translate(-173.911 -195.687)" fill="#fcd07f" />
                  <path id="Path_1530" data-name="Path 1530" d="M176.626,199.508a.152.152,0,0,0,.025.016c.085.048.132-.072.141-.137a.239.239,0,0,0-.067-.209c-.062-.057-.248-.087-.253.024a.088.088,0,0,0,.013.061.234.234,0,0,0,.029.024c.029.026.029.071.038.109A.2.2,0,0,0,176.626,199.508Z" transform="translate(-174.152 -195.501)" fill="#fcd07f" />
                  <path id="Path_1531" data-name="Path 1531" d="M176.143,201.72a.22.22,0,0,0,0,.034c0,.111.144.091.212.066a.271.271,0,0,0,.171-.179c.023-.091-.048-.292-.16-.237a.1.1,0,0,0-.053.045.336.336,0,0,0-.008.042c-.01.043-.054.067-.087.1A.226.226,0,0,0,176.143,201.72Z" transform="translate(-174.121 -195.715)" fill="#fcd07f" />
                  <path id="Path_1532" data-name="Path 1532" d="M176.507,195.97l-.027.021c-.081.075.032.168.1.2a.27.27,0,0,0,.248,0c.082-.045.181-.234.064-.279a.1.1,0,0,0-.07-.008c-.013.005-.023.016-.035.023-.038.023-.086.006-.13,0A.227.227,0,0,0,176.507,195.97Z" transform="translate(-174.15 -195.197)" fill="#fcd07f" />
                  <path id="Path_1533" data-name="Path 1533" d="M176.218,197.064a.186.186,0,0,0,0,.032c0,.1.135.081.2.055a.25.25,0,0,0,.152-.169c.019-.085-.052-.268-.153-.215a.089.089,0,0,0-.048.043.292.292,0,0,0-.006.039c-.007.04-.048.063-.078.091A.212.212,0,0,0,176.218,197.064Z" transform="translate(-174.128 -195.278)" fill="#fcd07f" />
                  <path id="Path_1534" data-name="Path 1534" d="M175.844,200.257a.116.116,0,0,0-.016.024c-.045.082.071.127.132.136a.229.229,0,0,0,.2-.064c.055-.059.084-.239-.023-.244a.085.085,0,0,0-.058.012.164.164,0,0,0-.023.028c-.025.029-.069.028-.106.037A.194.194,0,0,0,175.844,200.257Z" transform="translate(-174.091 -195.594)" fill="#fcd07f" />
                  <path id="Path_1535" data-name="Path 1535" d="M176.539,198.156a.261.261,0,0,0,.012.028c.044.09.152.015.2-.033a.246.246,0,0,0,.065-.214c-.019-.082-.157-.216-.225-.126a.087.087,0,0,0-.025.058c0,.013.008.024.011.036.009.039-.016.076-.031.113A.208.208,0,0,0,176.539,198.156Z" transform="translate(-174.158 -195.374)" fill="#fcd07f" />
                  <path id="Path_1536" data-name="Path 1536" d="M174.778,204.151c.065.054.154.12.157-.025,0-.081-.1-.17-.177-.181-.034-.005-.079,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.128.128,0,0,1,.056.014C174.742,204.119,174.759,204.134,174.778,204.151Z" transform="translate(-173.97 -195.941)" fill="#fcd07f" />
                  <path id="Path_1537" data-name="Path 1537" d="M177.151,198.493v.168c-.024-.015-.059-.005-.09,0a.226.226,0,0,1-.211-.139c-.027-.142.072-.1.148-.058l.057.029a.125.125,0,0,0,.058,0A.137.137,0,0,1,177.151,198.493Z" transform="translate(-174.188 -195.435)" fill="#fcd07f" />
                  <path id="Path_1538" data-name="Path 1538" d="M174.749,203.357c-.037.082-.078.188.069.151a.238.238,0,0,0,.133-.228c0-.036-.021-.079,0-.105s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C174.771,203.313,174.76,203.334,174.749,203.357Z" transform="translate(-173.987 -195.871)" fill="#fcd07f" />
                  <path id="Path_1539" data-name="Path 1539" d="M175.975,203.979c.041-.062.086-.144-.038-.13a.2.2,0,0,0-.135.168c0,.031.008.067-.016.086s-.082,0-.1.026a.135.135,0,0,0,.256-.055.1.1,0,0,1,.005-.05C175.952,204.013,175.964,204,175.975,203.979Z" transform="translate(-174.078 -195.946)" fill="#fcd07f" />
                  <path id="Path_1540" data-name="Path 1540" d="M174.527,195.551c0,.09,0,.207.122.115a.24.24,0,0,0,.038-.264c-.018-.034-.051-.067-.036-.1s.091-.047.092-.092a.167.167,0,0,0-.239.22.124.124,0,0,1,.024.056C174.53,195.5,174.528,195.525,174.527,195.551Z" transform="translate(-173.965 -195.129)" fill="#fcd07f" />
                  <path id="Path_1541" data-name="Path 1541" d="M175.461,202.415c-.048.071-.1.164.041.152a.226.226,0,0,0,.16-.192c0-.035-.007-.078.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.058.111.111,0,0,1-.007.057C175.488,202.377,175.474,202.4,175.461,202.415Z" transform="translate(-174.052 -195.787)" fill="#fcd07f" />
                  <path id="Path_1542" data-name="Path 1542" d="M175.431,197.769c-.07,0-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.013.053-.037.079-.026s.034.072.07.074a.135.135,0,0,0-.007-.161.133.133,0,0,0-.159-.029.1.1,0,0,1-.044.018Z" transform="translate(-174.043 -195.37)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1543" data-name="Path 1543" d="M176.444,195.265a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,176.444,195.265Z" transform="translate(-173.911 -193.802)" fill="#f1f4f4" />
            </g>
            <g id="Group_272" data-name="Group 272" transform="translate(11.217 17.216)">
              <path id="Path_1544" data-name="Path 1544" d="M179.85,205.175l-.348.247q-.042-.03-.09-.057a2.316,2.316,0,0,0-2.1,0,1.064,1.064,0,0,0-.094.061l-.336-.247v14.445h0a.742.742,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.728.728,0,0,0,.431-.607h0Z" transform="translate(-176.885 -196.213)" fill="#dce4e4" />
              <g id="Group_271" data-name="Group 271" transform="translate(0 0.881)">
                <path id="Path_1545" data-name="Path 1545" d="M179.849,196.323v8.016a.725.725,0,0,1-.431.608,2.315,2.315,0,0,1-2.094,0,.75.75,0,0,1-.438-.605v-8.016l.337.247c.029-.021.06-.042.094-.062a2.318,2.318,0,0,1,2.1.005.926.926,0,0,1,.089.057Z" transform="translate(-176.885 -196.26)" fill="#f1a443" />
                <g id="Group_270" data-name="Group 270" transform="translate(0 0.315)">
                  <path id="Path_1546" data-name="Path 1546" d="M177.912,202.877c-.053-.032-.112-.073-.118-.135a.442.442,0,0,0,0-.049.113.113,0,0,0-.05-.064c-.056-.042-.129-.074-.153-.14a.338.338,0,0,0-.105.1.12.12,0,0,0,0,.137c.086.1.08.04.03.145-.029.06-.055.127.014.178C177.657,203.141,177.838,202.954,177.912,202.877Z" transform="translate(-176.94 -197.162)" fill="#fcd07f" />
                  <path id="Path_1547" data-name="Path 1547" d="M180.127,203.809v.253a.233.233,0,0,0-.108.1c-.074-.079-.249-.27-.149-.389.055-.065.12-.034.178,0S180.114,203.818,180.127,203.809Z" transform="translate(-177.163 -197.28)" fill="#fcd07f" />
                  <path id="Path_1548" data-name="Path 1548" d="M177.2,204.663c-.062.065-.2.209-.32.194v-.575c.028.062.1.092.149.132a.111.111,0,0,1,.05.065.428.428,0,0,1,0,.048C177.093,204.589,177.151,204.631,177.2,204.663Z" transform="translate(-176.885 -197.331)" fill="#fcd07f" />
                  <path id="Path_1549" data-name="Path 1549" d="M177.2,199.021a.209.209,0,0,1,.074.171.45.45,0,0,0-.014.05.125.125,0,0,0,.029.08c.043.06.105.115.108.189a.369.369,0,0,0,.137-.066.127.127,0,0,0,.04-.139c-.056-.121-.067-.065.016-.153.048-.052.1-.11.043-.183C177.541,198.838,177.3,198.965,177.2,199.021Z" transform="translate(-176.915 -196.825)" fill="#fcd07f" />
                  <path id="Path_1550" data-name="Path 1550" d="M178.931,200.439c-.037-.031-.079-.069-.076-.118,0-.013,0-.025,0-.039a.093.093,0,0,0-.032-.055c-.04-.039-.093-.072-.1-.127a.265.265,0,0,0-.093.066.094.094,0,0,0-.012.108c.056.084.058.041.007.116-.03.044-.057.094-.009.141C178.7,200.617,178.865,200.492,178.931,200.439Z" transform="translate(-177.046 -196.937)" fill="#fcd07f" />
                  <path id="Path_1551" data-name="Path 1551" d="M180.131,198.3v.274a.261.261,0,0,0-.036.081c-.073-.042-.252-.145-.2-.258.025-.062.082-.054.134-.044.089.019.048.034.1-.05Z" transform="translate(-177.168 -196.768)" fill="#fcd07f" />
                  <path id="Path_1552" data-name="Path 1552" d="M177.1,200.516a.551.551,0,0,1-.214.119v-.457l0,0c.013.054.065.088.105.127a.1.1,0,0,1,.033.055.287.287,0,0,1-.005.039C177.019,200.445,177.061,200.485,177.1,200.516Z" transform="translate(-176.885 -196.944)" fill="#fcd07f" />
                  <path id="Path_1553" data-name="Path 1553" d="M177.793,200.293a.164.164,0,0,1-.13.063.268.268,0,0,0-.039-.009.1.1,0,0,0-.062.025c-.045.035-.086.085-.143.091a.284.284,0,0,0,.056.1.1.1,0,0,0,.109.025c.093-.047.049-.054.12.008.041.035.088.071.143.027C177.947,200.55,177.839,200.368,177.793,200.293Z" transform="translate(-176.935 -196.956)" fill="#fcd07f" />
                  <path id="Path_1554" data-name="Path 1554" d="M179.679,201.994a.174.174,0,0,1,0,.154.306.306,0,0,0-.025.034.1.1,0,0,0,0,.07.315.315,0,0,1,.025.178.277.277,0,0,0,.125-.009.1.1,0,0,0,.072-.093c-.005-.111-.032-.071.059-.113.053-.024.107-.054.09-.127C179.994,201.958,179.771,201.982,179.679,201.994Z" transform="translate(-177.146 -197.115)" fill="#fcd07f" />
                  <path id="Path_1555" data-name="Path 1555" d="M177.483,204.15a.158.158,0,0,1,.025-.138c.009-.009.021-.015.029-.025a.093.093,0,0,0,.015-.062.29.29,0,0,1,.012-.163.27.27,0,0,0-.113-.015.093.093,0,0,0-.082.069c-.016.1.014.069-.074.09-.052.012-.105.028-.1.1C177.2,204.122,177.4,204.143,177.483,204.15Z" transform="translate(-176.914 -197.281)" fill="#fcd07f" />
                  <path id="Path_1556" data-name="Path 1556" d="M178.6,197.519a.3.3,0,0,0,.009-.048.04.04,0,0,0-.022-.04.056.056,0,0,0-.024,0,.152.152,0,0,0-.087.026.165.165,0,0,0-.04.123c0,.072-.039.209.082.227.015,0,.032,0,.048,0a.088.088,0,0,1,.073.057c.007.019.007.041.014.06s.024.037.044.034.034-.031.039-.054a.229.229,0,0,0-.025-.219C178.663,197.631,178.583,197.616,178.6,197.519Z" transform="translate(-177.031 -196.686)" fill="#fcd07f" />
                  <path id="Path_1557" data-name="Path 1557" d="M179.178,196.816a.418.418,0,0,0,.056.013.047.047,0,0,0,.047-.024.074.074,0,0,0,0-.028.18.18,0,0,0-.028-.1.189.189,0,0,0-.141-.053c-.085-.007-.244-.054-.27.086,0,.018,0,.037,0,.056a.1.1,0,0,1-.07.083c-.023.007-.047.006-.07.014a.053.053,0,0,0-.043.051c0,.027.035.04.062.047a.266.266,0,0,0,.257-.02C179.044,196.882,179.064,196.789,179.178,196.816Z" transform="translate(-177.052 -196.608)" fill="#fcd07f" />
                  <path id="Path_1558" data-name="Path 1558" d="M177.245,201.231a.315.315,0,0,0,.009-.051.043.043,0,0,0-.024-.042.063.063,0,0,0-.026,0,.164.164,0,0,0-.091.03.174.174,0,0,0-.04.13c0,.077-.039.222.089.24.017,0,.034,0,.051,0a.1.1,0,0,1,.08.06c.007.02.007.043.014.063s.026.04.048.036.034-.034.04-.058a.241.241,0,0,0-.029-.232C177.31,201.35,177.225,201.336,177.245,201.231Z" transform="translate(-176.902 -197.035)" fill="#fcd07f" />
                  <path id="Path_1559" data-name="Path 1559" d="M179.6,204.518a.311.311,0,0,0,.023-.046.043.043,0,0,0-.012-.046.06.06,0,0,0-.025-.008.166.166,0,0,0-.094,0c-.043.019-.059.069-.073.113-.024.073-.1.2.022.253.015.007.033.008.048.014a.093.093,0,0,1,.06.078c0,.021,0,.043,0,.063a.048.048,0,0,0,.035.047c.025,0,.043-.023.054-.045a.238.238,0,0,0,.033-.228C179.627,204.648,179.551,204.613,179.6,204.518Z" transform="translate(-177.119 -197.344)" fill="#fcd07f" />
                  <path id="Path_1560" data-name="Path 1560" d="M177.767,198.272a.214.214,0,0,0,.015.048.041.041,0,0,0,.04.025.058.058,0,0,0,.024-.012.162.162,0,0,0,.065-.068.175.175,0,0,0-.026-.132c-.034-.068-.072-.21-.19-.165-.015.005-.029.016-.044.023a.091.091,0,0,1-.1-.014c-.016-.014-.026-.033-.043-.047a.049.049,0,0,0-.058-.009c-.02.015-.014.046-.007.07.024.079.051.159.134.187S177.733,198.172,177.767,198.272Z" transform="translate(-176.937 -196.731)" fill="#fcd07f" />
                  <path id="Path_1561" data-name="Path 1561" d="M177.093,197.57a.165.165,0,0,1-.065.067.076.076,0,0,1-.024.012c-.015,0-.031-.011-.039-.024a.2.2,0,0,1-.015-.048.1.1,0,0,0-.064-.072v-.237c.112-.034.149.1.182.17A.175.175,0,0,1,177.093,197.57Z" transform="translate(-176.885 -196.67)" fill="#fcd07f" />
                  <path id="Path_1562" data-name="Path 1562" d="M177.944,201.8a.333.333,0,0,0-.05.019.044.044,0,0,0-.024.043.063.063,0,0,0,.013.024.18.18,0,0,0,.075.065.183.183,0,0,0,.139-.034c.07-.039.218-.085.165-.208-.007-.016-.019-.03-.027-.045a.1.1,0,0,1,.011-.1c.014-.017.034-.03.048-.047a.051.051,0,0,0,.006-.062c-.017-.02-.049-.013-.073,0-.082.029-.166.062-.19.15S178.047,201.765,177.944,201.8Z" transform="translate(-176.978 -197.064)" fill="#fcd07f" />
                  <path id="Path_1563" data-name="Path 1563" d="M177.117,202.921a.171.171,0,0,1-.077.065.056.056,0,0,1-.024.008.041.041,0,0,1-.04-.031.2.2,0,0,1-.012-.052.108.108,0,0,0-.08-.089V202.6c.013,0,.024-.012.038-.015.129-.033.153.12.18.2A.177.177,0,0,1,177.117,202.921Z" transform="translate(-176.885 -197.171)" fill="#fcd07f" />
                  <path id="Path_1564" data-name="Path 1564" d="M179.6,200.992l.025.017c.086.047.132-.073.141-.138a.24.24,0,0,0-.067-.209c-.062-.057-.247-.087-.252.024a.086.086,0,0,0,.012.061.237.237,0,0,0,.029.024c.03.026.03.072.038.11A.2.2,0,0,0,179.6,200.992Z" transform="translate(-177.127 -196.986)" fill="#fcd07f" />
                  <path id="Path_1565" data-name="Path 1565" d="M179.118,203.205a.209.209,0,0,0,0,.034c0,.111.143.091.212.066a.269.269,0,0,0,.17-.179c.024-.091-.047-.292-.159-.236a.1.1,0,0,0-.053.044.223.223,0,0,0-.007.042c-.01.043-.054.067-.088.1A.23.23,0,0,0,179.118,203.205Z" transform="translate(-177.095 -197.199)" fill="#fcd07f" />
                  <path id="Path_1566" data-name="Path 1566" d="M179.482,197.454a.218.218,0,0,0-.027.021c-.082.075.031.168.1.2a.268.268,0,0,0,.247,0c.083-.045.182-.234.064-.278a.092.092,0,0,0-.069-.008.211.211,0,0,0-.035.023c-.039.022-.087.005-.13,0A.227.227,0,0,0,179.482,197.454Z" transform="translate(-177.125 -196.682)" fill="#fcd07f" />
                  <path id="Path_1567" data-name="Path 1567" d="M179.192,198.549c0,.01,0,.021,0,.031,0,.1.135.081.2.055a.251.251,0,0,0,.153-.169c.018-.085-.052-.268-.154-.215a.1.1,0,0,0-.048.043c0,.012,0,.025-.005.038-.008.04-.048.063-.078.091A.213.213,0,0,0,179.192,198.549Z" transform="translate(-177.102 -196.762)" fill="#fcd07f" />
                  <path id="Path_1568" data-name="Path 1568" d="M178.819,201.741a.115.115,0,0,0-.016.024c-.046.082.07.128.132.137a.233.233,0,0,0,.2-.065c.054-.059.084-.238-.023-.244a.082.082,0,0,0-.059.013.329.329,0,0,0-.023.027c-.025.029-.069.029-.105.037A.188.188,0,0,0,178.819,201.741Z" transform="translate(-177.065 -197.078)" fill="#fcd07f" />
                  <path id="Path_1569" data-name="Path 1569" d="M179.514,199.64a.19.19,0,0,0,.011.029c.044.089.153.015.2-.034a.24.24,0,0,0,.065-.213c-.019-.083-.157-.216-.225-.127a.092.092,0,0,0-.024.058.167.167,0,0,0,.011.036c.009.039-.017.076-.032.113A.208.208,0,0,0,179.514,199.64Z" transform="translate(-177.132 -196.859)" fill="#fcd07f" />
                  <path id="Path_1570" data-name="Path 1570" d="M177.753,205.635c.065.055.153.12.156-.025,0-.081-.1-.169-.176-.181-.035-.005-.079,0-.1-.031s.017-.1-.015-.122a.158.158,0,0,0,.026.306.121.121,0,0,1,.057.014A.562.562,0,0,1,177.753,205.635Z" transform="translate(-176.944 -197.425)" fill="#fcd07f" />
                  <path id="Path_1571" data-name="Path 1571" d="M180.125,199.977v.168c-.024-.015-.058-.005-.089,0a.226.226,0,0,1-.211-.139c-.028-.143.072-.1.148-.059.022.013.043.023.056.03a.16.16,0,0,0,.059,0A.172.172,0,0,1,180.125,199.977Z" transform="translate(-177.162 -196.919)" fill="#fcd07f" />
                  <path id="Path_1572" data-name="Path 1572" d="M177.724,204.842c-.038.081-.079.187.069.15a.239.239,0,0,0,.133-.227c0-.037-.022-.08,0-.106s.1-.009.119-.05a.164.164,0,0,0-.3.111.126.126,0,0,1,0,.061C177.745,204.8,177.735,204.819,177.724,204.842Z" transform="translate(-176.961 -197.355)" fill="#fcd07f" />
                  <path id="Path_1573" data-name="Path 1573" d="M178.95,205.463c.04-.062.086-.143-.038-.13a.2.2,0,0,0-.136.169c0,.03.008.067-.015.085s-.083,0-.1.026a.139.139,0,0,0,.15.075.14.14,0,0,0,.107-.13.1.1,0,0,1,.005-.05Z" transform="translate(-177.052 -197.43)" fill="#fcd07f" />
                  <path id="Path_1574" data-name="Path 1574" d="M177.5,197.035c0,.091,0,.206.122.115a.242.242,0,0,0,.039-.264c-.018-.033-.051-.067-.037-.1s.091-.047.092-.092a.167.167,0,0,0-.239.22.137.137,0,0,1,.025.056C177.5,196.985,177.5,197.009,177.5,197.035Z" transform="translate(-176.939 -196.614)" fill="#fcd07f" />
                  <path id="Path_1575" data-name="Path 1575" d="M178.435,203.9c-.048.071-.1.164.041.152.08-.006.157-.119.16-.192,0-.035-.007-.078.021-.1s.1.008.119-.027a.156.156,0,0,0-.3.057.122.122,0,0,1-.008.057A.5.5,0,0,1,178.435,203.9Z" transform="translate(-177.026 -197.271)" fill="#fcd07f" />
                  <path id="Path_1576" data-name="Path 1576" d="M178.406,199.254c-.071-.005-.16-.006-.092.092a.189.189,0,0,0,.2.035c.025-.014.053-.038.08-.026s.034.072.069.073a.129.129,0,0,0-.165-.19.11.11,0,0,1-.044.018Z" transform="translate(-177.017 -196.855)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1577" data-name="Path 1577" d="M179.418,196.75a2.319,2.319,0,0,1-2.1,0c-.58-.336-.583-.878-.006-1.21a2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,179.418,196.75Z" transform="translate(-176.885 -195.287)" fill="#f1f4f4" />
            </g>
            <g id="Group_275" data-name="Group 275" transform="translate(13.992 18.508)">
              <path id="Path_1578" data-name="Path 1578" d="M182.914,206.6l-.349.247c-.028-.02-.057-.038-.089-.057a2.319,2.319,0,0,0-2.1,0c-.034.02-.065.04-.1.061l-.336-.247v14.446h0a.748.748,0,0,0,.437.6,2.316,2.316,0,0,0,2.1,0,.726.726,0,0,0,.43-.607h0Z" transform="translate(-179.949 -197.64)" fill="#dce4e4" />
              <g id="Group_274" data-name="Group 274" transform="translate(0 0.881)">
                <path id="Path_1579" data-name="Path 1579" d="M182.913,197.75v8.017a.729.729,0,0,1-.431.608,2.317,2.317,0,0,1-2.1,0,.75.75,0,0,1-.437-.6V197.75l.337.247a1.124,1.124,0,0,1,.094-.062,2.313,2.313,0,0,1,2.1,0,.834.834,0,0,1,.09.057Z" transform="translate(-179.949 -197.687)" fill="#f1a443" />
                <g id="Group_273" data-name="Group 273" transform="translate(0 0.316)">
                  <path id="Path_1580" data-name="Path 1580" d="M180.975,204.3c-.053-.032-.111-.072-.118-.134a.291.291,0,0,0,0-.049.114.114,0,0,0-.05-.065c-.056-.042-.129-.074-.153-.14a.349.349,0,0,0-.105.1.118.118,0,0,0,0,.137c.087.1.081.041.031.145-.029.061-.055.128.014.178C180.721,204.568,180.9,204.382,180.975,204.3Z" transform="translate(-180.004 -198.59)" fill="#fcd07f" />
                  <path id="Path_1581" data-name="Path 1581" d="M183.191,205.237v.252a.244.244,0,0,0-.109.1c-.073-.079-.248-.269-.149-.389.054-.065.12-.034.178,0S183.178,205.245,183.191,205.237Z" transform="translate(-180.228 -198.708)" fill="#fcd07f" />
                  <path id="Path_1582" data-name="Path 1582" d="M180.269,206.09c-.062.065-.2.209-.32.194v-.575c.028.062.094.093.149.132a.117.117,0,0,1,.05.065.424.424,0,0,1,0,.048C180.156,206.016,180.215,206.058,180.269,206.09Z" transform="translate(-179.949 -198.759)" fill="#fcd07f" />
                  <path id="Path_1583" data-name="Path 1583" d="M180.266,200.448a.214.214,0,0,1,.074.171.509.509,0,0,0-.014.05.123.123,0,0,0,.029.081c.043.06.1.115.108.188a.363.363,0,0,0,.137-.066.124.124,0,0,0,.039-.138c-.055-.122-.066-.066.016-.154.049-.052.1-.11.043-.182C180.6,200.266,180.365,200.393,180.266,200.448Z" transform="translate(-179.979 -198.253)" fill="#fcd07f" />
                  <path id="Path_1584" data-name="Path 1584" d="M182,201.867c-.038-.031-.08-.07-.077-.119a.267.267,0,0,0,0-.038.091.091,0,0,0-.032-.056c-.039-.039-.092-.072-.1-.127a.276.276,0,0,0-.093.067.094.094,0,0,0-.012.107c.057.084.058.041.008.117-.03.044-.058.093-.01.141C181.767,202.045,181.928,201.919,182,201.867Z" transform="translate(-180.11 -198.365)" fill="#fcd07f" />
                  <path id="Path_1585" data-name="Path 1585" d="M183.2,199.727V200a.281.281,0,0,0-.036.081c-.073-.041-.253-.144-.2-.257.025-.062.082-.055.133-.044.09.018.049.034.105-.05Z" transform="translate(-180.232 -198.195)" fill="#fcd07f" />
                  <path id="Path_1586" data-name="Path 1586" d="M180.162,201.944a.558.558,0,0,1-.213.118V201.6l0,0c.012.055.065.088.105.127a.1.1,0,0,1,.033.055.3.3,0,0,1-.005.04A.164.164,0,0,0,180.162,201.944Z" transform="translate(-179.949 -198.372)" fill="#fcd07f" />
                  <path id="Path_1587" data-name="Path 1587" d="M180.857,201.72a.162.162,0,0,1-.13.063c-.014,0-.026-.007-.039-.008a.091.091,0,0,0-.062.025c-.045.035-.086.085-.143.09a.277.277,0,0,0,.056.1.1.1,0,0,0,.109.026c.092-.047.048-.054.12.007.042.035.089.071.144.027C181.01,201.977,180.9,201.8,180.857,201.72Z" transform="translate(-179.999 -198.383)" fill="#fcd07f" />
                  <path id="Path_1588" data-name="Path 1588" d="M182.742,203.421a.171.171,0,0,1,0,.154c-.007.012-.018.022-.025.034a.1.1,0,0,0,0,.07.326.326,0,0,1,.025.178.306.306,0,0,0,.125-.009.1.1,0,0,0,.072-.094c-.005-.11-.032-.071.059-.112.053-.024.107-.055.089-.127C183.058,203.385,182.835,203.41,182.742,203.421Z" transform="translate(-180.21 -198.542)" fill="#fcd07f" />
                  <path id="Path_1589" data-name="Path 1589" d="M180.547,205.577a.158.158,0,0,1,.025-.138.38.38,0,0,0,.029-.025.1.1,0,0,0,.015-.062.3.3,0,0,1,.011-.163.279.279,0,0,0-.112-.015.094.094,0,0,0-.082.07c-.015.1.014.069-.073.089-.052.012-.105.028-.1.1C180.26,205.55,180.462,205.57,180.547,205.577Z" transform="translate(-179.978 -198.708)" fill="#fcd07f" />
                  <path id="Path_1590" data-name="Path 1590" d="M181.666,198.946a.3.3,0,0,0,.01-.048.04.04,0,0,0-.023-.039.045.045,0,0,0-.024,0,.118.118,0,0,0-.126.149c0,.072-.04.209.081.227a.428.428,0,0,0,.048,0,.091.091,0,0,1,.074.058c.006.019.006.04.014.059s.024.038.044.034.034-.031.038-.054a.227.227,0,0,0-.024-.218C181.727,199.058,181.647,199.044,181.666,198.946Z" transform="translate(-180.095 -198.113)" fill="#fcd07f" />
                  <path id="Path_1591" data-name="Path 1591" d="M182.242,198.243a.288.288,0,0,0,.055.013.045.045,0,0,0,.047-.024.063.063,0,0,0,0-.029.179.179,0,0,0-.027-.1.194.194,0,0,0-.142-.052c-.084-.007-.243-.055-.269.085,0,.019,0,.038,0,.056a.1.1,0,0,1-.07.084c-.023.007-.048.006-.071.013s-.045.027-.043.051.035.041.062.047a.266.266,0,0,0,.257-.019C182.108,198.309,182.127,198.217,182.242,198.243Z" transform="translate(-180.116 -198.036)" fill="#fcd07f" />
                  <path id="Path_1592" data-name="Path 1592" d="M180.308,202.659a.249.249,0,0,0,.009-.051.042.042,0,0,0-.024-.042.043.043,0,0,0-.026,0,.166.166,0,0,0-.091.029.18.18,0,0,0-.041.13c0,.077-.039.223.09.24.017,0,.034,0,.051,0a.094.094,0,0,1,.079.06c.008.02.008.043.015.063s.026.04.047.036.035-.034.041-.059a.242.242,0,0,0-.029-.231C180.374,202.777,180.289,202.764,180.308,202.659Z" transform="translate(-179.966 -198.463)" fill="#fcd07f" />
                  <path id="Path_1593" data-name="Path 1593" d="M182.661,205.945a.27.27,0,0,0,.022-.046.042.042,0,0,0-.012-.046.059.059,0,0,0-.024-.007.162.162,0,0,0-.1,0,.177.177,0,0,0-.073.114c-.023.072-.1.2.023.253.015.006.033.008.048.014a.094.094,0,0,1,.06.078c0,.022,0,.043,0,.064a.049.049,0,0,0,.036.047c.024,0,.043-.024.053-.045a.24.24,0,0,0,.034-.229C182.691,206.076,182.614,206.04,182.661,205.945Z" transform="translate(-180.183 -198.771)" fill="#fcd07f" />
                  <path id="Path_1594" data-name="Path 1594" d="M180.83,199.7a.49.49,0,0,0,.015.048.043.043,0,0,0,.041.024.053.053,0,0,0,.023-.011.125.125,0,0,0,.039-.2c-.034-.068-.071-.211-.189-.166a.416.416,0,0,0-.044.024.093.093,0,0,1-.1-.014c-.016-.014-.027-.034-.043-.047a.046.046,0,0,0-.058-.009c-.02.014-.014.045-.007.069.024.079.051.16.133.187S180.8,199.6,180.83,199.7Z" transform="translate(-180.001 -198.159)" fill="#fcd07f" />
                  <path id="Path_1595" data-name="Path 1595" d="M180.156,199a.16.16,0,0,1-.065.067.058.058,0,0,1-.024.012c-.015,0-.031-.01-.04-.024a.328.328,0,0,1-.015-.048.1.1,0,0,0-.063-.072v-.237c.111-.034.149.1.182.17A.175.175,0,0,1,180.156,199Z" transform="translate(-179.949 -198.097)" fill="#fcd07f" />
                  <path id="Path_1596" data-name="Path 1596" d="M181.008,203.231a.307.307,0,0,0-.051.018.047.047,0,0,0-.024.044.06.06,0,0,0,.013.024.168.168,0,0,0,.075.065.177.177,0,0,0,.138-.034c.071-.038.219-.084.166-.208-.007-.015-.019-.029-.027-.045a.1.1,0,0,1,.011-.1c.014-.018.033-.03.047-.048a.05.05,0,0,0,.007-.062c-.017-.02-.049-.013-.073,0-.082.029-.167.062-.19.149S181.111,203.192,181.008,203.231Z" transform="translate(-180.042 -198.492)" fill="#fcd07f" />
                  <path id="Path_1597" data-name="Path 1597" d="M180.181,204.349a.171.171,0,0,1-.077.065.057.057,0,0,1-.024.009.041.041,0,0,1-.039-.032.231.231,0,0,1-.013-.051.106.106,0,0,0-.079-.09v-.223a.176.176,0,0,1,.038-.015c.129-.033.152.12.18.2A.175.175,0,0,1,180.181,204.349Z" transform="translate(-179.949 -198.599)" fill="#fcd07f" />
                  <path id="Path_1598" data-name="Path 1598" d="M182.664,202.42a.151.151,0,0,0,.025.016c.085.047.132-.072.141-.137a.239.239,0,0,0-.067-.209c-.062-.057-.248-.088-.253.024a.087.087,0,0,0,.013.061.361.361,0,0,0,.029.024c.029.025.029.071.038.109A.207.207,0,0,0,182.664,202.42Z" transform="translate(-180.19 -198.413)" fill="#fcd07f" />
                  <path id="Path_1599" data-name="Path 1599" d="M182.181,204.632a.221.221,0,0,0,0,.034c0,.111.144.091.213.065a.272.272,0,0,0,.17-.178c.023-.092-.048-.293-.159-.237a.1.1,0,0,0-.053.045.3.3,0,0,0-.008.041c-.01.043-.054.067-.087.1A.237.237,0,0,0,182.181,204.632Z" transform="translate(-180.159 -198.627)" fill="#fcd07f" />
                  <path id="Path_1600" data-name="Path 1600" d="M182.545,198.882a.14.14,0,0,0-.026.021c-.082.075.031.168.1.2a.27.27,0,0,0,.248,0c.082-.046.181-.235.064-.279a.1.1,0,0,0-.069-.008.3.3,0,0,0-.036.023.22.22,0,0,1-.13,0A.231.231,0,0,0,182.545,198.882Z" transform="translate(-180.189 -198.109)" fill="#fcd07f" />
                  <path id="Path_1601" data-name="Path 1601" d="M182.256,199.976c0,.011,0,.021,0,.032,0,.1.135.081.2.055a.247.247,0,0,0,.152-.17c.019-.085-.051-.267-.153-.214a.086.086,0,0,0-.048.043.287.287,0,0,0-.006.039c-.007.04-.048.063-.078.091A.215.215,0,0,0,182.256,199.976Z" transform="translate(-180.166 -198.19)" fill="#fcd07f" />
                  <path id="Path_1602" data-name="Path 1602" d="M181.882,203.168l-.016.024c-.045.083.071.128.133.137a.231.231,0,0,0,.2-.064c.055-.06.084-.239-.023-.244a.085.085,0,0,0-.058.012.218.218,0,0,0-.023.028c-.025.028-.069.028-.106.037A.2.2,0,0,0,181.882,203.168Z" transform="translate(-180.129 -198.506)" fill="#fcd07f" />
                  <path id="Path_1603" data-name="Path 1603" d="M182.577,201.068c0,.009.007.019.012.028.044.09.152.015.2-.033a.247.247,0,0,0,.065-.214c-.019-.083-.157-.216-.225-.126a.087.087,0,0,0-.025.058c0,.013.008.024.011.036.01.039-.016.076-.031.113A.209.209,0,0,0,182.577,201.068Z" transform="translate(-180.196 -198.286)" fill="#fcd07f" />
                  <path id="Path_1604" data-name="Path 1604" d="M180.816,207.062c.065.055.154.12.157-.025,0-.08-.1-.169-.176-.18-.035-.005-.08,0-.1-.031s.017-.1-.016-.122a.157.157,0,0,0,.027.305.124.124,0,0,1,.057.014Z" transform="translate(-180.008 -198.853)" fill="#fcd07f" />
                  <path id="Path_1605" data-name="Path 1605" d="M183.189,201.4v.168c-.024-.015-.058-.005-.09,0a.229.229,0,0,1-.211-.139c-.027-.142.072-.1.148-.058l.057.029a.126.126,0,0,0,.058,0A.136.136,0,0,1,183.189,201.4Z" transform="translate(-180.226 -198.347)" fill="#fcd07f" />
                  <path id="Path_1606" data-name="Path 1606" d="M180.787,206.269c-.037.082-.078.187.069.15a.236.236,0,0,0,.133-.227c0-.036-.021-.08,0-.106s.1-.008.119-.05a.171.171,0,0,0-.195-.063.169.169,0,0,0-.105.176.126.126,0,0,1,0,.061C180.809,206.225,180.8,206.246,180.787,206.269Z" transform="translate(-180.025 -198.783)" fill="#fcd07f" />
                  <path id="Path_1607" data-name="Path 1607" d="M182.013,206.89c.04-.061.086-.143-.038-.13a.2.2,0,0,0-.135.169c0,.03.008.067-.016.086s-.082-.005-.1.026a.135.135,0,0,0,.256-.055.128.128,0,0,1,.005-.05C181.99,206.925,182,206.908,182.013,206.89Z" transform="translate(-180.116 -198.858)" fill="#fcd07f" />
                  <path id="Path_1608" data-name="Path 1608" d="M180.566,198.462c0,.091,0,.206.122.115a.24.24,0,0,0,.038-.264c-.018-.033-.051-.066-.036-.1s.091-.046.092-.091a.166.166,0,0,0-.239.219.127.127,0,0,1,.024.057C180.568,198.413,180.566,198.436,180.566,198.462Z" transform="translate(-180.003 -198.041)" fill="#fcd07f" />
                  <path id="Path_1609" data-name="Path 1609" d="M181.5,205.327c-.048.07-.1.163.041.151a.225.225,0,0,0,.16-.191c0-.035-.007-.078.021-.1s.1.007.119-.028a.156.156,0,0,0-.3.057.124.124,0,0,1-.008.058C181.526,205.288,181.512,205.306,181.5,205.327Z" transform="translate(-180.09 -198.699)" fill="#fcd07f" />
                  <path id="Path_1610" data-name="Path 1610" d="M181.469,200.681c-.07-.005-.16-.005-.091.092a.187.187,0,0,0,.2.035c.025-.014.053-.038.079-.026s.034.072.07.073a.13.13,0,0,0-.166-.19.107.107,0,0,1-.044.019Z" transform="translate(-180.081 -198.282)" fill="#fcd07f" />
                </g>
              </g>
              <path id="Path_1611" data-name="Path 1611" d="M182.482,198.177a2.316,2.316,0,0,1-2.1,0,.639.639,0,0,1-.006-1.21,2.316,2.316,0,0,1,2.1,0A.639.639,0,0,1,182.482,198.177Z" transform="translate(-179.949 -196.714)" fill="#f1f4f4" />
            </g>
            <g id="Group_276" data-name="Group 276" transform="translate(7.251 11.519)">
              <path id="Path_1612" data-name="Path 1612" d="M180.69,205.737l15.923-9.192v18.712l-15.923,9.192Z" transform="translate(-173.278 -189.709)" fill="#ff0007" />
              <path id="Path_1613" data-name="Path 1613" d="M186.146,223.076l.136-3.664s1.218-1.847,5.069-4.024,5.776-3.129,5.776-3.129v4.479Z" transform="translate(-173.792 -191.19)" fill="#e4e5e8" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
              <path id="Path_1614" data-name="Path 1614" d="M182.474,207l12.692-7.327v5.074l-12.692,7.327Z" transform="translate(-173.446 -190.003)" fill="#e5a433" />
              <path id="Path_1615" data-name="Path 1615" d="M182.474,207l6.444.39,6.248-7.717V205.6l-12.692,7.327Z" transform="translate(-173.446 -190.003)" fill="#dce4e4" />
              <path id="Path_1616" data-name="Path 1616" d="M180.467,198.638l-.548.306-7.413-4.281.548-.306Z" transform="translate(-172.506 -189.502)" fill="#ede5e6" />
              <path id="Path_1617" data-name="Path 1617" d="M196.081,189l.483.249-.458,6.323-15.923,9.192v-6.587l2.541-1.468.46.263-.46,4.614,10.837-6.257v-4.877Z" transform="translate(-173.23 -188.997)" fill="#ede5e6" />
              <path id="Path_1618" data-name="Path 1618" d="M196.613,189.273v6.587l-15.923,9.192v-6.587L183.231,197v4.877l10.838-6.257V190.74Z" transform="translate(-173.278 -189.023)" fill="#d8d4d5" />
              <path id="Path_1619" data-name="Path 1619" d="M179.919,198.976v25.3l-7.413-4.281v-25.3Z" transform="translate(-172.506 -189.534)" fill="#cc0007" />
              <path id="Path_1620" data-name="Path 1620" d="M179.919,198.976v6.587l-7.412-8.18v-2.688Z" transform="translate(-172.506 -189.534)" fill="#aea6a8" />
            </g>
          </g>
          <g id="Group_279" data-name="Group 279" transform="translate(23.322 31.528)">
            <g id="Group_278" data-name="Group 278">
              <path id="Path_1621" data-name="Path 1621" d="M223.738,222.178c-.629-1.4-2.993-1.9-4.97-3-5.772-3.226-14.055-4.078-19.8-.873a8.591,8.591,0,0,0-3.841,3.773c-1.22-2.7.173-4.29,3.954-6.394.225-.128.351-1.64.591-1.76,0,0-.135,2.089.764,2.171a2.439,2.439,0,0,0,2.238-1.639,7.867,7.867,0,0,0,.082-1.677l-.014-.007c5.525-1.55,12.451-1.033,17.086,1.557.314.173.606.352.883.54a8.385,8.385,0,0,0,.1,1.669c.142.681.449,1.962,1.3,2.283.786.3.823-1.677.823-1.962A4.956,4.956,0,0,1,223.738,222.178Z" transform="translate(-190.669 -211.166)" fill="#678989" />
              <path id="Path_1622" data-name="Path 1622" d="M215.146,216.2a1.62,1.62,0,0,1-.16.121,3.214,3.214,0,0,1-.99.379c-.868.226-1.958-.325-2.85-.485a21.372,21.372,0,0,0-3.076-.32,25.428,25.428,0,0,0-6.277.588,24.009,24.009,0,0,0-6.192,2.283c-1.939,1.078-3.628,1.493-4.25,2.878-1.22-2.7.162-5.5,3.943-7.6.225-.128.452-.389.692-.508,0,0-.225,2.043.674,2.126a2.441,2.441,0,0,0,2.239-1.639,6.616,6.616,0,0,0,.067-1.685,25.068,25.068,0,0,1,12.426-.207,19.727,19.727,0,0,1,3.125,1.015,13.912,13.912,0,0,1,1.437.7c.294.167.37.18.263.505a8.076,8.076,0,0,1-.4.9A2.811,2.811,0,0,1,215.146,216.2Z" transform="translate(-190.313 -211.123)" fill="#678989" />
              <path id="Path_1623" data-name="Path 1623" d="M223.991,221.84l-3.962,3.818c-.321-.22-.658-.437-1.016-.644-6.609-3.816-17.292-3.835-23.859-.043q-.573.333-1.079.687l-3.825-3.818v5.02h0c.026,2.483,1.686,4.965,4.977,6.866,6.609,3.816,17.291,3.835,23.858.043,3.3-1.9,4.929-4.406,4.9-6.909h0Z" transform="translate(-190.25 -212.102)" fill="#dce4e4" />
              <path id="Path_1624" data-name="Path 1624" d="M219.088,227.739c-6.566,3.789-17.25,3.766-23.862-.044s-6.641-9.989-.074-13.776c.255-.143.509-.285.771-.419l1.437,1.13s-.135,2.089.764,2.171a2.441,2.441,0,0,0,2.239-1.639,7.867,7.867,0,0,0,.082-1.677l-.015-.007-1.512-1.168-.015-.014c6.409-2.044,14.645-1.483,20.111,1.669a13.306,13.306,0,0,1,1.28.831l-.044.022-1.85.742v.014a8.375,8.375,0,0,0,.1,1.67c.142.681.449,1.961,1.3,2.283.786.3.824-1.677.824-1.962v-.03l1.834-.726C225.311,220.4,224.195,224.788,219.088,227.739Z" transform="translate(-190.25 -211.089)" fill="#f1f4f4" />
              <path id="Path_1625" data-name="Path 1625" d="M217.825,227.173a8.993,8.993,0,0,1-1.37.674l-.053-.038s-2.455.794-3.062,1.063c-5.4,1.318-11.934.734-16.374-1.736-.255-.143-.5-.293-.735-.442l.045-.015a25.879,25.879,0,0,1-2.292-1.909.09.09,0,0,0-.014-.022c-2.535-3.191-.805-5.072,4.108-6.493,20.31-5.874,23.588,5.137,23.588,5.137S221.4,224.849,217.825,227.173Z" transform="translate(-190.491 -211.602)" fill="#dce4e4" />
              <path id="Path_1626" data-name="Path 1626" d="M219.846,219.688a7.678,7.678,0,0,1,.736,3.858s-.268,1.457-3.841,3.781a9.075,9.075,0,0,1-1.37.673l-.053-.037s-2.455.793-3.062,1.063a25.206,25.206,0,0,1-6.341.68,25.691,25.691,0,0,0,8.66-.536c.607-.269,3.062-1.063,3.062-1.063l.053.038a8.993,8.993,0,0,0,1.37-.674c3.573-2.324,3.84-3.782,3.84-3.782A8.891,8.891,0,0,0,219.846,219.688Z" transform="translate(-191.726 -211.899)" fill="#678989" />
              <path id="Path_1627" data-name="Path 1627" d="M209.207,226.5a8.792,8.792,0,0,0,1.421.19c.786.041,10.931-4.992,10.931-4.992a15.088,15.088,0,0,0-1.746-1.37c-.69-.412-12.39,4.885-12.509,5.244S209.207,226.5,209.207,226.5Z" transform="translate(-191.857 -211.957)" fill="#678989" />
              <path id="Path_1628" data-name="Path 1628" d="M224.512,223.556a6.821,6.821,0,0,0-1.475-2.061,4.962,4.962,0,0,0,.479-1.533c.029-.182.057-.373.077-.546a6.231,6.231,0,0,0,.114-1.178A4.953,4.953,0,0,1,224.512,223.556Z" transform="translate(-193.34 -211.763)" fill="#3c6868" />
              <path id="Path_1629" data-name="Path 1629" d="M220.724,218.9c-.02.173-.048.364-.077.546a4.962,4.962,0,0,1-.479,1.533c-.019-.019-.134-.114-.306-.268a5.824,5.824,0,0,0-.642-.536,11.7,11.7,0,0,0-1.485-.967c-5.768-3.229-15.118-3.248-20.857-.038a8.52,8.52,0,0,0-3.841,3.774c-1.216-2.711.057-5.691,3.841-7.789.221-.125.46-.249.7-.364,0,0-.135,2.089.766,2.165a2.438,2.438,0,0,0,2.242-1.639,7.543,7.543,0,0,0,.076-1.676l-.009-.01c5.528-1.552,12.444-1.034,17.082,1.562a8.715,8.715,0,0,1,.881.536,8.6,8.6,0,0,0,.105,1.667c.144.68.45,1.964,1.293,2.29a.509.509,0,0,0,.115.019.215.215,0,0,0,.134-.01.116.116,0,0,0,.076-.038.15.15,0,0,0,.077-.058.521.521,0,0,0,.067-.076.487.487,0,0,0,.086-.154,1.453,1.453,0,0,0,.134-.382A.275.275,0,0,1,220.724,218.9Z" transform="translate(-190.471 -211.246)" fill="#a6baba" />
            </g>
            <path id="Path_1630" data-name="Path 1630" d="M218.591,229.021c-.014.007-.037.014-.053.023a18.6,18.6,0,0,1-3.062,1c.607-.269,3.062-1.063,3.062-1.063Z" transform="translate(-192.628 -212.775)" fill="#dce4e4" />
            <path id="Path_1631" data-name="Path 1631" d="M219.985,231.84a2.459,2.459,0,0,1-2.246,1.639c-.5-.044-.681-.725-.742-1.31a6.348,6.348,0,0,1-.022-.861l-1.565-1.228a.363.363,0,0,0,.06-.03,18.6,18.6,0,0,0,3.062-1c.015-.008.038-.015.053-.023l1.475,1.138A8.186,8.186,0,0,1,219.985,231.84Z" transform="translate(-192.621 -212.779)" fill="#a9bdbd" />
            <path id="Path_1632" data-name="Path 1632" d="M218.457,230.667a2.539,2.539,0,0,1-1.46,1.505,6.348,6.348,0,0,1-.022-.861l-1.565-1.228a.363.363,0,0,0,.06-.03,18.6,18.6,0,0,0,3.062-1A7.581,7.581,0,0,1,218.457,230.667Z" transform="translate(-192.621 -212.782)" fill="#dce4e4" />
            <path id="Path_1633" data-name="Path 1633" d="M194.288,228.306l-.023.007s.023,2.313-.823,1.992-1.153-1.6-1.3-2.283a9.06,9.06,0,0,1-.1-1.685l1.85-.734a8.315,8.315,0,0,0,.1,1.677A5.294,5.294,0,0,0,194.288,228.306Z" transform="translate(-190.419 -212.457)" fill="#a6baba" />
            <path id="Path_1634" data-name="Path 1634" d="M196.648,227.742l-.061.023v-.014l.045-.015C196.64,227.735,196.64,227.742,196.648,227.742Z" transform="translate(-190.847 -212.658)" fill="#dce4e4" />
            <path id="Path_1635" data-name="Path 1635" d="M196.4,227.536l-.045.015a9.682,9.682,0,0,1-2.246-1.925A25.879,25.879,0,0,0,196.4,227.536Z" transform="translate(-190.614 -212.459)" fill="#dce4e4" />
            <path id="Path_1636" data-name="Path 1636" d="M196.351,227.549v.014l-1.871.742a5.3,5.3,0,0,1-.293-1.026,8.314,8.314,0,0,1-.1-1.677.089.089,0,0,1,.014.022A9.682,9.682,0,0,0,196.351,227.549Z" transform="translate(-190.611 -212.457)" fill="#dce4e4" />
          </g>
          <g id="Group_283" data-name="Group 283" transform="translate(39.141 30.575)">
            <g id="Group_280" data-name="Group 280" transform="translate(0 0.128)">
              <path id="Path_1637" data-name="Path 1637" d="M209.834,225.478l.072-.5c-.035-.019-.072-.04-.107-.063a2.73,2.73,0,0,1-1.131-2.2c0-.046.007-.09.013-.132l-.44-.22,23.714-12.123h0a.878.878,0,0,1,.869.135,2.73,2.73,0,0,1,1.131,2.2.861.861,0,0,1-.407.779h0Z" transform="translate(-207.764 -210.178)" fill="#f7f9f9" />
              <path id="Path_1638" data-name="Path 1638" d="M210.224,225.961a2.732,2.732,0,0,0-1.13-2.2c-.665-.43-1.235-.142-1.275.643a2.733,2.733,0,0,0,1.131,2.2C209.614,227.035,210.185,226.747,210.224,225.961Z" transform="translate(-207.724 -211.44)" fill="#f1f4f4" />
              <path id="Path_1639" data-name="Path 1639" d="M210.141,225.988A2.753,2.753,0,0,0,209,223.775c-.67-.432-1.245-.14-1.283.65a2.751,2.751,0,0,0,1.142,2.213C209.528,227.069,210.1,226.779,210.141,225.988Z" transform="translate(-207.715 -211.441)" fill="#68221a" />
              <path id="Path_1640" data-name="Path 1640" d="M210.024,225.922A2.452,2.452,0,0,0,209,223.947c-.6-.385-1.11-.125-1.144.581a2.458,2.458,0,0,0,1.019,1.975C209.478,226.888,209.989,226.628,210.024,225.922Z" transform="translate(-207.728 -211.459)" fill="#ff3317" />
              <path id="Path_1641" data-name="Path 1641" d="M209.406,225.078a.122.122,0,0,1,0,.044.134.134,0,0,1-.109.1c-.05.012-.1.007-.153.016a.63.63,0,0,0-.333.25c-.048.051.005.069-.1.067a.294.294,0,0,1-.2-.119.308.308,0,0,1-.037-.3c.07-.168.268-.238.391-.372a1,1,0,0,0,.176-.674.368.368,0,0,1,.245.153.66.66,0,0,1,.091.2c.015.067-.024.134-.024.2a1.5,1.5,0,0,1,.21-.018c.017,0,.037,0,.047-.014a.033.033,0,0,0,0-.037.062.062,0,0,0-.032-.023c-.044-.018-.1-.012-.139-.037s-.061-.1-.091-.144c-.052-.08-.1-.007-.12.053-.007,0-.014,0-.02-.01a.637.637,0,0,1-.117-.152l.1,0c.025,0,.05,0,.058-.025.015-.05-.054-.1-.082-.13a.46.46,0,0,0-.325-.163c-.081.005-.215-.017-.262.064a.781.781,0,0,0-.082.217.292.292,0,0,0-.114-.15c-.052-.039-.109-.069-.159-.109a.648.648,0,0,0,.3-.055c.1-.049.152-.084.238.007a.578.578,0,0,1,.108.167.684.684,0,0,0,.06.158,4.37,4.37,0,0,1,.256.355.1.1,0,0,1,.02.082c-.013.03-.051.038-.083.041-.3.024-.632-.06-.865.125a.094.094,0,0,0-.044.074c0,.034.038.055.069.071a6.167,6.167,0,0,0,.69.294,2.215,2.215,0,0,0-.274.585c.066.029.161.05.2-.014.047-.084.031-.212.079-.305a.436.436,0,0,1,.36-.213.6.6,0,0,0,.181-.061c.024-.013.044-.032.071-.029s.072.042.1.059l.341.251a.04.04,0,0,1,.018.021.033.033,0,0,1,0,.023.5.5,0,0,1-.129.22.186.186,0,0,1-.042-.13,2.04,2.04,0,0,1,0-.268c.005-.07.031-.144-.052-.159a.792.792,0,0,0-.277.009c.023,0,.07-.112.081-.133.028-.052.047-.107.074-.159a.27.27,0,0,0,.043-.087c.009-.053-.015-.1.018-.149a.321.321,0,0,1-.236.146c-.045,0-.1,0-.126.039s0,.143,0,.194c0,.106-.028.214-.038.32-.02.213-.03.428-.03.641a.244.244,0,0,0,.075.214c.08.052.2,0,.269.072a.136.136,0,0,1-.044.2.287.287,0,0,1-.227.008,1.485,1.485,0,0,0-.224-.069c-.014-.14-.118-.267-.1-.408s.168-.255.312-.312a.419.419,0,0,0,.13-.067.121.121,0,0,0,.042-.13.162.162,0,0,0-.038-.052,2.625,2.625,0,0,0-.829-.462c-.047-.021-.1-.074-.069-.114a.084.084,0,0,1,.053-.022.576.576,0,0,1,.325.056.985.985,0,0,0,.207.1.159.159,0,0,0,.193-.084Z" transform="translate(-207.762 -211.469)" fill="#68221a" />
              <path id="Path_1642" data-name="Path 1642" d="M208.239,225.188a.241.241,0,0,0-.014.134l.008.184a.337.337,0,0,0,.015.1.319.319,0,0,0,.052.083l.22.288a.582.582,0,0,0,.381.282.276.276,0,0,0-.052-.24.917.917,0,0,0-.186-.174,4.463,4.463,0,0,1-.466-.43.734.734,0,0,1-.146-.2.8.8,0,0,1-.045-.315c0-.146.014-.322.143-.389a.4.4,0,0,1,.211-.028l.664.034a.323.323,0,0,1,.141.027.327.327,0,0,1,.14.218,2.259,2.259,0,0,1,.067.721.192.192,0,0,0,.1.179c.108.077.129.035.128.174a1.124,1.124,0,0,0,.15.668,5.071,5.071,0,0,0,.2-.475l-.51-.068c0-.12.143-.181.17-.292-.358-.034-.066-.591-.129-.782a.4.4,0,0,0-.347-.228,3.889,3.889,0,0,1-.437-.031,1.186,1.186,0,0,1-.235-.091.4.4,0,0,1-.15-.105c-.032-.042-.031-.123-.076-.153-.157-.1-.269.178-.3.286.1.057.214-.022.3-.063a.647.647,0,0,1,.553.005,1.41,1.41,0,0,0-.129.105c-.053.04-.113.1-.182.091a.937.937,0,0,1-.255-.078.736.736,0,0,0-.195-.073.344.344,0,0,0,.158.291c-.027.055-.091.089-.106.149a.573.573,0,0,1-.011.069c-.015.037-.06.066-.049.1Z" transform="translate(-207.736 -211.505)" fill="#68221a" />
            </g>
            <g id="Group_282" data-name="Group 282" transform="translate(16.02)">
              <path id="Path_1643" data-name="Path 1643" d="M227,217.523l8.422-4.31a.859.859,0,0,0,.407-.78,2.729,2.729,0,0,0-1.129-2.2.882.882,0,0,0-.87-.135l-8.422,4.31.441.22c-.006.042-.011.086-.014.133a2.731,2.731,0,0,0,1.132,2.2c.035.024.071.043.108.063Z" transform="translate(-225.402 -210.036)" fill="#f1a443" />
              <g id="Group_281" data-name="Group 281" transform="translate(0.665 0.192)">
                <path id="Path_1644" data-name="Path 1644" d="M232.839,212.4a.241.241,0,0,0-.2-.052.56.56,0,0,1-.053.024.134.134,0,0,1-.094-.017c-.075-.037-.148-.1-.23-.086a.4.4,0,0,1,.049-.165.142.142,0,0,1,.146-.069c.147.04.085.062.168-.045.048-.063.1-.127.195-.082C232.98,211.988,232.881,212.278,232.839,212.4Z" transform="translate(-226.713 -210.403)" fill="#fcd07f" />
                <path id="Path_1645" data-name="Path 1645" d="M235.007,214.24l.265-.136a.282.282,0,0,1,.048-.168c-.122-.034-.417-.116-.49.053-.039.093.029.145.094.188S235.009,214.221,235.007,214.24Z" transform="translate(-226.954 -210.591)" fill="#fcd07f" />
                <path id="Path_1646" data-name="Path 1646" d="M234.328,210.688c.035-.1.11-.325.032-.44l-.6.31a.447.447,0,0,1,.219.086.142.142,0,0,0,.1.017c.018-.005.034-.018.052-.024A.249.249,0,0,1,234.328,210.688Z" transform="translate(-226.854 -210.248)" fill="#fcd07f" />
                <path id="Path_1647" data-name="Path 1647" d="M228.411,213.738a.248.248,0,0,0,.22-.014c.015-.013.028-.03.044-.041a.145.145,0,0,1,.1-.013.456.456,0,0,0,.257.012.444.444,0,0,1,0,.179.147.147,0,0,1-.123.116c-.159.007-.105-.035-.153.1-.028.078-.063.159-.168.143C228.4,214.191,228.407,213.871,228.411,213.738Z" transform="translate(-226.35 -210.57)" fill="#fcd07f" />
                <path id="Path_1648" data-name="Path 1648" d="M230.826,214.784a.186.186,0,0,0-.165-.017c-.014.007-.024.018-.039.024a.108.108,0,0,1-.075,0,.349.349,0,0,0-.189-.041.32.32,0,0,1,.02-.134.112.112,0,0,1,.107-.071c.118.015.073.04.126-.054.031-.054.068-.11.143-.085C230.891,214.448,230.847,214.685,230.826,214.784Z" transform="translate(-226.534 -210.639)" fill="#fcd07f" />
                <path id="Path_1649" data-name="Path 1649" d="M229.225,217.207l.287-.147a.308.308,0,0,1,.065-.082c-.083-.055-.287-.187-.38-.076-.051.061-.014.116.025.165.068.083.062.032,0,.137Z" transform="translate(-226.422 -210.871)" fill="#fcd07f" />
                <path id="Path_1650" data-name="Path 1650" d="M229.914,212.812a.64.64,0,0,0,.01-.287l-.479.245v0c.064-.017.127.022.189.042a.109.109,0,0,0,.075,0,.371.371,0,0,0,.039-.026A.194.194,0,0,1,229.914,212.812Z" transform="translate(-226.448 -210.463)" fill="#fcd07f" />
                <path id="Path_1651" data-name="Path 1651" d="M230.07,213.678a.2.2,0,0,0,0-.171.38.38,0,0,1-.031-.036.12.12,0,0,1-.006-.078.355.355,0,0,0,.018-.2.344.344,0,0,1,.139,0,.116.116,0,0,1,.086.1c0,.123-.031.08.072.122.061.024.122.054.107.135C230.423,213.7,230.174,213.686,230.07,213.678Z" transform="translate(-226.502 -210.525)" fill="#fcd07f" />
                <path id="Path_1652" data-name="Path 1652" d="M232.857,214.733a.2.2,0,0,0,.164-.078.352.352,0,0,1,.023-.045.12.12,0,0,1,.072-.04.375.375,0,0,0,.2-.07.365.365,0,0,1,.058.137.121.121,0,0,1-.06.126c-.119.054-.091.005-.087.123,0,.069,0,.141-.085.162C232.988,215.083,232.894,214.836,232.857,214.733Z" transform="translate(-226.769 -210.649)" fill="#fcd07f" />
                <path id="Path_1653" data-name="Path 1653" d="M233.952,211.273c-.054.019-.115.045-.131.1a.234.234,0,0,1-.011.043.107.107,0,0,1-.057.05c-.059.028-.13.043-.164.1a.329.329,0,0,1-.077-.11.109.109,0,0,1,.029-.123c.1-.071.08-.022.053-.126-.015-.06-.027-.125.045-.159C233.769,210.987,233.9,211.188,233.952,211.273Z" transform="translate(-226.83 -210.322)" fill="#fcd07f" />
                <path id="Path_1654" data-name="Path 1654" d="M227.579,216.013a.33.33,0,0,1-.044.035.048.048,0,0,1-.053,0,.071.071,0,0,1-.014-.025.179.179,0,0,1-.019-.105c.012-.052.063-.082.108-.108.074-.043.2-.154.283-.037.011.015.016.034.027.05a.1.1,0,0,0,.1.047c.024,0,.046-.014.07-.018a.054.054,0,0,1,.06.028c.009.026-.014.052-.036.07-.072.06-.149.119-.243.091S227.673,215.94,227.579,216.013Z" transform="translate(-226.259 -210.764)" fill="#fcd07f" />
                <path id="Path_1655" data-name="Path 1655" d="M227.161,216.995a.4.4,0,0,1,.043.052.056.056,0,0,1,0,.063.082.082,0,0,1-.029.018.21.21,0,0,1-.122.026c-.061-.011-.1-.07-.13-.121-.054-.085-.189-.226-.055-.329.017-.014.039-.021.057-.034a.125.125,0,0,0,.05-.12c0-.027-.018-.053-.024-.081a.064.064,0,0,1,.03-.072c.031-.012.062.015.083.04.073.082.146.168.118.281S227.072,216.889,227.161,216.995Z" transform="translate(-226.199 -210.827)" fill="#fcd07f" />
                <path id="Path_1656" data-name="Path 1656" d="M230.75,212.589a.312.312,0,0,1-.048.037.05.05,0,0,1-.057,0,.074.074,0,0,1-.014-.026.2.2,0,0,1-.019-.112c.014-.053.068-.085.116-.112.08-.045.213-.16.3-.035.012.016.017.036.028.053a.11.11,0,0,0,.105.05c.025,0,.049-.014.074-.017a.057.057,0,0,1,.063.03c.009.029-.016.055-.04.074a.286.286,0,0,1-.259.093C230.91,212.594,230.85,212.513,230.75,212.589Z" transform="translate(-226.557 -210.44)" fill="#fcd07f" />
                <path id="Path_1657" data-name="Path 1657" d="M235.469,213.294a.39.39,0,0,1-.036.048.052.052,0,0,1-.055.013.083.083,0,0,1-.022-.022.148.148,0,0,1,.034-.24c.064-.063.161-.209.277-.113.015.013.026.03.041.043a.109.109,0,0,0,.113.021c.024-.009.043-.027.066-.036a.057.057,0,0,1,.069.012c.016.024,0,.057-.018.081-.057.08-.119.16-.222.159S235.544,213.194,235.469,213.294Z" transform="translate(-227 -210.502)" fill="#fcd07f" />
                <path id="Path_1658" data-name="Path 1658" d="M227.918,214.741a.32.32,0,0,1,.059-.01.051.051,0,0,1,.048.03.067.067,0,0,1,0,.03.2.2,0,0,1-.036.105.2.2,0,0,1-.153.043c-.09,0-.259.039-.276-.111,0-.02,0-.04,0-.059a.107.107,0,0,0-.067-.093c-.023-.009-.049-.01-.072-.02s-.045-.031-.041-.055.041-.04.069-.045a.283.283,0,0,1,.268.04C227.782,214.662,227.8,214.76,227.918,214.741Z" transform="translate(-226.253 -210.653)" fill="#fcd07f" />
                <path id="Path_1659" data-name="Path 1659" d="M226.817,214.4a.192.192,0,0,0,.035-.105.075.075,0,0,0,0-.032c0-.017-.027-.026-.046-.028a.249.249,0,0,0-.059.01.12.12,0,0,1-.11-.028l-.249.127c.024.136.188.1.276.1A.212.212,0,0,0,226.817,214.4Z" transform="translate(-226.16 -210.622)" fill="#fcd07f" />
                <path id="Path_1660" data-name="Path 1660" d="M231.741,213.01a.365.365,0,0,1-.006-.062.052.052,0,0,1,.033-.049.079.079,0,0,1,.033,0,.207.207,0,0,1,.109.043.215.215,0,0,1,.038.163c0,.1.028.275-.13.286-.02,0-.041,0-.062,0a.117.117,0,0,0-.1.066c-.011.024-.014.052-.024.076s-.035.046-.061.04-.04-.044-.044-.075a.3.3,0,0,1,.056-.28C231.652,213.15,231.756,213.141,231.741,213.01Z" transform="translate(-226.643 -210.498)" fill="#fcd07f" />
                <path id="Path_1661" data-name="Path 1661" d="M232.454,211.551a.208.208,0,0,0,.027-.116.085.085,0,0,0,0-.03.048.048,0,0,0-.053-.025.3.3,0,0,0-.061.015.125.125,0,0,1-.136-.035l-.235.12a.293.293,0,0,0,0,.048c.035.152.208.1.3.084A.215.215,0,0,0,232.454,211.551Z" transform="translate(-226.688 -210.353)" fill="#fcd07f" />
                <path id="Path_1662" data-name="Path 1662" d="M231.763,215.2a.219.219,0,0,1,.032.018c.1.064-.006.178-.069.222a.282.282,0,0,1-.255.043c-.092-.034-.225-.214-.11-.278a.1.1,0,0,1,.07-.02.328.328,0,0,1,.041.018.236.236,0,0,0,.135-.018A.236.236,0,0,1,231.763,215.2Z" transform="translate(-226.624 -210.713)" fill="#fcd07f" />
                <path id="Path_1663" data-name="Path 1663" d="M233.838,213.492a.363.363,0,0,1,.034-.022c.116-.06.173.1.183.188a.316.316,0,0,1-.1.275c-.084.073-.332.107-.335-.04a.118.118,0,0,1,.019-.081.4.4,0,0,1,.039-.03c.041-.034.042-.093.055-.144A.265.265,0,0,1,233.838,213.492Z" transform="translate(-226.842 -210.55)" fill="#fcd07f" />
                <path id="Path_1664" data-name="Path 1664" d="M227.99,216.978a.2.2,0,0,1,.007-.04c.035-.125.193-.057.263-.005a.319.319,0,0,1,.136.259c0,.111-.149.316-.258.217a.118.118,0,0,1-.046-.069.3.3,0,0,1,.005-.049c0-.053-.041-.094-.068-.139A.269.269,0,0,1,227.99,216.978Z" transform="translate(-226.31 -210.872)" fill="#fcd07f" />
                <path id="Path_1665" data-name="Path 1665" d="M228.985,216.075a.191.191,0,0,1,.033-.019c.108-.052.157.1.164.178a.293.293,0,0,1-.1.251c-.079.065-.309.091-.307-.046a.108.108,0,0,1,.019-.073.345.345,0,0,1,.037-.026c.038-.03.041-.085.054-.131A.252.252,0,0,1,228.985,216.075Z" transform="translate(-226.385 -210.794)" fill="#fcd07f" />
                <path id="Path_1666" data-name="Path 1666" d="M232.141,213.975a.335.335,0,0,1,.017-.031c.063-.092.172.005.215.066a.271.271,0,0,1,.041.246c-.033.09-.206.217-.268.107a.1.1,0,0,1-.019-.067.385.385,0,0,1,.017-.039c.016-.042-.007-.087-.018-.13A.238.238,0,0,1,232.141,213.975Z" transform="translate(-226.7 -210.593)" fill="#fcd07f" />
                <path id="Path_1667" data-name="Path 1667" d="M230.3,215.826l.036,0c.118,0,.1.152.072.226a.288.288,0,0,1-.19.183c-.1.025-.311-.048-.253-.168a.1.1,0,0,1,.047-.057.241.241,0,0,1,.044-.009c.045-.011.071-.058.1-.093A.245.245,0,0,1,230.3,215.826Z" transform="translate(-226.496 -210.773)" fill="#fcd07f" />
                <path id="Path_1668" data-name="Path 1668" d="M235.649,210.763c.093.039.209.1.057.178a.267.267,0,0,1-.284-.088c-.024-.034-.043-.082-.084-.085s-.091.07-.137.05a.186.186,0,0,1,.335-.137.15.15,0,0,0,.045.053Z" transform="translate(-226.99 -210.281)" fill="#fcd07f" />
                <path id="Path_1669" data-name="Path 1669" d="M230.977,216.3l.178-.091c-.03-.016-.037-.058-.05-.092a.269.269,0,0,0-.26-.148c-.165.048-.064.129.018.187l.061.044a.144.144,0,0,1,.032.061A.169.169,0,0,0,230.977,216.3Z" transform="translate(-226.571 -210.787)" fill="#fcd07f" />
                <path id="Path_1670" data-name="Path 1670" d="M234.806,211.145c.064-.082.155-.183.195-.008a.28.28,0,0,1-.168.262c-.041.015-.094.02-.109.062s.045.111.012.151a.194.194,0,0,1-.044-.375.15.15,0,0,0,.065-.031C234.77,211.192,234.787,211.17,234.806,211.145Z" transform="translate(-226.93 -210.323)" fill="#fcd07f" />
                <path id="Path_1671" data-name="Path 1671" d="M236.121,212.107c-.043.075-.105.168-.158.031a.23.23,0,0,1,.105-.234c.031-.016.074-.027.081-.062s-.049-.084-.027-.121a.163.163,0,0,1,.159.118.166.166,0,0,1-.079.182.126.126,0,0,0-.05.033C236.144,212.065,236.134,212.085,236.121,212.107Z" transform="translate(-227.061 -210.387)" fill="#fcd07f" />
                <path id="Path_1672" data-name="Path 1672" d="M226.482,215.111c.092-.053.216-.113.187.066a.284.284,0,0,1-.257.183c-.044,0-.1-.017-.126.016s0,.12-.047.147a.2.2,0,0,1,.1-.37.143.143,0,0,0,.073,0C226.43,215.141,226.455,215.127,226.482,215.111Z" transform="translate(-226.136 -210.701)" fill="#fcd07f" />
                <path id="Path_1673" data-name="Path 1673" d="M234.2,212.4c.047-.088.116-.2.181-.039a.267.267,0,0,1-.116.272c-.035.021-.085.034-.092.075s.06.1.035.139a.184.184,0,0,1-.1-.344.146.146,0,0,0,.056-.039C234.173,212.451,234.185,212.427,234.2,212.4Z" transform="translate(-226.878 -210.44)" fill="#fcd07f" />
                <path id="Path_1674" data-name="Path 1674" d="M229.3,214.885c-.043-.072-.092-.166.047-.147a.222.222,0,0,1,.148.2c0,.034-.012.075.014.1s.094,0,.115.034a.153.153,0,0,1-.289-.072.117.117,0,0,0,0-.056C229.321,214.924,229.308,214.905,229.3,214.885Z" transform="translate(-226.429 -210.671)" fill="#fcd07f" />
              </g>
            </g>
            <path id="Path_1675" data-name="Path 1675" d="M227.365,217.189a2.73,2.73,0,0,0-1.131-2.2c-.664-.43-1.235-.141-1.274.643a2.735,2.735,0,0,0,1.13,2.2C226.755,218.263,227.326,217.975,227.365,217.189Z" transform="translate(-209.34 -210.484)" fill="#f1f4f4" />
          </g>
          <g id="Group_284" data-name="Group 284" transform="translate(56.365 38.509)">
            <path id="Path_1676" data-name="Path 1676" d="M226.745,218.8l-.014.031c.1.2.159.331.159.331Z" transform="translate(-226.731 -218.796)" fill="#f1f4f4" />
          </g>
          <path id="Path_1677" data-name="Path 1677" d="M223.721,218.237s.034,1.65-.525,1.957c.823.925,1.6-.82,1.6-.82Z" transform="translate(-170.033 -180.234)" fill="#3c6868" />
          <path id="Path_1678" data-name="Path 1678" d="M225.61,217.4l-1.834.726v.03a6.308,6.308,0,0,1,1.03,1.895,2.587,2.587,0,0,0,1.647-1.358A6.312,6.312,0,0,0,225.61,217.4Z" transform="translate(-170.087 -180.156)" fill="#f1f4f4" />
          <path id="Path_1679" data-name="Path 1679" d="M223.494,219.539c-.02.173-.048.364-.077.546a4.962,4.962,0,0,1-.479,1.533c-.019-.019-.134-.114-.306-.268s-.4-.335-.642-.526v-.01l.91-.469a.215.215,0,0,0,.134-.01.183.183,0,0,0,.076-.038.461.461,0,0,0,.077-.058.521.521,0,0,0,.067-.076.985.985,0,0,0,.086-.154,1.743,1.743,0,0,0,.134-.382A.275.275,0,0,1,223.494,219.539Z" transform="translate(-169.919 -180.357)" fill="#3c6868" />
        </g>
      </g>
    </svg>
  );
}

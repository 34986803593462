import styled from 'styled-components';

export const DWBRiskDepartmentStyled = styled.div`
  .bmi_department_head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 16px;
    .bmi_department_head_col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 30px;
      row-gap: 20px;
      .chart_status_block {
        display: flex;
        .item_status {
          &.ml_11 {
            margin-left: 11px;
          }
          &.ml_29 {
            margin-left: 11px;
          }
        }
      }
      .filter_block {
        display: flex;
        justify-content: flex-end;
        .item_filter {
          &.w_200 {
            width: 200px;
            flex-shrink: 0;
          }
          &.w_152 {
            width: 152px;
            flex-shrink: 0;
          }
          &.ml_14 {
            margin-left: 14px;
          }
          &.ml_16 {
            margin-left: 14px;
          }
        }
      }
    }
  }
  .chart_container {
    padding-top: 25px;
    .label_empty {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
    .chart_wrap {
      position: relative;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0px;
      }
      .c_row_2_col {
        display: flex;
        flex-wrap: wrap;
        column-gap: 35px;
        .bar_item {
          width: calc(50% - 18px);
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          &:last-child {
            // margin-bottom: 0px;
          }
          .label {
            width: 27%;
            flex-shrink: 0;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .percent_bar {
            flex: 1;
            background: ${({ theme }) => theme.COLORS.GRAY_2};
            display: flex;
            min-height: 32px;
            .stack_item {
              text-align: center;
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              padding: 4px 0;
              color: white;
            }
          }
        }
      }
    }
  }
`;

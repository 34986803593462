export default function Building2({ color = '#575d96', width = "33.999", height = "34" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="34" viewBox="0 0 33.999 34">
      <g id="company_1_" data-name="company (1)" transform="translate(-0.005 0)">
        <g id="Group_13981" data-name="Group 13981" transform="translate(0.005 0)">
          <g id="Group_13980" data-name="Group 13980">
            <path id="Path_19101" data-name="Path 19101" d="M33.008,32.007H31.946V15.074a1,1,0,0,0-.681-.945L19.993,10.372v-3.4a1,1,0,0,0-.646-.933L3.409.064A1,1,0,0,0,2.064,1V32.007H1A1,1,0,0,0,1,34H33.008a1,1,0,0,0,0-1.992Zm-22.976,0H8.04V28.023h1.992Zm3.984,0H12.024V28.023h1.992ZM18,11.09V32.007H16.009v-4.98a1,1,0,0,0-1-1H7.044a1,1,0,0,0-1,1v4.98H4.056V2.434L18,7.663Zm7.969,20.917H23.977V28.023h1.992Zm3.984,0H27.961v-4.98a1,1,0,0,0-1-1H22.981a1,1,0,0,0-1,1v4.98H19.993V12.472l9.961,3.32V32.007Z" transform="translate(-0.005 0)" fill={color} />
          </g>
        </g>
        <g id="Group_13983" data-name="Group 13983" transform="translate(6.048 22.047)">
          <g id="Group_13982" data-name="Group 13982">
            <path id="Path_19102" data-name="Path 19102" d="M93.992,332H92A1,1,0,1,0,92,334h1.992a1,1,0,1,0,0-1.992Z" transform="translate(-91.004 -332.003)" fill={color} />
          </g>
        </g>
        <g id="Group_13985" data-name="Group 13985" transform="translate(12.024 22.047)">
          <g id="Group_13984" data-name="Group 13984">
            <path id="Path_19103" data-name="Path 19103" d="M183.99,332H182A1,1,0,1,0,182,334h1.992a1,1,0,0,0,0-1.992Z" transform="translate(-181.002 -332.003)" fill={color} />
          </g>
        </g>
        <g id="Group_13987" data-name="Group 13987" transform="translate(6.048 18.062)">
          <g id="Group_13986" data-name="Group 13986">
            <path id="Path_19104" data-name="Path 19104" d="M93.992,272.005H92A1,1,0,0,0,92,274h1.992a1,1,0,1,0,0-1.992Z" transform="translate(-91.004 -272.005)" fill={color} />
          </g>
        </g>
        <g id="Group_13989" data-name="Group 13989" transform="translate(12.024 18.062)">
          <g id="Group_13988" data-name="Group 13988">
            <path id="Path_19105" data-name="Path 19105" d="M183.99,272.005H182A1,1,0,0,0,182,274h1.992a1,1,0,0,0,0-1.992Z" transform="translate(-181.002 -272.005)" fill={color} />
          </g>
        </g>
        <g id="Group_13991" data-name="Group 13991" transform="translate(21.985 22.047)">
          <g id="Group_13990" data-name="Group 13990">
            <path id="Path_19106" data-name="Path 19106" d="M335.978,332h-3.984a1,1,0,1,0,0,1.992h3.984a1,1,0,1,0,0-1.992Z" transform="translate(-330.998 -332.003)" fill={color} />
          </g>
        </g>
        <g id="Group_13993" data-name="Group 13993" transform="translate(21.985 18.062)">
          <g id="Group_13992" data-name="Group 13992">
            <path id="Path_19107" data-name="Path 19107" d="M335.978,272.005h-3.984a1,1,0,0,0,0,1.992h3.984a1,1,0,0,0,0-1.992Z" transform="translate(-330.998 -272.005)" fill={color} />
          </g>
        </g>
        <g id="Group_13995" data-name="Group 13995" transform="translate(6.048 14.078)">
          <g id="Group_13994" data-name="Group 13994">
            <path id="Path_19108" data-name="Path 19108" d="M93.992,212.006H92A1,1,0,1,0,92,214h1.992a1,1,0,1,0,0-1.992Z" transform="translate(-91.004 -212.006)" fill={color} />
          </g>
        </g>
        <g id="Group_13997" data-name="Group 13997" transform="translate(12.024 14.078)">
          <g id="Group_13996" data-name="Group 13996">
            <path id="Path_19109" data-name="Path 19109" d="M183.99,212.006H182A1,1,0,1,0,182,214h1.992a1,1,0,0,0,0-1.992Z" transform="translate(-181.002 -212.006)" fill={color} />
          </g>
        </g>
        <g id="Group_13999" data-name="Group 13999" transform="translate(6.048 10.094)">
          <g id="Group_13998" data-name="Group 13998">
            <path id="Path_19110" data-name="Path 19110" d="M93.992,152.007H92A1,1,0,1,0,92,154h1.992a1,1,0,1,0,0-1.992Z" transform="translate(-91.004 -152.007)" fill={color} />
          </g>
        </g>
        <g id="Group_14001" data-name="Group 14001" transform="translate(12.024 10.094)">
          <g id="Group_14000" data-name="Group 14000">
            <path id="Path_19111" data-name="Path 19111" d="M183.99,152.007H182A1,1,0,1,0,182,154h1.992a1,1,0,0,0,0-1.992Z" transform="translate(-181.002 -152.007)" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from "styled-components";

export const MissionContainerStyled = styled.div`
  background: #fff;
  padding: 20px 40px;
  min-height: calc(100vh - 235px);
  border-radius: 18px;
  .title_layout {
    margin-bottom: 52px;
    display: flex;
    row-gap: 17px;
    flex-direction: column;
  }
`;

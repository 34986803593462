import styled from 'styled-components';

export const NewUserStyled = styled.div`
  .bottom_current {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .img_show {
      width: 148px;
    }
  }
`;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { BtnIconStyled } from "./styled";

const BtnIcon = ({
  theme_btn_icon,
  theme_btn_icon_delete,
  label,
  labelBottom,
  svgFront,
  svgBack,
  fontSize,
  imgWidth,
  imgHeight,
  fontFamily,
  onClick,
  padding,
  disabled,
  type = "button",
}) => {
  const customClass = cx({
    theme_btn_icon: theme_btn_icon,
    theme_btn_icon_delete: theme_btn_icon_delete,
  });
  return (
    <BtnIconStyled
      fontSize={fontSize}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      fontFamily={fontFamily}
      padding={padding}
      disabled={disabled}
    >
      <div className={customClass}>
        <button
          className="body_btn"
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {svgFront && <div className="svg_show">{svgFront}</div>}
          <div className="show_label">{label}</div>
          {svgBack && <div className="svg_show_back">{svgBack}</div>}
        </button>
        {labelBottom && <div className="label_bottom">{labelBottom}</div>}
      </div>
    </BtnIconStyled>
  );
};

BtnIcon.propTypes = {};

export default BtnIcon;

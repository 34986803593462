import styled from 'styled-components';

export const UserPercentageStyled = styled.div`
  font-family: noto_sans_thai_regular, noto_sans_regular;
  font-size: ${({ theme }) => theme.FONT.SIZE.S10};
  color: ${({ theme }) => theme.COLORS.BLACK_3};
  .bottom_current {
    display: flex;
    margin: auto;
    margin-top: 2px;
    width: 160px;
    flex-direction: column;
    .show_svg {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .group_label {
      display: flex;
      margin-top: 7px;
      .left_group {
        width: 50%;
        .sub_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S7};
        }
      }
      .right_group {
        width: 50%;
        display: flex;
        align-items: center;
        .num_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: noto_sans_thai_bold, noto_sans_bold;
          width: 50%;
          text-align: center;
        }
        .group_label_2 {
          padding-left: 18px;
          width: 50%;
          .sub_label_2 {
            font-size: ${({ theme }) => theme.FONT.SIZE.S8};
          }
        }
      }
    }
  }
`;

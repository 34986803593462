export default function Cook() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38.535" height="40" viewBox="0 0 38.535 40">
      <g id="salad_2_" data-name="salad (2)" transform="translate(-9.374 0.001)">
        <path id="Path_20014" data-name="Path 20014" d="M124.366,174.426a4.819,4.819,0,0,1,0-6.832,1.107,1.107,0,0,1,1.561,0l5.307,5.279a1.094,1.094,0,0,1,0,1.553A4.879,4.879,0,0,1,124.366,174.426Z" transform="translate(-104.699 -154.205)" fill="#f9ebcf" />
        <path id="Path_20015" data-name="Path 20015" d="M164.268,185.151a2.243,2.243,0,0,0,0,3.183,2.271,2.271,0,0,0,3.2,0,2.245,2.245,0,0,0,.182-.207l-3.173-3.157A2.3,2.3,0,0,0,164.268,185.151Z" transform="translate(-142.182 -170.52)" fill="#ffcc60" />
        <path id="Path_20016" data-name="Path 20016" d="M28.538,214.927a2.562,2.562,0,0,0-2.657-.336,2.6,2.6,0,0,0-.335-2.671c-.669-.672-1.829-.7-3.065-.186a2.908,2.908,0,0,0-3.727.337,2.947,2.947,0,0,0-.335,3.747c-.508,1.242-.484,2.408.185,3.081a2.561,2.561,0,0,0,2.657.336A2.6,2.6,0,0,0,21.6,221.9a2.562,2.562,0,0,0,2.657.336,2.6,2.6,0,0,0,.335,2.671c1.107,1.113,3.558.453,5.475-1.474s2.573-4.391,1.466-5.5a2.561,2.561,0,0,0-2.657-.337A2.6,2.6,0,0,0,28.538,214.927Z" transform="translate(-7.86 -194.711)" fill="#78b591" />
        <path id="Path_20017" data-name="Path 20017" d="M55.488,249.667a.63.63,0,0,1-.448-.186l-5.332-5.36a.632.632,0,1,1,.9-.891l5.332,5.36a.631.631,0,0,1-.448,1.077Z" transform="translate(-37.014 -224.057)" fill="#619376" />
        <path id="Path_20018" data-name="Path 20018" d="M113.341,226.6a4.29,4.29,0,0,1-5.8,1.844.986.986,0,0,1-.421-1.324l3.046-5.927a.974.974,0,0,1,1.317-.419A4.345,4.345,0,0,1,113.341,226.6Z" transform="translate(-90.013 -203.423)" fill="#ef645e" />
        <g id="Group_14364" data-name="Group 14364" transform="translate(18.215 18.063)">
          <path id="Path_20019" data-name="Path 20019" d="M123.249,308.1a.629.629,0,0,1-.291-.072l-.087-.045a.632.632,0,0,1,.584-1.12l.087.045a.632.632,0,0,1-.292,1.192Z" transform="translate(-122.53 -300.883)" fill="#c14616" />
          <path id="Path_20020" data-name="Path 20020" d="M162.141,232.559a.632.632,0,0,1-.561-.92l.045-.087a.632.632,0,1,1,1.124.577l-.045.087A.631.631,0,0,1,162.141,232.559Z" transform="translate(-158.465 -231.209)" fill="#c14616" />
          <path id="Path_20021" data-name="Path 20021" d="M143.08,288.721a.63.63,0,0,1-.291-.071l-.087-.045a.632.632,0,0,1,.583-1.121l.087.045a.632.632,0,0,1-.292,1.192Z" transform="translate(-140.813 -283.021)" fill="#c14616" />
          <path id="Path_20022" data-name="Path 20022" d="M157.958,262.523a.63.63,0,0,1-.291-.071l-.087-.045a.632.632,0,0,1,.582-1.121l.087.045a.632.632,0,0,1-.292,1.192Z" transform="translate(-154.528 -258.87)" fill="#c14616" />
        </g>
        <ellipse id="Ellipse_1800" data-name="Ellipse 1800" cx="2.938" cy="2.923" rx="2.938" ry="2.923" transform="translate(34.958 15.793)" fill="#ef645e" />
        <path id="Path_20023" data-name="Path 20023" d="M325.324,244.339a2.644,2.644,0,0,0,.347-2.743,2.679,2.679,0,0,0,2.757-.345c.694-.69.719-1.888.192-3.164a3,3,0,0,0-.348-3.847,3.041,3.041,0,0,0-3.867-.346c-1.282-.524-2.486-.5-3.18.191a2.644,2.644,0,0,0-.347,2.743,2.68,2.68,0,0,0-2.757.345,2.645,2.645,0,0,0-.347,2.743,2.68,2.68,0,0,0-2.757.346c-1.149,1.143-.468,3.673,1.521,5.652s4.532,2.656,5.681,1.513a2.644,2.644,0,0,0,.347-2.743A2.68,2.68,0,0,0,325.324,244.339Z" transform="translate(-281.251 -215.127)" fill="#78b591" />
        <path id="Path_20024" data-name="Path 20024" d="M382.754,272.657a.666.666,0,0,1-.47-1.138l5.532-5.5a.666.666,0,1,1,.94.944l-5.532,5.5A.664.664,0,0,1,382.754,272.657Z" transform="translate(-343.596 -245.054)" fill="#5b8c6f" />
        <ellipse id="Ellipse_1801" data-name="Ellipse 1801" cx="2.938" cy="2.923" rx="2.938" ry="2.923" transform="translate(14.325 22.929)" fill="#ffab5f" />
        <path id="Path_20025" data-name="Path 20025" d="M222.419,181.088a2.732,2.732,0,0,0-2.81-.352,2.7,2.7,0,0,0-.354-2.8c-.707-.7-1.934-.729-3.241-.2a3.1,3.1,0,0,0-3.941.353,3.06,3.06,0,0,0-.354,3.921c-.537,1.3-.511,2.521.2,3.224a2.732,2.732,0,0,0,2.81.352,2.7,2.7,0,0,0,.354,2.8,2.732,2.732,0,0,0,2.81.352,2.7,2.7,0,0,0,.354,2.8c1.171,1.165,3.763.474,5.79-1.542s2.721-4.6,1.55-5.76a2.732,2.732,0,0,0-2.81-.352A2.7,2.7,0,0,0,222.419,181.088Z" transform="translate(-186.029 -163.355)" fill="#a5bc79" />
        <path id="Path_20026" data-name="Path 20026" d="M152.8,45.69a4.344,4.344,0,0,1-6.114,0,.974.974,0,0,1,0-1.382l4.724-4.7a.986.986,0,0,1,1.389,0A4.29,4.29,0,0,1,152.8,45.69Z" transform="translate(-126.317 -36.25)" fill="#ef645e" />
        <ellipse id="Ellipse_1802" data-name="Ellipse 1802" cx="2.074" cy="2.063" rx="2.074" ry="2.063" transform="translate(31.401 7.444)" fill="#ffab5f" />
        <path id="Path_20027" data-name="Path 20027" d="M333.591,1.455a.729.729,0,0,1,0,1.035l-1.248,1.241a.738.738,0,0,1-1.04,0L330.055,2.49a.729.729,0,0,1,0-1.035L331.3.214a.738.738,0,0,1,1.04,0Z" transform="translate(-295.43)" fill="#c14616" />
        <path id="Path_20028" data-name="Path 20028" d="M64.667,124.726a.589.589,0,0,1,0,.836l-1.009,1a.6.6,0,0,1-.84,0l-1.008-1a.589.589,0,0,1,0-.836l1.009-1a.6.6,0,0,1,.84,0Z" transform="translate(-48.179 -113.898)" fill="#a5bc79" />
        <path id="Path_20029" data-name="Path 20029" d="M400.692,148.951a.589.589,0,0,1,0,.836l-1.009,1a.6.6,0,0,1-.84,0l-1.009-1a.589.589,0,0,1,0-.836l1.009-1a.6.6,0,0,1,.84,0Z" transform="translate(-357.952 -136.23)" fill="#c14616" />
        <path id="Path_20030" data-name="Path 20030" d="M251.346,217.764a.63.63,0,0,1-.445-.184l-5.638-5.61a.632.632,0,1,1,.891-.9l5.638,5.61a.631.631,0,0,1-.445,1.079Z" transform="translate(-217.288 -194.416)" fill="#899b62" />
        <path id="Path_20031" data-name="Path 20031" d="M24.367,309.287a.914.914,0,0,1,0,1.3L22.8,312.142a.926.926,0,0,1-1.3,0l-1.565-1.557a.915.915,0,0,1,0-1.3L21.5,307.73a.926.926,0,0,1,1.3,0Z" transform="translate(-9.484 -283.441)" fill="#ef645e" />
        <path id="Path_20032" data-name="Path 20032" d="M140.224,266.8a4.344,4.344,0,0,1-6.114,0,.974.974,0,0,1,0-1.382l4.724-4.7a.986.986,0,0,1,1.389,0A4.29,4.29,0,0,1,140.224,266.8Z" transform="translate(-114.726 -240.086)" fill="#c14616" />
        <g id="Group_14365" data-name="Group 14365" transform="translate(30.812 20.965)">
          <ellipse id="Ellipse_1803" data-name="Ellipse 1803" cx="2.522" cy="2.509" rx="2.522" ry="2.509" fill="#ffab5f" />
          <ellipse id="Ellipse_1804" data-name="Ellipse 1804" cx="2.074" cy="2.063" rx="2.074" ry="2.063" transform="translate(11.16 3.729)" fill="#ffab5f" />
        </g>
        <path id="Path_20033" data-name="Path 20033" d="M320.068,297.32a1.094,1.094,0,0,1,0,1.553l-1.874,1.864a1.108,1.108,0,0,1-1.562,0l-1.874-1.864a1.1,1.1,0,0,1,0-1.553l1.874-1.864a1.108,1.108,0,0,1,1.562,0Z" transform="translate(-281.228 -272.078)" fill="#ef645e" />
        <g id="Group_14366" data-name="Group 14366" transform="translate(21.122 4.14)">
          <path id="Path_20034" data-name="Path 20034" d="M160.444,114.058a.63.63,0,0,1-.445-.184l-.069-.069a.632.632,0,0,1,.891-.9l.069.069a.632.632,0,0,1-.445,1.079Z" transform="translate(-159.743 -108.06)" fill="#c14616" />
          <path id="Path_20035" data-name="Path 20035" d="M220.4,54.335a.632.632,0,0,1-.445-1.079l.07-.069a.632.632,0,1,1,.891.9l-.069.069A.63.63,0,0,1,220.4,54.335Z" transform="translate(-215.082 -53.003)" fill="#c14616" />
          <path id="Path_20036" data-name="Path 20036" d="M185.344,101.671a.63.63,0,0,1-.445-.184l-.069-.069a.632.632,0,0,1,.891-.9l.069.069a.632.632,0,0,1-.445,1.079Z" transform="translate(-182.698 -96.641)" fill="#c14616" />
          <path id="Path_20037" data-name="Path 20037" d="M207.576,81.32a.629.629,0,0,1-.446-.184l-.07-.069a.632.632,0,0,1,.892-.895l.069.069a.632.632,0,0,1-.446,1.079Z" transform="translate(-203.193 -77.88)" fill="#c14616" />
        </g>
        <g id="Group_14367" data-name="Group 14367" transform="translate(20.14 21.414)">
          <path id="Path_20038" data-name="Path 20038" d="M147.873,335.166a.63.63,0,0,1-.445-.184l-.07-.069a.632.632,0,0,1,.891-.9l.069.069a.632.632,0,0,1-.445,1.079Z" transform="translate(-147.172 -329.168)" fill="#a03615" />
          <path id="Path_20039" data-name="Path 20039" d="M207.831,275.443a.632.632,0,0,1-.446-1.079l.07-.069a.632.632,0,1,1,.891.9l-.07.069A.629.629,0,0,1,207.831,275.443Z" transform="translate(-202.509 -274.111)" fill="#a03615" />
          <path id="Path_20040" data-name="Path 20040" d="M172.773,322.78a.63.63,0,0,1-.446-.184l-.07-.069a.632.632,0,1,1,.891-.9l.069.069a.632.632,0,0,1-.445,1.079Z" transform="translate(-170.126 -317.75)" fill="#a03615" />
          <path id="Path_20041" data-name="Path 20041" d="M195.006,302.427a.629.629,0,0,1-.445-.184l-.069-.069a.632.632,0,1,1,.891-.9l.069.069a.632.632,0,0,1-.445,1.079Z" transform="translate(-190.623 -298.987)" fill="#a03615" />
        </g>
        <path id="Path_20042" data-name="Path 20042" d="M220.155,272.313a4.819,4.819,0,0,1,0-6.832,1.107,1.107,0,0,1,1.561,0l5.307,5.279a1.094,1.094,0,0,1,0,1.553A4.879,4.879,0,0,1,220.155,272.313Z" transform="translate(-193.005 -244.444)" fill="#fff" />
        <path id="Path_20043" data-name="Path 20043" d="M260.057,283.039a2.243,2.243,0,0,0,0,3.183,2.271,2.271,0,0,0,3.2,0,2.32,2.32,0,0,0,.182-.207l-3.173-3.157A2.285,2.285,0,0,0,260.057,283.039Z" transform="translate(-230.488 -260.76)" fill="#ffcc60" />
        <path id="Path_20044" data-name="Path 20044" d="M20.555,356.453a11.34,11.34,0,0,1-7.386-2.73,11.208,11.208,0,0,1-3.783-6.8.974.974,0,0,1,.226-.784.984.984,0,0,1,.745-.34H46.474a.984.984,0,0,1,.745.34.974.974,0,0,1,.226.784,11.208,11.208,0,0,1-3.783,6.8,11.34,11.34,0,0,1-7.386,2.73Z" transform="translate(0 -318.783)" fill="#f9ebcf" />
        <g id="Group_14368" data-name="Group 14368" transform="translate(9.582 29.121)">
          <path id="Path_20045" data-name="Path 20045" d="M21.435,372.763H12.027a11.124,11.124,0,0,0,.392,1.261h9.016a.63.63,0,1,0,0-1.261Z" transform="translate(-12.027 -372.763)" fill="#ddb690" />
          <path id="Path_20046" data-name="Path 20046" d="M33.277,410.3H27.214a11.236,11.236,0,0,0,.851,1.261h5.212a.63.63,0,1,0,0-1.261Z" transform="translate(-26.028 -407.367)" fill="#ddb690" />
        </g>
        <path id="Path_20047" data-name="Path 20047" d="M12.913,346.922a.974.974,0,0,1,.226-.784.984.984,0,0,1,.745-.34H10.358a.984.984,0,0,0-.745.34.974.974,0,0,0-.226.784,11.208,11.208,0,0,0,3.783,6.8,11.34,11.34,0,0,0,7.386,2.73h3.526a11.34,11.34,0,0,1-7.386-2.73A11.208,11.208,0,0,1,12.913,346.922Z" transform="translate(-0.001 -318.783)" opacity="0.1" />
        <path id="Path_20048" data-name="Path 20048" d="M137.358,482.619a.834.834,0,0,1-.834.834H116.759a.834.834,0,0,1-.834-.834v-.751a.834.834,0,0,1,.834-.834h19.765a.834.834,0,0,1,.834.834Z" transform="translate(-98.226 -443.454)" fill="#a5bc79" />
        <path id="Path_20049" data-name="Path 20049" d="M119.44,482.619v-.751a.834.834,0,0,1,.834-.834h-3.513a.834.834,0,0,0-.834.834v.751a.834.834,0,0,0,.834.834h3.513A.834.834,0,0,1,119.44,482.619Z" transform="translate(-98.228 -443.454)" opacity="0.1" />
      </g>
    </svg>
  );
}

export default function renderIcon({
  color = "#002366",
  width = "23.353",
  height = "20.295",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        id="cardiology_1_"
        data-name="cardiology (1)"
        transform="translate(0.5 -34.517)"
      >
        <path
          id="Path_19112"
          data-name="Path 19112"
          d="M7.483,44.951,9.01,42.058a.655.655,0,0,1,1.209.125l1.4,4.884,1.248-1.829a.655.655,0,0,1,.541-.286h8.106a6.694,6.694,0,0,0-10.338-8.214A6.693,6.693,0,0,0,.838,44.951Z"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
        <path
          id="Path_19113"
          data-name="Path 19113"
          d="M52.806,245.422l-1.839,2.7a.655.655,0,0,1-1.171-.188l-1.336-4.66-.951,1.8a.655.655,0,0,1-.579.349h-6.1q.178.193.372.372l7.8,7.2a1.8,1.8,0,0,0,2.486-.04l7.758-7.162q.194-.179.372-.372Z"
          transform="translate(-39.051 -199.161)"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}

import { TotalEmployeeHealthCheckupStyled } from "./styled";
// import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
// import theme from "styles/theme.json";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Charts } from "components";

const TotalEmployeeHealthCheckup = ({ data }) => {
  const [_data, _setData] = useState();

  useEffect(() => {
    if (data) {
      _setData({
        ...data,
        percent:
          data.employee_count_total > 0
            ? Number(
                (
                  (data.employee_count_with_checkup /
                    data.employee_count_total) *
                  100
                ).toFixed(0)
              )
            : 0,
      });
    }
  }, [data]);

  return (
    <TotalEmployeeHealthCheckupStyled>
      <div className="box_shadow_total">
        <div className="box_total_emp">
          <div className="b_text_emp">
            พนักงานทั้งหมดที่มีผลตรวจสุขภาพประจำปีนี้
          </div>
          <div className="s_text_emp">
            Total employees who already have annual health check
          </div>
          <div className="graph_show">
            {/* <Chart
              type="radialBar"
              width="400"
              options={{
                chart: {
                  id: "apexchart-example",
                  fontFamily: theme.FONT.STYLE.BOLD,
                  toolbar: {
                    show: false,
                  },
                },
                // dataLabels: {
                //   formatter: function (val, opts) {
                //     return `${Number(val).toFixed(0)}%`
                //   },
                // },

                // tooltip: {
                //   y: {
                //     formatter: function (
                //       value,
                //       { series, seriesIndex, dataPointIndex, w }
                //     ) {
                //       return `${value}%`;
                //     },
                //   },
                // },
                legend: {
                  show: false,
                },
                plotOptions: {
                  radialBar: {
                    hollow: {
                      size: "40%",
                    },
                    dataLabels: {
                      name: {
                        show: false,
                        color: "#fff",
                      },
                      value: {
                        show: _data ? true : false,
                        color: "#002366",
                        offsetY: 10,
                        fontSize: "24px",
                        fontFamily: theme.FONT.STYLE.BOLD,
                      },
                    },
                    track: {
                      background: _data ? "#79A6FF" : "#989898",
                      strokeWidth: "100%",
                    },
                  },
                },
                colors: ["#002366"],
                // labels: ['ข้อเท้า เท้า'],
              }}
              // series={
              //   _data &&
              //     _data.nosymptom_user_count &&
              //     _data.nosymptom_user_count > 0
              //     ? [
              //       (
              //         (_data.nosymptom_user_count /
              //           _data.bos_user_count) *
              //         100
              //       ).toFixed(0),
              //     ]
              //     : [0]
              // }
              series={_data ? [_data.percent] : [0]}
            /> */}
            <Charts.RadialBar value={_data ? _data.percent : 0} /> 
          </div>
          <div className="big_num">
            {`${
              _data
                ? RenderCommaMoney(
                    Number(_data.employee_count_with_checkup).toFixed(0)
                  )
                : "0"
            } คน`}
          </div>
          <div className="small_num">
            {`จากทั้งหมดในระบบ ${
              _data
                ? RenderCommaMoney(
                    Number(_data.employee_count_total).toFixed(0)
                  )
                : "0"
            } คน`}
          </div>
        </div>
      </div>
    </TotalEmployeeHealthCheckupStyled>
  );
};

export default TotalEmployeeHealthCheckup;

export default function renderIcon({ color = '#4E5B7E' }) {
  return (
    <svg id="Group_57590" data-name="Group 57590" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="123.806" height="123.792" viewBox="0 0 123.806 123.792">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_26325" data-name="Rectangle 26325" width="123.761" height="123.761" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectangle_26326" data-name="Rectangle 26326" width="123.806" height="123.792" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_57587" data-name="Group 57587" transform="translate(0.044 0)">
        <g id="Group_57586" data-name="Group 57586" transform="translate(0)" clip-path="url(#clip-path)">
          <path id="Path_97810" data-name="Path 97810" d="M60.359,4.552l10.5,22.106a7.847,7.847,0,0,0,5.878,4.468l23.285,3.517a8.362,8.362,0,0,1,4.305,14.086L87.618,65.657a8.515,8.515,0,0,0-2.284,7.352L89.3,97.028a7.91,7.91,0,0,1-11.293,8.725l-21.037-11.5a7.443,7.443,0,0,0-7.189,0l-21.037,11.5a7.91,7.91,0,0,1-11.293-8.725l3.963-24.012a8.509,8.509,0,0,0-2.283-7.35L2.423,48.739a8.362,8.362,0,0,1,4.3-14.086l23.287-3.518a7.845,7.845,0,0,0,5.878-4.466l10.5-22.115a7.636,7.636,0,0,1,13.972,0" transform="translate(-0.044 0)" fill="#f3d55b" />
        </g>
      </g>
      <g id="Group_57589" data-name="Group 57589" transform="translate(0 0)">
        <g id="Group_57588" data-name="Group 57588" clip-path="url(#clip-path-2)">
          <path id="Path_97811" data-name="Path 97811" d="M123.928,69.175a8.591,8.591,0,0,0-2.312,7.362l3.983,24.009a7.93,7.93,0,0,1-11.312,8.751L93.263,97.77a7.576,7.576,0,0,0-7.184,0L65.056,109.3a7.93,7.93,0,0,1-11.312-8.751l.888-5.3C107.457,63.306,99.24,20.019,94.437,5.188a8.285,8.285,0,0,1,2.207,2.881L107.137,30.2a7.966,7.966,0,0,0,5.906,4.448l23.264,3.52a8.357,8.357,0,0,1,4.305,14.087Z" transform="translate(-36.298 -3.516)" fill="#f0c419" />
          <path id="Path_97812" data-name="Path 97812" d="M240.482,206.291a34.149,34.149,0,1,1-34.149-34.15,34.149,34.149,0,0,1,34.149,34.15" transform="translate(-116.676 -116.648)" fill="#ff5364" />
          <path id="Path_97813" data-name="Path 97813" d="M228.3,272.079a2.135,2.135,0,0,0-3.017,0l-4.895,4.895-4.894-4.895a2.135,2.135,0,0,0-3.018,3.018l4.895,4.894-4.895,4.895A2.134,2.134,0,1,0,215.5,287.9l4.894-4.894,4.895,4.894a2.134,2.134,0,0,0,3.017-3.017l-4.894-4.895L228.3,275.1a2.134,2.134,0,0,0,0-3.018" transform="translate(-143.54 -183.945)" fill="#fff" />
          <path id="Path_97814" data-name="Path 97814" d="M297.325,248.368H283.368a39.936,39.936,0,0,1,5.846-5.336c4.8-3.88,10.245-8.281,10.245-13.873a10.672,10.672,0,0,0-21.343,0,2.134,2.134,0,1,0,4.268,0,6.4,6.4,0,1,1,12.807,0c0,3.556-4.6,7.273-8.664,10.554-4.324,3.5-8.411,6.8-8.411,10.789a2.134,2.134,0,0,0,2.134,2.134h17.074a2.134,2.134,0,1,0,0-4.269" transform="translate(-188.459 -148.054)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}

export default function renderIcon({
  color = "#002366",
  width = "24",
  height = "24",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99776 3.99683H5.99693C4.8919 3.99683 3.99609 4.89263 3.99609 5.99766V19.0031C3.99609 20.1081 4.8919 21.0039 5.99693 21.0039H11.9994" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <rect x="7.99805" y="1.99585" width="8.00333" height="4.00167" rx="1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 3.99683H18.0008C19.1059 3.99683 20.0017 4.89263 20.0017 5.99766V12.0002" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99826 9.87418C7.92919 9.87418 7.8732 9.93017 7.8732 9.99923C7.8732 10.0683 7.92919 10.1243 7.99826 10.1243C8.06732 10.1243 8.12331 10.0683 8.12331 9.99923C8.12331 9.93017 8.06732 9.87418 7.99826 9.87418" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99826 13.8759C7.92919 13.8759 7.8732 13.9319 7.8732 14.0009C7.8732 14.07 7.92919 14.126 7.99826 14.126C8.06732 14.126 8.12331 14.07 8.12331 14.0009C8.12331 13.9319 8.06732 13.8759 7.99826 13.8759" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8034 16.075L18.0015 16.2761L18.1995 16.075C18.5607 15.7081 19.054 15.5015 19.5688 15.5015C20.0836 15.5015 20.5769 15.7081 20.9381 16.075V16.075C21.6912 16.8489 21.6912 18.0817 20.9381 18.8557L19.399 20.4185C19.0304 20.7929 18.5269 21.0038 18.0015 21.0038C17.476 21.0038 16.9726 20.7929 16.6039 20.4185L15.0648 18.8557C14.3117 18.0818 14.3117 16.849 15.0648 16.075V16.075C15.426 15.7081 15.9193 15.5015 16.4341 15.5015C16.9489 15.5015 17.4422 15.7081 17.8034 16.075Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.0001 9.99923H10.998" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.9989 14.0009H10.998" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

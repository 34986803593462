import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelSubVerticalTabStyled } from './styled';

const LabelSubVerticalTab = ({
  theme_standard_tab,
  theme_head_34_tab,
  title,
  subTitle,
}) => {
  const customClass = cx({
    theme_standard_tab: theme_standard_tab,
    theme_head_34_tab: theme_head_34_tab,
  });
  return (
    <LabelSubVerticalTabStyled>
      <div className={customClass}>
        <div className="lb_title">{title}</div>
        <div className="lb_sub_title">{subTitle}</div>
      </div>
    </LabelSubVerticalTabStyled>
  );
};

LabelSubVerticalTab.propTypes = {};

export default LabelSubVerticalTab;

import { UserPercentageStyled } from "./styled";
import { Blocks, Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const UserPercentage = ({ data }) => {
  const renderPercent = () => {
    let numberActive = Math.floor(
      ((data?.total_user_count / data?.total_employee_count) * 100) / 10
    );

    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) =>
      i < numberActive ? (
        <Icons.PeoplePercent key={i} active />
      ) : (
        <Icons.PeoplePercent key={i} />
      )
    );
  };

  return (
    <UserPercentageStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="16px"
        paddingBottom="0px"
        paddingLeft="16px"
        height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelLeftRight
            theme_standard_box_4_col
            label1="สัดส่วนผู้ใช้"
            label2="User Percentage"
            label3={
              data && data.total_user_count > 0 && data.total_employee_count > 0
                ? (
                    (data?.total_user_count / data?.total_employee_count) *
                    100
                  ).toFixed(0)
                : 0
            }
            svg2={<Icons.Percentage color="#000000" />}
          />
        </div>
        <div className="bottom_current">
          <div className="show_svg">{data && renderPercent()}</div>
          <div className="group_label">
            <div className="left_group">
              <div>พนักงานทั้งหมด</div>
              <div className="sub_label">Total Employee</div>
            </div>
            <div className="right_group">
              <div className="num_show">
                {data && data.total_employee_count
                  ? RenderCommaMoney(data.total_employee_count, true)
                  : 0}
              </div>
              <div className="group_label_2">
                <div>คน</div>
                <div className="sub_label_2">Users</div>
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </UserPercentageStyled>
  );
};

export default UserPercentage;

import styled from "styled-components";

export const CurrentBMIStatContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
  .block_2_col_layout {
    display: flex;
    padding: 10px 0px 20px 5px;
    column-gap: 24px;
    row-gap: 24px;
    margin: 0 -5px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #cccccc;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #c2c2c2;
    }
    ::-webkit-scrollbar-track {
      background: #ebebeb;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #e6e6e6;
    }
    ::-webkit-scrollbar-track:active {
      background: #ebebeb;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    &.no_pdb {
      padding-bottom: 0px;
    }
    .col_2 {
      &.flex_1 {
        flex: 1;
      }
      &.w_416 {
        flex: 1;
        max-width: 416px;
        min-width: 416px;
        // min-width: 300px;
      }
      &.mw_780 {
        min-width: 780px;
      }
      .b2c_block_wrap {
        &.mb_24 {
          margin-bottom: 24px;
        }
      }
      .wrap_mr5_overflow {
        margin-right: 5px;
        height: 100%;
      }
    }
  }
  .section_chart_layout {
    margin-bottom: 24px;
    &.mt24 {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    &.scroll {
      overflow: auto;
      padding: 5px;
      margin: 0 -5px;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cccccc;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #c2c2c2;
      }
      ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #ebebeb;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
    }
    .mr_5 {
      min-width: 890px;
      margin-right: 5px;
    }
  }
`;

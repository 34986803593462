export default function PeopleGift() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="310" height="176.09" viewBox="0 0 310 176.09">
      <g id="Layer_2" data-name="Layer 2" transform="translate(0 -0.005)">
        <g id="Слой_1" data-name="Слой 1" transform="translate(0 0.005)">
          <path id="Path_49523" data-name="Path 49523" d="M53.841,342.42S19.32,305.358,48,265.235c25.447-35.6,55.116-33.167,76.882-18.72,9.271,6.153,36,.753,58.75-35.466,31.025-49.4,138.567-19.434,118.959,56.565-9.49,36.78,28.878,38.117,9.328,74.883Z" transform="translate(-32.673 -166.773)" fill="#ebf3fa" />
          <path id="Path_49524" data-name="Path 49524" d="M2302.67,1121.206c-2.43-8.974-17.574-6.17-17.574-6.17s-10.283,15.7-13.275,16.079,6.17-15.144,6.17-15.144c-5.8-.56-20.565,6.544-20.565,6.544s.935,18.322-2.056,17.948,0-16.266,0-16.266c-5.609,1.683-10.844,8.227-10.844,8.227-6.543,10.656,7.479,18.509,7.479,18.509a29.825,29.825,0,0,1-3.739,13.648h0a21.6,21.6,0,0,0-1.786,3.635h1.529c.125-.41.263-.807.414-1.183a.006.006,0,0,1,0-.007l.007-.017c.107-.235.256-.572.443-.982h0c.467-.914.783-1.591.982-2.078h0c1.4-2.871,3.417-6.612,4.761-7.4a3,3,0,0,1-.323-.7l.323.7c2.618,5.608,15.7,6.543,23,5.047s4.112-3.549.187-6.889-12.152-2.458-14.584-3.764,1.122-2.618,5.984-2.244,7.291,5.235,15.143,5.983,13.835-14.022,13.835-14.022c-5.421,1.683-15.144-1.309-15.517-3.178s14.021-1.683,16.266-2.243S2305.1,1130.181,2302.67,1121.206Z" transform="translate(-2001.59 -994.753)" fill="#dfebfd" />
          <path id="Path_49525" data-name="Path 49525" d="M747.72,1166.222s.668,5.331-.331,5.394-.323-5.995-.323-5.995-4.753-2.657-6.687-2.59c0,0,2.722,5.333,1.738,5.148s-4.075-5.6-4.075-5.6-4.965-1.239-5.954,1.687.222,4.5.954,4.732,5.51.463,5.349,1.075-3.448,1.407-5.211.738c0,0,1.683,5.021,4.3,4.933s3.524-1.651,5.144-1.677,2.771.482,1.936.867-3.549-.075-4.914.952-2.466,1.644-.078,2.288,6.746.6,7.729-1.205l.123-.226a1.012,1.012,0,0,1-.123.226,7.8,7.8,0,0,1,1.428,2.551c.056.166.147.4.284.71h0c.055.142.1.258.128.339.722,2.184.044,5.839.044,5.839l.508.171a8.6,8.6,0,0,0-.447-6.814,9.909,9.909,0,0,1-.962-4.6s4.81-2.319,2.857-5.986A9.008,9.008,0,0,0,747.72,1166.222Z" transform="translate(-653.026 -1037.32)" fill="#afcdfb" />
          <path id="Path_49526" data-name="Path 49526" d="M545.946,1336.6c-.43-6.576,10.367-8.173,10.367-8.173s10.5,8.173,12.593,7.733-7.614-8.738-7.614-8.738c3.758-1.707,15.292-.334,15.292-.334s3.58,12.5,5.5,11.569-3.735-10.907-3.735-10.907c4.147-.159,9.161,3.026,9.161,3.026,6.834,5.644-.766,14.129-.766,14.129a21.172,21.172,0,0,0,5.642,8.294h0a15.3,15.3,0,0,1,2.033,2.027l-1.025.351c-.179-.246-.361-.481-.551-.7h0l-.009-.01c-.124-.134-.3-.323-.522-.557h0c-.524-.505-.89-.886-1.136-1.167h0c-1.6-1.6-3.809-3.649-4.891-3.871a2.066,2.066,0,0,0,.058-.545c-.018.174-.038.355-.058.545-.468,4.362-9.034,7.994-14.261,8.666s-3.574-1.438-1.708-4.578,7.585-4.438,8.914-5.874-1.353-1.5-4.528-.131-3.687,5.185-8.781,7.489-12.5-6.226-12.5-6.226c4.022-.117,9.854-4.354,9.679-5.7s-9.786,2.091-11.422,2.231S546.376,1343.177,545.946,1336.6Z" transform="translate(-487.223 -1183.907)" fill="#cde0fb" />
          <path id="Path_49527" data-name="Path 49527" d="M2403.81,284.173a4.278,4.278,0,0,1-1.449-2.168,3.914,3.914,0,0,1,.378-2.228l-1.542-.306a5.611,5.611,0,0,0,.316,3.407,3.913,3.913,0,0,0,1.368,1.207A1.338,1.338,0,0,0,2403.81,284.173Z" transform="translate(-2142.846 -249.416)" fill="#6bb7bf" />
          <path id="Path_49528" data-name="Path 49528" d="M2411.369,313.247l1.4,1.606s-3.966,1.054-6.238-1.753C2406.538,313.1,2409.878,314.229,2411.369,313.247Z" transform="translate(-2147.726 -279.429)" fill="#86d1cd" />
          <path id="Path_49529" data-name="Path 49529" d="M1776.224,105.106a2.826,2.826,0,0,1,3.957-.923l1.384-1.506s-1.445-.957-4.5,1.125C1775.065,105.164,1776.224,105.106,1776.224,105.106Z" transform="translate(-1584.906 -91.44)" fill="#f99746" />
          <path id="Path_49530" data-name="Path 49530" d="M1765.725,111.391s-.481-2.59,3.783-4.5a4.574,4.574,0,0,0-1.849,3.5Z" transform="translate(-1575.82 -95.395)" fill="#f99746" />
          <path id="Path_49531" data-name="Path 49531" d="M932.4,807.477a2.569,2.569,0,0,1-1.424-3.406l-1.557-1.011s-.639,1.439,1.686,3.862C932.631,808.508,932.4,807.477,932.4,807.477Z" transform="translate(-829.399 -716.697)" fill="#5685d8" />
          <path id="Path_49532" data-name="Path 49532" d="M941,830.158s-2.248.823-4.6-2.708a4.152,4.152,0,0,0,3.414,1.127Z" transform="translate(-835.697 -738.464)" fill="#739af0" />
          <path id="Path_49533" data-name="Path 49533" d="M1020.62,526.884s1.291-.817,1.43-1.8c0,0,1.259.068,1.532.476,0,0-.578,1.635-1.26,1.936C1022.322,527.5,1020.791,527.6,1020.62,526.884Z" transform="translate(-910.86 -468.612)" fill="#f1a34f" />
          <path id="Path_49534" data-name="Path 49534" d="M204.69,1075.095s-1.38-.872-1.525-1.925c0,0-1.344.072-1.635.509,0,0,.617,1.743,1.344,2.07C202.871,1075.749,204.509,1075.857,204.69,1075.095Z" transform="translate(-179.857 -957.759)" fill="#f1a34f" />
          <path id="Path_49535" data-name="Path 49535" d="M1632.445,353.109s-1.928-1.217-2.131-2.689c0,0-1.877.1-2.283.71,0,0,.86,2.436,1.878,2.892C1629.908,354.022,1632.191,354.174,1632.445,353.109Z" transform="translate(-1452.948 -312.735)" fill="#86d1cd" />
          <path id="Path_49536" data-name="Path 49536" d="M2172.283,304.527l11.984,37.524a3.17,3.17,0,0,1-2.043,3.973L2116.4,367.048a3.17,3.17,0,0,1-3.973-2.05l-11.99-37.524a3.171,3.171,0,0,1,2.043-3.973l65.827-21.025A3.17,3.17,0,0,1,2172.283,304.527Z" transform="translate(-1874.416 -269.815)" fill="#cde0fb" />
          <path id="Path_49537" data-name="Path 49537" d="M2256.376,741.958l-17.544,5.6a1.421,1.421,0,0,1-1.781-.918l-.054-.168a1.42,1.42,0,0,1,.918-1.781l17.544-5.6a1.422,1.422,0,0,1,1.781.918l.054.169A1.422,1.422,0,0,1,2256.376,741.958Z" transform="translate(-1996.365 -659.547)" fill="#afcdfb" />
          <path id="Path_49538" data-name="Path 49538" d="M2471.358,673.314l-17.544,5.6a1.421,1.421,0,0,1-1.781-.918l-.054-.169a1.422,1.422,0,0,1,.918-1.781l17.545-5.6a1.421,1.421,0,0,1,1.781.918l.054.169A1.421,1.421,0,0,1,2471.358,673.314Z" transform="translate(-2188.228 -598.289)" fill="#afcdfb" />
          <path id="Path_49539" data-name="Path 49539" d="M2409.665,564.061a3.435,3.435,0,0,1,.169,1.679,1.553,1.553,0,0,1-2.6.83,4.086,4.086,0,0,1-1.009-3.136,1.562,1.562,0,0,1,2.612-.834A3.43,3.43,0,0,1,2409.665,564.061Zm-.806.254a3.4,3.4,0,0,0-.459-.968q-.406-.546-.883-.395t-.5.829a3.267,3.267,0,0,0,.183,1.063q.523,1.638,1.358,1.371.483-.155.49-.84A3.39,3.39,0,0,0,2408.859,564.315Z" transform="translate(-2147.417 -501.712)" fill="#afcdfb" />
          <path id="Path_49540" data-name="Path 49540" d="M2443.16,553.37a3.426,3.426,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.075,4.075,0,0,1-1.009-3.136,1.564,1.564,0,0,1,2.612-.835A3.464,3.464,0,0,1,2443.16,553.37Zm-.806.254a3.39,3.39,0,0,0-.459-.968q-.406-.546-.883-.394t-.5.829a3.3,3.3,0,0,0,.182,1.063q.525,1.638,1.36,1.37.483-.155.488-.84A3.36,3.36,0,0,0,2442.354,553.623Z" transform="translate(-2177.31 -492.171)" fill="#afcdfb" />
          <path id="Path_49541" data-name="Path 49541" d="M2493.917,537.157a3.437,3.437,0,0,1,.169,1.68,1.553,1.553,0,0,1-2.6.83,4.076,4.076,0,0,1-1.009-3.137,1.564,1.564,0,0,1,2.612-.834A3.38,3.38,0,0,1,2493.917,537.157Zm-.806.255a3.389,3.389,0,0,0-.459-.968q-.407-.547-.883-.395t-.5.829a3.28,3.28,0,0,0,.183,1.063q.523,1.638,1.358,1.37.482-.154.489-.839A3.383,3.383,0,0,0,2493.111,537.412Z" transform="translate(-2222.607 -477.703)" fill="#afcdfb" />
          <path id="Path_49542" data-name="Path 49542" d="M2527.42,526.451a3.425,3.425,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.075,4.075,0,0,1-1.009-3.136,1.563,1.563,0,0,1,2.612-.835A3.444,3.444,0,0,1,2527.42,526.451Zm-.806.254a3.417,3.417,0,0,0-.459-.968q-.406-.547-.883-.395t-.5.829a3.267,3.267,0,0,0,.182,1.063q.523,1.637,1.359,1.371.482-.155.488-.84a3.382,3.382,0,0,0-.188-1.06Z" transform="translate(-2252.509 -468.146)" fill="#afcdfb" />
          <path id="Path_49543" data-name="Path 49543" d="M2560.92,515.749a3.418,3.418,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.077,4.077,0,0,1-1.009-3.136,1.562,1.562,0,0,1,2.611-.834A3.376,3.376,0,0,1,2560.92,515.749Zm-.806.254a3.409,3.409,0,0,0-.458-.968q-.406-.547-.884-.394t-.5.829a3.282,3.282,0,0,0,.183,1.063q.522,1.636,1.358,1.37.484-.155.49-.84A3.369,3.369,0,0,0,2560.114,516Z" transform="translate(-2282.405 -458.595)" fill="#afcdfb" />
          <path id="Path_49544" data-name="Path 49544" d="M2594.407,505.06a3.428,3.428,0,0,1,.168,1.679,1.551,1.551,0,0,1-2.6.83,4.077,4.077,0,0,1-1.009-3.136,1.564,1.564,0,0,1,2.612-.835A3.424,3.424,0,0,1,2594.407,505.06Zm-.805.254a3.368,3.368,0,0,0-.459-.968q-.406-.546-.883-.395t-.5.829a3.274,3.274,0,0,0,.183,1.063q.523,1.638,1.358,1.37.483-.155.489-.84A3.4,3.4,0,0,0,2593.6,505.313Z" transform="translate(-2312.291 -449.056)" fill="#afcdfb" />
          <path id="Path_49545" data-name="Path 49545" d="M2645.18,488.839a3.458,3.458,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.08,4.08,0,0,1-1.007-3.136,1.561,1.561,0,0,1,2.611-.835A3.441,3.441,0,0,1,2645.18,488.839Zm-.807.254a3.409,3.409,0,0,0-.458-.968q-.406-.547-.884-.395t-.5.829a3.294,3.294,0,0,0,.183,1.063q.523,1.638,1.358,1.371.481-.155.489-.84A3.4,3.4,0,0,0,2644.374,489.093Z" transform="translate(-2357.604 -434.579)" fill="#afcdfb" />
          <path id="Path_49546" data-name="Path 49546" d="M2678.67,478.141a3.422,3.422,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.074,4.074,0,0,1-1.009-3.136,1.562,1.562,0,0,1,2.612-.835A3.43,3.43,0,0,1,2678.67,478.141Zm-.806.254a3.383,3.383,0,0,0-.459-.968q-.406-.546-.883-.395t-.5.829a3.267,3.267,0,0,0,.182,1.063q.523,1.638,1.358,1.371.482-.155.488-.84A3.347,3.347,0,0,0,2677.865,478.395Z" transform="translate(-2387.493 -425.032)" fill="#afcdfb" />
          <path id="Path_49547" data-name="Path 49547" d="M2712.17,467.45a3.42,3.42,0,0,1,.168,1.679,1.553,1.553,0,0,1-2.6.83,4.074,4.074,0,0,1-1.009-3.136,1.564,1.564,0,0,1,2.612-.835A3.445,3.445,0,0,1,2712.17,467.45Zm-.805.254a3.4,3.4,0,0,0-.459-.968q-.406-.546-.883-.394a.777.777,0,0,0-.5.829,3.267,3.267,0,0,0,.182,1.063q.522,1.637,1.358,1.37.484-.155.49-.84A3.4,3.4,0,0,0,2711.365,467.7Z" transform="translate(-2417.39 -415.491)" fill="#afcdfb" />
          <path id="Path_49548" data-name="Path 49548" d="M2745.67,456.748a3.425,3.425,0,0,1,.168,1.679,1.552,1.552,0,0,1-2.6.83,4.079,4.079,0,0,1-1.008-3.136,1.563,1.563,0,0,1,2.611-.835A3.444,3.444,0,0,1,2745.67,456.748Zm-.807.254a3.366,3.366,0,0,0-.459-.968q-.406-.546-.883-.395t-.5.829a3.3,3.3,0,0,0,.183,1.063q.523,1.637,1.358,1.37.483-.155.489-.84A3.39,3.39,0,0,0,2744.864,457Z" transform="translate(-2447.287 -405.94)" fill="#afcdfb" />
          <path id="Path_49549" data-name="Path 49549" d="M2168.58,469.359l-17.169,5.485a1.421,1.421,0,0,1-1.781-.918h0a1.422,1.422,0,0,1,.919-1.781l17.169-5.485a1.421,1.421,0,0,1,1.781.918h0A1.42,1.42,0,0,1,2168.58,469.359Z" transform="translate(-1918.394 -416.415)" fill="#afcdfb" />
          <path id="Path_49550" data-name="Path 49550" d="M2703.5,388.029a2.164,2.164,0,0,0-.87-1.151.232.232,0,0,0-.335.088l-.054.108a.23.23,0,0,0,.075.295,1.593,1.593,0,0,1,.6,1.884.23.23,0,0,0,.108.284l.108.056a.231.231,0,0,0,.322-.122A2.161,2.161,0,0,0,2703.5,388.029Z" transform="translate(-2411.614 -345.238)" fill="#afcdfb" />
          <path id="Path_49551" data-name="Path 49551" d="M2709.187,378.991a3.325,3.325,0,0,0-1.467-1.849.231.231,0,0,0-.323.1l-.031.06a.232.232,0,0,0,.09.3,2.808,2.808,0,0,1,1.125,3.522.233.233,0,0,0,.108.3l.06.031a.229.229,0,0,0,.323-.108A3.319,3.319,0,0,0,2709.187,378.991Z" transform="translate(-2416.187 -336.556)" fill="#afcdfb" />
          <path id="Path_49552" data-name="Path 49552" d="M2714.24,371.073a4.355,4.355,0,0,0-1.982-2.455.232.232,0,0,0-.323.1h0a.231.231,0,0,0,.091.3,3.9,3.9,0,0,1,1.6,5.008.23.23,0,0,0,.108.3h0a.23.23,0,0,0,.323-.108A4.352,4.352,0,0,0,2714.24,371.073Z" transform="translate(-2420.265 -328.949)" fill="#afcdfb" />
          <path id="Path_49553" data-name="Path 49553" d="M2719,362.528a5.456,5.456,0,0,0-2.539-3.107.233.233,0,0,0-.323.1l-.053.107a.232.232,0,0,0,.093.305,4.888,4.888,0,0,1,2.027,6.349.23.23,0,0,0,.1.3l.107.055a.233.233,0,0,0,.323-.108A5.46,5.46,0,0,0,2719,362.528Z" transform="translate(-2423.967 -320.742)" fill="#afcdfb" />
          <rect id="Rectangle_18897" data-name="Rectangle 18897" width="37.477" height="107.95" transform="translate(213.255 67.293)" fill="#ffa412" />
          <rect id="Rectangle_18898" data-name="Rectangle 18898" width="99.011" height="107.95" transform="translate(114.243 67.293)" fill="#ffb93e" />
          <rect id="Rectangle_18899" data-name="Rectangle 18899" width="7.556" height="99.011" transform="translate(213.255 120.101) rotate(90)" fill="#ffa412" />
          <rect id="Rectangle_18900" data-name="Rectangle 18900" width="7.556" height="37.477" transform="translate(250.732 120.101) rotate(90)" fill="#fa9712" />
          <path id="Path_49554" data-name="Path 49554" d="M1693.242,546.958s22.369-14.535,37.574-9.865l-3.137,2.928,1.413,4.923a55.729,55.729,0,0,0-36.313,2.84Z" transform="translate(-1510.734 -478.508)" fill="#f99746" />
          <path id="Path_49555" data-name="Path 49555" d="M1171.492,187.492s-3.947-.477-9.829-1.291c-15.616-2.151-44.867-6.643-50.115-10.8-8.25-6.532,11.3-34.278,19.761-38.129S1171.492,187.492,1171.492,187.492Z" transform="translate(-990.235 -122.333)" fill="#f99746" />
          <path id="Path_49556" data-name="Path 49556" d="M1742.518,30.613c1.41,6.873-62.468,36.564-62.468,36.564s1.162-3.142,3.052-8.066V59.1c6.912-17.965,23.55-59.67,29.016-59.092a6.607,6.607,0,0,1,1.909.567C1722.424,4.273,1741.219,24.283,1742.518,30.613Z" transform="translate(-1499.373 -0.005)" fill="#f99746" />
          <path id="Path_49557" data-name="Path 49557" d="M1738.881,6.69c-3.9,6.775-29.972,51.719-30.381,52.419,6.9-17.971,23.542-59.676,29.008-59.1a3.478,3.478,0,0,1,1.909.567C1740.414,1.3,1741.032,2.948,1738.881,6.69Z" transform="translate(-1524.764 -0.005)" fill="#ed7d2b" />
          <path id="Path_49558" data-name="Path 49558" d="M1173.822,440.392h-.011c-15.616-2.151-44.867-6.643-50.115-10.8-2.366-6.677,3.78-7.221,8.257-7.691S1173.822,440.392,1173.822,440.392Z" transform="translate(-1002.383 -376.52)" fill="#ed7d2b" />
          <path id="Path_49559" data-name="Path 49559" d="M1574.67,554.17s3.581-8.146,10.628-8.6c7.361-.471,11.542,8.6,11.542,8.6Z" transform="translate(-1405.326 -486.885)" fill="#ed7d2b" />
          <rect id="Rectangle_18901" data-name="Rectangle 18901" width="103.601" height="14.847" transform="translate(111.242 67.293)" fill="#ffc261" />
          <rect id="Rectangle_18902" data-name="Rectangle 18902" width="39.049" height="14.847" transform="translate(214.843 67.293)" fill="#fa9712" />
          <path id="Path_49560" data-name="Path 49560" d="M1203.094,584.052s-20.291-6.428-33.983-2.178c-15.783,4.9-14.847,24.372-14.847,24.372l3.711-1.526,5.011,2.351s.845-20.621,13.713-23.508C1187.825,581.066,1203.094,584.052,1203.094,584.052Z" transform="translate(-1030.121 -518.002)" fill="#f99746" />
          <rect id="Rectangle_18903" data-name="Rectangle 18903" width="7.556" height="107.938" transform="translate(155.007 67.293)" fill="#ffa412" />
          <rect id="Rectangle_18904" data-name="Rectangle 18904" width="7.316" height="21.072" transform="matrix(0.948, -0.318, 0.318, 0.948, 86.263, 37.211)" fill="#ed7d2b" />
          <rect id="Rectangle_18905" data-name="Rectangle 18905" width="19.328" height="21.072" transform="matrix(0.948, -0.318, 0.318, 0.948, 67.938, 43.353)" fill="#f99746" />
          <rect id="Rectangle_18906" data-name="Rectangle 18906" width="1.478" height="19.375" transform="matrix(0.319, 0.948, -0.948, 0.319, 89.639, 47.222)" fill="#ffb93e" />
          <rect id="Rectangle_18907" data-name="Rectangle 18907" width="1.478" height="7.334" transform="matrix(0.319, 0.948, -0.948, 0.319, 96.6, 44.926)" fill="#f69b34" />
          <path id="Path_49561" data-name="Path 49561" d="M754.562,318.977s4.763-4.433,9-4.777l-.607.785.7.793a20.285,20.285,0,0,0-9.164,3.388Z" transform="translate(-673.359 -280.411)" fill="#ffb93e" />
          <path id="Path_49562" data-name="Path 49562" d="M640.682,303.308s-.759.156-1.9.371c-3.023.571-8.711,1.554-9.945,1.11-1.936-.7-.036-7.045,1.29-8.281S640.682,303.308,640.682,303.308Z" transform="translate(-560.473 -264.506)" fill="#ffb93e" />
          <path id="Path_49563" data-name="Path 49563" d="M755.293,234.152C755.981,235.335,746,244.8,746,244.8s.02-.654.065-1.682h0c.163-3.754.656-12.506,1.7-12.738a1.289,1.289,0,0,1,.389-.013C749.94,230.522,754.66,233.06,755.293,234.152Z" transform="translate(-665.773 -205.584)" fill="#ffb93e" />
          <path id="Path_49564" data-name="Path 49564" d="M749.016,231.368c-.3,1.5-2.339,11.432-2.366,11.587.163-3.754.656-12.506,1.7-12.738a.676.676,0,0,1,.389-.013C748.966,230.275,749.183,230.542,749.016,231.368Z" transform="translate(-666.353 -205.427)" fill="#f69b34" />
          <path id="Path_49565" data-name="Path 49565" d="M643.1,356.83h0c-3.023.571-8.711,1.554-9.946,1.11-.853-1.088.252-1.571,1.051-1.936S643.1,356.83,643.1,356.83Z" transform="translate(-564.787 -317.657)" fill="#f69b34" />
          <path id="Path_49566" data-name="Path 49566" d="M726.56,351.633a2.861,2.861,0,0,1,1.434-2.251c1.333-.543,2.669.875,2.669.875Z" transform="translate(-648.424 -311.699)" fill="#f69b34" />
          <rect id="Rectangle_18908" data-name="Rectangle 18908" width="20.223" height="2.898" transform="matrix(0.948, -0.318, 0.318, 0.948, 67.383, 43.54)" fill="#ffa15a" />
          <rect id="Rectangle_18909" data-name="Rectangle 18909" width="7.623" height="2.898" transform="matrix(0.948, -0.318, 0.318, 0.948, 86.557, 37.112)" fill="#f78639" />
          <path id="Path_49567" data-name="Path 49567" d="M665.49,367.12s-4.154.069-6.426,1.706c-2.616,1.885-1.236,5.432-1.236,5.432l.593-.513,1.075.124s-1.123-3.872,1.075-5.2A15.256,15.256,0,0,1,665.49,367.12Z" transform="translate(-586.759 -327.639)" fill="#ffbe55" />
          <rect id="Rectangle_18910" data-name="Rectangle 18910" width="1.474" height="21.254" transform="matrix(0.948, -0.318, 0.318, 0.948, 75.482, 40.825)" fill="#ffb93e" />
          <path id="Path_49568" data-name="Path 49568" d="M1933.379,674.554a3.4,3.4,0,0,0-1.061,4.747s-2.4-.44-2.92-1.157a1.884,1.884,0,0,0,.15.45s-2.208-.5-2.93-1.422,2.821-11.958,8.562-9.095c0,0,5.248.968,3.192,6.8s-.113,6.345-.113,6.345a2.867,2.867,0,0,1-.871.054s-.343-.246-.363-.477a1.4,1.4,0,0,0,.057.483,5.716,5.716,0,0,1-2.986-1.425C1933.259,678.788,1932.755,675.7,1933.379,674.554Z" transform="translate(-1719.341 -595.809)" fill="#233862" />
          <path id="Path_49569" data-name="Path 49569" d="M1953.26,819.45h-.01C1953.233,819.452,1953.236,819.45,1953.26,819.45Z" transform="translate(-1743.183 -731.325)" fill="#ffb27d" />
          <path id="Path_49570" data-name="Path 49570" d="M1960.1,761.539c.08.722.177,1.37.215,1.679.1.692-6.469.242-6.883.24.292-.127,1.183-2.951,1.465-4.029.341-1.307.61-2.719.61-2.719l4.635.292.276.017a16.207,16.207,0,0,0-.377,1.783c-.011.074-.022.149-.031.224A11.98,11.98,0,0,0,1960.1,761.539Z" transform="translate(-1743.354 -675.332)" fill="#ffb27d" />
          <path id="Path_49571" data-name="Path 49571" d="M1961.627,681.535a13.354,13.354,0,0,1-.6-4.21c.107-1.3,1.966-5.756,6.432-3.862a6.51,6.51,0,0,1,2.353,1.681c.828.947,1.894,2.8-.8,6.4l-.783,1.251Z" transform="translate(-1750.116 -600.625)" fill="#233862" />
          <path id="Path_49572" data-name="Path 49572" d="M1977.054,762.636c-3.244-.951-4.625-4.7-4.625-4.7a9.907,9.907,0,0,1,4.56,1.961c-.01.074-.021.149-.031.224A12.422,12.422,0,0,0,1977.054,762.636Z" transform="translate(-1760.31 -676.43)" fill="#ed975d" />
          <path id="Path_49573" data-name="Path 49573" d="M1976.012,700.452s-5.859-1.448-4.9-5.082.848-6.269,4.625-5.5,4.139,2.348,4.115,3.639S1977.565,700.735,1976.012,700.452Z" transform="translate(-1759.038 -615.562)" fill="#ffb27d" />
          <path id="Path_49574" data-name="Path 49574" d="M1966.551,686.425s-2.726,3.839-5.344,3.821-3.507-.957-3.507-.957a7.172,7.172,0,0,0,3.355-3.5C1961.05,685.789,1965.877,684.077,1966.551,686.425Z" transform="translate(-1747.164 -611.501)" fill="#233862" />
          <path id="Path_49575" data-name="Path 49575" d="M2035.41,695.178a5.334,5.334,0,0,1,.987,2.27c.046,1-.323,2.941.244,3.023,0,0,1.97-2.581,1.052-4.575C2036.72,693.779,2035.41,695.178,2035.41,695.178Z" transform="translate(-1816.517 -620.012)" fill="#233862" />
          <path id="Path_49576" data-name="Path 49576" d="M1989.061,690.044s-2.32,4.162-5.791,4.773c0,0,3.819-1.923,4.862-4.8S1989.061,690.044,1989.061,690.044Z" transform="translate(-1769.984 -614.675)" fill="#233862" />
          <path id="Path_49577" data-name="Path 49577" d="M2009.06,767.669s.073.553.43.684a5.114,5.114,0,0,0,1.385.309,4.685,4.685,0,0,0,.445-.489A7.325,7.325,0,0,0,2009.06,767.669Z" transform="translate(-1793.001 -685.108)" fill="#fff" />
          <path id="Path_49578" data-name="Path 49578" d="M1898.4,1132.124c-.074.311-.149.618-.222.924-.442,1.835-.853,3.537-1.111,4.961-.338,1.854-.416,3.233.025,3.813,1.82,2.385,0,21.315,0,21.315l-2.07,2.134s-3.6-18.69-4.576-21.616c-.444-1.331-1.09-7.017-1.662-12.822q-.043-.43-.086-.871c-.1-1.015-.194-2.027-.285-3.011-.513-5.492-.9-10.2-.9-10.2s3.818-2.689,7.287-2.983a4.37,4.37,0,0,1,4.915,2.983C1901.1,1120.4,1899.7,1126.711,1898.4,1132.124Z" transform="translate(-1684.531 -993.969)" fill="#ffb27d" />
          <path id="Path_49579" data-name="Path 49579" d="M1919.815,1132.088a20.419,20.419,0,0,1-8.281-11.672c-.277-2.97,2.065-5.794,4.68-6.686a4.37,4.37,0,0,1,4.915,2.983C1922.516,1120.367,1921.115,1126.675,1919.815,1132.088Z" transform="translate(-1705.943 -993.933)" fill="#ed975d" />
          <path id="Path_49580" data-name="Path 49580" d="M2075.914,949l13.942,3.6v1.628s-13.873-.162-14.769-1.708S2075.914,949,2075.914,949Z" transform="translate(-1851.697 -846.943)" fill="#ffb27d" />
          <path id="Path_49581" data-name="Path 49581" d="M1975.719,1121.915l-.652,2.688s-18.859-9.741-21.293-11.615c-.981-.753-2.275-4.833-3.475-9.451-1.786-6.883-3.36-14.948-3.36-14.948s2.644-10.168,9.721-3.945c4.235,3.723,3.577,10.9,3.112,16.481-.309,3.745-.53,6.775.875,7.556,2.739,1.529,10.778,8.572,13.322,13.418Z" transform="translate(-1737.561 -966.2)" fill="#ffb27d" />
          <path id="Path_49582" data-name="Path 49582" d="M1831.26,843.626c-4.926-1.253-10.34-8.895-10.34-8.895l1.387-1.2c1.436,1.693,7.405,4.608,7.405,4.608-.031-1.55.009-2.963.108-4.248.662-8.741,3.958-11.653,4.858-12.292a2.178,2.178,0,0,1,.228-.147s0,.063.009.184c.013.26.036.783.061,1.506C1835.157,828.485,1835.376,844.675,1831.26,843.626Z" transform="translate(-1625.094 -733.11)" fill="#ffb27d" />
          <path id="Path_49583" data-name="Path 49583" d="M1884.339,827.318c-.256,6.237-1.183,12.41-3.9,12.012-3.327-.485-4.194-3.592-4.371-5.9a13.272,13.272,0,0,1,.048-2.565,5.882,5.882,0,0,0,1.9-.252c1.258-1.161.885-12.557,1.506-13.74s4.662-1.943,4.662-1.943S1884.593,821.154,1884.339,827.318Z" transform="translate(-1674.272 -727.291)" fill="#fff" />
          <path id="Path_49584" data-name="Path 49584" d="M1884.674,894.6c-.256,6.238-1.183,12.411-3.9,12.013-3.327-.485-4.194-3.592-4.37-5.9.817-.041,2.258-.24,2.826-1.112.818-1.247,3.371-9.28,3.371-9.28S1883.6,892.123,1884.674,894.6Z" transform="translate(-1674.607 -794.573)" fill="#dfebfd" />
          <path id="Path_49585" data-name="Path 49585" d="M1928.6,811.13c.9,1.281.4,3.764-1.487,5.764a5.612,5.612,0,0,1-5.354,1.595c-1.129-.167-4.283-1.948-4.615-4.119-.059-.394-.092-.624-.092-.624a12.353,12.353,0,0,0-1.49,1.71c-.025.121-.148,1.208-.168,1.357a5.14,5.14,0,0,0,.107.839s-6.86,3-12.531-.416c0,0-1.613-13.857-.456-16.163.577-1.145,3.814-3.376,4.947-4.362a1.8,1.8,0,0,0,.441-1.321c-.057-.363.362-.977.712-.86.677.215,2.6,1.166,3.945.8.672-.179,1.525-.956,2.177-.779.8.215-.045,2.135.406,2.159.622.031,3.979.7,4.264,1.245.1.188,1.063,2.744,2.273,6.13a.025.025,0,0,1,0,.009C1923.942,810.44,1927.274,809.25,1928.6,811.13Z" transform="translate(-1697.538 -709.072)" fill="#fff" />
          <path id="Path_49586" data-name="Path 49586" d="M1991.825,930s-.168-5.286-.595-5.221-6.589,6-5.124,8.369S1991.825,930,1991.825,930Z" transform="translate(-1772.315 -825.323)" fill="#dfebfd" />
          <path id="Path_49587" data-name="Path 49587" d="M1904.468,1019.492a41.089,41.089,0,0,1-5.874,1.218c-4.332.6-10.388.814-15.8-1.218,0,0-.445-8.047.836-12.243s1.881-6.2,1.881-6.2a27.046,27.046,0,0,0,10.348.289,16.894,16.894,0,0,0,2.188-.566s3.217,6.829,4.1,9.755S1904.468,1019.492,1904.468,1019.492Z" transform="translate(-1680.25 -893.145)" fill="#2b478b" />
          <path id="Path_49588" data-name="Path 49588" d="M2002.563,1019.474a41.089,41.089,0,0,1-5.874,1.218c-6.416-3.638-3.288-18.947-3.288-18.947.19-.154.376-.3.556-.43a16.894,16.894,0,0,0,2.188-.566s3.217,6.829,4.1,9.755S2002.563,1019.474,2002.563,1019.474Z" transform="translate(-1778.346 -893.127)" fill="#233862" />
          <path id="Path_49589" data-name="Path 49589" d="M1911.83,990.96c-3.8,0-5.377-1.309-5.485-1.4l-.245-1.792c.037.027,5.14,3.092,13.037.157l-.168,1.357A20.77,20.77,0,0,1,1911.83,990.96Z" transform="translate(-1701.113 -881.543)" fill="#b64c41" />
          <path id="Path_49590" data-name="Path 49590" d="M2214.56,970.121a18.714,18.714,0,0,1,3.575-1.382,8.073,8.073,0,0,1,2.725,0,7.556,7.556,0,0,1-2.319,1.382c-.49.082-1.424.774-1.138.855s1.789-.264,1.81,0-.915.43-1.075.631a1.94,1.94,0,0,1-1.648.323c-.915-.125-1.927-.183-1.927-.183Z" transform="translate(-1976.401 -864.463)" fill="#ffb27d" />
          <path id="Path_49591" data-name="Path 49591" d="M1800.779,901.931l-1.391,1.212s-2.474-1.8-2.589-2.543v-.074c0-.769,1.568-2.366,2.869-2.426a.7.7,0,0,1,.355.089c.694.43.776,2.241.77,3.176C1800.792,901.7,1800.779,901.931,1800.779,901.931Z" transform="translate(-1603.568 -801.516)" fill="#ffb27d" />
          <path id="Path_49592" data-name="Path 49592" d="M2003.6,1275.383l-.652,2.689s-18.859-9.741-21.293-11.615c-.981-.753-2.275-4.833-3.475-9.451,1.613.331,5.086.507,9.473-2.416-.309,3.745-.53,6.775.875,7.556,2.739,1.529,10.778,8.572,13.322,13.418Z" transform="translate(-1765.442 -1119.669)" fill="#2b478b" />
          <path id="Path_49593" data-name="Path 49593" d="M2203.732,1426.946h-.017a1.181,1.181,0,0,1-.729-.767c-.17-.405-.22-.739-.375-1.141l-.637-1.1c-.666-.878-.9-.672-.9-.672s-.174.224-.43.518a5.354,5.354,0,0,1-1.538,1.338,1.337,1.337,0,0,0-.659.515,1.262,1.262,0,0,0-.2.452s1.367.239,1.248.912c-.231,1.3-.176,1.542-.153,1.585l.008.009s2.332,1.22,3.078,1.46a5.861,5.861,0,0,1,2.451,1.739.767.767,0,0,0,.653.205c.35-.055.8-.167.9-.382Z" transform="translate(-1961.845 -1270.187)" fill="#233862" />
          <path id="Path_49594" data-name="Path 49594" d="M1907.608,1283.63c1.82,2.385,0,21.315,0,21.315l-2.07,2.134s-3.6-18.69-4.576-21.616c-.444-1.331-1.09-7.017-1.661-12.822,1.721.77,5.935,2.447,9.394,2.219-.442,1.835-.853,3.537-1.111,4.961C1907.245,1281.671,1907.167,1283.05,1907.608,1283.63Z" transform="translate(-1695.045 -1135.778)" fill="#233862" />
          <path id="Path_49595" data-name="Path 49595" d="M1923.955,1570.266l-1.117.6c-.407.144-.741.185-1.152.343a2.381,2.381,0,0,0-1.085.794c-.315,1.281-.762,1.2-.762,1.2l-4.488.042c-.215-.108.147-1.012.323-1.183.424-.418,1.694-.208,2.157-1.523.261-.738,1.549-3.034,1.549-3.034l.009-.008c.044-.022.283-.07,1.58.2.67.138.947-1.222.947-1.222a1.259,1.259,0,0,1,.446.215,1.339,1.339,0,0,1,.5.673,5.408,5.408,0,0,0,1.29,1.575c.287.266.506.446.506.446S1924.851,1569.626,1923.955,1570.266Z" transform="translate(-1709.31 -1398.017)" fill="#233862" />
          <rect id="Rectangle_18911" data-name="Rectangle 18911" width="19.06" height="54.899" transform="translate(150.469 120.48)" fill="#4a75cb" />
          <rect id="Rectangle_18912" data-name="Rectangle 18912" width="50.354" height="54.899" transform="translate(100.116 120.48)" fill="#739af0" />
          <rect id="Rectangle_18913" data-name="Rectangle 18913" width="3.842" height="50.354" transform="translate(150.469 147.337) rotate(90)" fill="#afcdfb" />
          <rect id="Rectangle_18914" data-name="Rectangle 18914" width="3.842" height="19.06" transform="translate(169.529 147.336) rotate(90)" fill="#88b3ff" />
          <path id="Path_49596" data-name="Path 49596" d="M1269.912,1071.4s15.437-7.006,26.2-4.345l-2.151,1.438,1.083,2.539a52.939,52.939,0,0,0-25.441.782Z" transform="translate(-1133.064 -951.761)" fill="#4a75cb" />
          <path id="Path_49597" data-name="Path 49597" d="M986.528,897.24s-2.008-.243-5-.654c-7.942-1.093-22.818-3.378-25.488-5.493-4.194-3.322,5.747-17.433,10.05-19.391S986.528,897.24,986.528,897.24Z" transform="translate(-852.325 -777.868)" fill="#72a9f7" />
          <path id="Path_49598" data-name="Path 49598" d="M1276.929,817.433c.716,3.5-31.769,18.6-31.769,18.6s.591-1.6,1.553-4.1h0c3.512-9.141,11.974-30.348,14.754-30.055a3.328,3.328,0,0,1,.968.288C1266.71,804.036,1276.269,814.214,1276.929,817.433Z" transform="translate(-1111.252 -715.637)" fill="#72a9f7" />
          <path id="Path_49599" data-name="Path 49599" d="M1275.071,805.231c-1.984,3.441-15.243,26.3-15.451,26.659,3.512-9.141,11.974-30.349,14.754-30.055a1.768,1.768,0,0,1,.968.288C1275.851,802.5,1276.167,803.329,1275.071,805.231Z" transform="translate(-1124.157 -715.601)" fill="#4a75cb" />
          <path id="Path_49600" data-name="Path 49600" d="M987.747,1025.8h0c-7.942-1.093-22.818-3.378-25.488-5.493-1.205-3.4,1.923-3.673,4.2-3.911S987.747,1025.8,987.747,1025.8Z" transform="translate(-858.537 -907.085)" fill="#4a75cb" />
          <path id="Path_49601" data-name="Path 49601" d="M1191.57,1083.638s1.821-4.144,5.405-4.373c3.743-.24,5.87,4.373,5.87,4.373Z" transform="translate(-1063.426 -963.19)" fill="#4a75cb" />
          <rect id="Rectangle_18915" data-name="Rectangle 18915" width="52.687" height="7.551" transform="translate(98.59 120.48)" fill="#88b3ff" />
          <rect id="Rectangle_18916" data-name="Rectangle 18916" width="19.859" height="7.551" transform="translate(151.277 120.48)" fill="#3969b7" />
          <path id="Path_49602" data-name="Path 49602" d="M1002.54,1099.11s-10.324-3.268-17.283-1.108c-8.026,2.492-7.551,12.4-7.551,12.4l1.887-.776,2.549,1.2s.43-10.487,6.974-11.955C994.774,1097.592,1002.54,1099.11,1002.54,1099.11Z" transform="translate(-872.556 -979.262)" fill="#4a75cb" />
          <rect id="Rectangle_18917" data-name="Rectangle 18917" width="3.842" height="55.373" transform="translate(120.847 120.48)" fill="#afcdfb" />
          <path id="Path_49603" data-name="Path 49603" d="M357.491,843.583s14.2,10.4,17.664,10.15,15.767-7.837,15.767-7.837l-2.6-3.093s-11.135,4.125-13.174,2.989-10-10.792-13.344-10.277S355.156,839.844,357.491,843.583Z" transform="translate(-318.345 -745.644)" fill="#233862" />
          <path id="Path_49604" data-name="Path 49604" d="M223.739,1565.995H219.5a2.563,2.563,0,0,1-1.16-1.944c-.206-1.367-1.739-2.112-2.07-3.226s1.367-2.688,1.367-2.688l.284-.366.3-.38,2.815.663s.07.671.108,1.525a19.41,19.41,0,0,1,.013,1.954c-.083,1.489.416,3.1,1.741,3.385C223.872,1565.124,223.833,1565.706,223.739,1565.995Z" transform="translate(-192.969 -1389.905)" fill="#233862" />
          <path id="Path_49605" data-name="Path 49605" d="M431.951,1596.254h-9.7c-.466-.57-.295-2.671-.224-3.357.015-.138.025-.215.025-.215s3.313-2.65,3.764-.621c.014.063.031.126.049.187.573,1.9,3.145,2.761,4.63,3A1.678,1.678,0,0,1,431.951,1596.254Z" transform="translate(-376.558 -1420.165)" fill="#233862" />
          <path id="Path_49606" data-name="Path 49606" d="M381.228,1126.552s-.5,6.277-1.095,13.823c-.8,10.25-1.77,22.84-1.866,25.224-.166,4.142-.829,10.767-.829,10.767s-1.572,1.573-4.72.91a108.371,108.371,0,0,1-3.606-19.465c-.013-.163-.025-.329-.038-.495-.745-10.186-1.9-31.636-1.9-31.636Z" transform="translate(-327.684 -1004.622)" fill="#f99746" />
          <path id="Path_49607" data-name="Path 49607" d="M381.228,1126.57s-.5,6.277-1.095,13.822l-11.021,17.438c-.013-.163-.025-.329-.038-.495-.745-10.185-1.9-31.636-1.9-31.636Z" transform="translate(-327.684 -1004.64)" fill="#ed7d2b" />
          <path id="Path_49608" data-name="Path 49608" d="M255.931,1106.7s-9.068,22.732-15.031,33.416-7.824,13.665-7.824,13.665-3.416-1.367-3.726-2.3c0,0,2.28-20.624,6.273-25.652,2.258-2.841,4.907-11.367,5.031-14.348s.811-8.882.811-8.882Z" transform="translate(-204.685 -984.024)" fill="#f99746" />
          <path id="Path_49609" data-name="Path 49609" d="M339.343,768.442c-3.384,1.534-9.833-.725-9.833-.725a9.228,9.228,0,0,0,2.17-7.867l2.984,1.865,2.027,1.266a22.407,22.407,0,0,1,.082,2.441c0,.412-.016.871-.041,1.373A8.5,8.5,0,0,1,339.343,768.442Z" transform="translate(-294.074 -678.134)" fill="#ffb27d" />
          <path id="Path_49610" data-name="Path 49610" d="M357.04,778.415a22.413,22.413,0,0,1,.082,2.441c-2.09-.107-4.811-3.134-4.811-3.134a6.512,6.512,0,0,1,2.7-.573Z" transform="translate(-314.422 -693.573)" fill="#ed975d" />
          <path id="Path_49611" data-name="Path 49611" d="M341.577,709.211s-5.694,1.666-6.677-1.9-2.38-5.746,1.215-6.96,4.678-.051,5.3,1.058S343.033,708.681,341.577,709.211Z" transform="translate(-297.893 -624.576)" fill="#ffb27d" />
          <path id="Path_49612" data-name="Path 49612" d="M334.393,850.041a23.251,23.251,0,0,1,.222,2.7.569.569,0,0,1-.354.186c-1.323.142-5.087,1.339-8.388,1.4a24.448,24.448,0,0,1-8.066-1.311c-.365-.113-1.215-.443-1.243-.833-.04-.43-.071-1.046-.108-1.828a97.57,97.57,0,0,0-1.336-12.707c-.176-.988-.323-1.973-.445-2.947-.9-7.246-.231-13.8,2.4-16.306.226-.205,1.828.352,3.3.871a5.79,5.79,0,0,0,3.844,0l1.344.928a12.222,12.222,0,0,1,4.538,5.619,68.827,68.827,0,0,1,2.5,11.137c.333,2.034.653,4.087.907,6.04l.009.07c.041.193.067.4.089.6.047.347.092.688.137,1.025C334.018,846.77,334.24,848.632,334.393,850.041Z" transform="translate(-280.496 -730.346)" fill="#2b478b" />
          <path id="Path_49613" data-name="Path 49613" d="M326.589,683.474s.585-.171.389-.981-.323-1.884.369-1.765.789.651,1.026.306.084-1.662,2.059-1.993,2.867.263,3.742-.254.753-1.923.753-1.923a1.6,1.6,0,0,1-.581.738s1.291-2.294-.671-3.524-3.665-.066-4.691.122c-2.258.412-3.712.726-4.276,2.311S324.955,682.211,326.589,683.474Z" transform="translate(-289.629 -601.09)" fill="#2b478b" />
          <path id="Path_49614" data-name="Path 49614" d="M369.889,973.857c-.279.052-5.135.924-8.438-1.251-3.4-2.24-7.382-15.516-7.382-15.516s7.635,8.5,7.784,8.827c.124.282,5.63,2.033,7.387,2.581C369.515,970.587,369.737,972.448,369.889,973.857Z" transform="translate(-315.992 -854.163)" fill="#233862" />
          <rect id="Rectangle_18918" data-name="Rectangle 18918" width="37.775" height="37.775" transform="matrix(1, -0.014, 0.014, 1, 55.705, 77.616)" fill="#ed7d2b" />
          <rect id="Rectangle_18919" data-name="Rectangle 18919" width="37.775" height="37.775" transform="matrix(1, -0.014, 0.014, 1, 47.645, 77.733)" fill="#f99746" />
          <path id="Path_49615" data-name="Path 49615" d="M488.994,776.458a6.136,6.136,0,0,1,6.066-6.534c3.637-.052,6.13,2.758,6.178,6.36a6.158,6.158,0,0,1-6.066,6.534C491.534,782.87,489.045,780.06,488.994,776.458Zm20.836-6.453,4.286-.061-18.045,25.468-4.286.06Zm-12.367,6.335c-.025-1.693-.982-3.156-2.423-3.136-1.512.022-2.284,1.474-2.258,3.2.025,1.693.982,3.155,2.422,3.135,1.507-.025,2.278-1.512,2.254-3.2Zm7.205,12.792a6.136,6.136,0,0,1,6.066-6.533c3.637-.052,6.13,2.758,6.178,6.359a6.157,6.157,0,0,1-6.067,6.534c-3.642.048-6.132-2.763-6.184-6.363Zm8.464-.12c-.025-1.692-.982-3.155-2.422-3.135-1.513.021-2.285,1.473-2.258,3.2.025,1.693.982,3.156,2.422,3.136,1.506-.023,2.277-1.513,2.253-3.206Z" transform="translate(-436.405 -687.124)" fill="#fff" />
          <path id="Path_49616" data-name="Path 49616" d="M580.273,1050.047a10.1,10.1,0,0,1-3.435-.832,5.952,5.952,0,0,1-1.338-.868l.134-.84.161-1s.1-.016.262-.032.392-.035.652-.04a.85.85,0,0,0,.767-.183,1.819,1.819,0,0,1,1.469-.4c.484.115,1.521.009,1.554.178.082.409-1.432.334-1.613.59a.3.3,0,0,0,.015.416l.006.005c.886.894,3.936,1.219,3.815,1.928A5.323,5.323,0,0,1,580.273,1050.047Z" transform="translate(-513.609 -933.344)" fill="#ffb27d" />
          <path id="Path_49617" data-name="Path 49617" d="M332.432,866.726s6.99,16.15,10.109,17.689,17.368.343,17.368.343L360,882s-12.27-2.43-13.454-4.443-3.174-14.368-6.315-15.613S332.312,862.322,332.432,866.726Z" transform="translate(-296.679 -768.888)" fill="#2b478b" />
          <path id="Path_49618" data-name="Path 49618" d="M2471.469,1118.672q-.233.141-.471.273l-.163.088c-.125.066-.247.129-.372.19a12.55,12.55,0,0,1-12.863-21.45,12.761,12.761,0,0,1,1.281-.809c.16-.088.323-.174.485-.254s.323-.155.479-.226a12.433,12.433,0,0,1,1.495-.554,12.293,12.293,0,0,1,2.272-.458,12.551,12.551,0,0,1,7.857,23.2Z" transform="translate(-2188.649 -977.601)" fill="#ffb93e" />
          <path id="Path_49619" data-name="Path 49619" d="M2474.789,1129.758a11.442,11.442,0,1,1,5.509-1.423A11.441,11.441,0,0,1,2474.789,1129.758Zm-.021-22.189a10.754,10.754,0,1,0,9.426,5.563A10.753,10.753,0,0,0,2474.768,1107.569Z" transform="translate(-2198.417 -987.838)" fill="#ffa412" />
          <path id="Path_49620" data-name="Path 49620" d="M2502.07,1135.05l2.521,5.109,5.638.82-4.08,3.977.964,5.616-5.043-2.652-5.044,2.652.964-5.616-4.08-3.977,5.638-.82Z" transform="translate(-2225.708 -1012.984)" fill="#ffa412" />
          <path id="Path_49621" data-name="Path 49621" d="M2610.581,918.8c-.087.054-.177.108-.267.155l-.092.049c-.07.037-.14.073-.215.108a7.081,7.081,0,0,1-6.529-12.563c.091-.049.182-.1.274-.143s.178-.087.27-.127a7.083,7.083,0,0,1,6.56,12.521Z" transform="translate(-2320.241 -808.271)" fill="#ffb93e" />
          <path id="Path_49622" data-name="Path 49622" d="M2612.226,925.022a6.46,6.46,0,1,1,3.109-.8A6.46,6.46,0,0,1,2612.226,925.022Zm-.012-12.523a6.069,6.069,0,1,0,5.32,3.14A6.068,6.068,0,0,0,2612.214,912.5Z" transform="translate(-2325.525 -814.012)" fill="#ffa412" />
          <path id="Path_49623" data-name="Path 49623" d="M2627.656,928.05l1.423,2.883,3.182.462-2.3,2.244.543,3.169-2.845-1.5-2.847,1.5.543-3.169-2.3-2.244,3.182-.462Z" transform="translate(-2340.961 -828.246)" fill="#ffa412" />
          <path id="Path_49624" data-name="Path 49624" d="M1486.6,97.51c-.052.065-.107.127-.159.188l-.056.061c-.043.047-.086.092-.13.138a5.675,5.675,0,0,1-8.711-7.211,5.849,5.849,0,0,1,.424-.538c.056-.061.108-.123.17-.181s.108-.114.17-.169a5.752,5.752,0,0,1,.554-.461,5.59,5.59,0,0,1,.9-.538,5.675,5.675,0,0,1,6.841,8.711Z" transform="translate(-1317.745 -78.756)" fill="#cde0fb" />
          <path id="Path_49625" data-name="Path 49625" d="M1486.818,103.747a5.168,5.168,0,1,1,.305-.009C1487.022,103.743,1486.92,103.747,1486.818,103.747Zm.014-10.032c-.1,0-.192,0-.287.009a4.839,4.839,0,1,0,.287-.009Z" transform="translate(-1322.316 -83.365)" fill="#afcdfb" />
          <path id="Path_49626" data-name="Path 49626" d="M1496.467,108.08l1.843,1.8,2.527-.5-1.141,2.309,1.256,2.25-2.55-.372-1.751,1.89-.435-2.539-2.338-1.082,2.281-1.2Z" transform="translate(-1333.224 -96.457)" fill="#afcdfb" />
          <path id="Path_49627" data-name="Path 49627" d="M1266.59,1457.564q-.139.17-.286.336l-.1.108q-.115.126-.232.246a10.136,10.136,0,0,1-14.8-13.841q.148-.161.3-.323c.1-.108.2-.2.3-.3a10.136,10.136,0,0,1,14.816,13.773Z" transform="translate(-1114.325 -1286.049)" fill="#cde0fb" />
          <path id="Path_49628" data-name="Path 49628" d="M1267.145,1468.8a9.225,9.225,0,1,1,.544-.015C1267.508,1468.793,1267.326,1468.8,1267.145,1468.8Zm.024-17.918c-.17,0-.34,0-.512.015a8.654,8.654,0,1,0,.512-.015Z" transform="translate(-1122.656 -1294.376)" fill="#afcdfb" />
          <path id="Path_49629" data-name="Path 49629" d="M1284.272,1476.4l3.293,3.214,4.515-.892-2.039,4.125,2.243,4.018-4.553-.664-3.128,3.374-.776-4.535-4.176-1.933,4.075-2.139Z" transform="translate(-1142.033 -1317.625)" fill="#afcdfb" />
          <path id="Path_49630" data-name="Path 49630" d="M.336,1629.43H296.3a.336.336,0,0,1,.336.336v.115a.336.336,0,0,1-.336.336H.337a.337.337,0,0,1-.337-.336v-.115a.336.336,0,0,1,.336-.336Z" transform="translate(0 -1454.198)" fill="#2e3552" />
          <path id="Path_49631" data-name="Path 49631" d="M1659.431,983.441a5.035,5.035,0,0,1-2.964.8,4.557,4.557,0,0,1-2.343-1.26l-.924,1.606a6.614,6.614,0,0,0,3.921.929,4.561,4.561,0,0,0,1.861-1.075A1.568,1.568,0,0,0,1659.431,983.441Z" transform="translate(-1475.411 -877.268)" fill="#6bb7bf" />
          <path id="Path_49632" data-name="Path 49632" d="M1694.342,948.2l2.32-.961s-.323,4.822-4.3,6.3C1692.359,953.536,1694.876,950.235,1694.342,948.2Z" transform="translate(-1510.359 -845.372)" fill="#86d1cd" />
          <path id="Path_49633" data-name="Path 49633" d="M1858.624,1492.1a3.327,3.327,0,0,0,2.009,1.125s.133,1.255-.227,1.588c0,0-1.706-.312-2.116-.936C1858.287,1493.878,1857.945,1492.382,1858.624,1492.1Z" transform="translate(-1658.366 -1331.636)" fill="#f99746" />
          <path id="Path_49634" data-name="Path 49634" d="M1697.35,1302.4a4.279,4.279,0,0,1-.9-2.445,3.872,3.872,0,0,1,.886-2.079l-1.428-.658a5.616,5.616,0,0,0-.485,3.389,3.867,3.867,0,0,0,1.049,1.49A1.335,1.335,0,0,0,1697.35,1302.4Z" transform="translate(-1512.985 -1157.714)" fill="#cde0fb" />
          <path id="Path_49635" data-name="Path 49635" d="M1701.914,1332.207l.99,1.888s-4.1.1-5.655-3.155C1697.25,1330.94,1700.234,1332.816,1701.914,1332.207Z" transform="translate(-1514.724 -1187.808)" fill="#cde0fb" />
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components'

export const BtnCreateContentStyled = styled.div`
  .theme_standard_btn_create_content {
    font-family: noto_sans_thai_regular, noto_sans_regular;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.GRAY_2};
    padding: 12px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
    cursor: pointer;
    .left_button {

    }
    .right_button {
      padding-left: 15px;
      .title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
  }
`

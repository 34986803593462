import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { NotificationStyled } from './styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <NotificationStyled>
      <div className={customClass}>
        <ToastContainer />
      </div>
    </NotificationStyled>
  );
};

Notification.propTypes = {};

export default Notification;

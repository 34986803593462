import styled from 'styled-components';

export const LoadingMoreStyled = styled.div`
  .load_more_label {
    margin-bottom: 10px;
    text-align: center;

  }

  .theme_standard {
  }
`;

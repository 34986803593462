import styled from "styled-components";

export const TableAction2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  height: ${({ height }) => (height ? height : "unset")};
  .theme_standard_table_normal {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 10px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        .body_row {
          display: flex;
          width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          border-radius: 12px;
          padding: 18px 0;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_purple {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 11px 0;
        // border-bottom: 1.5px solid  ${({ theme }) => theme.COLORS.GRAY_4};
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        border-radius: 14px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.GRAY_11};
          border-radius: 14px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.PURPLE_3};
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_gray {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 11px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.GRAY_4};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.BLACK_1}50;
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        .body_row {
          display: flex;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border-radius: 14px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.GRAY_4}10;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_user {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: ${({ theme }) => theme.COLORS.BLUE_21};
        // border: 1px solid #b5b5b5;
        border-radius: 8px;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 12px 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            // border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-top: 16px;
          align-items: ${({ rowAlignItems }) => rowAlignItems && rowAlignItems};
          // border-left: 1px solid #b5b5b5;
          // border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
          background: ${({ theme }) => theme.COLORS.GRAY_32}99;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding: ${({ paddingRow }) => paddingRow ? paddingRow : "24px 15px"};
            width: 100%;
            // border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ activeColor, theme }) => activeColor ? activeColor : theme.COLORS.BLUE_21 + "24"};
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_standard_table_activity_anvite_join {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: ${({ theme }) => theme.COLORS.BLUE_25};
        // border: 1px solid #b5b5b5;
        border-radius: 4px;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 7px 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S24};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            /* border-right: 1px solid #e3e3e3; */
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        height: 383px;
        overflow: auto;
        ::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        ::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-thumb {
          background: #70707066;
          border: 0px none #ffffff;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #70707066;
        }
        ::-webkit-scrollbar-thumb:active {
          background: #70707066;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff;
          border: 0px none #ffffff;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track:hover {
          background: #ffffff;
        }
        ::-webkit-scrollbar-track:active {
          background: #ffffff;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          /* padding: 20px 0px; */
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          margin-top: 11px;
          align-items: ${({ rowAlignItems }) => rowAlignItems && rowAlignItems};
          // border-left: 1px solid #b5b5b5;
          // border-right: 1px solid #b5b5b5;
          // border-bottom: 1px solid #b5b5b5;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.32);
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S22};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_11};
            padding: ${({ paddingRow }) =>
    paddingRow ? paddingRow : "14px 15px"};
            width: 100%;
            /* border-right: 1px solid #e3e3e3; */
            display: flex;
            align-items: center;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ activeColor, theme }) =>
    activeColor ? activeColor : theme.COLORS.BLUE_41};
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_approve {
    height: 100%;
    .table_container {
      height: 100%;
      border: 0.5px solid ${({ theme }) => theme.COLORS.GRAY_21};
      border-radius: 7px;
      border-top: 0px;
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        background: ${({ theme }) => theme.COLORS.BLUE_25};
        border-radius: 7px;
        .header_row {
          display: flex;
          width: 100%;
          padding: 0px 24px;
          box-sizing: border-box;
          .header_col {
            width: 100%;
            padding: 18.7px 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S24};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        height: calc(100% - 65px);
        padding: 0px 24px;
        overflow: auto;
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        ::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-thumb {
          background: #70707066;
          border: 0px none #ffffff;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #70707066;
        }
        ::-webkit-scrollbar-thumb:active {
          background: #70707066;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff;
          border: 0px none #ffffff;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track:hover {
          background: #ffffff;
        }
        ::-webkit-scrollbar-track:active {
          background: #ffffff;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          margin-top: 7px;
          border-radius: 7px;
          align-items: ${({ rowAlignItems }) => rowAlignItems && rowAlignItems};
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.15);
          &:last-child {
            margin-bottom: 7px;
          }
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            padding: ${({ paddingRow }) =>
    paddingRow ? paddingRow : "16px 15px"};
            width: 100%;
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ activeColor, theme }) =>
    activeColor ? activeColor : theme.COLORS.BLUE_41};
            border-color: ${({ theme }) => theme.COLORS.BLUE_42};
          }
          &.row_acitve {
            background: ${({ activeColor, theme }) =>
    activeColor ? activeColor : theme.COLORS.BLUE_41};
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_reward {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_21};
          .header_col {
            width: 100%;
            padding: 11.6px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S24};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_11};
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        margin-top: 16px;
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          align-items: center;
          margin-top: 8px;
          align-items: ${({ rowAlignItems }) => rowAlignItems && rowAlignItems};
          // border-left: 1px solid #b5b5b5;
          // border-right: 1px solid #b5b5b5;
          // border-bottom: 1px solid #b5b5b5;
          background: ${({ theme }) => theme.COLORS.GRAY_32}99;
          &:first-child {
            margin-top: 0px;
          }
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S22};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            padding: ${({ paddingRow }) =>
    paddingRow ? paddingRow : "11.6px"};
            width: 100%;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ activeColor, theme }) =>
    activeColor ? activeColor : theme.COLORS.GRAY_21 + "66"};
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_department {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid #b5b5b5;
        .header_row {
          display: flex;
          // align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 10px 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
            color: #171717;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_21};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.PURPLE_5};
          // padding: 10px 0;
          // align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid #b5b5b5;
          border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 10px 15px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.active {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
    marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  @media (max-height: 900px) {
    .theme_standard_table_activity_anvite_join {
      .table_container {
        .table_body {
          height: 200px;
        }
      }
    }
  }
  // @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
  //   .theme_standard_table_user {
  //   }
  // }
  // @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
  //   .theme_standard_table_user {
  //     .table_container {
  //       .table_header {
  //         .header_row {
  //           .header_col {
  //             font-size: ${({ theme }) => theme.FONT.SIZE.S15};
  //           }
  //         }
  //       }
  //       .table_body {
  //         .body_row {
  //           .body_col {
  //             font-size: ${({ theme }) => theme.FONT.SIZE.S10};
  //             padding-top: 15px;
  //             &:first-child {
  //               padding-top: 10px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .theme_department {
  //     .table_container {
  //       .table_header {
  //         .header_row {
  //           .header_col {
  //             font-size: ${({ theme }) => theme.FONT.SIZE.S15};
  //           }
  //         }
  //       }
  //       .table_body {
  //         .body_row {
  //           .body_col {
  //             font-size: ${({ theme }) => theme.FONT.SIZE.S10};
  //             padding-top: 15px;
  //             &:first-child {
  //               padding-top: 10px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
`;

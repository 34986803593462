import React, { useState, useEffect } from "react";
import { TabBarSelectStyled } from "./styled";
import cx from "classnames";
import { Buttons, Icons } from "components";

const TabBarSelect = ({
  tab_standard_tab_bar_select,
  tab_normal,
  tab_canter_tab_bar_select,
  tab_small,
  tabHeader,
  tabContent,
  fontFamilyTop,
  fontSizeTop,
  showBtn,
  onClick,
  onClickSeletedTab,
  initialValue,
  ...props
}) => {
  const customClass = cx({
    tab_standard_tab_bar_select: tab_standard_tab_bar_select,
    tab_canter_tab_bar_select: tab_canter_tab_bar_select,
    tab_small: tab_small,
    tab_normal: tab_normal,
  });

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  const handleClickTab = (key, data) => {
    setSelected(key);
    onClickSeletedTab && onClickSeletedTab(data);
  };

  return (
    <TabBarSelectStyled
      {...props}
      fontFamilyTop={fontFamilyTop}
      fontSizeTop={fontSizeTop}
    >
      <div className={customClass}>
        <div className="tab_container">
          <div className="box_tab">
            {tabHeader &&
              tabHeader.map((e, i) => (
                <div
                  key={i}
                  className={`tab_item ${selected === i ? "active" : ""}`}
                  onClick={() => handleClickTab(i, e)}
                >
                  <div className="group_tab">
                    <div className="top_tab">{e.label}</div>
                    <div className="bottom_tab">{e.labelBottom}</div>
                  </div>
                </div>
              ))}
          </div>
          {showBtn && (
            <div className="box_btn">
              <Buttons.BtnNormal
                theme_standard_btn_normal_red
                label="สร้าง"
                labelBottom="Create"
                svg={<Icons.Plus color="#FFFFFF" />}
                onClick={onClick}
              />
            </div>
          )}
        </div>
        {tabHeader && tabHeader[selected].component && (
          <div className="tab_content">{tabHeader[selected].component}</div>
        )}
      </div>
    </TabBarSelectStyled>
  );
};

export default TabBarSelect;

import styled from "styled-components";

export const BtnNormalStyled = styled.div`
  width: 100%;
  .theme_standard_btn_normal {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_normal_purple {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.BLUE_20};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S20};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_normal_green {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.BLUE_20};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: 10px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_normal_red_3 {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.RED_3};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_normal_red {
    .group_button {
      width: 100%;
      background: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.COLORS.BLUE_20};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ fontColor, theme }) =>
        fontColor ? fontColor : theme.COLORS.WHITE_1};
      &.disabled {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_light_blue {
  .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.BLUE_21};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "11.2px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "8px"};
      border: 1px solid
        ${({ theme }) => theme.COLORS.BLUE_21};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_blue_border_bg_white {
  .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "11.2px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "8px"};
      border: 1px solid
        ${({ theme }) => theme.COLORS.BLUE_24};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_light_blue_only_border {
  .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "11.2px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "8px"};
      border: 1px solid
        ${({ theme }) => theme.COLORS.BLUE_28};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.BLUE_28};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
   .theme_red_border_bg_white {
  .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "11.2px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "8px"};
      border: 1px solid
        ${({ theme }) => theme.COLORS.RED_10};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.RED_10};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }


  .theme_only_border {
    .group_button {
      width: 100%;
      background: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.COLORS.WHITE_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "4px"};
      border: 1px solid
        ${({ theme, fontColor }) =>
          fontColor ? fontColor : theme.COLORS.PURPLE_1};
      border-color: ${({ theme, fontColor, borderColor }) =>
        borderColor
          ? borderColor
          : fontColor
          ? fontColor
          : theme.COLORS.PURPLE_1};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme, fontColor }) =>
        fontColor ? fontColor : theme.COLORS.PURPLE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_only_border_red {
    .group_button {
      width: 100%;
      background: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.COLORS.WHITE_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      padding: ${({ padding }) => (padding ? padding : "8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "4px"};
      border: 1px solid
        ${({ theme, fontColor }) =>
          fontColor ? fontColor : theme.COLORS.RED_10};
      border-color: ${({ theme, fontColor, borderColor }) =>
        borderColor
          ? borderColor
          : fontColor
          ? fontColor
          : theme.COLORS.RED_10};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme, fontColor }) =>
        fontColor ? fontColor : theme.COLORS.RED_10};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border: none;
        cursor: not-allowed;
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_standard_btn_normal_red_round {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 22.5px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_normal_gray {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      padding: 13.2px 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      // align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        // display: flex;
        // align-items: center;
        // padding-bottom: 7px;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR}};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_round_gray_regular_font {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      // align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        // display: flex;
        // align-items: center;
        // padding-bottom: 7px;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_round_red {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 16px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_round_red_bold {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.BLUE_20};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: ${({ padding }) => (padding ? padding : "8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 5px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_pop_up {
    .group_button {
      width: 100%;
      background: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: ${({ padding }) => (padding ? padding : "8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 16px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ fontColor, theme }) =>
        fontColor ? fontColor : theme.COLORS.WHITE_1};
      border: ${({ borderColor }) =>
        borderColor ? `1px solid ${borderColor}` : "none"};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_pop_up_account {
    .group_button {
      width: 100%;
      background: ${({ theme, backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: ${({ padding }) => (padding ? padding : "8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 16px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ fontColor, theme }) =>
        fontColor ? fontColor : theme.COLORS.WHITE_1};
      border: ${({ borderColor }) =>
        borderColor ? `1px solid ${borderColor}` : "none"};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_round_gray {
    .group_button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 16px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_purple {
    .group_button {
      width: 100%;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.PURPLE_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "7px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_round_white {
    .group_button {
      width: 100%;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 16px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.RED_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
  .theme_standard_btn_log_in {
    .group_button {
      width: 100%;
      background: ${({ backgroundColor, theme }) =>
        backgroundColor ? backgroundColor : theme.COLORS.BLUE_21};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.MEDIUM};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
        padding: ${({ padding }) => (padding ? padding : "12px 8px")};
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      // box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme, fontColor }) =>
        fontColor ? fontColor : theme.COLORS.PURPLE_1};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_10};
        color: ${({ theme }) => theme.COLORS.GRAY_35};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_only_border_blue {
    .group_button {
      width: 100%;
      border-radius: ${({ borderRadius }) =>
        borderRadius ? borderRadius : "8px"};
      display: flex;  
      align-items: center;
      justify-content: center;
      width: 100%; 
      padding: ${({ paddingButton }) =>
        paddingButton ? paddingButton : "8px 20px"};
      border: 1px solid ${({ disabled, theme, borderColor }) =>
        disabled
          ? theme.COLORS.GRAY_22
          : borderColor
          ? borderColor
          : theme.COLORS.BLUE_21};
      background: ${({ theme, disabled, backgroundColor }) =>
        disabled
          ? theme.COLORS.GRAY_29
          : backgroundColor
          ? backgroundColor
          : theme.COLORS.BLUE_21};
      color: ${({ disabled, theme, fontColor }) =>
        disabled
          ? theme.COLORS.GRAY_24
          : fontColor
          ? fontColor
          : theme.COLORS.PURPLE_1};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.MEDIUM};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_bg_white {
    .group_button {
      width: 100%;
      border-radius: ${({ borderRadius }) =>
        borderRadius ? borderRadius : "8px"};
      display: flex;  
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: ${({ paddingButton }) =>
        paddingButton ? paddingButton : "8px 20px"};
      border: 1px solid ${({ disabled, theme, borderColor }) =>
        disabled
          ? theme.COLORS.GRAY_22
          : borderColor
          ? borderColor
          : theme.COLORS.BLUE_24};
      background: ${({ theme, disabled, backgroundColor }) =>
        disabled
          ? theme.COLORS.GRAY_22
          : backgroundColor
          ? backgroundColor
          : theme.COLORS.WHITE_1};
      color: ${({ disabled, theme, fontColor }) =>
        disabled
          ? theme.COLORS.GRAY_24
          : fontColor
          ? fontColor
          : theme.COLORS.PURPLE_1};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.MEDIUM};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_gray {
    .group_button {
      width: 100%;
      background: ${({ backgroundColor, theme }) =>
        backgroundColor ? backgroundColor : theme.COLORS.GRAY_22};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }

  .theme_standard_btn_normal_pink {
    .group_button {
      width: 100%;
      background: ${({ backgroundColor, theme }) =>
        backgroundColor ? backgroundColor : theme.COLORS.PINK_2};
      font-family: ${({ theme, fontFamilyTop }) =>
        fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      padding: 8px;
      text-align: center;
      border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : " 8px"};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme, fontColor }) =>
        fontColor ? fontColor : theme.COLORS.WHITE_1};
      &.disabled {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        background: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .svg_front {
        margin-right: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show {
        margin-left: 10px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-family: ${(props) =>
        props.fontFamilyBottom
          ? props.fontFamilyBottom
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${(props) =>
        props.fontSizeLabelButtom
          ? props.fontSizeLabelButtom
          : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
        props.colorBottom
          ? props.colorBottom
          : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
        props.marginTopBottomLabel ? props.marginTopBottomLabel : "1px"};
      text-align: center;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextOTPStyled } from "./styled";
import OtpInput from 'react-otp-input';

const TextOTP = ({
  theme_standard_otp,
  label,
  errors,
  maxLength = 6,
  onChange,
  ...props
}) => {
  const customClass = cx({
    theme_standard_otp: theme_standard_otp,
  });

  const [_otp, _setOtp] = useState('');

  useEffect(() => {
    if (_otp && _otp.length === maxLength) {
      fetchVerifyOTP();
    }
  }, [_otp]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChange = (e) => {
    onChange && onChange(e)
    _setOtp(e);
  };

  const fetchVerifyOTP = async () => {
   
  };

  return (
    <TextOTPStyled >
      <div className={customClass}>
        {label && <div className="label_otp">{label}</div>}
        <OtpInput
          value={_otp}
          containerStyle="body_otp"
          inputStyle="inputStyle"
          numInputs={maxLength}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          onChange={_handleChange}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextOTPStyled>
  );
};

TextOTP.propTypes = {};

export default TextOTP;

import styled from 'styled-components';

export const UploadSetImageStyled = styled.div`
  .theme_upload_set_image {
    display: flex;
    margin: -10px;
    flex-wrap: wrap;
    .show_img {
      height: ${({ heightBox }) => (heightBox ? heightBox : '231px')};
      border-radius: 12px;
      // width: ${({ width }) => (width ? width : 'calc(32.5% - 10px)')};
      width: 100%;
      margin: 10px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .close {
        position: absolute;
        right: 7px;
        top: 8px;
        cursor: pointer;
        z-index: 2;
      }
      .block_more {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // opacity: 0.5;
        background: #ffffff80;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        cursor: pointer;
        .num_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S50};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          position: relative;
        }
      }
    }
    .container_other {
      height: ${({ heightBox }) => (heightBox ? heightBox : '231px')};
      border-radius: 12px;
      width: ${({ width }) => (width ? width : 'calc(32.5% - 10px)')};
      margin: 10px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .show_other {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5;
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        position: relative;
      }
    }
    .layout_upload {
      width: 100%;
      .box_upload {
        height: ${({ heightBox }) => (heightBox ? heightBox : '228px')};
        border-radius: 8px;
        background: ${({ theme }) => theme.COLORS.GRAY_22};
        // width: ${({ width }) => (width ? width : 'calc(32.5% - 10px)')};
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        // -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        .show_svg {
          display: flex;
        }
        .h_text {
          margin-top: 1px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
      .btn_upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.GRAY_19};
        row-gap: 9px;
        .btn_width {
          width: 225px;
        }
      }
    }
    .btn_show {
      width: 100%;
      margin-top: 33px;
      padding: 0px 17%;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_upload_set_image {
      .show_img {
        // width: calc(31.5% - 10px);
        width: ${({ width }) => (width ? width : 'calc(31.5% - 10px)')};
      }
      .box_upload {
        // width: calc(31.5% - 10px);
        width: ${({ width }) => (width ? width : 'calc(31.5% - 10px)')};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_upload_set_image {
      .show_img {
        // width: calc(31% - 10px);
        width: ${({ width }) => (width ? width : 'calc(31% - 10px)')};
      }
      .box_upload {
        // width: calc(31% - 10px);
        width: ${({ width }) => (width ? width : 'calc(31% - 10px)')};
      }
    }
  }
`;

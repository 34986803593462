export default function Activiness({ color = '#575D96' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.893"
      height="25.592"
      viewBox="0 0 27.893 25.592"
    >
      <g
        id="running_1_"
        data-name="running (1)"
        transform="translate(0 -23.054)"
      >
        <g
          id="Group_13952"
          data-name="Group 13952"
          transform="translate(1.634 25.766)"
        >
          <g id="Group_13951" data-name="Group 13951">
            <path
              id="Path_19075"
              data-name="Path 19075"
              d="M34.957,68.5h-4.14a.817.817,0,1,0,0,1.634h4.14a.817.817,0,1,0,0-1.634Z"
              transform="translate(-30 -68.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13954"
          data-name="Group 13954"
          transform="translate(1.634 32.303)"
        >
          <g id="Group_13953" data-name="Group 13953">
            <path
              id="Path_19076"
              data-name="Path 19076"
              d="M34.957,188.5h-4.14a.817.817,0,1,0,0,1.634h4.14a.817.817,0,1,0,0-1.634Z"
              transform="translate(-30 -188.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13956"
          data-name="Group 13956"
          transform="translate(0 29.034)"
        >
          <g id="Group_13955" data-name="Group 13955">
            <path
              id="Path_19077"
              data-name="Path 19077"
              d="M4.957,128.5H.817a.817.817,0,0,0,0,1.634h4.14a.817.817,0,0,0,0-1.634Z"
              transform="translate(0 -128.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13958"
          data-name="Group 13958"
          transform="translate(10.678 28.216)"
        >
          <g id="Group_13957" data-name="Group 13957">
            <path
              id="Path_19078"
              data-name="Path 19078"
              d="M211.58,118.381h-4.957v-3.269a1.637,1.637,0,0,0-2.79-1.155l-6.537,6.537a1.634,1.634,0,0,0,0,2.311l3.747,3.747-4.565,4.565a1.634,1.634,0,1,0,2.311,2.311l5.72-5.72a1.634,1.634,0,0,0,0-2.311l-3.747-3.747,2.592-2.592v.958a1.634,1.634,0,0,0,1.634,1.634h6.592a1.634,1.634,0,0,0,0-3.269Z"
              transform="translate(-196 -113.477)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13960"
          data-name="Group 13960"
          transform="translate(8.226 24.54)"
        >
          <g id="Group_13959" data-name="Group 13959">
            <path
              id="Path_19079"
              data-name="Path 19079"
              d="M158.034,46.281a1.635,1.635,0,0,0-2.062.2l-4.494,4.494a1.634,1.634,0,1,0,2.311,2.311l3.547-3.547,1.195.861,1.6-1.6a3.25,3.25,0,0,1,.926-.636Z"
              transform="translate(-150.999 -46.006)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13962"
          data-name="Group 13962"
          transform="translate(0.872 37.225)"
        >
          <g id="Group_13961" data-name="Group 13961">
            <path
              id="Path_19080"
              data-name="Path 19080"
              d="M25.946,280.325a3.239,3.239,0,0,1-.836-1.475l-8.631,8.631a1.634,1.634,0,0,0,2.311,2.311L27.1,281.48Z"
              transform="translate(-16 -278.85)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13964"
          data-name="Group 13964"
          transform="translate(19.499 23.054)"
        >
          <g
            id="Group_13963"
            data-name="Group 13963"
            transform="translate(0 0)"
          >
            <circle
              id="Ellipse_1702"
              data-name="Ellipse 1702"
              cx="3.047"
              cy="3.047"
              r="3.047"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

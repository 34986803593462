export default function Plus({ color = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.845" height="18.845" viewBox="0 0 18.845 18.845">
      <g id="Group_33119" data-name="Group 33119" transform="translate(-928.945 -1405.933) rotate(45)">
        <g id="Group_33118" data-name="Group 33118" transform="translate(1657.671 330.619)">
          <path id="Path_49742" data-name="Path 49742" d="M1910.568,595.148a1.691,1.691,0,0,1-1.2-2.887l9.943-9.943a1.691,1.691,0,0,1,2.392,2.392l-9.944,9.943A1.685,1.685,0,0,1,1910.568,595.148Z" transform="translate(-1908.876 -581.823)" fill={color} />
          <path id="Path_49743" data-name="Path 49743" d="M1920.511,595.148a1.686,1.686,0,0,1-1.2-.5l-9.943-9.943a1.691,1.691,0,0,1,2.391-2.392l9.944,9.943a1.691,1.691,0,0,1-1.2,2.887Z" transform="translate(-1908.876 -581.823)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

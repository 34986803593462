import styled from "styled-components";

export const MaleFemaleAverageResearchStyled = styled.div`
  .top_men {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    row-gap: 16px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 6px;
        display: flex;
      }
    }
    .top_main_right {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .label_layout {
        display: flex;
        .show_icon {
        }
        .show_label {
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          margin-left: 8px;
        }
      }
      .date_layout {
        color: ${({ theme }) => theme.COLORS.GRAY_12};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
      }
    }
    .right_men_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .date_show {
        margin-right: 19px;
        display: flex;
        padding-top: 13px;
        .show_icon {
          margin-right: 9px;
        }
        .date_layout {
          padding-top: 2px;
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
      .show_search {
      }
    }
  }
  .title_graph {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    padding-left: 10px;
  }
  .bottom_men {
    display: flex;
    column-gap: 48px;
    row-gap: 48px;
    flex-wrap: wrap;
    .b_left_layout {
      flex: 1;
      position: relative;
      min-width: 580px;
      .body_graph {
        padding: 0px 20px;
        tspan {
          &:first-child {
            font-weight: bold;
          }
        }
      }
      .arrow_left {
        display: flex;
        position: absolute;
        bottom: 23px;
        left: -5px;
        z-index: 2;
      }
      .arrow_right {
        display: flex;
        position: absolute;
        bottom: 23px;
        right: -22px;
        z-index: 2;
      }
    }
    .right_men_layout {
      width: 490px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .group_show {
        .box_well {
          width: 28%;
          min-width: 260px;
          &.label_w {
            width: 280px;
            flex-shrink: 0;
          }
        }
        .box_title {
          position: relative;
          .title_show {
            position: absolute;
            top: -80px;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            .sub_label {
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            }
          }
        }
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 32px;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
    .seleton_body {
      width: 100%;
    }
  }
  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  // @media (max-width: 1060px) {
  //   .bottom_men {
  //     flex-direction: column;
  //     .body_graph {
  //       margin-bottom: 35px;
  //     }
  //   }
  // }
  // @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  //   .bottom_men {
  //     .b_left_layout {
  //       .arrow_left {
  //         bottom: 400px;
  //       }
  //       .arrow_right {
  //         bottom: 400px;
  //         right: 7px;
  //       }
  //     }
  //   }
  // }

  // @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  //   .bottom_men {
  //     .b_left_layout {
  //       .arrow_left {
  //         bottom: 374px;
  //       }
  //       .arrow_right {
  //         bottom: 374px;
  //         right: 7px;
  //       }
  //     }
  //   }
  // }
`;

import React from 'react';
import { BMIContainerStyled } from './styled';
import { Blocks, Typographys, Icons, Tabs } from 'components';
import { CurrentBMIStatContainer,  } from './Tabs';

class BMIContainer extends React.Component {
  state = {};
 
  render() {
    return (
      <BMIContainerStyled>
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label="BMI Health Dashboard"
              svgFront={<Icons.BMI3 />}
            />
          </div>
          <Tabs.TabBarSelect
            tab_standard_tab_bar_select
            tabHeader={headerTab}
          />
        </Blocks.Container>
      </BMIContainerStyled>
    );
  }
}

const headerTab = [
  {
    label: 'ข้อมูลสถิติ BMI',
    labelBottom: 'Current BMI Stat',
    component: <CurrentBMIStatContainer />,
  },
  // {
  //   label: 'ข้อมูล A.I.',
  //   labelBottom: 'A.I. BMI Cluster',
  //   component: <AIBMIContainer />,
  // },
];

export default BMIContainer;

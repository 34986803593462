import styled from 'styled-components'

export const BarPercent2Styled = styled.div`
  .theme_standard_bar_percent2 {
    .top_bar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .line_bar {
        background: ${({ theme }) => theme.COLORS.RED_5};
        width: 78%;
        display: flex;
        border-radius: 18px;
        height: 24px;
        overflow: hidden;
        .left_bar {
          background: ${({ theme }) => theme.COLORS.RED_6};
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }
      }
      .svg {
        display: flex;
      }
    }
    .bot_bar {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      display: flex;
      justify-content: space-between;
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        .highlight {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          color: ${({ theme }) => theme.COLORS.BLACK_10};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        } 
      }
    }
  }

  .theme_normal_bar_percent2 {
    .top_bar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .line_bar {
        background: ${({ theme }) => theme.COLORS.GRAY_22};
        width: 78%;
        display: flex;
        border-radius: 18px;
        height: 24px;
        overflow: hidden;
        .left_bar {
          background: ${({ theme }) => theme.COLORS.BLUE_21};
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }
      }
      .svg {
        display: flex;
      }
    }
    .bot_bar {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      display: flex;
      justify-content: space-between;
      .group_label {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .highlight {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          color: ${({ theme }) => theme.COLORS.BLACK_10};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        } 
      }
    }
  }

  .theme_normal_bar_percent3 {
    .top_bar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .line_bar {
        background: ${({ theme }) => theme.COLORS.GRAY_22};
        width: 78%;
        display: flex;
        border-radius: 18px;
        height: 24px;
        overflow: hidden;
        .left_bar {
          background: ${({ theme }) => theme.COLORS.BLUE_21};
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }
      }
      .svg {
        display: flex;
      }
    }
    .bot_bar {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      display: flex;
      justify-content: space-between;
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .highlight {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          color: ${({ theme }) => theme.COLORS.BLACK_10};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        } 
      }
    }
  }
`

import styled from "styled-components";

export const ListStyled = styled.div`
  /* max-width: 658px;
  height: 485px;
  width: 80vw;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  color: ${({ theme }) => theme.COLORS.BLACK_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S18};
  font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
  /* padding: 16px 32px; */
  /* border-radius: 8px;
  box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75); */
  */ .group_list_emp {
    height: 100%;
    .top_list {
      padding: 16px 32px;
      display: flex;
      column-gap: 32px;
      position: relative;
      .group_text {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .small_1 {
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
        .small_2 {
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
        .small_3 {
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        }
      }
      .svg_x {
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 8px;
      }
    }
    /* .empty {
      text-align: center;
      margin-top: 50px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    } */
    .show_data {
      padding: 16px 32px;
      /* margin-top: 18px; */
      display: flex;
      column-gap: 18px;
      row-gap: 12px;
      flex-wrap: wrap;
      // max-height: 319px;
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100% - 62px);
      overflow-x: hidden;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #f0f0f0;
      }

      .loading {
        margin-top: 10px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
  }

  .head_table {
    display: flex;
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    padding-bottom: 10px;

    .employee_id {
      width: 130px;
    }
  }
  .body_table {
    color: ${({ theme }) => theme.COLORS.GRAY_23};
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    padding: 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    display: flex;
    .employee_id {
      width: 130px;
    }
    .box_user_show_det {
      /* width: 48px; */
      height: fit-content;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      // align-items: center
      .img_av {
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        border: 1px solid #b6e7e8;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .img_blank {
        min-width: 20px;
        min-height: 19px;
        border-radius: 50%;
        border: 1px solid #b6e7e8;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar_show {
          width: 15px;
          height: 15px;
        }
      }
      .name {
        white-space: nowrap;
        width: 98%;
        overflow: hidden;
        text-overflow: ellipsis;
        /* margin-top: 5px; */
        color: #141414;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    width: 80vw;
    height: 80vh;
  }
`;

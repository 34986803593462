export default function renderIcon({
  color = "#4E5B7E",
  width = "38",
  height = "38",
}) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.201 6.6645V4.16345C17.201 3.2426 16.451 2.49609 15.5258 2.49609H4.6371C3.71192 2.49609 2.96191 3.2426 2.96191 4.16345V15.835C2.96191 16.7558 3.71192 17.5023 4.6371 17.5023H8.82508"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.19727 12.5028H9.66306"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.19727 9.16684H10.5007"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.19727 5.93637H13.851"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0758 9.16684H13.3066"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4477 9.16797V12.3209L18.6196 15.7891C18.9413 16.3026 18.9581 16.9493 18.6636 17.4787C18.3691 18.0081 17.8096 18.3372 17.2016 18.3385H13.1853C12.5763 18.3393 12.0148 18.0111 11.7188 17.4812C11.4229 16.9514 11.4392 16.3035 11.7614 15.7891L13.9333 12.3209V9.16797"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5619 12.5028H13.8213"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.57993 12.607C5.63775 12.607 5.68463 12.5603 5.68463 12.5028C5.68463 12.4452 5.63775 12.3986 5.57993 12.3986C5.52211 12.3986 5.47523 12.4452 5.47523 12.5028C5.47523 12.5603 5.52211 12.607 5.57993 12.607"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.57993 9.27105C5.63775 9.27105 5.68463 9.22439 5.68463 9.16684C5.68463 9.10929 5.63775 9.06263 5.57993 9.06263C5.52211 9.06263 5.47523 9.10929 5.47523 9.16684C5.47523 9.22439 5.52211 9.27105 5.57993 9.27105"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.57993 6.04058C5.63775 6.04058 5.68463 5.99392 5.68463 5.93637C5.68463 5.87882 5.63775 5.83216 5.57993 5.83216C5.52211 5.83216 5.47523 5.87882 5.47523 5.93637C5.47523 5.99392 5.52211 6.04058 5.57993 6.04058"
        stroke="#002366"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

import styled from 'styled-components';

export const ShowDateStyled = styled.div`
  .theme_standard_show_date {
    position: relative;
    .top_date {
      display: flex;
      font-family: noto_sans_thai_regular, noto_sans_regular;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .show_label {
        margin-right: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
      .show_month {
        display: flex;
        margin-right: 10px;
        flex-direction: column;
        line-height: 1.1;
        justify-content: center;
        // text-align: center;
        .bottom_month {
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
      }
      .show_year {
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
    }
    .bottom_date {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      text-align: right;
      position: absolute;
      top: 45px;
      right: 0px;
    }
  }
  .theme_standard_show_date_mini {
    position: relative;
    .top_date {
      display: flex;
      align-items: center;
      font-family: noto_sans_thai_regular, noto_sans_regular;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .show_label {
        margin-right: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        &.no_margin {
          margin-right: 0px;
        }
      }
      .show_month {
        display: flex;
        margin-right: 10px;
        flex-direction: column;
        line-height: 1.1;
        justify-content: center;
        // text-align: center;
        .bottom_month {
          margin-top: 5px;
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
      }
      .show_year {
        font-family: noto_sans_thai_bold, noto_sans_bold;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        color: ${({ theme }) => theme.COLORS.RED_3};
      }
    }
    .bottom_date {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      text-align: right;
      position: absolute;
      top: 45px;
      right: 0px;
    }
  }
  .theme_nutrition_show_date {
    position: relative;
    .top_date {
      display: flex;
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .show_label {
        margin-right: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
      .show_month {
        display: flex;
        margin-right: 10px;
        flex-direction: column;
        line-height: 1.1;
        justify-content: center;
        .bottom_month {
        }
      }
      .show_year {
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
    }
    .bottom_date {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      text-align: right;
      position: absolute;
      top: 45px;
      right: 0px;
    }
  }
  .theme_red_show_date {
    position: relative;
    .top_date {
      display: flex;
      font-family: noto_sans_thai_regular, noto_sans_regular;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .show_label {
        margin-right: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
      .show_month {
        display: flex;
        margin-right: 10px;
        flex-direction: column;
        line-height: 1.1;
        justify-content: center;
        .bottom_month {
          color: ${({ theme }) => theme.COLORS.GRAY_4};
        }
      }
      .show_year {
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
      }
    }
    .bottom_date {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.RED_3};
      text-align: right;
      position: absolute;
      top: 45px;
      right: 0px;
    }
  }
`;

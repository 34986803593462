export default function Male4() {
  return (
    <svg id="flat" xmlns="http://www.w3.org/2000/svg" width="29.407" height="29.88" viewBox="0 0 29.407 29.88">
      <path id="Path_19163" data-name="Path 19163" d="M49.329,344l9.707,4.854a3.938,3.938,0,0,1,2.138,2.966l.233,1.633H32l.233-1.633a3.938,3.938,0,0,1,2.138-2.966L44.078,344Z" transform="translate(-32 -323.573)" fill="#026ca2" />
      <path id="Path_19164" data-name="Path 19164" d="M216,344h5.251v9.452H216Z" transform="translate(-203.922 -323.573)" fill="#cbcbcb" />
      <path id="Path_19165" data-name="Path 19165" d="M208.525,312v2.1L208,315.676v.525h6.3v-.525l-.525-1.575V312Z" transform="translate(-196.447 -293.852)" fill="#fdc9a6" />
      <path id="Path_19166" data-name="Path 19166" d="M221.251,312v1.289l-.016.011a4.654,4.654,0,0,1-5.235-.011V312Z" transform="translate(-203.922 -293.886)" fill="#fdc9a6" />
      <path id="Path_19167" data-name="Path 19167" d="M221.251,312v1.289l-.016.011a4.654,4.654,0,0,1-5.235-.011V312Z" transform="translate(-203.922 -293.886)" fill="#e6af89" />
      <path id="Path_19168" data-name="Path 19168" d="M72,450.626v-2.1a.525.525,0,1,1,1.05,0v2.1Z" transform="translate(-69.374 -420.746)" fill="#015078" />
      <path id="Path_19169" data-name="Path 19169" d="M424,450.626v-2.1a.525.525,0,0,1,1.05,0v2.1Z" transform="translate(-398.268 -420.746)" fill="#015078" />
      <path id="Path_19170" data-name="Path 19170" d="M230.857,376.825l.6,1.8h2.1l.6-1.8-1.65-.825Z" transform="translate(-217.804 -353.58)" fill="#0296e5" />
      <path id="Path_19171" data-name="Path 19171" d="M232.525,416,232,420.726h3.151L234.626,416Z" transform="translate(-218.872 -390.846)" fill="#026ca2" />
      <path id="Path_19172" data-name="Path 19172" d="M160.131,50.628l.225-.893a6.033,6.033,0,0,0-1.284-5.414,5.523,5.523,0,0,0-5.674-2.07l-1.026.239a4.992,4.992,0,0,0-3.335,2.63H148.9a2.872,2.872,0,0,0-2.843,3.278l.357,2.5.191,1.575h12.944l.432-1.313Q160.064,50.9,160.131,50.628Z" transform="translate(-138.54 -42.107)" fill="#55300b" />
      <path id="Path_19173" data-name="Path 19173" d="M181.251,125.5a4.654,4.654,0,0,1-4.321-2.925,13.008,13.008,0,0,1-.93-4.831v-3.8l1.575-2.674a8.932,8.932,0,0,0,6.373,2.674H186.5v3.8a13.007,13.007,0,0,1-.931,4.832A4.654,4.654,0,0,1,181.251,125.5Z" transform="translate(-166.548 -106.546)" fill="#fdc9a6" />
      <g id="Group_14074" data-name="Group 14074" transform="translate(11.028 8.455)">
        <path id="Path_19174" data-name="Path 19174" d="M274.1,169.05h-1.575a.525.525,0,1,1,0-1.05H274.1a.525.525,0,1,1,0,1.05Z" transform="translate(-267.274 -168)" fill="#55300b" />
        <ellipse id="Ellipse_1719" data-name="Ellipse 1719" cx="0.656" cy="0.656" rx="0.656" ry="0.656" transform="translate(5.251 1.575)" fill="#55300b" />
        <path id="Path_19175" data-name="Path 19175" d="M202.1,169.05h-1.575a.525.525,0,0,1,0-1.05H202.1a.525.525,0,0,1,0,1.05Z" transform="translate(-200 -168)" fill="#55300b" />
        <ellipse id="Ellipse_1720" data-name="Ellipse 1720" cx="0.656" cy="0.656" rx="0.656" ry="0.656" transform="translate(0.788 1.575)" fill="#55300b" />
      </g>
      <path id="Path_19176" data-name="Path 19176" d="M234.626,265.05h-2.1a.525.525,0,1,1,0-1.05h2.1a.525.525,0,1,1,0,1.05Z" transform="translate(-218.872 -249.119)" fill="#e6af89" />
      <path id="Path_19177" data-name="Path 19177" d="M241.575,210.626h-1.05A.525.525,0,0,1,240,210.1v-1.575a.525.525,0,1,1,1.05,0v1.05h.525a.525.525,0,0,1,0,1.05Z" transform="translate(-226.347 -196.861)" fill="#e6af89" />
      <path id="Path_19178" data-name="Path 19178" d="M146.1,176h-.726A1.375,1.375,0,0,0,144,177.375v.926a1.375,1.375,0,0,0,1.375,1.375h.923Z" transform="translate(-136.648 -167.001)" fill="#fdc9a6" />
      <path id="Path_19179" data-name="Path 19179" d="M333.2,176h.726a1.375,1.375,0,0,1,1.375,1.375v.926a1.375,1.375,0,0,1-1.375,1.375H333Z" transform="translate(-313.242 -167.001)" fill="#fdc9a6" />
      <path id="Path_19180" data-name="Path 19180" d="M208.525,344l2.626,2.1L208,347.676v-2.1Z" transform="translate(-196.447 -323.71)" fill="#ebebeb" />
      <path id="Path_19181" data-name="Path 19181" d="M258.626,344,256,346.1l3.151,1.575v-2.1Z" transform="translate(-241.296 -323.71)" fill="#ebebeb" />
      <path id="Path_19182" data-name="Path 19182" d="M156.2,353.452h-2.626L152,348.726,156.2,344Z" transform="translate(-144.123 -323.573)" fill="#0296e5" />
      <path id="Path_19183" data-name="Path 19183" d="M296,353.452h2.626l1.575-4.726L296,344Z" transform="translate(-278.671 -323.573)" fill="#0296e5" />
    </svg>
  );
}

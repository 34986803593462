import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { BoxNoticStyled } from './styled'
import { Buttons, } from 'components';

const BoxNotic = ({
  theme_box_notic,
  handleCancel,
  textShow,
  textBottom,
  labelCancel = 'รับทราบ',
}) => {
  const customClass = cx({
    theme_box_notic: theme_box_notic,
  })
  return (
    <BoxNoticStyled>
      <div className={customClass}>
        <div className='notic_box'>
          <div className='title_box'>
            <div className='group_label'>
              <div className='t_label'>
                {
                  textShow
                    ?
                    textShow
                    :
                    'ยืนยันการ'
                }
              </div>
              {
                textBottom &&
                <div className='b_label'>
                  {textBottom}
                </div>
              }
            </div>
          </div>
          <div className='action_btn'>
            <div className='body_btn'>
              <Buttons.BtnNormal
                theme_only_border
                label={labelCancel}
                fontSize="20px"
                fontFamily="noto_sans_regular, noto_sans_thai_thin"
                padding="10px"
                borderRadius='8px'
                fontColor='#7B7B7B'
                borderColor='#7B7B7B'
                backgroundColor="#fff"
                onClick={handleCancel}
              />
            </div>
            {/* <div className='body_btn'>
              <Buttons.BtnNormal
                theme_only_border
                label={labelConfirm}
                fontSize="20px"
                fontFamily="noto_sans_regular, noto_sans_thai_thin"
                padding="10px"
                borderRadius='8px'
                backgroundColor="#575D96"
                borderColor='#575D96'
                fontColor='#fff'
                onClick={handleConfirm}
              />
            </div> */}
          </div>
        </div>
      </div>
    </BoxNoticStyled>
  )
}

BoxNotic.propTypes = {}

export default BoxNotic

export default function Reward6() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20.619" viewBox="0 0 32 20.619">
      <g id="Group_13914" data-name="Group 13914" transform="translate(4046.448 20786.764)">
        <path id="Path_1260" data-name="Path 1260" d="M7.507,242.9l-6.49-1.742a.571.571,0,0,0-.035.145,4.982,4.982,0,0,0,1.988,3.282h0a.614.614,0,0,0-.035.145c-.024,1.6,2.113,3.316,2.113,3.316a.707.707,0,0,0-.035.145,4.466,4.466,0,0,0,2.015,3.29l3.564.957Z" transform="translate(-4047.369 -21024.227)" fill="#ffe27a" />
        <g id="Group_330" data-name="Group 330" transform="translate(-4046.448 -20782.93)">
          <path id="Path_1261" data-name="Path 1261" d="M66.565,355.813a2.8,2.8,0,0,1-2.015-2.129,2.8,2.8,0,0,0,2.015,3.29l3.564.956.138-.721-.906-.646Z" transform="translate(-60.458 -346.797)" fill="#f9cf58" />
          <path id="Path_1262" data-name="Path 1262" d="M33.31,300.436a2.8,2.8,0,0,1-2.015-2.128,2.8,2.8,0,0,0,2.015,3.29l4.006,1.075.128-.686-.566-.593Z" transform="translate(-29.282 -294.881)" fill="#f9cf58" />
          <path id="Path_1263" data-name="Path 1263" d="M2.079,245.6a2.8,2.8,0,0,1-2.015-2.128,2.8,2.8,0,0,0,2.015,3.29L7.1,248.111l.065-.648-.31-.579Z" transform="translate(-0.002 -243.474)" fill="#f9cf58" />
        </g>
        <path id="Path_1264" data-name="Path 1264" d="M360.35,242.9l6.49-1.742a.57.57,0,0,1,.035.145,4.982,4.982,0,0,1-1.988,3.282h0a.618.618,0,0,1,.035.145c.024,1.6-2.113,3.316-2.113,3.316a.7.7,0,0,1,.035.145,4.466,4.466,0,0,1-2.015,3.29l-3.564.957Z" transform="translate(-4381.385 -21024.227)" fill="#ffe27a" />
        <path id="Path_1265" data-name="Path 1265" d="M358.756,355.813a2.8,2.8,0,0,0,2.015-2.129,2.8,2.8,0,0,1-2.015,3.29l-3.564.956-.138-.721.906-.646Z" transform="translate(-4379.311 -21129.723)" fill="#f9cf58" />
        <path id="Path_1266" data-name="Path 1266" d="M385.548,300.436a2.8,2.8,0,0,0,2.015-2.128,2.8,2.8,0,0,1-2.015,3.29l-4.006,1.075-.128-.686.566-.593Z" transform="translate(-4404.024 -21077.811)" fill="#f9cf58" />
        <path id="Path_1267" data-name="Path 1267" d="M402.525,245.6a2.8,2.8,0,0,0,2.015-2.128,2.8,2.8,0,0,1-2.015,3.29l-5.019,1.347-.065-.648.31-.579Z" transform="translate(-4419.049 -21026.402)" fill="#f9cf58" />
        <path id="Path_1268" data-name="Path 1268" d="M112.839,182.115l9.544,4.014c-.639,12.251-9.544,16.042-9.544,16.042s-8.786-4.1-9.544-16.042Z" transform="translate(-4143.278 -20968.879)" fill="#ffcb5b" />
        <path id="Path_1269" data-name="Path 1269" d="M112.954,261.114a.319.319,0,0,1-.23,0c-.99-.381-8.084-3.509-9.331-15.442l-.1.041c.972,13.8,9.544,16.6,9.544,16.6s8.572-2.8,9.544-16.6l-.1-.041C121.038,257.605,113.944,260.734,112.954,261.114Z" transform="translate(-4143.287 -21028.463)" fill="#f7b737" />
        <path id="Path_1276" data-name="Path 1276" d="M152.538,228.163l6.784,2.853c-.577,8.487-6.906,11.451-6.906,11.451a14.582,14.582,0,0,1-6.906-11.451l6.784-2.853A.312.312,0,0,1,152.538,228.163Z" transform="translate(-4182.865 -21012.025)" fill="#ff9838" />
        <path id="Path_1277" data-name="Path 1277" d="M152.3,284.318c-.828-.329-5.705-2.6-6.68-10.853l-.106.045c.7,9.987,6.906,12.014,6.906,12.014s6.2-2.027,6.906-12.014l-.106-.045c-.975,8.249-5.852,10.524-6.68,10.853A.323.323,0,0,1,152.3,284.318Z" transform="translate(-4182.865 -21054.516)" fill="#ff8709" />
        <path id="Intersection_1" data-name="Intersection 1" d="M0,.812,1.872.024a.313.313,0,0,1,.242,0L3.986.812Z" transform="translate(-4032.441 -20783.885)" fill="#e88a33" />
        <g id="Group_13670" data-name="Group 13670" transform="translate(-4035.983 -20782.42)">
          <path id="Path_18957" data-name="Path 18957" d="M106.293,127.107a1.291,1.291,0,0,0,.156-.324l.006-.017c.013-.041.023-.082.031-.125l0-.015a1.27,1.27,0,0,0,.022-.212c0-.01,0-.02,0-.031,0-.033,0-.066-.006-.1s0-.047-.007-.071-.011-.058-.017-.087-.013-.055-.021-.083-.016-.052-.026-.078-.024-.061-.038-.091-.021-.046-.033-.068-.04-.067-.062-.1c-.012-.018-.022-.036-.035-.053a1.311,1.311,0,0,0-.124-.145l-4.255-4.255a1.256,1.256,0,0,0-.875-.368h-.017a1.258,1.258,0,0,0-.892.369l-4.255,4.256a1.25,1.25,0,0,0-.3.492l0,0a1.209,1.209,0,0,0-.044.175h0a1.24,1.24,0,0,0-.014.3s0,.01,0,.015c0,.038.009.076.015.113,0,.012,0,.024.006.035s0,.029.008.042l0-.005,0,.008c0,.012.006.025.009.037a1.339,1.339,0,0,0,.051.152v0a1.29,1.29,0,0,0,.176.3l.009.011a1.257,1.257,0,0,0,.111.122l.006.006a1.212,1.212,0,0,0,.293.205l.008,0a1.2,1.2,0,0,0,.152.062l.034.011a1.246,1.246,0,0,0,.145.034l.039.007a1.331,1.331,0,0,0,.188.015h8.511a1.332,1.332,0,0,0,.186-.014l.055-.01a1.218,1.218,0,0,0,.121-.028l.058-.018c.038-.013.076-.029.112-.045l.047-.021a1.191,1.191,0,0,0,.139-.083c.014-.01.026-.021.04-.031.03-.023.059-.045.086-.07s.036-.035.054-.053.038-.04.055-.061.036-.044.053-.066S106.281,127.125,106.293,127.107Z" transform="translate(-95.479 -119.994)" fill="#5c5c5c" opacity="0.303" />
          <g id="Group_13669" data-name="Group 13669" transform="translate(0 0)">
            <g id="Group_13667" data-name="Group 13667">
              <path id="Path_18946" data-name="Path 18946" d="M100.109,116.254a1.257,1.257,0,0,1,.892-.369v6.777H96.745a1.261,1.261,0,0,1-.892-2.153Z" transform="translate(-95.479 -115.885)" fill="#f8eaea" />
              <path id="Path_18947" data-name="Path 18947" d="M128.89,116.254a1.257,1.257,0,0,0-.892-.369v6.777h4.256a1.261,1.261,0,0,0,.892-2.153Z" transform="translate(-122.48 -115.885)" fill="#fff5f5" />
              <path id="Path_18948" data-name="Path 18948" d="M106.47,146.455H95.524a1.262,1.262,0,0,0,1.217,1.592h8.511A1.262,1.262,0,0,0,106.47,146.455Z" transform="translate(-95.479 -141.269)" fill="#efe2dd" />
              <path id="Path_18949" data-name="Path 18949" d="M95.84,121.094l4.255-4.256a1.261,1.261,0,0,1,1.783,0l4.255,4.256a1.244,1.244,0,0,1,.335.6,1.235,1.235,0,0,0-.335-1.181l-4.255-4.256a1.262,1.262,0,0,0-1.783,0l-4.255,4.256a1.235,1.235,0,0,0-.335,1.181A1.246,1.246,0,0,1,95.84,121.094Z" transform="translate(-95.47 -115.885)" fill="#fff" />
            </g>
            <g id="Group_13668" data-name="Group 13668" transform="translate(3.111 6.777)">
              <path id="Path_18950" data-name="Path 18950" d="M113.813,164.05l2.067,1.193a.678.678,0,0,0,.679,0l2.067-1.193v-8.213h-4.812v8.213Z" transform="translate(-113.813 -155.837)" fill="#fff5f5" />
              <path id="Path_18951" data-name="Path 18951" d="M116.559,199.446a.678.678,0,0,1-.679,0l-2.067-1.194v1.018l2.067,1.193a.678.678,0,0,0,.679,0l2.067-1.193v-1.018Z" transform="translate(-113.813 -191.057)" fill="#efe2dd" />
              <path id="Path_18952" data-name="Path 18952" d="M113.812,163.033l.835.482,1.233.712a.679.679,0,0,0,.339.091v-8.479h-2.406v7.195Z" transform="translate(-113.812 -155.838)" fill="#f8eaea" />
              <rect id="Rectangle_941" data-name="Rectangle 941" width="4.812" height="1.669" transform="translate(0 0)" fill="#f8eaea" />
              <path id="Path_18953" data-name="Path 18953" d="M138.5,155.838v7.556l.625-.361v-7.2Z" transform="translate(-134.312 -155.838)" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

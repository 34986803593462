export default function Bonus() {
  return (
    <svg id="_012-medal" data-name="012-medal" xmlns="http://www.w3.org/2000/svg" width="40.494" height="56" viewBox="0 0 40.494 56">
      <g id="Group_242" data-name="Group 242" transform="translate(0 0)">
        <path id="Path_964" data-name="Path 964" d="M174.453,341.4l-2.9,1.252-2.71-.734h-4.441l-2.872.719L158.8,341.4l-2.13-3.224-.988.934-.032,17.508a.567.567,0,0,0,.775.529l9.893-3.891a.567.567,0,0,1,.415,0l9.9,3.893a.567.567,0,0,0,.775-.527l.032-17.458-.981-.976Z" transform="translate(-146.381 -301.184)" fill="#c61926" />
        <path id="Path_965" data-name="Path 965" d="M209.436,375.285l4.785-1.882a.567.567,0,0,1,.415,0l4.823,1.9V363.963l-.984-1.185-3.946-1.981-4.109,1.956-.984,1.207Z" transform="translate(-194.282 -321.335)" fill="#ff4755" />
        <path id="Path_966" data-name="Path 966" d="M155.949,329.172l0,1.918a4.055,4.055,0,0,1,2.292,2.22,4.057,4.057,0,0,0,6.2,1.66,4.057,4.057,0,0,1,4.91,0,4.057,4.057,0,0,0,6.2-1.66,4.054,4.054,0,0,1,2.16-2.166l0-1.96Z" transform="translate(-146.642 -293.169)" fill="#b2182b" />
        <path id="Path_967" data-name="Path 967" d="M209.436,345.737v2.192a4.023,4.023,0,0,0,2.638-.823,4.057,4.057,0,0,1,4.91,0,4.021,4.021,0,0,0,2.475.827v-2.169l-4.93-2.966Z" transform="translate(-194.282 -305.304)" fill="#fc2b3a" />
        <path id="Path_968" data-name="Path 968" d="M93.355.75a3.67,3.67,0,0,1,5.6,1.5,3.67,3.67,0,0,0,3.846,2.221,3.67,3.67,0,0,1,4.1,4.1,3.67,3.67,0,0,0,2.221,3.846,3.67,3.67,0,0,1,1.5,5.6,3.67,3.67,0,0,0,0,4.441,3.67,3.67,0,0,1-1.5,5.6,3.67,3.67,0,0,0-2.249,3.48q0,.182.028.366c.3,2.38-1.723,3.421-4.1,3.119a3.67,3.67,0,0,0-3.846,2.221,3.67,3.67,0,0,1-5.6,1.5,3.67,3.67,0,0,0-4.441,0,3.67,3.67,0,0,1-5.6-1.5,3.67,3.67,0,0,0-3.846-2.221c-2.38.3-4.405-.739-4.1-3.119q.023-.184.028-.366a3.67,3.67,0,0,0-2.249-3.48,3.67,3.67,0,0,1-1.5-5.6,3.67,3.67,0,0,0,0-4.441,3.67,3.67,0,0,1,1.5-5.6,3.67,3.67,0,0,0,2.221-3.846,3.67,3.67,0,0,1,4.1-4.1,3.67,3.67,0,0,0,3.846-2.221,3.67,3.67,0,0,1,5.6-1.5,3.67,3.67,0,0,0,4.441,0Z" transform="translate(-70.887 0)" fill="#ffcb5b" />
        <g id="Group_238" data-name="Group 238" transform="translate(4.442 31.553)">
          <path id="Path_969" data-name="Path 969" d="M143.051,288.486a3.713,3.713,0,0,1-3.943,2.376,3.712,3.712,0,0,0-3.89,2.246,3.712,3.712,0,0,1-5.669,1.519,3.712,3.712,0,0,0-4.492,0,3.712,3.712,0,0,1-5.669-1.519,3.712,3.712,0,0,0-3.89-2.246,3.712,3.712,0,0,1-3.943-2.376c0,.121-.012.243-.027.366a3.67,3.67,0,0,0,4.1,4.1,3.67,3.67,0,0,1,3.846,2.221,3.67,3.67,0,0,0,5.6,1.5,3.67,3.67,0,0,1,4.441,0,3.67,3.67,0,0,0,5.6-1.5,3.67,3.67,0,0,1,3.846-2.221,3.67,3.67,0,0,0,4.1-4.1A3.632,3.632,0,0,1,143.051,288.486Z" transform="translate(-111.498 -288.486)" fill="#f7b737" />
        </g>
        <g id="Group_240" data-name="Group 240" transform="translate(6.103 6.069)">
          <g id="Group_239" data-name="Group 239">
            <path id="Path_970" data-name="Path 970" d="M140.829,82.411c-14.142,0-14.166-13.431-14.142-13.768a14.179,14.179,0,0,1,28.284,0C154.995,68.98,155.007,82.411,140.829,82.411Z" transform="translate(-126.685 -55.486)" fill="#ff9838" />
          </g>
        </g>
        <path id="Path_971" data-name="Path 971" d="M140.548,188.938a14.179,14.179,0,0,1-14.141-13.157c-.024.337-.037.678-.037,1.021a14.178,14.178,0,1,0,28.357,0q0-.515-.037-1.021A14.179,14.179,0,0,1,140.548,188.938Z" transform="translate(-120.302 -156.555)" fill="#ff8709" />
        <path id="Path_972" data-name="Path 972" d="M196.338,115.985l1.25,2.534a1.369,1.369,0,0,0,1.031.749l2.8.406a1.369,1.369,0,0,1,.759,2.335l-2.023,1.972a1.369,1.369,0,0,0-.394,1.212l.478,2.785a2.529,2.529,0,0,1,.011.4l-2,.054-3.138-1.65-3.138,1.65-1.991-.011a2.5,2.5,0,0,1,.005-.447l.478-2.785a1.369,1.369,0,0,0-.394-1.211l-2.023-1.972a1.369,1.369,0,0,1,.759-2.335l2.8-.406a1.369,1.369,0,0,0,1.031-.749l1.251-2.534a1.369,1.369,0,0,1,2.455,0Z" transform="translate(-174.863 -102.62)" fill="#fff5f5" />
        <g id="Group_241" data-name="Group 241" transform="translate(15.119 23.182)">
          <path id="Path_973" data-name="Path 973" d="M213.981,212.016l-4.864,2.557a1.37,1.37,0,0,0,1.99,1l2.5-1.315a1.369,1.369,0,0,1,1.274,0l2.5,1.315a1.371,1.371,0,0,0,1.99-1l-4.864-2.557A.567.567,0,0,0,213.981,212.016Z" transform="translate(-209.117 -211.951)" fill="#efe2dd" />
        </g>
      </g>
    </svg>
  );
}

export default function Control({
  width = '16',
  height = '16',
  color = '#000000',
}) {
  return (
    <svg
      id="fi-rr-settings-sliders"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        id="Path_7201"
        data-name="Path 7201"
        d="M.667,3.167H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,0,0,0-1.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333ZM4.889,1.333A1.167,1.167,0,1,1,3.722,2.5,1.167,1.167,0,0,1,4.889,1.333Z"
        transform="translate(0 0)"
        fill={color}
      />
      <path
        id="Path_7202"
        data-name="Path 7202"
        d="M15.333,10.083H13.509a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333H8.713a2.485,2.485,0,0,0,4.8,0h1.824a.667.667,0,0,0,0-1.333Zm-4.222,1.833a1.167,1.167,0,1,1,1.167-1.167,1.167,1.167,0,0,1-1.167,1.167Z"
        transform="translate(0 -2.75)"
        fill={color}
      />
      <path
        id="Path_7203"
        data-name="Path 7203"
        d="M15.333,18.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,0,0,0-1.333ZM4.889,20.167A1.167,1.167,0,1,1,6.055,19,1.167,1.167,0,0,1,4.889,20.167Z"
        transform="translate(0 -5.5)"
        fill={color}
      />
    </svg>
  );
}

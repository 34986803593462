import styled from "styled-components";

export const InputTextStyled = styled.div`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .label {
    margin-bottom: ${({ useLabel }) => (useLabel ? "1px" : "15px")};
    font-size: ${({ theme }) => theme.FONT.SIZE.S24};
    font-family: noto_sans_thai_regular, noto_sans_regular;
  }
  .sub_label {
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_regular, noto_sans_regular;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  .group_input_label {
    position: relative;
    input {
      position: relative;
      width: calc(100% - 30px);
      padding: 5px 15px;
      border-radius: 10px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
    }
    .bottom_label {
      position: absolute;
      top: 0px;
      right: -1px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_regular, noto_sans_regular;
    }
  }
  .error {
    font-size: 12px;
    font-family: "noto_sans_thai_regular, noto_sans_regular";
    color: #ff0000;
    padding-top: 2px;
  }

  .theme_standard {
  }
  .theme_input_normal {
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      border-radius: 12px;
      padding: 16px;
      border: 2px solid ${({ theme }) => theme.COLORS.BLUE_22};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      input {
        // border-radius: 12px;
        // padding: 16px;
        // border: 2px solid ${({ theme }) => theme.COLORS.PINK_3};
        padding: 0px;
        border-radius: 0px;
        border: none;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        width: ${({ labelBottom }) => (labelBottom ? "84%" : "100%")};
      }
    }
    .bottom_label {
      top: 16px;
      right: 16px;
    }
  }
  .theme_input_border_purple {
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      border-radius: 12px;
      padding: 16px;
      border: 2px solid ${({ theme }) => theme.COLORS.BLUE_15}59;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      input {
        // border-radius: 12px;
        // padding: 16px;
        // border: 2px solid ${({ theme }) => theme.COLORS.PINK_3};
        padding: 0px;
        border-radius: 0px;
        border: none;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        width: ${({ labelBottom }) => (labelBottom ? "84%" : "100%")};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
    .bottom_label {
      top: 16px;
      right: 16px;
    }
  }
  .theme_input_mini {
    border-radius: 12px;
    padding: 10.6px;
    border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      input {
        // border-radius: 12px;
        // border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
        padding: 0px;
        border: none;
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        border-radius: 0px;
        width: ${({ labelBottom }) => (labelBottom ? "79%" : "100%")};
      }
      .bottom_label {
        position: absolute;
        top: -3px;
        right: -1px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
    }
  }
  .theme_input_mini_border_purple {
    border-radius: 12px;
    padding: 10.6px;
    border: 2px solid ${({ theme }) => theme.COLORS.BLUE_15}59;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      input {
        // border-radius: 12px;
        // border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
        padding: 0px;
        border: none;
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        border-radius: 0px;
        width: ${({ labelBottom }) => (labelBottom ? "79%" : "100%")};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
      .bottom_label {
        position: absolute;
        top: -3px;
        right: -1px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
    }
  }
  .theme_input_create_account {
    border-radius: 10px;
    padding: 6px 14px;
    border: 2px solid ${({ theme }) => theme.COLORS.GRAY_10};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      input {
        // border-radius: 12px;
        // border: 2px solid ${({ theme }) => theme.COLORS.BLUE_11}20;
        padding: 0px;
        border: none;
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        border-radius: 0px;
        width: ${({ labelBottom }) => (labelBottom ? "79%" : "100%")};
      }
      .bottom_label {
        position: absolute;
        top: -3px;
        right: -1px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
    }
  }

  .theme_admin {
    border-radius: 8px;
    padding: 10px 14px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
    background: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_36 : theme.COLORS.WHITE_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      input {
        // border-radius: 12px;
        // border: 2px solid ${({ theme }) => theme.COLORS.BLUE_11}20;
        padding: 0px;
        border: none;
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ disabled, theme }) =>
          disabled ? theme.COLORS.GRAY_24 : theme.COLORS.PURPLE_1};
        border-radius: 0px;
        width: ${({ labelBottom }) => (labelBottom ? "79%" : "100%")};
      }
      .bottom_label {
        position: absolute;
        top: -3px;
        right: -1px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
    }
  }

  .theme_add_point {
    border-radius: 10px;
    padding: 6px 14px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      input {
        padding: 0px;
        border: none;
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        border-radius: 8px;
        width: ${({ labelBottom }) => (labelBottom ? "79%" : "100%")};
        color: ${({ theme }) => theme.COLORS.BLACK_8};
        height: 48px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: ${({ theme }) => theme.COLORS.BLACK_8}80;
          font-family: noto_sans_thai_regular, noto_sans_regular;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: ${({ theme }) => theme.COLORS.BLACK_8}80;
          font-family: noto_sans_thai_regular, noto_sans_regular;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: ${({ theme }) => theme.COLORS.BLACK_8}80;
          font-family: noto_sans_thai_regular, noto_sans_regular;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: ${({ theme }) => theme.COLORS.BLACK_8}80;
          font-family: noto_sans_thai_regular, noto_sans_regular;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
      }
    }
    .bottom_label {
      position: absolute;
      top: -3px;
      right: -1px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
    }
  }

  .theme_input_reward_exchange {
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      margin-bottom: 0px;
    }
    .sub_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      margin-bottom: 10px;
    }
    .group_input_label {
      border-radius: 12px;
      padding: 16px;
      border: 1px solid ${({ theme }) => theme.COLORS.RED_3};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      input {
        padding: 0px;
        border-radius: 8px;
        border: none;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        width: ${({ labelBottom }) => (labelBottom ? "84%" : "100%")};
      }
    }
    .bottom_label {
      top: 16px;
      right: 16px;
    }
  }
`;

import styled from "styled-components";

export const InputDropDownNormalStyled = styled.div`
  .theme_standard_drop_down_normal {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 16px;
        right: 15px;
      }
      .input_dropdown_control {
        padding: 16px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
        border-radius: 12px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_content {
    .label {
      margin-bottom: 4px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
           color: ${({ theme }) => theme.COLORS.GRAY_23};
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 15px;
        right: 8px;
      }
      .input_dropdown_control {
        padding: 11.6px 16px;
        padding-right: 29px;
        max-height: 48px;
        color: ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
        background: ${({ disabled }) =>
          disabled
            ? ({ theme }) => theme.COLORS.GRAY_28
            : ({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_30};
        &.is-selected {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 0px 0px 8px 8px;
        .Dropdown-option {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        }
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_40h {
    .label {
      margin-bottom: 4px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
           color: ${({ theme }) => theme.COLORS.GRAY_23};
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 11px;
        right: 8px;
      }
      .input_dropdown_control {
        padding: 7.2px 16px;
        padding-right: 29px;
        max-height: 48px;
        color: ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
        background: ${({ disabled }) =>
          disabled
            ? ({ theme }) => theme.COLORS.GRAY_28
            : ({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_30};
        &.is-selected {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 0px 0px 8px 8px;
        .Dropdown-option {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        }
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_border_purple {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 16px;
        right: 15px;
      }
      .input_dropdown_control {
        padding: 16px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        border: 2px solid ${({ theme }) => theme.COLORS.BLUE_15}59;
        border-radius: 12px;
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
        .Dropdown-option {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_lucky {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 15px;
        right: 8px;
      }
      .input_dropdown_control {
        padding: 11.2px 16px;
        padding-right: 26px;
        color: ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
        background: ${({ disabled }) =>
          disabled
            ? ({ theme }) => theme.COLORS.GRAY_28
            : ({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_30};
        &.is-selected {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        .Dropdown-option {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_standard_drop_down_account {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 7px;
        right: 15px;
      }
      .input_dropdown_control {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        border-radius: 10px;
        padding: 6px 14px;
        border: 2px solid ${({ theme }) => theme.COLORS.BLUE_11}20;
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .input_dropdown_menu {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        border: 1px solid ${({ theme }) => theme.COLORS.BLUE_11}20;
        .Dropdown-option {
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_mini_drop_down_normal {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 12px;
        right: 15px;
      }
      .input_dropdown_control {
        padding: 10px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
        border-radius: 12px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_standard_drop_down_create_account {
    .label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .input_container {
      position: relative;
      .arrow_icon {
        position: absolute;
        top: 16px;
        right: 15px;
      }
      .input_dropdown_control {
        padding: 11px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
        border-radius: 12px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      }
      .input_dropdown_placeholder {
        // color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      .input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      }
    }
    .error {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
`;

export const renderErrorMessage = (e, label) => {
  switch (e) {
    case "INVALID_PASSWORD":
      return "* ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบเเละกรอกใหม่อีกครั้ง";

    case "INVALID_PASSWORD_PROFILE":
      return "รหัสผ่านเก่าไม่ถูกต้อง";

    case "EMAIL_NOT_FOUND":
      return "* ไม่พบชื่อผู้ใช้งาน โปรดติดต่อผู้ดูแลระบบ";

    case "USERNAME_NOT_FOUND":
    case "LOGIN_REALM_MISSING":
      return "* ไม่พบชื่อผู้ใช้งาน โปรดติดต่อผู้ดูแลระบบ";

    case "EMAIL_INVALID_FORMAT":
      return "* รูปแบบ email ไม่ถูกต้อง";

    case "INACTIVE_ADMIN":
      return "* ชื่อผู้ใช้งานถูกระงับ โปรดติดต่อผู้ดูแลระบบ";

    case "DUPLICATE_MOBILE_NO":
      return "มีหมายเลขโทรศัทพ์นี้อยู่แล้ว";

    case "DUPLICATE_EMAIL":
      return "มี email นี้อยู่แล้ว";

    default:
      return label;
  }
};

import styled from "styled-components";

export const ProfileAccountContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layer_one {
    display: flex;
  }
  .layer_two {
    margin-top: 51px;
    margin-left: 10%;
  }
  .layer_delete {
    margin-top: 53px;
    padding: 2px 7%;
    .label_del {
      padding: 162px;
      border: 2px solid #575d96;
      border-radius: 44px;
      color: #092945;
      font-family: noto_sans_thai_bold, noto_sans_bold;
      font-size: ${({ theme }) => theme.FONT.SIZE.S36};
      text-align: center;
    }
    .btn_action {
      margin-top: 61px;
      display: flex;
      column-gap: 32px;
      justify-content: center;
      .btn_body {
        width: 209px;
      }
    }
  }
`;

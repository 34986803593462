import styled from 'styled-components'

export const TotalParticipantsStyled = styled.div`
  .top_current {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    .svg_show {

    }
    .label_show {
      margin-left: 12px;
      font-family: noto_sans_thai_medium, noto_sans_regular;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      
      .sub_title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
  .bottom_current {
    display: flex;
    padding-left: 15px;
    .left_bottom {
      width: 49%;
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
      .group_num {
        line-height: 1;
        min-height: 76px;
        font-family: noto_sans_thai_medium, noto_sans_regular;
        .num_show {
          text-align: right;
          font-size: ${({ theme }) => theme.FONT.SIZE.S54};
        }
        .sub_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S23};
          text-align: right;
        }
      }
    }
    .right_bottom {
      width: 61%;
      display: flex;
      .bar_show {
        margin-left: 26px;
      }
    }
  }
`

export default function renderIcon({ width = "28", height = "28", color = '#989898' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8933 6.33406C14.5022 6.33406 15.0975 6.16298 15.6038 5.84245C16.1101 5.52193 16.5048 5.06635 16.7378 4.53333C16.9708 4.00031 17.0318 3.4138 16.913 2.84795C16.7942 2.2821 16.501 1.76233 16.0704 1.35438C15.6398 0.946426 15.0912 0.668605 14.494 0.556051C13.8967 0.443497 13.2777 0.501264 12.7151 0.722047C12.1525 0.94283 11.6716 1.31671 11.3333 1.79642C10.995 2.27612 10.8145 2.8401 10.8145 3.41703C10.8143 3.80014 10.8939 4.17951 11.0485 4.53348C11.2032 4.88745 11.43 5.20907 11.7159 5.47997C12.0019 5.75086 12.3413 5.96573 12.7149 6.11228C13.0885 6.25882 13.489 6.33419 13.8933 6.33406Z" fill={color} />
      <path d="M22.6199 7.50057C23.2288 7.50057 23.8241 7.32948 24.3304 7.00896C24.8367 6.68843 25.2313 6.23285 25.4644 5.69983C25.6974 5.16682 25.7584 4.5803 25.6396 4.01445C25.5208 3.4486 25.2275 2.92884 24.797 2.52088C24.3664 2.11293 23.8178 1.83511 23.2205 1.72256C22.6233 1.61 22.0042 1.66777 21.4416 1.88855C20.8791 2.10933 20.3982 2.48322 20.0599 2.96292C19.7216 3.44262 19.541 4.0066 19.541 4.58354C19.5409 4.96664 19.6204 5.34602 19.7751 5.69999C19.9298 6.05395 20.1566 6.37558 20.4425 6.64647C20.7284 6.91737 21.0679 7.13223 21.4415 7.27878C21.8151 7.42533 22.2155 7.50069 22.6199 7.50057Z" fill={color} />
      <path d="M5.37866 7.50057C5.9876 7.50057 6.58286 7.32948 7.08918 7.00896C7.59549 6.68843 7.99012 6.23285 8.22315 5.69983C8.45618 5.16682 8.51715 4.5803 8.39836 4.01445C8.27956 3.4486 7.98632 2.92884 7.55574 2.52088C7.12515 2.11293 6.57656 1.83511 5.97932 1.72256C5.38208 1.61 4.76302 1.66777 4.20043 1.88855C3.63785 2.10933 3.157 2.48322 2.81869 2.96292C2.48038 3.44262 2.2998 4.0066 2.2998 4.58354C2.29967 4.96664 2.37922 5.34602 2.5339 5.69999C2.68857 6.05395 2.91535 6.37558 3.20128 6.64647C3.4872 6.91737 3.82667 7.13223 4.20027 7.27878C4.57388 7.42533 4.9743 7.50069 5.37866 7.50057Z" fill={color} />
      <path d="M27.9828 17.2028L27.1934 11.2234C27.0996 10.5183 26.7377 9.86975 26.1754 9.3993C25.6132 8.92885 24.8892 8.66875 24.1391 8.66772H21.098C20.7493 8.66903 20.4035 8.72703 20.0755 8.8392C19.9292 8.88772 19.8077 8.98692 19.7354 9.11686C19.6631 9.24681 19.6454 9.39785 19.6859 9.53963C19.7509 9.75697 19.7994 9.97842 19.8311 10.2023L20.6491 16.979C20.7034 17.419 20.6592 17.8648 20.5193 18.2875C20.3794 18.7102 20.147 19.1002 19.8373 19.432C19.6816 19.5927 19.5073 19.7363 19.3178 19.8602C19.2321 19.9181 19.1636 19.9959 19.1188 20.0862C19.074 20.1764 19.0545 20.2761 19.0622 20.3756L19.5458 26.7938C19.5562 26.9412 19.6254 27.0792 19.7394 27.18C19.8533 27.2808 20.0035 27.3367 20.1593 27.3363H25.0849C25.2406 27.3367 25.3906 27.2809 25.5046 27.1803C25.6185 27.0798 25.6878 26.942 25.6984 26.7948L26.2741 19.1655C26.5255 19.1507 26.7709 19.0871 26.9953 18.9787C27.2196 18.8703 27.4181 18.7194 27.5785 18.5355C27.7389 18.3515 27.8577 18.1383 27.9276 17.9091C27.9974 17.6798 28.0169 17.4394 27.9848 17.2028H27.9828Z" fill={color} />
      <path d="M8.64035 19.9735C8.38572 19.8239 8.15413 19.6417 7.95219 19.432C7.64245 19.1002 7.41005 18.7102 7.27015 18.2875C7.13025 17.8648 7.086 17.419 7.14029 16.979L7.95832 10.2023C7.99019 9.96205 8.04216 9.7246 8.11375 9.49216C8.13595 9.4176 8.14219 9.3396 8.13212 9.26272C8.12204 9.18584 8.09585 9.11164 8.05508 9.04449C8.0143 8.97734 7.95977 8.91858 7.89469 8.87168C7.82961 8.82478 7.75529 8.79069 7.6761 8.7714C7.42286 8.70537 7.16162 8.67085 6.89897 8.6687H3.85795C3.10777 8.66952 2.38366 8.92953 1.82137 9.40002C1.25908 9.87052 0.897225 10.5192 0.803639 11.2244L0.0142425 17.2028C-0.0169503 17.4394 0.00321943 17.6796 0.0735151 17.9086C0.143811 18.1376 0.26275 18.3506 0.423034 18.5345C0.583318 18.7183 0.781568 18.8693 1.00562 18.9779C1.22967 19.0866 1.4748 19.1508 1.72597 19.1665L2.30063 26.7957C2.31127 26.9429 2.38058 27.0808 2.4945 27.1813C2.60843 27.2819 2.75845 27.3376 2.91415 27.3373H7.84175C7.99746 27.3376 8.14748 27.2819 8.2614 27.1813C8.37533 27.0808 8.44463 26.9429 8.45527 26.7957L8.92973 20.5102C8.93788 20.4043 8.91521 20.2982 8.86419 20.2036C8.81317 20.1089 8.73575 20.0294 8.64035 19.9735Z" fill={color} />
      <path d="M19.4218 17.1109L18.6038 10.3293C18.5383 9.57457 18.1823 8.86896 17.6035 8.34667C17.0247 7.82438 16.2635 7.52184 15.4646 7.49658H12.3203C11.5214 7.52163 10.76 7.8241 10.1812 8.34643C9.60236 8.86877 9.24641 9.5745 9.18111 10.3293L8.36308 17.106C8.32849 17.3832 8.35618 17.6641 8.44429 17.9304C8.5324 18.1967 8.67896 18.4424 8.87435 18.6512C9.03326 18.8242 9.22699 18.9653 9.44417 19.0665C9.66136 19.1676 9.89765 19.2267 10.1392 19.2403L10.8192 27.9594C10.8301 28.1064 10.8995 28.244 11.0134 28.3444C11.1273 28.4447 11.2772 28.5003 11.4327 28.5H16.3583C16.5138 28.5003 16.6637 28.4447 16.7776 28.3444C16.8915 28.244 16.9609 28.1064 16.9718 27.9594L17.6518 19.2403C17.8933 19.2266 18.1296 19.1674 18.3468 19.0663C18.564 18.9652 18.7577 18.8241 18.9167 18.6512C19.1101 18.4423 19.255 18.1973 19.342 17.9319C19.429 17.6666 19.4562 17.3868 19.4218 17.1109Z" fill={color} />
    </svg>
  )
}

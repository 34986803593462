import styled from 'styled-components';

export const RewardExchangeHistoryContainerStyled = styled.div`
  padding: 15px 32px 0px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layer_one {
    display: flex;
  }
  .layer_two {
    margin-top: 18px;
    .t_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .b_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_semi_bold, noto_sans_regular;
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
    }
  }
  .title_layer {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
      .num_show {
        margin-left: 10px;
      }
    }
    .right_title {
      display: flex;
      .left_show_label {
        margin-right: 53px;
      }
      .date_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
      .icon_num {
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color:  ${({ theme }) => theme.COLORS.GOLD_1};
        display: flex;
        align-items: center;
      }
      .big_num {
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        display: flex;
        align-items: center;
        .num_hightlight {
          color:  ${({ theme }) => theme.COLORS.PURPLE_1};
          margin-right: 5px;
        }
      }
    }
  }
  .layer_table {
    margin-top: 14px;
  }
  
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_two {
      
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
      
    }
  }
`;

import styled from 'styled-components';

export const MsdPercentOfRiskPuiStyled = styled.div`
  .top_men {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 6px;
        display: flex;
      }
    }
    .right_men_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .show_search {
      }
    }
  }
  .title_graph {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size:  ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    margin-top: 10px;
    padding-left: 10px;
  }
  .bottom_men {
    display: flex;
    column-gap: 48px;
    row-gap: 16px;
    .b_left_layout {
      flex: 1;
      position: relative;
      .body_graph {
        padding: 0px 20px;
        tspan {
          &:first-child {
            font-weight: bold;
          } 
        }
      }
      .arrow_left {
        display: flex;
        position: absolute;
        bottom: 23px;
        left: -20px;
        z-index: 2;
      }
      .arrow_right {
        display: flex;
        position: absolute;
        bottom: 23px;
        right: -31px;
        z-index: 2;
      }
    }
    .right_men_layout {
      width: 217px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .date_show {
        margin-bottom: 23px;
        display: flex;
        .show_icon {
          margin-right: 9px;
        }
        .date_layout {
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size:  ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
      .show_being {
        margin-bottom: 27px;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
    .seleton_body {
      width: 100%;
    }
  }
  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  @media (max-width: 1060px) {
    .bottom_men {
      flex-direction: column;
      .body_graph {
        margin-bottom: 35px;
      }
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .bottom_men {
      .b_left_layout {
        .arrow_left {
          bottom: 400px;
        }
        .arrow_right {
          bottom: 400px;
          right: 7px;
        }
      }
    }
  }
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .bottom_men {
      .b_left_layout {
        .arrow_left {
          bottom: 374px;
        }
        .arrow_right {
          bottom: 374px;
          right: 7px;
        }
      }
    }
  }
`;

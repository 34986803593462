export default function Poor({ width = '48', height = '47.96' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 47.96">
      <g id="_034-dead" data-name="034-dead" transform="translate(0.075)">
        <ellipse id="Ellipse_281" data-name="Ellipse 281" cx="24" cy="23.972" rx="24" ry="23.972" transform="translate(-0.075)" fill="#ffe17d" />
        <path id="Path_3095" data-name="Path 3095" d="M164.03,333.181a.978.978,0,0,1-.656-.25.933.933,0,0,1-.057-1.338,12.381,12.381,0,0,1,16.908,0,.932.932,0,0,1-.057,1.338.982.982,0,0,1-1.368-.056,10.432,10.432,0,0,0-14.06,0A.974.974,0,0,1,164.03,333.181Z" transform="translate(-147.837 -297.434)" fill="#aa7346" />
        <path id="Path_3096" data-name="Path 3096" d="M27.494,96.583A24.047,24.047,0,0,1,8.866,57.376,24.026,24.026,0,1,0,42.637,91.194,23.885,23.885,0,0,1,27.494,96.583Z" transform="translate(-0.07 -52.113)" fill="#ffd164" />
        <g id="Group_1178" data-name="Group 1178" transform="translate(11.744 16.896)">
          <path id="Path_3097" data-name="Path 3097" d="M133.781,179.89a.983.983,0,0,0-1.368,0L130,182.251l-2.413-2.36a.983.983,0,0,0-1.368,0,.932.932,0,0,0,0,1.339l2.413,2.36-2.413,2.36a.932.932,0,0,0,0,1.339.983.983,0,0,0,1.368,0l2.413-2.36,2.413,2.36a.982.982,0,0,0,1.368,0,.932.932,0,0,0,0-1.339l-2.413-2.36,2.413-2.36A.932.932,0,0,0,133.781,179.89Z" transform="translate(-125.935 -179.613)" fill="#aa7346" />
          <path id="Path_3098" data-name="Path 3098" d="M304.787,183.59l2.413-2.36a.932.932,0,0,0,0-1.339.983.983,0,0,0-1.368,0l-2.413,2.36-2.413-2.36a.983.983,0,0,0-1.368,0,.932.932,0,0,0,0,1.339l2.413,2.36-2.413,2.36a.932.932,0,0,0,0,1.339.982.982,0,0,0,1.368,0l2.413-2.36,2.413,2.36a.982.982,0,0,0,1.368,0,.932.932,0,0,0,0-1.339Z" transform="translate(-283.096 -179.613)" fill="#aa7346" />
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const TotalLikeAndExchangeStyled = styled.div`
  .container_exchange {
    overflow: auto;
    .group_line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.mt {
        margin-top: 46px;
      }
      .show_icon {
        display: flex;
        width: 14%;
        // margin-right: 16px;
      }
      .group_label {
        display: flex;
        width: 83%;
        align-items: center;
        justify-content: space-between;
        .highlight {
          font-size: ${({ theme }) => theme.FONT.SIZE.S40};
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color:  ${({ theme }) => theme.COLORS.RED_8};
          &.gold {
            color:  ${({ theme }) => theme.COLORS.GOLD_1};
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const CurrentDWBStatContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block_2_col_layout {
    display: flex;
    padding: 10px 0px 10px 5px;
    column-gap: 24px;
    row-gap: 24px;
    margin: 0 -5px;
    overflow: auto;
    .col_2 {
      &.flex_1 {
        flex: 1;
      }
      &.w_368 {
        flex-shrink: 0;
        width: 368px;
      }
      &.mw_780 {
        min-width: 780px;
      }
      .b2c_block_wrap {
        &.h100 {
          height: 100%;
        }
        &.mb_24 {
          margin-bottom: 24px;
        }
      }
      .wrap_mr5_overflow {
        margin-right: 5px;
        height: 100%;
      }
    }
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
`;

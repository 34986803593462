export default function Dumbel3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_13723" data-name="Group 13723" transform="translate(0 0.06)">
        <g id="Group_4" data-name="Group 4" transform="translate(0 -0.06)">
          <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(0 0)" fill="#fedb41" stroke="#e3a52f" strokeWidth="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
          </g>
          <g id="gym" transform="translate(4.8 7.531)">
            <g id="Group_180" data-name="Group 180" transform="translate(14.202 1.255)">
              <g id="Group_179" data-name="Group 179" transform="translate(0 0)">
                <path id="Path_286" data-name="Path 286" d="M480.534,131.405v7.612a1.252,1.252,0,0,0,.93-1.208v-5.2A1.252,1.252,0,0,0,480.534,131.405Z" transform="translate(-480.534 -131.405)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_182" data-name="Group 182" transform="translate(10.479 0.247)">
              <g id="Group_181" data-name="Group 181" transform="translate(0 0)">
                <path id="Path_287" data-name="Path 287" d="M356.1,97.3H355.8a1.251,1.251,0,0,0-1.25,1.25v7.128a1.251,1.251,0,0,0,1.25,1.25h.293a1.251,1.251,0,0,0,1.25-1.25V98.551A1.251,1.251,0,0,0,356.1,97.3Z" transform="translate(-354.553 -97.301)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_184" data-name="Group 184" transform="translate(5.584 3.514)">
              <g id="Group_183" data-name="Group 183">
                <rect id="Rectangle_1366" data-name="Rectangle 1366" width="3.963" height="3.049" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_186" data-name="Group 186" transform="translate(0 1.008)">
              <g id="Group_185" data-name="Group 185" transform="translate(0 0)">
                <path id="Path_288" data-name="Path 288" d="M0,124.252v5.2a1.252,1.252,0,0,0,.93,1.208v-7.612A1.252,1.252,0,0,0,0,124.252Z" transform="translate(0 -123.044)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_188" data-name="Group 188" transform="translate(1.861 0)">
              <g id="Group_187" data-name="Group 187">
                <path id="Path_289" data-name="Path 289" d="M64.514,88.942h-.293a1.251,1.251,0,0,0-1.25,1.25V97.32a1.251,1.251,0,0,0,1.25,1.25h.293a1.251,1.251,0,0,0,1.25-1.25V90.192A1.251,1.251,0,0,0,64.514,88.942Z" transform="translate(-62.972 -88.942)" fill="#e3a52f" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import { AddLuckyRewardFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons } from "components";

const AddLuckyRewardForm = ({
  initialValues,
  onSubmit,
  isCannotEditDelete,
  aciton_type,
}) => {

  const [_disable, _setDisabled] = useState(false);

  const schema = yup.object().shape({
    name_th: yup.string().nullable().required("Name TH is required"),
    name_en: yup.string().nullable().required("Name EN is required"),
    // description: yup.string().nullable().required("Description is required"),
    // term: yup.string().nullable().required("Term is required"),
    // begin_date: yup.object().nullable().required('Reward Period is required'),
    // end_date: yup.object().nullable().required('Reward Period is required'),
    // default_coin_amount: yup
    //   .string()
    //   .nullable()
    //   .required('Health Point Requirement is required')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .max(4, 'Less than 9,999'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      if (isCannotEditDelete) {
        _setDisabled(true)
      } else if (aciton_type !== 'copy') {
        _setDisabled(initialValues.status === 'IN_USE' ? true : false)
      }

      reset({
        ...initialValues,
        files: initialValues.image_uri
          ? [{ image_uri: initialValues.image_uri }]
          : [],
      });
    }

  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps


  const renderStatus = (e) => {
    switch (e) {
      case "IN_USE":
        return 'กำลังใช้งาน';

      case "NO_USE":
        return 'ไม่ใช้งาน';

      default:
        return '-';
    }
  }

  return (
    <AddLuckyRewardFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_add_reward_lucky">
          <div className="left_add">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage3
                  theme_standard_upload_image
                  acceptType="cover"
                  maxMBFileSizeWarning="5"
                  widthImageSize={500}
                  heighImageSize={500}
                  {...field}
                  disabled={_disable}
                />
              )}
              name="files"
              defaultValue={[]}
            />
          </div>
          <div className="right_add">
            <div className="title_box">
              <div>
                ข้อมูลรางวัล
              </div>
              <div className="sub_text">
                Rewards Information
              </div>
            </div>
            {
              aciton_type === 'edit' &&
              <div className="status_zone">
                <div>
                  สถานะ
                </div>
                <div className="blue_text">
                  {`: ${renderStatus(initialValues.status)}`}
                </div>
              </div>
            }
            <div className="list_input">
              <div className="line_input">
                <div className="g_label">
                  <div >
                    ชื่อรางวัล (TH) *
                  </div>
                  <div className="g_sub_lbl">
                    Name
                  </div>
                </div>
                <div className="w_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        flexStyle='flex-end'
                        maxLength={120}
                        {...field}
                        errors={errors.name_th?.message}
                        disabled={_disable}
                      />
                    )}
                    name='name_th'
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_input">
                <div className="g_label">
                  <div >
                    ชื่อรางวัล (EN) *
                  </div>
                  <div className="g_sub_lbl">
                    Name
                  </div>
                </div>
                <div className="w_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        flexStyle='flex-end'
                        maxLength={120}
                        {...field}
                        errors={errors.name_en?.message}
                        disabled={_disable}
                      />
                    )}
                    name='name_en'
                    defaultValue=""
                  />
                </div>
              </div>
              {/* <div className="line_input flex-start">
                <div className="g_label">
                  <div >
                    ประเภท
                  </div>
                  <div className="g_sub_lbl">
                    Category
                  </div>
                </div>
                <div className="w_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Radio
                        theme_standard_radio
                        rowGap='10px'
                        {...field}
                        options={[
                          {
                            label: 'คะแนน',
                            value: '1',
                          },
                          {
                            label: 'ของรางวัล',
                            value: '2',
                          }
                        ]}
                      // placeholder={t('test')}
                      // errors={errors.how_to_play?.message}
                      />
                    )}
                    name='category'
                    defaultValue='1'
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="btn_zone_layout">
          {
            !_disable &&
            <div className="w134">
              <Buttons.BtnNormal
                theme_only_border_blue
                label='บันทึก'
                fontSize="14px"
                type="submit"
              // disabled={disabled}
              />
            </div>
          }
        </div>
      </form>
    </AddLuckyRewardFormStyled>
  );
};

AddLuckyRewardForm.propTypes = {};

export default AddLuckyRewardForm;

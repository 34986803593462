export default function MenuWell({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.353"
      height="19.293"
      viewBox="0 0 22.353 19.293"
    >
      <g
        id="cardiology_1_"
        data-name="cardiology (1)"
        transform="translate(0 -35.018)"
      >
        <path
          id="Path_19112"
          data-name="Path 19112"
          d="M7.483,44.951,9.01,42.058a.655.655,0,0,1,1.209.125l1.4,4.884,1.248-1.829a.655.655,0,0,1,.541-.286h8.106a6.694,6.694,0,0,0-10.338-8.214A6.693,6.693,0,0,0,.838,44.951Z"
          fill="#fff"
        />
        <path
          id="Path_19113"
          data-name="Path 19113"
          d="M52.806,245.422l-1.839,2.7a.655.655,0,0,1-1.171-.188l-1.336-4.66-.951,1.8a.655.655,0,0,1-.579.349h-6.1q.178.193.372.372l7.8,7.2a1.8,1.8,0,0,0,2.486-.04l7.758-7.162q.194-.179.372-.372Z"
          transform="translate(-39.051 -199.161)"
          fill="#fff"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.35"
      height="19.293"
      viewBox="0 0 22.35 19.293"
    >
      <path
        id="cardiology"
        d="M15.655,35.017a6.71,6.71,0,0,0-4.48,1.721,6.695,6.695,0,0,0-9.021,9.894l7.8,7.2a1.8,1.8,0,0,0,2.485-.04L20.2,46.632a6.695,6.695,0,0,0-4.54-11.615ZM11.523,52.856a.492.492,0,0,1-.681.014L3.682,46.26h4.2a.655.655,0,0,0,.579-.349l.951-1.8,1.336,4.659a.655.655,0,0,0,1.17.188l1.839-2.7h4.915l-7.126,6.578ZM6.695,36.327A5.4,5.4,0,0,1,10.689,38.1a.655.655,0,0,0,.971,0,5.385,5.385,0,0,1,8.3,6.85h-6.55a.655.655,0,0,0-.541.286l-1.248,1.829-1.4-4.884a.655.655,0,0,0-1.209-.125L7.482,44.95H2.393a5.384,5.384,0,0,1,4.3-8.624Z"
        transform="translate(0 -35.018)"
      />
    </svg>
  );
}

export default function ArrowLeft({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.579" height="19.603" viewBox="0 0 23.579 19.603">
      <g id="_008-up-arrow" data-name="008-up-arrow" transform="translate(0 19.603) rotate(-90)">
        <g id="Group_2200" data-name="Group 2200" transform="translate(0 0)">
          <path id="Path_5453" data-name="Path 5453" d="M60.706,8.885,52.2.376a1.3,1.3,0,0,0-1.83,0L41.858,8.885a1.291,1.291,0,0,0,0,1.824l.773.773a1.281,1.281,0,0,0,.912.376,1.306,1.306,0,0,0,.923-.376L49.44,6.517V22.288a1.274,1.274,0,0,0,1.268,1.291H51.8a1.322,1.322,0,0,0,1.324-1.291V6.461l5,5.02a1.278,1.278,0,0,0,1.81,0l.77-.773A1.291,1.291,0,0,0,60.706,8.885Z" transform="translate(-41.481 0)" />
        </g>
      </g>
    </svg>

  );
}

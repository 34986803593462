import styled from "styled-components";

export const MainlayoutStyled = styled.div`
  background: ${({ theme }) => theme.COLORS.GRAY_11};
  height: 100%;
  overflow: auto;
  position: relative;
  min-width: 768px;
  .member_action_layout {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_layout {
      display: flex;
      align-items: center;
      .icon_show {
        max-width: 47px;
      }
      .icon_show_2 {
        max-width: 172px;
      }
      .name_company {
        margin-left: 14px;
        &.green {
          color: ${({ theme }) => theme.COLORS.GREEN_3};
        }
      }
    }
  }
  .navbar_layout {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  .body_main_layout {
    max-width: 1400px;
    margin: auto;
    padding: 26px 32px;
  }
`;

import styled from "styled-components";

export const AverageDepartmentWellbeingStyled = styled.div`
  .top_avg_dep {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    row-gap: 40px;
    .group_well {
      display: flex;
      .show_wellbeing {
        margin-right: 16px;
      }
    }
    .group_date {
      width: 200px;
      .box_date {
        // width: 48%;
        width: 100%;
      }
    }
  }
  .bottom_avg_dep {
    .chart_wrap {
      position: relative;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0px;
      }
      .c_row_2_col {
        display: flex;
        flex-wrap: wrap;
        column-gap: 35px;
        .bar_item {
          width: calc(50% - 18px);
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
          .label {
            width: 27%;
            flex-shrink: 0;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .percent_bar {
            flex: 1;
            background: ${({ theme }) => theme.COLORS.GRAY_2};
            display: flex;
            min-height: 32px;
            .stack_item {
              text-align: center;
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              padding: 4px 0;
              color: white;
            }
          }
        }
      }
    }
  }
  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }

  @media (max-width: 1333px) {
    .top_avg_dep {
      .title_wrap {
        width: calc(100% - 200px);
      }
      .group_well {
        order: 3;
      }
      .group_date {
        order: 2;
      }
    }
  }
`;

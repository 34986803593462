import styled from 'styled-components';

export const BtnCircleLabelStyled = styled.div`
  .theme_standard_btn_circle_label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.GRAY_2};
    padding: 5px;
    cursor: pointer;
    .left_button {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .svg_show {
      }
    }
    .right_button {
      width: 50%;
      .title {
        font-size: ${({ fontSize, theme }) => fontSize ? fontSize : theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-family: ${({ fontFamily }) => fontFamily ? fontFamily : 'noto_sans_thai_regular, noto_sans_regular'};
        font-size: ${({ fontColor, theme }) => fontColor ? fontColor : theme.COLORS.BLACK_2};
      }
      .label_show {font-size: ${({ fontSizeLabelBottom, theme }) => fontSizeLabelBottom ? fontSizeLabelBottom : theme.FONT.SIZE.S14};
        font-family: ${({ fontFamilyBottom }) => fontFamilyBottom ? fontFamilyBottom : 'noto_sans_thai_regular, noto_sans_regular'};
        color: ${({ fontColorBottom }) => fontColorBottom ? fontColorBottom : 'rgb(112, 112, 112, 0.65)'};
      }
    }
  }
`;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { IconTitleSubTxtStyled } from './styled';

const IconTitleSubTxt = ({
  theme_standard,
  theme_flex_col,
  theme_big_text,
  theme_standard_new,
  svg,
  title,
  subTitle,
  prefixLabelScore,
  score,
  suffixLabelScore,
  bottomColor,
  height,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_flex_col: theme_flex_col,
    theme_standard_new:theme_standard_new,
    theme_big_text,
  });
  return (
    <IconTitleSubTxtStyled bottomColor={bottomColor} height={height}>
      <div className={customClass}>
        <div className="ict_container">
          <div className="top_current">
            <div className="svg_show">{svg}</div>
            <div className="label_show">
              <div className="title_txt">{title}</div>
              <div className="sub_title_show">{subTitle}</div>
            </div>
          </div>
          <div className="score_box">
            {prefixLabelScore && (
              <div className="s_prefix_label">
                {prefixLabelScore}
              </div>
            )}
            <div className="s_scroe">{score}</div>
            <div className="s_label">{suffixLabelScore}</div>
          </div>
        </div>
      </div>
    </IconTitleSubTxtStyled>
  );
};

IconTitleSubTxt.propTypes = {};

export default IconTitleSubTxt;

import styled from 'styled-components';

export const SpinGameCreateEditContainerStyled = styled.div`
    .show_loading {
        height: calc(100vh - 85px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    padding: 15px 32px 80px 32px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};

    .box_md {
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        padding: 40px 60px;
        border-radius: 16px;
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMI_BOLD};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .line_label {
            display: flex;
            column-gap: 5px;
            .red_text {
                font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
                color: ${({ theme }) => theme.COLORS.RED_8};
            }
        }
        .btn_100 {
            margin-top: 32px;
            width: 100%;
        }
    }
`;

export default function renderIcon({
  color = "#009078",
  width = "32",
  height = "32",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path
        id="Icon_material-insert-emoticon"
        data-name="Icon material-insert-emoticon"
        d="M18.984,3A16,16,0,1,0,35,19,15.992,15.992,0,0,0,18.984,3ZM19,31.8A12.8,12.8,0,1,1,31.8,19,12.8,12.8,0,0,1,19,31.8Zm5.6-14.4A2.4,2.4,0,1,0,22.2,15,2.4,2.4,0,0,0,24.6,17.4Zm-11.2,0A2.4,2.4,0,1,0,11,15,2.4,2.4,0,0,0,13.4,17.4ZM19,27.8a8.794,8.794,0,0,0,8.176-5.6H10.824A8.794,8.794,0,0,0,19,27.8Z"
        transform="translate(-3 -3)"
        fill={color}
      />
    </svg>
  );
}

import { TotalParticipantsStyled } from './styled';
import { Blocks, Icons, Charts } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalParticipants = ({ users_count, users_count_total }) => {
  return (
    <TotalParticipantsStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="25px"
        paddingBottom="0px"
        paddingLeft="16px"
      >
        <div className="top_current">
          <div className="svg_show">
            <Icons.Building  color='#79A6FF'/>
          </div>
          <div className="label_show">
            <div>จำนวนผู้เข้าร่วมทั้งหมด</div>
            <div className="sub_title_show">Total Participants</div>
          </div>
        </div>
        <div className="bottom_current">
          <div className="left_bottom">
            <div className="group_num">
              <div className="num_show">{users_count && RenderCommaMoney(users_count.toFixed(0))}</div>
              <div className="sub_label">คน</div>
            </div>
          </div>
          <div className="right_bottom">
            <div className="bar_show">  
              <Charts.BarItem2
                theme_mini_bar_blue
                showLetLine
                percent={users_count && users_count_total ?((users_count / users_count_total) * 100).toFixed(0) : 0}
                minHeight="200px"
                showPercent={users_count && users_count_total ? ((users_count / users_count_total) * 100).toFixed(0) : 0}
              />
            </div>
          </div>
        </div>
      </Blocks.Box>
    </TotalParticipantsStyled>
  );
};

export default TotalParticipants;

import React from "react";
import { UserAccountManagementContainerStyled } from "./styled";
import {
  Loading,
  Inputs,
  Buttons,
  Icons,
  TableCustom,
  Modal,
  BoxDelete2,
  Forms,
  Displays,
  BGLoading,
} from "components";
import theme from "styles/theme.json";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { getObjectKey2, getObjectKey } from "utils/functions/getObjectKey";

class UserAccountManagementContainer extends React.Component {
  state = {
    isLoading: true,
    offset: 0,
    perPage: 10,
    labelEmpty: "ยังไม่มีรายการ",
    isShowModal: null,
  };

  componentDidMount() {
    const { perPage } = this.state;

    this.scrollToTop();
    this.fetchData(`?per_page=${perPage}&offset=0`);
    this.fetchDepartment(`?per_page=300&offset=0`);
    this.checkPermission();
  }

  componentDidUpdate(prevProps) {
    const { perPage } = this.state;

    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=${perPage}&offset=0`);
      this.fetchDepartment(`?per_page=300&offset=0`);
      this.checkPermission();
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "employee_manage",
      "employee_manage_no_delete"
    );

    let checkPermissionDelete = getObjectKey(
      authenRedux.admin_role.permissions,
      "employee_manage_no_delete"
    );

    this.setState({
      perMissions: checkPermission,
      perMissionsDelete: checkPermissionDelete,
    });
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_EMPLOYEE_LIST(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
        isLoading: false,
        isBgLoading: false,
      });
    } else {
      this.setState({
        data: [],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchDepartment = async () => {
    let res = await adminService.GET_DEPARTMENT_LIST(
      this.props.companySelectedRedux.id
    );
    if (res && res.success) {
      let arrayTemp = [{ label: "All", name: "all", value: "all" }];
      this.setState({
        deparmentList: [
          ...arrayTemp,
          ...res.data.map((e) => ({
            label: e.name,
            name: e.id,
            value: e.id,
          })),
        ],
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickCreate = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`
    );
  };

  handleClickCreateManyAccount = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_MANY_USER_ACCOUNT}`);
  };

  handleClickDepartment = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_DEPARTMENT}`);
  };

  handleClickSort = (e) => {};

  handleClickDetail = (e) => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/${e.id}`
    );
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };

  handleConfirmDelete = () => {
    const { deleteList } = this.state;

    this.setState({
      isShowModal: false,
      isBgLoading: true,
    });

    if (deleteList && deleteList.length > 0) {
      Promise.all(
        deleteList.map((e) => {
          return new Promise(async (resolve) => {
            let res = await adminService.DELETE_EMPLOYEE(e);
            if (res && res.success) {
              resolve();
            }
          });
        })
      ).then(() => {
        toast.success("ลบสำเร็จ");

        this.setState(
          {
            offset: 0,
            resetSelect: true,
            // isLoading: true,
          },
          () => {
            this.handleJoinQuery();
          }
        );
      });
    }
  };

  // FILTER PROCESS
  //==========================================
  //==========================================
  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `department_ids=${e
              .filter((e) => e !== "all")
              .join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "keywordFilter":
        this.setState(
          {
            keywordFilter: !e ? false : `keyword=${e}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleChangeKeyword = (e) => {
    this.setState({
      // keyword: (e && e !== '') ? `keyword=${e}` : null,
      resetSelect: false,
      temp_keyword: e && e !== "" ? `keyword=${e}` : null,
    });
  };

  // handleSelectDepartment = (e) => {
  //   this.setState({
  //     // typeFilter: !e || e.value === 'all'
  //     //   ? false
  //     //   : `types=${e.value}`,
  //     temp_department:
  //       !e || e.value === "all" ? false : `department_ids=${e.value}`,
  //   });
  // };

  handleSelectStatus = (e) => {
    this.setState({
      // typeFilter: !e || e.value === 'all'
      //   ? false
      //   : `types=${e.value}`,
      temp_status: !e || e.value === "all" ? false : `status=${e.value}`,
    });
  };

  handleJoinQuery = () => {
    const {
      keyword,
      departmentFilter,
      locationFilter,
      statusFilter,
      offset,
      perPage,
    } = this.state;
    let queryString = [];

    this.setState({
      // resetSelect: false,
      isBgLoading: true,
      labelEmpty: "ไม่พบข้อมูลจากการค้นหา",
    });

    if (keyword && keyword !== "") {
      queryString.push(keyword);
    }
    if (departmentFilter) {
      queryString.push(departmentFilter);
    }
    if (statusFilter) {
      queryString.push(statusFilter);
    }
    if (locationFilter) {
      queryString.push(locationFilter);
    }

    if (queryString && queryString.length > 0) {
      this.fetchData(
        `?per_page=${perPage}&offset=${offset}&${queryString.join("&")}`
      );
      this.setState({
        queryFilterAll: queryString.join("&"),
      });
    } else {
      this.fetchData(`?per_page=${perPage}&offset=${offset}`);
      this.setState({
        queryFilterAll: null,
      });
    }
  };

  handlePageClick = (e) => {
    const { pagination } = this.state;

    this.setState(
      {
        resetSelect: false,
        offset: e.selected * pagination.per_page,
      },
      () => {
        this.handleJoinQuery();
      }
    );
  };

  handleClickLog = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_USER_MANAGEMENT_LOG}`);
    // console.log("click");
  };

  handleBack = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE}`);
  };

  handleSearch = () => {
    const { temp_keyword, temp_department, temp_status } = this.state;

    this.setState(
      {
        resetSelect: false,
        keyword: temp_keyword,
        departmentFilter: temp_department,
        statusFilter: temp_status,
        offset: 0,
      },
      () => this.handleJoinQuery()
    );
  };

  handleClickCloseModal = () => {
    this.setState({
      deleteList: null,
      isShowModal: false,
    });
  };

  handlePopup = (e) => {
    const { deleteList, selectedData, data } = this.state;

    if (e === "confirm") {
      this.setState({
        isShowModal: (
          <BoxDelete2
            theme_box_delete
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
            textShow={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Icons.Delete color="#EC5E49" />
                </div>
                <div style={{ color: "#EC5E49" }}>ยืนยันการลบหรือไม่ ?</div>
              </div>
            }
            subTextShow={
              deleteList && `จำนวนผู้ใช้งาน : ${deleteList.length} บัญชี`
            }
          />
        ),
      });
    } else {
      this.setState({
        isShowModal: (
          <Forms.SelectDeleteForm
            selectCount={selectedData.length}
            totalCount={data.length}
            onSubmit={this.handelBeforeDelete}
            handleCancel={this.handleClickCloseModal}
          />
        ),
      });
    }
  };

  handelBeforeDelete = (e) => {
    const { selectedData, data } = this.state;

    let tempUpload = [];

    if (e.delete_option === "1") {
      tempUpload = selectedData;
    } else {
      data.map((e) => tempUpload.push(e.id));
    }

    this.setState(
      {
        delete_option: e.delete_option,
        deleteList: tempUpload,
      },
      () => {
        this.handlePopup("confirm");
      }
    );
  };

  // handleDownload = async (e) => {
  //   const { keyword, departmentFilter, locationFilter, statusFilter } =
  //     this.state;
  //   let queryString = [];

  //   if (keyword && keyword !== "") {
  //     queryString.push(keyword);
  //   }
  //   if (departmentFilter) {
  //     queryString.push(departmentFilter);
  //   }
  //   if (statusFilter) {
  //     queryString.push(statusFilter);
  //   }
  //   if (locationFilter) {
  //     queryString.push(locationFilter);
  //   }

  //   let textTemp = queryString.join("&");

  //   this.setState({
  //     isBgLoading: true,
  //   });
  //   let res = await adminService.GET_REPORT_MANAGEMENT_TYPE_BY_COMPANY_ID(
  //     this.props.companySelectedRedux.id,
  //     "user_management.xlsx",
  //     textTemp && textTemp !== "" ? `?${textTemp}` : ""
  //   );

  //   if (res && res.success) {
  //     toast.success("ดาวน์โหลดสำเร็จ");
  //     const link = document.createElement("a");
  //     link.href = res.data.url;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     this.setState({
  //       isBgLoading: false,
  //     });
  //   } else {
  //     toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
  //     this.setState({
  //       isBgLoading: false,
  //     });
  //   }
  // };

  render() {
    const {
      isLoading,
      isShowModal,
      pagination,
      data,
      // deparmentList,
      selectedData,
      perMissions,
      isBgLoading,
      labelEmpty,
      resetSelect,
      perMissionsDelete,
    } = this.state;

    const { companySelectedRedux } = this.props;

    return (
      <UserAccountManagementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="box_content_layout">
            <div className="manage_container">
              <div className="head_wrap_layout">
                <div className="h_33">
                  <Buttons.BtnLink
                    theme_standard_btn_link
                    svg={<Icons.ArrowLeft />}
                    label="ย้อนกลับ"
                    onClick={this.handleBack}
                  />
                </div>
                <div className="h_33 center">
                  <div className="text_c">จัดการบัญชีผู้ใช้งาน</div>
                  <div className="g_h_text">User Management</div>
                </div>
                <div className="h_33 flex">
                  <div className="bw132">
                    <Buttons.BtnNormal
                      theme_blue_border_bg_white
                      padding="7.2px 8px"
                      label="Log"
                      svg_front={<Icons.Log color="#002366" />}
                      onClick={this.handleClickLog}
                    />
                  </div>
                  <div className="bw132">
                    <Buttons.BtnNormal
                      theme_blue_border_bg_white
                      padding="7.2px 8px"
                      label="แผนกในบริษัท"
                      onClick={this.handleClickDepartment}
                    />
                  </div>

                  {/* {perMissions && (
                    <div className="bw200">
                      <Buttons.BtnPopup
                        label="สร้างบัญชีผู้ใช้งาน"
                        options={optMenu1}
                      />
                    </div>
                  )} */}
                </div>
              </div>
              <div className="filter_content_zone">
                <div className="group_f_left">
                  <div className="w280">
                    <div className="lbl_t">ค้นหารายชื่อที่คุณต้องการ</div>
                    <Inputs.InputSearch2
                      theme_standard_search_48
                      iconColor="#989898"
                      placeholder="ค้นหาชื่อ,รหัสพนักงาน"
                      onChangeCustom={this.handleChangeKeyword}
                      onSubmit={this.handleSearch}
                    />
                  </div>
                  {/* <div className="w200">
                    <Inputs.InputDropDownNormal
                      theme_40h
                      iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                      iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                      placeholder="Department"
                      options={deparmentList && deparmentList}
                      onChange={this.handleSelectDepartment}
                    />
                  </div> */}
                  <div className="w200">
                    <Inputs.InputDropDownNormal
                      theme_content
                      iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                      iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                      placeholder="สถานะ"
                      options={optStatus && optStatus}
                      onChange={this.handleSelectStatus}
                    />
                  </div>
                  <div className="w83">
                    <Buttons.BtnNormal
                      theme_standard_btn_log_in
                      label="ค้นหา"
                      padding="12px 8px"
                      backgroundColor={theme.COLORS.BLUE_21}
                      onClick={this.handleSearch}
                    />
                  </div>
                </div>
                <div className="group_f_right">
                  {/* <div className="b132">
                    <Buttons.BtnNormal
                      theme_standard_btn_log_in
                      label="Location"
                      backgroundColor={theme.COLORS.PURPLE_1}
                      padding="8px 12px"
                      onClick={this.handleClickLocation}
                    />
                  </div>
                  <div className="b132">
                    <Buttons.BtnNormal
                      theme_standard_btn_log_in
                      label="Department"
                      backgroundColor={theme.COLORS.PURPLE_1}
                      padding="8px 12px"
                      onClick={this.handleClickDepartment}
                    />
                  </div> */}

                  {perMissions && (
                    <div className="bw98">
                      <Buttons.BtnNormal
                        theme_only_border_red
                        label="ลบ"
                        borderRadius="8px"
                        padding="11.2px 8px"
                        svg_front={
                          <Icons.Delete5
                            color={
                              selectedData &&
                              selectedData.length > 0 &&
                              !perMissionsDelete
                                ? "#ED5E49"
                                : "#FFFFFF"
                            }
                          />
                        }
                        disabled={
                          perMissionsDelete ||
                          !(selectedData && selectedData.length > 0)
                        }
                        onClick={() => this.handlePopup("alert")}
                      />
                    </div>
                  )}
                  {perMissions && (
                    <div className="righw200">
                      <Buttons.BtnNormal
                        theme_blue_border_bg_white
                        padding="11.2px 8px"
                        label="สร้างรายชื่อจำนวนมาก"
                        onClick={this.handleClickCreateManyAccount}
                      />
                    </div>
                  )}

                  {perMissions && (
                    <div className="righw200">
                      <Buttons.BtnNormal
                        theme_standard_btn_log_in
                        label="สร้างรายชื่อ"
                        padding="12px 8px"
                        svg_front={<Icons.AddRound color="#002366" />}
                        backgroundColor={theme.COLORS.BLUE_21}
                        onClick={this.handleClickCreate}
                      />
                    </div>
                  )}
                  {/* <div className="bw41">
                    <Buttons.BtnPopup
                      theme_popup_right
                      label={<Icons.DotThree />}
                      options={optMenu2({
                        handleDownload: this.handleDownload,
                        handleClickLocation: this.handleClickLocation,
                        handleClickDepartment: this.handleClickDepartment,
                      })}
                    />
                  </div> */}
                </div>
              </div>
              <div className="table_show">
                <TableCustom.TableAction
                  theme_standard_table_user
                  columns={columns({
                    handleClickDetail: this.handleClickDetail,
                    perMissions: perMissions,
                    companySelectedRedux: companySelectedRedux,
                  })}
                  data={data}
                  pagination={pagination}
                  isCheckbox={perMissions ? true : false}
                  labelEmpty={labelEmpty}
                  isUseResetSelect={false}
                  resetSelect={resetSelect}
                  useNo
                  showPagination
                  onChangeSelected={this.handleChangeSelectedTable}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
          </div>
          // <>
          //   <Blocks.Container>
          //     <div className="layer_one">
          //       <Tabs.HeadTab
          //         theme_standard_head_tab
          //         title="จัดการบัญชีผู้ใช้"
          //         subTitle="User Management"
          //         fontSize="34px"
          //         fontSizeLabelButtom="18px"
          //         fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
          //         fontColor="#092945"
          //         colorBottom="#9E9E9E"
          //         lineHeight="1.3"
          //         typeLogTow
          //         handleClickButton={this.handleClickLog}
          //         typeDepartment
          //         handleClickButtonDepartment={this.handleClickDepartment}
          //       />
          //     </div>
          //     <div className="layer_two align_end">
          //       <div className={`search_layer ${!perMissions ? "w50" : ""}`}>
          //         <div className="sub_label">ค้นหารายชื่อที่คุณต้องการ</div>
          //         <Inputs.InputSearch
          //           theme_standard_search
          //           onSubmit={(e) =>
          //             this.handleSelectedFilter("keywordFilter", e)
          //           }
          //         />
          //       </div>
          //       {perMissions && (
          //         <div className="btn_layer w100">
          //           <div className="body_btn">
          //             <div className="btn_layer w100">
          //               <div>
          //                 <Buttons.BtnNormal
          //                   theme_only_border
          //                   label="ลบ"
          //                   borderRadius="4px"
          //                   backgroundColor="#fff"
          //                   fontColor="#FF0000"
          //                   disabled={
          //                     !(selectedData && selectedData.length > 0)
          //                   }
          //                   svg={
          //                     <Icons.Delete3
          //                       color={
          //                         selectedData && selectedData.length > 0
          //                           ? "#f0211d"
          //                           : "#fff"
          //                       }
          //                       color2={
          //                         selectedData && selectedData.length > 0
          //                           ? "#fff"
          //                           : "#9E9E9E"
          //                       }
          //                     />
          //                   }
          //                   onClick={this.handlePopup}
          //                 />
          //               </div>
          //               <div>
          //                 <Buttons.BtnNormal
          //                   theme_only_border
          //                   label="สร้างรายชื่อจำนวนมาก"
          //                   onClick={this.handleClickCreateManyAccount}
          //                 />
          //               </div>
          //               <div className="min_132">
          //                 <Buttons.BtnNormal
          //                   theme_standard_btn_normal_red
          //                   label="สร้างรายชื่อ"
          //                   backgroundColor="#21A526"
          //                   fontSize="16px"
          //                   borderRadius="4px"
          //                   svg_front={<Icons.Plus color="#FFFFFF" />}
          //                   onClick={this.handleClickCreate}
          //                 />
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       )}
          //     </div>
          //     <div className="layer_table">
          //       <TableCustom.TableAction
          //         theme_standard_table_user
          //         columns={columns({
          //           deparmentList: deparmentList,
          //           handleClickSort: this.handleClickSort,
          //           handleClickDetail: this.handleClickDetail,
          //           handleSelectedFilter: this.handleSelectedFilter,
          //           companyName: companySelectedRedux.name,
          //           companyID: this.props.companySelectedRedux.id,
          //           perMissions: perMissions,
          //         })}
          //         data={data}
          //         pagination={pagination}
          //         isCheckbox={perMissions ? true : false}
          //         useNo
          //         showPagination
          //         onChangeSelected={this.handleChangeSelectedTable}
          //         handlePageClick={this.handlePageClick}
          //       />
          //     </div>
          //     <Modal
          //       theme_modal_standard
          //       isShow={isShowModal}
          //       handleClickCloseModal={this.handleClickCloseModal}
          //     >
          //       <BoxDelete
          //         theme_box_delete
          //         handleConfirm={this.handleConfirmDelete}
          //         handleCancel={this.handleClickCloseModal}
          //       />
          //     </Modal>
          //   </Blocks.Container>
          // </>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {isShowModal}
        </Modal>
      </UserAccountManagementContainerStyled>
    );
  }
}

const optStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Locked",
    value: "lock",
  },
];

// const optMenu1 = [
//   {
//     label: "สร้างบัญชีผู้ใช้งานรายคน",
//     route: `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`,
//   },
//   {
//     label: "สร้างบัญชีผู้ใช้งานจำนวนมาก",
//     route: ROUTE_PATH.BACK_OFFICE_MANY_USER_ACCOUNT,
//   },
// ];

// const optMenu2 = ({ handleClickDepartment, handleDownload }) => [
//   {
//     label: "Department",
//     isRoute: false,
//     handleEvent: handleClickDepartment,
//     // route: `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`,
//   },
//   // {
//   //   label: "ดาวน์โหลด",
//   //   isRoute: false,
//   //   handleEvent: handleDownload,
//   //   // route: `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`,
//   // },
//   {
//     label: "Activity Log",
//     isRoute: true,
//     route: ROUTE_PATH.BACK_OFFICE_USER_MANAGEMENT_LOG,
//   },
// ];

const columns = ({ handleClickDetail, perMissions, companySelectedRedux }) => {
  let tempColumn = [];

  if (perMissions) {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: { textAlign: "center", width: "41px", flexShrink: 0 },
      style: { textAlign: "center", width: "41px", flexShrink: 0 },
    });
  } else {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
      style: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
    });
  }

  tempColumn.push(
    {
      title: "ลำดับ",
      field: "no",
      headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
      style: { textAlign: "center", width: "30px", flexShrink: 0 },
    },
    {
      title: "รหัสพนักงาน",
      field: "code",
      headerStyle: {
        textAlign: "left",
        minWidth: "10px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          data-tip
          data-for={`tooltipCode${origin.id}`}
          onClick={() => handleClickDetail(origin)}
        >
          {value}
          <ReactTooltip id={`tooltipCode${origin.id}`} delayShow="800">
            <span> {`${origin.firstname} ${origin.lastname}`}</span>
          </ReactTooltip>
        </div>
      ),
    },
    {
      title: "ชื่อ - นามสกุล",
      field: "name",
      classStyle: "showHead",
      headerStyle: {
        textAlign: "left",
        minWidth: "130px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      style: { textAlign: "left", minWidth: "130px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "130px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          data-tip
          data-for={`tooltipName${origin.id}`}
          onClick={() => handleClickDetail(origin)}
        >
          <ReactTooltip id={`tooltipName${origin.id}`} delayShow="800">
            <span> {`${origin.firstname} ${origin.lastname}`}</span>
          </ReactTooltip>
          {`${origin.firstname} ${origin.lastname}`}
        </div>
      ),
    },
    {
      title: "แผนก",
      field: "department.name",
      classStyle: "showHead",
      headerStyle: {
        textAlign: "left",
        minWidth: "10px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
        >
          {value}
        </div>
      ),
    },
    {
      title: "บริษัท",
      field: "department.company.realm",
      classStyle: "showHead",
      headerStyle: {
        textAlign: "left",
        minWidth: "10px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          onClick={() => handleClickDetail(origin)}
          className="showName"
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          data-tip
          data-for={`tooltipCompany${origin.id}`}
        >
          <ReactTooltip id={`tooltipCompany${origin.id}`} delayShow="800">
            <span>{companySelectedRedux && companySelectedRedux.name}</span>
          </ReactTooltip>
          {companySelectedRedux && companySelectedRedux.name}
        </div>
      ),
    },
    // {
    //   title: "สถานะ",
    //   field: "employee.user",
    //   classStyle: "showStatus",
    //   headerStyle: { textAlign: "left", minWidth: "50px" },
    //   style: { textAlign: "left", minWidth: "50px" },
    //   render: ({ origin, value }) => (
    //     <div
    //       className="show_status"
    //       onClick={() => handleClickDetail(origin)}
    //       style={{
    //         color: origin.user != null ? "#575D96" : "#9E9E9E",
    //         minWidth: "50px",
    //         overflow: "hidden",
    //         whiteSpace: "nowrap",
    //         textOverflow: "ellipsis",
    //       }}
    //     >
    //       {origin.user != null && origin.user?.profile_completed_at != null
    //         ? "อยู่ในระบบ"
    //         : "ยังไม่เข้าสู่ระบบ"}
    //     </div>
    //   ),
    // },
    {
      title: "สถานะ",
      field: "user",
      headerStyle: { textAlign: "left", width: "110px", flexShrink: 0 },
      style: { textAlign: "left", width: "110px", flexShrink: 0 },
      render: ({ origin, value }) => (
        <div style={{ width: "110px" }}>
          <Displays.StatusLabel
            theme_user
            label={
              origin.user && !origin.user.is_lock
                ? "active"
                : origin.user && origin.user.is_lock
                ? "locked"
                : "pending"
            }
          />
        </div>
      ),
    }
  );

  return tempColumn;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountManagementContainer);

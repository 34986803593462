export default function SearchPage() {
  return (
    <svg id="Group_24723" data-name="Group 24723" xmlns="http://www.w3.org/2000/svg" width="45.293" height="52.857" viewBox="0 0 45.293 52.857">
      <g id="Group_24639" data-name="Group 24639" transform="translate(0 0)">
        <path id="Path_43878" data-name="Path 43878" d="M41.97,13.436V52.124a.94.94,0,0,1-.944.944H8.944A.946.946,0,0,1,8,52.124V4.944A.946.946,0,0,1,8.944,4h23.59Z" transform="translate(-8 -4)" fill="#fab400" />
      </g>
      <g id="Group_24640" data-name="Group 24640" transform="translate(3.783 5.662)">
        <path id="Path_43879" data-name="Path 43879" d="M24.276,10H12.953a.944.944,0,0,0,0,1.887H24.276a.944.944,0,0,0,0-1.887Z" transform="translate(-12.009 -10)" fill="#0a5078" />
      </g>
      <g id="Group_24641" data-name="Group 24641" transform="translate(3.783 43.406)">
        <path id="Path_43880" data-name="Path 43880" d="M29.938,50H12.953a.944.944,0,0,0,0,1.887H29.938a.944.944,0,0,0,0-1.887Z" transform="translate(-12.009 -50)" fill="#0a5078" />
      </g>
      <g id="Group_24642" data-name="Group 24642" transform="translate(3.783 39.632)">
        <path id="Path_43881" data-name="Path 43881" d="M17.671,46H12.953a.944.944,0,0,0,0,1.887h4.718a.944.944,0,0,0,0-1.887Z" transform="translate(-12.009 -46)" fill="#0a5078" />
      </g>
      <g id="Group_24649" data-name="Group 24649" transform="translate(3.774 11.309)">
        <g id="Group_24643" data-name="Group 24643" transform="translate(0 1.878)">
          <path id="Path_43882" data-name="Path 43882" d="M42.2,44.4v7.568l-6.879-6.907a.945.945,0,0,1-.094-1.227l-2.085-2.076a13.285,13.285,0,1,1,2.661-2.68v.009l2.095,2.076a.982.982,0,0,1,1.2.123Z" transform="translate(-12 -17.975)" fill="#dc9600" />
        </g>
        <g id="Group_24644" data-name="Group 24644" transform="translate(20.778 20.744)">
          <path id="Path_43883" data-name="Path 43883" d="M0,0H3.773V4.234H0Z" transform="translate(0 2.668) rotate(-45)" fill="#f0f0f0" />
        </g>
        <g id="Group_24645" data-name="Group 24645" transform="translate(0.016)">
          <path id="Path_43884" data-name="Path 43884" d="M38.438,29.2a13.1,13.1,0,0,1-2.623,7.889v.009a13.462,13.462,0,0,1-2.661,2.67A13.211,13.211,0,1,1,38.438,29.2Z" transform="translate(-12.017 -15.985)" fill="#00325a" />
        </g>
        <g id="Group_24646" data-name="Group 24646" transform="translate(3.79 3.774)">
          <ellipse id="Ellipse_3694" data-name="Ellipse 3694" cx="9.436" cy="9.436" rx="9.436" ry="9.436" fill="#fab400" />
        </g>
        <g id="Group_24647" data-name="Group 24647" transform="translate(7.549 9.45)">
          <path id="Path_43885" data-name="Path 43885" d="M27.549,35.436H21.887A1.887,1.887,0,0,1,20,33.549V27.887A1.887,1.887,0,0,1,21.887,26h5.662a1.887,1.887,0,0,1,1.887,1.887v5.662A1.887,1.887,0,0,1,27.549,35.436Z" transform="translate(-20 -26)" fill="#f0f0f0" />
        </g>
        <g id="Group_24648" data-name="Group 24648" transform="translate(23.073 23.042)">
          <path id="Path_43886" data-name="Path 43886" d="M54.112,58.117a2.5,2.5,0,0,1-3.539.118L36.947,44.571c-.566-.448-.6-.967-.33-1.345a1,1,0,0,1,.094-.113l2.435-2.435a1.641,1.641,0,0,1,.142-.123.969.969,0,0,1,1.2.123L54.112,54.342A2.66,2.66,0,0,1,54.112,58.117Z" transform="translate(-36.452 -40.404)" fill="#00325a" />
        </g>
      </g>
      <g id="Group_24650" data-name="Group 24650" transform="translate(22.647 0)">
        <path id="Path_43887" data-name="Path 43887" d="M43.323,13.436v1.887H33.887A1.893,1.893,0,0,1,32,13.436V4h1.887Z" transform="translate(-32 -4)" fill="#dc9600" />
      </g>
      <g id="Group_24651" data-name="Group 24651" transform="translate(24.534 0)">
        <path id="Path_43888" data-name="Path 43888" d="M43.436,13.436H34.944A.944.944,0,0,1,34,12.492V4Z" transform="translate(-34 -4)" fill="#fab400" />
      </g>
      <g id="Group_24653" data-name="Group 24653" transform="translate(13.665 21.717)">
        <g id="Group_24652" data-name="Group 24652">
          <path id="Path_43889" data-name="Path 43889" d="M30.777,27.291l-4.683,4.683-2-2a.944.944,0,0,0-1.337,0h0a.945.945,0,0,0,0,1.332l2.678,2.678a.945.945,0,0,0,1.335,0l5.341-5.348a.951.951,0,0,0,0-1.346.939.939,0,0,0-1.333,0Z" transform="translate(-22.482 -27.015)" fill="#00143c" />
        </g>
      </g>
    </svg>
  );
}

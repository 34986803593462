export default function Control2({ color = '#FFFFFF', width = '15', height = '16' }) {
  return (
    <svg id="Group_14928" data-name="Group 14928" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 15 16">
      <defs>
        <clipPath id="clipPath">
          <path id="Path_11418" data-name="Path 11418" d="M7.5-16a.5.5,0,0,1,.5.5v1.541a2.577,2.577,0,0,1,2.091,2.547A2.577,2.577,0,0,1,8-8.865V-.5a.5.5,0,0,1-.5.5A.5.5,0,0,1,7-.5V-8.883a2.58,2.58,0,0,1-2-2.529,2.58,2.58,0,0,1,2-2.529V-15.5A.5.5,0,0,1,7.5-16Zm-5,0a.5.5,0,0,1,.5.5v5.424A2.577,2.577,0,0,1,5.091-7.529,2.577,2.577,0,0,1,3-4.982V-.5a.5.5,0,0,1-.5.5A.5.5,0,0,1,2-.5V-5A2.58,2.58,0,0,1,0-7.529a2.58,2.58,0,0,1,2-2.529V-15.5A.5.5,0,0,1,2.5-16Zm10,0a.5.5,0,0,1,.5.5v7.383a2.58,2.58,0,0,1,2,2.529A2.58,2.58,0,0,1,13-3.06V-.5a.5.5,0,0,1-.5.5A.5.5,0,0,1,12-.5V-3.041A2.577,2.577,0,0,1,9.909-5.588,2.577,2.577,0,0,1,12-8.135V-15.5A.5.5,0,0,1,12.5-16Zm-.045,9.118a1.284,1.284,0,0,0-1.273,1.294,1.284,1.284,0,0,0,1.273,1.294,1.284,1.284,0,0,0,1.273-1.294A1.284,1.284,0,0,0,12.455-6.882ZM2.545-8.824A1.284,1.284,0,0,0,1.273-7.529,1.284,1.284,0,0,0,2.545-6.235,1.284,1.284,0,0,0,3.818-7.529,1.284,1.284,0,0,0,2.545-8.824Zm5-3.882a1.284,1.284,0,0,0-1.273,1.294,1.284,1.284,0,0,0,1.273,1.294,1.284,1.284,0,0,0,1.273-1.294A1.284,1.284,0,0,0,7.545-12.706Z" transform="translate(0 16)" fill={color} clipRule="evenodd" />
        </clipPath>
        <clipPath id="clipPath-2">
          <path id="Path_11417" data-name="Path 11417" d="M-853,432H347V-778H-853Z" transform="translate(853 778)" fill={color} />
        </clipPath>
      </defs>
      <g id="Group_2784" data-name="Group 2784" clipPath="url(#clipPath)">
        <g id="Group_2783" data-name="Group 2783" transform="translate(-853 -762)" clipPath="url(#clipPath-2)">
          <path id="Path_11416" data-name="Path 11416" d="M-5-21H20V5H-5Z" transform="translate(853 778)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

import styled from "styled-components";

export const BarItem3Styled = styled.div`
  .bar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // .icon_label_percent_box {
    //   display: flex;
    //   align-items: baseline;
    //   margin-bottom: 11px;
    //   .icon {
    //     margin-right: 2.5px;
    //   }
    //   .txt_label {
    //     font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    //     color: ${({ theme }) => theme.COLORS.GRAY_1};
    //     font-family: noto_sans_bold;
    //     .txt_percent_label {
    //       margin-left: 1.5px;
    //       font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    //     }
    //     &.blue {
    //       color: ${({ theme }) => theme.COLORS.RED_3};
    //     }
    //     &.red {
    //       color: ${({ theme }) => theme.COLORS.BLUE_2};
    //     }
    //   }
    // }
    .show_score {
      margin-bottom: 11px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S28};
      color: ${({ theme }) => theme.COLORS.PURPLE_6};
      font-family: noto_sans_bold;
    }
    .bar_percent_box {
      .bar_item {
        position: relative;
        width: 24px;
        min-height: ${({ chartMinheight }) =>
          chartMinheight ? chartMinheight : "192px"};
        border-radius: 16px;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .bar_value {
          position: absolute;
          bottom: 0;
          background: ${({ theme }) => theme.COLORS.PURPLE_6};
          height: ${({ score }) => score || "0"}%;
          width: 100%;
          border-radius: 16px;
        }
        .bar_icon {
          position: absolute;
          bottom: -34px;
          left: -7px;
          z-index: 2;
        }
      }
    }
    .description_box {
      margin-top: 34px;
      text-align: center;
      .txt_head {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
      .txt_sub {
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        color: ${({ theme }) => theme.COLORS.GRAY_4};
      }
      .txt_score {
        font-size: ${({ theme }) => theme.FONT.SIZE.S28};
        font-family: noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.RED_4};
      }
    }
  }

  .theme_standard {
  }

  .theme_blue {
    .bar_container {
      .show_score {
        color: ${({ theme }) => theme.COLORS.BLUE_14};
      }
      .bar_percent_box {
        .bar_item {
          .bar_value {
            background: ${({ theme }) => theme.COLORS.BLUE_14};
          }
        }
      }
      .description_box {
        .txt_score {
          color: ${({ theme }) => theme.COLORS.BLUE_12};
        }
      }
    }
  }

  .theme_orange {
    .bar_container {
      .show_score {
        color: ${({ theme }) => theme.COLORS.RED_2};
      }
      .bar_percent_box {
        .bar_item {
          .bar_value {
            background: ${({ theme }) => theme.COLORS.RED_2};
          }
        }
      }
      .description_box {
        .txt_score {
          color: ${({ theme }) => theme.COLORS.ORANGE_8};
        }
      }
    }
  }
`;

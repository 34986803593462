import styled from "styled-components";

export const AccountManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.mb49 {
        margin-bottom: 49px;
      }
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
        .bw140 {
          width: 140px;
        }
        .back_click {
          display: flex;
          cursor: pointer;
        }
      }
    }
  }
  .tab_show {
    margin: 32px 0px;
  }
  .filter_content_zone {
    // margin-bottom: 24px;
    flex-wrap: wrap;
    row-gap: 10px;
    display: flex;
    column-gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
    .group_f_left {
      display: flex;
      align-items: flex-end;
      flex: 1;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 20px;
      .w280 {
        width: 280px;
      }
      .w200 {
        width: 200px;
      }
      .w83 {
        width: 83px;
      }
    }
    .log140 {
      width: 140px;
    }
  }
  .layer_two {
    margin-top: 32px;
    .button_show {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .search_layer {
        width: 50%;
      }
      .btn {
        // width: 15%;
        width: 175px;
      }
    }
    .table_show {
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_two {
      .button_show {
        .btn {
          width: 20%;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
      .button_show {
        .btn {
          width: 25%;
        }
      }
    }
  }
`;

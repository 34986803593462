// import React from "react";
import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { MainDashboardContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Blocks, Cards, Typographys, Icons, Loading } from "components";
import {
  TotalCurrentUser,
  NewUser,
  UserPercentage,
  Gender,
  MainDashBoardBMIType2,
  DdcTopDepartmentPuiAndRisk,
  MainDashBoardWellBeing,
} from "widgets";
import { adminService } from "apiServices";
import momentTZ from "moment-timezone";
import { useSelector } from "react-redux";

const MainDashboardContainer = (props) => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [companyUserStatisticsData, setCompanyUserStatisticsData] = useState();
  const [dataGenderBMI, setDataGenderBMI] = useState();
  const [msdBehaviourStatistics, setMsdBehaviourStatistics] = useState();
  const [statisticsWellbeingData, setStatisticsWellbeingData] = useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchCompanyUserStatistics(),
      fetchGenderBMIStatistics(),
      fetchMsdSummaryByCompanyId(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}&period=weekly`
      ),
      fetchStatisticsWellbeing(),
    ]);
    setIsLoading(false);
    if (res) {
      setCompanyUserStatisticsData(res[0]);
      setDataGenderBMI(res[1]);
      setMsdBehaviourStatistics(res[2]);
      setStatisticsWellbeingData(res[3]);
    }
  };

  const fetchStatisticsWellbeing = async () => {
    let res = await adminService.GET_STATISTICS_COMPANY_WELLBEING_SUMMARY(
      companySelectedRedux.id
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchMsdSummaryByCompanyId = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_MSD_SUMMARY_BY_COMPANY_ID(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.department.name,
        norisk_user_count: e.norisk_user_count,
        pui_user_count: e.pui_user_count,
        user_count: e.user_count,
      }));
      data.sort((a, b) => b.user_count - a.user_count);
      return {
        data: data,
        startDate:
          initialStartDate ||
          momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
      };
    }
  };

  const fetchCompanyUserStatistics = async () => {
    let res = await adminService.GET_COMPANY_STATISTICS(
      companySelectedRedux?.id
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchGenderBMIStatistics = async () => {
    let res = await adminService.GET_GENDER_BMI_STATISTICS(
      companySelectedRedux?.id
    );

    if (res && res.success) {
      return res.data;
    }
  };

  return (
    <MainDashboardContainerStyled>
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label="Main Dashboard"
              svgFront={<Icons.Graph />}
            />
          </div>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/groupGirls.svg"
              titleLabel="Main Dashboard"
              label1="Main Dashboard คือ การแสดงภาพรวมยอดผู้ใช้งานในบริษัทในการตอบคำถามโดยจะแสดงเป็นค่าเฉลี่ยและเรียงตามลำดับ ไม่ว่าจะเป็นค่าเฉลี่ย BMI,การตอบคำถาม Well-being  และ MSD และสามารถดูยอดผู้ป่วยที่มีความเสี่ยงเป็นโรค PUI  โดยจะสามารถดูได้ทั้งรายเดือน และ รายสัปดาห์"
            />
          </div>
          <div className="block_4_col_layout">
            <div className="col_4">
              <TotalCurrentUser
                data={companyUserStatisticsData && companyUserStatisticsData}
              />
            </div>
            <div className="col_4">
              <NewUser
                data={companyUserStatisticsData && companyUserStatisticsData}
              />
            </div>
            <div className="col_4">
              <UserPercentage
                data={companyUserStatisticsData && companyUserStatisticsData}
              />
            </div>
            <div className="col_4">
              <div className="wrap_mr5_overflow">
                <Gender
                  data={companyUserStatisticsData && companyUserStatisticsData}
                />
              </div>
            </div>
          </div>
          <div className="block_2_col_layout">
            <div className="col_2 w_384">
              <MainDashBoardBMIType2 data={dataGenderBMI && dataGenderBMI} />
            </div>
            <div className="col_2 flex_1 mw_660">
              <div className="wrap_mr5_overflow">
                <MainDashBoardWellBeing
                  height="457px"
                  data={statisticsWellbeingData && statisticsWellbeingData}
                />
              </div>
            </div>
          </div>
          <div className="section_chart_layout">
            <DdcTopDepartmentPuiAndRisk
              data={msdBehaviourStatistics && msdBehaviourStatistics}
              label="7 แผนกสูงสุดที่มีจำนวนผู้ป่วย PUI และผู้ป่วยที่มีอาการเจ็บ แต่ไม่มีความเสี่ยง"
              labelBottom="Top 7 departments which employees are at PUI risk and pain without risk "
              fetchData={fetchMsdSummaryByCompanyId}
            />
          </div>
        </Blocks.Container>
      )}
    </MainDashboardContainerStyled>
  );
};
export default MainDashboardContainer;

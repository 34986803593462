import styled from 'styled-components';

export const Table1Styled = styled.div`
  .chart_container {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size:  ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: noto_sans_thai_semi_bold, noto_sans_regular;
    border: 1px solid #E3E3E3;
    border-bottom: none;
    .header {
      display: flex;
      border-bottom: 1px solid #E3E3E3;
      .h1 {
        max-width: 30%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #E3E3E3;
        padding: 29px 10px;
      }
      .h2 {
        max-width: 70%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 29px 10px;
        text-align: center; 
      }
    }
    .row {
      display: flex;
      border-bottom: 1px solid #E3E3E3;
      .r1 {
        max-width: 30%;
        width: 100%;
        padding: 8.5px 10px;
        font-size:  ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .r2 {
        max-width: 70%;
        width: 100%;
        display: flex;
        .value_show {
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size:  ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: noto_sans_thai_bold, noto_sans_bold;
          &.green {
            background: #8AC47F;
          }
          &.blue {
            background: #6FB5CB;
          }
          &.purple {
            background: #654C96;
          }
        }
      }
    }
  }

  .theme_standard_table {
  }
`;

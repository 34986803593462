export default function Graph({ color = '#575D96' }) {
  return (
    <svg
      id="poll"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_1037"
        data-name="Path 1037"
        d="M19,4H5A1,1,0,0,0,4,5V19a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V5A1,1,0,0,0,19,4ZM5,2A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3Z"
        transform="translate(-2 -2)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        id="Path_1038"
        data-name="Path 1038"
        d="M11,7h2V17H11Z"
        transform="translate(-2 -2)"
        fill={color}
      />
      <path
        id="Path_1039"
        data-name="Path 1039"
        d="M15,13h2v4H15Z"
        transform="translate(-2 -2)"
        fill={color}
      />
      <path
        id="Path_1040"
        data-name="Path 1040"
        d="M7,10H9v7H7Z"
        transform="translate(-2 -2)"
        fill={color}
      />
    </svg>
  );
}

import React from "react";
import { connect } from "react-redux";
import { CurrentMSDStatContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards, Typographys, Icons } from "components";
import { adminService } from "apiServices";
import millify from "millify";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import {
  MsdPercentOfRiskPui,
  MsdPercentOfRiskPuiAndPain,
  MsdPercentOfOrgans,
  DdcTopDepartmentPuiAndRisk,
  MsdPercentOfOrgansByDeparment,
} from "widgets";
import momentTZ from "moment-timezone";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";

class CurrentMSDStatContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    console.log("fetchData1");
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async () => {
    console.log("fetchData");
    let res = await Promise.all([
      this.fetchAllUserStatistics(),
      this.fetchAllMsdStatistics(),
      this.fetchAllMsdTrend("?period=weekly&offset=0&per_page=8"),
      this.fetchMsdWorkTypeStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      this.fetchMsdOrganStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      this.fetchMsdBehaviourStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}&period=weekly`
      ),
      this.fetchMsdOrganDepartmentStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .startOf("month")
          .format("YYYY-MM-DD")}&period=monthly`
      ),
    ]);

    if (res) {
      this.setState({
        allUserStatisticsData: res[0],
        allMsdStatisticsData: res[1],
        allMsdTrendData: res[2],
        msdWorkType: res[3],
        msdOrganStatistics: res[4],
        msdBehaviourStatistics: res[5],
        msdOrganDepartmentStatistics: res[6],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchAllUserStatistics = async () => {
    let res = await adminService.GET_USER_STATISTICS(
      `?stat_date=${moment()
        .startOf("week")
        .isoWeekday(1)
        .format("YYYY-MM-DD")}`
    );

    if (res && res.success) {
      return res.data;
    }
  };

  fetchAllMsdStatistics = async () => {
    let res = await adminService.GET_MSD_ALL_STATISTICS();

    if (res && res.success) {
      return res.data;
    }
  };

  fetchAllMsdTrend = async (stringQuery) => {
    let res = await adminService.GET_MSD_ALL_TREND_STATISTICS(stringQuery);

    if (res && res.success) {
      return res.data.reverse();
    }
  };

  fetchMsdWorkTypeStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_WORKTYPE_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data;
    }
  };

  fetchMsdOrganStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_ORGAN_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data;
    }
  };

  fetchMsdBehaviourStatistics = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_ALL_MSD_COMPANY_SUMMARY(stringQuery);
    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.company.name,
        norisk_user_count: e.norisk_user_count,
        pui_user_count: e.pui_user_count,
        user_count: e.user_count,
      }));
      data.sort((a, b) => b.user_count - a.user_count);
      return {
        data: data,
        startDate:
          initialStartDate ||
          momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
      };
    }
  };

  fetchMsdOrganDepartmentStatistics = async (stringQuery) => {
    let res = await adminService.GET_ALL_MSD_COMPANY_WITH_ORGAN(stringQuery);

    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.company.name,
        norisk_user: e.norisk_user,
        nosymptom_user: e.nosymptom_user,
        pui_organs: e.pui_organs,
        pui_user: e.pui_user,
      }));
      return sliceIntoChunks(data, 4);
    }
  };

  render() {
    const {
      isLoading,
      allUserStatisticsData,
      allMsdStatisticsData,
      allMsdTrendData,
      msdWorkType,
      msdOrganStatistics,
      msdBehaviourStatistics,
      msdOrganDepartmentStatistics,
    } = this.state;

    return (
      <CurrentMSDStatContainerStyled>
        {isLoading ? (
          <div className="loading_layout">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="section_introduce_layout">
              <Cards.BoxIntroduce
                src="/assets/images/logo/msd_man.svg"
                titleLabel="MSD (Musculoskeletal Disorders)"
                label1="MSD (Musculoskeletal Disorders) หมายถึง อาการบาดเจ็บที่ระบบกล้ามเนื้อและกระดูกของร่างกายมนุษย์ ซึ่งมีสาเหตุมาจากการทำงานหนักเกิน ไป หรือ รักษาท่าทางที่ไม่ดีในที่ทำงาน การรักษาตั้งแต่ระยะเริ่มต้น อาจช่วยบรรเทาอาการและปรับปรุงแนวโน้มในระยะยาวได้"
              />
            </div>
            <div className="block_3_col_layout">
              <div className="col_3">
                <Typographys.IconTitleSubTxt
                  svg={<Icons.Build />}
                  theme_flex_col
                  title="จำนวนบริษัททั้งหมด"
                  subTitle="Total number of companies"
                  height="182px"
                  score={
                    allUserStatisticsData &&
                    millify(Number(allUserStatisticsData.total_company_count))
                  }
                  suffixLabelScore="บริษัท"
                />
              </div>
              <div className="col_3">
                <Typographys.IconTitleSubTxt
                  svg={<Icons.ManMsd />}
                  theme_flex_col
                  title="จำนวนพนักงานที่ตอบคำถาม MSD สัปดาห์ที่แล้ว"
                  subTitle="Number of employees answering the MSD questions last week"
                  score={
                    allMsdStatisticsData &&
                    RenderCommaMoney(
                      Number(allMsdStatisticsData.last_week_msd_user_count),
                      true
                    )
                  }
                  suffixLabelScore="คน"
                  height="182px"
                />
              </div>
              <div className="col_3">
                <div className="wrap_mr5_overflow">
                  <Typographys.IconTitleSubTxt
                    svg={<Icons.ManMsd />}
                    title="จำนวนพนักงานที่ตอบคำถาม MSD ไตรมาสปัจจุบัน"
                    subTitle="Number of employees answering the MSD questions this quarter"
                    // prefixLabelScore="รวม 3 เดือนล่าสุด"
                    score={
                      allMsdStatisticsData &&
                      RenderCommaMoney(
                        Number(
                          allMsdStatisticsData.current_quarter_msd_user_count
                        ),
                        true
                      )
                    }
                    suffixLabelScore="คน"
                    height="182px"
                  />
                </div>
              </div>
            </div>
            <div className="block_3_col_layout mb_10">
              <div className="col_3">
                <Typographys.IconTitleSubTxt
                  svg={<Icons.NumberOfUse />}
                  theme_flex_col
                  title="จำนวนผู้ใช้"
                  subTitle="Number of User"
                  score={
                    allUserStatisticsData &&
                    millify(Number(allUserStatisticsData.total_user_count))
                  }
                  suffixLabelScore="คน"
                  height="182px"
                />
              </div>
              <div className="col_3">
                <Typographys.IconTitleSubTxt
                  svg={
                    <Icons.MSD color="#0F8989" width="25.61" height="32.93" />
                  }
                  title="จำนวนพนักงานมีอาการเจ็บแต่ไม่มีความเสี่ยงสัปดาห์ที่แล้ว"
                  subTitle="Number of employees who are pain without risk last week"
                  score={
                    allMsdStatisticsData &&
                    allMsdStatisticsData.last_week_norisk_count > 0
                      ? RenderCommaMoney(
                          Number(allMsdStatisticsData.last_week_norisk_count),
                          true
                        )
                      : "0"
                  }
                  suffixLabelScore="คน"
                  bottomColor="#6FB5CB"
                  height="182px"
                />
              </div>
              <div className="col_3">
                <div className="wrap_mr5_overflow">
                  <Typographys.IconTitleSubTxt
                    svg={
                      <Icons.MSD color="#654C96" width="25.61" height="32.93" />
                    }
                    title="จำนวนพนักงานที่มีความเสี่ยง PUI สัปดาห์ที่แล้ว"
                    subTitle="Number of employees who are at PUI risk last week"
                    score={
                      allMsdStatisticsData &&
                      allMsdStatisticsData.last_week_pui_count > 0
                        ? RenderCommaMoney(
                            Number(allMsdStatisticsData.last_week_pui_count),
                            true
                          )
                        : "0"
                    }
                    suffixLabelScore="คน"
                    bottomColor="#654C96"
                    height="182px"
                  />
                </div>
              </div>
            </div>
            <div className="section_chart_layout">
              <MsdPercentOfRiskPui
                data={allMsdTrendData && allMsdTrendData}
                fetchData={this.fetchAllMsdTrend}
              />
            </div>
            <div className="section_chart_layout">
              <DdcTopDepartmentPuiAndRisk
                data={msdBehaviourStatistics && msdBehaviourStatistics}
                label="7 อันดับบริษัทที่พนักงานมีความเสี่ยง PUI และมีเจ็บ แต่ไม่มีความเสี่ยงสูงสุด"
                labelBottom="Top 7 company which employees are at PUI risk and pain without risk"
                fetchData={this.fetchMsdBehaviourStatistics}
              />
            </div>
            <div className="block_2_col_layout no_pdb">
              <div className="col_2 w_416">
                <div className="b2c_block_wrap mb_24">
                  <MsdPercentOfRiskPuiAndPain
                    data={msdWorkType && msdWorkType}
                  />
                </div>
              </div>
              <div className="col_2 flex_1 mw_780">
                <div className="wrap_mr5_overflow">
                  <MsdPercentOfOrgans
                    height="100%"
                    data={msdOrganStatistics && msdOrganStatistics}
                    type="ddc"
                  />
                </div>
              </div>
            </div>
            <div className="section_chart_layout">
              <MsdPercentOfOrgansByDeparment
                data={msdOrganDepartmentStatistics}
                fetchData={this.fetchMsdOrganDepartmentStatistics}
                labelTitle="บริษัท"
                labelEng="company"
              />
            </div>
          </>
        )}
      </CurrentMSDStatContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentMSDStatContainer);

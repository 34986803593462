import styled from "styled-components";

export const UploadSetImageMaxLengthStyled = styled.div`
  width: 100%;
  .theme_upload_set_image {
    display: flex;
    // margin: -10px;
    flex-wrap: wrap;
    width: 100%;
    .show_img {
      height: ${({ heightBox }) => (heightBox ? heightBox : "231px")};
      border-radius: 12px;
      width: ${({ width }) => (width ? width : "calc(32.5% - 10px)")};
      margin: 10px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .close {
        position: absolute;
        right: 7px;
        top: 8px;
        cursor: pointer;
        z-index: 2;
      }
      .block_more {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // opacity: 0.5;
        background: #ffffff80;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        cursor: pointer;
        .num_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S50};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          position: relative;
        }
      }
      .copy_icon {
        display: flex;
        cursor: pointer;
        opacity: 0.7;
      }
      .copy_link {
        background: ${({ theme }) => theme.COLORS.GRAY_23};
        border-radius: 6px;
        width: 96px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        position: absolute;
        top: 142px;
        right: 99px;
      }
    }
    .container_other {
      height: ${({ heightBox }) => (heightBox ? heightBox : "231px")};
      border-radius: 12px;
      width: ${({ width }) => (width ? width : "calc(32.5% - 10px)")};
      margin: 10px;
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .show_other {
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5;
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        position: relative;
      }
    }
    .body_box_container {
      .box_upload {
        height: ${({ heightBox }) => (heightBox ? heightBox : "231px")};
        border-radius: 17px;
        background: #dddddd;
        width: ${({ width }) => (width ? width : "calc(32.5% - 10px)")};
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        .show_svg {
          display: flex;
        }
        .g_label_img {
          display: flex;
          flex-direction: column;
          align-items: center;
          .h1 {
            margin-top: 4px;
            font-size: ${({ theme, fontSizeLabelTop }) =>
              fontSizeLabelTop ? fontSizeLabelTop : theme.FONT.SIZE.S18};
            font-family: ${({ theme, fontFamilyLabelTop }) =>
              fontFamilyLabelTop
                ? fontFamilyLabelTop
                : theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
          }
          .sub {
            margin-top: 4px;
            font-size: ${({ theme, fontSizeLabelBottom }) =>
              fontSizeLabelBottom ? fontSizeLabelBottom : theme.FONT.SIZE.S14};
            font-family: ${({ theme, fontFamilyLabelBottom }) =>
              fontFamilyLabelBottom
                ? fontFamilyLabelBottom
                : theme.FONT.STYLE.LIGHT};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
          }
        }
      }
      .desc_txt {
        width: ${({ width }) => (width ? width : "calc(32.5% - 10px)")};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        text-align: center;
      }
    }
    .btn_show {
      width: 100%;
      margin-top: 33px;
      padding: 0px 17%;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_upload_set_image {
      .show_img {
      }
      .box_upload {
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_upload_set_image {
      .show_img {
      }
      .box_upload {
      }
    }
  }
`;

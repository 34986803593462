import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BarItem2Styled } from './styled';

const BarItem2 = ({
  theme_number_employees_bar,
  theme_mini_bar_blue,
  theme_mini_bar_orange,
  percent,
  minHeight,
  showLetLine,
  showPercent,
}) => {
  const customClass = cx({
    theme_number_employees_bar: theme_number_employees_bar,
    theme_mini_bar_blue: theme_mini_bar_blue,
    theme_mini_bar_orange: theme_mini_bar_orange,
  });
  return (
    <BarItem2Styled
      percent={percent}
      minHeight={minHeight}
    >
      <div className={customClass}>
        {
          showLetLine &&
          <div className='left_line'>
            {
              percent > 0 &&
              <div className='group_line' style={{ minHeight: percent > 0 ? '50px' : '0px' }}>
                <div className='round' />
                <div className='show_line' />
              </div>
            }
          </div>
        }
        <div className="bar_container">
          <div className="bar_inner">
            <div className='group_bar' style={{ minHeight: percent > 0 ? '50px' : '0px' }}>
              {
                showPercent && showPercent > 0 &&
                <div className='percent'>
                  {`${showPercent}%`}
                </div>
              }
              <div className="item left" />
              <div className="item right" />
            </div>

            {/* <div className="item left" />
            <div className="item right" /> */}
          </div>
        </div>
      </div>
    </BarItem2Styled >
  );
};

BarItem2.propTypes = {};

export default BarItem2;
import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputMaskTextStyled } from "./styled";
import InputMask from 'react-input-mask';
// import { Buttons, Icons } from "components";

const InputMaskText = ({
  theme_mask_text,
  maskFormat,
  maskSpace,
  value,
  errors,
  placeholder,
  disabled,
  onChange,
  fontColor,
  // show_bottom_label,
  // fontColor,
  // placeholder,
  // svg,
  // onSubmit,
  // initialValue,
}) => {
  const customClass = cx({
    theme_mask_text: theme_mask_text,
  });

  return (
    <InputMaskTextStyled
      disabled={disabled}
      fontColor={fontColor}
    >
      <div className={customClass}>
        <div className="box_mask_style">
          <InputMask
            mask={maskFormat}
            maskChar={maskSpace}
            disabled={disabled}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
        {errors && <div className="error">{`* ${errors}`}</div>}
      </div>
    </InputMaskTextStyled>
  );
};

InputMaskText.propTypes = {};

export default InputMaskText;

import styled from 'styled-components';

export const CompanyMisssionStyled = styled.div`
  font-family: noto_sans_thai_medium, noto_sans_regular;
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  min-width: 712px;
  .top_current {
    display: flex;
    justify-content: space-between;
    .top_left_current {
      display: flex;
      margin-left: 10px;
      .show_icon {
        margin-right: 16px;
        display: flex;
      }
    }
    .top_right_current {
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      .date {
        // font-family: noto_sans_bold;
      }
    }
  }
  .mid_current {
    margin-top: 10px;
    margin-left: 10px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S28};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-family: noto_sans_thai_bold, noto_sans_bold;
  }
  .bottom_current {
    margin-top: 24px;
    display: flex;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    align-items: center;
    .show_icon_bottom {
      margin-right: 13px;
      display: flex;
    }
    .date_detail {
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
  }
`;

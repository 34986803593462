import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { SuccesBoxStyled } from './styled'

const SuccesBox = ({ theme_standard_succes_box, theme_standard_un_succes_box, svg, label, labelBottom }) => {
  const customClass = cx({
    theme_standard_succes_box: theme_standard_succes_box,
    theme_standard_un_succes_box: theme_standard_un_succes_box,
  })
  return (
    <SuccesBoxStyled>
      <div className={customClass}>
        <div className='show_svg'>
          {svg}
        </div>
        <div className='group_label'>
          <div className='top_success'>
            {label}
          </div>
          <div className='bottom_success'>
            {labelBottom}
          </div>
        </div>
      </div>
    </SuccesBoxStyled>
  )
}

SuccesBox.propTypes = {}

export default SuccesBox

import { TotalLikeStyled } from './styled';
import { Blocks, Icons, Typographys } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalLike = ({ data }) => {
  return (
    <TotalLikeStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="19px"
        paddingRight="13px"
        paddingBottom="19px"
        paddingLeft="13px"
      >
        <div className="top_like">
          <div className="show_icon">
            <Icons.Heart />
          </div>
          <Typographys.TopBottom
            theme_left_align
            label="จำนวนคนกดไลค์"
            labelBottom="Total Like"
            fontSizeLabelButtom="14px"
          />
        </div>
        <div className="mid_like">
          <div className="show_num">
            {data && RenderCommaMoney(data.toFixed(0), true)}
          </div>
          <div className="label">ไลค์</div>
        </div>
      </Blocks.Box>
    </TotalLikeStyled>
  );
};

export default TotalLike;

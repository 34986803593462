import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TableSkeletonStyled } from './styled';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const TableSkeleton = ({ theme_most_picked }) => {
  const customClass = cx({
    theme_most_picked: theme_most_picked,
  });
  return (
    <TableSkeletonStyled>
      <div className={customClass}>
        <SkeletonTheme color="#DEDEDE" highlightColor="#F3F3F4">
          <div className='top_skeleton'>
            <Skeleton width='100%' />
          </div>
          <div className='head_skeleton'>
            <Skeleton width='100%' />
          </div>
          <div className="body_skeleton">
            <Skeleton width='100%' height='98%' />
          </div>
        </SkeletonTheme>
      </div>
    </TableSkeletonStyled>
  );
};

TableSkeleton.propTypes = {};

export default TableSkeleton;

import React from "react";
import { connect } from "react-redux";
import { ForgotPasswordContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import queryString from "query-string";
import { adminService } from "apiServices";
import {
  setReduxAuthen,
  setReduxCompanyList,
  setReduxCompanySelected,
} from "store/actions";
import { ROUTE_PATH } from "utils/constants";
import { toast } from "react-toastify";
import { Loading, Forms, BGLoading, Buttons, Icons } from "components";

class ForgotEmailPasswordContainer extends React.Component {
  state = { isLoading: true, step: 1 };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    const { ref_no } = queryString.parse(this.props.location.search);

    if (!ref_no) {
      this.props.history.push("/");
    } else {
      localStorage.clear();
      let res = await adminService.GET_RESET_PASSWORD_BY_REF_NO(ref_no);

      if (res && res.success) {
        this.setState({
          email: res.data.email,
          dataCheckEmail: res.data,
          isLoading: false,
        });
      } else {
        toast.error(this.renderError(res.error_code));
        this.props.history.push("/");
      }
    }
  };

  onSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    if (values) {
      let res = await adminService.POST_EMAIL_RESET_PASSWORD(values.email);

      if (res && res.success) {
        this.props.history.push(
          `${ROUTE_PATH.FORGOT_PASSWORD}?ref_no=${res.data.ref_no} `
        );
        this.setState({
          step: 2,
          email: values.email,
          dataCheckEmail: res.data,
          isBgLoading: false,
        });
      } else {
        toast.error(res.error);
        this.setState({
          isBgLoading: false,
        });
      }
    }
  };

  onSubmitOTP = async (values) => {
    const { dataCheckEmail } = this.state;

    this.setState({
      isBgLoading: true,
    });
    let res = await adminService.GET_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      `?password=${values.otp}`
    );

    if (res && res.success) {
      this.setState({
        passwordOtp: values.otp,
        step: 3,
        email: values.email,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  onSubmitChangePassword = async (values) => {
    const { dataCheckEmail, passwordOtp } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      password: passwordOtp,
      new_password: values.password,
    };
    let res = await adminService.PUT_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      params
    );
    if (res && res.success) {
      toast.success("Change password success");
      // this.props.history.push(ROUTE_PATH.LOGIN);
      this.setState({
        step: 4,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  renderError = (e) => {
    switch (e) {
      case "REFNO_INVALID":
        return "ref no. ไม่ถูกต้อง";
      case "OTP_EXPIRED":
        return "OTP หมดอายุ";
      case "PASSWORD_INCORRECT":
        return "รหัสไม่ถูกต้อง";
      default:
        return "เกิดข้อผิดพลาด";
    }
  };

  renderStep = () => {
    const { step, email, dataCheckEmail } = this.state;
    switch (step) {
      case 1:
        return (
          <Forms.OTPForm
            onSubmit={this.onSubmitOTP}
            email={email}
            data={dataCheckEmail && dataCheckEmail}
          />
        );
      case 2:
        return (
          <Forms.ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
        );

      case 3:
        return (
          <Forms.ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
        );

      case 4:
        return (
          <div className="b_success">
            <div className="logo_icon">
              <Icons.RoundCheck width="56" height="56" />
            </div>
            <div className="text_b">สร้างรหัสผ่านใหม่สำเร็จ</div>
            <div className="fw">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label="กลับหน้าเข้าสู่ระบบ"
                onClick={this.handleGoLogin}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };

  handleGoLogin = () => {
    this.props.history.push(ROUTE_PATH.LOGIN);
  };

  render() {
    const { isLoading, isBgLoading } = this.state;
    return (
      <ForgotPasswordContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="logo_show">
              <img
                className="img_logo"
                src={"/assets/images/logo/logo_login.svg"}
                alt="img"
              />
            </div>
            <div className="form_layout">{this.renderStep()}</div>
          </>
        )}
      </ForgotPasswordContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  rewardCategory: state.rewardCategory,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxCompanyList: (data) => dispatch(setReduxCompanyList(data)),
  setReduxCompanySelected: (data) => dispatch(setReduxCompanySelected(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotEmailPasswordContainer);

export default function TypeBook({ width = '14.722', height = '12.304' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14.722 12.304">
      <g id="documentation" transform="translate(0 -42.04)">
        <path id="Path_12212" data-name="Path 12212" d="M14.29,93.86h-.431v7.567a1.281,1.281,0,0,1-1.264,1.294H2.127a1.281,1.281,0,0,1-1.264-1.294V93.86H.431A.431.431,0,0,0,0,94.291v8.875a.431.431,0,0,0,.431.431H5.916a1.508,1.508,0,0,0,2.889,0H14.29a.431.431,0,0,0,.431-.431V94.291A.431.431,0,0,0,14.29,93.86Z" transform="translate(0 -50.33)" fill="#e4a39e" />
        <path id="Path_12213" data-name="Path 12213" d="M276.2,51.1V42.471a.417.417,0,0,0-.4-.431H271v9.489h4.8A.417.417,0,0,0,276.2,51.1Zm-1.73-1.294H272.72a.431.431,0,0,1,0-.863h1.754a.431.431,0,1,1,0,.863Zm0-1.725H272.72a.431.431,0,0,1,0-.863h1.754a.431.431,0,1,1,0,.863Zm.431-2.156a.431.431,0,0,1-.431.431H272.72a.431.431,0,0,1-.431-.431V44.2a.431.431,0,0,1,.431-.431h1.754a.431.431,0,0,1,.431.431Z" transform="translate(-263.208)" fill="#e4a39e" />
        <path id="Path_12214" data-name="Path 12214" d="M345.83,132.04h.891v.863h-.891Z" transform="translate(-335.886 -87.412)" fill="#e4a39e" />
        <path id="Path_12215" data-name="Path 12215" d="M60.4,51.529h4.8V42.04H60.4a.417.417,0,0,0-.4.431V51.1A.417.417,0,0,0,60.4,51.529Zm1.329-7.763h1.754a.431.431,0,1,1,0,.863H61.73a.431.431,0,1,1,0-.863Zm0,1.725h1.754a.431.431,0,1,1,0,.863H61.73a.431.431,0,1,1,0-.863Zm0,1.725h1.754a.431.431,0,0,1,0,.863H61.73a.431.431,0,1,1,0-.863Zm0,1.725h1.754a.431.431,0,0,1,0,.863H61.73a.431.431,0,1,1,0-.863Z" transform="translate(-58.275)" fill="#e4a39e" />
      </g>
    </svg>
  );
}

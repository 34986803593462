import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { BtnDateFillterStyled } from "./styled";

const BtnDateFillter = ({
  theme_standard_date_filter,
  initial,
  onChange,
  labeDisplay,
}) => {
  const customClass = cx({
    theme_standard_date_filter: theme_standard_date_filter,
  });

  const [selected, setSelected] = useState(1);
  const [_labelMenu, _setLabelMenu] = useState({
    menu1Top: "1 สัปดาห์",
    menu1Bottom: "1 Week",
    menu2Top: "12 สัปดาห์",
    menu2Bottom: "12 Weeks",
    menu3Top: "24 สัปดาห์",
    menu3Bottom: "24 Weeks",
  });

  useEffect(() => {
    if (initial) {
      setSelected(initial);
    }
    if (labeDisplay) {
      _setLabelMenu({
        menu1Top: "1 สัปดาห์",
        menu1Bottom: "1 Week",
        menu2Top: "3 เดือน",
        menu2Bottom: "3 Months",
        menu3Top: "6 เดือน",
        menu3Bottom: "6 Months",
      });
    }
  }, [initial, labeDisplay]);

  const handleClickTab = (key) => {
    setSelected(key);
    onChange && onChange(key);
  };

  return (
    <BtnDateFillterStyled>
      <div className={customClass}>
        <div className="top_opt">
          <div
            key={1}
            className={`opt1 ${selected === "weekly" ? "active" : ""}`}
            onClick={() => handleClickTab("weekly")}
          >
            {_labelMenu.menu1Top}
          </div>
          <div
            key={2}
            className={`opt2 ${selected === "quarterly" ? "active" : ""}`}
            onClick={() => handleClickTab("quarterly")}
          >
            {_labelMenu.menu2Top}
          </div>
          <div
            key={3}
            className={`opt3 ${selected === "halfyearly" ? "active" : ""}`}
            onClick={() => handleClickTab("halfyearly")}
          >
            {_labelMenu.menu3Top}
          </div>
        </div>
        <div className="bottom_opt">
          <div
            key={1}
            className={`label1 ${selected === "weekly" ? "active" : ""}`}
          >
            {_labelMenu.menu1Bottom}
          </div>
          <div
            key={2}
            className={`label2 ${selected === "quarterly" ? "active" : ""}`}
          >
            {_labelMenu.menu2Bottom}
          </div>
          <div
            key={3}
            className={`label2 ${selected === "halfyearly" ? "active" : ""}`}
          >
            {_labelMenu.menu3Bottom}
          </div>
        </div>
      </div>
    </BtnDateFillterStyled>
  );
};

// BtnDateFillter.propTypes = {};

export default BtnDateFillter;

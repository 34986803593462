import styled from "styled-components";

export const InputTextRichStyled = styled.div`

  position: relative;
  .title_layout {
    display: flex;
    justify-content: ${({ flexStyle }) =>
      flexStyle ? flexStyle : "space-between"};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    align-items: center;
    .show_title {
      display: flex;
      margin-bottom: 8px;
      .title_display {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        margin-right: 9px;
      }
      .sub_display {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        padding-top: 4px;
      }
    }
    .num_show {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }

  .icon {
    position: absolute;
    top: 59px;
    left: 23px;
  }
  .editor_wrap {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
    padding: 1px;
    resize: none;
    // overflow: auto;
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .editorClassName {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    outline: none;
    padding: 20px;
    // min-height: ${({ minHieght }) => (minHieght ? minHieght : "140px")};
    height: ${({ height }) => (height ? height : "100%")};
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${(props) =>
      props.fontFamily
        ? props.fontFamily
        : ({ theme }) => theme.FONT.STYLE.REGULAR}};
    color: ${(props) =>
      props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.BLACK_1};
    ::placeholder {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      padding-left: ${({ svg }) => (svg ? "40px" : "0px")};
    }
  }

  // ความห่างของบรรทัดข้อความ
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 0px;
  }

  .theme_standard {
  }

  .theme_normal_text_rich {
    .title_layout {
      margin-bottom: 8px;
      .show_title {
        .title_display {
          margin: 0px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      }
    }
    .editorClassName {
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : ({ theme }) => theme.FONT.STYLE.MEDIUM}};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.GRAY_23};
    }
  }

  .theme_text_rich_lucky {
    .title_layout {
      margin-bottom: 8px;
      .show_title {
        .title_display {
          margin: 0px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      }
    }
    .editor_wrap {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      padding: 1px;
      resize: none;
      // overflow: auto;
    }
    .editorClassName {
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : ({ theme }) => theme.FONT.STYLE.REGULAR}};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.GRAY_23};
    }
  }

  .theme_normal {
  .title_layout {
      margin-bottom: 8px;
      .show_title {
        .title_display {
          margin: 0px;
          margin-right: 9px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      }
    }
    .editor_wrap {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      padding: 1px;
      resize: none;
      // overflow: auto;
    }
    .editorClassName {
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : ({ theme }) => theme.FONT.STYLE.REGULAR}};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.GRAY_23};
    }
  }
`;

export default function Copying({ color = "#f0211d" }) {
  return (
    <svg
      id="_006-copying"
      data-name="006-copying"
      xmlns="http://www.w3.org/2000/svg"
      width="16.388"
      height="19"
      viewBox="0 0 16.388 19"
    >
      <rect
        id="Rectangle_146"
        data-name="Rectangle 146"
        width="12.708"
        height="16.368"
        rx="3"
        transform="translate(0 2.632)"
        fill="#0b408d"
      />
      <path
        id="Path_2668"
        data-name="Path 2668"
        d="M21.712,2.9V15.543a1.9,1.9,0,0,1-1.9,1.9h-.632V5.426a3.162,3.162,0,0,0-3.162-3.162H9.18A1.9,1.9,0,0,1,10.963,1h8.852a1.9,1.9,0,0,1,1.9,1.9Z"
        transform="translate(-5.325 -1)"
        fill="#0b408d"
      />
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UploadImage3Styled } from "./styled";
import { Icons, Buttons } from "components";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

const UploadImage3 = ({
  theme_standard_upload_image,
  acceptType = "cover",
  onChange,
  value,
  widthImageSize = 1024,
  heighImageSize = 1024,
  name,
  maxMBFileSizeWarning,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_upload_image: theme_standard_upload_image,
  });

  const [files, setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        // return "image/*";
        return ".png , .jpg , .jpeg";
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return "video/*";
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: async (acceptedFiles) => {
      let canUpload = false
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(6)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          // alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
          toast.error("ไฟล์มีขนาดใหญ่เกินขนาดสูง ไม่สามารถอัพโหลดได้");
        } else {
          canUpload = true
        }
      } else {
        canUpload = true
      }

      if (canUpload) {
        const imageResize = await resizeFile(acceptedFiles);
        let fileTemp = [
          ...imageResize.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              isNewUpload: true,
              fieldName: name,
            })
          ),
        ];

        setFiles(fileTemp);
        onChange && onChange(fileTemp);
      }
    },
  });

  const resizeFile = (files) => {
    return Promise.all(
      files.map((e) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            e,
            1024,
            1024,
            "jpeg",
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            "file"
          );
        });
      })
    ).then((e) => {
      return e;
    });
  };

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  // const onClickRemove = (index, data) => {
  //   if (data.isNewUpload) {
  //     let filesTemp = files;
  //     filesTemp.splice(index, 1);
  //     setFiles([...filesTemp]);
  //   } else {
  //     let filesTemp = files;
  //     filesTemp[index].isDelete = true;
  //     setFiles([...filesTemp]);
  //   }
  // };

  return (
    <UploadImage3Styled>
      <div className={customClass}>
        <div className="title_upload">
          <div className="group_label">
            <div className="label_1">อัพโหลดรูปภาพ</div>
            <div className="label_2">Upload Photo</div>
          </div>
        </div>
        <div
          className={`body_upload ${files && files.length > 0 ? "no_border" : ""
            } `}
        >
          {files && files.length > 0 ? (
            files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  className="show_img"
                  key={i}
                  style={{ backgroundImage: `url(${e.preview})` }}
                  alt="upload reward"
                />
              ) : (
                !e.isDelete && (
                  <div
                    className="show_img"
                    key={i}
                    style={{ backgroundImage: `url(${e.image_uri})` }}
                    alt="upload reward"
                  />
                )
              )
            )
          ) : (
            <div className="box_word">
              <div className="svg_box_word">
                <Icons.AddRound />
              </div>
              <div>
                อัพโหลดรูปภาพ
              </div>
            </div>
          )}
        </div>
        <div className="desc_zone">
          <div >
            {`ไฟล์ : Png  Resolution : ${widthImageSize} x ${heighImageSize}`}
          </div>
          <div >
            {`ขนาดไฟล์สูงสุดไม่เกิน : ${maxMBFileSizeWarning} MB `}
          </div>
        </div>
        <div {...getRootProps({ className: "btn_upload" })}>
          <input {...getInputProps()} disabled={disabled} />
          <Buttons.BtnNormal
            theme_only_border_blue
            label='เพิ่มรูป'
            fontSize="14px"
            disabled={disabled}
          />
        </div>
      </div>
    </UploadImage3Styled>
  );
};

UploadImage3.propTypes = {};

export default UploadImage3;

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnMonthStyled } from './styled';
import { Icons } from 'components';
import moment from 'moment';

const BtnMonth = ({ theme_standard_btn_month, onClick, disBtnNext }) => {
  const customClass = cx({
    theme_standard_btn_month: theme_standard_btn_month,
  });

  const [count, setCount] = useState(0);
  const [showMonth, setShowMonth] = useState();

  useEffect(() => {
    setShowMonth(moment().format('MMM YYYY'));
  }, []);

  const setCountNumber = (num) => {
    if (num === -1) {
      onClick && onClick(count - 1);
      setCount(count - 1);
      setShowMonth(
        moment()
          .add(count - 1, 'M')
          .format('MMM YYYY')
      );
    } else {
      onClick && onClick(count + 1);
      setCount(count + 1);
      setShowMonth(
        moment()
          .add(count + 1, 'M')
          .format('MMM YYYY')
      );
    }
  };

  return (
    <BtnMonthStyled>
      <div className={customClass}>
        <div className="svg_f" onClick={() => setCountNumber(-1)}>
          {<Icons.Arrow3 />}
        </div>
        <div className="label">{showMonth}</div>
        <div className="svg_b" onClick={() => setCountNumber(1)}>
          {!disBtnNext && <Icons.Arrow4 />}
        </div>
      </div>
    </BtnMonthStyled>
  );
};

BtnMonth.propTypes = {};

export default BtnMonth;

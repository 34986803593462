import styled from "styled-components";

export const UserAccountContainerStyled = styled.div`
  padding: 40px;
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* min-height: calc(100vh + 123px); */
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 8px;

  @media (max-width: 1550px) {
    /* min-height: calc(100vh + 250px); */
  }

  @media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    min-height: calc(100vh + 200px);
  }
`;

export default function renderIcon({
  color = "#009078",
  width = "40",
  height = "27.341",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 27.341"
    >
      <g id="XMLID_247_" transform="translate(0 -6)">
        <path
          id="XMLID_871_"
          d="M0,341.526v9.937H40v-9.937Zm10.776,7.588H2.348v-5.24h8.428Zm2.348,0v-5.24H32.407v5.24Zm24.527-5.24v5.24h-2.9v-5.24Z"
          transform="translate(0 -318.121)"
          fill={color}
        />
        <path
          id="XMLID_1405_"
          d="M364.09,51.634c-1.325,0-2.4-.771-2.4-1.718s1.078-1.718,2.4-1.718c2.281,0,4.136-1.326,4.136-2.956V42.533h-2.348v2.709c0,.7-.8,1.278-1.788,1.278-2.62,0-4.752,1.524-4.752,3.4s2.132,3.4,4.752,3.4,4.771,1.53,4.771,3.41v.839h2.348v-.839C371.21,53.917,368.016,51.634,364.09,51.634Z"
          transform="translate(-331.21 -36.533)"
          fill={color}
        />
        <path
          id="XMLID_1406_"
          d="M360.757,261.034h2.348a4.232,4.232,0,0,0-4.227-4.227H357v2.348h1.879A1.881,1.881,0,0,1,360.757,261.034Z"
          transform="translate(-329.055 -240.034)"
          fill={color}
        />
        <path
          id="XMLID_1407_"
          d="M257.725,53.594c0,1.838,2.168,3.334,4.833,3.334v-1.62c-1.37,0-2.485-.769-2.485-1.714a1.5,1.5,0,0,1,.728-1.212,5.93,5.93,0,0,0,2.925-4.766v-.071l0-.042a3.581,3.581,0,0,0-1.619-2.642,6.4,6.4,0,0,0-3.647-1.053v1.62c1.587,0,2.811.9,2.919,2.142v.048a4.518,4.518,0,0,1-2.238,3.619A2.91,2.91,0,0,0,257.725,53.594Z"
          transform="translate(-237.551 -37.807)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default function CopyLink({
  width = "45",
  height = "45",
  color = "#989898",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#D9D9D9" />
      <path
        d="M29.1723 24.6338L32.2546 21.5514C34.7024 19.0418 34.6774 15.0306 32.1985 12.5517C29.7197 10.0728 25.7084 10.0478 23.1988 12.4956L20.1165 15.578"
        stroke={color}
        stroke-width="2.10938"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6271 18.123L19.123 25.627"
        stroke={color}
        stroke-width="2.10938"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5782 19.1162L13.4958 22.1986C11.0481 24.7081 11.0731 28.7194 13.5519 31.1983C16.0308 33.6771 20.0421 33.7021 22.5516 31.2544L25.634 28.172"
        stroke={color}
        stroke-width="2.10938"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default function GroupPeople2() {
  return (
    <svg id="team-leader" xmlns="http://www.w3.org/2000/svg" width="22.361" height="22.361" viewBox="0 0 22.361 22.361">
      <g id="Team_Leader_2_">
        <g id="Group_14492" data-name="Group 14492" transform="translate(1.354 2.62)">
          <g id="Group_14491" data-name="Group 14491">
            <path id="Path_20557" data-name="Path 20557" d="M33.62,65.241a2.62,2.62,0,1,1,2.62-2.62A2.623,2.623,0,0,1,33.62,65.241Z" transform="translate(-31 -60)" fill="#80bfff" />
          </g>
        </g>
        <g id="Group_14494" data-name="Group 14494" transform="translate(15.766 2.62)">
          <g id="Group_14493" data-name="Group 14493">
            <path id="Path_20558" data-name="Path 20558" d="M363.62,65.241a2.62,2.62,0,1,1,2.62-2.62A2.623,2.623,0,0,1,363.62,65.241Z" transform="translate(-361 -60)" fill="#69f" />
          </g>
        </g>
        <g id="Group_14496" data-name="Group 14496" transform="translate(0 7.861)">
          <g id="Group_14495" data-name="Group 14495">
            <path id="Path_20559" data-name="Path 20559" d="M5.285,194.5H2.664a.655.655,0,0,1-.651-.59l-.6-6.049H.655A.655.655,0,0,1,0,187.206v-3.276a3.953,3.953,0,0,1,7.9,0v3.276a.655.655,0,0,1-.655.655H6.532l-.6,6.049a.655.655,0,0,1-.651.59Z" transform="translate(0 -180)" fill="#80bfff" />
          </g>
        </g>
        <g id="Group_14497" data-name="Group 14497" transform="translate(14.456 7.861)">
          <path id="Path_20560" data-name="Path 20560" d="M336.241,194.5h-2.62a.655.655,0,0,1-.651-.59l-.6-6.049h-.718a.655.655,0,0,1-.655-.655v-3.276a3.953,3.953,0,0,1,7.9,0v3.276a.655.655,0,0,1-.655.655h-.762l-.6,6.049A.655.655,0,0,1,336.241,194.5Z" transform="translate(-331 -180)" fill="#69f" />
        </g>
        <g id="Group_14498" data-name="Group 14498" transform="translate(6.595 6.603)">
          <path id="Path_20561" data-name="Path 20561" d="M156.241,151.2l-.655,2.568-.655-2.568A4.588,4.588,0,0,0,151,155.732v3.931a.649.649,0,0,0,.655.655h1.467l.5,6.036a.657.657,0,0,0,.655.6h2.62a.657.657,0,0,0,.655-.6l.5-6.036h1.467c.367,0,.437-.288.437-.655v-3.931A4.4,4.4,0,0,0,156.241,151.2Z" transform="translate(-151 -151.199)" fill="#9ef" />
        </g>
        <g id="Group_14499" data-name="Group 14499" transform="translate(7.905)">
          <path id="Path_20562" data-name="Path 20562" d="M184.276,6.551a3.276,3.276,0,1,1,3.276-3.276A3.279,3.279,0,0,1,184.276,6.551Z" transform="translate(-181)" fill="#9ef" />
        </g>
      </g>
      <path id="Path_20563" data-name="Path 20563" d="M257.31,166.956a.657.657,0,0,0,.655-.6l.5-6.036h1.467a.649.649,0,0,0,.655-.655v-3.931a4.588,4.588,0,0,0-3.931-4.533L256,153.767v13.189Z" transform="translate(-244.82 -144.596)" fill="#80bfff" />
      <path id="Path_20564" data-name="Path 20564" d="M259.276,3.276A3.279,3.279,0,0,0,256,0V6.551A3.279,3.279,0,0,0,259.276,3.276Z" transform="translate(-244.82)" fill="#80bfff" />
      <g id="Group_14500" data-name="Group 14500" transform="translate(10.525 6.551)">
        <path id="Path_20565" data-name="Path 20565" d="M242.31,150.052v4.533a.655.655,0,0,1-1.31,0v-4.533a4.125,4.125,0,0,1,1.31,0Z" transform="translate(-241 -150)" fill="#47568c" />
      </g>
      <path id="Path_20566" data-name="Path 20566" d="M256.655,154.586v-4.533A3.589,3.589,0,0,0,256,150v5.241A.649.649,0,0,0,256.655,154.586Z" transform="translate(-244.82 -143.449)" fill="#29376d" />
    </svg>

  );
}

import React from 'react';
import { DepartmentDetailContainerStyled } from './styled';
import { Tabs, Blocks, Loading, Cards } from 'components';
import { ArticleDetail, TotalView, TotalLike, TotalScore } from 'widgets';
import { ROUTE_PATH } from 'utils/constants';
import { adminService } from 'apiServices';

class DepartmentDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_CONTENT_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <DepartmentDetailContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="บทความ"
                subTitle="Article"
                fontSize="34px"
                fontSizeLabelButtom="18px"
                fontColor="#092945"
                colorBottom="#092945"
                onClick={this.handleBackClick}
                lineHeight="1.2"
              />
            </div>
            <div className="layer_two">
              <ArticleDetail data={data} />
            </div>
            <div className="layer_three">
              <div className="left_three">
                <Blocks.Box theme_standard_box>
                  <div
                    // style={{ height: '395px' }}
                    dangerouslySetInnerHTML={{ __html: data?.body }}
                  ></div>
                </Blocks.Box>
              </div>
              <div className="right_three">
                <div className="t_three">
                  <TotalView
                    total={data?.view_count}
                    maleCount={data?.view_count_male}
                    famaleCount={data?.view_count_female}
                  />
                </div>
                <div className="b_three">
                  <div className="box_three">
                    <TotalLike data={data?.like_count} />
                  </div>
                  <div className="box_three">
                    <TotalScore data={data?.redeemed_coin_amount} />
                  </div>
                </div>
              </div>
            </div>
            {data && data.covers && data.covers.length > 0 && (
              <div className="c_row_3_col">
                {data.covers.map((e, i) => (
                  <div key={i} className="item_wrap">
                    <Cards.BoxImage src={e.media_uri} />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </DepartmentDetailContainerStyled>
    );
  }
}

export default DepartmentDetailContainer;

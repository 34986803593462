import React, { useState, useMemo, forwardRef } from 'react';
import { InputDatePikerCustom2Styled } from './styled';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import th from 'date-fns/locale/th';
import moment from 'moment';
import { Icons } from 'components'
import thaiLocale from "moment/locale/th";

const InputDatePikerCustom2 = ({
  theme_standard_date_picker_custom,
  theme_quarter,
  theme_year,
  onChangeCustom,
  minDate,
  dateFormat,
  showMonthYearPicker,
  showFullMonthYearPicker,
  showQuarterYearPicker,
  showYearPicker,
  maxDate,
  initialValues,
  ext,
}) => {
  const customClass = cx({
    theme_standard_date_picker_custom: theme_standard_date_picker_custom,
    theme_quarter: theme_quarter,
    theme_year: theme_year,
  });

  const [_value, _setValue] = useState();
  const [_onClick] = useState(false);

  useMemo(() => {
    _setValue(initialValues);
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePicker = (e) => {
    _setValue(e);
    onChangeCustom && onChangeCustom(e);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="custom_date" onClick={onClick} ref={ref}>
      {
        showYearPicker
          ?
          <div className='body_show'>
            <div className='svg_front'>
              <Icons.Calendar4 />
            </div>
            <div className='label_date'>
              {`${moment(_value).add(543, 'Y').local(thaiLocale).format('YYYY')}`}
            </div>
            <span className="arrow_icon">{_onClick ? <Icons.ArrowUpDown up /> : <Icons.ArrowUpDown />}</span>
          </div>
          :
          showMonthYearPicker
            ?
            <>
              <div className='body_show'>
                <div className='svg_front'>
                  <Icons.Calendar4 />
                </div>
                <div className='label_date'>
                  {moment(_value).add(543, 'years').local(thaiLocale).format('MMMM YYYY')}
                </div>
                <span className="arrow_icon">{_onClick ? <Icons.ArrowUpDown up /> : <Icons.ArrowUpDown />}</span>
              </div>
            </>
            :
            <>
              <div className='body_show'>
                <div className='svg_front'>
                  <Icons.Calendar4 />
                </div>
                <div className='label_date'>
                  {moment(_value).add(543, 'years').local(thaiLocale).format('DD/MM/YYYY')}
                </div>
                <span className="arrow_icon">{_onClick ? <Icons.ArrowUpDown up /> : <Icons.ArrowUpDown />}</span>
              </div>
            </>
      }
    </button>
  ));

  return (
    <InputDatePikerCustom2Styled>
      <div className={customClass}>
        <DatePicker
          className="date_picker"
          selected={_value}
          onChange={(e) => {
            handleChangePicker(e);
          }}
          locale={th}
          dateFormat={dateFormat ? dateFormat : 'dd/MM/yyyy'}
          showMonthYearPicker={showMonthYearPicker}
          showFullMonthYearPicker={showFullMonthYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          showYearPicker={showYearPicker}
          minDate={minDate}
          maxDate={maxDate}
          customInput={<ExampleCustomInput />}
        />

      </div>
    </InputDatePikerCustom2Styled>
  );
};

InputDatePikerCustom2.propTypes = {};

export default InputDatePikerCustom2;

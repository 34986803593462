export default function New({ width = "32", height = "32" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        id="Group_57054"
        data-name="Group 57054"
        transform="translate(-96 -552)"
      >
        <g
          id="Group_15957"
          data-name="Group 15957"
          transform="translate(-56 216)"
        >
          <circle
            id="Ellipse_1503"
            data-name="Ellipse 1503"
            cx="16"
            cy="16"
            r="16"
            transform="translate(152 336)"
            fill="#aad3ed"
          />
        </g>
        <g id="Flat" transform="translate(77.806 533.805)">
          <path
            id="Path_55961"
            data-name="Path 55961"
            d="M38.765,30.625V40.874H28.219a1.758,1.758,0,0,1-1.758-1.758V34.9H24l1.777-6.12a6.626,6.626,0,0,1,6.018-4.769c.114-.006.23-.009.345-.009a6.625,6.625,0,0,1,6.625,6.625Z"
            transform="translate(0 0)"
            fill="#fec9a3"
          />
          <path
            id="Path_55962"
            data-name="Path 55962"
            d="M205.712,30.625V40.874H198.62a8.823,8.823,0,0,0,2.87-6.523V30.419a8.82,8.82,0,0,0-2.591-6.256q-.078-.078-.158-.154c.114-.006.23-.009.345-.009a6.625,6.625,0,0,1,6.625,6.625Z"
            transform="translate(-166.947 0)"
            fill="#fec093"
          />
          <path
            id="Path_55963"
            data-name="Path 55963"
            d="M143.734,82.111h-.09a1.582,1.582,0,0,0-2.9-1.252,1.582,1.582,0,0,0-2.9,1.252h-.09a1.758,1.758,0,0,0,0,3.515h5.976a1.758,1.758,0,0,0,0-3.515Z"
            transform="translate(-107.078 -53.541)"
            fill="#fec093"
          />
          <path
            id="Path_55964"
            data-name="Path 55964"
            d="M111.734,66.111h-.09a1.582,1.582,0,0,0-2.9-1.252,1.582,1.582,0,0,0-2.9,1.252h-.09a1.758,1.758,0,1,0,0,3.515h5.976a1.758,1.758,0,1,0,0-3.515Z"
            transform="translate(-76.485 -38.244)"
            fill="#f59898"
          />
          <g
            id="Group_55510"
            data-name="Group 55510"
            transform="translate(29.012 25.994)"
          >
            <path
              id="Path_55965"
              data-name="Path 55965"
              d="M139.207,104.845a1.241,1.241,0,0,1-.477-.613.352.352,0,0,0-.663.234,1.948,1.948,0,0,0,.749.963.352.352,0,1,0,.391-.584Z"
              transform="translate(-138.047 -102.476)"
              fill="#e07777"
            />
            <path
              id="Path_55966"
              data-name="Path 55966"
              d="M200.361,71.241l.039,0a.352.352,0,0,0,.311-.388,1.254,1.254,0,0,1-.008-.137,1.216,1.216,0,0,1,.273-.773.352.352,0,1,0-.547-.442,1.936,1.936,0,0,0-.418,1.43A.352.352,0,0,0,200.361,71.241Z"
              transform="translate(-197.277 -69.368)"
              fill="#e07777"
            />
            <path
              id="Path_55967"
              data-name="Path 55967"
              d="M171.481,104.6a.352.352,0,1,0-.5-.5,1.832,1.832,0,0,0-.505.952h-.913a1.582,1.582,0,0,0-.678,3.011.352.352,0,1,0,.3-.635.879.879,0,0,1,.376-1.673h.913a1.832,1.832,0,0,0,.505.952.352.352,0,0,0,.5-.5A1.141,1.141,0,0,1,171.481,104.6Z"
              transform="translate(-166.667 -102.478)"
              fill="#e07777"
            />
          </g>
          <path
            id="Path_55968"
            data-name="Path 55968"
            d="M286.222,96.086a1.973,1.973,0,0,1-1.094-.331l-.558-.372-.558.372a1.972,1.972,0,0,1-2.818-.683A9.489,9.489,0,0,1,280,90.463v-.381a2.082,2.082,0,0,1,3.383-1.626l1.187.95,1.187-.95a2.082,2.082,0,0,1,3.383,1.626v.381a9.489,9.489,0,0,1-1.194,4.608A1.972,1.972,0,0,1,286.222,96.086Z"
            transform="translate(-244.751 -61.188)"
            fill="#7eb457"
          />
          <path
            id="Path_55969"
            data-name="Path 55969"
            d="M304.7,113.027H304A1.029,1.029,0,0,1,305.027,112v.7A.325.325,0,0,0,304.7,113.027Z"
            transform="translate(-267.696 -84.133)"
            fill="#88c25e"
          />
          <path
            id="Path_55970"
            data-name="Path 55970"
            d="M304.7,154.247a8.442,8.442,0,0,1-.519-2.568l.7-.031a7.741,7.741,0,0,0,.476,2.354Z"
            transform="translate(-267.873 -122.04)"
            fill="#88c25e"
          />
          <path
            id="Path_55971"
            data-name="Path 55971"
            d="M346.109,51.515h-.7V50.109A1.408,1.408,0,0,0,344,48.7V48a2.112,2.112,0,0,1,2.109,2.109Z"
            transform="translate(-305.938 -22.945)"
            fill="#92563a"
          />
          <path
            id="Path_55972"
            data-name="Path 55972"
            d="M376,26.109A2.109,2.109,0,0,1,378.109,24h1.266a2.109,2.109,0,0,1-2.109,2.109Z"
            transform="translate(-336.532)"
            fill="#7eb457"
          />
          <path
            id="Path_55973"
            data-name="Path 55973"
            d="M128,368h15.82v2.109H128Z"
            transform="translate(-99.43 -328.884)"
            fill="#e0e0de"
          />
          <rect
            id="Rectangle_19819"
            data-name="Rectangle 19819"
            width="3"
            height="8"
            rx="1.5"
            transform="translate(32 36)"
            fill="#348fd9"
          />
          <rect
            id="Rectangle_19820"
            data-name="Rectangle 19820"
            width="2"
            height="5"
            rx="1"
            transform="translate(30 38)"
            fill="#3086cd"
          />
          <rect
            id="Rectangle_19821"
            data-name="Rectangle 19821"
            width="2"
            height="8"
            rx="1"
            transform="translate(41 44) rotate(180)"
            fill="#348fd9"
          />
          <path
            id="Path_55974"
            data-name="Path 55974"
            d="M210.109,408v2.461a1.055,1.055,0,0,1-2.109,0V408a1.055,1.055,0,0,0,2.109,0Z"
            transform="translate(-175.914 -367.126)"
            fill="#3086cd"
          />
          <path
            id="Path_55975"
            data-name="Path 55975"
            d="M362.109,408v2.461a1.055,1.055,0,0,1-2.109,0V408a1.055,1.055,0,1,0,2.109,0Z"
            transform="translate(-321.235 -367.126)"
            fill="#3086cd"
          />
          <rect
            id="Rectangle_19822"
            data-name="Rectangle 19822"
            width="2"
            height="5"
            rx="1"
            transform="translate(43 43) rotate(180)"
            fill="#3086cd"
          />
          <path
            id="Path_55976"
            data-name="Path 55976"
            d="M162.109,392v1.758a1.055,1.055,0,1,1-2.109,0V392a1.055,1.055,0,0,0,2.109,0Z"
            transform="translate(-130.024 -351.829)"
            fill="#2d7dbf"
          />
          <path
            id="Path_55977"
            data-name="Path 55977"
            d="M410.109,392v1.758a1.055,1.055,0,1,1-2.109,0V392a1.055,1.055,0,1,0,2.109,0Z"
            transform="translate(-367.126 -351.829)"
            fill="#2d7dbf"
          />
          <path
            id="Path_55978"
            data-name="Path 55978"
            d="M256,368h.7v2.109H256Z"
            transform="translate(-221.805 -328.884)"
            fill="#cfcfcd"
          />
          <path
            id="Path_55979"
            data-name="Path 55979"
            d="M456,368h.7v2.109H456Z"
            transform="translate(-413.017 -328.884)"
            fill="#cfcfcd"
          />
        </g>
      </g>
    </svg>
  );
}

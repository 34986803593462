import { NewUserStyled } from './styled';
import { Blocks, Icons } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const NewUser = ({ data }) => {
  return (
    <NewUserStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="12px"
        paddingBottom="0px"
        paddingLeft="16px"
        height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelCenter
            theme_standard_box_4_col
            label1="ผู้ใช้ใหม่สัปดาห์นี้"
            label2="New User"
            label3={
              data && data.new_user_per_week > 0
                ? `${RenderCommaMoney(data.new_user_per_week, true)}`
                : '0'
            }
            svg1={data?.new_user_per_week ? <Icons.ArrowUpBig /> : <></>}
            labelBack="คน"
          />
        </div>
      </Blocks.Box>
    </NewUserStyled>
  );
};

export default NewUser;

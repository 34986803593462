import { OverallburnoutStyled } from "./styled";
import { Blocks, Typographys, Icons, Buttons, Skeleton } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { RENDER_MONTH } from "utils/functions/date";
import momentTZ from "moment-timezone";
import moment from "moment";
import theme from "styles/theme.json";

const Overallburnout = ({ data, fetchData }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_data, _setData] = useState();
  const [_filter, _setFilter] = useState("weekly");

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const handleClickFilter = async (value) => {
    const date = renderFilterDay(value).initialDate;
    _setLoadSkeleton(true);
    let res = await fetchData(`?period=${value}&stat_date=${date}`, date);
    if (res) {
      _setFilter(value);
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setFilter(value);
      _setLoadSkeleton(false);
      _setData();
    }
  };

  const renderFilterDay = (filterType) => {
    switch (filterType) {
      case "weekly":
        return {
          number: 7,
          type: "days",
          startOf: "isoWeek",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      case "quarterly":
        return {
          number: 1,
          type: "quarter",
          startOf: "quarter",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
        };
      case "halfyearly":
        return {
          number: 6,
          type: "months",
          startOf: "month",
          initialDate:
            Number(momentTZ().tz("Asia/Bangkok").format("MM")) > 6
              ? `${moment().format("YYYY")}-07-01`
              : `${moment().format("YYYY")}-01-01`,
        };
      default:
        break;
    }
  };

  const renderDateShow = (key) => {
    let dateStart;
    let dateEnd;
    let valueDate;

    switch (key) {
      case "weekly":
        valueDate =
          moment(_data.stat_date).startOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(
            moment(_data.stat_date)
              .startOf("isoWeek")

              .format("MM")
          ).thShort +
          ` ` +
          moment(_data.stat_date).startOf("isoWeek").format("YYYY") +
          ` - ` +
          moment(_data.stat_date).endOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(moment(_data.stat_date).endOf("isoWeek").format("MM"))
            .thShort +
          ` ` +
          moment(_data.stat_date).endOf("isoWeek").format("YYYY");
        return valueDate;
      case "quarterly":
        dateStart = moment(_data.stat_date).startOf("month");
        dateEnd = moment(_data.stat_date).endOf("month").add(2, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      case "halfyearly":
        dateStart = moment(_data.stat_date).startOf("month");
        dateEnd = moment(_data.stat_date).endOf("month").add(5, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      default:
        return "";
    }
  };

  return (
    <OverallburnoutStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="24px"
        paddingLeft="24px"
      >
        <div className="top_men">
          <div className="left_men_layout">
            <div className="show_icon">
              <Icons.Fire color="#0F8989" width="24px" height="27px" />
            </div>
            <Typographys.TopBottom
              theme_left_align
              label="ภาพรวมอาการหมดไฟในการทำงานของพนักงานในบริษัท"
              labelBottom="Overall burnout syndrome condition"
            />
          </div>
          <div className="right_men_layout">
            <div className="label_layout">
              <div className="date_show">
                <div className="show_icon">
                  <Icons.CalendarIcon /> ข้อมูลวันที่
                  {/* วันที่รายสัปดาห์ล่าสุด */}
                </div>
                <div className="show_label">
                  {/* วันที่รายสัปดาห์ล่าสุด */}
                  {_data && renderDateShow(_filter)}
                </div>
              </div>
              {/* <div className="date_layout">
                {_data && renderDateShow(_filter)}
              </div> */}
            </div>
            <div className="show_search">
              <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                labeDisplay
                onChange={handleClickFilter}
              />
            </div> 
          </div>
        </div>
        {_data ? (
          <>
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="body_graph">
                    <div className="group_graph">
                      <div className="graph_show">
                        <Chart
                          type="radialBar"
                          width="220px"
                          height="220px"
                          options={{
                            chart: {
                              id: "apexchart-example",
                              fontFamily: theme.FONT.STYLE.REGULAR,
                              toolbar: {
                                show: false,
                              },
                            },
                            // dataLabels: {
                            //   formatter: function (val, opts) {
                            //     return `${Number(val).toFixed(0)}%`
                            //   },
                            // },

                            // tooltip: {
                            //   y: {
                            //     formatter: function (
                            //       value,
                            //       { series, seriesIndex, dataPointIndex, w }
                            //     ) {
                            //       return `${value}%`;
                            //     },
                            //   },
                            // },
                            legend: {
                              show: false,
                            },
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  size: "40%",
                                },
                                dataLabels: {
                                  name: {
                                    show: false,
                                    color: "#fff",
                                  },
                                  value: {
                                    show: true,
                                    color: "#4D5C7D",
                                    offsetY: 10,
                                    fontSize: "23px",
                                    fontFamily:
                                      "noto_sans_thai_medium, noto_sans_regular",
                                  },
                                },
                                track: {
                                  background: "#D0E8CC",
                                  strokeWidth: "100%",
                                },
                              },
                            },
                            colors: ["#8AC47F"],
                            // labels: ['ข้อเท้า เท้า'],
                          }}
                          series={
                            _data &&
                            _data.nosymptom_user_count &&
                            _data.nosymptom_user_count > 0
                              ? [
                                  (
                                    (_data.nosymptom_user_count /
                                      _data.bos_user_count) *
                                    100
                                  ).toFixed(0),
                                ]
                              : [0]
                          }
                          // series={[
                          //   (
                          //     (_data.nosymptom_user_count /
                          //       _data.bos_user_count) *
                          //     100
                          //   ).toFixed(0),
                          // ]}
                        />
                      </div>
                      <div className="label_graph">
                        <Blocks.BoxWellbeing
                          theme_standard_box_medium
                          label="ไม่มีอาการ"
                          labelBottom="No symptoms"
                          bgColor="#8AC47F"
                          showRound
                        />
                      </div>
                    </div>
                    <div className="group_graph">
                      <div className="graph_show">
                        <Chart
                          type="radialBar"
                          width="220"
                          height="220"
                          options={{
                            chart: {
                              id: "apexchart-example",
                              fontFamily: theme.FONT.STYLE.REGULAR,
                              toolbar: {
                                show: false,
                              },
                            },
                            legend: {
                              show: false,
                            },
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  size: "40%",
                                },
                                dataLabels: {
                                  name: {
                                    show: false,
                                    color: "#fff",
                                  },
                                  value: {
                                    show: true,
                                    color: "#4D5C7D",
                                    offsetY: 10,
                                    fontSize: "23px",
                                    fontFamily:
                                      "noto_sans_thai_medium, noto_sans_regular",
                                  },
                                },
                                track: {
                                  background: "#C4E8E3",
                                  strokeWidth: "100%",
                                },
                              },
                            },
                            colors: ["#6FB5CB"],
                          }}
                          series={
                            _data &&
                            _data.exhausted_user_count &&
                            _data.exhausted_user_count > 0
                              ? [
                                  (
                                    (_data.exhausted_user_count /
                                      _data.bos_user_count) *
                                    100
                                  ).toFixed(0),
                                ]
                              : [0]
                          }
                        />
                      </div>
                      <div className="label_graph">
                        <Blocks.BoxWellbeing
                          theme_standard_box_medium
                          label="เริ่มรู้สึกหมดไฟ"
                          labelBottom="Starting to feel exhausted"
                          bgColor="#6FB5CB"
                          showRound
                        />
                      </div>
                    </div>
                    <div className="group_graph">
                      <div className="graph_show">
                        <Chart
                          type="radialBar"
                          width="220"
                          height="220"
                          options={{
                            chart: {
                              id: "apexchart-example",
                              fontFamily: theme.FONT.STYLE.REGULAR,
                              toolbar: {
                                show: false,
                              },
                            },
                            legend: {
                              show: false,
                            },
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  size: "40%",
                                },
                                dataLabels: {
                                  name: {
                                    show: false,
                                    color: "#fff",
                                  },
                                  value: {
                                    show: true,
                                    color: "#4D5C7D",
                                    offsetY: 10,
                                    fontSize: "23px",
                                    fontFamily:
                                      "noto_sans_thai_medium, noto_sans_regular",
                                  },
                                },
                                track: {
                                  background: "#C2B8D5",
                                  strokeWidth: "100%",
                                },
                              },
                            },
                            colors: ["#654C96"],
                          }}
                          series={
                            _data &&
                            _data.burnout_user_count &&
                            _data.burnout_user_count > 0
                              ? [
                                  (
                                    (_data.burnout_user_count /
                                      _data.bos_user_count) *
                                    100
                                  ).toFixed(0),
                                ]
                              : [0]
                          }
                        />
                      </div>
                      <div className="label_graph">
                        <Blocks.BoxWellbeing
                          theme_standard_box_medium
                          label="หมดไฟในการทำงาน"
                          labelBottom="Burnout syndrome"
                          bgColor="#654C96"
                          showRound
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </OverallburnoutStyled>
  );
};

export default Overallburnout;

export default function ActivenessRunning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="63.754"
      viewBox="0 0 128 63.754"
    >
      <g
        id="Group_14855"
        data-name="Group 14855"
        transform="translate(2349.09 2983.234)"
      >
        <path
          id="Path_35147"
          data-name="Path 35147"
          d="M64.16,262c33.75,0,61.11,1.024,61.11,2.287s-27.36,2.284-61.11,2.284S3.05,265.54,3.05,264.289,30.4,262,64.16,262Z"
          transform="translate(-2346.359 -3186.051)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35148"
          data-name="Path 35148"
          d="M956.378,57.564s-1.2-.446-4.807.76a.257.257,0,0,1,0,.068c-.472.337-.554.236-.708.116s.17-.34.472-.361a.236.236,0,0,1,.182.042c3.665-1.227,4.913-.765,4.918-.762l-.05.137Z"
          transform="translate(-3203.362 -3029.688)"
          fill="#bababa"
        />
        <path
          id="Path_35149"
          data-name="Path 35149"
          d="M928.011,53.54l.88,1.945s-.559.26-.748.156a2.255,2.255,0,0,1-.394-1.652c0-.113.262-.437.262-.437Z"
          transform="translate(-3185.7 -3026.785)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35150"
          data-name="Path 35150"
          d="M934.124,55.614l-.666-1.225a1.439,1.439,0,0,0-.073-.944c-.224-.335-1.008-.531-1.225-.779s-.548-1.041-.708-1.116-.3.106-.3.106.3.781.2,1.038.236.087-.172.85.472,1.673.892,1.737.526.236.526.236l.708,1.6.817-1.51Z"
          transform="translate(-3188.273 -3025.251)"
          fill="#e8a597"
          fillRule="evenodd"
        />
        <path
          id="Path_35151"
          data-name="Path 35151"
          d="M936.85,66.191a.382.382,0,0,1,.163.135h0l.281.632a4.539,4.539,0,0,0,.637-1.18c.061-.29-.158.061-.3.2a1.79,1.79,0,0,1-.783.219Z"
          transform="translate(-3192.686 -3036.05)"
          fill="#d68f80"
          fillRule="evenodd"
        />
        <path
          id="Path_35152"
          data-name="Path 35152"
          d="M925.427,44.35l2.247,4.916-.378.142-2.256-4.866.387-.191Z"
          transform="translate(-3183.664 -3019.763)"
          fill="#f0f0f0"
          fillRule="evenodd"
        />
        <path
          id="Path_35153"
          data-name="Path 35153"
          d="M926.68,44.35l.356.1,2.006,4.524-.116.288L926.68,44.35Z"
          transform="translate(-3184.917 -3019.763)"
          fill="#4a4485"
          fillRule="evenodd"
        />
        <path
          id="Path_35154"
          data-name="Path 35154"
          d="M928.69,56.972c.51.061.708.222.691.413s-.172.373-.318.337-.724-.125-.774-.038c-.3.526-.868-.871.4-.708Z"
          transform="translate(-3185.847 -3029.401)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35155"
          data-name="Path 35155"
          d="M927.336,53.707a.685.685,0,0,1,.833-.05c.094.071.42.389.177.552s-.708-.2-.849.116C927.185,54.979,927.052,54,927.336,53.707Z"
          transform="translate(-3185.298 -3026.785)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35156"
          data-name="Path 35156"
          d="M929.091,60.464c.729-.708,2.44.9,1.2.736-.448-.059-.895-.328-.729-.125s.963.786.944.906C930.448,62.246,928.251,61.3,929.091,60.464Z"
          transform="translate(-3186.613 -3031.937)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35157"
          data-name="Path 35157"
          d="M989.082,31.943a1.888,1.888,0,0,1,.814-.168c.262-.071.762-.066,1.279.654.666.927,1.23.918,1.23.918v.2s-.668,0-1.395-1a1.86,1.86,0,0,0-.37-.4c.394.493.632,1.4,1.39,1.614.895.25.456,2.924-1.472,2.832s-2.376-3.186-2.376-3.186.944.078.9-1.477Z"
          transform="translate(-3231.903 -3010.135)"
          fill="#c96a16"
          fillRule="evenodd"
        />
        <path
          id="Path_35158"
          data-name="Path 35158"
          d="M988.853,31.89s.177.411-.262,1.18a2.143,2.143,0,0,1-.861.944c-.16-.045-.354-.236-.3-.651.1-.691.644-1.871,1.416-1.477Z"
          transform="translate(-3231.325 -3010.18)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35159"
          data-name="Path 35159"
          d="M965.44,120.428a62.239,62.239,0,0,0,5.373,9.5c3.323,4.779,5.39,6.348,6.57,7.821a44.3,44.3,0,0,1,4.987,6.785c1.682,3.042,3.334,5.548,3.334,5.548l1.786-2.962a27.036,27.036,0,0,1-2.8-4.861c-1.65-3.445-.911-2.339-3.976-6.416a47.1,47.1,0,0,1-4.956-8.472c-.609-1.119-2.36-7.068-2.36-7.068l-1.045-2.3-6.917,2.435Z"
          transform="translate(-3214.53 -3076.033)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35160"
          data-name="Path 35160"
          d="M949.384,119.367a5.836,5.836,0,0,1,2.99,4.779c.1,3.311-12.13,10.329-12.333,10.5s.54,1.4,1.18,5.347.774,7.386,1.347,8.923-.167,1.652-.167,1.652l-3.54.149a8.411,8.411,0,0,0,0-5.581c-1.01-3.408-3.91-10.129-3.438-11.8.446-1.579,5.307-6.478,8.156-9.439l-1.114-2.433a24.8,24.8,0,0,1,4.462-.588,15.412,15.412,0,0,1,2.454-1.513Z"
          transform="translate(-3191.557 -3077.072)"
          fill="#4a4485"
          fillRule="evenodd"
        />
        <path
          id="Path_35161"
          data-name="Path 35161"
          d="M972.357,119.382a6.136,6.136,0,0,1,2.461,2.6,68.612,68.612,0,0,0-9.333-.4l-.045-.1a24.8,24.8,0,0,1,4.463-.588,15.4,15.4,0,0,1,2.454-1.513Z"
          transform="translate(-3214.53 -3077.087)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35162"
          data-name="Path 35162"
          d="M944.589,70.772A16.3,16.3,0,0,1,938.8,66a4.064,4.064,0,0,0-1.888,1.855,26.535,26.535,0,0,0,4.63,4.989c2.136,1.51,2.18,2.223,2.18,2.223l.878-4.288Z"
          transform="translate(-3192.732 -3036.304)"
          fill="#de988a"
          fillRule="evenodd"
        />
        <path
          id="Path_35163"
          data-name="Path 35163"
          d="M957.99,78.58a19.773,19.773,0,0,1-3.54-2.36,7.7,7.7,0,0,0,.278,1.836,12.885,12.885,0,0,1,.432,2.761c1.909,1.4,1.951,2.058,1.951,2.058l.878-4.288Z"
          transform="translate(-3206.133 -3044.113)"
          fill="#cf887a"
          fillRule="evenodd"
        />
        <path
          id="Path_35164"
          data-name="Path 35164"
          d="M965.458,14.711s-.061-.75,1.151-1.218a3.269,3.269,0,0,1,3.922.741c1.68,1.364,1.475,2.511,1.317,2.929a2.445,2.445,0,0,1-.472,1.694c-1.2,1.652-2.516.857-4.413-.033-.533-.253.054-2.6-1.506-4.113Zm5.55.236a6.29,6.29,0,0,1,.743,1.652A2.473,2.473,0,0,0,971.009,14.947Z"
          transform="translate(-3214.543 -2995.937)"
          fill="#c96a16"
          fillRule="evenodd"
        />
        <path
          id="Path_35165"
          data-name="Path 35165"
          d="M967.762,37.919s.691.132,1.029,1.369c.071.267.123.493.158.687a6.6,6.6,0,0,0-.748.871,9.336,9.336,0,0,0-.441.84l1.753-.989,2.9-1.274a36.5,36.5,0,0,1-2.218-3.672A6.876,6.876,0,0,1,967.762,37.919Z"
          transform="translate(-3216.302 -3013.193)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35166"
          data-name="Path 35166"
          d="M968,37.841a.9.9,0,0,1,.165.08,1.3,1.3,0,0,1,.415.392v.017h0l.026.038h0v.019h0v.019h0v.012h0v.012l.012.021h0v.035h0l.016.035v.026h0V38.6l.035.094V38.7a.355.355,0,0,0,.031.087v.184a4.027,4.027,0,0,0,1.926-2.506,8.337,8.337,0,0,1-.432-.809V35.63a11.908,11.908,0,0,1-1.888,1.678h0l-.033.021a3.391,3.391,0,0,1-.274.512Z"
          transform="translate(-3216.485 -3013.102)"
          fill="#e8a597"
          fillRule="evenodd"
        />
        <path
          id="Path_35167"
          data-name="Path 35167"
          d="M967.848,59s.3.219.094.455l-.111-.1c.1-.116-.068-.236-.071-.236l.087-.118Z"
          transform="translate(-3216.302 -3030.957)"
          fill="#ccc"
        />
        <path
          id="Path_35168"
          data-name="Path 35168"
          d="M959.609,19.689a2.028,2.028,0,0,0-.354.389c-.557.826-.219,1.487-.515,2.247s-1.1.986-.908,1.22a1.5,1.5,0,0,0,.581.368c.363,2.176.621,2.6,3.068,1.692,1.336-.493,1.088-1.39,1.088-1.39a1.2,1.2,0,0,0,.758-.868c.134-.67-.142-.9-.488-.916-.944-.035-.944,1.038-1.338,1.18-.177.068-.094-1.027-.153-1.385s.017-.861-.606-1.154c-.2-.094-.09-.861-1.119-1.392Z"
          transform="translate(-3208.694 -3000.916)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35169"
          data-name="Path 35169"
          d="M964.422,29.4a.3.3,0,0,1,.373.179.29.29,0,0,1-.182.368.3.3,0,0,1-.373-.179.29.29,0,0,1,.182-.368Z"
          transform="translate(-3213.6 -3008.329)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35170"
          data-name="Path 35170"
          d="M962.51,27.331a1.045,1.045,0,0,1,1.222-.2l-.122.224a.781.781,0,0,0-.913.146Z"
          transform="translate(-3212.291 -3006.523)"
          fill="#c96a16"
          fillRule="evenodd"
        />
        <path
          id="Path_35171"
          data-name="Path 35171"
          d="M961.258,51.724s-2.235,2.516-3.068,3.667-.338,2.69,0,4.429.217,5.081.408,6.785.436,3.467.436,3.467c1.154-.264,8.968-1.083,9.223-1.857,0,0-1.18-3.714-1.022-5.664a43.8,43.8,0,0,1,.741-4.979c.4-2.289.439-3.068-.354-4.078a12.291,12.291,0,0,0-2.863-2.164l-3.023,1.319.023.1-1.865.944a9.7,9.7,0,0,1,1.366-1.9v-.071Z"
          transform="translate(-3208.641 -3025.097)"
          fill="#b36d5f"
          fillRule="evenodd"
        />
        <path
          id="Path_35172"
          data-name="Path 35172"
          d="M979.675,108.2c-.156-.649-.314-1.39-.415-2.1l-5.9,1.97s.118,1.506,1.331,1.18,1.152-.783,3.1-.779a8.212,8.212,0,0,0,1.888-.262Z"
          transform="translate(-3220.581 -3066.941)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35173"
          data-name="Path 35173"
          d="M990.434,68.933c.116-.708.264-1.48.472-2.634a11.977,11.977,0,0,0,.276-2.457l-2.773-1.083s-1.567,1.527.134,4.1a7.89,7.89,0,0,0,1.888,2.074Z"
          transform="translate(-3231.575 -3033.829)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35174"
          data-name="Path 35174"
          d="M958.39,80.361c.087.458.2.944.293,1.416a25.281,25.281,0,0,1,.267,3.731,13.574,13.574,0,0,0,1.331-.359c1.784-.566,2.089-1.18,2.089-1.371s1.666-.236,3.469-.5.684-1.135-.684-1.336-4.3-2.223-5.329-1.805a3.539,3.539,0,0,1-1.435.212Z"
          transform="translate(-3209.143 -3047.062)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35175"
          data-name="Path 35175"
          d="M965.924,68.79s3.08,3.9,5.178,3.981v.2c-2.192-.087-5.321-4.045-5.331-4.057l.16-.125Zm-2.7,13.265a23.222,23.222,0,0,0,8.215-1.416l.073.186a23.422,23.422,0,0,1-8.3,1.437v-.2Zm.609-6.844s2.692.847,4.5-.118l.1.179c-1.888,1.005-4.644.135-4.651.132Zm6.485-.67c0,.014-2.714,4.72-7.278,5.119l-.038-.2c4.46-.4,7.113-5.005,7.12-5.019l.177.1ZM963.6,81.295s4.123.524,6.955-2.15l.142.146c-2.9,2.735-7.11,2.206-7.122,2.2l.023-.2Zm2.6-9.364s1.074,1.805,4.024,1.371l.029.2c-3.084.453-4.224-1.463-4.227-1.468l.174-.1Z"
          transform="translate(-3212.665 -3038.436)"
          fill="#e8a597"
          fillRule="evenodd"
        />
        <path
          id="Path_35176"
          data-name="Path 35176"
          d="M971.79,101.462a7.236,7.236,0,0,1-1.6.276,6.785,6.785,0,0,0-2.183.51c-.337.175-.656.845-.472,1.086s.023.434.177.644.045.352.236.569.182.309.427.472a2.789,2.789,0,0,0,1.919-.283c.757-.359.826-.986,1.163-1.078a9.062,9.062,0,0,0,.885-.326l-.548-1.871Z"
          transform="translate(-3216.089 -3063.396)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35177"
          data-name="Path 35177"
          d="M971.376,51.122c-.215-.163-.842-.441-2.539-.3-2.735.236-3.226.189-5.517,1.458-.972.538,1.3-1.815,2-2.674a1.95,1.95,0,0,1,.08.335,12.878,12.878,0,0,0-1.114,1.482,1.951,1.951,0,0,1,.663-.566c.309-.186,1.06-.585,1.6-.866l2.294-.68-.378-.618a9.919,9.919,0,0,1,2.789.618c1.019.321,1.085.793.892,1.18a2.064,2.064,0,0,1,.708.661c.3.472-.066,1.031-.352,1.352-.139-.153-.271-.29-.4-.413a3.119,3.119,0,0,0-.4-.623A3.95,3.95,0,0,0,971.376,51.122Z"
          transform="translate(-3212.728 -3023.079)"
          fill="#dedede"
          fillRule="evenodd"
        />
        <path
          id="Path_35178"
          data-name="Path 35178"
          d="M978.24,49.912l2.178-.644-.378-.618a8.024,8.024,0,0,1,2.093.408c.34.175.609.347.609.453,0,.29-.38.281-.6.281s-1.02-.12-2.013-.109a12.407,12.407,0,0,0-1.888.236Z"
          transform="translate(-3224.309 -3023.049)"
          fill="#748493"
          fillRule="evenodd"
        />
        <path
          id="Path_35179"
          data-name="Path 35179"
          d="M951.443,240.774c-.019.5-.033.8-.033.8s.08.156,1.7.215,1.812-.236,1.812-.236a4.19,4.19,0,0,0-.3-.972,8.042,8.042,0,0,1-3.181.191Z"
          transform="translate(-3203.811 -3169.686)"
          fill="#b6beed"
          fillRule="evenodd"
        />
        <path
          id="Path_35180"
          data-name="Path 35180"
          d="M1044.06,237.556l.486.64s.163.068,1.454-.909,1.246-1.324,1.246-1.324l-.37-.623A8.688,8.688,0,0,1,1044.06,237.556Z"
          transform="translate(-3274.596 -3165.682)"
          fill="#b6beed"
          fillRule="evenodd"
        />
        <path
          id="Path_35181"
          data-name="Path 35181"
          d="M963.873,57.989s-1.215.4-3.268,3.58a.24.24,0,0,1,.042.057c-.153.555-.29.522-.472.524s-.08-.37.132-.569a.236.236,0,0,1,.167-.078c2.082-3.235,3.349-3.651,3.351-3.653l.049.139Z"
          transform="translate(-3210.435 -3030.078)"
          fill="#ccc"
        />
        <path
          id="Path_35182"
          data-name="Path 35182"
          d="M975.349,57.34s.345.149.194.425l-.13-.071c.076-.135-.12-.219-.123-.219Z"
          transform="translate(-3222.054 -3029.688)"
          fill="#ccc"
        />
        <path
          id="Path_35183"
          data-name="Path 35183"
          d="M981.163,58.487s1.055-.118,2.924,2.289,4.078,4.8,4.432,6.192c.67,2.643-8.672,4.382-10.305,4.72a4.648,4.648,0,0,1-.807-2.907s6.782-1.357,7.339-2.183-3.1-2.079-4.229-3.9S979.77,59.079,981.163,58.487Z"
          transform="translate(-3223.661 -3030.564)"
          fill="#e8a597"
          fillRule="evenodd"
        />
        <path
          id="Path_35184"
          data-name="Path 35184"
          d="M977.359,76.538a1.311,1.311,0,0,1,.014-.236s6.782-1.357,7.339-2.183-3.1-2.079-4.229-3.9a4.119,4.119,0,0,1-.654-1.888,6.662,6.662,0,0,0,2.322,2.6,11.056,11.056,0,0,1,2.747,2.2,3.56,3.56,0,0,1,1.439.342c.925.38-.835.068-.708.493s1.482,1.18,1.088,1.388-1.517-.776-1.871-.727-3.292,1.567-4.741,1.753a26.813,26.813,0,0,1-2.756.182Z"
          transform="translate(-3223.629 -3038.085)"
          fill="#cf887a"
          fillRule="evenodd"
        />
        <path
          id="Path_35185"
          data-name="Path 35185"
          d="M976.15,32.959a.338.338,0,0,0-.236-.4c-.3-.085-.536.179-.661.458s-.33.319-.2.415.472-.04.5.179.349.163.293-.111-.312-.236-.323-.323.187-.286.281-.16.345.156.337-.057Z"
          transform="translate(-3221.841 -3010.742)"
          fill="#e69685"
          fillRule="evenodd"
        />
        <path
          id="Path_35186"
          data-name="Path 35186"
          d="M961.27,39.107a4.081,4.081,0,0,1,1-.217.783.783,0,0,1-.5.708c-.34.057-.505-.472-.505-.472Z"
          transform="translate(-3211.344 -3015.582)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35187"
          data-name="Path 35187"
          d="M964.9,30.723a.1.1,0,0,1,.123.059.094.094,0,0,1-.059.123.1.1,0,0,1-.063-.182Z"
          transform="translate(-3214.073 -3009.348)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35188"
          data-name="Path 35188"
          d="M969.7,19.8a2.271,2.271,0,0,1,2.379.408l-.144.142a2.055,2.055,0,0,0-2.155-.363l-.08-.186Zm1.652,1.888a2.383,2.383,0,0,0,1.555-.368l.106.172a2.6,2.6,0,0,1-1.7.392l.035-.2ZM969.3,18.279s2.438-.687,3.752,2.31l-.186.08c-1.244-2.832-3.507-2.2-3.514-2.2Zm-1.057-.913s3.667-2.487,5.241,2.509l-.194.059c-1.491-4.72-4.932-2.4-4.942-2.4l-.113-.168Z"
          transform="translate(-3216.669 -2998.674)"
          fill="#f39c12"
        />
        <path
          id="Path_35189"
          data-name="Path 35189"
          d="M992.569,37.08a3.565,3.565,0,0,1,1.364,1.416c.51.92.661.92.661.92v.2s-.271,0-.84-1.027a3.359,3.359,0,0,0-1.277-1.322l.092-.179Zm-.536.746s.739,2.341,2.048,2.048l.045.2c-1.494.333-2.284-2.181-2.286-2.188Z"
          transform="translate(-3234.699 -3014.209)"
          fill="#f39c12"
        />
        <path
          id="Path_35190"
          data-name="Path 35190"
          d="M986.119,55.006s-1.336-.651-4.307-.017l-.043-.2c3.04-.649,4.434.031,4.439.033Z"
          transform="translate(-3227.006 -3027.527)"
          fill="#bababa"
        />
        <path
          id="Path_35191"
          data-name="Path 35191"
          d="M987.543,71.046a2.671,2.671,0,0,1,2.4.831l-.146.139a2.453,2.453,0,0,0-2.211-.774Zm-6.789,2.381a6.391,6.391,0,0,0,.675,2.306l-.16.123a6.4,6.4,0,0,1-.708-2.4l.2-.026Zm1.107.75s4,.109,6.107-1.253l.111.168c-2.166,1.4-6.211,1.286-6.223,1.286v-.2Zm1.872-9.687a7.639,7.639,0,0,1,3.66,4.72l-.2.05a7.42,7.42,0,0,0-3.554-4.59Zm3.658,6.754s1.737.59,1.956,1.956l-.2.033c-.2-1.246-1.822-1.8-1.826-1.8Zm-.1-.536a2.78,2.78,0,0,1,2.624-.1l-.071.189a2.576,2.576,0,0,0-2.431.068Z"
          transform="translate(-3226.082 -3035.151)"
          fill="#b36d5f"
          fillRule="evenodd"
        />
        <path
          id="Path_35192"
          data-name="Path 35192"
          d="M938.63,70.291A3.176,3.176,0,0,1,940.3,68.8l.059.193a2.949,2.949,0,0,0-1.546,1.39Zm1.9-.783a12.979,12.979,0,0,0,2.921,4.72l-.144.142a13.216,13.216,0,0,1-2.971-4.8l.193-.061Z"
          transform="translate(-3194.047 -3038.444)"
          fill="#b36d5f"
          fillRule="evenodd"
        />
        <path
          id="Path_35193"
          data-name="Path 35193"
          d="M944.234,180.78a4.687,4.687,0,0,1,2.152.63l-.141.144a4.392,4.392,0,0,0-2.025-.573Zm1.782,3.759c0,.017-.736,5.27,1.213,8.024l-.165.116c-2-2.815-1.251-8.144-1.249-8.16l.2.026Z"
          transform="translate(-3198.317 -3123.998)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35194"
          data-name="Path 35194"
          d="M971.075,130.855a19.387,19.387,0,0,1,2.36-2.065l.106.172a19.068,19.068,0,0,0-2.325,2.032l-.146-.139Z"
          transform="translate(-3218.831 -3084.277)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35195"
          data-name="Path 35195"
          d="M966.8,138.024c0,.021-3.705,6.888-8.453,7.936l-.043-.2c4.656-1.024,8.307-7.814,8.316-7.832Z"
          transform="translate(-3209.074 -3091.26)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35196"
          data-name="Path 35196"
          d="M1003.883,161.18a13.305,13.305,0,0,0,3.908,7.523l-.134.151a13.506,13.506,0,0,1-3.976-7.651Zm6.567,10.136c0,.012.854,4.248,2.473,6.19l-.158.127c-1.652-1.992-2.511-6.265-2.513-6.277l.2-.04Z"
          transform="translate(-3243.745 -3109.023)"
          fill="#4a4485"
          fillRule="evenodd"
        />
        <path
          id="Path_35197"
          data-name="Path 35197"
          d="M975.212,34.74s-.625.5-.545.7.708-.394.88-.691c.158-.257.2-.854-.269-.708a.37.37,0,0,0-.066.708Z"
          transform="translate(-3221.574 -3011.867)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35198"
          data-name="Path 35198"
          d="M966.52,239.854s.569-.906.831-.852.314.156.286.205-.767.455-.9.595S966.52,239.854,966.52,239.854Z"
          transform="translate(-3215.355 -3168.479)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35199"
          data-name="Path 35199"
          d="M933.428,237.453s-.309.993-.047,1.416a1.131,1.131,0,0,0,1.348.472c.545-.193.807-.748,1.3-.9s.871-.212.944-.106a9.209,9.209,0,0,1,0,1.47,2.282,2.282,0,0,0,.153.781,41.83,41.83,0,0,1-5.744,1.331,8.755,8.755,0,0,1-3.95-.5c.3-.538,1.822-1.026,2.818-1.451a4.832,4.832,0,0,0,2.36-1.914c.236-.479.552-.842.814-.59ZM927.637,242l-.017-.014Zm-.059-.047-.016-.017Zm-.052-.05-.014-.014Zm-.04-.043-.014-.017Zm-.035-.045-.014-.024Zm-.031-.047v0Zm-.021-.047v0Zm-.014-.042a.132.132,0,0,1,0-.026A.132.132,0,0,0,927.385,241.675Zm0-.042a.122.122,0,0,1,0-.028v-.021a.143.143,0,0,1,0-.031.143.143,0,0,0,0,.031Zm0-.092a.081.081,0,0,1,0-.033A.081.081,0,0,0,927.385,241.54Zm0-.04v0Zm0-.035a.586.586,0,0,1,.026-.057.59.59,0,0,0-.026.057Z"
          transform="translate(-3185.453 -3167.241)"
          fill="#474646"
          fillRule="evenodd"
        />
        <path
          id="Path_35200"
          data-name="Path 35200"
          d="M938.379,242.253a1.143,1.143,0,0,0,.279.208c-.753.519-2.6,1.69-3.693,1.541a4.337,4.337,0,0,1-1.234-.312c.46-.191.944-.375,1.354-.547a5.429,5.429,0,0,0,2.162-1.593c.061.08.135.175.222.278A1.274,1.274,0,0,0,938.379,242.253Z"
          transform="translate(-3190.303 -3170.427)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35201"
          data-name="Path 35201"
          d="M940.01,245.17l-.106.064.031.057.106-.064Zm-.37.222-.106.061.028.059.106-.064-.028-.057Zm-.375.217-.106.059.028.057.108-.059Zm-.375.208-.109.057.026.059.109-.059Zm-.382.2-.111.054.026.059.12-.054-.026-.059Zm-.387.184-.113.05.024.059.111-.05Zm-.4.163-.116.038.019.064.116-.04Zm-.406.116-.118.017v.064a1.015,1.015,0,0,0,.123-.017v-.064Zm-.42.031h-.12v.066h.113v-.066Zm-.42-.028-.118-.019v.064l.123.019v-.064Zm-.413-.087-.116-.038-.019.061.118.038Z"
          transform="translate(-3191.984 -3173.193)"
          fill="#5766c2"
          fillRule="evenodd"
        />
        <path
          id="Path_35202"
          data-name="Path 35202"
          d="M949.969,244.92c0,.205-.017.427-.017.632a2.28,2.28,0,0,0,.153.781,41.815,41.815,0,0,1-5.706,1.326A12.968,12.968,0,0,1,949.969,244.92Z"
          transform="translate(-3198.454 -3173.002)"
          fill="#4f4599"
          fillRule="evenodd"
        />
        <path
          id="Path_35203"
          data-name="Path 35203"
          d="M945.057,248.629c-.033.026-.066.052-.1.08l.038.05.094-.078Zm5.34-2.719v.066l-.1.019v-.064l.116-.021Zm-.413.09-.118.031.014.064.118-.031Zm-.413.116-.118.038.019.061.116-.038Zm-.408.135-.116.042.021.061.113-.042Zm-.4.156-.113.047.021.059.113-.047Zm-.4.17-.111.05.024.059.111-.05Zm-.389.184-.111.054.026.059.111-.054Zm-.385.2-.109.059.026.057.108-.057Zm-.38.21-.106.061.028.057.106-.061Zm-.373.219-.106.064.031.057.106-.064Zm-.368.236-.1.068.033.054.1-.066Zm-.361.236-.1.071.033.054.1-.068Zm-.359.25-.1.073.033.052.1-.071Zm-.349.257-.1.075.035.052.1-.073Z"
          transform="translate(-3198.882 -3173.758)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35204"
          data-name="Path 35204"
          d="M960.27,241.954a3.077,3.077,0,0,1,.873-.656c.491-.158.871-.212.944-.106a9.193,9.193,0,0,1,0,1.47h0A2.08,2.08,0,0,1,960.27,241.954Z"
          transform="translate(-3210.579 -3170.112)"
          fill="#5766c2"
          fillRule="evenodd"
        />
        <path
          id="Path_35205"
          data-name="Path 35205"
          d="M937.023,250.91a2.764,2.764,0,0,1,.172.588c.033.363.356,1.012,0,1.258a11.7,11.7,0,0,1-3.318.42c-.427-.068-.337-.278-.708-.286a15.262,15.262,0,0,1-3.658.168c-1.945-.472-2.421-.9-2.193-1.312a8.756,8.756,0,0,0,3.951.5,41.838,41.838,0,0,0,5.744-1.331Z"
          transform="translate(-3185.364 -3177.578)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35206"
          data-name="Path 35206"
          d="M940.527,243.752s.059.127.141.3l-.144.078-.144-.3Zm1.51-1.062s.059.127.142.3l-.144.08-.144-.3Zm-.4.363.144.3-.144.08-.146-.3Zm-.517.375.144.3-.144.078c-.083-.168-.146-.3-.146-.3Z"
          transform="translate(-3195.383 -3171.298)"
          fill="#bec5ed"
        />
        <path
          id="Path_35207"
          data-name="Path 35207"
          d="M1055.185,229.5s-.337-1.012-.127-1.18.321-.139.342-.087-.14.878-.113,1.067-.1.2-.1.2Z"
          transform="translate(-3282.946 -3160.22)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35208"
          data-name="Path 35208"
          d="M1040.659,233.981s.573.871,1.067.944a1.12,1.12,0,0,0,1.22-.729c.2-.543-.064-1.095.13-1.572s.392-.8.522-.786a9.4,9.4,0,0,1,1.137.944,2.3,2.3,0,0,0,.708.385,41.307,41.307,0,0,1-2.641,5.232,8.657,8.657,0,0,1-2.914,2.681c-.236-.573.37-2.044.675-3.068a4.916,4.916,0,0,0,.033-3.028c-.2-.493-.3-.963.064-1Zm-.182,7.316h0Zm-.075.017Zm-.071,0Zm-.062,0Zm-.057,0Zm-.054,0h0Zm-.05,0h0Zm-.042-.019-.024-.012Zm-.038-.021-.024-.017a.042.042,0,0,1-.016-.012l-.021-.021.021.021Zm-.071-.059a.183.183,0,0,1-.019-.026A.183.183,0,0,0,1039.958,241.214Zm-.026-.033-.019-.031Zm-.019-.033a.3.3,0,0,1-.026-.057A.3.3,0,0,0,1039.914,241.148Z"
          transform="translate(-3271.368 -3163.007)"
          fill="#474646"
          fillRule="evenodd"
        />
        <path
          id="Path_35209"
          data-name="Path 35209"
          d="M1042.912,244.68a1.2,1.2,0,0,0,.338-.08c-.078.906-.347,3.054-1.166,3.8a4.293,4.293,0,0,1-1.034.743c.146-.472.318-.965.441-1.385a5.3,5.3,0,0,0,.149-2.664H1042a1.288,1.288,0,0,0,.913-.425Z"
          transform="translate(-3272.297 -3172.758)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35210"
          data-name="Path 35210"
          d="M1043.881,245.51l-.017.12.062.014.019-.123Zm-.064.425-.021.12.064.014.019-.12Zm-.073.422-.021.12.064.017.021-.123Zm-.078.422-.026.118.064.016.023-.12Zm-.092.418-.028.118.064.019.028-.12Zm-.1.413-.033.116.059.021.035-.116Zm-.127.406-.042.113.059.026.043-.116Zm-.17.382c-.019.035-.04.068-.061.1l.052.038c.024-.035.045-.068.066-.106l-.057-.033Zm-.236.34-.08.092.049.042.08-.092Zm-.293.3c-.028.028-.059.054-.09.08l.042.047.092-.08Zm-.333.26-.1.064.036.054.106-.066Z"
          transform="translate(-3273.183 -3173.452)"
          fill="#5766c2"
          fillRule="evenodd"
        />
        <path
          id="Path_35211"
          data-name="Path 35211"
          d="M1053.91,234.027c.156.139.321.286.472.415a2.3,2.3,0,0,0,.708.385,40.708,40.708,0,0,1-2.62,5.192,12.731,12.731,0,0,1,1.437-6Z"
          transform="translate(-3281.021 -3164.674)"
          fill="#4f4599"
          fillRule="evenodd"
        />
        <path
          id="Path_35212"
          data-name="Path 35212"
          d="M1053.77,240.522v.123h.063v-.123Zm1.307-5.812.052.045-.05.087-.057-.031Zm-.191.373-.054.109.059.031c.016-.038.036-.073.052-.111Zm-.177.389c-.014.038-.031.073-.045.111l.059.026c.014-.035.031-.073.045-.111Zm-.153.4-.043.116.062.024a.4.4,0,0,1,.04-.116Zm-.139.406-.035.116.061.021.035-.116Zm-.12.411-.033.118.061.019.033-.118Zm-.109.415-.026.118.062.019.028-.12Zm-.092.418a.7.7,0,0,1-.024.12l.062.017a.722.722,0,0,1,.023-.12Zm-.08.422-.021.123.064.014.019-.123Zm-.068.425-.019.123.064.012.017-.12Zm-.057.43-.016.123h.064l.014-.123Zm-.047.429v.123h.064v-.123Zm-.035.43v.125h.063v-.123h-.063Zm-.026.434v.123h.063v-.123Z"
          transform="translate(-3282.015 -3165.201)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35213"
          data-name="Path 35213"
          d="M1053.275,233.7a2.919,2.919,0,0,1,.049-1.086c.194-.472.392-.8.522-.786a9.418,9.418,0,0,1,1.138.944h0a2.042,2.042,0,0,1-1.716.927Z"
          transform="translate(-3281.616 -3162.999)"
          fill="#5766c2"
          fillRule="evenodd"
        />
        <path
          id="Path_35214"
          data-name="Path 35214"
          d="M1045.611,237.409a2.737,2.737,0,0,1,.569.236c.3.208,1.01.375.972.8a11.585,11.585,0,0,1-1.8,2.8c-.325.283-.429.08-.668.349a15.157,15.157,0,0,1-2.209,2.9c-1.605,1.18-2.249,1.267-2.419.831a8.656,8.656,0,0,0,2.91-2.695,40.844,40.844,0,0,0,2.641-5.232Z"
          transform="translate(-3271.54 -3167.256)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35215"
          data-name="Path 35215"
          d="M1042.945,250.171l.321.08-.031.161-.326-.083Zm.144-1.831.321.08-.031.16-.326-.083Zm.023.54.321.08-.03.16-.326-.083Zm-.04.632.321.08-.031.161-.328-.083Z"
          transform="translate(-3273.717 -3175.615)"
          fill="#bec5ed"
        />
        <path
          id="Path_35248"
          data-name="Path 35248"
          d="M680.237,51.2c.068.163,1.008,1.652,1.265,2.185-.472.819-1.871.8-2.565,1.454a6.819,6.819,0,0,0-1.017-2.426l2.124-1.194.186-.019Z"
          transform="translate(-2994.86 -3024.998)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35249"
          data-name="Path 35249"
          d="M680.237,51.2c.031.076.25.437.5.854a4.037,4.037,0,0,1-2.077,1.81,7.359,7.359,0,0,0-.743-1.451l2.124-1.194.186-.019Z"
          transform="translate(-2994.86 -3024.998)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35250"
          data-name="Path 35250"
          d="M668.526,28.611c.286-.083,1.045-1.128,1.376-1.416l-.04.042.113.123a2.369,2.369,0,0,0,.236-.278l.168-.021c-.031.038-.064.073-.095.106l.111.123a2.053,2.053,0,0,0,.224-.271c.307-.08.628-.288.8-.972a3.2,3.2,0,0,0,.2-2.251,2.316,2.316,0,0,0-.507-.861,1.622,1.622,0,0,0-.507-.675c-.708-.472-1.416-1.263-2.261-1.128a6.253,6.253,0,0,1-2.157-.389,3.559,3.559,0,0,0-2.744,1.756,3.673,3.673,0,0,0-.729,1.7,3.825,3.825,0,0,0,.1,2.15l.153-.057a3.773,3.773,0,0,1-.2-1.312c.026.139.059.286.1.441a1.521,1.521,0,0,0,.283.6,3.636,3.636,0,0,0,.291.618l.139-.09a3.126,3.126,0,0,1-.153-.288c.278.17.552.149.585.078.061-.13-.108-1.1,1.142-.507s1.911,3.3,3.379,2.768Zm2.995-3.5a5.794,5.794,0,0,0-.205-1.593,2.276,2.276,0,0,1,.142.335,2.6,2.6,0,0,1,.064,1.258Z"
          transform="translate(-2983.162 -3001.722)"
          fill="#ebb64d"
          fillRule="evenodd"
        />
        <path
          id="Path_35251"
          data-name="Path 35251"
          d="M667.917,35.519c-.021,1.081.212.819.236,1.666s-.363,1.114-.236,1.255.842.125.842.125a1.768,1.768,0,0,0,1.133,1.373,2.172,2.172,0,0,0,2.676-1.232c-.13-.054-.2-.51-.326-1.14,0,0,.038,0,.271-.236a1.371,1.371,0,0,0,.154-1.277c-.175-.4-.524-.271-.68.064s-.182,1.6-.448,1.227.049-1.237,0-1.7-.382-.191-1.258-.359a2.466,2.466,0,0,1-1.4-.906c-.219-.319-.411-.437-.571-.434A3.248,3.248,0,0,0,667.917,35.519Z"
          transform="translate(-2987.198 -3011.818)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35252"
          data-name="Path 35252"
          d="M672.414,37.3c-.17-.408.1-1.18.045-1.6s-.382-.191-1.258-.359a2.466,2.466,0,0,1-1.4-.906c-.219-.319-.411-.437-.571-.434a2.646,2.646,0,0,0-.123.236,1.26,1.26,0,0,1,.529.609c.125.432,1.305.923,1.862.852s.845-.09.739.295,0,1.267.175,1.305Z"
          transform="translate(-2988.129 -3011.856)"
          fill="#fac2b6"
          fillRule="evenodd"
        />
        <path
          id="Path_35253"
          data-name="Path 35253"
          d="M672.032,43.212c.118-.024.236.12.279.319s-.031.382-.151.406-.236-.12-.278-.321S671.911,43.235,672.032,43.212Z"
          transform="translate(-2990.237 -3018.892)"
          fill="#4d352f"
          fillRule="evenodd"
        />
        <path
          id="Path_35254"
          data-name="Path 35254"
          d="M669.62,40.3a.971.971,0,0,1,1.286-.236l-.151.321a.636.636,0,0,0-.864.12l-.271-.217Z"
          transform="translate(-2988.519 -3016.379)"
          fill="#d49b2a"
        />
        <path
          id="Path_35255"
          data-name="Path 35255"
          d="M675.573,26.846a1.821,1.821,0,0,1,.548,2.374l-.154-.061c.578-1.572-.472-2.162-.472-2.162ZM672.446,24a4.2,4.2,0,0,0,2.742,1.619l-.033.168a4.376,4.376,0,0,1-2.846-1.692Zm-.925.909s-.392,1.215,2.912,2.159l-.043.165c-3.467-.979-3.025-2.36-3.023-2.36l.153.052Zm3.143-.786a3.672,3.672,0,0,1,2.523,2.124,2.209,2.209,0,0,1-.5,2.832l-.092-.139a2.048,2.048,0,0,0,.446-2.622,3.49,3.49,0,0,0-2.4-2.022l.028-.17Z"
          transform="translate(-2989.839 -3004.216)"
          fill="#d49b2a"
        />
        <path
          id="Path_35256"
          data-name="Path 35256"
          d="M685.9,43.627s0-.311-.189-.406-.361.194-.375.522-.13.6.137.625a.368.368,0,0,0,.38-.4c-.021-.156-.154-.026-.262-.116S685.878,43.724,685.9,43.627Z"
          transform="translate(-3000.501 -3018.887)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35257"
          data-name="Path 35257"
          d="M673.274,53.341s.321.1.684-.361l.17.146c-.458.585-.918.437-.918.437Z"
          transform="translate(-2991.262 -3026.357)"
          fill="#e69f91"
        />
        <path
          id="Path_35258"
          data-name="Path 35258"
          d="M691.893,186.52s-.6.859-.472,1.053,8.2,13.923,8.337,14.256-.5.871-1.1.871a9.014,9.014,0,0,1-2.107-.628l-9.777-13.8,1.8-3.273,3.328,1.524Z"
          transform="translate(-3001.622 -3127.222)"
          fill="#eba496"
          fillRule="evenodd"
        />
        <path
          id="Path_35259"
          data-name="Path 35259"
          d="M704.632,155.75l-2.608,8.883-2.324-.994,4.932-7.889Z"
          transform="translate(-3011.501 -3104.875)"
          fill="#bababa"
          fillRule="evenodd"
        />
        <path
          id="Path_35260"
          data-name="Path 35260"
          d="M676.5,182.784s-1.038,1.4-.944,1.546,10.117,2.619,10.277,2.74.236.673-.165,1.416a2.818,2.818,0,0,0-.361,1.324c-2.863-.189-13.723-1.18-14.466-4.012.052-1.532.439-3.688,1.147-4.463,1.081-1.18,4.422,1.416,4.515,1.435Z"
          transform="translate(-2989.451 -3124.187)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35261"
          data-name="Path 35261"
          d="M678.073,182.83a15.144,15.144,0,0,0-.868,1.3,10.384,10.384,0,0,1-4.3-1.392,3.774,3.774,0,0,1,.658-1.343C674.639,180.215,677.981,182.811,678.073,182.83Z"
          transform="translate(-2991.025 -3124.233)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35262"
          data-name="Path 35262"
          d="M677.306,139.3,672.56,149a11.264,11.264,0,0,0,6.775,1.888s3.84-6.046,4.788-8.151.828-3.7.415-4.248-7.28.847-7.233.824Z"
          transform="translate(-2990.765 -3091.588)"
          fill="#ccc"
          fillRule="evenodd"
        />
        <path
          id="Path_35263"
          data-name="Path 35263"
          d="M674.073,173.075l-.323.656a4.1,4.1,0,0,0,1.68.944c1.779.59,5.548-.92,5.98-1.841s-1.385.257-1.8-.087,2.124-1.588,1.128-1.18a17.047,17.047,0,0,1-5.76,1.517h-.908Z"
          transform="translate(-2991.674 -3116.896)"
          fill="#c2c0c0"
          fillRule="evenodd"
        />
        <path
          id="Path_35264"
          data-name="Path 35264"
          d="M682.06,151.152c.17.064.342.125.51.179a11.167,11.167,0,0,1,.758-1.4,1.055,1.055,0,0,1,.2-.144c.373-.236.791-.472.488-1.366a2.345,2.345,0,0,1,.472-1.609,15.046,15.046,0,0,1,1.4-1.937,30.439,30.439,0,0,0,2.539-5.123l-.25-.1-.309.047a28.044,28.044,0,0,1-2.4,4.835,15.491,15.491,0,0,0-1.451,2.013,2.7,2.7,0,0,0-.512,2.046.523.523,0,0,1-.262.741,1.289,1.289,0,0,0-.326.25A11.384,11.384,0,0,0,682.06,151.152Z"
          transform="translate(-2998.023 -3092.574)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35265"
          data-name="Path 35265"
          d="M687.666,155.275s-3.011,4.109-7.509,5.107l-.045-.205c4.43-.982,7.386-5.015,7.4-5.027l.17.125Zm-8.642,5.9s3.249.916,5.576-.97l.134.163c-2.412,1.954-5.756,1.012-5.765,1.01Z"
          transform="translate(-2995.663 -3104.417)"
          fill="#bababa"
          fillRule="evenodd"
        />
        <path
          id="Path_35266"
          data-name="Path 35266"
          d="M691.089,139.26l-.488,1a16.7,16.7,0,0,0,8.023-.618,2.192,2.192,0,0,0-.307-1.2c-.415-.557-7.28.847-7.233.824Z"
          transform="translate(-3004.548 -3091.547)"
          fill="#bababa"
          fillRule="evenodd"
        />
        <path
          id="Path_35267"
          data-name="Path 35267"
          d="M703.38,141.507c.2,0,.394,0,.595-.012.427-.965.736-1.742.739-1.746l-.25-.1h0l-.3.047h0c-.12.3-.415,1.01-.774,1.81Z"
          transform="translate(-3014.312 -3092.574)"
          fill="#e8ac33"
          fillRule="evenodd"
        />
        <path
          id="Path_35268"
          data-name="Path 35268"
          d="M676.04,104.92a3.884,3.884,0,0,0-1.819.656c-.588.531-.059,2.516.425,2.976s2.107-.411,2.107-.411-.784-3.221-.708-3.221Z"
          transform="translate(-2991.836 -3066.04)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35269"
          data-name="Path 35269"
          d="M682.583,60.685a10.41,10.41,0,0,0-1.862-1.076,3.729,3.729,0,0,0-2.636,1.777,9.267,9.267,0,0,0-.9,6.18c.642,2.516,2.471,8.125,2.471,9.336s-.76,2.07.592,2.6,7.915-.191,8.144-.772-.413-1.451-.552-2.249a37.4,37.4,0,0,0-5.255-15.78Z"
          transform="translate(-2994.136 -3031.415)"
          fill="#1f76b8"
          fillRule="evenodd"
        />
        <path
          id="Path_35270"
          data-name="Path 35270"
          d="M689.561,105.722c-.1-.524-.472-1.154-.578-1.744-.026-.149-.064-.545-.142-1.137a11.748,11.748,0,0,1-4.2,2.046c-2.124.333.458.769,1.959.385s1.963-.736,2.124-.139a.964.964,0,0,0,.836.59ZM678.47,95.61c.321,1.18.8,2.792,1.248,4.328a4.847,4.847,0,0,0,2.966-1.47c.264-.543-.583-.661.5-1.048s1.454-1.319-1.348-1.06A5.758,5.758,0,0,1,678.47,95.61Z"
          transform="translate(-2995.28 -3058.927)"
          fill="#1867a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35271"
          data-name="Path 35271"
          d="M684.35,90.864l.021.078.026.09.019.066.021.078V91.2l.014.05.021.076.012.045v.033l.021.076.016.061v.014l.021.076.021.076h0l.021.071.019.076v.019l.016.054.019.073v.073l.019.073.014.05v.021l.019.073.017.064h0l.019.068.019.071h0l.014.059.019.068v.068l.016.068v.066l.019.066v.066l.016.064.016.064h0l.014.061.017.064.014.061.017.061v.059l.014.061h0v.106l.024.1v.04l.014.054h0V93.8l.012.052V93.9h0v.052l.012.052v.05a.887.887,0,0,0,.035.182h0v.132h0v.156h0v.2A4.21,4.21,0,0,0,686.8,93.3c.5-.559,3.955-2.872,5.531-4.042l-.019-.076.21-.205-.014-.061h0l-.021-.085-.021-.087h0l-.028-.109-.038-.144-.021-.087h0l-.021-.085-.038-.144-.033-.142h0l-.024-.092-.026-.092-.026-.09-.026-.092-.026-.092-.035-.127-.038-.135-.054-.179v-.026l-.028-.094v-.021l-.043-.142-.052-.17-.042-.134-.064-.2-.036-.106-.061-.189-.047-.142-.057-.168-.047-.137-.068-.2-.04-.113-.064-.182-.052-.142-.08-.219-.038-.1-.078-.2-.057-.142-.068-.179-.054-.135-.085-.205-.061-.146-.08-.194-.052-.123-.094-.222c-.675.547-1.952,1.586-1.952,1.6a14,14,0,0,1,1.673,2.442c0,.387-4.451,4.012-4.451,4.012a3.181,3.181,0,0,0-1.383-.314h-.116Z"
          transform="translate(-2999.773 -3049.369)"
          fill="#1f76b8"
          fillRule="evenodd"
        />
        <path
          id="Path_35272"
          data-name="Path 35272"
          d="M684.35,90.879a31.654,31.654,0,0,1,.944,3.858,4.211,4.211,0,0,0,1.784-1.428c.5-.559,3.955-2.872,5.531-4.042a36.868,36.868,0,0,0-2.022-6.147c-.675.547-1.952,1.586-1.952,1.6a14,14,0,0,1,1.673,2.442c0,.387-4.451,4.012-4.451,4.012a3.181,3.181,0,0,0-1.383-.314h-.116Z"
          transform="translate(-2999.773 -3049.385)"
          fill="#1867a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35273"
          data-name="Path 35273"
          d="M683.251,104.379c.012,0,4.519.828,6.726,0l.071.208c-2.261.842-6.818,0-6.844,0l.035-.217Zm3.231-9.5s.37,4.05-1.843,6.551l-.156-.151c2.15-2.426,1.789-6.372,1.789-6.372l.21-.019Zm-5.779-.814a6.9,6.9,0,0,0,5.383-.028l.073.208a7.149,7.149,0,0,1-5.548.021Zm2.268,9.475c.017,0,5.138-.191,6.209-1.952l.177.118c-1.13,1.86-6.372,2.053-6.372,2.053v-.219Z"
          transform="translate(-2996.916 -3057.728)"
          fill="#67abe0"
          fillRule="evenodd"
        />
        <path
          id="Path_35274"
          data-name="Path 35274"
          d="M683.387,59.678a1.337,1.337,0,0,0-.236-.09,3.738,3.738,0,0,0-2.636,1.777,8.187,8.187,0,0,0-.375.814c.252-.066.533-.345.944-.925a6.718,6.718,0,0,1,2.308-1.576Z"
          transform="translate(-2996.556 -3031.399)"
          fill="#f7f9f9"
          fillRule="evenodd"
        />
        <path
          id="Path_35275"
          data-name="Path 35275"
          d="M683.951,81.355a13.312,13.312,0,0,1,1.673,2.442c0,.387-3.97,4.411-3.97,4.411a3.165,3.165,0,0,0-1.38-.314c-.647.047-1.789.472-1.576.845a1.44,1.44,0,0,1,.213.437s-.963,1.779.512,2.192,2.341-.72,2.971-1.428,5.921-4.17,6.289-4.668c.774-1.048-1.81-5.029-2.372-5.852C686.311,79.42,683.951,81.329,683.951,81.355Z"
          transform="translate(-2995.381 -3046.557)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35276"
          data-name="Path 35276"
          d="M701.217,81.317s.21.255.472.6c.651-.514,1.751-1.383,2.343-1.843-.269-.411-.472-.708-.472-.708s-2.36,1.909-2.36,1.935Z"
          transform="translate(-3012.646 -3046.52)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35277"
          data-name="Path 35277"
          d="M694.5,64.948s-1.82-3.41-3.106-4.234a1.528,1.528,0,0,0-2.28.43c-1.3,1.791,1.1,4.878,2.124,6.372,0,0,3.271-2.6,3.271-2.563Z"
          transform="translate(-3003.125 -3032.012)"
          fill="#67abe0"
          fillRule="evenodd"
        />
        <path
          id="Path_35278"
          data-name="Path 35278"
          d="M698.06,73.445a10.126,10.126,0,0,0,.165-3.965l.2-.04a10.339,10.339,0,0,1-.167,4.061Z"
          transform="translate(-3010.248 -3038.933)"
          fill="#1f76b8"
          fillRule="evenodd"
        />
        <path
          id="Path_35279"
          data-name="Path 35279"
          d="M691.4,61a1.856,1.856,0,0,0-.545.472l3.575,5.262.554-.437Z"
          transform="translate(-3004.739 -3032.485)"
          fill="#f7f9f9"
          fillRule="evenodd"
        />
        <path
          id="Path_35280"
          data-name="Path 35280"
          d="M672.555,43.679a.092.092,0,1,1-.085.092A.09.09,0,0,1,672.555,43.679Z"
          transform="translate(-2990.696 -3019.251)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35281"
          data-name="Path 35281"
          d="M709.31,97.679a1.838,1.838,0,0,1,1.451.345l-.142.165a1.611,1.611,0,0,0-1.274-.29Z"
          transform="translate(-3018.843 -3060.486)"
          fill="#f0b0a3"
        />
        <path
          id="Path_35282"
          data-name="Path 35282"
          d="M682.852,195.282a3.081,3.081,0,0,0-1.845.84l-.137-.168a3.3,3.3,0,0,1,1.975-.894v.222Z"
          transform="translate(-2997.114 -3134.908)"
          fill="#f0b0a3"
        />
        <path
          id="Path_35283"
          data-name="Path 35283"
          d="M715.8,254.19a1.982,1.982,0,0,1,.585,1.05c.085.6.748.989.8,1.253a1.586,1.586,0,0,1-1.543,1.64c-1.458.224-2.806.323-3.639.571a7.021,7.021,0,0,1-3.469-.484.927.927,0,0,1-.5-1.491c.326-.524,1.956.283,2.7-.814s1.263-2.027,1.668-1.819a5.909,5.909,0,0,0,2.237.9A1.344,1.344,0,0,0,715.8,254.19Z"
          transform="translate(-3017.719 -3179.99)"
          fill="#67abe0"
          fillRule="evenodd"
        />
        <path
          id="Path_35284"
          data-name="Path 35284"
          d="M716.062,255.85a1.722,1.722,0,0,1,.326.753c.085.6.748.989.8,1.253a1.586,1.586,0,0,1-1.543,1.64c-1.458.224-2.806.323-3.639.571a7.022,7.022,0,0,1-3.469-.484c-.677-.283-.8-.746-.642-1.18a4.263,4.263,0,0,0,3.349.434c2.145-.5,3.634-2.386,4.817-2.978Z"
          transform="translate(-3017.718 -3181.353)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35285"
          data-name="Path 35285"
          d="M717.188,263.56a.473.473,0,0,1,.109.186,1.588,1.588,0,0,1-1.543,1.64c-1.458.224-2.806.323-3.639.571a7.02,7.02,0,0,1-3.469-.484,1.069,1.069,0,0,1-.666-.616,8.617,8.617,0,0,0,2.874.724,25.336,25.336,0,0,0,4.37-.677A3.537,3.537,0,0,0,717.188,263.56Z"
          transform="translate(-3017.826 -3187.244)"
          fill="#1f76b8"
          fillRule="evenodd"
        />
        <path
          id="Path_35286"
          data-name="Path 35286"
          d="M718.864,249.645c-.026-.359-.125-1.119-.519-1.251-.054-.017-.087-.019-.1,0a.127.127,0,0,0-.024.09.83.83,0,0,0,.038.236A1.939,1.939,0,0,0,718.864,249.645Zm.21.215h.151c.51-.014,1.855-.059,1.9.493.061.727-2.112-.286-2.124-.288h-.349c-.944.146-1.18,1.225-1.18,1.227l-.193-.052a2.036,2.036,0,0,1,.84-1.206,2.176,2.176,0,0,1-.411-.083.772.772,0,0,0-.746.17l-.134-.156a.944.944,0,0,1,.944-.215,3.29,3.29,0,0,0,.869.1h.158a2.139,2.139,0,0,1-.708-1.06,1.032,1.032,0,0,1-.047-.307.318.318,0,0,1,.1-.25.288.288,0,0,1,.283-.028c.656.222.67,1.633.67,1.638v.028Zm1.852.507c-.024-.293-.852-.316-1.4-.309C720.082,250.294,720.946,250.612,720.927,250.367Z"
          transform="translate(-3024.588 -3175.497)"
          fill="#ccc"
        />
        <path
          id="Path_35287"
          data-name="Path 35287"
          d="M720.53,257.878a1.416,1.416,0,0,0-.72-.5l.059-.2a1.629,1.629,0,0,1,.826.573Zm.994-1a2.041,2.041,0,0,0-.857-.6l.061-.2a2.269,2.269,0,0,1,.944.665l-.154.135Zm-.212.257a2.06,2.06,0,0,0-.857-.6l.061-.2a2.25,2.25,0,0,1,.944.665l-.153.135Zm-.236.22a1.872,1.872,0,0,0-.817-.552l.059-.2a2.092,2.092,0,0,1,.913.621Zm-.257.307a1.854,1.854,0,0,0-.8-.538l.059-.2a2.048,2.048,0,0,1,.9.606Z"
          transform="translate(-3026.865 -3181.528)"
          fill="#e6e6e6"
        />
        <path
          id="Path_35288"
          data-name="Path 35288"
          d="M729.57,202.119a1.742,1.742,0,0,1,.987-.432c.538-.014.944-.59,1.18-.611s1.229.236,1.267,1.6-.024,2.636.1,3.436a6.864,6.864,0,0,1-.807,3.167c-.4.734-.944.661-1.362.3s.472-1.786-.418-2.6-1.635-1.388-1.416-1.742a5.74,5.74,0,0,0,1.038-1.98,1.255,1.255,0,0,0-.585-1.152Z"
          transform="translate(-3033.937 -3139.504)"
          fill="#67abe0"
          fillRule="evenodd"
        />
        <path
          id="Path_35289"
          data-name="Path 35289"
          d="M732.43,201.909a1.489,1.489,0,0,1,.708-.222c.538-.014.944-.59,1.18-.611s1.229.236,1.267,1.6-.024,2.636.1,3.436a6.863,6.863,0,0,1-.807,3.167c-.323.6-.741.658-1.114.472a4.149,4.149,0,0,0,.748-3.068c-.2-2.041-1.69-3.622-2.081-4.783Z"
          transform="translate(-3036.507 -3139.504)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35290"
          data-name="Path 35290"
          d="M739.639,201.156a.415.415,0,0,1,.175-.08c.236-.021,1.229.236,1.267,1.6s-.023,2.636.1,3.436a6.862,6.862,0,0,1-.807,3.167.962.962,0,0,1-.614.55,8.132,8.132,0,0,0,.944-2.6,25.053,25.053,0,0,0-.116-4.125,3.359,3.359,0,0,0-.963-1.963Z"
          transform="translate(-3042.007 -3139.504)"
          fill="#1f76b8"
          fillRule="evenodd"
        />
        <path
          id="Path_35291"
          data-name="Path 35291"
          d="M724.834,213.9c-.316-.017-.993,0-1.152.349-.021.047-.026.078-.017.092a.119.119,0,0,0,.076.033.628.628,0,0,0,.215-.012,1.7,1.7,0,0,0,.878-.472Zm.212-.172a.7.7,0,0,1,.014-.142c.04-.472.151-1.727.639-1.713.644.019-.472,1.93-.486,1.935h0c0,.054-.012.175-.031.326a1.471,1.471,0,0,0,.944,1.227l-.068.175a1.811,1.811,0,0,1-.965-.906,2.364,2.364,0,0,1-.116.373.741.741,0,0,0,.066.708l-.151.109a.928.928,0,0,1-.087-.892,3.306,3.306,0,0,0,.189-.8.305.305,0,0,1,0-.068h0v-.08a1.888,1.888,0,0,1-1.005.55.864.864,0,0,1-.276.012.267.267,0,0,1-.205-.12.274.274,0,0,1,0-.267c.264-.585,1.506-.448,1.508-.446Zm.649-1.668c-.259,0-.373.757-.425,1.265C725.533,212.835,725.913,212.063,725.7,212.056Z"
          transform="translate(-3029.664 -3147.75)"
          fill="#ccc"
        />
        <path
          id="Path_35292"
          data-name="Path 35292"
          d="M732.105,220.012a1.3,1.3,0,0,0-.512.616l-.17-.078a1.5,1.5,0,0,1,.595-.708l.087.165Zm-.767-1.027a1.888,1.888,0,0,0-.618.729l-.17-.076a2.111,2.111,0,0,1,.687-.809Zm.2.224a1.863,1.863,0,0,0-.618.729l-.17-.076a2.151,2.151,0,0,1,.687-.812Zm.168.236a1.8,1.8,0,0,0-.576.708l-.168-.078a1.962,1.962,0,0,1,.644-.781l.1.158Zm.236.271a1.75,1.75,0,0,0-.559.689l-.17-.078a1.922,1.922,0,0,1,.63-.769Z"
          transform="translate(-3035.071 -3153.068)"
          fill="#e6e6e6"
        />
        <path
          id="Path_35293"
          data-name="Path 35293"
          d="M847.443,112.62a23.419,23.419,0,0,1-3.151,2.858c-.609.208-1.225.092-1.548.215s-.628.236-.658.68-.132.878,0,1.074.323,1.1.455,1.218a.656.656,0,0,0,.649,0c.262-.116.394-.444.729-.67s.628-.208.944-.621a24.085,24.085,0,0,1,3.214-2.06C848.606,115,847.443,112.62,847.443,112.62Z"
          transform="translate(-3120.235 -3071.923)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35294"
          data-name="Path 35294"
          d="M875.655,24.871s1.1-.415,1.652.191A4.423,4.423,0,0,0,880.4,26.07a5.7,5.7,0,0,1,6.216,1.848l-.151.144a5.567,5.567,0,0,0-5.388-1.959c1.456.064,2.905.113,3.712.944.616.64,1.586,1.447,1.586,1.447a1.949,1.949,0,0,1-1.5-.179c-1.765-.911-2.327,2.45-6.046,1a6.987,6.987,0,0,1-.864-.4c.675.637,1.964,1.18,4.413.866l.026.208c-3.176.4-4.484-.595-5.017-1.451-1.085-.842-.93-1.685-1.227-2.013a1.228,1.228,0,0,0-.743-.451l.236-1.2Z"
          transform="translate(-3145.745 -3004.775)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35295"
          data-name="Path 35295"
          d="M882.108,159.4s-.578,5.39,0,6.7a3.253,3.253,0,0,0,2.273,2.138,9.188,9.188,0,0,1,4.012,3.068c1.074,1.487,2.329,3.776,2.976,4.186a3.541,3.541,0,0,0,1.3.512,30.162,30.162,0,0,1-1.2,3.3c-.212.186-.637-.333-1.468-1.416a27.635,27.635,0,0,0-3.009-3.006c-.4-.347-4.446-3.405-5.56-4.064s-1.822,0-2.45-1.888-1.7-5.791-1.7-5.791l-1.052-2.737,5.878-1.015Z"
          transform="translate(-3146.372 -3107.663)"
          fill="#e69f91"
          fillRule="evenodd"
        />
        <path
          id="Path_35296"
          data-name="Path 35296"
          d="M882.109,159.42s-.069.637-.135,1.527a23.9,23.9,0,0,0-5.17.982l-.574-1.494Z"
          transform="translate(-3146.372 -3107.679)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35297"
          data-name="Path 35297"
          d="M935.762,226.2a7.537,7.537,0,0,1-.628,1.994,3.428,3.428,0,0,1-.774.93l1.4,1.6a7.621,7.621,0,0,0,.97-2.544,6.542,6.542,0,0,1,.248-1.442Z"
          transform="translate(-3190.784 -3158.7)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35298"
          data-name="Path 35298"
          d="M898.163,135.46a32.281,32.281,0,0,1,.472,4.42c.031,1.937-.769,2.195-.769,2.195a5.013,5.013,0,0,1-1.376,0c-.4-.12,1.652-6.608,1.652-6.608Z"
          transform="translate(-3161.811 -3089.373)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35299"
          data-name="Path 35299"
          d="M898.444,160.85c-.236.526-.529.623-.529.623a5.015,5.015,0,0,1-1.376,0c-.068-.021-.066-.222-.017-.543A15.86,15.86,0,0,0,898.444,160.85Z"
          transform="translate(-3161.848 -3108.771)"
          fill="#db406a"
          fillRule="evenodd"
        />
        <path
          id="Path_35300"
          data-name="Path 35300"
          d="M859.779,153.169s-2.471,4.7-3.707,7.1-1.652,1.888-1.477,3.186a11.312,11.312,0,0,1,.236,4.788,55.554,55.554,0,0,1-1.256,6.1,14.247,14.247,0,0,0-.425,2.294,6.913,6.913,0,0,1-.486,1.416l-2.794-.691a26.212,26.212,0,0,0,1.234-4.743c.2-1.895.283-7.259.264-8.038s-.75-1.692-.547-3.252,3.219-9.708,3.219-9.708,3.776-.871,5.734,1.536Z"
          transform="translate(-3126.232 -3101.604)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35301"
          data-name="Path 35301"
          d="M871.025,153.192s-.472.9-1.109,2.124c-.323-.2-.684-.441-1.088-.708a5.234,5.234,0,0,0-3.2-.944,5.474,5.474,0,0,1-1.039-.085c.408-1.135.708-1.926.708-1.926s3.776-.871,5.734,1.536Z"
          transform="translate(-3137.479 -3101.628)"
          fill="#e69f91"
          fillRule="evenodd"
        />
        <path
          id="Path_35302"
          data-name="Path 35302"
          d="M873.521,130.464s.257-.179.257,1.959-1.652,6.372-2.145,6.426-1.286-.5-2.329-1.18a5.239,5.239,0,0,0-3.2-.944,4.055,4.055,0,0,1-1.874-.347s.9-3.83,1.213-4.588,2.886-1.97,8.078-1.336Z"
          transform="translate(-3137.204 -3085.413)"
          fill="#474646"
          fillRule="evenodd"
        />
        <path
          id="Path_35303"
          data-name="Path 35303"
          d="M876.6,130.5s.175-.12.236,1.019a12.01,12.01,0,0,1-4.427.4,7.365,7.365,0,0,0-4.149.743c.1-.368.2-.665.262-.826C868.836,131.076,871.408,129.861,876.6,130.5Z"
          transform="translate(-3140.283 -3085.445)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35304"
          data-name="Path 35304"
          d="M871.926,156.79c-.118.175-.217.276-.293.283-.507.057-1.286-.5-2.329-1.18a5.239,5.239,0,0,0-3.2-.944,4.056,4.056,0,0,1-1.874-.347s.043-.184.114-.472a15.408,15.408,0,0,0,2.249.286,5.847,5.847,0,0,1,2.862.838c.359.236,1.574.986,2.471,1.534Z"
          transform="translate(-3137.204 -3103.637)"
          fill="#db406a"
          fillRule="evenodd"
        />
        <path
          id="Path_35305"
          data-name="Path 35305"
          d="M938.672,227.018s-.486-.321-.6-.066.3.746.3,1.18a4.977,4.977,0,0,1-.644,1.97c-.274.335-.84.472-.88.623s.312.472.687,1.045a6.262,6.262,0,0,1,.72,2.907c-.09.713-.187,1.211.375,1.536a7.726,7.726,0,0,0,1.18-4.064,9.966,9.966,0,0,1,.193-3.162,5.736,5.736,0,0,0-.1-2.048,6.169,6.169,0,0,1-1.227.066Zm0,9.229a.262.262,0,0,0,.059.026l.059.012-.059-.012A.262.262,0,0,1,938.672,236.247Z"
          transform="translate(-3192.684 -3159.198)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35306"
          data-name="Path 35306"
          d="M944.387,235.729l.04.021c.607.323,1.529-1.491,1.72-2.549s.366-3.408.557-4.288a5.867,5.867,0,0,0,0-2.372c-.12-.422-.262-.408-.729-.186a1.429,1.429,0,0,1-.321.1,5.739,5.739,0,0,1,.1,2.048,9.961,9.961,0,0,0-.194,3.162,7.6,7.6,0,0,1-1.18,4.064Z"
          transform="translate(-3198.439 -3158.701)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35307"
          data-name="Path 35307"
          d="M936.17,244.706a2.86,2.86,0,0,0-.177.458,1.111,1.111,0,0,0-.052.505.316.316,0,0,0,.163.236,1.677,1.677,0,0,1,.179.113.609.609,0,0,0,.1.066c.024,0-.038-.017-.038-.066a13.03,13.03,0,0,0-.177-1.317Zm.548,1.971a5.578,5.578,0,0,0-.163-1.652c-.047-.038-.09-.075-.127-.111l-.016-.014a10.407,10.407,0,0,1,.144,1.123c0,.208-.063.278-.172.276a.427.427,0,0,1-.217-.1,1.41,1.41,0,0,0-.153-.1.52.52,0,0,1-.274-.392,1.309,1.309,0,0,1,.057-.6,3.069,3.069,0,0,1,.338-.76l.12-.2v-.057h.1c.087,0,1.017,0,1.18.472a1.09,1.09,0,0,1,.057.458.414.414,0,0,1-.073.189.289.289,0,0,1-.156.116.364.364,0,0,1-.184,0,1,1,0,0,1-.356-.137,6.045,6.045,0,0,1,.111,1.48l-.205-.014Zm-.236-2.374a2.752,2.752,0,0,1,.255.6c.04.031.08.061.123.09a.879.879,0,0,0,.342.149.183.183,0,0,0,.09,0,.108.108,0,0,0,.054-.038.292.292,0,0,0,.033-.1.873.873,0,0,0-.047-.363c-.085-.271-.6-.326-.849-.335Z"
          transform="translate(-3191.827 -3172.368)"
          fill="#db406a"
        />
        <path
          id="Path_35308"
          data-name="Path 35308"
          d="M852.052,252.674a4.2,4.2,0,0,1-1.784-.236c-.63-.326-1.2-.708-1.2-.708l-.987,1.751a6.245,6.245,0,0,0,2.426.708,5.553,5.553,0,0,1,1.333.068l.212-1.565Z"
          transform="translate(-3124.865 -3178.205)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35309"
          data-name="Path 35309"
          d="M857.43,48.317c1.149,1.793,1.321,2.164,1.336,3.495,0,.472-.446,2.669.019,2.067l3-3.891-1.7-3.117Z"
          transform="translate(-3132.008 -3021.689)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35310"
          data-name="Path 35310"
          d="M857.43,48.317a11.566,11.566,0,0,1,1.111,1.968,4.615,4.615,0,0,0,2.277-2.065l-.736-1.35Z"
          transform="translate(-3132.008 -3021.689)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35311"
          data-name="Path 35311"
          d="M862.548,60.909s-.236-.732-1.508-.807c-2.532-.151-2.15,2.759-3.148,3.391-.156.1,0-1.123-.118-1.586,0,0-.944,2.221-1.145,2.94s-1.036,2.336.019,3.4a3.834,3.834,0,0,1,1.321,2.832,16.281,16.281,0,0,0,.883,4.1,3.382,3.382,0,0,1,.137,2.145v1.2a6.391,6.391,0,0,1,2.7-.979c1.168,0,4.307-.064,5.265-.212s1.307-.316.861-.741a5.538,5.538,0,0,1-1.321-2.181c-.1-.614-.286-1.274-.392-1.76s-.852-2.848-.93-3.5S865,67.443,865,67.443Z"
          transform="translate(-3131.012 -3031.794)"
          fill="#db406a"
          fillRule="evenodd"
        />
        <path
          id="Path_35312"
          data-name="Path 35312"
          d="M882.432,84.261V84.2c-.076-.649-.172-1.709-.172-1.709l-1.013-2.709a11.376,11.376,0,0,0-2.537,1.1,47.371,47.371,0,0,0,3.731,3.379Z"
          transform="translate(-3148.267 -3046.832)"
          fill="#c92c56"
          fillRule="evenodd"
        />
        <path
          id="Path_35313"
          data-name="Path 35313"
          d="M858.219,92.75l.021.021a3.846,3.846,0,0,1,1.321,2.832,12.6,12.6,0,0,0,.224,1.652,11.034,11.034,0,0,0,2.02-.257c.958-.172,2.9-.106,3.342-.647s.017-1.779-.472-2.931-1.321-.559-2.879-1.029-1.225,0-2.091.366a2.573,2.573,0,0,1-1.5-.024Zm10.773,7.894a4.892,4.892,0,0,1-.911-1.709q-.024-.16-.057-.319a9.563,9.563,0,0,1-3.12,2.145C863.253,101.3,866.179,101.6,868.992,100.644Z"
          transform="translate(-3132.604 -3056.317)"
          fill="#c92c56"
          fillRule="evenodd"
        />
        <path
          id="Path_35314"
          data-name="Path 35314"
          d="M837.847,256.525s.224-.576-.023-.661-.63.43-1.031.491a3.9,3.9,0,0,1-1.864-.425c-.34-.25-.533-.852-.684-.873s-.387.408-.845.894a5.023,5.023,0,0,1-2.518,1.18c-.654,0-1.116-.035-1.331.623a5.991,5.991,0,0,0,3.823.708,7.678,7.678,0,0,1,2.874-.236,4.339,4.339,0,0,0,1.831-.392,7.979,7.979,0,0,1-.236-1.326Z"
          transform="translate(-3110.708 -3180.747)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35315"
          data-name="Path 35315"
          d="M864.74,65.131s.831-1.876,2.056-2.011,3.261,2.063,3.686,2.579,2.865,3.54,3.483,4.4.831.944.779,1.463-2.36,2.8-2.794,3.167-4.012,2.773-4.517,3.068a3.521,3.521,0,0,1-1.4.432,4.955,4.955,0,0,0-1.017.26c-.29.1-.453.05-.5-.045a.529.529,0,0,1-.054-.149s-.3-.08-.345-.276a2.01,2.01,0,0,1-.047-.217.323.323,0,0,1-.319-.222.561.561,0,0,1,0-.366.59.59,0,0,1-.441-.387c-.085-.319.335-.618.335-.618s-.5-.057-.42-.418.425,0,.977-.16a2.071,2.071,0,0,1,1.331-.144,4.73,4.73,0,0,0,1.494.413,8.793,8.793,0,0,0,3.183-2.893,21.474,21.474,0,0,0,1.18-1.824L868.155,68.8a3.519,3.519,0,0,1-2.832-1.18,3.36,3.36,0,0,1-.581-2.492Z"
          transform="translate(-3136.43 -3034.101)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35316"
          data-name="Path 35316"
          d="M869.566,69.926a4.981,4.981,0,0,1,.288-.536,3.859,3.859,0,0,0,1.18,2.3c1.447,1.442,2.1,2.011,2.124,2.027l-.168-.123a3.519,3.519,0,0,1-2.832-1.18,3.362,3.362,0,0,1-.58-2.492Z"
          transform="translate(-3141.256 -3038.894)"
          fill="#f0b0a3"
          fillRule="evenodd"
        />
        <path
          id="Path_35317"
          data-name="Path 35317"
          d="M840.775,254.877a2.414,2.414,0,0,0-.472-.137.849.849,0,0,0-.451.012.311.311,0,0,0-.193.212,1.945,1.945,0,0,1-.076.21.489.489,0,0,0-.047.13c0,.021,0-.035.047-.04a10.332,10.332,0,0,0,1.18-.387Zm-2,1.378a4.247,4.247,0,0,0,1.329-.654,4.623,4.623,0,0,1-1.291.111l.016-.21a4.129,4.129,0,0,0,1.576-.21v-.024a.255.255,0,0,1,.033-.05,6.225,6.225,0,0,1-.826.253c-.189.026-.264-.026-.281-.137a.5.5,0,0,1,.061-.236,1.512,1.512,0,0,0,.069-.184.5.5,0,0,1,.316-.352,1.046,1.046,0,0,1,.564-.024,2.6,2.6,0,0,1,.743.267l.174.113-.127.05h.012v.1h0c.014.1.142,1.095-.274,1.326a.868.868,0,0,1-.4.127.364.364,0,0,1-.179-.042.313.313,0,0,1-.13-.13.472.472,0,0,1-.05-.222,1.115,1.115,0,0,1,.05-.321,4.592,4.592,0,0,1-1.352.656l-.054-.205Zm1.8-.807-.042.047a2.078,2.078,0,0,0-.1.236,1.076,1.076,0,0,0-.087.4.278.278,0,0,0,.024.12.1.1,0,0,0,.045.045.17.17,0,0,0,.083.019.664.664,0,0,0,.3-.1c.236-.13.208-.708.184-.984a2.115,2.115,0,0,1-.406.236Z"
          transform="translate(-3117.737 -3180.33)"
          fill="#db406a"
        />
        <path
          id="Path_35318"
          data-name="Path 35318"
          d="M872.3,25.435s.682-.536,1-.453a1.241,1.241,0,0,1,.732.982c.083.481-1,.741-1,.741A2.055,2.055,0,0,0,872.3,25.435Z"
          transform="translate(-3143.369 -3004.96)"
          fill="#db406a"
          fillRule="evenodd"
        />
        <path
          id="Path_35319"
          data-name="Path 35319"
          d="M848.939,23.107c-.871,1.314-.2,1.8-.2,1.8l5.062,3.677s1.652-.21,1.994-1.4c1.416-5.081-4.892-7.061-6.87-4.078Z"
          transform="translate(-3125.181 -3002.464)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35320"
          data-name="Path 35320"
          d="M848.586,34.258c-.526,1.536.368,2.225.063,2.926s-.654.772-.472.873a4.134,4.134,0,0,0,.871.182s.453,1.777,1.09,1.652a4.839,4.839,0,0,0,3.514-1.966,4.267,4.267,0,0,0-.758-1.371s.342.054.595-.741c.2-.632-.894-1.593-1.038.566-.024.349-.387.6-.406.446s-.1-1.857-.1-1.857-.109-.168-1.128-.566c-1.246-.488-1.826.019-2.23-.153Z"
          transform="translate(-3124.901 -3011.996)"
          fill="#fcd2ca"
          fillRule="evenodd"
        />
        <path
          id="Path_35321"
          data-name="Path 35321"
          d="M853.583,40.33c.146,0,.274.168.286.392s-.092.418-.236.427-.274-.168-.286-.394S853.439,40.337,853.583,40.33Z"
          transform="translate(-3128.888 -3016.693)"
          fill="#331f1b"
          fillRule="evenodd"
        />
        <path
          id="Path_35322"
          data-name="Path 35322"
          d="M851.57,37.618a1.18,1.18,0,0,1,1.581.278l-.25.217a.849.849,0,0,0-1.149-.217Z"
          transform="translate(-3127.531 -3014.476)"
          fill="#995749"
          fillRule="evenodd"
        />
        <path
          id="Path_35323"
          data-name="Path 35323"
          d="M854.673,40.811a.076.076,0,1,1-.094.04.07.07,0,0,1,.039-.04.071.071,0,0,1,.056,0Z"
          transform="translate(-3129.825 -3017.055)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35324"
          data-name="Path 35324"
          d="M853.39,51.24a1.139,1.139,0,0,0,.746-.38l.139.092a1.306,1.306,0,0,1-.866.453Z"
          transform="translate(-3128.921 -3024.738)"
          fill="#995749"
        />
        <path
          id="Path_35325"
          data-name="Path 35325"
          d="M868.123,39.533c-.019.276-.085.472-.156.385s-.054-.309-.142-.262-.024.326-.149.444-.236-.444.026-.765C867.9,39.1,868.146,39.226,868.123,39.533Z"
          transform="translate(-3139.735 -3015.839)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35326"
          data-name="Path 35326"
          d="M859.37,25.749s-2.572-.918-4.1.354l-.111-.137c1.6-1.338,4.248-.387,4.267-.385Z"
          transform="translate(-3130.274 -3005.172)"
          fill="#b8886c"
        />
        <path
          id="Path_35327"
          data-name="Path 35327"
          d="M875.549,29.082a3.435,3.435,0,0,1,.34,3.368l-.158-.076a3.216,3.216,0,0,0-.321-3.181Zm1.923-.472a4.748,4.748,0,0,0,3.54,1.222c2.558,0,3.1.455,3.106.458l-.13.163s-.489-.411-2.976-.411a4.938,4.938,0,0,1-3.689-1.291l.151-.142Zm-.585.838s.882,2.471,4.108,1.94l.031.208c-3.394.559-4.33-2.065-4.333-2.074Z"
          transform="translate(-3145.745 -3007.738)"
          fill="#b8886c"
        />
        <path
          id="Path_35328"
          data-name="Path 35328"
          d="M863.869,29.11A3.167,3.167,0,0,0,861.1,30l-.118-.13a3.329,3.329,0,0,1,2.926-.944l-.038.172Z"
          transform="translate(-3134.721 -3007.946)"
          fill="#b8886c"
        />
        <path
          id="Path_35329"
          data-name="Path 35329"
          d="M861.59,63.612s-.628,2.794-1.76,3.7l-.128-.165c1.074-.864,1.685-3.58,1.687-3.587Zm.019-.708a2.164,2.164,0,0,1,2.66-1.683l-.05.2a1.954,1.954,0,0,0-2.4,1.527Zm.378,9.085s3.394-.1,5.064-1.529l.132.163c-1.723,1.47-5.192,1.576-5.192,1.576v-.21ZM859.6,67.732s1.133,2.3,6.648,2.183v.21c-5.645.123-6.832-2.289-6.844-2.3l.184-.1Z"
          transform="translate(-3133.513 -3032.593)"
          fill="#f05d84"
        />
        <path
          id="Path_35330"
          data-name="Path 35330"
          d="M870.38,124.69a27.376,27.376,0,0,1,6.136-2.82l.054.2a27.129,27.129,0,0,0-6.079,2.8l-.108-.179Zm7.3-1.215a7.8,7.8,0,0,1-3.242.493l.024-.21a7.64,7.64,0,0,0,3.146-.472l.073.2Z"
          transform="translate(-3141.902 -3078.99)"
          fill="#f05d84"
        />
        <path
          id="Path_35331"
          data-name="Path 35331"
          d="M868,138.1a6.562,6.562,0,0,1,4.394-1.475v.21a6.3,6.3,0,0,0-4.248,1.416L868,138.1Zm7.358-1.869a10.184,10.184,0,0,1-.835,4.3l-.182-.1a9.945,9.945,0,0,0,.81-4.193h.208Zm-7.148,2.655s3.4-.927,5.477.21l-.1.186c-2.006-1.1-5.317-.2-5.326-.191l-.054-.205Zm.045-2.018A6.442,6.442,0,0,1,872.378,135l.014.212a6.206,6.206,0,0,0-3.993,1.8l-.153-.142Z"
          transform="translate(-3140.084 -3089.021)"
          fill="#2b2b2b"
        />
        <path
          id="Path_35332"
          data-name="Path 35332"
          d="M860.949,196.9s.335.642,1.546.42l.038.205c-1.359.25-1.761-.519-1.763-.522l.179-.1Z"
          transform="translate(-3134.56 -3136.314)"
          fill="#e69f91"
        />
        <path
          id="Path_35333"
          data-name="Path 35333"
          d="M901.053,194.74a1.449,1.449,0,0,0-1.076.847l-.187-.09a1.673,1.673,0,0,1,1.237-.968Z"
          transform="translate(-3164.372 -3134.503)"
          fill="#db8777"
        />
        <path
          id="Path_35334"
          data-name="Path 35334"
          d="M898.4,95.524a3.066,3.066,0,0,1,1.291-.354v.21a2.833,2.833,0,0,0-1.194.328l-.1-.184Z"
          transform="translate(-3163.31 -3058.591)"
          fill="#e69f91"
        />
        <path
          id="Path_35335"
          data-name="Path 35335"
          d="M829.45,266.522l-.014.047c-.207.708,1.555,1.456,2.535,1.517s3.12-.078,3.939,0a4.484,4.484,0,0,0,2.136-.33c.366-.191.333-.345.068-.821a1.821,1.821,0,0,1-.134-.335,4.366,4.366,0,0,1-1.831.392,7.486,7.486,0,0,0-2.874.236,6.023,6.023,0,0,1-3.823-.708Z"
          transform="translate(-3110.607 -3189.505)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35336"
          data-name="Path 35336"
          d="M882.09,75.272l1.442-1.444a.3.3,0,0,1,.422,0l2.832,2.775a.3.3,0,0,1,0,.418l-1.442,1.444a.3.3,0,0,1-.42,0l-2.832-2.775a.3.3,0,0,1,0-.418Z"
          transform="translate(-3150.785 -3042.219)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35337"
          data-name="Path 35337"
          d="M883.026,76.21a.059.059,0,0,0,0,.083l2.832,2.775a.057.057,0,0,0,.083,0l1.442-1.442a.059.059,0,0,0,0-.083l-2.832-2.778a.059.059,0,0,0-.083,0Z"
          transform="translate(-3151.551 -3042.988)"
          fill="#374499"
          fillRule="evenodd"
        />
        <path
          id="Path_35338"
          data-name="Path 35338"
          d="M883.66,78.485s1.416-2.4,2.848-2.645a22.463,22.463,0,0,1,1.534,1.888,6.447,6.447,0,0,0-2.532,2.19C884.611,79.3,883.66,78.485,883.66,78.485Z"
          transform="translate(-3152.049 -3043.822)"
          fill="#474646"
          fillRule="evenodd"
        />
        <path
          id="Path_35420"
          data-name="Path 35420"
          d="M799.978,162.592s2.9,7.875,3.655,8.144,1.652-1.166,3.283-1.562a20.5,20.5,0,0,0,7.191-3.021l2.046,2.756a32.48,32.48,0,0,0-4.972,1.614c-1.59.793-5.895,3.955-7.365,4.212s-2.02-.21-3.155-1.416-5.48-8.33-5.48-8.33l4.8-2.409Z"
          transform="translate(-3084.448 -3110.093)"
          fill="#c9a088"
          fillRule="evenodd"
        />
        <path
          id="Path_35421"
          data-name="Path 35421"
          d="M799.978,162.63s.17.472.439,1.18a4.767,4.767,0,0,1-1.93,1.336,5.989,5.989,0,0,0-2.266,1.593c-.616-.994-1.041-1.69-1.041-1.69l4.8-2.409Z"
          transform="translate(-3084.448 -3110.131)"
          fill="#a1745a"
          fillRule="evenodd"
        />
        <path
          id="Path_35422"
          data-name="Path 35422"
          d="M741.742,154.465s-5.234,7.757-5.772,8.967a11,11,0,0,0-.37,3.828c-.019.411-.295,4.413-.609,7.006a45.754,45.754,0,0,1-1.6,6.584l3.12.543s.165-2.065.5-3.729,1.369-3.039,1.959-6.511.222-5.737.64-5.954,6.872-7.141,6.872-7.141l-4.739-3.6Z"
          transform="translate(-3037.24 -3103.889)"
          fill="#d7ad95"
          fillRule="evenodd"
        />
        <path
          id="Path_35423"
          data-name="Path 35423"
          d="M761.2,154.472s-1.1,1.628-2.341,3.511a40.4,40.4,0,0,0,5.17,2.124c1.081-1.152,1.909-2.039,1.909-2.039l-4.739-3.6Z"
          transform="translate(-3056.7 -3103.897)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35424"
          data-name="Path 35424"
          d="M779.752,37.151a2.029,2.029,0,0,0,1.524-.3l-3.7-2.832-.831-.094c.521,1.272,1.607,2.976,3,3.226Z"
          transform="translate(-3070.368 -3011.795)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35425"
          data-name="Path 35425"
          d="M735.68,78s-1.85,4.484-2.211,6.029c-.762.151-3.684-3.606-4.387-4.5a4.845,4.845,0,0,0-1.812.944l.045.033c.5.356,4.8,7.4,6.155,7.273s2.06-.448,3.436-3.54S735.68,78,735.68,78Z"
          transform="translate(-3032.564 -3045.473)"
          fill="#dce0f7"
          fillRule="evenodd"
        />
        <path
          id="Path_35426"
          data-name="Path 35426"
          d="M759.182,78s-.578,1.416-1.152,2.91a27.948,27.948,0,0,1,2.452,3.153C761.681,81.011,759.182,78,759.182,78Z"
          transform="translate(-3056.066 -3045.473)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35427"
          data-name="Path 35427"
          d="M756.72,70.844s2.714,2.992,3.124,4.248,4.6-4.309,4.076-5.765-3.948-3.2-4.7-3.068-2.5,4.583-2.5,4.583Z"
          transform="translate(-3055.065 -3036.498)"
          fill="#f7c96c"
          fillRule="evenodd"
        />
        <path
          id="Path_35428"
          data-name="Path 35428"
          d="M791.516,135.335s1.251,3.684,1.631,4.448c.208.413.436.93.6,1.314-.673.708-2.029,2.022-2.811,2.124-.831.111-2.412,1.612-3.082,2.284l.264.533-1.942-3.908s4.248-7.589,5.336-6.8Z"
          transform="translate(-3077.573 -3089.232)"
          fill="#30267d"
          fillRule="evenodd"
        />
        <path
          id="Path_35429"
          data-name="Path 35429"
          d="M799.175,160c.142.321.236.552.236.552s0,.113-1.237,1.4-1.086-.035-2.648,1.286-1.982,1.7-1.982,1.7l-.264-.533c.67-.673,2.251-2.173,3.082-2.284.781-.1,2.138-1.416,2.811-2.124Z"
          transform="translate(-3082.997 -3108.122)"
          fill="#f7c96c"
          fillRule="evenodd"
        />
        <path
          id="Path_35430"
          data-name="Path 35430"
          d="M760.359,133.43s-.434,1-.9,1.845-.543.432-.909,1.376a7.28,7.28,0,0,1-1.4,1.98l-.434.606.281-.392c.646.319,1.874.9,3.363,1.487l.708-.677-.123.9a28.079,28.079,0,0,0,3.875,1.18,23.38,23.38,0,0,1,3.037-3.858c1.222-1.017,1.329-2.221,1.55-3.738a7.3,7.3,0,0,0-.068-2.643s-8.8.963-8.991,1.942Z"
          transform="translate(-3055.065 -3086.347)"
          fill="#374499"
          fillRule="evenodd"
        />
        <path
          id="Path_35431"
          data-name="Path 35431"
          d="M769.4,133.392s-.406.93-.847,1.758a21.243,21.243,0,0,0,3.6-.149c2.6-.337,3.521-.708,4.413-.833a6.961,6.961,0,0,0,1.975-.972,6.812,6.812,0,0,0-.149-1.746S769.591,132.413,769.4,133.392Z"
          transform="translate(-3064.103 -3086.309)"
          fill="#30267d"
          fillRule="evenodd"
        />
        <path
          id="Path_35432"
          data-name="Path 35432"
          d="M727.334,76.17h0Zm-.052,0Zm-.045,0h0Zm-.047-.012h0Zm-.05-.017-.033-.014Zm-.054-.024-.035-.017Zm-.057-.028-.047-.026Zm-1.794-1.652-.154-.17Zm-.283-.319-.2-.219Zm-.67-.772-.182-.215ZM722.938,71.7l-.149-.191a3.331,3.331,0,0,0,.066-1.949c-.316-.885-.944-1.933-1.135-1.822a1.094,1.094,0,0,0-.25.2s-.411-.189-.543-.05a.366.366,0,0,0-.127.236s-.55-.085-.644.059a.627.627,0,0,0-.054.387.4.4,0,0,0-.394.061c-.189.153-.1.486-.1.486s-.406,0-.368.337.382.762.472,1.229a7.419,7.419,0,0,0,1.416,1.933,4.647,4.647,0,0,1,1.793-.911Z"
          transform="translate(-3026.429 -3037.623)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35433"
          data-name="Path 35433"
          d="M767.71,44.284a5.117,5.117,0,0,1,.823,1.621,10.437,10.437,0,0,1,.389,1.723l2.426-2.492-1.27-3.276L767.72,44.3Z"
          transform="translate(-3063.461 -3017.862)"
          fill="#d7ad95"
          fillRule="evenodd"
        />
        <path
          id="Path_35434"
          data-name="Path 35434"
          d="M767.71,44.238a5.086,5.086,0,0,1,.812,1.569,12.3,12.3,0,0,0,2.216-2.308l-.658-1.7-2.36,2.435Z"
          transform="translate(-3063.461 -3017.815)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35435"
          data-name="Path 35435"
          d="M759.99,20.052l-.024-.035a3.124,3.124,0,0,1-.236-.248c.118-.222.293-.526.543-.911a5.064,5.064,0,0,1,1.72-1.272,1.008,1.008,0,0,0,.488,0,2.358,2.358,0,0,1,2.275.573,1.7,1.7,0,0,0,.236.288,5.744,5.744,0,0,1,1.027,2.6,3.579,3.579,0,0,0,.361,1.579c-.085.168-.18.335-.276.5a3.05,3.05,0,0,0,.333-.4c.033.054.064.1.094.149a3.538,3.538,0,0,1-.708.708,11.3,11.3,0,0,1-1.128,1.449,2.733,2.733,0,0,1-.569-.906c-.264-.625-1.841-.411-1.841-.411L760,20.035Z"
          transform="translate(-3057.364 -2999.26)"
          fill="#4d352f"
          fillRule="evenodd"
        />
        <path
          id="Path_35436"
          data-name="Path 35436"
          d="M754.831,28.26a3,3,0,0,0-.651,1.652c-.156,1.154-1.289,1.489-1.2,1.827s.918.5.918.5-.21.415-.144.491.625,0,.625,0-.564.418-.55.614.177.177.189.34.087.944.54,1.027a4.962,4.962,0,0,0,3.068-1.038,2.722,2.722,0,0,0,.944-1.751,1.221,1.221,0,0,0,.644-.741c.25-.614-.175-1.305-.472-1.272a.8.8,0,0,0-.708.418,8.4,8.4,0,0,1-.413,1.416c-.108.021-.524-.026-.51-.146s.257-1.888.217-2.124-.441-.432-.564-.573a14.321,14.321,0,0,0-1.956-.621Z"
          transform="translate(-3052.205 -3007.471)"
          fill="#d7ad95"
          fillRule="evenodd"
        />
        <path
          id="Path_35437"
          data-name="Path 35437"
          d="M760.7,27.267h-.04v.017h0V27.3h0a7.377,7.377,0,0,1,2.022.769c.472.4.564.512.51,1.019a.788.788,0,0,0,.092.547,11.93,11.93,0,0,0,.132-1.477c-.04-.236-.441-.432-.564-.573a14.329,14.329,0,0,0-1.956-.621Z"
          transform="translate(-3058.075 -3006.478)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35438"
          data-name="Path 35438"
          d="M768.96,57.539s.109.918.449,4.937.925,6.39,1.522,7.629,1.385,1.914,1.385,2.317-3.443,1.213-6.058,1.482-4.363.809-4.363.4-.389-3.5-.444-4.581-1.222-4.444-1.494-7.731a7.49,7.49,0,0,1,1.005-4.956c0-.918,2.228-3.372,4.142-3.915s3.776.491,3.856,4.415Z"
          transform="translate(-3057.509 -3026.366)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35439"
          data-name="Path 35439"
          d="M784.193,88.71c.028.326.059.677.09,1.055.342,4.012.925,6.391,1.522,7.629s1.385,1.914,1.385,2.317c0,.038-.031.078-.087.123a2.5,2.5,0,0,0-1.619-.076c-1.277.43-4.776.566-5.6-.295s-.359-.97-.359-.97,2.851.3,3.832.3-.982-.894-1.527-1.083a4.123,4.123,0,0,1-2.261-1.841c-.437-1.005,2.438.708,2.815.628s-1.267-1.1-2.11-2.048.436-1.293,1.732-.054,2.077.333,2.077,0a12.226,12.226,0,0,1-.727-3.176A5.45,5.45,0,0,1,784.193,88.71Z"
          transform="translate(-3072.384 -3053.656)"
          fill="#f7c96c"
          fillRule="evenodd"
        />
        <path
          id="Path_35440"
          data-name="Path 35440"
          d="M783.727,58.868s.064.536.236,2.579l-.517-.486s-3.488-1.029-4.151-3.431c-.25-.392.472-2.969,2.341-2.789a3.449,3.449,0,0,1,.772.179c.753.665,1.267,1.921,1.31,3.948Z"
          transform="translate(-3072.276 -3027.696)"
          fill="#e8ac33"
          fillRule="evenodd"
        />
        <path
          id="Path_35441"
          data-name="Path 35441"
          d="M726.478,69.312a4.759,4.759,0,0,1,.368,1.015.354.354,0,0,1-.08.175.558.558,0,0,1-.165.139.4.4,0,0,1-.217.05.262.262,0,0,1-.139-.054,5.9,5.9,0,0,1-.6-1.123l.151-.064a6.211,6.211,0,0,0,.555,1.06.108.108,0,0,0,.054.021.218.218,0,0,0,.121-.033.365.365,0,0,0,.118-.1.192.192,0,0,0,.042-.09,5.134,5.134,0,0,0-.354-.944l.151-.066Z"
          transform="translate(-3031.319 -3038.826)"
          fill="#a1745a"
        />
        <path
          id="Path_35442"
          data-name="Path 35442"
          d="M720.2,74.277s.3.573.536.522a.277.277,0,0,0,.068-.026.087.087,0,0,0,.035-.052.216.216,0,0,0,0-.094,1.014,1.014,0,0,0-.156-.311c-.172-.25-.337-.505-.337-.507l.137-.087s.174.267.335.5a1.2,1.2,0,0,1,.182.37.366.366,0,0,1,0,.168.236.236,0,0,1-.1.144.355.355,0,0,1-.125.052c-.352.078-.708-.6-.708-.6l.144-.076Z"
          transform="translate(-3027.063 -3042.202)"
          fill="#a1745a"
        />
        <path
          id="Path_35443"
          data-name="Path 35443"
          d="M723.246,71.58a7.232,7.232,0,0,1,.522.979.619.619,0,0,1,.031.1.287.287,0,0,1,0,.085.3.3,0,0,1-.191.274.472.472,0,0,1-.2.035.533.533,0,0,1-.236-.068l.08-.142a.379.379,0,0,0,.163.047.285.285,0,0,0,.127-.021.161.161,0,0,0,.076-.066.144.144,0,0,0,.019-.066q0-.021,0-.042a.294.294,0,0,0-.026-.083,7.765,7.765,0,0,0-.507-.944l.139-.083Z"
          transform="translate(-3029.379 -3040.568)"
          fill="#a1745a"
        />
        <path
          id="Path_35444"
          data-name="Path 35444"
          d="M726.006,74.18s.208.529-.253.642-.493-.236-.493-.236l.163-.014s.014.165.29.1.139-.422.139-.422l.153-.061Z"
          transform="translate(-3031.029 -3042.554)"
          fill="#a1745a"
        />
        <path
          id="Path_35445"
          data-name="Path 35445"
          d="M731.452,75.987a.762.762,0,0,0-.592-.314V75.51a.944.944,0,0,1,.741.389l-.139.087Z"
          transform="translate(-3035.307 -3043.57)"
          fill="#a1745a"
        />
        <path
          id="Path_35446"
          data-name="Path 35446"
          d="M759.962,36.251a.323.323,0,0,1-.13.632.318.318,0,0,1-.236-.378.323.323,0,0,1,.378-.255Z"
          transform="translate(-3057.256 -3013.572)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35447"
          data-name="Path 35447"
          d="M759.74,33.8a1.507,1.507,0,0,1,1.272.708l-.264.13a1.222,1.222,0,0,0-1.008-.547Z"
          transform="translate(-3057.372 -3011.703)"
        />
        <path
          id="Path_35448"
          data-name="Path 35448"
          d="M761.122,36.833a.08.08,0,0,1,.064.1.083.083,0,1,1-.16-.033.08.08,0,0,1,.1-.064Z"
          transform="translate(-3058.35 -3014.02)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35449"
          data-name="Path 35449"
          d="M779.78,56.632s.064-2.209,1.531-2.815c1.416-.583,2.067-.281,5.347,1.609l-1.6,2.867v.944l4.821,1.947-5.192-2.1s-3.762-.236-4.9-2.45Zm17.227,10.761a1.371,1.371,0,0,0,.514.526,4.516,4.516,0,0,1,1.522,1.968c.231.755-.142.835-.137,1.076s-.019.406-.236.533a1.336,1.336,0,0,0-.453.363,1.376,1.376,0,0,1-.484.349,2.675,2.675,0,0,1-.909,0,1.655,1.655,0,0,1-.788-.975,7.308,7.308,0,0,1-.354-1.765,8.709,8.709,0,0,0-.455-.979,6.846,6.846,0,0,0,1.767-1.1Zm-2.124.448-.087-.156Zm-.208-.363-.1-.168Zm-.387-.628-.175-.262Zm-.441-.642-.123-.163Zm-.68-.831-.066-.071Zm-.073-.078-.132-.135Zm-.151-.151-.061-.061Z"
          transform="translate(-3072.683 -3026.792)"
          fill="#d7ad95"
          fillRule="evenodd"
        />
        <path
          id="Path_35450"
          data-name="Path 35450"
          d="M803.75,61.485l.411.236c3.639,2.1,5.159,3.54,6.11,5.064a30.739,30.739,0,0,1,2.568,4.163,14.825,14.825,0,0,0,1.27,2.5,7.193,7.193,0,0,1-1.815,1.149,10.942,10.942,0,0,0-2.457-3.58,20.054,20.054,0,0,1-2.865-3.776l-4.821-1.947v-.944l1.6-2.867Z"
          transform="translate(-3089.774 -3032.851)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35451"
          data-name="Path 35451"
          d="M803.742,61.5l.411.236c.37.215.722.422,1.05.623a7.179,7.179,0,0,1-.583,2.063c-.3.547-.625,1.152-.861,1.543l-1.619-.654v-.944l1.6-2.867Z"
          transform="translate(-3089.766 -3032.866)"
          fill="#dce0f7"
          fillRule="evenodd"
        />
        <path
          id="Path_35452"
          data-name="Path 35452"
          d="M779.358,56.2c-.326-.333-.142-3,1.725-3.2s5.17,1.687,5.576,1.919l.574.335a8.6,8.6,0,0,1-.647,2.483,21.11,21.11,0,0,1-1.071,1.86c-2.176-1.34-5.052-1.232-6.157-3.391Z"
          transform="translate(-3072.261 -3026.363)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35453"
          data-name="Path 35453"
          d="M762.479,80.624s0,3.967,7.316,6.27l-.049.156c-7.438-2.339-7.436-6.412-7.436-6.424Zm-.859-2.655a7,7,0,0,1,1.369-2.959l.135.092a6.856,6.856,0,0,0-1.34,2.884Zm9.642,7.242a2.775,2.775,0,0,0,.647,2.641l-.121.109a2.95,2.95,0,0,1-.682-2.806l.156.052Zm-5.461-4.6s.512,2.9,4.439,4.739l-.068.149c-4.012-1.888-4.529-4.847-4.531-4.857Zm-3.54-2.334a14.008,14.008,0,0,0,6.914.252l.043.156a14.156,14.156,0,0,1-7-.253Zm1.2,7.091a9.317,9.317,0,0,0,5.14,2.36l-.023.16a9.466,9.466,0,0,1-5.234-2.409l.118-.111Zm.555,3.8a10.52,10.52,0,0,0,7.136-.911l.071.146a10.643,10.643,0,0,1-7.257.911l.05-.156Z"
          transform="translate(-3058.808 -3043.188)"
          fill="#e8ac33"
        />
        <path
          id="Path_35454"
          data-name="Path 35454"
          d="M780.085,53.866a1.818,1.818,0,0,0-1.062,1.942l-.163.026a1.988,1.988,0,0,1,1.159-2.124l.066.146Zm.076,1.888a7.83,7.83,0,0,1,3.559,2.261l-.132.094a7.634,7.634,0,0,0-3.474-2.2Zm1.18-1.159a8.805,8.805,0,0,1,3.729,4.127l-.156.054a8.587,8.587,0,0,0-3.651-4.038Z"
          transform="translate(-3071.957 -3026.915)"
          fill="#e8ac33"
        />
        <path
          id="Path_35455"
          data-name="Path 35455"
          d="M774.825,150.423s-3.967,1.206-5.078,2.744l-.132-.094c1.142-1.583,5.149-2.8,5.161-2.806Zm-7.337-2a8.505,8.505,0,0,0,6.466-.132l.061.151a8.7,8.7,0,0,1-6.608.127l.071-.146Zm-.722,1.265s.418.861,3.63.109l.038.158c-3.337.784-3.811-.186-3.814-.189Z"
          transform="translate(-3062.628 -3099.176)"
          fill="#30267d"
        />
        <path
          id="Path_35456"
          data-name="Path 35456"
          d="M757,162.63l-.281.392a11.674,11.674,0,0,0,2.145,1.18c1.3.552.991.406.991.406l.507-.472c-1.489-.583-2.716-1.18-3.363-1.487Zm3.955,1.713-.08.59s2.527.864,2.8.93.774.236.774.236.146-.236.385-.581a28.1,28.1,0,0,1-3.875-1.18Z"
          transform="translate(-3055.065 -3110.131)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35457"
          data-name="Path 35457"
          d="M798.27,159.6s2.742-.3,3.266-2.785l.16.033c-.547,2.6-3.4,2.912-3.408,2.912Z"
          transform="translate(-3086.809 -3105.692)"
          fill="#374499"
        />
        <path
          id="Path_35458"
          data-name="Path 35458"
          d="M775.365,36.943s-.076-.37-.316-.453a.531.531,0,0,0-.592.288c-.061.165-.269.573-.2.687s.547-.2.547.052.189.333.312.042-.472-.276-.455-.453.387-.328.5-.1S775.365,36.943,775.365,36.943Z"
          transform="translate(-3068.451 -3013.749)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35459"
          data-name="Path 35459"
          d="M753.6,104.33a2.833,2.833,0,0,1,.656,1.388l-.163.017a2.686,2.686,0,0,0-.616-1.3Z"
          transform="translate(-3052.59 -3065.589)"
          fill="#bec5ed"
        />
        <path
          id="Path_35460"
          data-name="Path 35460"
          d="M823,81.628a2.124,2.124,0,0,1,1.034-.968l.064.151a1.906,1.906,0,0,0-.944.885L823,81.628Z"
          transform="translate(-3105.703 -3047.505)"
          fill="#bec5ed"
        />
        <path
          id="Path_35461"
          data-name="Path 35461"
          d="M823.763,195.483a1.952,1.952,0,0,0-.453,1.01l-.16-.028a2.134,2.134,0,0,1,.5-1.1Z"
          transform="translate(-3105.818 -3135.137)"
          fill="#a1745a"
        />
        <path
          id="Path_35462"
          data-name="Path 35462"
          d="M759.667,19.343s-.236-.786,1.086-1.864c2.348-1.933,6.624.066,5.711,4.557,0,0-3.174-1.152-4.264-1.652s-2.532-1.045-2.532-1.045Zm1.482-1.768a6.171,6.171,0,0,1-.38.944,4.819,4.819,0,0,0-.288.623l-.512-.2A2.261,2.261,0,0,1,761.149,17.575Z"
          transform="translate(-3057.304 -2998.672)"
          fill="#dce0f7"
          fillRule="evenodd"
        />
        <path
          id="Path_35463"
          data-name="Path 35463"
          d="M767.377,16.765a2.245,2.245,0,0,1,.33.035,3.32,3.32,0,0,0-2.268,2.832l-.139-.026a3.478,3.478,0,0,1,2.077-2.846Zm1.534.453a4.039,4.039,0,0,1,.4.26,3.571,3.571,0,0,1,.045,3.679l-.127-.066C770.216,18.9,769.364,17.673,768.911,17.218Z"
          transform="translate(-3061.62 -2998.685)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <path
          id="Path_35464"
          data-name="Path 35464"
          d="M759.711,26.293a.946.946,0,0,1,.078-.453l5.234,2.206c-.035.215,1.484.944,1.484.944s-3.174-1.152-4.264-1.652S759.711,26.293,759.711,26.293Z"
          transform="translate(-3057.348 -3005.622)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35465"
          data-name="Path 35465"
          d="M782.042,35.291a16.345,16.345,0,0,1,1.963,1.874c0,.208-.149.236-.472.123a13.256,13.256,0,0,1-3.151-2.457c-.12-.177-.1-.368,0-.328.281.118,1.666.788,1.666.788Z"
          transform="translate(-3073.08 -3012.236)"
          fill="#edf0ff"
          fillRule="evenodd"
        />
        <path
          id="Path_35466"
          data-name="Path 35466"
          d="M760.315,20.74a2.831,2.831,0,0,0-.642.255c-.236.151-.028.149-.028.149s-.437.028-.472.177.189.163.189.163c-.333.092-.878.448-.67.838,0,0-.047-.205.422-.208s1.194-1.373,1.194-1.373Z"
          transform="translate(-3056.536 -3001.726)"
          fill="#4d352f"
          fillRule="evenodd"
        />
        <path
          id="Path_35467"
          data-name="Path 35467"
          d="M715.151,254.561s.359-.5.585-.4.4.821.293,1.215.708,1.126,1.381,1.416,1.036-.684,1.107-.661.588.085.439.831a16.291,16.291,0,0,1-.524,2.164c-.208.557-.3.661-.472.632a27.294,27.294,0,0,1-2.766-.88c-1.033-.4-2.6-.859-3.405-1.13a5.261,5.261,0,0,1-2.124-1.083c-.366-.451.125-1.083.762-1.06a12.617,12.617,0,0,0,3.237-.236,3.911,3.911,0,0,0,1.492-.8Z"
          transform="translate(-3019.018 -3180.052)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35468"
          data-name="Path 35468"
          d="M718.542,264.607c-.033.113-.068.219-.1.319-.208.557-.3.661-.472.632a27.255,27.255,0,0,1-2.766-.88c-1.034-.4-2.6-.859-3.405-1.13a5.262,5.262,0,0,1-2.124-1.083.561.561,0,0,1,.019-.725,6.2,6.2,0,0,0,2.275,1.428,25.941,25.941,0,0,0,4.38,1.18c.767.116,1.673.118,2.2.267Z"
          transform="translate(-3019.024 -3185.852)"
          fill="#4f4599"
          fillRule="evenodd"
        />
        <path
          id="Path_35469"
          data-name="Path 35469"
          d="M723.331,258.985a1.192,1.192,0,0,1,.5.944l-.175-.019a1,1,0,0,0-.422-.786l.1-.144Z"
          transform="translate(-3029.478 -3183.744)"
          fill="#f5be51"
        />
        <path
          id="Path_35470"
          data-name="Path 35470"
          d="M725.179,258.38a1.07,1.07,0,0,1,.578.776l-.175.016a.89.89,0,0,0-.472-.635l.075-.158Z"
          transform="translate(-3030.915 -3183.285)"
          fill="#f5be51"
        />
        <path
          id="Path_35471"
          data-name="Path 35471"
          d="M727.076,257.57a1.066,1.066,0,0,1,.571.845h-.175a.885.885,0,0,0-.472-.689l.08-.156Z"
          transform="translate(-3032.358 -3182.667)"
          fill="#f5be51"
        />
        <path
          id="Path_35472"
          data-name="Path 35472"
          d="M724.368,253.851a1.9,1.9,0,0,0-.689-.224,2.566,2.566,0,0,0-.708,0,4.6,4.6,0,0,0,.774.139c.2.026.408.054.628.092Zm-2.178.194a9.824,9.824,0,0,1,1.248-.149,3.277,3.277,0,0,1-.531-.111.326.326,0,0,1-.172-.1.111.111,0,0,1,.017-.163.3.3,0,0,1,.125-.059,2.4,2.4,0,0,1,.824,0,1.964,1.964,0,0,1,.25.045.786.786,0,0,0-.182-.085.826.826,0,0,1-.418-.269.238.238,0,0,1-.05-.109.13.13,0,0,1,.057-.132.293.293,0,0,1,.219-.021h0a4.148,4.148,0,0,1,.9.411,1.536,1.536,0,0,1,.519.493,5.419,5.419,0,0,1,.26.491l.031.035h0a.2.2,0,0,1,.024.073l-.042.123-.09-.078c-1.232-.592-2.2,1.416-2.2,1.416l-.158-.076s.793-1.64,1.914-1.626a6.863,6.863,0,0,0-2.494.061l-.035-.172Zm2.19-.6a3.774,3.774,0,0,0-.819-.387.3.3,0,0,1-.064,0,.622.622,0,0,0,.319.2,5.29,5.29,0,0,1,1.1.757l-.076-.13a1.367,1.367,0,0,0-.472-.432Z"
          transform="translate(-3028.683 -3179.082)"
          fill="#f5be51"
        />
        <path
          id="Path_35473"
          data-name="Path 35473"
          d="M751.114,197a4.624,4.624,0,0,0,1.619.522v.212a4.849,4.849,0,0,1-1.723-.55Z"
          transform="translate(-3050.702 -3136.39)"
          fill="#b8886c"
          fillRule="evenodd"
        />
        <path
          id="Path_35474"
          data-name="Path 35474"
          d="M871.609,174.915s-.578.236-.708.026.111-.906.413-1.18.012-1.326-.4-1.928-1.244.024-1.291-.033-.448-.385.081-.944a16.227,16.227,0,0,1,1.614-1.543c.472-.361.616-.4.748-.278a27.925,27.925,0,0,1,1.857,2.216c.654.892,1.73,2.124,2.258,2.77a5.134,5.134,0,0,1,1.2,2.044c.061.573-.691.847-1.218.486a12.074,12.074,0,0,0-2.862-1.52,3.918,3.918,0,0,0-1.69-.123Z"
          transform="translate(-3141.18 -3114.977)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35475"
          data-name="Path 35475"
          d="M876.28,169.555c.09-.078.177-.149.26-.212.472-.361.616-.4.748-.279a27.863,27.863,0,0,1,1.857,2.216c.654.892,1.73,2.124,2.258,2.771a5.136,5.136,0,0,1,1.2,2.044.562.562,0,0,1-.406.6,6.136,6.136,0,0,0-1.147-2.416,25.527,25.527,0,0,0-3.068-3.323c-.583-.507-1.348-.994-1.711-1.4Z"
          transform="translate(-3146.402 -3115)"
          fill="#4f4599"
          fillRule="evenodd"
        />
        <path
          id="Path_35476"
          data-name="Path 35476"
          d="M887.218,192.814a1.183,1.183,0,0,1,.1-1.064l.137.111a1,1,0,0,0-.071.887Z"
          transform="translate(-3154.7 -3132.379)"
          fill="#f5be51"
        />
        <path
          id="Path_35477"
          data-name="Path 35477"
          d="M885.222,192.713a1.067,1.067,0,0,1-.066-.963l.156.078a.873.873,0,0,0,.061.793l-.151.092Z"
          transform="translate(-3153.127 -3132.379)"
          fill="#f5be51"
        />
        <path
          id="Path_35478"
          data-name="Path 35478"
          d="M883.161,192.195a1.054,1.054,0,0,1-.024-1.015l.149.094a.9.9,0,0,0,.028.835Z"
          transform="translate(-3151.552 -3131.944)"
          fill="#f5be51"
        />
        <path
          id="Path_35479"
          data-name="Path 35479"
          d="M878,190.476a1.9,1.9,0,0,0,.46.555,2.521,2.521,0,0,0,.6.387,4.688,4.688,0,0,0-.578-.531c-.151-.125-.316-.262-.472-.411Zm1.942,1a9.35,9.35,0,0,1-1.135-.538,3.009,3.009,0,0,1,.389.375.342.342,0,0,1,.09.177.113.113,0,0,1-.1.13.3.3,0,0,1-.137-.017,2.518,2.518,0,0,1-.708-.434,1.878,1.878,0,0,1-.184-.172.866.866,0,0,0,.1.17.805.805,0,0,1,.21.451.268.268,0,0,1-.019.118.132.132,0,0,1-.12.08.3.3,0,0,1-.2-.1h0a3.965,3.965,0,0,1-.536-.824,1.537,1.537,0,0,1-.172-.7c.016-.29.033-.458.047-.55a.348.348,0,0,1,0-.047h.012c0-.066.021-.073.021-.073l.1-.083.033.113c.708,1.156,2.619-.016,2.624-.021l.092.151s-1.555.96-2.492.349a6.733,6.733,0,0,0,2.136,1.279l-.064.163Zm-2.173-.658a3.742,3.742,0,0,0,.472.762.4.4,0,0,1,.05.042.629.629,0,0,0-.163-.337,5.188,5.188,0,0,1-.517-1.227v.146a1.342,1.342,0,0,0,.156.614Z"
          transform="translate(-3147.281 -3130.614)"
          fill="#f5be51"
        />
        <path
          id="Path_35480"
          data-name="Path 35480"
          d="M841.99,108.142l1.966-1.152.352.6-1.97,1.152-.347-.6Z"
          transform="translate(-3120.212 -3067.622)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35481"
          data-name="Path 35481"
          d="M843.8,108.457l-.444-.783a.17.17,0,0,1,.064-.236l1.086-.614a.165.165,0,0,1,.236.061l.444.784a.167.167,0,0,1-.061.236l-1.088.614a.172.172,0,0,1-.236-.061Z"
          transform="translate(-3121.233 -3067.474)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35482"
          data-name="Path 35482"
          d="M845.671,108.454l-.418-.734-1.034.583.418.736,1.034-.585Z"
          transform="translate(-3121.916 -3068.179)"
          fill="#e84638"
          fillRule="evenodd"
        />
        <path
          id="Path_35483"
          data-name="Path 35483"
          d="M845.671,108.454l-.418-.734-1.034.583.418.736,1.034-.585Z"
          transform="translate(-3121.916 -3068.179)"
          fill="#e84638"
          fillRule="evenodd"
        />
        <path
          id="Path_35570"
          data-name="Path 35570"
          d="M598.86,201.67s-.153-.236.347-.295a6.406,6.406,0,0,0,1.709-.26c.425-.205.708-.347.859-.068a20.4,20.4,0,0,1,.75,3.46c.153,1.22.076,2.143.127,2.8a5.464,5.464,0,0,1-.819,2.782c-.4.356-.965-.382-1.1-1.229a5.248,5.248,0,0,0-.342-1.716c-.269-.536-.923-.968-1.154-1.336s-.6-.446-.663-.708.578-.472.878-1.451-.078-1.888-.592-1.982Z"
          transform="translate(-2934.236 -3139.364)"
          fill="#fca695"
          fillRule="evenodd"
        />
        <path
          id="Path_35571"
          data-name="Path 35571"
          d="M600.69,218.138c-.042-.333-.059-.576-.1-.8l.378-.621-.564.035-.014-.031a2.1,2.1,0,0,0-.312-.446l1.815-.116Zm-.734-1.994c-.123-.127-.252-.248-.371-.363l.748-1.378-1.489.592a.625.625,0,0,1-.271-.312.163.163,0,0,1,.017-.123l2.832-1.13-1.475,2.714Z"
          transform="translate(-2934.234 -3148.943)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35572"
          data-name="Path 35572"
          d="M607.977,201.125a.794.794,0,0,0,.113-.047c.427-.2.708-.347.859-.068a20.394,20.394,0,0,1,.751,3.46c.153,1.22.075,2.143.127,2.8a5.463,5.463,0,0,1-.819,2.782.373.373,0,0,1-.514-.028,5.251,5.251,0,0,0,.944-3.686,14.931,14.931,0,0,0-1.468-5.211Z"
          transform="translate(-2941.417 -3139.335)"
          fill="#f9bfb3"
          fillRule="evenodd"
        />
        <path
          id="Path_35574"
          data-name="Path 35574"
          d="M651.759,219.532a8.2,8.2,0,0,0,1.378,2c.555.4,1.069.708,1.069.708s.118,1.522-.555,1.86a19.889,19.889,0,0,1-2.124.786l-.92-.4s.236-1.225-.028-1.631-1.279-1.727-1.279-1.727l2.456-1.607Z"
          transform="translate(-2972.994 -3153.596)"
          fill="#e69685"
          fillRule="evenodd"
        />
        <path
          id="Path_35575"
          data-name="Path 35575"
          d="M651.767,219.57s.236.444.524.923a6.609,6.609,0,0,1-2.308,1.574l-.673-.89Z"
          transform="translate(-2973.002 -3153.634)"
          fill="#bd6e5e"
          fillRule="evenodd"
        />
        <path
          id="Path_35576"
          data-name="Path 35576"
          d="M653.809,236.552s-.321-.96-.056-1.01a3.176,3.176,0,0,1,1.435.392c.066.139-.135.408-.4.566A2.162,2.162,0,0,1,653.809,236.552Z"
          transform="translate(-2976.316 -3165.833)"
          fill="#c96a16"
          fillRule="evenodd"
        />
        <path
          id="Path_35577"
          data-name="Path 35577"
          d="M649.514,229.606a.5.5,0,0,1,.555.132,4.536,4.536,0,0,1,.708.741,3.882,3.882,0,0,0,.708.724c.3.25.436.809-.552,1.794s-2.178,1.994-2.563,2.473a7.418,7.418,0,0,1-3.332,1.336c-1.015.1-1.31-.618-1.18-.871s1.692-1.326,1.945-2.143.174-1.994.448-2.025.784.286,1.043-.08a.944.944,0,0,1,1.055-.429c.522.08,1.2,0,1.324-.552a1.887,1.887,0,0,0-.144-1.1Z"
          transform="translate(-2968.814 -3161.275)"
          fill="#e67e22"
          fillRule="evenodd"
        />
        <path
          id="Path_35578"
          data-name="Path 35578"
          d="M652.263,235.86l.172.149c.3.25.437.809-.552,1.793s-2.178,1.994-2.563,2.473a7.419,7.419,0,0,1-3.332,1.336,1.262,1.262,0,0,1-.909-.2,5.425,5.425,0,0,0,3.249-1.168c1.541-1.317,2.041-2.45,2.676-2.914C651.6,236.9,652.051,236.759,652.263,235.86Z"
          transform="translate(-2969.77 -3166.08)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35579"
          data-name="Path 35579"
          d="M651.761,243.292a1.675,1.675,0,0,1,.883.38l-.16.137a1.47,1.47,0,0,0-.753-.311Zm.293-1.232a1.7,1.7,0,0,1,.885.38l-.163.137a1.473,1.473,0,0,0-.75-.312Zm-.109.43a1.712,1.712,0,0,1,.885.382l-.163.137a1.463,1.463,0,0,0-.75-.314Zm-.059.38a1.68,1.68,0,0,1,.885.38l-.163.137a1.452,1.452,0,0,0-.75-.311Z"
          transform="translate(-2974.851 -3170.817)"
          fill="#c96a16"
        />
        <path
          id="Path_35580"
          data-name="Path 35580"
          d="M650.039,240.562s.116,0,.156-.394a1.094,1.094,0,0,0-.179-.658h0a1.322,1.322,0,0,1,.106.616c-.014.054-.028.1-.042.151a1.312,1.312,0,0,0-.068.3h.014Zm.727-.547a2.3,2.3,0,0,0-.8-1.371,2.567,2.567,0,0,0,.071.38,1.649,1.649,0,0,0,.168.392,1.246,1.246,0,0,1,.2.772c-.047.472-.262.562-.345.578-.149.04-.236,0-.26-.116a.879.879,0,0,1,.064-.437c.014-.052.031-.106.04-.144a1.705,1.705,0,0,0-.151-.649,2.832,2.832,0,0,1-.165-.6,1.076,1.076,0,0,1,0-.151.585.585,0,0,0-.368.489,4.442,4.442,0,0,0,0,.472c0,.441.019.843-.309,1.031-.255.144-.4.12-.472,0-.146-.236.179-.944.26-1.116v-.016a3.744,3.744,0,0,1,.189-.677.791.791,0,0,1,.392-.441,1.434,1.434,0,0,0-.826.427l-.158-.142s.734-.76,1.416-.392a1.206,1.206,0,0,1,.236.087h.04v.017A2.367,2.367,0,0,1,651,239.982l-.213.035Zm-.758.566Zm-1-1.416c-.035.123-.064.257-.094.4a.27.27,0,0,1-.024.057c-.071.156-.359.786-.271.927,0,.017.061,0,.182-.068.222-.127.212-.472.205-.847v-.472Z"
          transform="translate(-2972.23 -3167.87)"
          fill="#c96a16"
        />
        <path
          id="Path_35581"
          data-name="Path 35581"
          d="M592.968,238.375a6.03,6.03,0,0,0,.382,2.272c.382.557.739,1.031.739,1.031s-.444,1.465-1.2,1.558a20.2,20.2,0,0,1-2.275.028l-.708-.684s.675-1.067.569-1.539-.578-2.051-.578-2.051l3.068-.621Z"
          transform="translate(-2927.612 -3167.998)"
          fill="#fca695"
          fillRule="evenodd"
        />
        <path
          id="Path_35582"
          data-name="Path 35582"
          d="M592.976,238.39a8.676,8.676,0,0,0,.212,1.874,8.992,8.992,0,0,1-2.806.422c-.172-.644-.472-1.675-.472-1.675l3.068-.621Z"
          transform="translate(-2927.619 -3168.012)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35583"
          data-name="Path 35583"
          d="M589.677,252.181s.049-1.008.316-.965a3.126,3.126,0,0,1,1.2.845c.019.151-.274.34-.578.4a2.192,2.192,0,0,1-.944-.278Z"
          transform="translate(-2927.436 -3177.811)"
          fill="#c96a16"
          fillRule="evenodd"
        />
        <path
          id="Path_35584"
          data-name="Path 35584"
          d="M586.579,38.74a5.865,5.865,0,0,0,.642,2.4,9.283,9.283,0,0,0,.774,1.048l-3.183,1.1a19.1,19.1,0,0,1-1.133-2.8C583.473,39.58,586.579,38.74,586.579,38.74Z"
          transform="translate(-2922.851 -3015.478)"
          fill="#fca695"
          fillRule="evenodd"
        />
        <path
          id="Path_35585"
          data-name="Path 35585"
          d="M586.548,38.74s.014.283.075.677a15.372,15.372,0,0,1-2.6,2.209,8.011,8.011,0,0,1-.389-1.14c-.205-.911,2.907-1.746,2.907-1.746Z"
          transform="translate(-2922.821 -3015.478)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35586"
          data-name="Path 35586"
          d="M566.79,21.776a1.93,1.93,0,0,1-1.593.8c-1.253.132-3.339-.335-2.74-1.534.184-.042,3.729.682,4.311.722h.021Z"
          transform="translate(-2906.564 -3001.956)"
          fill="#d49b2a"
          fillRule="evenodd"
        />
        <path
          id="Path_35587"
          data-name="Path 35587"
          d="M579.075,15.029c.708.67.373,1,.918,1.314.75.432.512,2.832-.04,3.412-.736.776-.833.621-1.22,1.286a4.516,4.516,0,0,1-1.805-.632,12.332,12.332,0,0,1-1.716-2.678c-.526-.866-1.532.066-1.982-1.362C574.71,15.253,577.126,13.186,579.075,15.029Z"
          transform="translate(-2914.876 -2996.8)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35588"
          data-name="Path 35588"
          d="M572.449,24.73s-.281,1.154-.493,1.685-.793,1.312-.573,1.381l.691.208.052.4.831-.066a4.21,4.21,0,0,1-.618.63c-.18.1.052.33.111.824s.179.708.835.621a4.327,4.327,0,0,0,2.36-1.274c.448-.621.453-.831.453-.831a.821.821,0,0,0,.944-.557c.267-.708,0-1.241-.356-1.305a.833.833,0,0,0-.871.5c-.068.281-.042.632-.316.632a.567.567,0,0,1-.467-.682,2.21,2.21,0,0,0-.016-1.121c-.085-.637-.595-.677-1.3-.677a2.493,2.493,0,0,1-1.258-.37Z"
          transform="translate(-2913.427 -3004.773)"
          fill="#fca695"
          fillRule="evenodd"
        />
        <path
          id="Path_35589"
          data-name="Path 35589"
          d="M574.715,24.73s-.217.892-.415,1.47a6.565,6.565,0,0,0,2.171-1.083c-.151-.014-.319-.017-.5-.017A2.494,2.494,0,0,1,574.715,24.73Z"
          transform="translate(-2915.693 -3004.773)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35590"
          data-name="Path 35590"
          d="M577.014,30.8a.347.347,0,1,1-.354.347A.352.352,0,0,1,577.014,30.8Z"
          transform="translate(-2917.496 -3009.411)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35591"
          data-name="Path 35591"
          d="M576.07,28.541a1.43,1.43,0,0,1,1.255.392l-.212.153a1.132,1.132,0,0,0-.989-.295Z"
          transform="translate(-2917.046 -3007.672)"
        />
        <path
          id="Path_35592"
          data-name="Path 35592"
          d="M573.54,13.613a14.581,14.581,0,0,1,1.416-2.032c.531-.529,1.768-.458,3.153,0a3.8,3.8,0,0,1,2.527,2.164,3.217,3.217,0,0,1,.236,2.185,1.856,1.856,0,0,1-.144.422,19.118,19.118,0,0,0-7.188-2.745Z"
          transform="translate(-2915.112 -2994.445)"
          fill="#e8ac33"
          fillRule="evenodd"
        />
        <path
          id="Path_35593"
          data-name="Path 35593"
          d="M565.508,20.36c.425.191,2.832.979,2.872,1.01a8.815,8.815,0,0,1-3.068.139,4.474,4.474,0,0,1-2.473-.986,14.835,14.835,0,0,0,2.679-.163Z"
          transform="translate(-2906.937 -3001.435)"
          fill="#fad489"
          fillRule="evenodd"
        />
        <path
          id="Path_35594"
          data-name="Path 35594"
          d="M578.3,31.34a.09.09,0,1,1-.09.087A.09.09,0,0,1,578.3,31.34Z"
          transform="translate(-2918.68 -3009.824)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Path_35595"
          data-name="Path 35595"
          d="M591.647,33.732s.078-.222-.217-.4-.536-.068-.687.328-.217.557.05.675a2.389,2.389,0,0,1,.444.21c.186.116.406-.222.208-.4s-.472-.109-.472-.236.276-.236.408-.1S591.647,33.732,591.647,33.732Z"
          transform="translate(-2928.156 -3011.281)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35596"
          data-name="Path 35596"
          d="M620.453,57.87s5.562,2.24,6.121,2.945S625,69.471,625.159,70.1s.614,1.057.453,1.862a1.581,1.581,0,0,1-1.385,1.437c-.691,0-1.078.035-1.277-.29s-.16-.505-.281-.491-.642.529-.85.352.505-1.735.451-2.24a4.643,4.643,0,0,1,.708-1.888,39.078,39.078,0,0,0,.333-6.832c-.238-.479-3.691-1.133-3.691-1.133l.816-3.006Z"
          transform="translate(-2950.318 -3030.093)"
          fill="#fca695"
          fillRule="evenodd"
        />
        <path
          id="Path_35597"
          data-name="Path 35597"
          d="M620.507,57.862s.727.293,1.683.708L620.8,61.1c-.623-.142-1.109-.236-1.109-.236l.817-3.006Z"
          transform="translate(-2950.372 -3030.085)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35598"
          data-name="Path 35598"
          d="M552.515,66.05s-1.338,3.54-2.686,4.212-5.081-5.812-6.006-7.042c-.153-.2-.25,0-.668-.175s-.741-.814-.762-.833-.687-.349-.708-.739.224-.472.224-.472c-.071-.3.262-.472.394-.484-.111-.29.205-.555.337-.606,0,0-.118-.413.172-.6a.472.472,0,0,1,.562.085s.172-.276.361-.236.21.288.26.512.349.295.708.59.269.373.352.708a3.156,3.156,0,0,0,.269.944,41.832,41.832,0,0,0,4.066,4.543l1.052-3.056,2.8.989-.727,1.652Z"
          transform="translate(-2890.774 -3031.073)"
          fill="#db8777"
          fillRule="evenodd"
        />
        <path
          id="Path_35599"
          data-name="Path 35599"
          d="M578.781,56.93s-1.232.354-2.4,3.212c-.358.878-.552,1.416-.649,1.749.3-.057,1.355,1.919,2.508,1.923,1.218,0,.489.337.489.337Z"
          transform="translate(-2916.786 -3029.375)"
          fill="#d49b2a"
          fillRule="evenodd"
        />
        <path
          id="Path_35600"
          data-name="Path 35600"
          d="M597.7,127.27s3.66,10.855,5.7,12.361,3.1,3.962,3.941,5.222,2.023,2.924,2.636,3.9c.81-.392,2.289-1.055,2.8-2.025-.673-1.18-1.536-2.832-2.218-3.986s-2.638-3.75-3.844-5.439-2.6-13.4-2.6-13.4Z"
          transform="translate(-2933.571 -3080.541)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35601"
          data-name="Path 35601"
          d="M597.7,127.27s1.4,4.16,2.952,7.686a31.743,31.743,0,0,0,3.91-7.636c-.276-1.961-.448-3.419-.448-3.419Z"
          transform="translate(-2933.571 -3080.541)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35602"
          data-name="Path 35602"
          d="M583.378,123.9s-1.491,5.109-2.549,6.8-5.565,7.342-5.612,7.788.153.368,1.416,3.25,1.737,7.776,1.938,10.284a8.9,8.9,0,0,1-3.578.547c-1.038-3.221-2.947-9.137-3.681-11.327-1.029-3.068-.979-4.831,0-7.349s4.307-9.987,4.307-9.987Z"
          transform="translate(-2912.835 -3080.541)"
          fill="#5b50ab"
          fillRule="evenodd"
        />
        <path
          id="Path_35603"
          data-name="Path 35603"
          d="M582.431,152.455c-.127.019-.253.033-.373.047a37.779,37.779,0,0,1-1.383-4.186,63.519,63.519,0,0,1-1.81-7.651,3.774,3.774,0,0,0-.213-1.18,2.847,2.847,0,0,0-.491-.982c-.295-.236-.194-.588-.085-.96l.026-.092a.647.647,0,0,1,.316-.4.326.326,0,0,0,.179-.248h0c.057-.418.262-1.956,3.1-5.574a19.588,19.588,0,0,0,3.464-7.36h.373a20.132,20.132,0,0,1-3.54,7.57c-2.785,3.54-2.981,5.01-3.033,5.409h0a.623.623,0,0,1-.33.488.357.357,0,0,0-.175.2l-.028.094c-.076.262-.146.51-.033.6a3.07,3.07,0,0,1,.6,1.14,4.1,4.1,0,0,1,.236,1.284,67.236,67.236,0,0,0,1.8,7.552,33.647,33.647,0,0,0,1.416,4.231Z"
          transform="translate(-2918.496 -3080.518)"
          fill="#e6e6e6"
          fillRule="evenodd"
        />
        <path
          id="Path_35604"
          data-name="Path 35604"
          d="M597.345,123.9s-.172.595-.444,1.444a9.267,9.267,0,0,1-3.25.083c-1.364-.337-4.347.4-4.812.132.441-1,.736-1.652.736-1.652Z"
          transform="translate(-2926.802 -3080.541)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35605"
          data-name="Path 35605"
          d="M584.721,47.515a21.871,21.871,0,0,0-3.318,2.079,6.666,6.666,0,0,0-1.286,5.647,47.984,47.984,0,0,1,1.01,5.31,44.835,44.835,0,0,1,.611,5.739c-.186.578,3.462-.378,5.031,0a13.416,13.416,0,0,0,4.524-.3s-1.515-5.838-1.756-7.349a45.034,45.034,0,0,1-.236-4.883l1.994-3.651c-2.2-1.416-4.179-1.416-6.572-2.6Z"
          transform="translate(-2919.997 -3022.178)"
          fill="#f5be51"
          fillRule="evenodd"
        />
        <path
          id="Path_35606"
          data-name="Path 35606"
          d="M597.153,100.7a9.109,9.109,0,0,1,2.655-.019,13.415,13.415,0,0,0,4.524-.3s-1.515-5.838-1.756-7.349c-.024-.153-.045-.323-.066-.507a13.882,13.882,0,0,1-1.416,3.54c-.857,1.071,1.251.38,1.251,1.024s-1.692,2-2.107,2.388-3.238-.066-3.238.665a1.178,1.178,0,0,0,.158.559Z"
          transform="translate(-2933.037 -3056.566)"
          fill="#e8ac33"
          fillRule="evenodd"
        />
        <path
          id="Path_35607"
          data-name="Path 35607"
          d="M603.361,59.692c-.017-.689-.017-1.218-.017-1.336-1.206-.163-3.45-.581-4.168-1.484-1-1.258-.132-3.372-.132-3.372-1.2.819-.852,3.927.571,4.883A12.011,12.011,0,0,0,603.361,59.692Z"
          transform="translate(-2934.043 -3026.754)"
          fill="#d49b2a"
          fillRule="evenodd"
        />
        <path
          id="Path_35608"
          data-name="Path 35608"
          d="M588.832,73.573c0,.017-1.963,5.428-3.021,6.412l-.156-.165c1.017-.944,2.957-6.308,2.964-6.324l.215.068Zm-6.148-3.693s.456,3.172,4.054,2.223l.061.208c-3.851,1.015-4.34-2.391-4.34-2.4ZM590,74.661c0,.014-1.069,5.392-2.2,6.8l-.182-.13c1.109-1.364,2.155-6.7,2.157-6.7l.224.04Zm-6.171,1.978s3.493-2.617,4.422-4.134l.2.109c-.944,1.553-4.465,4.189-4.484,4.2l-.141-.17Z"
          transform="translate(-2921.928 -3039.269)"
          fill="#d49b2a"
        />
        <path
          id="Path_35609"
          data-name="Path 35609"
          d="M584.771,134.068c0,.021-2.7,7.143-6.315,9.439l-.118-.179c3.54-2.251,6.218-9.307,6.225-9.329l.208.076Zm-8.007,9.607a2.532,2.532,0,0,0,1.935,1.949l-.057.2a2.742,2.742,0,0,1-2.091-2.124l.212-.028Zm-.432,1.317a2.907,2.907,0,0,0,2.575,1.168l.026.212a3.139,3.139,0,0,1-2.782-1.26Zm.892,2.695a29.858,29.858,0,0,0,3.181,9.645l-.2.1a30.1,30.1,0,0,1-3.2-9.72l.219-.024Z"
          transform="translate(-2917.107 -3088.258)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35610"
          data-name="Path 35610"
          d="M623.9,161.869c0,.012,2.053,4.628,5.034,5.9l-.087.2c-3.068-1.3-5.142-5.994-5.149-6.006l.2-.087Zm.8-1.616s-1.472-.035-2.03.722l-.177-.12c.623-.847,2.206-.809,2.211-.809v.208Zm-1.706-6.28s-1.369,3.674-1.034,5.947l-.217.031c-.345-2.324,1.041-6.041,1.043-6.051Z"
          transform="translate(-2951.897 -3103.461)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35611"
          data-name="Path 35611"
          d="M585.265,51.192a16.891,16.891,0,0,1,3.6-2.412l.09.2a16.6,16.6,0,0,0-3.54,2.372l-.153-.158Z"
          transform="translate(-2924.067 -3023.149)"
          fill="#fad489"
        />
        <path
          id="Path_35612"
          data-name="Path 35612"
          d="M603,58.935a6.924,6.924,0,0,1,4.956-1.588l-.021.215a6.674,6.674,0,0,0-4.772,1.529L603,58.935Z"
          transform="translate(-2937.62 -3029.678)"
          fill="#d49b2a"
        />
        <path
          id="Path_35613"
          data-name="Path 35613"
          d="M601.94,55.531a10.714,10.714,0,0,1,3.516-.585l-.014.215a10.368,10.368,0,0,0-3.422.573Z"
          transform="translate(-2936.811 -3027.851)"
          fill="#d49b2a"
        />
        <path
          id="Path_35614"
          data-name="Path 35614"
          d="M610.752,62.668a6.148,6.148,0,0,0,2.832-1.838l.177.137a6.394,6.394,0,0,1-2.952,1.909l-.059-.208Z"
          transform="translate(-2943.541 -3032.355)"
          fill="#d49b2a"
        />
        <path
          id="Path_35615"
          data-name="Path 35615"
          d="M635.64,72.249a2.6,2.6,0,0,1,1.527-.6l.033.215a2.382,2.382,0,0,0-1.383.519Z"
          transform="translate(-2962.558 -3040.622)"
          fill="#db8777"
        />
        <path
          id="Path_35616"
          data-name="Path 35616"
          d="M633.714,101.25l2.442.479-.142.656-2.445-.481.144-.654Z"
          transform="translate(-2960.976 -3063.236)"
          fill="#342d6e"
          fillRule="evenodd"
        />
        <path
          id="Path_35617"
          data-name="Path 35617"
          d="M635.337,101.616l.193-.85a.172.172,0,0,1,.2-.125l1.232.253a.16.16,0,0,1,.125.191l-.2.847a.163.163,0,0,1-.193.125l-1.234-.253a.156.156,0,0,1-.123-.189Z"
          transform="translate(-2962.323 -3062.769)"
          fill="#2b2b2b"
          fillRule="evenodd"
        />
        <path
          id="Path_35618"
          data-name="Path 35618"
          d="M637.363,102.494l.182-.8-1.163-.238-.182.8,1.163.238Z"
          transform="translate(-2962.986 -3063.396)"
          fill="#e84638"
          fillRule="evenodd"
        />
        <path
          id="Path_35619"
          data-name="Path 35619"
          d="M637.363,102.494l.182-.8-1.163-.238-.182.8,1.163.238Z"
          transform="translate(-2962.986 -3063.396)"
          fill="#e84638"
          fillRule="evenodd"
        />
        <path
          id="Path_35620"
          data-name="Path 35620"
          d="M544.326,60.64a1.5,1.5,0,0,0,.288.658.994.994,0,0,1,.236.368.292.292,0,0,1-.052.3.167.167,0,0,1-.135.057c.061.1.111.222.045.314a.487.487,0,0,1-.215.186.419.419,0,0,1-.184.026c.033.1.066.236-.024.316a.362.362,0,0,1-.118.068.453.453,0,0,1-.12.024.441.441,0,0,1-.269.286.919.919,0,0,1-.708-.09l.055-.092a.776.776,0,0,0,.6.085.346.346,0,0,0,.208-.2l-.09-.019a1.659,1.659,0,0,1-.2-.073,3.553,3.553,0,0,1-.812-.621l.08-.071a3.54,3.54,0,0,0,.776.595,1.489,1.489,0,0,0,.184.066.678.678,0,0,0,.135.024.292.292,0,0,0,.205-.066c.059-.05,0-.2-.033-.262l-.057-.021a4.368,4.368,0,0,1-.857-.708l.083-.071a4.3,4.3,0,0,0,.814.694.375.375,0,0,0,.286.021.391.391,0,0,0,.167-.149c.061-.087-.08-.255-.127-.307l-.04-.024a3.325,3.325,0,0,1-.4-.307,3.034,3.034,0,0,1-.432-.453l.09-.061a2.86,2.86,0,0,0,.415.427,3.411,3.411,0,0,0,.385.3l.052.028h0c.071.035.125.042.156,0a.191.191,0,0,0,.031-.2.87.87,0,0,0-.21-.323,1.626,1.626,0,0,1-.316-.708l.111-.014ZM546,63.274s-1.081.519-1.48-.425l.106-.035c.347.842,1.317.375,1.319.375l.052.1Z"
          transform="translate(-2891.65 -3032.209)"
          fill="#bd6e5e"
        />
        <path
          id="Path_35621"
          data-name="Path 35621"
          d="M574.611,90.61a4.544,4.544,0,0,1,.217,1.541h-.215a4.243,4.243,0,0,0-.2-1.463Z"
          transform="translate(-2915.777 -3055.107)"
          fill="#bd6e5e"
        />
        <path
          id="Path_35622"
          data-name="Path 35622"
          d="M581.071,250.369a.491.491,0,0,1,.472.309,4.438,4.438,0,0,1,.387.93,3.688,3.688,0,0,0,.4.916c.186.333.113.9-1.18,1.5s-2.766,1.14-3.3,1.461a7.535,7.535,0,0,1-3.608.137c-.989-.243-1-1.017-.8-1.213s2.07-.677,2.6-1.357.892-1.812,1.161-1.746.628.529,1.008.271a.944.944,0,0,1,1.142-.05c.472.25,1.128.408,1.444-.076a1.887,1.887,0,0,0,.264-1.078Z"
          transform="translate(-2914.956 -3177.165)"
          fill="#e67e22"
          fillRule="evenodd"
        />
        <path
          id="Path_35623"
          data-name="Path 35623"
          d="M582.632,258.67l.109.2c.187.333.113.9-1.18,1.5s-2.766,1.14-3.3,1.461a7.535,7.535,0,0,1-3.608.137,1.225,1.225,0,0,1-.779-.491,5.55,5.55,0,0,0,3.469,0c1.926-.72,2.808-1.614,3.573-1.836.708-.205,1.18-.186,1.711-.956Z"
          transform="translate(-2915.365 -3183.507)"
          fill="#ecf0f1"
          fillRule="evenodd"
        />
        <path
          id="Path_35624"
          data-name="Path 35624"
          d="M584.342,258.037a1.609,1.609,0,0,1,.689.651l-.2.076a1.417,1.417,0,0,0-.59-.545Zm.724-1.057a1.616,1.616,0,0,1,.689.654l-.2.073a1.415,1.415,0,0,0-.59-.545Zm-.26.368a1.607,1.607,0,0,1,.689.651l-.2.076a1.415,1.415,0,0,0-.59-.545Zm-.193.335a1.6,1.6,0,0,1,.689.651l-.2.073a1.4,1.4,0,0,0-.59-.543l.1-.182Z"
          transform="translate(-2923.288 -3182.216)"
          fill="#c96a16"
        />
        <path
          id="Path_35625"
          data-name="Path 35625"
          d="M582.948,255.443s.109.04.288-.316a1.075,1.075,0,0,0,.073-.677h0a1.345,1.345,0,0,1-.125.614l-.094.125a1.422,1.422,0,0,0-.172.255h.031Zm.88-.269a2.243,2.243,0,0,0-.236-1.55,3.278,3.278,0,0,0-.071.38,1.674,1.674,0,0,0,.014.422,1.236,1.236,0,0,1-.092.791c-.217.427-.451.439-.533.425-.156,0-.21-.083-.2-.193a.945.945,0,0,1,.219-.389l.09-.118a1.653,1.653,0,0,0,.094-.661,2.873,2.873,0,0,1,.066-.618.81.81,0,0,1,.054-.142.618.618,0,0,0-.526.335c-.066.142-.123.293-.175.439-.151.418-.288.8-.666.861-.29.052-.42-.021-.446-.161-.052-.267.512-.824.649-.958,0-.012,0,0,.014-.012a3.737,3.737,0,0,1,.425-.571.828.828,0,0,1,.529-.286,1.5,1.5,0,0,0-.93.125l-.1-.184s.965-.472,1.463.1a1.347,1.347,0,0,1,.184.158l.035.019v.012a2.309,2.309,0,0,1,.368,1.812l-.212-.038Zm-.916.278Zm-.427-1.652c-.078.1-.153.219-.236.34l-.042.047c-.123.12-.623.614-.592.776,0,.019.057.021.2,0,.252-.042.37-.368.5-.724.052-.144.106-.293.172-.434Z"
          transform="translate(-2921.13 -3179.118)"
          fill="#c96a16"
        />
      </g>
    </svg>
  );
}

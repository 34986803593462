export default function renderIcon({
  color = "#002366",
  width = "22.25",
  height = "22.25",
}) {
  return (
    <svg
      id="bmi_1_"
      data-name="bmi (1)"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        id="Path_49671"
        data-name="Path 49671"
        d="M11.125.159A11.1,11.1,0,0,0,.39,14.152a.651.651,0,0,0,.629.48H21.23a.651.651,0,0,0,.629-.48A11.1,11.1,0,0,0,11.125.159ZM4.927,11.9H3.7a.65.65,0,1,1,0-1.3H4.927a.65.65,0,1,1,0,1.3ZM6.321,8.485a.652.652,0,0,1-.89.238L4.367,8.11a.65.65,0,1,1,.651-1.126L6.083,7.6A.649.649,0,0,1,6.321,8.485ZM8.351,6.46a.652.652,0,0,1-.89-.238L6.847,5.16a.651.651,0,0,1,1.128-.65L8.59,5.572A.649.649,0,0,1,8.351,6.46Zm2.122-2.617a.651.651,0,0,1,1.3,0V5.068a.651.651,0,0,1-1.3,0Zm3.153,5.833-2.041,2.037a.651.651,0,0,1-.921-.919l2.041-2.037a.651.651,0,0,1,.921.919ZM15.4,5.16l-.614,1.062a.651.651,0,0,1-1.128-.65l.614-1.062a.651.651,0,0,1,1.128.65Zm.526,3.325a.649.649,0,0,1,.238-.888l1.064-.613a.65.65,0,1,1,.651,1.126l-1.064.613A.652.652,0,0,1,15.928,8.485ZM18.551,11.9H17.323a.65.65,0,1,1,0-1.3h1.229a.65.65,0,1,1,0,1.3Z"
        transform="translate(0 -0.159)"
        fill={color}
      />
      <path
        id="Path_49672"
        data-name="Path 49672"
        d="M219.013,361.272a.779.779,0,0,0-1.506-.061l-.958,3.328c-.005-.017-.995-3.341-1-3.357a.779.779,0,0,0-1.5.1v.007l-.933,5.12a.656.656,0,0,0,1.292.231l.531-2.913c.005.017.878,2.948.884,2.965a.779.779,0,0,0,1.481-.03l.846-2.939.564,2.924A.656.656,0,0,0,220,366.4Z"
        transform="translate(-203.875 -344.965)"
        fill={color}
      />
      <path
        id="Path_49673"
        data-name="Path 49673"
        d="M404.594,360.6a.653.653,0,0,0-.656.65v5.258a.656.656,0,0,0,1.313,0v-5.258A.653.653,0,0,0,404.594,360.6Z"
        transform="translate(-386.392 -344.91)"
        fill={color}
      />
      <path
        id="Path_49674"
        data-name="Path 49674"
        d="M81.853,363.477a1.836,1.836,0,0,0,.306-1.016,1.871,1.871,0,0,0-1.878-1.86H78.738a.653.653,0,0,0-.656.65v5.258a.647.647,0,0,0,.193.461.66.66,0,0,0,.463.189h0c.014,0,1.423-.006,1.809-.006a2.078,2.078,0,0,0,2.085-2.065A2.055,2.055,0,0,0,81.853,363.477ZM79.395,361.9h.886a.56.56,0,1,1,0,1.12h-.886Zm1.155,3.952-1.155,0v-1.533c.405,0,.215,0,1.155,0a.765.765,0,1,1,0,1.53Z"
        transform="translate(-74.695 -344.91)"
        fill={color}
      />
    </svg>
  );
}

import apiService from "../apiService";

const apiPath = "/admin-api";

export const adminService = {
  // SING IN
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  POST_LOGOUT: () => {
    return apiService.post(`${apiPath}/logout`);
  },
  GET_PROFILE_DETAIL: () => {
    return apiService.get(`${apiPath}/admins/me`);
  },
  POST_LOGIN_WITH_TOKEN: () => {
    return apiService.post(`${apiPath}/loginWithToken`);
  },
  PATCH_UPDATE_MY_ADMIN: (params) => {
    return apiService.patch(`${apiPath}/admins/me`, params);
  },

  // COMPANY
  GET_COMPANY_LIST: () => {
    return apiService.get(`${apiPath}/admins/me/companies`);
  },

  // STATISTICS
  GET_COMPANY_STATISTICS: (company_id) => {
    return apiService.get(`${apiPath}/companies/${company_id}/statistics/user`);
  },
  GET_USER_STATISTICS: () => {
    return apiService.get(`${apiPath}/statistics/user`);
  },
  GET_GENDER_BMI_STATISTICS: (company_id) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bmi/gender`
    );
  },
  GET_AGE_GENDER_BMI_STATISTICS: (company_id, stat_date) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bmi/age_gender?stat_date=${stat_date}`
    );
  },
  GET_DEPARTMENT_BMI_STATISTICS: (company_id, stat_date) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bmi/department?stat_date=${stat_date}`
    );
  },
  GET_DEPARTMENT_BMI_TREND: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bmi/gender_trend${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  GET_STATISTICS_WELLBEING: () => {
    return apiService.get(`${apiPath}/statistics/wellbeing`);
  },
  GET_STATISTICS_COMPANY_WELLBEING_SUMMARY: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/wellbeing/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  GET_WELLBEING_STATISTICS_GENDER_TREND: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/wellbeing/gender_trend${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_WELLBEING_STATISTICS_GENDER_AGE: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/wellbeing/age_gender${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_WELLBEING_STATISTICS_DEPARTMENT: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/wellbeing/department${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // DWB
  GET_DWB_STATISTICS_ALL_COMPANY: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/dwb${stringQuery ? stringQuery : ""}`
    );
  },
  GET_DWB_SUMMARY_COMPANY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/dwb/company/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_DWB_STATISTICS_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/dwb/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_DWB_SUMMARY_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/dwb/department/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // BOS
  GET_BOS_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bos/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_CAUSE_BOS_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bos/cause${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_BOS_SUMMARY_DEPARTMENT_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/bos/department/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // MSD
  GET_MSD_STATISTICS_SUMMARY_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_STATISTICS_TREND: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/trend${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_ALL_STATISTICS: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/msd${stringQuery ? stringQuery : ""}`
    );
  },
  GET_MSD_ALL_TREND_STATISTICS: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/msd/trend${stringQuery ? stringQuery : ""}`
    );
  },
  GET_ALL_MSD_COMPANY_SUMMARY: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/msd/company/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_SUMMARY_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/department/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_DEPARTMENT_ORGAN_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/department/organ${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_ORGAN_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/organ${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_ALL_MSD_COMPANY_WITH_ORGAN: (stringQuery) => {
    return apiService.get(
      `${apiPath}/statistics/msd/company/organ${stringQuery ? stringQuery : ""}`
    );
  },
  GET_MSD_WORKTYPE_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/worktype${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_MSD_BEHAVIOUR_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/msd/behaviour${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // STEP
  GET_ALL_STATISTICS_STEP: () => {
    return apiService.get(`${apiPath}/statistics/step`);
  },
  GET_STATISTICS_STEP: (company_id) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/step/summary`
    );
  },
  GET_STATISTICS_STEP_AVERAGE: (company_id, period) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/step/average?period=${period}`
    );
  },
  GET_STATISTICS_STEP_AVERAGE_PER_PAGE: (
    company_id,
    period,
    per_page,
    offset
  ) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/step/average?period=${period}&per_page=${per_page}&offset=${offset}`
    );
  },
  GET_GOALS: () => {
    return apiService.get(`${apiPath}/goals`);
  },

  // HEALTH CHECKUP
  GET_HEALTH_CHECKUP_SUMMARY: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/checkup/summary${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_HEALTH_CHECKUP_ABNORMAL: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/statistics/checkup/abnormal${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  //CONTENT MENU
  GET_CONTENT_LIST: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/contents${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_CONTENT_BY_ID: (content_id) => {
    return apiService.get(`${apiPath}/contents/${content_id}`);
  },
  GET_CAMPANIES_ID_CONTENT_CATEGORY: (company_id) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/content_categories`
    );
  },
  POST_CREATE_CONTENTS: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/contents`,
      params
    );
  },
  POST_UPLOAD_CONTENT_MEDIA: (content_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/contents/${content_id}/medias`,
      params
    );
  },
  PATCH_CONTENT_UPDATE: (content_id, params) => {
    return apiService.patch(`${apiPath}/contents/${content_id}`, params);
  },
  DELETE_MEDIA_CONTENT: (content_id, content_media_id) => {
    return apiService.delete(
      `${apiPath}/contents/${content_id}/medias/${content_media_id}`
    );
  },
  DELETE_CONTENT: (content_id) => {
    return apiService.delete(`${apiPath}/contents/${content_id}`);
  },

  //REWARD
  GET_REWARD_CATEGORIES: () => {
    return apiService.get(`${apiPath}/reward_categories`);
  },
  GET_REWARD_LIST_BY_CATEGORIES: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/reward_items${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_REWARD_LIST_BY_CATEGORIES_BY_KEYWORD: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/reward_items${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  POST_REWARD_ITEM: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/reward_items`,
      params
    );
  },
  PATCH_REWARD_ITEM: (reward_id, params) => {
    return apiService.patch(`${apiPath}/reward_items/${reward_id}`, params);
  },
  GET_REWARD_ITEM_BY_ID: (reward_item_id) => {
    return apiService.get(`${apiPath}/reward_items/${reward_item_id}`);
  },

  DELETE_REWARD_ITEM: (reward_item_id) => {
    return apiService.delete(`${apiPath}/reward_items/${reward_item_id}`);
  },
  POST_UPLOAD_REWARD_IMAGE: (reward_item_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/reward_items/${reward_item_id}/images`,
      params
    );
  },
  GET_REWARD_ITEM_LIST_COMPANY: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/reward_items${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_POPULAR_REWARD_ITEMS: (company_id) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/popular_reward_items`
    );
  },
  GET_REDEMPTIONS_BY_REWARD_ITEM: (
    reward_item,
    per_page = "10",
    offset = "0"
  ) => {
    return apiService.get(
      `${apiPath}/reward_items/${reward_item}/redemptions?per_page=${per_page}&offset=${offset}`
    );
  },
  GET_POPULAR_REWARD_CATEGORY: (company_id, startDate, EndDate) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/popular_reward_categories?begin_date=${startDate}&end_date=${EndDate}`
    );
  },

  PATCH_REDEEM_REWARD_ITEM: (redemption_id, params) => {
    return apiService.patch(`${apiPath}/redemptions/${redemption_id}`, params);
  },

  // USER
  GET_EMPLOYEE_LIST: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/employees${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_EMPLOYEE_BY_ID: (employee_id) => {
    return apiService.get(`${apiPath}/employees/${employee_id}`);
  },
  GET_REPORT_MANAGEMENT_TYPE_BY_COMPANY_ID: (
    company_id,
    management_type,
    stringQuery
  ) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/${management_type}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  POST_EMPLOYEE: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/employees`,
      params
    );
  },
  PATCH_EMPLOYEE: (employee_id, params) => {
    return apiService.patch(`${apiPath}/employees/${employee_id}`, params);
  },
  DELETE_EMPLOYEE: (employee_id) => {
    return apiService.delete(`${apiPath}/employees/${employee_id}`);
  },
  UNLOCK_USER_BY_ID: (employee_id, params) => {
    return apiService.post(`${apiPath}/users/${employee_id}/unlock`, params);
  },
  GET_DEPARTMENT_LIST: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/departments${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_DEPARTMENT_BY_ID: (department_id) => {
    return apiService.get(`${apiPath}/departments/${department_id}`);
  },
  POST_DEPARTMENT: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/departments`,
      params
    );
  },
  PATCH_DEPARTMENT: (department_id, params) => {
    return apiService.patch(`${apiPath}/departments/${department_id}`, params);
  },
  DELETE_DEPARTMENT: (department_id) => {
    return apiService.delete(`${apiPath}/departments/${department_id}`);
  },

  // ADMIN COMPANY
  GET_ADMIN_ROLES: () => {
    return apiService.get(`${apiPath}/admin_roles/`);
  },
  GET_ADMIN_LIST_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/admins${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_ADMIN_BY_ID: (admin_id) => {
    return apiService.get(`${apiPath}/admins/${admin_id}`);
  },
  GET_RESET_PASSWORD_BY_REF_NO: (ref_no, stringQuery) => {
    return apiService.get(
      `${apiPath}/reset_password/ref_no/${ref_no}${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_RESET_POINT_BY_COMPANY_ID: (company_id) => {
    return apiService.get(`${apiPath}/companies/${company_id}/reset_points`);
  },
  POST_EMAIL_RESET_PASSWORD: (email) => {
    return apiService.post(`${apiPath}/reset_password/email/${email}/`);
  },
  POST_ADMIN_RESET_PASSWORD_BY_USERNAME: (username) => {
    return apiService.post(`${apiPath}/reset_password/username/${username}`);
  },
  POST_ADMIN_RESET_PASSWORD: (admin_id) => {
    return apiService.post(`${apiPath}/admins/${admin_id}/reset_password`);
  },
  POST_ADMIN: (company_id, params) => {
    return apiService.post(`${apiPath}/companies/${company_id}/admins`, params);
  },
  PATCH_ADMIN: (admin_id, params) => {
    return apiService.patch(`${apiPath}/admins/${admin_id}`, params);
  },
  PUT_RESET_PASSWORD_BY_REF_NO: (ref_no, params) => {
    return apiService.put(`${apiPath}/reset_password/ref_no/${ref_no}`, params);
  },

  DELETE_ADMIN: (admin_id) => {
    return apiService.delete(`${apiPath}/admins/${admin_id}`);
  },
  POST_POINT: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/user_balances`,
      params
    );
  },
  GET_AUDIT_LOG_LIST_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/audit_logs${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  GET_AUDIT_LOG_BY_ID: (auditlog_id) => {
    return apiService.get(`${apiPath}/audit_logs/${auditlog_id}`);
  },
  GET_REPORT_BY_COMPANY_ID: (company_id, report_type, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/reports/${report_type}${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  // GROUPS
  GET_GROUPS_LIST: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/groups${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_GROUPS_BY_ID: (group_id) => {
    return apiService.get(`${apiPath}/groups/${group_id}`);
  },
  GET_USERS_BY_GROUP_ID: (group_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/groups/${group_id}/users${stringQuery ? stringQuery : ""}`
    );
  },
  POST_GROUPS: (company_id, params) => {
    return apiService.post(`${apiPath}/companies/${company_id}/groups`, params);
  },
  POST_UPLOAD_GROUPS_MEDIA: (group_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/groups/${group_id}/medias`,
      params
    );
  },
  GET_GROUPS_POSTS_PER_MONTH_BY_GROUP_ID: (group_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/groups/${group_id}/statistics/posts/per_month${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PATCH_GROUPS: (group_id, params) => {
    return apiService.patch(`${apiPath}/groups/${group_id}`, params);
  },
  PATCH_USER_GROUP: (group_id, users_id, params) => {
    return apiService.patch(
      `${apiPath}/groups/${group_id}/users/${users_id}`,
      params
    );
  },
  DELETE_GROUP_BY_ID: (group_id) => {
    return apiService.delete(`${apiPath}/groups/${group_id}`);
  },
  DELETE_MEDIA_GROUP: (group_id, group_media_id) => {
    return apiService.delete(
      `${apiPath}/groups/${group_id}/medias/${group_media_id}`
    );
  },

  // MISSION
  GET_COMANY_MISSIONS: (
    company_id,
    type = "current",
    per_page = "1",
    offset = "0"
  ) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/company_missions/?type=${type}&per_page=${per_page}&offset=${offset}`
    );
  },
  GET_DEPARTMENT_LIST_BY_COMPANY_MISSION_ID: (company_mission_id) => {
    return apiService.get(
      `${apiPath}/company_missions/${company_mission_id}/departments`
    );
  },
  GET_COMPANY_MISSION_SUMMARY_BY_ID: (company_mission_id) => {
    return apiService.get(
      `${apiPath}/company_missions/${company_mission_id}/summary`
    );
  },
  GET_COMPANY_MISSION_BY_ID: (company_mission_id) => {
    return apiService.get(`${apiPath}/company_missions/${company_mission_id}`);
  },
  GET_COMANY_MISSIONS_BY_BEGIN_DATE_END_DATE: (
    company_id,
    begin_date,
    end_date,
    per_page
  ) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/company_missions/?begin_date=${begin_date}&end_date=${end_date}&per_page=${per_page}`
    );
  },
  GET_REWARD_AVATAR_ITMES: () => {
    return apiService.get(`${apiPath}/avatar_items/?is_rewardable=true`);
  },
  DELETE_COMPANY_MISSION: (company_mission_id) => {
    return apiService.delete(
      `${apiPath}/company_missions/${company_mission_id}`
    );
  },
  POST_COMPANY_MISSION: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/company_missions`,
      params
    );
  },
  PATCH_COMPANY_MISSION: (company_mission_id, params) => {
    return apiService.patch(
      `${apiPath}/company_missions/${company_mission_id}`,
      params
    );
  },
  POST_UPLOAD_MISSION_IMAGE: (company_mission_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/company_missions/${company_mission_id}/images`,
      params
    );
  },
  GET_LIST_USER_BY_MISSION_ID_BY_COMPANY_ID: (
    company_mission_id,
    department_id,
    queryString
  ) => {
    return apiService.get(
      `${apiPath}/company_missions/${company_mission_id}/departments/${department_id}/users${
        queryString ? queryString : ""
      }`
    );
  },

  // ADMIN INVITRACE
  GET_ADMIN_LIST_INVITRACE: (stringQuery) => {
    return apiService.get(
      `${apiPath}/invitrace/admins${stringQuery ? stringQuery : ""}`
    );
  },
  GET_CANCELLATIONS_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/cancellations${stringQuery ? stringQuery : ""}`
    );
  },
  POST_ADMIN_INVITRACE: (params) => {
    return apiService.post(`${apiPath}/invitrace/admins`, params);
  },

  // ADMIN DDC
  GET_ADMIN_LIST_DDC: (stringQuery) => {
    return apiService.get(
      `${apiPath}/ddc/admins${stringQuery ? stringQuery : ""}`
    );
  },
  POST_ADMIN_DDC: (params) => {
    return apiService.post(`${apiPath}/ddc/admins`, params);
  },

  POST_BULK_EMPLOYEE: (company_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/companies/${company_id}/bulk_employees`,
      params
    );
  },

  //LUCKY SPIN
  GET_SPIN_LIST_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/spins${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_SPIN_ITEM_LIST_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/spin_items${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_SPINNING_COUNT_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/spinning_count${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_SPINNING_LIST_BY_COMPANY_ID: (company_id, stringQuery) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/spinnings${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  GET_SPIN_ITEM_BY_ID: (spin_item_id) => {
    return apiService.get(`${apiPath}/spin_items/${spin_item_id}`);
  },
  GET_SPIN_BY_ID: (spin_id) => {
    return apiService.get(`${apiPath}/spins/${spin_id}`);
  },
  POST_CREATE_SPIN_BY_COMPANY_ID: (company_id, params) => {
    return apiService.post(`${apiPath}/companies/${company_id}/spins`, params);
  },
  POST_CREATE_SPIN_ITEMS: (company_id, params) => {
    return apiService.post(
      `${apiPath}/companies/${company_id}/spin_items`,
      params
    );
  },
  PATCH_SPIN_BY_ID: (spin_id, params) => {
    return apiService.patch(`${apiPath}/spins/${spin_id}`, params);
  },
  PATCH_SPIN_ITEMS_BY_ID: (spin_item_id, params) => {
    return apiService.patch(`${apiPath}/spin_items/${spin_item_id}`, params);
  },
  PATCH_SPINNING_BY_ID: (spinning_id, params) => {
    return apiService.patch(`${apiPath}/spinnings/${spinning_id}`, params);
  },
  DELETE_SPIN_BY_ID: (spin_id) => {
    return apiService.delete(`${apiPath}/spins/${spin_id}`);
  },
  DELETE_SPIN_ITEMS_BY_ID: (spin_item_id) => {
    return apiService.delete(`${apiPath}/spin_items/${spin_item_id}`);
  },

  // Link media
  POST_GENERATE_LINK_MEDIA: (company_id, params) => {
    return apiService.post_formdata(
      `${apiPath}/companies/${company_id}/medias`,
      params
    );
  },

  GET_GENERATE_LINK_MEDIA: (company_id, media_key) => {
    return apiService.get(
      `${apiPath}/companies/${company_id}/medias/${media_key}`
    );
  },

  DELETE_LINK_MEDIA: (media_id) => {
    return apiService.delete(`${apiPath}/medias/${media_id}}`);
  },
};

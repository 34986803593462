import { MainDashBoardWellBeingStyled } from "./styled";
import { Blocks, Icons, Typographys, Charts } from "components";
import moment from "moment";
import "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";

const MainDashBoardWellBeing = ({
  data,
  height = "323px",
  barHeight = "360px",
}) => {
  return (
    <MainDashBoardWellBeingStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="24px"
        paddingBottom="0px"
        paddingLeft="24px"
        height={`calc(${height} - 8px)`}
      >
        <div className="container">
          <div className="top_main">
            <div className="top_main_left">
              <div className="show_icon">
                <Icons.SmileIcon />
              </div>
              <Typographys.TopBottom
                theme_left_align
                label="คะแนน Wellbeing ทั้งบริษัท"
                labelBottom="The well being scores of whole company"
              />
            </div>
            <div className="top_main_right">
              <div className="label_layout">
                <div className="show_icon">
                  <Icons.CalendarIcon />
                </div>
                <div className="show_label">
                  {/* วันที่รายสัปดาห์ล่าสุด */}
                  วันที่รายสัปดาห์
                </div>
              </div>
              <div className="date_layout">
                {data &&
                  moment(data.stat_date).startOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).startOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).startOf("isoWeek").format("YYYY")}
                {` - `}
                {data &&
                  moment(data.stat_date).endOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).endOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).endOf("isoWeek").format("YYYY")}
              </div>
            </div>
          </div>
          <div className="bottom_main">
            <div className="bottom_main_left">
              <div className="bar_show">
                <Charts.BarItem
                  theme_standard_bar
                  minHeight={
                    data && Number(data.good_count) > 0 ? barHeight : "10px"
                  }
                  minWidth="100%"
                  percent={
                    data && data.good_count && data.wellbeing_user_count
                      ? `${(
                          (Number(data.good_count) /
                            Number(data.wellbeing_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
              <div className="bar_show_2">
                <Charts.BarItem
                  theme_red
                  minHeight={
                    data && Number(data.moderate_count) > 0 ? barHeight : "10px"
                  }
                  minWidth="100%"
                  percent={
                    data && data.moderate_count && data.wellbeing_user_count
                      ? `${(
                          (Number(data.moderate_count) /
                            Number(data.wellbeing_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
              <div className="bar_show_2 no_mr">
                <Charts.BarItem
                  theme_blue
                  minHeight={
                    data && Number(data.depress_count) > 0 ? barHeight : "10px"
                  }
                  minWidth="100%"
                  percent={
                    data && data.depress_count && data.wellbeing_user_count
                      ? `${(
                          (Number(data.depress_count) /
                            Number(data.wellbeing_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
            </div>
            <div className="bottom_main_right">
              <div className="show_being">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="สุขภาวะทางจิตอยู่ในระดับดี"
                  labelBottom="Good wellbeing"
                  // bgColor="#FB9334"
                  bgColor="#8AC47F"
                  showRound
                />
              </div>
              <div className="show_being">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="สุขภาวะทางจิตอยู่ในระดับต่ำกว่าปกติ"
                  labelBottom="Poor wellbeing"
                  // bgColor="#FD6524"
                  bgColor="#6FB5CB"
                  showRound
                />
              </div>
              <div className="show_being no_margin">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="มีแนวโน้มของภาวะซึมเศร้า"
                  labelBottom="Likely depressed state"
                  // bgColor="#003C58"
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MainDashBoardWellBeingStyled>
  );
};

export default MainDashBoardWellBeing;

export default function ManGood() {
  return (
    <svg id="Group_338" data-name="Group 338" xmlns="http://www.w3.org/2000/svg" width="35.644" height="41.112" viewBox="0 0 35.644 41.112">
      <g id="Group_337" data-name="Group 337" transform="translate(0 0)">
        <g id="Group_306" data-name="Group 306" transform="translate(6.412)">
          <path id="Path_194" data-name="Path 194" d="M453.017,112.666a22.484,22.484,0,0,0-1.722-1.7c-.624-.562-1.286-1.085-1.955-1.587a24.1,24.1,0,0,0-4.266-2.5,18.439,18.439,0,0,0-4.533-1.439,18.067,18.067,0,0,0-4.582-.246l-.226.008a3.657,3.657,0,1,1-.267-7.308,3.707,3.707,0,0,1,.4.007,25.116,25.116,0,0,1,12.031,3.745,29.822,29.822,0,0,1,4.836,3.7c.725.7,1.428,1.41,2.086,2.168.33.376.654.76.966,1.159s.6.779.947,1.287a2.3,2.3,0,0,1-3.589,2.849Z" transform="translate(-431.942 -97.889)" fill="#010101" />
        </g>
        <g id="Group_307" data-name="Group 307" transform="translate(18.787 0.706)">
          <path id="Path_195" data-name="Path 195" d="M455.5,119.841c.019-.263.047-.712.046-1.088.005-.4-.006-.793-.032-1.193a19.538,19.538,0,0,0-.293-2.374,15.652,15.652,0,0,0-1.531-4.386,15.38,15.38,0,0,0-1.2-1.914,14.137,14.137,0,0,0-1.515-1.642,15.771,15.771,0,0,0-3.7-2.55l-.2-.112a3.156,3.156,0,0,1,3.042-5.531c.06.033.125.072.182.108a21.861,21.861,0,0,1,4.9,4,19.944,19.944,0,0,1,1.957,2.52,20.871,20.871,0,0,1,3.033,8.781,24.352,24.352,0,0,1,.133,3.034c-.008.5-.034,1.011-.081,1.519-.048.526-.1.98-.213,1.612a2.3,2.3,0,0,1-4.549-.558Z" transform="translate(-445.437 -98.659)" fill="#010101" />
        </g>
        <g id="Group_308" data-name="Group 308" transform="translate(3.764 4.418)">
          <path id="Rectangle_6" data-name="Rectangle 6" d="M.156,0H29.309a.156.156,0,0,1,.156.156V6.682A14.732,14.732,0,0,1,14.733,21.414h0A14.733,14.733,0,0,1,0,6.682V.156A.156.156,0,0,1,.156,0Z" transform="translate(29.465 21.414) rotate(-180)" fill="#010101" />
        </g>
        <g id="Group_309" data-name="Group 309" transform="translate(5.53 7.916)">
          <path id="Path_196" data-name="Path 196" d="M443.947,139.718h0a12.967,12.967,0,0,0,12.967-12.966v-7.263a12.967,12.967,0,0,0-12.967-12.967h0a12.967,12.967,0,0,0-12.966,12.967v7.263A12.967,12.967,0,0,0,443.947,139.718Z" transform="translate(-430.981 -106.522)" fill="#ffdac3" />
        </g>
        <g id="Group_320" data-name="Group 320" transform="translate(12.286 20.985)">
          <g id="Group_315" data-name="Group 315" transform="translate(5.226 0.423)">
            <g id="Group_314" data-name="Group 314">
              <path id="Path_199" data-name="Path 199" d="M444.5,121.515l-.308,3.053a5.7,5.7,0,0,0-.017,2.4,1.331,1.331,0,0,0,2.011.823c.319-.2.025-.708-.3-.506-1.661,1.042-1.136-2.47-1.09-2.933q.144-1.417.287-2.834c.038-.375-.549-.372-.587,0Z" transform="translate(-444.047 -121.234)" fill="#010101" />
            </g>
          </g>
          <g id="Group_316" data-name="Group 316" transform="translate(3.933 9.027)">
            <path id="Path_200" data-name="Path 200" d="M448.526,135.866h0c-2.648,0-4.794-1.245-4.794-2.782v-2.465h9.588v2.465C453.32,134.62,451.174,135.866,448.526,135.866Z" transform="translate(-446.129 -129.93)" fill="#fff" />
          </g>
          <g id="Group_317" data-name="Group 317" transform="translate(9.138 0.681) rotate(-13)">
            <path id="Path_201" data-name="Path 201" d="M2.574,1.4a.434.434,0,0,1-.1-.012L.35.894a.453.453,0,0,1,.2-.882L2.675.5a.453.453,0,0,1-.1.894Z" transform="translate(0 0)" fill="#010101" />
          </g>
          <g id="Group_318" data-name="Group 318" transform="translate(0.314 0.001) rotate(13)">
            <path id="Path_202" data-name="Path 202" d="M.453,1.4A.453.453,0,0,1,.35.5L2.472.011a.453.453,0,1,1,.2.882L.555,1.384A.435.435,0,0,1,.453,1.4Z" transform="translate(0 0)" fill="#010101" />
          </g>
          <g id="Group_319" data-name="Group 319" transform="translate(1.536 12.028)">
            <path id="Path_203" data-name="Path 203" d="M448.545,134.814c2.457,0,4.468-1.044,4.8-2.394a19.687,19.687,0,0,0-9.588.1C444.167,133.823,446.149,134.814,448.545,134.814Z" transform="translate(-443.759 -131.879)" fill="#e82365" />
          </g>
        </g>
        <g id="Group_321" data-name="Group 321" transform="translate(28.657 16.248)">
          <path id="Path_204" data-name="Path 204" d="M459.219,121.5H459a2.8,2.8,0,0,1-2.8-2.8v-3.095h3.028Z" transform="translate(-456.201 -115.608)" fill="#010101" />
        </g>
        <g id="Group_322" data-name="Group 322" transform="translate(5.313 16.248)">
          <path id="Path_205" data-name="Path 205" d="M430.744,121.5h.447a2.8,2.8,0,0,0,2.8-2.8v-3.095h-3.027Z" transform="translate(-430.744 -115.608)" fill="#010101" />
        </g>
        <g id="Group_335" data-name="Group 335" transform="translate(1.35 22.005)">
          <g id="Group_323" data-name="Group 323" transform="translate(27.776 0)">
            <path id="Path_206" data-name="Path 206" d="M463.231,125.146a3.259,3.259,0,1,1-3.26-3.259A3.26,3.26,0,0,1,463.231,125.146Z" transform="translate(-456.712 -121.887)" fill="#ffdac3" />
          </g>
          <g id="Group_324" data-name="Group 324">
            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="3.259" cy="3.259" rx="3.259" ry="3.259" fill="#ffdac3" />
          </g>
          <g id="Group_329" data-name="Group 329" transform="translate(1.81 2.231)">
            <g id="Group_326" data-name="Group 326">
              <g id="Group_325" data-name="Group 325">
                <path id="Path_207" data-name="Path 207" d="M431.14,125.774a1.758,1.758,0,0,0-2.6-1.235c-.335.176-.038.681.3.506a1.171,1.171,0,0,1,1.736.885c.065.37.63.214.566-.156Z" transform="translate(-428.396 -124.32)" fill="#010101" />
              </g>
            </g>
            <g id="Group_328" data-name="Group 328" transform="translate(0.678 1.076)">
              <g id="Group_327" data-name="Group 327">
                <path id="Path_208" data-name="Path 208" d="M431.057,125.756a1.582,1.582,0,0,0-1.793.031c-.305.216-.013.725.3.506a1.01,1.01,0,0,1,1.2-.031c.317.205.612-.3.3-.506Z" transform="translate(-429.135 -125.493)" fill="#010101" />
              </g>
            </g>
          </g>
          <g id="Group_334" data-name="Group 334" transform="translate(29.817 2.231)">
            <g id="Group_331" data-name="Group 331">
              <g id="Group_330" data-name="Group 330">
                <path id="Path_209" data-name="Path 209" d="M459.508,125.93a1.17,1.17,0,0,1,1.736-.885c.334.175.631-.33.3-.506a1.757,1.757,0,0,0-2.6,1.235c-.064.369.5.527.565.156Z" transform="translate(-458.938 -124.32)" fill="#010101" />
              </g>
            </g>
            <g id="Group_333" data-name="Group 333" transform="translate(0.014 1.076)">
              <g id="Group_332" data-name="Group 332">
                <path id="Path_210" data-name="Path 210" d="M459.385,126.262a1.01,1.01,0,0,1,1.2.031c.308.218.6-.29.3-.506a1.58,1.58,0,0,0-1.792-.031c-.315.2-.022.712.3.506Z" transform="translate(-458.953 -125.493)" fill="#010101" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_336" data-name="Group 336" transform="translate(0 3.871)">
          <path id="Path_211" data-name="Path 211" d="M453.541,118.211c-.018.008-.064-.112-.1-.2s-.088-.193-.137-.293a5.644,5.644,0,0,0-.319-.6,4.362,4.362,0,0,0-.367-.586,5.107,5.107,0,0,0-.4-.556l-.437-.52c-.146-.171-.328-.312-.484-.468a7.225,7.225,0,0,0-1.026-.82,10.077,10.077,0,0,0-4.926-1.629,17.022,17.022,0,0,0-2.79-.026c-.462,0-.981.131-1.461.185-.492.082-.961.113-1.459.226-1.009.268-1.983.459-2.977.76l-2.988,1.012-.283.1a6.38,6.38,0,0,1-4.094-12.085,6.67,6.67,0,0,1,1.645-.325l4.077-.257c1.371-.039,2.792.041,4.162.072.7.035,1.425.157,2.128.237.713.108,1.4.146,2.12.32a25.809,25.809,0,0,1,4.261,1.241,17.829,17.829,0,0,1,7.492,5.206,13.855,13.855,0,0,1,1.329,1.879c.19.331.4.647.561.989l.467,1.029a10.282,10.282,0,0,1,.387,1.053,8.74,8.74,0,0,1,.312,1.073,10.311,10.311,0,0,1,.231,1.1c.031.187.064.369.084.576s.044.375.066.68a2.553,2.553,0,0,1-5.066.633Z" transform="translate(-424.95 -102.111)" fill="#010101" />
        </g>
      </g>
      <g id="Group_602" data-name="Group 602" transform="translate(13.086 24.241)">
        <g id="Group_227" data-name="Group 227" transform="translate(0 0)">
          <path id="Path_149" data-name="Path 149" d="M451.4,61.179l-.008-.289a.586.586,0,0,0-1.172,0l.007.289a.587.587,0,0,0,1.173,0Z" transform="translate(-450.221 -60.324)" fill="#010101" />
        </g>
      </g>
    </svg>
  );
}

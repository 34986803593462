import styled from 'styled-components';

export const LabelIconStyled = styled.div`
  .theme_standard_label_icon {
    display: flex;
    align-items: ${({ align }) => (align ? align : 'baseline')};
    font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : ({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme, fontColor }) =>
    fontColor ? fontColor : theme.COLORS.PURPLE_1};
    font-size: ${({ theme, fontSizeLabelButtom }) =>
    fontSizeLabelButtom ? fontSizeLabelButtom : theme.FONT.SIZE.S24};
    .svg_show_f {
      margin-right: ${({ marginRight }) =>
    marginRight ? marginRight : '12px'};
      display: flex;
    }
    .show_label {
    }
    .svg_show_b {
      margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '12px')};
      display: flex;
    }
  }
`;

import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { LoadingStyled } from './styled'
import Loader from "react-loader-spinner";

const Loading = ({
  theme_standard_loading,
  type = 'Puff',
  color = '#575D96',
  height = 70,
  width = 70,
  timeout
}) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  })
  return (
    <LoadingStyled>
      <div className={customClass}>
        <Loader
          type={type}
          color={color}
          height={height}
          width={width}
          timeout={timeout} //3 secs
        />
      </div>
    </LoadingStyled>
  )
}

Loading.propTypes = {}

export default Loading

export default function TypeVideo({ width = '16', height = '16' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <g id="play-button" transform="translate(1)">
        <g id="Group_3639" data-name="Group 3639" transform="translate(-1)">
          <path id="Path_12222" data-name="Path 12222" d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm3.369,8.255a.571.571,0,0,1-.257.257v0L6.541,10.8a.571.571,0,0,1-.827-.514V5.714A.571.571,0,0,1,6.541,5.2l4.571,2.286A.571.571,0,0,1,11.369,8.255Z" fill="#4f6e83" />
        </g>
      </g>
    </svg>
  );
}

export default function ManSoSo() {
  return (
    <svg id="Group_405" data-name="Group 405" xmlns="http://www.w3.org/2000/svg" width="37.385" height="43.121" viewBox="0 0 37.385 43.121">
      <g id="Group_404" data-name="Group 404" transform="translate(0 0)">
        <g id="Group_373" data-name="Group 373" transform="translate(6.726)">
          <path id="Path_231" data-name="Path 231" d="M565.332,113.33a23.643,23.643,0,0,0-1.807-1.783c-.654-.59-1.348-1.138-2.051-1.665A25.39,25.39,0,0,0,557,107.257a19.333,19.333,0,0,0-4.755-1.509,18.946,18.946,0,0,0-4.806-.257l-.237.009a3.836,3.836,0,1,1-.28-7.666,4.121,4.121,0,0,1,.423.008,26.248,26.248,0,0,1,6.644,1.187,25.965,25.965,0,0,1,5.975,2.742,31.313,31.313,0,0,1,5.072,3.883c.761.731,1.5,1.479,2.187,2.274.346.4.686.8,1.013,1.217s.629.818.995,1.348a2.409,2.409,0,0,1-3.764,2.988Z" transform="translate(-543.227 -97.832)" fill="#010101" />
        </g>
        <g id="Group_374" data-name="Group 374" transform="translate(19.704 0.741)">
          <path id="Path_232" data-name="Path 232" d="M567.274,120.818c.02-.276.05-.746.049-1.141.006-.415-.008-.832-.035-1.252a20.463,20.463,0,0,0-.307-2.489,16.439,16.439,0,0,0-1.606-4.6,15.987,15.987,0,0,0-1.256-2.007,14.921,14.921,0,0,0-1.589-1.724,16.623,16.623,0,0,0-3.88-2.675l-.213-.117a3.31,3.31,0,1,1,3.19-5.8c.063.034.131.075.19.113a22.934,22.934,0,0,1,5.144,4.194,20.993,20.993,0,0,1,2.053,2.642,21.944,21.944,0,0,1,3.182,9.211,25.7,25.7,0,0,1,.138,3.182c-.009.53-.035,1.061-.085,1.594-.05.553-.1,1.029-.223,1.692a2.407,2.407,0,0,1-4.771-.586Z" transform="translate(-556.721 -98.602)" fill="#010101" />
        </g>
        <g id="Group_375" data-name="Group 375" transform="translate(3.949 4.633)">
          <path id="Path_233" data-name="Path 233" d="M555.792,102.649h0A15.452,15.452,0,0,1,571.244,118.1v6.845a.163.163,0,0,1-.164.163H540.5a.163.163,0,0,1-.162-.163V118.1A15.452,15.452,0,0,1,555.792,102.649Z" transform="translate(-540.34 -102.649)" fill="#010101" />
        </g>
        <g id="Group_376" data-name="Group 376" transform="translate(5.8 8.302)">
          <path id="Path_234" data-name="Path 234" d="M555.865,141.282h0a13.6,13.6,0,0,0,13.6-13.6v-7.617a13.6,13.6,0,0,0-13.6-13.6h0a13.6,13.6,0,0,0-13.6,13.6v7.617A13.6,13.6,0,0,0,555.865,141.282Z" transform="translate(-542.265 -106.464)" fill="#ffdac3" />
        </g>
        <g id="Group_387" data-name="Group 387" transform="translate(13.078 22.453)">
          <g id="Group_378" data-name="Group 378" transform="translate(0.757 2.759)">
            <g id="Group_377" data-name="Group 377">
              <path id="Path_235" data-name="Path 235" d="M551.857,124.941q0-.151-.009-.3c-.022-.79-1.252-.793-1.229,0q0,.151.009.3a.615.615,0,0,0,1.229,0Z" transform="translate(-550.619 -124.045)" fill="#010101" />
            </g>
          </g>
          <g id="Group_380" data-name="Group 380" transform="translate(10.319 2.821)">
            <g id="Group_379" data-name="Group 379">
              <path id="Path_236" data-name="Path 236" d="M561.8,125.006q0-.151-.009-.3a.616.616,0,0,0-1.23,0l.009.3a.615.615,0,0,0,1.23,0Z" transform="translate(-560.561 -124.11)" fill="#010101" />
            </g>
          </g>
          <g id="Group_382" data-name="Group 382" transform="translate(4.922)">
            <g id="Group_381" data-name="Group 381">
              <path id="Path_237" data-name="Path 237" d="M556.247,121.47l.3,2.974c.056.554.581,4.157-1.143,3.075-.337-.211-.645.321-.311.531a1.394,1.394,0,0,0,2.109-.863,6.076,6.076,0,0,0-.018-2.515l-.323-3.2c-.039-.39-.655-.393-.615,0Z" transform="translate(-554.95 -121.177)" fill="#010101" />
            </g>
          </g>
          <g id="Group_383" data-name="Group 383" transform="translate(9.52 0.298)">
            <path id="Path_238" data-name="Path 238" d="M562.338,123.148a.484.484,0,0,1-.15-.024l-2.134-.713a.475.475,0,0,1,.3-.9l2.134.712a.475.475,0,0,1-.151.925Z" transform="translate(-559.73 -121.487)" fill="#010101" />
          </g>
          <g id="Group_384" data-name="Group 384" transform="translate(0 0.293)">
            <path id="Path_239" data-name="Path 239" d="M550.307,123.152a.475.475,0,0,1-.151-.925l2.132-.718a.474.474,0,1,1,.3.9l-2.132.719A.481.481,0,0,1,550.307,123.152Z" transform="translate(-549.832 -121.482)" fill="#010101" />
          </g>
          <g id="Group_386" data-name="Group 386" transform="translate(3.979 8.619)">
            <g id="Group_385" data-name="Group 385">
              <path id="Path_240" data-name="Path 240" d="M555.655,132.065a1.673,1.673,0,0,1,2.886-.131c.378.553,1.285.031.9-.527a2.733,2.733,0,0,0-4.694.131.523.523,0,0,0,.9.527Z" transform="translate(-554.676 -130.138)" fill="#010101" />
            </g>
          </g>
        </g>
        <g id="Group_388" data-name="Group 388" transform="translate(30.056 17.042)">
          <path id="Path_241" data-name="Path 241" d="M570.651,121.73h-.232a2.934,2.934,0,0,1-2.934-2.934v-3.245h3.176Z" transform="translate(-567.485 -115.551)" fill="#010101" />
        </g>
        <g id="Group_389" data-name="Group 389" transform="translate(5.573 17.042)">
          <path id="Path_242" data-name="Path 242" d="M542.029,121.73h.468a2.934,2.934,0,0,0,2.934-2.934v-3.245h-3.176Z" transform="translate(-542.029 -115.551)" fill="#010101" />
        </g>
        <g id="Group_402" data-name="Group 402" transform="translate(1.416 23.08)">
          <g id="Group_390" data-name="Group 390" transform="translate(29.132)">
            <path id="Path_243" data-name="Path 243" d="M574.833,125.247a3.419,3.419,0,1,1-3.418-3.418A3.418,3.418,0,0,1,574.833,125.247Z" transform="translate(-567.996 -121.829)" fill="#ffdac3" />
          </g>
          <g id="Group_391" data-name="Group 391">
            <path id="Path_244" data-name="Path 244" d="M544.543,125.247a3.419,3.419,0,1,1-3.418-3.418A3.418,3.418,0,0,1,544.543,125.247Z" transform="translate(-537.706 -121.829)" fill="#ffdac3" />
          </g>
          <g id="Group_396" data-name="Group 396" transform="translate(1.899 2.34)">
            <g id="Group_393" data-name="Group 393">
              <g id="Group_392" data-name="Group 392">
                <path id="Path_245" data-name="Path 245" d="M542.558,125.787a1.844,1.844,0,0,0-2.724-1.3c-.35.185-.039.716.311.531a1.228,1.228,0,0,1,1.821.928c.067.39.66.224.592-.164Z" transform="translate(-539.68 -124.262)" fill="#010101" />
              </g>
            </g>
            <g id="Group_395" data-name="Group 395" transform="translate(0.712 1.129)">
              <g id="Group_394" data-name="Group 394">
                <path id="Path_246" data-name="Path 246" d="M542.435,125.711a1.658,1.658,0,0,0-1.879.034c-.32.226-.013.76.311.531a1.059,1.059,0,0,1,1.258-.034c.334.215.642-.316.311-.531Z" transform="translate(-540.42 -125.436)" fill="#010101" />
              </g>
            </g>
          </g>
          <g id="Group_401" data-name="Group 401" transform="translate(31.274 2.34)">
            <g id="Group_398" data-name="Group 398">
              <g id="Group_397" data-name="Group 397">
                <path id="Path_247" data-name="Path 247" d="M570.821,125.951a1.227,1.227,0,0,1,1.821-.928c.35.185.661-.346.311-.531a1.845,1.845,0,0,0-2.725,1.3c-.067.388.525.553.593.164Z" transform="translate(-570.223 -124.262)" fill="#010101" />
              </g>
            </g>
            <g id="Group_400" data-name="Group 400" transform="translate(0.015 1.129)">
              <g id="Group_399" data-name="Group 399">
                <path id="Path_248" data-name="Path 248" d="M570.69,126.242a1.061,1.061,0,0,1,1.259.034c.323.229.631-.3.311-.531a1.658,1.658,0,0,0-1.879-.034c-.332.214-.024.746.31.531Z" transform="translate(-570.238 -125.436)" fill="#010101" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_403" data-name="Group 403" transform="translate(0 4.06)">
          <path id="Path_249" data-name="Path 249" d="M566.222,118.939c-.019.01-.067-.117-.109-.21s-.091-.2-.143-.308a5.844,5.844,0,0,0-.334-.634,4.577,4.577,0,0,0-.386-.614,5.418,5.418,0,0,0-.423-.584l-.46-.545c-.153-.178-.344-.326-.508-.49a7.575,7.575,0,0,0-1.075-.861,10.582,10.582,0,0,0-5.168-1.709,18.056,18.056,0,0,0-2.926-.027c-.485,0-1.03.138-1.533.2-.516.086-1.008.117-1.529.236-1.058.282-2.08.483-3.124.8l-3.133,1.062-.3.1a6.691,6.691,0,1,1-4.294-12.675,6.924,6.924,0,0,1,1.726-.34l4.275-.269c1.439-.041,2.929.042,4.366.075.729.037,1.494.164,2.231.247.749.113,1.467.153,2.225.337a27,27,0,0,1,4.469,1.3,18.7,18.7,0,0,1,7.857,5.46,14.446,14.446,0,0,1,1.394,1.971c.2.348.418.68.59,1.038l.489,1.08a10.734,10.734,0,0,1,.407,1.1,9.277,9.277,0,0,1,.327,1.126,10.97,10.97,0,0,1,.241,1.157c.033.2.067.386.089.6s.047.393.07.714a2.678,2.678,0,1,1-5.314.664Z" transform="translate(-536.234 -102.053)" fill="#010101" />
        </g>
      </g>
    </svg>

  );
}

import { DepartmentRankStyled } from "./styled";
import { Blocks, TableCustom } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const DepartmentRank = ({ data, labelStatus, handleOnClick }) => {
  return (
    <DepartmentRankStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="48px"
        paddingBottom="70px"
        paddingLeft="16px"
      >
        <div className="depart_container">
          <div className="top_current">
            <div className="label_show">
              <div>อันดับแผนก</div>
              <div className="sub_title_show">Department Rank</div>
            </div>
          </div>
          <div className="bottom_current">
            <TableCustom.TableRank
              theme_standard_table_rank
              handleOnClick={data && data.length > 0 && handleOnClick}
              data={
                data &&
                data.map((e) => ({
                  label1: e.name,
                  // label2: 'Marketing',
                  label3: `${e.user_count} คน`,
                  label4: `${RenderCommaMoney(e.achieved_amount.toFixed(0))} ${
                    labelStatus.unit
                  }`,
                  label5: labelStatus.label1,
                  label6: labelStatus.label2,
                  dataRow: e,
                }))
              }
              labelEmpty="ไม่มีการจัดอันดับแผนก"
            />
          </div>
        </div>
      </Blocks.Box>
    </DepartmentRankStyled>
  );
};

export default DepartmentRank;

import styled from 'styled-components';

export const AllParticipantStyled = styled.div`
  .top_view {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .show_icon {
      display: flex;
    }
  }
  .mid_view {
    display: flex;
    justify-content: center;  
    column-gap: 17px;
    align-items: center;
    margin-top: 48px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    color:  ${({ theme }) => theme.COLORS.BLACK_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    .show_num {
      font-size: ${({ theme }) => theme.FONT.SIZE.S48};
      color:  ${({ theme }) => theme.COLORS.PURPLE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
    .icon_show {
      font-size: ${({ theme }) => theme.FONT.SIZE.S17};
      color:  ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      display: flex;
      flex-direction: column;
      align-items: center;
      .ic_m {
        display: flex;
      }
    }
  }
  .data_icon {
    margin-top: 41px;
    margin-bottom: 27px;
    display: flex;
    justify-content: center;
    .group_icon {
      display: flex;
      column-gap: 8px;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      color:  ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_23};
      // margin-right: 44px;
      // padding-right: 44px;
      justify-content: center;
      width: 50%;
      &.no_border {
        border-right: none;
        margin-right: 0px;
        padding-right: 0px;
      }
      .icon_zone {
        display: flex;
      }
      .value_m {
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        color:  ${({ theme }) => theme.COLORS.BLUE_25};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        &.pink {
          color:  ${({ theme }) => theme.COLORS.PINK_4};
        }
      }
    }
  }
`;

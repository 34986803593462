import styled from "styled-components";

export const TabBarSelectStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .tab_standard_tab_bar_select {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    .tab_container {
      display: flex;
      padding: 0px 40px;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_1};
      overflow: auto;
      .box_tab {
        display: flex;
        .tab_item {
          min-width: 150px;
          padding-bottom: 12px;
          margin-right: 30px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          .group_tab {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .top_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_4};
              font-family: ${({ fontFamilyTop }) =>
                fontFamilyTop
                  ? fontFamilyTop
                  : "noto_sans_thai_medium, noto_sans_regular"};
              font-size: ${({ theme, fontSizeTop }) =>
                fontSizeTop ? fontSizeTop : theme.FONT.SIZE.S22};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_bold, noto_sans_bold;
            }
          }
          &.active {
            border-bottom: 4px solid ${({ theme }) => theme.COLORS.PURPLE_1};
            .top_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_4};
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .box_btn {
        margin-left: 30px;
        width: 13%;
      }
    }
    .tab_content {
      padding: 22px 0px;
    }
  }

  .tab_small {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    .tab_container {
      display: flex;
      padding: 5px 320px 0px 32px;
      justify-content: space-between;
      border-bottom: 0px solid ${({ theme }) => theme.COLORS.BLUE_1} !important;
      overflow: hidden !important;
      background-color: ${({ theme }) => theme.COLORS.GRAY_22};
      .box_tab {
        display: flex;
        .tab_item {
          min-width: 150px;
          padding-bottom: 0px;
          margin-right: 30px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          padding: 5px 0 !important;
          .group_tab {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .top_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_4};
              font-family: ${({ fontFamilyTop }) =>
                fontFamilyTop
                  ? fontFamilyTop
                  : "noto_sans_thai_medium, noto_sans_regular"};
              font-size: ${({ theme, fontSizeTop }) =>
                fontSizeTop ? fontSizeTop : theme.FONT.SIZE.S14} !important;
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_bold, noto_sans_bold;
            }
          }
          &.active {
            padding: 5px 0;
            border-bottom: 4px solid ${({ theme }) => theme.COLORS.PURPLE_1};
            .top_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_4};
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .box_btn {
        margin-left: 30px;
        width: 13%;
      }
    }
    .tab_content {
      padding: 22px 0px;
    }
  }
  .tab_canter_tab_bar_select {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    margin-top: 33px;
    .tab_container {
      display: flex;
      padding: 0px 40px;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_1};
      overflow: auto;
      .box_tab {
        display: flex;
        width: 100%;
        justify-content: space-around;
        .tab_item {
          min-width: 150px;
          padding-bottom: 12px;
          /* margin-right: 30px; */
          padding: 0 3% 0 3%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          .group_tab {
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .top_tab {
              text-align: center;
              color: ${({ theme }) => theme.COLORS.GRAY_4};
              font-family: ${({ fontFamilyTop }) =>
                fontFamilyTop
                  ? fontFamilyTop
                  : "noto_sans_thai_medium, noto_sans_regular"};
              font-size: ${({ theme, fontSizeTop }) =>
                fontSizeTop ? fontSizeTop : theme.FONT.SIZE.S20};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_regular;
            }
          }
          &.active {
            border-bottom: 4px solid ${({ theme }) => theme.COLORS.PURPLE_1};
            .top_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .box_btn {
        margin-left: 30px;
        width: 13%;
      }
    }
    .tab_content {
      padding: 22px 0px;
    }
  }

  .tab_normal {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    .tab_container {
      display: flex;
      padding: 0px;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      overflow: auto;
      .box_tab {
        display: flex;
        .tab_item {
          min-width: 150px;
          padding-bottom: 7.8px;
          margin-right: 24px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          .group_tab {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .top_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_24};
              font-family: ${({ fontFamilyTop }) =>
                fontFamilyTop
                  ? fontFamilyTop
                  : "noto_sans_thai_medium, noto_sans_regular"};
              font-size: ${({ theme, fontSizeTop }) =>
                fontSizeTop ? fontSizeTop : theme.FONT.SIZE.S22};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.GRAY_24};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_bold, noto_sans_bold;
            }
          }
          &.active {
            border-bottom: 4px solid ${({ theme }) => theme.COLORS.PURPLE_1};
            .top_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
            .bottom_tab {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .box_btn {
        margin-left: 30px;
        width: 13%;
      }
    }
    .tab_content {
      padding: 22px 0px;
    }
  }
`;

export default function DashCircle({
  color = "#606060",
  width = "14",
  height = "14",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <circle
        id="Ellipse_2204"
        data-name="Ellipse 2204"
        cx="7"
        cy="7"
        r="7"
        fill={color}
      />
      <rect
        id="Rectangle_18535"
        data-name="Rectangle 18535"
        width="8"
        height="4"
        rx="2"
        transform="translate(3 5)"
        fill="#fff"
      />
    </svg>
  );
}

import { MiniCompanyMisssionStyled } from './styled';
import { Blocks, Icons, Label } from 'components'
import moment from 'moment';
import 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { RENDER_MONTH } from 'utils/functions/date';

const MiniCompanyMisssion = ({ dataCompany, dataMission }) => {
  const [_startDate, setStartDate] = useState('')
  const [_endDate, setEndDate] = useState('')

  useEffect(() => {
    let dateStart = moment(dataMission.begin_date).tz('Asia/Bangkok').format('D')
    let monthStart = RENDER_MONTH(moment(dataMission.begin_date).tz('Asia/Bangkok').format('MM')).th
    let yearStart = moment(dataMission.begin_date).tz('Asia/Bangkok').year() + 543
    let dateEnd = moment(dataMission.end_date).tz('Asia/Bangkok').format('D')
    let monthEnd = RENDER_MONTH(moment(dataMission.end_date).tz('Asia/Bangkok').format('MM')).th
    let yearEnd = moment(dataMission.end_date).tz('Asia/Bangkok').year() + 543

    setStartDate(dateStart + ' ' + monthStart + ' ' + yearStart)
    setEndDate(dateEnd + ' ' + monthEnd + ' ' + yearEnd)
  }, [dataMission]);

  return (
    <MiniCompanyMisssionStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop='16px'
        paddingRight='33px'
        paddingBottom='25px'
        paddingLeft='16px'
      >
        <div className='mini_company_top_current'>
          <div className='mini_top_left_current'>
            {/* <div className='show_icon'>
              <Icons.Company2 />
            </div> */}
            <Label.TopBottom
              theme_left_align
              label={dataCompany?.name}
              labelBottom={`Company Mission Season ${dataMission?.order}`}
              fontSize='15px'
              fontSizeLabelButtom='12px'
              fontColor='#707070'
              colorBottom='#707070'
              fontFamilyTop='noto_sans_thai_regular, noto_sans_regular'
              fontFamilyBottom='noto_sans_thai_regular, noto_sans_regular'
            />
          </div>
        </div>
        <div className='mini_company_mid_current'>
          {dataMission?.name}
        </div>
        <div className='mini_company_bottom_current'>
          <div className='show_icon_bottom'>
            <Icons.Calendar />
          </div>
          <div className="date_detail">
            {`ระยะเวลา ${_startDate} - ${_endDate}`}
          </div>
        </div>
      </Blocks.Box>
    </MiniCompanyMisssionStyled>
  );
};

export default MiniCompanyMisssion;

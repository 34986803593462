import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { InputTxtIconStyled } from "./styled";
import { Icons } from "components";

const InputTxtIcon = ({
  theme_standard,
  theme_not_icon_standard,
  label,
  iconSvg,
  onChangeCustom,
  onChange,
  icon,
  icon2,
  type = "text",
  isUseChangeType = true,
  changeType = "password",
  onClickIcon,
  disabled,
  errors,
  showIconHide,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_not_icon_standard: theme_not_icon_standard,
  });

  const [_type, _setType] = useState(type);

  const handleClickIcon = () => {
    if (isUseChangeType) {
      if (_type === "password") {
        _setType(changeType);
      } else {
        _setType(type);
      }
    }

    onClickIcon && onClickIcon();
  };

  return (
    <InputTxtIconStyled
      disabled={disabled}
      icon={icon}
      showIconHide={showIconHide}
    >
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="input_container">
          {iconSvg && <div className="icon">{iconSvg}</div>}
          <input
            {...props}
            type={_type}
            onChange={(e) => {
              onChange && onChange(e);
              onChangeCustom && onChangeCustom(e);
            }}
          />
          {showIconHide && (
            <div className="svg_group" onClick={handleClickIcon}>
              {type === _type ? (
                <div className="svg_ac">
                  <div className="ic_c">
                    {/* color={props && props.value ? '#264B98' : '#AAAAAA'}  */}
                    <Icons.EyeClose color="#989898" />
                  </div>
                  {/* <div className={`lbl_hide ${props && props.value ? 'color_text' : ''}`}>แสดง</div> */}
                </div>
              ) : (
                <div className="svg_ac">
                  <div className="ic_c">
                    <Icons.EyeOpen color="#989898" />
                  </div>
                  {/* <div className={`lbl_hide ${props && props.value ? 'color_text' : ''}`}>ไม่แสดง</div> */}
                </div>
              )}
            </div>
          )}
        </div>
        {errors && <div className="error">{errors}</div>}
      </div>
    </InputTxtIconStyled>
  );
};

InputTxtIcon.propTypes = {};

export default InputTxtIcon;

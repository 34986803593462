import React, { useEffect, useState } from "react";
import { SendPasswordFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons } from "components";
import theme from "styles/theme.json";

const SendPasswordForm = ({ onSubmit, handleConfirm, handleCancel, data }) => {
  const schema = yup.object().shape({
    // name: yup.string().required("Name is required"),
  });

  const [_optSendType, _setOptSendType] = useState([]);
  const [_defaultValue, _setDefaultValue] = useState("1");
  const [_isShowOpt, _setIsShowOpt] = useState(true);

  useEffect(() => {

    if (data) {
      let email = data.user.email && data.user.email;
      let phone = data.user.mobile_no && data.user.mobile_no;
      let tempOpt = [];
      email =
        email &&
        email.replace(
          /^(.)(.*)(.@.*)$/,
          (_, a, b, c) => a + b.replace(/./g, "*") + c
        );

      // let masked = phone.replace(/\w/g, "x"); //[a-zA-Z0-9_]
      // masked = phone.substring(0, 4) + masked.substring(4);

      if (phone && phone !== "") {
        tempOpt.push({
          label: (
            <div className="opt_s">
              <div>เบอร์โทรศัพท์ (SMS)</div>
              <div className={`lbl_back`}>{phone}</div>
            </div>
          ),
          value: "1",
        });
        _setDefaultValue("1");
        _setIsShowOpt(true);
      } else {
        tempOpt.push({
          label: (
            <div className="opt_s no_action">
              <div>เบอร์โทรศัพท์ (SMS)</div>
              <div className={`lbl_back gary`}>ไม่มีข้อมูล</div>
            </div>
          ),
          value: "1",
          disabled: true,
        });
        _setDefaultValue("2");
        setValue("opt_select", "2");
      }

      if (email && email !== "") {
        tempOpt.push({
          label: (
            <div className="opt_s">
              <div>อีเมล</div>
              <div className={`lbl_back`}>{email}</div>
            </div>
          ),
          value: "2",
        });
        _setIsShowOpt(true);
      } else {
        tempOpt.push({
          label: (
            <div className="opt_s no_action">
              <div>อีเมล</div>
              <div className={`lbl_back gary`}>ไม่มีข้อมูล</div>
            </div>
          ),
          value: "2",
          disabled: true,
        });
      }

      tempOpt.push({
        label: (
          <div
            className={`opt_s ${
              data.user.mobile_no || data.user.email ? "no_action" : ""
            }`}
          >
            <div>ปลดล็อกบัญชี (โดยไม่ผ่าน เบอร์โทรศัพท์/อีเมล)</div>
          </div>
        ),
        disabled: data.user.mobile_no || data.user.email ? true : false,
        value: "3",
      });

      // กรณีไม่มีทั้งเบอร์โทร อีเมลล์ให้ เซ็ทตัวเลือกเป็นปลดล็อคโดยไม่ผ่าน เบอร์โทรศัพท์/อีเมล
      if (!data.user.mobile_no && !data.user.email) {
        _setDefaultValue("3");
        setValue("opt_select", "3");
      }

      _setOptSendType(tempOpt);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    // handleSubmit,
    watch,
    setValue,
    // reset,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchAllFields = watch();

  return (
    <SendPasswordFormStyled>
      <form>
        <div className="bd_add_edit">
          <div className="top_box">
            <img
              className={`svg_i`}
              src="/assets/images/logo/locked.svg"
              alt="img"
            />
            {/* <div className="svg_i">
              <Icons.AddRound width="24" height="24" />
            </div> */}
            <div className="box_obj">
              <div className="h_text">ส่งลิงค์ตั้งค่ารหัสผ่าน</div>
              {_isShowOpt && (
                <>
                  <div className="sub_text">
                    โปรดเลือกวิธีส่งลิงค์ให้ผู้ใช้งานสร้างรหัสผ่าน
                  </div>
                  <div className="opt_select">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.Radio
                          theme_column
                          {...field}
                          flexDirection="column"
                          rowGap="24px"
                          options={_optSendType}
                          // disabled={true}
                          // placeholder={t('test')}
                          // disabled={isCannotEditDelete}
                          // errors={errors.id_card?.message}
                          // onChaneCustom={hadleClearText}
                        />
                      )}
                      name="opt_select"
                      defaultValue={_defaultValue}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="action_btn">
            <div className="body_btn margin_right">
              <Buttons.BtnNormal
                theme_bg_white
                label="ยกเลิก"
                onClick={handleCancel && handleCancel}
                borderColor={theme.COLORS.BLUE_28}
                fontColor={theme.COLORS.BLUE_28}
              />
            </div>
            {_isShowOpt && (
              <div className="body_btn">
                <Buttons.BtnNormal
                  theme_only_border_blue
                  label="ปลดล็อค"
                  backgroundColor={theme.COLORS.BLUE_28}
                  fontColor={theme.COLORS.WHITE_1}
                  onClick={() => handleConfirm && handleConfirm(watchAllFields)}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </SendPasswordFormStyled>
  );
};

SendPasswordForm.propTypes = {};

export default SendPasswordForm;

// var word = "abc6364607935616",
//   masked = word; // word & masked are the same
// if (word.length > 4) {
//   masked = new Array(word.length - 4).join("#"); // create 4 less than word
//   masked += word.substring(word.length - 4); // add on 4 last characters
// }
// console.log(masked); // "###########5616"

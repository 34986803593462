import styled from "styled-components";

export const PopularStyled = styled.div`
  .theme_popular {
    display: flex;
    .pop_box {
      display: flex;
      width: 100%;
      .show_svg {
        display: flex;
      }
      .group_pro {
        margin-left: 12px;
        width: 100%;
        .top_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          display: flex;
          align-items: baseline;
          margin-bottom: 10px;
          margin-top: 11px;
          // justify-content: center;
          .light_text {
            margin-left: 2px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
        }
        .box_image {
          width: 100%;
          // height: 128px;
          padding-top: 62%;
          border-radius: 8px;
          // border: 1px solid ${({ theme }) => theme.COLORS.ORANGE_6};
          border: ${({ border, theme }) =>
            border === "1" ? `1px solid ${theme.COLORS.ORANGE_6}` : "none"};
          background-image: url(${({ src }) => src});
          object-fit: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .bottom_label {
          margin-top: 10px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_semi_bold, noto_sans_regular;
          color: ${({ theme }) => theme.COLORS.RED_3};
          text-align: center;
        }
      }
    }
  }
`;

import React from 'react';
import { HealthyClubDetailContainerStyled } from './styled';
import { ROUTE_PATH } from "utils/constants";
import { connect } from 'react-redux';
// import { withRouter } from 'navigations/withRouter';
import { adminService } from 'apiServices';
import { toast } from 'react-toastify';
import { Loading, Modals, Blocks, Tabs, Buttons, Icons, TableCustom } from 'components';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  GroupDetail,
  AllParticipant,
  AveragePostPerMonth
} from 'widgets';
import { getObjectKey } from "utils/functions/getObjectKey";

class HealthyClubDetailContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
    dataUserGroup: { data: [] },
    dataGraph: [],
    hasMore: true,
    pagination: {
      offset: 0,
      per_page: 10,
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission()
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'group_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  fetchData = async () => {
    const {
      pagination: { offset, per_page },
    } = this.state;
    let res = await Promise.all([
      this.fetchGroupDetail(),
      this.fetchUserGroupDetail(
        `?sort_by=rank&order_by=asc&per_page=${per_page}&offset=${offset}`
      ),
      this.fetchDataGraph(),
    ]);

    if (res) {
      this.setState((state) => ({
        data: res[0],
        isLoading: false,
      }));
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchGroupDetail = async () => {
    let res = await adminService.GET_GROUPS_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      return res.data;
    }
  };

  fetchUserGroupDetail = async (stringQuery) => {
    const {
      pagination: { per_page },
    } = this.state;
    let res = await adminService.GET_USERS_BY_GROUP_ID(
      this.props.match.params.id,
      stringQuery
    );
    if (res && res.success) {
      if (!res.data || res.data.length === 0) {
        this.setState({
          hasMore: false,
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          dataUserGroup: {
            ...res.data,
            data: [...state.dataUserGroup.data, ...res.data],
          },
          hasMore: res.data.length < per_page ? false : true,
          isLoading: false,
        }));
      }
      return res;
    }
  };

  handleBackClick = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB}`);
  };

  handleConfirmDelete = async () => {
    let res = await adminService.DELETE_GROUP_BY_ID(this.props.match.params.id);

    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.setState({
        isShowModal: false,
      });
      this.handleBackClick();
    } else {
      toast.error('ลบไม่สำเร็จ');
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickGroup = () => {
    const { data } = this.state;
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_GROUP_ROLE_DETAIL}/${this.props.match.params.id}/${data.name}`);
  };

  // handleClickAllRank = () => {
  //   this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_RANK}`);
  // };

  handleClickEdit = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_DETAIL}/${this.props.match.params.id}`);
  };

  fetchMoreData = () => {
    this.setState(
      (state) => ({
        pagination: {
          offset:
            state.pagination.offset === 0
              ? 11
              : state.pagination.offset + state.pagination.per_page + 1,
          per_page: state.pagination.per_page,
        },
      }),
      () => {
        this.fetchUserGroupDetail(
          `?sort_by=rank&order_by=asc&per_page=${this.state.pagination.per_page}&offset=${this.state.pagination.offset}`
        );
      }
    );
  };

  fetchDataGraph = async (e) => {
    let selectYear = e && `?stat_date=${moment(e).format('YYYY-MM-DD')}`;
    let res = await adminService.GET_GROUPS_POSTS_PER_MONTH_BY_GROUP_ID(
      this.props.match.params.id,
      selectYear
    );

    if (res && res.success) {
      let tempDataGraph = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      res.data.map((b, i) => (tempDataGraph[b.month - 1] = b.posts_count));
      this.setState({
        dataGraph: tempDataGraph,
      });
      return res;
    }
  };

  setOrderColor = (key) => {
    switch (key) {
      case 1:
        return (
          <Icons.OrderTable
            color1="#FAFBD1"
            color2="#8A7E55"
            colorBorder="#8A7E55"
            orderNum={key}
          />
        );

      case 2:
        return (
          <Icons.OrderTable
            color1="#D1D1D1"
            color2="#8B8B8B"
            colorBorder="#8B8B8B"
            orderNum={key}
          />
        );

      case 3:
        return (
          <Icons.OrderTable
            color1="#FAD4B7"
            color2="#E59558"
            colorBorder="#E59558"
            orderNum={key}
          />
        );

      case 4:
        return (
          <Icons.OrderTable
            color1="#D9D9D9"
            color2="#D9D9D9"
            colorBorder="#D9D9D9"
            colorNumber='#3E3E3E'
            orderNum={key}
          />
        );

      case 5:
        return (
          <Icons.OrderTable
            color1="#D9D9D9"
            color2="#D9D9D9"
            colorBorder="#D9D9D9"
            colorNumber='#989898'
            orderNum={key}
          />
        );

      // case 6:
      //   return (
      //     <Icons.OrderTable
      //       color1="#171717"
      //       color2="#171717"
      //       colorBorder="#DBDBDB"
      //       orderNum={key}
      //     />
      //   );
      default:
        return (
          // <Icons.OrderTable
          //   color1="#171717"
          //   color2="#171717"
          //   colorBorder="#DBDBDB"
          //   orderNum={key}
          // />
          <>{key}</>
        );
    }
  };

  render() {
    const { data, isLoading, isShowModal, dataUserGroup, hasMore, dataGraph, perMissions } = this.state;
    return (
      <HealthyClubDetailContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <Blocks.Container theme_con_no_border_radius >
            <InfiniteScroll
              dataLength={dataUserGroup.data.length}
              next={this.fetchMoreData}
              hasMore={hasMore}
              loader={<h3 className="loading">Loading...</h3>}
              height={'calc(100vh - 76px)'}
              endMessage={
                <p className="loading">
                  <b>ดูครบทั้งหมดแล้ว</b>
                </p>
              }
              style={{ padding: '0 5px' }}
            >
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="กลุ่ม"
                  subTitle="Group"
                  handleBackCustom={this.handleBackClick}
                />
              </div>
              <div className="btn_action_show">
                <div className='w210'>
                  <Buttons.BtnNormal
                    theme_gray
                    label={
                      <div className='gorup_box_text'>
                        <div >
                          กำหนดบทบาทบนกลุ่ม
                        </div>
                        <div className='small_text'>
                          User Management
                        </div>
                      </div>
                    }
                    onClick={this.handleClickGroup}
                  />
                </div>
                {
                  perMissions &&
                  <div className="del_layout">
                    <Buttons.BtnNormal
                      theme_only_border_blue
                      label="ลบ"
                      svg_front={
                        <Icons.Delete3
                          width='20'
                          height='20'
                          color='#002366'
                          color2='#B6E7E8'
                        />
                      }
                      onClick={this.handlePopup}
                    />
                  </div>
                }
              </div>
              <div className="layer_two">
                <GroupDetail
                  data={data && data}
                  handleClickEdit={this.handleClickEdit}
                  perMissions={perMissions}
                />
              </div>
              <div className="layer_three">
                <div className="left_three">
                  <AllParticipant
                    total={data?.users_count}
                    maleCount={data?.users_count_male}
                    famaleCount={data?.users_count_female}
                  />
                </div>
                <div className="right_three">
                  <AveragePostPerMonth
                    handleChangDate={this.fetchDataGraph}
                    data={dataGraph && dataGraph}
                  />
                </div>
              </div>
              <div className="table_rank_show">
                <TableCustom.TableNormal
                  theme_white_head
                  columns={columns({
                    setOrderColor: this.setOrderColor,
                  })}
                  data={dataUserGroup && dataUserGroup.data}
                  showTitle='สมาชิกผู้เข้าร่วม'
                  labelEmpty="ไม่มีการจัดอันดับ"
                />
              </div>
            </InfiniteScroll>
          </Blocks.Container>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <Blocks.BoxDelete
            theme_box_delete
            textShow="ยืนยันการลบกลุ่มหรือไม่"
            maxWidth='374px'
            minWidth='374px'
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
          />
        </Modals.Standard>
      </HealthyClubDetailContainerStyled>
    );
  }
}

const columns = ({ setOrderColor }) => [
  // {
  //   title: "ลำดับ",
  //   field: "rank",
  //   headerStyle: { textAlign: "center", width: "48px", flexShrink: 0 },
  //   style: { textAlign: "center", width: "48px", flexShrink: 0 },
  //   render: ({ origin, value }) => (
  //     <div>
  //       {setOrderColor(value)}
  //     </div>
  //   ),
  // },
  {
    title: "ชื่อ - นามสกุล",
    field: "employee",
    headerStyle: { textAlign: "left", minWidth: "200px" },
    style: { textAlign: "left", minWidth: "200px" },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "10px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {
          origin && origin.employee
            ?
            `${origin.employee?.firstname} ${origin.employee?.lastname}`
            :
            '-'
        }
      </div>
    ),
  },
  {
    title: "แผนก",
    field: "employee",
    headerStyle: { textAlign: "left", minWidth: "10px" },
    style: { textAlign: "left", minWidth: "10px" },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "10px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {
          origin && origin.employee && origin.employee.department
            ?
            origin.employee.department.name
            :
            '-'
        }
      </div>
    ),
  },
  // {
  //   title: "เลเวล",
  //   field: "exp_level",
  //   headerStyle: { textAlign: "left", minWidth: "10px" },
  //   style: { textAlign: "left", minWidth: "10px" },
  // }
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthyClubDetailContainer);
export default function Excel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.963" height="30.904" viewBox="0 0 32.963 30.904">
      <g id="excel" transform="translate(0 0)">
        <path id="Path_20083" data-name="Path 20083" d="M271.451,102.673H257.03a1.03,1.03,0,0,1-1.03-1.03v-20.6a1.03,1.03,0,0,1,1.03-1.03h14.421a1.03,1.03,0,0,1,1.03,1.03v20.6A1.03,1.03,0,0,1,271.451,102.673Z" transform="translate(-239.518 -75.889)" fill="#eceff1" />
        <g id="Group_14422" data-name="Group 14422" transform="translate(16.481 8.242)">
          <path id="Path_20084" data-name="Path 20084" d="M261.15,146.071h-4.12a1.03,1.03,0,1,1,0-2.06h4.12a1.03,1.03,0,1,1,0,2.06Z" transform="translate(-256 -144.011)" fill="#388e3c" />
          <path id="Path_20085" data-name="Path 20085" d="M261.15,210.071h-4.12a1.03,1.03,0,1,1,0-2.06h4.12a1.03,1.03,0,1,1,0,2.06Z" transform="translate(-256 -203.891)" fill="#388e3c" />
          <path id="Path_20086" data-name="Path 20086" d="M261.15,274.071h-4.12a1.03,1.03,0,0,1,0-2.06h4.12a1.03,1.03,0,0,1,0,2.06Z" transform="translate(-256 -263.77)" fill="#388e3c" />
          <path id="Path_20087" data-name="Path 20087" d="M261.15,338.071h-4.12a1.03,1.03,0,0,1,0-2.06h4.12a1.03,1.03,0,0,1,0,2.06Z" transform="translate(-256 -323.65)" fill="#388e3c" />
          <path id="Path_20088" data-name="Path 20088" d="M387.09,146.071h-2.06a1.03,1.03,0,1,1,0-2.06h2.06a1.03,1.03,0,0,1,0,2.06Z" transform="translate(-375.759 -144.011)" fill="#388e3c" />
          <path id="Path_20089" data-name="Path 20089" d="M387.09,210.071h-2.06a1.03,1.03,0,1,1,0-2.06h2.06a1.03,1.03,0,0,1,0,2.06Z" transform="translate(-375.759 -203.891)" fill="#388e3c" />
          <path id="Path_20090" data-name="Path 20090" d="M387.09,274.071h-2.06a1.03,1.03,0,1,1,0-2.06h2.06a1.03,1.03,0,1,1,0,2.06Z" transform="translate(-375.759 -263.77)" fill="#388e3c" />
          <path id="Path_20091" data-name="Path 20091" d="M387.09,338.071h-2.06a1.03,1.03,0,1,1,0-2.06h2.06a1.03,1.03,0,1,1,0,2.06Z" transform="translate(-375.759 -323.65)" fill="#388e3c" />
        </g>
        <path id="Path_20092" data-name="Path 20092" d="M18.169,16.228a1.012,1.012,0,0,0-.847-.218L.841,19.1A1.027,1.027,0,0,0,0,20.111V42.773a1.029,1.029,0,0,0,.841,1.012l16.481,3.09a.969.969,0,0,0,.19.019,1.03,1.03,0,0,0,1.03-1.03V17.021A1.027,1.027,0,0,0,18.169,16.228Z" transform="translate(0 -15.99)" fill="#2e7d32" />
        <path id="Path_20093" data-name="Path 20093" d="M89,169.649l-3.257-3.723,3.294-4.236a1.03,1.03,0,1,0-1.625-1.267l-3.051,3.923-2.571-2.938a1.029,1.029,0,0,0-1.549,1.356L83.073,166,80.2,169.694a1.031,1.031,0,0,0,1.628,1.265l2.627-3.379,3,3.422A1.029,1.029,0,1,0,89,169.649Z" transform="translate(-74.834 -150.753)" fill="#fafafa" />
      </g>
    </svg>

  );
}

import styled from 'styled-components';

export const DepartmentDetailContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 15px 32px 80px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .layer_one {
    display: flex;
    padding-bottom: 10px;
  }
  .layer_two {
    margin-top: 43px;
  }
  .layer_three {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    .left_three {
      width: 100%;
    }
    .right_three {
      width: 464px;
      flex-shrink: 0;
      .t_three {
        margin-bottom: 24px;
      }
      .b_three {
        display: flex;
        justify-content: space-between;
        .box_three {
          width: 48%;
        }
      }
    }
  }
  .c_row_3_col {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    margin-top: 32px;
    row-gap: 16px;
    .item_wrap {
      padding: 0 16px;
      width: calc(33.33% - 32px);
    }
  }
`;

export default function PeoplePercent({ active, color = "#0B408D" }) {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.589"
      height="34.041"
      viewBox="0 0 14.589 34.041"
    >
      <g
        id="Group_33150"
        data-name="Group 33150"
        transform="translate(0 -2.852)"
      >
        <g
          id="Group_14075"
          data-name="Group 14075"
          transform="translate(2.405 2.852)"
        >
          <g id="Group_1238" data-name="Group 1238" transform="translate(0)">
            <ellipse
              id="Ellipse_306"
              data-name="Ellipse 306"
              cx="4.879"
              cy="4.849"
              rx="4.879"
              ry="4.849"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_14076"
          data-name="Group 14076"
          transform="translate(0 15.01)"
        >
          <g id="Group_1240" data-name="Group 1240">
            <path
              id="Path_3276"
              data-name="Path 3276"
              d="M174.58,170.786l-1.1-8.6A2.422,2.422,0,0,0,171.06,160h-7.525a2.417,2.417,0,0,0-2.417,2.157l-1.106,8.632a1.218,1.218,0,0,0,1.206,1.369h1.362l.965,7.537a2.427,2.427,0,0,0,2.422,2.188h2.662a2.419,2.419,0,0,0,2.417-2.157l.968-7.569h1.362a1.213,1.213,0,0,0,.912-.413A1.2,1.2,0,0,0,174.58,170.786Z"
              transform="translate(-160.001 -160)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.589"
      height="34.041"
      viewBox="0 0 14.589 34.041"
    >
      <g
        id="Group_33151"
        data-name="Group 33151"
        transform="translate(-129.254 -2.852)"
      >
        <g
          id="Group_14091"
          data-name="Group 14091"
          transform="translate(131.659 2.852)"
        >
          <g id="Group_1238" data-name="Group 1238" transform="translate(0)">
            <ellipse
              id="Ellipse_306"
              data-name="Ellipse 306"
              cx="4.879"
              cy="4.849"
              rx="4.879"
              ry="4.849"
              fill="#939a99"
            />
          </g>
        </g>
        <g
          id="Group_14092"
          data-name="Group 14092"
          transform="translate(129.254 15.01)"
        >
          <g id="Group_1240" data-name="Group 1240">
            <path
              id="Path_3276"
              data-name="Path 3276"
              d="M174.58,170.786l-1.1-8.6A2.422,2.422,0,0,0,171.06,160h-7.525a2.417,2.417,0,0,0-2.417,2.157l-1.106,8.632a1.218,1.218,0,0,0,1.206,1.369h1.362l.965,7.537a2.427,2.427,0,0,0,2.422,2.188h2.662a2.419,2.419,0,0,0,2.417-2.157l.968-7.569h1.362a1.213,1.213,0,0,0,.912-.413A1.2,1.2,0,0,0,174.58,170.786Z"
              transform="translate(-160.001 -160)"
              fill="#939a99"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

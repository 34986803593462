export default function Calendar() {
  return (
    <svg id="_018-calendar" data-name="018-calendar" xmlns="http://www.w3.org/2000/svg" width="18.277" height="18.454" viewBox="0 0 18.277 18.454">
      <path id="Path_1865" data-name="Path 1865" d="M23.392,46.407V58.532a1.061,1.061,0,0,0,1.061,1.061H40.608a1.061,1.061,0,0,0,1.061-1.061V46.407Z" transform="translate(-23.392 -41.139)" fill="#eeefee" />
      <path id="Path_1866" data-name="Path 1866" d="M23.392,30.131v2.841H41.669V30.131a1.061,1.061,0,0,0-1.061-1.061H24.453a1.061,1.061,0,0,0-1.061,1.061Z" transform="translate(-23.392 -27.704)" fill="#ef5261" />
      <circle id="Ellipse_169" data-name="Ellipse 169" cx="0.969" cy="0.969" r="0.969" transform="translate(2.853 2.124)" fill="#eeefee" />
      <circle id="Ellipse_170" data-name="Ellipse 170" cx="0.969" cy="0.969" r="0.969" transform="translate(13.485 2.124)" fill="#eeefee" />
      <path id="Path_1867" data-name="Path 1867" d="M38.458,23a.557.557,0,0,0-.558.557V25.9a.557.557,0,1,0,1.114,0V23.557A.557.557,0,0,0,38.458,23Z" transform="translate(-34.635 -23)" fill="#828a9e" />
      <path id="Path_1868" data-name="Path 1868" d="M85.7,23a.557.557,0,0,0-.557.557V25.9a.557.557,0,0,0,1.114,0V23.557A.557.557,0,0,0,85.7,23Z" transform="translate(-71.248 -23)" fill="#828a9e" />
      <g id="Group_478" data-name="Group 478" transform="translate(2.808 9.275)">
        <path id="Path_1869" data-name="Path 1869" d="M38.664,67.673A1.936,1.936,0,0,1,38.3,69a1.379,1.379,0,0,1-1.065.39,1.316,1.316,0,0,1-1-.385,1.411,1.411,0,0,1-.368-1.016V67.85h.676q0,.93.69.929a.733.733,0,0,0,.558-.2,1.331,1.331,0,0,0,.194-.867V64.215h.673Z" transform="translate(-35.871 -64.215)" fill="#575b6d" />
        <path id="Path_1870" data-name="Path 1870" d="M55.529,69.3H54.77l-.589-1.547H52.051L51.488,69.3h-.71l1.952-5.084h.725Zm-1.547-2.081-.895-2.358-.853,2.358Z" transform="translate(-47.423 -64.215)" fill="#575b6d" />
        <path id="Path_1871" data-name="Path 1871" d="M78.379,69.3h-.69l-2.663-4.013V69.3h-.638V64.215h.687l2.664,4.013V64.215h.642Z" transform="translate(-65.719 -64.215)" fill="#575b6d" />
      </g>
    </svg>

  );
}

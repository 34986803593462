import styled from 'styled-components';

export const CreateDepartmentFormStyled = styled.div`
  .id_form {
    width: 35%;
    .group_input {
      margin-bottom: 37px;
      &.no_margin {
        margin-bottom: 0px;
      }
      .input_sub {
        margin-top: 11px;
      }
    }
  }
  .top_form {
    display: flex;
    .l_top_form {
      width: 35%;
      margin-right: 13%;
      .group_input {
        margin-bottom: 37px;
        &.no_margin {
          margin-bottom: 0px;
        }
        .input_sub {
          margin-top: 11px;
        }
      }
    }
    .r_top_form {
      width: 35%;
      .group_input_right {
        margin-bottom: 37px;
        &.no_margin {
          margin-bottom: 0px;
        }
        .input_sub {
          margin-top: 11px;
        }
      }
    }
  }
  .bottom_form {
    margin-top: 114px;
    width: 83%;
    display: flex;
    justify-content: flex-end;
    .btn {
      flex-shrink: 0;
      margin-right: 0px;
      &.margin_right {
        margin-right: 24px;
      }
    }
  }
`;

export default function Popular1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="163.081" viewBox="0 0 168 163.081">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-180 -1261)">
        <g id="data-analysis" transform="translate(180 1223.762)">
          <path id="Path_20964" data-name="Path 20964" d="M112.456,220.271a5.8,5.8,0,0,1-5.8,5.8H5.8a5.8,5.8,0,0,1-5.8-5.8V84.242l112.456-5.8Z" transform="translate(0 -25.749)" fill="#edebed" />
          <path id="Path_20965" data-name="Path 20965" d="M256.466,93.9V229.929a5.8,5.8,0,0,1-5.8,5.8H239.075a5.8,5.8,0,0,0,5.8-5.8V222.2l-31.109-50.818L244.872,93.9Z" transform="translate(-133.575 -35.407)" fill="#dbd9dc" />
          <path id="Path_20966" data-name="Path 20966" d="M112.456,58.493H111.3l-52.17,8.941L11.594,58.493H0V43.035a5.8,5.8,0,0,1,5.8-5.8H106.659a5.8,5.8,0,0,1,5.8,5.8Z" fill="#c3aff0" />
          <path id="Path_20967" data-name="Path 20967" d="M298.63,43.037V58.495H287.037V43.037a5.8,5.8,0,0,0-5.8-5.8h11.594A5.8,5.8,0,0,1,298.63,43.037Z" transform="translate(-175.74 -0.001)" fill="#a69ded" />
          <path id="Path_20968" data-name="Path 20968" d="M130.608,222.2a1.932,1.932,0,0,1-1.932,1.932H32.837A1.932,1.932,0,0,1,30.9,222.2V93.9h99.7Z" transform="translate(-19.312 -35.405)" fill="#fff" />
          <path id="Path_20969" data-name="Path 20969" d="M118.426,156.493H63.743a1.932,1.932,0,0,1-1.932-1.932V126.736a1.932,1.932,0,0,1,1.932-1.932H136.4a1.932,1.932,0,0,1,1.932,1.932v13.526Z" transform="translate(-38.624 -54.718)" fill="#bde4ff" />
          <g id="Group_14561" data-name="Group 14561" transform="translate(20.289 110.47)">
            <path id="Path_20970" data-name="Path 20970" d="M90.025,300.066H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -271.083)" fill="#dbd9dc" />
            <path id="Path_20971" data-name="Path 20971" d="M90.025,269.161H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -251.771)" fill="#dbd9dc" />
            <path id="Path_20972" data-name="Path 20972" d="M93.889,238.255H56.983a2.9,2.9,0,1,1,0-5.8H93.889a2.9,2.9,0,1,1,0,5.8Z" transform="translate(-54.085 -232.459)" fill="#dbd9dc" />
          </g>
          <path id="Path_20973" data-name="Path 20973" d="M56.983,398.58a2.9,2.9,0,0,1-2.9-2.9v-5.8a2.9,2.9,0,1,1,5.8,0v5.8A2.9,2.9,0,0,1,56.983,398.58Z" transform="translate(-33.796 -218.55)" fill="#bde4ff" />
          <path id="Path_20974" data-name="Path 20974" d="M87.889,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,87.889,385.706Z" transform="translate(-53.109 -205.675)" fill="#fc8d94" />
          <path id="Path_20975" data-name="Path 20975" d="M149.7,359.956a2.9,2.9,0,0,1-2.9-2.9V328.074a2.9,2.9,0,1,1,5.8,0v28.984A2.9,2.9,0,0,1,149.7,359.956Z" transform="translate(-91.733 -179.926)" fill="#fc8d94" />
          <path id="Path_20976" data-name="Path 20976" d="M180.605,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,180.605,385.706Z" transform="translate(-111.045 -205.675)" fill="#bde4ff" />
          <path id="Path_20977" data-name="Path 20977" d="M118.794,372.831a2.9,2.9,0,0,1-2.9-2.9V348.678a2.9,2.9,0,1,1,5.8,0v21.255A2.9,2.9,0,0,1,118.794,372.831Z" transform="translate(-72.421 -192.8)" fill="#bde4ff" />
          <path id="Path_20979" data-name="Path 20979" d="M200.563,233.647a49.081,49.081,0,0,1,28.906-83.441c13.533.156,28.912,7.629,39.972,18.689,0,0,22.414,30.049-3.349,62.154,0,0-15.748,16.134-36.615,16.633a48.872,48.872,0,0,1-28.915-14.034Z" transform="translate(-116.345 -70.591)" fill="#ffe07d" />
          <path id="Path_20980" data-name="Path 20980" d="M356.447,198.371a49.1,49.1,0,0,1-54.872,48.738,49.08,49.08,0,0,0,0-97.473,49.075,49.075,0,0,1,54.872,48.735Z" transform="translate(-188.447 -70.02)" fill="#ffd064" />
          <path id="Path_20981" data-name="Path 20981" d="M228.074,244.192a37.485,37.485,0,1,1,53.012,0A37.485,37.485,0,0,1,228.074,244.192Z" transform="translate(-135.657 -89.334)" fill="#fff" />
        </g>
        <g id="_21-tteok_bokki" data-name="21-tteok bokki" transform="translate(258.004 1311.005)">
          <circle id="Ellipse_1934" data-name="Ellipse 1934" cx="40" cy="40" r="40" transform="translate(0.996 0.995)" fill="#e4e4e4" />
          <circle id="Ellipse_1935" data-name="Ellipse 1935" cx="37" cy="37" r="37" transform="translate(3.996 3.995)" fill="#5c4535" />
          <circle id="Ellipse_1936" data-name="Ellipse 1936" cx="33" cy="33" r="33" transform="translate(7.996 7.995)" fill="#ffffec" />
          <circle id="Ellipse_1937" data-name="Ellipse 1937" cx="33" cy="33" r="33" transform="translate(7.996 7.995)" fill="#da3427" />
          <path id="Path_20843" data-name="Path 20843" d="M55.034,14.031a3.189,3.189,0,0,0-.995,1.068,36.044,36.044,0,0,1,4.068,6.487,3.13,3.13,0,0,0,2.934-2.761,39.921,39.921,0,0,0-3.218-5.152,3.213,3.213,0,0,0-2.79.357Z" transform="translate(15.395 3.63)" fill="#a0775b" />
          <path id="Path_20844" data-name="Path 20844" d="M49.727,59.078a3.19,3.19,0,0,0-.759-1.236,36.2,36.2,0,0,1-26.631.477,3.168,3.168,0,0,0-.222,4.027,40,40,0,0,0,27.224-.488,3.209,3.209,0,0,0,.387-2.78Z" transform="translate(5.951 16.498)" fill="#a0775b" />
          <path id="Path_20845" data-name="Path 20845" d="M57.1,50.354a3.2,3.2,0,0,0-1.255-.729,36.3,36.3,0,0,1-9.3,8.129,3.171,3.171,0,0,0,3.037,2.658,40.227,40.227,0,0,0,8.492-7.425,3.211,3.211,0,0,0-.977-2.632Z" transform="translate(13.221 14.113)" fill="#a0775b" />
          <path id="Path_20846" data-name="Path 20846" d="M61.856,21.045a3.162,3.162,0,0,0-4.015.366,36.187,36.187,0,0,1,1.29,24.2,3.183,3.183,0,0,0,4.038-.053,40.065,40.065,0,0,0-1.319-24.515Z" transform="translate(16.499 5.658)" fill="#a0775b" />
          <path id="Path_20847" data-name="Path 20847" d="M6.079,21.174a3.19,3.19,0,0,0,1.391.414A36.2,36.2,0,0,1,11.536,15.1a3.164,3.164,0,0,0-3.782-1.427,39.98,39.98,0,0,0-3.226,5.161,3.21,3.21,0,0,0,1.55,2.339Z" transform="translate(1.025 3.628)" fill="#a0775b" />
          <path id="Path_20848" data-name="Path 20848" d="M19.493,59.093a3.2,3.2,0,0,0,.555-1.337,36.314,36.314,0,0,1-9.29-8.129,3.164,3.164,0,0,0-2.23,3.355,40.173,40.173,0,0,0,8.483,7.428,3.2,3.2,0,0,0,2.481-1.317Z" transform="translate(2.181 14.114)" fill="#a0775b" />
          <path id="Path_20849" data-name="Path 20849" d="M5.046,46.237a3.187,3.187,0,0,0,1.316-.626,36.181,36.181,0,0,1,1.29-24.2,3.161,3.161,0,0,0-4.015-.368A40.053,40.053,0,0,0,2.32,45.56a3.191,3.191,0,0,0,2.721.676Z" transform="translate(0 5.658)" fill="#a0775b" />
          <path id="Path_20850" data-name="Path 20850" d="M62.7,41.215a3.207,3.207,0,0,1-.668-.071,3.171,3.171,0,0,1-1.315-.624,36.03,36.03,0,0,1-6.571,12.469,3.165,3.165,0,0,1,2.228,3.355A40.343,40.343,0,0,1,52.4,60.356,39.945,39.945,0,0,0,65.317,38.035q-.241,1.227-.557,2.427a3.226,3.226,0,0,1-2.061.754Z" transform="translate(14.92 10.749)" fill="#886454" />
          <path id="Path_20851" data-name="Path 20851" d="M58.582,20.565a3.184,3.184,0,0,1-1.39.414q.443.911.837,1.853a3.162,3.162,0,0,1,4.015-.366A39.853,39.853,0,0,0,58.622,15.6q.8,1.28,1.5,2.619a3.206,3.206,0,0,1-1.541,2.346Z" transform="translate(16.31 4.238)" fill="#886454" />
          <path id="Path_20852" data-name="Path 20852" d="M10.193,17.661a3.19,3.19,0,0,1,.995,1.068,36.1,36.1,0,0,1,56.873,0A3.162,3.162,0,0,1,71.845,17.3,39.988,39.988,0,0,0,5.729,19.772Q6.522,18.5,7.406,17.3a3.2,3.2,0,0,1,2.787.359Z" transform="translate(1.374)" fill="#886454" />
          <path id="Path_20853" data-name="Path 20853" d="M23.486,60.139a3.174,3.174,0,0,1,.714-1.263,36,36,0,0,1-6.064-2.948A3.17,3.17,0,0,1,15.1,58.582,39.859,39.859,0,0,0,23.972,62.9,3.2,3.2,0,0,1,23.486,60.139Z" transform="translate(4.094 15.943)" fill="#886454" />
          <path id="Path_20854" data-name="Path 20854" d="M48.464,57.264a3.2,3.2,0,0,1-.555-1.335A36.083,36.083,0,0,1,43.058,58.4a3.164,3.164,0,0,1,.365,4.017c-.516.2-1.044.387-1.573.561a39.758,39.758,0,0,0,9.1-4.4A3.2,3.2,0,0,1,48.464,57.264Z" transform="translate(11.858 15.943)" fill="#886454" />
          <path id="Path_20855" data-name="Path 20855" d="M5.824,22.073a3.183,3.183,0,0,1,1.236.76q.387-.941.837-1.854A3.13,3.13,0,0,1,4.962,18.22q.7-1.342,1.507-2.62A39.748,39.748,0,0,0,3.05,22.465a3.21,3.21,0,0,1,2.774-.392Z" transform="translate(0.596 4.238)" fill="#886454" />
          <path id="Path_20856" data-name="Path 20856" d="M12.76,52.987A36.03,36.03,0,0,1,6.189,40.519a3.184,3.184,0,0,1-4.037-.05q-.317-1.2-.559-2.428a39.849,39.849,0,0,0,8.938,18.306,3.164,3.164,0,0,1,2.23-3.355Z" transform="translate(0.173 10.751)" fill="#886454" />
          <path id="Path_20857" data-name="Path 20857" d="M39.546,56.385A33.553,33.553,0,0,1,6.4,28a33.546,33.546,0,1,0,66.3,0,33.554,33.554,0,0,1-33.15,28.385Z" transform="translate(1.452 7.837)" fill="#91000c" />
          <path id="Path_20858" data-name="Path 20858" d="M63.891,61.074A4.858,4.858,0,0,1,61.8,62.442c-2.153.55-4.078-1.548-6.271-1.9A6.725,6.725,0,0,0,50.444,62.4,33.75,33.75,0,0,1,46.1,65.927a4.82,4.82,0,0,1-5.246.055c-1.161-.9-1.618-2.479-2.6-3.591a1.032,1.032,0,0,0-.658-.414.9.9,0,0,0-.605.245c-1.258,1.015-1.132,3.018-1.995,4.4-1.284,2.054-4.282,2.058-6.486,1.125a12.052,12.052,0,0,1-5.61-4.922,29.393,29.393,0,0,0-1.922-3.311c-2.356-2.993-6.674-3.355-9.789-5.5a9.82,9.82,0,0,1-4.174-7.247A9.087,9.087,0,0,1,10.3,39.109a3.153,3.153,0,0,0,.966-.975,2.76,2.76,0,0,0,.148-1.445l-.421-5.48c-.137-1.8-.183-3.859,1.1-5.1,1.232-1.2,3.226-1.135,4.6-2.147s1.871-2.871,2.673-4.419a20.048,20.048,0,0,1,4.244-5.05,26.048,26.048,0,0,1,5.338-4.258A8.576,8.576,0,0,1,35.5,9.3c1.024.324,1.956.9,2.968,1.248,2.633.894,5.487.15,8.234-.258a14.253,14.253,0,0,1,6.891.361,6.991,6.991,0,0,1,4.614,4.916c.516,2.547-.774,5.067-1.261,7.612a12.7,12.7,0,0,0,3.663,11.413c1.3,1.221,2.846,2.137,4.157,3.343a13.062,13.062,0,0,1,2.1,16.6,5.122,5.122,0,0,0-.935,3.053,6.322,6.322,0,0,1-2.041,3.482Z" transform="translate(1.742 2.329)" fill="#7a000a" />
          <path id="Path_20859" data-name="Path 20859" d="M7.022,39.494A9.82,9.82,0,0,0,11.2,46.741c3.115,2.15,7.433,2.507,9.789,5.5a29.392,29.392,0,0,1,1.922,3.311,12.052,12.052,0,0,0,5.61,4.922c2.2.933,5.2.929,6.486-1.125.863-1.381.737-3.383,1.995-4.4a.911.911,0,0,1,.605-.245,1.032,1.032,0,0,1,.658.414c.978,1.112,1.435,2.681,2.6,3.591a4.82,4.82,0,0,0,5.246-.055,33.751,33.751,0,0,0,4.339-3.531,6.725,6.725,0,0,1,5.082-1.851c2.193.352,4.117,2.451,6.271,1.9A4.858,4.858,0,0,0,63.89,53.8a6.322,6.322,0,0,0,2.046-3.484,5.122,5.122,0,0,1,.93-3.051,13.242,13.242,0,0,0,2.039-7.787,12.774,12.774,0,0,0-.316-2.141A33.531,33.531,0,0,1,8.35,34.076a9.263,9.263,0,0,0-1.328,5.418Z" transform="translate(1.743 9.6)" fill="#690009" />
          <path id="Path_20860" data-name="Path 20860" d="M46.02,12.361a3.894,3.894,0,0,1,1.819,5.122L40.886,32.8c-.09-.1-.181-.206-.284-.31A3.871,3.871,0,0,0,34,35.224V29.883l7.38-16.231v-.013l.013-.026A3.742,3.742,0,0,1,44.451,12a3.638,3.638,0,0,1,1.569.361Z" transform="translate(9.579 3.193)" fill="#ff8340" />
          <path id="Path_20861" data-name="Path 20861" d="M41.027,9.361a3.894,3.894,0,0,1,1.819,5.122l-.013.026v.013L35.457,30.753v-1.11a3.862,3.862,0,0,0-6.476-2.851L36.05,11.219A3.742,3.742,0,0,1,39.456,9a3.64,3.64,0,0,1,1.57.361Z" transform="translate(8.122 2.322)" fill="#ff8340" />
          <path id="Path_20862" data-name="Path 20862" d="M33.681,54.239a3.85,3.85,0,1,1-5.445,5.445L10.124,41.569a3.85,3.85,0,1,1,5.444-5.445Z" transform="translate(2.322 9.867)" fill="#ff8340" />
          <path id="Path_20863" data-name="Path 20863" d="M33.516,50.516a2.283,2.283,0,0,0,.013.3,3.845,3.845,0,0,1-5.29-.129l-3.755-3.755-14.36-14.36a3.85,3.85,0,1,1,5.444-5.445l2.465,2.465,7.741,7.741,7.741,7.741Z" transform="translate(2.322 7.255)" fill="#ff8340" />
          <path id="Path_20864" data-name="Path 20864" d="M23.741,37.644v2.3L16,32.2V20.871a3.871,3.871,0,1,1,7.741,0Z" transform="translate(4.355 4.644)" fill="#ff8340" />
          <path id="Path_20865" data-name="Path 20865" d="M29.741,29.263V39.676a3.871,3.871,0,1,1-7.741,0v-25.8a3.871,3.871,0,0,1,7.741,0Z" transform="translate(6.096 2.612)" fill="#ff8340" />
          <path id="Path_20866" data-name="Path 20866" d="M41.741,30.87v25.8a3.871,3.871,0,1,1-7.741,0V30.87a3.871,3.871,0,0,1,6.606-2.735c.1.1.194.206.284.31A3.871,3.871,0,0,1,41.741,30.87Z" transform="translate(9.579 7.547)" fill="#ff8340" />
          <path id="Path_20867" data-name="Path 20867" d="M35.742,32.322V51.676a3.871,3.871,0,0,1-7.727.3,2.284,2.284,0,0,1-.014-.3V25.78a3.871,3.871,0,0,1,7.741.09Z" transform="translate(7.838 6.095)" fill="#ff8340" />
          <path id="Path_20868" data-name="Path 20868" d="M40.58,47.526l-.658-.168A2.481,2.481,0,0,1,38,44.984V42.12l24.515,6.3v1.419a2.533,2.533,0,0,1-2.593,2.451,2.838,2.838,0,0,1-.645-.077Z" transform="translate(10.74 11.935)" fill="#e35f17" />
          <path id="Path_20869" data-name="Path 20869" d="M62.515,50.756v.31L38,44.769l2.632-1.355,19.9-10.232.09-.039a1.29,1.29,0,0,1,1.9,1.123Z" transform="translate(10.74 9.286)" fill="#ffaf85" />
          <path id="Path_20870" data-name="Path 20870" d="M59.205,44.637a3.568,3.568,0,0,1-2.418.541A3.205,3.205,0,0,1,55.1,44.043c-.446-.528-.795-1.129-1.212-1.677a8.488,8.488,0,0,0-2.83-2.4,3.044,3.044,0,0,1-1.348-.993,1.655,1.655,0,0,1-.03-1.452c.012-.03.035-.052.049-.08l-9.1,4.68L38,43.477l24.515,6.3v-7.29c-1.124.644-2.168,1.524-3.309,2.153Z" transform="translate(10.74 10.577)" fill="#ff8340" />
          <path id="Path_20871" data-name="Path 20871" d="M54.194,43.859a3.2,3.2,0,0,1-1.689-1.135c-.446-.528-.795-1.129-1.212-1.677a8.488,8.488,0,0,0-2.829-2.4,3.044,3.044,0,0,1-1.348-.993c-.021-.036-.028-.076-.045-.112a1.641,1.641,0,0,0,.045,1.4,3.044,3.044,0,0,0,1.348.993,8.489,8.489,0,0,1,2.83,2.392c.417.551.765,1.152,1.212,1.677a3.2,3.2,0,0,0,1.689,1.135,3.568,3.568,0,0,0,2.418-.541c1.142-.63,2.186-1.51,3.309-2.153V41.16c-1.124.645-2.168,1.524-3.309,2.153a3.568,3.568,0,0,1-2.418.546Z" transform="translate(13.333 10.606)" fill="#eb8a56" />
          <path id="Path_20872" data-name="Path 20872" d="M43.112,46.382c.924.354,1.759.9,2.66,1.306a14.246,14.246,0,0,0,7.071.873,9.309,9.309,0,0,1,4.9.321,22.58,22.58,0,0,1,2.747,2.086,6.1,6.1,0,0,0,1.221.637,2.369,2.369,0,0,0,.809-1.77V48.416L38,42.12v2.864a2.359,2.359,0,0,0,.434,1.347,6.967,6.967,0,0,1,4.678.05Z" transform="translate(10.74 11.935)" fill="#c95414" />
          <path id="Path_20873" data-name="Path 20873" d="M32.051,50.028a4.6,4.6,0,0,1-.814-4.271c.341-1.475,1.032-2.853,1.33-4.338a8.258,8.258,0,0,0,.077-2.746c-.117-.833-.363-1.646-.48-2.48-.152-1.08-.09-2.187-.321-3.253A11.37,11.37,0,0,0,30.252,29.5a14.479,14.479,0,0,1-1.72-3.386,5.122,5.122,0,0,1-.123-2.439A3.842,3.842,0,0,0,28,25.294v25.9a2.284,2.284,0,0,0,.013.3,3.871,3.871,0,0,0,7.723-.258,5.366,5.366,0,0,1-3.685-1.2Z" transform="translate(7.838 6.582)" fill="#e65f16" />
          <path id="Path_20874" data-name="Path 20874" d="M38.051,55.028a4.6,4.6,0,0,1-.814-4.271c.341-1.475,1.032-2.853,1.33-4.338a8.257,8.257,0,0,0,.077-2.746c-.117-.833-.363-1.646-.48-2.48-.152-1.08-.09-2.193-.321-3.253A11.369,11.369,0,0,0,36.252,34.5a14.479,14.479,0,0,1-1.72-3.386,5.122,5.122,0,0,1-.123-2.439A3.842,3.842,0,0,0,34,30.294v25.9a2.283,2.283,0,0,0,.013.3,3.871,3.871,0,0,0,7.723-.258A5.366,5.366,0,0,1,38.051,55.028Z" transform="translate(9.579 8.033)" fill="#e65f16" />
          <path id="Path_20875" data-name="Path 20875" d="M31.85,56.5a3.414,3.414,0,0,1-2.61-1.317c-.672-.751-1.161-1.646-1.831-2.405-1.694-1.935-4.32-2.795-6.187-4.561-1.951-1.848-2.92-4.533-4.8-6.451a17.4,17.4,0,0,0-4.033-2.81,6.254,6.254,0,0,1-2.041-1.539,3.027,3.027,0,0,1-.534-1.25,3.85,3.85,0,0,0,.311,5.062L28.235,59.345a3.837,3.837,0,0,0,6.135-4.46,3.778,3.778,0,0,1-2.52,1.61Z" transform="translate(2.326 10.205)" fill="#e65f16" />
          <path id="Path_20876" data-name="Path 20876" d="M24.482,46.7l3.753,3.755a3.845,3.845,0,0,0,5.29.129,2.284,2.284,0,0,1-.012-.3V44.839l-.819-.819A12.962,12.962,0,0,1,31.25,46c-.455.508-1.089,1.021-1.747.84-.557-.152-.863-.733-1.123-1.249a25.739,25.739,0,0,0-1.467-2.535,8.774,8.774,0,0,0-1.969-2.264,31.678,31.678,0,0,0-4.478-2.081c-3.528-1.866-5.143-6.036-8.143-8.668a7.294,7.294,0,0,1-1.864-1.935,2.159,2.159,0,0,1-.2-1.313c-.043.037-.09.066-.129.106a3.863,3.863,0,0,0,0,5.445Z" transform="translate(2.324 7.487)" fill="#e65f16" />
          <path id="Path_20877" data-name="Path 20877" d="M26.051,38.028a4.6,4.6,0,0,1-.814-4.271c.341-1.475,1.032-2.853,1.33-4.338a8.257,8.257,0,0,0,.077-2.746c-.117-.833-.363-1.646-.48-2.48-.152-1.08-.09-2.193-.321-3.253A11.37,11.37,0,0,0,24.252,17.5a14.479,14.479,0,0,1-1.72-3.386,5.122,5.122,0,0,1-.123-2.439A3.842,3.842,0,0,0,22,13.294v25.9a2.284,2.284,0,0,0,.013.3,3.871,3.871,0,0,0,7.723-.258,5.366,5.366,0,0,1-3.685-1.2Z" transform="translate(6.096 3.099)" fill="#e65f16" />
          <path id="Path_20878" data-name="Path 20878" d="M21.326,30.169a4.241,4.241,0,0,1-1.713-1.307,3.849,3.849,0,0,1-.45-2.595,20.087,20.087,0,0,0,.306-2.67,13.711,13.711,0,0,0-1.032-3.885,7.521,7.521,0,0,1-.426-2.322A3.85,3.85,0,0,0,16,20.758V32.086l7.741,7.741V30.918a6.58,6.58,0,0,1-2.415-.75Z" transform="translate(4.355 4.757)" fill="#e65f16" />
          <path id="Path_20879" data-name="Path 20879" d="M22.219,27.793a7.741,7.741,0,0,1-.692,2.992.645.645,0,0,1-.449.381.628.628,0,0,1-.476-.25,14.064,14.064,0,0,1-1.4-2.646,1.944,1.944,0,0,0-.71-.75,2.46,2.46,0,0,0-.692-.213c-.6-.119-1.208-.232-1.8-.4v2.414l7.741,7.741V28.155a5.393,5.393,0,0,1-1.521-.363Z" transform="translate(4.354 7.52)" fill="#db4f02" />
          <path id="Path_20880" data-name="Path 20880" d="M42.167,12.319c-.85.892-1.919,1.548-2.773,2.442a7.33,7.33,0,0,0-1.978,4.224c-.1.921-.028,1.854-.129,2.775a3.691,3.691,0,0,1-1.123,2.467.72.72,0,0,1-.464.209.81.81,0,0,1-.372-.129,10.838,10.838,0,0,1-1.664-1.173,8.494,8.494,0,0,0-1.706-1.317,3.268,3.268,0,0,0-.879-.268l-2.1,4.632a3.871,3.871,0,0,1,6.481,2.846v1.11l7.38-16.231v-.013l.013-.026a3.922,3.922,0,0,0,.177-2.746,5.12,5.12,0,0,1-.861,1.2Z" transform="translate(8.121 2.938)" fill="#e65f16" />
          <path id="Path_20881" data-name="Path 20881" d="M44.7,14.735a7.651,7.651,0,0,0-.823,4.311,14.869,14.869,0,0,1-.205,4.429,4.949,4.949,0,0,1-4.06,3.676,14.3,14.3,0,0,1-3.672-.556c-.174-.03-.354-.052-.533-.067L34,29.614v5.342a3.871,3.871,0,0,1,6.606-2.735c.1.1.194.206.284.31l6.953-15.315a3.916,3.916,0,0,0-.7-4.29,4.335,4.335,0,0,0-2.448,1.81Z" transform="translate(9.579 3.461)" fill="#db4f02" />
          <ellipse id="Ellipse_1938" data-name="Ellipse 1938" cx="7.5" cy="8" rx="7.5" ry="8" transform="translate(56.996 21.995)" fill="#fcd83c" />
          <path id="Path_20882" data-name="Path 20882" d="M67.662,25.556a23.14,23.14,0,0,1-5.4,10.011A13.036,13.036,0,0,1,43.819,17.132a16.8,16.8,0,0,1,2.555-2.064,23.361,23.361,0,0,1,4.258-2.257A22.252,22.252,0,0,1,59.109,11a9.528,9.528,0,0,1,6.838,2.439c2.76,2.762,2.94,7.523,1.715,12.115Zm-7.018.929A7.741,7.741,0,1,0,52.9,34.227a7.741,7.741,0,0,0,7.741-7.741Z" transform="translate(11.32 2.9)" fill="#fffae8" />
          <path id="Path_20883" data-name="Path 20883" d="M46.4,31.879A13.021,13.021,0,0,1,45.214,14.81a15.766,15.766,0,0,0-1.395,1.214A13.036,13.036,0,1,0,62.256,34.459,15.926,15.926,0,0,0,63.49,33.04,12.893,12.893,0,0,1,46.4,31.879Z" transform="translate(11.321 4.008)" fill="#fff2c4" />
          <path id="Path_20884" data-name="Path 20884" d="M65.354,13.444A9.528,9.528,0,0,0,58.516,11a22.251,22.251,0,0,0-8.477,1.806,23.361,23.361,0,0,0-4.258,2.257,16.8,16.8,0,0,0-2.555,2.064A13.068,13.068,0,0,0,42.041,18.5c.37-.289.75-.577,1.161-.85a23.362,23.362,0,0,1,4.258-2.257,22.251,22.251,0,0,1,8.476-1.806,9.528,9.528,0,0,1,6.838,2.439c2.76,2.76,2.94,7.521,1.715,12.113a24.534,24.534,0,0,1-4.171,8.589,13.325,13.325,0,0,0,1.347-1.161,23.14,23.14,0,0,0,5.4-10.011c1.226-4.587,1.045-9.348-1.715-12.108Z" transform="translate(11.913 2.9)" fill="#fff2c4" />
          <path id="Path_20885" data-name="Path 20885" d="M46.581,21.9a7.685,7.685,0,0,1,1.139-4.022A7.734,7.734,0,1,0,58.345,28.506a7.726,7.726,0,0,1-11.764-6.6Z" transform="translate(12.481 4.9)" fill="#f6a635" />
          <path id="Path_20886" data-name="Path 20886" d="M44.581,23.9a7.685,7.685,0,0,1,1.139-4.022A7.734,7.734,0,1,0,56.345,30.506a7.726,7.726,0,0,1-11.764-6.6Z" transform="translate(11.901 5.481)" fill="#ffefb5" />
          <path id="Path_20887" data-name="Path 20887" d="M49.892,18.326A1.908,1.908,0,0,0,48.86,19.68a2.255,2.255,0,0,0,.559,1.6,5.108,5.108,0,0,0,2.525,1.769,1.59,1.59,0,0,0,1.183,0c1.021-.516,1.08-2.817.563-3.729a2.93,2.93,0,0,0-3.8-.99Z" transform="translate(13.891 4.933)" fill="#ffef96" />
          <path id="Path_20888" data-name="Path 20888" d="M54.885,34.132a2.9,2.9,0,0,0-.754.5L52.6,35.89a1.813,1.813,0,0,0-.474.5,1.232,1.232,0,0,0,.008,1.086,2.292,2.292,0,0,0,.719.827,4.329,4.329,0,0,0,1.877.823,1.3,1.3,0,0,0,.707-.028,1.533,1.533,0,0,0,.613-.563,5.29,5.29,0,0,0,1.1-2.571,2.193,2.193,0,0,0-.258-1.353,1.707,1.707,0,0,0-2.005-.481Z" transform="translate(14.804 9.575)" fill="#ffcbb0" />
          <path id="Path_20889" data-name="Path 20889" d="M12.3,35.5a1.769,1.769,0,0,0-.859.154.774.774,0,0,0-.443.688c.026.366.378.617.7.808a14.245,14.245,0,0,1,1.33.823A8.423,8.423,0,0,0,14.953,39.3a.753.753,0,0,0,.532.037.707.707,0,0,0,.258-.2C17.374,37.34,13.831,35.537,12.3,35.5Z" transform="translate(2.904 10.012)" fill="#ffb15c" />
          <path id="Path_20890" data-name="Path 20890" d="M27.1,49.011a13.562,13.562,0,0,0-2.41-2.161L20.71,43.828a3.162,3.162,0,0,0-1.7-.828,4.147,4.147,0,0,0,1.7,3.192l6.967,6.5c.7.652,2.222,1.186,1.419-.475a16.9,16.9,0,0,0-2-3.206Z" transform="translate(5.228 12.19)" fill="#ffb15c" />
          <path id="Path_20891" data-name="Path 20891" d="M12.3,26.5a1.769,1.769,0,0,0-.859.154.774.774,0,0,0-.443.688c.026.366.378.617.7.808.45.265.91.516,1.33.823A8.423,8.423,0,0,0,14.953,30.3a.754.754,0,0,0,.532.037.707.707,0,0,0,.258-.2C17.374,28.34,13.831,26.537,12.3,26.5Z" transform="translate(2.904 7.4)" fill="#ffb15c" />
          <path id="Path_20892" data-name="Path 20892" d="M24.1,37.011a13.563,13.563,0,0,0-2.41-2.161L17.71,31.828a3.162,3.162,0,0,0-1.7-.828,4.147,4.147,0,0,0,1.7,3.192l6.967,6.5c.7.652,2.222,1.186,1.419-.475a16.9,16.9,0,0,0-2-3.206Z" transform="translate(4.357 8.707)" fill="#ffb15c" />
          <path id="Path_20893" data-name="Path 20893" d="M31.04,23.161a2.124,2.124,0,0,0-1.008,2.211A5.659,5.659,0,0,0,31.2,27.491a21.339,21.339,0,0,1,2.023,4.856c.165.462.439.993.866.974a.832.832,0,0,0,.592-.426,4.011,4.011,0,0,0,.477-2.263A14.475,14.475,0,0,0,34.02,25.16C33.495,23.93,32.376,22.516,31.04,23.161Z" transform="translate(8.417 6.385)" fill="#ffb15c" />
          <path id="Path_20894" data-name="Path 20894" d="M37.04,28.161a2.124,2.124,0,0,0-1.008,2.211A5.659,5.659,0,0,0,37.2,32.491a21.339,21.339,0,0,1,2.023,4.856c.165.462.439.993.866.974a.832.832,0,0,0,.592-.426,4.011,4.011,0,0,0,.477-2.263A14.475,14.475,0,0,0,40.02,30.16c-.525-1.23-1.644-2.644-2.98-2Z" transform="translate(10.159 7.837)" fill="#ffb15c" />
          <path id="Path_20895" data-name="Path 20895" d="M31.409,41.753a2.009,2.009,0,0,0-.409.99,1.484,1.484,0,0,0,.335.9,1.049,1.049,0,0,0,.8.516c.4-.018.706-.461.89-.9a8.207,8.207,0,0,0,.524-2.435,4.041,4.041,0,0,0,0-1.006c-.145-.9-.668-1.138-1.032-.316A17.877,17.877,0,0,1,31.409,41.753Z" transform="translate(8.708 11.028)" fill="#ffb15c" />
          <path id="Path_20896" data-name="Path 20896" d="M25.04,11.161a2.124,2.124,0,0,0-1.008,2.211A5.659,5.659,0,0,0,25.2,15.491a21.339,21.339,0,0,1,2.023,4.856c.165.462.439.993.866.974a.832.832,0,0,0,.592-.426,4.011,4.011,0,0,0,.477-2.263A14.475,14.475,0,0,0,28.02,13.16c-.525-1.23-1.644-2.644-2.98-2Z" transform="translate(6.676 2.902)" fill="#ffb15c" />
          <path id="Path_20897" data-name="Path 20897" d="M25.409,29.753a2.009,2.009,0,0,0-.409.99,1.484,1.484,0,0,0,.335.9,1.049,1.049,0,0,0,.8.516c.4-.018.706-.461.89-.9a8.207,8.207,0,0,0,.524-2.435,4.041,4.041,0,0,0,0-1.006c-.145-.9-.668-1.138-1.032-.316A17.878,17.878,0,0,1,25.409,29.753Z" transform="translate(6.967 7.545)" fill="#ffb15c" />
          <path id="Path_20898" data-name="Path 20898" d="M20.463,18.055a2.051,2.051,0,0,0-1.508.188,1.068,1.068,0,0,0-.258,1.144c.191.743.672,1.419.866,2.159a3.984,3.984,0,0,0,.321,1.09,1.032,1.032,0,0,0,1.08.5,1.183,1.183,0,0,0,.458-.258c1.543-1.283,1.637-4.485-.959-4.827Z" transform="translate(5.118 4.931)" fill="#ffb15c" />
          <path id="Path_20899" data-name="Path 20899" d="M34.435,13.263A3.027,3.027,0,0,0,34,14.588a.654.654,0,0,0,.057.334.347.347,0,0,0,.141.129,1.262,1.262,0,0,0,1.419-.329c.427-.363.8-.859,1.227-1.213.712-.586,1.6-.8,2.131-1.707a1.129,1.129,0,0,0,.163-.828.84.84,0,0,0-.274-.4,1.564,1.564,0,0,0-2.413-.013,8.806,8.806,0,0,0-2.017,2.7Z" transform="translate(9.578 2.613)" fill="#ffb15c" />
          <path id="Path_20900" data-name="Path 20900" d="M32.167,17.283A2.839,2.839,0,0,0,32,18.332a2.729,2.729,0,0,0,.445,1.29,1.516,1.516,0,0,0,.4.47.348.348,0,0,0,.47-.043,1.385,1.385,0,0,0,.218-.4l.681-1.641a2.74,2.74,0,0,0,0-2.71c-.534-.76-.97.108-1.379.715A5.317,5.317,0,0,0,32.167,17.283Z" transform="translate(8.999 4.064)" fill="#ffb15c" />
          <path id="Path_20901" data-name="Path 20901" d="M37.167,21.283A2.839,2.839,0,0,0,37,22.332a2.729,2.729,0,0,0,.445,1.29,1.516,1.516,0,0,0,.4.47.348.348,0,0,0,.47-.043,1.386,1.386,0,0,0,.218-.4l.681-1.641a2.741,2.741,0,0,0,0-2.71c-.534-.76-.97.108-1.379.715a5.317,5.317,0,0,0-.668,1.27Z" transform="translate(10.45 5.225)" fill="#ffb15c" />
          <ellipse id="Ellipse_1939" data-name="Ellipse 1939" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(52.986 66.596) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1940" data-name="Ellipse 1940" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(46.537 70.467) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1941" data-name="Ellipse 1941" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(10.418 31.777) rotate(-45)" fill="#ea6524" />
          <ellipse id="Ellipse_1942" data-name="Ellipse 1942" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(54.275 62.726) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1943" data-name="Ellipse 1943" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(43.041 10.259)" fill="#ea6524" />
          <ellipse id="Ellipse_1944" data-name="Ellipse 1944" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(10.785 42.515)" fill="#91000c" />
          <ellipse id="Ellipse_1945" data-name="Ellipse 1945" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(9.495 37.354)" fill="#ea6524" />
          <ellipse id="Ellipse_1946" data-name="Ellipse 1946" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(11.707 28.904) rotate(-45)" fill="#ea6524" />
          <ellipse id="Ellipse_1947" data-name="Ellipse 1947" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(38.791 12.133) rotate(-45)" fill="#ea6524" />
          <ellipse id="Ellipse_1948" data-name="Ellipse 1948" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(58.144 65.012) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1949" data-name="Ellipse 1949" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(36.59 10.259)" fill="#ea6524" />
          <ellipse id="Ellipse_1950" data-name="Ellipse 1950" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(15.946 59.288)" fill="#cc3a27" />
          <ellipse id="Ellipse_1951" data-name="Ellipse 1951" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(13.366 54.127)" fill="#91000c" />
          <ellipse id="Ellipse_1952" data-name="Ellipse 1952" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(36.924 62.982) rotate(-45)" fill="#91000c" />
          <ellipse id="Ellipse_1953" data-name="Ellipse 1953" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(15.742 29.517)" fill="#cc3a27" />
          <ellipse id="Ellipse_1954" data-name="Ellipse 1954" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(51.869 68.224)" fill="#cc3a27" />
          <ellipse id="Ellipse_1955" data-name="Ellipse 1955" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(40.257 63.063)" fill="#cc3a27" />
          <ellipse id="Ellipse_1956" data-name="Ellipse 1956" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(51.693 44.67) rotate(-45)" fill="#91000c" />
          <ellipse id="Ellipse_1957" data-name="Ellipse 1957" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(33.636 47.251) rotate(-45)" fill="#91000c" />
          <ellipse id="Ellipse_1958" data-name="Ellipse 1958" cx="0.741" cy="0.533" rx="0.741" ry="0.533" transform="translate(37.502 20.165) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1959" data-name="Ellipse 1959" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(57.234 42.515)" fill="#cc3a27" />
          <ellipse id="Ellipse_1960" data-name="Ellipse 1960" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(58.524 46.386)" fill="#91000c" />
          <ellipse id="Ellipse_1961" data-name="Ellipse 1961" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(51.692 39.217) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1962" data-name="Ellipse 1962" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(21.107 16.71)" fill="#ea6524" />
          <ellipse id="Ellipse_1963" data-name="Ellipse 1963" cx="0.533" cy="0.741" rx="0.533" ry="0.741" transform="translate(23.687 19.291)" fill="#cc3a27" />
          <ellipse id="Ellipse_1964" data-name="Ellipse 1964" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(53.704 47.503) rotate(-45)" fill="#91000c" />
          <ellipse id="Ellipse_1965" data-name="Ellipse 1965" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(21.438 65.557) rotate(-45)" fill="#cc3a27" />
          <ellipse id="Ellipse_1966" data-name="Ellipse 1966" cx="2.218" cy="1.593" rx="2.218" ry="1.593" transform="translate(14.11 26.267) rotate(-45)" fill="#ea6524" />
          <ellipse id="Ellipse_1967" data-name="Ellipse 1967" cx="2.218" cy="1.593" rx="2.218" ry="1.593" transform="translate(21.846 15.948) rotate(-45)" fill="#ea6524" />
          <ellipse id="Ellipse_1968" data-name="Ellipse 1968" cx="1.481" cy="1.064" rx="1.481" ry="1.064" transform="translate(38.55 69.931)" fill="#cc3a27" />
          <ellipse id="Ellipse_1969" data-name="Ellipse 1969" cx="1.064" cy="1.481" rx="1.064" ry="1.481" transform="translate(38.967 14.034)" fill="#cc3a27" />
          <path id="Path_20902" data-name="Path 20902" d="M31.8,9.251a1.27,1.27,0,0,1,0,1.768L30.018,12.8a1.27,1.27,0,0,1-1.768,0l-.89-.89a1.27,1.27,0,0,1,0-1.768l1.781-1.781a1.27,1.27,0,0,1,1.768,0Z" transform="translate(7.548 2.032)" fill="#7eb04f" />
          <path id="Path_20903" data-name="Path 20903" d="M14.8,22.141a1.262,1.262,0,0,1,0,1.768l-.89.89a1.27,1.27,0,0,1-1.768,0l-1.781-1.781a1.262,1.262,0,0,1-.271-1.355,1.43,1.43,0,0,1,.271-.413l.89-.89a1.27,1.27,0,0,1,1.768,0Z" transform="translate(2.613 5.515)" fill="#7eb04f" />
          <rect id="Rectangle_18228" data-name="Rectangle 18228" width="4" height="5" rx="1" transform="translate(30.996 32.995)" fill="#7eb04f" />
          <path id="Path_20904" data-name="Path 20904" d="M53.8,39.251a1.27,1.27,0,0,1,0,1.768L52.018,42.8a1.27,1.27,0,0,1-1.768,0l-.89-.89a1.27,1.27,0,0,1,0-1.768l1.781-1.781a1.27,1.27,0,0,1,1.768,0Z" transform="translate(13.933 10.74)" fill="#7eb04f" />
          <path id="Path_20905" data-name="Path 20905" d="M33.161,40.29v1.29a1.29,1.29,0,0,1-1.29,1.29H29.29A1.29,1.29,0,0,1,28,41.58V40.29A1.29,1.29,0,0,1,29.29,39h2.58A1.29,1.29,0,0,1,33.161,40.29Z" transform="translate(7.838 11.029)" fill="#7eb04f" />
          <path id="Path_20906" data-name="Path 20906" d="M44.8,51.251a1.27,1.27,0,0,1,0,1.768L43.018,54.8a1.27,1.27,0,0,1-1.768,0l-.89-.89a1.27,1.27,0,0,1,0-1.768l1.781-1.781a1.27,1.27,0,0,1,1.768,0Z" transform="translate(11.321 14.223)" fill="#7eb04f" />
          <path id="Path_20907" data-name="Path 20907" d="M33.8,54.141a1.27,1.27,0,0,1,0,1.768l-.89.89a1.262,1.262,0,0,1-1.768,0l-1.781-1.781a1.262,1.262,0,0,1-.271-1.355,1.429,1.429,0,0,1,.271-.413l.89-.89a1.27,1.27,0,0,1,1.768,0Z" transform="translate(8.128 14.803)" fill="#7eb04f" />
          <path id="Path_20908" data-name="Path 20908" d="M20.8,37.141a1.27,1.27,0,0,1,0,1.768l-.89.89a1.27,1.27,0,0,1-1.768,0l-1.781-1.781a1.27,1.27,0,0,1,0-1.768l.89-.89a1.27,1.27,0,0,1,1.768,0Z" transform="translate(4.355 9.869)" fill="#7eb04f" />
          <path id="Path_20909" data-name="Path 20909" d="M32.431,55.464l-1.781-1.781a1.253,1.253,0,0,1-.157-1.525,1.219,1.219,0,0,0-.243.157l-.89.89a1.27,1.27,0,0,0,0,1.768l1.781,1.781a1.27,1.27,0,0,0,1.768,0l.89-.89a1.219,1.219,0,0,0,.157-.243,1.253,1.253,0,0,1-1.525-.157Z" transform="translate(8.129 14.848)" fill="#5f873b" />
          <path id="Path_20910" data-name="Path 20910" d="M19.431,38.464l-1.781-1.781a1.253,1.253,0,0,1-.157-1.525,1.219,1.219,0,0,0-.243.157l-.89.89a1.27,1.27,0,0,0,0,1.768l1.781,1.781a1.27,1.27,0,0,0,1.768,0l.89-.89a1.219,1.219,0,0,0,.157-.243,1.253,1.253,0,0,1-1.525-.157Z" transform="translate(4.355 9.914)" fill="#5f873b" />
          <path id="Path_20911" data-name="Path 20911" d="M13.431,23.464l-1.781-1.781a1.253,1.253,0,0,1-.157-1.525,1.219,1.219,0,0,0-.243.157l-.89.89a1.27,1.27,0,0,0,0,1.768l1.781,1.781a1.27,1.27,0,0,0,1.768,0l.89-.89a1.219,1.219,0,0,0,.157-.243,1.253,1.253,0,0,1-1.525-.157Z" transform="translate(2.614 5.561)" fill="#5f873b" />
          <path id="Path_20912" data-name="Path 20912" d="M44.754,51.206l-.89-.89a1.22,1.22,0,0,0-.243-.157,1.253,1.253,0,0,1-.157,1.525l-1.781,1.781a1.253,1.253,0,0,1-1.525.157,1.219,1.219,0,0,0,.157.243l.89.89a1.27,1.27,0,0,0,1.768,0l1.781-1.781a1.27,1.27,0,0,0,0-1.768Z" transform="translate(11.366 14.268)" fill="#5f873b" />
          <path id="Path_20913" data-name="Path 20913" d="M53.754,39.206l-.89-.89a1.22,1.22,0,0,0-.243-.157,1.253,1.253,0,0,1-.157,1.525l-1.781,1.781a1.253,1.253,0,0,1-1.525.157,1.219,1.219,0,0,0,.157.243l.89.89a1.27,1.27,0,0,0,1.768,0l1.781-1.781a1.27,1.27,0,0,0,0-1.768Z" transform="translate(13.979 10.785)" fill="#5f873b" />
          <path id="Path_20914" data-name="Path 20914" d="M31.754,9.206l-.89-.89a1.219,1.219,0,0,0-.243-.157,1.253,1.253,0,0,1-.157,1.525l-1.781,1.781a1.253,1.253,0,0,1-1.525.157,1.219,1.219,0,0,0,.157.243l.89.89a1.27,1.27,0,0,0,1.768,0l1.781-1.781a1.27,1.27,0,0,0,0-1.768Z" transform="translate(7.593 2.078)" fill="#5f873b" />
          <path id="Path_20915" data-name="Path 20915" d="M31.871,41.29H29.29A1.29,1.29,0,0,1,28,40v1.29a1.29,1.29,0,0,0,1.29,1.29h2.58a1.29,1.29,0,0,0,1.29-1.29V40A1.29,1.29,0,0,1,31.871,41.29Z" transform="translate(7.838 11.32)" fill="#5f873b" />
          <path id="Path_20916" data-name="Path 20916" d="M27.871,28.29H25.29A1.29,1.29,0,0,1,24,27v1.29a1.29,1.29,0,0,0,1.29,1.29h2.58a1.29,1.29,0,0,0,1.29-1.29V27A1.29,1.29,0,0,1,27.871,28.29Z" transform="translate(6.677 7.546)" fill="#5f873b" />
          <g id="Group_14538" data-name="Group 14538" transform="translate(10.221 16.999)">
            <ellipse id="Ellipse_1970" data-name="Ellipse 1970" cx="2" cy="0.5" rx="2" ry="0.5" transform="translate(43.775 23.997)" fill="#689441" />
            <ellipse id="Ellipse_1971" data-name="Ellipse 1971" cx="0.645" cy="1.29" rx="0.645" ry="1.29" transform="translate(34.82 30.132) rotate(-21.791)" fill="#689441" />
            <ellipse id="Ellipse_1972" data-name="Ellipse 1972" cx="0.645" cy="1.29" rx="0.645" ry="1.29" transform="translate(47.715 34.007) rotate(-21.791)" fill="#689441" />
            <ellipse id="Ellipse_1973" data-name="Ellipse 1973" cx="0.645" cy="1.29" rx="0.645" ry="1.29" transform="translate(43.855 10.791) rotate(-21.791)" fill="#689441" />
            <ellipse id="Ellipse_1974" data-name="Ellipse 1974" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(19.341 35.918) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1975" data-name="Ellipse 1975" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(45.144 19.164) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1976" data-name="Ellipse 1976" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(11.598 46.232) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1977" data-name="Ellipse 1977" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(19.352 12.703) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1978" data-name="Ellipse 1978" cx="0.645" cy="1.29" rx="0.645" ry="1.29" transform="translate(15.481 15.939) rotate(-21.791)" fill="#689441" />
            <ellipse id="Ellipse_1979" data-name="Ellipse 1979" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(6.451 19.145) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1980" data-name="Ellipse 1980" cx="0.645" cy="1.29" rx="0.645" ry="1.29" transform="translate(10.316 33.991) rotate(-21.791)" fill="#689441" />
            <ellipse id="Ellipse_1981" data-name="Ellipse 1981" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(0 26.881) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1982" data-name="Ellipse 1982" cx="1.29" cy="0.645" rx="1.29" ry="0.645" transform="translate(32.251 8.84) rotate(-68.209)" fill="#689441" />
            <ellipse id="Ellipse_1983" data-name="Ellipse 1983" cx="1.935" cy="0.645" rx="1.935" ry="0.645" transform="translate(47.649 38.458) rotate(-45)" fill="#689441" />
            <ellipse id="Ellipse_1984" data-name="Ellipse 1984" cx="1.935" cy="0.645" rx="1.935" ry="0.645" transform="translate(20.556 46.197) rotate(-45)" fill="#689441" />
            <ellipse id="Ellipse_1985" data-name="Ellipse 1985" cx="0.645" cy="1.935" rx="0.645" ry="1.935" transform="translate(41.197 44.374) rotate(-45)" fill="#689441" />
            <ellipse id="Ellipse_1986" data-name="Ellipse 1986" cx="0.5" cy="1.5" rx="0.5" ry="1.5" transform="translate(29.775 -0.003)" fill="#689441" />
            <ellipse id="Ellipse_1987" data-name="Ellipse 1987" cx="0.645" cy="1.935" rx="0.645" ry="1.935" transform="translate(7.66 3.088) rotate(-45)" fill="#689441" />
            <ellipse id="Ellipse_1988" data-name="Ellipse 1988" cx="1.935" cy="0.645" rx="1.935" ry="0.645" transform="translate(43.781 29.428) rotate(-45)" fill="#689441" />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { RadialBarStyled } from "./styled";

const RadialBar = ({
  value = 0,
  theme_standard,
  theme_green,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_green: theme_green,
  });

  return (
    <RadialBarStyled>
      <div className={customClass}>
        <div className="container_radial">
          <div className={`chart_in x-${value.toFixed(0)}`}>
            <p>{`${value}%`}</p>
          </div>
        </div>
      </div>
    </RadialBarStyled>
  );
};

RadialBar.propTypes = {};

export default RadialBar;

export default function AccountManagement() {
  return (
    <svg
      id="admin"
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 120 120"
    >
      <circle
        id="Ellipse_5162"
        data-name="Ellipse 5162"
        cx="60"
        cy="60"
        r="60"
        fill="#f7f7f7"
      />
      <path
        id="Path_49652"
        data-name="Path 49652"
        d="M133.546,69.46V89.584a2.912,2.912,0,0,1-2.917,2.917h-7.253a45.462,45.462,0,0,1-3.668,8.853l5.13,5.13a2.918,2.918,0,0,1,0,4.124l-14.232,14.23a2.914,2.914,0,0,1-4.124,0l-5.128-5.128a45.412,45.412,0,0,1-8.855,3.668v7.253a2.917,2.917,0,0,1-2.917,2.917H69.46a2.917,2.917,0,0,1-2.917-2.917v-7.253a45.462,45.462,0,0,1-8.853-3.668l-5.13,5.128a2.914,2.914,0,0,1-4.124,0l-14.23-14.23a2.914,2.914,0,0,1,0-4.124l5.128-5.13A45.433,45.433,0,0,1,35.668,92.5H28.415A2.917,2.917,0,0,1,25.5,89.584V69.46a2.916,2.916,0,0,1,2.917-2.917h7.253a45.435,45.435,0,0,1,3.668-8.855l-5.128-5.128a2.917,2.917,0,0,1,0-4.125l14.23-14.23a2.918,2.918,0,0,1,4.124,0l5.13,5.13a45.318,45.318,0,0,1,8.853-3.668V28.415A2.916,2.916,0,0,1,69.46,25.5H89.584A2.916,2.916,0,0,1,92.5,28.415v7.253a45.291,45.291,0,0,1,8.855,3.668l5.128-5.13a2.918,2.918,0,0,1,4.124,0l14.232,14.23a2.92,2.92,0,0,1,0,4.125l-5.13,5.128a45.412,45.412,0,0,1,3.668,8.855h7.253A2.916,2.916,0,0,1,133.546,69.46Z"
        transform="translate(-19.522 -19.522)"
        fill="#537bc1"
      />
      <path
        id="Path_49653"
        data-name="Path 49653"
        d="M236.046,180.109a2.919,2.919,0,0,1-2.48,1.378h-7.253a45.462,45.462,0,0,1-3.668,8.853l5.13,5.13a2.918,2.918,0,0,1,0,4.124l-14.232,14.23a2.914,2.914,0,0,1-4.124,0l-5.128-5.128a45.413,45.413,0,0,1-8.855,3.668v7.253a2.917,2.917,0,0,1-2.917,2.917h-4.185L159.947,172.1l9.534-30.376L203.854,176.1l1.019-27.162Z"
        transform="translate(-122.459 -108.508)"
        fill="#416198"
      />
      <path
        id="Path_49654"
        data-name="Path 49654"
        d="M226.582,346.949V376.74a2.882,2.882,0,0,1-.317,1.319,2.912,2.912,0,0,1-2.6,1.6H203.542a2.917,2.917,0,0,1-2.917-2.917V346.949Z"
        transform="translate(-153.604 -265.633)"
        fill="#fccd30"
      />
      <path
        id="Path_49655"
        data-name="Path 49655"
        d="M226.583,346.949V376.74a2.882,2.882,0,0,1-.317,1.319l-25.64-25.642v-5.468Z"
        transform="translate(-153.604 -265.633)"
        fill="#c29f26"
      />
      <path
        id="Path_49656"
        data-name="Path 49656"
        d="M171.772,141.717v28.055l-12.978,5.065-12.978-5.065V141.717a29.757,29.757,0,1,0,25.957,0Z"
        transform="translate(-98.794 -108.502)"
        fill="#fccd30"
      />
    </svg>
  );
}

import styled from "styled-components";

export const PercentOfEmployeHealthCheckStyled = styled.div`
  .filter_emp_top {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_filter_percent_emp {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .emp_filter_label {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .emp_s_label_filter {
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .emp_box_filer_date_top {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .emp_bw_date {
          width: 113px;
        }
      }
    }
  }
  .box_percent_emp_health_check {
    padding: 24px 125px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    .body_graph_percent_emp_health {
      position: relative;
      .lbl_empty {
        display: flex;
        justify-content: center;
        height: 300px;
        align-items: center;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
      .top_percent_emp_title_g {
        position: absolute;
        left: 32px;
        color: ${({ theme }) => theme.COLORS.BLACK_11};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        font-weight: 300;
      }
      // .right_dep_title_g {
      //   color: ${({ theme }) => theme.COLORS.BLACK_11};
      //   font-size:  ${({ theme }) => theme.FONT.SIZE.S12};
      //   font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      //   font-weight: 300;
      //   position: absolute;
      //   right: -85px;
      //   bottom: 36px;
      // }
      .box_hover {
        // background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-readius: 8px;
        border: 2px solid ${({ theme }) => theme.COLORS.RED_8};
        color: ${({ theme }) => theme.COLORS.BLACK_11};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        font-weight: 300;
        padding: 16px;
        .big_text_s {
          font-weight: 600;
          display: flex;
          column-gap: 10px;
        }
        .stauts_group {
          display: flex;
          column-gap: 3px;
        }
      }
    }
  }
`;

import queryString from "query-string";

export const parseQueryString = (parsed) => {
  if (parsed) {
    const stringified = queryString.stringify(parsed);
    if (stringified) {
      return `?${stringified}`;
    }
    return ``;
  } else {
    return ``;
  }
};

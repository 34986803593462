export default function OrderTable({
  color1 = '#ffe200',
  color2 = '#B28301',
  colorBorder = '#F0D01D',
  colorNumber = '#fff',
  orderNum = '1',
  width = "48",
  height = "48",
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 64 68">
      <defs>
        <linearGradient id={`linear-gradient${orderNum}`} x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
      <g id="Group_33150" data-name="Group 33150" transform="translate(-136 -1192)">
        <ellipse id="Ellipse_1500" data-name="Ellipse 1500" cx="22.066" cy="3.258" rx="22.066" ry="3.258" transform="translate(144 1253.484)" fill="#d8d8d8" />
        <g id="Ellipse_1520" data-name="Ellipse 1520" transform="translate(136 1192)" fill="#fff" stroke={colorBorder} strokeWidth="2">
          <circle cx="32" cy="32" r="32" stroke="none" />
          <circle cx="32" cy="32" r="31" fill="none" />
        </g>
        <circle id="Ellipse_1521" data-name="Ellipse 1521" cx="27" cy="27" r="27" transform="translate(141 1197)" fill={`url(#linear-gradient${orderNum})`} />
        <text id="_1" data-name="#1" transform="translate(186 1235)" fill={colorNumber} fontSize="32" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="-37.359" y="0">#{orderNum}</tspan></text>
      </g>
    </svg>
  );
}

export default function EditGray({ color = '#9e9e9e' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.286"
      height="23.283"
      viewBox="0 0 23.286 23.283"
    >
      <g
        id="Group_33151"
        data-name="Group 33151"
        transform="translate(-708.714 -212.358)"
      >
        <path
          id="Path_20072"
          data-name="Path 20072"
          d="M143.592,189.015h-12.84A2.754,2.754,0,0,1,128,186.263v-12.84a2.754,2.754,0,0,1,2.752-2.752h7.337a.917.917,0,1,1,0,1.834h-7.337a.918.918,0,0,0-.918.918v12.84a.918.918,0,0,0,.918.918h12.84a.918.918,0,0,0,.918-.918v-7.338a.918.918,0,0,1,1.835,0v7.337A2.756,2.756,0,0,1,143.592,189.015Zm0,0"
          transform="translate(580.714 46.627)"
          fill={color}
        />
        <g
          id="Group_14932"
          data-name="Group 14932"
          transform="translate(717.804 212.358)"
        >
          <path
            id="Path_20073"
            data-name="Path 20073"
            d="M226.942,161.887l-7.257,7.257a.459.459,0,0,0-.125.234l-.648,3.243a.459.459,0,0,0,.45.549.406.406,0,0,0,.09-.01l3.243-.648a.47.47,0,0,0,.235-.125l7.257-7.257Zm0,0"
            transform="translate(-218.903 -158.974)"
            fill={color}
          />
          <path
            id="Path_20074"
            data-name="Path 20074"
            d="M328.224,128.678a2.3,2.3,0,0,0-3.243,0l-1.27,1.27,3.243,3.243,1.27-1.27a2.293,2.293,0,0,0,0-3.243Zm0,0"
            transform="translate(-314.699 -128.007)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

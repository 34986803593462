import React from "react";
import { RewardAddDetailsContainerStyled } from "./styled";
import { Tabs, Forms, Loading } from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";

class RewardAddDetailsContainer extends React.Component {
  state = {
    isLoading: true,
    labelNotic:
      this.props.match.params.id !== "create"
        ? {
            label1: "บันทึกสำเร็จ",
            label2: "บันทึกไม่สำเร็จ",
          }
        : {
            label1: "สร้างสำเร็จ",
            label2: "สร้างไม่สำเร็จ",
          },
  };

  componentDidMount() {
    this.scrollToTop();
    if (this.props.match.params.id !== "create") {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_REWARD_ITEM_BY_ID(
      this.props.match.params.id
    );
    if (res && res.success) {
      this.setState({
        isLoading: false,
        data: res.data,
        updateDate: res.data.updated_at && res.data.updated_at,
        initialValues: res.data,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleBackClick = () => {
    if (this.props.match.params.id !== "create") {
      this.props.history.push(
        `${ROUTE_PATH.BACK_OFFICE_REWARD_DETAILS}/${this.props.match.params.id}`
      );
    } else {
      this.props.history.push(ROUTE_PATH.BACK_OFFICE_REWARD);
    }
  };

  onSubmit = async (values) => {
    const { labelNotic } = this.state;

    let params = {
      ...values,
      category_id: values.category_id?.value,
      begin_date: values.begin_date?.value,
      end_date: values.end_date?.value,
    };
    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_REWARD_ITEM(
            this.props.authenRedux?.company?.id,
            params
          )
        : await adminService.PATCH_REWARD_ITEM(
            this.props.match.params.id,
            params
          );
    if (res && res.success) {
      const filesTemp = [
        ...params.files.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(res.data.id, filesTemp);
      } else {
        toast.success(labelNotic.label1);
        this.handleBackClick();
      }
    } else {
      toast.error(labelNotic.label2);
    }
  };

  handleUploadContentMedia = (rewardID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set("type", "cover");
            bodyFormData.append("files[]", e);
            let res = await adminService.POST_UPLOAD_REWARD_IMAGE(
              rewardID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
          if (e.isDelete) {
            const bodyFormData = new FormData();
            let res = await adminService.POST_UPLOAD_REWARD_IMAGE(
              rewardID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      toast.success("สร้างสำเร็จ");
      this.handleBackClick();
    });
  };

  handleDelete = async () => {
    let res = await adminService.DELETE_REWARD_ITEM(
      this.state.initialValues?.id
    );
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.handleBackClick();
    } else {
      toast.error("ลบไม่สำเร็จ");
    }
  };
  render() {
    const { initialValues, isLoading } = this.state;

    return (
      <RewardAddDetailsContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title={initialValues ? "แก้ไขรางวัล" : "เพิ่มรางวัล"}
                subTitle={initialValues ? "Edit Rewards" : "Add Rewards"}
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                margin_right="19px"
                lineHeight="1.3"
                // typeDate
                // dataDate={updateDate}
                onClick={this.handleBackClick}
              />
            </div>
            <div className="layer_two">
              <Forms.AddRewardDetailsForm
                initialValues={initialValues}
                groupCategory={this.props.rewardCategory}
                handleDelete={this.handleDelete}
                onSubmit={this.onSubmit}
              />
            </div>
          </>
        )}
      </RewardAddDetailsContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  rewardCategory: state.rewardCategory,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardAddDetailsContainer);

export default function CheckCorrect({ color = '#198754' }) {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_362_47245)">
        <path d="M11.8243 2.2695C11.59 2.03517 11.2101 2.03517 10.9757 2.2695L3.78737 9.45794L1.02428 6.69485C0.789973 6.46052 0.410098 6.46054 0.175746 6.69485C-0.058582 6.92915 -0.058582 7.30903 0.175746 7.54336L3.36311 10.7307C3.59734 10.965 3.9775 10.9648 4.21164 10.7307L11.8243 3.11803C12.0586 2.88372 12.0586 2.50382 11.8243 2.2695Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_362_47245">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

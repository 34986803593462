import styled from 'styled-components';

export const SpinGameContainerStyled = styled.div`
  .show_loading {
    height: 334px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  min-height: calc(100vh - 200px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};

  .box_spin_layout {
    .top_filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .w378 {
        width: 378px;
      }
      .w163 {
        min-width: 163px;
      }
    }
    .table_box {
      margin-top: 32px;
    }
  }

  .group_icon {
    display: flex;
    column-gap: 12px;
    justify-content: center;
    .box_icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_31};
      border-radius: 4px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
`;

import styled from "styled-components";

export const WeeklyPopularStyled = styled.div`
  .container_pop {
    display: flex;
    column-gap: 10px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: #dedede;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }
    ::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #f0f0f0;
    }
    .label_empty {
      padding: 40px 0px 20px 0px;
      margin: auto;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
    .top_week {
      cursor: pointer;
      width: 100%;
    }
  }
`;

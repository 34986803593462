import styled from 'styled-components';

export const PointAddDeleteFormStyled = styled.div`
  width: 592px;
  padding: 25px;
  border-radius: 8px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .tile_point {
    display: flex;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    align-items: center;
    column-gap: 4px;
    margin-bottom: 17px;
    .svg_point {
      display: flex;
    }
    .label {

    }
  }
  .name_list {
    margin-bottom: 19px;
    .show_name {
      display: flex;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_8};
      .hText {
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        margin-left: 19px;
      }
      &.detail {
        column-gap: 40px;
      }
      .w100 {
        width: 100px;
      }
      .w200 {
        width: 200px;
      }
      .w300 {
        width: 300px;
      }
    }
  }
  .input_zone {
    margin-bottom: 24px;
  }
  .btn_zone {
    display: flex;
    justify-content: center;
    column-gap: 32px;
    .box_width {
      width: 184px;
    }
  }
`;

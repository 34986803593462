import styled from "styled-components";

export const BarItemStyled = styled.div`
  width: 100%;
  .bar_container {
    width: 100%;
    .bar_inner {
      width: 100%;
      .group_item_bar {
        position: relative;
        min-height: 50px;
        width: 100%;
        padding: 5px;
        padding-bottom: 0px;
        height: calc(${({ percent }) => `${percent}% - 5px`});
        .item {
          width: 100%;
        }
        .percent_show {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -13%);
          font-size: ${({ theme }) => theme.FONT.SIZE.S22};
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
  }

  .theme_standard_bar {
    .bar_container {
      position: relative;
      min-width: ${(props) => (props.minWidth ? props.minWidth : "80px")};
      min-height: ${(props) => (props.minHeight ? props.minHeight : "360px")};
      overflow: hidden;
      background: #8ac47f40;
      border: 4px solid #1f7e4040;
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        // height: calc(100% - 55px);
        height: 100%;
        .bar_icon {
          width: 100%;
          text-align: center;
        }
        .group_item_bar {
          display: flex;
          .item {
            background: ${({ theme }) => theme.COLORS.GREEN_4};
          }
        }
      }
    }
  }
  .theme_red {
    .bar_container {
      position: relative;
      min-width: ${(props) => (props.minWidth ? props.minWidth : "80px")};
      min-height: ${(props) => (props.minHeight ? props.minHeight : "360px")};
      overflow: hidden;
      background: #70cec126;
      border: 4px solid #2a74a326;
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        // height: calc(100% - 55px);
        height: 100%;
        .bar_icon {
          width: 100%;
          text-align: center;
        }
        .group_item_bar {
          display: flex;
          .item {
            background: ${({ theme }) => theme.COLORS.BLUE_13};
          }
        }
      }
    }
  }
  .theme_blue {
    .bar_container {
      position: relative;
      min-width: ${(props) => (props.minWidth ? props.minWidth : "80px")};
      min-height: ${(props) => (props.minHeight ? props.minHeight : "360px")};
      overflow: hidden;
      background: #575d9626;
      border: 4px solid #1d005526;
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        // height: calc(100% - 55px);
        height: 100%;
        .bar_icon {
          width: 100%;
          text-align: center;
        }
        .group_item_bar {
          display: flex;
          .item {
            background: ${({ theme }) => theme.COLORS.PURPLE_7};
          }
        }
      }
    }
  }
`;

import styled from 'styled-components'

export const RewardExchangeFormStyled = styled.div`
.action_box {
  width: 416px;
  border-radius: 24px;
  padding: 89px 32px 64px 32px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title_box {
    display: flex;
    font-size: ${({ theme }) => theme.FONT.SIZE.S24};
    font-family: noto_sans_thai_bold, noto_sans_bold;
    color:  ${({ theme }) => theme.COLORS.BLACK_8};
    margin-bottom: 23px;
    align-items: center;
    .icon {
      display: flex;
      margin-left: 7px;
    }
  }
  .input_layer {
    width: 100%;
    margin-bottom: 37px;
    .show_svg {
      display: flex;
      justify-content: center;
    }
  }
  .action_btn {
    .body_btn {
      width: 256px;
      &.margin_bottom {
        margin-bottom: 24px;
      }
    }
  }
}
`

export default function Exercise({ width = "33", height = "33" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 33"
    >
      <g
        id="Group_57056"
        data-name="Group 57056"
        transform="translate(-96 -672)"
      >
        <g
          id="Group_15959"
          data-name="Group 15959"
          transform="translate(-56 209)"
        >
          <circle
            id="Ellipse_1502"
            data-name="Ellipse 1502"
            cx="16.5"
            cy="16.5"
            r="16.5"
            transform="translate(152 463)"
            fill="#aad3ed"
          />
          <g id="exercise" transform="translate(156.999 471.598)">
            <path
              id="Path_19194"
              data-name="Path 19194"
              d="M343.324,132.358v2.247a.337.337,0,0,1-.337.337h-2.64a.337.337,0,0,1-.337-.337v-2.247a.337.337,0,0,1,.337-.337h2.64A.337.337,0,0,1,343.324,132.358Z"
              transform="translate(-324.727 -128.435)"
              fill="#555760"
            />
            <g
              id="Group_13778"
              data-name="Group 13778"
              transform="translate(0 3.586)"
            >
              <path
                id="Path_19195"
                data-name="Path 19195"
                d="M1.609,132.016H.337a.337.337,0,0,0-.337.337V134.6a.337.337,0,0,0,.337.337H1.609a.337.337,0,0,0,.337-.337v-2.247A.337.337,0,0,0,1.609,132.016Z"
                transform="translate(0 -132.016)"
                fill="#555760"
              />
              <path
                id="Path_19196"
                data-name="Path 19196"
                d="M470.646,132.358v2.247a.337.337,0,0,1-.337.337h-1.272a.337.337,0,0,1-.337-.337v-2.247a.337.337,0,0,1,.337-.337h1.272A.337.337,0,0,1,470.646,132.358Z"
                transform="translate(-447.634 -132.021)"
                fill="#555760"
              />
              <g
                id="Group_13777"
                data-name="Group 13777"
                transform="translate(0 1.494)"
              >
                <path
                  id="Path_19197"
                  data-name="Path 19197"
                  d="M470.646,165.251v1.091a.337.337,0,0,1-.337.337h-1.272a.337.337,0,0,1-.337-.337v-1.091a.337.337,0,0,0,.337.337h1.272A.337.337,0,0,0,470.646,165.251Z"
                  transform="translate(-447.634 -165.251)"
                  fill="#36383f"
                />
                <path
                  id="Path_19198"
                  data-name="Path 19198"
                  d="M1.946,165.246v1.091a.337.337,0,0,1-.337.337H.337A.337.337,0,0,1,0,166.337v-1.091a.337.337,0,0,0,.337.337H1.609A.337.337,0,0,0,1.946,165.246Z"
                  transform="translate(0 -165.246)"
                  fill="#36383f"
                />
                <path
                  id="Path_19199"
                  data-name="Path 19199"
                  d="M343.324,172.751v.754a.337.337,0,0,1-.337.337h-2.64a.337.337,0,0,1-.337-.337v-.754Z"
                  transform="translate(-324.728 -172.414)"
                  fill="#36383f"
                />
              </g>
            </g>
            <g
              id="Group_13779"
              data-name="Group 13779"
              transform="translate(1.272 1.236)"
            >
              <path
                id="Path_19200"
                data-name="Path 19200"
                d="M30.1,80.639v5.825a.9.9,0,0,1-1.8,0V80.639a.9.9,0,0,1,1.8,0Z"
                transform="translate(-28.3 -79.741)"
                fill="#52deff"
              />
              <path
                id="Path_19201"
                data-name="Path 19201"
                d="M39.647,80.639v5.825a.9.9,0,0,1-1.347.778.885.885,0,0,0,.186-.142.9.9,0,0,0,.263-.635V80.639a.9.9,0,0,0-.449-.778.9.9,0,0,1,1.347.778Z"
                transform="translate(-37.851 -79.741)"
                fill="#55baff"
              />
              <path
                id="Path_19202"
                data-name="Path 19202"
                d="M445.567,80.637v5.825a.9.9,0,0,1-1.8,0V80.637a.9.9,0,0,1,1.8,0Z"
                transform="translate(-425.096 -79.739)"
                fill="#52deff"
              />
              <path
                id="Path_19203"
                data-name="Path 19203"
                d="M455.118,80.637v5.825a.9.9,0,0,1-1.348.778.884.884,0,0,0,.186-.142.9.9,0,0,0,.263-.635V80.637a.9.9,0,0,0-.449-.778.9.9,0,0,1,1.348.778Z"
                transform="translate(-434.647 -79.739)"
                fill="#55baff"
              />
            </g>
            <path
              id="Path_19204"
              data-name="Path 19204"
              d="M165.938,104.107v2.277a.337.337,0,0,1-.07.205l-1.763,2.288.524,5.485a.337.337,0,0,1-.336.369h-5.611a.337.337,0,0,1-.336-.369l.1-1,.017-.18.406-4.252-1.694-1.308a.337.337,0,0,1-.131-.267v-4.547a.337.337,0,0,1,.337-.337H164.3A1.638,1.638,0,0,1,165.938,104.107Z"
              transform="translate(-149.981 -100.213)"
              fill="#ffd3c0"
            />
            <path
              id="Path_19205"
              data-name="Path 19205"
              d="M193.669,104.107v2.277a.337.337,0,0,1-.07.205l-1.763,2.288.524,5.485a.337.337,0,0,1-.336.369h-5.611a.337.337,0,0,1-.336-.369l.1-1,.017-.18h4.621a.337.337,0,0,0,.336-.369l-.344-3.957,1.943-2.557a.337.337,0,0,0,.07-.206v-2.277c0-.557-.549-1.049-.976-1.345h.193a1.638,1.638,0,0,1,1.637,1.636Z"
              transform="translate(-177.713 -100.213)"
              fill="#ffb9a1"
            />
            <path
              id="Path_19206"
              data-name="Path 19206"
              d="M107.943,132.357v1.214a.334.334,0,0,1-.055.184l-.063.1-.612.938a.336.336,0,0,1-.282.153H98.587a.337.337,0,0,1-.337-.337v-2.247a.337.337,0,0,1,.341-.337h9.018A.337.337,0,0,1,107.943,132.357Z"
              transform="translate(-93.834 -128.434)"
              fill="#555760"
            />
            <path
              id="Path_19207"
              data-name="Path 19207"
              d="M98.25,172.751h9.575l-.612.938a.336.336,0,0,1-.282.153H98.587a.337.337,0,0,1-.337-.337Z"
              transform="translate(-93.834 -167.334)"
              fill="#36383f"
            />
            <g
              id="Group_13780"
              data-name="Group 13780"
              transform="translate(3.069 0)"
            >
              <path
                id="Path_19208"
                data-name="Path 19208"
                d="M70.3,53.251v8.072a1.011,1.011,0,0,1-1.01,1.01,1.013,1.013,0,0,1-1.011-1.01V53.251a1.011,1.011,0,0,1,2.021,0Z"
                transform="translate(-68.28 -52.241)"
                fill="#ff455c"
              />
              <path
                id="Path_19209"
                data-name="Path 19209"
                d="M81.046,53.251v8.072a1.011,1.011,0,0,1-1.01,1.01,1,1,0,0,1-.505-.136,1.01,1.01,0,0,0,.505-.874V53.251a1.01,1.01,0,0,0-.505-.874,1,1,0,0,1,.505-.136A1.011,1.011,0,0,1,81.046,53.251Z"
                transform="translate(-79.024 -52.241)"
                fill="#e51c5f"
              />
            </g>
            <g
              id="Group_13781"
              data-name="Group 13781"
              transform="translate(17.923 0)"
            >
              <path
                id="Path_19210"
                data-name="Path 19210"
                d="M400.771,53.249v8.072a1.011,1.011,0,0,1-1.01,1.01,1.013,1.013,0,0,1-1.011-1.01V53.249a1.011,1.011,0,0,1,2.021,0Z"
                transform="translate(-398.75 -52.239)"
                fill="#ff455c"
              />
              <path
                id="Path_19211"
                data-name="Path 19211"
                d="M411.516,53.249v8.072a1.011,1.011,0,0,1-1.01,1.01A1,1,0,0,1,410,62.2a1.01,1.01,0,0,0,.505-.874V53.249a1.01,1.01,0,0,0-.505-.874,1,1,0,0,1,.505-.136A1.011,1.011,0,0,1,411.516,53.249Z"
                transform="translate(-409.494 -52.239)"
                fill="#e51c5f"
              />
            </g>
            <path
              id="Path_19212"
              data-name="Path 19212"
              d="M157.925,82.816a.881.881,0,0,0-.881.881V85.64a.881.881,0,1,0,1.763,0V83.7A.881.881,0,0,0,157.925,82.816Z"
              transform="translate(-149.985 -81.442)"
              fill="#ffd3c0"
            />
            <path
              id="Path_19213"
              data-name="Path 19213"
              d="M197.138,82.816a.881.881,0,0,0-.881.881V85.64a.881.881,0,0,0,1.763,0V83.7A.881.881,0,0,0,197.138,82.816Z"
              transform="translate(-187.436 -81.442)"
              fill="#ffb9a1"
            />
            <path
              id="Path_19214"
              data-name="Path 19214"
              d="M236.352,82.816a.881.881,0,0,0-.881.881V85.64a.881.881,0,1,0,1.763,0V83.7A.881.881,0,0,0,236.352,82.816Z"
              transform="translate(-224.887 -81.442)"
              fill="#ffd3c0"
            />
            <path
              id="Path_19215"
              data-name="Path 19215"
              d="M275.566,82.816a.881.881,0,0,0-.881.881V85.64a.881.881,0,0,0,1.763,0V83.7A.881.881,0,0,0,275.566,82.816Z"
              transform="translate(-262.338 -81.442)"
              fill="#ffb9a1"
            />
            <path
              id="Path_19216"
              data-name="Path 19216"
              d="M162.164,364.591a.339.339,0,0,1-.252.113h-8.586a.337.337,0,0,1-.335-.377l.094-.792.356-3.007a.337.337,0,0,1,.335-.3h7.686a.337.337,0,0,1,.335.3l.45,3.8A.337.337,0,0,1,162.164,364.591Z"
              transform="translate(-146.112 -346.388)"
              fill="#eef6ff"
            />
            <path
              id="Path_19217"
              data-name="Path 19217"
              d="M162.164,364.591a.339.339,0,0,1-.252.113h-8.586a.337.337,0,0,1-.335-.377l.094-.792h7.48a.337.337,0,0,0,.334-.377l-.3-2.631a.338.338,0,0,0-.335-.3h1.195a.337.337,0,0,1,.335.3l.45,3.8A.337.337,0,0,1,162.164,364.591Z"
              transform="translate(-146.112 -346.388)"
              fill="#dae6ef"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function renderIcon({ color = '#4E5B7E', up }) {
  return (
    up
      ?
      <svg xmlns="http://www.w3.org/2000/svg" width="12.001" height="7.411" viewBox="0 0 12.001 7.411">
        <g id="ic_keyboard_arrow_up_24px" transform="translate(12.001 7.411) rotate(180)">
          <g id="Group">
            <path id="Shape" d="M1.41,0,6,4.58,10.591,0,12,1.41l-6,6-6-6Z" />
          </g>
        </g>
      </svg>
      :
      <svg id="ic_keyboard_arrow_up_24px" xmlns="http://www.w3.org/2000/svg" width="12.001" height="7.411" viewBox="0 0 12.001 7.411">
        <g id="Group">
          <path id="Shape" d="M1.41,0,6,4.58,10.591,0,12,1.41l-6,6-6-6Z" />
        </g>
      </svg>
  )
}

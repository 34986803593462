import React from "react";
import { PointManagementContainerStyled } from "./styled";
import {
  Tabs,
  Loading,
  Buttons,
  Icons,
  TableCustom,
  Modal,
  Forms,
  Blocks,
  BGLoading,
  Inputs,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { getObjectKey } from "utils/functions/getObjectKey";

class PointManagementContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData(`?per_page=10&offset=0&is_user=1`);
    this.checkPermission();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0&is_user=1`);
      this.checkPermission();
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "user_coin_manage"
    );

    this.setState({
      perMissions: checkPermission,
    });
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_EMPLOYEE_LIST(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
        isLoading: false,
        isBgLoading: false,
      });
    } else {
      this.setState({
        data: [],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handlePopup = (e, value, atc_by, count) => {
    const { dataSave, actionNow, data, selectRowData } = this.state;
    let dataSelect = [];

    // ดึงข้อมูลพนักงานที่เลือก
    if (atc_by === "button") {
      value.forEach((e) => {
        dataSelect.push(data.find((j) => Number(e) === Number(j.id)));
      });
    } else {
      selectRowData.forEach((e) => {
        dataSelect.push(data.find((j) => Number(e) === Number(j.id)));
      });
    }

    this.setState({
      action_by: atc_by,
    });

    switch (e) {
      case "add_point":
      case "del_point":
        this.setState({
          renderModal: (
            <Forms.PointAddDeleteForm
              actionType={e}
              handleCancel={this.handleClickCloseModal}
              selectedData={dataSelect}
              onSubmit={this.onSubmit}
              totalUser={dataSelect.length}
            />
          ),
          isShowModal: true,
          actionType: e,
          actionNow: e === "add_point" ? "add" : "delete",
        });
        break;
      case "confirm":
        this.setState({
          renderModal: (
            <div className="confirm_box">
              <div className="top_cf">
                {dataSelect.length > 1 ? (
                  <div className="group_top">
                    <div>
                      {actionNow === "add"
                        ? "ยืนยันการบันทึกแต้มทั้งหมด"
                        : "ยืนยันการบันทึกการลบแต้มทั้งหมด"}
                    </div>
                    <div className="g_text">{`${dataSelect.length} คน`}</div>
                  </div>
                ) : (
                  <div className="text_center">
                    {actionNow === "add"
                      ? "ยืนยันการบันทึกแต้ม"
                      : "ยืนยันการบันทึก การลบแต้ม"}
                  </div>
                )}
                <div className="point_show">
                  {dataSelect.length > 1 ? (
                    <>
                      <div className="svg_show">
                        {actionNow === "add" ? (
                          <Icons.RoundAdd bgColor="#21A526" color="#fff" />
                        ) : (
                          <Icons.RoundDel />
                        )}
                      </div>
                      <div>{`${RenderCommaMoney(dataSave.point)} แต้ม`}</div>
                    </>
                  ) : (
                    <div className="detail_show">
                      <div>{`${dataSelect[0].code}`}</div>
                      <div>
                        {`${dataSelect[0].firstname} ${dataSelect[0].lastname}`}
                      </div>
                      <div>{`${dataSelect[0].department.name}`}</div>
                      <div className="group_svg_point">
                        <div className="svg_action">
                          {actionNow === "add" ? (
                            <Icons.RoundAdd color="#fff" bgColor="#21A526" />
                          ) : (
                            <Icons.RoundDel />
                          )}
                        </div>
                        <div className="add_point">
                          {`${RenderCommaMoney(dataSave.point)} แต้ม`}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="btn_do">
                  <div className="btn_w184">
                    <Buttons.BtnNormal
                      theme_only_border
                      label="ยกเลิก"
                      fontSize="18px"
                      fontFamily="noto_sans_regular, noto_sans_thai_thin"
                      padding="10px"
                      borderRadius="8px"
                      backgroundColor="#fff"
                      fontColor="#0B408D"
                      borderColor="#0B408D"
                      onClick={this.handleClickCloseModal}
                    />
                  </div>
                  <div className="btn_w184">
                    <Buttons.BtnNormal
                      theme_only_border
                      label="บันทึก"
                      fontSize="18px"
                      fontFamily="noto_sans_bold, noto_sans_thai_bold"
                      padding="10px"
                      borderRadius="8px"
                      backgroundColor="#0B408D"
                      borderColor="#575D96"
                      fontColor="#fff"
                      // onClick={this.handleActionPoint}
                      onClick={() => this.handleActionPoint("no_all")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
          isShowModal: true,
        });
        break;

      case "error":
        this.setState({
          isShowModal: true,
        });
        this.setState({
          renderModal: (
            <div className="error_box">
              <div className="msg">
                {dataSelect.length > 1 ? (
                  <>
                    <div className="text_group">
                      <div>ไม่สามารถลบ</div>
                      <div className="htext_show">
                        {`คะแนน ${RenderCommaMoney(dataSave.point)} แต้ม`}
                      </div>
                    </div>
                    <div className="text_group">
                      {`ในจำนวน ${RenderCommaMoney(
                        count
                      )} คนได้ เนื่องจากจำนวนแต้ม `}
                    </div>
                    <div className="text_group">ไม่เพียงพอที่จะลบ</div>
                  </>
                ) : (
                  <>
                    <div className="text_group">
                      <div>ไม่สามารถลบ</div>
                      <div className="htext_show">
                        {`คะแนน ${RenderCommaMoney(dataSave.point)} แต้ม`}
                      </div>
                      <div>ได้</div>
                    </div>
                    <div className="text_group">
                      เนื่องจากจำนวนแต้มไม่เพียงพอที่จะลบ
                    </div>
                  </>
                )}
              </div>
              <div className="btn_accept">
                <Buttons.BtnNormal
                  theme_only_border
                  label="รับทราบ"
                  fontSize="20px"
                  fontFamily="noto_sans_regular, noto_sans_thai_thin"
                  padding="10px"
                  borderRadius="8px"
                  backgroundColor="#fff"
                  fontColor="#7B7B7B"
                  borderColor="#7E7E7E"
                  onClick={this.handleClickCloseModal}
                />
              </div>
            </div>
          ),
        });
        //   }
        // );
        break;

      default:
        break;
    }
  };

  handlePopupAll = (e, count) => {
    const { dataSave, actionNow, pagination } = this.state;
    switch (e) {
      case "add_point":
      case "del_point":
        this.setState({
          renderModal: (
            <Forms.PointAddDeleteForm
              actionType={e}
              handleCancel={this.handleClickCloseModal}
              onSubmit={this.onSubmitAll}
              totalUser={pagination.total}
            />
          ),
          isShowModal: true,
          actionType: e,
          actionNow: e === "add_point" ? "add" : "delete",
        });
        break;
      case "confirm":
        this.setState({
          renderModal: (
            <div className="confirm_box">
              <div className="top_cf">
                <div className="group_top">
                  <div>
                    {actionNow === "add"
                      ? "ยืนยันการบันทึกแต้มทั้งหมด"
                      : "ยืนยันการบันทึกการลบแต้มทั้งหมด"}
                  </div>
                  <div className="g_text">{`${pagination.total} คน`}</div>
                </div>
                <div className="point_show">
                  <div className="svg_show">
                    {actionNow === "add" ? (
                      <Icons.RoundAdd bgColor="#21A526" color="#fff" />
                    ) : (
                      <Icons.RoundDel />
                    )}
                  </div>
                  <div>{`${RenderCommaMoney(dataSave.point)} แต้ม`}</div>
                </div>
                <div className="btn_do">
                  <div className="btn_w184">
                    <Buttons.BtnNormal
                      theme_only_border
                      label="ยกเลิก"
                      fontSize="18px"
                      fontFamily="noto_sans_regular, noto_sans_thai_thin"
                      padding="10px"
                      borderRadius="8px"
                      backgroundColor="#fff"
                      fontColor="#7B7B7B"
                      borderColor="#7E7E7E"
                      onClick={this.handleClickCloseModal}
                    />
                  </div>
                  <div className="btn_w184">
                    <Buttons.BtnNormal
                      theme_only_border
                      label="บันทึก"
                      fontSize="18px"
                      fontFamily="noto_sans_bold, noto_sans_thai_bold"
                      padding="10px"
                      borderRadius="8px"
                      backgroundColor="#575D96"
                      borderColor="#575D96"
                      fontColor="#fff"
                      onClick={() => this.handleActionPoint("all")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
          isShowModal: true,
        });
        break;

      case "error":
        this.setState({
          isShowModal: true,
        });
        this.setState({
          renderModal: (
            <div className="error_box">
              <div className="msg">
                <div className="text_group">
                  <div>ไม่สามารถลบ</div>
                  <div className="htext_show">
                    {`คะแนน ${RenderCommaMoney(dataSave.point)} แต้ม`}
                  </div>
                </div>
                <div className="text_group">
                  {`ในจำนวน ${RenderCommaMoney(
                    count
                  )} คนได้ เนื่องจากจำนวนแต้ม `}
                </div>
                <div className="text_group">ไม่เพียงพอที่จะลบ</div>
              </div>
              <div className="btn_accept">
                <Buttons.BtnNormal
                  theme_only_border
                  label="รับทราบ"
                  fontSize="20px"
                  fontFamily="noto_sans_regular, noto_sans_thai_thin"
                  padding="10px"
                  borderRadius="8px"
                  backgroundColor="#fff"
                  fontColor="#7B7B7B"
                  borderColor="#7E7E7E"
                  onClick={this.handleClickCloseModal}
                />
              </div>
            </div>
          ),
        });

        break;

      default:
        break;
    }
  };

  handleActionPoint = async (e) => {
    this.setState({
      renderModal: null,
      isShowModal: false,
    });

    this.setState({
      isBgLoading: true,
    });
    const {
      dataSave,
      data,
      selectedData,
      actionNow,
      action_by,
      selectRowData,
      pagination,
    } = this.state;
    let userUpdate = [];
    let tempUser = [];
    if (e !== "all") {
      if (action_by === "button") {
        selectedData.forEach((e) => {
          tempUser.push(data.find((j) => Number(e) === Number(j.id)));
        });
      } else {
        selectRowData.forEach((e) => {
          tempUser.push(data.find((j) => Number(e) === Number(j.id)));
        });
      }
      tempUser.forEach((e) => {
        userUpdate.push({ id: e.user.id });
      });
    } else {
      userUpdate = null;
    }

    let params = {
      users: userUpdate,
      coin_amount:
        actionNow === "add" ? dataSave.point : Number(dataSave.point) * -1,
    };

    let res = await adminService.POST_POINT(
      this.props.companySelectedRedux?.id,
      params
    );

    if (res && res.success) {
      if (actionNow === "add") {
        toast.success("เพิ่มคะแนนสำเร็จ");
        this.handleClickCloseModal();
      } else {
        if (res.data.fail_users_count > 0) {
          if (userUpdate) {
            this.handlePopup(
              "error",
              selectedData,
              action_by,
              selectedData.length
            );
          } else {
            this.handlePopupAll("error", pagination.total);
          }
        } else {
          toast.success("ลบคะแนนสำเร็จ");
          this.handleClickCloseModal();
        }
      }
      this.fetchData(`?per_page=10&offset=0&is_user=1`);
    } else {
      if (actionNow === "delete") {
        if (userUpdate) {
          this.handlePopup(
            "error",
            selectedData,
            action_by,
            selectedData.length
          );
        } else {
          this.handlePopupAll("error", pagination.total);
        }
      } else {
        toast.error("เกิดข้อผิดพลาดในการทำงาน");
      }
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: null,
    });
  };

  handleClickCreate = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`
    );
  };

  handleClickCreateManyAccount = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_MANY_USER_ACCOUNT}`);
  };

  handleClickDetail = (e) => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/${e.id}`
    );
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };

  // FILTER PROCESS
  //==========================================
  //==========================================
  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `department_ids=${e
              .filter((e) => e !== "all")
              .join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "keywordFilter":
        this.setState(
          {
            keywordFilter: !e ? false : `keyword=${e}&is_user=1`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleJoinQuery = () => {
    this.setState({
      isBgLoading: true,
    });
    const { categoryFilter, keywordFilter } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchData(`?per_page=10&offset=0&is_user=1`);
      this.setState({
        queryFilterAll: false,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };

  handlePageClick = (e) => {
    this.setState({
      isBgLoading: true,
    });
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}&is_user=1`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT}${query}`);
    this.fetchData(query);
  };

  handleClickLog = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_POINT_LOG}`);
  };

  onSubmit = (values) => {
    const { selectedData, action_by } = this.state;

    this.setState({
      dataSave: values,
    });
    this.handlePopup("confirm", selectedData, action_by);
  };

  onSubmitAll = (values) => {
    this.setState({
      dataSave: values,
    });
    this.handlePopupAll("confirm");
  };

  handleChangePoint = (key, value) => {
    this.setState(
      {
        selectRowData: [value.id],
      },
      () => {
        switch (key) {
          case "increment":
            this.handlePopup("add_point", [value.id], "row");
            break;
          case "decrement":
            this.handlePopup("del_point", [value.id], "row");
            break;
          default:
            break;
        }
      }
    );
  };

  render() {
    const {
      isLoading,
      isShowModal,
      pagination,
      data,
      selectedData,
      perMissions,
      renderModal,
      isBgLoading,
    } = this.state;
    // const { companySelectedRedux } = this.props
    return (
      <PointManagementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="คะแนน"
                  subTitle="Point"
                  fontSize="34px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#092945"
                  colorBottom="#092945"
                  lineHeight="1.3"
                  typeLog
                  handleClickButton={this.handleClickLog}
                />
              </div>
              <div className="layer_two align_end">
                <div
                  className={`search_layer ${!perMissions ? "half_width" : ""}`}
                >
                  <div className="sub_label">ค้นหารายชื่อที่คุณต้องการ</div>
                  <Inputs.InputSearch
                    theme_standard_search
                    onSubmit={(e) =>
                      this.handleSelectedFilter("keywordFilter", e)
                    }
                  />
                </div>
                {perMissions && (
                  <div className="btn_layer w100">
                    <div className="">
                      <Buttons.BtnNormal
                        theme_standard_btn_normal_red
                        label="เพิ่ม"
                        backgroundColor="#21A526"
                        fontFamily="noto_sans_bold, noto_sans_thai_bold"
                        fontSize="16px"
                        borderRadius="4px"
                        padding={
                          selectedData && selectedData.length > 0
                            ? "8px"
                            : "7px"
                        }
                        borderColor={
                          selectedData && selectedData.length > 0
                            ? "#21A526"
                            : "#9E9E9E"
                        }
                        svg_front={
                          <Icons.RoundAdd
                            color={
                              selectedData && selectedData.length > 0
                                ? "#21A526"
                                : "#9E9E9E"
                            }
                            bgColor={
                              selectedData && selectedData.length > 0
                                ? "#fff"
                                : "#fff"
                            }
                          />
                        }
                        onClick={() =>
                          this.handlePopup("add_point", selectedData, "button")
                        }
                        disabled={!(selectedData && selectedData.length > 0)}
                      />
                    </div>
                    <div className="min_132">
                      <Buttons.BtnNormal
                        theme_standard_btn_normal_red
                        label="เพิ่มทั้งหมด"
                        backgroundColor="#21A526"
                        padding={data && data.length > 0 ? "8px" : "7px"}
                        fontFamily="noto_sans_bold, noto_sans_thai_bold"
                        fontSize="16px"
                        borderRadius="4px"
                        borderColor={
                          data && data.length > 0 ? "#21A526" : "#9E9E9E"
                        }
                        svg_front={
                          <Icons.RoundAdd
                            color={
                              data && data.length > 0 ? "#21A526" : "#9E9E9E"
                            }
                            bgColor={data && data.length > 0 ? "#fff" : "#fff"}
                          />
                        }
                        onClick={() => this.handlePopupAll("add_point")}
                        disabled={!(data && data.length > 0)}
                      />
                    </div>
                    <div className="">
                      <Buttons.BtnNormal
                        theme_only_border
                        label="ลบ"
                        borderRadius="4px"
                        backgroundColor="#fff"
                        fontColor="#FF1D00"
                        fontFamily="noto_sans_bold, noto_sans_thai_bold"
                        fontSize="16px"
                        borderColor="#FF1D00"
                        padding={
                          selectedData && selectedData.length > 0
                            ? "7px"
                            : "8px"
                        }
                        disabled={!(selectedData && selectedData.length > 0)}
                        svg_front={
                          <Icons.RoundDel
                            color={
                              selectedData && selectedData.length > 0
                                ? "#FF1D00"
                                : "#fff"
                            }
                            bgColor={
                              selectedData && selectedData.length > 0
                                ? "#fff"
                                : "#9E9E9E"
                            }
                          />
                        }
                        onClick={() =>
                          this.handlePopup("del_point", selectedData, "button")
                        }
                      />
                    </div>
                    <div className="min_132">
                      <Buttons.BtnNormal
                        theme_only_border
                        label="ลบทั้งหมด"
                        borderRadius="4px"
                        backgroundColor="#fff"
                        fontColor="#FF1D00"
                        fontFamily="noto_sans_bold, noto_sans_thai_bold"
                        borderColor="#FF1D00"
                        fontSize="16px"
                        padding={data && data.length > 0 ? "7px" : "8px"}
                        disabled={!(data && data.length > 0)}
                        svg_front={
                          <Icons.RoundDel
                            color={data && data.length > 0 ? "#FF1D00" : "#fff"}
                            bgColor={
                              data && data.length > 0 ? "#fff" : "#9E9E9E"
                            }
                          />
                        }
                        onClick={() => this.handlePopupAll("del_point")}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="layer_table">
                <TableCustom.TableAction
                  theme_standard_table_user
                  columns={columns({
                    handleClickDetail: this.handleClickDetail,
                    handleChangePoint: this.handleChangePoint,
                    perMissions: perMissions,
                  })}
                  data={data}
                  pagination={pagination}
                  useNo
                  isCheckbox={perMissions ? true : false}
                  showPagination
                  onChangeSelected={this.handleChangeSelectedTable}
                  handlePageClick={this.handlePageClick}
                />
              </div>
              <Modal
                theme_modal_standard
                isShow={isShowModal}
                handleClickCloseModal={this.handleClickCloseModal}
              >
                {renderModal}
              </Modal>
            </Blocks.Container>
          </>
        )}
      </PointManagementContainerStyled>
    );
  }
}

const columns = ({ handleClickDetail, handleChangePoint, perMissions }) => {
  let tempColumn = [];

  if (perMissions) {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
      style: { textAlign: "center", width: "20px", flexShrink: 0 },
    });
  } else {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
      style: {
        textAlign: "center",
        width: "0px",
        flexShrink: 0,
        padding: "0px",
      },
    });
  }

  tempColumn.push(
    {
      title: "ลำดับ",
      field: "no",
      headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
      style: { textAlign: "center", width: "30px", flexShrink: 0 },
    },
    {
      title: "รหัสพนักงาน",
      field: "code",
      headerStyle: { textAlign: "center", minWidth: "100px" },
      style: { textAlign: "left", minWidth: "100px" },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>{value}</div>
      ),
    },
    {
      title: "ชื่อ - นามสกุล",
      field: "name",
      classStyle: "showHead",
      headerStyle: { textAlign: "left", minWidth: "100px" },
      style: { textAlign: "left", minWidth: "100px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
        >{`${origin.firstname} ${origin.lastname}`}</div>
      ),
    },
    {
      title: "แผนก",
      field: "department.name",
      classStyle: "showHead",
      headerStyle: { textAlign: "center", minWidth: "100px" },
      style: { textAlign: "center", minWidth: "100px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
        >
          {value}
        </div>
      ),
    },
    {
      title: "คะแนน",
      field: "user",
      headerStyle: {
        textAlign: "center",
        maxWidth: perMissions ? "150px" : "100px",
      },
      style: { textAlign: "left", maxWidth: perMissions ? "150px" : "100px" },
      render: ({ origin, value }) => (
        <div className="action_box">
          <div>
            {origin.user ? (
              origin.user.balance ? (
                RenderCommaMoney(
                  origin.user.balance.current_coin_amount.toFixed(0)
                )
              ) : (
                <div>0</div>
              )
            ) : (
              <div>0</div>
            )}
          </div>
          {perMissions && (
            <div className="group_svg">
              <div
                className="svg_btn"
                onClick={() => handleChangePoint("increment", origin)}
              >
                <Icons.RoundAdd bgColor="#21A526" color="#fff" />
              </div>
              <div
                className="svg_btn"
                onClick={() => handleChangePoint("decrement", origin)}
              >
                <Icons.RoundDel />
              </div>
            </div>
          )}
        </div>
      ),
    }
  );
  return tempColumn;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointManagementContainer);

import styled from 'styled-components';

export const ManyUserAccountManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  min-height: calc(100vh - 285px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  .layer_one {
    display: flex;
  }
  .browse_zone {
    margin-top: 22px;
    margin-bottom: 38px;
    .top_zone {
      display: flex;
      align-items: center;
      column-gap: 4px;
      text-decoration: unset;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      .icon_download {
        display: flex;
      }
    }
    .form_show {
      margin-top: 16px;
    }
  }
  .import_zone {
    margin-top: 22px;
    .head_import {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .label_head {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .sub_text {
          color: ${({ theme }) => theme.COLORS.GRAY_14};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .btn_save {
        width: 15%;
      }
    }
    .table_show {
      margin-top: 28px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_two {
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
    }
  }
`;

import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { BoxDeleteStyled } from './styled'
import { Buttons, Icons, } from 'components';

const BoxDelete = ({
  theme_box_delete,
  handleConfirm,
  handleCancel,
  textShow,
  labelSub1,
  labelSub2,
  showIcon = true,
  icon,
  iconColor = '#002366',
  btnColor,
  minWidth,
  maxWidth,
  labelConfirm = 'ยืนยัน',
  labelCancel = 'ยกเลิก',
}) => {
  const customClass = cx({
    theme_box_delete: theme_box_delete,
  })
  return (
    <BoxDeleteStyled
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <div className={customClass}>
        <div className='delete_box'>
          <div className='title_box'>
            {
              showIcon &&
              <div className='icon'>
                {
                  icon
                    ?
                    icon
                    :
                    <Icons.Delete
                      width='24px'
                      height='24px'
                      color={iconColor}
                    />
                }
              </div>
            }
            <div className='group_label'>
              <div className='show_txt'>
                {
                  textShow
                    ?
                    textShow
                    :
                    'ยืนยันการลบหรือไม่'
                }
              </div>
              {
                labelSub1 &&
                <div className='sub_label mt10'>
                  {labelSub1}
                </div>
              }
              {
                labelSub2 &&
                <div className='sub_label'>
                  {labelSub2}
                </div>
              }
            </div>
          </div>
          <div className='action_btn'>
            <div className='body_btn margin_right'>
              <Buttons.BtnNormal
                theme_bg_white
                label={labelCancel}
                onClick={handleCancel}
              />
            </div>
            <div className='body_btn'>
              <Buttons.BtnNormal
                theme_only_border_blue
                label={labelConfirm}
                onClick={handleConfirm}
                backgroundColor={btnColor && btnColor}
              />
            </div>
          </div>
        </div>
      </div>
    </BoxDeleteStyled>
  )
}

BoxDelete.propTypes = {}

export default BoxDelete

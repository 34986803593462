export default function renderIcon({
  color = "#002366",
  width = "23.854",
  height = "22.364",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.854 22.364"
    >
      <g
        id="running_1_"
        data-name="running (1)"
        transform="translate(0 -23.054)"
      >
        <g
          id="Group_13952"
          data-name="Group 13952"
          transform="translate(1.417 25.405)"
        >
          <g id="Group_13951" data-name="Group 13951">
            <path
              id="Path_19075"
              data-name="Path 19075"
              d="M34.3,68.5H30.708a.708.708,0,1,0,0,1.417H34.3a.708.708,0,1,0,0-1.417Z"
              transform="translate(-30 -68.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13954"
          data-name="Group 13954"
          transform="translate(1.417 31.072)"
        >
          <g id="Group_13953" data-name="Group 13953">
            <path
              id="Path_19076"
              data-name="Path 19076"
              d="M34.3,188.5H30.708a.708.708,0,1,0,0,1.417H34.3a.708.708,0,1,0,0-1.417Z"
              transform="translate(-30 -188.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13956"
          data-name="Group 13956"
          transform="translate(0 28.239)"
        >
          <g id="Group_13955" data-name="Group 13955">
            <path
              id="Path_19077"
              data-name="Path 19077"
              d="M4.3,128.5H.708a.708.708,0,0,0,0,1.417H4.3a.708.708,0,0,0,0-1.417Z"
              transform="translate(0 -128.5)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13958"
          data-name="Group 13958"
          transform="translate(9.257 27.529)"
        >
          <g id="Group_13957" data-name="Group 13957">
            <path
              id="Path_19078"
              data-name="Path 19078"
              d="M209.507,117.728h-4.3v-2.834a1.419,1.419,0,0,0-2.419-1l-5.667,5.667a1.416,1.416,0,0,0,0,2l3.249,3.249-3.957,3.957a1.417,1.417,0,0,0,2,2l4.959-4.959a1.416,1.416,0,0,0,0-2l-3.249-3.249,2.247-2.247v.83a1.417,1.417,0,0,0,1.417,1.417h5.715a1.417,1.417,0,0,0,0-2.834Z"
              transform="translate(-196 -113.477)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13960"
          data-name="Group 13960"
          transform="translate(7.131 24.342)"
        >
          <g id="Group_13959" data-name="Group 13959">
            <path
              id="Path_19079"
              data-name="Path 19079"
              d="M157.1,46.244a1.417,1.417,0,0,0-1.788.177l-3.9,3.9a1.417,1.417,0,1,0,2,2l3.075-3.075,1.036.746,1.384-1.384a2.817,2.817,0,0,1,.8-.552Z"
              transform="translate(-150.999 -46.006)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13962"
          data-name="Group 13962"
          transform="translate(0.756 35.339)"
        >
          <g id="Group_13961" data-name="Group 13961">
            <path
              id="Path_19080"
              data-name="Path 19080"
              d="M24.623,280.129a2.808,2.808,0,0,1-.725-1.279l-7.483,7.483a1.417,1.417,0,0,0,2,2l7.206-7.206Z"
              transform="translate(-16 -278.85)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_13964"
          data-name="Group 13964"
          transform="translate(16.904 23.054)"
        >
          <g
            id="Group_13963"
            data-name="Group 13963"
            transform="translate(0 0)"
          >
            <circle
              id="Ellipse_1702"
              data-name="Ellipse 1702"
              cx="2.641"
              cy="2.641"
              r="2.641"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

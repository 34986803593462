import { combineReducers } from 'redux';
import authenReducer from './authenReducer';
import rewardCategory from './rewardCategory';
import companyReducer from './companyReducer';
import companyListReducer from './companyListReducer';

const rootReducer = combineReducers({
  authenRedux: authenReducer,
  rewardCategory: rewardCategory,
  companySelectedRedux: companyReducer,
  companyListRedux: companyListReducer,
});

export default rootReducer;

import styled from 'styled-components';

export const AIBMIContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
`;

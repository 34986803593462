import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputBrowseFileStyled } from "./styled";
import { Buttons, Icons } from "components";
import { useDropzone } from "react-dropzone";

const InputBrowseFile = ({
  theme_standard_browse,
  placeholder = "ไม่ได้เลือกไฟล์",
  onChange,
  acceptType,
  value,
  maxMBFileSizeWarning,
  onRemoveFile,
  isForceFileUpload,
}) => {
  const customClass = cx({
    theme_standard_browse: theme_standard_browse,
  });

  const [files, setFiles] = useState([]);
  const [_pathName, _setPathName] = useState();

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        return "image/*";
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return "video/*";
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      const manageFile = () => {
        let fileTemp = [
          ...files,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              isNewUpload: true,
            })
          ),
        ];
        setFiles(fileTemp);
        onChange && onChange(fileTemp);
      };
      if (isForceFileUpload) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert(`ไฟล์ต้องมีขนาดไม่เกิน ${maxMBFileSizeWarning} MB`);
        } else {
          manageFile();
        }
      } else {
        if (maxMBFileSizeWarning) {
          let checkFileLimit = acceptedFiles.filter(
            (e) =>
              Number((e.size / 1024 / 1024).toFixed(2)) >
              Number(maxMBFileSizeWarning)
          );
          if (checkFileLimit && checkFileLimit.length > 0) {
            alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
          }
        }
        manageFile();
      }
    },
  });

  useEffect(() => {
    if (value) {
      setFiles(value);
      if (value.length === 0) {
        _setPathName(placeholder);
      } else {
        _setPathName(value[0].path);
      }
    } else {
      _setPathName(placeholder);
    }
  }, [value, placeholder]);

  const onClickRemove = () => {
    _setPathName(placeholder);
    setFiles([]);
    onRemoveFile && onRemoveFile();
  };

  return (
    <InputBrowseFileStyled>
      <div className={customClass}>
        <div className="group_upload">
          <div className="file_location">
            <div className="location">
              <div className="group_name">
                {_pathName}
                {files && files.length > 0 && (
                  <div className="close" onClick={onClickRemove}>
                    <Icons.CloseX width="15" height="15" color="#4A4949" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="btn_browse">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Buttons.BtnNormal
                theme_only_border
                label="เลือกไฟล์"
                fontSize="18px"
                fontFamilyTop="noto_sans_bold, noto_sans_thai_bold"
                padding="10px"
                borderRadius="6px"
                fontColor="#002366"
                borderColor="#002366"
                backgroundColor="#fff"
              />
            </div>
          </div>
        </div>
        <div className="notic">{`ขนาดไฟล์สูงสุด 1 MB`}</div>
      </div>
    </InputBrowseFileStyled>
  );
};

InputBrowseFile.propTypes = {};

export default InputBrowseFile;

import styled from "styled-components";

export const InputBrowseStyled = styled.div`
  .theme_standard_browse {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .flex_box {
      display: flex;
      justify-content: space-between;
      .file_location {
        width: 77%;
        .location {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-family: noto_sans_thai_regular, noto_sans_regular;
          width: 100%;
          border: none;
          padding: 16px 12.5px;
          border-radius: 10px;
          box-sizing: border-box;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
        }
      }
    }

    .wording_limit {
      font-size: 12px;
      padding-bottom: 25px;
      color: #9e9e9e;
      padding-left: 10px;
    }
    .btn_browse {
      width: 20%;
    }
  }
`;

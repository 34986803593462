import styled from 'styled-components';

export const BarItem2Styled = styled.div`
  .theme_number_employees_bar {
    display: flex;
    .left_line {
      align-items: flex-end;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      display: flex;
      min-width: 66px;
      .group_line {
        position: relative;
        width: 100%;
        height: calc(${({ percent }) => `${percent}%`} - 5px);
        .round {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #F0211D;
          position: absolute;
          top: -2px;
          left: -8px;
        }
        .show_line{
          position: absolute;
          background: ${({ theme }) => theme.COLORS.PINK_6};
          height: 4px;
          width: 100%;
          top: 0px;
        }
      }
    }
    .bar_container {
      position: relative;
      min-width: 80px;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      overflow: hidden;
      background: ${({ theme }) => theme.COLORS.PINK_7};
      border: 4px solid ${({ theme }) => theme.COLORS.PINK_6};
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 100%;
        .group_bar {
          width: 80%;
          display: flex;
          height: calc(${({ percent }) => `${percent}%`});
          position: relative;
          .percent {
            position: absolute;
            width: 100%;
            text-align: center;
            // top: 40%;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -13%);
            font-family: noto_sans_thai_bold, noto_sans_bold;
            font-size: ${({ theme }) => theme.FONT.SIZE.S22};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
          }
          .item {
            width: 100%;
            height: 100%;
            &.left {
              background: linear-gradient(180deg, rgba(243,56,98,0.87) 0%, rgba(240,47,41,0.87) 25%, rgba(243,46,41,0.87) 75%, rgba(122,24,21,0.87) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
            &.right {
              background: linear-gradient(180deg, rgba(243,56,98,1) 0%, rgba(240,47,41,1) 25%, rgba(243,46,41,1) 75%, rgba(122,24,21,1) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
  .theme_mini_bar_blue {
    display: flex;
    .left_line {
      align-items: flex-end;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      display: flex;
      min-width: 26px;
      .group_line {
        position: relative;
        width: 100%;
        height: calc(${({ percent }) => `${percent}%`} - 5px);
        .round {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: ${({ theme }) => theme.COLORS.BLUE_6};
          position: absolute;
          top: -2px;
          left: -8px;
        }
        .show_line{
          background: ${({ theme }) => theme.COLORS.BLUE_5};
          height: 4px;
          width: 100%;
        }
      }
    }
    .bar_container {
      position: relative;
      min-width: 80px;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      overflow: hidden;
      background: ${({ theme }) => theme.COLORS.BLUE_21}4D;
      border: 4px solid  ${({ theme }) => theme.COLORS.BLUE_21};
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 100%;
        .group_bar {
          width: 80%;
          min-height: ${({ minChart }) => minChart};
          display: flex;
          height: calc(${({ percent }) => `${percent}%`});
          position: relative;
          .percent {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -13%);
            font-family: noto_sans_thai_bold, noto_sans_bold;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            color:  ${({ theme }) => theme.COLORS.PURPLE_1};
          }
          .item {
            width: 100%;
            height: 100%;
            &.left {
              // background: linear-gradient(180deg, rgba(68,133,125,0.87) 0%, rgba(67,132,124,0.87) 75%, rgba(122,24,21,0.87) 167070%);
              // background: rgb(182,231,232);
              background: linear-gradient(180deg, rgba(182,231,232,0.5) 0%, rgba(95,167,167,0.5) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
            &.right {
              // background: linear-gradient(180deg, rgba(68,133,125,1) 0%, rgba(67,132,124,1) 75%, rgba(122,24,21,1) 167070%);
              background: linear-gradient(180deg, rgba(182,231,232,1) 0%, rgba(95,167,167,1) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
  .theme_mini_bar_orange {
    display: flex;
    .left_line {
      align-items: flex-end;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      display: flex;
      min-width: 26px;
      .group_line {
        position: relative;
        width: 100%;
        height: calc(${({ percent }) => `${percent}%`} - 5px);
        .round {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #FD5E17;
          position: absolute;
          top: -2px;
          left: -8px;
        }
        .show_line{
          background: ${({ theme }) => theme.COLORS.ORANGE_2}40;
          height: 4px;
          width: 100%;
        }
      }
    }
    .bar_container {
      position: relative;
      min-width: 80px;
      min-height:  ${(props) => (props.minHeight ? props.minHeight : '300px')};
      overflow: hidden;
      background: ${({ theme }) => theme.COLORS.ORANGE_10}33;
      border: 4px solid ${({ theme }) => theme.COLORS.ORANGE_10};
      border-bottom: none;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .bar_inner {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 100%;
        .group_bar {
          width: 80%;
          display: flex;
          height: calc(${({ percent }) => `${percent}%`});
          position: relative;
          .percent {
            position: absolute;
            width: 100%;
            text-align: center;
            // top: 40%; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -13%);
            font-family: noto_sans_thai_bold, noto_sans_bold;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
          }
          .item {
            width: 100%;
            height: 100%;
            &.left {
              // background: linear-gradient(180deg, rgba(251,164,40,1) 0%, rgba(253,102,35,1) 100%);
              background: linear-gradient(90deg, rgba(227,165,47,0.70) 0%, rgba(253,199,9,0.70) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
            &.right {
              // background: linear-gradient(180deg, rgba(250,142,37,1) 0%, rgba(253,102,35,1) 100%);
              background: linear-gradient(90deg, rgba(227,165,47,0.8) 0%, rgba(253,199,9,0.8) 100%);
              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
`;
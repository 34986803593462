export default function Food2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="_20-bibibap" data-name="20-bibibap" transform="translate(0 0.083)">
        <circle id="Ellipse_1805" data-name="Ellipse 1805" cx="24" cy="24" r="24" transform="translate(0 -0.083)" fill="#e4e4e4" />
        <circle id="Ellipse_1806" data-name="Ellipse 1806" cx="21" cy="21" r="21" transform="translate(3 2.917)" fill="#5c4535" />
        <circle id="Ellipse_1807" data-name="Ellipse 1807" cx="19" cy="19" r="19" transform="translate(5 4.917)" fill="#ffffec" />
        <circle id="Ellipse_1808" data-name="Ellipse 1808" cx="19" cy="19" r="19" transform="translate(5 4.917)" fill="#fffacd" />
        <path id="Path_20097" data-name="Path 20097" d="M26.1,45A20.1,20.1,0,0,1,6.237,28a20.1,20.1,0,1,0,39.717,0A20.1,20.1,0,0,1,26.1,45Z" transform="translate(-2.132 -7.185)" fill="#ede4a9" />
        <path id="Path_20098" data-name="Path 20098" d="M54.635,13.82a1.911,1.911,0,0,0-.6.64,21.592,21.592,0,0,1,2.437,3.886,1.875,1.875,0,0,0,1.758-1.654,23.915,23.915,0,0,0-1.928-3.086,1.925,1.925,0,0,0-1.671.214Z" transform="translate(-13.041 -3.893)" fill="#a0775b" />
        <path id="Path_20099" data-name="Path 20099" d="M38.409,58.582a1.911,1.911,0,0,0-.454-.74A21.684,21.684,0,0,1,22,58.128a1.9,1.9,0,0,0-.133,2.412,23.961,23.961,0,0,0,16.309-.292,1.922,1.922,0,0,0,.232-1.666Z" transform="translate(-5.652 -13.961)" fill="#a0775b" />
        <path id="Path_20100" data-name="Path 20100" d="M52.868,50.062a1.917,1.917,0,0,0-.752-.437,21.747,21.747,0,0,1-5.57,4.869,1.9,1.9,0,0,0,1.819,1.592,24.1,24.1,0,0,0,5.087-4.448,1.924,1.924,0,0,0-.585-1.577Z" transform="translate(-11.34 -12.095)" fill="#a0775b" />
        <path id="Path_20101" data-name="Path 20101" d="M60.246,20.824a1.894,1.894,0,0,0-2.405.22,21.678,21.678,0,0,1,.773,14.5,1.907,1.907,0,0,0,2.419-.032,24,24,0,0,0-.79-14.686Z" transform="translate(-13.904 -5.48)" fill="#a0775b" />
        <path id="Path_20102" data-name="Path 20102" d="M5.457,18.1a1.911,1.911,0,0,0,.833.248,21.685,21.685,0,0,1,2.436-3.886A1.9,1.9,0,0,0,6.461,13.6,23.95,23.95,0,0,0,4.529,16.7a1.923,1.923,0,0,0,.928,1.4Z" transform="translate(-1.798 -3.892)" fill="#a0775b" />
        <path id="Path_20103" data-name="Path 20103" d="M15.09,55.3a1.914,1.914,0,0,0,.332-.8,21.754,21.754,0,0,1-5.565-4.869,1.9,1.9,0,0,0-1.336,2.01,24.066,24.066,0,0,0,5.082,4.45A1.92,1.92,0,0,0,15.09,55.3Z" transform="translate(-2.703 -12.096)" fill="#a0775b" />
        <path id="Path_20104" data-name="Path 20104" d="M3.422,35.915a1.909,1.909,0,0,0,.788-.375,21.674,21.674,0,0,1,.773-14.5,1.894,1.894,0,0,0-2.405-.22,23.994,23.994,0,0,0-.789,14.686,1.911,1.911,0,0,0,1.63.405Z" transform="translate(-0.996 -5.48)" fill="#a0775b" />
        <path id="Path_20105" data-name="Path 20105" d="M58.569,39.94a1.921,1.921,0,0,1-.4-.043,1.9,1.9,0,0,1-.788-.374,21.584,21.584,0,0,1-3.936,7.47A1.9,1.9,0,0,1,54.78,49a24.167,24.167,0,0,1-2.38,2.4,23.929,23.929,0,0,0,7.738-13.372q-.145.735-.334,1.454a1.932,1.932,0,0,1-1.234.451Z" transform="translate(-12.669 -9.464)" fill="#886454" />
        <path id="Path_20106" data-name="Path 20106" d="M58.024,18.574a1.908,1.908,0,0,1-.832.248q.265.546.5,1.11a1.894,1.894,0,0,1,2.405-.22A23.874,23.874,0,0,0,58.048,15.6q.478.767.9,1.569a1.921,1.921,0,0,1-.923,1.405Z" transform="translate(-13.757 -4.369)" fill="#886454" />
        <path id="Path_20107" data-name="Path 20107" d="M8.4,10.981a1.911,1.911,0,0,1,.6.64,21.628,21.628,0,0,1,34.07,0,1.894,1.894,0,0,1,2.267-.854A23.955,23.955,0,0,0,5.729,12.245q.475-.759,1-1.479a1.919,1.919,0,0,1,1.67.215Z" transform="translate(-2.071 -1.054)" fill="#886454" />
        <path id="Path_20108" data-name="Path 20108" d="M20.125,58.451a1.9,1.9,0,0,1,.427-.757,21.564,21.564,0,0,1-3.633-1.766,1.9,1.9,0,0,1-1.816,1.59,23.878,23.878,0,0,0,5.313,2.589A1.92,1.92,0,0,1,20.125,58.451Z" transform="translate(-4.2 -13.527)" fill="#886454" />
        <path id="Path_20109" data-name="Path 20109" d="M45.812,56.729a1.916,1.916,0,0,1-.332-.8,21.615,21.615,0,0,1-2.906,1.48,1.9,1.9,0,0,1,.219,2.406c-.309.119-.625.232-.942.336A23.818,23.818,0,0,0,47.3,57.518,1.919,1.919,0,0,1,45.812,56.729Z" transform="translate(-10.273 -13.527)" fill="#886454" />
        <path id="Path_20110" data-name="Path 20110" d="M4.712,19.478a1.907,1.907,0,0,1,.74.455q.232-.563.5-1.111A1.875,1.875,0,0,1,4.2,17.17q.422-.8.9-1.57A23.811,23.811,0,0,0,3.05,19.713a1.923,1.923,0,0,1,1.662-.235Z" transform="translate(-1.463 -4.369)" fill="#886454" />
        <path id="Path_20111" data-name="Path 20111" d="M8.283,46.994a21.584,21.584,0,0,1-3.936-7.47,1.908,1.908,0,0,1-2.418-.03q-.19-.719-.335-1.455A23.872,23.872,0,0,0,6.947,49.006,1.9,1.9,0,0,1,8.283,47Z" transform="translate(-1.132 -9.465)" fill="#886454" />
        <path id="Path_20112" data-name="Path 20112" d="M28.866,9.5l.773.773-1.183,1.183L27.273,12.64l-.773-.773-1-1A1.672,1.672,0,0,1,27.861,8.5Z" transform="translate(-6.448 -2.646)" fill="#689441" />
        <path id="Path_20113" data-name="Path 20113" d="M34.092,18.092v.773H31V16.546a1.546,1.546,0,1,1,3.092,0Z" transform="translate(-7.809 -4.233)" fill="#689441" />
        <path id="Path_20114" data-name="Path 20114" d="M40.092,12.092v.773H37V10.546a1.546,1.546,0,0,1,3.092,0Z" transform="translate(-9.172 -2.87)" fill="#689441" />
        <path id="Path_20115" data-name="Path 20115" d="M27.956,6.319V8.591l-1-1a1.672,1.672,0,0,0-2.365,2.365l1,1H23.319L21.394,9.124a1.334,1.334,0,0,1,0-1.894l2.837-2.837A1.334,1.334,0,0,1,25.181,4a1.314,1.314,0,0,1,.943.394Z" transform="translate(-5.539 -1.735)" fill="#6fa23b" />
        <path id="Path_20116" data-name="Path 20116" d="M35.183,13.546v2.01L33.638,17.41V15.865a1.546,1.546,0,1,0-3.092,0V17.41L29,15.71V13.237A1.237,1.237,0,0,1,30.237,12h3.71a1.194,1.194,0,0,1,.835.332l.139.155a.386.386,0,0,1,.054.062,1.225,1.225,0,0,1,.209.688Z" transform="translate(-7.355 -3.552)" fill="#6fa23b" />
        <path id="Path_20117" data-name="Path 20117" d="M41.183,7.237V9.555L39.638,11.41V9.865a1.546,1.546,0,1,0-3.092,0V11.41l-.209-.224a.386.386,0,0,0-.054-.062.868.868,0,0,0-.139-.155L35,9.71V7.237A1.237,1.237,0,0,1,36.237,6h3.71A1.237,1.237,0,0,1,41.183,7.237Z" transform="translate(-8.718 -2.189)" fill="#6fa23b" />
        <path id="Path_20118" data-name="Path 20118" d="M57.176,21.46a1.437,1.437,0,0,1-.425,1.159l-.131.131a.742.742,0,0,1-1.043,0l-.518-.518.518-.518-.518-.518L53.5,22.751a1.451,1.451,0,0,1-2.071,0,1.347,1.347,0,0,1-.325-.487A1.363,1.363,0,0,1,51,21.715a1.439,1.439,0,0,1,.433-1.036l1.554-1.554-.518-.518-.518.518-.518-.518a.742.742,0,0,1,0-1.043l.131-.131a1.437,1.437,0,0,1,1.159-.425,4.835,4.835,0,0,1,2.852,1.6,5.874,5.874,0,0,1,1.376,1.971,3.592,3.592,0,0,1,.224.881Z" transform="translate(-12.351 -4.687)" fill="#ede4a8" />
        <path id="Path_20119" data-name="Path 20119" d="M50.729,14.114v.2a.773.773,0,0,1-.773.773h-.773v-.773H48.41v2.319a1.546,1.546,0,1,1-3.092,0V14.315h-.773v.773h-.773A.773.773,0,0,1,43,14.315v-.2a1.512,1.512,0,0,1,.549-1.183A5.146,5.146,0,0,1,46.865,12a5.146,5.146,0,0,1,3.316.935,1.512,1.512,0,0,1,.549,1.183Z" transform="translate(-10.534 -3.548)" fill="#ede4a8" />
        <path id="Path_20120" data-name="Path 20120" d="M49.154,24.46a1.437,1.437,0,0,1-.425,1.159l-.131.131a.742.742,0,0,1-1.043,0l-.518-.518.518-.518-.518-.518-1.554,1.554a1.469,1.469,0,1,1-2.071-2.071l1.554-1.554-.518-.518-.518.518-.518-.518a.742.742,0,0,1,0-1.043l.131-.131a1.437,1.437,0,0,1,1.159-.425,4.835,4.835,0,0,1,2.852,1.6,6.653,6.653,0,0,1,1.121,1.438,3.659,3.659,0,0,1,.479,1.414Z" transform="translate(-10.512 -5.369)" fill="#ede4a8" />
        <path id="Path_20121" data-name="Path 20121" d="M35.635,47.42,38.6,51.578A2.581,2.581,0,0,1,36.725,52.8a13.139,13.139,0,0,0-2.458.394c-.85.332-1.546,1.059-2.373,1.453a5.741,5.741,0,0,1-2.234.479,7.476,7.476,0,0,1-2.52-.2,9.974,9.974,0,0,1-1.816-.812c-.526-.278-.51-.479-1.144-1.144L29.451,44l-1.128-.665a8.534,8.534,0,0,0,1.9.216A8.425,8.425,0,0,0,35.07,42.04a2,2,0,0,1,1.337.742L34.862,45.1v.023c-.255-.015-.518-.023-.773-.023l-.773,2.319a13.723,13.723,0,0,1,2.319-.07Z" transform="translate(-6.261 -10.373)" fill="#fdf9dc" />
        <path id="Path_20122" data-name="Path 20122" d="M34.083,48.695l.232-.7a25.621,25.621,0,0,1-6.333,1.121l-.541.917a17.777,17.777,0,0,0,3.821-.33,10.645,10.645,0,0,0,2.822-1.009Z" transform="translate(-7.001 -11.725)" fill="#fde9c8" />
        <path id="Path_20123" data-name="Path 20123" d="M34.854,47.031l.169-.507a20.505,20.505,0,0,0-5.616.386l-.5.85c.247-.088.5-.167.749-.232a20.143,20.143,0,0,1,5.2-.5Z" transform="translate(-7.335 -11.38)" fill="#fdd6aa" />
        <path id="Path_20124" data-name="Path 20124" d="M34.679,47.19a20.142,20.142,0,0,0-5.2.5,7.679,7.679,0,0,0-.749.232l-.594,1.011a25.621,25.621,0,0,0,6.333-1.121Z" transform="translate(-7.16 -11.54)" fill="#fdf9dc" />
        <path id="Path_20125" data-name="Path 20125" d="M35.707,49.709a8.385,8.385,0,0,0,.87-.362l-.266-.373V48.9a13.725,13.725,0,0,0-2.319.07l.027-.077a10.645,10.645,0,0,1-2.82,1,17.777,17.777,0,0,1-3.821.33l-.222.378a3.891,3.891,0,0,0,.764.106,20.294,20.294,0,0,0,7.786-1.006Z" transform="translate(-6.937 -11.927)" fill="#fdd6aa" />
        <path id="Path_20126" data-name="Path 20126" d="M28.126,53.424c.072.1.139.2.214.3,1.932.037,3.872.073,5.8-.077a21.1,21.1,0,0,0,4.606-.85l-.535-.749a18.214,18.214,0,0,1-4.519,1.11,49.729,49.729,0,0,1-5.567.265Z" transform="translate(-7.157 -12.646)" fill="#fdd6aa" />
        <path id="Path_20127" data-name="Path 20127" d="M35.171,46.448l.073-.221q-2.646-.13-5.3-.061l-.393.669a20.505,20.505,0,0,1,5.616-.386Z" transform="translate(-7.481 -11.304)" fill="#fdf9dc" />
        <path id="Path_20128" data-name="Path 20128" d="M37.423,42.56a2.231,2.231,0,0,0-1.135-.52,8.425,8.425,0,0,1-4.846,1.515,8.534,8.534,0,0,1-1.9-.216L30.668,44l-.114.192a20.846,20.846,0,0,0,2.82-.232A11.212,11.212,0,0,0,37.423,42.56Z" transform="translate(-7.478 -10.373)" fill="#fdce98" />
        <path id="Path_20129" data-name="Path 20129" d="M36.41,44.281a7.082,7.082,0,0,0,.718-.193l.769-1.153a1.377,1.377,0,0,0-.2-.222,11.212,11.212,0,0,1-4.049,1.406,20.846,20.846,0,0,1-2.82.232l-.087.148a27.676,27.676,0,0,0,5.67-.217Z" transform="translate(-7.75 -10.526)" fill="#fdd6aa" />
        <path id="Path_20130" data-name="Path 20130" d="M35.36,45.852l.065-.195c.255,0,.518.008.773.023v-.023l.777-1.166a7.083,7.083,0,0,1-.718.193,27.676,27.676,0,0,1-5.669.215l-.523.891Q32.712,45.726,35.36,45.852Z" transform="translate(-7.597 -10.93)" fill="#fde9c8" />
        <path id="Path_20131" data-name="Path 20131" d="M25.648,53.425l-.519.882c.005.006.009.015.014.021a6.261,6.261,0,0,0,.7.734,38.333,38.333,0,0,0,3.239,2.667c.147,0,.293,0,.44-.006a24.624,24.624,0,0,1-3.879-4.3Z" transform="translate(-6.476 -12.958)" fill="#fdd6aa" />
        <path id="Path_20132" data-name="Path 20132" d="M33.4,55.248a8.25,8.25,0,0,1,3.187.876,5.826,5.826,0,0,1,1.135-.206,9.906,9.906,0,0,0-4.321-.671Z" transform="translate(-8.354 -13.367)" fill="#fdd6aa" />
        <path id="Path_20133" data-name="Path 20133" d="M37.341,50.665,36.5,49.482a8.381,8.381,0,0,1-.87.362,20.294,20.294,0,0,1-7.786,1,3.892,3.892,0,0,1-.764-.106l-.271.464a12.622,12.622,0,0,0,1.019,1.636,49.731,49.731,0,0,0,5.565-.267,18.214,18.214,0,0,0,4.519-1.11Z" transform="translate(-6.857 -12.063)" fill="#fdf6c3" />
        <path id="Path_20134" data-name="Path 20134" d="M29.629,55.616a12.747,12.747,0,0,0,3.006,2.308,3.727,3.727,0,0,0,.5-.2,4.909,4.909,0,0,0,.587-.339c.433-.284.85-.608,1.294-.863a12.868,12.868,0,0,0-5.383-.907Z" transform="translate(-7.498 -13.453)" fill="#fdae98" />
        <path id="Path_20135" data-name="Path 20135" d="M39.562,54.07l-.752-1.054a21.1,21.1,0,0,1-4.606.85,57.849,57.849,0,0,1-5.8.077c.155.2.323.393.489.584a13.653,13.653,0,0,1,3.128-.213,28.771,28.771,0,0,1,3.8.222,11.66,11.66,0,0,1,2.412.608,2.347,2.347,0,0,0,1.329-1.074Z" transform="translate(-7.22 -12.865)" fill="#fdae98" />
        <path id="Path_20136" data-name="Path 20136" d="M32.164,54.7a13.653,13.653,0,0,0-3.128.213c.148.171.3.336.458.5a12.868,12.868,0,0,1,5.383.907,3.64,3.64,0,0,1,.492-.25c.077-.028.149-.04.223-.063a8.25,8.25,0,0,0-3.184-.878,9.907,9.907,0,0,1,4.321.671c.367-.035.737-.061,1.1-.126a3.421,3.421,0,0,0,.55-.147,11.66,11.66,0,0,0-2.415-.608A28.77,28.77,0,0,0,32.164,54.7Z" transform="translate(-7.363 -13.243)" fill="#fdf6c3" />
        <path id="Path_20137" data-name="Path 20137" d="M28.847,54.733c-.155-.163-.309-.328-.458-.5-.166-.192-.334-.386-.489-.584-.077-.1-.142-.2-.214-.3a12.622,12.622,0,0,1-1.019-1.636l-.482.819c.755,1,1.577,1.939,2.425,2.86a13.416,13.416,0,0,0,2.047,1.891,5.7,5.7,0,0,0,1.2-.238,12.747,12.747,0,0,1-3.006-2.311Z" transform="translate(-6.716 -12.569)" fill="#fdd6aa" />
        <path id="Path_20138" data-name="Path 20138" d="M24.6,55.256l-.42.714c.634.665.618.866,1.144,1.144a10.517,10.517,0,0,0,1.718.778,7.955,7.955,0,0,1-1.067-.927A14.36,14.36,0,0,1,24.6,55.256Z" transform="translate(-6.261 -13.374)" fill="#fdd6aa" />
        <path id="Path_20139" data-name="Path 20139" d="M25.051,54.587c-.005-.006-.009-.015-.014-.021l-.314.533a14.36,14.36,0,0,0,1.373,1.71,7.955,7.955,0,0,0,1.067.928c.033.011.065.024.1.034a6.212,6.212,0,0,0,1.731.219,38.33,38.33,0,0,1-3.239-2.667,6.261,6.261,0,0,1-.7-.736Z" transform="translate(-6.384 -13.217)" fill="#fdeccf" />
        <path id="Path_20140" data-name="Path 20140" d="M28.522,55.628c-.85-.917-1.67-1.861-2.425-2.86l-.3.5a24.623,24.623,0,0,0,3.879,4.3c.117,0,.232-.005.349-.012.18-.011.361-.025.541-.043a13.416,13.416,0,0,1-2.047-1.887Z" transform="translate(-6.629 -12.809)" fill="#fde9c8" />
        <path id="Path_20141" data-name="Path 20141" d="M37.158,50.415l-.665-.928a8.382,8.382,0,0,1-.87.363,20.294,20.294,0,0,1-7.786,1,3.892,3.892,0,0,1-.764-.106l-.271.464c.176.342.375.67.584.992a37.85,37.85,0,0,0,3.881-.271,23.3,23.3,0,0,0,5.89-1.519Z" transform="translate(-6.856 -12.064)" fill="#fddbc0" />
        <path id="Path_20142" data-name="Path 20142" d="M37.33,50.687A23.3,23.3,0,0,1,31.44,52.2a37.848,37.848,0,0,1-3.878.274c.141.217.284.434.436.643a49.729,49.729,0,0,0,5.565-.267,18.213,18.213,0,0,0,4.519-1.11l-.571-.8Z" transform="translate(-7.029 -12.336)" fill="#fdae98" />
        <ellipse id="Ellipse_1809" data-name="Ellipse 1809" cx="1.5" cy="1" rx="1.5" ry="1" transform="translate(21 39.917)" fill="#ffee59" />
        <path id="Path_20143" data-name="Path 20143" d="M29.159,53.886a1.334,1.334,0,0,1-1-.386.589.589,0,0,0-.161.386c0,.427.519.773,1.159.773s1.159-.346,1.159-.773a.589.589,0,0,0-.161-.386A1.334,1.334,0,0,1,29.159,53.886Z" transform="translate(-7.128 -12.975)" fill="#ffce52" />
        <ellipse id="Ellipse_1810" data-name="Ellipse 1810" cx="1.5" cy="1" rx="1.5" ry="1" transform="translate(25 37.917)" fill="#ffee59" />
        <path id="Path_20144" data-name="Path 20144" d="M35.159,50.886a1.334,1.334,0,0,1-1-.386.589.589,0,0,0-.161.386c0,.427.519.773,1.159.773s1.159-.346,1.159-.773a.589.589,0,0,0-.161-.386A1.334,1.334,0,0,1,35.159,50.886Z" transform="translate(-8.491 -12.294)" fill="#ffce52" />
        <ellipse id="Ellipse_1811" data-name="Ellipse 1811" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(24 35.917)" fill="#ffee59" />
        <path id="Path_20145" data-name="Path 20145" d="M32.159,48.886a1.334,1.334,0,0,1-1-.386.589.589,0,0,0-.161.386c0,.427.519.773,1.159.773s1.159-.346,1.159-.773a.589.589,0,0,0-.161-.386A1.334,1.334,0,0,1,32.159,48.886Z" transform="translate(-7.809 -11.84)" fill="#ffce52" />
        <ellipse id="Ellipse_1812" data-name="Ellipse 1812" cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(24 40.917)" fill="#ffee59" />
        <path id="Path_20146" data-name="Path 20146" d="M32.159,54.886a1.334,1.334,0,0,1-1-.386.589.589,0,0,0-.161.386c0,.427.519.773,1.159.773s1.159-.346,1.159-.773a.589.589,0,0,0-.161-.386,1.334,1.334,0,0,1-1,.386Z" transform="translate(-7.809 -13.202)" fill="#ffce52" />
        <circle id="Ellipse_1813" data-name="Ellipse 1813" cx="1" cy="1" r="1" transform="translate(28 40.917)" fill="#ffee59" />
        <path id="Path_20147" data-name="Path 20147" d="M38.159,54.886a1.334,1.334,0,0,1-1-.386.589.589,0,0,0-.161.386c0,.427.519.773,1.159.773s1.159-.346,1.159-.773a.589.589,0,0,0-.161-.386,1.334,1.334,0,0,1-1,.386Z" transform="translate(-9.172 -13.202)" fill="#ffce52" />
        <path id="Path_20148" data-name="Path 20148" d="M55.048,38.319l-.773,2.319a14.535,14.535,0,0,1-1.538-.085A25.166,25.166,0,0,1,45,38.319V36a11.807,11.807,0,0,0,1.5.858,16.225,16.225,0,0,0,8.549,1.461Z" transform="translate(-10.988 -9.001)" fill="#ff9a1f" />
        <path id="Path_20149" data-name="Path 20149" d="M54.275,34v2.319H46.546L46.5,36.4a11.807,11.807,0,0,1-1.5-.858l4.638-.773V34Z" transform="translate(-10.988 -8.547)" fill="#ff9a1f" />
        <path id="Path_20150" data-name="Path 20150" d="M51.686,40.8l-.008.085L50.9,43.2,40.37,39.435A8.2,8.2,0,0,0,41.8,37.07l2.149.719v.773A25.166,25.166,0,0,0,51.686,40.8Z" transform="translate(-9.937 -9.244)" fill="#ff9a1f" />
        <path id="Path_20151" data-name="Path 20151" d="M48.821,46.092v3.092L46.5,47.638a24.743,24.743,0,0,0-8.5-2.3v-.023L39.546,43C42.638,43,48.821,46.092,48.821,46.092Z" transform="translate(-9.399 -10.591)" fill="#ff9a1f" />
        <path id="Path_20152" data-name="Path 20152" d="M49.006,33.546v.773l-4.638.773v1.546l-2.149-.719a8.5,8.5,0,0,0,.6-3.146A29.247,29.247,0,0,1,49.006,32Z" transform="translate(-10.357 -8.093)" fill="#ff9a1f" />
        <path id="Path_20153" data-name="Path 20153" d="M46.048,48.319v2.319a13.608,13.608,0,0,0-3.811-1.747,16.364,16.364,0,0,0-3.919-.642,13.725,13.725,0,0,0-2.319.07L36.773,46c.255,0,.518.008.773.023a24.744,24.744,0,0,1,8.5,2.3Z" transform="translate(-8.945 -11.272)" fill="#ff9a1f" />
        <path id="Path_20154" data-name="Path 20154" d="M41.968,53.138,39,48.98v-.07a16.365,16.365,0,0,1,3.919.642l-.054.2,2.319,3.092L42.865,54.39Z" transform="translate(-9.626 -11.933)" fill="#ff9a1f" />
        <path id="Path_20155" data-name="Path 20155" d="M42.865,49.752l.054-.2A16.365,16.365,0,0,0,39,48.91v.07l1.1,1.546h1.114a1.546,1.546,0,0,1,1.326.751l1.427,2.378,1.214-.81Z" transform="translate(-9.626 -11.933)" fill="#ff8000" />
        <path id="Path_20156" data-name="Path 20156" d="M39.71,47.109a30.691,30.691,0,0,1,6.338,2.2v-.993a24.744,24.744,0,0,0-8.5-2.3c-.255-.015-.518-.023-.773-.023L36,48.319a12.882,12.882,0,0,1,1.832-.084l.038-.114a1.54,1.54,0,0,1,1.84-1.012Z" transform="translate(-8.945 -11.272)" fill="#f55d05" />
        <path id="Path_20157" data-name="Path 20157" d="M41.029,43.175A7.807,7.807,0,0,0,39.546,43L38,45.319v.023a24.744,24.744,0,0,1,8.5,2.3l2.319,1.546V47.6c-1.841-1.589-4.937-2.409-6.843-2.783a1.261,1.261,0,0,1-.949-1.641Z" transform="translate(-9.399 -10.591)" fill="#ff8000" />
        <path id="Path_20158" data-name="Path 20158" d="M51.922,40.881l.008-.085a25.166,25.166,0,0,1-7.737-2.234v-.773l-2.149-.719a8.107,8.107,0,0,1-.6,1.216A92.288,92.288,0,0,0,51.5,42.135Z" transform="translate(-10.181 -9.244)" fill="#e45705" />
        <path id="Path_20159" data-name="Path 20159" d="M47.56,38.713a1.54,1.54,0,0,1-1.014-1.452v-.383l-.046-.021A11.807,11.807,0,0,1,45,36v2.319a25.166,25.166,0,0,0,7.737,2.234,14.536,14.536,0,0,0,1.538.085l.232-.689c-2.253.3-5.361-.657-6.947-1.235Z" transform="translate(-10.988 -9.001)" fill="#ff7e05" />
        <path id="Path_20160" data-name="Path 20160" d="M42.22,36.827l2.149.719V36H42.5A8.58,8.58,0,0,1,42.22,36.827Z" transform="translate(-10.357 -9.001)" fill="#cc4b00" />
        <path id="Path_20161" data-name="Path 20161" d="M44.472,32.582c-.949.168-1.567.323-1.567.323a8.466,8.466,0,0,1-.326,2.319H44.45l4.638-.773V33.126l-2.667.762a1.539,1.539,0,0,1-1.949-1.306Z" transform="translate(-10.438 -8.225)" fill="#ff7e05" />
        <path id="Path_20162" data-name="Path 20162" d="M51.956,35.546A1.546,1.546,0,0,1,50.41,34h-.773v.773L45,35.546a11.807,11.807,0,0,0,1.5.858l.046-.085h7.729v-.773Z" transform="translate(-10.988 -8.547)" fill="#f55d05" />
        <path id="Path_20163" data-name="Path 20163" d="M37.49,21.44l-.546.546L34.757,19.8l.219-.219.328-.328L36.4,18.16a1.546,1.546,0,0,1,2.186,2.186Z" transform="translate(-8.662 -4.848)" fill="#547d30" />
        <path id="Path_20164" data-name="Path 20164" d="M41.694,18.739l-1.642,1.64-2.4.219L38.74,19.5a1.546,1.546,0,0,0-2.186-2.186L35.46,18.411l.012-.309a.391.391,0,0,0,.005-.082.879.879,0,0,0,.011-.208l.082-1.7,1.749-1.748a1.237,1.237,0,0,1,1.748,0l2.623,2.623a1.237,1.237,0,0,1,0,1.752Z" transform="translate(-8.822 -4.006)" fill="#6fa23b" />
        <path id="Path_20165" data-name="Path 20165" d="M35.574,16.289l-.082,1.7a.879.879,0,0,1-.011.208.391.391,0,0,1-.005.082l-.012.309.911-.911.729-2.915Z" transform="translate(-8.823 -4.179)" fill="#699934" />
        <path id="Path_20166" data-name="Path 20166" d="M40.155,18.9a.148.148,0,0,1,0,.041l2.752-1.1a1.225,1.225,0,0,0-.245-.36l-1.314-1.314L39.7,17.808A1.511,1.511,0,0,1,40.155,18.9Z" transform="translate(-9.786 -4.498)" fill="#84b851" />
        <path id="Path_20167" data-name="Path 20167" d="M38.293,22.215,40.7,22l.875-.875-2.551.365Z" transform="translate(-9.465 -5.623)" fill="#699934" />
        <path id="Path_20168" data-name="Path 20168" d="M39.373,17.353l1.639-1.639L39.7,14.4a1.225,1.225,0,0,0-.36-.245l-1.1,2.749a1.54,1.54,0,0,1,1.135.449Z" transform="translate(-9.453 -4.041)" fill="#93b86e" />
        <path id="Path_20169" data-name="Path 20169" d="M37.877,16.873l1.1-2.749a1.237,1.237,0,0,0-1.391.245l-.219.219L36.639,17.5l.182-.182A1.537,1.537,0,0,1,37.877,16.873Z" transform="translate(-9.09 -4.008)" fill="#719e4d" />
        <path id="Path_20170" data-name="Path 20170" d="M42.8,18.335l-2.752,1.1a1.5,1.5,0,0,1-.444,1.052l-.364.365,2.551-.365.765-.765A1.237,1.237,0,0,0,42.8,18.335Z" transform="translate(-9.68 -4.99)" fill="#719e4d" />
        <path id="Path_20171" data-name="Path 20171" d="M36.558,21.667l-1.094-1.094,1.64-1.639A.773.773,0,0,1,38.2,20.026Z" transform="translate(-8.823 -5.075)" fill="#719657" />
        <path id="Path_20172" data-name="Path 20172" d="M34.092,12.092v.773H31V10.546a1.546,1.546,0,1,1,3.092,0Z" transform="translate(-7.809 -2.87)" fill="#547d30" />
        <path id="Path_20173" data-name="Path 20173" d="M35.183,7.237V9.555L33.638,11.41V9.865a1.546,1.546,0,1,0-3.092,0V11.41l-.209-.224a.386.386,0,0,0-.054-.062.868.868,0,0,0-.139-.155L29,9.71V7.237A1.237,1.237,0,0,1,30.237,6h3.71A1.237,1.237,0,0,1,35.183,7.237Z" transform="translate(-7.355 -2.189)" fill="#6fa23b" />
        <path id="Path_20174" data-name="Path 20174" d="M29,10.164l1.144,1.26a.868.868,0,0,1,.139.155.386.386,0,0,1,.054.062l.209.224V10.576L29,8Z" transform="translate(-7.355 -2.643)" fill="#699934" />
        <path id="Path_20175" data-name="Path 20175" d="M34.09,8.775c.01.009.017.022.026.031l1.168-2.725A1.219,1.219,0,0,0,34.855,6H33V8.319a1.511,1.511,0,0,1,1.09.456Z" transform="translate(-8.264 -2.189)" fill="#84b851" />
        <path id="Path_20176" data-name="Path 20176" d="M35,12.092l1.546-1.855V9L35,11.061Z" transform="translate(-8.718 -2.87)" fill="#699934" />
        <path id="Path_20177" data-name="Path 20177" d="M32.329,8.319V6H30.474a1.219,1.219,0,0,0-.429.081L31.212,8.8A1.538,1.538,0,0,1,32.329,8.319Z" transform="translate(-7.593 -2.189)" fill="#93b86e" />
        <path id="Path_20178" data-name="Path 20178" d="M30.975,8.827,29.808,6.105A1.237,1.237,0,0,0,29,7.261V7.57l1.546,2.576V9.888a1.537,1.537,0,0,1,.429-1.061Z" transform="translate(-7.355 -2.213)" fill="#719e4d" />
        <path id="Path_20179" data-name="Path 20179" d="M35.612,6.105,34.444,8.83a1.508,1.508,0,0,1,.43,1.059V10.4L36.42,8.343V7.261A1.237,1.237,0,0,0,35.612,6.1Z" transform="translate(-8.591 -2.213)" fill="#719e4d" />
        <path id="Path_20180" data-name="Path 20180" d="M33.546,13.092H32V10.773a.773.773,0,1,1,1.546,0Z" transform="translate(-8.036 -3.097)" fill="#719657" />
        <path id="Path_20181" data-name="Path 20181" d="M29.092,20.092v.773H26V18.546a1.546,1.546,0,1,1,3.092,0Z" transform="translate(-6.674 -4.687)" fill="#547d30" />
        <path id="Path_20182" data-name="Path 20182" d="M30.183,15.237v2.319L28.638,19.41V17.865a1.546,1.546,0,0,0-3.092,0V19.41l-.209-.224a.386.386,0,0,0-.054-.062.868.868,0,0,0-.139-.155L24,17.71V15.237A1.237,1.237,0,0,1,25.237,14h3.71A1.237,1.237,0,0,1,30.183,15.237Z" transform="translate(-6.22 -4.006)" fill="#6fa23b" />
        <path id="Path_20183" data-name="Path 20183" d="M24,18.164l1.144,1.26a.868.868,0,0,1,.139.155.387.387,0,0,1,.054.062l.209.224V18.576L24,16Z" transform="translate(-6.22 -4.46)" fill="#699934" />
        <path id="Path_20184" data-name="Path 20184" d="M29.09,16.775c.01.009.017.022.026.031l1.168-2.725A1.219,1.219,0,0,0,29.855,14H28v2.319a1.511,1.511,0,0,1,1.09.456Z" transform="translate(-7.128 -4.006)" fill="#84b851" />
        <path id="Path_20185" data-name="Path 20185" d="M30,20.092l1.546-1.855V17L30,19.061Z" transform="translate(-7.582 -4.687)" fill="#699934" />
        <path id="Path_20186" data-name="Path 20186" d="M27.329,16.319V14H25.474a1.219,1.219,0,0,0-.429.081L26.212,16.8a1.538,1.538,0,0,1,1.117-.485Z" transform="translate(-6.457 -4.006)" fill="#93b86e" />
        <path id="Path_20187" data-name="Path 20187" d="M25.975,16.827l-1.167-2.722A1.237,1.237,0,0,0,24,15.261v.309l1.546,2.576v-.257a1.537,1.537,0,0,1,.429-1.061Z" transform="translate(-6.22 -4.03)" fill="#719e4d" />
        <path id="Path_20188" data-name="Path 20188" d="M30.612,14.105,29.444,16.83a1.508,1.508,0,0,1,.43,1.059V18.4l1.546-2.061V15.261A1.237,1.237,0,0,0,30.612,14.105Z" transform="translate(-7.456 -4.03)" fill="#719e4d" />
        <path id="Path_20189" data-name="Path 20189" d="M28.546,21.092H27V18.773a.773.773,0,0,1,1.546,0Z" transform="translate(-6.901 -4.914)" fill="#719657" />
        <path id="Path_20190" data-name="Path 20190" d="M34.092,18.092v.773H31V16.546a1.546,1.546,0,1,1,3.092,0Z" transform="translate(-7.809 -4.233)" fill="#416125" />
        <path id="Path_20191" data-name="Path 20191" d="M35.183,13.237v2.319L33.638,17.41V15.865a1.546,1.546,0,0,0-3.092,0V17.41l-.209-.224a.386.386,0,0,0-.054-.062.868.868,0,0,0-.139-.155L29,15.71V13.237A1.237,1.237,0,0,1,30.237,12h3.71A1.237,1.237,0,0,1,35.183,13.237Z" transform="translate(-7.355 -3.552)" fill="#6fa23b" />
        <path id="Path_20192" data-name="Path 20192" d="M29,16.164l1.144,1.26a.868.868,0,0,1,.139.155.387.387,0,0,1,.054.062l.209.224V16.576L29,14Z" transform="translate(-7.355 -4.006)" fill="#53752e" />
        <path id="Path_20193" data-name="Path 20193" d="M34.09,14.775c.01.009.017.022.026.031l1.168-2.725A1.219,1.219,0,0,0,34.855,12H33v2.319A1.511,1.511,0,0,1,34.09,14.775Z" transform="translate(-8.264 -3.552)" fill="#6cad2c" />
        <path id="Path_20194" data-name="Path 20194" d="M35,18.092l1.546-1.855V15L35,17.061Z" transform="translate(-8.718 -4.233)" fill="#53752e" />
        <path id="Path_20195" data-name="Path 20195" d="M32.329,14.319V12H30.474a1.219,1.219,0,0,0-.429.081L31.212,14.8A1.538,1.538,0,0,1,32.329,14.319Z" transform="translate(-7.593 -3.552)" fill="#65a229" />
        <path id="Path_20196" data-name="Path 20196" d="M30.975,14.827l-1.167-2.722A1.237,1.237,0,0,0,29,13.261v.309l1.546,2.576v-.257a1.537,1.537,0,0,1,.429-1.061Z" transform="translate(-7.355 -3.575)" fill="#548430" />
        <path id="Path_20197" data-name="Path 20197" d="M35.612,12.105,34.444,14.83a1.508,1.508,0,0,1,.43,1.059V16.4l1.546-2.061V13.261A1.237,1.237,0,0,0,35.612,12.105Z" transform="translate(-8.591 -3.575)" fill="#548430" />
        <path id="Path_20198" data-name="Path 20198" d="M33.546,19.092H32V16.773a.773.773,0,1,1,1.546,0Z" transform="translate(-8.036 -4.46)" fill="#548430" />
        <path id="Path_20199" data-name="Path 20199" d="M40.092,12.092v.773H37V10.546a1.546,1.546,0,0,1,3.092,0Z" transform="translate(-9.172 -2.87)" fill="#416125" />
        <path id="Path_20200" data-name="Path 20200" d="M41.183,7.237V9.555L39.638,11.41V9.865a1.546,1.546,0,1,0-3.092,0V11.41l-.209-.224a.386.386,0,0,0-.054-.062.868.868,0,0,0-.139-.155L35,9.71V7.237A1.237,1.237,0,0,1,36.237,6h3.71A1.237,1.237,0,0,1,41.183,7.237Z" transform="translate(-8.718 -2.189)" fill="#6fa23b" />
        <path id="Path_20201" data-name="Path 20201" d="M35,10.164l1.144,1.26a.868.868,0,0,1,.139.155.386.386,0,0,1,.054.062l.209.224V10.576L35,8Z" transform="translate(-8.718 -2.643)" fill="#53752e" />
        <path id="Path_20202" data-name="Path 20202" d="M40.09,8.775c.01.009.017.022.026.031l1.168-2.725A1.219,1.219,0,0,0,40.855,6H39V8.319a1.511,1.511,0,0,1,1.09.456Z" transform="translate(-9.626 -2.189)" fill="#6cad2c" />
        <path id="Path_20203" data-name="Path 20203" d="M41,12.092l1.546-1.855V9L41,11.061Z" transform="translate(-10.08 -2.87)" fill="#53752e" />
        <path id="Path_20204" data-name="Path 20204" d="M38.329,8.319V6H36.474a1.219,1.219,0,0,0-.429.081L37.212,8.8A1.538,1.538,0,0,1,38.329,8.319Z" transform="translate(-8.955 -2.189)" fill="#65a229" />
        <path id="Path_20205" data-name="Path 20205" d="M36.975,8.827,35.808,6.105A1.237,1.237,0,0,0,35,7.261V7.57l1.546,2.576V9.888a1.537,1.537,0,0,1,.429-1.061Z" transform="translate(-8.718 -2.213)" fill="#548430" />
        <path id="Path_20206" data-name="Path 20206" d="M41.612,6.105,40.444,8.83a1.508,1.508,0,0,1,.43,1.059V10.4L42.42,8.343V7.261A1.237,1.237,0,0,0,41.612,6.1Z" transform="translate(-9.954 -2.213)" fill="#548430" />
        <path id="Path_20207" data-name="Path 20207" d="M39.546,13.092H38V10.773a.773.773,0,0,1,1.546,0Z" transform="translate(-9.399 -3.097)" fill="#548430" />
        <path id="Path_20208" data-name="Path 20208" d="M29.047,9.681l.592.591L27.272,12.64l-.237-.237-.355-.356L25.5,10.862a1.674,1.674,0,1,1,2.367-2.367Z" transform="translate(-6.449 -2.645)" fill="#416125" />
        <path id="Path_20209" data-name="Path 20209" d="M26.125,4.39,27.9,6.168l.237,2.6L26.951,7.586a1.674,1.674,0,0,0-2.367,2.367l1.184,1.183-.332-.012a.4.4,0,0,0-.089-.006.941.941,0,0,0-.225-.012l-1.84-.089L21.387,9.125a1.344,1.344,0,0,1,0-1.894L24.229,4.39a1.344,1.344,0,0,1,1.9,0Z" transform="translate(-5.538 -1.735)" fill="#6fa23b" />
        <path id="Path_20210" data-name="Path 20210" d="M23.47,12.594l1.84.089a.941.941,0,0,1,.225.012.405.405,0,0,1,.089.006l.332.012-.983-.986-3.156-.789Z" transform="translate(-5.724 -3.31)" fill="#53752e" />
        <path id="Path_20211" data-name="Path 20211" d="M26.3,7.138h.045L25.154,4.161a1.351,1.351,0,0,0-.386.266L23.343,5.848l1.778,1.778A1.639,1.639,0,0,1,26.3,7.138Z" transform="translate(-6.071 -1.771)" fill="#6cad2c" />
        <path id="Path_20212" data-name="Path 20212" d="M29.886,9.129l-.237-2.6L28.7,5.577,29.1,8.339Z" transform="translate(-7.288 -2.093)" fill="#53752e" />
        <path id="Path_20213" data-name="Path 20213" d="M24.623,8.118,22.845,6.34,21.427,7.763a1.351,1.351,0,0,0-.266.39l2.978,1.191a1.666,1.666,0,0,1,.484-1.227Z" transform="translate(-5.575 -2.266)" fill="#65a229" />
        <path id="Path_20214" data-name="Path 20214" d="M24.1,9.877,21.125,8.686a1.341,1.341,0,0,0,.266,1.5l.237.237,3.16.789-.2-.2A1.661,1.661,0,0,1,24.1,9.877Z" transform="translate(-5.539 -2.799)" fill="#548430" />
        <path id="Path_20215" data-name="Path 20215" d="M25.686,4.125l1.193,2.98a1.632,1.632,0,0,1,1.137.482l.394.395-.394-2.762-.826-.829a1.341,1.341,0,0,0-1.5-.266Z" transform="translate(-6.603 -1.736)" fill="#548430" />
        <path id="Path_20216" data-name="Path 20216" d="M29.291,11.106l-1.186,1.186-1.778-1.778a.837.837,0,1,1,1.183-1.183Z" transform="translate(-6.693 -2.89)" fill="#548430" />
        <path id="Path_20217" data-name="Path 20217" d="M52.75,22.621l-.134.134a.737.737,0,0,1-1.038,0l-.519-.519.519-.519-.519-.519L49.5,22.756a1.467,1.467,0,0,1-2.075-2.075l1.557-1.557-.519-.519-.519.519-.519-.519a.734.734,0,0,1,0-1.037l.134-.135a1.436,1.436,0,0,1,1.159-.425,4.888,4.888,0,0,1,2.854,1.6,4.888,4.888,0,0,1,1.6,2.853,1.436,1.436,0,0,1-.423,1.162Z" transform="translate(-11.443 -4.687)" fill="#fff5db" />
        <path id="Path_20218" data-name="Path 20218" d="M47.89,17.292a1.51,1.51,0,0,0-.267.207l-.134.135a.734.734,0,0,0,0,1.037l.519.519V18.153A.727.727,0,0,1,47.89,17.292Z" transform="translate(-11.505 -4.753)" fill="#fcb584" />
        <path id="Path_20219" data-name="Path 20219" d="M53.288,23.775H52.251l.519.519a.737.737,0,0,0,1.038,0l.134-.134a1.517,1.517,0,0,0,.213-.271A.727.727,0,0,1,53.288,23.775Z" transform="translate(-12.635 -6.225)" fill="#fcb584" />
        <path id="Path_20220" data-name="Path 20220" d="M51.668,18.605a4.888,4.888,0,0,0-2.854-1.6A.972.972,0,0,0,48.643,17a1.45,1.45,0,0,0-.992.431l-.134.135a.685.685,0,0,0-.117.177,1.405,1.405,0,0,1,.724-.224,1.062,1.062,0,0,1,.172.005,4.888,4.888,0,0,1,2.854,1.6,4.887,4.887,0,0,1,1.6,2.854,1.4,1.4,0,0,1-.213.891.7.7,0,0,0,.172-.114l.134-.134a1.434,1.434,0,0,0,.426-1.159,4.888,4.888,0,0,0-1.6-2.854Z" transform="translate(-11.533 -4.687)" fill="#cc7539" />
        <path id="Path_20221" data-name="Path 20221" d="M47.945,23.166a1.478,1.478,0,0,1,0-2.075l-.519.519A1.467,1.467,0,1,0,49.5,23.684l.519-.519A1.473,1.473,0,0,1,47.945,23.166Z" transform="translate(-11.443 -5.616)" fill="#fcb584" />
        <path id="Path_20222" data-name="Path 20222" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,1,1,.367,0Z" transform="translate(36.499 16.522) rotate(-45)" fill="#ffc59c" />
        <path id="Path_20223" data-name="Path 20223" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,0,1,.367,0Z" transform="translate(37.018 17.041) rotate(-45)" fill="#ffd7ba" />
        <path id="Path_20224" data-name="Path 20224" d="M42.435,28.621l.134.134a.737.737,0,0,0,1.038,0l.519-.519-.519-.519.519-.519,1.557,1.556a1.467,1.467,0,1,0,2.075-2.075L46.2,25.124l.519-.519.519.519.519-.519a.734.734,0,0,0,0-1.037l-.134-.135a1.436,1.436,0,0,0-1.159-.425,4.888,4.888,0,0,0-2.854,1.6,4.888,4.888,0,0,0-1.6,2.853,1.436,1.436,0,0,0,.423,1.162Z" transform="translate(-10.308 -6.05)" fill="#fff5db" />
        <path id="Path_20225" data-name="Path 20225" d="M48.895,23.292a1.51,1.51,0,0,1,.267.207l.134.135a.734.734,0,0,1,0,1.037l-.519.519V24.153a.727.727,0,0,0,.117-.861Z" transform="translate(-11.846 -6.116)" fill="#fcb584" />
        <path id="Path_20226" data-name="Path 20226" d="M43.152,29.775H44.19l-.519.519a.737.737,0,0,1-1.038,0l-.134-.134a1.518,1.518,0,0,1-.213-.271.727.727,0,0,0,.866-.114Z" transform="translate(-10.372 -7.588)" fill="#fcb584" />
        <path id="Path_20227" data-name="Path 20227" d="M43.607,24.605a4.888,4.888,0,0,1,2.854-1.6A.972.972,0,0,1,46.632,23a1.45,1.45,0,0,1,.992.431l.134.135a.686.686,0,0,1,.117.177,1.405,1.405,0,0,0-.724-.224,1.061,1.061,0,0,0-.172.005,4.888,4.888,0,0,0-2.854,1.6,4.887,4.887,0,0,0-1.6,2.854,1.4,1.4,0,0,0,.213.891.7.7,0,0,1-.172-.114l-.134-.134a1.435,1.435,0,0,1-.426-1.159,4.888,4.888,0,0,1,1.6-2.854Z" transform="translate(-10.308 -6.049)" fill="#cc7539" />
        <path id="Path_20228" data-name="Path 20228" d="M48.168,29.166a1.478,1.478,0,0,0,0-2.075l.519.519a1.467,1.467,0,1,1-2.075,2.075l-.519-.519a1.473,1.473,0,0,0,2.076,0Z" transform="translate(-11.236 -6.978)" fill="#fcb584" />
        <path id="Path_20229" data-name="Path 20229" d="M.367,0A.366.366,0,0,1,.734.366V1.835a.367.367,0,1,1-.734,0V.367A.367.367,0,0,1,.367,0Z" transform="translate(34.848 20.107) rotate(-45)" fill="#ffc59c" />
        <path id="Path_20230" data-name="Path 20230" d="M.367,0A.366.366,0,0,1,.734.366V1.835a.367.367,0,0,1-.734,0V.367A.367.367,0,0,1,.367,0Z" transform="translate(34.329 20.624) rotate(-45)" fill="#ffd7ba" />
        <path id="Path_20231" data-name="Path 20231" d="M37.435,24.621l.134.134a.737.737,0,0,0,1.038,0l.519-.519-.519-.519.519-.519,1.557,1.556a1.467,1.467,0,1,0,2.075-2.075L41.2,21.124l.519-.519.519.519.519-.519a.734.734,0,0,0,0-1.037l-.134-.135a1.436,1.436,0,0,0-1.159-.425,4.888,4.888,0,0,0-2.854,1.6,4.888,4.888,0,0,0-1.6,2.853,1.436,1.436,0,0,0,.423,1.162Z" transform="translate(-9.173 -5.142)" fill="#fff5db" />
        <path id="Path_20232" data-name="Path 20232" d="M43.895,19.292a1.509,1.509,0,0,1,.267.207l.134.135a.734.734,0,0,1,0,1.037l-.519.519V20.153a.727.727,0,0,0,.117-.861Z" transform="translate(-10.711 -5.207)" fill="#fcb584" />
        <path id="Path_20233" data-name="Path 20233" d="M38.152,25.775H39.19l-.519.519a.737.737,0,0,1-1.038,0l-.134-.134a1.517,1.517,0,0,1-.213-.271A.727.727,0,0,0,38.152,25.775Z" transform="translate(-9.237 -6.68)" fill="#fcb584" />
        <path id="Path_20234" data-name="Path 20234" d="M38.607,20.605a4.888,4.888,0,0,1,2.854-1.6A.971.971,0,0,1,41.632,19a1.45,1.45,0,0,1,.992.431l.134.135a.686.686,0,0,1,.117.177,1.405,1.405,0,0,0-.724-.224,1.062,1.062,0,0,0-.172.005,4.888,4.888,0,0,0-2.854,1.6,4.887,4.887,0,0,0-1.6,2.854,1.4,1.4,0,0,0,.213.891.7.7,0,0,1-.172-.114l-.134-.134a1.435,1.435,0,0,1-.426-1.159,4.888,4.888,0,0,1,1.6-2.854Z" transform="translate(-9.172 -5.141)" fill="#cc7539" />
        <path id="Path_20235" data-name="Path 20235" d="M43.168,25.166a1.478,1.478,0,0,0,0-2.075l.519.519a1.467,1.467,0,1,1-2.075,2.075l-.519-.519A1.473,1.473,0,0,0,43.168,25.166Z" transform="translate(-10.101 -6.07)" fill="#fcb584" />
        <path id="Path_20236" data-name="Path 20236" d="M.367,0A.366.366,0,0,1,.734.366V1.835a.367.367,0,1,1-.734,0V.367A.367.367,0,0,1,.367,0Z" transform="translate(30.984 17.016) rotate(-45)" fill="#ffc59c" />
        <path id="Path_20237" data-name="Path 20237" d="M.367,0A.366.366,0,0,1,.734.366V1.835a.367.367,0,1,1-.734,0V.367A.367.367,0,0,1,.367,0Z" transform="translate(30.465 17.533) rotate(-45)" fill="#ffd7ba" />
        <path id="Path_20238" data-name="Path 20238" d="M55.729,25.114v.2a.773.773,0,0,1-.773.773h-.773v-.773H53.41v2.319a1.546,1.546,0,0,1-3.092,0V25.315h-.773v.773h-.773A.773.773,0,0,1,48,25.315v-.2a1.512,1.512,0,0,1,.549-1.183A5.146,5.146,0,0,1,51.865,23a5.146,5.146,0,0,1,3.316.935A1.512,1.512,0,0,1,55.729,25.114Z" transform="translate(-11.67 -6.046)" fill="#fff5db" />
        <path id="Path_20239" data-name="Path 20239" d="M48.045,25.283a1.551,1.551,0,0,0-.045.353v.2a.773.773,0,0,0,.773.773h.773l-.773-.773A.764.764,0,0,1,48.045,25.283Z" transform="translate(-11.67 -6.568)" fill="#fcb584" />
        <path id="Path_20240" data-name="Path 20240" d="M56.773,25.835,56,26.608h.773a.773.773,0,0,0,.773-.773v-.2a1.6,1.6,0,0,0-.043-.359A.765.765,0,0,1,56.773,25.835Z" transform="translate(-13.486 -6.566)" fill="#fcb584" />
        <path id="Path_20241" data-name="Path 20241" d="M51.865,23a5.146,5.146,0,0,0-3.316.935,1,1,0,0,0-.131.124A1.523,1.523,0,0,0,48,25.114v.2a.74.74,0,0,0,.045.219,1.479,1.479,0,0,1,.373-.706,1,1,0,0,1,.131-.124,5.146,5.146,0,0,1,3.316-.935,5.146,5.146,0,0,1,3.316.935,1.474,1.474,0,0,1,.505.823.754.754,0,0,0,.043-.213v-.2a1.512,1.512,0,0,0-.549-1.183A5.146,5.146,0,0,0,51.865,23Z" transform="translate(-11.67 -6.046)" fill="#cc7539" />
        <path id="Path_20242" data-name="Path 20242" d="M52.546,29.546A1.557,1.557,0,0,1,51,28v.773a1.546,1.546,0,1,0,3.092,0V28A1.55,1.55,0,0,1,52.546,29.546Z" transform="translate(-12.351 -7.185)" fill="#fcb584" />
        <rect id="Rectangle_18154" data-name="Rectangle 18154" height="3" transform="translate(40 18.917)" fill="#ffc59c" />
        <rect id="Rectangle_18155" data-name="Rectangle 18155" width="1" height="3" rx="0.5" transform="translate(40 18.917)" fill="#ffd7ba" />
        <path id="Path_20243" data-name="Path 20243" d="M45,30.066v-.2a.773.773,0,0,1,.773-.773h.773v.773h.773V27.546a1.546,1.546,0,0,1,3.092,0v2.319h.773v-.773h.773a.773.773,0,0,1,.773.773v.2a1.512,1.512,0,0,1-.549,1.183,5.146,5.146,0,0,1-3.316.935,5.146,5.146,0,0,1-3.316-.935A1.512,1.512,0,0,1,45,30.066Z" transform="translate(-10.988 -6.731)" fill="#fff5db" />
        <path id="Path_20244" data-name="Path 20244" d="M54.5,31.327a1.552,1.552,0,0,0,.045-.353v-.2A.773.773,0,0,0,53.773,30H53l.773.773A.764.764,0,0,1,54.5,31.327Z" transform="translate(-12.805 -7.639)" fill="#fcb584" />
        <path id="Path_20245" data-name="Path 20245" d="M45.773,30.773,46.546,30h-.773a.773.773,0,0,0-.773.773v.2a1.6,1.6,0,0,0,.043.359A.765.765,0,0,1,45.773,30.773Z" transform="translate(-10.988 -7.639)" fill="#fcb584" />
        <path id="Path_20246" data-name="Path 20246" d="M48.865,33.255a5.146,5.146,0,0,0,3.316-.935,1,1,0,0,0,.131-.124,1.523,1.523,0,0,0,.417-1.059v-.2a.74.74,0,0,0-.045-.219,1.479,1.479,0,0,1-.373.706,1,1,0,0,1-.131.124,5.146,5.146,0,0,1-3.316.935,5.146,5.146,0,0,1-3.316-.935,1.474,1.474,0,0,1-.505-.823.754.754,0,0,0-.043.213v.2a1.512,1.512,0,0,0,.549,1.183,5.146,5.146,0,0,0,3.316.935Z" transform="translate(-10.988 -7.802)" fill="#cc7539" />
        <path id="Path_20247" data-name="Path 20247" d="M49.546,26.773a1.557,1.557,0,0,1,1.546,1.546v-.773a1.546,1.546,0,0,0-3.092,0v.773A1.55,1.55,0,0,1,49.546,26.773Z" transform="translate(-11.67 -6.731)" fill="#fcb584" />
        <rect id="Rectangle_18156" data-name="Rectangle 18156" height="3" transform="translate(38 20.917)" fill="#ffc59c" />
        <rect id="Rectangle_18157" data-name="Rectangle 18157" width="2" height="3" rx="0.5" transform="translate(36 20.917)" fill="#ffd7ba" />
        <path id="Path_20248" data-name="Path 20248" d="M46.729,17.114v.2a.773.773,0,0,1-.773.773h-.773v-.773H44.41v2.319a1.546,1.546,0,0,1-3.092,0V17.315h-.773v.773h-.773A.773.773,0,0,1,39,17.315v-.2a1.512,1.512,0,0,1,.549-1.183A5.146,5.146,0,0,1,42.865,15a5.146,5.146,0,0,1,3.316.935,1.512,1.512,0,0,1,.549,1.183Z" transform="translate(-9.626 -4.229)" fill="#fff5db" />
        <path id="Path_20249" data-name="Path 20249" d="M39.045,17.283a1.552,1.552,0,0,0-.045.353v.2a.773.773,0,0,0,.773.773h.773l-.773-.773a.764.764,0,0,1-.728-.554Z" transform="translate(-9.626 -4.751)" fill="#fcb584" />
        <path id="Path_20250" data-name="Path 20250" d="M47.773,17.835,47,18.608h.773a.773.773,0,0,0,.773-.773v-.2a1.6,1.6,0,0,0-.043-.359A.765.765,0,0,1,47.773,17.835Z" transform="translate(-11.443 -4.749)" fill="#fcb584" />
        <path id="Path_20251" data-name="Path 20251" d="M42.865,15a5.146,5.146,0,0,0-3.316.935,1,1,0,0,0-.131.124A1.523,1.523,0,0,0,39,17.114v.2a.74.74,0,0,0,.045.219,1.479,1.479,0,0,1,.373-.706,1,1,0,0,1,.131-.124,5.146,5.146,0,0,1,3.316-.935,5.146,5.146,0,0,1,3.316.935,1.474,1.474,0,0,1,.505.823.754.754,0,0,0,.043-.213v-.2a1.512,1.512,0,0,0-.549-1.183A5.146,5.146,0,0,0,42.865,15Z" transform="translate(-9.626 -4.229)" fill="#cc7539" />
        <path id="Path_20252" data-name="Path 20252" d="M43.546,21.546A1.557,1.557,0,0,1,42,20v.773a1.546,1.546,0,0,0,3.092,0V20A1.55,1.55,0,0,1,43.546,21.546Z" transform="translate(-10.307 -5.368)" fill="#fcb584" />
        <rect id="Rectangle_18158" data-name="Rectangle 18158" height="1" transform="translate(33 13.917)" fill="#ffc59c" />
        <rect id="Rectangle_18159" data-name="Rectangle 18159" width="1" height="1" rx="0.5" transform="translate(33 13.917)" fill="#ffd7ba" />
        <path id="Path_20253" data-name="Path 20253" d="M57.729,28.114v.2a.773.773,0,0,1-.773.773h-.773v-.773H55.41v2.319a1.546,1.546,0,1,1-3.092,0V28.315h-.773v.773h-.773A.773.773,0,0,1,50,28.315v-.2a1.512,1.512,0,0,1,.549-1.183A5.146,5.146,0,0,1,53.865,26a5.146,5.146,0,0,1,3.316.935A1.512,1.512,0,0,1,57.729,28.114Z" transform="translate(-12.124 -6.727)" fill="#ffe9ad" />
        <path id="Path_20254" data-name="Path 20254" d="M50.045,28.283a1.552,1.552,0,0,0-.045.353v.2a.773.773,0,0,0,.773.773h.773l-.773-.773A.764.764,0,0,1,50.045,28.283Z" transform="translate(-12.124 -7.249)" fill="#ffd0b0" />
        <path id="Path_20255" data-name="Path 20255" d="M58.773,28.835,58,29.608h.773a.773.773,0,0,0,.773-.773v-.2a1.6,1.6,0,0,0-.043-.359.765.765,0,0,1-.73.56Z" transform="translate(-13.94 -7.247)" fill="#ffd0b0" />
        <path id="Path_20256" data-name="Path 20256" d="M53.865,26a5.146,5.146,0,0,0-3.316.935,1,1,0,0,0-.131.124A1.523,1.523,0,0,0,50,28.114v.2a.74.74,0,0,0,.045.219,1.479,1.479,0,0,1,.373-.706,1,1,0,0,1,.131-.124,5.146,5.146,0,0,1,3.316-.935,5.146,5.146,0,0,1,3.316.935,1.474,1.474,0,0,1,.505.823.754.754,0,0,0,.043-.213v-.2a1.512,1.512,0,0,0-.549-1.183A5.146,5.146,0,0,0,53.865,26Z" transform="translate(-12.124 -6.727)" fill="#a8602f" />
        <path id="Path_20257" data-name="Path 20257" d="M54.546,32.546A1.557,1.557,0,0,1,53,31v.773a1.546,1.546,0,0,0,3.092,0V31A1.55,1.55,0,0,1,54.546,32.546Z" transform="translate(-12.805 -7.866)" fill="#dd9e73" />
        <rect id="Rectangle_18160" data-name="Rectangle 18160" width="1" height="2" rx="0.5" transform="translate(41 21.917)" fill="#edb791" />
        <rect id="Rectangle_18161" data-name="Rectangle 18161" width="1" height="2" rx="0.5" transform="translate(42 21.917)" fill="#ffc8a1" />
        <path id="Path_20258" data-name="Path 20258" d="M50.729,14.114v.2a.773.773,0,0,1-.773.773h-.773v-.773H48.41v2.319a1.546,1.546,0,1,1-3.092,0V14.315h-.773v.773h-.773A.773.773,0,0,1,43,14.315v-.2a1.512,1.512,0,0,1,.549-1.183A5.146,5.146,0,0,1,46.865,12a5.146,5.146,0,0,1,3.316.935,1.512,1.512,0,0,1,.549,1.183Z" transform="translate(-10.534 -3.548)" fill="#ffe9ad" />
        <path id="Path_20259" data-name="Path 20259" d="M43.045,14.283a1.552,1.552,0,0,0-.045.353v.2a.773.773,0,0,0,.773.773h.773l-.773-.773A.764.764,0,0,1,43.045,14.283Z" transform="translate(-10.534 -4.07)" fill="#ffc8a1" />
        <path id="Path_20260" data-name="Path 20260" d="M51.773,14.835,51,15.608h.773a.773.773,0,0,0,.773-.773v-.2a1.6,1.6,0,0,0-.043-.359A.765.765,0,0,1,51.773,14.835Z" transform="translate(-12.351 -4.068)" fill="#ffc8a1" />
        <path id="Path_20261" data-name="Path 20261" d="M46.865,12a5.146,5.146,0,0,0-3.316.935,1,1,0,0,0-.131.124A1.523,1.523,0,0,0,43,14.114v.2a.74.74,0,0,0,.045.219,1.479,1.479,0,0,1,.373-.706,1,1,0,0,1,.131-.124,5.146,5.146,0,0,1,3.316-.935,5.146,5.146,0,0,1,3.316.935,1.474,1.474,0,0,1,.505.823.754.754,0,0,0,.043-.213v-.2a1.512,1.512,0,0,0-.549-1.183A5.146,5.146,0,0,0,46.865,12Z" transform="translate(-10.534 -3.548)" fill="#a8602f" />
        <path id="Path_20262" data-name="Path 20262" d="M47.546,18.546A1.557,1.557,0,0,1,46,17v.773a1.546,1.546,0,0,0,3.092,0V17A1.55,1.55,0,0,1,47.546,18.546Z" transform="translate(-11.216 -4.687)" fill="#dd9e73" />
        <rect id="Rectangle_18162" data-name="Rectangle 18162" width="1" height="2" rx="0.5" transform="translate(35 11.917)" fill="#edb791" />
        <rect id="Rectangle_18163" data-name="Rectangle 18163" height="2" transform="translate(36 11.917)" fill="#ffc8a1" />
        <path id="Path_20263" data-name="Path 20263" d="M56.75,22.621l-.134.134a.737.737,0,0,1-1.038,0l-.519-.519.519-.519-.519-.519L53.5,22.756a1.467,1.467,0,1,1-2.075-2.075l1.557-1.557-.519-.519-.519.519-.519-.519a.734.734,0,0,1,0-1.037l.134-.135a1.436,1.436,0,0,1,1.159-.425,4.888,4.888,0,0,1,2.854,1.6,4.888,4.888,0,0,1,1.6,2.853,1.436,1.436,0,0,1-.423,1.162Z" transform="translate(-12.351 -4.687)" fill="#ffe9ad" />
        <path id="Path_20264" data-name="Path 20264" d="M51.89,17.292a1.51,1.51,0,0,0-.267.207l-.134.135a.734.734,0,0,0,0,1.037l.519.519V18.153a.727.727,0,0,1-.117-.861Z" transform="translate(-12.413 -4.753)" fill="#ffc8a1" />
        <path id="Path_20265" data-name="Path 20265" d="M57.288,23.775H56.251l.519.519a.737.737,0,0,0,1.038,0l.134-.134a1.518,1.518,0,0,0,.213-.271.727.727,0,0,1-.866-.114Z" transform="translate(-13.543 -6.225)" fill="#ffc8a1" />
        <path id="Path_20266" data-name="Path 20266" d="M55.668,18.605a4.888,4.888,0,0,0-2.854-1.6A.972.972,0,0,0,52.643,17a1.45,1.45,0,0,0-.992.431l-.134.135a.686.686,0,0,0-.117.177,1.405,1.405,0,0,1,.724-.224,1.062,1.062,0,0,1,.172.005,4.888,4.888,0,0,1,2.854,1.6,4.887,4.887,0,0,1,1.6,2.854,1.4,1.4,0,0,1-.213.891.7.7,0,0,0,.172-.114l.134-.134a1.435,1.435,0,0,0,.426-1.159,4.888,4.888,0,0,0-1.6-2.854Z" transform="translate(-12.442 -4.687)" fill="#a8602f" />
        <path id="Path_20267" data-name="Path 20267" d="M51.945,23.166a1.478,1.478,0,0,1,0-2.075l-.519.519A1.467,1.467,0,0,0,53.5,23.684l.519-.519A1.473,1.473,0,0,1,51.945,23.166Z" transform="translate(-12.351 -5.616)" fill="#dd9e73" />
        <path id="Path_20268" data-name="Path 20268" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,1,1,.367,0Z" transform="translate(39.591 16.523) rotate(-45)" fill="#edb791" />
        <path id="Path_20269" data-name="Path 20269" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,1,1,.367,0Z" transform="translate(40.109 17.042) rotate(-45)" fill="#ffc8a1" />
        <path id="Path_20270" data-name="Path 20270" d="M48.75,25.621l-.134.134a.737.737,0,0,1-1.038,0l-.519-.519.519-.519-.519-.519L45.5,25.756a1.467,1.467,0,0,1-2.075-2.075l1.557-1.557-.519-.519-.519.519-.519-.519a.734.734,0,0,1,0-1.037l.134-.135a1.436,1.436,0,0,1,1.159-.425,4.888,4.888,0,0,1,2.854,1.6,4.888,4.888,0,0,1,1.6,2.853A1.436,1.436,0,0,1,48.75,25.621Z" transform="translate(-10.534 -5.369)" fill="#ffe9ad" />
        <path id="Path_20271" data-name="Path 20271" d="M43.89,20.292a1.51,1.51,0,0,0-.267.207l-.134.135a.734.734,0,0,0,0,1.037l.519.519V21.153a.727.727,0,0,1-.117-.861Z" transform="translate(-10.596 -5.434)" fill="#ffc8a1" />
        <path id="Path_20272" data-name="Path 20272" d="M49.288,26.775H48.251l.519.519a.737.737,0,0,0,1.038,0l.134-.134a1.517,1.517,0,0,0,.213-.271.727.727,0,0,1-.866-.114Z" transform="translate(-11.727 -6.907)" fill="#ffc8a1" />
        <path id="Path_20273" data-name="Path 20273" d="M47.668,21.605a4.888,4.888,0,0,0-2.854-1.6A.971.971,0,0,0,44.643,20a1.45,1.45,0,0,0-.992.431l-.134.135a.686.686,0,0,0-.117.177,1.405,1.405,0,0,1,.724-.224,1.061,1.061,0,0,1,.172.005,4.888,4.888,0,0,1,2.854,1.6,4.887,4.887,0,0,1,1.6,2.854,1.4,1.4,0,0,1-.213.891.7.7,0,0,0,.172-.114l.134-.134a1.435,1.435,0,0,0,.426-1.159A4.888,4.888,0,0,0,47.668,21.605Z" transform="translate(-10.625 -5.368)" fill="#a8602f" />
        <path id="Path_20274" data-name="Path 20274" d="M43.945,26.166a1.478,1.478,0,0,1,0-2.075l-.519.519A1.467,1.467,0,1,0,45.5,26.684l.519-.519a1.473,1.473,0,0,1-2.076,0Z" transform="translate(-10.534 -6.297)" fill="#dd9e73" />
        <path id="Path_20275" data-name="Path 20275" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,1,1,.367,0Z" transform="translate(33.407 18.841) rotate(-45)" fill="#edb791" />
        <path id="Path_20276" data-name="Path 20276" d="M.367,0H1.836a.367.367,0,0,1,0,.734H.366A.367.367,0,1,1,.367,0Z" transform="translate(33.925 19.359) rotate(-45)" fill="#ffc8a1" />
        <path id="Path_20277" data-name="Path 20277" d="M0,0H11.594V3.092H0Z" transform="translate(7.56 35.413) rotate(-45)" fill="#648d2f" />
        <path id="Path_20278" data-name="Path 20278" d="M0,0H11.594V1.546H0Z" transform="translate(8.653 36.506) rotate(-45)" fill="#375416" />
        <path id="Path_20279" data-name="Path 20279" d="M0,0H11.594V3.092H0Z" transform="translate(11.181 39.295) rotate(-64.409)" fill="#648d2f" />
        <path id="Path_20280" data-name="Path 20280" d="M0,0H11.594V1.546H0Z" transform="translate(12.576 39.963) rotate(-64.409)" fill="#375416" />
        <path id="Path_20281" data-name="Path 20281" d="M0,0H11.594V3.092H0Z" transform="translate(13.5 40.841) rotate(-64.409)" fill="#648d2f" />
        <path id="Path_20282" data-name="Path 20282" d="M0,0H11.594V1.546H0Z" transform="translate(14.894 41.509) rotate(-64.409)" fill="#375416" />
        <path id="Path_20283" data-name="Path 20283" d="M20.32,37.929v.008l-5.24,8.889L13,45.6,18.6,36.1l.077-.077,1.036.6a7.439,7.439,0,0,0,.611,1.306Z" transform="translate(-3.722 -9.006)" fill="#a6eb68" />
        <path id="Path_20284" data-name="Path 20284" d="M0,0H11.139V2.412H0Z" transform="translate(10.828 39.679) rotate(-59.537)" fill="#d3ea80" />
        <path id="Path_20285" data-name="Path 20285" d="M25.972,42.9l-4.893,8.309L19,49.99l4.869-8.27c.124.1.255.193.386.286.07.046.147.093.216.139.108.07.216.139.325.2.062.039.124.07.193.108A7.5,7.5,0,0,0,25.972,42.9Z" transform="translate(-5.084 -10.3)" fill="#a6eb68" />
        <path id="Path_20286" data-name="Path 20286" d="M23.458,53.255l-.379.634L21,52.668,26.511,43.3l.07.023a4.076,4.076,0,0,0,.464.155c.178.054.371.108.557.147l1.128.665Z" transform="translate(-5.539 -10.659)" fill="#d3ea80" />
        <path id="Path_20287" data-name="Path 20287" d="M20.034,36.623,19,36.02l-.077.077-.557.946a1.546,1.546,0,0,1,.313,1.932l-4.246,7.279.973.57,5.24-8.889v-.008A7.439,7.439,0,0,1,20.034,36.623Z" transform="translate(-4.047 -9.006)" fill="#648d2f" />
        <path id="Path_20288" data-name="Path 20288" d="M23.054,41.221,20.975,40l-.827,1.405a1.884,1.884,0,0,1,.185,2.156l-3.9,6.689.973.571Z" transform="translate(-4.501 -9.91)" fill="#84b22e" />
        <path id="Path_20289" data-name="Path 20289" d="M26.244,42.9a7.5,7.5,0,0,1-.982-.448c-.07-.039-.131-.07-.193-.108-.108-.062-.216-.131-.325-.2-.07-.046-.147-.093-.216-.139-.131-.093-.263-.186-.386-.286l-.6,1.019a1.54,1.54,0,0,1,.329,2.02L20.2,50.533l1.159.679Z" transform="translate(-5.357 -10.3)" fill="#648d2f" />
        <path id="Path_20290" data-name="Path 20290" d="M27.866,43.625c-.186-.039-.379-.093-.557-.147a4.078,4.078,0,0,1-.464-.155l-.07-.023-.48.815a1.541,1.541,0,0,1,.267,1.936l-4.4,7.146,1.178.7.379-.634,5.271-8.97Z" transform="translate(-5.803 -10.659)" fill="#84b22e" />
        <rect id="Rectangle_18164" data-name="Rectangle 18164" width="3.715" height="3.715" rx="0.961" transform="translate(11.285 23.906) rotate(-45)" fill="#ab0f1c" />
        <rect id="Rectangle_18165" data-name="Rectangle 18165" width="4" height="4" rx="1" transform="translate(15 19.917)" fill="#a60f1b" />
        <rect id="Rectangle_18166" data-name="Rectangle 18166" width="3.715" height="3.715" rx="0.961" transform="translate(15.923 16.178) rotate(-45)" fill="#8f0d18" />
        <rect id="Rectangle_18167" data-name="Rectangle 18167" width="3.715" height="3.715" rx="0.961" transform="translate(12.059 13.086) rotate(-45)" fill="#8f0d18" />
        <rect id="Rectangle_18168" data-name="Rectangle 18168" width="3.715" height="3.715" rx="0.961" transform="translate(12.831 17.724) rotate(-45)" fill="#8f0d18" />
        <rect id="Rectangle_18169" data-name="Rectangle 18169" width="3.715" height="3.715" rx="0.961" transform="translate(5.876 16.177) rotate(-45)" fill="#8f0d18" />
        <rect id="Rectangle_18170" data-name="Rectangle 18170" width="3.715" height="3.715" rx="0.961" transform="translate(4.329 26.223) rotate(-45)" fill="#ab0f1c" />
        <rect id="Rectangle_18171" data-name="Rectangle 18171" width="3.715" height="3.715" rx="0.961" transform="translate(9.739 28.543) rotate(-45)" fill="#d41323" />
        <path id="Path_20291" data-name="Path 20291" d="M11.5,38.685,9.813,40.37,7,39.813,9.813,37Z" transform="translate(-2.359 -9.228)" fill="#eb272c" />
        <path id="Path_20292" data-name="Path 20292" d="M19.37,24.865l-.557,2.813L16,24.865l1.685-1.685Z" transform="translate(-4.403 -6.09)" fill="#eb272c" />
        <path id="Path_20293" data-name="Path 20293" d="M23.37,19.865l-.557,2.813L20,19.865l1.685-1.685Z" transform="translate(-5.312 -4.955)" fill="#eb272c" />
        <path id="Path_20294" data-name="Path 20294" d="M15.01,15.813,13.325,17.5,11.64,15.813,12.2,13Z" transform="translate(-3.413 -3.779)" fill="#eb272c" />
        <path id="Path_20295" data-name="Path 20295" d="M19.01,10.813,17.325,12.5,15.64,10.813,16.2,8Z" transform="translate(-4.321 -2.643)" fill="#eb272c" />
        <path id="Path_20296" data-name="Path 20296" d="M16.5,13.325,14.813,15.01,12,12.2l2.813-.557Z" transform="translate(-3.495 -3.47)" fill="#de252a" />
        <path id="Path_20297" data-name="Path 20297" d="M12.5,18.325,10.813,20.01,8,17.2l2.813-.557Z" transform="translate(-2.587 -4.605)" fill="#de252a" />
        <path id="Path_20298" data-name="Path 20298" d="M14.01,40.865,11.2,43.678l-.557-2.813,1.685-1.685Z" transform="translate(-3.186 -9.724)" fill="#de252a" />
        <path id="Path_20299" data-name="Path 20299" d="M22.678,23.813l-2.813.557L18.18,22.685,19.865,21Z" transform="translate(-4.898 -5.595)" fill="#de252a" />
        <path id="Path_20300" data-name="Path 20300" d="M26.678,18.813l-2.813.557L22.18,17.685,23.865,16Z" transform="translate(-5.807 -4.46)" fill="#de252a" />
        <path id="Path_20301" data-name="Path 20301" d="M17.38,36.546l-3.37,3.37-1.685-1.685L10.64,36.546,12.186,35h3.648Z" transform="translate(-3.186 -8.774)" fill="#ff5926" />
        <path id="Path_20302" data-name="Path 20302" d="M22.38,15.01l-1.685,1.685L19.01,18.38l-3.37-3.37,1.685-1.685L19.01,11.64Z" transform="translate(-4.321 -3.47)" fill="#ff552a" />
        <path id="Path_20303" data-name="Path 20303" d="M15.01,23.38l-1.824-1.824L11.64,20.01l1.685-1.685L15.01,16.64l3.37,3.37-1.685,1.685Z" transform="translate(-3.413 -4.605)" fill="#ff8340" />
        <path id="Path_20304" data-name="Path 20304" d="M19.373,24.869l-.563,2.81-2.046-2.046L16,24.869l1.687-1.687Z" transform="translate(-4.403 -6.091)" fill="#eb272c" />
        <path id="Path_20305" data-name="Path 20305" d="M15.009,15.81,13.323,17.5,11.636,15.81,12.2,13Z" transform="translate(-3.412 -3.779)" fill="#ff8340" />
        <path id="Path_20306" data-name="Path 20306" d="M12.5,18.323,10.81,20.009,8,17.2l2.81-.563Z" transform="translate(-2.587 -4.604)" fill="#ff5926" />
        <path id="Path_20307" data-name="Path 20307" d="M22.679,23.81l-2.81.563-1.687-1.687L19.869,21l2.046,2.046Z" transform="translate(-4.899 -5.595)" fill="#ff2a30" />
        <path id="Path_20308" data-name="Path 20308" d="M16.7,21.7l-1.687,1.687L13.12,21.493l-1.484-1.484,1.687-1.687,1.687-1.687,3.373,3.373Z" transform="translate(-3.412 -4.604)" fill="#ff7626" />
        <path id="Path_20309" data-name="Path 20309" d="M15.571,21.7a.8.8,0,0,1-1.124,0l-1.125-1.125a.8.8,0,0,1,0-1.124L15.571,17.2l-.562-.563-1.687,1.687-1.687,1.687,1.484,1.484,1.889,1.889L16.7,21.7l1.687-1.687-.563-.562Z" transform="translate(-3.412 -4.604)" fill="#ff552a" />
        <path id="Path_20310" data-name="Path 20310" d="M0,0H2.385V.8H0Z" transform="translate(9.346 13.156) rotate(-45)" fill="#ff5926" />
        <path id="Path_20311" data-name="Path 20311" d="M11.935,17.871a1.591,1.591,0,0,1-2.248,0l-.75-.75L8,17.309l2.248,2.248.562.562L12.5,18.433Z" transform="translate(-2.587 -4.714)" fill="#eb272c" />
        <path id="Path_20312" data-name="Path 20312" d="M0,0H2.385V.8H0Z" transform="translate(13.28 17.091) rotate(-45)" fill="#d92429" />
        <path id="Path_20313" data-name="Path 20313" d="M18.248,23.744a1.591,1.591,0,0,0,0,2.248l.75.75-.188.937L16.562,25.43,16,24.869l1.687-1.687Z" transform="translate(-4.403 -6.091)" fill="#d92429" />
        <rect id="Rectangle_18172" data-name="Rectangle 18172" width="0.795" height="3.18" rx="0.398" transform="translate(11.594 13.157) rotate(-45)" fill="#ff9100" />
        <rect id="Rectangle_18173" data-name="Rectangle 18173" width="0.795" height="3.18" rx="0.398" transform="translate(10.47 14.28) rotate(-45)" fill="#ff9100" />
        <path id="Path_20314" data-name="Path 20314" d="M15.72,22.289,14.033,20.6a.4.4,0,0,0-.407-.092.785.785,0,0,0,.125.935l1.125,1.125a.785.785,0,0,0,.935.125A.4.4,0,0,0,15.72,22.289Z" transform="translate(-3.841 -5.479)" fill="#ff9100" />
        <path id="Path_20315" data-name="Path 20315" d="M18.869,32.636l2.81.563-2.046,2.046-.764.764-1.687-1.687Z" transform="translate(-4.672 -8.238)" fill="#eb272c" />
        <path id="Path_20316" data-name="Path 20316" d="M9.81,37,11.5,38.687,9.81,40.373,7,39.81Z" transform="translate(-2.359 -9.228)" fill="#ff8340" />
        <path id="Path_20317" data-name="Path 20317" d="M12.323,39.182l1.687,1.687-2.81,2.81-.563-2.81Z" transform="translate(-3.185 -9.724)" fill="#ff5926" />
        <path id="Path_20318" data-name="Path 20318" d="M17.81,29l.563,2.81L16.687,33.5,15,31.81l2.046-2.046Z" transform="translate(-4.176 -7.412)" fill="#ff2a30" />
        <path id="Path_20319" data-name="Path 20319" d="M15.7,34.323l1.687,1.687L15.493,37.9l-1.484,1.484L12.323,37.7l-1.687-1.687,3.373-3.373Z" transform="translate(-3.185 -8.238)" fill="#ff7626" />
        <path id="Path_20320" data-name="Path 20320" d="M15.7,35.447a.8.8,0,0,1,0,1.124L14.571,37.7a.8.8,0,0,1-1.124,0L11.2,35.447l-.563.562L12.323,37.7l1.687,1.687L15.493,37.9l1.889-1.889L15.7,34.323l-1.687-1.687-.562.563Z" transform="translate(-3.185 -8.238)" fill="#ff552a" />
        <path id="Path_20321" data-name="Path 20321" d="M0,0H.8V2.385H0Z" transform="translate(6.885 28.332) rotate(-45)" fill="#ff5926" />
        <path id="Path_20322" data-name="Path 20322" d="M11.871,39.744a1.591,1.591,0,0,1,0,2.248l-.75.75.188.937,2.248-2.248.562-.562-1.687-1.687Z" transform="translate(-3.295 -9.724)" fill="#eb272c" />
        <path id="Path_20323" data-name="Path 20323" d="M0,0H.8V2.385H0Z" transform="translate(10.82 24.398) rotate(-45)" fill="#d92429" />
        <path id="Path_20324" data-name="Path 20324" d="M17.744,33.871a1.591,1.591,0,0,0,2.248,0l.75-.75.937.188L19.43,35.557l-.562.562-1.687-1.687Z" transform="translate(-4.672 -8.348)" fill="#d92429" />
        <rect id="Rectangle_18174" data-name="Rectangle 18174" width="3.18" height="0.795" rx="0.398" transform="translate(8.01 27.207) rotate(-45)" fill="#ff9100" />
        <rect id="Rectangle_18175" data-name="Rectangle 18175" width="3.18" height="0.795" rx="0.398" transform="translate(9.133 28.332) rotate(-45)" fill="#ff9100" />
        <path id="Path_20325" data-name="Path 20325" d="M16.289,36.6,14.6,38.289a.4.4,0,0,0-.092.407.785.785,0,0,0,.935-.125l1.125-1.125a.785.785,0,0,0,.125-.935A.4.4,0,0,0,16.289,36.6Z" transform="translate(-4.06 -9.112)" fill="#ff9100" />
        <path id="Path_20326" data-name="Path 20326" d="M23.373,19.869l-.563,2.81-2.046-2.046L20,19.869l1.687-1.687Z" transform="translate(-5.312 -4.955)" fill="#eb272c" />
        <path id="Path_20327" data-name="Path 20327" d="M19.009,10.81,17.323,12.5,15.636,10.81,16.2,8Z" transform="translate(-4.321 -2.643)" fill="#ff8340" />
        <path id="Path_20328" data-name="Path 20328" d="M16.5,13.323,14.81,15.009,12,12.2l2.81-.563Z" transform="translate(-3.495 -3.469)" fill="#ff5926" />
        <path id="Path_20329" data-name="Path 20329" d="M0,0H2.385V.8H0Z" transform="translate(12.437 9.292) rotate(-45)" fill="#ff5926" />
        <path id="Path_20330" data-name="Path 20330" d="M15.935,12.871a1.591,1.591,0,0,1-2.248,0l-.75-.75L12,12.309l2.248,2.248.562.562L16.5,13.433Z" transform="translate(-3.495 -3.579)" fill="#eb272c" />
        <path id="Path_20331" data-name="Path 20331" d="M22.248,18.744a1.591,1.591,0,0,0,0,2.248l.75.75-.188.937L20.562,20.43,20,19.869l1.687-1.687Z" transform="translate(-5.312 -4.955)" fill="#d92429" />
        <rect id="Rectangle_18176" data-name="Rectangle 18176" width="0.795" height="3.18" rx="0.398" transform="translate(14.686 9.293) rotate(-45)" fill="#ff9100" />
        <rect id="Rectangle_18177" data-name="Rectangle 18177" width="0.795" height="3.18" rx="0.398" transform="translate(13.561 10.416) rotate(-45)" fill="#ff9100" />
        <path id="Path_20332" data-name="Path 20332" d="M19.72,17.289,18.033,15.6a.4.4,0,0,0-.407-.092.785.785,0,0,0,.125.935l1.125,1.125a.785.785,0,0,0,.935.125A.4.4,0,0,0,19.72,17.289Z" transform="translate(-4.75 -4.344)" fill="#ff9100" />
        <path id="Path_20333" data-name="Path 20333" d="M8.865,23v2.319H6.546L5,23Z" transform="translate(-1.905 -6.049)" fill="#ff8340" />
        <path id="Path_20334" data-name="Path 20334" d="M8.865,26v2.319H5L6.546,26Z" transform="translate(-1.905 -6.731)" fill="#ff5926" />
        <path id="Path_20335" data-name="Path 20335" d="M18.813,28.173H16V25.36Z" transform="translate(-4.403 -6.585)" fill="#ab0f1c" />
        <path id="Path_20336" data-name="Path 20336" d="M14.638,24.824v2.813H10V23h2.813Z" transform="translate(-3.041 -6.049)" fill="#ff5926" />
        <path id="Path_20337" data-name="Path 20337" d="M13.865,24.051v2.041a.773.773,0,0,1-.773.773H11.546a.773.773,0,0,1-.773-.773V23H10v4.638h4.638V24.824Z" transform="translate(-3.041 -6.049)" fill="#d41323" />
        <path id="Path_20338" data-name="Path 20338" d="M9,23h.773v2.319H9Z" transform="translate(-2.814 -6.049)" fill="#eb272c" />
        <path id="Path_20339" data-name="Path 20339" d="M8.092,26a1.546,1.546,0,0,1-1.546,1.546H5.516L5,28.319H8.865V26Z" transform="translate(-1.905 -6.731)" fill="#f71529" />
        <path id="Path_20340" data-name="Path 20340" d="M11.386,23a.386.386,0,0,0,0,.773h2.319a.4.4,0,0,0,.09-.018L13.041,23Z" transform="translate(-3.268 -6.049)" fill="#f70" />
        <rect id="Rectangle_18178" data-name="Rectangle 18178" width="4" transform="translate(8 18.917)" fill="#f70" />
        <path id="Path_20341" data-name="Path 20341" d="M11.786,27.773h1.546a.764.764,0,0,0,.73-.557A.386.386,0,0,0,13.718,27H11.4a.386.386,0,0,0-.343.216.764.764,0,0,0,.73.557Z" transform="translate(-3.281 -6.958)" fill="#f70" />
        <path id="Path_20342" data-name="Path 20342" d="M10,23h.773v2.319H10Z" transform="translate(-3.041 -6.049)" fill="#ff2a30" />
        <path id="Path_20343" data-name="Path 20343" d="M18.319,32l1.546,2.319H16V32Z" transform="translate(-4.403 -8.093)" fill="#eb272c" />
        <path id="Path_20344" data-name="Path 20344" d="M8.865,29v2.319H6.546L5,29Z" transform="translate(-1.905 -7.412)" fill="#ff8340" />
        <path id="Path_20345" data-name="Path 20345" d="M8.865,32v2.319H5L6.546,32Z" transform="translate(-1.905 -8.093)" fill="#ff5926" />
        <path id="Path_20346" data-name="Path 20346" d="M19.865,29l-1.546,2.319H16V29h3.865Z" transform="translate(-4.403 -7.412)" fill="#ff2a30" />
        <path id="Path_20347" data-name="Path 20347" d="M14.638,31.319v2.319H10V29h4.638Z" transform="translate(-3.041 -7.412)" fill="#ff5926" />
        <path id="Path_20348" data-name="Path 20348" d="M13.865,32.092a.773.773,0,0,1-.773.773H11.546a.773.773,0,0,1-.773-.773V29H10v4.638h4.638V29h-.773Z" transform="translate(-3.041 -7.412)" fill="#d41323" />
        <path id="Path_20349" data-name="Path 20349" d="M9,29h.773v2.319H9Z" transform="translate(-2.814 -7.412)" fill="#eb272c" />
        <path id="Path_20350" data-name="Path 20350" d="M8.092,32a1.546,1.546,0,0,1-1.546,1.546H5.516L5,34.319H8.865V32Z" transform="translate(-1.905 -8.093)" fill="#f71529" />
        <path id="Path_20351" data-name="Path 20351" d="M16,29h.773v2.319H16Z" transform="translate(-4.403 -7.412)" fill="#d92429" />
        <path id="Path_20352" data-name="Path 20352" d="M16.773,32a1.546,1.546,0,0,0,1.546,1.546h1.03l.516.773H16V32Z" transform="translate(-4.403 -8.093)" fill="#ab0f1c" />
        <rect id="Rectangle_18179" data-name="Rectangle 18179" width="4" height="1" rx="0.5" transform="translate(8 21.917)" fill="#f70" />
        <rect id="Rectangle_18180" data-name="Rectangle 18180" width="4" transform="translate(8 23.917)" fill="#f70" />
        <path id="Path_20353" data-name="Path 20353" d="M13.718,33H11.4a.386.386,0,0,0-.343.216.764.764,0,0,0,.73.557h1.546a.764.764,0,0,0,.73-.556A.386.386,0,0,0,13.718,33Z" transform="translate(-3.281 -8.32)" fill="#f70" />
        <g id="Group_14424" data-name="Group 14424" transform="translate(4.757 7.978)">
          <ellipse id="Ellipse_1814" data-name="Ellipse 1814" cx="1.5" cy="0.5" rx="1.5" ry="0.5" transform="translate(5.243 11.939)" fill="#689441" />
          <ellipse id="Ellipse_1815" data-name="Ellipse 1815" cx="0.386" cy="0.773" rx="0.386" ry="0.773" transform="translate(0 11.869) rotate(-21.791)" fill="#689441" />
          <ellipse id="Ellipse_1816" data-name="Ellipse 1816" cx="0.386" cy="0.773" rx="0.386" ry="0.773" transform="translate(6.956 2.601) rotate(-21.791)" fill="#689441" />
          <ellipse id="Ellipse_1817" data-name="Ellipse 1817" cx="0.773" cy="0.386" rx="0.773" ry="0.386" transform="translate(8.503 1.435) rotate(-68.209)" fill="#689441" />
          <ellipse id="Ellipse_1818" data-name="Ellipse 1818" cx="0.773" cy="0.386" rx="0.773" ry="0.386" transform="translate(8.5 6.843) rotate(-68.209)" fill="#689441" />
          <ellipse id="Ellipse_1819" data-name="Ellipse 1819" cx="0.773" cy="0.386" rx="0.773" ry="0.386" transform="translate(10.817 9.161) rotate(-68.209)" fill="#689441" />
          <ellipse id="Ellipse_1820" data-name="Ellipse 1820" cx="1.159" cy="0.386" rx="1.159" ry="0.386" transform="translate(0.72 16.861) rotate(-45)" fill="#689441" />
          <ellipse id="Ellipse_1821" data-name="Ellipse 1821" cx="1.159" cy="0.386" rx="1.159" ry="0.386" transform="translate(6.13 16.861) rotate(-45)" fill="#689441" />
          <ellipse id="Ellipse_1822" data-name="Ellipse 1822" cx="0.386" cy="1.159" rx="0.386" ry="1.159" transform="translate(3.81 21.177) rotate(-45)" fill="#689441" />
          <ellipse id="Ellipse_1823" data-name="Ellipse 1823" cx="0.5" cy="1.5" rx="0.5" ry="1.5" transform="translate(4.243 7.939)" fill="#689441" />
          <ellipse id="Ellipse_1824" data-name="Ellipse 1824" cx="1.159" cy="0.386" rx="1.159" ry="0.386" transform="translate(3.039 8.359) rotate(-45)" fill="#689441" />
        </g>
        <path id="Path_20354" data-name="Path 20354" d="M21.162,31.71A8.51,8.51,0,0,1,29.5,21.546a8.707,8.707,0,0,1,1.863.209,3.1,3.1,0,0,1,.773-1.013,3.043,3.043,0,0,1,2-.742,3.075,3.075,0,0,1,2.3,5.14,8.479,8.479,0,0,1,.966,8.054,8.61,8.61,0,0,1-3.053,3.841A8.425,8.425,0,0,1,29.5,38.55a8.534,8.534,0,0,1-1.9-.216c-.185-.046-.371-.093-.557-.147-.155-.046-.309-.093-.464-.155l-.07-.023a.286.286,0,0,1-.085-.039,8.675,8.675,0,0,1-.982-.448c-.07-.039-.131-.07-.193-.108-.108-.062-.216-.131-.325-.2-.07-.046-.147-.093-.216-.139-.131-.093-.263-.186-.386-.286A8.443,8.443,0,0,1,22.136,34.3v-.008a7.439,7.439,0,0,1-.611-1.306,7.791,7.791,0,0,1-.363-1.275Zm12.2-2.435a4.638,4.638,0,1,0-4.638,4.638A4.638,4.638,0,0,0,33.367,29.275Z" transform="translate(-5.539 -5.368)" fill="#fff0d1" />
        <ellipse id="Ellipse_1825" data-name="Ellipse 1825" cx="4.251" cy="4.251" rx="4.251" ry="4.251" transform="translate(18.963 19.656)" fill="none" />
        <path id="Path_20355" data-name="Path 20355" d="M29.668,26a4.638,4.638,0,1,0,4.638,4.638A4.638,4.638,0,0,0,29.668,26Zm0,8.889a4.251,4.251,0,1,1,4.251-4.251,4.251,4.251,0,0,1-4.251,4.251Z" transform="translate(-6.454 -6.731)" fill="#fdf9dc" />
        <path id="Path_20356" data-name="Path 20356" d="M36.465,25.14a3.081,3.081,0,1,0-5.07-3.385,8.707,8.707,0,0,0-1.863-.209,8.51,8.51,0,0,0-8.34,10.164,7.79,7.79,0,0,0,.363,1.275,7.439,7.439,0,0,0,.611,1.306V34.3a8.443,8.443,0,0,0,2.187,2.489c.124.1.255.193.386.286.07.046.147.093.216.139.108.07.216.139.325.2.062.039.124.07.193.108a8.676,8.676,0,0,0,.982.448.286.286,0,0,0,.085.039l.07.023c.155.062.309.108.464.155.186.054.371.1.557.147a8.535,8.535,0,0,0,1.9.216,8.425,8.425,0,0,0,4.846-1.515,8.61,8.61,0,0,0,3.053-3.841,8.479,8.479,0,0,0-.966-8.054Zm.607,7.913a8.223,8.223,0,0,1-2.915,3.666,8.108,8.108,0,0,1-6.433,1.237c-.18-.044-.361-.09-.556-.146-.138-.043-.276-.083-.414-.138l-.159-.064a8.167,8.167,0,0,1-.934-.426l-.067-.036c-.038-.021-.073-.039-.121-.068-.1-.059-.2-.124-.3-.189l-.206-.132c-.124-.089-.249-.176-.376-.278a8.107,8.107,0,0,1-2.035-2.284l-.054-.1a6.986,6.986,0,0,1-.581-1.246,7.412,7.412,0,0,1-.345-1.211,8.006,8.006,0,0,1-.155-1.589,8.125,8.125,0,0,1,8.116-8.116,8.25,8.25,0,0,1,1.778.2l.3.065.132-.275a2.743,2.743,0,0,1,.678-.888,2.664,2.664,0,0,1,1.751-.647,2.705,2.705,0,0,1,2.705,2.705,2.678,2.678,0,0,1-.7,1.79l-.207.232.179.253a8.038,8.038,0,0,1,1.5,4.682,8.127,8.127,0,0,1-.576,3.006Z" transform="translate(-5.545 -5.368)" fill="#dd9d56" />
        <ellipse id="Ellipse_1826" data-name="Ellipse 1826" cx="4.5" cy="5" rx="4.5" ry="5" transform="translate(19 18.917)" fill="#fcd83c" />
        <path id="Path_20357" data-name="Path 20357" d="M29.638,26a4.638,4.638,0,1,0,4.638,4.638A4.638,4.638,0,0,0,29.638,26Zm0,6.956a4.174,4.174,0,0,1-3.865-3.865c0-1.706,1.733-3.092,3.865-3.092S33.5,27.386,33.5,29.092A4.174,4.174,0,0,1,29.638,32.956Z" transform="translate(-6.447 -6.731)" fill="#f6a635" />
        <ellipse id="Ellipse_1827" data-name="Ellipse 1827" cx="1.125" cy="1.875" rx="1.125" ry="1.875" transform="translate(22.61 21.83) rotate(-45)" fill="#ffef96" />
        <path id="Path_20358" data-name="Path 20358" d="M27.045,28.566a.532.532,0,0,0,.113.6,1.213,1.213,0,0,0,.43.243,1.8,1.8,0,0,0,.773.136.232.232,0,0,0,.107-.029.135.135,0,0,0,.049-.12l.029-.979a.41.41,0,0,0-.018-.165.424.424,0,0,0-.3-.213,1,1,0,0,0-1.186.529Z" transform="translate(-6.901 -7.184)" fill="#ffef96" />
        <path id="Path_20359" data-name="Path 20359" d="M33.221,27.184a5.158,5.158,0,0,1-1.6-1.436,4.483,4.483,0,0,0-.981-1.159c-.456-.309-.762-.087-1.247-.032-.978.11-2.139-.379-3.014.279-.56.422-.773,1.206-1.271,1.707-.574.574-1.437.7-2.073,1.188a2.456,2.456,0,0,0-.866,2.279,13.79,13.79,0,0,0,.768,2.023,9.318,9.318,0,0,0,.791,2.6,3.371,3.371,0,0,0,1.623,1.074,7.439,7.439,0,0,0,1.843.483c.821.086,1.653-.039,2.473.069.763.1,1.493.4,2.255.5a2.3,2.3,0,0,0,2.126-.648,5.822,5.822,0,0,0,.72-2.294,21.842,21.842,0,0,1,.973-2.334,3.12,3.12,0,0,0,.09-2.469c-.492-1-1.673-1.3-2.609-1.834Zm-4.233,7.734a4.638,4.638,0,1,1,4.638-4.638A4.638,4.638,0,0,1,28.987,34.918Z" transform="translate(-5.797 -6.374)" fill="#fffae8" />
        <ellipse id="Ellipse_1828" data-name="Ellipse 1828" cx="1.269" cy="1.767" rx="1.269" ry="1.767" transform="translate(26.111 17.921) rotate(-45)" fill="#f6fafd" />
        <ellipse id="Ellipse_1829" data-name="Ellipse 1829" cx="0.638" cy="0.887" rx="0.638" ry="0.887" transform="translate(20.218 23.515) rotate(-45)" fill="#f6fafd" />
        <ellipse id="Ellipse_1830" data-name="Ellipse 1830" cx="0.319" cy="0.444" rx="0.319" ry="0.444" transform="translate(22.106 24.765) rotate(-45)" fill="#f6fafd" />
        <path id="Path_20360" data-name="Path 20360" d="M42.176,33.59a3.444,3.444,0,0,0-1.1-.856,6.789,6.789,0,0,0-2.441-.18c-1.242-.049-2.435-.583-3.678-.553a1.222,1.222,0,0,0-.55.112.87.87,0,0,0-.392.928,1.643,1.643,0,0,0,.582.891c.873.752,2.1.958,3.092,1.557a15.634,15.634,0,0,1,1.949,1.636,3.749,3.749,0,0,0,2.275,1.057A1.073,1.073,0,0,0,42.6,38a1.183,1.183,0,0,0,.339-.559,4.212,4.212,0,0,0,.264-2.25,4.068,4.068,0,0,0-1.028-1.6Z" transform="translate(-8.49 -8.093)" fill="#eb272c" />
        <path id="Path_20361" data-name="Path 20361" d="M42.942,36.808a1.177,1.177,0,0,1-.339.559,1.073,1.073,0,0,1-.689.186A3.749,3.749,0,0,1,39.637,36.5a15.634,15.634,0,0,0-1.949-1.636c-.989-.6-2.217-.805-3.092-1.557a1.819,1.819,0,0,1-.515-.681.958.958,0,0,0-.067.563,1.643,1.643,0,0,0,.582.891c.873.752,2.1.958,3.092,1.557a15.635,15.635,0,0,1,1.949,1.636,3.749,3.749,0,0,0,2.277,1.054,1.073,1.073,0,0,0,.689-.186,1.177,1.177,0,0,0,.339-.559,4.682,4.682,0,0,0,.3-2,6.147,6.147,0,0,1-.3,1.227Z" transform="translate(-8.49 -8.235)" fill="#ab0f1c" />
        <path id="Path_20362" data-name="Path 20362" d="M43.092,33.92a1.482,1.482,0,0,0-1.288-.143.38.38,0,0,0-.3.309.53.53,0,0,0,.128.338,3.973,3.973,0,0,0,.85.91c.232.171,1.3.718,1.336.056a1.907,1.907,0,0,0-.723-1.469Z" transform="translate(-10.194 -8.477)" fill="#ff5c60" />
        <path id="Path_20363" data-name="Path 20363" d="M36.63,33.1a1.9,1.9,0,0,0-.939-.056c-.093.024-.195.077-.191.155s.077.11.141.145a3.218,3.218,0,0,0,1.567.436c.171,0,.565-.027.608-.211.031-.134-.2-.2-.321-.232-.281-.093-.577-.161-.865-.236Z" transform="translate(-8.831 -8.321)" fill="#ff5c60" />
        <ellipse id="Ellipse_1831" data-name="Ellipse 1831" cx="0.319" cy="0.444" rx="0.319" ry="0.444" transform="translate(32.927 27.86) rotate(-45)" fill="#f6fafd" />
        <path id="Path_20364" data-name="Path 20364" d="M38.648,36.491c-.057-.035-.118-.064-.177-.1-.015.048-.035.094-.048.143-.083.315-.134.646-.2.972.546.4,1.025.889,1.537,1.335a7.741,7.741,0,0,0,.637-.9,12.236,12.236,0,0,0-1.75-1.458Z" transform="translate(-9.45 -9.091)" fill="#d9c18d" />
        <path id="Path_20365" data-name="Path 20365" d="M33.777,33.022a.991.991,0,0,0-.369.1.87.87,0,0,0-.392.928c.006.031.02.059.029.089a4.222,4.222,0,0,0,.838-.585,1.229,1.229,0,0,1-.094-.277A.964.964,0,0,1,33.777,33.022Z" transform="translate(-8.262 -8.325)" fill="#fca000" />
        <path id="Path_20366" data-name="Path 20366" d="M41.048,38.584l-.011-.009c-.064-.055-.125-.113-.189-.169a7.74,7.74,0,0,1-.637.9c.022.019.042.039.063.056.063.054.128.107.193.159l.039.032a8.072,8.072,0,0,0,.635-.887c-.031-.029-.063-.051-.094-.078Z" transform="translate(-9.901 -9.548)" fill="#c28036" />
        <path id="Path_20367" data-name="Path 20367" d="M35.348,34.922a4.242,4.242,0,0,1-.962-.6,1.9,1.9,0,0,1-.488-.614,4.222,4.222,0,0,1-.838.585,1.71,1.71,0,0,0,.553.8,4.376,4.376,0,0,0,1.053.642,4.666,4.666,0,0,0,.682-.817Z" transform="translate(-8.277 -8.481)" fill="#e88800" />
        <path id="Path_20368" data-name="Path 20368" d="M35.138,36.095a19.354,19.354,0,0,1,2.036.915c.122.077.232.161.349.245.064-.325.116-.657.2-.972.013-.049.033-.1.048-.143-.618-.341-1.314-.561-1.95-.863a4.666,4.666,0,0,1-.682.817Z" transform="translate(-8.749 -8.837)" fill="#eddaaf" />
      </g>
    </svg>
  );
}

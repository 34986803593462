import React from "react";
import { SpinGameContainerStyled } from "./styled";
import {
  TableCustom,
  Loading,
  // Modal,
  // Blocks,
  BGLoading,
  Buttons,
  Icons,
  Inputs,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { adminService } from "apiServices";
import moment from "moment";
import { getObjectKey } from "utils/functions/getObjectKey";
// import { toast } from "react-toastify";

class SpinGameContainer extends React.Component {
  state = {
    isLoading: true,
    sorByAsc: false,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData(`?per_page=10&offset=0`);
    this.checkPermission()
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'spin_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_SPIN_LIST_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );
  
    if (res && res.success) {
      let current = res.data.filter((e) => e.status === 'CURRENT');
      let notCurrent = res.data.filter((e) => e.status !== 'CURRENT');

      if (current.length > 0) {
        current[current.length - 1].showLine = true
      }

      this.setState({
        data: [
          ...current,
          ...notCurrent
        ],
        pagination: { ...res.page },
        isLoadingTable: false,
        isBgLoading: false,
        isLoading: false,
      });
    } else {
      this.setState({
        data: [],
        isLoadingTable: false,
        isBgLoading: false,
        isLoading: false,
      });
    }
  };

  handleClickDetail = (e, action) => {
    if (e === 'create') {
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_GAME}/${e}/create`);
    } else {
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_GAME}/${e}/${action}`);
    }
  };

  handleClickSort = (e) => {
    const { sorByAsc } = this.state

    let sort = sorByAsc ? 'desc' : 'asc'
    let sortFrom = e === 'begin_date' ? 'begin_date' : e === 'created_at' ? 'created_at' : 'status'

    this.setState(
      {
        sorByAsc: !sorByAsc,
        sortBy: `sort_by=${sortFrom}&order_by=${sort}`,
      },
      () => this.handleJoinQuery()
    );
  };

  renderStatus = (e) => {
    switch (e) {
      case 'DRAFT':
        return 'บันทึกร่าง';

      case 'CURRENT':
        return 'กำลังเผยแพร่';

      case 'UPCOMING':
        return 'รอเผยแพร่';

      case 'EXPIRED':
        return 'หมดอายุ';

      default:
        return '-';
    }
  }

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    this.setState({
      isLoadingTable: true,
    })
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.fetchData(query);
  };

  handleSelectedFilter = (e) => {
    this.setState(
      {
        keywordFilter: !e ? false : `keyword=${e}`,
      },
      () => this.handleJoinQuery()
    );
  }

  handleSelectedFilterStatus = (e) => {
    const hasAll = e.some((f) => f === "all");

    this.setState(
      {
        statusFilter: hasAll
          ? false
          : `status=${e.filter((e) => e !== "all").join()}`,
      },
      () => this.handleJoinQuery()
    );
  }

  handleJoinQuery = () => {
    const { keywordFilter, sortBy, statusFilter } = this.state;
    let queryString = [];

    this.setState({
      isLoadingTable: true,
    })
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (statusFilter) {
      queryString.push(statusFilter);
    }
    if (sortBy) {
      queryString.push(sortBy);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?per_page=10&offset=0&${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
    } else {
      this.fetchData(`?per_page=10&offset=0`);
      this.setState({
        queryFilterAll: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      pagination,
      isBgLoading,
      isLoadingTable,
      data,
      perMissions,
    } = this.state;

    return (
      <SpinGameContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="box_spin_layout">
            <div className="top_filter">
              <div className="w378">
                <Inputs.InputSearch2
                  theme_standard_search
                  placeholder='ค้นหาวงล้อ'
                  onSubmit={this.handleSelectedFilter}
                />
              </div>
              {
                perMissions &&
                <div className="w163">
                  <Buttons.BtnNormal
                    theme_only_border_blue
                    label="สร้างวงล้อ"
                    svg_front={<Icons.AddRound color="#002366" />}
                    onClick={() => this.handleClickDetail('create')}
                  />
                </div>
              }
            </div>
            <div className="table_box">
              <TableCustom.TableNormal
                theme_lucky
                columns={columns({
                  handleClickSort: this.handleClickSort,
                  handleClickDetail: this.handleClickDetail,
                  renderStatus: this.renderStatus,
                  handleSelectedFilterStatus: this.handleSelectedFilterStatus,
                  perMissions: perMissions,
                })}
                data={data}
                loading={isLoadingTable}
                pagination={pagination}
                labelEmpty="ยังไม่มีข้อมูล"
                // onChangeSelected={this.handleChangeSelectedTable}
                showPagination
                handlePageClick={this.handlePageClick}
              />
            </div>
          </div>
        )}
      </SpinGameContainerStyled>
    );
  }
}

const columns = ({
  handleClickSort,
  handleClickDetail,
  handleSelectedFilterStatus,
  renderStatus,
  perMissions,
}) => [
    {
      title: "ชื่อวงล้อ",
      field: "name_th",
      headerStyle: { textAlign: "left", minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer", columnGap: '8px', minWidth: "10px" }}
          onClick={() => handleClickSort("begin_date")}
        >
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            ระยะเวลาแสดงกิจกรรม
          </div>
          <div style={{ display: 'flex' }}>
            <Icons.Sort />
          </div>
        </div>
      ),
      field: "begin_date",
      headerStyle: {
        display: "flex",
        justifyContent: "left",
        minWidth: "10px"
      },
      style: {
        display: "flex",
        justifyContent: "left",
        minWidth: "10px"
      },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {
            `${moment(origin.begin_date).tz("Asia/Bangkok").format("DD MMMM")} ${moment(origin.begin_date).tz("Asia/Bangkok").add(543, "years").format("YYYY")}
             - 
             ${moment(origin.end_date).tz("Asia/Bangkok").format("DD MMMM")} ${moment(origin.end_date).tz("Asia/Bangkok").add(543, "years").format("YYYY")}
            `
          }
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer", columnGap: '8px', minWidth: "10px" }}
          onClick={() => handleClickSort("created_at")}
        >
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            วันที่สร้าง
          </div>
          <div style={{ display: 'flex' }}>
            <Icons.Sort />
          </div>
        </div>
      ),
      field: "created_at",
      headerStyle: {
        display: "flex",
        justifyContent: "left",
      },
      style: {
        display: "flex",
        justifyContent: "left",
      },
      render: ({ origin, value }) => (
        <div >
          {
            `${moment(origin.created_at).tz("Asia/Bangkok").format("DD MMM")} ${moment(origin.created_at).tz("Asia/Bangkok").add(543, "years").format("YYYY")}`
          }
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer", columnGap: '8px', minWidth: "10px" }}
        // onClick={() => handleClickSort("status")}
        >
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            สถานะวงล้อ
          </div>
          <Inputs.InputGroupCheckBox
            theme_lukcy
            labelButton="SUBMIT"
            iconColor='#3E3E3E  '
            // iconDisplay={<Icons.Sort />}
            luckyButton
            data={[
              {
                label: "All",
                name: "all",
                value: "all",
              },
              {
                label: "บันทึกร่าง",
                name: "DRAFT",
                value: "DRAFT",
              },
              {
                label: "รอเผยแพร่",
                name: "UPCOMING",
                value: "UPCOMING",
              },
              {
                label: "กำลังเผยแพร่",
                name: "CURRENT",
                value: "CURRENT",
              },
              {
                label: "หมดอายุ",
                name: "EXPIRED",
                value: "EXPIRED",
              },
            ]}
            onChangeCustom={(e) => handleSelectedFilterStatus(e)}
          />
        </div>
      ),
      field: "status",
      headerStyle: { textAlign: "left" },
      style: { textAlign: "left" },
      render: ({ origin, value }) => (
        <div >
          {renderStatus(value)}
        </div>
      ),
    },
    {
      title: '',
      field: 'action',
      headerStyle: { textAlign: 'center', width: perMissions ? '150px' : '75px', flexShrink: 0 },
      style: { textAlign: 'center', width: perMissions ? '150px' : '75px', flexShrink: 0 },
      render: ({ origin }) => (
        <div className="group_icon">
          <div className="box_icon" onClick={() => handleClickDetail(origin.id, 'edit')}>
            <Icons.IconsEdit />
          </div>
          {
            perMissions &&
            <div
              className="box_icon"
              onClick={() => handleClickDetail(origin.id, 'copy')}
            >
              <Icons.IconCopy />
            </div>
          }
        </div>
      ),
    },
  ];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpinGameContainer);
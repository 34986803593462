import styled from "styled-components";

export const DropdownLoadmoreStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  .group_box_body {
    position: relative;
    .body_dropdown {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      padding: 10.8px 14px;
      cursor: pointer;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      color:  ${({ theme, disabled }) => disabled ? theme.COLORS.GRAY_26 : theme.COLORS.PURPLE_1};
      background: ${({ disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_28 : ({ theme }) => theme.COLORS.WHITE_1};
      position: relative;
      .d_arrow {
        position: absolute;
        right: 17px;
        top: 10px;
      }
      .label_show {
        width: 87%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  
    .list_dropdown {
      position: absolute;
      top: ${({ showOnTop }) => showOnTop ? '-225px' : '49px'};
      left: 0;
      right: 0;
      min-height: 80px;
      border-radius: 8px;
      padding: 0;
      min-width: 272px;
      z-index: 1;
      background: white;
      overflow-y: auto;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      color:  ${({ theme, disabled }) => disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
      background: ${({ disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_28 : ({ theme }) => theme.COLORS.WHITE_1};
      .dropdown_item {
        white-space: nowrap;
        overflow: hidden;
        border-radius: 8px;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 8px 16px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        &:hover {
          background: ${({ theme }) => theme.COLORS.BLUE_32};
        }
      }
      .infinite-scroll-component {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
      }
    }
  
    .loading {
      margin-top: 10px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
  }
  .error {
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_3};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    padding-top: 2px;
  }

  .theme_standard {
  }
`;

export default function Female2({ color = '#ed3d26' }) {
  return (
    <svg id="Group_15161" data-name="Group 15161" xmlns="http://www.w3.org/2000/svg" width="22.331" height="48" viewBox="0 0 22.331 48">
      <g id="Group_73" data-name="Group 73">
        <g id="Group_72" data-name="Group 72">
          <g id="Group_70" data-name="Group 70" transform="translate(6.169)">
            <path id="Path_38" data-name="Path 38" d="M366.782,36.994a5,5,0,1,1-5-5A5,5,0,0,1,366.782,36.994Z" transform="translate(-356.789 -31.997)" fill="#ed3d26" />
          </g>
          <g id="Group_71" data-name="Group 71" transform="translate(0 11.386)">
            <path id="Path_39" data-name="Path 39" d="M362.927,77.66l-3.874-14.721c0-.006,0-.011-.006-.016a1.4,1.4,0,0,0-1.372-1.134H345.95a1.4,1.4,0,0,0-1.372,1.134c0,.005,0,.01-.006.016L340.7,77.66a1.543,1.543,0,1,0,2.984.785l3.134-11.909V73.2l-3.047,11.577h3.047V96.438a1.966,1.966,0,0,0,3.932,0V84.792h2.13V96.438a1.966,1.966,0,0,0,3.932,0V84.792h3.046l-3.046-11.577v-6.68l3.134,11.909a1.543,1.543,0,1,0,2.984-.785Z" transform="translate(-340.647 -61.789)" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  );
}

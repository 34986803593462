import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputGroupCheckBoxLoadmoreStyled } from './styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Blocks, Buttons, Icons } from 'components';

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

const InputGroupCheckBoxLoadmore = ({
  theme_standard_group_check_box,
  theme_standard_group_check_box_user,
  data,
  onChangeCustom,
  labelButton,
  fontSize,
  fontFamily,
  fontColor,
  boxSize,
  topBox,
  rightBox,
  iconColor,
  maxHeight,
  endPoint,
  epParameter,
  value,
  onChange,
  isInitial,
  placeholder,
}) => {
  const customClass = cx({
    theme_standard_group_check_box: theme_standard_group_check_box,
    theme_standard_group_check_box_user: theme_standard_group_check_box_user,
  });

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [seleted, setSelected] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [_value, _setValue] = useState(placeholder ? placeholder : 'หมวดหมู่');
  const [_options, _setOptions] = useState({
    page: { offset: 0, per_page: 10 },
    data: [],
    hasMore: true,
  });

  useEffect(() => {
    if (Object.keys(seleted).length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [seleted]);

  useEffect(() => {
    _fetchData();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchData = async (
    stringQuery = `?per_page=${_options.page.per_page}&offset=${_options.page.offset}`,
    isLoadMore
  ) => {
    if (!isLoadMore) {
      if (!_options || _options.data.length === 0) {
        let res = await endPoint(epParameter, stringQuery);
        if (res && res.success) {
          _setOptions({
            data: [
              { label: 'All', name: 'all', value: 'all' },
              ...res.data.map((e) => ({ label: e.name, value: e.id })),
            ],
            page: {
              offset: res.page.from - 1,
              per_page: Number(res.page.per_page),
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
          if (value && value !== _value) {
            let find = res.data.find((e) => e.id === value.value);
            _setValue(find ? find.name : value);
            if (!isInitial) {
              onChange &&
                onChange(find ? { label: find.name, value: find.id } : value);
            }
          }
        }
      } else {
        if (value && value !== _value) {
          let find = _options.data.find((e) => e.value === value.value);
          _setValue(find ? find.label : value);
        }
      }
    } else {
      let res = await endPoint(epParameter, stringQuery);
      if (res && res.success) {
        if (!res.data || res.data.length === 0) {
          _setOptions({
            ..._options,
            hasMore: false,
          });
        } else {
          _setOptions({
            ..._options,
            data: [
              ..._options.data,
              ...res.data.map((e) => ({ label: e.name, value: e.id })),
            ],
            page: {
              offset: res.page.from - 1,
              per_page: Number(res.page.per_page),
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
        }
      }
    }
  };

  const _fetchMoreData = () => {
    _fetchData(
      `?per_page=${_options.page.per_page}&offset=${
        _options.page.offset + _options.page.per_page
      }`,
      true
    );
  };

  const handleSelected = (key) => {
    let selectedTemp = { ...seleted };
    if (selectedTemp[key]) {
      if (key === 'all') {
        selectedTemp = {};
      } else {
        if (selectedTemp['all']) {
          delete selectedTemp['all'];
        }
        delete selectedTemp[key];
      }
    } else {
      if (key === 'all') {
        _options.data.forEach((e) => {
          selectedTemp[e.value] = true;
        });
      } else {
        selectedTemp[key] = true;
      }
    }
    setSelected({ ...selectedTemp });
  };

  const handleClickSelected = () => {
    setIsComponentVisible(false);
    onChangeCustom && onChangeCustom(Object.keys(seleted).map((key2) => key2));
  };

  const handleClickFilter = () => {
    setIsComponentVisible(true);
  };

  return (
    <InputGroupCheckBoxLoadmoreStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      boxSize={boxSize}
      topBox={topBox}
      rightBox={rightBox}
    >
      <div className={customClass} ref={ref}>
        <div className="icon_filter" onClick={handleClickFilter}>
          <Icons.Control2 color={iconColor && iconColor} />
        </div>
        <div
          className="filter_block"
          style={{ visibility: isComponentVisible ? 'visible' : 'hidden' }}
        >
          <Blocks.Box theme_group_check_box maxHeight={maxHeight}>
            <InfiniteScroll
              dataLength={_options.data.length}
              style={{ marginRight: -16 }}
              next={_fetchMoreData}
              hasMore={_options.hasMore}
              loader={<h3 className="loading">Loading...</h3>}
              height={250}
              endMessage={
                <p className="loading">{/* <b>ดูครบทั้งหมดแล้ว</b> */}</p>
              }
            >
              <div className="bc_body">
                {_options &&
                  _options.data.map((e, i) => (
                    <div key={i + 1} className="body_checkbox">
                      <label className="container">
                        <input
                          type="checkbox"
                          name={e.name}
                          // value={seleted[e.value]}
                          checked={seleted[e.value]}
                          onChange={() => {
                            handleSelected(e.value);
                          }}
                        />
                        <span className="checkmark"></span>
                        {e.label}
                      </label>
                    </div>
                  ))}
              </div>

              {/* {data &&
              data.map((e, i) => (
                <div key={i + 1} className="body_checkbox">
                  <label className="container">
                    <input
                      type="checkbox"
                      name={e.name}
                      // value={seleted[e.value]}
                      checked={seleted[e.value]}
                      onChange={() => {
                        handleSelected(e.value);
                      }}
                    />
                    <span className="checkmark"></span>
                    {e.label}
                  </label>
                </div>
              ))} */}
              <div className="btn_show">
                <Buttons.BtnNormal
                  theme_standard_btn_normal_pink
                  label={labelButton}
                  backgroundColor={
                    theme_standard_group_check_box_user && '#575D96'
                  }
                  borderRadius="6px"
                  fontSize="14px"
                  disabled={disabled}
                  onClick={handleClickSelected}
                />
              </div>
            </InfiniteScroll>
          </Blocks.Box>
        </div>
      </div>
    </InputGroupCheckBoxLoadmoreStyled>
  );
};

InputGroupCheckBoxLoadmore.propTypes = {};

export default InputGroupCheckBoxLoadmore;

import styled from 'styled-components';

export const RewardHistoryContainerStyled = styled.div`
  padding: 15px 32px 56px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
    .show_loading {
      height: calc(100vh - 85px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .layer_one {
        display: flex;
    }
    .layer_three {
        margin-top: 44px;
        .group_line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 29px;
          &.no_margin {
            margin-bottom: 0px;
          }
          .left_line {
            width: 80%;
            &.flex {
              display: flex;
            }
            &.pd_left {
              padding-left: 20px;
            }
            .title_list {
              display: flex;
              margin-bottom: 16px;
              .icon_list {
                display: flex;
                margin-right: 15px;
              }
            }
            .group_date {
              margin-left: 42px;
              width: 30%;
              display: flex;
              .box_date {
                width: 100%;
              }
            }
          }
          .right_line {
            width: 14%;
          }
        }
      }
    .layer_four {
        margin-top: 25px;
    }
`;

import styled from 'styled-components'

export const YourAccountFormStyled = styled.div`
  .top_form {
    display: flex;
    .l_top_form {
      width: 35%;
      margin-right: 13%;
      .group_input {
        margin-bottom: 37px;
        &.no_margin {
          margin-bottom: 0px;
        }
        .input_sub {
          margin-top: 11px;
        }
      }
    }
    .r_top_form {
      width: 35%;
      .group_input_right {
        margin-bottom: 37px;
        &.no_margin {
          margin-bottom: 0px;
        }
        .input_sub {
          margin-top: 11px;
        }
      }
    }
  }
  .bottom_form {
    margin-top: 114px;
    width: 83%;
    display: flex;
    justify-content: center;
    .btn {
      width: 24%;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .bottom_form {
      .btn {
        width: 30%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .bottom_form {
      .btn {
        width: 39%;
      }
    }
  }
`

export default function Star2({ width = '23.823', height = '27' }) {
  return (
    <svg id="_027-badge" data-name="027-badge" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23.823 27">
      <path id="Path_1140" data-name="Path 1140" d="M53.946,18.953V7.571a1.643,1.643,0,0,0-.822-1.423L42.856.22a1.643,1.643,0,0,0-1.643,0L30.945,6.148a1.643,1.643,0,0,0-.822,1.423V18.953a1.643,1.643,0,0,0,.822,1.423L41.213,26.3a1.643,1.643,0,0,0,1.643,0l10.268-5.928A1.643,1.643,0,0,0,53.946,18.953Z" transform="translate(-30.123 0.001)" fill="#ff4755" />
      <path id="Path_1141" data-name="Path 1141" d="M53.124,351.264l-10.268,5.928a1.643,1.643,0,0,1-1.643,0l-10.268-5.928a1.643,1.643,0,0,1-.822-1.423v.98a1.643,1.643,0,0,0,.822,1.423l10.268,5.928a1.643,1.643,0,0,0,1.643,0l10.268-5.928a1.643,1.643,0,0,0,.822-1.423v-.98A1.643,1.643,0,0,1,53.124,351.264Z" transform="translate(-30.123 -331.392)" fill="#fc2b3a" />
      <path id="Path_1142" data-name="Path 1142" d="M90.908,59.527v-10.1a.272.272,0,0,0-.136-.236l-9.46-5.462a.272.272,0,0,0-.272,0l-9.46,5.462a.272.272,0,0,0-.136.236v10.1l.569.834,9.164,5.291,9.163-5.291Z" transform="translate(-69.264 -41.387)" fill="#fff5f5" />
      <path id="Path_1143" data-name="Path 1143" d="M71.444,343.973v.823a.272.272,0,0,0,.136.236l9.46,5.462a.272.272,0,0,0,.272,0l9.46-5.462a.272.272,0,0,0,.136-.236v-.823l-9.6,5.54a.272.272,0,0,1-.272,0Z" transform="translate(-69.265 -325.834)" fill="#efe2dd" />
      <g id="Group_290" data-name="Group 290" transform="translate(5.757 7.146)">
        <path id="Path_1144" data-name="Path 1144" d="M146.45,136.139l1.029,2.085a1.127,1.127,0,0,0,.848.616l2.3.334a1.127,1.127,0,0,1,.624,1.922l-1.665,1.623a1.127,1.127,0,0,0-.324,1l.393,2.292a1.107,1.107,0,0,1-.23.895h-1.4l-2.583-1.54-2.583,1.54-1.375.036a1.107,1.107,0,0,1-.26-.931l.393-2.292a1.127,1.127,0,0,0-.324-1l-1.665-1.623a1.127,1.127,0,0,1,.624-1.922l2.3-.334a1.127,1.127,0,0,0,.848-.616l1.029-2.085A1.127,1.127,0,0,1,146.45,136.139Z" transform="translate(-139.285 -135.511)" fill="#ffcb5b" />
        <g id="Group_289" data-name="Group 289" transform="translate(2.198 9.383)">
          <path id="Path_1145" data-name="Path 1145" d="M184.8,313.482l-3.83,2.014a1.121,1.121,0,0,0,1.374.257l2.058-1.082a1.127,1.127,0,0,1,1.048,0l2.058,1.082a1.121,1.121,0,0,0,1.374-.257l-3.83-2.014A.273.273,0,0,0,184.8,313.482Z" transform="translate(-180.968 -313.45)" fill="#f7b737" />
        </g>
      </g>
    </svg>
  );
}

import styled from "styled-components";

export const RewardManagmentContainerStyled = styled.div`
  min-width: 1024px;
  padding: 15px 32px 56px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .layer_one {
    display: flex;
  }
  .serach_panel {
    margin-top: 18px;
    margin-bottom: 32px;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    .search_layout {
      width: 40%;
    }
    .add_reward {
      width: 240px;
      flex-shrink: 0;
    }
  }
  .layer_two {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1}50;
    .group_two {
      margin-top: 14px;
      display: flex;
      column-gap: 24px;
      .group_l {
        flex: 1;
        .pop_show {
        }
      }
      .group_r {
        width: 240px;
        flex-shrink: 0;
      }
    }
  }
  .layer_three {
    margin-top: 24px;
    .group_line {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .left_line {
        flex: 1;
        .title_list {
          display: flex;
          .icon_list {
            display: flex;
            margin-right: 15px;
          }
        }
      }
      .category_layout {
        width: 70%;
      }
    }
  }
  .layer_four {
    margin-top: 25px;
    position: relative;
    .history_button {
      width: 240px;
      position: absolute;
      top: 7px;
      right: 11px;
    }
  }

  .show_new {
    color: ${({ theme }) => theme.COLORS.BLUE_19};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
  }
`;

import React, { useState, useEffect } from "react";
import cx from "classnames";
import { InputDropDownFilterStyled } from "./styled";
import { Icons } from "components";
import { useComponentVisible } from "utils/functions/detectClickOutside";

const InputDropDownFilter = ({
  theme_standard,
  label,
  errors,
  defaultValue,
  placeholder = "Select an option",
  options = defaultOptions,
  onChange,
  color = "#002366",
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValues] = useState();

  useEffect(() => {
    if (defaultValue && !_value) {
      _setValues(defaultValue);
    }
  }, [options, defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleClickDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleSelectedOption = (e) => {
    _setValues(e);
    setIsComponentVisible(!isComponentVisible);
    onChange && onChange(e);
  };

  return (
    <InputDropDownFilterStyled>
      <div className={customClass}>
        <div className="input_container" ref={ref}>
          {label && <div className="label">{label}</div>}
          <div className="input_block">
            <div className="input_dropdown" onClick={handleClickDropdown}>
              <div className="input_value">
                {(_value && _value.label) || placeholder}
              </div>
              <div className="icon_wrap">
                {isComponentVisible ? (
                  <Icons.RectArrowUp color={color} />
                ) : (
                  <Icons.RectArrowDown color={color} />
                )}
              </div>
            </div>
            {isComponentVisible && (
              <div className="option_container">
                {options && options(handleSelectedOption)}
                {/*
                EXAMPLE CLASS
                {defaultOptions &&
                  defaultOptions.map((e, i) =>
                    !e.type ? (
                      <div key={i} className="title h3">
                        {e.label}
                      </div>
                    ) : (
                      <div className="layout_1">
                        <div className="title h1">{e.name}</div>
                        {e.items.map((f, j) => (
                          <div className="layout_2">
                            <div key={j} className="title h2">
                              {f.label}
                            </div>
                            {f.type_2 && (
                              <div className="layout3">
                                {f.items_2.map((g, k) => (
                                  <div key={k} className="title h3">
                                    {g.label}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  )} */}
              </div>
            )}
          </div>
        </div>
        {errors && <div className="error">{errors}</div>}
      </div>
    </InputDropDownFilterStyled>
  );
};

const defaultOptions = [
  { value: "one", label: "One" },
  { value: "two", label: "Two", className: "myOptionClassName" },
  {
    type: "group",
    name: "group1",
    items: [
      {
        value: "three",
        label: "Three",
        className: "myOptionClassName",
        type_2: "dd",
        items_2: [
          { value: "one", label: "One" },
          { value: "two", label: "Two", className: "myOptionClassName" },
        ],
      },
      { value: "four", label: "Four" },
    ],
  },
  {
    type: "group",
    name: "group2",
    items: [
      { value: "five", label: "Five" },
      { value: "six", label: "Six" },
    ],
  },
];

InputDropDownFilter.propTypes = {};

export default InputDropDownFilter;

import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSortStyled } from './styled';
import { Icons } from 'components';

const InputSort = ({ theme_standard_sort, onChangeCustom }) => {
  const customClass = cx({
    theme_standard_sort: theme_standard_sort,
  });

  const [_isSort, _setIsSort] = useState('desc');

  const handleClickFilter = () => {
    onChangeCustom && onChangeCustom(_isSort === 'desc' ? 'asc' : 'desc');
    _setIsSort(_isSort === 'desc' ? 'asc' : 'desc');
  };

  return (
    <InputSortStyled>
      <div className={customClass}>
        <div className="icon_filter" onClick={handleClickFilter}>
          {_isSort === 'desc' ? <Icons.ArrowDown /> : <Icons.ArrowUp />}
        </div>
      </div>
    </InputSortStyled>
  );
};

InputSort.propTypes = {};

export default InputSort;

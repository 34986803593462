export default function renderIcon({ color = '#4E5B7E', width = "27.472", height = "31.566" }) {
  return (
    <svg id="_029-trophy" data-name="029-trophy" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27.472 31.566">
      <g id="Group_302" data-name="Group 302">
        <path id="Path_1155" data-name="Path 1155" d="M42.607,68.779l-2.661-1.535c-3.427-1.538-4.5-4.88-4.83-7.1a.309.309,0,0,1,.073-.25.316.316,0,0,1,.242-.111h1.333l.69-.555-.271-.8-.639-.555H35.433a2.226,2.226,0,0,0-2.2,2.55,12.27,12.27,0,0,0,2.453,5.888A9.933,9.933,0,0,0,42.676,69.9l.619-.289Z" transform="translate(-33.204 -54.309)" fill="#ffe27a" />
        <g id="Group_300" data-name="Group 300" transform="translate(3.342 3.568)">
          <path id="Path_1156" data-name="Path 1156" d="M87.4,57.877c.032.464.1,1.128.219,1.909h1.245l.4-.955-.669-.955Z" transform="translate(-87.403 -57.877)" fill="#f9cf58" />
          <path id="Path_1157" data-name="Path 1157" d="M145.108,210.519a9.326,9.326,0,0,1-2.54-.712,32.224,32.224,0,0,1,2.729,2.659,15.229,15.229,0,0,0,1.558.079l.187-1.071Z" transform="translate(-139.167 -200.44)" fill="#f9cf58" />
        </g>
        <path id="Path_1158" data-name="Path 1158" d="M315.821,68.779l2.661-1.535c3.427-1.538,4.5-4.88,4.83-7.1a.309.309,0,0,0-.073-.25.316.316,0,0,0-.242-.111h-1.333l-.69-.555.271-.8.639-.555H323a2.226,2.226,0,0,1,2.2,2.55,12.27,12.27,0,0,1-2.453,5.888,9.933,9.933,0,0,1-6.994,3.589l-.619-.289Z" transform="translate(-297.752 -54.309)" fill="#ffe27a" />
        <g id="Group_301" data-name="Group 301" transform="translate(16.255 3.568)">
          <path id="Path_1159" data-name="Path 1159" d="M396.25,57.877c-.032.464-.1,1.128-.219,1.909h-1.245l-.4-.955.669-.955Z" transform="translate(-388.374 -57.877)" fill="#f9cf58" />
          <path id="Path_1160" data-name="Path 1160" d="M298.8,210.519a9.327,9.327,0,0,0,2.54-.712,32.222,32.222,0,0,0-2.729,2.659,15.229,15.229,0,0,1-1.558.079l-.187-1.071Z" transform="translate(-296.864 -200.44)" fill="#f9cf58" />
        </g>
        <path id="Path_1161" data-name="Path 1161" d="M124.5,46.161l.487.591c-.175,1.913-.794,5.813-2.988,8.5-1.9,2.32-4.143,2.419-4.424,7.611l-.584.591h-2.435l-.584-.591c-.281-5.192-2.526-5.291-4.424-7.611-2.194-2.682-2.813-6.582-2.988-8.5l.487-.591Z" transform="translate(-102.035 -43.315)" fill="#ffba57" />
        <path id="Path_1162" data-name="Path 1162" d="M123.945,27.208c.065-.709.068-1.146.068-1.146l-9.282-.729-9.282.729s0,.437.068,1.146Z" transform="translate(-100.996 -23.771)" fill="#ffac3e" />
        <path id="Path_1163" data-name="Path 1163" d="M226.775,316.986c.019.357.03.738.03,1.146l1.772,1.11,1.772-1.11c0-.408.01-.788.03-1.146Z" transform="translate(-214.841 -297.443)" fill="#ffac3e" />
        <path id="Path_1164" data-name="Path 1164" d="M101.711,1.736H82.162c-.633,0-1.09-1-.992-1.164A1.145,1.145,0,0,1,82.162,0h19.549a1.145,1.145,0,0,1,.992.572C102.8.741,102.343,1.736,101.711,1.736Z" transform="translate(-78.2)" fill="#ffe27a" />
        <path id="Path_1165" data-name="Path 1165" d="M99.574,9.864H80.025a1.144,1.144,0,0,1-.991-.573,1.144,1.144,0,0,0,.991,1.718H99.574a1.144,1.144,0,0,0,.991-1.718A1.144,1.144,0,0,1,99.574,9.864Z" transform="translate(-76.063 -8.718)" fill="#f9cf58" />
        <path id="Path_1166" data-name="Path 1166" d="M153.488,335.568h-8.674a1.938,1.938,0,0,0-1.921,1.678l-.763,5.631.4.591h13.241l.4-.591-.763-5.631A1.938,1.938,0,0,0,153.488,335.568Z" transform="translate(-135.415 -314.879)" fill="#e95454" />
        <path id="Path_1167" data-name="Path 1167" d="M139.768,454.112l-.155,1.146,7.176.825,7.176-.825-.155-1.146Z" transform="translate(-133.053 -426.115)" fill="#f48080" />
        <path id="Path_1168" data-name="Path 1168" d="M133.023,474.561H116.914l-.555-.591v-.005a1.273,1.273,0,0,1,1.273-1.273H132.3a1.273,1.273,0,0,1,1.273,1.273v.005Z" transform="translate(-111.232 -443.549)" fill="#ed6362" />
        <path id="Path_1169" data-name="Path 1169" d="M116.677,494.565h16.582a.318.318,0,0,0,.318-.318v-.827H116.359v.827A.318.318,0,0,0,116.677,494.565Z" transform="translate(-111.232 -462.999)" fill="#f54848" />
      </g>
    </svg>
  )
}

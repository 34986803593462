export default function Popular3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="163.081" viewBox="0 0 168 163.081">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-738 -1260.919)">
        <g id="data-analysis" transform="translate(738 1223.681)">
          <path id="Path_20964" data-name="Path 20964" d="M112.456,220.271a5.8,5.8,0,0,1-5.8,5.8H5.8a5.8,5.8,0,0,1-5.8-5.8V84.242l112.456-5.8Z" transform="translate(0 -25.749)" fill="#edebed" />
          <path id="Path_20965" data-name="Path 20965" d="M256.466,93.9V229.929a5.8,5.8,0,0,1-5.8,5.8H239.075a5.8,5.8,0,0,0,5.8-5.8V222.2l-31.109-50.818L244.872,93.9Z" transform="translate(-133.575 -35.407)" fill="#dbd9dc" />
          <path id="Path_20966" data-name="Path 20966" d="M112.456,58.493H111.3l-52.17,8.941L11.594,58.493H0V43.035a5.8,5.8,0,0,1,5.8-5.8H106.659a5.8,5.8,0,0,1,5.8,5.8Z" fill="#c3aff0" />
          <path id="Path_20967" data-name="Path 20967" d="M298.63,43.037V58.495H287.037V43.037a5.8,5.8,0,0,0-5.8-5.8h11.594A5.8,5.8,0,0,1,298.63,43.037Z" transform="translate(-175.74 -0.001)" fill="#a69ded" />
          <path id="Path_20968" data-name="Path 20968" d="M130.608,222.2a1.932,1.932,0,0,1-1.932,1.932H32.837A1.932,1.932,0,0,1,30.9,222.2V93.9h99.7Z" transform="translate(-19.312 -35.405)" fill="#fff" />
          <path id="Path_20969" data-name="Path 20969" d="M118.426,156.493H63.743a1.932,1.932,0,0,1-1.932-1.932V126.736a1.932,1.932,0,0,1,1.932-1.932H136.4a1.932,1.932,0,0,1,1.932,1.932v13.526Z" transform="translate(-38.624 -54.718)" fill="#bde4ff" />
          <g id="Group_14561" data-name="Group 14561" transform="translate(20.289 110.47)">
            <path id="Path_20970" data-name="Path 20970" d="M90.025,300.066H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -271.083)" fill="#dbd9dc" />
            <path id="Path_20971" data-name="Path 20971" d="M90.025,269.161H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -251.771)" fill="#dbd9dc" />
            <path id="Path_20972" data-name="Path 20972" d="M93.889,238.255H56.983a2.9,2.9,0,1,1,0-5.8H93.889a2.9,2.9,0,1,1,0,5.8Z" transform="translate(-54.085 -232.459)" fill="#dbd9dc" />
          </g>
          <path id="Path_20973" data-name="Path 20973" d="M56.983,398.58a2.9,2.9,0,0,1-2.9-2.9v-5.8a2.9,2.9,0,1,1,5.8,0v5.8A2.9,2.9,0,0,1,56.983,398.58Z" transform="translate(-33.796 -218.55)" fill="#bde4ff" />
          <path id="Path_20974" data-name="Path 20974" d="M87.889,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,87.889,385.706Z" transform="translate(-53.109 -205.675)" fill="#fc8d94" />
          <path id="Path_20975" data-name="Path 20975" d="M149.7,359.956a2.9,2.9,0,0,1-2.9-2.9V328.074a2.9,2.9,0,1,1,5.8,0v28.984A2.9,2.9,0,0,1,149.7,359.956Z" transform="translate(-91.733 -179.926)" fill="#fc8d94" />
          <path id="Path_20976" data-name="Path 20976" d="M180.605,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,180.605,385.706Z" transform="translate(-111.045 -205.675)" fill="#bde4ff" />
          <path id="Path_20977" data-name="Path 20977" d="M118.794,372.831a2.9,2.9,0,0,1-2.9-2.9V348.678a2.9,2.9,0,1,1,5.8,0v21.255A2.9,2.9,0,0,1,118.794,372.831Z" transform="translate(-72.421 -192.8)" fill="#bde4ff" />
          <path id="Path_20979" data-name="Path 20979" d="M200.563,233.647a49.081,49.081,0,0,1,28.906-83.441c13.533.156,28.912,7.629,39.972,18.689,0,0,22.414,30.049-3.349,62.154,0,0-15.748,16.134-36.615,16.633a48.872,48.872,0,0,1-28.915-14.034Z" transform="translate(-116.345 -70.591)" fill="#ffe07d" />
          <path id="Path_20980" data-name="Path 20980" d="M356.447,198.371a49.1,49.1,0,0,1-54.872,48.738,49.08,49.08,0,0,0,0-97.473,49.075,49.075,0,0,1,54.872,48.735Z" transform="translate(-188.447 -70.02)" fill="#ffd064" />
          <path id="Path_20981" data-name="Path 20981" d="M228.074,244.192a37.485,37.485,0,1,1,53.012,0A37.485,37.485,0,0,1,228.074,244.192Z" transform="translate(-135.657 -89.334)" fill="#fff" />
        </g>
        <g id="seasoning" transform="translate(816 1296.934)">
          <path id="Path_20982" data-name="Path 20982" d="M296.146,77.6V86l-6.263-1.392a7.094,7.094,0,0,0-4.171.338,7.085,7.085,0,0,1-5.266,0L276.079,83.2l-6.329-2.532,4.657-3.257,5.355-3.744L282.146,72l3.3.55a5.824,5.824,0,0,1,3.7,2.25,5.827,5.827,0,0,0,3.7,2.251Z" transform="translate(-224.146 -42.4)" fill="#f4ceaf" />
          <path id="Path_20983" data-name="Path 20983" d="M320.349,91.591h-3.141a4.96,4.96,0,0,1-3.53-1.462,3.147,3.147,0,0,0-1.221-.756l-2.842-.947A3.057,3.057,0,0,1,307.63,84.8a2.953,2.953,0,0,1,3.4-2.192l8.065,1.473Z" transform="translate(-257.527 -51.725)" fill="#f4ceaf" />
          <path id="Path_20984" data-name="Path 20984" d="M321.679,94.89h-2.6a5.11,5.11,0,0,1-3.55-1.493,3.147,3.147,0,0,0-1.19-.725l-2.842-.947a3.03,3.03,0,0,1-1.826-1.691l5.355-3.744,5.631,1.028a.467.467,0,1,1-.168.918l-.8-.147.437,5.866h1.559a.467.467,0,0,1,0,.933Z" transform="translate(-259.408 -55.023)" fill="#f2c194" />
          <path id="Path_20985" data-name="Path 20985" d="M361.867,320h10.267a2.8,2.8,0,0,1,0,5.6H365.6a3.733,3.733,0,0,1-3.733,3.733H360Z" transform="translate(-303.867 -261.467)" fill="#2f4054" />
          <path id="Path_20986" data-name="Path 20986" d="M66.933,328v.933a11.2,11.2,0,0,1-11.2,11.2H35.2a11.2,11.2,0,0,1-11.2-11.2V328Z" transform="translate(-7.067 -268.533)" fill="#e44b4d" />
          <path id="Path_20987" data-name="Path 20987" d="M66.933,264.4c0,1.988-1.766,3.816-4.718,5.255-3.934,1.918-9.975,3.145-16.749,3.145s-12.815-1.227-16.749-3.145C25.766,268.216,24,266.388,24,264.4c0-4.639,9.611-8.4,21.467-8.4S66.933,259.761,66.933,264.4Z" transform="translate(-7.067 -204.933)" fill="#8acce7" />
          <circle id="Ellipse_1992" data-name="Ellipse 1992" cx="0.5" cy="0.5" r="0.5" transform="translate(68 61.067)" fill="#262d43" />
          <path id="Path_20988" data-name="Path 20988" d="M97.6,311.467a3.422,3.422,0,0,1-.051.588,24.9,24.9,0,0,1-3.869,1.465A46.634,46.634,0,0,1,80.8,315.2a45.224,45.224,0,0,1-13.894-2,23.335,23.335,0,0,1-2.855-1.149,3.422,3.422,0,0,1-.051-.588C64,307.343,71.521,304,80.8,304S97.6,307.343,97.6,311.467Z" transform="translate(-42.4 -247.333)" fill="#c4d4e0" />
          <path id="Path_20989" data-name="Path 20989" d="M115.684,332.557a46.636,46.636,0,0,1-12.88,1.68,45.225,45.225,0,0,1-13.894-2,17.176,17.176,0,0,1,9.45-5.881l1.6-.369a17.2,17.2,0,0,1,9.3.442l1.671.556a4.112,4.112,0,0,1,2.689,2.905,4.115,4.115,0,0,0,1.709,2.424Z" transform="translate(-64.404 -266.37)" fill="#eedc9a" />
          <ellipse id="Ellipse_1993" data-name="Ellipse 1993" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(34 62.067)" fill="#e44b4d" />
          <ellipse id="Ellipse_1994" data-name="Ellipse 1994" cx="2" cy="1.5" rx="2" ry="1.5" transform="translate(42 60.067)" fill="#e44b4d" />
          <path id="Path_20990" data-name="Path 20990" d="M128,456h20.533v3.733H128Z" transform="translate(-98.933 -381.6)" fill="#c4d4e0" />
          <path id="Path_20991" data-name="Path 20991" d="M203.733,412.667c0-1.031-1.867-4.667-1.867-4.667S200,411.636,200,412.667a1.867,1.867,0,1,0,3.733,0Z" transform="translate(-162.533 -339.2)" fill="#e88604" />
          <path id="Path_20992" data-name="Path 20992" d="M147.733,419.733a1.866,1.866,0,0,1-1.956,1.865A1.9,1.9,0,0,1,144,419.681V416l.094.094a13.281,13.281,0,0,0,2.715,2.028A1.865,1.865,0,0,1,147.733,419.733Z" transform="translate(-113.067 -346.267)" fill="#e88604" />
          <path id="Path_20993" data-name="Path 20993" d="M46.533,419.733a2.8,2.8,0,0,0-2.8,2.8V424.4H40v-1.867A6.533,6.533,0,0,1,46.533,416a1.867,1.867,0,0,1,0,3.733Z" transform="translate(-21.2 -346.267)" fill="#2f4054" />
          <path id="Path_20994" data-name="Path 20994" d="M321.867,419.733a2.8,2.8,0,0,1,2.8,2.8V424.4H328.4v-1.867A6.533,6.533,0,0,0,321.867,416a1.867,1.867,0,1,0,0,3.733Z" transform="translate(-268.533 -346.267)" fill="#2f4054" />
          <path id="Path_20995" data-name="Path 20995" d="M16,480H62.667v1.867H16Z" transform="translate(0 -402.8)" fill="#262d43" />
          <ellipse id="Ellipse_1995" data-name="Ellipse 1995" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(32 72.067)" fill="#eebe33" />
          <path id="Path_20996" data-name="Path 20996" d="M256,419.733a1.866,1.866,0,0,0,1.956,1.865,1.9,1.9,0,0,0,1.777-1.917V416l-.094.094a13.279,13.279,0,0,1-2.715,2.028,1.866,1.866,0,0,0-.924,1.612Z" transform="translate(-212 -346.267)" fill="#e88604" />
          <circle id="Ellipse_1996" data-name="Ellipse 1996" cx="1.5" cy="1.5" r="1.5" transform="translate(44 72.067)" fill="#eebe33" />
          <circle id="Ellipse_1997" data-name="Ellipse 1997" cx="1.5" cy="1.5" r="1.5" transform="translate(38 72.067)" fill="#eebe33" />
          <path id="Path_20997" data-name="Path 20997" d="M264.8,32.947a3.187,3.187,0,0,1-3.186,3.186,10.334,10.334,0,0,0-7.308,3.027l-1.64,1.64L248,36.133l1.64-1.64a10.335,10.335,0,0,0,3.026-7.307A3.187,3.187,0,0,1,255.852,24a8.942,8.942,0,0,1,8.948,8.947Z" transform="translate(-204.933 0)" fill="#8acce7" />
          <path id="Path_20998" data-name="Path 20998" d="M293.067,75.347a3.187,3.187,0,0,1-3.186,3.186,10.339,10.339,0,0,0-7.306,3.025L280.933,83.2,280,82.267V77.6l1.918-1.918A12.571,12.571,0,0,1,290.807,72h1.327l.3.03A8.978,8.978,0,0,1,293.067,75.347Z" transform="translate(-233.2 -42.4)" fill="#ad7d4d" />
          <path id="Path_20999" data-name="Path 20999" d="M224,115.96a3.96,3.96,0,0,0,1.16,2.8l1.414,1.414a3.96,3.96,0,0,0,2.8,1.16h0a3.96,3.96,0,0,0,2.8-6.76l-1.414-1.414a3.96,3.96,0,0,0-2.8-1.16h0A3.96,3.96,0,0,0,224,115.96Z" transform="translate(-183.733 -77.733)" fill="#c4d4e0" />
          <circle id="Ellipse_1998" data-name="Ellipse 1998" cx="1" cy="1" r="1" transform="translate(42 38.067)" fill="#a6b2bc" />
          <circle id="Ellipse_1999" data-name="Ellipse 1999" cx="1" cy="1" r="1" transform="translate(44 36.067)" fill="#a6b2bc" />
          <ellipse id="Ellipse_2000" data-name="Ellipse 2000" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(44 40.067)" fill="#a6b2bc" />
          <circle id="Ellipse_2001" data-name="Ellipse 2001" cx="1" cy="1" r="1" transform="translate(46 38.067)" fill="#a6b2bc" />
          <path id="Path_21000" data-name="Path 21000" d="M317.393,84.672a3.187,3.187,0,0,1-3.186,3.186,10.308,10.308,0,0,0-3.947.784l-.646-.216A3.057,3.057,0,0,1,307.63,84.8a2.953,2.953,0,0,1,3.4-2.192l6.315,1.154A8.734,8.734,0,0,1,317.393,84.672Z" transform="translate(-257.527 -51.725)" fill="#966342" />
          <path id="Path_21001" data-name="Path 21001" d="M326.3,92.127l-8.4-1.535a2.025,2.025,0,0,0-2.329,1.5,2.108,2.108,0,0,0,1.374,2.517l2.841.947a4.059,4.059,0,0,1,1.587.981,4.06,4.06,0,0,0,2.871,1.189h2.985Z" transform="translate(-264.571 -58.794)" fill="#f4ceaf" />
          <g id="Group_14562" data-name="Group 14562" transform="translate(33.5 42.317)">
            <ellipse id="Ellipse_2002" data-name="Ellipse 2002" cx="0.5" cy="1.5" rx="0.5" ry="1.5" transform="translate(4.5 -0.25)" fill="#ad7d4d" />
            <ellipse id="Ellipse_2003" data-name="Ellipse 2003" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(5.5 3.75)" fill="#ad7d4d" />
            <circle id="Ellipse_2004" data-name="Ellipse 2004" cx="1" cy="1" r="1" transform="translate(8.5 4.75)" fill="#ad7d4d" />
            <circle id="Ellipse_2005" data-name="Ellipse 2005" cx="1" cy="1" r="1" transform="translate(3.5 4.75)" fill="#ad7d4d" />
            <ellipse id="Ellipse_2006" data-name="Ellipse 2006" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(5.5 10.75)" fill="#ad7d4d" />
            <ellipse id="Ellipse_2007" data-name="Ellipse 2007" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8.5 10.75)" fill="#ad7d4d" />
            <circle id="Ellipse_2008" data-name="Ellipse 2008" cx="0.5" cy="0.5" r="0.5" transform="translate(0.5 3.75)" fill="#ad7d4d" />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React, { useEffect, useState, useRef } from "react";
// import PropTypes from 'prop-types'
import { LoadingStyled } from "./styled";
import cx from "classnames";
import Loader from "react-loader-spinner";

const Loading = ({
  theme_standard_loading,
  visible,
  type = "Puff",
  color = "#575D96",
  height = 70,
  width = 70,
  timeout,
}) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  });

  let loadingRef = useRef();
  const [_isLoading, _setIsLoading] = useState(false);

  useEffect(() => {
    _setIsLoading(visible ? true : false);
    if (visible) {
      loadingRef.current = setTimeout(() => {
        _setIsLoading(false);
      }, 15000);
    } else {
      loadingRef.current && clearTimeout(loadingRef.current);
    }
    return () => {
      _setIsLoading(false);
      clearInterval(loadingRef.current);
    };
  }, [visible]);

  return (
    <LoadingStyled visible={_isLoading}>
      <div className={customClass}>
        <Loader
          type={type}
          color={color}
          height={height}
          width={width}
          timeout={timeout} //3 secs
        />
      </div>
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;

import { useEffect, useState } from "react";
import { StepIndicatorGraphStyled } from "./styled";
import { Blocks, Icons, Typographys, Buttons, Skeleton } from "components";
import Chart from "react-apexcharts";
import moment from "moment";
import { RENDER_MONTH, RENDER_DAYS } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import theme from "styles/theme.json";
// import millify from 'millify';

const StepIndicatorGraph = ({ data, fetchData, fetchDataGoal }) => {
  const [_data, _setData] = useState();
  const [_dataLoaded, _setDataLoaded] = useState();
  const [_avgValue, _setAvgValue] = useState(70000); // ของเดิม 70000
  // const [_dataGoal, _setDataGoal] = useState();
  const [, _setCurrentPageTemp] = useState(); //_currentPageTemp
  const [_filter, _setFilter] = useState("weekly");
  const [_isShorLeftArrow, _setIsShorLeftArrow] = useState(false);
  // const [_isShorRightArrow, _setIsShorRightArrow] = useState(false);
  const [_countClick, _setCountClick] = useState(0);
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);

  //First Initial Value
  useEffect(() => {
    if (data && data.data.length > 0) {
      const renderData = renderDataFormatByFilter("weekly", false, data);
      // _setDataGoal(
      //   renderDataFormatByFilter(
      //     'weekly',
      //     true,
      //     data.dataGoal.step.step_daily_target
      //   )
      // );
      // _setDataGoal(renderDataFormatByFilter('weekly', true, 7000));

      _setData(renderData.data);
      _setDataLoaded(renderData);
      if (data.page) {
        _setIsShorLeftArrow(true);
      }
      // _setCurrentPageTemp(data.page.current_page);
    }
  }, [data]);

  //Manage Enable/Disable Left/Right Arrow
  // useEffect(() => {
  //   if (_currentPageTemp && _dataLoaded) {
  //     if (_currentPageTemp === 1) {
  //       _setIsShorRightArrow(false);
  //       if (_currentPageTemp === _dataLoaded.page.last_page) {
  //         _setIsShorLeftArrow(false);
  //       } else {
  //         _setIsShorLeftArrow(true);
  //       }
  //     } else {
  //       _setIsShorRightArrow(true);
  //       if (_currentPageTemp === _dataLoaded.page.last_page) {
  //         _setIsShorLeftArrow(false);
  //       } else {
  //         _setIsShorLeftArrow(true);
  //       }
  //     }
  //   }
  // }, [_currentPageTemp, _dataLoaded]);

  const handleClickLeftArrow = async () => {
    _setCountClick(_countClick + 8);
    _setLoadSkeleton(true);

    let res = await fetchData(
      _filter,
      8,
      _dataLoaded.page.per_page * _dataLoaded.page.current_page
    );

    if (res) {
      _setDataLoaded({
        data: [...res.data],
        page: res.page,
      });
      const renderData = renderDataFormatByFilter(_filter, false, res);

      _setData(renderData.data);
      _setCurrentPageTemp(res.page.current_page);
      _setLoadSkeleton(false);
    }

    // if (_currentPageTemp < _dataLoaded.page.current_page) {
    //   handleCalDataPage('next');
    // } else {
    //   let res = await fetchData(
    //     _filter,
    //     8,
    //     _dataLoaded.page.per_page * _dataLoaded.page.current_page
    //   );
    //   if (res) {
    //     _setDataLoaded({
    //       data: [..._dataLoaded.data, ...res.data],
    //       page: res.page,
    //     });
    //     _setData(res.data);
    //     _setCurrentPageTemp(res.page.current_page);
    //   }
    // }
  };

  const handleClickRightArrow = async () => {
    let tempOffset = _countClick - 8
    _setCountClick(tempOffset)
    _setLoadSkeleton(true)

    let res = await fetchData(
      _filter,
      8,
      tempOffset,
    );
    if (res) {
      _setDataLoaded({
        data: [...res.data],
        page: res.page,
      });

      const renderData = renderDataFormatByFilter(_filter, false, res);

      _setData(renderData.data);
      _setCurrentPageTemp(res.page.current_page);
      _setLoadSkeleton(false)
    }

    // handleCalDataPage('back');
  };

  const handleClickFilter = async (value) => {
    _setFilter(value);
    _setCountClick(0);
    _setLoadSkeleton(true);
    let res = await Promise.all([fetchData(value)]);
    if (res) {
      const renderData = renderDataFormatByFilter(value, false, res[0]);

      if (value === "weekly") {
        _setAvgValue(70000);
      } else if (value === "monthly") {
        _setAvgValue(300000);
      } else {
        _setAvgValue(10000);
      }

      // _setDataGoal(
      //   renderDataFormatByFilter(
      //     'weekly',
      //     true,
      //     data.dataGoal.step.step_daily_target
      //   )
      // );
      // _setDataGoal(renderDataFormatByFilter(value, true, 7000));
      _setData(renderData.data);
      _setDataLoaded(renderData);
      _setCurrentPageTemp(renderData.page.current_page);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };

  // const handleCalDataPage = (key) => {
  //   switch (key) {
  //     case 'back':
  //       _setData(
  //         _dataLoaded.data.slice(
  //           _currentPageTemp - 1 === 1
  //             ? 0
  //             : (_currentPageTemp - 1) * Number(_dataLoaded.page.per_page) - 8,
  //           (_currentPageTemp - 1) * Number(_dataLoaded.page.per_page)
  //         )
  //       );
  //       _setCurrentPageTemp(_currentPageTemp - 1);
  //       break;
  //     case 'next':
  //       _setData(
  //         _dataLoaded.data.slice(
  //           (_currentPageTemp + 1) * Number(_dataLoaded.page.per_page) - 8,
  //           (_currentPageTemp + 1) * Number(_dataLoaded.page.per_page)
  //         )
  //       );
  //       _setCurrentPageTemp(_currentPageTemp + 1);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const renderDataFormatByFilter = (key, isGoal, value) => {
    switch (key) {
      case "weekly":
        return isGoal
          ? value * 7
          : {
              ...value,
              data: value.data.map((e) => ({
                ...e,
                // step_count_avg: Number(e.step_count_avg) * 7,
                step_count_avg: e.step_count_avg,
              })),
            };

      case "monthly":
        return isGoal
          ? 210000
          : {
              ...value,
              data: value.data.map((e) => ({
                ...e,
                // step_count_avg: Number(e.step_count_avg) * 30,
                step_count_avg: e.step_count_avg,
              })),
            };
      case "daily":
        return value;
      default:
        break;
    }
  };

  const renderLabelX = (key, value) => {
    let renderMonth;
    switch (key) {
      case "daily":
        renderMonth = moment(value).format("DD");
        return [renderMonth, RENDER_DAYS(moment(value).format("d")).enShort];
      case "weekly":
        renderMonth =
          moment(value).startOf("isoWeek").format("MM") ===
          moment(value).endOf("isoWeek").format("MM")
            ? RENDER_MONTH(moment(value).startOf("isoWeek").format("MM"))
                .enShort
            : `${
                RENDER_MONTH(moment(value).startOf("isoWeek").format("MM"))
                  .enShort
              } ${
                RENDER_MONTH(moment(value).endOf("isoWeek").format("MM"))
                  .enShort
              }`;

        return [
          `${moment(value).startOf("isoWeek").format("DD")}-${moment(value)
            .endOf("isoWeek")
            .format("DD")}`,
          renderMonth,
        ];
      default:
        return [
          RENDER_MONTH(moment(value).format("MM")).enShort,
          moment(value).format("YYYY"),
        ];
    }
  };

  const handleMaxGraphY = () => {
    let checkTotalCount = _data.every(
      (e) => Number(e.step_count_avg) < _avgValue
    );

    let tempMax = _filter === 'weekly' ? 77000 : _filter === 'monthly' ? 330000 : 11000

    return checkTotalCount
      ? tempMax
      : undefined;
  };

  return (
    <StepIndicatorGraphStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="17px"
        paddingRight="24px"
        paddingBottom="15px"
        paddingLeft="24px"
      >
        <div className="step_indicator_head_container">
          <div className="step_indicator_head_left_col">
            <div className="head_icon">
              <Icons.Run width="48" height="59" />
            </div>
            <Typographys.TopBottom
              theme_left_align
              label="กราฟแสดงผลก้าวเดิน"
              labelBottom="Steps Indicator Graph"
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="step_indicator_head_right_col">
            <Buttons.BtnDateFillter2
              theme_standard_date_filter_2
              initial={_filter}
              onChange={handleClickFilter}
            />
            <div className="group_label">
              <div className="box">
                <div className="box_color red" />
                <div className="label_show">
                  <div>เผาผลาญดี</div>
                  <div className="sub_label">Super Active</div>
                </div>
              </div>
              <div className="box no_margin">
                <div className="box_color gray" />
                <div className="label_show">
                  <div>ควรเพิ่มก้าว</div>
                  <div className="sub_label">Less Active</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chart_body_show">
          {_data ? (
            <>
              {_loadSkeleton ? (
                <div className="show_skeleton">
                  <Skeleton.GraphSkeleton height="283px" />
                </div>
              ) : (
                <>
                  <div className="left_chart">
                    <div className="arrow_left">
                      {_isShorLeftArrow && (
                        <div onClick={handleClickLeftArrow}>
                          <Icons.MoveChartLeft />
                        </div>
                      )}
                    </div>
                    <div className="arrow_right">
                      {_countClick > 0 && (
                        <div onClick={handleClickRightArrow}>
                          <Icons.MoveChartRight />
                        </div>
                      )}
                    </div>
                    <Chart
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                          fontFamily: theme.FONT.STYLE.REGULAR,
                          toolbar: {
                            show: false,
                          },
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: _data && `${(_data.length * 40) / 8}%`,
                          },
                        },
                        dataLabels: {
                          // enabled: false,
                          formatter: function (val) {
                            return val === 0
                              ? 0
                              : RenderCommaMoney(Number(val));
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        legend: {
                          show: false,
                          position: "right",
                          offsetX: -20,
                          offsetY: 190,
                        },
                        colors: [
                          function ({ value, seriesIndex, w }) {
                            if (_avgValue - 1 > value) {
                              return "#654C96";
                            } else {
                              return "#8AC47F";
                            }
                          },
                        ],
                        xaxis: {
                          categories: _data
                            ? _data.map((e) => {
                                return renderLabelX(_filter, e.stat_date);
                              })
                            : [],
                          axisBorder: {
                            show: true,
                            color: "#707070",
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                          },
                          legend: {
                            show: false,
                            position: "right",
                            offsetX: -20,
                            offsetY: 190,
                          },
                          colors: ["#FB4844"],
                        },
                        yaxis: {
                          labels: {
                            // formatter: function (value) {
                            //   return millify(Number(value));
                            // },
                            formatter: (value) => {
                              return value === 0
                                ? 0
                                : RenderCommaMoney(Number(value));
                            },
                          },
                          axisBorder: {
                            show: true,
                            color: "#707070",
                          },
                          max: _data ? handleMaxGraphY() : undefined,
                        },
                        fill: {
                          opacity: 1,
                        },
                        tooltip: {
                          custom: function ({
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                          }) {
                            let temp =
                              _avgValue > series[seriesIndex][dataPointIndex]
                                ? "ควรเพิ่มก้าว"
                                : "เผาผลาญดี";
                            let tag =
                              _avgValue > series[seriesIndex][dataPointIndex]
                                ? '<div class="green_dot"></div>'
                                : '<div class="purple_dot"></div>';
                            return (
                              '<div class="arrow_box">' +
                              tag +
                              temp +
                              ": " +
                              '<span class="c_value">' +
                              RenderCommaMoney(
                                Number(series[seriesIndex][dataPointIndex]),
                                0
                              ) +
                              "</span>" +
                              "</div>"
                            );
                          },
                        },
                        annotations: {
                          yaxis: [
                            {
                              // y: _dataGoal
                              //   ? Number(_dataGoal - (10 * _dataGoal) / 100)
                              //   : 0,
                              y:
                                _filter === "weekly"
                                  ? 63000
                                  : _filter === "monthly"
                                  ? 270000
                                  : 9000,
                              borderColor: "#FFA2A0",
                              label: {
                                textAnchor: "end",
                                position: "right",
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                  color: "#654C96",
                                  fontFamily:
                                    "noto_sans_thai_medium, noto_sans_regular",
                                  fontSize: "10px",
                                  cssClass: "apexcharts-yaxis-label",
                                },
                                text:
                                  _filter === "weekly"
                                    ? "63,000"
                                    : _filter === "monthly"
                                    ? "270,000"
                                    : "9,000",
                                // text: _dataGoal
                                //   ? String(
                                //     RenderCommaMoney(
                                //       _dataGoal - (10 * _dataGoal) / 100
                                //     )
                                //   )
                                //   : '0',
                              },
                            },
                            {
                              // y: _dataGoal ? _dataGoal : 0,
                              y:
                                _filter === "weekly"
                                  ? 70000
                                  : _filter === "monthly"
                                  ? 300000
                                  : 10000,
                              borderColor: "#FD5E17",
                              label: {
                                style: {
                                  color: "#FD921E",
                                  fontFamily:
                                    "noto_sans_thai_medium, noto_sans_regular",
                                  fontSize: "12px",
                                },
                                // text: _dataGoal
                                //   ? String(
                                //     `${RenderCommaMoney(
                                //       _dataGoal
                                //     )} ก้าวเดินมาตรฐานเฉลี่ย Avg. Steps Standard`
                                //   )
                                //   : '0',
                                text: `${
                                  _filter === "weekly"
                                    ? "70,000"
                                    : _filter === "monthly"
                                    ? "300,000"
                                    : "10,000"
                                } ก้าวเดินมาตรฐานเฉลี่ย Avg. Steps Standard`,
                              },
                            },
                            {
                              // y: _dataGoal
                              //   ? Number(_dataGoal + (10 * _dataGoal) / 100)
                              //   : 0,
                              y:
                                _filter === "weekly"
                                  ? 77000
                                  : _filter === "monthly"
                                  ? 330000
                                  : 11000,
                              borderColor: "#FFA2A0",
                              label: {
                                // borderColor: '#00E396',
                                // offsetX: 30,
                                style: {
                                  color: "#654C96",
                                  fontFamily:
                                    "noto_sans_thai_medium, noto_sans_regular",
                                  fontSize: "10px",
                                },

                                text:
                                  _filter === "weekly"
                                    ? "77,000"
                                    : _filter === "monthly"
                                    ? "330,000"
                                    : "11,000",
                              },
                            },
                          ],
                        },
                      }}
                      series={[
                        {
                          name: "average",
                          data: _data ? _data.map((e) => e.step_count_avg) : [],
                        },
                      ]}
                      type="bar"
                      height={268}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="label_empty">ยังไม่มีข้อมูล</div>
          )}
        </div>
      </Blocks.Box>
    </StepIndicatorGraphStyled>
  );
};

export default StepIndicatorGraph;

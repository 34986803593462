import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { LabelIconStyled } from './styled';

const LabelIcon = ({
  theme_standard_label_icon,
  label,
  svgFront,
  svgBack,
  fontSize,
  fontFamily,
  fontColor,
  marginLeft,
  marginRight,
  align,
}) => {
  const customClass = cx({
    theme_standard_label_icon: theme_standard_label_icon,
  });
  return (
    <LabelIconStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      marginLeft={marginLeft}
      marginRight={marginRight}
      align={align}
    >
      <div className={customClass} >
        {
          svgFront &&
          <div className='svg_show_f'>
            {svgFront}
          </div>
        }
        <div className='show_label'>
          {label}
        </div>
        {
          svgBack &&
          <div className='svg_show_b'>
            {svgBack}
          </div>
        }
      </div>
    </LabelIconStyled>
  );
};

LabelIcon.propTypes = {};

export default LabelIcon;

import styled from 'styled-components'

export const BtnNumberStyled = styled.div`
  .theme_standard_btn_number {
    font-family: noto_sans_thai_bold, noto_sans_bold;
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    color:  ${({ theme }) => theme.COLORS.BLUE_15};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    padding: 10px 23px;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.COLORS.BLUE_15};
    .svg_f {
      display: flex;
      cursor: pointer;
      width: 17px;
      flex-shrink: 0;
    }
    .label {
      display: flex;
      flex-shrink: 0;
      width: 100px;
      margin: 0px 30px;
      text-align: center;
      justify-content: center;
    }
    .svg_b {
      display: flex;
      cursor: pointer;
      width: 17px;
      flex-shrink: 0;
    }
  }
`

export default function Dumbel2({ width = '21', height = '21' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 21">
      <g id="Group_3374" data-name="Group 3374" transform="translate(20 4.435)">
        <g id="Group_4" data-name="Group 4" transform="translate(-20 -4.001)">
          <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(0 -0.434)" fill="#fedb41" stroke="#e3a52f" strokeWidth="2">
            <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
            <circle cx="10.5" cy="10.5" r="9.5" fill="none" />
          </g>
          <g id="gym" transform="translate(4.088 6.271)">
            <g id="Group_180" data-name="Group 180" transform="translate(12.095 1.069)">
              <g id="Group_179" data-name="Group 179" transform="translate(0 0)">
                <path id="Path_286" data-name="Path 286" d="M480.534,131.4v6.482a1.066,1.066,0,0,0,.792-1.029v-4.425A1.066,1.066,0,0,0,480.534,131.4Z" transform="translate(-480.534 -131.405)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_182" data-name="Group 182" transform="translate(8.924 0.21)">
              <g id="Group_181" data-name="Group 181" transform="translate(0 0)">
                <path id="Path_287" data-name="Path 287" d="M355.867,97.3h-.249a1.066,1.066,0,0,0-1.064,1.064v6.071a1.066,1.066,0,0,0,1.064,1.064h.249a1.065,1.065,0,0,0,1.064-1.064V98.365A1.065,1.065,0,0,0,355.867,97.3Z" transform="translate(-354.553 -97.301)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_184" data-name="Group 184" transform="translate(4.756 2.992)">
              <g id="Group_183" data-name="Group 183">
                <rect id="Rectangle_1366" data-name="Rectangle 1366" width="3.375" height="2.597" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_186" data-name="Group 186" transform="translate(0 0.858)">
              <g id="Group_185" data-name="Group 185" transform="translate(0 0)">
                <path id="Path_288" data-name="Path 288" d="M0,124.073V128.5a1.066,1.066,0,0,0,.792,1.029v-6.482A1.066,1.066,0,0,0,0,124.073Z" transform="translate(0 -123.044)" fill="#e3a52f" />
              </g>
            </g>
            <g id="Group_188" data-name="Group 188" transform="translate(1.585 0)">
              <g id="Group_187" data-name="Group 187">
                <path id="Path_289" data-name="Path 289" d="M64.286,88.942h-.249a1.065,1.065,0,0,0-1.064,1.064v6.071a1.065,1.065,0,0,0,1.064,1.064h.249a1.065,1.065,0,0,0,1.064-1.064V90.006A1.065,1.065,0,0,0,64.286,88.942Z" transform="translate(-62.972 -88.942)" fill="#e3a52f" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const TableRankStyled = styled.div`
  .theme_standard_table_rank {
    font-family: noto_sans_thai_bold, noto_sans_bold;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    min-width: ${({ isEmpty }) => (isEmpty ? 'auto' : '1000px')};
    .label_empty {
      padding: 40px 0;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
    .row {
      display: flex;
      margin-bottom: 30px;
      .left_row {
        display: flex;
        flex-shrink: 0;
        margin-right: 16px;
        .round {
          height: 54px;
          width: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .right_row {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b1b1c1;
        padding-bottom: 30px;
        .col1 {
          width: 16.666%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          .top_col1 {
            font-family: noto_sans_thai_medium, noto_sans_regular;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sub_col1 {
            color: ${({ theme }) => theme.COLORS.GRAY_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .col2 {
          width: 16.666%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          .top_col2 {
            font-family: noto_sans_thai_medium, noto_sans_regular;
          }
          .sub_col2 {
            color: ${({ theme }) => theme.COLORS.GRAY_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          }
        }
        .col3 {
          width: 13%;
          display: flex;
          flex-shrink: 0;
          justify-content: flex-end;
          margin-right: 2%;
        }
        .col4 {
          width: 10%;
          background: #ebebeb;
          display: flex;
          flex-shrink: 0;
          font-family: noto_sans_thai_medium, noto_sans_regular;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          margin-right: 9%;
        }
        .col5 {
          width: 16.666%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-right: 6%;
          .top_col5 {
            font-family: noto_sans_thai_medium, noto_sans_regular;
          }
          .sub_col5 {
            color: ${({ theme }) => theme.COLORS.GRAY_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          }
        }
        .col6 {
          width: 9.96%;
          display: flex;
          justify-content: flex-end;
          flex-shrink: 0;
        }
      }
      &:last-child {
        margin-bottom: 0px;
        .right_row {
          border-bottom: none;
          padding-bottom: 0px;
        }
      }
    }
  }
`;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { PreviewInputStyled } from "./styled";
import { Buttons, Icons } from "components";
// import ReactPlayer from 'react-player';
import { PlayAudio, ShowImage, PlayVideo } from "widgets";

const PreviewInput = ({
  theme_standard,
  theme_left_right,
  type,
  src,
  svg,
  name,
  onClickRemove,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_left_right: theme_left_right,
  });

  // const [isPlay, setIsPlay] = useState(false);

  const renderTypePreview = () => {
    switch (type) {
      case "cover":
        return <ShowImage url={src} name={name} />;
      case "audio":
        return <PlayAudio url={src} name={name} />;
      case "video":
        return <PlayVideo url={src} name={name} />;
      default:
        break;
    }
  };

  return (
    <PreviewInputStyled theme_left_right={theme_left_right}>
      <div className={customClass}>
        <div className="preview_container">
          {renderTypePreview()}
          <div className="btn_action">
            <Buttons.BtnIcon
              theme_btn_icon_delete
              svgFront={svg ? svg : <Icons.Delete />}
              onClick={onClickRemove}
            />
          </div>
        </div>
      </div>
    </PreviewInputStyled>
  );
};

PreviewInput.propTypes = {};

export default PreviewInput;

export default function renderIcon({ color = '#fa5b47', bgColor = '#fff', width = "18", height = "18" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
      <g id="Group_90936" data-name="Group 90936" transform="translate(-1704 -567.703)">
        <circle id="Ellipse_5331" data-name="Ellipse 5331" cx="9" cy="9" r="9" transform="translate(1704 567.703)" fill={color} />
        <g id="Group_57566" data-name="Group 57566" transform="translate(1712.972 571.163) rotate(45)">
          <g id="Group_33118" data-name="Group 33118" transform="translate(0 0)">
            <path id="Path_49742" data-name="Path 49742" d="M.994,7.835a.994.994,0,0,1-.7-1.7L6.137.291A.994.994,0,0,1,7.543,1.7L1.7,7.543A.991.991,0,0,1,.994,7.835Z" fill={bgColor} />
            <path id="Path_97924" data-name="Path 97924" d="M.994,7.835a.994.994,0,0,1-.7-1.7L6.137.291A.994.994,0,0,1,7.543,1.7L1.7,7.543A.991.991,0,0,1,.994,7.835Z" fill={bgColor} />
          </g>
        </g>
      </g>
    </svg>
  )
}

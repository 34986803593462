import { useState, useEffect } from "react";
import { DdcTopDepartmentPuiAndRiskStyled } from "./styled";
import { Blocks, Typographys, Icons, Buttons, Skeleton } from "components";
import Chart from "react-apexcharts";
import theme from "styles/theme.json";
import momentTZ from "moment-timezone";
import moment from "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const DdcTopDepartmentPuiAndRisk = ({
  data,
  fetchData,
  label,
  labelBottom,
}) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_filter, _setFilter] = useState("weekly");
  // const [_labelDate, _setLabelDate] = useState('วันที่รายสัปดาห์');
  const [_data, _setData] = useState("");
  const [_dataLoaded, _setDataLoaded] = useState();
  const [_isShorLeftArrow, _setIsShorLeftArrow] = useState(true);
  const [
    ,
    //_isShorRightArrow
    _setIsShorRightArrow,
  ] = useState(true);
  const [_dateLockNextArrow, _setDateLockNextArrow] = useState(true);
  const [_page, _setPage] = useState(1);

  useEffect(() => {
    if (data) {
      _setData(data);
      _setDataLoaded([data]);
    }
  }, [data]);

  // Manage Enable/Disable Left/Right Arrow
  // PAGINATION GRAPH FLOW
  //============================================
  //============================================
  const handleClickLeftArrow = async () => {
    _setLoadSkeleton(true);
    _setPage(_page + 1);

    //check has dataloadmore
    let renderDayQuery = renderFilterDay(_filter);
    let dateFilter = momentTZ(_data.startDate)
      .tz("Asia/Bangkok")
      .subtract(renderDayQuery.number, renderDayQuery.type)
      .startOf(renderDayQuery.startOf)
      .format("YYYY-MM-DD");
    handleCheckData("clickLeft", dateFilter);
  };
  const handleClickRightArrow = async () => {
    _setPage(_page - 1);
    _setLoadSkeleton(true);
    let renderDayQuery = renderFilterDay(_filter);
    let dateFilter = momentTZ(_data.startDate)
      .tz("Asia/Bangkok")
      .add(renderDayQuery.number, renderDayQuery.type)
      .startOf(renderDayQuery.startOf)
      .format("YYYY-MM-DD");
    handleCheckData("clickRight", dateFilter);
  };
  const handleCheckData = async (key, date) => {
    handleDisabledArrow(date);
    let isFindCheckHaveData =
      _dataLoaded && _dataLoaded.find((e) => e.startDate === date);
    if (!isFindCheckHaveData) {
      let res = await fetchData(`?stat_date=${date}&period=${_filter}`, date);
      if (res && res.data) {
        _setData(res);
        _setDataLoaded([..._dataLoaded, res]);
        _setLoadSkeleton(false);
        if (key === "clickRight") {
          _setDateLockNextArrow(date);
          _setIsShorRightArrow(false);
        }
      }
    } else {
      _setData(isFindCheckHaveData);
      _setLoadSkeleton(false);
    }
  };
  const handleDisabledArrow = (date) => {
    if (_dateLockNextArrow === date) {
      _setIsShorRightArrow(false);
    } else {
      _setIsShorRightArrow(true);
    }
  };

  const handleClickFilter = async (value) => {
    _setPage(1);
    _setFilter(value);
    _setLoadSkeleton(true);
    const date = renderFilterDay(value).initialDate;
    let res = await fetchData(`?period=${value}&stat_date=${date}`, date);

    if (res && res.data) {
      _setData(res);
      _setDataLoaded([res]);
      resetFlow();
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };

  const resetFlow = () => {
    _setIsShorRightArrow(true);
    _setIsShorLeftArrow(true);
    _setDateLockNextArrow(false);
  };

  const renderFilterDay = (filterType) => {
    switch (filterType) {
      case "weekly":
        // _setLabelDate('วันที่รายสัปดาห์')
        return {
          number: 7,
          type: "days",
          startOf: "isoWeek",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      case "quarterly":
        // _setLabelDate('วันที่ราย 12 สัปดาห์')
        return {
          number: 1,
          type: "quarter",
          startOf: "quarter",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
        };
      case "halfyearly":
        // _setLabelDate('วันที่ราย 24 สัปดาห์')
        return {
          number: 6,
          type: "months",
          startOf: "month",
          initialDate:
            Number(momentTZ().tz("Asia/Bangkok").format("MM")) > 6
              ? `${moment().format("YYYY")}-07-01`
              : `${moment().format("YYYY")}-01-01`,
        };
      default:
        break;
    }
  };

  const renderLabelX = (key) => {
    let dateStart;
    let dateEnd;
    let valueDate;

    switch (key) {
      case "weekly":
        valueDate =
          moment(_data.startDate).startOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(
            moment(_data.startDate)
              .startOf("isoWeek")

              .format("MM")
          ).thShort +
          ` ` +
          moment(_data.startDate).startOf("isoWeek").format("YYYY") +
          ` - ` +
          moment(_data.startDate).endOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(moment(_data.startDate).endOf("isoWeek").format("MM"))
            .thShort +
          ` ` +
          moment(_data.startDate).endOf("isoWeek").format("YYYY");
        return valueDate;
      case "quarterly":
        dateStart = moment(_data.startDate).startOf("month");
        dateEnd = moment(_data.startDate).endOf("month").add(2, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      case "halfyearly":
        dateStart = moment(_data.startDate).startOf("month");
        dateEnd = moment(_data.startDate).endOf("month").add(5, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      default:
        return "";
    }
  };

  return (
    <DdcTopDepartmentPuiAndRiskStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="32px"
        paddingBottom="59px"
        paddingLeft="32px"
      >
        <div className="top_men">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label={label}
              labelBottom={labelBottom}
            />
          </div>
          <div className="right_men_layout">
            <div className="show_search">
              <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                onChange={handleClickFilter}
              />
            </div>
          </div>
        </div>
        {_data ? (
          <>
            <div className="top_legend">Number of Employees</div>
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="b_left_layout">
                    <div className="body_graph">
                      <Chart
                        options={{
                          chart: {
                            type: "bar",
                            height: 350,
                            fontFamily: theme.FONT.STYLE.REGULAR,
                            stacked: true,
                            toolbar: {
                              show: false,
                            },
                          },
                          // responsive: [
                          //   {
                          //     breakpoint: 480,
                          //     options: {
                          //       legend: {
                          //         position: "bottom",
                          //         offsetX: -10,
                          //         offsetY: 0,
                          //       },
                          //     },
                          //   },
                          // ],
                          xaxis: {
                            categories: _data
                              ? _data.data.map((e) => e.label)
                              : [],
                            labels: {
                              rotate: -40,
                              rotateAlways: true,
                              hideOverlappingLabels: false,
                              // formatter: function (value, timestamp, opts) {
                              //   return parse(value)
                              // }
                              style: {
                                colors: [],
                                fontSize: "12px",
                                fontFamily:
                                  "noto_sans_thai_regular, noto_sans_regular",
                                fontWeight: 100,
                                cssClass: "apexcharts-yaxis-label",
                              },
                            },
                            axisBorder: {
                              show: true,
                              color: theme.COLORS.PURPLE_1,
                              height: 3,
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: true,
                              color: theme.COLORS.PURPLE_1,
                              width: 3,
                            },
                            labels: {
                              formatter: function (value) {
                                return RenderCommaMoney(
                                  Number(value).toFixed(0),
                                  0
                                );
                              },
                            },
                          },
                          colors: ["#654C96", "#6FB5CB"],
                          fill: {
                            opacity: 1,
                          },

                          plotOptions: {
                            bar: {
                              columnWidth:
                                _data && `${(_data.data.length * 50) / 7}%`,
                            },
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (
                                value,
                                { series, seriesIndex, dataPointIndex, w }
                              ) {
                                return `${Number(value).toFixed(0)}`;
                              },
                            },
                          },
                        }}
                        series={[
                          {
                            name: "Fit in PUI risk",
                            data: _data
                              ? _data.data.map((e) => e.pui_user_count)
                              : [],
                          },
                          {
                            name: "Painful but not at risk",
                            data: _data
                              ? _data.data.map((e) => e.norisk_user_count)
                              : [],
                          },
                        ]}
                        type="bar"
                        height={350}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="right_men_layout">
                    <div className="date_show">
                      <div className="left_arrow">
                        {_isShorLeftArrow && (
                          <div onClick={handleClickLeftArrow}>
                            <Icons.ArrowBlueLeft />
                          </div>
                        )}
                      </div>
                      <div className="group_date">
                        <div className="group_date_bottom">
                          <div className="show_icon">
                            <Icons.CalendarIcon /> วันที่รายสัปดาห์ล่าสุด
                          </div>
                          {_data && _data.data && renderLabelX(_filter)}
                        </div>
                      </div>
                      <div className="right_arrow">
                        {_page > 1 ? (
                          <div onClick={handleClickRightArrow}>
                            <Icons.ArrowBlueRight />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="show_being">
                      <Blocks.BoxWellbeing
                        theme_standard_box_well_being
                        label="มีอาการแต่ยังไม่มีความเสี่ยง"
                        labelBottom="Pain without risk"
                        bgColor="#2A74A3"
                        showRound
                      />
                    </div>
                    <div className="show_being no_margin">
                      <Blocks.BoxWellbeing
                        theme_standard_box_well_being
                        label="มีความเสี่ยงเข้าเกณฑ์เป็นโรค"
                        labelBottom="PUI risk"
                        bgColor="#654C96"
                        showRound
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </DdcTopDepartmentPuiAndRiskStyled>
  );
};

export default DdcTopDepartmentPuiAndRisk;

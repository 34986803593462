import React from "react";
import { LogContainerUserStyled } from "./styled";
import {
  // Tabs,
  TableCustom,
  Loading,
  Modal,
  Blocks,
  Buttons,
  Inputs,
  BGLoading,
  Icons,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { adminService } from "apiServices";
import moment from "moment";
// import { toast } from 'react-toastify';
// import moment from 'moment';
import ReactTooltip from "react-tooltip";

class LogContainerUser extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
    startMonth: moment()
      .tz("Asia/Bangkok")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endMonth: moment().tz("Asia/Bangkok").endOf("month").format("YYYY-MM-DD"),
    offset_select: 0,
  };

  componentDidMount() {
    const { startMonth, endMonth } = this.state;

    this.scrollToTop();
    this.fetchData(
      `?log_type=user&begin_date=${startMonth}&end_date=${endMonth}&per_page=10&offset=0`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_AUDIT_LOG_LIST_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      let tempData = [];

      res.data.forEach((e, i) => {
        let countEmployee = 0;

        if (
          e.event_name === "admin_create_employees" &&
          e.event_summary.includes("{")
        ) {
          countEmployee = JSON.parse(e.event_summary).length;
        } else {
          countEmployee = e.event_summary.replace(" Employees", "");
        }

        tempData.push({
          ...e,
          eventMany: e.event_name === "admin_create_employees" ? true : false,
          countEmployee: countEmployee,
        });
      });
      this.setState({
        data: tempData,
        pagination: { ...res.page },
        isLoading: false,
        isBgLoading: false,
      });
    } else {
      this.setState({
        data: [],
        offset_select: 0,
        pagination: null,
        isBgLoading: false,
        isLoading: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT);
  };

  handlePopup = async (e) => {
    if (e.event_name === "admin_create_employees") {
      this.setState({
        isBgLoading: true,
      });

      let res = await adminService.GET_AUDIT_LOG_BY_ID(e.id);

      if (res && res.success) {
        let data = res.data;
        let eventMany =
          Array.isArray(res.data.event_detail) === true
            ? res.data.event_detail
            : [];

        if (data && eventMany) {
          this.setState({
            renderModal: (
              <div className="show_detail_log">
                <div className="svg_c" onClick={this.handleClickCloseModal}>
                  <Icons.CloseXModal />
                </div>
                <div className="group_h">
                  <div className="big_text">{data.actor_detail}</div>
                  <div className="date_show">
                    {`${moment(data.created_at)
                      .tz("Asia/Bangkok")
                      .format("DD MMM")} ${moment(data.created_at)
                      .tz("Asia/Bangkok")
                      .add(543, "years")
                      .format("YYYY - HH:mm")}`}
                  </div>
                </div>
                <div className="show_point">
                  {/* {data.event_summary} */}
                  <div className="line_txt">
                    <div className="lbl">เพิ่มจำนวนสมาชิก</div>
                    <div>
                      {eventMany &&
                        eventMany.length > 0 &&
                        `${RenderCommaMoney(
                          Number(eventMany.length).toFixed(0)
                        )} คน`}
                    </div>
                  </div>
                </div>
                <div className="group_list">
                  {eventMany && eventMany.length > 0 ? (
                    eventMany.map((e, i) => (
                      <div key={(i = +1)} className="group_name">
                        <div className="id_show">{e.employee_code}</div>
                        <div className="name_show">{`${e.employee_name}`}</div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>ข้อมูลเก่า</div>
                  )}
                </div>
              </div>
            ),
            isShowModal: true,
            isBgLoading: false,
          });
        }
      }
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: null,
    });
  };

  handlePageClick = (e) => {
    const { startMonth, endMonth } = this.state;
    this.setState({
      offset_select: e.selected * 10,
      isBgLoading: true,
    });
    this.fetchData(
      `?log_type=user&begin_date=${startMonth}&end_date=${endMonth}&per_page=10&offset=${
        e.selected * 10
      }`
    );
  };

  handleChangDate = (e, isStart) => {
    const { startMonth, endMonth, offset_select } = this.state;
    let tempDate;
    if (isStart) {
      tempDate = moment(e)
        .tz("Asia/Bangkok")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.setState({
        startMonth: tempDate,
        isBgLoading: true,
      });
      this.fetchData(
        `?log_type=user&begin_date=${tempDate}&end_date=${endMonth}&per_page=10&offset=${offset_select}`
      );
    } else {
      tempDate = moment(e)
        .tz("Asia/Bangkok")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.setState({
        endMonth: tempDate,
        isBgLoading: true,
      });
      this.fetchData(
        `?log_type=user&begin_date=${startMonth}&end_date=${tempDate}&per_page=10&offset=${offset_select}`
      );
    }
  };

  handleClickBack = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT}`);
  };

  render() {
    const {
      isLoading,
      isShowModal,
      renderModal,
      pagination,
      data,
      isBgLoading,
    } = this.state;

    return (
      <LogContainerUserStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <div className="head_wrap_layout">
                  <div className="h_33">
                    <Buttons.BtnLink
                      theme_standard_btn_link
                      svg={<Icons.ArrowLeft />}
                      label="ย้อนกลับ"
                      onClick={this.handleClickBack}
                    />
                  </div>
                  <div className="h_33 center">
                    <div className="text_c">Log จัดการบัญชีผู้ใช้</div>
                    {/* <div className="g_h_text">Activity Log</div> */}
                  </div>
                  <div className="h_33 flex" />
                </div>
              </div>
              <div className="filter_show">
                <div className="box_date">
                  <Inputs.InputDatePikerCustom3
                    theme_log
                    dateFormat="MMMM yyyy"
                    onChangeCustom={(e) => this.handleChangDate(e, true)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    initialValues={new Date()}
                  />
                </div>
                <div>ถึง</div>
                <div className="box_date">
                  <Inputs.InputDatePikerCustom3
                    theme_log
                    dateFormat="MMMM yyyy"
                    onChangeCustom={(e) => this.handleChangDate(e, false)}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    initialValues={new Date()}
                  />
                </div>
              </div>
              <div className="layer_two">
                <TableCustom.TableNormal
                  theme_log
                  columns={columns({
                    handleClickDetail: this.handlePopup,
                  })}
                  data={data}
                  pagination={pagination}
                  useNo
                  showPagination
                  useRenderNoPage
                  handlePageClick={this.handlePageClick}
                />
              </div>
              <Modal
                theme_modal_standard
                isShow={isShowModal}
                handleClickCloseModal={this.handleClickCloseModal}
              >
                {renderModal}
              </Modal>
            </Blocks.Container>
          </>
        )}
      </LogContainerUserStyled>
    );
  }
}

const columns = ({ handleClickDetail }) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "Username",
    field: "actor_username",
    classStyle: "showUser",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "center",
      minWidth: "100px",
      maxWidth: "250px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      minWidth: "100px",
      maxWidth: "250px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "50px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        data-tip
        data-for={`tooltipUsername${origin.id}`}
      >
        {value}
        <ReactTooltip id={`tooltipUsername${origin.id}`} delayShow="800">
          <span> {value} </span>
        </ReactTooltip>
      </div>
    ),
  },

  {
    title: "ชื่อ - นามสกุล",
    field: "actor_detail",
    classStyle: "showUser",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      minWidth: "100px",
      maxWidth: "250px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      minWidth: "100px",
      maxWidth: "250px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "50px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        data-tip
        data-for={`tooltipName${origin.id}`}
      >
        {value}
        <ReactTooltip id={`tooltipName${origin.id}`} delayShow="800">
          <span> {value} </span>
        </ReactTooltip>
      </div>
    ),
  },
  {
    title: "วัน - เวลา",
    field: "created_at",
    classStyle: "showDate",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      maxWidth: "180px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      maxWidth: "180px",
    },
    render: ({ origin, value }) => (
      <div
        className="shwoDate"
        style={{
          maxWidth: "180px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {`${moment(value).tz("Asia/Bangkok").format("DD MMM")} ${moment(value)
          .tz("Asia/Bangkok")
          .add(543, "years")
          .format("YYYY-HH:mm")}`}
      </div>
    ),
  },
  {
    title: "Action",
    field: "event_summary",
    classStyle: "showHead",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      minWidth: "200px",
    },
    style: {
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
      minWidth: "200px",
    },
    render: ({ origin, value }) => (
      <div
        className="showName"
        onClick={() => handleClickDetail(origin)}
        style={{
          // textDecoration: value.length > 1 ? "underline" : "unset",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {origin.eventMany === true ? (
          <div className="box_line_icon">
            <div className="value_lbl">
              {origin.countEmployee &&
              origin.event_summary.includes("Employees")
                ? `สร้างรายชื่อจำนวนมาก ${RenderCommaMoney(
                    Number(origin.countEmployee).toFixed(0)
                  )}  รายชื่อ`
                : origin.event_summary}
            </div>
            <div className="svg_page">
              <Icons.Page2 />
            </div>
          </div>
        ) : (
          <div
            style={{
              // textDecoration: value.length > 1 ? "underline" : "unset",
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        )}
      </div>
    ),
  },
];

// {
//   username: 'admin01@aat',
//   firstname: 'พรพิมล',
//   lastname: 'ไม้ทอง',
//   date: '01 ก.ย. 2565-12:30',
//   point: 50,
//   group_name: [
//     {
//       id: '121211',
//       firstname: 'เอกฉัท',
//       lastname: 'ก้องเกียรติสกุล',
//     },
//   ]
// },

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LogContainerUser);

import styled from 'styled-components';

export const BtnPopupStyled = styled.div`
position: relative;
  .body_btn {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    background: ${({ theme }) => theme.COLORS.BLUE_28};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    display: flex;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    width: 100%;
    padding: 12px 10px;
    cursor: pointer;
  }
  .otp_show {
    width: 100%;
    min-width: ${({ minwidth }) => minwidth ? minwidth : '130px'};
    position: absolute;
    top: 50px;
    z-index: 2;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_21};
    padding: 12px 10px;
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .opt_box {
      padding-bottom: 9.2px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_21};
      cursor: pointer;
      .lbl_opt {
        height: 39px;
        display: flex;
        align-items: center;
        &:hover {
          background: ${({ theme }) => theme.COLORS.BLUE_32};
        }
      }
      &:last-child {
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
  }

  .theme_popup_right {
    .body_btn {
      height: 22px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_28};
      color: ${({ theme }) => theme.COLORS.BLUE_28};
      padding: 8.2px;
    }
    .otp_show {
      right: -20px;
      top: 43px
    }
  }
`;

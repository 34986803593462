import styled from 'styled-components';

export const TableSkeletonStyled = styled.div`
  .top_skeleton {

  }
  .head_skeleton {
    
  }
  .body_skeleton {
    // margin-top: 18px;
    // padding-bottom: 24px;
    height: 150px;
  }

  .theme_most_picked {

  }
`;

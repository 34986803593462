import styled from "styled-components";

export const BoxStyled = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  .theme_standard_box {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "16px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "16px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "10px"};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
  }
  .theme_box_no_shadow {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "16px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "16px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "10px"};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.25);
  }
  .theme_standard_box_over_flow {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "16px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "16px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "10px"};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
    overflow-x: ${(props) => (props.overflowX ? props.overflowX : "hidden")};
  }
  .theme_standard_box_weeklypopular {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "16px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "16px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "10px"};
    /* box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75); */
  }
  .theme_group_check_box {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "auto")};
    overflow: auto;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #cccccc;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #c2c2c2;
    }
    ::-webkit-scrollbar-track {
      background: #ebebeb;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #e6e6e6;
    }
    ::-webkit-scrollbar-track:active {
      background: #ebebeb;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "16px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "16px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "10px"};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
  }
  .theme_standard_box_white {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "24px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "24px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "24px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "24px"};
    border-top-left-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "18px"};
    border-top-right-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "18px"};
    border-top: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
    border-left: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
    border-right: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
  }
  .theme_gray_box {
    position: relative;
    height: ${(props) => (props.height ? props.height : "auto")};
    background: ${({ theme }) => theme.COLORS.GRAY_8};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "20px")};
    padding-right: ${(props) =>
      props.paddingRight ? props.paddingRight : "20px"};
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "20px"};
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft : "20px"};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "8px"};
  }
`;

import styled from 'styled-components';

export const MiniMissionDetailStyled = styled.div`
  .container_mini {
    .top_main_mini {
      display: flex;
      margin-bottom: 42px;
      .top_main_mini_left {
        width: 150px;
        flex-shrink: 0;
      }
    }
    .bottom_main_mini {
      display: flex;
      .bottom_main_mini_left {
        width: 20%;
      }
      .bottom_main_mini_right {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .group_label {
          width: 100%;
          margin-bottom: 12px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          color: ${({ theme }) => theme.COLORS.BLUE_3};
          font-family: noto_sans_thai_regular, noto_sans_regular;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
`;

export default function MenuNutrition({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.759"
      height="27.313"
      viewBox="0 0 26.759 27.313"
    >
      <g
        id="nutrition_1_"
        data-name="nutrition (1)"
        transform="translate(-5.182)"
      >
        <path
          id="Path_19089"
          data-name="Path 19089"
          d="M16.889,99.086a.786.786,0,0,1,0-1.573H20.1c.619-3.016,3.506-4.721,5.857-3.4V80.486a.413.413,0,0,0-.412-.413H22.227a2.6,2.6,0,0,1-2.471,1.808H11.385a2.6,2.6,0,0,1-2.471-1.808H5.594a.413.413,0,0,0-.412.413V102.7a.413.413,0,0,0,.412.412h15.93a7.71,7.71,0,0,1-1.532-4.028Zm0-12.91h5.093a.786.786,0,0,1,0,1.573H16.889a.786.786,0,1,1,0-1.573Zm0,5.669h2.848a.786.786,0,1,1,0,1.573H16.889a.786.786,0,1,1,0-1.573Zm-2.653,5.017-3.026,3a.787.787,0,0,1-1.14-.035l-1.5-1.678A.786.786,0,0,1,9.745,97.1l.947,1.06,2.438-2.414a.786.786,0,1,1,1.107,1.118Zm0-5.669-3.026,3a.787.787,0,0,1-1.14-.035l-1.5-1.678a.786.786,0,1,1,1.173-1.047l.947,1.061,2.438-2.414a.786.786,0,1,1,1.107,1.118Zm0-5.669-3.026,3a.786.786,0,0,1-1.14-.035l-1.5-1.678A.786.786,0,0,1,9.745,85.76l.947,1.061,2.438-2.414a.786.786,0,1,1,1.107,1.117Z"
          transform="translate(0 -75.802)"
          fill="#fff"
        />
        <path
          id="Path_19090"
          data-name="Path 19090"
          d="M103.345,4.506h8.371a1.021,1.021,0,0,0,0-2.042h-2.178a.786.786,0,0,1-.786-.786V1.221a1.221,1.221,0,1,0-2.442,0v.457a.786.786,0,0,1-.786.786h-2.178a1.021,1.021,0,0,0,0,2.042Z"
          transform="translate(-91.96)"
          fill="#fff"
        />
        <path
          id="Path_19091"
          data-name="Path 19091"
          d="M426.727,279.321a2.716,2.716,0,0,0,.583-2.238.261.261,0,0,0-.237-.237,2.714,2.714,0,0,0-2.238.583,3.282,3.282,0,0,0-.7,2.338.263.263,0,0,0,.259.258A3.279,3.279,0,0,0,426.727,279.321Z"
          transform="translate(-396.599 -262.058)"
          fill="#fff"
        />
        <path
          id="Path_19092"
          data-name="Path 19092"
          d="M322.128,366.234a2.915,2.915,0,0,0-2.167-1.9,2.262,2.262,0,0,0-2.1,1.083.786.786,0,0,1-1.267,0,2.263,2.263,0,0,0-2.1-1.083c-1.986.251-3.908,3.635-.9,7.35a1.487,1.487,0,0,0,.9.512h5.557a1.507,1.507,0,0,0,.813-.512C322.33,369.868,322.791,367.882,322.128,366.234Z"
          transform="translate(-290.483 -344.88)"
          fill="#fff"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.837"
      height="26.313"
      viewBox="0 0 25.837 26.313"
    >
      <g id="nutrition" transform="translate(-4.617)">
        <path
          id="Path_19093"
          data-name="Path 19093"
          d="M88.2,166.268l-2.019,2-.744-.833a.771.771,0,0,0-1.15,1.027L85.57,169.9a.772.772,0,0,0,1.118.034l2.6-2.57a.771.771,0,1,0-1.085-1.1Z"
          transform="translate(-75.388 -157.512)"
        />
        <path
          id="Path_19094"
          data-name="Path 19094"
          d="M213.858,200.9h4.368a.771.771,0,0,0,0-1.542h-4.368a.771.771,0,0,0,0,1.542Z"
          transform="translate(-197.757 -189.115)"
        />
        <path
          id="Path_19095"
          data-name="Path 19095"
          d="M88.2,260.863l-2.019,2-.744-.834a.771.771,0,1,0-1.15,1.027l1.285,1.439a.771.771,0,0,0,1.118.034l2.6-2.57a.771.771,0,0,0-1.085-1.1Z"
          transform="translate(-75.388 -247.245)"
        />
        <path
          id="Path_19096"
          data-name="Path 19096"
          d="M88.2,355.458l-2.019,2-.744-.833a.771.771,0,1,0-1.15,1.027l1.285,1.439a.772.772,0,0,0,1.118.034l2.6-2.57a.771.771,0,1,0-1.085-1.1Z"
          transform="translate(-75.388 -336.979)"
        />
        <path
          id="Path_19097"
          data-name="Path 19097"
          d="M213.879,295.5h2.142a.771.771,0,1,0,0-1.542h-2.163a.771.771,0,0,0-.77.807A.785.785,0,0,0,213.879,295.5Z"
          transform="translate(-197.756 -278.853)"
        />
        <path
          id="Path_19098"
          data-name="Path 19098"
          d="M28.514,17.232a4.118,4.118,0,0,0,.768-3.822.771.771,0,0,0-.611-.611,4.535,4.535,0,0,0-3.346.393V5.462a1.8,1.8,0,0,0-1.8-1.8H20.749a2.325,2.325,0,0,0-2.189-1.55H17.434a2.493,2.493,0,0,0-4.927,0H11.382a2.324,2.324,0,0,0-2.189,1.55H6.416a1.8,1.8,0,0,0-1.8,1.8V24.513a1.8,1.8,0,0,0,1.8,1.8H27.044a2.625,2.625,0,0,0,1.751-.975C31.5,21.988,30.524,18.556,28.514,17.232Zm-.665-3a2.313,2.313,0,0,1-.462,1.948,2.824,2.824,0,0,1-2.058.572c-.089-1.978.807-2.7,2.52-2.52ZM11.267,3.663c.085-.012-.057-.008,1.982-.008a.77.77,0,0,0,.771-.771V2.492a.951.951,0,0,1,1.9,0v.392a.771.771,0,0,0,.771.771c2.039,0,1.9,0,1.982.008a.779.779,0,0,1,0,1.542c-.09.012-7.309.013-7.407,0a.779.779,0,0,1,0-1.542ZM16.1,21.51h2.565A6.716,6.716,0,0,0,19.89,24.77H6.416a.257.257,0,0,1-.257-.257V5.462A.257.257,0,0,1,6.416,5.2H9.193a2.324,2.324,0,0,0,2.189,1.55H18.56A2.325,2.325,0,0,0,20.749,5.2h2.777a.257.257,0,0,1,.257.257V16.994a3.048,3.048,0,0,0-1.755-.3,4.052,4.052,0,0,0-3.252,3.277H16.1a.771.771,0,1,0,0,1.542ZM27.6,24.367a1.2,1.2,0,0,1-.634.4H22.216a1.179,1.179,0,0,1-.7-.4c-2.524-3.12-.924-5.94.71-6.147a1.836,1.836,0,0,1,1.711.891.771.771,0,0,0,1.242,0,1.835,1.835,0,0,1,1.711-.891c1.634.207,3.234,3.027.71,6.147Z"
        />
      </g>
    </svg>
  );
}

import React, { useEffect, useState } from 'react';
import { SelectDeleteFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons, Icons } from "components";
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import theme from 'styles/theme.json';

const SelectDeleteForm = ({
  onSubmit,
  selectCount,
  totalCount,
  handleCancel,
  formType,
}) => {

  const schema = yup.object().shape({
    // name_th: yup.string().nullable().required("Name TH is required"),
    // name_en: yup.string().nullable().required("Name EN is required"),
  });

  const [_style, _setStyle] = useState(
    {
      icons: <Icons.Alert2 />,
      title: 'ลบบัญชี',
      opt1: 'จำนวนบัญชีที่เลือก',
         opt2: 'จำนวนรายชื่อเฉพาะในหน้านี้',
      // opt2: 'จำนวนบัญชีที่เลือกทั้งหมดในระบบ',
      unit: 'บัญชี',
      btn: 'ยืนยัน',
    }
  );

  useEffect(() => {
    if (formType === 'create_many_user') {
      _setStyle(
        {
          icons: <Icons.RoundAdd2 />,
          title: 'นำรายชื่อเข้าสู่ระบบ',
          opt1: 'จำนวนรายชื่อที่เลือก',
          opt2: 'จำนวนรายชื่อทั้งหมด',
          unit: 'คน',
          btn: 'นำเข้าระบบ',
        }
      )
    }
  }, [formType]);

  const {
    control,
    handleSubmit,
    // formState: { },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <SelectDeleteFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="top_del_form">
          <div className="svg_d">
            {_style.icons}
          </div>
          <div>
            {_style.title}
          </div>
        </div>
        <div className="mid_del_form">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                theme_standard_green
                {...field}
                rowGap='24px'
                options={[
                  {
                    label: (
                      <div className="line_radio">
                        <div className="width_lbl">
                          {_style.opt1}
                        </div>
                        <div className="total_count">
                          {`${RenderCommaMoney(Number(selectCount).toFixed(0))} ${_style.unit}`}
                        </div>
                      </div>
                    ),
                    value: '1',
                  },
                  {
                    label: (
                      <div className="line_radio">
                        <div className="width_lbl">
                          {_style.opt2}
                        </div>
                        <div className="total_count">
                          {`${RenderCommaMoney(Number(totalCount).toFixed(0))} ${_style.unit}`}
                        </div>
                      </div>
                    ),
                    value: '2',
                  }
                ]}
              // errors={errors.how_to_play?.message}
              />
            )}
            name={`delete_option`}
            defaultValue='1'
          />
          <div className="btn_del_layout">
            <div className="body_flex">
              <Buttons.BtnNormal
                theme_only_border
                label='ยกเลิก'
                borderRadius='8px'
                padding='11.2px 8px'
                onClick={handleCancel}
                borderColor={theme.COLORS.BLUE_28}
                fontColor={theme.COLORS.BLUE_28}
              />
            </div>
            <div className="body_flex">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label={_style.btn}
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </SelectDeleteFormStyled>
  );
};

SelectDeleteForm.propTypes = {};

export default SelectDeleteForm;

import styled from 'styled-components'

export const ShowIconWellbeingStyled = styled.div`
  .theme_show_icon {
    display: flex;
    align-items: center;
    .show_svg {
      display: flex;
      margin-right: 9px;
      width: 32px;
    }
    .group_label {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      color:  ${({ fontColor, theme }) => fontColor ? fontColor : theme.COLORS.GRAY_1};
      .sub_label {
        margin-left: 5px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color:  ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
`

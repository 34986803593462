import styled from 'styled-components';

export const InputCheckBox2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_standard_input_check_box_square {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_3};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 20px;
      max-width: 20px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme, borderColor }) => borderColor ? borderColor : theme.COLORS.GREEN_1};
      border-radius: 4px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid ${({ theme, borderColorActive }) => borderColorActive ? borderColorActive : theme.COLORS.GREEN_1};
      background: ${({ theme, bgColor }) => bgColor ? bgColor : theme.COLORS.GREEN_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 7px;
      top: 3px;
      width: 3px;
      height: 8px;
      border: solid #ffff;
      border-width: 0 3px 3px 0;
      background:${({ theme, bgColor }) => bgColor ? bgColor : theme.COLORS.GREEN_1};
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_standard_input_check_box_round_2 {
    display: flex;
    flex-wrap: wrap;
    // font-size: ${({ theme, fontSize }) => fontSize ? fontSize : theme.FONT.SIZE.S16};
    // font-family: ${({ fontFamily }) => fontFamily ? fontFamily : ({ theme }) => theme.FONT.STYLE.REGULAR};
    // color:  ${({ theme, fontColor }) => fontColor ? fontColor : 'pink'};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      background: transparent;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_23};
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 2.9px;
      left: 2.9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background:  ${({ theme }) => theme.COLORS.PURPLE_1}; 
      
      // border: solid ${({ theme }) => theme.COLORS.PINK_2}; 
      // border-width: 0 3px 3px 0;
      // -webkit-transform: rotate(45deg);
      // -ms-transform: rotate(45deg);
      // transform: rotate(45deg);
    }
  }
`;

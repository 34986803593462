import TableRank from './TableRank';
import TableMostPicked from './TableMostPicked';
import TableRole from './TableRole';
import TableNormal from './TableNormal';
import Content from './Content';
import TableAction from './TableAction';
import TableAction2 from './TableAction2';
import TableUserRankGroup from './TableUserRankGroup';
import TableRank3 from './TableRank3';
import TableActionAllClick from './TableActionAllClick';

const Export = {
  TableActionAllClick,
  TableAction,
  TableAction2,
  TableNormal,
  TableRank,
  TableMostPicked,
  TableRole,
  Content,
  TableUserRankGroup,
  TableRank3,
};

export default Export;

import React from 'react';
import { connect } from 'react-redux';
import { HealthyClubContainerStyled } from './styled';
// import { HeadContent } from 'widgets';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { adminService } from 'apiServices';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from "utils/constants";
import { Icons, Loading, Tabs, Blocks, TableCustom, Buttons, Modals } from 'components'
import { getObjectKey } from "utils/functions/getObjectKey";
// import moment from 'moment';
// import 'moment-timezone';
// import { RENDER_MONTH } from 'utils/functions/date';

class HealthyClubContainer extends React.Component {
  state = { isLoading: true, isShowModal: false, selectedData: [] };

  componentDidMount() {
    this.fetchData(`?per_page=10&offset=0`);
    this.scrollToTop();
    this.checkPermission()
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0`);
      this.checkPermission()
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'group_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async (stringQuery) => {
    const { companySelectedRedux } = this.props;
    let res = await adminService.GET_GROUPS_LIST(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
        isLoading: false,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleBackClick = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE}`);
  };

  handleClickDetail = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_PREVIEW_DETAIL}/${e.id}`);

  };

  handleClickCreateGroup = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_DETAIL}/create`);
  };

  handlePageClick = (e) => {
    let query = `?per_page=10&offset=${e.selected * 10}`;
    this.fetchData(query);
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };
  handleConfirmDelete = () => {
    this.setState({
      isShowModal: false,
      isLoading: true,
    });
    const { selectedData } = this.state;
    if (selectedData && selectedData.length > 0) {
      Promise.all(
        selectedData.map((e) => {
          return new Promise(async (resolve) => {
            let res = await adminService.DELETE_GROUP_BY_ID(e);
            if (res && res.success) {
              resolve();
            }
          });
        })
      ).then(() => {
        toast.success('ลบสำเร็จ');
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.fetchData();
          }
        );
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  renderStatus = (e) => {
    switch (e) {
      case 'closed':
        return (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            {<Icons.StatusPrivate />}
            <div style={{ marginLeft: '9px' }}>{` Close Group`}</div>
          </div>
        );

      case 'open':
      case 'opend':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {<Icons.StatusPublic />}
            <div style={{ marginLeft: '9px' }}>{` Open Group`}</div>
          </div>
        );

      default:
        return '-'
    }
  };

  render() {
    const { data, pagination, isLoading, isShowModal, selectedData, perMissions } = this.state;

    return (
      <HealthyClubContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <Blocks.Container theme_con_no_border_radius >
            <div className="manage_container">
              <div className="head_wrap_layout">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="จัดการกลุ่ม"
                  subTitle="Group Management"
                  handleBackCustom={this.handleBackClick}
                />
              </div>
              {
                perMissions &&
                <div className='btn_action_zone'>
                  <div className='btn_225'>
                    <Buttons.BtnNormal
                      theme_gray
                      label={
                        <div className='g_label'>
                          <div>
                            สร้างกลุ่ม
                          </div>
                          <div className='sm_text'>
                            Create new group
                          </div>
                        </div>
                      }
                      onClick={this.handleClickCreateGroup}
                    />
                  </div>
                  <div className='btn_88'>
                    <Buttons.BtnNormal
                      theme_only_border_blue
                      label="ลบ"
                      disabled={!(selectedData && selectedData.length > 0)}
                      svg_front={
                        (selectedData && selectedData.length > 0)
                          ?
                          <Icons.Delete3
                            width='20'
                            height='20'
                            color='#002366'
                            color2='#B6E7E8'
                          />
                          :
                          <Icons.Delete3
                            width='20'
                            height='20'
                            color='#989898'
                            color2='#F3F3F3'
                          />
                      }
                      onClick={this.handlePopup}
                    />
                  </div>
                </div>
              }
              <div className="block_2_col_layout">
                <TableCustom.TableAction2
                  theme_standard_table_user
                  columns={columns({
                    handleClickDetail: this.handleClickDetail,
                    renderStatus: this.renderStatus,
                    perMissions: perMissions,
                  })}
                  data={data}
                  isCheckbox={perMissions ? true : false}
                  pagination={pagination}
                  labelEmpty="ยังไม่มีข้อมูล"
                  isRound
                  showPagination
                  handlePageClick={this.handlePageClick}
                  onChangeSelected={this.handleChangeSelectedTable}
                />
              </div>
            </div>
          </Blocks.Container>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <Blocks.BoxDelete
            theme_box_delete
            maxWidth='374px'
            minWidth='374px'
            textShow="ยืนยันการลบกลุ่มหรือไม่"
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
          />
        </Modals.Standard>
      </HealthyClubContainerStyled>
    );
  }
}


const columns = ({ handleClickDetail, perMissions }) => {
  let tempColumn = []

  if (perMissions) {
    tempColumn.push(
      {
        title: "",
        field: "",
        headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
        style: { textAlign: "center", width: "20px", flexShrink: 0 },
      },
    )
  } else {
    tempColumn.push(
      {
        title: "",
        field: "",
        headerStyle: { textAlign: "center", width: "0px", flexShrink: 0, padding: '0px' },
        style: { textAlign: "center", width: "0px", flexShrink: 0, padding: '0px' },
      },
    )
  }

  tempColumn.push(
    {
      title: 'ชื่อกลุ่ม',
      field: 'name',
      headerStyle: { textAlign: 'left', minWidth: '10px', },
      style: { textAlign: 'left', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          style={{
            overflow: 'hidden',
            minWidth: '10px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          onClick={() => handleClickDetail(origin)}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'จำนวนผู้เข้าร่วม',
      field: 'users_count',
      headerStyle: { textAlign: 'left', width: '145px', flexShrink: 0 },
      style: { textAlign: 'left', width: '145px', flexShrink: 0 },
      render: ({ origin, value }) => (
        <div
          onClick={() => handleClickDetail(origin)}
          style={{ textAlign: 'left', maxWidth: '120px' }}
        >
          {`${RenderCommaMoney(Number(value).toFixed(0), true)} คน`}
        </div>
      ),
    },
    {
      title: 'จำนวนโพสต์ต่อเดือน',
      field: 'posts_count_monthly_avg',
      headerStyle: { textAlign: 'left', width: '145px', flexShrink: 0 },
      style: { textAlign: 'left', width: '145px', flexShrink: 0 },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>
          {`${RenderCommaMoney(Number(value).toFixed(0))} โพส`}
        </div>
      ),
    },
  )
  return tempColumn
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthyClubContainer);
import React from 'react';
import { BackOfficeContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { Typographys, Cards, Icons, Loading } from 'components';
import { ROUTE_PATH } from 'utils/constants';
import { connect } from 'react-redux';

class BackOfficeContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickMenu = (e) => {
    this.props.history.push(e);
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <BackOfficeContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="top_title">
              <Typographys.LabelSubVertical
                theme_standard
                title="จัดการหลังบ้าน"
                subTitle="Back office"
              />
            </div>
            <div className="card_container">
              <div
                className="card_wrap"
                onClick={() =>
                  this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_ACCOUNT)
                }
              >
                <Cards.BoxIconTitleSub
                  title="จัดการบัญชีผู้ดูแลระบบ"
                  subTitle="Account Management"
                  svg={<Icons.AccountManagement />}
                />
              </div>
            </div>
          </>
        )}
      </BackOfficeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfficeContainer);

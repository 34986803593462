export default function ManBad() {
  return (
    <svg id="Group_15115" data-name="Group 15115" xmlns="http://www.w3.org/2000/svg" width="37.405" height="43.143" viewBox="0 0 37.405 43.143">
      <g id="Group_404" data-name="Group 404" transform="translate(0 0)">
        <g id="Group_373" data-name="Group 373" transform="translate(6.729)">
          <path id="Path_231" data-name="Path 231" d="M565.343,113.338a23.658,23.658,0,0,0-1.808-1.784c-.654-.59-1.349-1.138-2.052-1.666a25.406,25.406,0,0,0-4.476-2.626,19.345,19.345,0,0,0-4.758-1.51,18.956,18.956,0,0,0-4.809-.257l-.237.009a3.838,3.838,0,1,1-.28-7.67,4.125,4.125,0,0,1,.423.008A26.261,26.261,0,0,1,554,99.029a25.975,25.975,0,0,1,5.979,2.743,31.329,31.329,0,0,1,5.075,3.885c.761.731,1.5,1.48,2.188,2.275.346.4.686.8,1.013,1.217s.629.818,1,1.349a2.41,2.41,0,0,1-3.766,2.99Z" transform="translate(-543.227 -97.832)" fill="#010101" />
        </g>
        <g id="Group_374" data-name="Group 374" transform="translate(19.715 0.742)">
          <path id="Path_232" data-name="Path 232" d="M567.279,120.83c.02-.276.05-.747.049-1.141.006-.415-.008-.832-.035-1.253a20.485,20.485,0,0,0-.307-2.49,16.449,16.449,0,0,0-1.607-4.6,16.014,16.014,0,0,0-1.257-2.008,14.933,14.933,0,0,0-1.59-1.724,16.63,16.63,0,0,0-3.882-2.676l-.213-.117a3.312,3.312,0,1,1,3.192-5.8c.063.034.131.075.191.113a22.944,22.944,0,0,1,5.146,4.2,21,21,0,0,1,2.054,2.643,21.955,21.955,0,0,1,3.183,9.216,25.714,25.714,0,0,1,.139,3.183c-.009.53-.035,1.061-.085,1.595-.05.553-.1,1.03-.223,1.693a2.408,2.408,0,0,1-4.774-.586Z" transform="translate(-556.721 -98.602)" fill="#010101" />
        </g>
        <g id="Group_375" data-name="Group 375" transform="translate(3.951 4.636)">
          <path id="Path_233" data-name="Path 233" d="M555.8,102.649h0a15.46,15.46,0,0,1,15.46,15.46v6.849a.163.163,0,0,1-.164.164H540.5a.163.163,0,0,1-.163-.164v-6.849A15.46,15.46,0,0,1,555.8,102.649Z" transform="translate(-540.34 -102.649)" fill="#010101" />
        </g>
        <g id="Group_376" data-name="Group 376" transform="translate(5.803 8.307)">
          <path id="Path_234" data-name="Path 234" d="M555.873,141.3h0a13.607,13.607,0,0,0,13.607-13.608v-7.621a13.607,13.607,0,0,0-13.607-13.608h0a13.607,13.607,0,0,0-13.608,13.608v7.621A13.607,13.607,0,0,0,555.873,141.3Z" transform="translate(-542.265 -106.464)" fill="#ffdac3" />
        </g>
        <g id="Group_387" data-name="Group 387" transform="translate(13.085 22.465)">
          <g id="Group_378" data-name="Group 378" transform="translate(0.757 2.76)">
            <g id="Group_377" data-name="Group 377">
              <path id="Path_235" data-name="Path 235" d="M551.857,124.942q0-.152-.009-.3c-.022-.79-1.253-.793-1.23,0q0,.152.009.3a.615.615,0,0,0,1.23,0Z" transform="translate(-550.619 -119.045)" fill="#010101" />
            </g>
          </g>
          <g id="Group_380" data-name="Group 380" transform="translate(10.324 2.823)">
            <g id="Group_379" data-name="Group 379">
              <path id="Path_236" data-name="Path 236" d="M561.8,125.007q0-.152-.009-.3a.616.616,0,0,0-1.231,0l.009.3a.616.616,0,0,0,1.231,0Z" transform="translate(-560.561 -119.11)" fill="#010101" />
            </g>
          </g>
          <g id="Group_382" data-name="Group 382" transform="translate(4.925)">
            <g id="Group_381" data-name="Group 381">
              <path id="Path_237" data-name="Path 237" d="M556.248,121.47l.3,2.975c.056.554.581,4.159-1.143,3.076-.337-.211-.646.321-.311.531a1.4,1.4,0,0,0,2.11-.863,6.078,6.078,0,0,0-.018-2.516l-.323-3.2c-.039-.39-.655-.394-.615,0Z" transform="translate(-554.95 -116.177)" fill="#010101" />
            </g>
          </g>
          <g id="Group_383" data-name="Group 383" transform="translate(9.525 0.298)">
            <path id="Path_238" data-name="Path 238" d="M562.34,123.149a.484.484,0,0,1-.15-.024l-2.135-.713a.475.475,0,0,1,.3-.9l2.135.712a.475.475,0,0,1-.151.926Z" transform="translate(-559.73 -116.487)" fill="#010101" />
          </g>
          <g id="Group_384" data-name="Group 384" transform="translate(0 0.294)">
            <path id="Path_239" data-name="Path 239" d="M550.307,123.153a.475.475,0,0,1-.151-.926l2.133-.719a.475.475,0,1,1,.3.9l-2.133.72A.482.482,0,0,1,550.307,123.153Z" transform="translate(-549.832 -116.482)" fill="#010101" />
          </g>
          <g id="Group_386" data-name="Group 386" transform="translate(3.981 8.623)">
            <g id="Group_385" data-name="Group 385">
              <path id="Path_240" data-name="Path 240" d="M555.656,132.066a1.673,1.673,0,0,1,2.888-.131c.378.554,1.286.031.9-.527a2.734,2.734,0,0,0-4.7.131.524.524,0,0,0,.9.527Z" transform="translate(-554.676 -125.138)" fill="#010101" />
            </g>
          </g>
        </g>
        <g id="Group_388" data-name="Group 388" transform="translate(30.072 17.051)">
          <path id="Path_241" data-name="Path 241" d="M570.653,121.734h-.232a2.936,2.936,0,0,1-2.936-2.936v-3.247h3.177Z" transform="translate(-567.485 -115.551)" fill="#010101" />
        </g>
        <g id="Group_389" data-name="Group 389" transform="translate(5.576 17.051)">
          <path id="Path_242" data-name="Path 242" d="M542.029,121.734h.469a2.936,2.936,0,0,0,2.936-2.936v-3.247h-3.177Z" transform="translate(-542.029 -115.551)" fill="#010101" />
        </g>
        <g id="Group_402" data-name="Group 402" transform="translate(1.416 23.092)">
          <g id="Group_390" data-name="Group 390" transform="translate(29.148)">
            <path id="Path_243" data-name="Path 243" d="M574.837,125.249a3.42,3.42,0,1,1-3.42-3.42A3.42,3.42,0,0,1,574.837,125.249Z" transform="translate(-567.996 -121.829)" fill="#ffdac3" />
          </g>
          <g id="Group_391" data-name="Group 391">
            <path id="Path_244" data-name="Path 244" d="M544.547,125.249a3.42,3.42,0,1,1-3.42-3.42A3.42,3.42,0,0,1,544.547,125.249Z" transform="translate(-537.706 -121.829)" fill="#ffdac3" />
          </g>
          <g id="Group_396" data-name="Group 396" transform="translate(1.9 2.341)">
            <g id="Group_393" data-name="Group 393">
              <g id="Group_392" data-name="Group 392">
                <path id="Path_245" data-name="Path 245" d="M542.559,125.788a1.845,1.845,0,0,0-2.725-1.3c-.35.185-.039.716.311.531a1.229,1.229,0,0,1,1.822.929c.067.39.66.224.593-.164Z" transform="translate(-539.68 -124.262)" fill="#010101" />
              </g>
            </g>
            <g id="Group_395" data-name="Group 395" transform="translate(0.712 1.129)">
              <g id="Group_394" data-name="Group 394">
                <path id="Path_246" data-name="Path 246" d="M542.436,125.711a1.658,1.658,0,0,0-1.88.034c-.32.226-.013.76.311.531a1.06,1.06,0,0,1,1.259-.034c.334.216.643-.317.311-.531Z" transform="translate(-540.42 -125.436)" fill="#010101" />
              </g>
            </g>
          </g>
          <g id="Group_401" data-name="Group 401" transform="translate(31.29 2.341)">
            <g id="Group_398" data-name="Group 398">
              <g id="Group_397" data-name="Group 397">
                <path id="Path_247" data-name="Path 247" d="M570.821,125.952a1.228,1.228,0,0,1,1.822-.929c.35.185.661-.346.311-.531a1.846,1.846,0,0,0-2.726,1.3c-.067.388.525.553.594.164Z" transform="translate(-570.222 -124.262)" fill="#010101" />
              </g>
            </g>
            <g id="Group_400" data-name="Group 400" transform="translate(0.015 1.129)">
              <g id="Group_399" data-name="Group 399">
                <path id="Path_248" data-name="Path 248" d="M570.69,126.242a1.061,1.061,0,0,1,1.26.034c.323.229.631-.3.311-.531a1.659,1.659,0,0,0-1.88-.034c-.332.215-.024.747.31.531Z" transform="translate(-570.238 -125.436)" fill="#010101" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_403" data-name="Group 403" transform="translate(0 4.062)">
          <path id="Path_249" data-name="Path 249" d="M566.238,118.948c-.019.01-.067-.117-.109-.21s-.091-.2-.143-.308a5.852,5.852,0,0,0-.334-.634,4.586,4.586,0,0,0-.386-.614,5.423,5.423,0,0,0-.423-.584l-.46-.546c-.153-.178-.345-.326-.508-.49a7.581,7.581,0,0,0-1.076-.861,10.588,10.588,0,0,0-5.17-1.71,18.064,18.064,0,0,0-2.927-.027c-.485,0-1.031.138-1.534.2-.516.086-1.009.117-1.53.236-1.058.282-2.081.483-3.125.8l-3.135,1.062-.3.1a6.7,6.7,0,0,1-4.3-12.682,6.929,6.929,0,0,1,1.727-.341l4.277-.269c1.44-.041,2.93.042,4.369.075.729.037,1.494.164,2.232.247.75.114,1.468.153,2.226.337a27.019,27.019,0,0,1,4.472,1.3,18.709,18.709,0,0,1,7.861,5.463,14.453,14.453,0,0,1,1.394,1.972c.2.348.419.68.59,1.038l.489,1.081a10.718,10.718,0,0,1,.407,1.1,9.275,9.275,0,0,1,.327,1.127,10.98,10.98,0,0,1,.241,1.158c.033.2.067.386.089.6s.047.394.07.714a2.679,2.679,0,1,1-5.317.664Z" transform="translate(-536.234 -102.053)" fill="#010101" />
        </g>
      </g>
    </svg>
  );
}

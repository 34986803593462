export default function BuildingCircle({ color = '#880a1f' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_33150"
        data-name="Group 33150"
        transform="translate(-944 -1040)"
      >
        <g
          id="Ellipse_2201"
          data-name="Ellipse 2201"
          transform="translate(944 1040)"
          fill="#fff"
          stroke={color}
          strokeWidth="1"
        >
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="19.5" fill="none" />
        </g>
        <path
          id="Path_37082"
          data-name="Path 37082"
          d="M22.583,24.167h-1.25V2.3A1.465,1.465,0,0,0,19.875.833H18.833V.417A.417.417,0,0,0,18.417,0H7.583a.417.417,0,0,0-.417.417V.833H6.125A1.465,1.465,0,0,0,4.667,2.3V24.167H3.417a.417.417,0,0,0,0,.833h7.5a.417.417,0,0,0,.417-.417V17.5h3.333v7.083a.417.417,0,0,0,.417.417h7.5a.417.417,0,1,0,0-.833ZM9.667,20.417a.417.417,0,0,1-.417.417H6.75a.417.417,0,0,1-.417-.417V17.083a.417.417,0,0,1,.417-.417h2.5a.417.417,0,0,1,.417.417Zm0-6.667a.417.417,0,0,1-.417.417H6.75a.417.417,0,0,1-.417-.417V10.417A.417.417,0,0,1,6.75,10h2.5a.417.417,0,0,1,.417.417Zm0-6.667A.417.417,0,0,1,9.25,7.5H6.75a.417.417,0,0,1-.417-.417V3.75a.417.417,0,0,1,.417-.417h2.5a.417.417,0,0,1,.417.417Zm5,6.667a.417.417,0,0,1-.417.417h-2.5a.417.417,0,0,1-.417-.417V10.417A.417.417,0,0,1,11.75,10h2.5a.417.417,0,0,1,.417.417Zm0-6.667a.417.417,0,0,1-.417.417h-2.5a.417.417,0,0,1-.417-.417V3.75a.417.417,0,0,1,.417-.417h2.5a.417.417,0,0,1,.417.417Zm5,13.333a.417.417,0,0,1-.417.417h-2.5a.417.417,0,0,1-.417-.417V17.083a.417.417,0,0,1,.417-.417h2.5a.417.417,0,0,1,.417.417Zm0-6.667a.417.417,0,0,1-.417.417h-2.5a.417.417,0,0,1-.417-.417V10.417A.417.417,0,0,1,16.75,10h2.5a.417.417,0,0,1,.417.417Zm0-6.667a.417.417,0,0,1-.417.417h-2.5a.417.417,0,0,1-.417-.417V3.75a.417.417,0,0,1,.417-.417h2.5a.417.417,0,0,1,.417.417Z"
          transform="translate(951 1048)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default function Calendar2() {
  return (
    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g id="Group_32824" data-name="Group 32824" transform="translate(0 0)">
        <g id="Group_32819" data-name="Group 32819" transform="translate(0 4.667)">
          <path id="Path_49345" data-name="Path 49345" d="M64,13.167v8.167H8V13.167A1.17,1.17,0,0,1,9.167,12H62.833A1.17,1.17,0,0,1,64,13.167Z" transform="translate(-8 -12)" fill="#e7284a" />
        </g>
        <g id="Group_32820" data-name="Group 32820" transform="translate(21)">
          <path id="Path_49346" data-name="Path 49346" d="M27.167,17.333A1.167,1.167,0,0,1,26,16.167v-7a1.167,1.167,0,1,1,2.333,0v7A1.167,1.167,0,0,1,27.167,17.333Z" transform="translate(-26 -8)" fill="#00143c" />
        </g>
        <g id="Group_32821" data-name="Group 32821" transform="translate(9.333)">
          <path id="Path_49347" data-name="Path 49347" d="M17.167,17.333A1.167,1.167,0,0,1,16,16.167v-7a1.167,1.167,0,0,1,2.333,0v7A1.167,1.167,0,0,1,17.167,17.333Z" transform="translate(-16 -8)" fill="#00143c" />
        </g>
        <g id="Group_32822" data-name="Group 32822" transform="translate(44.333)">
          <path id="Path_49348" data-name="Path 49348" d="M47.167,17.333A1.167,1.167,0,0,1,46,16.167v-7a1.167,1.167,0,1,1,2.333,0v7A1.167,1.167,0,0,1,47.167,17.333Z" transform="translate(-46 -8)" fill="#00143c" />
        </g>
        <g id="Group_32823" data-name="Group 32823" transform="translate(32.667)">
          <path id="Path_49349" data-name="Path 49349" d="M37.167,17.333A1.167,1.167,0,0,1,36,16.167v-7a1.167,1.167,0,0,1,2.333,0v7A1.167,1.167,0,0,1,37.167,17.333Z" transform="translate(-36 -8)" fill="#00143c" />
        </g>
      </g>
      <g id="Group_32825" data-name="Group 32825" transform="translate(0 14)">
        <path id="Path_49350" data-name="Path 49350" d="M64,20V50.333a2.34,2.34,0,0,1-2.333,2.333H10.333A2.34,2.34,0,0,1,8,50.333V20Z" transform="translate(-8 -20)" fill="#f0f0f0" />
      </g>
      <g id="Group_32828" data-name="Group 32828" transform="translate(7 17.052)">
        <g id="Group_32826" data-name="Group 32826" transform="translate(11.903)">
          <path id="Path_49351" data-name="Path 49351" d="M30.176,36.722a1.165,1.165,0,0,0,1.65,0L42.291,26.257a1.165,1.165,0,0,0,0-1.65l-1.65-1.65a1.165,1.165,0,0,0-1.65,0L31,30.947l-3.158-3.158a1.165,1.165,0,0,0-1.65,0l-1.65,1.65a1.165,1.165,0,0,0,0,1.65c3.3,3.306,2.6,2.6,5.632,5.634Z" transform="translate(-24.203 -22.616)" fill="#d2d2d2" />
        </g>
        <g id="Group_32827" data-name="Group 32827" transform="translate(0 16.782)">
          <path id="Path_49352" data-name="Path 49352" d="M56,38.167V49.833H17.768a3.46,3.46,0,0,0,.9-2.333,3.5,3.5,0,0,0-3.5-3.5A1.17,1.17,0,0,1,14,42.833V38.167A1.17,1.17,0,0,1,15.167,37H54.833A1.17,1.17,0,0,1,56,38.167Z" transform="translate(-14 -37)" fill="#d2d2d2" />
        </g>
      </g>
      <g id="Group_32831" data-name="Group 32831" transform="translate(18.902 18.218)">
        <g id="Group_32829" data-name="Group 32829" transform="translate(0 4.832)">
          <path id="Path_49353" data-name="Path 49353" d="M30.177,37.032,24.544,31.4a1.165,1.165,0,0,1,0-1.65l1.65-1.65a1.165,1.165,0,0,1,1.65,0L34.3,34.559l-2.475,2.475a1.168,1.168,0,0,1-1.651,0Z" transform="translate(-24.202 -27.758)" fill="#fd921e" />
        </g>
        <g id="Group_32830" data-name="Group 32830" transform="translate(3.5)">
          <path id="Path_49354" data-name="Path 49354" d="M27.2,35.248l11.29-11.29a1.165,1.165,0,0,1,1.65,0l1.65,1.65a1.165,1.165,0,0,1,0,1.65L31.326,37.722a1.165,1.165,0,0,1-1.65,0Z" transform="translate(-27.202 -23.616)" fill="#fdc566" />
        </g>
      </g>
      <g id="Group_32846" data-name="Group 32846" transform="translate(7 35)">
        <g id="Group_32832" data-name="Group 32832">
          <path id="Path_49355" data-name="Path 49355" d="M56,39.167V57.833A1.167,1.167,0,0,1,54.833,59H15.167A1.167,1.167,0,0,1,14,57.833V53.167A1.167,1.167,0,0,1,15.167,52a3.5,3.5,0,1,0,0-7A1.167,1.167,0,0,1,14,43.833V39.167A1.167,1.167,0,0,1,15.167,38H54.833A1.167,1.167,0,0,1,56,39.167Z" transform="translate(-14 -38)" fill="#00a0c8" />
        </g>
        <g id="Group_32841" data-name="Group 32841" transform="translate(21 4.667)">
          <g id="Group_32834" data-name="Group 32834" transform="translate(2.333 1.166)">
            <g id="Group_32833" data-name="Group 32833">
              <path id="Path_49356" data-name="Path 49356" d="M34.342,51.992a1.165,1.165,0,0,1,0-1.65l7-7a1.166,1.166,0,0,1,1.65,1.65l-7,7A1.165,1.165,0,0,1,34.342,51.992Z" transform="translate(-34 -43)" fill="#00143c" />
            </g>
          </g>
          <g id="Group_32837" data-name="Group 32837">
            <g id="Group_32836" data-name="Group 32836">
              <g id="Group_32835" data-name="Group 32835">
                <path id="Path_49357" data-name="Path 49357" d="M34.333,42a2.333,2.333,0,1,0,2.333,2.333A2.34,2.34,0,0,0,34.333,42Zm0,2.917a.583.583,0,1,1,.583-.583A.578.578,0,0,1,34.333,44.917Z" transform="translate(-32 -42)" fill="#00143c" />
              </g>
            </g>
          </g>
          <g id="Group_32840" data-name="Group 32840" transform="translate(9.333 7)">
            <g id="Group_32839" data-name="Group 32839">
              <g id="Group_32838" data-name="Group 32838">
                <path id="Path_49358" data-name="Path 49358" d="M42.333,48a2.333,2.333,0,1,0,2.333,2.333A2.34,2.34,0,0,0,42.333,48Zm0,2.917a.583.583,0,1,1,.583-.583A.578.578,0,0,1,42.333,50.917Z" transform="translate(-40 -48)" fill="#00143c" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_32842" data-name="Group 32842" transform="translate(14)">
          <path id="Path_49359" data-name="Path 49359" d="M26,38h2.333V59H26Z" transform="translate(-26 -38)" fill="#00143c" />
        </g>
        <g id="Group_32843" data-name="Group 32843" transform="translate(7 4.667)">
          <path id="Path_49360" data-name="Path 49360" d="M23.5,44.333H21.167a1.167,1.167,0,1,1,0-2.333H23.5a1.167,1.167,0,1,1,0,2.333Z" transform="translate(-20 -42)" fill="#00143c" />
        </g>
        <g id="Group_32844" data-name="Group 32844" transform="translate(7 9.333)">
          <path id="Path_49361" data-name="Path 49361" d="M23.5,48.333H21.167a1.167,1.167,0,1,1,0-2.333H23.5a1.167,1.167,0,1,1,0,2.333Z" transform="translate(-20 -46)" fill="#00143c" />
        </g>
        <g id="Group_32845" data-name="Group 32845" transform="translate(7 14)">
          <path id="Path_49362" data-name="Path 49362" d="M23.5,52.333H21.167a1.167,1.167,0,1,1,0-2.333H23.5a1.167,1.167,0,1,1,0,2.333Z" transform="translate(-20 -50)" fill="#00143c" />
        </g>
      </g>
    </svg>
  );
}

import { ExportRewardPointStyled } from "./styled";
import { Inputs, Buttons, Icons } from "components";
import React, { useState } from "react";
import moment from "moment";

const ExportRewardPoint = ({ onDownload, minDate, rangeDate }) => {
  const _minDate = new Date(
    moment()
      .tz("Asia/Bangkok")
      .subtract(10, "years")
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const _maxDate = new Date(
    moment().tz("Asia/Bangkok").endOf("month").format("YYYY-MM-DD")
  );
  const [startDate, _setStartDate] = useState(
    moment().tz("Asia/Bangkok").startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, _setEndDate] = useState(
    moment().tz("Asia/Bangkok").endOf("month").format("YYYY-MM-DD")
  );

  const handleDownlaod = () => {
    onDownload &&
      onDownload({
        begin_date: moment(startDate)
          .tz("Asia/Bangkok")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date: moment(endDate)
          .tz("Asia/Bangkok")
          .endOf("month")
          .format("YYYY-MM-DD"),
      });
  };

  const handleChangDate = (e, isStart) => {
    let tempDateStart;
    let tempDateEnd;
    if (isStart) {
      tempDateStart = moment(e).tz("Asia/Bangkok").startOf("month");
      tempDateEnd = moment(e)
        .tz("Asia/Bangkok")
        .endOf("month")
        .format("YYYY-MM-DD");
      const maxDateRang = moment(e)
        .tz("Asia/Bangkok")
        .add(rangeDate, "months")
        .startOf("month")
        .isSameOrBefore(moment(_maxDate))
        ? moment(e).tz("Asia/Bangkok").add(rangeDate, "months").endOf("month")
        : moment(_maxDate).tz("Asia/Bangkok").endOf("month");
      _setStartDate(tempDateStart.format("YYYY-MM-DD"));
      _setEndDate(
        moment(e)
          .tz("Asia/Bangkok")
          .startOf("month")
          .isSameOrAfter(moment(endDate))
          ? moment(e).format("YYYY-MM-DD")
          : moment(endDate).isSameOrBefore(maxDateRang)
          ? moment(endDate).format("YYYY-MM-DD")
          : moment(maxDateRang).format("YYYY-MM-DD")
      );
    } else {
      tempDateEnd = moment(e)
        .tz("Asia/Bangkok")
        .endOf("month")
        .format("YYYY-MM-DD");
      const minDateRang = moment(e)
        .tz("Asia/Bangkok")
        .subtract(rangeDate, "months")
        .startOf("month")
        .isSameOrAfter(moment(_minDate))
        ? moment(e)
            .tz("Asia/Bangkok")
            .subtract(rangeDate, "months")
            .startOf("month")
        : moment(_minDate).tz("Asia/Bangkok").startOf("month");
      _setEndDate(tempDateEnd);
      _setStartDate(
        moment(e)
          .tz("Asia/Bangkok")
          .startOf("month")
          .isSameOrBefore(moment(startDate))
          ? moment(e).format("YYYY-MM-DD")
          : moment(startDate).isSameOrAfter(minDateRang)
          ? moment(startDate).format("YYYY-MM-DD")
          : moment(minDateRang).format("YYYY-MM-DD")
      );
    }
  };

  return (
    <ExportRewardPointStyled>
      <div className="detail_show">
        <div className="filter_download">
          <div className="label_to">เดือน</div>
          <div className="w190">
            <Inputs.InputDatePikerCustom2
              theme_standard_date_picker_custom
              dateFormat="MMMM yyyy"
              onChangeCustom={(e) => handleChangDate(e, true)}
              showMonthYearPicker
              showFullMonthYearPicker
              initialValues={new Date(startDate)}
              minDate={_minDate}
              maxDate={_maxDate}
            />
          </div>
          <div className="label_to">ถึง</div>
          <div className="w190">
            <Inputs.InputDatePikerCustom2
              theme_standard_date_picker_custom
              dateFormat="MMMM yyyy"
              onChangeCustom={(e) => handleChangDate(e, false)}
              showMonthYearPicker
              showFullMonthYearPicker
              initialValues={new Date(endDate)}
              minDate={_minDate}
              maxDate={_maxDate}
            />
          </div>
          <div className="w190">
            <Buttons.BtnNormal
              theme_standard_btn_normal_green
              label="Download"
              backgroundColor="#5D639A"
              fontSize="16px"
              borderRadius="8px"
              svg_front={<Icons.Download3 color="#FFFFFF" />}
              onClick={handleDownlaod}
            />
          </div>
        </div>
      </div>
    </ExportRewardPointStyled>
  );
};

export default ExportRewardPoint;

import styled from 'styled-components';
// import { COLOR, FONT, SCREEN } from 'assets';

export const InputSuggestHealthyClubStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  width: 100%;
  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }
  .react-autosuggest__input {
    // width: calc(100% - 42px);
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    padding: 12px 20px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    ::placeholder {
      color: #989898;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }
  .react-autosuggest__input--focused {
    outline: none;
  }
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 43px;
    width: 100%;
    border: 1px solid #A2A2A2;
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 200px;
    overflow-y: scroll;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-start;
  }
  .react-autosuggest__suggestion--highlighted {
    // background-color: gray;
  }

  .input_layout {
    width: 100%;
    position: relative;
    .box_suggest {
      display: flex;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      border-radius: 8px;
      column-gap: 41px;
      .label_bottom {
        // padding: 0px 24px;
        // flex: 1;
        background: #A8A8A83B;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        flex-shrink: 0;
        width: 136px;
      }
    }
  }
  .button {
    cursor: pointer;
    font-size: 16px;
    color: yellow;
    padding: 10px 30px;
    border: 1px solid gray;
    font-weight: 600;
    border-radius: 5px;
    background: blue;
    &.disabled {
      background: gray;
      color: blue;
      cursor: not-allowed;
    }
  }
  .error {
    position: absolute;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    margin-top: 5px;
    color: red;
 }

  @media (max-width: ${({ theme }) => theme.SCREENS.IPAD}) {
    .react-autosuggest__container {
      // width: 84%;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {

  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {

  }

  // @media(max-width: 1170px){
  //   font-size: 16px;
  //   .react-autosuggest__suggestions-container--open {
  //     width: 100%;
  //   }
  // }
`;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { TableMostPickedStyled } from './styled';
import {} from 'components';

const TableMostPicked = ({ theme_standard_table_most_pick, data }) => {
  const customClass = cx({
    theme_standard_table_most_pick: theme_standard_table_most_pick,
  });

  return (
    <TableMostPickedStyled>
      <div className={customClass}>
        {data &&
          data.map((e, i) => (
            <div key={i + 1} className="row_pick">
              <div className="col_left">
                <div className={`col_order ${i === 0 && `red`}`}>
                  {`${i + 1}.`}
                </div>
                <div className={`col_detail ${i === 0 && `red`}`}>
                  <div className={`label ${i === 0 && `red`}`}>{e.label}</div>
                  <div className="sub_label">{e.subLabel}</div>
                </div>
              </div>
              <div className="col_right">
                <div className={`col_num ${i === 0 && `red`}`}>{e.num}</div>
              </div>
            </div>
          ))}
      </div>
    </TableMostPickedStyled>
  );
};

// TableMostPicked.propTypes = {};

export default TableMostPicked;

export default function renderIcon({ color = '#4E5B7E' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100.657" height="115.746" viewBox="0 0 100.657 115.746">
      <g id="Group_13892" data-name="Group 13892" transform="translate(0 0)">
        <ellipse id="Ellipse_1500" data-name="Ellipse 1500" cx="33.606" cy="4.962" rx="33.606" ry="4.962" transform="translate(17.623 105.823)" fill="#d8d8d8" />
        <g id="Group_13719" data-name="Group 13719" transform="translate(0 0)">
          <g id="_095-badge-4" data-name="095-badge-4" transform="translate(0 8.998)">
            <path id="Path_819" data-name="Path 819" d="M35.312,0v57.27a43.578,43.578,0,0,0,.87,8.677h6.072V79.832h72.889V65.948h6.072a43.6,43.6,0,0,0,.87-8.677V0Z" transform="translate(-28.37 -0.001)" fill="#bdc3c7" />
            <path id="Path_820" data-name="Path 820" d="M70.622,320.964h72.889V300.139H70.622Z" transform="translate(-56.738 -241.134)" fill="#fd4755" />
            <g id="Group_200" data-name="Group 200" transform="translate(13.884 6.942)">
              <path id="Path_821" data-name="Path 821" d="M70.622,87.375h72.889V35.311H70.622Z" transform="translate(-70.622 -35.311)" fill="#ecf0f1" />
              <path id="Path_822" data-name="Path 822" d="M110.855,406.07a36.266,36.266,0,0,0,57.07,0Z" transform="translate(-102.945 -333.181)" fill="#ecf0f1" />
            </g>
            <path id="Path_823" data-name="Path 823" d="M393.435,335.449H379.552v13.884h-7.91a36.843,36.843,0,0,1-7.22,6.941h29.013l-6.942-10.413Z" transform="translate(-292.779 -269.502)" fill="#e33442" />
            <path id="Path_824" data-name="Path 824" d="M126.045,448.323a36.079,36.079,0,0,1-21.315-6.942H94.245a43.361,43.361,0,0,0,63.6,0H147.36a36.081,36.081,0,0,1-21.315,6.942" transform="translate(-75.717 -354.608)" fill="#bdc3c7" />
            <path id="Path_825" data-name="Path 825" d="M29.013,356.274a36.891,36.891,0,0,1-7.22-6.941h-7.91V335.449H0l6.942,10.413L0,356.274Z" transform="translate(0 -269.502)" fill="#e33442" />
          </g>
          <g id="office-building_1_" data-name="office-building (1)" transform="translate(15.877 0)">
            <rect id="Rectangle_17963" data-name="Rectangle 17963" width="32.816" height="63.289" transform="translate(17.581 4.688)" fill="#4d5c7d" />
            <path id="Path_19030" data-name="Path 19030" d="M136.533,142.182v17.58H169.35V105.719Z" transform="translate(-118.952 -91.785)" fill="#4d5c7d" />
            <rect id="Rectangle_17964" data-name="Rectangle 17964" width="23.44" height="4.688" transform="translate(22.269)" fill="#4d5c7d" />
            <rect id="Rectangle_17965" data-name="Rectangle 17965" width="23.44" height="2.344" transform="translate(22.269 2.344)" fill="#4d5c7d" />
            <g id="Group_13718" data-name="Group 13718" transform="translate(22.269 10.548)">
              <rect id="Rectangle_17966" data-name="Rectangle 17966" width="4.688" height="4.688" fill="#87ced9" />
              <rect id="Rectangle_17967" data-name="Rectangle 17967" width="4.688" height="4.688" transform="translate(9.376)" fill="#87ced9" />
              <rect id="Rectangle_17968" data-name="Rectangle 17968" width="4.688" height="4.688" transform="translate(18.752)" fill="#87ced9" />
              <rect id="Rectangle_17969" data-name="Rectangle 17969" width="4.688" height="4.688" transform="translate(0 9.376)" fill="#87ced9" />
              <rect id="Rectangle_17970" data-name="Rectangle 17970" width="4.688" height="4.688" transform="translate(9.376 9.376)" fill="#87ced9" />
              <rect id="Rectangle_17971" data-name="Rectangle 17971" width="4.688" height="4.688" transform="translate(18.752 9.376)" fill="#87ced9" />
              <rect id="Rectangle_17972" data-name="Rectangle 17972" width="4.688" height="4.688" transform="translate(0 18.752)" fill="#87ced9" />
              <rect id="Rectangle_17973" data-name="Rectangle 17973" width="4.688" height="4.688" transform="translate(9.376 18.752)" fill="#87ced9" />
              <rect id="Rectangle_17974" data-name="Rectangle 17974" width="4.688" height="4.688" transform="translate(18.752 18.752)" fill="#87ced9" />
              <rect id="Rectangle_17975" data-name="Rectangle 17975" width="4.688" height="4.688" transform="translate(0 28.128)" fill="#87ced9" />
              <rect id="Rectangle_17976" data-name="Rectangle 17976" width="4.688" height="4.688" transform="translate(9.376 28.128)" fill="#87ced9" />
              <rect id="Rectangle_17977" data-name="Rectangle 17977" width="4.688" height="4.688" transform="translate(18.752 28.128)" fill="#87ced9" />
              <rect id="Rectangle_17978" data-name="Rectangle 17978" width="4.688" height="4.688" transform="translate(0 37.504)" fill="#87ced9" />
              <rect id="Rectangle_17979" data-name="Rectangle 17979" width="4.688" height="4.688" transform="translate(9.376 37.504)" fill="#87ced9" />
              <rect id="Rectangle_17980" data-name="Rectangle 17980" width="4.688" height="4.688" transform="translate(18.752 37.504)" fill="#87ced9" />
              <rect id="Rectangle_17981" data-name="Rectangle 17981" width="14.064" height="9.376" transform="translate(4.688 48.053)" fill="#87ced9" />
            </g>
            <rect id="Rectangle_17982" data-name="Rectangle 17982" width="2.344" height="9.376" transform="translate(32.817 58.601)" fill="#61acc9" />
            <path id="Path_19031" data-name="Path 19031" d="M18.842,326.119a4.727,4.727,0,0,0,.09-2.649,4.645,4.645,0,0,0-3.415-3.372,4.691,4.691,0,0,0-5.653,5.784,6.772,6.772,0,0,1-.551,5.219,5.86,5.86,0,1,0,10.246.172A6.593,6.593,0,0,1,18.842,326.119Z" transform="translate(-8.527 -277.773)" fill="#97d729" />
            <rect id="Rectangle_17983" data-name="Rectangle 17983" width="2.344" height="21.096" transform="translate(4.689 46.881)" fill="#79a125" />
            <path id="Path_19032" data-name="Path 19032" d="M428.442,326.119a4.725,4.725,0,0,0,.09-2.649,4.645,4.645,0,0,0-3.415-3.372,4.691,4.691,0,0,0-5.652,5.784,6.776,6.776,0,0,1-.551,5.224,5.86,5.86,0,1,0,10.246.172A6.6,6.6,0,0,1,428.442,326.119Z" transform="translate(-361.87 -277.773)" fill="#97d729" />
            <rect id="Rectangle_17984" data-name="Rectangle 17984" width="2.344" height="21.096" transform="translate(60.946 46.881)" fill="#79a125" />
          </g>
        </g>
      </g>
    </svg>
  )
}

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnPopupStyled } from './styled';
import { useComponentVisible } from 'utils/functions/detectClickOutside';
import { useHistory } from 'react-router-dom';

const BtnPopup = ({
  theme_normal_btn_popup,
  theme_popup_right,
  label,
  options,
  minwidth,
  handleEvent,
}) => {
  const customClass = cx({
    theme_normal_btn_popup: theme_normal_btn_popup,
    theme_popup_right: theme_popup_right,
  });

  const history = useHistory();

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const handleClickBtn = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleClickMenu = (route, e) => {
    if (route) {
      history.push(route);
    } else {
      if (e.handleEvent) {
        e.handleEvent()
      }
    }
  };

  return (
    <BtnPopupStyled
      minwidth={minwidth}
    >
      <div className={customClass} ref={ref} onClick={handleClickBtn}>
        <div className='body_btn'>
          {label}
        </div>
        {
          isComponentVisible &&
          <div className='otp_show'>
            {
              options && options.length > 0 &&
              options.map((e, i) => (
                <div key={i + 1} className='opt_box' onClick={() => handleClickMenu(e.route, e)}>
                  <div className='lbl_opt'>
                    {e.label}
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>
    </BtnPopupStyled>
  );
};

BtnPopup.propTypes = {};

export default BtnPopup;

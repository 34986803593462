import { MsdScoreOfWholeCompanyStyled } from "./styled";
import { Blocks, Icons, Typographys, Charts } from "components";
import moment from "moment";
import "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";

const MsdScoreOfWholeCompany = ({ height, data }) => {
  return (
    <MsdScoreOfWholeCompanyStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="24px"
        paddingBottom="0px"
        paddingLeft="24px"
        height={`calc(${height} - 8px)`}
      >
        <div className="container">
          <div className="top_main">
            <div className="top_main_left">
              <div className="show_icon">
                <Icons.MSD2 color="#0F8989" width="29.61" height="38.45" />
              </div>
              <Typographys.TopBottom
                theme_left_align
                label="อาการ MSD ของพนักงานทั้งบริษัท"
                labelBottom="The MSD scores of whole company"
              />
            </div>
            <div className="top_main_right">
              <div className="label_layout">
                <div className="show_icon">
                  <Icons.CalendarIcon />
                </div>
                <div className="show_label">
                {/* วันที่รายสัปดาห์ที่แล้ว */}
                วันที่รายสัปดาห์
                </div>
              </div>
              <div className="date_layout">
                {data &&
                  moment(data.stat_date).startOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).startOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).startOf("isoWeek").format("YYYY")}
                {` - `}
                {data &&
                  moment(data.stat_date).endOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).endOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).endOf("isoWeek").format("YYYY")}
              </div>
            </div>
          </div>
          <div className="bottom_main">
            <div className="bottom_main_left">
              <div className="bar_show">
                <Charts.BarItem
                  theme_standard_bar
                  minHeight={
                    data && Number(data.nosymptom_count) > 0 ? "247px" : "10px"
                  }
                  percent={
                    data && data.nosymptom_count && data.msd_user_count
                      ? `${(
                          (Number(data.nosymptom_count) /
                            Number(data.msd_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
              <div className="bar_show_2">
                <Charts.BarItem
                  theme_red
                  minHeight={
                    data && Number(data.norisk_count) > 0 ? "247px" : "10px"
                  }
                  percent={
                    data && data.norisk_count && data.msd_user_count
                      ? `${(
                          (Number(data.norisk_count) /
                            Number(data.msd_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
              <div className="bar_show_2 no_mr">
                <Charts.BarItem
                  theme_blue
                  minHeight={
                    data && Number(data.pui_count) > 0 ? "247px" : "10px"
                  }
                  percent={
                    data && data.pui_count && data.msd_user_count
                      ? `${(
                          (Number(data.pui_count) /
                            Number(data.msd_user_count)) *
                          100
                        ).toFixed(0)}`
                      : "0"
                  }
                  show_percent
                />
              </div>
            </div>
            <div className="bottom_main_right">
              <div className="show_being">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="ไม่มีอาการ"
                  labelBottom="No symptoms"
                  // bgColor="#FB9334"
                  bgColor="#8AC47F"
                  showRound
                />
              </div>
              <div className="show_being">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="เจ็บแต่ไม่มีความเสี่ยง"
                  labelBottom="Painful but not at risk"
                  // bgColor="#FD6524"
                  bgColor="#6FB5CB"
                  showRound
                />
              </div>
              <div className="show_being no_margin">
                <Blocks.BoxWellbeing
                  theme_standard_box_well_being
                  label="มีความเสี่ยง PUI"
                  labelBottom="PUI risk"
                  // bgColor="#003C58"
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MsdScoreOfWholeCompanyStyled>
  );
};

export default MsdScoreOfWholeCompany;

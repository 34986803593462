import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { MeanRateStyled } from './styled';
import { Blocks } from 'components';

const MeanRate = ({ theme_standard, title, subTitle, svg, txt1, txt2 }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <MeanRateStyled>
      <div className={customClass}>
        <Blocks.Box
          theme_standard_box
          paddingTop="16px"
          paddingRight="16px"
          paddingBottom="16px"
          paddingLeft="16px"
          height="128px"
        >
          <div className="card_title">
            <div className="txt_title">{title}</div>
            <div className="txt_sub_title">{subTitle}</div>
          </div>
          <div className="card_content"> 
            <div className="icon">{svg}</div>
            <div className="detail_block">
              <div className="txt_percent">{txt1}</div>
              <div className="txt_row">
                <div className="group_text">
                  <div className="txt_top">สำเร็จ</div>
                  <div className="txt_bottom">Success</div>
                </div>
                <div className="txt_price">{txt2}</div>
                <div className="group_text">
                  <div className="txt_top">คน</div>
                  <div className="txt_bottom">Users</div>
                </div>
              </div>
            </div>
          </div>
        </Blocks.Box>
      </div>
    </MeanRateStyled>
  );
};

MeanRate.propTypes = {};

export default MeanRate;

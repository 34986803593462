import styled from "styled-components";

export const InputTextAreaStyled = styled.div`
  .error {
    font-size: 12px;
    font-family: "noto_sans_thai_regular, noto_sans_regular";
    color: #ff0000;
    padding-top: 2px;
  }

  .theme_standard_text_area {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
        flexStyle ? flexStyle : "space-between"};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 12px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          padding-top: 4px;
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .icon {
      position: absolute;
      top: 59px;
      left: 23px;
    }
    .text_area {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
      resize: none;
      outline: none;
      padding: 14px 20px;
      height: ${({ height }) => (height ? height : "140px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : "noto_sans_thai_medium, noto_sans_regular"};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        padding-left: ${({ svg }) => (svg ? "40px" : "0px")};
      }
    }
  }

  .theme_normal {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
        flexStyle ? flexStyle : "space-between"};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 12px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          padding-top: 4px;
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
      }
    }
    .icon {
      position: absolute;
      top: 59px;
      left: 23px;
    }
    .text_area {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      resize: none;
      outline: none;
      padding: ${({ padding }) => (padding ? padding : "14px 20px")};
      height: ${({ height }) => (height ? height : "140px")};
      overflow: ${({ overflow }) => (overflow ? overflow : "auto")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : "noto_sans_thai_medium, noto_sans_regular"};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        padding-left: ${({ svg }) => (svg ? "40px" : "0px")};
      }
    }
  }

  .theme_standard_text_area_reward {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
        flexStyle ? flexStyle : "space-between"};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 8px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          padding-top: 4px;
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .icon {
      position: absolute;
      top: 59px;
      left: 23px;
    }
    .text_area {
      width: 100%;
      box-sizing: border-box;
      border-radius: 16px;
      border: 2px solid ${({ theme }) => theme.COLORS.GRAY_5};
      resize: none;
      outline: none;
      padding: 20px;
      height: ${({ height }) => (height ? height : "140px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : "noto_sans_thai_medium, noto_sans_regular"};
      color: ${(props) =>
        props.fontColor
          ? props.fontColor
          : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: noto_sans_thai_regular, noto_sans_regular;
        padding-left: ${({ svg }) => (svg ? "40px" : "0px")};
      }
    }
  }

  .theme_text_area_lucky {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
        flexStyle ? flexStyle : "space-between"};
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 8px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          padding-top: 4px;
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .icon {
      position: absolute;
      top: 59px;
      left: 23px;
    }
    .text_area {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      resize: none;
      outline: none;
      padding: 20px;
      height: ${({ height }) => (height ? height : "140px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) =>
        props.fontFamily
          ? props.fontFamily
          : ({ theme }) => theme.FONT.STYLE.REGULAR};
      background: ${({ disabled }) =>
        disabled
          ? ({ theme }) => theme.COLORS.GRAY_28
          : ({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ disabled, fontColor }) =>
        disabled
          ? ({ theme }) => theme.COLORS.GRAY_23
          : fontColor
          ? fontColor
          : ({ theme }) => theme.COLORS.PURPLE_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        padding-left: ${({ svg }) => (svg ? "40px" : "0px")};
      }
    }
  }
`;

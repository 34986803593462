import styled from "styled-components";

export const RewardPointContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  min-height: calc(100vh - 200px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  .layer_one {
    display: flex;
  }
  .download_zone {
    margin-top: 44px;
    padding: 42px 32px 24px 56px;
    border: 1px solid #70707080;
    border-radius: 8px;
    .top_zone {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
    .form_show {
      margin-top: 28px;
    }
    .text_top {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_two {
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
    }
  }
`;

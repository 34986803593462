import BarPercent from './BarPercent';
import BarPercent2 from './BarPercent2';
import PersonPercent from './PersonPercent';

const Export = {
  BarPercent,
  BarPercent2,
  PersonPercent
};

export default Export;

import React, { useState, useEffect } from "react";
import { TableNormalStyled } from "./styled";
import cx from "classnames";
import { PaginationCustom, Skeleton, Inputs } from "components";

export const TableNormal = ({
  theme_standard_table_normal,
  theme_standard_table_normal_gray,
  theme_standard_table_normal_purple,
  theme_standard_table_user,
  theme_standard_table_msd,
  theme_lucky,
  theme_reward,
  theme_department,
  theme_log,
  theme_health_check,
  theme_announcement,
  theme_white_head,
  columns,
  data,
  pagination,
  showTitle,
  useSelect,
  useNo,
  loading,
  bgColor,
  bgHeader,
  showPagination,
  marginTopPagination,
  handlePageClick,
  onChangeSelected,
  labelEmpty,
  onClickRow,
  useRenderNoPage,
  skeletonOneBlock,
  theme_health_check_ranking,
  useNoIn,
  resetSelect = false,
  isUseResetSelect = true,
}) => {
  const customClass = cx({
    theme_standard_table_normal: theme_standard_table_normal,
    theme_standard_table_normal_gray: theme_standard_table_normal_gray,
    theme_standard_table_normal_purple: theme_standard_table_normal_purple,
    theme_standard_table_user: theme_standard_table_user,
    theme_reward: theme_reward,
    theme_department: theme_department,
    theme_log: theme_log,
    theme_health_check: theme_health_check,
    theme_standard_table_msd: theme_standard_table_msd,
    theme_white_head: theme_white_head,
    theme_lucky: theme_lucky,
    theme_announcement: theme_announcement,
    theme_health_check_ranking: theme_health_check_ranking,
  });

  const [_selected, _setSelected] = useState({});
  const [_isSelectedAll, _setIsSelectedAll] = useState(false);

  useEffect(() => {
    onChangeSelected && onChangeSelected(_selected);
  }, [_selected, onChangeSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _setIsSelectedAll(false);
    if (isUseResetSelect) {
      _setIsSelectedAll(false);
      _setSelected({});
    } else {
      if (resetSelect) {
        _setIsSelectedAll(false);
        _setSelected({});
      } else {
        if (_selected && Object.keys(_selected).length > 0) {
          let tempObj = [];

          Object.keys(_selected).forEach((e, i) => {
            let findIndex = data.findIndex((a) => String(a.id) === String(e));
            if (findIndex > -1) {
              tempObj.push(String(e));
            }
          });

          if (tempObj.length !== 0 && data.length === tempObj.length) {
            _setIsSelectedAll(true);
          }
        }
      }
    }
  }, [data, isUseResetSelect, resetSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const handleSelectedAll = (e) => {
    if (_isSelectedAll) {
      _setIsSelectedAll(false);
      _setSelected({});
    } else {
      let selectedTemp = { ..._selected };
      data &&
        data.forEach((element) => {
          selectedTemp[element.id] = true;
        });
      _setIsSelectedAll(true);
      _setSelected(selectedTemp);
    }
  };

  const handleSelectedItem = (e) => {
    let temp = { ..._selected };
    if (temp[e?.target?.name]) {
      delete temp[e?.target?.name];
      _setSelected(temp);
      if (_isSelectedAll) {
        _setIsSelectedAll(false);
      }
    } else {
      temp[e?.target?.name] = true;
      _setSelected(temp);
    }
  };

  return (
    <TableNormalStyled
      bgHeader={bgHeader}
      marginTopPagination={marginTopPagination}
    >
      <div className={customClass}>
        <div className="table_container">
          {showTitle && <div className="show_title">{showTitle}</div>}
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                columns.map((e, i) =>
                  useSelect && i === 0 ? (
                    <div
                      key={i}
                      className={`header_col checkbox_layout ${
                        e.classStyle ? e.classStyle : ""
                      }`}
                      style={{ width: 20 }}
                    >
                      <Inputs.InputCheckBoxTable
                        theme_blue_square
                        name={i}
                        value={"isSelectedAll"}
                        checked={_isSelectedAll}
                        onChange={handleSelectedAll}
                      />
                    </div>
                  ) : (
                    <div
                      key={i}
                      className={`header_col ${
                        e.classStyle ? e.classStyle : ""
                      }`}
                      style={e.headerStyle}
                    >
                      {e.renderTitle ? e.renderTitle : e.title}
                    </div>
                  )
                )}
            </div>
          </div>
          <div className="table_body">
            {loading ? (
              skeletonOneBlock ? (
                <Skeleton.GraphSkeleton />
              ) : (
                <Skeleton.TableSkeleton />
              )
            ) : !data || data.length === 0 ? (
              <div className="label_empty">{labelEmpty}</div>
            ) : (
              data &&
              data.map((e, i) => {
                return (
                  e && (
                    <>
                      <div
                        key={i}
                        className="body_row"
                        style={e.rowSty ? e.rowSty : {}}
                        onClick={() => {
                          onClickRow && onClickRow(e);
                        }}
                      >
                        {columns.map((e1, i2) => (
                          <div
                            key={i2}
                            className={`body_col ${
                              e1.classStyle ? e1.classStyle : ""
                            }`}
                            style={e1.style}
                          >
                            {useSelect && i2 === 0 && (
                              <div className="checkbox_layout">
                                <Inputs.InputCheckBoxTable
                                  theme_blue_square
                                  value={e.id}
                                  checked={
                                    _selected[e.id] === true ? true : false
                                  }
                                  onChange={handleSelectedItem}
                                />
                              </div>
                            )}

                            {useNo && i2 === 0 ? (
                              <div className="no">
                                {e1.render
                                  ? e1.render({
                                      origin: e,
                                      value: i + 1,
                                    })
                                  : !useRenderNoPage
                                  ? i + 1
                                  : pagination.from + i}
                              </div>
                            ) : e1.render ? (
                              useNoIn ? (
                                e1.render({
                                  origin: { ...e, no: i + 1 },
                                  value: getDescendantProp(e, e1.field),
                                })
                              ) : (
                                e1.render({
                                  origin: e,
                                  value: getDescendantProp(e, e1.field),
                                })
                              )
                            ) : (
                              getDescendantProp(e, e1.field)
                            )}
                          </div>
                        ))}
                      </div>
                      {e.showLine && (
                        <div
                          className="line"
                          style={{
                            borderBottom: "1px solid #E4E3E3",
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        />
                      )}
                    </>
                  )
                );
              })
            )}
          </div>
          {showPagination && pagination && (
            <div className="pagi_layout">
              <PaginationCustom
                theme_standard_pagination
                totalPages={pagination?.last_page}
                currentPageData={pagination?.current_page - 1}
                _handlePageClick={handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    </TableNormalStyled>
  );
};

export default TableNormal;

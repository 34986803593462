export default function Magic({ color = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.906" height="20.906" viewBox="0 0 20.906 20.906">
      <g id="_158-magic-wand" data-name="158-magic-wand" transform="translate(-4)">
        <g id="Group_1784" data-name="Group 1784" transform="translate(4 0)">
          <g id="Group_1783" data-name="Group 1783">
            <path id="Path_3764" data-name="Path 3764" d="M429.285,149.337h-1.742a.871.871,0,1,0,0,1.742h1.742a.871.871,0,0,0,0-1.742Z" transform="translate(-409.25 -143.239)" fill={color} />
            <path id="Path_3765" data-name="Path 3765" d="M320.877,3.488a.871.871,0,0,0,.871-.871V.875a.871.871,0,1,0-1.742,0V2.617A.871.871,0,0,0,320.877,3.488Z" transform="translate(-306.939 -0.004)" fill={color} />
            <path id="Path_3766" data-name="Path 3766" d="M320.877,277.337a.871.871,0,0,0-.871.871v.871a.871.871,0,1,0,1.742,0v-.871A.871.871,0,0,0,320.877,277.337Z" transform="translate(-306.939 -266.013)" fill={color} />
            <path id="Path_3767" data-name="Path 3767" d="M215.9,46.46a.871.871,0,1,0,1.232-1.232L215.9,44a.871.871,0,0,0-1.232,1.232Z" transform="translate(-205.654 -41.955)" fill={color} />
            <path id="Path_3768" data-name="Path 3768" d="M396.916,225.014a.871.871,0,0,0-1.232,1.232l1.232,1.232a.871.871,0,0,0,1.232-1.232Z" transform="translate(-379.283 -215.581)" fill={color} />
            <path id="Path_3769" data-name="Path 3769" d="M396.3,46.715a.869.869,0,0,0,.616-.255l1.232-1.232A.871.871,0,0,0,396.914,44l-1.232,1.232a.871.871,0,0,0,.616,1.487Z" transform="translate(-379.281 -41.955)" fill={color} />
            <path id="Path_3770" data-name="Path 3770" d="M.256,210.737a.87.87,0,0,0,0,1.232L2.1,213.817a.871.871,0,0,0,1.232,0l9.349-9.348L9.6,201.389Z" transform="translate(0 -193.166)" fill={color} />
            <path id="Path_3771" data-name="Path 3771" d="M269,106.921a.87.87,0,0,0-1.232,0l-2.38,2.38,3.079,3.08,2.38-2.381a.87.87,0,0,0,0-1.232Z" transform="translate(-254.554 -102.31)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}

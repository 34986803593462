export default function Popular4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="163.081" viewBox="0 0 168 163.081">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-1025 -1260.919)">
        <g id="data-analysis" transform="translate(1025 1223.681)">
          <path id="Path_20964" data-name="Path 20964" d="M112.456,220.271a5.8,5.8,0,0,1-5.8,5.8H5.8a5.8,5.8,0,0,1-5.8-5.8V84.242l112.456-5.8Z" transform="translate(0 -25.749)" fill="#edebed" />
          <path id="Path_20965" data-name="Path 20965" d="M256.466,93.9V229.929a5.8,5.8,0,0,1-5.8,5.8H239.075a5.8,5.8,0,0,0,5.8-5.8V222.2l-31.109-50.818L244.872,93.9Z" transform="translate(-133.575 -35.407)" fill="#dbd9dc" />
          <path id="Path_20966" data-name="Path 20966" d="M112.456,58.493H111.3l-52.17,8.941L11.594,58.493H0V43.035a5.8,5.8,0,0,1,5.8-5.8H106.659a5.8,5.8,0,0,1,5.8,5.8Z" fill="#c3aff0" />
          <path id="Path_20967" data-name="Path 20967" d="M298.63,43.037V58.495H287.037V43.037a5.8,5.8,0,0,0-5.8-5.8h11.594A5.8,5.8,0,0,1,298.63,43.037Z" transform="translate(-175.74 -0.001)" fill="#a69ded" />
          <path id="Path_20968" data-name="Path 20968" d="M130.608,222.2a1.932,1.932,0,0,1-1.932,1.932H32.837A1.932,1.932,0,0,1,30.9,222.2V93.9h99.7Z" transform="translate(-19.312 -35.405)" fill="#fff" />
          <path id="Path_20969" data-name="Path 20969" d="M118.426,156.493H63.743a1.932,1.932,0,0,1-1.932-1.932V126.736a1.932,1.932,0,0,1,1.932-1.932H136.4a1.932,1.932,0,0,1,1.932,1.932v13.526Z" transform="translate(-38.624 -54.718)" fill="#bde4ff" />
          <g id="Group_14561" data-name="Group 14561" transform="translate(20.289 110.47)">
            <path id="Path_20970" data-name="Path 20970" d="M90.025,300.066H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -271.083)" fill="#dbd9dc" />
            <path id="Path_20971" data-name="Path 20971" d="M90.025,269.161H56.983a2.9,2.9,0,0,1,0-5.8H90.025a2.9,2.9,0,0,1,0,5.8Z" transform="translate(-54.085 -251.771)" fill="#dbd9dc" />
            <path id="Path_20972" data-name="Path 20972" d="M93.889,238.255H56.983a2.9,2.9,0,1,1,0-5.8H93.889a2.9,2.9,0,1,1,0,5.8Z" transform="translate(-54.085 -232.459)" fill="#dbd9dc" />
          </g>
          <path id="Path_20973" data-name="Path 20973" d="M56.983,398.58a2.9,2.9,0,0,1-2.9-2.9v-5.8a2.9,2.9,0,1,1,5.8,0v5.8A2.9,2.9,0,0,1,56.983,398.58Z" transform="translate(-33.796 -218.55)" fill="#bde4ff" />
          <path id="Path_20974" data-name="Path 20974" d="M87.889,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,87.889,385.706Z" transform="translate(-53.109 -205.675)" fill="#fc8d94" />
          <path id="Path_20975" data-name="Path 20975" d="M149.7,359.956a2.9,2.9,0,0,1-2.9-2.9V328.074a2.9,2.9,0,1,1,5.8,0v28.984A2.9,2.9,0,0,1,149.7,359.956Z" transform="translate(-91.733 -179.926)" fill="#fc8d94" />
          <path id="Path_20976" data-name="Path 20976" d="M180.605,385.706a2.9,2.9,0,0,1-2.9-2.9V369.282a2.9,2.9,0,1,1,5.8,0v13.525A2.9,2.9,0,0,1,180.605,385.706Z" transform="translate(-111.045 -205.675)" fill="#bde4ff" />
          <path id="Path_20977" data-name="Path 20977" d="M118.794,372.831a2.9,2.9,0,0,1-2.9-2.9V348.678a2.9,2.9,0,1,1,5.8,0v21.255A2.9,2.9,0,0,1,118.794,372.831Z" transform="translate(-72.421 -192.8)" fill="#bde4ff" />
          <path id="Path_20979" data-name="Path 20979" d="M200.563,233.647a49.081,49.081,0,0,1,28.906-83.441c13.533.156,28.912,7.629,39.972,18.689,0,0,22.414,30.049-3.349,62.154,0,0-15.748,16.134-36.615,16.633a48.872,48.872,0,0,1-28.915-14.034Z" transform="translate(-116.345 -70.591)" fill="#ffe07d" />
          <path id="Path_20980" data-name="Path 20980" d="M356.447,198.371a49.1,49.1,0,0,1-54.872,48.738,49.08,49.08,0,0,0,0-97.473,49.075,49.075,0,0,1,54.872,48.735Z" transform="translate(-188.447 -70.02)" fill="#ffd064" />
          <path id="Path_20981" data-name="Path 20981" d="M228.074,244.192a37.485,37.485,0,1,1,53.012,0A37.485,37.485,0,0,1,228.074,244.192Z" transform="translate(-135.657 -89.334)" fill="#fff" />
        </g>
        <g id="_25-sliced" data-name="25-sliced" transform="translate(1115 1319)">
          <path id="Path_21002" data-name="Path 21002" d="M2.806,53H55.194L57,58.419H1Z" transform="translate(0 -5.032)" fill="#ea735c" />
          <path id="Path_21003" data-name="Path 21003" d="M57,59v3.613H1V59H57Z" transform="translate(0 -5.613)" fill="#9a3e32" />
          <path id="Path_21004" data-name="Path 21004" d="M17.323,53H2.871l-1.2,3.613h14.5a1.158,1.158,0,0,0,1.157-1.157Z" transform="translate(-0.065 -5.032)" fill="#c9634f" />
          <path id="Path_21005" data-name="Path 21005" d="M42.871,54.806H53.71l.235.471a2.419,2.419,0,0,0,2.162,1.336h2.42L57.323,53H23v1.806a2.71,2.71,0,0,0,2.71,2.71H40.161A2.71,2.71,0,0,0,42.871,54.806Z" transform="translate(-2.129 -5.032)" fill="#c9634f" />
          <path id="Path_21006" data-name="Path 21006" d="M4.529,53l1.084,2.71H2L2.9,53Z" transform="translate(-0.097 -5.032)" fill="#ffc285" />
          <g id="Group_14563" data-name="Group 14563" transform="translate(3.71 38.014)">
            <path id="Path_21007" data-name="Path 21007" d="M4.723,52.613,4,50.806,6.71,49l2.71,6.323H5.806Z" transform="translate(-4 -42.659)" fill="#ffebc2" />
            <path id="Path_21008" data-name="Path 21008" d="M10,48h2.71v7.226H10Z" transform="translate(-4.581 -42.563)" fill="#ffebc2" />
            <path id="Path_21009" data-name="Path 21009" d="M15.71,46v9.032H13V46Z" transform="translate(-4.871 -42.369)" fill="#ffebc2" />
            <path id="Path_21010" data-name="Path 21010" d="M18.71,44V54.839H16V44Z" transform="translate(-5.161 -42.175)" fill="#ffebc2" />
            <path id="Path_21011" data-name="Path 21011" d="M39.7,52.913H41.89a.932.932,0,0,0,.271-.036v1.843a37.27,37.27,0,0,1-8.129.9H25v-2.71a115.1,115.1,0,0,0,13.548-.795L38.6,52.1a1.146,1.146,0,0,0,1.093.813Z" transform="translate(-6.032 -42.959)" fill="#ffebc2" />
            <path id="Path_21012" data-name="Path 21012" d="M41.1,47.317a10.1,10.1,0,0,1,.569,1.418l.018.027-2.565,1.463a1.121,1.121,0,0,0-.578.985,1.037,1.037,0,0,0,.054.334l-.054.018A115.1,115.1,0,0,1,25,52.357V50.551s6.946-1.734,12.492-2.43v-.027a1.8,1.8,0,0,0,.7-.253l2.366-1.481a7.734,7.734,0,0,1,.551.957Z" transform="translate(-6.032 -42.404)" fill="#ffebc2" />
            <path id="Path_21013" data-name="Path 21013" d="M37.257,47.729a1.908,1.908,0,0,0,.235-.018v.027c-5.546.7-12.492,2.43-12.492,2.43V48.361a19.99,19.99,0,0,1,8.129-3.27l.036-.027a1.476,1.476,0,0,0,1.183.587,1.492,1.492,0,0,0,1.49-1.49V42.4a7.907,7.907,0,0,1,1.806.75l.09.108L36.119,44.64a1.754,1.754,0,0,0,1.138,3.089Z" transform="translate(-6.032 -42.021)" fill="#ffebc2" />
            <path id="Path_21014" data-name="Path 21014" d="M32.885,44.419a1.434,1.434,0,0,0,.28.605l-.036.027A19.99,19.99,0,0,0,25,48.321v-2.71s.831-3.306,7.226-3.595l.154-.036Z" transform="translate(-6.032 -41.98)" fill="#ffebc2" />
          </g>
          <path id="Path_21015" data-name="Path 21015" d="M24.71,54.065v.9A2.654,2.654,0,0,1,22,52.258V38.71L24.71,36Z" transform="translate(-2.032 -3.387)" fill="#d6cec1" />
          <path id="Path_21016" data-name="Path 21016" d="M14.987,23.59l.379-1.689-.56-.126A1.812,1.812,0,0,1,13,19.968V16.355l7.226-9.935V1h9.032s3.613,9.032,3.613,13.548a31.5,31.5,0,0,1-.217,3.757,8.07,8.07,0,0,1-2.99,5.311l-.8.641a3.756,3.756,0,0,0-1.409,2.935,2.71,2.71,0,0,1-2.71,2.71.9.9,0,0,1-.9-.9V24.484l4.516-6.323-7.226,5.419v3.658a1.409,1.409,0,0,1-.768,1.246,1.371,1.371,0,0,1-.623.145,1.39,1.39,0,0,1-1.111-.56l-2.014-2.683a1.63,1.63,0,0,1-1.626-1.8Z" transform="translate(-1.161)" fill="#ffbea7" />
          <path id="Path_21017" data-name="Path 21017" d="M51.137,46.69l-7.957,4.354a1.288,1.288,0,0,1-.244.081.932.932,0,0,1-.271.036H40.47a1.146,1.146,0,0,1-1.093-.813,1.037,1.037,0,0,1-.054-.334,1.121,1.121,0,0,1,.578-.985l2.565-1.463,3.179-1.825,3.613-3.613-4.516.9-3.414,2.132-2.366,1.481a1.8,1.8,0,0,1-.7.253,1.908,1.908,0,0,1-.235.018,1.754,1.754,0,0,1-1.138-3.089l1.617-1.382,3.523-3.026,3.613-.9H39.323l-2.71,2.71v2.123a1.492,1.492,0,0,1-1.49,1.49,1.476,1.476,0,0,1-1.183-.587,1.434,1.434,0,0,1-.28-.605l-.506-2.439-.117-.56a2.714,2.714,0,0,1,.6-2.177l2.954-3.5A2.71,2.71,0,0,1,38.663,34h8.788L60.1,37.613V49.355H55.581Z" transform="translate(-3.097 -3.194)" fill="#ffbea7" />
          <path id="Path_21018" data-name="Path 21018" d="M16.825,26.73a1.63,1.63,0,0,1-1.626-1.8l.379-1.689L16.825,17.7l1.806-1.806.452-.452a1.916,1.916,0,0,1,2.71,2.71l-2.258,2.258" transform="translate(-1.373 -1.343)" fill="none" />
          <path id="Path_21019" data-name="Path 21019" d="M22.419,17.886,19.71,20.6,17,26.918,19.014,29.6a1.39,1.39,0,0,0,1.111.56,1.371,1.371,0,0,0,.623-.145,1.409,1.409,0,0,0,.768-1.246V25.112l4.837-3.627a2.59,2.59,0,0,0-2.991-4.227Z" transform="translate(-1.548 -1.531)" fill="none" />
          <path id="Path_21020" data-name="Path 21020" d="M25,23.387v2.935L29.516,20Z" transform="translate(-2.323 -1.839)" fill="#ab674d" />
          <path id="Path_21021" data-name="Path 21021" d="M22,25.782V37.524l2.71-2.71V23.75Z" transform="translate(-2.032 -2.202)" fill="#9a3e32" />
          <path id="Path_21022" data-name="Path 21022" d="M15.71,16.669l-.9,3.613s-.6,1.2-1.806.17v.733a1.812,1.812,0,0,0,1.806,1.806l.56.126,1.246-5.546,1.806-1.806.452-.452a1.9,1.9,0,0,1,.378-.286,5.275,5.275,0,0,0-.452-1.445Z" transform="translate(-1.161 -1.218)" fill="#ffab8c" />
          <path id="Path_21023" data-name="Path 21023" d="M27.71,28.293V26.408a2.991,2.991,0,0,1,.657-1.871l2.168-2.71a3.613,3.613,0,0,0,.789-2.251v-.215a4.031,4.031,0,0,0-1.806.576L25,26.261v4.516h.226a2.484,2.484,0,0,0,2.484-2.484Z" transform="translate(-2.323 -1.777)" fill="#ffab8c" />
          <path id="Path_21024" data-name="Path 21024" d="M18.838,19.415a1.806,1.806,0,0,0-.338.469l-2.077,4.155a1.794,1.794,0,0,1-1.207.941l-.015.067a1.63,1.63,0,0,0,1.624,1.8l2.71-6.323,2.258-2.258a1.909,1.909,0,0,0,.369-2.175Z" transform="translate(-1.373 -1.461)" fill="#ffab8c" />
          <path id="Path_21025" data-name="Path 21025" d="M26.963,20.761a.493.493,0,0,0-.12-.257.637.637,0,0,0-.205-.126,1.694,1.694,0,0,0-.4-.138,1.374,1.374,0,0,0-.87.2,1.79,1.79,0,0,1-.213.209l-5.064,3.613A.9.9,0,0,0,19.71,25v.361c.9,1.888,0,3.694-1.881,1.888H17l2.014,2.683a1.39,1.39,0,0,0,1.111.56,1.371,1.371,0,0,0,.623-.145,1.409,1.409,0,0,0,.768-1.246V25.442l4.837-3.627a2.566,2.566,0,0,0,.583-.637.955.955,0,0,0,.027-.416Z" transform="translate(-1.548 -1.861)" fill="#ffab8c" />
          <path id="Path_21026" data-name="Path 21026" d="M28.858,3.619,28.484,1H21V6.194l6.22-.518a1.806,1.806,0,0,0,1.638-2.056Z" transform="translate(-1.935)" fill="#ffcebd" />
          <path id="Path_21027" data-name="Path 21027" d="M21.386,15.982a1.231,1.231,0,0,0-.625-.565.955.955,0,0,0-.315-.049,2.212,2.212,0,0,0-1.134.3,3.476,3.476,0,0,0-.813.764c-.3.329-.572.691-.888,1.008a.8.8,0,0,0,.173,1.218,1.33,1.33,0,0,0,.973.2c.117-.04.223-.138.338-.191a3.7,3.7,0,0,0,.473-.271,6.351,6.351,0,0,0,1.491-1.34A1.15,1.15,0,0,0,21.386,15.982Z" transform="translate(-1.587 -1.391)" fill="#ffcebd" />
          <path id="Path_21028" data-name="Path 21028" d="M21.016,20.161a4.071,4.071,0,0,0-.52.626.844.844,0,0,0-.1.775,1.1,1.1,0,0,0,.632.5,1.749,1.749,0,0,0,.542.147,2.289,2.289,0,0,0,1.287-.47,10.392,10.392,0,0,0,1.222-.8,2.732,2.732,0,0,0,.874-1.152c.422-1.174-.671-2.439-1.86-1.711a3.691,3.691,0,0,0-.733.657l-1.049,1.123c-.094.1-.192.2-.294.3Z" transform="translate(-1.872 -1.632)" fill="#ffcebd" />
          <path id="Path_21029" data-name="Path 21029" d="M17.282,12.2a1.037,1.037,0,0,1,.694-.377c.406-.028.937.24.9.7a1.355,1.355,0,0,1-.1.378,5.5,5.5,0,0,1-1.5,2.115,1.846,1.846,0,0,1-1.141.542.561.561,0,0,1-.361-.1.508.508,0,0,1-.155-.424,3.214,3.214,0,0,1,.632-1.375,11.947,11.947,0,0,1,.774-1.134c.089-.112.175-.223.269-.323Z" transform="translate(-1.413 -1.047)" fill="#ffcebd" />
          <path id="Path_21030" data-name="Path 21030" d="M43.894,37H39.222a1.806,1.806,0,0,0-1.277.529l-2.555,2.555a1.806,1.806,0,0,0-.529,1.277v.155a1.8,1.8,0,0,1-1.292,1.723l.145.7a1.434,1.434,0,0,0,.28.605,1.476,1.476,0,0,0,1.183.587,1.492,1.492,0,0,0,1.49-1.49V41.516l2.71-2.71H45.7A1.806,1.806,0,0,0,43.894,37Z" transform="translate(-3.152 -3.484)" fill="#ffab8c" />
          <path id="Path_21031" data-name="Path 21031" d="M47.835,41.326l-3.207.535a1.79,1.79,0,0,0-.787.337L38.584,46.14a1.677,1.677,0,0,1-1.917.063,1.741,1.741,0,0,0,1.716,1.42,1.907,1.907,0,0,0,.235-.018,1.8,1.8,0,0,0,.7-.253l2.366-1.481L45.1,43.739l4.516-.9a1.526,1.526,0,0,0-1.778-1.51Z" transform="translate(-3.452 -3.9)" fill="#ffab8c" />
          <path id="Path_21032" data-name="Path 21032" d="M58.968,44.958a2.077,2.077,0,0,1-3.234,1.731,5.369,5.369,0,0,0-4.678-.626l-.218.07-7.72,4.289a1.806,1.806,0,0,1-.877.228h-.435a1.8,1.8,0,0,1-1.567-.924A1.116,1.116,0,0,0,40,50.4a1.037,1.037,0,0,0,.054.334,1.146,1.146,0,0,0,1.093.813h2.195a.932.932,0,0,0,.271-.036,1.288,1.288,0,0,0,.244-.081l7.957-4.354,4.444,2.665h4.516V38.033a7.13,7.13,0,0,0-1.806,4.733Z" transform="translate(-3.774 -3.584)" fill="#ffab8c" />
          <path id="Path_21033" data-name="Path 21033" d="M61.671,37.62,49.263,34.075a6.67,6.67,0,0,0,2.612,3.733l4.457,3.121a6.672,6.672,0,0,0,3.544,1.186,7.13,7.13,0,0,1,1.794-4.465Z" transform="translate(-4.671 -3.201)" fill="#ffcebd" />
          <path id="Path_21034" data-name="Path 21034" d="M38.93,35.753a2.639,2.639,0,0,0,1.473.318,38.194,38.194,0,0,0,3.961-.249,2.944,2.944,0,0,0,.945-.2,1,1,0,0,0,.6-.712.418.418,0,0,0-.058-.309.515.515,0,0,0-.231-.156,4.6,4.6,0,0,0-1.968-.271c-.767.019-1.535.021-2.3.038-.519.011-3.252-.109-2.89.9a1.306,1.306,0,0,0,.47.644Z" transform="translate(-3.622 -3.209)" fill="#ffcebd" />
          <path id="Path_21035" data-name="Path 21035" d="M34.734,37.812a1.975,1.975,0,0,0-.511.788.674.674,0,0,0,.331.8.939.939,0,0,0,.788-.181,4.4,4.4,0,0,0,1.928-2.114.869.869,0,0,0-.033-.84.671.671,0,0,0-.576-.208c-.463.039-.582.281-.863.589Z" transform="translate(-3.212 -3.392)" fill="#ffcebd" />
          <path id="Path_21036" data-name="Path 21036" d="M44.012,39.827a.638.638,0,0,0-.463.288.481.481,0,0,0-.016.271,1.209,1.209,0,0,0,.776.847,2.83,2.83,0,0,0,1.184.131,8.877,8.877,0,0,0,1.174-.141,2.88,2.88,0,0,0,1.162-.432.919.919,0,0,0,.435-.862.827.827,0,0,0-.542-.573,3.415,3.415,0,0,0-1.775.051q-.975.172-1.935.418Z" transform="translate(-4.115 -3.703)" fill="#ffcebd" />
          <path id="Path_21037" data-name="Path 21037" d="M48.72,45.769a4.164,4.164,0,0,0-1.069,1.386.542.542,0,0,0-.051.282.48.48,0,0,0,.3.324,1.9,1.9,0,0,0,1.736-.348c.756-.48,3.021-1.868,1.659-2.851a.7.7,0,0,0-.212-.107.8.8,0,0,0-.263-.019c-.95.051-1.461.723-2.1,1.333Z" transform="translate(-4.509 -4.203)" fill="#ffcebd" />
          <path id="Path_21038" data-name="Path 21038" d="M16.9,44v7.226a1.806,1.806,0,0,0,1.806,1.806v1.806H16V44Z" transform="translate(-1.452 -4.161)" fill="#ffc285" />
          <path id="Path_21039" data-name="Path 21039" d="M13.9,46v5.419a1.806,1.806,0,0,0,1.806,1.806v1.806H13V46Z" transform="translate(-1.161 -4.355)" fill="#ffc285" />
          <path id="Path_21040" data-name="Path 21040" d="M10.9,48v3.613a1.806,1.806,0,0,0,1.806,1.806v1.806H10V48Z" transform="translate(-0.871 -4.548)" fill="#ffc285" />
          <path id="Path_21041" data-name="Path 21041" d="M7.826,54.565a1.806,1.806,0,0,1-1.616-1L4.677,50.5,4,50.952l.723,1.806,1.084,2.71H9.419l-.387-.9Z" transform="translate(-0.29 -4.79)" fill="#ffc285" />
          <path id="Path_21042" data-name="Path 21042" d="M5.613,55.71,4.529,53H3.806l.249.5a.9.9,0,0,1-.807,1.307H2.3l-.3.9Z" transform="translate(-0.097 -5.032)" fill="#ffac59" />
          <path id="Path_21043" data-name="Path 21043" d="M35.839,52.969a9.033,9.033,0,0,1-2.241-.292c-4.331.292-8.6.292-8.6.292v2.71h9.032a37.27,37.27,0,0,0,8.129-.9V52.933a.932.932,0,0,1-.271.036H39.7a1.116,1.116,0,0,1-.452-.107,3.758,3.758,0,0,1-.862.107Z" transform="translate(-2.323 -5.001)" fill="#ffdb94" />
          <path id="Path_21044" data-name="Path 21044" d="M36.626,48.4a2.21,2.21,0,0,0,2.092.712l.779-.156a.9.9,0,0,1,1.084.886v.194l2.24-1.278-.018-.027a10.1,10.1,0,0,0-.569-1.418,7.735,7.735,0,0,0-.551-.957l-2.37,1.481a1.8,1.8,0,0,1-.7.253v.027c-.649.081-1.317.178-1.991.284Z" transform="translate(-3.448 -4.39)" fill="#ffdb94" />
          <path id="Path_21045" data-name="Path 21045" d="M29.318,49.908c-2.507.535-4.318.986-4.318.986V52.7s4.267,0,8.6-.292a9.015,9.015,0,0,1-4.279-2.5Z" transform="translate(-2.323 -4.733)" fill="#ffdb94" />
          <path id="Path_21046" data-name="Path 21046" d="M33.044,45.247a2.7,2.7,0,0,0,2.309,1.308h.361a2.2,2.2,0,0,0,.565,1.467c.674-.106,1.342-.2,1.987-.284v-.027a1.907,1.907,0,0,1-.235.018,1.754,1.754,0,0,1-1.138-3.089l1.617-1.382-.09-.108a7.907,7.907,0,0,0-1.806-.75v1.761a1.492,1.492,0,0,1-1.487,1.49,1.476,1.476,0,0,1-1.183-.587l-.036.027c-.294.045-.581.1-.863.155Z" transform="translate(-3.101 -4.006)" fill="#ffdb94" />
          <path id="Path_21047" data-name="Path 21047" d="M27.684,47.28A17.655,17.655,0,0,0,25,48.834V50.64s1.806-.452,4.318-.986a9.05,9.05,0,0,1-1.634-2.374Z" transform="translate(-2.323 -4.479)" fill="#ffdb94" />
          <path id="Path_21048" data-name="Path 21048" d="M26.835,43.628A4.162,4.162,0,0,0,25,45.77v2.71a17.654,17.654,0,0,1,2.684-1.554,8.983,8.983,0,0,1-.849-3.3Z" transform="translate(-2.323 -4.125)" fill="#ffdb94" />
          <path id="Path_21049" data-name="Path 21049" d="M32.972,45.206c.283-.058.57-.11.863-.155l.036-.027a1.434,1.434,0,0,1-.28-.605l-.506-2.439-.154.036c-.224.01-.425.032-.632.05l.3,2.122a2.687,2.687,0,0,0,.369,1.019Z" transform="translate(-3.029 -3.966)" fill="#ffdb94" />
          <path id="Path_21050" data-name="Path 21050" d="M41.823,53.469a37.955,37.955,0,0,1-7.79.835H25.339V52.27a111.936,111.936,0,0,0,13.261-.8.327.327,0,0,0,.145-.063,1.168,1.168,0,0,1-.142-.29,1.016,1.016,0,0,1-.054-.313.283.283,0,0,0-.055,0,111,111,0,0,1-13.158.789V50.4c1.274-.311,7.31-1.748,12.194-2.36a31.568,31.568,0,0,1,3.729-.282.327.327,0,0,0,.192-.068c-.08-.205-.169-.407-.263-.608-.766,0-1.68.059-2.729.166l-.272.171a1.8,1.8,0,0,1-.7.253,1.908,1.908,0,0,1-.235.018,1.75,1.75,0,0,1-.794-.194c-4.524.646-9.656,1.848-11.124,2.2v-1.2a19.931,19.931,0,0,1,7.842-3.109.336.336,0,0,0,.21-.125,1.562,1.562,0,0,1-.4-.529,20.483,20.483,0,0,0-7.649,2.947V45.662c.115-.337,1.221-3.051,6.9-3.308a.332.332,0,0,0,.2-.084l-.061-.291-.154.036C25.831,42.305,25,45.611,25,45.611v9.032h9.032a37.27,37.27,0,0,0,8.129-.9l-.339-.339Z" transform="translate(-2.323 -3.966)" fill="#ffc285" />
          <path id="Path_21051" data-name="Path 21051" d="M4.613,59a1.806,1.806,0,0,0,1.806,1.806H51.581A1.806,1.806,0,0,0,53.387,59H57v3.613H1V59Z" transform="translate(0 -5.613)" fill="#662c20" />
          <path id="Path_21052" data-name="Path 21052" d="M22.9,38l-.9.9V52.452a2.654,2.654,0,0,0,2.71,2.71v-.9A6.165,6.165,0,0,1,22.9,49.9Z" transform="translate(-2.032 -3.581)" fill="#ede9e1" />
          <path id="Path_21053" data-name="Path 21053" d="M22.9,29.039a2.71,2.71,0,0,1,1.207-2.258l.6-.4V23.75L22,25.782V37.524l.9-.9Z" transform="translate(-2.032 -2.202)" fill="#662c20" />
          <path id="Path_21054" data-name="Path 21054" d="M5.9,19.032a.9.9,0,0,1-.9-.9C5,13.646,8.242,10,12.226,10a.9.9,0,1,1,0,1.806c-2.989,0-5.419,2.837-5.419,6.323A.9.9,0,0,1,5.9,19.032Z" transform="translate(-0.387 -0.871)" fill="#79cfd1" />
          <path id="Path_21055" data-name="Path 21055" d="M3.9,13.226a.9.9,0,0,1-.9-.9C3,8.837,6.647,6,11.129,6a.9.9,0,0,1,0,1.806c-3.486,0-6.323,2.026-6.323,4.516A.9.9,0,0,1,3.9,13.226Z" transform="translate(-0.194 -0.484)" fill="#79cfd1" />
        </g>
      </g>
    </svg>
  );
}

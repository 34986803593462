import { HeadContentStyled } from "./styled";
import { Buttons, Icons } from "components";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants";

const HeadContent = ({
  // isUseRightCreateBtn,
  isUseCreateBtn,
  isUseLogBtn,
  onClick,
  headerLabel = "คลังความรู้ด้านสุขภาพ",
  title_name,
  handleBackCustom,
}) => {
  let history = useHistory();

  const handleClickLog = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_NEW_LOG}`);
  };

  const handleClickBack = () => {
    if (handleBackCustom) {
      handleBackCustom && handleBackCustom();
    } else if (onClick) {
      onClick && onClick();
    } else {
      history.goBack();
    }
  };

  return (
    <HeadContentStyled>
      <div className="head_container">
        <div className="node_left" onClick={handleClickBack}>
          <Buttons.BtnLink
            theme_standard_btn_link
            svg={<Icons.ArrowLeft />}
            label="ย้อนกลับ"
            // onClick={handleClickBack}
          />
        </div>
        <div className="title_center">
          {headerLabel && <div className="head_t">{headerLabel}</div>}
          {title_name && <div className="sub_t">{title_name}</div>}
          {/* <Typographys.LabelSubVerticalTab
            theme_head_34_tab
            title={headerLabel}
            subTitle={title_name && title_name}
          /> */}
        </div>
        {/* {isUseRightCreateBtn && (
          <div className="node_right">
            <Buttons.BtnCreateContent
              theme_standard_btn_create_content
              onClick={handleClickCreateContent}
            />
          </div>
        )} */}
        {isUseCreateBtn && (
          <div className="node_right mw_272 top_18">
            <Buttons.BtnNormal
              theme_standard_btn_normal_red
              label="สร้าง"
              labelBottom="Create"
            />
          </div>
        )}
        {isUseLogBtn && (
          <div className="w132">
            <Buttons.BtnNormal
              theme_blue_border_bg_white
              label="Log"
              onClick={handleClickLog}
            />
          </div>
        )}
      </div>
    </HeadContentStyled>
  );
};

export default HeadContent;

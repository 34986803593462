import styled from "styled-components";

export const BtnRoundShadowStyled = styled.div`
  .theme_btn_round_shadow_pink {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.PINK_2};
      padding: 8px 28px 8px 28px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_blue {
    .body_btn {
      width: 100%;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.BLUE_21};
      padding: 12px 12px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_green_2 {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : "#21A526"};
      padding: ${(padding) => (padding ? padding : "8px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_green_3 {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : "#21A526"};
      padding: ${(padding) => (padding ? padding : "8px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_orange_2 {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
      color: #ff320f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: ${(padding) => (padding ? padding : "8px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      /* box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%); */
      border: 1px solid #ff320f;
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_orange_3 {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.RED_9};
      padding: ${(padding) => (padding ? padding : "8px")};
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      /* border: 1px solid #ff320f; */
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_green {
    .body_btn {
      width: 100%;
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.GREEN_1};
      padding: 8px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
  .theme_btn_round_shadow_orange {
    .body_btn {
      width: 100%;
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.BLUE_20};
      padding: 10px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      text-align: center;
    }
  }

  .theme_btn_round_shadow_white {
    .body_btn {
      width: 100%;
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.BLUE_19};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 6px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
    .label_bottom {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      text-align: center;
    }
  }
`;

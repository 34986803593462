export default function Paste({color='#f0211d'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.7" height="24" viewBox="0 0 20.7 24">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-1158 -410)">
        <g id="_006-copying" data-name="006-copying" transform="translate(1158 410)">
          <rect id="Rectangle_146" data-name="Rectangle 146" width="16.052" height="20.675" rx="3" transform="translate(0 3.325)" fill={color} />
          <path id="Path_2668" data-name="Path 2668" d="M25.01,3.4V19.37a2.4,2.4,0,0,1-2.4,2.4h-.8V6.591A3.994,3.994,0,0,0,17.822,2.6H9.18A2.4,2.4,0,0,1,11.432,1H22.614a2.4,2.4,0,0,1,2.4,2.4Z" transform="translate(-4.31 -1)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

export default function Reward7() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.31" height="25.211" viewBox="0 0 21.31 25.211">
      <g id="medal" transform="translate(-39.592)">
        <path id="Path_11724" data-name="Path 11724" d="M45.1,221.248l-5.506,10.373,5-.462,2.42,4.4,5.506-10.373Z" transform="translate(0 -210.349)" fill="#e95454" />
        <g id="Group_3324" data-name="Group 3324" transform="translate(44.14 10.899)">
          <path id="Path_11725" data-name="Path 11725" d="M133.508,265.034a.7.7,0,0,0-.33-.9l-.317-.169a.678.678,0,0,1,.652-1.188l.9.475a.565.565,0,0,0,.766-.238l.022-.043a.534.534,0,0,0-.206-.754l-2.678-1.421a.705.705,0,0,1-.334-.9.679.679,0,0,1,.933-.312L135.4,260.9a.572.572,0,0,0,.77-.236l.014-.036,0-.008a.6.6,0,0,0-.245-.809l-1.394-.739a.705.705,0,0,1-.332-.9.682.682,0,0,1,.933-.312l1.539.817a.575.575,0,0,0,.78-.265l0-.008,0-.008a.574.574,0,0,0-.218-.795l-1.661-.881a.7.7,0,0,1-.33-.9.679.679,0,0,1,.933-.312l4.1,2.175-5.506,10.373-1.517-2.758A.645.645,0,0,0,133.508,265.034Z" transform="translate(-131.92 -253.744)" fill="#ed6362" />
          <path id="Path_11726" data-name="Path 11726" d="M217.219,221.248l5.506,10.373-5-.462-2.42,4.4L209.8,225.186Z" transform="translate(-205.963 -221.248)" fill="#ed6362" />
        </g>
        <path id="Path_11727" data-name="Path 11727" d="M216.6,265.05a.7.7,0,0,1,.33-.9l.317-.169a.678.678,0,0,0-.652-1.188l-.9.475a.565.565,0,0,1-.766-.238l-.022-.043a.534.534,0,0,1,.206-.754l2.679-1.422a.705.705,0,0,0,.334-.9.679.679,0,0,0-.933-.312l-2.484,1.319a.572.572,0,0,1-.77-.236l-.014-.036,0-.008a.6.6,0,0,1,.245-.809l1.394-.739a.705.705,0,0,0,.332-.9.682.682,0,0,0-.933-.312l-1.541.819a.575.575,0,0,1-.78-.265l0-.008,0-.008a.574.574,0,0,1,.218-.795l1.661-.881a.7.7,0,0,0,.33-.9.679.679,0,0,0-.933-.312l-4.1,2.175,5.506,10.373,1.517-2.758A.656.656,0,0,1,216.6,265.05Z" transform="translate(-161.838 -242.86)" fill="#e95454" />
        <circle id="Ellipse_1497" data-name="Ellipse 1497" cx="9.199" cy="9.199" r="9.199" transform="translate(41.048 0)" fill="#ffcc5b" />
        <path id="Path_11728" data-name="Path 11728" d="M80.108,21.469h-5.83a.829.829,0,0,1-.825-.825l.006-.057v-.013a.862.862,0,0,1,.863-.869h4.04a1.022,1.022,0,0,0,1.04-.932.988.988,0,0,0-.983-1.033H74.14a.833.833,0,0,1-.819-.869v-.025a.833.833,0,0,1,.819-.869h4.479a1.02,1.02,0,0,0,1.033-.926.984.984,0,0,0-.983-1.033h-3.28a9.2,9.2,0,0,0,0,17.015h4.164a1.02,1.02,0,0,0,1.033-.926.984.984,0,0,0-.983-1.033H74.867a.98.98,0,0,1-.979-1.033,1.018,1.018,0,0,1,1.034-.928h1.837a.982.982,0,0,0-.028-1.963H73.947a.819.819,0,0,1-.819-.825v-.069a.775.775,0,0,1,.775-.825h6.149a1.021,1.021,0,0,0,1.04-.926A.985.985,0,0,0,80.108,21.469Z" transform="translate(-28.613 -13.326)" fill="#fdbc4b" />
        <path id="Path_11729" data-name="Path 11729" d="M129.151,66.572a6.566,6.566,0,1,1,6.567-6.566A6.574,6.574,0,0,1,129.151,66.572Z" transform="translate(-78.904 -50.807)" fill="#ffdb70" />
        <path id="Path_11730" data-name="Path 11730" d="M235.264,60.372a6.57,6.57,0,0,0-6.19-6.548h-.612a.778.778,0,0,0-.788.706.751.751,0,0,0,.75.788h3.613a.747.747,0,0,1,.746.788.776.776,0,0,1-.789.708h-1.4a.749.749,0,0,0,.021,1.5h2.123a.624.624,0,0,1,.624.629v.052a.591.591,0,0,1-.591.629h-4.689a.78.78,0,0,0-.793.706.751.751,0,0,0,.75.788h4.446a.631.631,0,0,1,.629.629l0,.043V61.8a.657.657,0,0,1-.658.663h-3.082a.78.78,0,0,0-.793.711.754.754,0,0,0,.75.788h3.264a.635.635,0,0,1,.624.663v.019a.635.635,0,0,1-.624.663h-3.415a.778.778,0,0,0-.788.706.751.751,0,0,0,.75.788h.9A6.577,6.577,0,0,0,235.264,60.372Z" transform="translate(-178.45 -51.172)" fill="#fff" opacity="0.2" />
        <path id="Path_11731" data-name="Path 11731" d="M163.793,92.942H160.6l-.988-3.038a.424.424,0,0,0-.806,0l-.987,3.038h-3.194a.424.424,0,0,0-.249.767l2.584,1.878-.987,3.038a.424.424,0,0,0,.653.474l2.584-1.878,2.584,1.878a.424.424,0,0,0,.653-.474l-.987-3.038,2.584-1.878A.424.424,0,0,0,163.793,92.942Z" transform="translate(-108.962 -85.197)" fill="#ec9922" />
      </g>
    </svg>
  );
}

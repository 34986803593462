export default function TypeSound({ width = '14.722', height = '12.304' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14.934">
      <g id="volume" transform="translate(0 -15.99)">
        <path id="Path_12219" data-name="Path 12219" d="M9.3,16.043a.532.532,0,0,0-.564.064l-5.188,4.15H1.067A1.068,1.068,0,0,0,0,21.324v4.267a1.068,1.068,0,0,0,1.067,1.067H3.546l5.187,4.15a.534.534,0,0,0,.565.063.532.532,0,0,0,.3-.48V16.524A.535.535,0,0,0,9.3,16.043Z" transform="translate(0 0)" fill="#88c1ea" />
        <path id="Path_12220" data-name="Path 12220" d="M342.66,122.406a.534.534,0,0,0-.75.759,4.254,4.254,0,0,1,0,6.025.533.533,0,1,0,.75.757,5.32,5.32,0,0,0,0-7.541Z" transform="translate(-330.36 -102.72)" fill="#88c1ea" />
        <path id="Path_12221" data-name="Path 12221" d="M387.777,77.28a.534.534,0,0,0-.752.757,6.365,6.365,0,0,1,0,9.034.534.534,0,0,0,.752.757,7.433,7.433,0,0,0,0-10.548Z" transform="translate(-373.972 -59.097)" fill="#88c1ea" />
      </g>
    </svg>
  );
}

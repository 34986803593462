export default function MenuMain({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.624"
      height="20.932"
      viewBox="0 0 19.624 20.932"
    >
      <g id="Line" transform="translate(0.073)">
        <rect
          id="Rectangle_17991"
          data-name="Rectangle 17991"
          width="9.158"
          height="6.541"
          rx="1"
          transform="translate(-0.073)"
          fill={color}
        />
        <rect
          id="Rectangle_17992"
          data-name="Rectangle 17992"
          width="9.158"
          height="6.541"
          rx="1"
          transform="translate(10.393 14.391)"
          fill={color}
        />
        <rect
          id="Rectangle_17993"
          data-name="Rectangle 17993"
          width="9.158"
          height="13.083"
          rx="1"
          transform="translate(-0.073 7.85)"
          fill={color}
        />
        <rect
          id="Rectangle_17994"
          data-name="Rectangle 17994"
          width="9.158"
          height="13.083"
          rx="1"
          transform="translate(10.393)"
          fill={color}
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.41"
      height="19.932"
      viewBox="0 0 19.41 19.932"
    >
      <g id="Line" transform="translate(-0.41)">
        <path
          id="Path_19085"
          data-name="Path 19085"
          d="M4.265,8.342H8.8A2.265,2.265,0,0,0,11.06,6.077V4.265A2.265,2.265,0,0,0,8.8,2H4.265A2.265,2.265,0,0,0,2,4.265V6.077A2.265,2.265,0,0,0,4.265,8.342ZM2.906,4.265A1.359,1.359,0,0,1,4.265,2.906H8.8a1.359,1.359,0,0,1,1.359,1.359V6.077A1.359,1.359,0,0,1,8.8,7.436H4.265A1.359,1.359,0,0,1,2.906,6.077Z"
          transform="translate(-1.59 -2)"
        />
        <path
          id="Path_19086"
          data-name="Path 19086"
          d="M32.8,32h-4.53A2.265,2.265,0,0,0,26,34.265v1.812a2.265,2.265,0,0,0,2.265,2.265H32.8a2.265,2.265,0,0,0,2.265-2.265V34.265A2.265,2.265,0,0,0,32.8,32Zm1.359,4.077A1.359,1.359,0,0,1,32.8,37.436h-4.53a1.359,1.359,0,0,1-1.359-1.359V34.265a1.359,1.359,0,0,1,1.359-1.359H32.8a1.359,1.359,0,0,1,1.359,1.359Z"
          transform="translate(-15.24 -18.41)"
        />
        <path
          id="Path_19087"
          data-name="Path 19087"
          d="M4.265,30.684H8.8a2.265,2.265,0,0,0,2.265-2.265V20.265A2.265,2.265,0,0,0,8.8,18H4.265A2.265,2.265,0,0,0,2,20.265v8.154a2.265,2.265,0,0,0,2.265,2.265ZM2.906,20.265a1.359,1.359,0,0,1,1.359-1.359H8.8a1.359,1.359,0,0,1,1.359,1.359v8.154A1.359,1.359,0,0,1,8.8,29.778H4.265a1.359,1.359,0,0,1-1.359-1.359Z"
          transform="translate(-1.59 -10.752)"
        />
        <path
          id="Path_19088"
          data-name="Path 19088"
          d="M32.8,2h-4.53A2.265,2.265,0,0,0,26,4.265v8.154a2.265,2.265,0,0,0,2.265,2.265H32.8a2.265,2.265,0,0,0,2.265-2.265V4.265A2.265,2.265,0,0,0,32.8,2Zm1.359,10.419A1.359,1.359,0,0,1,32.8,13.778h-4.53a1.359,1.359,0,0,1-1.359-1.359V4.265a1.359,1.359,0,0,1,1.359-1.359H32.8a1.359,1.359,0,0,1,1.359,1.359Z"
          transform="translate(-15.24 -2)"
        />
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const ExportHealthCheckStyled = styled.div`
  .box_export_health {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    .top_widget {
      display: flex;
      column-gap: 12px;
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      align-items: center;
      .img_show {
        width: 42;
        height: auto;
      }
      .hText {
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .detail_show {
      margin-top: 16px;
      .text_show {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
      .filter_download {
        margin-top: 16px;
        display: flex;
        align-items: center;
        column-gap: 30px;
        color: black;
        font-size: 20px;
        .w200 {
          width: 200px;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const StepIndicatorGraphStyled = styled.div`
  .step_indicator_head_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
    .step_indicator_head_left_col {
      display: flex;
      .head_icon {
        margin-right: 12px;
      }
    }
    .step_indicator_head_right_col {
      min-width: 496px;
      .group_label {
        margin-top: 22px;
        display: flex;
        padding-left: 20px;
        .box {
          display: flex;
          margin-right: 37px;
          &.no_margin {
            margin-right: 0px;
          }
          .box_color {
            width: 17px;
            height: 16px;
            border-radius: 4px;
            margin-right: 15px;
            &.red {
              background: #8ac47f;
            }
            &.gray {
              background: #654c96;
            }
          }
          .label_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            line-height: 0.8;
            .sub_label {
              margin-top: 10px;
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_regular, noto_sans_regular;
              color: ${({ theme }) => theme.COLORS.GRAY_1};
            }
          }
        }
      }
    }
  }
  .chart_body_show {
    display: flex;
    padding-right: 20px;
    .label_empty {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      width: 100%;
      margin-top: 40px;
      height: 243px;
    }
    .show_skeleton {
      width: 100%;
    }
    .left_chart {
      width: 100%;
      .arrow_left {
        display: flex;
        position: absolute;
        bottom: 8px;
        left: 0px;
        z-index: 2;
        cursor: pointer;
      }
      .arrow_right {
        display: flex;
        position: absolute;
        bottom: 8px;
        right: -27px;
        z-index: 2;
        cursor: pointer;
      }
      .arrow_box {
        padding: 5px 10px;
        display: flex;
        align-items: center;
      }
      .green_dot {
        background: #654c96;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 3px;
      }
      .purple_dot {
        background: #8ac47f;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
      .c_value {
        margin-left: 4px;
        font-weight: 800;
      }
    }
  }
`;

import LabelIcon from './LabelIcon';
import LabelSubVertical from './LabelSubVertical';
import TopBottom from './TopBottom';
import IconTitleSubTxt from './IconTitleSubTxt';
import LabelGraph from './LabelGraph';
import LabelGraph2 from './LabelGraph2';
import LabelGraph3 from './LabelGraph3';
import ShowDate from './ShowDate';
import LabelDescripScore from './LabelDescripScore';
import LabelSubVerticalTab from './LabelSubVerticalTab';
import ShowGender from './ShowGender';

const Export = {
  LabelIcon,
  LabelSubVertical,
  LabelSubVerticalTab,
  TopBottom,
  IconTitleSubTxt,
  LabelGraph,
  LabelGraph2,
  LabelGraph3,
  ShowDate,
  LabelDescripScore,
  ShowGender,
};

export default Export;

export default function WomenGood() {
  return (
    <svg id="Group_454" data-name="Group 454" xmlns="http://www.w3.org/2000/svg" width="35.438" height="40.673" viewBox="0 0 35.438 40.673">
      <g id="Group_407" data-name="Group 407" transform="translate(0 0)">
        <path id="Path_240" data-name="Path 240" d="M542.278,152.14c-.279-1.789-.543-3.579-.838-5.366-.515-3.128-.954-6.215-4.318-8.611a19.14,19.14,0,0,0-10.862-3.17,21.715,21.715,0,0,0-2.869.189c-4.345.579-8.2,2.493-10.3,5.137a9.925,9.925,0,0,0-1.808,5.163q-.437,2.84-.884,5.679l-1.754,11.231c-1.11,7.1,7.118,13.289,17.656,13.274h0c10.508-.015,18.688-6.19,17.582-13.274Z" transform="translate(-508.541 -134.993)" fill="#010101" />
      </g>
      <g id="Group_449" data-name="Group 449" transform="translate(1.07 3.917)">
        <g id="Group_442" data-name="Group 442">
          <g id="Group_415" data-name="Group 415" transform="translate(26.675 13.779)">
            <g id="Group_414" data-name="Group 414">
              <g id="Group_408" data-name="Group 408">
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="3.423" cy="3.423" r="3.423" fill="#ffdac3" />
              </g>
              <g id="Group_413" data-name="Group 413" transform="translate(2.145 2.342)">
                <g id="Group_410" data-name="Group 410">
                  <g id="Group_409" data-name="Group 409">
                    <path id="Path_241" data-name="Path 241" d="M543.513,159.73a1.229,1.229,0,0,1,1.823-.93c.35.186.661-.345.311-.531a1.844,1.844,0,0,0-2.728,1.3c-.066.388.527.554.593.164Z" transform="translate(-542.915 -158.037)" fill="#010101" />
                  </g>
                </g>
                <g id="Group_412" data-name="Group 412" transform="translate(0.015 1.13)">
                  <g id="Group_411" data-name="Group 411">
                    <path id="Path_242" data-name="Path 242" d="M543.384,160.146a1.062,1.062,0,0,1,1.261.033c.322.232.63-.3.31-.531a1.654,1.654,0,0,0-1.882-.033c-.33.217-.022.75.31.531Z" transform="translate(-542.933 -159.337)" fill="#010101" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_423" data-name="Group 423" transform="translate(0 13.779)">
            <g id="Group_422" data-name="Group 422">
              <g id="Group_416" data-name="Group 416">
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="3.423" cy="3.423" r="3.423" fill="#ffdac3" />
              </g>
              <g id="Group_421" data-name="Group 421" transform="translate(1.816 2.342)">
                <g id="Group_418" data-name="Group 418">
                  <g id="Group_417" data-name="Group 417">
                    <path id="Path_243" data-name="Path 243" d="M514.741,159.566a1.844,1.844,0,0,0-2.727-1.3c-.35.186-.04.717.31.531a1.229,1.229,0,0,1,1.824.93c.066.39.659.224.593-.164Z" transform="translate(-511.86 -158.037)" fill="#010101" />
                  </g>
                </g>
                <g id="Group_420" data-name="Group 420" transform="translate(0.714 1.13)">
                  <g id="Group_419" data-name="Group 419">
                    <path id="Path_244" data-name="Path 244" d="M514.7,159.614a1.654,1.654,0,0,0-1.882.033c-.318.23-.011.763.31.531a1.062,1.062,0,0,1,1.261-.033c.332.218.641-.314.31-.531Z" transform="translate(-512.681 -159.337)" fill="#010101" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_424" data-name="Group 424" transform="translate(4.802)">
            <path id="Path_245" data-name="Path 245" d="M527.273,170.166h0a11.979,11.979,0,0,0,11.979-11.979v-6.709a11.98,11.98,0,0,0-11.979-11.98h0a11.98,11.98,0,0,0-11.979,11.98v6.709A11.979,11.979,0,0,0,527.273,170.166Z" transform="translate(-515.294 -139.498)" fill="#ffdac3" />
          </g>
          <g id="Group_441" data-name="Group 441" transform="translate(9.58 11.314)">
            <g id="Group_428" data-name="Group 428" transform="translate(6.005 2.164)">
              <g id="Group_427" data-name="Group 427">
                <path id="Path_248" data-name="Path 248" d="M528.837,155.257q.133,1.308.264,2.618c.05.489.513,3.662-1.006,2.709-.3-.185-.568.283-.273.468a1.227,1.227,0,0,0,1.857-.76,5.34,5.34,0,0,0-.016-2.215l-.284-2.82c-.035-.343-.577-.348-.542,0Z" transform="translate(-527.695 -154.998)" fill="#010101" />
              </g>
            </g>
            <g id="Group_440" data-name="Group 440" transform="translate(0 0)">
              <g id="Group_436" data-name="Group 436" transform="translate(1.6 10.163)">
                <path id="Path_254" data-name="Path 254" d="M532.56,169.1h0c2.931,0,5.31-1.368,5.31-3.054v-2.706H527.247v2.706C527.247,167.73,529.625,169.1,532.56,169.1Z" transform="translate(-526.813 -163.089)" fill="#fff" />
              </g>
              <g id="Group_437" data-name="Group 437" transform="matrix(0.978, 0.208, -0.208, 0.978, 0.286, 0.045)">
                <path id="Path_255" data-name="Path 255" d="M.446,1.375A.445.445,0,0,1,.345.5L2.434.013a.445.445,0,1,1,.2.868L.546,1.364A.436.436,0,0,1,.446,1.375Z" transform="translate(0 0)" fill="#010101" />
              </g>
              <g id="Group_438" data-name="Group 438" transform="translate(11.26 0.721) rotate(-14)">
                <path id="Path_256" data-name="Path 256" d="M2.534,1.375a.435.435,0,0,1-.1-.011L.345.881a.445.445,0,0,1,.2-.868L2.634.5a.445.445,0,0,1-.1.879Z" transform="translate(0 0)" fill="#010101" />
              </g>
              <g id="Group_439" data-name="Group 439" transform="translate(1.6 13.278)">
                <path id="Path_257" data-name="Path 257" d="M532.571,167.868c-2.714,0-4.933-1.145-5.3-2.627a21.811,21.811,0,0,1,10.58.113C537.4,166.781,535.213,167.868,532.571,167.868Z" transform="translate(-526.835 -164.632)" fill="#e82365" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_445" data-name="Group 445" transform="translate(1.931 19.513)">
          <g id="Group_444" data-name="Group 444">
            <path id="Path_259" data-name="Path 259" d="M514.1,162.994a1.056,1.056,0,1,1-1.056-1.056A1.056,1.056,0,0,1,514.1,162.994Z" transform="translate(-511.993 -161.938)" fill="#d8f01d" />
          </g>
        </g>
        <g id="Group_448" data-name="Group 448" transform="translate(29.456 19.513)">
          <g id="Group_447" data-name="Group 447">
            <path id="Path_261" data-name="Path 261" d="M545.759,162.994a1.056,1.056,0,1,1-1.057-1.056A1.057,1.057,0,0,1,545.759,162.994Z" transform="translate(-543.647 -161.938)" fill="#d8f01d" />
          </g>
        </g>
      </g>
      <g id="Group_451" data-name="Group 451" transform="translate(4.421 1.174)">
        <path id="Path_262" data-name="Path 262" d="M517.852,149.488h-4.226s3.417-13.145,14.18-13.145,12.133,13.145,12.133,13.145h-9.446l-.64-2.13-.8,2.13h-1.618l-1.694-4.007-1.69,4.007h-6.2Z" transform="translate(-513.626 -136.343)" fill="#010101" />
      </g>
      <g id="Group_788" data-name="Group 788" transform="translate(11.436 19.596)">
        <g id="Group_482" data-name="Group 482">
          <path id="Path_279" data-name="Path 279" d="M583.822,158.337l-.008-.268c-.022-.718-1.142-.722-1.12,0l.009.268c.021.719,1.141.723,1.119,0Z" transform="translate(-582.694 -157.529)" fill="#010101" />
        </g>
      </g>
      <g id="Group_789" data-name="Group 789" transform="translate(22.741 19.596)">
        <g id="Group_482-2" data-name="Group 482">
          <path id="Path_279-2" data-name="Path 279" d="M583.822,158.337l-.008-.268c-.022-.718-1.142-.722-1.12,0l.009.268c.021.719,1.141.723,1.119,0Z" transform="translate(-582.694 -157.529)" fill="#010101" />
        </g>
      </g>
    </svg>
  );
}

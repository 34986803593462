import styled from 'styled-components';

export const ContainerStyled = styled.div`
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 45px 30px;
  .theme_con_standard {
  }
`;

import React from "react";
import { LoginFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons, Icons } from "components";

const LoginForm = ({ onSubmit, handleForgotPassword, btnDisabled }) => {
  const schema = yup.object().shape({
    username: yup.string().nullable().required("Username is required"),
    password: yup.string().nullable().required("Password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <LoginFormStyled onSubmit={handleSubmit(onSubmit)}>
      <div className="form_container">
        <div className="logo_icon">
          เข้าสู่ระบบ
          <div className="show_svg">
            <Icons.Enter color="#002366" />
          </div>
        </div>
        <div className="box_input">
          <div className="group_input">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTxtIcon
                  theme_not_icon_standard
                  {...field}
                  // iconSvg={<Icons.User />}
                  label="ชื่อผู้ใช้"
                  placeholder="example@gmail.com"
                  errors={errors.username?.message}
                />
              )}
              name="username"
              defaultValue=""
            />
          </div>
          <div className="group_input no_margin">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTxtIcon
                  theme_not_icon_standard
                  {...field}
                  // iconSvg={<Icons.PaddLock />}
                  label="รหัสผ่าน"
                  placeholder="รหัสผ่าน"
                  changeType="text"
                  type="password"
                  showIconHide
                  errors={errors.password?.message}
                />
              )}
              name="password"
              defaultValue=""
            />
          </div>
        </div>
        <div
          className="forgot_password"
          onClick={handleForgotPassword && handleForgotPassword}
        >
          ลืมรหัสผ่าน
        </div>
        <div className="btn_layout">
          <Buttons.BtnNormal
            theme_standard_btn_log_in
            label="เข้าสู่ระบบ"
            type="submit"
            disabled={btnDisabled}
          />
        </div>
        <div className="box_logo">
          <img
            className="img_logo"
            src={"/assets/images/logo/logo_login_well.svg"}
            alt="img"
          />
        </div>
      </div>
    </LoginFormStyled>
  );
};

LoginForm.propTypes = {};

export default LoginForm;

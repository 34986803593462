export default function GroupPeople3({ color = "#707070" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.381" height="28.905" viewBox="0 0 27.381 28.905">
      <g id="group_1_" data-name="group (1)" transform="translate(0 0)">
        <g id="Group_15975" data-name="Group 15975" transform="translate(0 0)">
          <g id="Group_15974" data-name="Group 15974" transform="translate(0)">
            <path id="Path_20363" data-name="Path 20363" d="M203.851,6.022a3.011,3.011,0,1,0-3.011-3.011A3.01,3.01,0,0,0,203.851,6.022Z" transform="translate(-190.264)" fill="#707070" />
            <path id="Path_20364" data-name="Path 20364" d="M355.019,27.356a3.011,3.011,0,1,0-3.011-3.011A3.01,3.01,0,0,0,355.019,27.356Z" transform="translate(-332.898 -20.13)" fill="#707070" />
            <path id="Path_20365" data-name="Path 20365" d="M56.352,27.356a3.011,3.011,0,1,0-3.011-3.011A3.01,3.01,0,0,0,56.352,27.356Z" transform="translate(-51.092 -20.13)" fill="#707070" />
            <path id="Path_20366" data-name="Path 20366" d="M352.343,158.144l-.772-6.172a3.015,3.015,0,0,0-2.987-2.638h-2.974a2.98,2.98,0,0,0-1,.177.6.6,0,0,0-.381.723,4.911,4.911,0,0,1,.142.684l.8,6.995a3.252,3.252,0,0,1-.794,2.532,3.006,3.006,0,0,1-.508.442.6.6,0,0,0-.25.532l.473,6.625a.6.6,0,0,0,.6.56h4.817a.6.6,0,0,0,.6-.559l.563-7.875a1.8,1.8,0,0,0,1.673-2.026Z" transform="translate(-324.977 -140.903)" fill={color} />
            <path id="Path_20367" data-name="Path 20367" d="M21.951,161.023a3.179,3.179,0,0,1-.673-.559,3.252,3.252,0,0,1-.794-2.532l.8-6.995a4.716,4.716,0,0,1,.152-.733.6.6,0,0,0-.428-.744,2.961,2.961,0,0,0-.76-.106H17.274a3.014,3.014,0,0,0-2.987,2.638l-.772,6.171a1.807,1.807,0,0,0,1.674,2.027l.562,7.875a.6.6,0,0,0,.6.559H21.17a.6.6,0,0,0,.6-.559l.464-6.488A.6.6,0,0,0,21.951,161.023Z" transform="translate(-13.501 -140.922)" fill={color} />
            <path id="Path_20368" data-name="Path 20368" d="M169.008,137.92l-.8-7a3.187,3.187,0,0,0-3.07-2.924h-3.075a3.186,3.186,0,0,0-3.07,2.924l-.8,6.995a2.043,2.043,0,0,0,.5,1.595,1.78,1.78,0,0,0,1.237.608l.665,9a.6.6,0,0,0,.6.558h4.817a.6.6,0,0,0,.6-.558l.665-9a1.782,1.782,0,0,0,1.237-.608A2.047,2.047,0,0,0,169.008,137.92Z" transform="translate(-150.014 -120.774)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}

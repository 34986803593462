import { TotalLikeAndExchangeStyled } from './styled';
import { Blocks, Icons, Typographys } from 'components';
import { nFormatter } from 'utils/functions/FormatMoney';

const TotalLikeAndExchange = ({ data }) => {
  return (
    <TotalLikeAndExchangeStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="32px"
        paddingRight="24px"
        paddingBottom="32px"
        paddingLeft="24px"
      >
        <div className="container_exchange">
          <div className="group_line">
            <div className="show_icon">
              <Icons.RoundHeart />
            </div>
            <div className="group_label">
              <Typographys.TopBottom
                theme_left_align
                label="ยอดไลค์ทั้งหมด"
                labelBottom="Total Like"
                fontSizeLabelButtom="14px" 
              />
              <div className="highlight">
                {nFormatter(Number(data?.like_count))}
              </div>
            </div>
          </div>
          <div className="group_line mt">
            <div className="show_icon">
              <Icons.Dumbel2 width="40" height="40" />
            </div>
            <div className="group_label">
              <Typographys.TopBottom
                theme_left_align
                label="แลกไปแล้ว"
                labelBottom="Exchange for"
                fontSizeLabelButtom="14px"
              />
              <div className="highlight gold">
                {data &&
                  nFormatter(Number(data.redeemed_count * data.default_coin_amount))}
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </TotalLikeAndExchangeStyled>
  );
};

export default TotalLikeAndExchange;

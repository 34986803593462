export default function ManGraph() {
  return (
    <svg id="Group_15007" data-name="Group 15007" xmlns="http://www.w3.org/2000/svg" width="33.103" height="35" viewBox="0 0 33.103 35">
      <g id="Group_47" data-name="Group 47" transform="translate(3.024 2.067)">
        <g id="Group_45" data-name="Group 45" transform="translate(0.013 9.022)">
          <path id="Path_186" data-name="Path 186" d="M216.784,372.587v11.008c.177,2.554,2.136,5.092,5.75,6.876a23.738,23.738,0,0,0,15.89,1.381V372.587Z" transform="translate(-216.784 -372.587)" fill="#036f7c" />
          <path id="Path_187" data-name="Path 187" d="M281.689,383.595v-7.144l-8.412-3.864v19.265a15.588,15.588,0,0,0,5.235-2.4C280.806,387.747,281.833,385.665,281.689,383.595Z" transform="translate(-251.637 -372.587)" fill="#d5751e" />
        </g>
        <path id="Path_188" data-name="Path 188" d="M219.94,353.515c-2.294,1.7-3.32,3.782-3.177,5.853h15.02v-9.015C227.32,350.246,222.9,351.317,219.94,353.515Z" transform="translate(-216.75 -350.345)" fill="#fd6524" />
        <g id="Group_46" data-name="Group 46" transform="translate(0.013 2.793)">
          <path id="Path_189" data-name="Path 189" d="M216.784,385.9c.177,2.554,2.136,5.092,5.75,6.876a23.739,23.739,0,0,0,15.89,1.381L231.8,385.9Z" transform="translate(-216.784 -374.272)" fill="#028a9a" />
          <path id="Path_190" data-name="Path 190" d="M271.027,366.245c-.176-2.554-2.136-5.091-5.75-6.877a22.452,22.452,0,0,0-9.283-2.139v9.015l6.62,8.257a15.59,15.59,0,0,0,5.235-2.4C270.143,370.4,271.17,368.316,271.027,366.245Z" transform="translate(-240.974 -357.23)" fill="#fb9334" />
        </g>
        <path id="Path_37083" data-name="Path 37083" d="M0,0H15.02V5.4H0Z" transform="translate(0.013 9.022)" fill="#db4b0e" />
        <path id="Path_191" data-name="Path 191" d="M262.615,387.711l-6.62-8.257v2.616l6.62,8.257Z" transform="translate(-240.961 -367.646)" fill="#d5751e" />
      </g>
      <g id="Group_54" data-name="Group 54" transform="translate(0 0)">
        <path id="Path_192" data-name="Path 192" d="M227.462,362.453a24.8,24.8,0,0,0-1.012,4.6c.071.791,1.333,1.59,1.546.975.259-.744.73-2.43.887-2.862a36.969,36.969,0,0,0,1.007-3.569,8.83,8.83,0,0,1,1.5-4.421,1.078,1.078,0,0,0-1.217.122,7.908,7.908,0,0,0-1.374,1.878C228.631,359.537,227.81,361.532,227.462,362.453Z" transform="translate(-219.71 -352.257)" fill="#4f2525" />
        <path id="Path_193" data-name="Path 193" d="M210.18,418.161c-.536.554-.7.713-1.2,1.228-.291.3-.051.577.5.554a9.464,9.464,0,0,0,2.207-.506,10.991,10.991,0,0,0,1.652-.912,1.16,1.16,0,0,0,.49-.657v-.924l-2.639.119S210.759,417.563,210.18,418.161Z" transform="translate(-208.855 -387.862)" fill="#5e2e2e" />
        <path id="Path_194" data-name="Path 194" d="M222.3,424.319c-.536.554-.7.714-1.2,1.228-.291.3-.051.576.5.554a9.471,9.471,0,0,0,2.207-.506,10.975,10.975,0,0,0,1.652-.912,1.159,1.159,0,0,0,.491-.657V422.4l-2.639.825S222.879,423.72,222.3,424.319Z" transform="translate(-216.332 -391.102)" fill="#5e2e2e" />
        <path id="Path_195" data-name="Path 195" d="M227.269,384.278,222,382.131a15.064,15.064,0,0,1-2.452,1.9c-.928.593-3.482,2.183-4.3,2.8-.5.377-.73,1.191-.927,3.023-.324,3.027-.562,6.483-.562,6.483a2.256,2.256,0,0,0,1.862.84,4.782,4.782,0,0,0,1.734-.331l.2-6.965,4.316-2.641s-2.448,1.605-2.8,3.029a14.818,14.818,0,0,0-.42,3.45c-.092,3.077-.123,5.09-.123,5.09a1.375,1.375,0,0,0,1.484,1.015,2.913,2.913,0,0,0,1.81-.859l.261-7.051a45.048,45.048,0,0,0,4.7-4C227.881,386.57,227.269,384.278,227.269,384.278Z" transform="translate(-211.879 -367.17)" fill="#4f2525" />
        <g id="Group_49" data-name="Group 49" transform="translate(6.502)">
          <path id="Path_196" data-name="Path 196" d="M230.361,357.86a5.539,5.539,0,0,0-1.764,3.254c-.093.946.148,4.19.156,6.279-.76.545-2.924,1.889-2.924,1.889a4.744,4.744,0,0,1,2.426,1.841,5.817,5.817,0,0,0,1.264-.786s-.071.212-.466,1.053c.474.152,2.1-.249,3.607.443a3.932,3.932,0,0,0,2.556.383,9.294,9.294,0,0,0,.077-2.647,18.858,18.858,0,0,1-.489-3.105c.26-2.117.649-5.54.578-6.1-.118-.937-2.124-1.952-2.124-1.952A3.123,3.123,0,0,0,230.361,357.86Z" transform="translate(-225.829 -352.639)" fill="#5e2e2e" />
          <path id="Path_197" data-name="Path 197" d="M236.051,357.682s-.921,0-1.7,1.605c-.279.574-.464.884-.464.884l.464.48-.66.335a18.581,18.581,0,0,0-.048,3.458,16.125,16.125,0,0,0,1.284,3.156,30.014,30.014,0,0,1,1-2.853c.3-.569,1.459-2.144,1.459-2.144l-.578-.923.942-.326s1.328-1.763,1.189-2.295a1.661,1.661,0,0,0-1.16-1.2Z" transform="translate(-230.604 -352.639)" fill="#4f2525" />
          <path id="Path_198" data-name="Path 198" d="M236.211,362.023s-.375,1.2-.58,2.287a24.159,24.159,0,0,0,.366,4.635s.507-3.468,1.176-4.472A22.579,22.579,0,0,1,239,362.151,8.173,8.173,0,0,0,236.211,362.023Z" transform="translate(-231.849 -355.161)" fill="#efe8eb" />
          <path id="Path_199" data-name="Path 199" d="M236.911,362.475l-.111.928a2.557,2.557,0,0,0-.628,1.177c-.111.647-.079,3.072-.079,3.072l.214,1.522.307-1.048s.237-1.442.44-2.331a5.778,5.778,0,0,1,.092-.808,6.719,6.719,0,0,0,.17-1.338l.547-.693-.522-.645Z" transform="translate(-232.159 -355.391)" fill="#ff65a7" />
          <g id="Group_48" data-name="Group 48" transform="translate(3.705 0.523)">
            <path id="Path_200" data-name="Path 200" d="M238.977,355.914l-.1,1.468a3.843,3.843,0,0,0,.65.71,8.092,8.092,0,0,0,1.664-1.307l-.388-2.279Z" transform="translate(-237.587 -351.274)" fill="#ffad97" />
            <path id="Path_201" data-name="Path 201" d="M238.938,347.266a1.767,1.767,0,0,0-3,.234c-1.024,1.6-.056,4.228.84,4.074a3.471,3.471,0,0,0,2.129-1.427A3.035,3.035,0,0,0,238.938,347.266Z" transform="translate(-235.502 -346.54)" fill="#ffc5b3" />
          </g>
          <path id="Path_202" data-name="Path 202" d="M237.77,358.966l.113,1.357-.913.394S236.877,359.28,237.77,358.966Z" transform="translate(-232.701 -353.402)" fill="#fff" />
          <path id="Path_203" data-name="Path 203" d="M241.352,358.122a7.083,7.083,0,0,1-.849,1.048,3.129,3.129,0,0,1-1.145.652l.344,1.092a7.145,7.145,0,0,0,1.724-1.146S241.973,358.767,241.352,358.122Z" transform="translate(-234.176 -352.901)" fill="#fff" />
          <path id="Path_204" data-name="Path 204" d="M235.477,347.27a1.515,1.515,0,0,0,1.367.81,3.156,3.156,0,0,0,1.223-.178l-.059.966a.747.747,0,0,0,.25-.036c.09-.367.239-.453.408-.3.191.175.164.847.164.847a2.531,2.531,0,0,0,.72-1.767c0-.69-.571-2.183-1.764-2.339a2.174,2.174,0,0,0-2.333,1.178A.936.936,0,0,0,235.477,347.27Z" transform="translate(-231.716 -345.251)" fill="#5e2e2e" />
        </g>
        <g id="Group_53" data-name="Group 53" transform="translate(3.536 12.91)">
          <g id="Group_50" data-name="Group 50" transform="translate(0.014 2.548)">
            <rect id="Rectangle_4" data-name="Rectangle 4" width="0.348" height="0.286" transform="translate(0 2.445)" fill="#b59eab" />
            <path id="Path_205" data-name="Path 205" d="M223.09,389.66l-4.8-2.932c-.252-.154-.22-.426.071-.6l3.2-1.958a1.046,1.046,0,0,1,.988-.043l4.8,2.932c.252.154.22.426-.071.6l-3.2,1.958A1.048,1.048,0,0,1,223.09,389.66Z" transform="translate(-218.124 -383.75)" fill="#b59eab" />
            <rect id="Rectangle_5" data-name="Rectangle 5" width="0.215" height="0.28" transform="translate(9.183 3.28)" fill="#b59eab" />
            <path id="Path_206" data-name="Path 206" d="M223.09,389l-4.8-2.932c-.252-.154-.22-.426.071-.6l3.2-1.958a1.045,1.045,0,0,1,.988-.043l4.8,2.932c.252.155.22.426-.071.6l-3.2,1.958A1.048,1.048,0,0,1,223.09,389Z" transform="translate(-218.124 -383.359)" fill="#eadce1" />
            <path id="Path_207" data-name="Path 207" d="M223.215,383.853l-3.381,2.065,5.038,3.064,3.39-2.059Z" transform="translate(-219.178 -383.652)" fill="#b59eab" opacity="0.5" />
            <path id="Path_208" data-name="Path 208" d="M227.49,383.767l-.259.159,5.038,3.064.268-.153Z" transform="translate(-223.742 -383.602)" fill="#eadce1" />
            <path id="Path_209" data-name="Path 209" d="M237.527,390.833l-.259.159.762.46.268-.153Z" transform="translate(-229.935 -387.801)" fill="#eadce1" />
            <path id="Path_210" data-name="Path 210" d="M235.342,389.566l-.259.159.535.327.268-.153Z" transform="translate(-228.587 -387.048)" fill="#eadce1" />
            <path id="Path_211" data-name="Path 211" d="M233.6,388.577l-.259.159.535.327.268-.153Z" transform="translate(-227.512 -386.46)" fill="#eadce1" />
            <path id="Path_212" data-name="Path 212" d="M231.867,387.584l-.259.159.535.328.268-.153Z" transform="translate(-226.443 -385.87)" fill="#eadce1" />
            <path id="Path_213" data-name="Path 213" d="M236.042,392.715l-.259.159.376.23.268-.153Z" transform="translate(-229.018 -388.92)" fill="#eadce1" />
            <path id="Path_214" data-name="Path 214" d="M234.607,391.88l-.259.159.376.23.268-.153Z" transform="translate(-228.133 -388.423)" fill="#eadce1" />
            <path id="Path_215" data-name="Path 215" d="M232.84,390.881l-.259.159.535.328.268-.153Z" transform="translate(-227.043 -387.83)" fill="#eadce1" />
            <path id="Path_216" data-name="Path 216" d="M234.886,393.384l-.259.159.376.23.268-.153Z" transform="translate(-228.305 -389.318)" fill="#eadce1" />
            <path id="Path_217" data-name="Path 217" d="M233.451,392.549l-.259.159.376.23.268-.153Z" transform="translate(-227.42 -388.821)" fill="#eadce1" />
            <path id="Path_218" data-name="Path 218" d="M233.657,394.03l-.259.159.376.23.268-.153Z" transform="translate(-227.547 -389.701)" fill="#eadce1" />
            <path id="Path_219" data-name="Path 219" d="M232.223,393.195l-.259.159.376.23.268-.153Z" transform="translate(-226.662 -389.205)" fill="#eadce1" />
            <path id="Path_220" data-name="Path 220" d="M231.684,391.55l-.259.159.535.328.268-.153Z" transform="translate(-226.33 -388.227)" fill="#eadce1" />
            <path id="Path_221" data-name="Path 221" d="M231.158,389.892l-.259.159.535.327.268-.153Z" transform="translate(-226.006 -387.242)" fill="#eadce1" />
            <path id="Path_222" data-name="Path 222" d="M229.425,388.9l-.259.159.535.327.268-.153Z" transform="translate(-224.936 -386.651)" fill="#eadce1" />
            <path id="Path_223" data-name="Path 223" d="M235.973,391.286l-.259.159.892.539.268-.153Z" transform="translate(-228.976 -388.07)" fill="#eadce1" />
            <path id="Path_224" data-name="Path 224" d="M234.126,390.215l-.259.159.535.327.268-.153Z" transform="translate(-227.836 -387.434)" fill="#eadce1" />
            <path id="Path_225" data-name="Path 225" d="M232.384,389.225l-.259.159.535.327.268-.153Z" transform="translate(-226.762 -386.846)" fill="#eadce1" />
            <path id="Path_226" data-name="Path 226" d="M230.651,388.232l-.259.159.535.327.268-.153Z" transform="translate(-225.692 -386.255)" fill="#eadce1" />
          </g>
          <path id="Path_227" data-name="Path 227" d="M231.8,385.619s-.786.432-1.283.694-1.97,1.607-1.657,1.917a2.514,2.514,0,0,0,1.3.621,2.647,2.647,0,0,0,.793-.729c.081-.127,1.037-.514,1.315-.956S231.8,385.619,231.8,385.619Z" transform="translate(-224.707 -382.154)" fill="#ffc5b3" />
          <g id="Group_52" data-name="Group 52">
            <g id="Group_51" data-name="Group 51">
              <path id="Path_228" data-name="Path 228" d="M224.2,380.409l.015,4.316a.4.4,0,0,1-.592.365l-4.807-2.913a.424.424,0,0,1-.2-.362l-.015-4.316a.4.4,0,0,1,.592-.365l4.807,2.914A.424.424,0,0,1,224.2,380.409Z" transform="translate(-218.407 -377.077)" fill="#9b8790" />
              <path id="Path_229" data-name="Path 229" d="M231.964,395.9l-.256.158-.311-.342.613-.4Z" transform="translate(-226.298 -387.914)" fill="#9b8790" />
              <path id="Path_230" data-name="Path 230" d="M218.781,377.254l-.175.109.458.006Z" transform="translate(-218.407 -377.182)" fill="#9b8790" />
              <path id="Path_231" data-name="Path 231" d="M223.685,380.715l.015,4.316a.4.4,0,0,1-.592.365l-4.807-2.913a.425.425,0,0,1-.2-.362l-.015-4.317a.4.4,0,0,1,.592-.365l4.807,2.913A.423.423,0,0,1,223.685,380.715Z" transform="translate(-218.087 -377.259)" fill="#ddccd3" />
            </g>
          </g>
          <path id="Path_232" data-name="Path 232" d="M220.288,390.958l-1.135-.694v-.525l1.135.694Z" transform="translate(-218.744 -384.603)" fill="#eadce1" />
          <path id="Path_233" data-name="Path 233" d="M228.366,395.628l-1.135-.694v-.525l1.135.694Z" transform="translate(-223.728 -387.378)" fill="#eadce1" />
          <path id="Path_234" data-name="Path 234" d="M224.555,386.448c0,.251-.211.326-.472.167a1.008,1.008,0,0,1-.472-.744c0-.252.211-.327.472-.167A1.009,1.009,0,0,1,224.555,386.448Z" transform="translate(-221.495 -382.154)" fill="#fff" />
        </g>
        <path id="Path_235" data-name="Path 235" d="M241.906,368.176c.3-2.262.289-4.215-.873-5.025-.844-.589-1.342,1.807-1.572,3.6-.055.43-.438,2.405-.531,2.873a25.415,25.415,0,0,0-4.014,2.64,4.182,4.182,0,0,1,1.017,1.994,25.175,25.175,0,0,0,4.62-2.337C241.542,371.378,241.763,369.236,241.906,368.176Z" transform="translate(-224.933 -355.836)" fill="#4f2525" />
      </g>
    </svg>

  );
}

import styled from "styled-components";

export const CreateUserAccountFormStyled = styled.div`
  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S32};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
        .bw132 {
          width: 132px;
        }
        .bw200 {
          width: 200px;
        }
      }
    }
  }

  .title_b {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S26};
    margin-top: 32px;
    &.mt40 {
      margin-top: 40px;
    }
  }
  .layer_two {
    display: flex;
    margin-top: 24px;
    column-gap: 49px;
    .box_50 {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      .group_input {
        &.flex_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .green_text {
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLUE_28};
          text-decoration: underline;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          cursor: pointer;
        }
        .g_b_label {
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          margin-bottom: 8px;
          .sub_gray {
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          }
        }
        .input_sub {
          &.mt17 {
            margin: 17px 0px;
          }
          .realm {
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            margin-top: 19px;
            margin-bottom: 11px;
          }
        }
      }
    }
  }

  .layer_button {
    display: flex;
    margin-top: 48px;
    justify-content: flex-end;
    column-gap: 40px;
    .w220 {
      width: 220px;
    }
  }

  .box_send {
    width: 463px;
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.35);
    padding: 32px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 16px;
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    .svg_x {
      cursor: pointer;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
    }
    .info_success {
      display: flex;
      align-items: center;
      flex-direction: column;
      .icon_succ {
        display: flex;
        margin-bottom: 20px;
      }
    }
    .bottom_success {
      margin-top: 32px;
      justify-content: center;
      display: flex;
      column-gap: 16px;
      .btn_k {
        width: 287px;
      }
    }
  }
`;

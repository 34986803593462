export default function Company2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.742" height="48" viewBox="0 0 41.742 48">
      <g id="Group_13892" data-name="Group 13892" transform="translate(-154 -64)">
        <ellipse id="Ellipse_1500" data-name="Ellipse 1500" cx="13.936" cy="2.058" rx="13.936" ry="2.058" transform="translate(161.308 107.885)" fill="#d8d8d8" />
        <g id="Group_13719" data-name="Group 13719" transform="translate(154 64)">
          <g id="_095-badge-4" data-name="095-badge-4" transform="translate(0 3.731)">
            <path id="Path_819" data-name="Path 819" d="M35.312,0v23.75a18.072,18.072,0,0,0,.361,3.6h2.518v5.758H68.418V27.349h2.518a18.079,18.079,0,0,0,.361-3.6V0Z" transform="translate(-32.433 -0.001)" fill="#bdc3c7" />
            <path id="Path_820" data-name="Path 820" d="M70.622,308.775h30.227v-8.636H70.622Z" transform="translate(-64.864 -275.669)" fill="#fd4755" />
            <g id="Group_200" data-name="Group 200" transform="translate(5.758 2.879)">
              <path id="Path_821" data-name="Path 821" d="M70.622,56.9h30.227V35.311H70.622Z" transform="translate(-70.622 -35.311)" fill="#ecf0f1" />
              <path id="Path_822" data-name="Path 822" d="M110.855,406.07a15.04,15.04,0,0,0,23.667,0Z" transform="translate(-107.575 -375.843)" fill="#ecf0f1" />
            </g>
            <path id="Path_823" data-name="Path 823" d="M376.454,335.449H370.7v5.758h-3.28a15.273,15.273,0,0,1-2.994,2.879h12.032l-2.879-4.318Z" transform="translate(-334.711 -308.101)" fill="#e33442" />
            <path id="Path_824" data-name="Path 824" d="M107.433,444.26a14.962,14.962,0,0,1-8.839-2.879H94.245a17.982,17.982,0,0,0,26.375,0h-4.348a14.963,14.963,0,0,1-8.839,2.879" transform="translate(-86.561 -405.396)" fill="#bdc3c7" />
            <path id="Path_825" data-name="Path 825" d="M12.032,344.085a15.3,15.3,0,0,1-2.994-2.879H5.758v-5.758H0l2.879,4.318L0,344.085Z" transform="translate(0 -308.101)" fill="#e33442" />
          </g>
          <g id="office-building_1_" data-name="office-building (1)" transform="translate(6.584 0)">
            <rect id="Rectangle_17963" data-name="Rectangle 17963" width="13.609" height="26.246" transform="translate(7.291 1.944)" fill="#4d5c7d" />
            <path id="Path_19030" data-name="Path 19030" d="M136.533,120.84v7.291h13.609V105.719Z" transform="translate(-129.242 -99.941)" fill="#4d5c7d" />
            <rect id="Rectangle_17964" data-name="Rectangle 17964" width="9.721" height="1.944" transform="translate(9.235)" fill="#4d5c7d" />
            <rect id="Rectangle_17965" data-name="Rectangle 17965" width="9.721" height="0.972" transform="translate(9.235 0.972)" fill="#4d5c7d" />
            <g id="Group_13718" data-name="Group 13718" transform="translate(9.235 4.374)">
              <rect id="Rectangle_17966" data-name="Rectangle 17966" width="1.944" height="1.944" fill="#87ced9" />
              <rect id="Rectangle_17967" data-name="Rectangle 17967" width="1.944" height="1.944" transform="translate(3.888)" fill="#87ced9" />
              <rect id="Rectangle_17968" data-name="Rectangle 17968" width="1.944" height="1.944" transform="translate(7.777)" fill="#87ced9" />
              <rect id="Rectangle_17969" data-name="Rectangle 17969" width="1.944" height="1.944" transform="translate(0 3.888)" fill="#87ced9" />
              <rect id="Rectangle_17970" data-name="Rectangle 17970" width="1.944" height="1.944" transform="translate(3.888 3.888)" fill="#87ced9" />
              <rect id="Rectangle_17971" data-name="Rectangle 17971" width="1.944" height="1.944" transform="translate(7.777 3.888)" fill="#87ced9" />
              <rect id="Rectangle_17972" data-name="Rectangle 17972" width="1.944" height="1.944" transform="translate(0 7.777)" fill="#87ced9" />
              <rect id="Rectangle_17973" data-name="Rectangle 17973" width="1.944" height="1.944" transform="translate(3.888 7.777)" fill="#87ced9" />
              <rect id="Rectangle_17974" data-name="Rectangle 17974" width="1.944" height="1.944" transform="translate(7.777 7.777)" fill="#87ced9" />
              <rect id="Rectangle_17975" data-name="Rectangle 17975" width="1.944" height="1.944" transform="translate(0 11.665)" fill="#87ced9" />
              <rect id="Rectangle_17976" data-name="Rectangle 17976" width="1.944" height="1.944" transform="translate(3.888 11.665)" fill="#87ced9" />
              <rect id="Rectangle_17977" data-name="Rectangle 17977" width="1.944" height="1.944" transform="translate(7.777 11.665)" fill="#87ced9" />
              <rect id="Rectangle_17978" data-name="Rectangle 17978" width="1.944" height="1.944" transform="translate(0 15.553)" fill="#87ced9" />
              <rect id="Rectangle_17979" data-name="Rectangle 17979" width="1.944" height="1.944" transform="translate(3.888 15.553)" fill="#87ced9" />
              <rect id="Rectangle_17980" data-name="Rectangle 17980" width="1.944" height="1.944" transform="translate(7.777 15.553)" fill="#87ced9" />
              <rect id="Rectangle_17981" data-name="Rectangle 17981" width="5.832" height="3.888" transform="translate(1.944 19.927)" fill="#87ced9" />
            </g>
            <rect id="Rectangle_17982" data-name="Rectangle 17982" width="0.972" height="3.888" transform="translate(13.609 24.302)" fill="#61acc9" />
            <path id="Path_19031" data-name="Path 19031" d="M12.8,322.515a1.96,1.96,0,0,0,.037-1.1,1.926,1.926,0,0,0-1.416-1.4,1.945,1.945,0,0,0-2.344,2.4,2.808,2.808,0,0,1-.228,2.164,2.43,2.43,0,1,0,4.249.071A2.734,2.734,0,0,1,12.8,322.515Z" transform="translate(-8.527 -302.466)" fill="#97d729" />
            <rect id="Rectangle_17983" data-name="Rectangle 17983" width="0.972" height="8.749" transform="translate(1.945 19.442)" fill="#79a125" />
            <path id="Path_19032" data-name="Path 19032" d="M422.4,322.515a1.96,1.96,0,0,0,.037-1.1,1.926,1.926,0,0,0-1.416-1.4,1.945,1.945,0,0,0-2.344,2.4,2.81,2.81,0,0,1-.229,2.166,2.43,2.43,0,1,0,4.249.071A2.735,2.735,0,0,1,422.4,322.515Z" transform="translate(-394.797 -302.466)" fill="#97d729" />
            <rect id="Rectangle_17984" data-name="Rectangle 17984" width="0.972" height="8.749" transform="translate(25.274 19.442)" fill="#79a125" />
          </g>
        </g>
      </g>
    </svg>
  );
}

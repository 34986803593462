import styled from 'styled-components';

export const UploadImageStyled = styled.div`
  .theme_standard_upload_image {
    .title_upload {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      .show_svg {
        display: flex;
      }
      .group_label {
        margin-left: 17px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        .label_1 {
          text-align: center;
        }
        .label_2 {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
    }
    .body_upload {
      border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_3};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 32px;
      position: relative;
      overflow: hidden;
      width: 360px;
      height: 257px;
      &.no_border {
        border: none;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .show_img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .btn_upload {
      padding: 0% 10%;
    }
  }
`;

import { AllParticipantStyled } from './styled';
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { Blocks, Label, Displays, Icons } from 'components';

const AllParticipant = ({ total, maleCount, famaleCount }) => {
  return (
    <AllParticipantStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="16px"
        paddingBottom="16px"
        paddingLeft="16px"
      // height='275px'
      >
        <div className="top_view">
          <div className='show_icon'>
            <Icons.AllKid />
          </div>
          <Label.TopBottom
            theme_left_align_3
            label="ข้อมูลผู้เข้าร่วมทั้งหมด"
            labelBottom="All participant profiles"
          />
        </div>
        <div className="mid_view">
          {/* <div className="icon_show">ทั้งหมด</div> */}
          <div className="show_num">{total}</div>
          <div className="icon_show">
            <div className='ic_m'>
              <Icons.ThreeMan />
            </div>
            <div>
              คน
            </div>
          </div>
        </div>
        {/* <div className="data_icon">
          <div className="group_icon">
            <div className="icon_zone">
              <Icons.Men />
            </div>
            <div>เพศชาย</div>
            <div className="value_m">
              {RenderCommaMoney(Number(maleCount ? maleCount : 0).toFixed(0))}
            </div>
            <div>คน</div>
          </div>
          <div className="group_icon no_border">
            <div className="icon_zone">
              <Icons.Women />
            </div>
            <div>เพศหญิง</div>
            <div className="value_m pink">
              {RenderCommaMoney(Number(famaleCount ? famaleCount : 0).toFixed(0))}
            </div>
            <div>คน</div>
          </div>
        </div> */}
        <div className="bot_view">
          <Displays.BarPercent2
            theme_normal_bar_percent3
            svgFront={<Icons.Male4 />}
            svgBack={<Icons.Female3 />}
            leftValue={maleCount}
            leftValuePercent={maleCount ? ((maleCount * 100) / total).toFixed(2) : 0}
            rightValue={famaleCount}
            rightValuePercent={famaleCount ? ((famaleCount * 100) / total).toFixed(2) : 0}
          />
        </div>
      </Blocks.Box>
    </AllParticipantStyled>
  );
};

export default AllParticipant;

import React, { useState, useEffect } from "react";
import { CreateAccountFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Icons, Buttons, Modal, Forms, BGLoading } from "components";
import theme from "styles/theme.json";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { renderErrorMessage } from "utils/functions/renderErrorMessage";

const CreateAccountForm = ({
  onSubmit,
  initialValues,
  handleBack,
  perMissions,
  handleDelete,
  disabled,
  disabledButtonInvite,
  hadleSendInvite,
  RoleOption,
  errorMessage,
  companySelectedRedux,
  handleClearTextError,
  isCannotEditDelete = false,
  authenRedux,
  isProfile = false,
}) => {
  const schema = yup.object().shape({
    // username: yup.string()
    //   .required("Username is required")
    //   .matches(/^[a-zA-Z0-9]+$/, "format username ไม่ถูกต้อง"), //eslint-disable-line
    // firstname: yup.string().required("Firstname is required"),
    // lastname: yup.string().required("Lastname is required"),
    // email: yup
    //   .string()
    //   .email("Email is required")
    //   .matches(
    //     /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    //     "Email is not valid"
    //   ), //eslint-disable-line
    // role: yup.object().required("Role is required"),

    username: yup
      .string()
      .required("Username is required")
      .min(4, "Minimum 4 characters")
      .max(191, "Maximum 191 characters"),
    firstname: yup.string().required("Firstname is required"),
    lastname: yup.string().required("Lastname is required"),
    password: yup
      .string()
      .min(4, "Minimum 4 characters")
      .max(191, "Maximum 32 characters"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const [errorSubmitMsg, setErrorSubmitMsg] = useState();
  const [_isShowModal, _setIsModal] = useState();
  const [_isBgLoading, _setIsBgLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
        role: {
          label: initialValues.admin_role.name,
          value: initialValues.admin_role.id,
        },
      };

      reset(params);
      // reset({
      //   ...initialValues,
      //   role: {
      //     label: initialValues.admin_role.name,
      //     value: initialValues.admin_role.id,
      //   },
      // });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDisabledIcon = () => {
    if (
      disabled ||
      !watchAllFields ||
      !watchAllFields.email ||
      watchAllFields.email === "" ||
      !watchAllFields.username ||
      watchAllFields.username === "" ||
      !watchAllFields.firstname ||
      watchAllFields.firstname === "" ||
      !watchAllFields.lastname ||
      watchAllFields.lastname === "" ||
      !watchAllFields.role
    ) {
      return "#ACACAC";
    } else {
      return "#002366";
    }
  };

  const renderDisabled = () => {
    return (
      disabled ||
      !watchAllFields ||
      !watchAllFields.email ||
      watchAllFields.email === "" ||
      !watchAllFields.username ||
      watchAllFields.username === "" ||
      !watchAllFields.firstname ||
      watchAllFields.firstname === "" ||
      !watchAllFields.lastname ||
      watchAllFields.lastname === "" ||
      !watchAllFields.role
    );
  };

  // const renderDisabledIcon = () => {
  //   if (
  //     disabled ||
  //     !watchAllFields ||
  //     !watchAllFields.username ||
  //     watchAllFields.username === "" ||
  //     !watchAllFields.firstname ||
  //     watchAllFields.firstname === "" ||
  //     !watchAllFields.lastname ||
  //     watchAllFields.lastname === ""
  //   ) {
  //     return "#ACACAC";
  //   } else {
  //     return "#002366";
  //   }
  // };

  // const renderDisabled = () => {
  //   return (
  //     disabled ||
  //     !watchAllFields ||
  //     !watchAllFields.username ||
  //     watchAllFields.username === "" ||
  //     !watchAllFields.firstname ||
  //     watchAllFields.firstname === "" ||
  //     !watchAllFields.lastname ||
  //     watchAllFields.lastname === ""
  //   );
  // };

  const _onSubmit = (values) => {
    let params = {
      ...values,
    };

    if (values && values.is_active && values.is_active.value === null) {
      delete params.is_active;
    }

    onSubmit(params, (err) => {
      setErrorSubmitMsg(err);
    });
  };

  const handleClickCloseModal = () => {
    _setIsModal(null);
  };

  const handleSendPassword = () => {
    _setIsModal(
      <Forms.ChangePasswordForm
        onSubmit={submitSendPassword}
        handleCancel={handleClickCloseModal}
        isResetPassword={false}
        height="529px"
      />
    );
  };

  const submitSendPassword = async (value) => {
    _setIsBgLoading(true);

    let params = {
      old_password: value.current_password,
      password: value.password,
    };

    let res = await adminService.PATCH_UPDATE_MY_ADMIN(params);

    if (res && res.success) {
      // toast.success("เปลี่ยนรหัสผ่านสำเร็จ");
      // handleClickCloseModal();
      _setIsBgLoading(false);

      _setIsModal(
        <div className="change_password_success_box">
          <div className="svg_check">
            <Icons.RoundCheck />
          </div>
          <div>เปลี่ยนรหัสผ่านสำเร็จ</div>
          <div className="btn_k" onClick={handleClickCloseModal}>
            รับทราบ
          </div>
        </div>
      );
    } else {
      if (res.error_code === "DUPLICATE_PASSWORD") {
        _setIsModal(
          <Forms.ChangePasswordForm
            onSubmit={submitSendPassword}
            handleCancel={handleClickCloseModal}
            isResetPassword={false}
            height="529px"
            DuplicatePassword={true}
          />
        );
      } else {
        toast.error(renderErrorMessage(res.error_code + "_PROFILE", res.error));
      }
      _setIsBgLoading(false);
    }
  };

  return (
    <CreateAccountFormStyled>
      <BGLoading visible={_isBgLoading} />
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="layer_one">
          <div className={`head_wrap_layout ${isProfile ? "mb61" : ""}`}>
            <div className="h_33">
              {!isProfile && (
                <Buttons.BtnLink
                  theme_standard_btn_link
                  svg={<Icons.RoundArrowLeft2 />}
                  onClick={handleBack}
                />
              )}
            </div>
            <div className="h_33 center">
              <div className="text_c">
                {isCannotEditDelete
                  ? "รายละเอียด"
                  : isProfile
                  ? "ตั้งค่าบัญชี"
                  : initialValues
                  ? "เเก้ไขบัญชี"
                  : "สร้างบัญชี"}
              </div>
              <div className="g_h_text">
                {isCannotEditDelete
                  ? "Detail Account"
                  : isProfile
                  ? "Account Setting"
                  : initialValues
                  ? "Edit Account"
                  : "Create Account"}
              </div>
            </div>
            <div className="h_33 flex">
              {initialValues &&
                !isCannotEditDelete &&
                !isProfile &&
                perMissions &&
                perMissions.key === "admin_manage" && (
                  <div className="bw132">
                    <Buttons.BtnNormal
                      theme_red_border_bg_white
                      label="ลบบัญชี"
                      svg_front={<Icons.Delete5 />}
                      onClick={() => handleDelete && handleDelete("delete")}
                    />
                  </div>
                )}
              {!isCannotEditDelete && (
                <div className="bw140">
                  <Buttons.BtnNormal
                    theme_standard_btn_log_in
                    backgroundColor={theme.COLORS.BLUE_21}
                    type="submit"
                    label={initialValues ? "บันทึก" : "บันทึก"}
                    disabled={renderDisabled()}
                    svg_front={
                      initialValues ? (
                        <Icons.Disk color={renderDisabledIcon()} />
                      ) : (
                        <Icons.Disk color={renderDisabledIcon()} />
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!isCannotEditDelete && !isProfile && (
          <div className="status_zone">
            {initialValues && initialValues.is_active !== null && (
              <div className="box_w_status">
                <div>สถานะบัญชี :</div>
                <div>
                  {/* <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDropDownNormal
                        {...field}
                        theme_account
                        iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                        iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                        placeholder="Status"
                        options={optStatus && optStatus}
                        errors={errors.status?.message}
                      />
                    )}
                    name="is_active"
                    defaultValue={optStatus[0]}
                  /> */}
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSwitch
                        {...field}
                        errors={errors.is_active?.message}
                      />
                    )}
                    name="is_active"
                    defaultValue={false}
                  />
                </div>
              </div>
            )}
            {initialValues && initialValues.is_active === null && (
              <div className="w161">
                <Buttons.BtnNormal
                  theme_only_border
                  label="ส่งคำเชิญอีกครั้ง"
                  padding="11.2px 12px"
                  fontColor={theme.COLORS.BLUE_28}
                  borderColor={theme.COLORS.BLUE_28}
                  disabled={disabledButtonInvite}
                  svg_front={
                    <Icons.Sync
                      color={disabledButtonInvite ? "#fff" : "#2CA5B7"}
                    />
                  }
                  onClick={() => {
                    hadleSendInvite && hadleSendInvite("invite");
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="title_b">ข้อมูลส่วนตัว</div>
        <div className="top_form border">
          <div className="l_top_form">
            <div className="group_input">
              <div className="g_b_label">
                <div>ชื่อ</div>
                <div className="sub_gray">Name</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="ชื่อ"
                      {...field}
                      errors={errors.firstname?.message}
                      disabled={isCannotEditDelete}
                      onChangeCustom={
                        handleClearTextError && handleClearTextError
                      }
                      maxLength={255}
                    />
                  )}
                  name="firstname"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="l_top_form">
            <div className="group_input">
              <div className="g_b_label">
                <div>นามสกุล</div>
                <div className="sub_gray">Surname</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="นามสกุล"
                      {...field}
                      errors={errors.lastname?.message}
                      disabled={isCannotEditDelete}
                      onChangeCustom={
                        handleClearTextError && handleClearTextError
                      }
                      maxLength={255}
                    />
                  )}
                  name="lastname"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="title_b">ข้อมูลการใช้งาน</div>
        <div className="top_form">
          <div className="l_top_form">
            <div className="group_input">
              <div className="g_b_label">
                <div>ชื่อผู้ใช้งาน</div>
                <div className="sub_gray">Username</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      iconSvg={<Icons.User />}
                      placeholder="Username"
                      disabled={initialValues && true}
                      {...field}
                      errors={errors.username?.message}
                      onChangeCustom={
                        handleClearTextError && handleClearTextError
                      }
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
              {errorMessage && errorMessage.username && (
                <div className="error_show">{errorMessage.username}</div>
              )}
            </div>
            {/* <div className="group_input">
              <div className="g_b_label">
                <div>รหัสผ่าน</div>
                <div className="sub_gray">Password</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="Password"
                      type="password"
                      disabled={isCannotEditDelete}
                      {...field}
                      errors={errors.password?.message}
                    />
                  )}
                  name="password"
                  defaultValue=""
                />
              </div>
            </div> */}
            {/* <div className="group_input">
              <div className="g_b_label">
                <div>Re-Password</div>
                <div className="sub_gray">Re-Password</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="Re-Password"
                      type="password"
                      disabled={isCannotEditDelete}
                      {...field}
                      errors={errors.passwordConfirmation?.message}
                    />
                  )}
                  name="passwordConfirmation"
                  defaultValue=""
                />
              </div>
            </div> */}
            <div className="group_input">
              <div className="g_b_label">
                <div>อีเมล</div>
                <div className="sub_gray">Email</div>
              </div>
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      iconSvg={<Icons.User />}
                      placeholder="อีเมล"
                      disabled={isCannotEditDelete}
                      onChangeCustom={
                        handleClearTextError && handleClearTextError
                      }
                      {...field}
                      errors={errors.email?.message}
                    />
                  )}
                  name="email"
                  defaultValue=""
                />
              </div>
              {errorMessage && errorMessage.email && (
                <div className="error_show">{errorMessage.email}</div>
              )}
            </div>
            {isProfile && (
              <div className="group_input flex_row">
                <div className="g_b_label">
                  <div>รหัสผ่าน</div>
                  <div className="sub_gray">Password</div>
                </div>
                <div className="purple_text" onClick={handleSendPassword}>
                  ตั้งค่ารหัสผ่าน
                </div>
              </div>
            )}
          </div>
          <div className="l_top_form">
            <div className="group_input">
              <div className="g_b_label">
                <div>บริษัท</div>
                <div className="sub_gray">Company</div>
              </div>
              <div className="input_sub">
                <div className="realm">
                  {/* {authenRedux &&
                    authenRedux.company &&
                    authenRedux.company.realm} */}
                  {companySelectedRedux && companySelectedRedux.realm}
                </div>
              </div>
            </div>
            <div className="group_input">
              <div className="g_b_label">
                <div>ตำแหน่ง</div>
                <div className="sub_gray">Role</div>
              </div>
              <div className="input_sub">
                {!isProfile ? (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDropDownNormal
                        {...field}
                        theme_content
                        iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                        iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                        disabled={isCannotEditDelete}
                        hideIcons={isCannotEditDelete}
                        placeholder="Role"
                        options={RoleOption && RoleOption}
                        errors={errors.role?.message}
                        onChangeCustom={
                          handleClearTextError && handleClearTextError
                        }
                      />
                    )}
                    name="role"
                    defaultValue={RoleOption[0]}
                  />
                ) : (
                  <div className="realm">
                    {watchAllFields &&
                      watchAllFields.role &&
                      watchAllFields.role.label}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_form">
          {errorSubmitMsg && (
            <div className="err_submit_msg">{`* ${errorSubmitMsg} *`}</div>
          )}
        </div>

        {/* <div className="top_form">
          <div className="l_top_form">
            <div className="group_input">
              <Label.TopBottom
                theme_left_align_2
                label="ชื่อ"
                labelBottom="Name"
                fontSize="24x"
                fontSizeLabelButtom="18px"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="ชื่อ"
                      {...field}
                      errors={errors.firstname?.message}
                      disabled={isCannotEditDelete}
                    />
                  )}
                  name="firstname"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input">
              <Label.TopBottom
                theme_left_align_2
                label="Username"
                fontSize="24x"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="Username"
                      disabled={initialValues}
                      {...field}
                      errors={errors.username?.message}
                    />
                  )}
                  name="username"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input no_margin">
              <Label.TopBottom
                theme_left_align_2
                label="Role"
                fontSize="24x"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputDropDownNormal
                      theme_standard_drop_down_create_account
                      iconSvgClose={<Icons.ArrowDropdown />}
                      iconSvgOpen={
                        <Icons.ArrowDropdown translate="21.81" rotate="90" />
                      }
                      options={RoleOption ? RoleOption : []}
                      disabled={isCannotEditDelete}
                      {...field}
                    />
                  )}
                  name="role"
                  defaultValue={{
                    label: RoleOption && RoleOption[0].label,
                    value: RoleOption && RoleOption[0].value,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="r_top_form">
            <div className="group_input_right">
              <Label.TopBottom
                theme_left_align_2
                label="นามสกุล"
                labelBottom="Surname"
                fontSize="24x"
                fontSizeLabelButtom="18px"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="นามสกุล"
                      disabled={isCannotEditDelete}
                      {...field}
                      errors={errors.lastname?.message}
                    />
                  )}
                  name="lastname"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input_right">
              <Label.TopBottom
                theme_left_align_2
                label="Password"
                fontSize="24x"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="Password"
                      type="password"
                      disabled={isCannotEditDelete}
                      {...field}
                      errors={errors.password?.message}
                    />
                  )}
                  name="password"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="group_input_right">
              <Label.TopBottom
                theme_left_align_2
                label="Re-Password"
                fontSize="24x"
                fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
              />
              <div className="input_sub">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_admin
                      placeholder="Re-Password"
                      type="password"
                      disabled={isCannotEditDelete}
                      {...field}
                      errors={errors.passwordConfirmation?.message}
                    />
                  )}
                  name="passwordConfirmation"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_form">
          {errorSubmitMsg && (
            <div className="err_submit_msg">{`* ${errorSubmitMsg} *`}</div>
          )}
          {!isCannotEditDelete &&
            (initialValues ? (
              <div className="btn_row_block">
                <div className="btn margin_right">
                  <Buttons.BtnRoundShadow
                    theme_btn_round_shadow_green_3
                    label="บันทึกการแก้ไข"
                    svg={<Icons.Download />}
                    fontSize="20px"
                    fontFamily="noto_sans_thai_medium, noto_sans_regular"
                    imgWidth="26px"
                    imgHeight="26px"
                    padding="10px 24px"
                    type="submit"
                    disabled={
                      !(
                        watchAllFields.username &&
                        watchAllFields.firstname &&
                        watchAllFields.lastname &&
                        watchAllFields.role
                      )
                    }
                  />
                </div>
                <div className="btn">
                  <Buttons.BtnRoundShadow
                    theme_btn_round_shadow_orange_3
                    label="ลบบัญชี"
                    svg={<Icons.UserDelete />}
                    fontSize="20px"
                    fontFamily="noto_sans_thai_medium, noto_sans_regular"
                    imgWidth="29px"
                    imgHeight="29px"
                    padding="10px 34px 10px 24px"
                    onClick={handleDelete}
                  />
                </div>
              </div>
            ) : (
              <div className="btn w18">
                <Buttons.BtnRoundShadow
                  theme_btn_round_shadow_pink
                  label="สร้าง"
                  svg={<Icons.AddCircle />}
                  fontSize="20px"
                  fontFamily="noto_sans_thai_medium, noto_sans_regular"
                  type="submit"
                  disabled={
                    !(
                      watchAllFields.username &&
                      watchAllFields.firstname &&
                      watchAllFields.lastname &&
                      watchAllFields.role &&
                      watchAllFields.password &&
                      watchAllFields.passwordConfirmation
                    )
                  }
                />
              </div>
            ))}
        </div> */}
      </form>
      <Modal
        theme_modal_standard
        isShow={_isShowModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        {_isShowModal}
      </Modal>
    </CreateAccountFormStyled>
  );
};

CreateAccountForm.propTypes = {};

export default CreateAccountForm;

import styled from 'styled-components';

export const TokenManagerStyled = styled.div`
  height: 100%;
  .loading_page {
    height: 100vh;
    width: 100wh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

import { ParticipantsAgeStyled } from './styled';
import { Blocks, Icons } from 'components';
import Chart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const ParticipantsAge = ({ data }) => {
  // const [_column, _setColumn] = useState([]);
  const [_value, _setValue] = useState([]);

  useEffect(() => {
    if (data) {
      let column = [];
      let value = [];
      if (data.age_groups && data.age_groups.length > 0) {
        data.age_groups.forEach((e, i) => {
          column.push(e.age_group);
          value.push(e.count);
        });
        // _setColumn(column)
        _setValue(value);
      }
    }
  }, [data]);
  return (
    <ParticipantsAgeStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="25px"
        paddingBottom="10px"
        paddingLeft="16px"
      >
        <div className="top_current">
          <div className="svg_show">
            <Icons.WomenGraph />
          </div>
          <div className="label_show">
            <div>จำนวนผู้เข้าร่วมแบ่งตามอายุ</div>
            <div className="sub_title_show">Participants on each age</div>
          </div>
        </div>
        <div className="bottom_current">
          <Chart
            type="bar"
            height={175}
            options={{
              chart: {
                type: 'bar',
                fontFamily: 'noto_sans_thai_regular, noto_sans_regular',
                toolbar: {
                  show: false,
                },
                events: {
                  click: function (chart, w, e) { },
                },
              },
              colors: ['#FD6524', '#43847C', '#FE706F', '#006C8D'],
              plotOptions: {
                bar: {
                  columnWidth: '30%',
                  distributed: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              xaxis: {
                // categories: _column,
                categories: [
                  '18 y - 19 y',
                  '20 y - 34 y',
                  '35 y - 49 y',
                  '50 y - 64 y',
                  '65 y and older',
                ],
                labels: {
                  style: {
                    colors: ['#000000', '#000000', '#000000', '#000000'],
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return RenderCommaMoney(val.toFixed(0));
                  },
                }
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return RenderCommaMoney(val.toFixed(0));
                  },
                },
              },
            }}
            series={[
              {
                name: 'จำนวนคน',
                // data: [44, 55, 57, 56],
                data: _value,
              },
            ]}
          />
        </div>
      </Blocks.Box>
    </ParticipantsAgeStyled>
  );
};

export default ParticipantsAge;

export default function WellBeing({ color = '#575D96' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.35"
      height="19.293"
      viewBox="0 0 22.35 19.293"
    >
      <path
        id="cardiology"
        d="M15.655,35.017a6.71,6.71,0,0,0-4.48,1.721,6.695,6.695,0,0,0-9.021,9.894l7.8,7.2a1.8,1.8,0,0,0,2.485-.04L20.2,46.632a6.695,6.695,0,0,0-4.54-11.615ZM11.523,52.856a.492.492,0,0,1-.681.014L3.682,46.26h4.2a.655.655,0,0,0,.579-.349l.951-1.8,1.336,4.659a.655.655,0,0,0,1.17.188l1.839-2.7h4.915l-7.126,6.578ZM6.695,36.327A5.4,5.4,0,0,1,10.689,38.1a.655.655,0,0,0,.971,0,5.385,5.385,0,0,1,8.3,6.85h-6.55a.655.655,0,0,0-.541.286l-1.248,1.829-1.4-4.884a.655.655,0,0,0-1.209-.125L7.482,44.95H2.393a5.384,5.384,0,0,1,4.3-8.624Z"
        transform="translate(0 -35.017)"
        fill={color}
      />
    </svg>
  );
}

import { ManageMissionStyled } from './styled';
import { Blocks, Buttons, Icons } from 'components';
import {
  MiniCompanyMisssion,
  MiniMissionDetail,
  MissionReward,
  MissionDetail,
} from 'widgets';
import { CHECK_BEFORE_AND_TODAY } from 'utils/functions/date';

const ManageMission = ({ label, dataMission, dataCompany, handleEdit, heightBox, heightBox2, perMissions }) => {
  return (
    <ManageMissionStyled>
      <Blocks.Box
        theme_gray_box
      >
        <div className="manage_top">
          <div className="show_label">{label}</div>
          {
            perMissions &&
            <div className="btn">
              <Buttons.BtnNormal
                theme_standard_btn_normal_gray
                label="แก้ไข"
                labelBottom="Edit"
                svg={<Icons.Edit color="#FFFFFF" />}
                onClick={handleEdit}
              />
            </div>
          }
        </div>
        <div className="manage_mid mb_14">
          <div className="col_2 w_312 m_lr_c">
            <div
              className="img_show"
              style={
                dataMission && dataMission.image_uri
                  ? {
                    backgroundImage: `url(${dataMission.image_uri})`,
                  }
                  : {
                    background: `white`,
                  }
              }
              alt="img"
            />
          </div>
          <div className="col_2 flex_1">
            <MiniCompanyMisssion
              dataCompany={dataCompany}
              dataMission={dataMission}
            />
          </div>
        </div>
        <div className="manage_mid">
          <div className="col_2 flex_1">
            {dataMission &&
              CHECK_BEFORE_AND_TODAY(dataMission.begin_date) === true ? (
              <MissionDetail dataMission={dataMission} heightBox={heightBox2} />
            ) : (
              <MiniMissionDetail dataMission={dataMission} heightBox={heightBox2} />
            )}
          </div>
          <div className="col_2 flex_1 w_384 min_w_300">
            <MissionReward dataMission={dataMission && dataMission} heightBox={heightBox} />
          </div>
        </div>
      </Blocks.Box>
    </ManageMissionStyled>
  );
};

export default ManageMission;

import styled from 'styled-components';

export const CategoryStyled = styled.div`
  .cate_head {
    margin-bottom: 16px;
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18}; 
    color: ${({ theme }) => theme.COLORS.GRAY_23};
    .sub_cat_label {
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14}; 
      color: ${({ theme }) => theme.COLORS.GRAY_24};
    }
  }
  .theme_standard {
  }
`;

export const ItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10.8px 10px;
  border-radius: 8px;
  cursor: pointer;
  .item_txt_left_col {
    display: flex;
    align-items: center;
    .item_icon {
      height: 32px;
    }
    .item_txt_group {
      margin-left: 16px;
      .cate_title {
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18}; 
        color: ${({ theme }) => theme.COLORS.GRAY_24};
      }
      .cate_sub_title {
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14}; 
        color: ${({ theme }) => theme.COLORS.GRAY_24};
      }
    }
  }
  .item_txt_right_col {
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.S32}; 
    color: ${({ theme }) => theme.COLORS.GRAY_24};
  }
  &.active {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
    border-radius: 8px;
    background: ${({ theme }) => theme.COLORS.BLUE_29};
    border-color: ${({ theme }) => theme.COLORS.BLUE_29};
    .item_txt_left_col {
      .item_txt_group {
        .cate_title {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
      }
    }
    .item_txt_right_col {
      color: ${({ theme }) => theme.COLORS.GRAY_23};
    }
  }
`;

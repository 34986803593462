export default function MenuMission({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g id="company" transform="translate(-0.008)">
        <g
          id="Group_13977"
          data-name="Group 13977"
          transform="translate(16.981 8.748)"
        >
          <g id="Group_13976" data-name="Group 13976">
            <path
              id="Path_19099"
              data-name="Path 19099"
              d="M339.277,181.866h-.8V169.147a.728.728,0,0,0-.509-.7L331,166.11v17.252h8.279a.748.748,0,1,0,0-1.5Zm-2.294,0h-1.5v-2.993h-1.5v2.993h-1.5v-3.741a.741.741,0,0,1,.748-.748h2.992a.741.741,0,0,1,.748.748v3.741Zm-.748-5.985h-2.992a.748.748,0,0,1,0-1.5h2.992a.748.748,0,1,1,0,1.5Zm0-2.992h-2.992a.748.748,0,0,1,0-1.5h2.992a.748.748,0,1,1,0,1.5Z"
              transform="translate(-330.998 -166.11)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13979"
          data-name="Group 13979"
          transform="translate(0.008 0)"
        >
          <g
            id="Group_13978"
            data-name="Group 13978"
            transform="translate(0 0)"
          >
            <path
              id="Path_19100"
              data-name="Path 19100"
              d="M14.542,4.616,2.572.046A.758.758,0,0,0,1.554.762V24.476h-.8A.762.762,0,0,0,.756,26H15.02V5.332A.763.763,0,0,0,14.542,4.616Zm-7,19.86H4.547V20.668a.748.748,0,0,1,.748-.762H7.539v4.57Zm-.748-6.094h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm5.237,15.234H9.035v-4.57H11.28a.748.748,0,0,1,.748.762v3.808Zm-.748-6.094h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Zm0-3.047h-1.5a.762.762,0,0,1,0-1.523h1.5a.762.762,0,0,1,0,1.523Z"
              transform="translate(-0.008 0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g id="company_1_" data-name="company (1)" transform="translate(-0.005)">
        <g
          id="Group_13981"
          data-name="Group 13981"
          transform="translate(0.005 0)"
        >
          <g id="Group_13980" data-name="Group 13980">
            <path
              id="Path_19101"
              data-name="Path 19101"
              d="M25.243,24.476h-.813V11.527a.762.762,0,0,0-.521-.723L15.29,7.931v-2.6a.762.762,0,0,0-.494-.713L2.608.049A.762.762,0,0,0,1.579.762V24.476H.767A.762.762,0,1,0,.767,26H25.243a.762.762,0,0,0,0-1.523Zm-17.57,0H6.15V21.429H7.673Zm3.047,0H9.2V21.429H10.72Zm3.047-16v16H12.243V20.668a.762.762,0,0,0-.762-.762H5.388a.762.762,0,0,0-.762.762v3.808H3.1V1.861l10.664,4Zm6.094,16H18.337V21.429H19.86Zm3.047,0H21.384V20.668a.762.762,0,0,0-.762-.762H17.575a.762.762,0,0,0-.762.762v3.808H15.29V9.537l7.617,2.539v12.4Z"
              transform="translate(-0.005 0)"
            />
          </g>
        </g>
        <g
          id="Group_13983"
          data-name="Group 13983"
          transform="translate(4.712 17.006)"
        >
          <g id="Group_13982" data-name="Group 13982">
            <path
              id="Path_19102"
              data-name="Path 19102"
              d="M92.969,332h-1.31a.655.655,0,0,0,0,1.31h1.31a.655.655,0,1,0,0-1.31Z"
              transform="translate(-91.004 -332.003)"
            />
          </g>
        </g>
        <g
          id="Group_13985"
          data-name="Group 13985"
          transform="translate(9.368 17.006)"
        >
          <g id="Group_13984" data-name="Group 13984">
            <path
              id="Path_19103"
              data-name="Path 19103"
              d="M182.967,332h-1.31a.655.655,0,1,0,0,1.31h1.31a.655.655,0,0,0,0-1.31Z"
              transform="translate(-181.002 -332.003)"
            />
          </g>
        </g>
        <g
          id="Group_13987"
          data-name="Group 13987"
          transform="translate(4.712 13.933)"
        >
          <g id="Group_13986" data-name="Group 13986">
            <path
              id="Path_19104"
              data-name="Path 19104"
              d="M92.969,272.005h-1.31a.655.655,0,0,0,0,1.31h1.31a.655.655,0,1,0,0-1.31Z"
              transform="translate(-91.004 -272.005)"
            />
          </g>
        </g>
        <g
          id="Group_13989"
          data-name="Group 13989"
          transform="translate(9.368 13.933)"
        >
          <g id="Group_13988" data-name="Group 13988">
            <path
              id="Path_19105"
              data-name="Path 19105"
              d="M182.967,272.005h-1.31a.655.655,0,0,0,0,1.31h1.31a.655.655,0,0,0,0-1.31Z"
              transform="translate(-181.002 -272.005)"
            />
          </g>
        </g>
        <g
          id="Group_13991"
          data-name="Group 13991"
          transform="translate(17.316 17.006)"
        >
          <g id="Group_13990" data-name="Group 13990">
            <path
              id="Path_19106"
              data-name="Path 19106"
              d="M334.272,332h-2.619a.655.655,0,1,0,0,1.31h2.619a.655.655,0,1,0,0-1.31Z"
              transform="translate(-330.998 -332.003)"
            />
          </g>
        </g>
        <g
          id="Group_13993"
          data-name="Group 13993"
          transform="translate(17.316 13.933)"
        >
          <g id="Group_13992" data-name="Group 13992">
            <path
              id="Path_19107"
              data-name="Path 19107"
              d="M334.272,272.005h-2.619a.655.655,0,1,0,0,1.31h2.619a.655.655,0,1,0,0-1.31Z"
              transform="translate(-330.998 -272.005)"
            />
          </g>
        </g>
        <g
          id="Group_13995"
          data-name="Group 13995"
          transform="translate(4.712 10.859)"
        >
          <g id="Group_13994" data-name="Group 13994">
            <path
              id="Path_19108"
              data-name="Path 19108"
              d="M92.969,212.006h-1.31a.655.655,0,1,0,0,1.31h1.31a.655.655,0,1,0,0-1.31Z"
              transform="translate(-91.004 -212.006)"
            />
          </g>
        </g>
        <g
          id="Group_13997"
          data-name="Group 13997"
          transform="translate(9.368 10.859)"
        >
          <g id="Group_13996" data-name="Group 13996">
            <path
              id="Path_19109"
              data-name="Path 19109"
              d="M182.967,212.006h-1.31a.655.655,0,1,0,0,1.31h1.31a.655.655,0,0,0,0-1.31Z"
              transform="translate(-181.002 -212.006)"
            />
          </g>
        </g>
        <g
          id="Group_13999"
          data-name="Group 13999"
          transform="translate(4.712 7.786)"
        >
          <g id="Group_13998" data-name="Group 13998">
            <path
              id="Path_19110"
              data-name="Path 19110"
              d="M92.969,152.007h-1.31a.655.655,0,1,0,0,1.31h1.31a.655.655,0,1,0,0-1.31Z"
              transform="translate(-91.004 -152.007)"
            />
          </g>
        </g>
        <g
          id="Group_14001"
          data-name="Group 14001"
          transform="translate(9.368 7.786)"
        >
          <g id="Group_14000" data-name="Group 14000">
            <path
              id="Path_19111"
              data-name="Path 19111"
              d="M182.967,152.007h-1.31a.655.655,0,1,0,0,1.31h1.31a.655.655,0,0,0,0-1.31Z"
              transform="translate(-181.002 -152.007)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

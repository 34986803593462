import styled from 'styled-components';

export const IconTitleSubTxtStyled = styled.div`
  height: 100%;
  .ict_container {
    // height: 100%;
    position: relative;
    height: ${(props) => (props.height ? props.height : 'auto')};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '16px')};
    padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '16px'};
    padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '16px'};
    padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft : '16px'};
    border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '10px'};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
    .top_current {
      display: flex;
      // margin-bottom: 14px;
      // align-items: center;
      .svg_show {
      }
      .label_show {
        margin-left: 12px;
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        .title_txt {
          color: ${({ theme }) => theme.COLORS.BLACK_6};
        }
        .sub_title_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
      }
    }
    .score_box {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
      padding: 7px 0px;
      .s_prefix_label {
        color: ${({ theme }) => theme.COLORS.BLUE_11};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
      .s_scroe {
        color: ${({ bottomColor, theme }) => bottomColor ? bottomColor : theme.COLORS.BLACK_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
      .s_label {
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }

  .theme_standard {
    
  }
  .theme_flex_col {
    // height: 100%;
    .score_box {
      flex-direction: column;
    }
  }

  .theme_standard_new {
    height: 100%;
  }

  .theme_big_text {
    .top_current {
      .label_show {
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        .title_txt {
          color: ${({ theme }) => theme.COLORS.BLACK_6};
        }
        .sub_title_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
      }
    }
    .score_box {

      .s_prefix_label {
        
      }
      .s_scroe {
        color: ${({ bottomColor, theme }) => bottomColor ? bottomColor : theme.COLORS.BLACK_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S56};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
      .s_label {
        padding-top: 23px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S26};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }
`;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { TableRankStyled } from './styled';
import { Icons } from 'components';
import ReactTooltip from 'react-tooltip';

const TableRank = ({ theme_standard_table_rank, data, labelEmpty, handleOnClick }) => {
  const customClass = cx({
    theme_standard_table_rank: theme_standard_table_rank,
  });

  const setOrderColor = (key) => {
    switch (key) {
      case 1:
        return (
          <Icons.OrderTable
            color1="#ffe200"
            color2="#B28301"
            colorBorder="#F0D01D"
            orderNum={key}
          />
        );

      case 2:
        return (
          <Icons.OrderTable
            color1="#CBCBCB"
            color2="#5C5C5C"
            colorBorder="#DBDBDB"
            orderNum={key}
          />
        );

      case 3:
        return (
          <Icons.OrderTable
            color1="#FF9D00"
            color2="#7C2600"
            colorBorder="#FFA700"
            orderNum={key}
          />
        );

      // case 4:
      //   return (
      //     <Icons.OrderTable
      //       color1="#B1B1C1"
      //       color2="#B1B1C1"
      //       colorBorder="#DBDBDB"
      //       orderNum={key}
      //     />
      //   );

      // case 5:
      //   return (
      //     <Icons.OrderTable
      //       color1="#707070"
      //       color2="#707070"
      //       colorBorder="#DBDBDB"
      //       orderNum={key}
      //     />
      //   );

      // case 6:
      //   return (
      //     <Icons.OrderTable
      //       color1="#171717"
      //       color2="#171717"
      //       colorBorder="#DBDBDB"
      //       orderNum={key}
      //     />
      //   );
      default:
        return (
          // <Icons.OrderTable
          //   color1="#171717"
          //   color2="#171717"
          //   colorBorder="#DBDBDB"
          //   orderNum={key}
          // />
          <Icons.OrderTable
            color1="#B1B1C1"
            color2="#B1B1C1"
            colorBorder="#DBDBDB"
            orderNum={key}
          />
        );
    }
  };

  return (
    <TableRankStyled isEmpty={!data || data.length === 0}>
      <div className={customClass}>
        {!data || data.length === 0 ? (
          <div className="label_empty">{labelEmpty}</div>
        ) : (
          data.map((e, i) => (
            <div key={i + 1} className="row">
              <div className="left_row">
                <div className="round">{setOrderColor(i + 1)}</div>
              </div>
              <div className="right_row">
                <div className="col1">
                  <div
                    className="top_col1"
                    data-tip
                    data-for={`name_th${i + 1}`}
                  >
                    <ReactTooltip id={`name_th${i + 1}`} delayShow='800'>
                      <span>
                        {e.label1}
                      </span>
                    </ReactTooltip>
                    {e.label1}
                  </div>
                  <div
                    className="sub_col1"
                    data-for={`name_en${i + 1}`}
                  >
                    <ReactTooltip id={`name_en${i + 1}`} delayShow='800'>
                      <span>
                        {e.label2}
                      </span>
                    </ReactTooltip>
                    {e.label2}
                  </div>
                </div>
                <div className="col2">
                  <div className="top_col2">จำนวนผู้ที่เข้าร่วม</div>
                  <div className="sub_col2">Total Participants</div>
                </div>
                <div className="col3">{e.label3}</div>
                <div className="col4" onClick={() => handleOnClick && handleOnClick(e.dataRow)}>แสดง</div>
                <div className="col5">
                  <div className="top_col5">{e.label5}</div>
                  <div className="sub_col5">{e.label6}</div>
                </div>
                <div className="col6">{e.label4}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </TableRankStyled>
  );
};

// TableRank.propTypes = {};

export default TableRank;

import React, { useEffect } from 'react';
import { AddHealthyClubFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Icons, Tabs, Blocks } from 'components';

const AddHealthyClubForm = ({ initialValues, onSubmit, isEdit }) => {
  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description  is required'),
    // password: yup.string().nullable().required('Password is required'),
  });


  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        // type: initialValues.type === 'closed' ? statusOptions[0] : statusOptions[1],
        // type: typeOptions.find((e) => e.value === initialValues.type),
        // language: languageOptions.find((e) => e.value === initialValues.language),
        // category: categoryOptions.find(
        //   (e) => e.value === initialValues.category
        // ),
        file_cover: initialValues.covers,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps


  // const statusOptions = [
  //   {
  //     label: 'Closed Group',
  //     value: 'closed',
  //     icon: <Icons.StatusPrivate />,
  //   },
  //   {
  //     label: 'Open Group',
  //     value: 'open',
  //     icon: <Icons.StatusPublic />,
  //   },
  // ];

  return (
    <AddHealthyClubFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Blocks.Container theme_con_no_border_radius> 
          <div className="layer_one">
            <Tabs.HeadTab
              theme_standard_head_tab
              labelButtonTop={initialValues ? "บันทึก" : "สร้างกลุ่ม"}
              labelButtonBottom={initialValues ? "Edit" : "Create new group"}
              typeButtonBlue
              type='submit'
            // handleBackCustom={this.handleBackClick}
            />
            {/* <div className='btn_zone_c'>
              <div className='btn_c'>
                <Buttons.BtnNormal
                  theme_round_brown_2
                  label={initialValues ? "แก้ไขกลุ่ม" : "สร้างกลุ่ม"}
                  icon={<Icons.Add width='15' height='15' />}
                  type='submit'
                />
              </div>
            </div> */}
          </div>
          <div className="layer_two">
            <div className='left_layer_two'>
              {/* <div className="top_form">
                <div className="group_top_form">
                  <div className="line_label">
                    <div className="label_1">สถานะ</div>
                    <div className="label_2">Status</div>
                  </div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSelectCusIconConfirm
                        iconSvg={<Icons.Lock />}
                        iconSvgClose={<Icons.ArrowDropdown />}
                        iconSvgOpen={
                          <Icons.ArrowDropdown translate="21.81" rotate="90" />
                        }
                        options={statusOptions}
                        {...field}
                        isDisabled={isEdit}
                      />
                    )}
                    name="type"
                    defaultValue={{
                      label: 'Closed Group',
                      value: 'closed',
                      icon: <Icons.StatusPrivate />,
                    }}
                  />
                  <div className='detail_sub'>
                    Open Group คือกลุ่มสาธารณะที่ผู้ใช้ Triple T จะเข้ากลุ่มอัตโนมัติ
                  </div>
                </div>
              </div> */}
              <div className="zone_upload">
                <div className="header_zone">
                  <div className="label_1">ภาพปกบทความ</div>
                  <div className="label_2">Cover photo</div>
                </div>
                <div className="upload_layer">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.UploadSetImage2
                        theme_upload_set_image
                        acceptType="cover"
                        maxMBFileSizeWarning="1"
                        {...field}
                        svg={<Icons.Delete3 />}
                        theme_left_right={false}
                      // width='100%'
                      />
                    )}
                    name="covers"
                    defaultValue={[]}
                  />
                </div>
              </div>
            </div>
            <div className='right_layer_two'>
              <div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    // <Inputs.InputTextArea
                    //   theme_standard_text_area
                    //   labelTitle="ชื่อกลุ่ม"
                    //   labelSub="Group Name"
                    //   maxLength={120}
                    //   height="48px"
                    //   placeholder="ชื่อกลุ่ม"
                    //   svg={<Icons.Pencil1 width='20' height='20' />}
                    //   {...field}
                    //   errors={errors.name?.message}
                    // />
                    <Inputs.InputTextMaxChar
                      theme_normal_text
                      maxLength={120}
                      labelTitle="ชื่อกลุ่ม"
                      labelSub="Group Name"
                      placeholder="หัวข้อ"
                      // flexStyle="flex-end"
                      {...field}
                      errors={errors.name?.message}
                    />
                  )}
                  name="name"
                  defaultValue=""
                />
              </div>
              <div className="right_text_rich">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextRich
                      theme_normal_text_rich
                      labelTitle="เนื้อหาของกลุ่ม"
                      // labelSub="Content"
                      // toolbarHidden
                      maxLength={500}
                      placeholder="บทความ"
                      svg={<Icons.Pencil1 />}
                      height="232px"
                      {...field}
                      errors={errors.description?.message}
                    />
                  )}
                  name="description"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </Blocks.Container>
      </form>
    </AddHealthyClubFormStyled>
  );
};

AddHealthyClubForm.propTypes = {};

export default AddHealthyClubForm;

import { ArticleDetailStyled } from "./styled";
import { Blocks, Buttons, Icons } from "components";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import React, { useEffect, useState } from "react";

const ArticleDetail = ({
  data,
  showButton = true
}) => {
  let history = useHistory();

  const [categoryShow, setCategoryShow] = useState({ name: "", icon: null });

  useEffect(() => {
    if (data) {
      renderCategory(data.category);
    }
  }, [data]);

  const handleClickEditContent = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE_NEW_DETAIL}/${data.id}`);
  };

  const renderCategory = (e) => {
    switch (e) {
      case "health":
        setCategoryShow({
          name: "สุขภาพรวม",
          icon: <Icons.New />,
        });
        break;

      case "relax":
        setCategoryShow({
          name: "คลายเครียด",
          icon: <Icons.Nutrition />,
        });
        break;

      case "exercise":
        setCategoryShow({
          name: "ออกกำลังกาย",
          icon: <Icons.Exercise />,
        });
        break;

      case "other":
        setCategoryShow({
          name: "อื่นๆ",
          icon: <Icons.Other />,
        });
        break;

      case "all":
        setCategoryShow({
          name: "ทั้งหมด",
          icon: <Icons.All />,
        });
        break;

      default:
        break;
    }
  };

  return (
    <ArticleDetailStyled
      // src="/assets/images/backgrounds/corona.png"
      src={data?.feature && data.feature.media_uri}
    >
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="15px"
        paddingBottom="24px"
        paddingLeft="24px"
      >
        <div className="article_layout">
          <div className="img_show" />
          <div className="detail_show">
            <div className="title_group">
              <div className="show_t">
                <div className="title_show">{data?.topic}</div>
                <div className="show_value">
                  <div className="box_icon">
                    <div className="show_svg">{categoryShow.icon}</div>
                    <div className="label_show">
                      <div>{categoryShow.name}</div>
                    </div>
                  </div>
                  <div className="box_icon">
                    <div className="show_svg">
                      <Icons.Reward7 />
                    </div>
                    <div className="label_show">
                      <div>ของรางวัล</div>
                    </div>
                  </div>
                  <div className="box_icon">
                    <div className="show_svg">
                      <Icons.Dumbel2 />
                    </div>
                    <div className="label_show gray">
                      <div>คะแนนสุขภาพ</div>
                      <div className="sub_label">
                        {data &&
                          data.default_coin_amount &&
                          `${RenderCommaMoney(data.default_coin_amount)} แต้ม`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                showButton &&
                <div className="btn_t">
                  <Buttons.BtnNormal
                    theme_light_blue
                    label="แก้ไข"
                    padding='7.2px'
                    labelBottom="Edit"
                    svg_front={<Icons.Edit color="#002366" />}
                    onClick={handleClickEditContent}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </Blocks.Box>
    </ArticleDetailStyled>
  );
};

export default ArticleDetail;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { ShowDateStyled } from "./styled";

const ShowDate = ({
  theme_standard_show_date,
  theme_standard_show_date_mini,
  theme_nutrition_show_date,
  theme_red_show_date,
  date,
  month1,
  month2,
  year,
  time,
  isMargin,
}) => {
  const customClass = cx({
    theme_standard_show_date: theme_standard_show_date,
    theme_standard_show_date_mini: theme_standard_show_date_mini,
    theme_nutrition_show_date: theme_nutrition_show_date,
    theme_red_show_date: theme_red_show_date,
  });
  return (
    <ShowDateStyled>
      <div className={customClass}>
        <div className="top_date">
          {date && <div className="show_label">{date}</div>}
          {(month1 || month2) && (
            <div className="show_month">
              <div>{month1}</div>
              <div className="bottom_month">{month2}</div>
            </div>
          )}
          {year && <div className="show_year">{year}</div>}
        </div>
        {time && <div className="bottom_date">{time}</div>}
      </div>
    </ShowDateStyled>
  );
};

ShowDate.propTypes = {};

export default ShowDate;

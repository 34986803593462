import React from "react";
import { connect } from "react-redux";
import { NewManagementContainerStyled } from "./styled";
import { HeadContent } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import {
  Lists,
  Icons,
  TableCustom,
  Loading,
  Buttons,
  Modal,
  BoxDelete,
} from "components";
import { adminService } from "apiServices";
import { toast } from "react-toastify";

class NewManagementContainer extends React.Component {
  state = { isLoading: true, isShowModal: false };

  componentDidMount() {
    this.fetchData(`?per_page=10&offset=0`);
    this.fetchContentCategory();
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_CONTENT_LIST(
      this.props.companySelectedRedux.id,
      stringQuery
    );
    if (res && res.success) {
      this.setState({
        data: res.data,
        isLoading: false,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };

  fetchContentCategory = async () => {
    let res = await adminService.GET_CAMPANIES_ID_CONTENT_CATEGORY(
      this.props.companySelectedRedux.id
    );
    if (res && res.success) {
      let dataTemp = [
        {
          icon: <Icons.All />,
          title: "ทั้งหมด",
          title_en: "All",
          value: "all",
          score: res.data.reduce((result, data) => {
            result += data.contents_count ? Number(data.contents_count) : 0;
            return result;
          }, 0),
        },
        ...res.data.map((e) => ({
          icon: this.renderCateIcon(e.category),
          title: e.name_th,
          title_en: e.name_en,
          score: e.contents_count,
          value: e.category,
        })),
      ];
      this.setState({
        categoryList: dataTemp,
      });
    }
  };

  renderCateIcon = (key) => {
    switch (key) {
      case "health":
        return <Icons.New />;
      case "relax":
        return <Icons.Nutrition />;
      case "exercise":
        return <Icons.Exercise />;
      case "other":
        return <Icons.Other />;
      default:
        break;
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  handleClickDetail = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_PREVIEW_DETAIL}/${e.id}`);
  };

  handleClickSort = (e) => { };

  renderCategory = (e) => {
    switch (e) {
      case "health":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.New />}
          </div>
        );

      case "relax":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Nutrition />}
          </div>
        );

      case "exercise":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Exercise />}
          </div>
        );

      case "other":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Other />}
          </div>
        );

      case "all":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.All />}
          </div>
        );

      default:
        break;
    }
  };

  renderType = (e) => {
    switch (e) {
      case "audio":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeSound />}
          </div>
        );

      case "video":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeVideo />}
          </div>
        );

      case "article":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeBook />}
          </div>
        );

      default:
        break;
    }
  };

  renderStatus = (e) => {
    switch (e) {
      case false:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.StatusPublic />}
            <div style={{ marginLeft: "9px" }}>{`สาธารณะ`}</div>
          </div>
        );

      case true:
        return (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            {<Icons.StatusPrivate />}
            <div style={{ marginLeft: "9px" }}>{` ไม่เผยแพร่`}</div>
          </div>
        );

      default:
        break;
    }
  };

  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        const hasAll = e.some((f) => f === "all");
        this.setState(
          {
            categoryFilter: hasAll
              ? false
              : `categories=${e.filter((e) => e !== "all").join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "categoryLeftMenuFilter":
        const hasAllLeft = e.value === "all";
        this.setState(
          {
            categoryLeftMenuFilter: hasAllLeft
              ? false
              : `categories=${e.value}`,
            categoryFilter: false,
            disbledCateFilterTable: hasAllLeft ? false : true,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "typeFilter":
        this.setState(
          {
            typeFilter: `types=${e.filter((e) => e !== "all").join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "statusFilter":
        if (e.length === 2) {
          this.setState(
            {
              statusFilter: false,
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              statusFilter: `is_draft=${e.filter((e) => e !== "all").join()}`,
            },
            () => this.handleJoinQuery()
          );
        }
        break;
      default:
        break;
    }
  };
  handleJoinQuery = () => {
    const { categoryFilter, categoryLeftMenuFilter, typeFilter, statusFilter } =
      this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (categoryLeftMenuFilter) {
      queryString.push(categoryLeftMenuFilter);
    }
    if (typeFilter) {
      queryString.push(typeFilter);
    }
    if (statusFilter) {
      queryString.push(statusFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchData();
      this.setState({
        queryFilterAll: false,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };
  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}${query}`);
    this.fetchData(query);
  };

  handleClickCreateContent = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW_DETAIL}/create`);
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };

  handleConfirmDelete = () => {
    this.setState({
      isShowModal: false,
      isLoading: true,
    });
    const { selectedData } = this.state;

    if (selectedData && selectedData.length > 0) {
      Promise.all(
        selectedData.map((e) => {
          return new Promise(async (resolve) => {
            let res = await adminService.DELETE_CONTENT(e);
            if (res && res.success) {
              resolve();
            }
          });
        })
      ).then(() => {
        toast.success("ลบสำเร็จ");
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.fetchData();
          }
        );
      });
    }
  };

  render() {
    const {
      data,
      pagination,
      isLoading,
      categoryList,
      disbledCateFilterTable,
      isShowModal,
      selectedData,
    } = this.state;
    return (
      <NewManagementContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="manage_container">
            <div className="head_wrap_layout">
              <HeadContent
                onClick={this.handleBackClick}
                title_name={this.props.authenRedux?.company?.name}
              />
            </div>
            <div className="bottom_control">
              <div className="tab_show">
                <div className="tab_select">Well Library</div>
              </div>
              <div className="group_button_layout">
                <div className="del_button">
                  <Buttons.BtnNormal
                    theme_standard_btn_normal_red_3
                    label="ลบ"
                    disabled={!(selectedData && selectedData.length > 0)}
                    svg={
                      <Icons.Delete3
                        color="#FFFFFF"
                        color2={
                          selectedData && selectedData.length > 0
                            ? "#f0211d"
                            : "#9E9E9E"
                        }
                      />
                    }
                    onClick={this.handlePopup}
                  />
                </div>

                <Buttons.BtnCreateContent
                  theme_standard_btn_create_content
                  onClick={this.handleClickCreateContent}
                />
              </div>
            </div>
            <div className="block_2_col_layout">
              <div className="col_2 w_280">
                <Lists.Category
                  data={categoryList}
                  onClickItem={(e) =>
                    this.handleSelectedFilter("categoryLeftMenuFilter", e)
                  }
                />
              </div>
              <div className="col_2 flex_1">
                <TableCustom.TableNormal
                  theme_standard_table_normal_purple
                  columns={columns({
                    handleClickSort: this.handleClickSort,
                    handleClickDetail: this.handleClickDetail,
                    renderCategory: this.renderCategory,
                    renderType: this.renderType,
                    renderStatus: this.renderStatus,
                    handleSelectedFilter: this.handleSelectedFilter,
                    disbledCateFilterTable: disbledCateFilterTable,
                  })}
                  useSelect
                  data={data}
                  pagination={pagination}
                  labelEmpty="ยังไม่มีข้อมูล"
                  onChangeSelected={this.handleChangeSelectedTable}
                  showPagination
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
          </div>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <BoxDelete
            theme_box_delete
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
            textShow={
              selectedData && `คุณต้องการลบ ${selectedData.length} บทความ?`
            }
          />
        </Modal>
      </NewManagementContainerStyled>
    );
  }
}

const columns = ({ handleClickDetail }) => [
  {
    title: "",
    field: "",
    headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
    style: { textAlign: "center", width: "20px", flexShrink: 0 },
  },
  {
    title: "หัวข้อ",
    field: "topic",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div
        style={{ fontSize: "18px", color: "#000000" }}
        onClick={() => handleClickDetail(origin)}
      >
        {value}
      </div>
    ),
  },
  {
    title: "จำนวนบริษัท",
    field: "companies_count",
    headerStyle: {
      display: "flex",
      justifyContent: "center",
      width: "120px",
      flexShrink: 0,
    },
    style: { textAlign: "center", width: "120px", flexShrink: 0 },
    render: ({ origin, value }) => (
      <div onClick={() => handleClickDetail(origin)}>{value}</div>
    ),
  },
  {
    title: "จำนวนการเข้าดู",
    field: "total_view_count",
    headerStyle: {
      display: "flex",
      justifyContent: "left",
      width: "120px",
      flexShrink: 0,
    },
    style: { textAlign: "center", width: "120px", flexShrink: 0 },
    render: ({ origin, value }) => (
      <div onClick={() => handleClickDetail(origin)}>{value}</div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewManagementContainer);

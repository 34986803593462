import styled from 'styled-components'

export const RewardPointFormStyled = styled.div`
  .group_select {
    display: flex;
    align-items: center;
    column-gap: 21px;
    flex-wrap: wrap;
    row-gap: 10px;
    .two_col {
      display: flex;
      align-items: center;
      column-gap: 21px;  
      .label_box {
        width: 35px;
        text-align: center;
      }
      .box_date {
        min-width: 168px;
        width: 20%;
      }
    }
  }
  .btn_download {
    margin-top: 90px;
    justify-content: flex-end;
    display: flex;
    .btn_width {
      width: 240px;
    }
  }
`

import React, { useState, useEffect, forwardRef } from "react";
import { InputDatePiker2Styled } from "./styled";
import cx from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import th from 'date-fns/locale/th';
import moment from "moment";
import { Icons } from "components";

const InputDatePiker2 = ({
  theme_standard,
  theme_date_lucky,
  value,
  onChange,
  onChangeCustom,
  alignTextDate = "left",
  disabled,
  minDate,
  maxDate,
  dateFormat,
  showDateFormat = "DD MMMM",
  yearFormat = "YYYY",
  iconColor = "#002366",
  showYearDropdown,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_date_lucky: theme_date_lucky,
  });

  const [_value, _setValue] = useState();
  const [_onClick] = useState(false);

  useEffect(() => {
    if (value) {
      _setValue(new Date(value));
    }
  }, [value]);

  const handleChangePicker = (e) => {
    // _setValue(e);
    onChange && onChange(e);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="custom_date" onClick={onClick} ref={ref}>
      <>
        {/* {moment(_value).add(543, 'years').format(showDateFormat)} */}
        {/* {`${moment(_value).format(showDateFormat)} ${yearFormat && yearFormat !== '' ? moment(_value).add(543, 'years').format(yearFormat) : ''}`} */}
        {`${moment(_value).format(showDateFormat)} ${
          yearFormat && yearFormat !== ""
            ? moment(_value).format(yearFormat)
            : ""
        }`}

        <span className="arrow_icon">
          {_onClick ? (
            <Icons.Calendar3 color={disabled ? "#989898" : iconColor} />
          ) : (
            <Icons.Calendar3 color={disabled ? "#989898" : iconColor} />
          )}
        </span>
      </>
    </button>
  ));

  return (
    <InputDatePiker2Styled disabled={disabled} alignTextDate={alignTextDate}>
      <div className={customClass}>
        <DatePicker
          selected={_value}
          // onChange={handleChangePicker}
          onChange={(e) => {
            onChange && handleChangePicker(e);
            onChangeCustom && onChangeCustom(e);
          }}
          dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
          minDate={minDate}
          maxDate={maxDate}
          customInput={<ExampleCustomInput />}
          showYearDropdown={showYearDropdown}
          disabled={disabled}
          dropdownMode="select"
        />
      </div>
    </InputDatePiker2Styled>
  );
};

InputDatePiker2.propTypes = {};

export default InputDatePiker2;

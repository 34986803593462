import React from "react";
import { RewardSearchContainerStyled } from "./styled";
import { Tabs, Inputs, Icons, Buttons, TableCustom, Loading } from "components";
import { ROUTE_PATH } from "utils/constants";
import moment from "moment";
import { connect } from "react-redux";
import queryString from "query-string";
import { adminService } from "apiServices";
import { DIF_DATE_2 } from "utils/functions/date";
import theme from "styles/theme.json";

class RewardSearchContainer extends React.Component {
  state = {
    isLoading: true,
    thisMoment: moment(),
    keyWordText: "",
    labelSearch: "",
    loadSkeleton: true,
  };

  componentDidMount() {
    // const { category_id } = this.state;
    const { keyword, category_id } = queryString.parse(
      this.props.location.search
    );
    this.scrollToTop();
    this.setFilterInitial();
    this.fetchData(
      `?category_id=${category_id || 1}${keyword ? `&keyword=${keyword}` : ""}`
    );
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  setFilterInitial = () => {
    const { keyword, category_id } = queryString.parse(
      this.props.location.search
    );
    this.setState({
      categoryFilter: `category_id=${category_id || 1}`,
      keywordFilter: keyword ? `keyword=${keyword}` : false,
      keyWordText: keyword,
      labelSearch: keyword,
    });
  };

  fetchData = async (query) => {
    this.setState({
      loadSkeleton: true,
    });
    let res = await adminService.GET_REWARD_LIST_BY_CATEGORIES_BY_KEYWORD(
      this.props.companySelectedRedux.id,
      query
    );
    if (res && res.success) {
      this.setState({
        data: res.data.map((e) => ({
          ...e,
          rowSty:
            e.redeemable_count - e.redeemed_count <= 0 ||
            moment(e.end_date).diff(moment().format("YYYY-MM-DD")) < 0
              ? { background: theme.COLORS.BLUE_1 }
              : false,
        })),
        isLoading: false,
        loadSkeleton: false,
      });
    } else {
      this.setState({
        data: [],
        isLoading: false,
        loadSkeleton: false,
      });
    }
    this.setState({
      thisMoment: moment(),
      isLoading: false,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickSort = (e) => {};

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_REWARD);
  };

  handleSelectCategory = (e) => {
    const { keyWordText } = this.state;
    this.fetchData(e.order, keyWordText);
    this.setState({
      category_id: e.order,
    });
  };

  handleClickSearch = (e) => {
    const { category_id } = this.state;
    this.setState({
      keyWordText: e,
    });
    this.fetchData(category_id, e);
  };

  // handleChangeSearch = (e) => {
  //   this.setState({
  //     keyWordText: e.target.value,
  //   });
  // };

  handleDetailHistory = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_DETAILS}/${e.id}`);
  };

  //FILTER PROCESS
  //=======================================
  //=======================================
  handleFilterDate = (key, value) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `category_id=${value.order}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "startDate":
        if (value) {
          this.setState(
            {
              startDateFilter: `end_date_after=${moment(value).format(
                "YYYY-MM-DD"
              )}`,
              inputStartDateFilter: new Date(
                moment(value).format("YYYY-MM-DD")
              ),
              endDateFilter: `begin_date_before=${moment(value)
                .add(30, "days")
                .format("YYYY-MM-DD")}`,
              inputEndDateFilter: new Date(
                moment(value).add(1, "months").format("YYYY-MM-DD")
              ),
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              startDateFilter: false,
              endDateFilter: false,
            },
            () => this.handleJoinQuery()
          );
        }

        break;
      case "endDate":
        if (value) {
          this.setState(
            {
              endDateFilter: `begin_date_before=${moment(value).format(
                "YYYY-MM-DD"
              )}`,
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              endDateFilter: false,
            },
            () => this.handleJoinQuery()
          );
        }

        break;
      case "sort":
        this.setState(
          {
            isSortFilter: `sort_by=end_date&order_by=${value}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "keywordFilter":
        this.setState(
          {
            keywordFilter: value ? `keyword=${value}` : false,
            keyWordText: value,
          },
          () => this.handleJoinQuery()
        );
        break;

      default:
        break;
    }
  };
  handleJoinQuery = () => {
    const {
      categoryFilter,
      startDateFilter,
      endDateFilter,
      isSortFilter,
      keywordFilter,
    } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (startDateFilter) {
      queryString.push(startDateFilter);
    }
    if (endDateFilter) {
      queryString.push(endDateFilter);
    }
    if (isSortFilter) {
      queryString.push(isSortFilter);
    }
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    this.setState({
      loadSkeleton: true,
    });
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchData();
      this.setState({
        queryFilterAll: false,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };

  render() {
    const {
      isLoading,
      data,
      labelSearch,
      loadSkeleton,
      category_id,
      inputStartDateFilter,
    } = this.state;
    const { keyword } = queryString.parse(this.props.location.search);
    const { rewardCategory } = this.props;

    return (
      <RewardSearchContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="ค้นหารางวัล"
                subTitle="Rewards Search"
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                lineHeight="1.3"
                svg={<Icons.SearchPage />}
                onClick={this.handleBackClick}
              />
            </div>
            <div className="serach_panel">
              <Inputs.InputSearch
                theme_standard_search
                svg={<Icons.Search color="#575D96" />}
                fontColor="#575D96"
                initialValue={keyword}
                placeholder="Search by Reward name"
                // onSubmit={(e) => this.handleClickSearch(e)}
                onSubmit={(e) => this.handleFilterDate("keywordFilter", e)}
                // show_bottom_label
              />
            </div>
            <div className="layer_three">
              <div className="group_line no_margin w69">
                <div className="left_line">
                  <Buttons.BtnCategroyFillter
                    theme_standard_category_filter
                    initialValue={category_id}
                    data={rewardCategory}
                    // onClick={(e) => this.handleSelectCategory(e)}
                    onClick={(e) => this.handleFilterDate("categoryFilter", e)}
                  />
                </div>
              </div>
            </div>
            <div className="layer_four">
              <TableCustom.TableNormal
                theme_standard_table_normal
                showTitle={`รางวัลที่พบในคำว่า "${
                  labelSearch ? labelSearch : "ไม่มีการค้นหา"
                }"`}
                columns={columns({
                  inputStartDateFilter: inputStartDateFilter,
                  endDate: this.state.endDate,
                  handleFilterDate: this.handleFilterDate,
                })}
                data={data}
                onClickRow={this.handleDetailHistory}
                bgHeader="#002366"
                useNo
                loading={loadSkeleton}
              />
            </div>
          </>
        )}
      </RewardSearchContainerStyled>
    );
  }
}

const columns = ({ inputStartDateFilter, endDate, handleFilterDate }) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
    style: { textAlign: "center", width: "30px", flexShrink: 0 },
  },
  {
    title: "แบรนด์",
    field: "name",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div className="name_layer">{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันเริ่ม
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={inputStartDateFilter}
            onChangeCustom={(e) => handleFilterDate("startDate", e)}
          />
        </div>
      </div>
    ),
    field: "begin_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันหมดเขต
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={endDate}
            minDate={inputStartDateFilter}
            onChangeCustom={(e) => handleFilterDate("endDate", e)}
          />
        </div>
      </div>
    ),
    field: "end_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <div>วันคงเหลือ</div>
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputSort
            theme_standard_sort
            onChangeCustom={(e) => handleFilterDate("sort", e)}
          />
        </div>
      </div>
    ),
    field: "remain_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{`${DIF_DATE_2(origin.begin_date, origin.end_date)} วัน`}</div>
    ),
  },
  {
    title: "สิทธิ์คงเหลือ",
    field: "remain",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>
        {`${
          origin.redeemable_count - origin.redeemed_count > 0
            ? origin.redeemable_count - origin.redeemed_count
            : 0
        } สิทธิ์`}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  rewardCategory: state.rewardCategory,
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardSearchContainer);

import styled from "styled-components";

export const SendPasswordFormStyled = styled.div`
  .bd_add_edit {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 20px;
    padding: 40px 64px;
    width: 463px;
    .top_box {
      display: flex;
      column-gap: 12px;
      .svg_i {
        // display: flex;
        width: 24px;
        height: 24px;
      }
      .box_obj {
        flex: 1;
        .h_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S22};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          margin-bottom: 10px;
        }
        .sub_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          margin-bottom: 24px;
        }
      }

      .opt_s {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.no_action {
          color: ${({ theme }) => theme.COLORS.GRAY_35};
        }
        .lbl_back {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          &.gary {
            color: ${({ theme }) => theme.COLORS.GRAY_35};
          }
        }
      }
    }

    .action_btn {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      .body_btn {
        width: 182px;
        &.margin_right {
          margin-right: 16px;
        }
      }
    }
  }
`;

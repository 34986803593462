import styled from 'styled-components';

export const InputGroupCheckBoxLoadmoreStyled = styled.div`
  .theme_standard_group_check_box {
    position: relative;
    .icon_filter {
      cursor: pointer;
    }
    .filter_block {
      position: absolute;
      width: ${({ boxSize }) => (boxSize ? boxSize : '210px')};
      // bottom: 0px;
      z-index: 2;
      top: ${({ topBox }) => (topBox ? topBox : '30px')};
      right: ${({ rightBox }) => (rightBox ? rightBox : '-80px')};
      .btn_show {
        margin-top: 20px;
        padding: 0px 20%;
      }
      .bc_body {
        height: calc(100% - 73px);
        .body_checkbox {
          display: flex;
          flex-wrap: wrap;
          font-size: ${({ theme, fontSize }) =>
            fontSize ? fontSize : theme.FONT.SIZE.S16};
          font-family: ${({ fontFamily }) =>
            fontFamily
              ? fontFamily
              : 'noto_sans_thai_regular, noto_sans_regular'};
          color: ${({ theme, fontColor }) =>
            fontColor ? fontColor : theme.COLORS.PINK_2};
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          .container_wrap {
            width: 50%;
            margin-bottom: 5px;
          }

          /* The container */
          .container {
            display: block;
            position: relative;
            padding-left: 33px;
            padding-top: 2px;
            padding-right: 0px;
            margin-bottom: 0px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          /* Hide the browser's default radio button */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0px;
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 3px;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            border: 2px solid ${({ theme }) => theme.COLORS.PINK_2};
            border-radius: 4px;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            // background-color: #ccc;
          }

          /* When the radio button is checked, add a blue background */
          .container input:checked ~ .checkmark {
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            // border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .container .checkmark:after {
            top: 2px;
            left: 2px;
            width: 80%;
            height: 80%;
            background: ${({ theme }) => theme.COLORS.PINK_2};
          }
        }
      }
    }
  }
  .theme_standard_group_check_box_user {
    position: relative;
    .icon_filter {
      cursor: pointer;
    }
    .filter_block {
      position: absolute;
      width: ${({ boxSize }) => (boxSize ? boxSize : '210px')};
      // bottom: 0px;
      z-index: 2;
      top: ${({ topBox }) => (topBox ? topBox : '30px')};
      right: ${({ rightBox }) => (rightBox ? rightBox : '-80px')};
      .btn_show {
        margin-top: 20px;
        padding: 0px 20%;
      }
      .bc_body {
        .body_checkbox {
          display: flex;
          flex-wrap: wrap;
          font-size: ${({ theme, fontSize }) =>
            fontSize ? fontSize : theme.FONT.SIZE.S16};
          font-family: ${({ fontFamily }) =>
            fontFamily
              ? fontFamily
              : 'noto_sans_thai_regular, noto_sans_regular'};
          color: ${({ theme, fontColor }) =>
            fontColor ? fontColor : theme.COLORS.PURPLE_1};
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          .container_wrap {
            width: 50%;
            margin-bottom: 5px;
          }

          /* The container */
          .container {
            display: block;
            position: relative;
            padding-left: 33px;
            padding-top: 2px;
            padding-right: 0px;
            margin-bottom: 0px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          /* Hide the browser's default radio button */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0px;
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 3px;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
            border-radius: 4px;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            // background-color: #ccc;
          }

          /* When the radio button is checked, add a blue background */
          .container input:checked ~ .checkmark {
            background-color: ${({ theme }) => theme.COLORS.WHITE_1};
            // border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .container .checkmark:after {
            top: 2px;
            left: 2px;
            width: 80%;
            height: 80%;
            background: ${({ theme }) => theme.COLORS.PURPLE_1};
          }
        }
      }
    }
  }
`;

import styled from 'styled-components'

export const BtnShadowStyled = styled.div`
  .theme_standard_btn_shadow {
    font-family: ${({ fontFamily }) => fontFamily ? fontFamily : 'noto_sans_thai_regular, noto_sans_regular'};
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.GRAY_2};
    padding: 8px;
    font-size: ${(props) => (props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S20)};
    box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
    cursor: pointer;
    .svg_show {
      margin-right: 10px;
      width: ${(props) => (props.imgWidth ? props.imgWidth : '18px')};
      height: ${(props) => (props.imgHeight ? props.imgHeight : '18px')};
      display: flex;
      align-items: center;
    }
  }
  .theme_btn_shadow_white {
    font-family: ${({ fontFamily }) => fontFamily ? fontFamily : 'noto_sans_thai_regular, noto_sans_regular'};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 8px;
    font-size: ${(props) => (props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S20)};
    box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
    cursor: pointer;
    .svg_show {
      margin-right: 10px;
      width: ${(props) => (props.imgWidth ? props.imgWidth : '18px')};
      height: ${(props) => (props.imgHeight ? props.imgHeight : '18px')};
      display: flex;
      align-items: center;
    }
  }
`

export default function renderIcon({ width = "17", height = "17", color = '#646464' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3774 8.87848L9.15874 13.0971C8.8878 13.3681 8.71205 13.7196 8.65787 14.0989L8.50356 15.179C8.4878 15.2894 8.52491 15.4007 8.60374 15.4795C8.68256 15.5583 8.7939 15.5955 8.90425 15.5797L9.98439 15.4254C10.3637 15.3712 10.7152 15.1955 10.9862 14.9245L15.2048 10.7059C15.7094 10.2012 15.7094 9.3831 15.2048 8.87848V8.87848C14.9625 8.63614 14.6338 8.5 14.2911 8.5C13.9484 8.5 13.6197 8.63614 13.3774 8.87848Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.375 7.08366H10.625" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.375 9.91667H7.79167" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.3776 14.875H4.2526C3.87698 14.8751 3.51671 14.726 3.25103 14.4605C2.98535 14.1949 2.83604 13.8347 2.83594 13.4591V3.54167C2.83583 3.16605 2.98495 2.80577 3.25048 2.54009C3.51601 2.27441 3.8762 2.1251 4.25183 2.125H12.7526C13.1282 2.12489 13.4885 2.27401 13.7542 2.53954C14.0199 2.80507 14.1692 3.16527 14.1693 3.54089V6.375" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.125 5.66667H3.77542" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.125 8.49967H3.77542" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.03906 11.3337H3.77448" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

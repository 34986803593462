import React from "react";
import { DepartmentContainerStyled } from "./styled";
import { Tabs, Forms, Loading, Modal, BoxCofirm, Blocks } from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { getObjectKey2 } from "utils/functions/getObjectKey";
// import moment from 'moment';

class DepartmentContainer extends React.Component {
  state = { isLoading: true, isShowModal: false };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission();
  }

  fetchData = () => {
    if (this.props.match.params.id !== "create") {
      this.fetchDataEdit();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props;

    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "employee_manage",
      "employee_manage_no_delete"
    );

    this.setState({
      perMissions: checkPermission,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  fetchDataEdit = async () => {
    let res = await adminService.GET_DEPARTMENT_BY_ID(
      this.props.match.params.id
    );

    if (res && res.success) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_DEPARTMENT);
  };

  onSubmit = (values) => {
    if (this.props.match.params.id === "create") {
      this.setState({
        dataSave: values,
      });
      this.handlePopup();
    } else {
      this.handleSaveEdit(values);
    }
  };

  handleSaveEdit = async (values) => {
    let params = {
      ...values,
    };

    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_DEPARTMENT(
            this.props.companySelectedRedux?.id,
            params
          )
        : await adminService.PATCH_DEPARTMENT(
            this.props.match.params.id,
            params
          );

    if (res && res.success) {
      toast.success("สร้างสำเร็จ");
      this.handleBackClick();
    } else {
      toast.error(res.error);
    }
  };

  handleCreate = () => {
    const { dataSave } = this.state;

    this.handleSaveEdit(dataSave);
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const { isLoading, initialValues, isShowModal, dataSave, perMissions } =
      this.state;

    return (
      <DepartmentContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title={initialValues ? "แก้ไขแผนก" : "สร้างแผนก"}
                  subTitle={
                    initialValues ? "Edit department" : "Creates department"
                  }
                  fontSize="32px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#013D59"
                  colorBottom="#9E9E9E"
                  margin_right="19px"
                  lineHeight="1.3"
                  typeBlank
                  onClick={this.handleBackClick}
                />
              </div>
              <div className="layer_two">
                <Forms.CreateDepartmentForm
                  initialValues={initialValues}
                  onSubmit={this.onSubmit}
                  isCannotEditDelete={perMissions ? false : true}
                />
              </div>
              <Modal
                theme_modal_standard
                isShow={isShowModal}
                handleClickCloseModal={this.handleClickCloseModal}
              >
                <BoxCofirm
                  theme_box_confirm
                  handleCancel={this.handleClickCloseModal}
                  handleConfirm={this.handleCreate}
                  textShow="ยืนยันการสร้างแผนก"
                  textBottom={dataSave && `แผนก${dataSave.name}`}
                />
              </Modal>
            </Blocks.Container>
          </>
        )}
      </DepartmentContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentContainer);

import React from "react";
import { RewardExchangeHistoryContainerStyled } from "./styled";
import {
  Tabs,
  Loading,
  Buttons,
  Typographys,
  TableCustom,
  Modal,
  Forms,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import moment from "moment";
import "moment-timezone";
import queryString from "query-string";
import { getObjectKey } from "utils/functions/getObjectKey";
// import { toast } from 'react-toastify';

class RewardExchangeHistoryContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
    actionStp: 1,
    redeem_id: null,
  };

  componentDidMount() {
    const { per_page, offset } = queryString.parse(this.props.location.search);
    this.scrollToTop();
    this.fetchData();
    this.fetchDataTable(per_page, offset);
    this.checkPermission()
  }

  fetchData = async () => {
    let res = await adminService.GET_REWARD_ITEM_BY_ID(
      this.props.match.params.id
    );
    if (res && res.success) {
      this.setState({
        dataDetail: res.data,
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props
    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'reward_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  fetchDataTable = async (per_page, offset) => {
    let res = await adminService.GET_REDEMPTIONS_BY_REWARD_ITEM(
      this.props.match.params.id,
      per_page,
      offset
    );
    if (res && res.success) {
      this.setState({
        isLoading: false,
        data: res.data,
        totalExchange: res.page.total,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickDetail = (e) => {
    this.setState({
      isShowModal: true,
      actionStp: 1,
      redeem_id: e.id,
    });
  };

  handleClickExchangeHistory = () => { };

  onSubmit = async (values) => {
    const { redeem_id } = this.state;
    const { per_page, offset } = queryString.parse(this.props.location.search);
    let params = {
      ...values,
      distributed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let res = await adminService.PATCH_REDEEM_REWARD_ITEM(redeem_id, params);
    if (res && res.success) {
      this.setState({
        actionStp: 2,
      });
      this.fetchDataTable(per_page, offset);
    } else {
      this.setState({
        actionStp: 3,
      });
    }
  };

  handlePageClick = (e) => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE}/${this.props.match.params.id
      }?per_page=10&offset=${e.selected * 10}`
    );
    this.fetchDataTable(10, e.selected * 10);
  };

  render() {
    const {
      isLoading,
      isShowModal,
      pagination,
      totalExchange,
      actionStp,
      data,
      dataDetail,
      perMissions,
    } = this.state;
    return (
      <RewardExchangeHistoryContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="ประวัติแลกของรางวัล"
                subTitle="Rewards History"
                fontSize="34px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#092945"
                colorBottom="#092945"
                lineHeight="1.3"
                typeBlank
              />
            </div>
            <div className="layer_two">
              <div className="t_label">{dataDetail?.name}</div>
              <div className="b_label">{dataDetail?.description}</div>
            </div>
            <div className="title_layer">
              <div className="left_title">
                จำนวนผู้แลก
                <span className="num_show">
                  {totalExchange && RenderCommaMoney(totalExchange)} คน
                </span>
              </div>
              <div className="right_title">
                <div className="left_show_label">
                  <Typographys.TopBottom
                    theme_left_align
                    label="สิทธิ์คงเหลือ"
                    labelBottom="Remaining"
                    fontSizeLabelButtom="14px"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  />
                </div>
                <div className="big_num">
                  <span className="num_hightlight">
                    {dataDetail &&
                      Number(
                        dataDetail.total_redeemable_count -
                          dataDetail.redeemed_count >
                          0
                          ? dataDetail.total_redeemable_count -
                          dataDetail.redeemed_count
                          : 0
                      )}
                  </span>
                  <span>{` / ${dataDetail &&
                    RenderCommaMoney(Number(dataDetail.total_redeemable_count))
                    }`}</span>
                </div>
              </div>
            </div>
            <div className="layer_table">
              <TableCustom.TableNormal
                theme_reward
                columns={columns({
                  handleClickSort: this.handleClickSort,
                  handleClickDetail: this.handleClickDetail,
                  perMissions: perMissions,
                })}
                data={data}
                pagination={pagination}
                showPagination
                useRenderNoPage
                useNo
                onChangeSelected={this.handleChangeSelectedTable}
                handlePageClick={this.handlePageClick}
              />
            </div>
            <Modal
              theme_modal_standard
              isShow={isShowModal}
              handleClickCloseModal={this.handleClickCloseModal}
            >
              <Forms.RewardExchangeForm
                onSubmit={this.onSubmit}
                actionStp={actionStp}
                handleCancel={this.handleClickCloseModal}
              />
            </Modal>
          </>
        )}
      </RewardExchangeHistoryContainerStyled>
    );
  }
}

const columns = ({ handleClickDetail, perMissions }) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100px",
      flexShrink: 0,
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100px",
      flexShrink: 0,
    },
  },
  {
    title: "รหัสพนักงาน",
    field: "user.employee.code",
    headerStyle: { display: "flex", alignItems: "center" },
    style: { display: "flex", alignItems: "center" },
    render: ({ origin, value }) => (
      <div>
        {origin.user && origin.user.employee ? (
          `${origin.user.employee.code}`
        ) : (
          <div className="gray">ไม่มีข้อมูลพนักงาน</div>
        )}
      </div>
    ),
  },
  {
    title: "ชื่อ - นามสกุล",
    field: "name",
    headerStyle: { display: "flex", alignItems: "center" },
    style: { display: "flex", alignItems: "center" },
    render: ({ origin, value }) => (
      <div>
        {origin.user && origin.user.employee ? (
          `${origin.user.employee.firstname} ${origin.user.employee.lastname}`
        ) : (
          <div className="gray">ไม่มีข้อมูลพนักงาน</div>
        )}
      </div>
    ),
  },
  {
    title: "แผนก",
    field: "user.employee.department.name",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    style: { display: "flex", alignItems: "center", justifyContent: "center" },
    render: ({ origin, value }) => (
      <div>
        {
          value
            ?
            value
            :
            'ไม่มีข้อมูลแผนก'
        }
      </div>
    ),
  },
  {
    title: "วันที่แลก",
    field: "distributed_at",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "136px",
      flexShrink: 0,
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "136px",
      flexShrink: 0,
    },
    render: ({ origin, value }) => (
      <div style={{ width: "100%" }}>
        {value != null ? (
          <div style={{ color: "#9E9E9E", textAlign: "center" }}>
            {moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY")}
          </div>
        ) :
          perMissions
            ?
            (
              <Buttons.BtnNormal
                theme_standard_btn_pop_up
                label="Redeem"
                fontSize="16px"
                backgroundColor="#E7284A"
                onClick={() => handleClickDetail(origin)}
              />
            )
            :
            ''
        }
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardExchangeHistoryContainer);

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnCircleLabelStyled } from './styled';
// import { Icons } from 'components';

const BtnCircleLabel = ({
  theme_standard_btn_circle_label,
  svg,
  label,
  labelBottom,
  fontSize,
  fontSizeLabelBottom,
  fontFamily,
  fontFamilyBottom,
  fontColor,
  fontColorBottom,
  onClick,
}) => {
  const customClass = cx({
    theme_standard_btn_circle_label: theme_standard_btn_circle_label,
  });
  return (
    <BtnCircleLabelStyled
      fontSize={fontSize}
      fontSizeLabelBottom={fontSizeLabelBottom}
      fontFamily={fontFamily}
      fontFamilyBottom={fontFamilyBottom}
      fontColor={fontColor}
      fontColorBottom={fontColorBottom}
    >
      <div className={customClass} onClick={onClick}>
        <div className="left_button">
          {svg && <div className="svg_show">{svg}</div>}
        </div>
        <div className="right_button">
          <div className="title">{label}</div>
          <div className="label_show">{labelBottom}</div>
        </div>
      </div>
    </BtnCircleLabelStyled>
  );
};

BtnCircleLabel.propTypes = {};

export default BtnCircleLabel;

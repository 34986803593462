import styled from 'styled-components'

export const BoxDeleteStyled = styled.div`
  .theme_box_delete {
    .delete_box {
      width: 400px;
      border-radius: 24px;
      border: 1px solid #F0211D;
      padding: 57px 20px 48px 20px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title_box {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S30};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color:  ${({ theme }) => theme.COLORS.BLACK_8};
        margin-bottom: 23px;
        align-items: baseline;
        .icon {
          display: flex;
          margin-left: 7px;
        }
      }
      .action_btn {
        display: flex;
        .body_btn {
          width: 120px;
          &.margin_right {
            margin-right: 16px;
          }
        }
      }
    }
  }
`

import styled from "styled-components";

export const PersonPercentStyled = styled.div`
  .theme_standard_person_percent {
    display: flex;
    width: 93px;
    .left_layout_person_percent {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: ${(props) =>
        props.color ? props.color : ({ theme }) => theme.COLORS.RED_3};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px;
    }
    .right_layout_person_percent {
      padding-left: 12px;
      .label_bottom {
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
      }
    }
  }
`;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { TableUserRankGroupStyled } from './styled';
import { Skeleton  ,PaginationCustom} from 'components';
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TableUserRankGroup = ({
  theme_standard_table_rank_group,
  theme_standard_table_rank_group_header_pink,
  data,
  labelEmpty,
  title_label,
  showHeader,
  loading,
  handlePageClick,
  pagination,
  showPagination,
}) => {
  const customClass = cx({
    theme_standard_table_rank_group: theme_standard_table_rank_group,
    theme_standard_table_rank_group_header_pink:
      theme_standard_table_rank_group_header_pink,
  });

  // const setOrderColor = (key) => {
  //   switch (key) {
  //     case 1:
  //       return (
  //         <Icons.OrderTable
  //           color1="#ffe200"
  //           color2="#B28301"
  //           colorBorder="#F0D01D"
  //           orderNum={key}
  //         />
  //       );

  //     case 2:
  //       return (
  //         <Icons.OrderTable
  //           color1="#CBCBCB"
  //           color2="#5C5C5C"
  //           colorBorder="#DBDBDB"
  //           orderNum={key}
  //         />
  //       );

  //     case 3:
  //       return (
  //         <Icons.OrderTable
  //           color1="#FF9D00"
  //           color2="#7C2600"
  //           colorBorder="#FFA700"
  //           orderNum={key}
  //         />
  //       );

  //     case 4:
  //       return (
  //         <Icons.OrderTable
  //           color1="#B1B1C1"
  //           color2="#B1B1C1"
  //           colorBorder="#DBDBDB"
  //           orderNum={key}
  //         />
  //       );

  //     case 5:
  //       return (
  //         <Icons.OrderTable
  //           color1="#707070"
  //           color2="#707070"
  //           colorBorder="#DBDBDB"
  //           orderNum={key}
  //         />
  //       );

  //     // case 6:
  //     //   return (
  //     //     <Icons.OrderTable
  //     //       color1="#171717"
  //     //       color2="#171717"
  //     //       colorBorder="#DBDBDB"
  //     //       orderNum={key}
  //     //     />
  //     //   );
  //     default:
  //       return (
  //         // <Icons.OrderTable
  //         //   color1="#171717"
  //         //   color2="#171717"
  //         //   colorBorder="#DBDBDB"
  //         //   orderNum={key}
  //         // />
  //         <>{key}</>
  //       );
  //   }
  // };

  const renderColor = (e) => {
    switch (e) {
      case 1:
        return '#BC9B6A';
      case 2:
        return '#BFC3CF';
      case 3:
        return '#BB9D90';
      case 4:
        return '#96999F';
      case 5:
        return '#8A6758';

      default:
        break;
    }
  };

  return (
    <TableUserRankGroupStyled isEmpty={!data || data.length === 0}>
      <div className={customClass}>
        {loading ? (
          <Skeleton.TableSkeleton />
        ) : !data || data.length === 0 ? (
          <div className="label_empty">{labelEmpty}</div>
        ) : (
          <>
            {title_label && <div className="title_layout">{title_label}</div>}
            {showHeader && (
              <div className="header">
                <div className="h1">ลำดับ</div>
                <div className="h2"></div>
                <div className="h3">ชื่อ-นามสกุล</div>
                <div className="h4">แผนก</div>
                <div className="h5">เลเวล</div>
              </div>
            )}
            <div className="group_row">
              {data.map((e, i) => (
                <div
                  key={i + 1}
                  className="row"
                  style={{ borderColor: i + 1 < 6 && renderColor(i + 1) }}
                >
                  <div className="left_row">
                    <div className="round">
                      {/* {setOrderColor(e.rank)} */}
                      {e.rank}
                    </div>
                  </div>
                  <div className="right_row">
                    <div
                      className="col1"
                      style={
                        e && e.profile && e.profile.profile_picture_uri
                          ? {
                              backgroundImage: `url(${e.profile.profile_picture_uri})`,
                            }
                          : {
                              background: `#b1b1c130`,
                            }
                      }
                      alt="img"
                    />
                    <div className="col2">
                      {e.employee &&
                        `${e.employee.firstname} ${e.employee.lastname}`}
                    </div>
                    <div className="col3">
                      {e.employee.department && e.employee.department.name}
                    </div>
                    <div className="col4">{`Lv.${e.exp_level}`}</div>
                  </div>
                </div>
              ))}
            </div>
            {showPagination && pagination && (
              <div className="pagi_layout">
                <PaginationCustom
                  theme_standard_pagination
                  totalPages={pagination?.last_page}
                  currentPageData={pagination?.current_page - 1}
                  _handlePageClick={handlePageClick}
                />
              </div>
            )}
          </>
        )}
      </div>
    </TableUserRankGroupStyled>
  );
};

// TableUserRankGroup.propTypes = {};

export default TableUserRankGroup;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { BtnNormalStyled } from "./styled";

const BtnNormal = ({
  theme_standard_btn_normal,
  theme_standard_btn_normal_red,
  theme_standard_btn_normal_purple,
  theme_standard_btn_normal_red_round,
  theme_standard_btn_normal_gray,
  theme_standard_btn_round_gray_regular_font,
  theme_standard_btn_pop_up_account,
  theme_standard_btn_round_red,
  theme_standard_btn_round_red_bold,
  theme_standard_btn_normal_red_3,
  theme_standard_btn_normal_green,
  theme_standard_btn_round_gray,
  theme_standard_btn_round_white,
  theme_standard_btn_log_in,
  theme_standard_btn_normal_pink,
  theme_light_blue_only_border,
  theme_standard_btn_pop_up,
  theme_gray,
  theme_purple,
  theme_only_border,
  theme_only_border_blue,
  theme_blue_border_bg_white,
  theme_red_border_bg_white,
  theme_only_border_red,
  theme_light_blue,
  theme_bg_white,
  label,
  labelBottom,
  svg_front,
  svg,
  imgWidth,
  imgHeight,
  fontSize,
  fontSizeLabelButtom,
  colorBottom,
  fontFamilyTop,
  fontFamilyBottom,
  marginTopBottomLabel,
  borderRadius,
  onClick,
  padding,
  disabled,
  backgroundColor,
  fontColor,
  borderColor,
  type = "button",
}) => {
  const customClass = cx({
    theme_standard_btn_normal: theme_standard_btn_normal,
    theme_standard_btn_normal_purple: theme_standard_btn_normal_purple,
    theme_standard_btn_normal_red: theme_standard_btn_normal_red,
    theme_standard_btn_normal_red_round: theme_standard_btn_normal_red_round,
    theme_standard_btn_normal_gray: theme_standard_btn_normal_gray,
    theme_standard_btn_normal_green: theme_standard_btn_normal_green,
    theme_standard_btn_round_gray_regular_font:
      theme_standard_btn_round_gray_regular_font,
    theme_standard_btn_pop_up_account,
    theme_standard_btn_round_red: theme_standard_btn_round_red,
    theme_standard_btn_round_red_bold: theme_standard_btn_round_red_bold,
    theme_standard_btn_round_gray: theme_standard_btn_round_gray,
    theme_standard_btn_round_white: theme_standard_btn_round_white,
    theme_standard_btn_log_in: theme_standard_btn_log_in,
    theme_standard_btn_normal_pink: theme_standard_btn_normal_pink,
    theme_standard_btn_pop_up: theme_standard_btn_pop_up,
    theme_standard_btn_normal_red_3: theme_standard_btn_normal_red_3,
    theme_only_border_red: theme_only_border_red,
    theme_only_border,
    theme_blue_border_bg_white: theme_blue_border_bg_white,
    theme_red_border_bg_white: theme_red_border_bg_white,
    theme_light_blue_only_border: theme_light_blue_only_border,
    theme_light_blue: theme_light_blue,
    theme_purple,
    theme_gray: theme_gray,
    theme_only_border_blue: theme_only_border_blue,
    theme_bg_white: theme_bg_white,
  });
  return (
    <BtnNormalStyled
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      fontSize={fontSize}
      fontSizeLabelButtom={fontSizeLabelButtom}
      colorBottom={colorBottom}
      fontFamilyTop={fontFamilyTop}
      fontFamilyBottom={fontFamilyBottom}
      marginTopBottomLabel={marginTopBottomLabel}
      borderRadius={borderRadius}
      padding={padding}
      backgroundColor={backgroundColor}
      disabled={disabled}
      fontColor={fontColor}
      borderColor={borderColor}
    >
      <div className={customClass}>
        <button
          className={`group_button ${disabled && `disabled`}`}
          type={type}
          onClick={onClick}
          disabled={disabled}
        >
          {svg_front && <div className="svg_front">{svg_front}</div>}
          {label}
          {svg && <div className="svg_show">{svg}</div>}
        </button>
        {labelBottom && <div className="label_bottom">{labelBottom}</div>}
      </div>
    </BtnNormalStyled>
  );
};

BtnNormal.propTypes = {};

export default BtnNormal;

export default function Logout({ color = '#575D96' }) {
  return (
    <svg id="_031-extract" data-name="031-extract" xmlns="http://www.w3.org/2000/svg" width="18.609" height="18.613" viewBox="0 0 18.609 18.613">
      <g id="Group_2271" data-name="Group 2271" transform="translate(5.235 4.725)">
        <g id="Group_2270" data-name="Group 2270">
          <path id="Path_5499" data-name="Path 5499" d="M151.515,128.771l-3.57-3.57a1.021,1.021,0,0,0-1.44,0l-.61.61a1.011,1.011,0,0,0-.3.72,1.022,1.022,0,0,0,.3.725l.771.776h-7.21a1.012,1.012,0,0,0-1.019,1.008v.863a1.037,1.037,0,0,0,1.019,1.037h7.254l-.814.8a1.011,1.011,0,0,0,0,1.432l.61.608a1.021,1.021,0,0,0,1.44,0l3.568-3.568a1.027,1.027,0,0,0,0-1.445Z" transform="translate(-138.438 -124.904)" fill={color} />
        </g>
      </g>
      <g id="Group_2273" data-name="Group 2273">
        <g id="Group_2272" data-name="Group 2272">
          <path id="Path_5500" data-name="Path 5500" d="M10.107,2.908a1.071,1.071,0,0,0,1.008.873h.862A1.036,1.036,0,0,0,13,2.758v-1.3A1.457,1.457,0,0,0,11.551,0H1.522A1.463,1.463,0,0,0,.062,1.457v15.7a1.466,1.466,0,0,0,1.46,1.46H11.551A1.46,1.46,0,0,0,13,17.153V15.845a1.027,1.027,0,0,0-1.026-1.012h-.862a1.068,1.068,0,0,0-1.009.873H2.97V2.908Z" transform="translate(-0.062)" fill={color} />
        </g>
      </g>
    </svg>
  );
}

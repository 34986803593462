export default function renderIcon({
  color = "#bb6989",
  width = "18.006",
  height = "33.75",
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2C11.314 2 14 4.686 14 8C14 11.314 11.314 14 8 14C4.686 14 2 11.314 2 8C2 4.686 4.686 2 8 2Z"
        fill="white"
        stroke="#EC5E49"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99935 8.33333V5"
        stroke="#EC5E49"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99868 10.6667C7.90668 10.6667 7.83202 10.7413 7.83268 10.8333C7.83268 10.9253 7.90735 11 7.99935 11C8.09135 11 8.16602 10.9253 8.16602 10.8333C8.16602 10.7413 8.09135 10.6667 7.99868 10.6667"
        stroke="#EC5E49"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

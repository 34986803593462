import styled from "styled-components";

export const MsdPercentOfOrgansByDeparmentStyled = styled.div`
  .body_mass_container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    row-gap: 16px;
    .body_mass_left_col {
      flex: 1;
    }
    .body_mass_right_col {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .item_right {
        .date_show {
          display: flex;
          .left_arrow {
            margin-right: 12px;
            min-width: 48px;
          }
          .group_date {
            display: flex;
            flex-direction: column;
            align-items: center;
            .group_date_top {
              display: flex;
              .show_icon {
                margin-right: 9px;
              }
              .date_layout {
                color: ${({ theme }) => theme.COLORS.BLACK_1};
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                font-family: noto_sans_thai_medium, noto_sans_regular;
              }
            }
            .group_date_bottom {
              color: ${({ theme }) => theme.COLORS.GRAY_12};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_regular, noto_sans_regular;
            }
          }
          .right_arrow {
            min-width: 48px;
            margin-left: 12px;
          }
        }
        &.w_200 {
          width: 200px;
          flex-shrink: 0;
        }
        &.w_152 {
          width: 152px;
          flex-shrink: 0;
        }
        &.ml_14 {
          margin-left: 14px;
        }
        &.ml_16 {
          margin-left: 14px;
        }
      }
    }
  }
  .bmi_trend_graph {
    display: flex;
    column-gap: 30px;
    row-gap: 16px;
    .show_graph {
      flex: 1;
      .graph_wrap {
        .body_content {
          margin-top: 18px;
          .show_group_label {
            display: flex;
            column-gap: 26px;
          }
          .group_graph {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .left_graph {
              width: 39.5%;
            }
            .right_graph {
              width: 59.5%;
            }
          }
        }
      }
    }
  }

  .label_empty {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .bmi_trend_graph {
      display: block;
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .bmi_trend_graph {
    }
  }

  @media (max-width: 1020px) {
    .bmi_trend_graph {
      flex-direction: column;
    }
  }
`;

import styled from "styled-components";

export const NewManagementContainerStyled = styled.div`
  padding-left: 5px;
  margin-left: -5px;
  margin-right: -20px;
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manage_container {
    padding: 15px 32px 80px 32px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 8px;
    .head_wrap_layout {
      margin-bottom: 22px;
    }
    .bottom_control {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_1};
      margin-bottom: 31px;
      .tab_show {
        display: flex;
        .tab_select {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S22};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          border-bottom: 4px solid ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
      .group_button_layout {
        display: flex;
        align-items: flex-end;
        padding-bottom: 16px;
        .del_button {
          width: 98px;
          margin-right: 16px;
        }
        .w200 {
          width: 200px;
          .group_t {
            line-height: 1.26;
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          }
        }
      }
    }

    .block_2_col_layout {
      display: flex;
      column-gap: 30px;
      overflow: auto;
      padding-bottom: 5px;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cccccc;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #c2c2c2;
      }
      ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #ebebeb;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .col_2 {
        &.flex_1 {
          width: calc(100% - 296px);
          min-width: 700px;
          // background: blue;
          // padding-right: 4px;
        }
        &.w_280 {
          flex-shrink: 0;
          width: 280px;
          // padding-left: 8px;
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const AddHealthyClubFormStyled = styled.div`
  .layer_one {
    display: flex;
    padding-bottom: 21px;
    flex-direction: column;
    .btn_zone_c {
      margin-top: 45px;
      display: flex;
      justify-content: flex-end;
      .btn_c {
        width: 225px;
      }
    }
  }
  .layer_two {
    margin-top: 7px;
    display: flex;
    column-gap: 32px;
    .right_layer_two {
      width: calc(100% - 420px);
      .right_text_rich {
        margin-top: 32px;
      }
      .group_top_form_right {
        width: 60%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        margin-bottom: 32px;
        .line_label {
          display: flex;
          margin-bottom: 14px;
          .label_1 {
            margin-right: 18px;
          }
          .label_2 {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
        }
      }
    }

    .left_layer_two {
      width: 400px;
      flex-shrink: 0;
      padding-bottom: 20px;
      .top_form {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        margin-bottom: 90px;
        width: 100%;
        .group_top_form {
          width: 100%;
          .line_label {
            display: flex;
            margin-bottom: 8px;
            align-items: baseline;
            column-gap: 8px;
            .label_1 {
              color: ${({ theme }) => theme.COLORS.GRAY_23};
              font-size: ${({ theme }) => theme.FONT.SIZE.S20};
              font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            }
            .label_2 {
              color: ${({ theme }) => theme.COLORS.GRAY_24};
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              // padding-top: 4px;
            }
          }
          .detail_sub {
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
            margin-top: 10px;
            white-space: nowrap;
          }
        }
      }
      .opt_show {
        display: flex;
        .left_opt {
          width: 41%;
          margin-right: 24px;
          .group_opt {
            .line_label {
              line-height: 1.2;
              margin-bottom: 19px;
              .label_1 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S20};
              }
              .label_2 {
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                color: ${({ theme }) => theme.COLORS.GRAY_1};
              }
            }
          }
          .show_img {
            margin-top: 32px;
          }
        }
      }
      .zone_upload {
        padding-bottom: 88px;
        .header_zone {
          margin-bottom: 8px;
          align-items: baseline;
          display: flex;
          column-gap: 8px;
          .label_1 {
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          }
          .label_2 {
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          }
        }
        .upload_layer {
        }
      }
    }
  }
`;

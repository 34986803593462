import styled from "styled-components";

export const InputCheckBoxTableStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_standard_input_check_box {
    display: flex;
    flex-wrap: wrap;
    font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
    font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color: ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PINK_2};
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 33px;
      padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 2px solid ${({ theme }) => theme.COLORS.PINK_2};
      border-radius: 4px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      // border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 2px;
      left: 2px;
      width: 80%;
      height: 80%;
      background: ${({ theme }) => theme.COLORS.PINK_2};

      // border: solid ${({ theme }) => theme.COLORS.PINK_2};
      // border-width: 0 3px 3px 0;
      // -webkit-transform: rotate(45deg);
      // -ms-transform: rotate(45deg);
      // transform: rotate(45deg);
    }
  }

  .input_check_box_square_light_blue {
    display: flex;
    flex-wrap: wrap;
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 20px;
      max-width: 20px;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_34};
      // border-radius: 50%;
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_10};
        border-color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_28};
      border-color: ${({ bgActiveColor, theme }) =>
        bgActiveColor ? bgActiveColor : theme.COLORS.BLUE_28};
      background: ${({ bgActiveColor, theme }) =>
        bgActiveColor ? bgActiveColor : theme.COLORS.BLUE_28};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_standard_input_check_box_round {
    display: flex;
    flex-wrap: wrap;
    /* font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
    font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color: ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PINK_2}; */
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #9e9e9e;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid #f0211e;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 2.9px;
      left: 2.9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #f0211d;

      // border: solid ${({ theme }) => theme.COLORS.PINK_2};
      // border-width: 0 3px 3px 0;
      // -webkit-transform: rotate(45deg);
      // -ms-transform: rotate(45deg);
      // transform: rotate(45deg);
    }
  }

  .theme_standard_input_check_box_square {
    display: flex;
    flex-wrap: wrap;
    /* font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
     font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color:  ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PINK_2}; */
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid #9e9e9e;
      // border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_blue_square {
    display: flex;
    flex-wrap: wrap;
    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 20px;
      max-width: 20px;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_34};
      // border-radius: 50%;
      &.disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_10};
        border-color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_30};
      border-color: ${({ bgActiveColor, theme }) =>
        bgActiveColor ? bgActiveColor : theme.COLORS.BLUE_30};
      background: ${({ bgActiveColor, theme }) =>
        bgActiveColor ? bgActiveColor : theme.COLORS.BLUE_30};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_standard_input_check_box_square_account {
    display: flex;
    flex-wrap: wrap;
    /* font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
    font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color:  ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PURPLE_1}; */

    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 10px;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 18px;
      max-width: 18px;
      width: 100%;
      height: 100%;
      // background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: gold;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid black;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_dark_blue {
    display: flex;
    flex-wrap: wrap;
    /* font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
    font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color: ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PURPLE_1}; */

    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 2px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      &.active_font {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
      &.dis_font {
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 14px;
      max-width: 14px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_29 : theme.COLORS.WHITE_1};
      border: 1px solid
        ${({ disabled, theme }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 5px;
      top: 1px;
      width: 2px;
      height: 6px;
      border: solid ${({ theme }) => theme.COLORS.WHITE_1};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .theme_purple {
    display: flex;
    flex-wrap: wrap;
    /* font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S16};
    font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "noto_sans_thai_regular, noto_sans_regular"};
    color: ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.PURPLE_1}; */

    .container_wrap {
      width: 50%;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      // padding-left: 33px;
      // padding-top: 2px;
      padding-right: 0px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
    }

    /* Hide the browser's default radio button */
    .container input {
      // position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0px;
    }

    .lable_show {
      margin-left: 2px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      &.active_font {
        /* color: ${({ theme }) => theme.COLORS.PURPLE_8}; */
      }
      &.dis_font {
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      margin-top: 1.7px;
      top: 0px;
      left: 0;
      max-height: 14px;
      max-width: 14px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_29 : theme.COLORS.WHITE_1};
      border: 1px solid
        ${({ disabled, theme }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.PURPLE_8};
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      // border: 1px solid #F0211E;
      background: ${({ theme }) => theme.COLORS.PURPLE_8};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      left: 5px;
      top: 1px;
      width: 2px;
      height: 6px;
      border: solid ${({ theme }) => theme.COLORS.WHITE_1};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

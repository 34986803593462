import React from "react";
import { connect } from "react-redux";
import { ManyUserAccountManagementContainerStyled } from "./styled";
import { adminService } from "apiServices";
import { DepartmentCodeContainer } from "./Modals";
import { RENDER_MEMBER_IMPORT_ERROR } from "utils/functions/member";
import moment from "moment";
import { ROUTE_PATH } from "utils/constants";
import {
  Tabs,
  Loading,
  Modal,
  BoxCofirm,
  Blocks,
  Icons,
  Forms,
  TableCustom,
  Buttons,
} from "components";

class ManyUserAccountManagementContainer extends React.Component {
  state = {
    isShowModal: false,
    hideForm: false,
  };

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleSubmit = () => {};

  handleRemoveFile = () => {
    this.setState({
      fileUploaded: false,
      memberReadyToUseData: false,
      memberRejectedData: false,
    });
  };
  handleChangeFile = async (values) => {
    this.setState({
      isLoading: true,
    });
    const bodyFormData = new FormData();
    bodyFormData.append("file", values[0]);
    let res = await adminService.POST_BULK_EMPLOYEE(
      this.props.companySelectedRedux.id,
      bodyFormData
    );
    if (res && res.success) {
      let reduceData = res.data.reduce(
        (result, data) => {
          if (data.result_code === "READY") {
            result.ready.push({
              ...data,
              id: data.index,
            });
          } else {
            result.rejected.push({
              ...data,
              result_status: RENDER_MEMBER_IMPORT_ERROR(data.result_code),
            });
          }
          return result;
        },
        { ready: [], rejected: [] }
      );
      this.setState({
        isLoading: false,
        fileUploaded: values[0],
        memberReadyToUseData: reduceData.ready,
        memberRejectedData: reduceData.rejected,
        hideForm: true,
      });
    }
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };
  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleClickSort = (e) => {};

  handlePopup = (e) => {
    const { selectedData } = this.state;
    let modalShow;
    if (e === "department") {
      modalShow = (
        <DepartmentCodeContainer
          companyID={this.props.companySelectedRedux.id}
          handleClickCloseModal={this.handleClickCloseModal}
        />
      );
    } else {
      modalShow = (
        <BoxCofirm
          theme_box_confirm
          handleCancel={this.handleClickCloseModal}
          handleConfirm={this.handleCreateMember}
          textShow="ยืนยันการบันทึกรายชื่อเข้าสู่ระบบ"
          textBottom={`รายชื่อทั้งหมด ${selectedData.length || 0} คน`}
          labelConfirm="บันทึก"
        />
      );
    }
    this.setState({
      isShowModal: true,
      renderModal: modalShow,
    });
  };

  handleCreateMember = async () => {
    const { selectedData, fileUploaded } = this.state;
    const bodyFormData = new FormData();
    bodyFormData.append("file", fileUploaded);
    bodyFormData.set("indexes", selectedData.join(","));
    let res = await adminService.POST_BULK_EMPLOYEE(
      this.props.companySelectedRedux.id,
      bodyFormData
    );
    if (res && res.success) {
      this.props.history.push(ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT);
    }
  };

  render() {
    const {
      isLoading,
      isShowModal,
      renderModal,
      memberReadyToUseData,
      memberRejectedData,
      hideForm,
    } = this.state;
    return (
      <ManyUserAccountManagementContainerStyled>
        <Blocks.Container>
          <div className="layer_one">
            <Tabs.HeadTab
              theme_standard_head_tab
              typeManayUserAccount
              title="สร้างรายชื่อจำนวนมาก"
              subTitle="Create many of name list"
              fontSize="34px"
              fontSizeLabelButtom="18px"
              fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              fontColor="#092945"
              colorBottom="#092945"
              lineHeight="1.3"
              handleClickButton={() => this.handlePopup("department")}
            />
          </div>
          <div className="browse_zone">
            <a
              className="top_zone"
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_API_ENDPOINT}/admin-api/companies/${this.props.companySelectedRedux?.id}/bulk_employees`}
            >
              <div className="icon_download">
                <Icons.Download3 />
              </div>
              <div>ดาวน์โหลดเทมเพลต</div>
            </a>

            {!hideForm && (
              <div className="form_show">
                <Forms.ManyUserUploadForm
                  onChangeFile={this.handleChangeFile}
                  onRemoveFile={this.handleRemoveFile}
                  onSubmit={this.handleSubmit}
                />
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="show_loading">
              <Loading theme_standard_loading />
            </div>
          ) : (
            <>
              <div className="import_zone">
                <div className="head_import">
                  <div className="label_head">
                    <div>รายชื่อที่ไม่สามารถนำเข้าสู่ระบบ</div>
                    <div className="sub_text">
                      โปรดตรวจสอบรายชื่อที่ไม่สามารถนำเข้าสู่ระบบ
                    </div>
                  </div>
                </div>
                <div className="table_show">
                  <TableCustom.TableNormal
                    theme_department
                    columns={columns}
                    data={memberRejectedData}
                    // pagination={pagination}
                    // useRenderNoPage
                    // useNo
                  />
                </div>
              </div>
              <div className="import_zone">
                <div className="head_import">
                  <div className="label_head">
                    <div>รายชื่อที่นำเข้าสู่ระบบ</div>
                    <div className="sub_text">
                      โปรดตรวจสอบรายชื่อที่เพิ่งนำเข้าสู่ระบบก่อนบันทึก
                    </div>
                  </div>
                  <div className="btn_save">
                    <Buttons.BtnNormal
                      theme_only_border
                      label="บันทึก"
                      fontSize="16px"
                      fontFamilyTop="noto_sans_bold, noto_sans_thai_bold"
                      padding="10px"
                      borderRadius="6px"
                      backgroundColor="#0B408D"
                      borderColor="#575D96"
                      fontColor="#fff"
                      onClick={() => this.handlePopup("save")}
                    />
                  </div>
                </div>
                <div className="table_show">
                  <TableCustom.TableAction
                    theme_department
                    columns={columnsImport}
                    data={memberReadyToUseData}
                    useNo
                    // pagination={pagination}
                    onChangeSelected={this.handleChangeSelectedTable}
                  />
                </div>
              </div>
            </>
          )}
          <Modal
            theme_modal_standard
            isShow={isShowModal}
            handleClickCloseModal={this.handleClickCloseModal}
          >
            {renderModal}
          </Modal>
        </Blocks.Container>
      </ManyUserAccountManagementContainerStyled>
    );
  }
}

const columnsImport = [
  {
    title: "",
    field: "",
    headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
    style: { textAlign: "center", width: "20px", flexShrink: 0 },
  },
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "รหัสพนักงาน",
    field: "employee_code",
    headerStyle: { textAlign: "center", maxWidth: "100px" },
    style: { textAlign: "center", maxWidth: "100px" },
    // render: ({ origin, value }) => <div>{String(value).padStart(4, '0')}</div>,
  },
  {
    title: "ชื่อ - นามสกุล",
    field: "firstname",
    headerStyle: { textAlign: "center", minWidth: "100px" },
    style: { textAlign: "left", minWidth: "100px" },
    render: ({ origin, value }) => (
      <div>{`${origin.firstname} ${origin.lastname}`}</div>
    ),
  },
  {
    title: "แผนก",
    field: "department",
    headerStyle: { textAlign: "center", minWidth: "100px" },
    style: { textAlign: "left", minWidth: "100px" },
    render: ({ origin, value }) => <div>{value?.name}</div>,
  },
  {
    title: "วัน เดือน ปี เกิด",
    field: "birth_date",
    headerStyle: { textAlign: "center", maxWidth: "150px" },
    style: { textAlign: "center", maxWidth: "150px" },
    render: ({ origin, value }) => (
      <div>{moment(value).format("DD MMMM YYYY")}</div>
    ),
  },
];

const columns = [
  {
    title: "รหัสพนักงาน",
    field: "employee_code",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
    },
  },
  {
    title: "ชื่อ - นามสกุล",
    field: "firstname",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      minWidth: "100px",
    },
    render: ({ origin, value }) => (
      <div>{`${origin.firstname} ${origin.lastname}`}</div>
    ),
  },
  {
    title: "แผนก",
    field: "department",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
    },
    render: ({ origin, value }) => <div>{value?.name}</div>,
  },
  {
    title: "",
    field: "result_status",
    headerStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      maxWidth: "450px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      maxWidth: "450px",
      color: "#FF1D00",
    },
    render: ({ origin, value }) => (
      <div>
        {origin.employee_code === "" || !origin.employee_code ? (
          <span>ไม่มีรหัสพนักงาน</span>
        ) : (
          <span>{value}</span>
        )}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManyUserAccountManagementContainer);

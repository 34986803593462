import React from "react";
import { UserAccountContainerStyled } from "./styled";
import {
  Forms,
  Loading,
  Modal,
  BoxDelete2,
  BGLoading,
  Icons,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import moment from "moment";
import { getObjectKey2 } from "utils/functions/getObjectKey";
import { renderErrorMessage } from "utils/functions/renderErrorMessage";

class UserAccountContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
    isCreate: this.props.match.params.id !== "create" ? true : false,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission();
  }

  fetchData = () => {
    this.fetchDepartment();
    if (this.props.match.params.id !== "create") {
      this.fetchDataEdit();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "employee_manage",
      "employee_manage_no_delete"
    );

    this.setState({
      perMissions: checkPermission,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  fetchDepartment = async () => {
    let res = await adminService.GET_DEPARTMENT_LIST(
      this.props.companySelectedRedux.id
    );
    if (res && res.success) {
      this.setState({
        deparmentList: res.data,
      });
    } else {
      this.setState({
        deparmentList: [],
      });
    }
  };

  fetchDataEdit = async () => {
    let res = await adminService.GET_EMPLOYEE_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT);
  };

  onSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });

    let params = {
      ...values,
      department_id: values.department_id.value,
      birth_date: moment(values.birth_date).format("YYYY-MM-DD"),
    };

    if (this.props.match.params.id !== "create") {
      delete params.code;
    }

    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_EMPLOYEE(
            this.props.companySelectedRedux?.id,
            params
          )
        : await adminService.PATCH_EMPLOYEE(this.props.match.params.id, params);

    if (res && res.success) {
      toast.success(
        this.props.match.params.id === "create" ? "สร้างสำเร็จ" : "แก้ไขสำเร็จ"
      );
      this.handleBackClick();
    } else {
      let txtErr = renderErrorMessage(res.error_code, res.error);

      toast.error(txtErr);
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleDelete = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await adminService.DELETE_EMPLOYEE(this.props.match.params.id);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.handleBackClick();
    } else {
      toast.error("ลบไม่สำเร็จ");
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const {
      isLoading,
      initialValues,
      deparmentList,
      isShowModal,
      perMissions,
      isBgLoading,
      isCreate,
    } = this.state;

    const { companySelectedRedux } = this.props;

    return (
      <UserAccountContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          deparmentList && (
            <Forms.CreateUserAccountForm
              initialValues={initialValues}
              isCreate={isCreate}
              companyCurrent={this.props.companySelectedRedux.id}
              companySelectedRedux={companySelectedRedux}
              onSubmit={this.onSubmit}
              handleDelete={this.handlePopup}
              handleBack={this.handleBackClick}
              perMissions={perMissions}
              deparmentList={deparmentList}
              isCannotEditDelete={perMissions ? false : true}
            />
          )
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <BoxDelete2
            theme_box_delete
            textShow={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Icons.Delete color="#EC5E49" />
                </div>
                <div style={{ color: "#EC5E49" }}>ยืนยันการลบหรือไม่ ?</div>
              </div>
            }
            handleCancel={this.handleClickCloseModal}
            handleConfirm={this.handleDelete}
          />
        </Modal>
      </UserAccountContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountContainer);

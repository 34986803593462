import { WeeklyPopularStyled } from "./styled";
import { Blocks, Icons, Cards } from "components";

const WeeklyPopular = ({ data, handleOnClick }) => {
  const renderPopular = (key) => {
    switch (key) {
      case 1:
        return <Icons.Bow1 />;
      case 2:
        return <Icons.Bow2 />;
      case 3:
        return <Icons.Bow3 />;
      default:
        break;
    }
  };

  return (
    <WeeklyPopularStyled>
      <Blocks.Box
        theme_standard_box_weeklypopular
        paddingTop="0px"
        paddingRight="30px"
        paddingBottom="18px"
        paddingLeft="30px"
      >
        <div className="container_pop">
          {data && data.length > 0 ? (
            data.map((e, i) => (
              <div
                key={i + 1}
                className="top_week"
                onClick={() => handleOnClick(e)}
              >
                <Cards.Popular
                  theme_popular
                  svg={renderPopular(i + 1)}
                  label1={`หมวด: ${e.category.name_th}`}
                  label2={e.category.name_en}
                  label3={`แลกไปแล้ว ${e.redeemed_count_weekly} สิทธิ์`}
                  src={e.image_uri}
                />
              </div>
            ))
          ) : (
            <div className="label_empty">
              ยังไม่มีการแลกรางวัลภายในสัปดาห์นี้
            </div>
          )}
        </div>
      </Blocks.Box>
    </WeeklyPopularStyled>
  );
};

export default WeeklyPopular;

import React from "react";
import { connect } from "react-redux";
import { TokenManagerStyled } from "./styled";
import { adminService } from "apiServices";
import { withRouter } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants";
import {
  setReduxAuthen,
  setReduxCompanySelected,
  setReduxCompanyList,
} from "store/actions";
import { Loading } from "components";

class TokenManager extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.checkTokenExpire();
  }

  checkTokenExpire = async () => {
    let res = await adminService.POST_LOGIN_WITH_TOKEN();
    if (res && res.success) {
      localStorage.setItem("token", res.token);
      let resProfile = await new Promise(async (resolve, reject) => {
        let res = await this.fetchProfile();
        if (res) {
          resolve(res);
        }
      });
      if (resProfile) {
        if (this.props.location.pathname === "/") {
          this.setState({ loading: true });
          this.props.history.replace(ROUTE_PATH.ADMINISTARTOR);
        } else {
          this.setState({ loading: true });
          this.props.history.replace(this.props.location.pathname);
        }
      }
    } else {
      this.setState({ loading: true });
      this.destroyStore();
    }
  };

  fetchProfile = async () => {
    const { companySelectedRedux } = this.props;
    let res = await adminService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.props.setReduxAuthen(res.data);
      if (res.data.role === "invitrace_admin") {
        this.props.setReduxCompanyList(false);
        this.props.setReduxCompanySelected({ id: 0, name: "" });
        return true;
      } else {
        let res = await adminService.GET_COMPANY_LIST();
        if (res && res.success) {
          this.props.setReduxCompanyList(res.data);
          if (!companySelectedRedux) {
            this.props.setReduxCompanySelected({
              id: res.data[0].id,
              name: res.data[0].name,
              isParent: res.data[0].is_parent,
            });
          }
          return true;
        }
      }
    }
  };

  // destroyStore = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  destroyStore = () => {
    localStorage.clear();
    switch (this.props.location.pathname) {
      case "/reset_password":
        break;
      default:
        this.props.history.replace("/");
        break;
    }
  };

  render() {
    const { children } = this.props;
    const { loading } = this.state;
    return (
      <TokenManagerStyled>
        {!loading ? (
          <div className="loading_page">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>{children}</>
        )}
      </TokenManagerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxCompanyList: (data) => dispatch(setReduxCompanyList(data)),
  setReduxCompanySelected: (data) => dispatch(setReduxCompanySelected(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TokenManager)
);

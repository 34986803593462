import styled from "styled-components";

export const BtnDateFillterStyled = styled.div`
  .theme_standard_date_filter {
    font-family: noto_sans_thai_regular, noto_sans_regular;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    .top_opt {
      display: flex;
      margin-bottom: 4px;
      .opt1 {
        padding: 8px 0px;
        width: 33.33%;
        min-width: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background: ${({ theme }) => theme.COLORS.BLUE_18};
        &.active {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border: 2px solid ${({ theme }) => theme.COLORS.BLUE_17};
          border-radius: 6px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        }
      }
      .opt2 {
        padding: 8px 0px;
        width: 33.33%;
        min-width: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-left: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
        cursor: pointer;
        border-top: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        background: ${({ theme }) => theme.COLORS.BLUE_18};
        &.active {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border: 2px solid ${({ theme }) => theme.COLORS.BLUE_17};
          border-radius: 6px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        }
      }
      .opt3 {
        padding: 8px 0px;
        width: 33.33%;
        display: flex;
        min-width: 125px;
        justify-content: center;
        align-items: center;
        border-left: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        cursor: pointer;
        border-top: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        border-right: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_17};
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: ${({ theme }) => theme.COLORS.BLUE_18};
        &.active {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border: 2px solid ${({ theme }) => theme.COLORS.BLUE_17};
          border-radius: 6px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
        }
      }
    }
    .bottom_opt {
      display: flex;
      .label1 {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        &.active {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
      .label2 {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        &.active {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
    }
  }
`;

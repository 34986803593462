import styled from "styled-components";

export const HealthCheckContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title_layout {
    margin-bottom: 19px;
  }
  .filter_health_top {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_filter_health {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .g_filter_label {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .s_label_filter {
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .box_blue {
        background: ${({ theme }) => theme.COLORS.BLUE_26};
        border-radius: 8px;
        padding: 8px 18px;
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        .date_blue {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        }
      }
      .box_filer_date_top {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .bw_date {
          width: 113px;
        }
      }
    }
  }
  .box_2_g {
    margin-top: 24px;
    display: flex;
    column-gap: 14px;
    overflow: auto;
    .g_w424 {
      max-width: 424px;
      min-width: 275px;
      width: 35%;
    }
    .g_full {
      flex: 1;
    }
  }
  .full_w_100 {
    margin-top: 16px;
    &.mt40 {
      margin-top: 40px;
    }
    .mid_title {
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
    .mid_table_show {
      margin-top: 24px;
      .des_sub {
        margin-top: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }

  .t_tab {
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }

  .group_value_show {
    display: flex;
    column-gap: 5px;
    .box_value_show {
      display: flex;
      align-items: center;
      width: 48%;
      height: 40px;
      &.pdl5 {
        padding-left: 8px;
        border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      }
      .text_warp {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

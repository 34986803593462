import styled from 'styled-components';

export const InputDatePikerCustom2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  position: relative;
  .custom_date {
    padding: 9px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4}1A;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .body_show {
      display: flex;
      position: relative;
      .svg_front {
        position: absolute;
        top: 2px;
        left: -4px;
      }
      .label_date {
        width: 100%;
      }
    }
  }
  .arrow_icon {
    position: absolute;
    top: 0px;
    right: -4px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input {
        padding: 9px;
        font-size: 18px;
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: #131313;
        border: 1px solid #f0211d;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      min-width: 230px;
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__header react-datepicker-year-header {
          }
          .react-datepicker__month react-datepicker__monthPicker {
          }
        }
      }
    }
  }

  .theme_quarter {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        min-width: auto;
        
      }
    }
  }

  .theme_year {
    .react-datepicker {
      .react-datepicker__year--container {
        .react-datepicker__year {
          .react-datepicker__year-wrapper {
            max-width: none;
            width: 204px;
          }
        }
      }
    }
  }
`;

import styled from "styled-components";

export const AddSpinGameFormStyled = styled.div`
  .layer_one {
    display: flex;
  }
  .tab_zone_select_data {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    .status_show_name {
      display: flex;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_24};
      column-gap: 3px;
      .lbl_status_name {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
  }
  .box_one {
    display: flex;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    padding: 30px 22px;
    .box50 {
      width: 50%;
      .line_obj {
        width: 100%;
        display: flex;
        column-gap: 14px;
        align-items: center;
        margin-bottom: 38px;
        &.mb137 {
          margin-bottom: 113px;
        }
        &.flex_start {
          align-items: flex-start;
        }
        &.mb47 {
          margin-bottom: 47px;
        }
        .label_group {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          width: 140px;
          .pt17 {
            padding-top: 4px;
          }
          .sub_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
          }
        }
        .right_obj {
          width: calc(100% - 154px);
          flex-shrink: 0;
          // background: wheat;
          display: flex;
          column-gap: 10px;
          align-items: center;
          position: relative;
          .err_date {
            position: absolute;
            top: 43px;
            left: 3px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.SEMI_BOLD};
            color: ${({ theme }) => theme.COLORS.RED_8};
          }
          &.between {
            column-gap: 0px;
            justify-content: space-between;
            align-items: flex-start;
          }
          .w95 {
            width: 95px;
          }
          .w_text_rich {
            width: 100%;
          }
          .w100 {
            width: 100%;
          }
          .label_mid {
            width: 70px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
          }
          .small_label_mid {
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
          }
          .pt10 {
            padding-top: 10px;
            width: 89px;
          }
          .back_label {
            padding-top: 8px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            width: 72px;
          }
        }
      }
    }
  }
  .b_title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .box_two {
    display: block;
    // &.show {
    //   display: block;
    // }
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    padding: 30px 22px;
    .box_obj_right {
      display: flex;
      column-gap: 12px;
      margin-bottom: 20px;
      .lbl_group {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        width: 140px;
        .sub_lbl {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
    }
    .list_wheel_rewards {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .list_box {
        display: flex;
        // column-gap: 10px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_26};
        padding-bottom: 12px;
        flex-wrap: wrap;
        row-gap: 20px;
        justify-content: space-between;
        .box33 {
          width: 32%;
          display: flex;
          column-gap: 5px;
          justify-content: space-between;
          row-gap: 20px;
          flex-shrink: 0;
          position: relative;
          &.gray {
            border-radius: 10px;
            padding: 10px;
            background: ${({ theme }) => theme.COLORS.GRAY_22};
          }
          .group_radio_show {
            width: 80px;
            padding-top: 10px;
            // flex-shrink: 0;
            white-space: nowrap;
          }
          .group_obj_select {
            min-width: calc(100% - 105px);
            max-width: calc(100% - 105px);
            width: 100%;
            row-gap: 10px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            &.w205 {
              width: 88px;
              min-width: unset;
              max-width: unset;
              // flex-shrink: 0;
            }
            .box_width {
              width: 100%;
              flex-shrink: 0;
            }
          }
          .g_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            width: 85px;
            flex-shrink: 0;
            .g_sub_lbl {
              font-size: ${({ theme }) => theme.FONT.SIZE.S12};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              color: ${({ theme }) => theme.COLORS.GRAY_24};
            }
          }
          .lbl_back {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding-top: 6px;
            width: 65px;
            white-space: nowrap;
          }
          .amount_label {
            position: absolute;
            bottom: 0px;
            right: 0px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.SEMI_BOLD};
            color: ${({ theme }) => theme.COLORS.BLUE_27};
          }
        }
      }
    }
  }
  .btn_zone {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    column-gap: 11px;
    align-items: center;
    .btn144 {
      width: 144px;
      &.min144 {
        width: unset;
        min-width: 144px;
      }
    }
    .del_btn {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMI_BOLD};
      color: ${({ theme }) => theme.COLORS.RED_8};
      width: 144px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width:  1340px) {
    .box_one  {
      .box50 {
        .line_obj {
          &.mb137 {
            margin-bottom: 206px;
          }
          .right_obj {
            &.flex_start {
              align-items: flex-start;
              flex-direction: column;
              row-gap: 5px;
            }
            .err_date {
              position: absolute;
              top: 131px;
              left: 3px;
            }
            .w100 {
              &.max244 {
                 max-width: 244px;
              } 
            }
            .label_mid {
              flex-direction: column;
              align-items: center;
              display: flex;
              width: 244px;
            }
          }
        }
      }
    }
  }

  .box_md {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 40px 60px;
    border-radius: 16px;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMI_BOLD};
    color: ${({ theme }) => theme.COLORS.GRAY_23};
    .line_label {
        display: flex;
        column-gap: 5px;
        .red_text {
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.RED_8};
        }
    }
    .btn_100 {
        margin-top: 32px;
        width: 100%;
    }
}

  @media (max-width:  1220px) {
    .box_two {
      .list_wheel_rewards {
        .list_box {
          flex-direction: column;
          .box33 {
            width: 50%;
          }
        }
      }
    }
  }
`;

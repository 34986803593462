import styled from "styled-components";

export const StandardStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_modal_standard {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    display: ${({ _isShow }) => (_isShow ? "flex" : "none")};
    justify-content: center;
    align-items: ${({ align }) => (align ? align : "center")};
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
    }
  }
  .theme_modal_margin_top {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    display: ${({ _isShow }) => (_isShow ? "flex" : "none")};
    justify-content: center;
    align-items: ${({ align }) => (align ? align : "center")};
    margin-top: 136px;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: relative;
      z-index: 91;
      margin: auto;
    }
  }

  .theme_modal_flex_end {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    display: ${({ _isShow }) => (_isShow ? "flex" : "none")};
    justify-content: center;
    align-items: flex-end;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
    }
  }
`;

export default function MenuBMI({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.25"
      height="22.252"
      viewBox="0 0 22.25 22.252"
    >
      <g id="bmi_1_" data-name="bmi (1)" transform="translate(0 -0.158)">
        <path
          id="Path_49671"
          data-name="Path 49671"
          d="M11.125.159A11.1,11.1,0,0,0,.39,14.152a.651.651,0,0,0,.629.48H21.23a.651.651,0,0,0,.629-.48A11.1,11.1,0,0,0,11.125.159ZM4.927,11.9H3.7a.65.65,0,1,1,0-1.3H4.927a.65.65,0,1,1,0,1.3ZM6.321,8.485a.652.652,0,0,1-.89.238L4.367,8.11a.65.65,0,1,1,.651-1.126L6.083,7.6A.649.649,0,0,1,6.321,8.485ZM8.351,6.46a.652.652,0,0,1-.89-.238L6.847,5.16a.651.651,0,0,1,1.128-.65L8.59,5.572A.649.649,0,0,1,8.351,6.46Zm2.122-2.617a.651.651,0,0,1,1.3,0V5.068a.651.651,0,0,1-1.3,0Zm3.153,5.833-2.041,2.037a.651.651,0,0,1-.921-.919l2.041-2.037a.651.651,0,0,1,.921.919ZM15.4,5.16l-.614,1.062a.651.651,0,0,1-1.128-.65l.614-1.062a.651.651,0,0,1,1.128.65Zm.526,3.325a.649.649,0,0,1,.238-.888l1.064-.613a.65.65,0,1,1,.651,1.126l-1.064.613A.652.652,0,0,1,15.928,8.485ZM18.551,11.9H17.323a.65.65,0,1,1,0-1.3h1.229a.65.65,0,1,1,0,1.3Z"
          transform="translate(0)"
          fill="#fff"
        />
        <path
          id="Path_49672"
          data-name="Path 49672"
          d="M219.013,361.272a.779.779,0,0,0-1.506-.061l-.958,3.328c-.005-.017-.995-3.341-1-3.357a.779.779,0,0,0-1.5.1v.007l-.933,5.12a.656.656,0,0,0,1.292.231l.531-2.913c.005.017.878,2.948.884,2.965a.779.779,0,0,0,1.481-.03l.846-2.939.564,2.924A.656.656,0,0,0,220,366.4Z"
          transform="translate(-203.875 -344.806)"
          fill="#fff"
        />
        <path
          id="Path_49673"
          data-name="Path 49673"
          d="M404.594,360.6a.653.653,0,0,0-.656.65v5.258a.656.656,0,0,0,1.313,0v-5.258A.653.653,0,0,0,404.594,360.6Z"
          transform="translate(-386.393 -344.751)"
          fill="#fff"
        />
        <path
          id="Path_49674"
          data-name="Path 49674"
          d="M81.853,363.477a1.836,1.836,0,0,0,.306-1.016,1.871,1.871,0,0,0-1.878-1.86H78.738a.653.653,0,0,0-.656.65v5.258a.647.647,0,0,0,.193.461.66.66,0,0,0,.463.189h0c.014,0,1.423-.006,1.809-.006a2.078,2.078,0,0,0,2.085-2.065A2.055,2.055,0,0,0,81.853,363.477ZM79.395,361.9h.886a.56.56,0,1,1,0,1.12h-.886Zm1.155,3.952-1.155,0v-1.533c.405,0,.215,0,1.155,0a.765.765,0,1,1,0,1.53Z"
          transform="translate(-74.696 -344.751)"
          fill="#fff"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.254"
      height="22.252"
      viewBox="0 0 22.254 22.252"
    >
      <g id="bmi" transform="translate(0 -0.027)">
        <path
          id="Path_49659"
          data-name="Path 49659"
          d="M19,3.287A11.128,11.128,0,0,0,.387,14.062a.652.652,0,0,0,.629.482H21.241a.652.652,0,0,0,.629-.482A11.144,11.144,0,0,0,19,3.287Zm1.733,9.953H1.529a9.824,9.824,0,1,1,19.2,0Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_49660"
          data-name="Path 49660"
          d="M241.683,72.554a.652.652,0,0,0,.652-.652V70.673a.652.652,0,1,0-1.3,0V71.9A.652.652,0,0,0,241.683,72.554Z"
          transform="translate(-230.554 -66.951)"
        />
        <path
          id="Path_49661"
          data-name="Path 49661"
          d="M156.755,93.25a.652.652,0,0,0-1.129.652l.615,1.065a.652.652,0,1,0,1.129-.652Z"
          transform="translate(-148.778 -88.858)"
        />
        <path
          id="Path_49662"
          data-name="Path 49662"
          d="M95,156.21l-1.065-.615a.652.652,0,1,0-.652,1.129c1.157.668,1.164.7,1.39.7A.653.653,0,0,0,95,156.21Z"
          transform="translate(-88.914 -148.722)"
        />
        <path
          id="Path_49663"
          data-name="Path 49663"
          d="M71.931,241H70.7a.652.652,0,0,0,0,1.3h1.229a.652.652,0,1,0,0-1.3Z"
          transform="translate(-67.005 -230.5)"
        />
        <path
          id="Path_49664"
          data-name="Path 49664"
          d="M383.727,241.654a.652.652,0,0,0,.652.652h1.229a.652.652,0,0,0,0-1.3h-1.229a.652.652,0,0,0-.652.652Z"
          transform="translate(-367.048 -230.5)"
        />
        <path
          id="Path_49665"
          data-name="Path 49665"
          d="M366,155.6l-1.065.615a.652.652,0,1,0,.652,1.129l1.065-.615A.652.652,0,1,0,366,155.6Z"
          transform="translate(-348.759 -148.723)"
        />
        <path
          id="Path_49666"
          data-name="Path 49666"
          d="M313.97,93.012a.652.652,0,0,0-.891.239l-.615,1.065a.652.652,0,1,0,1.129.652l.615-1.065A.652.652,0,0,0,313.97,93.012Z"
          transform="translate(-298.799 -88.859)"
        />
        <path
          id="Path_49667"
          data-name="Path 49667"
          d="M243.246,194.2l-2.043,2.043a.652.652,0,1,0,.922.922l2.043-2.043a.652.652,0,0,0-.922-.922Z"
          transform="translate(-230.536 -185.548)"
        />
        <path
          id="Path_49668"
          data-name="Path 49668"
          d="M218.979,361.283l0-.008a.771.771,0,0,0-1.489-.084c-.006.018-.953,3.342-.958,3.36l-.987-3.344-.007-.023a.771.771,0,0,0-1.489.107l-.927,5.135a.652.652,0,0,0,1.283.232l.527-2.921.87,2.95.007.023a.771.771,0,0,0,1.464-.007l.007-.023.84-2.948.56,2.932a.652.652,0,1,0,1.281-.245Z"
          transform="translate(-203.847 -344.955)"
        />
        <path
          id="Path_49669"
          data-name="Path 49669"
          d="M404.694,360.655a.652.652,0,0,0-.652.652v5.273a.652.652,0,1,0,1.3,0v-5.273A.652.652,0,0,0,404.694,360.655Z"
          transform="translate(-386.48 -344.952)"
        />
        <path
          id="Path_49670"
          data-name="Path 49670"
          d="M81.764,363.543a1.864,1.864,0,0,0-1.562-2.884H78.671a.652.652,0,0,0-.652.652v5.273a.652.652,0,0,0,.652.652h0c.014,0,1.413-.006,1.8-.006a2.07,2.07,0,0,0,1.294-3.687Zm-1.562-1.58a.561.561,0,1,1,0,1.123l-.559,0h-.32v-1.124h.88Zm.268,3.963-1.147,0v-1.538H80.47a.768.768,0,0,1,0,1.535Z"
          transform="translate(-74.627 -344.956)"
        />
      </g>
    </svg>
  );
}

export default function Female3() {
  return (
    <svg id="flat" xmlns="http://www.w3.org/2000/svg" width="29.88" height="29.88" viewBox="0 0 29.88 29.88">
      <path id="Path_19117" data-name="Path 19117" d="M73.928,344l9.471,4.984a4.087,4.087,0,0,1,2.191,2.95l.29,1.844H56l.29-1.844a4.087,4.087,0,0,1,2.191-2.95L67.952,344" transform="translate(-56 -323.899)" fill="#ffb3b3" />
      <path id="Path_19118" data-name="Path 19118" d="M304.9,446.216l1.812,1.208-1.087,1.63H304v-1.7Z" transform="translate(-285.287 -419.174)" fill="#faa6b2" />
      <path id="Path_19119" data-name="Path 19119" d="M143.809,68.1h-2.6a4.017,4.017,0,0,1-2.165-.619,2.942,2.942,0,0,1-1.435-2.691l.915-11.348a5.52,5.52,0,0,1,1.979-3.73,7.434,7.434,0,0,1,4.9-1.712q1.221.012,2.442,0A4.719,4.719,0,0,1,152,50.168a4.689,4.689,0,0,1,2.795,2.08l.016.028a3.733,3.733,0,0,1,.464,1.282l1.416,8.71a3.922,3.922,0,0,1-2.1,4l-.044.025a5.511,5.511,0,0,1-2.734.713H150.14Z" transform="translate(-132.063 -47.994)" fill="#683b0d" />
      <path id="Path_19120" data-name="Path 19120" d="M208.039,312v2.173l-.543,1.63v8.149h6.519V315.8l-.543-1.63V312Z" transform="translate(-195.854 -294.072)" fill="#fdc9a6" />
      <path id="Path_19121" data-name="Path 19121" d="M220.933,312v1.245a4.4,4.4,0,0,1-2.7.928h-.079a4.249,4.249,0,0,1-2.656-.927V312Z" transform="translate(-203.315 -294.072)" fill="#fdc9a6" />
      <path id="Path_19122" data-name="Path 19122" d="M220.933,312v1.245a4.4,4.4,0,0,1-2.7.928h-.079a4.249,4.249,0,0,1-2.656-.927V312Z" transform="translate(-203.315 -294.072)" fill="#e6af89" />
      <path id="Path_19123" data-name="Path 19123" d="M96,450.716v-2.173a.543.543,0,1,1,1.087,0v2.173Z" transform="translate(-93.284 -420.836)" fill="#faa6b2" />
      <path id="Path_19124" data-name="Path 19124" d="M400,450.716v-2.173a.543.543,0,1,1,1.086,0v2.173Z" transform="translate(-373.923 -420.836)" fill="#faa6b2" />
      <path id="Path_19125" data-name="Path 19125" d="M145.673,176h-.751a1.422,1.422,0,0,0-1.422,1.422v.959a1.422,1.422,0,0,0,1.422,1.422h1.023Z" transform="translate(-136.905 -167.307)" fill="#fdc9a6" />
      <path id="Path_19126" data-name="Path 19126" d="M331.772,176h.751a1.422,1.422,0,0,1,1.422,1.422v.958a1.423,1.423,0,0,1-1.423,1.422H331.5Z" transform="translate(-310.735 -167.307)" fill="#fdc9a6" />
      <path id="Path_19127" data-name="Path 19127" d="M264.961,378.261a.543.543,0,0,1-.486-.786l.543-1.087a.543.543,0,0,1,.972.486l-.543,1.087A.544.544,0,0,1,264.961,378.261Z" transform="translate(-248.759 -353.808)" fill="#e6af89" />
      <path id="Path_19128" data-name="Path 19128" d="M225.085,378.261a.543.543,0,0,1-.486-.3l-.543-1.087a.543.543,0,0,1,.972-.486l.543,1.087a.543.543,0,0,1-.486.786Z" transform="translate(-211.376 -353.808)" fill="#e6af89" />
      <path id="Path_19129" data-name="Path 19129" d="M169.766,446.662,168,447.839l1.087,1.63h1.63v-1.7Z" transform="translate(-159.549 -419.589)" fill="#faa6b2" />
      <path id="Path_19130" data-name="Path 19130" d="M167.581,320l-2.77,3.116a2.852,2.852,0,0,0-.12,3.646l2.347,3.017V323.26l.543-1.63Z" transform="translate(-155.908 -301.529)" fill="#faa6b2" />
      <path id="Path_19131" data-name="Path 19131" d="M295.493,320l2.77,3.116a2.852,2.852,0,0,1,.12,3.646l-2.346,3.017V323.26l-.543-1.63Z" transform="translate(-277.363 -301.529)" fill="#faa6b2" />
      <path id="Path_19132" data-name="Path 19132" d="M214.019,424a7.288,7.288,0,0,1-6.519,0l.034,4.346h6.519Z" transform="translate(-195.856 -398.466)" fill="#eaeae8" />
      <path id="Path_19133" data-name="Path 19133" d="M180.941,134.128h-.079a4.254,4.254,0,0,1-3.8-2.332l-.035-.069a14.211,14.211,0,0,1-1.537-6.43c0-1.346,0-2.581,0-2.581a10.615,10.615,0,0,0,5.155-1.336l2.485-1.38,3.226,3.8v1.577a14.194,14.194,0,0,1-1.466,6.282l-.007.014A4.4,4.4,0,0,1,180.941,134.128Z" transform="translate(-166.022 -115.11)" fill="#fdc9a6" />
      <g id="Group_14071" data-name="Group 14071" transform="translate(11.098 8.149)">
        <path id="Path_19134" data-name="Path 19134" d="M273.673,169.087h-1.63a.543.543,0,1,1,0-1.087h1.63a.543.543,0,0,1,0,1.087Z" transform="translate(-266.611 -168)" fill="#683b0d" />
        <ellipse id="Ellipse_1717" data-name="Ellipse 1717" cx="0.747" cy="0.747" rx="0.747" ry="0.747" transform="translate(5.364 1.562)" fill="#683b0d" />
        <path id="Path_19135" data-name="Path 19135" d="M201.673,169.087h-1.63a.543.543,0,0,1,0-1.087h1.63a.543.543,0,1,1,0,1.087Z" transform="translate(-199.5 -168)" fill="#683b0d" />
        <ellipse id="Ellipse_1718" data-name="Ellipse 1718" cx="0.747" cy="0.747" rx="0.747" ry="0.747" transform="translate(0.747 1.562)" fill="#683b0d" />
      </g>
      <path id="Path_19136" data-name="Path 19136" d="M233.121,265.344a2.911,2.911,0,0,1-1.269-.29l-.055-.027a.543.543,0,1,1,.474-.978l.055.027a1.832,1.832,0,0,0,1.632-.02.543.543,0,1,1,.5.965A2.913,2.913,0,0,1,233.121,265.344Z" transform="translate(-218.219 -249.326)" fill="#e6af89" />
      <path id="Path_19137" data-name="Path 19137" d="M241.63,218.173h-1.087a.543.543,0,0,1-.543-.543v-1.087a.543.543,0,0,1,1.086,0v.543h.543a.543.543,0,0,1,0,1.086Z" transform="translate(-226.147 -204.591)" fill="#e6af89" />
    </svg>
  );
}

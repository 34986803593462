import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { RewardMostPickedStyled } from './styled';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const RewardMostPicked = ({ theme_most_picked }) => {
  const customClass = cx({
    theme_most_picked: theme_most_picked,
  });
  return (
    <RewardMostPickedStyled>
      <div className={customClass}>
        <SkeletonTheme color="#DEDEDE" highlightColor="#F3F3F4">
          {/* <div className="top_pick">
            <div className="show_icon">
              <Skeleton circle={true} height={30} width={30} />
            </div>
            <div className='label_show'>
              <div>
                <Skeleton width='60%' />
              </div>
              <div>
                <Skeleton width='30%' />
              </div>
            </div>
          </div> */}
          <div className="mid_pick">
            <Skeleton width='100%' height='98%' />
          </div>
          {/* <div className="bot_pick">
            <Skeleton width='100%'  />
          </div> */}
        </SkeletonTheme>
      </div>
    </RewardMostPickedStyled>
  );
};

RewardMostPicked.propTypes = {};

export default RewardMostPicked;

export default function DotThree({ color = '#4CBBCB' }) {
  return (
    <svg width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1362_205768)">
        <path d="M10 2C10 2.82843 10.6716 3.5 11.5 3.5C12.3284 3.5 13 2.82843 13 2C13 1.17157 12.3284 0.5 11.5 0.5C10.6716 0.5 10 1.17157 10 2Z" fill={color} />
        <path d="M5 2C5 2.82843 5.67157 3.5 6.5 3.5C7.32843 3.5 8 2.82843 8 2C8 1.17157 7.32843 0.5 6.5 0.5C5.67157 0.5 5 1.17157 5 2Z" fill={color} />
        <path d="M0 2C0 2.82843 0.671573 3.5 1.5 3.5C2.32843 3.5 3 2.82843 3 2C3 1.17157 2.32843 0.5 1.5 0.5C0.671573 0.5 0 1.17157 0 2Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1362_205768">
          <rect width="13" height="3" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

import styled from 'styled-components';

export const DepartmentRankStyled = styled.div`
  .depart_container {
    overflow: auto;
    .top_current {
      display: flex;
      align-items: center;
      margin-bottom: 31px;
      .label_show {
        font-family: noto_sans_thai_medium, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};

        .sub_title_show {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
      }
    }
    .bottom_current {
    }
  }
`;

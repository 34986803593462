import styled from 'styled-components';

export const LabelDescripScoreStyled = styled.div`
  .container {
    display: flex;
    .icon_score_block {
      display: flex;
      margin-right: 16px;
      align-items: center;
      .icon_wrap {
        margin-right: 12px;
        height: 22px;
      }
      .txt_percent {
        font-size: ${({ theme }) => theme.FONT.SIZE.S28};
        font-family: noto_sans_bold;
        width: 72px;
        text-align: right;
        .red {
          color: ${({ theme }) => theme.COLORS.RED_3};
        }
        .blue {
          color: ${({ theme }) => theme.COLORS.BLUE_7};
        }
        .gray {
          color: ${({ theme }) => theme.COLORS.BLACK_5};
        }
      }
    }
    .label_descrip_container {
      display: flex;
      align-items: center;
      .txt_group {
        &.w44 {
          width: 44px;
        }
        .txt_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S10};
          color: ${({ theme }) => theme.COLORS.BLACK_3};
        }
        .txt_sub_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S7};
          color: ${({ theme }) => theme.COLORS.BLACK_3};
        }
      }
      .txt_mid {
        margin: 0 16px;
        width: 50px;
        text-align: right;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_bold;
        .red {
          color: ${({ theme }) => theme.COLORS.RED_3};
        }
        .blue {
          color: ${({ theme }) => theme.COLORS.BLUE_7};
        }
        .gray {
          color: ${({ theme }) => theme.COLORS.BLACK_5};
        }
      }
    }
  }

  .theme_standard {
  } 
  
`;

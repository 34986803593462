import { useLocation, useHistory } from "react-router-dom";
import { parseQueryString } from "../functions/parseQueryString";
import queryString from "query-string";

export const useRememberFilter = () => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = queryString.parse(location?.search);

  const handlerSearchParams = (e) => {
    let params = queryString.parse(location?.search);
    params = {
      ...params,
      ...e,
    };

    history.push({
      pathname: location?.pathname,
      search: parseQueryString(params),
    });
  };

  const handlerClearSearchParams = (e) => {
    history.push({
      pathname: location?.pathname,
      search: "",
    });
  };

  return {
    searchParams: searchParams,
    setSearchParams: handlerSearchParams,
    clearSearchParams: handlerClearSearchParams,
  };
};

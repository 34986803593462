import React from "react";
import { CurrentMissionContainerStyled } from "./styled";
import {
  CompanyMisssion,
  MissionDetail,
  MissionReward,
  ParticipantsDetails,
  DepartmentRank,
  ListEmployee,
} from "widgets";
import { Loading, Modal } from "components";
import { adminService } from "apiServices";
import { connect } from "react-redux";
import theme from "styles/theme.json";

class CurrentMissionContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: null,
    dataListUser: {
      data: [],
      hasMore: true,
    },
    isDeleted: 0,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async () => {
    let res = await Promise.all([this.fetchDataCurrent()]);

    if (res) {
      this.setState({
        isLoading: false,
        dataMission: res[0],
      });
      this.renderLabel(res[0].category);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchDataCurrent = async () => {
    const { companySelectedRedux } = this.props;
    let res = await adminService.GET_COMANY_MISSIONS(
      companySelectedRedux.id,
      "current",
      1,
      0
    );

    if (res && res.success) {
      if (res.data.length > 0) {
        this.fetchDeparmentList(res.data[0].id);
        this.setState({
          mission_id: res.data[0].id,
        });
        return res.data[0];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  renderLabel = (e) => {
    switch (e) {
      case "step":
        this.setState({
          labelStatus: {
            label1: "จำนวนก้าวเฉลี่ยในแผนก",
            label2: "Current step",
            unit: "ก้าว",
          },
        });
        break;

      case "mission_move":
        this.setState({
          labelStatus: {
            label1: "จำนวนขยับร่างกายเฉลี่ยในแผนก",
            label2: "Average body cumulative amount",
            unit: "ครั้ง",
          },
        });
        break;

      case "mission_eye":
        this.setState({
          labelStatus: {
            label1: "จำนวนคลายสายตาสะสมเฉลี่ยในแผนก",
            label2: "Average rest your eye cumulative amount",
            unit: "ครั้ง",
          },
        });
        break;

      default:
        break;
    }
  };

  fetchDeparmentList = async (e) => {
    let res = await adminService.GET_DEPARTMENT_LIST_BY_COMPANY_MISSION_ID(e);
    let res2 = await adminService.GET_COMPANY_MISSION_SUMMARY_BY_ID(e);

    if (res && res.success) {
      this.setState({
        departmentList: res.data,
        dataMissionDetail: res2.data,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchListUser = async ({ departmen_id, is_deleted }) => {
    const { dataListUser, mission_id, isDeleted } = this.state;

    let params =
      is_deleted !== undefined && isDeleted !== is_deleted
        ? { per_page: 40, offset: 0, is_deleted: is_deleted }
        : !dataListUser.page
        ? { per_page: 40, offset: 0, is_deleted: isDeleted }
        : {
            per_page: dataListUser.page.per_page,
            offset: dataListUser.page.per_page * dataListUser.page.current_page,
            is_deleted: isDeleted,
          };

    let res = await adminService.GET_LIST_USER_BY_MISSION_ID_BY_COMPANY_ID(
      mission_id,
      departmen_id,
      params
    );
    if (res && res.success) {
      this.setState((state) => ({
        dataListUser: {
          ...res,
          participants:
            is_deleted === undefined || is_deleted === 0
              ? res?.page?.total
              : state?.dataListUser?.participants,
          data:
            state.dataListUser.data.length === 0 ||
            (is_deleted !== undefined && isDeleted !== is_deleted)
              ? res.data
              : [...state.dataListUser.data, ...res.data],
          hasMore: res?.page?.to < res?.page?.total,
          // hasMore: res.data.length < Number(res.page.per_page) ? false : true,
        },
      }));
    }
    this.setState((state) => ({
      isDeleted: is_deleted === undefined ? state.isDeleted : is_deleted,
    }));
  };

  handlePopup = async (e) => {
    this.setState({
      department_id: e.id,
      department_detail: e,
    });

    this.fetchListUser({ departmen_id: e.id });
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: null,
      dataListUser: {
        data: [],
        hasMore: true,
      },
    });
  };

  render() {
    const {
      isLoading,
      dataMission,
      departmentList,
      dataMissionDetail,
      labelStatus,
      isShowModal,
      dataListUser,
      department_detail,
    } = this.state;

    return (
      <CurrentMissionContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : !dataMission || dataMission.length === 0 ? (
          <div className="label_empty">ยังไม่มีภารกิจ</div>
        ) : (
          <>
            <div className="block_2_col_layout mb_14">
              <div className="col_2 w_312">
                <div
                  className="img_show"
                  style={
                    dataMission && dataMission.image_uri
                      ? {
                          backgroundImage: `url(${dataMission.image_uri})`,
                        }
                      : {
                          background: theme.COLORS.GRAY_2,
                        }
                  }
                  alt="img"
                />
              </div>
              <div className="col_2 flex_1">
                <CompanyMisssion
                  dataCompany={
                    this.props.authenRedux.company &&
                    this.props.authenRedux.company
                  }
                  dataMission={dataMission && dataMission}
                />
              </div>
            </div>
            <div className="block_2_col_layout mb_47">
              <div className="col_2 min_w_568 flex_1">
                <MissionDetail dataMission={dataMission && dataMission} />
              </div>
              <div className="col_2 w_384 min_w_300 flex_1">
                <MissionReward
                  dataMission={dataMission && dataMission}
                  heightBox="229px"
                />
              </div>
            </div>
            <div className="participants_wrap_layout">
              <ParticipantsDetails
                data={dataMissionDetail && dataMissionDetail}
              />
            </div>
            <div className="rank_layout">
              <DepartmentRank
                data={departmentList}
                labelStatus={labelStatus}
                handleOnClick={this.handlePopup}
              />
            </div>
          </>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {
            <ListEmployee
              dataPage={dataListUser}
              department_detail={department_detail}
              fetchData={this.fetchListUser}
              onClose={this.handleClickCloseModal}
            />
          }
        </Modal>
      </CurrentMissionContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentMissionContainer);

import styled from "styled-components";

export const InputTxtIconStyled = styled.div`
  .label {
    margin-bottom: 5px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: noto_sans_thai_medium, noto_sans_regular;
  }
  .input_container {
    position: relative;
    .icon {
      position: absolute;
      top: 11px;
      left: 13px;
    }
    input {
      width: calc(100% - 69px);
      padding: 11px 10px 11px 57px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      padding-right: ${({ icon, showIconHide }) =>
        icon ? "36px" : showIconHide && "83px"};
      border-radius: 10px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
    }
    .svg_group {
      position: absolute;
      cursor: pointer;
      top: 12px;
      right: 8px;
      .svg_ac {
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        .lbl_hide {
          // color: ${({ theme }) => theme.COLORS.GRAY_DISABLE};
          &.color_text {
            color: ${({ theme }) => theme.COLORS.BLUE_7};
          }
        }
      }
    }
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_not_icon_standard {
    .label {
      margin-bottom: 5px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      border-color: ${({ theme }) => theme.COLORS.GRAY_23};
    }
    input {
      width: ${({ showIconHide }) =>
        showIconHide ? "calc(100% - 47px)" : "calc(100% - 22px)"};
      padding: 10.8px 10px;
      padding-right: ${({ showIconHide }) => showIconHide && "36px"};
      border-color: ${({ theme }) => theme.COLORS.GRAY_10};
      border-radius: 8px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      box-shadow: none;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${({ theme }) => theme.COLORS.GRAY_26};
      }
    }
  }
`;

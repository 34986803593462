// import React from "react";
import React, { useState, useEffect } from "react";
import { CurrentBurnoutSyndromeContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards } from "components";
import { adminService } from "apiServices";
import moment from "moment";
import momentTZ from "moment-timezone";
import {
  Overallburnout,
  BurnoutSyndrome,
  BurnoutSyndromeByDepartment,
} from "widgets";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";
import { useSelector } from "react-redux";

const CurrentBurnoutSyndromeContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [bosData, setBosData] = useState();
  const [causeBosData, setCauseBosData] = useState();
  const [bosSummaryDepartmentData, setBosSummaryDepartmentData] = useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchBos(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}&period=weekly`
      ),
      fetchCauseBos(
        `?period=weekly&stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      fetchBosSummaryDepartment(
        `?stat_date=${moment()
          .startOf("months")
          .format("YYYY-MM-DD")}&period=monthly`
      ),
    ]);
    if (res) {
      setBosData(res[0]);
      setCauseBosData(res[1]);
      setBosSummaryDepartmentData(res[2]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchBos = async (stringQuery) => {
    let res = await adminService.GET_BOS_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data;
    }
  };

  const fetchCauseBos = async (stringQuery, initialDate) => {
    let res = await adminService.GET_CAUSE_BOS_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return {
        data: res.data,
        stat_date:
          initialDate ||
          momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
      };
    }
  };

  const fetchBosSummaryDepartment = async (stringQuery) => {
    let res = await adminService.GET_BOS_SUMMARY_DEPARTMENT_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return sliceIntoChunks(
        res.data.map((e) => ({
          id: e.department.id,
          name: e.department.name,
          data: [
            { ...e.nosymptom_user, name: "nosymptom_user" },
            { ...e.exhausted_user, name: "exhausted_user" },
            { ...e.burnout_user, name: "burnout_user" },
          ],
        })),
        6
      );
    } else {
      return false;
    }
  };

  return (
    <CurrentBurnoutSyndromeContainerStyled>
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/logo_bou.svg"
              titleLabel="Burnout syndrome"
              label1="ภาวะการเปลี่ยนแปลงทางด้านจิตใจที่เป็นผลมาจากความเครียดเรื้อรังในที่ทำงานและไม่ได้รับการจัดการอย่างมีประสิทธิภาพ แบ่งลักษณะอาการออกเป็น 3 กลุ่ม ได้แก่"
              label2="1. เหนื่อยล้าทางอารมณ์ รู้สึกหมดพลัง สูญเสียพลังจิตใจ"
              label3="2. มีทัศนคติเชิงลบต่อความสามารถในการทำงานของตนเอง ขาดความเชื่อมั่นในความสำเร็จ"
              label4="3. ประสิทธิภาพการทำงานลดลง ความสัมพันธ์ในที่ทำงานเหินห่างหรือเป็นไปทางลบกับผู้ร่วมงานและลูกค้า"
            />
          </div>
          <div className="section_chart_layout">
            <Overallburnout data={bosData && bosData} fetchData={fetchBos} />
          </div>
          <div className="section_chart_layout">
            <BurnoutSyndrome
              data={causeBosData && causeBosData}
              fetchData={fetchCauseBos}
            />
          </div>
          <div className="section_chart_layout">
            <BurnoutSyndromeByDepartment
              data={bosSummaryDepartmentData && bosSummaryDepartmentData}
              fetchData={fetchBosSummaryDepartment}
            />
          </div>
        </>
      )}
    </CurrentBurnoutSyndromeContainerStyled>
  );
};

export default CurrentBurnoutSyndromeContainer;

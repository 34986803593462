export default function Pencil1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.648" viewBox="0 0 24 23.648">
      <g id="XMLID_3805_" transform="translate(0 -3.685)" opacity="0.5">
        <path id="XMLID_3975_" d="M378.9,8.578a2.866,2.866,0,0,0,0-4.054h0a2.866,2.866,0,0,0-4.054,0l-.507.507,4.054,4.054Z" transform="translate(-356.455 0)" />
        <path id="XMLID_3976_" d="M134.471,148.538l-11.5,11.5.38,2.66,12.642-12.642Z" transform="translate(-117.094 -137.933)" />
        <path id="XMLID_3977_" d="M.934,386.65l-.212.212L0,391.638l4.775-.722.212-.212-.507-3.547Z" transform="translate(0 -364.67)" />
        <path id="XMLID_4020_" d="M60.232,97.025l-1.52-1.52L46.07,108.147l2.66.38Z" transform="translate(-43.869 -87.434)" />
        <path id="XMLID_4021_" d="M0,0H1.433V5.733H0Z" transform="translate(15.854 7.059) rotate(-45)" />
        <path id="XMLID_4024_" d="M200.27,351.551H193.82a.717.717,0,0,1,0-1.433h4.3v-1.433h-4.3a2.15,2.15,0,0,0,0,4.3h6.449a.717.717,0,1,1,0,1.433h-12.9v1.433h12.9a2.15,2.15,0,1,0,0-4.3Z" transform="translate(-178.42 -328.518)" />
      </g>
    </svg>

  );
}

import styled from 'styled-components';

export const BoxIconTitleSubStyled = styled.div`
  .block_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    // min-width: 380px;
    .block_icon {
      width: 248px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 248px;
      border-radius: 8px;
      box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
      margin-bottom: 15px;
      .icon_wrap {
      }
    }
    .block_label {
    }
  }

  .theme_box_icon_standard {
  }
`;

import styled from 'styled-components';

export const BoxIntroduceStyled = styled.div`
  .main_board {
    display: flex;
    .main_left {
      width: 12%;
      display: flex;
      align-items: center;
      justify-content: center;
      .img_left {
        width: 100%;
      }
    }
    .main_right {
      width: 88%;
      padding-left: 24px;
      display: flex;
      .right_l {
        width: 80%;
        font-family: noto_sans_thai_regular, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_4};
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        .title_right {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          margin-bottom: 13px;
        }
      }
      .right_r {
        width: 20%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

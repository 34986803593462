import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { HealthCheckContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import moment from "moment";
import { adminService } from "apiServices";
// import { RenderCommaMoney, nFormatter } from "utils/functions/FormatMoney";
import {
  TotalEmployeeHealthCheckup,
  AgeDistribution,
  HealthCheckDepartment,
  PercentOfEmployeHealthCheck,
  ExportHealthCheck,
} from "widgets";
import {
  Blocks,
  Typographys,
  Icons,
  Loading,
  BGLoading,
  TableCustom,
  Inputs,
} from "components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const HealthCheckContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isBgLoading, setIsBgLoading] = useState();
  const [selectDate, setSelectDate] = useState(
    moment().tz("Asia/Bangkok").format(`YYYY-01-01`)
  );
  const [selectDateAbnormal, setSelectDateAbnormal] = useState(
    moment().tz("Asia/Bangkok").format(`YYYY-01-01`)
  );
  const [,setTempDate] = useState();
  const [data, setData] = useState();
  const [dataAbnormal, setDataAbnormal] = useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData(`?checkup_date=${selectDate}`);
    fetchAbnormalData(`?checkup_date=${selectDateAbnormal}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempDate = moment().tz("Asia/Bangkok").format(`YYYY-01-01`);
    setTempDate(moment().tz("Asia/Bangkok").format(`YYYY-01-01`));
    setIsLoading(true);
    fetchData(`?checkup_date=${tempDate}`);
    fetchAbnormalData(`?checkup_date=${tempDate}`);
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (stringQuery) => {
    let res = await adminService.GET_HEALTH_CHECKUP_SUMMARY(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setData(res.data);
      setIsLoading(false);
      setIsBgLoading(false);
    }
  };

  const fetchAbnormalData = async (stringQuery) => {
    let res = await adminService.GET_HEALTH_CHECKUP_ABNORMAL(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setDataAbnormal(res.data);
      setIsLoading(false);
      setIsBgLoading(false);
    }
  };

  const handleChangDate = (e) => {
    let tempDate = moment(e).tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsBgLoading(true);
    setSelectDate(tempDate);

    fetchData(`?checkup_date=${tempDate}`);
  };

  const handleChangDateAbnormal = (e) => {
    let tempDate = moment(e).tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsBgLoading(true);
    setSelectDateAbnormal(tempDate);

    fetchAbnormalData(`?checkup_date=${tempDate}`);
  };

  const handleDownload = async (e) => {
    setIsBgLoading(true);

    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      companySelectedRedux.id,
      "annual_checkup",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      // toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsBgLoading(false);
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      setIsBgLoading(false);
    }
  };

  return (
    <HealthCheckContainerStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label="Health check overview"
              align="center"
              svgFront={<Icons.HealthCheckOverView />}
            />
          </div>
          <div className="filter_health_top">
            <div className="box_filter_health">
              <div className="g_filter_label">
                <div>ภาพรวมพนักงานที่มีผลตรวจสุขภาพประจำปี</div>
                <div className="s_label_filter">
                  Employees annual health check overview
                </div>
              </div>
            </div>
            <div className="box_filter_health">
              <div className="box_blue">
                <div>คำนวณจากข้อมูลพนักงานทั้งหมด ณ วันที่</div>
                <div className="date_blue">
                  {`${moment().format("DD MMMM")} ${moment().year() + 543}`}
                </div>
              </div>
              <div className="box_filer_date_top">
                <div>แสดงสถิติข้อมูลปี</div>
                <div className="bw_date">
                  <Inputs.InputDatePikerCustom
                    theme_year
                    dateFormat="yyyy"
                    minDate={new Date("2021-01-01")}
                    maxDate={new Date()}
                    onChangeCustom={(e) => handleChangDate(e)}
                    showYearPicker
                    iconColor="#002366"
                    initialValues={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box_2_g">
            <div className="g_w424">
              <TotalEmployeeHealthCheckup
                data={data && data.summary && data.summary}
              />
            </div>
            <div className="g_full">
              <AgeDistribution
                data={data && data.age_group && data.age_group}
              />
            </div>
          </div>
          <div className="full_w_100">
            <HealthCheckDepartment
              data={data && data.department && data.department}
            />
          </div>
          <div className="filter_health_top">
            <div className="box_filter_health">
              <div className="g_filter_label">
                <div>ร้อยละพนักงานที่มีผลแลปผิดปกติ</div>
                <div className="s_label_filter">
                  Percentage of employees with abnormal lab results
                </div>
              </div>
            </div>
            <div className="box_filter_health">
              <div className="box_filer_date_top">
                <div>แสดงสถิติข้อมูลปี</div>
                <div className="bw_date">
                  <Inputs.InputDatePikerCustom
                    theme_year
                    dateFormat="yyyy"
                    minDate={new Date("2021-01-01")}
                    maxDate={new Date()}
                    onChangeCustom={(e) => handleChangDateAbnormal(e)}
                    showYearPicker
                    iconColor="#002366"
                    initialValues={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="full_w_100 mt40">
            <PercentOfEmployeHealthCheck data={dataAbnormal && dataAbnormal} />
          </div>
          <div className="full_w_100 mt40">
            <div className="mid_title">เกณฑ์</div>
            <div className="mid_table_show">
              <TableCustom.TableNormal
                theme_health_check
                columns={columns}
                data={tempData}
                // pagination={pagination}
                // useRenderNoPage
                // useNo
              />
              <div className="des_sub">
                *หมวดผลแลปที่มีหลายผลแลปย่อย หากผลแลปย่อยผิดปกติอย่างน้อย 1 ค่า
                ถือว่ามีความผิดปกติหมวดผลแลปนั้น
              </div>
            </div>
          </div>
          <div className="full_w_100 mt40">
            <ExportHealthCheck onDownload={handleDownload} />
          </div>
        </Blocks.Container>
      )}
    </HealthCheckContainerStyled>
  );
};

const columns = [
  {
    title: "หมวดผลแลป",
    field: "category",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      // justifyContent: "center",
      minWidth: "80px",
      maxWidth: "240px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      height: "40px",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    render: ({ origin, value }) => (
      <div
        className="t_tab"
        style={{
          minWidth: "80px",
          maxWidth: "240px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </div>
    ),
  },
  {
    title: "ผลแลปย่อย",
    field: "sub_category",
    headerStyle: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      height: "40px",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "80px",
          maxWidth: "240px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </div>
    ),
  },
  {
    title: "ค่าปกติ",
    field: "normal_value",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      // justifyContent: "center",
      minWidth: "400px",
    },
    style: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "left",
      minWidth: "400px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          width: "100%",
        }}
      >
        {origin.sub_normal_value ? (
          <div className="group_value_show">
            <div className="box_value_show">
              <div className="text_warp">{value}</div>
            </div>
            <div className="box_value_show pdl5">
              <div className="text_warp">{origin.sub_normal_value}</div>
            </div>
          </div>
        ) : (
          <div
            style={{ height: "40px", display: "flex", alignItems: "center" }}
          >
            {value}
          </div>
        )}
      </div>
    ),
  },
];

const tempData = [
  {
    category: "ดัชนีมวลกาย",
    sub_category: "BMI",
    normal_value: "18.5-22.9 kg/m2",
  },
  {
    category: "ความดันโลหิต",
    sub_category: "Blood pressure (SBP/DBP)",
    normal_value: "90-129 mmHg /60-79 mmHg",
  },
  {
    category: "น้ำตาลในเลือด",
    sub_category: "FBS",
    normal_value: "70-99 mg/dL",
  },
  {
    category: "ไขมันในเลือด",
    sub_category: "Cholesterol",
    normal_value: "< 200 mg/dL",
  },
  {
    category: "",
    sub_category: "Triglyceride",
    normal_value: "<150 mg/dL",
  },
  {
    category: "",
    sub_category: "LDL",
    normal_value: "<100 mg/dL",
  },
  {
    category: "",
    sub_category: "HDL",
    normal_value: "Male : ≥40 mg/dL",
    sub_normal_value: "Female : ≥50 mg/dL",
  },
  {
    category: "การทำงานของไต",
    sub_category: "BUN",
    normal_value: "Male ≤50 years : 8.9-20.6 mg/dL",
    sub_normal_value: "Female ≤50 years : 7.0-18.7 mg/dL",
  },
  {
    category: "",
    sub_category: "",
    normal_value: "Male >50 years : 8.4-25.7 mg/dL",
    sub_normal_value: "Female >50 years : 9.8- 20.1 mg/dL",
  },
  {
    category: "",
    sub_category: "SCr",
    normal_value: "Male : 0.73–1.18 mg/dL",
    sub_normal_value: "Female : 0.55–1.02 mg/dL",
  },
  {
    category: "สมรรถภาพตับ",
    sub_category: "SGOT",
    normal_value: "5-34 U/L",
  },
  {
    category: "",
    sub_category: "SGPT",
    normal_value: "0-55 U/L",
  },
  {
    category: "สารกรดยูริก",
    sub_category: "",
    normal_value: "Male : 3.5-7.2 mg/dL",
    sub_normal_value: "Female : 2.6-6.0 mg/dL",
  },
];

export default HealthCheckContainer;

export default function CategoryNews({ width = '32', height = '32' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_15964" data-name="Group 15964" transform="translate(-152 -336)">
        <circle id="Ellipse_1503" data-name="Ellipse 1503" cx="16" cy="16" r="16" transform="translate(152 336)" fill="#fef3f2" />
        <g id="newspaper" transform="translate(159.111 343.111)">
          <path id="Path_19218" data-name="Path 19218" d="M16.579,19.652H1.362A1.362,1.362,0,0,1,0,18.29V3.2A1.362,1.362,0,0,1,1.362,1.84H13.85A1.362,1.362,0,0,1,15.212,3.2v10.1Z" transform="translate(0 -1.84)" fill="#f3f3f3" />
          <path id="Path_19219" data-name="Path 19219" d="M436.857,267.465v-7.4h-2.729v7.4a1.362,1.362,0,0,0,1.362,1.362h0A1.362,1.362,0,0,0,436.857,267.465Z" transform="translate(-418.915 -251.015)" fill="#cacaca" />
          <rect id="Rectangle_17997" data-name="Rectangle 17997" width="12.156" height="5.149" transform="translate(1.504 1.581)" fill="#ffcc5b" />
          <path id="Path_19220" data-name="Path 19220" d="M43.974,50.9a.944.944,0,0,0,.757-.946V48.789a.764.764,0,0,1,.765-.762h.059a.723.723,0,0,1,.77.719v.727a.946.946,0,0,0,.76.949.91.91,0,0,0,1.06-.9v-.2a.763.763,0,0,1,.767-.763h.063a.8.8,0,0,1,.809.8v.35a.946.946,0,0,0,.76.949.91.91,0,0,0,1.06-.9v-1.4a.8.8,0,0,1,.809-.8h.021a.8.8,0,0,1,.809.8v.711a.946.946,0,0,0,.76.949.91.91,0,0,0,1.06-.9V46.944H42.912V50A.911.911,0,0,0,43.974,50.9Z" transform="translate(-41.408 -45.363)" fill="#fdbc4b" />
          <g id="Group_13782" data-name="Group 13782" transform="translate(3.052 2.989)">
            <path id="Path_19221" data-name="Path 19221" d="M87.088,90.494V88.24h.443l.922,1.505V88.24h.423v2.254H88.42l-.909-1.47v1.47h-.423Z" transform="translate(-87.088 -88.201)" fill="#464646" />
            <path id="Path_19222" data-name="Path 19222" d="M151.856,90.494V88.24h1.671v.381h-1.216v.5h1.131v.38h-1.131v.613h1.259v.38h-1.715Z" transform="translate(-149.586 -88.201)" fill="#464646" />
            <path id="Path_19223" data-name="Path 19223" d="M206.106,90.478l-.538-2.254h.466l.34,1.549.412-1.549h.541l.4,1.574.346-1.574h.458l-.547,2.254H207.5l-.449-1.685-.447,1.685h-.493Z" transform="translate(-201.416 -88.186)" fill="#464646" />
            <path id="Path_19224" data-name="Path 19224" d="M293.328,88.7l.443-.043a.542.542,0,0,0,.162.327.491.491,0,0,0,.33.1.507.507,0,0,0,.331-.093.277.277,0,0,0,.112-.218.205.205,0,0,0-.047-.136.376.376,0,0,0-.163-.1q-.08-.028-.364-.1a1.233,1.233,0,0,1-.514-.223.592.592,0,0,1-.11-.776.61.61,0,0,1,.281-.228,1.131,1.131,0,0,1,.443-.078.953.953,0,0,1,.639.186.657.657,0,0,1,.225.5l-.456.02a.389.389,0,0,0-.125-.25.461.461,0,0,0-.288-.076.52.52,0,0,0-.311.081.164.164,0,0,0-.072.14.173.173,0,0,0,.068.137,1.2,1.2,0,0,0,.418.151,2.215,2.215,0,0,1,.491.162.635.635,0,0,1,.249.229.71.71,0,0,1-.018.722.643.643,0,0,1-.3.251,1.276,1.276,0,0,1-.491.082.976.976,0,0,1-.657-.2A.86.86,0,0,1,293.328,88.7Z" transform="translate(-286.101 -87.136)" fill="#464646" />
          </g>
          <rect id="Rectangle_17998" data-name="Rectangle 17998" width="4.845" height="5.635" transform="translate(1.504 8.172)" fill="#3789c9" />
          <path id="Path_19225" data-name="Path 19225" d="M45.392,240.169a.438.438,0,0,0-.439-.351h-.541a.354.354,0,0,1-.353-.355v0c0-.008,0-.015,0-.023a.336.336,0,0,1,.334-.357h.337a.438.438,0,0,0,.44-.353.422.422,0,0,0-.417-.492h-.094a.354.354,0,0,1-.354-.356v-.03a.373.373,0,0,1,.373-.375h.163a.438.438,0,0,0,.44-.353.422.422,0,0,0-.417-.492h-.649a.373.373,0,0,1-.372-.375v-.01a.373.373,0,0,1,.372-.375h.33a.438.438,0,0,0,.44-.353.422.422,0,0,0-.417-.492H42.256v5.637h2.72A.422.422,0,0,0,45.392,240.169Z" transform="translate(-40.775 -226.853)" fill="#4b9fd8" />
          <g id="Group_13783" data-name="Group 13783" transform="translate(1.481 10.429)">
            <rect id="Rectangle_17999" data-name="Rectangle 17999" width="5.831" height="1.121" transform="translate(6.342)" fill="#cacaca" />
            <rect id="Rectangle_18000" data-name="Rectangle 18000" width="5.831" height="1.121" transform="translate(6.342 2.3)" fill="#cacaca" />
            <rect id="Rectangle_18001" data-name="Rectangle 18001" width="12.173" height="1.121" transform="translate(0 4.6)" fill="#cacaca" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function renderIcon({
  color = "#002366",
  width = "40",
  height = "40",
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.3332 9.33325H28V10.6669C28 12.8725 26.206 14.6669 24 14.6669H16C13.794 14.6669 12 12.8725 12 10.6669V9.33325H10.6668C9.2004 9.33325 8 10.5341 8 12.0001V33.3333C8 34.7997 9.2004 36 10.6668 36H29.3332C30.7996 36 32 34.7997 32 33.3333V12.0001C32 10.5341 30.7996 9.33325 29.3332 9.33325ZM16 21.3321H18.6652V18.6669H21.332V21.3321H24V23.9989H21.332V26.6669H18.6652V23.9989H16V21.3321ZM28 32H12V29.3333H28V32Z" fill={color} />
      <path d="M23.9992 6.6668H22.666C22.666 5.194 21.472 4 19.9992 4C18.5264 4 17.3324 5.194 17.3324 6.6668H15.9992C15.266 6.6668 14.666 7.2668 14.666 8V10.6668C14.666 11.3996 15.266 12 15.9992 12H23.9992C24.7324 12 25.3324 11.3996 25.3324 10.6668V8C25.3324 7.2668 24.7324 6.6668 23.9992 6.6668Z" fill={color} />
    </svg>
  );
}

import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CategoryStyled, ItemStyled } from "./styled";
import { Blocks } from "components";

const Category = ({ theme_standard, data, onClickItem }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [active, setActive] = useState(0);

  const handleClickItem = (key, value) => {
    setActive(key);
    onClickItem && onClickItem(value);
  };

  return (
    <CategoryStyled>
      <div className={customClass}>
        <Blocks.Box
          theme_box_no_shadow
          paddingTop="13px"
          paddingRight="13px"
          paddingBottom="13px"
          paddingLeft="13px"
        >
          <div className="cate_head">
            <div>หมวดหมู่</div>
            <div className="sub_cat_label">Categories</div>
          </div>
          {data &&
            data.map((e, i) => (
              <ItemStyled
                className={active === i ? "active" : ""}
                key={i}
                onClick={() => handleClickItem(i, e)}
              >
                <div className="item_txt_left_col">
                  <div className="item_icon">{e.icon}</div>
                  <div className="item_txt_group">
                    <div className="cate_title">{e.title}</div>
                    <div className="cate_sub_title">{e.title_en}</div>
                  </div>
                </div>
                <div className="item_txt_right_col">{e.score}</div>
              </ItemStyled>
            ))}
        </Blocks.Box>
      </div>
    </CategoryStyled>
  );
};

Category.propTypes = {};

export default Category;

import { GroupDetailStyled } from './styled';
import { Blocks, Buttons, Icons } from 'components';

const GroupDetail = ({ data, handleClickEdit, perMissions }) => {
  // const renderCategory = (e) => {
  //   switch (e) {
  //     case 'open':
  //     case 'opend':
  //       return (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <Icons.StatusPublic />
  //           <div style={{ marginLeft: '10px' }}>{`Open group`}</div>
  //         </div>
  //       );

  //     case 'closed':
  //       return (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <Icons.StatusPrivate />
  //           <div style={{ marginLeft: '10px' }}>{`Closed group`}</div>
  //         </div>
  //       );

  //     default:
  //       return (
  //         <div style={{ display: 'flex', alignItems: 'center' }}>-
  //           {/* <Icons.StatusPrivate />
  //           <div style={{ marginLeft: '10px' }}>{`Closed group`}</div> */}
  //         </div>
  //       );
  //   }
  // };

  return (
    <GroupDetailStyled
      // src="/assets/images/backgrounds/corona.png"
      src={data?.covers && data.covers.length > 0 && data.covers[0].media_uri}
    >
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="24px"
        paddingLeft="24px"
        borderRadius="8px"
      >
        <div className="article_layout">
          <div className="img_show" />
          <div className="detail_show">
            <div className="title_group">
              <div className="show_t">
                <div className="title_show">{data?.name}</div>
                <div className="show_value">
                  <div
                    className="detail_show_value"
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                  {/* <div className="status_show">
                    {renderCategory(data?.type)}
                  </div> */}
                </div>
              </div>
              {
                perMissions &&
                <div className="btn_t">
                  <Buttons.BtnNormal
                    theme_standard_btn_normal_gray
                    label="แก้ไข"
                    labelBottom="Edit"
                    svg_front={<Icons.Edit color="#FFFFFF" />}
                    onClick={handleClickEdit}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </Blocks.Box>
    </GroupDetailStyled>
  );
};

export default GroupDetail;

import styled from 'styled-components';

export const ExportRewardPointStyled = styled.div`
  .detail_show {
    .filter_download {
      margin-top: 20px;
      display: flex;
      align-items: center;
      column-gap: 30px;
      color: black;
      font-size: 20px;
      .w190 {
        width: 190px;
      }
      .label_to {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        // font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }
`;

import styled from 'styled-components';

export const LuckSpinContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manage_container {
    padding: 15px 32px 80px 32px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
    .head_wrap_layout {
      margin-bottom: 22px;
    }
    .bottom_control {
      margin-top: 32px;
    }

    .block_2_col_layout {
      display: flex;
      padding: 10px 0;
      column-gap: 16px;
      overflow: auto;
      .col_2 {
        &.flex_1 {
          flex: 1;
          padding-right: 4px;
        }
        &.w_280 {
          flex-shrink: 0;
          width: 280px;
          padding-left: 8px;
        }
      }
    }
  }
`;

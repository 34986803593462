export default function Book({
  color = "#575D96",
  width = "32",
  height = "26",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 26.745"
    >
      <g id="documentation" transform="translate(0 -42.04)">
        <path
          id="Path_12212"
          data-name="Path 12212"
          d="M31.063,93.86h-.937v16.449a2.784,2.784,0,0,1-2.747,2.813H4.623a2.784,2.784,0,0,1-2.747-2.812V93.86H.938A.938.938,0,0,0,0,94.8v19.292a.938.938,0,0,0,.937.938H12.86a3.277,3.277,0,0,0,6.28,0H31.063a.938.938,0,0,0,.937-.938V94.8A.938.938,0,0,0,31.063,93.86Z"
          transform="translate(0 -48.581)"
          fill={color}
        />
        <path
          id="Path_12213"
          data-name="Path 12213"
          d="M282.313,61.727V42.978a.907.907,0,0,0-.872-.937H271V62.665h10.44A.907.907,0,0,0,282.313,61.727Zm-3.761-2.812h-3.812a.937.937,0,0,1,0-1.875h3.812a.937.937,0,1,1,0,1.875Zm0-3.75h-3.812a.938.938,0,0,1,0-1.875h3.812a.938.938,0,1,1,0,1.875Zm.938-4.688a.938.938,0,0,1-.938.938h-3.812a.937.937,0,0,1-.937-.937v-3.75a.937.937,0,0,1,.937-.937h3.812a.938.938,0,0,1,.938.937Z"
          transform="translate(-254.063 0)"
          fill={color}
        />
        <path
          id="Path_12214"
          data-name="Path 12214"
          d="M345.83,132.04h1.938v1.875H345.83Z"
          transform="translate(-324.216 -84.375)"
          fill={color}
        />
        <path
          id="Path_12215"
          data-name="Path 12215"
          d="M60.873,62.665h10.44V42.04H60.873a.907.907,0,0,0-.872.937v18.75A.907.907,0,0,0,60.873,62.665ZM63.761,45.79h3.813a.937.937,0,0,1,0,1.875H63.761a.937.937,0,1,1,0-1.875Zm0,3.75h3.813a.937.937,0,0,1,0,1.875H63.761a.937.937,0,1,1,0-1.875Zm0,3.75h3.813a.938.938,0,0,1,0,1.875H63.761a.938.938,0,1,1,0-1.875Zm0,3.75h3.813a.937.937,0,0,1,0,1.875H63.761a.937.937,0,1,1,0-1.875Z"
          transform="translate(-56.25 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

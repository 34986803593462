import React from 'react';
import { BackOfficeContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import {
  Typographys,
  Cards,
  Icons,
  Loading,
  Modal,
  Forms,
  // Buttons,
} from 'components';
import { ROUTE_PATH } from 'utils/constants';
import { connect } from 'react-redux';

class BackOfficeContainer extends React.Component {
  state = { isLoading: true, isShowModal: false };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickMenu = (e) => {
    this.props.history.push(e);
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  onSubmit = async (values) => {};

  render() {
    const { isLoading, isShowModal } = this.state;

    return (
      <BackOfficeContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="top_title">
              <Typographys.LabelSubVertical
                theme_standard
                title="จัดการหลังบ้าน"
                subTitle="Back office"
              />
              {/* <div className="body_btn">
                <Buttons.BtnNormal
                  theme_standard_btn_pop_up_account
                  label="Download"
                  fontSize="16px"
                  backgroundColor="#575D96"
                  // onClick={handleConfirm}
                  type="submit"
                />
              </div> */}
            </div>

            <div className="card_container">
              <div
                className="card_wrap"
                onClick={() =>
                  this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_REWARD)
                }
              >
                <Cards.BoxIconTitleSub
                  title="จัดการรางวัล"
                  subTitle="Rewards Management"
                  svg={<Icons.RewardManage />}
                />
              </div>
              <div
                className="card_wrap"
                onClick={() => this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_NEW)}
              >
                <Cards.BoxIconTitleSub
                  title="จัดการข่าวสาร"
                  subTitle="News Management"
                  svg={<Icons.NewManagement />}
                />
              </div>
              <div
                className="card_wrap"
                onClick={() =>
                  this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_ACCOUNT)
                }
              >
                <Cards.BoxIconTitleSub
                  title="จัดการบัญชีผู้ดูแลระบบ"
                  subTitle="Account Management"
                  svg={<Icons.AccountManagement />}
                />
              </div>
              {/* <div
                className="card_wrap"
                // onClick={() => this.handleClickMenu(ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT)}
              >
                <Cards.BoxIconTitleSub
                  title="จัดการบัญชีผู้ใช้"
                  subTitle="Employee Management"
                  svg={<Icons.UserLogo />}
                />
              </div> */}
              <div
                className="card_wrap"
                //  onClick={() => this.handlePopup()}
                onClick={() =>
                  this.handleClickMenu(
                    ROUTE_PATH.BACK_OFFICE_DELETE_USER_ACCOUNT
                  )
                }
              >
                <Cards.BoxIconTitleSub
                  title="สาเหตุการลบบัญชี"
                  subTitle="Download Delete Account"
                  svg={<Icons.DownloadDeleteAccount />}
                />
              </div>
            </div>
            {isShowModal && (
              <Modal
                theme_modal_standard
                isShow={isShowModal}
                handleClickCloseModal={this.handleClickCloseModal}
              >
                <Forms.DownloadDeleteAccountForm
                  handleCancel={this.handleClickCloseModal}
                  onSubmit={this.onSubmit}
                />
              </Modal>
            )}
          </>
        )}
      </BackOfficeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfficeContainer);

import { TotalStepStyled } from './styled';
import { Icons } from 'components';
import millify from 'millify';

const TotalStep = ({ data }) => {
  return (
    <TotalStepStyled>
      <div className="ict_container">
        <div className="top_current">
          <div className="svg_show"><Icons.Runner2 /></div>
          <div className="label_show">
            <div className="title_txt">ก้าวสะสม</div>
            <div className="sub_title_show">Total steps</div>
          </div>
        </div>
        <div className="score_box">
          <div className="s_scroe">
            <div className='arrow'>
              <Icons.ArrowUpBig />
            </div>
            <div> 
              {data && data.total_step_count && millify(Number(data.total_step_count))}
            </div>
          </div>
          {/* <div className="s_label">sdfsdf</div> */}
        </div>
      </div>
    </TotalStepStyled>
  );
};

export default TotalStep;

import styled from 'styled-components'

export const BarPercent2Styled = styled.div`
  .theme_standard_bar_percent2 {
    .top_bar {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .line_bar {
        background: ${({ theme }) => theme.COLORS.RED_5};
        width: 78%;
        display: flex;
        border-radius: 18px;
        height: 24px;
        overflow: hidden;
        .left_bar {
          background: ${({ theme }) => theme.COLORS.RED_6};
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }
      }
      .svg {
        display: flex;
      }
    }
    .bot_bar {
      margin-top: 3px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      display: flex;
      justify-content: space-between;
      .group_label {
        display: flex;
        .highlight {
          color: ${({ theme }) => theme.COLORS.BLACK_1}50;
          margin-left: 4px;
        } 
      }
    }
  }
`

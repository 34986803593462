import React from "react";
import { RewardHistoryContainerStyled } from "./styled";
import {
  Tabs,
  Icons,
  Buttons,
  Typographys,
  Inputs,
  TableCustom,
  Loading,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import moment from "moment";
import { adminService } from "apiServices";
import { RENDER_MONTH } from "utils/functions/date";

class RewardHistoryContainer extends React.Component {
  state = {
    isLoading: true,
    yearSelect: moment().year(),
    monthSelect: moment().format("MM"),
    thisMoment: moment(),
    categoryFilter: `category_id=${1}`,
    dateMonthFilter: `end_date_after=${moment()
      .startOf("month")
      .format("YYYY-MM-DD")}&begin_date_before=${moment()
      .endOf("month")
      .format("YYYY-MM-DD")}`,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fecthRewardList("?category_id=1");
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fecthRewardList();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fecthRewardList = async (stringQuery) => {
    let res = await adminService.GET_REWARD_ITEM_LIST_COMPANY(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        dataRewardList: res,
        isLoading: false,
        thisMoment: moment(),
      });
    } else {
      this.setState({
        dataRewardList: [],
        isLoading: false,
        thisMoment: moment(),
      });
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_REWARD);
  };

  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `category_id=${e.id}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "dateMonthFilter":
        this.setState(
          {
            dateMonthFilter: `end_date_after=${moment(e)
              .startOf("month")
              .format("YYYY-MM-DD")}&begin_date_before=${moment(e)
              .endOf("month")
              .format("YYYY-MM-DD")}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "perpageFilter":
        this.setState(
          {
            perpageFilter: `offset=0&per_page=${e.value}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };
  handleJoinQuery = () => {
    const { categoryFilter, dateMonthFilter, perpageFilter } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (dateMonthFilter) {
      queryString.push(dateMonthFilter);
    }
    if (perpageFilter) {
      queryString.push(perpageFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fecthRewardList(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
    } else {
      this.fecthRewardList();
      this.setState({
        queryFilterAll: false,
      });
    }
  };
  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}${query}`);
    this.fecthRewardList(query);
  };

  render() {
    const { isLoading, dataRewardList } = this.state;
    return (
      <RewardHistoryContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="ประวัติรางวัล"
                subTitle="Reward History"
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                margin_right="19px"
                lineHeight="1.3"
                onClick={this.handleBackClick}
                svg={<Icons.Certicficate width="64px" height="52px" />}
              />
            </div>
            <div className="layer_three">
              <div className="group_line">
                <div className="left_line flex">
                  <Typographys.TopBottom
                    theme_left_align
                    label="แสดงรางวัล"
                    labelBottom="Show Rewards for"
                    fontSize="20px"
                    fontSizeLabelButtom="16px"
                  />
                  <div className="group_date">
                    <div className="box_date">
                      <Inputs.InputDatePikerCustom
                        theme_standard_date_picker_custom
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        maxDate={new Date(moment().format("YYYY-MM-DD"))}
                        onChangeCustom={(e) =>
                          this.handleSelectedFilter("dateMonthFilter", e)
                        }
                        initialValues={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group_line no_margin w69">
                <div className="left_line">
                  <Buttons.BtnCategroyFillter
                    theme_standard_category_filter
                    data={
                      this.props.rewardCategory && this.props.rewardCategory
                    }
                    onClick={(e) =>
                      this.handleSelectedFilter("categoryFilter", e)
                    }
                  />
                </div>
                <div className="right_line">
                  <Inputs.InputDropDownFilter
                    theme_mini
                    iconSvgClose={<Icons.RectArrowDown />}
                    iconSvgOpen={<Icons.RectArrowUp />}
                    placeholder="10 รายการ"
                    // onChange={(e) => this.handlChangePerPage(e)}
                    onChange={(e) =>
                      this.handleSelectedFilter("perpageFilter", e)
                    }
                    options={
                      groupCategory &&
                      groupCategory.map((e) => ({
                        value: e.value,
                        label: e.label,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="layer_four">
              <TableCustom.TableNormal
                theme_standard_table_normal_gray
                showTitle="รางวัลทั้งหมด"
                columns={columns}
                bgHeader="#575D96"
                data={dataRewardList && dataRewardList.data}
                useNo
              />
            </div>
          </>
        )}
      </RewardHistoryContainerStyled>
    );
  }
}

const groupCategory = [
  {
    label: "10 รายการ",
    value: 10,
  },
  {
    label: "20 รายการ",
    value: 20,
  },
  {
    label: "30 รายการ",
    value: 30,
  },
];

const columns = [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
    style: { textAlign: "center", width: "30px", flexShrink: 0 },
  },
  {
    title: "แบรนด์",
    field: "name",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div className="name_layer">{value}</div>,
  },
  {
    title: "ระยะเวลา",
    field: "begin_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>
        {`${moment(origin.begin_date).format("D")} ${
          RENDER_MONTH(moment(origin.begin_date).format("MM")).th
        } ${moment(origin.begin_date).year() + 543} - ${moment(
          origin.end_date
        ).format("D")} ${
          RENDER_MONTH(moment(origin.end_date).format("MM")).th
        } ${moment(origin.end_date).year() + 543}`}
      </div>
    ),
  },
  {
    title: "สิทธิ์คงเหลือ",
    field: "remain",
    headerStyle: { textAlign: "left", width: "200px", flexShrink: 0 },
    style: { textAlign: "left", width: "200px", flexShrink: 0 },
    render: ({ origin, value }) => (
      <div>
        {`${
          origin.redeemable_count - origin.redeemed_count > 0
            ? origin.redeemable_count - origin.redeemed_count
            : 0
        } สิทธิ์`}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  rewardCategory: state.rewardCategory,
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardHistoryContainer);

import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputGroupCheckBoxStyled } from './styled';
import { Blocks, Buttons, Icons } from 'components';

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

const InputGroupCheckBox = ({
  theme_standard_group_check_box,
  theme_standard_group_check_box_user,
  theme_lukcy,
  data,
  onChangeCustom,
  labelButton,
  luckyButton,
  iconDisplay,
  fontSize,
  fontFamily,
  fontColor,
  boxSize,
  topBox,
  rightBox,
  iconColor='#fff',
  maxHeight,
}) => {
  const customClass = cx({
    theme_standard_group_check_box: theme_standard_group_check_box,
    theme_standard_group_check_box_user: theme_standard_group_check_box_user,
    theme_lukcy: theme_lukcy,
  });

  const [seleted, setSelected] = useState({});
  const [disabled, setDisabled] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    if (Object.keys(seleted).length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [seleted]);

  const handleSelected = (key) => {
    let selectedTemp = { ...seleted };
    if (selectedTemp[key]) {
      if (key === 'all') {
        selectedTemp = {};
      } else {
        if (selectedTemp['all']) {
          delete selectedTemp['all'];
        }
        delete selectedTemp[key];
      }
    } else {
      if (key === 'all') {
        data.forEach((e) => {
          selectedTemp[e.value] = true;
        });
      } else {
        if (Object.keys(selectedTemp).length + 2 === data.length) {
          data.forEach((e) => {
            selectedTemp[e.value] = true;
          });
        } else {
          selectedTemp[key] = true;
        }
      }
    }
    setSelected({ ...selectedTemp });
  };

  const handleClickSelected = () => {
    setIsComponentVisible(false);
    onChangeCustom && onChangeCustom(Object.keys(seleted).map((key2) => key2));
  };

  const handleClickFilter = () => {
    setIsComponentVisible(true);
  };

  return (
    <InputGroupCheckBoxStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      boxSize={boxSize}
      topBox={topBox}
      rightBox={rightBox}
    > 
      <div className={customClass} ref={ref}>
        <div className="icon_filter" onClick={handleClickFilter}>
          {
            iconDisplay
              ?
              iconDisplay
              :
              <Icons.Control color={iconColor && iconColor} />
          }
        </div>
        <div
          className="filter_block"
          style={{ visibility: isComponentVisible ? 'visible' : 'hidden' }}
        >
          <Blocks.Box
            theme_group_check_box
            maxHeight={maxHeight}
          >
            {data &&
              data.map((e, i) => (
                <div key={i + 1} className="body_checkbox">
                  <label className="container">
                    <input
                      type="checkbox"
                      name={e.name}
                      // value={seleted[e.value]}
                      checked={seleted[e.value]}
                      onChange={() => {
                        handleSelected(e.value);
                      }}
                    />
                    <span className="checkmark"></span>
                    {e.label}
                  </label>
                </div>
              ))}
            <div className="btn_show">
              {
                luckyButton
                  ?
                  <Buttons.BtnNormal
                    theme_standard_btn_normal_pink
                    label={labelButton}
                    backgroundColor='#B6E7E8'
                    fontColor='#002366'
                    borderRadius="6px"
                    fontSize="14px"
                    disabled={disabled}
                    onClick={handleClickSelected}
                  />
                  :
                  <Buttons.BtnNormal
                    theme_standard_btn_normal_pink
                    label={labelButton}
                    backgroundColor={theme_standard_group_check_box_user && '#575D96'}
                    borderRadius="6px"
                    fontSize="14px"
                    disabled={disabled}
                    onClick={handleClickSelected}
                  />
              }
            </div>
          </Blocks.Box>
        </div>
      </div>
    </InputGroupCheckBoxStyled>
  );
};

InputGroupCheckBox.propTypes = {};

export default InputGroupCheckBox;

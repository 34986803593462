import styled from 'styled-components';

export const BMITrendStyled = styled.div`
  .bmi_trend_head_container {
    display: flex;
    justify-content: space-between;
    .bmi_trend_head_left_col {
      display: flex;
      align-items: center;
      .head_icon {
        margin-right: 12px;
        height: 32px;
      }
    }
    .bmi_trend_head_right_col {
      // min-width: 496px;
      padding-left: 10px;
      width: 50%;
    }
  }
  .box_graph {
    display: flex;
    .bmi_trend_graph {
      margin-top: 25px;
      margin-right: 20px;
      flex: 1;
      .show_graph {
        // width: calc(100% - 40px);
        position: relative;
        .show_skeloton {
          margin-bottom: 20px;
        }
        .label_empty {
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .arrow_left {
          display: flex;
          position: absolute;
          bottom: 20px;
          left: -18px;
          z-index: 2;
        }
        .arrow_right {
          display: flex;
          position: absolute;
          bottom: 20px;
          right: -34px;
          z-index: 2;
        }
      }
    }
    .label_group_show {
      min-width: 232px;
      max-width: 323px;
      flex-shrink: 0;
      padding-top: 30px;
    }
    .y_title {
      font-size: 13px;
      color: #4d5c7d;
      margin-bottom: -10px;
      margin-left: 25px;
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .box_graph {
      .bmi_trend_graph {
        .show_graph {
          .show_skeloton {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`;

import { MsdPercentOfOrgansStyled } from "./styled";
import { Blocks, Icons, Typographys } from "components";
import Chart from "react-apexcharts";
import moment from "moment";
import "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";
import theme from "styles/theme.json";

const MsdPercentOfOrgans = ({ height, data, type }) => {
  return (
    <MsdPercentOfOrgansStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="40px"
        paddingLeft="24px"
        height={`calc(${height} - 44px)`}
      >
        <div className="container">
          <div className="top_main">
            <div className="top_main_left">
              <Typographys.TopBottom
                theme_left_align
                label="สัดส่วนอวัยวะส่วนต่างๆ ที่มีอาการเจ็บ แต่ไม่มีความเสี่ยงและ PUI "
                labelBottom="Percentage of painful organs without risk and PUI risk"
              />
            </div>
            <div className="top_main_right">
              <div className="label_layout">
                <div className="show_icon">
                  <Icons.CalendarIcon />
                </div>
                <div className="show_label">วันที่รายสัปดาห์ล่าสุด</div>
              </div>
              <div className="date_layout">
                {data &&
                  moment(data.stat_date).startOf("isoWeek").format("DD") +
                  ` ` +
                  RENDER_MONTH(
                    moment(data.stat_date).startOf("isoWeek").format("MM")
                  ).thShort +
                  ` ` +
                  moment(data.stat_date).startOf("isoWeek").format("YYYY")}
                {` - `}
                {data &&
                  moment(data.stat_date).endOf("isoWeek").format("DD") +
                  ` ` +
                  RENDER_MONTH(
                    moment(data.stat_date).endOf("isoWeek").format("MM")
                  ).thShort +
                  ` ` +
                  moment(data.stat_date).endOf("isoWeek").format("YYYY")}
              </div>
            </div>
          </div>
          <div className="show_group_label">
            <div className="line_label">
              <div className="round" style={{ background: "#498BA1" }} />
              <div className="label">คอ</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#C4493F" }} />
              <div className="label">มือ นิ้วมือ</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#E66E1B" }} />
              <div className="label">ไหล่ แขน ข้อศอก</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#F0A231" }} />
              <div className="label">หลัง</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#FFCA7B" }} />
              <div className="label">เข่า</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#71A767" }} />
              <div className="label">สะโพก ต้นขา</div>
            </div>
            <div className="line_label">
              <div className="round" style={{ background: "#37735F" }} />
              <div className="label">ข้อเท้า เท้า</div>
            </div>
          </div>
          <div
            className="bottom_main"
            style={{ marginTop: type === "ddc" && "3px" }}
          >
            <div className="bottom_main_detail">
              {data &&
                (data.norisk.organ_arm.percent > 0 ||
                  data.norisk.organ_back.percent > 0 ||
                  data.norisk.organ_foot.percent > 0 ||
                  data.norisk.organ_hand.percent > 0 ||
                  data.norisk.organ_hip.percent > 0 ||
                  data.norisk.organ_knee.percent > 0 ||
                  data.norisk.organ_neck.percent > 0) ? (
                <div className="graph_show">
                  <Chart
                    type="pie"
                    width="100%"
                    height="292"
                    options={{
                      chart: {
                        id: "apexchart-example",
                        fontFamily: theme.FONT.STYLE.REGULAR,
                        toolbar: {
                          show: false,
                        },
                      },
                      dataLabels: {
                        formatter: function (val, opts) {
                          return `${Number(val).toFixed(0)}%`;
                        },
                      },
                      tooltip: {
                        y: {
                          formatter: function (
                            value,
                            { series, seriesIndex, dataPointIndex, w }
                          ) {
                            return `${value}%`;
                          },
                        },
                      },
                      legend: {
                        show: false,
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            offset: -40,
                          },
                        },
                      },
                      colors: [
                        "#37735F",
                        "#71A767",
                        "#FFCA7B", //เข่า
                        "#FFA726", //หลัง
                        "#E66E1B", //ไหล่
                        "#CF4D43",
                        "#498BA1",
                      ],
                      labels: [
                        "ข้อเท้า เท้า",
                        "สะโพก ต้นขา",
                        "เข่า",
                        "หลัง",
                        "ไหล่ แขน ข้อศอก",
                        "มือ นิ้วมือ",
                        "คอ",
                      ],
                    }}
                    series={
                      data && data.norisk
                        ? [
                          data.norisk.organ_foot.percent,
                          data.norisk.organ_hip.percent,
                          data.norisk.organ_knee.percent,
                          data.norisk.organ_back.percent,
                          data.norisk.organ_hand.percent,
                          data.norisk.organ_arm.percent,
                          data.norisk.organ_neck.percent,
                        ]
                        : []
                    }
                  />
                </div>
              ) : (
                <div className="empty_show">ยังไม่มีข้อมูล</div>
              )}

              <div className="graph_label_top">
                มีอาการแต่ยังไม่มีความเสี่ยง
              </div>
              <div className="graph_label_bottom">Pain without risk</div>
            </div>
            <div className="bottom_main_detail right">
              {data &&
                (data.pui.organ_arm.percent > 0 ||
                  data.pui.organ_back.percent > 0 ||
                  data.pui.organ_foot.percent > 0 ||
                  data.pui.organ_hand.percent > 0 ||
                  data.pui.organ_hip.percent > 0 ||
                  data.pui.organ_knee.percent > 0 ||
                  data.pui.organ_neck.percent > 0) ? (
                <div className="graph_show">
                  <Chart
                    type="pie"
                    width="100%"
                    height="292"
                    options={{
                      chart: {
                        id: "apexchart-example",
                        fontFamily: theme.FONT.STYLE.REGULAR,
                        toolbar: {
                          show: false,
                        },
                      },
                      dataLabels: {
                        formatter: function (val, opts) {
                          return `${Number(val).toFixed(0)}%`;
                        },
                      },
                      legend: {
                        show: false,
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            offset: -40,
                          },
                        },
                      },
                      tooltip: {
                        y: {
                          formatter: function (
                            value,
                            { series, seriesIndex, dataPointIndex, w }
                          ) {
                            return `${value}%`;
                          },
                        },
                      },
                      colors: [
                        "#37735F",
                        "#71A767",
                        "#FFCA7B",
                        "#FFA726",
                        "#E66E1B",
                        "#CF4D43",
                        "#498BA1",
                      ],
                      labels: [
                        "ข้อเท้า เท้า",
                        "สะโพก ต้นขา",
                        "เข่า",
                        "หลัง",
                        "ไหล่ แขน ข้อศอก",
                        "มือ นิ้วมือ",
                        "คอ",
                      ],
                    }}
                    series={
                      data && data.pui
                        ? [
                          data.pui.organ_foot.percent,
                          data.pui.organ_hip.percent,
                          data.pui.organ_knee.percent,
                          data.pui.organ_back.percent,
                          data.pui.organ_hand.percent,
                          data.pui.organ_arm.percent,
                          data.pui.organ_neck.percent,
                        ]
                        : []
                    }
                  />
                </div>
              ) : (
                <div className="empty_show">ยังไม่มีข้อมูล</div>
              )}
              <div className="graph_label_top">
                มีความเสี่ยงเข้าเกณฑ์เป็นโรค
              </div>
              <div className="graph_label_bottom">PUI risk</div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MsdPercentOfOrgansStyled>
  );
};

export default MsdPercentOfOrgans;

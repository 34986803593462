import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { InputTextMaxCharStyled } from './styled';

const InputTextMaxChar = ({
  theme_standard_text_max_char,
  theme_normal_text,
  theme_lucky,
  labelTitle,
  labelSub,
  maxLength,
  height,
  flexStyle,
  fontSize,
  fontFamily,
  fontColor,
  placeholder,
  errors,
  value,
  onChange,
  onChangeCustom,
  // onBlurCustom,
  disabled,
  ...props
}) => {
  const customClass = cx({
    theme_standard_text_max_char: theme_standard_text_max_char,
    theme_normal_text: theme_normal_text,
    theme_lucky: theme_lucky,
  });

  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(value ? value.length : 0);
  }, [value]);

  return (
    <InputTextMaxCharStyled
      height={height}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      flexStyle={flexStyle}
      disabled={disabled}
    >
      <div className={customClass}>
        <div className="title_layout">
          {labelTitle && (
            <div className="show_title">
              <div className="title_display">{labelTitle}</div>
              <div className="sub_display">{labelSub}</div>
            </div>
          )}
          {maxLength && (
            <div className="num_show">
              {`${count}/${maxLength ? maxLength : 40}`}
            </div>
          )}
        </div>
        <input
          className="text_input"
          maxLength={maxLength ? maxLength : 40}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e);
            onChangeCustom && onChangeCustom(e);
          }}
          // onBlur={(e) => onBlurCustom && onBlurCustom(e)}
          onWheel={(e) => e.target.blur()}
          value={value}
          disabled={disabled}
          {...props}
        />
        {errors && <div className="error">{`* ${errors}`}</div>}
      </div>
    </InputTextMaxCharStyled>
  );
};

InputTextMaxChar.propTypes = {};

export default InputTextMaxChar;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { TableRoleStyled } from "./styled";
import { PaginationCustom, Displays } from "components";

const TableRole = ({
  theme_standard_table_role,
  data,
  onClick,
  showPagination,
  pagination,
  handlePageClick,
  haveAdminRole,
  labelEmpty = "",
}) => {
  const customClass = cx({
    theme_standard_table_role: theme_standard_table_role,
  });

  return (
    <TableRoleStyled>
      <div className={customClass}>
        <div className="header_table">
          <div className="header_col_1">
            <div>ชื่อ - นามสกุล</div>
            <div className="sub_col_1">Name - Surname</div>
          </div>
          {haveAdminRole && (
            <div className="header_col_2">
              <div>ตำแหน่ง</div>
              <div className="sub_col_2">Role</div>
            </div>
          )}
          <div className="header_col_3">
            <div>สถานะ</div>
            <div className="sub_col_3">Status</div>
          </div>
        </div>
        {data && data.length > 0 ? (
          data.map((e, i) => (
            <div key={i + 1} className="row_table" onClick={() => onClick(e)}>
              <div
                className={`tab_color ${e.role === "company_admin" && "pink"}`}
              />
              <div className="group_row">
                <div className="row_col_1">
                  {`${e.firstname} ${e.lastname} `}
                </div>
                {haveAdminRole && (
                  <div className="row_col_2">
                    {haveAdminRole
                      ? e.admin_role.name
                      : e.role === "company_admin" ||
                        e.role === "invitrace_admin" ||
                        e.role === "ddc_admin"
                      ? "Admin"
                      : "Agent"}
                  </div>
                )}
                <div className="row_col_3">
                  <div style={{ width: "110px" }}>
                    <Displays.StatusLabel
                      label={
                        e.is_active === true
                          ? "active"
                          : e.is_active === false
                          ? "inactive"
                          : "pending"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty">{labelEmpty}</div>
        )}
      </div>
      {showPagination && pagination && (
        <div className="pagi_layout">
          <PaginationCustom
            theme_standard_pagination
            totalPages={pagination?.last_page}
            currentPageData={pagination?.current_page - 1}
            _handlePageClick={handlePageClick}
          />
        </div>
      )}
    </TableRoleStyled>
  );
};

// TableRole.propTypes = {};

export default TableRole;

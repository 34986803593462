import styled from 'styled-components';

export const ScoreBottomStyled = styled.div`
  .card_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .card_left_col {
      line-height: 20px;
      .txt_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_medium, noto_sans_bold;
      }
      .txt_sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_medium, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .card_right_col {
    }
  }
  .graph_show {
    display: flex;
    justify-content: center;
  }
  .card_score {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S38};
    font-family: noto_sans_thai_medium, noto_sans_bold;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    .iconWrap {
      margin-right: 10px;
    }
    &.up {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_standard {
  }
`;

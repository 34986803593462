import styled from "styled-components";

export const TableNormalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_table_normal {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#002366")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 10px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 40px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.BLUE_23};
          border-radius: 12px;
          padding: 6px 0;
          align-items: center;
          .no {
            margin: auto;
            width: fit-content;
          }
          .label_empty {
            padding: 25px 0;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            padding: 0 15px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_purple {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 10px 0;
        // border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
        border-radius: 8px;
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
          border-radius: 8px;
          padding: 9.2px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding: 0 13px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.BLUE_29};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_lucky {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
      .table_header {
        padding: 15.6px 0;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_24};
        // border-radius: 14px;
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.BLUE_26};
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          border-radius: 8px;
          // box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11.2px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding: 0 13px;
            width: 100%;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.PURPLE_3};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_announcement {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
      .table_header {
        // padding: 11.2px 0;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_24};
        // border-radius: 14px;
        // margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.GRAY_10};
        .header_row {
          display: flex;
          // align-items: center;
          height: 48px;
          width: 100%;
          .header_col {
            border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_24};
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            box-sizing: border-box;
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.BLUE_26};
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_24};
          border-top: none;
          // border-radius: 8px;
          // box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          // padding: 11.2px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_24};
            padding: 0px 13px;
            height: 48px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            &:first-child {
              border-left: none;
            }
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.PURPLE_3};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_white_head {
    .table_container {
      .show_title {
        padding: 7.8px 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#002366")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
      .table_header {
        padding: 11px 0;
        // border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
        // border-radius: 14px;
        // margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          // border-radius: 14px;
          // box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding: 0 13px;
            width: 100%;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.PURPLE_3};
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_normal_gray {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 11px 0;
        border-bottom: 1.5px solid ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 16px;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          // width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.BLUE_23};
          // border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREY_7};
          border-radius: 14px;
          box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
          padding: 11px 0px;
          align-items: center;
          // align-items: flex-start;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            padding: 0 13px;
            width: 100%;
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.GRAY_4}10;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_msd {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FD5E17")};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        text-align: center;
      }
      .table_header {
        // padding: 10px 0;
        border: 1px solid #e3e3e3;
        .header_row {
          display: flex;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 7px 2px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            border-right: 1px solid #e3e3e3;
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          // cursor: pointer;
          border: 1px solid #e3e3e3;
          border-top: none;
          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            padding: 8.3px 2px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: none;
            }
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_standard_table_user {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        padding: 10px 0;
        background: #fafafa;
        border: 1px solid #ececf2;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: #171717;
            // border-right: 1px solid #ECECF2;
            // &:last-child {
            //   border-right: 0px;
            // }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          padding: 10px 0;
          align-items: center;
          // align-items: flex-start;
          border: 1px solid #ececf2;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 0 15px;
            width: 100%;
            // border-right: 1px solid #ECECF2;
            // &:last-child {
            //   border-right: 0px;
            // }
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_reward {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid #b5b5b5;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 10px 10px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: #171717;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          // background: ${({ theme }) => theme.COLORS.ORANGE_7}30;
          // padding: 10px 0;
          align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid #b5b5b5;
          border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 10px 10px;
            min-height: 40px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
  .theme_department {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid #b5b5b5;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 10px 10px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: #171717;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.PURPLE_5};
          // padding: 10px 0;
          align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid #b5b5b5;
          border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 10px 10px;
            min-height: 40px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_log {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid #b5b5b5;
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 10px 10px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            color: #171717;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          // padding: 10px 0;
          align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid #b5b5b5;
          border-right: 1px solid #b5b5b5;
          border-bottom: 1px solid #b5b5b5;

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_6};
            padding: 10px 10px;
            min-height: 40px;
            width: 100%;
            border-right: 1px solid #e3e3e3;
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_health_check {
    .table_container {
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#FAFAFA")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        // padding: 10px 0;
        background: #fafafa;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 8px 8px;
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
            background: ${({ theme }) => theme.COLORS.GRAY_10};
            &:last-child {
              border-right: 0px;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 50px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          // margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          // padding: 10px 0;
          // align-items: center;
          // align-items: flex-start;
          // border: 1px solid #B5B5B5;
          border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};

          .no {
            margin: auto;
            width: fit-content;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            padding: 0px 8px;
            // height: 40px;
            width: 100%;
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
            &:last-child {
              border-right: 0px;
            }
          }
          .checkbox_layout {
            display: flex;
            justify-content: center;
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
          &.select {
            background: #ececf2;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }

  .theme_health_check_ranking {
    /* overflow: auto; */
    .table_container {
      min-width: 700px;
      .show_title {
        padding: 10px;
        background: ${({ bgHeader }) => (bgHeader ? bgHeader : "#002366")};
        border-radius: 8px;
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .table_header {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.GRAY_10};
        border-radius: 10px;

        .header_row {
          display: flex;
          align-items: center;
          width: 100%;
          .header_col {
            width: 100%;
            padding: 0 13px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_7};
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
            padding: 15px 0;
            &:last-child {
              border-right: none;
            }
            &:first-child {
              border-left: none;
            }
            .checkbox_layout {
              display: flex;
              justify-content: center;
              width: 20px;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          padding: 40px 0;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_1};
        }
        .body_row {
          display: flex;
          width: 100%;
          cursor: pointer;
          margin-bottom: 8px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};

          border-radius: 12px;
          /* padding: 6px 0; */
          align-items: center;
          .no {
            margin: auto;
            width: fit-content;
          }
          .label_empty {
            padding: 25px 0;
          }
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            /* padding: 0 15px; */
            width: 100%;
            border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};

            &:last-child {
              border-right: none;
            }
          }

          &:hover {
            background: ${({ theme }) => theme.COLORS.BLUE_1}30;
          }
        }
      }
      .pagi_layout {
        margin-top: ${({ marginTopPagination }) =>
          marginTopPagination ? marginTopPagination : "32px"};
      }
    }
  }
`;

import styled from "styled-components";

export const MaleFemaleAverageAgeStyled = styled.div`
  .top_age {
    display: flex;
    // flex-wrap: wrap;
    // overflow: auto;
    margin-bottom: 27px;
    align-items: center;
    row-gap: 16px;
    column-gap: 8px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 6px;
        display: flex;
      }
    }
    .right_men_layout {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .group_gender {
        display: flex;
        .box_show {
          margin-right: 33px;
        }
      }
      .group_date {
        margin-left: 40px;
        .box_date {
          width: 200px;
        }
      }
    }
  }
  .bottom_age {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    .body_skeleton {
      width: 100%;
    }
    .graph_show {
      flex: 1;
      min-width: 800px;
    }
    .group_label_layout {
      align-items: baseline;
      padding-top: 30px;
      padding-bottom: 50px;
      margin-left: -15px;
      .good_item {
        padding-left: 10px;
        height: 57.07px;
        display: flex;
        align-items: center;
        background: #fffaf7;
      }
      .pool_item {
        padding-left: 10px;
        height: 57.07px;
        display: flex;
        align-items: center;
        background: #f7fafa;
      }
      .depress_item {
        padding-left: 10px;
        height: 171.21px;
        display: flex;
        align-items: center;
        background: #f4f5f7;
      }
    }
    .label_empty {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      width: 100%;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.IPAD}) {
    .bottom_age {
      .graph_show {
        min-width: 525px;
      }
    }
  }
`;

import styled from 'styled-components'

export const InputBrowseFileStyled = styled.div`
  .theme_standard_browse {
    .group_upload {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .file_location {
        width: 77%;
        .location {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color:  #4A4949;
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          width: 100%;
          border: 1px solid #575D962E;
          padding: 0px 24px;
          height: 48px;
          display: flex;
          align-items: center;
          background: #F4F4F4;
          box-sizing: border-box;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: ${({ theme }) => theme.COLORS.GRAY_3};
          }
          .group_name {
            position: relative;
            .close {
              position: absolute;
              top: -5px;
              right: -18px;
              cursor: pointer;
            }
          }
        }
      }
      .btn_browse {
        width: 20%;
      }
    }
    .notic {
      margin-top: 8px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: #4A49499C;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }
`

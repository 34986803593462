import styled from 'styled-components';

export const RewardManagementCreateEditContainerStyled = styled.div`
  padding: 40px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .layer_one {
        display: flex;
    }
    .layer_two {
      margin-top: 17px;
    }
`;

import styled from "styled-components";

export const MainDashboardContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title_layout {
    margin-bottom: 19px;
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
  .block_4_col_layout {
    display: flex;
    overflow: auto;
    padding: 10px 0px 20px 0px;
    margin-bottom: 4px;
    column-gap: 8px;
    row-gap: 8px;
    padding-left: 5px;
    margin: 0 -5px;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #cccccc;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #c2c2c2;
    }
    ::-webkit-scrollbar-track {
      background: #ebebeb;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #e6e6e6;
    }
    ::-webkit-scrollbar-track:active {
      background: #ebebeb;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .col_4 {
      flex: 1;
      min-width: 264px;
      .wrap_mr5_overflow {
        margin-right: 5px;
      }
    }
  }
  .block_2_col_layout {
    display: flex;
    padding: 10px 0px 30px 5px;
    column-gap: 16px;
    row-gap: 16px;
    margin: 0 -5px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #cccccc;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cccccc;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #c2c2c2;
    }
    ::-webkit-scrollbar-track {
      background: #ebebeb;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #e6e6e6;
    }
    ::-webkit-scrollbar-track:active {
      background: #ebebeb;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .col_2 {
      &.flex_1 {
        flex: 1;
      }
      &.w_384 {
        flex-shrink: 0;
        min-width: 384px;
      }
      &.mw_660 {
        min-width: 660px;
      }
      &.mr_5 {
        margin-right: 5px;
      }
      .wrap_mr5_overflow {
        margin-right: 5px;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { AddArticleFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Icons, Typographys, Buttons } from "components";
import theme from "styles/theme.json";

const AddArticleForm = ({
  initialValues,
  goalData,
  companyID,
  onSubmit,
  disabled,
  handleBack,
  handleUpload,
  handleRemove,
  initialValuesImage,
  loadOnlyImage,
  handleCopy,
  apiGenerateLink,
  // initialValuesImgMaxLength,
}) => {
  const schema = yup.object().shape({
    // category: yup.object().nullable().required('Category is required'),
    // username: yup.string().nullable().required('Username is required'),
    // password: yup.string().nullable().required('Password is required'),

    default_coin_amount: yup
      .number()
      .nullable()
      .typeError("Amount must be a number")
      .required("Health Point Requirement is required")
      .min(1, "More than 1")
      .max(99999, "Less than 99,999"),
  });

  const [_countMaxFilePhoto, _setCountMaxFilePhoto] = useState(0);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (loadOnlyImage) {
      if (initialValuesImage) {
        let params = {
          ...watchAllFields,
          file_img: initialValuesImage,
        };
        reset(params);
      }
    } else {
      if (initialValues) {
        reset({
          ...initialValues,
          is_draft: initialValues.is_draft
            ? statusOptions[0]
            : statusOptions[1],
          type: typeOptions.find((e) => e.value === initialValues.type),
          reward_select: rewardOptions[0],
          category: categoryOptions.find(
            (e) => e.value === initialValues.category
          ),
          files:
            initialValues.type === "audio"
              ? initialValues.audios
              : initialValues.type === "video"
              ? initialValues.videos
              : [],
          file_cover: initialValues.covers,
          file_feature: initialValues.feature ? [initialValues.feature] : [],
          file_img:
            initialValuesImage && initialValuesImage ? initialValuesImage : [],
        });
      } else {
        // let params = {
        //   file_img:
        //     initialValuesImage && initialValuesImage.file_img
        //       ? initialValuesImage.file_img
        //       : [],
        // };
        // reset(params);
      }
    }
  }, [initialValues, initialValuesImage]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFile = (key) => {
    switch (key?.value) {
      case "audio":
        return (
          <>
            <div className="line_label">
              <div className="label_1">ไฟล์เสียง</div>
              <div className="label_2">Audio file</div>
            </div>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputBrowse
                  theme_standard_browse
                  placeholder="Document File Location"
                  maxMBFileSizeWarning="5"
                  acceptType="audio"
                  {...field}
                  svg={<Icons.Delete />}
                  theme_left_right={true}
                />
              )}
              name="files"
              defaultValue={[]}
            />
          </>
        );
      case "video":
        return (
          <>
            <div className="line_label">
              <div className="label_1">ไฟล์วิดีโอ</div>
              <div className="label_2">Video file</div>
            </div>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputBrowse
                  theme_standard_browse
                  placeholder="Document File Location"
                  acceptType="video"
                  maxMBFileSizeWarning="20"
                  {...field}
                  // svg={<Icons.Delete />}
                  theme_left_right={true}
                />
              )}
              name="files"
              defaultValue={[]}
            />
          </>
        );
      default:
        break;
    }
  };

  const statusOptions = [
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Lock />
          </div>
          <div>ไม่เผยแพร่</div>
        </div>
      ),
      value: "true",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Gobal />
          </div>
          <div>สาธารณะ</div>
        </div>
      ),
      value: "false",
    },
  ];

  const typeOptions = [
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Book width="24" height="24" />
          </div>
          <div>บทความ</div>
        </div>
      ),
      value: "article",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.TypeSound width="24" height="24" />
          </div>
          <div>เสียง</div>
        </div>
      ),
      value: "audio",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.TypeVideo width="24" height="24" />
          </div>
          <div>วิดีโอ</div>
        </div>
      ),
      value: "video",
    },
  ];

  const categoryOptions = [
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.New width="24" height="24" />
          </div>
          <div>สุขภาพรวม</div>
        </div>
      ),
      value: "health",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Nutrition width="24" height="24" />
          </div>
          <div>คลายเครียด</div>
        </div>
      ),
      value: "relax",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Exercise width="24" height="24" />
          </div>
          <div>ออกกำลังกาย</div>
        </div>
      ),
      value: "exercise",
    },
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.All width="24" height="24" />
          </div>
          <div>อื่นๆ</div>
        </div>
      ),
      value: "other",
    },
  ];

  const rewardOptions = [
    {
      label: (
        <div style={{ display: "flex", columnGap: "12px" }}>
          <div style={{ display: "flex" }}>
            <Icons.Dumbel2 width="24" height="24" />
          </div>
          <div>คะแนนสุขภาพ</div>
        </div>
      ),
      value: "nuti",
    },
  ];

  const handleCountFile = (e) => {
    _setCountMaxFilePhoto(e);
  };

  return (
    <AddArticleFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="layer_one">
          <div className={`head_wrap_layout`}>
            <div className="h_33">
              <Buttons.BtnLink
                theme_standard_btn_link
                svg={<Icons.ArrowLeft />}
                label="ย้อนกลับ"
                onClick={handleBack}
              />
            </div>
            <div className="h_33 center">
              <div className="text_c">
                {initialValues ? "แก้ไขบทความ" : "สร้างบทความใหม่"}
              </div>
              <div className="g_h_text">
                {initialValues ? "Edit Article" : "New Article"}
              </div>
            </div>
            <div className="h_33 flex">
              <div className="bw140">
                <Buttons.BtnNormal
                  theme_standard_btn_log_in
                  backgroundColor={theme.COLORS.BLUE_21}
                  type="submit"
                  label={initialValues ? "บันทึก" : "บันทึก"}
                  labelBottom={initialValues ? "Save Edit" : "Create"}
                  svg_front={
                    <Icons.Disk color={disabled ? "#ACACAC" : "#002366"} />
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          {/* <Tabs.HeadTab
            theme_standard_head_tab
            title={initialValues ? "แก้ไขบทความ" : "สร้างบทความใหม่"}
            subTitle={initialValues ? "Edit article" : "New article"}
            fontSize="34px"
            fontSizeLabelButtom="18px"
            fontColor="#092945"
            colorBottom="#9E9E9E"
            lineHeight="1.2"
            typeButton
            type="submit"
            labelButtonTop={initialValues && "บันทึก"}
            labelButtonBottom={initialValues && "Save"}
            disabled={disabled}
          /> */}
        </div>
        <div className="layer_two">
          <div className="left_layer_two">
            <div className="top_form">
              <div className="group_top_form">
                <div className="line_label">
                  <div className="label_1">สถานะ</div>
                  <div className="label_2">Status</div>
                </div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputDropDownNormal
                      theme_content
                      iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                      iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                      options={
                        companyID === 0 ? [statusOptions[1]] : statusOptions
                      }
                      {...field}
                    />
                  )}
                  name="is_draft"
                  defaultValue={
                    companyID === 0 ? statusOptions[1] : statusOptions[0]
                  }
                />
              </div>
            </div>
            <div className="mid_form">
              <div className="line_mid">
                <div className="mid_left">
                  <div className="group_mid_form">
                    <div className="line_label">
                      <div className="label_1">ประเภท</div>
                      <div className="label_2">Type</div>
                    </div>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDropDownNormal
                          theme_content
                          iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                          iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                          options={typeOptions}
                          {...field}
                        />
                      )}
                      name="type"
                      defaultValue={typeOptions[0]}
                    />
                  </div>
                </div>
                <div className="mid_right">
                  <div className="group_mid_form_right">
                    {renderFile(watchAllFields?.type)}
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom_form">
              <div className="bottom_left">
                <div className="group_bottom_form mb10">
                  <div className="line_label">
                    <div className="label_1">หมวด</div>
                    <div className="label_2">Category</div>
                  </div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDropDownNormal
                        theme_content
                        iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                        iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                        options={categoryOptions}
                        {...field}
                      />
                    )}
                    name="category"
                    defaultValue={categoryOptions[0]}
                  />
                </div>
              </div>
            </div>
            <div className="bottom_form">
              <div className="bottom_left">
                <div className="reward_opt">
                  <div className="show_reward_layer">
                    <div className="title_show">
                      <div className="show_icon">
                        <Icons.Reward5 />
                      </div>
                      <Typographys.TopBottom
                        theme_left_align
                        label="ของรางวัล"
                        labelBottom="Reward"
                        fontSizeLabelButtom="16px"
                        fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                      />
                    </div>
                    <div className="box_input_bottom">
                      <div className="left_box">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputDropDownNormal
                              theme_content
                              iconSvgClose={
                                <Icons.RectArrowDown color="#002366" />
                              }
                              iconSvgOpen={
                                <Icons.RectArrowUp color="#002366" />
                              }
                              options={rewardOptions}
                              {...field}
                            />
                          )}
                          name="reward_select"
                          defaultValue={rewardOptions[0]}
                        />
                      </div>
                      <div className="right_box">
                        <div className="body_input">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Inputs.InputText
                                theme_admin
                                type="number"
                                placeholder="ใส่คะแนน"
                                min="1"
                                max="99999"
                                {...field}
                                errors={errors.default_coin_amount?.message}
                              />
                            )}
                            name="default_coin_amount"
                            defaultValue={goalData}
                          />
                        </div>
                        <div className="label_b">แต้ม</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="zone_upload">
              <div className="header_zone">
                <div className="label_1">ภาพปกบทความ</div>
                <div className="label_2">Cover photo</div>
              </div>
              <div className="upload_layer">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadSetOneImage
                      theme_upload_set_image
                      acceptType="cover"
                      maxMBFileSizeWarning="1"
                      width="100%"
                      {...field}
                      svg={<Icons.Delete2 />}
                      theme_left_right={false}
                    />
                  )}
                  name="file_feature"
                  defaultValue={[]}
                />
              </div>
            </div>
            <div className="zone_upload">
              <div className="header_zone">
                <div className="label_1">ภาพประกอบบทความ</div>
                <div className="label_2">Cover photo</div>
              </div>
              <div className="upload_layer">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadSetImage
                      theme_upload_set_image
                      acceptType="cover"
                      maxMBFileSizeWarning="1"
                      width="100%"
                      maxLength={5}
                      {...field}
                      svg={<Icons.Delete2 />}
                      theme_left_right={false}
                    />
                  )}
                  name="file_cover"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="right_layer_two">
            <div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextArea
                    theme_normal
                    labelTitle="หัวข้อ"
                    labelSub="Topic"
                    maxLength={120}
                    height="48px"
                    padding="11.6px 16px"
                    placeholder="หัวข้อ"
                    overflow="hidden"
                    // svg={<Icons.Pencil1 />}
                    {...field}
                  />
                )}
                name="topic"
                defaultValue=""
              />
            </div>
            <div className="right_text_rich">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextRich
                    theme_normal
                    labelTitle="บทความ"
                    labelSub="Content"
                    fontSize="18px"
                    maxLength={5000}
                    placeholder="บทความ"
                    // svg={<Icons.Pencil2 />}
                    height="276px"
                    {...field}
                  />
                )}
                name="body"
                defaultValue=""
              />
            </div>
            <div className="more_photo">
              <div className="box_detail">
                <div className="g_text">
                  <div>ภาพรายละเอียด</div>
                  <div className="sub_g_text">Details Photo</div>
                </div>
                <div className="g_max_group">
                  <div>
                    อัพโหลดภาพเพื่อนำลิงค์ url ไปใส่ในกล่องจัดการบทความ
                    (เพิ่มได้สูงสุด 5 ภาพ)
                  </div>
                  <div className="sub_g_text">
                    {`${_countMaxFilePhoto}/5`}

                    {/* {`${
                      watchAllFields?.file_img
                        ? watchAllFields.file_img.length
                        : "0"
                    }/5`} */}
                  </div>
                </div>
                <div className="sub_more_detail">
                  <div className="ic_more">
                    <Icons.Info />
                  </div>
                  <div className="sub_more_detail">
                    กรณีมีจำนวน 5 ภาพ เเละ ต้องการเพิ่ม กรุณาลบภาพเดิม
                    โดยภาพใหม่จะไปเเทนภาพแรก (ซ้ายมือบนสุด)
                    ที่อัพโหลดไว้ก่อนหน้า
                  </div>
                </div>
              </div>
              <div className="render_photo_box">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadSetImageMaxLength
                      theme_upload_set_image
                      acceptType="cover"
                      maxMBFileSizeWarning="5"
                      widthImageSize={400}
                      heighImageSize={300}
                      labelTop="ภาพรายละเอียด"
                      labelBottom="details Photo"
                      showHeadDetail
                      onChangeCountFile={handleCountFile}
                      showCopyLink
                      width="300px"
                      maxLength={5}
                      desc_text="ไฟล์ : .jpg/jpeg/png Ratio : 4:3  Resolution : 400 x300"
                      desc_text2="ขนาดไฟล์สูงสุดไม่เกิน : 5 MB"
                      {...field}
                      svg={<Icons.Delete2 />}
                      theme_left_right={false}
                      onChangeCustom={handleUpload && handleUpload}
                      handleCopy={handleCopy && handleCopy}
                      apiGenerateLink={apiGenerateLink && apiGenerateLink}
                      handleRemove={handleRemove && handleRemove}
                      // errors={errors.file_img?.message}
                    />
                  )}
                  name="file_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </AddArticleFormStyled>
  );
};

AddArticleForm.propTypes = {};

export default AddArticleForm;

export default function ArrowDown({ width = '13.714', height = '16' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.714 16"
    >
      <path
        id="fi-sr-arrow-small-up"
        d="M6.335,11.719a1.143,1.143,0,0,0,1.616,0l3.763-3.762.008,11.9a1.143,1.143,0,0,0,2.286,0L14,7.955l3.763,3.763A1.143,1.143,0,1,0,19.38,10.1L15.282,6a3.429,3.429,0,0,0-4.849,0l-4.1,4.1A1.143,1.143,0,0,0,6.335,11.719Z"
        transform="translate(19.714 21) rotate(180)"
      />
    </svg>
  );
}

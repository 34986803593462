import React from "react";
import { connect } from "react-redux";
import { LoginContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Forms } from "components";
import { adminService } from "apiServices";
// import { toast } from 'react-toastify';
import {
  setReduxAuthen,
  setReduxCompanyList,
  setReduxCompanySelected,
} from "store/actions";
import { ROUTE_PATH } from "utils/constants";
import { toast } from "react-toastify";
import { renderErrorMessage } from "utils/functions/renderErrorMessage";

class LoginContainer extends React.Component {
  state = { btnDisabled: false };

  componentDidMount() {
    scrollToTopPageFN();
  }

  onSubmit = async (values) => {
    this.setState({
      btnDisabled: true,
    });
    let params = {
      ...values,
    };
    let res = await adminService.POST_LOGIN(params);
    if (res && res.success) {
      localStorage.setItem("token", res.token);
      this.fetchProfile();
      toast.success("Login success");
    } else {
      this.setState({
        btnDisabled: false,
      });
      if (res.error_code === "ADMIN_PASSWORD_EXPIRED") {
        this.handlePasswordExpired(values);
      } else {
        let txtErr = renderErrorMessage(res.error_code, res.error);
        toast.error(txtErr);
        this.setState({
          isBgLoading: false,
        });
      }
    }
  };

  handlePasswordExpired = (value) => {
    localStorage.clear();
    this.props.history.push(
      ROUTE_PATH.PASSWORD_EXPIRED + `?username=${value.username}`
    );
  };

  fetchProfile = async () => {
    let res = await adminService.GET_PROFILE_DETAIL();

    if (res && res.success) {
      this.props.setReduxAuthen(res.data);
      if (res.data.role === "invitrace_admin") {
        this.props.setReduxCompanyList(false);
        this.props.setReduxCompanySelected({
          id: 0,
          name: "",
          realm: res?.data?.company?.realm,
        });
        this.props.history.push(ROUTE_PATH.ADMINISTARTOR);
      } else {
        this.fetchCompaniesData();
      }
    }
  };

  fetchCompaniesData = async () => {
    const { authenRedux } = this.props;

    let res = await adminService.GET_COMPANY_LIST();
    if (res && res.success) {
      this.props.setReduxCompanyList(res.data);
      if (
        authenRedux?.admin_role?.name !== "Management" &&
        authenRedux?.admin_role?.name !== "Article Admin"
      ) {
        this.props.setReduxCompanySelected({
          id: res.data[0].id,
          name: res.data[0].name,
          isParent: res.data[0].is_parent,
          realm: res.data[0]?.realm,
        });
      } else {
        this.props.setReduxCompanySelected({
          id: res.data[0].id,
          name: res.data[0].name,
          isParent: 0,
          realm: res.data[0]?.realm,
        });
      }

      this.props.history.replace(ROUTE_PATH.ADMINISTARTOR);
    }
  };

  handleForgotPassword = () => {
    localStorage.clear();
    this.props.history.push(ROUTE_PATH.FORGOT_PASSWORD);
  };

  render() {
    return (
      <LoginContainerStyled>
        <div className="logo_show">
          <img
            className="img_logo"
            src={"/assets/images/logo/logo_login.svg"}
            alt="img"
          />
        </div>
        <div className="form_layout">
          <Forms.LoginForm
            handleForgotPassword={this.handleForgotPassword}
            onSubmit={this.onSubmit}
            btnDisabled={this?.state?.btnDisabled}
          />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxCompanyList: (data) => dispatch(setReduxCompanyList(data)),
  setReduxCompanySelected: (data) => dispatch(setReduxCompanySelected(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

import styled from "styled-components";

export const UserAccountManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box_content_layout {
    display: flex;
    min-height: calc(100vh + 16px);
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
    .manage_container {
      padding: 40px;
      padding-bottom: 65px;
      flex: 1;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      border-radius: 8px;
      .head_wrap_layout {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .h_33 {
          width: 33%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S32};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          &.center {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          &.flex {
            display: flex;
            column-gap: 10px;
            justify-content: flex-end;
            flex-wrap: wrap;
            row-gap: 10px;
          }
          .text_c {
            text-align: center;
            white-space: nowrap;
          }
          .g_h_text {
            text-align: center;
            font-size: ${({ theme }) => theme.FONT.SIZE.S24};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
          }
          .bw134 {
            width: 134px;
          }
          .bw132 {
            width: 132px;
          }
          .bw200 {
            width: 200px;
          }
        }
      }
      .filter_content_zone {
        margin-bottom: 24px;
        flex-wrap: wrap;
        row-gap: 10px;
        display: flex;
        column-gap: 10px;
        align-items: flex-end;
        justify-content: space-between;
        .group_f_left {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
          flex: 1;
          column-gap: 20px;
          .w280 {
            width: 280px;
            .lbl_t {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
              color: ${({ theme }) => theme.COLORS.GRAY_23};
            }
          }
          .w200 {
            width: 200px;
          }
          .w83 {
            width: 83px;
          }
        }
        .group_f_right {
          display: flex;
          row-gap: 10px;
          column-gap: 10px;
          .b132 {
            width: 132px;
          }
          .bw41 {
            width: 41px;
          }
          .bw98 {
            width: 98px;
          }
          .righw200 {
            width: 200px;
          }
        }
      }
      .layer_one {
        display: flex;
      }
      .layer_two {
        margin-top: 45px;
        .tab_show {
          margin-bottom: 30px;
        }
        .button_show {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          .search_layer {
            width: 50%;
          }
          .btn {
            width: 15%;
          }
        }
        .table_show {
        }
      }
    }
  }
`;

import React from 'react'
import cx from 'classnames'
import { PersonPercentStyled } from './styled'


const PersonPercent = ({ theme_standard_person_percent, color, label3 }) => {
  const customClass = cx({
    theme_standard_person_percent: theme_standard_person_percent,
  })
  return (
    <PersonPercentStyled color={color}>
      <div className={customClass}>
        <div className='left_layout_person_percent'>
        
        </div>
        <div className='right_layout_person_percent'>
          <div className='label_bottom'>
            {label3}
          </div>
        </div>
      </div>
    </PersonPercentStyled>
  )
}

// PersonPercent.propTypes = {}

export default PersonPercent

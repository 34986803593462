import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxStyled } from "./styled";

const Box = ({
  theme_standard_box,
  theme_box_no_shadow,
  theme_standard_box_white,
  theme_group_check_box,
  theme_standard_box_weeklypopular,
  theme_standard_box_over_flow,
  theme_gray_box,
  children,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  borderRadius,
  height,
  maxHeight,
  overflowX,
}) => {
  const customClass = cx({
    theme_standard_box: theme_standard_box,
    theme_box_no_shadow: theme_box_no_shadow,
    theme_standard_box_white: theme_standard_box_white,
    theme_gray_box: theme_gray_box,
    theme_group_check_box: theme_group_check_box,
    theme_standard_box_weeklypopular: theme_standard_box_weeklypopular,
    theme_standard_box_over_flow: theme_standard_box_over_flow,
  });
  return (
    <BoxStyled
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      borderRadius={borderRadius}
      height={height}
      maxHeight={maxHeight}
      overflowX={overflowX}
    >
      <div className={customClass}>{children}</div>
    </BoxStyled>
  );
};

Box.propTypes = {};

export default Box;

import styled from 'styled-components';

export const MissionDetailStyled = styled.div`
  .container {
    .top_main {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 31px;
      column-gap: 15px;
      .top_main_left {
        width: 150px;
        flex-shrink: 0;
      }
      .top_main_right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .date_layout {
          position: relative;
          .date_position {
          }
        }
      }
    }
    .bottom_main {
      display: flex;
      flex-wrap: wrap;
      .bottom_main_left {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        width: 122px;
        margin-left: auto;
        margin-right: auto;
        .img_icon {
          // width: 122px;
          width: auto;
          height: auto;
        }
        // &.move {
        //   .img_icon {
        //     width: 72px;
        //     height: auto;
        //   }
        // }
        // &.rest {
        //   align-items: center;
        //   padding-bottom: 46px;
        //   .img_icon {
        //     width: 87px;
        //     height: auto;
        //   }
        // }
      }
      .bottom_main_right {
        flex: 1;
        min-width: 388px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .group_label {
          width: 100%;
          // margin-bottom: 12px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S23};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          display: flex;
          justify-content: space-between;
          align-items: center; 
          .extra_text {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: noto_sans_thai_bold, noto_sans_bold;
            color: ${({ theme }) => theme.COLORS.BLUE_27};
          }
        }
      }
    }
  }
`;

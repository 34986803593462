export default function Kid() {
  return (
    <svg id="viewers" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <path id="Path_20369" data-name="Path 20369" d="M3.341,31.07c-.327-.491,1.062-1.725.778-2.245-.179-.328-2.042.066-2.2-.273h0A19.753,19.753,0,0,1,.77,25.512c-.294-1.028.04-2.264-.086-3.352-.042-.363-.623-.554-.645-.922Q0,20.624,0,20a20,20,0,0,1,39.612-3.935c.254,1.272-1.163,2.588-1.163,3.935a20.048,20.048,0,0,1-.181,3.352,15.217,15.217,0,0,1-.34,1.914c-.217.76-.627.918-.929,1.638-.282.673.851,2.156.5,2.788" transform="translate(0)" fill="#e8eeff" />
      <path id="Path_20370" data-name="Path 20370" d="M111.223,329.36v8.121a19.961,19.961,0,0,1-2.33,1.762l-.044.029c-.49.316-.995,1.795-1.513,2.068q-.735.387-1.5.713c-.509.216-1.028-.886-1.557-.713-1.963.644-4.06-.367-6.238-.367-.463,0-.921,1.67-1.376,1.639a20.028,20.028,0,0,1-2.391-.17l-.044-.009c-.512-.1-1.016.617-1.513.48s-1.012-.3-1.5-.48c-.53-.192-.918-2.1-1.426-2.339-1.834-.848-3.65-.911-5.134-2.248V329.36Z" transform="translate(-78.037 -303.629)" fill="#132866" />
      <path id="Path_20371" data-name="Path 20371" d="M87.083,162.212H82.946a4.491,4.491,0,0,0-4.491,4.491v7.809H91.574V166.7A4.491,4.491,0,0,0,87.083,162.212Z" transform="translate(-72.326 -149.539)" fill="#ef3d54" />
      <path id="Path_20372" data-name="Path 20372" d="M84.459,162.212a4.491,4.491,0,0,0-4.491,4.491v7.809H78.454V166.7a4.491,4.491,0,0,1,4.491-4.491Z" transform="translate(-72.325 -149.539)" fill="#ca0051" />
      <path id="Path_20373" data-name="Path 20373" d="M274.256,162.212h-4.137a4.491,4.491,0,0,0-4.491,4.491v7.809h13.119V166.7A4.491,4.491,0,0,0,274.256,162.212Z" transform="translate(-244.876 -149.539)" fill="#ef3d54" />
      <path id="Path_20374" data-name="Path 20374" d="M271.632,162.212a4.491,4.491,0,0,0-4.491,4.491v7.809h-1.513V166.7a4.491,4.491,0,0,1,4.491-4.491Z" transform="translate(-244.875 -149.539)" fill="#ca0051" />
      <path id="Path_20375" data-name="Path 20375" d="M104.958,199.578H101.8A3.425,3.425,0,0,0,98.378,203v5.956h10.005V203A3.425,3.425,0,0,0,104.958,199.578Z" transform="translate(-90.692 -183.986)" fill="#51aaf7" />
      <path id="Path_20376" data-name="Path 20376" d="M104.194,199.582a3.424,3.424,0,0,0-3.425,3.425v5.955H98.378v-5.955a3.425,3.425,0,0,1,3.425-3.425Z" transform="translate(-90.692 -183.99)" fill="#378ae5" />
      <path id="Path_20377" data-name="Path 20377" d="M86.491,7.24H83.176a4.917,4.917,0,0,0-4.917,4.917v5H91.408v-5A4.917,4.917,0,0,0,86.491,7.24Z" transform="translate(-72.145 -6.674)" fill="#2a428b" />
      <path id="Path_20378" data-name="Path 20378" d="M86.062,7.24a4.917,4.917,0,0,0-4.917,4.917v5H78.259v-5A4.917,4.917,0,0,1,83.176,7.24h2.885Z" transform="translate(-72.145 -6.674)" fill="#132866" />
      <path id="Path_20379" data-name="Path 20379" d="M141.433,179.059v-2.48h3.278v2.48a.86.86,0,0,1-.354.7,2.183,2.183,0,0,1-2.571,0h0A.86.86,0,0,1,141.433,179.059Z" transform="translate(-130.384 -162.784)" fill="#f1c0a6" />
      <path id="Path_20380" data-name="Path 20380" d="M142.612,180.125a2.185,2.185,0,0,1-.824-.37h0a.861.861,0,0,1-.353-.7v-2.48h1.145v3.238A1.428,1.428,0,0,0,142.612,180.125Z" transform="translate(-130.384 -162.785)" fill="#e6ac94" />
      <path id="Path_20381" data-name="Path 20381" d="M68.861,116.355a1.509,1.509,0,1,1-1.268-1.49A1.51,1.51,0,0,1,68.861,116.355Z" transform="translate(-60.698 -105.874)" fill="#f1c0a6" />
      <path id="Path_20382" data-name="Path 20382" d="M87.162,116.584a1.51,1.51,0,0,1-1.268,1.49,5.642,5.642,0,0,1,0-2.98A1.51,1.51,0,0,1,87.162,116.584Z" transform="translate(-78.999 -106.102)" fill="#e6ac94" />
      <circle id="Ellipse_1721" data-name="Ellipse 1721" cx="0.539" cy="0.539" r="0.539" transform="translate(6.114 9.942)" fill="#e6ac94" />
      <path id="Path_20383" data-name="Path 20383" d="M220.343,116.355a1.509,1.509,0,1,0,1.268-1.49A1.51,1.51,0,0,0,220.343,116.355Z" transform="translate(-203.129 -105.874)" fill="#f1c0a6" />
      <path id="Path_20384" data-name="Path 20384" d="M220.343,116.584a1.51,1.51,0,0,0,1.268,1.49,5.643,5.643,0,0,0,0-2.98A1.51,1.51,0,0,0,220.343,116.584Z" transform="translate(-203.129 -106.102)" fill="#e6ac94" />
      <circle id="Ellipse_1722" data-name="Ellipse 1722" cx="0.539" cy="0.539" r="0.539" transform="translate(18.184 9.942)" fill="#e6ac94" />
      <path id="Path_20385" data-name="Path 20385" d="M101.163,79.36V80.64a5.671,5.671,0,0,1-11.342,0V78.959a8.281,8.281,0,0,0,4.42-1.994S95.347,79.36,101.163,79.36Z" transform="translate(-82.804 -70.952)" fill="#f1c0a6" />
      <path id="Path_20386" data-name="Path 20386" d="M96.491,103.618l-.089.015a5.674,5.674,0,0,1-6.581-5.6V96.352a6.835,6.835,0,0,0,2-.519v2.2A5.672,5.672,0,0,0,96.491,103.618Z" transform="translate(-82.804 -88.346)" fill="#e6ac94" />
      <path id="Path_20387" data-name="Path 20387" d="M144.555,152.417a2.866,2.866,0,0,1-1.383-.357.252.252,0,0,1,.243-.442,2.354,2.354,0,0,0,2.279,0,.252.252,0,0,1,.243.442A2.866,2.866,0,0,1,144.555,152.417Z" transform="translate(-131.867 -139.744)" fill="#d49985" />
      <path id="Path_20388" data-name="Path 20388" d="M152.191,133.971H150.9a.278.278,0,0,1,0-.555h1.287a.278.278,0,1,1,0,.555Z" transform="translate(-138.859 -122.993)" fill="#e6ac94" />
      <path id="Path_20389" data-name="Path 20389" d="M174.307,120.562a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-160.689 -111.143)" fill="#e6ac94" />
      <circle id="Ellipse_1723" data-name="Ellipse 1723" cx="0.817" cy="0.817" r="0.817" transform="translate(13.94 8.602)" fill="#343a3e" />
      <circle id="Ellipse_1724" data-name="Ellipse 1724" cx="0.35" cy="0.35" r="0.35" transform="translate(14.748 8.925)" fill="#fff" />
      <path id="Path_20390" data-name="Path 20390" d="M121.353,120.562a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-111.872 -111.143)" fill="#e6ac94" />
      <circle id="Ellipse_1725" data-name="Ellipse 1725" cx="0.817" cy="0.817" r="0.817" transform="translate(9.803 8.602)" fill="#343a3e" />
      <path id="Path_20391" data-name="Path 20391" d="M126.487,111.715a.8.8,0,0,1-.191.023.817.817,0,1,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-115.676 -101.502)" fill="#1f2426" />
      <path id="Path_20392" data-name="Path 20392" d="M179.44,111.715a.8.8,0,0,1-.191.023.817.817,0,0,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-164.492 -101.502)" fill="#1f2426" />
      <circle id="Ellipse_1726" data-name="Ellipse 1726" cx="0.35" cy="0.35" r="0.35" transform="translate(10.61 8.925)" fill="#fff" />
      <path id="Path_20393" data-name="Path 20393" d="M292.131,199.578h-3.155A3.425,3.425,0,0,0,285.551,203v5.956h10.005V203A3.425,3.425,0,0,0,292.131,199.578Z" transform="translate(-263.242 -183.986)" fill="#e8eeff" />
      <path id="Path_20394" data-name="Path 20394" d="M291.367,199.582a3.424,3.424,0,0,0-3.425,3.425v5.955h-2.391v-5.955a3.425,3.425,0,0,1,3.425-3.425Z" transform="translate(-263.242 -183.99)" fill="#d2dbf9" />
      <path id="Path_20395" data-name="Path 20395" d="M273.663,7.24h-3.315a4.917,4.917,0,0,0-4.917,4.917v5H278.58v-5A4.917,4.917,0,0,0,273.663,7.24Z" transform="translate(-244.694 -6.674)" fill="#fadc42" />
      <path id="Path_20396" data-name="Path 20396" d="M273.235,7.24a4.917,4.917,0,0,0-4.917,4.917v5h-2.885v-5a4.917,4.917,0,0,1,4.917-4.917h2.885Z" transform="translate(-244.695 -6.674)" fill="#f6b231" />
      <path id="Path_20397" data-name="Path 20397" d="M328.606,179.059v-2.48h3.278v2.48a.86.86,0,0,1-.354.7,2.183,2.183,0,0,1-2.571,0h0A.86.86,0,0,1,328.606,179.059Z" transform="translate(-302.934 -162.784)" fill="#f1c0a6" />
      <path id="Path_20398" data-name="Path 20398" d="M329.785,180.125a2.185,2.185,0,0,1-.824-.37h0a.861.861,0,0,1-.353-.7v-2.48h1.145v3.238A1.428,1.428,0,0,0,329.785,180.125Z" transform="translate(-302.935 -162.785)" fill="#e6ac94" />
      <path id="Path_20399" data-name="Path 20399" d="M256.034,116.355a1.509,1.509,0,1,1-1.268-1.49A1.51,1.51,0,0,1,256.034,116.355Z" transform="translate(-233.248 -105.874)" fill="#f1c0a6" />
      <path id="Path_20400" data-name="Path 20400" d="M274.335,116.584a1.51,1.51,0,0,1-1.268,1.49,5.644,5.644,0,0,1,0-2.98A1.51,1.51,0,0,1,274.335,116.584Z" transform="translate(-251.549 -106.102)" fill="#e6ac94" />
      <circle id="Ellipse_1727" data-name="Ellipse 1727" cx="0.539" cy="0.539" r="0.539" transform="translate(20.737 9.942)" fill="#e6ac94" />
      <path id="Path_20401" data-name="Path 20401" d="M407.516,116.355a1.509,1.509,0,1,0,1.268-1.49A1.51,1.51,0,0,0,407.516,116.355Z" transform="translate(-375.679 -105.874)" fill="#f1c0a6" />
      <path id="Path_20402" data-name="Path 20402" d="M407.516,116.584a1.51,1.51,0,0,0,1.268,1.49,5.642,5.642,0,0,0,0-2.98A1.51,1.51,0,0,0,407.516,116.584Z" transform="translate(-375.679 -106.102)" fill="#e6ac94" />
      <circle id="Ellipse_1728" data-name="Ellipse 1728" cx="0.539" cy="0.539" r="0.539" transform="translate(32.807 9.942)" fill="#e6ac94" />
      <path id="Path_20403" data-name="Path 20403" d="M288.336,79.36V80.64a5.671,5.671,0,1,1-11.342,0V78.959a8.281,8.281,0,0,0,4.42-1.994S282.52,79.36,288.336,79.36Z" transform="translate(-255.354 -70.952)" fill="#f1c0a6" />
      <path id="Path_20404" data-name="Path 20404" d="M283.664,103.618l-.089.015a5.674,5.674,0,0,1-6.581-5.6V96.352a6.834,6.834,0,0,0,2-.519v2.2A5.672,5.672,0,0,0,283.664,103.618Z" transform="translate(-255.354 -88.346)" fill="#e6ac94" />
      <path id="Path_20405" data-name="Path 20405" d="M331.728,152.417a2.866,2.866,0,0,1-1.383-.357.252.252,0,0,1,.243-.442,2.354,2.354,0,0,0,2.279,0,.252.252,0,0,1,.243.442A2.866,2.866,0,0,1,331.728,152.417Z" transform="translate(-304.417 -139.744)" fill="#d49985" />
      <path id="Path_20406" data-name="Path 20406" d="M339.364,133.971h-1.287a.278.278,0,1,1,0-.555h1.287a.278.278,0,0,1,0,.555Z" transform="translate(-311.409 -122.993)" fill="#e6ac94" />
      <path id="Path_20407" data-name="Path 20407" d="M361.48,120.562a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-333.239 -111.143)" fill="#e6ac94" />
      <circle id="Ellipse_1729" data-name="Ellipse 1729" cx="0.817" cy="0.817" r="0.817" transform="translate(28.563 8.602)" fill="#343a3e" />
      <circle id="Ellipse_1730" data-name="Ellipse 1730" cx="0.35" cy="0.35" r="0.35" transform="translate(29.37 8.925)" fill="#fff" />
      <path id="Path_20408" data-name="Path 20408" d="M308.526,120.562a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-284.422 -111.143)" fill="#e6ac94" />
      <circle id="Ellipse_1731" data-name="Ellipse 1731" cx="0.817" cy="0.817" r="0.817" transform="translate(24.426 8.602)" fill="#343a3e" />
      <path id="Path_20409" data-name="Path 20409" d="M313.66,111.715a.8.8,0,0,1-.191.023.817.817,0,1,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-288.226 -101.502)" fill="#1f2426" />
      <path id="Path_20410" data-name="Path 20410" d="M366.613,111.715a.8.8,0,0,1-.191.023.817.817,0,1,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-337.042 -101.502)" fill="#1f2426" />
      <circle id="Ellipse_1732" data-name="Ellipse 1732" cx="0.35" cy="0.35" r="0.35" transform="translate(25.233 8.925)" fill="#fff" />
      <path id="Path_20411" data-name="Path 20411" d="M35.786,359.1v6.609q-.795-.288-1.557-.641c-2.834-1.311-4.359-3.752-6.288-6.162-.365-.456-1.662-.443-1.986-.93a19.958,19.958,0,0,1-1.425-2.518,4.475,4.475,0,0,1,2.628-.848h4.137A4.491,4.491,0,0,1,35.786,359.1Z" transform="translate(-22.614 -326.906)" fill="#ef3d54" />
      <path id="Path_20412" data-name="Path 20412" d="M28.681,354.61a4.485,4.485,0,0,0-3.633,1.85q-.268-.492-.509-1a4.465,4.465,0,0,1,2.627-.849Z" transform="translate(-22.623 -326.906)" fill="#ca0051" />
      <path id="Path_20413" data-name="Path 20413" d="M51.044,395.395v4.116a20.09,20.09,0,0,1-8.274-7.093,3.4,3.4,0,0,1,1.694-.448h3.155A3.425,3.425,0,0,1,51.044,395.395Z" transform="translate(-39.429 -361.347)" fill="#e8eeff" />
      <path id="Path_20414" data-name="Path 20414" d="M46.854,391.97a3.423,3.423,0,0,0-3.049,1.864q-.547-.684-1.035-1.416a3.4,3.4,0,0,1,1.694-.448Z" transform="translate(-39.429 -361.347)" fill="#d2dbf9" />
      <path id="Path_20415" data-name="Path 20415" d="M13.639,204.547v5l-12-.857-.788-.636a20,20,0,0,1-.366-2.781v-.725a4.917,4.917,0,0,1,4.917-4.917H8.722A4.917,4.917,0,0,1,13.639,204.547Z" transform="translate(-0.452 -184.034)" fill="#2a428b" />
      <path id="Path_20416" data-name="Path 20416" d="M8.293,199.63a4.917,4.917,0,0,0-4.917,4.917v5l-1.944-.678s-.491-.724-.576-.815a20,20,0,0,1-.366-2.781v-.725a4.917,4.917,0,0,1,4.917-4.917Z" transform="translate(-0.452 -184.034)" fill="#132866" />
      <path id="Path_20417" data-name="Path 20417" d="M63.668,371.449v-2.48h3.278v2.48a.86.86,0,0,1-.354.7,2.183,2.183,0,0,1-2.571,0h0A.86.86,0,0,1,63.668,371.449Z" transform="translate(-58.694 -340.143)" fill="#f1c0a6" />
      <path id="Path_20418" data-name="Path 20418" d="M64.847,372.515a2.185,2.185,0,0,1-.824-.37h0a.861.861,0,0,1-.353-.7v-2.48h1.145v3.238A1.427,1.427,0,0,0,64.847,372.515Z" transform="translate(-58.695 -340.144)" fill="#e6ac94" />
      <path id="Path_20419" data-name="Path 20419" d="M142.578,308.745a1.509,1.509,0,1,0,1.268-1.49A1.51,1.51,0,0,0,142.578,308.745Z" transform="translate(-131.439 -283.233)" fill="#f1c0a6" />
      <path id="Path_20420" data-name="Path 20420" d="M142.578,308.974a1.51,1.51,0,0,0,1.268,1.49,5.643,5.643,0,0,0,0-2.98A1.51,1.51,0,0,0,142.578,308.974Z" transform="translate(-131.439 -283.462)" fill="#e6ac94" />
      <circle id="Ellipse_1733" data-name="Ellipse 1733" cx="0.539" cy="0.539" r="0.539" transform="translate(12.109 24.973)" fill="#e6ac94" />
      <path id="Path_20421" data-name="Path 20421" d="M23.4,271.75v1.281a5.671,5.671,0,0,1-11.342,0v-1.682a8.281,8.281,0,0,0,4.42-1.993S17.582,271.75,23.4,271.75Z" transform="translate(-11.114 -248.312)" fill="#f1c0a6" />
      <path id="Path_20422" data-name="Path 20422" d="M66.79,344.809a2.866,2.866,0,0,1-1.383-.357.252.252,0,1,1,.243-.442,2.354,2.354,0,0,0,2.279,0,.252.252,0,1,1,.243.442A2.866,2.866,0,0,1,66.79,344.809Z" transform="translate(-60.177 -317.106)" fill="#d49985" />
      <path id="Path_20423" data-name="Path 20423" d="M74.426,326.362H73.139a.277.277,0,0,1,0-.555h1.287a.277.277,0,1,1,0,.555Z" transform="translate(-67.17 -300.353)" fill="#e6ac94" />
      <path id="Path_20424" data-name="Path 20424" d="M96.541,312.953a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-88.999 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1734" data-name="Ellipse 1734" cx="0.817" cy="0.817" r="0.817" transform="translate(7.865 23.632)" fill="#343a3e" />
      <circle id="Ellipse_1735" data-name="Ellipse 1735" cx="0.35" cy="0.35" r="0.35" transform="translate(8.672 23.955)" fill="#fff" />
      <path id="Path_20425" data-name="Path 20425" d="M43.588,312.953a1.139,1.139,0,0,0,2.279,0Z" transform="translate(-40.183 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1736" data-name="Ellipse 1736" cx="0.817" cy="0.817" r="0.817" transform="translate(3.728 23.632)" fill="#343a3e" />
      <path id="Path_20426" data-name="Path 20426" d="M48.721,304.105a.8.8,0,0,1-.191.023.817.817,0,0,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-43.985 -278.862)" fill="#1f2426" />
      <path id="Path_20427" data-name="Path 20427" d="M101.675,304.105a.8.8,0,0,1-.191.023.817.817,0,0,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-92.802 -278.862)" fill="#1f2426" />
      <circle id="Ellipse_1737" data-name="Ellipse 1737" cx="0.35" cy="0.35" r="0.35" transform="translate(4.535 23.955)" fill="#fff" />
      <path id="Path_20428" data-name="Path 20428" d="M200.979,359.1v6.229q-.763.323-1.557.584c-1.963.644-4.06-1.664-6.238-1.664-1.288,0-2.547,2.534-3.766,2.3l-.044-.009q-.768-.148-1.513-.354V359.1a4.491,4.491,0,0,1,4.491-4.491h4.137a4.491,4.491,0,0,1,4.491,4.491Z" transform="translate(-173.183 -326.905)" fill="#ef3d54" />
      <path id="Path_20429" data-name="Path 20429" d="M193.865,354.61a4.492,4.492,0,0,0-4.491,4.491v7.442q-.768-.148-1.513-.354V359.1a4.491,4.491,0,0,1,4.491-4.491Z" transform="translate(-173.183 -326.906)" fill="#ca0051" />
      <path id="Path_20430" data-name="Path 20430" d="M217.795,395.395v4.96a20.149,20.149,0,0,1-10,.638v-5.6a3.425,3.425,0,0,1,3.425-3.425h3.155A3.425,3.425,0,0,1,217.795,395.395Z" transform="translate(-191.556 -361.347)" fill="#51aaf7" />
      <path id="Path_20431" data-name="Path 20431" d="M213.606,391.97a3.424,3.424,0,0,0-3.425,3.425V401.3a20.041,20.041,0,0,1-2.391-.307v-5.6a3.425,3.425,0,0,1,3.425-3.425Z" transform="translate(-191.556 -361.347)" fill="#378ae5" />
      <path id="Path_20432" data-name="Path 20432" d="M195.9,199.631h-3.315a4.917,4.917,0,0,0-4.917,4.917v5h13.149v-5A4.917,4.917,0,0,0,195.9,199.631Z" transform="translate(-173.005 -184.035)" fill="#fadc42" />
      <path id="Path_20433" data-name="Path 20433" d="M195.47,199.631a4.917,4.917,0,0,0-4.917,4.917v5h-2.885v-5a4.917,4.917,0,0,1,4.917-4.917Z" transform="translate(-173.006 -184.035)" fill="#f6b231" />
      <path id="Path_20434" data-name="Path 20434" d="M250.841,371.449v-2.48h3.278v2.48a.86.86,0,0,1-.354.7,2.183,2.183,0,0,1-2.571,0h0A.86.86,0,0,1,250.841,371.449Z" transform="translate(-231.244 -340.143)" fill="#f1c0a6" />
      <path id="Path_20435" data-name="Path 20435" d="M252.02,372.515a2.185,2.185,0,0,1-.824-.37h0a.861.861,0,0,1-.353-.7v-2.48h1.145v3.238A1.433,1.433,0,0,0,252.02,372.515Z" transform="translate(-231.245 -340.144)" fill="#e6ac94" />
      <path id="Path_20436" data-name="Path 20436" d="M178.269,308.745a1.509,1.509,0,1,1-1.268-1.49A1.51,1.51,0,0,1,178.269,308.745Z" transform="translate(-161.559 -283.233)" fill="#f1c0a6" />
      <path id="Path_20437" data-name="Path 20437" d="M196.57,308.974a1.51,1.51,0,0,1-1.268,1.49,5.642,5.642,0,0,1,0-2.98A1.51,1.51,0,0,1,196.57,308.974Z" transform="translate(-179.86 -283.462)" fill="#e6ac94" />
      <circle id="Ellipse_1738" data-name="Ellipse 1738" cx="0.539" cy="0.539" r="0.539" transform="translate(14.661 24.973)" fill="#e6ac94" />
      <path id="Path_20438" data-name="Path 20438" d="M329.751,308.745a1.509,1.509,0,1,0,1.268-1.49A1.51,1.51,0,0,0,329.751,308.745Z" transform="translate(-303.989 -283.233)" fill="#f1c0a6" />
      <path id="Path_20439" data-name="Path 20439" d="M329.751,308.974a1.51,1.51,0,0,0,1.268,1.49,5.643,5.643,0,0,0,0-2.98A1.51,1.51,0,0,0,329.751,308.974Z" transform="translate(-303.989 -283.462)" fill="#e6ac94" />
      <circle id="Ellipse_1739" data-name="Ellipse 1739" cx="0.539" cy="0.539" r="0.539" transform="translate(26.732 24.973)" fill="#e6ac94" />
      <path id="Path_20440" data-name="Path 20440" d="M210.571,271.75v1.281a5.671,5.671,0,0,1-11.342,0v-1.682a8.281,8.281,0,0,0,4.42-1.993S204.755,271.75,210.571,271.75Z" transform="translate(-183.664 -248.312)" fill="#f1c0a6" />
      <path id="Path_20441" data-name="Path 20441" d="M205.9,296.008l-.089.015a5.674,5.674,0,0,1-6.581-5.6v-1.682a6.833,6.833,0,0,0,2-.519v2.2A5.672,5.672,0,0,0,205.9,296.008Z" transform="translate(-183.664 -265.706)" fill="#e6ac94" />
      <path id="Path_20442" data-name="Path 20442" d="M253.963,344.809a2.866,2.866,0,0,1-1.383-.357.252.252,0,1,1,.243-.442,2.354,2.354,0,0,0,2.279,0,.252.252,0,1,1,.243.442A2.866,2.866,0,0,1,253.963,344.809Z" transform="translate(-232.727 -317.106)" fill="#d49985" />
      <path id="Path_20443" data-name="Path 20443" d="M261.6,326.362h-1.287a.277.277,0,0,1,0-.555H261.6a.277.277,0,0,1,0,.555Z" transform="translate(-239.72 -300.353)" fill="#e6ac94" />
      <path id="Path_20444" data-name="Path 20444" d="M283.714,312.953a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-261.549 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1740" data-name="Ellipse 1740" cx="0.817" cy="0.817" r="0.817" transform="translate(22.488 23.632)" fill="#343a3e" />
      <circle id="Ellipse_1741" data-name="Ellipse 1741" cx="0.35" cy="0.35" r="0.35" transform="translate(23.295 23.955)" fill="#fff" />
      <path id="Path_20445" data-name="Path 20445" d="M230.761,312.953a1.139,1.139,0,0,0,2.279,0Z" transform="translate(-212.733 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1742" data-name="Ellipse 1742" cx="0.817" cy="0.817" r="0.817" transform="translate(18.351 23.632)" fill="#343a3e" />
      <path id="Path_20446" data-name="Path 20446" d="M235.894,304.105a.8.8,0,0,1-.191.023.817.817,0,0,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-216.536 -278.862)" fill="#1f2426" />
      <path id="Path_20447" data-name="Path 20447" d="M288.848,304.105a.8.8,0,0,1-.191.023.817.817,0,0,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-265.352 -278.862)" fill="#1f2426" />
      <circle id="Ellipse_1743" data-name="Ellipse 1743" cx="0.35" cy="0.35" r="0.35" transform="translate(19.158 23.955)" fill="#fff" />
      <path id="Path_20448" data-name="Path 20448" d="M384.18,354.64c-.876,2.089-3.19,2.846-4.685,4.5-1.187,1.316-1.455,3.626-2.952,4.59q-.735.473-1.513.883V359.1a4.491,4.491,0,0,1,4.491-4.491h4.137A4.423,4.423,0,0,1,384.18,354.64Z" transform="translate(-345.731 -326.906)" fill="#ef3d54" />
      <path id="Path_20449" data-name="Path 20449" d="M381.035,354.61a4.491,4.491,0,0,0-4.491,4.491v4.633q-.735.473-1.513.883V359.1a4.491,4.491,0,0,1,4.491-4.491Z" transform="translate(-345.731 -326.906)" fill="#ca0051" />
      <path id="Path_20450" data-name="Path 20450" d="M401.052,391.97a20.094,20.094,0,0,1-6.092,6.177v-2.752a3.425,3.425,0,0,1,3.425-3.425Z" transform="translate(-364.104 -361.347)" fill="#b7e445" />
      <path id="Path_20451" data-name="Path 20451" d="M400.776,391.97a3.424,3.424,0,0,0-3.425,3.425v.936a20.067,20.067,0,0,1-2.391,1.816v-2.752a3.425,3.425,0,0,1,3.425-3.425Z" transform="translate(-364.104 -361.347)" fill="#7bc52a" />
      <path id="Path_20452" data-name="Path 20452" d="M385.556,204.034a19.985,19.985,0,0,1-.77,5.512H374.84v-5a4.917,4.917,0,0,1,4.917-4.917h3.315a4.888,4.888,0,0,1,2.1.469A20.09,20.09,0,0,1,385.556,204.034Z" transform="translate(-345.556 -184.034)" fill="#aa7a63" />
      <path id="Path_20453" data-name="Path 20453" d="M382.643,199.631a4.917,4.917,0,0,0-4.917,4.917v5H374.84v-5a4.917,4.917,0,0,1,4.917-4.917Z" transform="translate(-345.556 -184.035)" fill="#845c47" />
      <path id="Path_20454" data-name="Path 20454" d="M441.288,368.969v.866q-.151.273-.311.541c-.078.13-.157.26-.238.389a20.018,20.018,0,0,1-1.266,1.789,2.172,2.172,0,0,1-1.109-.411.862.862,0,0,1-.354-.7v-2.48h3.278Z" transform="translate(-403.79 -340.143)" fill="#f1c0a6" />
      <path id="Path_20455" data-name="Path 20455" d="M439.193,372.515a2.185,2.185,0,0,1-.824-.37h0a.861.861,0,0,1-.353-.7v-2.48h1.145v3.238A1.429,1.429,0,0,0,439.193,372.515Z" transform="translate(-403.795 -340.144)" fill="#e6ac94" />
      <path id="Path_20456" data-name="Path 20456" d="M365.442,308.745a1.509,1.509,0,1,1-1.268-1.49A1.51,1.51,0,0,1,365.442,308.745Z" transform="translate(-334.109 -283.233)" fill="#f1c0a6" />
      <path id="Path_20457" data-name="Path 20457" d="M383.743,308.974a1.51,1.51,0,0,1-1.268,1.49,5.644,5.644,0,0,1,0-2.98A1.51,1.51,0,0,1,383.743,308.974Z" transform="translate(-352.41 -283.462)" fill="#e6ac94" />
      <circle id="Ellipse_1744" data-name="Ellipse 1744" cx="0.539" cy="0.539" r="0.539" transform="translate(29.284 24.973)" fill="#e6ac94" />
      <path id="Path_20458" data-name="Path 20458" d="M395.932,271.669a19.593,19.593,0,0,1-.489,2.16,19.891,19.891,0,0,1-2.044,4.721q-.206.049-.418.084a5.676,5.676,0,0,1-6.581-5.6v-1.682a8.28,8.28,0,0,0,4.419-1.993S391.688,271.24,395.932,271.669Z" transform="translate(-356.213 -248.316)" fill="#f1c0a6" />
      <path id="Path_20459" data-name="Path 20459" d="M393.072,296.008l-.089.015a5.674,5.674,0,0,1-6.581-5.6v-1.682a6.834,6.834,0,0,0,2-.519v2.2A5.672,5.672,0,0,0,393.072,296.008Z" transform="translate(-356.214 -265.706)" fill="#e6ac94" />
      <path id="Path_20460" data-name="Path 20460" d="M441.136,344.809a2.866,2.866,0,0,1-1.383-.357.252.252,0,0,1,.243-.442,2.354,2.354,0,0,0,2.279,0,.252.252,0,1,1,.243.442A2.866,2.866,0,0,1,441.136,344.809Z" transform="translate(-405.277 -317.106)" fill="#d49985" />
      <path id="Path_20461" data-name="Path 20461" d="M448.772,326.362h-1.287a.277.277,0,0,1,0-.555h1.287a.277.277,0,1,1,0,.555Z" transform="translate(-412.27 -300.353)" fill="#e6ac94" />
      <path id="Path_20462" data-name="Path 20462" d="M470.887,312.953a1.139,1.139,0,1,0,2.279,0Z" transform="translate(-434.099 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1745" data-name="Ellipse 1745" cx="0.817" cy="0.817" r="0.817" transform="translate(37.11 23.632)" fill="#343a3e" />
      <circle id="Ellipse_1746" data-name="Ellipse 1746" cx="0.35" cy="0.35" r="0.35" transform="translate(37.918 23.955)" fill="#fff" />
      <path id="Path_20463" data-name="Path 20463" d="M417.934,312.953a1.139,1.139,0,0,0,2.279,0Z" transform="translate(-385.283 -288.504)" fill="#e6ac94" />
      <circle id="Ellipse_1747" data-name="Ellipse 1747" cx="0.817" cy="0.817" r="0.817" transform="translate(32.973 23.632)" fill="#343a3e" />
      <path id="Path_20464" data-name="Path 20464" d="M423.067,304.105a.8.8,0,0,1-.191.023.817.817,0,1,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-389.086 -278.862)" fill="#1f2426" />
      <path id="Path_20465" data-name="Path 20465" d="M476.021,304.105a.8.8,0,0,1-.191.023.817.817,0,1,1,0-1.634.8.8,0,0,1,.191.023.817.817,0,0,0,0,1.589Z" transform="translate(-437.903 -278.862)" fill="#1f2426" />
      <circle id="Ellipse_1748" data-name="Ellipse 1748" cx="0.35" cy="0.35" r="0.35" transform="translate(33.781 23.955)" fill="#fff" />
      <path id="Path_20466" data-name="Path 20466" d="M5.988,310.127a19.852,19.852,0,0,1-.816-2.875,1.508,1.508,0,0,1,.816,2.875Z" transform="translate(-4.768 -283.233)" fill="#f1c0a6" />
      <g id="Group_15978" data-name="Group 15978" transform="translate(0.624 22.517)">
        <path id="Path_20467" data-name="Path 20467" d="M10,308.974a1.51,1.51,0,0,0-1.268-1.49,5.6,5.6,0,0,0-.188,1.116q.237.895.553,1.755A1.509,1.509,0,0,0,10,308.974Z" transform="translate(-8.501 -305.979)" fill="#e6ac94" />
        <path id="Path_20468" data-name="Path 20468" d="M8.511,320.2a.539.539,0,0,0-.53-.539q.128.5.282.993a.538.538,0,0,0,.248-.454Z" transform="translate(-7.981 -317.202)" fill="#e6ac94" />
        <path id="Path_20469" data-name="Path 20469" d="M18.726,296.008l-.089.015a5.674,5.674,0,0,1-6.581-5.6v-1.682a6.833,6.833,0,0,0,2-.519v2.2A5.672,5.672,0,0,0,18.726,296.008Z" transform="translate(-11.738 -288.223)" fill="#e6ac94" />
      </g>
    </svg>
  );
}

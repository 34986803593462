import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { StatusLabelStyled } from './styled';
import { Icons } from "components"

const StatusLabel = ({
  theme_standard_status_label,
  theme_user,
  theme_health,
  theme_mission,
  theme_follow,
  theme_admin_portal,
  label,
  textShow,
  minWidth,
  widthLabel,
}) => {
  const customClass = cx({
    theme_standard_status_label: theme_standard_status_label,
    theme_user: theme_user,
    theme_mission: theme_mission,
    theme_health: theme_health,
    theme_follow: theme_follow,
    theme_admin_portal: theme_admin_portal,
  });

  const [_style, _setStyle] = useState({
    label: 'Active',
    color: '#1F9834',
    bg: '#C6E7CC'
  });

  useEffect(() => {
    if (label) {
      if (theme_user || theme_health || theme_mission || theme_follow || theme_admin_portal) {
        switch (String(label).toLowerCase()) {
          case 'active':
            _setStyle(
              {
                label: 'Active',
                color: '#009E93',
                bg: '#D9EDEC'
              }
            )
            break;

          case 'current':
            _setStyle(
              {
                label: textShow ? textShow : 'กำลังเผยแพร่',
                color: '#009E93',
                bg: '#D9EDEC',
                minWidth: '120px',
              }
            )
            break;

          case 'in_progress':
            _setStyle(
              {
                label: textShow ? textShow : 'กำลังดำเนินอยู่',
                color: '#009E93',
                bg: '#D9EDEC'
              }
            )
            break;

          case 'normal':
            _setStyle(
              {
                label: textShow ? textShow : 'ปกติ',
                color: '#00AB84',
                bg: '#EBFBF2'
              }
            )
            break;

          case 'low':
            _setStyle(
              {
                label: textShow ? textShow : 'เสี่ยงต่ำ',
                color: '#3C90DE',
                bg: '#DCF9FF'
              }
            )
            break;

          case 'moderate':
            _setStyle(
              {
                label: textShow ? textShow : 'เสี่ยงกลาง',
                color: '#DCF9FF',
                bg: '#FFF5D2'
              }
            )
            break;

          case 'pending':
            _setStyle(
              {
                label: textShow ? textShow : 'Pending',
                color: '#E3A52F',
                bg: '#FDF7D0',
                minWidth: minWidth,
              }
            )
            break;

          case 'cancelled':
          case 'admin_cancelled':
            _setStyle(
              {
                label: textShow ? textShow : 'ปฎิเสธ',
                color: '#E12A62',
                bg: '#FEE6DA',
                minWidth: minWidth,
              }
            )
            break;

          case 'upcoming':
            _setStyle(
              {
                label: textShow ? textShow : 'รอเผยแพร่',
                color: '#E3A52F',
                bg: '#FDF7D0',
                minWidth: '100px',
              }
            )
            break;

          case 'open_use':
          case 'joined':
            _setStyle(
              {
                label: textShow ? textShow : 'ใช้งาน',
                color: '#009E93',
                bg: '#D9EDEC',
                minWidth: minWidth ? minWidth : '90px',
              }
            )
            break;

          case 'not_open_use':
            _setStyle(
              {
                label: 'ปิดการใช้งาน',
                color: '#E12A62',
                bg: '#FEE6DA',
                minWidth: '134px',
              }
            )
            break;

          case 'inactive':
            _setStyle(
              {
                label: 'Inactive',
                color: '#D32F2F',
                bg: '#FFEBEB'
              }
            )
            break;

          case 'hight_risk':
            _setStyle(
              {
                label: textShow ? textShow : 'เสี่ยงสูง',
                color: '#FF983A',
                bg: '#FFF2DE'
              }
            )
            break;

          case 'severe':
            _setStyle(
              {
                label: textShow ? textShow : 'เสี่ยงสูง',
                color: '#EC5E49',
                bg: '#FFEAE7'
              }
            )
            break;

          case 'expired':
          case 'history':
            _setStyle(
              {
                label: textShow ? textShow : 'หมดอายุ',
                color: '#D32F2F',
                bg: '#FFEBEB',
                minWidth: '90px',
              }
            )
            break;

          case 'error':
            let textTemp = textShow ? textShow : 'error'
            _setStyle(
              {
                label: textTemp,
                color: '#D32F2F',
                bg: '#FFEBEB'
              }
            )
            break;

          default:
            _setStyle(
              {
                label: 'Active',
                color: '#198754',
                bg: '#B8EFC2'
              }
            )
            break;
        }
      } else {
        switch (label) {
          case 'active':
            _setStyle(
              {
                label: 'Active',
                color: '#198754',
                bg: '#B8EFC2'
              }
            )
            break;

          case 'pending':
            _setStyle(
              {
                label: 'Pending',
                color: '#E5A603',
                bg: '#FFF7DC'
              }
            )
            break;

          case 'inactive':
            _setStyle(
              {
                label: 'Inactive',
                color: '#D32F2F',
                bg: '#FFEBEB'
              }
            )
            break;

          default:
            _setStyle(
              {
                label: 'Active',
                color: '#198754',
                bg: '#B8EFC2'
              }
            )
            break;
        }
      }
    }
  }, [label, theme_user, theme_health, textShow, theme_mission, theme_follow, theme_admin_portal, minWidth]);

  return (
    <StatusLabelStyled >
      <div className={customClass}>
        <div
          className='group_status'
          style={{
            background: _style.bg,
            color: _style.color,
            minWidth: _style.minWidth ? _style.minWidth : 'unset',
            width: widthLabel && widthLabel,
          }}
        >
          <div className='ic'>
            <Icons.Round color={_style.color} />
          </div>
          <div>
            {_style.label}
          </div>
        </div>
      </div>
    </StatusLabelStyled>
  );
};

StatusLabel.propTypes = {};

export default StatusLabel;

import RewardMostPicked from './RewardMostPicked';
import TableSkeleton from './TableSkeleton';
import GraphSkeleton from './GraphSkeleton';

const Export = { 
    GraphSkeleton,
    RewardMostPicked ,
    TableSkeleton,
};

export default Export;

import { MsdNumberOfAnswerQuestionStyled } from "./styled";
import { Blocks, Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const MsdNumberOfAnswerQuestion = ({ data }) => {
  return (
    <MsdNumberOfAnswerQuestionStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="12px"
        paddingBottom="0px"
        paddingLeft="16px"
        // height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelCenter
            theme_standard_box_4_col
            label1="จำนวนพนักงานที่ตอบแบบสอบถามล่าสุด
            สัปดาห์ที่แล้ว"
            label2=" "
            iconShow={
              <Icons.PeoplePercent
                color="#0f8989"
                active
                width="32"
                height="40"
              />
            }
            label3Fontsize="56px"
            label3FontsColor="#2A74A3"
            labelBackFontsize="26px"
            label3={
              data && Number(data.msd_user_count) > 0
                ? `${RenderCommaMoney(Number(data.msd_user_count), true)}`
                : "0"
            }
            labelBack="คน"
          />
        </div>
      </Blocks.Box>
    </MsdNumberOfAnswerQuestionStyled>
  );
};

export default MsdNumberOfAnswerQuestion;

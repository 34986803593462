export default function renderIcon({ width = '118.468', height = '63.338' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 118.468 63.338">
      <g id="Group_54652" data-name="Group 54652" transform="translate(-20.106 -102.787)">
        <g id="Background_Complete" data-name="Background Complete" transform="translate(20.106 127.873)">
          <g id="Group_53948" data-name="Group 53948" transform="translate(0 25.076)">
            <g id="Group_53947" data-name="Group 53947">
              <g id="Group_53927" data-name="Group 53927" transform="translate(0.026 0)">
                <g id="Group_53926" data-name="Group 53926">
                  <g id="Group_53925" data-name="Group 53925">
                    <path id="Path_55235" data-name="Path 55235" d="M21.254,398.212v5.951H20.26v.646h.994v3.983H39.828v-3.624H38.452v-.79h1.376v-1.184h.612v-.538h-.726v-4.444Z" transform="translate(-20.26 -398.212)" fill="#f5f5f5" />
                  </g>
                </g>
              </g>
              <g id="Group_53930" data-name="Group 53930" transform="translate(14.662)">
                <g id="Group_53929" data-name="Group 53929" transform="translate(0)">
                  <g id="Group_53928" data-name="Group 53928">
                    <path id="Path_55236" data-name="Path 55236" d="M106.454,398.229v10.5h4.932V405.1h-1.376v-.723l1.376.024v-1.184h.623l-.012-.612-.726-.018v-4.378Z" transform="translate(-106.454 -398.212)" fill="#e0e0e0" />
                  </g>
                </g>
              </g>
              <g id="Group_53934" data-name="Group 53934" transform="translate(0 6.446)">
                <g id="Group_53933" data-name="Group 53933">
                  <g id="Group_53932" data-name="Group 53932">
                    <g id="Group_53931" data-name="Group 53931">
                      <path id="Path_55237" data-name="Path 55237" d="M35.119,436.241c0,.037-3.361.066-7.506.066s-7.507-.029-7.507-.066,3.36-.066,7.507-.066S35.119,436.2,35.119,436.241Z" transform="translate(-20.106 -436.175)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53938" data-name="Group 53938" transform="translate(0 5.841)">
                <g id="Group_53937" data-name="Group 53937">
                  <g id="Group_53936" data-name="Group 53936">
                    <g id="Group_53935" data-name="Group 53935">
                      <path id="Path_55238" data-name="Path 55238" d="M35.119,432.68c0,.036-3.361.066-7.506.066s-7.507-.03-7.507-.066,3.36-.066,7.507-.066S35.119,432.644,35.119,432.68Z" transform="translate(-20.106 -432.614)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53942" data-name="Group 53942" transform="translate(0.964 4.214)">
                <g id="Group_53941" data-name="Group 53941">
                  <g id="Group_53940" data-name="Group 53940">
                    <g id="Group_53939" data-name="Group 53939">
                      <path id="Path_55239" data-name="Path 55239" d="M40.8,423.1c0,.036-3.361.066-7.506.066s-7.507-.03-7.507-.066,3.36-.066,7.507-.066S40.8,423.062,40.8,423.1Z" transform="translate(-25.785 -423.032)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53946" data-name="Group 53946" transform="translate(1.057 8.648)">
                <g id="Group_53945" data-name="Group 53945">
                  <g id="Group_53944" data-name="Group 53944">
                    <g id="Group_53943" data-name="Group 53943">
                      <path id="Path_55240" data-name="Path 55240" d="M41.344,449.21c0,.036-3.361.066-7.506.066s-7.507-.029-7.507-.066,3.36-.066,7.507-.066S41.344,449.174,41.344,449.21Z" transform="translate(-26.331 -449.144)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_53972" data-name="Group 53972" transform="translate(2.069 14.599)">
            <g id="Group_53971" data-name="Group 53971">
              <g id="Group_53951" data-name="Group 53951" transform="translate(0.026)">
                <g id="Group_53950" data-name="Group 53950">
                  <g id="Group_53949" data-name="Group 53949">
                    <path id="Path_55241" data-name="Path 55241" d="M33.438,336.51v5.951h-.994v.646h.994v3.983H52.012v-3.624H50.636v-.79h1.376v-1.184h.612v-.538H51.9V336.51Z" transform="translate(-32.444 -336.51)" fill="#f5f5f5" />
                  </g>
                </g>
              </g>
              <g id="Group_53954" data-name="Group 53954" transform="translate(14.662 0)">
                <g id="Group_53953" data-name="Group 53953" transform="translate(0)">
                  <g id="Group_53952" data-name="Group 53952">
                    <path id="Path_55242" data-name="Path 55242" d="M118.638,336.527v10.5h4.932V343.4h-1.376v-.723l1.376.024v-1.184h.623l-.012-.612-.726-.018V336.51Z" transform="translate(-118.638 -336.51)" fill="#e0e0e0" />
                  </g>
                </g>
              </g>
              <g id="Group_53958" data-name="Group 53958" transform="translate(0 6.446)">
                <g id="Group_53957" data-name="Group 53957">
                  <g id="Group_53956" data-name="Group 53956">
                    <g id="Group_53955" data-name="Group 53955">
                      <path id="Path_55243" data-name="Path 55243" d="M47.3,374.539c0,.036-3.361.066-7.506.066s-7.507-.03-7.507-.066,3.36-.066,7.507-.066S47.3,374.5,47.3,374.539Z" transform="translate(-32.29 -374.473)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53962" data-name="Group 53962" transform="translate(0 5.842)">
                <g id="Group_53961" data-name="Group 53961">
                  <g id="Group_53960" data-name="Group 53960">
                    <g id="Group_53959" data-name="Group 53959">
                      <path id="Path_55244" data-name="Path 55244" d="M47.3,370.979c0,.036-3.361.066-7.506.066s-7.507-.029-7.507-.066,3.36-.066,7.507-.066S47.3,370.943,47.3,370.979Z" transform="translate(-32.29 -370.913)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53966" data-name="Group 53966" transform="translate(0.964 4.214)">
                <g id="Group_53965" data-name="Group 53965">
                  <g id="Group_53964" data-name="Group 53964">
                    <g id="Group_53963" data-name="Group 53963">
                      <path id="Path_55245" data-name="Path 55245" d="M52.982,361.4c0,.036-3.361.066-7.506.066s-7.507-.03-7.507-.066,3.36-.066,7.507-.066S52.982,361.36,52.982,361.4Z" transform="translate(-37.969 -361.33)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_53970" data-name="Group 53970" transform="translate(1.057 8.648)">
                <g id="Group_53969" data-name="Group 53969">
                  <g id="Group_53968" data-name="Group 53968">
                    <g id="Group_53967" data-name="Group 53967">
                      <path id="Path_55246" data-name="Path 55246" d="M53.527,387.508c0,.036-3.361.066-7.506.066s-7.507-.03-7.507-.066,3.36-.066,7.507-.066S53.527,387.471,53.527,387.508Z" transform="translate(-38.514 -387.442)" fill="#e0e0e0" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_54006" data-name="Group 54006" transform="translate(100.741)">
            <g id="Group_53988" data-name="Group 53988">
              <g id="Group_53974" data-name="Group 53974" transform="translate(10.699 14.174)">
                <g id="Group_53973" data-name="Group 53973">
                  <path id="Path_55247" data-name="Path 55247" d="M677.725,334.542a5.776,5.776,0,0,1,3.584-.436,2.979,2.979,0,0,1,1.771.79,1.274,1.274,0,0,1,.018,1.768,1.7,1.7,0,0,1-1.117.283c-.947-.011-2-.178-2.756.392-.432.325-.719.866-1.236,1.026a1.221,1.221,0,0,1-1.343-.6,2.086,2.086,0,0,1-.152-1.54A2.351,2.351,0,0,1,677.725,334.542Z" transform="translate(-676.417 -334.005)" fill="#ebebeb" />
                </g>
              </g>
              <g id="Group_53976" data-name="Group 53976" transform="translate(8.879)">
                <g id="Group_53975" data-name="Group 53975">
                  <path id="Path_55248" data-name="Path 55248" d="M666.329,260.42a1.717,1.717,0,0,0,2.563.056,4.844,4.844,0,0,0,1.086-2.227,11.837,11.837,0,0,0,.668-5.63,3.128,3.128,0,0,0-.719-1.53,1.634,1.634,0,0,0-1.548-.515,2.39,2.39,0,0,0-1.353,1.421,13.418,13.418,0,0,0-1.326,5.759,4.655,4.655,0,0,0,.629,2.666" transform="translate(-665.699 -250.531)" fill="#ebebeb" />
                </g>
              </g>
              <g id="Group_53978" data-name="Group 53978" transform="translate(0 3.064)">
                <g id="Group_53977" data-name="Group 53977">
                  <path id="Path_55249" data-name="Path 55249" d="M621.086,276.766a3.465,3.465,0,0,0,.539-3.188,6.146,6.146,0,0,0-1.888-2.739,10.166,10.166,0,0,0-3.381-2,3.607,3.607,0,0,0-1.521-.258,1.6,1.6,0,0,0-1.276.772,2.388,2.388,0,0,0,.354,2.174,25.786,25.786,0,0,0,2.221,3.382,6.015,6.015,0,0,0,2.443,2.093,2.213,2.213,0,0,0,2.606-.361" transform="translate(-613.408 -268.575)" fill="#ebebeb" />
                </g>
              </g>
              <g id="Group_53981" data-name="Group 53981" transform="translate(9.451 15.496)">
                <g id="Group_53980" data-name="Group 53980">
                  <g id="Group_53979" data-name="Group 53979">
                    <path id="Path_55250" data-name="Path 55250" d="M669.081,350.548a2.078,2.078,0,0,0,.06-.433l.115-1.181a17.023,17.023,0,0,1,.266-1.732,13.38,13.38,0,0,1,.629-2.044,7.54,7.54,0,0,1,1.044-1.85,3.9,3.9,0,0,1,1.319-1.1,3.7,3.7,0,0,1,1.111-.346,2.07,2.07,0,0,0,.431-.062,2.029,2.029,0,0,0-.438-.007,3.37,3.37,0,0,0-1.159.3,3.892,3.892,0,0,0-1.392,1.107,7.357,7.357,0,0,0-1.082,1.887,12.5,12.5,0,0,0-.625,2.078,14.639,14.639,0,0,0-.23,1.754c-.038.5-.052.907-.059,1.188A2.019,2.019,0,0,0,669.081,350.548Z" transform="translate(-669.069 -341.792)" fill="#e0e0e0" />
                  </g>
                </g>
              </g>
              <g id="Group_53984" data-name="Group 53984" transform="translate(9.451 3.826)">
                <g id="Group_53983" data-name="Group 53983">
                  <g id="Group_53982" data-name="Group 53982">
                    <path id="Path_55251" data-name="Path 55251" d="M669.163,292.58a.864.864,0,0,0,.012-.2c0-.151,0-.341,0-.574,0-.5,0-1.219.025-2.109.044-1.781.2-3.7.494-6.4.28-2.54.657-5.5,1.054-7.419.085-.437.171-.831.252-1.175s.143-.643.206-.884l.134-.558a.869.869,0,0,0,.035-.2.834.834,0,0,0-.07.188l-.167.55c-.074.239-.15.534-.236.878s-.185.736-.278,1.172a65.088,65.088,0,0,0-1.108,6.884c-.3,2.707-.431,5.172-.442,6.958-.011.892.008,1.615.031,2.113.014.233.026.423.035.574A.842.842,0,0,0,669.163,292.58Z" transform="translate(-669.069 -273.063)" fill="#e0e0e0" />
                  </g>
                </g>
              </g>
              <g id="Group_53987" data-name="Group 53987" transform="translate(3.196 5.279)">
                <g id="Group_53986" data-name="Group 53986">
                  <g id="Group_53985" data-name="Group 53985">
                    <path id="Path_55252" data-name="Path 55252" d="M638.593,294.933a3.791,3.791,0,0,0,0-.6c-.022-.385-.075-.943-.184-1.625a21.446,21.446,0,0,0-.528-2.367,19.419,19.419,0,0,0-.99-2.8,14.928,14.928,0,0,0-2.907-4.527,7.423,7.423,0,0,0-.684-.636c-.1-.089-.2-.177-.292-.247l-.265-.183a3.825,3.825,0,0,0-.5-.323,17.871,17.871,0,0,1,1.655,1.476,15.721,15.721,0,0,1,2.834,4.51,22.14,22.14,0,0,1,1,2.775,24.068,24.068,0,0,1,.562,2.344c.124.675.2,1.227.239,1.609A3.87,3.87,0,0,0,638.593,294.933Z" transform="translate(-632.233 -281.624)" fill="#e0e0e0" />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_53990" data-name="Group 53990" transform="translate(4.961 26.975)">
              <g id="Group_53989" data-name="Group 53989">
                <path id="Path_55253" data-name="Path 55253" d="M642.626,409.4l.476,6.527h8.385l.477-6.527Z" transform="translate(-642.626 -409.399)" fill="#e0e0e0" />
              </g>
            </g>
            <g id="Group_53992" data-name="Group 53992" transform="translate(4.151 24.403)">
              <g id="Group_53991" data-name="Group 53991">
                <rect id="Rectangle_19782" data-name="Rectangle 19782" width="11.148" height="2.573" fill="#e0e0e0" />
              </g>
            </g>
            <g id="Group_53994" data-name="Group 53994" transform="translate(4.151 33.252)">
              <g id="Group_53993" data-name="Group 53993">
                <path id="Path_55254" data-name="Path 55254" d="M637.856,446.368v.713H648.82v-.713Z" transform="translate(-637.856 -446.368)" fill="#ebebeb" />
              </g>
            </g>
            <g id="Group_53996" data-name="Group 53996" transform="translate(4.675 33.966)">
              <g id="Group_53995" data-name="Group 53995">
                <path id="Path_55255" data-name="Path 55255" d="M641.454,451.823l-.513-1.255h10.041l-.6,1.255Z" transform="translate(-640.941 -450.568)" fill="#e0e0e0" />
              </g>
            </g>
            <g id="Group_53999" data-name="Group 53999" transform="translate(5.102 28.192)">
              <g id="Group_53998" data-name="Group 53998">
                <g id="Group_53997" data-name="Group 53997">
                  <path id="Path_55256" data-name="Path 55256" d="M652.5,417.48a.278.278,0,0,1-.039.06l-.125.166-.488.625-.022.028-.021-.029-1.2-1.66h.069l-1.181,1.673-.037.052-.041-.049-1.4-1.667h.081l-.077.1-1.253,1.571-.042.052-.038-.055-1.182-1.672.069,0-1.456,1.659-.026.029-.02-.033-.456-.766-.117-.2a.324.324,0,0,1-.036-.073.328.328,0,0,1,.049.065l.13.2.478.752-.046,0,1.432-1.679.036-.042.033.046,1.193,1.665-.08,0,1.25-1.573.077-.1.04-.05.041.049,1.4,1.67-.078,0,1.193-1.665.035-.049.034.049,1.175,1.678H651.8l.508-.609.136-.158A.27.27,0,0,1,652.5,417.48Z" transform="translate(-643.458 -416.563)" fill="#f5f5f5" />
                </g>
              </g>
            </g>
            <g id="Group_54002" data-name="Group 54002" transform="translate(5.031 26.88)">
              <g id="Group_54001" data-name="Group 54001">
                <g id="Group_54000" data-name="Group 54000">
                  <path id="Path_55257" data-name="Path 55257" d="M652.422,408.89c0,.029-2.1.052-4.692.052s-4.692-.023-4.692-.052,2.1-.052,4.692-.052S652.422,408.861,652.422,408.89Z" transform="translate(-643.037 -408.838)" fill="#f5f5f5" />
                </g>
              </g>
            </g>
            <g id="Group_54005" data-name="Group 54005" transform="translate(4.64 33.984)">
              <g id="Group_54004" data-name="Group 54004">
                <g id="Group_54003" data-name="Group 54003">
                  <path id="Path_55258" data-name="Path 55258" d="M650.933,450.727c0,.029-2.283.052-5.1.052s-5.1-.023-5.1-.052,2.283-.052,5.1-.052S650.933,450.7,650.933,450.727Z" transform="translate(-640.734 -450.675)" fill="#f5f5f5" />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Character_2" data-name="Character 2" transform="translate(48.182 103.024)">
          <g id="Group_54016" data-name="Group 54016" transform="translate(39.903 42.688)">
            <g id="Group_54012" data-name="Group 54012">
              <g id="Group_54011" data-name="Group 54011">
                <g id="Group_54010" data-name="Group 54010">
                  <g id="Group_54009" data-name="Group 54009">
                    <g id="Group_54008" data-name="Group 54008">
                      <g id="Group_54007" data-name="Group 54007">
                        <path id="Path_55259" data-name="Path 55259" d="M421.408,356.638a7.08,7.08,0,0,1,2.47-1.043l3.884.9s.332.363-.342.7a1.9,1.9,0,0,1-1.528-.146l-.945.133.652.545,2.576.792s1.866.528,1.64.8-.787.308-1.592.08l-1.827-.516s-.32-.069.194.288a27.5,27.5,0,0,0,3.073,1.06c.3.148,1.111.272,1.023.735s-1.107.09-1.107.09l-3.344-.776s1.32.57,1.545.676.956.307,1.347.5.578.454.533.659a.391.391,0,0,1-.423.329l-2.83-.972s-1.284-.511-1.37-.493.5.566.5.566l.9.462s1.3.437,1.307.8-.268.348-.268.348l-2.377-.82L422.449,361l-1.989-1.22a9.512,9.512,0,0,0,.29-1.463A12.677,12.677,0,0,1,421.408,356.638Z" transform="translate(-420.46 -355.592)" fill="#ffbe9d" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54015" data-name="Group 54015" transform="translate(1.878 1.446)">
              <g id="Group_54014" data-name="Group 54014">
                <g id="Group_54013" data-name="Group 54013">
                  <path id="Path_55260" data-name="Path 55260" d="M431.523,366.345a4.178,4.178,0,0,1,.64-.235,2.143,2.143,0,0,0,1.225-.947,1.149,1.149,0,0,0,.129-.741l-.017-.085.093-.012c.412-.053.8-.143,1.056-.178s.426-.045.427-.024a2.214,2.214,0,0,1-.406.121,10.821,10.821,0,0,1-1.071.242l.077-.1a1.269,1.269,0,0,1-.134.853,1.76,1.76,0,0,1-.646.683,2.8,2.8,0,0,1-.695.3A2.012,2.012,0,0,1,431.523,366.345Z" transform="translate(-431.523 -364.11)" fill="#eb996e" />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_54030" data-name="Group 54030" transform="translate(0 16.385)">
            <path id="Path_55261" data-name="Path 55261" d="M215.063,405.275l-.315-11.72,20.552.4,2.8,10.881-23.034.441" transform="translate(-209.774 -360.807)" fill="#263238" />
            <path id="Path_55262" data-name="Path 55262" d="M228.157,200.688l-1.545,1.463s-5.778,1.436-6.787,2.445-1.537,16.576-1.47,17.653,0,10.432,0,10.432-.34,3.567,0,3.769,6.819.288,10.05.356,10.082.257,10.486-.079-1-7.314-1-7.314l-.56-3.218.174-5.627,1.182-7.741.875-8.009a18.928,18.928,0,0,0-3.5-1.682C234.912,202.866,228.157,200.688,228.157,200.688Z" transform="translate(-212.641 -200.688)" fill="#3f3f8a" />
            <path id="Path_55263" data-name="Path 55263" d="M335.131,243.327l-3.14-13.184a14.728,14.728,0,0,0-1.08-3.031c-.487-1-1.09-2.091-1.422-2.13-1.947-.224-2.961,5.721-2.961,5.721v7.538l1.838,8.164a3.615,3.615,0,0,0,2.431,2.651l12.462,3.966,1.444-6.186Z" transform="translate(-302.575 -220.852)" fill="#3f3f8a" />
            <path id="Path_55264" data-name="Path 55264" d="M195.751,216.766s-4.735.669-6.264,4.282c0,0-4.733,18.42-3.941,20.846s6.909,11.789,6.909,11.789l5.363-2.831s-5.241-10.88-5.241-12.078S195.751,216.766,195.751,216.766Z" transform="translate(-185.457 -214.036)" fill="#455a64" />
            <g id="Group_54017" data-name="Group 54017" transform="translate(7.139 12.736)">
              <path id="Path_55265" data-name="Path 55265" d="M232.724,299.776a.375.375,0,0,1-.03-.059l-.08-.176-.3-.68-1.083-2.507-3.542-8.285-.185-.435,0-.009v-.007a4.542,4.542,0,0,1,.1-.637c.045-.206.09-.411.139-.612.1-.4.2-.8.3-1.189l.585-2.235c.376-1.422.71-2.7.982-3.772s.48-1.943.623-2.543l.161-.692.044-.179a.343.343,0,0,1,.018-.06.323.323,0,0,1-.009.062l-.035.181-.145.7c-.133.6-.33,1.475-.6,2.55s-.593,2.355-.964,3.778l-.58,2.236c-.1.389-.2.784-.295,1.188a9.02,9.02,0,0,0-.236,1.228l0-.016.185.435,3.518,8.3,1.057,2.518.283.687.072.179A.344.344,0,0,1,232.724,299.776Z" transform="translate(-227.502 -275.697)" fill="#263238" />
            </g>
            <g id="Group_54018" data-name="Group 54018" transform="translate(24.808 10.753)">
              <path id="Path_55266" data-name="Path 55266" d="M332.767,264.015a.492.492,0,0,1,0,.107l-.016.306c-.018.277-.043.658-.073,1.124-.068.949-.149,2.261-.3,3.705s-.365,2.744-.528,3.681c-.082.469-.151.847-.2,1.108l-.06.3a.5.5,0,0,1-.027.1.492.492,0,0,1,.009-.107l.044-.3.175-1.113c.146-.939.35-2.238.5-3.68s.242-2.753.326-3.7l.1-1.122.032-.3A.477.477,0,0,1,332.767,264.015Z" transform="translate(-331.56 -264.015)" fill="#263238" />
            </g>
            <path id="Path_55267" data-name="Path 55267" d="M230.835,421.971l.531.979a10.08,10.08,0,0,0,3.886-2.695l-.346-.333Z" transform="translate(-223.13 -382.698)" fill="#ffbe9d" />
            <g id="Group_54019" data-name="Group 54019" transform="translate(6.921 36.601)">
              <path id="Path_55268" data-name="Path 55268" d="M232.2,416.248a1.831,1.831,0,0,1-.151.25c-.051.079-.113.176-.2.281s-.167.232-.278.359-.225.271-.364.413-.286.293-.448.445a9.683,9.683,0,0,1-1.138.893,10.729,10.729,0,0,1-1.261.71,10.55,10.55,0,0,1-1.1.442c-.158.058-.3.1-.432.137s-.239.068-.33.089a1.83,1.83,0,0,1-.285.06,1.858,1.858,0,0,1,.276-.093c.09-.026.2-.06.325-.1s.27-.087.426-.149c.315-.113.682-.267,1.081-.457a11.508,11.508,0,0,0,1.247-.71,10.274,10.274,0,0,0,1.133-.88c.162-.15.316-.292.45-.436s.259-.277.369-.4.2-.246.286-.348.151-.2.206-.271A1.86,1.86,0,0,1,232.2,416.248Z" transform="translate(-226.218 -416.247)" fill="#455a64" />
            </g>
            <g id="Group_54020" data-name="Group 54020" transform="translate(7.803 4.717)">
              <path id="Path_55269" data-name="Path 55269" d="M233.834,233.06c-.02,0,.051-.311.036-.813a3.185,3.185,0,0,0-.035-.408,4.255,4.255,0,0,0-.086-.462,4.433,4.433,0,0,0-.994-1.885,4.22,4.22,0,0,0-.332-.332,3.167,3.167,0,0,0-.317-.259c-.405-.3-.7-.411-.691-.429a1.044,1.044,0,0,1,.205.08,1.636,1.636,0,0,1,.231.117,2.666,2.666,0,0,1,.291.181,3.014,3.014,0,0,1,.329.255,4,4,0,0,1,.344.332,4.293,4.293,0,0,1,1.013,1.921,4.043,4.043,0,0,1,.079.472,3.025,3.025,0,0,1,.025.416,2.664,2.664,0,0,1-.015.343,1.6,1.6,0,0,1-.034.256A1.045,1.045,0,0,1,233.834,233.06Z" transform="translate(-231.414 -228.471)" fill="#263238" />
            </g>
            <g id="Group_54021" data-name="Group 54021" transform="translate(2.551 12.703)">
              <path id="Path_55270" data-name="Path 55270" d="M207.441,277.177c-.006.024-1.568-.332-3.49-.795s-3.475-.857-3.469-.881,1.568.332,3.49.795S207.447,277.153,207.441,277.177Z" transform="translate(-200.482 -275.5)" fill="#fff" />
            </g>
            <g id="Group_54022" data-name="Group 54022" transform="translate(9.963 13.399)">
              <path id="Path_55271" data-name="Path 55271" d="M259.849,279.609c0,.024-3.518.1-7.857.178s-7.859.114-7.859.09,3.517-.1,7.858-.178S259.849,279.585,259.849,279.609Z" transform="translate(-244.133 -279.602)" fill="#fff" />
            </g>
            <g id="Group_54023" data-name="Group 54023" transform="translate(25.785 14.286)">
              <path id="Path_55272" data-name="Path 55272" d="M342.24,284.824a.838.838,0,0,1-.191.041c-.124.021-.3.048-.525.078-.444.061-1.059.135-1.74.2s-1.3.114-1.746.141c-.224.013-.4.022-.53.026a.1.1,0,1,1,0-.034l.528-.054,1.742-.167,1.741-.176.528-.05A.829.829,0,0,1,342.24,284.824Z" transform="translate(-337.314 -284.821)" fill="#fff" />
            </g>
            <g id="Group_54024" data-name="Group 54024" transform="translate(0.542 22.994)">
              <path id="Path_55273" data-name="Path 55273" d="M195.376,337.074c0,.024-1.512-.173-3.369-.439s-3.36-.5-3.357-.527,1.512.172,3.369.439S195.379,337.05,195.376,337.074Z" transform="translate(-188.65 -336.106)" fill="#fff" />
            </g>
            <g id="Group_54025" data-name="Group 54025" transform="translate(8.115 25.334)">
              <path id="Path_55274" data-name="Path 55274" d="M249.545,349.89c0,.025-3.645.3-8.145.606s-8.15.542-8.152.518,3.645-.3,8.146-.606S249.543,349.866,249.545,349.89Z" transform="translate(-233.248 -349.889)" fill="#fff" />
            </g>
            <g id="Group_54026" data-name="Group 54026" transform="translate(26.864 22.686)">
              <path id="Path_55275" data-name="Path 55275" d="M349.027,334.3a1.665,1.665,0,0,1-.207.185l-.583.482c-.493.406-1.172.97-1.911,1.6s-1.4,1.22-1.876,1.646l-.565.5a.139.139,0,1,1-.023-.025c.127-.126.313-.305.546-.524.466-.438,1.12-1.031,1.86-1.667s1.426-1.192,1.93-1.586c.252-.2.457-.354.6-.46A1.7,1.7,0,0,1,349.027,334.3Z" transform="translate(-343.67 -334.295)" fill="#fff" />
            </g>
            <g id="Group_54027" data-name="Group 54027" transform="translate(5.337 34.63)">
              <path id="Path_55276" data-name="Path 55276" d="M222.475,404.641a1.219,1.219,0,0,1-.216.1l-.6.247-1.977.811-1.971.826-.6.249a.119.119,0,1,1-.014-.032c.138-.067.338-.161.587-.275.5-.227,1.191-.531,1.962-.851s1.475-.6,1.988-.787c.256-.1.464-.172.609-.221A1.243,1.243,0,0,1,222.475,404.641Z" transform="translate(-216.891 -404.64)" fill="#fff" />
            </g>
            <g id="Group_54028" data-name="Group 54028" transform="translate(37.44 25.243)">
              <path id="Path_55277" data-name="Path 55277" d="M407.862,349.352a1.317,1.317,0,0,1-.034.244c-.027.156-.072.381-.133.657-.122.553-.316,1.312-.58,2.135s-.547,1.554-.769,2.075c-.111.26-.2.47-.274.612a.123.123,0,1,1-.032-.014l.248-.622c.207-.526.48-1.257.743-2.078s.466-1.574.6-2.122l.16-.65A1.314,1.314,0,0,1,407.862,349.352Z" transform="translate(-405.958 -349.352)" fill="#fff" />
            </g>
            <g id="Group_54029" data-name="Group 54029" transform="translate(8.597 3.2)">
              <path id="Path_55278" data-name="Path 55278" d="M242.936,219.539a1.7,1.7,0,0,1-.272.055l-.745.128c-.628.11-1.493.278-2.439.506s-1.792.475-2.4.664l-.721.227a1.688,1.688,0,0,1-.267.075,1.664,1.664,0,0,1,.256-.108c.167-.065.41-.152.713-.254.605-.2,1.451-.461,2.4-.69s1.818-.388,2.45-.482c.316-.047.572-.081.75-.1A1.656,1.656,0,0,1,242.936,219.539Z" transform="translate(-236.091 -219.537)" fill="#fff" />
            </g>
          </g>
          <g id="Group_54238" data-name="Group 54238" transform="translate(11.529 0)">
            <g id="Group_54034" data-name="Group 54034">
              <g id="Group_54033" data-name="Group 54033">
                <g id="Group_54032" data-name="Group 54032">
                  <g id="Group_54031" data-name="Group 54031">
                    <path id="Path_55279" data-name="Path 55279" d="M270.125,107.672c1.259,1.085.554,2.786.3,4.428a27.3,27.3,0,0,0,.108,5.1,7.165,7.165,0,0,1-.034,2.616,7.156,7.156,0,0,1-1.683,2.155,2.249,2.249,0,0,0-.582,2.506.834.834,0,0,1-.792-.11.965.965,0,0,1-1.109.356,2.82,2.82,0,0,1-1.04-.687.588.588,0,0,1-.71.4,1.575,1.575,0,0,1-.751-.5,2.049,2.049,0,0,0-.72-.548c-.637-.225-1.381.258-2-.016-.848-.376-.767-1.842-1.644-2.144a3.016,3.016,0,0,0-1.29.1.757.757,0,0,1-.839-.768,3.56,3.56,0,0,1-2.463.181.871.871,0,0,0,.308-.839,2.848,2.848,0,0,1-1.638-.2,1.676,1.676,0,0,0,1.085-.861,1.431,1.431,0,0,1-1.28-.456c.944.009,1.6-.969,1.8-1.892a14.394,14.394,0,0,1,.506-2.778,23.1,23.1,0,0,1,1.818-2.479c.742-1.226.763-2.791,1.531-4a6.784,6.784,0,0,1,1.742-2.019,3.883,3.883,0,0,1,2.7-1.041A8.47,8.47,0,0,1,267,105c1.509.924,3.11,1.954,3.779,3.592" transform="translate(-253.355 -104.187)" fill="#263238" />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54203" data-name="Group 54203" transform="translate(3.58 0.619)">
              <g id="Group_54194" data-name="Group 54194">
                <g id="Group_54193" data-name="Group 54193">
                  <g id="Group_54042" data-name="Group 54042" transform="translate(1.956 1.373)">
                    <g id="Group_54041" data-name="Group 54041">
                      <g id="Group_54040" data-name="Group 54040">
                        <g id="Group_54039" data-name="Group 54039">
                          <g id="Group_54038" data-name="Group 54038">
                            <g id="Group_54037" data-name="Group 54037">
                              <g id="Group_54036" data-name="Group 54036">
                                <g id="Group_54035" data-name="Group 54035">
                                  <path id="Path_55280" data-name="Path 55280" d="M286.032,124.685a22.807,22.807,0,0,1,.021-3.936,7.971,7.971,0,0,1,1.3-3.677c.115-.162,1.009-1.221,1.2-1.151.133.05-.519,1.085-.482,1.223a6.757,6.757,0,0,1-.279,4,17.8,17.8,0,0,1-1.757,3.539" transform="translate(-285.957 -115.917)" fill="#263238" />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54051" data-name="Group 54051" transform="translate(0 0.468)">
                    <g id="Group_54050" data-name="Group 54050">
                      <g id="Group_54049" data-name="Group 54049">
                        <g id="Group_54048" data-name="Group 54048">
                          <g id="Group_54047" data-name="Group 54047">
                            <g id="Group_54046" data-name="Group 54046">
                              <g id="Group_54045" data-name="Group 54045">
                                <g id="Group_54044" data-name="Group 54044">
                                  <g id="Group_54043" data-name="Group 54043">
                                    <path id="Path_55281" data-name="Path 55281" d="M279.605,133.6c-1.957-.565-5.642-4.4-5.119-6.367l3.146-11.849c.744-3.21,3.232-5.453,6.42-4.621l.29.094a5.891,5.891,0,0,1,3.914,6.966c-.66,2.778-1.424,5.839-1.913,7.285a3.8,3.8,0,0,1-4.173,2.487c-.007,0-.205.7-.454,1.6a14.5,14.5,0,0,1-2.111,4.407Z" transform="translate(-274.436 -110.587)" fill="#ffbe9d" />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54061" data-name="Group 54061" transform="translate(4.194 15.047)">
                    <g id="Group_54060" data-name="Group 54060">
                      <g id="Group_54059" data-name="Group 54059">
                        <g id="Group_54058" data-name="Group 54058">
                          <g id="Group_54057" data-name="Group 54057">
                            <g id="Group_54056" data-name="Group 54056">
                              <g id="Group_54055" data-name="Group 54055">
                                <g id="Group_54054" data-name="Group 54054">
                                  <g id="Group_54053" data-name="Group 54053">
                                    <g id="Group_54052" data-name="Group 54052">
                                      <path id="Path_55282" data-name="Path 55282" d="M302.675,198.878a7.637,7.637,0,0,1-3.54-2.428,3.926,3.926,0,0,0,3.3,3.269Z" transform="translate(-299.135 -196.45)" fill="#eb996e" />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54077" data-name="Group 54077" transform="translate(11.074 9.615)">
                    <g id="Group_54076" data-name="Group 54076">
                      <g id="Group_54075" data-name="Group 54075">
                        <g id="Group_54074" data-name="Group 54074">
                          <g id="Group_54073" data-name="Group 54073">
                            <g id="Group_54072" data-name="Group 54072">
                              <g id="Group_54071" data-name="Group 54071">
                                <g id="Group_54070" data-name="Group 54070">
                                  <g id="Group_54069" data-name="Group 54069">
                                    <g id="Group_54068" data-name="Group 54068">
                                      <g id="Group_54067" data-name="Group 54067">
                                        <g id="Group_54066" data-name="Group 54066">
                                          <g id="Group_54065" data-name="Group 54065">
                                            <g id="Group_54064" data-name="Group 54064">
                                              <g id="Group_54063" data-name="Group 54063">
                                                <g id="Group_54062" data-name="Group 54062">
                                                  <path id="Path_55283" data-name="Path 55283" d="M340.545,165.006a.452.452,0,0,1-.555.3.432.432,0,0,1-.317-.531.452.452,0,0,1,.554-.3A.432.432,0,0,1,340.545,165.006Z" transform="translate(-339.657 -164.459)" fill="#263238" />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54094" data-name="Group 54094" transform="translate(11.059 8.036)">
                    <g id="Group_54093" data-name="Group 54093">
                      <g id="Group_54092" data-name="Group 54092">
                        <g id="Group_54091" data-name="Group 54091">
                          <g id="Group_54090" data-name="Group 54090">
                            <g id="Group_54089" data-name="Group 54089">
                              <g id="Group_54088" data-name="Group 54088">
                                <g id="Group_54087" data-name="Group 54087">
                                  <g id="Group_54086" data-name="Group 54086">
                                    <g id="Group_54085" data-name="Group 54085">
                                      <g id="Group_54084" data-name="Group 54084">
                                        <g id="Group_54083" data-name="Group 54083">
                                          <g id="Group_54082" data-name="Group 54082">
                                            <g id="Group_54081" data-name="Group 54081">
                                              <g id="Group_54080" data-name="Group 54080">
                                                <g id="Group_54079" data-name="Group 54079">
                                                  <g id="Group_54078" data-name="Group 54078">
                                                    <path id="Path_55284" data-name="Path 55284" d="M341.259,155.942c-.072.039-.319-.308-.781-.456s-.876-.027-.908-.1c-.017-.033.07-.117.253-.176a1.238,1.238,0,0,1,.749.011,1.189,1.189,0,0,1,.6.433C341.278,155.808,341.294,155.925,341.259,155.942Z" transform="translate(-339.568 -155.156)" fill="#263238" />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54110" data-name="Group 54110" transform="translate(6.854 8.393)">
                    <g id="Group_54109" data-name="Group 54109">
                      <g id="Group_54108" data-name="Group 54108">
                        <g id="Group_54107" data-name="Group 54107">
                          <g id="Group_54106" data-name="Group 54106">
                            <g id="Group_54105" data-name="Group 54105">
                              <g id="Group_54104" data-name="Group 54104">
                                <g id="Group_54103" data-name="Group 54103">
                                  <g id="Group_54102" data-name="Group 54102">
                                    <g id="Group_54101" data-name="Group 54101">
                                      <g id="Group_54100" data-name="Group 54100">
                                        <g id="Group_54099" data-name="Group 54099">
                                          <g id="Group_54098" data-name="Group 54098">
                                            <g id="Group_54097" data-name="Group 54097">
                                              <g id="Group_54096" data-name="Group 54096">
                                                <g id="Group_54095" data-name="Group 54095">
                                                  <path id="Path_55285" data-name="Path 55285" d="M315.688,157.808a.452.452,0,0,1-.555.3.432.432,0,0,1-.317-.531.452.452,0,0,1,.554-.3A.432.432,0,0,1,315.688,157.808Z" transform="translate(-314.8 -157.261)" fill="#263238" />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54127" data-name="Group 54127" transform="translate(6.494 6.673)">
                    <g id="Group_54126" data-name="Group 54126">
                      <g id="Group_54125" data-name="Group 54125">
                        <g id="Group_54124" data-name="Group 54124">
                          <g id="Group_54123" data-name="Group 54123">
                            <g id="Group_54122" data-name="Group 54122">
                              <g id="Group_54121" data-name="Group 54121">
                                <g id="Group_54120" data-name="Group 54120">
                                  <g id="Group_54119" data-name="Group 54119">
                                    <g id="Group_54118" data-name="Group 54118">
                                      <g id="Group_54117" data-name="Group 54117">
                                        <g id="Group_54116" data-name="Group 54116">
                                          <g id="Group_54115" data-name="Group 54115">
                                            <g id="Group_54114" data-name="Group 54114">
                                              <g id="Group_54113" data-name="Group 54113">
                                                <g id="Group_54112" data-name="Group 54112">
                                                  <g id="Group_54111" data-name="Group 54111">
                                                    <path id="Path_55286" data-name="Path 55286" d="M314.372,147.918c-.071.039-.319-.308-.781-.456s-.876-.027-.908-.1c-.016-.033.07-.117.253-.176a1.238,1.238,0,0,1,.749.011,1.191,1.191,0,0,1,.6.433C314.391,147.785,314.406,147.9,314.372,147.918Z" transform="translate(-312.681 -147.133)" fill="#263238" />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54143" data-name="Group 54143" transform="translate(8.691 7.628)">
                    <g id="Group_54142" data-name="Group 54142">
                      <g id="Group_54141" data-name="Group 54141">
                        <g id="Group_54140" data-name="Group 54140">
                          <g id="Group_54139" data-name="Group 54139">
                            <g id="Group_54138" data-name="Group 54138">
                              <g id="Group_54137" data-name="Group 54137">
                                <g id="Group_54136" data-name="Group 54136">
                                  <g id="Group_54135" data-name="Group 54135">
                                    <g id="Group_54134" data-name="Group 54134">
                                      <g id="Group_54133" data-name="Group 54133">
                                        <g id="Group_54132" data-name="Group 54132">
                                          <g id="Group_54131" data-name="Group 54131">
                                            <g id="Group_54130" data-name="Group 54130">
                                              <g id="Group_54129" data-name="Group 54129">
                                                <g id="Group_54128" data-name="Group 54128">
                                                  <path id="Path_55287" data-name="Path 55287" d="M325.621,156.677a3.219,3.219,0,0,1,.794.108c.122.026.241.039.287-.035a.633.633,0,0,0,.035-.375q-.028-.482-.058-1.012a16.228,16.228,0,0,1-.021-2.6,16.254,16.254,0,0,1,.231,2.6q.022.53.041,1.012a.718.718,0,0,1-.089.483.311.311,0,0,1-.25.11.844.844,0,0,1-.209-.037A3.163,3.163,0,0,1,325.621,156.677Z" transform="translate(-325.621 -152.758)" fill="#263238" />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54155" data-name="Group 54155" transform="translate(6.612 4.775)">
                    <g id="Group_54154" data-name="Group 54154">
                      <g id="Group_54153" data-name="Group 54153">
                        <g id="Group_54152" data-name="Group 54152">
                          <g id="Group_54151" data-name="Group 54151">
                            <g id="Group_54150" data-name="Group 54150">
                              <g id="Group_54149" data-name="Group 54149">
                                <g id="Group_54148" data-name="Group 54148">
                                  <g id="Group_54147" data-name="Group 54147">
                                    <g id="Group_54146" data-name="Group 54146">
                                      <g id="Group_54145" data-name="Group 54145">
                                        <g id="Group_54144" data-name="Group 54144">
                                          <path id="Path_55288" data-name="Path 55288" d="M315.469,136.9c-.1.1-.509-.172-1.052-.361s-1.025-.252-1.041-.392c-.005-.066.121-.142.345-.175a1.932,1.932,0,0,1,1.594.573C315.466,136.712,315.515,136.851,315.469,136.9Z" transform="translate(-313.377 -135.956)" fill="#263238" />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54167" data-name="Group 54167" transform="translate(11.146 6.854)">
                    <g id="Group_54166" data-name="Group 54166">
                      <g id="Group_54165" data-name="Group 54165">
                        <g id="Group_54164" data-name="Group 54164">
                          <g id="Group_54163" data-name="Group 54163">
                            <g id="Group_54162" data-name="Group 54162">
                              <g id="Group_54161" data-name="Group 54161">
                                <g id="Group_54160" data-name="Group 54160">
                                  <g id="Group_54159" data-name="Group 54159">
                                    <g id="Group_54158" data-name="Group 54158">
                                      <g id="Group_54157" data-name="Group 54157">
                                        <g id="Group_54156" data-name="Group 54156">
                                          <path id="Path_55289" data-name="Path 55289" d="M341.925,149.048c-.129.076-.473-.15-.926-.3s-.861-.188-.916-.325c-.022-.065.071-.152.27-.2a1.53,1.53,0,0,1,.809.06,1.506,1.506,0,0,1,.676.44C341.964,148.884,341.983,149.009,341.925,149.048Z" transform="translate(-340.079 -148.196)" fill="#263238" />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54175" data-name="Group 54175" transform="translate(0.146 7.166)">
                    <g id="Group_54174" data-name="Group 54174">
                      <g id="Group_54173" data-name="Group 54173">
                        <g id="Group_54172" data-name="Group 54172">
                          <g id="Group_54171" data-name="Group 54171">
                            <g id="Group_54170" data-name="Group 54170">
                              <g id="Group_54169" data-name="Group 54169">
                                <g id="Group_54168" data-name="Group 54168">
                                  <path id="Path_55290" data-name="Path 55290" d="M277.868,150.438c-.044-.039-1.868-1.281-2.47.8a1.626,1.626,0,0,0,1.629,2.194C277.048,153.369,277.868,150.438,277.868,150.438Z" transform="translate(-275.295 -150.034)" fill="#ffbe9d" />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54184" data-name="Group 54184" transform="translate(0.717 7.818)">
                    <g id="Group_54183" data-name="Group 54183">
                      <g id="Group_54182" data-name="Group 54182">
                        <g id="Group_54181" data-name="Group 54181">
                          <g id="Group_54180" data-name="Group 54180">
                            <g id="Group_54179" data-name="Group 54179">
                              <g id="Group_54178" data-name="Group 54178">
                                <g id="Group_54177" data-name="Group 54177">
                                  <g id="Group_54176" data-name="Group 54176">
                                    <path id="Path_55291" data-name="Path 55291" d="M279.413,155.645c-.007-.009-.043.015-.113.025a.376.376,0,0,1-.272-.073.932.932,0,0,1-.182-.923,1.244,1.244,0,0,1,.251-.489.436.436,0,0,1,.353-.2.192.192,0,0,1,.185.161c.011.066-.014.1,0,.111s.055-.022.063-.113a.241.241,0,0,0-.044-.154.285.285,0,0,0-.185-.111.529.529,0,0,0-.471.213,1.279,1.279,0,0,0-.293.543.978.978,0,0,0,.268,1.05.4.4,0,0,0,.347.041C279.4,155.694,279.419,155.651,279.413,155.645Z" transform="translate(-278.658 -153.874)" fill="#eb996e" />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_54192" data-name="Group 54192" transform="translate(2.127)">
                    <g id="Group_54191" data-name="Group 54191">
                      <g id="Group_54190" data-name="Group 54190">
                        <g id="Group_54189" data-name="Group 54189">
                          <g id="Group_54188" data-name="Group 54188">
                            <g id="Group_54187" data-name="Group 54187">
                              <g id="Group_54186" data-name="Group 54186">
                                <g id="Group_54185" data-name="Group 54185">
                                  <path id="Path_55292" data-name="Path 55292" d="M298.274,111.7c.389-.092-.317-1.466-1.04-2.074a7.684,7.684,0,0,0-4.153-1.8,5.211,5.211,0,0,0-3.9,1.443,8.507,8.507,0,0,0-2.09,3.925,2.583,2.583,0,0,0-.063,1.354c.162.542.639.988.437,1.616a.9.9,0,0,0,.038.7c.267.512.84.262,1.161-.06a6.258,6.258,0,0,0,1.312-2.57,6.323,6.323,0,0,0,.423-2.018c0-.376-.077-.749-.06-1.125a1.3,1.3,0,0,1,.439-1,1.362,1.362,0,0,1,.217-.124,2.255,2.255,0,0,1,2.27.193c1.057.743,2.886,1.923,3.521,1.672a3.329,3.329,0,0,0,1.2-.685Z" transform="translate(-286.961 -107.832)" fill="#263238" />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_54202" data-name="Group 54202" transform="translate(7.245 11.439)">
                <g id="Group_54201" data-name="Group 54201">
                  <g id="Group_54200" data-name="Group 54200">
                    <g id="Group_54199" data-name="Group 54199">
                      <g id="Group_54198" data-name="Group 54198">
                        <g id="Group_54197" data-name="Group 54197">
                          <g id="Group_54196" data-name="Group 54196">
                            <g id="Group_54195" data-name="Group 54195">
                              <path id="Path_55293" data-name="Path 55293" d="M317.2,175.2c-.029,0-.078.1-.088.3a1.208,1.208,0,0,0,.205.717,2,2,0,0,0,.509.522c.15.107.254.155.274.132s-.047-.117-.166-.251a4.511,4.511,0,0,1-.426-.526C317.213,175.667,317.267,175.208,317.2,175.2Z" transform="translate(-317.105 -175.203)" fill="#263238" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54212" data-name="Group 54212" transform="translate(5.691 0.245)">
              <g id="Group_54207" data-name="Group 54207" transform="translate(0.565 8.229)">
                <g id="Group_54206" data-name="Group 54206">
                  <g id="Group_54205" data-name="Group 54205">
                    <g id="Group_54204" data-name="Group 54204">
                      <path id="Path_55294" data-name="Path 55294" d="M290.2,154.092c0,.042,0,.085,0,.132C290.2,154.224,290.2,154.176,290.2,154.092Z" transform="translate(-290.195 -154.092)" fill="#ff725e" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_54211" data-name="Group 54211">
                <g id="Group_54210" data-name="Group 54210">
                  <g id="Group_54209" data-name="Group 54209">
                    <g id="Group_54208" data-name="Group 54208">
                      <path id="Path_55295" data-name="Path 55295" d="M286.88,113.272s.516-.3.554.587c-.009-1.145.2-9.408,8.121-7.2,0,0-2.557-1.526-4.762-.854C290.793,105.8,286.627,106.411,286.88,113.272Z" transform="translate(-286.869 -105.63)" fill="#455a64" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54217" data-name="Group 54217" transform="translate(10.885 15.87)">
              <g id="Group_54216" data-name="Group 54216">
                <g id="Group_54215" data-name="Group 54215">
                  <g id="Group_54214" data-name="Group 54214">
                    <g id="Group_54213" data-name="Group 54213">
                      <path id="Path_55296" data-name="Path 55296" d="M317.464,198.372c.017.032.239-.063.445-.271s.3-.43.268-.447-.171.159-.364.352S317.444,198.339,317.464,198.372Z" transform="translate(-317.462 -197.652)" fill="#263238" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54222" data-name="Group 54222" transform="translate(10.453 15.577)">
              <g id="Group_54221" data-name="Group 54221">
                <g id="Group_54220" data-name="Group 54220">
                  <g id="Group_54219" data-name="Group 54219">
                    <g id="Group_54218" data-name="Group 54218">
                      <path id="Path_55297" data-name="Path 55297" d="M314.918,196.731c.028.025.21-.135.406-.357s.333-.422.305-.447-.21.135-.406.357S314.891,196.706,314.918,196.731Z" transform="translate(-314.915 -195.924)" fill="#263238" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54227" data-name="Group 54227" transform="translate(10.183 15.196)">
              <g id="Group_54226" data-name="Group 54226">
                <g id="Group_54225" data-name="Group 54225">
                  <g id="Group_54224" data-name="Group 54224">
                    <g id="Group_54223" data-name="Group 54223">
                      <path id="Path_55298" data-name="Path 55298" d="M313.339,194.594c.035.012.135-.183.222-.435s.13-.467.094-.479-.134.183-.222.435S313.3,194.582,313.339,194.594Z" transform="translate(-313.326 -193.68)" fill="#263238" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54232" data-name="Group 54232" transform="translate(9.703 14.933)">
              <g id="Group_54231" data-name="Group 54231">
                <g id="Group_54230" data-name="Group 54230">
                  <g id="Group_54229" data-name="Group 54229">
                    <g id="Group_54228" data-name="Group 54228">
                      <path id="Path_55299" data-name="Path 55299" d="M310.6,193.233c.038-.009,0-.258.053-.556s.161-.522.128-.543-.208.191-.261.521A.727.727,0,0,0,310.6,193.233Z" transform="translate(-310.5 -192.133)" fill="#263238" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54237" data-name="Group 54237" transform="translate(9.158 14.619)">
              <g id="Group_54236" data-name="Group 54236">
                <g id="Group_54235" data-name="Group 54235">
                  <g id="Group_54234" data-name="Group 54234">
                    <g id="Group_54233" data-name="Group 54233">
                      <path id="Path_55300" data-name="Path 55300" d="M307.378,191.366a25.924,25.924,0,0,1,.13-1.081c-.032-.016-.168.208-.2.524A.784.784,0,0,0,307.378,191.366Z" transform="translate(-307.293 -190.284)" fill="#263238" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_54239" data-name="Group 54239" transform="translate(16.37 42.687)">
            <path id="Path_55301" data-name="Path 55301" d="M288.921,355.589a.3.3,0,0,1-.064.053l-.194.138c-.169.119-.417.286-.726.488-.618.405-1.483.948-2.458,1.515s-1.876,1.049-2.534,1.385c-.329.168-.6.3-.784.389l-.216.1a.3.3,0,0,1-.078.03.3.3,0,0,1,.07-.045l.208-.115.77-.414c.65-.349,1.545-.84,2.519-1.406s1.843-1.1,2.469-1.491l.741-.465.2-.124A.3.3,0,0,1,288.921,355.589Z" transform="translate(-281.867 -355.589)" fill="#263238" />
          </g>
          <g id="Group_54240" data-name="Group 54240" transform="translate(30.434 38.838)">
            <path id="Path_55302" data-name="Path 55302" d="M366.808,332.921a6.3,6.3,0,0,0-2.115,1.991,1.545,1.545,0,0,1,.206-.382,4.522,4.522,0,0,1,1.515-1.427A1.544,1.544,0,0,1,366.808,332.921Z" transform="translate(-364.692 -332.92)" fill="#263238" />
          </g>
        </g>
        <g id="Character_3" data-name="Character 3" transform="translate(68.079 122.257)">
          <g id="Group_54297" data-name="Group 54297" transform="translate(0 0)">
            <path id="Path_55303" data-name="Path 55303" d="M315.116,319.941s-6.033.714-8.263,2.5a7.628,7.628,0,0,0-.98.953,10.233,10.233,0,0,0-2.427,5.8l-.808,8.873.065,1.465a4.29,4.29,0,0,0,4.272,4.1l24.929.174a12,12,0,0,0,2.417-9.322c-.2-1.334-.525-5.906-1.788-8.718a6.392,6.392,0,0,0-4.389-4.042l-6.3-1.78Z" transform="translate(-302.637 -302.539)" fill="#fae46c" />
            <g id="Group_54241" data-name="Group 54241" transform="translate(4.787 19.899)">
              <path id="Path_55304" data-name="Path 55304" d="M332.869,339.37a.9.9,0,0,1-.052-.2c-.027-.129-.069-.314-.125-.541a11.141,11.141,0,0,0-1.444-3.344c-.126-.2-.232-.355-.307-.462a.929.929,0,0,1-.109-.174.893.893,0,0,1,.136.153c.084.1.2.254.332.448a9.59,9.59,0,0,1,1.453,3.364c.049.231.082.418.1.549A.924.924,0,0,1,332.869,339.37Z" transform="translate(-330.832 -334.651)" fill="#455a64" />
            </g>
            <g id="Group_54242" data-name="Group 54242" transform="translate(6.767 26.064)">
              <path id="Path_55305" data-name="Path 55305" d="M343.593,379.63c-.024,0-.291-1.936-.6-4.332s-.532-4.34-.508-4.343.291,1.936.6,4.332S343.617,379.627,343.593,379.63Z" transform="translate(-342.488 -370.956)" fill="#455a64" />
            </g>
            <g id="Group_54243" data-name="Group 54243" transform="translate(4.215 23.582)">
              <path id="Path_55306" data-name="Path 55306" d="M330.016,358.139a1.947,1.947,0,0,1-.424-.18,7.687,7.687,0,0,1-1.815-1.278,1.93,1.93,0,0,1-.313-.339,3.049,3.049,0,0,1,.354.293c.215.184.518.431.87.679s.687.45.933.59A3.049,3.049,0,0,1,330.016,358.139Z" transform="translate(-327.463 -356.342)" fill="#455a64" />
            </g>
            <g id="Group_54244" data-name="Group 54244" transform="translate(22.918 20.574)">
              <path id="Path_55307" data-name="Path 55307" d="M437.613,345.072a1.974,1.974,0,0,1,0-.3,3.191,3.191,0,0,1,.023-.355c.016-.138.026-.3.06-.467s.061-.361.114-.56.112-.41.179-.631a7.9,7.9,0,0,1,1.468-2.608c.154-.173.3-.34.447-.481s.287-.274.42-.388.259-.209.369-.293a3.162,3.162,0,0,1,.291-.2,1.937,1.937,0,0,1,.259-.156,2,2,0,0,1-.24.185c-.08.056-.176.126-.281.215s-.232.181-.358.3-.27.243-.409.392-.286.309-.436.482a8.2,8.2,0,0,0-1.453,2.582c-.069.218-.14.424-.186.623s-.091.383-.123.553-.052.326-.072.462-.032.255-.038.352A1.971,1.971,0,0,1,437.613,345.072Z" transform="translate(-437.608 -338.625)" fill="#455a64" />
            </g>
            <g id="Group_54245" data-name="Group 54245" transform="translate(20.416 28.155)">
              <path id="Path_55308" data-name="Path 55308" d="M422.9,392.328a.914.914,0,0,1-.019-.313.943.943,0,0,1,.447-.73l-.007.005a7.681,7.681,0,0,1,2.555-1.779l-.026.035c.035-.319.07-.651.1-.991a29.357,29.357,0,0,0,.051-3.737c-.022-.478-.056-.864-.075-1.131l-.025-.308a.505.505,0,0,1,0-.108.492.492,0,0,1,.021.106l.042.306c.03.266.073.652.1,1.131a26.147,26.147,0,0,1-.025,3.748c-.025.341-.061.674-.1.995l0,.025-.023.01a7.925,7.925,0,0,0-2.549,1.737l0,0,0,0a.9.9,0,0,0-.331.341.936.936,0,0,0-.112.343C422.887,392.217,422.91,392.328,422.9,392.328Z" transform="translate(-422.878 -383.272)" fill="#455a64" />
            </g>
            <g id="Group_54246" data-name="Group 54246" transform="translate(23.433 34.338)">
              <path id="Path_55309" data-name="Path 55309" d="M444.759,421.842c-.014.008-.144-.236-.427-.576A4.379,4.379,0,0,0,443,420.194a4.181,4.181,0,0,0-.88-.343,3.251,3.251,0,0,0-.764-.1c-.441,0-.714.051-.716.034a.746.746,0,0,1,.189-.046,3.507,3.507,0,0,1,.527-.051,3.058,3.058,0,0,1,.784.084,4,4,0,0,1,.9.343,4.112,4.112,0,0,1,1.339,1.112,3.553,3.553,0,0,1,.3.439A.736.736,0,0,1,444.759,421.842Z" transform="translate(-440.641 -419.687)" fill="#455a64" />
            </g>
            <g id="Group_54247" data-name="Group 54247" transform="translate(23.578 33.79)">
              <path id="Path_55310" data-name="Path 55310" d="M445.349,416.841c-.007.015-.208-.079-.552-.165a4.447,4.447,0,0,0-.612-.106,5.977,5.977,0,0,0-.761-.019,6.753,6.753,0,0,0-1.369.209c-.344.089-.552.165-.557.151a.543.543,0,0,1,.14-.069,3.906,3.906,0,0,1,.4-.142,5.67,5.67,0,0,1,1.382-.237,5.278,5.278,0,0,1,.774.026,3.841,3.841,0,0,1,.62.126,2.175,2.175,0,0,1,.23.077,1.328,1.328,0,0,1,.169.071A.535.535,0,0,1,445.349,416.841Z" transform="translate(-441.497 -416.459)" fill="#455a64" />
            </g>
            <g id="Group_54267" data-name="Group 54267" transform="translate(7.663)">
              <g id="Group_54262" data-name="Group 54262" transform="translate(0 0.328)">
                <path id="Path_55311" data-name="Path 55311" d="M365.635,226.4a9.324,9.324,0,0,0-3.137-5.554,9.04,9.04,0,0,0-5.763-1.435,6.847,6.847,0,0,0-2.822,1.04c-1.115.68-1.553,2-2.063,3.2-1.021,2.408-.777,5.617,0,6.486l10.086,4.914c1.725-.971,3.6-2.278,4-4.216A11.431,11.431,0,0,0,365.635,226.4Z" transform="translate(-350.594 -219.385)" fill="#092945" />
                <g id="Group_54258" data-name="Group 54258" transform="translate(1.24 1.615)">
                  <path id="Path_55312" data-name="Path 55312" d="M365.053,230.551l-6.3-1.638c-2.02-.228-3.38,2.252-3.522,4.279a36.124,36.124,0,0,0-.005,6.692,5.012,5.012,0,0,0,3.1,4.188c0-.011.006,2.939.018,3.944l2.938,8.951,4.18-8.951.643-16.252A1.189,1.189,0,0,0,365.053,230.551Z" transform="translate(-355.071 -228.898)" fill="#ffbe9d" />
                  <g id="Group_54248" data-name="Group 54248" transform="translate(1.285 6.634)">
                    <path id="Path_55313" data-name="Path 55313" d="M362.639,268.355a.434.434,0,0,0,.415.44.415.415,0,0,0,.45-.388.434.434,0,0,0-.415-.44A.415.415,0,0,0,362.639,268.355Z" transform="translate(-362.639 -267.966)" fill="#263238" />
                  </g>
                  <g id="Group_54249" data-name="Group 54249" transform="translate(0.741 5.282)">
                    <path id="Path_55314" data-name="Path 55314" d="M359.444,260.457c.054.057.384-.184.85-.18s.807.24.858.183c.025-.025-.029-.128-.177-.237a1.19,1.19,0,0,0-.686-.217,1.143,1.143,0,0,0-.677.213C359.467,260.33,359.418,260.431,359.444,260.457Z" transform="translate(-359.438 -260.007)" fill="#263238" />
                  </g>
                  <g id="Group_54250" data-name="Group 54250" transform="translate(5.519 6.634)">
                    <path id="Path_55315" data-name="Path 55315" d="M387.575,268.355a.434.434,0,0,0,.415.44.415.415,0,0,0,.45-.388.434.434,0,0,0-.415-.441A.415.415,0,0,0,387.575,268.355Z" transform="translate(-387.575 -267.966)" fill="#263238" />
                  </g>
                  <g id="Group_54251" data-name="Group 54251" transform="translate(5.327 5.401)">
                    <path id="Path_55316" data-name="Path 55316" d="M386.452,261.154c.054.057.384-.184.85-.179s.807.24.858.183c.025-.025-.029-.128-.178-.237a1.188,1.188,0,0,0-.686-.217,1.143,1.143,0,0,0-.677.213C386.476,261.027,386.426,261.128,386.452,261.154Z" transform="translate(-386.446 -260.704)" fill="#263238" />
                  </g>
                  <g id="Group_54252" data-name="Group 54252" transform="translate(2.431 5.413)">
                    <path id="Path_55317" data-name="Path 55317" d="M370.572,264.664a3.088,3.088,0,0,0-.757-.142c-.119-.013-.232-.037-.251-.119a.606.606,0,0,1,.082-.353l.359-.9a15.59,15.59,0,0,0,.807-2.368,15.657,15.657,0,0,0-1,2.3l-.344.91a.69.69,0,0,0-.065.467.3.3,0,0,0,.195.176.818.818,0,0,0,.2.029A3.039,3.039,0,0,0,370.572,264.664Z" transform="translate(-369.386 -260.777)" fill="#263238" />
                  </g>
                  <path id="Path_55318" data-name="Path 55318" d="M374.217,312.66l.019.842a4.325,4.325,0,0,0,4.517-2.019A8.6,8.6,0,0,1,374.217,312.66Z" transform="translate(-370.966 -297.46)" fill="#eb996e" />
                  <path id="Path_55319" data-name="Path 55319" d="M377.708,287.17a.75.75,0,0,0-.621-.344.974.974,0,0,0-.891.434.606.606,0,0,0-.042.437c.032.074.007.038.064.089a1.7,1.7,0,0,0,.694.437.732.732,0,0,0,.775-.279A.747.747,0,0,0,377.708,287.17Z" transform="translate(-372.555 -276.99)" fill="#eb996e" />
                  <g id="Group_54253" data-name="Group 54253" transform="translate(3.492 9.631)">
                    <path id="Path_55320" data-name="Path 55320" d="M377.135,285.622c-.076,0-.08.5-.516.858s-.975.3-.979.368c-.007.032.12.1.347.107a1.261,1.261,0,0,0,.817-.278,1.1,1.1,0,0,0,.4-.72C377.225,285.745,377.17,285.619,377.135,285.622Z" transform="translate(-375.639 -285.622)" fill="#263238" />
                  </g>
                  <g id="Group_54254" data-name="Group 54254" transform="translate(5.123 4.012)">
                    <path id="Path_55321" data-name="Path 55321" d="M385.243,252.869c.045.127.511.07,1.058.14s.989.222,1.062.109c.033-.054-.043-.174-.221-.3a1.858,1.858,0,0,0-1.616-.189C385.325,252.709,385.223,252.808,385.243,252.869Z" transform="translate(-385.24 -252.525)" fill="#092945" />
                  </g>
                  <g id="Group_54255" data-name="Group 54255" transform="translate(0.917 3.643)">
                    <path id="Path_55322" data-name="Path 55322" d="M360.485,250.83c.081.106.4,0,.79,0s.713.076.789-.034c.033-.054-.017-.16-.16-.261a1.144,1.144,0,0,0-1.273.031C360.494,250.669,360.449,250.778,360.485,250.83Z" transform="translate(-360.473 -250.352)" fill="#092945" />
                  </g>
                  <g id="Group_54256" data-name="Group 54256" transform="translate(10.083 6.88)">
                    <path id="Path_55323" data-name="Path 55323" d="M414.5,269.516c.047-.022,1.883-.6,1.866,1.283a1.419,1.419,0,0,1-1.911,1.427C414.449,272.172,414.5,269.516,414.5,269.516Z" transform="translate(-414.452 -269.415)" fill="#ffbe9d" />
                  </g>
                  <g id="Group_54257" data-name="Group 54257" transform="translate(10.664 7.491)">
                    <path id="Path_55324" data-name="Path 55324" d="M417.876,274.507c.008-.006.033.023.088.049a.329.329,0,0,0,.246.007.814.814,0,0,0,.383-.727,1.088,1.088,0,0,0-.088-.471.38.38,0,0,0-.245-.255.168.168,0,0,0-.195.088c-.026.052-.014.09-.024.093s-.04-.032-.024-.11a.21.21,0,0,1,.075-.118.249.249,0,0,1,.182-.046.462.462,0,0,1,.341.3,1.117,1.117,0,0,1,.109.527.854.854,0,0,1-.487.811.352.352,0,0,1-.3-.053C417.88,274.552,417.87,274.51,417.876,274.507Z" transform="translate(-417.875 -273.017)" fill="#eb996e" />
                  </g>
                </g>
                <g id="Group_54259" data-name="Group 54259" transform="translate(11.844 11.06)">
                  <path id="Path_55325" data-name="Path 55325" d="M418.211,284.689c.009-.009.082.08.071.248a.351.351,0,0,1-.134.264.391.391,0,0,1-.362.026.383.383,0,0,1-.251-.262.4.4,0,0,1,.052-.29c.094-.146.213-.16.211-.149s-.089.051-.152.182a.346.346,0,0,0-.025.236.306.306,0,0,0,.2.194.317.317,0,0,0,.278-.009.3.3,0,0,0,.116-.2A1.018,1.018,0,0,0,418.211,284.689Z" transform="translate(-417.527 -284.524)" fill="#263238" />
                </g>
                <path id="Path_55326" data-name="Path 55326" d="M353.832,230.1a2.949,2.949,0,0,1,1.273-1.245c.524-.3,1.092-.516,1.614-.818a5.67,5.67,0,0,0,2.536-3.172,3.4,3.4,0,0,0-3.022.039,4.615,4.615,0,0,0-2.088,2.256,7.233,7.233,0,0,0-.576,3.057" transform="translate(-352.583 -223.66)" fill="#092945" />
                <g id="Group_54260" data-name="Group 54260" transform="translate(5.949 1.103)">
                  <path id="Path_55327" data-name="Path 55327" d="M388.66,227.54a6.45,6.45,0,0,0-5.852-1.437,10.727,10.727,0,0,0,2.524,4.591c1.062,1.226,1.7,3.375,4.011,2.645.159-.05.341.095.485.01a.591.591,0,0,0,.223-.35A5.413,5.413,0,0,0,388.66,227.54Z" transform="translate(-382.808 -225.879)" fill="#092945" />
                </g>
                <g id="Group_54261" data-name="Group 54261" transform="translate(0 1.828)">
                  <path id="Path_55328" data-name="Path 55328" d="M349.123,237.062a.7.7,0,0,1-.332-.019,1.3,1.3,0,0,1-.725-.534,1.981,1.981,0,0,1-.286-1.3,4.854,4.854,0,0,1,.133-.784,8.246,8.246,0,0,1,.238-.817,8.329,8.329,0,0,1,1.47-2.582,7.64,7.64,0,0,1,.62-.656l.185-.162a.293.293,0,0,1,.069-.051,2.239,2.239,0,0,1-.228.24c-.144.155-.359.375-.594.673a8.774,8.774,0,0,0-1.432,2.571c-.092.273-.176.543-.237.808a4.945,4.945,0,0,0-.136.769,1.938,1.938,0,0,0,.255,1.259,1.3,1.3,0,0,0,.679.536A1.712,1.712,0,0,0,349.123,237.062Z" transform="translate(-347.77 -230.152)" fill="#fff" />
                </g>
              </g>
              <path id="Path_55329" data-name="Path 55329" d="M421.017,220.759a3.623,3.623,0,0,0-.853-2.452,2.907,2.907,0,0,0-3.389-.531,3.292,3.292,0,0,0-1.672,3.062,3.8,3.8,0,0,0,1.9,2.985l.778-.088a2.09,2.09,0,0,0,2.407-.526A3.538,3.538,0,0,0,421.017,220.759Z" transform="translate(-403.666 -217.456)" fill="#092945" />
              <g id="Group_54263" data-name="Group 54263" transform="translate(6.399 2.442)">
                <path id="Path_55330" data-name="Path 55330" d="M391.653,232.182a.244.244,0,0,1-.04.064,2.133,2.133,0,0,1-.13.17,3.415,3.415,0,0,1-.573.541,3.775,3.775,0,0,1-1.031.551,3.605,3.605,0,0,1-2.8-.154,3.773,3.773,0,0,1-.965-.661,3.414,3.414,0,0,1-.51-.6,2.125,2.125,0,0,1-.111-.183.243.243,0,0,1-.032-.067,5.181,5.181,0,0,0,.7.8,3.869,3.869,0,0,0,.957.633,3.607,3.607,0,0,0,2.741.151,3.869,3.869,0,0,0,1.021-.524A5.159,5.159,0,0,0,391.653,232.182Z" transform="translate(-385.458 -231.841)" fill="#fff" />
              </g>
              <g id="Group_54264" data-name="Group 54264" transform="translate(6.393 2.558)">
                <path id="Path_55331" data-name="Path 55331" d="M392.526,235.317a.347.347,0,0,1-.063.064,1.962,1.962,0,0,1-.2.167,2.958,2.958,0,0,1-.842.441,3.756,3.756,0,0,1-1.4.188,4.32,4.32,0,0,1-1.671-.43,5.477,5.477,0,0,1-1.4-1.012,7.184,7.184,0,0,1-.9-1.086,8.537,8.537,0,0,1-.491-.812c-.054-.1-.09-.177-.116-.23a.344.344,0,0,1-.034-.083.349.349,0,0,1,.05.075l.13.222c.113.193.282.468.512.793a7.542,7.542,0,0,0,.9,1.064,5.538,5.538,0,0,0,1.38.991,4.329,4.329,0,0,0,1.636.428,3.794,3.794,0,0,0,1.373-.167,3.155,3.155,0,0,0,.841-.41C392.427,235.393,392.52,235.31,392.526,235.317Z" transform="translate(-385.419 -232.524)" fill="#fff" />
              </g>
              <g id="Group_54265" data-name="Group 54265" transform="translate(13.393 2.471)">
                <path id="Path_55332" data-name="Path 55332" d="M429.848,232.008s-.024.045-.081.116a2.281,2.281,0,0,1-.266.277,3.09,3.09,0,0,1-.458.341,3.289,3.289,0,0,1-1.314.457,3.1,3.1,0,0,1-.57.017,2.279,2.279,0,0,1-.38-.052.426.426,0,0,1-.135-.041c0-.016.2.024.518.03a3.542,3.542,0,0,0,2.3-.8C429.707,232.151,429.836,232,429.848,232.008Z" transform="translate(-426.645 -232.008)" fill="#fff" />
              </g>
              <g id="Group_54266" data-name="Group 54266" transform="translate(14.037 3.655)">
                <path id="Path_55333" data-name="Path 55333" d="M433.7,238.982a.275.275,0,0,1,.024.153.724.724,0,0,1-.146.4,1.245,1.245,0,0,1-.5.378,3.756,3.756,0,0,1-.72.24,3.862,3.862,0,0,1-1.371.058,3.167,3.167,0,0,1-.408-.083.52.52,0,0,1-.144-.053c0-.015.213.042.559.074a4.3,4.3,0,0,0,1.344-.083,2.19,2.19,0,0,0,1.188-.569.748.748,0,0,0,.161-.361C433.7,239.037,433.691,238.983,433.7,238.982Z" transform="translate(-430.437 -238.982)" fill="#fff" />
              </g>
            </g>
            <g id="Group_54268" data-name="Group 54268" transform="translate(14.002 32.907)">
              <path id="Path_55334" data-name="Path 55334" d="M391.793,416.51a.375.375,0,0,1-.093.009c-.061,0-.152.008-.269,0a6.122,6.122,0,0,1-.985-.1,6.627,6.627,0,0,1-4.945-3.88,6.118,6.118,0,0,1-.327-.934c-.032-.112-.046-.2-.06-.262a.374.374,0,0,1-.014-.093c.007,0,.039.123.107.346a7.154,7.154,0,0,0,.35.917,6.763,6.763,0,0,0,4.9,3.845,7.136,7.136,0,0,0,.974.122C391.664,416.5,391.793,416.5,391.793,416.51Z" transform="translate(-385.1 -411.259)" fill="#455a64" />
            </g>
            <g id="Group_54269" data-name="Group 54269" transform="translate(5.17 34.442)">
              <path id="Path_55335" data-name="Path 55335" d="M340.662,423.468a.307.307,0,0,1-.075-.041l-.2-.132-.746-.5a21.82,21.82,0,0,0-2.542-1.488,9.877,9.877,0,0,0-2.792-.892,7.755,7.755,0,0,0-.888-.082,2.925,2.925,0,0,1-.328-.014.315.315,0,0,1,.085-.01c.055,0,.138-.011.244-.01a6.324,6.324,0,0,1,.9.055,9.33,9.33,0,0,1,2.822.874,19.08,19.08,0,0,1,2.538,1.516c.311.215.56.394.73.519l.2.146A.318.318,0,0,1,340.662,423.468Z" transform="translate(-333.087 -420.302)" fill="#455a64" />
            </g>
            <path id="Path_55336" data-name="Path 55336" d="M371.306,436.356l-11.591.587.9,3.413,15.449.072Z" transform="translate(-350.024 -399.188)" fill="#ffbe9d" />
            <g id="Group_54296" data-name="Group 54296" transform="translate(2.071 35.067)">
              <g id="Group_54274" data-name="Group 54274">
                <g id="Group_54271" data-name="Group 54271" transform="translate(0 0.774)">
                  <g id="Group_54270" data-name="Group 54270">
                    <path id="Path_55337" data-name="Path 55337" d="M322.994,430.426a40.313,40.313,0,0,0-4.209-1.887c-.355-.012-2.414.337-3.36.5a.687.687,0,0,0-.581.763.389.389,0,0,0,.017.066.626.626,0,0,0,.523.4.728.728,0,0,0-.4.753c.074.537.739.551.739.551a.918.918,0,0,0-.08.1.647.647,0,0,0,.477,1.025l.3.022a.641.641,0,0,0-.309.592c.118.7,1.725.6,1.725.6l5.512-.039Z" transform="translate(-314.836 -428.538)" fill="#ffbe9d" />
                  </g>
                </g>
                <g id="Group_54273" data-name="Group 54273" transform="translate(0.818)">
                  <g id="Group_54272" data-name="Group 54272">
                    <path id="Path_55338" data-name="Path 55338" d="M319.664,424.366a1.157,1.157,0,0,0,.612.88l2.171-.418a8.785,8.785,0,0,1-1.986-.556,1.9,1.9,0,0,0-.358-.258A.312.312,0,0,0,319.664,424.366Z" transform="translate(-319.656 -423.98)" fill="#ffbe9d" />
                  </g>
                </g>
              </g>
              <g id="Group_54277" data-name="Group 54277" transform="translate(0.586 0.968)">
                <g id="Group_54276" data-name="Group 54276">
                  <g id="Group_54275" data-name="Group 54275">
                    <path id="Path_55339" data-name="Path 55339" d="M320.281,429.7a3.246,3.246,0,0,1-.987.222,4.086,4.086,0,0,1-1.006.081,3.251,3.251,0,0,1,.987-.222A4.085,4.085,0,0,1,320.281,429.7Z" transform="translate(-318.289 -429.681)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54280" data-name="Group 54280" transform="translate(0.551 2.264)">
                <g id="Group_54279" data-name="Group 54279">
                  <g id="Group_54278" data-name="Group 54278">
                    <path id="Path_55340" data-name="Path 55340" d="M319.8,437.328a3.2,3.2,0,0,1-1.723.2,16.408,16.408,0,0,1,1.723-.2Z" transform="translate(-318.08 -437.315)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54283" data-name="Group 54283" transform="translate(3.012 1.949)">
                <g id="Group_54282" data-name="Group 54282">
                  <g id="Group_54281" data-name="Group 54281">
                    <path id="Path_55341" data-name="Path 55341" d="M332.924,435.462c.035.015.012.14-.1.239s-.238.108-.249.07.068-.1.154-.177S332.888,435.445,332.924,435.462Z" transform="translate(-332.574 -435.46)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54286" data-name="Group 54286" transform="translate(0.838 3.603)">
                <g id="Group_54285" data-name="Group 54285">
                  <g id="Group_54284" data-name="Group 54284">
                    <path id="Path_55342" data-name="Path 55342" d="M321.658,445.222a6.353,6.353,0,0,1-1.887.224,6.369,6.369,0,0,1,1.887-.224Z" transform="translate(-319.771 -445.202)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54289" data-name="Group 54289" transform="translate(3.517 2.849)">
                <g id="Group_54288" data-name="Group 54288">
                  <g id="Group_54287" data-name="Group 54287">
                    <path id="Path_55343" data-name="Path 55343" d="M335.915,440.76c.017-.008.057.031.081.115a.455.455,0,0,1-.308.544c-.084.023-.139.008-.141-.01-.007-.038.179-.091.279-.271S335.879,440.774,335.915,440.76Z" transform="translate(-335.547 -440.759)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54292" data-name="Group 54292" transform="translate(1.549 4.878)">
                <g id="Group_54291" data-name="Group 54291">
                  <g id="Group_54290" data-name="Group 54290">
                    <path id="Path_55344" data-name="Path 55344" d="M325.67,452.758a5.176,5.176,0,0,1-1.713.048,5.181,5.181,0,0,1,1.713-.048Z" transform="translate(-323.957 -452.706)" fill="#eb996e" />
                  </g>
                </g>
              </g>
              <g id="Group_54295" data-name="Group 54295" transform="translate(4.037 4.054)">
                <g id="Group_54294" data-name="Group 54294">
                  <g id="Group_54293" data-name="Group 54293">
                    <path id="Path_55345" data-name="Path 55345" d="M338.952,447.856c.018-.005.052.039.062.124a.535.535,0,0,1-.072.314c-.13.218-.3.315-.323.292s.091-.179.2-.362S338.917,447.868,338.952,447.856Z" transform="translate(-338.614 -447.855)" fill="#eb996e" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Character_1" data-name="Character 1" transform="translate(28.445 102.787)">
          <g id="Group_54486" data-name="Group 54486" transform="translate(0)">
            <g id="Group_54300" data-name="Group 54300" transform="translate(25.473 48.941)">
              <path id="Path_55346" data-name="Path 55346" d="M228.831,400.864c-.76-.362-3.3-1.351-3.3-1.351l-1.241-8.491-5.056.759s2.943,10.821,3.728,10.9,5.3.006,5.3.006l1.471-.023S229.364,401.118,228.831,400.864Z" transform="translate(-219.235 -391.023)" fill="#9b6e65" />
              <g id="Group_54298" data-name="Group 54298" transform="translate(4.422 10.943)">
                <path id="Path_55347" data-name="Path 55347" d="M246.935,455.913a.554.554,0,0,1-.253.094,1.476,1.476,0,0,1-1.23-.328c-.126-.112-.181-.2-.173-.208s.081.064.212.158a1.706,1.706,0,0,0,1.182.315A1.6,1.6,0,0,1,246.935,455.913Z" transform="translate(-245.278 -455.47)" fill="#704c45" />
              </g>
              <g id="Group_54299" data-name="Group 54299" transform="translate(6.079 9.653)">
                <path id="Path_55348" data-name="Path 55348" d="M257.954,449.844c-.013.008-.118-.167-.31-.427a2.09,2.09,0,0,0-.39-.4,2.184,2.184,0,0,0-.617-.3,6.013,6.013,0,0,1-1.158-.548,2.854,2.854,0,0,1-.442-.292,3.488,3.488,0,0,1,.473.238,7.568,7.568,0,0,0,1.157.519,2.135,2.135,0,0,1,.637.319,1.966,1.966,0,0,1,.391.432,3.621,3.621,0,0,1,.2.333A.433.433,0,0,1,257.954,449.844Z" transform="translate(-255.036 -447.872)" fill="#704c45" />
              </g>
            </g>
            <g id="Group_54307" data-name="Group 54307" transform="translate(2.464 20.904)">
              <path id="Path_55349" data-name="Path 55349" d="M100.945,225.9l-1.872,1.288a12.578,12.578,0,0,0-4.015.435,7.535,7.535,0,0,0-3.3,1.948,9.244,9.244,0,0,0-2.349,4.086l-2.254,18.824-3.431,13.283h22.833V248.953l2.185-7.711A3.763,3.763,0,0,1,108.2,240c.018-.355,1.292-8.572,1.292-8.572l-2.6-3.114Z" transform="translate(-83.726 -225.896)" fill="#218e89" />
              <path id="Path_55350" data-name="Path 55350" d="M216.033,256.163a3.547,3.547,0,0,1,1.607,2.86c.316,2.257,2.6,19.673,2.6,19.673s-2.528.583-4.836.852l-2.9.494-.073-11.644Z" transform="translate(-190.577 -251.024)" fill="#218e89" />
              <g id="Group_54301" data-name="Group 54301" transform="translate(7.976 12.696)" opacity="0.2">
                <path id="Path_55351" data-name="Path 55351" d="M130.813,327.307c3.881-4.653,4.491-11.4,8.421-16.011l-2.4,1.351c.426-4.01.835-8.177-.506-11.979a105.166,105.166,0,0,1-5.624,26.622" transform="translate(-130.699 -300.667)" />
              </g>
              <g id="Group_54303" data-name="Group 54303" transform="translate(5.212 5.503)">
                <path id="Path_55352" data-name="Path 55352" d="M123.647,258.3q-.131,10.77-.987,21.515c-.028.35-.367,1.642-.367,1.642s-.734.908-1.315.82l-4.684-.707c-.815-.138-1.123-.246-1.368-.772a2.578,2.578,0,0,1-.225-1.161c.181-6.41-.379-9.806.007-15.866" transform="translate(-114.543 -258.304)" fill="#218e89" />
                <g id="Group_54302" data-name="Group 54302">
                  <path id="Path_55353" data-name="Path 55353" d="M114.586,263.776s0,.042,0,.125l-.016.366c-.017.322-.039.8-.058,1.408s-.034,1.36-.038,2.229.015,1.858.045,2.952c.06,2.187.175,4.792.126,7.687q-.011.542-.023,1.1a2.571,2.571,0,0,0,.194,1.08,1.237,1.237,0,0,0,.3.455,1.214,1.214,0,0,0,.5.24,11.064,11.064,0,0,0,1.147.2l2.374.357,1.214.183c.2.029.411.067.61.088a1.149,1.149,0,0,0,1.029-.509,1.927,1.927,0,0,0,.221-.562,9.593,9.593,0,0,0,.265-1.192c.075-.8.129-1.607.188-2.391.115-1.57.214-3.086.3-4.53.17-2.888.286-5.492.365-7.678s.123-3.957.15-5.18c.013-.61.024-1.084.031-1.409,0-.159.007-.281.01-.366s.006-.125.006-.125,0,.042,0,.125,0,.207,0,.366c0,.325-.008.8-.014,1.409-.017,1.224-.05,2.994-.122,5.182s-.18,4.791-.346,7.681c-.084,1.445-.182,2.96-.295,4.531-.059.786-.112,1.582-.188,2.4a9.672,9.672,0,0,1-.267,1.2,2,2,0,0,1-.232.587,1.236,1.236,0,0,1-1.108.549c-.214-.023-.412-.059-.618-.088l-1.214-.183-2.374-.359a11.149,11.149,0,0,1-1.156-.206,1.3,1.3,0,0,1-.531-.259,1.32,1.32,0,0,1-.326-.486,2.652,2.652,0,0,1-.2-1.115q.012-.555.025-1.1c.053-2.892-.056-5.5-.107-7.684-.026-1.094-.036-2.084-.032-2.954s.027-1.618.053-2.23.051-1.086.074-1.408l.025-.366C114.58,263.818,114.586,263.776,114.586,263.776Z" transform="translate(-114.422 -258.304)" fill="#263238" />
                </g>
              </g>
              <g id="Group_54304" data-name="Group 54304" transform="translate(22.831 8.724)">
                <path id="Path_55354" data-name="Path 55354" d="M218.192,297.986a.25.25,0,0,1,0-.054l0-.159c.005-.146.012-.35.021-.611.01-.531.076-1.3.186-2.24.069-.469.128-.987.237-1.535.052-.274.1-.56.159-.851l.193-.9c.275-1.225.627-2.559.955-3.967a29.053,29.053,0,0,0,.869-7.333c-.017-.473-.033-.9-.047-1.278s-.058-.7-.077-.963l-.05-.609-.011-.159a.244.244,0,0,1,0-.054.257.257,0,0,1,.01.053l.02.158.066.608c.025.264.068.586.092.963l.06,1.28a28.247,28.247,0,0,1-.846,7.354c-.328,1.411-.682,2.744-.962,3.965l-.2.894c-.063.29-.11.574-.163.848-.112.546-.174,1.062-.247,1.53-.118.94-.194,1.7-.214,2.234-.016.26-.029.464-.038.61,0,.066-.009.118-.012.158A.25.25,0,0,1,218.192,297.986Z" transform="translate(-218.19 -277.277)" fill="#263238" />
              </g>
              <g id="Group_54305" data-name="Group 54305" transform="translate(14.828 17.998)">
                <path id="Path_55355" data-name="Path 55355" d="M178.9,331.892a.439.439,0,0,1-.066.078l-.2.211c-.176.183-.436.44-.764.752-.655.625-1.59,1.458-2.676,2.313s-2.119,1.565-2.881,2.053c-.381.244-.693.437-.912.564l-.252.146a.451.451,0,0,1-.091.046.44.44,0,0,1,.082-.061l.243-.16c.213-.136.52-.336.9-.587.753-.5,1.775-1.219,2.861-2.07s2.023-1.676,2.688-2.289c.333-.306.6-.556.782-.731l.213-.2A.437.437,0,0,1,178.9,331.892Z" transform="translate(-171.055 -331.892)" fill="#263238" />
              </g>
              <g id="Group_54306" data-name="Group 54306" transform="translate(22.833 14.355)" opacity="0.2">
                <path id="Path_55356" data-name="Path 55356" d="M220.762,310.438c-.557,5.17-2.4,10.82-2.561,14.081.73-2.324,1.654-4.546,2.333-6.886A12.313,12.313,0,0,0,220.762,310.438Z" transform="translate(-218.201 -310.438)" />
              </g>
            </g>
            <g id="Group_54482" data-name="Group 54482" transform="translate(19.066)">
              <g id="Group_54308" data-name="Group 54308" transform="translate(1.756)">
                <path id="Path_55357" data-name="Path 55357" d="M202.246,107.977a1.328,1.328,0,0,0,.66-2c-.177-.261-.443-.456-.6-.728a4.274,4.274,0,0,1-.269-.737,2.683,2.683,0,0,0-1.809-1.618,3.46,3.46,0,0,0-2.449.287,2.648,2.648,0,0,1-.924.37,3.626,3.626,0,0,1-.953-.161,2.2,2.2,0,0,0-2.474,2.9,1.893,1.893,0,0,0-.022,3.731,4.073,4.073,0,0,0,1.616-.165,28.8,28.8,0,0,1,6.573-.735" transform="translate(-191.845 -102.787)" fill="#263238" />
              </g>
              <g id="Group_54309" data-name="Group 54309" transform="translate(0.044 3.675)">
                <path id="Path_55358" data-name="Path 55358" d="M182.73,137.582a6.769,6.769,0,0,1-.879-4.539,7.635,7.635,0,0,1,2.081-4.449c-.521-.854-.084-1.6.564-2.363a5.008,5.008,0,0,1,2.581-1.44,10.763,10.763,0,0,1,4.431-.234c1.2.188,2.747.707,2.737,1.6a1.288,1.288,0,0,1-.672,1.212,6.014,6.014,0,0,1,1.95,1.768,5.2,5.2,0,0,1,.975,4.846,6.514,6.514,0,0,1-2.282,3.574" transform="translate(-181.767 -124.429)" fill="#218e89" />
              </g>
              <g id="Group_54449" data-name="Group 54449" transform="translate(0 7.11)">
                <g id="Group_54311" data-name="Group 54311" transform="translate(10.655 11.131)">
                  <g id="Group_54310" data-name="Group 54310">
                    <path id="Path_55359" data-name="Path 55359" d="M244.763,210.474a1.51,1.51,0,0,1,.674-.246l-.044-.013a.853.853,0,0,1,.13.008.6.6,0,0,1,.443.164h0a1.111,1.111,0,0,1,.469.654,1.313,1.313,0,0,1-.462,1.281,1,1,0,0,1-1.713-.759A1.246,1.246,0,0,1,244.763,210.474Z" transform="translate(-244.255 -210.215)" fill="#455a64" />
                  </g>
                </g>
                <g id="Group_54442" data-name="Group 54442" transform="translate(0.53 1.299)">
                  <g id="Group_54441" data-name="Group 54441">
                    <g id="Group_54440" data-name="Group 54440">
                      <g id="Group_54315" data-name="Group 54315">
                        <g id="Group_54314" data-name="Group 54314">
                          <g id="Group_54313" data-name="Group 54313">
                            <g id="Group_54312" data-name="Group 54312">
                              <path id="Path_55360" data-name="Path 55360" d="M184.626,166.055l1.889-12.456a1.224,1.224,0,0,1,1.323-.994l6.691-.293c2.081.206,2.917,2.993,2.623,5.064a37.171,37.171,0,0,1-1.435,6.734c-1.245,3.3-3.946,3.189-3.946,3.189s-.505,2.36-.776,3.595l-1.38,4.887Z" transform="translate(-184.626 -152.313)" fill="#9b6e65" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="Group_54439" data-name="Group 54439" transform="translate(2.835 3.7)">
                        <g id="Group_54438" data-name="Group 54438">
                          <g id="Group_54437" data-name="Group 54437">
                            <g id="Group_54436" data-name="Group 54436">
                              <g id="Group_54435" data-name="Group 54435">
                                <g id="Group_54412" data-name="Group 54412" transform="translate(0 1.156)">
                                  <g id="Group_54411" data-name="Group 54411">
                                    <g id="Group_54410" data-name="Group 54410">
                                      <g id="Group_54339" data-name="Group 54339" transform="translate(7.068 0.868)">
                                        <g id="Group_54326" data-name="Group 54326" transform="translate(0.421 1.541)">
                                          <g id="Group_54325" data-name="Group 54325">
                                            <g id="Group_54324" data-name="Group 54324">
                                              <g id="Group_54323" data-name="Group 54323">
                                                <g id="Group_54322" data-name="Group 54322">
                                                  <g id="Group_54321" data-name="Group 54321">
                                                    <g id="Group_54320" data-name="Group 54320">
                                                      <g id="Group_54319" data-name="Group 54319">
                                                        <g id="Group_54318" data-name="Group 54318">
                                                          <g id="Group_54317" data-name="Group 54317">
                                                            <g id="Group_54316" data-name="Group 54316">
                                                              <path id="Path_55361" data-name="Path 55361" d="M246.313,195.584a.446.446,0,0,1-.512.354.427.427,0,0,1-.369-.487.446.446,0,0,1,.512-.354A.427.427,0,0,1,246.313,195.584Z" transform="translate(-245.426 -195.092)" fill="#263238" />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                        <g id="Group_54338" data-name="Group 54338">
                                          <g id="Group_54337" data-name="Group 54337">
                                            <g id="Group_54336" data-name="Group 54336">
                                              <g id="Group_54335" data-name="Group 54335">
                                                <g id="Group_54334" data-name="Group 54334">
                                                  <g id="Group_54333" data-name="Group 54333">
                                                    <g id="Group_54332" data-name="Group 54332">
                                                      <g id="Group_54331" data-name="Group 54331">
                                                        <g id="Group_54330" data-name="Group 54330">
                                                          <g id="Group_54329" data-name="Group 54329">
                                                            <g id="Group_54328" data-name="Group 54328">
                                                              <g id="Group_54327" data-name="Group 54327">
                                                                <path id="Path_55362" data-name="Path 55362" d="M244.67,186.678c-.066.046-.346-.268-.817-.364s-.864.068-.9,0c-.02-.031.057-.122.23-.2a1.225,1.225,0,0,1,.737-.07,1.177,1.177,0,0,1,.635.36C244.674,186.545,244.7,186.658,244.67,186.678Z" transform="translate(-242.947 -186.017)" fill="#263238" />
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g id="Group_54363" data-name="Group 54363" transform="translate(2.428)">
                                        <g id="Group_54350" data-name="Group 54350" transform="translate(0.637 1.6)">
                                          <g id="Group_54349" data-name="Group 54349">
                                            <g id="Group_54348" data-name="Group 54348">
                                              <g id="Group_54347" data-name="Group 54347">
                                                <g id="Group_54346" data-name="Group 54346">
                                                  <g id="Group_54345" data-name="Group 54345">
                                                    <g id="Group_54344" data-name="Group 54344">
                                                      <g id="Group_54343" data-name="Group 54343">
                                                        <g id="Group_54342" data-name="Group 54342">
                                                          <g id="Group_54341" data-name="Group 54341">
                                                            <g id="Group_54340" data-name="Group 54340">
                                                              <path id="Path_55363" data-name="Path 55363" d="M220.262,190.821a.446.446,0,0,1-.512.354.427.427,0,0,1-.369-.487.447.447,0,0,1,.512-.354A.427.427,0,0,1,220.262,190.821Z" transform="translate(-219.375 -190.328)" fill="#263238" />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                        <g id="Group_54362" data-name="Group 54362">
                                          <g id="Group_54361" data-name="Group 54361">
                                            <g id="Group_54360" data-name="Group 54360">
                                              <g id="Group_54359" data-name="Group 54359">
                                                <g id="Group_54358" data-name="Group 54358">
                                                  <g id="Group_54357" data-name="Group 54357">
                                                    <g id="Group_54356" data-name="Group 54356">
                                                      <g id="Group_54355" data-name="Group 54355">
                                                        <g id="Group_54354" data-name="Group 54354">
                                                          <g id="Group_54353" data-name="Group 54353">
                                                            <g id="Group_54352" data-name="Group 54352">
                                                              <g id="Group_54351" data-name="Group 54351">
                                                                <path id="Path_55364" data-name="Path 55364" d="M217.345,181.569c-.066.046-.347-.268-.817-.364s-.864.068-.9,0c-.02-.031.056-.122.23-.2a1.224,1.224,0,0,1,.737-.07,1.176,1.176,0,0,1,.635.361C217.349,181.435,217.377,181.548,217.345,181.569Z" transform="translate(-215.622 -180.907)" fill="#263238" />
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g id="Group_54375" data-name="Group 54375" transform="translate(5.136 0.539)">
                                        <g id="Group_54374" data-name="Group 54374">
                                          <g id="Group_54373" data-name="Group 54373">
                                            <g id="Group_54372" data-name="Group 54372">
                                              <g id="Group_54371" data-name="Group 54371">
                                                <g id="Group_54370" data-name="Group 54370">
                                                  <g id="Group_54369" data-name="Group 54369">
                                                    <g id="Group_54368" data-name="Group 54368">
                                                      <g id="Group_54367" data-name="Group 54367">
                                                        <g id="Group_54366" data-name="Group 54366">
                                                          <g id="Group_54365" data-name="Group 54365">
                                                            <g id="Group_54364" data-name="Group 54364">
                                                              <path id="Path_55365" data-name="Path 55365" d="M231.571,188.044a3.189,3.189,0,0,1,.792.02c.123.013.241.012.278-.066a.624.624,0,0,0-.007-.373l-.166-.988a16.055,16.055,0,0,1-.3-2.556,16.067,16.067,0,0,1,.508,2.526q.078.518.15.99a.709.709,0,0,1-.035.484.308.308,0,0,1-.234.135.824.824,0,0,1-.21-.014A3.117,3.117,0,0,1,231.571,188.044Z" transform="translate(-231.571 -184.083)" fill="#263238" />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g id="Group_54386" data-name="Group 54386" transform="translate(0 7.971)">
                                        <g id="Group_54385" data-name="Group 54385">
                                          <g id="Group_54384" data-name="Group 54384">
                                            <g id="Group_54383" data-name="Group 54383">
                                              <g id="Group_54382" data-name="Group 54382">
                                                <g id="Group_54381" data-name="Group 54381">
                                                  <g id="Group_54380" data-name="Group 54380">
                                                    <g id="Group_54379" data-name="Group 54379">
                                                      <g id="Group_54378" data-name="Group 54378">
                                                        <g id="Group_54377" data-name="Group 54377">
                                                          <g id="Group_54376" data-name="Group 54376">
                                                            <path id="Path_55366" data-name="Path 55366" d="M205.633,230.014a8.849,8.849,0,0,1-4.311-2.16s.623,2.569,4.1,2.955Z" transform="translate(-201.322 -227.854)" fill="#704c45" />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g id="Group_54397" data-name="Group 54397" transform="translate(3.105 4.89)">
                                        <g id="Group_54396" data-name="Group 54396">
                                          <g id="Group_54395" data-name="Group 54395">
                                            <g id="Group_54394" data-name="Group 54394">
                                              <g id="Group_54393" data-name="Group 54393">
                                                <g id="Group_54392" data-name="Group 54392">
                                                  <g id="Group_54391" data-name="Group 54391">
                                                    <g id="Group_54390" data-name="Group 54390">
                                                      <g id="Group_54389" data-name="Group 54389">
                                                        <g id="Group_54388" data-name="Group 54388">
                                                          <g id="Group_54387" data-name="Group 54387">
                                                            <path id="Path_55367" data-name="Path 55367" d="M221.338,210.422a1.181,1.181,0,0,0-.848-.7.768.768,0,0,0-.688.209.7.7,0,0,0,.56,1.194,1.751,1.751,0,0,0,.781-.285.549.549,0,0,0,.185-.145.245.245,0,0,0,.031-.242" transform="translate(-219.608 -209.708)" fill="#704c45" />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                      <g id="Group_54409" data-name="Group 54409" transform="translate(3.537 4.539)">
                                        <g id="Group_54408" data-name="Group 54408">
                                          <g id="Group_54407" data-name="Group 54407">
                                            <g id="Group_54406" data-name="Group 54406">
                                              <g id="Group_54405" data-name="Group 54405">
                                                <g id="Group_54404" data-name="Group 54404">
                                                  <g id="Group_54403" data-name="Group 54403">
                                                    <g id="Group_54402" data-name="Group 54402">
                                                      <g id="Group_54401" data-name="Group 54401">
                                                        <g id="Group_54400" data-name="Group 54400">
                                                          <g id="Group_54399" data-name="Group 54399">
                                                            <g id="Group_54398" data-name="Group 54398">
                                                              <path id="Path_55368" data-name="Path 55368" d="M222.3,207.642c.077.011-.027.522.335.974s.917.509.906.581c0,.033-.142.074-.373.033a1.3,1.3,0,0,1-.762-.456,1.131,1.131,0,0,1-.248-.81C222.186,207.747,222.269,207.632,222.3,207.642Z" transform="translate(-222.155 -207.641)" fill="#263238" />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                                <g id="Group_54423" data-name="Group 54423" transform="translate(2.413)">
                                  <g id="Group_54422" data-name="Group 54422">
                                    <g id="Group_54421" data-name="Group 54421">
                                      <g id="Group_54420" data-name="Group 54420">
                                        <g id="Group_54419" data-name="Group 54419">
                                          <g id="Group_54418" data-name="Group 54418">
                                            <g id="Group_54417" data-name="Group 54417">
                                              <g id="Group_54416" data-name="Group 54416">
                                                <g id="Group_54415" data-name="Group 54415">
                                                  <g id="Group_54414" data-name="Group 54414">
                                                    <g id="Group_54413" data-name="Group 54413">
                                                      <path id="Path_55369" data-name="Path 55369" d="M217.725,174.683c-.073.118-.53-.039-1.095-.087s-1.043.011-1.092-.119c-.021-.061.081-.166.286-.252a1.91,1.91,0,0,1,1.666.157C217.677,174.5,217.758,174.626,217.725,174.683Z" transform="translate(-215.536 -174.102)" fill="#263238" />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                                <g id="Group_54434" data-name="Group 54434" transform="translate(7.314 0.606)">
                                  <g id="Group_54433" data-name="Group 54433">
                                    <g id="Group_54432" data-name="Group 54432">
                                      <g id="Group_54431" data-name="Group 54431">
                                        <g id="Group_54430" data-name="Group 54430">
                                          <g id="Group_54429" data-name="Group 54429">
                                            <g id="Group_54428" data-name="Group 54428">
                                              <g id="Group_54427" data-name="Group 54427">
                                                <g id="Group_54426" data-name="Group 54426">
                                                  <g id="Group_54425" data-name="Group 54425">
                                                    <g id="Group_54424" data-name="Group 54424">
                                                      <path id="Path_55370" data-name="Path 55370" d="M245.981,178.355c-.1.09-.406-.088-.794-.174s-.734-.077-.787-.2c-.022-.061.052-.157.217-.229a1.177,1.177,0,0,1,1.274.3C246.006,178.191,246.029,178.31,245.981,178.355Z" transform="translate(-244.397 -177.673)" fill="#263238" />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54444" data-name="Group 54444" transform="translate(1.226 0.564)">
                  <g id="Group_54443" data-name="Group 54443">
                    <path id="Path_55371" data-name="Path 55371" d="M198.188,149.786s-3.183-.73-6.37,2.358a13.974,13.974,0,0,0-3.074,4.945l-.019-5.939,1.478-2.39,2.473-.776,4.236.66,1.412,1.12" transform="translate(-188.725 -147.984)" fill="#218e89" />
                  </g>
                </g>
                <g id="Group_54446" data-name="Group 54446" transform="translate(4.191)">
                  <g id="Group_54445" data-name="Group 54445">
                    <path id="Path_55372" data-name="Path 55372" d="M206.368,145.986c5.347-.137,9.051,2.416,8.945,6.563l1.6-2.4-1.965-3.752-2.733-1.372-6.021-.36Z" transform="translate(-206.19 -144.661)" fill="#218e89" />
                  </g>
                </g>
                <g id="Group_54448" data-name="Group 54448" transform="translate(0 8.854)">
                  <g id="Group_54447" data-name="Group 54447">
                    <path id="Path_55373" data-name="Path 55373" d="M182.053,197.086a1.623,1.623,0,0,1,.726-.265l-.047-.014a.96.96,0,0,1,.14.009.642.642,0,0,1,.476.177h0a1.193,1.193,0,0,1,.5.7,1.413,1.413,0,0,1-.5,1.379,1.08,1.08,0,0,1-1.843-.817A1.34,1.34,0,0,1,182.053,197.086Z" transform="translate(-181.506 -196.807)" fill="#455a64" />
                  </g>
                </g>
              </g>
              <g id="Group_54451" data-name="Group 54451" transform="translate(0.843 9.268)">
                <g id="Group_54450" data-name="Group 54450">
                  <path id="Path_55374" data-name="Path 55374" d="M187.768,162.787a.527.527,0,0,1-.208-.132,1.313,1.313,0,0,1-.367-.566,1.329,1.329,0,0,1,.057-1c.08-.173.187-.336.255-.514a.567.567,0,0,0-.027-.534,2,2,0,0,0-.429-.415,1.485,1.485,0,0,1-.387-.46,1.644,1.644,0,0,1-.173-.987,1.443,1.443,0,0,1,.238-.63.544.544,0,0,1,.168-.18,1.908,1.908,0,0,0-.305.822,1.6,1.6,0,0,0,.188.912,1.379,1.379,0,0,0,.362.416,2.029,2.029,0,0,1,.461.448.706.706,0,0,1,.036.659c-.079.2-.189.359-.267.52a1.252,1.252,0,0,0-.079.912,1.392,1.392,0,0,0,.31.556C187.7,162.727,187.775,162.776,187.768,162.787Z" transform="translate(-186.473 -157.37)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54453" data-name="Group 54453" transform="translate(2.269 7.573)">
                <g id="Group_54452" data-name="Group 54452">
                  <path id="Path_55375" data-name="Path 55375" d="M195.331,147.388a2.19,2.19,0,0,0-.322.763,1.637,1.637,0,0,0,.084.874,3.112,3.112,0,0,0,.658.88,1.464,1.464,0,0,1,.308.526,1.006,1.006,0,0,1-.011.574,7.719,7.719,0,0,0-.3.862.805.805,0,0,0,.091.571c.078.117.161.145.153.159s-.022,0-.059-.016a.412.412,0,0,1-.138-.109.819.819,0,0,1-.147-.618,6.061,6.061,0,0,1,.277-.884.888.888,0,0,0,0-.5,1.361,1.361,0,0,0-.284-.473,3.044,3.044,0,0,1-.672-.926,1.67,1.67,0,0,1-.06-.94,1.5,1.5,0,0,1,.258-.578C195.257,147.432,195.327,147.384,195.331,147.388Z" transform="translate(-194.869 -147.388)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54455" data-name="Group 54455" transform="translate(4.861 7.808)">
                <g id="Group_54454" data-name="Group 54454">
                  <path id="Path_55376" data-name="Path 55376" d="M211.066,151.721c.005.005-.05.044-.145,0a.287.287,0,0,1-.127-.125.357.357,0,0,1-.03-.235c.027-.175.161-.333.207-.51a.524.524,0,0,0-.251-.508,1.472,1.472,0,0,1-.465-.519,1.212,1.212,0,0,1-.119-.562.723.723,0,0,1,.159-.493,2.837,2.837,0,0,0-.058.492,1.218,1.218,0,0,0,.135.5,1.449,1.449,0,0,0,.44.47.952.952,0,0,1,.243.285.487.487,0,0,1,.043.364c-.072.223-.206.357-.236.5a.26.26,0,0,0,.086.291C211.012,151.722,211.064,151.709,211.066,151.721Z" transform="translate(-210.136 -148.773)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54457" data-name="Group 54457" transform="translate(7.093 8.194)">
                <g id="Group_54456" data-name="Group 54456">
                  <path id="Path_55377" data-name="Path 55377" d="M223.54,151.049c0-.007.036,0,.067.042a.25.25,0,0,1,.019.211,1.388,1.388,0,0,1-.139.246.561.561,0,0,0-.064.287,1.208,1.208,0,0,0,.181.54c.075.135.122.222.105.234s-.089-.053-.189-.179a1.024,1.024,0,0,1-.239-.592.628.628,0,0,1,.094-.359c.065-.1.129-.156.158-.216A.28.28,0,0,0,223.54,151.049Z" transform="translate(-223.28 -151.046)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54459" data-name="Group 54459" transform="translate(10.877 7.426)">
                <g id="Group_54458" data-name="Group 54458">
                  <path id="Path_55378" data-name="Path 55378" d="M247.524,146.521c.007.006-.021.053-.085.126s-.167.167-.269.295a.718.718,0,0,0-.155.521,2.838,2.838,0,0,1,.01.746,1.3,1.3,0,0,1-.362.671,1.239,1.239,0,0,1-.538.31.811.811,0,0,1-.56,0,2.841,2.841,0,0,0,.53-.1,1.217,1.217,0,0,0,.791-.912,3.157,3.157,0,0,0,0-.715.8.8,0,0,1,.209-.588,2.733,2.733,0,0,1,.308-.27C247.475,146.548,247.516,146.514,247.524,146.521Z" transform="translate(-245.565 -146.52)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54461" data-name="Group 54461" transform="translate(12.482 10.32)">
                <g id="Group_54460" data-name="Group 54460">
                  <path id="Path_55379" data-name="Path 55379" d="M257.282,163.568c.016.027-.173.069-.289.307-.058.123-.155.265-.236.419a3.1,3.1,0,0,1-.306.52,1.123,1.123,0,0,1-1.019.388.626.626,0,0,1-.416-.185,3.9,3.9,0,0,0,.426.085,1.094,1.094,0,0,0,.9-.378,3.8,3.8,0,0,0,.3-.493c.1-.164.194-.287.265-.407a.553.553,0,0,1,.254-.228C257.235,163.564,257.279,163.56,257.282,163.568Z" transform="translate(-255.015 -163.564)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54463" data-name="Group 54463" transform="translate(13.292 13.825)">
                <g id="Group_54462" data-name="Group 54462">
                  <path id="Path_55380" data-name="Path 55380" d="M260.976,184.208c.015.01-.014.088-.109.179a.744.744,0,0,1-.952.046c-.1-.081-.139-.156-.126-.168a.967.967,0,0,0,1.186-.057Z" transform="translate(-259.787 -184.206)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54465" data-name="Group 54465" transform="translate(8.645 6.27)">
                <g id="Group_54464" data-name="Group 54464">
                  <path id="Path_55381" data-name="Path 55381" d="M233.694,139.715a3.224,3.224,0,0,0-.353.265,1.066,1.066,0,0,0-.293.945,1.31,1.31,0,0,1,.069.621.834.834,0,0,1-.289.432.818.818,0,0,1-.409.216c-.012-.021.147-.1.341-.29a.785.785,0,0,0,.229-.387,1.321,1.321,0,0,0-.08-.563,1.062,1.062,0,0,1,.062-.63,1.133,1.133,0,0,1,.3-.417A.625.625,0,0,1,233.694,139.715Z" transform="translate(-232.418 -139.711)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54467" data-name="Group 54467" transform="translate(6.189 6.636)">
                <g id="Group_54466" data-name="Group 54466">
                  <path id="Path_55382" data-name="Path 55382" d="M217.96,143.272c-.035-.023.135-.3.219-.689s.056-.71.1-.716a1.373,1.373,0,0,1-.316,1.4Z" transform="translate(-217.956 -141.867)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54469" data-name="Group 54469" transform="translate(3.647 4.409)">
                <g id="Group_54468" data-name="Group 54468">
                  <path id="Path_55383" data-name="Path 55383" d="M203.24,131.554c0-.011.037-.03.081-.091a.518.518,0,0,0,.091-.3,4.168,4.168,0,0,0-.337-1.056,1.62,1.62,0,0,1-.082-.641.927.927,0,0,1,.19-.5.56.56,0,0,1,.3-.206c.087-.018.134,0,.133.008s-.046.013-.115.044a.588.588,0,0,0-.233.214,1.2,1.2,0,0,0-.054,1.038,3.182,3.182,0,0,1,.3,1.1.512.512,0,0,1-.15.331C203.3,131.56,203.241,131.562,203.24,131.554Z" transform="translate(-202.987 -128.753)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54471" data-name="Group 54471" transform="translate(7.002 3.687)">
                <g id="Group_54470" data-name="Group 54470">
                  <path id="Path_55384" data-name="Path 55384" d="M223.209,127.182c-.005-.008.03-.04.095-.089s.174-.117.205-.232a.723.723,0,0,0-.144-.437c-.1-.159-.237-.317-.373-.5a1.009,1.009,0,0,1-.246-.617.909.909,0,0,1,.236-.489,1.148,1.148,0,0,1,.363-.316,2.71,2.71,0,0,1-.283.378.876.876,0,0,0-.185.435.949.949,0,0,0,.23.525c.257.343.565.69.5,1.051a.494.494,0,0,1-.273.25C223.257,127.176,223.214,127.191,223.209,127.182Z" transform="translate(-222.745 -124.5)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54473" data-name="Group 54473" transform="translate(10.736 4.195)">
                <g id="Group_54472" data-name="Group 54472">
                  <path id="Path_55385" data-name="Path 55385" d="M244.734,129.783c-.009-.02.157-.054.249-.267a.649.649,0,0,0,.034-.386,2.876,2.876,0,0,0-.157-.487,1.348,1.348,0,0,1-.106-.538.688.688,0,0,1,.19-.418c.173-.2.368-.205.362-.181a.844.844,0,0,0-.283.244.632.632,0,0,0-.137.36,1.373,1.373,0,0,0,.109.484,2.415,2.415,0,0,1,.154.516.706.706,0,0,1-.074.451.464.464,0,0,1-.226.21C244.777,129.8,244.736,129.791,244.734,129.783Z" transform="translate(-244.734 -127.495)" fill="#fff" />
                </g>
              </g>
              <g id="Group_54475" data-name="Group 54475" transform="translate(2.072 6.121)">
                <g id="Group_54474" data-name="Group 54474">
                  <path id="Path_55386" data-name="Path 55386" d="M203.486,139.326a2.153,2.153,0,0,1-.392-.066c-.249-.057-.613-.118-1.063-.185a11.817,11.817,0,0,0-3.536.052,17.8,17.8,0,0,0-3.41.991l-1,.4a2.139,2.139,0,0,1-.374.136,2.131,2.131,0,0,1,.352-.186c.23-.112.567-.265.989-.443a15.833,15.833,0,0,1,3.423-1.038,10.984,10.984,0,0,1,3.575-.012,10.247,10.247,0,0,1,1.061.231A2.11,2.11,0,0,1,203.486,139.326Z" transform="translate(-193.707 -138.834)" fill="#263238" />
                </g>
              </g>
              <g id="Group_54478" data-name="Group 54478" transform="translate(3.154 7.827)">
                <g id="Group_54477" data-name="Group 54477">
                  <g id="Group_54476" data-name="Group 54476">
                    <path id="Path_55387" data-name="Path 55387" d="M200.079,149.09a.133.133,0,0,0,.036,0l.1-.02.168-.035.231-.036a6.419,6.419,0,0,1,1.482-.038,8.921,8.921,0,0,1,2.147.47c.393.133.8.3,1.21.481s.836.384,1.254.618a6.884,6.884,0,0,1,3.245,3.526,5.027,5.027,0,0,1,.274,1.453c.009.175.008.312.007.4,0,.042,0,.077,0,.105a.119.119,0,0,0,0,.036.127.127,0,0,0,.005-.036c0-.029,0-.063.007-.105a4.029,4.029,0,0,0,.009-.406,4.827,4.827,0,0,0-.251-1.471,6.852,6.852,0,0,0-3.259-3.578c-.421-.236-.846-.438-1.261-.62s-.822-.347-1.218-.479a8.749,8.749,0,0,0-2.167-.456,6.071,6.071,0,0,0-1.492.064l-.231.044-.167.042-.1.028A.108.108,0,0,0,200.079,149.09Z" transform="translate(-200.079 -148.882)" fill="#263238" />
                  </g>
                </g>
              </g>
              <g id="Group_54481" data-name="Group 54481" transform="translate(1.546 9.419)">
                <g id="Group_54480" data-name="Group 54480">
                  <g id="Group_54479" data-name="Group 54479">
                    <path id="Path_55388" data-name="Path 55388" d="M198.872,158.3a.588.588,0,0,0-.112-.02,3.07,3.07,0,0,0-.324-.026,4.588,4.588,0,0,0-1.186.133,8.222,8.222,0,0,0-3.426,1.88,12.635,12.635,0,0,0-1.466,1.6,15.307,15.307,0,0,0-1.028,1.439,8.146,8.146,0,0,0-.565,1.051c-.055.13-.1.231-.119.3a.057.057,0,1,0,.015.006c.028-.068.073-.168.133-.295a9.26,9.26,0,0,1,.584-1.033,16.42,16.42,0,0,1,1.038-1.423,13.027,13.027,0,0,1,1.462-1.582,8.4,8.4,0,0,1,3.386-1.885,4.885,4.885,0,0,1,1.171-.157c.14,0,.249,0,.323.011A.61.61,0,0,0,198.872,158.3Z" transform="translate(-190.613 -158.256)" fill="#263238" />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_54485" data-name="Group 54485" transform="translate(0 49.676)">
              <path id="Path_55389" data-name="Path 55389" d="M69.217,405.851s1.039-.341,1.707-.533a25.317,25.317,0,0,1,2.821-.955A16.74,16.74,0,0,0,77,402.9c.333-.223,1.647-7.548,1.647-7.548l4.958.748s-2.644,7.062-2.81,7.737S80,406.253,80,406.253H69.217Z" transform="translate(-69.217 -395.35)" fill="#9b6e65" />
              <g id="Group_54483" data-name="Group 54483" transform="translate(8.683 8.592)">
                <path id="Path_55390" data-name="Path 55390" d="M120.354,448.1c0-.016.163-.029.4-.13a1.609,1.609,0,0,0,1-1.6c-.014-.259-.075-.414-.06-.419s.026.034.052.1a1.34,1.34,0,0,1,.07.309,1.571,1.571,0,0,1-1.037,1.662,1.342,1.342,0,0,1-.309.073A.27.27,0,0,1,120.354,448.1Z" transform="translate(-120.354 -445.953)" fill="#704c45" />
              </g>
              <g id="Group_54484" data-name="Group 54484" transform="translate(2.634 8.402)">
                <path id="Path_55391" data-name="Path 55391" d="M90.127,446.809a.511.511,0,0,1-.1.045,1.784,1.784,0,0,1-.306.08,6.945,6.945,0,0,1-1.16.025,2.712,2.712,0,0,0-.782.095c-.272.08-.543.231-.856.335a2.81,2.81,0,0,1-2.089-.159l-.1-.051.113-.031.1-.028a3.826,3.826,0,0,0,1-.454,6.5,6.5,0,0,0,.8-.645,4.109,4.109,0,0,1,1.54-.846,9.21,9.21,0,0,1,1.154-.275l.318-.053a.537.537,0,0,1,.112-.012.552.552,0,0,1-.109.03l-.314.069a10.871,10.871,0,0,0-1.141.3,4.122,4.122,0,0,0-1.506.848,6.46,6.46,0,0,1-.809.656,3.869,3.869,0,0,1-1.021.466l-.1.028.008-.082a2.75,2.75,0,0,0,2.024.161c.3-.1.578-.247.861-.328a2.727,2.727,0,0,1,.8-.087,7.918,7.918,0,0,0,1.154,0A3.75,3.75,0,0,0,90.127,446.809Z" transform="translate(-84.728 -444.834)" fill="#704c45" />
              </g>
            </g>
          </g>
        </g>
        <g id="Character_4" data-name="Character 4" transform="translate(84.715 108.796)">
          <path id="Path_55392" data-name="Path 55392" d="M520.483,372.554l-2.881,6.668-2.673,8.588,25.9.122-.441-16.266-12.728-1.3Z" transform="translate(-495.52 -330.944)" fill="#263238" />
          <g id="Group_54493" data-name="Group 54493" transform="translate(0 42.635)">
            <g id="Group_54488" data-name="Group 54488">
              <g id="Group_54487" data-name="Group 54487">
                <path id="Path_55393" data-name="Path 55393" d="M411.736,389.273s-2.086.793-3.717,1.274a26.981,26.981,0,0,0-4.545,2.278s-2.96,2.671-2.857,3.021.458.523.909.162,2.672-1.674,2.672-1.674.143.743.624.829a1.29,1.29,0,0,0,.733-.035,1.439,1.439,0,0,0,1.209,1.209.561.561,0,0,0,.458.523,2.932,2.932,0,0,0,.919.109,8.135,8.135,0,0,0,1.793-1.39c.246-.452.547-1.061.547-1.061l.638-.32,1.961-.334Z" transform="translate(-400.614 -389.273)" fill="#c78976" />
              </g>
            </g>
            <g id="Group_54489" data-name="Group 54489" transform="translate(3.642 2.897)">
              <path id="Path_55394" data-name="Path 55394" d="M427.391,406.965a1.249,1.249,0,0,1-.192.235,2.487,2.487,0,0,1-.69.485,3.484,3.484,0,0,1-1.234.348A2.7,2.7,0,0,1,424.5,408a2.022,2.022,0,0,1-.79-.324l.05-.069a5.145,5.145,0,0,1,.548.464,2.058,2.058,0,0,1,.247.287.976.976,0,0,1,.149.365,1.12,1.12,0,0,1-.213.888l-.02.026-.03-.012a4.193,4.193,0,0,1-.833-.465c-.251-.174-.482-.354-.7-.526s-.419-.331-.611-.492a.762.762,0,0,1-.227-.64,1.1,1.1,0,0,1,.088-.286c.036-.086.072-.165.109-.238a3.9,3.9,0,0,1,.206-.362c.062-.1.114-.167.15-.214a.346.346,0,0,1,.06-.068.438.438,0,0,1-.047.077c-.032.05-.08.123-.137.221s-.124.219-.192.365c-.034.073-.069.152-.1.237a1.056,1.056,0,0,0-.079.273.7.7,0,0,0,.217.584c.184.15.4.311.611.481s.448.347.7.518a4.173,4.173,0,0,0,.817.453l-.05.014a1.036,1.036,0,0,0,.194-.818.89.89,0,0,0-.135-.332,2,2,0,0,0-.236-.275,5.136,5.136,0,0,0-.536-.457l.05-.069a1.955,1.955,0,0,0,.755.314,2.654,2.654,0,0,0,.756.039,3.516,3.516,0,0,0,1.216-.323,2.627,2.627,0,0,0,.693-.457C427.322,407.047,427.383,406.958,427.391,406.965Z" transform="translate(-422.063 -406.334)" fill="#925b4c" />
            </g>
            <g id="Group_54490" data-name="Group 54490" transform="translate(6.18 4.564)">
              <path id="Path_55395" data-name="Path 55395" d="M437.01,417.3a1.536,1.536,0,0,1,.255-.1,1.869,1.869,0,0,0,.553-.327.571.571,0,0,0,.174-.284.4.4,0,0,0-.039-.264c-.076-.144-.2-.154-.19-.168s.028-.011.078,0a.316.316,0,0,1,.166.133.439.439,0,0,1,.065.311.619.619,0,0,1-.193.333,1.553,1.553,0,0,1-.594.322A.613.613,0,0,1,437.01,417.3Z" transform="translate(-437.01 -416.15)" fill="#925b4c" />
            </g>
            <g id="Group_54491" data-name="Group 54491" transform="translate(6.614 5.121)">
              <path id="Path_55396" data-name="Path 55396" d="M439.568,421.209a3.7,3.7,0,0,1,.376-.244c.116-.075.252-.167.4-.276a2.954,2.954,0,0,0,.442-.383.6.6,0,0,0,.134-.251.4.4,0,0,0-.031-.254.553.553,0,0,0-.34-.284.955.955,0,0,0-.315-.038.451.451,0,0,1-.121,0c0-.005.04-.022.117-.035a.828.828,0,0,1,.336.012.6.6,0,0,1,.4.309.471.471,0,0,1,.042.306.666.666,0,0,1-.151.29,2.69,2.69,0,0,1-.462.392c-.151.107-.292.195-.413.264A1.648,1.648,0,0,1,439.568,421.209Z" transform="translate(-439.568 -419.433)" fill="#925b4c" />
            </g>
            <g id="Group_54492" data-name="Group 54492" transform="translate(7.83 5.856)">
              <path id="Path_55397" data-name="Path 55397" d="M447.571,424.667a1.708,1.708,0,0,0,.073-.229.5.5,0,0,0-.713-.547c-.135.058-.191.14-.2.13s.031-.1.175-.186a.544.544,0,0,1,.8.616C447.664,424.61,447.576,424.674,447.571,424.667Z" transform="translate(-446.728 -423.763)" fill="#925b4c" />
            </g>
          </g>
          <path id="Path_55398" data-name="Path 55398" d="M479.516,442.268l-.544.8-4.065-.008-5.174,2.085H483.1c.113-.141.461-.675.461-.675Z" transform="translate(-457.997 -390.635)" fill="#c78976" />
          <g id="Group_54510" data-name="Group 54510" transform="translate(10.232 15.338)">
            <path id="Path_55399" data-name="Path 55399" d="M497.02,240.076a54.109,54.109,0,0,0-9.723-10.759c-.533-.266-9.106-.8-9.106-.8l-6.772,4.8-2.248,3.36a7.358,7.358,0,0,0-.6,3.4l.209,1.657,7.063,4.56,3.513,2.05,1.957,2.775-.157,4.633,2.768-.526s4.263.421,8.348.521a48.24,48.24,0,0,0,9.7-1.2S500.275,245.344,497.02,240.076Z" transform="translate(-467.212 -228.513)" fill="#e77355" />
            <path id="Path_55400" data-name="Path 55400" d="M527.881,246.852l2.4,13.3-4.973,14.673,4.973,2.714s3.552-6.251,5.15-10.032a53.937,53.937,0,0,0,2.182-5.894s-.008-14.376-.518-16.033-2.2-4.943-3.974-4.765S527.154,242.817,527.881,246.852Z" transform="translate(-514.365 -238.721)" fill="#e77355" />
            <path id="Path_55401" data-name="Path 55401" d="M462.441,306.4l4.285,10.491a4.351,4.351,0,0,0-.516,1.234l-5.337,2.644,1.569,5.051s6.666-2.215,7.61-2.724,3.667-2.45,4.14-4.023-2.145-7.15-2.145-7.15Z" transform="translate(-460.872 -293.173)" fill="#e77355" />
            <g id="Group_54494" data-name="Group 54494" transform="translate(4.208 13.827)">
              <path id="Path_55402" data-name="Path 55402" d="M493.592,315.954a.437.437,0,0,1-.091-.044l-.254-.142c-.219-.125-.535-.309-.921-.543s-.843-.519-1.336-.856c-.246-.17-.5-.353-.755-.558l-.774-.641-2.7-2.264-.813-.694-.218-.192a.445.445,0,0,1-.072-.072.435.435,0,0,1,.084.058l.229.179.831.672,2.719,2.244.772.643c.251.2.5.387.747.558.488.34.941.629,1.322.87l.906.566.245.156A.436.436,0,0,1,493.592,315.954Z" transform="translate(-485.655 -309.947)" fill="#263238" />
            </g>
            <g id="Group_54495" data-name="Group 54495" transform="translate(4.428 0.614)">
              <path id="Path_55403" data-name="Path 55403" d="M495.162,232.131a2.375,2.375,0,0,1,.327.493,3.541,3.541,0,0,1,.381,1.563,4.224,4.224,0,0,1-.134,1.111,4.673,4.673,0,0,1-.5,1.18,6.444,6.444,0,0,1-2.064,2.066,5.477,5.477,0,0,1-2.766.919,3.635,3.635,0,0,1-2.268-.685,3.031,3.031,0,0,1-1.018-1.241,1.849,1.849,0,0,1-.089-.238,1.15,1.15,0,0,1-.049-.179l-.023-.112a.126.126,0,0,1,0-.039,4.716,4.716,0,0,0,.2.554,3.066,3.066,0,0,0,1.023,1.2,3.6,3.6,0,0,0,2.23.654,5.441,5.441,0,0,0,2.721-.912,6.427,6.427,0,0,0,2.042-2.034,4.655,4.655,0,0,0,.5-1.157,4.216,4.216,0,0,0,.142-1.091,3.608,3.608,0,0,0-.349-1.548,2.432,2.432,0,0,0-.118-.222c-.036-.063-.067-.117-.1-.157l-.062-.1A.132.132,0,0,1,495.162,232.131Z" transform="translate(-486.949 -232.131)" fill="#263238" />
            </g>
            <g id="Group_54496" data-name="Group 54496" transform="translate(10.036 5.639)">
              <path id="Path_55404" data-name="Path 55404" d="M520.741,262.83c-.024.01-.147-.266-.357-.568s-.421-.517-.4-.536a1.985,1.985,0,0,1,.76,1.1Z" transform="translate(-519.98 -261.724)" fill="#263238" />
            </g>
            <g id="Group_54497" data-name="Group 54497" transform="translate(11.178 4.575)">
              <path id="Path_55405" data-name="Path 55405" d="M527.94,256.115a2.387,2.387,0,0,0-1.235-.646,1.358,1.358,0,0,1,1.235.646Z" transform="translate(-526.706 -255.455)" fill="#263238" />
            </g>
            <g id="Group_54498" data-name="Group 54498" transform="translate(12.149 3.228)">
              <path id="Path_55406" data-name="Path 55406" d="M533.57,247.725a11.343,11.343,0,0,0-1.15-.162,1.685,1.685,0,0,1,1.15.162Z" transform="translate(-532.42 -247.522)" fill="#263238" />
            </g>
            <g id="Group_54499" data-name="Group 54499" transform="translate(12.121 1.319)">
              <path id="Path_55407" data-name="Path 55407" d="M533.281,236.295a36.3,36.3,0,0,0-1.024.291,1.582,1.582,0,0,1,1.024-.291Z" transform="translate(-532.256 -236.281)" fill="#263238" />
            </g>
            <g id="Group_54500" data-name="Group 54500" transform="translate(7.928 6.675)">
              <path id="Path_55408" data-name="Path 55408" d="M507.693,268.912a1.169,1.169,0,0,1,.006-1.088c.025.009-.049.245-.049.544S507.718,268.9,507.693,268.912Z" transform="translate(-507.562 -267.824)" fill="#263238" />
            </g>
            <g id="Group_54501" data-name="Group 54501" transform="translate(5.32 6.576)">
              <path id="Path_55409" data-name="Path 55409" d="M492.2,267.89c-.019-.016.091-.174.244-.353s.293-.312.311-.3-.091.174-.244.353S492.222,267.906,492.2,267.89Z" transform="translate(-492.201 -267.239)" fill="#263238" />
            </g>
            <g id="Group_54502" data-name="Group 54502" transform="translate(10.888 6.86)">
              <path id="Path_55410" data-name="Path 55410" d="M536.834,268.914s.008.024.02.071a1.924,1.924,0,0,1,.048.214c.038.19.072.476.109.849.071.747.135,1.846.188,3.247.1,2.8.175,6.817.19,11.652v.01c-.358,1.217-.811,2.465-1.309,3.751s-1.043,2.612-1.683,3.943c-1.273,2.664-2.754,5.421-4.339,8.244l-.021.038-.038-.021-.459-.251-4.513-2.463-.032-.018.012-.035,4.981-14.67v.017l-1.733-9.7-.472-2.671-.121-.7c-.027-.158-.038-.239-.038-.239s.018.08.048.238l.131.7.491,2.668,1.763,9.692,0,.009,0,.009-4.965,14.676-.021-.052,4.514,2.462.459.251-.06.017c1.584-2.821,3.065-5.577,4.339-8.236.641-1.328,1.18-2.653,1.685-3.936s.953-2.531,1.312-3.742v.01c-.007-4.834-.066-8.847-.159-11.65-.047-1.4-.1-2.5-.167-3.247-.034-.373-.064-.659-.1-.849-.013-.1-.032-.167-.042-.215S536.834,268.914,536.834,268.914Z" transform="translate(-524.993 -268.914)" fill="#263238" />
            </g>
            <g id="Group_54503" data-name="Group 54503" transform="translate(14.148 2.613)">
              <path id="Path_55411" data-name="Path 55411" d="M549.541,244.534a7.423,7.423,0,0,0-.805-.342A4.018,4.018,0,0,0,544.8,245a7.391,7.391,0,0,0-.606.631,1.033,1.033,0,0,1,.129-.2,3.544,3.544,0,0,1,.435-.479,3.921,3.921,0,0,1,4-.818,3.534,3.534,0,0,1,.588.269A1.031,1.031,0,0,1,549.541,244.534Z" transform="translate(-544.194 -243.903)" fill="#263238" />
            </g>
            <g id="Group_54504" data-name="Group 54504" transform="translate(21.513 11.907)" opacity="0.3">
              <path id="Path_55412" data-name="Path 55412" d="M587.57,390.83l.08-.212-.044.03Z" transform="translate(-587.57 -375)" />
              <path id="Path_55413" data-name="Path 55413" d="M590.644,305.115a39.947,39.947,0,0,0-1.063-6.476c-.479,3.674.463,7.441-.167,11.092a24.972,24.972,0,0,1-1.373,4.526,6.379,6.379,0,0,0,2.557-4.125A16.926,16.926,0,0,0,590.644,305.115Z" transform="translate(-587.961 -298.639)" />
            </g>
            <g id="Group_54505" data-name="Group 54505" transform="translate(5.674 15.04)" opacity="0.3">
              <path id="Path_55414" data-name="Path 55414" d="M500.186,321.539l-5.9-4.45c.066,1.659,1.328,3.021,2.64,4.039s3.1,2.19,4.339,3.29C500.858,323.5,500.6,322.456,500.186,321.539Z" transform="translate(-494.289 -317.089)" />
            </g>
            <g id="Group_54506" data-name="Group 54506" transform="translate(1.628 11.351)">
              <path id="Path_55415" data-name="Path 55415" d="M483,295.365a.929.929,0,0,1-.1.113c-.065.072-.162.176-.29.307a7.066,7.066,0,0,1-1.187,1,3.416,3.416,0,0,1-.982.445,2.481,2.481,0,0,1-1.236,0,4.6,4.6,0,0,1-1.24-.574c-.4-.247-.8-.533-1.24-.763a3.2,3.2,0,0,0-1.367-.41,2.007,2.007,0,0,0-1.243.406,9.846,9.846,0,0,0-.928.8,3.877,3.877,0,0,1-.874.63,1.617,1.617,0,0,1-.869.181,1.329,1.329,0,0,1-.636-.212.9.9,0,0,1-.293-.306.58.58,0,0,1-.06-.135s.023.046.075.126a.927.927,0,0,0,.3.287,1.315,1.315,0,0,0,.621.192,1.577,1.577,0,0,0,.837-.188,3.867,3.867,0,0,0,.853-.628,9.6,9.6,0,0,1,.931-.81,2.438,2.438,0,0,1,.6-.317,1.912,1.912,0,0,1,.7-.109,3.263,3.263,0,0,1,1.406.418c.448.233.845.522,1.245.767a4.569,4.569,0,0,0,1.215.569,2.211,2.211,0,0,0,.616.08,2.418,2.418,0,0,0,.582-.069,3.393,3.393,0,0,0,.966-.426,7.566,7.566,0,0,0,1.2-.975l.3-.3A.939.939,0,0,1,483,295.365Z" transform="translate(-470.458 -295.364)" fill="#fff" />
            </g>
            <g id="Group_54507" data-name="Group 54507" transform="translate(14.07 8.825)">
              <path id="Path_55416" data-name="Path 55416" d="M552.45,281.983a.405.405,0,0,1-.071.068,1,1,0,0,1-.244.142,1.6,1.6,0,0,1-1.024.031c-.884-.2-1.669-1.285-2.991-1.53a4.725,4.725,0,0,0-.947-.12,5.041,5.041,0,0,0-.888.068,4.759,4.759,0,0,0-1.426.479,5.079,5.079,0,0,0-1.122.825.409.409,0,0,1,.063-.075l.193-.2c.092-.079.2-.18.341-.285l.228-.159c.08-.057.174-.1.268-.159a4.675,4.675,0,0,1,1.442-.5,5.007,5.007,0,0,1,.9-.075,4.736,4.736,0,0,1,.966.121,4.435,4.435,0,0,1,.919.322c.137.078.276.147.4.228s.248.167.365.25a4.447,4.447,0,0,0,1.3.755,1.4,1.4,0,0,0,1.322-.18Z" transform="translate(-543.737 -280.487)" fill="#fff" />
            </g>
            <g id="Group_54508" data-name="Group 54508" transform="translate(23.223 7.938)">
              <path id="Path_55417" data-name="Path 55417" d="M601.612,275.266c0,.008-.078.044-.2.141a1.632,1.632,0,0,0-.422.514,3.433,3.433,0,0,0-.322.941,6.434,6.434,0,0,1-.325,1.2,2.043,2.043,0,0,1-.818.933,2.276,2.276,0,0,1-.963.325,1.925,1.925,0,0,1-.683-.054,1.158,1.158,0,0,1-.177-.058c-.039-.016-.06-.025-.058-.028s.087.022.243.053a2.087,2.087,0,0,0,.669.025,2.286,2.286,0,0,0,.927-.332,1.994,1.994,0,0,0,.78-.9,6.8,6.8,0,0,0,.326-1.18,3.323,3.323,0,0,1,.347-.954,1.555,1.555,0,0,1,.456-.511.921.921,0,0,1,.163-.092C601.589,275.271,601.611,275.264,601.612,275.266Z" transform="translate(-597.641 -275.266)" fill="#fff" />
            </g>
            <g id="Group_54509" data-name="Group 54509" transform="translate(3.972 16.347)">
              <path id="Path_55418" data-name="Path 55418" d="M487.612,324.789a.727.727,0,0,1-.034.18,1.441,1.441,0,0,1-.061.207,2.447,2.447,0,0,1-.1.267,2.751,2.751,0,0,1-.157.31,3.332,3.332,0,0,1-.215.335,3.888,3.888,0,0,1-.624.666,3.944,3.944,0,0,1-.768.493,3.373,3.373,0,0,1-.7.242,2.2,2.2,0,0,1-.282.049,1.329,1.329,0,0,1-.215.018.727.727,0,0,1-.184,0c0-.019.261-.02.665-.124a3.661,3.661,0,0,0,.684-.255,4.168,4.168,0,0,0,.747-.487,4.108,4.108,0,0,0,.615-.646,3.514,3.514,0,0,0,.217-.324,2.947,2.947,0,0,0,.163-.3C487.538,325.04,487.594,324.784,487.612,324.789Z" transform="translate(-484.266 -324.789)" fill="#fff" />
            </g>
          </g>
          <g id="Group_54511" data-name="Group 54511" transform="translate(37.901 43.112)">
            <path id="Path_55419" data-name="Path 55419" d="M630.15,394.709a1.714,1.714,0,0,1-.278.049,2.647,2.647,0,0,1-.33.033,4.348,4.348,0,0,1-.44.014,4.886,4.886,0,0,1-.533-.026c-.192-.011-.394-.048-.607-.082a6.717,6.717,0,0,1-2.577-1.07c-.175-.127-.344-.244-.487-.372a4.905,4.905,0,0,1-.395-.359,4.341,4.341,0,0,1-.3-.321,2.676,2.676,0,0,1-.21-.257.141.141,0,1,1,.027-.022,3.152,3.152,0,0,0,.219.246c.09.092.185.2.307.31a5.264,5.264,0,0,0,.4.347c.144.124.312.238.486.361a6.965,6.965,0,0,0,2.547,1.057c.21.035.41.075.6.089a5.209,5.209,0,0,0,.527.036c.161.011.307,0,.436,0a3.2,3.2,0,0,0,.329-.018A1.729,1.729,0,0,1,630.15,394.709Z" transform="translate(-623.831 -392.085)" fill="#455a64" />
          </g>
          <g id="Group_54512" data-name="Group 54512" transform="translate(30.607 46.81)">
            <path id="Path_55420" data-name="Path 55420" d="M580.874,421.363c-.024-.005.34-1.689.813-3.76s.875-3.746.9-3.74-.34,1.688-.813,3.76S580.9,421.368,580.874,421.363Z" transform="translate(-580.873 -413.863)" fill="#455a64" />
          </g>
          <g id="Group_54569" data-name="Group 54569" transform="translate(5.694)">
            <g id="Group_54564" data-name="Group 54564">
              <g id="Group_54562" data-name="Group 54562" transform="translate(0 0.812)">
                <g id="Group_54513" data-name="Group 54513" transform="translate(0.362 0.532)">
                  <path id="Path_55421" data-name="Path 55421" d="M452.727,162.679h0a1.955,1.955,0,0,0-.172-.954l-5.152-11.28c-1.2-3.239-4.088-5.24-7.292-3.949l-.289.137a6.176,6.176,0,0,0-3.142,7.765c1.057,2.8,2.26,5.882,2.961,7.322a3.979,3.979,0,0,0,4.67,2.031l.6,1.147a2.893,2.893,0,0,0,3.315,1.859c2.065-.233,3.595-2.239,4.192-3.154A1.946,1.946,0,0,0,452.727,162.679Z" transform="translate(-436.281 -146.099)" fill="#c78976" />
                </g>
                <g id="Group_54516" data-name="Group 54516" transform="translate(8.365 15.186)">
                  <g id="Group_54515" data-name="Group 54515">
                    <g id="Group_54514" data-name="Group 54514">
                      <path id="Path_55422" data-name="Path 55422" d="M483.416,235.4a8.041,8.041,0,0,0,3.385-3,4.084,4.084,0,0,1-3.024,3.736Z" transform="translate(-483.416 -232.4)" fill="#925b4c" />
                    </g>
                  </g>
                </g>
                <g id="Group_54522" data-name="Group 54522" transform="translate(2.648 10.458)">
                  <g id="Group_54521" data-name="Group 54521">
                    <g id="Group_54520" data-name="Group 54520">
                      <g id="Group_54519" data-name="Group 54519">
                        <g id="Group_54518" data-name="Group 54518">
                          <g id="Group_54517" data-name="Group 54517">
                            <path id="Path_55423" data-name="Path 55423" d="M449.779,205.186a.473.473,0,0,0,.616.237.453.453,0,0,0,.259-.594.474.474,0,0,0-.616-.238A.453.453,0,0,0,449.779,205.186Z" transform="translate(-449.743 -204.554)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54529" data-name="Group 54529" transform="translate(2.074 9.021)">
                  <g id="Group_54528" data-name="Group 54528">
                    <g id="Group_54527" data-name="Group 54527">
                      <g id="Group_54526" data-name="Group 54526">
                        <g id="Group_54525" data-name="Group 54525">
                          <g id="Group_54524" data-name="Group 54524">
                            <g id="Group_54523" data-name="Group 54523">
                              <path id="Path_55424" data-name="Path 55424" d="M446.384,197.061c.079.031.29-.362.752-.579s.908-.145.931-.225c.013-.037-.089-.112-.287-.15a1.3,1.3,0,0,0-.778.111,1.248,1.248,0,0,0-.565.53C446.347,196.925,446.346,197.049,446.384,197.061Z" transform="translate(-446.36 -196.089)" fill="#263238" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54535" data-name="Group 54535" transform="translate(6.88 8.497)">
                  <g id="Group_54534" data-name="Group 54534">
                    <g id="Group_54533" data-name="Group 54533">
                      <g id="Group_54532" data-name="Group 54532">
                        <g id="Group_54531" data-name="Group 54531">
                          <g id="Group_54530" data-name="Group 54530">
                            <path id="Path_55425" data-name="Path 55425" d="M474.7,193.635a.473.473,0,0,0,.617.237.453.453,0,0,0,.259-.594.474.474,0,0,0-.616-.237A.453.453,0,0,0,474.7,193.635Z" transform="translate(-474.666 -193.003)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54542" data-name="Group 54542" transform="translate(6.642 6.969)">
                  <g id="Group_54541" data-name="Group 54541">
                    <g id="Group_54540" data-name="Group 54540">
                      <g id="Group_54539" data-name="Group 54539">
                        <g id="Group_54538" data-name="Group 54538">
                          <g id="Group_54537" data-name="Group 54537">
                            <g id="Group_54536" data-name="Group 54536">
                              <path id="Path_55426" data-name="Path 55426" d="M473.29,184.977c.079.031.291-.363.752-.579s.907-.145.931-.225c.013-.036-.088-.112-.287-.149a1.3,1.3,0,0,0-.778.111,1.246,1.246,0,0,0-.565.53C473.253,184.84,473.253,184.964,473.29,184.977Z" transform="translate(-473.267 -184.005)" fill="#263238" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54548" data-name="Group 54548" transform="translate(4.979 8.209)">
                  <g id="Group_54547" data-name="Group 54547">
                    <g id="Group_54546" data-name="Group 54546">
                      <g id="Group_54545" data-name="Group 54545">
                        <g id="Group_54544" data-name="Group 54544">
                          <g id="Group_54543" data-name="Group 54543">
                            <path id="Path_55427" data-name="Path 55427" d="M465.1,195.245a3.37,3.37,0,0,0-.812.218c-.123.043-.246.073-.3,0a.662.662,0,0,1-.086-.386q-.036-.505-.075-1.06a17.016,17.016,0,0,0-.325-2.712,17.023,17.023,0,0,0,.106,2.732q.048.554.092,1.058a.753.753,0,0,0,.157.49.327.327,0,0,0,.275.081.889.889,0,0,0,.213-.067A3.315,3.315,0,0,0,465.1,195.245Z" transform="translate(-463.474 -191.308)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54554" data-name="Group 54554" transform="translate(7.22 11.822)">
                  <g id="Group_54553" data-name="Group 54553">
                    <g id="Group_54552" data-name="Group 54552">
                      <g id="Group_54551" data-name="Group 54551">
                        <g id="Group_54550" data-name="Group 54550">
                          <g id="Group_54549" data-name="Group 54549">
                            <path id="Path_55428" data-name="Path 55428" d="M477.564,212.592c-.077.031.158.531-.1,1.088s-.819.753-.789.825c.008.035.165.041.393-.059a1.378,1.378,0,0,0,.672-.66,1.2,1.2,0,0,0,.054-.9C477.712,212.671,477.6,212.573,477.564,212.592Z" transform="translate(-476.672 -212.59)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54556" data-name="Group 54556" transform="translate(5.678 5.226)">
                  <g id="Group_54555" data-name="Group 54555">
                    <path id="Path_55429" data-name="Path 55429" d="M467.6,174.708c.1.1.536-.173,1.108-.363s1.078-.249,1.1-.4c.006-.069-.125-.151-.358-.189a2.027,2.027,0,0,0-1.679.577C467.61,174.512,467.556,174.658,467.6,174.708Z" transform="translate(-467.587 -173.742)" fill="#263238" />
                  </g>
                </g>
                <g id="Group_54558" data-name="Group 54558" transform="translate(1.592 7.601)">
                  <g id="Group_54557" data-name="Group 54557">
                    <path id="Path_55430" data-name="Path 55430" d="M443.57,188.708c.13.066.4-.192.776-.377s.738-.263.76-.407c.008-.068-.093-.149-.281-.182a1.249,1.249,0,0,0-1.238.632C443.5,188.545,443.51,188.674,443.57,188.708Z" transform="translate(-443.525 -187.729)" fill="#263238" />
                  </g>
                </g>
                <g id="Group_54559" data-name="Group 54559">
                  <path id="Path_55431" data-name="Path 55431" d="M434.548,150.283s-.1-2.251.6-3.116c.845-1.048,1.885-.068,2.791-.853s1.712-1.788,3.161-1.485c1.585.332,1.331,1.21,2.031,2.506,2.839,5.254,4.543,6.314,3.624,3.642-.1-.293.567-.879.126-2.108a14.637,14.637,0,0,0-2.238-4.395,4.719,4.719,0,0,0-2.795-1.415,7.858,7.858,0,0,0-5.33,1.316C435.214,145.162,433.313,147.992,434.548,150.283Z" transform="translate(-434.148 -142.963)" fill="#263238" />
                </g>
                <g id="Group_54560" data-name="Group 54560" transform="translate(12.308 6.723)">
                  <path id="Path_55432" data-name="Path 55432" d="M506.706,183.856a.9.9,0,0,1,.508-1.2c.584-.211,1.356-.187,1.865.986a1.7,1.7,0,0,1-1.4,2.5C507.657,186.1,507.075,184.729,506.706,183.856Z" transform="translate(-506.633 -182.555)" fill="#c78976" />
                </g>
                <g id="Group_54561" data-name="Group 54561" transform="translate(12.968 7.483)">
                  <path id="Path_55433" data-name="Path 55433" d="M511.019,188.881c.006-.011.047.01.12.011a.4.4,0,0,0,.273-.112.977.977,0,0,0,.066-.984,1.3,1.3,0,0,0-.326-.475.456.456,0,0,0-.393-.16.2.2,0,0,0-.171.192c0,.07.028.106.019.114s-.06-.016-.08-.109a.252.252,0,0,1,.025-.166.3.3,0,0,1,.177-.14.555.555,0,0,1,.518.158,1.341,1.341,0,0,1,.377.525,1.025,1.025,0,0,1-.139,1.128.423.423,0,0,1-.355.089C511.045,188.93,511.014,188.888,511.019,188.881Z" transform="translate(-510.523 -187.034)" fill="#925b4c" />
                </g>
              </g>
              <path id="Path_55434" data-name="Path 55434" d="M446.795,143.063a2.15,2.15,0,0,1-.679.811.74.74,0,0,1-.964-.12c-.092-.131-.135-.313-.281-.379-.174-.079-.361.06-.524.159a1.447,1.447,0,0,1-1.8-.3.607.607,0,0,1-.7.417,3.245,3.245,0,0,1-.84-.333.932.932,0,0,0-.87-.02c-.141.093-.241.25-.4.309-.28.1-.561-.15-.727-.4s-.346-.545-.642-.582c-.275-.035-.512.172-.742.326s-.578.246-.756.034a.538.538,0,0,1-.084-.415,1.468,1.468,0,0,1,1.33-1.242,18.346,18.346,0,0,0,4.571-3.108,2.054,2.054,0,0,1,1.8.486,1.463,1.463,0,0,1,2.4,1.22,1.39,1.39,0,0,1,1.164.674.8.8,0,0,1-.468,1.12.81.81,0,0,1-.922,1.329" transform="translate(-436.329 -138.179)" fill="#263238" />
              <g id="Group_54563" data-name="Group 54563" transform="translate(1.915 2.866)">
                <path id="Path_55435" data-name="Path 55435" d="M445.429,156.018s0,.014.018.037a1.207,1.207,0,0,0,.065.1,1.425,1.425,0,0,0,.335.325,1.624,1.624,0,0,0,.683.27,1.51,1.51,0,0,0,.952-.157l-.056-.029a.82.82,0,0,0,.308.546,2.346,2.346,0,0,0,.589.338,1.688,1.688,0,0,0,.34.127.792.792,0,0,0,.382-.033.827.827,0,0,0,.548-.548l-.087.014a1.7,1.7,0,0,0,1.154.756,1.239,1.239,0,0,0,.777-.1,2.235,2.235,0,0,0,.594-.459l-.066,0a1.785,1.785,0,0,0,.784.4.95.95,0,0,0,.806-.143,1.248,1.248,0,0,0,.452-1.294l-.036.043a1.167,1.167,0,0,0,.753-.229,1.269,1.269,0,0,0,.388-.451,1.1,1.1,0,0,0,.111-.359.546.546,0,0,0,0-.131,1.364,1.364,0,0,1-.146.471,1.257,1.257,0,0,1-.386.424,1.117,1.117,0,0,1-.716.2l-.049,0,.013.047a1.178,1.178,0,0,1-.436,1.207.863.863,0,0,1-.735.122,1.7,1.7,0,0,1-.739-.385l-.034-.03-.032.032a2.156,2.156,0,0,1-.569.438,1.143,1.143,0,0,1-.718.088,1.608,1.608,0,0,1-1.09-.711l-.059-.091-.028.105a.735.735,0,0,1-.484.484.7.7,0,0,1-.337.032,1.645,1.645,0,0,1-.32-.118,2.292,2.292,0,0,1-.571-.32.745.745,0,0,1-.286-.491l-.006-.057-.05.028a1.457,1.457,0,0,1-.907.163,1.612,1.612,0,0,1-.668-.245A1.756,1.756,0,0,1,445.429,156.018Z" transform="translate(-445.429 -155.056)" fill="#455a64" />
              </g>
            </g>
            <g id="Group_54565" data-name="Group 54565" transform="translate(0.29 9.311)">
              <path id="Path_55436" data-name="Path 55436" d="M440.372,195.126a4.09,4.09,0,0,0-.125-.535,2.229,2.229,0,0,0-.9-1.151,2.2,2.2,0,0,0-2.1-.16,2.2,2.2,0,0,0-.962.837,2.188,2.188,0,0,0,.185,2.588,2.2,2.2,0,0,0,1.072.692,2.218,2.218,0,0,0,2.785-1.725,4.1,4.1,0,0,0,.048-.547.889.889,0,0,1,.012.143,1.023,1.023,0,0,1,0,.174,1.566,1.566,0,0,1-.025.237,2.254,2.254,0,0,1-1.641,1.806,2.277,2.277,0,0,1-1.2-.009,2.277,2.277,0,0,1-1.115-.714,2.266,2.266,0,0,1-.192-2.691,2.276,2.276,0,0,1,1-.866,2.276,2.276,0,0,1,1.187-.179,2.254,2.254,0,0,1,1.881,1.554,1.577,1.577,0,0,1,.058.231,1.039,1.039,0,0,1,.026.172A.878.878,0,0,1,440.372,195.126Z" transform="translate(-435.857 -193.013)" fill="#263238" />
            </g>
            <g id="Group_54566" data-name="Group 54566" transform="translate(5.567 6.8)">
              <path id="Path_55437" data-name="Path 55437" d="M471.449,180.343a4.094,4.094,0,0,0-.125-.535,2.232,2.232,0,0,0-.9-1.151,2.2,2.2,0,0,0-2.1-.16,2.2,2.2,0,0,0-.962.837,2.187,2.187,0,0,0,.185,2.589,2.2,2.2,0,0,0,1.072.692,2.218,2.218,0,0,0,2.785-1.725,4.083,4.083,0,0,0,.048-.547.876.876,0,0,1,.012.143,1.008,1.008,0,0,1,0,.174,1.546,1.546,0,0,1-.025.236A2.255,2.255,0,0,1,469.8,182.7a2.277,2.277,0,0,1-1.2-.008,2.279,2.279,0,0,1-1.115-.714,2.266,2.266,0,0,1-.193-2.691,2.281,2.281,0,0,1,1-.866,2.277,2.277,0,0,1,1.186-.179,2.255,2.255,0,0,1,1.881,1.554,1.565,1.565,0,0,1,.058.231,1.006,1.006,0,0,1,.026.172A.87.87,0,0,1,471.449,180.343Z" transform="translate(-466.935 -178.229)" fill="#263238" />
            </g>
            <g id="Group_54567" data-name="Group 54567" transform="translate(4.414 9.677)">
              <path id="Path_55438" data-name="Path 55438" d="M461.391,195.212a1.535,1.535,0,0,0-1.246.6c-.009,0,.018-.086.1-.2a1.166,1.166,0,0,1,.931-.451C461.313,195.173,461.393,195.2,461.391,195.212Z" transform="translate(-460.143 -195.169)" fill="#263238" />
            </g>
            <g id="Group_54568" data-name="Group 54568" transform="translate(9.951 8.189)">
              <path id="Path_55439" data-name="Path 55439" d="M495.077,186.434a15.3,15.3,0,0,1-2.322.058,15.291,15.291,0,0,1,2.322-.058Z" transform="translate(-492.755 -186.41)" fill="#263238" />
            </g>
          </g>
        </g>
        <g id="Device" transform="translate(65.158 148.916)">
          <g id="Group_54571" data-name="Group 54571" transform="translate(0)">
            <path id="Path_55440" data-name="Path 55440" d="M398.98,453.215h10.262a.512.512,0,0,1,.512.512h0a.512.512,0,0,1-.512.512H398.98a.512.512,0,0,1-.512-.512h0A.512.512,0,0,1,398.98,453.215Z" transform="translate(-379.276 -439.842)" fill="#e0e0e0" />
            <path id="Path_55441" data-name="Path 55441" d="M306.778,388.855H287.365l-1.916-13.11a1.118,1.118,0,0,1,1.106-1.286l17.043.139a1.07,1.07,0,0,1,1.164.812Z" transform="translate(-285.436 -374.459)" fill="#e0e0e0" />
            <path id="Path_55442" data-name="Path 55442" d="M342.317,410.7a1.067,1.067,0,1,1-1.067-1.067A1.067,1.067,0,0,1,342.317,410.7Z" transform="translate(-330.887 -403.664)" fill="#fff" />
            <g id="Group_54570" data-name="Group 54570" transform="translate(18.161 0.139)">
              <path id="Path_55443" data-name="Path 55443" d="M394.728,389.418c-.024,0-.567-3.159-1.212-7.064s-1.148-7.075-1.124-7.079.566,3.158,1.212,7.064S394.752,389.414,394.728,389.418Z" transform="translate(-392.391 -375.275)" fill="#263238" />
            </g>
          </g>
        </g>
        <g id="Table" transform="translate(21.488 163.311)">
          <g id="Group_54651" data-name="Group 54651" transform="translate(0)">
            <g id="Group_54650" data-name="Group 54650">
              <g id="Group_54649" data-name="Group 54649">
                <g id="Group_54576" data-name="Group 54576" transform="translate(0 0)">
                  <g id="Group_54575" data-name="Group 54575">
                    <g id="Group_54574" data-name="Group 54574">
                      <g id="Group_54573" data-name="Group 54573">
                        <g id="Group_54572" data-name="Group 54572">
                          <rect id="Rectangle_19783" data-name="Rectangle 19783" width="114.36" height="2.812" fill="#455a64" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54582" data-name="Group 54582" transform="translate(0.181 0.042)">
                  <g id="Group_54581" data-name="Group 54581">
                    <g id="Group_54580" data-name="Group 54580">
                      <g id="Group_54579" data-name="Group 54579">
                        <g id="Group_54578" data-name="Group 54578">
                          <g id="Group_54577" data-name="Group 54577">
                            <path id="Path_55444" data-name="Path 55444" d="M53.356,459.486s-.01.011-.036.03-.064.049-.12.084a2.651,2.651,0,0,1-.575.259,5.091,5.091,0,0,1-1.1.208,13.81,13.81,0,0,1-1.541.038c-.573-.011-1.195-.06-1.855-.12s-1.366-.129-2.112-.142a22.35,22.35,0,0,0-4.754.518c-1.632.328-3.156.7-4.615.928-.728.114-1.434.2-2.1.235a15.789,15.789,0,0,1-1.87,0,10.1,10.1,0,0,1-2.564-.461,4.566,4.566,0,0,1-.793-.333.3.3,0,0,1,.055.02l.153.064c.133.056.336.136.612.223a10.433,10.433,0,0,0,2.548.432,15.868,15.868,0,0,0,1.847-.008c.662-.041,1.36-.124,2.081-.24,1.445-.229,2.964-.6,4.6-.932a22.289,22.289,0,0,1,4.806-.517c.759.015,1.467.089,2.127.15s1.275.115,1.84.129a14.136,14.136,0,0,0,1.525-.026,5.2,5.2,0,0,0,1.09-.192A3.056,3.056,0,0,0,53.356,459.486Z" transform="translate(-29.311 -459.485)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54588" data-name="Group 54588" transform="translate(1.255 0.076)">
                  <g id="Group_54587" data-name="Group 54587">
                    <g id="Group_54586" data-name="Group 54586">
                      <g id="Group_54585" data-name="Group 54585">
                        <g id="Group_54584" data-name="Group 54584">
                          <g id="Group_54583" data-name="Group 54583">
                            <path id="Path_55445" data-name="Path 55445" d="M46.1,459.7a2.894,2.894,0,0,1-.346.166,10.036,10.036,0,0,1-1.043.378,13.859,13.859,0,0,1-1.706.39,16.024,16.024,0,0,1-2.229.2,14.352,14.352,0,0,1-3.986-.463,2.711,2.711,0,0,1-.962-.442.762.762,0,0,1-.157-.17c-.024-.042-.034-.064-.029-.065a1.873,1.873,0,0,0,.231.216,2.943,2.943,0,0,0,.962.41,14.936,14.936,0,0,0,3.937.434,16.506,16.506,0,0,0,2.2-.187,14.889,14.889,0,0,0,1.7-.37c.462-.134.818-.261,1.057-.353A3.592,3.592,0,0,1,46.1,459.7Z" transform="translate(-35.638 -459.687)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54594" data-name="Group 54594" transform="translate(29.418 0.647)">
                  <g id="Group_54593" data-name="Group 54593">
                    <g id="Group_54592" data-name="Group 54592">
                      <g id="Group_54591" data-name="Group 54591">
                        <g id="Group_54590" data-name="Group 54590">
                          <g id="Group_54589" data-name="Group 54589">
                            <path id="Path_55446" data-name="Path 55446" d="M210.63,464.269s.062.011.177.036.292.055.528.079a5.324,5.324,0,0,0,2-.176l-.006.036a7.22,7.22,0,0,0-2.482-.544,7.89,7.89,0,0,0-3.228.471c-.121.049-.238.109-.25.183s.082.14.214.181a2.318,2.318,0,0,0,.443.087l.487.062c.654.082,1.33.163,2.03.221a21.338,21.338,0,0,0,2.159.083c.368,0,.739-.025,1.108-.06.185-.018.369-.038.552-.065a4.446,4.446,0,0,0,.534-.1,2.069,2.069,0,0,0,.693-.281c.175-.122.231-.289.133-.432a1.126,1.126,0,0,0-.544-.341,6.206,6.206,0,0,0-.759-.208,17.619,17.619,0,0,0-3.176-.37,22.623,22.623,0,0,0-5.422.473,18.27,18.27,0,0,0-3.264,1c-.349.152-.617.271-.789.36l-.2.1a.516.516,0,0,1-.072.031.361.361,0,0,1,.062-.037l.192-.1c.166-.092.429-.216.774-.372a17.5,17.5,0,0,1,3.262-1.03,22.542,22.542,0,0,1,5.465-.493,17.763,17.763,0,0,1,3.22.371,6.357,6.357,0,0,1,.784.215,1.235,1.235,0,0,1,.6.38c.117.168.047.369-.153.507a2.223,2.223,0,0,1-.748.306,4.64,4.64,0,0,1-.557.1c-.186.028-.374.048-.563.066-.376.035-.754.056-1.127.06a21.417,21.417,0,0,1-2.184-.085c-.705-.059-1.384-.142-2.039-.225l-.487-.063a2.523,2.523,0,0,1-.479-.1.669.669,0,0,1-.2-.1.158.158,0,0,1-.073-.152c.025-.112.178-.179.3-.231a8.06,8.06,0,0,1,3.31-.473,7.238,7.238,0,0,1,2.52.567l.046.021-.052.015a5.341,5.341,0,0,1-2.045.16A3.563,3.563,0,0,1,210.63,464.269Z" transform="translate(-201.497 -463.049)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54600" data-name="Group 54600" transform="translate(49.295 0.115)">
                  <g id="Group_54599" data-name="Group 54599">
                    <g id="Group_54598" data-name="Group 54598">
                      <g id="Group_54597" data-name="Group 54597">
                        <g id="Group_54596" data-name="Group 54596">
                          <g id="Group_54595" data-name="Group 54595">
                            <path id="Path_55447" data-name="Path 55447" d="M322.532,459.917a.393.393,0,0,1,.113.142.639.639,0,0,1,.076.422c-.038.362-.433.838-1.114,1.227a6.007,6.007,0,0,1-1.04.5,6.662,6.662,0,0,1-1,.274c-.3.057-.553.086-.727.1a1.756,1.756,0,0,1-.274.013c-.005-.015.384-.045.967-.168a7.023,7.023,0,0,0,.966-.282,6.221,6.221,0,0,0,1.009-.49,2.118,2.118,0,0,0,1.117-1.179A.911.911,0,0,0,322.532,459.917Z" transform="translate(-318.561 -459.917)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54606" data-name="Group 54606" transform="translate(57.126 0.81)">
                  <g id="Group_54605" data-name="Group 54605">
                    <g id="Group_54604" data-name="Group 54604">
                      <g id="Group_54603" data-name="Group 54603">
                        <g id="Group_54602" data-name="Group 54602">
                          <g id="Group_54601" data-name="Group 54601">
                            <path id="Path_55448" data-name="Path 55448" d="M381.361,465.864c-.013.006-.161-.119-.525-.287a5.391,5.391,0,0,0-1.789-.453,7.042,7.042,0,0,0-1.362.022,10.3,10.3,0,0,1-1.6.082,4.532,4.532,0,0,1-1.589-.438,7.136,7.136,0,0,0-1.6-.532,10.239,10.239,0,0,0-3.546-.031,11.844,11.844,0,0,0-2.624.747,15.182,15.182,0,0,0-1.521.73l-.388.215a1.009,1.009,0,0,1-.14.073.743.743,0,0,1,.12-.084c.082-.053.206-.13.37-.226a13.059,13.059,0,0,1,1.5-.753,11.6,11.6,0,0,1,2.647-.772,10.375,10.375,0,0,1,3.629.026,7.256,7.256,0,0,1,1.633.545,4.352,4.352,0,0,0,1.52.427,10.284,10.284,0,0,0,1.562-.075,7.129,7.129,0,0,1,1.4-.012,5.2,5.2,0,0,1,1.808.485A2.009,2.009,0,0,1,381.361,465.864Z" transform="translate(-364.685 -464.009)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54612" data-name="Group 54612" transform="translate(74.078 0.027)">
                  <g id="Group_54611" data-name="Group 54611">
                    <g id="Group_54610" data-name="Group 54610">
                      <g id="Group_54609" data-name="Group 54609">
                        <g id="Group_54608" data-name="Group 54608">
                          <g id="Group_54607" data-name="Group 54607">
                            <path id="Path_55449" data-name="Path 55449" d="M482.578,459.4a.874.874,0,0,1-.125.1c-.089.06-.206.155-.391.261a11,11,0,0,1-1.669.834,18.254,18.254,0,0,1-2.9.9c-.579.132-1.2.251-1.868.335a16.809,16.809,0,0,1-2.09.151,17.469,17.469,0,0,1-3.965-.464,17.756,17.756,0,0,1-2.881-.919,11.444,11.444,0,0,1-1.658-.841c-.184-.107-.3-.2-.392-.261a.87.87,0,0,1-.126-.1,1.121,1.121,0,0,1,.148.086c.1.056.221.147.41.25a12.813,12.813,0,0,0,1.677.817,18.337,18.337,0,0,0,2.871.9,17.505,17.505,0,0,0,3.914.452,16.788,16.788,0,0,0,2.06-.147c.657-.082,1.276-.2,1.85-.327a18.847,18.847,0,0,0,2.887-.881,12.231,12.231,0,0,0,1.688-.809c.19-.1.313-.194.409-.251A1.128,1.128,0,0,1,482.578,459.4Z" transform="translate(-464.518 -459.4)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54618" data-name="Group 54618" transform="translate(78.097)">
                  <g id="Group_54617" data-name="Group 54617">
                    <g id="Group_54616" data-name="Group 54616">
                      <g id="Group_54615" data-name="Group 54615">
                        <g id="Group_54614" data-name="Group 54614">
                          <g id="Group_54613" data-name="Group 54613">
                            <path id="Path_55450" data-name="Path 55450" d="M499.61,459.241a.471.471,0,0,1-.1.044c-.067.026-.164.067-.3.113-.26.1-.648.224-1.145.365a18.472,18.472,0,0,1-1.81.414,13.712,13.712,0,0,1-2.361.227,15.992,15.992,0,0,1-4.193-.6c-.5-.141-.881-.274-1.135-.375-.13-.048-.223-.091-.289-.119a.424.424,0,0,1-.094-.046c.007-.005.149.048.413.139s.651.217,1.149.35a16.688,16.688,0,0,0,4.149.567,13.973,13.973,0,0,0,2.323-.217c.7-.118,1.305-.263,1.8-.395s.893-.253,1.161-.34S499.6,459.236,499.61,459.241Z" transform="translate(-488.189 -459.241)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54624" data-name="Group 54624" transform="translate(89.561 0.209)">
                  <g id="Group_54623" data-name="Group 54623">
                    <g id="Group_54622" data-name="Group 54622">
                      <g id="Group_54621" data-name="Group 54621">
                        <g id="Group_54620" data-name="Group 54620">
                          <g id="Group_54619" data-name="Group 54619">
                            <path id="Path_55451" data-name="Path 55451" d="M575.665,460.553s-.08,0-.228,0l-.645-.013-.465-.012h-.555c-.4,0-.851-.006-1.35.007l-.784.014-.846.032c-.587.017-1.2.062-1.853.1-1.292.092-2.694.221-4.118.4s-2.717.4-3.858.619c-.555.117-1.1.221-1.569.337l-.691.162-.611.161c-.4.1-.726.2-1.02.289l-.411.122-.325.109-.456.15a1.281,1.281,0,0,1-.172.049.631.631,0,0,1,.135-.059l.424-.16.312-.115.4-.127c.287-.091.61-.2,1-.3l.606-.167.689-.167c.468-.12,1.013-.226,1.57-.346,1.145-.224,2.452-.445,3.885-.631s2.856-.31,4.163-.4c.659-.034,1.281-.077,1.875-.091l.854-.028.792-.009c.5-.01.961,0,1.361,0l.557.008.466.018.643.028C575.59,460.545,575.666,460.55,575.665,460.553Z" transform="translate(-555.708 -460.474)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54630" data-name="Group 54630" transform="translate(52.851 0.296)">
                  <g id="Group_54629" data-name="Group 54629">
                    <g id="Group_54628" data-name="Group 54628">
                      <g id="Group_54627" data-name="Group 54627">
                        <g id="Group_54626" data-name="Group 54626">
                          <g id="Group_54625" data-name="Group 54625">
                            <path id="Path_55452" data-name="Path 55452" d="M362.51,463.3a4.28,4.28,0,0,0-.629-.421,7.773,7.773,0,0,0-2.3-.724c-.537-.095-1.143-.165-1.8-.223s-1.362-.1-2.1-.176-1.506-.188-2.283-.322-1.586-.271-2.436-.331a17.517,17.517,0,0,0-4.745.368c-.7.144-1.339.318-1.93.488s-1.134.338-1.626.495c-.984.313-1.768.58-2.308.765l-.621.214-.163.055a.315.315,0,0,1-.058.016.227.227,0,0,1,.05-.023l.155-.061c.138-.053.342-.13.608-.227.532-.194,1.308-.468,2.288-.788.49-.16,1.031-.331,1.622-.5s1.233-.35,1.939-.5a17.572,17.572,0,0,1,4.808-.379c.867.061,1.68.2,2.458.335s1.53.255,2.266.324,1.441.122,2.1.184,1.268.135,1.81.235a7.5,7.5,0,0,1,2.312.755A2.1,2.1,0,0,1,362.51,463.3Z" transform="translate(-339.505 -460.985)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54636" data-name="Group 54636" transform="translate(44.351 0.042)">
                  <g id="Group_54635" data-name="Group 54635">
                    <g id="Group_54634" data-name="Group 54634">
                      <g id="Group_54633" data-name="Group 54633">
                        <g id="Group_54632" data-name="Group 54632">
                          <g id="Group_54631" data-name="Group 54631">
                            <path id="Path_55453" data-name="Path 55453" d="M295.194,459.488c.007,0,.011.065-.017.18a1.434,1.434,0,0,1-.224.474,3.668,3.668,0,0,1-1.758,1.264,17.284,17.284,0,0,1-2.605.674c-.348.065-.633.112-.832.141a2.28,2.28,0,0,1-.312.036,2.208,2.208,0,0,1,.3-.066l.815-.164a19.555,19.555,0,0,0,2.561-.688,3.882,3.882,0,0,0,1.742-1.219A2.646,2.646,0,0,0,295.194,459.488Z" transform="translate(-289.447 -459.488)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54642" data-name="Group 54642" transform="translate(23.55 0.041)">
                  <g id="Group_54641" data-name="Group 54641">
                    <g id="Group_54640" data-name="Group 54640">
                      <g id="Group_54639" data-name="Group 54639">
                        <g id="Group_54638" data-name="Group 54638">
                          <g id="Group_54637" data-name="Group 54637">
                            <path id="Path_55454" data-name="Path 55454" d="M166.942,462.255a1.929,1.929,0,0,1,.278-.15c.184-.094.462-.222.814-.375a29.8,29.8,0,0,1,2.916-1.056c1.189-.367,2.3-.661,3.1-.869.4-.1.731-.186.959-.241a3.169,3.169,0,0,1,.36-.078,2.719,2.719,0,0,1-.337.106l-.939.264c-.794.221-1.9.522-3.079.888s-2.21.74-2.925,1.032c-.358.146-.645.267-.839.353A2.505,2.505,0,0,1,166.942,462.255Z" transform="translate(-166.941 -459.485)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_54648" data-name="Group 54648" transform="translate(11.155 0.126)">
                  <g id="Group_54647" data-name="Group 54647">
                    <g id="Group_54646" data-name="Group 54646">
                      <g id="Group_54645" data-name="Group 54645">
                        <g id="Group_54644" data-name="Group 54644">
                          <g id="Group_54643" data-name="Group 54643">
                            <path id="Path_55455" data-name="Path 55455" d="M111.723,460.012c0,.009-.264-.019-.738,0a8.457,8.457,0,0,0-1.908.326,21.771,21.771,0,0,0-2.478.935c-.457.187-.946.38-1.493.544a12.857,12.857,0,0,1-1.785.386,29.021,29.021,0,0,1-3.653.247c-1.131.018-2.147.006-3,.026s-1.536.063-2,.11c-.234.024-.415.044-.538.061a1.675,1.675,0,0,1-.189.019,1.549,1.549,0,0,1,.182-.035c.12-.021.3-.046.534-.076.468-.056,1.156-.112,2.011-.136s1.872-.02,3-.043a29.64,29.64,0,0,0,3.622-.25,12.84,12.84,0,0,0,1.756-.378c.538-.16,1.024-.349,1.481-.534a21.094,21.094,0,0,1,2.511-.926,8.3,8.3,0,0,1,1.948-.307c.24-.005.427,0,.552.01A1.637,1.637,0,0,1,111.723,460.012Z" transform="translate(-93.94 -459.981)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

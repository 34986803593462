import React from "react";
import { connect } from "react-redux";
import { PasswordExpiredContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Forms, BGLoading, Icons, Buttons } from "components";
import { adminService } from "apiServices";
import {
  setReduxAuthen,
  setReduxCompanyList,
  setReduxCompanySelected,
} from "store/actions";
import { ROUTE_PATH } from "utils/constants";
import { toast } from "react-toastify";
import { renderErrorMessage } from "utils/functions/renderErrorMessage";
import { setReduxRewardCategory } from "store/actions";
import queryString from "query-string";

class PasswordExpiredContainer extends React.Component {
  state = { isBgLoading: true, step: 1 };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isBgLoading: false,
      });
    }, 1000);
  }

  onSubmit = async (values) => {
    const { username } = queryString.parse(this.props.location.search);

    if (username !== "" && username) {
      this.setState({
        isBgLoading: true,
      });

      if (values) {
        let res = await adminService.POST_ADMIN_RESET_PASSWORD_BY_USERNAME(
          username
        );

        if (res && res.success) {
          this.props.history.push(
            `${ROUTE_PATH.RESET_PASSWORD}?ref_no=${res.data.ref_no} `
          );
          this.props.setReduxRewardCategory(res.data.email);
          this.setState({
            step: 2,
            errorMessage: null,
            email: res.data.email,
            dataCheckEmail: res.data,
            isBgLoading: false,
          });
        } else {
          let txtErr = renderErrorMessage(res.error_code, res.error);

          // toast.error(res.error);
          this.setState({
            errorMessage: txtErr,
            isBgLoading: false,
          });
        }
      }
    } else {
      toast.error("ไม่มีข้อมูล Username นี้");
    }
  };

  onSubmitOTP = async (values) => {
    const { dataCheckEmail } = this.state;

    this.setState({
      isBgLoading: true,
    });

    let res = await adminService.GET_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      `?password=${values.otp}`
    );

    if (res && res.success) {
      this.setState({
        passwordOtp: values.otp,
        step: 3,
        email: values.email,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  onSubmitChangePassword = async (values) => {
    const { dataCheckEmail, passwordOtp } = this.state;

    this.setState({
      isBgLoading: true,
    });

    let params = {
      password: passwordOtp,
      new_password: values.password,
    };

    let res = await adminService.PUT_RESET_PASSWORD_BY_REF_NO(
      dataCheckEmail.ref_no,
      params
    );

    if (res && res.success) {
      toast.success("Change password success");
      this.setState({
        step: 4,
        isBgLoading: false,
      });
    } else {
      toast.error(this.renderError(res.error_code));
      this.setState({
        isBgLoading: false,
      });
    }
  };

  renderError = (e) => {
    switch (e) {
      case "REFNO_INVALID":
        return "ref no. ไม่ถูกต้อง";

      case "OTP_EXPIRED":
        return "OTP หมดอายุ";

      case "PASSWORD_INCORRECT":
        return "รหัสไม่ถูกต้อง";

      default:
        return "เกิดข้อผิดพลาด";
    }
  };

  renderStep = () => {
    const { step, email, dataCheckEmail, errorMessage } = this.state;

    switch (step) {
      case 1:
        return (
          <div className="forgot_form_container">
            <div className="svg_top">
              <Icons.Alert4 />
            </div>
            <div className="logo_icon">ไม่สามารถเข้าสู่ระบบได้</div>
            <div className="box_input">
              <div className="group_input">
                เนื่องจากรหัสผ่านของคุณหมดอายุ เพื่อความปลอดภัยของข้อมูล
              </div>
              <div className="group_input">
                เเละ ความสะดวกในการใช้งาน เราขอแนะนำให้คุณตั้งค่ารหัสผ่านใหม่
              </div>
              <div className="group_input">
                โดยจะได้รับรหัส OTP ทางอีเมลเพื่อยืนยันตัวตน
              </div>
            </div>
            <div className="btn_layout">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label="ตั้งค่ารหัสผ่าน"
                onClick={this.onSubmit}
              />
              {errorMessage && <div className="txt_err">{errorMessage}</div>}
            </div>
            <div className="box_logo">
              <img
                className="img_logo"
                src={"/assets/images/logo/invitrace-long.svg"}
                alt="img"
              />
            </div>
          </div>
        );

      case 2:
        return (
          <Forms.OTPForm
            onSubmit={this.onSubmitOTP}
            email={email}
            data={dataCheckEmail && dataCheckEmail}
          />
        );

      case 3:
        return (
          <Forms.ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
        );

      case 4:
        return (
          <div className="b_success">
            <div className="logo_icon">
              <Icons.RoundCheck width="56" height="56" />
            </div>
            <div className="text_b">สร้างรหัสผ่านใหม่สำเร็จ</div>
            <div className="fw">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label="เข้าสู่ระบบ"
                onClick={this.handleGoLogin}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };

  handleClearTextError = () => {
    this.setState({
      errorMessage: null,
    });
  };

  handleGoLogin = () => {
    this.props.history.push(ROUTE_PATH.LOGIN);
  };

  render() {
    const { isBgLoading } = this.state;

    return (
      <PasswordExpiredContainerStyled>
        <BGLoading visible={isBgLoading} />
        <div className="logo_show">
          <img
            className="img_logo"
            src={"/assets/images/logo/Mesook.svg"}
            alt="img"
          />
        </div>
        <div className="form_layout">{this.renderStep()}</div>
      </PasswordExpiredContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxCompanyList: (data) => dispatch(setReduxCompanyList(data)),
  setReduxCompanySelected: (data) => dispatch(setReduxCompanySelected(data)),
  setReduxRewardCategory: (data) => dispatch(setReduxRewardCategory(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordExpiredContainer);

import styled from 'styled-components'

export const AddLuckyRewardFormStyled = styled.div`
  .box_add_reward_lucky {
    display: flex;
    margin-top: 32px;
    column-gap: 32px;
    .left_add {
      width: 35%;
      min-width: 414px;
      flex-shrink: 0;
      border-radius: 8px;
      padding: 16px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    }
    .right_add {
      width: 65%;
      border-radius: 8px;
      padding: 16px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      .title_box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        margin-bottom: 20px;
        .sub_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-family: ${({ theme }) => theme.FONT.STYLE.THIN};
        }
      }
      .status_zone {
        margin-bottom: 14px;
        display: flex;
        justify-content: flex-end;
        column-gap: 4px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .blue_text {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
      }
      .list_input {
        display: flex;
        row-gap: 14px;
        flex-direction: column;
        row-gap: 14px;
        .line_input {
          display: flex;
          column-gap: 56px;
          align-items: center;
          &.flex-start {
            align-items: flex-start;
          }
          .g_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            width: 100px;
            flex-shrink: 0;
            .g_sub_lbl {
              font-size: ${({ theme }) => theme.FONT.SIZE.S12};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              color: ${({ theme }) => theme.COLORS.GRAY_24};
            }
          }
          .w_input {
            
            width: 100%;
          }
        }
      }
    }
  }
  .btn_zone_layout {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    .w134 {
      width: 134px;
    }
  }
`

import styled from 'styled-components';

export const StatusLabelStyled = styled.div`
  font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  color: ${({ theme }) => theme.COLORS.GRAY_24};
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  .group_status {
    display: flex;
    align-items: baseline;
    column-gap: 8px;
    background: ${({ theme }) => theme.COLORS.GREEN_8};
    border-radius: 12px;
    justify-content: center;
    min-width: 100px;
    padding: 4.4px;
    .ic {
      display: flex;
    }
  }

  .theme_user {
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    .group_status {
      padding: 3.2px;
    }
  }

  .theme_admin_portal {
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    .group_status {
      padding: 3.2px;
      min-width: 100px;
      width: fit-content;
    }
  }

  .theme_health {
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    .group_status {
      width: fit-content;
      padding: 1.7px 16px;
    }
  }

  .theme_mission {
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    .group_status {
      width: fit-content;
      padding: 2.9px 10px;
    }
  }

  .theme_follow {
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    .group_status {
      width: fit-content;
      padding: 2.9px 10px;
    }
  }
`;

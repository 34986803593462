import styled from 'styled-components'

export const LabelIconStyled = styled.div`
  .theme_standard_label_icon {
    display: flex;
    align-items: ${(props) => (props.align ? props.align : 'baseline')};
    font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'noto_sans_thai_bold, noto_sans_bold')};
    color: ${(props) => (props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.RED_3)};
    font-size: ${(props) => (props.fontSizeLabelButtom ? props.fontSizeLabelButtom : ({ theme }) => theme.FONT.SIZE.S24)};
    .svg_show_f {
      margin-right: ${(props) => (props.marginRight ? props.marginRight : '12px')};
      display: flex;
    }
    .show_label {

    }
    .svg_show_b {
      margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '12px')};
      display: flex;
    }
  }
 
`

import { MaleFemaleAverageResearchStyled } from "./styled";
import {
  Blocks,
  Typographys,
  Bars,
  Icons,
  Buttons,
  Skeleton,
} from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { RENDER_MONTH } from "utils/functions/date";
import moment from "moment";
import theme from "styles/theme.json";

const MaleFemaleAverageResearch = ({ data, fetchData }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_percent, _setPercent] = useState();
  const [_data, _setData] = useState();
  const [_filter, _setFilter] = useState("weekly");
  const [_dataLoaded, _setDataLoaded] = useState();
  const [_isShorLeftArrow, _setIsShorLeftArrow] = useState(false);
  const [_isShorRightArrow, _setIsShorRightArrow] = useState(false);
  const [_page, _setPage] = useState({
    per_page: 8,
    offset: 0,
    current_page: 1,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      _setData(data);
      _setDataLoaded(data);
      _setPercent({
        index: [data.length - 1],
        male_good: data[data.length - 1].male_good.percent,
        female_good: data[data.length - 1].female_good.percent,
        male_moderate: data[data.length - 1].male_moderate.percent,
        female_moderate: data[data.length - 1].female_moderate.percent,
        male_depress: data[data.length - 1].male_depress.percent,
        female_depress: data[data.length - 1].female_depress.percent,
      });
    }
  }, [data]);

  const handleClickFilter = async (value) => {
    _setFilter(value);
    _setLoadSkeleton(true);
    let res = await fetchData(`?period=${value}&offset=0&per_page=8`);
    if (res && res.length > 0) {
      _setData(res);
      _setDataLoaded(res);
      _setLoadSkeleton(false);
      _setPercent({
        index: [data.length - 1],
        male_good: res[res.length - 1].male_good.percent,
        female_good: res[res.length - 1].female_good.percent,
        male_moderate: res[res.length - 1].male_moderate.percent,
        female_moderate: res[res.length - 1].female_moderate.percent,
        male_depress: res[res.length - 1].male_depress.percent,
        female_depress: res[res.length - 1].female_depress.percent,
      });
      resetPagination();
    } else {
      _setLoadSkeleton(false);
    }
  };

  const handleClickItemGraph = (index) => {
    if (!_percent || (_percent && index !== _percent.index)) {
      _setPercent({
        index: index,
        male_good: _data[index].male_good.percent,
        female_good: _data[index].female_good.percent,
        male_moderate: _data[index].male_moderate.percent,
        female_moderate: _data[index].female_moderate.percent,
        male_depress: _data[index].male_depress.percent,
        female_depress: _data[index].female_depress.percent,
      });
    }
  };

  const renderLabelX = (key, value) => {
    let dateStart;
    let dateEnd;
    let renderMonth;

    switch (key) {
      case "weekly":
        dateStart = moment(value);
        dateEnd = moment(value).add(7, "days");

        renderMonth =
          dateStart.format("MM") === dateEnd.format("MM")
            ? RENDER_MONTH(dateStart.format("MM")).enShort
            : `${RENDER_MONTH(dateStart.format("MM")).enShort} ${RENDER_MONTH(dateEnd.format("MM")).enShort
            }`;
        return [
          `${moment(value).startOf("isoWeek").format("DD")}-${moment(value)
            .endOf("isoWeek")
            .format("DD")}`,
          renderMonth,
        ];

      case "quarterly":
        dateStart = moment(value).startOf("month");
        dateEnd = moment(value).endOf("month").add(2, "month");
        renderMonth =
          dateStart.format("YY") === dateEnd.format("YY")
            ? dateStart.format("YYYY")
            : `${dateStart.format("YYYY")} - ${dateEnd.format("YYYY")}`;
        return [
          `${RENDER_MONTH(dateStart.format("MM")).enShort} - ${
            RENDER_MONTH(dateEnd.format("MM")).enShort
          }`,
          renderMonth,
        ];
      case "halfyearly":
        dateStart = moment(value).startOf("month");
        dateEnd = moment(value).endOf("month").add(5, "month");
        renderMonth =
          dateStart.format("YY") === dateEnd.format("YY")
            ? dateStart.format("YYYY")
            : `${dateStart.format("YYYY")} - ${dateEnd.format("YYYY")}`;
        return [
          `${RENDER_MONTH(dateStart.format("MM")).enShort} - ${
            RENDER_MONTH(dateEnd.format("MM")).enShort
          }`,
          renderMonth,
        ];
      default:
        return RENDER_MONTH(moment(value).format("MM"));
    }
  };

  const _fetchDataApi = async (
    filterDay = "weekly",
    offset = 0,
    perPage = 8
  ) => {
    // _setLoadSkeleton(true);
    let res = await fetchData(
      `?period=${filterDay}&offset=${offset}&per_page=${perPage}`
    );
    if (res && res.length > 0) {
      _setLoadSkeleton(false);
      return res;
    } else {
      _setLoadSkeleton(false);
      return res;
    }
  };

  // Manage Enable/Disable Left/Right Arrow
  // PAGINATION GRAPH FLOW
  //============================================
  //============================================
  useEffect(() => {
    const checkCondition = async () => {
      if (_page.current_page === 1) {
        _setIsShorRightArrow(false);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      } else {
        _setIsShorRightArrow(true);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0 || res.length < _page.per_page) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      }
    };
    if (_page) {
      checkCondition();
    }
  }, [_page]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClickLeftArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    //check has dataloadmore
    if (_page.current_page * _page.per_page < _dataLoaded.length) {
      handleCalDataPage("next");
    } else {
      let res = await _fetchDataApi(
        _filter,
        _page.current_page * _page.per_page,
        _page.per_page
      );
      if (res) {
        _setDataLoaded([...res, ..._dataLoaded]);
        _setData(res);
        _setPage({ ..._page, current_page: _page.current_page + 1 });
      }
    }
  };
  const handleClickRightArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    handleCalDataPage("back");
  };
  const handleCalDataPage = (key) => {
    let dataTemps = [..._dataLoaded];
    switch (key) {
      case "back":
        _setData(
          dataTemps
            .reverse()
            .slice(
              _page.current_page - 1 === 1
                ? 0
                : (_page.current_page - 1) * Number(_page.per_page) -
                    _page.per_page,
              (_page.current_page - 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page - 1 });
        break;
      case "next":
        _setData(
          dataTemps
            .reverse()
            .slice(
              (_page.current_page + 1) * Number(_page.per_page) -
                _page.per_page,
              (_page.current_page + 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page + 1 });
        break;
      default:
        break;
    }
  };
  const resetPagination = () => {
    _setPage({ per_page: 8, offset: 0, current_page: 1 });
  };
  const handleDisabledArrow = () => {
    _setIsShorLeftArrow(false);
    _setIsShorRightArrow(false);
  };

  // const renderDateShow = (key) => {
  //   let dateStart
  //   let dateEnd
  //   let valueDate

  //   switch (key) {
  //     case 'weekly':
  //       valueDate = moment(data.stat_date)
  //         .startOf('isoWeek')
  //         .format('DD') +
  //         ` ` +
  //         RENDER_MONTH(
  //           moment(data.stat_date)
  //             .startOf('isoWeek')

  //             .format('MM')
  //         ).thShort +
  //         ` ` +
  //         moment(data.stat_date)
  //           .startOf('isoWeek')
  //           .format('YYYY')
  //         + ` - ` +
  //         moment(data.stat_date).endOf('isoWeek').format('DD') +
  //         ` ` +
  //         RENDER_MONTH(
  //           moment(data.stat_date).endOf('isoWeek').format('MM')
  //         ).thShort +
  //         ` ` +
  //         moment(data.stat_date).endOf('isoWeek').format('YYYY')
  //       return valueDate;
  //     case 'quarterly':
  //       dateStart = moment(data.stat_date).startOf('month');
  //       dateEnd = moment(data.stat_date).endOf('month').add(2, 'month');
  //       valueDate = dateStart.format('DD') + ' ' + RENDER_MONTH(dateStart.format('MM')).thShort + ' ' + dateStart.format('YYYY') + ' - ' + dateEnd.format('DD') + ' ' + RENDER_MONTH(dateEnd.format('MM')).thShort + ' ' + dateEnd.format('YYYY')

  //       return valueDate;
  //     case 'halfyearly':
  //       dateStart = moment(data.stat_date).startOf('month');
  //       dateEnd = moment(data.stat_date).endOf('month').add(5, 'month');
  //       valueDate = dateStart.format('DD') + ' ' + RENDER_MONTH(dateStart.format('MM')).thShort + ' ' + dateStart.format('YYYY') + ' - ' + dateEnd.format('DD') + ' ' + RENDER_MONTH(dateEnd.format('MM')).thShort + ' ' + dateEnd.format('YYYY')

  //       return valueDate;
  //     default:
  //       return "";
  //   }
  // }

  return (
    <MaleFemaleAverageResearchStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="32px"
        paddingBottom="59px"
        paddingLeft="32px"
      >
        <div className="top_men">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="สัดส่วนผู้ที่มีสุขภาวะแบบต่างๆ แบ่งตามเพศ"
              labelBottom="Percentage of employees who have Wellbeing condition by gender"
            />
          </div>
          <div className="right_men_layout">
            {/* <div className="date_show">
              <div className="show_icon">
                <Icons.CalendarIcon />
              </div>
              <div className="date_layout">
                {data && renderDateShow(_filter)}
              </div>
            </div> */}
            <div className="top_main_right">
              {/* <div className="label_layout">
                <div className="show_icon">
                  <Icons.CalendarIcon />
                </div>
                <div className="show_label">วันที่รายสัปดาห์ล่าสุด</div>
              </div>
              <div className="date_layout">
                {data &&
                  moment(data.stat_date).startOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).startOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).startOf("isoWeek").format("YYYY")}
                {` - `}
                {data &&
                  moment(data.stat_date).endOf("isoWeek").format("DD") +
                    ` ` +
                    RENDER_MONTH(
                      moment(data.stat_date).endOf("isoWeek").format("MM")
                    ).thShort +
                    ` ` +
                    moment(data.stat_date).endOf("isoWeek").format("YYYY")}
              </div> */}
            </div>
            <div className="show_search">
              <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                labeDisplay
                onChange={handleClickFilter}
              />
            </div>
          </div>
        </div>
        {_data && _data.length > 0 ? (
          <>
            {_loadSkeleton === false && (
              <div className="title_graph">Percent</div>
            )}
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="b_left_layout">
                    <div className="arrow_left">
                      {_isShorLeftArrow && (
                        <div onClick={handleClickLeftArrow}>
                          <Icons.MoveChartLeft />
                        </div>
                      )}
                    </div>
                    <div className="arrow_right">
                      {_isShorRightArrow && (
                        <div onClick={handleClickRightArrow}>
                          <Icons.MoveChartRight />
                        </div>
                      )}
                    </div> 
                    <div className="body_graph">
                      <Chart
                        type="line"
                        width="100%"
                        height="292"
                        options={{
                          chart: {
                            id: "apexchart-example",
                            fontFamily: theme.FONT.STYLE.REGULAR,
                            toolbar: {
                              show: false,
                            },
                            events: {
                              markerClick: function (
                                event,
                                chartContext,
                                { seriesIndex, dataPointIndex, config }
                              ) {
                                handleClickItemGraph(dataPointIndex);
                              },
                              mouseMove: function (
                                event,
                                chartContext,
                                config
                              ) {
                                if (config.dataPointIndex !== -1) {
                                  handleClickItemGraph(config.dataPointIndex);
                                }
                              },
                              mouseLeave: function (
                                event,
                                chartContext,
                                config
                              ) {
                                if (_data) {
                                  handleClickItemGraph(_data.length - 1);
                                }
                              },
                            },
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (
                                value,
                                { series, seriesIndex, dataPointIndex, w }
                              ) {
                                return `${value}%`;
                              },
                            },
                          },
                          xaxis: {
                            // categories: [
                            //   ['1-14', 'Dec'],
                            //   ['15-28', 'Dec'],
                            //   ['29-11', 'Dec Jan'],
                            //   ['11-24', 'Jan'],
                            //   ['25-7', 'Jan FEB'],
                            //   ['8-21', 'FEB'],
                            //   ['22-7', 'MAR'],
                            // ],
                            categories: _data
                              ? _data.map((e) => {
                                  return renderLabelX(_filter, e.stat_date);
                                })
                              : [],
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                            max: 100,
                          },
                          colors: [
                            "#8AC47F",
                            "#FDD600",
                            "#6FB5CB",
                            "#43519C",
                            "#654C96",
                            "#9B68FD",
                          ],
                          markers: {
                            size: 4,
                          },
                          stroke: {
                            curve: "smooth",
                            width: 3,
                          },
                        }}
                        series={[
                          {
                            name: "Male good wellbeing",
                            data: _data && [
                              ..._data.map((e) => e.male_good.percent),
                            ],
                          },
                          {
                            name: "Female good wellbeing",
                            data: _data && [
                              ..._data.map((e) => e.female_good.percent),
                            ],
                          },
                          {
                            name: "Male poor wellbeing",
                            data: _data && [
                              ..._data.map((e) => e.male_moderate.percent),
                            ],
                          },
                          {
                            name: "Female poor wellbeing",
                            data: _data && [
                              ..._data.map((e) => e.female_moderate.percent),
                            ],
                          },
                          {
                            name: "Male depressed state",
                            data: _data && [
                              ..._data.map((e) => e.male_depress.percent),
                            ],
                          },
                          {
                            name: "Female depressed state",
                            data: _data && [
                              ..._data.map((e) => e.female_depress.percent),
                            ],
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="right_men_layout">
                    <div className="group_show">
                      <div className="box_well label_w">
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="สุขภาวะทางจิตอยู่ในระดับดี"
                          labelBottom="Good wellbeing"
                          bgColor="#FB9334"
                        />
                      </div>
                      <div className="box_title">
                        <div className="title_show">
                          <div>ชาย</div>
                          <div className="sub_label">Male</div>
                        </div>
                        <Bars.PersonPercent
                          theme_standard_person_percent
                          color="#8AC47F"
                          svg={<Icons.ManGood />}
                          label1="ชาย"
                          label2="Male"
                          label3={_percent && `${_percent.male_good}%`}
                        />
                      </div>
                      <div className="box_title">
                        <div className="title_show">
                          <div>หญิง</div>
                          <div className="sub_label">Female</div>
                        </div>
                        <Bars.PersonPercent
                          theme_standard_person_percent
                          color="#FDD600"
                          svg={<Icons.WomenGood />}
                          label1="ผู้หญิง"
                          label2="Female"
                          label3={_percent && `${_percent.female_good}%`}
                        />
                      </div>
                    </div>
                    <div className="group_show">
                      <div className="box_well label_w">
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="สุขภาวะทางจิตอยู่ในระดับต่ำกว่าปกติ"
                          labelBottom="Poor wellbeing"
                          bgColor="#FD6524"
                        />
                      </div>
                      <Bars.PersonPercent
                        theme_standard_person_percent
                        color="#6FB5CB"
                        svg={<Icons.ManSoSo />}
                        label1="ชาย"
                        label2="Male"
                        label3={_percent && `${_percent.male_moderate}%`}
                      />
                      <Bars.PersonPercent
                        theme_standard_person_percent
                        color="#43519C"
                        svg={<Icons.WomenSoSo />}
                        label1="ผู้หญิง"
                        label2="Female"
                        label3={_percent && `${_percent.female_moderate}%`}
                      />
                    </div>
                    <div className="group_show no_margin">
                      <div className="box_well label_w">
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="มีแนวโน้มของภาวะซึมเศร้า"
                          labelBottom="Likely depressed state"
                          bgColor="#003C58"
                        />
                      </div>
                      <Bars.PersonPercent
                        theme_standard_person_percent
                        color="#654C96"
                        svg={<Icons.ManBad />}
                        label1="ชาย"
                        label2="Male"
                        label3={_percent && `${_percent.male_depress}%`}
                      />
                      <Bars.PersonPercent
                        theme_standard_person_percent
                        color="#9B68FD"
                        svg={<Icons.WomenBad />}
                        label1="ผู้หญิง"
                        label2="Female"
                        label3={_percent && `${_percent.female_depress}%`}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </MaleFemaleAverageResearchStyled>
  );
};

export default MaleFemaleAverageResearch;

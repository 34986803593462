import React from 'react';
import { MSDContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { Blocks, Typographys, Icons, Tabs } from 'components';
import { CurrentBMIStatContainer,  } from './Tabs';

class MSDContainer extends React.Component {
  state = {
    titleShow: {
      labelTitle: 'Company MSD Score',
      icon: <Icons.MSD2 />
    }
  };

  componentDidMount() {
    scrollToTopPageFN();
  }

  ChangeTitle = (e) => {
    this.setState({
      titleShow: {
        labelTitle: e.labelTitle,
        icon: e.icon
      }
    })
  }

  render() {
    const { titleShow } = this.state
    return (
      <MSDContainerStyled>
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label={titleShow.labelTitle}
              svgFront={titleShow.icon}
            />
          </div>
          <Tabs.TabBarSelect
            tab_standard_tab_bar_select
            tabHeader={headerTab}
            onClickSeletedTab={this.ChangeTitle}
          />
        </Blocks.Container>
      </MSDContainerStyled>
    );
  }
}
 
const headerTab = [
  {
    label: 'ข้อมูลสถิติ MSD',
    labelBottom: 'Current MSD Stat',
    component: <CurrentBMIStatContainer />,
    icon: <Icons.MSD2 />,
    labelTitle: "Company MSD Score"
  },
  // {
  //   label: 'ข้อมูลสถิติ DWB',
  //   labelBottom: 'Current DWB Stat',
  //   component: <AIBMIContainer />,
  //   icon: <Icons.Gear />,
  //   labelTitle: "Company DWB Score"
  // },
];

export default MSDContainer;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnLanguageStyled } from './styled';

const BtnLanguage = ({ theme_standard_language ,label}) => {
  const customClass = cx({
    theme_standard_language: theme_standard_language,
  });
  return (
    <BtnLanguageStyled>
      <div className={customClass}>
        {label}
      </div>
    </BtnLanguageStyled>
  );
};

BtnLanguage.propTypes = {};

export default BtnLanguage;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnCreateContentStyled } from './styled';
import { Icons } from 'components';

const BtnCreateContent = ({ theme_standard_btn_create_content, onClick }) => {
  const customClass = cx({
    theme_standard_btn_create_content: theme_standard_btn_create_content,
  });
  return (
    <BtnCreateContentStyled>
      <div className={customClass} onClick={onClick}>
        <div className="left_button">
          <Icons.Page />
        </div>
        <div className="right_button">
          <div className="title">สร้างบทความใหม่</div>
          <div className="label_show">Create new content</div>
        </div>
      </div>
    </BtnCreateContentStyled>
  );
};

BtnCreateContent.propTypes = {};

export default BtnCreateContent;

import { HealthCheckDepartmentStyled } from "./styled";
import { Blocks } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import theme from "styles/theme.json";
// import momentTZ from "moment-timezone";
// import moment from "moment-timezone";
// import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const HealthCheckDepartment = ({ data }) => {
  const [_category, _setCategory] = useState([]);
  const [_dataNoCheckup, _setDataNoCheckup] = useState([]);
  const [_dataWithCheckup, _setDataWithCheckup] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      let tempCate = [];
      let tempWithCheckup = [];
      let tempNoCheckup = [];

      data.forEach((e) => {
        tempCate.push(
          `${e.department.name} ${RenderCommaMoney(
            Number(e.employee_count_total).toFixed(0),
            0
          )} คน`
        );
        tempWithCheckup.push(
          e.employee_count_with_checkup === 0
            ? null
            : e.employee_count_with_checkup
        );
        tempNoCheckup.push(
          e.employee_count_no_checkup === 0 ? null : e.employee_count_no_checkup
        );
      });

      _setCategory(tempCate);
      _setDataWithCheckup(tempWithCheckup);
      _setDataNoCheckup(tempNoCheckup);
    }
  }, [data]);

  const renderHeight = () => {
    let tempHight = 136;
    if (data && data.length > 1) {
      tempHight = 79.6 + 56.4 * data.length;
    }

    return tempHight;
  };

  return (
    <HealthCheckDepartmentStyled>
      <div className="box_dep_health_check">
        <div className="body_graph_dep_health">
          <div className="top_dep_title_g">
            <div className="text_c">แบ่งตามแผนก</div>
            <div className="text_c">Department</div>
          </div>
          <div className="right_dep_title_g">จำนวนพนักงาน</div>
          <Chart
            type="bar"
            height={renderHeight()}
            options={{
              chart: {
                stacked: true,
                fontFamily: theme.FONT.STYLE.BOLD,
                toolbar: {
                  show: false,
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: true,
                  },
                },
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              // responsive: [
              //   {
              //     breakpoint: 480,
              //     options: {
              //       legend: {
              //         position: "bottom",
              //         offsetX: -10,
              //         offsetY: 0,
              //       },
              //     },
              //   },
              //   {
              //     breakpoint: 780,
              //     options: {
              //       xaxis: {
              //         labels: {
              //           rotate: -80,
              //         }
              //       },
              //     }
              //   },
              //   // {
              //   //   breakpoint: 1200,
              //   //   options: {
              //   //     plotOptions: {
              //   //       bar: {
              //   //         columnWidth: "40%",
              //   //       },
              //   //     },
              //   //   }
              //   // }
              // ],
              tooltip: {
                style: {
                  fontSize: "12px",
                  fontFamily: theme.FONT.STYLE.REGULAR,
                },
                y: {
                  formatter: function (
                    value,
                    { series, seriesIndex, dataPointIndex, w }
                  ) {
                    return `${RenderCommaMoney(
                      Number(value).toFixed(0),
                      0
                    )} คน`;
                  },
                },
              },
              dataLabels: {
                style: {
                  // colors: theme.COLORS.WHITE_1,
                  fontSize: "14px",
                  fontFamily: theme.FONT.STYLE.REGULAR,
                  fontWeight: 400,
                },
              },
              xaxis: {
                categories: _category,
                labels: {
                  formatter: function (value) {
                    return RenderCommaMoney(Number(value).toFixed(0), 0);
                  },
                  style: {
                    colors: theme.COLORS.GRAY_23,
                    fontSize: "16px",
                    fontFamily: theme.FONT.STYLE.BOLD,
                  },
                },
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  // color: theme.COLORS.PURPLE_7,
                  // height: 3,
                },
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: theme.COLORS.PURPLE_7,
                  width: 3,
                },
                labels: {
                  // rotate: -16,
                  // formatter: function (value, timestamp, opts) {
                  //   return parse(value)
                  // }
                  hideOverlappingLabels: false,
                  style: {
                    colors: theme.COLORS.BLACK_11,
                    fontSize: "12px",
                    fontFamily: theme.FONT.STYLE.REGULAR,
                  },
                },
              },
              colors: ["#002366", "#79A6FF"],
              fill: {
                opacity: 1,
              },

              plotOptions: {
                bar: {
                  horizontal: true,
                  columnWidth: "100%",
                },
              },
              legend: {
                show: false,
              },
            }}
            series={[
              {
                name: "พนักงานที่มีผลสุขภาพประจำปีแล้ว",
                data: _dataWithCheckup && _dataWithCheckup,
              },
              {
                name: "พนักงานที่ยังไม่มีผลสุขภาพประจำปี",
                data: _dataNoCheckup && _dataNoCheckup,
              },
            ]}
          />
        </div>
        <div className="right_dep_men_layout">
          <div className="show_being">
            <Blocks.BoxWellbeing
              theme_center
              label="พนักงานที่มีผลสุขภาพประจำปีแล้ว"
              bgColor={theme.COLORS.PURPLE_1}
              showRound
            />
          </div>
          <div className="show_being">
            <Blocks.BoxWellbeing
              theme_center
              label="พนักงานที่ยังไม่มีผลสุขภาพประจำปี"
              bgColor={theme.COLORS.BLUE_25}
              showRound
            />
          </div>
        </div>
      </div>
    </HealthCheckDepartmentStyled>
  );
};

export default HealthCheckDepartment;

export default function EmotionSmile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="47.962"
      viewBox="0 0 48 47.962"
    >
      <g id="_045-happy-3" dataname="045-happy-3" transform="translate(0.379)">
        <ellipse
          id="Ellipse_298"
          dataname="Ellipse 298"
          cx="24"
          cy="23.966"
          rx="24"
          ry="23.966"
          transform="translate(-0.379)"
          fill="#ffe17d"
        />
        <path
          id="Path_3209"
          dataname="Path 3209"
          d="M27.462,96.482A23.978,23.978,0,0,1,8.856,57.376,23.979,23.979,0,1,0,42.588,91.108,23.878,23.878,0,0,1,27.462,96.482Z"
          transform="translate(-0.334 -52.001)"
          fill="#ffd164"
        />
        <path
          id="Path_3210"
          dataname="Path 3210"
          d="M121.28,290.557A56.279,56.279,0,0,1,108.2,289.05a.746.746,0,0,0-.814.993c1.866,6.588,7.374,11.367,13.891,11.367s12.025-4.779,13.891-11.367a.746.746,0,0,0-.814-.993A56.282,56.282,0,0,1,121.28,290.557Z"
          transform="translate(-97.632 -261.957)"
          fill="#9c6846"
        />
        <g
          id="Group_1197"
          dataname="Group 1197"
          transform="translate(9.723 14.698)"
        >
          <path
            id="Path_3211"
            dataname="Path 3211"
            d="M134.357,289.05a57.522,57.522,0,0,1-26.154,0,.746.746,0,0,0-.814.993,17.018,17.018,0,0,0,2.172,4.743,61.244,61.244,0,0,0,23.438,0,17.016,17.016,0,0,0,2.173-4.744A.746.746,0,0,0,134.357,289.05Z"
            transform="translate(-107.355 -276.655)"
            fill="#7d5046"
          />
          <path
            id="Path_3212"
            dataname="Path 3212"
            d="M150.579,163.9h0a1.934,1.934,0,0,1-1.934-1.934v-3.133a1.934,1.934,0,0,1,1.934-1.934h0a1.934,1.934,0,0,1,1.934,1.934v3.133A1.934,1.934,0,0,1,150.579,163.9Z"
            transform="translate(-144.777 -156.903)"
            fill="#7d5046"
          />
        </g>
        <path
          id="Path_3213"
          dataname="Path 3213"
          d="M165.548,156.9a1.942,1.942,0,0,0-.387.039V161.2a1.16,1.16,0,1,0,2.321,0v-2.36A1.934,1.934,0,0,0,165.548,156.9Z"
          transform="translate(-150.023 -142.205)"
          fill="#9c6846"
        />
        <path
          id="Path_3214"
          dataname="Path 3214"
          d="M324,163.9h0a1.934,1.934,0,0,1-1.934-1.934v-3.133A1.934,1.934,0,0,1,324,156.9h0a1.934,1.934,0,0,1,1.934,1.934v3.133A1.934,1.934,0,0,1,324,163.9Z"
          transform="translate(-292.229 -142.205)"
          fill="#7d5046"
        />
        <path
          id="Path_3215"
          dataname="Path 3215"
          d="M338.968,156.9a1.942,1.942,0,0,0-.387.039V161.2a1.16,1.16,0,1,0,2.321,0v-2.36A1.934,1.934,0,0,0,338.968,156.9Z"
          transform="translate(-307.198 -142.205)"
          fill="#9c6846"
        />
        <path
          id="Path_3216"
          dataname="Path 3216"
          d="M155.425,295.322a14.309,14.309,0,0,0,1.061-2.284,57.824,57.824,0,0,1-22.934,0,14.286,14.286,0,0,0,1.071,2.292,2.987,2.987,0,0,0,2.207,1.473,67.156,67.156,0,0,0,16.383,0A2.992,2.992,0,0,0,155.425,295.322Z"
          transform="translate(-121.375 -265.586)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

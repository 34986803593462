import { COMPANY_LIST } from '../actions/actionTypes';

const initialState = {
  // name: '',
  // id: '',
};

const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_LIST:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default companyListReducer;

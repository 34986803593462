import { TotalViewStyled } from './styled';
import { Blocks, Icons, Typographys } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalView = ({ total, maleCount, famaleCount }) => {

  const renderPercent = () => {
    let numberActive = 0
    if (total && maleCount && famaleCount) {
      numberActive = Math.floor(
        ((total / maleCount) * 100) / 10
      );

    }

    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) =>
      i < numberActive ? (
        <Icons.PeoplePercent key={i} active />
      ) : (
        <Icons.PeoplePercent key={i} />
      )
    );
  };

  return (
    <TotalViewStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="23px"
        paddingBottom="43px"
        paddingLeft="16px"
      >
        <div className="top_view">
          <Typographys.TopBottom
            theme_left_align
            label="ข้อมูลผู้เข้าชมทั้งหมด"
            labelBottom="Total view"
            fontSizeLabelButtom="14px"
          />
        </div>
        <div className='show_detail'>
          <div className='graph_show'>
            <div className='num_show'>
              <div>
                {total > 0 ? RenderCommaMoney(Number(total), true) : '0'}
              </div>
              <div className='label_show'>
                คน
              </div>
            </div>
            <div className='graph_state'>
              {renderPercent()}
            </div>
          </div>
          <div className='state_show'>
            <div className='group_line mb'>
              <div className='show_svg'>
                <Icons.ManIcon />
              </div>
              <div className='s_value_show'>
                {maleCount > 0 ? `${RenderCommaMoney(Number(maleCount), true)} คน` : '0 คน'}
              </div>
              <div className='s_percent_show'>
                {
                  total && maleCount
                    ?
                    `(${((maleCount * 100) / total).toFixed(0)} %)`
                    :
                    `(0 %)`
                }
              </div>
            </div>
            <div className='group_line'>
              <div className='show_svg'>
                <Icons.WomenIcon />
              </div>
              <div className='s_value_show'>
                {famaleCount > 0 ? `${RenderCommaMoney(Number(famaleCount), true)} คน` : '0 คน'}
              </div>
              <div className='s_percent_show'>
                {
                  total && famaleCount
                    ?
                    `(${((famaleCount * 100) / total).toFixed(0)} %)`
                    :
                    `(0 %)`
                }
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </TotalViewStyled>
  );
};

export default TotalView;

import styled from "styled-components";

export const InputTextMaxCharStyled = styled.div`
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  .theme_standard_text_max_char {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) =>
    flexStyle ? flexStyle : "space-between"};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 8px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          margin-right: 9px;
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          padding-top: 4px;
        }
      }
      .num_show {
        font-family: noto_sans_thai_regular, noto_sans_regular;
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .text_input {
      width: 100%;
      box-sizing: border-box;
      border-radius: 16px;
      border: 2px solid ${({ theme }) => theme.COLORS.GRAY_5};
      resize: none;
      outline: none;
      padding: 13px;
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${(props) =>
    props.fontFamily
      ? props.fontFamily
      : "noto_sans_thai_medium, noto_sans_regular"};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: noto_sans_thai_regular, noto_sans_regular;
      }
    }
    .error {
      font-size: 12px;
      font-family: "noto_sans_thai_regular, noto_sans_regular";
      color: #ff0000;
      padding-top: 2px;
    }
  }

  .theme_normal_text {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) => flexStyle ? flexStyle : "space-between"};
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 8px;
        align-items: baseline;
        column-gap: 8px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      }
    }
    .text_input {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      resize: none;
      outline: none;
      padding: 11.2px 16px;
      font-size: ${(props) => props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${(props) => props.fontFamily ? props.fontFamily : ({ theme }) => theme.FONT.STYLE.MEDIUM}};
      color: ${(props) => props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.BLACK_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .error {
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: #ff0000;
      padding-top: 2px;
    }
  }

  .theme_lucky {
    position: relative;
    .title_layout {
      display: flex;
      justify-content: ${({ flexStyle }) => flexStyle ? flexStyle : "space-between"};
      align-items: center;
      .show_title {
        display: flex;
        margin-bottom: 8px;
        align-items: baseline;
        column-gap: 8px;
        .title_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
        .sub_display {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      }
    }
    .text_input {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      resize: none;
      outline: none;
      background: ${({ disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_28 : ({ theme }) => theme.COLORS.WHITE_1};
      padding: 9.6px 16px;
      font-size: ${(props) => props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) => props.fontFamily ? props.fontFamily : ({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ fontColor, disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_23 : fontColor ? fontColor : ({ theme }) => theme.COLORS.PURPLE_1};
      ::placeholder {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .error {
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: #ff0000;
      padding-top: 2px;
    }
  }
`;

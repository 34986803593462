import React from "react";
import { RewardExchangeFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs } from "components";
import { Buttons, Icons } from "components";

const RewardExchangeForm = ({ onSubmit, handleCancel, actionStp }) => {
  const schema = yup.object().shape({
    code: yup.string().required("Code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RewardExchangeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="action_box">
          <div className="title_box">
            <div>
              {actionStp === 1
                ? "กรุณาใส่รหัสแลกของรางวัล"
                : actionStp === 2
                ? "แลกรับสิทธิ์เรียบร้อย"
                : "ยืนยันการรับสิทธิ์ไม่สำเร็จ"}
            </div>
            {actionStp === 1 && (
              <div className="icon">
                <Icons.Ribbin />
              </div>
            )}
          </div>
          <div className="input_layer">
            {actionStp === 1 && (
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputText
                    theme_input_reward_exchange
                    iconSvg={<Icons.User />}
                    placeholder="ใส่ Code"
                    type="number"
                    {...field}
                    errors={errors.code?.message}
                  />
                )}
                name="code"
                defaultValue=""
              />
            )}
            {actionStp === 2 && (
              <div className="show_svg">
                <Icons.EmotionVerySmile />
              </div>
            )}
            {actionStp === 3 && (
              <div className="show_svg">
                <Icons.EmotionSad />
              </div>
            )}
          </div>
          <div className="action_btn">
            {actionStp === 1 ? (
              <>
                <div className="body_btn margin_bottom">
                  <Buttons.BtnNormal
                    theme_standard_btn_pop_up
                    label="ยืนยัน"
                    fontSize="26px"
                    backgroundColor="#575D96"
                    type="submit"
                  />
                </div>
                <div className="body_btn">
                  <Buttons.BtnNormal
                    theme_standard_btn_pop_up
                    label="ยกเลิก"
                    fontSize="26px"
                    backgroundColor="#FFFFFF"
                    fontColor="#575D96"
                    borderColor="#575D96"
                    onClick={handleCancel}
                  />
                </div>
              </>
            ) : (
              <div className="body_btn">
                <Buttons.BtnNormal
                  theme_standard_btn_pop_up
                  label="ปิด"
                  fontSize="26px"
                  backgroundColor="#FFFFFF"
                  fontColor="#2A74A3"
                  borderColor="#2A74A3"
                  onClick={handleCancel}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </RewardExchangeFormStyled>
  );
};

RewardExchangeForm.propTypes = {};

export default RewardExchangeForm;

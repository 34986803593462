import styled from 'styled-components';

export const LabelSubVerticalTabStyled = styled.div`
  .lb_title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S32};
    font-family: noto_sans_thai_bold, noto_sans_bold;
    color: ${({ theme }) => theme.COLORS.BLACK_6};
  }
  .lb_sub_title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }

  .theme_standard_tab {
    text-align: center;
  }

  .theme_head_34_tab {
    .lb_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S34};
      color: ${({ theme }) => theme.COLORS.BLUE_3};
    }
    .lb_sub_title {
      color: ${({ theme }) => theme.COLORS.BLUE_3};
    }
  }
`;

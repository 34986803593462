export default function CategoryOthers({ width = '32', height = '32' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_15962" data-name="Group 15962" transform="translate(-152 -463)">
        <circle id="Ellipse_1502" data-name="Ellipse 1502" cx="16" cy="16" r="16" transform="translate(152 463)" fill="#fef3f2" />
        <g id="exercise" transform="translate(156.848 471.338)">
          <path id="Path_19194" data-name="Path 19194" d="M343.224,132.348v2.179a.327.327,0,0,1-.327.327h-2.56a.327.327,0,0,1-.327-.327v-2.179a.327.327,0,0,1,.327-.327h2.56A.327.327,0,0,1,343.224,132.348Z" transform="translate(-325.191 -128.544)" fill="#555760" />
          <g id="Group_13778" data-name="Group 13778" transform="translate(0 3.477)">
            <path id="Path_19195" data-name="Path 19195" d="M1.56,132.016H.327a.327.327,0,0,0-.327.327v2.179a.327.327,0,0,0,.327.327H1.56a.327.327,0,0,0,.327-.327v-2.179A.327.327,0,0,0,1.56,132.016Z" transform="translate(0 -132.016)" fill="#555760" />
            <path id="Path_19196" data-name="Path 19196" d="M470.587,132.348v2.179a.327.327,0,0,1-.327.327h-1.233a.327.327,0,0,1-.327-.327v-2.179a.327.327,0,0,1,.327-.327h1.233A.327.327,0,0,1,470.587,132.348Z" transform="translate(-448.272 -132.021)" fill="#555760" />
            <g id="Group_13777" data-name="Group 13777" transform="translate(0 1.448)">
              <path id="Path_19197" data-name="Path 19197" d="M470.587,165.251v1.058a.327.327,0,0,1-.327.327h-1.233a.327.327,0,0,1-.327-.327v-1.058a.327.327,0,0,0,.327.327h1.233A.327.327,0,0,0,470.587,165.251Z" transform="translate(-448.272 -165.251)" fill="#36383f" />
              <path id="Path_19198" data-name="Path 19198" d="M1.887,165.246V166.3a.327.327,0,0,1-.327.327H.327A.327.327,0,0,1,0,166.3v-1.058a.327.327,0,0,0,.327.327H1.56A.327.327,0,0,0,1.887,165.246Z" transform="translate(0 -165.246)" fill="#36383f" />
              <path id="Path_19199" data-name="Path 19199" d="M343.224,172.751v.731a.327.327,0,0,1-.327.327h-2.56a.327.327,0,0,1-.327-.327v-.731Z" transform="translate(-325.191 -172.424)" fill="#36383f" />
            </g>
          </g>
          <g id="Group_13779" data-name="Group 13779" transform="translate(1.233 1.199)">
            <path id="Path_19200" data-name="Path 19200" d="M30.043,80.612V86.26a.871.871,0,0,1-1.743,0V80.612a.871.871,0,0,1,1.743,0Z" transform="translate(-28.3 -79.741)" fill="#52deff" />
            <path id="Path_19201" data-name="Path 19201" d="M39.607,80.612V86.26a.871.871,0,0,1-1.307.754.859.859,0,0,0,.18-.138.869.869,0,0,0,.255-.616V80.612a.87.87,0,0,0-.435-.754.871.871,0,0,1,1.307.754Z" transform="translate(-37.864 -79.741)" fill="#55baff" />
            <path id="Path_19202" data-name="Path 19202" d="M445.513,80.61v5.648a.871.871,0,0,1-1.743,0V80.61a.871.871,0,0,1,1.743,0Z" transform="translate(-425.662 -79.739)" fill="#52deff" />
            <path id="Path_19203" data-name="Path 19203" d="M455.077,80.61v5.648a.871.871,0,0,1-1.307.754.858.858,0,0,0,.18-.138.869.869,0,0,0,.255-.616V80.61a.87.87,0,0,0-.435-.754.871.871,0,0,1,1.307.754Z" transform="translate(-435.226 -79.739)" fill="#55baff" />
          </g>
          <path id="Path_19204" data-name="Path 19204" d="M165.668,104.057v2.208a.327.327,0,0,1-.068.2l-1.709,2.218L164.4,114a.327.327,0,0,1-.326.358h-5.441a.327.327,0,0,1-.326-.358l.093-.97.017-.174.394-4.123-1.643-1.268a.327.327,0,0,1-.127-.258V102.8a.327.327,0,0,1,.327-.327h6.715A1.588,1.588,0,0,1,165.668,104.057Z" transform="translate(-150.196 -100.282)" fill="#ffd3c0" />
          <path id="Path_19205" data-name="Path 19205" d="M193.439,104.057v2.208a.327.327,0,0,1-.068.2l-1.709,2.218L192.17,114a.327.327,0,0,1-.326.358H186.4a.327.327,0,0,1-.326-.358l.093-.97.017-.174h4.481a.327.327,0,0,0,.326-.358l-.334-3.837,1.884-2.48a.327.327,0,0,0,.068-.2v-2.208c0-.54-.533-1.018-.946-1.3h.187A1.588,1.588,0,0,1,193.439,104.057Z" transform="translate(-177.966 -100.282)" fill="#ffb9a1" />
          <path id="Path_19206" data-name="Path 19206" d="M107.649,132.347v1.177a.324.324,0,0,1-.053.178l-.061.093-.593.909a.326.326,0,0,1-.274.149H98.577a.327.327,0,0,1-.327-.327v-2.179a.327.327,0,0,1,.331-.327h8.745A.327.327,0,0,1,107.649,132.347Z" transform="translate(-93.968 -128.543)" fill="#555760" />
          <path id="Path_19207" data-name="Path 19207" d="M98.25,172.751h9.285l-.593.909a.326.326,0,0,1-.274.149H98.577a.327.327,0,0,1-.327-.327Z" transform="translate(-93.968 -167.499)" fill="#36383f" />
          <g id="Group_13780" data-name="Group 13780" transform="translate(2.976 0)">
            <path id="Path_19208" data-name="Path 19208" d="M70.24,53.221v7.827a.981.981,0,0,1-.98.98.982.982,0,0,1-.98-.98V53.221a.98.98,0,0,1,1.96,0Z" transform="translate(-68.28 -52.241)" fill="#ff455c" />
            <path id="Path_19209" data-name="Path 19209" d="M81,53.221v7.827a.981.981,0,0,1-.98.98.969.969,0,0,1-.49-.132.979.979,0,0,0,.49-.848V53.221a.979.979,0,0,0-.49-.848.969.969,0,0,1,.49-.132A.981.981,0,0,1,81,53.221Z" transform="translate(-79.04 -52.241)" fill="#e51c5f" />
          </g>
          <g id="Group_13781" data-name="Group 13781" transform="translate(17.379)">
            <path id="Path_19210" data-name="Path 19210" d="M400.71,53.219v7.827a.981.981,0,0,1-.98.98.982.982,0,0,1-.98-.98V53.219a.98.98,0,0,1,1.96,0Z" transform="translate(-398.75 -52.239)" fill="#ff455c" />
            <path id="Path_19211" data-name="Path 19211" d="M411.47,53.219v7.827a.981.981,0,0,1-.98.98.969.969,0,0,1-.49-.132.98.98,0,0,0,.49-.848V53.219a.98.98,0,0,0-.49-.848.969.969,0,0,1,.49-.132A.981.981,0,0,1,411.47,53.219Z" transform="translate(-409.51 -52.239)" fill="#e51c5f" />
          </g>
          <path id="Path_19212" data-name="Path 19212" d="M157.9,82.816a.855.855,0,0,0-.855.855v1.884a.855.855,0,1,0,1.709,0V83.671A.855.855,0,0,0,157.9,82.816Z" transform="translate(-150.199 -81.483)" fill="#ffd3c0" />
          <path id="Path_19213" data-name="Path 19213" d="M197.112,82.816a.855.855,0,0,0-.855.855v1.884a.855.855,0,0,0,1.709,0V83.671A.855.855,0,0,0,197.112,82.816Z" transform="translate(-187.703 -81.483)" fill="#ffb9a1" />
          <path id="Path_19214" data-name="Path 19214" d="M236.326,82.816a.855.855,0,0,0-.855.855v1.884a.855.855,0,0,0,1.709,0V83.671A.855.855,0,0,0,236.326,82.816Z" transform="translate(-225.208 -81.483)" fill="#ffd3c0" />
          <path id="Path_19215" data-name="Path 19215" d="M275.54,82.816a.855.855,0,0,0-.855.855v1.884a.855.855,0,0,0,1.709,0V83.671A.855.855,0,0,0,275.54,82.816Z" transform="translate(-262.713 -81.483)" fill="#ffb9a1" />
          <path id="Path_19216" data-name="Path 19216" d="M161.886,364.459a.328.328,0,0,1-.245.11h-8.326a.327.327,0,0,1-.325-.365l.091-.768.345-2.916a.327.327,0,0,1,.325-.289H161.2a.327.327,0,0,1,.325.289l.436,3.684A.327.327,0,0,1,161.886,364.459Z" transform="translate(-146.321 -346.807)" fill="#eef6ff" />
          <path id="Path_19217" data-name="Path 19217" d="M161.886,364.459a.328.328,0,0,1-.245.11h-8.326a.327.327,0,0,1-.325-.365l.091-.768h7.253a.327.327,0,0,0,.324-.365l-.288-2.551a.327.327,0,0,0-.325-.288H161.2a.327.327,0,0,1,.325.288l.436,3.684A.327.327,0,0,1,161.886,364.459Z" transform="translate(-146.321 -346.807)" fill="#dae6ef" />
        </g>
      </g>
    </svg>
  );
}

export default function renderIcon({
  width = "24",
  height = "24",
  color = "#002366",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4725 10.0906C15.5269 11.145 15.5269 12.8546 14.4725 13.909C13.4181 14.9634 11.7085 14.9634 10.6541 13.909C9.59968 12.8546 9.59968 11.145 10.6541 10.0906C11.7085 9.0362 13.4181 9.0362 14.4725 10.0906"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.81286 12C5.81286 12.297 5.83986 12.594 5.87586 12.882L4.28786 14.124C3.93586 14.4 3.83986 14.893 4.06386 15.28L5.47586 17.723C5.69886 18.11 6.17286 18.273 6.58786 18.107L8.00986 17.536C8.29086 17.423 8.60386 17.468 8.85586 17.635C9.07586 17.781 9.30386 17.915 9.53986 18.035C9.80986 18.172 10.0059 18.417 10.0489 18.717L10.2659 20.23C10.3289 20.672 10.7079 21 11.1539 21H13.9709C14.4169 21 14.7959 20.672 14.8589 20.23L15.0759 18.718C15.1189 18.418 15.3169 18.171 15.5879 18.035C15.8229 17.917 16.0499 17.784 16.2689 17.639C16.5229 17.471 16.8369 17.423 17.1189 17.537L18.5379 18.107C18.9519 18.273 19.4259 18.11 19.6499 17.723L21.0619 15.28C21.2859 14.893 21.1899 14.399 20.8379 14.124L19.2499 12.882C19.2859 12.594 19.3129 12.297 19.3129 12C19.3129 11.703 19.2859 11.406 19.2499 11.118L20.8379 9.876C21.1899 9.6 21.2859 9.107 21.0619 8.72L19.6499 6.277C19.4269 5.89 18.9529 5.727 18.5379 5.893L17.1189 6.463C16.8369 6.576 16.5229 6.529 16.2689 6.361C16.0499 6.216 15.8229 6.083 15.5879 5.965C15.3169 5.829 15.1189 5.582 15.0759 5.282L14.8599 3.77C14.7969 3.328 14.4179 3 13.9719 3H11.1549C10.7089 3 10.3299 3.328 10.2669 3.77L10.0489 5.284C10.0059 5.583 9.80886 5.829 9.53986 5.966C9.30386 6.086 9.07586 6.221 8.85586 6.366C8.60286 6.532 8.28986 6.577 8.00886 6.464L6.58786 5.893C6.17286 5.727 5.69886 5.89 5.47586 6.277L4.06386 8.72C3.83986 9.107 3.93586 9.601 4.28786 9.876L5.87586 11.118C5.83986 11.406 5.81286 11.703 5.81286 12V12Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

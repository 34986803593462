export default function Avatar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="208.278" height="133.851" viewBox="0 0 208.278 133.851">
      <defs>
        <clipPath id="clip-path">
          <rect x="51" y="41" width="208.278" height="133.851" fill="none" />
        </clipPath>
        <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="2.309" gradientTransform="matrix(0.509, 0.861, -0.487, 0.289, 0.489, -0.075)" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#48486b" />
          <stop offset="0.261" stop-color="#5e5d7f" />
          <stop offset="0.502" stop-color="#bebebe" />
          <stop offset="0.77" stop-color="#6b6a8b" />
          <stop offset="1" stop-color="#706e8f" />
        </radialGradient>
        <clipPath id="clip-path-2">
          <ellipse id="Ellipse_5954" data-name="Ellipse 5954" cx="107.06" cy="78.886" rx="107.06" ry="78.886" transform="translate(0 76.733) rotate(-21)" fill="#48486b" stroke="#707070" stroke-width="1" />
        </clipPath>
        <clipPath id="clip-path-3">
          <ellipse id="Ellipse_5953" data-name="Ellipse 5953" cx="69.461" cy="51.182" rx="69.461" ry="51.182" transform="translate(51.182 0) rotate(30)" fill="#fff" />
        </clipPath>
      </defs>
      <g id="Scroll_Group_3" data-name="Scroll Group 3" transform="translate(-51 -41)" clip-path="url(#clip-path)" style={{ isolation: 'isolate' }}>
        <g id="Group_90886" data-name="Group 90886" transform="translate(14167.76 -9920.209)">
          <g id="Rectangle_17645" data-name="Rectangle 17645" transform="translate(-14116.76 9961.203)" stroke="#eaeaea" stroke-width="1" fill="url(#radial-gradient)">
            <rect width="207.76" height="133.796" rx="8" stroke="none" />
            <rect x="0.5" y="0.5" width="206.76" height="132.796" rx="7.5" fill="none" />
          </g>
          <g id="Mask_Group_89494" data-name="Mask Group 89494" transform="translate(-14167.76 9929.033)" opacity="0.261" clip-path="url(#clip-path-2)">
            <g id="Rectangle_32341" data-name="Rectangle 32341" transform="translate(51 31.171)" fill="#48486b" stroke="#eaeaea" stroke-width="1">
              <rect width="205.438" height="136" rx="8" stroke="none" />
              <rect x="0.5" y="0.5" width="204.438" height="135" rx="7.5" fill="none" />
            </g>
          </g>
          <g id="Mask_Group_89493" data-name="Mask Group 89493" transform="translate(-14028.973 9920.209)" opacity="0.32" clip-path="url(#clip-path-3)">
            <rect id="Rectangle_32340" data-name="Rectangle 32340" width="285.155" height="148.061" rx="8" transform="translate(-166.507 42.497)" fill="url(#radial-gradient)" />
          </g>
          <g id="success" transform="translate(-14035.88 9985.729)">
            <g id="Group_3528" data-name="Group 3528" transform="translate(1.381 4.458)">
              <g id="Group_3527" data-name="Group 3527" transform="translate(0 0)">
                <path id="Path_12138" data-name="Path 12138" d="M31.523,87.288h-3.9a7.6,7.6,0,0,1-4.564-1.536l-5.106-3.874A6.824,6.824,0,0,1,22,69.619c2.784-.033,4.366.055,4.432.059l-.174,3.065c-.015,0-1.554-.085-4.221-.054a3.755,3.755,0,0,0-2.225,6.745l5.106,3.874a4.507,4.507,0,0,0,2.709.911h3.9v3.069Z" transform="translate(-15.251 -69.611)" fill="#ffd064" />
              </g>
            </g>
            <g id="Group_3530" data-name="Group 3530" transform="translate(1.382 10.491)">
              <g id="Group_3529" data-name="Group 3529">
                <path id="Path_12139" data-name="Path 12139" d="M23.074,144.754l-5.106-3.874a6.8,6.8,0,0,1-2.653-4.616,6.8,6.8,0,0,0,2.653,6.233l5.106,3.874a7.594,7.594,0,0,0,4.564,1.536h3.9V146.29h-3.9A7.594,7.594,0,0,1,23.074,144.754Z" transform="translate(-15.267 -136.264)" fill="#ecbe6b" />
              </g>
            </g>
            <g id="Group_3532" data-name="Group 3532" transform="translate(28.711 4.458)">
              <g id="Group_3531" data-name="Group 3531" transform="translate(0 0)">
                <path id="Path_12140" data-name="Path 12140" d="M317.158,87.288h3.9a7.594,7.594,0,0,0,4.564-1.536l5.106-3.874a6.824,6.824,0,0,0-4.044-12.26c-2.784-.033-4.366.055-4.432.059l.174,3.065c.015,0,1.554-.085,4.221-.054a3.755,3.755,0,0,1,2.225,6.745l-5.106,3.874a4.507,4.507,0,0,1-2.709.911h-3.9v3.069Z" transform="translate(-317.158 -69.611)" fill="#ffd064" />
              </g>
            </g>
            <g id="Group_3534" data-name="Group 3534" transform="translate(28.711 5.901)">
              <g id="Group_3533" data-name="Group 3533">
                <path id="Path_12141" data-name="Path 12141" d="M333.033,88.667a6.812,6.812,0,0,0-2.254-3.112,6.811,6.811,0,0,1-1.272,9.606L324.4,99.035a7.6,7.6,0,0,1-4.564,1.536h-2.681v1.218h3.9a7.6,7.6,0,0,0,4.564-1.536l5.106-3.874A6.8,6.8,0,0,0,333.033,88.667Z" transform="translate(-317.156 -85.555)" fill="#ecbe6b" />
              </g>
            </g>
            <g id="Group_3535" data-name="Group 3535" transform="translate(17.636 23.399)">
              <path id="Path_12142" data-name="Path 12142" d="M205.338,288.829l-1.816-1.376a3.128,3.128,0,0,1-1.239-2.493v-6.108h-4.112v6.108a3.128,3.128,0,0,1-1.239,2.493l-1.816,1.376a.741.741,0,0,0,.447,1.331h9.326A.741.741,0,0,0,205.338,288.829Z" transform="translate(-194.822 -278.851)" fill="#ffd064" />
            </g>
            <g id="Group_3536" data-name="Group 3536" transform="translate(22.967 23.399)">
              <path id="Path_12143" data-name="Path 12143" d="M258.89,288.829l-1.816-1.376a3.128,3.128,0,0,1-1.239-2.493v-6.108H253.7v6.108a3.128,3.128,0,0,0,1.239,2.493l1.816,1.376a.741.741,0,0,1-.447,1.331h2.132A.741.741,0,0,0,258.89,288.829Z" transform="translate(-253.704 -278.851)" fill="#ecbe6b" />
            </g>
            <g id="Group_3537" data-name="Group 3537" transform="translate(10.848 0)">
              <path id="Path_12144" data-name="Path 12144" d="M143.7,20.368H120.633a.8.8,0,0,0-.8.8v8.26a19.044,19.044,0,0,0,2.844,10.012l2.13,3.447a8.59,8.59,0,0,0,14.571.069l2.184-3.46A19.044,19.044,0,0,0,144.5,29.331V21.168A.8.8,0,0,0,143.7,20.368Z" transform="translate(-119.833 -20.368)" fill="#ffe07d" />
            </g>
            <g id="Group_3538" data-name="Group 3538" transform="translate(15.095 0)">
              <path id="Path_12145" data-name="Path 12145" d="M186.366,20.368h-3.7v4.466A19.044,19.044,0,0,1,179.73,35l-2.184,3.46a8.592,8.592,0,0,1-10.8,3.252l.727,1.177a8.59,8.59,0,0,0,14.571.069l2.184-3.46a19.044,19.044,0,0,0,2.939-10.163V21.168a.8.8,0,0,0-.8-.8Z" transform="translate(-166.746 -20.368)" fill="#ffd064" />
            </g>
            <g id="Group_3560" data-name="Group 3560" transform="translate(0 18.756)">
              <g id="Group_3539" data-name="Group 3539" transform="translate(2.623 6.471)">
                <path id="Path_12146" data-name="Path 12146" d="M49.523,313.594a21.875,21.875,0,0,1-12.288-3.781,23.424,23.424,0,0,1-8.2-9.782.7.7,0,0,1,1.271-.575A21.313,21.313,0,0,0,49.523,312.2a21.313,21.313,0,0,0,19.219-12.743.7.7,0,0,1,1.271.575,23.424,23.424,0,0,1-8.2,9.782A21.876,21.876,0,0,1,49.523,313.594Z" transform="translate(-28.971 -299.046)" fill="#0dc183" />
              </g>
              <g id="Group_3540" data-name="Group 3540" transform="translate(41.92)">
                <path id="Path_12147" data-name="Path 12147" d="M466.649,233.377c-.762,1.8-2.061,2.977-2.9,2.622s-.9-2.1-.142-3.907a21.265,21.265,0,0,1,3.331-4.428.326.326,0,0,1,.566.239A21.262,21.262,0,0,1,466.649,233.377Z" transform="translate(-463.074 -227.557)" fill="#aad880" />
              </g>
              <g id="Group_3541" data-name="Group 3541" transform="translate(42.026 0)">
                <path id="Path_12148" data-name="Path 12148" d="M468,227.665c-.266.29-.6.659-.952,1.069a15.721,15.721,0,0,1-.743,3.974,4.953,4.953,0,0,1-2.061,2.558,1.077,1.077,0,0,0,.568.733c.84.355,2.139-.819,2.9-2.622a21.265,21.265,0,0,0,.853-5.474A.326.326,0,0,0,468,227.665Z" transform="translate(-464.248 -227.558)" fill="#80cb8e" />
              </g>
              <g id="Group_3542" data-name="Group 3542" transform="translate(37.272 4.598)">
                <path id="Path_12149" data-name="Path 12149" d="M414.851,283.577c.057,1.848-.595,3.367-1.456,3.394s-1.606-1.45-1.663-3.3a20.086,20.086,0,0,1,1.112-5.114.308.308,0,0,1,.58-.018A20.081,20.081,0,0,1,414.851,283.577Z" transform="translate(-411.731 -278.346)" fill="#aad880" />
              </g>
              <g id="Group_3543" data-name="Group 3543" transform="translate(31.483 9.214)">
                <path id="Path_12150" data-name="Path 12150" d="M351.484,333.6c.728,1.7.676,3.352-.116,3.691s-2.024-.763-2.753-2.462a20.083,20.083,0,0,1-.832-5.167.308.308,0,0,1,.533-.228A20.089,20.089,0,0,1,351.484,333.6Z" transform="translate(-347.783 -329.337)" fill="#aad880" />
              </g>
              <g id="Group_3544" data-name="Group 3544" transform="translate(31.483 9.214)">
                <path id="Path_12151" data-name="Path 12151" d="M351.484,333.6a20.085,20.085,0,0,0-3.167-4.166.308.308,0,0,0-.533.228c.024.383.059.868.109,1.4a14.6,14.6,0,0,1,2.17,3.054,4.7,4.7,0,0,1,.4,3.066,1.041,1.041,0,0,0,.91.114C352.16,336.955,352.212,335.3,351.484,333.6Z" transform="translate(-347.783 -329.337)" fill="#80cb8e" />
              </g>
              <g id="Group_3545" data-name="Group 3545" transform="translate(37.139 14.124)">
                <path id="Path_12152" data-name="Path 12152" d="M412.946,386.895c-1.746-.609-2.931-1.762-2.647-2.575s1.929-.98,3.674-.371a20.09,20.09,0,0,1,4.376,2.871.308.308,0,0,1-.191.548A20.081,20.081,0,0,1,412.946,386.895Z" transform="translate(-410.257 -383.581)" fill="#aad880" />
              </g>
              <g id="Group_3546" data-name="Group 3546" transform="translate(31.594 17.936)">
                <path id="Path_12153" data-name="Path 12153" d="M351.7,429.009c-1.746-.609-2.931-1.762-2.647-2.575s1.929-.98,3.674-.371a20.082,20.082,0,0,1,4.376,2.871.308.308,0,0,1-.191.548A20.083,20.083,0,0,1,351.7,429.009Z" transform="translate(-349.011 -425.695)" fill="#aad880" />
              </g>
              <g id="Group_3547" data-name="Group 3547" transform="translate(37.138 14.829)">
                <path id="Path_12154" data-name="Path 12154" d="M418.347,393.9c-.424-.342-1.017-.807-1.657-1.269a18.5,18.5,0,0,1-4.807-.473,6.438,6.438,0,0,1-1.573-.791c0,.011-.01.022-.014.033-.284.814.9,1.967,2.647,2.575a20.086,20.086,0,0,0,5.212.472A.308.308,0,0,0,418.347,393.9Z" transform="translate(-410.254 -391.363)" fill="#80cb8e" />
              </g>
              <g id="Group_3548" data-name="Group 3548" transform="translate(31.594 18.641)">
                <path id="Path_12155" data-name="Path 12155" d="M357.1,436.011c-.424-.342-1.017-.807-1.657-1.269a18.5,18.5,0,0,1-4.807-.473,6.43,6.43,0,0,1-1.573-.791c0,.011-.01.022-.014.033-.284.814.9,1.967,2.647,2.575a20.085,20.085,0,0,0,5.212.472A.308.308,0,0,0,357.1,436.011Z" transform="translate(-349.011 -433.478)" fill="#80cb8e" />
              </g>
              <g id="Group_3549" data-name="Group 3549" transform="translate(0)">
                <path id="Path_12156" data-name="Path 12156" d="M.854,233.377c.762,1.8,2.061,2.977,2.9,2.622s.9-2.1.142-3.907a21.264,21.264,0,0,0-3.331-4.428A.326.326,0,0,0,0,227.9a21.26,21.26,0,0,0,.853,5.474Z" transform="translate(-0.001 -227.557)" fill="#aad880" />
              </g>
              <g id="Group_3550" data-name="Group 3550" transform="translate(0)">
                <path id="Path_12157" data-name="Path 12157" d="M3.9,232.092a21.264,21.264,0,0,0-3.331-4.428A.326.326,0,0,0,0,227.9c.022.395.056.893.1,1.435a16.018,16.018,0,0,1,2.388,3.37A5.025,5.025,0,0,1,2.9,235.93a1.036,1.036,0,0,0,.851.069C4.595,235.644,4.658,233.895,3.9,232.092Z" transform="translate(0 -227.557)" fill="#80cb8e" />
              </g>
              <g id="Group_3551" data-name="Group 3551" transform="translate(5.953 4.598)">
                <path id="Path_12158" data-name="Path 12158" d="M65.761,283.577c-.057,1.848.595,3.367,1.456,3.394s1.606-1.45,1.663-3.3a20.085,20.085,0,0,0-1.112-5.114.308.308,0,0,0-.58-.018A20.082,20.082,0,0,0,65.761,283.577Z" transform="translate(-65.757 -278.346)" fill="#aad880" />
              </g>
              <g id="Group_3552" data-name="Group 3552" transform="translate(37.965 4.598)">
                <path id="Path_12159" data-name="Path 12159" d="M421.812,283.577a20.086,20.086,0,0,0-1.427-5.035.308.308,0,0,0-.58.018c-.121.376-.271.858-.421,1.384a14.59,14.59,0,0,1,.9,3.633,4.771,4.771,0,0,1-.727,2.969,1.031,1.031,0,0,0,.794.425C421.217,286.945,421.869,285.425,421.812,283.577Z" transform="translate(-419.384 -278.347)" fill="#80cb8e" />
              </g>
              <g id="Group_3553" data-name="Group 3553" transform="translate(6.683 4.598)">
                <path id="Path_12160" data-name="Path 12160" d="M75.1,278.56a.308.308,0,0,0-.58-.018c-.149.378-.334.867-.523,1.4a14.759,14.759,0,0,1,.691,3.729,4.873,4.873,0,0,1-.869,2.872,1,1,0,0,0,.729.425c.861.027,1.606-1.45,1.663-3.3A20.085,20.085,0,0,0,75.1,278.56Z" transform="translate(-73.82 -278.346)" fill="#80cb8e" />
              </g>
              <g id="Group_3554" data-name="Group 3554" transform="translate(10.651 9.214)">
                <path id="Path_12161" data-name="Path 12161" d="M118.171,333.6c-.728,1.7-.676,3.352.116,3.691s2.024-.763,2.753-2.462a20.084,20.084,0,0,0,.832-5.167.308.308,0,0,0-.533-.228A20.089,20.089,0,0,0,118.171,333.6Z" transform="translate(-117.657 -329.337)" fill="#aad880" />
              </g>
              <g id="Group_3555" data-name="Group 3555" transform="translate(10.738 9.214)">
                <path id="Path_12162" data-name="Path 12162" d="M122.212,329.436c-.285.306-.648.707-1.031,1.152a14.41,14.41,0,0,1-.709,3.623,4.877,4.877,0,0,1-1.855,2.355,1.035,1.035,0,0,0,.543.728c.792.339,2.024-.763,2.753-2.462a20.085,20.085,0,0,0,.832-5.167A.308.308,0,0,0,122.212,329.436Z" transform="translate(-118.618 -329.337)" fill="#80cb8e" />
              </g>
              <g id="Group_3556" data-name="Group 3556" transform="translate(1.003 14.124)">
                <path id="Path_12163" data-name="Path 12163" d="M16.6,386.895c1.746-.609,2.931-1.762,2.647-2.575s-1.929-.98-3.674-.371a20.09,20.09,0,0,0-4.376,2.871.308.308,0,0,0,.191.548,20.081,20.081,0,0,0,5.212-.472Z" transform="translate(-11.079 -383.581)" fill="#aad880" />
              </g>
              <g id="Group_3557" data-name="Group 3557" transform="translate(6.547 17.936)">
                <path id="Path_12164" data-name="Path 12164" d="M77.843,429.009c1.746-.609,2.931-1.762,2.647-2.575s-1.929-.98-3.674-.371a20.082,20.082,0,0,0-4.376,2.871.308.308,0,0,0,.191.548A20.084,20.084,0,0,0,77.843,429.009Z" transform="translate(-72.325 -425.695)" fill="#aad880" />
              </g>
              <g id="Group_3558" data-name="Group 3558" transform="translate(1.003 14.197)">
                <path id="Path_12165" data-name="Path 12165" d="M19.245,385.054a1.185,1.185,0,0,0-.947-.665,4.316,4.316,0,0,1-2.618,1.965,13.965,13.965,0,0,1-3.532.452c-.363.276-.691.536-.954.747a.308.308,0,0,0,.191.548,20.085,20.085,0,0,0,5.212-.472C18.344,387.021,19.529,385.868,19.245,385.054Z" transform="translate(-11.08 -384.389)" fill="#80cb8e" />
              </g>
              <g id="Group_3559" data-name="Group 3559" transform="translate(6.547 18.01)">
                <path id="Path_12166" data-name="Path 12166" d="M80.49,427.169a1.185,1.185,0,0,0-.947-.665,4.317,4.317,0,0,1-2.618,1.965,13.965,13.965,0,0,1-3.532.452c-.363.276-.691.535-.954.747a.308.308,0,0,0,.191.548,20.093,20.093,0,0,0,5.212-.472C79.589,429.135,80.774,427.982,80.49,427.169Z" transform="translate(-72.325 -426.504)" fill="#80cb8e" />
              </g>
            </g>
            <g id="Group_3561" data-name="Group 3561" transform="translate(17.747 6.46)">
              <ellipse id="Ellipse_1588" data-name="Ellipse 1588" cx="5.219" cy="5.219" rx="5.219" ry="5.219" transform="translate(0 0)" fill="#ecbe6b" />
            </g>
            <g id="Group_3562" data-name="Group 3562" transform="translate(18.122 6.835)">
              <path id="Path_12167" data-name="Path 12167" d="M206.979,95.87a5.219,5.219,0,0,1-6.787,6.787,5.219,5.219,0,1,0,6.787-6.787Z" transform="translate(-200.192 -95.87)" fill="#eab14d" />
            </g>
            <g id="Group_3563" data-name="Group 3563" transform="translate(20.419 9.168)">
              <path id="Path_12168" data-name="Path 12168" d="M228.491,121.83l.547,1.107a.341.341,0,0,0,.257.186l1.222.178a.341.341,0,0,1,.189.582l-.884.862a.341.341,0,0,0-.1.3l.209,1.217a.341.341,0,0,1-.495.359l-1.093-.575a.341.341,0,0,0-.317,0l-1.093.575a.341.341,0,0,1-.495-.359l.209-1.217a.34.34,0,0,0-.1-.3l-.884-.862a.341.341,0,0,1,.189-.582l1.222-.178a.341.341,0,0,0,.257-.186l.547-1.107A.341.341,0,0,1,228.491,121.83Z" transform="translate(-225.563 -121.64)" fill="#ffe07d" />
            </g>
            <g id="Group_3565" data-name="Group 3565" transform="translate(23.587 10.764)">
              <g id="Group_3564" data-name="Group 3564">
                <path id="Path_12169" data-name="Path 12169" d="M262.535,139.923a.341.341,0,0,0-.189-.582l-.445-.065-1.187,1.157a.5.5,0,0,0-.145.447l.267,1.555.432.227a.341.341,0,0,0,.495-.359l-.209-1.217a.341.341,0,0,1,.1-.3Z" transform="translate(-260.561 -139.277)" fill="#ffd064" />
              </g>
            </g>
            <g id="Group_3566" data-name="Group 3566" transform="translate(15.635 34.708)">
              <path id="Path_12170" data-name="Path 12170" d="M187.031,411.733H173.487a.775.775,0,0,1-.775-.775v-6.4a.775.775,0,0,1,.775-.775h13.544a.775.775,0,0,1,.775.775v6.4A.775.775,0,0,1,187.031,411.733Z" transform="translate(-172.712 -403.78)" fill="#407093" />
            </g>
            <g id="Group_3567" data-name="Group 3567" transform="translate(15.635 34.708)">
              <path id="Path_12171" data-name="Path 12171" d="M187.033,403.779h-1.467v4.94a.773.773,0,0,1-.773.773H172.712v1.467a.773.773,0,0,0,.773.773h13.548a.773.773,0,0,0,.773-.773v-6.407a.773.773,0,0,0-.773-.773Z" transform="translate(-172.712 -403.779)" fill="#365e7d" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}

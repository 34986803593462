import styled from "styled-components";

export const BurnoutSyndromeStyled = styled.div`
  .top_men {
    display: flex;
    flex-wrap: wrap;
    // margin-bottom: 75px;
    row-gap: 16px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }
    .right_men_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .label_layout {
        margin-right: 14px;
        /* padding-top: 10px; */
        .date_show {
          display: flex;
          align-items: center;
          flex-direction: column;
          .show_icon {
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          }
          .show_label {
            min-width: 178px;
            color: ${({ theme }) => theme.COLORS.GRAY_12};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            margin-left: 8px;
          }
        }
        .date_layout {
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
      .show_search {
      }
    }
  }
  .title_graph {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    margin-top: 10px;
    padding-left: 10px;
  }
  .bottom_men {
    display: flex;
    margin-top: 30px;
    .body_graph {
      padding: 0px 20px;
      width: 92%;
      .label_g {
        position: absolute;
        top: -15px;
        left: 0px;
        color: ${({ theme }) => theme.COLORS.GRAY_4};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: noto_sans_thai_medium, noto_sans_regular;
      }
    }

    .seleton_body {
      width: 100%;
    }
  }
  .label_empty {
    margin-top: 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  @media (max-width: 1060px) {
    .bottom_men {
      flex-direction: column;
      .body_graph {
      }
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .bottom_men {
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .bottom_men {
    }
  }
`;

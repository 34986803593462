import { MsdPercentOfRiskPuiAndPainStyled } from './styled';
import {
  Blocks,
  Charts,
  // Skeleton,
} from 'components';
// import Chart from 'react-apexcharts';
// import { useState, useEffect } from 'react';
// import { RENDER_MONTH } from 'utils/functions/date';
// import moment from 'moment';

const MsdPercentOfRiskPuiAndPain = ({ data }) => {
  // useEffect(() => {
  //   if (data && data.length > 0) {

  //   }
  // }, [data]);

  return (
    <MsdPercentOfRiskPuiAndPainStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="24px"
        paddingLeft="24px"
      >
        {data ? (
          <>
            <div className="top_title">
              <div className="head">
                สัดส่วนผู้มีความเสี่ยง Pui และผู้มีอาการเจ็บ
                แต่ไม่มีความเสี่ยงแบ่งตามลักษณะการทำงาน
              </div>
              <div className="sub_head">
                Percentage of employees who are at PUI risk and pain without
                risk by line of work
              </div>
            </div>
            <div className="label_show">
              <div className="show_being">
                <Blocks.BoxWellbeing
                  theme_msd_pui
                  label="มีอาการแต่ยังไม่มีความเสี่ยง"
                  labelBottom="Pain without risk"
                  bgColor="#6FB5CB"
                  showRound
                />
              </div>
              <div className="show_being no_margin">
                <Blocks.BoxWellbeing
                  theme_msd_pui
                  label="มีความเสี่ยงเข้าเกณฑ์เป็นโรค"
                  labelBottom="PUI risk"
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
            <div className="graph_show">
              <Charts.TwoTone
                labelTop="งานในสายการผลิต"
                labelBottom="ต้องเคลื่อนไหวร่างกายเยอะเป็นหลัก"
                data={
                  data &&
                  data.worktype_assembly &&
                  data.worktype_assembly.norisk &&
                  data.worktype_assembly
                }
              />
            </div>
            <div className="graph_show">
              <Charts.TwoTone
                labelTop="งานนั่งโต๊ะ งานเอกสาร"
                labelBottom="งานเคลื่อนไหวร่างกายใกล้เคียงเป็นหลัก"
                data={
                  data &&
                  data.worktype_office &&
                  data.worktype_office.norisk &&
                  data.worktype_office
                }
              />
            </div>
            <div className="graph_show">
              <Charts.TwoTone
                labelTop="ทำทั้งสองงานควบคู่"
                labelBottom="งานในสายการผลิตและงานตั้งโต๊ะงานเอกสาร"
                data={
                  data &&
                  data.worktype_assembly_office &&
                  data.worktype_assembly_office.norisk &&
                  data.worktype_assembly_office
                }
              />
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </MsdPercentOfRiskPuiAndPainStyled>
  );
};

export default MsdPercentOfRiskPuiAndPain;

export default function renderIcon({ color = '#4E5B7E', width = "122", height = "144" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="122" height="144" viewBox="0 0 122 144">
      <defs>
        <filter id="Rectangle_32500" x="-9" y="-6" width="140" height="162" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clip-path">
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_32500)">
            <rect id="Rectangle_32500-2" data-name="Rectangle 32500" width="122" height="144" rx="8" fill="#fff" stroke="rgba(112,112,112,0.1)" stroke-width="1" />
          </g>
        </clipPath>
      </defs>
      <g id="Mask_Group_89516" data-name="Mask Group 89516" clip-path="url(#clip-path)">
        <g id="runner" transform="matrix(-0.995, -0.105, 0.105, -0.995, 94.153, 211.154)">
          <path id="Path_97932" data-name="Path 97932" d="M0,10.509s3.23-5.25,3.634-9.288,12.115,3.23,12.115,3.23l.807,5.25s-.4,16.153-.807,16.153c-.4.4-5.25,1.211-5.25,1.211L0,10.509Z" transform="translate(23.5 161.677)" fill="#fdc9a6" />
          <path id="Path_97933" data-name="Path 97933" d="M0,2.019C.4,1.211.807.808,1.211,0L8.076,10.9Z" transform="translate(24.711 167.744)" fill="#f5b54c" />
          <g id="Group_90947" data-name="Group 90947" transform="translate(18.197 168.932)">
            <path id="Path_97934" data-name="Path 97934" d="M15.8,14.941S10.149,1.211,2.88,0A1.483,1.483,0,0,0,1.669.4a1.768,1.768,0,0,0,0,2.423V4.442s0,.808.4.808c0,0-.4,0-.4.808V7.673S-.35,7.673.053,8.884s2.827,3.23,2.827,3.23.4.4,0,1.211a3.107,3.107,0,0,0,.4,2.423l4.038,6.057,6.461,2.423C13.783,23.826,20.244,17.768,15.8,14.941Z" transform="translate(0 0.023)" fill="#fdc9a6" />
            <path id="Path_97935" data-name="Path 97935" d="M14.941,13.349S6.865-2.8,0,.427l12.922,16.96Z" transform="translate(2.072 0)" fill="#fdc9a6" />
          </g>
          <path id="Path_97936" data-name="Path 97936" d="M2.423,10.1V7.673H4.038c0,.4.4,2.827,1.211,3.634,1.212.808,3.634,1.615,4.442-.4s.4-8.48.4-8.48S14.134,1.615,14.941,0c0,0,4.442,9.692,3.634,14.134-.4,4.846-5.25,7.673-5.25,7.673A19.6,19.6,0,0,1,5.25,23.018,6.874,6.874,0,0,1,0,18.979v-.808s4.442,1.615,2.827-.4c0-.4-.4-.4-.4-.808a4.389,4.389,0,0,1-.4-2.019C2.423,12.922,2.826,11.711,2.423,10.1Z" transform="translate(24.712 170.974)" fill="#2f2f2f" />
          <g id="Group_90948" data-name="Group 90948" transform="translate(12.743 19.811)">
            <path id="Path_97937" data-name="Path 97937" d="M0,1.034S0-.582,1.615.226s4.846,3.634,3.23,6.865c0,0-1.615,2.423-3.23-.808A13.115,13.115,0,0,1,0,1.034Z" transform="translate(16.007 156.995)" fill="#fdc9a6" />
            <path id="Path_97938" data-name="Path 97938" d="M.808,43.208,0,38.766s3.23-4.442,5.25-5.653,6.057-6.057,6.057-6.057,6.461-8.884,11.711-12.922C27.864,9.692,30.286,0,30.286,0s9.288,1.211,9.692,6.057L37.555,8.48s-6.461,7.673-7.673,12.115-9.288,18.979-14.134,21C10.5,43.208.808,43.208.808,43.208Z" transform="translate(39.833 2.559)" fill="#fdc9a6" />
            <path id="Path_97939" data-name="Path 97939" d="M18.43,52.227l-1.615-5.25-2.423-2.423a1.949,1.949,0,0,1,0-1.615c.4-.807,1.615-10.5.4-19.383-1.211-8.48-.4-21.4-.4-21.4S5.1-2.692,4.3,2.154c0,0,1.615,8.884.807,14.133s-2.423,10.5-2.019,18.172c0,0-.808,6.461-2.019,8.076s-1.615,6.057,0,8.48c1.615,2.019,8.076,3.231,8.076,3.231Z" transform="translate(0 0)" fill="#fdc9a6" />
          </g>
          <path id="Path_97940" data-name="Path 97940" d="M0,2.423a.869.869,0,0,0,.808.808c3.634.808,6.057,2.423,9.288,4.846,3.634,2.827,6.461,9.288,6.461,9.288,2.827-2.423,11.307-1.211,11.307-1.211l2.423-5.25-.4-.808L25.441,5.653,2.827,0A7.031,7.031,0,0,0,0,1.211Z" transform="translate(0.078 7.024)" fill="#425a6b" />
          <path id="Path_97941" data-name="Path 97941" d="M.078,3.5s6.865-1.211,10.9,1.211a5.736,5.736,0,0,0,2.423.808c3.634.4,12.519,2.827,15.345,5.25,0,0,2.019,1.615,2.423.808,0,0,2.019-1.616,1.211-3.634S25.115,4.3,25.115,4.3a63.194,63.194,0,0,0-8.076-2.827C13.4.67,6.136-.541,2.5.266.886,1.074-.326,2.286.078,3.5Z" transform="translate(0 5.949)" fill="#e8eae8" />
          <path id="Path_97942" data-name="Path 97942" d="M.3.4a.757.757,0,0,0,0,1.211,19.947,19.947,0,0,1,3.23,10.1c.808,4.442-1.615,10.9-1.615,10.9,3.634,0,8.884,6.865,8.884,6.865l5.25-1.615.4-.808V21L4.341,1.211A9.647,9.647,0,0,0,1.514,0,4.066,4.066,0,0,0,.3.4Z" transform="translate(80.136 0.562)" fill="#425a6b" />
          <path id="Path_97943" data-name="Path 97943" d="M0,.966S5.653,4.6,6.865,9.447a2.221,2.221,0,0,0,1.211,2.019c2.019,2.827,6.865,10.5,7.269,14.134,0,0,0,2.423,1.211,2.423a4.994,4.994,0,0,0,3.634-1.615c.808-2.019-2.827-7.673-2.827-7.673a79.542,79.542,0,0,0-4.038-7.673C11.71,7.831,6.864,2.177,3.634.158A3.875,3.875,0,0,0,0,.966Z" transform="translate(80.439 0)" fill="#e8eae8" />
          <path id="Path_97944" data-name="Path 97944" d="M42.8,45.369l3.634-3.23a9.051,9.051,0,0,0-.807-6.057c-1.211-3.634.807-7.673.807-7.673s3.634-10.9,3.634-15.749c0-4.442,4.038-9.692,4.038-9.692Q45.632-1.272,38.363.545L26.248,15.89,15.749,6.2S10.9,12.256,0,10.64c0,0,5.25,14.941,8.076,18.979s4.038,8.48,7.269,11.307C18.575,43.35,42.8,45.369,42.8,45.369Z" transform="translate(13.808 60.994)" fill="#425a6b" />
          <path id="Path_97945" data-name="Path 97945" d="M3.23,21.806,0,0S7.673,3.23,10.9,6.057A27.418,27.418,0,0,0,14.537,8.48c1.615.808,2.423,2.423,2.019,3.634a3.052,3.052,0,0,1-.4,1.615c-.808,2.423-2.827,8.076-4.442,10.5C9.288,26.248,3.23,21.806,3.23,21.806Z" transform="translate(45.71 128.17)" fill="#fdc9a6" />
          <path id="Path_97946" data-name="Path 97946" d="M7.673,16.96l8.48-9.692A16.931,16.931,0,0,0,7.673,0L0,15.749Z" transform="translate(40.864 145.13)" fill="#425a6b" />
          <path id="Path_97947" data-name="Path 97947" d="M27.519,65.876s3.634-11.711,3.23-18.172-1.211-14.134-.4-17.364c.808-3.634,3.23-12.922,3.634-13.73,0,0,5.653-4.442,5.653-10.5a66.949,66.949,0,0,1-6.865-2.019,38.431,38.431,0,0,0-10.5-1.615S13.789.861,12.174.053c-1.615-.4-4.442,1.615-4.442,1.615S9.751,8.13,9.347,10.957,4.5,27.917,4.5,31.955V35.59S.463,43.262.059,49.723,1.271,63.049,6.117,67.088a64.343,64.343,0,0,0,6.461,4.846h.808a47.646,47.646,0,0,0,6.461-.4h.808c1.211,0,2.423-2.019,2.423-2.019A3.012,3.012,0,0,1,25.5,67.492,2.781,2.781,0,0,0,27.519,65.876Z" transform="translate(20.614 97.022)" fill="#218e89" />
          <path id="Path_97948" data-name="Path 97948" d="M15.345,18.254l.4-2.423a3.108,3.108,0,0,0,.4-2.423,31.923,31.923,0,0,1-3.23-4.442c-.808-1.615-3.634-.808-3.634-.808L7.269,10.985S5.25,11.793,4.846,12.6H3.634c-.4-.4-.808-.808-1.211-.808s-.4-1.615,1.211-1.211v-.4H1.211a14.282,14.282,0,0,1-.4-2.423S0,7.755,0,6.543A1.494,1.494,0,0,1,.808,5.332,1.769,1.769,0,0,1,.4,4.12c0-.807.4-.807.4-.807A1.769,1.769,0,0,1,.4,2.1,1.494,1.494,0,0,1,1.211.89,20.5,20.5,0,0,0,4.845.082C5.653-.321,8.48.89,8.48.89a39.923,39.923,0,0,0,6.057-.4,12.938,12.938,0,0,1,8.884,2.019s2.423.4,2.827,2.423c0,0,3.231,4.038,2.827,12.922v.4c-.4,6.057-8.48,8.076-11.711,3.23Z" transform="translate(9.77 124.857)" fill="#fdc9a6" />
          <path id="Path_97949" data-name="Path 97949" d="M0,14.538a4.586,4.586,0,0,1,1.211-3.23A9.911,9.911,0,0,0,4.038,6.461S4.038,2.019,5.25,0c2.019,4.038,9.288,5.25,13.326,2.423,0,0,1.615,6.865-.807,11.711-2.019,2.827-2.423,8.076-2.827,10.9,0,1.211-.4,3.23-2.019,3.634H11.711s-2.019-.808-6.461-4.846A19.318,19.318,0,0,1,0,14.538Z" transform="translate(20.673 140.688)" fill="#425a6b" />
          <path id="Path_97950" data-name="Path 97950" d="M0,18.575,2.827,0,8.48,5.249Z" transform="translate(31.576 71.635)" fill="#344a5e" />
        </g>
      </g>
    </svg>
  )
}

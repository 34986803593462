export default function Other() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_57057" data-name="Group 57057" transform="translate(-96 -736)">
        <g id="Group_15960" data-name="Group 15960" transform="translate(-56 208)">
          <circle id="Ellipse_1504" data-name="Ellipse 1504" cx="16" cy="16" r="16" transform="translate(152 528)" fill="#aad3ed" />
          <g id="marriage" transform="translate(159.969 535.028)">
            <path id="Path_19226" data-name="Path 19226" d="M32.6,0C31.122,0,31,2.266,31,3.738a.536.536,0,0,0,.534.532H35.27C35.345,1.107,33.7,0,32.6,0Z" transform="translate(-31 0)" fill="#e1e6f0" />
            <path id="Path_19227" data-name="Path 19227" d="M88.276,0H76c1.1,0,1.677,1.107,1.6,4.27V17.685a.533.533,0,0,0,.534.534H89.877a.533.533,0,0,0,.534-.534V3.736C90.411.981,89.308,0,88.276,0Z" transform="translate(-74.399 0)" fill="#f3f5f9" />
            <path id="Path_19228" data-name="Path 19228" d="M307.4,17.685V3.736C307.4.981,306.3,0,305.27,0H301V18.218h5.871A.533.533,0,0,0,307.4,17.685Z" transform="translate(-291.393 0)" fill="#e1e6f0" />
            <g id="Group_13784" data-name="Group 13784" transform="translate(5.337 14.98)">
              <path id="Path_19229" data-name="Path 19229" d="M183.669,422.067h-2.135a.534.534,0,0,1,0-1.067h2.135a.534.534,0,0,1,0,1.067Z" transform="translate(-181 -421)" fill="#fb9" />
            </g>
            <g id="Group_13785" data-name="Group 13785" transform="translate(10.675 14.98)">
              <path id="Path_19230" data-name="Path 19230" d="M333.669,422.067h-2.135a.534.534,0,1,1,0-1.067h2.135a.534.534,0,1,1,0,1.067Z" transform="translate(-331 -421)" fill="#ff9680" />
            </g>
            <path id="Path_19231" data-name="Path 19231" d="M229.736,60.015a1.622,1.622,0,0,1-2.135,0,1.553,1.553,0,0,0-1.6,1.536c0,.907.755,1.458,1.8,2.222l.549.406a.533.533,0,0,0,.64,0l.547-.4c1.046-.764,1.8-1.316,1.8-2.224A1.553,1.553,0,0,0,229.736,60.015Z" transform="translate(-219.061 -57.879)" fill="#ff8c9f" />
            <path id="Path_19232" data-name="Path 19232" d="M301.867,63.775c1.046-.764,1.8-1.316,1.8-2.224A1.608,1.608,0,0,0,301,60.416v3.869a.531.531,0,0,0,.32-.107Z" transform="translate(-291.393 -57.88)" fill="#f255a3" />
            <g id="Group_13786" data-name="Group 13786" transform="translate(5.337 7.472)">
              <path id="Path_19233" data-name="Path 19233" d="M241.534,211.067h3.2a.534.534,0,1,0,0-1.067h-3.2a.534.534,0,1,0,0,1.067Z" transform="translate(-238.865 -210)" fill="#fb9" />
              <path id="Path_19234" data-name="Path 19234" d="M181.534,271.067h7.472a.534.534,0,0,0,0-1.067h-7.472a.534.534,0,0,0,0,1.067Z" transform="translate(-181 -267.865)" fill="#fb9" />
              <path id="Path_19235" data-name="Path 19235" d="M189.006,330h-7.472a.534.534,0,0,0,0,1.068h7.472a.534.534,0,0,0,0-1.068Z" transform="translate(-181 -325.73)" fill="#fb9" />
            </g>
            <path id="Path_19236" data-name="Path 19236" d="M303.135,210.534A.533.533,0,0,0,302.6,210H301v1.067h1.6A.533.533,0,0,0,303.135,210.534Z" transform="translate(-291.393 -202.528)" fill="#ff9680" />
            <path id="Path_19237" data-name="Path 19237" d="M305.27,270.534a.533.533,0,0,0-.534-.534H301v1.067h3.736A.533.533,0,0,0,305.27,270.534Z" transform="translate(-291.393 -260.393)" fill="#ff9680" />
            <path id="Path_19238" data-name="Path 19238" d="M305.27,330.534a.533.533,0,0,0-.534-.534H301v1.068h3.736A.533.533,0,0,0,305.27,330.534Z" transform="translate(-291.393 -318.258)" fill="#ff9680" />
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from "styled-components";

export const ListEmployeeStyled = styled.div`
  max-width: 658px;
  height: 485px;
  width: 80vw;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  color: ${({ theme }) => theme.COLORS.BLACK_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S18};
  font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
  /* padding: 16px 32px; */
  border-radius: 8px;
  box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
  .group_list_emp {
    height: 100%;
    .top_list {
      padding: 29px 32px 16px 32px;
      display: flex;
      column-gap: 32px;
      position: relative;
      .group_text {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .small_1 {
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
        .small_2 {
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
        .small_3 {
          color: ${({ theme }) => theme.COLORS.GRAY_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .line {
        border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      }
      .svg_x {
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 11px;
      }
    }
    .empty {
      text-align: center;
      margin-top: 50px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    }
    .show_data {
      padding: 0px 32px;
      /* margin-top: 18px; */
      /* display: flex; */
      column-gap: 18px;
      row-gap: 12px;
      flex-wrap: wrap;
      // max-height: 319px;
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100% - 62px);
      overflow-x: hidden;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #f0f0f0;
      }

      .loading {
        margin-top: 10px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    width: 80vw;
    height: 80vh;
  }
`;

export default function Search({ color = "#002366" }) {
  return (
    <svg
      id="_007-search"
      data-name="007-search"
      xmlns="http://www.w3.org/2000/svg"
      width="23.999"
      height="24"
      viewBox="0 0 23.999 24"
    >
      <g id="Searching_1_" transform="translate(0 0)">
        <g
          id="Group_2333"
          data-name="Group 2333"
          transform="translate(15.47 15.469)"
        >
          <path
            id="Path_6122"
            data-name="Path 6122"
            d="M334.967,337.933l-4.312-4.312a2.109,2.109,0,0,1,2.983-2.983l4.312,4.312a2.109,2.109,0,0,1-2.983,2.983Z"
            transform="translate(-330.037 -330.021)"
            fill={color}
          />
        </g>
        <g id="Group_2334" data-name="Group 2334">
          <path
            id="Path_6123"
            data-name="Path 6123"
            d="M8.452,0A8.437,8.437,0,1,0,16.89,8.437,8.447,8.447,0,0,0,8.452,0Zm0,14.062a5.625,5.625,0,1,1,5.625-5.625,5.631,5.631,0,0,1-5.625,5.625Z"
            transform="translate(-0.015)"
            fill={color}
          />
        </g>
      </g>
      <path
        id="Path_6124"
        data-name="Path 6124"
        d="M286.939,285.929l-4.049-4.049a.7.7,0,1,0-.994.994l4.049,4.049A3.5,3.5,0,0,1,286.939,285.929Z"
        transform="translate(-268.486 -268.471)"
        fill={color}
      />
    </svg>
  );
}

import { RewardTotalViewerStyled } from "./styled";
import { Blocks, Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const RewardTotalViewer = ({ data }) => {
  const renderPercent = () => {
    let numberActive = Math.floor(
      ((data?.redeemed_count / data?.total_redeemable_count) * 100) / 10
    );

    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) =>
      i < numberActive ? (
        <Icons.PeoplePercent key={i} active />
      ) : (
        <Icons.PeoplePercent key={i} />
      )
    );
  };

  return (
    <RewardTotalViewerStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="13px"
        paddingRight="25px"
        paddingBottom="16px"
        paddingLeft="25px"
      >
        <div className="top_current">
          <div>ยอดผู้แลกรางวัลนี้</div>
          <div className="sub_top">Total Redeem</div>
        </div>
        <div className="mid_current">
          <div>{RenderCommaMoney(data?.redeemed_count, true)}</div>
          <div className="label_mid">คน</div>
        </div>
        <div className="bottom_current">{data && renderPercent()}</div>
      </Blocks.Box>
    </RewardTotalViewerStyled>
  );
};

export default RewardTotalViewer;

export default function FamaleCircle({ color = '#e86751' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_33151"
        data-name="Group 33151"
        transform="translate(-1152 -1040)"
      >
        <g
          id="Ellipse_2203"
          data-name="Ellipse 2203"
          transform="translate(1152 1040)"
          fill="#fff"
          stroke={color}
          strokeWidth="1"
        >
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="19.5" fill="none" />
        </g>
        <g id="female-sign-svgrepo-com" transform="translate(1157.873 1059)">
          <path
            id="Path_34251"
            data-name="Path 34251"
            d="M22.576,8.886A8.818,8.818,0,0,0,13.853,0,8.818,8.818,0,0,0,5.127,8.886a8.859,8.859,0,0,0,6.89,8.687v2.068H10.076a1.729,1.729,0,0,0,0,3.458h1.941V24.45a1.7,1.7,0,1,0,3.4,0V23.1h2.038a1.729,1.729,0,0,0,0-3.458H15.413V17.63A8.882,8.882,0,0,0,22.576,8.886Zm-14.064,0a5.339,5.339,0,1,1,5.338,5.439A5.394,5.394,0,0,1,8.512,8.886Z"
            transform="translate(0 -12)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

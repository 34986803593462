import { MaleFemaleAverageAgeStyled } from "./styled";
import {
  Blocks,
  Cards,
  Typographys,
  Icons,
  Inputs,
  Skeleton,
} from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import moment from "moment";
import theme from "styles/theme.json";

const MaleFemaleAverageAge = ({ data, fetchData }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_data, _setData] = useState([]);

  useEffect(() => {
    if (data && data.age_groups.length > 0) {
      _setData(data.age_groups);
    } else {
      _setData([]);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);
    if (res && res.age_groups.length > 0) {
      _setData(res.age_groups);
      _setLoadSkeleton(false);
    } else {
      _setData([]);
      _setLoadSkeleton(false);
    }
  };
  const handleChangDate = (e) => {
    _setLoadSkeleton(true);
    fetchDataApi(
      `?period=monthly&stat_date=${moment(e)
        .startOf("months")
        .format("YYYY-MM-DD")}`
    );
  };

  return (
    <MaleFemaleAverageAgeStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="21px"
        paddingRight="33px"
        paddingBottom="40px"
        paddingLeft="19px"
      >
        <div className="top_age">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="สัดส่วนผู้ที่มีสุขภาวะแบบต่างๆ แบ่งตามเพศและอายุ"
              labelBottom="Percentage of employees who have Wellbeing condition by gender and age"
              warpText
            />
          </div>
          <div className="right_men_layout">
            <div className="group_gender">
              <div className="box_show">
                <Typographys.ShowGender
                  theme_gender_male
                  svg={<Icons.Male2 />}
                  label="ชาย"
                  labelBottom="Male"
                />
              </div>

              <Typographys.ShowGender
                theme_gender_female
                svg={<Icons.Female2 />}
                label="หญิง"
                labelBottom="Female"
              />
            </div>
            <div className="group_date">
              <div className="box_date">
                <Inputs.InputDatePikerCustom
                  theme_standard_date_picker_custom
                  dateFormat="MMMM yyyy"
                  onChangeCustom={(e) => handleChangDate(e)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  initialValues={new Date()}
                />
              </div>
              {/* <div className="box_date">
                <Inputs.InputDropDownFilter
                  iconSvgClose={<Icons.RectArrowDown />}
                  iconSvgOpen={<Icons.RectArrowUp />}
                  placeholder="2020"
                />
              </div>
              <div className="box_date">
                <Inputs.InputDropDownFilter
                  iconSvgClose={<Icons.RectArrowDown />}
                  iconSvgOpen={<Icons.RectArrowUp />}
                  placeholder="November"
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="bottom_age">
          {_data && _data.length > 0 ? (
            <>
              {_loadSkeleton ? (
                <div className="body_skeleton">
                  <Skeleton.GraphSkeleton height="365px" />
                </div>
              ) : (
                <>
                  <div className="graph_show">
                    <Chart
                      options={{
                        chart: {
                          type: "bar",
                          fontFamily: theme.FONT.STYLE.REGULAR,
                          height: 350,
                          toolbar: {
                            show: false,
                          },
                        },
                        grid: {
                          yaxis: {
                            lines: {
                              show: false,
                            },
                          },
                          row: {
                            colors: [
                              "#FFF5F0",
                              "#F0F6F5",
                              "#EAECF0",
                              "#EAECF0",
                              "#EAECF0",
                            ],
                          },
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "55%",
                            endingShape: "rounded",
                            borderRadius: 10,
                          },
                        },

                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          show: true,
                          width: 3,
                          colors: ["transparent"],
                        },
                        legend: {
                          show: false,
                          position: "right",
                          offsetX: -20,
                          offsetY: 190,
                        },
                        colors: ["#003C58", "#FB4844"],
                        xaxis: {
                          // categories: [
                          //   // 'Total',
                          //   "18 y- 19 y",
                          //   "20 y- 34 y",
                          //   "35 y- 49 y",
                          //   "50 y-64 y",
                          //   "65 y and older",
                          // ],
                          categories: [
                            // 'Total',
                            // "18 - 19 ปี",
                            "20 y and less",
                            "20 y - 34 y",
                            "35 - 49 y",
                            "50 - 64 y",
                            "65 y and older",
                          ],
                          axisBorder: {
                            show: true,
                            color: "#B3B3B3",
                            height: 4,
                          },
                        },
                        yaxis: {
                          axisBorder: {
                            show: true,
                            color: "#B3B3B3",
                            width: 4,
                          },
                          max: 100,
                          tickAmount: 5,
                        },
                        fill: {
                          opacity: 1,
                        },
                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return val + "%";
                            },
                          },
                        },
                      }}
                      // series={[
                      //   {
                      //     name: 'Net Profit',
                      //     data: [44, 55, 57, 56, 61, 58, 100],
                      //   },
                      //   {
                      //     name: 'Net Profit',
                      //     data: [44, 55, 57, 56, 61, 48, 100],
                      //   },
                      // ]}
                      series={[
                        {
                          name: "Male",
                          data: _data && [..._data.map((e) => e.male.percent)],
                        },
                        {
                          name: "Female",
                          data: _data && [
                            ..._data.map((e) => e.female.percent),
                          ],
                        },
                      ]}
                      type="bar"
                      height={350}
                      width="100%"
                    />
                  </div>
                  <div className="group_label_layout">
                    <div className="good_item">
                      <Cards.ShowIconWellbeing
                        theme_show_icon
                        svg={<Icons.EmotionSmile />}
                        label="สุขภาวะทางจิตอยู่ในระดับดี"
                        subLabel="Good wellbeing"
                        fontColor="#FD6524"
                      />
                    </div>
                    <div className="pool_item">
                      <Cards.ShowIconWellbeing
                        theme_show_icon
                        svg={<Icons.Poor />}
                        label="สุขภาวะทางจิตอยู่ในระดับต่ำกว่าปกติ"
                        subLabel="Poor wellbeing"
                        fontColor="#009078"
                      />
                    </div>
                    <div className="depress_item">
                      <Cards.ShowIconWellbeing
                        theme_show_icon
                        svg={<Icons.Depressed />}
                        label="มีแนวโน้มของภาวะซึมเศร้า"
                        subLabel="Likely depressed state"
                        fontColor="#4D5C7D"
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="label_empty">ยังไม่มีข้อมูล</div>
          )}
        </div>
      </Blocks.Box>
    </MaleFemaleAverageAgeStyled>
  );
};

export default MaleFemaleAverageAge;

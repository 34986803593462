import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputSearchStyled } from "./styled";
import { Buttons, Icons } from "components";

const InputSearch = ({
  theme_standard_search,
  show_bottom_label,
  fontColor,
  placeholder,
  svg,
  onSubmit,
  initialValue,
}) => {
  const customClass = cx({
    theme_standard_search: theme_standard_search,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (initialValue) {
      _setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e) => {
    _setValue(e.target.value);
  };

  const handleClickSubmit = () => {
    onSubmit(_value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit(_value);
    }
  };

  return (
    <InputSearchStyled fontColor={fontColor}>
      <div className={customClass}>
        <div className="line_search">
          <div className="search_layout">
            <div className="icon">{svg ? svg : <Icons.Search />}</div>
            <input
              className="input_serach"
              value={_value}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="btn_serach">
            <Buttons.BtnNormal
              theme_standard_btn_round_red_bold
              label="ค้นหา"
              fontFamilyBottom="noto_sans_thai_bold, noto_sans_bold;"
              colorBottom="#F0211D"
              fontSize="16px"
              padding="10px"
              borderRadius="4px"
              onClick={handleClickSubmit}
            />
          </div>
        </div>
        {show_bottom_label && (
          <div className="show_label">
            <div className="btn_label">Search</div>
          </div>
        )}
      </div>
    </InputSearchStyled>
  );
};

InputSearch.propTypes = {};

export default InputSearch;

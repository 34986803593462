import React from "react";
import { AnnouncementContainerStyled } from "./styled";
import {
  TableCustom,
  Loading,
  Modals,
  Icons,
  Buttons,
  BGLoading,
  Inputs,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import moment from "moment";
import theme from 'styles/theme.json';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { getObjectKey } from "utils/functions/getObjectKey";

class AnnouncementContainer extends React.Component {
  state = {
    isLoading: true,
    tabIndex: 1,
    spinningCount: 0,
    // departmentValue: null,
    departmentOpt: [],
    spinItemOpt: [],
    // spinItemValue: null,
    countPage: 1,
    allSpinItem: []
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchAllData()
    this.checkPermission()
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'spin_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  fetchAllData = async () => {
    let res = await Promise.all([
      this.fetchDepartment('?per_page=200&offset=0'),
      this.fetchSpinningCount(),
      this.fetchSpinItem('?per_page=100&offset=0'),
      this.fetchData('?per_page=10&offset=0'),
    ]);

    if (res && res[0] && res[1]) {
      this.setState({
        departmentOpt: res[0],
        spinningCount: res[1],
      })
    }
  }

  fetchData = async (stringQuery) => {
    const { tabIndex } = this.state

    let res = await adminService.GET_SPINNING_LIST_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery + `&has_item=${tabIndex === 1 ? false : true}`,
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
        isLoadingTable: false,
      });
    } else {
      this.setState({
        data: [],
        isLoadingTable: false,
      });
    }
  };

  fetchDepartment = async (stringQuery) => {
    let res = await adminService.GET_DEPARTMENT_LIST(
      this.props.companySelectedRedux.id,
      stringQuery,
    );
    if (res && res.success) {
      let tempData
      let arrayTemp = [{ label: "ทั้งหมด", name: "all", value: "all" }];
      // this.setState({
      //   departmentValue: arrayTemp[0]
      // })
      tempData = [
        ...arrayTemp,
        ...res.data.map((e) => ({
          label: e.name,
          name: e.id,
          value: e.id,
        })),
      ]

      return tempData
    }
  };

  fetchSpinningCount = async (stringQuery) => {
    let res = await adminService.GET_SPINNING_COUNT_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery,
    );
    if (res && res.success) {
      return res.data
    }
  };

  fetchSpinItem = async (queryString) => {
    const { allSpinItem, countPage } = this.state
    const { companySelectedRedux } = this.props;

    let res = await adminService.GET_SPIN_LIST_BY_COMPANY_ID(companySelectedRedux?.id, queryString);

    if (res && res.success) {
      let tempAllSpin = allSpinItem
      tempAllSpin.push(...res.data)
      this.setState({
        allSpinItem: tempAllSpin,
        countPage: countPage + 1,
      },
        () => {
          if (res.page.current_page !== res.page.last_page) {
            this.fetchSpinItem(`?per_page=100&offset=${countPage * 100}`)
          } else {
            let tempData = []
            allSpinItem.map((e) => (
              tempData.push({
                label: e.name_th,
                value: e.id,
              })
            ))

            tempData.sort((a, b) => a.label.localeCompare(b.label))
            tempData.splice(0, 0, { label: "ทั้งหมด", name: "all", value: "all" });

            this.setState({
              spinItemOpt: tempData,
              isLoading: false,
            })
          }
        })
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  handleClickSort = (e) => { };

  renderStatus = (e) => {
    switch (e) {
      case '1':
        return 'คะแนน';

      case '2':
        return 'ของรางวัล';

      default:
        return '-';
    }
  }

  handleClickExchange = async (e) => {
    const { perMissions } = this.state

    if (perMissions) {
      this.setState({
        isBgLoading: true,
      })
      let res = await adminService.PATCH_SPINNING_BY_ID(
        e.id,
        {
          distributed_at: moment().tz("Asia/Bangkok").format("YYYY-MM-DD")
        }
      );

      if (res && res.success) {
        this.setState({
          isShowModal: true,
          isBgLoading: false,
          isLoadingTable: true,
        });
        this.handleJoinQuery()
      } else {
        toast.error("แลกรางวัลไม่สำเร็จ");
        this.setState({
          isBgLoading: false,
        })
      }
    }
  }

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: null,
    });
  };

  handleConfirmDelete = () => {
    this.setState({
      isShowModal: null,
    });
  }

  handleSetIndexTab = (e) => {
    const { tabIndex } = this.state

    if (e !== tabIndex) {
      this.setState({
        isLoadingTable: true,
        tabIndex: e,
      }, () => {
        this.handleJoinQuery()
      })

    }
  }


  handleJoinQuery = () => {
    const { keywordFilter, spinItemFilter, departmentFilter } = this.state;
    let queryString = [];

    this.setState({
      isLoadingTable: true,
    })
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (spinItemFilter) {
      queryString.push(spinItemFilter);
    }
    if (departmentFilter) {
      queryString.push(departmentFilter);
    }

    if (queryString && queryString.length > 0) {
      this.fetchData(`?per_page=10&offset=0&${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
    } else {
      this.fetchData(`?per_page=10&offset=0`);
      this.setState({
        queryFilterAll: false,
      });
    }
  };

  handleSelectedFilter = (e) => {
    this.setState(
      {
        keywordFilter: !e ? false : `keyword=${e}`,
      },
      () => this.handleJoinQuery()
    );
  }

  handleSelectDeparment = (e) => {
    this.setState({
      departmentValue: e,
      departmentFilter: e.value === 'all' ? false : `department_id=${e.value}`,
    },
      () => this.handleJoinQuery()
    )
  }

  handleSelectSpinItem = (e) => {
    this.setState({
      spinItemValue: e,
      spinItemFilter: e.value === 'all' ? false : `spin_id=${e.value}`,
    },
      () => this.handleJoinQuery()
    )
  }

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    this.setState({
      isLoadingTable: true,
    })

    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.fetchData(query);
  };

  render() {
    const {
      isLoading,
      pagination,
      isShowModal,
      tabIndex,
      isLoadingTable,
      isBgLoading,
      departmentOpt,
      spinningCount,
      spinItemOpt,
      data,
      spinItemValue,
      departmentValue,
    } = this.state;

    return (
      <AnnouncementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="box_spin_layout">
            <div className="sum_zone">
              {`จำนวนผู้ได้รับรางวัลทุกกิจกรรม ${RenderCommaMoney(Number(spinningCount).toFixed(0))} คน`}
            </div>
            <div className="tab_select">
              <div className={`body_tab ${tabIndex === 1 ? 'active' : ''}`} onClick={() => this.handleSetIndexTab(1)}>
                คะแนน
              </div>
              <div className={`body_tab ${tabIndex === 2 ? 'active' : ''}`} onClick={() => this.handleSetIndexTab(2)}>
                ของรางวัล
              </div>
            </div>
            <div className="top_filter">
              <div className="w25">
                <Inputs.InputSearch2
                  theme_standard_search
                  placeholder='รหัสพนักงาน,ชื่อ,นามสกุล'
                  onSubmit={this.handleSelectedFilter}
                />
              </div>
              <div className="w25">
                <Inputs.InputDropDownNormal
                  theme_lucky
                  iconSvgClose={<Icons.RectArrowDown color='#002366' />}
                  iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                  placeholder="แผนก"
                  options={departmentOpt}
                  onChange={this.handleSelectDeparment}
                  value={departmentValue}
                // errors={errors.category?.message}
                />
              </div>
              <div className="w25">
                <Inputs.InputDropDownNormal
                  theme_lucky
                  iconSvgClose={<Icons.RectArrowDown color='#002366' />}
                  iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                  placeholder="กิจกรรม"
                  options={spinItemOpt}
                  onChange={this.handleSelectSpinItem}
                  value={spinItemValue}
                />
              </div>
              {/* <div className="w25">
                <Inputs.InputDatePikerCustom
                  theme_luky
                  dateFormat="MMMM yyyy"
                  // onChangeCustom={(e) => this.handleChangDate(e, true)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  initialValues={new Date()}
                  iconColor='#002366'
                />
              </div>
              <div>
                ถึง
              </div>
              <div className="w25">
                <Inputs.InputDatePikerCustom
                  theme_luky
                  dateFormat="MMMM yyyy"
                  // onChangeCustom={(e) => this.handleChangDate(e, false)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  initialValues={new Date()}
                  iconColor='#002366'
                />
              </div> */}
            </div>
            <div className="lbl_count_name">
              {
                (pagination && pagination.total && pagination.total > 0) 
                ?
                 `รายชื่อทั้งหมด :  ${RenderCommaMoney(Number(pagination.total).toFixed(0))} คน`
                 :
                 `รายชื่อทั้งหมด :  0 คน`
              }
            </div>
            <div className="table_box">
              <TableCustom.TableNormal
                theme_announcement
                columns={columns({
                  handleClickExchange: this.handleClickExchange,
                  renderStatus: this.renderStatus,
                  tabIndex: tabIndex,
                })}
                data={data}
                pagination={pagination}
                labelEmpty="ยังไม่มีข้อมูล"
                loading={isLoadingTable}
                skeletonOneBlock
                // onChangeSelected={this.handleChangeSelectedTable}
                useRenderNoPage
                useNo
                showPagination
                handlePageClick={this.handlePageClick}
              />
            </div>
          </div>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <div className="box_exchange">
            <div>
              แลกรับรางวัลสำเร็จ
            </div>
            <Icons.EmotionVerySmile />
            <div className="w100">
              <Buttons.BtnNormal
                theme_only_border_blue
                label='ปิด'
                fontSize="16px"
                onClick={this.handleClickCloseModal}
              // disabled={disabled}
              />
            </div>
          </div>
        </Modals.Standard>
      </AnnouncementContainerStyled>
    );
  }
}

const columns = ({ tabIndex, handleClickExchange }) => {
  let tempMenu = [
    {
      title: "ลำดับ",
      field: "no",
      headerStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "60px",
        flexShrink: 0,
      },
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "60px",
        flexShrink: 0,
      },
    },
    {
      title: (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          รหัสพนักงาน
        </div>
      ),
      field: "user.employee.code",
      headerStyle: { display: 'flex', alignItems: 'center', minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {`${value ? value : '-'}`}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          ชื่อ - นามสกุล
        </div>
      ),
      field: "name",
      headerStyle: { display: 'flex', alignItems: 'center', minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {
            origin.user && origin.user && origin.user.employee
              ?
              `${origin.user.employee.firstname} ${origin.user.employee.lastname}`
              :
              '-'
          }
        </div>
      ),
    },
  ];

  if (tabIndex === 2) {
    tempMenu.push(
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            เบอร์โทรติดต่อ
          </div>
        ),
        field: "mobile_no",
        headerStyle: { display: 'flex', alignItems: 'center', minWidth: "10px" },
        style: { textAlign: "left", minWidth: "10px" },
        render: ({ origin, value }) => (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {`${value ? value : '-'}`}
          </div>
        ),
      },
    )
  }

  tempMenu.push(
    {
      title: (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          แผนก
        </div>
      ),
      field: "department",
      headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "10px" },
      style: { textAlign: "center", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {
            origin.user && origin.user && origin.user.employee
              ?
              `${origin.user.employee.department.name}`
              :
              '-'
          }
        </div>
      ),
    },
  )

  if (tabIndex === 1) {
    tempMenu.push(
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            กิจกรรม
          </div>
        ),
        field: "spin_option.spin.name_th",
        headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "10px" },
        style: { textAlign: "center", minWidth: "10px" },
        render: ({ origin, value }) => (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            คะแนน
          </div>
        ),
        field: "spin_option.spin_coin_amount",
        headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "10px" },
        style: { textAlign: "center", minWidth: "10px", width: '100%' },
        render: ({ origin, value }) => (
          <div
            style={{
              minWidth: "10px",
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                minWidth: "10px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textAlign: 'center',
              }}
            >
              {`${RenderCommaMoney(Number(value).toFixed(0))} คะแนน`}
            </div>
          </div>
        ),
      },
    )
  }

  if (tabIndex === 2) {
    tempMenu.push(
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            ของรางวัล
          </div>
        ),
        field: "spin_option.item.name_th",
        headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "10px" },
        style: { textAlign: "center", minWidth: "10px" },
        render: ({ origin, value }) => (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            รหัสรางวัล
          </div>
        ),
        field: "code",
        headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: "10px" },
        style: { textAlign: "center", minWidth: "10px", width: '100%' },
        render: ({ origin, value }) => (
          <div
            style={{
              minWidth: "10px",
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                minWidth: "10px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textAlign: 'center',
              }}
            >
              {value ? value : '-'}
            </div>
          </div>
        ),
      },
    )
  }

  tempMenu.push(
    {
      title: (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          วันที่ได้รับ
        </div>
      ),
      field: "created_at",
      headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '10px' },
      style: { textAlign: "left", justifyContent: 'center', minWidth: '10px' },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: 'center'
          }}
        >
          {moment(value).tz("Asia/Bangkok").format("DD/MM/YY")}
        </div>
      ),
    }
  )

  if (tabIndex === 2) {
    tempMenu.push(
      {
        title: (
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            สถานะ
          </div>
        ),
        field: "distributed_at",
        headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: "117px", flexShrink: 0 },
        style: { textAlign: "left", justifyContent: 'center', width: "117px", flexShrink: 0 },
        render: ({ origin, value }) => (
          value
            ?
            <Icons.ExchangeLucky />
            :
            <Buttons.BtnNormal
              theme_only_border
              label="แลกรางวัล"
              borderRadius="8px"
              backgroundColor="#fff"
              fontColor={theme.COLORS.PURPLE_1}
              fontSize='14px'
              borderColor="#92CBDB"
              padding='4.4px'
              disabled={!origin.code && true}
              fontFamilyTop={theme.FONT.STYLE.MEDIUM}
              onClick={() => handleClickExchange(origin)}
            />
        ),
      }
    )
  }

  return tempMenu
}


const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementContainer);
import React from 'react';
import { DownloadDeleteAccountFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const DownloadDeleteAccountForm = ({ onSubmit, handleCancel }) => {
  const schema = yup.object().shape({
    // test: yup.string().required('New password is required'),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <DownloadDeleteAccountFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="delete_box">
          <div className="title_box">
            <div>ยืนยันการลบบัญชี</div>
            <div className="sub_title">โปรดระบุบสาเหตุในการลบบัญชีของคุณ</div>
          </div>
          <div className="opt_layout">
            <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputRadioCheckbox
                    theme_standard_input_check_box_square_account
                    {...field}
                    options={[
                      { label: 'ฉันไม่ได้รับผลประโยชน์จากแอปนี้', value: '1' },
                      { label: 'แอปมีการแจ้งเตือนมากเกินไป', value: '2' },
                      { label: 'ฉันไม่ค่อยได้ใช้แอปนี้', value: '3' },
                      { label: 'ฉันมีใช้แอปที่ใกล้เอียงกับแอปนี้', value: '4' },
                      { label: 'อื่นๆ', value: '5' },
                    ]}
                  />
                )}
                name="opt_1"
                defaultValue={false}
              />
            </div>
            {/* <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    theme_standard_input_check_box_square_account
                    {...field}
                    label="ฉันไม่ได้รับผลประโยชน์จากแอปนี้"
                  />
                )}
                name="opt_1"
                defaultValue={false}
              />
            </div>
            <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    theme_standard_input_check_box_square_account
                    {...field}
                    label="แอปมีการแจ้งเตือนมากเกินไป"
                  />
                )}
                name="opt_2"
                defaultValue={false}
              />
            </div>
            <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    theme_standard_input_check_box_square_account
                    {...field}
                    label="ฉันไม่ค่อยได้ใช้แอปนี้"
                  />
                )}
                name="opt_3"
                defaultValue={false}
              />
            </div>
            <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    theme_standard_input_check_box_square_account
                    {...field}
                    label="ฉันมีใช้แอปที่ใกล้เอียงกับแอปนี้"
                  />
                )}
                name="opt_4"
                defaultValue={false}
              />
            </div>
            <div className="line_opt">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputCheckBox
                    theme_standard_input_check_box_square_account
                    {...field}
                    label="อื่นๆ"
                  />
                )}
                name="opt_5"
                defaultValue={false}
              />
            </div> */}
          </div>
          <div className="action_btn">
            <div className="body_btn margin_right">
              <Buttons.BtnNormal
                theme_standard_btn_pop_up_account
                label="ยกเลิก"
                fontSize="16px"
                backgroundColor="#FFFFFF"
                fontColor="#575D96"
                borderColor="#575D96"
                onClick={handleCancel}
              />
            </div>
            <div className="body_btn">
              <Buttons.BtnNormal
                theme_standard_btn_pop_up_account
                label="ยืนยัน"
                fontSize="16px"
                backgroundColor="#575D96"
                // onClick={handleConfirm}
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </DownloadDeleteAccountFormStyled>
  );
};

DownloadDeleteAccountForm.propTypes = {};

export default DownloadDeleteAccountForm;

import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { InputTextAreaStyled } from "./styled";

const InputTextArea = ({
  theme_standard_text_area,
  theme_standard_text_area_reward,
  theme_text_area_lucky,
  theme_normal,
  labelTitle,
  labelSub,
  maxLength,
  flexStyle,
  height,
  fontSize,
  fontFamily,
  fontColor,
  disabled,
  placeholder,
  svg,
  onChange,
  value,
  padding,
  overflow,
  errors,
}) => {
  const customClass = cx({
    theme_standard_text_area: theme_standard_text_area,
    theme_normal: theme_normal,
    theme_standard_text_area_reward: theme_standard_text_area_reward,
    theme_text_area_lucky: theme_text_area_lucky,
  });

  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(value ? value.length : 0);
  }, [value]);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <InputTextAreaStyled
      height={height}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      flexStyle={flexStyle}
      disabled={disabled}
      svg={svg}
      padding={padding}
      overflow={overflow}
    >
      <div className={customClass}>
        <div className="title_layout">
          {labelTitle && (
            <div className="show_title">
              <div className="title_display">{labelTitle}</div>
              <div className="sub_display">{labelSub}</div>
            </div>
          )}
          {maxLength && (
            <div className="num_show">
              {`${count}/${maxLength ? maxLength : 100}`}
            </div>
          )}
        </div>
        {svg && count < 1 && <div className="icon">{svg}</div>}
        <textarea
          className="text_area"
          maxLength={maxLength ? maxLength : 100}
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
          value={value}
          disabled={disabled}
        />
        {errors && <div className="error">{`* ${errors}`}</div>}
      </div>
    </InputTextAreaStyled>
  );
};

InputTextArea.propTypes = {};

export default InputTextArea;

export default function RewardManage() {
  return (
    <svg
      id="_012-medal"
      data-name="012-medal"
      xmlns="http://www.w3.org/2000/svg"
      width="83.696"
      height="115.746"
      viewBox="0 0 83.696 115.746"
    >
      <g id="Group_242" data-name="Group 242" transform="translate(0 0)">
        <path
          id="Path_964"
          data-name="Path 964"
          d="M194.512,344.836l-5.984,2.587-5.6-1.518h-9.18l-5.937,1.487-5.648-2.556-4.4-6.664-2.042,1.931-.066,36.188a1.172,1.172,0,0,0,1.6,1.093l20.448-8.043a1.172,1.172,0,0,1,.858,0l20.455,8.046a1.172,1.172,0,0,0,1.6-1.089l.066-36.084-2.027-2.017Z"
          transform="translate(-136.49 -261.723)"
          fill="#c61926"
        />
        <path
          id="Path_965"
          data-name="Path 965"
          d="M209.436,390.741l9.891-3.89a1.171,1.171,0,0,1,.858,0l9.968,3.921v-23.43l-2.035-2.449-8.156-4.1-8.492,4.042-2.035,2.495Z"
          transform="translate(-178.115 -279.233)"
          fill="#ff4755"
        />
        <path
          id="Path_966"
          data-name="Path 966"
          d="M155.952,329.172l-.007,3.965a8.381,8.381,0,0,1,4.738,4.588,8.386,8.386,0,0,0,12.807,3.432,8.386,8.386,0,0,1,10.148,0,8.386,8.386,0,0,0,12.807-3.432,8.38,8.38,0,0,1,4.464-4.478l.007-4.052Z"
          transform="translate(-136.716 -254.757)"
          fill="#b2182b"
        />
        <path
          id="Path_967"
          data-name="Path 967"
          d="M209.436,348.873v4.53a8.316,8.316,0,0,0,5.452-1.7,8.386,8.386,0,0,1,10.148,0,8.311,8.311,0,0,0,5.117,1.709v-4.483l-10.191-6.13Z"
          transform="translate(-178.115 -265.302)"
          fill="#fc2b3a"
        />
        <path
          id="Path_968"
          data-name="Path 968"
          d="M117.325,1.549a7.586,7.586,0,0,1,11.585,3.1,7.586,7.586,0,0,0,7.95,4.59,7.586,7.586,0,0,1,8.481,8.481,7.586,7.586,0,0,0,4.59,7.95,7.586,7.586,0,0,1,3.1,11.585,7.585,7.585,0,0,0,0,9.18,7.586,7.586,0,0,1-3.1,11.585,7.586,7.586,0,0,0-4.648,7.194q.01.376.058.756c.624,4.919-3.561,7.07-8.481,6.446a7.586,7.586,0,0,0-7.95,4.59,7.586,7.586,0,0,1-11.585,3.1,7.585,7.585,0,0,0-9.18,0,7.586,7.586,0,0,1-11.585-3.1,7.586,7.586,0,0,0-7.95-4.59c-4.919.624-9.105-1.527-8.481-6.446q.048-.38.058-.756a7.586,7.586,0,0,0-4.648-7.194,7.586,7.586,0,0,1-3.1-11.585,7.586,7.586,0,0,0,0-9.18,7.586,7.586,0,0,1,3.1-11.585,7.586,7.586,0,0,0,4.59-7.95,7.586,7.586,0,0,1,8.481-8.481,7.586,7.586,0,0,0,7.95-4.59,7.586,7.586,0,0,1,11.585-3.1,7.585,7.585,0,0,0,9.179,0Z"
          transform="translate(-70.887 0)"
          fill="#ffcb5b"
        />
        <g
          id="Group_238"
          data-name="Group 238"
          transform="translate(9.181 65.217)"
        >
          <path
            id="Path_969"
            data-name="Path 969"
            d="M176.714,288.486a7.674,7.674,0,0,1-8.15,4.912,7.672,7.672,0,0,0-8.041,4.642,7.672,7.672,0,0,1-11.717,3.139,7.672,7.672,0,0,0-9.284,0,7.672,7.672,0,0,1-11.717-3.139,7.672,7.672,0,0,0-8.041-4.642,7.673,7.673,0,0,1-8.15-4.912c-.007.251-.024.5-.057.756a7.586,7.586,0,0,0,8.481,8.481,7.586,7.586,0,0,1,7.95,4.59,7.586,7.586,0,0,0,11.585,3.1,7.585,7.585,0,0,1,9.18,0,7.586,7.586,0,0,0,11.585-3.1,7.587,7.587,0,0,1,7.95-4.59,7.586,7.586,0,0,0,8.481-8.481A7.5,7.5,0,0,1,176.714,288.486Z"
            transform="translate(-111.498 -288.486)"
            fill="#f7b737"
          />
        </g>
        <g
          id="Group_240"
          data-name="Group 240"
          transform="translate(12.614 12.543)"
        >
          <g id="Group_239" data-name="Group 239">
            <path
              id="Path_970"
              data-name="Path 970"
              d="M155.919,111.138c-29.23,0-29.28-27.76-29.23-28.457a29.306,29.306,0,0,1,58.46,0C185.2,83.378,185.224,111.138,155.919,111.138Z"
              transform="translate(-126.685 -55.486)"
              fill="#ff9838"
            />
          </g>
        </g>
        <path
          id="Path_971"
          data-name="Path 971"
          d="M155.675,202.976a29.306,29.306,0,0,1-29.229-27.2c-.049.7-.076,1.4-.076,2.11a29.305,29.305,0,1,0,58.61,0q0-1.064-.076-2.11A29.306,29.306,0,0,1,155.675,202.976Z"
          transform="translate(-113.827 -136.043)"
          fill="#ff8709"
        />
        <path
          id="Path_972"
          data-name="Path 972"
          d="M205.625,116.8l2.585,5.237a2.83,2.83,0,0,0,2.13,1.548l5.779.84a2.829,2.829,0,0,1,1.568,4.825l-4.182,4.076a2.829,2.829,0,0,0-.814,2.5l.987,5.756a5.231,5.231,0,0,1,.023.836l-4.127.111-6.486-3.41-6.485,3.41-4.115-.023a5.157,5.157,0,0,1,.01-.925l.987-5.756a2.829,2.829,0,0,0-.814-2.5l-4.182-4.076a2.829,2.829,0,0,1,1.568-4.825l5.779-.84a2.829,2.829,0,0,0,2.13-1.548l2.585-5.237a2.829,2.829,0,0,1,5.073,0Z"
          transform="translate(-161.24 -89.174)"
          fill="#fff5f5"
        />
        <g
          id="Group_241"
          data-name="Group 241"
          transform="translate(31.249 47.915)"
        >
          <path
            id="Path_973"
            data-name="Path 973"
            d="M219.17,212.086l-10.053,5.285a2.833,2.833,0,0,0,4.113,2.058l5.169-2.718a2.829,2.829,0,0,1,2.633,0l5.169,2.718a2.833,2.833,0,0,0,4.113-2.058l-10.053-5.285A1.171,1.171,0,0,0,219.17,212.086Z"
            transform="translate(-209.117 -211.951)"
            fill="#efe2dd"
          />
        </g>
      </g>
    </svg>
  );
}

export default function UserLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="120.235"
      viewBox="0 0 144 120.235"
    >
      <g id="group" transform="translate(0 -38.071)">
        <g
          id="Group_80380"
          data-name="Group 80380"
          transform="translate(0 38.071)"
        >
          <g
            id="Group_80374"
            data-name="Group 80374"
            transform="translate(27.374 6.398)"
          >
            <g id="XMLID_29_">
              <g id="Group_80373" data-name="Group 80373">
                <path
                  id="Path_100420"
                  data-name="Path 100420"
                  d="M177.025,279.075v.038a66.511,66.511,0,0,1-89.32,0v-.038a44.658,44.658,0,0,1,25.487-40.343c5.663,5.649,12.295,8.692,19.175,8.692s13.511-3.044,19.177-8.692A44.653,44.653,0,0,1,177.025,279.075Z"
                  transform="translate(-87.705 -182.501)"
                  fill="#3776aa"
                />
                <path
                  id="Path_100421"
                  data-name="Path 100421"
                  d="M176.352,58.571a24.7,24.7,0,0,1,24.7,24.7c0,13.645-11.061,32.305-24.7,32.305s-24.7-18.66-24.7-32.305A24.7,24.7,0,0,1,176.352,58.571Z"
                  transform="translate(-131.691 -58.571)"
                  fill="#fcd09f"
                />
              </g>
            </g>
          </g>
          <g id="Group_80379" data-name="Group 80379">
            <g id="Group_80376" data-name="Group 80376">
              <g id="XMLID_27_">
                <g id="Group_80375" data-name="Group 80375">
                  <path
                    id="Path_100422"
                    data-name="Path 100422"
                    d="M51.216,200.777q1.136,1.618,2.351,3.041L51.1,204.99a48.757,48.757,0,0,0-27.478,38.22A57.8,57.8,0,0,1,0,230.243v-.032a38.92,38.92,0,0,1,22.209-35.156c4.935,4.924,10.713,7.576,16.707,7.576a21.654,21.654,0,0,0,11.327-3.307C50.556,199.815,50.883,200.3,51.216,200.777Z"
                    transform="translate(0 -146.058)"
                    fill="#3776aa"
                  />
                  <path
                    id="Path_100423"
                    data-name="Path 100423"
                    d="M81.568,69.169a40.283,40.283,0,0,0,3.707,16.075,14.967,14.967,0,0,1-8.032,2.5c-11.888,0-21.525-16.259-21.525-28.151A21.526,21.526,0,0,1,93.688,45.705,28.789,28.789,0,0,0,81.568,69.169Z"
                    transform="translate(-38.328 -38.071)"
                    fill="#efc59c"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_80378"
              data-name="Group 80378"
              transform="translate(88.641)"
            >
              <g id="XMLID_22_">
                <g id="Group_80377" data-name="Group 80377">
                  <path
                    id="Path_100424"
                    data-name="Path 100424"
                    d="M292.092,200.777q-1.136,1.618-2.351,3.041l2.465,1.172a48.757,48.757,0,0,1,27.478,38.22,57.8,57.8,0,0,0,23.624-12.967v-.032A38.92,38.92,0,0,0,321.1,195.055c-4.935,4.924-10.713,7.576-16.707,7.576a21.654,21.654,0,0,1-11.327-3.307C292.753,199.815,292.425,200.3,292.092,200.777Z"
                    transform="translate(-287.949 -146.058)"
                    fill="#3776aa"
                  />
                  <path
                    id="Path_100425"
                    data-name="Path 100425"
                    d="M296.118,69.169a40.284,40.284,0,0,1-3.707,16.075,14.967,14.967,0,0,0,8.032,2.5c11.888,0,21.525-16.259,21.525-28.151A21.525,21.525,0,0,0,284,45.705,28.789,28.789,0,0,1,296.118,69.169Z"
                    transform="translate(-283.999 -38.071)"
                    fill="#efc59c"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import corporateRoutes from "routes/corporateRoutes";
import DDCRoutes from "routes/DDCRoutes";
import invitraceRoutes from "routes/invitraceRoutes";
import { ROUTE_PATH } from "utils/constants/index";

export const renderConfigSystemFN = (
  authenRedux,
  pathURL,
  companySelectedRedux
) => {
  switch (authenRedux.role) {
    case "company_admin":
    case "company_agent":
      return {
        headerLogo: "/assets/images/logo/logo_ttib.svg",
        routes:
          authenRedux.role === "company_agent"
            ? corporateRoutes(authenRedux).filter((e) => e.key !== "backoffice")
            : companySelectedRedux?.isParent
            ? corporateRoutes(authenRedux).filter((e) => e.key === "backoffice")
            : authenRedux?.admin_role?.name !== "Management"
            ? corporateRoutes(authenRedux)
            : corporateRoutes(authenRedux).filter(
                (e) => e.key !== "backoffice"
              ),
        isShowFilterRightNavbar: authenRedux.company.is_parent === 1,
        enableAccountManagementOnBackoffice:
          authenRedux.role === "company_admin",
      };
    case "invitrace_admin":
      return {
        headerLogo: "/assets/images/logo/logo_ttib.svg",
        headerLabel: "กรมควบคุมโรค",
        routes: invitraceRoutes,
        isShowFilterRightNavbar: false,
        enableAccountManagementOnBackoffice: true,
      };
    case "ddc_admin":
    case "ddc_agent":
      // var re = new RegExp(/backoffice/g);
      let isBackOfficer = pathURL ? pathURL.search("backoffice") !== -1 : false;
      return {
        headerLogo: "/assets/images/logo/logo_ttib.svg",
        routes:
          authenRedux.role === "ddc_agent"
            ? DDCRoutes.filter((e) => e.key !== "backoffice")
            : DDCRoutes,
        isShowFilterRightNavbar:
          pathURL === ROUTE_PATH.MAIN ||
          pathURL === ROUTE_PATH.ADMINISTARTOR ||
          isBackOfficer
            ? false
            : true,
        enableAccountManagementOnBackoffice: true,
      };
    default:
      break;
  }
};

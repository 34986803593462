import { MissionDetailStyled } from './styled';
import { Blocks, Label, BarPercent } from 'components';
import moment from 'moment';
import 'moment-timezone';
import { RENDER_MONTH } from 'utils/functions/date';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import React, { useState, useEffect } from 'react';

const MissionDetail = ({ dataMission, heightBox }) => {
  const [_labelShow, setLabelShow] = useState({ labelTh: '', labelEn: '' });

  useEffect(() => {
    if (dataMission) {
      switch (dataMission.category) {
        case 'step':
          setLabelShow({
            labelTh: 'ก้าวสะสม',
            labelEn: 'Current Step',
            target_amount: dataMission.target_amount,
            unit: 'ก้าว',
            // icon: <Icons.Run />,
            // icon: "/assets/images/logo/run.png",
            icon: "/assets/images/logo/nin_step.svg",
            theme: 'run'
          });
          break;

        case 'mission_eye':
          setLabelShow({
            labelTh: 'ผ่อนคลายสายตาสะสม',
            labelEn: 'Rest your eye cumulative amount',
            target_amount: dataMission.target_amount,
            unit: 'ครั้ง',
            // icon: <Icons.Kcal width='80%' height='80%' />,
            // icon: "/assets/images/logo/rest.png",
            icon: "/assets/images/logo/nin_eyes.svg",
            theme: 'rest'
          });

          break;
        case 'mission_move':
          setLabelShow({
            labelTh: 'ขยับร่างกายสะสม',
            labelEn: 'Body cumulative amount',
            target_amount: dataMission.target_amount,
            unit: 'ครั้ง',
            // icon: <Icons.Kcal width='80%' height='80%' />,
            // icon: "/assets/images/logo/move.png",
            icon: "/assets/images/logo/nin_move.svg",
            theme: 'move'
          });

          break;

        default:
          break;
      }
    }
  }, [dataMission]);
  return (
    <MissionDetailStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="32px"
        paddingBottom="24px"
        paddingLeft="16px"
        height={heightBox ? heightBox : 'auto'}
      >
        <div className="container">
          <div className="top_main">
            <div className="top_main_left">
              <Label.TopBottom
                theme_left_align
                label="รายละเอียดภารกิจ"
                labelBottom="Mission details"
                fontSizeLabelButtom="14px"
              />
            </div>
            <div className="top_main_right">
              <Label.TopBottom
                theme_left_align
                label="อัพเดตล่าสุด"
                labelBottom="Lasted Update"
                fontSizeLabelButtom="12px"
                fontSize="12px"
              />
              <div className="date_layout">
                <div className="date_position">
                  <Label.ShowDate
                    theme_standard_show_date
                    date={
                      dataMission &&
                      moment(dataMission.updated_at)
                        .tz('Asia/Bangkok')
                        .format('D')
                    }
                    month1={
                      dataMission &&
                      RENDER_MONTH(
                        moment(dataMission.updated_at)
                          .tz('Asia/Bangkok')
                          .format('MM')
                      ).th
                    }
                    month2={
                      dataMission &&
                      RENDER_MONTH(
                        moment(dataMission.updated_at)
                          .tz('Asia/Bangkok')
                          .format('MM')
                      ).en
                    }
                    year={
                      dataMission &&
                      moment(dataMission.updated_at).tz('Asia/Bangkok').year() +
                      543
                    }
                    time={`${dataMission &&
                      moment(dataMission.updated_at)
                        .tz('Asia/Bangkok')
                        .format('HH:mm')
                      } น.`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_main">
            <div className={`bottom_main_left ${_labelShow.theme}`}>
              {/* {dataMission && _labelShow.icon} */}
              <img className={`img_icon`} src={_labelShow.icon} alt="img" />
            </div>
            <div className="bottom_main_right">
              <div className="group_label">
                <Label.TopBottom
                  theme_left_align
                  label={_labelShow.labelTh}
                  labelBottom={_labelShow.labelEn}
                  fontSizeLabelButtom="14px"
                />
                <div>
                  {/* <span className="extra_text">
                    {dataMission &&
                      `${dataMission.achieved_amount &&
                      RenderCommaMoney(dataMission.achieved_amount.toFixed(0))
                      } `}
                  </span>
                  {dataMission && _labelShow.unit} */}
                  <span className="extra_text">
                    {dataMission &&
                      `${dataMission.achieved_amount &&
                      RenderCommaMoney(dataMission.achieved_amount.toFixed(0))
                      } `}
                  </span>
                  {dataMission && _labelShow.unit}
                </div>
              </div>
              <div className="group_label">
                <Label.TopBottom
                  theme_left_align
                  label="เป้าหมาย"
                  labelBottom="Target"
                  fontSizeLabelButtom="14px"
                />
                <div>
                  <span className="extra_text">
                    {dataMission &&
                      `${dataMission.target_amount &&
                      RenderCommaMoney(dataMission.target_amount.toFixed(0))
                      } `}
                  </span>
                  {dataMission && _labelShow.unit}
                </div>
              </div>
              <BarPercent
                theme_normal_bar_percent
                percent={
                  dataMission &&
                    dataMission.achieved_amount >= dataMission.target_amount
                    ? 100
                    : (dataMission.achieved_amount * 100) /
                    dataMission.target_amount
                }
                showPercent
              />
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MissionDetailStyled>
  );
};

export default MissionDetail;

import styled from 'styled-components';

export const HealthyClubContainerStyled = styled.div`
  // min-width: 1024px;
  // overflow: auto;
  padding-left: 5px;
  margin-left: -5px;
  margin-right: -20px;
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manage_container {
    margin-right: 20px;
    .head_wrap_layout {
      margin-bottom: 45px;
    }
    .btn_action_zone {
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;
      .btn_225 {
        width: 225px;
        .g_label {
          .sm_text {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            color: ${({ theme }) => theme.COLORS.GRAY_24};
          }
        }
      }
      .btn_88 {
        width: 88px;
      }
    }
    .block_2_col_layout {
      
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.IPAD}) {
    .show_loading {
      width: calc(100vw - 172px);
    }
    
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .show_loading {
      
    }

  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
      
  }
`;

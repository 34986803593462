import React from 'react';
import { AddEditMissionContainerStyled } from './styled';
import { Forms, HeadTab, Loading, Modal, BoxDelete } from 'components';
import { ROUTE_PATH } from 'utils/constants';
import { adminService } from 'apiServices';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import queryString from 'query-string';

class AddEditMissionContainer extends React.Component {
  state = {
    isLoading: true,
    startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
    isShowModal: false,
    messageShow:
      this.props.match.params.id !== 'create'
        ? {
          label1: 'แก้ไขภารกิจ',
          label2: 'Edit Mission',
          label3: 'แก้ไขสำเร็จ',
          label4: 'แก้ไขไม่สำเร็จ',
        }
        : {
          label1: 'เพิ่มภารกิจ',
          label2: 'Add Mission',
          label3: 'สร้างสำเร็จ',
          label4: 'สร้างไม่สำเร็จ',
        },
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.match.params.id !== 'create') {
      this.fetchDataEdit();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  };

  fetchDataEdit = async () => {
    let res = await adminService.GET_COMPANY_MISSION_BY_ID(
      this.props.match.params.id
    );
    if (res && res.success) {
      this.setState({
        data: res.data,
        updateDate: res.data.updated_at && res.data.updated_at,
        initialValues: res.data,
        disableMissionInformation: res.data.is_active === 1 ? true : false,
        isLoading: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    const { from } = queryString.parse(this.props.location.search);
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_MISSION}?page=${from}`);
  };

  onSubmit = async (values) => {
    const { companySelectedRedux } = this.props;
    const { messageShow } = this.state;
    this.setState({
      isLoading: true,
    });
    let params = {
      ...values,
      category: values.category?.value,
      begin_date: moment(values.begin_date).format('YYYY-MM-DD'),
      end_date: moment(values.end_date).format('YYYY-MM-DD'),
      target_type: values.target_type?.value,
    };

    if (
      values.success_avatar_item_id &&
      values.success_avatar_item_id.value !== 'empty'
    ) {
      params.success_avatar_item_id = values.success_avatar_item_id?.value;
    } else {
      delete params.success_avatar_item_id;
    }
    if (
      values.fail_avatar_item_id &&
      values.fail_avatar_item_id.value !== 'empty'
    ) {
      params.fail_avatar_item_id = values.fail_avatar_item_id?.value;
    } else {
      delete params.fail_avatar_item_id;
    }
    let res =
      this.props.match.params.id === 'create'
        ? await adminService.POST_COMPANY_MISSION(
          companySelectedRedux.id,
          params
        )
        : await adminService.PATCH_COMPANY_MISSION(
          this.props.match.params.id,
          params
        );

    if (res && res.success) {
      const filesTemp = [
        ...params.files.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(res.data.id, filesTemp);
      } else {
        this.setState({
          isLoading: false,
        });
        toast.success(messageShow.label3);
        this.handleBackClick();
      }
    } else {
      this.setState({
        isLoading: false,
      });
      toast.error(messageShow.label4);
    }
  };

  handleUploadContentMedia = (missionID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('type', 'cover');
            bodyFormData.append('files[]', e);
            let res = await adminService.POST_UPLOAD_MISSION_IMAGE(
              missionID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
          if (e.isDelete) {
            const bodyFormData = new FormData();
            let res = await adminService.POST_UPLOAD_MISSION_IMAGE(
              missionID,
              bodyFormData
            );
            if (res && res.success) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isLoading: false,
      });
      toast.success('สร้างสำเร็จ');
      this.handleBackClick();
    });
  };

  onDelete = async () => {
    let res = await adminService.DELETE_COMPANY_MISSION(
      this.props.match.params.id
    );
    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.handleBackClick();
    } else {
      toast.error('ลบไม่สำเร็จ');
    }
  };

  handleDelete = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleCheckData = (values) => { };

  render() {
    const { companySelectedRedux } = this.props;
    const {
      isLoading,
      initialValues,
      disableMissionInformation,
      updateDate,
      isShowModal,
      messageShow,
    } = this.state;
    return (
      <AddEditMissionContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="add_mission_container">
            <div className="layer_one">
              <HeadTab
                theme_standard_head_tab
                title={messageShow.label1}
                subTitle={messageShow.label2}
                typeDate
                dataDate={updateDate}
                onClick={this.handleBackClick}
              />
            </div>
            <div className="layer_two">
              <Forms.AddMissionForm
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                companyID={companySelectedRedux?.id}
                disableMissionInformation={disableMissionInformation}
                handleDelete={this.handleDelete}
                handleCheckData={this.handleCheckData}
              />
            </div>
            <Modal
              theme_modal_standard
              isShow={isShowModal}
              handleClickCloseModal={this.handleClickCloseModal}
            >
              <BoxDelete
                theme_box_delete
                handleCancel={this.handleClickCloseModal}
                handleConfirm={this.onDelete}
              />
            </Modal>
          </div>
        )}
      </AddEditMissionContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditMissionContainer);

export default function Food() {
  return (
    <svg id="healthy-food" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <circle id="Ellipse_1758" data-name="Ellipse 1758" cx="20" cy="20" r="20" fill="#e19974" />
      <circle id="Ellipse_1759" data-name="Ellipse 1759" cx="15.205" cy="15.205" r="15.205" transform="translate(4.795 4.795)" fill="#dc8758" />
      <circle id="Ellipse_1760" data-name="Ellipse 1760" cx="11.976" cy="11.976" r="11.976" transform="translate(6.036 4.684)" fill="#f3f0f3" />
      <path id="Path_19579" data-name="Path 19579" d="M178.125,49.964a12.1,12.1,0,0,0-1.449.087,11.976,11.976,0,0,1,0,23.777,11.976,11.976,0,1,0,1.449-23.864Z" transform="translate(-163.945 -45.28)" fill="#e2dee2" />
      <circle id="Ellipse_1761" data-name="Ellipse 1761" cx="5.408" cy="5.408" r="5.408" transform="translate(9.892 8.831)" fill="#ffd15b" />
      <path id="Path_19580" data-name="Path 19580" d="M178.125,120.016a5.41,5.41,0,0,0-1.449.2,5.408,5.408,0,0,1,0,10.421,5.408,5.408,0,1,0,1.449-10.619Z" transform="translate(-163.333 -111.185)" fill="#ffc344" />
      <path id="Path_19581" data-name="Path 19581" d="M134.563,389.763a1.545,1.545,0,0,1-.616-2.1l1.169-2.14a1.545,1.545,0,0,1,2.713,1.481l-1.169,2.14A1.545,1.545,0,0,1,134.563,389.763Z" transform="translate(-123.512 -356.654)" fill="#dbf1d0" />
      <path id="Path_19582" data-name="Path 19582" d="M132.953,339.177l-2.437-1.331a2.958,2.958,0,0,1-1.178-4.013l.59-1.081a2.958,2.958,0,0,1,4.013-1.178l2.437,1.331a2.958,2.958,0,0,1,1.178,4.013l-.59,1.081A2.958,2.958,0,0,1,132.953,339.177Z" transform="translate(-119.361 -306.422)" fill="#97da7b" />
      <path id="Path_19583" data-name="Path 19583" d="M150.769,336.14l-2.437-1.331-.054-.028a2.957,2.957,0,0,1,1.126,3.983l-.59,1.081a2.957,2.957,0,0,1-3.96,1.206l.052.03,2.437,1.331a2.958,2.958,0,0,0,4.013-1.178l.59-1.081A2.958,2.958,0,0,0,150.769,336.14Z" transform="translate(-133.954 -309.672)" fill="#80d261" />
      <path id="Path_19584" data-name="Path 19584" d="M297.664,369.815a1.545,1.545,0,0,1-2.124-.514l-1.27-2.082a1.545,1.545,0,1,1,2.638-1.61l1.27,2.082A1.545,1.545,0,0,1,297.664,369.815Z" transform="translate(-271.531 -337.052)" fill="#dbf1d0" />
      <path id="Path_19585" data-name="Path 19585" d="M255.246,318.136l-2.371,1.446a2.958,2.958,0,0,1-4.065-.984l-.641-1.051a2.958,2.958,0,0,1,.984-4.065l2.371-1.446a2.958,2.958,0,0,1,4.065.984l.641,1.051A2.958,2.958,0,0,1,255.246,318.136Z" transform="translate(-229.265 -288.293)" fill="#97da7b" />
      <path id="Path_19586" data-name="Path 19586" d="M264.737,312.036l-2.371,1.446-.051.033a2.957,2.957,0,0,1,4.013,1.014l.641,1.051a2.957,2.957,0,0,1-.934,4.033l.052-.03,2.371-1.446a2.957,2.957,0,0,0,.984-4.065l-.641-1.051A2.957,2.957,0,0,0,264.737,312.036Z" transform="translate(-242.589 -288.234)" fill="#80d261" />
      <path id="Path_19587" data-name="Path 19587" d="M385.349,271.477a1.545,1.545,0,0,1-2.121.526l-2.089-1.259a1.545,1.545,0,1,1,1.6-2.647l2.089,1.259A1.545,1.545,0,0,1,385.349,271.477Z" transform="translate(-352.73 -247.365)" fill="#dbf1d0" />
      <path id="Path_19588" data-name="Path 19588" d="M335.072,226.811l-1.433,2.379a2.958,2.958,0,0,1-4.06,1.007l-1.055-.636a2.958,2.958,0,0,1-1.007-4.06l1.433-2.379a2.958,2.958,0,0,1,4.06-1.007l1.055.636A2.958,2.958,0,0,1,335.072,226.811Z" transform="translate(-302.623 -205.163)" fill="#97da7b" />
      <path id="Path_19589" data-name="Path 19589" d="M332.769,223.122l-1.433,2.379-.03.052a2.957,2.957,0,0,1,4.027-.956l1.055.636a2.957,2.957,0,0,1,1.037,4.007l.032-.051,1.433-2.379a2.958,2.958,0,0,0-1.007-4.06l-1.055-.636a2.957,2.957,0,0,0-4.06,1.007Z" transform="translate(-306.459 -205.017)" fill="#80d261" />
      <path id="Path_19590" data-name="Path 19590" d="M342.846,88.681a4.545,4.545,0,0,1,9.054.813c0,.015,0,.029,0,.043a2.155,2.155,0,0,0,.64,1.741,2.871,2.871,0,1,1-4.743,2.959.942.942,0,0,0-.819-.622h-.007A4.545,4.545,0,0,1,342.846,88.681Z" transform="translate(-318.688 -78.36)" fill="#fff0ad" />
      <path id="Path_19591" data-name="Path 19591" d="M382.444,91.279a2.155,2.155,0,0,1-.64-1.741l0-.043a4.544,4.544,0,0,0-5.978-4.713,4.572,4.572,0,0,1,3.081,4.713l0,.043a2.155,2.155,0,0,0,.64,1.741,2.865,2.865,0,0,1-.579,4.511,2.87,2.87,0,0,0,3.476-4.511Z" transform="translate(-348.596 -78.361)" fill="#ffe177" />
    </svg>

  );
}

export default function Male3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.848" height="36.369" viewBox="0 0 14.848 36.369">
      <g id="Group_15056" data-name="Group 15056" transform="translate(-119.181 -155.643)">
        <path id="Path_681" data-name="Path 681" d="M139.057,171.621a28.025,28.025,0,0,0-.834-3.609c-.839-1.963-2.187-1.494-2.187-1.494l.34.924a28.335,28.335,0,0,0,1.323,4.706c.335.4.038,1.368.355,1.54a5.331,5.331,0,0,0,3.05-.254c-.034-.15.516-1.168.234-1.51A10.3,10.3,0,0,0,139.057,171.621Z" transform="translate(-10.115 -6.497)" fill="#4f2525" />
        <path id="Path_682" data-name="Path 682" d="M140.455,230.986c-.521-.508-.911-.932-.911-.932l-2.372-.1v.784a.984.984,0,0,0,.441.557,10.1,10.1,0,0,0,1.485.774,8.942,8.942,0,0,0,1.984.429c.5.019.713-.215.452-.47C141.086,231.592,140.937,231.457,140.455,230.986Z" transform="translate(-10.797 -44.596)" fill="#5e2e2e" />
        <path id="Path_683" data-name="Path 683" d="M126.252,236.562l-2.139-.241a5.57,5.57,0,0,0,.2,2.762c.5,1.4,1.379,1.416,1.687,1.3s.451-1,.4-2.012S126.252,236.562,126.252,236.562Z" transform="translate(-2.907 -48.417)" fill="#5e2e2e" />
        <path id="Path_684" data-name="Path 684" d="M129.145,187.657s-4.5-1.357-4.371-.387a5.735,5.735,0,0,0-1.4,4.389c.085,1.322-.028,4.954-.139,7.223-1.328,2.988-.991,7.4-.991,7.4s-.2.577.83,1.093a3.838,3.838,0,0,0,2.541-.022s-.245-5.143.523-7.83a33.429,33.429,0,0,0,.491-6.238l1.208,4.456.092,6.5a2.671,2.671,0,0,0,1.627.729,1.526,1.526,0,0,0,1.492-.846,84.077,84.077,0,0,0-.532-8.515C130.108,192.868,129.145,187.657,129.145,187.657Z" transform="translate(-1.814 -18.77)" fill="#4f2525" />
        <path id="Path_685" data-name="Path 685" d="M131.612,170.268a5.1,5.1,0,0,0-1.737-3.025,3.207,3.207,0,0,0-2.852.514s-1.975.943-2.091,1.814c-.069.521,1.142,3.784.6,5.693a13.5,13.5,0,0,0-.641,5.09,11.312,11.312,0,0,0,3.58.993,5.369,5.369,0,0,0,1.583-.233c0-.752.088-2.117.088-2.117a10.933,10.933,0,0,1,.293,1.987,9.991,9.991,0,0,0,1.423-.783s-.433-3.057-.429-4.147C131.436,174.111,131.7,171.148,131.612,170.268Z" transform="translate(-3.403 -6.862)" fill="#5e2e2e" />
        <path id="Path_686" data-name="Path 686" d="M132.554,169.838l.457-.446a2.994,2.994,0,0,0-.457-.822,9.127,9.127,0,0,0-1.45-1.492l-1.926.166a1.6,1.6,0,0,0-1.142,1.113c-.136.495,1.171,2.134,1.171,2.134l.927.3-.569.859s1.136,1.464,1.436,1.993a26.782,26.782,0,0,1,.986,2.652,17.581,17.581,0,0,1,1.087-2.878c.493-.822.13-3.27.13-3.27Z" transform="translate(-5.308 -6.862)" fill="#4f2525" />
        <path id="Path_687" data-name="Path 687" d="M133.721,171.169a8.511,8.511,0,0,0-2.742.119,21.228,21.228,0,0,1,1.795,2.159c.659.935.986,4.218.986,4.218a19.531,19.531,0,0,0,.532-4.369A21.208,21.208,0,0,0,133.721,171.169Z" transform="translate(-7.081 -9.264)" fill="#fff" />
        <path id="Path_688" data-name="Path 688" d="M135.577,172.458l-.11-.863-.423-.152-.514.6.539.645a5.932,5.932,0,0,0,.167,1.244,5.079,5.079,0,0,1,.09.751c.2.827.433,2.167.433,2.167l.131,1.035.3-1.549a22.832,22.832,0,0,0,.006-2.782A2.351,2.351,0,0,0,135.577,172.458Z" transform="translate(-9.212 -9.482)" fill="#ff65a7" />
        <g id="Group_150" data-name="Group 150" transform="translate(123.561 156.013)">
          <path id="Path_689" data-name="Path 689" d="M130.948,164.08l-.382,2.119a7.911,7.911,0,0,0,1.637,1.216,3.666,3.666,0,0,0,.641-.66l-.094-1.365Z" transform="translate(-130.394 -161.076)" fill="#ffad97" />
          <path id="Path_690" data-name="Path 690" d="M130.478,157.243a2.683,2.683,0,0,0,.027,2.679,3.437,3.437,0,0,0,2.1,1.327c.883.142,1.835-2.3.828-3.788A1.794,1.794,0,0,0,130.478,157.243Z" transform="translate(-130.135 -156.568)" fill="#ffc5b3" />
        </g>
        <path id="Path_691" data-name="Path 691" d="M135.937,168.677l-.122,1.106.9.367S136.817,168.969,135.937,168.677Z" transform="translate(-9.983 -7.822)" fill="#ddccd3" />
        <path id="Path_692" data-name="Path 692" d="M130.387,168.288c-.611.6.086,1.426.086,1.426a8.4,8.4,0,0,0,1.6.85l.338-1.015a6.174,6.174,0,0,1-1.1-.378A2.505,2.505,0,0,1,130.387,168.288Z" transform="translate(-6.575 -7.589)" fill="#ddccd3" />
        <path id="Path_693" data-name="Path 693" d="M120.134,173.92a13.886,13.886,0,0,0-.946,3.525c-.068.55.347,1.656.9,1.73.9.122.995-.968,1.364-1.591,0,0,.859-2.4,1.042-3.234a3.671,3.671,0,0,0-.387-2.907C121.2,171.411,120.737,172.293,120.134,173.92Z" transform="translate(0 -9.482)" fill="#4f2525" />
        <path id="Path_694" data-name="Path 694" d="M126.535,194.808a1.814,1.814,0,0,0,.393,1.2,1.255,1.255,0,0,0-.439.216c-.154.141-.154.339.185.278a1.3,1.3,0,0,0,1.214-.952,1.459,1.459,0,0,0-.339-1.4S126.575,194.378,126.535,194.808Z" transform="translate(-4.327 -23.106)" fill="#ffc5b3" />
        <path id="Path_695" data-name="Path 695" d="M121.869,184.92s-1.983,1.183-1.55,2.085a16.829,16.829,0,0,0,2.364,2.786,1.318,1.318,0,0,0,1.05-.463,1.508,1.508,0,0,0,.416-.924s-.832-1.1-1.248-1.694S121.869,184.92,121.869,184.92Z" transform="translate(-0.646 -17.57)" fill="#4f2525" />
        <path id="Path_696" data-name="Path 696" d="M132.592,155.987l-.011-.008h0a2.387,2.387,0,0,0-1.641-.312,2.107,2.107,0,0,0-1.651,2.055,2.3,2.3,0,0,0,.709,1.643s-.028-.625.161-.788c.167-.144.313-.064.4.277a1.578,1.578,0,0,0,1.046-1.149l.033.012a4.412,4.412,0,0,0,2.158,0s-.194-.358-.444-.855A2.448,2.448,0,0,0,132.592,155.987Z" transform="translate(-6.065)" fill="#5e2e2e" />
        <g id="Group_151" data-name="Group 151" transform="translate(128.188 162.423)">
          <path id="Path_697" data-name="Path 697" d="M147.279,172.615c-.042-.027-.115-.024-.219.021l-2.2,1.276a1.783,1.783,0,0,0-.706.641l-2.377,4.117c-.082.142-.086.238-.03.275v0l.235.135.11-.231,2.079-1.2a1.784,1.784,0,0,0,.706-.641l2.377-4.117c.006-.011.01-.02.015-.03l.054.053.186-.169-.224-.131Z" transform="translate(-141.706 -172.598)" fill="#eadce1" />
          <path id="Path_698" data-name="Path 698" d="M147.63,172.963l-2.2,1.275a1.784,1.784,0,0,0-.706.641L142.344,179c-.142.246-.057.36.189.254l2.2-1.276a1.782,1.782,0,0,0,.706-.641l2.377-4.118C147.961,172.971,147.876,172.856,147.63,172.963Z" transform="translate(-142.048 -172.793)" fill="#b59eab" />
        </g>
        <path id="Path_699" data-name="Path 699" d="M151.373,179.361c-.455-.1-1.064.667-.962,1.142s.792.242.99-.223C151.626,179.75,151.763,179.444,151.373,179.361Z" transform="translate(-18.736 -14.229)" fill="#ffc5b3" />
      </g>
    </svg>
  );
}

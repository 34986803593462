export default function renderIcon({
  color = "#ffffff",
  width = "22.604",
  height = "29.065",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        id="back-pain_1_"
        data-name="back-pain (1)"
        transform="translate(-56.906 0)"
      >
        <path
          id="Path_57813"
          data-name="Path 57813"
          d="M184.026.541a3.255,3.255,0,0,0-4.507.913l-.436.626-.011.017a3.232,3.232,0,0,0-.514,2.2A3.979,3.979,0,0,0,175.051,6.6l-2.976,5.789-.493.873a4.258,4.258,0,0,0-.494,3.011l-.253,2.317a58.293,58.293,0,0,1-1.468,7.353,2.42,2.42,0,1,0,4.641,1.373l.5-1.979L174.747,27a2.358,2.358,0,0,0,4.7-.3c0-2.626-.525-9.067-.64-10.445l.549-1.023c.721.4,1.87,1.027,2.139,1.182a2.081,2.081,0,0,0,2.291-3.464c-1.911-1.8-1.827-1.717-1.827-1.717.01-.017.275-.508.285-.526a4.1,4.1,0,0,0,.235-3.641A3.245,3.245,0,0,0,184.5,5.7l.436-.626.011-.017a3.255,3.255,0,0,0-.922-4.513ZM172.364,26.873A.717.717,0,0,1,171,26.439a60.18,60.18,0,0,0,1.539-7.737c.328.288.724.588,1.168.937l.178,1.257Zm4.722.489a.656.656,0,0,1-.65-.575l0-.015-1.091-7.71c-.057-.406-.285-.528-.875-.989-.8-.624-1.455-1.064-1.69-2.045a2.567,2.567,0,0,1,.279-1.911l.006-.011.06-.107,4,2.572c.135,1.644.62,7.7.62,10.137A.655.655,0,0,1,177.086,27.362Zm5.566-13.13a.863.863,0,0,0,.094.077.378.378,0,0,1-.406.636c-.468-.269-3.3-1.818-3.69-2.04a.891.891,0,0,1-.375-1.128l.007-.014c.127-.262.987-2.007,1-2.025a.851.851,0,0,0-1.528-.753c-.332.673-.969,1.968-1,2.024-.012.025-.013.028-.021.046l-.011.023h0a2.591,2.591,0,0,0,1.079,3.3l.061.035-.243.452-3.685-2.37,2.634-5.126.009-.017a2.076,2.076,0,0,1,3.108-.968l.5.4a2.393,2.393,0,0,1,.574,3.08l-.015.027-.277.511a1.7,1.7,0,0,0,.446,2.194Zm.879-10.125-.435.625-.011.017a1.556,1.556,0,0,1-2.153.44h0a1.553,1.553,0,0,1-.445-2.146l.435-.625.011-.017a1.554,1.554,0,0,1,2.6,1.706Z"
          transform="translate(-105.978 0)"
          fill={color}
        />
        <path
          id="Path_57814"
          data-name="Path 57814"
          d="M103,75.238a.852.852,0,0,0,1.459.179l.857-1.126.847,1.959a.852.852,0,0,0,1.563-.676l-1.427-3.3a.852.852,0,0,0-1.457-.174l-.856,1.125-.846-1.961a.852.852,0,0,0-1.564.674Z"
          transform="translate(-42.065 -66.734)"
          fill={color}
        />
        <path
          id="Path_57815"
          data-name="Path 57815"
          d="M62.108,190.1l-.515-1.318c2.305.324,2.149.3,2.233.3a.852.852,0,0,0,.117-1.695l-3.559-.5a.852.852,0,0,0-.908,1.153l.514,1.317-2.115-.3a.852.852,0,0,0-.238,1.686l3.557.5A.852.852,0,0,0,62.108,190.1Z"
          transform="translate(0 -176.273)"
          fill={color}
        />
        <path
          id="Path_57816"
          data-name="Path 57816"
          d="M219.072,61.586a.852.852,0,0,0,.852-.852V58.348a.852.852,0,0,0-1.7,0v2.387A.851.851,0,0,0,219.072,61.586Z"
          transform="translate(-152.156 -54.232)"
          fill={color}
        />
        <path
          id="Path_57817"
          data-name="Path 57817"
          d="M121.9,274.509a.852.852,0,0,0-1.2,0l-1.688,1.688a.852.852,0,0,0,1.2,1.2l1.688-1.688A.851.851,0,0,0,121.9,274.509Z"
          transform="translate(-58.341 -258.69)"
          fill={color}
        />
      </g>
    </svg>
  );
}

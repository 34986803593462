import React from 'react';
import { ModalStyled } from './styled';
import cx from 'classnames';

export const Modal = ({
  theme_modal_standard,
  children,
  isShow,
  handleClickCloseModal,
  noClickBackdrop,
}) => {
  const customClass = cx({
    theme_modal_standard: theme_modal_standard,
  });

  return (
    <ModalStyled _isShow={isShow}>
      <div className={customClass}>
        <div className="modal_body">{children}</div>
        {
          noClickBackdrop
            ?
            <div className="backdrop" />
            :
            <div className="backdrop" onClick={handleClickCloseModal} />
        }
      </div>
    </ModalStyled>
  );
};


Modal.propTypes = {}

export default Modal

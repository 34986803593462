import styled from "styled-components";

export const RewardSearchContainerStyled = styled.div`
  padding: 15px 32px 56px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layer_one {
    display: flex;
  }
  .serach_panel {
    margin-top: 44px;
    width: 40%;
  }
  .layer_three {
    margin-top: 24px;
    .group_line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      &.no_margin {
        margin-bottom: 0px;
      }
      &.w69 {
        .left_line {
          width: 69%;
        }
      }
      .left_line {
        width: 74%;
        &.pd_left {
          padding-left: 20px;
        }
        .title_list {
          display: flex;
          margin-bottom: 16px;
          .icon_list {
            display: flex;
            margin-right: 15px;
          }
        }
      }
    }
  }
  .layer_four {
    margin-top: 25px;
  }
`;

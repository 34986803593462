export default function renderIcon({ color = '#0f8989', width = '15.224', height = '34.04' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15.224 34.04">
      <g id="Group_56924" data-name="Group 56924" transform="translate(-15681 5694.601)">
        <g id="Group_14075" data-name="Group 14075" transform="translate(15683.51 -5694.601)">
          <g id="Group_1238" data-name="Group 1238" transform="translate(0)">
            <ellipse id="Ellipse_306" data-name="Ellipse 306" cx="5.092" cy="4.849" rx="5.092" ry="4.849" fill={color} />
          </g>
        </g>
        <g id="Group_14076" data-name="Group 14076" transform="translate(15681 -5682.443)">
          <g id="Group_1240" data-name="Group 1240">
            <path id="Path_3276" data-name="Path 3276" d="M175.214,170.786l-1.152-8.6A2.489,2.489,0,0,0,171.54,160h-7.853a2.485,2.485,0,0,0-2.522,2.157l-1.154,8.632a1.179,1.179,0,0,0,.307.956,1.289,1.289,0,0,0,.951.413h1.421l1.007,7.537a2.5,2.5,0,0,0,2.527,2.188H169a2.487,2.487,0,0,0,2.522-2.157l1.01-7.569h1.421a1.29,1.29,0,0,0,.952-.413A1.165,1.165,0,0,0,175.214,170.786Z" transform="translate(-160.001 -160)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  )
}

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSelectCusIconStyled, OptionStyled } from './styled';
import Select, { components } from 'react-select';
import { Icons } from 'components';
import theme from 'styles/theme.json';

const InputSelectCusIcon = ({ theme_standard, options, ...props }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: `1px solid ${theme.COLORS.PURPLE_3}`,
      color: state.selectProps.menuColor,
      padding: 0,
      zIndex: 22,
    }),

    control: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: '6px 16px',
      width: 'calc(100% - 30px)',
      border: `1px solid ${theme.COLORS.PURPLE_3}`,
      borderRadius: '10px',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const CustomOption = ({ innerProps, isDisabled, data }) =>
    !isDisabled ? (
      <OptionStyled {...innerProps}>
        <div className="op_icon_wrap">{data.icon}</div>
        <div className="op_label_wrap">{data.label}</div>
      </OptionStyled>
    ) : null;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <Icons.ArrowDropdown translate="21.81" rotate="90" />
        ) : (
          <Icons.ArrowDropdown />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <InputSelectCusIconStyled>
      <div className={customClass}>
        <Select
          styles={customStyles}
          defaultValue={options[0]}
          options={options}
          {...props}
          components={{
            Control: ({ children, selectProps, ...rest }) => {
              return (
                <components.Control {...rest}>
                  {selectProps.value.icon}
                  {children}
                </components.Control>
              );
            },
            Option: CustomOption,
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </InputSelectCusIconStyled>
  );
};

InputSelectCusIcon.propTypes = {};

export default InputSelectCusIcon;

import { MsdNumberOfRiskPuiStyled } from "./styled";
import { Blocks, Typographys, Icons, Buttons, Skeleton } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import moment from "moment";
import theme from "styles/theme.json";

const MsdNumberOfRiskPui = ({ data, fetchData }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_percent, _setPercent] = useState();
  const [_data, _setData] = useState();
  const [_filter, _setFilter] = useState("weekly");
  const [_dataLoaded, _setDataLoaded] = useState();
  const [_isShorLeftArrow, _setIsShorLeftArrow] = useState(false);
  const [_isShorRightArrow, _setIsShorRightArrow] = useState(false);
  const [_page, _setPage] = useState({
    per_page: 8,
    offset: 0,
    current_page: 1,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      _setData(data);
      _setDataLoaded(data);
      _setPercent({
        index: [data.length - 1],
        norisk: data[data.length - 1].norisk.count,
        pui: data[data.length - 1].pui.count,
      });
    }
  }, [data]);

  const handleClickFilter = async (value) => {
    _setFilter(value);
    _setLoadSkeleton(true);
    let res = await fetchData(`?period=${value}&offset=0&per_page=8`);
    if (res && res.length > 0) {
      _setData(res);
      _setDataLoaded(res);
      _setLoadSkeleton(false);
      _setPercent({
        index: [data.length - 1],
        norisk: res[res.length - 1].norisk.count,
        pui: res[res.length - 1].pui.count,
      });
      resetPagination();
    } else {
      _setLoadSkeleton(false);
    }
  };

  const handleClickItemGraph = (index) => {
    if (!_percent || (_percent && index !== _percent.index)) {
      _setPercent({
        index: index,
        norisk: _data[index].norisk.count,
        pui: _data[index].pui.count,
      });
    }
  };

  const renderLabelX = (key, value) => {
    let dateStart;
    let dateEnd;
    let renderMonth;

    switch (key) {
      case "weekly":
        dateStart = moment(value).startOf("isoWeek");
        dateEnd = moment(value).endOf("isoWeek").add(7, "days");
        renderMonth =
          dateStart.format("MM") === dateEnd.format("MM")
            ? RENDER_MONTH(dateStart.format("MM")).enShort
            : `${RENDER_MONTH(dateStart.format("MM")).enShort} ${
                RENDER_MONTH(dateEnd.format("MM")).enShort
              }`;
        return [
          `${moment(value).startOf("isoWeek").format("DD")}-${moment(value)
            .endOf("isoWeek")
            .format("DD")}`,
          renderMonth,
        ];
      case "quarterly":
        dateStart = moment(value).startOf("month");
        dateEnd = moment(value).endOf("month").add(2, "month");
        renderMonth =
          dateStart.format("YY") === dateEnd.format("YY")
            ? dateStart.format("YYYY")
            : `${dateStart.format("YYYY")} - ${dateEnd.format("YYYY")}`;
        return [
          `${RENDER_MONTH(dateStart.format("MM")).enShort} - ${
            RENDER_MONTH(dateEnd.format("MM")).enShort
          }`,
          renderMonth,
        ];
      case "halfyearly":
        dateStart = moment(value).startOf("month");
        dateEnd = moment(value).endOf("month").add(5, "month");
        renderMonth =
          dateStart.format("YY") === dateEnd.format("YY")
            ? dateStart.format("YYYY")
            : `${dateStart.format("YYYY")} - ${dateEnd.format("YYYY")}`;
        return [
          `${RENDER_MONTH(dateStart.format("MM")).enShort} - ${
            RENDER_MONTH(dateEnd.format("MM")).enShort
          }`,
          renderMonth,
        ];
      default:
        return RENDER_MONTH(moment(value).format("MM"));
    }
  };

  const _fetchDataApi = async (
    filterDay = "weekly",
    offset = 0,
    perPage = 8
  ) => {
    // _setLoadSkeleton(true);
    let res = await fetchData(
      `?period=${filterDay}&offset=${offset}&per_page=${perPage}`
    );
    if (res && res.length > 0) {
      _setLoadSkeleton(false);
      return res;
    } else {
      _setLoadSkeleton(false);
      return res;
    }
  };

  // Manage Enable/Disable Left/Right Arrow
  // PAGINATION GRAPH FLOW
  //============================================
  //============================================
  useEffect(() => {
    const checkCondition = async () => {
      if (_page.current_page === 1) {
        _setIsShorRightArrow(false);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      } else {
        _setIsShorRightArrow(true);
        let res = await _fetchDataApi(
          _filter,
          _page.current_page * _page.per_page,
          _page.per_page
        );
        if (!res || res.length === 0 || res.length < _page.per_page) {
          _setIsShorLeftArrow(false);
        } else {
          _setIsShorLeftArrow(true);
        }
      }
    };
    if (_page) {
      checkCondition();
    }
  }, [_page]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClickLeftArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    //check has dataloadmore
    if (_page.current_page * _page.per_page < _dataLoaded.length) {
      handleCalDataPage("next");
    } else {
      let res = await _fetchDataApi(
        _filter,
        _page.current_page * _page.per_page,
        _page.per_page
      );
      if (res) {
        _setDataLoaded([...res, ..._dataLoaded]);
        _setData(res);
        _setPage({ ..._page, current_page: _page.current_page + 1 });
      }
    }
  };
  const handleClickRightArrow = async () => {
    handleDisabledArrow();
    _setLoadSkeleton(true);
    handleCalDataPage("back");
  };
  const handleCalDataPage = (key) => {
    let dataTemps = [..._dataLoaded];
    switch (key) {
      case "back":
        _setData(
          dataTemps
            .reverse()
            .slice(
              _page.current_page - 1 === 1
                ? 0
                : (_page.current_page - 1) * Number(_page.per_page) -
                    _page.per_page,
              (_page.current_page - 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page - 1 });
        break;
      case "next":
        _setData(
          dataTemps
            .reverse()
            .slice(
              (_page.current_page + 1) * Number(_page.per_page) -
                _page.per_page,
              (_page.current_page + 1) * Number(_page.per_page)
            )
            .reverse()
        );
        _setPage({ ..._page, current_page: _page.current_page + 1 });
        break;
      default:
        break;
    }
  };
  const resetPagination = () => {
    _setPage({ per_page: 8, offset: 0, current_page: 1 });
  };
  const handleDisabledArrow = () => {
    _setIsShorLeftArrow(false);
    _setIsShorRightArrow(false);
  };

  const renderDateShow = (key) => {
    let dateStart;
    let dateEnd;
    let valueDate;

    switch (key) {
      case "weekly":
        valueDate =
          moment(_data[0].stat_date).startOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(
            moment(_data[0].stat_date)
              .startOf("isoWeek")

              .format("MM")
          ).thShort +
          ` ` +
          moment(_data[0].stat_date).startOf("isoWeek").format("YYYY") +
          ` - ` +
          moment(_data[0].stat_date).endOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(moment(_data[0].stat_date).endOf("isoWeek").format("MM"))
            .thShort +
          ` ` +
          moment(_data[0].stat_date).endOf("isoWeek").format("YYYY");
        return valueDate;
      case "quarterly":
        dateStart = moment(_data[0].stat_date).startOf("month");
        dateEnd = moment(_data[0].stat_date).endOf("month").add(2, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      case "halfyearly":
        dateStart = moment(_data[0].stat_date).startOf("month");
        dateEnd = moment(_data[0].stat_date).endOf("month").add(5, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      default:
        return "";
    }
  };

  return (
    <MsdNumberOfRiskPuiStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="32px"
        paddingBottom="59px"
        paddingLeft="32px"
      >
        <div className="top_men">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="จำนวนพนักงานที่มีอาการเจ็บแต่ไม่มีความเสี่ยง และมีความเสี่ยง PUI"
              labelBottom="Number of employees who be painful but not at risk and is at PUI risk"
            />
          </div>
          <div className="right_men_layout">
            <div className="show_search">
              <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                labeDisplay
                onChange={handleClickFilter}
              />
            </div>
          </div>
        </div>
        {_data ? (
          <>
            {_loadSkeleton === false && (
              <div className="title_graph">จำนวนคน</div>
            )}
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="b_left_layout">
                    <div className="arrow_left">
                      {_isShorLeftArrow && (
                        <div onClick={handleClickLeftArrow}>
                          <Icons.MoveChartLeft />
                        </div>
                      )}
                    </div>
                    <div className="arrow_right">
                      {_isShorRightArrow && (
                        <div onClick={handleClickRightArrow}>
                          <Icons.MoveChartRight />
                        </div>
                      )}
                    </div>
                    <div className="body_graph">
                      <Chart
                        type="line"
                        width="100%"
                        height="292"
                        options={{
                          chart: {
                            id: "apexchart-example",
                            fontFamily: theme.FONT.STYLE.REGULAR,
                            toolbar: {
                              show: false,
                            },
                            events: {
                              markerClick: function (
                                event,
                                chartContext,
                                { seriesIndex, dataPointIndex, config }
                              ) {
                                handleClickItemGraph(dataPointIndex);
                              },
                              mouseMove: function (
                                event,
                                chartContext,
                                config
                              ) {
                                if (config.dataPointIndex !== -1) {
                                  handleClickItemGraph(config.dataPointIndex);
                                }
                              },
                              mouseLeave: function (
                                event,
                                chartContext,
                                config
                              ) {
                                if (_data) {
                                  handleClickItemGraph(_data.length - 1);
                                }
                              },
                            },
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (
                                value,
                                { series, seriesIndex, dataPointIndex, w }
                              ) {
                                return `${Number(value).toFixed(0)} คน`;
                              },
                            },
                          },
                          xaxis: {
                            // categories: [
                            //   ['1-14', 'Dec'],
                            //   ['15-28', 'Dec'],
                            //   ['29-11', 'Dec Jan'],
                            //   ['11-24', 'Jan'],
                            //   ['25-7', 'Jan FEB'],
                            //   ['8-21', 'FEB'],
                            //   ['22-7', 'MAR'],
                            // ],
                            categories: _data
                              ? _data.map((e) => {
                                  return renderLabelX(_filter, e.stat_date);
                                })
                              : [],
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: true,
                              color: "#707070",
                            },
                            labels: {
                              formatter: function (value) {
                                return RenderCommaMoney(
                                  Number(value).toFixed(0),
                                  0
                                );
                              },
                            },
                          },
                          colors: ["#2A74A3", "#654C96"],
                          markers: {
                            size: 4,
                          },
                          stroke: {
                            curve: "smooth",
                            width: 3,
                          },
                        }}
                        series={[
                          {
                            name: "Painful but not at risk",
                            data: _data && [
                              ..._data.map((e) => e.norisk.count),
                            ],
                          },
                          {
                            name: "PUI risk",
                            data: _data && [..._data.map((e) => e.pui.count)],
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="right_men_layout">
                    <div className="date_show">
                      <div className="label_layout">
                        <div className="show_icon">
                          <Icons.CalendarIcon /> วันที่รายสัปดาห์ล่าสุด
                        </div>
                      </div>
                      <div className="date_layout">
                        {data && renderDateShow(_filter)}
                      </div>
                    </div>
                    <div className="show_being">
                      <Blocks.BoxWellbeing
                        theme_standard_box_well_being
                        label="มีอาการแต่ยังไม่มีความเสี่ยง"
                        labelBottom="Pain without risk"
                        bgColor="#2A74A3"
                        showRound
                      />
                    </div>
                    <div className="show_being no_margin">
                      <Blocks.BoxWellbeing
                        theme_standard_box_well_being
                        label="มีความเสี่ยงเข้าเกณฑ์เป็นโรค"
                        labelBottom="PUI risk"
                        bgColor="#654C96"
                        showRound
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </MsdNumberOfRiskPuiStyled>
  );
};

export default MsdNumberOfRiskPui;

import React, { useEffect } from 'react';
import { AddArticleInvitraceFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Inputs,
  Icons,
  Typographys,
  Tabs,
  Blocks,
  TableCustom,
  Buttons,
} from 'components';

const AddArticleInvitraceForm = ({
  initialValues,
  goalData,
  companyID,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    // category: yup.object().nullable().required('Category is required'),
    // username: yup.string().nullable().required('Username is required'),
    // password: yup.string().nullable().required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        is_draft: initialValues.is_draft ? statusOptions[0] : statusOptions[1],
        type: typeOptions.find((e) => e.value === initialValues.type),
        category: categoryOptions.find(
          (e) => e.value === initialValues.category
        ),
        files:
          initialValues.type === 'audio'
            ? initialValues.audios
            : initialValues.type === 'video'
            ? initialValues.videos
            : [],
        file_cover: initialValues.covers,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFile = (key) => {
    switch (key?.value) {
      case 'audio':
        return (
          <>
            <div className="line_label">
              <div className="label_1">ไฟล์เสียง</div>
              <div className="label_2">Audio file</div>
            </div>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputBrowse
                  theme_standard_browse
                  placeholder="Document File Location"
                  maxMBFileSizeWarning="5"
                  acceptType="audio"
                  {...field}
                  svg={<Icons.Delete />}
                  theme_left_right={true}
                />
              )}
              name="files"
              defaultValue={[]}
            />
          </>
        );
      case 'video':
        return (
          <>
            <div className="line_label">
              <div className="label_1">ไฟล์วิดีโอ</div>
              <div className="label_2">Video file</div>
            </div>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputBrowse
                  theme_standard_browse
                  placeholder="Document File Location"
                  acceptType="video"
                  maxMBFileSizeWarning="20"
                  {...field}
                  svg={<Icons.Delete />}
                  theme_left_right={true}
                />
              )}
              name="files"
              defaultValue={[]}
            />
          </>
        );
      default:
        break;
    }
  };

  const statusOptions = [
    {
      label: 'ไม่เผยแพร่',
      value: 'true',
      icon: <Icons.Lock />,
    },
    {
      label: 'สาธารณะ',
      value: 'false',
      icon: <Icons.Gobal />,
    },
  ];
  const typeOptions = [
    {
      label: 'บทความ',
      value: 'article',
      icon: <Icons.Book />,
    },
    {
      label: 'เสียง',
      value: 'audio',
      icon: <Icons.TypeSound width="32" height="32" />,
    },
    {
      label: 'วิดีโอ',
      value: 'video',
      icon: <Icons.TypeVideo width="32" height="32" />,
    },
  ];
  const categoryOptions = [
    {
      label: 'สุขภาพรวม',
      value: 'health',
      icon: <Icons.New />,
    },
    {
      label: 'คลายเครียด',
      value: 'relax',
      icon: <Icons.Nutrition />,
    },
    {
      label: 'ออกกำลังกาย',
      value: 'exercise',
      icon: <Icons.Exercise />,
    },
    {
      label: 'อื่นๆ',
      value: 'other',
      icon: <Icons.All />,
    },
  ];

  return (
    <AddArticleInvitraceFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="layer_one">
          <Tabs.HeadTab
            theme_standard_head_tab
            title={initialValues ? 'แก้ไขบทความ' : 'สร้างบทความใหม่'}
            subTitle={initialValues ? 'Edit article' : 'New article'}
            fontSize="34px"
            fontSizeLabelButtom="18px"
            fontColor="#092945"
            colorBottom="#092945"
            lineHeight="1.2"
            typeButton
            type="submit"
            labelButtonTop={initialValues && 'บันทึก'}
            labelButtonBottom={initialValues && 'Save'}
          />
        </div>
        <div className="layer_two">
          <div className="top_form">
            <div className="group_top_form">
              <div className="line_label">
                <div className="label_1">สถานะ</div>
                <div className="label_2">Status</div>
              </div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputSelectCusIcon
                    theme_mini
                    iconSvg={<Icons.Lock />}
                    iconSvgClose={<Icons.ArrowDropdown />}
                    iconSvgOpen={
                      <Icons.ArrowDropdown translate="21.81" rotate="90" />
                    }
                    options={
                      companyID === 0 ? [statusOptions[1]] : statusOptions
                    }
                    {...field}
                  />
                )}
                name="is_draft"
                defaultValue={
                  companyID === 0 ? statusOptions[1] : statusOptions[0]
                }
              />
            </div>
          </div>
          <div className="mid_form">
            <div className="line_mid">
              <div className="mid_left">
                <div className="group_mid_form">
                  <div className="line_label">
                    <div className="label_1">ประเภท</div>
                    <div className="label_2">Type</div>
                  </div>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSelectCusIcon
                        theme_mini
                        iconSvg={<Icons.Lock />}
                        iconSvgClose={<Icons.ArrowDropdown />}
                        iconSvgOpen={
                          <Icons.ArrowDropdown translate="21.81" rotate="90" />
                        }
                        options={typeOptions}
                        {...field}
                      />
                    )}
                    name="type"
                    defaultValue={{
                      label: 'บทความ',
                      value: 'article',
                      icon: <Icons.Book />,
                    }}
                  />
                </div>
              </div>
              <div className="mid_right">
                <div className="group_mid_form_right">
                  {renderFile(watchAllFields?.type)}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_form">
            <div className="bottom_left">
              <div className="group_bottom_form mb10">
                <div className="line_label">
                  <div className="label_1">หมวด</div>
                  <div className="label_2">Category</div>
                </div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputSelectCusIcon
                      theme_mini_drop_down_normal
                      iconSvgClose={<Icons.ArrowDropdown />}
                      iconSvgOpen={
                        <Icons.ArrowDropdown translate="21.81" rotate="90" />
                      }
                      options={categoryOptions}
                      {...field}
                    />
                  )}
                  name="category"
                  defaultValue={{
                    label: 'อื่นๆ',
                    value: 'other',
                    icon: <Icons.All />,
                  }}
                />
              </div>
            </div>
            <div className="bottom_right">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextArea
                    theme_standard_text_area
                    labelTitle="หัวข้อ"
                    labelSub="Topic"
                    maxLength={120}
                    height="58px"
                    placeholder="หัวข้อ"
                    svg={<Icons.Pencil1 />}
                    {...field}
                  />
                )}
                name="topic"
                defaultValue=""
              />
            </div>
          </div>
          <div className="bottom_form">
            <div className="bottom_left">
              <div className="reward_opt">
                <div className="show_reward_layer">
                  <div className="title_show">
                    <div className="show_icon">
                      <Icons.Reward5 />
                    </div>
                    <Typographys.TopBottom
                      theme_left_align
                      label="ของรางวัล"
                      labelBottom="Reward"
                      fontSizeLabelButtom="16px"
                      fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    />
                  </div>
                  <div className="box_input_bottom">
                    <div className="left_box">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Inputs.InputSelectCusIcon
                            theme_mini
                            iconSvgClose={<Icons.ArrowDropdown />}
                            iconSvgOpen={
                              <Icons.ArrowDropdown
                                translate="21.81"
                                rotate="90"
                              />
                            }
                            options={[
                              {
                                label: 'คะแนนสุขภาพ',
                                value: 'nuti',
                                icon: <Icons.Dumbel2 />,
                              },
                              // {
                              //   label: 'ค่าประสบการณ์',
                              //   value: 'nutrition',
                              //   icon: <Icons.Exp />,
                              // },
                            ]}
                            {...field}
                          />
                        )}
                        name="reward_select"
                        defaultValue={{
                          label: 'คะแนนสุขภาพ',
                          value: 'nuti',
                          icon: <Icons.Dumbel2 />,
                        }}
                      />
                    </div>
                    <div className="right_box">
                      <div className="body_input">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputText
                              theme_input_mini
                              type="number"
                              iconSvg={<Icons.User />}
                              placeholder="ใส่ข้อความ"
                              {...field}
                              errors={errors.username?.message}
                            />
                          )}
                          name="default_coin_amount"
                          defaultValue={goalData}
                        />
                      </div>
                      <div className="label_b">แต้ม</div>
                    </div>
                  </div>
                  {/* <div className="box_input_bottom no_margin">
                    <div className="left_box">
                      <Buttons.BtnShadow
                        theme_standard_btn_shadow
                        label="เพิ่มของรางวัล"
                        svg={<Icons.Plus color="#EC2B27" />}
                        fontSize="16px"
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="bottom_right">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextRich
                    labelTitle="บทความ"
                    labelSub="Content"
                    maxLength={5000}
                    placeholder="บทความ"
                    svg={<Icons.Pencil2 />}
                    height="276px"
                    {...field}
                  />
                )}
                name="body"
                defaultValue=""
              />
            </div>
          </div>
          {/* <div className="opt_show">
            <div className="left_opt">
              <div className="group_opt">
                <div className="line_label">
                  <div className="label_1">ภาพปกบทความ</div>
                  <div className="label_2">Cover photo</div>
                </div>
                <Controller
                  control={control}
                  render={({field}) => (
                    <Inputs.InputBrowse
                      theme_standard_browse
                      acceptType="cover"
                      maxMBFileSizeWarning="1"
                      value={value}
                      onChange={onChange}
                      svg={<Icons.Delete2 />}
                      theme_left_right={false}
                    />
                  )}
                  name="file_cover"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div> */}
          <div className="zone_upload">
            <div className="header_zone">
              <div className="label_1">ภาพปกบทความ</div>
              <div className="label_2">Cover photo</div>
            </div>
            <div className="upload_layer">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadSetOneImage
                    theme_upload_set_image
                    acceptType="cover"
                    maxMBFileSizeWarning="1"
                    {...field}
                    svg={<Icons.Delete2 />}
                    theme_left_right={false}
                  />
                )}
                name="file_feature"
                defaultValue={[]}
              />
            </div>
          </div>
          <div className="zone_upload">
            <div className="header_zone">
              <div className="label_1">ภาพประกอบบทความ</div>
              <div className="label_2">Cover photo</div>
            </div>
            <div className="upload_layer">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadSetImage
                    theme_upload_set_image
                    acceptType="cover"
                    maxMBFileSizeWarning="1"
                    {...field}
                    svg={<Icons.Delete2 />}
                    theme_left_right={false}
                  />
                )}
                name="file_cover"
                defaultValue={[]}
              />
            </div>
          </div>
        </div>
        <div className="reward_setting">
          <div className="top_setting">
            <div className="left_top_setting">
              <div className="show_icon_set">
                <Icons.Setting />
              </div>
              <Typographys.TopBottom
                theme_left_align
                label="ตั้งค่ารางวัล"
                labelBottom="Rewards Setting"
                fontSize="24px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              />
            </div>
            <div className="right_top_setting">
              <div className="btn_action_save">
                <Buttons.BtnNormal
                  theme_standard_btn_normal_purple
                  // type={type}
                  label="บันทึกการตั้งค่าและรายชื่อบริษัท"
                  fontSize="16px"
                  fontFamilyTop="noto_sans_thai_medium, noto_sans_regular"
                />
              </div>
            </div>
          </div>
          <div className="body_setting">
            <div className="left_body">
              <Blocks.Box
                theme_standard_box
                paddingTop="21px"
                paddingRight="21px"
                paddingBottom="56px"
                paddingLeft="21px"
              >
                <div className="in_box">
                  <div className="show_icon_in_box">
                    <Icons.Dumbel2 width="26" height="26" />
                  </div>
                  <Typographys.TopBottom
                    theme_left_align
                    label="คะแนนสุขภาพ"
                    labelBottom="Health Point Requirement"
                    fontSize="18px"
                    fontSizeLabelButtom="14px"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  />
                </div>
                {/* <div className='opt_layout'>
                  <div className={`label_1`}>
                    ไม่เท่ากัน
                  </div>
                  <Inputs.InputSwitch
                    theme_input_switch
                    onChangeCustom={setCheck}
                  />
                  <div className={`label_2 ${isCheck && `active`}`}>
                    เท่ากันทั้งหมด
                  </div>
                </div> */}
                <div className="opt_layout pd_right">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_standard_text_max_char
                        type="number"
                        // iconSvg={<Icons.User />}
                        {...field}
                        // disabled={!isCheck}
                        // errors={errors.username?.message}
                      />
                    )}
                    name="score"
                    // defaultValue={goalData}
                  />
                  <div className="group_label">
                    <div>แต้ม</div>
                    <div className="sub_label">Point</div>
                  </div>
                </div>
              </Blocks.Box>
            </div>
            <div className="right_body">
              <TableCustom.TableAction
                theme_standard_table_normal_purple
                columns={columns}
                data={dataCompany}
                pagination={pagination}
                labelEmpty="ยังไม่มีข้อมูล"
                showPagination
                // handlePageClick={this.handlePageClick}
              />
            </div>
          </div>
        </div>
      </form>
    </AddArticleInvitraceFormStyled>
  );
};

const pagination = {
  last_page: 4,
  current_page: 1,
};

const columns = [
  {
    title: '',
    field: '',
    headerStyle: { textAlign: 'center', width: '20px', flexShrink: 0 },
    style: { textAlign: 'center', width: '20px', flexShrink: 0 },
  },
  {
    title: 'บริษัท',
    field: 'company',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: 'สถานะปัจจุบัน',
    field: 'status',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
];

const dataCompany = [
  {
    company: 'Toyota A',
    status: 'ไม่เผยแพร่',
  },
  {
    company: 'Toyota B',
    status: 'ไม่เผยแพร่',
  },
  {
    company: 'Toyota C',
    status: 'เผยแพร่',
  },
];

AddArticleInvitraceForm.propTypes = {};

export default AddArticleInvitraceForm;

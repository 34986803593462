import styled from "styled-components";

export const InputSearchStyled = styled.div`
  .theme_standard_search {
    .line_search {
      display: flex;
      justify-content: space-between;
      .search_layout {
        width: 72%;
        position: relative;
        .icon {
          position: absolute;
          top: 10px;
          left: 16px;
        }
        .input_serach {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          color: ${({ fontColor, theme }) =>
            fontColor ? fontColor : theme.COLORS.BLACK_1};
          width: 100%;
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_15};
          padding: 10px 15px 10px 51px;
          border-radius: 10px;
          box-sizing: border-box;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
        }
      }
      .btn_serach {
        width: 25%;
      }
    }
    .show_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      margin-top: 1px;
      display: flex;
      justify-content: flex-end;
      .btn_label {
        width: 25%;
        text-align: center;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { AddRewardDetailsFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Blocks, Typographys, Icons, Inputs, Buttons } from "components";
import moment from "moment";
import { RENDER_MONTH_SELECT } from "utils/functions/date";

const AddRewardDetailsForm = ({
  initialValues,
  onSubmit,
  groupCategory,
  disabled,
  handleDelete,
}) => {
  const schema = yup.object().shape({
    name: yup.string().nullable().required("Brand Name is required"),
    description: yup.string().nullable().required("Description is required"),
    term: yup.string().nullable().required("Term is required"),
    // begin_date: yup.object().nullable().required('Reward Period is required'),
    // end_date: yup.object().nullable().required('Reward Period is required'),
    // default_coin_amount: yup
    //   .string()
    //   .nullable()
    //   .required('Health Point Requirement is required')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .max(4, 'Less than 9,999'),
    default_coin_amount: yup
      .number()
      .nullable()
      .typeError("Amount must be a number")
      .required("Health Point Requirement is required")
      .min(1, "More than 1")
      .max(99999, "Less than 99,999"),
    total_redeemable_count: yup
      .number()
      .nullable()
      .typeError("Amount must be a number")
      .required("Total Reward Amount is required")
      .min(
        initialValues?.redeemed_count || 1,
        `More than ${initialValues?.redeemed_count || "1"}`
      )
      .max(9999, "Less than 9,999"),

    // .min(
    //   initialValues?.redeemed_count > 0 ? initialValues.redeemed_count : 1,
    //   `More than ${initialValues?.redeemed_count > 0 ? initialValues.redeemed_count : '1'}`
    // )
  });

  const {
    control,
    handleSubmit,
    reset,
    // watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [_redeemableCount, setRedeemableCount] = useState(0);
  const [_redeemaCount, setRedeemCount] = useState(0);
  const [addDate, setAddDate] = useState(365);

  useEffect(() => {
    if (initialValues) {
      setRedeemableCount(initialValues.total_redeemable_count);
      setRedeemCount(
        Number(initialValues.total_redeemable_count) -
          Number(initialValues.redeemed_count)
      );
      reset({
        ...initialValues,
        category_id: {
          label: initialValues.category.name_th,
          value: initialValues.category.id,
        },
        begin_date: {
          value: initialValues.begin_date,
          label: `${moment(initialValues.begin_date).format("D MMMM")} ${
            moment(initialValues.begin_date).year() + 543
          }`,
        },
        end_date: {
          value: initialValues.end_date,
          label: `${moment(initialValues.end_date).format("D MMMM")} ${
            moment(initialValues.end_date).year() + 543
          }`,
        },
        files: initialValues.image_uri
          ? [{ image_uri: initialValues.image_uri }]
          : [],
      });
    }

    // let today = new Date()

    let startDay = moment();
    let thisYear = moment(startDay).format("YYYY");
    let endOfYear = new Date(`${thisYear}-12-31`);
    let endDay = moment(endOfYear);
    let addDay = endDay.diff(startDay, "days");
    setAddDate(367 + addDay);
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  // const onChangeCustom = (e) => {
  //   if (initialValues) {
  //     if (Number(e.target.value) >= Number(initialValues.redeemable_count)) {
  //       setRedeemableCount(e.target.value);
  //       setRedeemCount(e.target.value - initialValues.redeemed_count);
  //     } else {
  //       setRedeemableCount(initialValues.redeemable_count);
  //       setRedeemCount(
  //         Number(initialValues.redeemable_count) -
  //           Number(initialValues.redeemed_count)
  //       );
  //     }
  //   } else {
  //     setRedeemableCount(e.target.value);
  //   }
  // };

  return (
    <AddRewardDetailsFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="top_add_mission">
          <div className="left_add">
            <div className="upload_layer">
              <Blocks.Box
                theme_standard_box
                paddingTop="22px"
                paddingBottom="32px;"
                paddingLeft="24px"
                paddingRight="24px"
              >
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      theme_standard_upload_image
                      acceptType="cover"
                      maxMBFileSizeWarning="1"
                      {...field}
                    />
                  )}
                  name="files"
                  defaultValue={[]}
                />
              </Blocks.Box>
            </div>
            <div className="setting_layer">
              <Blocks.Box
                theme_standard_box
                paddingTop="26px"
                paddingBottom="32px;"
                paddingLeft="24px"
                paddingRight="24px"
              >
                <div className="setting_top">
                  <div className="show_icon">
                    <Icons.Setting />
                  </div>
                  <Typographys.TopBottom
                    theme_left_align
                    label="ตั้งค่ารางวัล"
                    labelBottom="Rewards Setting"
                    fontSize="24px"
                    fontSizeLabelButtom="18px"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    marginTopBottomLabel="1px"
                  />
                </div>
                <div className="setting_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDropDownNormal
                        theme_standard_drop_down_normal
                        iconSvgClose={<Icons.ArrowDropdown />}
                        label="หมวด"
                        labelBottom="Category"
                        iconSvgOpen={
                          <Icons.ArrowDropdown translate="21.81" rotate="90" />
                        }
                        options={
                          groupCategory &&
                          groupCategory.map((e) => ({
                            value: e.id,
                            label: e.name_th,
                          }))
                        }
                        placeholder="เลือกหมวด"
                        {...field}
                      />
                    )}
                    name="category_id"
                    defaultValue={{
                      label: groupCategory && groupCategory[0].name_th,
                      value: groupCategory && groupCategory[0].id,
                    }}
                  />
                </div>
                <div className="group_setting_label">
                  <div className="t_input">
                    <div className="show_icon">
                      <Icons.Dumbel2 width="26" height="26" />
                    </div>
                    <Typographys.TopBottom
                      theme_left_align
                      label="คะแนนสุขภาพที่กำหนด"
                      labelBottom="Health Point Requirement"
                      fontSizeLabelButtom="14px"
                      fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    />
                  </div>
                  <div className="b_input">
                    <div className="left_b_input">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Inputs.InputText
                            theme_input_normal
                            iconSvg={<Icons.User />}
                            placeholder="ใส่ตัวเลข"
                            {...field}
                            // type='number'
                            maxLength={5}
                            min="1"
                            max="99999"
                            errors={errors.default_coin_amount?.message}
                          />
                        )}
                        name="default_coin_amount"
                        defaultValue=""
                      />
                    </div>
                    <div className="right_b_input">
                      <Typographys.TopBottom
                        theme_left_align
                        label="แต้ม"
                        labelBottom="Point"
                        fontSizeLabelButtom="14px"
                        fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                      />
                    </div>
                  </div>
                </div>
                <div className="group_setting_label">
                  <div className="t_input">
                    <div className="show_icon">
                      <Icons.Star2 />
                    </div>
                    <Typographys.TopBottom
                      theme_left_align
                      label="จำนวนสิทธิ์ทั้งหมด"
                      labelBottom="Total Reward Amount"
                      fontSizeLabelButtom="14px"
                      fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    />
                  </div>
                  <div className="b_input">
                    <div className="left_b_input">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Inputs.InputText
                            theme_input_normal
                            type="number"
                            iconSvg={<Icons.User />}
                            placeholder="ใส่ตัวเลข"
                            {...field}
                            errors={errors.total_redeemable_count?.message}
                          />
                        )}
                        name="total_redeemable_count"
                        defaultValue=""
                      />
                    </div>
                    <div className="right_b_input">
                      <Typographys.TopBottom
                        theme_left_align
                        label="สิทธิ์"
                        labelBottom="Quota"
                        fontSizeLabelButtom="14px"
                        fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                      />
                    </div>
                  </div>
                </div>
                <div className="group_setting_label">
                  <div className="b_input">
                    <div
                      className="left_b_input"
                      style={{ paddingLeft: "15px", width: "35%" }}
                    >
                      <Typographys.TopBottom
                        theme_left_align
                        label="สิทธิ์คงเหลือ"
                        labelBottom="Remaining"
                        fontSizeLabelButtom="14px"
                        fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                      />
                    </div>
                    <div className="right_b_input">
                      <span className="num1">{_redeemaCount}</span>
                      <span>/</span>
                      <span className="num2">{_redeemableCount}</span>
                    </div>
                  </div>
                </div>
              </Blocks.Box>
            </div>
          </div>
          <div className="right_add">
            <Blocks.Box
              theme_standard_box
              paddingTop="32px"
              paddingBottom="10px;"
              paddingLeft="24px"
              paddingRight="24px"
            >
              <div className="right_add_top">
                <div className="show_icon">
                  <Icons.Calendar2 />
                </div>
                <Typographys.TopBottom
                  theme_left_align
                  label="ข้อมูลรางวัล"
                  labelBottom="Rewards Information"
                  fontSize="24px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  marginTopBottomLabel="8px"
                />
              </div>
              {/* <div className="block_dual">
                <div className="label_pos center">
                  <Typographys.TopBottom
                    theme_left_align
                    label="ร้านค้า"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    fontSizeLabelButtom="14px"
                  />
                </div>
                <div className="input_pos">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_standard_text_max_char
                        // placeholder="ร้านค้า"
                        flexStyle="flex-end"
                        disabled
                        {...field}
                      // errors={errors.name?.message}
                      />
                    )}
                    name="shope"
                    defaultValue=""
                  />
                </div>
              </div> */}
              <div className="block_dual">
                <div className="label_pos center">
                  <Typographys.TopBottom
                    theme_left_align
                    label="ชื่อแบรนด์"
                    labelBottom="Brand Name"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    fontSizeLabelButtom="14px"
                  />
                </div>
                <div className="input_pos">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_standard_text_max_char
                        maxLength={40}
                        placeholder="หัวข้อ"
                        flexStyle="flex-end"
                        {...field}
                        errors={errors.name?.message}
                      />
                    )}
                    name="name"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="block_dual">
                <div className="label_pos pt12">
                  <Typographys.TopBottom
                    theme_left_align
                    label="คำอธิบาย"
                    labelBottom="Description"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    fontSizeLabelButtom="14px"
                  />
                </div>
                <div className="input_pos">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextArea
                        theme_standard_text_area_reward
                        maxLength={120}
                        placeholder="คำอธิบาย"
                        height="190px"
                        flexStyle="flex-end"
                        {...field}
                        errors={errors.description?.message}
                      />
                    )}
                    name="description"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="block_dual mb8">
                <div className="label_pos">
                  <Typographys.TopBottom
                    theme_left_align
                    label="ระยะเวลา"
                    labelBottom="Reward Period"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    fontSizeLabelButtom="14px"
                  />
                </div>
                <div className="input_drop">
                  <div className="left_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDropDownFilter
                          theme_reward_add
                          iconSvgClose={<Icons.RectArrowDown />}
                          iconSvgOpen={<Icons.RectArrowUp />}
                          placeholder={`${moment().format("D MMMM")} ${
                            moment().year() + 543
                          }`}
                          options={RENDER_MONTH_SELECT(addDate).map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))}
                          fontSize="16px"
                          {...field}
                        />
                      )}
                      name="begin_date"
                      defaultValue={{
                        label: `${moment().format("D MMMM")} ${
                          moment().year() + 543
                        }`,
                        value: `${moment().format("YYYY-MM-DD")}`,
                      }}
                    />
                  </div>
                  <div className="between_label">
                    <div>ถึง</div>
                    <div className="small">To</div>
                  </div>
                  <div className="right_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDropDownFilter
                          theme_reward_add
                          iconSvgClose={<Icons.RectArrowDown />}
                          iconSvgOpen={<Icons.RectArrowUp />}
                          placeholder={`${moment()
                            .add(addDate, "days")
                            .format("D MMMM")} ${
                            moment().add(addDate, "days").year() + 543
                          }`}
                          options={RENDER_MONTH_SELECT(addDate).map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))}
                          fontSize="16px"
                          {...field}
                        />
                      )}
                      name="end_date"
                      defaultValue={{
                        label: `${moment()
                          .add(addDate, "days")
                          .format("D MMMM")} ${
                          moment().add(addDate, "days").year() + 543
                        }`,
                        value: `${moment()
                          .add(addDate, "days")
                          .format("YYYY-MM-DD")}`,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="block_dual mb21">
                <div className="label_pos pt12">
                  <Typographys.TopBottom
                    theme_left_align
                    label="เงื่อนไขรางวัล"
                    labelBottom="Reward Term"
                    fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                    fontSizeLabelButtom="14px"
                  />
                </div>
                <div className="input_pos">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextArea
                        theme_standard_text_area_reward
                        maxLength={1500}
                        placeholder="เงื่อนไขรางวัล"
                        height="460px"
                        flexStyle="flex-end"
                        {...field}
                        errors={errors.term?.message}
                      />
                    )}
                    name="term"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="block_dual flex_end mb0">
                <div className="input_pos pos_end">
                  <div className="btn_layout">
                    {initialValues && (
                      <div className="btn_del">
                        <Buttons.BtnNormal
                          theme_only_border
                          label="ลบ"
                          labelBottom="Delete"
                          disabled={initialValues?.redeemed_count !== 0}
                          borderRadius="8px"
                          backgroundColor={
                            initialValues?.redeemed_count !== 0
                              ? "#C4C4C4 !important"
                              : "#fff"
                          }
                          fontColor={
                            initialValues?.redeemed_count !== 0
                              ? "#989898 !important"
                              : "#FF1D00"
                          }
                          fontFamily="noto_sans_bold, noto_sans_thai_bold"
                          borderColor={
                            initialValues?.redeemed_count !== 0
                              ? "#C4C4C4 !important"
                              : "#FF1D00"
                          }
                          fontSize="16px"
                          svg_front={
                            <Icons.RewardDel
                              color={
                                initialValues?.redeemed_count !== 0
                                  ? "#989898"
                                  : "#EC5E49"
                              }
                            />
                          }
                          onClick={handleDelete}
                        />
                      </div>
                    )}
                    <div className="btn_submit">
                      <Buttons.BtnNormal
                        theme_only_border_blue
                        label={
                          initialValues ? "บันทึกการแก้ไข" : "เพิ่มรางวัลใหม่"
                        }
                        svg_front={
                          <Icons.RewardEdit
                            color={
                              !isDirty || !isValid || disabled
                                ? "#989898"
                                : "#002366"
                            }
                          />
                        }
                        labelBottom={
                          initialValues ? "Save Edit" : "Add Rewards"
                        }
                        type="submit"
                        disabled={!isDirty || !isValid || disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Blocks.Box>
          </div>
        </div>
      </form>
    </AddRewardDetailsFormStyled>
  );
};

AddRewardDetailsForm.propTypes = {};

export default AddRewardDetailsForm;

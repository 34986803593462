import styled from 'styled-components';

export const RadioStyled = styled.div`
  display: flex;
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .radio_input_item {
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    color: ${({ theme }) => theme.COLORS.GRAY_26};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    .group_show_radio {
      display: flex;
      align-items: flex-end;
      .radio_input {
        margin: 0;
        cursor: pointer;
      }
      .radio_label {
        cursor: pointer;
        background: blue;
        .hText {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_26};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        }
        .sText {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_26};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        }
      }
    }
    .radio_sub_detial {
      margin-left: 21px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_26};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    // display: inline-block;
    width: 13px;
    height: 13px;
    // padding: 2.5px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.COLORS.GRAY_26 : theme.COLORS.GRAY_23};
    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 50%;
  }
  
  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: ${({ theme, disabled }) => disabled ? theme.COLORS.GRAY_26 : theme.COLORS.PURPLE_1};
    padding: 2.5px;
  }
  
  /* optional styles, I'm using this for centering radiobuttons */
  .flex {
    display: flex;
    align-items: center;
  }

  .theme_standard_radio {
    width: 100%;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
    row-gap:  ${({ rowGap }) => rowGap ? rowGap : '8px'};
    column-gap: 28px;
    .radio_input_item {
      .group_show_radio {
       
      }
      .radio_label {
        cursor: pointer;
        .hText {
          // color: ${({ theme }) => theme.COLORS.BLUE_13};
          // font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          // font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
        }
        .sText {
          // color: ${({ theme }) => theme.COLORS.GRAY_26};
          // font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        }
      }
    }
  }

  .theme_column {
    width: 100%;
    display: flex;
    flex-direction: ${({ flexDirection }) =>
      flexDirection ? flexDirection : "row"};
    row-gap: ${({ rowGap }) => (rowGap ? rowGap : "8px")};
    column-gap: 40px;
    .radio_input_item {
      .group_show_radio {
      }
      .radio_label {
        cursor: pointer;
        flex: 1;
        .hText {
          white-space: nowrap;
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        }
        .sText {
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        }
      }
    }
    input[type="radio"] {
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      // display: inline-block;
      width: 18px;
      height: 18px;
      // padding: 2.5px;
      /* background-color only for content */
      background-clip: content-box;
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_35 : theme.COLORS.BLUE_28};
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_10 : theme.COLORS.WHITE_1};
      border-radius: 50%;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:checked {
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_35 : theme.COLORS.BLUE_28};
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_10 : theme.COLORS.BLUE_28};
      padding: 2.5px;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:disabled {
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_35};
      background-color: ${({ theme }) => theme.COLORS.GRAY_10};
      padding: 2.5px;
    }
  }

  .theme_standard_green {
    width: 100%;
    display: flex;
    flex-direction: ${({ flexDirection }) =>
      flexDirection ? flexDirection : "column"};
    row-gap: ${({ rowGap }) => (rowGap ? rowGap : "8px")};
    column-gap: 28px;
    .radio_input_item {
      .group_show_radio {
      }
      .radio_label {
        cursor: pointer;
        .hText {
          white-space: nowrap;
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        .sText {
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        }
      }
    }
    input[type="radio"] {
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      // display: inline-block;
      width: 22px;
      height: 22px;
      // padding: 2.5px;
      /* background-color only for content */
      background-clip: content-box;
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_26 : theme.COLORS.BLUE_28};
      background-color: ${({ theme }) => theme.COLORS.WHITE_1};
      border-radius: 50%;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:checked {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_26 : theme.COLORS.BLUE_28};
      padding: 2.5px;
    }
  }
`;

import styled from 'styled-components'

export const ShowGenderStyled = styled.div`
  .theme_gender_male {
    display: flex;
    align-items: center;
    font-family: noto_sans_thai_regular, noto_sans_regular;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    color: #003C58;
    .svg_show {
      margin-right: 8px;
      display: flex;
    }
    .show_label {
      .labe_bottom {
        color:  ${({ theme }) => theme.COLORS.GRAY_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
    }
  }
  .theme_gender_female {
    display: flex;
    align-items: center;
    font-family: noto_sans_thai_regular, noto_sans_regular;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    color: #DD2646;
    .svg_show {
      margin-right: 8px;
      display: flex;
    }
    .show_label {
      .labe_bottom {
        color:  ${({ theme }) => theme.COLORS.GRAY_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
    }
  }
`

import styled from 'styled-components'

export const BarPercentStyled = styled.div`
  .theme_standard_bar_percent {
    display: flex;
    height: 36px;
    background: ${({ theme }) => theme.COLORS.RED_2};
    border-radius: 18px;
    overflow: hidden;
    .left_bar {
      background: ${({ theme }) => theme.COLORS.BLUE_2};
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }
    .right_bar {

    }
  }
  .theme_normal_bar_percent {
    display: flex;
    height: 36px;
    background: ${({ theme }) => theme.COLORS.GRAY_5}; 
    border-radius: 40px;
    overflow: hidden;
    .left_bar {
      background: ${({ theme }) => theme.COLORS.BLUE_21};
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;
      &.blank {
        background: none;
        .value_percent {
          font-family: noto_sans_thai_bold, noto_sans_bold;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          color:  ${({ theme }) => theme.COLORS.WHITE_1};
          display: flex;
          justify-content: center;
        }
      }
      .value_percent {
        font-family: noto_sans_thai_bold, noto_sans_bold;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color:  ${({ theme }) => theme.COLORS.PURPLE_1};
        display: flex;
        justify-content: center;
      }
    }
    .right_bar {

    }
  }
`

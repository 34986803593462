import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ContainerStyled } from './styled';

const Container = ({ theme_con_standard, children }) => {
  const customClass = cx({
    theme_con_standard: theme_con_standard,
  });
  return (
    <ContainerStyled>
      <div className={customClass}>{children}</div>
    </ContainerStyled>
  );
};

Container.propTypes = {};

export default Container;

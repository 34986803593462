import React from 'react';
import { CreateAccountContainerStyled } from './styled';
import { Tabs, Forms, Loading, Blocks, Buttons, Icons } from 'components';
import { ROUTE_PATH } from 'utils/constants';
import { adminService } from 'apiServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class CreateAccountContainer extends React.Component {
  state = {
    isLoading: true,
    isActionDelete: false,
    titleShow: {
      th: 'สร้างบัญชี',
      en: 'Create Account',
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = () => {
    if (this.props.match.params.id !== 'create') {
      this.fetchDataEdit();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_ACCOUNT);
  };

  fetchDataEdit = async () => {
    let res = await adminService.GET_ADMIN_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
        titleShow: {
          th: 'บัญชีของคุณ',
          en: 'Your Account',
        },
      });
    }
  };

  onSubmit = async (values, callback) => {
    let params = {
      ...values,
      role: values.role.value,
    };
    let res =
      this.props.match.params.id === 'create'
        ? await adminService.POST_ADMIN_DDC(params)
        : await adminService.PATCH_ADMIN(this.props.match.params.id, params);

    if (res && res.success) {
      toast.success('สร้างสำเร็จ');
      this.handleBackClick();
    } else {
      callback && callback(res.error);
    }
  };

  handleDelete = async () => {
    let res = await adminService.DELETE_ADMIN(this.props.match.params.id);
    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.handleBackClick();
    } else {
      toast.error('ลบไม่สำเร็จ');
    }
  };

  handlePopup = () => {
    this.setState({
      isActionDelete: true,
      isLoading: true,
      titleShow: {
        th: 'ลบบัญชี',
        en: 'Delete Account',
      },
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  };

  handleClickCloseModal = () => {
    this.setState({
      isLoading: true,
      isActionDelete: false,
    });

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  };

  render() {
    const { isLoading, initialValues, isActionDelete, titleShow } = this.state;
    // const { authenRedux } = this.props;

    return (
      <CreateAccountContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title={titleShow.th}
                  subTitle={titleShow.en}
                  fontSize="32px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#013D59"
                  colorBottom="#9E9E9E"
                  margin_right="19px"
                  lineHeight="1.3"
                  typeBlank
                  onClick={this.handleBackClick}
                />
              </div>
              {isActionDelete ? (
                <div className="layer_delete">
                  <div className="label_del">
                    คุณต้องการลบบัญชีของคุณใช่หรือไม่ ?
                  </div>
                  <div className="btn_action">
                    <div className="btn_body">
                      <Buttons.BtnRoundShadow
                        theme_btn_round_shadow_green_2
                        label="ยืนยัน"
                        svg={<Icons.Check />}
                        fontSize="20px"
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                        imgWidth="26px"
                        imgHeight="26px"
                        padding="10px 24px"
                        onClick={this.handleDelete}
                      />
                    </div>
                    <div className="btn_body">
                      <Buttons.BtnRoundShadow
                        theme_btn_round_shadow_orange_2
                        label="ยกเลิก"
                        svg={<Icons.Wrong />}
                        fontSize="20px"
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                        imgWidth="29px"
                        imgHeight="29px"
                        padding="10px 24px"
                        onClick={this.handleClickCloseModal}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="layer_two">
                  <Forms.CreateAccountForm
                    onSubmit={this.onSubmit}
                    handleDelete={this.handlePopup}
                    initialValues={initialValues}
                    RoleOption={[
                      {
                        value: 'ddc_admin',
                        label: 'Admin',
                      },
                      {
                        value: 'ddc_agent',
                        label: 'Agent',
                      },
                    ]}
                  />
                </div>
              )}
            </Blocks.Container>
          </>
        )}
      </CreateAccountContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountContainer);

import BoxIconTitleSub from "./BoxIconTitleSub";
import BoxIntroduce from "./BoxIntroduce";
import ScoreBottom from "./ScoreBottom";
import MeanRate from "./MeanRate";
import PreviewInput from "./PreviewInput";
import NewestReward from "./NewestReward";
import Popular from "./Popular";
import BoxImage from "./BoxImage";
import ShowIconWellbeing from "./ShowIconWellbeing";
import PreviewMedia from "./PreviewMedia";

const Export = {
  PreviewMedia,
  BoxIconTitleSub,
  BoxIntroduce,
  ScoreBottom,
  MeanRate,
  PreviewInput,
  NewestReward,
  Popular,
  BoxImage,
  ShowIconWellbeing,
};

export default Export;

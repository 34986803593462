import { MiniMissionDetailStyled } from './styled';
import { Blocks, Label } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import React, { useState, useEffect } from 'react';

const MiniMissionDetail = ({ dataMission }) => {
  const [_labelShow, setLabelShow] = useState({ labelTh: '', labelEn: '' });

  useEffect(() => {
    if (dataMission) {
      switch (dataMission.category) {
        case 'step':
          setLabelShow({
            labelTh: 'ก้าวสะสม',
            labelEn: 'Current Step',
            target_amount: dataMission.target_amount,
            unit: 'ก้าว',
            // icon: <Icons.Run />,
            // icon: "/assets/images/logo/run.png",
            icon: "/assets/images/logo/nin_step.svg",
            theme: 'run'
          });
          break;

        case 'mission_eye':
          setLabelShow({
            labelTh: 'ผ่อนคลายสายตาสะสม',
            labelEn: 'Rest your eye cumulative amount',
            target_amount: dataMission.target_amount,
            unit: 'ครั้ง',
            // icon: <Icons.Kcal width='80%' height='80%' />,
            // icon: "/assets/images/logo/rest.png",
            icon: "/assets/images/logo/nin_eyes.svg",
            theme: 'rest'
          });

          break;
        case 'mission_move':
          setLabelShow({
            labelTh: 'ขยับร่างกายสะสม',
            labelEn: 'Body cumulative amount',
            target_amount: dataMission.target_amount,
            unit: 'ครั้ง',
            // icon: <Icons.Kcal width='80%' height='80%' />,
            // icon: "/assets/images/logo/move.png",
            icon: "/assets/images/logo/nin_move.svg",
            theme: 'move'
          });

          break;

        default:
          break;
      }
    }
  }, [dataMission]);

  return (
    <MiniMissionDetailStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="32px"
        paddingBottom="46px"
        paddingLeft="16px"
      >
        <div className="container_mini">
          <div className="top_main_mini">
            <div className="top_main_mini_left">
              <Label.TopBottom
                theme_left_align
                label="รายละเอียดภารกิจ"
                labelBottom="Mission details"
                fontSize="16px"
                fontSizeLabelButtom="14px"
              />
            </div>
          </div>
          <div className="bottom_main_mini">
            <div className="bottom_main_mini_left">
              {dataMission && _labelShow.icon}
            </div>
            <div className="bottom_main_mini_right">
              <div className="group_label">
                <Label.TopBottom
                  theme_left_align
                  label="เป้าหมาย"
                  labelBottom="Target"
                  fontSize="16px"
                  fontSizeLabelButtom="14px"
                />
                <div>
                  {dataMission &&
                    `${dataMission.target_amount &&
                    RenderCommaMoney(dataMission.target_amount.toFixed(0))
                    } ${_labelShow.unit}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Blocks.Box>
    </MiniMissionDetailStyled>
  );
};

export default MiniMissionDetail;

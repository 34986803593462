import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnNumberStyled } from './styled';
import { Icons } from 'components';

const BtnNumber = ({ theme_standard_btn_number, maxCount, onClick, label }) => {
  const customClass = cx({
    theme_standard_btn_number: theme_standard_btn_number,
  });

  const [count, setCount] = useState(1);

  const setCountNumber = (num) => {
    if (num === -1) {
      onClick && onClick(count - 1);
      setCount(count - 1);
    } else {
      onClick && onClick(count + 1);
      setCount(count + 1);
    }
  };

  return (
    <BtnNumberStyled>
      <div className={customClass}>
        <div className="svg_f" onClick={() => setCountNumber(-1)}>
          {count > 1 && <Icons.Arrow  color='#43519C'/>}
        </div>
        <div className="label">{`ครั้งที่ ${label}`}</div>
        <div className="svg_b" onClick={() => setCountNumber(1)}>
          {count < maxCount && <Icons.Arrow2 color='#43519C' />}
        </div>
      </div>
    </BtnNumberStyled>
  );
};

BtnNumber.propTypes = {};

export default BtnNumber;

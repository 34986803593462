import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { HeadTabStyled } from "./styled";
import { useHistory } from "react-router-dom";
import { Buttons, Icons, Label } from "components";
import moment from "moment";
import "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";

const HeadTab = ({
  theme_standard_head_tab,
  theme_standard_quizz,
  theme_only_title,
  title,
  subTitle,
  fontSize,
  fontFamilyTop,
  fontFamilyBottom,
  fontColor,
  colorBottom,
  fontSizeLabelButtom,
  lineHeight,
  margin_right,
  typeDate,
  typeButton,
  typeButtonQuizz,
  typeBlank,
  typeExchangeReward,
  showButtonDelete,
  type,
  svg,
  onClick,
  handleBackCustom,
  handleClickButton,
  dataDate,
  labelButtonTop,
  labelButtonBottom,
}) => {
  const customClass = cx({
    theme_standard_head_tab: theme_standard_head_tab,
    theme_only_title: theme_only_title,
    theme_standard_quizz: theme_standard_quizz,
  });

  let history = useHistory();

  const handleClickBack = () => {
    if (handleBackCustom) {
      handleBackCustom();
    } else {
      history.goBack();
    }
  };

  return (
    <HeadTabStyled
      fontSize={fontSize}
      fontFamilyTop={fontFamilyTop}
      fontFamilyBottom={fontFamilyBottom}
      fontColor={fontColor}
      colorBottom={colorBottom}
      fontSizeLabelButtom={fontSizeLabelButtom}
      lineHeight={lineHeight}
      margin_right={margin_right}
    >
      <div className={customClass}>
        <div className="box1">
          <div className="btn_link" onClick={onClick}>
            <Buttons.BtnLink
              theme_standard_btn_link
              svg={<Icons.ArrowLeft />}
              label="ย้อนกลับ"
              onClick={handleClickBack}
            />
          </div>
        </div>
        <div className="box2">
          {svg && <div className="show_svg">{svg}</div>}
          <div className="group_label">
            <div className="top_title">{title}</div>
            <div className="bottom_title">{subTitle}</div>
          </div>
        </div>
        {typeDate && dataDate && (
          <div className="date_layer">
            <Label.TopBottom
              theme_left_align
              label="อัพเดตล่าสุด"
              labelBottom="Lasted Update"
              fontSizeLabelButtom="12px"
              fontSize="12px"
            />
            <div className="date_wrap">
              <Label.ShowDate
                theme_red_show_date
                date={moment(dataDate).tz("Asia/Bangkok").format("D")}
                month1={
                  RENDER_MONTH(moment(dataDate).tz("Asia/Bangkok").format("MM"))
                    .th
                }
                month2={
                  RENDER_MONTH(moment(dataDate).tz("Asia/Bangkok").format("MM"))
                    .en
                }
                year={moment(dataDate).tz("Asia/Bangkok").year() + 543}
                time={`${moment(dataDate)
                  .tz("Asia/Bangkok")
                  .format("HH:mm")} น.`}
              />
            </div>
          </div>
        )}
        {typeButton && (
          <div className="btn_layer">
            <div className="btn_body">
              <Buttons.BtnNormal
                theme_standard_btn_normal_red
                type={type}
                label={labelButtonTop ? labelButtonTop : "สร้าง"}
                labelBottom={labelButtonBottom ? labelButtonBottom : "Create"}
                fontSize="20px"
                fontSizeLabelButtom="16px"
                fontFamilyBottom="noto_sans_thai_medium, noto_sans_regular"
              />
            </div>
          </div>
        )}
        {typeButtonQuizz && (
          <div className="btn_layer_quizz">
            <div className="show_label_quizz">
              <div className="top_show_quizz">
                <div className="show_text">คุณจะสามารถสร้าง</div>
                <div className="svg_show">
                  <Icons.Quiz4 width="59" height="27" />
                </div>
                <div className="show_text">ได้</div>
              </div>
              <div className="top_show_quizz">หลังจากที่สร้างบทความแล้ว</div>
            </div>
            <div className="btn_body">
              <Buttons.BtnNormal
                theme_standard_btn_normal_red
                type={type}
                label={labelButtonTop ? labelButtonTop : "สร้าง"}
                labelBottom={labelButtonBottom ? labelButtonBottom : "Create"}
                fontSize="20px"
                fontSizeLabelButtom="16px"
                fontFamilyBottom="noto_sans_thai_medium, noto_sans_regular"
              />
            </div>
          </div>
        )}
        {typeExchangeReward && (
          <div className="btn_layer">
            <div className="btn_body">
              <Buttons.BtnNormal
                theme_standard_btn_normal_red
                onClick={handleClickButton}
                label="ประวัติการแลกของรางวัล"
                fontSize="18px"
                fontSizeLabelButtom="16px"
                fontFamilyBottom="noto_sans_thai_medium, noto_sans_regular"
                borderRadius="6px"
              />
            </div>
          </div>
        )}
        {showButtonDelete && (
          <div className="btn_layer">
            <div className="btn_body">
              <Buttons.BtnNormal
                theme_standard_btn_normal_red_2
                label="ลบ"
                fontSize="18px"
                onClick={handleClickButton}
                svgFront={<Icons.Delete3 />}
              />
            </div>
          </div>
        )}
        {typeBlank && <div className="blank" />}
      </div>
    </HeadTabStyled>
  );
};

HeadTab.propTypes = {};

export default HeadTab;

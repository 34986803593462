import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types';
import { BtnRoundShadowStyled } from "./styled";

const BtnRoundShadow = ({
  theme_btn_round_shadow_pink,
  theme_btn_round_shadow_green,
  theme_btn_round_shadow_orange,
  theme_btn_round_shadow_white,
  theme_btn_round_shadow_green_2,
  theme_btn_round_shadow_orange_2,
  theme_btn_round_shadow_green_3,
  theme_btn_round_shadow_orange_3,
  theme_btn_blue,
  label,
  labelBottom,
  svg,
  fontSize,
  imgWidth,
  imgHeight,
  fontFamily,
  onClick,
  padding,
  disabled,
  type = "button",
}) => {
  const customClass = cx({
    theme_btn_round_shadow_pink: theme_btn_round_shadow_pink,
    theme_btn_round_shadow_green: theme_btn_round_shadow_green,
    theme_btn_round_shadow_orange: theme_btn_round_shadow_orange,
    theme_btn_round_shadow_white: theme_btn_round_shadow_white,
    theme_btn_round_shadow_green_2: theme_btn_round_shadow_green_2,
    theme_btn_round_shadow_orange_2: theme_btn_round_shadow_orange_2,
    theme_btn_round_shadow_green_3: theme_btn_round_shadow_green_3,
    theme_btn_round_shadow_orange_3: theme_btn_round_shadow_orange_3,
    theme_btn_blue: theme_btn_blue,
  });
  return (
    <BtnRoundShadowStyled
      fontSize={fontSize}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      fontFamily={fontFamily}
      padding={padding}
      disabled={disabled}
    >
      <div className={customClass}>
        <button
          className="body_btn"
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {svg && <div className="svg_show">{svg}</div>}
          <div className="show_label">{label}</div>
        </button>
        {labelBottom && <div className="label_bottom">{labelBottom}</div>}
      </div>
    </BtnRoundShadowStyled>
  );
};

BtnRoundShadow.propTypes = {};

export default BtnRoundShadow;

import styled from 'styled-components'

export const HeadTabStyled = styled.div`
  width: 100%;
  .theme_standard_head_tab {
    display: flex;
    .box1 {
      display: flex;
      align-items: flex-end;
      padding-top: 30px;
      width: 33.33%;
      .btn_link {
        width: 20%;
      }
    }
    .box2 {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      .show_svg {
        display: flex;
        margin-right: ${(props) => (props.margin_right ? props.margin_right : '3px')};
      }
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'unset')};
        .top_title {
          font-size: ${(props) => (props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S32)};
          font-family: ${(props) => (props.fontFamilyTop ? props.fontFamilyTop : 'noto_sans_thai_bold, noto_sans_bold')};
          color: ${(props) => (props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.PURPLE_1)};
          text-align: center;
        }
        .bottom_title {
          font-family: ${(props) => (props.fontFamilyBottom ? props.fontFamilyBottom : 'noto_sans_thai_medium, noto_sans_regular')};
          font-size: ${(props) => (props.fontSizeLabelButtom ? props.fontSizeLabelButtom : ({ theme }) => theme.FONT.SIZE.S18)};
          color: ${(props) => (props.colorBottom ? props.colorBottom : ({ theme }) => theme.COLORS.GRAY_1)};
        }
      }
    }
    .date_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .date_wrap {
        margin-left: 14px;
      }
    }
    .btn_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .btn_body {
        width: 50%;
      }
    }
    .btn_layer_quizz {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      width: 33.33%;
      .show_label_quizz {
        margin-bottom: 16px;
        padding: 15px;
        border-radius: 8px;
        border: 1px solid #989898;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        color:  ${({ theme }) => theme.COLORS.GRAY_1};
        display: flex;
        flex-direction: column;
        align-items: center;
        background: blue;
        width: 100%;
        box-sizing: border-box;
        width: calc(100% - 29px);
        .show_text {
          text-align: center;
        }
        .top_show_quizz {
          display: flex;
          justify-content: center;
          text-align: center;
          .svg_show {
            margin: 0px 5px;
          }
        }
      }
      .btn_body {
        width: calc(100% - 30px);
        box-sizing: border-box;
      }
    }
    .blank {
      width: 33.33%;
    }
  }
  .theme_standard_quizz {
    display: flex;
    .box1 {
      display: flex;
      align-items: top;
      width: 33.33%;
      .btn_link {
        width: 20%;
      }
    }
    .box2 {
      width: 33.33%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .show_svg {
        display: flex;
        margin-right: ${(props) => (props.margin_right ? props.margin_right : '3px')};
      }
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'unset')};
        .top_title {
          font-size: ${(props) => (props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S32)};
          font-family: ${(props) => (props.fontFamilyTop ? props.fontFamilyTop : 'noto_sans_thai_bold, noto_sans_bold')};
          color: ${(props) => (props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.PURPLE_1)};
          text-align: center;
        }
        .bottom_title {
          font-family: ${(props) => (props.fontFamilyBottom ? props.fontFamilyBottom : 'noto_sans_thai_medium, noto_sans_regular')};
          font-size: ${(props) => (props.fontSizeLabelButtom ? props.fontSizeLabelButtom : ({ theme }) => theme.FONT.SIZE.S18)};
          color: ${(props) => (props.colorBottom ? props.colorBottom : ({ theme }) => theme.COLORS.GRAY_1)};
        }
      }
    }
    .date_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .date_wrap {
        margin-left: 14px;
      }
    }
    .btn_layer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      .btn_body {
        width: 50%;
      }
    }
    .btn_layer_quizz {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      width: 33.33%;
      padding-top: 20px;
      .show_label_quizz {
        margin-bottom: 16px;
        padding: 15px;
        border-radius: 8px;
        border: 1px solid #989898;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_medium, noto_sans_regular;
        color:  ${({ theme }) => theme.COLORS.GRAY_1};
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        width: calc(100% - 29px);
        .show_text {
          text-align: center;
        }
        .top_show_quizz {
          display: flex;
          justify-content: center;
          text-align: center;
          .svg_show {
            margin: 0px 5px;
          }
        }
      }
      .btn_body {
        width: calc(100% - 30px);
        box-sizing: border-box;
      }
    }
    .blank {
      width: 33.33%;
    }
  }
  .theme_only_title {
    display: flex;
    .box1 {
      display: none;
    }
    .box2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .show_svg {
        display: flex;
        margin-right: ${(props) => (props.margin_right ? props.margin_right : '3px')};
      }
      .group_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'unset')};
        .top_title {
          font-size: ${(props) => (props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S32)};
          font-family: ${(props) => (props.fontFamilyTop ? props.fontFamilyTop : 'noto_sans_thai_bold, noto_sans_bold')};
          color: ${(props) => (props.fontColor ? props.fontColor : ({ theme }) => theme.COLORS.PURPLE_1)};
          text-align: center;
        }
        .bottom_title {
          font-family: ${(props) => (props.fontFamilyBottom ? props.fontFamilyBottom : 'noto_sans_thai_medium, noto_sans_regular')};
          font-size: ${(props) => (props.fontSizeLabelButtom ? props.fontSizeLabelButtom : ({ theme }) => theme.FONT.SIZE.S18)};
          color: ${(props) => (props.colorBottom ? props.colorBottom : ({ theme }) => theme.COLORS.GRAY_1)};
        }
      }
    }
  } 

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard_head_tab {

    } 
    .theme_standard_quizz {

    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_standard_head_tab {
      .btn_layer_quizz {
        .show_label_quizz {
          width: calc(100% - 2px);
        }
        .btn_body {
          width: calc(100% - 2px);
        }
      }
      .box2 {
        .group_label {
          .top_title {
            font-size: ${({ theme }) => theme.FONT.SIZE.S30};
          }
        }
      }
    }

    .theme_standard_quizz {
      .btn_layer_quizz {
        .show_label_quizz {
          width: calc(100% - 2px);
        }
        .btn_body {
          width: calc(100% - 2px);
        }
      }
      .box2 {
        .group_label {
          .top_title {
            font-size: ${({ theme }) => theme.FONT.SIZE.S30};
          }
        }
      }
    }
  }
`

import styled from 'styled-components'

export const AveragePostPerMonthStyled = styled.div`
.top_current {
  display: flex;
  justify-content: space-between;
  .left_top {
    display: flex;
    align-items: center;
    .label_show {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.GRAY_24};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      .title_show {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
    }
  }
  .right_top {
    width: 164px;
  }
}
.graph_current {
  margin-top: 11px;
}

.tool_box {
  background: #FE706F;
  padding: 7px;
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 7px;
}
`

import styled from "styled-components";

export const TableRoleStyled = styled.div`
  .theme_standard_table_role {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
    .header_table {
      display: flex;
      justify-content: space-between;
      padding: 6px 30px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      .header_col_1 {
        width: 59%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .sub_col_1 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
      .header_col_2 {
        width: 25%;
        display: flex;
        flex-direction: column;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .sub_col_2 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
      .header_col_3 {
        width: 15%;
        display: flex;
        flex-direction: column;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .sub_col_3 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
    }
    .row_table {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
      display: flex;
      cursor: pointer;
      // .tab_color {
      //   background: ${({ theme }) => theme.COLORS.BLUE_2};
      //   width: 3px;
      //   &.pink {
      //     background: ${({ theme }) => theme.COLORS.PINK_13};
      //   }
      // }
      .group_row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 12.8px 27px;
        .row_col_1 {
          width: 59%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_8};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .row_col_2 {
          width: 25%;
          display: flex;
          flex-direction: column;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_9};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .row_col_3 {
          width: 15%;
          display: flex;
          flex-direction: column;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_9};
        }
      }
      &:hover {
        background: ${({ theme }) => theme.COLORS.BLUE_11}80;
      }
    }
    .empty {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.GRAY_24};
      text-align: center;
      padding: 10.4px 10px;
    }
  }
  .pagi_layout {
    margin-top: ${({ marginTopPagination }) =>
      marginTopPagination ? marginTopPagination : "32px"};
  }
`;

import { ROUTE_PATH } from "utils/constants";
import { Icons } from "components";
import {
  getObjectKey,
  getObjectKey2,
  getObjectKey3,
} from "utils/functions/getObjectKey";

import MainDashboardContainer from "containers/CorporateSystem/MainDashboardContainer";
import WellBeingContainer from "containers/CorporateSystem/WellBeingContainer";
import BMIContainer from "containers/CorporateSystem/BMIContainer";
import MSDContainer from "containers/CorporateSystem/MSDContainer";
import StepContainer from "containers/CorporateSystem/StepContainer";
import HealthCheckContainer from "containers/CorporateSystem/HealthCheckContainer";

import BackOfficeContainer from "containers/CorporateSystem/BackOfficeContainer";

import LuckSpinContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/LuckSpinContainer";
import SpinGameCreateEditContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/LuckSpinContainer/Menus/SpinGameCreateEditContainer";
import RewardManagementCreateEditContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/LuckSpinContainer/Menus/RewardManagementCreateEditContainer";

import NewManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/NewManagementContainer";
import NewLogContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/NewLogContainer";
import DepartmentDetailContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/DepartmentDetailContainer";
import DepartmentCreateEditContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/NewManagementContainer/Menus/DepartmentCreateEditContainer";

import RewardManagmentContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer";
import RewardSearchContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardSearchContainer";
import RewardHistoryContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardHistoryContainer";
import RewardExchangeContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardExchangeContainer";
import RewardExchangeHistoryContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardExchangeHistoryContainer";
import RewardDetailsContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardDetailsContainer";
import RewardAddDetailsContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus/RewardAddDetailsContainer";

import AccountManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/AccountManagementContainer";
import CreateAccountContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/AccountManagementContainer/Menus/CreateAccountContainer";
import ProfileAccountContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/AccountManagementContainer/Menus/ProfileAccountContainer";

import UserAccountManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/UserAccountManagementContainer";
import UserAccountContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/UserAccountManagementContainer/Menus/UserAccountContainer";
import ManyUserAccountManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/ManyUserAccountManagementContainer";

import HealthyClubContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/HealthyClubContainer";
import HealthyClubCreateEditContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/HealthyClubContainer/Menus/HealthyClubCreateEditContainer";
import HealthyClubDetailContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/HealthyClubContainer/Menus/HealthyClubDetailContainer";
import GroupRoleContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/HealthyClubContainer/Menus/GroupRoleContainer";

import RewardPointContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardPointContainer";

import PointManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/PointManagementContainer";
import LogContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/PointManagementContainer/Menus/LogContainer";

import DepartmentManagementContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/DepartmentManagementContainer";
import DepartmentContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/DepartmentManagementContainer/Menus/DepartmentContainer";

import MissionContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/MissionContainer";
import AddEditMissionContainer from "containers/CorporateSystem/BackOfficeContainer/Menus/MissionContainer/Menus/AddEditMissionContainer";
import { LogContainerUser } from "containers/CorporateSystem/BackOfficeContainer/Menus/UserAccountManagementContainer/Menus";
import { LogContainerAccount } from "containers/CorporateSystem/BackOfficeContainer/Menus/AccountManagementContainer/Menus";
import { LogContainerReward } from "containers/CorporateSystem/BackOfficeContainer/Menus/RewardManagmentContainer/Menus";

const adminRoutes = (authenRedux) => {
  let checkPermissionMain = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_main_view"
  );
  let checkPermissionWellbeing = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_wellbeing_view"
  );
  let checkPermissionBmi = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_bmi_view"
  );
  let checkPermissionMsd = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_msd_view"
  );
  let checkPermissionStep = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_step_view"
  );
  let checkPermissionHelathCheck = getObjectKey(
    authenRedux.admin_role.permissions,
    "stat_checkup_view"
  );

  let checkPermissionReward = getObjectKey(
    authenRedux.admin_role.permissions,
    "reward_manage"
  );
  let checkPermissionContent = getObjectKey2(
    authenRedux.admin_role.permissions,
    "content_manage",
    "content_view"
  );
  let checkPermissionCompanyMissionBackOffice = getObjectKey2(
    authenRedux.admin_role.permissions,
    "company_mission_manage",
    "company_mission_view"
  );
  let checkPermissionEmployee = getObjectKey3(
    authenRedux.admin_role.permissions,
    "employee_manage",
    "employee_view",
    "employee_manage_no_delete"
  );

  let checkPermissionAdmin = getObjectKey3(
    authenRedux.admin_role.permissions,
    "admin_manage",
    "admin_view",
    "admin_manage_no_delete"
  );
  let checkPermissionPoint = getObjectKey2(
    authenRedux.admin_role.permissions,
    "user_coin_manage",
    "user_coin_view"
  );

  let checkPermissionSpin = getObjectKey(
    authenRedux.admin_role.permissions,
    "spin_manage"
  );

  let checkPermissionGroup = getObjectKey(
    authenRedux.admin_role.permissions,
    "group_manage"
  );

  let tempMenu = [];

  if (
    checkPermissionMain &&
    checkPermissionMain.permission_group &&
    checkPermissionMain.permission_group.key === "stat_main"
  ) {
    tempMenu.push(
      {
        key: "administrator",
        path: ROUTE_PATH.ADMINISTARTOR,
        name: "Main",
        exact: true,
        notShowSidebar: true,
        component: MainDashboardContainer,
      },
      {
        key: "main",
        path: ROUTE_PATH.MAIN,
        name: "Main",
        exact: true,
        icon: (active) => <Icons.Main color={active ? "#002366" : "#002366"} />,
        component: MainDashboardContainer,
      }
    );
  }
  if (
    checkPermissionWellbeing &&
    checkPermissionWellbeing.permission_group &&
    checkPermissionWellbeing.permission_group.key === "stat_wellbeing"
  ) {
    tempMenu.push({
      key: "wellbeing",
      path: ROUTE_PATH.WELL_BEING,
      name: "Well being",
      exact: true,
      icon: (active) => (
        <Icons.HeartWell color={active ? "#002366" : "#002366"} />
      ),
      component: WellBeingContainer,
    });
  }
  if (
    checkPermissionBmi &&
    checkPermissionBmi.permission_group &&
    checkPermissionBmi.permission_group.key === "stat_bmi"
  ) {
    tempMenu.push({
      key: "bmi",
      path: ROUTE_PATH.BMI,
      name: "BMI",
      exact: true,
      icon: (active) => (active ? <Icons.BMI2 /> : <Icons.BMI1 />),
      component: BMIContainer,
    });
  }
  if (
    checkPermissionMsd &&
    checkPermissionMsd.permission_group &&
    checkPermissionMsd.permission_group.key === "stat_msd"
  ) {
    tempMenu.push({
      key: "msd",
      path: ROUTE_PATH.MSD,
      name: "MSD",
      exact: true,
      icon: (active) => (active ? <Icons.MSD2 /> : <Icons.MSD />),
      component: MSDContainer,
    });
  }
  if (
    checkPermissionStep &&
    checkPermissionStep.permission_group &&
    checkPermissionStep.permission_group.key === "stat_step"
  ) {
    tempMenu.push({
      key: "step",
      path: ROUTE_PATH.STEP,
      name: "Step",
      exact: true,
      icon: (active) => (active ? <Icons.Step /> : <Icons.Step1 />),
      component: StepContainer,
    });
  }
  if (
    checkPermissionHelathCheck &&
    checkPermissionHelathCheck.permission_group &&
    checkPermissionHelathCheck.permission_group.key === "stat_checkup"
  ) {
    tempMenu.push({
      key: "health_check",
      path: ROUTE_PATH.HEALTH_CHECK,
      name: "Health Check",
      exact: true,
      icon: (active) =>
        active ? <Icons.HealthCheck /> : <Icons.HealthCheck color="#fff" />,
      component: HealthCheckContainer,
    });
  }

  // // show menu back office
  // if (
  //   checkPermissionReward ||
  //   checkPermissionContent ||
  //   checkPermissionCompanyMissionBackOffice ||
  //   checkPermissionEmployee ||
  //   checkPermissionAdmin ||
  //   checkPermissionPoint ||
  //   checkPermissionSpin
  // ) {
  //   tempMenu.push({
  //     key: "backoffice",
  //     path: ROUTE_PATH.BACK_OFFICE,
  //     name: "Back office",
  //     exact: true,
  //     icon: (active) =>
  //       active ? <Icons.Setting2 color="#002366" /> : <Icons.Setting1 />,
  //     component: BackOfficeContainer,
  //   });
  // }

  // menu backoffice
  tempMenu.push({
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE,
    name: "Back office",
    exact: true,
    icon: (active) =>
      active ? <Icons.Setting2 color="#002366" /> : <Icons.Setting1 />,
    component: BackOfficeContainer,
  });

  if (checkPermissionReward) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardManagmentContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_LOG,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: LogContainerReward,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_SEARCH,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardSearchContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_HISTORY,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardHistoryContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE_LIST,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardExchangeContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE_HISTORY_LIST,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardExchangeHistoryContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_PREVIEW_DETAIL_BY_ID,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardDetailsContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardAddDetailsContainer,
      }
    );
  }

  if (checkPermissionContent) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_NEW,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: NewManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_NEW_LOG,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: NewLogContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_PREVIEW_DETAIL_BY_ID,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: DepartmentDetailContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: DepartmentCreateEditContainer,
      }
    );
  }

  if (checkPermissionCompanyMissionBackOffice) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_MISSION,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: MissionContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_MISSION_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: AddEditMissionContainer,
      }
    );
  }

  if (checkPermissionEmployee) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: UserAccountManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: UserAccountContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_DEPARTMENT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: DepartmentManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_DEPARTMENT_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: DepartmentContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_MANY_USER_ACCOUNT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: ManyUserAccountManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_USER_MANAGEMENT_LOG,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: LogContainerUser,
      }
    );
  }

  if (checkPermissionAdmin) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_ACCOUNT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        icon: (active) => <Icons.MenuBackOffice isActive={active} />,
        component: AccountManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: CreateAccountContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_LOG,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: LogContainerAccount,
      }
    );
  }

  if (checkPermissionPoint) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_REWARD_POINT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardPointContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_POINT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: PointManagementContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_POINT_LOG,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: LogContainer,
      }
    );
  }

  if (checkPermissionSpin) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_LUCK_SPIN,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: LuckSpinContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_GAME_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: SpinGameCreateEditContainer,
      },
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_REWARD_MANAGEMENT_CREATE_EDIT,
        name: "Back office",
        exact: true,
        notShowSidebar: true,
        component: RewardManagementCreateEditContainer,
      }
    );
  }

  if (checkPermissionGroup) {
    tempMenu.push(
      {
        key: "backoffice",
        path: ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB,
        name: "HealthyClub",
        exact: true,
        notShowSidebar: true,
        component: HealthyClubContainer,
      },
      {
        key: "backoffice",
        name: "HealthyClub",
        path: ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_CREATE_EDIT,
        notShowSidebar: true,
        component: HealthyClubCreateEditContainer,
      },
      {
        key: "backoffice",
        name: "HealthyClub",
        path: ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_PREVIEW_DETAIL_BY_ID,
        notShowSidebar: true,
        component: HealthyClubDetailContainer,
      },
      {
        key: "backoffice",
        name: "HealthyClub",
        path: ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_GROUP_ROLE_CREATE_EDIT,
        notShowSidebar: true,
        component: GroupRoleContainer,
      }
    );
  }

  // profile edit
  tempMenu.push({
    key: "backoffice",
    path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_PROFILE_BY_ID,
    name: "Back office",
    exact: true,
    notShowSidebar: true,
    component: ProfileAccountContainer,
  });

  // if (
  //   authenRedux &&
  //   authenRedux.admin_role.name.toLowerCase() !== "management"
  // ) {
  //   tempMenu.push({
  //     key: "backoffice",
  //     path: ROUTE_PATH.BACK_OFFICE_ACCOUNT_PROFILE_BY_ID,
  //     name: "Back office",
  //     exact: true,
  //     notShowSidebar: true,
  //     component: ProfileAccountContainer,
  //   });
  // }

  return tempMenu;
};

export default adminRoutes;

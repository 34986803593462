import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnCategroyFillterStyled } from './styled';
import { Label } from 'components';

const BtnCategroyFillter = ({
  theme_standard_category_filter,
  data,
  onClick,
  initialValue,
}) => {
  const customClass = cx({
    theme_standard_category_filter: theme_standard_category_filter,
  });
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    if (initialValue) {
      setSelected(Number(initialValue));
    }
  }, [initialValue]);

  const handleClickTab = (key) => {
    setSelected(key);
  };

  return (
    <BtnCategroyFillterStyled>
      <div className={customClass}>
        <div className="show_label">
          <Label.TopBottom
            theme_left_align
            label="หมวด"
            labelBottom="Category"
            fontSize="14px"
            fontSizeLabelButtom="12px"
          />
        </div>
        {data && (
          <div className="group_btn">
            <div className="top_opt">
              {data &&
                data.map((e, i) => (
                  <div
                    key={i + 1}
                    className={`opt1 ${selected === e.id ? 'active' : ''}`}
                    onClick={() => {
                      handleClickTab(e.id);
                      onClick(e);
                    }}
                  >
                    {e.name_th}
                  </div>
                ))}
            </div>
            <div className="bottom_opt">
              {data &&
                data.map((e, i) => (
                  <div
                    key={i + 1}
                    className={`label1 ${selected === e.id ? 'active' : ''}`}
                  >
                    {e.name_en}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </BtnCategroyFillterStyled>
  );
};

// BtnCategroyFillter.propTypes = {};

export default BtnCategroyFillter;

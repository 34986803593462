import { BoxIntroduceStyled } from "./styled";
import { Blocks } from "components";

const BoxIntroduce = ({ src, titleLabel, label1, label2, label3, label4 }) => {
  return (
    <BoxIntroduceStyled>
      <Blocks.Box theme_standard_box>
        <div className="main_board">
          <div className="main_left">
            <img className="img_left" src={src} alt="img" />
          </div>
          <div className="main_right">
            <div className="right_l">
              <div className="title_right">{titleLabel}</div>
              <div>{label1}</div>
              <div>{label2}</div>
              <div>{label3}</div>
              <div>{label4}</div>
            </div>
            <div className="right_r">
              {/* <Buttons.BtnLanguage theme_standard_language label="TH" /> */}
            </div>
          </div>
        </div>
      </Blocks.Box>
    </BoxIntroduceStyled>
  );
};

export default BoxIntroduce;

import styled from 'styled-components';

export const InputSelectCusIconConfirmStyled = styled.div`
  .theme_standard {
  }
  .theme_language {
    .css-2b097c-container {
      .css-1re46mu-Control {
        border: none;
        .css-g1d714-ValueContainer {
          border: none;
          // display: flex;
          // justify-content: center;
        }
      }
      .css-wfkxvc-menu {
        border: none;
      }
    }
    .op_label_wrap {
      width: calc(100% - 30px);
      // text-align: center;
    }
  }
`;

export const OptionStyled = styled.div`
  display: flex;
  align-item: center;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 8px 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: #f2f9fc;
  }
  .op_icon_wrap {
    width: 40px;
  }
  .op_label_wrap {

  }
`;

export default function Camera() {
  return (
    <svg id="_025-camera" data-name="025-camera" xmlns="http://www.w3.org/2000/svg" width="37.943" height="31.125" viewBox="0 0 37.943 31.125">
      <path id="Path_6175" data-name="Path 6175" d="M10.079,202.67A8.836,8.836,0,0,1,13.152,196H0v13.339H13.152A8.836,8.836,0,0,1,10.079,202.67Z" transform="translate(0 -184.884)" fill="#f0211d" />
      <path id="Path_49502" data-name="Path 49502" d="M2.242,0A2.242,2.242,0,1,1,0,2.242,2.242,2.242,0,0,1,2.242,0Z" transform="translate(16.672 15.216)" fill="#fdc566" />
      <path id="Path_6176" data-name="Path 6176" d="M337.6,202.67a8.836,8.836,0,0,1-3.073,6.67H347.68V196H334.528A8.836,8.836,0,0,1,337.6,202.67Z" transform="translate(-309.737 -184.884)" fill="#f0211d" />
      <path id="Path_6177" data-name="Path 6177" d="M0,409.335a1.111,1.111,0,0,0,1.112,1.112h35.72a1.111,1.111,0,0,0,1.112-1.112V406H0Z" transform="translate(0 -379.321)" fill="#fd5e17" />
      <path id="Path_6178" data-name="Path 6178" d="M172.67,209.339a6.67,6.67,0,1,0-6.67-6.67A6.677,6.677,0,0,0,172.67,209.339Zm0-11.116a4.446,4.446,0,1,1-4.446,4.446A4.451,4.451,0,0,1,172.67,198.223Z" transform="translate(-153.698 -184.884)" fill="#fdc566" />
      <path id="Path_6179" data-name="Path 6179" d="M36.831,50.446H31.2V49.335a1.112,1.112,0,0,0-2.223,0v1.112H26.328l-1.916-3.832A1.112,1.112,0,0,0,23.418,46H14.525a1.112,1.112,0,0,0-.994.614l-1.916,3.832H8.967V49.335a1.112,1.112,0,1,0-2.223,0v1.112H1.112A1.111,1.111,0,0,0,0,51.558v3.335H37.943V51.558A1.111,1.111,0,0,0,36.831,50.446ZM21.195,52.67H16.748a1.112,1.112,0,1,1,0-2.223h4.446a1.112,1.112,0,1,1,0,2.223Z" transform="translate(0 -46)" fill="#fd5e17" />
    </svg>

  );
}

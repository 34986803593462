import React from "react";
import { StandardStyled } from "./styled";
import cx from "classnames";

export const Standard = ({
  theme_modal_standard,
  theme_modal_margin_top,
  theme_modal_flex_end,
  children,
  isShow,
  align,
  handleClickCloseModal,
  noClickBackdrop,
}) => {
  const customClass = cx({
    theme_modal_standard: theme_modal_standard,
    theme_modal_flex_end: theme_modal_flex_end,
    theme_modal_margin_top: theme_modal_margin_top,
  });

  return (
    <StandardStyled _isShow={isShow} align={align}>
      <div className={customClass}>
        <div className="modal_body">{children}</div>
        {noClickBackdrop ? (
          <div className="backdrop" />
        ) : (
          <div className="backdrop" onClick={handleClickCloseModal} />
        )}
      </div>
    </StandardStyled>
  );
};

Standard.propTypes = {};

export default Standard;

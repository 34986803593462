import styled from 'styled-components';

export const CreateEditMissionContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 280px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label_empty {
    text-align: center;
    padding-top: 40px;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  .box_show {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .loading {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
`;

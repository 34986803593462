import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputSearch2Styled } from "./styled";
import { Icons } from "components";

const InputSearch2 = ({
  theme_standard_search,
  theme_standard_search_2,
  theme_standard_search_48,
  fontColor,
  placeholder,
  svg,
  onSubmit,
  initialValue,
  label,
  onChangeCustom,
}) => {
  const customClass = cx({
    theme_standard_search: theme_standard_search,
    theme_standard_search_2: theme_standard_search_2,
    theme_standard_search_48:theme_standard_search_48,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (initialValue) {
      _setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e) => {
    _setValue(e.target.value);
    onChangeCustom && onChangeCustom(e.target.value)
  };
  // const handleClickSubmit = () => {
  //   onSubmit(_value);
  // };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit(_value);
    }
  };

  return (
    <InputSearch2Styled fontColor={fontColor}>
      <div className={customClass}>
        {label && <div className="label_show">{label}</div>}
        <div className="line_search">
          <div className="search_layout">
            <input
              className="input_serach"
              value={_value}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <div className="icon" onClick={() => onSubmit(_value)}>
              {svg ? (
                svg
              ) : (
                <Icons.Search2 width="20" height="20" color="#989898" />
              )}
            </div>
          </div>
          {/* <div className="btn_serach">
            <Buttons.BtnNormal
              theme_standard_btn_round_red_bold
              label="ค้นหา"
              fontFamilyBottom="noto_sans_thai_bold, noto_sans_bold;"
              colorBottom="#F0211D"
              fontSize="16px"
              padding="10px"
              borderRadius="4px"
              onClick={handleClickSubmit}
            />
          </div> */}
        </div>
      </div>
    </InputSearch2Styled>
  );
};

InputSearch2.propTypes = {};

export default InputSearch2;

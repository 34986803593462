import styled from 'styled-components'

export const ParticipantsAgeStyled = styled.div`
  .top_current {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    .svg_show {

    }
    .label_show {
      margin-left: 12px;
      font-family: noto_sans_thai_medium, noto_sans_regular;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      
      .sub_title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
  .bottom_current {
    
  }
`

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { BtnShadowStyled } from './styled';

const BtnShadow = ({
  theme_standard_btn_shadow,
  theme_btn_shadow_white,
  label,
  svg,
  fontSize,
  imgWidth,
  imgHeight,
  fontFamily,
  onClick,
}) => {
  const customClass = cx({
    theme_standard_btn_shadow: theme_standard_btn_shadow,
    theme_btn_shadow_white: theme_btn_shadow_white,
  });
  return (
    <BtnShadowStyled
      fontSize={fontSize}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      fontFamily={fontFamily}
      onClick={onClick}
    >
      <div className={customClass}>
        {svg && <div className="svg_show">{svg}</div>}
        <div className="show_label">{label}</div>
      </div>
    </BtnShadowStyled>
  );
};

BtnShadow.propTypes = {};

export default BtnShadow;

export default function GroupPeople4({ color = '#707070', width = '30.253', height = '31.937' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30.253 31.937">
      <g id="group_1_" data-name="group (1)" transform="translate(0 0)">
        <g id="Group_15975" data-name="Group 15975" transform="translate(0 0)">
          <g id="Group_15974" data-name="Group 15974" transform="translate(0)">
            <path id="Path_20363" data-name="Path 20363" d="M204.167,6.654a3.327,3.327,0,1,0-3.327-3.327A3.326,3.326,0,0,0,204.167,6.654Z" transform="translate(-189.154)" fill="#707070" />
            <path id="Path_20364" data-name="Path 20364" d="M355.335,27.987a3.327,3.327,0,1,0-3.327-3.327A3.326,3.326,0,0,0,355.335,27.987Z" transform="translate(-330.893 -20.003)" fill="#707070" />
            <path id="Path_20365" data-name="Path 20365" d="M56.668,27.987a3.327,3.327,0,1,0-3.327-3.327A3.326,3.326,0,0,0,56.668,27.987Z" transform="translate(-50.856 -20.003)" fill="#707070" />
            <path id="Path_20366" data-name="Path 20366" d="M353.259,159.068l-.853-6.82a3.332,3.332,0,0,0-3.3-2.914H345.82a3.294,3.294,0,0,0-1.108.2.665.665,0,0,0-.421.8,5.426,5.426,0,0,1,.157.756l.882,7.729a3.593,3.593,0,0,1-.877,2.8,3.321,3.321,0,0,1-.562.488.662.662,0,0,0-.277.588l.523,7.32a.666.666,0,0,0,.664.619h5.323a.666.666,0,0,0,.664-.617l.621-8.7a1.994,1.994,0,0,0,1.848-2.238Z" transform="translate(-323.022 -140.019)" fill={color} />
            <path id="Path_20367" data-name="Path 20367" d="M22.838,162.247a3.513,3.513,0,0,1-.744-.617,3.593,3.593,0,0,1-.877-2.8L22.1,151.1a5.21,5.21,0,0,1,.168-.81.667.667,0,0,0-.472-.822,3.272,3.272,0,0,0-.84-.117H17.669a3.33,3.33,0,0,0-3.3,2.914l-.853,6.819a2,2,0,0,0,1.85,2.24l.621,8.7a.665.665,0,0,0,.664.617h5.323a.666.666,0,0,0,.664-.617l.512-7.169A.659.659,0,0,0,22.838,162.247Z" transform="translate(-13.501 -140.038)" fill={color} />
            <path id="Path_20368" data-name="Path 20368" d="M170.143,138.961l-.882-7.73A3.521,3.521,0,0,0,165.869,128h-3.4a3.52,3.52,0,0,0-3.392,3.231l-.882,7.729a2.258,2.258,0,0,0,.547,1.762,1.967,1.967,0,0,0,1.367.672l.735,9.943a.666.666,0,0,0,.664.616h5.323a.666.666,0,0,0,.664-.616l.735-9.943a1.969,1.969,0,0,0,1.367-.672A2.262,2.262,0,0,0,170.143,138.961Z" transform="translate(-149.157 -120.016)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}

import { TotalCurrentUserStyled } from './styled';
import { Blocks } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalCurrentUser = ({ data }) => {
  return (
    <TotalCurrentUserStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="12px"
        paddingBottom="0px"
        paddingLeft="16px"
        height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelCenter
            theme_standard_box_4_col
            label1="ยอดผู้ใช้ปัจจุบัน"
            label2="Total current User"
            label3={
              data && data.total_user_count > 0
                ? `${RenderCommaMoney(data.total_user_count, true)}`
                : '0'
            }
            labelBack="คน"
          />
        </div>
      </Blocks.Box>
    </TotalCurrentUserStyled>
  );
};

export default TotalCurrentUser;

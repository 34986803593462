import styled from 'styled-components';

export const ExportRawStyled = styled.div`
  .top_widget {
    display: flex;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    align-items: center;
    .img_show {
      width: 96px;
      height: 96px;
      &.bmi {
        width: 83px;
        height: 83px;
      }
      &.well {
        width: 89px;
        height: 89px;
      }
    }
    .hText {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
  }
  .detail_show {
    .text_show {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
    .filter_download {
      margin-top: 20px;
      display: flex;
      align-items: center;
      column-gap: 30px;
      color: black;
      font-size: 20px;
      .w190 {
        width: 190px;
      }
      .label_to {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
    }
  }
`;

import styled from "styled-components";

export const InputDropDownFilterStyled = styled.div`
  .label {
    margin-bottom: 5px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: noto_sans_thai_medium, noto_sans_regular;
  }
  .input_container {
    position: relative;
    min-width: 150px;
    .arrow_icon {
      position: absolute;
      top: 11px;
      right: 8px;
    }
    .input_dropdown_control {
      padding: 8px 10px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: noto_sans_bold;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4}40;
      border-radius: 8px;
      text-align: center;
    }
    .input_dropdown_placeholder {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
    .input_dropdown_menu {
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    }
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_add {
    .input_container {
      .input_dropdown_control {
        padding: 16px;
        font-size: ${({ fontSize, theme }) =>
          fontSize ? fontSize : theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_7};
      }
      .arrow_icon {
        top: 18px;
        right: 17px;
      }
    }
  }
  .theme_reward {
    .input_container {
      .input_dropdown_control {
        padding: 9px;
        font-size: ${({ fontSize, theme }) =>
          fontSize ? fontSize : theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_7};
      }
      .arrow_icon {
        top: 12px;
        right: 17px;
      }
    }
  }
  .theme_reward_add {
    .input_container {
      .input_dropdown_control {
        padding: 9px;
        font-size: ${({ fontSize, theme }) =>
          fontSize ? fontSize : theme.FONT.SIZE.S18};
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_7};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      }
      .arrow_icon {
        top: 12px;
        right: 17px;
      }
    }
  }
  .theme_reward_point {
    .input_container {
      .input_dropdown_control {
        padding: 12px;
        font-size: ${({ fontSize, theme }) =>
          fontSize ? fontSize : theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
        border-radius: 6px;
      }
      .Dropdown-menu.input_dropdown_menu {
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
        border-radius: 6px;
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_14};
        margin-top: 4px;
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-thumb {
          background: #e1e1e1;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #ffffff;
        }
        ::-webkit-scrollbar-thumb:active {
          background: #fcfcfc;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-track:hover {
          background: #dedede;
        }
        ::-webkit-scrollbar-track:active {
          background: #d1d1d1;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
      }
      .arrow_icon {
        top: 12px;
        right: 17px;
      }
    }
  }
  .theme_mini {
    .input_container {
      .icon_copying {
        position: absolute;
        top: 10px;
        left: 16px;
      }
      .input_dropdown_control {
        padding: 7px;
        font-size: ${({ fontSize, theme }) =>
          fontSize ? fontSize : theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: ${({ theme }) => theme.COLORS.BLACK_8};
      }
      .arrow_icon {
        top: 10px;
        right: 9px;
      }
    }
  }
`;

import React from "react";
import { RewardManagementContainerStyled } from "./styled";
import {
  TableCustom,
  Loading,
  Modals,
  Blocks,
  Buttons,
  Icons,
  Inputs,
  BGLoading,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { connect } from "react-redux";
import moment from "moment";
import { adminService } from "apiServices";
import { getObjectKey } from "utils/functions/getObjectKey";
import { toast } from "react-toastify";
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';
// import { adminService } from "apiServices";

class RewardManagementContainer extends React.Component {
  state = {
    isLoading: true,
    sorByAsc: false,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData(`?per_page=10&offset=0`);
    this.checkPermission()
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'spin_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_SPIN_ITEM_LIST_BY_COMPANY_ID(
      this.props.companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
        isLoadingTable: false,
        isBgLoading: false,
        isLoading: false,
      });
    } else {
      this.setState({
        data: [],
        isLoadingTable: false,
        isBgLoading: false,
        isLoading: false,
      });
    }
  };

  handleClickDetail = (e, id) => {
    if (e === 'create') {
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_REWARD_MANAGEMENT}/${e}/create`);
    } else {
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN_REWARD_MANAGEMENT}/${id}/${e}`);
    }
  };

  handleClickSort = (e) => {
    const { sorByAsc } = this.state

    let sort = sorByAsc ? 'desc' : 'asc'

    this.setState(
      {
        sorByAsc: !sorByAsc,
        sortBy: `sort_by=created_at&order_by=${sort}`,
      },
      () => this.handleJoinQuery()
    );
  };

  renderStatus = (e) => {
    switch (e) {
      case "IN_USE":
        return 'กำลังใช้งาน';

      case "NO_USE":
        return 'ไม่ใช้งาน';

      default:
        return '-';
    }
  }

  handleClickDelete = (e) => {
    if (e.status === 'NO_USE') {
      this.setState({
        isShowModal: true,
        dataDelete: e,
      });
    }
  }

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: null,
      dataDelete: null,
    });
  };

  handleConfirmDelete = async () => {
    const { dataDelete } = this.state

    if (dataDelete) {
      this.setState({
        isBgLoading: true,
      })
      let res = await adminService.DELETE_SPIN_ITEMS_BY_ID(dataDelete.id);

      if (res && res.success) {
        this.setState({
          isLoadingTable: true,
        })
        toast.success("ลบสำเร็จ");
        this.handleClickCloseModal()
        this.handleJoinQuery()
      } else {
        this.setState({
          isBgLoading: false,
        })
        toast.error("ลบไม่สำเร็จ");
      }
    }
  }

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    this.setState({
      isLoadingTable: true,
    })
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.fetchData(query);
  };

  handleSelectedFilter = (e) => {
    this.setState(
      {
        keywordFilter: !e ? false : `keyword=${e}`,
      },
      () => this.handleJoinQuery()
    );
  }

  handleJoinQuery = () => {
    const { keywordFilter, sortBy } = this.state;
    let queryString = [];

    this.setState({
      isLoadingTable: true,
    })
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (sortBy) {
      queryString.push(sortBy);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?per_page=10&offset=0&${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
    } else {
      this.fetchData(`?per_page=10&offset=0`);
      this.setState({
        queryFilterAll: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      pagination,
      isShowModal,
      isBgLoading,
      isLoadingTable,
      data,
      perMissions,
    } = this.state;

    return (
      <RewardManagementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="box_spin_layout">
            <div className="top_filter">
              <div className="w378">
                <Inputs.InputSearch2
                  theme_standard_search
                  placeholder='ค้นหารางวัล'
                  onSubmit={this.handleSelectedFilter}
                />
              </div>
              {
                perMissions &&
                <div className="w170">
                  <Buttons.BtnNormal
                    theme_only_border_blue
                    label="สร้างของรางวัล"
                    svg_front={<Icons.AddRound color="#002366" />}
                    onClick={() => this.handleClickDetail('create')}
                  />
                </div>
              }
            </div>
            <div className="table_box">
              <TableCustom.TableNormal
                theme_lucky
                columns={columns({
                  handleClickDelete: this.handleClickDelete,
                  handleClickDetail: this.handleClickDetail,
                  renderStatus: this.renderStatus,
                  handleClickSort: this.handleClickSort,
                  perMissions: perMissions,
                })}
                data={data}
                pagination={pagination}
                labelEmpty="ยังไม่มีข้อมูล"
                // onChangeSelected={this.handleChangeSelectedTable}
                showPagination
                handlePageClick={this.handlePageClick}
                loading={isLoadingTable}
              />
            </div>
          </div>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <Blocks.BoxDelete
            theme_box_delete
            maxWidth='374px'
            minWidth='374px'
            textShow="ยืนยันการลบหรือไม่"
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
          />
        </Modals.Standard>
      </RewardManagementContainerStyled>
    );
  }
}

const columns = ({
  renderStatus,
  handleClickDelete,
  handleClickDetail,
  handleClickSort,
  perMissions,
}) => [
    {
      title: "ID",
      field: "id",
      headerStyle: { textAlign: "left", width: "100px", flexShrink: 0 },
      style: { textAlign: "left", width: "100px", flexShrink: 0 },
      render: ({ origin, value }) => (
        <div>
          {value.toString().padStart(4, '0')}
        </div>
      ),
    },
    {
      // title: (
      //   <div
      //     style={{ display: "flex", alignItems: "center", cursor: "pointer", columnGap: '8px', minWidth: "10px" }}
      //     onClick={() => handleClickSort("name")}
      //   >
      //     <div
      //       style={{
      //         minWidth: "10px",
      //         overflow: "hidden",
      //         whiteSpace: "nowrap",
      //         textOverflow: "ellipsis",
      //       }}
      //     >
      //       ชื่อของรางวัล
      //     </div>
      //     <div style={{ display: 'flex' }}>
      //       <Icons.Sort />
      //     </div>
      //   </div>
      // ),
      title: 'ชื่อของรางวัล',
      field: "name_th",
      headerStyle: { textAlign: "left", minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer", columnGap: '8px', minWidth: "10px" }}
          onClick={() => handleClickSort("date")}
        >
          <div
            style={{
              minWidth: "10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            วันที่สร้าง
          </div>
          <div style={{ display: 'flex' }}>
            <Icons.Sort />
          </div>
        </div>
      ),
      field: "created_at",
      headerStyle: { textAlign: "left", minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {
            `${moment(origin.created_at).tz("Asia/Bangkok").format("DD MMM")} ${moment(origin.created_at).tz("Asia/Bangkok").add(543, "years").format("YYYY")}`
          }
        </div>
      ),
    },
    {
      title: "สถานะรางวัล",
      field: "status",
      headerStyle: { textAlign: "left", minWidth: "10px" },
      style: { textAlign: "left", minWidth: "10px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {renderStatus(value)}
        </div>
      ),
    },
    {
      title: '',
      field: 'action',
      headerStyle: { textAlign: 'center', width: perMissions ? '180px' : '75px', flexShrink: 0 },
      style: { textAlign: 'center', width: perMissions ? '180px' : '75px', flexShrink: 0 },
      render: ({ origin }) => (
        <div className="group_icon">
          <div className="box_icon" onClick={() => handleClickDetail('edit', origin.id)}>
            <Icons.IconsEdit />
          </div>
          {
            perMissions &&
            <>
              <div
                className="box_icon"
                onClick={() => handleClickDetail('copy', origin.id)}
              >
                <Icons.IconCopy />
              </div>
              <div
                className="box_icon"
                onClick={() => handleClickDelete(origin)}
              >
                {/* <Icons.Delete4 color={origin.status === "NO_USE" ? '#646464' : '#9898984a'} /> */}
                <Icons.Delete4 />
              </div>
            </>
          }
        </div>
      ),
    },
  ];

// const data = [
//   {
//     id: '00001',
//     name: 'คะแนนสุขภาพ 50 คะแนน',
//     reward_status: '1',
//     created_at: new Date(),
//   },
//   {
//     id: '00002',
//     name: 'บัตร Starbuck 500 บาท',
//     reward_status: '2',
//     created_at: new Date(),
//   }
// ]

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardManagementContainer);
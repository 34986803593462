import styled from 'styled-components'

export const SelectDeleteFormStyled = styled.div`
  border-radius: 16px;
  font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
  color: ${({ theme }) => theme.COLORS.PURPLE_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S22};
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border: 1px solid ${({ theme }) => theme.COLORS.BLUE_21};
  padding: 40px 64px;
  .top_del_form {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
    .svg_d {
      display: flex;
    }
  }
  .mid_del_form {
    .line_radio {
      display: flex;
      column-gap: 53px;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .width_lbl {
        width: 230px;
      }
      .total_count {
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
    }
  }
  .btn_del_layout {
    margin-top: 24px;
    display: flex;
    column-gap: 15px;
    .body_flex {
      flex: 1;
    }
  }
`

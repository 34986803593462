import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { LabelGraph2Styled } from './styled'

const LabelGraph2 = ({ theme_standard_label_graph, data }) => {
  const customClass = cx({
    theme_standard_label_graph: theme_standard_label_graph,
  })

  return (
    <LabelGraph2Styled>
      <div className={customClass}>
        {
          data &&
          data.map((e, i) => (
            <div key={i + 1} className={`row_label`} style={{ borderColor: e.color }}>
              <div className='circle'  >
                <div className='bg_color' style={{ background: e.color }} />
              </div>
              <div className='label_layer'>
                <div className='group_label'>
                  <div>
                    {e.label}
                  </div>
                  <div className='sub_label'>
                    {e.labelBottom}
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div> 
    </LabelGraph2Styled>
  )
}

LabelGraph2.propTypes = {}

export default LabelGraph2

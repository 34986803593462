import styled from 'styled-components';

export const TotalCurrentUserStyled = styled.div`
  height: 160px;
  .bottom_current {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.IPAD}) {
    
  }
`;

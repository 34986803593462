import React, { useEffect, useState } from "react";
import { AddSpinGameFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tabs, Inputs, Buttons, Icons, Modal } from "components";
import moment from 'moment';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import theme from 'styles/theme.json';
import { toast } from "react-toastify";

const AddSpinGameForm = ({
  initialValues,
  onSubmit,
  aciton_type,
  handleBack,
  optSpinItem,
  handleClickDelete,
  perMissions,
  errorDuplicateEvent,
}) => {
  const schema = yup.object().shape({
    name_th: yup.string().nullable().required('Is required'),
    name_en: yup.string().nullable().required('Is required'),
    // term_en: yup.string().nullable().required('Is required'),
    // term_th: yup.string().nullable().required('Is required'),

    term_th: yup.string()
      .test(
        'has text',
        'Is required',
        value => value !== '' && value !== '<p></p>\n' && value !== '<p></p>'
      ).required('Is required'),

    term_en: yup.string()
      .test(
        'has text',
        'Is required',
        value => value !== '' && value !== '<p></p>\n' && value !== '<p></p>'
      ).required('Is required'),


    play_per_user_day: yup.string().when("time_to_play", (time_to_play, schema) => {
      if (time_to_play === '1') {
        return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1, "Min is 1").required('is required')
      }
    }),
    coin_amount_per_play: yup.string().when("how_to_play", (how_to_play, schema) => {
      if (how_to_play === '1') {
        return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1, "Min is 1").required('is required')
      }
    }),
    options: yup.array().of(
      yup.object().shape({
        spin_coin_amount: yup.string().when("category", (category, schema) => {
          if (category === '1') {
            return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1, "Min is 1").required('is required')
          }
        }),
        spin_item_id: yup.object().when("category", {
          is: '2',
          then: yup.object().required('* Is required')
        }),

        // spin_item_id: yup.string().when("category", (category, schema) => {
        //   if (category === '2') {
        //     return yup.object().nullable().typeError("* Is required").required('* Is required')
        //   } 
        // }),
        redeemable_per_user: yup.string().when("limit", (limit, schema) => {
          if (limit === '1') {
            return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1, "Min is 1").required('is required')
          }
        }),
        redeemable_per_spin: yup.string().when(["qty", "redeemed_count"], (qty, redeemed_count, schema) => {
          if (aciton_type === 'create' || aciton_type === 'copy') {
            if (qty === '1') {
              return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1, "Min is 1").required('is required')
            }
          } else {
            if (qty === '1') {
              let minNumber = redeemed_count < 1 ? 1 : redeemed_count
              return yup.number().transform(value => (isNaN(value) ? undefined : value)).min(minNumber, `Min is ${minNumber}`).required('is required')
            }
          }
        }),
      })
    ),
  });

  // const [_tabIndex, _setTabIndex] = useState(1);
  const [_submitType, _setSubmitType] = useState();
  const [_maxDate, _setMaxDate] = useState();
  const [_minDate, _setMinDate] = useState();
  const [_isShowModal, _setIsShowModal] = useState();
  const [_errorDuplicateDate, _setErrorDuplicateDate] = useState();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    // setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      options: [
        {
          blank: ''
        },
        {
          blank: ''
        },
        {
          blank: ''
        },
        {
          blank: ''
        },
        {
          blank: ''
        },
        {
          blank: ''
        },
      ]
    },
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (initialValues) {

      if (initialValues.status === 'CURRENT') {
        _setMaxDate(new Date(initialValues.end_date))
        _setMinDate(new Date(initialValues.begin_date))
      }

      let params = {
        ...initialValues,
        play_per_user_day: initialValues.play_per_user_day ? initialValues.play_per_user_day : '',
        coin_amount_per_play: initialValues.coin_amount_per_play ? initialValues.coin_amount_per_play : '',
        option_count: String(initialValues.option_count),
        begin_date: initialValues.begin_date ? new Date(initialValues.begin_date) : new Date(),
        end_date: initialValues.end_date ? new Date(initialValues.end_date) : new Date(),
        time_to_play: initialValues.play_per_user_day ? '1' : '2',
        how_to_play: initialValues.coin_amount_per_play ? '1' : '2',
      };

      if (initialValues?.options?.length) {
        let tempOpt = []

        for (const element of initialValues.options) {
          tempOpt.push({
            category: element?.item ? '2' : '1',
            spin_item_id: element?.item ? { label: element.item.name_th, value: element.item.id } : undefined,
            spin_coin_amount: element?.item ? '' : element.spin_coin_amount,
            redeemable_per_user: element?.redeemable_per_user ? element.redeemable_per_user : '',
            limit: element?.redeemable_per_user ? '1' : '2',
            redeemable_per_spin: element?.redeemable_per_spin ? element.redeemable_per_spin : '',
            qty: element?.redeemable_per_spin ? '1' : '2',
            redeemed_count: element?.redeemed_count,
          })
        }

        params.options = tempOpt
      }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorDuplicateEvent === true) {
      _setErrorDuplicateDate('ระยะเวลาซ้ำกับวงล้ออื่น')
    } else {
      _setErrorDuplicateDate(null)
    }

  }, [errorDuplicateEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const { fields, } = useFieldArray({
    control,
    name: 'options',
  });

  // const handleClickTab = (e) => {
  //   _setTabIndex(e.value)
  // }

  const _handleChangeDate = (key, value) => {
    _setErrorDuplicateDate(null)
    switch (key) {
      case 'begin_date':
        setValue(
          'end_date',
          new Date(moment(value).add(1, 'days').format('YYYY-MM-DD'))
        );
        clearErrors('end_date');
        break;
      case 'end_date':
        let b_date = moment(new Date(getValues('begin_date'))).format(
          'YYYY-MM-DD'
        );
        let e_date = moment(new Date(value)).format('YYYY-MM-DD');
        let checkDate = moment(e_date).diff(b_date);

        if (checkDate > 0) {
          clearErrors('end_date');
        } else {
          // _setDisabledBtnSubmit(true);
          toast.error("วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น");
          setValue(
            'end_date',
            new Date(moment(watchAllFields.end_date).format('YYYY-MM-DD'))
          );
          // setError('end_date', {
          //   type: 'manual',
          //   message: 'end date must morethan start date',
          // });
        }
        break;
      default:
        break;
    }
  };

  const handleChangeNumberOfWheels = (e) => {
    let tempFields = watchAllFields.options
    let dumpData = []

    for (let i = 0; i < e; i++) {
      dumpData.push(
        {
          category: tempFields[i] ? tempFields[i].category : '1',
          spin_coin_amount: tempFields[i] ? tempFields[i].spin_coin_amount : '',
          // reward: tempFields[i] ? tempFields[i].reward : undefined,
          limit: tempFields[i] ? tempFields[i].limit : '1',
          redeemable_per_user: tempFields[i] ? tempFields[i].redeemable_per_user : '',
          qty: tempFields[i] ? tempFields[i].qty : '1',
          redeemable_per_spin: tempFields[i] ? tempFields[i].redeemable_per_spin : '',
          spin_item_id: (tempFields[i] && tempFields[i].spin_item_id) && tempFields[i].spin_item_id,
          redeemed_count: tempFields[i] ? tempFields[i].redeemed_count : 0,
        },
      )
    }
    reset(
      {
        ...watchAllFields,
        option_count: String(e),
        options: dumpData,
      }
    )

  }

  const handleClickCloseModal = () => {
    _setIsShowModal(null)
  };

  const handleChckValue = () => {
    let haveError = watchAllFields.options.filter((e) => e.qty === '1').find((a) => a.redeemable_per_spin < a.redeemed_count);

    if (initialValues && initialValues.status === 'CURRENT' && haveError) {
      _setIsShowModal(
        <div className="box_md">
          <div className="line_label">
            <div>
              ไม่สามารถ
            </div>
            <div className="red_text">
              ลดของราลวัล
            </div>
            <div>
              ได้
            </div>
          </div>
          <div>
            เนื่องจากจำนวนของรางวัลถูกแจกไปสูงกว่า
          </div>
          <div className="btn_100">
            <Buttons.BtnNormal
              theme_only_border_blue
              label='รับทราบ'
              fontSize="14px"
              type="submit"
              onClick={handleClickCloseModal}
            />
          </div>
        </div>
      )
    }
  }

  const renderDisabledTimeToPlay = () => {
    if (perMissions) {
      return (watchAllFields.time_to_play === '2' || (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT'))) && true
    } else {
      return true
    }
  }

  const renderDisabledHowToPlay = () => {
    if (perMissions) {
      return (watchAllFields.how_to_play === '2' || (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT'))) && true
    } else {
      return true
    }
  }

  const renderDisabledOpt = (index) => {
    if (perMissions) {
      return (watchAllFields.options[index].category === '1' || (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT'))) && true
    } else {
      return true
    }
  }

  const renderDisabledTxt = (index) => {
    if (perMissions) {
      return (watchAllFields.options[index].category === '2' || (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT'))) && true
    } else {
      return true
    }
  }

  const renderDisabledLimit = (index) => {
    if (perMissions) {
      return (watchAllFields.options[index].limit === '2' || (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT'))) && true
    } else {
      return true
    }
  }

  const renderDisabledQty = (index) => {
    if (perMissions) {
      if (initialValues && initialValues.status === 'EXPIRED') {
        return true
      } else {
        return (watchAllFields.options[index].qty === '2') && true
      }
    } else {
      return true
    }
  }

  const renderDisabledTextField = () => {
    if (perMissions) {
      if (initialValues && (initialValues.status === 'EXPIRED' || initialValues.status === 'CURRENT')) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const renderDisabledCanEdit = () => {
    if (perMissions) {
      if (initialValues && initialValues.status === 'EXPIRED') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const handleBeforSave = (e) => {
    let params = {
      ...watchAllFields,
    }

    if (_submitType) {
      if (_submitType === 'save_draft') {
        params.is_draft = true
      } else if (_submitType === 'save_publish') {
        params.is_draft = false
      }

      onSubmit && onSubmit(params)
    }
  }

  const renderButton = () => {
    let tempButton = []

    if (aciton_type === 'edit') {
      if (initialValues && initialValues.status === 'DRAFT') {
        tempButton.push(
          <div className="btn144">
            <div className="del_btn" onClick={handleClickDelete && handleClickDelete} >
              ลบวงล้อ
            </div>
          </div>
        )
        tempButton.push(
          <div className="btn144">
            <Buttons.BtnNormal
              theme_only_border
              label="บันทึก"
              borderRadius="8px"
              backgroundColor="#fff"
              fontColor={theme.COLORS.PURPLE_1}
              fontSize='14px'
              borderColor="#92CBDB"
              fontFamilyTop={theme.FONT.STYLE.MEDIUM}
              onClick={() => _setSubmitType('save_draft')}
              type="submit"
            />
          </div>
        )
        tempButton.push(
          <div className="btn144 min144">
            <Buttons.BtnNormal
              theme_only_border_blue
              label='บันทึกและเผยแพร่'
              fontSize="14px"
              type="submit"
              onClick={() => _setSubmitType('save_publish')}
            // disabled={disabled}
            />
          </div>
        )
      }
      if (initialValues && initialValues.status === 'CURRENT') {
        tempButton.push(
          <div className="btn144">
            <Buttons.BtnNormal
              theme_only_border
              label="บันทึก"
              borderRadius="8px"
              backgroundColor="#fff"
              fontColor={theme.COLORS.PURPLE_1}
              fontSize='14px'
              borderColor="#92CBDB"
              fontFamilyTop={theme.FONT.STYLE.MEDIUM}
              onClick={() => {
                _setSubmitType('save_current')
                handleChckValue()
              }}
              type="submit"
            />
          </div>
        )
      }
      if (initialValues && initialValues.status === 'UPCOMING') {
        tempButton.push(
          <div className="btn144">
            <div className="del_btn" onClick={handleClickDelete && handleClickDelete} >
              ลบวงล้อ
            </div>
          </div>
        )
        tempButton.push(
          <div className="btn144">
            <Buttons.BtnNormal
              theme_only_border_blue
              label='บันทึก'
              fontSize="14px"
              onClick={() => _setSubmitType('save_upcoming')}
              type="submit"
            // disabled={disabled}
            />
          </div>
        )
      }
    } else if (aciton_type === 'create' || aciton_type === 'copy') {
      tempButton.push(
        <div className="btn144">
          <Buttons.BtnNormal
            theme_only_border
            label="บันทึกร่าง"
            borderRadius="8px"
            backgroundColor="#fff"
            fontColor={theme.COLORS.PURPLE_1}
            fontSize='14px'
            borderColor="#92CBDB"
            fontFamilyTop={theme.FONT.STYLE.MEDIUM}
            onClick={() => _setSubmitType('save_draft')}
            type="submit"
          />
        </div>
      )
      tempButton.push(
        <div className="btn144 min144">
          <Buttons.BtnNormal
            theme_only_border_blue
            label='บันทึกและเผยแพร่'
            fontSize="14px"
            type="submit"
            onClick={() => _setSubmitType('save_publish')}
          // disabled={disabled}
          />
        </div>
      )
    }

    return tempButton
  }

  const renderStatus = (e) => {
    switch (e) {
      case 'DRAFT':
        return 'บันทึกร่าง';

      case 'CURRENT':
        return 'กำลังเผยแพร่';

      case 'UPCOMING':
        return 'รอเผยแพร่';

      case 'EXPIRED':
        return 'หมดอายุ';

      default:
        return '-';
    }
  }

  return (
    <AddSpinGameFormStyled>
      {/* {console.log('watchAllFields', watchAllFields)} */}
      <form onSubmit={handleSubmit(handleBeforSave)}>
        <div className="layer_one">
          <Tabs.HeadTab
            theme_standard_head_tab
            title={aciton_type !== 'edit' ? "สร้างวงล้อ" : "แก้ไขวงล้อ"}
            subTitle={aciton_type !== 'edit' ? "Create Spin Game" : "Edit Spin Game"}
            fontSize="34px"
            fontSizeLabelButtom="18px"
            fontColor="#092945"
            colorBottom="#9E9E9E"
            lineHeight="1.2"
            handleBackCustom={handleBack}
          />
        </div>
        <div className="tab_zone_select_data">
          <div>
            ตั้งค่าวงล้อ
          </div>
          {
            initialValues && initialValues.status
            &&
            <div className="status_show_name">
              <div>
                สถานะ :
              </div>
              <div className="lbl_status_name">
                {renderStatus(initialValues.status)}
              </div>
            </div>
          }
          {/* <Tabs.TabBarSelect
            tab_normal
            tabHeader={headerTab}
            onClickSeletedTab={handleClickTab}
            fontSizeTop='18px'
          /> */}
        </div>
        <div className={`box_one`}>
          <div className="box50">
            <div className="line_obj">
              <div className="label_group">
                <div className="pt17">
                  ชื่อวงล้อ (TH) *
                </div>
              </div>
              <div className="right_obj">
                <div className="w100">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        maxLength={50}
                        placeholder="ชื่อวงล้อ (TH) *"
                        flexStyle="flex-end"
                        {...field}
                        errors={errors.name_th?.message}
                        disabled={renderDisabledTextField()}
                      />
                    )}
                    name="name_th"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            < div className="line_obj">
              <div className="label_group">
                <div >
                  ระยะเวลา *
                </div>
                <div className="sub_label">
                  Start-End Date
                </div>
              </div>
              <div className="right_obj flex_start">
                {
                  _errorDuplicateDate &&
                  <div className="err_date">
                    {_errorDuplicateDate}
                  </div>
                }
                <div className="w100 max244">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDatePiker
                        theme_date_lucky
                        minDate={_minDate}
                        maxDate={_maxDate}
                        onChangeCustom={(e) =>
                          _handleChangeDate('begin_date', e)
                        }
                        {...field}
                        iconColor="#002366"
                        errors={errors.begin_date?.message}
                        disabled={renderDisabledTextField()}
                      />
                    )}
                    name="begin_date"
                    defaultValue={new Date(moment().add(1, 'days'))}
                  />
                </div>
                <div className="label_mid">
                  <div>
                    ถึง *
                  </div>
                  <div className="small_label_mid">
                    To
                  </div>
                </div>
                <div className="w100 max244">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDatePiker
                        theme_date_lucky
                        minDate={_minDate}
                        maxDate={_maxDate}
                        onChangeCustom={(e) =>
                          _handleChangeDate('end_date', e)
                        }
                        {...field}
                        iconColor="#002366"
                        errors={errors.end_date?.message}
                        // disabled={renderDisabledTextField()}
                        disabled={renderDisabledCanEdit()}
                      />
                    )}
                    name="end_date"
                    defaultValue={new Date(moment().add(2, 'days'))}
                  />
                </div>
              </div>
            </div>
            <div className="line_obj flex_start">
              <div className="label_group">
                <div >
                  เงื่อนไข (TH) *
                </div>
                <div className="sub_label">
                  Condition
                </div>
              </div>
              <div className="right_obj">
                <div className="w_text_rich">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextRich
                        theme_text_rich_lucky
                        maxLength={500}
                        placeholder="คำอธิบาย"
                        height="190px"
                        labelTitle={<div></div>}
                        flexStyle="flex-end"
                        optionsTextRich={
                          [
                            'list',
                            'inline',
                          ]
                        }
                        inlineOption={
                          { options: ['bold'], }
                        }
                        listOption={
                          { options: ['unordered'], }
                        }
                        {...field}
                        disabled={renderDisabledTextField()}
                        errors={errors.term_th?.message}
                      />
                    )}
                    name="term_th"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="line_obj flex_start mb47">
              <div className="label_group">
                <div >
                  <div>
                    จำนวน
                  </div>
                  <div>
                    การเล่นต่อวัน *
                  </div>
                </div>
                <div className="sub_label">
                  Times to play
                </div>
              </div>
              <div className="right_obj between">
                <div className="pt10">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Radio
                        theme_standard_radio
                        rowGap='20px'
                        {...field}
                        options={[
                          {
                            label: 'จำกัด',
                            value: '1',
                          },
                          {
                            label: 'ไม่จำกัด',
                            value: '2',
                          }
                        ]}
                        // placeholder={t('test')}
                        disabled={renderDisabledTextField()}
                        errors={errors.time_to_play?.message}
                      // onChaneCustom={handleChangeCare}
                      />
                    )}
                    name="time_to_play"
                    defaultValue='1'
                  />
                </div>
                <div className="w95">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        type='number'
                        // min='1'
                        {...field}
                        disabled={renderDisabledTimeToPlay()}
                        errors={errors.play_per_user_day?.message}
                      />
                    )}
                    name="play_per_user_day"
                    defaultValue=""
                  />
                </div>
                <div className="back_label">
                  สิทธิ์/วัน/คน
                </div>
              </div>
            </div>
            <div className="line_obj flex_start mb47">
              <div className="label_group">
                <div >
                  วิธีการเล่น *
                </div>
                <div className="sub_label">
                  How to play
                </div>
              </div>
              <div className="right_obj between">
                <div className="pt10">
                  <div>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.Radio
                          theme_standard_radio
                          rowGap='20px'
                          {...field}
                          options={[
                            {
                              label: 'แลกคะแนน',
                              value: '1',
                            },
                            {
                              label: 'ใช้สิทธิ์ฟรี',
                              value: '2',
                            }
                          ]}
                          // placeholder={t('test')}
                          errors={errors.how_to_play?.message}
                          disabled={renderDisabledTextField()}
                        // onChaneCustom={handleChangeCare}
                        />
                      )}
                      name="how_to_play"
                      defaultValue='1'
                    />
                  </div>
                </div>
                <div className="w95">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        type='number'
                        // min='1'
                        {...field}
                        disabled={renderDisabledHowToPlay()}
                        errors={errors.coin_amount_per_play?.message}
                      />
                    )}
                    name="coin_amount_per_play"
                    defaultValue=""
                  />
                </div>
                <div className="back_label">
                  คะแนน
                </div>
              </div>
            </div>
          </div>
          <div className="box50">
            <div className="line_obj mb137">
              <div className="label_group">
                <div className="pt17">
                  ชื่อวงล้อ (EN) *
                </div>
              </div>
              <div className="right_obj">
                <div className="w100">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextMaxChar
                        theme_lucky
                        maxLength={50}
                        placeholder="ชื่อวงล้อ (EN) *"
                        flexStyle="flex-end"
                        disabled={renderDisabledTextField()}
                        {...field}
                        errors={errors.name_en?.message}
                      />
                    )}
                    name="name_en"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="line_obj flex_start">
              <div className="label_group">
                <div >
                  เงื่อนไข (EN) *
                </div>
                <div className="sub_label">
                  Condition
                </div>
              </div>
              <div className="right_obj">
                <div className="w_text_rich">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextRich
                        theme_text_rich_lucky
                        maxLength={500}
                        placeholder="คำอธิบาย"
                        height="190px"
                        labelTitle={<div></div>}
                        flexStyle="flex-end"
                        optionsTextRich={
                          [
                            'list',
                            'inline',
                          ]
                        }
                        inlineOption={
                          { options: ['bold'], }
                        }
                        listOption={
                          { options: ['unordered'], }
                        }
                        {...field}
                        disabled={renderDisabledTextField()}
                        errors={errors.term_en?.message}
                      />
                    )}
                    name="term_en"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="b_title">
          ตั้งค่ารางวัล
        </div>
        <div className={`box_two`}>
          <div className="box_obj_right">
            <div className="lbl_group">
              <div >
                จำนวนวงล้อ *
              </div>
              <div className="sub_lbl">
                Number of Wheels
              </div>
            </div>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Radio
                  theme_standard_radio
                  rowGap='20px'
                  flexDirection='row'
                  flex
                  {...field}
                  options={[
                    {
                      label: '6 วงล้อ',
                      value: '6',
                    },
                    {
                      label: '8 วงล้อ',
                      value: '8',
                    }
                  ]}
                  // placeholder={t('test')}
                  errors={errors.option_count?.message}
                  disabled={renderDisabledTextField()}
                  onChaneCustom={handleChangeNumberOfWheels}
                />
              )}
              name="option_count"
              defaultValue='6'
            />
          </div>
          <div className="box_obj_right">
            <div className="lbl_group">
              <div >
                ประเภทรางวัล *
              </div>
              <div className="sub_lbl">
                Category
              </div>
            </div>
          </div>
          <div className="list_wheel_rewards">
            {
              fields &&
              fields.length > 0 &&
              fields.map((item, index) => {
                return (
                  <div key={item.id} className="list_box">
                    <div className="box33 gray">
                      <div className="group_radio_show">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.Radio
                              theme_standard_radio
                              rowGap='28px'
                              {...field}
                              options={[
                                {
                                  label: 'คะแนน',
                                  value: '1',
                                },
                                {
                                  label: 'ของรางวัล',
                                  value: '2',
                                }
                              ]}
                              disabled={renderDisabledTextField()}
                            // placeholder={t('test')}
                            // errors={errors.how_to_play?.message}
                            />
                          )}
                          name={`options[${index}].category`}
                          defaultValue='1'
                        />
                      </div>
                      <div className="group_obj_select">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputTextMaxChar
                              theme_lucky
                              type='number'
                              // min='1'
                              {...field}
                              disabled={renderDisabledTxt(index)}
                              // errors={errors.play_per_user_day?.message}
                              errors={
                                errors &&
                                errors["options"] &&
                                errors["options"][index] &&
                                errors["options"][index].spin_coin_amount &&
                                errors["options"][index].spin_coin_amount.message
                              }
                            />
                          )}
                          name={`options[${index}].spin_coin_amount`}
                          defaultValue=""
                        />
                        <div className="box_width">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Inputs.InputDropDownNormal
                                theme_lucky
                                iconSvgClose={<Icons.RectArrowDown color={renderDisabledOpt(index) === true ? "#949494" : '#002366'} />}
                                iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                                placeholder="เลือกรางวัล"
                                disabled={renderDisabledOpt(index)}
                                options={optSpinItem}
                                {...field}
                                errors={
                                  errors &&
                                  errors["options"] &&
                                  errors["options"][index] &&
                                  errors["options"][index].spin_item_id &&
                                  errors["options"][index].spin_item_id.message
                                }
                              />
                            )}
                            name={`options[${index}].spin_item_id`}
                          // defaultValue={optReward[0]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="box33">
                      <div className="g_label">
                        <div >
                          จำกัดรางวัล *
                        </div>
                        <div className="g_sub_lbl">
                          Limit/Person
                        </div>
                      </div>
                      <div className="group_radio_show">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.Radio
                              theme_standard_radio
                              rowGap='28px'
                              {...field}
                              disabled={renderDisabledTextField()}
                              options={[
                                {
                                  label: 'จำกัด',
                                  value: '1',
                                },
                                {
                                  label: 'ไม่จำกัด',
                                  value: '2',
                                }
                              ]}
                            // placeholder={t('test')}
                            // errors={errors.how_to_play?.message}
                            />
                          )}
                          name={`options[${index}].limit`}
                          defaultValue='1'
                        />
                      </div>
                      <div className="group_obj_select w205">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputTextMaxChar
                              theme_lucky
                              type='number'
                              // min='1'
                              {...field}
                              disabled={renderDisabledLimit(index)}
                              errors={
                                errors &&
                                errors["options"] &&
                                errors["options"][index] &&
                                errors["options"][index].redeemable_per_user &&
                                errors["options"][index].redeemable_per_user.message
                              }
                            />
                          )}
                          name={`options[${index}].redeemable_per_user`}
                          defaultValue=""
                        />
                      </div>
                      <div className="lbl_back">
                        รางวัล/คน
                      </div>
                    </div>
                    <div className="box33">
                      <div className="g_label">
                        <div >
                          จำนวนรางวัล*
                        </div>
                        <div className="g_sub_lbl">
                          Quantity
                        </div>
                      </div>
                      <div className="group_radio_show">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.Radio
                              theme_standard_radio
                              rowGap='28px'
                              {...field}
                              options={[
                                {
                                  label: 'จำกัด',
                                  value: '1',
                                },
                                {
                                  label: 'ไม่จำกัด',
                                  value: '2',
                                }
                              ]}
                              disabled={renderDisabledTextField()}
                            // disabled={renderDisabledCanEdit()}
                            // placeholder={t('test')}
                            // errors={errors.how_to_play?.message}
                            />
                          )}
                          name={`options[${index}].qty`}
                          defaultValue='1'
                        />
                      </div>
                      <div className="group_obj_select w205">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Inputs.InputTextMaxChar
                              theme_lucky
                              type='number'
                              // min='1'
                              {...field}
                              disabled={renderDisabledQty(index)}
                              errors={
                                errors &&
                                errors["options"] &&
                                errors["options"][index] &&
                                errors["options"][index].redeemable_per_spin &&
                                errors["options"][index].redeemable_per_spin.message
                              }
                            />
                          )}
                          name={`options[${index}].redeemable_per_spin`}
                          defaultValue=""
                        />
                      </div>
                      <div className="lbl_back">
                        รางวัล
                      </div>
                      {
                        aciton_type === 'edit' &&
                        <div className="amount_label">
                          {` จำนวนรางวัลที่ออกไป  :  ${RenderCommaMoney(Number(item?.redeemed_count).toFixed(0))}`}
                        </div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {
          perMissions &&
          <div className="btn_zone">
            {renderButton()}
          </div>
        }
      </form>
      <Modal
        theme_modal_standard
        isShow={_isShowModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        {_isShowModal}
      </Modal>
    </AddSpinGameFormStyled>
  );
};

// const optReward = [
//   {
//     label: 'บัตร Starbuck 500 บาท',
//     value: 1,
//   },
//   {
//     label: 'บัตร Starbuck 100 บาท',
//     value: 2,
//   },
// ];

// const headerTab = [
//   {
//     label: 'ตั้งค่าวงล้อ',
//     value: 1,
//   },
//   {
//     label: 'ตั้งค่ารางวัล',
//     value: 2,
//   },
// ];

AddSpinGameForm.propTypes = {};

export default AddSpinGameForm;

export default function renderIcon({
  width = "24",
  height = "24",
  color = "#002366",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55859 12.0002H14.5632"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5605 8.99902L14.5618 12.0003L11.5605 15.0015"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.55859 9.13634V7.39862C6.55917 6.44522 7.23236 5.62466 8.16726 5.4378L19.1718 3.0308C19.786 2.95985 20.401 3.15712 20.8593 3.57211C21.3176 3.9871 21.5747 4.57952 21.5648 5.1977V19.0034C21.5651 19.5936 21.3049 20.1537 20.8537 20.5341C20.4025 20.9145 19.8065 21.0763 19.2249 20.9763L8.22029 19.0835C7.25986 18.9183 6.55814 18.0852 6.55859 17.1107V14.9728"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

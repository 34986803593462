import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ScoreBottomStyled } from "./styled";
import { Blocks, Icons } from "components";
import theme from "styles/theme.json";

const ScoreBottom = ({
  title,
  subTitle,
  svg,
  score,
  status,
  theme_standard,
  labelBottom,
  colorScore,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderScore = () => {
    let classStatus = "";
    switch (status) {
      case "up":
        classStatus = "up";
        break;
      case "down":
        classStatus = "down";
        break;
      default:
        break;
    }
    return (
      <div className={`card_score ${classStatus}`}>
        {status === "up" && (
          <span className="iconWrap">
            <Icons.ArrowUpBig color={theme.COLORS.RED_1} />
          </span>
        )}
        <span style={{ color: colorScore && colorScore }}>{score}</span>
        {labelBottom && (
          <div style={{ fontSize: "14px", color: "#000000" }}>
            {labelBottom}
          </div>
        )}
      </div>
    );
  };

  return (
    <ScoreBottomStyled>
      <div className={customClass}>
        <Blocks.Box
          theme_standard_box
          paddingTop="16px"
          paddingRight="16px"
          paddingBottom="16px"
          paddingLeft="16px"
          height="128px"
        >
          <div className="card_title">
            <div className="card_left_col">
              <div className="txt_title">{title}</div>
              <div className="txt_sub_title">{subTitle}</div>
            </div>
            <div className="card_right_col">{svg}</div>
          </div>
          {renderScore()}
        </Blocks.Box>
      </div>
    </ScoreBottomStyled>
  );
};

ScoreBottom.propTypes = {};

export default ScoreBottom;

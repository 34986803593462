import styled from 'styled-components';

export const UploadImage3Styled = styled.div`
  .theme_standard_upload_image {
    .title_upload {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      .group_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .label_1 {
          text-align: center;
        }
        .label_2 {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-family: ${({ theme }) => theme.FONT.STYLE.THIN};
        }
      }
    }
    .body_upload {
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      background: ${({ theme }) => theme.COLORS.GRAY_25};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 24px;
      position: relative;
      overflow: hidden;
      width: 360px;
      height: 257px;
      &.no_border {
        border: none;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .show_img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .box_word {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
    .desc_zone {
      align-items: center;
      flex-direction: column;
      display: flex;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.GRAY_27};
      font-family: ${({ theme }) => theme.FONT.STYLE.THIN};
      margin-bottom: 24px;
    }
    .btn_upload {
      padding: 0% 5%;
    }
  }
`;

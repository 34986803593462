import React, { useEffect, useState } from 'react';
import { RewardPointFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons, Icons } from 'components';
import moment from 'moment';

const RewardPointForm = ({ onSubmit }) => {
  const schema = yup.object().shape({

  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const [_month, _setMonth] = useState([]);
  const [_year, _setYear] = useState([]);

  useEffect(() => {
    let start_year = moment().subtract(12, 'months')
    let tempYear = []
    let tempMonth = []
    let isYear = ''

    for (let i = 1; i <= 12; i++) {
      tempMonth.push({
        value: moment(start_year).add(i, 'months').tz('Asia/Bangkok').format(`YYYY-MM-01`),
        label: moment(start_year).add(i, 'months').format('MMMM'),
        // label: moment(start_year).add(i, 'months').year() + 543,
      })

      if (isYear !== moment(start_year).add(i, 'months').format(`YYYY`)) {
        isYear = moment(start_year).add(i, 'months').format(`YYYY`)
        tempYear.push({
          label: moment(start_year).add(i, 'months').year() + 543,
          value: moment(start_year).add(i, 'months').tz('Asia/Bangkok').format(`YYYY-MM-01`),
        })
      }
    }
    _setMonth(tempMonth)
    _setYear(tempYear)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectStartMonth = (e) => {
    setValue(
      'begin_year',
      {
        value: moment(e.value).tz('Asia/Bangkok').format(`YYYY-MM-DD`),
        label: moment(e.value).year() + 543,
      }
    );
  }

  const handleSelectEndMonth = (e) => {
    setValue(
      'end_year',
      {
        value: moment(e.value).tz('Asia/Bangkok').format(`YYYY-MM-DD`),
        label: moment(e.value).year() + 543,
      }
    );
  }

  return (
    <RewardPointFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='group_select'>
          <div className='two_col'>
            <div className='label_box'>
              เดือน
            </div>
            <div className='box_date'>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputDropDownFilter
                    theme_reward_point
                    iconSvgClose={<Icons.ArrowDropdown width='10' height='16' />}
                    iconSvgOpen={<Icons.ArrowDropdown translate="21.81" rotate="90" width='10' height='16' />}
                    placeholder={`${moment().format('MMMM')}`}
                    options={_month}
                    onChangeCustom={handleSelectStartMonth}
                    fontSize="16px"
                    {...field}
                  />
                )}
                name="begin_month"
                defaultValue={{
                  label: `${moment().format('MMMM')}`,
                  value: `${moment().format('YYYY-MM-DD')}`,
                }}
              />
            </div>
            <div className='label_box'>
              {`ปี  `}
            </div>
            <div className='box_date'>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputDropDownFilter
                    theme_reward_point
                    iconSvgClose={<Icons.ArrowDropdown width='10' height='16' />}
                    iconSvgOpen={<Icons.ArrowDropdown translate="21.81" rotate="90" width='10' height='16' />}
                    placeholder={`${moment().year() + 543}`}
                    options={_year}
                    disabled
                    fontSize="16px"
                    {...field}
                  />
                )}
                name="begin_year"
                defaultValue={{
                  value: moment().tz('Asia/Bangkok').format(`YYYY-MM-DD`),
                  label: moment().year() + 543,
                }}
              />
            </div>
          </div>
          <div className='two_col'>
            <div className='label_box'>
              {`ถึง  `}
            </div>
            <div className='box_date'>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputDropDownFilter
                    theme_reward_point
                    iconSvgClose={<Icons.ArrowDropdown width='10' height='16' />}
                    iconSvgOpen={<Icons.ArrowDropdown translate="21.81" rotate="90" width='10' height='16' />}
                    placeholder={`${moment().format('MMMM')}`}
                    options={_month}
                    fontSize="16px"
                    onChangeCustom={handleSelectEndMonth}
                    {...field}
                  />
                )}
                name="end_month"
                defaultValue={{
                  label: `${moment().format('MMMM')}`,
                  value: `${moment().format('YYYY-MM-DD')}`,
                }}
              />
            </div>
            <div className='label_box'>
              {`ปี  `}
            </div>
            <div className='box_date'>
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputDropDownFilter
                    theme_reward_point
                    disabled
                    iconSvgClose={<Icons.ArrowDropdown width='10' height='16' />}
                    iconSvgOpen={<Icons.ArrowDropdown translate="21.81" rotate="90" width='10' height='16' />}
                    placeholder={`${moment().year() + 543}`}
                    options={_year}
                    fontSize="16px"
                    {...field}
                  />
                )}
                name="end_year"
                defaultValue={{
                  value: moment().tz('Asia/Bangkok').format(`YYYY-MM-DD`),
                  label: moment().year() + 543,
                }}
              />
            </div>
          </div>
        </div>
        <div className='btn_download'>
          <div className='btn_width'>
            <Buttons.BtnNormal
              theme_standard_btn_normal_red
              label="Download"
              backgroundColor="#5D639A"
              fontSize='23px'
              borderRadius='8px'
              svg_front={<Icons.Download3 color="#FFFFFF" />}
              type='submit'
            />
          </div>
        </div>
      </form>
    </RewardPointFormStyled>
  );
};

RewardPointForm.propTypes = {};

export default RewardPointForm;

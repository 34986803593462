import styled from 'styled-components';

export const WeeklyAchieverStyled = styled.div`
  .weekly_container {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_4}80;
    padding-bottom: 20px;
    margin-bottom: 16px;
    .weekly_block {
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      .left_col {
      }
      .right_col {
        margin-left: 32px;
        .txt_percent {
          font-size: ${({ theme }) => theme.FONT.SIZE.S42};
          font-family: noto_sans_bold;
          color: ${({ theme }) => theme.COLORS.GREEN_4};
        }
      }
    }
    .icon_rating_block {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
    .descrip_scrore_block {
      width: fit-content;
      margin: auto;
    }
  }
  .recent_goal_container {
    .title_wrap {
      margin-bottom: 24px;
    }
    .score_wrap {
      &.mb_10 {
        margin-bottom: 10px;
      }
    }
  }
`;

export default function Setting() {
  return (
    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" width="45.549" height="45.549" viewBox="0 0 45.549 45.549">
      <g id="Group_33046" data-name="Group 33046" transform="translate(0 6.507)">
        <g id="Group_33025" data-name="Group 33025" transform="translate(17.894 29.281)">
          <g id="Group_33024" data-name="Group 33024">
            <path id="Path_49478" data-name="Path 49478" d="M35.76,54.507H26L27.082,48h7.6Z" transform="translate(-26 -48)" fill="#074f77" />
          </g>
        </g>
        <g id="Group_33027" data-name="Group 33027" transform="translate(18.577 29.281)">
          <g id="Group_33026" data-name="Group 33026">
            <path id="Path_49479" data-name="Path 49479" d="M35.242,50.44h-8.4l.4-2.44h7.6Z" transform="translate(-26.84 -48)" fill="#00325a" />
          </g>
        </g>
        <g id="Group_33029" data-name="Group 33029" transform="translate(18.708 29.281)">
          <g id="Group_33028" data-name="Group 33028">
            <path id="Path_49480" data-name="Path 49480" d="M35.134,49.627H27L27.268,48h7.6Z" transform="translate(-27 -48)" fill="#00325a" />
          </g>
        </g>
        <g id="Group_33031" data-name="Group 33031">
          <g id="Group_33030" data-name="Group 33030">
            <path id="Path_49481" data-name="Path 49481" d="M49.549,13.627V41.281a1.632,1.632,0,0,1-1.627,1.627H5.627A1.632,1.632,0,0,1,4,41.281V13.627A1.632,1.632,0,0,1,5.627,12h42.3A1.632,1.632,0,0,1,49.549,13.627Z" transform="translate(-4 -12)" fill="#01a0c8" />
          </g>
        </g>
        <g id="Group_33032" data-name="Group 33032" transform="translate(1.627 1.627)">
          <path id="Path_49482" data-name="Path 49482" d="M48.3,14.813V37.588a.816.816,0,0,1-.813.813H6.813A.816.816,0,0,1,6,37.588V14.813A.816.816,0,0,1,6.813,14H47.482A.816.816,0,0,1,48.3,14.813Z" transform="translate(-6 -14)" fill="#f0f0f0" />
        </g>
        <g id="Group_33033" data-name="Group 33033" transform="translate(3.253 20.334)">
          <path id="Path_49483" data-name="Path 49483" d="M12.067,38.627H8.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,0,1,0,1.627Z" transform="translate(-8 -37)" fill="#d2d2d2" />
        </g>
        <g id="Group_33034" data-name="Group 33034" transform="translate(3.253 17.081)">
          <path id="Path_49484" data-name="Path 49484" d="M12.067,34.627H8.813a.813.813,0,1,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-8 -33)" fill="#d2d2d2" />
        </g>
        <g id="Group_33035" data-name="Group 33035" transform="translate(3.253 23.588)">
          <path id="Path_49485" data-name="Path 49485" d="M12.067,42.627H8.813a.813.813,0,1,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-8 -41)" fill="#d2d2d2" />
        </g>
        <g id="Group_33036" data-name="Group 33036" transform="translate(37.415 20.334)">
          <path id="Path_49486" data-name="Path 49486" d="M54.067,38.627H50.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -37)" fill="#d2d2d2" />
        </g>
        <g id="Group_33037" data-name="Group 33037" transform="translate(37.415 17.081)">
          <path id="Path_49487" data-name="Path 49487" d="M54.067,34.627H50.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -33)" fill="#d2d2d2" />
        </g>
        <g id="Group_33038" data-name="Group 33038" transform="translate(37.415 23.588)">
          <path id="Path_49488" data-name="Path 49488" d="M54.067,42.627H50.813a.813.813,0,1,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -41)" fill="#d2d2d2" />
        </g>
        <g id="Group_33039" data-name="Group 33039" transform="translate(8.386 1.627)">
          <path id="Path_49489" data-name="Path 49489" d="M42.949,25.924l-3.14,5.433a1.042,1.042,0,0,1-1.432.382l-1.529-.887a12.614,12.614,0,0,1-3.969,2.286v1.773a1.045,1.045,0,0,1-1.041,1.049H25.559a1.045,1.045,0,0,1-1.041-1.049V33.139a12.614,12.614,0,0,1-3.969-2.286l-1.529.887a1.042,1.042,0,0,1-1.432-.382l-3.14-5.433a1.056,1.056,0,0,1,.382-1.432l1.537-.887a12,12,0,0,1,0-4.571l-1.537-.887a1.056,1.056,0,0,1-.382-1.432L16.018,14H41.379l1.57,2.717a1.056,1.056,0,0,1-.382,1.432l-1.537.887a12,12,0,0,1,0,4.571l1.537.887a1.056,1.056,0,0,1,.382,1.432Z" transform="translate(-14.311 -14)" fill="#d2d2d2" />
        </g>
        <g id="Group_33041" data-name="Group 33041" transform="translate(13.014 35.788)">
          <g id="Group_33040" data-name="Group 33040">
            <path id="Path_49490" data-name="Path 49490" d="M38.708,59.253H20.813A.814.814,0,0,1,20,58.44v-.813A1.626,1.626,0,0,1,21.627,56H37.894a1.626,1.626,0,0,1,1.627,1.627v.813A.814.814,0,0,1,38.708,59.253Z" transform="translate(-20 -56)" fill="#01a0c8" />
          </g>
        </g>
        <g id="Group_33043" data-name="Group 33043" transform="translate(39.042 27.655)">
          <g id="Group_33042" data-name="Group 33042">
            <path id="Path_49491" data-name="Path 49491" d="M52.813,47.627h3.253a.813.813,0,0,0,0-1.627H52.813a.813.813,0,0,0,0,1.627Z" transform="translate(-52 -46)" fill="#f0f0f0" />
          </g>
        </g>
        <g id="Group_33045" data-name="Group 33045" transform="translate(35.967 27.558)">
          <g id="Group_33044" data-name="Group 33044" transform="translate(0 0)">
            <ellipse id="Ellipse_5157" data-name="Ellipse 5157" cx="0.555" cy="0.926" rx="0.555" ry="0.926" fill="#c8094f" />
          </g>
        </g>
      </g>
      <g id="Group_33050" data-name="Group 33050" transform="translate(8.386 0)">
        <g id="Group_33047" data-name="Group 33047">
          <path id="Path_49492" data-name="Path 49492" d="M42.563,21.815l-1.534-.886a12.123,12.123,0,0,0,0-4.578l1.534-.886a1.046,1.046,0,0,0,.383-1.428L39.809,8.6a1.046,1.046,0,0,0-1.428-.383l-1.535.887a12.519,12.519,0,0,0-3.964-2.286V5.046A1.046,1.046,0,0,0,31.836,4H25.561a1.046,1.046,0,0,0-1.046,1.046V6.821a12.535,12.535,0,0,0-3.964,2.286L19.016,8.22a1.046,1.046,0,0,0-1.428.383l-3.137,5.434a1.046,1.046,0,0,0,.383,1.428l1.534.886a12.122,12.122,0,0,0,0,4.578l-1.534.886a1.046,1.046,0,0,0-.383,1.428l3.137,5.434a1.046,1.046,0,0,0,1.428.383l1.535-.887a12.519,12.519,0,0,0,3.964,2.286v1.775a1.046,1.046,0,0,0,1.046,1.046h6.274a1.046,1.046,0,0,0,1.046-1.046V30.46a12.535,12.535,0,0,0,3.964-2.286l1.535.887a1.046,1.046,0,0,0,1.428-.383l3.137-5.434A1.046,1.046,0,0,0,42.563,21.815Z" transform="translate(-14.311 -4)" fill="#e7284a" />
        </g>
        <g id="Group_33048" data-name="Group 33048" transform="translate(6.022 6.274)">
          <circle id="Ellipse_5158" data-name="Ellipse 5158" cx="8.366" cy="8.366" r="8.366" fill="#f0f0f0" />
        </g>
        <g id="Group_33049" data-name="Group 33049" transform="translate(7.964 10.574)">
          <path id="Path_49493" data-name="Path 49493" d="M36.712,18.388l-1.15-1.15a.812.812,0,0,0-1.15,0l-5.57,5.57-2.2-2.2a.814.814,0,0,0-1.15,0l-1.15,1.15a.812.812,0,0,0,0,1.15l3.927,3.927a.812.812,0,0,0,1.15,0l7.3-7.3a.812.812,0,0,0,0-1.149Z" transform="translate(-24.102 -17)" fill="#d2d2d2" />
        </g>
      </g>
      <g id="Group_33053" data-name="Group 33053" transform="translate(16.349 9.76)">
        <g id="Group_33051" data-name="Group 33051" transform="translate(0 3.369)">
          <path id="Path_49494" data-name="Path 49494" d="M28.266,26.607,24.339,22.68a.812.812,0,0,1,0-1.15l1.15-1.15a.812.812,0,0,1,1.15,0l4.5,4.5-1.725,1.725a.814.814,0,0,1-1.151,0Z" transform="translate(-24.101 -20.142)" fill="#fd921e" />
        </g>
        <g id="Group_33052" data-name="Group 33052" transform="translate(2.44)">
          <path id="Path_49495" data-name="Path 49495" d="M27.1,24.109l7.871-7.871a.812.812,0,0,1,1.15,0l1.15,1.15a.812.812,0,0,1,0,1.15l-7.3,7.3a.812.812,0,0,1-1.15,0Z" transform="translate(-27.101 -16)" fill="#fdc566" />
        </g>
      </g>
      <g id="Group_33054" data-name="Group 33054" transform="translate(3.253 26.028)">
        <path id="Path_49496" data-name="Path 49496" d="M12.067,37.627H8.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,0,1,0,1.627Z" transform="translate(-8 -36)" fill="#00143c" />
      </g>
      <g id="Group_33055" data-name="Group 33055" transform="translate(3.253 22.774)">
        <path id="Path_49497" data-name="Path 49497" d="M12.067,33.627H8.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,0,1,0,1.627Z" transform="translate(-8 -32)" fill="#00143c" />
      </g>
      <g id="Group_33056" data-name="Group 33056" transform="translate(3.253 29.281)">
        <path id="Path_49498" data-name="Path 49498" d="M12.067,41.627H8.813a.813.813,0,1,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-8 -40)" fill="#00143c" />
      </g>
      <g id="Group_33057" data-name="Group 33057" transform="translate(37.415 26.028)">
        <path id="Path_49499" data-name="Path 49499" d="M54.067,37.627H50.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -36)" fill="#00143c" />
      </g>
      <g id="Group_33058" data-name="Group 33058" transform="translate(37.415 22.774)">
        <path id="Path_49500" data-name="Path 49500" d="M54.067,33.627H50.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -32)" fill="#00143c" />
      </g>
      <g id="Group_33059" data-name="Group 33059" transform="translate(37.415 29.281)">
        <path id="Path_49501" data-name="Path 49501" d="M54.067,41.627H50.813a.813.813,0,0,1,0-1.627h3.253a.813.813,0,1,1,0,1.627Z" transform="translate(-50 -40)" fill="#00143c" />
      </g>
    </svg>
  );
}

import styled from 'styled-components'

export const BtnMonthStyled = styled.div`
  .theme_standard_btn_month {
    font-family: noto_sans_thai_bold, noto_sans_bold;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    color:  ${({ theme }) => theme.COLORS.PURPLE_1};
    display: flex;
    align-items: center;
    justify-content: center;
    .svg_f {
      display: flex;
      cursor: pointer;
    }
    .label {
      display: flex;
      flex-shrink: 0;
      text-align: center;
      justify-content: center;
      width: 50%;
      margin: 0px 16px;
    }
    .svg_b {
      display: flex;
      cursor: pointer;
    }
  }
`

import React from "react";
import { connect } from "react-redux";
import { SpinGameCreateEditContainerStyled } from "./styled";
import { Forms, Loading, BGLoading, Modal, Blocks, Buttons } from "components";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants";
import moment from "moment";
import { getObjectKey } from "utils/functions/getObjectKey";

class SpinGameCreateEditContainer extends React.Component {
  state = {
    isLoading: true,
    countPage: 1,
    allSpinItem: [],
    errorDuplicateEvent: null,
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchAllData()
    this.checkPermission()
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'spin_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  fetchAllData = async () => {
    let res = await Promise.all([
      this.fetchData(),
      this.fetchSpinItem('?per_page=100&offset=0')
    ]);

    if (res && res[0]) {
      this.setState({
        initialValues: res[0] === 'create' ? null : res[0],
        // dataSpinItem: res[1],
        // isLoading: false,
      })
    }
  }

  fetchData = async () => {
    if (this.props.match.params.id !== "create") {
      let res = await adminService.GET_SPIN_BY_ID(
        this.props.match.params.id
      );

      if (res && res.success) {
        let tempData = res.data
        if (this.props.match.params.action !== "edit") {
          delete tempData.status
        }
        return tempData
      }
    } else {
      return 'create'
    }
  };

  fetchNewData = async () => {
    let res = await adminService.GET_SPIN_BY_ID(
      this.props.match.params.id
    );

    if (res && res.success) {
      let tempData = res.data
      if (this.props.match.params.action !== "edit") {
        delete tempData.status
      }
      
      this.setState({
        initialValues: tempData,
        isBgLoading: false
      })
    }
  };

  fetchSpinItem = async (queryString) => {
    const { allSpinItem, countPage } = this.state
    const { companySelectedRedux } = this.props;

    let res = await adminService.GET_SPIN_ITEM_LIST_BY_COMPANY_ID(companySelectedRedux?.id, queryString);

    if (res && res.success) {
      let tempAllSpin = allSpinItem
      tempAllSpin.push(...res.data)
      this.setState({
        allSpinItem: tempAllSpin,
        countPage: countPage + 1,
      },
        () => {
          if (res.page.current_page !== res.page.last_page) {
            this.fetchSpinItem(`?per_page=100&offset=${countPage * 100}`)
          } else {
            let tempData = []
            allSpinItem.map((e) => (
              tempData.push({
                label: e.name_th,
                value: e.id,
              })
            ))

            tempData.sort((a, b) => a.label.localeCompare(b.label))
            this.setState({
              dataSpinItem: tempData,
              isLoading: false,
            })
          }
        })
    }
  };

  onSubmit = async (values) => {
    const { companySelectedRedux } = this.props;
    this.setState({
      errorDuplicateEvent: null,
      isBgLoading: true,
    })

    let tempArray = []
    values.options.map((e) => (
      tempArray.push({
        spin_coin_amount: e.category === '1' ? Number(e.spin_coin_amount) : null,
        spin_item_id: e.category === '2' ? Number(e.spin_item_id.value) : null,
        redeemable_per_user: e.limit === '1' ? Number(e.redeemable_per_user) : null,
        redeemable_per_spin: e.qty === '1' ? Number(e.redeemable_per_spin) : null,
      })
    ))
    let params = {
      ...values,
      option_count: Number(values.option_count),
      begin_date: moment(values.begin_date).tz("Asia/Bangkok").format("YYYY-MM-DD"),
      end_date: moment(values.end_date).tz("Asia/Bangkok").format("YYYY-MM-DD"),
      play_per_user_day: values.time_to_play === '1' ? Number(values.play_per_user_day) : null,
      coin_amount_per_play: values.how_to_play === '1' ? Number(values.coin_amount_per_play) : null,
      options: tempArray,
    }

    if (this.props.match.params.action !== "edit") {
      delete params.status
    }

    let res =
      this.props.match.params.action !== "edit"
        ? await adminService.POST_CREATE_SPIN_BY_COMPANY_ID(
          companySelectedRedux?.id,
          params
        )
        : await adminService.PATCH_SPIN_BY_ID(
          this.props.match.params.id,
          params
        );

    if (res && res.success) {
      toast.success("บันทึก");
      this.handleBack()
    } else {
      if (res.error_code === 'INVALID_OPTION_REDEEMABLE_PER_SPIN') {
        this.fetchNewData()
        this.setState({
          errorDuplicateEvent: null,
          isShowModal: (
            <div className="box_md">
              <div className="line_label">
                <div>
                  ไม่สามารถ
                </div>
                <div className="red_text">
                  ลดของราลวัล
                </div>
                <div>
                  ได้
                </div>
              </div>
              <div>
                เนื่องจากจำนวนของรางวัลถูกแจกไปสูงกว่า
              </div>
              <div className="btn_100">
                <Buttons.BtnNormal
                  theme_only_border_blue
                  label='รับทราบ'
                  fontSize="14px"
                  type="submit"
                  onClick={this.handleClickCloseModal}
                />
              </div>
            </div>
          )
        })
      } else if (res.error_code === 'SPIN_OVERLAP_PERIOD') {
        toast.error("บันทึกไม่สำเร็จ ระยะเวลาซ้ำกับวงล้ออื่น");
        this.setState({
          errorDuplicateEvent: true,
          isBgLoading: false,
        })
      } else {
        this.setState({
          errorDuplicateEvent: null,
          isBgLoading: false,
        })
        toast.error("บันทึกไม่สำเร็จ");
      }
    }

  };

  handleBack = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_LUCK_SPIN}?tabIndex=spin_game`);
  }

  handleClickDelete = (e) => {
    this.setState({
      isShowModal: (
        <Blocks.BoxDelete
          theme_box_delete
          maxWidth='374px'
          minWidth='374px'
          textShow={<div style={{ color: '#EC5E49' }}>ยืนยันการลบหรือไม่ ?</div>}
          iconColor='#EC5E49'
          handleConfirm={this.handleConfirmDelete}
          handleCancel={this.handleClickCloseModal}
        />
      ),
    });
  }

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: null,
      dataDelete: null,
    });
  };

  handleConfirmDelete = async () => {
    this.setState({
      isBgLoading: true,
    })
    let res = await adminService.DELETE_SPIN_BY_ID(this.props.match.params.id);

    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.handleClickCloseModal()
      this.handleBack()
    } else {
      this.setState({
        isBgLoading: false,
      })
      toast.error(res.error);
    }
  }

  render() {
    const { companySelectedRedux } = this.props;
    const {
      initialValues,
      isLoading,
      dataSpinItem,
      disabled,
      isBgLoading,
      isShowModal,
      perMissions,
      errorDuplicateEvent,
    } = this.state;
    return (
      <SpinGameCreateEditContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Forms.AddSpinGameForm
              initialValues={initialValues}
              companyID={companySelectedRedux?.id}
              optSpinItem={dataSpinItem}
              onSubmit={this.onSubmit}
              aciton_type={this.props.match.params.action}
              disabled={disabled}
              perMissions={perMissions ? true : false}
              handleClickDelete={this.handleClickDelete}
              handleBack={this.handleBack}
              errorDuplicateEvent={errorDuplicateEvent}
            />
          </>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {isShowModal}
        </Modal>
      </SpinGameCreateEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpinGameCreateEditContainer);

export default function renderIcon({ color = '#575d96' ,width="17.934" , height="17.934" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17.934 17.934">
      <g id="_x30_8" transform="translate(-2 -2)">
        <path id="Path_97926" data-name="Path 97926" d="M10.483,20.967A4.483,4.483,0,1,0,6,16.483,4.483,4.483,0,0,0,10.483,20.967Zm-3.711-4.8a5.084,5.084,0,0,1,7.421,0,.436.436,0,0,1,0,.625,5.084,5.084,0,0,1-7.421,0A.436.436,0,0,1,6.773,16.171Z" transform="translate(-1.438 -3.595)" fill={color} />
        <path id="Path_97927" data-name="Path 97927" d="M4.771,23.4,2.3,25.869a1.03,1.03,0,0,0,0,1.456h0a1.03,1.03,0,0,0,1.456,0l2.469-2.469A5.162,5.162,0,0,1,4.771,23.4Z" transform="translate(0 -7.694)" fill={color} />
        <circle id="Ellipse_5957" data-name="Ellipse 5957" cx="1.5" cy="1.5" r="1.5" transform="translate(7.545 11.467)" fill={color} />
        <path id="Path_97928" data-name="Path 97928" d="M20.888,2H11.921A1.924,1.924,0,0,0,10,3.921V5.843H22.81V3.921A1.924,1.924,0,0,0,20.888,2ZM17.045,4.242a.32.32,0,0,1-.64,0V3.6a.32.32,0,0,1,.64,0Zm1.281,0a.32.32,0,0,1-.64,0V3.6a.32.32,0,0,1,.64,0Zm1.281,0a.32.32,0,1,1-.64,0V3.6a.32.32,0,1,1,.64,0Zm1.281,0a.32.32,0,1,1-.64,0V3.6a.32.32,0,1,1,.64,0Z" transform="translate(-2.876)" fill={color} />
        <path id="Path_97929" data-name="Path 97929" d="M11.937,25.562a5.09,5.09,0,0,1-1.894-.367,1.918,1.918,0,0,0,1.894,1.648H20.9a1.924,1.924,0,0,0,1.921-1.921V23H16.368A5.123,5.123,0,0,1,11.937,25.562Zm8.326-.961a.32.32,0,0,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,0,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,0,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,0,1,.64,0v.64a.32.32,0,1,1-.64,0Z" transform="translate(-2.892 -7.55)" fill={color} />
        <path id="Path_97930" data-name="Path 97930" d="M20.767,17.494a.315.315,0,0,1,.022.108v.64a.315.315,0,0,1-.022.108,5.082,5.082,0,0,1-.356,1.494h6.142V16H20.411A5.082,5.082,0,0,1,20.767,17.494Zm3.224.108a.32.32,0,0,1,.64,0v.64a.32.32,0,0,1-.64,0Zm-1.281,0a.32.32,0,1,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,1,1,.64,0v.64a.32.32,0,1,1-.64,0Z" transform="translate(-6.619 -5.033)" fill={color} />
        <path id="Path_97931" data-name="Path 97931" d="M10,10.658a5.1,5.1,0,0,1,6.352,2.185H22.81V9H10ZM20.248,10.6a.32.32,0,1,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,1,1,.64,0v.64a.32.32,0,1,1-.64,0Zm-1.281,0a.32.32,0,0,1,.64,0v.64a.32.32,0,0,1-.64,0Zm-1.281,0a.32.32,0,0,1,.64,0v.64a.32.32,0,0,1-.64,0Z" transform="translate(-2.876 -2.517)" fill={color} />
      </g>
    </svg>
  )
}

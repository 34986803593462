// import React from "react";
import React, { useState, useEffect } from "react";
import { WellBeingContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Blocks, Typographys, Icons, Tabs } from "components";
import {
  CurrentWellBeingStatContainer,
  CurrentBurnoutSyndromeContainer,
} from "./Tabs";

const WellBeingContainer = () => {
  const [titleShow, setTitleShow] = useState({
    labelTitle: "Company Wellbeing Score",
    icon: <Icons.WellBeing />,
  });

  useEffect(() => {
    scrollToTopPageFN();
  }, []);

  const ChangeTitle = (e) => {
    setTitleShow({
      labelTitle: e.labelTitle,
      icon: e.icon,
    });
  };

  return (
    <WellBeingContainerStyled>
      <Blocks.Container>
        <div className="title_layout">
          <Typographys.LabelIcon
            theme_standard_label_icon
            label={titleShow.labelTitle}
            svgFront={titleShow.icon}
          />
        </div>
        <Tabs.TabBarSelect
          tab_standard_tab_bar_select
          tabHeader={headerTab}
          onClickSeletedTab={ChangeTitle}
        />
      </Blocks.Container>
    </WellBeingContainerStyled>
  );
};

const headerTab = [
  {
    label: "ข้อมูลสถิติ Wellbeing",
    labelBottom: "Current Wellbeing Stat",
    component: <CurrentWellBeingStatContainer />,
    icon: <Icons.WellBeing />,
    labelTitle: "Company Wellbeing Score",
  },
  {
    label: "ข้อมูลสถิติ Burnout syndrome",
    labelBottom: "Current Burnout syndrome Stat",
    component: <CurrentBurnoutSyndromeContainer />,
    icon: <Icons.Fire />,
    labelTitle: "Burnout syndrome  Score",
  },
];
export default WellBeingContainer;

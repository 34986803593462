import styled from 'styled-components'

export const AddGroupRoleFormStyled = styled.div`
 .box_form {
     display: flex;
     align-items: center;
     .input_show {
        width: 100%;
     }
     .btn_submit {
         width: 120px;
         margin-left: 16px;
         flex-shrink: 0;
     }
 }
 .error {
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    margin-top: 5px;
    color: red;
 }
`

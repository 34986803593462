import React from "react";
import { AIBMIContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards, Typographys, Icons } from "components";
import { adminService } from "apiServices";
import { connect } from "react-redux";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { DwbRisk, DWBRiskDepartment } from "widgets";
import moment from "moment";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";

class AIBMIContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchDWBAllCompany(),
      this.fetchDWBSummaryCompanyList(
        `?stat_date=${moment().startOf("quarter").format("YYYY-MM-DD")}`
      ),
      this.fetchDWBPercentDepartmentList(
        `?stat_date=${moment().startOf("quarter").format("YYYY-MM-DD")}`
      ),
    ]);

    if (res) {
      this.setState({
        dwbAllCompanyData: res[0],
        dwbSummaryCompanyListData: res[1],
        dwbPercentDepartmentListData: res[2],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchDWBAllCompany = async () => {
    let res = await adminService.GET_DWB_STATISTICS_BY_COMPANY_ID(
      this.props.companySelectedRedux?.id
    );

    if (res && res.success) {
      return res.data;
    }
  };

  fetchDWBSummaryCompanyList = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_DWB_SUMMARY_BY_COMPANY_ID(
      this.props.companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      let tempData = res.data.map((e) => ({
        name: e.department.name,
        risk_user_count: e.risk_user_count,
      }));
      return {
        data: tempData,
        startDate:
          initialStartDate || moment().startOf("quarter").format("YYYY-MM-DD"),
      };
    }
  };

  fetchDWBPercentDepartmentList = async (stringQuery) => {
    let res = await adminService.GET_DWB_SUMMARY_BY_COMPANY_ID(
      this.props.companySelectedRedux?.id,
      stringQuery
    );
    if (res && res.success) {
      return sliceIntoChunks(
        res.data.map((e) => ({
          id: e.department.id,
          name: e.department.name,
          nosymptom_user_count: e.nosymptom_user_count,
          nosymptom_user_percent: e.nosymptom_user_percent,
          risk_user_count: e.risk_user_count,
          risk_user_percent: e.risk_user_percent,
        })),
        6
      );
    }
  };

  render() {
    const {
      isLoading,
      dwbSummaryCompanyListData,
      dwbAllCompanyData,
      dwbPercentDepartmentListData,
    } = this.state;
    const { companySelectedRedux } = this.props;
    return (
      <AIBMIContainerStyled>
        {isLoading ? (
          <div className="loading_layout">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="section_introduce_layout">
              <Cards.BoxIntroduce
                src="/assets/images/logo/logo_dbg.svg"
                titleLabel="Dangerous work behavior"
                label1="พฤติกรรมการทำงานที่มีความเสี่ยงจะทำให้พนักงานเกิดอาการบาดเจ็บและนำไปสู่โรค MSD จึงควรมีการการเฝ้าระวังและติดตามอาการบาดเจ็บของระบบกล้ามเนื้อและโครงกระดูกในการปฏิบัติงานของพนักงานเพื่อให้พนักงานมีสุขภาพร่างกายที่ดียิ่งขึ้น"
                // label2="14.3% of employees fail in this category, which is better than the national average."
                // label3="These employees were signposted and re-routed to the company’s Employee Assistance Programme."
              />
            </div>
            <div className="block_2_col_layout">
              <div className="col_2 w_368">
                <div className="b2c_block_wrap mb_24">
                  <Typographys.IconTitleSubTxt
                    theme_big_text
                    bottomColor="#2A74A3"
                    svg={<Icons.Build />}
                    title="จำนวนพนักงานที่มีความเสี่ยง DWB"
                    subTitle="Total number of companies"
                    score={
                      dwbAllCompanyData &&
                      Number(dwbAllCompanyData.total_risk_user_count) > 0
                        ? RenderCommaMoney(
                            Number(dwbAllCompanyData.total_risk_user_count),
                            0
                          )
                        : "0"
                    }
                    suffixLabelScore="คน"
                  />
                </div>
                <div className="b2c_block_wrap">
                  <Typographys.IconTitleSubTxt
                    theme_big_text
                    bottomColor="#2A74A3"
                    svg={<Icons.NumberOfUse />}
                    title="จำนวนพนักงานที่ตอบแบบสอบถามล่าสุดสัปดาห์ที่แล้ว"
                    score={
                      dwbAllCompanyData &&
                      Number(dwbAllCompanyData.quarter_user_count) > 0
                        ? RenderCommaMoney(
                            Number(dwbAllCompanyData.quarter_user_count),
                            true
                          )
                        : "0"
                    }
                    suffixLabelScore="คน"
                  />
                </div>
              </div>
              <div className="col_2 flex_1 mw_780">
                <div className="wrap_mr5_overflow">
                  <DwbRisk
                    data={
                      dwbSummaryCompanyListData && dwbSummaryCompanyListData
                    }
                    labelTop="5 อันดับแผนกที่พนักงานมีความเสี่ยง DWB สูงสุด"
                    labelSub="Top 5 departments of DBG risk’s employees"
                    fetchData={this.fetchDWBSummaryCompanyList}
                  />
                </div>
              </div>
            </div>
            {companySelectedRedux.isParent === 0 && (
              <div>
                <DWBRiskDepartment
                  data={
                    dwbPercentDepartmentListData && dwbPercentDepartmentListData
                  }
                  fetchData={this.fetchDWBPercentDepartmentList}
                />
              </div>
            )}
          </>
        )}
      </AIBMIContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AIBMIContainer);

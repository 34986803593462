export default function Arrow3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Group_33150" data-name="Group 33150" transform="translate(-1008 -706)">
        <g id="Ellipse_3684" data-name="Ellipse 3684" transform="translate(1008 706)" fill="#575D96"  strokeWidth="1">
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.5" fill="none" />
        </g>
        <g id="_025-left-arrow-2" data-name="025-left-arrow-2" transform="translate(1015 711)">
          <g id="Group_2243" data-name="Group 2243" transform="translate(0 0)">
            <path id="Path_5484" data-name="Path 5484" d="M103.308,4.632l3.464-3.464a.508.508,0,0,0,0-.716l-.3-.3a.507.507,0,0,0-.716,0l-4.125,4.125a.511.511,0,0,0,0,.719l4.121,4.121a.507.507,0,0,0,.716,0l.3-.3a.507.507,0,0,0,0-.716Z" transform="translate(-101.48 0)" fill="#ffffff" />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import { TableAction2Styled } from "./styled";
import cx from "classnames";
import { Inputs, PaginationCustom } from "components";

export const TableAction2 = ({
  theme_standard_table_normal,
  theme_standard_table_normal_gray,
  theme_standard_table_normal_purple,
  theme_standard_table_user,
  theme_approve,
  theme_department,
  theme_reward,
  columns,
  data = [],
  pagination,
  showTitle,
  useNo,
  bgColor,
  bgHeader,
  paddingRow,
  activeColor,
  showPagination,
  marginTopPagination,
  handlePageClick,
  onChangeSelected,
  rowAlignItems,
  isCheckbox = true,
  isRound = false,
  labelEmpty,
  height,
  handlePushArray,
  handleClickAllRow,
  theme_standard_table_activity_anvite_join,
  handleAllArray,
  fieldKey = "id",
  theme_small_pagination,
}) => {
  const customClass = cx({
    theme_standard_table_normal: theme_standard_table_normal,
    theme_standard_table_normal_gray: theme_standard_table_normal_gray,
    theme_standard_table_user: theme_standard_table_user,
    theme_standard_table_normal_purple: theme_standard_table_normal_purple,
    theme_department: theme_department,
    theme_reward: theme_reward,
    theme_approve: theme_approve,
    theme_standard_table_activity_anvite_join:
      theme_standard_table_activity_anvite_join,
  });

  const [_selected, _setSelected] = useState({});
  const [_isSelectedAll, _setIsSelectedAll] = useState(false);
  const [_rowClick, _setRowClick] = useState();

  useEffect(() => {
    onChangeSelected && onChangeSelected(_selected);
  }, [_selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const handleSelectedAll = (e) => {
    if (_isSelectedAll) {
      _setIsSelectedAll(false);
      _setSelected({});
      handleAllArray && handleAllArray(false);
    } else {
      let selectedTemp = { ..._selected };
      let allData = [];
      data &&
        data.forEach((element) => {
          selectedTemp[element[fieldKey]] = true;
          allData.push(element[fieldKey]);
        });
      _setIsSelectedAll(true);
      _setSelected(selectedTemp);
      handleAllArray && handleAllArray(allData);
    }
  };

  const handleSelectedItem = (e) => {
    let temp = { ..._selected };
    if (temp[e?.target?.value]) {
      delete temp[e.target.value];
      _setSelected(temp);
      if (_isSelectedAll) {
        _setIsSelectedAll(false);
      }
    } else {
      temp[e.target.value] = true;
      _setSelected(temp);
    }
    handlePushArray && handlePushArray(e.target.value);
  };

  const handleClickRow = (indexRow, data) => {
    _setRowClick(indexRow);
    handleClickAllRow && handleClickAllRow(data);
  };

  return (
    <TableAction2Styled
      bgHeader={bgHeader}
      marginTopPagination={marginTopPagination}
      paddingRow={paddingRow}
      activeColor={activeColor}
      rowAlignItems={rowAlignItems}
      height={height}
    >
      <div className={customClass}>
        <div className="table_container">
          {showTitle && <div className="show_title">{showTitle}</div>}
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                columns.map((e, i) => (
                  <div
                    key={i}
                    className={`header_col ${e.classStyle ? e.classStyle : ""}`}
                    style={e.headerStyle}
                  >
                    {isCheckbox && i === 0 && (
                      <div className="checkbox_layout">
                        {isRound ? (
                          <Inputs.InputCheckBox2
                            theme_standard_input_check_box_round_2
                            name={i}
                            value={"isSelectedAll"}
                            checked={_isSelectedAll}
                            onChange={handleSelectedAll}
                            bgColor="#3260AD"
                            borderColor="#9E9E9E"
                            borderColorActive="#9E9E9E"
                          />
                        ) : (
                          <Inputs.InputCheckBox2
                            theme_standard_input_check_box_square
                            name={i}
                            value={"isSelectedAll"}
                            checked={_isSelectedAll}
                            onChange={handleSelectedAll}
                            bgColor="#3260AD"
                            borderColor="#9E9E9E"
                            borderColorActive="#9E9E9E"
                          />
                        )}
                      </div>
                    )}
                    {e?.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className="table_body">
            {(!data || data.length === 0) && labelEmpty ? (
              <div className="label_empty">{labelEmpty}</div>
            ) : (
              data &&
              data.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`body_row ${
                      _selected[e[fieldKey]] ? "active" : ""
                    } ${_rowClick === i ? "row_acitve" : ""}`}
                    onClick={() => handleClickRow(i, e)}
                  >
                    {columns.map((e1, i2) => (
                      <div
                        key={i2}
                        className={`body_col ${
                          e1.classStyle ? e1.classStyle : ""
                        }`}
                        style={e1.style}
                      >
                        {isCheckbox && i2 === 0 && (
                          <div className="checkbox_layout">
                            {isRound ? (
                              <Inputs.InputCheckBox2
                                theme_standard_input_check_box_round_2
                                value={e[fieldKey]}
                                checked={_selected[e[fieldKey]] ? true : false}
                                onChange={handleSelectedItem}
                                bgColor="#3260AD"
                                borderColor="#9E9E9E"
                                borderColorActive="#3260AD"
                              />
                            ) : (
                              <Inputs.InputCheckBox2
                                theme_standard_input_check_box_square
                                value={e[fieldKey]}
                                checked={_selected[e[fieldKey]] ? true : false}
                                onChange={handleSelectedItem}
                                bgColor="#3260AD"
                                borderColor="#9E9E9E"
                                borderColorActive="#3260AD"
                              />
                            )}
                          </div>
                        )}
                        {useNo && i2 === 1 ? (
                          <div className="no">
                            {e1.render
                              ? e1.render({
                                  origin: e,
                                  value: i + 1,
                                })
                              : pagination
                              ? pagination.from + i
                              : i + 1}
                          </div>
                        ) : e1.render ? (
                          e1.render({
                            origin: e,
                            value: getDescendantProp(e, e1.field),
                          })
                        ) : (
                          getDescendantProp(e, e1.field)
                        )}
                      </div>
                    ))}
                  </div>
                );
              })
            )}
          </div>
          {showPagination &&
            pagination &&
            (theme_small_pagination ? (
              <div className="pagi_layout">
                <PaginationCustom
                  theme_small_pagination
                  totalPages={pagination?.last_page}
                  currentPageData={pagination?.current_page - 1}
                  _handlePageClick={handlePageClick}
                />
              </div>
            ) : (
              <div className="pagi_layout">
                <PaginationCustom
                  theme_standard_pagination
                  totalPages={pagination?.last_page}
                  currentPageData={pagination?.current_page - 1}
                  _handlePageClick={handlePageClick}
                />
              </div>
            ))}
        </div>
      </div>
    </TableAction2Styled>
  );
};

export default TableAction2;

export default function renderIcon({ color = '#4E5B7E', width = "15", height = "16.667" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 16.667">
      <g id="ic_today_24px" transform="translate(-2.5 -0.833)">
        <g id="Group" transform="translate(2.5 0.833)">
          <path id="Shape" d="M13.333,1.667H12.5V0H10.833V1.667H4.167V0H2.5V1.667H1.667A1.659,1.659,0,0,0,.008,3.333L0,15a1.666,1.666,0,0,0,1.667,1.667H13.333A1.672,1.672,0,0,0,15,15V3.333a1.672,1.672,0,0,0-1.667-1.667Zm0,13.333H1.667V5.833H13.333V15Zm-10-7.5H7.5v4.167H3.333Z" fill-rule="evenodd" />
        </g>
      </g>
    </svg>
  )
}

export default function Ribbin({ width = '34.709', height = '48' }) {
  return (
    <svg id="_012-medal" data-name="012-medal" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34.709 48">
      <g id="Group_242" data-name="Group 242" transform="translate(0 0)">
        <path id="Path_964" data-name="Path 964" d="M171.767,340.935l-2.482,1.073-2.323-.629h-3.807l-2.462.617-2.342-1.06-1.826-2.764-.847.8-.028,15.007a.486.486,0,0,0,.664.453l8.48-3.335a.486.486,0,0,1,.356,0l8.483,3.336a.486.486,0,0,0,.664-.452l.027-14.964-.841-.837Z" transform="translate(-147.705 -306.468)" fill="#c61926" />
        <path id="Path_965" data-name="Path 965" d="M209.436,373.215l4.1-1.613a.486.486,0,0,1,.356,0l4.134,1.626v-9.717l-.844-1.016-3.382-1.7-3.522,1.676-.844,1.035Z" transform="translate(-196.447 -326.972)" fill="#ff4755" />
        <path id="Path_966" data-name="Path 966" d="M155.948,329.172l0,1.644a3.475,3.475,0,0,1,1.965,1.9,3.478,3.478,0,0,0,5.311,1.423,3.478,3.478,0,0,1,4.209,0,3.478,3.478,0,0,0,5.311-1.423,3.475,3.475,0,0,1,1.851-1.857l0-1.68Z" transform="translate(-147.971 -298.312)" fill="#b2182b" />
        <path id="Path_967" data-name="Path 967" d="M209.436,345.317V347.2a3.449,3.449,0,0,0,2.261-.706,3.478,3.478,0,0,1,4.209,0,3.447,3.447,0,0,0,2.122.709v-1.859L213.8,342.8Z" transform="translate(-196.447 -310.66)" fill="#fc2b3a" />
        <path id="Path_968" data-name="Path 968" d="M90.145.643a3.146,3.146,0,0,1,4.8,1.287,3.146,3.146,0,0,0,3.3,1.9,3.146,3.146,0,0,1,3.517,3.517,3.146,3.146,0,0,0,1.9,3.3,3.146,3.146,0,0,1,1.287,4.8,3.146,3.146,0,0,0,0,3.807,3.146,3.146,0,0,1-1.287,4.8,3.146,3.146,0,0,0-1.928,2.983q0,.156.024.314c.259,2.04-1.477,2.932-3.517,2.673a3.146,3.146,0,0,0-3.3,1.9,3.146,3.146,0,0,1-4.8,1.287,3.146,3.146,0,0,0-3.807,0,3.146,3.146,0,0,1-4.8-1.287,3.146,3.146,0,0,0-3.3-1.9c-2.04.259-3.776-.633-3.517-2.673q.02-.157.024-.314a3.146,3.146,0,0,0-1.928-2.983,3.146,3.146,0,0,1-1.287-4.8,3.146,3.146,0,0,0,0-3.807,3.146,3.146,0,0,1,1.287-4.8,3.146,3.146,0,0,0,1.9-3.3,3.146,3.146,0,0,1,3.517-3.517,3.146,3.146,0,0,0,3.3-1.9,3.146,3.146,0,0,1,4.8-1.287,3.146,3.146,0,0,0,3.807,0Z" transform="translate(-70.887 0)" fill="#ffcb5b" />
        <g id="Group_238" data-name="Group 238" transform="translate(3.807 27.046)">
          <path id="Path_969" data-name="Path 969" d="M138.543,288.486a3.182,3.182,0,0,1-3.38,2.037,3.182,3.182,0,0,0-3.334,1.925,3.182,3.182,0,0,1-4.859,1.3,3.182,3.182,0,0,0-3.85,0,3.182,3.182,0,0,1-4.859-1.3,3.182,3.182,0,0,0-3.334-1.925,3.182,3.182,0,0,1-3.38-2.037c0,.1-.01.209-.023.314a3.146,3.146,0,0,0,3.517,3.517,3.146,3.146,0,0,1,3.3,1.9,3.146,3.146,0,0,0,4.8,1.287,3.146,3.146,0,0,1,3.807,0,3.146,3.146,0,0,0,4.8-1.287,3.146,3.146,0,0,1,3.3-1.9,3.146,3.146,0,0,0,3.517-3.517A3.119,3.119,0,0,1,138.543,288.486Z" transform="translate(-111.498 -288.486)" fill="#f7b737" />
        </g>
        <g id="Group_240" data-name="Group 240" transform="translate(5.231 5.202)">
          <g id="Group_239" data-name="Group 239">
            <path id="Path_970" data-name="Path 970" d="M138.808,78.565c-12.122,0-12.142-11.512-12.122-11.8a12.153,12.153,0,0,1,24.244,0C150.951,67.053,150.961,78.565,138.808,78.565Z" transform="translate(-126.685 -55.486)" fill="#ff9838" />
          </g>
        </g>
        <path id="Path_971" data-name="Path 971" d="M138.523,187.059A12.153,12.153,0,0,1,126.4,175.781c-.021.289-.032.581-.032.875a12.153,12.153,0,1,0,24.306,0q0-.441-.032-.875A12.153,12.153,0,0,1,138.523,187.059Z" transform="translate(-121.169 -159.302)" fill="#ff8709" />
        <path id="Path_972" data-name="Path 972" d="M195.094,115.876l1.072,2.172a1.173,1.173,0,0,0,.883.642l2.4.348a1.173,1.173,0,0,1,.65,2l-1.734,1.69a1.173,1.173,0,0,0-.337,1.038l.409,2.387a2.167,2.167,0,0,1,.009.347l-1.712.046-2.69-1.414-2.69,1.414-1.707-.009a2.14,2.14,0,0,1,0-.384l.409-2.387a1.173,1.173,0,0,0-.337-1.038l-1.734-1.69a1.173,1.173,0,0,1,.65-2l2.4-.348a1.173,1.173,0,0,0,.883-.642l1.072-2.172a1.173,1.173,0,0,1,2.1,0Z" transform="translate(-176.688 -104.42)" fill="#fff5f5" />
        <g id="Group_241" data-name="Group 241" transform="translate(12.959 19.87)">
          <path id="Path_973" data-name="Path 973" d="M213.286,212.007l-4.169,2.192a1.175,1.175,0,0,0,1.706.853l2.144-1.127a1.173,1.173,0,0,1,1.092,0l2.144,1.127a1.175,1.175,0,0,0,1.706-.853l-4.169-2.192A.486.486,0,0,0,213.286,212.007Z" transform="translate(-209.117 -211.951)" fill="#efe2dd" />
        </g>
      </g>
    </svg>

  );
}

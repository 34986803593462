import React from 'react';
import { DeleteUserAccountManagementContainerStyled } from './styled';
import {
  Tabs,
  Loading,
  Inputs,
  // Buttons,
  // Icons,
  TableCustom,
  // Modal,
  // BoxDelete,
  Blocks,
} from 'components';
// import { ROUTE_PATH } from 'utils/constants';
import { connect } from 'react-redux';
import { adminService } from 'apiServices';
// import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { RENDER_MONTH } from 'utils/functions/date';

class DeleteUserAccountManagementContainer extends React.Component {
  state = { isLoading: true, };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData(`?per_page=10&offset=0`);
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData(`?per_page=10&offset=0`);
    }
  }

  fetchData = async (stringQuery) => {
    let res = await adminService.GET_CANCELLATIONS_LIST(
      stringQuery
    );

    if (res && res.success) {
      this.setState({
        isLoading: false,
        data: res.data,
        pagination: { ...res.page },
      });
    } else {
      this.setState({
        isLoading: false,
        data: [],
      });
    }
  };

  // handlePopup = () => {
  //   this.setState({
  //     isShowModal: true,
  //   });
  // };

  // handleClickCloseModal = () => {
  //   this.setState({
  //     isShowModal: false,
  //   });
  // };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // handleClickCreate = () => {
  //   this.props.history.push(
  //     `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/create`
  //   );
  // };

  // handleClickSort = (e) => { };

  handleClickDetail = (e) => {
    // this.props.history.push(
    //   `${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT_DETAIL}/${e.id}`
    // );
  };


  // FILTER PROCESS
  //==========================================
  //==========================================
  handleSelectedFilter = (key, e) => {
    switch (key) {
      case 'categoryFilter':
        this.setState(
          {
            categoryFilter: `department_ids=${e
              .filter((e) => e !== 'all')
              .join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case 'keywordFilter':
        this.setState(
          {
            keywordFilter: !e ? false : `keyword=${e}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };

  handleJoinQuery = () => {
    const { categoryFilter, keywordFilter } = this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (keywordFilter) {
      queryString.push(keywordFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchData(`?${queryString.join('&')}`);
      this.setState({
        queryFilterAll: `${queryString.join('&')}`,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchData();
      this.setState({
        queryFilterAll: false,
      });
      // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    // this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_USER_ACCOUNT}${query}`);
    this.fetchData(query);
  };

  render() {
    const {
      isLoading,
      pagination,
      data,
    } = this.state;
    return (
      <DeleteUserAccountManagementContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title="สาเหตุการลบบัญชี"
                  subTitle="Download Delete Account"
                  fontSize="34px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#092945"
                  colorBottom="#092945"
                  lineHeight="1.3"
                />
              </div>
              <div className="layer_two">
                <div className="search_layer">
                  <Inputs.InputSearch
                    theme_standard_search
                    onSubmit={(e) =>
                      this.handleSelectedFilter('keywordFilter', e)
                    }
                  />
                </div>
                <div className="btn_layer">
                  {/* <div className="body_btn">
                    <Buttons.BtnNormal
                      theme_standard_btn_normal_red
                      label="ลบ"
                      backgroundColor="#F0211D"
                      disabled={!(selectedData && selectedData.length > 0)}
                      svg={<Icons.Delete3 color="#FFFFFF" color2={(selectedData && selectedData.length > 0) ? '#f0211d' : '#9E9E9E'} />}
                      onClick={this.handlePopup}
                    />
                  </div>
                  <div className="body_btn">
                    <Buttons.BtnNormal
                      theme_standard_btn_normal_red
                      label="สร้าง"
                      backgroundColor="#21A526"
                      svg={<Icons.Plus color="#FFFFFF" />}
                      onClick={this.handleClickCreate}
                    />
                  </div> */}
                </div>
              </div>
              <div className="layer_table">
                <TableCustom.TableAction
                  theme_standard_table_user
                  columns={columns({
                    handleClickDetail: this.handleClickDetail,
                    // handleSelectedFilter: this.handleSelectedFilter,
                  })}
                  data={data}
                  pagination={pagination}
                  isCheckbox={false}
                  useNo
                  showPagination
                  onChangeSelected={this.handleChangeSelectedTable}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </Blocks.Container>
          </>
        )}
      </DeleteUserAccountManagementContainerStyled>
    );
  }
}

const columns = ({
  handleClickDetail,
  // handleSelectedFilter,
}) => [
    {
      title: '',
      field: '',
      headerStyle: { textAlign: 'center', width: '0px', flexShrink: 0, display: 'none' },
      style: { textAlign: 'center', width: '0px', flexShrink: 0, display: 'none' },
    },
    {
      title: 'ลำดับ',
      field: 'no',
      headerStyle: { textAlign: 'center', width: '40px', flexShrink: 0 },
      style: { textAlign: 'center', width: '40px', flexShrink: 0 },
    },
    {
      title: 'บริษัท',
      field: 'user.employee.department.company.name',
      headerStyle: { textAlign: 'left', minWidth: '10px', },
      style: { textAlign: 'left', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          style={{ minWidth: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          onClick={() => handleClickDetail(origin)}
          data-tip data-for={`tooltipCompany${origin.id}`}
        >
          {
            value
              ?
              <>
                <ReactTooltip id={`tooltipCompany${origin.id}`}>
                  <span>{value}</span>
                </ReactTooltip>
                {value}
              </>
              :
              <div className='gray'>ไม่มีข้อมูลพนักงาน</div>
          }
        </div>
      ),
    },
    {
      title: 'รหัสพนักงาน',
      field: 'user.employee.code',
      headerStyle: { textAlign: 'center', minWidth: '10px', },
      style: { textAlign: 'center', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          style={{ minWidth: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          onClick={() => handleClickDetail(origin)}
        >
          {(origin.user && origin.user.employee) ? `${origin.user.employee.code}` : <div className='gray'>ไม่มีข้อมูลพนักงาน</div>}
        </div>
      ),
    },
    {
      title: 'ชื่อ - นามสกุล',
      field: 'employee.user',
      headerStyle: { textAlign: 'center', minWidth: '10px', },
      style: { textAlign: 'center', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          style={{ minWidth: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          onClick={() => handleClickDetail(origin)}
        >
          {(origin.user && origin.user.employee) ? `${origin.user.employee.firstname} ${origin.user.employee.lastname}` : <div className='gray'>ไม่มีข้อมูลพนักงาน</div>}
        </div>
      ),
    },
    {
      title: 'วันที่ลบ',
      field: 'created_at',
      headerStyle: { textAlign: 'center', minWidth: '10px', },
      style: { textAlign: 'center', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          onClick={() => handleClickDetail(origin)}
          data-tip data-for={`tootipTime${origin.id}`}
        >
          <ReactTooltip id={`tootipTime${origin.id}`}>
            <div 
             style={{ minWidth: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {`${moment(origin.created_at).tz('Asia/Bangkok').format('DD')} ${RENDER_MONTH(moment(origin.created_at).tz('Asia/Bangkok').format('MM')).th} ${moment(origin.created_at).tz('Asia/Bangkok').year() + 543} ${moment(origin.created_at).tz('Asia/Bangkok').format('HH:mm')}`}
            </div>
          </ReactTooltip>
          {`${moment(origin.created_at).tz('Asia/Bangkok').format('DD')} ${RENDER_MONTH(moment(origin.created_at).tz('Asia/Bangkok').format('MM')).th} ${moment(origin.created_at).tz('Asia/Bangkok').year() + 543}`}
        </div>
      ),
    },
    {
      title: 'สาเหตุ',
      field: 'message',
      headerStyle: { textAlign: 'left', minWidth: '10px', },
      style: { textAlign: 'left', minWidth: '10px', },
      render: ({ origin, value }) => (
        <div
          style={{ minWidth: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          onClick={() => handleClickDetail(origin)}
        >{value}</div>
      ),
    },
  ];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteUserAccountManagementContainer);

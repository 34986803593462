import styled from "styled-components";

export const BtnIconStyled = styled.div`
  .theme_btn_icon {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : ({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.GRAY_13};
      padding: 8px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      cursor: pointer;
      .svg_show {
        margin-right: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
      .svg_show_back {
        margin-left: 8px;
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }

  .theme_btn_icon_delete {
    .body_btn {
      width: 100%;
      font-family: ${({ fontFamily }) =>
        fontFamily ? fontFamily : ({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.GRAY_1 : theme.COLORS.WHITE_1};
      padding: 8px;
      font-size: ${(props) =>
        props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
      cursor: pointer;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      .svg_show {
        /* margin-right: 8px; */
        width: ${(props) => (props.imgWidth ? props.imgWidth : "18px")};
        height: ${(props) => (props.imgHeight ? props.imgHeight : "18px")};
        display: flex;
        align-items: center;
      }
    }
  }
`;

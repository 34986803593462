import styled from "styled-components";

export const InputMaskTextStyled = styled.div`
    .box_mask_style {
        border-radius: 8px;
        padding: 10.6px 14px;
        border: 1px solid #EDEDED;
        background: ${({ disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_28 : ({ theme }) => theme.COLORS.WHITE_1};
        input {
            // border-radius: 12px;
            // border: 2px solid ${({ theme }) => theme.COLORS.BLUE_11}20;
            padding: 0px;
            border: none;
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            color: ${({ fontColor, disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_24 : fontColor ? fontColor : ({ theme }) => theme.COLORS.PURPLE_1};
            border-radius: 0px;
            background: ${({ disabled }) => disabled ? ({ theme }) => theme.COLORS.GRAY_28 : ({ theme }) => theme.COLORS.WHITE_1};
            width: 100%;
            ::placeholder {
                font-size: ${({ theme }) => theme.FONT.SIZE.S18};
                color: ${({ theme }) => theme.COLORS.GRAY_26};
                font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
            }
        }
    }
 
    .error {
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.RED_3};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        padding-top: 2px;
    }
`;

import { TotalDepartmentStyled } from './styled';
import { Blocks, Icons, Charts } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalDepartment = ({ departments_count, departments_count_total }) => {
  return (
    <TotalDepartmentStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="25px"
        paddingBottom="0px"
        paddingLeft="16px"
      >
        <div className="top_current">
          <div className="svg_show">
            <Icons.ManGraph />
          </div>
          <div className="label_show">
            <div>จำนวนแผนกที่เข้าร่วม</div>
            <div className="sub_title_show">Total Department</div>
          </div>
        </div>
        <div className="bottom_current">
          <div className="left_bottom">
            <div className="group_num">
              <div className="num_show">{departments_count && RenderCommaMoney(departments_count.toFixed(0))}</div>
              <div className="sub_label">แผนก</div>
            </div>
          </div>
          <div className="right_bottom">
            <div className="bar_show">
              <Charts.BarItem2
                theme_mini_bar_orange
                showLetLine
                percent={departments_count && departments_count_total ? ((departments_count / departments_count_total) * 100).toFixed(0) : 0}
                minHeight="200px"
                showPercent={departments_count && departments_count_total ? ((departments_count / departments_count_total) * 100).toFixed(0) : 0}
              />
            </div>
          </div>
        </div>
      </Blocks.Box>
    </TotalDepartmentStyled>
  );
};

export default TotalDepartment;

import styled from "styled-components";

export const OverallburnoutStyled = styled.div`
  .top_men {
    display: flex;
    flex-wrap: wrap;
    // margin-bottom: 75px;
    row-gap: 16px;
    .left_men_layout {
      flex: 1;
      display: flex;
      align-items: flex-start;
      .show_icon {
        margin-right: 15px;
        display: flex;
      }
    }
    .right_men_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .label_layout {
        margin-right: 14px;
        /* padding-top: 10px; */
        .date_show {
          display: flex;
          align-items: center;
          flex-direction: column;

          .show_icon {
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          }
          .show_label {
            min-width: 178px;
            margin-left: 8px;
            color: ${({ theme }) => theme.COLORS.GRAY_12};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
          }
        }
        .date_layout {
          color: ${({ theme }) => theme.COLORS.GRAY_12};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_regular, noto_sans_regular;
        }
      }
      .show_search {
      }
    }
  }
  .title_graph {
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    margin-top: 10px;
    padding-left: 10px;
  }
  .bottom_men {
    display: flex;
    margin-top: 30px;
    .body_graph {
      padding: 0px 20px;
      // column-gap: 30px;
      display: flex;
      overflow: auto;
      overflow-y: hidden;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cccccc;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #c2c2c2;
      }
      ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #ebebeb;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .group_graph {
        display: flex;
        align-items: center;
        .graph_show {
          margin-right: -10px;
        }
        .label_graph {
        }
      }
    }

    .seleton_body {
      width: 100%;
    }
  }
  .label_empty {
    margin-top: 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  @media (max-width: 1060px) {
    .bottom_men {
      flex-direction: column;
      .body_graph {
      }
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .bottom_men {
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .bottom_men {
    }
  }
`;

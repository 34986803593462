import { TotalScoreStyled } from './styled';
import { Blocks, Icons, Typographys } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const TotalScore = ({ data }) => {
  return (
    <TotalScoreStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="19px"
        paddingRight="13px"
        paddingBottom="19px"
        paddingLeft="13px"
      >
        <div className="top_like">
          <div className="show_icon">
            <Icons.Dumbel3 />
          </div>
          <Typographys.TopBottom
            theme_left_align
            label="รวมคะแนนที่กดรับ"
            labelBottom=" Total Coin"
            fontSizeLabelButtom="14px"
          />
        </div>
        <div className="mid_like">
          <div className="show_num">
            {data && RenderCommaMoney(data.toFixed(0))}
          </div>
          <div className="label">คะแนน</div>
        </div>
      </Blocks.Box>
    </TotalScoreStyled>
  );
};

export default TotalScore;

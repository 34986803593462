export default function MaleCircle({ color = '#009078' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_33150"
        data-name="Group 33150"
        transform="translate(-1048 -1040)"
      >
        <g
          id="Ellipse_2202"
          data-name="Ellipse 2202"
          transform="translate(1048 1040)"
          fill="#fff"
          stroke={color}
          strokeWidth="1"
        >
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="19.5" fill="none" />
        </g>
        <g id="male-symbol-svgrepo-com" transform="translate(1058.953 1048)">
          <path
            id="Path_34250"
            data-name="Path 34250"
            d="M22.386,0h-6.11a1.662,1.662,0,1,0,0,3.324h2.1L13.716,7.981a8.765,8.765,0,1,0,2.349,2.351l4.659-4.659v2.1a1.662,1.662,0,0,0,3.324,0V1.662A1.662,1.662,0,0,0,22.386,0ZM12.652,19.084a5.437,5.437,0,1,1,0-7.689A5.4,5.4,0,0,1,12.652,19.084Z"
            transform="translate(-3)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

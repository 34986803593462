export default function Arrow({ color = '#f0211d' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.715" height="25.072" viewBox="0 0 16.715 25.072">
      <g id="chevron-down-r" transform="translate(0 25.072) rotate(-90)">
        <path id="Path_274" data-name="Path 274" d="M0,12.536l4.179,4.179,8.357-8.358,8.357,8.358,4.179-4.179L12.536,0Z" transform="translate(0 0)" fill={color} />
      </g>
    </svg>

  );
}

import styled from "styled-components";

export const InputDatePikerCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  position: relative;
  .custom_date {
    padding: 9px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    color: ${({ theme }) => theme.COLORS.GRAY_23};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
  .arrow_icon {
    position: absolute;
    top: 13px;
    right: 9px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input {
        padding: 9px;
        font-size: 18px;
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: #131313;
        border: 1px solid #f0211d;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      min-width: 230px;
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__header react-datepicker-year-header {
          }
          .react-datepicker__month react-datepicker__monthPicker {
          }
        }
      }
    }
  }

  .theme_quarter {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        min-width: auto;
      }
    }
  }

  .theme_year {
    .custom_date {
      padding: 9px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        left: 2px !important;
        width: 230px !important;
        .react-datepicker {
          width: 100%;
          .react-datepicker__year-wrapper {
            max-width: 216px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .theme_log {
    .custom_date {
      padding: 9px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .arrow_icon {
      position: absolute;
      top: 13px;
      right: 9px;
    }
  }

  .theme_luky {
    .custom_date {
      padding: 9px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .arrow_icon {
      position: absolute;
      top: 13px;
      right: 9px;
    }
  }
`;

import React, { useState } from "react";
import { ChangePasswordFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons, Icons } from "components";
import { renderErrorStyle } from "utils/functions/renderErrorStyle";

const ChangePasswordForm = ({
  handleCancel,
  onSubmit,
  errorMessage,
  isResetPassword = true,
  height,
  DuplicatePassword,
  // handleClearTextError,
}) => {
  const schema = yup.object().shape({
    // email: yup.string().email('Email is required').matches(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Email is not valid'), //eslint-disable-line
    // email: yup.string().required("Username is required").matches(/^(?=.{6,32}$)(?![._\-])[a-zA-Z0-9._\-]+(?<![._\-])$/, 'format username ไม่ถูกต้อง'), //eslint-disable-line
    // email: yup.string().required("Username is required"),
  });

  const [_textTemp] = useState(
    `(@ ! " # $ % & ' ( ) * + , - . / : ; < = > ? [ \ ] ` + "^ _ ` { | } ~. )" // eslint-disable-line
  ); // eslint-disable-line
  const [_checkPassword, _setPassword] = useState(false);
  const [_checkPasswordHaveNumber, _setPasswordHaveNumber] = useState(false);
  const [_checkPasswordHavBigText, _setPasswordHaveBigText] = useState(false);
  const [_checkPasswordHaveSpecial, _setCheckPasswordHaveSpecial] =
    useState(false);
  const [_checkPasswordHavSmallText, _setPasswordHaveSmallText] =
    useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  const handleCheckPassword = (e) => {
    // let passwordCheck = new RegExp("^(?=.{6,32}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$");
    let passwordCheck = new RegExp("^(?=.{8,32}$).*$"); // มีอักขระอย่างน้อย 8 ตัวอักษร
    let passwordHaveNumber = new RegExp("^(?=.*[0-9]).*$"); // มีตัวเลขอย่างน้อย 1 ตัวเลข
    let passwordHaveBigText = new RegExp("^(?=.*[A-Z]).*$"); // มีตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว
    let passwordHaveSmallText = new RegExp("^(?=.*[a-z]).*$"); // มีตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว
    let passwordHaveSpecial = new RegExp(
      /[!@#$%^&*()\-+={}[\]:;"'<>,`_~.?\/|\\]/ // eslint-disable-line
    ); // eslint-disable-line
    // ตัวอักขระพิเศษ อย่างน้อย 1 ตัว 1 ตัว
    // let passwordHaveAllType = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$"); // มีตัวอักษรพิมพ์เล็กและพิมพ์ใหญ่อย่างน้อย 1 ตัว

    if (passwordCheck.test(e.target.value)) {
      _setPassword(true);
    } else {
      _setPassword(false);
    }

    if (passwordHaveSpecial.test(e.target.value)) {
      _setCheckPasswordHaveSpecial(true);
    } else {
      _setCheckPasswordHaveSpecial(false);
    }

    if (passwordHaveNumber.test(e.target.value)) {
      _setPasswordHaveNumber(true);
    } else {
      _setPasswordHaveNumber(false);
    }

    if (passwordHaveBigText.test(e.target.value)) {
      _setPasswordHaveBigText(true);
    } else {
      _setPasswordHaveBigText(false);
    }

    if (passwordHaveSmallText.test(e.target.value)) {
      _setPasswordHaveSmallText(true);
    } else {
      _setPasswordHaveSmallText(false);
    }
  };

  const renderDisable = () => {
    // return (
    //   !_checkPassword ||
    //   !_checkPasswordHaveNumber ||
    //   !_checkPasswordHavBigText ||
    //   !_checkPasswordHavSmallText ||
    //   !_checkPasswordHaveSpecial ||
    //   watchAllFields.confirmPassword !== watchAllFields.password
    // );
    if (isResetPassword) {
      return (
        !_checkPassword ||
        !_checkPasswordHaveNumber ||
        !_checkPasswordHavBigText ||
        !_checkPasswordHavSmallText ||
        !_checkPasswordHaveSpecial ||
        watchAllFields.confirmPassword !== watchAllFields.password
      );
    } else {
      return (
        !_checkPassword ||
        !_checkPasswordHaveNumber ||
        !_checkPasswordHavBigText ||
        !_checkPasswordHavSmallText ||
        !_checkPasswordHaveSpecial ||
        !watchAllFields.current_password ||
        watchAllFields.current_password === "" ||
        watchAllFields.confirmPassword !== watchAllFields.password
      );
    }
  };

  return (
    <ChangePasswordFormStyled height={height} onSubmit={handleSubmit(onSubmit)}>
      <div className="forgot_form_container">
        {!isResetPassword && (
          <div className="svg_c" onClick={handleCancel && handleCancel}>
            <Icons.CloseX3 />
          </div>
        )}
        <div className="logo_icon">ตั้งค่ารหัสผ่าน</div>
        {/* {isResetPassword && (
          <>
            <div className="sub_text">
              รหัสผ่านของคุณหมดอายุ เพื่อความปลอดภัยของข้อมูล
            </div>
            <div className="sub_text mb">
              เเละ ความสะดวกในการใช้งาน กรุณาตั้งค่ารหัสผ่านใหม่
            </div>
          </>
        )} */}
        {!isResetPassword && (
          <div className="box_input mt_mb">
            <div className="group_input">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTxtIcon
                    {...field}
                    theme_not_icon_standard
                    // iconSvg={<Icons.User />}
                    label="รหัสผ่านปัจจุบัน"
                    placeholder="รหัสผ่านปัจจุบัน"
                    errors={errors.current_password?.message}
                    changeType="text"
                    type="password"
                    showIconHide
                  />
                )}
                name="current_password"
                defaultValue=""
              />
            </div>
          </div>
        )}
        <div className="box_input">
          <div className="group_input">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTxtIcon
                  theme_not_icon_standard
                  {...field}
                  // iconSvg={<Icons.User />}
                  label="รหัสผ่าน"
                  placeholder="รหัสผ่าน"
                  errors={errors.password?.message}
                  onChangeCustom={handleCheckPassword}
                  style={renderErrorStyle(errorMessage)}
                  changeType="text"
                  type="password"
                  showIconHide
                />
              )}
              name="password"
              defaultValue=""
            />
          </div>
        </div>
        <div className="obj_chek_ch">
          <div className={`sw150_ch ${_checkPassword ? "green" : ""}`}>
            <div className="dot_t_ch">
              <Icons.CheckCorrect
                color={_checkPassword ? "#009E93" : "#D9D9D9"}
              />
            </div>
            <div>มีความยาวภาษาอังกฤษ อย่างน้อย 8 ตัว</div>
          </div>
        </div>
        <div className="obj_chek_ch">
          <div
            className={`sw150_ch ${_checkPasswordHaveNumber ? "green" : ""}`}
          >
            <div className="dot_t_ch">
              <Icons.CheckCorrect
                color={_checkPasswordHaveNumber ? "#009E93" : "#D9D9D9"}
              />
            </div>
            <div>ตัวเลข อย่างน้อย 1 ตัว</div>
          </div>
        </div>
        <div className="obj_chek_ch">
          <div
            className={`sw150_ch no_width ${
              _checkPasswordHavSmallText ? "green" : ""
            }`}
          >
            <div className="dot_t_ch">
              <Icons.CheckCorrect
                color={_checkPasswordHavSmallText ? "#009E93" : "#D9D9D9"}
              />
            </div>
            <div>มีตัวอักษรภาษาอังกฤษพิมพ์เล็ก</div>
          </div>
        </div>
        <div className="obj_chek_ch">
          <div
            className={`sw150_ch ${_checkPasswordHavBigText ? "green" : ""}`}
          >
            <div className="dot_t_ch">
              <Icons.CheckCorrect
                color={_checkPasswordHavBigText ? "#009E93" : "#D9D9D9"}
              />
            </div>
            <div>มีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่</div>
          </div>
        </div>
        <div className="obj_chek_ch no_mt">
          <div
            className={`sw150_ch flex_start ${
              _checkPasswordHaveSpecial ? "green" : ""
            }`}
          >
            <div className="dot_t_ch">
              <Icons.CheckCorrect
                color={_checkPasswordHaveSpecial ? "#009E93" : "#D9D9D9"}
              />
            </div>
            <div>
              <div>ตัวอักขระพิเศษ อย่างน้อย 1 ตัว</div>
              <div>{_textTemp}</div>
            </div>
          </div>
        </div>
        <div className="mt32">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputTxtIcon
                theme_not_icon_standard
                {...field}
                // iconSvg={<Icons.User />}
                label="ยืนยันรหัสผ่าน"
                placeholder="ยืนยันรหัสผ่าน"
                errors={errors.confirmPassword?.message}
                // onChangeCustom={handleCheckPassword}
                style={renderErrorStyle(errorMessage)}
                changeType="text"
                type="password"
                showIconHide
              />
            )}
            name="confirmPassword"
            defaultValue=""
          />
        </div>
        {DuplicatePassword && (
          <div className="error_show">
            รหัสผ่านนี้เคยใช้งานแล้ว กรุณาเปลี่ยนรหัสผ่าน
          </div>
        )}
        {errorMessage && <div className="error_show">{errorMessage}</div>}
        <div className="btn_layout">
          <Buttons.BtnNormal
            theme_standard_btn_log_in
            label="ยืนยัน"
            type="submit"
            disabled={renderDisable()}
          />
        </div>
      </div>
    </ChangePasswordFormStyled>
  );
};

ChangePasswordForm.propTypes = {};

export default ChangePasswordForm;

import React from 'react';
import { ComponentContainerStyled } from './styled';
import { NavBar } from 'widgets';

class ComponentContainer extends React.Component {
  render() {
    return (
      <ComponentContainerStyled>
        <fieldset>
          <legend>Button</legend>
          button hear
        </fieldset>

        <fieldset>
          <legend>Input</legend>
          Input hear
        </fieldset>
        <div className="layout_1">
          <NavBar theme_standard_navbar showRight />
        </div>
      </ComponentContainerStyled>
    );
  }
}

export default ComponentContainer;

import styled from 'styled-components';

export const GroupDetailStyled = styled.div`
  .article_layout {
    display: flex;
    .img_show {
      width: 35%;
      border-radius: 12px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      background-image: url(${({ src }) => src});
      height: 207px;
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .detail_show {
      width: 65%;
      margin-left: 18px;
      overflow: auto;
      .title_group {
        display: flex;
        justify-content: space-between;
        .show_t {
          width: 72%;
          white-space: pre-wrap;
          word-break: break-word;
          .title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S32};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
          }
          .show_value {
            margin-top: 17px;
            .detail_show_value {
              font-size: ${({ theme }) => theme.FONT.SIZE.S24};
              font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
              color: ${({ theme }) => theme.COLORS.BLACK_1};
              p {
                margin: 0px;
              }
              ul {
                margin: 0px;
              }
              ol {
                margin: 0px;
              }
            }
            .status_show {
              font-size: ${({ theme }) => theme.FONT.SIZE.S22};
              font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            }
          }
        }
        .btn_t {
          display: flex;
          padding-left: 15px;
          width: 200px;
          column-gap: 16px;
          justify-content: flex-end;
        }
      }
    }
  }
`;

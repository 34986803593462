export default function Scales() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.137"
      height="32.166"
      viewBox="0 0 32.137 32.166"
    >
      <g
        id="_051-weight"
        data-name="051-weight"
        transform="translate(-6.073 -6.044)"
      >
        <path
          id="Path_31978"
          data-name="Path 31978"
          d="M99.627,69.51H72.208a2.366,2.366,0,0,0-2.359,2.359V99.317a2.366,2.366,0,0,0,2.359,2.359H99.627a2.366,2.366,0,0,0,2.359-2.359V71.9A2.335,2.335,0,0,0,99.627,69.51Z"
          transform="translate(-63.776 -63.466)"
          fill="#646b79"
        />
        <path
          id="Path_31979"
          data-name="Path 31979"
          d="M179.014,101.308a7.964,7.964,0,0,0-15.921,0Z"
          transform="translate(-148.912 -85.447)"
          fill="#fcfcfd"
        />
        <path
          id="Path_31980"
          data-name="Path 31980"
          d="M242.46,106.822l-1.238-5.1-1.238,5.1a1.274,1.274,0,0,0,1.238,1.592h0A1.285,1.285,0,0,0,242.46,106.822Z"
          transform="translate(-219.08 -92.876)"
          fill="#f0582f"
        />
        <rect
          id="Rectangle_2007"
          data-name="Rectangle 2007"
          width="0.767"
          height="16.953"
          transform="translate(21.758 18.928)"
          fill="#bdbdbe"
        />
      </g>
    </svg>
  );
}

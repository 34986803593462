import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelGraph3Styled } from './styled';

const LabelGraph3 = ({ theme_standard_label_graph, data }) => {
  const customClass = cx({
    theme_standard_label_graph: theme_standard_label_graph,
  });
  const [selected, setSelected] = useState(1);

  const handleClick = (key) => {
    setSelected(key);
  };

  return (
    <LabelGraph3Styled>
      <div className={customClass}>
        {
          data &&
          <div className='group_left_show'>
            {
              data.map((e, i) => (
                e &&
                <div
                  key={i + 1}
                  className={`row_label ${selected === i + 1 ? 'active' : ''}`}
                  style={{ borderColor: e.color }}
                  onClick={() => handleClick(i + 1)}
                >
                  <div className="circle">
                    <div className="bg_color" style={{ background: e.color }} />
                  </div>
                  <div className="label_layer">
                    <div className="group_label">
                      <div>{e.label}</div>
                      <div className="sub_label">{e.labelBottom}</div>
                    </div>
                    <div className="percent">{e.percent}</div>
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>
    </LabelGraph3Styled>
  );
};

LabelGraph3.propTypes = {};

export default LabelGraph3;

export default function renderIcon({
  color = "#002366",
  width = "20.625",
  height = "21.933",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        id="Group_57004"
        data-name="Group 57004"
        transform="translate(-421.5 -191.5)"
      >
        <rect
          id="Rectangle_17991"
          data-name="Rectangle 17991"
          width="9.158"
          height="6.541"
          rx="1"
          transform="translate(422 192)"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
        <rect
          id="Rectangle_17992"
          data-name="Rectangle 17992"
          width="9.158"
          height="6.541"
          rx="1"
          transform="translate(432.467 206.391)"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
        <rect
          id="Rectangle_17993"
          data-name="Rectangle 17993"
          width="9.158"
          height="13.083"
          rx="1"
          transform="translate(422 199.85)"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
        <rect
          id="Rectangle_17994"
          data-name="Rectangle 17994"
          width="9.158"
          height="13.083"
          rx="1"
          transform="translate(432.467 192)"
          fill={color}
          stroke="#fff"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}

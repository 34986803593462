import React from 'react';
import { AddGroupRoleFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const AddGroupRoleForm = ({ onSubmit, groupId, perMissions }) => {
  const schema = yup.object().shape({
    admin: yup.object().required('required'),
  });

  const {
    control,
    setValue,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const _handleSubmmit = (e) => {
    setTimeout(() => {
      setValue('admin', '');
    }, 1000);
    onSubmit(e);
  };

  return (
    <AddGroupRoleFormStyled>
      <form onSubmit={handleSubmit(_handleSubmmit)}>
        <div className="box_form">
          <div className="input_show">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => (
                <Inputs.InputSuggestHealthyClub
                  value={value}
                  groupId={groupId}
                  onChangeValue={onChange}
                  errors={errors.admin?.message}
                />
              )}
              name="admin"
              defaultValue=""
            />
          </div>
          {
            perMissions &&
            <div className="btn_submit">
              <Buttons.BtnNormal
                theme_only_border_blue
                label="สร้าง"
                type="submit"
              />
            </div>
          }
        </div>
      </form>
    </AddGroupRoleFormStyled>
  );
};

AddGroupRoleForm.propTypes = {};

export default AddGroupRoleForm;

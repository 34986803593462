import styled from "styled-components";

export const TextOTPStyled = styled.div`
.label_otp {
  margin-bottom: 12px;
  color: ${({ theme }) => theme.COLORS.GRAY_23};
  font-size: ${({ theme }) => theme.FONT.SIZE.S24};
  font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
}
.body_otp {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}
.inputStyle {
  width: 50px !important;
  height: 50px !important;
  color: ${({ theme }) => theme.COLORS.GRAY_23};
  font-size: ${({ theme }) => theme.FONT.SIZE.S24};
  font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
}
.errors {
  margin-top: 3px;
  font-size: ${({ theme }) => theme.FONT.SIZE.S12};
  color: ${({ theme }) => theme.COLORS.RED_1};
}
`;

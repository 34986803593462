import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BarPercentStyled } from './styled';

const BarPercent = ({
  theme_standard_bar_percent,
  theme_normal_bar_percent,
  percent,
  showPercent,
}) => {
  const customClass = cx({
    theme_standard_bar_percent: theme_standard_bar_percent,
    theme_normal_bar_percent: theme_normal_bar_percent,
  });
  return (
    <BarPercentStyled>
      <div className={customClass}> 
        <div className={`left_bar ${percent <= 0 ? 'blank' : ''}`} style={{ width: `${percent}%` }}>
          {showPercent && (
            <div className="value_percent">
              <span className='number_show'>
                {percent ? percent.toFixed(0) : '0'}%
              </span>
            </div>
          )}
        </div>
        <div className="right_bar" />
      </div>
    </BarPercentStyled>
  );
};

BarPercent.propTypes = {};

export default BarPercent;

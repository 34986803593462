import styled from 'styled-components';

export const HealthyClubDetailContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .layer_one {
    display: flex;
    padding-bottom: 10px;
  }
  .btn_action_show {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .w210 {
      width: 210px;
      .gorup_box_text {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .small_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.GRAY_24};
        }
      }
    }
    
    .del_layout {
      margin-left: 16px;
      width: 120px;
    }
  }
  .layer_two {
    margin-top: 24px;
  }
  .layer_three {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    .left_three {
      width: 448px;
      flex-shrink: 0;
    }
    .right_three {
      flex: 1;
      // min-width: 581px;
    }
  }
  .table_rank_show {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .label_del {
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_8};
    display: flex;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_three {
      padding: 4px;
      overflow: auto;
    }
    .layer_quiz {
      .have_quiz {
        .detail_quiz {
          .box_detail {
            .opt_detail {
              .group_detail {
                padding-right: 15%;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
  }
`;

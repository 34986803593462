import { MainDashBoardBMIType2Styled } from "./styled";
import { Blocks, Typographys, Charts, Icons } from "components";
import { RENDER_MONTH } from "utils/functions/date";
import moment from "moment";

const MainDashBoardBMIType2 = ({ data }) => {
  const renderPercentChart = (bmi) => {
    let percent;
    if (bmi < 18.5) { //     ค่า BMI < 18.5 แสดงถึง อยู่ในเกณฑ์น้ำหนักน้อยหรือผอม
      percent = 5 + bmi > 24 ? 24 : 5 + bmi
    } else if (bmi >= 18.5 && bmi < 23) { // ค่า BMI 18.5 – 22.90 แสดงถึง อยู่ในเกณฑ์ปกติ
      percent = 24 + bmi;
    } else if (bmi >= 23 && bmi < 25) { // ค่า BMI 23 – 24.90 แสดงถึง น้ำหนักเกิน
      percent = 60 + bmi;
    } else if (bmi >= 25) { // ค่า BMI 25 – 29.90 แสดงถึง โรคอ้วนระดับที่ 1.
      percent = 86 + bmi > 100 ? 100 : 86 + bmi
    }

    return percent;
    
    // let percent;
    // if (bmi <= 14.5) {
    //   percent = 0;
    // } else if (bmi >= 34) {
    //   percent = 100;
    // } else {
    //   percent = ((bmi - 14.5) / 19.5) * 100;
    // }
    // return percent;
  };

  return (
    <MainDashBoardBMIType2Styled>
      <Blocks.Box theme_standard_box height={data ? "434px" : "117px"}>
        <div className="container">
          <div className="top_container">
            <div className="left_top">
              <Typographys.TopBottom
                theme_left_align
                label="BMI เฉลี่ยล่าสุด"
                labelBottom="latest updated Avg. BMI"
                fontSizeLabelButtom="14px"
                fontSize="18px"
              />
            </div>
            {data && (
              <div className="right_top">
                <Typographys.ShowDate
                  theme_standard_show_date_mini
                  month1={RENDER_MONTH(moment(data?.stat_date).format("MM")).th}
                  month2={RENDER_MONTH(moment(data?.stat_date).format("MM")).en}
                  year={moment(data?.stat_date).format("YYYY")}
                />
              </div>
            )}
          </div>
          <div className="bottom_container">
            {data ? (
              <>
                <div className="chart_wrap_container">
                  <div className="item_bar_wrap">
                    <Charts.BarItem3
                      score={
                        data && data.all_bmi_percent && data.all_bmi_percent
                      }
                      difference="begin"
                      barIcon={<Icons.BuildingCircle color="#575D96" />}
                      txt_head="ทั้งบริษัท"
                      txt_sub="company"
                      txtPercent={renderPercentChart(data?.all_bmi_avg)}
                      txt_score={
                        data && data.all_bmi_avg && data.all_bmi_avg
                          ? data.all_bmi_avg.toFixed(2)
                          : "-"
                      }
                    />
                  </div>
                  <div className="item_bar_wrap">
                    <Charts.BarItem3
                      theme_blue
                      score={
                        data && data.male_bmi_percent && data.male_bmi_percent
                      }
                      difference="up"
                      barIcon={<Icons.MaleCircle color="#003C58" />}
                      txt_head="ชาย"
                      txt_sub="Men"
                      txtPercent={renderPercentChart(data?.male_bmi_avg)}
                      txt_score={
                        data && data.male_bmi_avg && data.male_bmi_avg
                          ? data.male_bmi_avg.toFixed(2)
                          : "-"
                      }
                    />
                  </div>
                  <div className="item_bar_wrap">
                    <Charts.BarItem3
                      theme_orange
                      score={
                        data &&
                        data.female_bmi_percent &&
                        data.female_bmi_percent
                      }
                      difference="down"
                      barIcon={<Icons.FamaleCircle color="#E86751" />}
                      txt_head="หญิง"
                      txt_sub="Women"
                      txtPercent={renderPercentChart(data?.female_bmi_avg)}
                      txt_score={
                        data && data.female_bmi_avg && data.female_bmi_avg
                          ? data.female_bmi_avg.toFixed(2)
                          : "-"
                      }
                    />
                  </div>
                  <div className="chart_bg">
                    <div className="layer h_40 pink">
                      <div className="txt_box">
                        <div className="bmi_title">ภาวะอ้วน</div>
                        <div className="bmi_sub_title">Obese</div>
                      </div>
                      <div className="txt_box pd_50">
                        <div className="bmi_title">ภาวะอ้วน</div>
                        <div className="bmi_sub_title">Obese</div>
                      </div>
                    </div>
                    <div className="layer h_48 orange">
                      <div className="txt_box">
                        <div className="bmi_title">น้ำหนักเกิน</div>
                        <div className="bmi_sub_title">Overweight</div>
                      </div>
                      <div className="txt_box pd_50">
                        <div className="bmi_title">น้ำหนักเกิน</div>
                        <div className="bmi_sub_title">Overweight</div>
                      </div>
                    </div>
                    <div className="layer h_72 pink_dark">
                      <div className="txt_box">
                        <div className="bmi_title">สมส่วน</div>
                        <div className="bmi_sub_title">Normal</div>
                      </div>
                      <div className="txt_box pd_50">
                        <div className="bmi_title">สมส่วน</div>
                        <div className="bmi_sub_title">Normal</div>
                      </div>
                    </div>
                    <div className="layer h_40  blue">
                      <div className="txt_box">
                        <div className="bmi_title">ผอม</div>
                        <div className="bmi_sub_title">Underweight</div>
                      </div>
                      <div className="txt_box pd_50">
                        <div className="bmi_title">ผอม</div>
                        <div className="bmi_sub_title">Underweight</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="label_empty">ยังไม่มีข้อมูล</div>
            )}
          </div>
        </div>
      </Blocks.Box>
    </MainDashBoardBMIType2Styled>
  );
};

export default MainDashBoardBMIType2;

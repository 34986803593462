import styled from 'styled-components';

export const MissionHistoryContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 280px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label_empty {
    text-align: center;
    padding-top: 40px;
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  .select_layout {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .block_2_col_layout {
    display: flex;
    // flex-wrap: wrap;
    // margin: 0 -12px;
    // overflow: auto;
    padding: 10px 2px;
    column-gap: 16px;
    row-gap: 16px;
    overflow: auto;
    &.mb_47 {
      margin-bottom: 37px;
    }
    .col_2 {
      &.flex_1 {
        flex: 1;
        min-width: 712px;
        padding-right: 2px;
      }
      &.m_lr_c {
        margin-left: auto;
        margin-right: auto;
      }
      &.w_312 {
        flex-shrink: 0;
        width: 312px;
      }
      &.w_384 {
        flex-shrink: 0;
        width: 384px;
        padding-right: 3px;
      }
      &.mb_14 {
        margin-bottom: 10px;
      }
      &.min_w_786 {
        min-width: 786px;
      }
      .img_show {
        height: 100%;
        width: 100%;
        height: 100%;
        min-height: 176px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .participants_wrap_layout {
    margin-bottom: 24px;
  }
  .rank_layout {
    margin-top: 24px;
  }
`;

import { CompanyMisssionStyled } from "./styled";
import { Blocks, Icons, Label } from "components";
import React, { useState, useEffect } from "react";
import { RENDER_MONTH, DIF_DATE } from "utils/functions/date";
import moment from "moment";
import "moment-timezone";

const CompanyMisssion = ({ dataCompany, dataMission, isHistory = false }) => {
  const [_startDate, setStartDate] = useState("");
  const [_endDate, setEndDate] = useState("");

  useEffect(() => {
    if (dataMission) {
      let dateStart = moment(dataMission.begin_date)
        .tz("Asia/Bangkok")
        .format("D");
      let monthStart = RENDER_MONTH(
        moment(dataMission.begin_date).tz("Asia/Bangkok").format("MM")
      ).th;
      let yearStart =
        moment(dataMission.begin_date).tz("Asia/Bangkok").year() + 543;
      let dateEnd = moment(dataMission.end_date).tz("Asia/Bangkok").format("D");
      let monthEnd = RENDER_MONTH(
        moment(dataMission.end_date).tz("Asia/Bangkok").format("MM")
      ).th;
      let yearEnd =
        moment(dataMission.end_date).tz("Asia/Bangkok").year() + 543;

      setStartDate(dateStart + " " + monthStart + " " + yearStart);
      setEndDate(dateEnd + " " + monthEnd + " " + yearEnd);
    }
  }, [dataMission]);

  return (
    <CompanyMisssionStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="33px"
        paddingBottom="20px"
        paddingLeft="16px"
      >
        <div className="top_current">
          <div className="top_left_current">
            {/* <div className="show_icon">
              <Icons.Company2 />
            </div> */}
            <Label.TopBottom
              theme_left_align
              label="Company Mission Season"
              // label={dataCompany?.name}
              // labelBottom={`Company Mission Season ${dataMission?.order}`}
              fontSize="15px"
              fontColor="#707070"
              fontFamilyTop="noto_sans_regular, noto_sans_thai_thin"
              colorBottom="#707070"
            />
          </div>
          {!isHistory && (
            <div className="top_right_current">
              เหลือเวลาอีก{" "}
              <span className="date">
                {dataMission &&
                DIF_DATE(
                  moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
                  dataMission.end_date
                ) < 0
                  ? 0
                  : DIF_DATE(
                      moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
                      dataMission.end_date
                    )}
              </span>{" "}
              วัน
            </div>
          )}
        </div>
        <div className="mid_current">{dataMission?.name}</div>
        <div className="bottom_current">
          <div className="show_icon_bottom">
            <Icons.Calendar />
          </div>
          <div className="date_detail">
            {`ระยะเวลา ${_startDate} - ${_endDate}`}
          </div>
        </div>
      </Blocks.Box>
    </CompanyMisssionStyled>
  );
};

export default CompanyMisssion;

import React, { useState, useEffect } from "react";
import cx from "classnames";
import { NavBarStyled } from "./styled";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import { Inputs, Icons } from 'components';
// import { setReduxCompanySelected } from "store/actions";
import { renderConfigSystemFN } from "utils/functions/renderCofigSystem";
// import { ROUTE_PATH } from "utils/constants";

const NavBar = ({ theme_standard_navbar, routes }) => {
  const customClass = cx({
    theme_standard_navbar: theme_standard_navbar,
  });

  let history = useHistory();
  let location = useLocation();
  const { authenRedux } = useSelector((state) => state);
  // const dispatch = useDispatch();

  const [active, setActive] = useState("main");
  const [, setIsShowCompFilter] = useState();

  useEffect(() => {
    if (location?.pathname) {
      const splitPathName = location.pathname.split("/");
      if (splitPathName[splitPathName.length - 1] === "administrator") {
        let findData = routes.find(
          (e) =>
            e.key === "administrator" ||
            e.key === "main" ||
            e.key === "wellbeing" ||
            e.key === "bmi" ||
            e.key === "msd" ||
            e.key === "step"
        );
        if (findData) {
          setActive("main");
        } else {
          setActive(routes[0].key);
          history.push(routes[0].path);
        }
      } else {
        if (splitPathName.length > 3) {
          setActive(splitPathName[2]);
        } else {
          setActive(splitPathName[splitPathName.length - 1]);
        }
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenRedux.role) {
      setIsShowCompFilter(
        renderConfigSystemFN(authenRedux, location?.pathname)
          ?.isShowFilterRightNavbar
      );
    }
  }, [authenRedux, active]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickMenu = (e) => {
    setActive(e.key);
    history.push(e.path);
  };

  // const handleChangeCompany = (e) => {
  //   dispatch(
  //     setReduxCompanySelected({
  //       id: e.value,
  //       name: e.label,
  //       isParent: e.isParent,
  //     })
  //   );
  //   handleCheckRedirectMainMenu(e);
  // };

  // const handleCheckRedirectMainMenu = (value) => {
  //   const split = location?.pathname.split("/");
  //   if (
  //     split[2] === "backoffice" &&
  //     split[3] !== "accountmanagement" &&
  //     value &&
  //     value.isParent
  //   ) {
  //     history.push(ROUTE_PATH.BACK_OFFICE);
  //   }
  // };

  return (
    <NavBarStyled>
      <div className={customClass}>
        <div className="group_menu">
          {routes &&
            routes
              .filter((e) => !e.notShowSidebar)
              .map((e, i) => (
                <div
                  key={i}
                  className={`tab ${active === e.key ? "active" : ""}`}
                  onClick={() => {
                    handleClickMenu(e);
                  }}
                >
                  <div className="icon_show">
                    {e.icon(active === e.key ? true : false)}
                  </div>
                  <div>{e.name}</div>
                </div>
              ))}
        </div>
        {/* {isShowCompFilter && companyListRedux && (
          <div className="group_right">
            <div className="input_wrap">
              <Inputs.InputDropDownFilterNest
                theme_reward
                label="แสดงข้อมูลของ:"
                iconSvgClose={<Icons.RectArrowDown />}
                iconSvgOpen={<Icons.RectArrowUp />}
                placeholder={`กรุณาเลือก`}
                defaultValue={
                  !companySelectedRedux
                    ? companyListRedux &&
                    companyListRedux.length > 0 && {
                      label: companyListRedux[0].name,
                      value: companyListRedux[0].id,
                    }
                    : {
                      label: companySelectedRedux.name,
                      value: companySelectedRedux.id,
                    }
                }
                onChange={handleChangeCompany}
                options={(handleSelectedOption) =>
                  companyListRedux &&
                  companyListRedux.map((e, i) => (
                    <div key={i} className="layout_1">
                      <div
                        className="title h1"
                        onClick={() =>
                          handleSelectedOption({
                            label: e.name,
                            value: e.id,
                            isParent: e.is_parent,
                          })
                        }
                      >
                        {e.name}
                      </div>
                      {e.associates &&
                        e.associates.map((f, j) => (
                          <div className="layout_2">
                            <div
                              key={j}
                              className="title h2"
                              onClick={() =>
                                handleSelectedOption({
                                  label: f.name,
                                  value: f.id,
                                  isParent: f.is_parent,
                                })
                              }
                            >
                              {f.name}
                            </div>
                            {f.associates && (
                              <div className="layout3">
                                {f.associates.map((g, k) => (
                                  <div
                                    key={k}
                                    className="title h3"
                                    onClick={() =>
                                      handleSelectedOption({
                                        label: g.name,
                                        value: g.id,
                                        isParent: g.is_parent,
                                      })
                                    }
                                  >
                                    {g.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ))
                }
              />
            </div>
          </div>
        )} */}
      </div>
    </NavBarStyled>
  );
};

NavBar.propTypes = {};

export default NavBar;

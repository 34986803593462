import styled from "styled-components";

export const InputDatePikerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  position: relative;
  .custom_date {
    padding: 9px;
    font-size: 18px;
    font-family: noto_sans_thai_semi_bold, noto_sans_regular;
    color: #131313;
    border: 2px solid ${({ theme }) => theme.COLORS.BLUE_15};
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .arrow_icon {
    position: absolute;
    top: 13px;
    right: 9px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input {
        padding: 9px;
        font-size: 18px;
        font-family: noto_sans_thai_semi_bold, noto_sans_regular;
        color: #131313;
        border: 1px solid #f0211d;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      z-index: 2;
      min-width: 230px;
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__header react-datepicker-year-header {
          }
          .react-datepicker__month react-datepicker__monthPicker {
          }
        }
      }
    }
  }

  .theme_date_lucky {
    .custom_date {
      padding: 7.2px 9px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      background: ${({ theme, disabled }) => disabled ? theme.COLORS.GRAY_28 : theme.COLORS.WHITE_1};
    }
    .arrow_icon {
      position: absolute;
      top: 13px;
      right: 9px;
    }
  }
`;

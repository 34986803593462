import styled from 'styled-components';

export const WellLifeContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 486px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block_2_col_layout {
    display: flex;
    padding: 10px 0px 10px 5px;
    column-gap: 24px;
    row-gap: 24px;
    margin: 0 -5px;
    overflow: auto;
    .col_2 {
      &.flex_1 {
        flex: 1;
      }
      &.w_368 {
        flex-shrink: 0;
        width: 368px;
      }
      &.mw_780 {
        min-width: 780px;
      }
      .b2c_block_wrap {
        &.h100 {
          height: 100%;
        }
        &.mb_24 {
          margin-bottom: 24px;
        }
      }
      .wrap_mr5_overflow {
        margin-right: 5px;
        height: 100%;
      }
      .obj_four {
        margin-right: 3px;
        .obj_four {
          display: flex;
          justify-content: space-between;
          .obj_show {
            width: 49%;
          }
          &.mb {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .block_2_col_layout {
      .col_2 {
        .obj_four {
          .obj_four {
            .obj_show {
              width: 48.5%;
            }
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const GroupRoleContainerStyled = styled.div`
  
  
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .con_box {
    background: #FFFFFF;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 45px 30px;
    padding-bottom: 50px;
    min-height: calc(100vh - 100px);
    .loading {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
    .label_del {
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_8};
      display: flex;
      align-items: center;
    }
    .form_layout_show {
      margin-top: 32px;
      .title_form {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .lbl_t {
          width: 85px;
          flex-shrink: 0;
        }
        .hText {
          color: ${({ theme }) => theme.COLORS.BLUE_25};
          margin: 0px 5px;
          min-width: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .sub_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
      }
      .label_del {
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_8};
        display: flex;
        align-items: center;
        text-align: center;
      }
      .show_form_show {
        margin-top: 16px;
      }
    }
    .table_layout_show {
      margin-top: 77px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK10};
      .table_line_1 {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        .lbl_line_1 {
          width: 85px;
          flex-shrink: 0;
        }
        .lbl_back {
          width: 108px;
          flex-shrink:0;
        }
        .hText {
          color: ${({ theme }) => theme.COLORS.BLUE_25};
          margin: 0px 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 10px;
        }
        .sub_text {
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.GRAY_23};
        }
      }
      .table_show_rank {
        margin-top: 43px;
      }
    }
  }
`;

export default function Reward5() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.072" height="33.211" viewBox="0 0 28.072 33.211">
      <g id="medal" transform="translate(-39.592)">
        <path id="Path_11724" data-name="Path 11724" d="M46.845,221.248l-7.253,13.664,6.586-.608,3.188,5.8,7.253-13.664Z" transform="translate(0 -206.89)" fill="#e95454" />
        <g id="Group_3324" data-name="Group 3324" transform="translate(45.584 14.358)">
          <path id="Path_11725" data-name="Path 11725" d="M134.012,268.083a.926.926,0,0,0-.435-1.186l-.417-.222a.893.893,0,0,1,.859-1.565l1.18.626a.744.744,0,0,0,1.009-.314l.029-.056a.7.7,0,0,0-.271-.993l-3.528-1.872a.929.929,0,0,1-.44-1.187.9.9,0,0,1,1.229-.411l3.273,1.737a.753.753,0,0,0,1.014-.311l.019-.048.005-.01a.784.784,0,0,0-.323-1.066l-1.836-.974a.929.929,0,0,1-.437-1.192.9.9,0,0,1,1.229-.411L138.2,259.7a.757.757,0,0,0,1.028-.349l.005-.01a.048.048,0,0,0,.005-.01.756.756,0,0,0-.288-1.047l-2.188-1.161a.927.927,0,0,1-.435-1.184.9.9,0,0,1,1.229-.411l5.4,2.865L135.7,272.062l-2-3.633A.849.849,0,0,0,134.012,268.083Z" transform="translate(-131.92 -253.21)" fill="#ed6362" />
          <path id="Path_11726" data-name="Path 11726" d="M219.574,221.248l7.253,13.664-6.586-.608-3.188,5.8L209.8,226.435Z" transform="translate(-204.746 -221.248)" fill="#ed6362" />
        </g>
        <path id="Path_11727" data-name="Path 11727" d="M218.758,268.1a.926.926,0,0,1,.435-1.186l.417-.222a.893.893,0,0,0-.859-1.565l-1.18.626a.744.744,0,0,1-1.009-.314l-.029-.056a.7.7,0,0,1,.271-.993l3.529-1.873a.929.929,0,0,0,.44-1.187.9.9,0,0,0-1.229-.411l-3.273,1.737a.753.753,0,0,1-1.014-.311l-.019-.048-.005-.01a.784.784,0,0,1,.323-1.066l1.836-.974a.929.929,0,0,0,.437-1.192.9.9,0,0,0-1.229-.411l-2.03,1.079a.757.757,0,0,1-1.028-.349.05.05,0,0,1-.005-.01.048.048,0,0,1-.005-.01.756.756,0,0,1,.288-1.047l2.188-1.161a.927.927,0,0,0,.435-1.184.9.9,0,0,0-1.229-.411l-5.4,2.865,7.253,13.664,2-3.633A.864.864,0,0,1,218.758,268.1Z" transform="translate(-159.177 -238.867)" fill="#e95454" />
        <circle id="Ellipse_1497" data-name="Ellipse 1497" cx="12.118" cy="12.118" r="12.118" transform="translate(41.51 0)" fill="#ffcc5b" />
        <path id="Path_11728" data-name="Path 11728" d="M83.415,23.834H75.735a1.091,1.091,0,0,1-1.087-1.087l.008-.075v-.017a1.135,1.135,0,0,1,1.137-1.145h5.322a1.346,1.346,0,0,0,1.37-1.228,1.3,1.3,0,0,0-1.295-1.361H75.552a1.1,1.1,0,0,1-1.079-1.145v-.033A1.1,1.1,0,0,1,75.552,16.6h5.9a1.343,1.343,0,0,0,1.361-1.22,1.3,1.3,0,0,0-1.295-1.361H77.2a12.117,12.117,0,0,0,0,22.414h5.485a1.343,1.343,0,0,0,1.361-1.22,1.3,1.3,0,0,0-1.295-1.36h-6.24a1.291,1.291,0,0,1-1.29-1.361,1.341,1.341,0,0,1,1.362-1.222H79a1.293,1.293,0,0,0-.037-2.585H75.3a1.078,1.078,0,0,1-1.079-1.087V27.5a1.021,1.021,0,0,1,1.021-1.087h8.1a1.346,1.346,0,0,0,1.37-1.22A1.3,1.3,0,0,0,83.415,23.834Z" transform="translate(-28.143 -13.106)" fill="#fdbc4b" />
        <path id="Path_11729" data-name="Path 11729" d="M131.234,70.74a8.65,8.65,0,1,1,8.65-8.649A8.66,8.66,0,0,1,131.234,70.74Z" transform="translate(-77.606 -49.972)" fill="#ffdb70" />
        <path id="Path_11730" data-name="Path 11730" d="M237.794,62.449a8.654,8.654,0,0,0-8.154-8.625h-.806a1.025,1.025,0,0,0-1.038.93.989.989,0,0,0,.987,1.038h4.76a.984.984,0,0,1,.983,1.038,1.023,1.023,0,0,1-1.039.932h-1.845a.986.986,0,0,0,.028,1.972h2.8a.822.822,0,0,1,.822.829v.069a.778.778,0,0,1-.779.829h-6.177a1.027,1.027,0,0,0-1.045.93.989.989,0,0,0,.987,1.038h5.856a.832.832,0,0,1,.829.829l-.006.057v.012a.866.866,0,0,1-.867.873h-4.06a1.027,1.027,0,0,0-1.045.937.993.993,0,0,0,.987,1.038h4.3a.837.837,0,0,1,.822.873v.025a.837.837,0,0,1-.822.873h-4.5a1.025,1.025,0,0,0-1.038.93.989.989,0,0,0,.987,1.038h1.187A8.664,8.664,0,0,0,237.794,62.449Z" transform="translate(-175.516 -50.331)" fill="#fff" opacity="0.2" />
        <path id="Path_11731" data-name="Path 11731" d="M166.838,94H162.63l-1.3-4a.559.559,0,0,0-1.062,0l-1.3,4H154.76a.558.558,0,0,0-.328,1.01l3.4,2.473-1.3,4a.559.559,0,0,0,.86.624l3.4-2.473,3.4,2.473a.559.559,0,0,0,.86-.624l-1.3-4,3.4-2.473A.559.559,0,0,0,166.838,94Z" transform="translate(-107.171 -83.797)" fill="#ec9922" />
      </g>
    </svg>
  );
}

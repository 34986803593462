export default function renderIcon({ color = '#ffffff', width = '23.854', height = '22.364' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23.854 22.364">
      <g id="running" transform="translate(0 -15.996)">
        <g id="Group_13966" data-name="Group 13966" transform="translate(0.793 15.996)">
          <g id="Group_13965" data-name="Group 13965" transform="translate(0 0)">
            <path id="Path_19081" data-name="Path 19081" d="M37.99,24.382h-2.8v-2.1h0v0a2.133,2.133,0,0,0-.122-.7,2.8,2.8,0,1,0-2.63-2.3L29.728,17.4l-.011-.007a2.1,2.1,0,0,0-2.645.263L23.928,20.8a2.1,2.1,0,0,0,2.965,2.965l1.914-1.914,1.022.736-3.8,3.8-8.386,8.386A2.1,2.1,0,1,0,20.6,37.745l6.9-6.9,1.228,1.228-2.711,2.711a2.1,2.1,0,1,0,2.965,2.965l4.193-4.193a2.1,2.1,0,0,0,0-2.965l-2.711-2.711.663-.663A2.1,2.1,0,0,0,33.1,28.575H37.99a2.1,2.1,0,1,0,0-4.193Zm-2.8-6.989a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,35.194,17.394Zm-6.053,2.978a.7.7,0,0,0-.9.073L25.9,22.78a.7.7,0,1,1-.988-.988l3.145-3.145a.7.7,0,0,1,.878-.09l2.932,2.03a2.059,2.059,0,0,0-.256.217l-.785.785ZM19.614,36.757a.7.7,0,1,1-.988-.988l6.988-6.988a2.1,2.1,0,0,0,.41.578l.494.494ZM37.99,27.178H33.1a.7.7,0,0,1-.7-.7V25.66a.7.7,0,0,0-1.193-.494l-2.217,2.217a.7.7,0,0,0,0,.988l3.2,3.2a.7.7,0,0,1,0,.988L28,36.757a.7.7,0,1,1-.988-.988l3.2-3.2a.7.7,0,0,0,0-.988l-3.2-3.2a.7.7,0,0,1,0-.988L32.6,21.792a.7.7,0,0,1,1.193.493v2.8a.7.7,0,0,0,.7.7H37.99a.7.7,0,0,1,0,1.4Z" transform="translate(-17.025 -15.996)" fill={color} />
          </g>
        </g>
        <g id="Group_13968" data-name="Group 13968" transform="translate(1.491 18.791)">
          <g id="Group_13967" data-name="Group 13967" transform="translate(0 0)">
            <path id="Path_19082" data-name="Path 19082" d="M36.193,76H32.7a.7.7,0,1,0,0,1.4h3.494a.7.7,0,0,0,0-1.4Z" transform="translate(-32 -75.996)" fill={color} />
          </g>
        </g>
        <g id="Group_13970" data-name="Group 13970" transform="translate(1.491 24.382)">
          <g id="Group_13969" data-name="Group 13969">
            <path id="Path_19083" data-name="Path 19083" d="M36.193,196H32.7a.7.7,0,0,0,0,1.4h3.494a.7.7,0,0,0,0-1.4Z" transform="translate(-32 -195.996)" fill={color} />
          </g>
        </g>
        <g id="Group_13972" data-name="Group 13972" transform="translate(0 21.587)">
          <g id="Group_13971" data-name="Group 13971">
            <path id="Path_19084" data-name="Path 19084" d="M4.286,136H.7a.7.7,0,0,0,0,1.4H4.286a.7.7,0,0,0,0-1.4Z" transform="translate(0 -135.996)" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  )
}

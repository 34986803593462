import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { CurrentWellBeingStatContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { adminService } from "apiServices";
import { Loading, Cards, BGLoading } from "components";
import {
  MainDashBoardWellBeing,
  NumberOfDoResearch,
  NumberOfSmoking,
  MaleFemaleAverageResearch,
  MaleFemaleAverageAge,
  AverageDepartmentWellbeing,
  ExportRaw,
} from "widgets";
import moment from "moment";
import momentTZ from "moment-timezone";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CurrentWellBeingStatContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [statisticsWellbeingData, setStatisticsWellbeingData] = useState();
  const [genderTrendData, setGenderTrendData] = useState();
  const [genderAgeData, setGenderAgeData] = useState();
  const [deparmentData, setDeparmentData] = useState();
  const [isBgLoading, setIsBgLoading] = useState(false);

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchStatisticsWellbeing(
        `?period=weekly&stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      fetchGenderTrend("?period=weekly&offset=0&per_page=8"),
      fetchGenderAge(
        `?period=monthly&stat_date=${moment()
          .startOf("months")
          .format("YYYY-MM-DD")}`
      ),
      fetchDepartment(
        `?period=monthly&stat_date=${moment()
          .startOf("months")
          .format("YYYY-MM-DD")}`
      ),
    ]);
    if (res) {
      setStatisticsWellbeingData(res[0]);
      setGenderTrendData(res[1]);
      setGenderAgeData(res[2]);
      setDeparmentData(res[3]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchStatisticsWellbeing = async (stringQuery) => {
    let res = await adminService.GET_STATISTICS_COMPANY_WELLBEING_SUMMARY(
      companySelectedRedux.id,
      stringQuery
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchGenderTrend = async (stringQuery) => {
    let res = await adminService.GET_WELLBEING_STATISTICS_GENDER_TREND(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data.reverse();
    }
  };

  const fetchGenderAge = async (stringQuery) => {
    let res = await adminService.GET_WELLBEING_STATISTICS_GENDER_AGE(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      let tempData = {
        age_groups: res.data.age_groups.reverse(),
        stat_date: res.data.stat_date,
        updated_at: res.data.updated_at,
      };
      return tempData;
    }
  };

  const fetchDepartment = async (stringQuery) => {
    let res = await adminService.GET_WELLBEING_STATISTICS_DEPARTMENT(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      // return res.data;
      return sliceIntoChunks(
        res.data.departments.map((e) => ({
          id: e.id,
          name: e.name,
          data: [
            { ...e.good, name: "good" },
            { ...e.moderate, name: "moderate" },
            { ...e.depress, name: "depress" },
          ],
        })),
        6
      );
    }
  };

  const handleDownload = async (e) => {
    setIsBgLoading(true);
    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      companySelectedRedux.id,
      "wellbeing_test",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      // toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsBgLoading(false);
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      setIsBgLoading(false);
    }
  };

  return (
    <CurrentWellBeingStatContainerStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/girl_well.svg"
              titleLabel="WHO-5 Wellbeing Index"
              label1="จากสถิติ คนไทยเสี่ยงซึมเศร้า 3.5 แสนราย โดยเฉพาะในกรุงเทพฯ 1 ใน 2 ของคนกรุงเทพ มีความเสี่ยงที่จะซึมเศร้า แม้ว่าจำนวนผู้มีแนวโน้มซึมเศร้าจะมีจำนวนมาก แต่พบว่าพนักงานส่วนใหญ่ไม่กล้าที่จะพูดถึงปัญหาสุขภาวะจิตในที่ทำงาน ซึ่งเป็นเรื่องปกติที่พนักงานจะกังวลในการพูดคุยในเรื่องนี้ เพราะอาจกระทบการทำงานและความสัมพันธ์ในที่ทำงาน ด้วยเหตุนี้การดูแลสุขภาวะจิตด้วยแพลตฟอร์มจึงเป็นผู้ช่วยที่จะดูแลสุขภาวะจิตของพนักงานได้อย่างทั่วถึง"
              // label2=" แต่พบว่าพนักงานส่วนใหญ่ไม่กล้าที่จะพูดถึงปัญหาสุขภาวะจิตในที่ทำงาน ซึ่งเป็นเรื่องปกติที่พนักงานจะกังวลในการพูดคุยในเรื่องนี้ "
              // label3="ในที่ทำงาน ด้วยเหตุนี้การดูแลสุขภาวะจิตด้วยแพลตฟอร์มจึงเป็นผู้ช่วยที่จะดูแลสุขภาวะจิตของพนักงานได้อย่างทั่วถึง"
            />
          </div>
          <div className="block_2_col_layout">
            <div className="col_2 w_416">
              <div className="b2c_block_wrap mb_24">
                <NumberOfDoResearch
                  data={statisticsWellbeingData && statisticsWellbeingData}
                />
              </div>
              <div className="b2c_block_wrap">
                <NumberOfSmoking
                  data={statisticsWellbeingData && statisticsWellbeingData}
                />
              </div>
            </div>
            <div className="col_2 flex_1 mw_780">
              <div className="wrap_mr5_overflow">
                <MainDashBoardWellBeing
                  height="100%"
                  data={statisticsWellbeingData && statisticsWellbeingData}
                  barHeight="270px"
                />
              </div>
            </div>
          </div>
          <div className="section_chart_layout mt22">
            <MaleFemaleAverageResearch
              data={genderTrendData && genderTrendData}
              fetchData={fetchGenderTrend}
            />
          </div>
          <div className="section_chart_layout">
            <MaleFemaleAverageAge
              data={genderAgeData && genderAgeData}
              fetchData={fetchGenderAge}
            />
          </div>
          {companySelectedRedux && !companySelectedRedux.isParent && (
            <div>
              <AverageDepartmentWellbeing
                data={deparmentData && deparmentData}
                fetchData={fetchDepartment}
              />
            </div>
          )}
          <div className="export">
            <ExportRaw
              img="well"
              onDownload={handleDownload}
              // minDate={moment().tz('Asia/Bangkok').subtract(11, 'months').format('MM-01-YYYY')}
              // maxDate={moment().tz('Asia/Bangkok').endOf('month').format('MM-DD-YYYY')}
              minDate={moment()
                .tz("Asia/Bangkok")
                .subtract(10, "years")
                .format("01-01-YYYY")}
              rangeDate={11}
            />
          </div>
        </>
      )}
    </CurrentWellBeingStatContainerStyled>
  );
};

export default CurrentWellBeingStatContainer;

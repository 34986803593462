import styled from "styled-components";

export const CountDownStyled = styled.div`
  .time_render_action {
    margin-top: 16px;
    .group_value {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
      color: ${({ theme }) => theme.COLORS.GRAY_32};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      .underline {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

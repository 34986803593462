import styled from 'styled-components'

export const TotalStepStyled = styled.div`
height: 100%;
.ict_container {
  position: relative;
  height: calc(100% - 32px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '16px')};
  padding-right: ${(props) =>
  props.paddingRight ? props.paddingRight : '16px'};
  padding-bottom: ${(props) =>
  props.paddingBottom ? props.paddingBottom : '16px'};
  padding-left: ${(props) =>
  props.paddingLeft ? props.paddingLeft : '16px'};
  border-radius: ${(props) =>
  props.borderRadius ? props.borderRadius : '10px'};
  box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
  .top_current {
    display: flex;
    margin-bottom: 14px;
    // align-items: center;
    .svg_show {
    }
    .label_show {
      margin-left: 12px;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .title_txt {
        color: ${({ theme }) => theme.COLORS.BLACK_6};
      }
      .sub_title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
  .score_box {
    height: calc(100% - 70px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 20px;
    .s_prefix_label {
      color: ${({ theme }) => theme.COLORS.BLUE_11};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
    .s_scroe {
      display: flex;
      .arrow {
        margin-right: 7px;
      }
      color: ${({ bottomColor, theme }) => bottomColor ? bottomColor : theme.COLORS.BLACK_6};
      font-size: ${({ theme }) => theme.FONT.SIZE.S50};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
    .s_label {
      color: ${({ theme }) => theme.COLORS.BLACK_6};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
  }
}
`

import { DwbRiskStyled } from "./styled";
import { Blocks, Typographys, Icons, Skeleton } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import theme from "styles/theme.json";
import momentTZ from "moment-timezone";
import moment from "moment-timezone";
import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const DwbRisk = ({ data, fetchData, labelTop, labelSub }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [
    _filter,
    // _setFilter
  ] = useState("quarterly");
  const [_data, _setData] = useState();
  const [_dataLoaded, _setDataLoaded] = useState();
  const [
    _isShorLeftArrow,
    //  _setIsShorLeftArrow
  ] = useState(true);
  const [_isShorRightArrow, _setIsShorRightArrow] = useState(true);
  const [_dateLockNextArrow, _setDateLockNextArrow] = useState(true);

  useEffect(() => {
    if (data && data.data.length > 0) {
      _setData(data);
      _setDataLoaded([data]);
    } else {
      _setData(data);
      _setDataLoaded([]);
    }
  }, [data]);

  // Manage Enable/Disable Left/Right Arrow
  // PAGINATION GRAPH FLOW
  //============================================
  //============================================
  const handleClickLeftArrow = async () => {
    _setLoadSkeleton(true);
    //check has dataloadmore
    let renderDayQuery = renderFilterDay(_filter);
    let dateFilter = momentTZ(_data.startDate)
      .tz("Asia/Bangkok")
      .subtract(renderDayQuery.number, renderDayQuery.type)
      .startOf(renderDayQuery.startOf)
      .format("YYYY-MM-DD");
    handleCheckData("clickLeft", dateFilter);
  };
  const handleClickRightArrow = async () => {
    _setLoadSkeleton(true);
    let renderDayQuery = renderFilterDay(_filter);
    let dateFilter = momentTZ(_data.startDate)
      .tz("Asia/Bangkok")
      .add(renderDayQuery.number, renderDayQuery.type)
      .startOf(renderDayQuery.startOf)
      .format("YYYY-MM-DD");
    handleCheckData("clickRight", dateFilter);
  };
  const handleCheckData = async (key, date) => {
    handleDisabledArrow(date);
    let isFindCheckHaveData =
      _dataLoaded && _dataLoaded.find((e) => e.startDate === date);
    if (!isFindCheckHaveData) {
      let res = await fetchData(`?stat_date=${date}&period=${_filter}`, date);
      if (res && res.data) {
        _setData(res);
        _setDataLoaded([..._dataLoaded, res]);
        _setLoadSkeleton(false);
        if (key === "clickRight") {
          _setDateLockNextArrow(date);
          _setIsShorRightArrow(false);
        }
      }
    } else {
      _setData(isFindCheckHaveData);
      _setLoadSkeleton(false);
    }
  };
  const handleDisabledArrow = (date) => {
    if (_dateLockNextArrow === date) {
      _setIsShorRightArrow(false);
    } else {
      _setIsShorRightArrow(true);
    }
  };

  // const handleClickFilter = async (value) => {
  //   _setFilter(value);
  //   _setLoadSkeleton(true);
  //   const date = renderFilterDay(value).initialDate;
  //   let res = await fetchData(`?period=${value}&stat_date=${date}`, date);
  //   if (res && res.data) {
  //     _setData(res);
  //     _setDataLoaded([res]);
  //     resetFlow();
  //     _setLoadSkeleton(false);
  //   } else {
  //     _setLoadSkeleton(false);
  //   }
  // };

  // const resetFlow = () => {
  //   _setIsShorRightArrow(true);
  //   _setIsShorLeftArrow(true);
  //   _setDateLockNextArrow(false);
  // };

  const renderFilterDay = (filterType) => {
    switch (filterType) {
      case "weekly":
        return {
          number: 7,
          type: "days",
          startOf: "isoWeek",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      case "quarterly":
        return {
          number: 1,
          type: "quarter",
          startOf: "quarter",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
        };
      case "halfyearly":
        return {
          number: 6,
          type: "months",
          startOf: "month",
          initialDate:
            Number(momentTZ().tz("Asia/Bangkok").format("MM")) > 6
              ? `${moment().format("YYYY")}-07-01`
              : `${moment().format("YYYY")}-01-01`,
        };
      default:
        break;
    }
  };

  const renderLabelX = (key) => {
    let dateStart;
    let dateEnd;
    let valueDate;
    switch (key) {
      case "weekly":
        valueDate =
          moment(_data.startDate).startOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(
            moment(_data.startDate)
              .startOf("isoWeek")

              .format("MM")
          ).thShort +
          ` ` +
          moment(_data.startDate).startOf("isoWeek").format("YYYY") +
          ` - ` +
          moment(_data.startDate).endOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(moment(_data.startDate).endOf("isoWeek").format("MM"))
            .thShort +
          ` ` +
          moment(_data.startDate).endOf("isoWeek").format("YYYY");
        return valueDate;
      case "quarterly":
        dateStart = moment(_data.startDate).startOf("month");
        dateEnd = moment(_data.startDate).endOf("month").add(2, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      case "halfyearly":
        dateStart = moment(_data.startDate).startOf("month");
        dateEnd = moment(_data.startDate).endOf("month").add(5, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      default:
        return "";
    }
  };

  return (
    <DwbRiskStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="24px"
        paddingLeft="24px"
        height={`calc(100% - 24px)`}
      >
        <div className="top_men">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label={labelTop}
              labelBottom={labelSub}
            />
          </div>
          <div className="right_men_layout">
            <div className="show_search">
              {/* <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                onChange={handleClickFilter}
              /> */}
              <div className="date_show">
                <div className="left_arrow">
                  {_isShorLeftArrow && (
                    <div onClick={handleClickLeftArrow}>
                      <Icons.ArrowBlueLeft />
                    </div>
                  )}
                </div>
                <div className="group_date">
                  <div className="group_date_top">
                    <div className="show_icon">
                      <Icons.CalendarIcon />
                    </div>
                    <div className="date_layout">ไตรมาสล่าสุด</div>
                  </div>
                  <div className="group_date_bottom">
                    {_data && _data.data && renderLabelX(_filter)}
                  </div>
                </div>
                <div className="right_arrow">
                  {_isShorRightArrow && (
                    <div onClick={handleClickRightArrow}>
                      <Icons.ArrowBlueRight />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {_data ? (
          <>
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="b_left_layout">
                    <div className="top_label">Number of employees</div>
                    <div className="body_graph">
                      <Chart
                        options={{
                          chart: {
                            type: "bar",
                            height: 350,
                            stacked: true,
                            toolbar: {
                              show: false,
                            },
                          },
                          // responsive: [
                          //   {
                          //     breakpoint: 480,
                          //     options: {
                          //       legend: {
                          //         position: 'bottom',
                          //         offsetX: -10,
                          //         offsetY: 0,
                          //       },
                          //     },
                          //   },
                          // ],
                          xaxis: {
                            categories: _data
                              ? _data.data.map((e) => e.name)
                              : [],
                            labels: {
                              rotate: -16,
                              // formatter: function (value, timestamp, opts) {
                              //   return parse(value)
                              // }
                              hideOverlappingLabels: false,
                              style: {
                                colors: [],
                                fontSize: "14px",
                                fontFamily:
                                  "noto_sans_thai_regular, noto_sans_regular",
                                fontWeight: 100,
                                cssClass: "apexcharts-yaxis-label",
                              },
                            },
                            axisBorder: {
                              show: true,
                              color: theme.COLORS.PURPLE_1,
                              height: 3,
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: true,
                              color: theme.COLORS.PURPLE_1,
                              width: 3,
                            },
                            labels: {
                              formatter: function (value) {
                                return RenderCommaMoney(
                                  Number(value).toFixed(0),
                                  0
                                );
                              },
                            },
                          },
                          colors: ["#654C96"],
                          fill: {
                            opacity: 1,
                          },

                          plotOptions: {
                            bar: {
                              columnWidth: "30%",
                            },
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={[
                          {
                            name: "Fit in PUI risk",
                            data: _data
                              ? _data.data.map((e) => e.risk_user_count)
                              : [],
                          },
                        ]}
                        type="bar"
                        height={230}
                        width="100%"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </DwbRiskStyled>
  );
};

export default DwbRisk;

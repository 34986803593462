import React from "react";
import { connect } from "react-redux";
import { GroupRoleContainerStyled } from "./styled";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Forms,
  Loading,
  Modals,
  Blocks,
  HeadTab,
  TableCustom,
} from "components";
import { getObjectKey } from "utils/functions/getObjectKey";

class GroupRoleContainer extends React.Component {
  state = {
    isLoading: true,
    isShowModal: false,
    labelNotic:
      this.props.match.params.id !== "create"
        ? {
          label1: "บันทึกสำเร็จ",
          label2: "บันทึกไม่สำเร็จ",
        }
        : {
          label1: "สร้างสำเร็จ",
          label2: "สร้างไม่สำเร็จ",
        },

    dataUserGroup: { data: [] },
    hasMore: true,
    pagination: {
      offset: 0,
      per_page: 10,
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.checkPermission()
    this.fetchUserGroupDetail("?is_admin=1&sort_by=joined_at&order_by=desc");
  }

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'group_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchUserGroupDetail = async (stringQuery) => {
    const {
      isReset,
      pagination: { per_page },
    } = this.state;
    let res = await adminService.GET_USERS_BY_GROUP_ID(
      this.props.match.params.id,
      stringQuery
    );

    if (res && res.success) {
      if ((!res.data || res.data.length === 0) && !isReset) {
        this.setState({
          hasMore: false,
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          dataUserGroup: isReset
            ? {
              ...res.data,
              data: [...res.data],
            }
            : {
              ...res.data,
              data: [...state.dataUserGroup.data, ...res.data],
            },
          hasMore: res.data.length < per_page ? false : true,
          isLoading: false,
          isReset: false,
        }));
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleConfirmDelete = async () => {
    const {
      dataDelete,
      pagination: { offset, per_page },
    } = this.state;
    let params = {
      is_admin: false,
    };
    let res = await adminService.PATCH_USER_GROUP(
      this.props.match.params.id,
      dataDelete.id,
      params
    );
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.setState(
        {
          isShowModal: false,
          isReset: true,
        },
        () =>
          this.fetchUserGroupDetail(
            `?is_admin=1&sort_by=joined_at&order_by=desc&per_page=${per_page}&offset=${offset}`
          )
      );
    } else {
      toast.error("ลบไม่สำเร็จ");
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
      dataDelete: null,
    });
  };

  handlePopup = (e) => {
    this.setState({
      isShowModal: true,
      dataDelete: e,
    });
  };

  handleBack = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_HEALTHY_CLUB_PREVIEW_DETAIL}/${this.props.match.params.id}`
    );
  };

  fetchMoreData = () => {
    this.setState(
      (state) => ({
        pagination: {
          offset: state.pagination.offset * state.pagination.per_page + 1,
          per_page: state.pagination.per_page,
        },
      }),
      () => {
        this.fetchUserGroupDetail(
          `?is_admin=1&sort_by=joined_at&order_by=desc&per_page=${this.state.pagination.per_page}&offset=${this.state.pagination.offset}`
        );
      }
    );
  };

  onSubmit = async (e) => {
    let params = {
      is_admin: true,
    };
    let res = await adminService.PATCH_USER_GROUP(
      this.props.match.params.id,
      e.admin.id,
      params
    );
    if (res && res.success) {
      toast.success("บันทึกสำเร็จ");
      this.setState(
        {
          isReset: true,
          pagination: {
            offset: 0,
            per_page: 10,
          },
        },
        () => {
          this.fetchUserGroupDetail(
            `?is_admin=1&sort_by=joined_at&order_by=desc&per_page=${this.state.pagination.per_page}&offset=${this.state.pagination.offset}`
          );
        }
      );
    } else {
      toast.error("บันทึกไม่สำเร็จ");
    }
  };

  render() {
    const {
      initialValues,
      isLoading,
      isShowModal,
      dataUserGroup,
      hasMore,
      dataDelete,
      perMissions,
    } = this.state;

    return (
      <GroupRoleContainerStyled> {console.log('perMissions', perMissions)}
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="con_box">
            <InfiniteScroll
              dataLength={dataUserGroup.data.length}
              next={this.fetchMoreData}
              hasMore={hasMore}
              loader={<h3 className="loading">Loading...</h3>}
              // height={'calc(100vh - 76px)'}
              // height='100%'
              endMessage={
                <p className="loading">
                  <b>ดูครบทั้งหมดแล้ว</b>
                </p>
              }
              style={{ padding: "0 5px" }}
            >
              <div className="layer_one">
                <HeadTab
                  theme_standard_head_tab
                  title="กำหนดบทบาทบนกลุ่ม"
                  subTitle="Group"
                  // fontSize="34px"
                  // fontSizeLabelButtom="15px"
                  // fontColor="#092945"
                  // colorBottom="#989898"
                  lineHeight="1.2"
                  showButtonDelete={initialValues ? true : false}
                  // handleClickButton={handleDelete}
                  handleBackCustom={this.handleBack}
                />
              </div>
              {
                perMissions &&
                <div className="form_layout_show">
                  <div className="title_form">
                    <div className="lbl_t">ผู้ดูแลกลุ่ม</div>
                    <div className="hText">{this.props.match.params.group}</div>
                    {/* <div>ที่มีอยู่ในระบบ</div> */}
                  </div>
                  <div className="sub_text">
                    สามารถแก้ไขรูปภาพหน้าปกของกลุ่ม และลบความคิดเห็นบนกลุ่ม, ดูว่าใครเป็นคนสร้างโพสต์หรือแสดงความคิดเห็น
                  </div>
                  <div className="show_form_show">
                    <Forms.AddGroupRoleForm
                      onSubmit={this.onSubmit}
                      groupId={this.props.match.params.id}
                      fetchUserGroupDetail={this.fetchUserGroupDetail}
                      perMissions={perMissions ? true : false}
                    />
                  </div>
                </div>
              }
              <div className="table_layout_show">
                <div className="table_line_1">
                  <div className="lbl_line_1">ผู้ดูแลกลุ่ม</div>
                  <div className="hText">{this.props.match.params.group}</div>
                  <div className="lbl_back">ที่มีอยู่ในระบบ</div>
                </div>
                {/* <div className="sub_text">
                  สามารถแก้ไขรูปภาพหน้าปกของกลุ่ม และลบความคิดเห็นบนกลุ่ม,
                  ดูว่าใครเป็นคนสร้างโพสต์หรือแสดงความคิดเห็น
                </div> */}
                <div className="table_show_rank">
                  <TableCustom.TableRank3
                    theme_standard_table_rank3
                    data={dataUserGroup && dataUserGroup.data}
                    handleClickDelete={this.handlePopup}
                    perMissions={perMissions}
                  // pagination={paginationRank && paginationRank}
                  />
                </div>
              </div>
            </InfiniteScroll>
          </div>
        )}
        <Modals.Standard
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <Blocks.BoxDelete
            theme_box_delete
            maxWidth="374px"
            minWidth="374px"
            label={
              <div className="label_del">
                {dataDelete &&
                  `ต้องการ ลบ ${dataDelete.employee.firstname} ${dataDelete.employee.lastname} ออกจากผู้ดูแลกลุ่ม ใช่หรือไม่?`}
              </div>
            }
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
          />
        </Modals.Standard>
      </GroupRoleContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupRoleContainer);

import React from 'react';
import { ManyUserUploadFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs } from 'components';

const ManyUserUploadForm = ({ onSubmit, onChangeFile, onRemoveFile }) => {
  const schema = yup.object().shape({});

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <ManyUserUploadFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => (
            <Inputs.InputBrowseFile
              theme_standard_browse
              isForceFileUpload
              maxMBFileSizeWarning="1"
              acceptType="file"
              onChange={onChangeFile}
              onRemoveFile={onRemoveFile}
              theme_left_right={true}
            />
          )}
          name="files"
          defaultValue={[]}
        />
      </form>
    </ManyUserUploadFormStyled>
  );
};

ManyUserUploadForm.propTypes = {};

export default ManyUserUploadForm;

import React from "react";
import { OTPFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons, Displays } from "components";

const OTPForm = ({ onSubmit, email, data }) => {
  const schema = yup.object().shape({
    otp: yup.string().nullable().required("OTP is required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  return (
    <OTPFormStyled onSubmit={handleSubmit(onSubmit)}>
      <div className="otp_form_container">
        <div className="logo_icon">
          กรอกรหัสยืนยันตัวตน
        </div>
        <div className="email_detail">
          <div>
            รหัสยืนยันตัวตนถูกส่งไปยังอีเมล
          </div>
          <div className="lbl_email">
            {email}
          </div>
        </div>
        <div className="box_input">
          <div className="group_input">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextOTP
                  {...field}
                  // iconSvg={<Icons.User />}
                  errors={errors.otp?.message}
                />
              )}
              name="otp"
              defaultValue=""
            />
          </div>
        </div>
        <div className="text_code">
          {`รหัสอ้างอิง : ${data && data.ref_no}`}
        </div>
        <div className="btn_layout">
          <Buttons.BtnNormal
            theme_standard_btn_log_in
            label="ยืนยัน"
            type="submit"
            disabled={
              !watchAllFields ||
              !watchAllFields.otp ||
              watchAllFields.otp === '' ||
              watchAllFields.otp.length < 6
            }
          />
        </div>
        <div className="time_show">
          <Displays.CountDown
            countdown={
              data && data.expired_at}
          />
        </div>
      </div>
    </OTPFormStyled>
  );
};

OTPForm.propTypes = {};

export default OTPForm;

import { BurnoutSyndromeStyled } from "./styled";
import { Blocks, Typographys, Icons, Buttons, Skeleton } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { RENDER_MONTH } from "utils/functions/date";
import momentTZ from "moment-timezone";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import theme from "styles/theme.json";

const BurnoutSyndrome = ({ data, fetchData }) => {
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_data, _setData] = useState();
  const [_filter, _setFilter] = useState("weekly");

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const handleClickFilter = async (value) => {
    const date = renderFilterDay(value).initialDate;
    _setLoadSkeleton(true);
    let res = await fetchData(`?period=${value}&stat_date=${date}`, date);
    if (res) {
      _setFilter(value);
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setFilter(value);
      _setData();
      _setLoadSkeleton(false);
    }
  };

  const renderFilterDay = (filterType) => {
    switch (filterType) {
      case "weekly":
        return {
          number: 7,
          type: "days",
          startOf: "isoWeek",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      case "quarterly":
        return {
          number: 1,
          type: "quarter",
          startOf: "quarter",
          initialDate: momentTZ()
            .tz("Asia/Bangkok")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
        };
      case "halfyearly":
        return {
          number: 6,
          type: "months",
          startOf: "month",
          initialDate:
            Number(momentTZ().tz("Asia/Bangkok").format("MM")) > 6
              ? `${moment().format("YYYY")}-07-01`
              : `${moment().format("YYYY")}-01-01`,
        };
      default:
        break;
    }
  };

  const renderDateShow = (key) => {
    let dateStart;
    let dateEnd;
    let valueDate;

    switch (key) {
      case "weekly":
        valueDate =
          moment(_data.stat_date).startOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(
            moment(_data.stat_date)
              .startOf("isoWeek")

              .format("MM")
          ).thShort +
          ` ` +
          moment(_data.stat_date).startOf("isoWeek").format("YYYY") +
          ` - ` +
          moment(_data.stat_date).endOf("isoWeek").format("DD") +
          ` ` +
          RENDER_MONTH(moment(_data.stat_date).endOf("isoWeek").format("MM"))
            .thShort +
          ` ` +
          moment(_data.stat_date).endOf("isoWeek").format("YYYY");
        return valueDate;
      case "quarterly":
        dateStart = moment(_data.stat_date).startOf("month");
        dateEnd = moment(_data.stat_date).endOf("month").add(2, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      case "halfyearly":
        dateStart = moment(_data.stat_date).startOf("month");
        dateEnd = moment(_data.stat_date).endOf("month").add(5, "month");
        valueDate =
          dateStart.format("DD") +
          " " +
          RENDER_MONTH(dateStart.format("MM")).thShort +
          " " +
          dateStart.format("YYYY") +
          " - " +
          dateEnd.format("DD") +
          " " +
          RENDER_MONTH(dateEnd.format("MM")).thShort +
          " " +
          dateEnd.format("YYYY");

        return valueDate;
      default:
        return "";
    }
  };

  return (
    <BurnoutSyndromeStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="24px"
        paddingRight="24px"
        paddingBottom="24px"
        paddingLeft="24px"
      >
        <div className="top_men">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="7 อันดับเหตุผลที่ทำให้พนักงานรู้สึกหมดไฟในการทำงานสูงสุด"
              labelBottom="Top 7 causes of Burnout syndrome"
            />
          </div>
          <div className="right_men_layout">
            <div className="label_layout">
              <div className="date_show">
                <div className="show_icon">
                  <Icons.CalendarIcon />
                  ข้อมูลรายสัปดาห์
                  {/* วันที่รายสัปดาห์ล่าสุด */}
                </div>
                <div className="show_label">
                  {_data && renderDateShow(_filter)}
                </div>
              </div>
              {/* <div className="date_layout">
                {_data && renderDateShow(_filter)}
              </div> */}
            </div>
            <div className="show_search">
              <Buttons.BtnDateFillter
                theme_standard_date_filter
                initial={_filter}
                labeDisplay
                onChange={handleClickFilter}
              />
            </div>
          </div>
        </div>
        {_data && _data.data.length !== 0 ? (
          <>
            <div className="bottom_men">
              {_loadSkeleton ? (
                <div className="seleton_body">
                  <Skeleton.GraphSkeleton height="307px" />
                </div>
              ) : (
                <>
                  <div className="body_graph">
                    <div className="label_g">จำนวนครั้ง</div>
                    <Chart
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                          fontFamily: theme.FONT.STYLE.REGULAR,
                          stacked: true,
                          toolbar: {
                            show: false,
                          },
                        },
                        // responsive: [
                        //   {
                        //     breakpoint: 480,
                        //     options: {
                        //       legend: {
                        //         position: "bottom",
                        //         offsetX: -10,
                        //         offsetY: 0,
                        //       },
                        //     },
                        //   },
                        // ],
                        xaxis: {
                          categories: _data
                            ? _data.data.map((e) => e.label)
                            : [],
                          labels: {
                            rotate: -16,
                            hideOverlappingLabels: false,
                            // formatter: function (value, timestamp, opts) {
                            //   return parse(value)
                            // }
                            style: {
                              colors: [],
                              fontSize: "10px",
                              fontFamily:
                                "noto_sans_thai_regular, noto_sans_regular",
                              fontWeight: 100,
                              cssClass: "apexcharts-yaxis-label",
                            },
                          },
                          axisBorder: {
                            show: true,
                            color: theme.COLORS.PURPLE_1,
                            height: 3,
                          },
                        },
                        yaxis: {
                          axisBorder: {
                            show: true,
                            color: theme.COLORS.PURPLE_1,
                            width: 3,
                          },
                          labels: {
                            formatter: function (value) {
                              return RenderCommaMoney(
                                Number(value).toFixed(0),
                                0
                              );
                            },
                          },
                        },
                        colors: ["#654C96"],
                        fill: {
                          opacity: 1,
                        },

                        plotOptions: {
                          bar: {
                            columnWidth:
                              _data && `${(_data.data.length * 50) / 7}%`,
                          },
                        },
                        legend: {
                          show: false,
                        },
                        // tooltip: {
                        //   enabled: true,
                        //   y: {
                        //     formatter: function (
                        //       value,
                        //       { series, seriesIndex, dataPointIndex, w }
                        //     ) {
                        //       return `${Number(value).toFixed(0)}`;
                        //     },
                        //   },
                        // },
                      }}
                      series={[
                        {
                          name: "ครั้ง",
                          data: _data
                            ? _data.data.map((e) => e.user_count)
                            : [],
                        },
                      ]}
                      type="bar"
                      height={350}
                      width="100%"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </BurnoutSyndromeStyled>
  );
};

export default BurnoutSyndrome;

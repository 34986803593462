import React from 'react';
import { InputCheckBox2Styled } from './styled';
import cx from 'classnames';

export const InputCheckBox2 = ({
  theme_standard_input_check_box_square,
  theme_standard_input_check_box_round_2,
  label,
  value,
  checked,
  img,
  fontSize,
  fontFamily,
  fontColor,
  borderColor,
  borderColorActive,
  bgColor,
  onChange,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_input_check_box_square: theme_standard_input_check_box_square,
    theme_standard_input_check_box_round_2:theme_standard_input_check_box_round_2,
  });

  return (
    <InputCheckBox2Styled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
      bgColor={bgColor}
      borderColor={borderColor}
      borderColorActive={borderColorActive}
    >
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={value}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <span className="checkmark"></span>
          {
            img &&
            <img className='img_logo' src={img} alt='logo' />
          }

          {label &&
            <span className='lable_show'>
              {label}
            </span>
          }
        </label>
      </div>
    </InputCheckBox2Styled>
  );
};

export default InputCheckBox2;

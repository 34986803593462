import styled from "styled-components";

export const OTPFormStyled = styled.form`
  .otp_form_container {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 72px 54px 42px 54px;
    width: 432px;
    height: 529px;
    // height: 467px;
    .logo_icon {
      margin-bottom: 43px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .email_detail {
      margin-bottom: 40px;
      color: ${({ theme }) => theme.COLORS.GRAY_32};
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      .lbl_email {
        margin-top: 1px;
        color: ${({ theme }) => theme.COLORS.BLUE_31};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
    }
    .box_input {
      .group_input {
        margin-bottom: 22px;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
    .text_code {
      margin-top: 16px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_26};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
    .btn_layout {
      margin-top: 40px;
    }
    .time_show {
      margin-top: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .form_container {
      padding: 40px 30px 20px 30px;
    }
  }
`;

import React from 'react';
import { MainDashboardContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { Blocks, Cards, Typographys, Icons, Tabs } from 'components';
import { WellLifeContainer, AnalysisContainer } from './Tabs';
class MainDashboardContainer extends React.Component {
  state = {};

  componentDidMount() {
    scrollToTopPageFN();
  }

  render() {
    return (
      <MainDashboardContainerStyled>
        <Blocks.Container>
          <div className="title_layout">
            <Typographys.LabelIcon
              theme_standard_label_icon
              label="Main Dashboard"
              svgFront={<Icons.Graph />}
            />
          </div>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/groupGirls.svg"
              titleLabel="Main Dashboard"
              label1="Main Dashboard คือ การแสดงภาพรวมยอดผู้ใช้งานในบริษัทในการตอบคำถามโดยจะแสดงเป็นค่าเฉลี่ยและเรียงตามลำดับ ไม่ว่าจะเป็นค่าเฉลี่ย BMI,การตอบคำถาม Well-being  และ MSD และสามารถดูยอดผู้ป่วยที่มีความเสี่ยงเป็นโรค PUI  โดยจะสามารถดูได้ทั้งรายเดือน และ รายสัปดาห์"
            />
          </div>
          <Tabs.TabBarSelect
            tab_standard_tab_bar_select
            tabHeader={headerTab}
          />
        </Blocks.Container>
      </MainDashboardContainerStyled>
    );
  }
}

const headerTab = [
  {
    label: 'Well Life',
    // labelBottom: 'Current BMI Stat',
    component: <WellLifeContainer />,
  },
  {
    label: 'Analysis',
    // labelBottom: 'A.I. BMI Cluster',
    component: <AnalysisContainer />,
  },
];

export default MainDashboardContainer;

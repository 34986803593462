import styled from 'styled-components';

export const BoxLabelLeftRightStyled = styled.div`
  .theme_standard_box_label_left_right {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    display: flex;
    flex-wrap: wrap;
    .left_layout {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .right_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      font-size: ${({ theme }) => theme.FONT.SIZE.S48};
      .svg_show_f {
        margin-right: 5px;
      }
      .svg_show_b {
        margin-left: 3px;
      }
    }
  }

  .theme_standard_box_4_col {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    display: flex;
    flex-wrap: wrap;
    .left_layout {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .bottom_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
    .right_layout {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      font-size: ${({ theme }) => theme.FONT.SIZE.S48};
      .svg_show_f {
        margin-right: 5px;
      }
      .svg_show_b {
        margin-left: 3px;
      }
    }
  }
`;

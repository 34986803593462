export default function Delete2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.958" height="32" viewBox="0 0 24.958 32">
      <g id="_38_Delete" data-name="38 Delete" transform="translate(-5.081 -2)">
        <path id="Path_20064" data-name="Path 20064" d="M26.429,8H8.143A1.16,1.16,0,0,0,7,9.2L8.029,29.895a3.429,3.429,0,0,0,3.429,3.248H23.114a3.429,3.429,0,0,0,3.429-3.251L27.572,9.2A1.16,1.16,0,0,0,26.429,8Z" transform="translate(0.274 0.857)" fill="#fff2f2" />
        <g id="Group_14931" data-name="Group 14931" transform="translate(5.081 2)">
          <path id="Path_20065" data-name="Path 20065" d="M27.274,5H7.846c-3.69,0-3.682,5.714,0,5.714H27.274C30.964,10.714,30.956,5,27.274,5Z" transform="translate(-5.081 -1.571)" fill="#f0211d" />
          <path id="Path_20066" data-name="Path 20066" d="M11,6.571V5.429A3.429,3.429,0,0,1,14.429,2h4.555a3.461,3.461,0,0,1,3.429,3.453L22.4,6.587a1.143,1.143,0,0,1-2.286-.031l.016-1.128a1.159,1.159,0,0,0-1.143-1.143H14.429a1.143,1.143,0,0,0-1.143,1.143V6.571C13.286,7.2,11,7.2,11,6.571Z" transform="translate(-4.235 -2)" fill="#f0211d" />
          <path id="Path_20067" data-name="Path 20067" d="M19,26.857V13.143a1.143,1.143,0,1,1,2.286,0V26.857a1.143,1.143,0,0,1-2.286,0Z" transform="translate(-3.092 -0.571)" fill="#f0211d" />
          <path id="Path_20068" data-name="Path 20068" d="M15,26.857V13.143a1.143,1.143,0,1,1,2.286,0V26.857a1.143,1.143,0,0,1-2.286,0Z" transform="translate(-3.664 -0.571)" fill="#f0211d" />
          <path id="Path_20069" data-name="Path 20069" d="M11,26.857V13.143a1.143,1.143,0,1,1,2.286,0V26.857a1.143,1.143,0,0,1-2.286,0Z" transform="translate(-4.235 -0.571)" fill="#f0211d" />
        </g>
      </g>
    </svg>
  );
}

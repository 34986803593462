export default function renderIcon({
  color = "#ffffff",
  width = "22.25",
  height = "22.25",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g id="bmi" transform="translate(0 -0.028)">
        <path
          id="Path_49659"
          data-name="Path 49659"
          d="M19,3.287A11.128,11.128,0,0,0,.387,14.062a.652.652,0,0,0,.629.482H21.241a.652.652,0,0,0,.629-.482A11.144,11.144,0,0,0,19,3.287Zm1.733,9.953H1.529a9.824,9.824,0,1,1,19.2,0Z"
          transform="translate(0 0)"
          fill={color}
        />
        <path
          id="Path_49660"
          data-name="Path 49660"
          d="M241.683,72.554a.652.652,0,0,0,.652-.652V70.673a.652.652,0,1,0-1.3,0V71.9A.652.652,0,0,0,241.683,72.554Z"
          transform="translate(-230.554 -66.951)"
          fill={color}
        />
        <path
          id="Path_49661"
          data-name="Path 49661"
          d="M156.755,93.25a.652.652,0,0,0-1.129.652l.615,1.065a.652.652,0,1,0,1.129-.652Z"
          transform="translate(-148.778 -88.858)"
          fill={color}
        />
        <path
          id="Path_49662"
          data-name="Path 49662"
          d="M95,156.21l-1.065-.615a.652.652,0,1,0-.652,1.129c1.157.668,1.164.7,1.39.7A.653.653,0,0,0,95,156.21Z"
          transform="translate(-88.914 -148.722)"
          fill={color}
        />
        <path
          id="Path_49663"
          data-name="Path 49663"
          d="M71.931,241H70.7a.652.652,0,0,0,0,1.3h1.229a.652.652,0,1,0,0-1.3Z"
          transform="translate(-67.005 -230.5)"
          fill={color}
        />
        <path
          id="Path_49664"
          data-name="Path 49664"
          d="M383.727,241.654a.652.652,0,0,0,.652.652h1.229a.652.652,0,0,0,0-1.3h-1.229a.652.652,0,0,0-.652.652Z"
          transform="translate(-367.048 -230.5)"
          fill={color}
        />
        <path
          id="Path_49665"
          data-name="Path 49665"
          d="M366,155.6l-1.065.615a.652.652,0,1,0,.652,1.129l1.065-.615A.652.652,0,1,0,366,155.6Z"
          transform="translate(-348.759 -148.723)"
          fill={color}
        />
        <path
          id="Path_49666"
          data-name="Path 49666"
          d="M313.97,93.012a.652.652,0,0,0-.891.239l-.615,1.065a.652.652,0,1,0,1.129.652l.615-1.065A.652.652,0,0,0,313.97,93.012Z"
          transform="translate(-298.799 -88.859)"
          fill={color}
        />
        <path
          id="Path_49667"
          data-name="Path 49667"
          d="M243.246,194.2l-2.043,2.043a.652.652,0,1,0,.922.922l2.043-2.043a.652.652,0,0,0-.922-.922Z"
          transform="translate(-230.536 -185.548)"
          fill={color}
        />
        <path
          id="Path_49668"
          data-name="Path 49668"
          d="M218.979,361.283l0-.008a.771.771,0,0,0-1.489-.084c-.006.018-.953,3.342-.958,3.36l-.987-3.344-.007-.023a.771.771,0,0,0-1.489.107l-.927,5.135a.652.652,0,0,0,1.283.232l.527-2.921.87,2.95.007.023a.771.771,0,0,0,1.464-.007l.007-.023.84-2.948.56,2.932a.652.652,0,1,0,1.281-.245Z"
          transform="translate(-203.847 -344.955)"
          fill={color}
        />
        <path
          id="Path_49669"
          data-name="Path 49669"
          d="M404.694,360.655a.652.652,0,0,0-.652.652v5.273a.652.652,0,1,0,1.3,0v-5.273A.652.652,0,0,0,404.694,360.655Z"
          transform="translate(-386.48 -344.952)"
          fill={color}
        />
        <path
          id="Path_49670"
          data-name="Path 49670"
          d="M81.764,363.543a1.864,1.864,0,0,0-1.562-2.884H78.671a.652.652,0,0,0-.652.652v5.273a.652.652,0,0,0,.652.652h0c.014,0,1.413-.006,1.8-.006a2.07,2.07,0,0,0,1.294-3.687Zm-1.562-1.58a.561.561,0,1,1,0,1.123l-.559,0h-.32v-1.124h.88Zm.268,3.963-1.147,0v-1.538H80.47a.768.768,0,0,1,0,1.535Z"
          transform="translate(-74.627 -344.956)"
          fill={color}
        />
      </g>
    </svg>
  );
}

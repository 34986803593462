import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { GraphSkeletonStyled } from './styled';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const GraphSkeleton = ({ theme_graph_skeleton, height }) => {
  const customClass = cx({
    theme_graph_skeleton: theme_graph_skeleton,
  });
  return (
    <GraphSkeletonStyled height={height}>
      <div className={customClass}>
        <SkeletonTheme color="#DEDEDE" highlightColor="#F3F3F4">
          <div className="body_skeleton">
            <Skeleton width='100%' height='100%' />
          </div>
        </SkeletonTheme>
      </div>
    </GraphSkeletonStyled>
  );
};

GraphSkeleton.propTypes = {};

export default GraphSkeleton;

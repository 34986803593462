import styled from 'styled-components';

export const InputSelectCusIconStyled = styled.div`
  .theme_standard {
  }
`;

export const OptionStyled = styled.div`
  display: flex;
  align-item: center;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 8px 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: #f2f9fc;
  }
  .op_icon_wrap {
    width: 40px;
  }
  .op_label_wrap {
  }
`;

import styled from "styled-components";

export const PaginationCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_pagination {
    .pagination {
      display: flex;
      width: 100%;
      list-style: none;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;

      .previous {
        display: flex;
      }
      .next {
        display: flex;
      }
      li {
        cursor: pointer;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.BLUE_20};
        a {
          padding: 0 10px;
        }
      }
      .active {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: ${({ theme }) => theme.COLORS.BLUE_20};
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      }
    }
  }
`;

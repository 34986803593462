import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { PopularStyled } from './styled'

const Popular = ({ theme_popular, svg, label1, label2, label3, src }) => {
  const customClass = cx({
    theme_popular: theme_popular,
  })
  return (
    <PopularStyled
      src={src ? src : '/assets/images/backgrounds/temp_image.png'}
      border={src ? '1' : '2'}
    >
      <div className={customClass}>
        <div className='pop_box'>
          <div className='show_svg'>
            {svg}
          </div>
          <div className='group_pro'>
            <div className='top_text'>
              <div>
                {label1}
              </div>
              <div className='light_text'>
                {label2}
              </div>
            </div>
            <div className='box_image' >
            </div>
            <div className='bottom_label'>
              {label3}
            </div>
          </div>
        </div>
      </div>
    </PopularStyled>
  )
}

Popular.propTypes = {}

export default Popular

export default function renderIcon({
  width = "56",
  height = "56",
  color = "#4BAE4F",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 0C12.5563 0 0 12.5563 0 28C0 43.4437 12.5563 56 28 56C43.4437 56 56 43.4437 56 28C56 12.5563 43.4437 0 28 0Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.5391 18.5617C42.2172 19.2398 42.2172 20.3555 41.5391 21.0336L25.1328 37.4398C24.7938 37.7789 24.3453 37.9539 23.8969 37.9539C23.4484 37.9539 23 37.7789 22.6609 37.4398L14.4578 29.2367C13.7797 28.5586 13.7797 27.443 14.4578 26.7648C15.1359 26.0867 16.2516 26.0867 16.9297 26.7648L23.8969 33.732L39.0672 18.5617C39.7453 17.8727 40.8609 17.8727 41.5391 18.5617Z"
        fill="white"
      />
    </svg>
  );
}

import { ParticipantsDetailsStyled } from './styled';
import { Label } from 'components';
import { TotalParticipants, TotalDepartment, ParticipantsAge } from 'widgets';
import moment from 'moment';
import 'moment-timezone';
import { RENDER_MONTH } from 'utils/functions/date';

const ParticipantsDetails = ({ data }) => {
  return (
    <ParticipantsDetailsStyled>
      <div className="title_participant_block">
        <div className="left_col">
          <div className="title">ข้อมูลผู้เข้าร่วม</div>
          <div className="sub_title">Participants Details</div>
        </div>
        <div className="right_col">
          {
            data && data.updated_at != null &&
            <>
              <Label.TopBottom
                theme_left_align
                label="อัพเดตล่าสุด"
                labelBottom="Lasted Update"
                fontSizeLabelButtom="12px"
                fontSize="12px"
              />
              <div className="date_wrap">
                <Label.ShowDate
                  theme_standard_show_date
                  date={
                    moment(data.updated_at)
                      .tz('Asia/Bangkok')
                      .format('D')
                  }
                  month1={
                    RENDER_MONTH(
                      moment(data.updated_at)
                        .tz('Asia/Bangkok')
                        .format('MM')
                    ).th
                  }
                  month2={
                    RENDER_MONTH(
                      moment(data.updated_at)
                        .tz('Asia/Bangkok')
                        .format('MM')
                    ).en
                  }
                  year={
                    moment(data.updated_at).tz('Asia/Bangkok').year() +
                    543
                  }
                  time={`${moment(data.updated_at)
                    .tz('Asia/Bangkok')
                    .format('HH:mm')
                    } น.`}
                />
              </div>
            </>
          }
        </div>
      </div>
      <div className="row">
        <div className="col_3 w_327">
          <TotalParticipants
            users_count={data?.users_count}
            users_count_total={data?.users_count_total}
          />
        </div>
        <div className="col_3 w_327">
          <TotalDepartment
            departments_count={data?.departments_count}
            departments_count_total={data?.departments_count_total}
          />
        </div>
        <div className="col_3 w_415">
          <ParticipantsAge
            data={data && data}
          />
        </div>
      </div>
    </ParticipantsDetailsStyled>
  );
};

export default ParticipantsDetails;

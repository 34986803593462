import React from 'react';
import { AIBMIContainerStyled } from './styled';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { Loading, Cards } from 'components';

class AIBMIContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  }

  render() {
    const { isLoading } = this.state;
    return (
      <AIBMIContainerStyled>
        {isLoading ? (
          <div className="loading_layout">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="section_introduce_layout">
              <Cards.BoxIntroduce
                src="/assets/images/logo/girl_well.svg"
                titleLabel="WHO-5 Wellbeing Index"
                label1="In the UK, 1 in 4 adults suffer from mental health issues."
                label2="14.3% of employees fail in this category, which is better than the national average."
                label3="These employees were signposted and re-routed to the company’s Employee Assistance Programme."
              />
            </div>
          </>
        )}
      </AIBMIContainerStyled>
    );
  }
}

export default AIBMIContainer;

import { ExportHealthCheckStyled } from "./styled";
import { Inputs, Buttons, Icons } from "components";
import React, { useState } from "react";
import theme from "styles/theme.json";
import moment from "moment";

const ExportHealthCheck = ({ img, onDownload }) => {
  const [startDate, _setStartDate] = useState(new Date());

  const renderImage = () => {
    return "/assets/images/logo/medical-history.svg";
  };

  const handleChangDate = (e) => {
    _setStartDate(e);
  };

  const handleDownload = () => {
    onDownload &&
      onDownload({
        begin_date: moment(startDate).startOf("year").format("YYYY-MM-DD"),
        end_date: moment(startDate).endOf("year").format("YYYY-MM-DD"),
      });
  };

  return (
    <ExportHealthCheckStyled>
      <div className="box_export_health">
        <div className="top_widget">
          <img className={`img_show ${img}`} src={renderImage()} alt="img" />
          <div className="group_text">
            <div>ดาวน์โหลดข้อมูลรายบุคคล</div>
            <div className="hText">Download Data</div>
          </div>
        </div>
        <div className="detail_show">
          <div className="text_show">
            กรุณาเลือกปีที่คุณต้องการดาวน์โหลดข้อมูล
          </div>
          <div className="filter_download">
            <div className="w200">
              <Inputs.InputDatePikerCustom
                theme_year
                dateFormat="yyyy"
                onChangeCustom={(e) => handleChangDate(e)}
                showYearPicker
                minDate={new Date("2021-01-01")}
                maxDate={new Date()}
                iconColor="#002366"
                formatAD
                // initialValues={new Date()}
              />
            </div>
            <div className="w200">
              <Buttons.BtnNormal
                theme_only_border_blue
                label="ดาวน์โหลด"
                fontFamilyTop={theme.FONT.STYLE.BOLD}
                fontSize={theme.FONT.SIZE.S14}
                disabled={!startDate && true}
                svg_front={<Icons.Download4 />}
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </ExportHealthCheckStyled>
  );
};

export default ExportHealthCheck;

import styled from "styled-components";

export const BackOfficeContainerStyled = styled.div`
  background: #ffffff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 45px 30px;
  min-height: calc(100vh - 85px);
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card_container {
    margin: auto;
    margin-top: 54px;
    display: flex;
    align-items: baseline;
    row-gap: 45px;
    flex-wrap: wrap;
    .card_wrap {
      width: 33%;
      flex-shrink: 0;
    }
    @media (max-width: 1238px) {
      .card_wrap {
        width: 50%;
      }
    }
    @media (max-width: 785px) {
      .card_wrap {
        width: 100%;
      }
    }
  }
`;

import styled from 'styled-components';

export const CurrentMSDStatContainerStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
  .block_3_col_layout {
    display: flex;
    overflow: auto;
    padding: 10px 0;
    margin-bottom: 4px;
    column-gap: 8px;
    row-gap: 8px;
    padding-left: 5px;
    margin: 0 -5px;
    &.mb_10 {
      margin-bottom: 10px;
    }
    .col_3 {
      flex: 1;
      min-width: 368px;
      .wrap_mr5_overflow {
        margin-right: 5px;
      }
    }
  }
  .block_2_col_layout {
    display: flex;
    padding: 10px 0px 20px 5px;
    column-gap: 24px;
    row-gap: 24px;
    margin: 0 -5px;
    overflow: auto;
    &.no_pdb {
      padding-bottom: 0px;
    }
    .col_2 {
      &.flex_1 {
        flex: 1;
      }
      &.w_416 {
        flex: 1;
        max-width: 416px;
        min-width: 416px;
        // min-width: 300px;
      }
      &.mw_780 {
        min-width: 780px;
      }
      .b2c_block_wrap {
        &.mb_24 {
          margin-bottom: 24px;
        }
      }
      .wrap_mr5_overflow {
        margin-right: 5px;
        height: 100%;
      }
    }
  }
  .section_chart_layout {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

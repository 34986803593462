import { useEffect, useState } from 'react';
import { MostPickedStyled } from './styled';
import { Blocks, Typographys, TableCustom, Skeleton } from 'components';
import moment from 'moment';
import { adminService } from 'apiServices';

const MostPicked = ({
  //  data,
  companyID,
}) => {
  const [_data, _setData] = useState();
  // const [_disBtnNext, _setDisBtnNext] = useState(true);
  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    fetchPopularRewardCategory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPopularRewardCategory = async (
    startDate = moment().startOf('month').format('YYYY-MM-DD'),
    endDate = moment().endOf('month').format('YYYY-MM-DD')
  ) => {
    let res = await adminService.GET_POPULAR_REWARD_CATEGORY(
      companyID,
      startDate,
      endDate
    );
    if (res && res.success) {
      _setData(
        res.data.map((e) => ({
          label: e.name_th,
          subLabel: e.name_en,
          num: `${e.redeemed_percent}%`,
        }))
      );
      _setIsLoading(false);
    } else {
      _setIsLoading(false);
    }
  };

  // const handleClickMonth = (value) => {
  //   _setIsLoading(true);
  //   if (value < 0) {
  //     _setDisBtnNext(false);
  //   } else {
  //     _setDisBtnNext(true);
  //   }
  //   fetchPopularRewardCategory(
  //     moment().add(value, 'M').startOf('month').format('YYYY-MM-DD'),
  //     moment().add(value, 'M').endOf('month').format('YYYY-MM-DD')
  //   );
  // };

  return (
    <MostPickedStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="16px"
        paddingBottom="35px"
        paddingLeft="16px"
      >
        <div className="container_most_pick">
          <div className="top_pick">
            {/* <div className="show_icon">
              <Icons.MostPick />
            </div> */}
            <Typographys.TopBottom
              theme_left_align
              label="หมวดที่ถูกเลือกมากที่สุด"
              labelBottom="Most Picked Category"
              fontColor="#3A3A3A"
              fontSize="16px"
              fontSizeLabelButtom="10px"
              fontFamilyTop="noto_sans_thai_bold, noto_sans_bold"
              fontFamilyBottom="noto_sans_thai_bold, noto_sans_bold"
            />
          </div>
          {_isLoading ? (
            <Skeleton.RewardMostPicked />
          ) : (
            <div className="mid_pick">
              {!_data || _data.length === 0 ? (
                <div className="label_empty">
                  ยังไม่มีการจัดอันดับหมวดที่ถูกเลือกมากที่สุดในเดือนนี้
                </div>
              ) : (
                <TableCustom.TableMostPicked
                  theme_standard_table_most_pick
                  data={_data}
                />
              )}
            </div>
          )}
          {/* <div className="bot_pick">
            <Buttons.BtnMonth
              theme_standard_btn_month
              disBtnNext={_disBtnNext}
              onClick={handleClickMonth}
            />
          </div> */}
        </div>
      </Blocks.Box>
    </MostPickedStyled>
  );
};

export default MostPicked;

import styled from "styled-components";

export const MemberActionStyled = styled.div`
  .layout_login {
    // background: ${({ theme }) => theme.COLORS.WHITE_1};
    display: flex;
    // border-radius: 8px;
    // padding: 6px 20px;
    column-gap: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_4};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: noto_sans_thai_bold, noto_sans_bold;
    .show_company {
      margin-right: 12px;
      display: flex;
      align-items: center;
      .icon_show {
        max-width: 47px;
        // width: 100%;
        margin-right: 14px;
      }
      .icon_show_2 {
        max-width: 66px;
        // width: 100%;
        margin-right: 14px;
      }
      .name_company {
        &.green {
          color: ${({ theme }) => theme.COLORS.GREEN_3};
        }
      }
    }
    .left_login {
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 3.2px 22px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      border-radius: 8px;
      position: relative;
      .expand_box {
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-radius: 8px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_23};
        position: absolute;
        border: none;
        right: 0px;
        bottom: -186px;
        width: 227px;
        z-index: 4;
        padding-top: 24px;
        box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.25);
        .group_item_menu {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 11.6px 16px;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          &.rela {
            position: relative;
          }
          &.show_hover {
            &:hover {
              background: ${({ theme }) => theme.COLORS.BLUE_11}80;
            }
          }

          .svg_item {
            display: flex;
            margin-right: 9px;
            &.ab {
              margin-right: 0px;
              cursor: pointer;
              position: absolute;
              top: -16px;
              right: 8px;
            }
          }
          .name_item {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
            width: 89%;
            &.gray {
              color: ${({ theme }) => theme.COLORS.GRAY_23};
            }
            .name_show {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .role_item {
              color: ${({ theme }) => theme.COLORS.GRAY_24};
              font-size: ${({ theme }) => theme.FONT.SIZE.S12};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
      .company_name_show {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_28};
        margin-right: 20px;
        padding-right: 20px;
      }
      .group_mem {
        cursor: pointer;
        display: flex;
        align-items: center;
        /* width: 209px; */
        flex-shrink: 0;
        .show_svg_left {
          display: flex;
          margin-right: 9px;
        }
        .show_svg_right {
          display: flex;
          margin-left: 9px;
        }
        .group_name {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          width: 90%;
          .top_group {
            text-overflow: ellipsis;
            white-space: nowrap;
            /* overflow: hidden; */
          }
          .show_role {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: ${({ theme }) => theme.COLORS.GRAY_24};
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          }
        }
      }
    }
    .right_login {
      padding-left: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .show_svg_right {
        display: flex;
        margin-right: 10px;
      }
    }
  }
`;

import React from 'react';
import { DepartmentCodeContainerStyled } from './styled';
import { adminService } from 'apiServices';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icons } from 'components';

class DepartmentCodeContainer extends React.Component {
  state = {
    dataPage: {
      data: [],
      hasMore: true,
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { dataPage } = this.state;
    let res = await adminService.GET_DEPARTMENT_LIST(
      this.props.companyID,
      !dataPage.page
        ? `?per_page=10`
        : `?per_page=${dataPage.page.per_page}&offset=${dataPage.page.per_page * dataPage.page.current_page + 1
        }`
    );
    if (res && res.success) {
      if (!res.data || res.data.length === 0) {
        this.setState((state) => ({
          isLoading: false,
          dataPage: { ...state.dataPage, hasMore: false },
        }));
      } else {
        this.setState((state) => ({
          isLoading: false,
          dataPage: {
            ...res,
            data:
              state.dataPage.data.length === 0
                ? res.data
                : [...state.dataPage.data, ...res.data],
            hasMore: res.data.length < Number(res.page.per_page) ? false : true,
          },
        }));
      }
    }
  };

  render() {
    const { handleClickCloseModal } = this.props;
    const { dataPage } = this.state;
    return (
      <DepartmentCodeContainerStyled>
        <div className="body_department">
          <div className="title">
            <div>รหัสแผนกในบริษัท</div>
            <div className="svg_c" onClick={handleClickCloseModal}>
              <Icons.CloseXModal />
            </div>
          </div>
          <InfiniteScroll
            dataLength={dataPage.data.length} //This is important field to render the next data
            next={this.fetchData}
            height={300}
            hasMore={dataPage.hasMore}
            loader=''
          // endMessage={<div>ldksfjdlskfj</div>}
          >
            <div className="table_department">
              <div className="head_show">
                <div className="h1">รหัส</div>
                <div className="h2">แผนก</div>
              </div>
              <div className="row_show">
                {dataPage &&
                  dataPage.data.length > 0 &&
                  dataPage.data.map((e, i) => (
                    <div key={i + 1} className="group_row">
                      <div className="r1">{String(e.id).padStart(4, '0')}</div>
                      <div className="r2">{e.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </DepartmentCodeContainerStyled>
    );
  }
}

export default DepartmentCodeContainer;

export default function UserDelete() {
  return (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xmlSpace="preserve">  <image id="image0" width="256" height="256" x="0" y="0"
    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAQAAAD2e2DtAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQflBRIGMjRw5OyDAAAS/klEQVR42u2deZRVxZ3Hv9VsTUNDC9GwuCAouywDcYCEUXABBIyggkvi
ccbRMy7HJTrqHBONM5njiNFxSRzRGTQ6YcYdRWMIi8giCgEXFIWg7MO+L2033f2dP6Cb7vvu7b63
qt771eXVh8Mf952+9b5V39+rW/feqvoBHo/H4/F4PB6Px+PxeDwej8fj8Xg8Ho/H4/F4PB6Px+Px
eDwej8fj8Xg8Ho/H4/F40oSSFpBN2ARd0Rs9cRraoSOK0AqNAAC7cRCbsQVr8RW+xpfqsLRSOY7L
AGBTDMU5OBdno1mMPy/Fn7EQMzE/nwPhOIHNOJ7/zd3UYS9f4Y/ZVLoOHk14Jidxm5b1tdnBx9lF
ui6ehLA/X2OlsfnVVPJNni1dJ09M2I1vs8qa+dVUcRp7S9fN0wBszUdZbt38air4BFtJ19ETCUdz
Y9bMr2YTx0nX0xMCi/l81s2v5jm2kK6vpw7sxa9yZj9JrmRP6Tp7auDlPJBT+0lyHy+WrrcHAMCf
WbzhS0Ilb5eue95DxcdEzK/mQekWyHP4uKj9JPlr6TbIY/iItPskyQek2yFP4R3Sztdwi3Rb5CEc
wwpp32uo4Gjp9rBFSuYDsCcWw6WHMXswSK2UFmGDAmkBcWBzTHXKfqAEr7G5tAgbpCIA8CT6SkvI
oDcekpZggxRcAjgC7zmpk7hQzZIWYYqLDVsHtsJynCqtIoLV6KNKpUWY4f4l4OfO2g+cgZ9LSzDF
8R6AnbEi1sxeKcrRQ30rLcIE13uAh5y2H2iK+6UlmOF0D8Be+Nz5EK3CWWqFtAh93G7e+xzXBwAF
+EdpCSY43AOwE1YfXcrlNuXoojZKi9DF5V/Y9amwH2iKG6Ul6ONsD8AmWIf20ipisgmnqUppEXq4
2wOMTI39QEdcKC1BF3cDYIK0gERcIS1AF0cvAWyGrWgtrSIBO9FOVUiL0MHVHmBIquwH2uJH0hL0
cDUAzpMWkAeKAbgbAMOkBSRmqLQAPZwcA7AJ9jv+DiCT79BalUuLSI6bPUCv1NkPFKKHtAQd3AyA
ftICtEjldhJuBkBXaQFa9JIWoIObAdBJWkD+qHYzAE6TFqBFB2kBOrgZACdKC9DCB4A1SqQFaJGu
Z5dHcTMA0rkzVypXCrkZAI2lBWjhA8AaVdICtEjllBA3AyCVTYn90gJ0cDMADkoL0MIHgDV2SQvQ
Yp+0AB3cDIAd0gK02CstQAc3A2CdtAAtVksL0MHNAPhGWoAWqdwyxs0ASGVTYpW0AB3cDIBPpAVo
8bW0AB3cnBLWCPtQJK0iIfvQNo0Tw53sAVQlPpLWkJgP0mi/owEAYI60gMTMlhagh6sBMFNaQGLS
F7IAHB0DAFTYgI7SKhKwCacoSovQwdEeQBFvSmtIxNR02u9sAACYKi0gES9JC9DF0UsAAPBLpCVR
0xKV2jyj7vYAwLPSAmLzgrQAfVzuAYqwBidJq4jBDpyuDkiL0MXhHkAdwhPSGmLxcHrtd7oHANgK
a3GCtIoGSPXv3+keAFD78KS0hgaZlGb7He8BAJbgK7STVlEPK9FXlUmLMMHpHgBQe3C7tIZ6IG5K
t/3OBwCgXsZ0aQ2RPK9S+gbgGI5fAgCAnfCFYymjjrAVvdROaRGmON8DAGqtk3k5KnBF+u1PCVR8
WTpXZAZ3SbeKHVJwCQAAtsQip/bgmYbxaX3/V5eUBADAM7DYmYdCy3CuSuVCsExSMAY4glqNqx1Z
NLoCI44X+1MGr2Gl9KWfq5nKrWCOE3gzq0TtX8dO0m2Q5/AaHhaz/3OeIl1/DziBpSL2v8di6bp7
AAAczC05Nr+KjzKdOxcdn/BULsqh/ds5WrrGngBswkdyNCCcwTStUMgn+COuyLL5O3kDU/OwLA9h
IR/ggSyZX87fso10DT0Nwo6cYv0BUSWnsot0zTyxYTdOYbkl80v5HFO593+ew458kJsMzV/F+5jO
vco9AMDGHMnnuUvD+nV8in8trT/3HJcjXDbGIIzAUAxAywb/eAOW4X3MUKnc4ceclAUAC3AWzsEA
/JeaF+OvG6E7uqMrOuH7OAlNcQKAgyjHbmzHemzAaixT22N9b2c8BGAB5mG5SudW1mmHfXgn367p
3Kv4v7l6McMi/kuttw+7+Q5vZzfp9sgbWMSxfIbrQ67aB/kLZnmPfjbjzdwQOmb4lk9zbLa/P69h
Ca/luw28+dvA25ilDeVYGGn+MQ7wFU5kwyMNTxLYklfxLX4Xc/y+jQ/YnqXDznyY22PfQZRyGi9n
+rKdugcVz+FLPJT4Ju4wX+doNrGgoJhX8w9aTxZ38T84WLoFUwzb8W6u1Gj4Y+zkcxxNzVVEbM+f
8A2DySY7OZ33MGU5Dx25DeQQ3IEfw8IvGEAZFuIDLMZiFSPxBBuhK/rhhxhmsCPRcryFt7A0jSsF
xAOAjXEp7kB2nsFtwCqswhpsxXZsRxX2AChCM7RGS5yKk9ER3dDbKNvXejyPl1Q6t7eXhy14J9cZ
dfpyVHIaRzA16yqcgy14GzdLu6hJOV9kWrawcxG25L0JbrLcooovpW2g5xRsyltTaz45lwOlW9Au
OR4EciwewxnSldZkD+7Bc3FG+myBPuiOM3Ea2uNEFNfkQt6LLdiG9diItfgCq9Rh6SrlNAA4GL/G
EOkKa/MGblZbGqhhGwzH+RiMnrGyH5fjUyzEfMxRqUw4lwi24WThVX0mlPK2BurXgbdyASu0Si/n
LN5yHM9DYgFv4E5pDw34sr4ZgmzEi/muhUmp5ZzOscfhbSX75XQNj33ejn7Xx0LexDVWv+0vvIWF
0p7ZM78x72GZtINGPMFGkXW7KUvPMTbyJhuvtcRhNy6V9s+IKt4aWbex/Dqr3/0VR0j7Z2r/WO6W
dtCICl4XUbP2fD0nCl7n96Vd1DVf8ZcpHvOTZAWvjKjb1VqTzvXYwaukvdSxvxEnS/tnSBX/PrRm
zflEzrW8qDu/Qcr+JnxT2j9j7g6tWWd+IaLmM6bn2Smb8DVp94wJzVHAwdwqpmgn/0ba2Xj2K74o
7Z4xs8K2guG42FNUs0MpL5N2N04A/EraPWPW8Hsh9brC2spjfSp4jbS/Ddl/lXQbGVPGASH1+qkD
21OSZAV/Iu1xffZ34z7pFjLmnpB6jRHclzBIBS+x7Zul18EsxNLU5PmM4n2cH1z4ycGYbTRp1DYH
MVwttlmgrbdPv0q9/aW4PsP+jnjDKfuBFpjOk20WaCUAONjp1E7xeDA4uZuFeNPBjGUn4VU2tVec
hQBgAX6DRubliLIcj2V8Ngk/kJYVyiA8aq8wGz3AdfgrscawxZ3B+XkcgVukRUVyMy+yVZTxIJCF
WI2076T5JxV4+coTsMLB7v8Ym3CW2m2jIPMe4MbU21+FezM+m+S0/UBHPGKnIMMegM2wDml9a13N
NDUuUKuh+EB+1WQDVGGI+ti8GNMe4MrU2w9MqnvIAjwey/4KPINLMQx3YZ0VHWtxF4bhMkxGRYy/
LsBvHNjFOOWTvkhybkad4j3SLueomjNO4AJjHQtYUlPeqJhPHy+Xtr+fqHV2CHb/TfhNrPOernNW
sWEILKibjYTPxDprBY1vv80uAVfnLNayxVa8E/hkIjrHOnNW7QO1H6OwUFvFQowKJKKbGeu8Hphg
2gAGAUCFK0y/Xpzf1b3/p8LdMc8M3IQZhECm/RmlR/Iz0wYw6QH6wOpTaRFeCByfj7NinpnxlFAz
BMLsR+xnkAM5NLsNVA/8J+nLtzFfZNQpfpLqDaFTR5KOBRaEZSLj9xLsej5VLgBmSvtnzD8HatQ2
0cSvZWH5RNiC78cuYWGo/a35cQIVh9haxv4C7pX2z5jAOwzemPD8Jcdu3WqVErcXCP/1l3BxQhV/
JxMAvaXdM2Z78EEKZycuQ78XsPHrP8IfZALgSmn/jHk1UKO2WpO/9HoBW79+kiw1WTqifxfQPTeB
lkXmBo7Pj7WvR5CBmJkZAg3cEYSO/NkaM7RmIBTifP1G0A+AM/W/1BE+DByfq1nOQMzJvBCo/RiR
EWLV3xtl/9maCobpN4J+AKT9JfBhrAh8co52Wf0xI6QXOIiLQ3qBhRgZYb/+bqk/zGI7RWG4rbM8
nwXq08pwPXO8scCCiKHfR0bffVh/FKDfA6Q9p+bywHFfwxkAcS4EUZ3/Hw33Sm4M7TyH+gHg1nTp
5AQ3eO5jXGJDF4Kozv+PGGT83dqT8vUDIO35MTYEjm0swa7vjiB85N/Kiv0GAaBz42N6phtsDByf
aqXUgZjJC9Seuh+q/RwFZNF+QHv34uNvX7q4bA4c2wmAyF4gwn5bKWba656YvwGwL3Dc1lrJoSEQ
xKr90J/D7AOgmlZapYQTekdQG7bAdIv2A9pvBPM3AIJdcrFWKVH0x6zoEGALvAu7m75o35PlbwBU
Bo5tD2pDbwoBgMWYYfDUMRwfAIkJGh5nLn4yCiJatwr28wRo5yvL3wAITqi2HQDLcEF42jp1EGMi
XhPpc0j3xPwNgOCDrANWS1+C86KzFka8JjKhVPfE/A2A4G3fDotlL8aFwYdBdTFcR5DJPt0T8zcA
gnN6t1sreRlG1W8/UO98AR026Z7oA6Aa7SYMsBjDMzt/lsSeL6CHD4DEBBe1/MVKqR/jwswUUCzB
TI2JY0nYqHti/gZA8O3fagtlLsWoEPtbYwYGJp44lowvdE/M3wAIzmnUbsIaPsJ5mdu2sASzjs71
SzJxLCnLjUtIivSMLmO+DtSnMQ8ZlbcobIUOS7ikzl+ZLSWJokx/47j87QG61rVCVeATg9KW4qLI
zr822bkQ/FmV656avwGg0D/wyQLtshZheL2df22ycSGYp98M+RsAmUuw/6RZziKMVBkPYkJ+/dXY
7wV0zzNB+hJugemBGjXlfo1SPmTITIIG1/iZryw+xkEW+QDQ4QAD7wM00t0sDLU/OPQLw95w8BUT
H/P5EtAiI5f5ywlL+BCjEnX+tbF3IXgty+0UjvTP1wqB3TZZlOgiELXCt+FffzU2eoG9QmnlpL2z
wvqMHQKejX3umlDzkthPRoXACVwbu4SnYUQ+XwKAUzImZsZvzqcy3/ixBDNjdP61CZ9EvhtPxS7h
WbMmyO8AQHCjO/Vp7LvxZcEPNOwHoiaRL4t59nz1qVkD5HsA/DTjFurhmGcGXifHHPqFETYcPDHm
uf9q2gD5HgAlGZtdvhPz11cnw0DEU7+4ZD4dHBnrvKVqRvabKAKthyYukvEGgBfHOq+C42vOODHh
0C+MJaz51fPSmJkKR8V1KxrtNfH8FqfnPuyywjA1N1C3ObE2XanC7zELe9Af/2AlvcRmTMYnKMEF
uCpWz5yR50QH/QB4HeN1z3WMOeq8QN36YqnzabAq0U+Zz2EwGAO8L90C1hjOwB476jP8VlpUg/y7
DftNeoC22IhC6VawxFwV6PJZhM/RRVpWPaxCP6W9FqA22j2A2on/lG4Fa5wbTBuhDuEGVOkVlgMq
cZ0d+81uA+/HNumWsMYjwTeDag7+TVpUJL9U+pNXAhgEgNqNiVlY5ihDF9yZ8dn9mC0tK5TZeMhe
YaZp4ybiRVjMZCtIGfqrrwK1a4ePrW0dY4tvMEhZXMZm+CRQvYwRWC/bIpZohinBFExqC0Zhj7Sw
OuzCaJv2W3gUrOaiNx5yrJn0GJSZL0itwESUSQuroRSXqJV2i7SUepDFGI8rNPfbdocKDFfzM+o2
EtOc2BWxHOPVu7YLtZp7km0wBpdjZIrDYAv6qy0Z9boUU8VHOmUYp96zX2wWko/yZEzAOAxJ6ZvG
uRiRucyCF+B1y9tIJWMvLlVZuSfJWvZZtsVojMFFkJmxZsIL6m9D6jMA74hlFN+A0Sr3q/9swGJO
4O+5XfSFb3LuC61LB34oomYBO0j7aBoGBRzAeziT5dLOxqSKN4fWoymfMswqkFzJo2wi7Z+9QGjD
iZzCNdL+xmr46yPqMDJBUkdTNnGMtGfZCYROvJa/4zppl+ulMjIE2nBKzBk7ZkxpeMfhlMPOvJaT
+TkrpN2O4BeRys/WyO+XhKW0u5Ws27CY5/E+Tuc2acczeJoRM4NYwMv4WVa+cy2vZc5uobN2G6gH
O6AnemEABqCHI9rmYYLaGqFW4RLcAZv5u7/EJPyPyuE7VjcaOQSWoC96oCe6o4dwmvr1GK+W1qO0
D27EBOMkWmV4A5MxT2nv+quHswFQG7ZCd3RHZ5yOzjgdHXKu+jvciyfrs4ZNMByX4xKttBOlmI1X
8VbmFjO5IBUBUBcWohNORwd0RPuj/0/K0hzendiCTdiM/8NmvK3WNaisMX6AIRiKIbFW9hzCMnyE
mZhva3qXDikMgExYgDZoW+t/MZqjBIUoQms0x5HFX4V19tSvOJowogyHsBdlOIAD+A57sQu7sBO7
jvxT32kr6owuOANnoAtaoxitUITm2ANgO7ZhK77FKqzE18r+FvUej8fj8Xg8Ho/H4/F4PB6Px+Px
eDwej8fj8Xg8Ho/H4/F4PB6Px+PxeDwej8fj8XiOc/4fxKbJxDbIOooAAAAldEVYdGRhdGU6Y3Jl
YXRlADIwMjEtMDUtMThUMDY6NTA6NTIrMDM6MDDN8xi4AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIx
LTA1LTE4VDA2OjUwOjUyKzAzOjAwvK6gBAAAAABJRU5ErkJggg==" />
</svg>
  );
}

import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSelectCusIconConfirmStyled, OptionStyled } from './styled';
import Select, { components } from 'react-select';
import { Icons, Modals, Blocks } from 'components';
import theme from 'styles/theme.json';

const InputSelectCusIconConfirm = ({
  theme_standard,
  theme_language,
  onChange,
  options,
  value,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_language: theme_language,
  });

  const [_isShowModal, _setIsShowModal] = useState(false)
  // const [_setTempValue] = useState()

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: `1px solid ${theme.COLORS.GRAY_24}`,
      // color: state.selectProps.menuColor,
      color: theme.COLORS.GRAY_23,
      fontSize: '16px',
      fontFamily: theme.FONT.STYLE.MEDIUM,
      padding: 0,
      zIndex: 22,
    }),

    control: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: '5.2px 16px',
      width: 'calc(100% - 30px)',
      border: `1px solid ${theme.COLORS.GRAY_25}`,
      borderRadius: '8px',
      fontSize: '16px',
      fontFamily: theme.FONT.STYLE.MEDIUM,
      // boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const CustomOption = ({ innerProps, isDisabled, data }) =>
    !isDisabled ? (
      <OptionStyled {...innerProps}>
        <div className="op_icon_wrap">{data.icon}</div>
        <div className="op_label_wrap">{data.label}</div>
      </OptionStyled>
    ) : null;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <Icons.ArrowDropdown translate="21.81" rotate="90" />
        ) : (
          <Icons.ArrowDropdown />
        )}
      </components.DropdownIndicator>
    );
  };

  const handleChangeStatus = (e) => {
    if (e.value === 'open') {
      _setIsShowModal(true)
      onChange(e)
      // _setTempValue(e)
    } else {
      // _setTempValue(e)
      onChange(e)
    }
  }

  const handleClickCloseModal = () => {
    _setIsShowModal(false)
    // _setTempValue()
  }

  const handleConfirmStatus = () => {
    _setIsShowModal(false)
    // onChange(_tempValue)
  }

  const handleCancelStatus = () => {
    _setIsShowModal(false)
    onChange(options[0])
  }

  return (
    <InputSelectCusIconConfirmStyled>
      <div className={customClass}>
        <Select
          styles={customStyles}
          defaultValue={options[0]}
          options={options}
          value={value}
          {...props}
          onChange={handleChangeStatus}
          components={{
            Control: ({ children, selectProps, ...rest }) => {
              return (
                <components.Control {...rest}>
                  {selectProps.value.icon}
                  {children}
                </components.Control>
              );
            },
            Option: CustomOption,
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <Modals.Standard
        theme_modal_standard
        isShow={_isShowModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        <Blocks.BoxDelete
          theme_box_delete
          showIcon={false}
          textShow='ยืนยันสถานะของกลุ่มหรือไม่'
          labelSub1='คุณไม่สามารถแก้ไขสถานะได้หลังจากที่คุณเลือก'
          labelSub2='สถานะ Open group ไปแล้ว'
          minWidth='374px'
          maxWidth='374px'
          handleConfirm={handleConfirmStatus}
          handleCancel={handleCancelStatus}
        />
      </Modals.Standard>
    </InputSelectCusIconConfirmStyled>
  );
};

InputSelectCusIconConfirm.propTypes = {};

export default InputSelectCusIconConfirm;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TwoToneStyled } from './styled';

const TwoTone = ({ theme_standard_two_tone, labelTop, labelBottom, data }) => {
  const customClass = cx({
    theme_standard_two_tone: theme_standard_two_tone,
  });
  return (
    <TwoToneStyled>
      <div className={`chart_container ${customClass}`}>
        {
          labelTop &&
          <div className='top_label'>
            {labelTop}
          </div>
        }
        {
          labelBottom &&
          <div className='sub_label'>
            {labelBottom}
          </div>
        }
        {
          data &&
            (data.norisk.percent > 0 ||
              data.pui.percent > 0)
            ?
            <>
              <div className='body_graph'>
                {
                  data.norisk.percent > 0 &&
                  <div className='value_graph' style={data && { width: `${data.norisk.percent}%`, borderRadius: data.norisk.percent >= 100 && '9px' }}>
                    {`${data && data.norisk.percent}%`}
                  </div>
                }
                {
                  data.pui.percent > 0 &&
                  <div className='value_graph purple' style={data && { width: `${data.pui.percent}%`, borderRadius: data.pui.percent >= 100 && '9px' }}>
                    {`${data && data.pui.percent}%`}
                  </div>
                }

              </div>
            </>
            :
            <div className='empty'>
              
            </div>
        }
      </div>
    </TwoToneStyled>
  );
};

TwoTone.propTypes = {};

export default TwoTone;

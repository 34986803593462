import styled from "styled-components";

export const TwoToneStyled = styled.div`
  .chart_container {
    .top_label {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: noto_sans_thai_semi_bold, noto_sans_regular;
    }
    .sub_label {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_regular, noto_sans_regular;
      margin-top: 5px;
    }
    .body_graph {
      margin-top: 14px;
      display: flex;
      .value_graph {
        padding: 12px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        background: ${({ theme }) => theme.COLORS.BLUE_13};
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        &.purple {
          background: ${({ theme }) => theme.COLORS.PURPLE_7};
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
        }
      }
    }
    .empty {
      margin-top: 14px;
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      border-radius: 9px;
      height: 48px;
    }
  }

  .theme_standard_two_tone {
  }
`;

export default function MenuStep({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.54"
      height="21.598"
      viewBox="0 0 23.54 21.598"
    >
      <g
        id="running_1_"
        data-name="running (1)"
        transform="translate(0 -23.055)"
      >
        <g
          id="Group_13952"
          data-name="Group 13952"
          transform="translate(1.379 25.343)"
        >
          <g id="Group_13951" data-name="Group 13951">
            <path
              id="Path_19075"
              data-name="Path 19075"
              d="M34.184,68.5H30.69a.69.69,0,1,0,0,1.379h3.494a.69.69,0,1,0,0-1.379Z"
              transform="translate(-30 -68.5)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13954"
          data-name="Group 13954"
          transform="translate(1.379 30.86)"
        >
          <g id="Group_13953" data-name="Group 13953">
            <path
              id="Path_19076"
              data-name="Path 19076"
              d="M34.184,188.5H30.69a.69.69,0,1,0,0,1.379h3.494a.69.69,0,1,0,0-1.379Z"
              transform="translate(-30 -188.5)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13956"
          data-name="Group 13956"
          transform="translate(0 28.101)"
        >
          <g id="Group_13955" data-name="Group 13955">
            <path
              id="Path_19077"
              data-name="Path 19077"
              d="M4.184,128.5H.69a.69.69,0,0,0,0,1.379H4.184a.69.69,0,0,0,0-1.379Z"
              transform="translate(0 -128.5)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13958"
          data-name="Group 13958"
          transform="translate(9.011 27.41)"
        >
          <g id="Group_13957" data-name="Group 13957">
            <path
              id="Path_19078"
              data-name="Path 19078"
              d="M209.149,117.616h-4.184v-2.759a1.382,1.382,0,0,0-2.355-.975l-5.517,5.517a1.379,1.379,0,0,0,0,1.95l3.163,3.163-3.852,3.852a1.379,1.379,0,0,0,1.95,1.95l4.828-4.828a1.379,1.379,0,0,0,0-1.95l-3.163-3.163,2.187-2.187V119a1.379,1.379,0,0,0,1.379,1.379h5.563a1.379,1.379,0,0,0,0-2.759Z"
              transform="translate(-196 -113.477)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13960"
          data-name="Group 13960"
          transform="translate(6.942 24.308)"
        >
          <g id="Group_13959" data-name="Group 13959">
            <path
              id="Path_19079"
              data-name="Path 19079"
              d="M156.936,46.238a1.38,1.38,0,0,0-1.74.172L151.4,50.2a1.379,1.379,0,1,0,1.95,1.95l2.994-2.994,1.008.726,1.347-1.347a2.743,2.743,0,0,1,.782-.537Z"
              transform="translate(-150.999 -46.006)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13962"
          data-name="Group 13962"
          transform="translate(0.736 35.014)"
        >
          <g id="Group_13961" data-name="Group 13961">
            <path
              id="Path_19080"
              data-name="Path 19080"
              d="M24.394,280.095a2.734,2.734,0,0,1-.706-1.245L16.4,286.135a1.379,1.379,0,0,0,1.95,1.95l7.015-7.015Z"
              transform="translate(-16 -278.85)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_13964"
          data-name="Group 13964"
          transform="translate(16.456 23.054)"
        >
          <g
            id="Group_13963"
            data-name="Group 13963"
            transform="translate(0 0)"
          >
            <circle
              id="Ellipse_1702"
              data-name="Ellipse 1702"
              cx="2.571"
              cy="2.571"
              r="2.571"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.854"
      height="22.363"
      viewBox="0 0 23.854 22.363"
    >
      <g id="running" transform="translate(0 -15.996)">
        <g
          id="Group_13966"
          data-name="Group 13966"
          transform="translate(0.793 15.996)"
        >
          <g
            id="Group_13965"
            data-name="Group 13965"
            transform="translate(0 0)"
          >
            <path
              id="Path_19081"
              data-name="Path 19081"
              d="M37.99,24.382h-2.8v-2.1h0v0a2.133,2.133,0,0,0-.122-.7,2.8,2.8,0,1,0-2.63-2.3L29.728,17.4l-.011-.007a2.1,2.1,0,0,0-2.645.263L23.928,20.8a2.1,2.1,0,0,0,2.965,2.965l1.914-1.914,1.022.736-3.8,3.8-8.386,8.386A2.1,2.1,0,1,0,20.6,37.745l6.9-6.9,1.228,1.228-2.711,2.711a2.1,2.1,0,1,0,2.965,2.965l4.193-4.193a2.1,2.1,0,0,0,0-2.965l-2.711-2.711.663-.663A2.1,2.1,0,0,0,33.1,28.575H37.99a2.1,2.1,0,1,0,0-4.193Zm-2.8-6.989a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,35.194,17.394Zm-6.053,2.978a.7.7,0,0,0-.9.073L25.9,22.78a.7.7,0,1,1-.988-.988l3.145-3.145a.7.7,0,0,1,.878-.09l2.932,2.03a2.059,2.059,0,0,0-.256.217l-.785.785ZM19.614,36.757a.7.7,0,1,1-.988-.988l6.988-6.988a2.1,2.1,0,0,0,.41.578l.494.494ZM37.99,27.178H33.1a.7.7,0,0,1-.7-.7V25.66a.7.7,0,0,0-1.193-.494l-2.217,2.217a.7.7,0,0,0,0,.988l3.2,3.2a.7.7,0,0,1,0,.988L28,36.757a.7.7,0,1,1-.988-.988l3.2-3.2a.7.7,0,0,0,0-.988l-3.2-3.2a.7.7,0,0,1,0-.988L32.6,21.792a.7.7,0,0,1,1.193.493v2.8a.7.7,0,0,0,.7.7H37.99a.7.7,0,0,1,0,1.4Z"
              transform="translate(-17.025 -15.996)"
            />
          </g>
        </g>
        <g
          id="Group_13968"
          data-name="Group 13968"
          transform="translate(1.491 18.791)"
        >
          <g
            id="Group_13967"
            data-name="Group 13967"
            transform="translate(0 0)"
          >
            <path
              id="Path_19082"
              data-name="Path 19082"
              d="M36.193,76H32.7a.7.7,0,1,0,0,1.4h3.494a.7.7,0,0,0,0-1.4Z"
              transform="translate(-32 -75.996)"
            />
          </g>
        </g>
        <g
          id="Group_13970"
          data-name="Group 13970"
          transform="translate(1.491 24.382)"
        >
          <g id="Group_13969" data-name="Group 13969">
            <path
              id="Path_19083"
              data-name="Path 19083"
              d="M36.193,196H32.7a.7.7,0,0,0,0,1.4h3.494a.7.7,0,0,0,0-1.4Z"
              transform="translate(-32 -195.996)"
            />
          </g>
        </g>
        <g
          id="Group_13972"
          data-name="Group 13972"
          transform="translate(0 21.587)"
        >
          <g id="Group_13971" data-name="Group 13971">
            <path
              id="Path_19084"
              data-name="Path 19084"
              d="M4.286,136H.7a.7.7,0,0,0,0,1.4H4.286a.7.7,0,0,0,0-1.4Z"
              transform="translate(0 -135.996)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

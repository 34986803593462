import React from "react";
import { connect } from "react-redux";
import { NewManagementContainerStyled } from "./styled";
import { HeadContent } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import {
  Lists,
  Icons,
  TableCustom,
  Inputs,
  Loading,
  Buttons,
  Modal,
  BoxDelete2,
  BGLoading,
} from "components";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { getObjectKey } from "utils/functions/getObjectKey";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

class NewManagementContainer extends React.Component {
  state = { isLoading: true, isShowModal: false };

  componentDidMount() {
    this.fetchData();
    this.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchContentList(),
      this.fetchContentCategory(),
      this.checkPermission(),
    ]);
    if (res) {
      this.setState({
        perMissions: res[2],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "content_manage"
    );

    return checkPermission;
  };

  fetchContentList = async (stringQuery = `?per_page=10&offset=0`) => {
    let res = await adminService.GET_CONTENT_LIST(
      this.props.companySelectedRedux.id,
      stringQuery
    );
    if (res && res.success) {
      this.setState({
        data: res.data,
        pagination: { ...res.page },
        isLoading: false,
        isBgLoading: false,
      });
    } else {
      this.setState({
        data: [],
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  fetchContentCategory = async () => {
    let res = await adminService.GET_CAMPANIES_ID_CONTENT_CATEGORY(
      this.props.companySelectedRedux.id
    );
    if (res && res.success) {
      let dataTemp = [
        {
          icon: <Icons.All />,
          title: "ทั้งหมด",
          title_en: "All",
          value: "all",
          score: res.data.reduce((result, data) => {
            result += data.contents_count ? Number(data.contents_count) : 0;
            return result;
          }, 0),
        },
        ...res.data.map((e) => ({
          icon: this.renderCateIcon(e.category),
          title: e.name_th,
          title_en: e.name_en,
          score: e.contents_count,
          value: e.category,
        })),
      ];

      let dataTempFilter = [
        {
          label: "ทั้งหมด",
          name: "All",
          value: "all",
        },
        ...res.data.map((e) => ({
          label: e.name_th,
          name: e.name_en,
          value: e.category,
        })),
      ];

      this.setState({
        dataFilter: dataTempFilter,
        categoryList: dataTemp,
      });
    }
  };

  renderCateIcon = (key) => {
    switch (key) {
      case "health":
        return <Icons.New />;
      case "relax":
        return <Icons.Nutrition />;
      case "exercise":
        return <Icons.Exercise />;
      case "other":
        return <Icons.Other />;
      default:
        break;
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  handleClickDetail = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_PREVIEW_DETAIL}/${e.id}`);
  };

  handleClickSort = (e) => {};

  renderCategory = (e) => {
    switch (e) {
      case "health":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.New />}
          </div>
        );

      case "relax":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Nutrition />}
          </div>
        );

      case "exercise":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Exercise />}
          </div>
        );

      case "other":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.Other />}
          </div>
        );

      case "all":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.All />}
          </div>
        );

      default:
        break;
    }
  };

  renderType = (e) => {
    switch (e) {
      case "audio":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeSound />}
          </div>
        );

      case "video":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeVideo />}
          </div>
        );

      case "article":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.TypeBook />}
          </div>
        );

      default:
        break;
    }
  };

  renderStatus = (e) => {
    switch (e) {
      case false:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {<Icons.StatusPublic />}
            <div style={{ marginLeft: "9px" }}>{` สาธารณะ`}</div>
          </div>
        );

      case true:
        return (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            {<Icons.StatusPrivate />}
            <div style={{ marginLeft: "9px" }}>{` ไม่เผยแพร่`}</div>
          </div>
        );

      default:
        break;
    }
  };

  handleSelectedFilter = (key, e) => {
    switch (key) {
      case "categoryFilter":
        const hasAll = e.some((f) => f === "all");
        this.setState(
          {
            categoryFilter: hasAll
              ? false
              : `categories=${e.filter((e) => e !== "all").join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "categoryLeftMenuFilter":
        const hasAllLeft = e.value === "all";
        this.setState(
          {
            categoryLeftMenuFilter: hasAllLeft
              ? false
              : `categories=${e.value}`,
            categoryFilter: false,
            disbledCateFilterTable: hasAllLeft ? false : true,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "typeFilter":
        this.setState(
          {
            typeFilter: `types=${e.filter((e) => e !== "all").join()}`,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "statusFilter":
        if (e.length === 2) {
          this.setState(
            {
              statusFilter: false,
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              statusFilter: `is_draft=${e.filter((e) => e !== "all").join()}`,
            },
            () => this.handleJoinQuery()
          );
        }
        break;
      default:
        break;
    }
  };
  handleJoinQuery = () => {
    this.setState({
      isBgLoading: true,
    });
    const { categoryFilter, categoryLeftMenuFilter, typeFilter, statusFilter } =
      this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (categoryLeftMenuFilter) {
      queryString.push(categoryLeftMenuFilter);
    }
    if (typeFilter) {
      queryString.push(typeFilter);
    }
    if (statusFilter) {
      queryString.push(statusFilter);
    }
    if (queryString && queryString.length > 0) {
      this.fetchContentList(`?${queryString.join("&")}`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}`,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    } else {
      this.fetchContentList();
      this.setState({
        queryFilterAll: false,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}`);
    }
  };
  handlePageClick = (e) => {
    this.setState({
      isBgLoading: true,
      resetSelect: false,
    });
    const { queryFilterAll } = this.state;
    let query = `?per_page=10&offset=${e.selected * 10}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW}${query}`);
    this.fetchContentList(query);
  };

  handleClickCreateContent = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_NEW_DETAIL}/create`);
  };

  handlePopup = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  // TABLE PROCESS
  //========================================
  //========================================
  handleChangeSelectedTable = (e) => {
    this.setState({
      selectedData: Object.keys(e).map((key) => key),
    });
  };

  handleConfirmDelete = () => {
    this.setState({
      isShowModal: false,
      isLoading: true,
    });
    const { selectedData } = this.state;

    if (selectedData && selectedData.length > 0) {
      Promise.all(
        selectedData.map(async (e) => {
          let res = await adminService.DELETE_CONTENT(e);
          return res;
        })
      )
        .then((response) => {
          let noSuccess;
          let Success;

          response.map((e, i) => {
            if (e.success) {
              toast.success("ลบสำเร็จ");

              return (Success = { ...Success, i });
            } else {
              toast.error("ลบไม่สำเร็จ");

              console.log({ ...noSuccess, i });
              return (noSuccess = { ...noSuccess, i });
            }
          });

          this.setState(
            {
              isLoading: true,
            },
            () => {
              this.fetchData();
            }
          );
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };

  render() {
    const {
      data,
      pagination,
      isLoading,
      categoryList,
      disbledCateFilterTable,
      isShowModal,
      selectedData,
      perMissions,
      isBgLoading,
      resetSelect,
      dataFilter,
    } = this.state;
    return (
      <NewManagementContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <div className="manage_container">
            <div className="head_wrap_layout">
              <HeadContent
                onClick={this.handleBackClick}
                title_name={this.props.authenRedux?.company?.name}
                isUseLogBtn
              />
            </div>
            <div className="bottom_control">
              <div className="tab_show">
                <div className="tab_select">Well Library</div>
              </div>
              {perMissions && (
                <div className="group_button_layout">
                  <div className="del_button">
                    <Buttons.BtnNormal
                      theme_red_border_bg_white
                      label="ลบ"
                      // disabled={false}
                      disabled={!(selectedData && selectedData.length > 0)}
                      svg_front={
                        <Icons.Delete5
                          color={
                            selectedData && selectedData.length > 0
                              ? "#ED5E49"
                              : "#fff"
                          }
                        />
                      }
                      onClick={this.handlePopup}
                    />
                  </div>
                  <div className="w200">
                    <Buttons.BtnNormal
                      theme_light_blue
                      padding="3px 10px"
                      label={
                        <div className="group_t">
                          <div>สร้างบทความใหม่</div>
                          <div>Create new content</div>
                        </div>
                      }
                      svg_front={<Icons.Pencil3 />}
                      onClick={this.handleClickCreateContent}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="block_2_col_layout">
              <div className="col_2 w_280">
                <Lists.Category
                  data={categoryList}
                  onClickItem={(e) =>
                    this.handleSelectedFilter("categoryLeftMenuFilter", e)
                  }
                />
              </div>
              <div className="col_2 flex_1">
                <TableCustom.TableNormal
                  theme_standard_table_normal_purple
                  columns={columns({
                    handleClickSort: this.handleClickSort,
                    handleClickDetail: this.handleClickDetail,
                    renderCategory: this.renderCategory,
                    renderType: this.renderType,
                    renderStatus: this.renderStatus,
                    handleSelectedFilter: this.handleSelectedFilter,
                    disbledCateFilterTable: disbledCateFilterTable,
                    perMissions: perMissions,
                    dataFilter: dataFilter,
                  })}
                  useSelect={perMissions ? true : false}
                  data={data}
                  pagination={pagination}
                  labelEmpty="ยังไม่มีข้อมูล"
                  onChangeSelected={this.handleChangeSelectedTable}
                  showPagination
                  isUseResetSelect={false}
                  resetSelect={resetSelect}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
          </div>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          <BoxDelete2
            theme_box_delete
            handleConfirm={this.handleConfirmDelete}
            handleCancel={this.handleClickCloseModal}
            backgroundColor="#EC5E49"
            fontColor="#fff"
            textShow={
              <div
                style={{
                  color: "#EC5E49",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Icons.Delete width="24" height="24" />
                </div>
                <div>ยืนยันการลบบทความหรือไม่ ?</div>
              </div>
            }
            // textShow={selectedData && `คุณต้องการลบ ${selectedData.length} บทความ?`}
          />
        </Modal>
      </NewManagementContainerStyled>
    );
  }
}

const columns = ({
  handleClickSort,
  handleClickDetail,
  renderCategory,
  renderType,
  renderStatus,
  handleSelectedFilter,
  disbledCateFilterTable,
  perMissions,
  dataFilter,
}) => {
  let tempColumn = [];

  if (perMissions) {
    tempColumn.push({
      title: "",
      field: "",
      headerStyle: { textAlign: "center", width: "20px", flexShrink: 0 },
      style: { textAlign: "center", width: "20px", flexShrink: 0 },
    });
  }
  tempColumn.push(
    {
      title: "หัวข้อ",
      field: "topic",
      headerStyle: { textAlign: "left", minWidth: "10px" },
      style: {
        textAlign: "left",
        minWidth: "10px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
        >
          {value}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => handleClickSort("d1")}
        >
          <div>หมวด</div>
          {!disbledCateFilterTable && (
            <div style={{ display: "flex", marginLeft: "16px" }}>
              <Inputs.InputGroupCheckBox
                theme_standard_group_check_box
                labelButton="SUBMIT"
                data={dataFilter}
                onChangeCustom={(e) =>
                  handleSelectedFilter("categoryFilter", e)
                }
              />
            </div>
          )}
        </div>
      ),
      field: "category",
      headerStyle: {
        display: "flex",
        justifyContent: "center",
        width: "100px",
        flexShrink: 0,
      },
      style: {
        display: "flex",
        justifyContent: "center",
        width: "100px",
        flexShrink: 0,
      },
      render: ({ origin, value }) => (
        <div
          style={{ fontSize: "18px", color: "#000000" }}
          onClick={() => handleClickDetail(origin)}
        >
          {renderCategory(value)}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => handleClickSort("d2")}
        >
          <div>ประเภท</div>
          <div style={{ display: "flex", marginLeft: "16px" }}>
            <Inputs.InputGroupCheckBox
              theme_standard_group_check_box
              labelButton="SUBMIT"
              data={[
                {
                  label: "All",
                  name: "all",
                  value: "all",
                },
                {
                  label: "บทความ",
                  name: "article",
                  value: "article",
                },
                {
                  label: "เสียง",
                  name: "audio",
                  value: "audio",
                },
                {
                  label: "วิดีโอ",
                  name: "video",
                  value: "video",
                },
              ]}
              onChangeCustom={(e) => handleSelectedFilter("typeFilter", e)}
            />
          </div>
        </div>
      ),
      field: "type",
      headerStyle: {
        display: "flex",
        justifyContent: "center",
        width: "100px",
        flexShrink: 0,
      },
      style: {
        display: "flex",
        justifyContent: "center",
        width: "100px",
        flexShrink: 0,
      },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>{renderType(value)}</div>
      ),
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => handleClickSort("d3")}
        >
          <div>สถานะ</div>
          <div style={{ display: "flex", marginLeft: "16px" }}>
            <Inputs.InputGroupCheckBox
              theme_standard_group_check_box
              labelButton="SUBMIT"
              data={[
                {
                  label: "All",
                  name: "all",
                  value: "all",
                },
                {
                  label: "ไม่เผยแพร่",
                  name: "true",
                  value: "1",
                },
                {
                  label: "สาธารณะ",
                  name: "false",
                  value: "0",
                },
              ]}
              onChangeCustom={(e) => handleSelectedFilter("statusFilter", e)}
            />
          </div>
        </div>
      ),
      field: "is_draft",
      headerStyle: {
        display: "flex",
        justifyContent: "flext-start",
        width: "100px",
        flexShrink: 0,
      },
      style: {
        display: "flex",
        justifyContent: "flext-start",
        width: "100px",
        flexShrink: 0,
      },
      render: ({ origin, value }) => (
        <div onClick={() => handleClickDetail(origin)}>
          {renderStatus(value)}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            minWidth: "10px",
            maxWidth: "120px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          จำนวนคนเข้าดู
        </div>
      ),
      field: "total_view_count",
      headerStyle: {
        display: "flex",
        justifyContent: "center",
        minWidth: "10px",
        maxWidth: "120px",
      },
      style: { textAlign: "center", minWidth: "10px", maxWidth: "120px" },
      render: ({ origin, value }) => (
        <div
          style={{
            minWidth: "10px",
            maxWidth: "120px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleClickDetail(origin)}
        >
          {RenderCommaMoney(Number(value).toFixed(0))}
        </div>
      ),
    }
  );

  return tempColumn;
};

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewManagementContainer);

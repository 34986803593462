export default function RoundArrowRight({ width = "48", height = "48" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g
        id="Ellipse_2189"
        data-name="Ellipse 2189"
        transform="translate(48 48) rotate(-180)"
        fill="#0B408D"
        strokeWidth="1"
      >
        <circle cx="24" cy="24" r="24" stroke="none" />
        <circle cx="24" cy="24" r="23.5" fill="none" />
      </g>
      <g
        id="_025-left-arrow-2"
        data-name="025-left-arrow-2"
        transform="translate(24.019 35.213) rotate(-180)"
      >
        <g
          id="Group_2243"
          data-name="Group 2243"
          transform="translate(-6.031 0)"
        >
          <path
            id="Path_5484"
            data-name="Path 5484"
            d="M105.865,11.111l8.31-8.31a1.218,1.218,0,0,0,0-1.718l-.728-.728a1.217,1.217,0,0,0-1.718,0l-9.894,9.894a1.226,1.226,0,0,0,0,1.724l9.885,9.884a1.217,1.217,0,0,0,1.719,0l.728-.728a1.217,1.217,0,0,0,0-1.718Z"
            transform="translate(-101.48 0)"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
}

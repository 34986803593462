export default function GroupPeople1() {
  return (
    <svg id="team-leader" xmlns="http://www.w3.org/2000/svg" width="22.044" height="22.044" viewBox="0 0 22.044 22.044">
      <g id="Team_Leader_2_">
        <g id="Group_14492" data-name="Group 14492" transform="translate(1.335 2.583)">
          <g id="Group_14491" data-name="Group 14491">
            <path id="Path_20557" data-name="Path 20557" d="M33.583,65.167a2.583,2.583,0,1,1,2.583-2.583A2.586,2.586,0,0,1,33.583,65.167Z" transform="translate(-31 -60)" fill="#ffdf34" />
          </g>
        </g>
        <g id="Group_14494" data-name="Group 14494" transform="translate(15.543 2.583)">
          <g id="Group_14493" data-name="Group 14493">
            <path id="Path_20558" data-name="Path 20558" d="M363.583,65.167a2.583,2.583,0,1,1,2.583-2.583A2.586,2.586,0,0,1,363.583,65.167Z" transform="translate(-361 -60)" fill="#ffed89" />
          </g>
        </g>
        <g id="Group_14496" data-name="Group 14496" transform="translate(0 7.75)">
          <g id="Group_14495" data-name="Group 14495">
            <path id="Path_20559" data-name="Path 20559" d="M5.21,194.294H2.626a.645.645,0,0,1-.642-.582L1.4,187.75H.646A.645.645,0,0,1,0,187.1v-3.229a3.9,3.9,0,0,1,7.793,0V187.1a.645.645,0,0,1-.646.646H6.439l-.588,5.963A.645.645,0,0,1,5.21,194.294Z" transform="translate(0 -180)" fill="#ffdf34" />
          </g>
        </g>
        <g id="Group_14497" data-name="Group 14497" transform="translate(14.251 7.75)">
          <path id="Path_20560" data-name="Path 20560" d="M336.167,194.294h-2.583a.645.645,0,0,1-.642-.582l-.588-5.963h-.708A.645.645,0,0,1,331,187.1v-3.229a3.9,3.9,0,0,1,7.793,0V187.1a.645.645,0,0,1-.646.646H337.4l-.588,5.963A.645.645,0,0,1,336.167,194.294Z" transform="translate(-331 -180)" fill="#ffed89" />
        </g>
        <g id="Group_14498" data-name="Group 14498" transform="translate(6.501 6.51)">
          <path id="Path_20561" data-name="Path 20561" d="M156.167,151.2l-.646,2.532-.646-2.532A4.523,4.523,0,0,0,151,155.668v3.875a.64.64,0,0,0,.646.646h1.447l.491,5.95a.647.647,0,0,0,.646.594h2.583a.647.647,0,0,0,.646-.594l.491-5.95H159.4c.362,0,.431-.284.431-.646v-3.875A4.341,4.341,0,0,0,156.167,151.2Z" transform="translate(-151 -151.199)" fill="#ffed89" />
        </g>
        <g id="Group_14499" data-name="Group 14499" transform="translate(7.793)">
          <path id="Path_20562" data-name="Path 20562" d="M184.229,6.458a3.229,3.229,0,1,1,3.229-3.229A3.233,3.233,0,0,1,184.229,6.458Z" transform="translate(-181)" fill="#ffed89" />
        </g>
      </g>
      <path id="Path_20563" data-name="Path 20563" d="M257.292,166.733a.647.647,0,0,0,.646-.594l.491-5.95h1.447a.64.64,0,0,0,.646-.646v-3.875a4.523,4.523,0,0,0-3.875-4.469L256,153.731v13Z" transform="translate(-244.978 -144.689)" fill="#ffdf34" />
      <path id="Path_20564" data-name="Path 20564" d="M259.229,3.229A3.233,3.233,0,0,0,256,0V6.458A3.233,3.233,0,0,0,259.229,3.229Z" transform="translate(-244.978 0)" fill="#ffdf34" />
      <g id="Group_14500" data-name="Group 14500" transform="translate(10.376 6.458)">
        <path id="Path_20565" data-name="Path 20565" d="M242.292,150.052v4.469a.646.646,0,1,1-1.292,0v-4.469a4.067,4.067,0,0,1,1.292,0Z" transform="translate(-241 -150)" fill="#47568c" />
      </g>
      <path id="Path_20566" data-name="Path 20566" d="M256.646,154.521v-4.469A3.538,3.538,0,0,0,256,150v5.167A.64.64,0,0,0,256.646,154.521Z" transform="translate(-244.978 -143.542)" fill="#29376d" />
    </svg>

  );
}

export default function renderIcon({
  color = "#002366",
  width = "20.368",
  height = "26.447",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.368 26.447"
    >
      <g id="back-pain" transform="translate(-58.841 0)">
        <path
          id="Path_57836"
          data-name="Path 57836"
          d="M241.283,81.332l-.84,7.086-.549.811-1.039,1.935-.9.429-2.029-1.207-.009-.005-2.032-1.209-.082-.967c.254-.462.643-1.232,3.006-5.831a2.818,2.818,0,0,1,4.262-1.213Z"
          transform="translate(-165.92 -76.437)"
          fill={color}
        />
        <path
          id="Path_57837"
          data-name="Path 57837"
          d="M280.089,94.761l-.84,7.086-.549.811-1.039,1.936-.9.429-2.029-1.207-.009-.005Z"
          transform="translate(-204.727 -89.866)"
          fill={color}
        />
        <path
          id="Path_57838"
          data-name="Path 57838"
          d="M174.769,283.869v1.3l-2.3,7.114a1.565,1.565,0,0,1-2.922-1.124c.29-.757.617-1.807.928-2.917.447-1.59.862-3.3,1.092-4.44l.441-2.432a3.366,3.366,0,0,0,.1.435c.222.72.746.87,1.448,1.229A5.266,5.266,0,0,1,174.769,283.869Z"
          transform="translate(-104.885 -266.837)"
          fill={color}
        />
        <path
          id="Path_57839"
          data-name="Path 57839"
          d="M224.465,241.055a1.455,1.455,0,0,1-2.9.178l-.813-5.747-.241-1.7c-.52-.414-.929-.719-1.258-1a3.312,3.312,0,0,1-1.076-1.439,3.185,3.185,0,0,1-.13-.413,3.319,3.319,0,0,1,.358-2.456c.182-.322.263-.459.366-.647l2.536,1.485,2.52,1.475S224.465,238.27,224.465,241.055Z"
          transform="translate(-150.894 -216.062)"
          fill={color}
        />
        <path
          id="Path_57840"
          data-name="Path 57840"
          d="M361.454,2.305a2.291,2.291,0,0,1-.318,1.164l-.389.628a2.3,2.3,0,0,1-3.97-2.333l.39-.628a2.3,2.3,0,0,1,4.288,1.168Z"
          transform="translate(-282.245 0)"
          fill={color}
        />
        <path
          id="Path_57841"
          data-name="Path 57841"
          d="M382.319,8.138A2.292,2.292,0,0,1,382,9.3l-.389.628a2.3,2.3,0,0,1-3.151.818l2.722-4.6A2.3,2.3,0,0,1,382.319,8.138Z"
          transform="translate(-303.109 -5.833)"
          fill={color}
        />
        <path
          id="Path_57842"
          data-name="Path 57842"
          d="M107.8,72.129a.776.776,0,0,0-1.326-.158l-.779,1.023-.77-1.784a.775.775,0,1,0-1.423.614l1.295,3a.775.775,0,0,0,1.328.163l.78-1.025.771,1.783a.775.775,0,1,0,1.422-.615Z"
          transform="translate(-42.294 -67.087)"
          fill={color}
        />
        <path
          id="Path_57843"
          data-name="Path 57843"
          d="M65.245,187.338l-3.238-.454a.776.776,0,0,0-.826,1.05l.468,1.2-1.924-.272a.775.775,0,0,0-.217,1.534l3.237.458a.775.775,0,0,0,.83-1.049l-.468-1.2,1.924.27a.775.775,0,1,0,.215-1.535Z"
          transform="translate(0 -177.223)"
          fill={color}
        />
        <path
          id="Path_57844"
          data-name="Path 57844"
          d="M220.931,57.487a.775.775,0,0,0-.775.775v2.172a.775.775,0,1,0,1.55,0V58.262A.775.775,0,0,0,220.931,57.487Z"
          transform="translate(-152.982 -54.517)"
          fill={color}
        />
        <path
          id="Path_57845"
          data-name="Path 57845"
          d="M122.457,274.481l-1.536,1.536a.775.775,0,1,0,1.1,1.1l1.536-1.536a.775.775,0,0,0-1.1-1.1Z"
          transform="translate(-58.658 -260.088)"
          fill={color}
        />
        <path
          id="Path_57846"
          data-name="Path 57846"
          d="M398.245,218.562a1.248,1.248,0,0,1-1.982,1.009l-.714-.54.531-1.508,1.207-.561.617.744A1.246,1.246,0,0,1,398.245,218.562Z"
          transform="translate(-319.315 -205.754)"
          fill={color}
        />
        <path
          id="Path_57847"
          data-name="Path 57847"
          d="M329.505,101.073l-1.738,2.069-1.711-1.295-1.654-1.474a.793.793,0,0,1-.212-1.051l2.695-4.541.012-.021.262.207a3.119,3.119,0,0,1,.753,4.037l-.048.089Z"
          transform="translate(-251.534 -89.866)"
          fill={color}
        />
      </g>
    </svg>
  );
}

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxDelete2Styled } from "./styled";
import { Buttons } from "components";
// import theme from 'styles/theme.json';

const BoxDelete2 = ({
  theme_box_delete,
  handleConfirm,
  handleCancel,
  labelConfirm = 'ยืนยัน',
  labelCancel = 'ยกเลิก',
  buttonWidth,
  maxWidth,
  textShow,
  iconShow,
  fontColor,
  backgroundColor,
  subTextShow,
}) => {
  const customClass = cx({
    theme_box_delete: theme_box_delete,
  });
  return (
    <BoxDelete2Styled
      subTextShow={subTextShow}
      maxWidth={maxWidth}
      buttonWidth={buttonWidth}
    >
      <div className={customClass}>
        <div className="delete_box">
          <div className="title_box">
            <div>{textShow ? textShow : "ยืนยันการลบหรือไม่"}</div>
            {
              iconShow &&
              <div className="icon">
                {iconShow}
              </div>
            }
          </div>
          {
            subTextShow &&
            <div className="sub_text">
              {subTextShow}
            </div>
          }
          <div>

          </div>
          <div className="action_btn">
            <div className="body_btn margin_right">
              <Buttons.BtnNormal
                theme_blue_border_bg_white
                label={labelCancel}
                onClick={handleCancel}
              />
            </div>
            <div className="body_btn">
              <Buttons.BtnNormal
                theme_standard_btn_log_in
                label={labelConfirm}
                onClick={handleConfirm}
                fontColor={fontColor}
                backgroundColor={backgroundColor}
              />
            </div>
          </div>
        </div>
      </div>
    </BoxDelete2Styled>
  );
};

BoxDelete2.propTypes = {};

export default BoxDelete2;

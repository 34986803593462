export default function Exp() {
  return (
    <svg id="Group_33094" data-name="Group 33094" xmlns="http://www.w3.org/2000/svg" width="28.916" height="18.631" viewBox="0 0 28.916 18.631">
      <path id="Path_1260" data-name="Path 1260" d="M6.878,242.729l-5.865-1.574a.517.517,0,0,0-.031.131,4.5,4.5,0,0,0,1.8,2.965h0a.555.555,0,0,0-.031.131c-.022,1.443,1.91,3,1.91,3a.639.639,0,0,0-.031.131,4.035,4.035,0,0,0,1.821,2.973l3.221.864Z" transform="translate(-0.927 -237.818)" fill="#ffe27a" />
      <g id="Group_330" data-name="Group 330" transform="translate(0 3.464)">
        <path id="Path_1261" data-name="Path 1261" d="M66.365,355.608a2.532,2.532,0,0,1-1.821-1.923,2.534,2.534,0,0,0,1.821,2.973l3.221.864.125-.652-.819-.584Z" transform="translate(-60.847 -347.461)" fill="#f9cf58" />
        <path id="Path_1262" data-name="Path 1262" d="M33.11,300.231a2.532,2.532,0,0,1-1.821-1.923,2.534,2.534,0,0,0,1.821,2.973l3.619.971.115-.62-.511-.536Z" transform="translate(-29.47 -295.211)" fill="#f9cf58" />
        <path id="Path_1263" data-name="Path 1263" d="M1.879,245.4a2.532,2.532,0,0,1-1.821-1.923,2.534,2.534,0,0,0,1.821,2.973l4.535,1.217.059-.586-.281-.523Z" transform="translate(-0.002 -243.474)" fill="#f9cf58" />
      </g>
      <path id="Path_1264" data-name="Path 1264" d="M360.053,242.729l5.865-1.574a.514.514,0,0,1,.031.131,4.5,4.5,0,0,1-1.8,2.965h0a.558.558,0,0,1,.031.131c.021,1.443-1.91,3-1.91,3a.629.629,0,0,1,.032.131,4.035,4.035,0,0,1-1.821,2.973l-3.221.864Z" transform="translate(-337.089 -237.818)" fill="#ffe27a" />
      <path id="Path_1265" data-name="Path 1265" d="M358.4,355.608a2.532,2.532,0,0,0,1.821-1.923,2.534,2.534,0,0,1-1.821,2.973l-3.221.864-.125-.652.819-.584Z" transform="translate(-335.001 -343.993)" fill="#f9cf58" />
      <path id="Path_1266" data-name="Path 1266" d="M385.15,300.231a2.532,2.532,0,0,0,1.821-1.923,2.534,2.534,0,0,1-1.821,2.973l-3.619.971-.115-.62.511-.536Z" transform="translate(-359.874 -291.746)" fill="#f9cf58" />
      <path id="Path_1267" data-name="Path 1267" d="M402.034,245.4a2.532,2.532,0,0,0,1.821-1.923,2.534,2.534,0,0,1-1.821,2.973l-4.535,1.217-.059-.586.28-.523Z" transform="translate(-374.995 -240.009)" fill="#f9cf58" />
      <path id="Path_1268" data-name="Path 1268" d="M111.919,182.115l8.624,3.627c-.577,11.071-8.624,14.5-8.624,14.5s-7.939-3.7-8.624-14.5Z" transform="translate(-97.453 -182.115)" fill="#ffcb5b" />
      <path id="Path_1269" data-name="Path 1269" d="M112.023,259.626a.289.289,0,0,1-.208,0c-.895-.344-7.3-3.171-8.432-13.954l-.089.037c.878,12.472,8.624,15,8.624,15s7.746-2.532,8.624-15l-.089-.037C119.328,256.455,112.918,259.282,112.023,259.626Z" transform="translate(-97.461 -242.083)" fill="#f7b737" />
      <path id="Path_1276" data-name="Path 1276" d="M151.86,228.16l6.13,2.578a12.285,12.285,0,0,1-6.24,10.348,13.177,13.177,0,0,1-6.24-10.348l6.131-2.578A.282.282,0,0,1,151.86,228.16Z" transform="translate(-137.293 -225.538)" fill="#ff9838" />
      <path id="Path_1277" data-name="Path 1277" d="M151.643,283.272c-.748-.3-5.155-2.352-6.036-9.807l-.1.04c.635,9.024,6.24,10.856,6.24,10.856s5.6-1.832,6.24-10.856l-.1-.04c-.881,7.454-5.288,9.509-6.036,9.807A.292.292,0,0,1,151.643,283.272Z" transform="translate(-137.293 -268.302)" fill="#ff8709" />
      <path id="Intersection_1" data-name="Intersection 1" d="M0,.733,1.691.022a.282.282,0,0,1,.219,0L3.6.733Z" transform="translate(12.657 2.602)" fill="#e88a33" />
      <g id="Group_13670" data-name="Group 13670" transform="translate(9.456 3.926)">
        <path id="Path_18957" data-name="Path 18957" d="M105.251,126.507a1.167,1.167,0,0,0,.141-.292l.005-.016c.011-.037.02-.075.028-.113l0-.014a1.145,1.145,0,0,0,.02-.191c0-.009,0-.018,0-.028,0-.03,0-.059-.006-.089s0-.043-.006-.064-.01-.052-.015-.078-.012-.05-.019-.075-.015-.047-.023-.07-.022-.055-.035-.082-.019-.041-.03-.061-.036-.061-.056-.09-.02-.032-.032-.048a1.185,1.185,0,0,0-.112-.131l-3.845-3.845a1.135,1.135,0,0,0-.791-.333h-.015a1.137,1.137,0,0,0-.806.334l-3.845,3.845a1.13,1.13,0,0,0-.276.444v0a1.093,1.093,0,0,0-.04.158h0a1.12,1.12,0,0,0-.013.274s0,.009,0,.013c0,.035.008.068.014.1,0,.011,0,.021.006.032s0,.026.007.038v0l0,.007.008.034a1.208,1.208,0,0,0,.046.138h0a1.166,1.166,0,0,0,.159.272l.008.01a1.134,1.134,0,0,0,.1.11l.006.006a1.1,1.1,0,0,0,.264.186l.007,0a1.09,1.09,0,0,0,.138.056l.031.01a1.119,1.119,0,0,0,.131.031l.035.006a1.206,1.206,0,0,0,.17.013h7.691a1.2,1.2,0,0,0,.168-.013l.05-.009a1.115,1.115,0,0,0,.109-.026l.052-.016c.035-.012.069-.026.1-.041l.043-.019a1.079,1.079,0,0,0,.126-.075c.013-.009.024-.019.036-.028s.053-.041.077-.064.032-.032.048-.048.034-.036.05-.055.033-.039.048-.06S105.24,126.524,105.251,126.507Z" transform="translate(-95.479 -120.08)" fill="#5c5c5c" opacity="0.303" />
        <g id="Group_13669" data-name="Group 13669" transform="translate(0 0)">
          <g id="Group_13667" data-name="Group 13667">
            <path id="Path_18946" data-name="Path 18946" d="M99.663,116.219a1.136,1.136,0,0,1,.806-.334v6.124H96.623a1.139,1.139,0,0,1-.806-1.945Z" transform="translate(-95.48 -115.885)" fill="#f8eaea" />
            <path id="Path_18947" data-name="Path 18947" d="M128.8,116.219a1.136,1.136,0,0,0-.806-.334v6.124h3.845a1.139,1.139,0,0,0,.806-1.945Z" transform="translate(-123.012 -115.885)" fill="#fff5f5" />
            <path id="Path_18948" data-name="Path 18948" d="M105.411,146.455H95.52a1.14,1.14,0,0,0,1.1,1.438h7.691A1.14,1.14,0,0,0,105.411,146.455Z" transform="translate(-95.479 -141.769)" fill="#efe2dd" />
            <path id="Path_18949" data-name="Path 18949" d="M95.8,120.592l3.845-3.845a1.14,1.14,0,0,1,1.612,0l3.845,3.845a1.124,1.124,0,0,1,.3.538,1.116,1.116,0,0,0-.3-1.067l-3.845-3.845a1.14,1.14,0,0,0-1.612,0L95.8,120.063a1.116,1.116,0,0,0-.3,1.067A1.126,1.126,0,0,1,95.8,120.592Z" transform="translate(-95.47 -115.885)" fill="#fff" />
          </g>
          <g id="Group_13668" data-name="Group 13668" transform="translate(2.812 6.124)">
            <path id="Path_18950" data-name="Path 18950" d="M113.813,163.258l1.868,1.078a.613.613,0,0,0,.613,0l1.867-1.078v-7.421h-4.348v7.421Z" transform="translate(-113.813 -155.837)" fill="#fff5f5" />
            <path id="Path_18951" data-name="Path 18951" d="M116.294,199.331a.613.613,0,0,1-.613,0l-1.868-1.078v.92l1.868,1.078a.613.613,0,0,0,.613,0l1.867-1.078v-.92Z" transform="translate(-113.813 -191.751)" fill="#efe2dd" />
            <path id="Path_18952" data-name="Path 18952" d="M113.812,162.339l.754.436,1.114.643a.613.613,0,0,0,.306.082v-7.662h-2.174v6.5Z" transform="translate(-113.812 -155.838)" fill="#f8eaea" />
            <rect id="Rectangle_941" data-name="Rectangle 941" width="4.348" height="1.508" transform="translate(0 0)" fill="#f8eaea" />
            <path id="Path_18953" data-name="Path 18953" d="M138.5,155.838v6.828l.564-.326v-6.5Z" transform="translate(-134.716 -155.838)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const TableMostPickedStyled = styled.div`
  .theme_standard_table_most_pick {
    .row_pick {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      .col_left {
        display: flex;
        column-gap: 10px;
        .col_order {
          &.red {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
          }
        }
        .col_detail {
          padding: 0px 5px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          .label {
            &.red {
              color: ${({ theme }) => theme.COLORS.PURPLE_1};
            }
          }
          .sub_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S10};
            color: ${({ theme }) => theme.COLORS.GRAY_1};
          }
        }
      }
      .col_right {
        .col_num {
          text-align: right;
          &.red {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
          }
        }
      }
    }
  }
`;

import styled from 'styled-components'

export const BoxDelete2Styled = styled.div`
  .theme_box_delete {
    .delete_box {
      min-width: 285.4px;
      max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 'unset'};
      border-radius: 16px;
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_21};
      padding: 40px 64px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title_box {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color:  ${({ theme }) => theme.COLORS.PURPLE_1};
        margin-bottom: ${({ subTextShow }) => subTextShow ? '10px' : '30px'};
        align-items: baseline;
        .icon {
          display: flex;
          margin-left: 7px;
        }
      }
      .sub_text {
        margin-bottom: 20px;
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
      .action_btn {
        display: flex;
        .body_btn {
          width: ${({ buttonWidth }) => buttonWidth ? buttonWidth : '136px'};
          &.margin_right {
            margin-right: 16px;
          }
        }
      }
    }
  }
`

export default function Reward() {
  return (
    <svg id="_029-trophy" data-name="029-trophy" xmlns="http://www.w3.org/2000/svg" width="27.755" height="31.892" viewBox="0 0 27.755 31.892">
      <g id="Group_302" data-name="Group 302">
        <path id="Path_1155" data-name="Path 1155" d="M42.7,68.892l-2.688-1.551c-3.462-1.554-4.548-4.93-4.88-7.171a.312.312,0,0,1,.074-.252.32.32,0,0,1,.245-.112H36.8l.7-.561-.273-.808-.645-.561H35.456a2.249,2.249,0,0,0-2.227,2.576A12.4,12.4,0,0,0,35.707,66.4a10.036,10.036,0,0,0,7.067,3.626l.626-.292Z" transform="translate(-33.204 -54.272)" fill="#ffe27a" />
        <g id="Group_300" data-name="Group 300" transform="translate(3.376 3.605)">
          <path id="Path_1156" data-name="Path 1156" d="M87.4,57.877c.032.468.1,1.14.222,1.929h1.257l.4-.964-.676-.964Z" transform="translate(-87.403 -57.877)" fill="#f9cf58" />
          <path id="Path_1157" data-name="Path 1157" d="M145.135,210.526a9.422,9.422,0,0,1-2.567-.719,32.556,32.556,0,0,1,2.757,2.687,15.372,15.372,0,0,0,1.574.08l.189-1.082Z" transform="translate(-139.132 -200.343)" fill="#f9cf58" />
        </g>
        <path id="Path_1158" data-name="Path 1158" d="M315.828,68.892l2.688-1.551c3.462-1.554,4.548-4.93,4.88-7.171a.312.312,0,0,0-.074-.252.319.319,0,0,0-.245-.112h-1.347l-.7-.561.273-.808.645-.561h1.125a2.249,2.249,0,0,1,2.227,2.576,12.4,12.4,0,0,1-2.478,5.949,10.036,10.036,0,0,1-7.067,3.626l-.626-.292Z" transform="translate(-297.573 -54.272)" fill="#ffe27a" />
        <g id="Group_301" data-name="Group 301" transform="translate(16.423 3.605)">
          <path id="Path_1159" data-name="Path 1159" d="M396.269,57.877c-.032.468-.1,1.14-.222,1.929H394.79l-.4-.964.676-.964Z" transform="translate(-388.312 -57.877)" fill="#f9cf58" />
          <path id="Path_1160" data-name="Path 1160" d="M298.818,210.526a9.422,9.422,0,0,0,2.567-.719,32.548,32.548,0,0,0-2.757,2.687,15.373,15.373,0,0,1-1.574.08l-.189-1.082Z" transform="translate(-296.864 -200.343)" fill="#f9cf58" />
        </g>
        <path id="Path_1161" data-name="Path 1161" d="M124.683,46.161l.492.6c-.176,1.933-.8,5.873-3.019,8.583-1.917,2.344-4.186,2.444-4.47,7.689l-.59.6h-2.46l-.59-.6c-.284-5.245-2.552-5.345-4.47-7.689-2.217-2.71-2.842-6.65-3.019-8.583l.492-.6Z" transform="translate(-101.988 -43.286)" fill="#ffba57" />
        <path id="Path_1162" data-name="Path 1162" d="M124.136,27.227c.065-.716.069-1.157.069-1.157l-9.378-.737-9.378.737s0,.441.069,1.157Z" transform="translate(-100.95 -23.755)" fill="#ffac3e" />
        <path id="Path_1163" data-name="Path 1163" d="M226.775,316.986c.02.361.03.745.03,1.157l1.791,1.121,1.79-1.121c0-.412.01-.8.03-1.157Z" transform="translate(-214.718 -297.241)" fill="#ffac3e" />
        <path id="Path_1164" data-name="Path 1164" d="M101.923,1.754H82.172c-.639,0-1.1-1.006-1-1.176a1.157,1.157,0,0,1,1-.578h19.751a1.157,1.157,0,0,1,1,.578C103.023.748,102.562,1.754,101.923,1.754Z" transform="translate(-78.17)" fill="#ffe27a" />
        <path id="Path_1165" data-name="Path 1165" d="M99.787,9.87H80.036a1.156,1.156,0,0,1-1-.579,1.156,1.156,0,0,0,1,1.736H99.787a1.156,1.156,0,0,0,1-1.736A1.156,1.156,0,0,1,99.787,9.87Z" transform="translate(-76.034 -8.712)" fill="#f9cf58" />
        <path id="Path_1166" data-name="Path 1166" d="M153.605,335.568h-8.763a1.958,1.958,0,0,0-1.94,1.7l-.771,5.689.4.6h13.378l.4-.6-.771-5.689A1.958,1.958,0,0,0,153.605,335.568Z" transform="translate(-135.346 -314.666)" fill="#e0e0e0" />
        <path id="Path_1167" data-name="Path 1167" d="M139.77,454.112l-.157,1.157,7.25.833,7.25-.833-.157-1.157Z" transform="translate(-132.985 -425.826)" fill="#cecece" />
        <path id="Path_1168" data-name="Path 1168" d="M133.195,474.58H116.92l-.561-.6v-.005a1.286,1.286,0,0,1,1.286-1.286h14.824a1.286,1.286,0,0,1,1.286,1.286v.005Z" transform="translate(-111.179 -443.249)" fill="#5a5a5a" />
        <path id="Path_1169" data-name="Path 1169" d="M116.68,494.577h16.753a.321.321,0,0,0,.321-.321v-.836h-17.4v.836A.321.321,0,0,0,116.68,494.577Z" transform="translate(-111.179 -462.685)" fill="#4c4c4c" />
      </g>
    </svg>
  );
}

export default function Run({ width = '106', height = '128' }) {
  return (
    <svg
      id="Group_13732"
      data-name="Group 13732"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 106 128"
    >
      <ellipse
        id="Ellipse_1698"
        data-name="Ellipse 1698"
        cx="53"
        cy="9"
        rx="53"
        ry="9"
        transform="translate(0 110)"
        fill="#c2c5c7"
      />
      <g id="runner" transform="translate(6.109 0)">
        <path
          id="Path_12452"
          data-name="Path 12452"
          d="M186.33,98.127s-6.258,8.745-6.443,11.145,11.077,6.277,13.292,6.831,8.006,2.123,9.4,3.323.571,1.335,3.058,0,3.046-1.292,3.046-2.216-4.293-2.631-6.3-2.977S186.33,98.127,186.33,98.127Z"
          transform="translate(-141.191 -68.503)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12453"
          data-name="Path 12453"
          d="M219.978,15.436c-.143,1.074-1.465.392-1.465.392a1.221,1.221,0,0,0-.487,1.317c.143.979-.3.64-.3.64a.533.533,0,0,0-.048.439c.1.2.1.439-.476.682s-.011.979-.011.979c.2,1.566-2.349.931-3.28,1.37s-1.888,3.306-1.888,3.306c4.39,6.316,3.7,9.606,1.936,16.176S210.1,60.609,210.1,60.609a40.1,40.1,0,0,1-9.077.788c-7.575-.392-6.887-4.4-6.887-4.4a13.528,13.528,0,0,0,1.143-5.983c-.127-3.433.127-17.048,1.656-22.143s8.331-8.109,8.331-8.109a13.514,13.514,0,0,0,.587-4.591c-.291-1.613-2.1-5.136-.587-9.632s5.136-2.5,8.167-2.544S216.8,5.9,216.8,5.9c1.848,1.735.984,2.706,1.3,5.063,0,0,.123.785.222,1.184C219.109,15.33,220.126,14.362,219.978,15.436Z"
          transform="translate(-151.166 -2.534)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12454"
          data-name="Path 12454"
          d="M236.5.777a10.23,10.23,0,0,0-10.44,1.58c-4.176,3.612-.9,12.358.564,12.528s3.555-2.37,3.724-4.232.847-1.806,2.991-1.975c1.646-.13,1.3,2.2,1.046,3.29a.448.448,0,1,0,.882.153c.068-.586.191-1.779.263-3.274.1-2.144.856-2.37.4-3.442s-.621-1.524,0-2.2,2.265.329,2.265.329c.177-1.42-.854-2.191-1.7-2.755Z"
          transform="translate(-172.249 0)"
          fill="#4d5c7d"
        />
        <path
          id="Path_12455"
          data-name="Path 12455"
          d="M213.964,81.984c-1.772,6.57-3.867,19.873-3.867,19.873l-9.077.788c-7.575-.392-6.887-4.4-6.887-4.4a13.529,13.529,0,0,0,1.143-5.983c-.127-3.433.127-17.048,1.656-22.143s8.331-8.109,8.331-8.109l6.766,3.8C216.418,72.124,215.731,75.414,213.964,81.984Z"
          transform="translate(-151.257 -42.515)"
          fill="#27a2db"
        />
        <path
          id="Path_12456"
          data-name="Path 12456"
          d="M244,241.638l-1.687-20.247a4,4,0,0,0-.532-1.686L231.2,201.57l-2.468-4-8.225,2.468,2.115,11.515L235.543,224.6s-1.645,6.7,2.115,15.393S241.536,253,241.536,253h3.995l.516-4.567A15.652,15.652,0,0,1,244,241.638Z"
          transform="translate(-168.365 -136.886)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12457"
          data-name="Path 12457"
          d="M285.512,343.244h-6.073c3.588,8.42,3.709,12.607,3.709,12.607h4l.516-4.566a15.644,15.644,0,0,1-2.043-6.8C285.588,344.152,285.552,343.727,285.512,343.244Z"
          transform="translate(-211.403 -239.737)"
          fill="#3ea69b"
        />
        <path
          id="Path_12458"
          data-name="Path 12458"
          d="M114.165,211.926c-5.38,9.281-2.421,19.5-2.421,19.5s-7.8-1.211-12.644-.807-15.2,1.48-15.2,1.48l-.941,4.977,5.246-.134c7.381,1.652,21.07,1.725,25.784,1.7a2.73,2.73,0,0,0,2.405-1.472l9.111-17.43S119.545,202.646,114.165,211.926Z"
          transform="translate(-73.979 -145.886)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12459"
          data-name="Path 12459"
          d="M97.355,282.681c-5.217.471-13.454,1.327-13.454,1.327l-.942,4.977,5.246-.135a76.6,76.6,0,0,0,9.15,1.307v-7.476Z"
          transform="translate(-73.979 -197.64)"
          fill="#44c4a1"
        />
        <path
          id="Path_12460"
          data-name="Path 12460"
          d="M222.772,72.627a5.994,5.994,0,0,0-9.757,3.977c-.007.063-.013.126-.019.19-.6,6.387.526,8.491,4.208,9.017s7.89.751,9.393-1.578C227.836,82.311,228.053,77.063,222.772,72.627Z"
          transform="translate(-164.444 -49.78)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12461"
          data-name="Path 12461"
          d="M240.782,115.394s1.339-.148,1.094-.9c-.13-.4-.784-.414-1.067-.382.317-.129.657-.377.517-.837-.245-.8-1.186-.389-1.608-.369-4.434.655-8.45.228-11.775-1.991l.943-12.787a45.8,45.8,0,0,0-2.194,6.683l-6.06-3.767s-.9,8.578-.542,11.738,6.321,3.521,11.2,4.515a44.134,44.134,0,0,0,8.668.993,3.893,3.893,0,0,0,1.467-.09c.648-.294.66-1.421-.919-1.332,0,0,1.378.169,1.545-.144C242.247,116.348,242.477,115.186,240.782,115.394Z"
          transform="translate(-169.294 -68.519)"
          fill="#f0c8a0"
        />
        <path
          id="Path_12462"
          data-name="Path 12462"
          d="M187.722,182.9s-1.6,4.016-2.56,6.238-.348,7.879-.348,7.879L196.66,202.3h2.252a16.737,16.737,0,0,1,6-5.027c3.568-1.577,1.653-8.14,1.653-8.14l-2.892-3.67S193.59,188.534,187.722,182.9Z"
          transform="translate(-144.613 -127.723)"
          fill="#4d5c7d"
        />
        <path
          id="Path_12463"
          data-name="Path 12463"
          d="M248.5,108.563l.4-5.377.231,5.377Z"
          transform="translate(-189.582 -72.157)"
          fill="#dc8744"
        />
        <path
          id="Path_12464"
          data-name="Path 12464"
          d="M300.223,371.5c-1.62.907-5.209-.262-6.833-1.495a1.022,1.022,0,0,0-1.382.1,8.106,8.106,0,0,1-5.4,2.437,1.033,1.033,0,0,0-.891,1.246l1.013,4.813s.589,1.378,3.79.95c3.638-.486,4.733-2.6,7.572-3.313s7.1-3.869,7.1-5.6S302.194,370.4,300.223,371.5Z"
          transform="translate(-215.19 -258.475)"
          fill="#d5d6db"
        />
        <path
          id="Path_12465"
          data-name="Path 12465"
          d="M307.45,371.04c0,1.735-3.941,5.205-6.781,5.914s-3.946,2.761-7.575,3.311-4.1-1.259-4.1-1.259l-.1-.444c.386.5,1.4,1.227,3.877.851,3.629-.55,4.734-2.6,7.569-3.311,2.751-.687,6.533-3.962,6.776-5.745A.712.712,0,0,1,307.45,371.04Z"
          transform="translate(-217.449 -258.88)"
          fill="#4d5c7d"
        />
        <path
          id="Path_12466"
          data-name="Path 12466"
          d="M56.383,285.654c.5-1.787,3.868-3.5,5.888-3.774a1.022,1.022,0,0,0,.908-1.046,8.109,8.109,0,0,1,2.092-5.539,1.033,1.033,0,0,0-.252-1.511L60.9,271.1s-1.2-.948-3.378,2.008-1.506,5.187-3.012,7.7-2.255,7.753-1.028,8.98S55.77,287.829,56.383,285.654Z"
          transform="translate(-52.734 -189.222)"
          fill="#d5d6db"
        />
        <path
          id="Path_12467"
          data-name="Path 12467"
          d="M53.159,289.776c-1.227-1.227-.894-6.467.613-8.977s.838-4.743,3.015-7.7,3.789-2.008,3.789-2.008l.382.247c-.625-.078-1.855.12-3.344,2.14-2.177,2.955-1.507,5.188-3.011,7.694-1.459,2.431-1.818,7.421-.729,8.854A.713.713,0,0,1,53.159,289.776Z"
          transform="translate(-52.41 -189.218)"
          fill="#4d5c7d"
        />
      </g>
    </svg>
  );
}

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BarItem3Styled } from './styled';
// import { Icons } from 'components';

const BarItem3 = ({
  score,
  chartMinheight,
  barIcon,
  txt_head,
  txt_sub,
  txt_score,
  txtPercent,
  theme_standard,
  theme_blue,
  theme_orange,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_blue: theme_blue,
    theme_orange: theme_orange,
  });

  // const renderDifference = () => {
  //   let renderStatus = score === 0 ? 'begin' : score > 0 ? 'up' : 'down';
  //   switch (renderStatus) {
  //     case 'begin':
  //       return (
  //         <>
  //           <div className="icon">
  //             <Icons.DashCircle />
  //           </div>
  //           <div className="txt_label">
  //             {score}
  //             <span className="txt_percent_label">%</span>
  //           </div>
  //         </>
  //       );
  //     case 'down':
  //       return (
  //         <>
  //           <div className="icon">
  //             <Icons.ArrowDownCircle />
  //           </div>
  //           <div className="txt_label blue">
  //             {-1 * score}
  //             <span className="txt_percent_label">%</span>
  //           </div>
  //         </>
  //       );
  //     case 'up':
  //       return (
  //         <>
  //           <div className="icon">
  //             <Icons.ArrowUpCircle />
  //           </div>
  //           <div className="txt_label red">
  //             {score}
  //             <span className="txt_percent_label">%</span>
  //           </div>
  //         </>
  //       );
  //     default:
  //       break;
  //   }
  // };

  return (
    <BarItem3Styled
      // score={score < 0 ? score * -1 : score}
      score={txtPercent}
      // score={txt_score}
      chartMinheight={chartMinheight}
    >
      <div className={customClass}>
        <div className="bar_container">
          {/* <div className="icon_label_percent_box">
            {score || score === 0 ? renderDifference() : '-'}
          </div> */}
          <div className='show_score'>
            {txt_score || '-'}
          </div>
          <div className="bar_percent_box">
            <div className="bar_item">
              <div className="bar_value"></div>
              <div className="bar_icon">{barIcon}</div>
            </div>
          </div>
          <div className="description_box">
            <div className="txt_head">{txt_head}</div>
            <div className="txt_sub">{txt_sub}</div>
            {/* <div className="txt_score">{txt_score || '-'}</div> */}
          </div>
        </div>
      </div>
    </BarItem3Styled>
  );
};

BarItem3.propTypes = {};

export default BarItem3;

import { WeeklyAchieverStyled } from "./styled";
import { Blocks, Icons, Typographys } from "components";
import millify from "millify";
import React from "react";

const WeeklyAchiever = ({ data }) => {
  const renderPercent = () => {
    // let numberActive = Math.floor(
    //   ((data?.previous_weekly_achievement_percent /
    //     data?.previous_weekly_achievement_percent) *
    //     100) /
    //     10
    // );

    let numberActive = Math.floor(
      data?.previous_weekly_achievement_percent / 10
    );

    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) =>
      i < numberActive ? (
        <Icons.PersonByStatus key={i} active color="#8AC47F" />
      ) : (
        <Icons.PersonByStatus key={i} color="#8AC47F" />
      )
    );
  };

  return (
    <WeeklyAchieverStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="16px"
        paddingRight="16px"
        paddingBottom="16px"
        paddingLeft="16px"
        height="408px"
      >
        <div className="weekly_container">
          <div className="weekly_block">
            <div className="left_col">
              <Typographys.TopBottom
                theme_left_align
                label={
                  <div>
                    <div>ผู้ทำสำเร็จตามเป้าหมายต่อเนื่อง</div>
                    <div>(2 สัปดาห์ที่ผ่านมา)</div>
                  </div>
                }
                labelBottom={
                  <div>
                    <div>Consecutive Goal Achiever</div>
                    <div>(last 2 weeks)</div>
                  </div>
                }
                fontSizeLabelButtom="14px"
              />
            </div>
            <div className="right_col">
              <div className="txt_percent">
                {`${
                  data && data.previous_weekly_achievement_percent
                    ? data.previous_weekly_achievement_percent
                    : 0
                } %`}
              </div>
            </div>
          </div>
          <div className="icon_rating_block">{data && renderPercent()}</div>
          <div className="descrip_scrore_block">
            <Typographys.LabelDescripScore
              txtTitlePrefix="สำเร็จ"
              txtSubTitlePrefix="Success"
              // txtMid={data && millify(Number(data.user_count))}
              txtMid={data && data.previous_weekly_achievement_count}
              txtTitleAffix="คน"
              txtSubTitleAffix="Users"
              centerText
            />
          </div>
        </div>
        <div className="recent_goal_container">
          <div className="title_wrap">
            <Typographys.TopBottom
              theme_left_align
              label="เปลี่ยนแปลงเป้าหมายล่าสุด"
              labelBottom="Recent Goal Adjustment"
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="score_wrap mb_10">
            <Typographys.LabelDescripScore
              txtTitlePrefix="เพิ่มขึ้น"
              txtSubTitlePrefix="Increase"
              txtMid={
                data && data.target_adjustment
                  ? millify(Number(data.target_adjustment.increase.count))
                  : "-"
              }
              txtPercent={
                data && data.target_adjustment
                  ? `${
                      data.target_adjustment.increase.percent === null
                        ? 0
                        : data.target_adjustment.increase.percent
                    }%`
                  : "-"
              }
              txtTitleAffix="คน"
              txtSubTitleAffix="Users"
              status="down"
              isShowIconStatus
            />
          </div>
          <div className="score_wrap mb_10">
            <Typographys.LabelDescripScore
              txtTitlePrefix="ลดลง"
              txtSubTitlePrefix="Decrease"
              txtMid={
                data && data.target_adjustment
                  ? millify(Number(data.target_adjustment.decrease.count))
                  : "-"
              }
              txtPercent={
                data && data.target_adjustment
                  ? `${
                      data.target_adjustment.decrease.percent === null
                        ? 0
                        : data.target_adjustment.decrease.percent
                    }%`
                  : "-"
              }
              txtTitleAffix="คน"
              txtSubTitleAffix="Users"
              status="up"
              isShowIconStatus
            />
          </div>
          <div className="score_wrap">
            <Typographys.LabelDescripScore
              txtTitlePrefix="คงที่"
              txtSubTitlePrefix="Remain"
              txtMid={
                data && data.target_adjustment
                  ? millify(Number(data.target_adjustment.remain.count))
                  : "-"
              }
              txtPercent={
                data && data.target_adjustment
                  ? `${
                      data.target_adjustment.remain.percent === null
                        ? 0
                        : data.target_adjustment.remain.percent
                    }%`
                  : "-"
              }
              txtTitleAffix="คน"
              txtSubTitleAffix="Users"
              status="stable"
              isShowIconStatus
            />
          </div>
        </div>
      </Blocks.Box>
    </WeeklyAchieverStyled>
  );
};

export default WeeklyAchiever;

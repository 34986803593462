import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BarItemStyled } from './styled';

const BarItem = ({
  theme_standard_bar,
  theme_red,
  theme_blue,
  iconSvg,
  percent,
  minHeight,
  show_percent,
  minWidth,
}) => {
  const customClass = cx({
    theme_standard_bar: theme_standard_bar,
    theme_red: theme_red,
    theme_blue: theme_blue,
  });
  return (
    <BarItemStyled percent={percent} minHeight={minHeight} minWidth={minWidth}>
      <div className={customClass}>
        <div className="bar_container">
          <div className="bar_inner">
            <div className="bar_icon">{iconSvg}</div>
            {
              percent && Number(percent) > 0 &&
              <div className='group_item_bar'>
                <div className="item left" />
                <div className="item right" />
                {
                  show_percent &&
                  <div className='percent_show'>
                    {`${percent}%`}
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </BarItemStyled>
  );
};

BarItem.propTypes = {};

export default BarItem;

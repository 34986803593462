export default function renderIcon({ color = '#4E5B7E' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="123" height="123" viewBox="0 0 123 123">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_26335" data-name="Rectangle 26335" width="123" height="123" transform="translate(0 0.031)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_57593" data-name="Group 57593" transform="translate(0 -0.031)">
        <rect id="Rectangle_26327" data-name="Rectangle 26327" width="34" height="34" transform="translate(0 89.031)" fill="#a3daff" />
        <g id="Group_57592" data-name="Group 57592">
          <g id="Group_57591" data-name="Group 57591" clip-path="url(#clip-path)">
            <path id="Path_97815" data-name="Path 97815" d="M0,264.727H25.455a12.727,12.727,0,0,1-12.727,12.727H0v8.485H33.939V252H0Z" transform="translate(0 -162.909)" fill="#1ec0ff" />
            <rect id="Rectangle_26328" data-name="Rectangle 26328" width="34" height="34" transform="translate(89 89.031)" fill="#a3daff" />
            <path id="Path_97816" data-name="Path 97816" d="M285.939,264.727H260.485a12.727,12.727,0,0,0,12.727,12.727h12.727v8.485H252V252h33.939Z" transform="translate(-162.909 -162.909)" fill="#1ec0ff" />
            <rect id="Rectangle_26329" data-name="Rectangle 26329" width="55" height="51" transform="translate(34 72.031)" fill="#ff8b7b" />
            <path id="Path_97817" data-name="Path 97817" d="M154.97,253.455V228h-8.485L138,238.606l3.052,4.629,5.433-3.328v13.548h-6.364v8.485h21.212v-8.485Z" transform="translate(-89.212 -147.394)" fill="#ede574" />
            <rect id="Rectangle_26330" data-name="Rectangle 26330" width="34" height="9" transform="translate(89 89.031)" fill="#f9d423" />
            <rect id="Rectangle_26331" data-name="Rectangle 26331" width="34" height="9" transform="translate(0 89.031)" fill="#f9d423" />
            <rect id="Rectangle_26332" data-name="Rectangle 26332" width="55" height="8" transform="translate(34 64.031)" fill="#f9d423" />
            <rect id="Rectangle_26333" data-name="Rectangle 26333" width="17" height="28" transform="translate(53 36.031)" fill="#a3daff" />
            <path id="Path_97818" data-name="Path 97818" d="M158.485,104.121a4.242,4.242,0,0,0-4.242,4.242v3.225a12.909,12.909,0,0,0,.17,2.036,4.242,4.242,0,0,1,4.073-3.139v5.371a9.343,9.343,0,0,0,8.386,9.427h.1v4.294H150V102h16.97v2.121Z" transform="translate(-96.97 -65.939)" fill="#1ec0ff" />
            <path id="Path_97819" data-name="Path 97819" d="M166.97,8.485A8.485,8.485,0,1,1,158.485,0a8.485,8.485,0,0,1,8.485,8.485" transform="translate(-96.97)" fill="#f9d423" />
            <path id="Path_97820" data-name="Path 97820" d="M155.657,18.212a4.243,4.243,0,0,0-6,0l-6.621,6.621a10.605,10.605,0,0,1-15,0l-6.621-6.622a4.243,4.243,0,1,0-6,6l8.315,8.315a10.608,10.608,0,0,1,3.107,7.5v7h16.97V40.454a10.6,10.6,0,0,1,3.107-7.5l8.742-8.742a4.243,4.243,0,0,0,0-6" transform="translate(-73.808 -10.97)" fill="#f9d423" />
            <path id="Path_97821" data-name="Path 97821" d="M166.206,13.36a8.474,8.474,0,1,1-11.2-11.2,8.334,8.334,0,0,0-.764,3.479,8.485,8.485,0,0,0,8.485,8.485,8.334,8.334,0,0,0,3.479-.764" transform="translate(-96.969 -1.396)" fill="#f9b53d" />
            <path id="Path_97822" data-name="Path 97822" d="M115.416,20.484a4.24,4.24,0,0,0,0,6l8.317,8.315a10.6,10.6,0,0,1,3.106,7.5v7h16.97V42.726a10.6,10.6,0,0,1,3.107-7.5l8.742-8.742a4.243,4.243,0,0,0,0-6l-7.952,8.3a16.972,16.972,0,0,1-24.626-.128Z" transform="translate(-73.808 -13.242)" fill="#f9b53d" />
            <path id="Path_97823" data-name="Path 97823" d="M172.061,126A1.061,1.061,0,0,0,171,127.061v18.03h2.121v-18.03A1.061,1.061,0,0,0,172.061,126" transform="translate(-110.545 -81.454)" fill="#299cfc" />
            <path id="Path_97824" data-name="Path 97824" d="M151.152,184.242v4.242H96V180h6.745a6.3,6.3,0,0,0,5.983,4.242Z" transform="translate(-62.061 -116.364)" fill="#f9b53d" />
            <path id="Path_97825" data-name="Path 97825" d="M151.152,242.182v12.727H96V204h12.727v12.727a25.454,25.454,0,0,0,25.455,25.455Z" transform="translate(-62.061 -131.879)" fill="#ff6464" />
            <path id="Path_97826" data-name="Path 97826" d="M165.212,303.273v4.242H144V299.03h6.364v-.53a25.265,25.265,0,0,0,14.849,4.773" transform="translate(-93.091 -192.97)" fill="#f9d423" />
            <rect id="Rectangle_26334" data-name="Rectangle 26334" width="42" height="4" transform="translate(47 72.031)" fill="#ff6464" />
            <path id="Path_97827" data-name="Path 97827" d="M285.939,256.242v4.242H252V252h4.242a4.254,4.254,0,0,0,4.242,4.242Z" transform="translate(-162.909 -162.909)" fill="#f9b53d" />
            <path id="Path_97828" data-name="Path 97828" d="M0,256.242v4.242H33.939V252H29.7a4.254,4.254,0,0,1-4.242,4.242Z" transform="translate(0 -162.909)" fill="#f9b53d" />
            <path id="Path_97829" data-name="Path 97829" d="M46.242,20.485v2.121a2.121,2.121,0,0,0,4.242,0V20.485h2.121a2.121,2.121,0,1,0,0-4.242H50.485V14.121a2.121,2.121,0,0,0-4.242,0v2.121H44.121a2.121,2.121,0,1,0,0,4.242Z" transform="translate(-27.151 -7.758)" fill="#ede574" />
            <path id="Path_97830" data-name="Path 97830" d="M16.606,100.242H14.485V98.121a2.121,2.121,0,1,0-4.242,0v2.121H8.121a2.121,2.121,0,0,0,0,4.242h2.121v2.121a2.121,2.121,0,1,0,4.242,0v-2.121h2.121a2.121,2.121,0,0,0,0-4.242" transform="translate(-3.879 -62.061)" fill="#ede574" />
            <path id="Path_97831" data-name="Path 97831" d="M52.242,74.121A2.121,2.121,0,1,1,50.121,72a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-31.03 -46.545)" fill="#ede574" />
            <path id="Path_97832" data-name="Path 97832" d="M10.242,68.121A2.121,2.121,0,1,1,8.121,66a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-3.879 -42.667)" fill="#ede574" />
            <path id="Path_97833" data-name="Path 97833" d="M88.242,56.121A2.121,2.121,0,1,1,86.121,54a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-54.303 -34.909)" fill="#ede574" />
            <path id="Path_97834" data-name="Path 97834" d="M309.8,16.242H307.68V14.121a2.121,2.121,0,1,0-4.242,0v2.121h-2.121a2.121,2.121,0,1,0,0,4.242h2.121v2.121a2.121,2.121,0,1,0,4.242,0V20.485H309.8a2.121,2.121,0,0,0,0-4.242" transform="translate(-193.419 -7.758)" fill="#ede574" />
            <path id="Path_97835" data-name="Path 97835" d="M263.121,59.485h2.121v2.121a2.121,2.121,0,0,0,4.242,0V59.485h2.121a2.121,2.121,0,1,0,0-4.242h-2.121V53.121a2.121,2.121,0,0,0-4.242,0v2.121h-2.121a2.121,2.121,0,0,0,0,4.242" transform="translate(-168.727 -32.97)" fill="#ede574" />
            <path id="Path_97836" data-name="Path 97836" d="M316.242,74.121A2.121,2.121,0,1,1,314.121,72a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-201.697 -46.545)" fill="#ede574" />
            <path id="Path_97837" data-name="Path 97837" d="M334.242,56.121A2.121,2.121,0,1,1,332.121,54a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-213.333 -34.909)" fill="#ede574" />
            <path id="Path_97838" data-name="Path 97838" d="M250.242,44.121A2.121,2.121,0,1,1,248.121,42a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-159.03 -27.151)" fill="#ede574" />
            <path id="Path_97839" data-name="Path 97839" d="M274.242,14.121A2.121,2.121,0,1,1,272.121,12a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-174.545 -7.758)" fill="#ede574" />
            <path id="Path_97840" data-name="Path 97840" d="M298.242,104.121A2.121,2.121,0,1,1,296.121,102a2.122,2.122,0,0,1,2.121,2.121" transform="translate(-190.06 -65.939)" fill="#ede574" />
            <path id="Path_97841" data-name="Path 97841" d="M225.155,78.108a2.122,2.122,0,0,0-2.683,1.343l-6.364,19.091a2.124,2.124,0,0,0,4.031,1.34L226.5,80.791a2.122,2.122,0,0,0-1.348-2.683" transform="translate(-139.636 -50.424)" fill="#ede574" />
            <path id="Path_97842" data-name="Path 97842" d="M246.2,105.522a2.121,2.121,0,0,0-3.315-2.646L234.4,113.482a2.121,2.121,0,1,0,3.315,2.646Z" transform="translate(-151.211 -66.017)" fill="#ede574" />
            <path id="Path_97843" data-name="Path 97843" d="M256.083,132.149l-14.849,6.364a2.122,2.122,0,1,0,1.671,3.9l14.849-6.364a2.122,2.122,0,1,0-1.671-3.9" transform="translate(-155.1 -85.311)" fill="#ede574" />
            <path id="Path_97844" data-name="Path 97844" d="M106.124,79.446a2.124,2.124,0,0,0-4.031,1.34l6.364,19.091a2.124,2.124,0,0,0,4.031-1.34Z" transform="translate(-65.929 -50.419)" fill="#ede574" />
            <path id="Path_97845" data-name="Path 97845" d="M81.717,102.721a2.121,2.121,0,1,0-3.315,2.646l8.485,10.606a2.121,2.121,0,0,0,3.315-2.646Z" transform="translate(-50.362 -65.862)" fill="#ede574" />
            <path id="Path_97846" data-name="Path 97846" d="M71.854,138.556l-14.849-6.364a2.122,2.122,0,0,0-1.671,3.9l14.849,6.364a2.122,2.122,0,1,0,1.671-3.9" transform="translate(-34.958 -85.354)" fill="#ede574" />
          </g>
        </g>
      </g>
    </svg>
  )
}

import React, { useEffect } from 'react';
import { CreateDepartmentFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Label, Icons, Buttons } from 'components';

const CreateDepartmentForm = ({
  onSubmit,
  initialValues,
  isCannotEditDelete,
  // handleDelete,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required('Department is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();
  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CreateDepartmentFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="id_form">
          <div className="group_input">
            <Label.TopBottom
              theme_left_align_2
              label="แผนก"
              labelBottom="department"
              fontSize="24x"
              fontSizeLabelButtom="18px"
              fontFamilyTop="noto_sans_thai_regular, noto_sans_regular"
              fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
            />
            <div className="input_sub">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputText
                    theme_input_create_account
                    iconSvg={<Icons.User />}
                    // placeholder="ชื่อ"
                    {...field}
                    minlength={10}
                    maxLength={40}
                    disabled={isCannotEditDelete}
                    errors={errors.name?.message}
                  />
                )}
                name="name"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        {
          !isCannotEditDelete &&
          <div className="bottom_form">
            {initialValues ? (
              <>
                <div className="btn margin_right">
                  <Buttons.BtnNormal
                    theme_standard_btn_normal_red
                    label="บันทึกการแก้ไขแผนก"
                    fontSize="18px"
                    fontFamily="noto_sans_bold, noto_sans_thai_bold"
                    padding="10px"
                    borderRadius='4px'
                    backgroundColor="#575D96"
                    disabled={
                      !(
                        watchAllFields.name
                      )
                    }
                    type="submit"
                  />
                </div>
                {/* <div className="btn">
                <Buttons.BtnRoundShadow
                  theme_btn_round_shadow_orange_2
                  label="ลบแผนก"
                  svg={<Icons.UserDelete />}
                  fontSize="18px"
                  fontFamily="noto_sans_bold, noto_sans_thai_bold"
                  imgWidth="29px"
                  imgHeight="29px"
                  padding="10px 24px"
                  onClick={handleDelete}
                />
              </div> */}
              </>
            ) : (
              <div className="btn">
                <Buttons.BtnNormal
                  theme_standard_btn_normal_red
                  label="สร้างแผนก"
                  fontSize="18px"
                  fontFamily="noto_sans_bold, noto_sans_thai_bold"
                  padding="10px"
                  borderRadius='4px'
                  backgroundColor="#21A526"
                  svg_front={<Icons.Plus color="#FFFFFF" />}
                  disabled={
                    !(
                      watchAllFields.name
                    )
                  }
                  type="submit"
                />
              </div>
            )}
          </div>
        }
      </form>
    </CreateDepartmentFormStyled>
  );
};

CreateDepartmentForm.propTypes = {};

export default CreateDepartmentForm;

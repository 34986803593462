// import React from "react";
// import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { CurrentBMIStatContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards, BGLoading } from "components";
import moment from "moment";
import { adminService } from "apiServices";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";
import {
  BodyMassAgeGender,
  BMITrend,
  MainDashBoardBMIType2,
  BMIByDepartment,
  ExportRaw,
} from "widgets";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CurrentBMIStatContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGenderBMI, setDataGenderBMI] = useState();
  const [datAgeGender, setDatAgeGender] = useState();
  const [dataDeparment, setDataDeparment] = useState();
  const [dataGenderTrend, setDataGenderTrend] = useState();
  const [isBgLoading, setIsBgLoading] = useState(false);

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchGenderBMIStatistics(),
      fetchBMIStatisticsAgeGender(
        moment().startOf("months").format("YYYY-MM-DD")
      ),
      fetchBMIStatisticsDepartment(
        moment().startOf("months").format("YYYY-MM-DD")
      ),
      fetchBMITrend(`?period=weekly&offset=0&per_page=8`),
    ]);
    if (res) {
      setIsLoading(false);
      setDataGenderBMI(res[0]);
      setDatAgeGender(res[1]);
      setDataDeparment(res[2]);
      setDataGenderTrend(res[3]);
    } else {
      setIsLoading(false);
    }
  };

  const fetchGenderBMIStatistics = async () => {
    let res = await adminService.GET_GENDER_BMI_STATISTICS(
      companySelectedRedux?.id
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchBMIStatisticsAgeGender = async (query) => {
    let res = await adminService.GET_AGE_GENDER_BMI_STATISTICS(
      companySelectedRedux?.id,
      query
    );
    if (res && res.success) {
      let obese = [];
      let over = [];
      let normal = [];
      let under = [];

      if (res.data.age_groups !== null) {
        res.data.age_groups.reverse().forEach((e, i) => {
          obese.push(Number(e.male.obese.count));
          obese.push(Number(e.female.obese.count));
          over.push(Number(e.male.over.count));
          over.push(Number(e.female.over.count));
          normal.push(Number(e.male.normal.count));
          normal.push(Number(e.female.normal.count));
          under.push(Number(e.male.under.count));
          under.push(Number(e.female.under.count));
        });
        const insertEmpty = (data) => {
          return data.reduce((arr, b, i) => {
            return i % 2 === 1 && data.length - 1 !== i
              ? [...arr, b, ""]
              : // : i === 0
                //   ? ['20', ...arr, b]
                [...arr, b];
          }, []);
        };
        return {
          obese: insertEmpty(obese), /// increse blank space
          over: insertEmpty(over),
          normal: insertEmpty(normal),
          under: insertEmpty(under),
        };
      } else {
        return {
          obese: [],
          over: [],
          normal: [],
          under: [],
        };
      }
    }
  };

  const fetchBMIStatisticsDepartment = async (query) => {
    if (companySelectedRedux && !companySelectedRedux.isParent) {
      let res = await adminService.GET_DEPARTMENT_BMI_STATISTICS(
        companySelectedRedux?.id,
        query
      );
      if (res && res.success) {
        return sliceIntoChunks(
          res.data.departments.map((e) => ({
            id: e.id,
            name: e.name,
            data: [
              { ...e.normal, name: "normal" },
              {
                ...e.over,
                percent: e.over.percent + e.obese.percent,
                name: "over",
              },
              { ...e.under, name: "under" },
            ],
          })),
          8
        );
      }
    } else {
      return false;
    }
  };

  const fetchBMITrend = async (query) => {
    let res = await adminService.GET_DEPARTMENT_BMI_TREND(
      companySelectedRedux?.id,
      query
    );
    // if (res && res.success) {
    //   return res.data.length > 0 ? res.data.reverse() : [];
    // }
    if (res && res.success) {
      let tempData = [];
      if (res.data.length > 0) {
        res.data.map((e) =>
          tempData.push({
            stat_date: e.stat_date,
            male_over: {
              count: e.male_obese.count + e.male_over.count,
              percent: e.male_obese.percent + e.male_over.percent,
            },
            female_over: {
              count: e.female_obese.count + e.female_over.count,
              percent: e.female_obese.percent + e.female_over.percent,
            },
            male_normal: e.male_normal,
            female_normal: e.female_normal,
            male_under: e.male_under,
            female_under: e.female_under,
          })
        );
        tempData.reverse();
      }
      return tempData;
      // return tempData > 0 ? tempData.reverse() : [];
    }
  };

  const handleDownload = async (e) => {
    setIsBgLoading(true);
    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      companySelectedRedux.id,
      "user_bmi",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      toast.success("ดาวน์โหลดสำเร็จ");
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsBgLoading(false);
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      setIsBgLoading(false);
    }
  };

  return (
    <CurrentBMIStatContainerStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/logo_bmi.svg"
              titleLabel="Body Mass Index"
              label1="BMI (Body Mass Index) คือ ค่าความหนาของร่างกาย ที่ใช้เป็นมาตรฐานในการประเมินภาวะอ้วนหรือผอมในผู้ใหญ่ตั้งแต่"
              label2="อายุ 20 ปีขึ้นไป ซึ่งคำนวณได้จาก การใช้น้ำหนักตัวเป็นกิโลกรัมและหารด้วยส่วนสูงที่วัดเป็นเมตรยกกำลังสอง ซึ่งใช้ได้ทั้ง"
              label3="ผู้หญิงและผู้ชาย"
            />
          </div>
          <div className="section_graph">
            <BodyMassAgeGender
              data={datAgeGender && datAgeGender}
              fetchData={fetchBMIStatisticsAgeGender}
            />
          </div>
          <div className="block_2_col_layout">
            <div className="col_2 flex_1 mw_700">
              <BMITrend data={dataGenderTrend} fetchData={fetchBMITrend} />
            </div>
            <div className="col_2 w_340">
              <div className="wrap_mr5_overflow">
                <MainDashBoardBMIType2 data={dataGenderBMI && dataGenderBMI} />
              </div>
            </div>
          </div>
          {companySelectedRedux && !companySelectedRedux.isParent && (
            <div className="bmiDep">
              <BMIByDepartment
                data={dataDeparment && dataDeparment}
                fetchData={fetchBMIStatisticsDepartment}
              />
            </div>
          )}
          <div className="export">
            <ExportRaw
              img="bmi"
              onDownload={handleDownload}
              // minDate={moment().tz('Asia/Bangkok').subtract(11, 'months').format('MM-01-YYYY')}
              // maxDate={moment().tz('Asia/Bangkok').endOf('month').format('MM-DD-YYYY')}
              minDate={moment()
                .tz("Asia/Bangkok")
                .subtract(10, "years")
                .format("01-01-YYYY")}
              rangeDate={11}
            />
          </div>
        </>
      )}
    </CurrentBMIStatContainerStyled>
  );
};
export default CurrentBMIStatContainer;

import Box from './Box';
import Container from './Container';
import BoxLabelLeftRight from './BoxLabelLeftRight';
import BoxLabelCenter from './BoxLabelCenter';
import BoxWellbeing from './BoxWellbeing';
import BoxMaxHeight from './BoxMaxHeight';
import BoxDelete from './BoxDelete';

const Export = {
  Box,
  BoxMaxHeight,
  Container,
  BoxLabelLeftRight,
  BoxLabelCenter,
  BoxWellbeing,
  BoxDelete,
};

export default Export;

import { NumberOfDoResearchStyled } from "./styled";
import { Blocks, Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const NumberOfDoResearch = ({ data }) => {
  return (
    <NumberOfDoResearchStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="12px"
        paddingBottom="12px"
        paddingLeft="16px"
        // height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelCenter
            theme_standard_box_4_col
            label1="จำนวนพนักงานที่ทำแบบทดสอบ"
            label2="The Number of employees to do the research"
            iconShow={<Icons.Build width="32" height="40" />}
            label3Fontsize="56px"
            label3FontsColor="#2A74A3"
            labelBackFontsize="26px"
            label3={
              data && data.wellbeing_user_count > 0
                ? `${RenderCommaMoney(data.wellbeing_user_count, true)}`
                : "0"
            }
            labelBack="คน"
          />
        </div>
      </Blocks.Box>
    </NumberOfDoResearchStyled>
  );
};

export default NumberOfDoResearch;

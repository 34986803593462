import styled from 'styled-components';

export const DeleteUserAccountManagementContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layer_one {
    display: flex;
  }
  .layer_two {
    margin-top: 41px;
    display: flex;
    justify-content: space-between;
    .search_layer {
      width: 50%;
    }
    .btn_layer {
      width: 30%;
      display: flex;
      justify-content: space-between;
      .body_btn {
        width: 45%;
      }
    }
  }
  .layer_table {
    margin-top: 24px;
    .gray {
      color:  ${({ theme }) => theme.COLORS.GRAY_1};
      text-align: center;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .layer_two {
      
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .layer_two {
      
    }
  }
`;

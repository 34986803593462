import styled from 'styled-components';

export const BoxImageStyled = styled.div`
  .show_img {
    height: ${({ heightBox }) => (heightBox ? heightBox : '231px')};
    border-radius: 12px;
    width: 100%;
    box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
    -webkit-box-shadow: 1px 3px 10px -4px rgb(0 0 0 / 45%);
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .close {
      position: absolute;
      right: 7px;
      top: 8px;
      cursor: pointer;
      z-index: 2;
    }
    .block_more {
      border-radius: 12px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background: #ffffff80;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      .num_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        font-family: noto_sans_thai_bold, noto_sans_bold;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        position: relative;
      }
    }
  }

  .theme_standard {
  }
`;

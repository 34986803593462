export default function Cooking3({ width = '50.417', height = '48.542' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50.417 48.542">
      <g id="Group_13709" data-name="Group 13709" transform="translate(-42.194 -262.194)">
        <g id="Group_13692" data-name="Group 13692" transform="translate(42.194 268.395)">
          <path id="Path_18968" data-name="Path 18968" d="M31.925,119.35l-2.036,2.036L8.033,99.532A1.44,1.44,0,0,1,10.07,97.5Z" transform="translate(-7.612 -97.074)" fill="#a56941" />
          <path id="Path_18969" data-name="Path 18969" d="M8.567,97.842a1.437,1.437,0,0,0-.533,2.372L29.889,122.07l.869-.869L8.9,99.345a1.438,1.438,0,0,1-.336-1.5Z" transform="translate(-7.612 -97.756)" fill="#965028" />
        </g>
        <g id="Group_13695" data-name="Group 13695" transform="translate(73.275 280.114)">
          <g id="XMLID_9_" transform="translate(1.989 0)">
            <g id="XMLID_10_">
              <g id="XMLID_11_">
                <path id="Path_18970" data-name="Path 18970" d="M306.065,209.75a1.218,1.218,0,0,0,.461-.012,1.009,1.009,0,0,1,1,.417q.043.06.088.119c.979,1.27,2.574,1.8,3.563,1.19a1.489,1.489,0,0,0,.316-.261.812.812,0,0,1,.684-.26,2.093,2.093,0,0,0,1.979-3.58.842.842,0,0,1-.154-.746,1.5,1.5,0,0,0,.053-.422c-.019-1.163-1.329-2.217-2.927-2.354l-.148-.01a1.01,1.01,0,0,1-.892-.617,1.22,1.22,0,0,0-.239-.395,1.874,1.874,0,0,0-2.965.774.788.788,0,0,1-.781.527,2.829,2.829,0,0,0-1.408.384,3,3,0,0,0,.471,1.381.769.769,0,0,1-.032.927,1.754,1.754,0,0,0-.389,1A1.777,1.777,0,0,0,306.065,209.75Z" transform="translate(-304.696 -202.348)" fill="#97d729" />
              </g>
            </g>
          </g>
          <g id="Group_13693" data-name="Group 13693" transform="translate(1.989)">
            <path id="Path_18971" data-name="Path 18971" d="M305.42,205.375a.787.787,0,0,0,.781-.527,1.874,1.874,0,0,1,2.965-.774,1.219,1.219,0,0,1,.239.395,1.009,1.009,0,0,0,.892.617c.049,0,.1.006.148.01,1.6.137,2.908,1.191,2.927,2.354a1.5,1.5,0,0,1-.053.422.842.842,0,0,0,.154.746,2.787,2.787,0,0,1,.664,1.872,1.728,1.728,0,0,0,.483-.552,2.381,2.381,0,0,0-.46-2.576.842.842,0,0,1-.154-.746,1.5,1.5,0,0,0,.053-.422c-.019-1.163-1.329-2.217-2.927-2.354l-.148-.01a1.01,1.01,0,0,1-.892-.617,1.22,1.22,0,0,0-.239-.395,1.874,1.874,0,0,0-2.965.774.788.788,0,0,1-.781.527,2.83,2.83,0,0,0-1.408.384,2.935,2.935,0,0,0,.227.918A2.458,2.458,0,0,1,305.42,205.375Z" transform="translate(-304.698 -202.347)" fill="#8bc727" />
          </g>
          <g id="Group_13694" data-name="Group 13694" transform="translate(0 0.837)">
            <path id="Path_18972" data-name="Path 18972" d="M286.85,210.519a.51.51,0,0,1,.734-.592l6.629,3.62a.51.51,0,1,1-.489.9l-6.629-3.62A.509.509,0,0,1,286.85,210.519Z" transform="translate(-286.83 -209.864)" fill="#78a91f" />
          </g>
        </g>
        <g id="Group_13701" data-name="Group 13701" transform="translate(46.941 287.009)">
          <g id="Group_13696" data-name="Group 13696" transform="translate(0 6.342)">
            <path id="Path_18973" data-name="Path 18973" d="M53.871,328.492a3.615,3.615,0,1,1,3.615-3.615A3.619,3.619,0,0,1,53.871,328.492Zm0-5.225a1.611,1.611,0,1,0,1.611,1.611A1.613,1.613,0,0,0,53.871,323.267Z" transform="translate(-50.256 -321.263)" fill="#e33442" />
          </g>
          <g id="Group_13697" data-name="Group 13697" transform="translate(38.441 6.342)">
            <path id="Path_18974" data-name="Path 18974" d="M399.2,328.492a3.615,3.615,0,1,1,3.615-3.615A3.619,3.619,0,0,1,399.2,328.492Zm0-5.225a1.611,1.611,0,1,0,1.611,1.611A1.613,1.613,0,0,0,399.2,323.267Z" transform="translate(-395.582 -321.263)" fill="#fd4755" />
          </g>
          <g id="Group_13698" data-name="Group 13698" transform="translate(3.455 1.412)">
            <path id="Path_18975" data-name="Path 18975" d="M114.587,299.29H86.6a5.3,5.3,0,0,1-5.3-5.3v-17.01h38.6v17.01A5.3,5.3,0,0,1,114.587,299.29Z" transform="translate(-81.291 -276.976)" fill="#fd4755" />
          </g>
          <g id="Group_13699" data-name="Group 13699" transform="translate(3.455 1.412)">
            <path id="Path_18976" data-name="Path 18976" d="M85.188,293.986V281.095h34.7v-4.119h-38.6v17.01a5.3,5.3,0,0,0,5.3,5.3h3.9A5.3,5.3,0,0,1,85.188,293.986Z" transform="translate(-81.291 -276.976)" fill="#e33442" />
          </g>
          <g id="Group_13700" data-name="Group 13700" transform="translate(2.394)">
            <path id="Path_18977" data-name="Path 18977" d="M110.007,264.289H74.243a2.479,2.479,0,0,0-2.479,2.479v1.193h40.721v-1.193A2.479,2.479,0,0,0,110.007,264.289Z" transform="translate(-71.764 -264.289)" fill="#fffad7" />
          </g>
          <path id="Path_18978" data-name="Path 18978" d="M82.814,264.289H74.243a2.479,2.479,0,0,0-2.479,2.479v1.193h8.571v-1.193A2.479,2.479,0,0,1,82.814,264.289Z" transform="translate(-69.37 -264.289)" fill="#ffebb4" />
        </g>
        <g id="Group_13703" data-name="Group 13703" transform="translate(71.411 264.977)">
          <path id="Path_18979" data-name="Path 18979" d="M293.794,75.1c-1.3,1.54-3.428.128-5.823-1.89s-4.148-3.877-2.851-5.417,5.156-2.179,7.551-.161S295.092,73.564,293.794,75.1Z" transform="translate(-283.055 -66.365)" fill="#fad2b4" />
          <path id="Path_18980" data-name="Path 18980" d="M286.352,68.834c1.185-1.406,4.5-2.059,6.893-.628a5.019,5.019,0,0,0-.572-.569c-2.4-2.018-6.253-1.379-7.551.161s.456,3.4,2.851,5.417q.313.264.62.513C286.525,71.912,285.167,70.241,286.352,68.834Z" transform="translate(-283.056 -66.366)" fill="#faa073" />
          <path id="Path_18981" data-name="Path 18981" d="M278.7,99.606a4.614,4.614,0,0,0-6.7-5.646,8.6,8.6,0,0,0,1.818,2.185,20.848,20.848,0,0,0-3.187,2.676,2.3,2.3,0,1,0,3.513,2.96,20.866,20.866,0,0,0,2.1-3.595A8.6,8.6,0,0,0,278.7,99.606Z" transform="translate(-270.084 -90.343)" fill="#fabe9b" />
          <g id="Group_13702" data-name="Group 13702" transform="translate(0 3.003)">
            <path id="Path_18982" data-name="Path 18982" d="M271.65,99.683a20.871,20.871,0,0,1,3.187-2.676,8.6,8.6,0,0,1-1.818-2.185,5.037,5.037,0,0,1,4.841.013,4.015,4.015,0,0,0-.476-.476,4.942,4.942,0,0,0-5.391-.4,8.6,8.6,0,0,0,1.818,2.185,20.853,20.853,0,0,0-3.187,2.676,2.3,2.3,0,0,0,.854,3.592A2.3,2.3,0,0,1,271.65,99.683Z" transform="translate(-270.084 -93.345)" fill="#faa073" />
            <path id="Path_18983" data-name="Path 18983" d="M308.268,127.425l-1.4-1.177-.74.536Z" transform="translate(-302.118 -122.585)" fill="#faa073" />
          </g>
        </g>
        <g id="Group_13705" data-name="Group 13705" transform="translate(62.663 262.194)">
          <path id="Path_18984" data-name="Path 18984" d="M196.143,50.274a.634.634,0,0,0,.554-.626,3.884,3.884,0,0,1,1.626-3.16l2.11-1.507a.594.594,0,0,0-.69-.967.244.244,0,0,1-.342-.339l0,0a.611.611,0,0,0-.085-.812.594.594,0,0,0-.873.11.23.23,0,0,1-.409-.2l.065-.225a.611.611,0,0,0-.344-.74.594.594,0,0,0-.79.388.138.138,0,0,1-.27-.038v-.173a.611.611,0,0,0-.536-.617.594.594,0,0,0-.652.591v.216a.149.149,0,0,1-.292.042.611.611,0,0,0-.688-.441.594.594,0,0,0-.459.751l.071.241a.231.231,0,0,1-.41.2.594.594,0,0,0-.874-.106.611.611,0,0,0-.081.813l.015.021a.24.24,0,0,1-.335.335.613.613,0,0,0-.806.07.594.594,0,0,0,.1.883l2.126,1.508a3.883,3.883,0,0,1,1.637,3.168v.023a.594.594,0,0,0,.634.593Z" transform="translate(-191.495 -41.365)" fill="#97d729" />
          <g id="Group_13704" data-name="Group 13704">
            <path id="Path_18985" data-name="Path 18985" d="M196.75,49a3.882,3.882,0,0,0-1.49-2.114l-2.126-1.508a.594.594,0,0,1-.1-.883.613.613,0,0,1,.806-.07.24.24,0,0,0,.335-.335l-.015-.021a.611.611,0,0,1,.081-.813.594.594,0,0,1,.874.106.231.231,0,0,0,.41-.2l-.071-.241a.594.594,0,0,1,.459-.751.611.611,0,0,1,.688.441.149.149,0,0,0,.292-.042v-.216a.6.6,0,0,1,.009-.083.138.138,0,0,1-.211-.116v-.173a.611.611,0,0,0-.536-.617.594.594,0,0,0-.652.591v.216a.149.149,0,0,1-.292.042.611.611,0,0,0-.688-.441.594.594,0,0,0-.459.751l.071.241a.231.231,0,0,1-.41.2.594.594,0,0,0-.874-.106.611.611,0,0,0-.081.813l.015.021a.24.24,0,0,1-.335.335.613.613,0,0,0-.806.07.594.594,0,0,0,.1.883l2.126,1.508a3.883,3.883,0,0,1,1.637,3.168v.023a.594.594,0,0,0,.634.593.634.634,0,0,0,.554-.626A3.884,3.884,0,0,1,196.75,49Z" transform="translate(-191.494 -41.364)" fill="#8bc727" />
          </g>
        </g>
        <g id="Group_13708" data-name="Group 13708" transform="translate(56.173 271.104)">
          <path id="Path_18986" data-name="Path 18986" d="M145.822,127.724l-6.315-5.513a3.364,3.364,0,0,0-3.3-.616l-.018.006a4.189,4.189,0,0,0-2.817,2.558c-.834,2.5,1.316,5.475,4.8,6.635s6.987.071,7.821-2.433A.6.6,0,0,0,145.822,127.724Z" transform="translate(-133.194 -121.408)" fill="#fd4755" />
          <g id="Group_13706" data-name="Group 13706" transform="translate(2.158 1.511)">
            <path id="Path_18987" data-name="Path 18987" d="M158.1,139.05a5.238,5.238,0,0,1-3.648-3.533.668.668,0,0,0-1.137-.327,2.652,2.652,0,0,0-.6.994c-.621,1.865.98,4.078,3.576,4.942a5.292,5.292,0,0,0,4.978-.586.668.668,0,0,0-.5-1.175A6.4,6.4,0,0,1,158.1,139.05Z" transform="translate(-152.58 -134.983)" fill="#d13824" />
          </g>
          <g id="Group_13707" data-name="Group 13707" transform="translate(2.813 3.026)">
            <path id="Path_18988" data-name="Path 18988" d="M158.819,148.593a1.8,1.8,0,0,1,.541.151.1.1,0,0,1,.008.19,1.8,1.8,0,0,1-.526.2c-.2.009-.375-.1-.381-.253S158.615,148.6,158.819,148.593Z" transform="translate(-158.46 -148.593)" fill="#f4c833" />
            <path id="Path_18989" data-name="Path 18989" d="M166.209,160.281a2.168,2.168,0,0,1,.64-.216.125.125,0,0,1,.136.183,2.168,2.168,0,0,1-.391.551.34.34,0,1,1-.386-.519Z" transform="translate(-165.157 -158.786)" fill="#f4c833" />
            <path id="Path_18990" data-name="Path 18990" d="M177.187,168.806a2.174,2.174,0,0,1,.551-.391.125.125,0,0,1,.184.136,2.172,2.172,0,0,1-.215.641.34.34,0,1,1-.519-.385Z" transform="translate(-175.003 -166.194)" fill="#f4c833" />
            <path id="Path_18991" data-name="Path 18991" d="M189.291,175.243a2.173,2.173,0,0,1,.414-.534.125.125,0,0,1,.215.077,2.169,2.169,0,0,1-.02.675c-.083.231-.287.37-.455.31A.4.4,0,0,1,189.291,175.243Z" transform="translate(-185.828 -171.768)" fill="#f4c833" />
            <path id="Path_18992" data-name="Path 18992" d="M204.2,179.16a1.771,1.771,0,0,1,.231-.5.1.1,0,0,1,.186.022,1.773,1.773,0,0,1,.108.541c-.023.2-.16.347-.3.33S204.177,179.359,204.2,179.16Z" transform="translate(-199.106 -175.267)" fill="#f4c833" />
            <path id="Path_18993" data-name="Path 18993" d="M218.008,180.691a1.212,1.212,0,0,1,.086-.368.07.07,0,0,1,.127-.011,1.216,1.216,0,0,1,.147.348c.012.137-.059.255-.159.263S218.019,180.827,218.008,180.691Z" transform="translate(-211.379 -176.749)" fill="#f4c833" />
          </g>
        </g>
      </g>
    </svg>
  );
}

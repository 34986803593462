import styled from 'styled-components';

export const InputCalendarStyled = styled.div`
  .theme_standard_calendar {
    position: relative;
    .icon_filter {
      cursor: pointer;
    }
    .filter_block22 {
      position: absolute;
      width: ${({ boxSize }) => (boxSize ? boxSize : '210px')};
      z-index: 2;
      top: ${({ topBox }) => (topBox ? topBox : '30px')};
      right: ${({ rightBox }) => (rightBox ? rightBox : '-80px')};
      .btn_show {
        margin-top: 20px;
        padding: 0px 20%;
      }
    }
  }
`;

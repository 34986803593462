import styled from 'styled-components';

export const AnnouncementContainerStyled = styled.div`
  .show_loading {
    height: 334px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  min-height: calc(100vh - 200px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};

  .box_spin_layout {
    .sum_zone {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      text-align: right;
      margin-bottom: 24px;
    }
    .tab_select {
      margin-bottom: 37px;
      border-radius: 8px;
      display: flex;
      background: ${({ theme }) => theme.COLORS.GRAY_22};
      .body_tab {
        width: 50%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
          border-radius: 8px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          background: ${({ theme }) => theme.COLORS.BLUE_21};
        }
      }
    }
    .top_filter {
      display: flex;
      column-gap: 14px;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: #A9A9A9;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      .w25 {
        width: 25%;
      }

    }
    .lbl_count_name {
      margin-top: 16px;
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    }
    .table_box {
      margin-top: 32px;
    }
  }

  .group_icon {
    display: flex;
    column-gap: 12px;
    justify-content: center;
    .box_icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_31};
      border-radius: 4px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .box_exchange {
    padding: 75px 64px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 16px;
    display: flex;
    width: 290px;
    row-gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    .w100 {
      width: 100%;
    }
  }
`;

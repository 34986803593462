import styled from 'styled-components';

export const TableRank3Styled = styled.div`
  .theme_standard_table_rank3 {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    .label_empty {
      padding: 40px 0;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    }
    .group_row {
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .row {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 16px;
        background: ${({ theme }) => theme.COLORS.GRAY_32}99;
        padding: 7.7px 0px;
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
        // padding-bottom: 10px;
        column-gap: 10px;
        &:last-child {
          border-bottom: none;
        }
        .round {
          width: 100px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        .col2 {
          // width: 50%;
          // margin: 0px 5px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 1;
        }
        .col3 {
          // width: 50%;
          // margin: 0px 5px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 1;
        }
        .col4 {
          width: 120px;
          flex-shrink: 0;
          // margin: 0px 5px;
          text-align: center;
          justify-content: flex-end;
        }
        &:last-child {
          margin-bottom: 0px;
          .right_row {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components'

export const LabelGraph2Styled = styled.div`
  .theme_standard_label_graph {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: noto_sans_thai_medium, noto_sans_regular;

    .row_label {
      display: flex;
      // justify-content: space-between;
      padding: 9px;
      border: none;
      .circle {
        // width: 8%;
        .bg_color {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          // margin-right: 12px;
        }
      }
      .label_layer {
        // width: 92%;
        margin-top: -4px;
        // justify-content: space-between;
        // display: flex;
        .group_label {
          margin-left: 12px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: noto_sans_thai_medium, noto_sans_regular;
          color:  ${({ theme }) => theme.COLORS.BLACK_1};
          font-weight: 400;
          .sub_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: noto_sans_thai_regular, noto_sans_regular;
            color:  ${({ theme }) => theme.COLORS.GRAY_4};
            font-weight: 300;
          }
        }
      }
    }
  }


  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .theme_standard_label_graph {
      display: flex;
      flex-wrap: wrap;
      .row_label {
        width: 40%;
      }
    }
  }
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .theme_standard_label_graph {
      
    }
  }


  // @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
  //   .theme_standard_label_graph {
  //     .row_label {
  //       .circle {
  //         width: 13%;
  //       }
  //       .label_layer {
  //         width: 87%;
  //       }
  //     }
  //   }
  // }
  // @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
  //   .theme_standard_label_graph {
  //     .row_label {
  //       .circle {
  //         width: 18%;
  //       }
  //       .label_layer {
  //         width: 82;
  //       }
  //     }
  //   }
  // }
`

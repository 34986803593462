import styled from 'styled-components';

export const ManageMissionStyled = styled.div`
  .manage_top {
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
    .show_label {
      font-family: noto_sans_thai_bold, noto_sans_bold;
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      color: ${({ theme }) => theme.COLORS.BLACK_6};
    }
    .btn {
      width: 128px;
      flex-shrink: 0;
    }
  }
  .manage_mid {
    display: flex;
    // flex-wrap: wrap;
    padding: 10px 2px;
    column-gap: 16px;
    row-gap: 16px;
    overflow: auto;
    min-height: 277px;
    &.mb_47 {
      margin-bottom: 37px;
    }
    .col_2 {
      &.flex_1 {
        flex: 1;
        min-width: 712px;
        padding-right: 2px;
      }
      &.m_lr_c {
        margin-left: auto;
        margin-right: auto;
      }
      &.w_312 {
        flex-shrink: 0;
        min-width: 312px;
      }
      &.w_384 {
        max-width: 384px;
      }
      &.mb_14 {
        margin-bottom: 10px;
      }
      &.min_w_300 {
        min-width: 300px;
      }
      &.min_w_568 {
        min-width: 568px;
      }
      .img_show {
        height: 100%;
        min-height: 176px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
`;

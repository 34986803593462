import { ListStyled } from "./styled";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";

const List = ({ dataPage, department_detail, fetchData, is_deleted }) => {
  return (
    <ListStyled>
      {dataPage && dataPage.data.length > 0 ? (
        <div>
          <InfiniteScroll
            className="show_data"
            dataLength={dataPage.data.length} //This is important field to render the next data
            next={() =>
              fetchData({
                departmen_id: department_detail.id,
                is_deleted: is_deleted,
              })
            }
            height={319}
            hasMore={dataPage.hasMore}
            loader={<h3 className="loading">{``}</h3>}
            // endMessage={<Displays.LoadEnd />}
          >
            <div className="head_table">
              <div className="employee_id"> รหัสพนักงาน</div>
              <div>ชื่อ - นามสกุล</div>
            </div>
            {dataPage.data.map((e, i) => (
              <div className="body_table" key={i}>
                <div className="employee_id"> {e.code}</div>
                <div>
                  <div className="box_user_show_det">
                    {e.profile_picture_uri ? (
                      <div
                        className="img_av"
                        // style={{ backgroundImage: `url('assets/images/logo/avatar.png')` }}
                        style={{
                          backgroundImage: `url(${e.profile_picture_uri}) `,
                        }}
                      />
                    ) : (
                      <div className="img_blank">
                        <img
                          className="avatar_show"
                          src="/assets/images/logo/avatar.png"
                          alt="img"
                        />
                      </div>
                    )}
                    <div
                      className="name"
                      data-tip
                      data-for={`tooltipCode${i + 1}`}
                    >
                      <ReactTooltip id={`tooltipCode${i + 1}`} delayShow="800">
                        <span>{`${e.firstname} ${e.lastname} `}</span>
                      </ReactTooltip>
                      {`${e.firstname} ${e.lastname} `}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="empty">ไม่มีข้อมูล</div>
      )}
    </ListStyled>
  );
};

export default List;

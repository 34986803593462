import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BoxIconTitleSubStyled } from './styled';
import { Typographys } from 'components';

const BoxIconTitleSub = ({ theme_box_icon_standard, svg, title, subTitle }) => {
  const customClass = cx({
    theme_box_icon_standard: theme_box_icon_standard,
  });
  return (
    <BoxIconTitleSubStyled>
      <div className={customClass}>
        <div className="block_container">
          <div className="block_icon">
            <div className="icon_wrap">{svg}</div>
          </div>
          <div className="block_label">
            <Typographys.LabelSubVertical
              theme_standard
              title={title}
              subTitle={subTitle}
            />
          </div>
        </div>
      </div>
    </BoxIconTitleSubStyled>
  );
};

BoxIconTitleSub.propTypes = {};

export default BoxIconTitleSub;

import styled from "styled-components";

export const ChangePasswordFormStyled = styled.form`
  .forgot_form_container {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 54px 54px;
    width: 432px;
    // height: ${({ height }) => (height ? height : "627px")};
    overflow: auto;
    position: relative;
    .svg_c {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .logo_icon {
      margin-bottom: 32px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sub_text {
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      text-align: center;
      &.mb {
        margin-bottom: 32px;
      }
    }
    .box_input {
      &.mt_mb {
        margin: 32px 0px;
      }
      .group_input {
      }
    }
    .obj_chek_ch {
      margin-top: 9px;
      display: flex;
      column-gap: 30px;
      .sw150_ch {
        // width: 150px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: ${({ theme }) => theme.COLORS.GRAY_25};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        &.flex_start {
          align-items: start;
          .dot_t_ch {
            padding-top: 7px;
          }
        }
        &.green {
          color: ${({ theme }) => theme.COLORS.GREEN_6};
        }
        &.no_width {
          width: unset;
        }
        .dot_t_ch {
          dispaly: flex;
        }
      }
    }
  }
  .mt32 {
    margin-top: 32px;
  }
  .error_show {
    margin-top: 8px;
    color: ${({ theme }) => theme.COLORS.RED_3};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
  }
  .btn_layout {
    margin-top: 32px;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .form_container {
      padding: 40px 30px 20px 30px;
    }
  }
`;

import { MemberActionStyled } from "./styled";
import { adminService } from "apiServices";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { renderConfigSystemFN } from "utils/functions/renderCofigSystem";
import { Inputs, Icons } from "components";
import { ROUTE_PATH } from "utils/constants";
import { setReduxCompanySelected } from "store/actions";
import { useComponentVisible } from "utils/functions/detectClickOutside";

const MemberAction = ({ routes }) => {
  let history = useHistory();
  let location = useLocation();
  const { authenRedux, companyListRedux, companySelectedRedux } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const [active, setActive] = useState("main");
  const [isShowCompFilter, setIsShowCompFilter] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    if (location?.pathname) {
      const splitPathName = location.pathname.split("/");
      if (splitPathName[splitPathName.length - 1] === "administrator") {
        let findData = routes.find(
          (e) =>
            e.key === "administrator" ||
            e.key === "main" ||
            e.key === "wellbeing" ||
            e.key === "bmi" ||
            e.key === "msd" ||
            e.key === "step"
        );

        if (findData) {
          setActive("main");
        } else {
          setActive(routes[0].key);
          history.push(routes[0].path);
        }
      } else {
        setActive(splitPathName[splitPathName.length - 1]);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenRedux.role) {
      setIsShowCompFilter(
        renderConfigSystemFN(authenRedux, location?.pathname)
          ?.isShowFilterRightNavbar
      );
    }
  }, [authenRedux, active]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeCompany = (e) => {
    dispatch(
      setReduxCompanySelected({
        id: e.value,
        name: e.label,
        isParent: e.isParent,
        realm: e.realm,
      })
    );
    handleCheckRedirectMainMenu(e);
  };

  const handleCheckRedirectMainMenu = (value) => {
    const split = location?.pathname.split("/");
    if (
      split[2] === "backoffice" &&
      split[3] !== "accountmanagement" &&
      value &&
      value.isParent
    ) {
      history.push(ROUTE_PATH.BACK_OFFICE);
    }
  };

  const handleClickLogout = async () => {
    let res = await adminService.POST_LOGOUT();

    if (res && res.success) {
      toast.success("Logout success");
      localStorage.clear();
      history.replace("/");
    } else {
      toast.error("Logout fail");
    }
  };

  const handleClickMainBox = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleClickClose = () => {
    setIsComponentVisible(false);
  };

  const handleClickProfile = () => {
    setIsComponentVisible(false);
    history.push(`${ROUTE_PATH.BACK_OFFICE_ACCOUNT_PROFILE}/${authenRedux.id}`);
  };

  // const renderIcon = () => {
  //   switch (authenRedux.role) {
  //     case "company_admin":
  //       return (
  //         <>
  //           {authenRedux && authenRedux.company.image_uri && (
  //             // <img
  //             //   className="icon_show"
  //             //   src={authenRedux.company.image_uri}
  //             //   alt="img"
  //             // />
  //             <></>
  //           )}
  //           <div className="name_company">{authenRedux?.company.name}</div>
  //         </>
  //       );

  //     case "company_agent":
  //       return (
  //         <>
  //           {authenRedux && authenRedux.company.image_uri && (
  //             // <img
  //             //   className="icon_show"
  //             //   src={authenRedux.company.image_uri}
  //             //   alt="img"
  //             // />
  //             <></>
  //           )}
  //           <div className="name_company">{authenRedux?.company.name}</div>
  //         </>
  //       );

  //     case "invitrace_admin":
  //       return (
  //         <>
  //           {/* <img
  //             className="icon_show"
  //             src="/assets/images/logo/invitrace_icon.svg"
  //             alt="img"
  //           /> */}
  //           <div className="name_company">บริษัท อินไวเทรส จำกัด</div>
  //         </>
  //       );

  //     case "ddc_admin":
  //       return (
  //         <>
  //           {/* <img
  //             className="icon_show_2"
  //             src="/assets/images/logo/ddc_icon.svg"
  //             alt="img"
  //           /> */}
  //           <div className="name_company green">กรมควบคุมโรค</div>
  //         </>
  //       );

  //     case "ddc_agent":
  //       return (
  //         <>
  //           {/* <img
  //             className="icon_show_2"
  //             src="/assets/images/logo/ddc_icon.svg"
  //             alt="img"
  //           /> */}
  //           <div className="name_company green">กรมควบคุมโรค</div>
  //         </>
  //       );

  //     default:
  //       break;
  //   }
  // };

  return (
    <MemberActionStyled>
      <div className="layout_login" ref={ref}>
        {isShowCompFilter && companyListRedux && (
          <div className="group_right">
            <div className="input_wrap">
              <Inputs.InputDropDownFilterNest
                theme_reward
                // label="แสดงข้อมูลของ:"
                iconSvgClose={<Icons.RectArrowDown color="#002366" />}
                iconSvgOpen={<Icons.RectArrowUp color="#002366" />}
                placeholder={`กรุณาเลือก`}
                defaultValue={
                  !companySelectedRedux
                    ? companyListRedux &&
                      companyListRedux.length > 0 && {
                        label: companyListRedux[0].name,
                        value: companyListRedux[0].id,
                        realm: companyListRedux[0].realm,
                      }
                    : {
                        label: companySelectedRedux.name,
                        value: companySelectedRedux.id,
                        realm: companySelectedRedux.realm,
                      }
                }
                onChange={handleChangeCompany}
                options={(handleSelectedOption) =>
                  companyListRedux &&
                  companyListRedux.map((e, i) => (
                    <div key={i} className="layout_1">
                      {authenRedux?.admin_role?.name !== "Management" &&
                        authenRedux?.admin_role?.name !== "Article Admin" && (
                          <div
                            className="title h1"
                            onClick={() =>
                              handleSelectedOption({
                                label: e.name,
                                value: e.id,
                                isParent: e.is_parent,
                                realm: e.realm,
                              })
                            }
                          >
                            {e.name}
                          </div>
                        )}
                      {e.associates &&
                        e.associates.map((f, j) => (
                          <div className="layout_2">
                            <div
                              key={j}
                              className="title h2"
                              onClick={() =>
                                handleSelectedOption({
                                  label: f.name,
                                  value: f.id,
                                  isParent: f.is_parent,
                                  realm: f.realm,
                                })
                              }
                            >
                              {f.name}
                            </div>
                            {f.associates && (
                              <div className="layout3">
                                {f.associates.map((g, k) => (
                                  <div
                                    key={k}
                                    className="title h3"
                                    onClick={() =>
                                      handleSelectedOption({
                                        label: g.name,
                                        value: g.id,
                                        isParent: g.is_parent,
                                      })
                                    }
                                  >
                                    {g.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ))
                }
              />
            </div>
          </div>
        )}
        {/* <div className="show_company">{renderIcon()}</div>  */}
        <div className="left_login">
          <div className="company_name_show">
            {authenRedux && authenRedux.company && authenRedux.company.name}
          </div>
          <div className="group_mem" onClick={handleClickMainBox}>
            <div className="show_svg_left">
              <Icons.Profile />
            </div>
            <div className="group_name">
              <div className="top_group">
                {authenRedux?.firstname} {authenRedux?.lastname}
              </div>
              <div className="show_role">
                {authenRedux &&
                  authenRedux.admin_role &&
                  authenRedux.admin_role.name}
              </div>
            </div>
            <div className="show_svg_right">
              {!isComponentVisible ? (
                <Icons.RectArrowDown color="#002366" />
              ) : (
                <Icons.RectArrowUp color="#002366" />
              )}
            </div>
          </div>
          {isComponentVisible && (
            <div className="expand_box">
              <div className="group_item_menu rela">
                <div className="svg_item ab" onClick={handleClickClose}>
                  <Icons.CloseX2 />
                </div>
                <div className="svg_item">
                  <Icons.Profile />
                </div>
                <div className="name_item">
                  <div className="name_show">
                    {authenRedux?.firstname} {authenRedux?.lastname}
                  </div>
                  <div className="role_item">
                    {authenRedux &&
                      authenRedux.admin_role &&
                      authenRedux.admin_role.name}
                  </div>
                </div>
              </div>
              <div
                className="group_item_menu show_hover"
                onClick={handleClickProfile}
              >
                <div className="svg_item">
                  <Icons.Setting3 color="#002366" />
                </div>
                <div className="name_item gray">ตั้งค่าบัญชี</div>
              </div>
              <div
                className="group_item_menu show_hover"
                onClick={handleClickLogout}
              >
                <div className="svg_item">
                  <Icons.Logout2 />
                </div>
                <div className="name_item gray">ออกจากระบบ</div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="right_login" onClick={handleClickLogout}>
          <div className="show_svg_right">
            <Icons.Logout />
          </div>
          <div>Log out</div>
        </div> */}
      </div>
    </MemberActionStyled>
  );
};

export default MemberAction;

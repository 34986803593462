import styled from 'styled-components'

export const LabelGraph3Styled = styled.div`
  .theme_standard_label_graph {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: noto_sans_thai_medium, noto_sans_regular;
    display: flex;
    // justify-content: space-between; 
    .group_left_show {
      // width: 48%;
      .row_label {
        display: flex;
        justify-content: space-between;
        padding: 9px;
        cursor: pointer;
        border: none;
        &.active {
          border: 1px solid;
          border-radius: 10px;
        }
        .circle {
          padding-top: 4px;
          width: 8%;
          .bg_color {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 12px;
          }
        }
        .label_layer {
          width: 92%;
          justify-content: space-between;
          display: flex;
          .group_label {
            margin-left: 7px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color:  ${({ theme }) => theme.COLORS.BLACK_1};
            .sub_label {
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_regular, noto_sans_regular;
              color:  ${({ theme }) => theme.COLORS.GRAY_4};
            }
          }
          .percent {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: noto_sans_thai_medium, noto_sans_regular;
            color:  ${({ theme }) => theme.COLORS.BLACK_1};
          }
        }
      }
    }
    // .group_right_show {
    //   width: 48%;
    //   .row_label_right {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 9px;
    //     cursor: pointer;
    //     border: none;
    //     &.active {
    //       border: 1px solid;
    //       border-radius: 10px;
    //     }
    //     .circle {
    //       width: 8%;
    //       .bg_color {
    //         width: 16px;
    //         height: 16px;
    //         border-radius: 4px;
    //         margin-right: 12px;
    //       }
    //     }
    //     .label_layer {
    //       width: 92%;
    //       justify-content: space-between;
    //       display: flex;
    //       .group_label {
    //         margin-left: 7px;
    //         font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    //         font-family: noto_sans_thai_medium, noto_sans_regular;
    //         color:  ${({ theme }) => theme.COLORS.BLACK_1};
    //         .sub_label {
    //           font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    //           font-family: noto_sans_thai_regular, noto_sans_regular;
    //           color:  ${({ theme }) => theme.COLORS.GRAY_4};
    //         }
    //       }
    //       .percent {
    //         font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    //         font-family: noto_sans_thai_medium, noto_sans_regular;
    //         color:  ${({ theme }) => theme.COLORS.BLACK_1};
    //       }
    //     }
    //   }
    // }




    // .row_label {
    //   display: flex;
    //   justify-content: space-between;
    //   padding: 9px;
    //   cursor: pointer;
    //   border: none;
    //   &.active {
    //     border: 1px solid;
    //     border-radius: 10px;
    //   }
    //   .circle {
    //     width: 8%;
    //     .bg_color {
    //       width: 16px;
    //       height: 16px;
    //       border-radius: 4px;
    //       margin-right: 12px;
    //     }
    //   }
    //   .label_layer {
    //     width: 92%;
    //     justify-content: space-between;
    //     display: flex;
    //     .group_label {
    //       margin-left: 7px;
    //       font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    //       font-family: noto_sans_thai_medium, noto_sans_regular;
    //       color:  ${({ theme }) => theme.COLORS.BLACK_1};
    //       .sub_label {
    //         font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    //         font-family: noto_sans_thai_regular, noto_sans_regular;
    //         color:  ${({ theme }) => theme.COLORS.GRAY_4};
    //       }
    //     }
    //     .percent {
    //       font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    //       font-family: noto_sans_thai_medium, noto_sans_regular;
    //       color:  ${({ theme }) => theme.COLORS.BLACK_1};
    //     }
    //   }
    // }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard_label_graph {
      .row_label {
        .circle {
          width: 13%;
        }
        .label_layer {
          width: 87%;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_standard_label_graph {
      .row_label {
        .circle {
          width: 18%;
        }
        .label_layer {
          width: 82;
        }
      }
    }
  }
`

export default function ActivenessWalk() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54.006"
      height="62.944"
      viewBox="0 0 54.006 62.944"
    >
      <g
        id="Group_14856"
        data-name="Group 14856"
        transform="translate(-593.471 -218.056)"
      >
        <path
          id="Path_35680"
          data-name="Path 35680"
          d="M30.053,262c14.913,0,27,.882,27,1.97s-12.09,1.968-27,1.968-27-.888-27-1.965S15.137,262,30.053,262Z"
          transform="translate(590.421 15.063)"
          fill="#bec5ed"
          fillRule="evenodd"
        />
        <g
          id="Layer_2"
          data-name="Layer 2"
          transform="translate(596.093 218.056)"
        >
          <g id="Слой_1" data-name="Слой 1" transform="translate(0 0)">
            <path
              id="Path_35626"
              data-name="Path 35626"
              d="M115.409,23.762a3.118,3.118,0,0,0-.724-1.263c-.269-.061-1.737-.2-2.475-.269l.032-.349c.087,0,2.161.2,2.52.276s.814,1.064.962,1.478Z"
              transform="translate(-76.237 -14.861)"
              fill="#ffe0e5"
            />
            <path
              id="Path_35627"
              data-name="Path 35627"
              d="M118.6,34.133s.186-1.087.221-1.253a2.656,2.656,0,0,1,.789-.843,3.122,3.122,0,0,1,1.228-.6.484.484,0,0,1,.343.2.923.923,0,0,1,.17.43.555.555,0,0,1,.295.446.484.484,0,0,1,.218.494s.26.154.2.4a3.427,3.427,0,0,0-.423.49,7.848,7.848,0,0,1-1.632,1.3S119.123,35.787,118.6,34.133Z"
              transform="translate(-80.579 -21.356)"
              fill="#eea886"
            />
            <path
              id="Path_35628"
              data-name="Path 35628"
              d="M122.7,37.145a2.837,2.837,0,0,1,.087.708c-.042.17,1.205.26,1.205.26S123.36,37.007,122.7,37.145Z"
              transform="translate(-83.364 -25.224)"
              fill="#cf8c6c"
            />
            <path
              id="Path_35629"
              data-name="Path 35629"
              d="M123.265,31.506s-1.042.9-.917,1.087a.353.353,0,0,0,.282.18l-.26.564s.042.471.542.292c0,0,0,.442.455.212,0,0,.125.295.359.224a4.488,4.488,0,0,0,.878-.676s.08-.189-.2-.4a.474.474,0,0,0-.218-.494.516.516,0,0,0-.295-.446s-.016-.6-.513-.641A1.22,1.22,0,0,0,123.265,31.506Z"
              transform="translate(-83.118 -21.336)"
              fill="#db9c7c"
            />
            <path
              id="Path_35630"
              data-name="Path 35630"
              d="M83.45,27.141,82.3,36.53l-.9,7.312s-4.687,1.247-4.526-2.286a29.7,29.7,0,0,1,.7-5.027c.923-4.167,2.491-7.783,3.231-8.694A2.489,2.489,0,0,1,83.45,27.141Z"
              transform="translate(-52.224 -18.349)"
              fill="#323657"
            />
            <path
              id="Path_35631"
              data-name="Path 35631"
              d="M81.972,56.443l-.833,7.549s-4.927,1.026-4.767-2.507a27.8,27.8,0,0,1,.878-5.046Z"
              transform="translate(-51.885 -38.342)"
              fill="#335496"
            />
            <path
              id="Path_35632"
              data-name="Path 35632"
              d="M139.425,178.55s1.359.484,1.962.641,2.767-.18,3.017-.019.3.817-.045.962a17.156,17.156,0,0,1-3.815.843c-.91-.032-2.468.641-2.853.574s-.529-2.042-.474-2.507S139.425,178.55,139.425,178.55Z"
              transform="translate(-93.22 -121.307)"
              fill="#a0ba84"
            />
            <path
              id="Path_35633"
              data-name="Path 35633"
              d="M137.278,177.039l-.038.551,2.209-.487-.247-.683Z"
              transform="translate(-93.243 -119.86)"
              fill="#eea886"
            />
            <path
              id="Path_35634"
              data-name="Path 35634"
              d="M73.169,176.432s.92,1.109,1.356,1.558,2.468,1.257,2.6,1.523-.16.856-.523.8a17.594,17.594,0,0,1-3.712-1.225c-.766-.5-2.443-.724-2.744-.962s.587-2.039.872-2.4S73.169,176.432,73.169,176.432Z"
              transform="translate(-47.618 -119.337)"
              fill="#a0ba84"
            />
            <path
              id="Path_35635"
              data-name="Path 35635"
              d="M73.314,174.46l-.314.455,2.148.708.135-.715Z"
              transform="translate(-49.597 -118.528)"
              fill="#eea886"
            />
            <path
              id="Path_35636"
              data-name="Path 35636"
              d="M94.943,45.049c.763.875.9.8,1.478.769,1.26-.048,5.168-6.411,5.168-6.411l-1.119-1.167s-1.334,1.84-2.244,2.94a8.01,8.01,0,0,1-1.741,1.657,17.812,17.812,0,0,1-1.6-1.632c-.282-.327-1.773-3.526-2.3-3.725C92.584,37.477,90.019,39.387,94.943,45.049Z"
              transform="translate(-62.448 -25.46)"
              fill="#eea886"
            />
            <path
              id="Path_35637"
              data-name="Path 35637"
              d="M83.258,96.449s-.25,1.84-.564,4.065l-.228,1.6h0c-.026.186-.051.369-.08.555h0c-.083.58-.167,1.157-.247,1.705h0c-.285,1.923-.539,3.5-.641,3.783-.276.8-5.94,12.243-5.94,12.243s-2.885-.523-2.988-.85c0,0,4.959-12.037,4.924-12.5s.4-13.22.4-13.22S83.107,95.715,83.258,96.449Z"
              transform="translate(-49.304 -63.746)"
              fill="#f2ffd6"
            />
            <path
              id="Path_35638"
              data-name="Path 35638"
              d="M97.1,87.327s4.453,11.726,4.677,12.682,5.6,14.724,5.6,14.724a4.472,4.472,0,0,1-3.263,1.026s-5.844-13.711-6.091-14.169c-.936-1.779-6.992-10.713-7.444-11.944a11.65,11.65,0,0,0-1.241-2.353S92.475,84.872,97.1,87.327Z"
              transform="translate(-60.699 -58.58)"
              fill="#f2ffd6"
            />
            <path
              id="Path_35639"
              data-name="Path 35639"
              d="M96.96,47.119c-.539.106-7.4,1.9-8.434,2.244,0,0-.045-8.219.282-9.2a.707.707,0,0,0,.029-.147,5.774,5.774,0,0,0,.019-.724c-.029-2.109-.474-7.312.433-11.188.048-.208.1-.407.154-.606A7.857,7.857,0,0,1,90.6,25a44.6,44.6,0,0,1,3.6,2.908c.782.83,2.44,4.167,2.3,5.225A53.593,53.593,0,0,0,96.331,43.5,36.057,36.057,0,0,0,96.96,47.119Z"
              transform="translate(-60.144 -16.981)"
              fill="#ffd255"
            />
            <path
              id="Path_35640"
              data-name="Path 35640"
              d="M95,18.145s5.129,5.491,4.809,4.869c-1.244-2.587-1.923-2.565-1.923-2.981,0-.321.112-1.225.128-1.529.029-.58.064-1.055.064-1.055s-2.565-3.491-2.449-2.286A7.947,7.947,0,0,1,95,18.145Z"
              transform="translate(-64.544 -10.126)"
              fill="#eea886"
            />
            <path
              id="Path_35641"
              data-name="Path 35641"
              d="M98.994,2.268a13.007,13.007,0,0,1,.744,2.007,8.011,8.011,0,0,0,0,1.116,15.937,15.937,0,0,1,.426,1.677c-.051.321-.728.167-.811.7s-.558,1.529-.8,1.555c-.641.061-3.049-.875-3.206-1.375s-1.1-3.289-1.372-3.818S98.141.6,98.571,1.432Z"
              transform="translate(-63.839 -0.869)"
              fill="#eea886"
            />
            <path
              id="Path_35642"
              data-name="Path 35642"
              d="M90.517,2.7c-.343-1.382,1.25-1.58,1.25-1.58a4.34,4.34,0,0,1,3.5-1.135c2.488.093,2.167,3.35,2.167,3.35a9.142,9.142,0,0,0-2.468-.022,11.777,11.777,0,0,0,.019,1.728l-.939-.067s.083-1.231-.369-1.282S93.127,6.311,92.3,6.9a.926.926,0,0,0-.122-.369h0A11.005,11.005,0,0,1,91.5,5.186,14.951,14.951,0,0,1,90.517,2.7Z"
              transform="translate(-61.466 0.016)"
              fill="#16202e"
            />
            <path
              id="Path_35643"
              data-name="Path 35643"
              d="M99.462,11.359a.718.718,0,0,0-1.18-.321c-.593.455.055,2.029.641,2.01S99.725,12.167,99.462,11.359Z"
              transform="translate(-66.614 -7.353)"
              fill="#eea886"
            />
            <path
              id="Path_35644"
              data-name="Path 35644"
              d="M97.786,20.69a1.818,1.818,0,0,0,.269.609,5.321,5.321,0,0,0,1.879.939l-.08.984S97.709,22.44,97.786,20.69Z"
              transform="translate(-66.435 -14.052)"
              fill="#c48a6f"
            />
            <path
              id="Path_35645"
              data-name="Path 35645"
              d="M105.356,92.84a4.687,4.687,0,0,0,.641,1c.26.224-1.375,1.821-1.375,1.821a14.523,14.523,0,0,1-.7-1.651A10.619,10.619,0,0,1,105.356,92.84Z"
              transform="translate(-70.605 -63.073)"
              fill="#eea987"
            />
            <path
              id="Path_35646"
              data-name="Path 35646"
              d="M106.349,95.08a32.07,32.07,0,0,1,.686,4.209c-.022,1-3.757-1.491-3.587-2.478Z"
              transform="translate(-70.28 -64.595)"
              fill="#f2ffd6"
            />
            <path
              id="Path_35647"
              data-name="Path 35647"
              d="M95.164,50.5,93.6,55.523l3.308,5.77s-2.218-4.978-2.141-6.235S95.164,50.5,95.164,50.5Z"
              transform="translate(-63.593 -34.306)"
              fill="#d6b047"
            />
            <path
              id="Path_35648"
              data-name="Path 35648"
              d="M117.04,26.956l2.129,5.123,2.347-.689-.984-5.459Z"
              transform="translate(-79.519 -17.613)"
              fill="#fff0f2"
            />
            <path
              id="Path_35649"
              data-name="Path 35649"
              d="M119.37,35.533a4.7,4.7,0,0,1,1.471-.128c.125.022.244.067.272.192s-.122.321-.513.439a3.347,3.347,0,0,0-.846.449S119.316,36.488,119.37,35.533Z"
              transform="translate(-81.099 -24.042)"
              fill="#eea886"
            />
            <path
              id="Path_35650"
              data-name="Path 35650"
              d="M99.054,106.666l-.228,1.6L96.65,102.88Z"
              transform="translate(-65.665 -69.895)"
              fill="#acb598"
            />
            <path
              id="Path_35651"
              data-name="Path 35651"
              d="M95.738,34.5a8.572,8.572,0,0,1-1.481,3.93,8.357,8.357,0,0,1-2.44,2.263q-.282.173-.558.321a5.747,5.747,0,0,0,.019-.724l.218-.131a7.549,7.549,0,0,0,3.622-5.738,4.5,4.5,0,0,0-1.558-4.411,5.267,5.267,0,0,0-1.853-.91c-.122-.032-.192-.045-.2-.045l.106-.615s.1.019.244.054a5.841,5.841,0,0,1,2.064,1.01A5.087,5.087,0,0,1,95.738,34.5Z"
              transform="translate(-62.003 -19.318)"
              fill="#323657"
            />
            <path
              id="Path_35652"
              data-name="Path 35652"
              d="M87.09,33.7s-1.83,9.537-1.975,10.511c-.138.923,6.222,9.649,6.222,9.649l1.6-1.058s-4.809-8.937-4.809-9.341,2.186-8.1,2.186-8.1a2.817,2.817,0,0,0-1.039-2.125,1.487,1.487,0,0,0-2.189.465Z"
              transform="translate(-57.827 -22.372)"
              fill="#eea886"
            />
            <path
              id="Path_35653"
              data-name="Path 35653"
              d="M88.15,36.166l3.7,1.6s1.539-4.809-.478-5.828C89.086,30.812,88.682,32.255,88.15,36.166Z"
              transform="translate(-59.89 -21.443)"
              fill="#ffd255"
            />
            <path
              id="Path_35654"
              data-name="Path 35654"
              d="M28.6,36.24,22.748,43.9s-.641.962-1.728,3.023c-1.407,2.674-2.67,5.626-2.67,5.626s.779.93,1.3.609c.856-.5,5.344-7.168,5.392-7.694s4.623-5.081,4.623-5.081S29.653,36.541,28.6,36.24Z"
              transform="translate(-12.466 -24.618)"
              fill="#eebba2"
            />
            <path
              id="Path_35655"
              data-name="Path 35655"
              d="M34.888,31.5l-1.1,8.957-.859,6.979s-4.472,1.189-4.315-2.183a28.313,28.313,0,0,1,.667-4.809c.882-3.988,2.379-7.428,3.084-8.3A2.392,2.392,0,0,1,34.888,31.5Z"
              transform="translate(-19.435 -21.313)"
              fill="#323657"
            />
            <path
              id="Path_35656"
              data-name="Path 35656"
              d="M33.419,59.7l-.859,6.979s-4.639,1.2-4.488-2.167a26.389,26.389,0,0,1,.837-4.809Z"
              transform="translate(-19.069 -40.557)"
              fill="#6dbd7d"
            />
            <path
              id="Path_35657"
              data-name="Path 35657"
              d="M4.343,168a2.36,2.36,0,0,0,.3,1.369c.407.782,2.222,2.773,2.1,3.594s-2.222.721-3.526-.721S-.074,169.309,0,168.963a9.044,9.044,0,0,1,2.734-2.382C3.417,166.344,4.343,168,4.343,168Z"
              transform="translate(0.006 -113.159)"
              fill="#3f74a0"
            />
            <path
              id="Path_35658"
              data-name="Path 35658"
              d="M97.4,174.819a8.922,8.922,0,0,0,1.984.583c.926.128,3.164-.24,3.8.369s-.734,2.375-2.792,2.638-4.69.7-4.956.426a8,8,0,0,1-.26-3.488C95.386,174.6,97.4,174.819,97.4,174.819Z"
              transform="translate(-64.603 -118.753)"
              fill="#3f74a0"
            />
            <path
              id="Path_35659"
              data-name="Path 35659"
              d="M51.616,22.277a9.754,9.754,0,0,0,.25,2.331c.25.644-3.574-1.151-3.574-1.151a7.719,7.719,0,0,0-.058-3.106C47.824,19.21,51.616,22.277,51.616,22.277Z"
              transform="translate(-32.749 -13.649)"
              fill="#eebba2"
            />
            <path
              id="Path_35660"
              data-name="Path 35660"
              d="M66.615,103.2l-4.706,3.142s-.991-.692-2.157-1.571c-.285-.218-.58-.442-.875-.673a20.331,20.331,0,0,1-2.456-2.167c-1.263-1.459,1.5-10.691,5-6.575C61.422,95.358,65.692,100.769,66.615,103.2Z"
              transform="translate(-38.121 -64.072)"
              fill="#f08d29"
            />
            <path
              id="Path_35661"
              data-name="Path 35661"
              d="M50.714,94.17S48.87,103.761,46.7,107.2a48.777,48.777,0,0,1-5.943-1.452s.045-7.242.208-9.152C41.122,94.818,50.714,94.17,50.714,94.17Z"
              transform="translate(-27.692 -63.977)"
              fill="#f08d29"
            />
            <path
              id="Path_35662"
              data-name="Path 35662"
              d="M51.618,50.732c-.1.321-.462,1.8-.8,1.818l-10.258.862s.487-7.966-.282-12.182A15.392,15.392,0,0,1,42.2,31.4a6.347,6.347,0,0,1,.641-.792s3.52,1.087,3.7,1.128a5.218,5.218,0,0,1,.882.92h0a10.774,10.774,0,0,1,2.536,5.421c.205,3.087,1.741,5.2,2.007,8.681A8.82,8.82,0,0,1,51.618,50.732Z"
              transform="translate(-27.253 -20.792)"
              fill="#fffed1"
            />
            <path
              id="Path_35663"
              data-name="Path 35663"
              d="M64.787,56.809a.228.228,0,0,1-.09.083,4.658,4.658,0,0,1-1.1.369c-2.18.545-6.71,1.189-8.043.962-1.75-.321-5.238-8.171-5.238-8.171L53.519,48l2.629,5.328A2.632,2.632,0,0,0,57.27,54.5a4.562,4.562,0,0,0,1.8.593c1.154.038,2.767.048,3.933.051h1.507S65,56.306,64.787,56.809Z"
              transform="translate(-34.187 -32.608)"
              fill="#eebba2"
            />
            <path
              id="Path_35664"
              data-name="Path 35664"
              d="M96.634,171.33l.237,1.138L94.647,173l-.337-1.17Z"
              transform="translate(-64.075 -116.402)"
              fill="#eebba2"
            />
            <path
              id="Path_35665"
              data-name="Path 35665"
              d="M10.711,165.463l-.741.8L8.3,164.838l.769-.888Z"
              transform="translate(-5.637 -111.388)"
              fill="#eebba2"
            />
            <path
              id="Path_35666"
              data-name="Path 35666"
              d="M94.124,67.347a2.244,2.244,0,0,0,.763-.817,1.482,1.482,0,0,1,1.58-.513c.433.167.962-.1,1.128.2s-.321.689-.269.962.433,1.539-.593,1.869-1.923-.074-2.456.1S94.124,67.347,94.124,67.347Z"
              transform="translate(-63.86 -44.809)"
              fill="#eebba2"
            />
            <path
              id="Path_35667"
              data-name="Path 35667"
              d="M78.666,122.05s.9,2.475,3.023,10.611c0,0,1.228,4.488,1.439,5.014s-3.783,1.391-3.8.853-4.257-11.281-5.366-13.336C72.983,123.384,78.666,122.05,78.666,122.05Z"
              transform="translate(-50.172 -82.919)"
              fill="#f08d29"
            />
            <path
              id="Path_35668"
              data-name="Path 35668"
              d="M25.267,127.633C23.985,130.519,11.514,139,11.514,139s-.872-.526-1.587-1.064c-.151-.109-.292-.221-.42-.321a2.988,2.988,0,0,1-.391-.365c-.519-.606,2.606-3.584,2.606-3.584,7.024-8.11,7.514-9.123,7.514-9.123s.757.032,1.907.189l.506.077C23.786,125.139,26.046,125.883,25.267,127.633Z"
              transform="translate(-6.153 -84.611)"
              fill="#f08d29"
            />
            <path
              id="Path_35669"
              data-name="Path 35669"
              d="M55.844,53.913,51.455,55.8s-.128-.321-.295-.776l4.392-1.962C55.744,53.563,55.844,53.913,55.844,53.913Z"
              transform="translate(-34.758 -36.046)"
              fill="#eddad3"
            />
            <path
              id="Path_35670"
              data-name="Path 35670"
              d="M51.757,32.6A29.522,29.522,0,0,1,46.5,31.322a6.347,6.347,0,0,1,.641-.792s3.555,1.112,3.735,1.154A5.219,5.219,0,0,1,51.757,32.6Z"
              transform="translate(-31.592 -20.738)"
              fill="#335496"
            />
            <path
              id="Path_35671"
              data-name="Path 35671"
              d="M51.237,7.892A10.769,10.769,0,0,1,52.2,9.886c0,.346-.414.346-.2.705s.664,1.039.449,1.26-.721.112-.817.567-.7,1.923-1.35,1.923a6.276,6.276,0,0,1-3.049-1.6c-.163-.468-1-3.033-1.282-3.526s4.462-2.885,4.873-2.1Z"
              transform="translate(-31.213 -4.72)"
              fill="#eebba2"
            />
            <path
              id="Path_35672"
              data-name="Path 35672"
              d="M49.528,4.433A.85.85,0,0,0,49.98,3.4a1.644,1.644,0,0,0-2.222-1.173C46.37,2.67,45.351,3.6,44.4,3.757s-1.167,1.077-.321,2.8S45.27,9.12,45.27,9.12s.263-1.484.641-1.427.939-.92.939-.92l.093.25.641-.042s-.833-1.8-.391-2.032S49.467,4.638,49.528,4.433Z"
              transform="translate(-29.59 -1.428)"
              fill="#163342"
            />
            <path
              id="Path_35673"
              data-name="Path 35673"
              d="M53.167,17.32s.423,1.542.776,1.561a6.071,6.071,0,0,1,1.773.388.321.321,0,0,1,.1.359c-.074.224-.285,1.558.138,2,0,0-.3.478-1.814-.051a7.344,7.344,0,0,1-2.311-1.25s1.087-2.064.7-2.965Z"
              transform="translate(-35.213 -11.763)"
              fill="#163342"
            />
            <path
              id="Path_35674"
              data-name="Path 35674"
              d="M50.673,14.895a.8.8,0,0,0-1.241-.256c-.583.42.192,1.827.8,1.8S51,15.622,50.673,14.895Z"
              transform="translate(-33.443 -9.809)"
              fill="#eebba2"
            />
            <path
              id="Path_35675"
              data-name="Path 35675"
              d="M47.184,39.959a4.579,4.579,0,0,0-3.065-3.318,6.3,6.3,0,0,0-2.424-.407c-.087.18-.17.381-.253.571a3.093,3.093,0,0,1,.526-.045,5.77,5.77,0,0,1,1.959.359,4.153,4.153,0,0,1,2.731,2.94,2.244,2.244,0,0,1-.407,1.923c-1.209,1.638-4.392,2.391-5.911,2.667.019.135.038.266.061.394a.248.248,0,0,0,.022.125c1.584-.279,4.927-1.071,6.264-2.885A2.773,2.773,0,0,0,47.184,39.959Z"
              transform="translate(-27.406 -24.612)"
              fill="#323657"
            />
            <path
              id="Path_35676"
              data-name="Path 35676"
              d="M54.3,46.5,49.907,48.38s-.128-.321-.295-.776c-.471-1.337-1.244-4.014-.24-4.841,2.4-1.975,4.01,1.247,4.632,2.885C54.2,46.146,54.3,46.5,54.3,46.5Z"
              transform="translate(-33.21 -28.628)"
              fill="#fffed1"
            />
            <path
              id="Path_35677"
              data-name="Path 35677"
              d="M17.527,84.608s-.34.728.077,1.125a3.116,3.116,0,0,1,.622,1.151c.087.253.68,1.01.321,1.035S17.5,86.7,17.306,86.451s-.529.885-.8,1-2.6-.837-2.27-1.558,1.282-1.077,1.346-1.212.426-.962.426-.962Z"
              transform="translate(-9.652 -56.877)"
              fill="#eebba2"
            />
            <path
              id="Path_35678"
              data-name="Path 35678"
              d="M13.613,91.978a13.613,13.613,0,0,1-1.459,2.17c-.24.058-.391-.032-.289-.237s1.1-2.084,1.1-2.084-2.01,2.2-2.186,2.289c-.1.048-.4-.109-.362-.224s1.856-2.423,1.856-2.423a26.159,26.159,0,0,1-2.064,2.08c-.183.029-.407-.17-.282-.321s1.718-2.186,1.718-2.186-1.491,1.4-1.7,1.34c-.119-.035-.385-.163.022-.51S11.2,90.59,11.2,90.59Z"
              transform="translate(-6.623 -61.545)"
              fill="#eebba2"
            />
            <path
              id="Path_35679"
              data-name="Path 35679"
              d="M65.112,114.662c-.285-.218-.821-.641-1.112-.862a34.3,34.3,0,0,0,1.6-4.959A13.842,13.842,0,0,1,65.112,114.662Z"
              transform="translate(-43.482 -73.944)"
              fill="#cf7923"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

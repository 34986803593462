import LoginForm from './LoginForm';
import AddArticleForm from './AddArticleForm';
import AddRewardDetailsForm from './AddRewardDetailsForm';
import RewardExchangeForm from './RewardExchangeForm';
import AddArticleInvitraceForm from './AddArticleInvitraceForm';
import DownloadDeleteAccountForm from './DownloadDeleteAccountForm';
import CreateUserAccountForm from './CreateUserAccountForm';
import CreateDepartmentForm from './CreateDepartmentForm';
import YourAccountForm from './YourAccountForm';
import CreateAccountForm from './CreateAccountForm';
import ManyUserUploadForm from './ManyUserUploadForm';
import RewardPointForm from './RewardPointForm';
import PointAddDeleteForm from './PointAddDeleteForm';
import AddMissionForm from './AddMissionForm';
import AddHealthyClubForm from './AddHealthyClubForm';
import AddGroupRoleForm from './AddGroupRoleForm';
import AddSpinGameForm from './AddSpinGameForm';
import AddLuckyRewardForm from './AddLuckyRewardForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import OTPForm from './OTPForm';
import ChangePasswordForm from './ChangePasswordForm';
import SendPasswordForm from './SendPasswordForm';
import SelectDeleteForm from './SelectDeleteForm';

const Export = {
  SelectDeleteForm,
  SendPasswordForm,
  ChangePasswordForm,
  OTPForm,
  ForgotPasswordForm,
  AddLuckyRewardForm,
  AddSpinGameForm,
  AddGroupRoleForm,
  AddHealthyClubForm,
  AddMissionForm,
  ManyUserUploadForm,
  RewardPointForm,
  LoginForm,
  AddArticleForm,
  AddRewardDetailsForm,
  RewardExchangeForm,
  AddArticleInvitraceForm,
  DownloadDeleteAccountForm,
  CreateUserAccountForm,
  CreateDepartmentForm,
  PointAddDeleteForm,
  YourAccountForm,
  CreateAccountForm,
};

export default Export;

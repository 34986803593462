export default function ActivenessGoal({ color = '#4E5B7E' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="96"
      height="92.049"
      viewBox="0 0 96 92.049"
    >
      <defs>
        <clipPath id="clipPath">
          <path
            id="Path_34951"
            data-name="Path 34951"
            d="M12.1,176.12s-31.971,21.7,8.05,22.488S96,187.627,96,159.04l-.034-30.09-3.131-11.18h-17.7l-40.98,43.059-9.909,7.484Z"
            transform="translate(-0.002 -117.77)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.03" stopColor="#fbfbfc" />
          <stop offset="0.29" stopColor="#dde0e6" />
          <stop offset="0.53" stopColor="#c7ccd6" />
          <stop offset="0.78" stopColor="#bac0cc" />
          <stop offset="1" stopColor="#b6bcc9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.255"
          y1="0.5"
          x2="1.255"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#5e7ece" />
          <stop offset="1" stopColor="#365ec2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0.634"
          y1="0.5"
          x2="1.634"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#203a7a" />
          <stop offset="0.41" stopColor="#182e64" />
          <stop offset="1" stopColor="#0a193d" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="2.807"
          y1="1"
          x2="2.807"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.39" stopColor="#294792" />
          <stop offset="1" stopColor="#153175" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="4.568"
          y1="0.5"
          x2="5.569"
          y2="0.5"
          xlinkHref="#linear-gradient-4"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="12.903"
          y1="0.501"
          x2="13.903"
          y2="0.501"
          xlinkHref="#linear-gradient-4"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="3.119"
          y1="0"
          x2="3.119"
          y2="1"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="4.699"
          y1="0"
          x2="4.699"
          y2="1"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="3.48"
          y1="1"
          x2="3.48"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#5e7ece" />
          <stop offset="1" stopColor="#869eda" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-10"
          x1="2.857"
          y1="1"
          x2="2.857"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0a193d" />
          <stop offset="1" stopColor="#3b4764" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-11"
          x1="6.581"
          y1="0.5"
          x2="7.58"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0a193d" />
          <stop offset="0.48" stopColor="#222f50" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-12"
          x1="4.594"
          y1="0.5"
          x2="5.594"
          y2="0.5"
          xlinkHref="#linear-gradient-9"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="7.406"
          y1="0.5"
          x2="8.403"
          y2="0.5"
          xlinkHref="#linear-gradient-9"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="-233.317"
          y1="1"
          x2="-233.317"
          y2="0"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="Asset_1" data-name="Asset 1" transform="translate(-67.982)">
        <g id="Layer_2" data-name="Layer 2" transform="translate(67.982)">
          <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
            <g
              id="Group_14853"
              data-name="Group 14853"
              transform="translate(0 11.175)"
              clipPath="url(#clipPath)"
            >
              <path
                id="Path_34932"
                data-name="Path 34932"
                d="M726.005,260.72v74.865L689,335.611Z"
                transform="translate(-630.075 -247.156)"
                fill="#eaecf0"
              />
              <path
                id="Path_34933"
                data-name="Path 34933"
                d="M882.28,217.658h18.733l-1.217-1.9H884.241Z"
                transform="translate(-805.015 -206.462)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34934"
                data-name="Path 34934"
                d="M.28,737.315h83.2l4.911-4.555H18.775Z"
                transform="translate(-6.704 -674.406)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34935"
                data-name="Path 34935"
                d="M0,795.19v26.623H75.174l1.03-3.036H0v-4.84H76.2l3.768-6.262H0v-4.649H79.972l3.253-4.934H0Z"
                transform="translate(-6.451 -730.913)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34936"
                data-name="Path 34936"
                d="M824.27,262.942h23.047l1.191-2.182H829.774Z"
                transform="translate(-752.509 -247.192)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34937"
                data-name="Path 34937"
                d="M902.95,178.278H918.5l-1.056-1.518H903.863Z"
                transform="translate(-823.724 -171.163)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34938"
                data-name="Path 34938"
                d="M912.57,145.8h13.586l-.866-1.044H913.319Z"
                transform="translate(-832.431 -142.199)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34939"
                data-name="Path 34939"
                d="M587.64,433.607h40.01l2-2.847h-32.4Z"
                transform="translate(-538.332 -401.062)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34940"
                data-name="Path 34940"
                d="M759.44,314.227h27.528l1.67-2.467H765.591Z"
                transform="translate(-693.831 -293.353)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34941"
                data-name="Path 34941"
                d="M920.46,118.624h11.971l-.731-.854H921.092Z"
                transform="translate(-839.572 -117.77)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34942"
                data-name="Path 34942"
                d="M528.98,498.512h43.386l2.19-2.752h-40.01Z"
                transform="translate(-485.238 -459.894)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34943"
                data-name="Path 34943"
                d="M688.92,371.512h32.4l1.817-2.752H695.611Z"
                transform="translate(-630.002 -344.945)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34944"
                data-name="Path 34944"
                d="M195.2,650.84h69.615l2.77-4.08h-56.26Z"
                transform="translate(-183.129 -596.567)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34945"
                data-name="Path 34945"
                d="M365.13,567.745h56.26l2.673-3.985H380.677Z"
                transform="translate(-336.935 -521.442)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34946"
                data-name="Path 34946"
                d="M932.79,75.434h9.448l-.542-.664h-8.437Z"
                transform="translate(-850.732 -78.85)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34947"
                data-name="Path 34947"
                d="M927.12,95.529h10.608l-.622-.759h-9.448Z"
                transform="translate(-845.6 -96.952)"
                fill="#f0f2f4"
              />
              <path
                id="Path_34948"
                data-name="Path 34948"
                d="M938.244,60.77l-.514.38h8.437l-.6-.38Z"
                transform="translate(-855.204 -66.178)"
                fill="#f0f2f4"
              />
              <rect
                id="Rectangle_18184"
                data-name="Rectangle 18184"
                width="76.193"
                height="4.839"
                transform="translate(-6.451 83.025)"
                fill="#d3d8df"
              />
              <rect
                id="Rectangle_18185"
                data-name="Rectangle 18185"
                width="79.894"
                height="4.649"
                transform="translate(-6.451 72.113)"
                fill="#d3d8df"
              />
              <path
                id="Path_34949"
                data-name="Path 34949"
                d="M0,782.129v2.9H83.215v-4.27H0Z"
                transform="translate(-6.451 -717.852)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18186"
                data-name="Rectangle 18186"
                width="69.551"
                height="4.08"
                transform="translate(12.052 54.274)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18187"
                data-name="Rectangle 18187"
                width="56.267"
                height="3.89"
                transform="translate(28.183 46.304)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18188"
                data-name="Rectangle 18188"
                width="43.363"
                height="3.701"
                transform="translate(43.744 38.618)"
                fill="#c5cbd5"
              />
              <path
                id="Path_34950"
                data-name="Path 34950"
                d="M587,461.146v2.935h40.042V460.76H587Z"
                transform="translate(-537.753 -428.215)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18189"
                data-name="Rectangle 18189"
                width="32.356"
                height="3.131"
                transform="translate(58.926 26.568)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18190"
                data-name="Rectangle 18190"
                width="27.517"
                height="2.941"
                transform="translate(65.568 20.875)"
                fill="#c5cbd5"
              />
              <rect
                id="Rectangle_18191"
                data-name="Rectangle 18191"
                width="23.057"
                height="2.657"
                transform="translate(71.735 15.751)"
                fill="#c4cbd4"
              />
              <rect
                id="Rectangle_18192"
                data-name="Rectangle 18192"
                width="18.692"
                height="2.372"
                transform="translate(77.238 11.196)"
                fill="#c6cdd5"
              />
              <rect
                id="Rectangle_18193"
                data-name="Rectangle 18193"
                width="15.561"
                height="2.182"
                transform="translate(79.231 7.116)"
                fill="#c8cfd7"
              />
              <rect
                id="Rectangle_18194"
                data-name="Rectangle 18194"
                width="13.569"
                height="1.993"
                transform="translate(80.085 3.606)"
                fill="#cad0d8"
              />
              <rect
                id="Rectangle_18195"
                data-name="Rectangle 18195"
                width="11.956"
                height="1.708"
                transform="translate(80.844 0.854)"
                fill="#cdd2da"
              />
              <rect
                id="Rectangle_18196"
                data-name="Rectangle 18196"
                width="10.627"
                height="1.423"
                transform="translate(81.508 -1.423)"
                fill="#cfd4db"
              />
              <rect
                id="Rectangle_18197"
                data-name="Rectangle 18197"
                width="9.489"
                height="1.234"
                transform="translate(81.983 -3.416)"
                fill="#d1d6dd"
              />
              <rect
                id="Rectangle_18198"
                data-name="Rectangle 18198"
                width="8.445"
                height="0.949"
                transform="translate(82.457 -5.029)"
                fill="#d3d8de"
              />
              <rect
                id="Rectangle_18199"
                data-name="Rectangle 18199"
                width="7.306"
                height="0.759"
                transform="translate(83.026 -6.168)"
                fill="#d5dae0"
              />
            </g>
            <path
              id="Path_34952"
              data-name="Path 34952"
              d="M135.395,720.534c7.484,3.516,12.635-4.678,19.905-5.421s16.714-4.484,10.551-7.686-12.887-2.218-18.59.591S126.51,716.358,135.395,720.534Z"
              transform="translate(-120.065 -638.496)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_34953"
              data-name="Path 34953"
              d="M440.251,696.29l-.931,2.865,3-.023.424-1.87Z"
              transform="translate(-404.086 -630.222)"
              fill="#d4dae9"
            />
            <path
              id="Path_34954"
              data-name="Path 34954"
              d="M260.087,740.728l1.667-1.7-1.518-2.441-1.916,1.926Z"
              transform="translate(-240.26 -666.698)"
              fill="#d4dae9"
            />
            <path
              id="Path_34955"
              data-name="Path 34955"
              d="M162.764,758.958a2.9,2.9,0,0,0-1.2.19l.741,3.247.658.789a13.046,13.046,0,0,0,.346,3.543c.441,1.533.838,1.435.838,1.435l.707-.19Z"
              transform="translate(-146.231 -686.94)"
              fill="#091634"
            />
            <path
              id="Path_34956"
              data-name="Path 34956"
              d="M175.757,753.313a2.17,2.17,0,0,0-1.817.492c-.312.256.474,3.036.692,4.251s.509,4.608,1.364,4.776c.29.058.854.083.644-.825a15.746,15.746,0,0,1,.061-3.529,6.878,6.878,0,0,1,.719-1.91.841.841,0,0,0-.052-.848,7.572,7.572,0,0,1-1.011-1.057A2.416,2.416,0,0,1,175.757,753.313Z"
              transform="translate(-157.371 -681.799)"
              fill="#3a455d"
            />
            <path
              id="Path_34957"
              data-name="Path 34957"
              d="M462.226,392.765s-.041-2.035.639-2.3.8,0,.8,0,1.5-.958,2.218-.439,1.339,3.277.4,3.7a10.977,10.977,0,0,1-2.618.6l-.447.158Z"
              transform="translate(-418.367 -352.882)"
              fill="#ff997a"
            />
            <path
              id="Path_34958"
              data-name="Path 34958"
              d="M184.146,492.75a6.669,6.669,0,0,1,.346,1.682c0,.674-.552,1.083-.658,1.436s-.59,1.279-.687.354.392-1.13-.179-1.79,0-2.782,0-2.782Z"
              transform="translate(-165.378 -445)"
              fill="#ff997a"
            />
            <path
              id="Path_34959"
              data-name="Path 34959"
              d="M115.969,510.427l1.569-3.734a1.777,1.777,0,0,1,2.326-.949l2.888,1.214a1.778,1.778,0,0,1,.949,2.326l-1.569,3.734a1.777,1.777,0,0,1-2.326.949l-2.887-1.214A1.778,1.778,0,0,1,115.969,510.427Zm3.511-3.763a.781.781,0,0,0-1.02.417l-1.569,3.733a.778.778,0,0,0,.416,1.019l2.888,1.212a.78.78,0,0,0,1.019-.417l1.568-3.733a.778.778,0,0,0-.416-1.019Z"
              transform="translate(-104.84 -457.631)"
              fill="#2e4681"
            />
            <rect
              id="Rectangle_18200"
              data-name="Rectangle 18200"
              width="2.082"
              height="0.638"
              rx="0.319"
              transform="translate(18.564 53.724) rotate(-157.2)"
              fill="#2e4681"
            />
            <rect
              id="Rectangle_18201"
              data-name="Rectangle 18201"
              width="2.082"
              height="0.638"
              rx="0.319"
              transform="translate(13.361 51.536) rotate(-157.2)"
              fill="#2e4681"
            />
            <rect
              id="Rectangle_18202"
              data-name="Rectangle 18202"
              width="13.81"
              height="9.132"
              rx="4.566"
              transform="translate(17.975 62.965) rotate(-157.2)"
              fill="#d49100"
            />
            <rect
              id="Rectangle_18203"
              data-name="Rectangle 18203"
              width="13.81"
              height="9.132"
              rx="4.566"
              transform="translate(17.975 62.965) rotate(-157.2)"
              fill="#475f9b"
            />
            <rect
              id="Rectangle_18204"
              data-name="Rectangle 18204"
              width="0.912"
              height="8.18"
              transform="translate(15.587 61.961) rotate(-157.2)"
              fill="#2e4681"
            />
            <rect
              id="Rectangle_18205"
              data-name="Rectangle 18205"
              width="0.912"
              height="8.18"
              transform="translate(8.472 58.972) rotate(-157.2)"
              fill="#2e4681"
            />
            <rect
              id="Rectangle_18206"
              data-name="Rectangle 18206"
              width="14.615"
              height="4.582"
              rx="2.291"
              transform="translate(20.108 58.927) rotate(-157.2)"
              fill="#7e98d8"
            />
            <rect
              id="Rectangle_18207"
              data-name="Rectangle 18207"
              width="14.615"
              height="4.015"
              rx="2.007"
              transform="translate(20.107 58.927) rotate(-157.2)"
              fill="#5e7ece"
            />
            <rect
              id="Rectangle_18208"
              data-name="Rectangle 18208"
              width="1.58"
              height="2.426"
              rx="0.79"
              transform="translate(11.974 56.286) rotate(-67.2)"
              fill="#7e98d8"
            />
            <rect
              id="Rectangle_18209"
              data-name="Rectangle 18209"
              width="1.393"
              height="2.426"
              rx="0.696"
              transform="translate(11.973 56.287) rotate(-67.2)"
              fill="#2e4681"
            />
            <path
              id="Path_34960"
              data-name="Path 34960"
              d="M152.9,488.234s-1.044,2.069-1.587,2.177a2.759,2.759,0,0,1-2.285-.844,2.383,2.383,0,0,1,0-2.068c.272-.408,2.047-2.149,2.047-2.149l1.942,1.3Z"
              transform="translate(-134.671 -439.297)"
              fill="#ff997a"
            />
            <path
              id="Path_34961"
              data-name="Path 34961"
              d="M524.349,422.581l.5-.321-1.119-1.94-.679.443Z"
              transform="translate(-479.871 -380.438)"
              fill="#d4dae9"
            />
            <path
              id="Path_34962"
              data-name="Path 34962"
              d="M364.912,377.14c.657.538,1.763,6.845,1.763,6.845l5.256-2.6,1.716,2.937s-6.363,4.765-8.521,4.072S363,384.155,363,384.155Z"
              transform="translate(-328.557 -341.355)"
              fill="url(#linear-gradient-2)"
            />
            <path
              id="Path_34963"
              data-name="Path 34963"
              d="M371.491,745.453a2.791,2.791,0,0,0-.085,1.157h3.252l.894-.425a12.6,12.6,0,0,0,3.447.453c1.549-.085,1.557-.474,1.557-.474l-.029-.724Z"
              transform="translate(-336.142 -674.709)"
              fill="#091634"
            />
            <path
              id="Path_34964"
              data-name="Path 34964"
              d="M370.316,714.62a2.6,2.6,0,0,0,.021,2.062c.176.352,2.993.206,4.2.265s4.5.515,4.839-.263c.117-.264.264-.792-.646-.792a15.473,15.473,0,0,1-3.345-.824,9.011,9.011,0,0,1-2.025-1.408s-.233.577-1.376.938A2.259,2.259,0,0,1,370.316,714.62Z"
              transform="translate(-334.99 -645.944)"
              fill="#3a455d"
            />
            <path
              id="Path_34965"
              data-name="Path 34965"
              d="M301.061,499.77h3.294l1.5,2.679s8.363,3.416,8.488,5.409-3.8,11.386-4.11,12.631-4.608-.565-4.175-2.372,2.428-8.719,2.428-8.719-8.729-.964-9.62-3.206S301.061,499.77,301.061,499.77Z"
              transform="translate(-270.366 -452.349)"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="Path_34966"
              data-name="Path 34966"
              d="M201.645,495.7a19.674,19.674,0,0,0-.5,6.782,24.629,24.629,0,0,1-.812,7.906s-4.983,7.346-5.668,8.654,1.743,4.047,3.486,2.365,6.321-8.593,7.276-10.153,2.827-10.587,2.827-10.587v-3.59Z"
              transform="translate(-176.083 -448.665)"
              fill="url(#linear-gradient-4)"
            />
            <path
              id="Path_34967"
              data-name="Path 34967"
              d="M323.108,319.123a5.731,5.731,0,0,0-.692,2.345c0,.751-2.053.095-2.874-.271s.2-1.082,1.071-3.816Z"
              transform="translate(-288.949 -287.265)"
              fill="#ff997a"
            />
            <path
              id="Path_34968"
              data-name="Path 34968"
              d="M398.148,351.461l-.623-.2-.491-.039-1.4.308-.76.2,1.433,4.436.51,8.01.844-1.068Z"
              transform="translate(-363.853 -317.894)"
              fill="#bcc4d6"
            />
            <path
              id="Path_34969"
              data-name="Path 34969"
              d="M404.277,367.537l.405,9.868-1.128,1.635-1.084-1.894.831-9.726Z"
              transform="translate(-370.732 -332.557)"
              fill="url(#linear-gradient-5)"
            />
            <path
              id="Path_34970"
              data-name="Path 34970"
              d="M342.459,351.22l-.759.345.149,1.192a2.657,2.657,0,0,0,.975.117l.127-1.226Z"
              transform="translate(-309.278 -317.894)"
              fill="url(#linear-gradient-6)"
            />
            <path
              id="Path_34971"
              data-name="Path 34971"
              d="M323.993,266.272c2.83-.687,3.923.807,3.923,3.226s-.231,3.866-1.5,3.692a4.79,4.79,0,0,1-3.634-2.769C322.32,268.921,322.088,266.733,323.993,266.272Z"
              transform="translate(-291.863 -240.855)"
              fill="#ff997a"
            />
            <path
              id="Path_34972"
              data-name="Path 34972"
              d="M322.157,334.729l-.807.807a5.282,5.282,0,0,1-2.08-1.547l.818-.99Z"
              transform="translate(-288.976 -301.403)"
              fill="#d4dae9"
            />
            <path
              id="Path_34973"
              data-name="Path 34973"
              d="M418.224,346.37l-.544.46.544.941.437-.7Z"
              transform="translate(-384.499 -313.505)"
              fill="#d4dae9"
            />
            <path
              id="Path_34974"
              data-name="Path 34974"
              d="M255.325,340.73s-2.58.218-3.159,1.234-.664,10-.934,11.115-1.913,3.558-1.468,4.981,6.821,2.713,7.97,2.58,1.586-4.493,1.586-4.493l-.143-7.7-1.443-4.4S256.7,340.833,255.325,340.73Z"
              transform="translate(-225.998 -308.4)"
              fill="url(#linear-gradient-7)"
            />
            <path
              id="Path_34975"
              data-name="Path 34975"
              d="M352.488,353.76s2.193,1.328,2.193,2.218-2.234,11.92-1.092,13.344-.488,1.007-1.1.306a4.721,4.721,0,0,1-.788-2.087S352.522,356.523,352.488,353.76Z"
              transform="translate(-318.329 -320.193)"
              fill="url(#linear-gradient-8)"
            />
            <path
              id="Path_34976"
              data-name="Path 34976"
              d="M172.873,478.146l-.688.919a4.34,4.34,0,0,1-2.685-1.708l.62-.826Z"
              transform="translate(-153.417 -431.314)"
              fill="#d4dae9"
            />
            <path
              id="Path_34977"
              data-name="Path 34977"
              d="M183.957,344.61c1.654.011,2.037,3.131-.632,6.334s-7.149,8.32-7.149,8.32-2.713-.845-3.336-2.047c0,0,4.121-5.788,5.188-6.941S181.884,344.6,183.957,344.61Z"
              transform="translate(-156.44 -311.911)"
              fill="url(#linear-gradient-9)"
            />
            <path
              id="Path_34978"
              data-name="Path 34978"
              d="M315.087,252.053a2.713,2.713,0,0,1,2.171-1.461c1.431,0,2.73-.785,3.285-.65s.854,2.941-.214,3.3-2.176.046-2.514,1.629a5.693,5.693,0,0,1-1.587,2.706,1.886,1.886,0,0,1-1.274-1.248C314.549,255.247,313.677,252.026,315.087,252.053Z"
              transform="translate(-284.49 -226.212)"
              fill="url(#linear-gradient-10)"
            />
            <path
              id="Path_34979"
              data-name="Path 34979"
              d="M340.568,297.041s-.474-.812-.831-.406-.6,2,.688,2Z"
              transform="translate(-307.243 -268.386)"
              fill="#ff997a"
            />
            <path
              id="Path_34980"
              data-name="Path 34980"
              d="M418.924,283.42l-.471,2.784H417.7l-.025-1.742Z"
              transform="translate(-384.499 -256.528)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_34981"
              data-name="Path 34981"
              d="M310.359,337.4s2.234,2.049,2.728,6.759a46.058,46.058,0,0,1,0,8.977l-1.773-7.116,1.2-1.379-1.735-.534a11.274,11.274,0,0,0-1.69-6.391Z"
              transform="translate(-279.762 -305.386)"
              fill="url(#linear-gradient-12)"
            />
            <path
              id="Path_34982"
              data-name="Path 34982"
              d="M352.334,350.44l.569.315a19.056,19.056,0,0,1,.511,5.314l-.606.268.775.659s-1.724,6.721-1.844,7.541c0,0,.329-6.9.387-7.74S352.334,350.44,352.334,350.44Z"
              transform="translate(-318.365 -317.188)"
              fill="url(#linear-gradient-13)"
            />
            <rect
              id="Rectangle_18210"
              data-name="Rectangle 18210"
              width="0.398"
              height="11.17"
              transform="translate(87.8 11.17) rotate(180)"
              fill="url(#linear-gradient-14)"
            />
            <path
              id="Path_34983"
              data-name="Path 34983"
              d="M937.5,0l-5.4,1.272,5.4,1.4Z"
              transform="translate(-850.108)"
              fill="#812e58"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components';

export const MissionHistoryDetailStyled = styled.div`
  .container_mis_history {
    .top_main {
      display: flex;
      margin-bottom: 31px;
      .top_main_left {
        width: 150px;
        flex-shrink: 0;
      }
      .top_main_right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .date_layout {
          position: relative;
          margin-left: 16px;
          .date_position {
          }
        }
      }
    }
    .bottom_main {
      display: flex;
      align-items: flex-end;
      column-gap: 20px;
      row-gap: 16px;
      .group_state {
        flex: 1;
        display: flex;
        .bottom_main_left {
          display: flex;
          justify-content: center;
          flex-shrink: 0;
          width: 122px;
          margin-left: auto;
          margin-right: auto;
          .img_icon {
            // width: 122px;
            width: auto;
            height: auto;
          }
          // &.move {
          //   .img_icon {
          //     width: 72px;
          //     height: auto;
          //   }
          // }
          // &.rest {
          //   align-items: center;
          //   padding-bottom: 46px;
          //   .img_icon {
          //     width: 87px;
          //     height: auto;
          //   }
          // }
        }
        .bottom_main_right {
          flex: 1;
          // min-width: 388px;
          min-width: 233px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .group_label {
            width: 100%;
            margin-bottom: 12px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            color: ${({ theme }) => theme.COLORS.GRAY_23};
            font-family: noto_sans_thai_bold, noto_sans_bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .extra_text {
              font-size: ${({ theme }) => theme.FONT.SIZE.S20};
              font-family: noto_sans_thai_bold, noto_sans_bold;
              color: ${({ theme }) => theme.COLORS.BLUE_27};
            }
          }
        }
      }
      .show_success {
        flex-shrink: 0;
        width: 208px;
        height: 135px;
      }
    }
  }
`;

import styled from "styled-components";

export const DepartmentDetailContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 15px 32px 80px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.mb61 {
        margin-bottom: 61px;
      }
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S32};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
        .bw132 {
          width: 132px;
        }
        .bw140 {
          width: 140px;
        }
      }
    }
  }
  .layer_two {
    margin-top: 43px;
  }
  .layer_three {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    .left_three {
      // width: 60%;
      flex: 1;
      .show_det {
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        border-radius: 10px;
        box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.75);
        min-height: 389px;
      }
    }
    .right_three {
      // width: 40%;
      width: 529px;
      /* flex-shrink: 0; */
      .t_three {
        margin-bottom: 24px;
      }
      .b_three {
        display: flex;
        justify-content: space-between;
        .box_three {
          width: 48%;
        }
      }
    }
  }
  .c_row_3_col {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    margin-top: 32px;
    row-gap: 16px;
    .item_wrap {
      padding: 0 16px;
      width: calc(33.33% - 32px);
    }
  }
`;

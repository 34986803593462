import React from "react";
import { connect } from "react-redux";
import { RewardManagmentContainerStyled } from "./styled";
import {
  Tabs,
  Icons,
  Inputs,
  Typographys,
  Buttons,
  TableCustom,
  Loading,
} from "components";
import { WeeklyPopular, MostPicked } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import { adminService } from "apiServices";
import { setReduxRewardCategory } from "store/actions";
import moment from "moment";
import { DIF_DATE_2 } from "utils/functions/date";
import theme from "styles/theme.json";

class RewardManagmentContainer extends React.Component {
  state = {
    isLoading: false,
    keyword: "",
    categoryFilter: "category_id=1",
    loadSkeleton: true,
    dataRewardList: { data: [] },
    hasMore: true,
    pagination: {
      offset: 0,
      per_page: 10,
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }
  handleClickLog = () => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_LOG}`);
    // console.log("click");
  };

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchRewardCategoryData(),
      this.fecthRewardList("?category_id=1&per_page=5"),
      this.fetchRewardItemListCompany(),
      this.fetchPopularRewardItems(),
    ]);
    if (res[0] || res[1]) {
      this.setState({
        isLoading: false,
        dataRewardCategory: res[0],
        // dataRewardItemListCompany: res[2],
        dataPopularRewardItems: res[3],
      });
      this.props.setReduxRewardCategory(res[0]);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchRewardCategoryData = async () => {
    let res = await adminService.GET_REWARD_CATEGORIES();
    if (res && res.success) {
      return res.data;
    }
  };

  fecthRewardList = async (stringQuery) => {
    let res = await adminService.GET_REWARD_LIST_BY_CATEGORIES(
      this.props.companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      this.setState((state) => ({
        dataRewardList: res.data.map((e) => ({
          ...e,
          rowSty:
            e.redeemable_count - e.redeemed_count <= 0 ||
            moment(e.end_date).diff(moment().format("YYYY-MM-DD")) < 0
              ? { background: theme.COLORS.BLUE_1 }
              : false,
        })),
        pagination: { ...res.page },
        isReset: false,
        loadSkeleton: false,
      }));
    } else {
      this.setState({
        dataRewardList: { data: [] },
        loadSkeleton: false,
      });
    }
  };

  fetchRewardItemListCompany = async () => {
    let stringQuery = "?sort_by=created_at&per_page=3";
    let res = await adminService.GET_REWARD_ITEM_LIST_COMPANY(
      this.props.companySelectedRedux?.id,
      stringQuery
    );
    if (res && res.success) {
      return res.data;
    }
  };

  fetchPopularRewardItems = async () => {
    let res = await adminService.GET_POPULAR_REWARD_ITEMS(
      this.props.companySelectedRedux?.id
    );

    if (res && res.success) {
      return res.data;
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickSearch = (e) => {
    const { categoryFilter } = this.state;
    let splitCateID = categoryFilter.split("=");
    let queryString = `?category_id=${splitCateID[splitCateID.length - 1]}`;
    if (e) {
      queryString += `&keyword=${e}`;
    }
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_SEARCH}${queryString}`
    );
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };
  handleAddNewReward = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_NEW_DETAIL}/create`
    );
  };
  handleRewardHistory = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_REWARD_HISTORY);
  };
  handleDetailHistory = (e) => {
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD_DETAILS}/${e.id}`);
  };

  //FILTER PROCESS
  //=======================================
  //=======================================
  handleFilterDate = (key, value) => {
    switch (key) {
      case "categoryFilter":
        this.setState(
          {
            categoryFilter: `category_id=${value.id}`,
            isReset: true,
            hasMore: true,
          },
          () => this.handleJoinQuery()
        );
        break;
      case "startDate":
        if (value) {
          this.setState(
            {
              startDateFilter: `end_date_after=${moment(value).format(
                "YYYY-MM-DD"
              )}`,
              inputStartDateFilter: new Date(
                moment(value).format("YYYY-MM-DD")
              ),
              endDateFilter: `begin_date_before=${moment(value)
                .add(30, "days")
                .format("YYYY-MM-DD")}`,
              inputEndDateFilter: new Date(
                moment(value).add(1, "months").format("YYYY-MM-DD")
              ),
              isReset: true,
              hasMore: true,
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              startDateFilter: false,
              endDateFilter: false,
              isReset: true,
              hasMore: true,
            },
            () => this.handleJoinQuery()
          );
        }

        break;
      case "endDate":
        if (value) {
          this.setState(
            {
              endDateFilter: `begin_date_before=${moment(value).format(
                "YYYY-MM-DD"
              )}`,
              isReset: true,
              hasMore: true,
            },
            () => this.handleJoinQuery()
          );
        } else {
          this.setState(
            {
              endDateFilter: false,
              isReset: true,
              hasMore: true,
            },
            () => this.handleJoinQuery()
          );
        }

        break;
      case "sort":
        this.setState(
          {
            isSortFilter: `sort_by=end_date&order_by=${value}`,
            isReset: true,
            hasMore: true,
          },
          () => this.handleJoinQuery()
        );
        break;
      default:
        break;
    }
  };
  handleJoinQuery = () => {
    const { categoryFilter, startDateFilter, endDateFilter, isSortFilter } =
      this.state;
    let queryString = [];
    if (categoryFilter) {
      queryString.push(categoryFilter);
    }
    if (startDateFilter) {
      queryString.push(startDateFilter);
    }
    if (endDateFilter) {
      queryString.push(endDateFilter);
    }
    if (isSortFilter) {
      queryString.push(isSortFilter);
    }
    this.setState({
      loadSkeleton: true,
    });
    if (queryString && queryString.length > 0) {
      this.fecthRewardList(`?${queryString.join("&")}&per_page=5`);
      this.setState({
        queryFilterAll: `${queryString.join("&")}&per_page=5`,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}`);
    } else {
      this.fecthRewardList();
      this.setState({
        queryFilterAll: false,
      });
      this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}`);
    }
  };

  handlePageClick = (e) => {
    const { queryFilterAll } = this.state;
    let query = `?per_page=5&offset=${e.selected * 5}`;
    if (queryFilterAll) query += `&${queryFilterAll}`;
    this.props.history.push(`${ROUTE_PATH.BACK_OFFICE_REWARD}${query}`);
    this.fecthRewardList(query);
  };

  handleClickExchangeReward = (e) => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE}/${e.id}`
    );
  };

  render() {
    const {
      isLoading,
      dataRewardCategory,
      dataRewardList,
      dataPopularRewardItems,
      loadSkeleton,
      inputStartDateFilter,
      pagination,
    } = this.state;

    return (
      <RewardManagmentContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="จัดการรางวัล"
                subTitle="Rewards Management"
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                lineHeight="1.3"
                svg={<Icons.Bonus />}
                onClick={this.handleBackClick}
                typeLog
                handleClickButton={this.handleClickLog}
              />
            </div>
            <div className="serach_panel">
              <div className="search_layout">
                <Inputs.InputSearch
                  theme_standard_search
                  placeholder="Search by Reward name"
                  onSubmit={(e) => this.handleClickSearch(e)}
                />
              </div>
              <div className="add_reward" onClick={this.handleAddNewReward}>
                <Buttons.BtnRoundShadow
                  theme_btn_round_shadow_orange
                  label="เพิ่มรางวัลใหม่"
                  svg={<Icons.Magic />}
                  imgWidth="20px"
                  imgHeight="20px"
                />
              </div>
            </div>
            <div className="layer_two">
              <Typographys.TopBottom
                theme_left_align
                label="รางวัลยอดนิยมประจำสัปดาห์"
                labelBottom="Weekly Popular Rewards"
                fontSizeLabelButtom="14px"
              />
              <div className="group_two">
                <div className="group_l">
                  <div className="pop_show">
                    <WeeklyPopular
                      data={dataPopularRewardItems}
                      handleOnClick={(e) => this.handleDetailHistory(e)}
                    />
                  </div>
                </div>
                <div className="group_r">
                  <MostPicked companyID={this.props.companySelectedRedux?.id} />
                </div>
              </div>
            </div>
            <div className="layer_three">
              <div className="group_line">
                <div className="left_line">
                  <div className="title_list">
                    <div className="icon_list">
                      <Icons.List />
                    </div>
                    <Typographys.TopBottom
                      theme_left_align
                      label="รายชื่อรางวัลทั้งหมด"
                      labelBottom="All Rewards list"
                      fontSizeLabelButtom="14px"
                    />
                  </div>
                </div>
                <div className="category_layout">
                  <Buttons.BtnCategroyFillter
                    theme_standard_category_filter
                    data={dataRewardCategory}
                    // onClick={(e) => this.handleSelectCategory(e)}
                    onClick={(e) => this.handleFilterDate("categoryFilter", e)}
                  />
                </div>
              </div>
            </div>
            <div className="layer_four">
              <div
                className="history_button"
                onClick={this.handleRewardHistory}
              >
                <Buttons.BtnRoundShadow
                  theme_btn_round_shadow_white
                  label="ดูประวัติรางวัล"
                  imgWidth="31px"
                  imgHeight="25px"
                  svg={<Icons.Certicficate />}
                />
              </div>
              <TableCustom.TableNormal
                theme_standard_table_normal
                showTitle="รางวัลในระบบขณะนี้"
                columns={columns({
                  inputStartDateFilter: inputStartDateFilter,
                  endDate: this.state.endDate,
                  handleFilterDate: this.handleFilterDate,
                  handleDetailHistory: this.handleDetailHistory,
                  handleClickExchangeReward: this.handleClickExchangeReward,
                })}
                data={dataRewardList}
                useNo
                useRenderNoPage
                labelEmpty="ไม่มีการแลกรางวัล"
                loading={loadSkeleton}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </RewardManagmentContainerStyled>
    );
  }
}

const columns = ({
  inputStartDateFilter,
  endDate,
  handleFilterDate,
  handleDetailHistory,
  handleClickExchangeReward,
}) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "30px", flexShrink: 0 },
    style: { textAlign: "center", width: "30px", flexShrink: 0 },
  },
  {
    title: "แบรนด์",
    field: "name",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>{value}</div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันเริ่ม
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={inputStartDateFilter || new Date()}
            onChangeCustom={(e) => handleFilterDate("startDate", e)}
          />
        </div>
      </div>
    ),
    field: "begin_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันหมดเขต
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputCalendar
            theme_standard_calendar
            initialValue={endDate || new Date()}
            minDate={inputStartDateFilter}
            onChangeCustom={(e) => handleFilterDate("endDate", e)}
          />
        </div>
      </div>
    ),
    field: "end_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${moment(value).format("D MMMM")} ${moment(value).year() + 543}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <div>วันคงเหลือ</div>
        <div style={{ marginLeft: "16px" }}>
          <Inputs.InputSort
            theme_standard_sort
            onChangeCustom={(e) => handleFilterDate("sort", e)}
          />
        </div>
      </div>
    ),
    field: "remain_date",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${DIF_DATE_2(origin.begin_date, origin.end_date)} วัน`}
      </div>
    ),
  },
  {
    title: "สิทธิ์คงเหลือ",
    field: "remain",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div onClick={() => handleDetailHistory(origin)}>
        {`${
          origin.total_redeemable_count - origin.redeemed_count > 0
            ? origin.total_redeemable_count - origin.redeemed_count
            : 0
        } สิทธิ์`}
      </div>
    ),
  },
  {
    title: "",
    field: "status",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div className="show_new" onClick={() => handleDetailHistory(origin)}>
        New
      </div>
    ),
  },
  {
    title: "",
    field: "button",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <Buttons.BtnNormal
        theme_standard_btn_round_red_bold
        label="แลกของรางวัล"
        fontFamilyBottom="noto_sans_thai_bold, noto_sans_bold;"
        colorBottom="#F0211D"
        fontSize="16px"
        padding="10px"
        onClick={() => handleClickExchangeReward(origin)}
      />
    ),
  },
];

const mapStateToProps = (state) => ({
  rewardCategory: state.rewardCategory,
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxRewardCategory: (data) => dispatch(setReduxRewardCategory(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardManagmentContainer);

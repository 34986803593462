export default function Cooking() {
  return (
    <svg id="cooking" xmlns="http://www.w3.org/2000/svg" width="48" height="45.281" viewBox="0 0 48 45.281">
      <path id="Path_20382" data-name="Path 20382" d="M137.092,291.25c-.678-2.883-2.936-4.235-5.768-4.734a16.019,16.019,0,0,0-2.026-.218,3.246,3.246,0,0,1-1.3-.294c-2.034,3.856-11.335,3.584-12.887,0v-.027a3.218,3.218,0,0,1-1.362.322,16.155,16.155,0,0,0-1.969.207c-2.754.472-4.97,1.741-5.744,4.426,1.2,1.147,10.9,9.9,10.978,10.377h20.379V296.4a1.717,1.717,0,0,1,.61-1.311Zm0,0" transform="translate(-96.091 -259.166)" fill="#64b0d9" fillRule="evenodd" />
      <path id="Path_20383" data-name="Path 20383" d="M64.53,224.209a.73.73,0,0,1-.509-1.252c.688-.671.541-.96.185-1.657a2.522,2.522,0,0,1,.557-3.491.73.73,0,1,1,1,1.063c-.783.737-.629,1.038-.257,1.764A2.467,2.467,0,0,1,65.04,224,.728.728,0,0,1,64.53,224.209Zm0,0" transform="translate(-57.763 -197.209)" fill="#666" />
      <path id="Path_20384" data-name="Path 20384" d="M107.618,182.469a.73.73,0,0,1-.51-1.252c.688-.671.541-.959.183-1.657a2.521,2.521,0,0,1,.556-3.492.73.73,0,0,1,1,1.063c-.783.736-.628,1.037-.256,1.764a2.466,2.466,0,0,1-.464,3.367A.729.729,0,0,1,107.618,182.469Zm0,0" transform="translate(-96.808 -159.382)" fill="#666" />
      <path id="Path_20385" data-name="Path 20385" d="M465.988,259.884a.73.73,0,0,1-.73-.729V247.237a.73.73,0,0,1,1.46,0v11.917A.73.73,0,0,1,465.988,259.884Zm0,0" transform="translate(-421.64 -223.398)" fill="#666" />
      <g id="Group_14446" data-name="Group 14446">
        <path id="Path_20386" data-name="Path 20386" d="M169.266,286.3a16.155,16.155,0,0,0-1.969.207v4.426l1.479,1.327,1.852-1.327v-4.954A3.218,3.218,0,0,1,169.266,286.3Zm0,0" transform="translate(-151.613 -259.166)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_20387" data-name="Path 20387" d="M165.785,300.363h3.965a1.432,1.432,0,0,1,1.411,1.207H188.12l-1.3-7.519a1.447,1.447,0,0,0-1.345-1.349v-5.925a16.011,16.011,0,0,0-2.026-.218,3.244,3.244,0,0,1-1.3-.294V292.7H171.962C171.984,292.866,166.973,299.875,165.785,300.363Zm0,0" transform="translate(-150.243 -259.428)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_20388" data-name="Path 20388" d="M214.118,253.807l-4.638-1.463-5.106,1.436-.014.025a3.445,3.445,0,0,1-1.536,1.692v.027c1.552,3.583,10.853,3.856,12.887,0A3.58,3.58,0,0,1,214.118,253.807Zm0,0" transform="translate(-183.809 -228.687)" fill="#fcc368" fillRule="evenodd" />
        <path id="Path_20389" data-name="Path 20389" d="M191.7,115.053a37.64,37.64,0,0,0-11.144,0q-1.618.241-3.238.627v7.1c0,2.516,1.833,4.737,3.941,6.2a8.916,8.916,0,0,0,4.856,1.823,10.057,10.057,0,0,0,4.889-1.8,8.278,8.278,0,0,0,3.965-6.606v-6.728Q193.341,115.3,191.7,115.053Zm0,0" transform="translate(-160.697 -103.891)" fill="#ffdda6" fillRule="evenodd" />
        <path id="Path_20390" data-name="Path 20390" d="M170.727,0a4.919,4.919,0,0,0-4.385,2.614h-2.391c-3.456,0-5.515,3.8-2.28,5.812V13.71q1.622-.384,3.238-.627a37.614,37.614,0,0,1,11.144,0q1.638.245,3.268.627V8.426c3.235-2.014,1.266-5.812-2.19-5.812h-2.571A3.767,3.767,0,0,0,170.76,0Zm0,0" transform="translate(-145.048)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_20391" data-name="Path 20391" d="M324.915,33.688c3.235-2.014,1.266-5.813-2.19-5.813h-2.018c3.1,0,5.014,2.771,3.037,4.856-.635.668-.836.322-.836,1.256l-.008,4.564q1.009.184,2.015.42Zm0,0" transform="translate(-290.641 -25.262)" fill="#c2c7cc" fillRule="evenodd" />
        <path id="Path_20392" data-name="Path 20392" d="M258.89,175.1a4.925,4.925,0,0,1-2.015-.916v1.766a8.278,8.278,0,0,1-3.968,6.606,10.032,10.032,0,0,1-4.889,1.8c-.055,0-.109.005-.166.005a5.839,5.839,0,0,0,2.184.414,10.057,10.057,0,0,0,4.889-1.8,8.279,8.279,0,0,0,3.965-6.606Zm0,0" transform="translate(-224.616 -157.85)" fill="#fcc368" fillRule="evenodd" />
        <path id="Path_20393" data-name="Path 20393" d="M296.955,269.67a3.579,3.579,0,0,1-1.593-1.717,11.133,11.133,0,0,1-2.889,1.414,3.643,3.643,0,0,0,2.119,1.883,2.812,2.812,0,0,0,.5.182A4.387,4.387,0,0,0,296.955,269.67Zm0,0" transform="translate(-265.053 -242.833)" fill="#eda93b" fillRule="evenodd" />
        <path id="Path_20394" data-name="Path 20394" d="M331.4,291.512c-.678-2.883-2.936-4.235-5.768-4.734a16.018,16.018,0,0,0-2.026-.218,3.246,3.246,0,0,1-1.3-.294,4.386,4.386,0,0,1-1.86,1.763,3.423,3.423,0,0,0,.8.112c.359.019.711.044,1.059.082.33.036.651.079.967.136a10.239,10.239,0,0,1,2.364.7,5.679,5.679,0,0,1,3.407,4.041,35.237,35.237,0,0,1,.948,8.475h1.71v-4.905a1.716,1.716,0,0,1,.61-1.311Zm0,0" transform="translate(-290.404 -259.428)" fill="#4a83c9" fillRule="evenodd" />
        <path id="Path_20395" data-name="Path 20395" d="M343.616,286.778a16.019,16.019,0,0,0-2.026-.218,3.246,3.246,0,0,1-1.3-.294v1.957c.33.036.651.079.967.136a10.239,10.239,0,0,1,2.364.7Zm0,0" transform="translate(-308.383 -259.428)" fill="#c2c7cc" fillRule="evenodd" />
        <path id="Path_20396" data-name="Path 20396" d="M446.608,381.931a3.06,3.06,0,0,0-3.05-3.052h-1.334a1.72,1.72,0,0,0-1.716,1.717v5.518a1.724,1.724,0,0,0,1.716,1.719h1.334a3.06,3.06,0,0,0,3.05-3.052Zm0,0" transform="translate(-399.21 -343.359)" fill="#ffdda6" fillRule="evenodd" />
        <path id="Path_20397" data-name="Path 20397" d="M439.292,141.621h-3.1a2.11,2.11,0,0,0-2.1,2.1v6.355a2.108,2.108,0,0,0,2.1,2.1h3.1a2.111,2.111,0,0,0,2.1-2.1v-6.355a2.115,2.115,0,0,0-2.1-2.1Zm0,0" transform="translate(-393.394 -128.344)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_20398" data-name="Path 20398" d="M358.417,356.275a1.446,1.446,0,0,0-1.345-1.349h-1.939l1.588,8.867h3Zm0,0" transform="translate(-321.839 -321.651)" fill="#c2c7cc" fillRule="evenodd" />
        <path id="Path_20399" data-name="Path 20399" d="M458.244,381.931a3.06,3.06,0,0,0-3.05-3.052H453.86a1.7,1.7,0,0,0-.512.079,3.063,3.063,0,0,1,2.361,2.973v2.851a3.066,3.066,0,0,1-2.361,2.973,1.782,1.782,0,0,0,.512.079h1.335a3.06,3.06,0,0,0,3.05-3.052Zm0,0" transform="translate(-410.846 -343.359)" fill="#fcc368" fillRule="evenodd" />
        <path id="Path_20400" data-name="Path 20400" d="M230.324,340.33a1.745,1.745,0,0,0-1.724-1.51h-2.925c-1.027,0-1.2.968-1.422,1.962-.112.5,2.13,1.055,1.953,1.518H228.6a1.743,1.743,0,0,0,1.74-1.739A1.613,1.613,0,0,0,230.324,340.33Zm0,0" transform="translate(-203.226 -307.056)" fill="#fcc368" fillRule="evenodd" />
        <path id="Path_20401" data-name="Path 20401" d="M4.663,338.82H1.738a1.74,1.74,0,0,0,0,3.48H4.126l2.268-2.153Zm0,0" transform="translate(0 -307.056)" fill="#fcc368" fillRule="evenodd" />
        <path id="Path_20402" data-name="Path 20402" d="M53.927,338.82H39.575c-1.027,0-1.185,1.041-.964,2.038a10.9,10.9,0,0,0,.428,1.442,9.713,9.713,0,0,0,5.6,5.69l3.315,2.071,4.426-2.071a10.092,10.092,0,0,0,5.948-7.132c.221-1,.066-2.038-.964-2.038Zm0,0" transform="translate(-34.912 -307.056)" fill="#f78411" fillRule="evenodd" />
        <path id="Path_20403" data-name="Path 20403" d="M63.85,436.637H52.611a1.431,1.431,0,0,0-1.427,1.428v1.491a1.431,1.431,0,0,0,1.427,1.428h15.2a1.432,1.432,0,0,0,1.427-1.428v-1.491a1.2,1.2,0,0,0-.019-.221,1.432,1.432,0,0,0-1.408-1.207Zm0,0" transform="translate(-46.385 -395.702)" fill="gray" fillRule="evenodd" />
        <path id="Path_20404" data-name="Path 20404" d="M162.479,338.82H159.5c1.027,0,1.185,1.041.964,2.038a10.158,10.158,0,0,1-2.808,5.055l-.131.126a9.884,9.884,0,0,1-3.012,1.951h2.98a9.758,9.758,0,0,0,1.525-.793,10.116,10.116,0,0,0,4.423-6.339,3.252,3.252,0,0,0,.079-.528C163.582,339.526,163.326,338.82,162.479,338.82Zm0,0" transform="translate(-140.03 -307.056)" fill="#f74311" fillRule="evenodd" />
        <path id="Path_20405" data-name="Path 20405" d="M201.2,437.844a1.432,1.432,0,0,0-1.408-1.207h-2.979a1.435,1.435,0,0,1,1.411,1.207,1.517,1.517,0,0,1,.016.221v1.491a1.432,1.432,0,0,1-1.427,1.428h2.979a1.432,1.432,0,0,0,1.427-1.428v-1.491A1.167,1.167,0,0,0,201.2,437.844Zm0,0" transform="translate(-178.358 -395.702)" fill="#666" fillRule="evenodd" />
        <path id="Path_20406" data-name="Path 20406" d="M463.628,142.392a2.1,2.1,0,0,0-1.621-.771h-2.852a2.112,2.112,0,0,1,2.1,2.1v6.355a2.11,2.11,0,0,1-2.1,2.1h2.852a2.111,2.111,0,0,0,2.1-2.1v-6.355A2.091,2.091,0,0,0,463.628,142.392Zm0,0" transform="translate(-416.11 -128.344)" fill="#c2c7cc" fillRule="evenodd" />
        <path id="Path_20407" data-name="Path 20407" d="M333.709,140.185q-1.63-.384-3.268-.627c-.384,3.461,3.05,4.123,3.268,4.161Zm0,0" transform="translate(-299.435 -126.475)" fill="gray" fillRule="evenodd" />
        <path id="Path_20408" data-name="Path 20408" d="M180.558,139.559q-1.618.245-3.238.627v3.529C177.791,143.622,180.923,142.864,180.558,139.559Zm0,0" transform="translate(-160.697 -126.475)" fill="gray" fillRule="evenodd" />
        <path id="Path_20409" data-name="Path 20409" d="M346.109,142.185q-1-.237-2.015-.42V144.8a4.928,4.928,0,0,0,2.015.916Zm0,0" transform="translate(-311.835 -128.475)" fill="#666" fillRule="evenodd" />
      </g>
      <path id="Path_20410" data-name="Path 20410" d="M217.23,171.021a.73.73,0,0,1-.521-1.241,2.653,2.653,0,0,1,1.79-.6h.015a2.683,2.683,0,0,1,1.809.591.73.73,0,0,1-1.036,1.029,1.941,1.941,0,0,0-1.536,0A.725.725,0,0,1,217.23,171.021Zm0,0" transform="translate(-196.203 -153.323)" fill="#666" />
      <path id="Path_20411" data-name="Path 20411" d="M280.295,171.021a.73.73,0,0,1-.521-1.241,2.654,2.654,0,0,1,1.79-.6h.015a2.684,2.684,0,0,1,1.809.591.73.73,0,0,1-1.036,1.029,1.94,1.94,0,0,0-1.535,0A.726.726,0,0,1,280.295,171.021Zm0,0" transform="translate(-253.355 -153.323)" fill="#666" />
      <path id="Path_20412" data-name="Path 20412" d="M263.25,46.792a.73.73,0,0,1-.73-.73V42.71a.73.73,0,0,1,1.46,0v3.352A.73.73,0,0,1,263.25,46.792Zm0,0" transform="translate(-237.908 -38.045)" fill="#c2c7cc" />
      <path id="Path_20413" data-name="Path 20413" d="M210.379,62.953a.73.73,0,0,1-.73-.73V58.874a.73.73,0,0,1,1.46,0v3.349A.73.73,0,0,1,210.379,62.953Zm0,0" transform="translate(-189.994 -52.693)" fill="#c2c7cc" />
      <path id="Path_20414" data-name="Path 20414" d="M316.12,62.953a.73.73,0,0,1-.73-.73V58.874a.73.73,0,0,1,1.46,0v3.349A.73.73,0,0,1,316.12,62.953Zm0,0" transform="translate(-285.823 -52.693)" fill="#c2c7cc" />
    </svg>

  );
}

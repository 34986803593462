import styled from 'styled-components';

export const RewardMostPickedStyled = styled.div`
  // height: 419.8px;
  .top_pick {
    display: flex;
    align-items: center;
    .show_icon {
      margin-right: 16px;
      display: flex;
    }
    .label_show {
      width: 100%;
    }
  }
  .mid_pick {
    // margin-top: 18px;
    // padding-bottom: 24px;
    // height: 100px;
  }
  .bot_pick {
    margin-top: 26px;
    padding: 0% 15%;
  }
  .theme_most_picked {

  }
`;

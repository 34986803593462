export default function Pencil2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.997" viewBox="0 0 24 23.997">
      <g id="pencil" transform="translate(-0.001)" opacity="0.5">
        <path id="Path_20112" data-name="Path 20112" d="M127.354,364.728a.469.469,0,1,0,.663,0A.469.469,0,0,0,127.354,364.728Zm0,0" transform="translate(-121.252 -347.501)" />
        <path id="Path_20113" data-name="Path 20113" d="M282.687,492.887a.469.469,0,0,0,0-.937h-1.875a.469.469,0,0,0,0,.938Zm0,0" transform="translate(-267.202 -468.889)" />
        <path id="Path_20114" data-name="Path 20114" d="M19.2.822,3.119,16.9h0a.494.494,0,0,0-.092.13l-2.98,6.3A.47.47,0,0,0,.47,24H11.736a.469.469,0,1,0,0-.937H2.55l4.415-2.095a.482.482,0,0,0,.13-.091h0L23.177,4.8A2.813,2.813,0,1,0,19.2.822Zm.331,6.3L16.878,4.468l.995-.994,2.652,2.652ZM4.165,21.256,2.738,19.829l.851-1.8L5.963,20.4Zm2.6-1.377L4.113,17.228l12.1-12.1.995.995L7.428,15.9a.469.469,0,1,0,.663.663l9.783-9.776.994.994Zm-4.453.85.953.953-1.81.859Zm20.2-16.593L21.188,5.462,18.536,2.81l1.326-1.325a1.875,1.875,0,0,1,2.652,2.652Zm0,0" transform="translate(0)" />
      </g>
    </svg>
  );
}

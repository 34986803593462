import React from 'react'
import cx from 'classnames'
// import PropTypes from 'prop-types'
import { ShowIconWellbeingStyled } from './styled'

const ShowIconWellbeing = ({ theme_show_icon, svg, label, subLabel, fontColor }) => {
  const customClass = cx({
    theme_show_icon: theme_show_icon,
  })
  return (
    <ShowIconWellbeingStyled fontColor={fontColor}>
      <div className={customClass}>
        <div className='show_svg'>
          {svg}
        </div>
        <div className='group_label'>
          <div>
            {label}
          </div>
          <div className='sub_label'>
            {subLabel}
          </div>
        </div>
      </div>
    </ShowIconWellbeingStyled>
  )
}

ShowIconWellbeing.propTypes = {}

export default ShowIconWellbeing

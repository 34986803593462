export default function ArrowDownCircle({ width = '14', height = '14' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <g
        id="Group_33150"
        data-name="Group 33150"
        transform="translate(-1135.875 -844.875)"
      >
        <circle
          id="Ellipse_2206"
          data-name="Ellipse 2206"
          cx="7"
          cy="7"
          r="7"
          transform="translate(1135.875 844.875)"
          fill="#f0211d"
        />
        <g
          id="_007-up-arrow-1"
          data-name="007-up-arrow-1"
          transform="translate(1146.322 855.885) rotate(180)"
        >
          <g id="Group_2199" data-name="Group 2199">
            <path
              id="Path_5452"
              data-name="Path 5452"
              d="M6.863,4.275,3.491.022a.065.065,0,0,0-.1,0L.014,4.283a.063.063,0,0,0,.049.1H1.311V7.959a.066.066,0,0,0,.068.062H5.513a.06.06,0,0,0,.06-.062V4.385H6.821a.063.063,0,0,0,.042-.11Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

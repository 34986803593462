import styled from 'styled-components';

export const TotalScoreStyled = styled.div`
  .top_like {
    display: flex;
    align-items: center;
    .show_icon {
      margin-right: 16px;
      display: flex;
    }
  }
  .mid_like {
    margin-top: 12px;
    line-height: 1;
    color:  ${({ theme }) => theme.COLORS.GOLD_1};
    .show_num {
      font-size: ${({ theme }) => theme.FONT.SIZE.S36};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      text-align: center;

    }
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: noto_sans_thai_medium, noto_sans_regular;
      text-align: right;
    }
  }
`;

import Avatar from "./Avatar";
import Logout from "./Logout";
import Main from "./Main";
import HeartWell from "./HeartWell";
import MSD from "./MSD";
import Step1 from "./Step1";
import Step from "./Step";
import Setting1 from "./Setting1";
import Setting2 from "./Setting2";
import Graph from "./Graph";
import WellBeing from "./WellBeing";
import BMI from "./BMI";
import BMI1 from "./BMI1";
import BMI2 from "./BMI2";
import Activiness from "./Activiness";
import AccountManagement from "./AccountManagement";
import NewManagement from "./NewManagement";
import RewardManage from "./RewardManage";
import UserLogo from "./UserLogo";
import ArrowLeft from "./ArrowLeft";
import RectArrowUp from "./RectArrowUp";
import RectArrowDown from "./RectArrowDown";
import Company from "./Company";
import ArrowUpBig from "./ArrowUpBig";
import Male from "./Male";
import Female from "./Female";
import PeoplePercent from "./PeoplePercent";
import Percentage from "./Percentage";
import GropPeople from "./GropPeople";
import BuildingCircle from "./BuildingCircle";
import MaleCircle from "./MaleCircle";
import FamaleCircle from "./FamaleCircle";
import Scales from "./Scales";
import MoveChartLeft from "./MoveChartLeft";
import MoveChartRight from "./MoveChartRight";
import Build from "./Build";
import ManMsd from "./ManMsd";
import PersonByStatus from "./PersonByStatus";
import DashCircle from "./DashCircle";
import ArrowUpCircle from "./ArrowUpCircle";
import ArrowDownCircle from "./ArrowDownCircle";
import Run from "./Run";
import NumberOfUse from "./NumberOfUse";
import Control2 from "./Control2";
import OrderTable from "./OrderTable";
import All from "./All";
import New from "./New";
import Nutrition from "./Nutrition";
import Exercise from "./Exercise";
import Other from "./Other";
import CategoryAll from "./CategoryAll";
import CategoryExercise from "./CategoryExercise";
import CategoryNews from "./CategoryNews";
import CategoryNutrition from "./CategoryNutrition";
import CategoryOthers from "./CategoryOthers";
import TypeSound from "./TypeSound";
import TypeVideo from "./TypeVideo";
import TypeBook from "./TypeBook";
import StatusPublic from "./StatusPublic";
import StatusPrivate from "./StatusPrivate";
import Page from "./Page";
import Delete3 from "./Delete3";
import Kid from "./Kid";
import GroupPeople3 from "./GroupPeople3";
import Reward6 from "./Reward6";
import Reward7 from "./Reward7";
import Edit from "./Edit";
import Dumbel2 from "./Dumbel2";
import Heart from "./Heart";
import Dumbel3 from "./Dumbel3";
import Delete from "./Delete";
import Book from "./Book";
import Lock from "./Lock";
import Reward5 from "./Reward5";
import User from "./User";
import Pencil1 from "./Pencil1";
import Pencil2 from "./Pencil2";
import CloseX from "./CloseX";
import AddRound from "./AddRound";
import ArrowDropdown from "./ArrowDropdown";
import Delete2 from "./Delete2";
import Gobal from "./Gobal";
import RoundArrowLeft from "./RoundArrowLeft";
import RoundArrowRight from "./RoundArrowRight";
import Male4 from "./Male4";
import Female3 from "./Female3";
import Plus from "./Plus";
import Excel from "./Excel";
import Add from "./Add";
import AddCircle from "./AddCircle";
import PaddLock from "./PaddLock";
import People from "./People";
import ManGraph from "./ManGraph";
import EmotionSmile from "./EmotionSmile";
import Building from "./Building";
import ManGood from "./ManGood";
import ManSoSo from "./ManSoSo";
import ManBad from "./ManBad";
import WomenGood from "./WomenGood";
import WomenSoSo from "./WomenSoSo";
import WomenBad from "./WomenBad";
import WomenGraph from "./WomenGraph";
import ManGraph2 from "./ManGraph2";
import Male2 from "./Male2";
import Female2 from "./Female2";
import Male3 from "./Male3";
import Smoke from "./Smoke";
import Alcohol from "./Alcohol";
import Sleep from "./Sleep";
import Cook from "./Cook";
import GroupPeople1 from "./GroupPeople1";
import GroupPeople2 from "./GroupPeople2";
import Food from "./Food";
import Success from "./Success";
import Cooking from "./Cooking";
import Cooking2 from "./Cooking2";
import Food2 from "./Food2";
import Popular1 from "./Popular1";
import Food3 from "./Food3";
import Popular2 from "./Popular2";
import Food4 from "./Food4";
import Popular3 from "./Popular3";
import Food5 from "./Food5";
import Popular4 from "./Popular4";
import Building2 from "./Building2";
import Company2 from "./Company2";
import Calendar from "./Calendar";
import Reward from "./Reward";
import Reward2 from "./Reward2";
import Reward3 from "./Reward3";
import MenuMain from "./MenuMain";
import MenuBackOffice from "./MenuBackOffice";
import MenuBMI from "./MenuBMI";
import MenuCook from "./MenuCook";
import MenuMission from "./MenuMission";
import MenuNutrition from "./MenuNutrition";
import MenuStep from "./MenuStep";
import MenuWell from "./MenuWell";
import ActivenessGoal from "./ActivenessGoal";
import ActivenessWalk from "./ActivenessWalk";
import ActivenessGroup from "./ActivenessGroup";
import ActivenessRunning from "./ActivenessRunning";
import Camera from "./Camera";
import Upload from "./Upload";
import Calendar2 from "./Calendar2";
import Dumbel from "./Dumbel";
import Exp from "./Exp";
import Gift from "./Gift";
import Star from "./Star";
import Runner from "./Runner";
import Reward4 from "./Reward4";
import BoxSuccess from "./BoxSuccess";
import BoxFail from "./BoxFail";
import Arrow from "./Arrow";
import Arrow2 from "./Arrow2";
import Article from "./Article";
import News from "./News";
import Bonus from "./Bonus";
import Search from "./Search";
import Bow1 from "./Bow1";
import Bow2 from "./Bow2";
import Bow3 from "./Bow3";
import Reward8 from "./Reward8";
import MostPick from "./MostPick";
import Arrow3 from "./Arrow3";
import Arrow4 from "./Arrow4";
import List from "./List";
import Magic from "./Magic";
import Certicficate from "./Certicficate";
import SearchPage from "./SearchPage";
import Paste from "./Paste";
import PeopleGift from "./PeopleGift";
import RoundHeart from "./RoundHeart";
import GroupPeople4 from "./GroupPeople4";
import Setting from "./Setting";
import Star2 from "./Star2";
import Download from "./Download";
import Control from "./Control";
import ArrowUp from "./ArrowUp";
import Poor from "./Poor";
import Depressed from "./Depressed";
import UserDelete from "./UserDelete";
import Kcal from "./Kcal";
import Cooking3 from "./Cooking3";
import ArrowDown from "./ArrowDown";
import Ribbin from "./Ribbin";
import EmotionVerySmile from "./EmotionVerySmile";
import EmotionSad from "./EmotionSad";
import Enter from "./Enter";
import ManIcon from "./ManIcon";
import WomenIcon from "./WomenIcon";
import SmileIcon from "./SmileIcon";
import CalendarIcon from "./CalendarIcon";
import Smokeing from "./Smokeing";
import Alert from "./Alert";
import ArrowBlueLeft from "./ArrowBlueLeft";
import ArrowBlueRight from "./ArrowBlueRight";
import BMI3 from "./BMI3";
import Fire from "./Fire";
import MSD2 from "./MSD2";
import Gear from "./Gear";
import Runner2 from "./Runner2";
import Check from "./Check";
import Wrong from "./Wrong";
import DownloadDeleteAccount from "./DownloadDeleteAccount";
import ArrowUpDown from "./ArrowUpDown";
import Calendar4 from "./Calendar4";
import Download2 from "./Download2";
import Download3 from "./Download3";
import CloseXModal from "./CloseXModal";
import RewardPoint from "./RewardPoint";
import Points from "./Points";
import CompanyMission from "./CompanyMission";
import Log from "./Log";
import RoundAdd from "./RoundAdd";
import RoundDel from "./RoundDel";
import Reward9 from "./Reward9";
import Run2 from "./Run2";
import Copying from "./Copying";
import BackOfficeUserLogo from "./BackOfficeUserLogo";
import Men from "./Men";
import Women from "./Women";
import AllKid from "./AllKid";
import ThreeMan from "./ThreeMan";
import LuckSpin from "./LuckSpin";
import Search2 from "./Search2";
import Sort from "./Sort";
import IconsEdit from "./IconsEdit";
import IconCopy from "./IconCopy";
import Delete4 from "./Delete4";
import ExchangeLucky from "./ExchangeLucky";
import HealthCheck from "./HealthCheck";
import HealthCheckOverView from "./HealthCheckOverView";
import Download4 from "./Download4";
import RewardDel from "./RewardDel";
import RewardEdit from "./RewardEdit";
import Page2 from "./Page2";
import Profile from "./Profile";
import CloseX2 from "./CloseX2";
import Logout2 from "./Logout2";
import Setting3 from "./Setting3";
import EyeClose from "./EyeClose";
import EyeOpen from "./EyeOpen";
import ClockTime from "./ClockTime";
import CloseX3 from "./CloseX3";
import CheckCorrect from "./CheckCorrect";
import Round from "./Round";
import RoundArrowLeft2 from "./RoundArrowLeft2";
import Delete5 from "./Delete5";
import Disk from "./Disk";
import Sync from "./Sync";
import RoundCheck from "./RoundCheck";
import Pencil3 from "./Pencil3";
import CopyLink from "./CopyLink";
import Info from "./Info";
import DotThree from "./DotThree";
import Alert2 from "./Alert2";
import RoundAdd2 from "./RoundAdd2";
import Calendar3 from "./Calendar3";
import AlertRound from "./AlertRound";
import LabCriteria from "./LabCriteria";
import Warn from "./Warn";

const Export = {
  Warn,
  AlertRound,
  Calendar3,
  Alert2,
  RoundAdd2,
  DotThree,
  Info,
  CopyLink,
  Pencil3,
  RoundCheck,
  Sync,
  Disk,
  Delete5,
  RoundArrowLeft2,
  Round,
  CheckCorrect,
  CloseX3,
  ClockTime,
  EyeOpen,
  EyeClose,
  Setting3,
  Logout2,
  CloseX2,
  Profile,
  Page2,
  RewardEdit,
  RewardDel,
  Download4,
  HealthCheckOverView,
  HealthCheck,
  ExchangeLucky,
  Delete4,
  IconCopy,
  IconsEdit,
  Sort,
  Search2,
  LuckSpin,
  ThreeMan,
  AllKid,
  Men,
  Women,
  BackOfficeUserLogo,
  Run2,
  Copying,
  Reward9,
  Log,
  RoundAdd,
  RoundDel,
  RewardPoint,
  Points,
  CompanyMission,
  Download3,
  CloseXModal,
  Download2,
  Calendar4,
  ArrowUpDown,
  Wrong,
  Check,
  Runner2,
  Gear,
  MSD2,
  Fire,
  BMI3,
  BMI2,
  ArrowBlueRight,
  ArrowBlueLeft,
  Alert,
  Smokeing,
  CalendarIcon,
  SmileIcon,
  WomenIcon,
  ManIcon,
  Enter,
  NumberOfUse,
  ManMsd,
  Build,
  GropPeople,
  Company,
  Avatar,
  Logout,
  Main,
  HeartWell,
  MSD,
  Step1,
  Setting1,
  Setting2,
  Graph,
  WellBeing,
  BMI,
  BMI1,
  Activiness,
  AccountManagement,
  NewManagement,
  RewardManage,
  UserLogo,
  ArrowLeft,
  RectArrowUp,
  RectArrowDown,
  ArrowUpBig,
  Male,
  Step,
  Female,
  PeoplePercent,
  Percentage,
  BuildingCircle,
  MaleCircle,
  FamaleCircle,
  Scales,
  MoveChartLeft,
  MoveChartRight,
  PersonByStatus,
  DashCircle,
  ArrowUpCircle,
  ArrowDownCircle,
  Run,
  Control2,
  OrderTable,
  All,
  New,
  Nutrition,
  Exercise,
  Other,
  CategoryAll,
  CategoryExercise,
  CategoryNews,
  CategoryNutrition,
  CategoryOthers,
  TypeSound,
  TypeVideo,
  TypeBook,
  StatusPrivate,
  StatusPublic,
  Page,
  Delete3,
  Kid,
  GroupPeople3,
  Reward6,
  Reward7,
  Edit,
  Dumbel2,
  Heart,
  Dumbel3,
  Delete,
  Book,
  Lock,
  Reward5,
  User,
  Pencil1,
  Pencil2,
  CloseX,
  AddRound,
  ArrowDropdown,
  Delete2,
  Gobal,
  RoundArrowLeft,
  RoundArrowRight,
  Male4,
  Female3,
  Plus,
  Excel,
  Add,
  AddCircle,
  PaddLock,
  People,
  ManGraph,
  EmotionSmile,
  Building,
  ManGood,
  ManSoSo,
  ManBad,
  WomenGood,
  WomenSoSo,
  WomenBad,
  WomenGraph,
  ManGraph2,
  Male2,
  Female2,
  Male3,
  Smoke,
  Alcohol,
  Sleep,
  Cook,
  GroupPeople1,
  GroupPeople2,
  Food,
  Success,
  Cooking,
  Cooking2,
  Food2,
  Popular1,
  Food3,
  Popular2,
  Food4,
  Popular3,
  Food5,
  Popular4,
  Building2,
  Company2,
  Calendar,
  Reward,
  Reward2,
  Reward3,
  MenuMain,
  MenuBackOffice,
  MenuBMI,
  MenuCook,
  MenuMission,
  MenuNutrition,
  MenuStep,
  MenuWell,
  ActivenessGoal,
  ActivenessWalk,
  ActivenessGroup,
  ActivenessRunning,
  Camera,
  Upload,
  Calendar2,
  Dumbel,
  Exp,
  Gift,
  Star,
  Reward4,
  Runner,
  BoxSuccess,
  BoxFail,
  Arrow,
  Arrow2,
  Article,
  News,
  Bonus,
  Search,
  Bow1,
  Bow2,
  Bow3,
  Reward8,
  MostPick,
  Arrow3,
  Arrow4,
  List,
  Magic,
  Certicficate,
  SearchPage,
  Paste,
  PeopleGift,
  RoundHeart,
  GroupPeople4,
  Setting,
  Star2,
  Download,
  Control,
  ArrowUp,
  Poor,
  Depressed,
  UserDelete,
  Kcal,
  Cooking3,
  ArrowDown,
  Ribbin,
  EmotionVerySmile,
  EmotionSad,
  DownloadDeleteAccount,
  LabCriteria,
};

export default Export;

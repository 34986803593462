import styled from "styled-components";

export const MeanRateStyled = styled.div`
  .card_title {
    line-height: 20px;
    margin-bottom: 20px;
    .txt_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: noto_sans_thai_medium, noto_sans_bold;
    }
    .txt_sub_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: noto_sans_thai_medium, noto_sans_bold;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  .card_content {
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .detail_block {
      display: flex;
      align-items: center;
      .txt_percent {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.BLACK};
        font-size: ${({ theme }) => theme.FONT.SIZE.S40};
        font-family: noto_sans_bold;
        margin-right: 22px;
      }
      .txt_row {
        display: flex;
        align-items: center;
        .group_text {
          .txt_top {
            font-size: ${({ theme }) => theme.FONT.SIZE.S10};
            color: ${({ theme }) => theme.COLORS.BLACK_3};
          }
          .txt_bottom {
            font-size: ${({ theme }) => theme.FONT.SIZE.S8};
            color: ${({ theme }) => theme.COLORS.BLACK_3};
          }
        }
        .txt_price {
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          color: ${({ theme }) => theme.COLORS.BLACK_3};
          font-family: noto_sans_bold;
          margin: 0 14px;
        }
      }
    }
  }

  .theme_standard {
  }
`;

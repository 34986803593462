export default function Male2({ color = '#003c58' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.661" height="48" viewBox="0 0 17.661 48">
      <g id="Group_15164" data-name="Group 15164" transform="translate(-614.339 -1200)">
        <g id="Group_15162" data-name="Group 15162" transform="translate(614.339 1200)">
          <g id="Group_77" data-name="Group 77">
            <path id="Path_41" data-name="Path 41" d="M233.677,36.994a5,5,0,1,1-5-5A5,5,0,0,1,233.677,36.994Z" transform="translate(-219.85 -31.997)" fill="#003c58" />
            <path id="Path_42" data-name="Path 42" d="M229.771,61.789H215.195a1.543,1.543,0,0,0-1.543,1.542V78.489a1.586,1.586,0,0,0,1.5,1.607,1.543,1.543,0,0,0,1.59-1.542V65.248a.374.374,0,0,1,.374-.374h0a.374.374,0,0,1,.374.374V96.37a2.011,2.011,0,0,0,1.835,2.029,1.966,1.966,0,0,0,2.1-1.961V80.1h2.13V96.37a2.01,2.01,0,0,0,1.835,2.029,1.966,1.966,0,0,0,2.1-1.961V65.248a.374.374,0,0,1,.375-.374h0a.374.374,0,0,1,.374.374v13.24a1.586,1.586,0,0,0,1.5,1.607,1.542,1.542,0,0,0,1.59-1.542V63.331A1.542,1.542,0,0,0,229.771,61.789Z" transform="translate(-213.652 -50.403)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}

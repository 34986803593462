import React, { useEffect, useState } from "react";
import { MainlayoutStyled } from "./styled";
import { Route, Switch } from "react-router-dom";
import "moment/locale/th.js";
import { MemberAction, NavBar } from "widgets";
import { useSelector } from "react-redux";
import { renderConfigSystemFN } from "utils/functions/renderCofigSystem";

const Mainlayout = () => {
  const { authenRedux, companySelectedRedux } = useSelector((state) => state);
  const [system, setSystem] = useState();

  useEffect(() => {
    if (authenRedux.role) {
      setSystem(
        renderConfigSystemFN(authenRedux, undefined, companySelectedRedux)
      );
    }
  }, [authenRedux, companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainlayoutStyled>
      {system && (
        <>
          <div className="member_action_layout">
            <div className="logo_layout">
              {system.headerLogo && (
                <img
                  className="icon_show_2"
                  src={system.headerLogo}
                  alt="img"
                />
              )}
              {system.headerLabel && (
                <div className="name_company green">{system.headerLabel}</div>
              )}
            </div>
            <MemberAction routes={system.routes} />
          </div>
          <div className="navbar_layout">
            <NavBar theme_standard_navbar routes={system.routes} />
          </div>
          <div className="body_main_layout">
            <Switch>
              {system.routes.map((e, i) => (
                <Route
                  key={i}
                  exact={e.exact}
                  path={e.path}
                  component={e.component}
                />
              ))}
            </Switch>
          </div>
        </>
      )}
    </MainlayoutStyled>
  );
};

export default Mainlayout;

import styled from 'styled-components';

export const DepartmentCodeContainerStyled = styled.div`
  .body_department {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    border-radius: 5px;
    width: 508px;
    padding: 20px;
    .title {
      display: flex;
      justify-content: center;
      position: relative;
      .svg_c {
        position: absolute;
        top: -6px;
        right: -6px;
        cursor: pointer;
      }
    }
    .table_department {
      margin-top: 10px;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cccccc;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #c2c2c2;
      }
      ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #ebebeb;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }

      .head_show {
        display: flex;
        margin-bottom: 17px;
        justify-content: space-between;
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        .h1 {
          width: 30%;
        }
        .h2 {
          width: 68%;
        }
      }
      .row_show {
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        height: 210px;
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .group_row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .r1 {
            width: 30%;
          }
          .r2 {
            width: 68%;
          }
        }
      }
    }
  }
`;

export default function NewManagement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114.451"
      height="120"
      viewBox="0 0 114.451 120"
    >
      <g
        id="Group_10783"
        data-name="Group 10783"
        transform="translate(434.036 -7821.38)"
      >
        <rect
          id="Rectangle_17898"
          data-name="Rectangle 17898"
          width="90.744"
          height="98.834"
          transform="matrix(-0.961, -0.275, 0.275, -0.961, -346.804, 7941.38)"
          fill="#e6e9ee"
        />
        <rect
          id="Rectangle_17899"
          data-name="Rectangle 17899"
          width="89.336"
          height="97.578"
          transform="translate(-421.473 7832.584)"
          fill="#fffae5"
        />
        <g
          id="Group_10782"
          data-name="Group 10782"
          transform="translate(-412.408 7841.616)"
        >
          <path
            id="Path_12448"
            data-name="Path 12448"
            d="M181.1,159.9h-3.823l-6.912-10.541a12.291,12.291,0,0,1-.85-1.39h-.039a24.978,24.978,0,0,1,.077,2.7V159.9H166V143.139h4.054l6.642,10.194c.309.463.579.927.849,1.351h.039a16.342,16.342,0,0,1-.077-2.317V143.1h3.552v16.8Z"
            transform="translate(-163.606 -142.945)"
            fill="#324a5e"
          />
          <path
            id="Path_12449"
            data-name="Path 12449"
            d="M225.14,159.958H215.1V143.2h9.653v3.089h-5.908v3.745h5.483v3.051h-5.483v3.823h6.294Z"
            transform="translate(-193.747 -143.007)"
            fill="#324a5e"
          />
          <path
            id="Path_12450"
            data-name="Path 12450"
            d="M267.27,143.2l-4.441,16.758h-4.17l-2.78-10.773a9.306,9.306,0,0,1-.27-1.892h-.039a10.152,10.152,0,0,1-.309,1.892L252.4,159.958H248L243.6,143.2h4.132l2.355,11.159a10.522,10.522,0,0,1,.232,1.931h.077a9.629,9.629,0,0,1,.347-1.969l3.051-11.121h4.054l2.742,11.275a11.547,11.547,0,0,1,.27,1.815h.039a15.187,15.187,0,0,1,.232-1.853l2.317-11.2h3.823Z"
            transform="translate(-211.242 -143.007)"
            fill="#324a5e"
          />
          <path
            id="Path_12451"
            data-name="Path 12451"
            d="M308.277,159.034v-3.746a7.1,7.1,0,0,0,2.2,1.274,7.455,7.455,0,0,0,2.394.425,5.848,5.848,0,0,0,1.236-.116,2.669,2.669,0,0,0,.888-.348,1.8,1.8,0,0,0,.541-.541,1.3,1.3,0,0,0,.193-.656,1.5,1.5,0,0,0-.27-.888,2.265,2.265,0,0,0-.772-.7,5.381,5.381,0,0,0-1.159-.618c-.463-.193-.927-.425-1.429-.618a7.164,7.164,0,0,1-2.935-2.008,4.27,4.27,0,0,1-.965-2.857,4.47,4.47,0,0,1,.541-2.24,4.214,4.214,0,0,1,1.429-1.545,7.232,7.232,0,0,1,2.085-.888,10.646,10.646,0,0,1,2.51-.27,13.672,13.672,0,0,1,2.317.154,8.667,8.667,0,0,1,1.854.5v3.514a5.245,5.245,0,0,0-.927-.5,10.714,10.714,0,0,0-1-.386,10.092,10.092,0,0,0-1.043-.232,9.5,9.5,0,0,0-1-.077,5.349,5.349,0,0,0-1.158.116,2.667,2.667,0,0,0-.888.347,1.938,1.938,0,0,0-.579.541,1.334,1.334,0,0,0-.193.7,1.392,1.392,0,0,0,.232.772,2.344,2.344,0,0,0,.618.618,7.711,7.711,0,0,0,1,.579c.386.193.849.386,1.313.579a15.911,15.911,0,0,1,1.815.888,5.585,5.585,0,0,1,1.39,1.081,4.56,4.56,0,0,1,.888,1.351,5.027,5.027,0,0,1,.309,1.776,4.828,4.828,0,0,1-.541,2.355,4.569,4.569,0,0,1-1.429,1.544,6.129,6.129,0,0,1-2.124.85,13.565,13.565,0,0,1-2.549.27,14.436,14.436,0,0,1-2.626-.232A10.708,10.708,0,0,1,308.277,159.034Z"
            transform="translate(-250.897 -142.7)"
            fill="#324a5e"
          />
          <rect
            id="Rectangle_17900"
            data-name="Rectangle 17900"
            width="71.203"
            height="4.325"
            transform="translate(0 26.952)"
            fill="#324a5e"
          />
          <rect
            id="Rectangle_17901"
            data-name="Rectangle 17901"
            width="71.203"
            height="4.325"
            transform="translate(0 40.042)"
            fill="#324a5e"
          />
          <rect
            id="Rectangle_17902"
            data-name="Rectangle 17902"
            width="37.919"
            height="4.325"
            transform="translate(0 53.132)"
            fill="#324a5e"
          />
          <rect
            id="Rectangle_17903"
            data-name="Rectangle 17903"
            width="37.919"
            height="4.325"
            transform="translate(0 66.184)"
            fill="#324a5e"
          />
          <rect
            id="Rectangle_17904"
            data-name="Rectangle 17904"
            width="37.919"
            height="4.325"
            transform="translate(0 79.274)"
            fill="#324a5e"
          />
        </g>
        <rect
          id="Rectangle_17905"
          data-name="Rectangle 17905"
          width="27.029"
          height="30.505"
          transform="translate(-369.83 7890.367)"
          fill="#ffd05b"
        />
      </g>
    </svg>
  );
}

export default function Reward8() {
  return (
    <svg id="Group_24722" data-name="Group 24722" xmlns="http://www.w3.org/2000/svg" width="38.029" height="38.029" viewBox="0 0 38.029 38.029">
      <g id="Group_24709" data-name="Group 24709" transform="translate(13.582 25.806)">
        <g id="Group_24706" data-name="Group 24706">
          <path id="Path_43922" data-name="Path 43922" d="M34.866,42V53.541a.681.681,0,0,1-.985.611l-4.142-2.078a.722.722,0,0,0-.611,0l-4.142,2.078A.681.681,0,0,1,24,53.541V42Z" transform="translate(-24 -42)" fill="#00a0c8" />
        </g>
        <g id="Group_24707" data-name="Group 24707" transform="translate(5.433)">
          <path id="Path_43923" data-name="Path 43923" d="M37.433,42V53.541a.681.681,0,0,1-.985.611l-4.142-2.078A.671.671,0,0,0,32,52.006V42Z" transform="translate(-32 -42)" fill="#c80a50" />
        </g>
        <g id="Group_24708" data-name="Group 24708" transform="translate(5.433)">
          <path id="Path_43924" data-name="Path 43924" d="M37.433,42v2.94A13.53,13.53,0,0,1,32,46.075V42Z" transform="translate(-32 -42)" fill="#a00028" />
        </g>
      </g>
      <g id="Group_24710" data-name="Group 24710" transform="translate(13.582 25.806)">
        <path id="Path_43925" data-name="Path 43925" d="M29.433,42v4.075A13.53,13.53,0,0,1,24,44.94V42Z" transform="translate(-24 -42)" fill="#0082aa" />
      </g>
      <g id="Group_24711" data-name="Group 24711" transform="translate(5.586 1.523)">
        <ellipse id="Ellipse_3704" data-name="Ellipse 3704" cx="13.558" cy="13.406" rx="13.558" ry="13.406" fill="#fab400" />
      </g>
      <g id="Group_24712" data-name="Group 24712" transform="translate(5.433 14.281)">
        <path id="Path_43926" data-name="Path 43926" d="M39.164,25.689a13.582,13.582,0,1,1-27.164,0c0-.217.007-.441.02-.659a13.579,13.579,0,0,0,27.123,0C39.157,25.247,39.164,25.471,39.164,25.689Z" transform="translate(-12 -25.03)" fill="#dc9600" />
      </g>
      <g id="Group_24714" data-name="Group 24714" transform="translate(29.88 0)">
        <g id="Group_24713" data-name="Group 24713">
          <path id="Path_43927" data-name="Path 43927" d="M55.47,6.716H53.433V4.679A.679.679,0,0,0,52.754,4H51.4a.679.679,0,0,0-.679.679V6.716H48.679A.679.679,0,0,0,48,7.4V8.754a.679.679,0,0,0,.679.679h2.037V11.47a.679.679,0,0,0,.679.679h1.358a.679.679,0,0,0,.679-.679V9.433H55.47a.679.679,0,0,0,.679-.679V7.4A.679.679,0,0,0,55.47,6.716Z" transform="translate(-48 -4)" fill="#fab400" />
        </g>
      </g>
      <g id="Group_24716" data-name="Group 24716" transform="translate(0 24.447)">
        <g id="Group_24715" data-name="Group 24715">
          <path id="Path_43928" data-name="Path 43928" d="M11.47,42.716H9.433V40.679A.679.679,0,0,0,8.754,40H7.4a.679.679,0,0,0-.679.679v2.037H4.679A.679.679,0,0,0,4,43.4v1.358a.679.679,0,0,0,.679.679H6.716V47.47a.679.679,0,0,0,.679.679H8.754a.679.679,0,0,0,.679-.679V45.433H11.47a.679.679,0,0,0,.679-.679V43.4A.679.679,0,0,0,11.47,42.716Z" transform="translate(-4 -40)" fill="#fab400" />
        </g>
      </g>
      <g id="Group_24717" data-name="Group 24717" transform="translate(9.547 5.484)">
        <circle id="Ellipse_3705" data-name="Ellipse 3705" cx="9.445" cy="9.445" r="9.445" fill="#f0f0f0" />
      </g>
      <g id="Group_24718" data-name="Group 24718" transform="translate(12.982 9.128)">
        <path id="Path_43929" data-name="Path 43929" d="M29.753,17.816l1.343,2.72a.673.673,0,0,0,.507.368l3,.436a.673.673,0,0,1,.373,1.148L32.8,24.6a.673.673,0,0,0-.194.6l.513,2.989a.673.673,0,0,1-.977.71l-2.685-1.412a.672.672,0,0,0-.626,0L26.15,28.9a.673.673,0,0,1-.977-.71l.513-2.989a.672.672,0,0,0-.194-.6l-2.172-2.117a.673.673,0,0,1,.373-1.148l3-.436a.673.673,0,0,0,.507-.368l1.343-2.72a.675.675,0,0,1,1.209,0Z" transform="translate(-23.117 -17.441)" fill="#fab400" />
      </g>
      <g id="Group_24719" data-name="Group 24719" transform="translate(12.985 13.202)">
        <path id="Path_43930" data-name="Path 43930" d="M25.5,26.53l-2.173-2.119a.668.668,0,0,1,.014-.971l2.16,2.112a.674.674,0,0,1,.19.6l-.088.509a.609.609,0,0,0-.1-.129Z" transform="translate(-23.122 -23.44)" fill="#dc9600" />
      </g>
      <g id="Group_24720" data-name="Group 24720" transform="translate(15.032 18.118)">
        <path id="Path_43931" data-name="Path 43931" d="M34.091,32.439a.675.675,0,0,1-.978.713L30.43,31.739a.677.677,0,0,0-.625,0l-2.682,1.413a.675.675,0,0,1-.978-.713l.088-.516a.664.664,0,0,0,.89.244l2.682-1.406a.639.639,0,0,1,.625,0l2.682,1.406a.664.664,0,0,0,.89-.244Z" transform="translate(-26.135 -30.68)" fill="#dc9600" />
      </g>
      <g id="Group_24721" data-name="Group 24721" transform="translate(22.467 13.202)">
        <path id="Path_43932" data-name="Path 43932" d="M39.458,24.411,37.285,26.53a.608.608,0,0,0-.1.129l-.088-.509a.674.674,0,0,1,.19-.6l2.16-2.112a.668.668,0,0,1,.014.971Z" transform="translate(-37.084 -23.44)" fill="#dc9600" />
      </g>
    </svg>
  );
}

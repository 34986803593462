import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { RadioStyled } from "./styled";

const Radio = ({
  theme_standard_radio,
  theme_standard_green,
  theme_column,
  value,
  label,
  options,
  name,
  errors,
  onChange,
  onChaneCustom,
  flexDirection,
  rowGap,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_radio: theme_standard_radio,
    theme_column: theme_column,
    theme_standard_green: theme_standard_green,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (value && value !== _value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onChange = (e) => {
    _setValue(e.target.value);
    onChange && onChange(e.target.value);
    onChaneCustom && onChaneCustom(e.target.value);
  };

  return (
    <RadioStyled
      disabled={disabled}
      rowGap={rowGap}
      flexDirection={flexDirection}
    >
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {options &&
          options.map((e, i) => (
            <div key={i} className="radio_input_item">
              <div className="group_show_radio">
                <input
                  className="radio_input"
                  type="radio"
                  id={`${name || ""}${e.value}`}
                  name={name}
                  value={e.value}
                  onChange={_onChange}
                  checked={_value === e.value}
                  disabled={disabled || e.disabled}
                />
              </div>
              <label
                className="radio_label"
                htmlFor={`${name || ""}${e.value}`}
              >
                <div className="hText">{e.label}</div>
                <div className="sText">{e.subLabel}</div>
              </label>
              {e.subDetail && (
                <div className="radio_sub_detial">{e.subDetail}</div>
              )}
            </div>
          ))}
        {errors && <div className="errors">{errors}</div>}
      </div>
    </RadioStyled>
  );
};

Radio.propTypes = {};

export default Radio;

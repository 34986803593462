import React, { useEffect, useState } from "react";
import { MissionContainerStyled } from "./styled";
import { Label, Icons, TabBarSelect, Buttons } from "components";
import { ROUTE_PATH } from "utils/constants";
import {
  MissionHistoryContainer,
  CurrentMissionContainer,
  CreateEditMissionContainer,
} from "./Tabs";

import { useHistory } from "react-router-dom";
import { getObjectKey } from "utils/functions/getObjectKey";
import { useSelector } from "react-redux";
import { useRememberFilter } from "utils/hooks/useRememberFilter";

const MissionContainer = () => {
  let history = useHistory();
  const [activeTab, setActiveTab] = useState();
  const [perMissions, setPerMissions] = useState();
  const { authenRedux } = useSelector((state) => state);
  const { searchParams, setSearchParams } = useRememberFilter();
  const [selectPage, setSelectPage] = useState(searchParams);

  useEffect(() => {
    scrollToTop();
    handleActiveTabMenu();
    checkPermission();
    setSearchParams(selectPage);
  }, [selectPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleActiveTabMenu = () => {
    switch (selectPage?.page) {
      case "currentmission":
        setActiveTab(0);
        break;
      case "createeditmission":
        setActiveTab(1);
        break;
      case "historymission":
        setActiveTab(2);
        break;
      default:
        setActiveTab(0);
        break;
    }
  };

  const checkPermission = () => {
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "company_mission_manage"
    );
    setPerMissions(checkPermission);
  };

  const handleClickSeletedTab = (e) => {
    setSelectPage({ page: e.path });
    history.push(`${ROUTE_PATH.BACK_OFFICE_MISSION}`);
  };

  const handleClickCreate = () => {
    let pageTemp = selectPage.page ? selectPage.page : "currentmission";
    history.push(`${ROUTE_PATH.BACK_OFFICE_MISSION}/create?from=${pageTemp}`);
  };

  const handleClickBack = () => {
    history.push(`${ROUTE_PATH.BACK_OFFICE}`);
  };

  return (
    <MissionContainerStyled>
      <div className="title_layout">
        <Buttons.BtnLink
          theme_standard_btn_link
          svg={<Icons.ArrowLeft />}
          label="ย้อนกลับ"
          onClick={handleClickBack}
        />
        <Label.LabelIcon
          theme_standard_label_icon
          label="Mission"
          svgFront={<Icons.Building2 />}
          fontColor="#575D96"
        />
      </div>
      {(activeTab || activeTab === 0) && (
        <TabBarSelect
          tab_standard_tab_bar_select
          initialValue={activeTab}
          tabHeader={headerTab(history, selectPage?.page)}
          showBtn2={perMissions ? true : false}
          onClickSeletedTab={handleClickSeletedTab}
          onClick={handleClickCreate}
        />
      )}
    </MissionContainerStyled>
  );
};

const headerTab = (LinkHistory, page) => [
  {
    label: "ภารกิจปัจจุบัน",
    labelBottom: "Current Mission",
    path: "currentmission",
    component: <CurrentMissionContainer />,
  },
  {
    label: "จัดการภารกิจ",
    labelBottom: "Create/ Edit Mission",
    path: "createeditmission",
    component: (
      <CreateEditMissionContainer LinkHistory={LinkHistory} page={page} />
    ),
  },
  {
    label: "ประวัติภารกิจ",
    labelBottom: "Mission History",
    path: "historymission",
    component: <MissionHistoryContainer />,
  },
];

export default MissionContainer;

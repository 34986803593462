export default function List() {
  return (
    <svg id="Group_24042" data-name="Group 24042" xmlns="http://www.w3.org/2000/svg" width="29.651" height="34.594" viewBox="0 0 29.651 34.594">
      <g id="Group_24012" data-name="Group 24012" transform="translate(0 1.235)">
        <path id="Path_43394" data-name="Path 43394" d="M32.71,8.471V36.887a2.47,2.47,0,0,1-2.471,2.471H10.471A2.47,2.47,0,0,1,8,36.887V8.471A2.47,2.47,0,0,1,10.471,6H30.239A2.47,2.47,0,0,1,32.71,8.471Z" transform="translate(-8 -6)" fill="#0a5078" />
      </g>
      <g id="Group_24013" data-name="Group 24013" transform="translate(3.706 4.942)">
        <path id="Path_43395" data-name="Path 43395" d="M33.768,12.618V39.8a.62.62,0,0,1-.618.618H14.618A.62.62,0,0,1,14,39.8v-.618H31.914a.62.62,0,0,0,.618-.618V12h.618A.62.62,0,0,1,33.768,12.618Z" transform="translate(-14 -12)" fill="#00325a" />
      </g>
      <g id="Group_24014" data-name="Group 24014" transform="translate(2.471 3.706)">
        <path id="Path_43396" data-name="Path 43396" d="M31.768,10.618V37.8a.62.62,0,0,1-.618.618H12.618A.62.62,0,0,1,12,37.8V10.618A.62.62,0,0,1,12.618,10H31.15A.62.62,0,0,1,31.768,10.618Z" transform="translate(-12 -10)" fill="#f0f0f0" />
      </g>
      <g id="Group_24015" data-name="Group 24015" transform="translate(6.177 1.235)">
        <path id="Path_43397" data-name="Path 43397" d="M18,6h1.235V8.471H18Z" transform="translate(-18 -6)" fill="#00325a" />
      </g>
      <g id="Group_24016" data-name="Group 24016" transform="translate(19.768 1.235)">
        <path id="Path_43398" data-name="Path 43398" d="M40,6h1.235V8.471H40Z" transform="translate(-40 -6)" fill="#00325a" />
      </g>
      <g id="Group_24017" data-name="Group 24017" transform="translate(6.177 3.706)">
        <path id="Path_43399" data-name="Path 43399" d="M32.826,10v3.706a1.239,1.239,0,0,1-1.235,1.235H19.235A1.239,1.239,0,0,1,18,13.706V10h1.235v1.853a.62.62,0,0,0,.618.618H30.973a.62.62,0,0,0,.618-.618V10Z" transform="translate(-18 -10)" fill="#d2d2d2" />
      </g>
      <g id="Group_24018" data-name="Group 24018" transform="translate(22.239 18.381)">
        <path id="Path_43400" data-name="Path 43400" d="M46.471,33.755v5.238L44,41.464V36.226Z" transform="translate(-44 -33.755)" fill="#00325a" />
      </g>
      <g id="Group_24019" data-name="Group 24019" transform="translate(14.694 20.852)">
        <path id="Path_43401" data-name="Path 43401" d="M39.331,37.755v5.238l-3.972,3.978c-2.323.772-.432.142-2.761.92a.618.618,0,0,1-.778-.785c.982-2.953.1-.278.92-2.755Z" transform="translate(-31.787 -37.755)" fill="#d2d2d2" />
      </g>
      <g id="Group_24020" data-name="Group 24020" transform="translate(15.647 16.932)">
        <path id="Path_43402" data-name="Path 43402" d="M45.228,34.035c-8.056,8.048-5.941,5.935-9.278,9.272L33.33,40.688c4.891-4.891,2.833-2.832,9.272-9.278Z" transform="translate(-33.33 -31.41)" fill="#fab400" />
      </g>
      <g id="Group_24021" data-name="Group 24021" transform="translate(14.694 26.211)">
        <path id="Path_43403" data-name="Path 43403" d="M35.359,49.049l-2.761.92a.615.615,0,0,1-.778-.778c.719-2.149.516-1.539.92-2.761Z" transform="translate(-31.787 -46.43)" fill="#f2c8a0" />
      </g>
      <g id="Group_24022" data-name="Group 24022" transform="translate(14.693 27.522)">
        <path id="Path_43404" data-name="Path 43404" d="M34.048,50.3l-1.449.483A.618.618,0,0,1,31.818,50l.483-1.449Z" transform="translate(-31.785 -48.552)" fill="#00143c" />
      </g>
      <g id="Group_24023" data-name="Group 24023" transform="translate(25.795 14.826)">
        <path id="Path_43405" data-name="Path 43405" d="M53.252,29.236l-.873-.873a1.235,1.235,0,0,0-1.747,0l-.874.873,2.621,2.621.873-.873A1.236,1.236,0,0,0,53.252,29.236Z" transform="translate(-49.757 -28)" fill="#c80a50" />
      </g>
      <g id="Group_24024" data-name="Group 24024" transform="translate(16.514 17.81)">
        <path id="Path_43406" data-name="Path 43406" d="M0,0H13.118V1.235H0Z" transform="translate(0 9.275) rotate(-45)" fill="#00325a" />
      </g>
      <g id="Group_24025" data-name="Group 24025" transform="translate(24.921 16.056)">
        <path id="Path_43407" data-name="Path 43407" d="M0,0H3.706V1.235H0Z" transform="translate(0.873) rotate(45)" fill="#f2c8a0" />
      </g>
      <g id="Group_24026" data-name="Group 24026" transform="translate(4.942 0)">
        <path id="Path_43408" data-name="Path 43408" d="M30.208,4a.618.618,0,0,0-.618.618V8.324a.618.618,0,0,1-.618.618H17.853a.618.618,0,0,1-.618-.618V4.618a.618.618,0,1,0-1.235,0v5.56a1.235,1.235,0,0,0,1.235,1.235H29.59a1.235,1.235,0,0,0,1.235-1.235V4.618A.618.618,0,0,0,30.208,4Z" transform="translate(-16 -4)" fill="#fab400" />
      </g>
      <g id="Group_24027" data-name="Group 24027" transform="translate(4.942 9.884)">
        <path id="Path_43409" data-name="Path 43409" d="M18.471,20H17.235A1.235,1.235,0,0,0,16,21.235v1.235a1.235,1.235,0,0,0,1.235,1.235h1.235a1.235,1.235,0,0,0,1.235-1.235V21.235A1.235,1.235,0,0,0,18.471,20Zm.618,2.471a.618.618,0,0,1-.618.618H17.235a.618.618,0,0,1-.618-.618V21.235a.618.618,0,0,1,.618-.618h1.235a.618.618,0,0,1,.618.618Z" transform="translate(-16 -20)" fill="#c80a50" />
      </g>
      <g id="Group_24029" data-name="Group 24029" transform="translate(6.177 9.575)">
        <g id="Group_24028" data-name="Group 24028">
          <path id="Path_43410" data-name="Path 43410" d="M19.017,22.5l-.927-.927a.309.309,0,0,1,.437-.437l.709.708,2.252-2.252a.309.309,0,0,1,.437.437L19.453,22.5A.309.309,0,0,1,19.017,22.5Z" transform="translate(-18 -19.501)" fill="#00143c" />
        </g>
      </g>
      <g id="Group_24030" data-name="Group 24030" transform="translate(10.81 11.119)">
        <path id="Path_43411" data-name="Path 43411" d="M31.986,22.618H25.809a.309.309,0,1,1,0-.618h6.177a.309.309,0,0,1,0,.618Z" transform="translate(-25.5 -22)" fill="#c80a50" />
      </g>
      <g id="Group_24031" data-name="Group 24031" transform="translate(10.81 12.355)">
        <path id="Path_43412" data-name="Path 43412" d="M33.222,24.618H25.809a.309.309,0,1,1,0-.618h7.413a.309.309,0,1,1,0,.618Z" transform="translate(-25.5 -24)" fill="#c80a50" />
      </g>
      <g id="Group_24032" data-name="Group 24032" transform="translate(4.942 16.061)">
        <path id="Path_43413" data-name="Path 43413" d="M18.471,30H17.235A1.235,1.235,0,0,0,16,31.235v1.235a1.235,1.235,0,0,0,1.235,1.235h1.235a1.235,1.235,0,0,0,1.235-1.235V31.235A1.235,1.235,0,0,0,18.471,30Zm.618,2.471a.618.618,0,0,1-.618.618H17.235a.618.618,0,0,1-.618-.618V31.235a.618.618,0,0,1,.618-.618h1.235a.618.618,0,0,1,.618.618Z" transform="translate(-16 -30)" fill="#c80a50" />
      </g>
      <g id="Group_24034" data-name="Group 24034" transform="translate(6.177 15.753)">
        <g id="Group_24033" data-name="Group 24033">
          <path id="Path_43414" data-name="Path 43414" d="M19.017,32.5l-.927-.927a.309.309,0,1,1,.437-.437l.709.708,2.252-2.252a.309.309,0,0,1,.437.437L19.453,32.5A.309.309,0,0,1,19.017,32.5Z" transform="translate(-18 -29.501)" fill="#00143c" />
        </g>
      </g>
      <g id="Group_24035" data-name="Group 24035" transform="translate(10.81 17.297)">
        <path id="Path_43415" data-name="Path 43415" d="M31.986,32.618H25.809a.309.309,0,0,1,0-.618h6.177a.309.309,0,0,1,0,.618Z" transform="translate(-25.5 -32)" fill="#c80a50" />
      </g>
      <g id="Group_24036" data-name="Group 24036" transform="translate(10.81 18.532)">
        <path id="Path_43416" data-name="Path 43416" d="M33.222,34.618H25.809a.309.309,0,1,1,0-.618h7.413a.309.309,0,1,1,0,.618Z" transform="translate(-25.5 -34)" fill="#c80a50" />
      </g>
      <g id="Group_24037" data-name="Group 24037" transform="translate(4.942 22.239)">
        <path id="Path_43417" data-name="Path 43417" d="M18.471,40H17.235A1.235,1.235,0,0,0,16,41.235v1.235a1.235,1.235,0,0,0,1.235,1.235h1.235a1.235,1.235,0,0,0,1.235-1.235V41.235A1.235,1.235,0,0,0,18.471,40Zm.618,2.471a.618.618,0,0,1-.618.618H17.235a.618.618,0,0,1-.618-.618V41.235a.618.618,0,0,1,.618-.618h1.235a.618.618,0,0,1,.618.618Z" transform="translate(-16 -40)" fill="#c80a50" />
      </g>
      <g id="Group_24039" data-name="Group 24039" transform="translate(6.177 21.93)">
        <g id="Group_24038" data-name="Group 24038">
          <path id="Path_43418" data-name="Path 43418" d="M19.017,42.5l-.927-.927a.309.309,0,1,1,.437-.437l.709.708,2.252-2.252a.309.309,0,0,1,.437.437L19.453,42.5A.309.309,0,0,1,19.017,42.5Z" transform="translate(-18 -39.501)" fill="#00143c" />
        </g>
      </g>
      <g id="Group_24040" data-name="Group 24040" transform="translate(10.81 23.474)">
        <path id="Path_43419" data-name="Path 43419" d="M30.442,42.618H25.809a.309.309,0,1,1,0-.618h4.633a.309.309,0,1,1,0,.618Z" transform="translate(-25.5 -42)" fill="#c80a50" />
      </g>
      <g id="Group_24041" data-name="Group 24041" transform="translate(10.81 24.71)">
        <path id="Path_43420" data-name="Path 43420" d="M29.206,44.618h-3.4a.309.309,0,0,1,0-.618h3.4a.309.309,0,0,1,0,.618Z" transform="translate(-25.5 -44)" fill="#c80a50" />
      </g>
    </svg>
  );
}

// import React from "react";
import React, { useState, useEffect } from "react";
import { CurrentBMIStatContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { Loading, Cards } from "components";
// import { connect } from "react-redux";
import {
  MsdScoreOfWholeCompany,
  MsdNumberOfDoResearch,
  MsdNumberOfAnswerQuestion,
  MsdNumberOfRiskPui,
  MsdPercentOfRiskPuiAndPain,
  MsdPercentOfOrgans,
  MsdBehavior,
  MsdPercentOfOrgansByDeparment,
  // DdcTopDepartmentPuiAndRisk,
} from "widgets";
import moment from "moment";
import momentTZ from "moment-timezone";
import { adminService } from "apiServices";
import { sliceIntoChunks } from "utils/functions/chunkSizeArr";
import { useSelector } from "react-redux";

const CurrentBMIStatContainer = () => {
  const { companySelectedRedux } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [statisticsMsdData, setStatisticsMsdData] = useState();
  const [msdTrendData, setMsdTrendData] = useState();
  const [msdWorkType, setMsdWorkType] = useState();
  const [msdOrganStatistics, setMsdOrganStatistics] = useState();
  const [msdBehaviourStatistics, setMsdBehaviourStatistics] = useState();
  const [msdOrganDepartmentStatistics, setMsdOrganDepartmentStatistics] =
    useState();
  // const [msdBehavDepartmentStatistics, setMsdBehavDepartmentStatistics] =
  //   useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    let res = await Promise.all([
      fetchStatisticsMsd(),
      fetchMsdTrend("?period=weekly&offset=0&per_page=8"),
      fetchMsdWorkTypeStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      fetchMsdOrganStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      fetchMsdBehaviourStatistics(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      ),
      fetchMsdOrganDepartmentStatistics(
        `?stat_date=${
          momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD")
          // .tz("Asia/Bangkok")
          // .startOf("month")
          // .format("YYYY-MM-DD")
        }&period=weekly`
      ),
      fetchMsdSummaryByCompanyId(
        `?stat_date=${momentTZ()
          .tz("Asia/Bangkok")
          .subtract(7, "days")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")}&period=weekly`
      ),
    ]);
    if (res) {
      setStatisticsMsdData(res[0]);
      setMsdTrendData(res[1]);
      setMsdWorkType(res[2]);
      setMsdOrganStatistics(res[3]);
      setMsdBehaviourStatistics(res[4]);
      setMsdOrganDepartmentStatistics(res[5]);
      // setMsdBehavDepartmentStatistics(res[6]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchStatisticsMsd = async () => {
    let res = await adminService.GET_MSD_STATISTICS_SUMMARY_BY_COMPANY_ID(
      companySelectedRedux.id,
      `?stat_date=${moment()
        .startOf("week")
        .isoWeekday(1)
        .format("YYYY-MM-DD")}`
    );
    if (res && res.success) {
      return res.data;
    }
  };

  const fetchMsdTrend = async (stringQuery) => {
    let res = await adminService.GET_MSD_STATISTICS_TREND(
      companySelectedRedux.id,
      stringQuery
    );
    if (res && res.success) {
      return res.data.reverse();
    }
  };

  const fetchMsdWorkTypeStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_WORKTYPE_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data;
    }
  };

  const fetchMsdOrganStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_ORGAN_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      return res.data;
    }
  };

  const fetchMsdBehaviourStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_BEHAVIOUR_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );
    if (res && res.success) {
      let data = [
        {
          key: "behaviour_forceful",
          labelX: ["การออกแรง", "อย่างหนัก"],
          totalScore:
            Number(res.data.behaviour_forceful.norisk.count) +
            Number(res.data.behaviour_forceful.pui.count),
          ...res.data.behaviour_forceful,
        },

        {
          key: "behaviour_mechanical",
          labelX: ["การออกแรงมากเกินไป", "เนื่องจากการเสียเปรียบเชิงกล"],
          totalScore:
            Number(res.data.behaviour_mechanical.norisk.count) +
            Number(res.data.behaviour_mechanical.pui.count),
          ...res.data.behaviour_mechanical,
        },
        {
          key: "behaviour_rapid",
          labelX: ["การเคลื่อนไหวเร็วๆ", "หรือซ้ำๆ"],
          totalScore:
            Number(res.data.behaviour_rapid.norisk.count) +
            Number(res.data.behaviour_rapid.pui.count),
          ...res.data.behaviour_rapid,
        },
        {
          key: "behaviour_static",
          labelX: ["การอยู่ในท่าทางที่ฝืน", "และอึดอัด"],
          totalScore:
            Number(res.data.behaviour_static.norisk.count) +
            Number(res.data.behaviour_static.pui.count),
          ...res.data.behaviour_static,
        },
        {
          key: "behaviour_vibration",
          labelX: "แรงสั่นสะเทือน",
          totalScore:
            Number(res.data.behaviour_vibration.norisk.count) +
            Number(res.data.behaviour_vibration.pui.count),
          ...res.data.behaviour_vibration,
        },
      ];
      data.sort((a, b) => b.totalScore - a.totalScore);
      return { data: data, startDate: res.data.stat_date };
    }
  };

  const fetchMsdOrganDepartmentStatistics = async (stringQuery) => {
    let res = await adminService.GET_MSD_DEPARTMENT_ORGAN_BY_COMPANY_ID(
      companySelectedRedux.id,
      stringQuery
    );

    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.department.name,
        norisk_user: e.norisk_user,
        nosymptom_user: e.nosymptom_user,
        pui_organs: e.pui_organs,
        pui_user: e.pui_user,
      }));
      return sliceIntoChunks(data, 4);
    }
  };

  const fetchMsdSummaryByCompanyId = async (stringQuery, initialStartDate) => {
    let res = await adminService.GET_MSD_SUMMARY_BY_COMPANY_ID(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      let data = res.data.map((e) => ({
        label: e.department.name,
        norisk_user_count: e.norisk_user_count,
        pui_user_count: e.pui_user_count,
        user_count: e.user_count,
      }));
      data.sort((a, b) => b.user_count - a.user_count);
      return {
        data: data,
        startDate:
          initialStartDate ||
          momentTZ()
            .tz("Asia/Bangkok")
            .subtract(7, "days")
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
      };
    }
  };

  return (
    <CurrentBMIStatContainerStyled>
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="section_introduce_layout">
            <Cards.BoxIntroduce
              src="/assets/images/logo/msd_man.svg"
              titleLabel="MSD (Musculoskeletal Disorders)"
              label1="MSD (Musculoskeletal Disorders) หมายถึง อาการบาดเจ็บที่ระบบกล้ามเนื้อและกระดูกของร่างกายมนุษย์ ซึ่งมีสาเหตุมาจากการทำงานหนักเกินไป หรือ รักษาท่าทางที่ไม่ดีในที่ทำงาน การรักษาตั้งแต่ระยะเริ่มต้น อาจช่วยบรรเทาอาการและปรับปรุงแนวโน้มในระยะยาวได้"
              // label2="ไป หรือ รักษาท่าทางที่ไม่ดีในที่ทำงาน การรักษาตั้งแต่ระยะเริ่มต้น อาจช่วยบรรเทาอาการและปรับปรุงแนวโน้มในระยะยาวได้"
              // label3="These employees were signposted and re-routed to the company’s Employee Assistance Programme."
            />
          </div>
          <div className="block_2_col_layout">
            <div className="col_2 w_416">
              <div className="b2c_block_wrap mb_24">
                <MsdNumberOfDoResearch
                  data={statisticsMsdData && statisticsMsdData}
                />
              </div>
              <div className="b2c_block_wrap">
                <MsdNumberOfAnswerQuestion
                  data={statisticsMsdData && statisticsMsdData}
                />
              </div>
            </div>
            <div className="col_2 flex_1 mw_780">
              <div className="wrap_mr5_overflow">
                <MsdScoreOfWholeCompany
                  height="100%"
                  data={statisticsMsdData && statisticsMsdData}
                />
              </div>
            </div>
          </div>
          <div className="section_chart_layout">
            <MsdNumberOfRiskPui
              data={msdTrendData && msdTrendData}
              fetchData={fetchMsdTrend}
            />
          </div>
          <div className="section_chart_layout">
            {/* <DdcTopDepartmentPuiAndRisk
          data={
            msdBehavDepartmentStatistics && msdBehavDepartmentStatistics
          }
          label="7 แผนกสูงสุดที่มีจำนวนผู้ป่วย Pui และผู้ป่วยที่มีอาการเจ็บ แต่ไม่มีความเสี่ยง"
          labelBottom="Top 7 departments which employees are at PUI risk and pain without risk "
          fetchData={this.fetchMsdSummaryByCompanyId}
        /> */}
          </div>
          <div className="block_2_col_layout no_pdb">
            <div className="col_2 w_416">
              <div className="b2c_block_wrap mb_24">
                <MsdPercentOfRiskPuiAndPain data={msdWorkType && msdWorkType} />
              </div>
            </div>
            <div className="col_2 flex_1 mw_780">
              <div className="wrap_mr5_overflow">
                <MsdPercentOfOrgans
                  height="100%"
                  data={msdOrganStatistics && msdOrganStatistics}
                />
              </div>
            </div>
          </div>
          <div className="section_chart_layout scroll">
            <div className="mr_5">
              <MsdBehavior
                data={msdBehaviourStatistics && msdBehaviourStatistics}
                fetchData={fetchMsdBehaviourStatistics}
              />
            </div>
          </div>
          {companySelectedRedux.isParent === 0 && (
            <div className="section_chart_layout mt24">
              <MsdPercentOfOrgansByDeparment
                data={msdOrganDepartmentStatistics}
                fetchData={fetchMsdOrganDepartmentStatistics}
                labelTitle="แผนก"
                labelEng="department"
              />
            </div>
          )}
        </>
      )}
    </CurrentBMIStatContainerStyled>
  );
};

export default CurrentBMIStatContainer;

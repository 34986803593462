export default function Certicficate({ width = '31', height = '25.784' }) {
  return (
    <svg id="_035-diploma" data-name="035-diploma" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 25.784">
      <g id="Group_326" data-name="Group 326">
        <path id="Path_1230" data-name="Path 1230" d="M29.75,64.934H1.25A1.25,1.25,0,0,1,0,63.684V44.327a1.25,1.25,0,0,1,1.25-1.25h28.5A1.25,1.25,0,0,1,31,44.327V63.684A1.25,1.25,0,0,1,29.75,64.934Z" transform="translate(0 -43.077)" fill="#9e9e9e" />
        <path id="Path_1231" data-name="Path 1231" d="M29.75,375.1H1.25A1.25,1.25,0,0,1,0,373.846v1.125a1.25,1.25,0,0,0,1.25,1.25h28.5a1.25,1.25,0,0,0,1.25-1.25v-1.125A1.25,1.25,0,0,1,29.75,375.1Z" transform="translate(0 -353.819)" fill="#898989" />
        <path id="Path_1232" data-name="Path 1232" d="M64.406,96.972V83a1.913,1.913,0,0,1-1.913-1.913H39.921A1.913,1.913,0,0,1,38.008,83V96.972a1.91,1.91,0,0,1,1.547.788l1.456.58H61.4l1.417-.525A1.911,1.911,0,0,1,64.406,96.972Z" transform="translate(-35.707 -78.784)" fill="#fff5f5" />
        <path id="Path_1233" data-name="Path 1233" d="M63.552,356.483a1.9,1.9,0,0,1,.366,1.125H86.49a1.9,1.9,0,0,1,.366-1.125Z" transform="translate(-59.704 -337.507)" fill="#efe2dd" />
        <path id="Path_1234" data-name="Path 1234" d="M381.439,413.071l-.431.512v2.557a.312.312,0,0,0,.447.282l1.445-.686a.312.312,0,0,1,.268,0l1.445.686a.313.313,0,0,0,.447-.282v-2.557l-.658-.512Z" transform="translate(-357.939 -390.669)" fill="#ff4755" />
        <path id="Path_1235" data-name="Path 1235" d="M381.009,402.95v1.125a3.648,3.648,0,0,0,4.051,0V402.95Z" transform="translate(-357.94 -381.161)" fill="#fc2b3a" />
        <path id="Path_1236" data-name="Path 1236" d="M362.069,295.663a3.649,3.649,0,0,0-7.21,0,3.468,3.468,0,0,0,3.605,3.666A3.511,3.511,0,0,0,362.069,295.663Z" transform="translate(-333.37 -277.475)" fill="#ffe27a" />
        <path id="Path_1237" data-name="Path 1237" d="M357.857,346.632a3.649,3.649,0,0,1-3.6-3.086,3.648,3.648,0,1,0,7.21,0A3.649,3.649,0,0,1,357.857,346.632Z" transform="translate(-332.763 -325.353)" fill="#f9cf58" />
        <path id="Path_1238" data-name="Path 1238" d="M389.1,324.01a1.822,1.822,0,0,0-3.466,0,1.653,1.653,0,0,0,1.733,1.839A1.742,1.742,0,0,0,389.1,324.01Z" transform="translate(-362.274 -305.818)" fill="#ff9838" />
        <path id="Path_1239" data-name="Path 1239" d="M386.2,344.8a1.822,1.822,0,0,1-1.733-1.259,1.822,1.822,0,1,0,3.465,0A1.822,1.822,0,0,1,386.2,344.8Z" transform="translate(-361.106 -325.353)" fill="#ff8709" />
      </g>
      <g id="Group_327" data-name="Group 327" transform="translate(5.084 5.974)">
        <path id="Path_1240" data-name="Path 1240" d="M191.2,142.687a.47.47,0,0,0,0-.939h-7.924a.47.47,0,1,0,0,.939Z" transform="translate(-176.819 -141.748)" fill="#9e9e9e" />
        <path id="Path_1241" data-name="Path 1241" d="M83.965,194.485a.47.47,0,0,0,.47.47h19.893a.47.47,0,1,0,0-.939H84.435a.47.47,0,0,0-.47.47Z" transform="translate(-83.965 -190.85)" fill="#9e9e9e" />
        <path id="Path_1242" data-name="Path 1242" d="M84.436,247.221h19.893a.47.47,0,0,0,0-.939H84.436a.47.47,0,0,0,0,.939Z" transform="translate(-83.966 -239.953)" fill="#9e9e9e" />
        <path id="Path_1243" data-name="Path 1243" d="M84.436,299.488h9.1a.47.47,0,1,0,0-.939h-9.1a.47.47,0,1,0,0,.939Z" transform="translate(-83.966 -289.055)" fill="#9e9e9e" />
      </g>
    </svg>
  );
}

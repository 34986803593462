import styled from "styled-components";

export const PasswordExpiredContainerStyled = styled.div`
  width: 100%;
  min-height: calc(100% - 30px);
  padding: 15px 0;
  background-image: url(/assets/images/backgrounds/bg_login.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .logo_show {
    display: flex;
    align-items: center;
    margin-right: 245px;
    .img_logo {
      width: 293px;
    }
  }
  .forgot_form_container {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 72px 54px 42px 54px;
    width: 432px;
    height: 529px;
    .svg_top {
      display: flex;
      justify-content: center;
      margin-bottom: 22px;
    }
    .logo_icon {
      margin-bottom: 43px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box_input {
      .group_input {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_32};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .error_show {
      margin-top: 8px;
      color: ${({ theme }) => theme.COLORS.RED_3};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-weight: ${({ theme }) => theme.FONT.WEIGHT.W300};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
    .btn_layout {
      margin-top: 131px;
      position: relative;
      .txt_err {
        text-align: center;
        position: absolute;
        left: 0px;
        right: 0px;
        color: ${({ theme }) => theme.COLORS.RED_3};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-weight: ${({ theme }) => theme.FONT.WEIGHT.W300};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
    .box_logo {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .img_logo {
        width: 204px;
      }
    }
  }

  .b_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 72px 54px 42px 54px;
    width: 432px;
    height: 529px;
    .logo_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text_b {
      margin-top: 37px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S32};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
    .fw {
      margin-top: 150px;
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 200px;
      }
    }
    .form_layout {
      margin-top: 40px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 200px;
      }
    }
    .form_layout {
      margin-top: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 200px;
      }
    }
    .form_layout {
      margin-top: 20px;
      max-width: 332px;
    }
    .b_success {
      padding: 40px 30px 20px 30px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SMALLMOBILE}) {
    flex-direction: column;
    .logo_show {
      margin-right: 0px;
      .img_logo {
        width: 200px;
      }
    }
    .form_layout {
      margin-top: 20px;
      max-width: 284px;
    }
  }
`;

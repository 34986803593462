// import BarVertical from './BarVertical';
// import BarHorizontal from './BarHorizontal';
// import Bump from './Bump';
import BarItem from './BarItem';
import BarItem2 from './BarItem2';
import BarItem3 from './BarItem3';
import TwoTone from './TwoTone';
import Table1 from './Table1';
import RadialBar from './RadialBar';

const Export = {
  // BarVertical,
  // BarHorizontal,
  // Bump,
  BarItem,
  BarItem2,
  BarItem3,
  TwoTone,
  Table1,
  RadialBar,
};

export default Export;

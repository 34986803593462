export default function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37.767" height="40" viewBox="0 0 37.767 40">
      <g id="Group_15160" data-name="Group 15160" transform="translate(-32.709 -32.073)">
        <path id="Path_37134" data-name="Path 37134" d="M94.877,47.693c.407,2.482-21.349,1.837-25.159.884-2.169-.451.37-10.954-.992-14.378C67.943,30.99,92.4,31.835,94.2,33.959,95.594,35.593,94.3,44.2,94.877,47.693Z" transform="translate(-30.174)" fill="#fff" />
        <g id="Group_15137" data-name="Group 15137" transform="translate(39.914 38.148)">
          <path id="Path_37135" data-name="Path 37135" d="M79.849,73.128c.085-.428-.024-.923.038-1.161.139-.533.209-.8.126-.878-.335-.311-.568,1.1-.883,1.092-.127-.069-.234-.5-.276-.8-.05-.367.091-1.454-.13-1.435-.185.016-.31.807-.306,1.073-.006-.277.1-1.489-.19-1.387-.216.075-.218,1.461-.213,1.645-.006-.089.043-1.418-.169-1.328-.246.105-.2,1.3-.192,1.486-.009-.112.068-1.253-.192-1.127-.161.078-.227,1.183-.223,1.367a11.36,11.36,0,0,0,.919,3l1.266.025s.178-.522.229-.778C79.719,73.6,79.817,73.288,79.849,73.128Z" transform="translate(-77.239 -69.622)" fill="#f8b791" />
        </g>
        <g id="Group_15138" data-name="Group 15138" transform="translate(40.136 42.781)">
          <path id="Path_37136" data-name="Path 37136" d="M128.985,108.162c.154,1.175-1.491,5.252-1.14,7.276,3.4.714,11.428-.567,12.053-3.259.051-1.822-.014-3.86.021-5.075.091-3.171-.494-8.605-5.774-6.924-.27-.057-.26-.454-2.923-.274C128.647,100.082,128.524,104.651,128.985,108.162Z" transform="translate(-119.838 -99.601)" fill="#ef5ea3" />
          <path id="Path_37137" data-name="Path 37137" d="M78.813,98.756c-1.069,5.451,2.243,9.534,6.864,9.317,6.567-.309,10.521-5.469,8.725-9.048l-1.768.025c-5.241-.916-6.495,3-8.071,2.862a4,4,0,0,1-3.392-3.4C81.087,98.015,78.9,98.32,78.813,98.756Z" transform="translate(-78.611 -98.257)" fill="#ef5ea3" />
        </g>
        <g id="Group_15150" data-name="Group 15150" transform="translate(47.729 34.083)">
          <g id="Group_15139" data-name="Group 15139" transform="translate(0 0)">
            <path id="Path_37138" data-name="Path 37138" d="M130.537,54.042c-.878,2.1-4.319,3.088-3.767-.008.17-.957-1.085-1.713-1.2-2.393-.258-1.546,1.289-2.044,1.628-3.453.512-2.123,1.357-5.646,7.093-2.32C136.035,46.878,130.537,54.042,130.537,54.042Z" transform="translate(-125.542 -44.501)" fill="#002869" />
          </g>
          <g id="Group_15140" data-name="Group 15140" transform="translate(4.987 5.702)">
            <path id="Path_37139" data-name="Path 37139" d="M159.19,79.742l.009,3.924c-.2,1.129-2.973,1.3-2.826.009l-.008-1.685Z" transform="translate(-156.366 -79.742)" fill="#f27979" />
          </g>
          <g id="Group_15149" data-name="Group 15149" transform="translate(3.139 1.693)">
            <g id="Group_15141" data-name="Group 15141">
              <path id="Path_37140" data-name="Path 37140" d="M144.978,58.918a5.152,5.152,0,0,0-.035.693,2.9,2.9,0,0,0,.2.908c.311.774.958,1.873,1.768,1.891a3.364,3.364,0,0,0,3.055-3.667c-.2-1.819-1.739-4.611-2.3-3.536-.864,1.668-1.833,1.026-2.451,2.057a1.877,1.877,0,0,0-.24,1.113C144.985,58.577,144.991,58.753,144.978,58.918Z" transform="translate(-144.94 -54.967)" fill="#f8b791" />
            </g>
            <g id="Group_15142" data-name="Group 15142" transform="translate(2.983 4.201)">
              <path id="Path_37141" data-name="Path 37141" d="M163.379,81.706a.806.806,0,1,0,.836-.776A.806.806,0,0,0,163.379,81.706Z" transform="translate(-163.378 -80.93)" fill="#f27979" />
            </g>
            <g id="Group_15143" data-name="Group 15143" transform="translate(0 3.95)">
              <path id="Path_37142" data-name="Path 37142" d="M144.944,80.075a3.018,3.018,0,0,0,.2.908.807.807,0,0,0-.108-1.6h-.059A5.152,5.152,0,0,0,144.944,80.075Z" transform="translate(-144.94 -79.382)" fill="#f27979" />
            </g>
            <g id="Group_15144" data-name="Group 15144" transform="translate(2.416 3.855)">
              <path id="Path_37143" data-name="Path 37143" d="M159.888,78.883s.324.538,1.319-.072c.124-.076-.427.095-.768.122S159.8,78.768,159.888,78.883Z" transform="translate(-159.872 -78.792)" fill="#002869" />
            </g>
            <g id="Group_15145" data-name="Group 15145" transform="translate(0.204 3.58)">
              <path id="Path_37144" data-name="Path 37144" d="M146.207,77.115c.08.133.8.685,1.062.2.07-.128-.207.14-.529.023S146.155,77.03,146.207,77.115Z" transform="translate(-146.2 -77.093)" fill="#002869" />
            </g>
            <g id="Group_15147" data-name="Group 15147" transform="translate(1.381 3.875)">
              <g id="Group_15146" data-name="Group 15146">
                <path id="Path_37145" data-name="Path 37145" d="M154.075,80.283c-.212.009-.538-.041-.593-.292a.431.431,0,0,1,.082-.3,1.44,1.44,0,0,0,.237-.745c0-.037.057-.046.058-.008a1.389,1.389,0,0,1-.212.725c-.075.138-.163.26-.05.41a.559.559,0,0,0,.47.151c.037,0,.046.056.008.058Z" transform="translate(-153.476 -78.916)" fill="#f27979" />
              </g>
            </g>
            <g id="Group_15148" data-name="Group 15148" transform="translate(1.183 5.716)">
              <path id="Path_37146" data-name="Path 37146" d="M153.657,90.4c.063-.126-.6.318-1.333-.076C152.012,90.15,152.762,91.065,153.657,90.4Z" transform="translate(-152.252 -90.298)" fill="#f27979" />
            </g>
          </g>
        </g>
        <path id="Path_37147" data-name="Path 37147" d="M69.7,160.946c-2.471,2.157-16.358.827-23.012,1.288-.154.011-.307.021-.461.031-2.791.186-5.48.288-7.671.217-.26-.008-.515-.019-.76-.034-2.406-.133-4.074-.514-4.385-1.286a49.277,49.277,0,0,1-.662-6.585c-.006-.136-.011-.273-.013-.414-.129-4.25.227-9.97,1.024-11.625a1.53,1.53,0,0,1,.112-.2c1.467-2.228,10.753-2.632,19.385-2.249.023,0,.044,0,.065,0,2.467.112,4.878.286,7.035.5l.612.063c3.945.416,6.938.963,7.684,1.484C70.87,143.688,70.868,159.926,69.7,160.946Z" transform="translate(0 -90.432)" fill="#016099" />
        <g id="Group_15151" data-name="Group 15151" transform="translate(32.735 52.605)">
          <path id="Path_37148" data-name="Path 37148" d="M235.968,159.455l1.907-.066c.266-.009.267-.423,0-.414l-1.907.066c-.266.009-.267.423,0,.414Z" transform="translate(-202.94 -158.975)" fill="#a9d3e0" />
          <path id="Path_37149" data-name="Path 37149" d="M213.242,261.168l-1.565,1.092c-.218.152.006.5.225.348l1.565-1.092c.218-.152-.006-.5-.225-.348Z" transform="translate(-182.673 -244.602)" fill="#a9d3e0" />
          <path id="Path_37150" data-name="Path 37150" d="M236.455,176.829l-1.385,2.347c-.134.227.2.472.335.243l1.385-2.348c.134-.227-.2-.472-.335-.243Z" transform="translate(-202.329 -173.863)" fill="#a9d3e0" />
          <path id="Path_37151" data-name="Path 37151" d="M247.809,246.4a12.6,12.6,0,0,0,1.443,1.671c.191.185.485-.107.293-.293a12,12,0,0,1-1.378-1.588c-.155-.214-.514-.007-.357.209Z" transform="translate(-213 -232.006)" fill="#a9d3e0" />
          <path id="Path_37152" data-name="Path 37152" d="M256.42,204.444v2.07a.207.207,0,0,0,.414,0v-2.07a.207.207,0,0,0-.414,0Z" transform="translate(-220.25 -196.914)" fill="#a9d3e0" />
          <path id="Path_37153" data-name="Path 37153" d="M185.569,227.647l-1.595-.451c-.255-.072-.392.319-.135.392l1.595.451c.255.072.392-.319.135-.391Z" transform="translate(-159.298 -216.151)" fill="#a9d3e0" />
          <path id="Path_37154" data-name="Path 37154" d="M204.939,169.775a13.3,13.3,0,0,1,1.781,1.289c.2.173.43-.173.23-.344a13.3,13.3,0,0,0-1.781-1.289c-.227-.138-.459.206-.23.344Z" transform="translate(-177.02 -167.712)" fill="#a9d3e0" />
          <path id="Path_37155" data-name="Path 37155" d="M34.025,228.223l-1.146-.065c-.006-.136-.01-.274-.013-.414l1.144.065C34.273,227.825,34.291,228.238,34.025,228.223Z" transform="translate(-32.866 -216.618)" fill="#a9d3e0" />
          <path id="Path_37156" data-name="Path 37156" d="M150.511,257.941l1.907.066c.266.009.267.423,0,.414l-1.907-.066c-.266-.009-.267-.423,0-.414Z" transform="translate(-131.31 -241.929)" fill="#a9d3e0" />
          <path id="Path_37157" data-name="Path 37157" d="M147.185,181.63l-1.385-2.348c-.134-.227.2-.472.336-.243l1.385,2.347c.134.228-.2.472-.336.243Z" transform="translate(-127.502 -175.716)" fill="#a9d3e0" />
          <path id="Path_37158" data-name="Path 37158" d="M112.325,268.9c-.154.011-.307.021-.461.031l-.815-1.382c-.134-.228.2-.472.335-.243Q111.854,268.1,112.325,268.9Z" transform="translate(-98.373 -249.698)" fill="#a9d3e0" />
          <path id="Path_37159" data-name="Path 37159" d="M182.423,184.955v-2.07a.207.207,0,0,1,.414,0v2.07a.207.207,0,1,1-.414,0Z" transform="translate(-158.226 -178.849)" fill="#a9d3e0" />
          <path id="Path_37160" data-name="Path 37160" d="M128.383,205.356l-1.595.451c-.255.072-.392-.319-.135-.392l1.595-.451c.255-.072.392.319.135.391Z" transform="translate(-111.364 -197.517)" fill="#a9d3e0" />
          <path id="Path_37161" data-name="Path 37161" d="M127.788,235.673a13.289,13.289,0,0,0,1.781-1.289c.2-.173.43.172.23.344a13.313,13.313,0,0,1-1.781,1.289c-.228.138-.459-.206-.23-.344Z" transform="translate(-112.352 -222.144)" fill="#a9d3e0" />
          <path id="Path_37162" data-name="Path 37162" d="M60.272,273.581c-.26-.008-.515-.019-.76-.034q-.425-.318-.822-.67c-.2-.176.06-.5.259-.322A13.512,13.512,0,0,0,60.272,273.581Z" transform="translate(-54.452 -254.135)" fill="#a9d3e0" />
          <path id="Path_37163" data-name="Path 37163" d="M90.3,235.036l-.957,2.552c-.093.247-.493.141-.4-.109l.957-2.552c.093-.247.493-.141.4.109Z" transform="translate(-79.855 -222.521)" fill="#a9d3e0" />
          <path id="Path_37164" data-name="Path 37164" d="M46.794,167.331l-1.822-.564c-.254-.079-.146-.478.109-.4l1.822.564c.254.079.146.478-.109.4Z" transform="translate(-42.891 -165.162)" fill="#a9d3e0" />
          <path id="Path_37165" data-name="Path 37165" d="M81.624,183.446l.72,2.629c.07.255-.317.4-.388.146l-.72-2.629c-.07-.255.317-.4.387-.146Z" transform="translate(-73.404 -179.375)" fill="#a9d3e0" />
          <path id="Path_37166" data-name="Path 37166" d="M53.83,239.773A12.608,12.608,0,0,1,52,241.007a.207.207,0,0,1-.206-.359,11.975,11.975,0,0,0,1.747-1.17c.205-.166.5.128.29.3Z" transform="translate(-48.644 -226.412)" fill="#a9d3e0" />
          <path id="Path_37167" data-name="Path 37167" d="M53.128,197.781l-.543,2a.207.207,0,0,1-.4-.109l.543-2a.207.207,0,0,1,.4.109Z" transform="translate(-49.053 -191.283)" fill="#a9d3e0" />
          <path id="Path_37168" data-name="Path 37168" d="M100.4,222.767l1.657-.016c.265,0,.294.41.027.413l-1.657.017c-.265,0-.294-.41-.027-.413Z" transform="translate(-89.319 -212.432)" fill="#a9d3e0" />
          <path id="Path_37169" data-name="Path 37169" d="M115.247,185.713a13.311,13.311,0,0,0-2.057.776c-.24.114-.37-.279-.132-.393a13.305,13.305,0,0,1,2.057-.776c.256-.073.388.319.132.393Z" transform="translate(-99.978 -181.05)" fill="#a9d3e0" />
        </g>
        <path id="Path_37170" data-name="Path 37170" d="M66.4,140.658c-2.045,5.233-11.655,7.934-18.685,7.067a14.337,14.337,0,0,1-8.523-5.184,1.762,1.762,0,0,1,.113-.2c1.466-2.227,10.752-2.631,19.385-2.248.022,0,1.87.1,1.893.1C63.42,140.326,64.253,140.432,66.4,140.658Z" transform="translate(-5.437 -90.435)" fill="#fff" />
        <g id="Group_15153" data-name="Group 15153" transform="translate(53.307 58.149)">
          <path id="Path_37171" data-name="Path 37171" d="M172.849,204.382c-.516,1.233-3.737,3.158-4.266,2.874-.869-.466-4.085-5.058-3.819-6.151.254-1.04,3.311-2.921,3.311-2.921a19.2,19.2,0,0,1,4.89-1.293c.965-.018,4.824,5.826,4.355,7.036C177.092,204.516,174.31,204.885,172.849,204.382Z" transform="translate(-163.983 -196.301)" fill="#fff" />
          <path id="Path_37172" data-name="Path 37172" d="M170.691,204.5c-.152,1.335-3.888,3.456-4.621,3.167-.431-.17-4.055-5.256-3.788-6.35.254-1.04,5.025-3.347,5.025-3.347a32.845,32.845,0,0,1,4.35-.523c.965-.017,3.927,5.568,3.458,6.779C174.886,204.815,171.51,205.475,170.691,204.5Z" transform="translate(-161.903 -196.768)" fill="#fff" />
          <path id="Path_37173" data-name="Path 37173" d="M171.35,204.775c-.527,1.692-4.462,3.453-4.918,3.147-.74-.5-3.537-4.9-3.271-5.992.254-1.04,4.975-3.289,4.975-3.289,1.447-.087,2.618-.566,4.493-.618.968-.027,4.017,5.13,3.65,6.344C176.061,205.083,171.951,205.484,171.35,204.775Z" transform="translate(-162.637 -197.249)" fill="#fff" />
          <path id="Path_37174" data-name="Path 37174" d="M209.077,238.455a.821.821,0,0,0,1.078.382,1.127,1.127,0,0,0,.731-1.143A2.7,2.7,0,0,0,209.077,238.455Z" transform="translate(-201.139 -230.502)" fill="#e8e2e3" />
          <path id="Path_37175" data-name="Path 37175" d="M168.566,200.374a1.068,1.068,0,0,0-.392.974c.1.424-3.75,2.221-4.734,2.309-.661.059-3.538-5.569-3.421-6.237.09-.516,4.646-2.529,4.86-2.938,0,0,.681-.513,1.148-.4s4.361-.828,4.821-.839c.72-.017,3.461,5.416,3.451,6.511,0,.453-4.533,1.434-4.689,1.062C169.442,200.414,168.566,200.374,168.566,200.374Z" transform="translate(-160.016 -193.245)" fill="#a9d3e0" />
          <path id="Path_37176" data-name="Path 37176" d="M188.846,197.932l3.489,6.838c.352-.437.97-.837,1.435-.531l-3.36-6.812C190.237,197.087,188.731,197.7,188.846,197.932Z" transform="translate(-184.176 -196.667)" fill="#002869" />
          <g id="Group_15152" data-name="Group 15152" transform="translate(3.48 7.072)">
            <path id="Path_37177" data-name="Path 37177" d="M183.3,240.707a10.14,10.14,0,0,0,2.581-1.421c.111-.086.237.093.126.179a10.344,10.344,0,0,1-2.632,1.447.109.109,0,0,1-.075-.205Z" transform="translate(-182.952 -238.89)" fill="#002869" />
            <path id="Path_37178" data-name="Path 37178" d="M181.593,237.9a9.393,9.393,0,0,0,1.749-.92c.112-.085.238.094.125.179a9.679,9.679,0,0,1-1.8.946c-.128.059-.2-.146-.075-.205Z" transform="translate(-181.527 -236.954)" fill="#002869" />
          </g>
        </g>
        <g id="Group_15154" data-name="Group 15154" transform="translate(47.887 53.256)">
          <path id="Path_37179" data-name="Path 37179" d="M130.172,163.409c-.416-.131-.92-.075-1.15-.162-.515-.2-.774-.294-.859-.22-.345.3,1.037.683.99,1-.083.118-.52.179-.829.188-.371.01-1.436-.247-1.44-.025,0,.186.769.4,1.034.42-.276-.023-1.47-.264-1.4.04.051.222,1.429.373,1.612.389-.089,0-1.406-.2-1.338.026.078.256,1.273.335,1.457.35-.113,0-1.238-.2-1.141.07.06.169,1.152.353,1.335.369a11.358,11.358,0,0,0,3.079-.592l.161-1.256s-.5-.233-.749-.312C130.624,163.589,130.328,163.458,130.172,163.409Z" transform="translate(-126.516 -163.002)" fill="#f8b791" />
        </g>
        <path id="Path_37180" data-name="Path 37180" d="M155.159,113.2c1.329.173,7.453,3.353,9.3-.97,2.583-6.045-2.147-12.347-6.115-11.806l1.521,5.652c-.6,1-.257,2.7-.4,3.131-.369,1.077.049,1.677-4.376,1.615C154.576,110.814,154.718,113.142,155.159,113.2Z" transform="translate(-102.305 -57.265)" fill="#ef5ea3" />
        <path id="Path_37181" data-name="Path 37181" d="M157.44,44.444c-.105-.6,5.2-2.178,6.78,2.879.564,1.805,4.746,1.754,2.812,5.232-.611,1.1,3.731,2.078-.037,4.912-3.525,2.651-8.912.328-8.426-1.518.538-2.043,2.381-.954,1.175-3.557A2.629,2.629,0,0,1,160,49.838q.043-.137.088-.279h0a1.283,1.283,0,0,0,0-.9c-.378-.861-2.961-.339-2.409-2.36C158.2,44.391,157.44,44.444,157.44,44.444Z" transform="translate(-104.549 -9.774)" fill="#002869" />
        <g id="Group_15159" data-name="Group 15159" transform="translate(48.485 36.158)">
          <g id="Group_15155" data-name="Group 15155" transform="translate(7.704 12.398)">
            <path id="Path_37182" data-name="Path 37182" d="M177.907,138.779c1.666-.4.53-3.662,1.4-4.722.049-.06-.037-.147-.086-.086-.875,1.073.154,4.331-1.341,4.691-.076.018-.044.136.032.118Z" transform="translate(-177.831 -133.95)" fill="#002869" />
          </g>
          <g id="Group_15156" data-name="Group 15156" transform="translate(6.188 20.283)">
            <path id="Path_37183" data-name="Path 37183" d="M168.5,182.806c1.7.572,3.951,1.111,5.66.361.072-.031.01-.137-.062-.105-1.673.734-3.9.188-5.565-.374-.075-.025-.107.093-.033.118Z" transform="translate(-168.456 -182.685)" fill="#002869" />
          </g>
          <g id="Group_15157" data-name="Group 15157" transform="translate(10.437 0)">
            <path id="Path_37184" data-name="Path 37184" d="M194.727,57.413a3.943,3.943,0,0,0,1.133,1.351,3.585,3.585,0,0,1,1.278,1.252c.035.07.143.013.108-.057a2.715,2.715,0,0,0-.927-1.025,4.393,4.393,0,0,1-1.484-1.578.061.061,0,0,0-.108.057Z" transform="translate(-194.719 -57.324)" fill="#002869" />
          </g>
          <g id="Group_15158" data-name="Group 15158" transform="translate(0 6.165)">
            <path id="Path_37185" data-name="Path 37185" d="M130.541,95.505c.16.594-.315,1.245-.329,1.847-.014.629.438.9,1.009,1,.077.014.11-.1.032-.118-1.767-.322-.318-1.74-.594-2.763a.061.061,0,0,0-.118.032Z" transform="translate(-130.212 -95.43)" fill="#002869" />
          </g>
        </g>
      </g>
    </svg>
  );
}

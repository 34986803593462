export default function Gift() {
  return (
    <svg id="Flat" xmlns="http://www.w3.org/2000/svg" width="20.384" height="20.682" viewBox="0 0 20.384 20.682">
      <path id="Path_30813" data-name="Path 30813" d="M96.491,6.031l-.866-.414a.851.851,0,0,1-.482-.825l.051-.742a.467.467,0,0,0-.607-.445c-.828.262-1.176.968-2.391,1.278l1.2,3.772a4.184,4.184,0,0,0,3.265-1.781.593.593,0,0,0-.168-.845Z" transform="translate(-77.218 -3.583)" fill="#ff9c31" />
      <path id="Path_30814" data-name="Path 30814" d="M11.084,3.6a.467.467,0,0,0-.607.445l.051.742a.851.851,0,0,1-.482.825l-.867.414a.592.592,0,0,0-.166.845,4.184,4.184,0,0,0,3.265,1.781l1.2-3.772C12.259,4.57,11.911,3.864,11.084,3.6Z" transform="translate(-8.068 -3.581)" fill="#ff9c31" />
      <path id="Path_30815" data-name="Path 30815" d="M68.466,7.193c-.97.017-2.532,1.484-2.92,3.238v2.444s4.957.41,5.254,0a4,4,0,0,0,.466-1.223c.663-2.254-1.272-4.487-2.8-4.459Z" transform="translate(-55.092 -6.579)" fill="#ffb339" />
      <path id="Path_30816" data-name="Path 30816" d="M30.9,7.193c-1.528-.027-3.463,2.207-2.8,4.459a2.272,2.272,0,0,0,.55,1.142c.393.3,5.159.132,5.159.132V10.431C33.424,8.677,31.871,7.211,30.9,7.193Z" transform="translate(-23.892 -6.579)" fill="#ffb339" />
      <path id="Path_30817" data-name="Path 30817" d="M68.829,25.257a.255.255,0,0,1-.219-.385.982.982,0,0,1,.516-.326,1.616,1.616,0,0,0,.692-.419.255.255,0,0,1,.409.3,2.023,2.023,0,0,1-.911.588,1.735,1.735,0,0,0-.277.127A.255.255,0,0,1,68.829,25.257Z" transform="translate(-57.606 -20.554)" fill="#f28a2c" />
      <path id="Path_30818" data-name="Path 30818" d="M50.843,25.256a.255.255,0,0,1-.21-.111,1.724,1.724,0,0,0-.277-.127,2.023,2.023,0,0,1-.911-.588.255.255,0,0,1,.409-.3,1.617,1.617,0,0,0,.692.419.982.982,0,0,1,.516.326.255.255,0,0,1-.219.385Z" transform="translate(-41.682 -20.554)" fill="#f28a2c" />
      <path id="Path_30819" data-name="Path 30819" d="M57.483,27.357h2.193A1.106,1.106,0,1,0,57.483,27.357Z" transform="translate(-48.385 -22.285)" fill="#ff9c31" />
      <rect id="Rectangle_1830" data-name="Rectangle 1830" width="18.173" height="14.739" rx="5.309" transform="translate(1.105 5.943)" fill="#f95877" />
      <path id="Path_30820" data-name="Path 30820" d="M50.589,64.312h4.553V74.685H50.589Z" transform="translate(-42.674 -54.003)" fill="#ffe8e3" />
      <rect id="Rectangle_1831" data-name="Rectangle 1831" width="20.384" height="5.407" rx="2.703" transform="translate(0 4.902)" fill="#ff6687" />
      <path id="Path_30821" data-name="Path 30821" d="M47.518,32.461h5.6v5.407h-5.6Z" transform="translate(-40.124 -27.559)" fill="#fff3f0" />
    </svg>
  );
}

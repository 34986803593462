import styled from "styled-components";

export const NewLogContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  min-height: calc(100vh - 200px);
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;

  .layer_one {
    .head_wrap_layout {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .h_33 {
        width: 33%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        &.center {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.flex {
          display: flex;
          column-gap: 10px;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 10px;
        }
        .text_c {
          text-align: center;
          white-space: nowrap;
        }
        .g_h_text {
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
        .bw140 {
          width: 140px;
        }
        .back_click {
          display: flex;
          cursor: pointer;
        }
      }
    }
  }
  .filter_show {
    margin-top: 47px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    .box_date {
      width: 216px;
    }
  }
  .layer_two {
    margin-top: 26px;
  }

  .show_detail_log {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    width: 500px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    position: relative;
    .svg_c {
      position: absolute;
      top: 6px;
      right: 9px;
      cursor: pointer;
    }
    .group_h {
      display: flex;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: #696868;
      column-gap: 26px;
      align-items: baseline;
      .big_text {
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_6};
      }
    }
    .show_point {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      margin-top: 8px;
    }
    .group_list {
      margin-top: 11px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      max-height: 328px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cccccc;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cccccc;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #c2c2c2;
      }
      ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #e6e6e6;
      }
      ::-webkit-scrollbar-track:active {
        background: #ebebeb;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .group_name {
        display: flex;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        .id_show {
          width: 30%;
        }
        .name_show {
          width: 68%;
        }
      }
    }
  }

  // @media (max-width: ${({ theme }) => theme.SCREENS.IPAD}) {
  //   .header_col  {
  //     &.showHead {
  //       width: 270px;
  //       max-width: 270px;
  //     }
  //   }
  //   .body_col {
  //     &.showHead {
  //       width: 270px;
  //       max-width: 270px;
  //     }
  //   }
  //   .showName {
  //     max-width: 270px;
  //   }
  // }

  // @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
  //   .header_col  {
  //     &.showHead {
  //       width: 200px;
  //       max-width: 200px;
  //     }
  //     &.showUser {
  //       width: 100px;
  //       max-width: 100px;
  //     }
  //     &.showDate {
  //       width: 100px;
  //       max-width: 100px;
  //     }
  //   }
  //   .body_col  {
  //     &.showHead {
  //       width: 200px;
  //       max-width: 200px;
  //     }
  //     &.showUser {
  //       width: 100px;
  //       max-width: 100px;

  //     }
  //     &.showDate {
  //       width: 100px;
  //       max-width: 100px;
  //     }
  //   }
  //   .showName {
  //     width: 180px;
  //     max-width: 180px;
  //   }
  // }
`;

import styled from 'styled-components'

export const BoxDeleteStyled = styled.div`
  .theme_box_delete {
    .delete_box {
      min-width: ${({ minWidth }) => minWidth ? minWidth : '500px'};
      max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 'unset'};
      border-radius: 16px;
      border: 1px solid #70707000;
      padding: 45px 20px 36px 20px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title_box {
        display: flex;
        // flex-direction: column;
        align-items: center;
        column-gap: 12px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color:  ${({ theme }) => theme.COLORS.PURPLE_1};
        margin-bottom: 32px;
        .icon {
          display: flex;
          // margin-bottom: 9px;
        }
        .group_label {
          display: flex;
          flex-direction: column;
          align-items: center;
          .show_txt {
            text-align: center;
          }
          .sub_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            font-weight: 400;
            color:  ${({ theme }) => theme.COLORS.GRAY_24};
            &.mt10 {
              margin-top: 10px;
            }
          }
        }
      }
      .action_btn {
        display: flex;
        .body_btn {
          width: 136px;
          &.margin_right {
            margin-right: 16px;
          }
        }
      }
    }
  }
`

export default function CategoryNutrition({ width = '32', height = '32' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_15961" data-name="Group 15961" transform="translate(-152 -400.03)">
        <circle id="Ellipse_1501" data-name="Ellipse 1501" cx="16" cy="16" r="16" transform="translate(152 400.03)" fill="#fef3f2" />
        <g id="pyramid" transform="translate(158.679 405.955)">
          <path id="Path_19171" data-name="Path 19171" d="M171.868,20.193l-2.6-4.87a.559.559,0,0,0-.487-.3l-3.139,5.546Z" transform="translate(-159.391 -15.025)" fill="#ffc250" />
          <path id="Path_19172" data-name="Path 19172" d="M169.439,15.022a.56.56,0,0,0-.511.3l-2.6,4.87.229.229Z" transform="translate(-160.051 -15.022)" fill="#e69642" />
          <path id="Path_19173" data-name="Path 19173" d="M117.55,155.788l9.857-.377-1.814-3.4h-5.851Z" transform="translate(-113.115 -146.847)" fill="#e27f86" />
          <path id="Path_19174" data-name="Path 19174" d="M120.4,152.015h-.351l-1.814,3.4.229.229Z" transform="translate(-113.776 -146.847)" fill="#d16161" />
          <path id="Path_19175" data-name="Path 19175" d="M69.461,245.788l13.485-.377-1.814-3.4H71.653Z" transform="translate(-66.84 -233.451)" fill="#fff0af" />
          <path id="Path_19176" data-name="Path 19176" d="M72.314,242.015h-.351l-1.814,3.4.229.229Z" transform="translate(-67.501 -233.451)" fill="#ffe07d" />
          <path id="Path_19177" data-name="Path 19177" d="M22.841,332.015H9.733L7,337l18.455-.075a.583.583,0,0,0-.034-.078Z" transform="translate(-6.735 -320.056)" fill="#c2e4a1" />
          <path id="Path_19178" data-name="Path 19178" d="M.822,336.087,3,332.015H2.647L.068,336.842a.566.566,0,0,0,.5.833h11.57l.283-.377.283.377h5.483a.566.566,0,0,0,.534-.755H1.321a.566.566,0,0,1-.5-.833Z" transform="translate(0 -320.056)" fill="#98ca91" />
          <path id="Path_19179" data-name="Path 19179" d="M159.946,333.421h0a.283.283,0,0,1-.279-.287,1.259,1.259,0,0,1,1.254-1.235.283.283,0,1,1,0,.566.691.691,0,0,0-.688.677A.283.283,0,0,1,159.946,333.421Z" transform="translate(-153.639 -319.944)" fill="#d8ab8c" />
          <path id="Path_19180" data-name="Path 19180" d="M119.732,362.015a4.126,4.126,0,0,0-.755.122,4.125,4.125,0,0,0-.755-.122,1.345,1.345,0,0,0-.588.127,1.608,1.608,0,0,0-.546,1.134c0,.8.621,1.945,1.388,1.945a3.478,3.478,0,0,0,.69-.122,3.477,3.477,0,0,0,.69.122c.147,0,.666-.42.8-.493a2.483,2.483,0,0,0,.4-1.263A1.326,1.326,0,0,0,119.732,362.015Z" transform="translate(-112.67 -348.924)" fill="#e27f86" />
          <path id="Path_19181" data-name="Path 19181" d="M114.505,368.044a3.476,3.476,0,0,1-.69.122c-.767,0-1.237-1.069-1.237-1.87a1.452,1.452,0,0,1,.245-.908,1.4,1.4,0,0,0-.735,1.323c0,.8.621,1.945,1.388,1.945a3.477,3.477,0,0,0,.69-.122,3.476,3.476,0,0,0,.69.122,1.275,1.275,0,0,0,.988-.682,2.052,2.052,0,0,1-.648.192A3.477,3.477,0,0,1,114.505,368.044Z" transform="translate(-107.859 -352.17)" fill="#d16161" />
          <path id="Path_19182" data-name="Path 19182" d="M329.867,365.51c.127.026.257.154.39.154a2.113,2.113,0,0,0,1.153-.493,3.052,3.052,0,0,0,.705-1.64.34.34,0,0,0-.419-.368A2.639,2.639,0,0,0,329.867,365.51Z" transform="translate(-317.422 -350.019)" fill="#98ca91" />
          <path id="Path_19183" data-name="Path 19183" d="M329.221,416.966a3.476,3.476,0,0,0-.025.418,2.084,2.084,0,0,0,1.568-.757,1.977,1.977,0,0,1-1.153.38A1.9,1.9,0,0,1,329.221,416.966Z" transform="translate(-316.776 -401.475)" fill="#68b181" />
          <path id="Path_19184" data-name="Path 19184" d="M281.8,365.738a2.634,2.634,0,0,0-1.831-2.335C279.966,364.571,280.536,365.738,281.8,365.738Z" transform="translate(-269.403 -350.259)" fill="#98ca91" />
          <path id="Path_19185" data-name="Path 19185" d="M269.3,363.146h0a.34.34,0,0,0-.411.374,2.5,2.5,0,0,0,2.273,2.392,3.481,3.481,0,0,0-.026-.43A2.634,2.634,0,0,1,269.3,363.146Z" transform="translate(-258.738 -350.003)" fill="#68b181" />
          <path id="Path_19186" data-name="Path 19186" d="M222.525,152.015a1.127,1.127,0,0,0-.71.25,3.055,3.055,0,0,0-.158.5,1.132,1.132,0,0,0,1.132,1.132c.172,0,.868-.023.868-.023v-.732A1.132,1.132,0,0,0,222.525,152.015Z" transform="translate(-213.294 -146.847)" fill="#c38778" />
          <path id="Path_19187" data-name="Path 19187" d="M231.448,66.534a.7.7,0,1,1-1.393,0,5.085,5.085,0,0,1,.7-1.451A5.085,5.085,0,0,1,231.448,66.534Z" transform="translate(-221.375 -63.194)" fill="#fff0af" />
          <path id="Path_19188" data-name="Path 19188" d="M321.979,434.626a.283.283,0,0,1-.283-.283v-1.031c0-.156.127.075.283.075s.283-.232.283-.075v1.031A.283.283,0,0,1,321.979,434.626Z" transform="translate(-309.559 -417.479)" fill="#68b181" />
          <path id="Path_19189" data-name="Path 19189" d="M321.7,462.015h.566v.755H321.7Z" transform="translate(-309.559 -445.151)" fill="#518f60" />
          <g id="Group_13776" data-name="Group 13776" transform="translate(7.301 9.489)">
            <path id="Path_19190" data-name="Path 19190" d="M194.51,268.032a.283.283,0,0,1-.387-.1l-.566-.98a.283.283,0,0,1,.49-.283l.566.98A.283.283,0,0,1,194.51,268.032Z" transform="translate(-193.52 -266.524)" fill="#d8ab8c" />
            <path id="Path_19191" data-name="Path 19191" d="M234.51,268.032a.283.283,0,0,1-.387-.1l-.566-.98a.283.283,0,0,1,.49-.283l.566.98A.283.283,0,0,1,234.51,268.032Z" transform="translate(-232.011 -266.524)" fill="#d8ab8c" />
            <path id="Path_19192" data-name="Path 19192" d="M274.51,268.032a.283.283,0,0,1-.387-.1l-.566-.98a.283.283,0,0,1,.49-.283l.566.98A.283.283,0,0,1,274.51,268.032Z" transform="translate(-270.502 -266.524)" fill="#d8ab8c" />
          </g>
          <path id="Path_19193" data-name="Path 19193" d="M217.843,161.187s-.936-.936-.944-.942a.616.616,0,0,0-.424-.146c-.188,0-.357-.009-.563-.009a.953.953,0,0,1-.934-.934,1.834,1.834,0,0,1,.1-.5,1.132,1.132,0,0,0,.71,2.013h.732l.922.922a.283.283,0,0,0,.4-.4Z" transform="translate(-206.558 -153.233)" fill="#b1705b" />
        </g>
      </g>
    </svg>
  );
}

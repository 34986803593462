import React from "react";
import { ForgotPasswordFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Inputs, Buttons } from "components";
import { renderErrorStyle } from "utils/functions/renderErrorStyle";

const ForgotPasswordForm = ({
  onSubmit,
  errorMessage,
  handleClearTextError,
}) => {
  const schema = yup.object().shape({
    // email: yup.string().email('Email is required').matches(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Email is not valid'), //eslint-disable-line
    // email: yup.string().required("Username is required").matches(/^(?=.{6,32}$)(?![._\-])[a-zA-Z0-9._\-]+(?<![._\-])$/, 'format username ไม่ถูกต้อง'), //eslint-disable-line
    email: yup.string().required("Username is required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  return (
    <ForgotPasswordFormStyled onSubmit={handleSubmit(onSubmit)}>
      <div className="forgot_form_container">
        <div className="logo_icon">ลืมรหัสผ่าน</div>
        <div className="box_input">
          <div className="group_input">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTxtIcon
                  theme_not_icon_standard
                  {...field}
                  // iconSvg={<Icons.User />}
                  label="ชื่อผู้ใช้งาน"
                  placeholder="กรุณาระบุชื่อผู้ใช้งาน"
                  errors={errors.email?.message}
                  onChangeCustom={handleClearTextError}
                  style={renderErrorStyle(errorMessage)}
                />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>
        {errorMessage && <div className="error_show">{errorMessage}</div>}
        <div className="btn_layout">
          <Buttons.BtnNormal
            theme_standard_btn_log_in
            label="ยืนยัน"
            type="submit"
            disabled={
              !watchAllFields ||
              !watchAllFields.email ||
              watchAllFields.email === ""
            }
          />
        </div>
        <div className="text_com">Triple T Wellness</div>
      </div>
    </ForgotPasswordFormStyled>
  );
};

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;

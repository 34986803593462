import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxWellbeingStyled } from "./styled";

const BoxWellbeing = ({
  theme_standard_box_well_being,
  theme_average_box_well_being,
  theme_standard_box_medium,
  theme_health_check,
  theme_center,
  theme_msd_pui,
  label,
  labelBottom,
  bgColor,
  showRound,
}) => {
  const customClass = cx({
    theme_standard_box_well_being: theme_standard_box_well_being,
    theme_average_box_well_being: theme_average_box_well_being,
    theme_msd_pui: theme_msd_pui,
    theme_standard_box_medium: theme_standard_box_medium,
    theme_center: theme_center,
    theme_health_check: theme_health_check,
  });
  return (
    <BoxWellbeingStyled>
      <div className={customClass}>
        {showRound && (
          <div className="left_layout">
            <div className="round" style={{ background: bgColor }} />
          </div>
        )}
        <div className="right_layout">
          <div className="label_top">{label}</div>
          <div className="label_bottom">{labelBottom}</div>
        </div>
      </div>
    </BoxWellbeingStyled>
  );
};

BoxWellbeing.propTypes = {};

export default BoxWellbeing;

export default function Cooking2() {
  return (
    <svg id="cooking_1_" data-name="cooking (1)" xmlns="http://www.w3.org/2000/svg" width="64" height="62.286" viewBox="0 0 64 62.286">
      <path id="Path_20567" data-name="Path 20567" d="M341.09,213.437l0,.018a2.578,2.578,0,0,1-2.029,3.028l-21.327,4.212-1-5.075,21.327-4.212A2.578,2.578,0,0,1,341.09,213.437Z" transform="translate(-277.143 -185.795)" fill="#d63030" />
      <path id="Path_20568" data-name="Path 20568" d="M401.511,213.438l0,.009L389.588,215.8a3.482,3.482,0,0,1-3.8-1.886l12.7-2.507A2.577,2.577,0,0,1,401.511,213.438Z" transform="translate(-337.564 -185.796)" fill="#f44545" />
      <path id="Path_20569" data-name="Path 20569" d="M46.032,204.5l.01.048a12.363,12.363,0,0,1-9.74,14.534l-21.153,4.177A12.372,12.372,0,0,1,.614,213.52L.6,213.472a1.288,1.288,0,0,1,1.015-1.514l42.9-8.472A1.289,1.289,0,0,1,46.032,204.5Z" transform="translate(-0.507 -178.886)" fill="#5a5555" />
      <path id="Path_20570" data-name="Path 20570" d="M88.938,204.5l.009.048a12.311,12.311,0,0,1-.012,4.868,12.322,12.322,0,0,1-5.494,2.606L62.29,216.2a12.373,12.373,0,0,1-12.675-5.247l37.809-7.466A1.288,1.288,0,0,1,88.938,204.5Z" transform="translate(-43.413 -178.887)" fill="#736e6e" />
      <path id="Path_20571" data-name="Path 20571" d="M4.265,10.277a2.763,2.763,0,1,1,4.286,1.592,2.791,2.791,0,0,1-.317.194l4.519,6.346a1.29,1.29,0,0,1-.3,1.794L10.967,21.26a1.29,1.29,0,0,1-1.794-.3L4.654,14.612a2.763,2.763,0,1,1-.389-4.335Z" transform="translate(0 -6.854)" fill="#ebe1dc" />
      <path id="Path_20572" data-name="Path 20572" d="M206.561,80.267A5.206,5.206,0,1,0,215.12,84.6a4.026,4.026,0,1,0-4.694-5.539A5.19,5.19,0,0,0,206.561,80.267Z" transform="translate(-179.131 -67.947)" fill="#6e9213" />
      <path id="Path_20573" data-name="Path 20573" d="M242.989,66.8a.966.966,0,0,1-.625-1.7l10.405-8.819a.966.966,0,0,1,1.25,1.475l-10.405,8.819A.963.963,0,0,1,242.989,66.8Z" transform="translate(-211.769 -49.898)" fill="#84b017" />
      <g id="Group_14501" data-name="Group 14501" transform="translate(1.531 1.117)">
        <circle id="Ellipse_1849" data-name="Ellipse 1849" cx="3.043" cy="3.043" r="3.043" transform="translate(18.274)" fill="#d63030" />
        <circle id="Ellipse_1850" data-name="Ellipse 1850" cx="3.043" cy="3.043" r="3.043" transform="translate(0 21.383)" fill="#d63030" />
      </g>
      <path id="Path_20574" data-name="Path 20574" d="M74.284,92.478A4.671,4.671,0,0,1,72.6,94.739c-2.579,1.836-6.617.589-9.022-2.786s-3.879-10.4-1.3-12.232a3.571,3.571,0,0,1,3.2-.176A17.005,17.005,0,0,1,72.922,85.3,8.045,8.045,0,0,1,74.284,92.478Z" transform="translate(-53.311 -70.211)" fill="#f77026" />
      <path id="Path_20575" data-name="Path 20575" d="M105.923,94.455a8.109,8.109,0,0,1-6.182-3.633,15.957,15.957,0,0,1-2.621-9.3,17.005,17.005,0,0,1,7.441,5.758A8.045,8.045,0,0,1,105.923,94.455Z" transform="translate(-84.95 -72.189)" fill="#ff883e" />
      <path id="Path_20576" data-name="Path 20576" d="M183.859,299.5H164.852a7.171,7.171,0,0,1-.769-1.218,8.877,8.877,0,0,1-.876-4.82c.308-3,1.836-5.7,3.425-8.285a.473.473,0,0,1,.809.005l3.139,5.275a7.5,7.5,0,0,0,1.858-2.656.471.471,0,0,1,.853-.031,6.446,6.446,0,0,1,.108,5.539,18.848,18.848,0,0,0,5.745-7.566c.228-.55.667-1.235,1.231-1.041.961.327,4.289,5.972,1.723,11.6a12.18,12.18,0,0,0,4.464-3.693.473.473,0,0,1,.84.173C188.013,295.286,186.323,298.171,183.859,299.5Z" transform="translate(-142.766 -249.938)" fill="#fb5858" />
      <path id="Path_20577" data-name="Path 20577" d="M189.907,340.189H171.3a7.168,7.168,0,0,1-.769-1.218c0-.031.005-.063.008-.094.271-2.629,1.61-5,3-7.261a.414.414,0,0,1,.709.005c.918,1.54,1.112,2.872,2.03,4.414a11.167,11.167,0,0,0,1.732-3.262.414.414,0,0,1,.749-.028c.751,1.494,1.423,4.48.711,6a16.515,16.515,0,0,0,5.035-6.63c.2-.482.585-1.083,1.079-.912.843.286,2.458,3.514.209,8.444,1.54-.742,3.807-1.528,4.828-2.9a.414.414,0,0,1,.736.152C191.574,337.806,190.912,339.009,189.907,340.189Z" transform="translate(-149.21 -290.631)" fill="#ff883e" />
      <path id="Path_20578" data-name="Path 20578" d="M108.454,395.864H65.338a2.559,2.559,0,0,0-2.556,2.556v5.456a2.559,2.559,0,0,0,2.556,2.556h4.424a.966.966,0,0,0,0-1.933H65.338a.623.623,0,0,1-.623-.623V398.42a.623.623,0,0,1,.623-.623H85.929v7.668a.967.967,0,0,0,1.933,0V397.8h20.592a.623.623,0,0,1,.623.623v5.456a.623.623,0,0,1-.623.623h-4.425a.966.966,0,0,0,0,1.933h4.425a2.559,2.559,0,0,0,2.556-2.556V398.42A2.559,2.559,0,0,0,108.454,395.864Z" transform="translate(-54.934 -347.238)" fill="#5a5555" />
      <path id="Path_20579" data-name="Path 20579" d="M143.963,465.905V469a.967.967,0,0,1-.966.966H107.685a.967.967,0,0,1-.966-.966v-3.093a.967.967,0,0,1,.966-.967H143A.967.967,0,0,1,143.963,465.905Z" transform="translate(-93.379 -407.678)" fill="#d63030" />
      <path id="Path_20580" data-name="Path 20580" d="M167.417,480.4v2.126a.967.967,0,0,1-.966.967H133.524v-2.126a.967.967,0,0,1,.966-.966Z" transform="translate(-116.834 -421.209)" fill="#f44545" />
    </svg>
  );
}

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { InputSwitchStyled } from './styled';
import Switch from "react-switch";
import theme from '../../../styles/theme.json';

const InputSwitch = ({
  theme_input_switch,
  disabled,
  onChange,
  value,
  color,
  label,
  height = 20,
  width = 37,
  onChangeCustom
}) => {
  const customClass = cx({
    theme_input_switch: theme_input_switch,
  });

  // const [checked, setChecked] = useState(false);
  // const handleChange = nextChecked => {
  //   setChecked(nextChecked);
  //   onChangeCustom && onChangeCustom()
  // };

  return (
    <InputSwitchStyled  >
      <div className={customClass}>
      {label && <div className="label">{label}</div>}
        <Switch
         disabled={disabled}
         onChange={onChange}
         height={height}
         width={width}
         checked={value}
         onColor={color ? color : theme.COLORS.BLUE_28}
         uncheckedIcon={false}
         checkedIcon={false}
        />
      </div>
    </InputSwitchStyled>
  );
};

InputSwitch.propTypes = {};

export default InputSwitch;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelSubVerticalStyled } from './styled';

const LabelSubVertical = ({
  theme_standard,
  theme_head_34,
  title,
  subTitle,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_head_34: theme_head_34,
  });
  return (
    <LabelSubVerticalStyled>
      <div className={customClass}>
        <div className="lb_title">{title}</div>
        <div className="lb_sub_title">{subTitle}</div>
      </div>
    </LabelSubVerticalStyled>
  );
};

LabelSubVertical.propTypes = {};

export default LabelSubVertical;

import TopBottom from './TopBottom';
import ShowDate from './ShowDate';
import LabelIcon from './LabelIcon';
import ShowGender from './ShowGender';
import LabelDescripScore from './LabelDescripScore';
import LabelSubVertical from './LabelSubVertical';

const Export = {
  TopBottom,
  ShowDate,
  LabelIcon,
  ShowGender,
  LabelDescripScore,
  LabelSubVertical,
};

export default Export;

import { useState, useEffect } from "react";
import { MsdPercentOfOrgansByDeparmentStyled } from "./styled";
import {
  Blocks,
  // Inputs,
  Typographys,
  Skeleton,
  Charts,
  TableCustom,
  Icons,
} from "components";
import moment from "moment";

const MsdPercentOfOrgansByDeparment = ({
  data,
  fetchData,
  labelTitle,
  labelEng,
}) => {
  const [_data, _setData] = useState();
  const [_date, _setDate] = useState(
    moment().subtract(7, "days").startOf("isoWeeks").format("YYYY-MM-DD")
  );
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);
  const [_page, _setPage] = useState(0);

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);

    if (res) {
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };

  const handlePrev = (e) => {
    const date = moment(e)
      .subtract(7, "days")
      .startOf("isoWeeks")
      .format("YYYY-MM-DD");

    _setDate(date);
    _setLoadSkeleton(true);
    fetchDataApi(`?stat_date=${date}&period=weekly`);
    _setPage(_page + 1);
  };

  const handleNext = (e) => {
    const date = moment(e)
      .tz("Asia/Bangkok")
      .add(7, "days")
      .startOf("isoWeeks")
      .format("YYYY-MM-DD");

    _setDate(date);
    _setLoadSkeleton(true);
    fetchDataApi(`?stat_date=${date}&period=weekly`);
    _setPage(_page - 1);
  };

  return (
    <MsdPercentOfOrgansByDeparmentStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="26px"
        paddingRight="24px"
        paddingBottom="20px"
        paddingLeft="24px"
      >
        <div className="body_mass_container">
          <div className="body_mass_left_col">
            <Typographys.TopBottom
              theme_left_align
              label={`สัดส่วนผู้ที่มีอาการ MSD แบบต่างๆ และส่วนของร่างกายที่เกิด PUI ในแต่ละ${labelTitle}`}
              labelBottom={`Percentage of employees having MSD conditions and painful organs without risk and PUI risk by ${labelEng}`}
              fontSizeLabelButtom="14px"
            />
          </div>
          <div className="body_mass_right_col">
            <div className="item_right ">
              <div className="date_show">
                <div onClick={() => handlePrev(_date)} className="left_arrow">
                  <Icons.ArrowBlueLeft />
                </div>
                <div className="group_date">
                  <div className="group_date_top">
                    <div className="show_icon">
                      <Icons.CalendarIcon />
                    </div>
                    <div className="date_layout">วันที่รายสัปดาห์ล่าสุด</div>
                  </div>
                  <div className="group_date_bottom">
                    {`${moment(_date).format("DD MMM YYYY")} - ${moment(_date)
                      .endOf("isoWeeks")
                      .format("DD MMM YYYY")}`}
                  </div>
                </div>

                {_page >= 0 && (
                  <div
                    onClick={() => handleNext(_date)}
                    className="right_arrow"
                  >
                    <Icons.ArrowBlueRight />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {_data && _data.length > 0 ? (
          <>
            <div className="bmi_trend_graph">
              <div className="show_graph">
                <div className="graph_wrap">
                  {_loadSkeleton ? (
                    <Skeleton.GraphSkeleton />
                  ) : (
                    <div className="body_content">
                      <div className="show_group_label">
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="ไม่มีอาการ"
                          labelBottom="No symptoms"
                          fontSizeLabelButtom="16px"
                          bgColor="#8AC47F"
                          showRound
                        />
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="มีอาการแต่ยังไม่มีความเสี่ยง"
                          labelBottom="Pain without risk"
                          fontSizeLabelButtom="16px"
                          bgColor="#6FB5CB"
                          showRound
                        />
                        <Blocks.BoxWellbeing
                          theme_standard_box_well_being
                          label="มีความเสี่ยงเข้าเกณฑ์เป็นโรค"
                          labelBottom="PUI risk"
                          fontSizeLabelButtom="16px"
                          bgColor="#654C96"
                          showRound
                        />
                      </div>
                      {_data &&
                        _data.length > 0 &&
                        _data.map((e, i) => (
                          <div key={i} className="group_graph">
                            <div className="left_graph">
                              <Charts.Table1
                                labelTop1={labelTitle}
                                labelTop2="สัดส่วนผู้ไม่มีอาการ, เจ็บ แต่ไม่มีความเสี่ยง, ผู้ป่วย PUI"
                                data={e.map((f) => ({
                                  label: f.label,
                                  percent1: f.nosymptom_user.percent,
                                  percent2: f.norisk_user.percent,
                                  percent3: f.pui_user.percent,
                                }))}
                              />
                            </div>
                            <div className="right_graph">
                              <TableCustom.TableNormal
                                theme_standard_table_msd
                                showTitle="ส่วนต่างๆ ของร่างกายที่เกิด PUI "
                                columns={columns}
                                bgHeader="#654C96"
                                data={e.map((f) => f.pui_organs)}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        )}
      </Blocks.Box>
    </MsdPercentOfOrgansByDeparmentStyled>
  );
};

const columns = [
  {
    title: "คอ",
    field: "1",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "ไหล่ แขน ข้อศอก",
    field: "2",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "มือ นิ้วมือ",
    field: "3",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "หลัง",
    field: "4",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "สะโพก ต้นขา",
    field: "5",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "เข่า",
    field: "6",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
  {
    title: "ข้อเท้า เท้า",
    field: "7",
    headerStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    style: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "14.2%",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          fontFamily:
            Number(value) > 49
              ? "noto_sans_thai_bold, noto_sans_bold"
              : "noto_sans_thai_regular, noto_sans_regular",
          display: "flex",
          alignItems: "center",
        }}
      >
        {Number(value) > 49 && (
          <div style={{ display: "flex", marginRight: "4px" }}>
            <Icons.Alert />
          </div>
        )}
        {`${value || 0}%`}
      </div>
    ),
  },
];

export default MsdPercentOfOrgansByDeparment;

import React, { useState, useEffect } from 'react';
import { AddMissionFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Blocks, Label, Icons, Inputs, Buttons } from 'components';
// import { RENDER_MONTH_SELECT_2 } from 'utils/functions/date';
import moment from 'moment';
import { adminService } from 'apiServices';

const AddMissionForm = ({
  initialValues,
  onSubmit,
  disableMissionInformation,
  handleDelete,
  companyID,
}) => {
  const schema = yup.object().shape({
    name: yup.string().nullable().required('Mission information is required'),
    target_amount: yup
      .string()
      .nullable()
      .required('Goal is required')
      .matches(/^[0-9]+$/, 'Must be only digits'),
    // begin_date: yup.object().nullable().required('Reward Period is required'),
    // end_date: yup.object().nullable().required('Reward Period is required'),
    success_coin_amount: yup
      .string()
      .nullable()
      .required('Is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .max(4, 'Less than 9,999'),
    // success_exp_amount: yup
    //   .string()
    //   .nullable()
    //   .required('Is required')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .max(4, 'Less than 9,999'),
    fail_coin_amount: yup
      .string()
      .nullable()
      .required('Is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .max(4, 'Less than 9,999'),
    // fail_exp_amount: yup
    //   .string()
    //   .nullable()
    //   .required('Is required')
    //   .matches(/^[0-9]+$/, 'Must be only digits')
    //   .max(4, 'Less than 9,999'),
  });

  const routerID = useParams();
  const { id } = routerID;
  const [_stepLabel, setStepLabel] = useState('ก้าว');
  const [_disabledBtnSubmit, _setDisabledBtnSubmit] = useState(false);
  // const {
  //   control,
  //   handleSubmit,
  //   errors,
  //   setValue,
  //   reset,
  //   getValues,
  //   setError,
  //   clearErrors,
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        begin_date: new Date(initialValues.begin_date),
        end_date: new Date(initialValues.end_date),
        success_avatar_item_id:
          initialValues.success_avatar_item === null
            ? { label: 'ไม่มี', value: 'empty', icon: false }
            : {
              label: initialValues.success_avatar_item?.name,
              value: initialValues.success_avatar_item?.id,
              icon: initialValues.success_avatar_item?.icon_uri,
            },
        fail_avatar_item_id:
          initialValues.fail_avatar_item === null
            ? { label: 'ไม่มี', value: 'empty', icon: false }
            : {
              label: initialValues.fail_avatar_item?.name,
              value: initialValues.fail_avatar_item?.id,
              icon: initialValues.fail_avatar_item?.icon_uri,
            },
        files: initialValues.image_uri
          ? [{ image_uri: initialValues.image_uri }]
          : [],
      });

      changeLabelStep(initialValues.category);
      changeCategroy(initialValues.target_type);
      changeSubCategroy(initialValues.category);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  //CHECK DUPLICATE MISSION
  //========================================
  //========================================
  useEffect(() => {
    if (companyID && id === 'create') {
      fetchDataCheckDuplicateEvent();
    }
  }, [companyID]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDataCheckDuplicateEvent = async (
    startDate = moment().add(1, 'days').format('YYYY-MM-DD'),
    endDate = moment().add(30, 'days').format('YYYY-MM-DD')
  ) => {
    let res = await adminService.GET_COMANY_MISSIONS_BY_BEGIN_DATE_END_DATE(
      companyID,
      startDate,
      endDate,
      1
    );

    if (res && res.success) {
      _setDisabledBtnSubmit(res.data.length > 0 ? true : false);
    }
  };

  const _handleChangeDate = (key, value) => {
    switch (key) {
      case 'startDate':
        setValue(
          'end_date',
          new Date(moment(value).add(1, 'days').format('YYYY-MM-DD'))
        );
        clearErrors('end_date');
        fetchDataCheckDuplicateEvent(
          moment(new Date(value)).format('YYYY-MM-DD'),
          moment(new Date(value)).add(1, 'days').format('YYYY-MM-DD')
        );
        break;
      case 'endDate':
        let b_date = moment(new Date(getValues('begin_date'))).format(
          'YYYY-MM-DD'
        );
        let e_date = moment(new Date(value)).format('YYYY-MM-DD');
        let checkDate = moment(e_date).diff(b_date);
        if (checkDate > 0) {
          clearErrors('end_date');
          fetchDataCheckDuplicateEvent(
            moment(new Date(getValues('begin_date'))).format('YYYY-MM-DD'),
            moment(new Date(value)).format('YYYY-MM-DD')
          );
        } else {
          _setDisabledBtnSubmit(true);
          setError('end_date', {
            type: 'manual',
            message: 'end date must morethan start date',
          });
        }
        break;
      default:
        break;
    }
  };

  const changeLabelStep = (e) => {
    switch (e) {
      case 'step':
        setStepLabel('ก้าว');
        break;

      case 'step_step_count':
        setStepLabel('ก้าว');
        break;

      case 'mission_move':
        setStepLabel('ครั้ง');
        break;

      case 'mission_movel_count':
        setStepLabel('ครั้ง');
        break;

      case 'mission_eye':
        setStepLabel('ครั้ง');
        break;

      case 'mission_eye_count':
        setStepLabel('ครั้ง');
        break;

      default:
        break;
    }
  };

  const changeSubCategroy = (e) => {
    switch (e) {
      case 'step':
        setValue(
          'target_type',
          { label: 'ก้าวสะสม', value: 'step_step_count' },
          { shouldValidate: true }
        );
        break;

      case 'mission_move':
        setValue(
          'target_type',
          { label: 'ขยับร่างกายสะสม', value: 'mission_move_count' },
          { shouldValidate: true }
        );
        break;

      case 'mission_eye':
        setValue(
          'target_type',
          { label: 'ผ่อนคลายสายตาสะสม', value: 'mission_eye_count' },
          { shouldValidate: true }
        );
        break;

      default:
        break;
    }
  };

  const changeCategroy = (e) => {
    switch (e) {
      case 'step_step_count':
        setValue(
          'category',
          { label: 'ก้าว', value: 'step' },
          { shouldValidate: true }
        );
        break;

      case 'mission_move_count':
        setValue(
          'category',
          { label: 'ขยับร่างกาย', value: 'mission_move' },
          { shouldValidate: true }
        );
        break;

      case 'mission_eye_count':
        setValue(
          'category',
          { label: 'ผ่อนคลายสายตา', value: 'mission_eye' },
          { shouldValidate: true }
        );
        break;

      default:
        break;
    }
  };

  return (
    <AddMissionFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="top_add_mission">
          <div className="left_add">
            <Blocks.Box
              theme_standard_box
              paddingTop="44px"
              paddingBottom="179px;"
              paddingLeft="24px"
              paddingRight="24px"
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.UploadImage
                    theme_standard_upload_image
                    acceptType="cover"
                    maxMBFileSizeWarning="1"
                    disabled={disableMissionInformation}
                    {...field}
                  />
                )}
                name="files"
                defaultValue={[]}
              />
            </Blocks.Box>
          </div>
          <div className="right_add">
            <Blocks.Box
              theme_standard_box
              paddingTop="32px"
              paddingBottom="37px;"
              paddingLeft="32px"
              paddingRight="32px"
            >
              <div className="right_add_top">
                <div className="show_icon">
                  <Icons.Calendar2 />
                </div>
                <Label.TopBottom
                  theme_left_align
                  label="ข้อมูลภารกิจ"
                  labelBottom="Mission Information"
                  fontSize="24px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  marginTopBottomLabel="8px"
                />
              </div>
              <div className="right_add_bottom">
                <div className="box_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputDropDownNormal
                        theme_border_purple
                        label="หมวด"
                        labelBottom="Category"
                        iconSvgClose={<Icons.RectArrowDown />}
                        iconSvgOpen={<Icons.RectArrowUp />}
                        placeholder="เลือกหมวด"
                        disabled={disableMissionInformation}
                        onChangeCustom={(e) => {
                          changeLabelStep(e.value);
                          changeSubCategroy(e.value);
                        }}
                        options={
                          groupCategory &&
                          groupCategory.map((e) => ({
                            value: e.value,
                            label: e.label,
                          }))
                        }
                        {...field}
                        errors={errors.category?.message}
                      />
                    )}
                    name="category"
                    defaultValue={{
                      label: 'ก้าว',
                      value: 'step',
                    }}
                  />
                </div>
                <div className="box_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputText
                        theme_input_border_purple
                        {...field}
                        iconSvg={<Icons.User />}
                        label="ชื่อภารกิจ"
                        subLabel="Mission Information"
                        placeholder="เพิ่มข้อความ"
                        maxLength={60}
                        disabled={disableMissionInformation}
                        errors={errors.name?.message}
                      />
                    )}
                    name="name"
                    defaultValue=""
                  />
                </div>
                <div className="two_label">
                  <div className="two_title">เป้าหมาย</div>
                  <div className="two_sub">Goal</div>
                </div>
                <div className="box_input2">
                  <div className="left_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDropDownNormal
                          theme_border_purple
                          iconSvgClose={<Icons.RectArrowDown />}
                          iconSvgOpen={<Icons.RectArrowUp />}
                          placeholder="เลือกหมวด"
                          disabled={disableMissionInformation}
                          onChangeCustom={(e) => {
                            changeLabelStep(e.value);
                            changeCategroy(e.value);
                          }}
                          options={
                            subCategory &&
                            subCategory.map((e) => ({
                              value: e.value,
                              label: e.label,
                            }))
                          }
                          {...field}
                          errors={errors.target_type?.message}
                        />
                      )}
                      name="target_type"
                      defaultValue={{
                        label: 'ก้าวสะสม',
                        value: 'step_step_count',
                      }}
                    />
                  </div>
                  <div className="right_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputText
                          theme_input_border_purple
                          iconSvg={<Icons.User />}
                          placeholder="เพิ่มข้อความ"
                          labelBottom={_stepLabel}
                          disabled={disableMissionInformation}
                          {...field}
                          errors={errors.target_amount?.message}
                        />
                      )}
                      name="target_amount"
                    />
                  </div>
                </div>
                <div className="two_label">
                  <div className="two_title">ระยะเวลา</div>
                  <div className="two_sub">Rewards Period</div>
                </div>
                <div className="box_input2 no_margin flex_start">
                  <div className="left_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDatePiker
                          theme_standard
                          disabled={disableMissionInformation}
                          minDate={
                            id === 'create' && new Date(moment().add(1, 'days'))
                          }
                          onChangeCustom={(e) =>
                            _handleChangeDate('startDate', e)
                          }
                          {...field}
                          errors={errors.begin_date?.message}
                        />
                      )}
                      name="begin_date"
                      defaultValue={new Date(moment().add(1, 'days'))}
                    />
                  </div>
                  <div className="between_label pt_9">ถึง</div>
                  <div className="right_box">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.InputDatePiker
                          theme_standard
                          disabled={disableMissionInformation}
                          minDate={
                            id === 'create' && new Date(moment().add(1, 'days'))
                          }
                          onChangeCustom={(e) =>
                            _handleChangeDate('endDate', e)
                          }
                          {...field}
                          errors={errors.end_date?.message}
                        />
                      )}
                      name="end_date"
                      defaultValue={new Date(moment().add(2, 'days'))}
                    />
                  </div>
                </div>
              </div>
            </Blocks.Box>
          </div>
        </div>
        <div className="mid_add_mission">
          <div className="box_mid left">
            <div className="title_show">
              <div className="show_icon">
                <Icons.Reward9 />
              </div>
              <Label.TopBottom
                theme_left_align
                label="รางวัลเมื่อภารกิจสำเร็จ"
                labelBottom="Reward"
                fontSizeLabelButtom="16px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              />
            </div>
            <div className="box_input_bottom">
              <div className="left_box">
                <div className="box_label">
                  <div className="icon_svg">
                    <Icons.Dumbel />
                  </div>
                  <div>คะแนนสุขภาพ</div>
                </div>
              </div>
              <div className="right_box">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_mini_border_purple
                      iconSvg={<Icons.User />}
                      placeholder="ใส่ข้อความ"
                      labelBottom="แต้ม"
                      {...field}
                      maxLength={4}
                      min="1"
                      max="9999"
                      errors={errors.success_coin_amount?.message}
                    />
                  )}
                  name="success_coin_amount"
                  defaultValue="0"
                />
              </div>
            </div>
          </div>
          <div className="box_mid right">
            <div className="title_show">
              <div className="show_icon">
                <Icons.Star />
              </div>
              <Label.TopBottom
                theme_left_align
                label="รางวัลปลอบใจสำหรับผู้ที่ไม่ผ่านภารกิจ"
                labelBottom="Consolation Reward"
                fontSizeLabelButtom="16px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
              />
            </div>
            <div className="box_input_bottom">
              <div className="left_box">
                <div className="box_label">
                  <div className="icon_svg">
                    <Icons.Dumbel />
                  </div>
                  <div>คะแนนสุขภาพ</div>
                </div>
              </div>
              <div className="right_box">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputText
                      theme_input_mini_border_purple
                      iconSvg={<Icons.User />}
                      placeholder="ใส่ข้อความ"
                      labelBottom="แต้ม"
                      {...field}
                      maxLength={4}
                      min="1"
                      max="9999"
                      errors={errors.fail_coin_amount?.message}
                    />
                  )}
                  name="fail_coin_amount"
                  defaultValue="0"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_add_mission">
          <div className="show_svg">

          </div>
          <div className="btn_action">
            {_disabledBtnSubmit && (
              <div className="error">* มีภารกิจในช่วงเวลาดังกล่าวแล้ว *</div>
            )}
            {initialValues ? (
              <div className="body_btn_2">
                <div className="box_2">
                  <Buttons.BtnNormal
                    theme_standard_btn_round_gray
                    label="ลบภารกิจ"
                    onClick={handleDelete}
                    borderRadius='7px'
                  />
                </div>
                <div className="box_2">
                  <Buttons.BtnNormal
                    theme_purple
                    label="บันทึกการแก้ไข"
                    type="submit"
                  // disabled={disableButton}
                  />
                </div>
              </div>
            ) : (
              <div className="body_btn">
                <Buttons.BtnNormal
                  theme_purple
                  label="เพิ่มภารกิจ"
                  labelBottom="Add Mission"
                  type="submit"
                  disabled={_disabledBtnSubmit}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </AddMissionFormStyled>
  );
};

const groupCategory = [
  {
    label: 'ก้าว',
    value: 'step',
  },
  {
    label: 'ขยับร่างกาย',
    value: 'mission_move',
  },
  {
    label: 'ผ่อนคลายสายตา',
    value: 'mission_eye',
  },
];
const subCategory = [
  {
    label: 'ก้าวสะสม',
    value: 'step_step_count',
  },
  {
    label: 'ขยับร่างกายสะสม',
    value: 'mission_move_count',
  },
  {
    label: 'ผ่อนคลายสายตาสะสม',
    value: 'mission_eye_count',
  },
];
AddMissionForm.propTypes = {};

export default AddMissionForm;

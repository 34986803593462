import styled from "styled-components";

export const NavBarStyled = styled.div`
  .theme_standard_navbar {
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 8px 32px 0px 16px;
    display: flex;
    justify-content: space-between;
    max-height: 64px;
    .group_menu {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      min-width: 130px;

      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #858585;
      }
      ::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #666666;
      }
      ::-webkit-scrollbar-track:active {
        background: #d1c7c7;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }

      // &::-webkit-scrollbar {
      //   width: 2px;
      //   height: 2px;
      // }

      // &::-webkit-scrollbar-track {
      //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: darkgrey;
      //   outline: 1px solid slategrey;
      // }

      .tab {
        padding: 8px;
        min-width: 104px;
        width: 104px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        .icon_show {
          min-height: 30px;
        }
        &.active {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          background: ${({ theme }) => theme.COLORS.GRAY_11};
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        }
      }
    }
    .group_right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin-left: 15px;
      .input_wrap {
      }
    }
  }
`;

import styled from 'styled-components';

export const DepartmentCreateEditContainerStyled = styled.div`
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 15px 32px 80px 32px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_3};
`;

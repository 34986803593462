import { AveragePostPerMonthStyled } from "./styled";
import { Inputs, Blocks } from "components";
import Chart from "react-apexcharts";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import React, { useEffect } from "react";

const AveragePostPerMonth = ({ data, handleChangDate }) => {
  useEffect(() => {}, []);
  return (
    <AveragePostPerMonthStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="28px"
        paddingRight="24px"
        paddingBottom="4px"
        paddingLeft="24px"
        // height='560px'
      >
        <div className="top_current">
          <div className="left_top">
            <div className="label_show">
              <div className="title_show">จำนวนโพสต์เฉลี่ยต่อเดือน</div>
              <div>Average posts per month</div>
            </div>
          </div>
          <div className="right_top">
            <Inputs.InputDatePikerCustom
              theme_year
              dateFormat="yyyy"
              onChangeCustom={(e) => handleChangDate(e)}
              showYearPicker
              initialValues={new Date()}
            />
          </div>
        </div>
        <div className="graph_current">
          <Chart
            series={[
              {
                data: data && data,
              },
            ]}
            options={{
              chart: {
                type: "bar",
                height: 160,
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              tooltip: {
                enabled: true,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  return (
                    '<div class="tool_box">' +
                    "<span>" +
                    series[seriesIndex][dataPointIndex] +
                    "</span>" +
                    "</div>"
                  );
                },
              },
              // responsive: [
              //   {
              //     breakpoint: 480,
              //     options: {
              //       legend: {
              //         position: 'bottom',
              //         offsetX: -10,
              //         offsetY: 0,
              //       },
              //     },
              //   },
              // ],
              xaxis: {
                categories: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                labels: {
                  rotate: -85,
                  // formatter: function (value) {
                  //   return RenderCommaMoney((Number(value)))
                  // },
                  hideOverlappingLabels: false,
                  style: {
                    // colors: ['#9E9E9E', '#9E9E9E', '#9E9E9E', '#9E9E9E'],
                    fontSize: "12px",
                    fontFamily: "noto_sans_thai_regular, noto_sans_regular",
                    fontWeight: 100,
                    cssClass: "apexcharts-yaxis-label",
                  },
                },
                axisBorder: {
                  show: true,
                  color: "#D9D9D9",
                  height: 3,
                },
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#D9D9D9",
                  width: 3,
                },
                title: {
                  style: {
                    fontSize: "12px",
                    fontFamily: "noto_sans_thai_medium, noto_sans_regular",
                  },
                },
                labels: {
                  rotate: 0,
                  formatter: function (value) {
                    // return RenderCommaMoney(Number(value).toFixed(2))
                    return RenderCommaMoney(Number(value).toFixed(2));
                  },
                  style: {
                    colors: [],
                    fontSize: "14px",
                    fontFamily: "noto_sans_thai_regular, noto_sans_regular",
                    fontWeight: 100,
                    cssClass: "apexcharts-yaxis-label",
                  },
                },
              },
              colors: ["#B6E7E8"],
              fill: {
                opacity: 1,
              },

              plotOptions: {
                bar: {
                  columnWidth: "60%",
                  distributed: true,
                },
              },
              dataLabels: {
                enabled: false,
                // formatter: function (val) {
                //   return RenderCommaMoney(Number(val).toFixed(2))
                // },
              },
              legend: {
                show: false,
              },
            }}
            type="bar"
            height={160}
            width="100%"
          />
        </div>
      </Blocks.Box>
    </AveragePostPerMonthStyled>
  );
};

export default AveragePostPerMonth;

export default function Reward3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49.452" height="31.863" viewBox="0 0 49.452 31.863">
      <g id="Group_13914" data-name="Group 13914" transform="translate(0 0)">
        <path id="Path_1260" data-name="Path 1260" d="M11.066,243.847l-10.03-2.692a.883.883,0,0,0-.054.224,7.7,7.7,0,0,0,3.071,5.071h0a.949.949,0,0,0-.054.224c-.037,2.467,3.266,5.125,3.266,5.125a1.093,1.093,0,0,0-.054.224,6.9,6.9,0,0,0,3.114,5.084l5.508,1.478Z" transform="translate(-0.887 -235.448)" fill="#ffe27a" />
        <g id="Group_330" data-name="Group 330" transform="translate(0 5.925)">
          <path id="Path_1261" data-name="Path 1261" d="M67.7,356.974a4.33,4.33,0,0,1-3.114-3.289,4.334,4.334,0,0,0,3.114,5.084l5.508,1.478.213-1.114-1.4-1Z" transform="translate(-58.26 -343.04)" fill="#f9cf58" />
          <path id="Path_1262" data-name="Path 1262" d="M34.442,301.6a4.33,4.33,0,0,1-3.114-3.289,4.334,4.334,0,0,0,3.114,5.084l6.19,1.661.2-1.061-.874-.916Z" transform="translate(-28.217 -293.012)" fill="#f9cf58" />
          <path id="Path_1263" data-name="Path 1263" d="M3.211,246.763A4.33,4.33,0,0,1,.1,243.474a4.334,4.334,0,0,0,3.114,5.084l7.756,2.081.1-1-.48-.894Z" transform="translate(-0.002 -243.474)" fill="#f9cf58" />
        </g>
        <path id="Path_1264" data-name="Path 1264" d="M362.032,243.847l10.03-2.692a.879.879,0,0,1,.054.224,7.7,7.7,0,0,1-3.071,5.071h0a.952.952,0,0,1,.054.224c.037,2.467-3.266,5.125-3.266,5.125a1.08,1.08,0,0,1,.054.224,6.9,6.9,0,0,1-3.114,5.084l-5.508,1.478Z" transform="translate(-322.76 -235.448)" fill="#ffe27a" />
        <path id="Path_1265" data-name="Path 1265" d="M360.775,356.974a4.33,4.33,0,0,0,3.114-3.289,4.334,4.334,0,0,1-3.114,5.084l-5.508,1.478-.213-1.114,1.4-1Z" transform="translate(-320.76 -337.109)" fill="#f9cf58" />
        <path id="Path_1266" data-name="Path 1266" d="M387.8,301.6a4.33,4.33,0,0,0,3.114-3.289,4.334,4.334,0,0,1-3.114,5.084l-6.19,1.661-.2-1.061.875-.916Z" transform="translate(-344.575 -287.086)" fill="#f9cf58" />
        <path id="Path_1267" data-name="Path 1267" d="M405.3,246.763a4.33,4.33,0,0,0,3.114-3.289,4.334,4.334,0,0,1-3.114,5.084l-7.756,2.081-.1-1,.48-.894Z" transform="translate(-359.054 -237.547)" fill="#f9cf58" />
        <path id="Path_1268" data-name="Path 1268" d="M118.044,182.115l14.749,6.2c-.988,18.933-14.749,24.79-14.749,24.79s-13.577-6.334-14.749-24.79Z" transform="translate(-93.303 -182.115)" fill="#ffcb5b" />
        <path id="Path_1269" data-name="Path 1269" d="M118.222,269.536a.494.494,0,0,1-.355,0c-1.53-.588-12.492-5.423-14.42-23.864l-.152.064c1.5,21.33,14.749,25.66,14.749,25.66s13.248-4.33,14.749-25.66l-.152-.064C130.714,264.113,119.752,268.948,118.222,269.536Z" transform="translate(-93.318 -239.534)" fill="#f7b737" />
        <path id="Path_1276" data-name="Path 1276" d="M156.37,228.176l10.484,4.409c-.891,13.116-10.672,17.7-10.672,17.7a22.535,22.535,0,0,1-10.672-17.7L156,228.176A.482.482,0,0,1,156.37,228.176Z" transform="translate(-131.457 -223.692)" fill="#ff9838" />
        <path id="Path_1277" data-name="Path 1277" d="M156,290.236c-1.279-.509-8.816-4.023-10.323-16.771l-.164.069c1.086,15.433,10.672,18.566,10.672,18.566s9.585-3.133,10.672-18.566l-.164-.069c-1.507,12.748-9.044,16.263-10.323,16.771A.5.5,0,0,1,156,290.236Z" transform="translate(-131.457 -264.636)" fill="#ff8709" />
        <path id="Intersection_1" data-name="Intersection 1" d="M0,1.254,2.892.038a.483.483,0,0,1,.374,0L6.159,1.254Z" transform="translate(21.646 4.45)" fill="#e88a33" />
        <g id="Group_13670" data-name="Group 13670" transform="translate(16.172 6.714)">
          <path id="Path_18957" data-name="Path 18957" d="M112.191,130.5a2,2,0,0,0,.242-.5c0-.009.006-.018.009-.027.019-.063.035-.127.048-.192l0-.024a1.961,1.961,0,0,0,.034-.327c0-.016,0-.031,0-.047,0-.051-.005-.1-.01-.153s-.005-.073-.011-.109-.016-.089-.026-.134-.02-.086-.032-.128-.025-.08-.04-.12-.038-.094-.059-.14-.032-.071-.051-.105c-.029-.053-.062-.1-.1-.155-.018-.027-.035-.055-.055-.082a2.028,2.028,0,0,0-.192-.224l-6.576-6.576a1.941,1.941,0,0,0-1.352-.569H104a1.945,1.945,0,0,0-1.378.57l-6.576,6.576a1.932,1.932,0,0,0-.471.76l0,.007a1.869,1.869,0,0,0-.068.271h0a1.917,1.917,0,0,0-.022.469c0,.008,0,.015,0,.023.005.059.014.117.024.175,0,.018.006.037.01.055s.007.044.012.066l0-.008,0,.012c0,.019.009.038.014.057a2.068,2.068,0,0,0,.079.235v0a1.994,1.994,0,0,0,.272.466l.014.018a1.946,1.946,0,0,0,.172.188l.01.01a1.874,1.874,0,0,0,.452.317l.012.005a1.861,1.861,0,0,0,.235.1l.053.017a1.917,1.917,0,0,0,.224.052l.06.011a2.059,2.059,0,0,0,.291.023h13.153a2.057,2.057,0,0,0,.288-.022c.029,0,.057-.01.085-.016a1.894,1.894,0,0,0,.187-.044l.089-.028c.059-.021.117-.044.173-.07.024-.011.049-.021.073-.033a1.841,1.841,0,0,0,.215-.128c.022-.015.04-.032.061-.048.046-.035.09-.07.132-.109s.056-.054.083-.082.058-.062.085-.094.056-.067.083-.1S112.172,130.528,112.191,130.5Z" transform="translate(-95.479 -119.508)" fill="#5c5c5c" opacity="0.303" />
          <g id="Group_13669" data-name="Group 13669" transform="translate(0 0)">
            <g id="Group_13667" data-name="Group 13667">
              <path id="Path_18946" data-name="Path 18946" d="M102.632,116.455a1.942,1.942,0,0,1,1.378-.57v10.474H97.434a1.949,1.949,0,0,1-1.378-3.327Z" transform="translate(-95.478 -115.885)" fill="#f8eaea" />
              <path id="Path_18947" data-name="Path 18947" d="M129.376,116.455a1.942,1.942,0,0,0-1.378-.57v10.474h6.576a1.949,1.949,0,0,0,1.378-3.327Z" transform="translate(-119.471 -115.885)" fill="#fff5f5" />
              <path id="Path_18948" data-name="Path 18948" d="M112.463,146.455H95.547a1.95,1.95,0,0,0,1.881,2.46h13.152A1.95,1.95,0,0,0,112.463,146.455Z" transform="translate(-95.478 -138.441)" fill="#efe2dd" />
              <path id="Path_18949" data-name="Path 18949" d="M96.042,123.935l6.576-6.576a1.949,1.949,0,0,1,2.756,0l6.576,6.576a1.923,1.923,0,0,1,.517.921,1.909,1.909,0,0,0-.517-1.825l-6.576-6.576a1.95,1.95,0,0,0-2.756,0l-6.576,6.576a1.908,1.908,0,0,0-.517,1.825A1.925,1.925,0,0,1,96.042,123.935Z" transform="translate(-95.47 -115.885)" fill="#fff" />
            </g>
            <g id="Group_13668" data-name="Group 13668" transform="translate(4.808 10.473)">
              <path id="Path_18950" data-name="Path 18950" d="M113.813,168.529l3.195,1.844a1.048,1.048,0,0,0,1.049,0l3.194-1.844V155.837h-7.437v12.692Z" transform="translate(-113.813 -155.837)" fill="#fff5f5" />
              <path id="Path_18951" data-name="Path 18951" d="M118.056,200.1a1.048,1.048,0,0,1-1.049,0l-3.195-1.844v1.573l3.195,1.844a1.048,1.048,0,0,0,1.049,0l3.194-1.844v-1.573Z" transform="translate(-113.813 -187.133)" fill="#efe2dd" />
              <path id="Path_18952" data-name="Path 18952" d="M113.812,166.956l1.29.745,1.9,1.1a1.049,1.049,0,0,0,.523.141v-13.1h-3.718v11.118Z" transform="translate(-113.812 -155.838)" fill="#f8eaea" />
              <rect id="Rectangle_941" data-name="Rectangle 941" width="7.437" height="2.579" transform="translate(0 0)" fill="#f8eaea" />
              <path id="Path_18953" data-name="Path 18953" d="M138.5,155.838v11.677l.965-.557V155.838Z" transform="translate(-132.028 -155.838)" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import styled from 'styled-components'

export const MissionRewardStyled = styled.div`
height: ${({ heightBox }) => heightBox ? heightBox : '250px'};
  .container_box {
    height: ${({ heightBox }) => heightBox ? heightBox : '250px'};
    .top_current {
      display: flex;
      align-items: center;
      .show_icon {
        margin-right: 4px;
        display: flex;
      }
    }
    .bottom_current {
      margin-top: 27px;
      .group_icon {
        width: 100%;
        display: flex;
        align-items: center;
        .left_icon {
          width: 20%;
          display: flex;
          justify-content: center;
        }
        .right_icon {
          width: 80%;
          margin-left: 20px;
          .show_label {
            font-family: noto_sans_thai_semi_bold, noto_sans_regular;
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            color:  ${({ theme }) => theme.COLORS.GOLD_1};
            &.orange {
              font-family: noto_sans_thai_bold, noto_sans_bold;
              font-size: ${({ theme }) => theme.FONT.SIZE.S18};
              color:  ${({ theme }) => theme.COLORS.ORANGE_4};
            }
            &.black {
              font-size: ${({ theme }) => theme.FONT.SIZE.S18};
              color:  ${({ theme }) => theme.COLORS.BLACK_1};
            }
          }
        }
      }
    }
  }
`

export default function LuckSpin() {
  return (
    <svg width="115" height="114" viewBox="0 0 115 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.498 89.4032C81.2322 89.4032 100.473 70.1628 100.473 46.4286C100.473 22.6945 81.2322 3.4541 57.498 3.4541C33.7638 3.4541 14.5234 22.6945 14.5234 46.4286C14.5234 70.1628 33.7638 89.4032 57.498 89.4032Z" fill="#FEC200" />
      <path d="M57.5034 89.4203C55.6552 89.4203 53.8416 89.2822 52.0625 89.0576V95.8803H62.9616V89.0576C61.1652 89.2822 59.3516 89.4203 57.5034 89.4203Z" fill="#AAB2BD" />
      <path opacity="0.1" d="M55.517 92.4258V89.3513C54.3598 89.2994 53.2025 89.2131 52.0625 89.0576V95.8803H62.9616V92.4258H55.517Z" fill="black" />
      <path d="M45.2649 16.9268L53.9013 37.7922C51.5176 38.7768 49.7558 40.6422 48.8404 42.8531L27.9922 34.1995C31.1186 26.6513 37.1295 20.2949 45.2649 16.9268Z" fill="#FF4823" />
      <path d="M87.014 34.2168L66.1312 42.8531C65.1467 40.4695 63.2812 38.7077 61.0703 37.7922L69.7067 16.9268C77.2721 20.0531 83.6458 26.064 87.014 34.2168Z" fill="#FF4823" />
      <path d="M53.9046 55.0827L45.2682 75.9482C37.7028 72.8218 31.3291 66.8109 27.9609 58.6754L48.8264 50.0391C49.8282 52.4054 51.6937 54.1672 53.9046 55.0827Z" fill="#FF4823" />
      <path d="M86.9967 58.6578C83.8703 66.2233 77.8594 72.5797 69.7067 75.9479L61.0703 55.0824C63.4539 54.0978 65.2158 52.2324 66.1312 50.0215L86.9967 58.6578Z" fill="#FF4823" />
      <path d="M48.8481 42.8529C47.9326 45.081 47.8635 47.6374 48.8481 50.021L27.9826 58.6574C24.6144 50.5219 24.8735 41.7647 27.9999 34.1992L48.8481 42.8529Z" fill="#E6E9EE" />
      <path d="M69.716 16.9265L61.0624 37.792C58.8342 36.8765 56.2778 36.8074 53.8942 37.792L45.2578 16.9265C53.4105 13.5584 62.1678 13.8002 69.716 16.9265Z" fill="#E6E9EE" />
      <path d="M87.0233 34.2168C90.3915 42.3523 90.1324 51.1095 87.0061 58.675L66.1406 50.0213C67.0561 47.7932 67.1252 45.2368 66.1406 42.8532L87.0233 34.2168Z" fill="#E6E9EE" />
      <path d="M61.078 55.0817L69.7143 75.9472C61.5789 79.3154 52.8389 79.0735 45.2734 75.9299L53.9098 55.0645C56.138 55.9972 58.6943 56.0663 61.078 55.0817Z" fill="#E6E9EE" />
      <path d="M63.563 9.51683C63.563 12.8677 57.5002 23.8359 57.5002 23.8359C57.5002 23.8359 51.4375 12.8677 51.4375 9.51683C51.4375 6.16592 54.1493 3.4541 57.5002 3.4541C60.8511 3.4541 63.563 6.16592 63.563 9.51683Z" fill="#009AFF" />
      <path opacity="0.1" d="M62.9946 6.96094C62.9773 7.01276 62.9601 7.0473 62.9428 7.09912C61.181 12.2291 53.8228 12.2291 52.061 7.09912C52.0437 7.0473 52.0264 7.01276 52.0091 6.96094C51.6464 7.73821 51.4219 8.60185 51.4219 9.5173C51.4219 12.8682 57.4846 23.8364 57.4846 23.8364C57.4846 23.8364 63.5473 12.8682 63.5473 9.5173C63.5646 8.60185 63.3573 7.75548 62.9946 6.96094Z" fill="black" />
      <path d="M57.5024 55.79C52.3379 55.79 48.1406 51.5928 48.1406 46.4282C48.1406 41.2637 52.3379 37.0664 57.5024 37.0664C62.667 37.0664 66.8643 41.2637 66.8643 46.4282C66.8643 51.5928 62.667 55.79 57.5024 55.79Z" fill="#5D5B68" />
      <path opacity="0.1" d="M60.9508 52.3363C55.7863 52.3363 51.589 48.139 51.589 42.9745C51.589 41.2818 52.0554 39.6927 52.8326 38.3281C50.0172 39.9518 48.1172 42.9745 48.1172 46.4463C48.1172 51.6109 52.3145 55.8081 57.479 55.8081C60.9508 55.8081 63.9736 53.9081 65.5972 51.0927C64.2326 51.8872 62.6436 52.3363 60.9508 52.3363Z" fill="black" />
      <path d="M57.5014 49.8827C59.4093 49.8827 60.956 48.3361 60.956 46.4282C60.956 44.5203 59.4093 42.9736 57.5014 42.9736C55.5935 42.9736 54.0469 44.5203 54.0469 46.4282C54.0469 48.3361 55.5935 49.8827 57.5014 49.8827Z" fill="#AAB2BD" />
      <path d="M94.9616 48.1567C95.9156 48.1567 96.6889 47.3834 96.6889 46.4294C96.6889 45.4755 95.9156 44.7021 94.9616 44.7021C94.0077 44.7021 93.2344 45.4755 93.2344 46.4294C93.2344 47.3834 94.0077 48.1567 94.9616 48.1567Z" fill="#FF4823" />
      <path d="M20.032 48.1567C20.9859 48.1567 21.7592 47.3834 21.7592 46.4294C21.7592 45.4755 20.9859 44.7021 20.032 44.7021C19.078 44.7021 18.3047 45.4755 18.3047 46.4294C18.3047 47.3834 19.078 48.1567 20.032 48.1567Z" fill="#FF4823" />
      <path d="M83.9929 21.6772C84.9468 21.6772 85.7202 20.9039 85.7202 19.9499C85.7202 18.996 84.9468 18.2227 83.9929 18.2227C83.039 18.2227 82.2656 18.996 82.2656 19.9499C82.2656 20.9039 83.039 21.6772 83.9929 21.6772Z" fill="#FF4823" />
      <path d="M31.0007 74.6528C31.9547 74.6528 32.728 73.8795 32.728 72.9255C32.728 71.9716 31.9547 71.1982 31.0007 71.1982C30.0468 71.1982 29.2734 71.9716 29.2734 72.9255C29.2734 73.8795 30.0468 74.6528 31.0007 74.6528Z" fill="#FF4823" />
      <path d="M57.4929 85.6381C58.4468 85.6381 59.2202 84.8648 59.2202 83.9109C59.2202 82.9569 58.4468 82.1836 57.4929 82.1836C56.539 82.1836 55.7656 82.9569 55.7656 83.9109C55.7656 84.8648 56.539 85.6381 57.4929 85.6381Z" fill="#FF4823" />
      <path d="M31.0007 21.6772C31.9547 21.6772 32.728 20.9039 32.728 19.9499C32.728 18.996 31.9547 18.2227 31.0007 18.2227C30.0468 18.2227 29.2734 18.996 29.2734 19.9499C29.2734 20.9039 30.0468 21.6772 31.0007 21.6772Z" fill="#FF4823" />
      <path d="M83.9929 74.6528C84.9468 74.6528 85.7202 73.8795 85.7202 72.9255C85.7202 71.9716 84.9468 71.1982 83.9929 71.1982C83.039 71.1982 82.2656 71.9716 82.2656 72.9255C82.2656 73.8795 83.039 74.6528 83.9929 74.6528Z" fill="#FF4823" />
      <path d="M86.6565 110.545H28.3438L33.8019 103.273H81.1983L86.6565 110.545Z" fill="#AAB2BD" />
      <path d="M73.2176 103.274V97.6081C73.2176 96.6581 72.4403 95.8809 71.4903 95.8809H43.5085C42.5585 95.8809 41.7812 96.6581 41.7812 97.6081V103.274H73.2176Z" fill="#5D5B68" />
      <path opacity="0.1" d="M49.1584 99.819C46.982 99.819 45.2202 98.0572 45.2202 95.8809H43.4929C42.5429 95.8809 41.7656 96.6581 41.7656 97.6081V103.274H73.2193V99.819H49.1584Z" fill="black" />
      <path opacity="0.1" d="M26.0633 17.1345C18.8951 24.8209 14.5078 35.1155 14.5078 46.4464C14.5078 70.1791 33.7496 89.4209 57.4824 89.4209C68.8133 89.4209 79.1078 85.0336 86.7942 77.8655C87.7096 76.9155 88.5905 75.9309 89.4024 74.8946C82.0615 80.7327 72.786 84.2391 62.6642 84.2391C38.9315 84.2391 19.6896 64.9973 19.6896 41.2646C19.6896 31.16 23.1787 21.8673 29.0342 14.5264C28.0151 15.3382 27.0305 16.2191 26.0633 17.1345Z" fill="black" />
    </svg>
  );
}

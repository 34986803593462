import styled from "styled-components";

export const TotalEmployeeHealthCheckupStyled = styled.div`
  height: 100%;
  .box_shadow_total {
    height: calc(100% - 50px);
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 24px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    .box_total_emp {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .b_text_emp {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        /* text-align: center; */
      }
      .s_text_emp {
        color: ${({ theme }) => theme.COLORS.GRAY_24};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        /* text-align: center; */
      }
      .graph_show {
        display: flex;
        justify-content: center;
        margin-top: 32px;
      }
      .big_num {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S32};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        text-align: center;
        margin-top: 32px;
      }
      .small_num {
        color: ${({ theme }) => theme.COLORS.GRAY_26};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        text-align: center;
      }
    }
  }
`;

import { ListEmployeeStyled } from "./styled";
import { Icons } from "components";
import { Tabs } from "components";
import List from "./List";

const ListEmployee = ({ onClose, dataPage, department_detail, fetchData }) => {
  const ChangeTitle = (e) => {
    fetchData &&
      fetchData({
        departmen_id: department_detail.id,
        is_deleted: e.is_deleted,
      });
  };
  const headerTab = [
    {
      label: "สถานะผู้เข้าร่วม Active",
      is_deleted: 0,
      component: (
        <List
          dataPage={dataPage}
          department_detail={department_detail}
          fetchData={fetchData}
          is_deleted={0}
        />
      ),
    },
    {
      label: "สถานะผู้เข้าร่วม Inactive",
      is_deleted: 1,
      component: (
        <List
          dataPage={dataPage}
          department_detail={department_detail}
          fetchData={fetchData}
          is_deleted={1}
        />
      ),
    },
  ];

  return (
    <ListEmployeeStyled>
      <div className="group_list_emp">
        <div className="top_list">
          <div className="group_text">
            <div>{department_detail?.name}</div>
            <div className="small_1">{/* Marketing */}</div>
          </div>
          <div className="line" />
          <div className="group_text">
            <div className="small_2">จำนวนผู้ที่เข้าร่วม</div>
            <div className="small_3">Total Participants</div>
          </div>
          <div className="group_text">
            <div>{`${
              dataPage && dataPage.participants ? dataPage.participants : 0
            } คน`}</div>
          </div>
          <div className="svg_x" onClick={onClose}>
            <Icons.CloseXModal color="#000000" />
          </div>
        </div>

        <>
          <Tabs.TabBarSelect
            tab_small
            tabHeader={headerTab}
            onClickSeletedTab={ChangeTitle}
          />
        </>
      </div>
    </ListEmployeeStyled>
  );
};

export default ListEmployee;

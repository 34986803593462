import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types';
import { TableRank3Styled } from './styled';
import { Icons, Buttons } from 'components';

const TableRank3 = ({
  theme_standard_table_rank3,
  data,
  handleClickDelete,
  perMissions,
}) => {
  const customClass = cx({
    theme_standard_table_rank3: theme_standard_table_rank3,
  });

  return (
    <TableRank3Styled >
      <div className={customClass}>
        {!data || data.length === 0 ? (
          <div className="label_empty">ยังไม่มีข้อมูล</div>
        ) : (
          <>
            <div className='group_row'>
              {
                data.map((e, i) => (
                  <div key={i + 1} className="row">
                    <div className="round">{i + 1}</div>
                    {/* <div className="col1"
                        style={
                          e && e.profile && e.profile.profile_picture_uri
                            ? {
                              backgroundImage: `url(${e.profile.profile_picture_uri})`,
                            }
                            : {
                              background: `#b1b1c130`,
                            }
                        }
                        alt="img"
                      /> */}
                    <div className="col2">
                      {`${e.employee.firstname} ${e.employee.lastname}`}
                    </div>
                    <div className="col3">{e.employee.department.name}</div>
                    {
                      perMissions &&
                      <div className="col4">
                        <Buttons.BtnNormal
                          theme_only_border_blue
                          label="ลบ"
                          fontColor="#fff"
                          backgroundColor='#C4C4C4'
                          borderColor='#C4C4C4'
                          svg_front={
                            <Icons.Delete3
                              width='12.75'
                              height='16.35'
                              color='#fff'
                              color2='#C4C4C4'
                            />
                          }
                          onClick={() => handleClickDelete(e)}
                        />
                      </div>
                    }
                  </div>
                ))
              }
            </div>
          </>
        )}
      </div>
    </TableRank3Styled>
  );
};

// TableRank3.propTypes = {};

export default TableRank3;

import React from 'react';
import { CreateEditMissionContainerStyled } from './styled';
import { ManageMission } from 'widgets';
import { Loading } from 'components';
import { adminService } from 'apiServices';
import { connect } from 'react-redux';
import { ROUTE_PATH } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getObjectKey } from "utils/functions/getObjectKey";

class CreateEditMissionContainer extends React.Component {
  state = {
    isLoading: true,
    dataMission: { data: [] },
    hasMore: true,
    pagination: {
      offset: 0,
      per_page: 10,
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission()
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
    }
  }

  fetchData = async () => {
    const {
      pagination: { per_page },
    } = this.state;
    let res = await Promise.all([this.fetchDataCurrent()]);
    if (res) {
      if (!res[0].data || res[0].data.length === 0) {
        this.setState({
          hasMore: false,
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          isLoading: false,
          dataMission: {
            ...res[0],
            data: [...state.dataMission.data, ...res[0].data],
          },
          hasMore: res[0].data.length < per_page ? false : true,
        }));
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchMoreData = () => {
    this.setState(
      (state) => ({
        pagination: {
          offset: state.pagination.offset + state.pagination.per_page,
          per_page: state.pagination.per_page,
        },
      }),
      () => {
        this.fetchData();
      }
    );
  };

  checkPermission = () => {
    const { authenRedux } = this.props

    let checkPermission = getObjectKey(authenRedux.admin_role.permissions, 'company_mission_manage')
    this.setState({
      perMissions: checkPermission,
    })
  };


  fetchDataCurrent = async () => {
    const { companySelectedRedux } = this.props;
    const {
      pagination: { offset, per_page },
    } = this.state;
    let res = await adminService.GET_COMANY_MISSIONS(
      companySelectedRedux.id,
      'current_upcoming',
      per_page,
      offset
    );
    if (res && res.success) {
      return res;
    } else {
      return [];
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleEdit = (e) => {
    const { LinkHistory, page } = this.props;
    LinkHistory.push(`${ROUTE_PATH.BACK_OFFICE_MISSION}/${e.id}?from=${page}`);
  };

  render() {
    const { isLoading, dataMission, hasMore, perMissions } = this.state;

    return (
      <CreateEditMissionContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            {dataMission && dataMission.data.length > 0 ? (
              <InfiniteScroll
                dataLength={dataMission.data.length}
                next={this.fetchMoreData}
                hasMore={hasMore}
                loader={<h3 className="loading">Loading...</h3>}
                height={'calc(100vh - 290px)'}
                endMessage={
                  <p className="loading">
                    <b>ดูครบทั้งหมดแล้ว</b>
                  </p>
                }
              >
                {dataMission.data.map((e, i) => (
                  <div key={i + 1} className="box_show">
                    <ManageMission
                      label={`ลำดับที่ ${e.order}`}
                      dataMission={e}
                      heightBox='245px'
                      heightBox2='227px'
                      perMissions={perMissions}
                      dataCompany={
                        this.props.authenRedux.company &&
                        this.props.authenRedux.company
                      }
                      handleEdit={() => this.handleEdit(e)}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className="label_empty">ยังไม่มีภารกิจ</div>
            )}
          </>
        )}
      </CreateEditMissionContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditMissionContainer);

import styled from 'styled-components';

export const TopBottomStyled = styled.div`
  .theme_standard_top_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 'unset')};
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : 'noto_sans_thai_medium, noto_sans_regular'};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      white-space: nowrap;
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : 'noto_sans_thai_medium, noto_sans_regular'};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '3px'};
    }
  }

  .theme_mini_top_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.4;
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : 'noto_sans_thai_bold, noto_sans_bold'};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      white-space: nowrap;
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : 'noto_sans_thai_regular, noto_sans_regular'};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S14};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '0px'};
    }
  }
  .theme_left_align {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    .top_label {
      font-size: ${(props) =>
    props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : 'noto_sans_thai_medium, noto_sans_regular'};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : 'noto_sans_thai_medium, noto_sans_regular'};
      font-size: ${(props) =>
    props.fontSizeLabelButtom
      ? props.fontSizeLabelButtom
      : ({ theme }) => theme.FONT.SIZE.S16};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '0px'};
    }
  }

  .theme_left_align_2 {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    .top_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${(props) =>
    props.fontFamilyTop
      ? props.fontFamilyTop
      : 'noto_sans_thai_medium, noto_sans_regular'};
      color: ${(props) =>
    props.fontColor
      ? props.fontColor
      : ({ theme }) => theme.COLORS.BLACK_1};
    }
    .bottom_label {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: ${(props) =>
    props.fontFamilyBottom
      ? props.fontFamilyBottom
      : 'noto_sans_thai_medium, noto_sans_regular'};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      color: ${(props) =>
    props.colorBottom
      ? props.colorBottom
      : ({ theme }) => theme.COLORS.GRAY_1};
      margin-top: ${(props) =>
    props.marginTopBottomLabel ? props.marginTopBottomLabel : '0px'};
    }
  }

  .theme_left_align_3 {
    display: flex;
    flex-direction: column;
    .top_label {
      font-size: ${({ fontSize, theme }) => fontSize ? fontSize : theme.FONT.SIZE.S18};
      font-family: ${({ fontFamilyTop, theme }) => fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.BOLD};
      color: ${({ fontColor, theme }) => fontColor ? fontColor : theme.COLORS.BLACK_1};
    }
    .bottom_label {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: ${({ fontFamilyBottom, theme }) => fontFamilyBottom ? fontFamilyBottom : theme.FONT.STYLE.REGULAR};
      font-size: ${({ fontSizeLabelButtom, theme }) => fontSizeLabelButtom ? fontSizeLabelButtom : theme.FONT.SIZE.S14};
      color: ${({ colorBottom, theme }) => colorBottom ? colorBottom : theme.COLORS.GRAY_1};
      margin-top: ${({ marginTopBottomLabel }) => marginTopBottomLabel ? marginTopBottomLabel : '0px'};
    }
  }
`;

export default function WomenGraph() {
  return (
    <svg id="Group_15006" data-name="Group 15006" xmlns="http://www.w3.org/2000/svg" width="33.692" height="48" viewBox="0 0 33.692 48">
      <g id="Group_184" data-name="Group 184" transform="translate(10.31 7.161)">
        <g id="Group_182" data-name="Group 182" transform="translate(4.946 15.851)">
          <path id="Path_852" data-name="Path 852" d="M154.2,78.9c-.153-.407,2.5-.717,2.5-.717s.006,1.977.077,2.81a9.653,9.653,0,0,1-.279,3.543,30.423,30.423,0,0,0-.309,4.579,4.288,4.288,0,0,0,.981,2.512c.228.361-.642.7-1.192.176s-.608-.629-.919-.794-.206-.559-.183-1.384c.017-.638-.259-2.763-.365-4.126-.15-1.934.274-1.976.244-2.954A14.586,14.586,0,0,0,154.2,78.9Z" transform="translate(-154.195 -78.178)" fill="#ffad97" />
          <path id="Path_853" data-name="Path 853" d="M155.306,109.6l0-.01a.446.446,0,0,1,.017-.051h0a1.221,1.221,0,0,1,.234-.362,2.653,2.653,0,0,0,.818,1.652c.767.539,1.11.619,1.475.351a7.7,7.7,0,0,0,.608.744c.227.252.036.5-.633.456a1.686,1.686,0,0,1-1.139-.563,8.694,8.694,0,0,0-.8-1.026l-.117-.106h0c-.02.31-.011.508-.026.732-.065.1-.164.078-.242,0a4.38,4.38,0,0,0-.125-.93A1.689,1.689,0,0,1,155.306,109.6Z" transform="translate(-154.879 -97.739)" fill="#4f4faa" />
        </g>
        <path id="Path_854" data-name="Path 854" d="M148.659,81.748c-.153-.407,2.5-.717,2.5-.717s.006,1.977.078,2.81a9.653,9.653,0,0,1-.279,3.543,30.329,30.329,0,0,0-.309,4.579,4.287,4.287,0,0,0,.981,2.512c.228.361-.642.7-1.192.176s-.608-.63-.918-.795-.206-.559-.183-1.384c.017-.638-.259-2.763-.365-4.126-.151-1.934.274-1.976.244-2.954A14.606,14.606,0,0,0,148.659,81.748Z" transform="translate(-145.752 -64.128)" fill="#ffc5b3" />
        <g id="Group_183" data-name="Group 183" transform="translate(3.309 28.313)">
          <path id="Path_855" data-name="Path 855" d="M149.786,112.369l0-.011a.391.391,0,0,1,.017-.051h0a1.214,1.214,0,0,1,.234-.362,2.653,2.653,0,0,0,.818,1.652c.768.538,1.11.619,1.475.351a7.7,7.7,0,0,0,.608.744c.227.252.036.5-.633.456a1.689,1.689,0,0,1-1.139-.563,8.665,8.665,0,0,0-.8-1.026l-.117-.106h0c-.02.31-.011.508-.026.732-.065.1-.164.078-.242,0a4.352,4.352,0,0,0-.125-.93A1.693,1.693,0,0,1,149.786,112.369Z" transform="translate(-149.758 -111.947)" fill="#4f4faa" />
        </g>
        <path id="Path_856" data-name="Path 856" d="M159.429,49.492c-.092-.148-.183-.292-.272-.426-.69-1.042-1.647-3.4-2.9-3.858-1.282-.47-1.514.428-1.514.428l3.066,5.122A3,3,0,0,0,159.429,49.492Z" transform="translate(-149.596 -41.438)" fill="#4f4faa" />
        <path id="Path_857" data-name="Path 857" d="M150.2,40.892a4.48,4.48,0,0,0-.826,2.3,10.216,10.216,0,0,0-.147,2.056s1.38.172,1.691-.535S150.2,40.892,150.2,40.892Z" transform="translate(-146.111 -38.802)" fill="#5e2e2e" />
        <path id="Path_858" data-name="Path 858" d="M153.094,65.875a12.953,12.953,0,0,1,.18,2.106c.033,1.671-.173,5.044-.173,5.044a2.639,2.639,0,0,1-1.414.854,4.648,4.648,0,0,1-2.563-.067c-.171-.748-.321-1.876-.321-1.876-.047.53-.276,1.273-.289,1.764a1.519,1.519,0,0,1-.714-.383s-.131-1.362-.261-2.837a8.738,8.738,0,0,1,.556-4.264C149.056,63.815,153.039,64.576,153.094,65.875Z" transform="translate(-145.017 -53.794)" fill="#4f4faa" />
        <path id="Path_859" data-name="Path 859" d="M146.938,48.509c.129-.9,2.178-2.119,2.178-2.119a3.889,3.889,0,0,0,.65,1.249,7.964,7.964,0,0,1,.844.891h.007a6.8,6.8,0,0,0-.378-1.744,3.217,3.217,0,0,1,.051-1.049s.125-.031.305-.063c.271.3.8,1.1,1.092,1.427a3.156,3.156,0,0,1,.848,2.466,11.494,11.494,0,0,0-.3,2.5,14.833,14.833,0,0,0,.559,2.106,2.1,2.1,0,0,1-2.463,1.016,5.653,5.653,0,0,1-2.478-1.088,23.071,23.071,0,0,0,.3-3.1A2.412,2.412,0,0,1,146.938,48.509Z" transform="translate(-144.651 -41.817)" fill="#fff" />
        <path id="Path_860" data-name="Path 860" d="M156.951,47.111s.444,1.261.856,2.373.406,4.2.526,4.658c.2.75.733,1.043,1.093.786,0,0-.826-3.624-.554-4.867s-.026-1.554-.871-2.676-.823-.911-.823-.911Z" transform="translate(-150.987 -42.314)" fill="#6262c4" />
        <path id="Path_861" data-name="Path 861" d="M156.674,47.138c.327.441.945,1.28.945,1.28l-.243.34a1.3,1.3,0,0,1,.373.343c.007.565-.172,2.149-.172,2.149a11.1,11.1,0,0,0-1.254-3.235c-.294-.475-.123-.481-.123-.481S156.52,47.12,156.674,47.138Z" transform="translate(-150.483 -42.742)" fill="#4f4faa" />
        <path id="Path_862" data-name="Path 862" d="M153.521,43.384a8.231,8.231,0,0,0,.231,1.442.285.285,0,0,1-.235.334,1.485,1.485,0,0,1-.861-.109c-.674-.54-.775-.822-.775-.822s.138-.63.154-.857c.027-.379,0-1.617,0-1.617Z" transform="translate(-147.789 -39.346)" fill="#ffad97" />
        <path id="Path_863" data-name="Path 863" d="M155.991,50.516a1.558,1.558,0,0,1,.046.493c-.029.2-.159.268-.362.107a1.008,1.008,0,0,1-.288-.4A.481.481,0,0,1,155.991,50.516Z" transform="translate(-150 -44.845)" fill="#ff65a7" />
        <path id="Path_864" data-name="Path 864" d="M156.608,51.966a4.627,4.627,0,0,1,.589,1.349,29.256,29.256,0,0,1,.646,2.953,2.441,2.441,0,0,1-.4.795l-.65-.564-.387-4.426Z" transform="translate(-150.642 -45.789)" fill="#ff65a7" />
        <path id="Path_865" data-name="Path 865" d="M156.428,47.428s.209.15.366.885a1.1,1.1,0,0,1-.094.787l-.547-.619a1.661,1.661,0,0,0,.079-1.053C156.178,47.18,156.428,47.428,156.428,47.428Z" transform="translate(-150.483 -42.856)" fill="#ddccd3" />
        <path id="Path_866" data-name="Path 866" d="M152.119,36.893a1.591,1.591,0,0,1,2.524.715c.61,1.476-.653,3.424-1.4,3.143a2.533,2.533,0,0,1-1.538-1.322A2.792,2.792,0,0,1,152.119,36.893Z" transform="translate(-147.595 -36.05)" fill="#ffc5b3" />
        <path id="Path_867" data-name="Path 867" d="M148.95,47.617a7.97,7.97,0,0,1,1.761,3.595,21.617,21.617,0,0,1,.48,3.051,3.38,3.38,0,0,1-.591,2.6c-1.4,1.088-3.1-.559-3.1-.559a12.189,12.189,0,0,0,.559-2.978c-.118-.7-1-3.764-.714-4.262A8.443,8.443,0,0,1,148.95,47.617Z" transform="translate(-144.892 -43.045)" fill="#6262c4" />
        <path id="Path_868" data-name="Path 868" d="M151.239,47.137a2.088,2.088,0,0,0,.677,1.419,5.222,5.222,0,0,1,1.1,3.178l-1.47-1.859.25-.544-.577-.035a3.1,3.1,0,0,1-.508-1.561C150.709,47.309,151.239,47.137,151.239,47.137Z" transform="translate(-147.048 -42.742)" fill="#4f4faa" />
        <path id="Path_869" data-name="Path 869" d="M153.45,49.772a4.953,4.953,0,0,0,.206-.983,5.353,5.353,0,0,1-1.454-.984A2.122,2.122,0,0,0,153.45,49.772Z" transform="translate(-147.988 -43.164)" fill="#ddccd3" />
        <path id="Path_870" data-name="Path 870" d="M145.422,68.89a.586.586,0,0,0,.781-.145c.407-.42.617-.813.395-1.024s-.592-.247-1.012.123A.86.86,0,0,0,145.422,68.89Z" transform="translate(-143.645 -55.63)" fill="#43439b" />
        <path id="Path_871" data-name="Path 871" d="M143.774,50.325c-.461-.049-1.1.992-1.894,2.375a6.84,6.84,0,0,0-1.088,2.98,1.467,1.467,0,0,0,.282.734,6.217,6.217,0,0,0,1.495,1.6,1.382,1.382,0,0,1,.48-1.037,1.113,1.113,0,0,1,.7-.132l-.982-1.6a10.248,10.248,0,0,0,1.589-2.487C144.722,51.763,144.336,50.386,143.774,50.325Z" transform="translate(-140.792 -44.753)" fill="#4f4faa" />
        <path id="Path_872" data-name="Path 872" d="M152.819,37.142a4.742,4.742,0,0,1-1.971,1.353c-.478-1.079-.572-1.377-.344-2.06a1.141,1.141,0,0,1,1.082-.771,1.458,1.458,0,0,1,2-.083,1.718,1.718,0,0,1,.4,2.665S153.924,37.533,152.819,37.142Z" transform="translate(-146.845 -35.228)" fill="#5e2e2e" />
        <path id="Path_873" data-name="Path 873" d="M152.912,43.132a1.331,1.331,0,0,0-.237-.256c-.106-.081-.306-.1-.364.2a.513.513,0,0,0,.255.576Z" transform="translate(-148.052 -40.021)" fill="#ffc5b3" />
        <path id="Path_874" data-name="Path 874" d="M163.364,54.743c.072.006,3.017-.4,3.39-.409-.117.617-.262.806.288,1.374a6.533,6.533,0,0,1-2.16.741,8.546,8.546,0,0,1-1.516.371.679.679,0,0,1-.612-.218C162.13,55.832,163.364,54.743,163.364,54.743Z" transform="translate(-154.538 -47.283)" fill="#4f4faa" />
      </g>
      <g id="Group_187" data-name="Group 187">
        <g id="Group_185" data-name="Group 185">
          <path id="Path_875" data-name="Path 875" d="M145.177,15.824l-8.41,6.428,2.37,1.632-6.291,12.921L127.6,33.282,112.855,63.029l5.731-3.309L128.548,39.7l5.2,3.536,8.417-17.271,2.109,1.43Z" transform="translate(-112.855 -15.824)" fill="#1f9390" />
        </g>
        <g id="Group_186" data-name="Group 186" transform="translate(1.37 0.795)">
          <path id="Path_876" data-name="Path 876" d="M148.889,17.978l-8.41,6.428,2.37,1.632-6.291,12.921-5.246-3.523L116.568,65.183l5.731-3.309L132.26,41.85l5.2,3.536,8.417-17.271,2.109,1.43Z" transform="translate(-116.568 -17.978)" fill="#2ca8a8" />
        </g>
        <path id="Path_877" data-name="Path 877" d="M114.225,93.672l-1.37-.795L127.6,63.13l1.37.795Z" transform="translate(-112.855 -45.672)" fill="#40c1bd" />
        <path id="Path_878" data-name="Path 878" d="M164.2,86.2l-1.37-.795-3.882-2.639.051-.1Z" transform="translate(-141.94 -57.996)" fill="#147c77" />
        <path id="Path_879" data-name="Path 879" d="M187.428,16.619l-1.37-.795-8.41,6.428,1.37.795Z" transform="translate(-153.736 -15.824)" fill="#40c1bd" />
        <path id="Path_880" data-name="Path 880" d="M177.648,33.243l1.37.795,2.37,1.632-1.37-.795Z" transform="translate(-153.736 -26.815)" fill="#147c77" />
        <path id="Path_881" data-name="Path 881" d="M196.649,46.08l1.37.795-2.109-1.43-.049.1Z" transform="translate(-165.227 -34.514)" fill="#147c77" />
      </g>
      <path id="Path_882" data-name="Path 882" d="M173.583,52.3s.273-.3.551-.566a3.942,3.942,0,0,0,.5-.776c.111-.134.306.077.144.411l-.353.726-.653,1.341S173.234,53.219,173.583,52.3Z" transform="translate(-151.097 -37.968)" fill="#ffad97" />
      <path id="Path_883" data-name="Path 883" d="M145.746,68.9a1.234,1.234,0,0,0,.347.415c.223.108.407.1.611.208a2.143,2.143,0,0,0,.92.355.564.564,0,0,0,.53-.912c-.4-.442-.576-.7-.807-.741a2.7,2.7,0,0,1-.81-.229C145.746,67.937,145.746,68.9,145.746,68.9Z" transform="translate(-133.608 -48.739)" fill="#ffc5b3" />
    </svg>
  );
}

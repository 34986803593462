import React from "react";
import { RewardDetailsContainerStyled } from "./styled";
import { Tabs, Blocks, Buttons, Icons, Typographys, Loading } from "components";
import { RewardTotalViewer, TotalLikeAndExchange } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { adminService } from "apiServices";
import moment from "moment";
import { getObjectKey } from "utils/functions/getObjectKey";
import { connect } from "react-redux";

class RewardDetailsContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_REWARD_ITEM_BY_ID(
      this.props.match.params.id
    );
    if (res && res.success) {
      this.setState({
        isLoading: false,
        data: res.data,
        updateDate: res.data.updated_at && res.data.updated_at,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "reward_manage"
    );
    this.setState({
      perMissions: checkPermission,
    });
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_REWARD);
  };

  handleClickEdit = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_NEW_DETAIL}/${this.props.match.params.id}`
    );
  };

  handleClickExchangeReward = () => {
    this.props.history.push(
      `${ROUTE_PATH.BACK_OFFICE_REWARD_EXCHANGE}/${this.props.match.params.id}`
    );
  };

  render() {
    const { isLoading, data, updateDate, perMissions } = this.state;

    return (
      <RewardDetailsContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <Tabs.HeadTab
                theme_standard_head_tab
                title="รายละเอียดรางวัล"
                subTitle="Rewards Details"
                fontSize="32px"
                fontSizeLabelButtom="18px"
                fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                fontColor="#013D59"
                colorBottom="#9E9E9E"
                margin_right="19px"
                lineHeight="1.3"
                typeDate
                dataDate={updateDate}
                handleBackCustom={this.handleBackClick}
                onClick={this.handleBackClick}
              />
            </div>
            <div className="layer_two">
              <div className="left_two">
                <Blocks.Box
                  theme_standard_box_over_flow
                  paddingTop="16px"
                  paddingRight="24px"
                  paddingBottom="54px"
                  paddingLeft="16px"
                  overflowX="auto"
                >
                  <div className="two_block">
                    <div className="box">
                      <div
                        className="img_show"
                        style={{
                          backgroundImage: `url(${
                            data.image_uri && data.image_uri
                          })`,
                        }}
                      />
                    </div>
                    <div className="box">
                      <div className="group_box">
                        <div className="box_left">
                          <div className="group_label">
                            <div className="label f24">{data?.name}</div>
                            <Typographys.TopBottom
                              theme_left_align
                              label="คำอธิบาย"
                              labelBottom="Description"
                              fontSizeLabelButtom="14px"
                              fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                            />
                            <div
                              className="label f20"
                              dangerouslySetInnerHTML={{
                                __html: data?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="box_right">
                          {perMissions && (
                            <Buttons.BtnCircleLabel
                              theme_standard_btn_circle_label
                              label="แก้ไข"
                              labelBottom="Edit"
                              svg={<Icons.Edit />}
                              fontFamily="noto_sans_thai_medium, noto_sans_regular"
                              fontFamilyBottom="noto_sans_thai_medium, noto_sans_regular"
                              onClick={this.handleClickEdit}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two_block">
                    <div className="box">
                      <div className="group_show_label">
                        <div className="left_show_label">
                          <Typographys.TopBottom
                            theme_left_align
                            label="หมวด"
                            labelBottom="Category"
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                          />
                        </div>
                        <div>
                          <Typographys.TopBottom
                            theme_standard_top_bottom
                            label={data && data.category.name_th}
                            labelBottom={data && data.category.name_en}
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                            marginTopBottomLabel="0px"
                            lineHeight="1.4"
                            fontColor="#575D96"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="group_show_label">
                        <div className="left_show_label">
                          <Typographys.TopBottom
                            theme_left_align
                            label="หมดเขต"
                            labelBottom="Expired Date"
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                          />
                        </div>
                        <div className="date_show">
                          {data &&
                            `${moment(data.end_date).format("D MMMM")} ${
                              moment(data.end_date).year() + 543
                            }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two_block">
                    <div className="box">
                      <div className="group_show_label">
                        <div className="left_show_label">
                          <Typographys.TopBottom
                            theme_left_align
                            label="ใช้แต้ม"
                            labelBottom="Points require"
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                          />
                        </div>
                        <div className="icon_num">
                          <div className="show_svg">
                            <Icons.Dumbel2 width={32} height={32} />
                          </div>
                          <div>
                            {data &&
                              RenderCommaMoney(
                                Number(data.default_coin_amount),
                                0
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="group_show_label">
                        <div className="left_show_label">
                          <Typographys.TopBottom
                            theme_left_align
                            label="สิทธิ์คงเหลือ"
                            labelBottom="Remaining"
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                          />
                        </div>
                        <div
                          className="big_num"
                          onClick={this.handleClickExchangeReward}
                        >
                          <span className="num_hightlight">
                            {data &&
                              RenderCommaMoney(
                                Number(
                                  data.total_redeemable_count -
                                    data.redeemed_count >
                                    0
                                    ? data.total_redeemable_count -
                                        data.redeemed_count
                                    : 0
                                )
                              )}
                          </span>
                          <span>{` / ${
                            data &&
                            RenderCommaMoney(
                              Number(data.total_redeemable_count)
                            )
                          }`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two_block">
                    <div className="box">
                      <div className="group_show_label">
                        <div className="left_show_label">
                          <Typographys.TopBottom
                            theme_left_align
                            label="เงื่อนไข"
                            labelBottom="Term"
                            fontSizeLabelButtom="14px"
                            fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                          />
                        </div>
                        <div
                          className="box"
                          style={{ width: "50%" }}
                          dangerouslySetInnerHTML={{ __html: data?.term }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="two_block">
                    <div
                      className="box"
                      dangerouslySetInnerHTML={{ __html: data?.term }}
                    ></div>
                  </div> */}
                  {/* <div className="btn_del">
                    {perMissions && (
                      <Buttons.BtnCircleLabel
                        theme_standard_btn_circle_label
                        label="แก้ไข"
                        labelBottom="Edit"
                        svg={<Icons.Edit />}
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                        fontFamilyBottom="noto_sans_thai_medium, noto_sans_regular"
                        onClick={this.handleClickEdit}
                      />
                    )}
                  </div> */}
                </Blocks.Box>
              </div>
              <div className="right_two">
                <div
                  className="box_right_2"
                  onClick={this.handleClickExchangeReward}
                >
                  <RewardTotalViewer data={data && data} />
                </div>
                <div className="box_right_2 mb40">
                  <TotalLikeAndExchange data={data && data} />
                </div>
              </div>
            </div>
          </>
        )}
      </RewardDetailsContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardDetailsContainer);

export default function CategoryAll({ width = '32', height = '32' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <g id="Group_15956" data-name="Group 15956" transform="translate(-152 -272)">
        <circle id="Ellipse_1505" data-name="Ellipse 1505" cx="16" cy="16" r="16" transform="translate(152 272)" fill="#fef3f2" />
        <g id="request" transform="translate(-568.908 613)">
          <path id="Path_20162" data-name="Path 20162" d="M17.123,0H.7C.314,0,0,.234,0,.522V17.3c0,.288.314.522.7.522H17.123c.388,0,.7-.234.7-.522V.522C17.825.234,17.511,0,17.123,0Z" transform="translate(728.186 -334)" fill="#f4f1f1" />
          <g id="Group_14992" data-name="Group 14992" transform="translate(737.098 -334)">
            <path id="Path_20163" data-name="Path 20163" d="M198.726,0h-8.211V17.825h8.211c.388,0,.7-.234.7-.522V.522C199.427.234,199.113,0,198.726,0Z" transform="translate(-190.515)" fill="#e7dfdd" />
          </g>
          <g id="Group_14997" data-name="Group 14997" transform="translate(738.624 -330.042)">
            <g id="Group_14993" data-name="Group 14993">
              <path id="Path_20164" data-name="Path 20164" d="M228.3,114.723h-4.456c-.388,0-.7-.234-.7-.522s.314-.522.7-.522H228.3c.388,0,.7.234.7.522S228.689,114.723,228.3,114.723Z" transform="translate(-223.143 -113.679)" fill="#38336b" />
            </g>
            <g id="Group_14994" data-name="Group 14994" transform="translate(0 3.168)">
              <path id="Path_20165" data-name="Path 20165" d="M228.3,205.728h-4.456c-.388,0-.7-.234-.7-.522s.314-.522.7-.522H228.3c.388,0,.7.234.7.522S228.689,205.728,228.3,205.728Z" transform="translate(-223.143 -204.684)" fill="#38336b" />
            </g>
            <g id="Group_14995" data-name="Group 14995" transform="translate(0 6.337)">
              <path id="Path_20166" data-name="Path 20166" d="M228.3,296.732h-4.456c-.388,0-.7-.234-.7-.522s.314-.522.7-.522H228.3c.388,0,.7.234.7.522S228.689,296.732,228.3,296.732Z" transform="translate(-223.143 -295.688)" fill="#38336b" />
            </g>
            <g id="Group_14996" data-name="Group 14996" transform="translate(0 9.626)">
              <path id="Path_20167" data-name="Path 20167" d="M228.3,391.222h-4.456c-.388,0-.7-.234-.7-.522s.314-.522.7-.522H228.3c.388,0,.7.234.7.522S228.689,391.222,228.3,391.222Z" transform="translate(-223.143 -390.178)" fill="#38336b" />
            </g>
          </g>
          <g id="Group_15001" data-name="Group 15001" transform="translate(730.423 -331.005)">
            <g id="Group_14998" data-name="Group 14998">
              <path id="Path_20168" data-name="Path 20168" d="M49.542,88.657a.839.839,0,0,1-.5-.153l-1-.748a.434.434,0,0,1,0-.739.881.881,0,0,1,.992,0l.508.378,1.635-1.217a.881.881,0,0,1,.992,0,.434.434,0,0,1,0,.739L50.038,88.5A.839.839,0,0,1,49.542,88.657Z" transform="translate(-47.835 -86.026)" fill="#90d960" />
            </g>
            <g id="Group_14999" data-name="Group 14999" transform="translate(0 3.168)">
              <path id="Path_20169" data-name="Path 20169" d="M49.542,179.662a.839.839,0,0,1-.5-.153l-1-.748a.434.434,0,0,1,0-.739.881.881,0,0,1,.992,0l.508.378,1.635-1.217a.881.881,0,0,1,.992,0,.434.434,0,0,1,0,.739l-2.131,1.586A.839.839,0,0,1,49.542,179.662Z" transform="translate(-47.835 -177.031)" fill="#90d960" />
            </g>
            <g id="Group_15000" data-name="Group 15000" transform="translate(0 6.337)">
              <path id="Path_20170" data-name="Path 20170" d="M49.542,270.667a.839.839,0,0,1-.5-.153l-1-.748a.435.435,0,0,1,0-.739.881.881,0,0,1,.992,0l.508.378,1.635-1.217a.881.881,0,0,1,.992,0,.434.434,0,0,1,0,.739l-2.131,1.586A.839.839,0,0,1,49.542,270.667Z" transform="translate(-47.835 -268.036)" fill="#90d960" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import { GenderStyled } from "./styled";
import { Blocks, Typographys, Icons, Bars } from "components";

const Gender = ({ data }) => {
  return (
    <GenderStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="11px"
        paddingBottom="0px"
        paddingLeft="11px"
        height="160px"
      >
        <div className="top_current">
          <Typographys.TopBottom
            theme_standard_top_bottom
            label="เพศ"
            labelBottom="Gender"
          />
        </div>
        <div className="bottom_current">
          <div className="left_layout">
            <Icons.Male color="#003C58" />
            <div className="show_label_l">
              <Typographys.TopBottom
                theme_mini_top_bottom
                label={
                  data && data.male_user_count > 0 ? data.male_user_count : 0
                }
                labelBottom={
                  data && data.male_user_count > 0 && data.total_user_count > 0
                    ? `(${(
                        (data?.male_user_count / data?.total_user_count) *
                        100
                      ).toFixed(0)}%)`
                    : `(0 %)`
                }
              />
            </div>
          </div>
          <div className="mid_layout">
            <Bars.BarPercent
              theme_standard_bar_percent
              percent={
                data && data.male_user_count > 0 && data.total_user_count > 0
                  ? `${(data?.male_user_count / data?.total_user_count) * 100}`
                  : 0
              }
            />
          </div>
          <div className="right_layout">
            <Icons.Female color="#E86751" />
            <div className="show_label_r">
              <Typographys.TopBottom
                theme_mini_top_bottom
                label={
                  data && data.female_user_count > 0
                    ? data.female_user_count
                    : 0
                }
                labelBottom={
                  data &&
                  data.female_user_count > 0 &&
                  data.total_user_count > 0
                    ? `(${(
                        (data?.female_user_count * 100) /
                        data?.total_user_count
                      ).toFixed(0)}%)`
                    : `(0 %)`
                }
              />
            </div>
          </div>
        </div>
      </Blocks.Box>
    </GenderStyled>
  );
};

export default Gender;

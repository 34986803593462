import styled from 'styled-components'

export const DownloadDeleteAccountFormStyled = styled.div`
  .delete_box {
    width: 400px;
    border-radius: 24px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 57px 20px 48px 20px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_box {
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: noto_sans_thai_bold, noto_sans_bold;
      color:  ${({ theme }) => theme.COLORS.BLACK_8};
      margin-bottom: 23px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        color:  ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
    .opt_layout {
      padding: 0px 30px;
      .line_opt {
        display: flex;
      }
    }
    .action_btn {
      display: flex;
      .body_btn {
        width: 120px;
        &.margin_right {
          margin-right: 16px;
        }
      }
    }
  }
`

import styled from "styled-components";

export const BoxWellbeingStyled = styled.div`
  .theme_standard_box_well_being {
    display: flex;
    .left_layout {
      // width: 20%;
      display: flex;
      // align-items: center;
      padding-top: 8px;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      margin-right: 16px;
      .round {
        height: 16px;
        width: 16px;
        border-radius: 4px;
      }
    }
    .right_layout {
      // width: 80%;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .label_bottom {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }

  .theme_center {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .left_layout {
      // width: 20%;
      display: flex;
      // align-items: center;
      // padding-top: 4px;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      .round {
        height: 20px;
        width: 20px;
        border-radius: 4px;
      }
    }
    .right_layout {
      // width: 80%;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .label_top {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
      }
      .label_bottom {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        color: ${({ theme }) => theme.COLORS.GRAY_23};
      }
    }
  }

  .theme_standard_box_medium {
    display: flex;
    .left_layout {
      display: flex;
      padding-top: 5px;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      margin-right: 16px;
      .round {
        height: 16px;
        width: 16px;
        border-radius: 4px;
      }
    }
    .right_layout {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .label_top {
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .label_bottom {
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        white-space: nowrap;
      }
    }
  }

  .theme_average_box_well_being {
    display: flex;
    .left_layout {
      display: flex;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      .round {
        height: 16px;
        width: 16px;
        border-radius: 4px;
      }
    }
    .right_layout {
      margin-left: 10px;
      line-height: 0.75;
      .label_top {
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .label_bottom {
        margin-top: 10px;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
    }
  }
  .theme_msd_pui {
    display: flex;
    .left_layout {
      display: flex;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .round {
        height: 16px;
        width: 16px;
        border-radius: 4px;
      }
    }
    .right_layout {
      margin-left: 10px;
      .label_top {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      }
      .label_bottom {
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
    }
  }

  .theme_health_check {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .left_layout {
      // width: 20%;
      display: flex;
      // align-items: center;
      // padding-top: 4px;
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      .round {
        height: 12px;
        width: 12px;
        border-radius: 50px;
      }
    }
    .right_layout {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_23};
    }
  }
`;

import { NumberOfSmokingStyled } from './styled';
import { Blocks, Icons } from 'components';
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const NumberOfSmoking = ({ data }) => {
  return (
    <NumberOfSmokingStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="12px"
        paddingRight="12px"
        paddingBottom="12px"
        paddingLeft="16px"
        // height="160px"
      >
        <div className="top_current">
          <Blocks.BoxLabelCenter
            theme_standard_box_4_col
            label1="ข้อมูลการสูบบุหรี่ของพนักงาน "
            label2="Number of Smoking employees"
            iconShow={<Icons.Smokeing />}
            label3Fontsize='52px'
            label3FontsColor='#2A74A3'
            label3={
              data && data.smoke_count > 0 ? `${((data.smoke_count / data.user_count) * 100).toFixed(1)}%` : '0'
            }
          />
        </div>
      </Blocks.Box>
    </NumberOfSmokingStyled>
  );
};

export default NumberOfSmoking;

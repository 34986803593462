export default function AllKid({ width = "40", height = "40" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.34101 31.0737C3.01401 30.5829 4.40301 29.3493 4.11901 28.8295C3.94001 28.5016 2.07701 28.8955 1.91901 28.5566C1.45315 27.5769 1.06885 26.5605 0.770005 25.5177C0.476005 24.49 0.810005 23.2545 0.684005 22.1668C0.642005 21.804 0.0610054 21.613 0.0390054 21.2452C0.0130054 20.836 5.36204e-06 20.4235 5.36204e-06 20.0076C-0.00362797 15.0448 1.83932 10.258 5.17043 6.57818C8.50155 2.89836 13.0827 0.588552 18.023 0.0979673C22.9632 -0.392618 27.9094 0.971091 31.8996 3.92388C35.8899 6.87667 38.6389 11.2075 39.612 16.074C39.866 17.3456 38.449 18.6611 38.449 20.0076C38.4825 21.1276 38.4219 22.2485 38.268 23.3584C38.1956 24.0028 38.082 24.6419 37.928 25.2718C37.711 26.0315 37.301 26.1894 36.999 26.9092C36.717 27.5819 37.85 29.0644 37.499 29.6962" fill="#E8EEFF" />
      <path d="M27.598 25.7366C30.6842 25.7366 33.1861 28.2385 33.1861 31.3247C33.1861 32.9328 32.5172 34.4994 31.1844 35.3992C31.0757 35.4726 30.9663 35.5449 30.8561 35.6161L30.8121 35.6451C30.3221 35.961 29.8171 37.4394 29.2991 37.7124C28.8091 37.9703 28.3091 38.2079 27.7991 38.4251C27.2901 38.641 26.7711 37.5394 26.2421 37.7124C24.2791 38.3561 22.1821 37.3455 20.0041 37.3455C19.5411 37.3455 19.0831 39.0149 18.6281 38.9839C17.8284 38.975 17.03 38.9183 16.2371 38.814L16.1931 38.805C15.6811 38.705 15.1771 39.4217 14.6801 39.2848C14.1831 39.1478 13.6681 38.9849 13.1801 38.805C12.6501 38.6131 12.2621 36.7057 11.7541 36.4668C10.7795 36.0163 9.80996 35.7874 8.89263 35.4713C7.32006 34.9293 6.62012 33.2699 6.62012 31.6065C6.62012 28.3646 9.24819 25.7366 12.4901 25.7366H27.598Z" fill="#132866" />
      <path d="M14.7569 12.6833H10.6199C9.42882 12.6833 8.28652 13.1563 7.44429 13.9983C6.60206 14.8402 6.12891 15.9821 6.12891 17.1728V18.4195C6.12891 22.0422 9.06569 24.979 12.6884 24.979C16.3111 24.979 19.2479 22.0422 19.2479 18.4195V17.1698C19.2471 15.9796 18.7736 14.8385 17.9315 13.9972C17.0893 13.1559 15.9475 12.6833 14.7569 12.6833Z" fill="#EF3D54" />
      <path d="M12.1339 12.6832C10.9428 12.6832 9.80052 13.1562 8.95829 13.9981C8.11606 14.84 7.64291 15.9819 7.64291 17.1726V24.2218C7.64291 24.6399 7.30399 24.9788 6.88591 24.9788C6.46783 24.9788 6.12891 24.6399 6.12891 24.2218V17.1696C6.12891 15.9789 6.60206 14.837 7.44429 13.9951C8.28652 13.1532 9.42882 12.6802 10.6199 12.6802L12.1339 12.6832Z" fill="#CA0051" />
      <path d="M29.38 12.6833H25.243C24.0519 12.6833 22.9096 13.1563 22.0674 13.9983C21.2251 14.8402 20.752 15.9821 20.752 17.1728V18.4195C20.752 22.0422 23.6887 24.979 27.3115 24.979C30.9342 24.979 33.871 22.0422 33.871 18.4195V17.1698C33.8702 15.9796 33.3966 14.8385 32.5545 13.9972C31.7124 13.1559 30.5705 12.6833 29.38 12.6833Z" fill="#EF3D54" />
      <path d="M26.7569 12.6832C25.5658 12.6832 24.4236 13.1562 23.5813 13.9981C22.7391 14.84 22.2659 15.9819 22.2659 17.1726V24.2223C22.2659 24.6401 21.9272 24.9788 21.5094 24.9788C21.0916 24.9788 20.7529 24.6401 20.7529 24.2223V17.1696C20.7529 15.9789 21.2261 14.837 22.0683 13.9951C22.9106 13.1532 24.0528 12.6802 25.2439 12.6802L26.7569 12.6832Z" fill="#CA0051" />
      <path d="M14.2655 15.6011H11.1076C10.2002 15.6019 9.33028 15.9625 8.68871 16.6039C8.04713 17.2452 7.68634 18.1149 7.68555 19.0219V19.9732C7.68555 22.7361 9.92524 24.9758 12.688 24.9758C15.4509 24.9758 17.6906 22.7361 17.6906 19.9732V19.0219C17.6898 18.1143 17.3286 17.2442 16.6863 16.6028C16.0441 15.9614 15.1734 15.6011 14.2655 15.6011Z" fill="#51AAF7" />
      <path d="M13.5015 15.6011C13.0517 15.6009 12.6063 15.6894 12.1907 15.8614C11.7751 16.0334 11.3975 16.2856 11.0794 16.6036C10.7613 16.9215 10.5091 17.299 10.337 17.7145C10.1649 18.1299 10.0764 18.5752 10.0765 19.0249V23.7823C10.0765 24.4425 9.5413 24.9778 8.88105 24.9778C8.22079 24.9778 7.68555 24.4425 7.68555 23.7823V19.0249C7.68555 18.5752 7.77414 18.13 7.94626 17.7146C8.11838 17.2992 8.37066 16.9218 8.68871 16.6039C9.00675 16.286 9.38432 16.0338 9.79986 15.8617C10.2154 15.6896 10.6608 15.6011 11.1105 15.6011H13.5015Z" fill="#378AE5" />
      <path d="M14.3463 0.580322H11.0313C9.72719 0.580322 8.47653 1.09818 7.55441 2.01997C6.6323 2.94176 6.11426 4.19198 6.11426 5.49559C6.11426 8.25603 8.35204 10.4938 11.1125 10.4938H14.265C17.0255 10.4938 19.2633 8.25603 19.2633 5.49559C19.2633 4.19198 18.7452 2.94176 17.8231 2.01997C16.901 1.09818 15.6503 0.580322 14.3463 0.580322Z" fill="#2A428B" />
      <path d="M13.9173 0.580322C12.6132 0.580322 11.3625 1.09818 10.4404 2.01997C9.5183 2.94176 9.00025 4.19198 9.00025 5.49559V9.05082C9.00025 9.84777 8.3542 10.4938 7.55725 10.4938C6.76031 10.4938 6.11426 9.84777 6.11426 9.05082V5.49559C6.11426 4.19198 6.6323 2.94176 7.55441 2.01997C8.47653 1.09818 9.72719 0.580322 11.0313 0.580322H13.9173Z" fill="#132866" />
      <path d="M11.0488 16.2838V15.4437C11.0488 14.5385 11.7826 13.8047 12.6878 13.8047C13.593 13.8047 14.3268 14.5385 14.3268 15.4437V16.2838C14.3276 16.4202 14.2959 16.5547 14.2343 16.6764C14.1727 16.7981 14.0831 16.9034 13.9728 16.9836C13.5994 17.2555 13.1494 17.4021 12.6873 17.4021C12.2253 17.4021 11.7752 17.2555 11.4018 16.9836C11.2917 16.9032 11.2023 16.7979 11.1409 16.6762C11.0796 16.5546 11.048 16.4201 11.0488 16.2838Z" fill="#F1C0A6" />
      <path d="M12.1958 17.0367C12.1946 17.1838 12.0645 17.3116 11.9266 17.2601C11.7408 17.1907 11.5648 17.0961 11.4038 16.9787C11.2937 16.8983 11.2044 16.793 11.143 16.6713C11.0816 16.5497 11.05 16.4152 11.0508 16.2789V14.3723C11.0508 14.0561 11.3071 13.7998 11.6233 13.7998C11.9395 13.7998 12.1958 14.0561 12.1958 14.3723V17.0367Z" fill="#E6AC94" />
      <path d="M8.16253 10.492C8.16245 10.8006 8.06768 11.1018 7.89098 11.3549C7.71428 11.608 7.46416 11.8008 7.17439 11.9074C6.88461 12.0139 6.56912 12.029 6.27048 11.9507C5.97184 11.8723 5.70443 11.7043 5.50433 11.4693C5.30424 11.2342 5.18108 10.9435 5.15148 10.6362C5.12188 10.329 5.18727 10.0201 5.33881 9.75121C5.49036 9.4823 5.72078 9.26634 5.99897 9.13246C6.27716 8.99858 6.58974 8.95322 6.89453 9.00252C7.24834 9.05994 7.57018 9.24132 7.80248 9.5142C8.03479 9.78708 8.16242 10.1337 8.16253 10.492Z" fill="#F1C0A6" />
      <path d="M8.16264 10.4929C8.16253 10.8512 8.0349 11.1978 7.8026 11.4707C7.41034 11.9315 6.75928 11.539 6.71175 10.9357C6.68853 10.641 6.68853 10.3448 6.71175 10.0501C6.75928 9.44681 7.41034 9.05433 7.8026 9.51509C8.0349 9.78797 8.16253 10.1346 8.16264 10.4929Z" fill="#E6AC94" />
      <path d="M6.65326 11.0314C6.95094 11.0314 7.19226 10.7901 7.19226 10.4924C7.19226 10.1947 6.95094 9.95337 6.65326 9.95337C6.35558 9.95337 6.11426 10.1947 6.11426 10.4924C6.11426 10.7901 6.35558 11.0314 6.65326 11.0314Z" fill="#E6AC94" />
      <path d="M17.2139 10.492C17.2139 10.8006 17.3087 11.1018 17.4854 11.3549C17.6621 11.608 17.9122 11.8008 18.202 11.9074C18.4918 12.0139 18.8073 12.029 19.1059 11.9507C19.4045 11.8723 19.672 11.7043 19.8721 11.4693C20.0722 11.2342 20.1953 10.9435 20.2249 10.6362C20.2545 10.329 20.1891 10.0201 20.0376 9.75121C19.886 9.4823 19.6556 9.26634 19.3774 9.13246C19.0992 8.99858 18.7866 8.95322 18.4819 9.00252C18.128 9.05994 17.8062 9.24132 17.5739 9.5142C17.3416 9.78708 17.214 10.1337 17.2139 10.492Z" fill="#F1C0A6" />
      <path d="M17.2139 10.4929C17.214 10.8512 17.3416 11.1978 17.5739 11.4707C17.9662 11.9314 18.6172 11.539 18.6647 10.9357C18.6879 10.641 18.6879 10.3448 18.6647 10.0501C18.6172 9.44682 17.9662 9.05434 17.5739 9.51509C17.3416 9.78797 17.214 10.1346 17.2139 10.4929Z" fill="#E6AC94" />
      <path d="M18.7226 11.0314C19.0203 11.0314 19.2616 10.7901 19.2616 10.4924C19.2616 10.1947 19.0203 9.95337 18.7226 9.95337C18.4249 9.95337 18.1836 10.1947 18.1836 10.4924C18.1836 10.7901 18.4249 11.0314 18.7226 11.0314Z" fill="#E6AC94" />
      <path d="M17.0768 8.37778C17.7819 8.42557 18.3586 8.99242 18.3586 9.6991C18.3586 11.2026 17.7611 12.6445 16.6976 13.7077C15.6341 14.7708 14.1916 15.3681 12.6876 15.3681C11.1836 15.3681 9.74112 14.7708 8.6776 13.7077C7.61408 12.6445 7.0166 11.2026 7.0166 9.6991C7.0166 8.73855 7.74185 7.95023 8.65072 7.63943C8.7365 7.6101 8.82177 7.57937 8.90648 7.54725C10.2919 7.02212 12.407 7.21496 13.7951 7.73274C14.6034 8.03421 15.6722 8.28257 17.0768 8.37778Z" fill="#F1C0A6" />
      <path d="M13.5979 15.2646C13.6152 15.268 13.615 15.2933 13.5976 15.2963C12.7864 15.4276 11.9564 15.3812 11.1649 15.1603C10.3734 14.9394 9.63934 14.5493 9.0135 14.0169C8.38766 13.4846 7.88497 12.8227 7.54018 12.077C7.1954 11.3313 7.01675 10.5197 7.0166 9.69824V8.79147C7.0166 8.35201 7.35103 7.98948 7.78035 7.89558C8.3816 7.76409 9.0166 8.18406 9.0166 8.79952V9.69824C9.01608 11.0287 9.4836 12.3169 10.3373 13.3376C11.1717 14.3351 12.3231 15.0149 13.5979 15.2646Z" fill="#E6AC94" />
      <path d="M12.6875 12.6835C12.2038 12.6831 11.728 12.5603 11.3045 12.3265C11.2459 12.2942 11.2025 12.2401 11.1838 12.1758C11.1652 12.1116 11.1728 12.0426 11.205 11.984C11.2372 11.9254 11.2914 11.8819 11.3557 11.8633C11.4199 11.8446 11.4889 11.8522 11.5475 11.8845C11.8963 12.0774 12.2884 12.1787 12.687 12.1787C13.0856 12.1787 13.4777 12.0774 13.8265 11.8845C13.8851 11.8522 13.9542 11.8446 14.0184 11.8633C14.0826 11.8819 14.1368 11.9254 14.169 11.984C14.2013 12.0426 14.2089 12.1116 14.1902 12.1758C14.1715 12.2401 14.1281 12.2942 14.0695 12.3265C13.6463 12.5601 13.1709 12.6829 12.6875 12.6835Z" fill="#D49985" />
      <path d="M13.3316 10.9891H12.0406C11.9699 10.9848 11.9035 10.9538 11.8549 10.9022C11.8063 10.8506 11.7793 10.7824 11.7793 10.7116C11.7793 10.6407 11.8063 10.5726 11.8549 10.521C11.9035 10.4694 11.9699 10.4383 12.0406 10.4341H13.3276C13.3655 10.4318 13.4034 10.4373 13.439 10.4502C13.4747 10.4632 13.5073 10.4832 13.5349 10.5092C13.5625 10.5352 13.5845 10.5666 13.5995 10.6014C13.6145 10.6362 13.6223 10.6737 13.6223 10.7116C13.6223 10.7495 13.6145 10.787 13.5995 10.8218C13.5845 10.8566 13.5625 10.888 13.5349 10.914C13.5073 10.94 13.4747 10.96 13.439 10.973C13.4034 10.9859 13.3655 10.9914 13.3276 10.9891H13.3316Z" fill="#E6AC94" />
      <path d="M14.5131 9.43018C14.0188 9.43018 13.6024 9.88643 13.9519 10.2359C14.1656 10.4496 14.4554 10.5697 14.7577 10.5697C15.0599 10.5697 15.3497 10.4496 15.5634 10.2359C15.9129 9.88643 15.4965 9.43018 15.0022 9.43018H14.5131Z" fill="#E6AC94" />
      <path d="M14.7574 10.2478C15.2086 10.2478 15.5744 9.88199 15.5744 9.43077C15.5744 8.97955 15.2086 8.61377 14.7574 8.61377C14.3062 8.61377 13.9404 8.97955 13.9404 9.43077C13.9404 9.88199 14.3062 10.2478 14.7574 10.2478Z" fill="#343A3E" />
      <path d="M15.098 9.63652C15.2913 9.63652 15.448 9.47982 15.448 9.28652C15.448 9.09322 15.2913 8.93652 15.098 8.93652C14.9047 8.93652 14.748 9.09322 14.748 9.28652C14.748 9.47982 14.9047 9.63652 15.098 9.63652Z" fill="white" />
      <path d="M10.3764 9.43018C9.88212 9.43018 9.4657 9.88643 9.8152 10.2359C10.0289 10.4496 10.3187 10.5697 10.6209 10.5697C10.9232 10.5697 11.213 10.4496 11.4267 10.2359C11.7762 9.88643 11.3598 9.43018 10.8655 9.43018H10.3764Z" fill="#E6AC94" />
      <path d="M10.6197 10.2478C11.071 10.2478 11.4367 9.88199 11.4367 9.43077C11.4367 8.97955 11.071 8.61377 10.6197 8.61377C10.1685 8.61377 9.80273 8.97955 9.80273 9.43077C9.80273 9.88199 10.1685 10.2478 10.6197 10.2478Z" fill="#343A3E" />
      <path d="M10.8107 10.2253C10.8107 10.225 10.8105 10.2248 10.8102 10.2249C10.7479 10.2401 10.6839 10.2478 10.6197 10.2478C10.4031 10.2478 10.1952 10.1617 10.042 10.0085C9.88881 9.85526 9.80273 9.64745 9.80273 9.43077C9.80273 9.21409 9.88881 9.00628 10.042 8.85307C10.1952 8.69985 10.4031 8.61377 10.6197 8.61377C10.6688 8.61374 10.6739 8.68108 10.6302 8.70333C10.5257 8.75659 10.4332 8.83207 10.3596 8.92535C10.246 9.06948 10.1842 9.24771 10.1842 9.43127C10.1842 9.61484 10.246 9.79306 10.3596 9.9372C10.4732 10.0812 10.6319 10.1828 10.8102 10.2257C10.8105 10.2257 10.8107 10.2255 10.8107 10.2253Z" fill="#1F2426" />
      <path d="M14.9484 10.2253C14.9484 10.225 14.9482 10.2248 14.9479 10.2249C14.8856 10.2401 14.8216 10.2478 14.7574 10.2478C14.5407 10.2478 14.3329 10.1617 14.1797 10.0085C14.0265 9.85526 13.9404 9.64745 13.9404 9.43077C13.9404 9.21409 14.0265 9.00628 14.1797 8.85307C14.3329 8.69985 14.5407 8.61377 14.7574 8.61377C14.8064 8.61374 14.8116 8.68108 14.7679 8.70334C14.6634 8.75659 14.5709 8.83207 14.4973 8.92535C14.3837 9.06948 14.3218 9.24771 14.3218 9.43127C14.3218 9.61484 14.3837 9.79306 14.4973 9.9372C14.6109 10.0812 14.7696 10.1828 14.9479 10.2257C14.9482 10.2257 14.9484 10.2255 14.9484 10.2253Z" fill="#1F2426" />
      <path d="M10.9604 9.63652C11.1537 9.63652 11.3104 9.47982 11.3104 9.28652C11.3104 9.09322 11.1537 8.93652 10.9604 8.93652C10.7671 8.93652 10.6104 9.09322 10.6104 9.28652C10.6104 9.47982 10.7671 9.63652 10.9604 9.63652Z" fill="white" />
      <path d="M28.8886 15.6011H25.7336C24.8258 15.6011 23.955 15.9614 23.3128 16.6028C22.6706 17.2442 22.3094 18.1143 22.3086 19.0219V19.9732C22.3086 22.7361 24.5483 24.9758 27.3111 24.9758C30.0739 24.9758 32.3136 22.7361 32.3136 19.9732V19.0219C32.3128 18.1143 31.9516 17.2442 31.3094 16.6028C30.6672 15.9614 29.7965 15.6011 28.8886 15.6011Z" fill="#E8EEFF" />
      <path d="M28.1246 15.6011C27.6748 15.6009 27.2294 15.6894 26.8138 15.8614C26.3982 16.0334 26.0205 16.2856 25.7025 16.6036C25.3844 16.9215 25.1321 17.299 24.9601 17.7145C24.788 18.1299 24.6995 18.5752 24.6996 19.0249V23.7823C24.6996 24.4425 24.1643 24.9778 23.5041 24.9778C22.8438 24.9778 22.3086 24.4425 22.3086 23.7823V19.0249C22.3086 18.5752 22.3972 18.13 22.5693 17.7146C22.7414 17.2992 22.9937 16.9218 23.3118 16.6039C23.6298 16.286 24.0074 16.0338 24.4229 15.8617C24.8385 15.6896 25.2838 15.6011 25.7336 15.6011H28.1246Z" fill="#D2DBF9" />
      <path d="M28.9693 0.580322H25.6543C24.3502 0.580322 23.0996 1.09818 22.1775 2.01997C21.2553 2.94176 20.7373 4.19198 20.7373 5.49559C20.7373 8.25603 22.9751 10.4938 25.7355 10.4938H28.8881C31.6485 10.4938 33.8863 8.25603 33.8863 5.49559C33.8863 4.19198 33.3683 2.94176 32.4461 2.01997C31.524 1.09818 30.2734 0.580322 28.9693 0.580322Z" fill="#FADC42" />
      <path d="M28.5403 0.580322C27.2362 0.580322 25.9855 1.09818 25.0634 2.01997C24.1413 2.94176 23.6233 4.19198 23.6233 5.49559V9.05133C23.6233 9.84799 22.9774 10.4938 22.1808 10.4938C21.3841 10.4938 20.7383 9.84799 20.7383 9.05133V5.49559C20.7383 4.19198 21.2563 2.94176 22.1784 2.01997C23.1006 1.09818 24.3512 0.580322 25.6553 0.580322H28.5403Z" fill="#F6B231" />
      <path d="M25.6719 16.2838V15.4437C25.6719 14.5385 26.4057 13.8047 27.3109 13.8047C28.2161 13.8047 28.9499 14.5385 28.9499 15.4437V16.2838C28.9506 16.4202 28.9189 16.5547 28.8573 16.6764C28.7958 16.7981 28.7062 16.9034 28.5959 16.9836C28.2225 17.2555 27.7724 17.4021 27.3104 17.4021C26.8484 17.4021 26.3983 17.2555 26.0249 16.9836C25.9148 16.9032 25.8254 16.7979 25.764 16.6762C25.7026 16.5546 25.671 16.4201 25.6719 16.2838Z" fill="#F1C0A6" />
      <path d="M26.8179 17.0367C26.8167 17.1838 26.6866 17.3116 26.5487 17.2601C26.3629 17.1907 26.1869 17.0961 26.0259 16.9787C25.9158 16.8983 25.8264 16.793 25.7651 16.6713C25.7037 16.5497 25.6721 16.4152 25.6729 16.2789V14.3723C25.6729 14.0561 25.9292 13.7998 26.2454 13.7998C26.5615 13.7998 26.8179 14.0561 26.8179 14.3723V17.0367Z" fill="#E6AC94" />
      <path d="M22.7856 10.492C22.7855 10.8006 22.6907 11.1018 22.514 11.3549C22.3373 11.608 22.0872 11.8008 21.7974 11.9074C21.5077 12.0139 21.1922 12.029 20.8935 11.9507C20.5949 11.8723 20.3275 11.7043 20.1274 11.4693C19.9273 11.2342 19.8041 10.9435 19.7745 10.6362C19.7449 10.329 19.8103 10.0201 19.9619 9.75121C20.1134 9.4823 20.3438 9.26634 20.622 9.13246C20.9002 8.99858 21.2128 8.95322 21.5176 9.00252C21.8714 9.05994 22.1932 9.24132 22.4255 9.5142C22.6578 9.78708 22.7855 10.1337 22.7856 10.492Z" fill="#F1C0A6" />
      <path d="M22.7856 10.4929C22.7855 10.8512 22.6579 11.1978 22.4256 11.4707C22.0333 11.9314 21.3823 11.5389 21.3348 10.9357C21.3116 10.641 21.3116 10.3448 21.3348 10.0501C21.3823 9.44684 22.0333 9.05434 22.4256 9.51509C22.6579 9.78797 22.7855 10.1346 22.7856 10.4929Z" fill="#E6AC94" />
      <path d="M21.2763 11.0314C21.574 11.0314 21.8153 10.7901 21.8153 10.4924C21.8153 10.1947 21.574 9.95337 21.2763 9.95337C20.9786 9.95337 20.7373 10.1947 20.7373 10.4924C20.7373 10.7901 20.9786 11.0314 21.2763 11.0314Z" fill="#E6AC94" />
      <path d="M31.8369 10.492C31.837 10.8006 31.9318 11.1018 32.1085 11.3549C32.2852 11.608 32.5353 11.8008 32.8251 11.9074C33.1148 12.0139 33.4303 12.029 33.729 11.9507C34.0276 11.8723 34.295 11.7043 34.4951 11.4693C34.6952 11.2342 34.8184 10.9435 34.848 10.6362C34.8776 10.329 34.8122 10.0201 34.6606 9.75121C34.5091 9.4823 34.2787 9.26634 34.0005 9.13246C33.7223 8.99858 33.4097 8.95322 33.1049 9.00252C32.7511 9.05994 32.4293 9.24132 32.197 9.5142C31.9647 9.78708 31.837 10.1337 31.8369 10.492Z" fill="#F1C0A6" />
      <path d="M31.8369 10.4929C31.837 10.8512 31.9647 11.1978 32.197 11.4707C32.5892 11.9315 33.2403 11.539 33.2878 10.9357C33.311 10.641 33.311 10.3448 33.2878 10.0501C33.2403 9.44681 32.5892 9.05433 32.197 9.51509C31.9647 9.78797 31.837 10.1346 31.8369 10.4929Z" fill="#E6AC94" />
      <path d="M33.3456 11.0314C33.6433 11.0314 33.8846 10.7901 33.8846 10.4924C33.8846 10.1947 33.6433 9.95337 33.3456 9.95337C33.048 9.95337 32.8066 10.1947 32.8066 10.4924C32.8066 10.7901 33.048 11.0314 33.3456 11.0314Z" fill="#E6AC94" />
      <path d="M31.6999 8.37778C32.4049 8.42557 32.9817 8.99242 32.9817 9.6991C32.9817 11.2026 32.3842 12.6445 31.3206 13.7077C30.2571 14.7708 28.8147 15.3681 27.3107 15.3681C25.8066 15.3681 24.3642 14.7708 23.3007 13.7077C22.2371 12.6445 21.6396 11.2026 21.6396 9.6991C21.6396 8.73855 22.3649 7.95023 23.2738 7.63943C23.3595 7.6101 23.4448 7.57937 23.5295 7.54726C24.9149 7.02212 27.03 7.21496 28.4182 7.73275C29.2264 8.03422 30.2952 8.28257 31.6999 8.37778Z" fill="#F1C0A6" />
      <path d="M28.2209 15.2646C28.2382 15.268 28.238 15.2933 28.2207 15.2963C27.4095 15.4276 26.5794 15.3812 25.7879 15.1603C24.9964 14.9394 24.2624 14.5493 23.6366 14.0169C23.0107 13.4846 22.508 12.8227 22.1632 12.077C21.8185 11.3313 21.6398 10.5197 21.6396 9.69824V8.79147C21.6396 8.35201 21.9741 7.98949 22.4034 7.8956C23.0046 7.7641 23.6396 8.18407 23.6396 8.79953V9.69824C23.6391 11.0287 24.1067 12.3169 24.9604 13.3376C25.7948 14.3351 26.9462 15.0149 28.2209 15.2646Z" fill="#E6AC94" />
      <path d="M27.3106 12.6835C26.8268 12.6831 26.3511 12.5603 25.9276 12.3265C25.869 12.2942 25.8255 12.2401 25.8069 12.1758C25.7882 12.1116 25.7959 12.0426 25.8281 11.984C25.8603 11.9254 25.9145 11.8819 25.9787 11.8633C26.043 11.8446 26.112 11.8522 26.1706 11.8845C26.5194 12.0774 26.9115 12.1787 27.3101 12.1787C27.7087 12.1787 28.1008 12.0774 28.4496 11.8845C28.5082 11.8522 28.5772 11.8446 28.6414 11.8633C28.7057 11.8819 28.7599 11.9254 28.7921 11.984C28.8243 12.0426 28.8319 12.1116 28.8133 12.1758C28.7946 12.2401 28.7512 12.2942 28.6926 12.3265C28.2694 12.5601 27.794 12.6829 27.3106 12.6835Z" fill="#D49985" />
      <path d="M27.9547 10.9891H26.6677C26.6299 10.9914 26.5919 10.9859 26.5563 10.973C26.5207 10.96 26.488 10.94 26.4604 10.914C26.4329 10.888 26.4108 10.8566 26.3958 10.8218C26.3808 10.787 26.373 10.7495 26.373 10.7116C26.373 10.6737 26.3808 10.6362 26.3958 10.6014C26.4108 10.5666 26.4329 10.5352 26.4604 10.5092C26.488 10.4832 26.5207 10.4632 26.5563 10.4502C26.5919 10.4373 26.6299 10.4318 26.6677 10.4341H27.9547C28.0254 10.4383 28.0918 10.4694 28.1404 10.521C28.189 10.5726 28.216 10.6407 28.216 10.7116C28.216 10.7824 28.189 10.8506 28.1404 10.9022C28.0918 10.9538 28.0254 10.9848 27.9547 10.9891Z" fill="#E6AC94" />
      <path d="M29.1361 9.43018C28.6419 9.43018 28.2255 9.88643 28.575 10.2359C28.7886 10.4496 29.0785 10.5697 29.3807 10.5697C29.6829 10.5697 29.9728 10.4496 30.1865 10.2359C30.536 9.88643 30.1195 9.43018 29.6253 9.43018H29.1361Z" fill="#E6AC94" />
      <path d="M29.3805 10.2478C29.8317 10.2478 30.1975 9.88199 30.1975 9.43077C30.1975 8.97955 29.8317 8.61377 29.3805 8.61377C28.9293 8.61377 28.5635 8.97955 28.5635 9.43077C28.5635 9.88199 28.9293 10.2478 29.3805 10.2478Z" fill="#343A3E" />
      <path d="M29.7201 9.63652C29.9134 9.63652 30.0701 9.47982 30.0701 9.28652C30.0701 9.09322 29.9134 8.93652 29.7201 8.93652C29.5268 8.93652 29.3701 9.09322 29.3701 9.28652C29.3701 9.47982 29.5268 9.63652 29.7201 9.63652Z" fill="white" />
      <path d="M24.9994 9.43018C24.5052 9.43018 24.0887 9.88643 24.4382 10.2359C24.6519 10.4496 24.9418 10.5697 25.244 10.5697C25.5462 10.5697 25.836 10.4496 26.0497 10.2359C26.3992 9.88643 25.9828 9.43018 25.4885 9.43018H24.9994Z" fill="#E6AC94" />
      <path d="M25.2428 10.2478C25.694 10.2478 26.0598 9.88199 26.0598 9.43077C26.0598 8.97955 25.694 8.61377 25.2428 8.61377C24.7916 8.61377 24.4258 8.97955 24.4258 9.43077C24.4258 9.88199 24.7916 10.2478 25.2428 10.2478Z" fill="#343A3E" />
      <path d="M25.4338 10.2253C25.4338 10.225 25.4335 10.2248 25.4333 10.2249C25.3709 10.2401 25.307 10.2478 25.2428 10.2478C25.0261 10.2478 24.8183 10.1617 24.6651 10.0085C24.5119 9.85526 24.4258 9.64745 24.4258 9.43077C24.4258 9.21409 24.5119 9.00628 24.6651 8.85307C24.8183 8.69985 25.0261 8.61377 25.2428 8.61377C25.2918 8.61374 25.2969 8.68108 25.2532 8.70334C25.1488 8.75659 25.0563 8.83207 24.9827 8.92535C24.869 9.06948 24.8072 9.24771 24.8072 9.43127C24.8072 9.61484 24.869 9.79306 24.9827 9.9372C25.0963 10.0812 25.255 10.1828 25.4333 10.2257C25.4335 10.2257 25.4338 10.2255 25.4338 10.2253Z" fill="#1F2426" />
      <path d="M29.5715 10.2253C29.5715 10.225 29.5712 10.2248 29.571 10.2249C29.5086 10.2401 29.4447 10.2478 29.3805 10.2478C29.1638 10.2478 28.956 10.1617 28.8028 10.0085C28.6495 9.85526 28.5635 9.64745 28.5635 9.43077C28.5635 9.21409 28.6495 9.00628 28.8028 8.85307C28.956 8.69985 29.1638 8.61377 29.3805 8.61377C29.4295 8.61374 29.4346 8.68108 29.3909 8.70334C29.2864 8.75659 29.194 8.83207 29.1204 8.92535C29.0067 9.06948 28.9449 9.24771 28.9449 9.43127C28.9449 9.61484 29.0067 9.79306 29.1204 9.9372C29.234 10.0812 29.3927 10.1828 29.571 10.2257C29.5712 10.2257 29.5715 10.2255 29.5715 10.2253Z" fill="#1F2426" />
      <path d="M25.5834 9.63652C25.7767 9.63652 25.9334 9.47982 25.9334 9.28652C25.9334 9.09322 25.7767 8.93652 25.5834 8.93652C25.3901 8.93652 25.2334 9.09322 25.2334 9.28652C25.2334 9.47982 25.3901 9.63652 25.5834 9.63652Z" fill="white" />
      <path d="M13.172 32.1974V37.1199C13.172 37.939 12.3583 38.5075 11.615 38.1633C8.78101 36.8528 7.25601 34.4126 5.32701 32.0035C4.96201 31.5477 3.66501 31.5607 3.34101 31.0738C3.32476 31.0494 3.30856 31.025 3.29241 31.0005C2.39645 29.6428 2.91727 27.7065 4.54402 27.709H8.68102C9.87193 27.709 11.0141 28.1818 11.8563 29.0236C12.6985 29.8653 13.1717 31.0069 13.172 32.1974Z" fill="#EF3D54" />
      <path d="M6.05802 27.709C5.34936 27.7084 4.65066 27.8758 4.01916 28.1973C3.60708 28.4072 3.23127 28.6788 2.90417 29.0015C2.66104 29.2415 2.23745 29.2126 2.08515 28.9069C1.98531 28.7064 2.04629 28.4614 2.23832 28.3461C2.93234 27.9298 3.72884 27.7086 4.54302 27.71L6.05802 27.709Z" fill="#CA0051" />
      <path d="M11.6148 34.0507C11.6148 35.9706 9.62541 37.2111 8.08402 36.0666C6.70691 35.0441 5.46381 33.8475 4.387 32.5045C3.78745 31.7568 4.07637 30.625 5.03482 30.627H8.18982C8.6396 30.627 9.08497 30.7155 9.50051 30.8876C9.91605 31.0596 10.2936 31.3118 10.6117 31.6297C10.9297 31.9477 11.182 32.3251 11.3541 32.7405C11.5262 33.1559 11.6148 33.6011 11.6148 34.0507Z" fill="#E8EEFF" />
      <path d="M7.42482 30.627C6.79422 30.6267 6.17579 30.8006 5.63776 31.1295C5.39654 31.277 5.17568 31.453 4.97953 31.653C4.63899 32.0002 4.03384 32.0607 3.74997 31.6658C3.52339 31.3507 3.62695 30.9047 3.99684 30.787C4.33109 30.6807 4.68117 30.6262 5.03482 30.627H7.42482Z" fill="#D2DBF9" />
      <path d="M13.1872 20.5202C13.1872 23.2211 10.8957 25.3549 8.20166 25.1625L1.65744 24.6953C1.35129 24.6735 1.05917 24.5585 0.820302 24.3657C0.548111 24.1461 0.358871 23.8389 0.298312 23.4944C0.167553 22.7507 0.0790252 22 0.0332031 21.246V20.5212C0.0332031 19.2176 0.551243 17.9674 1.47336 17.0456C2.39548 16.1238 3.64613 15.606 4.9502 15.606H8.2702C9.5741 15.606 10.8246 16.1237 11.7467 17.0453C12.6688 17.9668 13.1869 19.2168 13.1872 20.5202Z" fill="#2A428B" />
      <path d="M7.84109 15.605C6.53702 15.605 5.28636 16.1228 4.36424 17.0446C3.44213 17.9664 2.92409 19.2166 2.92409 20.5202V23.8516C2.92409 24.6667 2.11979 25.2381 1.35015 24.9697C1.10985 24.886 0.903309 24.7268 0.758606 24.5175C0.731264 24.478 0.703028 24.4373 0.674925 24.397C0.503063 24.1505 0.37072 23.8705 0.31749 23.5748C0.178869 22.8048 0.0855587 22.0272 0.0380859 21.246V20.5212C0.0380859 19.2176 0.556126 17.9674 1.47824 17.0456C2.40036 16.1238 3.65102 15.606 4.95509 15.606L7.84109 15.605Z" fill="#132866" />
      <path d="M4.97365 31.3095V30.4693C4.97365 29.5641 5.70745 28.8303 6.61265 28.8303C7.51784 28.8303 8.25165 29.5641 8.25165 30.4693V31.3095C8.25238 31.4458 8.22066 31.5803 8.1591 31.702C8.09755 31.8237 8.00794 31.929 7.89765 32.0092C7.52425 32.2812 7.07416 32.4277 6.61215 32.4277C6.15015 32.4277 5.70006 32.2812 5.32665 32.0092C5.21655 31.9289 5.12712 31.8235 5.06575 31.7019C5.00437 31.5802 4.9728 31.4457 4.97365 31.3095Z" fill="#F1C0A6" />
      <path d="M6.11963 32.0623C6.11846 32.2095 5.9883 32.3373 5.85044 32.2857C5.66463 32.2163 5.48867 32.1217 5.32763 32.0043C5.21758 31.9239 5.1282 31.8186 5.06683 31.6969C5.00546 31.5753 4.97386 31.4408 4.97462 31.3046V29.3979C4.97462 29.0818 5.23094 28.8254 5.54712 28.8254C5.86331 28.8254 6.11963 29.0818 6.11963 29.3979V32.0623Z" fill="#E6AC94" />
      <path d="M11.1387 25.5176C11.1387 25.8263 11.2335 26.1275 11.4102 26.3806C11.5869 26.6337 11.837 26.8265 12.1268 26.933C12.4166 27.0395 12.7321 27.0546 13.0307 26.9763C13.3294 26.898 13.5968 26.7299 13.7969 26.4949C13.997 26.2598 14.1201 25.9691 14.1497 25.6619C14.1793 25.3546 14.1139 25.0457 13.9624 24.7768C13.8108 24.5079 13.5804 24.292 13.3022 24.1581C13.024 24.0242 12.7114 23.9789 12.4067 24.0282C12.0528 24.0856 11.731 24.2669 11.4987 24.5398C11.2664 24.8127 11.1388 25.1593 11.1387 25.5176Z" fill="#F1C0A6" />
      <path d="M11.1387 25.5175C11.1388 25.8759 11.2664 26.2225 11.4987 26.4953C11.891 26.9561 12.542 26.5636 12.5895 25.9604C12.6127 25.6656 12.6127 25.3695 12.5895 25.0747C12.542 24.4715 11.891 24.079 11.4987 24.5397C11.2664 24.8126 11.1388 25.1592 11.1387 25.5175Z" fill="#E6AC94" />
      <path d="M12.6484 26.057C12.9461 26.057 13.1874 25.8157 13.1874 25.518C13.1874 25.2203 12.9461 24.979 12.6484 24.979C12.3507 24.979 12.1094 25.2203 12.1094 25.518C12.1094 25.8157 12.3507 26.057 12.6484 26.057Z" fill="#E6AC94" />
      <path d="M11.0035 23.4026C11.7091 23.4505 12.2863 24.0178 12.2863 24.725C12.2863 26.2285 11.6889 27.6704 10.6253 28.7336C9.56182 29.7967 8.11938 30.394 6.61534 30.394C5.11129 30.394 3.66885 29.7967 2.60533 28.7336C1.54182 27.6704 0.944336 26.2285 0.944336 24.725C0.944336 23.7639 1.67008 22.9752 2.57947 22.6643C2.66481 22.6351 2.74965 22.6045 2.83395 22.5726C4.21924 22.0478 6.33485 22.2409 7.72294 22.7583C8.53093 23.0594 9.59937 23.3075 11.0035 23.4026Z" fill="#F1C0A6" />
      <path d="M6.61331 27.7081C6.12957 27.7077 5.65379 27.5849 5.23031 27.3511C5.20128 27.3352 5.17569 27.3137 5.15498 27.2878C5.13427 27.262 5.11886 27.2323 5.10962 27.2005C5.10038 27.1687 5.0975 27.1354 5.10113 27.1024C5.10477 27.0695 5.11486 27.0377 5.13081 27.0086C5.14677 26.9796 5.16828 26.954 5.19413 26.9333C5.21997 26.9126 5.24965 26.8972 5.28145 26.888C5.31326 26.8787 5.34656 26.8758 5.37948 26.8794C5.4124 26.8831 5.44429 26.8932 5.47331 26.9091C5.82211 27.1021 6.21419 27.2033 6.61281 27.2033C7.01142 27.2033 7.40351 27.1021 7.75231 26.9091C7.78133 26.8932 7.81321 26.8831 7.84613 26.8794C7.87905 26.8758 7.91236 26.8787 7.94417 26.888C7.97597 26.8972 8.00564 26.9126 8.03149 26.9333C8.05734 26.954 8.07885 26.9796 8.09481 27.0086C8.11076 27.0377 8.12084 27.0695 8.12448 27.1024C8.12811 27.1354 8.12523 27.1687 8.11599 27.2005C8.10676 27.2323 8.09134 27.262 8.07063 27.2878C8.04992 27.3137 8.02433 27.3352 7.99531 27.3511C7.57212 27.5848 7.09671 27.7076 6.61331 27.7081Z" fill="#D49985" />
      <path d="M7.25591 26.0147H5.9689C5.89531 26.0147 5.82472 25.9855 5.77268 25.9334C5.72064 25.8814 5.69141 25.8108 5.69141 25.7372C5.69141 25.6636 5.72064 25.593 5.77268 25.541C5.82472 25.4889 5.89531 25.4597 5.9689 25.4597H7.25591C7.3295 25.4597 7.40008 25.4889 7.45213 25.541C7.50417 25.593 7.5334 25.6636 7.5334 25.7372C7.5334 25.8108 7.50417 25.8814 7.45213 25.9334C7.40008 25.9855 7.3295 26.0147 7.25591 26.0147Z" fill="#E6AC94" />
      <path d="M8.43694 24.4551C7.94267 24.4551 7.52624 24.9113 7.87574 25.2608C8.08944 25.4745 8.37927 25.5946 8.68149 25.5946C8.9837 25.5946 9.27355 25.4745 9.48724 25.2608C9.83674 24.9113 9.42031 24.4551 8.92605 24.4551H8.43694Z" fill="#E6AC94" />
      <path d="M8.68223 25.2724C9.13345 25.2724 9.49923 24.9066 9.49923 24.4554C9.49923 24.0042 9.13345 23.6384 8.68223 23.6384C8.23102 23.6384 7.86523 24.0042 7.86523 24.4554C7.86523 24.9066 8.23102 25.2724 8.68223 25.2724Z" fill="#343A3E" />
      <path d="M9.02187 24.6612C9.21517 24.6612 9.37187 24.5045 9.37187 24.3112C9.37187 24.1179 9.21517 23.9612 9.02187 23.9612C8.82858 23.9612 8.67188 24.1179 8.67188 24.3112C8.67188 24.5045 8.82858 24.6612 9.02187 24.6612Z" fill="white" />
      <path d="M4.30022 24.4551C3.80595 24.4551 3.38953 24.9113 3.73903 25.2608C3.95272 25.4745 4.24256 25.5946 4.54477 25.5946C4.84699 25.5946 5.13682 25.4745 5.35052 25.2608C5.70002 24.9113 5.28359 24.4551 4.78933 24.4551H4.30022Z" fill="#E6AC94" />
      <path d="M4.54454 25.2724C4.99576 25.2724 5.36154 24.9066 5.36154 24.4554C5.36154 24.0042 4.99576 23.6384 4.54454 23.6384C4.09332 23.6384 3.72754 24.0042 3.72754 24.4554C3.72754 24.9066 4.09332 25.2724 4.54454 25.2724Z" fill="#343A3E" />
      <path d="M4.55492 25.1831C4.59865 25.2053 4.59356 25.2725 4.54454 25.2724C4.32785 25.2724 4.12005 25.1864 3.96683 25.0331C3.81361 24.8799 3.72754 24.6721 3.72754 24.4554C3.72754 24.2388 3.81361 24.031 3.96683 23.8777C4.12005 23.7245 4.32785 23.6384 4.54454 23.6384C4.59356 23.6384 4.59869 23.7057 4.55501 23.728C4.45051 23.7813 4.35801 23.8567 4.28445 23.95C4.17078 24.0941 4.10896 24.2724 4.10896 24.4559C4.10896 24.6395 4.17078 24.8177 4.28445 24.9619C4.35799 25.0551 4.45046 25.1302 4.55492 25.1831Z" fill="#1F2426" />
      <path d="M8.69262 25.1831C8.73635 25.2053 8.73126 25.2725 8.68224 25.2724C8.46555 25.2724 8.25775 25.1864 8.10453 25.0331C7.95131 24.8799 7.86523 24.6721 7.86523 24.4554C7.86523 24.2388 7.95131 24.031 8.10453 23.8777C8.25775 23.7245 8.46555 23.6384 8.68224 23.6384C8.73126 23.6384 8.73639 23.7057 8.69271 23.728C8.58821 23.7813 8.49571 23.8567 8.42215 23.95C8.30848 24.0941 8.24665 24.2724 8.24665 24.4559C8.24665 24.6395 8.30848 24.8177 8.42215 24.9619C8.49569 25.0551 8.58816 25.1302 8.69262 25.1831Z" fill="#1F2426" />
      <path d="M4.88516 24.6612C5.07846 24.6612 5.23516 24.5045 5.23516 24.3112C5.23516 24.1179 5.07846 23.9612 4.88516 23.9612C4.69186 23.9612 4.53516 24.1179 4.53516 24.3112C4.53516 24.5045 4.69186 24.6612 4.88516 24.6612Z" fill="white" />
      <path d="M27.7957 32.1984V36.7619C27.7957 37.7701 27.1966 38.6942 26.2387 39.009C24.2757 39.6528 22.1787 37.3456 20.0007 37.3456C18.7127 37.3456 17.4537 39.8787 16.2347 39.6448L16.1907 39.6358C15.289 39.4621 14.6777 38.646 14.6777 37.7276V32.1984C14.6777 31.0077 15.1509 29.8658 15.9931 29.0239C16.8353 28.182 17.9777 27.709 19.1687 27.709H23.3057C24.4968 27.709 25.6391 28.182 26.4814 29.0239C27.3236 29.8658 27.7957 31.0077 27.7957 32.1984Z" fill="#EF3D54" />
      <path d="M20.6817 27.709C19.4907 27.7092 18.3486 28.1824 17.5064 29.0242C16.6643 29.8661 16.191 31.0078 16.1907 32.1984V38.6834C16.1907 39.1784 15.7372 39.5513 15.2562 39.4342C14.9119 39.3504 14.6777 39.0385 14.6777 38.6841V32.1974C14.6777 31.0068 15.1509 29.8648 15.9931 29.0229C16.8353 28.181 17.9777 27.708 19.1687 27.708L20.6817 27.709Z" fill="#CA0051" />
      <path d="M26.2393 34.0508V34.2786C26.2393 37.0997 24.4226 39.7095 21.6106 39.9354C18.4903 40.1861 16.2393 37.3812 16.2393 34.2509V34.0488C16.2393 33.1408 16.6001 32.2699 17.2424 31.6278C17.8847 30.9857 18.7559 30.625 19.6643 30.625H22.8193C23.7271 30.6263 24.5973 30.9879 25.2386 31.6303C25.8799 32.2727 26.2398 33.1433 26.2393 34.0508Z" fill="#51AAF7" />
      <path d="M22.0504 30.627C21.6006 30.6269 21.1551 30.7153 20.7395 30.8874C20.3239 31.0594 19.9463 31.3116 19.6283 31.6295C19.3102 31.9475 19.0579 32.325 18.8858 32.7404C18.7138 33.1559 18.6253 33.6011 18.6254 34.0508V38.5939C18.6254 39.3296 18.0018 39.9153 17.2728 39.8161C16.6679 39.7337 16.2344 39.2051 16.2344 38.5946V34.0488C16.2344 33.5992 16.323 33.154 16.4951 32.7386C16.6672 32.3232 16.9195 31.9457 17.2375 31.6278C17.5556 31.3099 17.9332 31.0577 18.3487 30.8856C18.7642 30.7136 19.2096 30.625 19.6594 30.625L22.0504 30.627Z" fill="#378AE5" />
      <path d="M22.8951 15.605H19.5801C18.276 15.605 17.0254 16.1228 16.1032 17.0446C15.1811 17.9664 14.6631 19.2166 14.6631 20.5203C14.6631 23.2807 16.9009 25.5185 19.6613 25.5185H22.8138C25.5743 25.5185 27.8121 23.2807 27.8121 20.5203C27.8121 19.2166 27.294 17.9664 26.3719 17.0446C25.4498 16.1228 24.1992 15.605 22.8951 15.605Z" fill="#FADC42" />
      <path d="M22.4641 15.605C21.16 15.605 19.9094 16.1228 18.9873 17.0446C18.0651 17.9664 17.5471 19.2166 17.5471 20.5203V24.076C17.5471 24.8727 16.9013 25.5185 16.1046 25.5185C15.3079 25.5185 14.6621 24.8727 14.6621 24.076V20.5203C14.6621 19.2166 15.1801 17.9664 16.1023 17.0446C17.0244 16.1228 18.275 15.605 19.5791 15.605H22.4641Z" fill="#F6B231" />
      <path d="M19.5967 31.3095V30.4693C19.5967 29.5641 20.3305 28.8303 21.2357 28.8303C22.1409 28.8303 22.8747 29.5641 22.8747 30.4693V31.3095C22.8754 31.4458 22.8437 31.5803 22.7822 31.702C22.7206 31.8237 22.631 31.929 22.5207 32.0092C22.1473 32.2812 21.6972 32.4277 21.2352 32.4277C20.7732 32.4277 20.3231 32.2812 19.9497 32.0092C19.8396 31.9289 19.7502 31.8235 19.6888 31.7019C19.6274 31.5802 19.5959 31.4457 19.5967 31.3095Z" fill="#F1C0A6" />
      <path d="M20.7427 32.0623C20.7416 32.2095 20.6114 32.3373 20.4735 32.2857C20.2877 32.2163 20.1117 32.1217 19.9507 32.0043C19.8406 31.9239 19.7512 31.8186 19.6899 31.6969C19.6285 31.5753 19.5969 31.4408 19.5977 31.3046V29.3979C19.5977 29.0818 19.854 28.8254 20.1702 28.8254C20.4864 28.8254 20.7427 29.0818 20.7427 29.3979V32.0623Z" fill="#E6AC94" />
      <path d="M16.7104 25.5176C16.7103 25.8263 16.6155 26.1275 16.4388 26.3806C16.2621 26.6337 16.012 26.8265 15.7222 26.933C15.4325 27.0395 15.117 27.0546 14.8183 26.9763C14.5197 26.898 14.2523 26.7299 14.0522 26.4949C13.8521 26.2598 13.7289 25.9691 13.6993 25.6619C13.6697 25.3546 13.7351 25.0457 13.8867 24.7768C14.0382 24.5079 14.2686 24.292 14.5468 24.1581C14.825 24.0242 15.1376 23.9789 15.4424 24.0282C15.7962 24.0856 16.118 24.2669 16.3503 24.5398C16.5826 24.8127 16.7103 25.1593 16.7104 25.5176Z" fill="#F1C0A6" />
      <path d="M16.7105 25.5175C16.7104 25.8759 16.5828 26.2225 16.3504 26.4953C15.9582 26.9561 15.3071 26.5636 15.2596 25.9604C15.2364 25.6656 15.2364 25.3695 15.2596 25.0747C15.3071 24.4715 15.9582 24.079 16.3504 24.5397C16.5828 24.8126 16.7104 25.1592 16.7105 25.5175Z" fill="#E6AC94" />
      <path d="M15.2001 26.057C15.4978 26.057 15.7391 25.8157 15.7391 25.518C15.7391 25.2203 15.4978 24.979 15.2001 24.979C14.9025 24.979 14.6611 25.2203 14.6611 25.518C14.6611 25.8157 14.9025 26.057 15.2001 26.057Z" fill="#E6AC94" />
      <path d="M25.7617 25.5176C25.7618 25.8263 25.8566 26.1275 26.0333 26.3806C26.21 26.6337 26.4601 26.8265 26.7498 26.933C27.0396 27.0395 27.3551 27.0546 27.6537 26.9763C27.9524 26.898 28.2198 26.7299 28.4199 26.4949C28.62 26.2598 28.7432 25.9691 28.7728 25.6619C28.8024 25.3546 28.737 25.0457 28.5854 24.7768C28.4339 24.5079 28.2035 24.292 27.9253 24.1581C27.6471 24.0242 27.3345 23.9789 27.0297 24.0282C26.6759 24.0856 26.3541 24.2669 26.1218 24.5398C25.8895 24.8127 25.7618 25.1593 25.7617 25.5176Z" fill="#F1C0A6" />
      <path d="M25.7617 25.5175C25.7618 25.8759 25.8895 26.2225 26.1218 26.4953C26.514 26.9561 27.1651 26.5636 27.2126 25.9604C27.2358 25.6656 27.2358 25.3695 27.2126 25.0747C27.1651 24.4715 26.514 24.079 26.1218 24.5397C25.8895 24.8126 25.7618 25.1592 25.7617 25.5175Z" fill="#E6AC94" />
      <path d="M27.2714 26.057C27.5691 26.057 27.8104 25.8157 27.8104 25.518C27.8104 25.2203 27.5691 24.979 27.2714 24.979C26.9737 24.979 26.7324 25.2203 26.7324 25.518C26.7324 25.8157 26.9737 26.057 27.2714 26.057Z" fill="#E6AC94" />
      <path d="M25.6246 23.4026C26.3302 23.4505 26.9074 24.0178 26.9074 24.725C26.9074 26.2285 26.3099 27.6704 25.2464 28.7336C24.1829 29.7967 22.7405 30.394 21.2364 30.394C19.7324 30.394 18.2899 29.7967 17.2264 28.7336C16.1629 27.6704 15.5654 26.2285 15.5654 24.725C15.5654 23.7639 16.2912 22.9752 17.2006 22.6643C17.2862 22.635 17.3713 22.6043 17.4558 22.5723C18.8411 22.0474 20.9555 22.2401 22.3435 22.7577C23.1516 23.0591 24.2202 23.3074 25.6246 23.4026Z" fill="#F1C0A6" />
      <path d="M22.1477 30.2893C22.165 30.2927 22.1648 30.318 22.1474 30.321C21.3362 30.4523 20.5062 30.4059 19.7147 30.185C18.9232 29.9641 18.1891 29.574 17.5633 29.0416C16.9375 28.5093 16.4348 27.8474 16.09 27.1017C15.7452 26.3561 15.5666 25.5444 15.5664 24.7229V23.8152C15.5664 23.3757 15.9008 23.0132 16.3302 22.9193C16.9314 22.7878 17.5664 23.2078 17.5664 23.8232V24.7219C17.5657 26.0525 18.0331 27.341 18.8868 28.3619C19.7212 29.3596 20.8728 30.0396 22.1477 30.2893Z" fill="#E6AC94" />
      <path d="M21.2364 27.7081C20.7526 27.7077 20.2768 27.5849 19.8534 27.3511C19.8243 27.3352 19.7987 27.3137 19.778 27.2878C19.7573 27.262 19.7419 27.2323 19.7327 27.2005C19.7234 27.1687 19.7205 27.1354 19.7242 27.1024C19.7278 27.0695 19.7379 27.0377 19.7539 27.0086C19.7698 26.9796 19.7913 26.954 19.8172 26.9333C19.843 26.9126 19.8727 26.8972 19.9045 26.888C19.9363 26.8787 19.9696 26.8758 20.0025 26.8794C20.0354 26.8831 20.0673 26.8932 20.0964 26.9091C20.4452 27.1021 20.8372 27.2033 21.2359 27.2033C21.6345 27.2033 22.0266 27.1021 22.3754 26.9091C22.4044 26.8932 22.4363 26.8831 22.4692 26.8794C22.5021 26.8758 22.5354 26.8787 22.5672 26.888C22.599 26.8972 22.6287 26.9126 22.6545 26.9333C22.6804 26.954 22.7019 26.9796 22.7179 27.0086C22.7338 27.0377 22.7439 27.0695 22.7475 27.1024C22.7512 27.1354 22.7483 27.1687 22.739 27.2005C22.7298 27.2323 22.7144 27.262 22.6937 27.2878C22.673 27.3137 22.6474 27.3352 22.6184 27.3511C22.1952 27.5848 21.7198 27.7076 21.2364 27.7081Z" fill="#D49985" />
      <path d="M21.8799 26.0147H20.5929C20.5193 26.0147 20.4488 25.9855 20.3967 25.9334C20.3447 25.8814 20.3154 25.8108 20.3154 25.7372C20.3154 25.6636 20.3447 25.593 20.3967 25.541C20.4488 25.4889 20.5193 25.4597 20.5929 25.4597H21.8799C21.9535 25.4597 22.0241 25.4889 22.0762 25.541C22.1282 25.593 22.1574 25.6636 22.1574 25.7372C22.1574 25.8108 22.1282 25.8814 22.0762 25.9334C22.0241 25.9855 21.9535 26.0147 21.8799 26.0147Z" fill="#E6AC94" />
      <path d="M23.06 24.4551C22.5657 24.4551 22.1493 24.9113 22.4988 25.2608C22.7125 25.4745 23.0023 25.5946 23.3045 25.5946C23.6067 25.5946 23.8966 25.4745 24.1103 25.2608C24.4598 24.9113 24.0434 24.4551 23.5491 24.4551H23.06Z" fill="#E6AC94" />
      <path d="M23.3053 25.2724C23.7565 25.2724 24.1223 24.9066 24.1223 24.4554C24.1223 24.0042 23.7565 23.6384 23.3053 23.6384C22.8541 23.6384 22.4883 24.0042 22.4883 24.4554C22.4883 24.9066 22.8541 25.2724 23.3053 25.2724Z" fill="#343A3E" />
      <path d="M23.6449 24.6612C23.8382 24.6612 23.9949 24.5045 23.9949 24.3112C23.9949 24.1179 23.8382 23.9612 23.6449 23.9612C23.4516 23.9612 23.2949 24.1179 23.2949 24.3112C23.2949 24.5045 23.4516 24.6612 23.6449 24.6612Z" fill="white" />
      <path d="M18.9233 24.4551C18.429 24.4551 18.0126 24.9113 18.3621 25.2608C18.5758 25.4745 18.8656 25.5946 19.1678 25.5946C19.47 25.5946 19.7599 25.4745 19.9736 25.2608C20.3231 24.9113 19.9066 24.4551 19.4124 24.4551H18.9233Z" fill="#E6AC94" />
      <path d="M19.1676 25.2724C19.6188 25.2724 19.9846 24.9066 19.9846 24.4554C19.9846 24.0042 19.6188 23.6384 19.1676 23.6384C18.7164 23.6384 18.3506 24.0042 18.3506 24.4554C18.3506 24.9066 18.7164 25.2724 19.1676 25.2724Z" fill="#343A3E" />
      <path d="M19.177 25.1831C19.2207 25.2053 19.2156 25.2725 19.1666 25.2724C18.9499 25.2724 18.7421 25.1864 18.5889 25.0331C18.4357 24.8799 18.3496 24.6721 18.3496 24.4554C18.3496 24.2388 18.4357 24.031 18.5889 23.8777C18.7421 23.7245 18.9499 23.6384 19.1666 23.6384C19.2156 23.6384 19.2208 23.7057 19.1771 23.728C19.0726 23.7813 18.9801 23.8567 18.9065 23.95C18.7929 24.0941 18.731 24.2724 18.731 24.4559C18.731 24.6395 18.7929 24.8177 18.9065 24.9619C18.9801 25.0551 19.0725 25.1302 19.177 25.1831Z" fill="#1F2426" />
      <path d="M23.3157 25.1831C23.3594 25.2053 23.3543 25.2725 23.3053 25.2724C23.0886 25.2724 22.8808 25.1864 22.7276 25.0331C22.5744 24.8799 22.4883 24.6721 22.4883 24.4554C22.4883 24.2388 22.5744 24.031 22.7276 23.8777C22.8808 23.7245 23.0886 23.6384 23.3053 23.6384C23.3543 23.6384 23.3594 23.7057 23.3158 23.728C23.2113 23.7812 23.1188 23.8567 23.0452 23.95C22.9315 24.0941 22.8697 24.2724 22.8697 24.4559C22.8697 24.6395 22.9315 24.8177 23.0452 24.9619C23.1187 25.0551 23.2112 25.1302 23.3157 25.1831Z" fill="#1F2426" />
      <path d="M19.5082 24.6612C19.7015 24.6612 19.8582 24.5045 19.8582 24.3112C19.8582 24.1179 19.7015 23.9612 19.5082 23.9612C19.3149 23.9612 19.1582 24.1179 19.1582 24.3112C19.1582 24.5045 19.3149 24.6612 19.5082 24.6612Z" fill="white" />
      <path d="M37.9268 27.708C38.189 27.7081 38.3473 27.9806 38.2167 28.2079C37.2141 29.9518 35.1413 30.714 33.7638 32.2374C32.5768 33.5529 32.3088 35.8621 30.8118 36.8258C30.1425 37.2564 29.2988 36.7522 29.2988 35.9563V32.1974C29.2988 31.0068 29.772 29.8648 30.6142 29.0229C31.4565 28.181 32.5987 27.708 33.7898 27.708H37.9268Z" fill="#EF3D54" />
      <path d="M35.3038 27.709C34.1127 27.709 32.9704 28.182 32.1282 29.0239C31.2859 29.8658 30.8128 31.0078 30.8128 32.1984V36.3957C30.8128 36.6662 30.6766 36.9188 30.446 37.0602C29.9356 37.3732 29.2998 36.9931 29.2998 36.3944V32.1974C29.2998 31.0068 29.7729 29.8648 30.6152 29.0229C31.4574 28.181 32.5997 27.708 33.7908 27.708L35.3038 27.709Z" fill="#CA0051" />
      <path d="M34.2815 30.627C35.4408 30.627 36.1252 31.8932 35.3839 32.7844C34.6748 33.637 33.8962 34.4294 33.0564 35.1535C32.1582 35.9278 30.8564 35.2366 30.8564 34.0507C30.8564 33.1427 31.2173 32.2718 31.8596 31.6297C32.5019 30.9877 33.3731 30.627 34.2815 30.627Z" fill="#B7E445" />
      <path d="M36.6725 30.627C36.2226 30.6268 35.7772 30.7153 35.3616 30.8873C34.946 31.0593 34.5684 31.3115 34.2503 31.6295C33.9322 31.9474 33.68 32.3249 33.5079 32.7404C33.3358 33.1558 33.2473 33.6011 33.2475 34.0507V34.395C33.2475 34.7711 33.0876 35.131 32.7987 35.3717C31.9995 36.0378 30.8564 35.4287 30.8564 34.3882V34.0507C30.8564 33.6011 30.945 33.1559 31.1172 32.7405C31.2893 32.3251 31.5416 31.9477 31.8596 31.6297C32.1777 31.3118 32.5552 31.0596 32.9708 30.8876C33.3863 30.7155 33.8317 30.627 34.2815 30.627H36.6725Z" fill="#7BC52A" />
      <path d="M40.0002 20.0075C40.0006 20.4432 39.9867 20.8784 39.9587 21.3125C39.7956 23.8383 37.4855 25.5175 34.9544 25.5175H34.2824C31.522 25.5175 29.2842 23.2797 29.2842 20.5193C29.2842 19.2157 29.8022 17.9655 30.7243 17.0437C31.6465 16.1219 32.8971 15.604 34.2012 15.604H37.5162C38.8808 15.601 39.8031 16.8601 39.9228 18.2195C39.9751 18.8137 40.001 19.4102 40.0002 20.0075Z" fill="#AA7A63" />
      <path d="M37.0872 15.605C35.7831 15.605 34.5325 16.1228 33.6104 17.0446C32.6882 17.9664 32.1702 19.2166 32.1702 20.5203V24.0755C32.1702 24.8724 31.5241 25.5185 30.7272 25.5185C29.9302 25.5185 29.2842 24.8724 29.2842 24.0755V20.5203C29.2842 19.2166 29.8022 17.9664 30.7243 17.0446C31.6465 16.1228 32.8971 15.605 34.2012 15.605H37.0872Z" fill="#845C47" />
      <path d="M37.4977 28.8304V29.0722C37.4977 29.4815 37.3965 29.8856 37.1867 30.237C37.1087 30.3669 37.0297 30.4969 36.9487 30.6258C36.7723 30.9065 36.5889 31.1828 36.3987 31.4542C35.9693 32.0675 35.1785 32.4447 34.5737 32.0033C34.4636 31.923 34.374 31.8177 34.3125 31.696C34.2509 31.5744 34.2192 31.4399 34.2197 31.3036V30.4635C34.2197 29.5583 34.9535 28.8245 35.8587 28.8245H37.4917C37.495 28.8245 37.4977 28.8271 37.4977 28.8304Z" fill="#F1C0A6" />
      <path d="M35.3657 32.0623C35.3646 32.2095 35.2344 32.3373 35.0965 32.2857C34.9107 32.2163 34.7348 32.1217 34.5737 32.0043C34.4637 31.9239 34.3743 31.8186 34.3129 31.6969C34.2516 31.5753 34.22 31.4408 34.2207 31.3046V29.3979C34.2207 29.0818 34.477 28.8254 34.7932 28.8254C35.1094 28.8254 35.3657 29.0818 35.3657 29.3979V32.0623Z" fill="#E6AC94" />
      <path d="M31.3334 25.5176C31.3334 25.8263 31.2386 26.1275 31.0619 26.3806C30.8852 26.6337 30.6351 26.8265 30.3453 26.933C30.0555 27.0395 29.74 27.0546 29.4414 26.9763C29.1428 26.898 28.8754 26.7299 28.6753 26.4949C28.4752 26.2598 28.352 25.9691 28.3224 25.6619C28.2928 25.3546 28.3582 25.0457 28.5097 24.7768C28.6613 24.5079 28.8917 24.292 29.1699 24.1581C29.4481 24.0242 29.7607 23.9789 30.0655 24.0282C30.4193 24.0856 30.7411 24.2669 30.9734 24.5398C31.2057 24.8127 31.3333 25.1593 31.3334 25.5176Z" fill="#F1C0A6" />
      <path d="M31.3335 25.5175C31.3334 25.8759 31.2057 26.2225 30.9734 26.4953C30.5812 26.9561 29.9301 26.5636 29.8826 25.9604C29.8594 25.6656 29.8594 25.3695 29.8826 25.0747C29.9301 24.4715 30.5812 24.079 30.9734 24.5397C31.2057 24.8126 31.3334 25.1592 31.3335 25.5175Z" fill="#E6AC94" />
      <path d="M29.8232 26.057C30.1209 26.057 30.3622 25.8157 30.3622 25.518C30.3622 25.2203 30.1209 24.979 29.8232 24.979C29.5255 24.979 29.2842 25.2203 29.2842 25.518C29.2842 25.8157 29.5255 26.057 29.8232 26.057Z" fill="#E6AC94" />
      <path d="M37.5428 22.9504C38.6932 23.2891 39.5601 24.366 39.2295 25.5187C38.7946 27.037 38.1804 28.4975 37.4002 29.8696C37.2655 30.1066 37.0365 30.2776 36.7675 30.322C35.9564 30.453 35.1265 30.4063 34.3351 30.1853C33.5438 29.9643 32.8099 29.5741 32.1841 29.0419C31.5584 28.5096 31.0557 27.8478 30.7108 27.1023C30.3659 26.3568 30.187 25.5453 30.1865 24.724C30.1865 23.7629 30.9122 22.9741 31.8216 22.6631C31.907 22.6338 31.992 22.6032 32.0763 22.5713C33.4613 22.0463 35.5754 22.2396 36.9629 22.7577C37.143 22.825 37.336 22.8896 37.5428 22.9504Z" fill="#F1C0A6" />
      <path d="M36.7697 30.2893C36.787 30.2927 36.7868 30.318 36.7695 30.321C35.9583 30.4523 35.1282 30.4059 34.3367 30.185C33.5452 29.9641 32.8112 29.574 32.1854 29.0416C31.5595 28.5093 31.0568 27.8474 30.712 27.1017C30.3673 26.3561 30.1886 25.5444 30.1885 24.7229V23.8152C30.1885 23.3757 30.5229 23.0132 30.9522 22.9193C31.5535 22.7878 32.1885 23.2078 32.1885 23.8232V24.7219C32.1877 26.0525 32.6551 27.341 33.5089 28.3619C34.3433 29.3596 35.4948 30.0396 36.7697 30.2893Z" fill="#E6AC94" />
      <path d="M35.8594 27.7081C35.3756 27.7077 34.8999 27.5849 34.4764 27.3511C34.4178 27.3189 34.3744 27.2647 34.3557 27.2005C34.3371 27.1363 34.3447 27.0672 34.3769 27.0086C34.4091 26.95 34.4633 26.9066 34.5275 26.888C34.5918 26.8693 34.6608 26.8769 34.7194 26.9091C35.0682 27.1021 35.4603 27.2033 35.8589 27.2033C36.2575 27.2033 36.6496 27.1021 36.9984 26.9091C37.0274 26.8932 37.0593 26.8831 37.0922 26.8794C37.1251 26.8758 37.1584 26.8787 37.1903 26.888C37.2221 26.8972 37.2517 26.9126 37.2776 26.9333C37.3034 26.954 37.3249 26.9796 37.3409 27.0086C37.3568 27.0377 37.3669 27.0695 37.3706 27.1024C37.3742 27.1354 37.3713 27.1687 37.3621 27.2005C37.3528 27.2323 37.3374 27.262 37.3167 27.2878C37.296 27.3137 37.2704 27.3352 37.2414 27.3511C36.8182 27.5848 36.3428 27.7076 35.8594 27.7081Z" fill="#D49985" />
      <path d="M36.502 26.0147H35.215C35.1414 26.0147 35.0708 25.9855 35.0188 25.9334C34.9668 25.8814 34.9375 25.8108 34.9375 25.7372C34.9375 25.6636 34.9668 25.593 35.0188 25.541C35.0708 25.4889 35.1414 25.4597 35.215 25.4597H36.502C36.5756 25.4597 36.6462 25.4889 36.6982 25.541C36.7503 25.593 36.7795 25.6636 36.7795 25.7372C36.7795 25.8108 36.7503 25.8814 36.6982 25.9334C36.6462 25.9855 36.5756 26.0147 36.502 26.0147Z" fill="#E6AC94" />
      <path d="M37.683 24.4551C37.1888 24.4551 36.7724 24.9113 37.1219 25.2608C37.3356 25.4745 37.6254 25.5946 37.9276 25.5946C38.2298 25.5946 38.5196 25.4745 38.7333 25.2608C39.0828 24.9113 38.6664 24.4551 38.1721 24.4551H37.683Z" fill="#E6AC94" />
      <path d="M37.9274 25.2724C38.3786 25.2724 38.7444 24.9066 38.7444 24.4554C38.7444 24.0042 38.3786 23.6384 37.9274 23.6384C37.4761 23.6384 37.1104 24.0042 37.1104 24.4554C37.1104 24.9066 37.4761 25.2724 37.9274 25.2724Z" fill="#343A3E" />
      <path d="M38.268 24.6612C38.4613 24.6612 38.618 24.5045 38.618 24.3112C38.618 24.1179 38.4613 23.9612 38.268 23.9612C38.0747 23.9612 37.918 24.1179 37.918 24.3112C37.918 24.5045 38.0747 24.6612 38.268 24.6612Z" fill="white" />
      <path d="M33.5463 24.4551C33.0521 24.4551 32.6356 24.9113 32.9851 25.2608C33.1988 25.4745 33.4886 25.5946 33.7909 25.5946C34.0931 25.5946 34.3829 25.4745 34.5966 25.2608C34.9461 24.9113 34.5297 24.4551 34.0354 24.4551H33.5463Z" fill="#E6AC94" />
      <path d="M33.7897 25.2724C34.2409 25.2724 34.6067 24.9066 34.6067 24.4554C34.6067 24.0042 34.2409 23.6384 33.7897 23.6384C33.3384 23.6384 32.9727 24.0042 32.9727 24.4554C32.9727 24.9066 33.3384 25.2724 33.7897 25.2724Z" fill="#343A3E" />
      <path d="M33.8001 25.1831C33.8438 25.2053 33.8387 25.2725 33.7897 25.2724C33.573 25.2724 33.3652 25.1864 33.2119 25.0331C33.0587 24.8799 32.9727 24.6721 32.9727 24.4554C32.9727 24.2388 33.0587 24.031 33.2119 23.8777C33.3652 23.7245 33.573 23.6384 33.7897 23.6384C33.8387 23.6384 33.8438 23.7057 33.8001 23.728C33.6956 23.7812 33.6031 23.8567 33.5296 23.95C33.4159 24.0941 33.3541 24.2724 33.3541 24.4559C33.3541 24.6395 33.4159 24.8177 33.5296 24.9619C33.6031 25.0551 33.6956 25.1302 33.8001 25.1831Z" fill="#1F2426" />
      <path d="M37.9377 25.1831C37.9815 25.2053 37.9764 25.2725 37.9273 25.2724C37.7107 25.2724 37.5029 25.1864 37.3496 25.0331C37.1964 24.8799 37.1104 24.6721 37.1104 24.4554C37.1104 24.2388 37.1964 24.031 37.3496 23.8777C37.5029 23.7245 37.7107 23.6384 37.9273 23.6384C37.9764 23.6384 37.9815 23.7057 37.9378 23.728C37.8333 23.7813 37.7408 23.8567 37.6673 23.95C37.5536 24.0941 37.4918 24.2724 37.4918 24.4559C37.4918 24.6395 37.5536 24.8177 37.6673 24.9619C37.7408 25.0551 37.8333 25.1302 37.9377 25.1831Z" fill="#1F2426" />
      <path d="M34.1312 24.6612C34.3245 24.6612 34.4812 24.5045 34.4812 24.3112C34.4812 24.1179 34.3245 23.9612 34.1312 23.9612C33.938 23.9612 33.7812 24.1179 33.7812 24.3112C33.7812 24.5045 33.938 24.6612 34.1312 24.6612Z" fill="white" />
      <path d="M1.98055 26.1614C1.72969 26.6922 1.04707 26.4347 0.874997 25.8734C0.794348 25.6103 0.719181 25.3456 0.649546 25.0793C0.500969 24.5113 0.946531 23.9337 1.43885 24.2535C1.74471 24.4523 1.96814 24.7551 2.06775 25.1059C2.16735 25.4567 2.13637 25.8317 1.98055 26.1614Z" fill="#F1C0A6" />
      <path d="M2.12299 25.5178C2.12288 25.1595 1.99526 24.8129 1.76295 24.54C1.74007 24.5131 1.71632 24.4871 1.69176 24.462C1.31021 24.0726 0.733967 24.427 0.681196 24.9696C0.669992 25.0848 0.681869 25.2011 0.712483 25.3127C0.782554 25.5682 0.857492 25.8215 0.937298 26.0727C1.08057 26.5236 1.61795 26.7383 1.87696 26.3424C2.03732 26.0972 2.1228 25.8107 2.12299 25.5178Z" fill="#E6AC94" />
      <path d="M1.15402 25.5209C1.15404 25.3795 1.09849 25.2438 0.99935 25.1429C0.851513 24.9926 0.675583 25.1835 0.73219 25.3866C0.762942 25.4969 0.794654 25.607 0.827327 25.7168C0.866071 25.8471 1.02304 25.8989 1.08817 25.7797C1.13152 25.7003 1.15417 25.6114 1.15402 25.5209Z" fill="#E6AC94" />
      <path d="M7.52365 30.2893C7.54093 30.2927 7.54075 30.318 7.52338 30.321C6.71219 30.4523 5.88215 30.4059 5.09066 30.185C4.29917 29.9641 3.56512 29.574 2.93928 29.0416C2.31344 28.5093 1.81075 27.8474 1.46596 27.1017C1.12118 26.3561 0.942533 25.5444 0.942383 24.7229V23.8152C0.942383 23.3757 1.27682 23.0132 1.70613 22.9193C2.30737 22.7878 2.94238 23.2078 2.94238 23.8232V24.7219C2.94163 26.0525 3.40903 27.341 4.26278 28.3619C5.09722 29.3596 6.24876 30.0396 7.52365 30.2893Z" fill="#E6AC94" />
    </svg>
  );
}

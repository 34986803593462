import { useState, useEffect } from "react";
import { DWBRiskDepartmentStyled } from "./styled";
import { Blocks, Typographys, Inputs, Skeleton } from "components";
import moment from "moment";

const DWBRiskDepartment = ({ data, fetchData }) => {
  const [_data, _setData] = useState();
  const [_loadSkeleton, _setLoadSkeleton] = useState(false);

  useEffect(() => {
    if (data) {
      _setData(data);
    }
  }, [data]);

  const fetchDataApi = async (stat_date) => {
    let res = await fetchData(stat_date);
    if (res) {
      _setData(res);
      _setLoadSkeleton(false);
    } else {
      _setLoadSkeleton(false);
    }
  };
  const handleChangDate = (e) => {
    _setLoadSkeleton(true);
    fetchDataApi(`?stat_date=${moment(e).format("YYYY-MM-DD")}`);
  };

  return (
    <DWBRiskDepartmentStyled>
      <Blocks.Box
        theme_standard_box
        paddingTop="23px"
        paddingRight="20px"
        paddingBottom="23px"
        paddingLeft="20px"
      >
        <div className="bmi_department_head_container">
          <div className="bmi_department_head_col">
            <Typographys.TopBottom
              theme_left_align
              label="สัดส่วนพนักงานที่มีความเสี่ยง DWB แบ่งตามแผนก"
              labelBottom="Percentage of DWB risk’s employees by department"
              fontSizeLabelButtom="14px"
            />
            <div className="chart_status_block">
              <div className="item_status ml_29">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="ไม่มีความเสี่ยง"
                  labelBottom="No symptoms"
                  bgColor="#8AC47F"
                  showRound
                />
              </div>
              <div className="item_status ml_11">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="มีความเสี่ยง DWB"
                  labelBottom="Risks of dangerous towards job descriptions "
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
          </div>
          {/* <div className="bmi_department_head_col">
            <div className="chart_status_block">
              <div className="item_status ml_29">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="ไม่มีความเสี่ยง"
                  labelBottom="No symptoms"
                  bgColor="#8AC47F"
                  showRound
                />
              </div>
              <div className="item_status ml_11">
                <Blocks.BoxWellbeing
                  theme_average_box_well_being
                  label="มีความเสี่ยง DWB"
                  labelBottom="Risks of dangerous towards job descriptions "
                  bgColor="#654C96"
                  showRound
                />
              </div>
            </div>
          </div> */}
          <div className="bmi_department_head_col">
            <div className="filter_block">
              <div className="item_filter w_200">
                <Inputs.InputDatePikerCustom
                  theme_quarter
                  onChangeCustom={(e) => handleChangDate(e)}
                  dateFormat="yyyy, QQQ"
                  showQuarterYearPicker
                  initialValues={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="chart_container">
          {!_data || _data.length === 0 ? (
            <div className="label_empty">ยังไม่มีข้อมูล</div>
          ) : _loadSkeleton ? (
            <Skeleton.GraphSkeleton height="100px" />
          ) : (
            _data.map((d, h) => (
              <div key={h} className="chart_wrap">
                {d && (
                  <div className="c_row_2_col">
                    {d.map((e, i) => (
                      <div key={i} className="bar_item">
                        <div className="label">{e.name}</div>
                        <div className="percent_bar">
                          <div
                            className="stack_item"
                            style={{
                              width: `${e.nosymptom_user_percent}%`,
                              backgroundColor: COLORS_GRAPH[0],
                            }}
                          >
                            {e.nosymptom_user_percent !== 0 &&
                              `${e.nosymptom_user_percent}%`}
                          </div>
                          <div
                            className="stack_item"
                            style={{
                              width: `${e.risk_user_percent}%`,
                              backgroundColor: COLORS_GRAPH[1],
                            }}
                          >
                            {e.risk_user_percent !== 0 &&
                              `${e.risk_user_percent}%`}
                          </div>

                          {/* {e.data.map((e2, j) => (
                            <div
                              key={j}
                              className="stack_item"
                              style={{
                                width: `${e2.nosymptom_user_percent}%`,
                                backgroundColor: COLORS_GRAPH[j],
                              }}
                            >
                              {e2.nosymptom_user_percent !== 0 && `${e2.nosymptom_user_percent}%`}
                            </div>
                          ))} */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Blocks.Box>
    </DWBRiskDepartmentStyled>
  );
};

const COLORS_GRAPH = ["#8AC47F", "#654C96"];

export default DWBRiskDepartment;

import React from "react";
import { DepartmentDetailContainerStyled } from "./styled";
import { Buttons, Icons, Loading, Cards } from "components";
import { ArticleDetail, TotalView, TotalLike, TotalScore } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import { adminService } from "apiServices";
import { connect } from "react-redux";
import { getObjectKey } from "utils/functions/getObjectKey";

class DepartmentDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.checkPermission();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await adminService.GET_CONTENT_BY_ID(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
      if (res?.data?.type === "video") {
        this.setState({
          files: res?.data?.videos,
        });
      } else if (res?.data?.type === "audio") {
        this.setState({
          files: res?.data?.audios,
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey(
      authenRedux.admin_role.permissions,
      "content_manage"
    );
    this.setState({
      perMissions: checkPermission,
    });
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_NEW);
  };

  render() {
    const { data, isLoading, files, perMissions } = this.state;
    return (
      <DepartmentDetailContainerStyled>
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <div className="layer_one">
              <div className={`head_wrap_layout`}>
                <div className="h_33">
                  <Buttons.BtnLink
                    theme_standard_btn_link
                    label="ย้อนกลับ"
                    svg={<Icons.ArrowLeft />}
                    onClick={this.handleBackClick}
                  />
                </div>
                <div className="h_33 center">
                  <div className="text_c">บทความ</div>
                  <div className="g_h_text">Article</div>
                </div>
                <div className="h_33 flex" />
              </div>
            </div>
            <div className="layer_two">
              <ArticleDetail
                data={data}
                showButton={perMissions ? true : false}
              />
            </div>
            <div className="layer_three">
              <div className="left_three">
                {files &&
                  files.length > 0 &&
                  files.map((e, i) => (
                    <Cards.PreviewMedia
                      key={i}
                      type={data?.type}
                      src={e.media_uri}
                      name={e.name}
                      theme_standard
                    />
                  ))}
                <div
                  className="show_det"
                  style={{ overflow: "auto" }}
                  dangerouslySetInnerHTML={{ __html: data?.body }}
                ></div>
              </div>
              <div className="right_three">
                <div className="t_three">
                  <TotalView
                    total={data?.view_count}
                    maleCount={data?.view_count_male}
                    famaleCount={data?.view_count_female}
                  />
                </div>
                <div className="b_three">
                  <div className="box_three">
                    <TotalLike data={data?.like_count} />
                  </div>
                  <div className="box_three">
                    <TotalScore data={data?.redeemed_coin_amount} />
                  </div>
                </div>
              </div>
            </div>
            {data && data.covers && data.covers.length > 0 && (
              <div className="c_row_3_col">
                {data.covers.map((e, i) => (
                  <div key={i} className="item_wrap">
                    <Cards.BoxImage src={e.media_uri} />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </DepartmentDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentDetailContainer);

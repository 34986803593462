import { default as BarPercent2 } from './BarPercent2';
import { default as CountDown } from './CountDown';
import { default as StatusLabel } from './StatusLabel';

const EXPORT = {
  BarPercent2,
  CountDown,
  StatusLabel,
};

export default EXPORT;

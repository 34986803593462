export default function Nutrition({ width = "32", height = "32" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <g
        id="Group_57055"
        data-name="Group 57055"
        transform="translate(-96 -608)"
      >
        <g
          id="Group_56169"
          data-name="Group 56169"
          transform="translate(-56 272)"
        >
          <circle
            id="Ellipse_1503"
            data-name="Ellipse 1503"
            cx="16"
            cy="16"
            r="16"
            transform="translate(152 336)"
            fill="#aad3ed"
          />
        </g>
        <g
          id="smile_1_"
          data-name="smile (1)"
          transform="translate(102.753 613.884)"
        >
          <path
            id="Path_56004"
            data-name="Path 56004"
            d="M22.8,26.73a9.5,9.5,0,1,1-9.5-9.5A9.5,9.5,0,0,1,22.8,26.73Zm0,0"
            transform="translate(-3.558 -16.114)"
            fill="#ffe765"
          />
          <path
            id="Path_56005"
            data-name="Path 56005"
            d="M5.387,26.73A9.5,9.5,0,0,1,14.1,17.264c-.261-.022-.525-.033-.791-.033a9.5,9.5,0,0,0,0,19c.267,0,.53-.012.791-.033A9.5,9.5,0,0,1,5.387,26.73Zm0,0"
            transform="translate(-3.558 -16.114)"
            fill="#ffe765"
          />
          <path
            id="Path_56006"
            data-name="Path 56006"
            d="M131.988,208.626a.3.3,0,0,1-.3-.3v-1.067a.3.3,0,1,1,.593,0v1.067A.3.3,0,0,1,131.988,208.626Zm0,0"
            transform="translate(-126.534 -198.563)"
            fill="#4b3f4e"
          />
          <path
            id="Path_56007"
            data-name="Path 56007"
            d="M99.471,273.656H98.3a.3.3,0,1,1,0-.593h1.17a.3.3,0,1,1,0,.593Zm0,0"
            transform="translate(-94.14 -262.122)"
            fill="#e56655"
          />
          <path
            id="Path_56008"
            data-name="Path 56008"
            d="M355.531,208.626a.3.3,0,0,1-.3-.3v-1.067a.3.3,0,1,1,.593,0v1.067A.3.3,0,0,1,355.531,208.626Zm0,0"
            transform="translate(-341.493 -198.563)"
            fill="#4b3f4e"
          />
          <path
            id="Path_56009"
            data-name="Path 56009"
            d="M359.923,273.656h-1.17a.3.3,0,1,1,0-.593h1.17a.3.3,0,1,1,0,.593Zm0,0"
            transform="translate(-344.592 -262.122)"
            fill="#e56655"
          />
          <path
            id="Path_56010"
            data-name="Path 56010"
            d="M173.259,287.812q.082-.071.158-.147a3.185,3.185,0,0,0,.936-2.26.675.675,0,0,0-.674-.674h-.591l-1.931.621-1.931-.621h-.591a.674.674,0,0,0-.674.674,3.184,3.184,0,0,0,.936,2.26q.076.077.158.148Zm0,0"
            transform="translate(-161.411 -273.342)"
            fill="#ed3d26"
          />
          <path
            id="Path_56011"
            data-name="Path 56011"
            d="M200.66,344.043a3.195,3.195,0,0,1-4.2,0,3.061,3.061,0,0,1,4.2,0Zm0,0"
            transform="translate(-188.813 -329.573)"
            fill="#e56655"
          />
          <path
            id="Path_56012"
            data-name="Path 56012"
            d="M204.757,284.73v.521a.722.722,0,0,1-.722.721h-2.419a.722.722,0,0,1-.722-.721v-.521Zm0,0"
            transform="translate(-193.08 -273.342)"
            fill="#eceaec"
          />
        </g>
      </g>
    </svg>
  );
}

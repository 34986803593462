import { PercentOfEmployeHealthCheckStyled } from "./styled";
// import { Inputs, Skeleton } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import theme from "styles/theme.json";
// import momentTZ from "moment-timezone";
// import moment from "moment-timezone";
// import { RENDER_MONTH } from "utils/functions/date";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const PercentOfEmployeHealthCheck = ({ data }) => {
  const [_data, _setData] = useState([]);

  useEffect(() => {
    if (data) {
      let dumpData = [
        {
          x: "ดัชนีมวลกาย",
          y: Number(
            (
              (Number(data.bmi_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(Number(data.bmi_abnormal_count).toFixed(0)),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "ความดันโลหิต",
          y: Number(
            (
              (Number(data.bp_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(Number(data.bp_abnormal_count).toFixed(0)),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "น้ำตาลในเลือด",
          y: Number(
            (
              (Number(data.fbs_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(Number(data.fbs_abnormal_count).toFixed(0)),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "ไขมันในเลือด",
          y: Number(
            (
              (Number(data.cholesterol_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(
            Number(data.cholesterol_abnormal_count).toFixed(0)
          ),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "การทำงานของไต",
          y: Number(
            (
              (Number(data.kidney_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(
            Number(data.kidney_abnormal_count).toFixed(0)
          ),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "สมรรถภาพตับ",
          y: Number(
            (
              (Number(data.liver_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(
            Number(data.liver_abnormal_count).toFixed(0)
          ),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
        {
          x: "สารกรดยูริก",
          y: Number(
            (
              (Number(data.uric_abnormal_count) / data.checkup_count) *
              100
            ).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(Number(data.uric_abnormal_count).toFixed(0)),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
        },
      ];

      _setData(dumpData);
    }
  }, [data]);

  const renderHeight = () => {
    let tempHight = 136;
    if (_data && _data.length > 1) {
      tempHight = 79.6 + 56.4 * _data.length;
    }

    return tempHight;
  };

  return (
    <PercentOfEmployeHealthCheckStyled>
      <div className="box_percent_emp_health_check">
        <div className="body_graph_percent_emp_health">
          {/* <div className="right_dep_title_g">
            จำนวนพนักงาน
          </div> */}
          {!data || (data && data.checkup_count === 0) ? (
            <div className="lbl_empty">ไม่มีข้อมูล</div>
          ) : (
            <>
              <div className="top_percent_emp_title_g">ข้อมูลสุขภาพ</div>
              <Chart
                type="bar"
                height={renderHeight()}
                options={{
                  chart: {
                    stacked: true,
                    fontFamily: theme.FONT.STYLE.BOLD,
                    toolbar: {
                      show: false,
                    },
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      var dataToolTip =
                        w.globals.initialSeries[seriesIndex].data[
                          dataPointIndex
                        ];
                      return (
                        '<div class="box_hover">' +
                        '<div class="big_text_s">' +
                        "<div >" +
                        dataToolTip.x +
                        "</div>" +
                        "<div >" +
                        dataToolTip.status +
                        "</div>" +
                        "</div>" +
                        '<div class="stauts_group">' +
                        "<div >" +
                        dataToolTip.y +
                        "%</div>" +
                        "<div > (" +
                        dataToolTip.amount +
                        " คน จาก</div>" +
                        "<div >" +
                        dataToolTip.totalAmount +
                        " คน)</div>" +
                        "</div>" +
                        "</div>"
                      );
                    },
                  },
                  grid: {
                    xaxis: {
                      lines: {
                        show: true,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                  },
                  // responsive: [
                  //   {
                  //     breakpoint: 480,
                  //     options: {
                  //       legend: {
                  //         position: "bottom",
                  //         offsetX: -10,
                  //         offsetY: 0,
                  //       },
                  //     },
                  //   },
                  //   {
                  //     breakpoint: 780,
                  //     options: {
                  //       xaxis: {
                  //         labels: {
                  //           rotate: -80,
                  //         }
                  //       },
                  //     }
                  //   },
                  //   {
                  //     breakpoint: 1200,
                  //     options: {
                  //       plotOptions: {
                  //         bar: {
                  //           columnWidth: "40%",
                  //         },
                  //       },
                  //     }
                  //   }
                  // ],
                  dataLabels: {
                    enabled: false,
                    style: {
                      // colors: theme.COLORS.WHITE_1,
                      fontSize: "14px",
                      fontFamily: theme.FONT.STYLE.REGULAR,
                      fontWeight: 400,
                    },
                  },
                  xaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    categories: tempCate,
                    labels: {
                      formatter: function (value) {
                        return `${value}%`;
                      },
                      style: {
                        fontSize: "16px",
                        colors: theme.COLORS.GRAY_23,
                        fontFamily: theme.FONT.STYLE.BOLD,
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                    axisTicks: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                      // color: theme.COLORS.PURPLE_7,
                      // height: 3,
                    },
                  },
                  yaxis: {
                    // axisBorder: {
                    //   show: true,
                    //   color: theme.COLORS.PURPLE_7,
                    //   width: 3,
                    // },
                    labels: {
                      // rotate: -16,
                      // formatter: function (value, timestamp, opts) {
                      //   return parse(value)
                      // }
                      hideOverlappingLabels: false,
                      style: {
                        colors: theme.COLORS.BLACK_11,
                        fontSize: "12px",
                        fontFamily: theme.FONT.STYLE.REGULAR,
                      },
                    },
                  },
                  colors: ["#EC5E49"],
                  fill: {
                    opacity: 1,
                  },

                  plotOptions: {
                    bar: {
                      horizontal: true,
                      columnWidth: "45%",
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "Series 1",
                    data: _data,
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </PercentOfEmployeHealthCheckStyled>
  );
};

const tempCate = [
  "ดัชนีมวลกาย",
  "ความดันโลหิต",
  "น้ำตาลในเลือด",
  "ไขมันในเลือด",
  "การทำงานของไต",
  "สมรรถภาพตับ",
  "สารกรดยูริก",
];

export default PercentOfEmployeHealthCheck;

import styled from 'styled-components';

export const TableUserRankGroupStyled = styled.div`
  .theme_standard_table_rank_group {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    // min-width: ${({ isEmpty }) => (isEmpty ? 'auto' : '1000px')};
    .label_empty {
      padding: 40px 0;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
    .title_layout {
      padding: 19.6px 24px;
      background: ${({ theme }) => theme.COLORS.BLUE_2};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      font-size: ${({ theme }) => theme.FONT.SIZE.S30};
      border-radius: 4px;
    }
    .header {
      display: flex;
      // padding: 0px 30px;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      color: ${({ theme }) => theme.COLORS.BLACK_11};
      border-left: 8px solid ${({ theme }) => theme.COLORS.WHITE_1};
      .h1 {
        margin: 0px 5px;
        width: 142px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        padding: 14.8px 0px;
      }
      .h2 {
        margin: 0px 40px;
        width: 49px;
        display: flex;
        flex-shrink: 0;
        padding: 14.8px 0px;
      }
      .h3 {
        margin: 0px 5px;
        padding: 14.8px 0px;
        width: 25%;
        // text-align: center;
      }
      .h4 {
        margin: 0px 5px;
        padding: 14.8px 0px;
        width: 21.1%;
        // text-align: center;
      }
      .h5 {
        margin: 0px 5px;
        padding: 14.8px 0px;
        width: 19.1%;
        // text-align: center;
        // justify-content: center;
      }
    }
    .group_row {
      padding: 15px 0px;
      // border: 1px solid #b1b1c1;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .row {
        display: flex;
        border-left: 8px solid #f4f4f4;
        // margin-bottom: 30px;
        // border-bottom: 1px solid #b1b1c1;
        // padding-bottom: 10px;
        padding: 19px 0px;
        margin-top: 16px;
        background: ${({ theme }) => theme.COLORS.GRAY_32}99;
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          border-bottom: none;
        }
        .left_row {
          display: flex;
          flex-shrink: 0;
          .round {
            width: 142px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${({ theme }) => theme.FONT.SIZE.S32};
          }
          // .round {
          //   height: 50px;
          //   width: 50px;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   font-size: ${({ theme }) => theme.FONT.SIZE.S32};
          // }
        }
        .right_row {
          width: calc(100% - 43px);
          display: flex;
          align-items: center;
          .col1 {
            width: 43px;
            height: 43px;
            border-radius: 50%;
            display: flex;
            flex-shrink: 0;
            margin: 0px 40px;
            background: #b1b1c130;
            object-fit: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .col2 {
            width: calc(30.5% - 36px);
            display: flex;
            flex-shrink: 0;
            margin: 0px 5px;
            // text-align: center;
            padding-left: 15px;
          }
          .col3 {
            width: calc(24.5% - 11px);
            display: flex;
            flex-shrink: 0;
            // justify-content: center;
            margin: 0px 5px;
            // text-align: center;
          }
          .col4 {
            width: calc(23.8% - 49px);
            display: flex;
            flex-shrink: 0;
            margin: 0px 5px;
            // text-align: center;
            // justify-content: center;
          }
        }
        &:last-child {
          margin-bottom: 0px;
          .right_row {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  .theme_standard_table_rank_group_header_pink {
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    // min-width: ${({ isEmpty }) => (isEmpty ? 'auto' : '1000px')};
    .label_empty {
      padding: 40px 0;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
    .title_layout {
      padding: 9px 24px;
      background: #FF423E;
      color: #ffffff;
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .header {
      display: flex;
      padding: 0px 30px;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: #FFFFFF;
      background: #FF423E;
      border-radius: 8px;
      .h1 {
        margin: 0px 5px;
        width: 41px;
        display: flex;
        flex-shrink: 0;
        padding: 11px 0px;
      }
      .h2 {
        margin: 0px 40px;
        width: 49px;
        display: flex;
        flex-shrink: 0;
        padding: 11px 0px;
      }
      .h3 {
        margin: 0px 5px;
        padding: 11px 0px;
        width: 25%;
        text-align: center;
      }
      .h4 {
        margin: 0px 5px;
        padding: 11px 0px;
        width: 21.1%;
        text-align: center;
      }
      .h5 {
        margin: 0px 5px;
        padding: 11px 0px;
        width: 19.1%;
        text-align: center;
        justify-content: center;
      }
      .h6 {
        margin: 0px 5px;
        padding: 11px 0px;
        width: 17.6%;
        text-align: center;
        justify-content: center;
      }
    }
    .group_row {
      padding: 30px;
      border: 1px solid #b1b1c1;
      border-top: none;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      .row {
        display: flex;
        margin-bottom: 30px;
        border-bottom: 1px solid #b1b1c1;
        padding-bottom: 10px;
        &:last-child {
          border-bottom: none;
        }
        .left_row {
          display: flex;
          flex-shrink: 0;
          .round {
            height: 49px;
            width: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right_row {
          width: calc(100% - 49px);
          display: flex;
          align-items: center;
          .col1 {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            flex-shrink: 0;
            margin: 0px 40px;
            background: #b1b1c130;
            object-fit: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .col2 {
            width: calc(33% - 49px);
            display: flex;
            flex-shrink: 0;
            margin: 0px 5px;
            text-align: center;
          }
          .col3 {
            width: calc(26% - 49px);
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            margin: 0px 5px;
            text-align: center;
          }
          .col4 {
            width: calc(23.8% - 49px);
            display: flex;
            flex-shrink: 0;
            margin: 0px 5px;
            text-align: center;
            justify-content: center;
          }
        }
        &:last-child {
          margin-bottom: 0px;
          .right_row {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
      }
    }
    .pagi_layout {
      margin-top: ${({ marginTopPagination }) =>
        marginTopPagination ? marginTopPagination : '32px'};
    }
  }
`;

import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UploadImageStyled } from "./styled";
import { Icons, Buttons } from "components";
import { useDropzone } from "react-dropzone";

const UploadImage = ({
  theme_standard_upload_image,
  acceptType = "cover",
  onChange,
  value,
  maxMBFileSizeWarning,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_upload_image: theme_standard_upload_image,
  });

  const [files, setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        // return "image/*";
        return ".png , .jpg , .jpeg";
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return "video/*";
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
        }
      }
      let fileTemp = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  // const onClickRemove = (index, data) => {
  //   if (data.isNewUpload) {
  //     let filesTemp = files;
  //     filesTemp.splice(index, 1);
  //     setFiles([...filesTemp]);
  //   } else {
  //     let filesTemp = files;
  //     filesTemp[index].isDelete = true;
  //     setFiles([...filesTemp]);
  //   }
  // };

  return (
    <UploadImageStyled>
      <div className={customClass}>
        <div className="title_upload">
          <div className="show_svg">
            <Icons.Camera />
          </div>
          <div className="group_label">
            <div className="label_1">อัพโหลดรูปภาพ</div>
            <div className="label_2">Upload Photo</div>
          </div>
        </div>
        <div
          className={`body_upload ${
            files && files.length > 0 ? "no_border" : ""
          } `}
        >
          {files && files.length > 0 ? (
            files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  className="show_img"
                  key={i}
                  style={{ backgroundImage: `url(${e.preview})` }}
                  alt="upload reward"
                />
              ) : (
                !e.isDelete && (
                  <div
                    className="show_img"
                    key={i}
                    style={{ backgroundImage: `url(${e.image_uri})` }}
                    alt="upload reward"
                  />
                )
              )
            )
          ) : (
            <Icons.Upload />
          )}
        </div>
        <div {...getRootProps({ className: "btn_upload" })}>
          <input {...getInputProps()} disabled={disabled} />
          <Buttons.BtnNormal
            theme_standard_btn_round_red
            label="เพิ่มรูป"
            labelBottom="Add Photo"
            marginTopBottomLabel="11px"
            disabled={disabled}
          />
        </div>
      </div>
    </UploadImageStyled>
  );
};

UploadImage.propTypes = {};

export default UploadImage;

import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UploadSetImageMaxLengthStyled } from "./styled";
import { Icons, Buttons } from "components";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

const UploadSetImageMaxLength = ({
  theme_upload_set_image,
  useOnchangeRemoveEvent = false,
  heightBox,
  acceptType,
  labelTop = "เพิ่มรูปภาพ",
  labelBottom = "Document File Location",
  iconWidth = "60",
  iconHeight = "60",
  useShowMore = false,
  value,
  showCopyLink,
  onChangeCountFile,
  maxLength,
  disabled,
  desc_text,
  desc_text2,
  onChange,
  fontSizeLabelTop,
  fontSizeLabelBottom,
  fontFamilyLabelTop,
  fontFamilyLabelBottom,
  maxMBFileSizeWarning,
  widthImageSize = 1024,
  heighImageSize = 1024,
  name,
  width,
  onChangeCustom,
  apiGenerateLink,
  handleRemove,
}) => {
  const customClass = cx({
    theme_upload_set_image: theme_upload_set_image,
  });
  const [files, setFiles] = useState([]);
  const [initial, setInitial] = useState(true);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);
  const [_countFile, _setCountFile] = useState(0);
  const [_isShowCopy, _setIsShowCopy] = useState(false);
  const [_imageId, _setImageId] = useState();

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        return "image/*";
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return "video/*";
      default:
        break;
    }
  };

  const upLoadGenerateLink = async (e) => {
    const response = await apiGenerateLink(e);

    setFiles([...files, response?.data[0]]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: async (acceptedFiles) => {
      let canUpload = false;
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(6)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          // alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
          toast.error("ไฟล์มีขนาดใหญ่เกินขนาดสูง ไม่สามารถอัพโหลดได้");
        } else {
          canUpload = true;
        }
      } else {
        canUpload = true;
      }

      if (canUpload) {
        const imageResize = await resizeFile(acceptedFiles);
        let fileTemp = [];
        // fileTemp = [
        //   ...files,
        //   ...imageResize.map((file) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //       isNewUpload: true,
        //       fieldName: name,
        //     })
        //   ),
        // ];

        fileTemp = [
          ...files,
          Object.assign(imageResize[0], {
            preview: URL.createObjectURL(imageResize[0]),
            isNewUpload: true,
            fieldName: name,
          }),
        ];

        upLoadGenerateLink(fileTemp?.[fileTemp.length - 1]);
        setIsShowLoadMore(false);
        setInitial(false);
        setFiles(fileTemp);
        onChange && onChange(fileTemp);
        onChangeCustom && onChangeCustom(fileTemp);
      }
    },
  });

  const resizeFile = (files) => {
    return Promise.all(
      files.map((e) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            e,
            widthImageSize,
            heighImageSize,
            "jpeg",
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            "file"
          );
        });
      })
    ).then((e) => {
      return e;
    });
  };

  useEffect(() => {
    if (value && value.length > 0) {
      let countFile = value.filter((e) => !e.isDelete);
      _setCountFile(countFile.length);
      onChangeCountFile && onChangeCountFile(countFile.length);
    }
    if (value && value.length > 0 && initial) {
      setFiles(value);

      if (value.length > 3 && useShowMore) {
        setIsShowLoadMore(true);
        setInitial(false);
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickRemove = async (index, data) => {
    let countFileNum = 0;
    if (data.isNewUpload) {
      let filesTemp = files;
      countFileNum = _countFile - 1;
      filesTemp.splice(index, 1);
      setFiles([...filesTemp]);
      _setCountFile(countFileNum);
      onChangeCountFile && onChangeCountFile(countFileNum);
      // ใช้กรณีอยากให้กดลบแล้วคืนค่าออกไป
      if (useOnchangeRemoveEvent) {
        onChange && onChange([...filesTemp]);
      }
    } else {
      let filesTemp = files;
      countFileNum = _countFile - 1;
      filesTemp[index].isDelete = true;
      setFiles([...filesTemp]);
      _setCountFile(countFileNum);

      onChangeCountFile && onChangeCountFile(countFileNum);
      handleRemove && handleRemove(data);
    }
  };

  const handleClickMore = () => {
    setIsShowLoadMore(false);
  };

  const renderShowInput = () => {
    let tempShow = true;
    if (maxLength) {
      if (_countFile < maxLength) {
        tempShow = true;
      } else {
        tempShow = false;
      }
    } else {
      tempShow = true;
    }

    if (tempShow) {
      return (
        <div className="body_box_container">
          <div {...getRootProps({ className: "box_upload" })}>
            {!disabled && <input {...getInputProps()} />}
            <div className="show_svg">
              <Icons.AddRound width={iconWidth} height={iconHeight} />
            </div>
            <div className="g_label_img">
              <div className="h1">{labelTop}</div>
              <div className="sub">{labelBottom}</div>
            </div>
          </div>
          {!disabled && desc_text && (
            <div className="desc_txt">
              {desc_text && <div>{desc_text}</div>}
              {desc_text2 && <div>{desc_text2}</div>}
            </div>
          )}
        </div>
      );
    }
  };

  const _handleCopy = async (e) => {
    _setImageId(e.id);
    navigator.clipboard.writeText(e?.image_uri);
    _setIsShowCopy(true);
    setTimeout(() => {
      _setIsShowCopy(false);
    }, 1000);
  };

  return (
    <UploadSetImageMaxLengthStyled
      heightBox={heightBox}
      width={width}
      fontSizeLabelTop={fontSizeLabelTop}
      fontSizeLabelBottom={fontSizeLabelBottom}
      fontFamilyLabelTop={fontFamilyLabelTop}
      fontFamilyLabelBottom={fontFamilyLabelBottom}
    >
      <div className={customClass}>
        {files && files.length > 0 && !isShowLoadMore
          ? files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i + 1}
                  className="show_img"
                  style={{ backgroundImage: `url(${e.preview})` }}
                >
                  {!disabled && (
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX />
                    </div>
                  )}
                  {showCopyLink && (
                    <div className="copy_icon" onClick={() => _handleCopy(e)}>
                      <Icons.CopyLink />
                    </div>
                  )}

                  {_isShowCopy && e.id === _imageId && (
                    <div className="copy_link">คัดลอกลิงค์สำเร็จ</div>
                  )}

                  {isShowLoadMore && i === 2 && (
                    <div className="block_more" onClick={handleClickMore}>
                      <div className="num_show">+ 5</div>
                    </div>
                  )}
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    key={i + 1}
                    className="show_img"
                    style={{ backgroundImage: `url(${e.image_uri})` }}
                  >
                    {!disabled && (
                      <div
                        className="close"
                        onClick={() => onClickRemove(i, e)}
                      >
                        <Icons.CloseX />
                      </div>
                    )}
                    {showCopyLink && (
                      <div className="copy_icon" onClick={() => _handleCopy(e)}>
                        <Icons.CopyLink />
                      </div>
                    )}
                    {_isShowCopy && e.id === _imageId && (
                      <div className="copy_link">คัดลอกลิงค์สำเร็จ</div>
                    )}
                    {isShowLoadMore && i === 2 && (
                      <div className="block_more" onClick={handleClickMore}>
                        <div className="num_show">+ 5</div>
                      </div>
                    )}
                  </div>
                )
              )
            )
          : files.slice(0, 3).map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i + 1}
                  className="show_img"
                  style={{ backgroundImage: `url(${e.preview})` }}
                >
                  {!disabled && (
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX />
                    </div>
                  )}
                  <div className="block_more" onClick={handleClickMore}>
                    <div className="num_show">+ 5</div>
                  </div>
                  {isShowLoadMore && i === 2 && (
                    <div className="block_more" onClick={handleClickMore}>
                      <div className="num_show">+ 5</div>
                    </div>
                  )}
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    key={i + 1}
                    className="show_img"
                    style={{ backgroundImage: `url(${e.image_uri})` }}
                  >
                    {!disabled && (
                      <div
                        className="close"
                        onClick={() => onClickRemove(i, e)}
                      >
                        <Icons.CloseX />
                      </div>
                    )}
                    {isShowLoadMore && i === 2 && (
                      <div className="block_more" onClick={handleClickMore}>
                        <div className="num_show">+ {files.length}</div>
                      </div>
                    )}
                  </div>
                )
              )
            )}
        {renderShowInput()}
        {isShowLoadMore && (
          <div className="btn_show">
            <Buttons.BtnShadow
              theme_btn_shadow_white
              label="แสดงรูปภาพทั้งหมด"
              svg={<Icons.ArrowDropdown width="9.74" height="16" />}
              fontSize="20px"
              onClick={handleClickMore}
            />
          </div>
        )}
      </div>
    </UploadSetImageMaxLengthStyled>
  );
};

UploadSetImageMaxLength.propTypes = {};

// const data = [
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
//   {
//     url: '/assets/images/backgrounds/corona.png',
//   },
// ];

export default UploadSetImageMaxLength;

import styled from 'styled-components';

export const AddEditMissionContainerStyled = styled.div`
  min-width: 1024px;
  overflow: auto;
  padding-left: 5px;
  margin-left: -5px;
  margin-right: -20px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 18px;
  .show_loading {
    height: calc(100vh - 85px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add_mission_container {
    margin-right: 20px;
    .layer_one {
      display: flex;
      padding-bottom: 10px;
    }
    .layer_two {
      margin-top: 7px;
    }
  }
`;

import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BoxLabelLeftRightStyled } from './styled';

const BoxLabelLeftRight = ({
  theme_standard_box_label_left_right,
  theme_standard_box_4_col,
  label1,
  label2,
  label3,
  svg1,
  svg2,
}) => {
  const customClass = cx({
    theme_standard_box_label_left_right: theme_standard_box_label_left_right,
    theme_standard_box_4_col: theme_standard_box_4_col,
  });
  return (
    <BoxLabelLeftRightStyled>
      <div className={customClass}>
        <div className="left_layout">
          <div>{label1}</div>
          <div className="bottom_label">{label2}</div>
        </div>
        <div className="right_layout">
          {svg1 && <div className="svg_show_f">{svg1}</div>}
          <div>{label3}</div>
          {svg2 && <div className="svg_show_b">{svg2}</div>}
        </div>
      </div>
    </BoxLabelLeftRightStyled>
  );
};

BoxLabelLeftRight.propTypes = {};

export default BoxLabelLeftRight;

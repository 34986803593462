import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BoxDeleteStyled } from "./styled";
import { Buttons, Icons } from "components";

const BoxDelete = ({
  theme_box_delete,
  handleConfirm,
  handleCancel,
  textShow,
}) => {
  const customClass = cx({
    theme_box_delete: theme_box_delete,
  });
  return (
    <BoxDeleteStyled>
      <div className={customClass}>
        <div className="delete_box">
          <div className="title_box">
            <div>{textShow ? textShow : "ยืนยันการลบหรือไม่"}</div>
            <div className="icon">
              <Icons.Delete width="21.84px" height="28px" />
            </div>
          </div>
          <div className="action_btn">
            <div className="body_btn margin_right">
              <Buttons.BtnNormal
                theme_standard_btn_pop_up
                label="ยืนยัน"
                fontSize="16px"
                backgroundColor="#0B408D"
                onClick={handleConfirm}
              />
            </div>
            <div className="body_btn">
              <Buttons.BtnNormal
                theme_standard_btn_pop_up
                label="ยกเลิก"
                fontSize="16px"
                backgroundColor="#B1B1C1"
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </BoxDeleteStyled>
  );
};

BoxDelete.propTypes = {};

export default BoxDelete;

import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InputDropDownNormalStyled } from "./styled";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import { Icons } from 'components';

const InputDropDownNormal = ({
  theme_standard_drop_down_normal,
  theme_mini_drop_down_normal,
  theme_standard_drop_down_account,
  theme_standard_drop_down_create_account,
  theme_border_purple,
  theme_lucky,
  theme_content,
  theme_40h,
  label,
  labelBottom,
  iconSvg,
  iconSvgClose,
  iconSvgOpen,
  errors,
  placeholder,
  onChangeCustom,
  onChange,
  disabled,
  ...props
}) => {
  const customClass = cx({
    theme_standard_drop_down_normal: theme_standard_drop_down_normal,
    theme_mini_drop_down_normal: theme_mini_drop_down_normal,
    theme_standard_drop_down_account: theme_standard_drop_down_account,
    theme_standard_drop_down_create_account:
      theme_standard_drop_down_create_account,
    theme_border_purple: theme_border_purple,
    theme_content: theme_content,
    theme_40h: theme_40h,
    theme_lucky: theme_lucky,
  });
  return (
    <InputDropDownNormalStyled disabled={disabled}>
      <div className={customClass}>
        {label && (
          <div className="label">
            <div>{label}</div>
            {labelBottom && <div className="bottom_label">{labelBottom}</div>}
          </div>
        )}
        <div className="input_container">
          <Dropdown
            className="input_dropdown"
            controlClassName="input_dropdown_control"
            placeholderClassName="input_dropdown_placeholder"
            menuClassName="input_dropdown_menu"
            options={options}
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              onChangeCustom && onChangeCustom(e);
            }}
            {...props}
            arrowClosed={
              <span className="arrow_icon arrow-closed">{iconSvgClose}</span>
            }
            arrowOpen={
              <span className="arrow_icon arrow-open">{iconSvgOpen}</span>
            }
            placeholder={placeholder}
          />
        </div>
        {errors && <div className="error">{errors}</div>}
      </div>
    </InputDropDownNormalStyled>
  );
};

const options = [
  { value: "one", label: "One" },
  { value: "two", label: "Two", className: "myOptionClassName" },
  {
    type: "group",
    name: "group1",
    items: [
      { value: "three", label: "Three", className: "myOptionClassName" },
      { value: "four", label: "Four" },
    ],
  },
  {
    type: "group",
    name: "group2",
    items: [
      { value: "five", label: "Five" },
      { value: "six", label: "Six" },
    ],
  },
];

InputDropDownNormal.propTypes = {};

export default InputDropDownNormal;

export default function MenuCook({ isActive, color = '#fff' }) {
  return isActive ? (
    <svg
      id="chef_2_"
      data-name="chef (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="22.35"
      height="21.02"
      viewBox="0 0 22.35 21.02"
    >
      <g
        id="Group_14008"
        data-name="Group 14008"
        transform="translate(3.941 18.389)"
      >
        <g id="Group_14007" data-name="Group 14007">
          <rect
            id="Rectangle_17999"
            data-name="Rectangle 17999"
            width="14.471"
            height="2.631"
            fill="#fff"
          />
        </g>
      </g>
      <g id="Group_14010" data-name="Group 14010" transform="translate(0 0)">
        <g id="Group_14009" data-name="Group 14009">
          <path
            id="Path_19114"
            data-name="Path 19114"
            d="M17.17,17.793a5.169,5.169,0,0,0-1.384.187,5.182,5.182,0,0,0-9.169.015A5.181,5.181,0,1,0,3.941,28V32.3H18.412V28A5.18,5.18,0,0,0,17.17,17.793Z"
            transform="translate(0 -15.22)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      id="chef_1_"
      data-name="chef (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="22.35"
      height="21.021"
      viewBox="0 0 22.35 21.021"
    >
      <g id="Group_14011" data-name="Group 14011" transform="translate(0 0)">
        <path
          id="Path_19115"
          data-name="Path 19115"
          d="M17.17,17.791a5.17,5.17,0,0,0-1.384.187,5.182,5.182,0,0,0-9.169.015A5.181,5.181,0,1,0,3.941,28v8.239H18.412V28A5.18,5.18,0,0,0,17.17,17.791ZM17.1,34.927H5.253v-1.32H17.1Zm.572-8.122-.572.074V32.3H5.253V26.88l-.572-.074a3.868,3.868,0,1,1,2.027-7.39l.633.273.241-.646a3.869,3.869,0,0,1,7.24-.019l.24.633.625-.26a3.868,3.868,0,1,1,1.985,7.409Z"
          transform="translate(0 -15.218)"
        />
      </g>
    </svg>
  );
}

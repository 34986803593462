import React from "react";
import { connect } from "react-redux";
import { LuckSpinContainerStyled } from "./styled";
import { HeadContent } from "widgets";
import { ROUTE_PATH } from "utils/constants";
import {
  Tabs,
} from "components";
// import { adminService } from "apiServices";
// import { toast } from "react-toastify";
import { SpinGameContainer, RewardManagementContainer, AnnouncementContainer } from '../LuckSpinContainer/Menus'
import queryString from 'query-string';

class LuckSpinContainer extends React.Component {
  state = {};

  componentDidMount() {
    const { tabIndex } = queryString.parse(this.props.location.search);
    
    this.setState({
      tabIndex: tabIndex === 'reward' ? 1 : tabIndex === 'announcement' ? 2 : 0,
    })
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE);
  };

  render() {
    const { tabIndex } = this.state

    return (
      <LuckSpinContainerStyled>
        <div className="manage_container">
          <div className="head_wrap_layout">
            <HeadContent
              onClick={this.handleBackClick}
              headerLabel="วงล้อมหาสนุก"
              title_name='Lucky Spin Management'
            />
          </div>
          <div className="bottom_control">
            <Tabs.TabBarSelect
              tab_normal
              initialValue={tabIndex}
              tabHeader={headerTab({
                history: this.props.history
              })}
            />
          </div>
        </div>
      </LuckSpinContainerStyled>
    );
  }
}

const headerTab = ({ history }) => [
  {
    label: 'จัดการวงล้อ',
    labelBottom: 'Spin Game Management',
    component: <SpinGameContainer history={history} />,
  },
  {
    label: 'จัดการรางวัล',
    labelBottom: 'Reward Management',
    component: <RewardManagementContainer history={history} />,
  },
  {
    label: 'รายชื่อผู้รับรางวัล',
    labelBottom: 'Announcement',
    component: <AnnouncementContainer />,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LuckSpinContainer);

import styled from 'styled-components';

export const GenderStyled = styled.div`
  .top_current {
  }
  .bottom_current {
    display: flex;
    margin-top: 30px;
    .left_layout {
      width: 25%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      .show_label_l {
        margin-top: 4px;
      }
    }
    .mid_layout {
      width: 50%;
      padding-top: 4.5%;
    }
    .right_layout {
      width: 25%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      .show_label_r {
        margin-top: 4px;
      }
    }
  }
`;

export default function Runner() {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="328" height="85.131" viewBox="0 0 328 85.131">
      <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
        <path id="Path_35147" data-name="Path 35147" d="M166.589,262C76.269,262,3.05,263.313,3.05,264.932s73.219,2.929,163.539,2.929,163.539-1.322,163.539-2.926S256.924,262,166.589,262Z" transform="translate(-3.05 -182.729)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35148" data-name="Path 35148" d="M950.9,57.627s1.543-.572,6.163.974a.315.315,0,0,0,0,.088c.605.433.711.3.908.148s-.218-.436-.605-.463a.3.3,0,0,0-.233.054c-4.7-1.573-6.3-.98-6.305-.977l.064.175Z" transform="translate(-917.696 -39.991)" fill="#bababa" />
        <path id="Path_35149" data-name="Path 35149" d="M928.834,53.54l-1.129,2.493s.717.333.959.2a2.891,2.891,0,0,0,.505-2.118c0-.145-.336-.56-.336-.56Z" transform="translate(-881.911 -37.34)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35150" data-name="Path 35150" d="M931.073,56.766l.853-1.57a1.844,1.844,0,0,1,.094-1.21c.287-.43,1.292-.681,1.57-1s.7-1.334.908-1.431.384.136.384.136-.384,1-.254,1.331-.3.112.221,1.089-.605,2.145-1.144,2.227-.675.3-.675.3l-.908,2.048-1.047-1.936Z" transform="translate(-888.688 -35.94)" fill="#e8a597" fillRule="evenodd" />
        <path id="Path_35151" data-name="Path 35151" d="M938.249,66.339a.492.492,0,0,0-.209.172h0l-.36.811a5.815,5.815,0,0,1-.817-1.513c-.079-.372.2.079.381.251a2.293,2.293,0,0,0,1,.281Z" transform="translate(-893.7 -45.798)" fill="#d68f80" fillRule="evenodd" />
        <path id="Path_35152" data-name="Path 35152" d="M927.92,44.35l-2.88,6.3.484.182,2.893-6.239-.5-.245Z" transform="translate(-880.294 -30.931)" fill="#f0f0f0" fillRule="evenodd" />
        <path id="Path_35153" data-name="Path 35153" d="M929.709,44.35l-.457.133-2.572,5.8.148.369,2.88-6.3Z" transform="translate(-882.083 -30.931)" fill="#4a4485" fillRule="evenodd" />
        <path id="Path_35154" data-name="Path 35154" d="M928.787,56.975c-.654.079-.908.284-.886.529s.221.478.408.433.929-.16.992-.048c.384.675,1.113-1.116-.514-.908Z" transform="translate(-882.544 -39.728)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35155" data-name="Path 35155" d="M928.588,53.755a.878.878,0,0,0-1.068-.064c-.121.091-.538.5-.227.708s.908-.254,1.089.148C928.781,55.385,928.951,54.127,928.588,53.755Z" transform="translate(-881.313 -37.34)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35156" data-name="Path 35156" d="M931,60.515c-.935-.908-3.128,1.156-1.54.944.575-.076,1.147-.421.935-.16s-1.234,1.008-1.21,1.162C929.261,62.8,932.078,61.589,931,60.515Z" transform="translate(-884.292 -42.044)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35157" data-name="Path 35157" d="M992.529,32a2.42,2.42,0,0,0-1.044-.215c-.336-.091-.977-.085-1.64.838-.853,1.189-1.576,1.177-1.576,1.177v.26s.856,0,1.788-1.286a2.379,2.379,0,0,1,.475-.508c-.505.632-.811,1.8-1.782,2.07-1.147.321-.584,3.749,1.888,3.631s3.047-4.085,3.047-4.085-1.21.1-1.156-1.894Z" transform="translate(-964.666 -22.141)" fill="#c96a16" fillRule="evenodd" />
        <path id="Path_35158" data-name="Path 35158" d="M987.469,31.914s-.227.526.336,1.513a2.745,2.745,0,0,0,1.1,1.21c.206-.057.454-.3.378-.835-.127-.886-.826-2.4-1.815-1.894Z" transform="translate(-960.054 -22.183)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35159" data-name="Path 35159" d="M993.711,121.113a79.782,79.782,0,0,1-6.889,12.178c-4.26,6.127-6.911,8.139-8.423,10.027a56.792,56.792,0,0,0-6.393,8.7c-2.157,3.9-4.275,7.113-4.275,7.113l-2.29-3.8a34.664,34.664,0,0,0,3.585-6.233c2.115-4.417,1.168-3,5.1-8.227a60.362,60.362,0,0,0,6.354-10.862c.781-1.434,3.026-9.062,3.026-9.062l1.34-2.95,8.868,3.122Z" transform="translate(-957.812 -82.298)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35160" data-name="Path 35160" d="M939.206,119.369a7.482,7.482,0,0,0-3.833,6.127c-.13,4.245,15.551,13.243,15.812,13.458s-.693,1.8-1.513,6.856-.992,9.47-1.728,11.44.215,2.118.215,2.118l4.538.191s-1.295-2.787,0-7.156,5.013-12.986,4.408-15.128c-.572-2.024-6.8-8.305-10.456-12.1l1.428-3.119a31.794,31.794,0,0,0-5.721-.753,19.741,19.741,0,0,0-3.147-1.939Z" transform="translate(-912.175 -83.246)" fill="#4a4485" fillRule="evenodd" />
        <path id="Path_35161" data-name="Path 35161" d="M968.6,119.383a7.867,7.867,0,0,0-3.156,3.328,87.973,87.973,0,0,1,11.966-.511l.057-.127a31.8,31.8,0,0,0-5.721-.753,19.741,19.741,0,0,0-3.147-1.939Z" transform="translate(-941.565 -83.26)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35162" data-name="Path 35162" d="M936.922,72.118A20.9,20.9,0,0,0,944.347,66a5.212,5.212,0,0,1,2.42,2.378s-3.2,4.46-5.936,6.4-2.8,2.85-2.8,2.85l-1.126-5.5Z" transform="translate(-902.237 -46.031)" fill="#de988a" fillRule="evenodd" />
        <path id="Path_35163" data-name="Path 35163" d="M954.45,79.246a25.352,25.352,0,0,0,4.538-3.026,9.887,9.887,0,0,1-.357,2.354,16.5,16.5,0,0,0-.554,3.54c-2.448,1.794-2.5,2.638-2.5,2.638l-1.126-5.5Z" transform="translate(-919.764 -53.158)" fill="#cf887a" fillRule="evenodd" />
        <path id="Path_35164" data-name="Path 35164" d="M973.783,15.147s.079-.962-1.476-1.561c-1.5-.578-3.673-.808-5.029.95-2.154,1.749-1.891,3.219-1.688,3.755a3.135,3.135,0,0,0,.605,2.172c1.543,2.118,3.225,1.1,5.658-.042.684-.324-.069-3.328,1.93-5.274Zm-7.116.3a8.079,8.079,0,0,0-.953,2.118A3.171,3.171,0,0,1,966.667,15.45Z" transform="translate(-937.891 -9.18)" fill="#c96a16" fillRule="evenodd" />
        <path id="Path_35165" data-name="Path 35165" d="M973.72,38.531s-.886.169-1.319,1.755c-.091.342-.157.632-.2.88a8.46,8.46,0,0,1,.959,1.116,12.014,12.014,0,0,1,.566,1.077l-2.248-1.268-3.715-1.634A46.752,46.752,0,0,0,970.6,35.75S972.271,38.08,973.72,38.531Z" transform="translate(-938.527 -24.933)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35166" data-name="Path 35166" d="M971.367,38.465a1.162,1.162,0,0,0-.212.1,1.66,1.66,0,0,0-.533.5v.021h0l-.033.048h0v.024h0v.024h0V39.2h0v.015l-.015.027h0v.045h0l-.021.045v.033h0v.067l-.045.121v.015a.457.457,0,0,1-.039.112v.236c-.687-.369-1.589-.938-2.469-3.213a10.748,10.748,0,0,0,.554-1.038V35.63a15.261,15.261,0,0,0,2.421,2.151h0l.042.027a4.355,4.355,0,0,0,.351.657Z" transform="translate(-936.243 -24.849)" fill="#e8a597" fillRule="evenodd" />
        <path id="Path_35167" data-name="Path 35167" d="M967.976,59s-.39.281-.121.584L968,59.46c-.133-.148.088-.3.091-.3l-.112-.151Z" transform="translate(-932.891 -41.149)" fill="#ccc" />
        <path id="Path_35168" data-name="Path 35168" d="M962.612,19.692a2.6,2.6,0,0,1,.454.5c.714,1.059.281,1.906.66,2.88s1.416,1.265,1.165,1.564a1.929,1.929,0,0,1-.744.472c-.466,2.79-.8,3.328-3.933,2.169-1.712-.632-1.395-1.782-1.395-1.782a1.54,1.54,0,0,1-.971-1.113c-.172-.859.182-1.159.626-1.174,1.21-.045,1.21,1.331,1.716,1.513.227.088.121-1.316.2-1.776s-.021-1.1.777-1.48c.254-.121.115-1.1,1.434-1.785Z" transform="translate(-926.719 -13.725)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35169" data-name="Path 35169" d="M964.722,29.4a.378.378,0,0,0-.478.23.372.372,0,0,0,.233.472.378.378,0,0,0,.478-.23.372.372,0,0,0-.233-.472Z" transform="translate(-928.709 -20.492)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35170" data-name="Path 35170" d="M964.077,27.419a1.34,1.34,0,0,0-1.567-.251l.157.287a1,1,0,0,1,1.171.188Z" transform="translate(-927.292 -18.844)" fill="#c96a16" fillRule="evenodd" />
        <path id="Path_35171" data-name="Path 35171" d="M966.707,51.835s2.865,3.225,3.933,4.7.433,3.449,0,5.679-.278,6.514-.523,8.7-.56,4.445-.56,4.445c-1.48-.339-11.5-1.389-11.824-2.381,0,0,1.513-4.762,1.31-7.261a56.088,56.088,0,0,0-.95-6.384c-.517-2.935-.563-3.933.454-5.228a15.758,15.758,0,0,1,3.67-2.774l3.876,1.691-.03.127,2.39,1.21a12.431,12.431,0,0,0-1.752-2.442v-.091Z" transform="translate(-932.995 -35.799)" fill="#b36d5f" fillRule="evenodd" />
        <path id="Path_35172" data-name="Path 35172" d="M973.369,108.793c.2-.832.4-1.782.532-2.693l7.564,2.526s-.151,1.93-1.706,1.513-1.476-1-3.979-1a10.528,10.528,0,0,1-2.42-.336Z" transform="translate(-947.963 -73.998)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35173" data-name="Path 35173" d="M988.716,70.675c-.148-.908-.339-1.9-.605-3.377a15.347,15.347,0,0,1-.354-3.15l3.555-1.389s2.009,1.958-.172,5.255a10.115,10.115,0,0,1-2.42,2.659Z" transform="translate(-963.007 -43.771)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35174" data-name="Path 35174" d="M969.14,80.44c-.112.587-.254,1.21-.375,1.815a32.405,32.405,0,0,0-.342,4.783,17.41,17.41,0,0,1-1.706-.46c-2.287-.726-2.678-1.513-2.678-1.758s-2.136-.3-4.448-.641-.877-1.455.878-1.712,5.51-2.85,6.832-2.315a4.539,4.539,0,0,0,1.84.272Z" transform="translate(-931.108 -55.851)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35175" data-name="Path 35175" d="M970.162,68.79s-3.949,5-6.638,5.1v.257c2.811-.112,6.823-5.186,6.835-5.2l-.206-.16ZM973.626,85.8a29.772,29.772,0,0,1-10.532-1.815L963,84.22a30.03,30.03,0,0,0,10.635,1.843v-.257Zm-.781-8.774s-3.452,1.086-5.764-.151l-.124.23c2.421,1.289,5.954.172,5.963.169Zm-8.314-.859c0,.018,3.479,6.051,9.331,6.562l.048-.257c-5.718-.514-9.119-6.417-9.128-6.435l-.227.13Zm8.614,8.659s-5.286.672-8.916-2.756l-.182.188c3.721,3.507,9.116,2.829,9.131,2.826l-.03-.257Zm-3.328-12.006s-1.377,2.315-5.159,1.758l-.036.257c3.954.581,5.416-1.876,5.419-1.882l-.224-.133Z" transform="translate(-937.273 -47.976)" fill="#e8a597" fillRule="evenodd" />
        <path id="Path_35176" data-name="Path 35176" d="M968.189,101.463a9.278,9.278,0,0,0,2.051.354,8.7,8.7,0,0,1,2.8.654c.433.224.841,1.083.605,1.392s-.03.557-.227.826-.057.451-.3.729-.233.4-.548.605a3.575,3.575,0,0,1-2.46-.363c-.971-.46-1.059-1.265-1.492-1.383a11.619,11.619,0,0,1-1.135-.418l.7-2.4Z" transform="translate(-938.432 -70.762)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35177" data-name="Path 35177" d="M965.121,51.809c.275-.209,1.08-.566,3.256-.39,3.507.3,4.136.242,7.074,1.87,1.247.69-1.67-2.327-2.566-3.428a2.474,2.474,0,0,0-.1.43,16.511,16.511,0,0,1,1.428,1.9,2.5,2.5,0,0,0-.85-.726c-.4-.239-1.358-.75-2.048-1.11l-2.941-.871.484-.793a12.718,12.718,0,0,0-3.576.793c-1.307.411-1.392,1.017-1.144,1.513a2.645,2.645,0,0,0-.908.847c-.39.605.085,1.322.451,1.734.178-.2.348-.372.511-.529a4,4,0,0,1,.508-.8,5.107,5.107,0,0,1,.424-.439Z" transform="translate(-939.144 -33.957)" fill="#dedede" fillRule="evenodd" />
        <path id="Path_35178" data-name="Path 35178" d="M984.013,50.268l-2.793-.826.484-.793a10.286,10.286,0,0,0-2.684.523c-.436.224-.781.445-.781.581,0,.372.487.36.769.36s1.307-.154,2.581-.139a15.905,15.905,0,0,1,2.42.3Z" transform="translate(-951.987 -33.929)" fill="#748493" fillRule="evenodd" />
        <path id="Path_35179" data-name="Path 35179" d="M955.873,240.828c.024.641.042,1.026.042,1.026s-.1.2-2.181.275-2.324-.3-2.324-.3a5.363,5.363,0,0,1,.384-1.247,10.309,10.309,0,0,0,4.078.245Z" transform="translate(-915.771 -167.79)" fill="#b6beed" fillRule="evenodd" />
        <path id="Path_35180" data-name="Path 35180" d="M1048.145,238.181l-.623.82s-.209.088-1.864-1.165-1.6-1.7-1.6-1.7l.475-.8A11.135,11.135,0,0,0,1048.145,238.181Z" transform="translate(-1036.034 -164.135)" fill="#b6beed" fillRule="evenodd" />
        <path id="Path_35181" data-name="Path 35181" d="M960.084,58.029s1.558.511,4.19,4.59a.3.3,0,0,0-.054.073c.2.711.372.669.605.672s.1-.475-.169-.729a.3.3,0,0,0-.215-.1c-2.669-4.148-4.293-4.681-4.3-4.684l-.064.179Z" transform="translate(-927.426 -40.346)" fill="#ccc" />
        <path id="Path_35182" data-name="Path 35182" d="M975.587,57.34s-.442.191-.248.545l.167-.091c-.1-.172.154-.281.157-.281Z" transform="translate(-942.744 -39.991)" fill="#ccc" />
        <path id="Path_35183" data-name="Path 35183" d="M986.867,58.487s-1.352-.151-3.749,2.935-5.228,6.151-5.682,7.939c-.859,3.389,11.119,5.619,13.213,6.051a5.96,5.96,0,0,0,1.035-3.728s-8.7-1.74-9.41-2.8,3.976-2.666,5.422-5S988.652,59.247,986.867,58.487Z" transform="translate(-959.419 -40.79)" fill="#e8a597" fillRule="evenodd" />
        <path id="Path_35184" data-name="Path 35184" d="M989.441,78.853a1.662,1.662,0,0,0-.018-.3s-8.7-1.74-9.41-2.8,3.976-2.666,5.422-5a5.278,5.278,0,0,0,.838-2.42,8.54,8.54,0,0,1-2.977,3.328,14.176,14.176,0,0,0-3.522,2.817,4.565,4.565,0,0,0-1.846.439c-1.186.487,1.071.088.908.632s-1.9,1.513-1.395,1.779,1.946-1,2.4-.932,4.221,2.009,6.078,2.248a34.377,34.377,0,0,0,3.534.233Z" transform="translate(-957.158 -47.656)" fill="#cf887a" fillRule="evenodd" />
        <path id="Path_35185" data-name="Path 35185" d="M975.021,33.077a.433.433,0,0,1,.3-.514c.381-.109.687.23.847.587s.424.408.26.533-.605-.051-.645.23-.448.209-.375-.142.4-.3.415-.415-.239-.366-.36-.206-.442.2-.432-.073Z" transform="translate(-943.479 -22.695)" fill="#e69685" fillRule="evenodd" />
        <path id="Path_35186" data-name="Path 35186" d="M962.568,39.172a5.231,5.231,0,0,0-1.286-.278,1,1,0,0,0,.638.908c.436.073.648-.605.648-.605Z" transform="translate(-925.408 -27.114)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35187" data-name="Path 35187" d="M965.008,30.724a.124.124,0,0,0-.157.076.121.121,0,0,0,.076.157.124.124,0,0,0,.082-.233Z" transform="translate(-929.001 -21.423)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35188" data-name="Path 35188" d="M973.1,20.665a2.912,2.912,0,0,0-3.05.523l.185.182A2.635,2.635,0,0,1,973,20.9l.1-.239Zm-2.118,2.42a3.055,3.055,0,0,1-1.994-.472l-.136.221a3.329,3.329,0,0,0,2.185.5l-.045-.254Zm2.62-4.375s-3.125-.88-4.811,2.962l.239.1c1.595-3.631,4.5-2.817,4.505-2.817Zm1.356-1.171s-4.7-3.189-6.72,3.216l.248.076c1.912-6.051,6.323-3.077,6.336-3.071l.145-.215Z" transform="translate(-939.917 -11.679)" fill="#f39c12" />
        <path id="Path_35189" data-name="Path 35189" d="M994.436,37.08a4.572,4.572,0,0,0-1.749,1.815c-.654,1.18-.847,1.18-.847,1.18v.26s.348,0,1.077-1.316a4.308,4.308,0,0,1,1.637-1.694l-.118-.23Zm.687.956s-.947,3-2.626,2.626l-.057.254c1.915.427,2.929-2.8,2.932-2.8Z" transform="translate(-967.46 -25.861)" fill="#f39c12" />
        <path id="Path_35190" data-name="Path 35190" d="M981.885,55.146s1.712-.835,5.522-.021l.054-.254c-3.9-.832-5.685.039-5.691.042Z" transform="translate(-956.503 -38.018)" fill="#bababa" />
        <path id="Path_35191" data-name="Path 35191" d="M983.643,72.9a3.425,3.425,0,0,0-3.083,1.065l.188.179a3.146,3.146,0,0,1,2.835-.992Zm8.7,3.053a8.188,8.188,0,0,1-.865,2.956l.206.157a8.212,8.212,0,0,0,.908-3.08l-.26-.033Zm-1.419.962s-5.122.139-7.83-1.607l-.142.215c2.778,1.791,7.964,1.649,7.979,1.649V76.91Zm-2.4-12.42a9.8,9.8,0,0,0-4.693,6.051l.254.064a9.513,9.513,0,0,1,4.556-5.885Zm-4.69,8.659s-2.227.756-2.508,2.508l.257.042c.257-1.6,2.336-2.305,2.342-2.305Zm.124-.687a3.564,3.564,0,0,0-3.364-.124l.091.242a3.3,3.3,0,0,1,3.116.088Z" transform="translate(-961.272 -44.977)" fill="#b36d5f" fillRule="evenodd" />
        <path id="Path_35192" data-name="Path 35192" d="M944.814,70.712a4.072,4.072,0,0,0-2.139-1.912l-.076.248a3.78,3.78,0,0,1,1.982,1.782Zm-2.439-1a16.641,16.641,0,0,1-3.746,6.051l.185.182a16.942,16.942,0,0,0,3.809-6.157l-.248-.079Z" transform="translate(-900.804 -47.983)" fill="#b36d5f" fillRule="evenodd" />
        <path id="Path_35193" data-name="Path 35193" d="M948.06,180.78s-2.073.145-2.759.808l.182.185c.605-.605,2.59-.735,2.6-.735Zm-2.284,4.82c0,.021.944,6.756-1.555,10.287l.212.148c2.56-3.61,1.6-10.441,1.6-10.462l-.257.033Z" transform="translate(-905.759 -126.083)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35194" data-name="Path 35194" d="M974.232,131.437a24.858,24.858,0,0,0-3.026-2.647l-.136.221a24.443,24.443,0,0,1,2.98,2.605l.187-.179Z" transform="translate(-940.043 -89.823)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35195" data-name="Path 35195" d="M958.3,138.051c0,.027,4.75,8.832,10.838,10.175l.054-.254c-5.969-1.313-10.65-10.018-10.662-10.042Z" transform="translate(-931.133 -96.198)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35196" data-name="Path 35196" d="M1015.271,161.18s-.729,5.867-5.01,9.646l.172.194c4.357-3.846,5.1-9.791,5.1-9.809Zm-8.42,12.995c0,.015-1.1,5.446-3.171,7.936l.2.163c2.118-2.554,3.219-8.033,3.222-8.048Z" transform="translate(-991.202 -112.413)" fill="#4a4485" fillRule="evenodd" />
        <path id="Path_35197" data-name="Path 35197" d="M975.229,34.944s.8.641.7.893-.908-.505-1.129-.886c-.2-.33-.257-1.1.345-.908a.475.475,0,0,1,.085.908Z" transform="translate(-942.828 -23.722)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35198" data-name="Path 35198" d="M967.96,240.1s-.729-1.162-1.065-1.092-.4.2-.366.263.983.584,1.153.762S967.96,240.1,967.96,240.1Z" transform="translate(-932.388 -166.688)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35199" data-name="Path 35199" d="M932.121,237.474s.4,1.274.061,1.815a1.449,1.449,0,0,1-1.728.605c-.7-.248-1.035-.959-1.664-1.159s-1.116-.272-1.21-.136a11.806,11.806,0,0,0,0,1.885,2.929,2.929,0,0,1-.2,1,53.628,53.628,0,0,0,7.364,1.706,11.225,11.225,0,0,0,5.065-.635c-.387-.69-2.336-1.316-3.613-1.861a6.2,6.2,0,0,1-3.026-2.454c-.3-.614-.708-1.08-1.044-.756Zm7.425,5.824.021-.018Zm.076-.061.021-.021Zm.066-.064.018-.018Zm.052-.054.018-.021Zm.045-.058.018-.03Zm.039-.06v0Zm.027-.061v0Zm.018-.054a.152.152,0,0,0,0-.033.152.152,0,0,1,0,.033Zm0-.054a.144.144,0,0,0,0-.036v-.027a.17.17,0,0,0,0-.039.17.17,0,0,1,0,.039Zm0-.118a.1.1,0,0,0,0-.042A.1.1,0,0,1,939.87,242.714Zm0-.051v0Zm0-.045a.8.8,0,0,0-.033-.073Q939.855,242.58,939.87,242.617Z" transform="translate(-892.458 -165.558)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35200" data-name="Path 35200" d="M934.087,242.452a1.463,1.463,0,0,1-.357.266c.965.666,3.328,2.166,4.735,1.976a5.561,5.561,0,0,0,1.582-.4c-.59-.245-1.21-.481-1.737-.7a6.959,6.959,0,0,1-2.771-2.042c-.079.1-.172.224-.284.357A1.634,1.634,0,0,1,934.087,242.452Z" transform="translate(-894.555 -168.466)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35201" data-name="Path 35201" d="M935.969,245.17l.136.082-.039.073-.136-.082Zm.475.284.136.079-.036.076-.136-.082.036-.073Zm.481.278.136.076-.036.073-.139-.076Zm.481.266.139.073-.033.076-.139-.076Zm.49.254.142.07-.033.076-.154-.07.033-.076Zm.5.236.145.064-.03.076-.142-.064Zm.508.209.148.048-.024.082-.148-.051Zm.52.148.151.021v.082a1.32,1.32,0,0,1-.157-.021v-.082Zm.539.039h.154v.085h-.145v-.085Zm.539-.036.151-.024v.082l-.157.024v-.082Zm.529-.112.148-.048.024.079-.151.048Z" transform="translate(-896.373 -170.991)" fill="#5766c2" fillRule="evenodd" />
        <path id="Path_35202" data-name="Path 35202" d="M944.576,244.92c0,.263.021.548.021.811a2.927,2.927,0,0,1-.2,1,53.613,53.613,0,0,0,7.316,1.7A16.626,16.626,0,0,0,944.576,244.92Z" transform="translate(-909.451 -170.817)" fill="#4f4599" fillRule="evenodd" />
        <path id="Path_35203" data-name="Path 35203" d="M951.825,249.4c.042.033.085.067.124.1l-.048.064-.121-.1Zm-6.847-3.485v.085l.13.024v-.082l-.148-.027Zm.53.115.151.039-.018.082-.151-.039Zm.529.148.151.048-.024.079-.148-.048Zm.523.172.148.054-.027.079-.145-.054Zm.514.2.145.061-.027.076-.145-.061Zm.508.218.142.064-.03.076-.142-.064Zm.5.236.142.07-.033.076-.142-.07Zm.493.251.139.076-.033.073-.139-.073Zm.487.269.136.079-.036.073-.136-.079Zm.478.281.136.082-.039.073-.136-.082Zm.472.3.133.088-.042.07-.133-.085Zm.463.3.133.091-.042.07-.13-.088Zm.46.321.127.094-.042.067-.13-.091Zm.448.33.127.1-.045.067-.124-.094Z" transform="translate(-909.854 -171.507)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35204" data-name="Path 35204" d="M962.641,242.185a3.945,3.945,0,0,0-1.12-.841c-.629-.2-1.116-.272-1.21-.136a11.791,11.791,0,0,0,0,1.885h0A2.666,2.666,0,0,0,962.641,242.185Z" transform="translate(-925.179 -168.179)" fill="#5766c2" fillRule="evenodd" />
        <path id="Path_35205" data-name="Path 35205" d="M927.7,250.91a3.534,3.534,0,0,0-.221.753c-.042.466-.457,1.3,0,1.613a15,15,0,0,0,4.254.539c.548-.088.433-.357.908-.366s3.289.551,4.69.215c2.493-.605,3.1-1.159,2.811-1.682a11.225,11.225,0,0,1-5.065.635,53.635,53.635,0,0,1-7.364-1.706Z" transform="translate(-892.76 -174.995)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35206" data-name="Path 35206" d="M942.5,244.052s-.076.163-.182.381l.184.1.185-.387Zm-1.936-1.362s-.076.163-.182.381l.185.1.185-.39Zm.517.466s-.079.163-.185.381l.185.1.188-.39Zm.663.481-.185.381.185.1c.106-.215.187-.387.187-.387Z" transform="translate(-899.205 -169.262)" fill="#bec5ed" />
        <path id="Path_35207" data-name="Path 35207" d="M1055.266,229.866s.433-1.3.163-1.513-.411-.179-.439-.112.178,1.125.145,1.368.13.254.13.254Z" transform="translate(-1046.713 -159.148)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35208" data-name="Path 35208" d="M1045.967,234.586s-.735,1.116-1.368,1.21a1.437,1.437,0,0,1-1.564-.935c-.257-.7.082-1.4-.167-2.015s-.5-1.026-.669-1.008a12.075,12.075,0,0,0-1.458,1.21,2.947,2.947,0,0,1-.907.493,52.981,52.981,0,0,0,3.385,6.708,11.106,11.106,0,0,0,3.737,3.437c.3-.735-.475-2.62-.865-3.933a6.3,6.3,0,0,1-.042-3.882c.26-.632.384-1.234-.082-1.283Zm.233,9.379h0Zm.1.021Zm.091,0Zm.079,0Zm.073,0Zm.07,0h0Zm.063,0h0Zm.054-.024.03-.015Zm.048-.027.03-.021a.056.056,0,0,0,.021-.015l.027-.027-.027.027Zm.091-.076a.225.225,0,0,0,.024-.033.242.242,0,0,1-.024.033Zm.033-.042.024-.039Zm.024-.042a.4.4,0,0,0,.033-.073A.4.4,0,0,1,1046.923,243.775Z" transform="translate(-1033.635 -161.693)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35209" data-name="Path 35209" d="M1041.483,244.7a1.531,1.531,0,0,1-.433-.1c.1,1.162.445,3.915,1.495,4.874a5.5,5.5,0,0,0,1.325.953c-.187-.605-.409-1.237-.566-1.776a6.793,6.793,0,0,1-.191-3.416h-.46a1.652,1.652,0,0,1-1.171-.545Z" transform="translate(-1030.848 -170.594)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35210" data-name="Path 35210" d="M1042.292,245.51l.021.154-.079.018-.024-.157Zm.082.545.027.154-.082.018-.024-.154Zm.094.542.027.154-.082.021-.027-.157Zm.1.542.033.151-.082.021-.03-.154Zm.118.536.036.151-.082.024-.036-.154Zm.133.529.042.148-.076.027-.045-.148Zm.163.52.054.145-.076.033-.054-.148Zm.218.49c.024.045.051.088.079.13l-.066.048c-.03-.045-.057-.088-.085-.136l.073-.042Zm.3.436.1.118-.063.054-.1-.118Zm.375.387c.036.036.076.07.115.1l-.054.061-.118-.1Zm.427.333.13.082-.045.07-.136-.085Z" transform="translate(-1031.762 -171.228)" fill="#5766c2" fillRule="evenodd" />
        <path id="Path_35211" data-name="Path 35211" d="M1053.983,234.029c-.2.178-.411.366-.6.533a2.95,2.95,0,0,1-.908.493,52.152,52.152,0,0,0,3.358,6.656,16.319,16.319,0,0,0-1.843-7.691Z" transform="translate(-1046.261 -163.215)" fill="#4f4599" fillRule="evenodd" />
        <path id="Path_35212" data-name="Path 35212" d="M1055.513,242.162v.157h-.082v-.157Zm-1.676-7.452-.067.057.063.112.073-.039Zm.245.478.069.139-.075.039c-.021-.048-.045-.094-.066-.142Zm.227.5c.018.048.039.094.057.142l-.076.033c-.018-.045-.039-.094-.057-.142Zm.2.508.054.148-.079.03a.513.513,0,0,0-.052-.148Zm.179.52.045.148-.078.027-.046-.148Zm.154.526.042.151-.079.024-.042-.151Zm.139.533.033.151-.079.024-.036-.154Zm.118.536a.932.932,0,0,0,.03.154l-.079.021a.919.919,0,0,0-.03-.154Zm.1.542.027.157-.082.018-.024-.157Zm.088.545.024.157-.082.015-.021-.154Zm.073.551.021.157h-.082l-.018-.157Zm.061.551v.157h-.082V240.5Zm.045.551v.16h-.082v-.157h.082Zm.033.557v.157h-.082v-.157Z" transform="translate(-1046.339 -163.696)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35213" data-name="Path 35213" d="M1055.438,234.228a3.732,3.732,0,0,0-.064-1.392c-.248-.605-.5-1.026-.668-1.008a12.086,12.086,0,0,0-1.458,1.21h0a2.618,2.618,0,0,0,2.2,1.189Z" transform="translate(-1046.14 -161.686)" fill="#5766c2" fillRule="evenodd" />
        <path id="Path_35214" data-name="Path 35214" d="M1042.038,237.412a3.512,3.512,0,0,0-.729.3c-.384.266-1.295.481-1.246,1.029a14.862,14.862,0,0,0,2.3,3.591c.417.363.551.1.856.448s1.676,2.862,2.832,3.715c2.058,1.513,2.883,1.625,3.1,1.065a11.105,11.105,0,0,1-3.731-3.455,52.363,52.363,0,0,1-3.385-6.708Z" transform="translate(-1035.832 -165.572)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35215" data-name="Path 35215" d="M1043.536,250.688l-.411.1.039.206.417-.106Zm-.185-2.348-.411.1.039.206.417-.106Zm-.03.693-.411.1.039.206.418-.106Zm.052.811-.411.1.039.206.421-.106Z" transform="translate(-1031.122 -173.202)" fill="#bec5ed" />
        <path id="Path_35216" data-name="Path 35216" d="M332.171,139.7a32.512,32.512,0,0,0-1.543,5.824c-.533,3.234-.236,9-1.186,9.885s-9.9,3.41-13.107,5.295-3.38,1.41-3.631,2.057-1.3,1.767.239,2.06,17.673-3.295,19.155-4.414,3.8-5.059,4.923-10.354-4.8-10.472-4.862-10.354Z" transform="translate(-103.597 -97.427)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35217" data-name="Path 35217" d="M313.65,142.85a33.539,33.539,0,0,0-1.21,4.871,40.255,40.255,0,0,0-.351,4.157l6.8.172a7.057,7.057,0,0,0-.033-2.584c-1.1-2.557-3.026-6.142-5.2-6.617Z" transform="translate(-85.412 -99.629)" fill="#a60c00" fillRule="evenodd" />
        <path id="Path_35218" data-name="Path 35218" d="M264.253,93.419c1.153-.865,5.05-2.42,5.643-3.122a2.183,2.183,0,0,0,.408-1.283,4.455,4.455,0,0,1,.454-1.492,2.024,2.024,0,0,1,.947-.3.838.838,0,0,1,.448.2,1.88,1.88,0,0,1,.557.263.841.841,0,0,1,.242.3,1.055,1.055,0,0,1,.43.245,1.277,1.277,0,0,1,.221.281.938.938,0,0,1,.481.605c.115.43.085.605,0,.717a2.865,2.865,0,0,0-.681.808,2.48,2.48,0,0,1-1.159,1.186,6.372,6.372,0,0,1-.693.139c-2.823,2.054-4.971,3.485-8,5.38,0,0,.681-3.973.711-3.912Z" transform="translate(-25.897 -60.83)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35219" data-name="Path 35219" d="M263.915,124.82s-2.551,3.824-2.42,6.883,5.634,9.709,7.059,11.766a67.171,67.171,0,0,1,4.439,9c.666,1.646.829,4,2.723,7.473a23.946,23.946,0,0,1,2.42,7.177l4.39-1.292a125.364,125.364,0,0,1-3.679-12.592,37.344,37.344,0,0,0-2.312-7.942c-.415-.941-4.478-13.092-4.892-14.266s.088-4.06.324-4.738S263.815,124.874,263.915,124.82Z" transform="translate(-33.641 -87.054)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35220" data-name="Path 35220" d="M291.031,127.92c-.744,1.325-1.948,3.821-1.867,5.945s2.844,6.09,4.968,8.95l7.712-2.221c-1.141-3.361-2.3-6.8-2.508-7.4a10.8,10.8,0,0,1,.248-4.478Z" transform="translate(-61.331 -89.216)" fill="#c82c1d" fillRule="evenodd" />
        <path id="Path_35221" data-name="Path 35221" d="M295.893,65.248s1.57,2.472,2.608,3.973a6.93,6.93,0,0,1,1.6,4.56c-.148,1.619-2.315,10.357-2.315,10.357l-8.774-.826s1.513-6.178.415-11.059,2.877-8.593,2.877-8.593S295.85,65.221,295.893,65.248Z" transform="translate(-59.567 -44.399)" fill="#4a4485" fillRule="evenodd" />
        <path id="Path_35222" data-name="Path 35222" d="M298.224,81.5v.054c-.115,1.234-1.4,6.62-2.006,9.1a5.355,5.355,0,0,1-1.815-2.463,5.471,5.471,0,0,0-1.716-2.548c-.275-.324-.811-.735-1.2-1.116-2.983-1.464-2.333-3.858-2.33-3.867l.281.076a3.026,3.026,0,0,0,1.622,3.222c-.109-.3.166-.539,1.25-.539,1.927,0,4.538-1.21,5.912-1.918Zm-4.92-6.478a12.033,12.033,0,0,1,2.911,3.57l.272-.1a12.268,12.268,0,0,0-2.992-3.685Zm-3,4.817s2.859,1.985,4.962,1.985v-.3c-2.012,0-4.786-1.921-4.8-1.924l-.166.236Z" transform="translate(-57.695 -52.168)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35223" data-name="Path 35223" d="M303.355,70.3a1.848,1.848,0,0,1,2.62-.442c1.513,1.089,1.156,2.8-.206,4.384-1.573,1.815-4.009,2.8-5.249,4.914,1.546.566,5.307,3.631,6.432,3.691.605.033,1.761-.142,2.251.254a8.011,8.011,0,0,1,1.785,2.42c-.063.278-.605.436-.605.436a.605.605,0,0,1-.657.5c.094.387-.43.569-.684.411,0,0-.172.4-.451.384s-1.247-.847-1.247-.847-1.834-1.513-2.472-1.955c-.877-.605-2.139-.89-3-1.44-.206-.133-5.361-1.961-5.446-3.549s6.941-9.149,6.941-9.149Z" transform="translate(-72.675 -48.449)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35224" data-name="Path 35224" d="M289.032,28.238c3.349,1.147,3.231,3.265,3.5,5.059s1.041,1.77.738,2.148a3.54,3.54,0,0,1-1.1.324c-.3.057-.572,1.752-1.307,2.293s-1.277-.076-2.336-.245a6.959,6.959,0,0,0-.708,2.393c-.115,1.02-.209,1.737-.209,1.737-.26-.351-3.591-3.1-3.591-3.1l1.21-2.251a6.4,6.4,0,0,1-1-1.622c-1.555-3.57,1.014-8.024,4.774-6.738Z" transform="translate(-51.194 -19.536)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35225" data-name="Path 35225" d="M288.995,30.26a3.891,3.891,0,0,1,2.118,2.6,9.777,9.777,0,0,1-1.464.729c-.741.242-1.247.372-1.467.82s-.566,1.473-.638,1.322-.073-2.378-.073-2.378l1.513-3.092Z" transform="translate(-50.088 -21.104)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35226" data-name="Path 35226" d="M302.917,57.63a6.875,6.875,0,0,0-.666,2.118,9.5,9.5,0,0,1-2.611-3.328Z" transform="translate(-65.574 -39.349)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35227" data-name="Path 35227" d="M306.809,26.937a3.294,3.294,0,0,0-2.254-2.293,4.535,4.535,0,0,0-4.063.027c-.505.351-.9.59-.9.59s-1.482-.5-1.513-.678a2.9,2.9,0,0,0-3.174-1.588,2.6,2.6,0,0,0-.893.351,4.363,4.363,0,0,0-2.1,1.643c-1.9,2.557-3.292,2.315-3.295,2.315l-.048.284s1.567.269,3.576-2.42a5.239,5.239,0,0,1,.7-.775c-1.014,1.162-1.84,2.708-2.768,3.328a3.086,3.086,0,0,1-2.938.233s.366,2.23,3.361,2.457c3.933.941,6.29-2.82,6.959-4.06a.738.738,0,0,1,.13-.073,1.392,1.392,0,0,1,1.334.3,4.335,4.335,0,0,0-.741,3.207,5.34,5.34,0,0,0,1.486,3.295,4.3,4.3,0,0,0,2.076-2s-.889-1.029-.327-1.706,1.392-.411,1.422.236a8.784,8.784,0,0,1-.03,1.21s.212.257.275.057c.178-.569.427-2.076,1.029-2.3a8.118,8.118,0,0,0,2.7-1.616Zm-14.7,3.283c1.945-.56,3.631-2.312,4.656-3.295C295.969,28.166,294.4,30.032,292.108,30.22Z" transform="translate(-65.691 -15.999)" fillRule="evenodd" />
        <path id="Path_35228" data-name="Path 35228" d="M310.869,28.153s-.39,1.667-.357,1.7,1.21.33,1.21.33l.823-1.38a3.255,3.255,0,0,0-1.673-.65Z" transform="translate(-78.492 -19.63)" fill="#a60c00" fillRule="evenodd" />
        <path id="Path_35229" data-name="Path 35229" d="M290.037,45c-.172.018-.3.26-.263.542s.194.5.366.478.3-.257.263-.542S290.209,44.979,290.037,45Z" transform="translate(-50.081 -31.382)" fill="#331f1b" fillRule="evenodd" />
        <path id="Path_35230" data-name="Path 35230" d="M289.911,42.194a1.443,1.443,0,0,0-1.591.242l.2.275c.729-.529,1.21-.236,1.21-.233l.191-.284Z" transform="translate(-49.156 -29.335)" fillRule="evenodd" />
        <path id="Path_35231" data-name="Path 35231" d="M290.461,54.831a1.364,1.364,0,0,1-.983-.281l-.157.136a1.574,1.574,0,0,0,1.159.348Z" transform="translate(-50.014 -38.045)" fill="#b36d5f" />
        <path id="Path_35232" data-name="Path 35232" d="M290.909,45.654a.106.106,0,1,0,.124.169.1.1,0,0,0,.024-.145.106.106,0,0,0-.148-.024Z" transform="translate(-51.077 -31.826)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35233" data-name="Path 35233" d="M265.6,251.87c.2.687.318,1.1.318,1.1s-.061.242-2.29.859-2.593.272-2.593.272-.151-.363-.393-.956A12.468,12.468,0,0,0,265.6,251.87Z" transform="translate(-16.775 -175.664)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35234" data-name="Path 35234" d="M235.3,251.463a4.508,4.508,0,0,1,.539,2.635c-.121,1.625.121,1.945.121,1.945a.539.539,0,0,0-.357.533,1.029,1.029,0,0,0,.336.756,41.563,41.563,0,0,0,9.933-1.93c1.679-.708,2.566-1.286,2.641-1.867a1.18,1.18,0,0,0-1.434-1.307c-2.935.638-4.311-.218-7.034-2.242-.175-.133-.908.327-.835.923s.4.454-.048.908a3.013,3.013,0,0,1-2.066.908c-.72-.067-.859-1.292-1.794-1.259Z" transform="translate(8.292 -174.334)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35235" data-name="Path 35235" d="M235.607,263.148a.563.563,0,0,0-.3.505,1.029,1.029,0,0,0,.336.756,41.564,41.564,0,0,0,9.933-1.93c1.679-.708,2.566-1.286,2.641-1.867a1.138,1.138,0,0,0-.054-.5c-3.67,2.541-8.693,3.083-12.562,3.026Z" transform="translate(8.585 -181.411)" fill="#534d8c" fillRule="evenodd" />
        <path id="Path_35236" data-name="Path 35236" d="M390.788,215.319c-.847-.139-1.4-.218-1.4-.218l-.65-3.631a8.042,8.042,0,0,0,2.1.532,7.788,7.788,0,0,0-.054,3.313Z" transform="translate(-180.459 -147.487)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35237" data-name="Path 35237" d="M298.95,27.926a16.522,16.522,0,0,0-4.136,3.349,2.636,2.636,0,0,1-4.069,0l-.215.194a2.914,2.914,0,0,0,4.5,0,16.164,16.164,0,0,1,4.051-3.286Zm6.868,2.681s-.726-.883-3.174-1.059l-.018.3c2.321.166,2.968.95,2.968.953l.224-.182Zm1.927-1.461s-1.979-1.9-5.682-1.053l.064.281c3.552-.808,5.416.98,5.422.983Zm-5.891,5.3a10.875,10.875,0,0,1-.254-4.457l-.287-.051a11.124,11.124,0,0,0,.263,4.587Zm-2.723-7.407s-2.623-1.337-4.7,2.393l.254.139C296.63,26.089,299,27.3,299,27.3l.13-.26Z" transform="translate(-67.647 -18.697)" fill="#474646" />
        <path id="Path_35238" data-name="Path 35238" d="M334.285,101.1s-1.513-.115-1.985.466l.227.182c.369-.463,1.737-.36,1.74-.36l.018-.3Z" transform="translate(-106.824 -70.497)" fill="#e69f91" />
        <path id="Path_35239" data-name="Path 35239" d="M302.072,137.107a13.21,13.21,0,0,0-6.336-2.327l-.018.287a12.876,12.876,0,0,1,6.181,2.269Zm1.619,7.261c-.018,0-6.478.629-9.053-3.561l-.248.151c2.672,4.342,9.307,3.7,9.325,3.7l-.024-.3Z" transform="translate(-64.784 -94.001)" fill="#e84638" />
        <path id="Path_35240" data-name="Path 35240" d="M306.729,144.3c-.018,0-5.531-1.126-7.839.224l.145.248c2.215-1.292,7.618-.194,7.634-.191Z" transform="translate(-69.16 -100.331)" fill="#e84638" />
        <path id="Path_35241" data-name="Path 35241" d="M392.221,207.8a4.348,4.348,0,0,1-2.557-.2c-1.464-.536-1.815-.4-1.815-.4a.511.511,0,0,0-.393-.463.992.992,0,0,0-.784.106,38.6,38.6,0,0,0-.826,9.479c.212,1.7.508,2.653,1.023,2.874a1.123,1.123,0,0,0,1.576-.956c.182-2.817,1.328-3.836,3.9-5.767.166-.127-.057-.908-.629-1s-.52.245-.8-.278a2.9,2.9,0,0,1-.3-2.1c.242-.635,1.41-.439,1.625-1.292Z" transform="translate(-181.113 -144.158)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35242" data-name="Path 35242" d="M402.91,207.13a.539.539,0,0,0-.387-.4.992.992,0,0,0-.784.106,38.6,38.6,0,0,0-.826,9.479c.212,1.7.508,2.653,1.023,2.874a1.238,1.238,0,0,0,.478.082C401.04,215.293,401.854,210.616,402.91,207.13Z" transform="translate(-196.187 -144.158)" fill="#534d8c" fillRule="evenodd" />
        <path id="Path_35243" data-name="Path 35243" d="M301.468,43.945s.36-.351.62-.254.363,1.053.1,1.307-.666-.3-.8-.245-.133-.551.127-.493.605-.091.454-.166A2.834,2.834,0,0,0,301.468,43.945Z" transform="translate(-65.503 -30.46)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35244" data-name="Path 35244" d="M299.663,120.27l-.744-.88-.278.23.741.88.281-.23Z" transform="translate(-62.018 -83.267)" fill="#e69f91" />
        <path id="Path_35245" data-name="Path 35245" d="M301.483,121.97l-.744-.88-.278.23.741.88.281-.23Z" transform="translate(-64.388 -84.453)" fill="#e69f91" />
        <path id="Path_35246" data-name="Path 35246" d="M303.523,123.86l-.744-.88-.278.233.741.88.281-.233Z" transform="translate(-67.046 -85.771)" fill="#e69f91" />
        <path id="Path_35247" data-name="Path 35247" d="M266.86,88.417a5.114,5.114,0,0,1-.076,1.1,8.967,8.967,0,0,1-.605,1c-.067-.033-.176-.133-.16-.442a2.478,2.478,0,0,1,.118-.738l-.094-.039a1.178,1.178,0,0,0,.1-.387.669.669,0,0,0-.557-.687l-.039.194a.475.475,0,0,1,.4.481,1.545,1.545,0,0,1-.182.542,1.513,1.513,0,0,0-.194,1.1l.185-.051a1.18,1.18,0,0,1,.1-.79c0,.1-.024.224-.033.363-.033.635.384.666.384.669h.057l.033-.048a10.873,10.873,0,0,0,.672-1.092,5.31,5.31,0,0,0,.088-1.177h-.194Zm.623,2.835s.088.191.375.115c.209-.057.3-.091.3-.091l-.067-.185s-.082.027-.3.082c-.127.036-.145,0-.145,0l-.181.07Zm.654-1.924s-.1.657-.13.75a5.611,5.611,0,0,1-.52.663,1.3,1.3,0,0,1-.318.3.3.3,0,0,1-.194-.024l-.079.182a.427.427,0,0,0,.3.039c.1,0,.269-.166.445-.363a5.189,5.189,0,0,0,.539-.732c.036-.106.139-.787.139-.787l-.191-.03Zm-.623-.418s-.027.605-.064.8a2.12,2.12,0,0,1-.3.469,7.536,7.536,0,0,1-.605.714c-.055.045-.121-.036-.121-.036l-.151.124s.188.236.393.067a7.018,7.018,0,0,0,.629-.747,2.074,2.074,0,0,0,.339-.554c.039-.218.067-.82.067-.823H267.5Z" transform="translate(-20.662 -61.528)" fill="#db8777" />
        <path id="Path_35248" data-name="Path 35248" d="M679.542,51.2c-.088.209-1.292,2.118-1.622,2.8.605,1.05,2.4,1.029,3.289,1.864a8.741,8.741,0,0,1,1.3-3.11l-2.723-1.531-.239-.024Z" transform="translate(-559.622 -35.708)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35249" data-name="Path 35249" d="M678.564,51.2c-.039.1-.321.56-.644,1.1a5.176,5.176,0,0,0,2.662,2.321,9.435,9.435,0,0,1,.953-1.861l-2.723-1.531-.239-.024Z" transform="translate(-558.645 -35.708)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35250" data-name="Path 35250" d="M666.73,30.833c-.366-.106-1.34-1.446-1.764-1.815l.051.054-.145.157a3.032,3.032,0,0,1-.3-.357l-.215-.027c.039.048.082.094.121.136l-.142.157a2.622,2.622,0,0,1-.287-.348c-.393-.1-.8-.369-1.026-1.247a4.1,4.1,0,0,1-.257-2.886,2.969,2.969,0,0,1,.651-1.1,2.077,2.077,0,0,1,.651-.865c.908-.605,1.815-1.619,2.9-1.446a8.017,8.017,0,0,0,2.766-.5,4.564,4.564,0,0,1,3.519,2.251,4.712,4.712,0,0,1,.935,2.181,4.905,4.905,0,0,1-.133,2.756l-.2-.073a4.837,4.837,0,0,0,.254-1.682c-.033.179-.076.366-.124.566a1.945,1.945,0,0,1-.363.775,4.657,4.657,0,0,1-.372.793l-.178-.115a4.027,4.027,0,0,0,.2-.369c-.357.218-.708.191-.75.1-.079-.166.139-1.407-1.464-.651s-2.451,4.236-4.333,3.549Zm-3.839-4.484a7.428,7.428,0,0,1,.263-2.042,2.915,2.915,0,0,0-.182.43,3.329,3.329,0,0,0-.082,1.613Z" transform="translate(-546.795 -14.461)" fill="#ebb64d" fillRule="evenodd" />
        <path id="Path_35251" data-name="Path 35251" d="M674.1,35.962c.027,1.386-.272,1.05-.3,2.136s.466,1.428.3,1.61-1.08.16-1.08.16a2.266,2.266,0,0,1-1.452,1.761,2.785,2.785,0,0,1-3.431-1.579c.166-.07.257-.654.417-1.461,0,0-.048,0-.348-.3a1.758,1.758,0,0,1-.2-1.637c.224-.514.672-.348.871.082s.233,2.048.575,1.573-.063-1.585,0-2.175.49-.245,1.613-.46a3.162,3.162,0,0,0,1.791-1.162c.281-.408.526-.56.732-.557A4.167,4.167,0,0,1,674.1,35.962Z" transform="translate(-548.205 -23.678)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35252" data-name="Path 35252" d="M669.176,38.236c.218-.523-.124-1.513-.057-2.057s.49-.245,1.613-.46a3.162,3.162,0,0,0,1.791-1.162c.281-.408.526-.56.732-.557a3.334,3.334,0,0,1,.157.3,1.615,1.615,0,0,0-.678.781c-.16.554-1.673,1.183-2.387,1.092s-1.083-.115-.947.378,0,1.625-.224,1.673Z" transform="translate(-547.856 -23.712)" fill="#fac2b6" fillRule="evenodd" />
        <path id="Path_35253" data-name="Path 35253" d="M672.241,43.213c-.151-.03-.3.154-.357.408s.039.49.194.52.3-.154.357-.411S672.4,43.243,672.241,43.213Z" transform="translate(-547.729 -30.135)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35254" data-name="Path 35254" d="M671.269,40.4a1.245,1.245,0,0,0-1.649-.3l.194.411a.816.816,0,0,1,1.107.154l.348-.278Z" transform="translate(-545.867 -27.842)" fill="#d49b2a" />
        <path id="Path_35255" data-name="Path 35255" d="M673.781,27.649s-1.513.85-.7,3.044l.2-.079c-.741-2.015.605-2.771.605-2.771ZM677.79,24a5.386,5.386,0,0,1-3.516,2.076l.042.215a5.61,5.61,0,0,0,3.649-2.169Zm1.186,1.165s.5,1.558-3.734,2.768l.055.212c4.444-1.256,3.879-3.026,3.876-3.026l-.2.067Zm-4.03-1.008a4.707,4.707,0,0,0-3.234,2.723,2.832,2.832,0,0,0,.641,3.631l.118-.179a2.626,2.626,0,0,1-.572-3.361,4.475,4.475,0,0,1,3.083-2.593l-.036-.218Z" transform="translate(-554.318 -16.738)" fill="#d49b2a" />
        <path id="Path_35256" data-name="Path 35256" d="M685.3,43.747s0-.4.242-.52.463.248.481.669.166.769-.176.8a.472.472,0,0,1-.487-.517c.027-.2.2-.033.336-.148S685.336,43.871,685.3,43.747Z" transform="translate(-565.417 -30.131)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35257" data-name="Path 35257" d="M674.305,53.443s-.411.133-.878-.463l-.218.188c.587.75,1.177.56,1.177.56Z" transform="translate(-550.072 -36.95)" fill="#e69f91" />
        <path id="Path_35258" data-name="Path 35258" d="M696.878,186.948s.769,1.1.605,1.349-10.514,17.851-10.689,18.278.641,1.116,1.416,1.116a11.558,11.558,0,0,0,2.7-.8L703.447,189.2l-2.3-4.2-4.266,1.955Z" transform="translate(-583.234 -129.026)" fill="#eba496" fillRule="evenodd" />
        <path id="Path_35259" data-name="Path 35259" d="M699.7,155.75l3.343,11.388,2.98-1.274L699.7,155.75Z" transform="translate(-589.723 -108.626)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35260" data-name="Path 35260" d="M682.972,183.279s1.331,1.8,1.21,1.982-12.971,3.358-13.176,3.513-.3.862.212,1.815a3.613,3.613,0,0,1,.463,1.7c3.67-.242,17.594-1.513,18.547-5.144-.067-1.964-.563-4.729-1.47-5.721-1.386-1.513-5.67,1.815-5.788,1.84Z" transform="translate(-565.195 -126.255)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35261" data-name="Path 35261" d="M672.9,183.321a19.424,19.424,0,0,1,1.113,1.667,13.312,13.312,0,0,0,5.519-1.785,4.842,4.842,0,0,0-.844-1.722C677.3,179.969,673.018,183.3,672.9,183.321Z" transform="translate(-555.123 -126.297)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35262" data-name="Path 35262" d="M682.23,139.567,688.314,152a14.442,14.442,0,0,1-8.686,2.42s-4.923-7.752-6.139-10.45-1.062-4.744-.533-5.446,9.334,1.086,9.273,1.056Z" transform="translate(-563.802 -96.497)" fill="#ccc" fillRule="evenodd" />
        <path id="Path_35263" data-name="Path 35263" d="M683.241,173.523l.414.841a5.261,5.261,0,0,1-2.154,1.21c-2.281.756-7.113-1.18-7.667-2.36s1.776.33,2.3-.112-2.723-2.036-1.446-1.513,4.538,1.918,7.385,1.945h1.165Z" transform="translate(-559.503 -119.6)" fill="#c2c0c0" fillRule="evenodd" />
        <path id="Path_35264" data-name="Path 35264" d="M690.22,154.4c-.218.082-.439.16-.654.23a14.317,14.317,0,0,0-.971-1.8,1.357,1.357,0,0,0-.257-.185c-.478-.3-1.014-.605-.626-1.752a3.006,3.006,0,0,0-.605-2.063,19.292,19.292,0,0,0-1.791-2.484c-1.225-1.477-3.25-6.553-3.255-6.569l.321-.127.4.061c.375.926,2.051,4.971,3.071,6.2a19.871,19.871,0,0,1,1.861,2.581,3.465,3.465,0,0,1,.657,2.623.67.67,0,0,0,.336.95,1.65,1.65,0,0,1,.417.321A14.59,14.59,0,0,1,690.22,154.4Z" transform="translate(-568.582 -97.397)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35265" data-name="Path 35265" d="M678.985,155.31s3.861,5.268,9.627,6.547l.057-.263c-5.679-1.259-9.47-6.429-9.482-6.445l-.218.16Zm11.08,7.564s-4.166,1.174-7.149-1.244l-.172.209c3.092,2.505,7.38,1.3,7.391,1.295Z" transform="translate(-567.562 -108.207)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35266" data-name="Path 35266" d="M700.263,139.53l.626,1.28a21.408,21.408,0,0,1-10.287-.793,2.811,2.811,0,0,1,.393-1.543c.533-.714,9.334,1.086,9.273,1.056Z" transform="translate(-581.835 -96.46)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35267" data-name="Path 35267" d="M705.089,142.031c-.251,0-.505,0-.762-.015-.548-1.237-.944-2.233-.947-2.239l.321-.127h0l.381.061h0c.154.381.532,1.295.992,2.321Z" transform="translate(-589.902 -97.397)" fill="#e8ac33" fillRule="evenodd" />
        <path id="Path_35268" data-name="Path 35268" d="M674.875,104.92a4.98,4.98,0,0,1,2.333.841c.753.681.076,3.225-.545,3.815s-2.7-.526-2.7-.526,1-4.13.908-4.13Z" transform="translate(-553.452 -73.175)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35269" data-name="Path 35269" d="M684.481,60.991a13.345,13.345,0,0,1,2.387-1.38c.611-.139,2.641.908,3.38,2.278a11.88,11.88,0,0,1,1.156,7.924c-.823,3.225-3.168,10.417-3.168,11.969s.974,2.653-.759,3.328-10.148-.245-10.441-.989.529-1.861.708-2.883.826-10.977,6.738-20.232Z" transform="translate(-568.498 -41.566)" fill="#1f76b8" fillRule="evenodd" />
        <path id="Path_35270" data-name="Path 35270" d="M678.473,108.575c.124-.672.605-1.479.741-2.236.033-.191.082-.7.182-1.458a15.064,15.064,0,0,0,5.379,2.623c2.723.427-.587.986-2.511.493s-2.517-.944-2.723-.179a1.236,1.236,0,0,1-1.071.756Zm14.22-12.965c-.412,1.513-1.032,3.579-1.6,5.549-1.679-.215-3.5-1.28-3.8-1.885-.339-.7.747-.847-.638-1.343s-1.864-1.691,1.728-1.358A7.383,7.383,0,0,0,692.693,95.61Z" transform="translate(-569.969 -66.682)" fill="#1867a3" fillRule="evenodd" />
        <path id="Path_35271" data-name="Path 35271" d="M694.834,93.054l-.027.1-.033.115-.024.085-.027.1v.036l-.018.064-.027.1-.015.057v.042l-.027.1-.021.079v.018l-.027.1-.027.1h0l-.027.091-.024.1v.024l-.021.07-.024.094v.094l-.024.094-.018.064v.027l-.024.094-.021.082h0l-.024.088-.024.091h0l-.018.076-.024.088V95.4l-.021.088v.085l-.024.085v.085l-.021.082-.021.082h0l-.018.079-.021.082-.018.079-.021.079v.076l-.018.079h0v.136l-.03.124v.051l-.018.07h0v.061l-.015.067v.067h0v.067l-.015.067v.064a1.134,1.134,0,0,1-.045.233h0v.169h0v.2h0v.26a5.4,5.4,0,0,1-2.287-1.83c-.635-.717-5.071-3.682-7.092-5.183l.024-.1-.269-.263.018-.079h0l.027-.109.027-.112h0l.036-.139.048-.185.027-.112h0l.027-.109.048-.185.042-.182h0l.03-.118.033-.118.033-.115.033-.118.033-.118.045-.163.048-.172.07-.23v-.033l.036-.121v-.027l.054-.182.066-.218.054-.172.082-.26.045-.136.079-.242.061-.182.073-.215.061-.175.088-.251.051-.145.082-.233.067-.182.1-.281.048-.13.1-.26.073-.182.088-.23.07-.172.109-.263.079-.188.1-.248.067-.157.121-.284c.865.7,2.5,2.033,2.5,2.057s-2.145,2.635-2.145,3.131,5.706,5.143,5.706,5.143a4.079,4.079,0,0,1,1.773-.4h.148Z" transform="translate(-573.889 -57.957)" fill="#1f76b8" fillRule="evenodd" />
        <path id="Path_35272" data-name="Path 35272" d="M694.94,93.068a40.618,40.618,0,0,0-1.21,4.947,5.4,5.4,0,0,1-2.287-1.83c-.635-.717-5.071-3.682-7.092-5.183a47.3,47.3,0,0,1,2.593-7.882c.865.7,2.5,2.033,2.5,2.057s-2.145,2.635-2.145,3.131,5.706,5.144,5.706,5.144a4.079,4.079,0,0,1,1.773-.4h.148Z" transform="translate(-573.995 -57.971)" fill="#1867a3" fillRule="evenodd" />
        <path id="Path_35273" data-name="Path 35273" d="M689.324,107.3c-.015,0-5.794,1.062-8.623,0l-.091.266c2.9,1.08,8.741,0,8.774,0l-.045-.278ZM685.182,95.12s-.475,5.192,2.363,8.4l.2-.194c-2.756-3.11-2.293-8.169-2.293-8.169l-.269-.024Zm7.41-1.044a8.842,8.842,0,0,1-6.9-.036l-.094.266a9.166,9.166,0,0,0,7.113.027Zm-2.908,12.148c-.021,0-6.587-.245-7.96-2.5l-.227.151c1.449,2.384,8.169,2.632,8.169,2.632v-.281Z" transform="translate(-570.633 -65.587)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35274" data-name="Path 35274" d="M680.146,59.706a1.72,1.72,0,0,1,.3-.115c.605-.139,2.641.908,3.38,2.278a10.419,10.419,0,0,1,.481,1.044c-.324-.085-.684-.442-1.21-1.186a8.611,8.611,0,0,0-2.959-2.021Z" transform="translate(-562.091 -41.552)" fill="#f7f9f9" fillRule="evenodd" />
        <path id="Path_35275" data-name="Path 35275" d="M684.852,81.9s-2.145,2.635-2.145,3.131,5.089,5.655,5.089,5.655a4.061,4.061,0,0,1,1.77-.4c.829.06,2.293.605,2.021,1.083a1.841,1.841,0,0,0-.272.56s1.234,2.281-.657,2.811-3-.923-3.809-1.83-7.591-5.346-8.063-5.985c-.992-1.343,2.321-6.448,3.041-7.5C681.826,79.42,684.852,81.868,684.852,81.9Z" transform="translate(-569.026 -55.39)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35276" data-name="Path 35276" d="M704.81,81.866s-.269.327-.605.775c-.835-.66-2.245-1.773-3-2.363.345-.526.605-.908.605-.908s3.026,2.448,3.026,2.481Z" transform="translate(-588.984 -55.355)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35277" data-name="Path 35277" d="M688.75,66.236s2.333-4.372,3.982-5.428a1.959,1.959,0,0,1,2.923.551c1.67,2.3-1.407,6.254-2.723,8.169,0,0-4.193-3.328-4.193-3.286Z" transform="translate(-576.522 -42.113)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35278" data-name="Path 35278" d="M698.685,74.574a12.979,12.979,0,0,1-.212-5.083l-.251-.051a13.255,13.255,0,0,0,.215,5.207Z" transform="translate(-581.888 -48.43)" fill="#1f76b8" fillRule="evenodd" />
        <path id="Path_35279" data-name="Path 35279" d="M695.446,61a2.38,2.38,0,0,1,.7.605l-4.584,6.747-.711-.56Z" transform="translate(-577.166 -42.543)" fill="#f7f9f9" fillRule="evenodd" />
        <path id="Path_35280" data-name="Path 35280" d="M672.6,43.679a.118.118,0,1,0,.109.118A.115.115,0,0,0,672.6,43.679Z" transform="translate(-548.167 -30.463)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35281" data-name="Path 35281" d="M711.171,97.687a2.357,2.357,0,0,0-1.861.442l.182.212a2.066,2.066,0,0,1,1.634-.372Z" transform="translate(-597.778 -68.105)" fill="#f0b0a3" />
        <path id="Path_35282" data-name="Path 35282" d="M680.87,195.344a3.952,3.952,0,0,1,2.366,1.077l.176-.215a4.236,4.236,0,0,0-2.532-1.147v.284Z" transform="translate(-561.414 -136.042)" fill="#f0b0a3" />
        <path id="Path_35283" data-name="Path 35283" d="M709.63,254.225a2.539,2.539,0,0,0-.75,1.346c-.109.775-.959,1.268-1.029,1.607a2.033,2.033,0,0,0,1.979,2.1c1.87.287,3.6.414,4.665.732a9,9,0,0,0,4.448-.62,1.189,1.189,0,0,0,.635-1.912c-.417-.672-2.508.363-3.467-1.044s-1.619-2.6-2.139-2.333a7.576,7.576,0,0,1-2.868,1.159,1.723,1.723,0,0,1-1.473-1.038Z" transform="translate(-606.003 -177.196)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35284" data-name="Path 35284" d="M709.3,255.85a2.209,2.209,0,0,0-.417.965c-.109.775-.959,1.268-1.029,1.607a2.033,2.033,0,0,0,1.979,2.1c1.87.287,3.6.415,4.665.732a9,9,0,0,0,4.448-.62c.868-.363,1.02-.956.823-1.513a5.467,5.467,0,0,1-4.293.557c-2.75-.641-4.659-3.059-6.175-3.818Z" transform="translate(-606.002 -178.44)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35285" data-name="Path 35285" d="M708.131,263.56a.6.6,0,0,0-.139.239,2.036,2.036,0,0,0,1.979,2.1c1.87.287,3.6.415,4.665.732a9,9,0,0,0,4.448-.62,1.37,1.37,0,0,0,.853-.79,11.045,11.045,0,0,1-3.685.929,32.5,32.5,0,0,1-5.6-.868A4.535,4.535,0,0,1,708.131,263.56Z" transform="translate(-606.141 -183.817)" fill="#1f76b8" fillRule="evenodd" />
        <path id="Path_35286" data-name="Path 35286" d="M719.733,250.057c.033-.46.16-1.434.666-1.6.07-.021.112-.024.13,0a.164.164,0,0,1,.03.115,1.071,1.071,0,0,1-.048.3A2.487,2.487,0,0,1,719.733,250.057Zm-.269.275h-.194c-.654-.018-2.378-.076-2.439.632-.079.932,2.708-.366,2.723-.369H720c1.21.188,1.513,1.57,1.513,1.573l.248-.067a2.612,2.612,0,0,0-1.077-1.546,2.794,2.794,0,0,0,.526-.106.989.989,0,0,1,.956.218l.172-.2a1.21,1.21,0,0,0-1.21-.275,4.214,4.214,0,0,1-1.113.133h-.2a2.744,2.744,0,0,0,.908-1.358,1.323,1.323,0,0,0,.06-.393.408.408,0,0,0-.13-.321.369.369,0,0,0-.363-.036c-.841.284-.859,2.094-.859,2.1v.036Zm-2.375.65c.03-.375,1.092-.405,1.791-.4C718.172,250.889,717.065,251.3,717.089,250.982Z" transform="translate(-611.223 -173.094)" fill="#ccc" />
        <path id="Path_35287" data-name="Path 35287" d="M721.274,258.385a1.815,1.815,0,0,1,.923-.635l-.076-.254a2.091,2.091,0,0,0-1.059.735ZM720,257.106a2.614,2.614,0,0,1,1.1-.769l-.079-.257a2.908,2.908,0,0,0-1.21.853l.2.172Zm.272.33a2.642,2.642,0,0,1,1.1-.769l-.079-.257a2.884,2.884,0,0,0-1.21.853l.2.172Zm.3.281a2.4,2.4,0,0,1,1.047-.708l-.076-.257a2.683,2.683,0,0,0-1.171.8Zm.33.393a2.375,2.375,0,0,1,1.029-.69l-.076-.257a2.627,2.627,0,0,0-1.153.778Z" transform="translate(-611.981 -178.6)" fill="#e6e6e6" />
        <path id="Path_35288" data-name="Path 35288" d="M733.626,202.414a2.233,2.233,0,0,0-1.265-.554c-.69-.018-1.21-.756-1.513-.784s-1.576.3-1.625,2.057.03,3.38-.133,4.405a8.8,8.8,0,0,0,1.035,4.06c.511.941,1.21.847,1.746.387s-.605-2.29.536-3.328,2.1-1.779,1.815-2.233a7.356,7.356,0,0,1-1.331-2.538,1.609,1.609,0,0,1,.75-1.477Z" transform="translate(-626.856 -140.238)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35289" data-name="Path 35289" d="M736.633,202.145a1.909,1.909,0,0,0-.908-.284c-.69-.018-1.21-.756-1.513-.784s-1.576.3-1.625,2.057.03,3.38-.133,4.405a8.8,8.8,0,0,0,1.035,4.06c.415.765.95.844,1.428.605a5.32,5.32,0,0,1-.959-3.933c.257-2.617,2.166-4.644,2.669-6.133Z" transform="translate(-630.235 -140.238)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35290" data-name="Path 35290" d="M741.636,201.179a.533.533,0,0,0-.224-.1c-.3-.027-1.576.3-1.625,2.057s.03,3.38-.133,4.405a8.8,8.8,0,0,0,1.035,4.06,1.234,1.234,0,0,0,.787.7,10.426,10.426,0,0,1-1.21-3.328,32.147,32.147,0,0,1,.148-5.289,4.307,4.307,0,0,1,1.234-2.517Z" transform="translate(-637.429 -140.238)" fill="#1f76b8" fillRule="evenodd" />
        <path id="Path_35291" data-name="Path 35291" d="M725.131,214.469c.405-.021,1.274,0,1.477.448.027.06.033.1.021.118a.151.151,0,0,1-.1.042.8.8,0,0,1-.275-.015,2.172,2.172,0,0,1-1.125-.605Zm-.272-.221a.9.9,0,0,0-.018-.182c-.051-.605-.194-2.215-.82-2.2-.826.024.6,2.475.623,2.481h0c0,.07.015.224.039.418a1.886,1.886,0,0,1-1.21,1.573l.088.224A2.321,2.321,0,0,0,724.8,215.4a3.044,3.044,0,0,0,.148.478.95.95,0,0,1-.085.908l.194.139a1.189,1.189,0,0,0,.112-1.144,4.238,4.238,0,0,1-.242-1.02.422.422,0,0,0,0-.088h0v-.1a2.421,2.421,0,0,0,1.289.7,1.109,1.109,0,0,0,.354.015.342.342,0,0,0,.263-.154.351.351,0,0,0,0-.342c-.339-.75-1.93-.575-1.933-.572Zm-.832-2.139c.333,0,.478.971.545,1.622C724.236,213.107,723.749,212.118,724.027,212.109Z" transform="translate(-617.768 -147.766)" fill="#ccc" />
        <path id="Path_35292" data-name="Path 35292" d="M730.55,220.346a1.664,1.664,0,0,1,.657.79l.218-.1a1.928,1.928,0,0,0-.763-.908l-.112.212Zm.983-1.316a2.42,2.42,0,0,1,.793.935l.218-.1a2.705,2.705,0,0,0-.88-1.038Zm-.26.287a2.388,2.388,0,0,1,.793.935l.218-.1a2.755,2.755,0,0,0-.88-1.041Zm-.215.3a2.308,2.308,0,0,1,.738.908l.215-.1a2.515,2.515,0,0,0-.826-1l-.127.2Zm-.3.348a2.241,2.241,0,0,1,.717.883l.218-.1a2.465,2.465,0,0,0-.808-.986Z" transform="translate(-625.577 -152.621)" fill="#e6e6e6" />
        <path id="Path_35293" data-name="Path 35293" d="M843.01,112.62s3.259,3.4,4.039,3.664,1.57.118,1.985.275.8.3.844.871.169,1.126,0,1.377-.414,1.416-.584,1.561a.841.841,0,0,1-.832,0c-.336-.148-.505-.569-.935-.859s-.8-.266-1.21-.8a30.861,30.861,0,0,0-4.121-2.641C841.518,115.676,843.01,112.62,843.01,112.62Z" transform="translate(-776.722 -78.545)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35294" data-name="Path 35294" d="M889.458,24.91s-1.4-.532-2.118.245c-.908.974-2.393,1.21-3.961,1.292-1.44-.484-4.8-1.065-7.969,2.369L875.6,29a7.137,7.137,0,0,1,6.907-2.511c-1.867.082-3.725.145-4.759,1.21-.79.82-2.033,1.855-2.033,1.855a2.5,2.5,0,0,0,1.927-.23c2.263-1.168,2.983,3.141,7.752,1.28a8.951,8.951,0,0,0,1.107-.514c-.865.817-2.517,1.513-5.658,1.11l-.033.266c4.072.517,5.749-.762,6.432-1.861,1.392-1.08,1.192-2.16,1.573-2.581a1.573,1.573,0,0,1,.953-.578l-.3-1.54Z" transform="translate(-826.634 -17.249)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35295" data-name="Path 35295" d="M889.766,159.406s.741,6.91,0,8.6a4.171,4.171,0,0,1-2.914,2.741,11.78,11.78,0,0,0-5.144,3.933c-1.377,1.906-2.986,4.841-3.815,5.367a4.54,4.54,0,0,1-1.664.657,38.649,38.649,0,0,0,1.534,4.236c.272.239.817-.427,1.882-1.815a35.424,35.424,0,0,1,3.858-3.855c.508-.445,5.7-4.366,7.128-5.21s2.336,0,3.141-2.42,2.181-7.425,2.181-7.425l1.349-3.51-7.537-1.3Z" transform="translate(-834.413 -111.172)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35296" data-name="Path 35296" d="M876.23,159.42s.088.817.172,1.958a30.65,30.65,0,0,1,6.629,1.259l.735-1.915Z" transform="translate(-820.877 -111.186)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35297" data-name="Path 35297" d="M935.921,226.2a9.664,9.664,0,0,0,.8,2.557,4.4,4.4,0,0,0,.992,1.192l-1.8,2.048a9.772,9.772,0,0,1-1.243-3.262,8.4,8.4,0,0,0-.318-1.849Z" transform="translate(-892.416 -157.761)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35298" data-name="Path 35298" d="M897.044,135.46a41.4,41.4,0,0,0-.605,5.667c-.039,2.484.986,2.814.986,2.814a6.429,6.429,0,0,0,1.764,0c.517-.154-2.118-8.472-2.118-8.472Z" transform="translate(-842.48 -94.475)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35299" data-name="Path 35299" d="M896.487,160.85c.3.675.678.8.678.8a6.429,6.429,0,0,0,1.764,0c.088-.027.085-.284.021-.7A20.341,20.341,0,0,1,896.487,160.85Z" transform="translate(-842.235 -112.183)" fill="#db406a" fillRule="evenodd" />
        <path id="Path_35300" data-name="Path 35300" d="M849.87,153.648s3.168,6.024,4.753,9.107,2.118,2.42,1.894,4.085a14.5,14.5,0,0,0-.3,6.139,71.173,71.173,0,0,0,1.61,7.821,18.241,18.241,0,0,1,.545,2.941,8.868,8.868,0,0,0,.623,1.815l3.582-.886a33.607,33.607,0,0,1-1.582-6.081c-.26-2.43-.363-9.307-.339-10.305s.962-2.169.7-4.169-4.127-12.447-4.127-12.447-4.841-1.116-7.352,1.97Z" transform="translate(-791.709 -105.641)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35301" data-name="Path 35301" d="M864.6,153.669s.605,1.153,1.422,2.723c.414-.254.877-.566,1.395-.908a6.711,6.711,0,0,1,4.1-1.21,7.015,7.015,0,0,0,1.331-.109c-.523-1.455-.908-2.469-.908-2.469s-4.841-1.116-7.352,1.97Z" transform="translate(-806.438 -105.662)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35302" data-name="Path 35302" d="M864.56,130.517s-.33-.23-.33,2.511,2.118,8.169,2.75,8.239,1.649-.641,2.986-1.513a6.717,6.717,0,0,1,4.1-1.21,5.2,5.2,0,0,0,2.4-.445s-1.153-4.911-1.555-5.882-3.7-2.526-10.357-1.712Z" transform="translate(-809.951 -90.86)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35303" data-name="Path 35303" d="M868.562,130.546s-.224-.154-.3,1.307a15.4,15.4,0,0,0,5.676.511,9.443,9.443,0,0,1,5.319.953c-.133-.472-.251-.853-.336-1.059C878.516,131.29,875.219,129.732,868.562,130.546Z" transform="translate(-813.953 -90.889)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35304" data-name="Path 35304" d="M864.23,157.54c.151.224.278.354.375.363.65.073,1.649-.641,2.986-1.513a6.717,6.717,0,0,1,4.1-1.21,5.2,5.2,0,0,0,2.4-.445s-.054-.236-.145-.605a19.755,19.755,0,0,1-2.883.366,7.5,7.5,0,0,0-3.67,1.074c-.46.3-2.018,1.265-3.168,1.967Z" transform="translate(-807.576 -107.496)" fill="#db406a" fillRule="evenodd" />
        <path id="Path_35305" data-name="Path 35305" d="M938.619,227.064s.623-.411.765-.085-.384.956-.384,1.513a6.382,6.382,0,0,0,.826,2.526c.351.43,1.077.605,1.128.8s-.4.605-.88,1.34a8.024,8.024,0,0,0-.923,3.728c.115.914.239,1.552-.481,1.97a9.907,9.907,0,0,1-1.513-5.21,12.771,12.771,0,0,0-.248-4.054,7.359,7.359,0,0,1,.127-2.626,7.909,7.909,0,0,0,1.573.085Zm0,11.833a.338.338,0,0,1-.076.033l-.075.015.075-.015A.338.338,0,0,0,938.619,238.9Z" transform="translate(-896.409 -158.216)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35306" data-name="Path 35306" d="M947.506,238.416l-.051.027c-.778.415-1.961-1.912-2.206-3.268s-.469-4.369-.714-5.5a7.527,7.527,0,0,1,0-3.041c.154-.542.336-.523.935-.239a1.836,1.836,0,0,0,.411.127,7.359,7.359,0,0,0-.127,2.626,12.77,12.77,0,0,1,.248,4.054,9.746,9.746,0,0,0,1.513,5.21Z" transform="translate(-905.245 -157.763)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35307" data-name="Path 35307" d="M937.55,244.88a3.642,3.642,0,0,1,.227.587,1.425,1.425,0,0,1,.067.647.4.4,0,0,1-.209.3,2.162,2.162,0,0,0-.23.145.774.774,0,0,1-.13.085c-.03,0,.048-.021.048-.085a16.755,16.755,0,0,1,.227-1.688Zm-.7,2.526a7.156,7.156,0,0,1,.209-2.118c.06-.048.115-.1.163-.142l.021-.018a13.4,13.4,0,0,0-.184,1.44c0,.266.081.357.221.354a.547.547,0,0,0,.278-.13,1.828,1.828,0,0,1,.2-.127.665.665,0,0,0,.351-.5,1.676,1.676,0,0,0-.073-.775,3.927,3.927,0,0,0-.433-.974l-.154-.251v-.073h-.127c-.112,0-1.3,0-1.513.605a1.4,1.4,0,0,0-.073.587.53.53,0,0,0,.094.242.371.371,0,0,0,.2.148.468.468,0,0,0,.236,0,1.279,1.279,0,0,0,.457-.175,7.749,7.749,0,0,0-.142,1.9l.263-.018Zm.3-3.044a3.538,3.538,0,0,0-.327.768c-.051.039-.1.079-.157.115a1.128,1.128,0,0,1-.439.191.236.236,0,0,1-.115,0,.139.139,0,0,1-.069-.048.367.367,0,0,1-.042-.124,1.118,1.118,0,0,1,.06-.466c.109-.348.775-.418,1.089-.43Z" transform="translate(-893.231 -170.238)" fill="#db406a" />
        <path id="Path_35308" data-name="Path 35308" d="M848.08,252.94a5.383,5.383,0,0,0,2.287-.3c.808-.418,1.54-.908,1.54-.908l1.265,2.245a8.006,8.006,0,0,1-3.11.908,7.118,7.118,0,0,0-1.71.088l-.272-2.006Z" transform="translate(-781.765 -175.566)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35309" data-name="Path 35309" d="M863.009,48.725c-1.474,2.3-1.694,2.774-1.712,4.481,0,.605.572,3.422-.024,2.65l-3.842-4.989,2.178-4Z" transform="translate(-794.431 -32.689)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35310" data-name="Path 35310" d="M861.775,48.725a14.83,14.83,0,0,0-1.425,2.523,5.917,5.917,0,0,1-2.92-2.647l.944-1.731Z" transform="translate(-793.197 -32.689)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35311" data-name="Path 35311" d="M863.119,61.138s.3-.938,1.933-1.035c3.247-.194,2.756,3.537,4.036,4.348.2.127,0-1.44.151-2.033,0,0,1.21,2.847,1.467,3.77s1.328,3-.024,4.36a4.916,4.916,0,0,0-1.694,3.631,20.878,20.878,0,0,1-1.132,5.252,4.334,4.334,0,0,0-.175,2.75v1.54a8.194,8.194,0,0,0-3.455-1.256c-1.5,0-5.522-.082-6.75-.272s-1.676-.405-1.1-.95a7.1,7.1,0,0,0,1.694-2.8c.124-.787.366-1.634.5-2.257s1.092-3.652,1.192-4.484.221-2.191.221-2.191Z" transform="translate(-802.38 -41.913)" fill="#db406a" fillRule="evenodd" />
        <path id="Path_35312" data-name="Path 35312" d="M878.722,85.526v-.082c.1-.832.221-2.191.221-2.191l1.3-3.473a14.586,14.586,0,0,1,3.252,1.413,60.73,60.73,0,0,1-4.783,4.333Z" transform="translate(-821.354 -55.641)" fill="#c92c56" fillRule="evenodd" />
        <path id="Path_35313" data-name="Path 35313" d="M872.022,92.907l-.027.027a4.931,4.931,0,0,0-1.694,3.631,16.133,16.133,0,0,1-.287,2.118,14.15,14.15,0,0,1-2.59-.33c-1.228-.221-3.712-.136-4.284-.829s-.021-2.281.605-3.758,1.694-.717,3.691-1.319,1.57,0,2.681.469a3.3,3.3,0,0,0,1.918-.03ZM858.21,103.028a6.273,6.273,0,0,0,1.168-2.191q.03-.206.073-.408a12.263,12.263,0,0,0,4,2.75C865.568,103.872,861.816,104.253,858.21,103.028Z" transform="translate(-803.692 -64.3)" fill="#c92c56" fillRule="evenodd" />
        <path id="Path_35314" data-name="Path 35314" d="M829.846,256.94s-.287-.738.03-.847.808.551,1.322.629a5,5,0,0,0,2.39-.545c.436-.321.684-1.092.878-1.119s.5.523,1.083,1.147a6.44,6.44,0,0,0,3.228,1.513c.838,0,1.431-.045,1.706.8a7.682,7.682,0,0,1-4.9.908,9.845,9.845,0,0,0-3.685-.3,5.564,5.564,0,0,1-2.348-.5,10.227,10.227,0,0,0,.3-1.7Z" transform="translate(-763.471 -177.887)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35315" data-name="Path 35315" d="M876.052,65.7s-1.065-2.405-2.635-2.578-4.181,2.644-4.726,3.307-3.673,4.538-4.466,5.643-1.065,1.21-1,1.876,3.026,3.585,3.582,4.06,5.144,3.555,5.791,3.933a4.514,4.514,0,0,0,1.791.554,6.353,6.353,0,0,1,1.3.333c.372.127.581.064.644-.057a.671.671,0,0,0,.07-.191s.378-.1.442-.354a2.505,2.505,0,0,0,.06-.278.415.415,0,0,0,.409-.284.72.72,0,0,0,0-.469.756.756,0,0,0,.566-.5c.109-.408-.43-.793-.43-.793s.635-.073.539-.536-.545,0-1.253-.206a2.656,2.656,0,0,0-1.706-.185,6.063,6.063,0,0,1-1.915.529,11.272,11.272,0,0,1-4.082-3.709,27.6,27.6,0,0,1-1.513-2.339l4.148-3.059s2.517.13,3.631-1.513a4.308,4.308,0,0,0,.744-3.2Z" transform="translate(-811.177 -44.018)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35316" data-name="Path 35316" d="M874.139,70.077a6.34,6.34,0,0,0-.369-.687,4.947,4.947,0,0,1-1.513,2.947c-1.855,1.849-2.693,2.578-2.723,2.6l.215-.157s2.517.13,3.631-1.513a4.309,4.309,0,0,0,.744-3.2Z" transform="translate(-809.264 -48.395)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35317" data-name="Path 35317" d="M839.37,254.98a3.1,3.1,0,0,1,.605-.175,1.089,1.089,0,0,1,.578.015.4.4,0,0,1,.248.272,2.5,2.5,0,0,0,.1.269.62.62,0,0,1,.06.166c0,.027,0-.045-.06-.051a13.253,13.253,0,0,1-1.513-.5Zm2.566,1.767a5.447,5.447,0,0,1-1.7-.838,5.926,5.926,0,0,0,1.655.142l-.021-.269a5.3,5.3,0,0,1-2.021-.269v-.03a.324.324,0,0,0-.042-.064,7.973,7.973,0,0,0,1.059.324c.242.033.339-.033.36-.175a.632.632,0,0,0-.079-.3,1.9,1.9,0,0,1-.088-.236.645.645,0,0,0-.406-.451,1.34,1.34,0,0,0-.723-.03,3.326,3.326,0,0,0-.953.342l-.224.145.163.063H838.9v.127h0c-.018.127-.182,1.4.351,1.7a1.113,1.113,0,0,0,.518.163.468.468,0,0,0,.23-.054.4.4,0,0,0,.167-.166.605.605,0,0,0,.064-.284,1.423,1.423,0,0,0-.064-.411,5.89,5.89,0,0,0,1.734.841l.07-.263Zm-2.306-1.035.054.061a2.655,2.655,0,0,1,.133.3,1.379,1.379,0,0,1,.112.514.357.357,0,0,1-.03.154.132.132,0,0,1-.057.057.218.218,0,0,1-.106.024.851.851,0,0,1-.39-.13c-.3-.166-.266-.908-.236-1.262a2.717,2.717,0,0,0,.52.3Z" transform="translate(-767.737 -177.506)" fill="#db406a" />
        <path id="Path_35318" data-name="Path 35318" d="M874.523,25.565s-.874-.687-1.28-.581a1.591,1.591,0,0,0-.938,1.259c-.106.617,1.28.95,1.28.95A2.636,2.636,0,0,1,874.523,25.565Z" transform="translate(-810.445 -17.417)" fill="#db406a" fillRule="evenodd" />
        <path id="Path_35319" data-name="Path 35319" d="M857.552,23.5c1.117,1.685.251,2.312.251,2.312l-6.49,4.714s-2.118-.269-2.557-1.8c-1.815-6.514,6.272-9.053,8.807-5.228Z" transform="translate(-786.841 -15.139)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35320" data-name="Path 35320" d="M854.624,34.279c.675,1.97-.472,2.853-.082,3.752s.838.989.6,1.119a5.3,5.3,0,0,1-1.116.233s-.581,2.278-1.4,2.118c-1.688-.3-3.606-.941-4.505-2.52a5.467,5.467,0,0,1,.971-1.758s-.439.07-.763-.95c-.257-.811,1.147-2.042,1.331.726.03.448.5.775.52.572s.133-2.381.133-2.381.139-.215,1.446-.726c1.6-.626,2.342.024,2.859-.2Z" transform="translate(-783.819 -23.84)" fill="#fcd2ca" fillRule="evenodd" />
        <path id="Path_35321" data-name="Path 35321" d="M853.713,40.33c-.188,0-.351.215-.366.5s.118.536.3.548.351-.215.366-.505S853.9,40.339,853.713,40.33Z" transform="translate(-784.203 -28.127)" fill="#331f1b" fillRule="evenodd" />
        <path id="Path_35322" data-name="Path 35322" d="M853.6,37.671a1.513,1.513,0,0,0-2.027.357l.321.278a1.089,1.089,0,0,1,1.473-.278Z" transform="translate(-783.246 -26.104)" fill="#995749" fillRule="evenodd" />
        <path id="Path_35323" data-name="Path 35323" d="M854.636,40.813a.1.1,0,1,0,.121.051.091.091,0,0,0-.121-.051Z" transform="translate(-785.323 -28.459)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35324" data-name="Path 35324" d="M854.525,51.347a1.461,1.461,0,0,1-.956-.487l-.179.118a1.674,1.674,0,0,0,1.11.581Z" transform="translate(-784.724 -35.471)" fill="#995749" />
        <path id="Path_35325" data-name="Path 35325" d="M867.545,39.623c.024.354.109.605.2.493s.069-.4.182-.336.03.418.191.569.3-.569-.033-.98C867.835,39.066,867.515,39.23,867.545,39.623Z" transform="translate(-802.77 -27.348)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35326" data-name="Path 35326" d="M855.233,25.89s3.3-1.177,5.255.454l.142-.175c-2.054-1.716-5.446-.5-5.47-.493Z" transform="translate(-791.365 -17.611)" fill="#b8886c" />
        <path id="Path_35327" data-name="Path 35327" d="M886.4,29.215a4.4,4.4,0,0,0-.436,4.318l.2-.1a4.124,4.124,0,0,1,.411-4.078Zm-2.466-.605a6.088,6.088,0,0,1-4.538,1.567c-3.28,0-3.979.584-3.982.587l.166.209s.626-.526,3.815-.526a6.33,6.33,0,0,0,4.729-1.655l-.194-.182Zm.75,1.074s-1.132,3.168-5.268,2.487l-.039.266c4.351.717,5.552-2.647,5.555-2.659Z" transform="translate(-823.436 -19.953)" fill="#b8886c" />
        <path id="Path_35328" data-name="Path 35328" d="M861.028,29.175a4.06,4.06,0,0,1,3.552,1.144l.151-.166a4.269,4.269,0,0,0-3.752-1.21l.048.221Z" transform="translate(-797.228 -20.143)" fill="#b8886c" />
        <path id="Path_35329" data-name="Path 35329" d="M866.571,64.309s.8,3.582,2.257,4.75l.163-.212c-1.377-1.107-2.16-4.59-2.163-4.6Zm-.024-.908a2.774,2.774,0,0,0-3.41-2.157l.064.26a2.506,2.506,0,0,1,3.08,1.958Zm-.484,11.648s-4.351-.133-6.493-1.961l-.169.209c2.209,1.885,6.656,2.021,6.656,2.021V75.05Zm3.065-5.458s-1.452,2.953-8.523,2.8v.269c7.237.157,8.759-2.935,8.774-2.944l-.236-.124Z" transform="translate(-801.396 -42.642)" fill="#f05d84" />
        <path id="Path_35330" data-name="Path 35330" d="M879.735,125.486a35.1,35.1,0,0,0-7.867-3.616l-.07.26a34.787,34.787,0,0,1,7.794,3.585l.139-.23Zm-9.355-1.558a10.006,10.006,0,0,0,4.157.632l-.03-.269a9.8,9.8,0,0,1-4.033-.605l-.094.254Z" transform="translate(-815.075 -84.997)" fill="#f05d84" />
        <path id="Path_35331" data-name="Path 35331" d="M877.436,138.976a8.413,8.413,0,0,0-5.634-1.891v.269a8.074,8.074,0,0,1,5.446,1.815l.182-.2Zm-9.434-2.4a13.058,13.058,0,0,0,1.071,5.516l.233-.133a12.75,12.75,0,0,1-1.038-5.376H868Zm9.165,3.4s-4.36-1.189-7.022.269l.124.239c2.572-1.41,6.817-.251,6.829-.245l.07-.263Zm-.058-2.587a8.259,8.259,0,0,0-5.286-2.4l-.018.272a7.957,7.957,0,0,1,5.119,2.3l.2-.182Z" transform="translate(-812.056 -94.154)" fill="#2b2b2b" />
        <path id="Path_35332" data-name="Path 35332" d="M862.8,196.9s-.43.823-1.982.539l-.048.263c1.743.321,2.257-.666,2.26-.669l-.23-.133Z" transform="translate(-795.463 -137.326)" fill="#e69f91" />
        <path id="Path_35333" data-name="Path 35333" d="M899.79,194.8a1.858,1.858,0,0,1,1.38,1.086l.239-.115a2.145,2.145,0,0,0-1.585-1.24Z" transform="translate(-845.647 -135.673)" fill="#db8777" />
        <path id="Path_35334" data-name="Path 35334" d="M900.055,95.624a3.932,3.932,0,0,0-1.655-.454v.269a3.63,3.63,0,0,1,1.531.421l.13-.236Z" transform="translate(-843.879 -66.375)" fill="#e69f91" />
        <path id="Path_35335" data-name="Path 35335" d="M840.785,266.523l.018.061c.266.908-1.994,1.867-3.25,1.945s-4-.1-5.05,0a5.749,5.749,0,0,1-2.738-.424c-.469-.245-.427-.442-.088-1.053a2.34,2.34,0,0,0,.172-.43,5.6,5.6,0,0,0,2.348.5,9.6,9.6,0,0,1,3.685.3,7.72,7.72,0,0,0,4.9-.908Z" transform="translate(-763.771 -185.882)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35336" data-name="Path 35336" d="M888.139,75.7l-1.849-1.852a.384.384,0,0,0-.542,0l-3.631,3.558a.378.378,0,0,0,0,.536l1.849,1.852a.384.384,0,0,0,.539,0l3.631-3.558a.381.381,0,0,0,0-.536Z" transform="translate(-827.103 -51.43)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35337" data-name="Path 35337" d="M888.615,76.622a.076.076,0,0,1,0,.106l-3.631,3.558a.073.073,0,0,1-.106,0l-1.849-1.849a.075.075,0,0,1,0-.106l3.631-3.561a.076.076,0,0,1,.106,0Z" transform="translate(-827.798 -52.132)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35338" data-name="Path 35338" d="M889.279,79.232s-1.815-3.08-3.652-3.392a28.812,28.812,0,0,0-1.967,2.42s2.526,1.259,3.247,2.808C888.059,80.276,889.279,79.232,889.279,79.232Z" transform="translate(-828.637 -52.893)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35339" data-name="Path 35339" d="M405.95,48.58l.49.475.529-.9-.215-.52-.8.95Z" transform="translate(-201.792 -33.219)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35340" data-name="Path 35340" d="M395.851,105.132a7.5,7.5,0,0,1,.605,1.086c.236.533-.175,1.02-.85,1.455a5.625,5.625,0,0,1-1.988.563v-2.42s2.284-.678,2.224-.7Z" transform="translate(-187.615 -73.314)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35341" data-name="Path 35341" d="M405.235,27.172a6.935,6.935,0,0,0-3.116-1.513,4.439,4.439,0,0,0-4.3,1.513,3.248,3.248,0,0,0-.36.647,9.209,9.209,0,0,0-1.858,1.864c-2.29,3-4.511,2.175-4.517,2.175l-.088.272s2.42.89,4.826-2.269a9.7,9.7,0,0,1,1.422-1.513,10.147,10.147,0,0,1-1.138,2.339,7.52,7.52,0,0,1-4.617,2.118v.3a6.777,6.777,0,0,0,2.974-.88c-1.41.926-2.566,1.059-2.723,1.982a2.629,2.629,0,0,0,1.782,3.026,5.183,5.183,0,0,0,.605.175,3.755,3.755,0,0,1-2.23-.908l-.172.23a3.631,3.631,0,0,0,3.434.826c1.21.1,2.378,0,2.629-.345,1.355-1.951,1.008-3.177,1.685-4.236.148-.233.587-.275,1.086-.578.953-.575,2.7-.908,2.95-1.688.381-1.177.414-2.52.811-2.723s1-.472,1-.472l-.109-.345Z" transform="translate(-195.629 -17.835)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35342" data-name="Path 35342" d="M401.547,51.734s-1.02,0-1.676,1.873a22.92,22.92,0,0,0-.88,3.08l-4.19-2.663a15.1,15.1,0,0,0,2.354-2.554c.6-1.023.826-1.47.826-1.47Z" transform="translate(-192.996 -34.872)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35343" data-name="Path 35343" d="M399.7,53.357a2.737,2.737,0,0,0-1.371,1.8c-.215.605-.384,1.156-.514,1.61-.732-1.253-1.383-2.8-2.006-4.085.163-.275.191-.384.245-.469Z" transform="translate(-191.456 -36.413)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35344" data-name="Path 35344" d="M388.882,32a3.177,3.177,0,0,1,1.029,2.684c-.2,1.679,1.461,2.227,1.1,2.445a4.813,4.813,0,0,1-.8.372c0,.045-.908,2.986-1.564,2.986h-.063c-.726.166-4.085-1.658-4.79-2.375a2.555,2.555,0,0,1,.542-.886c-.98-1.48-.045-2.466.408-2.118.357.263.563.926,1.089.944s.732-1.138.968-1.513.13-1.18.566-1.852A2.042,2.042,0,0,1,388.882,32Z" transform="translate(-179.182 -22.318)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35345" data-name="Path 35345" d="M389.751,42.43c-.176,0-.321.194-.321.43s.145.427.321.427.318-.191.318-.427S389.926,42.43,389.751,42.43Z" transform="translate(-179.893 -29.592)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35346" data-name="Path 35346" d="M390.058,40.386a1.282,1.282,0,0,0-1.858.333,1.855,1.855,0,0,1,1.591-.151Z" transform="translate(-179.51 -27.98)" fill="#342d6e" />
        <path id="Path_35347" data-name="Path 35347" d="M444.662,144.31a33.8,33.8,0,0,0-1.334,5.845c-.433,3.24-.018,8.986-.908,9.888s-8.774,4.1-12.317,5.676-4.2.977-4.411,1.628a1.83,1.83,0,0,0,1.256,2.475c1.473.257,16.744-3.7,18.129-4.841s3.495-5.143,4.445-10.441-4.811-10.332-4.865-10.214Z" transform="translate(-250.392 -100.647)" fill="#2d3a4a" fillRule="evenodd" />
        <path id="Path_35348" data-name="Path 35348" d="M376.52,126.882a9.833,9.833,0,0,0-1.02,6.508c.7,3.746,6.015,10.7,7.416,12.707a66.211,66.211,0,0,1,4.439,8.874c.656,1.628.883,3.97,2.768,7.385a31.713,31.713,0,0,1,2.744,7.013l3.882-1.3a129.366,129.366,0,0,1-3.788-12.471,38.274,38.274,0,0,0-2.384-7.867c-.417-.929-4.56-12.949-4.983-14.114s.393-2.771.605-3.452-9.764-3.228-9.682-3.292Z" transform="translate(-182.307 -88.484)" fill="#3d4a5a" fillRule="evenodd" />
        <path id="Path_35349" data-name="Path 35349" d="M410.9,126.91a8.074,8.074,0,0,0-.711,1.661c3.5.965,7.331,2.345,9.821,3.289.175-.678.457-1.3.569-1.658.209-.681-9.764-3.228-9.682-3.292Z" transform="translate(-216.688 -88.512)" fill="#2d3a4a" fillRule="evenodd" />
        <path id="Path_35350" data-name="Path 35350" d="M397.853,64.39s1.461-2.42,2.293-2.669a2.438,2.438,0,0,1,2.172,0,18.155,18.155,0,0,1,3.1,2.139c1.752,1.543,2.917,4.886,3.089,7.346.133,1.921-1.737,2.593-1.939,3.277s-2.26,6.544-2.569,7.906.045,1.906-.405,2.439-2.5-.862-3.721-1.141-5.682-1.558-6.372-1.849-.7-.663-.136-1.876a41.3,41.3,0,0,0,1.951-3.985c.169-.775.88-9.077,2.542-11.6Z" transform="translate(-199.401 -42.865)" fill="#a60c00" fillRule="evenodd" />
        <path id="Path_35351" data-name="Path 35351" d="M407.467,78.559c-.605,1.764-1.706,2.514-1.906,3.186-.07.236-.36,1.092-.726,2.169-5.485,1.694-8.326,1.044-8.886-1.113-.581-2.236.215-5.546.581-7.985s1.646-2.956,1.646-2.956l-.666,4.786s3.068,1.942,3.28,1.942a6.656,6.656,0,0,0,3.292-.07,7.952,7.952,0,0,1,3.389.039ZM393.089,85.8a14.333,14.333,0,0,0,1.21-2.557c.051-.248.163-1.271.342-2.629.52.578.514,2.572.717,3.573.233,1.135.345,1.486,3.407,2.747s2.874,2.82,2.033,2.187-3.162-1.056-5.67-2.036A6.133,6.133,0,0,1,393.08,85.8Z" transform="translate(-198.398 -50.118)" fill="#821008" fillRule="evenodd" />
        <path id="Path_35352" data-name="Path 35352" d="M375.585,85.258a31.531,31.531,0,0,0-.366,5.773c.175,2.33,4.6,1.467,6.1,1.014s6.051-1.958,6.8-2.336,2.076.481,2.578-1.21.127-3.077-.539-3.259-.947-.079-1.116-.415-.605-.324-.908.021-.523,1.688-.974,2.139-.811.605-2.753.862-5.28.908-5.28.908l.215-2.777s-3.758-.747-3.755-.729Z" transform="translate(-176.438 -58.99)" fill="#f0b0a3" fillRule="evenodd" />
        <path id="Path_35353" data-name="Path 35353" d="M413.694,86.819s-.115.732-.224,1.749l3.885.227.094-1.247S413.691,86.8,413.694,86.819Z" transform="translate(-214.547 -60.551)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35354" data-name="Path 35354" d="M410.838,65.215c4.251-1.186,3.289,5.195,3.01,7.673l-5.534-.327C408.178,70.391,408.287,65.926,410.838,65.215Z" transform="translate(-209.666 -45.381)" fill="#c0392b" fillRule="evenodd" />
        <path id="Path_35355" data-name="Path 35355" d="M414.6,65.215l.218-.054-.027.136a46.105,46.105,0,0,0-.632,7.388l-.354-.021a46.318,46.318,0,0,1,.632-7.4Zm.569-.118a2.575,2.575,0,0,1,.363-.027l-.03.16a46.035,46.035,0,0,0-.632,7.5l-.354-.021a46.068,46.068,0,0,1,.638-7.537Zm.717,0a1.987,1.987,0,0,1,.345.079l-.015.094a45.4,45.4,0,0,0-.632,7.506l-.357-.021a46.057,46.057,0,0,1,.641-7.552l.018-.112Z" transform="translate(-213.424 -45.382)" fill="#ec7063" fillRule="evenodd" />
        <path id="Path_35356" data-name="Path 35356" d="M390.568,42.84c-.048,0-.088.051-.088.118s.039.118.088.118.088-.054.088-.118S390.616,42.84,390.568,42.84Z" transform="translate(-180.798 -29.878)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35357" data-name="Path 35357" d="M402.425,43.781s.169-.49.529-.418.868,1.319.7,1.558-.5-.321-.726-.061-.372-.69-.07-.69.1-.505-.094-.3-.336-.073-.336-.073Z" transform="translate(-197.442 -30.238)" fill="#e69f91" fillRule="evenodd" />
        <path id="Path_35358" data-name="Path 35358" d="M393.3,29.492a3.1,3.1,0,0,0-2.185,1.044,10.358,10.358,0,0,1-2.26,2.006c-.178-.16-.269-.451-.65-.7,0,0,1.086-1.918,1.528-2.841a5.143,5.143,0,0,1,1.295-1.591A5.49,5.49,0,0,1,393.3,29.492Z" transform="translate(-182.747 -19.116)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35359" data-name="Path 35359" d="M408.474,28.884a4.435,4.435,0,0,0-4.333,2.835,6.843,6.843,0,0,1-3.392,3.839l.106.269a7.281,7.281,0,0,0,3.546-4.012,4.145,4.145,0,0,1,4.054-2.653l.018-.3Z" transform="translate(-201.724 -20.128)" fill="#5b50ab" />
        <path id="Path_35360" data-name="Path 35360" d="M412.444,37.74c0,.018-2.8,6.883-6.372,6.305l-.042.284c3.791.605,6.656-6.46,6.674-6.478Z" transform="translate(-207.551 -26.321)" fill="#5b50ab" />
        <path id="Path_35361" data-name="Path 35361" d="M404.323,90.083a17.288,17.288,0,0,0-8-2.381v.233a16.868,16.868,0,0,1,7.827,2.333l.179-.185Zm4.841-13.134a15.081,15.081,0,0,0-2.9-5.419l-.212.2a14.619,14.619,0,0,1,2.829,5.295Zm-11.1,4.917a6.436,6.436,0,0,0,3.044,4.091l.157-.2a6.163,6.163,0,0,1-2.914-3.933l-.3.042Zm-.587,2.971s1.225,1.973,5.594,3.292l.1-.218c-4.263-1.289-5.446-3.174-5.446-3.18l-.26.106Z" transform="translate(-201.073 -49.887)" fill="#e84638" />
        <path id="Path_35362" data-name="Path 35362" d="M387.04,149.592c.021.021,7.479,7.972,8.862,14.32l.287-.07c-1.4-6.429-8.919-14.438-8.941-14.462Zm9.582,20.02c0,.015,3.894,5.794,4.7,9.6l.287-.067c-.82-3.864-4.735-9.682-4.744-9.709l-.242.172Z" transform="translate(-190.71 -104.183)" fill="#556373" fillRule="evenodd" />
        <path id="Path_35363" data-name="Path 35363" d="M448.646,182.752a42.56,42.56,0,0,1-10.414,2.644l.036.3a43.4,43.4,0,0,0,10.487-2.666l-.109-.278Zm.284-2.926s1.259.79,1.177,1.555l.272.027c.1-.932-1.3-1.815-1.3-1.815l-.148.227Zm1.915-7.582s1.38,4.384.5,7.47l.278.088c.908-3.18-.5-7.64-.505-7.652l-.275.094Z" transform="translate(-256.521 -120.064)" fill="#3d4a5a" fillRule="evenodd" />
        <path id="Path_35364" data-name="Path 35364" d="M387.71,93.855l.874,3.062.88-.245-.88-3.062-.874.245Z" transform="translate(-178.769 -65.287)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35365" data-name="Path 35365" d="M388.817,95.762l-1,.3a.194.194,0,0,0-.13.236l.4,1.34a.191.191,0,0,0,.239.127l.992-.3a.191.191,0,0,0,.13-.236l-.39-1.34a.191.191,0,0,0-.236-.127Z" transform="translate(-178.754 -66.782)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35366" data-name="Path 35366" d="M389.76,97.787l-.932.272-.378-1.277.932-.272.378,1.277Z" transform="translate(-179.288 -67.31)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35367" data-name="Path 35367" d="M389.76,97.787l-.932.272-.378-1.277.932-.272.378,1.277Z" transform="translate(-179.288 -67.31)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35368" data-name="Path 35368" d="M395.16,31.821a3.519,3.519,0,0,0-1.849,1.295,15.027,15.027,0,0,1-1.722,1.87l.166.2a15.268,15.268,0,0,0,1.758-1.909,3.234,3.234,0,0,1,1.694-1.21l-.057-.269Z" transform="translate(-185.687 -22.178)" fill="#1f76b8" />
        <path id="Path_35369" data-name="Path 35369" d="M407.529,45.682l-.859,1.3.5.572.605-1.11a2.445,2.445,0,0,1-.245-.772Z" transform="translate(-202.812 -31.852)" fill="#67abe0" fillRule="evenodd" />
        <path id="Path_35370" data-name="Path 35370" d="M389.86,51.082s-.43.233-1.328-.542l-.182.209c1.044.908,1.643.575,1.643.575Z" transform="translate(-179.491 -35.248)" fill="#db8777" />
        <path id="Path_35371" data-name="Path 35371" d="M378.442,256.092s.021-.711-.327-.735-1.292.272-1.21.442a4.783,4.783,0,0,0,.762.717,1.948,1.948,0,0,1,.787-.424Z" transform="translate(-164.488 -178.095)" fill="#a62014" fillRule="evenodd" />
        <path id="Path_35372" data-name="Path 35372" d="M357.127,255.659s-.4-.236-.889.672-.5,1.1-1.168,1.392-1.331-.508-1.564-.436-.354,1-.191,2.088.514,1.289.514,1.289h0a4.816,4.816,0,0,0,1.3-.363c1.788-.778,3.685-1.141,6.051-2.042a8,8,0,0,0,3.328-2.593h0a1.031,1.031,0,0,0-.266-.526c-.635-.678-1.737,0-2.6.3s-.88-.206-4.52.209Z" transform="translate(-143.388 -177.741)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35373" data-name="Path 35373" d="M358.869,258.523l.142-.1Zm1.87-2.723a1.031,1.031,0,0,0-.266-.526.929.929,0,0,0-.327-.224c-2.348,1.237-7.788,4.181-6.783,4.372a3.282,3.282,0,0,1,.484.136c1.083-.33,2.251-.666,3.558-1.165a8.005,8.005,0,0,0,3.328-2.593Z" transform="translate(-139.614 -177.882)" fill="#a62014" fillRule="evenodd" />
        <path id="Path_35374" data-name="Path 35374" d="M372,266.11v.327A11.773,11.773,0,0,1,377.189,268c.121-.054.257-.109.405-.16A12.245,12.245,0,0,0,372,266.11Z" transform="translate(-162.145 -185.596)" fill="#f7c96c" fillRule="evenodd" />
        <path id="Path_35375" data-name="Path 35375" d="M353.233,262.541a.714.714,0,0,0,.218.844c.442.327,3.383-.908,4.36-1.349s2.835-.726,4.454-1.936c1.262-.932,1.782-1.87,1.652-2.56h0a8.006,8.006,0,0,1-3.328,2.593c-2.36.908-4.257,1.265-6.051,2.042a4.812,4.812,0,0,1-1.3.363Z" transform="translate(-142.792 -179.619)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35376" data-name="Path 35376" d="M369.7,256.46a1.05,1.05,0,0,0-.566.887h.2a.838.838,0,0,1,.442-.7l-.079-.172Zm-2.293.091a1.056,1.056,0,0,0-.563.883h.2a.838.838,0,0,1,.442-.7l-.082-.172Zm.714-.024a1.062,1.062,0,0,0-.563.887h.2a.832.832,0,0,1,.442-.7l-.082-.175Zm.781,0a1.05,1.05,0,0,0-.566.883h.2a.832.832,0,0,1,.442-.7l-.079-.172Z" transform="translate(-152.764 -178.865)" fill="#f7c96c" />
        <path id="Path_35377" data-name="Path 35377" d="M372.137,254.208a1.374,1.374,0,0,1,.759-.369,4.308,4.308,0,0,1-.759.369Zm-.887,2.363c.814.112.127-1.495.048-1.67,1.047-.3,1.38.908,1.383.908l.194-.064s-.254-.926-1.017-1.068a3,3,0,0,1,1.4-.312l.051-.194a2.468,2.468,0,0,0-.959.151c.514-.26,1.053-.59.605-.663s-1.4.829-1.407.832c-.605.581-1.113,1.976-.281,2.094Zm.042-.194c-.4-.058-.218-.781-.088-1.174C371.369,255.615,371.65,256.414,371.293,256.377Z" transform="translate(-157.505 -176.91)" fill="#f7c96c" />
        <path id="Path_35378" data-name="Path 35378" d="M491.161,227.627s.675.245.808-.079.142-1.3-.039-1.289a4.683,4.683,0,0,0-.926.5,1.873,1.873,0,0,1,.157.871Z" transform="translate(-312.624 -157.802)" fill="#a62014" fillRule="evenodd" />
        <path id="Path_35379" data-name="Path 35379" d="M497.093,219.072s.348-.3-.366-1.05-.908-.817-.962-1.534.908-1.1.908-1.337-.844-.647-1.936-.835-1.395.079-1.395.079h0a4.9,4.9,0,0,0-.061,1.343c.185,1.927-.067,3.827.058,6.336a7.906,7.906,0,0,0,1.434,3.954h0a1.053,1.053,0,0,0,.584-.088c.85-.384.545-1.637.52-2.545s.475-.762,1.21-4.324Z" transform="translate(-318.417 -149.425)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35380" data-name="Path 35380" d="M498.25,231.723c.018.057.036.112.052.166Zm2.015,2.617a1.053,1.053,0,0,0,.584-.088.85.85,0,0,0,.318-.236c-.445-2.6-1.555-8.65-2.051-7.761a3.2,3.2,0,0,1-.281.411c-.024,1.126-.073,2.327,0,3.718a7.906,7.906,0,0,0,1.434,3.954Z" transform="translate(-323.916 -157.738)" fill="#a62014" fillRule="evenodd" />
        <path id="Path_35381" data-name="Path 35381" d="M501.128,215.17c-.1-.033-.2-.07-.3-.1a11.6,11.6,0,0,1-3.126,4.393c0,.133.021.278.027.433a12.023,12.023,0,0,0,3.41-4.726Z" transform="translate(-323.72 -149.998)" fill="#f7c96c" fillRule="evenodd" />
        <path id="Path_35382" data-name="Path 35382" d="M502.4,214.32a.729.729,0,0,0-.874-.061c-.451.3-.2,3.467-.076,4.538s-.191,2.9.448,4.8c.5,1.48,1.231,2.266,1.933,2.36h0A7.905,7.905,0,0,1,502.4,222c-.124-2.508.127-4.408-.058-6.336a4.9,4.9,0,0,1,.061-1.343Z" transform="translate(-327.497 -149.351)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35383" data-name="Path 35383" d="M494.466,233.555a1.056,1.056,0,0,0-.672-.808l-.055.191a.838.838,0,0,1,.533.638Zm.632-2.188a1.059,1.059,0,0,0-.672-.808l-.054.194a.829.829,0,0,1,.535.638Zm-.2.681a1.059,1.059,0,0,0-.672-.808l-.054.191a.835.835,0,0,1,.536.638Zm-.236.735a1.059,1.059,0,0,0-.672-.808l-.054.194a.829.829,0,0,1,.533.638Z" transform="translate(-316.483 -160.802)" fill="#f7c96c" />
        <path id="Path_35384" data-name="Path 35384" d="M493.447,229.377a1.343,1.343,0,0,1,.115.832A4.191,4.191,0,0,1,493.447,229.377Zm-1.982-1.576c-.363.732,1.389.59,1.582.572-.033,1.083-1.295,1.017-1.3,1.017v.2a1.573,1.573,0,0,0,1.343-.605,3.112,3.112,0,0,1-.133,1.413l.169.106a2.3,2.3,0,0,0,.157-.95c.088.569.236,1.177.445.772s-.351-1.582-.351-1.585c-.369-.75-1.543-1.67-1.915-.926Zm.172.1c.179-.36.814.039,1.15.284C492.34,228.212,491.477,228.224,491.638,227.9Z" transform="translate(-314.459 -158.695)" fill="#f7c96c" />
        <path id="Path_35385" data-name="Path 35385" d="M103.77,145.07s-2.42,10.453-3.846,10.487c-10.087.239-14.011,4.908-18.916,4.765-2.723-.082-1.888,6.441-1.888,6.441a20.6,20.6,0,0,1,4.786-3.277c2.251-.941,14.71-1.725,18.456-3.243s6.523-10.133,6.523-10.133l-5.1-5.041Z" transform="translate(195.212 -101.177)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35386" data-name="Path 35386" d="M80.09,145.07s-.439,1.891-1.05,4.066a23.932,23.932,0,0,0,2.333,1.691c.829.539,2.033,1.377,2.823,1.93.638-1.513,1-2.647,1-2.647Z" transform="translate(218.893 -101.177)" fill="#734522" fillRule="evenodd" />
        <path id="Path_35387" data-name="Path 35387" d="M17.563,79.117a50.386,50.386,0,0,0,6.16-.448c.841-.312,1.752-2.481,2.381-2.469s.669.765.632.908,1.87-.853,2.03-.061,1.455,2.935-.369,3.882-3.982-.088-4.841.351S17.233,82.4,17.233,82.4Z" transform="translate(293.426 -53.144)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35388" data-name="Path 35388" d="M19.71,156.712s5.867,7.373,5.8,8.562c-.493,8.493,4.236,14.874,5.165,22.468a2.013,2.013,0,0,0,2.511.865c1.6-.466,3.21-3.253,3.21-3.253l-2.178-1.331a38.122,38.122,0,0,1-2.242-8.922c-.605-4.953-.514-8.39-1.21-11.518s-3.631-8.393-3.631-8.393H22.618A5.146,5.146,0,0,0,19.71,156.7Z" transform="translate(285.644 -108.235)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35389" data-name="Path 35389" d="M46.88,156.719s1.047,1.316,2.251,2.926a2.4,2.4,0,0,0,1.383-1.764c.027-.354.89.8,2.254.182a17.445,17.445,0,0,1,2.575-.877c-.59-1.174-1.044-1.985-1.044-1.985H49.788a5.146,5.146,0,0,0-2.908,1.513Z" transform="translate(258.474 -108.242)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35390" data-name="Path 35390" d="M75.139,127.83s-1.688,6.145-2.384,6.78,1.939,2.442,3.259,3.307,3.631,2.5,3.631,2.5l1.815-5.749-6.32-6.826Z" transform="translate(224.563 -89.153)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35391" data-name="Path 35391" d="M47.986,111.122a6.349,6.349,0,0,0-1.483,4.7c.091,3.219,5.6,12.1,6.417,12.1s3.119-.651,3.328-1.053a7.856,7.856,0,0,0,.3-1.815s.772,1.237,1.377,1.362,1.135-.3,1.936-.433l2.118-.342s-2.557-5.791-2.617-7.216-.729-5.4-.729-5.4S50.6,112.121,48,111.11Z" transform="translate(251.949 -77.492)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35392" data-name="Path 35392" d="M59.383,126.6a17.026,17.026,0,0,0,8.547,5.144l.079-.3a16.647,16.647,0,0,1-8.384-5.035l-.242.2Zm8.72-.654s-.971-1.722-4.417-1.7c-3.3.03-4.6-.759-4.6-.762l-.157.272s1.371.841,4.762.808c3.259-.03,4.145,1.531,4.148,1.537l.266-.163Zm-9.313,2.293c0,.015,3.237,5.588,5.528,6.317l.091-.3c-2.175-.693-5.34-6.16-5.349-6.175Z" transform="translate(242.111 -86.12)" fill="#5766c2" fillRule="evenodd" />
        <path id="Path_35393" data-name="Path 35393" d="M60.087,131.169a4.167,4.167,0,0,0,.393-.175,13.557,13.557,0,0,1-1.28-3.652,5.642,5.642,0,0,0-.965-2.529,1.822,1.822,0,0,1-.669-1.286,7.261,7.261,0,0,0-.3-1.1,4.622,4.622,0,0,1-.027-2.118,3.428,3.428,0,0,0,.03-1.374c-.23-.7-.811-2.293-.814-2.3l-.393.166s.587,1.6.811,2.281a3.283,3.283,0,0,1-.051,1.159,4.935,4.935,0,0,0,.054,2.339,7.579,7.579,0,0,1,.281,1.029,2.171,2.171,0,0,0,.838,1.558,6.224,6.224,0,0,1,.8,2.275,14.427,14.427,0,0,0,1.295,3.709Zm3.141-.956c.133-.042.272-.094.418-.145a30.555,30.555,0,0,1-2.375-4.057,7.618,7.618,0,0,0-.605-1.365,9.379,9.379,0,0,1-1.21-3.358,5.591,5.591,0,0,1,.154-1.383,1.334,1.334,0,0,0-.03-.971,22.461,22.461,0,0,0-1.177-2.1l-.348.254a22.274,22.274,0,0,1,1.141,2.027.983.983,0,0,1,0,.684,6.033,6.033,0,0,0-.166,1.489,9.657,9.657,0,0,0,1.265,3.57,7.467,7.467,0,0,1,.557,1.25,27.97,27.97,0,0,0,2.369,4.106Z" transform="translate(247.394 -81.349)" fill="#f5be51" fillRule="evenodd" />
        <path id="Path_35394" data-name="Path 35394" d="M57.978,111.15a5.353,5.353,0,0,0-1.268,2.517c1.362.605,7.516.835,9.413,1.107a7.039,7.039,0,0,0,2.762-.036c-.145-.944-.266-1.67-.266-1.67S60.583,112.161,57.978,111.15Z" transform="translate(241.958 -77.52)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35395" data-name="Path 35395" d="M56.359,20.38s.061,3.785-.8,5.029a8.118,8.118,0,0,0-.908,1.44L60.6,28.936s-.726-3.785-.151-4.986S56.359,20.38,56.359,20.38Z" transform="translate(250.868 -14.213)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35396" data-name="Path 35396" d="M55.148,20.38a21.835,21.835,0,0,1-.16,2.823,20.54,20.54,0,0,0,2.581,2.021,8.747,8.747,0,0,0,1.461.811,5.094,5.094,0,0,1,.206-2.082C59.81,22.743,55.148,20.38,55.148,20.38Z" transform="translate(252.079 -14.213)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35397" data-name="Path 35397" d="M49.608,5.423c-.127.983,1.483,2.508,1.177,2.784a1.829,1.829,0,0,1-1.047.236,3.671,3.671,0,0,1-.632,2.375c-.732.7-2.26.3-3.933-.817A17.085,17.085,0,0,1,42.337,7.72a4.242,4.242,0,0,1-1.9-4.045C41.07-1,50.407-.806,49.608,5.423Z" transform="translate(264.957 -0.298)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35398" data-name="Path 35398" d="M51.084,7.6a4.629,4.629,0,0,1-1.876-4.221c.693-5.14,8.623-3.1,7.649-2.275a5.667,5.667,0,0,1-1.274.738c-.236.106,1.062,1.008.378,1.579s-1.582.469-1.582,1.274.563,1.189.281,1.473a.759.759,0,0,1-1.274-.345c-.224-.753-.753-1.513-1.555-1.159-.605.266-.563,1.168-.194,1.761.418.66,1.083.478.98.772s-.626.75-1.534.4Z" transform="translate(256.185 0.002)" fill="#5b2809" fillRule="evenodd" />
        <path id="Path_35399" data-name="Path 35399" d="M61.128,45.352a33.6,33.6,0,0,0-2.181-4.641A38.879,38.879,0,0,1,53.67,38.13c-3.053.735-4.656,1.495-4.656,3.591,0,2.048-.542,6.018-.542,6.018.115,1.017,1.247,5.851.366,8.054s-2.3,4.687-2.605,5.513,7.494,1.065,9.682,1.368,3.631,0,3.649-.908a18.776,18.776,0,0,1,.605-6.684c1-3.724,1.815-8.59.983-9.742Z" transform="translate(252.52 -26.593)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35400" data-name="Path 35400" d="M65.935,52.32c-.088,2.136-.523,5.328-.523,5.328.115,1.017,1.247,5.851.366,8.057-.481,1.21-1.122,2.487-1.658,3.54,2.868,1.773,5.555,1.952,5.31,1.274a24.536,24.536,0,0,1-.883-4.075c-.1-1.086.1-2.009,1.9-1.21s3.443-.024,1.458-1.1a6.849,6.849,0,0,1-3.246-3.246c-.221-.717,3.5.7,3.349,0s-2.678-2.139-2.066-2.554,3.7.415,2.974,0-3.9-2.148-3.561-2.965a15.376,15.376,0,0,0,.587-2.659l-4-.381Z" transform="translate(235.58 -36.49)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35401" data-name="Path 35401" d="M57.058,65.593s2.451,5.576,7.156,6.98l.091-.3c-4.569-1.362-6.959-6.8-6.959-6.811l-.284.127Zm3.4-3.879a11.494,11.494,0,0,0,4.92,2.672l.061-.3a11.145,11.145,0,0,1-4.774-2.593l-.212.224Zm5.349,12.956s-.46,1.918-10.8,1.815v.3c10.59.115,11.1-2.021,11.1-2.027l-.3-.085Zm-8.72-4.1a15.688,15.688,0,0,1,3.026,4.572l.3-.121a16,16,0,0,0-3.107-4.668Zm0-6.52s4.8,5.144,8.859,5.446l.021-.3c-3.933-.3-8.638-5.331-8.653-5.346l-.227.212Z" transform="translate(245.249 -42.885)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35402" data-name="Path 35402" d="M69.868,45.37c-1.679,1.948-5.579,6.587-6.1,8.127-.663,1.973,6.859,8.581,6.859,8.581a13.571,13.571,0,0,0,2.375,2.8c.953.635,1.579.542,1.912,1.392a.58.58,0,0,0,.974.315s.487.094.575-.079.236-.115.478-.3.3-.7.351-.793.36-.079.4-.581a1.513,1.513,0,0,0-.33-1.159,12.213,12.213,0,0,0-1.4-1.3s1.431.1,1.87-.348.342-.605-.439-.542-1.958-.348-2.871-.2a2.012,2.012,0,0,1-1.579-.484,18.9,18.9,0,0,1-3.428-4.236c-.9-1.879-1.325-2.784-1.21-2.9s4.345-4.215,5.806-4.841c3.026-1.286-.284-8.033-4.236-3.44Z" transform="translate(230.627 -30.544)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35403" data-name="Path 35403" d="M67.577,106.582a8.47,8.47,0,0,1,1.449,1.546c.239.505.2.605.2.605l.242.061s.057-.2-.212-.768A8.307,8.307,0,0,0,67.74,106.4l-.163.185ZM66.2,107.867s1.268.947,1.48,1.48.16.666.16.666l.242.061s.067-.212-.172-.817-1.555-1.579-1.558-1.582Zm.847-.484a6.44,6.44,0,0,1,1.446,1.434,1.337,1.337,0,0,1,.194.72l.242.042a1.552,1.552,0,0,0-.212-.865,6.6,6.6,0,0,0-1.513-1.513l-.151.194Z" transform="translate(238.501 -74.207)" fill="#915c33" />
        <path id="Path_35404" data-name="Path 35404" d="M47.217,15.29a.466.466,0,0,0-.421.5.427.427,0,1,0,.841,0A.466.466,0,0,0,47.217,15.29Z" transform="translate(266.201 -10.663)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35405" data-name="Path 35405" d="M47.463,12.309a1.938,1.938,0,0,0-2.263.339l.439.354c.563-.687,1.576-.185,1.579-.185Z" transform="translate(266.862 -8.424)" fill="#5b2809" fillRule="evenodd" />
        <path id="Path_35406" data-name="Path 35406" d="M46.536,27.826A1.086,1.086,0,0,1,45.2,27.59l-.281.233a1.475,1.475,0,0,0,1.8.321Z" transform="translate(267.693 -19.242)" fill="#915c33" />
        <path id="Path_35407" data-name="Path 35407" d="M61.091,16.89a.666.666,0,0,1,.3-.56c.3-.221.7-.042.868.56s.569.445.436.738-.823-.26-.908.148-.778,0-.427-.381.448,0,.554-.148-.157-.52-.427-.336-.436.166-.384-.021Z" transform="translate(246.793 -11.325)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35408" data-name="Path 35408" d="M48.334,15.7a.151.151,0,1,0,.127.151.139.139,0,0,0-.127-.151Z" transform="translate(264.969 -10.945)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35409" data-name="Path 35409" d="M0,251.575s.07,1.068.106,2.623a9.3,9.3,0,0,0,1.274,3.6s3.05-.605,3.4-.986.418-.669,1.062-.669a9.768,9.768,0,0,0,5.116-2.118c1.452-1.455,1.752-2.191,1.168-2.59s-2.835.212-4.7.191A4.229,4.229,0,0,1,4.844,251s-2.675,2.817-4.823.605Z" transform="translate(315.593 -175.057)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35410" data-name="Path 35410" d="M0,258.462c.245.508.454.85.454.85s3.05-.605,3.4-.986.4-.669,1.047-.669a9.768,9.768,0,0,0,5.116-2.118c1.21-1.21,1.628-1.933,1.38-2.369-.808,1.041-2.493,2.953-4.133,3.262-2.272.43-3.249.384-3.479.711A13.918,13.918,0,0,1,0,258.462Z" transform="translate(316.534 -176.571)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35411" data-name="Path 35411" d="M14.36,252a3.525,3.525,0,0,0-.72,1.47l.3.079a3.141,3.141,0,0,1,.644-1.319l-.233-.23Zm1.855.13a1.954,1.954,0,0,0-.551,1.57l.3-.079a1.575,1.575,0,0,1,.46-1.247l-.218-.245Zm-.874-.064a2.278,2.278,0,0,0-.605,1.513h.3a1.918,1.918,0,0,1,.523-1.277l-.221-.245Z" transform="translate(307.448 -175.755)" fill="#5b50ab" />
        <path id="Path_35412" data-name="Path 35412" d="M19.35,247.586h0c-.445.076-2.608.5-2.118,1.652a.605.605,0,0,0,.351.375.726.726,0,0,0,.575-.082,2.46,2.46,0,0,0,.545-.408,4.536,4.536,0,0,0,.575-.7,1.61,1.61,0,0,0,.856,1.443l.133-.3c-.859-.421-.672-1.552-.635-1.737.384-.572,2.018-.454,2.021-.454l.021-.339a5.143,5.143,0,0,0-1.619.154c.436-.7.95-1.722.151-1.61a.868.868,0,0,0-.663.626,3.985,3.985,0,0,0-.194,1.386ZM17.51,249.1c-.284-.687,1.1-1.032,1.7-1.147a4.293,4.293,0,0,1-.729.908,2.085,2.085,0,0,1-.472.357.439.439,0,0,1-.336.064.3.3,0,0,1-.16-.188Zm2.163-1.906a3.1,3.1,0,0,1,.163-.865c.085-.221.215-.393.408-.421.3-.042-.218.772-.572,1.286Z" transform="translate(301.136 -171.272)" fill="#5b50ab" />
        <path id="Path_35413" data-name="Path 35413" d="M171.671,192s-1-.327-2.42-.871a8.7,8.7,0,0,0-3.74-.121s-.587,3.119-.36,3.594.457.629.233,1.25a10.432,10.432,0,0,0,.015,5.637c.784,1.918,1.337,2.481,1.921,2.07s.856-2.771,1.567-4.538a4.331,4.331,0,0,1,1.537-2.215s-1.567-3.585,1.25-4.8Z" transform="translate(106.408 -133.107)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35414" data-name="Path 35414" d="M181.771,190.979c-.554.045-.941.115-.941.115s-.587,3.119-.36,3.594.457.629.233,1.25a10.432,10.432,0,0,0,.015,5.637c.66,1.61,1.156,2.266,1.64,2.191-.644-1.156-1.758-3.461-1.431-5.143.451-2.318.853-3.231.641-3.57a14.912,14.912,0,0,1,.2-4.081Z" transform="translate(91.088 -133.19)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35415" data-name="Path 35415" d="M173.038,211.67a3.328,3.328,0,0,0-.641-1.21l-.124.269a2.98,2.98,0,0,1,.578,1.071l.188-.145Zm-.551,1.734s-.227-.956-.741-1.068l-.036.324c.4.085.581.865.584.868Zm.26-.814a1.933,1.933,0,0,0-.7-1.107l-.082.3a1.646,1.646,0,0,1,.59.932Z" transform="translate(103.01 -146.783)" fill="#5b50ab" />
        <path id="Path_35416" data-name="Path 35416" d="M168.249,206.783h0c.145-.3.811-1.867-.427-1.876a.829.829,0,0,0-.5.13.49.49,0,0,0-.2.4,1.237,1.237,0,0,0,.1.478,2.524,2.524,0,0,0,.33.584,2.178,2.178,0,0,0-1.673.163l.209.175a2.017,2.017,0,0,1,1.815.067c.3.415-.572,1.464-.575,1.467l.3.106a3.63,3.63,0,0,0,.638-1.113c.4.484,1.05,1.113,1.334.554.118-.233,0-.442-.23-.605a3.934,3.934,0,0,0-1.119-.517Zm-.445-1.643c.735,0,.372,1.02.188,1.446a2.571,2.571,0,0,1-.451-.741,1.086,1.086,0,0,1-.085-.411.278.278,0,0,1,.106-.239A.387.387,0,0,1,167.8,205.14Zm.632,1.97a2.7,2.7,0,0,1,.678.348c.151.121.242.254.173.39C169.178,208.059,168.715,207.488,168.436,207.109Z" transform="translate(108.166 -142.909)" fill="#5b50ab" />
        <path id="Path_35417" data-name="Path 35417" d="M98.933,74.109A3.008,3.008,0,0,0,97,73.531v.3a2.7,2.7,0,0,1,1.725.5Z" transform="translate(199.719 -51.28)" fill="#915c33" />
        <path id="Path_35418" data-name="Path 35418" d="M62.306,41.781A25.055,25.055,0,0,1,56.833,39l-.2.239A24.763,24.763,0,0,0,62.2,42.083ZM54.47,40.086a2.957,2.957,0,0,1,.735.194,3.283,3.283,0,0,1,1.573,1.392A3.231,3.231,0,0,1,57.2,43.7a2.832,2.832,0,0,1-.2.708l.3.118a3.192,3.192,0,0,0,.212-.787,3.507,3.507,0,0,0-.463-2.227A3.582,3.582,0,0,0,55.332,40a3.268,3.268,0,0,0-.811-.215l-.042.3Z" transform="translate(249.214 -27.2)" fill="#bec5ed" />
        <path id="Path_35419" data-name="Path 35419" d="M38.777,84.59l-1.017.079a15.381,15.381,0,0,0,.206,2.024c.16.8.185.865.185.865l.89-.215A12.232,12.232,0,0,1,38.777,84.59Z" transform="translate(277.537 -58.996)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35420" data-name="Path 35420" d="M815.917,162.6s-3.721,10.1-4.687,10.441-2.118-1.495-4.209-2c-2.874-.7-6.9-2.021-9.219-3.873l-2.623,3.534a41.647,41.647,0,0,1,6.375,2.07c2.039,1.017,7.558,5.071,9.443,5.4s2.59-.269,4.045-1.815,7.025-10.68,7.025-10.68l-6.151-3.089Z" transform="translate(-734.656 -113.389)" fill="#c9a088" fillRule="evenodd" />
        <path id="Path_35421" data-name="Path 35421" d="M795.743,162.63s-.218.605-.563,1.513a6.112,6.112,0,0,0,2.475,1.712,7.678,7.678,0,0,1,2.9,2.042c.79-1.274,1.334-2.166,1.334-2.166l-6.151-3.089Z" transform="translate(-714.482 -113.424)" fill="#a1745a" fillRule="evenodd" />
        <path id="Path_35422" data-name="Path 35422" d="M739.465,154.466s6.711,9.945,7.4,11.5.451,4.381.475,4.908.378,5.658.781,8.983a58.64,58.64,0,0,0,2.051,8.441l-4,.7s-.212-2.647-.641-4.78-1.755-3.9-2.511-8.348-.284-7.355-.82-7.634-8.811-9.155-8.811-9.155l6.075-4.614Z" transform="translate(-644.065 -107.726)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35423" data-name="Path 35423" d="M764.935,154.473s1.41,2.088,3,4.5a51.782,51.782,0,0,1-6.629,2.723c-1.386-1.477-2.448-2.614-2.448-2.614l6.075-4.614Z" transform="translate(-669.536 -107.733)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35424" data-name="Path 35424" d="M778.7,38.062a2.6,2.6,0,0,1-1.954-.39l4.738-3.631,1.065-.121c-.669,1.631-2.06,3.815-3.849,4.136Z" transform="translate(-689.565 -23.657)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35425" data-name="Path 35425" d="M729.355,78s2.372,5.749,2.835,7.73c.977.194,4.723-4.623,5.625-5.767a6.21,6.21,0,0,1,2.324,1.21l-.058.042c-.638.457-6.148,9.491-7.891,9.325s-2.641-.575-4.405-4.538S729.355,78,729.355,78Z" transform="translate(-632.179 -54.4)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35426" data-name="Path 35426" d="M760.114,78s.741,1.815,1.477,3.731a35.823,35.823,0,0,0-3.144,4.042C756.91,81.861,760.114,78,760.114,78Z" transform="translate(-662.939 -54.4)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35427" data-name="Path 35427" d="M766.01,72.139s-3.479,3.836-4.006,5.446-5.9-5.525-5.225-7.392,5.062-4.1,6.027-3.933,3.2,5.876,3.2,5.876Z" transform="translate(-666.961 -46.206)" fill="#f7c96c" fillRule="evenodd" />
        <path id="Path_35428" data-name="Path 35428" d="M789.045,135.352s-1.6,4.723-2.091,5.7c-.266.529-.56,1.192-.775,1.685.862.908,2.6,2.593,3.6,2.723,1.065.142,3.092,2.066,3.951,2.929l-.339.684,2.49-5.01s-5.446-9.73-6.841-8.717Z" transform="translate(-705.751 -94.346)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35429" data-name="Path 35429" d="M793.583,160c-.182.411-.3.708-.3.708s0,.145,1.585,1.8,1.392-.045,3.395,1.649,2.541,2.184,2.541,2.184l.339-.684c-.859-.862-2.886-2.787-3.951-2.929-1-.133-2.741-1.815-3.6-2.723Z" transform="translate(-713.153 -111.59)" fill="#f7c96c" fillRule="evenodd" />
        <path id="Path_35430" data-name="Path 35430" d="M768.44,133.975s.557,1.28,1.15,2.366.7.554,1.165,1.764a9.337,9.337,0,0,0,1.794,2.538l.557.778-.36-.5c-.829.408-2.4,1.159-4.312,1.906l-.908-.868.157,1.159a36.006,36.006,0,0,1-4.968,1.513,29.956,29.956,0,0,0-3.894-4.947c-1.567-1.3-1.7-2.847-1.988-4.793a9.36,9.36,0,0,1,.088-3.389s11.279,1.234,11.527,2.49Z" transform="translate(-674.057 -91.713)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35431" data-name="Path 35431" d="M780.276,133.94s.521,1.192,1.086,2.254a27.228,27.228,0,0,1-4.614-.191c-3.328-.433-4.514-.908-5.658-1.068a8.925,8.925,0,0,1-2.532-1.247,8.726,8.726,0,0,1,.191-2.239S780.029,132.684,780.276,133.94Z" transform="translate(-685.893 -91.678)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35432" data-name="Path 35432" d="M719.24,78.552h0Zm.067,0Zm.057,0h0Zm.06-.015h0Zm.064-.021.042-.018Zm.07-.03.045-.021Zm.073-.036.061-.033Zm2.3-2.118.2-.218Zm.363-.408.251-.281Zm.859-.989.233-.275Zm1.725-2.118.191-.245a4.272,4.272,0,0,1-.085-2.5c.405-1.135,1.21-2.478,1.455-2.336a1.4,1.4,0,0,1,.321.257s.527-.242.7-.064a.47.47,0,0,1,.163.3s.7-.109.826.076a.8.8,0,0,1,.07.5.508.508,0,0,1,.505.079c.242.2.124.623.124.623s.52,0,.472.433-.49.977-.6,1.576a9.517,9.517,0,0,1-1.815,2.478,5.957,5.957,0,0,0-2.3-1.168Z" transform="translate(-619.229 -47.234)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35433" data-name="Path 35433" d="M772.375,44.967a6.562,6.562,0,0,0-1.056,2.079,13.393,13.393,0,0,0-.5,2.209l-3.11-3.2,1.628-4.2,3.026,3.122Z" transform="translate(-676.652 -29.194)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35434" data-name="Path 35434" d="M771.592,44.925a6.526,6.526,0,0,0-1.041,2.012,15.774,15.774,0,0,1-2.841-2.959l.844-2.178,3.026,3.122Z" transform="translate(-675.868 -29.153)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35435" data-name="Path 35435" d="M768.117,20.768l.03-.045a3.9,3.9,0,0,0,.3-.318c-.151-.284-.375-.675-.7-1.168a6.5,6.5,0,0,0-2.206-1.631,1.292,1.292,0,0,1-.626,0c-.7-.2-2.351-.136-2.917.735a2.163,2.163,0,0,1-.3.369,7.364,7.364,0,0,0-1.316,3.328,4.59,4.59,0,0,1-.463,2.024c.109.215.23.43.354.644a3.941,3.941,0,0,1-.427-.511c-.042.07-.082.133-.121.191a4.537,4.537,0,0,0,.907.908,14.492,14.492,0,0,0,1.446,1.858,3.507,3.507,0,0,0,.729-1.162c.339-.8,2.36-.526,2.36-.526l2.926-4.717Z" transform="translate(-670.312 -12.214)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35436" data-name="Path 35436" d="M758.7,28.26a3.842,3.842,0,0,1,.835,2.118c.2,1.48,1.652,1.909,1.534,2.342s-1.177.641-1.177.641.269.532.184.629-.8,0-.8,0,.723.536.705.787-.227.227-.242.436-.112,1.21-.693,1.316a6.361,6.361,0,0,1-3.933-1.331,3.491,3.491,0,0,1-1.21-2.245,1.566,1.566,0,0,1-.826-.95c-.321-.787.224-1.673.605-1.631a1.024,1.024,0,0,1,.908.536,10.758,10.758,0,0,0,.529,1.815c.139.027.672-.033.654-.188s-.33-2.42-.278-2.723.566-.554.723-.735a18.362,18.362,0,0,1,2.508-.8Z" transform="translate(-660.894 -19.709)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35437" data-name="Path 35437" d="M764.142,27.353h.051v.021h0v.015h0a9.46,9.46,0,0,0-2.593.986c-.6.508-.723.657-.653,1.307a1.01,1.01,0,0,1-.118.7,15.286,15.286,0,0,1-.17-1.894c.052-.3.566-.554.723-.735a18.365,18.365,0,0,1,2.508-.8Z" transform="translate(-666.337 -18.802)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35438" data-name="Path 35438" d="M764.222,58.822s-.139,1.177-.575,6.33-1.186,8.193-1.951,9.782-1.776,2.454-1.776,2.971,4.414,1.555,7.767,1.9,5.594,1.038,5.594.517.5-4.49.569-5.873,1.567-5.7,1.915-9.912-1.289-6.354-1.289-6.354c0-1.177-2.856-4.324-5.31-5.019s-4.841.629-4.944,5.661Z" transform="translate(-677.732 -36.958)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35439" data-name="Path 35439" d="M783.23,88.71c-.036.418-.076.868-.115,1.352-.439,5.143-1.186,8.193-1.952,9.782s-1.776,2.454-1.776,2.971c0,.048.039.1.112.157a3.2,3.2,0,0,1,2.076-.1c1.637.551,6.124.726,7.186-.378s.46-1.243.46-1.243-3.655.387-4.914.387,1.259-1.147,1.958-1.389a5.285,5.285,0,0,0,2.9-2.36c.56-1.289-3.125.908-3.61.8s1.625-1.413,2.7-2.626-.56-1.658-2.221-.07-2.662.427-2.662,0a15.675,15.675,0,0,0,.932-4.072A6.987,6.987,0,0,0,783.23,88.71Z" transform="translate(-697.201 -61.87)" fill="#f7c96c" fillRule="evenodd" />
        <path id="Path_35440" data-name="Path 35440" d="M779.549,60.035s-.082.687-.3,3.307l.663-.623s4.472-1.319,5.322-4.4c.321-.5-.605-3.806-3-3.576a4.421,4.421,0,0,0-.989.23c-.965.853-1.625,2.463-1.679,5.062Z" transform="translate(-693.06 -38.172)" fill="#e8ac33" fillRule="evenodd" />
        <path id="Path_35441" data-name="Path 35441" d="M726.112,69.315a6.1,6.1,0,0,0-.472,1.3.453.453,0,0,0,.1.224.716.716,0,0,0,.212.179.508.508,0,0,0,.278.064.336.336,0,0,0,.179-.07,7.56,7.56,0,0,0,.775-1.44l-.194-.082a7.966,7.966,0,0,1-.711,1.358.139.139,0,0,1-.07.027.278.278,0,0,1-.154-.042.468.468,0,0,1-.151-.124.25.25,0,0,1-.055-.115,6.592,6.592,0,0,1,.454-1.21l-.194-.085Z" transform="translate(-618.734 -48.332)" fill="#a1745a" />
        <path id="Path_35442" data-name="Path 35442" d="M721.108,74.434s-.39.735-.687.669a.358.358,0,0,1-.088-.033.112.112,0,0,1-.045-.067.277.277,0,0,1,0-.121,1.3,1.3,0,0,1,.2-.4c.221-.321.433-.647.433-.65l-.175-.112s-.224.342-.43.644a1.537,1.537,0,0,0-.233.475.47.47,0,0,0,0,.215.3.3,0,0,0,.13.185.455.455,0,0,0,.16.067c.451.1.908-.775.908-.775l-.185-.1Z" transform="translate(-611.143 -51.415)" fill="#a1745a" />
        <path id="Path_35443" data-name="Path 35443" d="M723.812,71.58a9.277,9.277,0,0,0-.669,1.256.786.786,0,0,0-.039.13.358.358,0,0,0,0,.109.387.387,0,0,0,.245.351.6.6,0,0,0,.26.045.684.684,0,0,0,.3-.088l-.1-.182a.486.486,0,0,1-.209.061.366.366,0,0,1-.163-.027.205.205,0,0,1-.1-.085.185.185,0,0,1-.024-.085.477.477,0,0,1,0-.054.376.376,0,0,1,.033-.106,9.932,9.932,0,0,1,.65-1.21l-.178-.106Z" transform="translate(-614.779 -49.922)" fill="#a1745a" />
        <path id="Path_35444" data-name="Path 35444" d="M725.32,74.18s-.266.678.324.823.632-.3.632-.3l-.209-.018s-.018.212-.372.124-.178-.542-.178-.542l-.2-.079Z" transform="translate(-617.709 -51.736)" fill="#a1745a" />
        <path id="Path_35445" data-name="Path 35445" d="M731.051,76.121a.977.977,0,0,1,.759-.4V75.51a1.21,1.21,0,0,0-.95.5l.178.112Z" transform="translate(-624.937 -52.663)" fill="#a1745a" />
        <path id="Path_35446" data-name="Path 35446" d="M759.9,36.253a.415.415,0,0,0,.166.811.408.408,0,0,0,.3-.484.414.414,0,0,0-.484-.327Z" transform="translate(-662.195 -25.279)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35447" data-name="Path 35447" d="M761.371,33.8a1.932,1.932,0,0,0-1.631.908l.339.166a1.566,1.566,0,0,1,1.292-.7Z" transform="translate(-663.236 -23.573)" />
        <path id="Path_35448" data-name="Path 35448" d="M761.105,36.834a.1.1,0,0,0-.082.124.106.106,0,1,0,.206-.042.1.1,0,0,0-.124-.082Z" transform="translate(-663.488 -25.687)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35449" data-name="Path 35449" d="M804.575,57.5s-.082-2.832-1.964-3.61c-1.815-.747-2.65-.36-6.856,2.063l2.051,3.676v1.21l-6.181,2.5,6.656-2.7s4.823-.3,6.281-3.141ZM782.488,71.3a1.758,1.758,0,0,1-.66.675,5.789,5.789,0,0,0-1.951,2.523c-.3.968.182,1.071.175,1.38s.024.52.3.684a1.713,1.713,0,0,1,.581.466,1.765,1.765,0,0,0,.62.448,3.43,3.43,0,0,0,1.165,0,2.122,2.122,0,0,0,1.011-1.25,9.347,9.347,0,0,0,.454-2.263,11.183,11.183,0,0,1,.584-1.256A8.776,8.776,0,0,1,782.5,71.3Zm2.723.575.112-.2Zm.266-.466.127-.215Zm.5-.8.224-.336Zm.566-.823.157-.209Zm.871-1.065.085-.091Zm.094-.1.169-.172Zm.194-.194.079-.079Z" transform="translate(-712.503 -37.347)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35450" data-name="Path 35450" d="M815.429,61.486l-.526.3c-4.665,2.693-6.614,4.538-7.833,6.493a39.417,39.417,0,0,0-3.292,5.337,18.979,18.979,0,0,1-1.628,3.2,9.224,9.224,0,0,0,2.327,1.473,14.027,14.027,0,0,1,3.15-4.59,25.708,25.708,0,0,0,3.673-4.841l6.181-2.5v-1.21l-2.051-3.676Z" transform="translate(-732.177 -42.878)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35451" data-name="Path 35451" d="M804.013,61.5l-.526.3c-.475.275-.926.542-1.346.8a9.194,9.194,0,0,0,.747,2.644c.39.7.8,1.476,1.1,1.979l2.076-.838v-1.21L804.016,61.5Z" transform="translate(-720.761 -42.892)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35452" data-name="Path 35452" d="M789.324,57.11c.418-.427.182-3.849-2.212-4.1s-6.629,2.163-7.149,2.46l-.735.43a11.021,11.021,0,0,0,.829,3.183,27.133,27.133,0,0,0,1.374,2.384c2.79-1.719,6.478-1.579,7.894-4.348Z" transform="translate(-697.252 -36.956)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35453" data-name="Path 35453" d="M773.71,82.208s0,5.086-9.379,8.039l.064.2c9.537-3,9.534-8.221,9.534-8.236Zm1.1-3.4a8.969,8.969,0,0,0-1.755-3.794l-.172.118a8.787,8.787,0,0,1,1.719,3.7ZM762.449,88.09a3.558,3.558,0,0,1-.829,3.386l.154.139a3.782,3.782,0,0,0,.874-3.6l-.2.067Zm7-5.9s-.657,3.715-5.691,6.075l.088.191c5.144-2.42,5.806-6.215,5.809-6.227Zm4.538-2.992a17.96,17.96,0,0,1-8.865.324l-.054.2a18.149,18.149,0,0,0,8.98-.324Zm-1.534,9.092a11.946,11.946,0,0,1-6.59,3.026l.03.206a12.137,12.137,0,0,0,6.711-3.089Zm-.711,4.871a13.488,13.488,0,0,1-9.149-1.168l-.091.188a13.646,13.646,0,0,0,9.3,1.168l-.064-.2Z" transform="translate(-677.245 -52.315)" fill="#e8ac33" />
        <path id="Path_35454" data-name="Path 35454" d="M785.219,53.91a2.331,2.331,0,0,1,1.362,2.49l.209.033A2.549,2.549,0,0,0,785.3,53.71l-.085.188Zm-.1,2.42s-3.138.935-4.563,2.9l.169.121c1.383-1.906,4.445-2.817,4.454-2.82Zm-1.513-1.486s-3.582,1.936-4.78,5.292l.2.07c1.168-3.28,4.672-5.174,4.68-5.177Z" transform="translate(-694.47 -37.459)" fill="#e8ac33" />
        <path id="Path_35455" data-name="Path 35455" d="M766.62,151.025s5.086,1.546,6.511,3.519l.169-.121c-1.464-2.03-6.6-3.591-6.617-3.6Zm9.406-2.566a10.9,10.9,0,0,1-8.29-.169l-.079.194a11.159,11.159,0,0,0,8.472.163l-.091-.188Zm.926,1.622s-.536,1.1-4.653.139l-.048.2c4.278,1,4.886-.239,4.889-.242Z" transform="translate(-681.087 -103.423)" fill="#30267d" />
        <path id="Path_35456" data-name="Path 35456" d="M766.759,162.63l.36.5a14.961,14.961,0,0,1-2.75,1.513c-1.67.708-1.271.52-1.271.52l-.651-.605c1.909-.747,3.482-1.513,4.311-1.906Zm-5.071,2.2.1.756s-3.24,1.107-3.585,1.192-.992.3-.992.3-.188-.3-.493-.744a36.013,36.013,0,0,0,4.968-1.513Z" transform="translate(-668.07 -113.424)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35457" data-name="Path 35457" d="M802.663,160.39s-3.516-.381-4.187-3.57l-.206.042c.7,3.328,4.357,3.734,4.369,3.734Z" transform="translate(-716.186 -109.372)" fill="#374499" />
        <path id="Path_35458" data-name="Path 35458" d="M774.242,37.074s.1-.475.405-.581a.681.681,0,0,1,.759.369c.079.212.345.735.257.88s-.7-.257-.7.067-.242.427-.4.054.605-.354.584-.581-.5-.421-.638-.127S774.242,37.074,774.242,37.074Z" transform="translate(-681.934 -25.44)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35459" data-name="Path 35459" d="M754.321,104.33a3.632,3.632,0,0,0-.841,1.779l.209.021a3.443,3.443,0,0,1,.79-1.664Z" transform="translate(-654.449 -72.764)" fill="#bec5ed" />
        <path id="Path_35460" data-name="Path 35460" d="M824.407,81.9a2.723,2.723,0,0,0-1.325-1.24l-.082.194a2.445,2.445,0,0,1,1.21,1.135l.194-.088Z" transform="translate(-745.412 -56.255)" fill="#bec5ed" />
        <path id="Path_35461" data-name="Path 35461" d="M823.15,195.517a2.5,2.5,0,0,1,.581,1.295l.206-.036a2.737,2.737,0,0,0-.638-1.416Z" transform="translate(-744.987 -136.252)" fill="#a1745a" />
        <path id="Path_35462" data-name="Path 35462" d="M768.526,20.076s.3-1.008-1.392-2.39c-3.01-2.478-8.493.085-7.322,5.842,0,0,4.069-1.476,5.467-2.118s3.246-1.34,3.246-1.34Zm-1.9-2.266a7.894,7.894,0,0,0,.487,1.21,6.2,6.2,0,0,1,.369.8l.657-.257A2.9,2.9,0,0,0,766.626,17.81Z" transform="translate(-670.385 -11.677)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35463" data-name="Path 35463" d="M768.445,16.766a2.875,2.875,0,0,0-.424.045,4.256,4.256,0,0,1,2.908,3.631l.178-.033a4.46,4.46,0,0,0-2.662-3.649Zm-1.966.581a5.171,5.171,0,0,0-.518.333c-.575.832-1.116,2.363-.057,4.717l.163-.085c-1.262-2.808-.169-4.381.411-4.965Z" transform="translate(-674.654 -11.689)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35464" data-name="Path 35464" d="M768.422,26.421a1.211,1.211,0,0,0-.1-.581l-6.711,2.829c.045.275-1.9,1.21-1.9,1.21s4.069-1.476,5.467-2.118S768.422,26.421,768.422,26.421Z" transform="translate(-670.281 -18.021)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35465" data-name="Path 35465" d="M782.818,35.515a20.957,20.957,0,0,0-2.517,2.4c0,.266.191.3.605.157a17,17,0,0,0,4.039-3.15c.154-.227.127-.472,0-.421-.36.151-2.136,1.011-2.136,1.011Z" transform="translate(-693.136 -24.059)" fill="#edf0ff" fillRule="evenodd" />
        <path id="Path_35466" data-name="Path 35466" d="M758.645,20.74a3.628,3.628,0,0,1,.823.327c.3.194.036.191.036.191s.56.036.605.227-.242.209-.242.209c.427.118,1.125.575.859,1.074,0,0,.061-.263-.542-.266s-1.531-1.761-1.531-1.761Z" transform="translate(-662.32 -14.464)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35467" data-name="Path 35467" d="M714.451,254.678s-.46-.647-.75-.511-.508,1.053-.375,1.558-.908,1.443-1.77,1.815-1.328-.877-1.419-.847-.753.109-.563,1.065a20.889,20.889,0,0,0,.672,2.774c.266.714.39.847.605.811a34.956,34.956,0,0,0,3.546-1.129c1.325-.514,3.328-1.1,4.366-1.449a6.746,6.746,0,0,0,2.723-1.389c.469-.578-.16-1.389-.977-1.358a16.18,16.18,0,0,1-4.151-.3,5.014,5.014,0,0,1-1.912-1.023Z" transform="translate(-608.323 -177.253)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35468" data-name="Path 35468" d="M709.554,265.416c.042.145.088.281.133.408.266.714.39.847.6.811a34.973,34.973,0,0,0,3.546-1.129c1.325-.514,3.328-1.1,4.366-1.449a6.744,6.744,0,0,0,2.723-1.389.72.72,0,0,0-.024-.929,7.945,7.945,0,0,1-2.917,1.83,33.278,33.278,0,0,1-5.615,1.513c-.983.148-2.145.151-2.823.342Z" transform="translate(-607.764 -182.548)" fill="#4f4599" fillRule="evenodd" />
        <path id="Path_35469" data-name="Path 35469" d="M723.871,258.986a1.528,1.528,0,0,0-.635,1.21l.224-.024a1.288,1.288,0,0,1,.542-1.007l-.13-.185Z" transform="translate(-614.82 -180.623)" fill="#f5be51" />
        <path id="Path_35470" data-name="Path 35470" d="M725.851,258.38a1.371,1.371,0,0,0-.741,1l.224.021a1.141,1.141,0,0,1,.605-.814l-.1-.2Z" transform="translate(-617.327 -180.204)" fill="#f5be51" />
        <path id="Path_35471" data-name="Path 35471" d="M727.732,257.57a1.366,1.366,0,0,0-.732,1.083h.224a1.135,1.135,0,0,1,.605-.883l-.1-.2Z" transform="translate(-619.788 -179.639)" fill="#f5be51" />
        <path id="Path_35472" data-name="Path 35472" d="M723.4,254.125a2.443,2.443,0,0,1,.883-.287,3.289,3.289,0,0,1,.908,0,5.886,5.886,0,0,1-.992.178c-.251.033-.523.07-.8.118Zm2.793.248a12.608,12.608,0,0,0-1.6-.191,4.209,4.209,0,0,0,.681-.142.417.417,0,0,0,.221-.13.142.142,0,0,0-.021-.209.377.377,0,0,0-.16-.076,3.085,3.085,0,0,0-1.056,0,2.52,2.52,0,0,0-.321.057,1,1,0,0,1,.233-.109,1.058,1.058,0,0,0,.535-.345.3.3,0,0,0,.064-.139.167.167,0,0,0-.073-.169.375.375,0,0,0-.281-.027h0a5.322,5.322,0,0,0-1.153.526,1.97,1.97,0,0,0-.666.632,6.968,6.968,0,0,0-.333.629l-.039.045h0a.255.255,0,0,0-.03.094l.054.157.115-.1c1.579-.759,2.82,1.815,2.823,1.815l.2-.1s-1.017-2.1-2.454-2.085a8.8,8.8,0,0,1,3.2.079l.045-.221Zm-2.808-.772a4.84,4.84,0,0,1,1.05-.5.382.382,0,0,0,.082,0,.8.8,0,0,1-.409.257,6.78,6.78,0,0,0-1.413.971l.1-.166a1.755,1.755,0,0,1,.605-.554Z" transform="translate(-616.694 -176.367)" fill="#f5be51" />
        <path id="Path_35473" data-name="Path 35473" d="M753.086,197a5.929,5.929,0,0,1-2.076.669v.272a6.219,6.219,0,0,0,2.209-.7Z" transform="translate(-652.442 -137.395)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35474" data-name="Path 35474" d="M876.839,176.591s.741.3.908.033-.142-1.162-.53-1.513-.015-1.7.511-2.472,1.594.03,1.655-.042.575-.493-.1-1.21a20.8,20.8,0,0,0-2.069-1.979c-.605-.463-.79-.508-.959-.357a35.769,35.769,0,0,0-2.381,2.841c-.838,1.144-2.218,2.723-2.9,3.552a6.585,6.585,0,0,0-1.537,2.62c-.079.735.886,1.086,1.561.623a15.476,15.476,0,0,1,3.67-1.948,5.022,5.022,0,0,1,2.166-.157Z" transform="translate(-814.682 -117.849)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35475" data-name="Path 35475" d="M884.38,169.71c-.115-.1-.227-.191-.333-.272-.605-.463-.79-.508-.959-.357a35.726,35.726,0,0,0-2.381,2.841c-.838,1.144-2.218,2.723-2.9,3.552a6.587,6.587,0,0,0-1.537,2.62.72.72,0,0,0,.521.768,7.867,7.867,0,0,1,1.47-3.1,32.748,32.748,0,0,1,3.933-4.26c.747-.651,1.728-1.274,2.194-1.794Z" transform="translate(-821.514 -117.87)" fill="#4f4599" fillRule="evenodd" />
        <path id="Path_35476" data-name="Path 35476" d="M887.43,193.115a1.517,1.517,0,0,0-.124-1.365l-.176.142a1.276,1.276,0,0,1,.091,1.138Z" transform="translate(-827.95 -133.734)" fill="#f5be51" />
        <path id="Path_35477" data-name="Path 35477" d="M885.265,192.984a1.367,1.367,0,0,0,.085-1.234l-.2.1a1.119,1.119,0,0,1-.079,1.017l.194.118Z" transform="translate(-825.244 -133.734)" fill="#f5be51" />
        <path id="Path_35478" data-name="Path 35478" d="M883.207,192.481a1.35,1.35,0,0,0,.03-1.3l-.191.121a1.148,1.148,0,0,1-.036,1.071Z" transform="translate(-822.562 -133.336)" fill="#f5be51" />
        <path id="Path_35479" data-name="Path 35479" d="M880.439,190.768a2.443,2.443,0,0,1-.59.711,3.232,3.232,0,0,1-.765.5,6.028,6.028,0,0,1,.741-.681c.194-.16.406-.336.605-.526Zm-2.49,1.277a11.98,11.98,0,0,0,1.456-.69,3.85,3.85,0,0,0-.5.481.439.439,0,0,0-.115.227.145.145,0,0,0,.133.166.384.384,0,0,0,.175-.021,3.23,3.23,0,0,0,.908-.557,2.385,2.385,0,0,0,.236-.221,1.121,1.121,0,0,1-.133.218,1.032,1.032,0,0,0-.269.578.34.34,0,0,0,.024.151.169.169,0,0,0,.154.1.384.384,0,0,0,.251-.127h0a5.084,5.084,0,0,0,.687-1.056,1.973,1.973,0,0,0,.221-.893c-.021-.372-.042-.587-.061-.7a.435.435,0,0,0,0-.06H881.1c0-.085-.027-.094-.027-.094l-.13-.106-.042.145c-.908,1.483-3.359-.021-3.364-.027l-.118.194s1.994,1.231,3.2.448a8.635,8.635,0,0,1-2.738,1.64l.082.209Zm2.787-.844a4.807,4.807,0,0,1-.605.977.5.5,0,0,0-.064.054.8.8,0,0,1,.209-.433,6.654,6.654,0,0,0,.663-1.573v.188a1.723,1.723,0,0,1-.2.787Z" transform="translate(-818.648 -132.123)" fill="#f5be51" />
        <path id="Path_35480" data-name="Path 35480" d="M844.961,108.466l-2.52-1.476-.451.768,2.526,1.476.445-.769Z" transform="translate(-771.712 -74.619)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35481" data-name="Path 35481" d="M845.137,108.925l.569-1a.218.218,0,0,0-.082-.3l-1.392-.787a.212.212,0,0,0-.3.079l-.569,1a.215.215,0,0,0,.079.3l1.395.787a.221.221,0,0,0,.3-.079Z" transform="translate(-772.892 -74.484)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35482" data-name="Path 35482" d="M844.22,108.661l.536-.941,1.325.747-.536.944-1.325-.75Z" transform="translate(-773.506 -75.128)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35483" data-name="Path 35483" d="M844.22,108.661l.536-.941,1.325.747-.536.944-1.325-.75Z" transform="translate(-773.506 -75.128)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35484" data-name="Path 35484" d="M150.84,211.71s2.974,4.414,2.3,5.328l-.166.218c-1.119,1.486-3.839,5.089-1.21,7.513a14.668,14.668,0,0,0,7.818,3.961c2.9.339,5.882.121,7.867-1.077l-.551-.853c-1.773,1.071-4.354,1.129-7.059.814a14.064,14.064,0,0,1-7.388-3.585c-1.967-1.815.36-4.889,1.322-6.163l.166-.227c1.11-1.492-2.257-6.472-2.266-6.487l-.847.56Z" transform="translate(114.976 -147.264)" fill="#f5be51" />
        <path id="Path_35485" data-name="Path 35485" d="M149.767,265.773s-.448.708.826,1.513a9.009,9.009,0,0,0,6.52.357c1.83-.8.091-2.983.091-2.983s-2.587,1.924-7.437,1.129Z" transform="translate(124.759 -184.584)" fill="#bd6e5e" fillRule="evenodd" />
        <path id="Path_35486" data-name="Path 35486" d="M149.942,265.742s-.3.5.336,1.122a12.613,12.613,0,0,0,7.739-1.068,4.317,4.317,0,0,0-.638-1.186S154.792,266.534,149.942,265.742Z" transform="translate(124.64 -184.549)" fill="#945447" fillRule="evenodd" />
        <path id="Path_35487" data-name="Path 35487" d="M202.915,174.867c-1.21-3.652-4.735-12.027-4.735-12.027s-6.91,2.759-6.91,2.741c.2,0,5.724,9.633,7.461,13.21l4.184-3.933Z" transform="translate(67.215 -113.571)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35488" data-name="Path 35488" d="M212.282,164.463l-.672-1.613h0s-6.91,2.759-6.91,2.741a2.7,2.7,0,0,1,.375.526h0c.182.3.427.693.714,1.174l6.493-2.826Z" transform="translate(53.785 -113.578)" fill="#a1745a" fillRule="evenodd" />
        <path id="Path_35489" data-name="Path 35489" d="M197.113,190.327c-.324-.971-1.918-4.106-2.46-5.5l-6.033,3.186c1.023,1.852,2.929,4.938,3.485,6.084l.027.033Z" transform="translate(73.82 -128.908)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35490" data-name="Path 35490" d="M194.184,198.306c-.133-.4-.484-1.171-.89-2.036l-2.088.027-.759.415s.883.89.635,1.712a10.859,10.859,0,0,1-2.463,2.623c.236.418.43.775.557,1.032l.027.033Z" transform="translate(76.748 -136.886)" fill="#ccc" fillRule="evenodd" />
        <path id="Path_35491" data-name="Path 35491" d="M200.251,189.544c-.067-.145-.133-.3-.194-.424a30.86,30.86,0,0,1-5.827,3.234l.23.4A31.5,31.5,0,0,0,200.251,189.544Z" transform="translate(68.984 -131.9)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35492" data-name="Path 35492" d="M217.935,37.18s-.505,3.4-.775,4.959c.883,1,2.781.351,4.266,1.05a9.076,9.076,0,0,1-.354-2.653c.145-1.365-3.138-3.355-3.138-3.355Z" transform="translate(40.871 -25.93)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35493" data-name="Path 35493" d="M218.393,37.18s-.115.775-.263,1.752a8.754,8.754,0,0,0,3.461,2.8,4.936,4.936,0,0,1-.061-1.2c.145-1.365-3.138-3.355-3.138-3.355Z" transform="translate(40.413 -25.93)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35494" data-name="Path 35494" d="M203.961,11.253a2.352,2.352,0,0,1,1.858-1.34,4.474,4.474,0,0,0,.663-.03,2.387,2.387,0,0,0,.962-.605h0a2.3,2.3,0,0,1,1.362-.657,3.2,3.2,0,0,1,1.119-.027,1.9,1.9,0,0,1,1.815.605c1.026.956.3,1.017,1.256,1.44a1.014,1.014,0,0,1,.242.124,1.741,1.741,0,0,1,.411.345c.663.629,1.513,1.87-.587,2.856-.378.3-.487.3-.487.3s-4.221,1.265-4.015,1.682.66,2.4.06,2.42a10.393,10.393,0,0,1-2.242-.575s.073.475-.508.188-.49-.669-1-.823-.826-.024-1.183-.5-1.035-.079-1.483-1.531-.067-3.652,1.752-3.9Zm3.249-1.513a8.832,8.832,0,0,0,1.446-.663,7.785,7.785,0,0,1,.717-.336,3.806,3.806,0,0,0-.511.064,2.039,2.039,0,0,0-1.228.569h0a1.389,1.389,0,0,1-.408.357Zm6.823,2.16a1.794,1.794,0,0,1,0,.523,1.791,1.791,0,0,1-.206.772,1.05,1.05,0,0,0,.2-1.295Z" transform="translate(52.478 -5.964)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35495" data-name="Path 35495" d="M210.913,22.763a6.654,6.654,0,0,1,.236,1.658c0,1.141-.106,1.017.172,1.582s.887,1.57.926,1.637a7.677,7.677,0,0,1-1.265.333s-.109.505-.015.651c.251.393-.947-.3-.938-.2a4.42,4.42,0,0,0,.5.862c.1.182-.218.191-.418.554-1.234,2.23-4.463-.832-5.18-2.139l.605-.436s-.269,0-.938-.941,0-1.283.487-1.21.56.66.79,1.316.726,1.11.908.953-.46-1.21-.411-1.716.3-.65.121-.992-.545-.4-.575-.738,1.044.148,2.929-.3a5.391,5.391,0,0,0,2.063-.908Z" transform="translate(53.935 -15.852)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35496" data-name="Path 35496" d="M213.423,22.777c.03.121.061.251.091.387a9.485,9.485,0,0,1-2.484.908,4.035,4.035,0,0,1-2.172.03.853.853,0,0,1,.345.938,1.852,1.852,0,0,0,.182,1.607c.176.278.082.487-.07.539a6.491,6.491,0,0,1-.43-1.495c.048-.517.3-.651.121-.992s-.545-.4-.575-.738,1.044.148,2.929-.3a5.368,5.368,0,0,0,2.063-.908Z" transform="translate(51.425 -15.866)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35497" data-name="Path 35497" d="M211.681,29.42c-.169,0-.3.254-.284.554s.157.529.327.529.3-.254.284-.554S211.85,29.414,211.681,29.42Z" transform="translate(52.033 -20.518)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35498" data-name="Path 35498" d="M211.252,27.286a1.64,1.64,0,0,0-2.012.172l.236.251a1.286,1.286,0,0,1,1.582-.136l.194-.3Z" transform="translate(53.442 -18.844)" fill="#4d352f" />
        <path id="Path_35499" data-name="Path 35499" d="M212.515,29.877a.106.106,0,1,0,.106.185.1.1,0,0,0,.042-.145A.109.109,0,0,0,212.515,29.877Z" transform="translate(51.041 -20.826)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35500" data-name="Path 35500" d="M226.361,32.545s-.2-.281-.115-.481.327-.345.56-.039.172.714.3,1-.427.194-.484-.133.191-.411.054-.433a.635.635,0,0,0-.321.091Z" transform="translate(32.427 -22.212)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35501" data-name="Path 35501" d="M212.48,39.6s-.3.175-.278.39h.1c-.024-.145.224-.284.224-.284l-.048-.091Z" transform="translate(51.27 -27.618)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35502" data-name="Path 35502" d="M205.29,138.25l4.339,7.724-6.9,2.693S205.353,138.25,205.29,138.25Z" transform="translate(57.035 -96.421)" fill="#e67e22" fillRule="evenodd" />
        <path id="Path_35503" data-name="Path 35503" d="M252.906,167.653s-1.274,6.1-2.024,6.444a86.567,86.567,0,0,1-10.426-1.7c-1.964-.424-4.1-.635-4.635-1.171a8.214,8.214,0,0,0-2.454-2l-.048-.021.045.021c-.49.436-1.48,2.281-2.829,6.081-.941,2.653-.79,4.094-.605,4.723l.024-.018c.275-.215.678-1.8,1.355-2.517.472-.5,3.125-3.355,3.346-3.383.405-.051,2.856,1.132,4.042,1.513,4.339,1.425,10.959,4.036,12.756,3.9,1.622-.124,3.864-.039,4.956-2.841s3.153-7.522,3.153-7.522-6.617-1.513-6.656-1.513Zm-20.876,1.316h0l-.269.647Zm-.421,1.017c-.127.3-.281.687-.445,1.1C231.328,170.673,231.482,170.3,231.609,169.986Zm-1.879,10.108.07-.015Zm.088-.021h0Zm.048-.021h.027Z" transform="translate(-1.068 -116.926)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35504" data-name="Path 35504" d="M230.1,167.66s-.142.672-.354,1.582l6.354,1.443c.387-.908.65-1.513.65-1.513s-6.617-1.513-6.656-1.513Z" transform="translate(21.734 -116.933)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35505" data-name="Path 35505" d="M216.18,131.44s-1.28,1.331-1.815,4.859a62.318,62.318,0,0,0-.605,6.505l9.077,1.921,3.4-11.8s-10.081-1.486-10.051-1.486Z" transform="translate(37.089 -91.671)" fill="#f39c12" fillRule="evenodd" />
        <path id="Path_35506" data-name="Path 35506" d="M214.506,131.44a4.978,4.978,0,0,0-.726,1.147,38.208,38.208,0,0,0,10.423,1.564l.354-1.225S214.476,131.44,214.506,131.44Z" transform="translate(38.763 -91.671)" fill="#e67e22" fillRule="evenodd" />
        <path id="Path_35507" data-name="Path 35507" d="M227.75,147.756l.644.136,2.287-10.871-.654-.091Z" transform="translate(28.411 -95.5)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35508" data-name="Path 35508" d="M232.43,146.939l.644.136,2.269-10.786c-.218-.033-.436-.07-.651-.109Z" transform="translate(22.334 -94.977)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35509" data-name="Path 35509" d="M237,146.053l.644.136,2.263-10.735-.647-.124-2.254,10.723Z" transform="translate(16.387 -94.384)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35510" data-name="Path 35510" d="M228.4,137.735l.151-.714-.654-.091-.151.714Z" transform="translate(30.538 -95.5)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35511" data-name="Path 35511" d="M233.08,137.009l.151-.72c-.218-.033-.436-.07-.65-.109l-.151.723Z" transform="translate(24.446 -94.977)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35512" data-name="Path 35512" d="M237.667,136.18l.151-.726-.647-.124-.151.729Z" transform="translate(18.47 -94.384)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35513" data-name="Path 35513" d="M243.541,62.419s-4.091,5.915-4.4,7.261,3.277,3.2,3.277,3.2.188-8.266,1.126-10.466Z" transform="translate(12.112 -43.527)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35514" data-name="Path 35514" d="M209.026,58.353s.5-4.305,1.84-5.945,3.364-2.042,4.366-1.455,3.531,1.864,4.057,4.678a25.417,25.417,0,0,1-.088,12.49c-1.489,6.227-.439,7.833-.563,8.792s-11.642-.868-11.549-1.7,1.186-3.249,1.21-4.148S208.651,59.563,209.026,58.353Z" transform="translate(45.317 -35.358)" fill="#c82c1d" fillRule="evenodd" />
        <path id="Path_35515" data-name="Path 35515" d="M218.874,69.246a31.471,31.471,0,0,1-.669,3.746q-.209.865-.354,1.619a2.42,2.42,0,0,1-.992.057c-1.334-.505-6.024-13.01-3.664-16.459.862-1.262,2.644-.094,3.7,1.655s1.694,7.083,1.982,9.379Zm-1.413,8.063a14.009,14.009,0,0,0,.021,2.923c-4.236.935-7.564-.257-7.564-.551s3-.378,2.532-.536-4.841-1.008-4.841-1.3,1.951.469,4.841.3a29.855,29.855,0,0,0,5-.829Z" transform="translate(46.312 -40.227)" fill="#a62014" fillRule="evenodd" />
        <path id="Path_35516" data-name="Path 35516" d="M170.278,56.664c.938-1.21,2.723-1.1,3.9.853s2.094,10.393,2.094,10.393l6.006-1.21s.375-1.008.563-1.682.484-.375,1.325-.908c.932-.578,4.19-.076,2.635,3.467a3.234,3.234,0,0,1-3.464,2.242s-8.266,2.856-9.2,2.5c-1.334-.508-5.688-13.313-3.87-15.66Z" transform="translate(89.399 -38.97)" fill="#d7ad95" fillRule="evenodd" />
        <path id="Path_35517" data-name="Path 35517" d="M211.957,65.974l-2.82.605a.424.424,0,0,0-.321.5l1.21,5.446a.421.421,0,0,0,.5.3l2.82-.623a.415.415,0,0,0,.33-.481l-1.21-5.446a.421.421,0,0,0-.5-.3Z" transform="translate(51.142 -46.005)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35518" data-name="Path 35518" d="M212.819,67.084a.082.082,0,0,1,.1.061l1.21,5.446a.082.082,0,0,1-.064.1l-2.82.623a.091.091,0,0,1-.1-.064l-1.21-5.446a.085.085,0,0,1,.064-.1l2.82-.605Z" transform="translate(50.352 -46.785)" fill="#374499" fillRule="evenodd" />
        <path id="Path_35519" data-name="Path 35519" d="M212.729,71.77s-4.3,0-5.549,1.377c.248,1.035.823,3.053.823,3.053s3.534-1.679,5.252-1.449A9.683,9.683,0,0,0,212.729,71.77Z" transform="translate(52.062 -50.055)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35520" data-name="Path 35520" d="M219.986,90.1a8.814,8.814,0,0,1-5.712,1.752v.272a9.147,9.147,0,0,0,5.894-1.815l-.182-.2ZM218.237,77a8.515,8.515,0,0,0-3.113,4.236l.254.1a8.173,8.173,0,0,1,3-4.088l-.136-.233Zm.5,4.018s.959,3.54-2.9,6.744l.175.209c3.991-3.328,2.989-7.01,2.986-7.019Zm4.009,6.753s-5.367,2.88-8.774,1.619l-.094.254c3.534,1.3,8.992-1.625,9.007-1.634l-.127-.239Z" transform="translate(40.403 -53.702)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35521" data-name="Path 35521" d="M315.786,174.17a5.849,5.849,0,0,0-1.77-1.31c-.493.436-1.483,2.281-2.832,6.081-.941,2.653-.79,4.094-.605,4.723l.024-.018c.275-.215.678-1.8,1.355-2.517.342-.366,1.84-1.973,2.723-2.835-.887-2.75.345-3.346,1.119-4.124Zm-5.376,9.555h0Z" transform="translate(-81.718 -120.559)" fill="#4d352f" fillRule="evenodd" />
        <path id="Path_35522" data-name="Path 35522" d="M317.5,193.3l-.372-.357-.209.212.372.357ZM318.757,192l-.587-.584-.209.212.587.584Zm-.318.3-.5-.487-.209.212.5.487Zm-.33.351-.445-.439-.209.212.445.439Zm-.333.342-.408-.393-.209.212.408.39Z" transform="translate(-86.642 -133.504)" fill="#fff" />
        <path id="Path_35523" data-name="Path 35523" d="M321.207,172.155c-1.014-.472-1.322-.257-1.322-.257s-3.416,8.208-3.546,9.4a1.743,1.743,0,0,0,.469,1.634s.666.209.956.024c-.175-.629-.327-2.069.605-4.723,1.349-3.8,2.339-5.646,2.829-6.081Z" transform="translate(-88.911 -119.854)" fill="#f0f0f0" fillRule="evenodd" />
        <path id="Path_35524" data-name="Path 35524" d="M207.522,96.18a4.465,4.465,0,0,0-.762,1.21l.251.106a4.074,4.074,0,0,1,.714-1.126l-.2-.182Z" transform="translate(57.719 -67.079)" fill="#b8886c" />
        <path id="Path_35525" data-name="Path 35525" d="M211.21,160.48a8.757,8.757,0,0,1-3.455-.66l-.145.23a8.926,8.926,0,0,0,3.6.7Z" transform="translate(53.976 -111.465)" fill="#f39c12" fillRule="evenodd" />
        <path id="Path_35526" data-name="Path 35526" d="M228.532,143.205s-1.289,4.1-3.7,6.354l.188.194c2.463-2.315,3.77-6.469,3.773-6.481l-.257-.082Zm-9.452,1.174s.989,3.056,1.855,4.9l.091-.445c-.808-1.815-1.685-4.538-1.688-4.538Z" transform="translate(32.927 -99.866)" fill="#e67e22" fillRule="evenodd" />
        <path id="Path_35527" data-name="Path 35527" d="M254.41,189.531a5.167,5.167,0,0,1,.908,2.036h.272a5.279,5.279,0,0,0-.953-2.188l-.224.157Z" transform="translate(-4.563 -132.081)" fill="#b8886c" fillRule="evenodd" />
        <path id="Path_35528" data-name="Path 35528" d="M150.192,211.71s2.974,4.414,2.293,5.328l-.163.218c-1.122,1.486-3.842,5.089-1.21,7.513,1.416,1.3,4.983,3.861,8.532,4.272,2.9.339,5.879.121,7.867-1.077l-.529-.862c-1.776,1.068-4.517,1.253-7.222.938-3.352-.39-6.677-2.841-7.957-4.012-1.967-1.815.363-4.892,1.322-6.163l.169-.227c1.107-1.495-2.257-6.472-2.266-6.487l-.847.56Z" transform="translate(115.106 -147.264)" fill="#4f4599" />
        <path id="Path_35529" data-name="Path 35529" d="M177.774,194.111s1.815,1.458.7,2.983a12.872,12.872,0,0,1-2.118,2.309l1.837,2.4a3.046,3.046,0,0,1,2.832-.941c1.576.454,2.33,1.628,3.673.56s-1.331-5.325-3.083-7.37S177.774,194.111,177.774,194.111Z" transform="translate(89.582 -134.715)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35530" data-name="Path 35530" d="M180.521,196.156s1.262-.7,2.6,1a16.165,16.165,0,0,1,1.9,2.977,2.823,2.823,0,0,1,.245,2.545l.23.142a3.044,3.044,0,0,0-.227-2.8A16.178,16.178,0,0,0,183.331,197c-1.476-1.888-2.932-1.083-2.935-1.083l.124.242Zm.511.717s.566,1.936-1.873,4.027l.175.206c2.572-2.206,1.964-4.3,1.961-4.3Z" transform="translate(88.046 -136.521)" fill="#5b50ab" />
        <path id="Path_35531" data-name="Path 35531" d="M311.867,190.724s.021.151.048.375a2.835,2.835,0,0,1-.7,1.386l.163.188a2.4,2.4,0,0,0,.605-.947c0,.106.015.212.018.3a1.4,1.4,0,0,0,.3.838.441.441,0,0,0,.16.121.224.224,0,0,0,.221-.024.414.414,0,0,0,.13-.3,1.4,1.4,0,0,0-.018-.345.605.605,0,0,0,.427.251c.254,0,.36-.094.366-.269,0-.3-.472-.874-.893-1.371-.179-.209-.342-.405-.451-.557l-.206.091c.036.16-.045.182-.16.248Zm.75.908c-.07-.239-.148-.472-.215-.66l.106.124c.393.466.844,1,.838,1.21,0,.03-.039.048-.13.045-.23,0-.436-.342-.605-.732Zm-.451-.508v-.045a5.132,5.132,0,0,1,.375,1.582.4.4,0,0,1-.021.112.192.192,0,0,1-.057-.048,1.162,1.162,0,0,1-.236-.681,7.613,7.613,0,0,0-.073-.908Z" transform="translate(-79.743 -132.771)" fill="#f0f0f0" />
        <path id="Path_35532" data-name="Path 35532" d="M181,84.626a1.963,1.963,0,0,1-.124,1.15l.257.088a2.218,2.218,0,0,0,.127-1.313Z" transform="translate(91.969 -58.968)" fill="#b8886c" />
        <path id="Path_35533" data-name="Path 35533" d="M476.046,224l-1.116.257,1.933,7.337,1.119-.257L476.046,224Z" transform="translate(-293.676 -156.226)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35534" data-name="Path 35534" d="M488.343,193.05l-2.233.514.436,1.649,2.236-.514-.439-1.649Z" transform="translate(-307.857 -134.641)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35535" data-name="Path 35535" d="M490.346,164.78l-2.89.666-.148.036-2.889.663a20.924,20.924,0,0,0,3.086,7.912l1.945-.436.151-.033,1.948-.448a20.325,20.325,0,0,0-1.21-8.345Z" transform="translate(-310.157 -114.924)" fill="#30267d" fillRule="evenodd" />
        <path id="Path_35536" data-name="Path 35536" d="M488.248,193.838a.841.841,0,0,0-.654,1.026.908.908,0,0,0,1.089.623.841.841,0,0,0,.654-1.026A.908.908,0,0,0,488.248,193.838Z" transform="translate(-308.879 -135.171)" fill="#ccc" fillRule="evenodd" />
        <path id="Path_35537" data-name="Path 35537" d="M487.869,195.956a.642.642,0,0,0,.024.124.924.924,0,0,0,.067.182l1.513-.348a.97.97,0,0,0-.042-.2.378.378,0,0,0-.042-.124l-1.528.354Z" transform="translate(-309.078 -136.412)" fill="#bababa" fillRule="evenodd" />
        <path id="Path_35538" data-name="Path 35538" d="M486.124,198.22l-2.974.687.523,1.994,2.977-.687-.526-1.994Z" transform="translate(-304.831 -138.246)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35539" data-name="Path 35539" d="M482.473,210.822l-2.974.687L477.91,205.5l2.977-.687,1.585,6.012Z" transform="translate(-299.067 -142.842)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35540" data-name="Path 35540" d="M484.865,206.658a.838.838,0,0,0-.65,1.026.908.908,0,0,0,1.086.623.841.841,0,0,0,.654-1.026A.908.908,0,0,0,484.865,206.658Z" transform="translate(-304.474 -144.112)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35541" data-name="Path 35541" d="M481.445,219.486a.841.841,0,0,0-.654,1.026.908.908,0,0,0,1.1.626.838.838,0,0,0,.651-1.026.908.908,0,0,0-1.086-.623Z" transform="translate(-300.027 -153.062)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35542" data-name="Path 35542" d="M473.636,230.01l-1.246.287,2.16,8.187,1.25-.287-2.163-8.187Z" transform="translate(-290.725 -160.418)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35543" data-name="Path 35543" d="M474.979,236.05l-1.709.393,1.168,4.426,1.709-.393-1.168-4.426Z" transform="translate(-291.338 -164.631)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35544" data-name="Path 35544" d="M469.8,256.08a2.77,2.77,0,0,0-2.054-1.47,1.908,1.908,0,0,0-2.088,1.186c-.221.551-.451,1.068.287,2.472S469.8,256.08,469.8,256.08Z" transform="translate(-282.62 -177.572)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35545" data-name="Path 35545" d="M442.293,257.949s-.369-.039-.142.536a7.029,7.029,0,0,1,.666,2c0,.575,0,.968.405.974a29.126,29.126,0,0,0,4.435-1.023c1.5-.481,2.526-1.059,3.328-1.352a6.84,6.84,0,0,0,2.762-2.375c.185-.629-1.011-.856-2.076-.554a7.374,7.374,0,0,1-2.185.542,15.793,15.793,0,0,1-2.2-.548c-.56-.054-.853-.418-1.189-.351s-.221.883-1.186,1.731-2.23.908-2.635.411Z" transform="translate(-259.52 -178.4)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35546" data-name="Path 35546" d="M455.481,256.307c-.411.13-.7.242-.983.318l-.505.744-.278-.635h-.045a2.744,2.744,0,0,1-.693-.1l.908,2.042,1.613-2.366Zm-2.723.26c-.218-.067-.43-.145-.629-.212l-1.174,1.552-.157-1.942a.85.85,0,0,0-.514-.13.212.212,0,0,0-.133.085l.3,3.7,2.312-3.056Z" transform="translate(-263.692 -178.428)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35547" data-name="Path 35547" d="M442.137,261.192a1.317,1.317,0,0,1,0,.151c0,.575,0,.968.405.974a29.131,29.131,0,0,0,4.436-1.023c1.5-.481,2.526-1.059,3.328-1.352a6.839,6.839,0,0,0,2.762-2.375c.073-.248-.067-.433-.324-.548-.227.484-1.1,1.921-3.737,3a20.2,20.2,0,0,1-6.868,1.171Z" transform="translate(-258.858 -179.256)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35548" data-name="Path 35548" d="M492.526,133.63s2.29,8.741,3.3,13.128l-6.115,1.958c-1.316-2.551-2.678-5.637-2.678-5.637l-2.442-8.221Z" transform="translate(-314.44 -93.199)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35549" data-name="Path 35549" d="M524.22,131.749s-3.419,2.641-4.057,5.945-5.673,14.608-8.635,15.27-7.14.3-10.8.88-6.354.953-7.585,1.467a33.886,33.886,0,0,1-3.228,1.071l.045-4.3a11.7,11.7,0,0,0,5.446-1.47c2.775-1.54,4.366-2.5,8.03-2.532s4.538.88,5.4-.073,3-9.655,3.089-11.049-.233-3.283.345-5.21a3.448,3.448,0,0,1,1.785-2.369l10.172,2.369Z" transform="translate(-344.452 -90.234)" fill="#474646" fillRule="evenodd" />
        <path id="Path_35550" data-name="Path 35550" d="M501.654,131.749a13.744,13.744,0,0,0-1.555,1.47,21.735,21.735,0,0,1-2.33-.572c-2.227-.635-6.1-1.02-7.839-1.531a3.1,3.1,0,0,1,1.552-1.737Z" transform="translate(-321.886 -90.234)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35551" data-name="Path 35551" d="M490.641,41.351a9.519,9.519,0,0,0-.941,3.67l-3.7-.908,1.573-6.033S489.029,38.5,490.641,41.351Z" transform="translate(-309.684 -26.558)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35552" data-name="Path 35552" d="M489.779,41.33a5.569,5.569,0,0,0-.675,1.815,10.668,10.668,0,0,1-3.1-2.42l.711-2.675S488.166,38.483,489.779,41.33Z" transform="translate(-308.822 -26.537)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35553" data-name="Path 35553" d="M518.6,65s-6.475,5.924-7.328,7.679,1.513,3.885,2.614,5.352a30.615,30.615,0,0,0,2.79,3.065l1.11-2.514a24.335,24.335,0,0,1-2.293-3.6c-1-1.958-.381-1.876.221-2.306s2.886-1.952,2.886-1.952Z" transform="translate(-345.234 -45.333)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35554" data-name="Path 35554" d="M482.717,56.911c-1.277,2.275-.657,5.144-.657,8.3a23.6,23.6,0,0,1-1.434,8.075c-.7,1.722-3.737,3.6-3.328,4.366s5.882,1.177,8.714,1.985,3.718.88,3.791,0,1.377-6.608,1.815-10.133,2-4.88,1.21-7.525c-.463-1.564-1.791-5.582-3.265-6.438-1.513.136-3.912-.378-3.522-1.268a6.9,6.9,0,0,0-3.328,2.641Z" transform="translate(-309.448 -37.85)" fill="#dce0f7" fillRule="evenodd" />
        <path id="Path_35555" data-name="Path 35555" d="M496.26,113.245c-.136.439-.272.829-.412,1.168-.623,1.534-3.1,3.189-3.346,4.075a20.68,20.68,0,0,0,3.631-.372c2.823-.439,5.525,2.094,7.195,1.67s-3.18-2.551-5.074-2.723-2.009-.514-1.715-.811,4.375.587,5.594,0-.157-.823-2.723-.935a3.9,3.9,0,0,1-3.153-2.088Z" transform="translate(-324.682 -78.971)" fill="#ccd1ed" fillRule="evenodd" />
        <path id="Path_35556" data-name="Path 35556" d="M481.94,92.076c.136-.75.266-1.513.378-2.212l-4.46-2.009-.321.036a2.143,2.143,0,0,0,.451,2.548A14.908,14.908,0,0,0,481.94,92.076Zm.457-2.723c.051-.339.1-.666.136-.977.408-3.31,1.815-4.708,1.337-7.056a10.36,10.36,0,0,0-1.458,1.582c-.711,1.056-1.074.817-1.119,1.655s-.666,2.4,0,3.3c.333.448.762,1.032,1.1,1.513Z" transform="translate(-300.378 -56.715)" fill="#bec5ed" fillRule="evenodd" />
        <path id="Path_35557" data-name="Path 35557" d="M486.611,61.782a7.775,7.775,0,0,0-1.513,2.6,10.715,10.715,0,0,0-.932,5.395,9.851,9.851,0,0,0,.672,4.075c.517,1.25,2.233.953,2.575.953,3.026,0,3.976-9.252,2.669-12.036-.569-1.21-2.611-1.885-3.476-.989Z" transform="translate(-309.133 -42.802)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35558" data-name="Path 35558" d="M484.018,77.376a12.323,12.323,0,0,0-.082,2.557c.045.956.082,1.607.142,2.118A10.166,10.166,0,0,0,489.2,83.4a14.185,14.185,0,0,0,1.21-7.47,17.219,17.219,0,0,1-4.741,1.383,8.529,8.529,0,0,1-1.652.048Z" transform="translate(-308.897 -52.956)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35559" data-name="Path 35559" d="M487.915,18.416a2.66,2.66,0,0,0,.605-.363.375.375,0,0,0,.342-.1c.157-.206-.148-.287,0-.542s.3-.433.176-.511-.278.133-.281-.133.242-.632.079-.726-.372.133-.366-.088v-.061c.079-.13.139-.221.139-.221s-.054.027-.139.061a.487.487,0,0,0-.3-.52c-.348-.109-.436-.118-.436-.321s-.176-.36-.318-.3.073.3-.212.157-.327-.526-.5-.52-.166.172-.245.172-.079-.191-.157-.172-.145-.07-.194.03-.133.048-.287-.048-.378-.179-.469-.091-.079.2-.173.206,0-.139-.239-.166-.3.094-.272.191-.523-.36-.983-.266-.56.287-.66.221-.275-.121-.339,0a.523.523,0,0,1-.3.263c-.13.027-.287-.239-.436-.042s-.039.384-.145.424a5.751,5.751,0,0,1-.605-.027c-.06.021-.36.182-.463.209s-.169-.191-.3-.018-.263.242-.124.278.49,0,.209.169a1.356,1.356,0,0,1-.666.224c-.06-.033-.342.136-.342.212s.091.242-.03.263-.369-.094-.421.106a1.327,1.327,0,0,0,0,.651c.057.085-.079.348-.206.266s-.578.239-.46.384a.516.516,0,0,1,.094.157.584.584,0,0,0-.3.387.6.6,0,0,0,.018.263.3.3,0,0,0-.027.142c0,.218.3.182.191.363s-.191.218-.191.275v.242c0,.067.2.106.2.106s.2.07.048.136a.726.726,0,0,1-.333.085c-.106,0-.463-.121-.49.088s-.024.49.118.478.227-.269.3-.218-.139.3-.139.466.357.354.427.345a.242.242,0,0,0,.06-.021,1.382,1.382,0,0,0,.091.121.118.118,0,0,1,.018.033,1.978,1.978,0,0,1-.3.526c-.263.3-.1.52-.051.539s.064.109.182.139l-.036.13c-.154-.018-.33,0-.369.227a.681.681,0,0,0,.526.8c.287,0,.378-.3.5,0s.063.345.221.457.333,0,.445,0,.324-.3.378.048.057.424.178.575a1.065,1.065,0,0,0,.554.263c.073,0,.572.027.572.151s.605,0,.605,0l.348-.067c.254.07.514.112.514,0a.85.85,0,0,1,.687-.7c.411-.088-.027-1.174.439-1.467s.687-.248.687-.248,1.534.112,1.591-.908.56-2.908,1.755-3.162Z" transform="translate(-305.581 -9.811)" fill="#5b2809" fillRule="evenodd" />
        <path id="Path_35560" data-name="Path 35560" d="M479.57,27.362A2.577,2.577,0,0,1,480.356,29a3.213,3.213,0,0,0,1.083,1.764c.067.212-.908.487-.908.487s-.067.554.076.672c.345.3-.481.442-.781.351-.175-.054-.166.148.278.366.23.1.463.1.436.284s-.091.151-.206.345.39.85-.339,1.247-3.007-.539-3.655-1.325a2.454,2.454,0,0,1-.605-1.585s-1.126-.336-1.283-1.119.26-1.443.605-1.355a3.882,3.882,0,0,1,1.165.814c.26.248.418,1.062.539.657s.427-1.464.127-1.921.275-1.21.557-1.425a13.424,13.424,0,0,1,2.136.112Z" transform="translate(-296.981 -18.987)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35561" data-name="Path 35561" d="M451.853,62.722c1.422,1.192-.057,9.655.366,10.5s2.926,1.945,4.333,2.239c1.062.224,2.42.529,3.026.666a2.115,2.115,0,0,1,.248-.245c.369-.324.968-.572,1.15-.3s1.119-.4,1.513-.124,1.18.638,1.1.98,0,.3,0,.726v.781c0,.284.3.284,0,.838s-1.116.551-1.585.551a9.931,9.931,0,0,1-2.318-.172c-.339-.172-.439-.517-.533-.566-.781.124-9.812-1.555-10.45-2.817-.439-.865-.8-8.369-.654-11.685.1-2.42,2.608-2.375,3.8-1.383Z" transform="translate(-271.271 -43.288)" fill="#b57748" fillRule="evenodd" />
        <path id="Path_35562" data-name="Path 35562" d="M480.207,32.7a.405.405,0,1,0,.393.405A.406.406,0,0,0,480.207,32.7Z" transform="translate(-297.764 -22.805)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35563" data-name="Path 35563" d="M481.141,33.09a.145.145,0,1,0,.139.142.139.139,0,0,0-.139-.142Z" transform="translate(-298.807 -23.078)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35564" data-name="Path 35564" d="M481.359,30.548a1.716,1.716,0,0,0-2.009.493l.212.179a1.431,1.431,0,0,1,1.67-.433l.13-.239Z" transform="translate(-298.393 -21.207)" fill="#5b2809" fillRule="evenodd" />
        <path id="Path_35565" data-name="Path 35565" d="M491.932,35.7s-.115-.62.275-.717a1.19,1.19,0,0,1,1.122.717c.106.366-.048.418-.254.672s.039.324-.082.36-.826-.169-.7-.442.711-.139.581-.439a.369.369,0,0,0-.623-.076c-.127.172-.3-.076-.3-.076Z" transform="translate(-314.197 -24.386)" fill="#915c33" fillRule="evenodd" />
        <path id="Path_35566" data-name="Path 35566" d="M488.966,99.47a2.653,2.653,0,0,0-1.706.908l.175.139a2.421,2.421,0,0,1,1.549-.823l-.018-.221Z" transform="translate(-308.408 -69.374)" fill="#915c33" />
        <path id="Path_35567" data-name="Path 35567" d="M495.751,58.852s-1.622,2.723-1.15,8.323l.224-.018c-.466-5.528,1.113-8.187,1.119-8.2Zm1.377-2.992s-.838,2.157.239,2.847l.121-.185c-.923-.605-.151-2.581-.151-2.584Zm-.72,15.067a6.334,6.334,0,0,1-2.617,1.782l.085.206a6.765,6.765,0,0,0,2.723-1.855l-.179-.133Zm4.687,8.36c-.018,0-6.844-2.862-11.1-1.286l.079.206c4.175-1.543,10.919,1.277,10.941,1.283Zm-2.118-6.6s-.672,1.582-5.446,1.374v.221c4.941.215,5.673-1.513,5.676-1.513L499,72.676Zm2.063,3.056c-.015,0-5.222,1.234-8.169-1.113l-.139.172c3.026,2.42,8.351,1.162,8.366,1.156l-.048-.215Z" transform="translate(-321.347 -38.959)" fill="#bec5ed" />
        <path id="Path_35568" data-name="Path 35568" d="M494.308,17.7s-.672-.348-1-.094a1.771,1.771,0,0,0-.263.239c-.2-.024-.768-.067-.886.236l.209.073c.079-.2.708-.088.711-.088l.1.018.027-.1s0-.027.242-.215.763.118.766.118Zm-4.557,4.538-.454.215a.181.181,0,0,0-.142-.136.42.42,0,0,0-.175,0,.6.6,0,0,0-.176.045c-.124.048-.224.13-.23.236a.436.436,0,0,0,.036.248.351.351,0,0,0,.148.148v.015c-.1.124-.221.281-.018.454l.148-.157c-.048-.042,0-.112.048-.166a.665.665,0,0,0,.07-.1.139.139,0,0,0-.085-.212.172.172,0,0,1-.1-.076.3.3,0,0,1-.015-.139.446.446,0,0,1,.094-.054.4.4,0,0,1,.121-.03.221.221,0,0,1,.076,0c.1.23.763-.088.766-.088l-.1-.194Zm2.451-2.687s-1.21,0-1.053.711l.218-.045c-.1-.451.832-.451.835-.451Z" transform="translate(-314.128 -12.214)" fill="#734522" />
        <path id="Path_35569" data-name="Path 35569" d="M490.567,22.005s-.672-.345-1-.091a1.868,1.868,0,0,0-.26.239c-.2-.024-.768-.07-.89.236l.209.073c.079-.2.711-.088.711-.088l.1.015.027-.094s0-.027.242-.215.762.118.765.118Zm-6.278,5.664-.454.215a.181.181,0,0,0-.142-.136.478.478,0,0,0-.175,0,.714.714,0,0,0-.175.045c-.124.048-.224.13-.23.236a.435.435,0,0,0,.036.248.351.351,0,0,0,.148.148v.018c-.1.121-.221.278-.018.451l.148-.157c-.048-.042,0-.112.048-.166a.66.66,0,0,0,.07-.1.136.136,0,0,0-.085-.209.2.2,0,0,1-.1-.076.324.324,0,0,1-.015-.142.448.448,0,0,1,.094-.054.506.506,0,0,1,.121-.03.208.208,0,0,1,.076,0c.1.227.763-.091.765-.091l-.1-.194Zm-.342-4.538s-1.21,0-1.053.711l.218-.045c-.1-.451.829-.451.832-.451v-.215Z" transform="translate(-308.666 -15.218)" fill="#734522" />
        <path id="Path_35570" data-name="Path 35570" d="M603.437,201.89s.2-.3-.445-.378a8.214,8.214,0,0,1-2.191-.333c-.545-.263-.908-.445-1.1-.088a26.159,26.159,0,0,0-.962,4.436c-.2,1.564-.1,2.747-.163,3.588a7.005,7.005,0,0,0,1.05,3.567c.517.457,1.237-.49,1.41-1.576a6.733,6.733,0,0,1,.439-2.2c.345-.687,1.183-1.24,1.479-1.712s.765-.572.85-.908-.741-.605-1.126-1.861.1-2.421.759-2.542Z" transform="translate(-456.911 -140.11)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35571" data-name="Path 35571" d="M600.111,219.466c.054-.427.076-.738.127-1.026l-.484-.8.723.045.018-.039a2.7,2.7,0,0,1,.4-.572l-2.327-.148Zm.941-2.557c.157-.163.324-.318.475-.466l-.959-1.767,1.909.759a.8.8,0,0,0,.348-.4.209.209,0,0,0-.021-.157l-3.631-1.449,1.891,3.479Z" transform="translate(-455.932 -148.854)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35572" data-name="Path 35572" d="M610.347,201.2a1.042,1.042,0,0,1-.145-.061c-.548-.26-.908-.445-1.1-.088a26.153,26.153,0,0,0-.962,4.436c-.2,1.564-.1,2.747-.163,3.588a7.008,7.008,0,0,0,1.05,3.567.478.478,0,0,0,.66-.036,6.733,6.733,0,0,1-1.21-4.726,19.153,19.153,0,0,1,1.882-6.68Z" transform="translate(-466.302 -140.084)" fill="#f9bfb3" fillRule="evenodd" />
        <path id="Path_35573" data-name="Path 35573" d="M524.808,158.43a2.769,2.769,0,0,1,1.243,1.933l.221-.018a3.085,3.085,0,0,0-1.346-2.118l-.118.188ZM534.154,143c0,.033-1.591,11.736-5.972,15.158l.136.176c4.454-3.479,6.051-15.27,6.051-15.3l-.224-.03Zm-11.518,16.2c-.027,0-9.171.451-13.415,3.367l.127.182c4.19-2.88,13.273-3.328,13.313-3.328V159.2Z" transform="translate(-360.437 -99.734)" fill="#2b2b2b" />
        <path id="Path_35574" data-name="Path 35574" d="M652.448,219.535a10.516,10.516,0,0,1-1.767,2.566c-.711.508-1.371.908-1.371.908s-.151,1.951.711,2.384a25.474,25.474,0,0,0,2.723,1.008l1.18-.514s-.3-1.57.036-2.091,1.64-2.215,1.64-2.215l-3.15-2.06Z" transform="translate(-524.051 -153.102)" fill="#e69685" fillRule="evenodd" />
        <path id="Path_35575" data-name="Path 35575" d="M649.982,219.57s-.3.569-.672,1.183a8.472,8.472,0,0,0,2.959,2.018l.862-1.141Z" transform="translate(-521.585 -153.137)" fill="#bd6e5e" fillRule="evenodd" />
        <path id="Path_35576" data-name="Path 35576" d="M655.431,236.839s.411-1.231.073-1.295a4.071,4.071,0,0,0-1.84.5c-.085.179.172.523.511.726A2.771,2.771,0,0,0,655.431,236.839Z" transform="translate(-525.403 -164.273)" fill="#c96a16" fillRule="evenodd" />
        <path id="Path_35577" data-name="Path 35577" d="M646.635,229.616a.635.635,0,0,0-.711.169,5.815,5.815,0,0,0-.908.95,4.977,4.977,0,0,1-.908.929c-.381.321-.56,1.038.708,2.3s2.793,2.557,3.286,3.171a9.513,9.513,0,0,0,4.272,1.712c1.3.133,1.679-.793,1.513-1.116s-2.169-1.7-2.493-2.747-.224-2.557-.575-2.6-1,.366-1.337-.1a1.21,1.21,0,0,0-1.352-.551c-.669.1-1.543,0-1.7-.708a2.419,2.419,0,0,1,.185-1.41Z" transform="translate(-520.719 -160.112)" fill="#e67e22" fillRule="evenodd" />
        <path id="Path_35578" data-name="Path 35578" d="M645.582,235.86l-.221.191c-.381.321-.56,1.038.708,2.3s2.793,2.557,3.286,3.171a9.512,9.512,0,0,0,4.272,1.712,1.619,1.619,0,0,0,1.165-.257,6.955,6.955,0,0,1-4.166-1.5c-1.976-1.688-2.617-3.141-3.431-3.737-.759-.551-1.34-.729-1.613-1.882Z" transform="translate(-521.966 -164.498)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35579" data-name="Path 35579" d="M653.24,243.639a2.148,2.148,0,0,0-1.132.487l.206.176a1.885,1.885,0,0,1,.965-.4Zm-.375-1.579a2.172,2.172,0,0,0-1.135.487l.209.175a1.888,1.888,0,0,1,.962-.4Zm.139.551a2.2,2.2,0,0,0-1.135.49l.209.175a1.877,1.877,0,0,1,.962-.4Zm.076.487a2.154,2.154,0,0,0-1.135.487l.209.176a1.861,1.861,0,0,1,.962-.4Z" transform="translate(-522.465 -168.822)" fill="#c96a16" />
        <path id="Path_35580" data-name="Path 35580" d="M649.528,241.228s-.148,0-.2-.505a1.4,1.4,0,0,1,.23-.844h0a1.7,1.7,0,0,0-.136.79c.018.07.036.13.054.194a1.687,1.687,0,0,1,.088.378h-.018Zm-.932-.7a2.948,2.948,0,0,1,1.02-1.758,3.284,3.284,0,0,1-.091.487,2.116,2.116,0,0,1-.215.5,1.6,1.6,0,0,0-.257.989c.06.605.336.72.442.741.191.051.3,0,.333-.148a1.126,1.126,0,0,0-.082-.56c-.018-.067-.039-.136-.051-.185a2.186,2.186,0,0,1,.194-.832A3.628,3.628,0,0,0,650.1,239a1.424,1.424,0,0,0,0-.194.751.751,0,0,1,.472.626,5.751,5.751,0,0,1,0,.605c0,.566-.024,1.08.4,1.322.327.185.514.154.605,0,.188-.3-.23-1.21-.333-1.431V239.9a4.8,4.8,0,0,0-.242-.868,1.014,1.014,0,0,0-.5-.566,1.839,1.839,0,0,1,1.059.548l.2-.182s-.941-.974-1.815-.5a1.548,1.548,0,0,0-.3.112h-.051v.021a3.034,3.034,0,0,0-1.289,2.018l.272.045Zm.971.726Zm1.286-1.815c.045.157.082.33.121.508a.334.334,0,0,0,.03.073c.091.2.46,1.008.348,1.189,0,.021-.079,0-.233-.088-.284-.163-.272-.605-.263-1.086v-.605Z" transform="translate(-519.906 -166.132)" fill="#c96a16" />
        <path id="Path_35581" data-name="Path 35581" d="M591.337,238.376a7.732,7.732,0,0,1-.49,2.914c-.49.714-.947,1.322-.947,1.322s.569,1.879,1.534,2a25.872,25.872,0,0,0,2.917.036l.908-.877s-.865-1.368-.729-1.973.741-2.629.741-2.629l-3.933-.8Z" transform="translate(-445.749 -166.249)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35582" data-name="Path 35582" d="M590.182,238.39a11.136,11.136,0,0,1-.272,2.4,11.53,11.53,0,0,0,3.6.542c.221-.826.605-2.148.605-2.148l-3.933-.8Z" transform="translate(-444.594 -166.263)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35583" data-name="Path 35583" d="M591.614,252.454s-.063-1.292-.405-1.237a4.008,4.008,0,0,0-1.537,1.083c-.024.194.351.436.741.511a2.81,2.81,0,0,0,1.21-.357Z" transform="translate(-442.031 -175.207)" fill="#c96a16" fillRule="evenodd" />
        <path id="Path_35584" data-name="Path 35584" d="M585.484,38.74a7.521,7.521,0,0,1-.823,3.071,11.915,11.915,0,0,1-.992,1.343l4.081,1.413a24.474,24.474,0,0,0,1.452-3.585C589.466,39.817,585.484,38.74,585.484,38.74Z" transform="translate(-437.808 -27.018)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35585" data-name="Path 35585" d="M583.726,38.74s-.018.363-.1.868a19.714,19.714,0,0,0,3.328,2.832,10.243,10.243,0,0,0,.5-1.461c.263-1.168-3.728-2.239-3.728-2.239Z" transform="translate(-436.05 -27.018)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35586" data-name="Path 35586" d="M562.352,21.983a2.474,2.474,0,0,0,2.042,1.029c1.607.169,4.281-.43,3.513-1.967-.236-.054-4.78.874-5.528.926h-.027Z" transform="translate(-410.186 -14.675)" fill="#d49b2a" fillRule="evenodd" />
        <path id="Path_35587" data-name="Path 35587" d="M575.015,15.236c-.908.859-.478,1.28-1.177,1.685-.962.554-.656,3.631.051,4.375.944,1,1.068.8,1.564,1.649a5.79,5.79,0,0,0,2.315-.811c.8-.62,1.948-3.026,2.2-3.434.675-1.11,1.964.085,2.542-1.746C580.612,15.524,577.514,12.873,575.015,15.236Z" transform="translate(-427.944 -9.968)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35588" data-name="Path 35588" d="M577.36,24.73s.36,1.48.632,2.16,1.017,1.682.735,1.77l-.886.266-.067.517-1.065-.085a5.4,5.4,0,0,0,.793.808c.23.124-.067.424-.142,1.056s-.23.908-1.071.8a5.546,5.546,0,0,1-3.026-1.634c-.575-.8-.581-1.065-.581-1.065a1.053,1.053,0,0,1-1.21-.714c-.342-.908,0-1.591.457-1.673a1.068,1.068,0,0,1,1.117.641c.088.36.054.811.405.811a.726.726,0,0,0,.6-.874,2.834,2.834,0,0,1,.021-1.437c.109-.817.763-.868,1.667-.868a3.2,3.2,0,0,0,1.613-.475Z" transform="translate(-423.651 -17.247)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35589" data-name="Path 35589" d="M576.551,24.73s.278,1.144.532,1.885a8.418,8.418,0,0,1-2.784-1.389c.194-.018.408-.021.638-.021a3.2,3.2,0,0,0,1.613-.475Z" transform="translate(-422.842 -17.247)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35590" data-name="Path 35590" d="M577.1,30.8a.445.445,0,1,0,.454.445A.451.451,0,0,0,577.1,30.8Z" transform="translate(-424.022 -21.481)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35591" data-name="Path 35591" d="M577.68,28.546a1.833,1.833,0,0,0-1.61.5l.272.2a1.451,1.451,0,0,1,1.268-.378Z" transform="translate(-423.974 -19.893)" />
        <path id="Path_35592" data-name="Path 35592" d="M583.06,14.29a18.7,18.7,0,0,0-1.815-2.605c-.681-.678-2.266-.587-4.042,0a4.874,4.874,0,0,0-3.24,2.774,4.123,4.123,0,0,0-.3,2.8,2.374,2.374,0,0,0,.185.542,24.51,24.51,0,0,1,9.216-3.519Z" transform="translate(-428.588 -7.818)" fill="#e8ac33" fillRule="evenodd" />
        <path id="Path_35593" data-name="Path 35593" d="M566.521,20.36c-.545.245-3.631,1.256-3.682,1.295a11.3,11.3,0,0,0,3.933.179c1.855-.327,3.2-1.056,3.171-1.265a19.016,19.016,0,0,1-3.434-.209Z" transform="translate(-412.234 -14.199)" fill="#fad489" fillRule="evenodd" />
        <path id="Path_35594" data-name="Path 35594" d="M578.325,31.34a.115.115,0,1,0,.115.112A.115.115,0,0,0,578.325,31.34Z" transform="translate(-425.381 -21.857)" fill="#fff" fillRule="evenodd" />
        <path id="Path_35595" data-name="Path 35595" d="M590.623,33.868s-.1-.284.278-.514.687-.088.88.421.278.714-.064.865a3.069,3.069,0,0,0-.569.269c-.239.148-.52-.284-.266-.514s.605-.139.605-.3-.354-.3-.523-.13S590.623,33.868,590.623,33.868Z" transform="translate(-442.644 -23.187)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35596" data-name="Path 35596" d="M627.589,57.87s-7.131,2.871-7.848,3.776,2.015,11.1,1.815,11.906-.787,1.355-.581,2.387a2.027,2.027,0,0,0,1.776,1.843c.886,0,1.383.045,1.637-.372s.206-.647.36-.629.823.678,1.089.451-.647-2.224-.578-2.871a5.954,5.954,0,0,0-.908-2.42c-.191-.269-.732-8.145-.427-8.759s4.732-1.452,4.732-1.452l-1.047-3.855Z" transform="translate(-488.127 -40.36)" fill="#fca695" fillRule="evenodd" />
        <path id="Path_35597" data-name="Path 35597" d="M621.847,57.863s-.932.375-2.157.908l1.782,3.246c.8-.182,1.422-.3,1.422-.3l-1.047-3.855Z" transform="translate(-482.386 -40.353)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35598" data-name="Path 35598" d="M542.622,68s1.716,4.538,3.443,5.4,6.514-7.452,7.7-9.028c.2-.257.321,0,.856-.224s.95-1.044.977-1.068.881-.448.908-.947-.287-.605-.287-.605c.091-.384-.336-.605-.505-.62.142-.372-.263-.711-.433-.778,0,0,.151-.529-.221-.775a.605.605,0,0,0-.72.109s-.221-.354-.463-.3-.269.369-.333.657-.448.378-.908.756-.345.478-.451.908a4.05,4.05,0,0,1-.345,1.21,53.623,53.623,0,0,1-5.213,5.824L545.279,64.6l-3.594,1.268.932,2.118Z" transform="translate(-392.399 -41.255)" fill="#db8777" fillRule="evenodd" />
        <path id="Path_35599" data-name="Path 35599" d="M575.938,56.93s1.579.454,3.08,4.118c.46,1.126.708,1.815.832,2.242-.381-.073-1.737,2.46-3.216,2.466-1.561,0-.626.433-.626.433Z" transform="translate(-426.041 -39.705)" fill="#d49b2a" fillRule="evenodd" />
        <path id="Path_35600" data-name="Path 35600" d="M617.027,128.221s-4.693,13.918-7.307,15.848-3.97,5.08-5.053,6.7-2.593,3.749-3.38,5c-1.038-.5-2.935-1.352-3.588-2.6.862-1.513,1.97-3.631,2.844-5.11s3.383-4.808,4.929-6.974S608.8,123.9,608.8,123.9Z" transform="translate(-469.866 -86.412)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35601" data-name="Path 35601" d="M606.5,128.221s-1.8,5.334-3.785,9.854a40.7,40.7,0,0,1-5.013-9.791c.354-2.514.575-4.384.575-4.384Z" transform="translate(-459.337 -86.412)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35602" data-name="Path 35602" d="M570.558,123.9s1.912,6.55,3.268,8.714,7.134,9.413,7.195,9.984-.2.472-1.815,4.166-2.227,9.969-2.484,13.185a11.4,11.4,0,0,0,4.587.7c1.331-4.13,3.779-11.715,4.72-14.523,1.319-3.933,1.256-6.193,0-9.422s-5.522-12.8-5.522-12.8Z" transform="translate(-431.62 -86.412)" fill="#5b50ab" fillRule="evenodd" />
        <path id="Path_35603" data-name="Path 35603" d="M581.953,160.519c.163.024.324.042.478.061a48.472,48.472,0,0,0,1.773-5.367,81.454,81.454,0,0,0,2.321-9.809,4.841,4.841,0,0,1,.272-1.513,3.656,3.656,0,0,1,.629-1.259c.378-.3.248-.753.109-1.231l-.033-.118a.83.83,0,0,0-.405-.511.417.417,0,0,1-.23-.318h0c-.073-.536-.336-2.508-3.979-7.146a25.115,25.115,0,0,1-4.442-9.437h-.478a25.811,25.811,0,0,0,4.538,9.706c3.57,4.538,3.821,6.423,3.888,6.935h0a.8.8,0,0,0,.424.626.456.456,0,0,1,.224.26l.036.121c.1.336.188.654.042.772a3.934,3.934,0,0,0-.774,1.461,5.25,5.25,0,0,0-.3,1.646,86.206,86.206,0,0,1-2.3,9.682,43.16,43.16,0,0,1-1.815,5.425Z" transform="translate(-434.543 -86.392)" fill="#e6e6e6" fillRule="evenodd" />
        <path id="Path_35604" data-name="Path 35604" d="M588.84,123.9s.221.762.569,1.852a11.881,11.881,0,0,0,4.166.106c1.749-.433,5.573.511,6.169.169-.566-1.277-.944-2.118-.944-2.118Z" transform="translate(-449.902 -86.412)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35605" data-name="Path 35605" d="M588.363,47.516a28.037,28.037,0,0,1,4.254,2.666,8.546,8.546,0,0,1,1.649,7.24,61.566,61.566,0,0,0-1.3,6.808s-1.023,6.617-.784,7.358-4.438-.484-6.451,0-5.8-.387-5.8-.387,1.942-7.485,2.251-9.422a57.689,57.689,0,0,0,.3-6.26l-2.557-4.681c2.826-1.815,5.358-1.815,8.426-3.328Z" transform="translate(-441.964 -33.135)" fill="#f5be51" fillRule="evenodd" />
        <path id="Path_35606" data-name="Path 35606" d="M606.2,103a11.679,11.679,0,0,0-3.4-.024c-2.012.484-5.8-.387-5.8-.387s1.942-7.485,2.251-9.422c.03-.2.058-.414.085-.65a17.8,17.8,0,0,0,1.815,4.538c1.1,1.374-1.6.487-1.6,1.313s2.169,2.56,2.7,3.062,4.151-.085,4.151.853a1.514,1.514,0,0,1-.2.717Z" transform="translate(-459.027 -64.527)" fill="#e8ac33" fillRule="evenodd" />
        <path id="Path_35607" data-name="Path 35607" d="M598.317,61.439c.021-.883.021-1.561.021-1.712,1.546-.209,4.423-.744,5.343-1.9,1.28-1.613.17-4.324.17-4.324,1.537,1.05,1.092,5.035-.732,6.26a15.4,15.4,0,0,1-4.8,1.679Z" transform="translate(-457.809 -37.313)" fill="#d49b2a" fillRule="evenodd" />
        <path id="Path_35608" data-name="Path 35608" d="M583.958,74.615c0,.021,2.517,6.959,3.873,8.221l.2-.212c-1.3-1.21-3.791-8.087-3.8-8.109l-.275.088Zm7.882-4.735s-.584,4.066-5.2,2.85l-.079.266c4.938,1.3,5.564-3.065,5.564-3.077Zm-9.379,6.13c0,.018,1.371,6.913,2.826,8.714l.233-.166c-1.422-1.749-2.762-8.59-2.765-8.59l-.287.051Zm7.912,2.535s-4.478-3.355-5.67-5.3l-.254.139c1.21,1.991,5.724,5.37,5.749,5.379l.182-.218Z" transform="translate(-440.354 -48.737)" fill="#d49b2a" />
        <path id="Path_35609" data-name="Path 35609" d="M576.15,134.088c0,.027,3.467,9.158,8.1,12.1l.151-.23c-4.538-2.886-7.972-11.933-7.982-11.96l-.266.1ZM586.416,146.4a3.247,3.247,0,0,1-2.481,2.5l.073.257a3.516,3.516,0,0,0,2.681-2.723l-.272-.036Zm.554,1.688a3.727,3.727,0,0,1-3.3,1.5l-.033.272a4.025,4.025,0,0,0,3.567-1.616Zm-1.144,3.455a38.286,38.286,0,0,1-4.078,12.366l.251.127a38.594,38.594,0,0,0,4.109-12.462l-.281-.03Z" transform="translate(-433.521 -93.457)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35610" data-name="Path 35610" d="M628.14,164.117c0,.015-2.632,5.933-6.454,7.564l.112.251c3.933-1.67,6.593-7.685,6.6-7.7l-.26-.112Zm-1.023-2.073s1.888-.045,2.6.926l.227-.154c-.8-1.086-2.829-1.038-2.835-1.038v.266Zm2.188-8.051s1.755,4.711,1.325,7.624l.278.039c.442-2.98-1.334-7.745-1.337-7.758Z" transform="translate(-491.075 -107.336)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35611" data-name="Path 35611" d="M589.989,51.872a21.659,21.659,0,0,0-4.614-3.092l-.115.254a21.282,21.282,0,0,1,4.538,3.041l.2-.2Z" transform="translate(-439.07 -34.021)" fill="#fad489" />
        <path id="Path_35612" data-name="Path 35612" d="M609.354,59.388A8.877,8.877,0,0,0,603,57.352l.027.275a8.556,8.556,0,0,1,6.118,1.961l.2-.2Z" transform="translate(-463.796 -39.982)" fill="#d49b2a" />
        <path id="Path_35613" data-name="Path 35613" d="M606.448,55.7a13.738,13.738,0,0,0-4.508-.75l.018.275a13.294,13.294,0,0,1,4.387.735Z" transform="translate(-460.569 -38.313)" fill="#d49b2a" />
        <path id="Path_35614" data-name="Path 35614" d="M614.608,63.187a7.882,7.882,0,0,1-3.631-2.357l-.227.175a8.2,8.2,0,0,0,3.785,2.448l.076-.266Z" transform="translate(-471.397 -42.425)" fill="#d49b2a" />
        <path id="Path_35615" data-name="Path 35615" d="M637.64,72.419a3.328,3.328,0,0,0-1.958-.769l-.042.275a3.053,3.053,0,0,1,1.773.666Z" transform="translate(-501.957 -49.971)" fill="#db8777" />
        <path id="Path_35616" data-name="Path 35616" d="M636.7,101.25l-3.132.614.182.841,3.134-.617-.184-.838Z" transform="translate(-500.577 -70.615)" fill="#342d6e" fillRule="evenodd" />
        <path id="Path_35617" data-name="Path 35617" d="M637.576,101.892l-.248-1.089a.221.221,0,0,0-.251-.16l-1.579.324a.206.206,0,0,0-.16.245l.251,1.086a.209.209,0,0,0,.248.16l1.582-.324a.2.2,0,0,0,.157-.242Z" transform="translate(-501.806 -70.189)" fill="#2b2b2b" fillRule="evenodd" />
        <path id="Path_35618" data-name="Path 35618" d="M636.433,102.785l-.233-1.02,1.492-.306.233,1.02-1.492.306Z" transform="translate(-502.411 -70.762)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35619" data-name="Path 35619" d="M636.433,102.785l-.233-1.02,1.492-.306.233,1.02-1.492.306Z" transform="translate(-502.411 -70.762)" fill="#e84638" fillRule="evenodd" />
        <path id="Path_35620" data-name="Path 35620" d="M544.978,60.64a1.931,1.931,0,0,1-.369.844,1.277,1.277,0,0,0-.3.472.376.376,0,0,0,.067.384.215.215,0,0,0,.173.073c-.079.124-.142.284-.058.4a.626.626,0,0,0,.275.239.539.539,0,0,0,.236.033c-.042.124-.085.3.03.405a.462.462,0,0,0,.151.088.58.58,0,0,0,.154.03.565.565,0,0,0,.345.366,1.177,1.177,0,0,0,.908-.115l-.07-.118a1,1,0,0,1-.769.109.444.444,0,0,1-.266-.254l.115-.024a2.137,2.137,0,0,0,.257-.094,4.557,4.557,0,0,0,1.041-.8l-.1-.091a4.541,4.541,0,0,1-1,.762,1.9,1.9,0,0,1-.236.085.865.865,0,0,1-.172.03.375.375,0,0,1-.263-.085c-.076-.064,0-.26.042-.336l.073-.027a5.6,5.6,0,0,0,1.1-.908l-.106-.091a5.522,5.522,0,0,1-1.044.89.481.481,0,0,1-.366.027.5.5,0,0,1-.215-.191c-.079-.112.1-.327.163-.393l.051-.03a4.261,4.261,0,0,0,.514-.393,3.886,3.886,0,0,0,.554-.581l-.115-.079a3.675,3.675,0,0,1-.532.548,4.385,4.385,0,0,1-.493.381l-.067.036h0c-.091.045-.16.054-.2,0a.245.245,0,0,1-.039-.257,1.115,1.115,0,0,1,.269-.415,2.082,2.082,0,0,0,.405-.908l-.142-.018Zm-2.148,3.377s1.386.666,1.9-.545l-.136-.045c-.445,1.08-1.688.481-1.691.481l-.067.124Z" transform="translate(-383.133 -42.292)" fill="#bd6e5e" />
        <path id="Path_35621" data-name="Path 35621" d="M574.691,90.61a5.83,5.83,0,0,0-.278,1.976h.275a5.446,5.446,0,0,1,.26-1.876Z" transform="translate(-420.74 -63.195)" fill="#bd6e5e" />
        <path id="Path_35622" data-name="Path 35622" d="M575.045,250.369a.629.629,0,0,0-.605.4,5.68,5.68,0,0,0-.5,1.192,4.737,4.737,0,0,1-.511,1.174c-.239.427-.145,1.159,1.513,1.918s3.546,1.461,4.236,1.873a9.662,9.662,0,0,0,4.626.175c1.268-.312,1.286-1.3,1.023-1.555s-2.653-.868-3.328-1.74-1.144-2.324-1.489-2.239-.8.678-1.292.348a1.21,1.21,0,0,0-1.464-.064c-.605.321-1.446.523-1.852-.1a2.42,2.42,0,0,1-.339-1.383Z" transform="translate(-430.43 -174.617)" fill="#e67e22" fillRule="evenodd" />
        <path id="Path_35623" data-name="Path 35623" d="M574.107,258.67l-.139.251c-.239.427-.145,1.159,1.513,1.918s3.546,1.461,4.236,1.873a9.661,9.661,0,0,0,4.626.176,1.57,1.57,0,0,0,1-.629,7.117,7.117,0,0,1-4.448,0c-2.469-.923-3.6-2.069-4.581-2.354-.908-.263-1.513-.239-2.193-1.225Z" transform="translate(-430.969 -180.407)" fill="#ecf0f1" fillRule="evenodd" />
        <path id="Path_35624" data-name="Path 35624" d="M586.052,258.335a2.061,2.061,0,0,0-.883.835l.257.1a1.816,1.816,0,0,1,.756-.7Zm-.929-1.355a2.072,2.072,0,0,0-.883.838l.257.094a1.814,1.814,0,0,1,.756-.7Zm.333.472a2.061,2.061,0,0,0-.883.835l.257.1a1.814,1.814,0,0,1,.756-.7Zm.248.43a2.045,2.045,0,0,0-.884.835l.257.094a1.794,1.794,0,0,1,.756-.7l-.13-.233Z" transform="translate(-434.948 -179.228)" fill="#c96a16" />
        <path id="Path_35625" data-name="Path 35625" d="M582.9,256.152s-.139.051-.369-.405a1.379,1.379,0,0,1-.094-.868h0a1.728,1.728,0,0,0,.16.787l.121.16a1.821,1.821,0,0,1,.221.327H582.9Zm-1.128-.345a2.876,2.876,0,0,1,.3-1.988,4.255,4.255,0,0,1,.091.487,2.148,2.148,0,0,1-.018.542,1.586,1.586,0,0,0,.118,1.014c.278.548.578.563.684.545.2,0,.269-.106.26-.248a1.211,1.211,0,0,0-.281-.5l-.115-.151a2.12,2.12,0,0,1-.121-.847,3.683,3.683,0,0,0-.085-.793,1.043,1.043,0,0,0-.07-.182.793.793,0,0,1,.675.43c.085.182.157.375.224.563.194.536.369,1.02.853,1.1.372.067.539-.027.572-.206.067-.342-.656-1.056-.832-1.228,0-.015,0,0-.018-.015a4.8,4.8,0,0,0-.545-.732,1.062,1.062,0,0,0-.678-.366,1.921,1.921,0,0,1,1.192.16l.124-.236s-1.238-.605-1.876.13a1.736,1.736,0,0,0-.236.2l-.045.024v.015a2.961,2.961,0,0,0-.472,2.324l.272-.048Zm1.174.357Zm.548-2.118c.1.133.2.281.3.436l.054.061c.157.154.8.787.759,1,0,.024-.073.027-.251,0-.324-.054-.475-.472-.638-.929-.067-.185-.136-.375-.221-.557Z" transform="translate(-432.778 -176.401)" fill="#c96a16" />
      </g>
    </svg>
  );
}

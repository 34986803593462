import styled from "styled-components";

export const AddRewardDetailsFormStyled = styled.div`
  .top_add_mission {
    display: flex;
    margin-bottom: 60px;
    .left_add {
      width: 35%;
      min-width: 414px;
      flex-shrink: 0;
      .upload_layer {
      }
      .setting_layer {
        margin-top: 16px;
        .setting_top {
          display: flex;
          justify-content: center;
          margin-bottom: 13px;
          .show_icon {
            display: flex;
            margin-right: 15px;
          }
        }
        .setting_input {
        }
        .group_setting_label {
          margin-top: 18px;
          &.no_margin {
            margin-top: 0px;
          }
          .t_input {
            display: flex;
            align-items: center;
            padding-left: 15px;
            .show_icon {
              display: flex;
              margin-right: 15px;
            }
          }
          .b_input {
            margin-top: 10px;
            display: flex;
            .left_b_input {
              width: 80%;
            }
            .right_b_input {
              margin-left: 16px;
              display: flex;
              align-items: center;
              font-size: ${({ theme }) => theme.FONT.SIZE.S20};
              font-family: noto_sans_thai_bold, noto_sans_bold;
              .num1 {
                color: ${({ theme }) => theme.COLORS.PURPLE_1};
                margin-right: 3px;
              }
              .num2 {
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
    .right_add {
      width: 65%;
      margin-left: 32px;
      .right_add_top {
        display: flex;
        justify-content: center;
        margin-bottom: 27px;
        .show_icon {
          display: flex;
          margin-right: 15px;
        }
      }
      .block_dual {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        &.flex_end {
          justify-content: flex-end;
        }
        &.mb8 {
          margin-bottom: 8px;
        }
        &.mb21 {
          margin-bottom: 21px;
        }
        &.mb0 {
          margin-bottom: 0px;
        }
        .label_pos {
          width: 18%;
          display: flex;
          &.center {
            align-items: center;
          }
          &.pt12 {
            padding-top: 12px;
          }
        }
        .input_pos {
          width: 70%;
          /* display: flex; */
          &.pos_end {
            display: flex;
            justify-content: flex-end;
          }
        }
        .btn_layout {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          column-gap: 5%;

          .btn_submit {
            width: 200px;
          }
          .btn_del {
            width: 98px;
          }
        }
        .input_drop {
          width: 70%;
          justify-content: space-between;
          display: flex;
          .left_box {
            width: 45%;
          }
          .right_box {
            width: 45%;
          }
          .between_label {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            font-family: noto_sans_thai_bold, noto_sans_bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            .small {
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: noto_sans_thai_regular, noto_sans_regular;
              color: ${({ theme }) => theme.COLORS.GRAY_1};
            }
          }
        }
      }
    }
  }
`;
